define('businessServices/authentication/stores/signupAuthenticationStore',[
    'settings/settings',
    'common/time/date',
    'common/authentication/authenticatedSignup',
    'common/storage/browserTabStorage',
    'cookie'
], function(){
    return function() {
        var self = this;
        var DateConstructor = null;
        var _browserTabStorage = require('common/storage/browserTabStorage');
        var _cookieTimeout = require('settings/settings');
        var STORAGE_NAME = "TrestaSignupSession";
        var _authenticatedSignup = require("common/authentication/authenticatedSignup");
        var _cookieOptions = {
            path: '/'
        };

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.save = function(signupSessionId, accountNumber, secretKey, workSessionId) {
            _authenticatedSignup.signupSessionId = signupSessionId;
            _authenticatedSignup.accountNumber = accountNumber;
            _authenticatedSignup.secretKey = secretKey;
            _authenticatedSignup.workSessionId = workSessionId;
            var date = new Date();
            date.setTime(date.getTime() + _cookieTimeout.signupExpireTimeInMilliseconds);

            var data = {
                signupSessionId : signupSessionId,
                accountId : accountNumber,
                expirationDate: date,
                secretKey: secretKey,
                workSessionId: workSessionId
            };

            if (workSessionId === undefined) {
                $.cookie(STORAGE_NAME, data, _cookieOptions);

            } else {
                _browserTabStorage.saveObject(STORAGE_NAME, data);
            }
        };

        self.clear = function() {
            $.cookie(STORAGE_NAME, {}, _cookieOptions);
            _browserTabStorage.deleteObject(STORAGE_NAME);
            _authenticatedSignup.signupSessionId = null;
            _authenticatedSignup.accountNumber = null;
            _authenticatedSignup.secretKey = null;
            _authenticatedSignup.workSessionId = null;
        };

        self.load = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var data = _browserTabStorage.getObject(STORAGE_NAME);
                if (!data) {
                    data = $.cookie(STORAGE_NAME);
                }

                var date = new Date();
                if (data && data.expirationDate) {
                    var cookieDateInMilliseconds = Date.parse(data.expirationDate);
                    if (date.getTime() > cookieDateInMilliseconds) {
                        self.clear();
                    } else if (data.signupSessionId) {
                        _authenticatedSignup.signupSessionId = data.signupSessionId;
                        _authenticatedSignup.accountNumber = data.accountId;
                        _authenticatedSignup.secretKey = data.secretKey;
                        _authenticatedSignup.workSessionId = data.workSessionId;
                    } else {
                        _authenticatedSignup.signupSessionId = null;
                        _authenticatedSignup.accountNumber = null;
                        _authenticatedSignup.secretKey = null;
                        _authenticatedSignup.workSessionId = null;
                    }
                } else {
                    _authenticatedSignup.signupSessionId = null;
                    _authenticatedSignup.accountNumber = null;
                    _authenticatedSignup.secretKey = null;
                    _authenticatedSignup.workSessionId = null;
                }

                deferredObject.resolve(_authenticatedSignup.signupSessionId, _authenticatedSignup.accountNumber, _authenticatedSignup.secretKey, _authenticatedSignup.workSessionId);
            });
        };
        self.init = function() {
            DateConstructor = require('common/time/date');
            _initialize();
        };

        var _initialize = function() {
            $.cookie.json = true;
        };
    };
});

