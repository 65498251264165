define('presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxGreetingsViewModel',[
        'common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'businessServices/state/modelStateObserver',
        'presentation/common/actionModal/viewModels/deleteVoicemailGreetingActionViewModel',
        'presentation/common/voicemail/viewModels/addVoicemailGreetingViewModel',
        'presentation/settings/voicemailBoxes/facades/addVoicemailBoxGreetingsFacade',
        'presentation/settings/voicemailBoxes/presentationObjects/voicemailBoxGreetingPresentationObject'
], function () {
        return function () {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');
            const _sorter = new SorterConstructor();

            const AddVoicemailGreetingViewModelConstructor = require('presentation/common/voicemail/viewModels/addVoicemailGreetingViewModel');
            const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteVoicemailGreetingActionViewModel');
            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const VoicemailGreetingsConstructor = require('presentation/settings/voicemailBoxes/presentationObjects/voicemailBoxGreetingPresentationObject');

            let _facade = null;
            let _parent = null;
            let _voicemailBoxId = null;

            const DELETE_RESPONSE = "yes";
            const SUCCESS_RESPONSE = "success";

            const _getVoicemailGreetingsList = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    _facade.getCurrentGreetingId(_voicemailBoxId)
                        .fail(promise.reject)
                        .done(currentGreetingId => {
                            _facade.getVoicemailBoxGreetings(_voicemailBoxId)
                                .fail(promise.reject)
                                .done(voicemailGreetings => {
                                    let currentGreeting = voicemailGreetings.find(g => g.voicemailBoxGreetingId === currentGreetingId);
                                    if (!currentGreeting) {
                                        currentGreeting = voicemailGreetings[0];
                                    }
                                    currentGreeting.isDefault(true);

                                    _sorter.sort(voicemailGreetings, "name", true);

                                    self.greetings(voicemailGreetings);
                                    promise.resolve();
                                });
                        });
                });
            };

            const _sortGreetings = () => {
                _sorter.sort(self.greetings(), "name", true);
            };

            const _isGreetingNameUnique = (greetingId, name) => {
                const nameLowerCase = name.trim().toLowerCase();
                return _promiseFactory.defer((deferredObject) => {
                    let matchedGreetings = self.greetings().filter(voicemailGreeting => {
                        if (voicemailGreeting.isDeleted() === false) {
                            if (voicemailGreeting.isAddMode() || voicemailGreeting.isEditMode()) {
                                let editingGreetingName = voicemailGreeting.addVoicemailGreetingViewModel().name();
                                if (editingGreetingName.trim().toLowerCase() === nameLowerCase) {
                                    return true;
                                }
                            } else if (voicemailGreeting.name().trim().toLowerCase() === nameLowerCase) {
                                return true;
                            }
                        }
                        return false;
                    });

                    if (matchedGreetings.length === 1) {
                        deferredObject.resolve(true);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            };

            const _showDeleteGreeting = (greetingPresentationObject) => {
                _buildDeleteModal(greetingPresentationObject);
                self.actionModal.showModal()
                    .done((result) => {
                        if (result === DELETE_RESPONSE) {
                            self.removeVoicemailGreeting(greetingPresentationObject);
                        }
                    })
                    .fail((error) => {
                        throw error;
                    });
            };

            const _buildDeleteModal = (greeting) => {
                let constructorParams = [greeting];

                self.actionModal
                    .clearModal()
                    .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                    .setHeaderText({i18n: {key: 'deleteHeader', options: {type: "Greeting"}}})
                    .setSubmitButtonText({i18n: 'delete'});
            };

            const _onVoicemailBoxGreetingConverted = (voicemailBoxGreeting) => {
                const foundGreeting = self.greetings().find(g => g.voicemailBoxGreetingId === voicemailBoxGreeting.voicemailBoxGreetingId);
                if (foundGreeting) {
                    foundGreeting.greetingAudioObject(voicemailBoxGreeting.greetingAudioObject());
                }
            };

            const _removeEmptyGreetingsBeforeSave = () => {
                let emptyAddedGreetings = self.greetings().filter((greeting) => {
                    return greeting.file() === "" && greeting.name() === "";
                });
                self.greetings.removeAll(emptyAddedGreetings);
            };

            const _performSave = (voicemailGreetings) => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    _facade.saveVoicemailBoxGreetings(_voicemailBoxId, voicemailGreetings)
                        .fail(deferredObject.reject)
                        .done((result) => {
                            if (result.status === SUCCESS_RESPONSE) {
                                self.greetings(result.voicemailGreetings);
                                deferredObject.resolve();
                            }  else {
                                deferredObject.reject();
                            }
                        });
                });
            };

            self.modelStateObserver = null;
            self.greetings = ko.observableArray([]).extend({observeState: true});
            self.isValid = ko.observable(true);
            self.voicemailGreetingsNotDeleted = ko.pureComputed(() => self.greetings().filter(voicemailGreeting => !voicemailGreeting.isDeleted()));

            self.makeDefault = (voicemailGreeting) => {
                voicemailGreeting.showSpinner(true);
                setTimeout(() => {
                    self.greetings().forEach(greeting => {
                        greeting.isDefault(greeting.voicemailBoxGreetingId === voicemailGreeting.voicemailBoxGreetingId);
                    });
                    voicemailGreeting.showSpinner(false);
                }, 1000);

            };

            self.deleteVoicemailGreeting = (voicemailGreeting) => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    if (voicemailGreeting.isBuiltIn() || voicemailGreeting.isDefault()) {
                        promise.resolve();
                    } else {
                        _showDeleteGreeting(voicemailGreeting);
                        promise.resolve();
                    }
                });
            };

            self.removeVoicemailGreeting = (voicemailGreeting) => {
                if (voicemailGreeting.voicemailBoxGreetingId) {
                    voicemailGreeting.isDeleted(true);
                    voicemailGreeting.requiresUpdate = true;
                } else {
                    self.greetings.remove(voicemailGreeting);
                }
            };

            self.addVoicemailGreeting = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    let newVoicemailGreeting = new VoicemailGreetingsConstructor();
                    let addVoicemailGreetingViewModel = new AddVoicemailGreetingViewModelConstructor();
                    addVoicemailGreetingViewModel.activate()
                        .fail(promise.reject)
                        .done(() => {
                            addVoicemailGreetingViewModel.isNameUnique = _isGreetingNameUnique;
                            newVoicemailGreeting.addVoicemailGreetingViewModel(addVoicemailGreetingViewModel);
                            newVoicemailGreeting.isAddMode(true);

                            self.greetings.push(newVoicemailGreeting);
                            self.modelStateObserver.addChildObserverAndWatch(addVoicemailGreetingViewModel.modelStateObserver);
                            promise.resolve();
                        });
                });
            };

            self.editVoicemailGreeting = (voicemailGreeting) => {
                return _promiseFactory.defer((promise) => {
                    if (voicemailGreeting.isBuiltIn()) {
                        promise.resolve();
                    } else {
                        let editVoicemailGreetingViewModel = new AddVoicemailGreetingViewModelConstructor(voicemailGreeting.voicemailBoxGreetingId, voicemailGreeting.name(), voicemailGreeting.file(), voicemailGreeting.greetingAudioObject());
                        editVoicemailGreetingViewModel.activate()
                            .fail(promise.reject)
                            .done(() => {
                                editVoicemailGreetingViewModel.isNameUnique = _isGreetingNameUnique;
                                voicemailGreeting.addVoicemailGreetingViewModel(editVoicemailGreetingViewModel);
                                voicemailGreeting.isEditMode(true);
                                voicemailGreeting.requiresUpdate = true;
                                self.modelStateObserver.addChildObserverAndWatch(editVoicemailGreetingViewModel.modelStateObserver);
                                promise.resolve();
                            });
                    }
                });
            };

            self.cancelVoicemailGreeting = (voicemailGreeting) => {
                voicemailGreeting.addVoicemailGreetingViewModel().modelStateObserver.restoreData();
                if (voicemailGreeting.isAddMode()) {
                    self.greetings.remove(voicemailGreeting);
                    voicemailGreeting.isAddMode(false);
                } else {
                    voicemailGreeting.isEditMode(false);
                }

                let allVoicemailGreetings = self.greetings();
                let isValid = allVoicemailGreetings.every(
                    greeting => !greeting.isAddMode() && !greeting.isEditMode() || greeting.addVoicemailGreetingViewModel().validator.isValid()
                );
                self.isValid(isValid);
            };

            self.validateVoicemailGreeting = (voicemailGreeting) => {
                voicemailGreeting.addVoicemailGreetingViewModel().validate();
            };

            self.saveVoicemailGreeting = (voicemailGreeting) => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    let addVoicemailGreetingViewModel = voicemailGreeting.addVoicemailGreetingViewModel();
                    addVoicemailGreetingViewModel.validate()
                        .fail(deferredObject.reject)
                        .done((isValid) => {
                            if (isValid) {
                                voicemailGreeting.greetingId = addVoicemailGreetingViewModel.greetingId;
                                voicemailGreeting.name(addVoicemailGreetingViewModel.name());
                                voicemailGreeting.file(addVoicemailGreetingViewModel.greetingFile());
                                voicemailGreeting.greetingAudioObject = addVoicemailGreetingViewModel.currentVoicePrompt;
                                voicemailGreeting.isEditMode(false);
                                voicemailGreeting.isAddMode(false);
                                voicemailGreeting.requiresUpdate = true;
                                _sortGreetings();
                            }
                            deferredObject.resolve(isValid);
                        });
                });
            };

            self.cancelForm = () => {
                return _promiseFactory.defer((deferredObject) =>{
                    self.modelStateObserver.restoreData();
                    self.greetings().forEach((voicemailGreeting) => {
                        voicemailGreeting.isEditMode(false);
                    });
                    self.isValid(true);
                    deferredObject.resolve();
                });
            };

            self.validate = () => {
                return _promiseFactory.defer((promise) => {
                    const validateGreeting = (voicemailGreeting) => {
                        return _promiseFactory.defer(checkUnsavedFormsPromise => {
                            if (!voicemailGreeting.isEditMode() && !voicemailGreeting.isAddMode()) {
                                checkUnsavedFormsPromise.resolve(true);
                            } else {
                                self.saveVoicemailGreeting(voicemailGreeting)
                                    .fail(checkUnsavedFormsPromise.reject)
                                    .done(checkUnsavedFormsPromise.resolve);
                            }
                        });
                    };
                    _promiseFactory.deferredList(self.greetings(), validateGreeting)
                        .fail(promise.reject)
                        .done(areUnsavedFormsValid => {
                            const everyFormIsValid = areUnsavedFormsValid.every(isValid => isValid);
                            self.isValid(everyFormIsValid);
                            promise.resolve(everyFormIsValid);
                        });
                });
            };

            self.save = (voicemailBoxId) => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    if (voicemailBoxId !== undefined) {
                        _voicemailBoxId = voicemailBoxId;
                    }
                    _removeEmptyGreetingsBeforeSave();
                    self.validate()
                        .done((isValid) => {
                            if (isValid) {
                                _performSave(self.greetings())
                                    .fail(deferredObject.reject)
                                    .done(deferredObject.resolve);
                            } else {
                                deferredObject.resolve();
                            }
                        });
                });
            };

            self.disposeSubscriptions = () => {
                _facade.dispose();
            };

            self.activate = (voicemailBoxId) => {
                _voicemailBoxId = voicemailBoxId;

                const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
                self.actionModal = new ActionModalViewModelConstructor();

                const FacadeConstructor = require('presentation/settings/voicemailBoxes/facades/addVoicemailBoxGreetingsFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                return _initialize();
            };

            const _initialize = () => {
                self.modelStateObserver = new ModelStateObserverConstructor(self, true);

                _getVoicemailGreetingsList()
                    .done(self.modelStateObserver.commitData);

                _facade.onVoicemailBoxGreetingConverted(_onVoicemailBoxGreetingConverted);

                return _promiseFactory.wait();
            };
        };
    });

