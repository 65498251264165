define('presentation/settings/userProfile/validators/userProfileViewModelValidator',['common/promises/promiseFactory',
        'common/specifications/validEmailAddressSpecification',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        const _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _facade = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("firstName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'firstNameIsRequired');
            validationRules.field("lastName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'lastNameIsRequired');
            validationRules.field("emailAddress")
                .addValidationI18n(_commonValidator.isStringWithValue, 'emailAddressRequired')
                .addValidationI18n(_validEmailAddressSpecification.isSatisfiedBy, 'emailAddressInvalid')
                .addValidationI18n(_validateEmailAddressUnique, 'emailAddressUnique');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateEmailAddressUnique = (emailAddress) => {
            return _promiseFactory.defer((deferredObject) => {
                const originalEmailAddress = _viewModel.originalEmailAddress;
                if (originalEmailAddress === emailAddress.trim()) {
                    deferredObject.resolve(true);
                } else {
                    _facade.isEmailAddressUnique(emailAddress.trim())
                        .done(deferredObject.resolve)
                        .fail(deferredObject.reject);
                }
            });
        };
    };
});

