define('constants/validationMessageEnumerations',[],
    function() {
        var shared = {
            areaCodeRequired: "Please enter an area code.",
            areaCodeInvalid: "Please enter a valid area code.",
            devicePhoneNumberUnique: "Sorry, this phone number is already in use by another user's device in this account.",
            emailAddressInvalid: "Please enter a valid email address.",
            emailAddressNotFound: "Sorry, the email address you entered is not a registered email address. Please try again.",
            emailAddressRequired: "Please enter an email address.",
            emailAddressUnique: "Sorry, the email address you entered is already in use. Please try again." ,
            firstNameIsRequired: "Please enter a first name.",
            fileTooBig: "Sorry, the selected audio file is too large. File size cannot exceed 4 MB. Please try again.",
            invalidAudioFile: "Sorry, the selected file is not a valid audio file. Valid file types are MP3, WAV, WMA, and M4A and cannot exceed 4 MB.",
            lastNameIsRequired: "Please enter a last name.",
            titleIsRequired: "Please enter a title.",
            passwordConfirmRequired: "Please re-enter your password.",
            passwordDoesNotMatch: "Sorry, the passwords you entered do not match. Please try again.",
            passwordIncorrect: "Incorrect password. Please try again.",
            passwordInvalid: "Invalid password.  Passwords must contain 8-32 characters, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number.",
            passwordRequired: "Please enter a password. Passwords must contain 8-32 characters, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number.",
            postalCodeInvalid: "Please enter a valid postal code.",
            postalCodeRequired: "Please enter a postal code.",
            phoneNumberInvalid: "Please enter a valid phone number.",
            phoneNumberRequired: "Please enter a phone number.",
            phoneNumberRequiredAtLeastOne : "Please enter a phone number. At least one phone number is required.",
            restrictedPhoneNumber: "Must be a US or Canadian phone number.",
            verificationAnswerRequired: "Please enter a verification answer.",
            zipCodeInvalid: "Please enter a valid ZIP Code.",
            zipCodeRequired: "Please enter a ZIP Code.",
            deviceNameRequired : "Please enter a device name.",
            deviceNameUnique : "You have used this device name already.",
            extensionAlreadyInUse: "Sorry, this extension is already in use.",
            extensionInvalid: "Please select an extension.",
            deactivatedUser : "Your login has been deactivated. Please contact your System Admin or call us at +1 (844) 2-TRESTA."
        };

        return {
            clientui: {
                presentation: {
                    acceptUserInvite: {
                        createUserProfile: {
                            confirmPasswordMatch: "Passwords do not match.",
                            confirmPasswordRequired: "Please re-enter password.",
                            currentPasswordRequired: "To change your password, you must enter your current password.",
                            currentPasswordValid: "The password you entered does not match your current password.",
                            firstNameRequired: shared.firstNameIsRequired,
                            lastNameRequired: shared.lastNameIsRequired,
                            titleRequired: shared.titleIsRequired,
                            passwordRequired: shared.passwordRequired,
                            passwordValid: shared.passwordInvalid,
                            verificationAnswerRequired: shared.verificationAnswerRequired
                        },
                        createUserDevices: {
                            phoneNumberInvalid: shared.phoneNumberRequired,
                            phoneNumberRequired: shared.phoneNumberInvalid,
                            phoneNumberUnique: shared.devicePhoneNumberUnique,
                            nameRequired: shared.deviceNameRequired,
                            nameUnique: shared.deviceNameUnique,
                            restrictedPhoneNumber: shared.restrictedPhoneNumber,
                            integrityWarnings: {
                                maximumDevices: {
                                    title: "Maximum Devices",
                                    message: "You cannot add more than 5 devices"
                                },
                                duplicate_phone_number: {
                                    title: "Duplicate Phone Number",
                                    message: "Sorry, this phone number is in use by another user"
                                },
                                last_device: {
                                    title: "Last Device",
                                    message: "You cannot remove your last device"
                                },
                                restricted_number: {
                                    title: "Restricted Number",
                                    message: shared.restrictedPhoneNumber
                                }
                            }
                        }
                    },
                    accountRedirects: {
                        modalPayBalance: {
                            customerAgreesToPay: "REQUIRED",
                            paymentMethodExpired: "Your payment method is expired."
                        }
                    },
                    analytics: {
                        sidebar: {
                            invalidStartDate: "Please enter a valid date.",
                            invalidEndDate: "Please enter a valid date."
                        }
                    },
                    common: {
                        voicemailGreeting: {
                            addVoicemailGreeting: {
                                nameRequired: "Please enter a name.",
                                nameNotUnique: "Sorry, the name you entered is already in use by another voicemail greeting. Please try again.",
                                greetingAudioUploadRequired: "Please select an audio file.",
                                greetingAudioVoicemailRequired: "Please record a greeting.",
                                greetingAudioUploadTypeInvalid: shared.invalidAudioFile,
                                greetingAudioUploadSizeExceedsMax: shared.fileTooBig
                            },
                            integrityWarnings: {
                                duplicate_name: {
                                    title: "Duplicate Greeting Name",
                                    message: "Sorry, the name you entered is already in use by another voicemail greeting. Please try again."
                                },
                                maximum_greetings:{
                                    title: "Maximum Greetings",
                                    message:  "You have reached the maximum number of greetings that can be added to a voicemail. To add an additional greeting, you must delete an existing greeting."
                                }

                            }
                        },
                        paymentMethod: {
                            cardHolderNameRequired: "Please enter the cardholder's name.",
                            cardNumberInvalid: "Please enter a valid American Express, Discover, Mastercard, or Visa card number.",
                            cardNumberRequired: "Please enter a card number.",
                            countryRequired: "Please select a country.",
                            cvvSecurityCodeInvalid: "Please enter a valid CVV.",
                            cvvSecurityCodeRequired: "Please enter the CVV.",
                            duplicateCard: "Sorry, this card is already on your account. Either use the edit feature to change the card or enter a different payment method.",
                            expirationDateInvalid: "Please enter a valid expiration date.",
                            expiredCard: "Your payment method is expired.",
                            invalidCard: "Sorry, this is not a valid card. Check the card information and try again.",
                            invalidConnection: "Sorry, having trouble authorizing card. Please try again later, or call customer service for assistance.",
                            paymentDeclined: "Sorry, your credit card was declined. Please verify your card information and try again, or provide a new card.",
                            postalCodeInvalid: shared.postalCodeInvalid,
                            postalCodeRequired: shared.postalCodeRequired,
                            zipCodeInvalid: shared.zipCodeInvalid,
                            zipCodeRequired: shared.zipCodeRequired
                        },
                        routeToModal: {
                            routeToAutoAttendant: {
                                noneSelected: "Please select a different route to option."
                            },
                            routeToUser: {
                                userNameRequired: "Please enter a user name.",
                                userInvalid: "Sorry, the user you selected is inactive. Please select an active user."
                            }
                        },
                        emailAddressRequired: shared.emailAddressRequired,
                        emailAddressInvalid: shared.emailAddressInvalid,
                        emailAddressAlreadyInUse: shared.emailAddressUnique,
                        emailAddressNotRegistered: shared.emailAddressNotFound,
                        emailAddressInvitePending: "In order to log in, you must first configure your Tresta account by accepting the invite that was sent via email.",
                        emailAddressInviteExpired: "Sorry, you can't login until you have accepted the invite that was sent to your email."
                    },
                    forgotPassword: {
                        forgotPassword: {
                            emailAddressRequired: shared.emailAddressRequired,
                            emailAddressInvalid: shared.emailAddressInvalid,
                            emailAddressUnique: shared.emailAddressNotFound,
                            emailAddressNotRegistered: shared.emailAddressNotFound,
                            emailAddressInvitePending: "Sorry, you can't login until you have accepted the invite that was sent to your email. Can't find the invite?  Click Here to have it resent.",
                            emailAddressInviteExpired: "Sorry, you can't login until you have accepted the invite that was sent to your email.",
                            emailAddressInviteCanceled: shared.emailAddressNotFound,
                            userDeactivated : shared.deactivatedUser
                        },
                        resetPassword: {
                            passwordInvalid: shared.passwordInvalid,
                            passwordConfirmRequired: shared.passwordConfirmRequired,
                            passwordConfirmDoesNotMatch: shared.passwordDoesNotMatch
                        }
                    },
                    login: {
                        login: {
                            usernameRequired: "Please enter an email address.",
                            usernameInvalid: "This email address is not associated with a Tresta account.",
                            emailAddressInvalid: "Please enter a valid email address.",
                            passwordRequired: "Please enter a password.",
                            passwordInvalid: "Incorrect password.",
                            invitePending: "You must accept your invite before you can log in. Resend Invite",
                            inviteExpired: "Your invite was never accepted and is now expired.",
                            userDeactivated : "This user has been deactivated."
                        }
                    },
                    reactivation: {
                        reactivationPhoneNumbersSection: {
                            selectedPhoneNumberRequired: "Please select a phone number."
                        },
                        termsConditionsRequired: "Required",
                        messageBox: {
                            plural: "The {0} sections above are not completed. Please review these sections, and try again.",
                            singular: "The {0} section above is not completed. Please review this section, and try again."
                        }
                    },
                    settings: {
                        billing: {
                            paymentMethod: {
                                oneTimePaymentModal: {
                                    oneTimePaymentAmountRequired: "Please enter a payment amount.",
                                    oneTimePaymentAmountNotNumeric: "Please enter only numbers for the payment amount.",
                                    oneTimePaymentAmountAboveMax: "Sorry, one-time payments cannot exceed $10,000. Please try again with a payment amount of $10,000 or less.",
                                    oneTimePaymentAmountBelowMin: "Sorry, the amount you entered is invalid. Please try again with a payment amount of $0.01 or more.",
                                    oneTimePaymentAmountGreaterThanBalanceDue: "Sorry, the amount you entered is greater than the balance owed. Please try again."
                                }
                            }
                        },
                        autoattendants: {
                            addOrSelectPrompt: "Please add or select an audio file",
                            uniqueNameRequired: "Sorry, the name you entered is already in use by another auto attendant. Please try again.",
                            autoAttendantNameRequired: "Please enter an auto attendant name.",
                            menuRoutesToAtLeastOneEndpoint: "Please assign at least one key to route to any of the available route to options.",
                            subMenuRoutesToAtLeastOneEndpoint: "One or more submenus results in a dead-end route. Please assign at least one key to route to any of the available route to options.",
                            voicePromptMissingOnMenu: "One or more submenus is missing the audio file required to inform callers of the available key-press options.",
                            voicePromptRequired: "Please assign an audio file."
                        },
                        connectors: {
                            addConnector: {
                                areaCodeRequired: shared.areaCodeRequired,
                                areaCodeValid: shared.areaCodeInvalid,
                                selectedPhoneNumbersRequired: "Please select at least one phone number.",
                                customerAgreeWithIncreasedChargesRequired: "REQUIRED",
                                phoneNumberRequired: shared.phoneNumberRequired,
                                phoneNumberLength: shared.phoneNumberInvalid,
                                restrictedPhoneNumber: shared.restrictedPhoneNumber,
                                phoneNumberOnThisAccount: "Sorry, this phone number is already on this account.",
                                phoneNumberOnAnotherAccount: "Sorry, this phone number is connected to another account.",
                                carrierRequired: "Please provide the current carrier.",
                                billingNameRequired: "Please enter the name on file with the carrier",
                                billingNumberRequired: "Please enter the phone number on file with the carrier.",
                                billingAddressRequired: "Please enter the address on file with the carrier.",
                                billingCityRequired: "Please enter the city on file with the carrier.",
                                postalCodeInvalid: shared.postalCodeInvalid,
                                postalCodeRequired: shared.postalCodeRequired,
                                zipCodeInvalid: shared.zipCodeInvalid,
                                zipCodeRequired: shared.zipCodeRequired,
                                portDateRequired: "Please enter the date to port your number(s).",
                                authorizedSignature: "Please enter your signature as owner or authorized representative of requested numbers."

                            },
                            recording: {
                                notificationDisclaimerRequired: "Sorry, to continue you must agree to the notification disclaimer or return the notification audio file to the default item.",
                                outboundCallRecordingDisclaimerRequired: "To continue, you must agree to the notification disclaimer."
                            },
                            uniqueNameRequired: "Sorry, the name you entered is already in use by another phone number. Please try again."
                        },
                        extensions: {
                            assignExtension: {
                                assignToRequired: shared.extensionInvalid
                            }
                        },
                        forwardingNumbers: {
                            addForwardingNumbers: {
                                forwardingNumberNameRequired: "Please enter a forwarding number name.",
                                forwardingNumberNameUnique: "Sorry, the name you entered is already in use by another forwarding number. Please try again.",
                                forwardingNumberCountryRequired: "Please select a country.",
                                forwardingNumberPhoneNumberRequired: shared.phoneNumberRequired,
                                forwardingNumberPhoneNumberRestricted: shared.restrictedPhoneNumber,
                                forwardingNumberPhoneNumberLength: shared.phoneNumberInvalid,
                                forwardingNumberExtensionRequired: shared.extensionInvalid,
                                forwardingNumberExtensionUnique: shared.extensionAlreadyInUse
                            },
                            voicemail: {
                                answerTimeoutRequired: "Please select an answer timeout option for your voicemail.",
                                greetingRequired: "Please select a voicemail greeting."
                            }
                        },
                        prompts: {
                            addPrompts: {
                                promptNameRequired: "Please enter an audio file name.",
                                promptNameInvalid: "Please enter a valid audio file name.",
                                promptAudioUploadRequired: "Please select an audio file.",
                                promptAudioRecordingRequired: "Please record an audio file.",
                                promptAudioUploadTypeInvalid: shared.invalidAudioFile,
                                promptAudioUploadSizeExceedsMax: shared.fileTooBig,
                                promptNameNotUnique: "Sorry, the name you entered is already in use by another audio file. Please try again.",
                                promptNameNotLocallyUnique: "Sorry, the name you entered is already in use by another new audio file. Please try again.",
                            }
                        },
                        schedules: {
                            addSchedule : {
                                nameRequired : "Please enter a schedule name.",
                                nameNotUnique: "Sorry, the name you entered is already in use by another schedule. Please try again.",
                                routingRuleError: "Please make a selection."
                            },
                            scheduleRange: {
                                rangeNameRequired: "Please enter a name for this range.",
                                selectedDayRequired: "Please select at least one day for this range.",
                                timeSelectionsInvalid: "The start time must occur before the end time.",
                                segmentsShouldNotOverlap: "Schedule ranges cannot overlap.",
                                rangeNameMustBeUnique: 'Sorry, the name you entered is already in use by another range.',
                                routingRuleError: "Please make a selection."
                            }
                        },
                        voicemailBoxes: {
                            addVoicemailBox : {
                                nameRequired : "Please enter a voicemail box name.",
                                nameNotUnique: "Sorry, the name you entered is already in use by another voicemail box. Please try again.",
                            }
                        },
                        userGroups: {
                            addUserGroup: {
                                nameRequired: "Please enter a group name.",
                                nameNotUnique: "User group name is already in use.",
                                membersRequired: "Please select at least one member",
                                extensionAlreadyInUse: shared.extensionAlreadyInUse,
                                extensionInvalid: shared.extensionInvalid
                            }
                        },
                        users: {
                            addUsers: {
                                emailAddressRequired: shared.emailAddressRequired,
                                emailAddressInvalid: shared.emailAddressInvalid,
                                emailAddressDuplicate: "You’ve already provided this email in the invite list.",
                                emailAddressNotUnique: "Email address is already in use.",
                                extensionAlreadyInUse: shared.extensionAlreadyInUse,
                                extensionInvalid: shared.extensionInvalid
                            },
                            editUser: {
                                firstNameRequired: shared.firstNameIsRequired,
                                lastNameRequired: shared.lastNameIsRequired,
                                titleRequired: shared.titleIsRequired,
                                emailAddressRequired: shared.emailAddressRequired,
                                emailAddressInvalid: shared.emailAddressInvalid,
                                emailAddressUnique: "Email address is already in use.",
                                contactPhoneNumberRequired: shared.phoneNumberRequired,
                                contactPhoneNumberRequiredAtLeastOne: shared.phoneNumberRequiredAtLeastOne,
                                contactPhoneNumberValid: shared.phoneNumberInvalid,
                                restrictedPhoneNumber: shared.restrictedPhoneNumber,
                                messageBox: {
                                    plural: "Sorry, {0} have missing or invalid fields.",
                                    singular: "Sorry, {0} has missing or invalid fields."
                                },
                                extensionAlreadyInUse: shared.extensionAlreadyInUse
                            }
                        },
                        userDevices: {
                            deviceNameRequired: shared.deviceNameRequired,
                            deviceNameUnique: shared.deviceNameUnique,
                            devicePhoneNumberRequired: shared.phoneNumberRequired,
                            devicePhoneNumberValid: shared.phoneNumberInvalid,
                            devicePhoneNumberUnique: shared.devicePhoneNumberUnique,
                            restrictedPhoneNumber: shared.restrictedPhoneNumber,
                            integrityWarnings: {
                                maximumDevices: {
                                    title: "Maximum Devices",
                                    message: "You cannot add more than 5 devices"
                                },
                                duplicate_phone_number: {
                                    title: "Duplicate Phone Number",
                                    message: "Sorry, this phone number is in use by another user"
                                },
                                last_device: {
                                    title: "Last Device",
                                    message: "You cannot remove your last device"
                                },
                                restricted_number: {
                                    title: "Restricted Number",
                                    message: shared.restrictedPhoneNumber
                                }
                            }
                        },
                        system: {
                            accountProfile: {
                                accountHolderFirstName : shared.firstNameIsRequired,
                                accountHolderLastName : shared.lastNameIsRequired,
                                businessNameRequired: "Please enter a business name.",
                                addressLineOneRequired: "Please enter an address.",
                                cityRequired: "Please enter a city.",
                                postalCodeRequired: shared.postalCodeRequired,
                                postalCodeValid: shared.postalCodeInvalid,
                                zipcodeRequired: shared.zipCodeRequired,
                                zipcodeValid: shared.zipCodeInvalid,
                                howYouWillUseTrestaRequired: "Please select either Business or Personal.",
                                businessSinceValid: "Please enter the four digit year your business was founded.",
                                industryValid: "Please select an industry.",
                                emailAddressRequired: shared.emailAddressRequired,
                                emailAddressInvalid: shared.emailAddressInvalid,
                                emailAddressUnique: "Email address is already in use.",
                                contactPhoneNumberRequired: shared.phoneNumberRequired,
                                contactPhoneNumberRequiredAtLeastOne: shared.phoneNumberRequiredAtLeastOne,
                                contactPhoneNumberValid: shared.phoneNumberInvalid,
                                restrictedPhoneNumber: shared.restrictedPhoneNumber,
                            }
                        },
                        userProfile: {
                            changePasswordModal: {
                                currentPasswordRequired: "To change your password, you must enter your current password.",
                                currentPasswordValid: "Sorry, the password you entered does not match your current password. Please try again.",
                                newPasswordRequired: shared.passwordRequired,
                                newPasswordValid: shared.passwordInvalid,
                                confirmPasswordRequired: shared.passwordConfirmRequired,
                                confirmPasswordMatch: shared.passwordDoesNotMatch
                            },
                            confirmPasswordModal: {
                                passwordRequired: "Please enter your current password.",
                                passwordInvalid: "Sorry, the password you entered does not match your current password. Please try again."
                            },
                            changeVerificationQAModal: {
                                verificationAnswerRequired: shared.verificationAnswerRequired
                            },
                            userProfile: {
                                firstNameRequired: shared.firstNameIsRequired,
                                lastNameRequired: shared.lastNameIsRequired,
                                titleRequired: shared.titleIsRequired,
                                emailAddressRequired: shared.emailAddressRequired,
                                emailAddressInvalid: shared.emailAddressInvalid,
                                emailAddressUnique: shared.emailAddressUnique,
                                contactPhoneNumberRequired: shared.phoneNumberRequired,
                                contactPhoneNumberRequiredAtLeastOne: shared.phoneNumberRequiredAtLeastOne,
                                contactPhoneNumberValid: shared.phoneNumberInvalid,
                                restrictedPhoneNumber: shared.restrictedPhoneNumber,
                                extensionAlreadyInUse: shared.extensionAlreadyInUse
                            }
                        },
                        user : {
                            voicemail: {
                                subscribersRequired: "Please choose at least one subscriber.",
                                greetingRequired: "Please select a voicemail greeting."
                            }
                        }
                    },
                    signup: {
                        signupAccountInfoSection : {
                            emailAddressRequired: "Please enter an email address.",
                            emailAddressInvalid: "Please enter a valid email address.",
                            emailAddressUnique: "This email address is already in use on another Tresta account.",
                            passwordInvalid: "Incorrect password.",
                            passwordConfirmRequired: shared.passwordConfirmRequired,
                            passwordConfirmDoesNotMatch: shared.passwordDoesNotMatch,
                            termsConditionsAgreement: "Required",
                            passwordNotProvided : "Please enter a password."
                        },
                        signupPhoneNumbersSection : {
                            phoneNumberRequired: "Please select a number.",
                            areaCodeRequired: shared.areaCodeRequired,
                            areaCodeValid: shared.areaCodeInvalid
                        },
                        signupPhoneNumbersPortingSection : {
                            phoneNumberRequired: shared.phoneNumberRequired,
                            phoneNumberLength: shared.phoneNumberInvalid,
                            phoneNumberOnAnotherAccount: "This phone number is already associated with a Tresta account.",
                            phoneNumberNotPortable: "This phone number cannot be ported into Tresta.",
                            carrierRequired: "Please select your current carrier.",
                            billingNameRequired: "Please enter a name.",
                            billingNumberRequired: "Please enter a phone number.",
                            billingAddressRequired: "Please enter an address.",
                            billingCityRequired: "Please enter a city.",
                            postalCodeInvalid: shared.postalCodeInvalid,
                            postalCodeRequired: shared.postalCodeRequired,
                            restrictedPhoneNumber: shared.restrictedPhoneNumber,
                            zipCodeInvalid: shared.zipCodeInvalid,
                            zipCodeRequired: shared.zipCodeRequired,
                            portDateRequired: "Please specify your desired port date.",
                            authorizedSignature: "Authorized signature is required",
                        },
                        messageBox: {
                            plural: "The {0} sections above are not completed. Please review these sections, and try again.",
                            singular: "The {0} section above is not completed. Please review this section, and try again."
                        },
                    },
                    userProfile: {
                        editUserProfileModal: {
                            firstNameRequired: shared.firstNameIsRequired,
                            lastNameRequired: shared.lastNameIsRequired,
                            emailAddressRequired: shared.emailAddressRequired,
                            emailAddressInvalid: shared.emailAddressInvalid,
                            emailAddressUnique: shared.emailAddressUnique,
                            newPasswordInvalid: shared.passwordInvalid,
                            passwordConfirmRequired: shared.passwordConfirmRequired,
                            passwordConfirmDoesNotMatch: shared.passwordDoesNotMatch,
                            currentPasswordRequiredEmail: "To change your email address, you must enter your current password.",
                            currentPasswordRequiredPassword: "To change your password, you must enter your current password.",
                            currentPasswordInvalid: "Sorry, the password you entered does not match your current password. Please try again.",
                            userVerificationAnswerRequired: shared.verificationAnswerRequired,
                            userAddressLineOneRequired: "Please enter an address.",
                            userCityRequired: "Please enter a city.",
                            userZipcodeRequired: shared.zipCodeRequired,
                            userZipcodeValid: shared.zipCodeInvalid,
                            userPostalcodeRequired: shared.postalCodeRequired,
                            userPostalcodeValid: shared.postalCodeInvalid,
                            userPhoneNumberRequired: "Please select a default phone number.",
                            otherContactInfoPhoneNumberRequired: shared.phoneNumberRequired,
                            otherContactInfoPhoneNumberRequiredAtLeastOne: shared.phoneNumberRequiredAtLeastOne,
                            otherContactInfoPhoneNumberValid: shared.phoneNumberInvalid,
                            otherContactInfoEmailAddressValid: shared.emailAddressInvalid,
                            otherAuthorizedContactsFirstNameRequired: shared.firstNameIsRequired,
                            otherAuthorizedContactsLastNameRequired: shared.lastNameIsRequired,
                            otherAuthorizedContactsEmailAddressRequired: shared.emailAddressRequired,
                            otherAuthorizedContactsEmailAddressValid: shared.emailAddressInvalid,
                            otherAuthorizedContactsPhoneNumberRequired: shared.phoneNumberRequired,
                            otherAuthorizedContactsPhoneNumberValid: shared.phoneNumberInvalid,
                            otherAuthorizedContactsVerificationAnswerRequired: shared.verificationAnswerRequired,
                            restrictedPhoneNumber: shared.restrictedPhoneNumber
                        }
                    }
                }
            },
            webcrm: {
                presentation: {
                    account: {
                        activity: {
                            email: {
                                emailRequired: shared.emailAddressRequired,
                                templateRequired: "Please select a template.",
                                subjectRequired: "Please enter a subject.",
                                contentRequired: "Please enter a body.",
                                notationCommentsRequired: "Please enter a notation comment."
                            },
                            addNotation: {
                                eventRequired: "Please select an event.",
                                startOnRequired: "Please enter a valid start on date and time.",
                                dueOnRequired: "Please enter a valid due on date and time.",
                                commentRequired: "Please enter a comment."
                            },
                            updateNotation: {
                                originalNotationStartOnRequired: "Please enter a valid start on date and time.",
                                originalNotationDueOnRequired: "Please enter a valid due on date and time.",
                                eventRequired: "Please select an event.",
                                commentRequired: "Please enter a comment."
                            }
                        }
                    },
                    developer: {
                        numberManagement: {
                            accountNumberRequired: "Please enter an account number.",
                            accountNumberInvalid: "Sorry, the account number you entered is invalid.",
                            accountNumberNotUnique: "Please enter a different account number than the one you are moving numbers from.",
                        },
                        fraudSettings: {
                            required: "Please enter a number between 0 and 100."
                        }
                    },
                    forgotPassword: {
                        emailAddressRequired: shared.emailAddressRequired,
                        emailAddressValid: shared.emailAddressInvalid,
                        emailAddressNotFound: shared.emailAddressNotFound
                    },
                    login: {
                        usernameRequired: shared.emailAddressRequired,
                        passwordRequired: shared.passwordRequired,
                        passwordValid: shared.passwordIncorrect,
                        usernameValid: shared.emailAddressInvalid,
                        usernameEnabled: "This login has been disabled."
                    },
                    marketing: {
                        editResource: {
                            addNewTopicRequired: "Please enter a topic.",
                            titleRequired: "Please enter a title.",
                            urlUnique: "URL is already in use.",
                            fileRequired: "Please select a file.",
                            thumbnailRequired: "Please upload a valid image.",
                            thumbnailValid: "Selected file is not a valid image file. Valid file types are GIF, JPG, JPEG, and PNG and cannot exceed 4MB.",
                            thumbnailFileSizeValid: "Selected image file is too large. File size cannot exceed 4MB.",
                            descriptionRequired: "Please enter a description",
                            downloadMessageRequired: "Please enter the message that will display in the download box of the resource page.",
                            wistiaUrlRequired: "Please enter a valid Wistia URL."
                        },
                        addEmailRecipient: {
                            emailAddressRequired: shared.emailAddressRequired,
                            emailAddressInvalid: shared.emailAddressInvalid,
                        },
                    },
                    resetPassword: {
                        passwordValid: shared.passwordInvalid,
                        passwordConfirmRequired: shared.passwordConfirmRequired,
                        passwordConfirmValid: shared.passwordDoesNotMatch
                    },
                    settings: {
                        addBlacklistPhoneNumber: {
                            phoneNumberRequired: shared.phoneNumberRequired,
                            phoneNumberInvalid: shared.phoneNumberInvalid
                        },
                        addEmployee: {
                            firstNameRequired: shared.firstNameIsRequired,
                            lastNameRequired: shared.lastNameIsRequired,
                            emailAddressRequired: shared.emailAddressRequired,
                            emailAddressInvalid: shared.emailAddressInvalid,
                            emailAddressUnique: shared.emailAddressUnique,
                            emailAddressInvalidDomain: "Email address must be in the patlive.com or tresta.com domain.",
                            passwordRequired: shared.passwordRequired,
                            passwordInvalid: shared.passwordInvalid,
                            passwordConfirmRequired: shared.passwordConfirmRequired,
                            passwordConfirmDoesNotMatch: shared.passwordDoesNotMatch,
                            employeeNumberRequired: "Please enter an employee number.",
                            employeeNumberInvalid: "Sorry, the employee number you entered is invalid. Employee numbers must be 4 characters and can only contain alphanumeric characters.",
                            employeeNumberUnique: "Sorry, the employee number you entered is already in use. Please try again."
                        },
                        addNotationCategory: {
                            notationCategoryDescriptionRequired: "Please enter a description.",
                            notationCategoryDescriptionUnique: "Sorry, a category with a matching description already exists in this system. Please try again."
                        },
                        addNotationEvent: {
                            notationCategoryRequired: "Please select a category from the list.",
                            notationEventDescriptionRequired: "Please enter an event name.",
                            notationEventDescriptionUnique: "An event with a matching name and category already exists in this system."
                        },
                        potentialCustomer: {
                            accountNumberRequired: "Please enter an account number.",
                            accountNumberInvalid: "Sorry, the account number you entered is invalid. Please try again."
                        },
                        addNotationReport: {
                            reportNameRequired: "Please enter a report name.",
                            reportNameUnique: "Report name is already in use.",
                            reportNameIsReserved: "This report name is reserved.",
                            categoryEventRequired: "Please select at least one event."
                        },
                        emailNotifications : {
                            emailInvalid : "Please enter a Tresta or PATLive email address; or leave this entry blank to not receive notifications."
                        }
                    },
                    sidebar: {
                        editAccountStatus: {
                            accountStatusCannotBeChangedToActive: "The account status cannot be changed from 'Closed' to 'Active'.",
                            billingStatusInvalidForActiveAccount: "The billing status selected is invalid for an active account.",
                            reasonForCancellationInvalidForActiveAccount: "The reason for cancellation must be empty for an active account.",
                            cancellationCommentInvalidForActiveAccount: "The cancellation comment must be empty for an active account.",
                            reasonForCancellationInvalidForClosedAccount: "A reason for cancellation must be selected for a closed account.",
                            fraudStatusInvalidForFraudulentAccount: "Fraud status is invalid for a fraudulent account.",
                            cancellationCommentRequired: "A cancellation comment is required.",
                            closeAccountVerification: "Are you sure you want to close this account?",
                            billingStatusOKToCCDecline: "The billing status cannot be changed from 'OK' to 'CC Declined'.",
                            billingStatusCCDeclineToOK: "The billing status cannot be changed from 'CC Decline' to 'OK'."
                        },
                        vanityAdd: {
                            phoneNumberRequired: shared.phoneNumberRequired,
                            phoneNumberInvalid: shared.phoneNumberInvalid,
                            restrictedPhoneNumber: shared.restrictedPhoneNumber,
                            phoneNumberDoesNotExist: "Sorry, this phone number does not exist",
                            phoneNumberOnAnotherAccount: "Sorry, this phone number is connected to another account.",
                            phoneNumberOnThisAccount: "Sorry, this phone number is already on this account."
                        },
                       retireNumber: {
                            phoneNumberRequired: shared.phoneNumberRequired,
                            phoneNumberInvalid: shared.phoneNumberInvalid,
                            phoneNumberOnThisAccount: "Sorry, this phone number is not on this account."
                        }
                    },
                    release: {
                        addFeatureToAccount: {
                            accountIdRequired: "Please enter an account id (guid without dashes).",
                            accountIdInvalid: "Account not found. Please enter a valid account id (guid without dashes)."
                        }
                    }
                }
            },
            commonPresentation: {
                croppableImage: {
                    avatarTypeInvalid: "Selected file is not a valid image file. Valid file types are GIF, JPG, JPEG, and PNG.",
                }
            }
        };
});

