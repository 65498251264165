define('persistence/repositories/messagingComplianceRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], function (
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('externalDependencies/clientWebSocket') */
    ClientWebSocket
) {
    const APPLICATION_NAME = `messagingCompliance`;

    return function() {
        /** @type { IMessagingComplianceRepository } */
        const self = this;

        const _promiseFactory = new PromiseFactory();

        const _messagingComplianceSocket = ClientWebSocket.forApplication(APPLICATION_NAME);

        /**
         * @template T
         * @param { string } eventName
         * @param { (event: T) => void } callBack
         */
        const _subscribe = (eventName, callBack) => {
            _messagingComplianceSocket.subscribeToEvent(eventName, callBack);
        };

        /**
         * @template TOut
         * @param { string } command
         * @param { { [key: string]: any } } parameters
         * @returns { JQuery.Deferred<TOut?> }
         */
        const _send = (command, parameters) => {
            return _promiseFactory.deferIndefinitely((/** @type { JQuery.Deferred<TOut?> } */deferred) => {
                _messagingComplianceSocket.send(command, parameters || {}, (/** @type { TOut? } */ response, /** @type { any } */ errMsg) => {
                    if (errMsg) {
                        deferred.reject(new Error(errMsg));
                        return;
                    }

                    deferred.resolve(response);
                });
            });
        };

        /** @type { IMessagingComplianceRepository["getMessagingCompliance"] } */
        self.getMessagingCompliance = () => {
            return _send('retrieve', {});
        };

        /** @type { IMessagingComplianceRepository["updateMessagingCompliance"] } */
        self.updateMessagingCompliance = (updateRequest) => {
            return _send('update', updateRequest);
        };

        /** @type { IMessagingComplianceRepository["getAccountBrandStatus"] } */
        self.getAccountBrandStatus = () => {
            return _send('getAccountBrandStatus', {});
        };

        /** @type { IMessagingComplianceRepository["getAccountMessagingStatus"] } */
        self.getAccountMessagingStatus = () => {
            return _send('getAccountMessagingStatus', {});
        };

        /** @type { IMessagingComplianceRepository["registerSoleProprietorBrand"] } */
        self.registerSoleProprietorBrand = () => {
            return _send('registerSoleProprietorBrand', {});
        };

        /** @type { IMessagingComplianceRepository["resubmitBrandForAccount"] } */
        self.resubmitBrandForAccount = () => {
            return _send('resubmitBrandForAccount', {});
        };

        /** @type { IMessagingComplianceRepository["requestSmsPin"] } */
        self.requestSmsPin = () => {
            return _send('requestSmsPin', {});
        };

        /** @type { IMessagingComplianceRepository["submitSmsPin"] } */
        self.submitSmsPin = (submitRequest) => {
            return _send('submitSmsPin', submitRequest );
        };

        //#endregion

        //#region Subscriptions

        /** @type { IMessagingComplianceRepository["onBrandStatusUpdated"] } */
        self.onBrandStatusUpdated = (callBack) => {
            _subscribe(`brandStatusUpdated`, callBack);
        };

        /** @type { IMessagingComplianceRepository["onCampaignStatusUpdated"] } */
        self.onCampaignStatusUpdated = (callBack) => {
            _subscribe(`campaignStatusUpdated`, callBack);
        };

        //#endregion

        /** @type { IMessagingComplianceRepository["dispose"] } */
        self.dispose = () => {
            _messagingComplianceSocket.disposeOfEvents();
        };
    };
});
