define('entities/routingRuleEntity',[],function() {
    return function(){
        const self = this;

        self.routingRuleId = null;
        self.accountId = null;
        self.orderOfPrecedence = 1;
        self.routingType = null;
        self.routeToUserId = null;
        self.routeToUserGroupId = null;
        self.routeToForwardingNumberId = null;
        self.routeToScheduleId = null;
        self.routeToVoicemailBoxId = null;
        self.routeToVoicemailBoxGreetingId = null;
        self.routeToAutoAttendantId = null;
        self.routeToSubMenu = null;
        self.routeToVoicePromptId = null;
        self.routeToSystemAudioClipId = null;
        self.routingRuleExitConditions = [];
    };
});

