define('presentation/common/modal',[
    'plugins/dialog',
    'durandal/system'
],
function(dialog, system) {
    return function() {
        /** @type typeof import('presentation/common/modal') */
        const self = this;

        let lastModalViewModel = null;

        self.showModal = function(viewModel){
            var activeViewModel = system.getModuleId(viewModel);
            if (lastModalViewModel !== activeViewModel) {
                lastModalViewModel = activeViewModel;
                dialog.show(viewModel).then(function() {
                    if (lastModalViewModel === activeViewModel) {
                        lastModalViewModel = null;
                    }
                });
            }
        };

        self.closeModal = function(viewModel){
            dialog.close(viewModel);
        };
    };
});

