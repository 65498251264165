define('constants/autoAttendantVoiceRouteEnumerations',[], function() {
    return {
        autoAttendant : "autoAttendant",
        directory : "directory",
        forwardingNumber : "forwardingNumber",
        pleaseSelect: "pleaseSelect",
        prompt : "prompt",
        routingRule: "routingRule",
        schedule : "schedule",
        subMenu : "subMenu",
        user : "user",
        userGroup : "userGroup",
        voicemailBox : "voicemailBox",
    };
});
