define('presentation/common/routeToModal/viewModels/routeToSubmenuViewModel',[
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/inlinePromptDefaultOptionEnumerations',
    'settings/navigationConfiguration'
], function() {
    return function(initialRoutedToId, initialRouteToType, routingRuleExitConditions, validateRouteToViewModelCallback) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        const _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _commonState = require('common/storage/commonState');
        const _inlinePromptEnumerations = require('constants/inlinePromptDefaultOptionEnumerations');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _i18n = require('i18next');

        let _disposables = [];

        const _promptSelectionChanged = () => {
            const selectedPrompt = self.selectedPrompt();

            if (selectedPrompt.id === _inlinePromptEnumerations.SELECT_PROMPT.id) {
                self.selectedPromptAudio(_inlinePromptEnumerations.SELECT_PROMPT);
            } else {
                const promptAudioObject = selectedPrompt.isSystemPrompt ?
                    _voicePromptAudioFactory.getSystemVoicePrompt(selectedPrompt.id) :
                    _voicePromptAudioFactory.getVoicePrompt(selectedPrompt.id);

                promptAudioObject.duration = ko.pureComputed(() => selectedPrompt.durationInMilliseconds() / 1000);

                self.selectedPromptAudio(promptAudioObject);
            }
        };

        self.availablePrompts = ko.pureComputed(() => {
            let availablePrompts = _commonState.prompts().filter((prompt) => {
                return prompt.isSystemPrompt === false;
            }).concat([]);
            const sorter = new SorterConstructor();
            sorter.sort(availablePrompts, "name", true);
            return availablePrompts;
        });

        self.addPromptUrl = _navigationConfiguration.routesById.newPrompt.url;
        self.emptyMessage = _inlinePromptEnumerations.SELECT_PROMPT.name;
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.selectedPrompt = ko.observable().extend({observeState: true});
        self.selectedPromptAudio = ko.observable();
        self.hasAudioFiles = ko.pureComputed(() => self.availablePrompts().length > 0);
        self.isSelectPrompt = ko.pureComputed(() => (self.selectedPrompt() !== undefined) && (self.selectedPrompt().id === _inlinePromptEnumerations.SELECT_PROMPT.id));
        self.isValid = ko.pureComputed(() => !self.isSelectPrompt());

        self.createAudioFileLinkText = _i18n.t('routeToPrompt:createAudioFile');
        self.noAudioFilesWarningMessage = _i18n.t('routeToPrompt:noAudioFilesWarningMessage');

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {

            return _initialize();
        };

        const _initialize = () => {
            _disposables.push(self.selectedPrompt.subscribe(_promptSelectionChanged));
            if ((initialRoutedToId) && (initialRoutedToId !== _inlinePromptEnumerations.NEW_PROMPT.id) && (initialRouteToType ===  _voiceRouteEnumerations.prompt)) {
                const prompt = _commonState.get(initialRoutedToId);
                self.selectedPrompt(prompt);
            }
            else {
                self.selectedPrompt(_inlinePromptEnumerations.SELECT_PROMPT);
            }

            return _promiseFactory.wait();
        };
    };
});
