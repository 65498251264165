define('presentation/loginNotifications/facades/resendInviteFacade',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp'
], function () {
    return function () {
        const self = this;

        self.resendInvite = (emailAddress) => {
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const WebSocketValidationConstructor = require('persistence/webSocket/webSocketApp');
            const webSocketApp = new WebSocketValidationConstructor();
            webSocketApp.init("resend_invite");

            const webSocketParameters = {emailAddress: emailAddress};

            return _promiseFactory.defer((promise) => {
                webSocketApp.send("resend_invite", webSocketParameters, (result) => {
                    promise.resolve(result);
                });
            });
        };
    };
});

