define('presentation/settings/userProfile/validators/changeVerificationQAModalValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;

        self.registerViewModel = (viewModel) => {
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("verificationAnswer")
                .addValidationKeyUp(_commonValidator.isStringWithValue)
                .addValidationI18n(_commonValidator.isStringWithValue, 'verificationAnswerRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

