define('externalDependencies/kazooResult',[], function() {

    return function() {
        var self = this;

        self.success = false;
        self.errorMessage = null;
        self.data = null;
    };

});

