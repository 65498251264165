define('presentation/settings/schedules/presentationObjects/schedulePresentationObject',[],function() {
    return function(){
        const self = this;

        self.accountAttendantScheduleId = null;
        self.name = null;
        self.editScheduleUrl = null;
        self.showMap = ko.observable(true);
    };
});

