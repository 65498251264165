define('constants/creditCardResponseEnumerations',[], function() {

    return {
        approved : {
            formattedOutcome: "Approved",
            cssClass: "cc-approved"
        },
        declined: {
            formattedOutcome: "Declined",
            cssClass: "cc-declined"
        }
    };
});

