define('constants/formOfBusinessEnumerations',[], function() {
    return {
        Charity: 1,
        PrivateCompany: 2,
        PubliclyTraded: 3,
        Government: 4,
        Franchise: 5,
        PublicUtility: 6,
        SoleProprietor: 7
    };
});
