define('presentation/common/actionModal/viewModels/copyAutoAttendantActionViewModel',[
    'common/promises/promiseFactory',
    'presentation/settings/autoAttendant/facades/autoAttendantFacade',
    'presentation/settings/autoAttendant/validators/autoAttendantModalValidator',
], function() {
    return function (_autoAttendantId, _autoAttendantName) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        let _facade = null;
        let _validator = null;

        self.autoAttendantId = ko.observable(_autoAttendantId);
        self.autoAttendantName =  ko.observable(_autoAttendantName + _i18n.t('actionModal:copy'));

        self.submitAction = () => {
            return _promiseFactory.defer((deferredObject) => {
                self.autoAttendantName.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        let responseObject = {isValid: isValid};

                        if (isValid === false) {
                            deferredObject.resolve(responseObject);
                            return;
                        }

                        _facade.copyAutoAttendant(self.autoAttendantId(), self.autoAttendantName())
                            .fail(deferredObject.reject)
                            .done(() => {
                                deferredObject.resolve(responseObject);
                            });
                    });
            });
        };

        self.activate = () => {
            const ValidatorConstructor = require('presentation/settings/autoAttendant/validators/autoAttendantModalValidator');
            _validator = new ValidatorConstructor();

            const AutoAttendantFacade = require('presentation/settings/autoAttendant/facades/autoAttendantFacade');
            _facade = new AutoAttendantFacade();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});
