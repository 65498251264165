define('presentation/common/filterSelection/viewModels/filterSelectionViewModel',[
    'common/collections/collectionSorter',
    'common/storage/commonState',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const commonState = require('common/storage/commonState');

        const _i18n = require('i18next');

        let _isItemEnabledProperty = "";
        let _readOnlyProperty = "";
        let _settings = null;
        let _subscriptions = [];

        const _filterAvailableOptions = ko.pureComputed(() => {
            const filteredOptions = self.allOptions().filter(allOption => {
                const optionId = ko.unwrap(allOption).id;
                return undefined === self.selectedValues().find((selectedValueItem) => {
                    return optionId === ko.unwrap(selectedValueItem).id;
                });
            });

            return filteredOptions;
        });

        self.labelI18nKey = ko.observable("");
        self.allOptions = ko.observableArray();
        self.selectedValues = ko.observableArray();
        self.displayProperty = 'displayName';
        self.avatarProperty = 'avatar';
        self.avatarTypeProperty = 'avatarType';
        self.groupIdProperty = "id";
        self.isOptionActiveProperty = "";
        self.isEnabled = ko.observable(true);
        self.optionTypeTitle = ko.observable('items');
        self.addAnotherWarning = ko.computed(() => _i18n.t('filterSelection:addAnotherMessage', {optionText: self.optionTypeTitle()}));
        self.noResultsText = ko.computed(() => _i18n.t('filterSelection:addAnotherMessage', {optionText: self.optionTypeTitle()}));
        self.addSubscriberEnabled = ko.observable(true);
        self.addAnotherIsDisabled = ko.observable(false);
        self.firstAvailableOption = (availableOptions) => availableOptions()[0];

        self.showClearButton = ko.computed(() => self.selectedValues().length > 0);

        self.clearSelectedOptions = () => {
            self.selectedValues.removeAll();
            _settings.selectedValues.removeAll();
        };

        self.itemIsDisabled = (item) => {
            return ko.computed(() => {
                if (self.isEnabled() === false) {
                    return true;
                }

                if (_readOnlyProperty !== "") {
                    return ko.unwrap(item[_readOnlyProperty]);
                }

                return false;
            });
        };

        self.showDisabledDeleteIcon = (item) => {
            return ko.computed(() => {
                if (self.isEnabled() === false) {
                    return true;
                }

                if (_readOnlyProperty !== "") {
                    return item[_readOnlyProperty]();
                } else {
                    return false;
                }
            });
        };

        self.deleteSelectedValue = (itemToDelete) => {
            if (self.isEnabled()) {
                if (_readOnlyProperty === "" || itemToDelete().isReadOnly() === false) {
                    self.selectedValues.remove(itemToDelete);
                    _deleteParentSelectedItem(itemToDelete);
                }
            }
        };

        const _deleteParentSelectedItem = (itemToDelete) => {
            const actualItemToDelete = ko.unwrap(itemToDelete);
            if (ko.isObservable(_settings.selectedValues)) {
                _settings.selectedValues.remove(actualItemToDelete);
            } else {
                const itemIndex = _settings.selectedValues.indexOf(actualItemToDelete);
                if (itemIndex > -1) {
                    _settings.selectedValues.splice(itemIndex, 1);
                }
            }
        };

        self.addSelectedValue = () => {
            if (self.addAnotherIsDisabled() === true) {
                return;
            }
            const firstAvailableOption = self.firstAvailableOption(self.availableOptions);
            _addLocalSelectedItem(firstAvailableOption);
            _settings.selectedValues.push(firstAvailableOption);
        };

        const _addLocalSelectedItem = (selectedItem) => {
            if (ko.isObservable(selectedItem)) {
                self.selectedValues.push(selectedItem);
            } else {
                const selectedValueObservable = ko.observable(selectedItem);
                self.selectedValues.push(selectedValueObservable);
                _subscriptions.push(selectedValueObservable.subscribePrevious(_updateParentSelectedItem));
            }
        };

        const _updateParentSelectedItem = (newValue, oldValue) => {
            if (ko.isObservable(_settings.selectedValues)) {
                _settings.selectedValues.replace(oldValue, newValue);
            } else {
                const selectedIndex = _settings.selectedValues.findIndex(selectedValue => ko.unwrap(selectedValue) === oldValue);
                if (selectedIndex === -1) {
                    _settings.selectedValues.push(newValue);
                } else {
                    if (ko.isWriteableObservable(_settings.selectedValues[selectedIndex])) {
                        _settings.selectedValues[selectedIndex](newValue);
                    } else {
                        _settings.selectedValues[selectedIndex] = newValue;
                    }
                }
            }
        };

        const _updateLocalSelectedItems = (arrayChanges) => {
            arrayChanges.forEach(arrayChange => {
                if (!arrayChange.moved) {
                    const localSelectedItemIndex = self.selectedValues().findIndex(localSelectedItem => arrayChange.value === ko.unwrap(localSelectedItem));
                    switch (arrayChange.status) {
                        case "added":
                            if (localSelectedItemIndex === -1) {
                                _addLocalSelectedItem(arrayChange.value);
                            }
                            break;
                        case "deleted":
                            if (localSelectedItemIndex > -1) {
                                self.selectedValues.splice(localSelectedItemIndex,1);
                            }
                            break;
                    }
                }

            });
        };

        self.activate = (settings, isEnabled = true) => {
            _settings = settings;
            return _initialize(isEnabled);
        };

        self.detached = () => {
            _subscriptions.forEach((s) => s.dispose());
            _subscriptions = [];
        };

        const _initialize = (isEnabled) => {
            self.labelI18nKey(ko.unwrap(_settings.labelI18nKey));

            if (_settings.possibleOptions) {
                if (ko.isObservable(_settings.possibleOptions)) {
                    self.allOptions = _settings.possibleOptions;
                } else {
                    self.allOptions(_settings.possibleOptions);
                }
            } else {
                self.allOptions = ko.pureComputed(() => {
                    const users = commonState.users().reduce((accumulator, user) => {
                        if (user.isActive() || user.isInvited()) {
                            accumulator.push({
                                id: user.id,
                                subscriberType: user.type,
                                displayName: user.name(),
                                avatar: user.avatar()
                            });
                        }
                        return accumulator;
                    }, []);
                    const userGroups = commonState.userGroups().reduce((accumulator, userGroup) => {
                        if (userGroup.isActive()) {
                            accumulator.push({
                                id: commonState.resolveGroupIdToGuid(userGroup.id),
                                subscriberType: userGroup.type,
                                displayName: userGroup.name(),
                                avatar: userGroup.avatars()
                            });
                        }
                        return accumulator;
                    }, []);
                    const sorter = new SorterConstructor();
                    let combinedResults = users.concat(userGroups);
                    sorter.sort(combinedResults, "displayName", true);
                    return combinedResults;
                });
            }

            if (ko.isObservable(isEnabled)) {
                self.isEnabled = isEnabled;
            } else {
                self.isEnabled(isEnabled);
            }

            if (_settings.selectedValues) {
                ko.unwrap(_settings.selectedValues).forEach(selectedItem => _addLocalSelectedItem(selectedItem));
                if (ko.isObservable(_settings.selectedValues)) {
                    _subscriptions.push(_settings.selectedValues.subscribe(_updateLocalSelectedItems, this, "arrayChange"));
                }
            } else {
                throw new Error("selectedValues is required");
            }

            if (_settings.filterAvailableFunction) {
                self.availableOptions = ko.pureComputed(() => {
                    return _settings.filterAvailableFunction(self.allOptions(), self.selectedValues());
                });
            } else {
                self.availableOptions = _filterAvailableOptions;
            }

            if (_settings.firstAvailableOption) {
                self.firstAvailableOption = _settings.firstAvailableOption;
            }

            if (_settings.displayProperty) {
                self.displayProperty = ko.unwrap(_settings.displayProperty);
            }

            if (_settings.avatarProperty) {
                if (ko.isObservable(_settings.avatarProperty)) {
                    self.avatarProperty = _settings.avatarProperty;
                } else {
                    self.avatarProperty = ko.unwrap(_settings.avatarProperty);
                }
            }

            if (_settings.avatarTypeProperty) {
                self.avatarTypeProperty = ko.unwrap(_settings.avatarTypeProperty);
            }

            if ((_settings.groupIdProperty) && (typeof _settings.groupIdProperty === "string")) {
                self.groupIdProperty = _settings.groupIdProperty;
            }

            if (_settings.isOptionActiveProperty) {
                self.isOptionActiveProperty = ko.unwrap(_settings.isOptionActiveProperty);
            }

            if (_settings.readOnlyProperty) {
                _readOnlyProperty = ko.unwrap(_settings.readOnlyProperty);
            }

            if (_settings.isItemEnabledProperty) {
                _isItemEnabledProperty = ko.unwrap(_settings.isItemEnabledProperty);
            }

            if (_settings.isEnabled) {
                if (ko.isObservable(_settings.isEnabled)) {
                    self.isEnabled = _settings.isEnabled;
                } else {
                    self.isEnabled(_settings.isEnabled);
                }
            }

            if (_settings.addAnotherMessage !== undefined) {
                if (ko.isObservable(_settings.addAnotherMessage)) {
                    self.addAnotherWarning = _settings.addAnotherMessage;
                } else {
                    self.addAnotherWarning(_settings.addAnotherMessage);
                }
            }

            if (_settings.noResultsText) {
                if (ko.isObservable(_settings.noResultsText)) {
                    self.noResultsText = _settings.noResultsText;
                } else {
                    self.noResultsText(_settings.noResultsText);
                }
            }

            if (_settings.optionTypeTitle) {
                if (ko.isObservable(_settings.optionTypeTitle)) {
                    self.optionTypeTitle(_settings.optionTypeTitle());
                } else {
                    self.optionTypeTitle(_settings.optionTypeTitle);
                }
            }

            if (_settings.addSubscriberEnabled) {
                if (ko.isObservable(_settings.addSubscriberEnabled)) {
                    self.addSubscriberEnabled = _settings.addSubscriberEnabled;
                } else {
                    self.addSubscriberEnabled(_settings.addSubscriberEnabled);
                }
            }

            self.addAnotherIsDisabled = ko.pureComputed(() => {
                let allowAddAnother = self.isEnabled() &&
                    self.addSubscriberEnabled() &&
                    self.availableOptions().length > 0;
                return !allowAddAnother;
            });

            self.tooltip = ko.computed(() => {
                return self.addAnotherIsDisabled() ? self.addAnotherWarning() : '';
            });

            return _promiseFactory.wait();
        };
    };
});
