define('presentation/loginNotifications/validators/addPaymentMethodModalValidator',[
    'businessServices/country/countryCode',
    'businessServices/creditCards/creditCardValidations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function () {
    return function () {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const CountryCodeConstructor = require('businessServices/country/countryCode');
        const CreditCardValidationConstructor = require('businessServices/creditCards/creditCardValidations');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const _creditCardValidations = new CreditCardValidationConstructor();
        const _countries = new CountryCodeConstructor();

        let _commonValidator = null;
        let _viewModel = null;

        self.registerViewModel = function (viewModel) {
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("cardHolderName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addPaymentMethodModal:nameOnCardRequired')
                .addValidationI18n(_noSpecialCharacters, 'addPaymentMethodModal:noSpecialCharacters');
            validationRules.field("cardNumber")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addPaymentMethodModal:cardNumberRequired')
                .addValidationI18n(_creditCardValidations.validateCardNumber, 'addPaymentMethodModal:cardNumberInvalid');
            validationRules.field('cvvSecurityCode')
                .addValidationI18n(_commonValidator.isStringWithValue, 'addPaymentMethodModal:cvvRequiredToAdd')
                .addValidationI18n(_isCvvValid, 'addPaymentMethodModal:cvvInvalid');
            validationRules.fieldGroup("expirationDate", ["monthSelected", "yearSelected"])
                .addValidationI18n(_validateExpirationDate, 'addPaymentMethodModal:expirationDateInvalid');
            validationRules.field("postalCode")
                .addValidationI18n(_commonValidator.isStringWithValue, _postalCodeRequiredI18nKey)
                .addValidationI18n(_validatePostalCodeValid, _postalCodeInvalidI18nKey);

            validationRules.validationGroupKeyUp('isValid', ["cardHolderName", "cardNumber", "expirationDate", "cvvCode", "postalCode"]);

            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _noSpecialCharacters = (name) => /^[a-zA-Z0-9 ']*$/.test(name);

        const _isCvvValid = (cvv) => _creditCardValidations.validateCvv(cvv, _viewModel.cardNumber());

        const _removeSpacesFromString = (string) => string.replace(/\s+/g, '');

        const _validatePostalCodeValid = (postalCode) => {
            return _creditCardValidations.validatePostalCodeFormat(_removeSpacesFromString(postalCode), _currentCountry());
        };

        const _validateExpirationDate = (monthSelected, yearSelected) => {
            return _creditCardValidations.validateExpirationDate(monthSelected, yearSelected);
        };

        const _currentCountry = ko.pureComputed(() => {
            let currentCountry = _countries.getDefaultCountry();
            if (_viewModel.countrySelected()) {
                currentCountry = _countries.findByAbbreviation(_viewModel.countrySelected());
            }
            return currentCountry;
        });

        const _postalCodeRequiredI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'addPaymentMethodModal:postalCodeRequired' : 'addPaymentMethodModal:zipCodeRequired');

        const _postalCodeInvalidI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'addPaymentMethodModal:postalCodeInvalid' : 'addPaymentMethodModal:zipCodeInvalid');
    };
});

