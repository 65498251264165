define('presentation/settings/autoAttendant/validators/autoAttendantModalValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'constants/validationMessageEnumerations'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _autoAttendantFacade = null;
        let _autoAttendantViewModel = null;
        let _commonValidator = null;

        self.registerViewModel = (autoAttendantViewModel, autoAttendantFacade) => {
            _autoAttendantViewModel = autoAttendantViewModel;
            _autoAttendantFacade = autoAttendantFacade;
            _commonValidator = new CommonValidatorConstructor(_autoAttendantViewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("autoAttendantName")
                .addValidationI18n(_validateAutoAttendantNameUniqueness, 'autoAttendant:uniqueNameRequired')
                .addValidationI18n(_commonValidator.isStringWithValue, 'autoAttendant:nameRequired');

            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateAutoAttendantNameUniqueness = (autoAttendantName) => {
            return _promiseFactory.defer((deferredResult) => {
                _autoAttendantFacade.isAutoAttendantNameUnique(undefined, autoAttendantName)
                    .done(deferredResult.resolve)
                    .fail(deferredResult.reject);
            });
        };
    };
});
