define('presentation/signup/presentationObjects/signupProfilePresentationObject',[],function() {
    return function(){
        let self = this;
        self.firstName = null;
        self.lastName = null;
        self.password = null;
        self.businessName = null;
        self.mobileNumber = null;
        self.mobileNumberCountryCode = null;
        self.signupPhoneNumbers = null;
        self.signupNumberType = null;
        self.verificationQuestionId = null;
        self.verificationAnswer = null;
    };
});
