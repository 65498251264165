/**
 * @module common/uniqueId/guidUtil
 */
define('common/uniqueId/guidUtil',[], function() {
    /*
     Compares two guids to confirm they are equal.
     Hyphenation and capitalization are ignored
     @returns boolean
     */
    const areEqual = (guid1, guid2) => {
        if (guid1 === null && guid2 === null) {
            return true;
        } else if ((guid1 === null) || (guid2 === null)) {
            return false;
        }
        const guid1Compare = _removeHyphens(guid1).toLowerCase();
        const guid2Compare = _removeHyphens(guid2).toLowerCase();
        return (guid1Compare === guid2Compare);
    };

    const _removeHyphens = (guid) => {
        const hyphensRemoved  = guid.replace(/-/g, "");
        return hyphensRemoved;
    };

    const _isGuid = (guid) => {
        const looseGuidTest = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;  // 8hex-4hex-4hex-4hex-12hex, close to but not true Rfc4122 Guid
        return typeof guid === 'string' && looseGuidTest.test(guid);
    };

    const _maybeGuidToId = (guid) => _isGuid(guid) ? _removeHyphens(guid) : guid;

    return {
        areEqual: areEqual,
        maybeGuidToId: _maybeGuidToId,
    };
});

