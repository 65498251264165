define('entities/signupNumberPortEntity',[],function() {
    return function() {
        let self = this;

        self.numberPortId = null;
        self.carrier = "";
        self.phoneNumbersToPort = null;
        self.billingName = "";
        self.billingPhoneNumber = "";
        self.billingPhoneNumberCountry = "us";
        self.billingAddressLine1 = "";
        self.billingAddressLine2 = "";
        self.billingCity = "";
        self.billingCountry = "";
        self.billingState = "";
        self.billingZipPostalCode = "";
        self.billingAccountNumber = "";
        self.billingPinPasscode = "";
        self.otherNumbersOnAccount = false;
        self.whenToPort = "Asap";
        self.desiredPortDate = null;
    };
});
