define('presentation/settings/forwardingNumbers/facades/addForwardingNumbersFacade',[
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'entities/forwardingNumbersEntity',
    'persistence/webSocket/webSocketApp',
    'presentation/common/dateTimeValue',
    'presentation/settings/forwardingNumbers/presentationObjects/forwardingNumbersPresentationObject'
],

    function() {
        const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        const ForwardingNumbersEntityConstructor = require('entities/forwardingNumbersEntity');
        const ForwardingNumbersPresentationObjectConstructor = require('presentation/settings/forwardingNumbers/presentationObjects/forwardingNumbersPresentationObject');

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        const _webSocketApp = new WebSocketAppConstructor();

        const _commonState = require('common/storage/commonState');

        let _promiseFactory = null;

        const _getForwardingNumber = (forwardingNumberId) => {
            return _promiseFactory.defer((deferredObject) => {
                const webSocketParams = {
                    "forwardingNumberId": forwardingNumberId
                };
                _webSocketApp.send("get_forwarding_number", webSocketParams, (result) => {
                    if (result.status === "success") {
                        const currentForwardingNumber = result.forwardingNumber;
                        const displayForwardingNumber = new ForwardingNumbersPresentationObjectConstructor();
                        displayForwardingNumber.forwardingNumberId = currentForwardingNumber.forwardingNumberId;
                        displayForwardingNumber.createdDateTime = new DateTimeValueConstructor(currentForwardingNumber.createdDateTime);
                        displayForwardingNumber.modifiedDateTime = new DateTimeValueConstructor(currentForwardingNumber.modifiedDateTime);
                        displayForwardingNumber.forwardingNumberName = currentForwardingNumber.forwardingNumberName;
                        displayForwardingNumber.phoneNumber = currentForwardingNumber.phoneNumber;
                        displayForwardingNumber.countryCode = currentForwardingNumber.countryCode;
                        displayForwardingNumber.countryAbbreviation = currentForwardingNumber.countryAbbreviation;
                        displayForwardingNumber.extension = currentForwardingNumber.extension;
                        displayForwardingNumber.aniToDisplay = currentForwardingNumber.aniToDisplay;

                        const forwardingNumberResponse = {
                            "forwardingNumber": displayForwardingNumber
                        };
                        deferredObject.resolve(forwardingNumberResponse);
                    } else {
                        const error = new Error("Non success status returned from add_forwarding_numbers web socket getForwardingNumber call. status = " + result.status);
                        error.forwardingNumberId = forwardingNumberId;
                        deferredObject.reject(error);
                    }
                });
            });
        };

        const _addForwardingNumber = (forwardingNumberName, countryAbbreviation, countryCode, phoneNumber, aniToDisplay) => {
            const forwardingNumberEntity = new ForwardingNumbersEntityConstructor();
            forwardingNumberEntity.forwardingNumberName = forwardingNumberName.trim();
            forwardingNumberEntity.countryAbbreviation = countryAbbreviation.trim();
            forwardingNumberEntity.countryCode = countryCode.trim();
            forwardingNumberEntity.phoneNumber = phoneNumber.trim();
            forwardingNumberEntity.aniToDisplay = aniToDisplay;

            return _promiseFactory.defer((deferredObject) => {
                const webSocketParams = {
                    "forwardingNumberEntity": forwardingNumberEntity
                };
                _webSocketApp.send("add_forwarding_number", webSocketParams, (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        const _updateForwardingNumber = (forwardingNumberId, forwardingNumberName, countryAbbreviation, countryCode, phoneNumber, aniToDisplay) => {
            const forwardingNumberEntity = new ForwardingNumbersEntityConstructor();
            forwardingNumberEntity.forwardingNumberId = forwardingNumberId;
            forwardingNumberEntity.forwardingNumberName = forwardingNumberName.trim();
            forwardingNumberEntity.countryAbbreviation = countryAbbreviation.trim();
            forwardingNumberEntity.countryCode = countryCode.trim();
            forwardingNumberEntity.phoneNumber = phoneNumber.trim();
            forwardingNumberEntity.aniToDisplay = aniToDisplay;

            return _promiseFactory.defer((deferredObject) => {
                const webSocketParams = {
                    "forwardingNumberEntity": forwardingNumberEntity,
                };
                _webSocketApp.send("update_forwarding_number", webSocketParams, (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        const _isForwardingNumberNameUnique = (forwardingNumberId, forwardingNumberName) => {
            const formattedExpectedForwardingName = forwardingNumberName.toLowerCase().trim();
            return _commonState.forwardingNumbers().every(
                f => f.name().toLowerCase().trim() !== formattedExpectedForwardingName || f.id === forwardingNumberId
            );
        };

        const _isExtensionUnique = (forwardingNumberId, extension) => {
            const extensionString = extension.toString();
            return _commonState.extensions().every(
                f => f.extension !== extensionString || f.ownerId === forwardingNumberId
            );
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            _webSocketApp.init("add_forwarding_numbers");
        };


        return function() {
            const self = this;

            self.init = _init;

            self.addForwardingNumber = _addForwardingNumber;
            self.updateForwardingNumber = _updateForwardingNumber;
            self.getForwardingNumber = _getForwardingNumber;
            self.isForwardingNumberNameUnique = _isForwardingNumberNameUnique;
            self.isExtensionUnique = _isExtensionUnique;
        };
    });

