define('presentation/settings/phoneNumbers/viewModels/addPhoneNumberTypeChooserViewModel',[
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'settings/navigationConfiguration',
], function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _router = require('businessServices/router/router');
        const _navigationConfig = require('settings/navigationConfiguration');

        const buildNumberTypeCard = (numberType, routeId, icon, iconHeight, iconWidth) => ({
            numberType,
            icon,
            iconHeight,
            iconWidth,
            isSelected: ko.observable(false),
            cardClick() {
                self.numberTypeCards.forEach(a => a.isSelected(false));
                this.isSelected(true);

                _promiseFactory.deferWithMinimumWait((promise) => {
                    _router.navigate(_navigationConfig.routesById[routeId].url);
                    promise.resolve();
                });
            },
        });

        self.numberTypeCards = [
            buildNumberTypeCard('local', 'newPhoneNumberLocal', 'numberPickerLocal', '30', '30'),
            buildNumberTypeCard('tollFree', 'newPhoneNumberTollFree', 'numberPickerTollfree', '30', '30'),
            buildNumberTypeCard('transfer', 'newPhoneNumberPort', 'numberPickerTransfer', '25', '25'),
        ];

        self.cancelClick = () => {
            _router.navigate(_navigationConfig.routesById.phoneNumbers.url);
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

