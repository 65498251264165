define('entities/signupEntity',[],function() {
    return function(){
        let self = this;
        self.signupSessionId = null;
        self.deviceFingerPrint = null;
        self.lobbyAccountId = null;
        self.secretKey = null;
        self.planId = null;
        self.packageId = null;
        self.phoneNumbers = null;
        self.isPortingNumbers = false;
        self.phoneNumbersToPort = null;
        self.numberPortInfo = null;
        self.creditCardNumber = null;
        self.cardHolderName = null;
        self.expirationDate = null;
        self.cvvSecurityCode = null;
        self.emailAddress = null;
        self.password = null;
        self.country = null;
        self.postalCode = null;
    };
});

