define('presentation/common/routeToModal/strategies/autoAttendantSubMenuStrategy',['common/promises/promiseFactory',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/routingRuleConstants',
    'presentation/settings/autoAttendant/facades/autoAttendantFacade',
    'presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel',
    'presentation/settings/autoAttendant/viewModels/autoAttendantRoutingRulesViewModel',
    'presentation/common/routeToModal/common/routingRuleBuilder'
], function() {

    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SubMenuConnectorConstructor = require('presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel');
        const RoutingRulesViewModelConstructor = require('presentation/settings/autoAttendant/viewModels/autoAttendantRoutingRulesViewModel');
        const RoutingRuleBuilderConstructor = require('presentation/common/routeToModal/common/routingRuleBuilder');

        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _routingRuleConstants = require('constants/routingRuleConstants');
        const _routingRuleBuilder = new RoutingRuleBuilderConstructor();
        const _promiseFactory = new PromiseFactoryConstructor();
        const Facade = require('presentation/settings/autoAttendant/facades/autoAttendantFacade');
        const _facade = new Facade();
        _facade.init(_promiseFactory);


        const _save = (routeToViewModel) => {
            return _promiseFactory.defer((deferredObject) => {
                const setConnection = (routingRule) => {
                    let connection;
                    switch (routingRule.routingType) {
                        case _routingRuleConstants.subMenu:
                            self.subMenuItemPresentationObject.routeId("subMenuId");
                            self.subMenuItemPresentationObject.type(_voiceRouteEnumerations.subMenu);
                            self.subMenuItemPresentationObject.showVoicePromptOption(true);
                            routingRule.routeToSubMenu = {
                                accountVoicePromptId: routingRule.routeToVoicePromptId,
                                systemAudioClipId: routingRule.routeToSystemAudioClipId
                            };
                            connection = new SubMenuConnectorConstructor(self.autoAttendantId, self.availablePrompts, routingRule, _promiseFactory, self.saveStorageFacade, self.hasAutoAttendantChanged, self.subMenuItemPresentationObject, self.autoAttendants, self.availableObjects);
                            break;
                        default:
                            self.subMenuItemPresentationObject.routeId("routingRuleId");
                            self.subMenuItemPresentationObject.type(_voiceRouteEnumerations.routingRule);
                            self.subMenuItemPresentationObject.routingRules(routingRule);
                            self.subMenuItemPresentationObject.showVoicePromptOption(false);
                            connection = new RoutingRulesViewModelConstructor(self.autoAttendantId, self.hasAutoAttendantChanged, _promiseFactory, self.saveStorageFacade, self.subMenuItemPresentationObject, self.autoAttendants, self.availableObjects);
                            break;
                    }
                    connection.activate()
                        .fail(deferredObject.reject)
                        .done(() => {
                            self.subMenuItemPresentationObject.connector(connection);
                            deferredObject.resolve();
                        });
                };

                let optionName = routeToViewModel.optionName();
                if (optionName === null || optionName === "") {
                    optionName = "Menu Key " + self.subMenuItemPresentationObject.key;
                }
                self.subMenuItemPresentationObject.optionName(optionName);
                self.hasAutoAttendantChanged(true);

                if (routeToViewModel.initialRouteToType === _voiceRouteEnumerations.subMenu && routeToViewModel.routeTo().value === _voiceRouteEnumerations.subMenu) {
                    deferredObject.resolve();
                    return;
                }

                const routingRule = _routingRuleBuilder.buildRoutingRule(routeToViewModel);

                const isNewAutoAttendant = routingRule.routeToAutoAttendantId && self.availableObjects.availableAutoAttendants.every(a => a.accountAutoAttendantId !== routingRule.routeToAutoAttendantId);
                const isNewSchedule = routingRule.routeToScheduleId && self.availableObjects.availableSchedules.every(a => a.accountAttendantScheduleId !== routingRule.routeToScheduleId);
                const isNewVoicePrompt = routingRule.routeToVoicePromptId && self.availableObjects.availableVoicePrompts.every(a => a.voicePromptId !== routingRule.routeToVoicePromptId);

                if (isNewAutoAttendant) {
                    _facade.getAutoAttendants()
                        .done(availableAutoAttendants => {
                            self.availableObjects.availableAutoAttendants = availableAutoAttendants;
                            setConnection(routingRule);
                        });
                }
                else if (isNewSchedule) {
                    _facade.getSchedules()
                        .done(availableSchedules => {
                            self.availableObjects.availableSchedules = availableSchedules;
                            setConnection(routingRule);
                        });
                }
                else if (isNewVoicePrompt) {
                    _facade.getVoicePrompts()
                        .done(availableVoicePrompts => {
                            self.availableObjects.availableVoicePrompts = availableVoicePrompts;
                            setConnection(routingRule);
                        });
                }
                else {
                    setConnection(routingRule);
                }
            });
        };

        const _update = (routeToViewModel) => {
            return _promiseFactory.defer((deferredObject) => {
                _save(routeToViewModel)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        const _init = () => {

        };

        self.init = _init;
        self.save = _save;
        self.update = _update;

        // set externally, for example autoAttendantUserViewModel self.editRouteToUser function
        self.autoAttendantId = null;
        self.autoAttendants = null;
        self.autoAttendantVoiceRouteId = null;
        self.autoAttendantVoiceSubMenuId = null;
        self.saveStorageFacade = null;
        self.hasAutoAttendantChanged = null;
        self.subMenuItemPresentationObject = null;
        self.availablePrompts = null;
        self.availableObjects = null;
    };
});
