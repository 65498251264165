define('entities/autoAttendantVoiceRouteEntity',[],function() {
    return function(){
        var self = this;

        self.autoAttendantVoiceRouteId = null;
        self.autoAttendantId = null;
        self.autoAttendantVoiceRouteName = null;
        self.isInitialVoiceRoute = null;
        self.voicePromptId = null;
        self.routeToUserId = null;
        self.routeToUserGroupId = null;
        self.routeToForwardingNumberId = null;
        self.routeToAutoAttendantVoiceSubMenuId = null;
        self.routeToAutoAttendantId = null;
        self.routeToScheduleId = null;
        self.routeToType = null;
        self.createdDateTime = null;
        self.modifiedDateTime = null;
		self.kazooPhoneConnectorId = null;
    };
});

