define('presentation/common/callFlow/presentationObjects/scheduleSegmentPresentationObject',['i18next'],function() {
    return function(){
        const self = this;

        const _i18n = require('i18next');

        self.accountAttendantScheduleSegmentId = null;
        self.accountAttendantScheduleId = null;
        self.name = ko.observable(null);
        self.sequence = ko.observable(null);
        self.isDefault = false;
        self.isForSunday = ko.observable(false);
        self.isForMonday = ko.observable(false);
        self.isForTuesday = ko.observable(false);
        self.isForWednesday = ko.observable(false);
        self.isForThursday = ko.observable(false);
        self.isForFriday = ko.observable(false);
        self.isForSaturday = ko.observable(false);
        self.startHour = ko.observable(0);
        self.startMinute = ko.observable(0);
        self.endHour = ko.observable(0);
        self.endMinute = ko.observable(0);
        self.timeout = ko.observable(null);

        self.callFlowSeparatorMessage = ko.computed(() => {
            if (self.timeout()) {
                return _i18n.t('callFlow:timeoutSeparatorMessage');
            } else {
                return _i18n.t('callFlow:defaultSeparatorMessage');
            }
        });

        self.callFlowTimeoutMessage = ko.computed(() => {
            if (self.timeout()) {
                return self.timeout() + _i18n.t('callFlow:timeoutMessage');
            } else {
                return "";
            }
        });

        self.routeSearchData = ko.observable(null);
        self.routeType = ko.observable("");
        self.routingRule = null;

        self.userAvatar = ko.observable(null);
        self.hasAvatar = ko.observable(false);
        self.userGroupRingType = ko.observable(null);
        self.forwardingNumberPhoneNumber = ko.observable(null);
        self.forwardingNumberCountryName = null;
        self.showDeactivated = ko.observable(false);
        self.deactivatedMessage = ko.observable("");
        self.showScheduleSegmentDetails = ko.observable(true);
    };
});

