/* eslint-disable no-case-declarations */
define('businessServices/router/plugins/messagesRouterPlugin',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/messages/messagesStateSingleton',
    'presentation/messages/dataSources/webMessagingDataSource',
    'settings/navigationConfiguration'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    messagesState,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    webMessagingDataSource,
    /** @type typeof import('settings/navigationConfiguration') */
    navigationConfiguration
) {
    const ROUTES_BY_ID = navigationConfiguration.routesById;

    const MESSAGES_ROUTE_ID = ROUTES_BY_ID.messages.routeId;
    const NEW_MESSAGE_ROUTE_ID = ROUTES_BY_ID.newMessage.routeId;
    const CONVERSATION_ROUTE_ID = ROUTES_BY_ID.conversation.routeId;

    /**
     * @param {string} routeId
     * @param {DurandalRouteInstruction} routeInfo
     * @returns {boolean | { routeUrl: string }}
     */
    const routeToMessages = (routeId, routeInfo) => {
        switch (routeId) {
            case NEW_MESSAGE_ROUTE_ID:
                const [conversationDraftId] = routeInfo.params;
                if (conversationDraftId && _isAuthorized()) {
                    messagesState.selectedConversationDraftId(conversationDraftId);
                    messagesState.selectedConversationId(null);
                    return true;
                }
                return _newRoute(ROUTES_BY_ID.messages.url);
            case CONVERSATION_ROUTE_ID:
                const [conversationId] = routeInfo.params;
                if (conversationId && _isAuthorized()) {
                    messagesState.selectedConversationDraftId(null);
                    messagesState.selectedConversationId(conversationId);
                    return true;
                }
                return _newRoute(ROUTES_BY_ID.messages.url);
            case MESSAGES_ROUTE_ID:
                if (messagesState.selectedConversationId()) {
                    const conversationUrl = `${ROUTES_BY_ID.conversation.baseUrl}/${messagesState.selectedConversationId()}`;
                    return _newRoute(conversationUrl);
                }
                return true;
            default:
                return true;
        }
    };

    const _isAuthorized = () => {
        return webMessagingDataSource.authorizedHostedNumberIds().length;
    };

    /**
     * @param {string} routeUrl
     * @returns {{ routeUrl: string }}
     */
    const _newRoute = (routeUrl) => {
        return { routeUrl: routeUrl };
    };

    return {
        guardRoute: routeToMessages
    };
});

