define('presentation/messages/dataSources/conversationReadStateDataSource',[],() => {

    /** @typedef {import('presentation/messages/dataSources/conversationReadStateDataSource')} ConversationReadState */

    const ConversationReadStateDataSource = function() {
        /** @type {Map<string, IReadInfo>} */
        const _conversationReadState = new Map();

        /** @type {ConversationReadState} */
        const _self = this;

        /** @type {ConversationReadState["getOrGenerateInfo"]} */
        _self.getOrGenerateInfo = (smsConversationId) => {
            if (_conversationReadState.has(smsConversationId)) {
                return _conversationReadState.get(smsConversationId);
            }

            /** @type {IReadInfo} */
            const readInfo = {
                smsConversationId: smsConversationId,
                lastViewedDate: ko.observable(null),
                isMarkedAsNew: ko.observable(null),
                lastMessageDate: ko.observable(null),
                newMessageCount: ko.observable(0),
                isConversationLoaded: ko.observable(false)
            };
            _conversationReadState.set(smsConversationId, readInfo);
            return readInfo;
        };

        /** @type {ConversationReadState["loadConversation"]} */
        _self.loadConversation = (conversation) => {
            const readInfo = _self.getOrGenerateInfo(conversation.smsConversationId);
            readInfo.newMessageCount(conversation.unreadMessagesCount);
            readInfo.isConversationLoaded(true);
        };

        /** @type {ConversationReadState["setConversationReadState"]} */
        _self.setConversationReadState = (smsConversationOrId, isMarkedAsNew, conversationLastViewedDateTime) => {
            if (typeof smsConversationOrId === "object") {
                // SidebarConversationPresentationObject
                const conversation = smsConversationOrId;
                const readInfo = _self.getOrGenerateInfo(conversation.smsConversationId);

                // Conversation Details and Messages have been loaded,
                if (!readInfo.isConversationLoaded()) {
                    readInfo.lastViewedDate(new Date(conversation.conversationLastViewedDateTime()));
                    readInfo.isMarkedAsNew(conversation.isMarkedAsNew());
                }

                readInfo.lastMessageDate(new Date(conversation.lastMessageDateTime()));
            }
            else {
                // Individual Values
                const readInfo = _self.getOrGenerateInfo(smsConversationOrId);

                readInfo.isMarkedAsNew(isMarkedAsNew);
                if (conversationLastViewedDateTime) {
                    readInfo.lastViewedDate(new Date(conversationLastViewedDateTime));
                }
            }
        };

        /** @type {ConversationReadState["setConversationNewMessagesCount"]} */
        _self.setConversationNewMessagesCount = (smsConversationId, newMessagesCount) => {
            const readInfo = _self.getOrGenerateInfo(smsConversationId);
            readInfo.newMessageCount(newMessagesCount);
        };

        /** @type {ConversationReadState["markConversationAsNew"]} */
        _self.markConversationAsNew = (smsConversationId) => {
            const readInfo = _self.getOrGenerateInfo(smsConversationId);
            readInfo.isMarkedAsNew(true);
            readInfo.lastViewedDate(new Date());
        };

        /** @type {ConversationReadState["markConversationAsReviewed"]} */
        _self.markConversationAsReviewed = (smsConversationId) => {
            const readInfo = _self.getOrGenerateInfo(smsConversationId);
            readInfo.isMarkedAsNew(false);
            readInfo.lastViewedDate(new Date());
        };
    };

    if (!ConversationReadStateDataSource._singletonInstance) {
        ConversationReadStateDataSource._singletonInstance = new ConversationReadStateDataSource();
    }

    return ConversationReadStateDataSource._singletonInstance;
});
