define('presentation/common/emptyState/viewModels/emptyStateViewModel',[],function() {
    return function() {
        var self = this;
        var _settings = null;

        self.emptyStateIcon = ko.observable("");
        self.emptyStateIcons = ko.observable("");
        self.showEmptyStateIcons = ko.observable(true);
        self.emptyStateIconArray = ko.pureComputed(function() {
            if (Array.isArray(self.emptyStateIcons())) {
                return self.emptyStateIcons();
            }
            else {
                return new Array(3).fill(self.emptyStateIcons());
            }
        });
        self.emptyStateText = ko.observable({
            header: "No results found.",
            comments: ["Try adjusting your search criteria to find what you're looking for."]
        });
        self.showResults = ko.observable(false);
        self.visible = ko.pureComputed(function() {
            return ! self.showResults();
        });

        self.activate = function(settings) {
            _settings = settings;
            _initialize();
        };

        var setProperty = function(property) {
            if (_settings[property] !== undefined) {
                if (ko.isObservable(_settings[property])) {
                    self[property] = _settings[property];
                } else {
                    self[property](_settings[property]);
                }
            }
        };

        var _initialize = function() {
            setProperty('emptyStateIcon');
            setProperty('emptyStateIcons');
            setProperty('showEmptyStateIcons');
            setProperty('emptyStateText');
            setProperty('showResults');
        };
    };
});

