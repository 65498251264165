define('constants/trestaIconConstants',[], function() {
    return {
        autoAttendant : "autoAttendant",
        forwardingNumber : "forwardingNumber",
        prompt : "prompt",
        schedule : "schedule",
        subMenu : "subMenu",
        user : "user",
        userGroup : "userGroup",
        voicemail : "voicemail",
    };
});
