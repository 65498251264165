define('presentation/settings/userGroup/viewModels/userGroupVoicemailViewModel',['businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'presentation/settings/userGroup/viewModels/userGroupVoicemailGreetingsViewModel',
        'presentation/settings/userGroup/viewModels/userGroupVoicemailSubscribersViewModel',
        'presentation/tabs/presentationObjects/tabsPresentationObject',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');

        const _i18n = require('i18next');

        let _disposables = [];

        const _updateActiveTabViewModel = () => {
            switch (self.tabActive()) {
                case self.tabMetadata.greetings.id:
                    self.activeTabViewModel(self.greetingsTabViewModel);
                    break;
                case self.tabMetadata.subscribers.id:
                    self.activeTabViewModel(self.subscribersTabViewModel);
                    break;
            }
        };

        self.userGroupId = null;
        self.modelStateObserver = null;
        self.tabActive = ko.observable();
        self.tabMetadata = {
            greetings: { id: "greetings", isValid: ko.observable(true), title: _i18n.t('userGroupVoicemail:greetingsTab') },
            subscribers: { id: "subscribers", isValid: ko.observable(true), title: _i18n.t('userGroupVoicemail:subscribersTab') },
        };
        self.tabs = [
            new TabPresentationObjectConstructor(self.tabMetadata.greetings.id, self.tabActive, self.tabMetadata.greetings.isValid, self.tabMetadata.greetings.title),
            new TabPresentationObjectConstructor(self.tabMetadata.subscribers.id, self.tabActive, self.tabMetadata.subscribers.isValid, self.tabMetadata.subscribers.title)
        ];
        self.tabActive(self.tabs[0].id);
        self.isFirstTabActive = ko.computed(() => self.tabActive() === self.tabs[0].id);
        self.isTabActive = (tabId) => {
            return self.tabActive() === tabId;
        };
        self.greetingsTabViewModel = null;
        self.subscribersTabViewModel = null;
        self.activeTabViewModel = ko.observable();

        self.forceValidation = () => {
            let validationPromiseFactory = new PromiseFactoryConstructor();
            let isGreetingsTabValid;
            let isSubscribersTabValid;

            validationPromiseFactory.defer((greetingsTabPromise) => {
                self.greetingsTabViewModel.validate()
                    .fail(greetingsTabPromise.reject)
                    .done((isValid) => {
                        isGreetingsTabValid = isValid;
                        greetingsTabPromise.resolve();
                    });
            });
            validationPromiseFactory.defer((subscriberSelectionPromise) => {
                self.subscribersTabViewModel.subscriberSelectionViewModel.validate()
                    .fail(subscriberSelectionPromise.reject)
                    .done((isValid) => {
                        isSubscribersTabValid = isValid;
                        subscriberSelectionPromise.resolve();
                    });
            });
            
            return _promiseFactory.defer((validationPromise)=> {
                validationPromiseFactory.wait()
                    .fail(validationPromise.reject)
                    .done(() => {
                        validationPromise.resolve(isGreetingsTabValid && isSubscribersTabValid);
                    });
            });
        };

        self.save = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let savePromiseFactory = new PromiseFactoryConstructor();
                savePromiseFactory.defer((greetingsPromise) =>{
                    self.greetingsTabViewModel.save(self.userGroupId)
                        .fail(greetingsPromise.reject)
                        .done(greetingsPromise.resolve);
                });
                savePromiseFactory.defer((subscribersPromise) => {
                    self.subscribersTabViewModel.save(self.userGroupId)
                        .fail(subscribersPromise.reject)
                        .done(subscribersPromise.resolve);
                });

                savePromiseFactory.wait()
                    .fail(promise.reject)
                    .done(() => {
                        self.modelStateObserver.saveData();
                        promise.resolve();
                    });
            });
        };

        self.savePage = (groupId = null) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                if (groupId) {
                   self.userGroupId = groupId;
                }

                self.forceValidation()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            promise.resolve();
                        } else {
                            self.save()
                                .fail(promise.reject)
                                .done(promise.resolve);
                        }
                    });
            });
        };

        self.cancelForm = () => {
            return _promiseFactory.defer((promise) => {
                self.greetingsTabViewModel.cancelForm()
                    .fail(promise.reject)
                    .done(() => {
                        self.modelStateObserver.restoreData();
                        promise.resolve();
                    });
            });
        };

        self.dispose = () => {
            self.greetingsTabViewModel.disposeSubscriptions();

            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = (userGroupId) => {
            self.userGroupId = userGroupId;

            const VoicemailGreetingsViewModelConstructor = require('presentation/settings/userGroup/viewModels/userGroupVoicemailGreetingsViewModel');
            self.greetingsTabViewModel = new VoicemailGreetingsViewModelConstructor();

            const VoicemailSubscribersViewModelConstructor = require('presentation/settings/userGroup/viewModels/userGroupVoicemailSubscribersViewModel');
            self.subscribersTabViewModel = new VoicemailSubscribersViewModelConstructor();

            return _initialize();
        };

        const _initialize = () => {

            _initializeTabs()
                .done(() => {
                    self.modelStateObserver = new ModelStateObserverConstructor(self, true, [self.greetingsTabViewModel.modelStateObserver, self.subscribersTabViewModel.modelStateObserver]);
                    self.modelStateObserver.commitData();
                });

            return _promiseFactory.wait();

        };

        const _initializeTabs = () => {
            return _promiseFactory.defer((initTabsPromise) => {
                let initTabsPromiseFactory = new PromiseFactoryConstructor();
                initTabsPromiseFactory.defer((greetingsPromise) => {
                    self.greetingsTabViewModel.activate(self.userGroupId)
                        .fail(greetingsPromise.reject)
                        .done(greetingsPromise.resolve);
                });
                initTabsPromiseFactory.defer((subscribersPromise) => {
                    self.subscribersTabViewModel.activate(self.userGroupId)
                        .fail(subscribersPromise.reject)
                        .done(subscribersPromise.resolve);
                });

                initTabsPromiseFactory.wait()
                    .fail(initTabsPromise.reject)
                    .done(() => {
                        let firstTab = self.tabs.find(t => true);
                        self.tabActive(firstTab.id);

                        _updateActiveTabViewModel();
                        _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                        initTabsPromise.resolve();
                    });
            });
        };
    };
});

