define('presentation/messages/viewModels/conversationLinksViewModel',[
    'businessServices/events/eventManager'
], function (
    /** @type import('businessServices/events/eventManager') */
    EventManager
) {
    return function (
        /** @type import('presentation/messages/utils/conversationLinksManager') */
        linksManager,
        /** @type {KnockoutObservable<boolean>}*/
        showAllLinks
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationLinksViewModel')} ConversationLinksViewModel */

        /** @type {ConversationLinksViewModel}*/
        const self = this;

        /** @type {IDisposable[]} */
        let _disposables = [];

        const _loadNextBatchOfLinks = (/** @type {string} */scrollEventSource) => {
            if (scrollEventSource !== self.scrollEventSource) {
                return;
            }

            linksManager.getNextPage();
        };

        self.showLinksLoadingState = ko.pureComputed(() => linksManager.isLoading() === true);

        self.linkPreviews = linksManager.messageLinkPreviews;

        self.showAllLinks = showAllLinks;

        // 12 loading state links ~ = the height of a full page of links
        self.loadingStateLinks = Array(12);

        self.scrollEventSource = 'messagesConversationLinks';

        self.onClickClose = () => {
            self.showAllLinks(false);
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = () => {
            EventManager.subscribeBottomScrollBufferReached(_loadNextBatchOfLinks);
        };
    };
});
