define('businessServices/converters/callDistributionTypeToCallDistributionMethodNameConverter',['durandal/system',
        'constants/callDistributionTypeConstants'
],function () {
    
    var CallDistributionTypeConstructor = require('constants/callDistributionTypeConstants');
	var _callDistributionTypes = new CallDistributionTypeConstructor();
	
    var _findCallDistributionMethodName = function (callDistributionType) {
		var callDistributionMethod = _callDistributionTypes.callDistributionMethodOptions;
        if (callDistributionType) {
			
            for (var x = 0; x < callDistributionMethod.length; x++) {
				if (callDistributionMethod[x].value === callDistributionType) {
                    return callDistributionMethod[x].name;
                }
            }
        }

        return null;
    };

    return function() {
        var self = this;
        self.convert = _findCallDistributionMethodName;
    };
});
