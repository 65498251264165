define('presentation/settings/schedules/validators/scheduleRangeViewModelValidator',[
    'common/promises/promiseFactory',
    'constants/autoAttendantVoiceRouteEnumerations',
    'presentation/common/routeToModal/common/routeToValidationHelper',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const RouteToValidationHelperConstructor = require('presentation/common/routeToModal/common/routeToValidationHelper');
        const _validationHelper = new RouteToValidationHelperConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _ranges;
        let _viewModel;

        self.registerViewModel = (viewModel, ranges) => {
            _viewModel = viewModel;
            _ranges = ranges;

            let validationRules = new ValidationRulesConstructor();
            validationRules.fieldGroup("areSelectedDaysValid", ['isSundaySelected', 'isMondaySelected', 'isTuesdaySelected', 'isWednesdaySelected', 'isThursdaySelected', 'isFridaySelected', 'isSaturdaySelected'])
                .addValidationI18n(_areAnyDaysSelected, 'addSchedule:selectedDayRequired');
            validationRules.fieldGroup("areTimeSelectionsValid", ['selectedStartTime2400', 'selectedEndTime2400'])
                .addValidationI18n(_isStartTimeBeforeEndTime, 'addSchedule:timeSelectionsInvalid');
            validationRules.fieldGroup("doesSegmentOverlap", ['isSundaySelected', 'isMondaySelected', 'isTuesdaySelected', 'isWednesdaySelected', 'isThursdaySelected', 'isFridaySelected', 'isSaturdaySelected', 'selectedStartTime2400', 'selectedEndTime2400'])
                .addValidationI18n(_segmentsShouldNotOverlap, 'addSchedule:segmentsShouldNotOverlap');
            validationRules.field('rangeName')
                .addValidationI18n(_rangeNameMustBeProvided, 'addSchedule:nameRequired')
                .addValidationI18n(_rangeNameIsUnique, 'addSchedule:rangeNameMustBeUnique');
            validationRules.field('isRoutingRuleValid')
                .addValidationI18n(_isRoutingRuleValid, 'addSchedule:routingRuleError');

            _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules, true);
        };

        const _rangeNameMustBeProvided = (rangeName) => _commonValidator.isStringWithValue(rangeName);

        const _areAnyDaysSelected = () => {
            const areAnyDaysSelected = _viewModel.isSundaySelected() ||
                _viewModel.isMondaySelected() ||
                _viewModel.isTuesdaySelected() ||
                _viewModel.isWednesdaySelected() ||
                _viewModel.isThursdaySelected() ||
                _viewModel.isFridaySelected() ||
                _viewModel.isSaturdaySelected();
            return areAnyDaysSelected;
        };

        const _isStartTimeBeforeEndTime = () => {
            const isStartTimeBeforeEndTime = _viewModel.selectedStartTime2400() < _viewModel.selectedEndTime2400();
            return isStartTimeBeforeEndTime;
        };

        const _segmentsShouldNotOverlap = () => {
            return _viewModel.doAnySegmentsOverlap();
        };

        const _rangeNameIsUnique = () => _commonValidator.isUnique(_viewModel, _ranges, "sequence", "rangeName");

        const _isRoutingRuleValid = () =>  {
            return _promiseFactory.defer((deferredObject) => {
                _validationHelper.validateRouteToViewModel(_viewModel.routeToViewModel())
                    .done((result) => {
                        deferredObject.resolve(result);
                    });
            });
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.resetValidation = () => {
            _viewModel.name.resetValidation();
        };
    };
});

