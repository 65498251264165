define('presentation/common/paymentMethod/validators/addPaymentMethodValidator',[
    'businessServices/country/countryCode',
    'businessServices/creditCards/creditCardValidations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
    ], function() {
        return function() {
            let self = this;
    
            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const CountryCodeConstructor = require('businessServices/country/countryCode');
            const CreditCardValidationConstructor = require('businessServices/creditCards/creditCardValidations');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
    
            const _creditCardValidations = new CreditCardValidationConstructor();
            const _countries = new CountryCodeConstructor();

            let _commonValidator = null;
            let _viewModel = null;

            self.registerViewModel = function(viewModel) {
                _viewModel = viewModel;
                _commonValidator = new CommonValidatorConstructor(_viewModel);
    
                const validationRules = new ValidationRulesConstructor();
                validationRules.field("cardHolderName")
                    .addValidationI18n(_commonValidator.isStringWithValue, 'paymentMethod:cardHolderNameRequired')
                    .addValidationI18n(_noSpecialCharacters, 'paymentMethod:noSpecialCharacters');
                validationRules.field("cardNumber")
                    .addValidationI18n(_commonValidator.isStringWithValue, 'paymentMethod:cardNumberRequired')
                    .addValidationI18n(_creditCardValidations.validateCardNumber, 'paymentMethod:cardNumberInvalid');
                validationRules.field('cvvSecurityCode')
                    .addValidationI18n(_commonValidator.isStringWithValue, 'addPaymentMethod:cvvRequiredToAdd')
                    .addValidationI18n(_isCvvValid, 'addPaymentMethod:cvvInvalid');
                validationRules.fieldGroup("expirationDate", ["monthSelected", "yearSelected"])
                    .addValidationI18n(_validateExpirationDate, 'paymentMethod:expirationDateInvalid');
                validationRules.field("postalCode")
                    .addValidationI18n(_commonValidator.isStringWithValue, _postalCodeRequiredI18nKey)
                    .addValidationI18n(_validatePostalCodeValid, _postalCodeInvalidI18nKey);

                validationRules.validationGroupKeyUp('isValid', ["cardHolderName", "cardNumber", "expirationDate", "cvvCode", "postalCode"]);
    
                _commonValidator.setValidationRules(validationRules);
            };
    
            self.validate = () => {
                return _commonValidator.validate();
            };

            const _noSpecialCharacters = (name) => /^[a-zA-Z0-9 ']*$/.test(name);
    
            const _isCvvValid = (cvv) => _creditCardValidations.validateCvv(cvv, _viewModel.cardNumber());
    
            const _validatePostalCodeValid = (postalCode) => _creditCardValidations.validatePostalCodeFormat(postalCode, _currentCountry());
    
            const _validateExpirationDate = (monthSelected, yearSelected) => {
                return _creditCardValidations.validateExpirationDate(monthSelected, yearSelected);
            };

            const _currentCountry = ko.pureComputed(() => {
                if (_viewModel.countrySelected()) {
                    return _countries.findByAbbreviation(_viewModel.countrySelected().countryAbbreviation);
                }
        
                return _countries.getDefaultCountry();
            });
    
            const _postalCodeRequiredI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'paymentMethod:postalCodeRequired' : 'paymentMethod:zipCodeRequired');
    
            const _postalCodeInvalidI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'paymentMethod:postalCodeInvalid' : 'paymentMethod:zipCodeInvalid');
    };
});

