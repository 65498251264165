define('presentation/contacts/viewModels/addContactEmailAddressViewModel',[
    'businessServices/state/modelStateObserver',
    'constants/contactEmailAddressLabelEnumerations'
], function (
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('constants/contactEmailAddressLabelEnumerations') */
    ContactEmailAddressLabel
) {
    /** @typedef { import('presentation/contacts/viewModels/addContactViewModel') } AddContactViewModel */

    return function (/** @type AddContactViewModel */ parent) {

        let self = this;

        self.parent = parent;

        self.modelStateObserver = null;

        self.accountContactEmailAddressId = '';
        self.label = ko.observable(ContactEmailAddressLabel.Work.toString()).extend({observeState: true});
        self.email = ko.observable('').extend({observeState: true});

        self.onRemoveEmailAddressClick = () => {
            self.parent.onRemoveEmailAddressClick(self);
        };

        self.cancelForm = () => {
            if (self.email.isDirty) {
                self.email.forceUpdate(true);
            }

            self.modelStateObserver.restoreData();
        };


        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserver(self, true);
            self.modelStateObserver.commitData();
        };

        _initialize();
    };
});
