define('presentation/settings/phoneNumbers/viewModels/editPhoneNumberTextMessagingTabViewModel',[
    'presentation/common/window/windowControl',
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/permissionInheritanceConstants',
    'constants/smsCampaignRegistrationConstants',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/settings/phoneNumbers/facades/editPhoneNumberTextMessagingTabFacade',
    'presentation/settings/phoneNumbers/viewModels/messagingFeeConfirmationModalViewModel',
    'settings/navigationConfiguration',
    'businessServices/router/router'
],
    function () {
        return function () {
            let self = this;

            const ActionModal = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            const MessagingFeeConfirmationModalViewModel = require('presentation/settings/phoneNumbers/viewModels/messagingFeeConfirmationModalViewModel');
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
            const _inheritanceConstants = require('constants/permissionInheritanceConstants');
            const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');
            const _windowControl = require('presentation/common/window/windowControl');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _i18n = require('i18next');
            const _urlFormatter = require('common/url/urlFormatter');
            const _router = require('businessServices/router/router');

            let _facade = null;
            let _disposables = [];

            const _updateInheritanceObservable = () => {
                self.isSmsPermissionInherited(self.isSmsPermissionInheritedSelectedOption() === _inheritanceConstants.enabled);
            };

            const _formatHostedNumbersForWarning = (smsEnabledLocalHostedNumbers) => {
                return smsEnabledLocalHostedNumbers.map((hostedNumber) => {
                    return {
                        number: hostedNumber.formattedPhoneNumber,
                        location: hostedNumber.location,
                        hasLocation: hostedNumber.location.length > 0
                    };
                });
            };

            const _showSmsConfirmationModal = () => {
                if (self.isInitialSmsStatus()) {
                    return;
                }

                if (self.showEnableSmsModal()) {
                    _showEnableSmsConfirmationModal();
                }
                else if (self.showDisableSmsModal()) {
                    _showDisableSmsConfirmationModal();
                }
            };

            const _buildConfirmationModal = () => {
                const actionModal = new ActionModal();
                actionModal
                    .clearModal()
                    .setContentViewModel(MessagingFeeConfirmationModalViewModel, [self.isSoleProprietor, self.hasOnlyOneEnabledLocalNumber])
                    .setHeaderText({i18n: 'messagingFeeConfirmationModal:headerTitle'})
                    .setShouldDisplayCancelButton(true)
                    .setShouldDisplaySubmitButton(true)
                    .setSubmitButtonText({i18n: 'continue'})
                    .setShouldScrollIntoView(false)
                    .setIsScrollDisabled(true);

                return actionModal;
            };

            const _showEnableSmsConfirmationModal = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    const actionModal = _buildConfirmationModal();

                    actionModal
                        .showModal()
                        .done((result) => {
                            if (result.isValid) {
                                self.customerAcknowledgedSmsNotice(true);
                                promise.resolve();
                            }
                            if (result === ActionModal.defaultCancelResponse) {
                                self.customerAcknowledgedSmsNotice(false);
                                self.isSmsEnabled(false);
                                promise.resolve();
                            }
                        });
                });
            };

            const _showDisableSmsConfirmationModal = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    const actionModal = _buildConfirmationModal();

                    actionModal
                        .showModal()
                        .done((result) => {
                            if (result.isValid) {
                                self.customerAcknowledgedSmsNotice(true);
                                promise.resolve();
                            }
                            if (result === ActionModal.defaultCancelResponse) {
                                self.customerAcknowledgedSmsNotice(false);
                                self.isSmsEnabled(true);
                                promise.resolve();
                            }
                        });
                });
            };

            const _setSmsStatusVariables = (smsStatusResponse) => {
                if (smsStatusResponse.registrationStatus.canSmsBeEnabled === false) {
                    self.isSmsEnabled(false);
                }

                self.canSmsBeEnabled(smsStatusResponse.registrationStatus.canSmsBeEnabled);
                self.smsDisabledReason(smsStatusResponse.registrationStatus.smsDisabledReason);
                self.smsBwRegistrationFailureReason(smsStatusResponse.registrationStatus.smsBwRegistrationFailureReason);
                self.isSmsRegistrationPending(smsStatusResponse.registrationStatus.isSmsRegistrationPending);
                self.hasSmsRegistrationSucceeded(smsStatusResponse.registrationStatus.hasSmsRegistrationSucceeded);
                self.isSoleProprietor(smsStatusResponse.registrationStatus.isSoleProprietor);
                self.hasExistingCampaign(smsStatusResponse.registrationStatus.hasExistingCampaign);
                self.hasOnlyOneEnabledLocalNumber(smsStatusResponse.registrationStatus.hasOnlyOneEnabledLocalNumber);
                self.warningPhoneNumbers(_formatHostedNumbersForWarning(smsStatusResponse.smsEnabledLocalHostedNumbers));
            };

            self.toggleOptions = [
                {title: 'Disabled', value: false},
                {title: 'Enabled', value: true}
            ];

            self.inheritanceOptions = ko.observableArray([
                { id: _inheritanceConstants.enabled, text: _i18n.t('editPhoneNumberTextMessagingTab:inheritPermissionEnabled') },
                { id: _inheritanceConstants.disabled, text: _i18n.t('editPhoneNumberTextMessagingTab:inheritPermissionDisabled') }
            ]);

            self.phoneNumber = ko.observable();
            self.isLocalPhoneNumber = ko.observable(false);
            self.isSmsEnabled = ko.observable(false);
            self.initialIsSmsEnabledValue = ko.observable(false);
            self.isSmsPermissionInherited = ko.observable(false);
            self.phoneConnectorId = null;
            self.isSmsPermissionInheritedSelectedOption = ko.observable();
            self.canSmsBeEnabled = ko.observable(false);
            self.smsDisabledReason = ko.observable();
            self.smsBwRegistrationFailureReason = ko.observable();
            self.isSmsRegistrationPending = ko.observable(false);
            self.hasSmsRegistrationSucceeded = ko.observable(false);
            self.isSoleProprietor = ko.observable(false);
            self.hasExistingCampaign = ko.observable(false);
            self.hasOnlyOneEnabledLocalNumber = ko.observable(false);
            self.warningPhoneNumbers = ko.observable([]);
            self.customerAcknowledgedSmsNotice = ko.observable(false);


            self.isToggleDisabled = ko.pureComputed(() => {
                return !self.canSmsBeEnabled() || self.isSmsRegistrationPending();
            });

            self.messagingRegistrationWillBeQueued = ko.pureComputed(() => {
                return self.canSmsBeEnabled() &&
                    self.isSmsEnabled() &&
                    !self.isSmsRegistrationPending() &&
                    !self.hasSmsRegistrationSucceeded();
            });

            self.isBillingAdmin = ko.pureComputed(() => _sessionAccountInfo.accountPermissions().Billing);
            self.showNoMessagingComplianceMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.NO_MESSAGING_COMPLIANCE);
            self.showNeedMoreComplianceInfoMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.REACHED_SP_LIMIT);
            self.showPersonalAccountSmsLimitReachedMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.REACHED_PERSONAL_ACCOUNT_LIMIT);
            self.showTcrRegistrationFailedMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.TCR_REGISTRATION_FAILED);
            self.showBandwidthAssociationFailedMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.BW_ASSOCIATION_FAILED);
            self.showPortPendingMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.PORT_PENDING);
            self.showTollFreeSmsLimitReachedMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.REACHED_TOLL_FREE_SMS_LIMIT);
            self.showInFreeTrialMessage = ko.pureComputed(() => self.smsDisabledReason() === _smsCampaignRegistrationConstants.smsDisabledReasons.IN_FREE_TRIAL);

            self.showBandwidthRegistrationFailureReason = ko.pureComputed(() => !!self.smsBwRegistrationFailureReason());

            self.messagingRegistrationIsPendingTitle = ko.pureComputed(() => {
                if (self.isLocalPhoneNumber()) {
                    return _i18n.t('editPhoneNumberTextMessagingTab:messagingRegistrationIsPending:title');
                }
                return _i18n.t('editPhoneNumberTextMessagingTab:messagingRegistrationIsPendingTollFree:title');
            });

            self.messagingRegistrationIsPendingWarningPartOne = ko.pureComputed(() => {
                if (self.isLocalPhoneNumber()) {
                    return _i18n.t('editPhoneNumberTextMessagingTab:messagingRegistrationIsPending:warningPart1');
                }
                return _i18n.t('editPhoneNumberTextMessagingTab:messagingRegistrationIsPendingTollFree:warningPart1');
            });

            self.messagingRegistrationIsPendingWarningPartTwo = ko.pureComputed(() => {
                if (self.isLocalPhoneNumber()) {
                    return _i18n.t('editPhoneNumberTextMessagingTab:messagingRegistrationIsPending:warningPart2');
                }
                return _i18n.t('editPhoneNumberTextMessagingTab:messagingRegistrationIsPendingTollFree:warningPart2');
            });

            self.resubmitButtonText = _i18n.t('editPhoneNumberTextMessagingTab:resubmit');

            self.showEnableSmsModal = ko.pureComputed(() => self.isLocalPhoneNumber() && self.hasExistingCampaign() === false);
            self.showDisableSmsModal = ko.pureComputed(() => self.isLocalPhoneNumber() && self.hasOnlyOneEnabledLocalNumber() === true);
            self.showResubmitTollFreeVerification = ko.pureComputed(() => {
                return _sessionAccountInfo.isImpersonating() && self.showBandwidthAssociationFailedMessage() && self.isLocalPhoneNumber() === false;
            });

            self.isInitialSmsStatus = ko.pureComputed(() => self.isSmsEnabled() === self.initialIsSmsEnabledValue());

            self.isCustomerAcknowledgedSmsFeeValid = ko.pureComputed(() => {
                if (self.isInitialSmsStatus() === false &&
                    (self.showEnableSmsModal() || self.showDisableSmsModal())) {
                    return self.customerAcknowledgedSmsNotice();
                }
                return true;
            });

            self.toggleMessageKey = ko.pureComputed(() => {
                if (self.messagingRegistrationWillBeQueued() || self.isSmsRegistrationPending()) {
                    return 'editPhoneNumberTextMessagingTab:pending';
                }

                return self.isSmsEnabled() ? 'editPhoneNumber:enabled' : 'editPhoneNumber:disabled';
            });

            self.openTcrPage = () => _windowControl.openTcrUrl();
            self.openMessagingCompliancePage = () => {
                const messagingComplianceUrl = _urlFormatter.buildWorkSessionUrl(_navigationConfiguration.routesById.messagingCompliance.url);
                _windowControl.openNewWindow(messagingComplianceUrl);
            };

            self.sendFeeAutoNotation = () => {
                return _promiseFactory.defer((deferredObject) => {
                    if (self.isInitialSmsStatus() === false &&
                        self.customerAcknowledgedSmsNotice() &&
                        (self.showEnableSmsModal() || self.showDisableSmsModal())) {
                        _facade.sendMessagingFeeConfirmationAutoNotation(self.phoneNumber())
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    } else {
                        deferredObject.resolve();
                    }
                });
            };

            self.resubmitTollFreeVerification = () => {
                return _promiseFactory.defer((deferredObject) => {
                    _facade.resubmitTollFreeVerification(self.phoneConnectorId)
                        .fail(deferredObject.reject)
                        .done(() => {
                            _router.navigateBack();
                            deferredObject.resolve();
                        });
                });
            };

            self.validate = () => {
                return _promiseFactory.defer((deferredObject) => {
                    const isValid = self.isCustomerAcknowledgedSmsFeeValid();
                    deferredObject.resolve(isValid);
                });
            };

            self.dispose = () => {
                _facade.dispose();

                _disposables.forEach((disposable) => {
                    disposable.dispose();
                });

                _disposables = [];
            };

            self.activate = (phoneNumber, isLocalPhoneNumber, isSmsEnabled, isSmsPermissionInherited, authorizedMessagersSelectionViewModel, phoneConnectorId) => {
                const FacadeConstructor = require('presentation/settings/phoneNumbers/facades/editPhoneNumberTextMessagingTabFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                self.authorizedMessagersSelectionViewModel = authorizedMessagersSelectionViewModel;
                self.authorizedMessagersSelectionViewModel.subscriberSelectionViewModel().membershipDescription(_i18n.t('editPhoneNumberTextMessagingTab:manualDescription'));
                self.authorizedMessagersSelectionViewModel.yellowBoxInheritedAction(_i18n.t('editPhoneNumberTextMessagingTab:action'));
                self.authorizedMessagersSelectionViewModel.yellowBoxAlternateInheritedAction(_i18n.t('editPhoneNumberTextMessagingTab:actionAlternate'));
                self.authorizedMessagersSelectionViewModel.yellowBoxManualOption(_i18n.t('editPhoneNumberTextMessagingTab:inheritPermissionDisabled'));
                self.authorizedMessagersSelectionViewModel.yellowBoxGrantedPermission(_i18n.t('editPhoneNumberTextMessagingTab:grantPermissions'));
                self.authorizedMessagersSelectionViewModel.inheritedSubscriptionsDescription(_i18n.t('editPhoneNumberTextMessagingTab:inheritedDescription'));
                self.authorizedMessagersSelectionViewModel.inheritedPermissionChecklist([
                    _i18n.t('editPhoneNumberTextMessagingTab:beIncludedIn'),
                    _i18n.t('editPhoneNumberTextMessagingTab:beAbleTo')
                ]);

                return _initialize(phoneNumber, isLocalPhoneNumber, isSmsEnabled, isSmsPermissionInherited, phoneConnectorId);
            };

            const _initialize = (phoneNumber, isLocalPhoneNumber, isSmsEnabled, isSmsPermissionInherited, phoneConnectorId) => {
                self.phoneNumber(phoneNumber);
                self.isLocalPhoneNumber(isLocalPhoneNumber);
                self.isSmsEnabled = isSmsEnabled;
                self.isSmsPermissionInherited = isSmsPermissionInherited;
                self.phoneConnectorId = phoneConnectorId;
                self.isSmsPermissionInheritedSelectedOption = ko.observable(
                    self.isSmsPermissionInherited() ? _inheritanceConstants.enabled : _inheritanceConstants.disabled
                );

                _facade.getHostedNumberSmsRegistrationInfo(phoneConnectorId)
                    .done((response) => {
                        _setSmsStatusVariables(response);
                        self.initialIsSmsEnabledValue(self.isSmsEnabled());
                        _disposables.push(self.isSmsEnabled.subscribe(_showSmsConfirmationModal));
                    });

                _facade.onSmsRegistrationChange(_setSmsStatusVariables);

                _disposables.push(self.isSmsPermissionInheritedSelectedOption.subscribe(_updateInheritanceObservable));

                return _promiseFactory.wait();
            };
        };
    });

