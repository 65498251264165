define('presentation/settings/schedules/facades/schedulesFacade',[
    'constants/referentialConstraintEnumerations',
    'externalDependencies/clientWebSocket',
    'persistence/webSocket/webSocketApp',
    'presentation/common/dateTimeValue',
    'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
    'presentation/settings/schedules/presentationObjects/schedulePresentationObject',
    'presentation/settings/schedules/presentationObjects/scheduleSegmentPresentationObject',
    'settings/navigationConfiguration',
], function() {
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');
    const SchedulePresentationObjectConstructor = require('presentation/settings/schedules/presentationObjects/schedulePresentationObject');
    const ScheduleSegmentPresentationObjectConstructor = require('presentation/settings/schedules/presentationObjects/scheduleSegmentPresentationObject');

    const scheduleSocket = require('externalDependencies/clientWebSocket').forApplication("schedule");

    const _integrityWarningMessagesEnumeration = require('constants/referentialConstraintEnumerations');
    const _navigationConfiguration = require('settings/navigationConfiguration');

    let _promiseFactory = null;
    let _webSocketApp = null;

    const _getSchedules = () => {
        return _promiseFactory.defer((deferredObject) => {

            scheduleSocket.send("retrieveAll", {}, (accountSchedules, err) => {
                if (err) {
                    deferredObject.reject(new Error(err));
                } else {
                    const displaySchedules = accountSchedules.map((currentSchedule) => {
                        const displaySchedule = new SchedulePresentationObjectConstructor();
                        displaySchedule.accountAttendantScheduleId = currentSchedule.accountAttendantScheduleId;
                        displaySchedule.name = currentSchedule.name;
                        displaySchedule.createdDateTime = new DateTimeValueConstructor(currentSchedule.createdDateTime);
                        displaySchedule.modifiedDateTime = new DateTimeValueConstructor(currentSchedule.updatedDateTime);
                        displaySchedule.editScheduleUrl = _navigationConfiguration.routesById.editSchedule.baseUrl + currentSchedule.accountAttendantScheduleId;

                        displaySchedule.segments = currentSchedule.segments.map((currentSegment) => {
                            const displaySegment = new ScheduleSegmentPresentationObjectConstructor();
                            displaySegment.scheduleId = currentSchedule.accountAttendantScheduleId;
                            displaySegment.endHour(currentSegment.endHour);
                            displaySegment.endMinute(currentSegment.endMinute);
                            displaySegment.isForSunday(currentSegment.isForSunday);
                            displaySegment.isForMonday(currentSegment.isForMonday);
                            displaySegment.isForTuesday(currentSegment.isForTuesday);
                            displaySegment.isForWednesday(currentSegment.isForWednesday);
                            displaySegment.isForThursday(currentSegment.isForThursday);
                            displaySegment.isForFriday(currentSegment.isForFriday);
                            displaySegment.isForSaturday(currentSegment.isForSaturday);
                            displaySegment.sequence(currentSegment.sequence);
                            displaySegment.startHour(currentSegment.startHour);
                            displaySegment.startMinute(currentSegment.startMinute);

                            return displaySegment;
                        });

                        return displaySchedule;
                    });

                    deferredObject.resolve(displaySchedules);
                }
            });
        });
    };


    const _deleteSchedule = (scheduleId) => {
        const request = {
            "accountAttendantScheduleId" : scheduleId
        };

        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("delete", request, (result) => {
                promise.resolve();
            });
        });
    };

    const _getConstraints = (scheduleId) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketApp.send("get_constraints", {accountAttendantScheduleId: scheduleId},  (constraints) => {
                let integrityWarnings = [];
                constraints.forEach((referencedObject) => {
                    if (_integrityWarningMessagesEnumeration[referencedObject.type]) {
                        const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                        integrityWarning.entityText = _integrityWarningMessagesEnumeration[referencedObject.type].entityText;
                        integrityWarning.entityName = referencedObject.name;
                        integrityWarning.icon = _integrityWarningMessagesEnumeration[referencedObject.type].icon;
                        integrityWarning.message = _integrityWarningMessagesEnumeration[referencedObject.type].message;
                        integrityWarnings.push(integrityWarning);
                    } else {
                        deferredObject.reject(new Error("Unhandled referenced object type."));
                    }
                });
                deferredObject.resolve(integrityWarnings);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("schedule_listing");
    };

    return function() {
        const self = this;

        self.init = _init;
        self.deleteSchedule = _deleteSchedule;
        self.getConstraints = _getConstraints;
        self.getSchedules = _getSchedules;
    };
});

