define('presentation/signup/presentationObjects/signupNumberPortPresentationObject',[],function() {
    return function() {
        let self = this;

        self.numberPortId = null;
        self.currentCarrier = "";
        self.billingInfoName = "";
        self.billingInfoNumber = "";
        self.billingInfoNumberCountryAbbreviation = "us";
        self.billingInfoAddress = "";
        self.billingInfoApt = "";
        self.billingInfoCity = "";
        self.billingInfoCountry = "";
        self.billingInfoState = "";
        self.billingInfoZip = "";
        self.portAccountNumber = "";
        self.pinOrPasscode = "";
        self.showPostalCode = false;
        self.showZipCode = true;
        self.authorizedSignature = "";
        self.selectedOnlyNumberOption = true;
        self.portType = "FULL";
        self.portDateSelection = "default";
        self.portDate = "";
        self.phoneNumbersToPort = null;
        self.isNumberPortModalCompleted = false;
        self.isNumberPortBillingModalCompleted = false;
        self.isAcknowledged = false;
        self.authorizedSignature = false;
    };
});
