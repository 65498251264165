define('businessServices/pathProviders/voicePromptUrlPathProvider',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], function () {
    const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

    return function () {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let promiseFactory = new PromiseFactoryConstructor();

        self.getPromptUrls = function(voicePromptId){
            return promiseFactory.defer(function(promise){

                promptSocket.send("getVoicePromptMp3Url", {accountVoicePromptId: voicePromptId}, (result) => {
                    if (result.isSuccessful) {
                        promise.resolve(result.url);
                        return;
                    } else {
                        promise.reject(new Error("unable to find voice prompt"));
                        return;
                    }
                });
            });
        };

        self.getSystemPromptUrl = (systemPromptId) => {
            return promiseFactory.defer((promise) => {
                promptSocket.send("getSystemAudioClipUrl", {systemAudioClipId: systemPromptId}, (result) => {
                    if (result.isSuccessful) {
                        promise.resolve(result.url);
                        return;
                    } else {
                        promise.reject(new Error("unable to find system prompt"));
                        return;
                    }
                });
            });
        };

    };
});

