define('common/storage/temporaryStorage',[], function() {
    var _storage = window.sessionStorage;

    function getObject(key) {
        var serialized = _storage.getItem(key);
        if (serialized) {
            return JSON.parse(serialized);
        } else {
            return {};
        }
    }

    function saveObject(key, value) {
        var serialized = JSON.stringify(value);
        _storage.setItem(key, serialized);
    }

    function deleteObject(key) {
        _storage.removeItem(key);
    }

    return {
        saveObject : saveObject,
        getObject : getObject,
        deleteObject : deleteObject
    };
});
