define('settings/i18nOptions',[
    'common/presentation/i18nResources'
], function () {
    return {
        debug: false,
        detectFromHeaders: false,
        lng: window.navigator.language || 'en-US',
        fallbackLng: 'en-US',
        resources: require('common/presentation/i18nResources'),
        ns: [],
        fallbackNS: "common",
        useCookie: false,
        interpolation: {
            format: function(value, formatter, lng) {
                if (formatter === 'makePossessive') {
                    return value.endsWith("s") ? value + "'": value + "'s";
                }
                return value;
            }
        }
    };
});

