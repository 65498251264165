define('common/specifications/validUrlAddressSpecification',[], function() {
    return function() {
        const self = this;

        const URL_ADDRESS_REGULAR_EXPRESSION = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/);

        self.isSatisfiedBy = (urlAddress) => {
            if (urlAddress) {
                const isValid = URL_ADDRESS_REGULAR_EXPRESSION.test(urlAddress);
                return isValid;
            }

            return false;
        };
    };
});

