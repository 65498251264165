define('presentation/common/callFlow/viewModels/autoAttendantSubMenuCallFlowViewModel',['common/promises/promiseFactory',
    'common/storage/commonState',
    'common/time/datetimeFormatter',
    'presentation/common/callFlow/facades/autoAttendantSubMenuCallFlowFacade',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',
    'presentation/common/callFlow/viewModels/autoAttendantSubMenuCallFlowViewModel',
    'presentation/common/callFlow/viewModels/callFlowViewModel',
    'businessServices/events/eventManager'], function() {
    return function(subMenuCallFlow, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');
        const SubMenuCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantSubMenuCallFlowViewModel');
        const CallFlowViewModelConstructor = require('presentation/common/callFlow/viewModels/callFlowViewModel');

        const _commonState = require('common/storage/commonState');

        let _facade = null;

        const KEY_ORDER = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*'];

        const _getSubMenuCallFlow = (subMenuCallFlow) => {
            let subMenuItems = [];

            KEY_ORDER.forEach((key) => {
                let subMenuItem = subMenuCallFlow.subMenuItems.find(subMenuItem => subMenuItem.key === key);
                if (subMenuItem) {
                    subMenuItems.push(subMenuItem);
                }
            });

            subMenuCallFlow.subMenuItems = subMenuItems;

            subMenuCallFlow.subMenuItems.forEach(subMenuItem => {
                subMenuItem.callFlow.forEach(callFlow => {

                    let autoAttendantsInNextCallFlow = autoAttendantsInCurrentCallFlow.slice(0);

                    if (callFlow.routeType() === _commonState.types.autoAttendant) {
                        let isLoopedCallFlow = autoAttendantsInCurrentCallFlow.some(autoAttendantId => autoAttendantId === callFlow.routeToItemId);
                        callFlow.showLoopedCallFlow(isLoopedCallFlow);

                        if (!isLoopedCallFlow) {
                            let autoAttendant = new AutoAttendantCallFlowConstructor(callFlow.routeToItemId, autoAttendantsInNextCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                            autoAttendant.activate();
                            callFlow.autoAttendant(autoAttendant);
                        }
                    }
                    if (callFlow.routeType() === _commonState.types.subMenu) {
                        let subMenuViewModel = new SubMenuCallFlowConstructor(callFlow, autoAttendantsInNextCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                        subMenuViewModel.activate();
                        callFlow.subMenu(subMenuViewModel);
                    }

                    if (callFlow.routeType() === _commonState.types.schedule) {
                        callFlow.scheduleSegments.forEach(segment => {
                            segment.callFlow.forEach(segmentCallFlow => {
                                if (segmentCallFlow.routeType() === _commonState.types.autoAttendant) {
                                    let isLoopedCallFlow = autoAttendantsInCurrentCallFlow.some(autoAttendantId => autoAttendantId === segmentCallFlow.routeToItemId);
                                    segmentCallFlow.showLoopedCallFlow(isLoopedCallFlow);
                                    autoAttendantsInNextCallFlow.push(segmentCallFlow.routeToItemId);
                                    if (!isLoopedCallFlow) {
                                        let autoAttendant = new AutoAttendantCallFlowConstructor(segmentCallFlow.routeToItemId, autoAttendantsInNextCallFlow,  availableVoicePrompts, availableSchedules, availableAutoAttendants);
                                        autoAttendant.activate();
                                        segmentCallFlow.autoAttendant(autoAttendant);
                                    }
                                }
                            });
                        });
                    }

                });
            });
            self.subMenuCallFlow(subMenuCallFlow);
            self.callFlowViewModel = new CallFlowViewModelConstructor();
            self.callFlowViewModel.activate(self);
        };

        self.subMenuCallFlow = ko.observable(null);
        self.showCallFlowDetails = ko.observable(true);
        self.showDeactivated = ko.observable(false);
        self.deactivatedMessage = ko.observable("");

        self.toggleCallFlowDetails = (subMenuPresentationObject) => {
            subMenuPresentationObject.showCallFlowDetails(!subMenuPresentationObject.showCallFlowDetails());
        };

        self.expandAllView = () => {
            self.showCallFlowDetails(true);

            self.subMenuCallFlow().subMenuItems.forEach((subMenuItem) => {
                subMenuItem.showSubMenuCallFlow(true);
                subMenuItem.showSubMenuCallFlowDetails(true);
                subMenuItem.showScheduleDetails(true);

                subMenuItem.callFlow.forEach((item) => {
                    if (item.scheduleSegments) {
                        item.showSubMenuCallFlow(true);
                        item.scheduleSegments.forEach((segment) => {
                            segment.showScheduleSegmentDetails(true);
                        });
                    }
                    if (item.autoAttendant()) {
                        item.showSubMenuCallFlow(true);
                        item.autoAttendant().expandAllView();
                    }
                    if (item.subMenu()) {
                        item.showCallFlowDetails(true);
                        item.subMenu().expandAllView();
                    }
                });
            });
        };

        self.detached = () =>{
            _facade.dispose();
        };


        self.activate = () => {
            const FacadeConstructor = require('presentation/common/callFlow/facades/autoAttendantSubMenuCallFlowFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            _getSubMenuCallFlow(subMenuCallFlow);

             _facade.onNewPromptConverted((promptInfo) => {
                const {accountVoicePromptId, audioData} = promptInfo;

                if (self.subMenuCallFlow().subMenuItems) {
                    self.subMenuCallFlow().subMenuItems.forEach((subMenuItem) => {
                        if (subMenuItem.callFlow) {
                            subMenuItem.callFlow.forEach((callFlowSegment) => {
                                if (callFlowSegment.routeToItemId === accountVoicePromptId) {
                                    callFlowSegment.currentVoicePrompt(audioData);
                                    callFlowSegment.conversionComplete(true);
                                    callFlowSegment.conversionInProgress(false);
                                }
                            });
                        }
                    });
                }
            });

            return _promiseFactory.wait();
        };
    };
});

