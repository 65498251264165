define('presentation/navigation/viewModels/mainNavigationViewModel',['businessServices/authentication/sessionAccountInfo',
        'businessServices/browserSupport/browserType',
        'businessServices/converters/numberFormatter',
        'businessServices/router/router',
        'presentation/inbox/dataSources/recordingSubscriptionDataSource',
        'presentation/inbox/dataSources/voicemailSubscriptionDataSource',
        'presentation/messages/dataSources/webMessagingDataSource'
], function() {
    return function(){
        let self = this;

        const NumberFormatterConstructor = require('businessServices/converters/numberFormatter');
        const _numberFormatter = new NumberFormatterConstructor();

        let _navigationActiveSection = ko.observable("");
        let _router = null;

        const _browserType = require('businessServices/browserSupport/browserType');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        const _callRecordingsSubscriptionsDataSource = require('presentation/inbox/dataSources/recordingSubscriptionDataSource');
        const _voicemailSubscriptionDataSource = require('presentation/inbox/dataSources/voicemailSubscriptionDataSource');
        const _webMessagingDataSource = require('presentation/messages/dataSources/webMessagingDataSource');



        const WINDOW_BREAKPOINT = 1200;
        const MAIN_MENU = "MainMenu";
        /** @type { {[key: string]: number} } */
        const MAIN_MENU_ORDER = {
            'analytics': 0,
            'contacts': 1,
            'calls': 2,
            'messages': 3,
            'inbox': 4,
            'settings': 5
        };

        const _updateNavigation = () => {
            let navigationModel = _router.navigationModel();
            let navigationItems = navigationModel.filter((navigationItem) => {
                if (navigationItem.customSettings &&
                    navigationItem.customSettings.menuType === MAIN_MENU) {
                    let permissionId = navigationItem.customSettings.permission;
                    return checkUserHasPermission(permissionId);
                }
                return false;
            });

            let navigationList = navigationItems.map(navigationItem => {
                const customSettings = navigationItem.customSettings;
                const isActive = ko.pureComputed(() => navigationItem.isActive() || _navigationActiveSection() === customSettings.section);
                const iconName = ko.pureComputed(() => isActive() ? customSettings.iconActive : customSettings.icon);
                const order = navigationItem.customSettings.menuType === MAIN_MENU ? MAIN_MENU_ORDER[customSettings.routeId] : null;
                const badgeCount =  ko.computed(() => {
                    switch (customSettings.routeId) {
                        case 'messages':
                            return self.newMessagesCount();
                        case 'inbox':
                            return self.newVoicemailCount() + self.newCallRecordingCount();
                        default:
                            return 0;
                    }
                });
                const prefixedBadgeCount = ko.computed(() => _numberFormatter.formatDigitsWithPrefix(badgeCount(), 1));
                const isBadgeVisible =  ko.computed(() => badgeCount() > 0);
                const isBadgeSingleDigit =  ko.computed(() => badgeCount() <= 9);
                const isDisplayNameVisible = ko.pureComputed(() => _browserType.windowWidth() >= WINDOW_BREAKPOINT);
                const leftRems = ko.pureComputed(() => {
                    const remCalc = (/**@type {number}*/num) => `${num * (1 / 16)}rem`;

                    switch (prefixedBadgeCount().length) {
                        case 1:
                            return remCalc(10);
                        case 2:
                            return remCalc(6);
                        case 3:
                            return remCalc(4.5);
                        default:
                            return remCalc(5);
                    }
                });

                return {
                    prefixedBadgeCount,
                    displayName: navigationItem.displayName,
                    iconName,
                    order,
                    iconCss: `navigation-item__icon--${customSettings.routeId}`,
                    linkCss: `navigation-item__link--${customSettings.routeId}`,
                    isActive,
                    isBadgeVisible,
                    isBadgeSingleDigit,
                    leftRems,
                    isDisplayNameVisible,
                    hash: navigationItem.hash
                };
            }).sort((a, b) => a.order - b.order);

            self.navigationList(navigationList);

        };

        const checkUserHasPermission = (permissionId) => {
            if (permissionId === null) {
                return true;
            }

            const accountPermissions = _sessionAccountInfo.accountPermissions();
            if (accountPermissions === null) {
                return false;
            }
            return accountPermissions[permissionId];
        };

        self.navigationList = ko.observableArray([]);
        self.newVoicemailCount = _voicemailSubscriptionDataSource.totalNewMessages;
        self.newCallRecordingCount = _callRecordingsSubscriptionsDataSource.totalNewMessages;
        self.newMessagesCount = _webMessagingDataSource.totalNewMessages;

        self.activate = () => {
            _router = require('businessServices/router/router');

            _initialize();
        };

        const _initialize = () => {
            _router.activeItem.subscribe(() => {
                _navigationActiveSection(_router.activeInstruction().config.customSettings.section);
            });

            _navigationActiveSection(_router.activeInstruction().config.customSettings.section);
            _updateNavigation();
        };
    };
});

