define('presentation/common/comboBox/viewModels/comboBoxViewModel',[],() => {
    return function () {
        /** @typedef {import('presentation/common/comboBox/viewModels/comboBoxViewModel')} ComboBoxViewModel */

        /** @type {ComboBoxViewModel} */
        const self = this;
        self.selfReference = null;
        self.isOpen = ko.observable(false);
        self.searchTerm = ko.observable('');
        self.isFocused = ko.observable(false);
        self.isMouseIn = ko.observable(false);
        self.selectedElement = ko.observable({});
        self.allOptions = ko.observable([]);
        self.filteredOptions = ko.observable([]);
        self.onKeyDownCallback = ko.observable();
        self.selectedOptionId = null;

        /** @type {IDisposable[]} */
        let _disposables = [];
        let _isKeyboardNavigation = false;

        const _focusOnInput = (/** @type {Boolean} */isKeyboardNavigation) => {
            // Focus on the <input /> when clicking anywhere
            // This is in a timeout to allow knockout to finish rendering
            // (You can't focus on an element that is hidden)
            setTimeout(() => {
                _isKeyboardNavigation = _isKeyboardNavigation || isKeyboardNavigation;
                const input = self.selfReference.querySelector('input');
                input.focus();
                _isKeyboardNavigation = false;
            }, 100);
        };

        const _onDocumentKeyDown = (/** @type {KeyboardEvent} */event) => {
            if (event.key === "Tab") {
                _isKeyboardNavigation = true;
            }
        };

        const _onDocumentKeyUp = (/** @type {KeyboardEvent} */event) => {
            if (event.key === "Tab") {
                _isKeyboardNavigation = false;
            }
        };

        const _onSelectedOptionIdChanged = (/** @type {string} */id) => {
            const selectedElement = self
                .allOptions()
                .find(x => x.id === id);
            self.selectedElement(selectedElement || {});
            self.searchTerm('');
        };

        const _onSearchTermChanged = (/** @type {string} */searchTerm) => {
            const allOptions = self.allOptions();
            searchTerm = searchTerm.trim();

            if (searchTerm) {
                const lowerCaseSearchTerm = searchTerm.toLowerCase();
                const digitsOnlySearchTerm = searchTerm.replace(/\D/g, '');

                const filteredOptions = allOptions.filter(({searchText}) => {
                    const lowerCaseSearchText = searchText.toLowerCase();
                    if (lowerCaseSearchText.includes(lowerCaseSearchTerm)) {
                        return true;
                    }

                    const digitsOnlySearchText = searchText.replace(/\D/g, '');
                    if (digitsOnlySearchText && digitsOnlySearchTerm) {
                        return digitsOnlySearchText.includes(digitsOnlySearchTerm);
                    }

                    return false;
                });

                self.filteredOptions(filteredOptions);
            } else {
                self.filteredOptions(allOptions);
            }
        };

        const _onAllOptionsChanged = () => {
            const searchTerm = self.searchTerm();
            _onSearchTermChanged(searchTerm);
        };

        /** @type {ComboBoxViewModel["onClick"]} */
        self.onClick = () => {
            _focusOnInput(false);
            self.isOpen(true);
        };

        /** @type {ComboBoxViewModel["onSearchArrowClick"]} */
        self.onSearchArrowClick = () => {
            _focusOnInput(false);
            self.isOpen(!self.isOpen());
        };

        /** @type {ComboBoxViewModel["onMouseEnter"]} */
        self.onMouseEnter = () => {
            self.isMouseIn(true);
        };

        /** @type {ComboBoxViewModel["onMouseLeave"]} */
        self.onMouseLeave = () => {
            self.isMouseIn(false);
        };

        /** @type {ComboBoxViewModel["onFocusIn"]} */
        self.onFocusIn = () => {
            self.isFocused(true);

            if (_isKeyboardNavigation) {
                self.isOpen(true);
                if (!self.selectedOptionId()) {
                    const input = self.selfReference.querySelector('input');
                    input.focus();
                }
                else {
                    _focusOnInput(true);
                }
            }
        };

        /** @type {ComboBoxViewModel["onListBoxClick"]} */
        self.onListBoxClick = () => {
            self.isOpen(false);
        };

        /** @type {ComboBoxViewModel["onSearchKeyDown"]} */
        self.onSearchKeyDown = (_, event) => {
            const filteredOptions = self.filteredOptions();

            // Propagate <input /> events to list box
            const keyDown = self.onKeyDownCallback();
            if (keyDown && filteredOptions.length) {
                keyDown(event);
            }

            switch (event.key) {
                case "Enter":
                    if (filteredOptions.length) {
                        // Select first option if no option selected or only one option available
                        const selectedOptionId = self.selectedOptionId();

                        if (!selectedOptionId || filteredOptions.length === 1) {
                            const {id} = filteredOptions[0];
                            self.selectedOptionId(id);
                        }

                        self.isOpen(false);
                    }
                    break;
                case "Escape":
                    self.isOpen(false);
                    break;
                default:
                    self.isOpen(true);
                    break;
            }

            return true;
        };

        /** @type {ComboBoxViewModel["onFocusOut"]} */
        self.onFocusOut = () => {
            if (self.isMouseIn()) {
                return;
            }

            if (_isKeyboardNavigation) {
                return;
            }

            self.isFocused(false);
            self.isOpen(false);
            self.searchTerm('');
        };

        /** @type {ComboBoxViewModel["detached"]} */
        self.detached = () => {
            document.removeEventListener('keydown', _onDocumentKeyDown);
            document.removeEventListener('keyup', _onDocumentKeyUp);

            for (const d of _disposables) {
                d.dispose();
            }

            _disposables = [];
            _isKeyboardNavigation = false;
        };

        /** @type {ComboBoxViewModel["activate"]} */
        self.activate = (settings) => {
            const { options, selectedOptionId } = settings;
            self.selectedOptionId = selectedOptionId;
            self.allOptions = options;
            self.selfReference = settings.child;

            _onSelectedOptionIdChanged(selectedOptionId());
            _onSearchTermChanged('');

            _disposables.push(
                self.selectedOptionId.subscribe(_onSelectedOptionIdChanged),
                self.searchTerm.subscribe(_onSearchTermChanged),
                self.allOptions.subscribe(_onAllOptionsChanged)
            );

            document.addEventListener('keydown', _onDocumentKeyDown);
            document.addEventListener('keyup', _onDocumentKeyUp);
        };
    };
});
