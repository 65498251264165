define('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceTollfreePresentationObject',[],function() {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceTollfreePresentationObject') } MessagingComplianceTollfreePresentationObject } */

        /** @typedef { MessagingComplianceTollfreePresentationObject }*/
        const self = this;

        self.smsComplianceTollfreeId = "";
        self.majorityOfMessagesSentToCanada = ko.observable("");
        self.messagesPerDay = ko.observable("");
        self.useCase = ko.observable("");
        self.summaryOfTexting = ko.observable("");
        self.sampleTextMessageOne = ko.observable("");
        self.sampleTextMessageTwo = ko.observable("");
        self.sampleTextMessageThree = ko.observable("");
        self.sampleTextMessageFour = ko.observable("");
        self.isCustomerConsentEmployeeRelationship = ko.observable(false);
        self.isCustomerConsentInitiateConversation = ko.observable(false);
        self.isCustomerConsentLeadCaptureForms = ko.observable(false);
        self.isCustomerConsentOnlineCheckout = ko.observable(false);
        self.isCustomerConsentOther = ko.observable(false);
        self.isCustomerConsentPosTransaction = ko.observable(false);
        self.customerConsentOther = ko.observable("");
        self.isIneligibleUseCaseAcknowledged = ko.observable(false);
        self.hasTollFreeNumberWithMessagingEnabled = ko.observable(false);
        self.isTollfreeSmsFormEnabled = ko.observable(false);
    };
});

