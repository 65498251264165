define('businessServices/contacts/enumToLabelConverter',[
    'constants/contactAddressLabelEnumerations',
    'constants/contactAddressCountryLabelEnumerations',
    'constants/contactEmailAddressLabelEnumerations',
    'constants/contactPhoneNumberLabelEnumerations',
    'i18next'
], function(
    /** @type typeof import('constants/contactAddressLabelEnumerations') */
    ContactAddressLabel,
    /** @type typeof import('constants/contactAddressCountryLabelEnumerations') */
    ContactAddressCountryLabel,
    /** @type typeof import('constants/contactEmailAddressLabelEnumerations') */
    ContactEmailAddressLabel,
    /** @type typeof import('constants/contactPhoneNumberLabelEnumerations') */
    ContactPhoneNumberLabel,
    /** @type typeof import('i18next') */
    i18n
) {
    return function() {
        /** @typedef {import('businessServices/contacts/enumToLabelConverter')} EnumToLabelConverter */

        /** @type {EnumToLabelConverter} */
        const self = this;

        /** @type {EnumToLabelConverter['getAddressLabel']} */
        const _getAddressLabel = (enumValue) => {
            switch (enumValue) {
                case ContactAddressLabel.Work:
                    return i18n.t('contacts:work');
                case ContactAddressLabel.Personal:
                    return i18n.t('contacts:personal');
                case ContactAddressLabel.Other:
                    return i18n.t('contacts:other');
                default:
                    return i18n.t('contacts:other');
            }
        };

        /** @type {EnumToLabelConverter['getAddressEnum']} */
        const _getAddressEnumValue = (label) => {
            switch (label) {
                case i18n.t('contacts:work'):
                    return ContactAddressLabel.Work;
                case i18n.t('contacts:personal'):
                    return ContactAddressLabel.Personal;
                case i18n.t('contacts:other'):
                    return ContactAddressLabel.Other;
                default:
                    return ContactAddressLabel.Other;
            }
        };

        /** @type {EnumToLabelConverter['getAddressCountryLabel']} */
        const _getAddressCountryLabel = (enumValue) => {
            switch (enumValue) {
                case ContactAddressCountryLabel.US:
                    return i18n.t('contacts:us');
                case ContactAddressCountryLabel.CA:
                    return i18n.t('contacts:ca');
                case ContactAddressCountryLabel.Other:
                    return i18n.t('contacts:other');
                default:
                    return i18n.t('contacts:other');
            }
        };

        /** @type {EnumToLabelConverter['getAddressCountryEnum']} */
        const _getAddressCountryEnumValue = (label) => {
            switch (label) {
                case i18n.t('contacts:us'):
                    return ContactAddressCountryLabel.US;
                case i18n.t('contacts:ca'):
                    return ContactAddressCountryLabel.CA;
                case i18n.t('contacts:other'):
                    return ContactAddressCountryLabel.Other;
                default:
                    return ContactAddressCountryLabel.Other;
            }
        };

        /** @type {EnumToLabelConverter['getEmailLabel']} */
        const _getEmailLabel = (enumValue) => {
            switch (enumValue) {
                case ContactEmailAddressLabel.Work:
                    return i18n.t('contacts:work');
                case ContactEmailAddressLabel.Personal:
                    return i18n.t('contacts:personal');
                case ContactEmailAddressLabel.Other:
                    return i18n.t('contacts:other');
                default:
                    return i18n.t('contacts:personal');
            }
        };

        /** @type {EnumToLabelConverter['getEmailEnumValue']} */
        const _getEmailEnumValue = (label) => {
            switch (label) {
                case i18n.t('contacts:work'):
                    return ContactEmailAddressLabel.Work;
                case i18n.t('contacts:personal'):
                    return ContactEmailAddressLabel.Personal;
                case i18n.t('contacts:other'):
                    return ContactEmailAddressLabel.Other;
                default:
                    return ContactEmailAddressLabel.Personal;
            }
        };

        /** @type {EnumToLabelConverter['getPhoneLabel']} */
        const _getPhoneLabel = (enumValue) => {
            switch (enumValue) {
                case ContactPhoneNumberLabel.Mobile:
                    return i18n.t('contacts:mobile');
                case ContactPhoneNumberLabel.Home:
                    return i18n.t('contacts:home');
                case ContactPhoneNumberLabel.Work:
                    return i18n.t('contacts:work');
                case ContactPhoneNumberLabel.School:
                    return i18n.t('contacts:school');
                case ContactPhoneNumberLabel.IPhone:
                    return i18n.t('contacts:iPhone');
                case ContactPhoneNumberLabel.AppleWatch:
                    return i18n.t('contacts:appleWatch');
                case ContactPhoneNumberLabel.Main:
                    return i18n.t('contacts:main');
                case ContactPhoneNumberLabel.HomeFax:
                    return i18n.t('contacts:homeFax');
                case ContactPhoneNumberLabel.WorkFax:
                    return i18n.t('contacts:workFax');
                case ContactPhoneNumberLabel.Pager:
                    return i18n.t('contacts:pager');
                case ContactPhoneNumberLabel.Other:
                    return i18n.t('contacts:other');
                default:
                    return i18n.t('contacts:mobile');
            }
        };

        /** @type {EnumToLabelConverter['getPhoneEnumValue']} */
        const _getPhoneEnumValue = (label) => {
            switch (label) {
                case i18n.t('contacts:mobile'):
                    return ContactPhoneNumberLabel.Mobile;
                case i18n.t('contacts:home'):
                    return ContactPhoneNumberLabel.Home;
                case i18n.t('contacts:work'):
                    return ContactPhoneNumberLabel.Work;
                case i18n.t('contacts:school'):
                    return ContactPhoneNumberLabel.School;
                case i18n.t('contacts:iPhone'):
                    return ContactPhoneNumberLabel.IPhone;
                case i18n.t('contacts:appleWatch'):
                    return ContactPhoneNumberLabel.AppleWatch;
                case i18n.t('contacts:main'):
                    return ContactPhoneNumberLabel.Main;
                case i18n.t('contacts:homeFax'):
                    return ContactPhoneNumberLabel.HomeFax;
                case i18n.t('contacts:workFax'):
                    return ContactPhoneNumberLabel.WorkFax;
                case i18n.t('contacts:pager'):
                    return ContactPhoneNumberLabel.Pager;
                case i18n.t('contacts:other'):
                    return ContactPhoneNumberLabel.Other;
                default:
                    return ContactPhoneNumberLabel.Mobile;
            }
        };

        self.getAddressLabel = _getAddressLabel;
        self.getAddressEnumValue = _getAddressEnumValue;
        self.getAddressCountryLabel = _getAddressCountryLabel;
        self.getAddressCountryEnumValue = _getAddressCountryEnumValue;
        self.getEmailLabel = _getEmailLabel;
        self.getEmailEnumValue = _getEmailEnumValue;
        self.getPhoneLabel = _getPhoneLabel;
        self.getPhoneEnumValue = _getPhoneEnumValue;
    };
});

