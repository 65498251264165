define('presentation/common/audioRecorder/viewModels/audioRecorderViewModel',[
        'businessServices/converters/durationTimeFormatter',
        'common/promises/promiseFactory',
        'common/time/timeSpan',
        'presentation/common/audioRecorder/audioRecorderSoundMeter',
        'recordRTC',
        'webrtc-adapter'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DurationTimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const _durationTimeFormatter = new DurationTimeFormatterConstructor();

        const TimeSpanConstructor = require('common/time/timeSpan');

        const _i18n = require('i18next');
        const _webrtcAdapter = require('webrtc-adapter');

        let _countDownTimeOut = null;
        let _featureSupported = false;
        let _recordingTimerId = null;
        let _recordRTC = null;
        let _soundMeter = null;
        let _stream = null;
        let _settings = null;

        const createSoundMeter = require('presentation/common/audioRecorder/audioRecorderSoundMeter');

        const ONE_MINUTE_IN_MILLISECONDS = 60000;
        const MAX_RECORDING_LENGTH_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * 5;

        const _detectBrowserSupport = () => {
            if (_webrtcAdapter.browserDetails.browser === "Not a supported browser.") {
                _featureSupported = false;
                self.showFeatureNotSupportedMessage(true);
                self.showNoRecordingDevicesMessage(false);
                self.showPermissionRequiredMessage(false);
            } else {
                _featureSupported = true;
                self.showFeatureNotSupportedMessage(false);
                navigator.mediaDevices.enumerateDevices()
                    .then((deviceInfos) => {
                        _populateUserDevices(deviceInfos);
                    });
            }
        };

        const _getUserMedia = () => {
            return _promiseFactory.defer((deferredObject) => {
                let mediaConstraints = {};
                mediaConstraints.video = false;

                let selectedRecordingDevice = self.selectedRecordingDevice();

                if (selectedRecordingDevice !== null) {
                    mediaConstraints.audio = {};
                    mediaConstraints.audio.deviceId = {exact: selectedRecordingDevice};
                } else {
                    mediaConstraints.audio = true;
                }

                let getUserMediaFailureTimeoutId = setTimeout(() => {
                    _onMediaCapturingFailed();
                    deferredObject.resolve();
                }, 20000);
                navigator.mediaDevices.getUserMedia(mediaConstraints)
                    .then((stream) => {
                        _onMediaCaptured(stream);
                        clearTimeout(getUserMediaFailureTimeoutId);
                        deferredObject.resolve();
                    })
                    .catch(() => {
                        _onMediaCapturingFailed();
                        clearTimeout(getUserMediaFailureTimeoutId);
                        deferredObject.resolve();
                    });
            });
        };

        const _populateUserDevices = (deviceInfos) => {
            return _promiseFactory.defer((deferredObject) => {
                let deviceList = [];
                let canSelectDevice = false;
                let hasRecordingDevices = false;

                deviceInfos.forEach((deviceInfo) => {
                    if (deviceInfo.kind === "audioinput") {
                        hasRecordingDevices = true;

                        if (deviceInfo.label.length > 0) {
                            canSelectDevice = true;
                            deviceInfo.text = deviceInfo.label;
                            deviceInfo.id = deviceInfo.deviceId;
                            deviceList.push(deviceInfo);
                        }
                    }
                });

                if (canSelectDevice) {
                    self.deviceSelectorIsDisabled(false);
                    self.recordingDevicesList(deviceList);
                    self.showPermissionRequiredMessage(true);
                    self.showNoRecordingDevicesMessage(false);
                    _getUserMedia()
                        .done(deferredObject.resolve)
                        .fail(deferredObject.reject);
                } else if (hasRecordingDevices) {
                    self.deviceSelectorIsDisabled(true);
                    self.recordingDevicesList([{label: "Browser selected mic", text: "Browser selected mic", deviceId: null, id: null, value: "record"}]);
                    self.showPermissionRequiredMessage(true);
                    self.showNoRecordingDevicesMessage(false);
                    _getUserMedia()
                        .done(deferredObject.resolve)
                        .fail(deferredObject.reject);
                } else {
                    self.deviceSelectorIsDisabled(true);
                    self.showPermissionRequiredMessage(false);
                    self.showNoRecordingDevicesMessage(true);
                    deferredObject.resolve();
                }
            });
        };

        const _onMediaCaptured = (stream) => {
            _stream = stream;
            self.showPermissionRequiredMessage(false);
            self.showSoundMeter(true);

            try {
                const AudioContext = window.AudioContext || window.webkitAudioContext;
                window.audioContext = new AudioContext();
            } catch (e) {
                // browser does not support web audio api - so we won't animate sound meter
            }

            _soundMeter = createSoundMeter();
            _soundMeter.initialize(window.audioContext);
            _soundMeter.connectToSource(stream, (e) => {
                if (e) {
                    // could not connect to source - so we won't animate sound meter
                    return;
                }
                setInterval(() => {
                    let volume = _soundMeter.getCurrentVolume();
                    self.updateSoundMeterDisplay(volume);
                }, 100);
            });
        };

        const _onSelectedRecordingDeviceChange = () => {
            if (_soundMeter) {
                _soundMeter.stop();
            }
            if (_stream) {
                _stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            _getUserMedia();
        };

        const _onMediaCapturingFailed = () => {
            self.isRecording(false);
            self.recordButtonLabel(_i18n.t('audioRecorder:record'));
        };

        const _startRecordingCountdown = () => {
            self.isRecordingCountdown(true);

            let countFrom = 3;
            const updateCountdown = () => {
                if (self.isRecording()) {
                    self.isRecordingCountdown(true);
                    self.recordButtonLabel(_i18n.t('audioRecorder:starting'));
                    self.displayDuration(countFrom.toString());
                    countFrom -= 1;

                    if (countFrom >= 0) {
                        _countDownTimeOut = setTimeout(() => {
                            if (countFrom === 0) {
                                self.isRecordingCountdown(false);
                                _recordFromMicrophone();
                            } else {
                                updateCountdown();
                            }
                        }, 1000);
                    }
                }
            };

            updateCountdown();
        };

        const _startRecordingDuration = () => {
            const timeSpan = new TimeSpanConstructor();
            self.recordingDuration(_durationTimeFormatter.format(timeSpan));
            self.displayDuration(_durationTimeFormatter.formatElapsedTime(timeSpan));
            self.recordButtonLabel(_i18n.t('audioRecorder:stop'));

            _recordingTimerId = setInterval(() => {
                timeSpan.addSeconds(1);
                self.recordingDuration(_durationTimeFormatter.format(timeSpan));
                self.displayDuration(_durationTimeFormatter.formatElapsedTime(timeSpan));
            }, 1000);
        };

        const _stopRecordingDuration = () => {
            clearTimeout(_countDownTimeOut);
            clearInterval(_recordingTimerId);
        };

        const _recordFromMicrophone = () => {
            _recordRTC = new RecordRTC(_stream, {
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1
            });
            _recordRTC.setRecordingDuration(MAX_RECORDING_LENGTH_MILLISECONDS, self.stopRecording);
            _recordRTC.startRecording();
            _startRecordingDuration();
        };

        const _cleanUpStream = () => {
            if (_recordRTC) {
                _recordRTC.stopRecording();
            }
            if (_soundMeter) {
                _soundMeter.stop();
            }
            if (_stream) {
                _stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        };

        self.recordButtonLabel = ko.observable(_i18n.t('audioRecorder:record'));
        self.recordingDuration = ko.observable("");
        self.displayDuration = ko.observable("");
        self.isRecording = ko.observable(false);
        self.isRecordingCountdown = ko.observable(false);
        self.audioURL = ko.observable("");
        self.recordingDevicesList = ko.observableArray([]);
        self.selectedRecordingDevice = ko.observable(null);
        self.selectedRecordingDevice.subscribe(_onSelectedRecordingDeviceChange);
        self.deviceSelectorIsDisabled = ko.observable(true);
        self.showFeatureNotSupportedMessage = ko.observable();
        self.showPermissionRequiredMessage = ko.observable();
        self.showNoRecordingDevicesMessage = ko.observable();
        self.showRecordedAudioPreview = ko.observable(false);
        self.showSoundMeter = ko.observable(true);
        self.deviceSelectorTooltip = ko.computed(() => {
            return self.deviceSelectorIsDisabled() ? _i18n.t('audioRecorder:deviceSelectorTooltip') : "";
        });
        self.showButtonPulse = ko.pureComputed(() => self.isRecording() && !self.isRecordingCountdown());
        self.showControl = ko.pureComputed(() => {
            return self.showFeatureNotSupportedMessage() === false &&
                self.showPermissionRequiredMessage() === false &&
                self.showNoRecordingDevicesMessage() === false;
        });

        self.toggleRecording = () => {
            if (!self.isRecording()) {
                self.isRecording(true);
                self.showRecordedAudioPreview(false);
                self.showSoundMeter(true);
                _startRecordingCountdown();
            } else {
                self.isRecording(false);
                _stopRecordingDuration();
                
                if (_recordRTC) {
                    self.recordButtonLabel(_i18n.t('audioRecorder:reRecord'));
                    self.showRecordedAudioPreview(true);
                    self.showSoundMeter(false);

                    _recordRTC.stopRecording(() => {
                        if (_settings.recordedAudioFile !== undefined) {
                            if (ko.isWriteableObservable(_settings.recordedAudioFile) === true) {
                                _settings.recordedAudioFile(_recordRTC.getBlob());
                            } else {
                                _settings.recordedAudioFile = _recordRTC.getBlob();
                            }
                        }

                        if (_settings.recordingDuration !== undefined) {
                            _settings.recordingDuration(self.recordingDuration());
                        }
                    });
                } else {
                    self.recordButtonLabel(_i18n.t('audioRecorder:record'));
                }
            }
        };

        const _totalSoundMeterCells = 30;
        const _activeSoundMeterCells = ko.observable(0);
        class SoundMeterCell {
            constructor(index) {
                this.index = index;
                this.isActive = ko.pureComputed(() => this.index < _activeSoundMeterCells());
            }
        }

        const _soundMeterCells = () => Array.from(new Array(_totalSoundMeterCells), (a,i) => new SoundMeterCell(i));
        self.soundMeterCells = _soundMeterCells();

        self.updateSoundMeterDisplay = (vol) => {
            _activeSoundMeterCells(Math.round(vol/(_totalSoundMeterCells * 0.6)));
        };

        self.detached = () => {
            _cleanUpStream();
        };

        self.activate = (settings) => {
            _settings = settings;

            return _initialize();
        };

        const _initialize = () => {
            _detectBrowserSupport();

            return _promiseFactory.wait();
        };
    };
});

