define('presentation/signup/viewModels/signupSuccessViewModel',[
    'common/promises/promiseFactory',
    'constants/signupNumberTypeSelectionConstants',
    'businessServices/router/router',
    'businessServices/signup/affiliateSignup',
    'settings/navigationConfiguration',
    'common/converters/phoneNumberFormatter',
    'presentation/signup/facades/signupSuccessFacade',
],
function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const AffiliateSignupConstructor = require('businessServices/signup/affiliateSignup');
        const _affiliateSignup = new AffiliateSignupConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');
        const _signupNumberType = require('constants/signupNumberTypeSelectionConstants');

        let _facade = null;

        const _setActivePhoneNumbersToPortingNumbers = () => {
            const incomingNumbers = self.signupDocument.numberPort.phoneNumbersToPort;
            let pushIncomingNumbersToArray = [];
            incomingNumbers.forEach((item) => {
                pushIncomingNumbersToArray.push(item.phoneNumber);
            });
            self.activePhoneNumbers = pushIncomingNumbersToArray;
        };

        const _setActivePhoneNumbersToReservedNumber = () => {
            const incomingNumbers = [];
            incomingNumbers.push(self.signupDocument.reservedPhoneNumber);
            self.activePhoneNumbers = incomingNumbers;
        };

        self.onLoginClick = () => {
            const SignupAuthenticationStoreConstructor = require('businessServices/authentication/stores/signupAuthenticationStore');
            const _signupAuthenticationStore = new SignupAuthenticationStoreConstructor();
            _signupAuthenticationStore.init();
            _signupAuthenticationStore.load()
                .done(signupSessionId => {
                    if (signupSessionId === null) {
                        _router.navigate(_navigationConfiguration.loginPageUrl);
                    } else {
                        const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
                        const _signupSessionStore = new SignupSessionStoreConstructor();
                        _signupSessionStore.init();
                        _signupSessionStore.getState(true)
                            .fail(() => _router.navigate(_navigationConfiguration.loginPageUrl))
                            .done(sessionState => {
                                const _signupSessionStatusConstants = require('constants/signupSessionStatusConstants');

                                if (sessionState.signupDocument.signupSessionStatus !== _signupSessionStatusConstants.accountCreationCompleted) {
                                    _router.navigate(_navigationConfiguration.loginPageUrl);
                                } else if (sessionState.password === null) {
                                    _router.navigate(_navigationConfiguration.loginPageUrl);
                                } else {
                                    const _authenticationManager = require('businessServices/authentication/authenticationManager');
                                    _authenticationManager.loginWithCredentials(sessionState.signupDocument.emailAddress, sessionState.password)
                                        .fail(() => _router.navigate(_navigationConfiguration.loginPageUrl))
                                        .done(loginResult => {
                                            _signupSessionStore.clear();
                                            _signupAuthenticationStore.clear();
                                            _affiliateSignup.clear();
                                            if (loginResult.success === true) {
                                                _router.navigate(_navigationConfiguration.signupLandingPage);
                                            } else {
                                                _router.navigate(_navigationConfiguration.loginPageUrl);
                                            }
                                        });
                                }
                            });
                    }
                });
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/signup/facades/signupSuccessFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.defer(promise => {
                _facade.loadSignupSession()
                    .done((signupDocument) => {
                        self.signupDocument = signupDocument;
                        if (signupDocument.numberTypeSelection === _signupNumberType.port) {
                            self.isPorting = true;
                            _setActivePhoneNumbersToPortingNumbers();
                        } else {
                            self.isPorting = false;
                            _setActivePhoneNumbersToReservedNumber();
                        }

                        self.userMobilePhone = _phoneNumberFormatter.toUSAreaCode(signupDocument.mobilePhoneNumber);

                        self.phoneNumbers = () => {
                            if (self.activePhoneNumbers) {
                                const activePhoneNumbersLengthLessOne = self.activePhoneNumbers.length - 1;
                                return self.activePhoneNumbers.map((phoneNumber, i) => {
                                    return {
                                        phoneNumber: _phoneNumberFormatter.toUSAreaCode(phoneNumber),
                                        showDivider: i < activePhoneNumbersLengthLessOne
                                    };
                                });
                            } else {
                                return "";
                            }
                        };

                        self.isThereMoreThanOneActiveNumber = () => self.activePhoneNumbers.length > 1;

                        promise.resolve();
                    });
            });
            return _promiseFactory.wait();
        };
    };
});

