define('presentation/common/validation/validationMessage',[], function(){
    return function(validationKey, validationMessage, validationHeading) {
        var correctedValidationHeading = validationHeading || '';
        return {
            key : validationKey,
            message : validationMessage,
            heading : correctedValidationHeading
        };
    };
});
