define('presentation/loginNotifications/facades/deactivatedUserModalFacade',['businessServices/authentication/authenticationManager',
        'persistence/dataProviders/customerServiceDataProvider'
], function() {
    let _promiseFactory = null;
    let _customerServiceInfoDataProvider = null;
    let _authenticationManager = null;

    const _getCustomerServiceNumber = () => {
        return _promiseFactory.defer((deferredObject) => {
            _customerServiceInfoDataProvider.get()
                .fail(deferredObject.resolve)
                .done((customerServiceInfo) => {
                    deferredObject.resolve(customerServiceInfo.support_phone);
                });
        });
    };

    const _logout = () => {
        return _promiseFactory.defer((promise) => {
            _authenticationManager.logout()
                .done(promise.resolve)
                .fail(promise.reject);
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const CustomerServiceDataProviderConstructor = require('persistence/dataProviders/customerServiceDataProvider');
        _customerServiceInfoDataProvider = new CustomerServiceDataProviderConstructor();
        _authenticationManager = require('businessServices/authentication/authenticationManager');
    };

    return function() {
        const self = this;

        self.init = _init;
        self.getCustomerServiceNumber = _getCustomerServiceNumber;
        self.logout = _logout;
    };
});

