define('presentation/loginNotifications/validators/reactivationValidator',[
    'constants/creditCardResponseConstants',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
], function () {
    return function () {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _viewModel = null;

        const _validateCustomerAgreesToPay = (customerAgreesToPay) => {
            if (_viewModel.hasPastDueBalance()) {
                return customerAgreesToPay;
            }
            return true;
        };

        const _validateUnderstandTermsOfUse = (customerAgreesToTermsOfUse) => {
            if (_viewModel.isAccountAbleToBeActivated()) {
                return customerAgreesToTermsOfUse;
            }
            return true;
        };

        self.registerViewModel = (viewModel) => {
            const validationRules = new ValidationRulesConstructor();
            validationRules.field("customerAgreesToPay")
                .addValidationI18n(_validateCustomerAgreesToPay, 'reactivation:acknowledgementRequired');
            validationRules.field("customerAgreesToTermsOfUse")
                .addValidationI18n(_validateUnderstandTermsOfUse, 'reactivation:agreementRequired');

            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
            _viewModel = viewModel;
        };

        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

