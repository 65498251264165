define('presentation/settings/phoneNumbers/facades/editPhoneNumberFacade',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/converters/durationTimeFormatter',
    'businessServices/uploads/customerUpload',
    'common/collections/collectionSorter',
    'common/storage/commonState',
    'constants/referentialConstraintEnumerations',
    'constants/userGroupConstants',
    'externalDependencies/clientWebSocket',
    'persistence/webSocket/webSocketApp',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'presentation/settings/phoneNumbers/presentationObjects/recordingNotificationAudioObject',
    'presentation/settings/phoneNumbers/presentationObjects/recordingNotificationPresentationObject',
    'presentation/common/callFlow/common/callFlowBuilder',
    'i18next'
], function() {

    const RecordingNotificationPresentationObject = require('presentation/settings/phoneNumbers/presentationObjects/recordingNotificationPresentationObject');
    const RecordingNotificationAudioObjectConstructor = require('presentation/settings/phoneNumbers/presentationObjects/recordingNotificationAudioObject');
    const CollectionSorterConstructor = require('common/collections/collectionSorter');
    const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
    const SorterConstructor = require('common/collections/collectionSorter');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const CallFlowBuilderConstructor = require('presentation/common/callFlow/common/callFlowBuilder');

    const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");
    const accountHostedNumberSocket = require('externalDependencies/clientWebSocket').forApplication("accountHostedNumber");
    const _commonState = require('common/storage/commonState');
    const _userGroupConstants = require('constants/userGroupConstants');
    const _i18n = require('i18next');

    let customerUpload = require('businessServices/uploads/customerUpload');

    let _sorter = new SorterConstructor();
    let _voicePromptAudioFactory = null;
    let _promiseFactory = null;
    let _webSocketApp = null;
    let _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

    const DISABLED_SEARCH_DATA = "Disabled";

    const _savePhoneNumberData = (accountHostedNumberId, phoneNumberName, callRecordingNotificationId, permissionObject, shouldUpdatePermissions, routingRule, routingRuleResponse, callRecordingSubscribers, authorizedDialers, authorizedMessagers) => {
        return _promiseFactory.defer((deferredObject) => {
            const {
                isInboundCallingEnabled,
                isInboundCallRecordingEnabled,
                isOutboundCallingEnabled,
                isOutboundCallingPermissionInherited,
                isOutboundCallRecordingEnabled,
                isCallRecordingPermissionInherited,
                isSmsEnabled,
                isSmsPermissionInherited,
                isHumanDetectionEnabled
            } = permissionObject;

            const subscribers = callRecordingSubscribers.subscribers.map((subscriber) => {
                return {
                    callRecordingSubscriberId: subscriber.callRecordingSubscriberId,
                    userId: subscriber.userId,
                    userGroupId: _commonState.resolveGroupIdToGuid(subscriber.userGroupId),
                    type: subscriber.subscriberType
                };
            });
            const dialers = mapAuthorizedUsersForSave(authorizedDialers.subscribers);
            const messagers = mapAuthorizedUsersForSave(authorizedMessagers.subscribers);
            
            const websocketData = {
                accountHostedNumberId: accountHostedNumberId,
                hostedPhoneNumberName: phoneNumberName,
                shouldUpdatePermissions: shouldUpdatePermissions,
                
                //inbound settings
                isInboundCallingEnabled: isInboundCallingEnabled,
                routingRule: routingRule,
                routingRuleAction: _convertRoutingRuleResponseToAction(routingRuleResponse),
                
                //outbound settings
                isOutboundCallingEnabled: isOutboundCallingEnabled,
                isOutboundCallingPermissionInherited: isOutboundCallingPermissionInherited,
                authorizedDialers: dialers,
    
                //callRecording settings
                isInboundCallRecordingEnabled: isInboundCallRecordingEnabled,
                isOutboundCallRecordingEnabled: isOutboundCallRecordingEnabled,
                isCallRecordingPermissionInherited: isCallRecordingPermissionInherited,
                callRecordingSubscribers: subscribers,
                callRecordingNotificationId: callRecordingNotificationId,
    
                //sms settings
                isSmsEnabled: isSmsEnabled,
                isSmsPermissionInherited: isSmsPermissionInherited,
                authorizedMessagers: messagers,

                // human detection settings
                isHumanDetectionEnabled: isHumanDetectionEnabled
            };
            
            accountHostedNumberSocket.send("updateHostedNumber", websocketData, (result, err) => {
                deferredObject.resolve();
            });
        });
    };

    const _getPhoneNumberData = (accountHostedNumberId) => {
        return _promiseFactory.defer((deferredObject) => {
            let response = { accountHostedNumberId: accountHostedNumberId };
            let getHostedNumberDataPromiseFactory = new PromiseFactoryConstructor();
            getHostedNumberDataPromiseFactory.defer((erlangPromise) => {
                _getAllRecordingNotifications()
                    .fail(erlangPromise.reject)
                    .done((allRecordingNotifications) => {
                        response.recordingNotifications = allRecordingNotifications;
                        erlangPromise.resolve();
                    });
            });

            getHostedNumberDataPromiseFactory.defer((clientApiPromise) => {
                accountHostedNumberSocket.send("retrieve", {accountHostedNumberId: accountHostedNumberId}, (accountHostedNumber, err) => {
                    response.hostedPhoneNumber = accountHostedNumber.hostedPhoneNumber;
                    response.hostedPhoneNumberName = accountHostedNumber.hostedPhoneNumberName;
                    response.hostedPhoneNumberDefaultName = accountHostedNumber.hostedPhoneNumberDefaultName;
                    response.cityOfPurchase = accountHostedNumber.hostedPhoneNumberCityOfPurchase;
                    response.regionOfPurchase = accountHostedNumber.hostedPhoneNumberRegionOfPurchase;
                    response.isOutboundCallingEnabled = accountHostedNumber.isOutboundCallingEnabled;
                    response.isOutboundCallingPermissionInherited = accountHostedNumber.isOutboundCallingPermissionInherited;
                    response.isInboundCallRecordingEnabled = accountHostedNumber.isInboundCallRecordingEnabled;
                    response.isOutboundCallRecordingEnabled = accountHostedNumber.isOutboundCallRecordingEnabled;
                    response.isHumanDetectionEnabled = accountHostedNumber.isHumanDetectionEnabled;
                    response.isInboundCallRecordingWarningVerified = accountHostedNumber.isInboundCallRecordingEnabled;
                    response.isOutboundCallRecordingWarningVerified = accountHostedNumber.isOutboundCallRecordingEnabled;
                    response.isCallRecordingPermissionInherited = accountHostedNumber.isCallRecordingPermissionInherited;
                    response.isSmsEnabled = accountHostedNumber.isSmsEnabled;
                    response.isSmsPermissionInherited = accountHostedNumber.isSmsPermissionInherited;
                    response.selectedAuthorizedDialers = _processAuthorizedDialers(accountHostedNumber.outboundCallingPermission);
                    response.selectedCallRecordingSubscribers = _processSelectedCallRecordingSubscribers(accountHostedNumber.callRecordingSubscribers);
                    response.selectedAuthorizedMessagers = _processAuthorizedMessagers(accountHostedNumber.smsAuthorizedMessagers);
                    if (accountHostedNumber.customRecordingNotification !== null) {
                        response.recordingNotificationId = accountHostedNumber.customRecordingNotification.accountVoicePromptId;
                    } else if (accountHostedNumber.systemRecordingNotification !== null) {
                        response.recordingNotificationId = accountHostedNumber.systemRecordingNotification.systemAudioClipId;
                    } else {
                        response.recordingNotificationId = "";
                    }
                    response.callRecordingSubscribersLog = accountHostedNumber.callRecordingSubscribersLog;
                    response.outboundCallingPermissionLog = accountHostedNumber.outboundCallingPermissionLog;
                    response.smsAuthorizedMessagersLog = accountHostedNumber.smsAuthorizedMessagersLog;
                    response.hostedNumberRoutingRule = _processRoutingRule(accountHostedNumber.hostedNumberRoutingRule);
                    clientApiPromise.resolve();
                });
            });

            getHostedNumberDataPromiseFactory.wait()
                .fail(deferredObject.reject)
                .done(() => {
                    deferredObject.resolve(response);
                });
        });
    };

    const _getAllRecordingNotifications = () => {
        return _promiseFactory.defer((promise) => {
            promptSocket.send("retrieveAll", {}, (accountRecordingNotifications, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                const displayNotifications = accountRecordingNotifications.map((currentNotification) => {
                    let displayNotification = new RecordingNotificationPresentationObject();
                    displayNotification.recordingNotificationId = currentNotification.accountVoicePromptId;
                    displayNotification.notificationName(currentNotification.name);
                    displayNotification.isCurrent(false);
                    displayNotification.isBuiltIn(false);
                    displayNotification.isNew(false);
                    displayNotification.notificationAudioObject(_getCurrentVoicePrompt(currentNotification.accountVoicePromptId));

                    if (currentNotification.conversionStatus === 1) {
                        displayNotification.conversionComplete = ko.observable(true);
                        displayNotification.conversionInProgress = ko.observable(false);
                    } else {
                        displayNotification.conversionComplete = ko.observable(false);
                        displayNotification.conversionInProgress = ko.observable(true);
                    }

                    if (currentNotification.durationInMilliseconds && displayNotification.conversionComplete) {
                        displayNotification.notificationAudioObject().duration = ko.observable(_getFormattedDurationProperty(currentNotification.durationInMilliseconds / 1000));
                    } else {
                        displayNotification.voicePromptDuration = ko.observable("Unknown");
                    }

                    return displayNotification;
                });

                promptSocket.send("retrieveSystemPrompts", {}, (systemRecordingNotifications) => {
                    const displaySystemNotifications = systemRecordingNotifications.map((systemNotification) => {
                        let displaySystemNotification = new RecordingNotificationPresentationObject();
                        displaySystemNotification.recordingNotificationId = systemNotification.systemAudioClipId;
                        displaySystemNotification.notificationName(systemNotification.name);
                        displaySystemNotification.isCurrent(true);
                        displaySystemNotification.conversionComplete = ko.observable(true);
                        displaySystemNotification.conversionInProgress = ko.observable(false);
                        displaySystemNotification.notificationAudioObject(_getCurrentSystemPrompt(systemNotification.systemAudioClipId));
                        displaySystemNotification.notificationAudioObject().duration = ko.observable(_getFormattedDurationProperty(systemNotification.durationInMilliseconds / 1000));

                        return displaySystemNotification;
                    });

                    const allNotifications = displayNotifications.concat(displaySystemNotifications);

                    let noneRecordingNotificationPresentationObject = new RecordingNotificationPresentationObject();
                    noneRecordingNotificationPresentationObject.recordingNotificationId = "";
                    noneRecordingNotificationPresentationObject.notificationName(_i18n.t('editPhoneNumber:none'));
                    noneRecordingNotificationPresentationObject.isCurrent(false);
                    noneRecordingNotificationPresentationObject.isBuiltIn(true);
                    noneRecordingNotificationPresentationObject.isNew(false);
                    let noneRecordingNotificationAudioObject = new RecordingNotificationAudioObjectConstructor();
                    noneRecordingNotificationPresentationObject.notificationAudioObject(noneRecordingNotificationAudioObject);

                    let sorter = new CollectionSorterConstructor();
                    sorter.multiSort(allNotifications, ["isBuiltIn", "notificationName"], [true, true]);
                    allNotifications.unshift(noneRecordingNotificationPresentationObject);

                    promise.resolve(allNotifications);

                });
            });
        });
    };
    
    const _convertRoutingRuleResponseToAction = (routingRuleResponse) => {
        switch (routingRuleResponse) {
            case "callflowUnassigned":
                return 1;
            case "callflowCreated":
                return 2;
            case "callflowUpdated":
                return 3;
            case "callflowUnchanged":
                return 4;
            default:
                throw new Error(`Unknown routingRuleAction ${routingRuleResponse}`);
        }
    };

    const _getFormattedDurationProperty = (promptDurationInSeconds) => {
        let formatter = new TimeFormatterConstructor();
        let formattedTimeDuration = formatter.formatTotalSeconds(promptDurationInSeconds);
        return formattedTimeDuration;
    };

    const _getCurrentVoicePrompt = (voicePromptId) => {
        return _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
    };

    const _getCurrentSystemPrompt = (systemPromptId) => {
        return _voicePromptAudioFactory.getSystemVoicePrompt(systemPromptId);
    };

    const _isNameUnique = (phoneConnectorId, phoneNumberName) => {
        return _promiseFactory.defer((deferredObject) => {
            let websocketData = {
                phoneConnectorId: phoneConnectorId,
                phoneNumberName: phoneNumberName
            };
            _webSocketApp.send("isPhoneNumberNameUnique", websocketData, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _processAuthorizedDialers = (subscribers) => {
        if (subscribers.length > 0) {
            return ko.pureComputed(() => {
                return mapSubscribers(subscribers);
            });
        }
        return ko.pureComputed(() => {
            return [mapSubscriber({userGroupId: _userGroupConstants.allUserGroupId})];
        });
    };

    const _processSelectedCallRecordingSubscribers = (subscribers) => {
        if (subscribers.length > 0) {
            return ko.pureComputed(() => {
                return mapSubscribers(subscribers);
            });
        }
        return ko.pureComputed(() => {
            return [mapSubscriber({userId: _sessionAccountInfo.userId()})];
        });
    };

    const _processAuthorizedMessagers = (subscribers) => {
        if (subscribers.length > 0) {
            return ko.pureComputed(() => {
                return mapSubscribers(subscribers);
            });
        }
        return ko.pureComputed(() => {
            return [mapSubscriber({userGroupId: _userGroupConstants.allUserGroupId})];
        });
    };

    const _processRoutingRule = (routingRule) => {
        let callFlowBuilder = new CallFlowBuilderConstructor();
        callFlowBuilder.init(_promiseFactory);

        let displayRoutingRule = {
            routeToType: ko.observable(null),
            routeSearchData: ko.observable(DISABLED_SEARCH_DATA),
            routingRule: ko.observable(null),
            routingRules: []
        };

        if (routingRule === null) {
            displayRoutingRule.routeToType(null);
            displayRoutingRule.routeSearchData(DISABLED_SEARCH_DATA);

        } else {
            displayRoutingRule.routeToType("routingRule");
            displayRoutingRule.routeToRoutingRuleId = routingRule.routeToRoutingRuleId;
            displayRoutingRule.routingRule(routingRule);

            displayRoutingRule.routingRules = callFlowBuilder.buildRoutingRules(routingRule, [routingRule]);
        }

        return displayRoutingRule;
    };

    const mapSubscribers = (subscribers) => {
        let selectedSubscribers = subscribers.map(subscriber => {
            return mapSubscriber(subscriber);
        });
        _sorter.sort(selectedSubscribers, "displayName", true);
        return selectedSubscribers;
    };

    const mapSubscriber = (subscriber) => {
        const commonStateItem = subscriber.userId ? _commonState.get(subscriber.userId) : _commonState.get(subscriber.userGroupId);
        const avatar = commonStateItem.type === "user" ? {avatar: commonStateItem.avatar()} : {};
        const callRecordingSubscriber = subscriber.callRecordingSubscriberId ? {callRecordingSubscriberId: subscriber.callRecordingSubscriberId} : {};
        return Object.assign({}, {
                id: commonStateItem.id,
                subscriberType: commonStateItem.type,
                displayName: commonStateItem.name()
            },
            avatar,
            callRecordingSubscriber);
    };

    const mapAuthorizedUsersForSave = (authorizedUsers) => {
        let selectedAuthorizedUsers = authorizedUsers.map((authorizedUser) => {
            return mapAuthorizedUserForSave(authorizedUser);
        });
        return selectedAuthorizedUsers;
    };

    const mapAuthorizedUserForSave = (authorizedUser) => {
        const commonStateItem = _commonState.get(authorizedUser.id);
        const userId = commonStateItem.type === "user" ? commonStateItem.id : null;
        const userGroupId = commonStateItem.type === "userGroup" ? commonStateItem.id : null;
        return {
            userId: userId,
            userGroupId: _commonState.resolveGroupIdToGuid(userGroupId)
        };
    };
    
    const _saveNewRecordingNotification = function (voicePromptName, voicePromptFileHandler) {
        return _promiseFactory.deferIndefinitely((promise) => {
            customerUpload.uploadFile(voicePromptFileHandler)
                .fail(promise.reject)
                .done(({uploadId}) => {
                    const parameters = {
                        name: voicePromptName,
                        uploadId: uploadId,
                    };

                    promptSocket.send("addVoicePrompt", parameters, (response, err) => {
                        if (err) {
                            promise.reject(new Error(err));
                            return;
                        }

                        if (response.isSuccessful === false) {
                            promise.reject(new Error(response.failureReason));
                            return;
                        }

                        promise.resolve(response.accountVoicePromptId);
                    });
                });
        });
    };

    const _getParticipantsForRouteObject = (objectId, objectType) => {
        return _promiseFactory.defer((deferredObject) => {
            const websocketData = {
                accountAttendantScheduleId: objectType === _commonState.types.schedule ? objectId : null,
                accountAutoAttendantId: objectType === _commonState.types.autoAttendant ? objectId : null
            };

            accountHostedNumberSocket.send("retrieveObjectParticipants", websocketData, (result, err) => {
                if (err) {
                    deferredObject.reject(new Error(err));
                    return;
                }

                deferredObject.resolve(result.participants);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketActivityAppConstructor();
        _webSocketApp.init("phone_numbers_edit");

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();
    };

    const _dispose = () => {
        promptSocket.disposeOfEvents();
        accountHostedNumberSocket.disposeOfEvents();
    };

    return function() {
        let self = this;
        self.init = _init;
        self.getPhoneNumber = _getPhoneNumberData;
        self.getParticipantsForRouteObject = _getParticipantsForRouteObject;
        self.savePhoneNumber = _savePhoneNumberData;
        self.saveNewRecordingNotification = _saveNewRecordingNotification;
        self.isNameUnique = _isNameUnique;
        self.dispose = _dispose;
    };
});

