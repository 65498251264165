/* jshint ignore:start */
define('presentation/common/setMaxHeight',[],() => {
    return {
        /** @type {KnockoutBindingHandler<HTMLElement, ISetMaxHeightOptions>["init"]} */
        init: (element, valueAccessor) => {
            const { setToZero } = valueAccessor();

            const resizeObserver = new ResizeObserver(() => {
                if (ko.unwrap(setToZero)) {
                    element.style.maxHeight = `0px`;
                }
                else {
                    const maxHeightWithBuffer = element.scrollHeight + 1;
                    element.style.maxHeight = `${maxHeightWithBuffer}px`;
                }
            });

            for (let i = 0; i < element.children.length; i++) {
                resizeObserver.observe(element.children[i]);
            }

            resizeObserver.observe(element);

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                resizeObserver.disconnect();
            });
        },
        /** @type {KnockoutBindingHandler<HTMLElement, ISetMaxHeightOptions>["update"]} */
        update: (element, valueAccessor) => {
            const { setToZero } = valueAccessor();

            if (ko.unwrap(setToZero)) {
                element.style.maxHeight = `0px`;
            }
            else {
                const maxHeightWithBuffer = element.scrollHeight + 1;
                element.style.maxHeight = `${maxHeightWithBuffer}px`;
            }
        }
    };
});

