define('presentation/shell/viewModels/appEntryShellViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/events/eventManager',
    'businessServices/router/router',
    'businessServices/signup/signupSessionStore',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/shellNameEnumerations',
    'constants/signupSessionFlowConstants',
    'presentation/signup/common/signupNavigationManager',
    'settings/navigationConfiguration'
], function() {

    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        let _eventManager = null;
        let _urlFormatter = null;

        const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
        let _signupSessionStore = null;
        let _signupNavigationManager = null;

        const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
        const _browserType = require('businessServices/browserSupport/browserType');

        const _shellNameEnumerations = require('constants/shellNameEnumerations');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        const _onShellUpdated = (shellName) => {
            if (shellName === _shellNameEnumerations.appEntry) {
                self.childViewModel(self.router.activeItem());
                self.shellIsActive(true);
            } else {
                self.childViewModel(null);
                self.shellIsActive(false);
            }
        };

        const _displayModal = () => {
            self.isModalActive(true);
            setTimeout(() => {
                self.isModalVisible(true);
            }, 100);
        };

        const _isAPhoneNumberReserved = () => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.get()
                    .fail(promise.reject)
                    .done((signupSession) => {
                        self.reservedPhoneNumber = signupSession.reservedPhoneNumber;
                        const isPhoneNumberReserved = self.reservedPhoneNumber != null;
                        promise.resolve(isPhoneNumberReserved);
                    });
            });
        };

        const _isForgotPasswordScreen = () => self.router.activeInstruction().fragment === _navigationConfiguration.routesById.forgotPassword.url;
        const _isResetPasswordScreen = () => self.router.activeInstruction().config.customSettings.routeId === _navigationConfiguration.routesById.resetPassword.routeId;
        const _isLoginScreen = () => self.router.activeInstruction().fragment === _navigationConfiguration.loginPageUrl;
        const _isFlowAEmailScreen = () => self.router.activeInstruction().config.hash === _navigationConfiguration.routesById.signupEmail.url && self.flowId() === _signupSessionFlowConstants.flowA;

        self.router = null;
        self.childViewModel = ko.observable();
        self.flowId = ko.observable();
        self.shellIsActive = ko.observable(true);
        self.isModalVisible = ko.observable(false);
        self.isModalActive = ko.observable(false);

        self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);

        self.hideModal = () => {
            self.isModalVisible(false);
            setTimeout(() => {
                self.isModalActive(false);
            }, 250);
        };

        self.handleLogoClick = () => {
            _isAPhoneNumberReserved()
                .done((isReserved) => {
                    if (isReserved && !self.isAppEntryLandingScreen()) {
                        _displayModal();
                    } else {
                        self.navigateUserToHomePage();
                    }
                });
        };

        self.navigateUserToHomePage = () => {
            self.isModalVisible(false);
            self.isModalActive(false);
            self.router.navigate(self.homeUrl);
        };

        self.activate = () => {
            self.router = require('businessServices/router/router');
            _eventManager = require('businessServices/events/eventManager');
            _urlFormatter = require('common/url/urlFormatter');

            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            self.flowId(_signupNavigationManager.getFlowId());

            return _initialize();
        };

        const _initialize = () => {
            self.childViewModel(self.router.activeItem());
            self.childViewModel().parent = self;
            self.homeUrl = _urlFormatter.buildFrontendUrl("/");

            self.isAppEntryLandingScreen = ko.computed(() => {
                return _isForgotPasswordScreen() || _isResetPasswordScreen() || _isLoginScreen() || _isFlowAEmailScreen();
            });

            _eventManager.subscribeShellUpdated(_onShellUpdated);

            return _promiseFactory.wait();
        };
    };
});
