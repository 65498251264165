define('presentation/contacts/viewModels/contactPhoneViewModel',[
    'common/collections/collectionSorter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'constants/contactPhoneNumberLabelEnumerations',
    'i18next',
    'presentation/contacts/facades/contactCardFacade',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/confirmActionViewModel'
], function(
    /** @type typeof import('common/collections/collectionSorter') */
    SorterConstructor,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/contactPhoneNumberLabelEnumerations') */
    PhoneNumberLabelEnum,
    /** @type import('i18next') */
    i18n,
    /** @type typeof import('presentation/contacts/facades/contactCardFacade') */
    ContactCardFacade,
    /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
    ActionModalViewModel,
    /** @type typeof import('presentation/common/actionModal/viewModels/confirmActionViewModel') */
    ConfirmAction
) {
    return function() {
        const self = this;
        const _actionModal = new ActionModalViewModel();

        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _promiseFactory = new PromiseFactory();
        const _sorter = new SorterConstructor();

        /** @type import('presentation/contacts/facades/contactCardFacade') */
        let _facade = null;

        const _navigationConfiguration = require('settings/navigationConfiguration');

        self.comingSoon = i18n.t("contacts:comingSoon");
        self.phoneNumbers = ko.observableArray([]);
        self.router = null;

        self.openPopup = (/** @type { any } */ phoneNumber) => {
            const showPopUpMenu = phoneNumber.showPopUpMenu;
            showPopUpMenu(!showPopUpMenu());
        };

        self.onClick = (/** @type { any } */ phoneNumber) => {
            return _promiseFactory.defer(deferredObject => {
                if (!phoneNumber.isValid()) {
                    _actionModal
                        .clearModal()
                        .setContentViewModel(ConfirmAction, [i18n.t('contacts:unsupportedModalText')])
                        .setHeaderText({i18n: 'contacts:unsupportedModalTitle'})
                        .setShouldDisplayCancelButton(false)
                        .setShouldDisplaySubmitButton(true)
                        .setSubmitButtonText({i18n: 'ok'})
                        .setShouldScrollIntoView(false)
                        .showModal()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                } else {
                    _facade.createDraftConversation(phoneNumber.phoneNumber())
                        .fail(deferredObject.reject)
                        .done((/** @type string */ conversationDraftId) => {
                            const newMessageUrl = `${_navigationConfiguration.routesById.newMessage.baseUrl}/${conversationDraftId}`;
                            self.router.navigate(newMessageUrl);
                            deferredObject.resolve();
                        });
                }
            });
        };

        self.activate = (/** @type { IContactPhoneViewModelActivationObject } */ activationObject) => {
            self.phoneNumbers = ko.observableArray(activationObject.phoneNumbers().map((n) => {
                n.isValid = ko.observable(_phoneNumberFormatter.isShortCode(_phoneNumberFormatter.toNumbers(n.rawPhoneNumber())) ||
                    _phoneNumberFormatter.removeNonNumericalAndLeadingOne(n.rawPhoneNumber()).length === 10);
                return n;
            }));

            self.router = activationObject.router;


            _facade = new ContactCardFacade();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {

            sortPhoneNumbers();

            return _promiseFactory.wait();
        };

        const sortPhoneNumbers = () => {
            _sorter.multiSort(self.phoneNumbers, ["label", "phoneNumber"]);

            const extension = self.phoneNumbers.remove((/** @type { IContactPhoneNumberPresentationObject } */ n) => {
                return ko.unwrap(n.label) === i18n.t('contacts:extension');
            });
            if (extension.length > 0) {
                self.phoneNumbers.unshift(extension[0]);
            }
        };
    };
});
