define('presentation/settings/prompts/facades/promptsFacade',[
        'businessServices/converters/durationTimeFormatter',
        'constants/audioFileConversionStatusConstants',
        'constants/referentialConstraintEnumerations',
        'externalDependencies/clientWebSocket',
        'presentation/common/dateTimeValue',
        'presentation/common/voicePrompt/voicePromptAudioFactory',
        'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
        'presentation/settings/prompts/presentationObjects/promptsPresentationObject',
        'settings/navigationConfiguration'
    ],
    function() {
        const PromptsPresentationObjectConstructor = require('presentation/settings/prompts/presentationObjects/promptsPresentationObject');
        const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');
        const integrityWarningMessagesEnumeration = require('constants/referentialConstraintEnumerations');

        const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

        const _audioFileConversionStatusConstants = require('constants/audioFileConversionStatusConstants');
        let _navigationConfiguration = require('settings/navigationConfiguration');

        let _promiseFactory = null;
        let _voicePromptAudioFactory = null;

        const _findAllVoicePrompts = () => {
            return _promiseFactory.defer((promise) => {

                promptSocket.send("retrieveAll", {}, (accountVoicePrompts, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }
                    const displayVoicePrompts = accountVoicePrompts.map((currentPrompt) => {
                        let displayPrompt = new PromptsPresentationObjectConstructor();
                        displayPrompt.voicePromptId = currentPrompt.accountVoicePromptId;
                        displayPrompt.createdDateTime = new DateTimeValueConstructor(currentPrompt.createdDateTime);
                        displayPrompt.modifiedDateTime = new DateTimeValueConstructor(currentPrompt.updatedDateTime);
                        displayPrompt.promptName = currentPrompt.name;
                        displayPrompt.currentVoicePrompt(_getCurrentVoicePrompt(currentPrompt.accountVoicePromptId));

                        if (currentPrompt.conversionStatus === _audioFileConversionStatusConstants.converted) {
                            displayPrompt.conversionComplete = ko.observable(true);
                            displayPrompt.conversionInProgress = ko.observable(false);
                        } else {
                            displayPrompt.conversionComplete = ko.observable(false);
                            displayPrompt.conversionInProgress = ko.observable(true);
                        }

                        if (currentPrompt.durationInMilliseconds && displayPrompt.conversionComplete) {
                            displayPrompt.currentVoicePrompt().duration = ko.observable(_getFormattedDurationProperty(currentPrompt.durationInMilliseconds));
                        } else {
                            displayPrompt.currentVoicePrompt().duration = ko.observable("Unknown");
                        }
                        displayPrompt.editPromptUrl = _navigationConfiguration.routesById.editPrompt.baseUrl + currentPrompt.accountVoicePromptId;

                        return displayPrompt;
                    });

                    promise.resolve(displayVoicePrompts);
                });
            });
        };

        const _getFormattedDurationProperty = (durationInMilliseconds) => {
            let formatter = new TimeFormatterConstructor();
            let formattedTimeDuration = formatter.formatTotalMilliseconds(durationInMilliseconds);
            return formattedTimeDuration;
        };

        const _getConstraints = (voicePromptId) => {
            return _promiseFactory.defer((promise) => {
                promptSocket.send("getConstraints", {accountVoicePromptId: voicePromptId}, (constraints, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    const integrityWarnings = getIntegrityWarnings(constraints);
                    promise.resolve(integrityWarnings);
                });
            });
        };

        const _getCurrentVoicePrompt = (voicePromptId) => {
            return _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
        };

        const _deletePrompt = function(voicePromptId) {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    accountVoicePromptId: voicePromptId
                };
                promptSocket.send("delete", request, (result) => {
                    if (result.isSuccessful === false) {
                        const integrityWarnings = getIntegrityWarnings(result.constraints);
                        deferredObject.reject({constraintViolations: integrityWarnings});
                    } else {
                        deferredObject.resolve();
                    }
                });
            });
        };

        const getIntegrityWarnings = (constraints) => {
            return constraints.map((constraint) => {
                const constraintType = constraint.parentType + '-' + constraint.referencedType;
                if (integrityWarningMessagesEnumeration[constraintType]) {
                    const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                    integrityWarning.entityText = integrityWarningMessagesEnumeration[constraintType].entityText;
                    integrityWarning.entityName = constraint.name;
                    integrityWarning.icon = integrityWarningMessagesEnumeration[constraintType].icon;
                    integrityWarning.message = integrityWarningMessagesEnumeration[constraintType].message;
                    return integrityWarning;
                }
            });
        };

        const _onNewPromptConverted = (callback) => {

            promptSocket.subscribeToEvent("voicePromptMp3Converted", (eventData) => {
                const {accountVoicePromptId, durationInMilliseconds} = eventData;
                let updatedAudioData = (_getCurrentVoicePrompt(accountVoicePromptId));
                let formattedTimeDuration = ko.observable(_getFormattedDurationProperty(durationInMilliseconds));
                updatedAudioData.duration = ko.observable(formattedTimeDuration);

                callback({
                    accountVoicePromptId: accountVoicePromptId,
                    audioData: updatedAudioData,
                });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
            _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
            _voicePromptAudioFactory.init();
        };

        const _dispose = () => {
            promptSocket.disposeOfEvents();
        };

        return function() {
            const self = this;

            self.init = _init;
            self.dispose = _dispose;
            self.deletePrompt = _deletePrompt;
            self.findAllVoicePrompts = _findAllVoicePrompts;
            self.getConstraints = _getConstraints;
            self.getCurrentVoicePrompt = _getCurrentVoicePrompt;
            self.onNewPromptConverted = _onNewPromptConverted;
        };
    });

