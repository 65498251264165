define('presentation/common/extensionPicker/viewModels/extensionPickerViewModel',[
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'common/storage/commonState'
], function() {
    return function() {
        const self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');

        let _disposables = [];
        let _extensionsList = null;
        let _settings;

        const PAGE_COUNT = 10;
        const PAGE_EXTENSION_COUNT = 100;

        const _buildOwnedExtensions = () => {
            let ownedExtensions = {};

            _commonState.extensions().forEach((extensionObject) => {
                ownedExtensions[extensionObject.extension] = extensionObject.ownerId;
            });

            return ownedExtensions;
        };

        const _buildPageLabels = () => {
            let blockStart = self.selectedBlock() * 1000;

            self.displayedPages(
                Array.from({length: PAGE_COUNT}).map((value, index) => {
                    const pageLabel = (index * 100) + blockStart;

                    return {
                        value: pageLabel,
                        isSelected: ko.pureComputed(() => self.selectedPage() === _extensionToPageToNumber(pageLabel))
                    };
                })
            );
        };

        const _buildExtensionPage = () => {
            const pageStart = (self.selectedPage() * 100) + (self.selectedBlock() * 1000);

            self.displayedExtensions(
                Array.from({length: PAGE_EXTENSION_COUNT}).map((value, index) => {
                    const extensionNumber = index + pageStart;

                    return {
                        value: extensionNumber,
                        isSelected: ko.pureComputed(() => self.extension() === (extensionNumber)),
                        isDisabled: ko.pureComputed(() => self.ownedExtensions()[extensionNumber] !== undefined)
                    };
                })
            );
        };

        const _buildAvailableExtensionPreview = (extension) => {
            const initialExtensionOwnerId = self.ownedExtensions()[self.initialExtension()];

            if (initialExtensionOwnerId === undefined) {
                return {
                    message: _i18n.t('extensions:changeTo', {extension: extension})
                };
            }

            const initialOwner = _commonState.get(initialExtensionOwnerId);
            const message = ko.pureComputed(() => {
                return initialOwner.name() + ': ' + ((extension === self.initialExtension()) ?
                    _i18n.t('extensions:ext', {extension: extension}):
                    _i18n.t('extensions:changeTo', {extension: extension}));
            });

            return {
                id: initialOwner.id,
                type: initialOwner.type,
                name: initialOwner.name,
                avatar: initialOwner.avatar,
                message: message
            };
        };

        const _buildOwnedExtensionPreview = (extension, extensionOwnerId) => {
            const extensionOwner = _commonState.get(extensionOwnerId);
            const message = ko.pureComputed(() => extensionOwner.name() + ': ' + _i18n.t('extensions:ext', {extension: extension}));

            return {
                id: extensionOwner.id,
                type: extensionOwner.type,
                name: extensionOwner.name,
                avatar: extensionOwner.avatar,
                message: message
            };
        };

        const _findSearchedExtension = () => {
            if (self.searchedExtension().length === 0) {
                return;
            }

            const firstDigit = self.searchedExtension().slice(0, 1);

            if (firstDigit > 0) {
                self.selectedBlock(parseInt(firstDigit));
                self.selectedPage((self.searchedExtension().length >= 2) ? parseInt(self.searchedExtension().slice(1, 2)) : self.selectedPage());
                self.extension((self.searchedExtension().length === 4) ? parseInt(self.searchedExtension()) : self.extension());
            }
        };

        const _updateSearchedExtension = () => {
            self.searchedExtension(self.extension().toString());
        };

        const _extensionToPageToNumber = (extension) => {
            return Math.floor((extension % 1000) / 100);
        };

        const _extensionToBlockNumber = (extension) => {
            return Math.floor(extension / 1000);
        };

        const _resetExtension = () => {
            self.extension(self.initialExtension());
        };

        const _toggleExtensionPickers = (selectedExtension) => {
            _extensionsList().forEach((extension) => {
                if (extension.extension() !== selectedExtension) {
                    extension.showDropdown(false);
                } else {
                    extension.showDropdown(true);
                }
            });
        };

        self.modelStateObserver = null;
        self.isForwardingNumber = false;
        self.isUserGroup = false;
        self.isUser = false;
        self.userAvatar = {};
        self.saveButtonText = _i18n.t('extensions:save');

        self.extension = ko.observable().extend({observeState: true});
        self.initialExtension = ko.observable();
        self.searchedExtension = ko.observable();
        self.ownedExtensions = ko.observable({});
        self.selectedBlock = ko.observable(1);
        self.selectedPage = ko.observable(0);
        self.displayedPages = ko.observable([]);
        self.displayedExtensions = ko.observable([]);

        self.isForwardingNumber = ko.pureComputed(() => self.extensionPreview().type === _commonState.types.forwardingNumber);
        self.isUser = ko.pureComputed(() => self.extensionPreview().type === _commonState.types.user);
        self.isUserGroup = ko.pureComputed(() => self.extensionPreview().type === _commonState.types.userGroup);
        self.isSaveButtonDisabled = ko.pureComputed(() => self.ownedExtensions()[self.extension()] !== undefined);

        self.extensionPreview = ko.pureComputed(() => {
            const extensionOwnerId = self.ownedExtensions()[self.extension()];

            if (extensionOwnerId === undefined) {
                return _buildAvailableExtensionPreview(self.extension());
            } else {
                return _buildOwnedExtensionPreview(self.extension(), extensionOwnerId);
            }
        });

        self.onSaveCallback = () => {};

        self.openDropdown = () => {
            self.modelStateObserver.commitData();
            self.initialExtension(self.extension());
            self.searchedExtension(self.extension().toString());
            self.ownedExtensions(_buildOwnedExtensions());

            _buildPageLabels();
            _buildExtensionPage();
            _toggleExtensionPickers(self.extension());
        };

        self.updateBlock = (increment) => {
            const newBlock = ((self.selectedBlock() + increment) % 10);

            if (newBlock === 0) {
                self.selectedBlock(self.selectedBlock() === 1 ? 9 : 1);
            } else {
                self.selectedBlock(newBlock);
            }
        };

        self.selectPage = (page) => {
            self.selectedPage(_extensionToPageToNumber(page.value));
        };

        self.selectExtension = (extension) => {
            self.extension(extension.value);
        };

        self.cancel = () => {
            _resetExtension();
            self.hideExtensionPickers();
        };

        self.save = () => {
            return _promiseFactory.defer((promise) => {
                self.onSaveCallback(self.extension(), self.initialExtension())
                    .fail(promise.reject)
                    .done(() => {
                        self.hideExtensionPickers();
                        promise.resolve();
                    });
            });
        };

        self.hideExtensionPickers = () => {
            _extensionsList().forEach((extension) => {
                extension.showDropdown(false);
            });
        };

        self.detached = () => {
            _disposables.forEach((disposable) => {
                disposable.dispose();
            });

            _disposables = [];
        };

        self.activate = (settings) => {
            _settings = settings;

            self.modelStateObserver = new ModelStateObserverConstructor(self);

            return _initialize();
        };

        const _initialize = () => {
            _extensionsList = _settings.extensionsList;
            self.extension(ko.unwrap(_settings.extension));
            self.initialExtension(self.extension());
            self.searchedExtension(self.extension().toString());
            self.onSaveCallback = _settings.onSaveCallback;
            self.selectedPage(_extensionToPageToNumber(self.extension()));
            self.selectedBlock(_extensionToBlockNumber(self.extension()));

            _disposables.push(self.selectedPage.subscribe(_buildExtensionPage));
            _disposables.push(self.selectedBlock.subscribe(_buildExtensionPage));
            _disposables.push(self.selectedBlock.subscribe(_buildPageLabels));
            _disposables.push(self.searchedExtension.subscribe(_findSearchedExtension));
            _disposables.push(self.extension.subscribe(_updateSearchedExtension));

            return _promiseFactory.wait();
        };
    };
});

