define('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject',[],
    function() {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject') } MessagingComplianceProfilePresentationObject } */

        /** @typedef { MessagingComplianceProfilePresentationObject }*/
        const self = this;

        self.smsComplianceProfileId = "";
        self.formOfBusiness = ko.observable("");
        self.accountHolderFirstName = ko.observable("");
        self.accountHolderLastName = ko.observable("");
        self.brandName = ko.observable("");
        self.businessName = ko.observable("");
        self.einTaxId = ko.observable("");
        self.registryId = ko.observable("");
        self.businessNumber = ko.observable("");
        self.stateProvinceOfIncorporation = ko.observable("");
        self.addressLineOne = ko.observable("");
        self.addressLineTwo = ko.observable("");
        self.city = ko.observable("");
        self.stateProvince = ko.observable("");
        self.postalCode = ko.observable("");
        self.country = ko.observable("");
        self.phoneNumber = ko.observable("");
        self.phoneNumberCountryAbbreviation = ko.observable("");
        self.websiteUrl = ko.observable("");
        self.industry = ko.observable("");
        self.exchange = ko.observable("");
        self.stockSymbol = ko.observable("");
        self.pointOfContactFirstName = ko.observable("");
        self.pointOfContactLastName = ko.observable("");
        self.pointOfContactJobTitle = ko.observable("");
        self.pointOfContactEmailAddress = ko.observable("");
        self.pointOfContactPhoneNumber = ko.observable("");
        self.pointOfContactPhoneNumberCountryAbbreviation = ko.observable("");
        self.pointOfContactAddressLineOne = ko.observable("");
        self.pointOfContactAddressLineTwo = ko.observable("");
        self.pointOfContactCity = ko.observable("");
        self.pointOfContactStateOrProvince = ko.observable("");
        self.pointOfContactZipOrPostalCode = ko.observable("");
        self.pointOfContactCountry = ko.observable("");
    };
});

