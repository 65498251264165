define('presentation/settings/prompts/presentationObjects/voicePromptPresentationObject',[],function() {
    return function(){
        var self = this;

        self.voicePromptId = null;
        self.promptName = null;
        self.durationInMilliseconds = null;
        self.voicePromptDurationSeconds = null;
        self.promptAudioObject = ko.observable();
        self.isSystemPrompt = false;
    };
});

