define('presentation/inbox/facades/inboxFacade',['constants/callRecordingMessageConstants',
        'constants/voicemailMessageConstants',
        'externalDependencies/clientWebSocket',
        'persistence/webSocket/webSocketApp'
    ],
    function () {
        const callRecordingMessageConstants = require('constants/callRecordingMessageConstants');
        const voicemailMessageConstants = require('constants/voicemailMessageConstants');

        const _callRecordingSubscriberWebSocket = require('externalDependencies/clientWebSocket').forApplication("callRecordingSubscribers");
        const _callRecordingSubscriptionWebSocket = require('externalDependencies/clientWebSocket').forApplication("callRecordingSubscriptionDataSource");
        const _callRecordingWebSocket = require('externalDependencies/clientWebSocket').forApplication("callRecording");
        const _voicemailSubscriptionWebSocket = require('externalDependencies/clientWebSocket').forApplication("voicemailSubscriptionDataSource");
        const _voicemailWebSocket = require('externalDependencies/clientWebSocket').forApplication("voicemail");

        let _promiseFactory = null;
        let _socket = null;

        const init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _socket = new WebSocketAppConstructor();
            _socket.init("inbox");
        };

        const getCallDetails = (callHistoryId) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let request = {
                    callHistoryId: callHistoryId,
                    mapHeight: 200,
                    mapWidth: 744
                };

                _socket.send("get_call_details", request, (results) => {
                    if (results === "error") {
                        promise.reject(new Error(""));
                        return;
                    }
                    promise.resolve(results);
                });
            });
        };

        const deleteCallRecording = (callRecordingId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    callRecordingId: callRecordingId,
                };
                _callRecordingWebSocket.send("deleteCallRecording", data, () => {
                    promise.resolve();
                });
            });
        };

        const deleteVoicemailMessage = (voicemailMessageId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    voicemailMessageId: voicemailMessageId,
                };
                _voicemailWebSocket.send("deleteMessage", data, () => {
                    promise.resolve();
                });
            });
        };

        const setCallRecordingStatus = (callRecordingId, newStatus) => {
            let status;
            switch (newStatus) {
                case callRecordingMessageConstants.status.new.name:
                    status = callRecordingMessageConstants.status.new.id;
                    break;
                case callRecordingMessageConstants.status.reviewed.name:
                    status = callRecordingMessageConstants.status.reviewed.id;
                    break;
                default:
                    throw new Error("Unknown status: " + newStatus);
            }

            return _promiseFactory.defer((promise) => {
                const data = {
                    callRecordingId: callRecordingId,
                    status: status,
                };

                _callRecordingWebSocket.send("setCallRecordingStatus", data, () => {
                    promise.resolve();
                });
            });
        };

        const setVoicemailMessageStatus = (voicemailMessageId, newStatus) => {
            let status;
            switch (newStatus) {
                case voicemailMessageConstants.status.new.name:
                    status = voicemailMessageConstants.status.new.id;
                    break;
                case voicemailMessageConstants.status.reviewed.name:
                    status = voicemailMessageConstants.status.reviewed.id;
                    break;
                default:
                    throw new Error("Unknown status: " + newStatus);
            }

            return _promiseFactory.defer((promise) => {
                const data = {
                    voicemailMessageId: voicemailMessageId,
                    status: status,
                };

                _voicemailWebSocket.send("setMessageStatus", data, () => {
                    promise.resolve();
                });
            });
        };

        const getCallRecordings = ({startDate, endDate, searchText, searchStatus, selectedHostedNumberIds, pageNumber, itemCount}) => {
            return _promiseFactory.defer((promise) => {

                let formattedStartDate = null;
                if (startDate) {
                    formattedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
                }

                let formattedEndDate = null;
                if (endDate) {
                    formattedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString();
                }

                let formattedSearchStatus;
                switch (searchStatus) {
                    case callRecordingMessageConstants.status.new.status:
                        formattedSearchStatus = callRecordingMessageConstants.status.new.id;
                        break;
                    case callRecordingMessageConstants.status.reviewed.status:
                        formattedSearchStatus = callRecordingMessageConstants.status.reviewed.id;
                        break;
                    default:
                        formattedSearchStatus = null;
                        break;
                }

                const request = {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    status: formattedSearchStatus,
                    hostedNumberIds: selectedHostedNumberIds,
                    pageNumber: pageNumber,
                    itemCount: itemCount
                };

                _callRecordingWebSocket.send("initializeCallRecordingList", request, (result) => {
                    const recordings = result.callRecordings.map((callRecording) => {
                        let subscriberIds = result.callRecordingSubscribers.filter((subscriber) => {
                            return subscriber.hostedNumberId === callRecording.hostedNumberId;
                        }).map((subscriber) => {
                            return subscriber.subscriberId;
                        });
                        return Object.assign({subscriberIds: subscriberIds}, callRecording);
                    });
                    promise.resolve(recordings);
                });
            });
        };

        const getFilteredCallRecording = (callRecordingId, {startDate, endDate, searchStatus, selectedHostedNumberIds}) => {
            return _promiseFactory.defer((promise) => {
                let formattedStartDate = null;
                if (startDate) {
                    formattedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
                }

                let formattedEndDate = null;
                if (endDate) {
                    formattedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString();
                }

                let formattedSearchStatus;
                switch (searchStatus) {
                    case callRecordingMessageConstants.status.new.status:
                        formattedSearchStatus = callRecordingMessageConstants.status.new.id;
                        break;
                    case callRecordingMessageConstants.status.reviewed.status:
                        formattedSearchStatus = callRecordingMessageConstants.status.reviewed.id;
                        break;
                    default:
                        formattedSearchStatus = null;
                        break;
                }

                const request = {
                    callRecordingId: callRecordingId,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    status: formattedSearchStatus,
                    hostedNumberIds: selectedHostedNumberIds,
                };

                _callRecordingWebSocket.send("getFilteredCallRecording", request, (result) => {
                    const recordings = result.callRecordings.map((callRecording) => {
                        let subscriberIds = result.callRecordingSubscribers.filter((subscriber) => {
                            return subscriber.hostedNumberId === callRecording.hostedNumberId;
                        }).map((subscriber) => {
                            return subscriber.subscriberId;
                        });
                        return Object.assign({subscriberIds: subscriberIds}, callRecording);
                    });

                    const response = {
                        hasCallRecording: false,
                        foundCallRecording: null
                    };

                    if (recordings.length > 0) {
                        response.hasCallRecording = true;
                        response.foundCallRecording = recordings[0];
                    }

                    promise.resolve(response);
                });

            });
        };

        const getVoicemailMessages = ({ startDate, endDate, searchStatus, selectedVoicemailBoxIds, pageCount, itemCount }) => {
            return _promiseFactory.defer((promise) => {

                let formattedStartDate = null;
                if (startDate) {
                    formattedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
                }

                let formattedEndDate = null;
                if (endDate) {
                    formattedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString();
                }

                let formattedSearchStatus;
                switch (searchStatus) {
                    case voicemailMessageConstants.status.new.status:
                        formattedSearchStatus = voicemailMessageConstants.status.new.id;
                        break;
                    case voicemailMessageConstants.status.reviewed.status:
                        formattedSearchStatus = voicemailMessageConstants.status.reviewed.id;
                        break;
                    default:
                        formattedSearchStatus = null;
                        break;
                }

                const request = {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    messageStatus: formattedSearchStatus,
                    voicemailBoxIds: selectedVoicemailBoxIds,
                    pageCount: pageCount,
                    itemCount: itemCount,
                };
                _voicemailWebSocket.send("initializeMessageList", request, (result) => {
                    promise.resolve(result);
                });
            });
        };

        const getFilteredVoicemailMessage = (voicemailMessageId, {startDate, endDate, searchStatus, selectedVoicemailBoxIds}) => {
            return _promiseFactory.defer((promise) => {

                let formattedStartDate = null;
                if (startDate) {
                    formattedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
                }

                let formattedEndDate = null;
                if (endDate) {
                    formattedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString();
                }

                let formattedSearchStatus;
                switch (searchStatus) {
                    case voicemailMessageConstants.status.new.status:
                        formattedSearchStatus = voicemailMessageConstants.status.new.id;
                        break;
                    case voicemailMessageConstants.status.reviewed.status:
                        formattedSearchStatus = voicemailMessageConstants.status.reviewed.id;
                        break;
                    default:
                        formattedSearchStatus = null;
                        break;
                }

                const request = {
                    voicemailMessageId: voicemailMessageId,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    messageStatus: formattedSearchStatus,
                    voicemailBoxIds: selectedVoicemailBoxIds,
                };

                _voicemailWebSocket.send("getFilteredVoicemailMessage", request, (result) => {
                    const response = {
                        hasVoicemailMessage: false,
                        foundVoicemailMessage: null
                    };

                    if (result.length > 0) {
                        response.hasVoicemailMessage = true;
                        response.foundVoicemailMessage = result[0];
                    }

                    promise.resolve(response);
                });
            });
        };

        const getCallRecordingDownloadUrl = (callRecordingId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    callRecordingId: callRecordingId
                };
                _callRecordingWebSocket.send("getDownloadUrl", data, (result) => {
                    promise.resolve(result.url);
                });
            });
        };

        const getVoicemailDownloadUrl = (voicemailMessageId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    voicemailMessageId: voicemailMessageId
                };
                _voicemailWebSocket.send("getDownloadUrl", data, (result) => {
                    promise.resolve(result.url);
                });
            });
        };

        const checkAnyCallRecordings = () => {
            return _promiseFactory.defer(promise => {
                _callRecordingWebSocket.send("anyCallRecordings", {}, (result) => {
                    promise.resolve(result.anyCallRecordings);
                });
            });
        };

        const checkAnyVoicemailMessages = () => {
            return _promiseFactory.defer(promise => {
                _voicemailWebSocket.send("anyMessages", {}, (result) => {
                    promise.resolve(result.anyMessages);
                });
            });
        };

        const getCallRecordingSubscribers = (accountHostedNumberId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    accountHostedNumberId: accountHostedNumberId
                };
                _callRecordingSubscriberWebSocket.send("getCallRecordingSubscribers", data, (result) => {
                    const subscriberIds = result.callRecordingSubscribersList;
                    promise.resolve(subscriberIds);
                });
            });
        };

        const onCallRecordingStatusChanged = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("callRecordingStatusChanged", (eventData) => {
                const {hostedNumberId, callRecordingId, newStatus} = eventData;
                callback(hostedNumberId, callRecordingId, newStatus);
            });
        };

        const onVoicemailMessageStatusChanged = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailMessageStatusChanged", (eventData) => {
                const {voicemailBoxId, voicemailMessageId, newStatus} = eventData;
                callback(voicemailBoxId, voicemailMessageId, newStatus);
            });
        };


        const onNewCallRecording = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("callRecordingConverted", (eventData) => {
                const {
                    callRecordingId,
                    hostedNumberId,
                    callerAni,
                    callerLocationState,
                    callerLocationCity,
                    durationInMilliseconds,
                    recordingLeftDateTime,
                    status,
                    callHistoryId,
                    isOutbound,
                    initiatedFromUser,
                    destinationPhoneNumber,
                    destinationLocationState,
                    destinationLocationCity
                } = eventData;

                callback({callRecordingId,
                    hostedNumberId,
                    callerAni,
                    callerLocationState,
                    callerLocationCity,
                    durationInMilliseconds,
                    recordingLeftDateTime,
                    status,
                    callHistoryId,
                    isOutbound,
                    initiatedFromUser,
                    destinationPhoneNumber,
                    destinationLocationState,
                    destinationLocationCity
                });
            });
        };

        const onNewVoicemailMessage = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailMessageConverted", (voicemailMessage) => {
                callback(voicemailMessage);
            });
        };

        const onCallRecordingSubscribersChanged = (callback) => {
            _callRecordingSubscriberWebSocket.subscribeToEvent("callRecordingSubscribersChanged", (eventData) => {
                const {accountHostedNumberId, subscriberIds} = eventData;
                callback(accountHostedNumberId, subscriberIds);
            });
        };

        const onCallRecordingSubscriberSettingsChanged = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("callRecordingSubscriberSettingsChanged", (eventData) => {
                const {hostedNumberId, isActiveSubscriber, isShowInSidebarEnabled} = eventData;
                callback(hostedNumberId, isActiveSubscriber, isShowInSidebarEnabled);
            });
        };

        const onVoicemailSubscriberSettingsChanged = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailBoxSubscriberSettingsChanged", (eventData) => {
                callback(eventData);
            });
        };

        const onCallRecordingDeleted = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("callRecordingDeleted", (eventData) => {
                const {callRecordingId} = eventData;
                callback(callRecordingId);
            });
        };

        const onVoicemailBoxDeleted = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailBoxDeleted", (eventData) => {
                callback(eventData);
            });
        };

        const onVoicemailBoxReactivated = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailBoxReactivated", (eventData) => {
                callback(eventData);
            });
        };

        const onVoicemailMessageDeleted = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailMessageDeleted", (voicemailMessage) => {
                callback(voicemailMessage);
            });
        };

        const onHostedNumberStatusChanged = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("accountHostedNumberStatusChanged", (eventData) => {
                const {hostedNumberId, newStatus} = eventData;
                callback(hostedNumberId, newStatus);
            });
        };

        const _disposeCallRecordings = () => {
            _callRecordingSubscriberWebSocket.disposeOfEvents();
            _callRecordingSubscriptionWebSocket.disposeOfEvents();
            _callRecordingWebSocket.disposeOfEvents();
        };

        const _disposeVoicemail = () => {
            _voicemailSubscriptionWebSocket.disposeOfEvents();
            _voicemailWebSocket.disposeOfEvents();
        };

        return function () {
            let self = this;

            self.init = init;
            self.checkAnyCallRecordings = checkAnyCallRecordings;
            self.checkAnyVoicemailMessages = checkAnyVoicemailMessages;
            self.deleteCallRecording = deleteCallRecording;
            self.deleteVoicemailMessage = deleteVoicemailMessage;
            self.getCallDetails = getCallDetails;
            self.getCallRecordingDownloadUrl = getCallRecordingDownloadUrl;
            self.getCallRecordings = getCallRecordings;
            self.getCallRecordingSubscribers = getCallRecordingSubscribers;
            self.getFilteredCallRecording = getFilteredCallRecording;
            self.getFilteredVoicemailMessage = getFilteredVoicemailMessage;
            self.getVoicemailDownloadUrl = getVoicemailDownloadUrl;
            self.getVoicemailMessages = getVoicemailMessages;
            self.onCallRecordingDeleted = onCallRecordingDeleted;
            self.onCallRecordingStatusChanged = onCallRecordingStatusChanged;
            self.onCallRecordingSubscribersChanged = onCallRecordingSubscribersChanged;
            self.onCallRecordingSubscriberSettingsChanged = onCallRecordingSubscriberSettingsChanged;
            self.onHostedNumberStatusChanged = onHostedNumberStatusChanged;
            self.onNewCallRecording = onNewCallRecording;
            self.onNewVoicemailMessage = onNewVoicemailMessage;
            self.onVoicemailBoxDeleted = onVoicemailBoxDeleted;
            self.onVoicemailBoxReactivated = onVoicemailBoxReactivated;
            self.onVoicemailMessageDeleted = onVoicemailMessageDeleted;
            self.onVoicemailMessageStatusChanged = onVoicemailMessageStatusChanged;
            self.onVoicemailSubscriberSettingsChanged = onVoicemailSubscriberSettingsChanged;
            self.setCallRecordingStatus = setCallRecordingStatus;
            self.setVoicemailMessageStatus = setVoicemailMessageStatus;
            self.disposeCallRecordings = _disposeCallRecordings;
            self.disposeVoicemail = _disposeVoicemail;
        };
    }
);

