define('constants/smsCampaignRegistrationConstants',[], function() {
    return {
        smsAccountTcrRegistrationStatuses: {
            NONE: 1,
            PENDING: 2,
            FAILED: 3,
            SUCCEEDED: 4,
        },
        smsAccountPhoneNumberLimits: {
            NONE: -1,
        },
        smsAccountPhoneNumberLimitReasons: {
            NONE: 1,
            NO_MESSAGING_COMPLIANCE: 2,
            REACHED_SP_LIMIT: 3,
            REACHED_PERSONAL_ACCOUNT_LIMIT: 4,
            TCR_REGISTRATION_FAILED: 5,
        },
        smsDisabledReasons: {
            NONE: 1,
            NO_MESSAGING_COMPLIANCE: 2,
            REACHED_SP_LIMIT: 3,
            REACHED_PERSONAL_ACCOUNT_LIMIT: 4,
            TCR_REGISTRATION_FAILED: 5,
            BW_ASSOCIATION_FAILED: 6,
            PORT_PENDING: 7,
            REACHED_TOLL_FREE_SMS_LIMIT: 8,
            IN_FREE_TRIAL: 9
        },
        hostedNumberCampaignRegistrationStatuses: {
            DEFAULT: 1,
            PENDING: 2,
            SUCCESS: 3,
            FAILED: 4,
        }
    };
});
