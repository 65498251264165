define('businessServices/converters/creditCardEnumerationValueConverter',['constants/creditCardTypeEnumerations'],
    function() {
        var _creditCardTypeEnumerations = require('constants/creditCardTypeEnumerations');

        var _getEnumerationValueByType = function(cardTypeToMatch) {
            var result = null;

            for (var creditCardType in _creditCardTypeEnumerations) {
                if (_creditCardTypeEnumerations.hasOwnProperty(creditCardType)) {
                    if (_creditCardTypeEnumerations[creditCardType].type === cardTypeToMatch) {
                        result = _creditCardTypeEnumerations[creditCardType];
                        break;
                    }
                }
            }

            return result;
        };

        return {
            getEnumerationValueByType: _getEnumerationValueByType
        };
});

