define('presentation/signup/common/signupPageIndicatorWidget',[
    'businessServices/router/router',
    'presentation/signup/common/signupNavigationManager',
    'constants/signupSessionFlowConstants'
], () => {
    const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
    const _router = require('businessServices/router/router');

    const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
    const _signupNavigationManager = new SignupNavigationManagerConstructor();
    _signupNavigationManager.init();

    const _setIndicatorWidgetState = (pageIndicatorLines, pageIndicatorIcons, isPageIndicatorVisible) => {
        const routeId = _signupNavigationManager.getRouteId();
        const flowId = _signupNavigationManager.getFlowId();
        const flow = _signupNavigationManager.getFlow(flowId);

        const statusIconObject = (page, index) => ({
            isCurrent: index === page,
            indicatorClass: index < page ?
                "signup-page-counter__dot--complete" :
                "signup-page-counter__dot--todo",
            navigate: index < page ?
                () => _router.navigate(flow.routes[index]()) :
                () => null
        });

        const buildIndicatorIcons = (page, pages) => Array.from(new Array(pages), (_, index) => statusIconObject(page, index));

        const buildIndicatorLines = (page, pages) => Array.from(new Array(pages - 1), (_, index) => index < page ?
            "signup-page-counter__bar--past" :
            "signup-page-counter__bar--future"
        );

        if ((routeId === "signupEmail" && flowId === _signupSessionFlowConstants.flowA) || routeId === "signupSuccess") {
            isPageIndicatorVisible(false);
        } else {
            isPageIndicatorVisible(true);
        }

        pageIndicatorLines(buildIndicatorLines(flow.routeIndexes[routeId], flow.routes.length));
        pageIndicatorIcons(buildIndicatorIcons(flow.routeIndexes[routeId], flow.routes.length));
    };

    return {
        setIndicator: _setIndicatorWidgetState
    };
});
