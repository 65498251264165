define('presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/callRecording/callRecordingAudioFactory',
    'presentation/common/voicemail/voicemailAudioFactory'
], function() {
    return function(callMediaObject, getMediaById, setMediaStatus) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _callRecordingAudioFactory = require('presentation/common/callRecording/callRecordingAudioFactory');
        const _voicemailAudioFactory = require('presentation/common/voicemail/voicemailAudioFactory');

        const _callRecordingMessageConstants = require('constants/callRecordingMessageConstants');
        const _voicemailMessageConstants = require('constants/voicemailMessageConstants');

        const CALL_RECORDING_TYPE = "call recording";
        const VOICEMAIL_TYPE = "voicemail";

        self.mediaType = ko.observable('');
        self.isMediaLoaded = false;
        self.callMediaId = '';
        self.callerAvatar = null;
        self.isStatusNew = false;
        self.fromLocation = null;
        self.callMediaBoxMetadata = null;
        self.callRecordingBoxType = '';
        self.callRecordingBoxAvatar = '';
        self.callRecordingBoxInitials = '';
        self.isOutbound = false;
        self.ownerType = null;
        self.ownerUserAvatar = null;
        self.ownerUserGroupId = '';
        self.callMediaAudio = ko.observable(_callRecordingAudioFactory.getEmptyCallRecording());
        self.durationInSeconds = 0;
        self.callMediaBoxName = '';
        self.callerAniDisplay = '';
        self.callerInitials = '';
        self.callerType = '';

        self.isCallRecording = ko.pureComputed(() => {
            return self.mediaType() === CALL_RECORDING_TYPE;
        });

        self.getMediaById = getMediaById;
        self.setMediaStatus = setMediaStatus;

        self.playMedia = () => {
            let newMediaStatus = self.isCallRecording() ?
                _callRecordingMessageConstants.status.reviewed.name :
                _voicemailMessageConstants.status.reviewed.name;

            if (self.isMediaLoaded === false) {
                self.getMediaById(self.callMediaId)
                    .done((url) => {
                        let newAudio = self.isCallRecording() ?
                            _callRecordingAudioFactory.playCallRecording(url) :
                            _voicemailAudioFactory.playVoicemail(url);

                        self.callMediaAudio(newAudio);
                        self.isMediaLoaded = true;

                        self.setMediaStatus(callMediaObject, newMediaStatus);
                    });
            } else {
                // Force the audio to play again.
                self.callMediaAudio.valueHasMutated();

                self.setMediaStatus(callMediaObject, newMediaStatus);
            }
        };

        const _mapCallMediaData = (callMediaObject) => {
            switch (self.mediaType()) {
                case CALL_RECORDING_TYPE:
                    self.callMediaId = callMediaObject.callRecordingId;
                    self.callMediaAudio(_callRecordingAudioFactory.getEmptyCallRecording());
                    self.callMediaBoxName = callMediaObject.callRecordingBoxName;
                    self.isOutbound = callMediaObject.isOutbound;
                    self.callMediaBoxMetadata = callMediaObject.toLocation;
                    self.callRecordingBoxType = callMediaObject.callRecordingBoxType;
                    self.callRecordingBoxAvatar = callMediaObject.callRecordingBoxAvatar;
                    self.callRecordingBoxInitials = callMediaObject.callRecordingBoxInitials;
                    break;
                case VOICEMAIL_TYPE:
                    self.callMediaId = callMediaObject.voicemailMessageId;
                    self.callMediaAudio(_voicemailAudioFactory.getEmptyVoicemail());
                    self.callMediaBoxName = callMediaObject.voicemailBoxName;
                    self.ownerType = callMediaObject.ownerType;
                    self.ownerUserAvatar = callMediaObject.ownerUserAvatar;
                    self.ownerUserGroupId = callMediaObject.ownerUserGroupId;
                    break;
            }

            self.callerAniDisplay = ko.unwrap(callMediaObject.callerAniDisplay);
            self.callerAvatar = callMediaObject.callerAvatar;
            self.callerInitials = callMediaObject.callerInitials;
            self.callerType = callMediaObject.callerType;
            self.durationInSeconds = callMediaObject.durationInSeconds;
            self.fromLocation = callMediaObject.fromLocation;
        };

        self.activate = () => {
            self.mediaType(callMediaObject.hasOwnProperty("callRecordingId") ? CALL_RECORDING_TYPE : VOICEMAIL_TYPE);

            _mapCallMediaData(callMediaObject);

            return _promiseFactory.wait();
        };
    };
});
