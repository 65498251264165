define('common/collections/collectionSorter',[], function() {
    return function () {
        let self = this;

        const FIRST_ITEM_LESS_THAN_SECOND_ITEM = -1;
        const FIRST_ITEM_GREATER_THAN_SECOND_ITEM = 1;
        const FIRST_ITEM_EQUAL_TO_SECOND_ITEM = 0;

        const _comparison = (item1, item2, propertyPaths) => {
            const item1Value = _getValue(item1, propertyPaths);
            const item2Value = _getValue(item2, propertyPaths);

            if (item1Value === item2Value) {
                return FIRST_ITEM_EQUAL_TO_SECOND_ITEM;
            }
            if (item1Value === null && item2Value !== null) {
                return FIRST_ITEM_LESS_THAN_SECOND_ITEM;
            }
            if (item2Value === null && item1Value !== null) {
                return FIRST_ITEM_GREATER_THAN_SECOND_ITEM;
            }
            if (item1Value > item2Value) {
                return FIRST_ITEM_GREATER_THAN_SECOND_ITEM;
            }
            return FIRST_ITEM_LESS_THAN_SECOND_ITEM;
        };

        const _reverseComparison = (item1, item2, propertyPaths) => {
            const compare = _comparison(item1, item2, propertyPaths);
            return compare * -1;
        };

        const _getValue = (item, propertyPaths) => {
            let currentObject = item;

            for (let propertyNameIndex = 0; propertyNameIndex < propertyPaths.length; propertyNameIndex++) {
                const propertyName = propertyPaths[propertyNameIndex];
                currentObject = ko.unwrap(currentObject[propertyName]);
                if ((currentObject === undefined) || (currentObject === null)) {
                    return null;
                }

                if (typeof currentObject === "function") {
                    currentObject = currentObject();
                }
            }

            if (typeof(currentObject) === "string") {
                return currentObject.toLowerCase();
            } else {
                return currentObject;
            }
        };

        let _propertyPath = null;
        let _isAscending = true;

        self.setOptions = (options, sortType) => {
            const option = options.find((option) => {
                return option.sortPath === sortType;
            });
            if (option === undefined) {
                return false;
            }
            _propertyPath = option.sortPath.split(".");
            if (option.isAscending !== undefined && option.isAscending !== null) {
                _isAscending = option.isAscending;
            }
            return true;
        };

        self.sort = (items, propertyPath, isAscending) => {
            let propertyPaths;
            let ascending;
            if (propertyPath === undefined) {
                propertyPaths = _propertyPath;
                ascending = _isAscending;
            }
            else {
                if ((isAscending === null) || (isAscending === undefined)) {
                    ascending = true;
                } else {
                    ascending = isAscending;
                }

                propertyPaths = propertyPath.split(".");
            }

            if (ascending) {
                items.sort((item1, item2) => {
                    return _comparison(item1, item2, propertyPaths);
                });
            } else {
                items.sort((item1, item2) => {
                    return _reverseComparison(item1, item2, propertyPaths);
                });
            }
        };

        /**
         * Sorts a collection based on multiple property paths
         * @param {Array} items the collection to be sorted
         * @param {Array} propertyPaths the property paths of the values to use for sorting
         * @param {(boolean|Array)} [isAscending=true] Indicates to sort ascending or descending.  
         */
        self.multiSort = (items, propertyPaths, isAscending) => {
            let ascending;
            if ((isAscending === null) || (isAscending === undefined)) {
                ascending = propertyPaths.map(() => true);
            } else if (typeof isAscending === "boolean") {
                ascending = propertyPaths.map(() => isAscending);
            } else {
                ascending = isAscending;
            }

            items.sort(function(item1, item2) {
                let sortResult = 0;
                for (let i=0; i < propertyPaths.length; i++) {
                    const propertyPath = propertyPaths[i].split(".");
                    if (ascending[i]) {
                        sortResult = _comparison(item1, item2, propertyPath);
                    } else {
                        sortResult = _reverseComparison(item1, item2, propertyPath);
                    }

                    if (sortResult !== FIRST_ITEM_EQUAL_TO_SECOND_ITEM) {
                        break;
                    }
                }

                return sortResult;
            });
        };
    };
});

