define('presentation/settings/userGroup/facades/userGroupVoicemailSubscribersFacade',['common/promises/promiseFactory',
        'common/storage/commonState',
        'externalDependencies/clientWebSocket',
        'presentation/settings/userGroup/presentationObjects/voicemailSubscribersPresentationObject',

], function () {
    const VoicemailSubscribersPresentationObjectConstructor = require('presentation/settings/userGroup/presentationObjects/voicemailSubscribersPresentationObject');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _userGroupVoicemailSocket = clientWebSocket.forApplication("userGroupVoicemail");

    const _commonState = require('common/storage/commonState');

    let _promiseFactory = null;

    const _getVoicemailBoxSubscribers = (userGroupId) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const userGroupGuid =  _commonState.resolveGroupIdToGuid(userGroupId);

            const request = {
                userGroupId: userGroupGuid
            };

            _userGroupVoicemailSocket.send("listSubscribers", request, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }

                let subscribers = response.subscribers;
                let subscribersList = subscribers.map(subscriber => {
                    const subscriberPresentationObject = new VoicemailSubscribersPresentationObjectConstructor();
                    if (subscriber.userId) {
                        subscriberPresentationObject.subscriberId = subscriber.userId;
                        subscriberPresentationObject.avatarType = "user";
                    }
                    else if (subscriber.userGroupId) {
                        subscriberPresentationObject.subscriberId = subscriber.userGroupId;
                        subscriberPresentationObject.avatarType = "userGroup";
                    }
                    return subscriberPresentationObject;
                });

                promise.resolve({
                    subscribers : subscribersList,
                    subscribersLog : response.subscribersLog
                });
            });
        });
    };

    const _saveVoicemailBoxSubscribers = (userGroupId, subscribers) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const userGroupGuid =  _commonState.resolveGroupIdToGuid(userGroupId);
            let subscribersToUpdate = subscribers.subscribers.map(subscriber => {
                let subscriberToUpdate = {};
                if (subscriber.subscriberId === "All Members") {
                    subscriberToUpdate.userId = null;
                    subscriberToUpdate.userGroupId = userGroupGuid;
                } else {
                    subscriberToUpdate.userId = subscriber.userId;
                    subscriberToUpdate.userGroupId = _commonState.resolveGroupIdToGuid(subscriber.userGroupId);
                }
                return subscriberToUpdate;
            });
            const request = {
                userGroupId: userGroupGuid,
                subscribers: subscribersToUpdate,
            };

            _userGroupVoicemailSocket.send("updateSubscribers", request, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }

                promise.resolve();
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    return function () {
        let self = this;

        self.init = _init;
        self.getVoicemailBoxSubscribers = _getVoicemailBoxSubscribers;
        self.saveVoicemailBoxSubscribers = _saveVoicemailBoxSubscribers;
    };
});
