define('presentation/contacts/viewModels/addContactPhoneNumberViewModel',[
    'businessServices/state/modelStateObserver',
    'constants/contactPhoneNumberLabelEnumerations'
], function (
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('constants/contactPhoneNumberLabelEnumerations') */
    ContactPhoneNumberLabel
) {
    /** @typedef { import('presentation/contacts/viewModels/addContactViewModel') } AddContactViewModel */

    return function (/** @type AddContactViewModel */ parent) {

        let self = this;

        self.parent = parent;

        self.modelStateObserver = null;

        self.accountContactPhoneNumberId = '';
        self.label = ko.observable(ContactPhoneNumberLabel.Mobile.toString()).extend({observeState: true});
        self.number = ko.observable('').extend({observeState: true});

        self.onRemovePhoneNumberClick = () => {
            self.parent.onRemovePhoneNumberClick(self);
        };

        self.cancelForm = () => {
            if (self.number.isDirty) {
                self.number.forceUpdate(true);
            }

            self.modelStateObserver.restoreData();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserver(self, true);
            self.modelStateObserver.commitData();
        };

        _initialize();
    };
});
