define('presentation/acceptUserInvite/viewModels/createUserSecurityViewModel',[
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'common/files/encoding',
    'presentation/acceptUserInvite/validators/createUserSecurityValidator',
    'constants/secretQuestionEnumerations'
],
function() {
    return function(_userProfile) {
        const self = this;

        const _keyValueConverter = require('common/collections/enumerationKeyValueConverter');
        const _verificationQuestionsEnumerations = require('constants/secretQuestionEnumerations');
        const VERIFICATION_QUESTIONS_LIST = _keyValueConverter.convertToKeyValues(_verificationQuestionsEnumerations);

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        let _validator = null;

        const _forceConfirmPasswordValidation = () => {
            let isMatch = _validator.validatePasswordsMatch();
            self.confirmPassword.isValid(isMatch);
            if (isMatch === false) {
                self.confirmPassword.validationMessage(_i18n.t('passwordDoesNotMatch'));
            }
        };

        self.password = ko.observable("");
        self.confirmPassword = ko.observable("");
        self.passwordPlaceholder = _i18n.t('acceptUserInvite:passwordPlaceholder');
        self.verificationQuestion = ko.observable("");
        self.verificationAnswer = ko.observable("");

        self.canDisplayPasswordValidation = ko.observable(false);
        self.canDisplayConfirmPasswordValidation = ko.observable(false);

        self.verificationQuestions = VERIFICATION_QUESTIONS_LIST.map((question) => {
            question.id = question.key;
            question.text = question.value;
            return question;
        });

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            self.canDisplayPasswordValidation(true);
                            self.canDisplayConfirmPasswordValidation(true);
                            self.password.subscribe(_forceConfirmPasswordValidation);
                        }

                        promise.resolve(isValid);
                    });
            });
        };

        self.activate = () => {
            const ValidatorConstructor = require('presentation/acceptUserInvite/validators/createUserSecurityValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.password = _userProfile.password;
            self.verificationQuestion = _userProfile.verificationQuestion;
            self.verificationAnswer = _userProfile.verificationAnswer;

            if (self.verificationQuestion() === "") {
                self.verificationQuestion(self.verificationQuestions[0].id);
            }

            _validator.registerViewModel(self);

            self.passwordIsInvalid = ko.pureComputed(() => self.password.isInvalid() && self.canDisplayPasswordValidation());
            self.confirmPasswordIsInvalid = ko.pureComputed(() => self.confirmPassword.isInvalid() && self.canDisplayConfirmPasswordValidation());

            return _promiseFactory.wait();
        };
    };
});
