define('presentation/inbox/facades/inboxSidebarFacade',['businessServices/userSettings/userSettingStore'],
    function () {

        let _promiseFactory = null;
        let _userSettingStore = null;

        const getAllNewReviewedCallRecordingsFilterState = () => {
            return _promiseFactory.defer((promise) => {
                _userSettingStore.get("callRecordingSidebar")
                    .done((result) => {
                        if (result.selectedNewReviewedFilter) {
                            promise.resolve(result.selectedNewReviewedFilter);
                        } else {
                            promise.resolve("new");
                        }
                    });
            });
        };

        const getAllNewReviewedVoicemailFilterState = () => {
            return _promiseFactory.defer((promise) => {
                _userSettingStore.get("voicemailSidebar")
                    .done((result) => {
                        if (result.selectedNewReviewedFilter) {
                            promise.resolve(result.selectedNewReviewedFilter);
                        } else {
                            promise.resolve("new");
                        }
                    });
            });
        };

        const setAllNewReviewedCallRecordingsFilterStatus = (selectedNewReviewedFilter) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    selectedNewReviewedFilter: selectedNewReviewedFilter
                };

                _userSettingStore.set("callRecordingSidebar", data)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const setAllNewReviewedVoicemailFilterStatus = (selectedNewReviewedFilter) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    selectedNewReviewedFilter: selectedNewReviewedFilter
                };

                _userSettingStore.set("voicemailSidebar", data)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const init = function (promiseFactory) {
            _promiseFactory = promiseFactory;

            const UserSettingStoreConstructor = require('businessServices/userSettings/userSettingStore');
            _userSettingStore = new UserSettingStoreConstructor();
            _userSettingStore.init();
        };

        return function () {
            let self = this;

            self.init = init;
            self.getAllNewReviewedCallRecordingsFilterState = getAllNewReviewedCallRecordingsFilterState;
            self.getAllNewReviewedVoicemailFilterState = getAllNewReviewedVoicemailFilterState;
            self.setAllNewReviewedCallRecordingsFilterStatus = setAllNewReviewedCallRecordingsFilterStatus;
            self.setAllNewReviewedVoicemailFilterStatus = setAllNewReviewedVoicemailFilterStatus;
        };
    }
);
