define('presentation/settings/phoneNumbers/viewModels/numberToPortViewModel',[
    'common/converters/phoneNumberFormatConverter',
    'common/converters/phoneNumberFormatter',
    'businessServices/state/modelStateObserver',
    'presentation/settings/phoneNumbers/validators/numberToPortValidator',
], function () {
    return function (parent, facade) {
        let self = this;

        const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatConverter');
        const _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const _facade = facade;
        const _parent = parent;

        const _i18n = require('i18next');

        self.modelStateObserver = null;
        self.validator = null;
        self.index = 0;

        self.numberToPort = ko.observable('').extend({observeState: true, rateLimit: {timeout: 1000, method: 'notifyWhenChangesStop'}});
        self.countryAbbreviation = ko.observable("us").extend({observeState: true});
        self.carrierTooltip = _i18n.t('numberToPort:tooltipLine1') + '</br>' + _i18n.t('numberToPort:tooltipLine2') + '</br>' + _i18n.t('numberToPort:tooltipLine3');
        self.formattedNumberToPort = ko.computed(() => _phoneNumberFormatter.toUSAreaCode(self.numberToPort()));
        self.rawNumberToPort = ko.computed(() => _phoneNumberFormatConverter.getRaw(self.numberToPort()));

        self.shouldPreventDeletion = ko.computed(() => {
            return !_parent.canDeleteNumber();
        });

        self.portingPhoneNumbers = ko.computed(() => {
            return _parent.numbersToPort().map(numberToPort => numberToPort.numberToPort());
        });

        self.deleteNumber = () => {
            _parent.deleteNumber(self);
        };

        self.validate = () => {
            return self.validator.validate();
        };

        const _initialize = () => {
            const ValidatorConstructor = require('presentation/settings/phoneNumbers/validators/numberToPortValidator');
            self.validator = new ValidatorConstructor();
            self.validator.registerViewModel(self, _facade);

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.commitData();
        };

        _initialize();
    };
});

