define('presentation/forgotPassword/facades/resetPasswordFacade',['businessServices/url/url',
    'persistence/dataProviders/resetPasswordDataProvider',
    'persistence/repositories/resetPasswordRepository'
], function () {

    let _resetPasswordDataProvider = null;
    let _resetPasswordRepository = null;
    let _promiseFactory = null;

    const URLBusinessServiceConstructor = require('businessServices/url/url');
    const _urlBusinessService = new URLBusinessServiceConstructor();

    const _constructResetPasswordUrl = (emailAddress, expirationDateTime) => {
        return _urlBusinessService.getBaseURL() + "/reset-password/" + emailAddress + "/" + expirationDateTime;
    };

    const _validateLink = (emailAddress, expirationDateTime, signature) => {
        return _promiseFactory.defer((deferredObject) => {
            const url = _constructResetPasswordUrl(emailAddress, expirationDateTime);
            _resetPasswordDataProvider.validateLink(emailAddress, url, signature)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _resetPassword = (emailAddress, password, expirationDateTime, signature) => {
        return _promiseFactory.defer((deferredObject) => {
            const url = _constructResetPasswordUrl(emailAddress, expirationDateTime);
            _resetPasswordRepository.resetPassword(emailAddress, password, url, signature)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const ResetPasswordDataProviderConstructor = require('persistence/dataProviders/resetPasswordDataProvider');
        _resetPasswordDataProvider = new ResetPasswordDataProviderConstructor();

        const ResetPasswordRepositoryConstructor = require('persistence/repositories/resetPasswordRepository');
        _resetPasswordRepository = new ResetPasswordRepositoryConstructor();
    };

    return function () {
        const self = this;

        self.validateLink = _validateLink;
        self.resetPassword = _resetPassword;
        self.init = _init;
    };
});

