define('businessServices/numberProvisioning/numberProvisioner',['durandal/system',
    'common/promises/promiseFactory',
    'externalDependencies/clientApi',
    'externalDependencies/kazooApi',
    'businessServices/numberProvisioning/phoneNumberResult',
    'businessServices/numberProvisioning/phoneNumberMetaResult',
    'businessServices/url/urlBuilder'
], function () {

    let _kazoo = null;
    let _system = null;
    let PhoneNumberResultConstructor = null;
    let PhoneNumberMetaResultConstructor = null;
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const UrlBuilder = require('businessServices/url/urlBuilder');
    const _promiseFactory = new PromiseFactoryConstructor();
    const _baseUrl = 'numberProvisioning/';

    let _clientApi = null;
    let _initial = null;
    let _geoLocation = null;

    const _init = function () {
        const KazooConstructor = require('externalDependencies/kazooApi');
        _kazoo = new KazooConstructor();
        _system = require('durandal/system');
        PhoneNumberResultConstructor = require('businessServices/numberProvisioning/phoneNumberResult');
        PhoneNumberMetaResultConstructor = require('businessServices/numberProvisioning/phoneNumberMetaResult');

        const ClientApiConstructor = require('externalDependencies/clientApi');
        _clientApi = new ClientApiConstructor();
        _clientApi.init();
    };


    const _provisionNumber = function (phoneNumber) {
        return _promiseFactory.deferIndefinitely(function (promise) {
            const provisionRequest = {
                "data" : {
                    "phoneNumbers" : [
                        {
                            "phoneNumber" : phoneNumber
                        }
                    ]
                }
            };

            return _kazoo.callAccount("/lobby_phone_connectors/provision", "post", provisionRequest)
                .done(function(response) {
                    const status = response.data.data.status;
                    const successPhoneNumbers = response.data.data.successfulProvisioned.map(function(phoneInfo){
                        return phoneInfo.phoneNumber;
                    });
                    const failedPhoneNumbers = response.data.data.failedProvisioned.map(function(phoneInfo){
                        return phoneInfo.phoneNumber;
                    });

                    const result = {
                        status : status,
                        successfulPhoneNumbers : successPhoneNumbers,
                        failedPhoneNumbers : failedPhoneNumbers
                    };

                    promise.resolve(result);
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    };

    const _provisionNumbers = function (phoneNumbers) {
        if (phoneNumbers.length === 0) {
            throw new Error("Must provision at least one phone number");
        }

        return _promiseFactory.deferIndefinitely(function (promise) {
            const phoneNumberInfo = phoneNumbers.map(function(phoneNumber) {
                return { "phoneNumber" : phoneNumber };
            });
            const provisionRequest = {
                "data" : {
                    "phoneNumbers" : phoneNumberInfo
                }
            };

            _kazoo.setHasTimeout(false);
            _kazoo.callAccount("/lobby_phone_connectors/provision", "post", provisionRequest)
                .done(function(response) {
                    _kazoo.setHasTimeout(true);
                    const status = response.data.data.status;
                    const successPhoneNumbers = response.data.data.successfulProvisioned.map(function(phoneInfo){
                        return phoneInfo.phoneNumber;
                    });
                    const failedPhoneNumbers = response.data.data.failedProvisioned.map(function(phoneInfo){
                        return phoneInfo.phoneNumber;
                    });

                    const result = {
                        status : status,
                        successfulPhoneNumbers : successPhoneNumbers,
                        failedPhoneNumbers : failedPhoneNumbers
                    };

                    promise.resolve(result);
                })
                .fail(function(error) {
                    _kazoo.setHasTimeout(true);
                    promise.reject(error);
                });
        });
    };

    const _searchTollFree = (prefix) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _clientApi.call(`numberProvisioning/searchTollFree?prefix=${prefix}`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchByNpa = function (npa) {
        return _promiseFactory.deferIndefinitely(function (deferredObject) {
            _clientApi.call(`numberProvisioning/searchByNpa?npa=${npa}`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchByCity = function (region, city, npa) {
        return _promiseFactory.deferIndefinitely(function (deferredObject) {
            _clientApi.call(`numberProvisioning/search?region=${region}&city=${city}&npa=${npa}`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchByRegionCity = (region, city) => {
        return _promiseFactory.deferIndefinitely(function (deferredObject) {
            _clientApi.call(`numberProvisioning/searchCityAndRegion?region=${region}&city=${city}`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchByIpAddress = () => {
        return _promiseFactory.deferIndefinitely(function (deferredObject) {
            _clientApi.call(`numberProvisioning/searchByIpAddress`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _getCountries = function() {
        return _promiseFactory.deferIndefinitely(function(deferredObject) {
            _clientApi.call("numberProvisioning/regions", "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _getCities = function(regionCode) {
        return _promiseFactory.deferIndefinitely(function(deferredObject) {
            _clientApi.call(`numberProvisioning/cities?region=${regionCode}`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _getGeoLocation = () => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            if (_geoLocation) {
                deferredObject.resolve(_geoLocation);
                return;
            }
            _clientApi.call("numberProvisioning/geoLocation", "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(geoLocation => {
                    _geoLocation = geoLocation;
                    deferredObject.resolve(_geoLocation);
                });
        });
    };

    const _getInitial = () => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            if (_initial) {
                deferredObject.resolve(_initial);
            } else {
                _clientApi.call("numberProvisioning/getInitial", "GET", {}, 'application/json')
                    .fail(deferredObject.reject)
                    .done(result => {
                        _initial = result;
                        deferredObject.resolve(_initial);
                    });
            }
        });
    };

    const _buildUrlBuilder = (urlPath, accountId, signupSessionId) => {
        const urlBuilder = new UrlBuilder(_baseUrl);
        urlBuilder.addUrlPath(urlPath);
        urlBuilder.addQueryString("path", window.location.pathname);

        if (accountId !== null) {
            urlBuilder.addQueryString("accountId", accountId);
        }

        if (signupSessionId !== null) {
            urlBuilder.addQueryString("signupSessionId", signupSessionId);
        }
        return urlBuilder;
    };

    const _getInitialGeolocation = ({accountId = null, signupSessionId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildUrlBuilder("getInitialGeolocation", accountId, signupSessionId);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchLocalNumbers = ({region, city, requestType, accountId = null, signupSessionId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildUrlBuilder("findLocalNumbers", accountId, signupSessionId);
            urlBuilder.addQueryString("regionCode", region);
            urlBuilder.addQueryString("requestType", requestType);
            urlBuilder.addQueryString("cityCode", city);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchLocalRepeatingNumbers = ({npa, region, requestType, accountId = null, signupSessionId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildUrlBuilder("searchLocalRepeatingDigits", accountId, signupSessionId);
            urlBuilder.addQueryString("npa", npa);
            urlBuilder.addQueryString("region", region);
            urlBuilder.addQueryString("requestType", requestType);
            urlBuilder.addQueryString("resultBlockSize", 4);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchLocalVanityNumbers = ({npa, region, vanityString, startsWith, endsWith, signupSessionId, requestType, accountId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildUrlBuilder("searchLocalVanity", accountId, signupSessionId);
            urlBuilder.addQueryString("npa", npa);
            urlBuilder.addQueryString("region", region);
            urlBuilder.addQueryString("vanityString", vanityString);
            urlBuilder.addQueryString("startsWith", startsWith);
            urlBuilder.addQueryString("requestType", requestType);
            urlBuilder.addQueryString("endsWith", endsWith);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _buildTollFreeUrl = (urlPath, prefix, requestType, accountId, signupSessionId) => {
        const urlBuilder = _buildUrlBuilder(urlPath, accountId, signupSessionId);
        urlBuilder.addQueryString("prefix", prefix);
        urlBuilder.addQueryString("requestType", requestType);
        return urlBuilder;
    };
    const _searchTollFreeNumbers = ({prefix, requestType, accountId = null, signupSessionId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildTollFreeUrl("findTollFreeNumbers", prefix, requestType, accountId, signupSessionId);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchTollFreeRepeatingDigits = ({prefix, requestType, resultBlockSize=4, accountId = null, signupSessionId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildTollFreeUrl("searchTollFreeRepeatingDigits", prefix, requestType, accountId, signupSessionId);
            urlBuilder.addQueryString("resultBlockSize", resultBlockSize);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _searchTollFreeVanityNumbers = ({prefix, vanityString, startsWith, endsWith, requestType, accountId = null, signupSessionId = null}) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const urlBuilder = _buildTollFreeUrl("searchTollFreeVanity", prefix, requestType, accountId, signupSessionId);
            urlBuilder.addQueryString("vanityString", vanityString);
            urlBuilder.addQueryString("startsWith", startsWith);
            urlBuilder.addQueryString("endsWith", endsWith);
            const url = urlBuilder.buildUrl();

            _clientApi.call(url, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done(deferredObject.resolve);
        });
    };

    const _checkNumberPortability = (numberToPort) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _clientApi.call(`numberProvisioning/checkNumberPortability?numberToPort=${numberToPort}`, "GET", {}, 'application/json')
                .fail(deferredObject.reject)
                .done((result) => {
                    deferredObject.resolve(result.data);
                });
        });
    };

    return function () {
        const self = this;

        self.init = _init;

        self.provisionNumber = _provisionNumber;
        self.provisionNumbers = _provisionNumbers;

        self.getCities = _getCities;
        self.getCountries = _getCountries;
        self.getGeoLocation = _getGeoLocation;
        self.searchByCity = _searchByCity;
        self.searchByRegionCity = _searchByRegionCity;
        self.searchByNpa = _searchByNpa;
        self.searchTollFree = _searchTollFree;
        self.searchByIpAddress =_searchByIpAddress;
        self.getInitial = _getInitial;
        self.getInitialGeolocation = _getInitialGeolocation;
        self.searchLocalNumbers = _searchLocalNumbers;
        self.searchLocalRepeatingNumbers = _searchLocalRepeatingNumbers;
        self.searchLocalVanityNumbers = _searchLocalVanityNumbers;
        self.searchTollFreeNumbers = _searchTollFreeNumbers;
        self.searchTollFreeRepeatingDigits = _searchTollFreeRepeatingDigits;
        self.searchTollFreeVanityNumbers = _searchTollFreeVanityNumbers;
        self.checkNumberPortability = _checkNumberPortability;
    };
});

