define('presentation/common/timeframe/viewModels/timeframeViewModel',[
    'moment-timezone'
], function() {
    return function () {
        const self = this;

        const _i18n = require('i18next');
        const _moment = require('moment-timezone');

        let _settings = null;
        let _disposables = [];

        self.selectedOption = ko.observable(null);
        self.startOn = ko.observable();
        self.endOn = ko.observable();
        self.isAnalytics = ko.observable(false);
        self.placeholderMessage = ko.computed(() => self.isAnalytics() ? '' : _i18n.t('timeframe:all'));

        const _updateStartEndMoments = () => {
            if (self.isAnalytics()) {
                return;
            }

            let startMoment = _moment.utc();
            let endMoment = startMoment.clone();
            switch (self.selectedOption()) {
                case 'today':
                    startMoment.startOf('day');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'this_week':
                    startMoment.startOf('week');
                    endMoment.startOf('week').add(1, 'weeks');
                    break;
                case 'this_month':
                    startMoment.startOf('month');
                    endMoment.startOf('month').add(1, 'months');
                    break;
                case 'this_quarter':
                    startMoment.startOf('quarter');
                    endMoment.startOf('quarter').add(1, 'quarter');
                    break;
                case 'this_year':
                    startMoment.startOf('year');
                    endMoment.startOf('year').add(1, 'years');
                    break;
                case 'yesterday':
                    startMoment.startOf('day').subtract(1, 'days');
                    endMoment.startOf('day');
                    break;
                case 'last_week':
                    startMoment.startOf('week').subtract(7, 'days');
                    endMoment.startOf('week');
                    break;
                case 'last_month':
                    startMoment.startOf('month').subtract(1, 'months');
                    endMoment.startOf('month');
                    break;
                case 'last_quarter':
                    startMoment.startOf('quarter').subtract(1, 'quarters');
                    endMoment.startOf('quarter');
                    break;
                case 'last_year':
                    startMoment.startOf('year').subtract(1, 'years');
                    endMoment.startOf('year');
                    break;
                case 'month_to_date':
                    startMoment.startOf('month');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'quarter_to_date':
                    startMoment.startOf('quarter');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'year_to_date':
                    startMoment.startOf('year');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_7':
                    startMoment.startOf('day').subtract(6, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_14':
                    startMoment.startOf('day').subtract(13, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_30':
                    startMoment.startOf('day').subtract(29, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_60':
                    startMoment.startOf('day').subtract(59, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_90':
                    startMoment.startOf('day').subtract(89, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_months_12':
                    startMoment.startOf('day').subtract(12, 'months');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case '':
                    self.startOn("");
                    self.endOn("");
                    return;
                case 'custom':
                    return;
            }

            self.startOn(startMoment.format("MM/DD/YYYY"));
            self.endOn(endMoment.format("MM/DD/YYYY"));
        };

        self.showDateRangeSelector = ko.pureComputed(() => self.selectedOption() === 'custom');
        self.showClearButton = ko.pureComputed(() => {
            return self.isAnalytics() === false &&
                self.selectedOption() !== '';
        });

        self.timeframeOptions = [
            {text: _i18n.t('timeframe:today'), id: 'today'},
            {text: _i18n.t('timeframe:thisWeek'), id: 'this_week'},
            {text: _i18n.t('timeframe:thisMonth'), id: 'this_month'},
            {text: _i18n.t('timeframe:thisQuarter'), id: 'this_quarter'},
            {text: _i18n.t('timeframe:thisYear'), id: 'this_year'},
            {text: _i18n.t('timeframe:yesterday'), id: 'yesterday'},
            {text: _i18n.t('timeframe:lastWeek'), id: 'last_week'},
            {text: _i18n.t('timeframe:lastMonth'), id: 'last_month'},
            {text: _i18n.t('timeframe:lastQuarter'), id: 'last_quarter'},
            {text: _i18n.t('timeframe:lastYear'), id: 'last_year'},
            {text: _i18n.t('timeframe:monthToDate'), id: 'month_to_date'},
            {text: _i18n.t('timeframe:quarterToDate'), id: 'quarter_to_date'},
            {text: _i18n.t('timeframe:yearToDate'), id: 'year_to_date'},
            {text: _i18n.t('timeframe:lastDays7'), id: 'last_days_7'},
            {text: _i18n.t('timeframe:lastDays14'), id: 'last_days_14'},
            {text: _i18n.t('timeframe:lastDays30'), id: 'last_days_30'},
            {text: _i18n.t('timeframe:lastDays60'), id: 'last_days_60'},
            {text: _i18n.t('timeframe:lastDays90'), id: 'last_days_90'},
            {text: _i18n.t('timeframe:lastMonths12'), id: 'last_months_12'},
            {text: _i18n.t('timeframe:custom'), id: 'custom'},
        ];

        self.clearSelectedOption = () => {
            self.selectedOption('');
        };

        self.detached = () => {
            _disposables.forEach((disposable) => {
                disposable.dispose();
            });

            _disposables = [];
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize(settings);
        };

        const _initialize = () => {
            self.selectedOption = _settings.timeframeOption;

            if (ko.isObservable(_settings.startOn) && ko.isObservable(_settings.endOn) === false) {
                throw new Error('date parameters must be observable');
            }

            self.startOn = _settings.startOn;
            self.endOn = _settings.endOn;

            if (_settings.isAnalytics) {
                self.isAnalytics(ko.unwrap(_settings.isAnalytics));
            }

            _disposables.push(self.selectedOption.subscribe(_updateStartEndMoments));
        };
    };
});
