define('businessServices/contacts/contactsStateSingleton',[
    'businessServices/contacts/enumToLabelConverter',
    'common/collections/collectionSorter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'common/time/datetimeFormatter',
    'constants/accountContactAvatarStatus',
    'constants/contactAddressLabelEnumerations',
    'constants/contactEmailAddressLabelEnumerations',
    'constants/contactPhoneNumberLabelEnumerations',
    'constants/contactSortByOptions',
    'constants/contactFilterByOptions',
    'constants/selectControlAvatarType',
    'constants/userGroupConstants',
    'i18next',
    'persistence/repositories/contactsRepository'
], function (
    /** @type typeof import('businessServices/contacts/enumToLabelConverter') */
    EnumToLabelConverter,
    /** @type typeof import('common/collections/collectionSorter') */
    SorterConstructor,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/storage/commonState') */
    CommonState,
    /** @type typeof import('common/time/datetimeFormatter') */
    DateTimeFormatter,
    /** @type typeof import('constants/accountContactAvatarStatus')*/
    AccountContactAvatarStatus,
    /** @type typeof import('constants/contactAddressLabelEnumerations') */
    ContactAddressLabel,
    /** @type typeof import('constants/contactEmailAddressLabelEnumerations') */
    ContactEmailAddressLabel,
    /** @type typeof import('constants/contactPhoneNumberLabelEnumerations') */
    ContactPhoneNumberLabel,
    /** @type typeof import('constants/contactSortByOptions')*/
    ContactSortByOptions,
    /** @type typeof import('constants/contactFilterByOptions')*/
    ContactFilterByOptions,
    /** @type typeof import('constants/selectControlAvatarType') */
    SelectControlAvatarType,
    /** @type typeof import('constants/userGroupConstants') */
    UserGroupConstants,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('persistence/repositories/contactsRepository') */
    ContactsRepository
) {
    /** @typedef { import('businessServices/contacts/contactsStateSingleton') } ContactsStateSingleton */

    const ContactsStateSingleton = function () {

        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        const _dateTimeFormatter = new DateTimeFormatter();
        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _enumToLabelConverter = new EnumToLabelConverter();
        const _sorter = new SorterConstructor();

        const _promiseFactory = new PromiseFactory();
        const _commonState = CommonState;

        /** @type { Map<string, IContactPresentationObject> } */
        const _contactsById = new Map();
        /** @type { Map<string, Array<string>> } */
        const _contactsByPhoneNumber = new Map();
        /** @type { KnockoutObservable<string | null> } */
        const _selectedContactId = ko.observable(null);
        /** @type { KnockoutObservableArray<ISidebarContactPresentationObject> } */
        const _sidebarContacts = ko.observableArray([]);
        /** @type { Map<string, IContactPresentationObject> } */
        const _contactGroups = new Map();
        /** @type { Map<string, IContactPresentationObject> } */
        const _contactUsers = new Map();

        /** @type { Map<string, number> } */
        const _contactsPositionsById = new Map();
        /** @type { number } */
        let _contactsSidebarPosition = 0;
        /** @type { Map<string, number> } */
        const _contactsDetailPositionsById = new Map();
        /** @type { Map<string, boolean> } */
        const _contactsDetailOpenStateById = new Map();

        /** @type { KnockoutObservableArray<IContactPermissionsPresentationObject> } */
        const _contactPermissionsUsersAndUserGroups = ko.observableArray([]);

        /** @type { KnockoutObservableArray<string> } */
        const _contactPermissionsUserIds = ko.observableArray([]);

        const _sortBy = ko.observable(ContactSortByOptions.FirstName);
        const _filterBy = ko.observableArray([ContactFilterByOptions.companyAddressBook, ContactFilterByOptions.yourAddressBook, ContactFilterByOptions.trestaUserGroups]);

        /** @type { IContactsRepository } */
        let _contactsRepository = null;
        _contactsRepository = new ContactsRepository();

        /** @type { IDisposable[] } */
        let _disposables = [];

        this.isLoaded = false;

        // use pureComputed so these values cannot be accidentally overwritten without calling the setters
        this.selectedContactId = ko.pureComputed(() => _selectedContactId());

        this.activeUsers = ko.pureComputed(() => {
            return _commonState.users().filter((u) => u.status() === 'active');
        }).extend({ trackArrayChanges: true });

        this.activeUserGroups = ko.pureComputed(() => {
            return _commonState.userGroups().filter((g) => g.status() === 'active');
        }).extend({ trackArrayChanges: true });

        const _onUserGroupsChanged = (/** @type {any[]} */ groupsChanged) => {
            if (_sidebarContacts().length > 0) {
                ko.utils.arrayForEach(groupsChanged, (group) => {
                    switch (group.status)
                    {
                        case "added":
                            if (_sidebarContacts().some((c) => c.accountUserGroupId === group.value.id)) {
                                break;
                            }
                            const groupPresentationObjects = _buildContactPresentationObjectsFromSystemObjects([group.value]);
                            _upsertGroupsToMap(groupPresentationObjects);
                            const contactSidebarPresentationObject = _buildContactsSidebarPresentationObject(groupPresentationObjects[0]);
                            _sidebarContacts.push(contactSidebarPresentationObject);
                            break;
                        case "deleted":
                            _sidebarContacts(_sidebarContacts().filter((c) => c.accountUserGroupId !== group.value.id));
                            break;
                    }
                });
            }
        };

        const _onUsersChanged = (/** @type {any[]} */ usersChanged) => {
            if (_sidebarContacts().length > 0) {
                ko.utils.arrayForEach(usersChanged, (user) => {
                    switch (user.status)
                    {
                        case "added":
                            if (_sidebarContacts().some((c) => c.accountUserId === user.value.id)) {
                                break;
                            }
                            const userPresentationObjects = _buildContactPresentationObjectsFromSystemObjects([user.value]);
                            _upsertUsersToMap(userPresentationObjects);
                            const contactSidebarPresentationObject = _buildContactsSidebarPresentationObject(userPresentationObjects[0]);
                            _sidebarContacts.push(contactSidebarPresentationObject);
                            break;
                        case "deleted":
                            _sidebarContacts(_sidebarContacts().filter((c) => c.accountUserId !== user.value.id));
                            break;
                    }
                });
            }
        };

        /** @type { ContactsStateSingleton["initialize"] } */
        this.initialize = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const groups = this.activeUserGroups();
                const groupPresentationObjects = _buildContactPresentationObjectsFromSystemObjects(groups);
                _upsertGroupsToMap(groupPresentationObjects);
                const users = this.activeUsers();
                const userPresentationObjects = _buildContactPresentationObjectsFromSystemObjects(users);
                _upsertUsersToMap(userPresentationObjects);

                Promise.all([
                    _contactsRepository.getContacts(),
                    this.initializeContactPermissions()
                ]).then(([getContactsResult, initializeContactPermissionsResult]) => {
                    const contacts = getContactsResult.contacts;
                    if (contacts && contacts.length > 0) {
                        const contactPresentationObjects = _buildContactPresentationObjects(contacts);
                        _upsertContactsToMap(contactPresentationObjects);
                        const allContacts = contactPresentationObjects.concat(groupPresentationObjects, userPresentationObjects);
                        _setSidebarContacts(allContacts);
                        deferredObject.resolve();
                    } else {
                        const allContacts = groupPresentationObjects.concat(userPresentationObjects);
                        _setSidebarContacts(allContacts);
                        deferredObject.resolve();
                    }
                });

                this.activeUserGroups.subscribe(_onUserGroupsChanged, null, "arrayChange");
                this.activeUsers.subscribe(_onUsersChanged, null, "arrayChange");
            });
        };

        /** @type { ContactsStateSingleton["setSelectedContactId"] } */
        this.setSelectedContactId = (/** @type string */ contactId) => {
            _clearSelectedContact();
            _selectedContactId(contactId);
        };

        /** @type { ContactsStateSingleton["setSidebarContactsSort"] } */
        this.setSidebarContactsSort = (/** @type {number} */ sortBy) => {
            _sortBy(sortBy);
        };

        /** @type { ContactsStateSingleton["setSidebarContactsFilter"] } */
        this.setSidebarContactsFilter = (/** @type Array<string> */ filterBy) => {
            _filterBy(filterBy);
        };

        /** @type { ContactsStateSingleton["getDefaultContactId"] } */
        this.getDefaultContactId = () => {
            return _promiseFactory.defer((deferredObject) => {
                const firstContactId = _sidebarContacts().length > 0 ?
                    _sidebarContacts()[0].accountContactId ||
                    _sidebarContacts()[0].accountUserId ||
                    _sidebarContacts()[0].accountUserGroupId :
                    null;
                deferredObject.resolve(firstContactId);
            });
        };

        /** @type { ContactsStateSingleton["getContactById"] } */
        this.getContactById = (/** @type string */ id) => {
            return _promiseFactory.defer((deferredObject) => {
                if (_contactUsers.has(id)) {
                    const user = _contactUsers.get(id);

                    _contactsRepository.getContactHostedPhoneNumbers(id, _commonState.types.user)
                        .then(({/** @type { IAssociatedHostedPhoneNumber[] } */ hostedPhoneNumbers }) => {
                            if (hostedPhoneNumbers && hostedPhoneNumbers.length) {
                                const hostedNumbers = hostedPhoneNumbers.map(_buildUserOrGroupContactPhoneNumberPresentationObjectFromHostedNumber);

                                /** @type { IContactPhoneNumberPresentationObject[] } */
                                const systemNumbers = [];
                                hostedNumbers.forEach(hn => {
                                    if (!user.phoneNumbers().some(n => n.hostedNumberId === hn.hostedNumberId)) {
                                        systemNumbers.push(hn);
                                    }
                                });

                                const allNumbers = user.phoneNumbers().concat(systemNumbers);
                                user.phoneNumbers(allNumbers);
                                deferredObject.resolve(user);
                            } else {
                                deferredObject.resolve(user);
                            }
                        });
                    return;
                }
                if (_contactGroups.has(id)) {
                    const group = _contactGroups.get(id);

                    _contactsRepository.getContactHostedPhoneNumbers(id, _commonState.types.userGroup)
                        .then(({/** @type { IAssociatedHostedPhoneNumber[] } */ hostedPhoneNumbers }) => {
                            if (hostedPhoneNumbers && hostedPhoneNumbers.length) {
                                const hostedNumbers = hostedPhoneNumbers.map(_buildUserOrGroupContactPhoneNumberPresentationObjectFromHostedNumber);

                                /** @type { IContactPhoneNumberPresentationObject[] } */
                                const systemNumbers = [];
                                hostedNumbers.forEach(hn => {
                                    if (!group.phoneNumbers().some(n => n.hostedNumberId === hn.hostedNumberId)) {
                                        systemNumbers.push(hn);
                                    }
                                });

                                const allNumbers = group.phoneNumbers().concat(systemNumbers);
                                group.phoneNumbers(allNumbers);
                                deferredObject.resolve(group);
                            } else {
                                deferredObject.resolve(group);
                            }
                        });
                    return;
                }
                if (_contactsById.has(id)) {
                    deferredObject.resolve(_contactsById.get(id));
                    return;
                }

                _contactsRepository.getContactById(id)
                    .then((/** @type { IContact } */ contact) => {
                        if (contact) {
                            const contactPresentationObject = _buildContactPresentationObject(contact);
                            _upsertContactsToMap([contactPresentationObject]);
                            deferredObject.resolve(contactPresentationObject);
                        } else {
                            deferredObject.resolve(null);
                        }
                    });
            });
        };

        /** @type { ContactsStateSingleton["getContactByPhoneNumber"] } */
        this.getContactByPhoneNumber = (/** @type string */ phoneNumber) => {
            const phoneNumberKey = _phoneNumberFormatter.toNumbers(phoneNumber);
            const contactIds = _contactsByPhoneNumber.get(phoneNumberKey);
            if (!contactIds) {
                return null;
            }

            const contact = _contactsById.get(contactIds[0]);
            if (!contact) {
                return null;
            }

            return contact;
        };

        /** @type { ContactsStateSingleton["getScrollPositionById"] } */
        this.getScrollPositionById = (/** @type string */ accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                if (_contactsPositionsById.has(accountContactId)) {
                    deferredObject.resolve(_contactsPositionsById.get(accountContactId));
                }
                else {
                    deferredObject.resolve(0);
                }
            });
        };

        /** @type {ContactsStateSingleton["getDetailScrollPositionById"]} */
        this.getDetailScrollPositionById = (/** @type string */ accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                if (_contactsDetailPositionsById.has(accountContactId)) {
                    deferredObject.resolve(_contactsDetailPositionsById.get(accountContactId));
                }
                else {
                    deferredObject.resolve(0);
                }
            });
        };

        /** @type { ContactsStateSingleton["setScrollPositionById"] } */
        this.setScrollPositionById = (/** @type string */ accountContactId, /** @type {number} */ position) => {
            _contactsPositionsById.set(accountContactId, position);
        };

        /** @type { ContactsStateSingleton["setDetailScrollPositionById"] } */
        this.setDetailScrollPositionById = (/** @type string */ accountContactId, /** @type {number} */ position) => {
            _contactsDetailPositionsById.set(accountContactId, position);
        };

        /** @type { ContactsStateSingleton["getSidebarScrollPosition"] } */
        this.getSidebarScrollPosition = () => {
            return _promiseFactory.defer((deferredObject) => {
                deferredObject.resolve(_contactsSidebarPosition);
            });
        };

        /** @type {ContactsStateSingleton["setSidebarScrollPosition"]} */
        this.setSidebarScrollPosition = (/** @type {number} */ position) => {
            _contactsSidebarPosition = position;
        };

        /** @type { ContactsStateSingleton["setOpenStateById"] } */
        this.setOpenStateById = (/** @type string */ accountContactId, /** @type {boolean} */ isOpen) => {
            _contactsDetailOpenStateById.set(accountContactId, isOpen);
        };

        /** @type { ContactsStateSingleton["getOpenStateById"] } */
        this.getOpenStateById = (/** @type string */ accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                if (_contactsDetailOpenStateById.has(accountContactId)) {
                    deferredObject.resolve(_contactsDetailOpenStateById.get(accountContactId));
                }
                else {
                    deferredObject.resolve(false);
                }
            });
        };

        /** @type { ContactsStateSingleton["getDuplicateContacts"] } */
        this.getDuplicateContacts = (/** @type { Array<string> } */ phoneNumbers, /** @type { Array<string> } */ emailAddresses) => {
            const duplicateContacts = new Set();
            _contactsById.forEach((contact) => {

                const isPhoneNumberMatch = contact.phoneNumbers()
                    .some((p) => {
                        const phoneNumber = _phoneNumberFormatter.removeNonNumericalAndLeadingOne(p.phoneNumber());
                        return phoneNumbers.includes((phoneNumber));
                    });

                const isEmailAddressMatch = contact.emailAddresses()
                    .some((e) => {
                        const emailAddress = e.emailAddress().trim().toLowerCase();
                        return emailAddresses.includes(emailAddress);
                    });

                if (isPhoneNumberMatch || isEmailAddressMatch) {
                    duplicateContacts.add(contact);
                }
            });

            return duplicateContacts;
        };

        this.createContact = (/** @type { ICreateContactRequest } */ createRequest) => {
            return _contactsRepository.createContact(createRequest);
        };

        this.updateContact = (/** @type { IUpdateContactRequest } */ updateRequest) => {
            return _contactsRepository.updateContact(updateRequest);
        };

        this.deleteContact = (/** @type { IDeleteContactRequest } */ deleteRequest) => {
            return _contactsRepository.deleteContact(deleteRequest);
        };

        this.allUsersAndUserGroups = () => {
            const userPresentationObjects = this.activeUsers().map(_commonStateUserToContactPermissionsPresentationObject);
            const userGroupPresentationObjects = this.activeUserGroups().map(_commonStateUserGroupToPresentationObject);

            return [...userPresentationObjects, ...userGroupPresentationObjects];

        };

        this.initializeContactPermissions = () => {
            return _promiseFactory.defer((deferredObject) => {
                _contactsRepository.getContactPermissions()
                    .fail(deferredObject.reject)
                    .done(((/** @type { IGetContactPermissionsResponse } */ response) => {
                        _setContactPermissionsUserIds(response.userIdsWithContactPermissions, response.userGroupIdsWithContactPermissions);
                        const initialPermissions = _idsToPresentationObjects(response.userIdsWithContactPermissions, response.userGroupIdsWithContactPermissions);
                        _contactPermissionsUsersAndUserGroups(initialPermissions);
                        deferredObject.resolve();
                    }));

            });
        };

        this.updateContactPermissions = (/** @type { IUpdateContactPermissionsRequest } */ updateRequest) => {
            return _promiseFactory.defer((deferredObject) => {
                _contactsRepository.updateContactPermissions(updateRequest)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type { (repository: IContactsRepository) => void } */
        this.setRepository = (repository) => {
            // TODO: implement repository injection
            if (_contactsRepository !== null) {
                return;
            }

            _contactsRepository = repository;

            // register event listeners
            _contactsRepository.onContactCreated(_onContactCreated);
            _contactsRepository.onContactUpdated(_onContactUpdated);
            _contactsRepository.onContactDeleted(_onContactDeleted);

            _disposables.push(_contactsRepository);
        };

        const _onLogin = () => {
            return this.initialize();
        };

        const _onLogout = () => {
            this.isLoaded = false;

            _clearSelectedContact();
            _contactsById.clear();
            _contactsPositionsById.clear();
            _contactsSidebarPosition = 0;
            _contactsDetailPositionsById.clear();
            _contactsByPhoneNumber.clear();
            _contactUsers.clear();
            _contactGroups.clear();
            _sidebarContacts([]);
            _contactPermissionsUserIds([]);
            _contactPermissionsUsersAndUserGroups([]);
            _contactsDetailOpenStateById.clear();

            _disposables.forEach((disposable) => disposable.dispose());
        };

        /** @type { IAuthenticationListeningModule["alerts"] } */
        this.alerts = {
            receiveAlertLoginSuccessful: _onLogin,
            receiveAlertLogout: _onLogout
        };

        const _clearSelectedContact = () => {
            _selectedContactId(null);
        };

        //#region event handlers

        /** @type { (event: IContactCreatedEvent) => void } */
        const _onContactCreated = (event) => {
            _contactsRepository.getContactById(event.accountContactId)
                .then((/** @type { IContact } */ contact) => {
                    const contactPresentationObject = _buildContactPresentationObject(contact);
                    _upsertContactsToMap([contactPresentationObject]);

                    const contactSidebarPresentationObject = _buildContactsSidebarPresentationObject(contactPresentationObject);
                    _sidebarContacts.push(contactSidebarPresentationObject);
                });
        };

        /** @type { (event: IContactUpdatedEvent) => void } */
        const _onContactUpdated = (event) => {
            _contactsRepository.getContactById(event.accountContactId)
                .then((/** @type { IContact } */ contact) => {
                    _updateSidebarContact(contact);
                    _updateContactToMap(contact);
                });
        };

        /** @type { (event: IContactDeletedEvent) => void } */
        const _onContactDeleted = (event) => {
            _deleteContactFromMap(event.accountContactId);
            _sidebarContacts.remove((contact) => {
                return contact.accountContactId === event.accountContactId;
            });
        };

        /** @type { (event: IContactAvatarUpdatedEvent) => void } */
        const _onContactAvatarUpdated = (event) => {
            _contactsRepository.getContactById(event.contactId)
                .then((/** @type { IContact } */ contact) => {
                    _updateSidebarContact(contact);
                    _updateContactToMap(contact);
                });
        };

        //#endregion


        //#contact permissions event handlers

        /** @type { (event: IContactPermissionAddedEvent) => void } */
        const _contactPermissionAdded = (event) => {
            const commonStateItem = _commonState.get(event.userOrGroupId);
            if (!commonStateItem) {
                return;
            }

            const userIdsWithPermission = new Set(_contactPermissionsUserIds());
            const contactPermissionPresentationObject = _commonStateItemToPermissionPresentationObject(commonStateItem);

            if (commonStateItem.isUser) {
                userIdsWithPermission.add(event.userOrGroupId);

                const permissionExists = _contactPermissionsUsersAndUserGroups().find((permission) => permission.userId === commonStateItem.id);
                if (!permissionExists) {
                    _contactPermissionsUsersAndUserGroups.push(contactPermissionPresentationObject);
                }
            }

            if (commonStateItem.isUserGroup) {
                const members = commonStateItem.members() || [];
                members.forEach((memberId) =>  userIdsWithPermission.add(memberId));

                const permissionExists = _contactPermissionsUsersAndUserGroups().find((permission) => permission.userGroupId === commonStateItem.id);
                if (!permissionExists) {
                    _contactPermissionsUsersAndUserGroups.push(contactPermissionPresentationObject);
                }
            }

            _contactPermissionsUserIds(Array.from(userIdsWithPermission));
        };

        /** @type { (event: IContactPermissionRemovedEvent) => void } */
        const _contactPermissionRemoved = (event) => {
            const commonStateItem = _commonState.get(event.userOrGroupId);
            if (!commonStateItem) {
                return;
            }

            const userIdsWithPermission = new Set(_contactPermissionsUserIds());

            if (commonStateItem.isUser) {
                const groupMemberships = _commonState.userGroups()
                    .filter((group) => {
                        return group.members().includes(event.userOrGroupId);
                    })
                    .map((g) => g.id);
                const groupsWithPermission = _contactPermissionsUsersAndUserGroups().filter((permission) => groupMemberships.includes(permission.userGroupId));
                if (groupsWithPermission.length === 0) {
                    userIdsWithPermission.delete(event.userOrGroupId);
                }

                const updatedPermissions = _contactPermissionsUsersAndUserGroups().filter((permission) => permission.userId !== commonStateItem.id);
                _contactPermissionsUsersAndUserGroups(updatedPermissions);
            }

            if (commonStateItem.isUserGroup) {
                const members = commonStateItem.members() || [];
                members.forEach((memberId) =>  {
                    const memberUserHasPermission = _contactPermissionsUsersAndUserGroups().find((permission) => permission.userId === memberId);
                    if (!memberUserHasPermission) {
                        userIdsWithPermission.delete(memberId);
                    }
                });

                const updatedPermissions = _contactPermissionsUsersAndUserGroups().filter((permission) => permission.userGroupId !== commonStateItem.id);
                _contactPermissionsUsersAndUserGroups(updatedPermissions);
            }

            _contactPermissionsUserIds(Array.from(userIdsWithPermission));
        };

        //#endregion

        //#region contacts by id

        const _deleteContactFromMap = (/** @type string */ contactId) => {
            _removeContactFromAnyPhoneNumbers(contactId);
            _contactsById.delete(contactId);
        };

        const _updateContactToMap = (/** @type { IContact } */ contact) => {
            const currentContact = _contactsById.get(contact.accountContactId);
            const updatedContact = _buildContactPresentationObject(contact);
            if (!currentContact) {
                _upsertContactsToMap([updatedContact]);
                return;
            }

            _updateContactsByPhoneNumber(currentContact, updatedContact);
            _updateContactsById(currentContact, updatedContact);
        };

        const _updateContactsById = (/** @type { IContactPresentationObject } */ currentContact, /** @type {IContactPresentationObject} */ updatedContact) => {
            currentContact.addresses(updatedContact.addresses());
            currentContact.avatarUrl(updatedContact.avatarUrl());
            currentContact.birthday(updatedContact.birthday());
            currentContact.company(updatedContact.company());
            currentContact.contactSource(updatedContact.contactSource());
            currentContact.displayName(updatedContact.displayName());
            currentContact.emailAddresses(updatedContact.emailAddresses());
            currentContact.firstName(updatedContact.firstName());
            currentContact.hasAvatar(updatedContact.hasAvatar());
            currentContact.jobTitle(updatedContact.jobTitle());
            currentContact.lastName(updatedContact.lastName());
            currentContact.modifiedDateTime(updatedContact.modifiedDateTime());
            currentContact.notes(updatedContact.notes());
            currentContact.phoneNumbers(updatedContact.phoneNumbers());
            currentContact.privateContactForUserId = updatedContact.privateContactForUserId;
            currentContact.urls(updatedContact.urls());
        };

        const _upsertContactsToMap = (/** @type { Array<IContactPresentationObject> } */ contacts) => {
            contacts.map((contact) => {
                _contactsById.set(contact.accountContactId, contact);
                contact.phoneNumbers().map((number) => {
                    const phoneNumberKey = _phoneNumberFormatter.toNumbers(number.phoneNumber());
                    _addContactToPhoneNumber(phoneNumberKey, contact.accountContactId);
                });
            });
        };

        //#endregion

        //#region contacts by phone number

        const _addContactToPhoneNumber = (/** @type string */ number, /** @type string */ contactId) => {
            const contactIds = _contactsByPhoneNumber.get(number) || [];
            contactIds.push(contactId);
            _contactsByPhoneNumber.set(number, contactIds);
        };

        const _removeContactFromAnyPhoneNumbers = (/** @type string */ contactId) => {
            const contact = _contactsById.get(contactId);
            if (!contact) {
                return;
            }

            const phoneNumbersKeys = contact.phoneNumbers().map((number) => {
                return _phoneNumberFormatter.toNumbers(number.phoneNumber());
            });

            phoneNumbersKeys.map((number) => {
                _removeContactFromPhoneNumber(number, contactId);
            });
        };

        const _removeContactFromPhoneNumber = (/** @type string */ number, /** @type string */ contactId) => {
            const contactIds = _contactsByPhoneNumber.get(number);
            if (!contactIds) {
                return;
            }

            const updatedPhoneNumberContactIds = contactIds.filter((id) => {
                return id !== contactId;
            });

            if (updatedPhoneNumberContactIds.length === 0) {
                _contactsByPhoneNumber.delete(number);
                return;
            }

            _contactsByPhoneNumber.set(number, updatedPhoneNumberContactIds);
        };

        const _updateContactsByPhoneNumber = (/** @type { IContactPresentationObject } */ currentContact, /** @type {IContactPresentationObject} */ updatedContact) => {
            const currentContactNumbers = currentContact.phoneNumbers().map((number) => {
                return _phoneNumberFormatter.toNumbers(number.phoneNumber());
            });
            const updatedContactNumbers = updatedContact.phoneNumbers().map((number) => {
                return _phoneNumberFormatter.toNumbers(number.phoneNumber());
            });

            const numbersToDelete = currentContactNumbers.filter((number) => {
                return !updatedContactNumbers.includes(number);
            });
            numbersToDelete.map((numberToDelete) => {
                _removeContactFromPhoneNumber(numberToDelete, currentContact.accountContactId);
            });

            const numbersToAdd = updatedContactNumbers.filter((number) => {
                return !currentContactNumbers.includes(number);
            });
            numbersToAdd.map((numberToDelete) => {
                _addContactToPhoneNumber(numberToDelete, currentContact.accountContactId);
            });
        };

        //#endregion

        //#region sidebar contacts

        const _setSidebarContacts = (/** @type { Array<IContactPresentationObject> } */ contacts) => {
            const presentationObjects = _buildContactsSidebarPresentationObjects(contacts);
            const updatedSidebarContacts = _sidebarContacts().concat(presentationObjects);
            const sortedContacts = _sortSidebarContacts(updatedSidebarContacts, true);
            _sidebarContacts(sortedContacts);
        };

        const _sortSidebarContacts = (/** @type { Array<ISidebarContactPresentationObject> } */contacts, /** @type { boolean } */isFirstName) => {
            const sortValue = isFirstName ? "displayNameFirst" : "displayNameLast";
            const alphaOnlyRegex = /^[a-z]/i;
            const nonAlphaContacts = contacts.filter((c) => !alphaOnlyRegex.test(c.displayNameFirst().trim()));
            _sorter.sort(nonAlphaContacts, sortValue, true);
            const namedContacts = contacts.filter((c) => !nonAlphaContacts.includes(c));
            _sorter.sort(namedContacts, sortValue, true);
            return namedContacts.concat(nonAlphaContacts);
        };

        const _updateSidebarContact = (/** @type { IContact } */ contact) => {
            const updatedContact = _buildContactPresentationObject(contact);
            const contactSidebarPresentationObject = _buildContactsSidebarPresentationObject(updatedContact);
            const sidebarContact = _sidebarContacts().find((s) => {
                return s.accountContactId === contact.accountContactId;
            });

            if (sidebarContact) {
                _sidebarContacts.replace(sidebarContact, contactSidebarPresentationObject);
            } else {
                _sidebarContacts.push(contactSidebarPresentationObject);
            }
        };

        //#endregion

        //#region user and group contacts

        const _upsertGroupsToMap = (/** @type { Array<IContactPresentationObject> } */ groups) => {
            groups.map((group) => {
                _contactGroups.set(group.accountUserGroupId, group);
            });
        };

        const _upsertUsersToMap = (/** @type { Array<IContactPresentationObject> } */ users) => {
            users.map((user) => {
                _contactUsers.set(user.accountUserId, user);
            });
        };

        //#endregion

        //#region contact presentation object builders

        const _buildContactPresentationObjects = (/** @type { Array<IContact> } */ contacts) => {
            /** @type { Array<IContactPresentationObject> } */
            const contactPresentationObjects = contacts.map((contact) => {
                return _buildContactPresentationObject(contact);
            });

            return contactPresentationObjects;
        };

        const _buildContactPresentationObject = (/** @type { IContact } */ contact) => {
            const displayName = _buildDisplayName(contact);
            const nonEmptyAddresses = contact.addresses.filter((a) => {
                const { addressLineOne, addressLineTwo, city, regionName, postalCode } = a;
                return addressLineOne !== '' || addressLineTwo !== '' || city !== '' || regionName !== '' || postalCode !== '';
            });

            /** @type { IContactPresentationObject } */
            const contactPresentationObject = {
                accountContactId: contact.accountContactId,
                accountUserId: '',
                accountUserGroupId: '',
                accountId: contact.accountId,
                addresses: ko.observableArray(nonEmptyAddresses.map(_buildContactAddressPresentationObject)),
                avatarUrl: ko.observable(contact.avatarUrl || null),
                birthday: ko.observable(contact.birthday),
                company: ko.observable(contact.company),
                contactHash: ko.observable(contact.contactHash),
                contactSource: ko.observable(_buildContactSource(contact)),
                createdDateTime: ko.observable(_dateTimeFormatter.customDateTimeTextFromIso(contact.createdDateTime, "MMM Do, YYYY h:mma")),
                displayName: ko.observable(displayName),
                emailAddresses: ko.observableArray(contact.emailAddresses.map(_buildContactEmailAddressPresentationObject)),
                firstName: ko.observable(contact.firstName),
                hasAvatar: ko.observable(contact.hasAvatar),
                initials: ko.observable(_getInitialsFromDisplayName(displayName)),
                isDeleted: ko.observable(contact.isDeleted),
                isUser: false,
                isUserGroup: false,
                jobTitle: ko.observable(contact.jobTitle),
                lastName: ko.observable(contact.lastName),
                name: ko.observable(displayName),
                modifiedDateTime: ko.observable(_dateTimeFormatter.customDateTimeTextFromIso(contact.modifiedDateTime, "MMM Do, YYYY h:mma")),
                notes: ko.observable(contact.notes),
                phoneNumbers: ko.observableArray(contact.phoneNumbers.map(_buildContactPhoneNumberPresentationObject)),
                privateContactForUserId: contact.privateContactForUserId,
                urls: ko.observableArray(contact.urls.map(_buildContactUrlPresentationObject))
            };

            return contactPresentationObject;
        };

        const _buildContactAddressPresentationObject = (/** @type { IContactAddress } */ address) => {
            /** @type { IContactAddressPresentationObject } */
            const contactAddressPresentationObject = {
                accountContactAddressId: address.accountContactAddressId,
                addressLineOne: ko.observable(address.addressLineOne),
                addressLineTwo: ko.observable(address.addressLineTwo),
                addressLineThree: ko.observable(address.city + ", " + address.regionName + " " + address.postalCode),
                city: ko.observable(address.city),
                countryName: ko.observable(address.countryName),
                label: ko.observable(_enumToLabelConverter.getAddressLabel(address.label)),
                otherAddressInfo: ko.observable(address.otherAddressInfo),
                postalCode: ko.observable(address.postalCode),
                regionName: ko.observable(address.regionName)
            };

            return contactAddressPresentationObject;
        };

        const _buildContactEmailAddressPresentationObject = (/** @type { IContactEmailAddress } */ emailAddress) => {
            /** @type { IContactEmailAddressPresentationObject } */
            const contactEmailAddressPresentationObject = {
                accountContactEmailAddressId: emailAddress.accountContactEmailAddressId,
                label: ko.observable(_enumToLabelConverter.getEmailLabel(emailAddress.label)),
                emailAddress: ko.observable(emailAddress.emailAddress)
            };

            return contactEmailAddressPresentationObject;
        };

        const _buildContactPhoneNumberPresentationObject = (/** @type { IContactPhoneNumber } */ phoneNumber) => {
            /** @type { IContactPhoneNumberPresentationObject } */
            const contactPhoneNumberPresentationObject = {
                accountContactPhoneNumberId: phoneNumber.accountContactPhoneNumberId,
                label: ko.observable(_enumToLabelConverter.getPhoneLabel(phoneNumber.label)),
                phoneNumber: ko.observable(_formatPhoneNumberForDisplay(phoneNumber)),
                rawPhoneNumber: ko.observable(phoneNumber.rawPhoneNumber),
                showPopUpMenu: ko.observable(false)
            };

            return contactPhoneNumberPresentationObject;
        };

        const _buildContactUrlPresentationObject = (/** @type { IContactUrl } */ url) => {
            /** @type { IContactUrlPresentationObject } */
            const contactUrlPresentationObject = {
                accountContactUrlId: url.accountContactUrlId,
                url: ko.observable(url.url)
            };

            return contactUrlPresentationObject;
        };

        const _formatPhoneNumberForDisplay = (/** @type { IContactPhoneNumber }*/ phoneNumber) => {
            const digitsOnly = _phoneNumberFormatter.toNumbers(phoneNumber.phoneNumber);
            const displayPhoneNumber = digitsOnly ? _phoneNumberFormatter.toNumericDefault(digitsOnly) : phoneNumber.rawPhoneNumber;
            return displayPhoneNumber;
        };

        const _buildDisplayName = (/** @type { IContact }*/ contact) => {
            if (contact.firstName && contact.lastName) {
                return contact.firstName.trim() + ' ' + contact.lastName.trim();
            }

            if (contact.firstName) {
                return contact.firstName.trim();
            }

            if (contact.lastName) {
                return contact.lastName.trim();
            }

            if (contact.company) {
                return contact.company.trim();
            }

            if (contact.emailAddresses && contact.emailAddresses.length > 0) {
                return contact.emailAddresses[0].emailAddress.trim();
            }

            if (contact.phoneNumbers && contact.phoneNumbers.length > 0) {
                return _formatPhoneNumberForDisplay(contact.phoneNumbers[0]);
            }

            if (contact.addresses && contact.addresses.length > 0) {
                const nonEmptyAddresses = contact.addresses.filter((a) => {
                    const { addressLineOne, addressLineTwo, city, regionName, postalCode } = a;
                    return addressLineOne !== '' || addressLineTwo !== '' || city !== '' || regionName !== '' || postalCode !== '';
                });
                const addressDisplayName = nonEmptyAddresses[0].addressLineOne + ' ' + nonEmptyAddresses[0].addressLineTwo;
                return addressDisplayName.trim();
            }

            return ' ';
        };

        const _buildContactSource = (/** @type { IContact }*/ contact) => {
            if (contact.privateContactForUserId !== null) {
                return ContactFilterByOptions.yourAddressBook;
            } else {
                return ContactFilterByOptions.companyAddressBook;
            }
        };

        //#endregion

        //#region user and group contact presentation object builders

        const _buildContactPresentationObjectsFromSystemObjects = (/** @type { Array<any> } */ items) => {
            /** @type { Array<IContactPresentationObject> } */
            const contactPresentationObjects = items.map((item) => {
                return _buildContactPresentationObjectFromSystemObject(item);
            });

            return contactPresentationObjects;
        };

        const _buildContactPresentationObjectFromSystemObject = (/** @type { any } */ item) => {
            const addresses = _commonState.companyPostalAddress() ?
                [_buildContactAddressFromCompanyAddress(_commonState.companyPostalAddress())] :
                [];
            const hostedNumbers = item.hostedPhoneNumbers() ?
                item.hostedPhoneNumbers().map(_buildContactPhoneNumberPresentationObjectFromHostedNumber) : [];
            const extension = _buildContactPhoneNumberPresentationObjectFromExtension(item.extension);
            const systemNumbers = [extension].concat(hostedNumbers);
            const emailAddresses = [];


            if (item.isUser && item.emailAddress) {
                emailAddresses.push({
                    label: ko.observable(i18n.t('contacts:tresta')),
                    emailAddress: item.emailAddress
                });
            }

            /** @type { IContactPresentationObject } */
            const contactPresentationObject = {
                accountContactId: '',
                accountUserId: item.isUser ? item.id : '',
                accountUserGroupId: item.isUserGroup ? item.id : '',
                accountId: _sessionAccountInfo.accountId(),
                addresses: ko.observableArray(addresses),
                avatar: item.avatar,
                avatars: item.avatars,
                avatarUrl: ko.observable(null),
                birthday: ko.observable(''),
                company: item.title,
                contactHash: ko.observable(null),
                contactSource: ko.observable(ContactFilterByOptions.trestaUserGroups),
                createdDateTime: ko.observable(_dateTimeFormatter.customDateTimeTextFromIso(item.createdDateTime.sortValue, "MMM Do, YYYY h:mma")),
                displayName: item.name,
                emailAddresses: ko.observableArray(emailAddresses),
                firstName: ko.observable(null),
                hasAvatar: ko.observable(null),
                initials: ko.observable(_getInitialsFromDisplayName(item.name())),
                isDeleted: item.isDeleted,
                isUser: item.isUser,
                isUserGroup: item.isUserGroup,
                jobTitle: ko.observable(null),
                lastName: ko.observable(null),
                modifiedDateTime: ko.observable(_dateTimeFormatter.customDateTimeTextFromIso(item.modifiedDateTime().sortValue, "MMM Do, YYYY h:mma")),
                name: item.name,
                notes: ko.observable(''),
                phoneNumbers: ko.observableArray(systemNumbers),
                privateContactForUserId: null,
                urls: ko.observableArray([])
            };

            return contactPresentationObject;
        };

        const _buildContactPhoneNumberPresentationObjectFromExtension = (/** @type { any } */extension) => {
            /** @type { IContactPhoneNumberPresentationObject } */
            return {
                accountContactPhoneNumberId: '',
                label: ko.observable(i18n.t('contacts:extension')),
                phoneNumber: extension,
                rawPhoneNumber: extension,
                showPopUpMenu: ko.observable(false)
            };
        };

        const _buildContactPhoneNumberPresentationObjectFromHostedNumber = (/** @type { any } */hostedNumber) => {
            const commonStateNumber = _commonState.get(hostedNumber.id);
            const isTollFree = _phoneNumberFormatter.isTollFree(hostedNumber.number);
            let phoneLabel = "";

            if (isTollFree) {
                phoneLabel = i18n.t('tollFree');
            } else if (commonStateNumber.hasDefaultName()) {
                phoneLabel = commonStateNumber.location();
            } else {
                phoneLabel = commonStateNumber.name();
            }

            /** @type { IContactPhoneNumberPresentationObject } */
            return {
                accountContactPhoneNumberId: '',
                hostedNumberId: commonStateNumber.id,
                label: phoneLabel,
                phoneNumber: commonStateNumber.phoneNumber,
                rawPhoneNumber: ko.observable(hostedNumber.number),
                showPopUpMenu: ko.observable(false)
            };
        };

        const _buildUserOrGroupContactPhoneNumberPresentationObjectFromHostedNumber = (/** @type { IAssociatedHostedPhoneNumber } */associatedHostedNumber) => {
            const commonStateNumber = _commonState.get(associatedHostedNumber.accountHostedNumberId);
            const rawNumber = _phoneNumberFormatter.toNumbers(associatedHostedNumber.hostedPhoneNumber);
            const isTollFree = _phoneNumberFormatter.isTollFree(rawNumber);
            let phoneLabel = "";

            if (isTollFree) {
                phoneLabel = i18n.t('tollFree');
            } else if (commonStateNumber.hasDefaultName()) {
                phoneLabel = commonStateNumber.location();
            } else {
                phoneLabel = commonStateNumber.name();
            }

            /** @type { IContactPhoneNumberPresentationObject } */
            return {
                accountContactPhoneNumberId: '',
                hostedNumberId: associatedHostedNumber.accountHostedNumberId,
                label: ko.observable(phoneLabel),
                phoneNumber: commonStateNumber.phoneNumber,
                rawPhoneNumber: ko.observable(rawNumber),
                showPopUpMenu: ko.observable(false)
            };
        };

        const _buildContactAddressFromCompanyAddress = (/** @type { any } */ address) => {
            const addressLineOne = address ? address.street : '';
            const addressLineThree = address ? address.city + ", " + address.region + " " + address.postCode : '';
            const city = address ? address.city : '';
            const postalCode = address ? address.postCode : '';
            const regionName = address ? address.region : '';

            /** @type { IContactAddressPresentationObject } */
            return {
                accountContactAddressId: '',
                addressLineOne: ko.observable(addressLineOne),
                addressLineTwo: ko.observable(null),
                addressLineThree: ko.observable(addressLineThree),
                city: ko.observable(city),
                countryName: ko.observable(null),
                label: ko.observable(_enumToLabelConverter.getAddressLabel(ContactAddressLabel.Work)),
                otherAddressInfo: ko.observable(null),
                postalCode: ko.observable(postalCode),
                regionName: ko.observable(regionName)
            };
        };

        //#endregion

        //#region contact sidebar presentation object builders

        const _buildContactsSidebarPresentationObjects = (/** @type { Array<IContactPresentationObject> } */ contacts) => {
            /** @type { ISidebarContactPresentationObject[] } */
            const contactsSidebarPresentationObjects = contacts.map(/** @type IContactPresentationObject */contact => {
                return _buildContactsSidebarPresentationObject(contact);
            }
            );
            return contactsSidebarPresentationObjects;
        };

        const _buildDisplayNameLast = (/** @type { string } */displayName) => {
            const [, firstName, lastName] = displayName.match(/(\w*)\s*(\w*)/);
            if (lastName !== '') {
                return `${lastName}, ${firstName}`;
            } else {
                return displayName;
            }
        };

        const _buildContactsSidebarPresentationObject = (/** @type { IContactPresentationObject } */ contact) => {
            /** @type { ISidebarContactPresentationObject } */
            const contactsSidebarPresentationObject = {
                accountContactId: contact.accountContactId,
                accountUserId: contact.accountUserId,
                accountUserGroupId: contact.accountUserGroupId,
                accountId: contact.accountId,
                contactSource: contact.contactSource,
                privateContactForUserId: contact.privateContactForUserId,
                hasAvatar: contact.hasAvatar,
                isUser: contact.isUser,
                isUserGroup: contact.isUserGroup,
                avatar: contact.avatar,
                avatars: contact.avatars,
                avatarUrl: contact.avatarUrl || null,
                displayNameFirst: contact.displayName,
                displayNameLast: ko.observable(_buildDisplayNameLast(contact.displayName())),
                initials: contact.initials,
                name: contact.name,
            };

            return contactsSidebarPresentationObject;
        };

        const _getInitialsFromDisplayName = (/** @type { string } */ displayName) => {
            if (displayName.trim().startsWith("+1")) {
                const digitsOnly = _phoneNumberFormatter.toNumbers(displayName);
                return digitsOnly.substring(0, 2);
            }

            const splitName = displayName.trim().split(' ');
            let initials = splitName[0].substring(0, 1).toUpperCase();
            if (splitName.length > 1) {
                initials += splitName[splitName.length - 1].substring(0, 1).toUpperCase();
            }

            return initials;
        };

        //#endregion

        //#region contact user group permissions
        const _commonStateUserToContactPermissionsPresentationObject = (/** @type { any } */ commonStateUser) => {
            /** @type { IContactPermissionsPresentationObject } */
            const contactPermissionsPresentationObject =  {
                id: commonStateUser.id,
                avatar: commonStateUser.avatar,
                userId: commonStateUser.id,
                userGroupId: null,
                displayName: commonStateUser.name,
                avatarType: SelectControlAvatarType.user,
                removeToolTipText: "",
                isReadOnly: ko.observable(false)
            };

            return contactPermissionsPresentationObject;
        };

       const _commonStateUserGroupToPresentationObject = (/** @type { any } */ commonStateUserGroup) => {
            let systemAdminsGroup = _commonState.get(UserGroupConstants.systemAdminsGroupId);
            let isSystemAdminsGroup = commonStateUserGroup.id === systemAdminsGroup.id;

           /** @type { IContactPermissionsPresentationObject } */
           const contactPermissionsPresentationObject =  {
                id: commonStateUserGroup.id,
                avatar: commonStateUserGroup.avatars,
                userId: null,
                userGroupId: commonStateUserGroup.id,
                displayName: commonStateUserGroup.name,
                avatarType: SelectControlAvatarType.userGroup,
                removeToolTipText: isSystemAdminsGroup ? i18n.t('contactPermissions:cannotRemoveSystemAdminsGroup') : "",
                isReadOnly: ko.observable(isSystemAdminsGroup)
            };

           return contactPermissionsPresentationObject;
       };

        const _commonStateItemToPermissionPresentationObject = (/** @type { any } */ commonStateItem) => {
            const systemAdminsGroup = _commonState.get(UserGroupConstants.systemAdminsGroupId);
            const isSystemAdminsGroup = commonStateItem.id === systemAdminsGroup.id;

            const avatar = commonStateItem.isUserGroup ? commonStateItem.avatars : commonStateItem.avatar;
            const avatarType = commonStateItem.isUserGroup ? SelectControlAvatarType.userGroup : SelectControlAvatarType.user;

            /** @type { IContactPermissionsPresentationObject } */
            const contactPermissionsPresentationObject =  {
                id: commonStateItem.id,
                avatar: avatar,
                userId: commonStateItem.isUser ? commonStateItem.id : null,
                userGroupId: commonStateItem.isUserGroup ? commonStateItem.id : null,
                displayName: commonStateItem.name,
                avatarType: avatarType,
                removeToolTipText: isSystemAdminsGroup ? i18n.t('contactPermissions:cannotRemoveSystemAdminsGroup') : "",
                isReadOnly: ko.observable(isSystemAdminsGroup)
            };

            return contactPermissionsPresentationObject;
        };

        const _idsToPresentationObjects = (/** @type { Array<string> } */ userIdsWithPermission, /** @type { Array<string> } */ userGroupIdsWithPermission) => {
            const userPermissionPresentationObjects = userIdsWithPermission.map((userId) => {
                const commonStateUser = _commonState.get(userId);

                return _commonStateUserToContactPermissionsPresentationObject(commonStateUser);
            });

            const userGroupPermissionPresentationObjects = userGroupIdsWithPermission.map((userGroupId) => {
                const commonStateUserGroup = _commonState.get(userGroupId);

                return _commonStateUserGroupToPresentationObject(commonStateUserGroup);
            });

            /** @type { Array<IContactPermissionsPresentationObject> } */
            return userPermissionPresentationObjects.concat(userGroupPermissionPresentationObjects);
        };

        const _setContactPermissionsUserIds = (/** @type { Array<string> } */ userIdsWithPermission, /** @type { Array<string> } */ userGroupIdsWithPermission) => {
            const usersWithPermission = new Set(userIdsWithPermission);
            const groupMembersWithPermission = userGroupIdsWithPermission.map((userGroupId) => {
                const commonStateGroup = _commonState.get(userGroupId);
                return commonStateGroup ? commonStateGroup.members() : [];
            });
            groupMembersWithPermission.forEach((memberId) =>  usersWithPermission.add(memberId));
            _contactPermissionsUserIds(Array.from(usersWithPermission));
        };

        //#endregion

        this.sidebarContacts = ko.computed(() => {
            const filteredContacts = [..._sidebarContacts()].filter((c) => {
                return _filterBy().includes(c.contactSource());
            });

            if (_sortBy() === ContactSortByOptions.FirstName) {
                return _sortSidebarContacts(filteredContacts, true);
            } else {
                return _sortSidebarContacts(filteredContacts, false);
            }
        });

        this.contactPermissionsUsersAndUserGroups = ko.computed(() => {
            return [..._contactPermissionsUsersAndUserGroups()];
        });

        this.usersWithContactPermissions = ko.computed(() => {
            return [..._contactPermissionsUserIds()];
        });

        _contactsRepository.onContactCreated(_onContactCreated);
        _contactsRepository.onContactUpdated(_onContactUpdated);
        _contactsRepository.onContactDeleted(_onContactDeleted);
        _contactsRepository.onContactAvatarUpdated(_onContactAvatarUpdated);
        _contactsRepository.onContactPermissionAdded(_contactPermissionAdded);
        _contactsRepository.onContactPermissionRemoved(_contactPermissionRemoved);
        _disposables.push(_contactsRepository);
    };


    ContactsStateSingleton.singleton = new ContactsStateSingleton();

    return ContactsStateSingleton.singleton;
});
