define('presentation/common/birthdayInput/viewModels/birthdayInputViewModel',[
    'businessServices/converters/birthdayFormatter'
], function(
    /** @type typeof import('businessServices/converters/birthdayFormatter') */
    BirthdayFormatter
) {
    return function() {
        const self = this;

        const _birthdayFormatter = new BirthdayFormatter();
        let _settings = null;

        const _formatBirthday = (/** @type { string } */  birthday) => {
            const numericBirthday = birthday.replaceAll(/[^0-9]/g, "");
            const trimmedBirthday = numericBirthday.substring(0, 8);
            return _birthdayFormatter.formatBirthdayString(trimmedBirthday);
        };

        const _initialize = () => {
            if (_settings.value !== undefined) {
                const initialValue = ko.unwrap(_settings.value);
                self.birthday(_formatBirthday(initialValue));
                _settings.value.isKeyUp = ko.observable(false);
            }

            if (_settings.hasOwnProperty('validateOnBlur')) {
                self.validateOnBlur = ko.unwrap(_settings.validateOnBlur);
            }

            const id = _settings.customId || _settings.id;
            if (id !== undefined) {
                self.customId = id;
            }
        };

        self.keyUpEvent = () => {
            if (_settings.valueUpdate === "keyup") {
                _settings.value.isKeyUp(true);
                self.birthday(_formatBirthday(self.birthday()));
                _settings.value((self.birthday()));
            }
        };

        self.updateEvent = () => {
            _settings.value.isKeyUp(false);
            if (_settings.value() !== self.birthday()) {
                self.birthday(_formatBirthday(self.birthday()));
                _settings.value((self.birthday()));
            } else if (_settings.value.validate && self.validateOnBlur) {
                _settings.value.validate();
            }
        };

        self.customId = "";
        self.birthday = ko.observable('');
        self.validateOnBlur = true;


        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };
    };
});

