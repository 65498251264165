/**
 * @module presentation/common/userGroupAvatar/userGroupAvatarImageFactory
 * @require common/promises/promiseFactory
 * @require presentation/common/userGroupAvatar/imageViewModel
 * @require persistence/fileStores/userAvatarFileStore
 * @require common/files/encoding
 */
define('presentation/common/userGroupAvatar/userGroupAvatarImageFactory',['common/promises/promiseFactory',
    'presentation/common/userGroupAvatar/userGroupImagePresentationObject',
    'presentation/common/userAvatar/userAvatarImageFactory',
    'persistence/dataProviders/userGroupDataProvider',
    'persistence/dataProviders/userDataProvider',
	'presentation/settings/userGroup/presentationObjects/userGroupPresentationObject',
    'businessServices/specifications/userHasAvatarSpecification',
    'common/files/encoding'
], function () {
    /**
     * @constructor
     */
    return function () {
        var self = this;

        /**
         * @type {module:common/promises/promiseFactory}
         * @private
         */
        var _promiseFactory;

        /**
         * @type {module:persistence/fileStores/userAvatarFileStore}
         * @private
         */
        var _userAvatarImageFactory = null;

		/**
         * @type {module:presentation/settings/userGroup/presentationObjects/userGroupPresentationObject}
         * @private
         */
        var UserGroupPresentationObjectConstructor = null;

        /**
         * @type {module:persistence/dataProviders/userGroupDataProvider}
         * @private
         */
        var _userGroupDataProvider = null;

        /**
         * @type {module:persistence/dataProviders/userDataProvider}
         * @private
         */
        var _userDataProvider = null;

        /**
         * @type {module:businessServices/specifications/userHasAvatarSpecification}
         * @private
         */
        var _userHasAvatarSpecification = null;

        /**
         * @type {module:common/files/encoding}
         * @private
         */
        var _encoding = null;

        var ImageViewModelConstructor = null;



        /**
         *  Constructs an image place holder to be aynchronously loaded
         * @param {id} userGroupId The user group id to retrieve the avatar for
         * @returns {module:presentation/common/userGroupAvatar/userGroupImagePresentationObject}
         * @private
         */
        var _findImageForUserGroupId = function (userGroupId) {
            var UserGroupImagePresentationObjectConstructor = require('presentation/common/userGroupAvatar/userGroupImagePresentationObject');
            var userGroupImagePresentationObject = new UserGroupImagePresentationObjectConstructor();

            _promiseFactory.defer(function(deferredObject) {
                _userGroupDataProvider.getById(userGroupId).done(function(userGroup) {
                    _findUsersForUserGroup(userGroup).done(function(users) {
                        var usersWithAvatars = [];

                        // Find which users have avatars
                        var memberIds = userGroup.memberUserIds;
                        for (var memberIndex = memberIds.length - 1; memberIndex >= 0; memberIndex-- ) {
                            var memberId = memberIds[memberIndex];
                            var user = _findUserByUserId(memberId, users);

                            if (_userHasAvatarSpecification.isSatisfiedBy(user)) {
                                var userAvatar;
                                _userAvatarImageFactory.getAvatarByUser(user).done(function(avatar) {
                                    userAvatar = avatar;
                                })
                                .fail(function(error) {
                                    error.user = user;
                                    deferredObject.reject(error);
                                });

                                usersWithAvatars.push(userAvatar);
                                if (usersWithAvatars.length === 4) {
                                    break;
                                }
                            }
                        }

                        userGroupImagePresentationObject.images(usersWithAvatars);
                        deferredObject.resolve();
                    });
                });
            });

            return userGroupImagePresentationObject;
        };
		
		/**
         * Retrieves users list for a user group
         * @param {object} userGroup The user group object to retrieve the user details / avatars from userAvatarImageFactory
         * @returns {module:presentation/common/userGroupAvatar/userGroupAvatarImageFactory}
         */
        var _findUsersForUserGroup = function(userGroup) {
            return _promiseFactory.defer(function(deferredObject) {
                var memberIds = userGroup.memberUserIds;
                if (memberIds.length === 1) {
                    var memberId = memberIds[0];
                    _userDataProvider.getById(memberId).done(function(user) {
                        var users = [user];
                        deferredObject.resolve(users);
                    })
                    .fail(function(error) {
                        error.userGroup = userGroup;
                        error.memberId = memberId;
                        deferredObject.reject(error);
                    });
                } else {
                    _userDataProvider.getAll().done(function(allUsers) {
                        deferredObject.resolve(allUsers);
                    })
                    .fail(function(error) {
                        error.userGroup = userGroup;
                        deferredObject.reject(error);
                    });
                }
            });
        };
		
		/**
         * Retrieves user details if user present in member list
         * @param {id} userId The user Id to retrieve the user details
		 * @param {object} users array of objects The users provide list of users available in the group
         * @returns {module:presentation/common/userGroupAvatar/userGroupAvatarImageFactory}
         */
        var _findUserByUserId = function(userId, users) {
            for (var x = 0; x < users.length; x++) {
                if (users[x].userId === userId) {
                    return users[x];
                }
            }
            return null;
        };

        /**
         * Retrieves a user group avatar
         * @param {id} userGroupId The user group id to retrieve the avatar for
         * @returns {module:presentation/common/userGroupAvatar/userGroupImagePresentationObject}
         */
        self.getAvatarByUserGroupId = function (userGroupId) {
            var image = _findImageForUserGroupId(userGroupId);
            return image;
        };

        /**
         * Initializes the state of the object at runtime.
         */
        self.init = function () {
            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            _promiseFactory = new PromiseFactoryConstructor();

            var UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
            _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
            _userAvatarImageFactory.init();
			
			UserGroupPresentationObjectConstructor = require('presentation/settings/userGroup/presentationObjects/userGroupPresentationObject');
            var UserGroupDataProviderConstructor = require('persistence/dataProviders/userGroupDataProvider');
            _userGroupDataProvider = new UserGroupDataProviderConstructor();


            var UserDataProviderConstructor = require('persistence/dataProviders/userDataProvider');
            _userDataProvider = new UserDataProviderConstructor();

            var EncodingConstructor = require('common/files/encoding');
            _encoding = new EncodingConstructor();

            var UserHasAvatarSpecificationConstructor = require('businessServices/specifications/userHasAvatarSpecification');
            _userHasAvatarSpecification = new UserHasAvatarSpecificationConstructor();
        };
    };
});
