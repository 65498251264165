define('presentation/settings/schedules/facades/addScheduleFacade',[
        'persistence/webSocket/webSocketApp'
    ],
    function() {
        let _promiseFactory = null;
        let _webSocketApp = null;

        const _get = (scheduleId) => {
            return _promiseFactory.defer(function(promise) {
                const request = {
                    "accountAttendantScheduleId" : scheduleId
                };
                _webSocketApp.send("get", request, (result) => {
                    promise.resolve(result);
                });
            });
        };

        const _create = (scheduleName, segments, defaultRoutingRule) => {
            return _promiseFactory.defer((promise) => {
                const request = {
                    "name" : scheduleName,
                    "segments" : segments,
                    "defaultRoutingRule": defaultRoutingRule
                };
                _webSocketApp.send("create", request, () => {
                    promise.resolve();
                });
            });
        };

        const _update = function(scheduleName, segments, defaultRoutingRule, scheduleId) {
            return _promiseFactory.defer((promise) => {
                const request = {
                    "accountAttendantScheduleId" : scheduleId,
                    "name" : scheduleName,
                    "segments" : segments,
                    "defaultRoutingRule": defaultRoutingRule
                };
                _webSocketApp.send("update", request, () => {
                    promise.resolve();
                });
            });
        };

        const _getTimeZone = () => {
            return _promiseFactory.defer((promise) => {
                const request = {};
                _webSocketApp.send("getTimezone", request, (result) => {
                    promise.resolve(result.timeZoneName);
                });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("schedule_edit");
        };

        return function() {
            let self = this;

            self.init = _init;
            self.create = _create;
            self.get = _get;
            self.update = _update;
            self.getTimeZone = _getTimeZone;
        };
    });

