define('presentation/signup/viewModels/signupHeaderViewModel',[
        'businessServices/browserSupport/browserType',
        'businessServices/signup/signupSessionStore',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'constants/signupNumberTypeSelectionConstants',
        'presentation/signup/common/signupNavigationManager',
        'constants/signupSessionFlowConstants'
    ],
    function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const _browserType = require('businessServices/browserSupport/browserType');
            const _i18n = require('i18next');
            const _signupNumberTypeSelectionConstants = require('constants/signupNumberTypeSelectionConstants');
            const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
            const _signupSessionState = require('businessServices/signup/signupSessionSingleton');

            let _phoneNumberFormatter = null;
            let _signupSessionStore = null;
            let _signupNavigationManager = null;
            let _disposables = [];

            self.carrierName = ko.observable('');
            self.isHeaderHidden = ko.observable(true);
            self.headerIcon = ko.observable('signupNumberLocal');
            self.headerText = ko.observable('localNumber');
            self.phoneNumbers = ko.observable([]);
            self.preloadedPhoneNumbers = ko.observable([]);
            self.formattedCarrierName = ko.pureComputed(() => {
                if (self.carrierName()) {
                    return '(' + self.carrierName() + ')';
                }
                return null;
            });
            self.showCarrier = ko.pureComputed(() => {
                if (self.isMobile){
                    return self.carrierName() !== '' && !self.isThereMoreThanOnePhoneNumber();
                } else {
                  return self.carrierName() !== '';
                }
            });
            self.firstPhoneNumber = ko.pureComputed(() => {
                if (self.phoneNumbers().length) {
                    return self.phoneNumbers()[0].phoneNumber;
                }

                return "";
            });
            self.showPhoneNumbers = ko.pureComputed(() => {
                return self.phoneNumbers().length > 0;
            });
            self.mobileButtonText = ko.pureComputed(() => {
                let phoneNumbersMinusOne = self.phoneNumbers().length - 1;
                return '+' + phoneNumbersMinusOne + ' ';
            });
            self.isMobile = _browserType.isMobile();
            self.shouldDisplayModal = ko.observable(false);
            self.modalSubtitle = ko.pureComputed(() => {
                return _i18n.t('signupHeader:carrierName', {carrierName: self.carrierName()});
            });
            self.isThereMoreThanOnePhoneNumber = ko.pureComputed(() => {
                return self.phoneNumbers().length > 1;
            });
            self.isCheckmarkVisible = ko.pureComputed(() => {
                return self.phoneNumbers().length === 1;
            });
            self.phoneNumbersAreUpdating = ko.observable(false);

            const _setHeaderNumbers = () => {

                const formatPhoneNumbers = () => {
                    if (_signupSessionState.headerPhoneNumbers()) {
                        const lastPhoneNumberIndex = _signupSessionState.headerPhoneNumbers().length - 1;
                        return _signupSessionState.headerPhoneNumbers().map((phoneNumber, index) => ({
                                phoneNumber: _phoneNumberFormatter.toUSAreaCode(phoneNumber),
                                showDivider: index < lastPhoneNumberIndex
                            })
                        );
                    } else {
                        return [];
                    }
                };

                self.carrierName(_signupSessionState.headerCarrier());

                self.phoneNumbersAreUpdating(true);
                setTimeout(() => {
                    self.phoneNumbers(formatPhoneNumbers());
                    self.phoneNumbersAreUpdating(false);
                }, 500);
            };

            const _setHeaderStatus = () => {
                const routeId = _signupNavigationManager.getRouteId();
                const flowId = _signupNavigationManager.getFlowId();

                if ((routeId === "signupEmail" && flowId === _signupSessionFlowConstants.flowA) || routeId === "signupPhoneNumberType") {
                    self.isHeaderHidden(true);
                } else {
                    switch (_signupSessionState.numberTypeSelection()) {
                        case _signupNumberTypeSelectionConstants.local:
                            self.headerIcon('signupNumberLocal');
                            self.headerText("localNumber");
                            self.isHeaderHidden(false);
                            break;
                        case _signupNumberTypeSelectionConstants.tollFree:
                            self.headerIcon('signupNumberTollfree');
                            self.headerText("tollFreeNumber");
                            self.isHeaderHidden(false);
                            break;
                        case _signupNumberTypeSelectionConstants.port:
                            self.headerIcon('signupNumberTransfer');
                            self.headerText("portNumber");
                            self.isHeaderHidden(false);
                            break;
                        default:
                            self.isHeaderHidden(true);
                            break;
                    }
                }
            };

            self.displayModal = () => {
                self.shouldDisplayModal(true);
            };

            self.hideModal = () => {
                self.shouldDisplayModal(false);
            };

            self.detached = function() {
                _disposables.forEach(subscription => subscription.dispose());
                _disposables = [];
            };

            self.activate = function() {
                const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
                _signupNavigationManager = new SignupNavigationManagerConstructor();
                _signupNavigationManager.init();

                const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
                _signupSessionStore = new SignupSessionStoreConstructor();
                _signupSessionStore.init();

                const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
                _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

                return _initialize();
            };

            const _initialize = () => {
                _disposables.push(_signupSessionState.numberTypeSelection.subscribe(_setHeaderStatus));
                _disposables.push(_signupSessionState.headerPhoneNumbers.subscribe(_setHeaderNumbers));
                _disposables.push(_signupSessionState.headerCarrier.subscribe(_setHeaderNumbers));

                _setHeaderNumbers();
                _setHeaderStatus();

                _promiseFactory.wait();
            };
        };
    });
