define('presentation/settings/voicemailBoxes/facades/voicemailBoxesFacade',[
    'common/storage/commonState',
    'constants/referentialConstraintEnumerations',
    'constants/userGroupConstants',
    'externalDependencies/clientApi',
    'presentation/common/dateTimeValue',
    'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
    'settings/navigationConfiguration'
    ],
    function() {
        const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');
        const integrityWarningMessagesEnumeration = require('constants/referentialConstraintEnumerations');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _clientApi = null;
        let _promiseFactory = null;

        const _getVoicemailBoxes = () => {
             return _promiseFactory.defer((promise) => {
                 let request = {};
                _clientApi.call("settings/voicemailBoxes/retrieve", "POST", request, 'application/json')
                    .fail(promise.reject)
                    .done((result) => {
                        let voicemailBoxes = result.data;
                        let convertedVoicemailBoxes = voicemailBoxes.map(voicemailBox => {
                            let convertedVoicemailBox = {
                                voicemailBoxId: voicemailBox.voicemailBoxId,
                                name: ko.observable(voicemailBox.standaloneName),
                                type: ko.observable(_commonState.typeDisplay.standalone),
                                isDeactivated: ko.observable(false),
                                isExpired: ko.observable(false),
                                currentGreetingName: voicemailBox.currentGreetingName,
                                createdDateTime: new DateTimeValueConstructor(voicemailBox.createdDateTime),
                                modifiedDateTime: new DateTimeValueConstructor(voicemailBox.updatedDateTime),
                                owningUserId: voicemailBox.owningUserId,
                                userAvatar: ko.observable(),
                                owningUserGroupId: voicemailBox.owningUserGroupId,
                                editVoicemailBoxUrl: _navigationConfiguration.routesById.editVoicemailBox.baseUrl + voicemailBox.voicemailBoxId,
                                deactivateTooltip: ''
                            };
                            if (voicemailBox.owningUserId !== null && voicemailBox.standaloneName === null ) {
                                convertedVoicemailBox.type(_commonState.typeDisplay.user);
                                convertedVoicemailBox.deactivateTooltip = _i18n.t('voicemailBoxes:deactivateUserTooltip');
                            }
                            if (voicemailBox.owningUserGroupId !== null) {
                                convertedVoicemailBox.type(_commonState.typeDisplay.userGroup);
                                const isSystemGroup = voicemailBox.owningUserGroupType !== 4;
                                convertedVoicemailBox.deactivateTooltip = isSystemGroup ?
                                    _i18n.t('voicemailBoxes:deactivateTooltip') :
                                    _i18n.t('voicemailBoxes:deactivateGroupTooltip');
                            }

                            return convertedVoicemailBox;
                        });

                    promise.resolve(convertedVoicemailBoxes);
                    });
                });
            };

        const _deleteVoicemailBox = (voicemailBoxId) => {
            return _promiseFactory.defer((promise) => {
                let request = {
                    "voicemailBoxId" : voicemailBoxId
                };
                _clientApi.call("settings/voicemailBoxes/delete", "POST", request, 'application/json')
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _checkConstraints = (voicemailBoxId) => {
            return _promiseFactory.defer((promise) => {
                let request = {
                    "voicemailBoxId": voicemailBoxId
                };
                _clientApi.call("settings/voicemailBoxes/checkConstraints", "POST", request, 'application/json')
                    .fail(promise.reject)
                    .done(results => {
                        if (results.data && results.data.length > 0) {

                            const integrityWarnings = results.data.map(constraint => {
                                const constraintType = constraint.parentType + '-' + constraint.referencedType;

                                const integrityWarningMessagesEnumerationElement = integrityWarningMessagesEnumeration[constraintType];
                                if (integrityWarningMessagesEnumerationElement) {
                                    const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                                    integrityWarning.entityText = integrityWarningMessagesEnumerationElement.entityText;
                                    integrityWarning.entityName = constraint.name;
                                    integrityWarning.icon = integrityWarningMessagesEnumerationElement.icon;
                                    integrityWarning.message = integrityWarningMessagesEnumerationElement.message;
                                    return integrityWarning;
                                } else {
                                    promise.reject(new Error("unknown integrity warning type."));
                                }
                            });
                            promise.resolve(integrityWarnings);
                        }
                        else {
                            promise.resolve(false);
                        }
                    });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
                _clientApi = new ClientApiConstructor();
                _clientApi.init();
        };

        return function() {
            let self = this;

            self.init = _init;
            self.getVoicemailBoxes = _getVoicemailBoxes;
            self.checkConstraints = _checkConstraints;
            self.deleteVoicemailBox = _deleteVoicemailBox;

            };
        });
