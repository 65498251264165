define('presentation/loginNotifications/viewModels/addPaymentMethodModalViewModel',[
    'businessServices/country/countryCode',
    'businessServices/creditCards/creditCardTypes',
    'common/promises/promiseFactory',
    'constants/creditCardExpirationYearConstants',
    'constants/creditCardResponseConstants',
    'constants/countryEnumerations',
    'constants/monthEnumerations',
    'common/collections/collectionSorter',
    'entities/paymentMethodEntity',
    'presentation/loginNotifications/validators/addPaymentMethodModalValidator'
], function () {
    return function () {
        const self = this;

        const SorterConstructor = require('common/collections/collectionSorter');
        const sorter = new SorterConstructor();
        const CountryCodeConstructor = require('businessServices/country/countryCode');
        const CreditCardTypesConstructor = require('businessServices/creditCards/creditCardTypes');
        const _creditCardTypes = new CreditCardTypesConstructor();
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const AddPaymentValidatorConstructor = require('presentation/loginNotifications/validators/addPaymentMethodModalValidator');
        const DateConstructor = require('common/time/date');
        const _creditCardExpirationYear = require('constants/creditCardExpirationYearConstants');
        const MonthConstructor = require('common/calendar/monthList');
        const _months = new MonthConstructor().monthList;
        const _countryEnumerations = require('constants/countryEnumerations');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _countryCodes = new CountryCodeConstructor();
        const _addPaymentValidator = new AddPaymentValidatorConstructor();
        const _i18n = require('i18next');
        const _date = new DateConstructor();

        const PaymentMethodEntityConstructor = require('entities/paymentMethodEntity');

        let _disposables = [];

        const _currentCountry = (country = null) => {
            if (country) {
                const currentCountry = _countryCodes.findByAbbreviation(country);
                return self.countryList.find(item => item.text === currentCountry.name);
            } else {
                const currentCountry = _countryCodes.getDefaultCountry();
                return self.countryList.find(item => item.text === currentCountry.name);
            }
        };

        const _currentMonth = (month = null) => {
            if (month) {
                return _months.find(item => item.value === month);
            } else {
                const currentMonth = new DateConstructor();
                currentMonth.setToday();
                return _months.find(item => item.value === currentMonth.monthPadded());
            }
        };

        const _currentYear = (year = null) => {
            if (year) {
                const isPaymentMethodExpirationYearInYearList = !!self.yearList.find(item => item.text === year);
                if (isPaymentMethodExpirationYearInYearList) {
                    return self.yearList.find(item => item.text === year);
                }
                const currentYear = new Date().getFullYear();
                return currentYear;
            } else {
                const currentYear = new Date().getFullYear();
                return self.yearList.find(item => item.text === currentYear);
            }
        };

        const _onCountryChange = () => {
            self.postalCode("");
            self.postalCode.isValid(null);
        };

        const _populateCountryDropdown = () => {
            let countries = _countryCodes.countryCodes;
            sorter.sort(countries, 'name', true);
            self.countryList = countries.map((country) => {
                return Object.assign({}, country, {
                    id: country.countryAbbreviation,
                    text: country.name,
                });
            });
        };

        const _populateExpirationDateDropdowns = () => {
            self.monthList = _months.map((month) => {
                month.id = month.value;
                month.text = month.value;
                return month;
            });

            const currentYear = new Date().getFullYear();
            self.yearList = Array.from(Array(_creditCardExpirationYear.yearsFromCurrent),
                (_, i) => ({id: currentYear + i, text: currentYear + i})
            );
        };


        self.cardHolderName = ko.observable("");
        self.cardNumber = ko.observable("");
        self.creditCardMask = '(3999 999999 99999|4999 9999 9999 9999|5999 9999 9999 9999|6999 9999 9999 9999)';
        self.cvvSecurityCode = ko.observable("");
        self.countryList = null;
        self.countrySelected = ko.observable("");
        self.expirationDate = ko.observable("");
        self.isCanada = ko.computed(() => self.countrySelected().toString() === _countryEnumerations.ca);
        self.isDuplicateCard = ko.observable(false);
        self.displayedCardName = ko.observable("");
        self.lastFourDigits = ko.observable("");
        self.monthList = null;
        self.monthSelected = ko.observable("");
        self.postalCode = ko.observable("");
        self.postalCodeMask = ko.computed(() => self.isCanada() ? 'A9A 9A9' : '99999');
        self.postalCodeMetaData = ko.pureComputed(() => {
            return (self.isCanada()) ?
                {i18nKey: 'addPaymentMethodModal:postalCode', type: 'postalcode', maxLength: 6} :
                {i18nKey: 'addPaymentMethodModal:zipCode', type: 'zipcode', maxLength: 5};
        });
        self.yearList = null;
        self.yearSelected = ko.observable("").extend({observeState: true});
        self.postalCodeLabel = ko.computed(() => self.isCanada() ? _i18n.t('addPaymentMethodModal:postalCode') : _i18n.t('addPaymentMethod:zipCode'));
        self.showValidation = ko.observable(false);

        self.paymentMethod = () => {
            let paymentMethod = new PaymentMethodEntityConstructor();
            if (self.cardHolderName() !== '') {
                paymentMethod.cardHolderName = self.cardHolderName();
            }
            if (self.cardNumber() !== '') {
                paymentMethod.creditCardNumber = _creditCardTypes.unformatCreditCard(self.cardNumber());
                paymentMethod.cardType = _creditCardTypes.getCreditCardType(paymentMethod.creditCardNumber);
            }
            if (self.monthSelected() !== '') {
                paymentMethod.expirationMonth = self.monthSelected();
            }
            if (self.yearSelected() !== '') {
                paymentMethod.expirationYear = self.yearSelected();
            }
            if (paymentMethod.expirationMonth && paymentMethod.expirationYear) {
                paymentMethod.expirationDate = _date.getExpirationDate(paymentMethod.expirationYear, paymentMethod.expirationMonth);
            }
            if (self.cvvSecurityCode() !== '') {
                paymentMethod.cvvSecurityCode = self.cvvSecurityCode();
            }
            if (self.postalCode() !== '') {
                paymentMethod.postalCode = self.postalCode();
            }
            if (self.countrySelected() !== '') {
                paymentMethod.country = self.countrySelected();
            }
            return paymentMethod;
        };

        self.validate = () => {
            return _promiseFactory.defer((deferredObject) => {
                _addPaymentValidator.validate()
                    .fail(deferredObject.reject)
                    .done((validationItems) => {
                        self.showValidation(true);
                        deferredObject.resolve(validationItems);
                    });
            });
        };

        self.detached = function () {
            _disposables.forEach(s => s.dispose());
            _disposables = [];
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            _populateExpirationDateDropdowns();
            _populateCountryDropdown();

            self.countrySelected(_currentCountry().id);
            self.monthSelected(_currentMonth().id);
            self.yearSelected(_currentYear().id);

            _addPaymentValidator.registerViewModel(self);

            _disposables.push(self.countrySelected.subscribe(_onCountryChange));

            return _promiseFactory.wait();
        };
    };
});
