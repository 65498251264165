define('presentation/settings/autoAttendant/viewModels/autoAttendantRoutingRulesViewModel',[
    'businessServices/events/eventManager',
    'common/storage/commonState',
    'common/time/datetimeFormatter',
    'presentation/common/modal',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/deleteSubMenuActionViewModel',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',
    'presentation/common/routeToModal/strategies/autoAttendantSubMenuStrategy',
    'presentation/common/routeToModal/viewModels/routeToModalViewModel',
    'presentation/settings/autoAttendant/facades/autoAttendantRoutingRulesFacade'
], function() {
    return function(autoAttendantId, hasAutoAttendantChanged, promiseFactory, storageFacade, subMenuItem, autoAttendants, availableObjects) {
        const self = this;

        const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');
        const AutoAttendantSubMenuSaveStrategyConstructor = require('presentation/common/routeToModal/strategies/autoAttendantSubMenuStrategy');
        const DeleteSubMenuActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteSubMenuActionViewModel');
        const RouteToModalViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToModalViewModel');
        const Modal = require('presentation/common/modal');

        const DateFormatterConstructor = require('common/time/datetimeFormatter');
        let _dateTimeFormatter = new DateFormatterConstructor();

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        let _facade = null;

        const _autoAttendantId = autoAttendantId;
        const _autoAttendants = autoAttendants;
        const _promiseFactory = promiseFactory;
        const _subMenuItem = subMenuItem;

        const DELETE_RESPONSE = "yes";

        const _buildDeleteModal = function(subMenuItem) {
            let constructorParams = [subMenuItem];

            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteSubMenuActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: 'removeHeader'})
                .setSubmitButtonText({i18n: 'remove'});
        };

        const _buildRoutingRules = () => {
            return _promiseFactory.defer((deferredObject) => {

                _facade.buildRoutingRulesForDisplay(_subMenuItem, availableObjects)
                    .fail(deferredObject.reject)
                    .done((callFlow) => {
                        self.callFlow = callFlow;
                        self.callFlow.forEach(callFlow => {
                            var autoAttendantsInCurrentCallFlow = [autoAttendantId];
                            var autoAttendantsInNextCallFlow = autoAttendantsInCurrentCallFlow.slice(0);
                            self.isInitialAutoAttendant = autoAttendantsInCurrentCallFlow.length > 0 && autoAttendantsInCurrentCallFlow[0] === autoAttendantId;

                            if (callFlow.routeType() === _commonState.types.autoAttendant) {

                                if (autoAttendantId){
                                    let foundAutoAttendant = availableObjects.availableAutoAttendants.find(autoAttendant => {
                                        return autoAttendant.accountAutoAttendantId === autoAttendantId;
                                    });

                                    if (foundAutoAttendant) {
                                        autoAttendantsInCurrentCallFlow.push(foundAutoAttendant.accountAutoAttendantId);
                                    }

                                    let isLoopedCallFlow = autoAttendantsInCurrentCallFlow.some(autoAttendantId => autoAttendantId === callFlow.routeToItemId);
                                    callFlow.showLoopedCallFlow(isLoopedCallFlow);

                                    if (!isLoopedCallFlow) {
                                        let autoAttendant = new AutoAttendantCallFlowConstructor(callFlow.routeToItemId, autoAttendantsInNextCallFlow, availableObjects.availableVoicePrompts, availableObjects.availableSchedules, availableObjects.availableAutoAttendants);
                                        autoAttendant.activate();
                                        callFlow.autoAttendant(autoAttendant);
                                    }
                                } else {
                                    let autoAttendant = new AutoAttendantCallFlowConstructor(callFlow.routeToItemId, autoAttendantsInNextCallFlow, availableObjects.availableVoicePrompts, availableObjects.availableSchedules, availableObjects.availableAutoAttendants);
                                    autoAttendant.activate();
                                    callFlow.autoAttendant(autoAttendant);
                                }

                            } else if (callFlow.routeType() === _commonState.types.schedule) {
                                callFlow.scheduleSegments.forEach(segment => {
                                    segment.callFlow.forEach(segmentCallFlow => {
                                        if (segmentCallFlow.routeType() === _commonState.types.autoAttendant) {
                                            let isLoopedCallFlow = autoAttendantsInCurrentCallFlow.some(autoAttendantId => autoAttendantId === segmentCallFlow.routeToItemId);
                                            segmentCallFlow.showLoopedCallFlow(isLoopedCallFlow);
                                            autoAttendantsInNextCallFlow.push(segmentCallFlow.routeToItemId);
                                            if (!isLoopedCallFlow) {
                                                let autoAttendant = new AutoAttendantCallFlowConstructor(segmentCallFlow.routeToItemId, autoAttendantsInNextCallFlow,  availableObjects.availableVoicePrompts, availableObjects.availableSchedules, availableObjects.availableAutoAttendants);
                                                autoAttendant.activate();
                                                segmentCallFlow.autoAttendant(autoAttendant);
                                            }
                                        }
                                    });
                                });
                            }
                        });

                        deferredObject.resolve();
                    });
            });
        };

        const _showCallFlowControls = (connector) => {
            if (connector()) {
                connector().callFlow.map((c) => {
                  return c.showControls(true);
                });
            }
        };

        const _showDeletePrompt = () => {
            _buildDeleteModal(_subMenuItem);
            self.actionModal.showModal()
                .done((result) => {

                    _showCallFlowControls(_subMenuItem.connector);

                    if (_subMenuItem.subMenuItems){
                        _subMenuItem.subMenuItems.map((s) => {
                            s.showControls(true);
                            return _showCallFlowControls(s.connector);
                        });
                    }

                    if (result === DELETE_RESPONSE) {
                        hasAutoAttendantChanged(true);
                        subMenuItem.optionName(null);
                        subMenuItem.routeId("");
                        subMenuItem.type(null);
                        subMenuItem.typeId(null);
                        subMenuItem.connector(null);
                        subMenuItem.showVoicePromptOption(false);
                        subMenuItem.isVoicePromptInvalid(false);
                        subMenuItem.isVoicePromptEnabled(false);
                        subMenuItem.routingRules(null);
                    }
                })
                .fail((error) => {
                    throw error;
                });

        };

        self.modalDisplayer = new Modal();
        self.actionModal = null;
        self.optionName = ko.observable(null);
        self.storageFacade = null;
        self.conversionInProgress = false;
        self.callFlow = [];
        self.routeSearchData = "";
        self.showCallFlowDetails = ko.observable(true);
        self.showScheduleDetails = ko.observable(true);
        self.isInitialAutoAttendant = false;

        self.toggleCallFlowDetails = () => {
            self.showCallFlowDetails(!self.showCallFlowDetails());
        };

        self.toggleScheduleDetails = () => {
            self.showScheduleDetails(!self.showScheduleDetails());
        };

        self.toggleScheduleSegmentDetails = (subMenuPresentationObject) => {
            subMenuPresentationObject.showScheduleSegmentDetails(!subMenuPresentationObject.showScheduleSegmentDetails());
        };

        self.getSegmentDays = (scheduleSegment) => {
            let segmentDays = [];
            if(scheduleSegment.isForMonday()) {
                segmentDays.push("Mon");
            }
            if(scheduleSegment.isForTuesday()) {
                segmentDays.push("Tue");
            }
            if(scheduleSegment.isForWednesday()) {
                segmentDays.push("Wed");
            }
            if(scheduleSegment.isForThursday()) {
                segmentDays.push("Thur");
            }
            if(scheduleSegment.isForFriday()) {
                segmentDays.push("Fri");
            }
            if(scheduleSegment.isForSaturday()) {
                segmentDays.push("Sat");
            }
            if(scheduleSegment.isForSunday()) {
                segmentDays.push("Sun");
            }
            return segmentDays.join(", ");
        };

        self.getSegmentHours = (scheduleSegment) => {
            let today = new Date();
            let startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            startTime.setHours(scheduleSegment.startHour());
            startTime.setMinutes(scheduleSegment.startMinute());
            let startTimeString = _dateTimeFormatter.format(startTime, "hhh:nn tt");

            let endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            endTime.setHours(scheduleSegment.endHour());
            endTime.setMinutes(scheduleSegment.endMinute());
            let endTimeString = _dateTimeFormatter.format(endTime, "hhh:nn tt");

            return startTimeString + " to " + endTimeString;
        };

        self.editRoutingRules = () => {
            return _promiseFactory.defer((deferredObject) => {
                const modal = new RouteToModalViewModelConstructor(_voiceRouteEnumerations.routingRule, _subMenuItem.routingRules());
                const saveStrategy = new AutoAttendantSubMenuSaveStrategyConstructor();
                saveStrategy.init();
                modal.isOptionNameEnabled = true;
                modal.optionName(_subMenuItem.optionName());
                modal.modalTitle(_i18n.t('addAutoAttendant:menuOptionEdit'));
                modal.routeTitle(_i18n.t('routeTo:whenOptionSelected'));
                modal.addMode = false;
                modal.routeToStrategy = saveStrategy;
                modal.routeToStrategy.autoAttendantId = _autoAttendantId;
                modal.routeToStrategy.autoAttendants = _autoAttendants;
                modal.routeToStrategy.autoAttendantVoiceSubMenuId = _subMenuItem.subMenuId;
                modal.routeToStrategy.saveStorageFacade = self.storageFacade;
                modal.routeToStrategy.hasAutoAttendantChanged = hasAutoAttendantChanged;
                modal.routeToStrategy.subMenuItemPresentationObject = _subMenuItem;
                modal.routeToStrategy.availableObjects = availableObjects;

                self.modalDisplayer.showModal(modal);
                deferredObject.resolve();
            });
        };
        
        self.unassignRoutingRules = () => {
            _showDeletePrompt();
        };
        
        self.activate = () => {
            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const FacadeConstructor = require('presentation/settings/autoAttendant/facades/autoAttendantRoutingRulesFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.defer((deferredObject) => {

                _buildRoutingRules()
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

    };
});
