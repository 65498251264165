define('persistence/dataProviders/autoAttendantVoiceRouteDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_autoattendant_voice_route', 'GET')
                    .done(function(result) {
                            deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(autoAttendantVoiceRouteId) {
            if(autoAttendantVoiceRouteId === null) {
                var err = new Error("Missing autoAttendantVoiceRouteId");
                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_autoattendant_voice_route/' + autoAttendantVoiceRouteId,'GET')
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.autoAttendantVoiceRouteId = autoAttendantVoiceRouteId;
                            deferredObject.reject(error);
                        });
                });
            }
            
        };
    };
});

