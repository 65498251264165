define('common/authentication/employeeAuthenticationStore',['common/storage/machineStorage'], function() {
    var _store = require('common/storage/machineStorage');
    var STORE_NAME = "employeeAuthenticationStore";

    function getEmployeeId() {
        var employeeAuthentication = _store.getObject(STORE_NAME);
        if (employeeAuthentication) {
            return employeeAuthentication.employeeId;
        } else {
            return undefined;
        }
    }

    function getReconnectionToken() {
        var employeeAuthentication = _store.getObject(STORE_NAME);
        if (employeeAuthentication) {
            return employeeAuthentication.reconnectionToken;
        } else {
            return undefined;
        }
    }

    function setEmployeeAuthentication(employeeId, reconnectionToken) {
        var employeeAuthentication = {
            employeeId : employeeId,
            reconnectionToken : reconnectionToken
        };

        _store.saveObject(STORE_NAME, employeeAuthentication);
    }

    function resetEmployeeAuthentication() {
        _store.deleteObject(STORE_NAME);
    }

    return {
        getEmployeeId : getEmployeeId,
        getReconnectionToken : getReconnectionToken,
        setEmployeeAuthentication : setEmployeeAuthentication,
        resetEmployeeAuthentication : resetEmployeeAuthentication
    };
});
