define('presentation/signup/viewModels/signupPaymentMethodViewModel',[
        'businessServices/authentication/stores/signupAuthenticationStore',
        'businessServices/browserSupport/browserType',
        'businessServices/country/countryCode',
        'businessServices/creditCards/creditCardTypes',
        'businessServices/router/router',
        'businessServices/signup/signupSessionStore',
        'common/calendar/monthList',
        'common/converters/phoneNumberFormatter',
        'common/encryption/crypto',
        'common/promises/promiseFactory',
        'common/time/date',
        'constants/creditCardExpirationYearConstants',
        'entities/paymentMethodEntity',
        'presentation/signup/facades/signupPaymentMethodFacade',
        'presentation/signup/validators/signupPaymentMethodValidator',
        'settings/navigationConfiguration',
        'presentation/signup/common/signupNavigationManager',
        'constants/signupSessionFlowConstants',
        'constants/signupSessionStatusConstants'
    ],
    function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const FacadeConstructor = require('presentation/signup/facades/signupPaymentMethodFacade');
            const ValidatorConstructor = require('presentation/signup/validators/signupPaymentMethodValidator');

            const CreditCardTypesConstructor = require('businessServices/creditCards/creditCardTypes');
            const CountryCodeConstructor = require('businessServices/country/countryCode');
            const DateConstructor = require('common/time/date');
            const MonthConstructor = require('common/calendar/monthList');
            const _months = new MonthConstructor().monthList;

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const _countries = new CountryCodeConstructor();
            const _creditCardExpirationYear = require('constants/creditCardExpirationYearConstants');
            const _creditCardTypes = new CreditCardTypesConstructor();

            const _browserType = require('businessServices/browserSupport/browserType');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
            const _signupSessionStatusConstants = require('constants/signupSessionStatusConstants');

            let _facade = null;
            let _validator = null;
            let _disposables = [];
            let _signupNavigationManager = null;
            let _signupSessionStore = null;
            let _startTrialClicked = false;
            let _modalOtherNavigationRouteId = null;
            let _reservationFailedRouteId = null;

            const _onCountryChange = () => {
                self.postalCode("");
                self.postalCode.isValid(null);
            };

            const _currentCountry = (country = null) => {
                if (country){
                    const currentCountry = _countries.findByAbbreviation(country);
                    return self.countries.find(item => item.text === currentCountry.text);
                } else {
                    const currentCountry = _countries.getDefaultCountry();
                    return self.countries.find(item => item.text === currentCountry.text);
                }
            };

            const _currentMonth = (month = null) => {
                if (month){
                    return _months.find(item => item.value === month);
                } else {
                    const currentMonth = new DateConstructor();
                    currentMonth.setToday();
                    return _months.find(item => item.value === currentMonth.monthPadded());
                }
            };

            const _currentYear = (year = null) => {
                if (year){
                    return self.expirationYears.find(item => item.text === year);
                } else {
                    const currentYear = new Date().getFullYear();
                    return self.expirationYears.find(item => item.text === currentYear);
                }
            };

            const _populateCountryDropdown = () => {
                self.countries =  _countries.countryCodes.map((country) => {
                    country.id = country.countryAbbreviation;
                    country.text = country.name;
                    return country;
                });
            };

            const _populateExpirationDateDropdowns = () => {
                self.expirationMonths = _months.map((month) => {
                    month.id = month.value;
                    month.text = month.value;
                    return month;
                });

                const currentYear = new Date().getFullYear();
                self.expirationYears = Array.from(
                    Array(_creditCardExpirationYear.yearsFromCurrent),
                    (_, i) => ({id: currentYear + i, text: currentYear + i})
                );
            };

            const _createFreeTrialEndDate = () => {
                const trialEnd = new DateConstructor();
                trialEnd.setToday();
                trialEnd.addDays(7);
                return `${trialEnd.monthName()} ${trialEnd.dayRank()}`;
            };

            const _saveCardHolderName = (newValue, oldValue) => {
                if (newValue !== oldValue && self.cardHolderName.isValid()) {
                    _signupSessionStore.savePaymentHolderName(self.cardHolderName());
                }
            };
            const _saveExpirationDate = () => {
                if (self.expirationDate.isValid()) {
                    _signupSessionStore.savePaymentExpirationDate(self.expirationMonth()[0], self.expirationYear()[0]);
                }
            };
            const _savePostalCode = (newValue, oldValue) => {
                if (newValue !== oldValue && self.postalCode.isValid()) {
                    _signupSessionStore.savePaymentPostalCode(self.country()[0], self.postalCode());
                    if (self.signupResult() === "invalid_postal_code") {
                        self.signupResult("");
                    }
                }
            };

            self.startTrial = () => _promiseFactory.deferIndefinitely(deferredObject => {
                if (_startTrialClicked) {
                    deferredObject.resolve(true);
                    return;
                }

                _startTrialClicked = true;
                self.signupResult("");
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done(isValid => {
                        if (!isValid) {
                            deferredObject.resolve(true);
                            _startTrialClicked = false;
                            return;
                        }

                        const date = new DateConstructor();

                        const paymentMethod = {
                            cardHolderName: self.cardHolderName(),
                            cardType: _creditCardTypes.getCreditCardType(self.cardNumber()),
                            countryCode: self.country()[0],
                            cardNumber: self.cardNumber(),
                            cvvCode: self.cvvCode(),
                            expirationDate: date.getExpirationDate(self.expirationYear()[0], self.expirationMonth()[0]),
                            postalCode: self.postalCode(),
                        };

                        _facade.isFraudulentPaymentInformation(paymentMethod)
                            .fail(deferredObject.reject)
                            .done((isFraudulent) => {

                                if (isFraudulent) {
                                    _signupNavigationManager.navigateToErrorPage();
                                    deferredObject.resolve({keepSpinning: false});
                                    return;
                                }

                                _facade.createAccount(paymentMethod)
                                    .fail(deferredObject.reject)
                                    .done(status => {
                                        switch (status) {
                                            case "success":
                                                _startTrialClicked = false;
                                                _signupSessionStore.savePaymentIsComplete(true)
                                                    .fail(deferredObject.reject)
                                                    .done(() => {
                                                        _signupSessionStore.saveSignupSessionStatus(_signupSessionStatusConstants.accountCreationInProgress)
                                                            .fail(deferredObject.reject)
                                                            .done(() => {
                                                                deferredObject.resolve({keepSpinning: false});
                                                                _signupNavigationManager.navigateToSuccessPage();
                                                            });
                                                    });
                                                break;
                                            case "credit_card_declined":
                                            case "payment_gateway_down":
                                            case "create_account_failed":
                                            case "invalid_email_address":
                                            case "invalid_postal_code":
                                            case "no_email_address_provided":
                                            case "no_mobile_phone_number_provided":
                                            case "no_mobile_phone_number_country_provided":
                                                self.signupResult(status);
                                                deferredObject.resolve({keepSpinning: false});
                                                break;
                                            case "error_no_phone_numbers":
                                            case "failed_to_reserve_phone_numbers":
                                                _signupSessionStore.get()
                                                    .fail(deferredObject.reject)
                                                    .done(signupDocument => {
                                                        self.modalPhoneNumber(_phoneNumberFormatter.toUSAreaCode(signupDocument.reservedPhoneNumber));
                                                        deferredObject.resolve({keepSpinning: false});
                                                        self.isModalVisible(true);
                                                        _reservationFailedRouteId = _phoneNumberFormatter.isTollFree(signupDocument.reservedPhoneNumber) ?
                                                            _navigationConfiguration.routesById.signupPhoneNumberTollfree.routeId :
                                                            _navigationConfiguration.routesById.signupPhoneNumberLocal.routeId;
                                                    });
                                                break;
                                            case "authorized_signature_required":
                                                deferredObject.resolve({keepSpinning: false});
                                                self.modalOtherText = ko.observable('missingAuthorizedSignature');
                                                self.modalOtherButtonText = ko.observable('modalAuthorizedSignatureButtonText');
                                                _modalOtherNavigationRouteId = _navigationConfiguration.routesById.signupPhoneNumberPortAuthorization.routeId;
                                                self.isModalOtherVisible(true);
                                                break;
                                            case "no_password_provided":
                                                deferredObject.resolve({keepSpinning: false});
                                                self.modalOtherText = ko.observable('missingPassword');
                                                self.modalOtherButtonText = ko.observable('modalPasswordButtonText');
                                                _modalOtherNavigationRouteId = _navigationConfiguration.routesById.signupProfile.routeId;
                                                self.isModalOtherVisible(true);
                                                break;
                                            default:
                                                self.signupResult(status);
                                                deferredObject.resolve({keepSpinning: false});
                                        }
                                        _startTrialClicked = false;
                                    });
                            });
                    });
            });

            self.countries = null;
            self.expirationMonths = null;
            self.expirationYears = null;

            self.cardHolderName = ko.observable("");
            self.cardHolderNameThrottled = ko.computed(self.cardHolderName).extend({throttle: 300});
            self.cardNumber = ko.observable("");
            self.country = ko.observable("");
            self.cvvCode = ko.observable("");
            self.errorNotification = ko.observable();
            self.expirationDate = ko.observable();
            self.expirationMonth = ko.observable("");
            self.expirationYear = ko.observable("");
            self.freeTrialEndDate = _createFreeTrialEndDate();
            self.isCompositionComplete = ko.observable(false);
            self.isNavigateToNextPageActive = ko.observable(true);
            self.isValid = ko.observable(false);
            self.postalCode = ko.observable("");
            self.signupResult = ko.observable("");
            self.startTrialClass = ko.pureComputed(() => self.isValid() ?
                'signup-payment__go' :
                'signup-payment__go--disabled'
            );

            self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
            self.postalCodeMetaData = ko.pureComputed(() => _countries.findByAbbreviation(self.country()[0]).hasProvinces ?
                {i18nKey: 'postalCode', type: 'text', maxLength: 6} :
                {i18nKey: 'zipCode', type: 'tel', maxLength: 5}
            );

            self.isScrollActive = ko.observable(false);

            self.isModalVisible = ko.observable(false);
            self.isXCloseVisible = false;
            self.modalPhoneNumber = ko.observable("");
            self.isModalOtherVisible = ko.observable(false);
            self.modalOtherText = ko.observable('');
            self.modalOtherButtonText = ko.observable('');

            self.modalCloseClick = () => {
                const flowId = _signupNavigationManager.getFlowId();
                switch (flowId) {
                    case _signupSessionFlowConstants.flowA:
                    case _signupSessionFlowConstants.flowB:
                    case _signupSessionFlowConstants.flowD:
                        _signupNavigationManager.navigateToRoute(_reservationFailedRouteId, flowId);
                        break;
                    case _signupSessionFlowConstants.flowC:
                        _signupNavigationManager.navigateToRoute(_reservationFailedRouteId, _signupSessionFlowConstants.flowB);
                        break;
                }
            };

            self.modalOtherCloseClick = () => {
                const flowId = _signupNavigationManager.getFlowId();
                _signupNavigationManager.navigateToRoute(_modalOtherNavigationRouteId, flowId);
            };

            self.validate = function() {
                return _promiseFactory.defer(function(deferredObject) {
                    _validator.validate()
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                });
            };

            self.detached = function() {
                _signupNavigationManager.detached();
                _disposables.forEach(subscription => subscription.dispose());
                _disposables = [];
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = function() {
                const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
                _signupSessionStore = new SignupSessionStoreConstructor();
                _signupSessionStore.init();

                const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
                _signupNavigationManager = new SignupNavigationManagerConstructor();
                _signupNavigationManager.init();

                _facade = new FacadeConstructor();
                _facade.init();

                _validator = new ValidatorConstructor();

                return _initialize();
            };


            const _initialize = () => {
                _validator.registerViewModel(self);

                _promiseFactory.deferIndefinitely(deferredObject => {
                    _populateCountryDropdown();
                    _populateExpirationDateDropdowns();

                    _signupSessionStore.get()
                        .fail(deferredObject.reject)
                        .done(signupDocument => {
                            if (signupDocument.paymentMethodHolderName) {
                                self.cardHolderName(signupDocument.paymentMethodHolderName);
                            }
                            if (signupDocument.paymentMethodPostalCode) {
                                self.postalCode(signupDocument.paymentMethodPostalCode);
                            }
                            if (signupDocument.paymentMethodCountryCode) {
                                self.country([_currentCountry(signupDocument.paymentMethodCountryCode).id]);
                            } else {
                                self.country([_currentCountry().id]);
                            }
                            if (signupDocument.paymentMethodExpirationDate !== "0001-01-01T00:00:00Z") {
                                const date = new DateConstructor();
                                date.setFromDateString(signupDocument.paymentMethodExpirationDate);
                                self.expirationMonth([_currentMonth(date.monthPadded()).id]);
                                self.expirationYear([_currentYear(date.year()).id]);
                            } else {
                                self.expirationMonth([_currentMonth().id]);
                                self.expirationYear([_currentYear().id]);
                            }

                            _disposables.push(
                                self.country.subscribe(_onCountryChange),
                                self.cardHolderNameThrottled.subscribePrevious(_saveCardHolderName),
                                self.expirationMonth.subscribe(_saveExpirationDate),
                                self.expirationYear.subscribe(_saveExpirationDate),
                                self.postalCode.subscribePrevious(_savePostalCode)
                            );

                            deferredObject.resolve();
                        });
                });
                return _promiseFactory.wait();
            };
        };
    });

