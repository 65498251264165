/* eslint-disable no-case-declarations */
define('businessServices/router/plugins/featureToggleEvaluatorRouterPlugin',[
    'businessServices/authentication/featureToggleSettings',
    'settings/navigationConfiguration'
], function (
    /** @type typeof import('businessServices/authentication/featureToggleSettings') */
    featureToggleSettings,
    /** @type typeof import('settings/navigationConfiguration') */
    navigationConfiguration
) {
    /**
     * @param { string } routeId
     * @param { DurandalRouteInstruction } routeInfo
     * @returns { boolean | { routeUrl: string } }
     */
    const checkFeatureToggleSettings = (routeId, routeInfo) => {
        const routeFeatureId = routeInfo.config.customSettings && routeInfo.config.customSettings.featureId;

        if (!routeFeatureId) {
            return true;
        }

        const isFeatureEnabled = featureToggleSettings.getById(routeFeatureId);

        if (isFeatureEnabled) {
            return true;
        }

        const routeSection = routeInfo.config.customSettings.section;
        const errorUrl = _getErrorScreenUrl(routeSection);

        return { routeUrl: errorUrl };
    };

    const _getErrorScreenUrl = (section) => {
        let errorUrl = navigationConfiguration.errorPageUrl;

        if (section) {
            errorUrl = navigationConfiguration.sections[section].url + navigationConfiguration.errorPageUrl;
        }

        return errorUrl;
    };

    return {
        guardRoute: checkFeatureToggleSettings
    };
});

