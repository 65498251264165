define('constants/smsRegistrationFailureEnumerations',[], function() {
    return {
        brandRegistrationFailureCodes: {
            invalidInput : "501",
            duplicateRecord: "503",
            brandLimitExceeded: "512",
            countryRestricted: "529",
            soleProprietorNotEnabled: "534",
            duplicateBrand: "550",
            unsupportedPhoneNumber: "551",
            unsupportedEmailAddress: "553",
            invalidAddress: "554",
            unsupportedAddress: "555",
            invalidBrandName: "556",
            tcrSystemError: "590",
            tempSystemError: "591",
            externalVetUnavailable: "620"
        }
    };
});

