define('presentation/settings/billing/billCycleToDate/viewModels/billCycleToDateViewModel',['businessServices/converters/numberFormatter',
        'common/promises/promiseFactory',
        'common/url/urlFormatter',
        'money',
        'businessServices/converters/currencyFormatter',
        'presentation/settings/billing/billCycleToDate/facades/billCycleToDateFacade',
        'settings/navigationConfiguration'
],
    function() {
        return function() {
            const self = this;

            const NumberFormatterConstructor = require('businessServices/converters/numberFormatter');
            let _numberFormatter = new NumberFormatterConstructor();

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            let _promiseFactory = new PromiseFactoryConstructor();

            const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
            const _currencyFormatter = new CurrencyFormatterConstructor();

            const Money = require('money');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _i18n = require('i18next');
            const _urlFormatter = require('common/url/urlFormatter');

            let _facade = null;

            const DETAILS_GROUP = {
                plan: "plan",
                phoneNumbers: "phoneNumbers",
                startingBalance: "startingBalance",
                taxesAndFees: "taxesAndFees",
                users: "users"
            };

            const TOTALS_GROUP = {
                estimatedBalance: "estimatedBalance",
                pendingPayment: "pendingPayment",
                pastDueBalance: "pastDueBalance"
            };

            const _amountTotal = (details) => {
                return ko.pureComputed(() => {
                    let total = new Money(0);
                    details().forEach((element) => {
                        if (element.includeInTotal !== false) {
                            total = total.plus(element.amount);
                        }
                    });
                    return total.toFixed(2);
                });
            };

            const _buildDetailGroup = (type) => {
                let group = {
                    header: ko.observable(""),
                    details: ko.observableArray([]),
                };
                group.total = _amountTotal(group.details);
                group.count = ko.pureComputed(() => group.details().length);
                group.countDisplay = ko.pureComputed(() => _numberFormatter.formatForDisplay(group.details().length));
                group.header = ko.pureComputed(() => {
                    let options = {
                        count: group.count(),
                        countDisplay: group.countDisplay()
                    };
                    if (type === "startingBalance") {
                        if (self.hasPastDueBalance()) {
                            type = "pastDueBalance";
                        }
                    }
                    return _i18n.t(`billCycleToDate:${type}:header`, options);
                });
                return group;
            };

            const _buildTotalsGroup = (type) => {
                let group = {
                    header: ko.observable(""),
                    tooltip: ko.observable(""),
                    startDate: ko.observable(""),
                    endDate: ko.observable(""),
                    amount: ko.observable(""),
                    processingDateDisplay: ko.observable(null),
                    billingPeriodDisplay: ko.observable(null)
                };
                group.header = ko.pureComputed(() => {
                    return _i18n.t(`billCycleToDate:${type}:totalsHeader`);
                });
                group.tooltip = ko.pureComputed(() => {
                    let options = {
                        processingDateDisplay: group.processingDateDisplay()
                    };
                    return _i18n.t(`billCycleToDate:${type}:tooltip`, options);
                });
                group.processingInfoDisplay = ko.pureComputed(() => {
                    let options = {
                        processingDateDisplay: group.processingDateDisplay()
                    };
                    return _i18n.t(`billCycleToDate:${type}:processingInfoDisplay`, options);
                });
                group.billingPeriodDisplay = ko.pureComputed(() => {
                    let options = {
                        startDate: group.startDate(),
                        endDate: group.endDate()
                    };
                    return _i18n.t(`billCycleToDate:${type}:billingPeriodDisplay`, options);
                });
                return group;
            };

            self.autoCharge = ko.observable(true);
            self.hasCurrentBalance = ko.observable();
            self.hasPastDueBalance = ko.observable();
            self.hasPendingPayment = ko.observable();
            self.packageMinutes = ko.observable();
            self.pendingChargeAmount = ko.observable();

            self.isInFreeTrial = ko.observable(false);
            self.numberOfDaysRemainingInFreeTrial = ko.observable();

            self.defaultPaymentMethod = {
                displayName: ko.observable(""),
                cssClass: ko.observable(""),
                lastFourDigits: ko.observable(""),
                expirationDate: ko.observable(""),
                shortDisplayName: ko.observable("")
            };

            self.displayPlan = ko.observable(false);
            self.plan = _buildDetailGroup(DETAILS_GROUP.plan);
            self.users = _buildDetailGroup(DETAILS_GROUP.users);
            self.phoneNumbers = _buildDetailGroup(DETAILS_GROUP.phoneNumbers);
            self.startingBalance = _buildDetailGroup(DETAILS_GROUP.startingBalance);
            self.taxes = _buildDetailGroup(DETAILS_GROUP.taxesAndFees);

            self.currentCharges = ko.pureComputed(() => {
                let total = new Money(0);
                total = total
                    .plus(self.plan.total())
                    .plus(self.users.total())
                    .plus(self.phoneNumbers.total())
                    .plus(self.taxes.total());
                return total.toFixed(2);
            }).extend({deferred: true});

            self.estimatedBalance = _buildTotalsGroup(TOTALS_GROUP.estimatedBalance);
            self.pendingPayment = _buildTotalsGroup(TOTALS_GROUP.pendingPayment);
            self.pastDueBalance = _buildTotalsGroup(TOTALS_GROUP.pastDueBalance);

            self.autoCloseDateDisplay = ko.observable("");
            self.showAutoCloseWarning = ko.pureComputed(() => {
                return self.autoCloseDateDisplay() !== "";
            });

            self.makeAPaymentUrl = _urlFormatter.buildWorkSessionUrl(_navigationConfiguration.routesById.makeAPayment.url);
            self.changePaymentMethodUrl = _navigationConfiguration.routesById.paymentMethods.url;

            self.showProcessingInfoMethod = ko.pureComputed(() => {
                return self.autoCharge() && self.autoCloseDateDisplay() === "";
            });

            self.showPastDueBalance = ko.pureComputed(() => {
                return self.hasPastDueBalance();
            });

            self.showPendingPayment = ko.pureComputed(() => {
                return self.hasCurrentBalance() && !self.hasPastDueBalance();
            });

            self.freeTrialWarningTitle = ko.pureComputed(() => {
                let options = {
                    count: self.numberOfDaysRemainingInFreeTrial(),
                    freeTrialDaysRemaining: self.numberOfDaysRemainingInFreeTrial()
                };
                return _i18n.t('billCycleToDate:freeTrialWarningTitle', options);
            });

            self.freeTrialWarningMessage = ko.pureComputed(() => {
                let options = {
                    freeTrialEndDate: self.freeTrialEndDate()
                };
                return _i18n.t('billCycleToDate:freeTrialWarningMessage', options);
            });

            self.pastDueBalanceWarningMessage = ko.pureComputed(() => {
                let options = {
                    pastDueBalanceAmount: _currencyFormatter.formatUSDForDisplay(self.hasPastDueBalance() ? self.pastDueBalance.amount() : 0)
                };
                let message = _i18n.t('billCycleToDate:pastDueBalance_part1', options);
                message += '<a class="warning-box__message-link" href="' + self.makeAPaymentUrl + '">';
                message += _i18n.t('billCycleToDate:pastDueBalance_part2') + '</a>';
                message += _i18n.t('billCycleToDate:pastDueBalance_part3');
                return message;
            });

            self.freeTrialEndDate = ko.observable();

            const _getCurrentStatement = () => {
                _facade.getCurrentStatement()
                    .done((currentStatement) => {
                        self.isInFreeTrial(currentStatement.isInFreeTrial);
                        self.freeTrialEndDate(currentStatement.freeTrialEndDate);
                        self.numberOfDaysRemainingInFreeTrial(currentStatement.numberOfDaysRemainingInFreeTrial);

                        self.startingBalance.details(currentStatement.startingBalance);

                        self.estimatedBalance.amount(currentStatement.estimatedCharges);
                        self.estimatedBalance.processingDateDisplay(currentStatement.estimatedChargesProcessingDateDisplay);
                        self.estimatedBalance.startDate(currentStatement.cycleStartDate);
                        self.estimatedBalance.endDate(currentStatement.cycleEndDate);

                        self.defaultPaymentMethod.displayName(currentStatement.defaultPaymentMethod.displayCardNameAbbreviation);
                        self.defaultPaymentMethod.cssClass(currentStatement.defaultPaymentMethod.cssClass);
                        self.defaultPaymentMethod.lastFourDigits(currentStatement.defaultPaymentMethod.lastFourDigits);
                        self.defaultPaymentMethod.expirationDate(currentStatement.defaultPaymentMethod.expirationDate);
                        self.defaultPaymentMethod.shortDisplayName(currentStatement.defaultPaymentMethod.shortDisplayName);

                        self.autoCloseDateDisplay(currentStatement.pastDueBalanceAutoCloseDate);
                        self.autoCharge(!currentStatement.doNotAutoCharge);

                        self.hasPastDueBalance(currentStatement.hasPastDueBalance);
                        self.pastDueBalance.amount(currentStatement.pastDueBalance);
                        self.pastDueBalance.processingDateDisplay(currentStatement.currentBalanceProcessingDateDisplay);
                        self.pastDueBalance.startDate(currentStatement.pastDueBalanceCycleStartDate);
                        self.pastDueBalance.endDate(currentStatement.pastDueBalanceCycleEndDate);

                        self.hasCurrentBalance(currentStatement.hasCurrentBalance);
                        self.pendingPayment.amount(currentStatement.currentBalance);
                        self.pendingPayment.processingDateDisplay(currentStatement.currentBalanceProcessingDateDisplay);
                        self.pendingPayment.startDate(currentStatement.currentBalanceCycleStartDate);
                        self.pendingPayment.endDate(currentStatement.currentBalanceCycleEndDate);

                        self.pendingChargeAmount(currentStatement.currentBalance);

                        self.displayPlan(currentStatement.displayPlan);
                        self.plan.details(currentStatement.plan);
                        self.phoneNumbers.details(currentStatement.phoneNumbers);
                        self.users.details(currentStatement.users);
                        self.taxes.details(currentStatement.taxesFeesSurcharges);
                    });
            };

            self.activate = () => {
                const Facade = require('presentation/settings/billing/billCycleToDate/facades/billCycleToDateFacade');
                _facade = new Facade();
                _facade.init(_promiseFactory);

                return _initialize();
            };

            const _initialize = () => {
                _getCurrentStatement();
                return _promiseFactory.wait();
            };
        };
    });

