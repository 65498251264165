define('presentation/settings/schedules/viewModels/scheduleCommonFunctions',[], function () {

    const _scheduleMapDayLabels = [
        {title: "S"},
        {title: "M"},
        {title: "T"},
        {title: "W"},
        {title: "Th"},
        {title: "F"},
        {title: "S"},
    ];

    const _scheduleMapTimeLabels = ["AM", "PM"].reduce((labels, amPm) => labels.concat([12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(hour => `${hour} ${amPm}`)), []);

    const _getGridCell = (hour, minute) => hour * 4 + Math.trunc(minute / 15);

    return {
        getGridCell:_getGridCell,
        scheduleMapDayLabels:_scheduleMapDayLabels,
        scheduleMapTimeLabels:_scheduleMapTimeLabels,
    };
});
