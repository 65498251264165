define('presentation/contacts/viewModels/contactDetailsViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/router/router',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'i18next',
    'presentation/contacts/facades/contactCardFacade',
    'settings/navigationConfiguration'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    _sessionAccountInfo,
    /** @type typeof import('businessServices/router/router') */
    _router,
    /** @type typeof import('common/collections/collectionSorter') */
    SorterConstructor,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/storage/commonState') */
    CommonState,
    /** @type import('i18next') */
    i18n,
    /** @type typeof import('presentation/contacts/facades/contactCardFacade') */
    ContactCardFacade,
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration
) {
    return function (/** @type { string } */ contactId, /** @type { () => void } */ onCloseDetailsClickedCallback) {

        const self = this;
        const _commonState = CommonState;
        const _sorter = new SorterConstructor();

        /** @type import('common/promises/promiseFactory') */
        const _promiseFactory = new PromiseFactory();

        /** @type import('presentation/contacts/facades/contactCardFacade') */
        let _contactCardFacade = null;

        self.comingSoonTooltip = i18n.t('comingSoon');
        self.noPermissionTooltip = i18n.t("contactDetails:noPermissionUsers");
        self.positionY = ko.observable();

        /** @type { KnockoutObservable<IContactPresentationObject> } */
        self.contact = ko.observable(null);
        self.extension = ko.observable('');

        self.isSystemAdmin = ko.pureComputed(() => _sessionAccountInfo.isSystemAdmin());

        self.detailsListTitle = ko.pureComputed(() => {
            const contact = self.contact();
            if (contact.accountUserId) {
                return i18n.t("contactDetails:membership");
            }
            if (contact.accountUserGroupId) {
                return i18n.t("contactDetails:members");
            }
            return '';
        });

        self.activeUsers = ko.pureComputed(() => {
            return _commonState.users().filter((u) => u.status() === 'active');
        });

        self.activeUserGroups = ko.pureComputed(() => {
            return _commonState.userGroups().filter((g) => g.status() === 'active');
        });

        self.usersOrGroupsList = ko.pureComputed(() => {
            const contact = self.contact();
            const members =  self.contactGroupMembers();

            if (contact.isUser) {
                return self.contactUserGroups();
            }
            if (contact.isUserGroup) {
                const list = self.activeUsers().filter(u => members.includes(u.id));
                _sorter.sort(list, "name", true);
                return list;
            }
            return [];
        });

        self.contactUserGroups = ko.pureComputed(() => {
            const contact = self.contact();
            if (contact.isUser === false) {
                return [];
            }

            const list = self.activeUserGroups().filter(g => g.members().includes(contactId));
            _sorter.sort(list, "name", true);
            return list;
        });

        self.contactGroupMembers = ko.pureComputed(() => {
            const contact = self.contact();
            if (contact.isUserGroup === false) {
                return [];
            }

            const activeGroups = self.activeUserGroups();
            const userGroupId = contact.accountUserGroupId;
            const _commonStateGroup = activeGroups.find(g => g.id === userGroupId);
            return _commonStateGroup.members();
        });

        self.navigateToContact = (/** @type any*/ contact) => {
            const url = `${_navigationConfiguration.routesById.contact.baseUrl}/${contact.id}`;
            _router.navigate(url);
        };

        self.navigateToEdit = () => {
            const contact = self.contact();
            if (contact.accountUserId) {
                _router.navigate(_navigationConfiguration.routesById.editUser.baseUrl + contact.accountUserId);
            }
            if (contact.accountUserGroupId) {
                _router.navigate(_navigationConfiguration.routesById.editUserGroup.baseUrl + contact.accountUserGroupId);
            }
        };

        self.onCloseClicked = () => {
            _contactCardFacade.setOpenStateById(contactId, false);
            onCloseDetailsClickedCallback();
        };

        self.updateScrollPositionY = (/** @type {number}*/ position) => {
            _contactCardFacade.setDetailScrollPositionById(contactId, position);
        };

        self.activate = () => {
            _contactCardFacade = new ContactCardFacade();
            _contactCardFacade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {

            _contactCardFacade.getContactById(contactId)
                .fail(() => {
                    self.contact(null);
                })
                .done((result) => {
                    if (result) {
                        const commonStateItem = _commonState.get(contactId);
                        if (commonStateItem) {
                            self.extension = commonStateItem.extension;
                            self.contact(result);
                        }
                    }
                });

            _contactCardFacade.getDetailScrollPositionById(contactId)
                .then(position => {
                    self.positionY(position);
                });

            return _promiseFactory.wait();
        };
    };
});
