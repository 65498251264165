define('presentation/loginNotifications/viewModels/resendInviteViewModel',[
    'common/converters/passedTimeSpanFormatter',
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/loginNotifications/facades/resendInviteFacade',
], function () {
    return function (onModalCloseCompletePromise, emailAddress) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _onModalCloseCompletePromise = onModalCloseCompletePromise;

        const _resendInvite = () => {
            return _promiseFactory.defer((promise) => {
                _facade.resendInvite(emailAddress)
                    .fail(promise.reject)
                    .done((result) => {
                        const PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
                        const _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();
                        self.inviteExpirationDate(_passedTimeSpanFormatter.formatDateString(result.inviteExpirationDateTime));
                        _onModalCloseCompletePromise.resolve(result);
                        promise.resolve();
                    });
            });
        };

        self.inviteExpirationDate = ko.observable('');

        self.activate = () => {
            const FacadeConstructor = require('presentation/loginNotifications/facades/resendInviteFacade');
            _facade = new FacadeConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _resendInvite();

            return _promiseFactory.wait();
        };
    };
});

