define('presentation/messages/utils/conversationLinksManager',[
], function(
) {
    return function (
        /** @type string */
        conversationId,
        /** @type import('presentation/messages/facades/conversationLinksFacade') */
        conversationLinksFacade,
        /** @type boolean */
        isDraft
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationMessageViewModel')} ConversationMessageViewModel */
        /** @typedef {import('presentation/messages/viewModels/messageLinkPreviewCardViewModel')} MessageLinkPreviewCardViewModel */
        /** @typedef {import('presentation/messages/utils/conversationLinksManager')} ConversationLinksManager */

        /** @type {ConversationLinksManager}*/
        const self = this;

        const PAGE_SIZE = 100;

        /** @type KnockoutObservable<boolean>*/
        const _isLoading = ko.observable();
        const _isInitialized = ko.observable(false);
        const _isGettingNextPage = ko.observable(false);
        const _haveReachedEnd = ko.observable(false);

        /** @type {KnockoutObservableArray<MessageLinkPreviewCardViewModel>} */
        const _linkPreviews = ko.observableArray([]);

        /** @type {KnockoutComputed<number>} */
        const _linkPreviewsCount = ko.pureComputed(() => {
           const linkPreviews = _linkPreviews();
           if (!linkPreviews || !linkPreviews.length) {
               return 0;
           }
           const nonFilteredLinkIds = linkPreviews.map((vm) => vm.smsConversationMessageUrlId());
           return new Set(nonFilteredLinkIds).size;
        });

        //#region private methods
        const _updatedLinkPreviews = (/** @type {MessageLinkPreviewCardViewModel[]}*/urlPreviews, /** @type {boolean}*/newLinksInFront = false) => {
            /** @type {MessageLinkPreviewCardViewModel[]}*/
            const newLinks = [];

            for (let urlPreview of urlPreviews) {
                const linkExists = _linkPreviews().some((lp) => lp.smsConversationMessageUrlId() === urlPreview.smsConversationMessageUrlId());
                if (linkExists) {
                    continue;
                }
                newLinks.push(urlPreview);
            }

            const updatedLinks =  newLinksInFront ? newLinks.concat(_linkPreviews()) : _linkPreviews().concat(newLinks);
            _linkPreviews(updatedLinks);
        };

        const _getNextLinksPage = () => {
            if (_isGettingNextPage() === true || _haveReachedEnd() || isDraft) {
                return;
            }

            _isGettingNextPage(true);

            conversationLinksFacade.getConversationSmsMessageUrlPreviews(conversationId, PAGE_SIZE, _linkPreviewsCount())
                .then((urlPreviews) => {
                    if (!urlPreviews || !urlPreviews.length) {
                        _isGettingNextPage(false);
                        return;
                    }

                    _updatedLinkPreviews(urlPreviews);
                    _isGettingNextPage(false);
                });
        };

        const _onMessage = ({smsConversationId}) => {
            if (smsConversationId !== conversationId || isDraft) {
                return;
            }

            conversationLinksFacade.getConversationSmsMessageUrlPreviews(conversationId, PAGE_SIZE, 0)
                .then((urlPreviews) => {
                    if (!urlPreviews) {
                        return;
                    }

                    _updatedLinkPreviews(urlPreviews, true);
                });
        };
        //#endregion

        self.messageLinkPreviews = ko.pureComputed(() => {
            return _linkPreviews();
        });

        self.isLoading = ko.pureComputed(() => {
            return _isLoading();
        });

        /** @type ConversationLinksManager["updateDetailsLinkPreviews"]*/
        self.updateDetailsLinkPreviews = (messageUrlPreview) => {
            const linkPreviewToUpdate = _linkPreviews().find((lp) => lp.smsConversationMessageUrlId() === messageUrlPreview.smsConversationMessageUrlId);
            if (linkPreviewToUpdate) {
                linkPreviewToUpdate.activate(messageUrlPreview);
                return;
            }
            _updatedLinkPreviews([conversationLinksFacade.createLinkPreviewViewModel(messageUrlPreview)]);
        };

        /** @type ConversationLinksManager["getNextPage"]*/
        self.getNextPage = () => {
            _getNextLinksPage();
        };

        /** @type ConversationLinksManager["dispose"]*/
        self.dispose = () => {
            conversationLinksFacade.dispose();
        };

        /** @type ConversationLinksManager["init"]*/
        self.init = () => {
            if (_isInitialized() === true || isDraft) {
                return;
            }

            _isLoading(true);

            conversationLinksFacade.getConversationSmsMessageUrlPreviews(conversationId, PAGE_SIZE, 0)
                .then((urlPreviews) => {
                    if (!urlPreviews) {
                        _isLoading(false);
                        _isInitialized(true);
                        return;
                    }

                    if (urlPreviews.length < PAGE_SIZE) {
                        _haveReachedEnd(true);
                    }

                    _linkPreviews(urlPreviews);
                    _isLoading(false);
                    _isInitialized(true);
                });

            conversationLinksFacade.onSmsConversationMessageForUrlPreviews(_onMessage);
        };
    };
});
