define('presentation/common/actionModal/viewModels/deletePaymentMethodViewModel',[
  'common/promises/promiseFactory',
], function() {
  return function(paymentMethod) {
    const _commonState = require('common/storage/commonState');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();
    const _i18n = require('i18next');
    const self = this;

    self.paymentMethod = paymentMethod;
    self.objectName = _commonState.typeDisplay.paymentMethod.toLowerCase();
    self.displayCardNumber = `${_i18n.t('paymentMethod:endingIn')} ${paymentMethod.lastFourDigits}`;
    self.activate = () => {
      return _promiseFactory.wait();
    };
  };
});
