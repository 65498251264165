define('constants/smsConversationMessageUrlPreviewConversionStatus',[],() => {
    return Object.freeze({
        // Url awaiting conversion, set on creation
        Pending: 0,
        // Url + images successfully converted
        Converted: 1,
        // Url wasn't able to be converted
        ConversionError: 2,
        // Url converted & preview image conversion needed & requested
        ConversionRequested: 3
    });
});
