define('businessServices/numberProvisioning/phoneNumberMetaResult',[],function() {
    return function(){
        var self = this;
        
        self.rateCenter = null;
        self.city = null;
        self.state = null;
        self.lataCode = null;
        self.orderCreateDate = null;
        
    };
});

