define('presentation/common/svgIcon/svgIcon',['common/presentation/iconNames'],
    function() {
        const SPRITE_PATH = "/assets/img/iconSprite.svg";
        const DEFAULT_VIEWBOX = "0 0 100 100";
        const SVG_ICON_CLASS = "_svg-icon";

        const _iconNames = require('common/presentation/iconNames');
        /** @type {Promise<SVGSVGElement>} */
        const _spriteReadPromise = fetch(SPRITE_PATH)
            .then((response) => {
                return response.text();
            })
            .then((svgText) => {
                const domParser = new DOMParser();
                const parsed = domParser.parseFromString(svgText, 'text/html');
                return parsed.getElementsByTagName("svg")[0];
            });

        /**
         * @param {() => SvgIconOptions} valueAccessor 
         * @returns {SvgIconParsedOptions}
         */
        const _parseOptions = (valueAccessor) => {
            const options = valueAccessor();

            return {
                iconName: ko.unwrap(options.iconName),
                useCss: options.useCss,
                width: ko.unwrap(options.width),
                height: ko.unwrap(options.height),
                shouldScale: ko.unwrap(options.shouldScale)
            };
        };

        /**
         * 
         * @param {SVGSVGElement} masterSvg 
         * @param {string} iconClass 
         * @param {string} className 
         * @returns {[svgElement: SVGSVGElement, viewBox: string]}
         */
        const _cloneSvg = (masterSvg, iconClass, className) => {
            const symbol = masterSvg.getElementById(iconClass);
            const viewBox = symbol.getAttribute("viewBox") || DEFAULT_VIEWBOX;

            const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svgElement.setAttribute("viewBox", viewBox);
            svgElement.classList.add(SVG_ICON_CLASS);
            svgElement.classList.add(className);

            symbol.childNodes.forEach(child => {
                svgElement.appendChild(child.cloneNode(true));
            });

            return [svgElement, viewBox];
        };

        /**
         * @param {HTMLElement} element 
         * @param {() => SvgIconOptions} valueAccessor
         */
        const _renderSvg = (element, valueAccessor) => {
            const options = _parseOptions(valueAccessor);

            _spriteReadPromise.then((masterSvg) => {
                /** @type {string} */
                let iconClass;
                if (options.iconName) {
                    iconClass = _iconNames[ko.unwrap(options.iconName)];
                } else {
                    iconClass = element.getAttribute("class");
                }

                let svgElement = element.getElementsByTagName("svg")[0];
    
                if (!iconClass) {
                    if (svgElement) {
                        svgElement.remove();
                    }
                    return;
                }

                const className = `${SVG_ICON_CLASS}__${iconClass}`;
                const hasSvg = svgElement && svgElement.classList.contains(className);
                let viewBox;

                if (!hasSvg) {
                    [svgElement, viewBox] = _cloneSvg(masterSvg, iconClass, className);
                }
                else {
                    viewBox = svgElement.getAttribute("viewBox") || DEFAULT_VIEWBOX;
                }

                if (!options.useCss) {
                    svgElement.setAttribute('height', '100%');
    
                    const viewBoxAttributes = viewBox.split(' ');
                    const originalWidth = Number(viewBoxAttributes[2]);
                    const originalHeight = Number(viewBoxAttributes[3]);
                    let requestedWidth = originalWidth;
                    let requestedHeight = originalHeight;
                    let formattedWidth = "";
                    let formattedHeight = "";
    
                    const optionWidth = options.width;
                    const optionHeight = options.height;
    
                    if (optionWidth && optionHeight) {
                        requestedWidth = optionWidth;
                        requestedHeight = optionHeight;
                    } else if (optionWidth) {
                        requestedWidth = optionWidth;
                        requestedHeight = (originalHeight / originalWidth) * requestedWidth;
                    } else if (optionHeight) {
                        requestedHeight = optionHeight;
                        requestedWidth = (originalWidth / originalHeight) * requestedHeight;
                    }
    
                    const shouldScale = options.shouldScale || false;
    
                    if (shouldScale === false) {
                        formattedWidth = requestedWidth + "px";
                        formattedHeight = requestedHeight + "px";
                    } else {
                        formattedWidth = (requestedWidth / 16) + "rem";
                        formattedHeight = (requestedHeight / 16) + "rem";
                    }
                    svgElement.style.width = formattedWidth;
                    svgElement.style.height = formattedHeight;
                }
                else if (hasSvg) {
                    svgElement.removeAttribute('height');
                }
    
                element.replaceChildren(svgElement);
            });
        };

        return {
            /** @type {KnockoutBindingHandler<HTMLElement, SvgIconOptions>["init"]} */
            init: _renderSvg,
            /** @type {KnockoutBindingHandler<HTMLElement, SvgIconOptions>["update"]} */
            update: _renderSvg
        };
    }
);

