define('presentation/common/actionModal/viewModels/deleteAutoAttendantActionViewModel',[
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'presentation/common/actionModal/presentationObjects/deleteAutoAttendantPresentationObject',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel'
], function() {
    return function(autoAttendant, availableAutoAttendants, availableSchedules, availableVoicePrompts) {
        const self = this;

        const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');
        const AutoAttendantPresentationObjectConstructor = require('presentation/common/actionModal/presentationObjects/deleteAutoAttendantPresentationObject');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _commonState = require('common/storage/commonState');

        self.autoAttendant = ko.observable();
        self.objectType = _commonState.typeDisplay.autoAttendant.toLowerCase();

        self.buildDeleteAutoAttendant = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const autoAttendantToDelete = new AutoAttendantPresentationObjectConstructor();
                autoAttendantToDelete.name(autoAttendant.name());
                autoAttendantToDelete.accountAutoAttendantId = autoAttendant.accountAutoAttendantId;
                autoAttendantToDelete.accountVoicePromptId = autoAttendant.accountVoicePromptId;
                autoAttendantToDelete.systemAudioClipId = autoAttendant.systemAudioClipId;
                autoAttendantToDelete.routingRule = autoAttendant.routingRule;
                autoAttendantToDelete.createdDateTime = autoAttendant.createdDateTime;
                autoAttendantToDelete.modifiedDateTime = autoAttendant.updatedDateTime;
                autoAttendantToDelete.editAutoAttendantUrl = autoAttendant.editAutoAttendantUrl;
                autoAttendantToDelete.collapsedAutoAttendantMenuLabel(autoAttendant.collapsedAutoAttendantMenuLabel());
                autoAttendantToDelete.collapsedMemberIds(autoAttendant.collapsedMemberIds());

                let autoAttendantConstructorPromise = new PromiseFactoryConstructor();
                autoAttendantConstructorPromise.deferIndefinitely((promise) => {
                    let autoAttendantsInCurrentCallFlow = [autoAttendant.accountAutoAttendantId];
                    let autoAttendantCallFlow = new AutoAttendantCallFlowConstructor(autoAttendant.accountAutoAttendantId, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                    autoAttendantCallFlow.isAutoAttendantPromptCollapsible(true);
                    autoAttendantCallFlow.toggleAutoAttendantCallFlow = self.toggleAutoAttendantCallFlow;
                    autoAttendantCallFlow.activate();

                    autoAttendantToDelete.autoAttendantCallFlowViewModel(autoAttendantCallFlow);
                    promise.resolve();
                });

                autoAttendantConstructorPromise.wait()
                    .fail(deferredObject.reject)
                    .done(() => {
                        deferredObject.resolve(autoAttendantToDelete);
                    });
            });
        };


        self.toggleAutoAttendantCallFlow = () => {
            self.autoAttendant().showCallFlow(!self.autoAttendant().showCallFlow());
        };


        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            self.buildDeleteAutoAttendant()
                .done((autoAttendantToDelete) => {
                    self.autoAttendant(autoAttendantToDelete);
                });

            return _promiseFactory.wait();
        };
    };
});
