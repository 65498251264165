define('presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel',['common/promises/promiseFactory',
    'businessServices/state/modelStateObserver',
    'presentation/common/subscriberSelection/validators/subscriberSelectionValidator',
    'common/collections/collectionSorter',
    'common/storage/commonState',
    'common/time/date',
    'moment-timezone'
],function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DateTimeFormatterConstructor = require('common/time/datetimeFormatter');
        const DateUtilConstructor = require('common/time/date');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const SorterConstructor = require('common/collections/collectionSorter');
        const commonState = require('common/storage/commonState');

        const _i18n = require('i18next');

        let _readOnlyProperty = "readOnly";
        let _removeItemToolTipProperty = "removeToolTipText";
        let _subscriptions = [];
        let _validator;

        const ALL_MEMBERS_SUBSCRIBER = {
            avatar: {},
            avatarType: commonState.types.userGroup,
            displayName: _i18n.t("subscriberSelection:allMembers"),
            iconProperty: commonState.types.userGroup,
            id: "All Members",
            subscriberId: "All Members",
            subscriberType: commonState.types.userGroup,
        };

        const SUBSCRIBER_LOG_ACTIONS = {
            added: 1,
            removed: 2,
            enabled: 3,
            disabled: 4,
        };

        const SUBSCRIBER_LOG_ACTION_DIRECTION = {
            inbound: 1,
            outbound: 2
        };

        const _trackSelectedValue = (selectedValueItem) => {
            let selectedItemObservable = null;
            if (!ko.isObservable(selectedValueItem)) {
                selectedItemObservable = ko.observable(selectedValueItem);
            } else {
                selectedItemObservable = selectedValueItem;
            }
            selectedItemObservable.extend({observeState: true});
            return selectedItemObservable;
        };

        self.isAtLeastOneItemRequired = false;
        self.modelStateObserver = null;
        self.displayValues = ko.observableArray([]).extend({observeState: true});
        self.displayProperty = 'displayName';
        self.avatarProperty = 'avatar';
        self.avatarTypeProperty = 'subscriberType';
        self.newItemConstructorProperty = 'newSubscriberConstructor';
        self.isEnabled = ko.observable(true);
        self.isValid = ko.observable(true);
        self.canAddAnother = ko.observable(true);
        self.showMembershipControl = ko.observable(true);
        self.addItemTitle = ko.observable(_i18n.t('subscriberSelection:addItemTitle'));
        self.removeItemTitle = ko.observable("");
        self.lastItemRemoveTitle = ko.observable(_i18n.t('subscriberSelection:lastItemRemoveTitle'));
        self.membershipDescription = ko.observable("");
        self.subscriberLogPermissionTitle = ko.observable(_i18n.t("subscriberSelection:subscriberLogPermissionTitle"));
        self.subscriberLogTitle = ko.observable(_i18n.t("subscriberSelection:subscriberLogTitle"));
        self.addAnotherMessage = _i18n.t('subscriberSelection:addAnotherMessage');
        self.unavailableExtensions = null;
        self.showMembershipDescription = ko.computed(() => self.membershipDescription() !== "");

        self.availableSubscribers = ko.pureComputed(() => {
            const users = commonState.users().reduce((accumulator, user) => {
                if (user.isActive()) {
                    accumulator.push({
                        id: user.id,
                        subscriberType: user.type,
                        displayName: user.name(),
                        avatar: user.avatar()
                    });
                }
                return accumulator;
            }, []);
            const userGroups = commonState.userGroups().reduce((accumulator, userGroup) => {
                if (userGroup.isActive()) {
                    accumulator.push({
                        id: commonState.resolveGroupIdToGuid(userGroup.id),
                        subscriberType: userGroup.type,
                        displayName: userGroup.name(),
                        avatar: userGroup.avatars()
                    });
                }
                return accumulator;
            }, []);
            const sorter = new SorterConstructor();
            let combinedResults = users.concat(userGroups);
            sorter.sort(combinedResults, "displayName", true);
            return combinedResults;
        });
        self.availableOptions = ko.computed(() => {
            let selectedValues = self.displayValues();
            return self.availableSubscribers().filter((availableSubscriber) => {
                let availableSubscriberId = ko.unwrap(availableSubscriber).id;
                return undefined === selectedValues.find((selectedValuesItem) => {
                    return availableSubscriberId === ko.unwrap(selectedValuesItem).id;
                });
            });
        });
        self.addAnotherIsDisabled = ko.pureComputed(() => {
            const allowAddAnother = self.isEnabled() &&
                self.availableOptions().length > 0;
            return !allowAddAnother;
        });

        self.removeItemToolTip = (item) => {
            return {
                'container': "body",
                'position': "top",
                'text': ko.computed(() => {
                    if (_removeItemToolTipProperty !== "" && item[_removeItemToolTipProperty]) {
                        return item[_removeItemToolTipProperty];
                    } else if (self.displayValues().length === 1) {
                        return self.lastItemRemoveTitle();
                    } else {
                        return self.removeItemTitle();
                    }
                })
            };
        };

        self.itemIsDisabled = (item) => {
            return ko.computed(() => {
                if (self.isEnabled() === false) {
                    return true;
                }
                
                if (_readOnlyProperty !== "") {
                    return ko.unwrap(item[_readOnlyProperty]);
                }

                return false;
            });
        };

        self.showDisabledDeleteIcon = (item) => {
            return ko.computed(() => {
                if (self.displayValues().length === 1) {
                    return true;
                }

                if (self.isEnabled() === false) {
                    return true;
                }

                if (_readOnlyProperty !== "") {
                    return item[_readOnlyProperty];
                } else {
                    return false;
                }
            });
        };

        self.deleteSelectedValue = (selectedValueToDelete) => {
            if (self.isEnabled() && self.displayValues().length > 1) {
                if (_readOnlyProperty !== "") {
                    if (selectedValueToDelete()[_readOnlyProperty] === false ||
                        selectedValueToDelete()[_readOnlyProperty] === undefined) {
                        self.displayValues.remove(selectedValueToDelete);
                    }
                } else {
                    self.displayValues.remove(selectedValueToDelete);
                }
            }
        };

        self.addSubscriber = () => {
            self.displayValues.push(_trackSelectedValue(self.availableOptions()[0]));
        };

        self.validate = () => {
            return _promiseFactory.defer((promise) => {
                _promiseFactory.deferredList(self.displayValues(), () => {
                    return _promiseFactory.defer((validatePromise) => {
                        validatePromise.resolve(true);
                    });
                })
                    .fail(promise.reject)
                    .done(() => {
                        _validator.validate()
                            .done((isSubscriberSelectionValid) => {
                                self.isValid(isSubscriberSelectionValid);
                                promise.resolve(isSubscriberSelectionValid);
                            });
                    });
            });
        };

        self.getSubscribers = () => {
            return _promiseFactory.defer((promise) => {
                let subscriberResults = {
                    subscribers: []
                };
                let getSubscribersPromiseFactory = new PromiseFactoryConstructor();
                for (let subscribersIndex = 0; subscribersIndex < self.displayValues().length; subscribersIndex++) {
                    getSubscribersPromiseFactory.defer((getSubscribersPromise) => {
                        let currentSubscriber = self.displayValues()[subscribersIndex]();
                        let convertedSubscriber = Object.assign({}, currentSubscriber, {
                            userId: currentSubscriber.subscriberType === commonState.types.user ? currentSubscriber.id : null,
                            userGroupId: currentSubscriber.subscriberType === commonState.types.userGroup ? currentSubscriber.id : null
                        });
                        subscriberResults.subscribers.push(convertedSubscriber);
                        getSubscribersPromise.resolve();
                    });
                }
                getSubscribersPromiseFactory.wait()
                    .fail(promise.reject)
                    .done(() => {
                        promise.resolve(subscriberResults);
                    });
            });
        };

        self.reloadSubscribers = (selectedSubscribers) => {
            return _promiseFactory.defer((reloadPromise) => {
                _subscriptions.forEach((currentSubscription) => {
                    currentSubscription.dispose();
                });

                _subscriptions = [];

                let trackedSubscribers = selectedSubscribers.map(selectedValueItem => {
                    return _trackSelectedValue(selectedValueItem);
                });
                self.displayValues(trackedSubscribers);
                reloadPromise.resolve();
            });
        };

        let _subscriberLogEntries = null;
        self.setSubscribersLog = (subscriberLogEntries) => {
            if (!subscriberLogEntries) {
                return;
            }
            if (subscriberLogEntries.length === 0) {
                return;
            }

            const CollectionSorterConstructor = require('common/collections/collectionSorter');
            let collectionSorter = new CollectionSorterConstructor();

            _subscriberLogEntries = subscriberLogEntries.slice();
            collectionSorter.sort(_subscriberLogEntries, "actionDateTime", false);
            _populateSubscriberLog(5);

            if (_subscriberLogEntries.length > 5) {
                self.allowToggleFullSubscriberLog(true);
            } else {
                self.allowToggleFullSubscriberLog(false);
            }

            self.hasSubscriberLogHistory(true);
        };

        const _populateSubscriberLog = (countOfItemsToDisplay) => {
            let count = Math.min(countOfItemsToDisplay, _subscriberLogEntries.length);
            let logLines = [];

            for (let x = 0; x < count; x++) {
                let entry = _subscriberLogEntries[x];

                logLines.push({
                    modifiedName : _getLogModifiedName(entry),
                    action: _getLogAction(entry),
                    modifiedByName: _getLogModifiedByName(entry),
                    actionDate: _getLogActionDate(entry),
                });
            }
            self.subscriberLogLines(logLines);
        };

        const _getLogModifiedName = (logEntry) => {
            let modifiedEntity = null;

            if ((logEntry.action === SUBSCRIBER_LOG_ACTIONS.enabled) ||
                (logEntry.action === SUBSCRIBER_LOG_ACTIONS.disabled)) {

                if (logEntry.hasOwnProperty('permissionType')) {
                    let type = logEntry.permissionType === SUBSCRIBER_LOG_ACTION_DIRECTION.inbound ?
                        _i18n.t("subscriberSelection:inbound") :
                        _i18n.t("subscriberSelection:outbound");

                    return `${type} ${self.subscriberLogPermissionTitle()}`;
                }

                return self.subscriberLogPermissionTitle();
            }

            if (logEntry.modifiedUserId) {
                modifiedEntity = commonState.get(logEntry.modifiedUserId);
            } else if (logEntry.modifiedUserGroupId) {
                modifiedEntity = commonState.get(logEntry.modifiedUserGroupId);
            }

            if (modifiedEntity) {
                return modifiedEntity.name;
            }

            return _i18n.t("unknown");
        };

        const _getLogAction = (logEntry) => {
            switch (logEntry.action) {
                case SUBSCRIBER_LOG_ACTIONS.added:
                    return _i18n.t("added");
                case SUBSCRIBER_LOG_ACTIONS.removed:
                    return _i18n.t("removed");
                case SUBSCRIBER_LOG_ACTIONS.enabled:
                    return _i18n.t("enabled");
                case SUBSCRIBER_LOG_ACTIONS.disabled:
                    return _i18n.t("disabled");
                default:
                    return _i18n.t("unknown");
            }
        };

        const _getLogModifiedByName = (logEntry) => {
            if (logEntry.isModifiedBySystem) {
                return _i18n.t("subscriberSelection:systemProcess");
            }
            if (logEntry.isModifiedByCustomerService) {
                return _i18n.t("subscriberSelection:customerServiceAgent");
            } else if (logEntry.modifiedByUserId) {
                const modifiedByEntity = commonState.get(logEntry.modifiedByUserId);
                if (modifiedByEntity) {
                    return modifiedByEntity.name;
                }
            }

            return _i18n.t("unknown");
        };

        const _getLogActionDate = (logEntry) => {
            let dateUtil = new DateUtilConstructor();
            let dateTimeFormatter = new DateTimeFormatterConstructor();

            let today = new Date();
            let todayUtil = new DateUtilConstructor();
            todayUtil.setFromDate(today);
            todayUtil.beginningOfDay();

            let yesterday = new Date();
            let yesterdayUtil = new DateUtilConstructor();
            yesterdayUtil.setFromDate(yesterday);
            yesterdayUtil.beginningOfDay();
            yesterdayUtil.addDays(-1);

            let actionDate = "";
            dateUtil.setFromDateString(logEntry.actionDateTime);
            if (dateUtil.isOnSameDay(todayUtil)) {
                actionDate = actionDate + `${_i18n.t("today")} `;
            } else if (dateUtil.isOnSameDay(yesterdayUtil)){
                actionDate = actionDate + `${_i18n.t("yesterday")} `;
            } else {
                actionDate = actionDate + `${_i18n.t("on")} ` + dateTimeFormatter.format(dateUtil.rawDate(), "MMMM DD") + " ";
            }

            return actionDate + `${_i18n.t("at")} ` + dateTimeFormatter.format(dateUtil.rawDate(), "h:nn tt");
        };

        const _buildSubscribers = (selectedValues) => {
            return selectedValues.map(selectedValueItem => {
                if (selectedValueItem.subscriberId === ALL_MEMBERS_SUBSCRIBER.subscriberId) {
                    return _trackSelectedValue(ALL_MEMBERS_SUBSCRIBER);
                }
                let commonStateSubscriber = commonState.get(selectedValueItem.id);
                let simpleSubscriber = {
                    id: commonStateSubscriber.type === commonState.types.user ? commonStateSubscriber.id : commonState.resolveGroupIdToGuid(commonStateSubscriber.id),
                    subscriberType: commonStateSubscriber.type,
                    displayName: commonStateSubscriber.name(),
                    avatar: commonStateSubscriber.type === commonState.types.user ? commonStateSubscriber.avatar() : commonStateSubscriber.avatars(),
                    readOnly: selectedValueItem.readOnly,
                    removeToolTipText: selectedValueItem.removeToolTipText
                };
                return _trackSelectedValue(simpleSubscriber);
            });
        };

        self.toggleFullSubscriberLog = () => {
            self.allowToggleFullSubscriberLog(false);
            _populateSubscriberLog(_subscriberLogEntries.length);
        };
        self.allowToggleFullSubscriberLog = ko.observable(false);
        self.hasSubscriberLogHistory = ko.observable(false);
        self.subscriberLogLines = ko.observable([]);

        self.activate = (selectedValues, isEnabled = true, showMembershipControl = true) => {
            return _initialize(selectedValues, isEnabled, showMembershipControl);
        };

        const _initialize = (selectedValues, isEnabled, showMembershipControl) => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            let trackedSubscribers = _buildSubscribers(selectedValues);
            self.displayValues(trackedSubscribers);

            if (ko.isObservable(isEnabled)) {
                self.isEnabled = isEnabled;
            } else {
                self.isEnabled(isEnabled);
            }

            if (ko.isObservable(showMembershipControl)) {
                self.showMembershipControl = showMembershipControl;
            } else {
                self.showMembershipControl(showMembershipControl);
            }

            const ValidatorConstructor = require('presentation/common/subscriberSelection/validators/subscriberSelectionValidator');
            _validator = new ValidatorConstructor();
            _validator.registerViewModel(self);

            return _promiseFactory.wait();
        };
    };
});

