define('presentation/signup/facades/signupPaymentMethodFacade',[
        'businessServices/authentication/stores/signupAuthenticationStore',
        'businessServices/creditCards/creditCardTypes',
        'common/encryption/crypto',
        'common/promises/promiseFactory',
        'persistence/repositories/signupRepository',
    ],
    function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _signupAuthenticationStore = null;
        let _signupRepository = null;
        let _signupSessionStore = null;

        const CreditCardFormatterConstructor = require('businessServices/creditCards/creditCardTypes');
        const _creditCardFormatter = new CreditCardFormatterConstructor();

        const CryptoConstructor = require('common/encryption/crypto');
        const _crypto = new CryptoConstructor();

        const _isFraudulentPaymentInformation = (paymentMethod) => {
            const unformattedCardNumber = _creditCardFormatter.unformatCreditCard(paymentMethod.cardNumber);
            const firstSixDigits = unformattedCardNumber.slice(0,6);
            const cardNumberHashed = _crypto.createSha256Hash(paymentMethod.cardNumber);
            return _signupSessionStore.isFraudulentPaymentInformation(paymentMethod.cardHolderName,
                cardNumberHashed,
                firstSixDigits,
                paymentMethod.expirationDate,
                paymentMethod.countryCode,
                paymentMethod.postalCode);
        };

        const _createAccount = (paymentMethod) => _promiseFactory.deferIndefinitely(deferredObject => {
            _signupAuthenticationStore.load()
                .fail(deferredObject.reject)
                .done((signupSessionId, accountNumber, secretKey) => {
                    _signupSessionStore.getState()
                        .fail(deferredObject.reject)
                        .done(signupSession => {
                            const signupData = {
                                accountNumber: accountNumber,
                                authorizedSignature: signupSession.authorizedSignature,
                                cardCvv: paymentMethod.cvvCode,
                                cardExpirationDate: paymentMethod.expirationDate,
                                cardHolderName: paymentMethod.cardHolderName,
                                cardNumber: _creditCardFormatter.unformatCreditCard(paymentMethod.cardNumber),
                                cardCountryCode: paymentMethod.countryCode,
                                cardPostalCode: paymentMethod.postalCode,
                                cardType: paymentMethod.cardType,
                                emailAddress: signupSession.signupDocument.emailAddress,
                                password: signupSession.password,
                                secretKey: secretKey,
                                signupSessionId: signupSessionId
                            };

                            _signupRepository.createAccount(signupData)
                                .fail(deferredObject.reject)
                                .done((response) => {
                                    if (!response) {
                                        deferredObject.reject(response);
                                    } else {
                                        deferredObject.resolve(response.status);
                                    }
                                });
                        });
                });
        });

        const _init = () => {
            const SignupRepository = require('persistence/repositories/signupRepository');
            _signupRepository = new SignupRepository();
            _signupRepository.init();

            const SignupAuthenticationStoreConstructor = require('businessServices/authentication/stores/signupAuthenticationStore');
            _signupAuthenticationStore = new SignupAuthenticationStoreConstructor();
            _signupAuthenticationStore.init();

            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();
        };


        return function() {
            let self = this;

            self.init = _init;
            self.createAccount = _createAccount;
            self.isFraudulentPaymentInformation = _isFraudulentPaymentInformation;
        };
    })
;

