define('externalDependencies/clientWebSocket',['common/uniqueId/uniqueIdGenerator',
        'externalDependencies/configuration/webSocketConfiguration',
        'settings/settings',
        'common/logging/logger',
        'socketio',
        'common/promises/waitHandle',
        'externalDependencies/configuration/clientApiConfiguration',
        'businessServices/authentication/sessionAccountInfo',
    ],
    function() {
        const clientConfiguration = require('externalDependencies/configuration/clientApiConfiguration');
        const apiUrl = clientConfiguration.apiUrl;
        let webSocketUrl = apiUrl.replaceAll("https://", "wss://").replaceAll("http://", "ws://");
        webSocketUrl = webSocketUrl + "ws/";
        
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        const CommandIdGeneratorConstructor = require('common/uniqueId/uniqueIdGenerator');
        const _commandIdGenerator = new CommandIdGeneratorConstructor();

        const LoggerConstructor = require('common/logging/logger');
        const _logger = new LoggerConstructor();
        _logger.init();

        const WaitHandleConstructor = require('common/promises/waitHandle');
        const _connectionWaitHandle = new WaitHandleConstructor();
        _connectionWaitHandle.setRedLight();

        const _authenticatedWaitHandle = new WaitHandleConstructor();
        _authenticatedWaitHandle.setRedLight();

        let _connectCallbacks = [];
        let _reconnectCallbacks = [];
        let _webSocketEventHandler = null;
        let commandListeners = [];

        const createEventManager = () => {
            let _subscriptions = [];

            const subscribe = (applicationName, eventName, callback) => {
                const subscriptionId = _commandIdGenerator.generateUniqueIdWithoutHyphens();
                const actualApplicationName = applicationName.toLowerCase();
                const actualEventName = eventName.toLowerCase();

                _subscriptions.push(
                    {
                        applicationName : actualApplicationName,
                        eventName : actualEventName,
                        callback,
                        subscriptionId,
                    }
                );

                return subscriptionId;
            };

            const unsubscribe = (subscriptionId) => {
                const subscriptionIndex = _subscriptions.findIndex((subscription) => {
                    return (subscription.subscriptionId === subscriptionId);
                });
                if (subscriptionIndex === -1) {
                    return;
                }
                _subscriptions.splice(subscriptionIndex, 1);
            };

            const sendEvent = (applicationName, eventName, eventData) => {
                const actualApplicationName = applicationName.toLowerCase();
                const actualEventName = eventName.toLowerCase();
                _subscriptions.forEach((subscription) => {
                    if ((subscription.applicationName === actualApplicationName) && (subscription.eventName === actualEventName))
                    {
                        try {
                            subscription.callback(eventData);
                        } catch (ex) {
                            _logger.logError(ex);
                        }
                    }
                });
            };

            return {
                subscribe,
                unsubscribe,
                sendEvent,
            };
        };
        const _eventManager = createEventManager();

        const _onWebSocketFrameReceived = (event) => {
            let data = JSON.parse(event.data);
            if ((event.type === "message") && (data.status !== "event")) {
                _onWebSocketMessage(data);
            } else {
                _onWebSocketEvent(data);
            }
        };
        const _onWebSocketMessage = (data) => {
            if (typeof commandListeners[data.commandId].callback === "function") {
                if (data.status === "success") {
                    commandListeners[data.commandId].callback(data.result, null);
                } else {
                    commandListeners[data.commandId].callback(null, data.errorMessage);
                }
            } else {
                let error = new Error("Listener callback is not a function.");
                error.data = data;
                error.listener = commandListeners[data.commandId];
                _logger.logError(error);
            }
            delete commandListeners[data.commandId];
        };
        const _onWebSocketEvent = (data) => {
            const applicationName = data.applicationName;
            const eventName = data.eventName;
            const eventData = data.eventData;

            _eventManager.sendEvent(applicationName, eventName, eventData);
        };
        const _onConnected = () => {
            _connectCallbacks.forEach((callback) => {
                callback();
            });
            _connectionWaitHandle.setGreenLight();
        };
        const _onDisconnected = () => {
            _connectionWaitHandle.setRedLight();
            _authenticatedWaitHandle.setRedLight();
            setTimeout(() => {
                _rebuildCurrentSocket();
            }, 2000);
        };
        const _onReconnected = () => {
            _reconnectCallbacks.forEach((callback) => {
                callback();
            });
            _connectionWaitHandle.setGreenLight();
        };
        const _onError = (err) => {
            _logger.logError(err);
        };

        const _rebuildCurrentSocket = () => {
            let socket = new WebSocket(webSocketUrl);
            if (_isFirstConnection) {
                socket.onopen = _onConnected;
            } else {
                socket.onopen = _onReconnected;
            }
            socket.onclose = _onDisconnected;
            socket.onmessage = _onWebSocketFrameReceived;
            socket.onerror = _onError;

            _isFirstConnection = false;
            _currentSocket = socket;
        };
        let _isFirstConnection = true;
        let _currentSocket = null;

        let _loginInfo ={
            isLoggedIn: false,
            accountId: null,
            userId: null,
            token: null,
            infoPopulatedWaitHandle: new WaitHandleConstructor(),
        };
        _loginInfo.infoPopulatedWaitHandle.setRedLight();

        const sendCredentialsAfterConnected = () => {
            if (_loginInfo.isLoggedIn === false) {
                return;
            }

            _loginInfo.infoPopulatedWaitHandle.waitForSignal(() => {
                const params = {
                    tokenId: _loginInfo.token,
                    accountId: _loginInfo.accountId,
                    userId: _loginInfo.userId,
                };

                const callback = (result) => {
                    if (result.isSuccessful) {
                        _authenticatedWaitHandle.setGreenLight();
                    } else {
                        _authenticatedWaitHandle.setRedLight();
                    }
                };

                sendUnAuthenticated("authentication", "loginToken", params, callback, "high");
            });
        };
        _connectCallbacks.push(sendCredentialsAfterConnected);
        _reconnectCallbacks.push(sendCredentialsAfterConnected);

        const sendLogout = () => {
            const params = {};
            const emptyCallback = () => true;
            sendUnAuthenticated("authentication", "logOut", params, emptyCallback, "high");
        };

        const receiveAlertLoginSuccessful = (loginInfo) => {
            _loginInfo.isLoggedIn = true;
            _loginInfo.accountId = loginInfo.accountId;
            _loginInfo.userId = loginInfo.userId;
            _loginInfo.token = loginInfo.authenticationToken;
            _loginInfo.infoPopulatedWaitHandle.setGreenLight();
            sendCredentialsAfterConnected();
        };

        const receiveAlertLogout = () => {
            _loginInfo.isLoggedIn = false;
            _loginInfo.accountId = null;
            _loginInfo.userId = null;
            _loginInfo.token = null;
            _loginInfo.infoPopulatedWaitHandle.setRedLight();
            sendLogout();
        };

        const send = (application, command, parameters, callback) => {
            const commandId = _commandIdGenerator.generateUniqueIdWithoutHyphens();

            let authToken = null;
            if (_sessionAccountInfo.isLoggedIn()) {
                authToken = _sessionAccountInfo.apiToken();
                if (authToken === undefined) {
                    authToken = null;
                }
            }

            const payload = {
                commandId: commandId,
                application: application,
                command: command,
                parameters: parameters,
            };

            commandListeners[commandId] = {
                "application": application,
                "callback": callback,
                "payload" : payload
            };

            _currentSocket.send(JSON.stringify(payload));
        };

        const sendUnAuthenticated = (application, command, parameters, callback, priority) => {
            _connectionWaitHandle.waitForSignal(() => {
                send(application, command, parameters, callback);
            }, priority);
        };

        const sendAuthenticated = (application, command, parameters, callback, priority) => {
            _authenticatedWaitHandle.waitForSignal(() => {
                send(application, command, parameters, callback);
            }, priority);
        };

        let _webSocketSingleton = {};
        _webSocketSingleton.on = {};
        _webSocketSingleton.on.connected = (callback) => {
            _connectCallbacks.push(callback);
        };
        _webSocketSingleton.on.reconnect = (callback) => {
            _reconnectCallbacks.push(callback);
        };
        _webSocketSingleton.on.webSocketEvent = (callback) => {
            _webSocketEventHandler.push(callback);
        };
        _webSocketSingleton.send = (application, command, parameters, callback, priority) => {
            sendAuthenticated(application, command, parameters, callback, priority);
        };
        _webSocketSingleton.sendUnAuthenticated = (application, command, parameters, callback, priority) => {
            sendUnAuthenticated(application, command, parameters, callback, priority);
        };
        _webSocketSingleton.forApplication = (applicationName) => {
            let subscriptionIds = [];

            const sendCommand = (command, parameters, callback) => {
                _webSocketSingleton.send(applicationName, command, parameters, callback);
            };

            const sendUnAuthenticatedCommand = (command, parameters, callback) => {
                _webSocketSingleton.sendUnAuthenticated(applicationName, command, parameters, callback);
            };

            const subscribeToEvent = (eventName, callback) => {
                const subscriptionId = _eventManager.subscribe(applicationName, eventName, callback);
                subscriptionIds.push(subscriptionId);
            };

            const disposeOfEvents = () => {
                subscriptionIds.forEach((subscriptionId) => {
                    _eventManager.unsubscribe(subscriptionId);
                });
            };

            return {
                send : sendCommand,
                sendUnAuthenticated : sendUnAuthenticatedCommand,
                subscribeToEvent: subscribeToEvent,
                disposeOfEvents : disposeOfEvents,
            };
        };
        _webSocketSingleton.alerts = {
            receiveAlertLoginSuccessful: receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout,
        };

        // Perform the initial connection
        _rebuildCurrentSocket();
        return _webSocketSingleton;

    });

