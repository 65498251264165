define('externalDependencies/kazooApi',['businessServices/events/eventManager',
        'externalDependencies/kazooResult',
        'businessServices/authentication/sessionAccountInfo',
        'externalDependencies/configuration/kazooApiConfiguration',
        'common/promises/promiseFactory'
        ], function() {

    var KazooResultConstructor = require('externalDependencies/kazooResult');
    var _apiUrl = null;
    var _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
    var _eventManager = null;
    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();


    return function() {
        var self = this;

        var MAXIMUM_NUMBER_OF_DOCUMENT_CONFLICTS = 5;


        var _updateConfigurationValues = function(kazooConfiguration) {
            _apiUrl = kazooConfiguration.apiUrl;
        };

        var _isDocumentConflict = function(result) {
            if ((result.status === "error") && (result.error === "409")) {
                return true;
            } else {
                return false;
            }
        };

        self.init = function() {
            _eventManager = require('businessServices/events/eventManager');

            var kazooConfiguration = require('externalDependencies/configuration/kazooApiConfiguration');
            _updateConfigurationValues(kazooConfiguration);
        };

        self.init2 = function(kazooConfiguration, resultConstructor, eventManager) {
            _eventManager = eventManager;

            _updateConfigurationValues(kazooConfiguration);
        };

        self.setHasTimeout = function(hasTimeout) {
            _promiseFactory.setHasTimeout(hasTimeout);
        };

        self.callAccountUploadFile = function(url, binaryData, contentType) {
            var accountUrl = 'accounts/' + _sessionAccountInfo.accountId() + url;
            return self.call(accountUrl, "post", binaryData, contentType);
        };

		self.callAccountDownloadFile = function(url) {
            var accountUrl = 'accounts/' + _sessionAccountInfo.accountId() + url;
            return self.call(accountUrl, "GET", "", "application/json");
        };

        self.callAccount = function(url, method, document) {
            if (_sessionAccountInfo.isLoggedIn()) {
                var accountUrl = 'accounts/' + _sessionAccountInfo.accountId() + url;
                var jsonCall = "";
                if(document){
                    jsonCall = JSON.stringify(document);
                }
                return self.call(accountUrl, method, jsonCall, 'application/json');
            } else {
                return _promiseFactory.defer(function (deferredObject) {
                    _eventManager.publishAuthenticationExpired();
                    deferredObject.reject(new Error("Not Authorized"));
                });
            }
        };

        self.unauthenticatedCall = function(url, method, document) {
            var jsonCall = "";
            if(document){
                jsonCall = JSON.stringify(document);
            }
            return self.call(url, method, jsonCall, 'application/json');
        };

        self.call = function(url, method, requestData, contentType) {
            return _promiseFactory.defer(function(promise) {

                var actualContentType = contentType || 'application/json';
                var fullUrl = _apiUrl + url;

                var errorHandler = function(jQueryRequest, textStatus, exceptionThrown) {
                    var jQueryResponse;

                    if (jQueryRequest.status === 401) {
                        _eventManager.publishAuthenticationExpired();
                        promise.reject(new Error("Not Authorized"));
                        return;
                    }

                    if (jQueryRequest.responseText) {
                        try {
                            jQueryResponse = JSON.parse(jQueryRequest.responseText);
                        }
                        catch (exception) {
                            jQueryResponse = "[Error in parsing response: " + exception + "]";
                        }
                    }
                    else {
                        jQueryResponse = "[undefined]";
                    }

                    var requestStatus;
                    if (textStatus) {
                        requestStatus = textStatus;
                    }
                    else {
                        requestStatus = "[undefined]";
                    }

                    var exceptionMessage;
                    if (exceptionThrown) {
                        exceptionMessage = exceptionThrown;
                    }
                    else {
                        exceptionMessage = "[undefined]";
                    }

                    var result = new KazooResultConstructor();
                    result.success = false;
                    result.data = jQueryResponse;
                    result.errorMessage = "JQueryResponse: {" + jQueryResponse + "}, RequestStatus: {" + requestStatus + "}, ExceptionMessage: {" + exceptionMessage + "}";

                    promise.resolve(result);
                };

                var documentConflictCounter = 0;
                // Define the function so we can recall it recursively in case there are conflicts.
                function performRequest() {
                    var ajaxSettings = {};
                    ajaxSettings.type = method;
                    ajaxSettings.contentType = actualContentType;
                    ajaxSettings.data = requestData;
                    ajaxSettings.cache = false;
                    ajaxSettings.async = true;
                    ajaxSettings.beforeSend = function(jQueryRequest) {
                        if (_sessionAccountInfo.isLoggedIn()) {
                            var apiToken = _sessionAccountInfo.apiToken();
                            if (apiToken) {
                                jQueryRequest.setRequestHeader('X-Auth-Token', apiToken);
                            }
                        }
                    };
                    ajaxSettings.error = errorHandler;
                    ajaxSettings.success = function(jsonResult) {
                        var dataResult;
                        try {
                            dataResult = JSON.parse(jsonResult);
                            // Determine if we received a document conflict error.
                            // This will happen if multiple processes are trying to update the same document at the same time
                            if (_isDocumentConflict(dataResult)) {
                                if (documentConflictCounter >= MAXIMUM_NUMBER_OF_DOCUMENT_CONFLICTS) {
                                    // We have exceeded the maximum number of tries, so stop trying
                                    var resultConflict = new KazooResultConstructor();
                                    resultConflict.success = true;
                                    resultConflict.data = dataResult;
                                    resultConflict.errorMessage = "";
                                    promise.resolve(resultConflict);
                                    return;
                                }

                                // try again
                                documentConflictCounter++;
                                performRequest();
                                return;
                            }
                        } catch (e) {
                            dataResult = jsonResult;
                        }
                        var result = new KazooResultConstructor();
                        result.success = true;
                        result.data = dataResult;
                        result.errorMessage = "";
                        promise.resolve(result);
                        return;
                    };


                    $.ajax(fullUrl, ajaxSettings);
                }

                performRequest();
            });
        };
    };
});

