define('persistence/dataProviders/featureSetDataProvider',['persistence/webSocket/webSocketApp',
  'common/promises/promiseFactory'
], function(WebSocketApp, PromiseFactory) {

  var _promiseFactory = new PromiseFactory();
  var _webSocketApp = new WebSocketApp();

  function _init() {
    _webSocketApp = new WebSocketApp();
    _webSocketApp.init("feature_set");
  }

  function _getFeatures() {
    return _promiseFactory.defer(function(deferredObject) {
      _webSocketApp.send("get_featureset", null, function(result) {
        deferredObject.resolve(result);
      });
    });
  }

  return {
    init: _init,
    getFeatures: _getFeatures
  };

});
