define('common/time/datetimeFormatter',[
    'moment',
    'i18next'
], function(
    /** @type import('moment') */
    moment,
    /** @type import('i18next') */
    i18n
) {
    return function() {
        /** @typedef {import('common/time/datetimeFormatter')} DateTimeFormatter */

        /** @type {DateTimeFormatter} */
        const self = this;

        /**
         * @param {Date} dateTime
         * @param {Date} now
         * @returns {boolean}
         */
        const _isInSameWeek = (dateTime, now) => {
            const sevenDaysPrior = new Date(now);
            sevenDaysPrior.setDate(sevenDaysPrior.getDate() - 7);
            return now >= dateTime && dateTime >= sevenDaysPrior;
        };

        /**
         * @param {Date} date
         * @returns {boolean}
         */
        const _isYesterday = (date) => {
            const today = new Date();
            return (
                date.getFullYear() === today.getFullYear() &&
                date.getMonth() === today.getMonth() &&
                date.getDate() === today.getDate() - 1
            );
        };

        /**
         * @param {string} textToBeSearched
         * @param {string} textToFind
         * @param {string} valueToSet
         * @returns {string}
         */
        const _replaceAll = (textToBeSearched, textToFind, valueToSet) => {
            const regexExpressionsEscaped = textToFind.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
            return textToBeSearched.replace(new RegExp(regexExpressionsEscaped, 'g'), valueToSet);
        };

        /**
         * @param {string} source
         * @param {number} characterCount
         * @returns {string}
         */
        const _rightString = (source, characterCount) => {
            const sourceString = source.toString();
            return sourceString.substring(sourceString.length - characterCount);
        };

        /**
         * @param {string} source
         * @param {number} characterCount
         * @returns {string}
         */
        const _padLeftWithZeros = (source, characterCount) => {
            let sourceString = source.toString();
            while (sourceString.length < characterCount) {
                sourceString = "0" + sourceString;
            }
            return _rightString(sourceString, characterCount);
        };

        const _rank = (/** @type {number} */ count) => {
            const firstDigit = count % 10;
            if (count > 10 && count < 20) {
                return count.toString() + "th";
            } else {
                switch (firstDigit) {
                    case 1:
                        return count.toString() + "st";
                    case 2:
                        return count.toString() + "nd";
                    case 3:
                        return count.toString() + "rd";
                    default:
                        return count.toString() + "th";
                }
            }
        };

        const _shortMonthName = (/** @type {number} */ month) => {
            switch (month + 1) {
                case 1:
                    return "Jan";
                case 2:
                    return "Feb";
                case 3:
                    return "Mar";
                case 4:
                    return "Apr";
                case 5:
                    return "May";
                case 6:
                    return "Jun";
                case 7:
                    return "Jul";
                case 8:
                    return "Aug";
                case 9:
                    return "Sep";
                case 10:
                    return "Oct";
                case 11:
                    return "Nov";
                case 12:
                    return "Dec";
            }
        };

        const _longMonthName = (/** @type {number} */ month) => {
            switch (month + 1) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        };

        const _longDayName = (/** @type {Date} */ date) => {
            switch (date.getDay()) {
                case 0:
                    return "Sunday";
                case 1:
                    return "Monday";
                case 2:
                    return "Tuesday";
                case 3:
                    return "Wednesday";
                case 4:
                    return "Thursday";
                case 5:
                    return "Friday";
                case 6:
                    return "Saturday";
            }
        };

        /**
         * @param {moment.MomentInput} dateTimeText
         * @param {string} dateTimeTextFormat
         * @returns {string}
         */
        const _customDateTimeTextToIso = (dateTimeText, dateTimeTextFormat) => {
            const customDateTimeIso = moment(dateTimeText, dateTimeTextFormat).isValid() ?
                moment(dateTimeText, dateTimeTextFormat).toISOString() :
                moment().toISOString();
            return customDateTimeIso;
        };

        /**
         * @param {moment.MomentInput} dateTimeIso
         * @param {string} dateTimeTextFormat
         * @returns {string}
         */
        const _customDateTimeTextFromIso = (dateTimeIso, dateTimeTextFormat) => {
            const customDateTimeText = moment(dateTimeIso).isValid() ?
                moment(dateTimeIso).format(dateTimeTextFormat) :
                moment().format(dateTimeTextFormat);
            return customDateTimeText;
        };

        self.customDateTimeTextFromIso = _customDateTimeTextFromIso;
        self.customDateTimeTextToIso = _customDateTimeTextToIso;

        /** @type {DateTimeFormatter["isOnSameDay"]} */
        self.isOnSameDay = (first, second) => {
            return (
                first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate()
            );
        };

        /** @type {DateTimeFormatter["formatWithinMinOrShortDayDate"]} */
        self.formatWithinMinOrShortDayDate = (isoDateTime) => {
            const oneMinute = 60000;
            const locale = 'en-us';

            const now = new Date();
            const currentYear = now.getFullYear();
            const dateTime = new Date(isoDateTime);

            if (Math.abs(now.getTime() - dateTime.getTime()) < oneMinute) {
                return i18n.t("timeframe:now");
            }
            else if (self.isOnSameDay(dateTime, now)) {
                const timeOfDay = dateTime.toLocaleTimeString(locale, { timeStyle: 'short' });
                return timeOfDay.replace(" ", "").toLowerCase();
            }
            else if (_isInSameWeek(dateTime, now)) {
                return dateTime.toLocaleDateString(locale, { weekday: 'short' });
            }
            else if (dateTime.getFullYear() !== currentYear) {
                return dateTime.toLocaleDateString(locale, { month: '2-digit', day: '2-digit', year: '2-digit' });
            }
            else {
                return dateTime.toLocaleDateString(locale, { month: 'short', day: '2-digit' });
            }
        };

        /** @type {DateTimeFormatter["formatDateForSidebar"]} */
        self.formatDateForSidebar = (isoDateTime) => {
            const locale = 'en-us';

            const now = new Date();
            const dateTime = new Date(isoDateTime);

            let datePart;
            if (self.isOnSameDay(dateTime, now)) {
                const today = i18n.t("timeframe:today");
                const dateStr = dateTime.toLocaleDateString(locale, { month: 'short', day: 'numeric' });
                datePart = `${today}, ${dateStr}`;
            }
            else if (_isYesterday(dateTime)) {
                const yesterday = i18n.t("timeframe:yesterday");
                const dateStr = dateTime.toLocaleDateString(locale, { month: 'short', day: 'numeric' });
                datePart = `${yesterday}, ${dateStr}`;
            }
            else if (now.getFullYear() === dateTime.getFullYear()) {
                datePart = dateTime.toLocaleDateString(locale, { weekday: 'short', month: 'short', day: 'numeric' });
            }
            else {
                datePart = dateTime.toLocaleDateString(locale, { month: 'short', day: 'numeric', year: 'numeric' });
            }

            const timePart = dateTime.toLocaleTimeString(locale, { timeStyle: "short" })
                .replaceAll(" ", "")
                .toLowerCase();
            return `${datePart} ${i18n.t('at')} ${timePart}`;
        };

        /** @type {DateTimeFormatter["formatDateForMediaModal"]} */
        self.formatDateForMediaModal = (isoDateTime) => {
            const locale = 'en-us';
            const dateTime = new Date(isoDateTime);

            const datePart = dateTime.toLocaleDateString(locale, { month: 'short', day: 'numeric' });
            const timePart = dateTime.toLocaleTimeString(locale, { timeStyle: "short" })
                .replaceAll(" ", "")
                .toLowerCase();
            return `${datePart} ${timePart}`;
        };

        /** @type {DateTimeFormatter["formatDateForLinkPreview"]} */
        self.formatDateForLinkPreview = (isoDateTime) => {
            const locale = 'en-us';

            const now = new Date();
            const dateTime = new Date(isoDateTime);

            if (self.isOnSameDay(dateTime, now)) {
                return dateTime.toLocaleTimeString(locale, { timeStyle: "short" }).toLowerCase();
            }
            else if (_isInSameWeek(dateTime, now)) {
                return dateTime.toLocaleDateString(locale, { weekday: 'short' });
            }
            else if (dateTime.getFullYear() !== now.getFullYear()) {
                return dateTime.toLocaleDateString(locale, { month: '2-digit', day: '2-digit', year: '2-digit' });
            }
            else {
                return dateTime.toLocaleDateString(locale, { month: 'short', day: 'numeric' });
            }
        };

        /** @type {DateTimeFormatter["formatWithinDayOrLongDate"]} */
        self.formatWithinDayOrLongDate = (isoDateTime) => {
            const locale = 'en-us';

            const now = new Date();
            const dateTime = new Date(isoDateTime);

            if (self.isOnSameDay(dateTime, now)) {
                return i18n.t("timeframe:today");
            }
            else if (_isYesterday(dateTime)) {
                return i18n.t("timeframe:yesterday");
            }
            else if (_isInSameWeek(dateTime, now)) {
                return dateTime.toLocaleDateString(locale, { weekday: 'long' });
            }
            else if (dateTime.getFullYear() !== now.getFullYear()) {
                return dateTime.toLocaleDateString(locale, { month: '2-digit', day: '2-digit', year: '2-digit' });
            }
            else {
                return dateTime.toLocaleDateString(locale, { month: 'long', day: 'numeric' });
            }
        };

        /** @type {DateTimeFormatter["formatShortMonthDayYearTime"]} */
        self.formatShortMonthDayYearTime = (isoDateTime) => {
            const locale = 'en-us';

            const dateTime = new Date(isoDateTime);

            const datePart = dateTime.toLocaleDateString(locale, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
            const timePart = dateTime.toLocaleTimeString(locale, { timeStyle: "short" }).toLowerCase();

            return `${datePart} ${i18n.t('at')} ${timePart}`;
        };

        /** @type {DateTimeFormatter["format"]} */
        self.format = (date, dateFormat) => {
            let result = dateFormat;

            const fourDigitYear = date.getFullYear().toString();
            const twoDigitYear = _rightString(fourDigitYear, -2);
            const shortMonthName = _shortMonthName(date.getMonth());
            const longMonthName = _longMonthName(date.getMonth());
            const unpaddedMonth = (date.getMonth() + 1).toString();
            const paddedMonth = _padLeftWithZeros(unpaddedMonth, 2);
            const longDayName = _longDayName(date);
            const dayRank = _rank(date.getDate());
            const unpaddedDay = date.getDate().toString();
            const paddedDay = _padLeftWithZeros(unpaddedDay, 2);
            const unpadded12HourMidnightIs12 = ((date.getHours() % 12 === 0) ? 12 : (date.getHours() % 12) ).toString();
            let twelveHour = (date.getHours() % 12);
            if (twelveHour === 0) {
                twelveHour = 12;
            }
            const unpadded12Hour = (twelveHour).toString();
            const padded12Hour = _padLeftWithZeros(unpadded12Hour, 2);
            const unpadded24Hour = date.getHours().toString();
            const padded24Hour = _padLeftWithZeros(unpadded24Hour, 2);
            const unpaddedMinute = date.getMinutes().toString();
            const paddedMinute = _padLeftWithZeros(unpaddedMinute, 2);
            const unpaddedSecond = date.getSeconds().toString();
            const paddedSecond = _padLeftWithZeros(unpaddedSecond, 2);
            const meridiem = date.getHours() < 12 ? 'am' : 'pm';

            result = _replaceAll(result, "yyyy", fourDigitYear);
            result = _replaceAll(result, "yy", twoDigitYear);
            result = _replaceAll(result, "mm", paddedMonth);
            result = _replaceAll(result, "m", unpaddedMonth);
            result = _replaceAll(result, "dd", paddedDay);
            result = _replaceAll(result, "d", unpaddedDay);
            result = _replaceAll(result, "hhh", unpadded12HourMidnightIs12);
            result = _replaceAll(result, "hh", padded12Hour);
            result = _replaceAll(result, "h", unpadded12Hour);
            result = _replaceAll(result, "HH", padded24Hour);
            result = _replaceAll(result, "H", unpadded24Hour);
            result = _replaceAll(result, "nn", paddedMinute);
            result = _replaceAll(result, "n", unpaddedMinute);
            result = _replaceAll(result, "ss", paddedSecond);
            result = _replaceAll(result, "s", unpaddedSecond);
            result = _replaceAll(result, "tt", meridiem);

            result = _replaceAll(result, "MMMM", longMonthName);
            result = _replaceAll(result, "MMM", shortMonthName);
            result = _replaceAll(result, "DDDD", longDayName);
            result = _replaceAll(result, "DD", dayRank);
            return result;
        };

        /** @type {DateTimeFormatter["displayDateTime"]} */
        self.displayDateTime = (dateTime, format) => {
            const momentValue = moment(dateTime);
            if (!momentValue.isValid()) {
                return null;
            }
            if (format !== undefined) {
                return momentValue.format(format);
            }

            /** @type {import('moment').CalendarSpecVal} */
            const buildFormatString = (now => {
                if (momentValue.isSame(now, 'year')) {
                    return 'MMMM Do h:mm a';
                } else {
                    return 'MMMM Do, YYYY h:mm a';
                }
            });

            return momentValue.calendar(null, {
                sameDay: '[Today] h:mm a',
                lastDay: '[Yesterday] h:mm a',
                nextDay: buildFormatString,
                nextWeek: buildFormatString,
                lastWeek: buildFormatString,
                sameElse: buildFormatString,
            });
        };

        /** @type {DateTimeFormatter["humanizedDay"]} */
        self.humanizedDay = (dateTime) => {
            const momentValue = moment(dateTime);
            if (!momentValue.isValid()) {
                return null;
            }
            /** @type {import('moment').CalendarSpecVal} */
            const buildFormatString = (now => {
                if (momentValue.isSame(now, 'year')) {
                    return 'MMM D';
                } else {
                    return 'MMM D, YYYY';
                }
            });

            return momentValue.calendar(null, {
                sameDay: '[Today]',
                lastDay: '[Yesterday]',
                nextDay: '[Tomorrow]',
                nextWeek: buildFormatString,
                lastWeek: 'ddd, MMM D',
                sameElse: buildFormatString,
            });
        };

        /** @type {DateTimeFormatter["buildHourMinuteMeridiemTime"]} */
        self.buildHourMinuteMeridiemTime = (dateTime, removeAmPm = false) => {
            const locale = 'en-US';

            const date = new Date(dateTime);
            let timeStr = date
                .toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric', hour12: true })
                .toLocaleLowerCase();

            if (removeAmPm) {
                const spaceIndex = timeStr.indexOf(` `);
                timeStr = timeStr.substring(0, spaceIndex);
            }

            return timeStr;
        };
    };
});

