define('presentation/common/intlTelPhoneInput/viewModels/intlTelPhoneInputViewModel',[],function () {
    return function () {
        const self = this;

        let _disposables = [];
        let _settings = null;
        let _autoFocus = null;

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        self.attached = () => {
            if (_autoFocus !== null) {
                self.hasFocus(_autoFocus);
            }
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        const _initialize = () => {
            if (_settings.autoFocus !== undefined) {
                if (ko.isWriteableObservable(_settings.autoFocus) === true) {
                    self.autoFocus = _settings.autoFocus;
                } else {
                    self.autoFocus(_settings.autoFocus);
                }
            }

            self.hasFocus = ko.computed(() => {
                return self.autoFocus();
            });

            if (_settings.maxLength !== undefined) {
                if (ko.isWriteableObservable(_settings.maxLength) === true) {
                    self.maxLength = _settings.maxLength;
                } else {
                    self.maxLength(_settings.maxLength);
                }
            }

            if(_settings.enabled !== undefined) {
                self.enabled = _settings.enabled;
            }

            if(_settings.valueUpdate) {
                self.valueUpdate(_settings.valueUpdate);
            }


            if (_settings.value !== undefined) {
                _settings.value.forceUpdate = ko.observable(false);
                _settings.value.isKeyUp = ko.observable(false);
                if ((ko.isWriteableObservable(_settings.value))) {
                    self.value = _settings.value;
                } else {
                    self.value(_settings.value);
                }
            }
            _disposables.push(_settings.value.subscribe(_updateLocalValue, this, "change"));

            if (_settings.countryAbbreviation !== undefined) {
                _settings.countryAbbreviation.forceUpdate = ko.observable(false);
                if ((ko.isWriteableObservable(_settings.countryAbbreviation))) {
                    self.countryAbbreviation = _settings.countryAbbreviation;
                } else {
                    self.countryAbbreviation(_settings.countryAbbreviation);
                }
            }
            _disposables.push(_settings.countryAbbreviation.subscribe(_updateLocalCountryAbbreviation, this, "change"));

            if (_settings.countryCode !== undefined) {
                if ((ko.isWriteableObservable(_settings.countryCode))) {
                    self.countryCode = _settings.countryCode;
                } else {
                    self.countryCode(_settings.countryCode);
                }
            }

            if (_settings.theme !== undefined) {
                if ((ko.isWriteableObservable(_settings.theme))) {
                    self.theme = _settings.theme;
                } else {
                    self.theme(_settings.theme);
                }
            }

            if (_settings.isSmallWidth !== undefined) {
                if ((ko.isWriteableObservable(_settings.isSmallWidth))) {
                    self.isSmallWidth = _settings.isSmallWidth;
                } else {
                    self.isSmallWidth(_settings.isSmallWidth);
                }
            }

            if (_settings.hasOwnProperty('validateOnBlur')) {
                self.validateOnBlur = ko.unwrap(_settings.validateOnBlur);
            }
        };

        const _updateLocalValue = (newValue) => {
            if (_settings.value.forceUpdate()) {
                _settings.value.forceUpdate(false);
                self.value(newValue);
            }
        };

        const _updateLocalCountryAbbreviation = (newAbbreviation) => {
            if (_settings.countryAbbreviation.forceUpdate()) {
                _settings.countryAbbreviation.forceUpdate(false);
                self.countryAbbreviation(newAbbreviation);
            }
        };

        self.autoFocus = ko.observable(false);
        self.maxLength = ko.observable('200');
        self.type = 'tel';
        self.value = ko.observable('');
        self.countryAbbreviation = ko.observable('');
        self.countryCode = ko.observable('');
        self.enabled = ko.observable(true);
        self.valueUpdate = ko.observable('');
        self.theme = ko.observable('');
        self.isSmallWidth = ko.observable(false);
        self.validateOnBlur = true;

        self.keyUpEvent = () => {
            if (_settings.valueUpdate === "keyup") {
                _settings.value.isKeyUp(true);
                _settings.value(self.value());
            }
        };
        
        self.blurEvent = () => {
            _settings.value.isKeyUp(false);
            if (_settings.value() !== self.value()) {
                _settings.value(self.value());
            } else if (_settings.value.validate && self.validateOnBlur) {
                _settings.value.validate();
            }
        };
    };
});

