define('businessServices/signup/signupSessionStore',[
    'businessServices/authentication/stores/signupAuthenticationStore',
    'businessServices/signup/signupSessionSingleton',
    'common/authentication/authenticatedSignup',
    'common/promises/promiseFactory',
    'constants/signupNumberTypeSelectionConstants',
    'entities/signupEntity',
    'persistence/dataProviders/signupDataProvider',
    'persistence/repositories/signupRepository',
], () => {
    return function (){
        let self = this;

        let _authenticatedSignup = null;
        let _signupAuthenticationStore = null;
        let _signupSessionDataProvider = null;
        let _signupSessionRepository = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _signupSessionState = require('businessServices/signup/signupSessionSingleton');
        const _signupNumberTypeSelectionConstants = require('constants/signupNumberTypeSelectionConstants');
        const _signupSocket = require('externalDependencies/clientWebSocket').forApplication("signup");

        const _generateNewSignup = (createIfMissing) => {
            return _promiseFactory.defer((deferredObject) => {
                if (createIfMissing === false) {
                    deferredObject.resolve(null);
                } else {
                    _signupSessionRepository.createNew()
                        .fail(deferredObject.reject)
                        .done((newSignup) => {
                            _signupAuthenticationStore.save(newSignup.signupSessionId, null, newSignup.secretKey);
                            _signupSessionState.authorizedSignature = '';
                            _signupSessionState.headerCarrier = ko.observable('');
                            _signupSessionState.headerPhoneNumbers = ko.observable([]);
                            _signupSessionState.isAcknowledged = false;
                            _signupSessionState.isLoaded = true;
                            _signupSessionState.numberTypeSelection(newSignup.numberTypeSelection);
                            _signupSessionState.password = null;
                            _signupSessionState.signupDocument = newSignup;
                            deferredObject.resolve(newSignup);
                        });
                }
            });
        };

        const _loadSignupSession = (createIfMissing) => {
            return _promiseFactory.defer((deferredObject) => {
                _signupAuthenticationStore.load()
                    .fail(deferredObject.reject)
                    .done((signupSessionId, accountId, secretKey) => {
                        if (signupSessionId === null) {
                            _generateNewSignup(createIfMissing)
                                .fail(deferredObject.reject)
                                .done(deferredObject.resolve);
                        } else {
                            _signupSessionDataProvider.getById(signupSessionId, secretKey)
                                .fail(deferredObject.reject)
                                .done((signupResult) => {
                                    if (signupResult && signupResult.isSuccessful) {
                                        _signupSessionState.signupDocument = signupResult.signupDocument;
                                        _signupSessionState.signupDocument.numberPort = signupResult.numberPortDocument;
                                        _signupSessionState.numberTypeSelection(signupResult.signupDocument.numberTypeSelection);
                                        switch (signupResult.signupDocument.numberTypeSelection) {
                                            case  _signupNumberTypeSelectionConstants.local:
                                            case  _signupNumberTypeSelectionConstants.tollFree:
                                                if (signupResult.signupDocument.reservedPhoneNumber){
                                                    _signupSessionState.headerPhoneNumbers([signupResult.signupDocument.reservedPhoneNumber]);
                                                }
                                                break;
                                            case  _signupNumberTypeSelectionConstants.port:
                                                _signupSessionState.headerCarrier(signupResult.numberPortDocument.carrier);
                                                if (signupResult.numberPortDocument.phoneNumbersToPort) {
                                                    _signupSessionState.headerPhoneNumbers(signupResult.numberPortDocument.phoneNumbersToPort.map(n => n.phoneNumber));
                                                }
                                                break;
                                            default:
                                                _signupSessionState.headerPhoneNumbers([]);
                                                break;
                                        }
                                        _signupSessionState.isLoaded = true;
                                        deferredObject.resolve();
                                    } else {
                                        _generateNewSignup(createIfMissing)
                                            .fail(deferredObject.reject)
                                            .done(deferredObject.resolve);
                                    }
                                });
                        }
                    });
            });
        };
        const _clearSessionStateReservedPhoneNumber = () => {
            _signupSessionState.signupDocument.reservedPhoneNumber = null;
            _signupSessionState.signupDocument.reservedPhoneNumberRegion = null;
            _signupSessionState.signupDocument.reservedPhoneNumberCity = null;
            _signupSessionState.signupDocument.reservedPhoneNumberVanityString = null;
            _signupSessionState.signupDocument.reservedPhoneNumberSearchType = null;
            _signupSessionState.headerPhoneNumbers([]);
        };
        const _updateSessionStateReservedPhoneNumber = (signupSessionEntity) => {
            _signupSessionState.signupDocument.reservedPhoneNumber = signupSessionEntity.signupDocument.reservedPhoneNumber;
            _signupSessionState.signupDocument.reservedPhoneNumberRegion = signupSessionEntity.signupDocument.reservedPhoneNumberRegion;
            _signupSessionState.signupDocument.reservedPhoneNumberCity = signupSessionEntity.signupDocument.reservedPhoneNumberCity;
            _signupSessionState.signupDocument.reservedPhoneNumberVanityString = signupSessionEntity.signupDocument.reservedPhoneNumberVanityString;
            _signupSessionState.signupDocument.reservedPhoneNumberSearchType = signupSessionEntity.signupDocument.reservedPhoneNumberSearchType;
            _signupSessionState.signupDocument.numberTypeSelection = signupSessionEntity.signupDocument.numberTypeSelection;

            _signupSessionState.numberTypeSelection(signupSessionEntity.signupDocument.numberTypeSelection);
            _signupSessionState.headerPhoneNumbers([signupSessionEntity.signupDocument.reservedPhoneNumber]);
        };
        self.cancelPhoneNumberReservation = (rawPhoneNumber) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _promiseFactory.defer((promise) => {
                _signupSessionRepository.cancelReservation(signupSessionId, secretKey, rawPhoneNumber)
                    .fail(promise.reject)
                    .done(() => {
                        _clearSessionStateReservedPhoneNumber();
                        promise.resolve();
                    });
            });
        };
        self.clear = () => {
            _signupSessionState.isLoaded = false;
            _signupSessionState.authorizedSignature = '';
            _signupSessionState.headerCarrier('');
            _signupSessionState.headerPhoneNumbers([]);
            _signupSessionState.isAcknowledged = false;
            _signupSessionState.numberTypeSelection('');
            _signupSessionState.password = null;
            _signupSessionState.signupDocument = null;
        };
        self.connectSocket = (signupSessionId, secretKey) => _promiseFactory.defer(promise => {
            _signupSessionDataProvider.connectSocket(signupSessionId, secretKey)
                .fail(promise.reject)
                .done(promise.resolve);
        });
        self.createNumberPort = () => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.createNumberPort(signupSessionId, secretKey)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort = numberPortEntity;
                        promise.resolve(numberPortEntity);
                    });
            });
        };
        self.generateNewSignup = (createIfMissing = true) => {
            return _generateNewSignup(createIfMissing);
        };
        self.get = (createIfMissing = true) => {
            return _promiseFactory.defer( (deferredObject) => {
                if (_signupSessionState.isLoaded === false) {
                    _loadSignupSession(createIfMissing)
                        .fail(deferredObject.reject)
                        .done(() => {
                            deferredObject.resolve(_signupSessionState.signupDocument);
                        });
                } else {
                    deferredObject.resolve(_signupSessionState.signupDocument);
                }
            });
        };
        self.getNumberType = () => _promiseFactory.deferIndefinitely(deferredObject => {
            self.get()
                .fail(deferredObject.reject)
                .done(signupDocument => {
                    _signupSessionState.numberTypeSelection(signupDocument.numberTypeSelection);
                    deferredObject.resolve(signupDocument.numberTypeSelection);
                });
        });
        self.getState = (forceLoad) => {
            return _promiseFactory.defer((promise) => {
                if (_signupSessionState.isLoaded === false || forceLoad === true) {
                    _loadSignupSession()
                        .fail(promise.reject)
                        .done(() => {
                            promise.resolve(_signupSessionState);
                        });
                } else {
                    promise.resolve(_signupSessionState);
                }
            });
        };
        self.getAffiliates = () => _promiseFactory.defer(promise => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            _signupSessionDataProvider.getAffiliates(signupSessionId, secretKey)
                .fail(promise.reject)
                .done(promise.resolve);
        });
        self.checkIsFraudulentDeviceOrIp = (requestId) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            if(requestId) {
                return _signupSessionRepository.checkIsFraudulentDevice(signupSessionId, secretKey, requestId);
            } else {
                return _signupSessionRepository.checkIsFraudulentIpAddress(signupSessionId, secretKey);
            }
        };
        self.isRestrictedPhoneNumber = (phoneNumber) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _signupSessionRepository.checkRestrictedPhoneNumber(signupSessionId, secretKey, phoneNumber);
        };
        self.isFraudulentPhoneNumber = (phoneNumber, countryCode) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _signupSessionRepository.checkIsFraudulentPhoneNumber(signupSessionId, secretKey, phoneNumber, countryCode);
        };
        self.reservePhoneNumber = ({phoneNumber, region, city, searchType, vanityString = null}) => {
            const {signupSessionId, secretKey} = _authenticatedSignup;
            return _promiseFactory.defer((deferredObject) => {
                _signupSessionRepository.reserveNumber({signupSessionId, secretKey, phoneNumber, region, city, searchType, vanityString})
                    .fail(deferredObject.reject)
                    .done((signupSessionEntity) => {
                        if (signupSessionEntity.status === "reserved") {
                            _updateSessionStateReservedPhoneNumber(signupSessionEntity);
                        }

                        deferredObject.resolve({
                            status: signupSessionEntity.status
                        });
                    });
            });
        };
        self.reserveLocalPhoneNumberWithoutLocation = ({phoneNumber, searchType, vanityString = null}) => {
            const {signupSessionId, secretKey} = _authenticatedSignup;
            return _promiseFactory.defer((deferredObject) => {
                _signupSessionRepository.reserveLocalPhoneNumberWithoutLocation({signupSessionId, secretKey, phoneNumber, searchType, vanityString})
                    .fail(deferredObject.reject)
                    .done((signupSessionEntity) => {
                        if (signupSessionEntity.status === "reserved") {
                            _updateSessionStateReservedPhoneNumber(signupSessionEntity);
                        }

                        deferredObject.resolve({
                            status: signupSessionEntity.status
                        });
                    });
            });
        };
        self.reReserveSessionPhoneNumber = () => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _promiseFactory.defer((deferredObject) => {
                _signupSessionRepository.reReserveSessionPhoneNumber(signupSessionId, secretKey)
                    .fail(deferredObject.reject)
                    .done((signupSessionEntity) => {
                        if (signupSessionEntity.status === "reserved") {
                            _updateSessionStateReservedPhoneNumber(signupSessionEntity);
                        } else {
                            _clearSessionStateReservedPhoneNumber();
                        }

                        deferredObject.resolve({
                            status: signupSessionEntity.status
                        });
                    });
            });
        };
        self.checkIsFraudulentDevice = (requestId) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _signupSessionRepository.checkIsFraudulentDevice(signupSessionId, secretKey, requestId);
        };
        self.saveDeviceFingerPrint = (deviceFingerPrint) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveDeviceFingerPrint(signupSessionId, secretKey, deviceFingerPrint)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.deviceFingerPrint = signupSessionEntity.deviceFingerPrint;
                        promise.resolve();
                    });
            });
        };
        self.saveBusinessName = (businessName) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveBusinessName(signupSessionId, secretKey, businessName)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.businessName = signupSessionEntity.businessName;
                        promise.resolve();
                    });
            });
        };
        self.isFraudulentEmailAddress = (emailAddress) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _signupSessionRepository.checkIsFraudulentEmailAddress(signupSessionId, secretKey, emailAddress);
        };
        self.saveEmailAddress = (emailAddress) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveEmailAddress(signupSessionId, secretKey, emailAddress)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        if (emailAddress !== _signupSessionState.signupDocument.emailAddress) {
                            _signupSessionState.signupDocument.isEmailCompleted = false;
                        }

                        _signupSessionState.signupDocument.emailAddress = signupSessionEntity.emailAddress;
                        _signupSessionState.signupDocument.isEmailArrowValid = false;
                        _signupSessionState.signupDocument.emailVerificationCodeSendCount = 0;
                        promise.resolve();
                    });
            });
        };
        self.saveFirstName = (firstName) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveFirstName(signupSessionId, secretKey, firstName)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.firstName = signupSessionEntity.firstName;
                        promise.resolve();
                    });
            });
        };
        self.saveFlow = (flow) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveFlow(signupSessionId, secretKey, flow)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.flow = signupSessionEntity.flow;
                        promise.resolve();
                    });
            });
        };
        self.saveLastName = (lastName) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveLastName(signupSessionId, secretKey, lastName)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.lastName = signupSessionEntity.lastName;
                        promise.resolve();
                    });
            });
        };
        self.saveMobileNumber = (mobileNumber) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveMobileNumber(signupSessionId, secretKey, mobileNumber)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.mobilePhoneNumber = signupSessionEntity.mobilePhoneNumber;
                        promise.resolve();
                    });
            });
        };
        self.saveMobileNumberCountryCode = (mobileNumberCountryCode) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveMobileNumberCountryCode(signupSessionId, secretKey, mobileNumberCountryCode)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.mobileNumberCountryCode = signupSessionEntity.mobileNumberCountryCode;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPort = (numberPort) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPort(signupSessionId, secretKey, numberPort)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort = numberPortEntity;
                        _signupSessionState.headerPhoneNumbers(numberPortEntity.phoneNumbersToPort.map(n => n.phoneNumber));
                        _signupSessionState.headerCarrier(numberPortEntity.carrier);
                        promise.resolve();
                    });
            });
        };

        self.storeNumberPortAuthorizedSignature = (authorizedSignature) => _signupSessionState.authorizedSignature = authorizedSignature;
        self.storeNumberPortIsAcknowledged = (isAcknowledged) => _signupSessionState.isAcknowledged = isAcknowledged;

        self.saveNumberPortBillingAccountNumber = (numberPortId, accountNumber) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingAccountNumber(signupSessionId, secretKey, numberPortId, accountNumber)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingAccountNumber = numberPortEntity.billingAccountNumber;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingAddressLine1 = (numberPortId, billingAddress) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingAddressLine1(signupSessionId, secretKey, numberPortId, billingAddress)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingAddressLine1 = numberPortEntity.billingAddressLine1;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingAddressLine2 = (numberPortId, billingApt) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingAddressLine2(signupSessionId, secretKey, numberPortId, billingApt)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingAddressLine2 = numberPortEntity.billingAddressLine2;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingCity = (numberPortId, billingCity) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingCity(signupSessionId, secretKey, numberPortId, billingCity)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingCity = numberPortEntity.billingCity;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingCountry = (numberPortId, countryAbbreviation) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingCountry(signupSessionId, secretKey, numberPortId, countryAbbreviation)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingCountry = numberPortEntity.billingCountry;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingName = (numberPortId, billingName) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingName(signupSessionId, secretKey, numberPortId, billingName)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingName = numberPortEntity.billingName;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingPinPasscode = (numberPortId, pinOrPasscode) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingPinPasscode(signupSessionId, secretKey, numberPortId, pinOrPasscode)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingPinPasscode = numberPortEntity.billingPinPasscode;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingPhoneNumber = (numberPortId, phoneNumber) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingPhoneNumber(signupSessionId, secretKey, numberPortId, phoneNumber)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingPhoneNumber = numberPortEntity.billingPhoneNumber;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingPhoneNumberCountry = (numberPortId, countryAbbreviation) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingPhoneNumberCountry(signupSessionId, secretKey, numberPortId, countryAbbreviation)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingPhoneNumberCountry = numberPortEntity.billingPhoneNumberCountry;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingState = (numberPortId, billingState) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingState(signupSessionId, secretKey, numberPortId, billingState)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingState = numberPortEntity.billingState;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortBillingZipPostalCode = (numberPortId, zipPostalCode) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortBillingZipPostalCode(signupSessionId, secretKey, numberPortId, zipPostalCode)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.billingZipPostalCode = numberPortEntity.billingZipPostalCode;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortCarrier = (numberPortId, carrier) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortCarrier(signupSessionId, secretKey, numberPortId, carrier)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.carrier = numberPortEntity.carrier;
                        _signupSessionState.headerCarrier(carrier);
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortDesiredPortDate = (numberPortId, portDate) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortDesiredPortDate(signupSessionId, secretKey, numberPortId, portDate)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.desiredPortDate = numberPortEntity.desiredPortDate;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortOtherNumbersOnAccount = (numberPortId, areOtherNumbersOnAccount) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortOtherNumbersOnAccount(signupSessionId, secretKey, numberPortId, areOtherNumbersOnAccount)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.otherNumbersOnAccount = numberPortEntity.otherNumbersOnAccount;
                        promise.resolve();
                    });
            });
        };
        self.saveNumberPortPhoneNumbers = (numberPortId, numbersToPort) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberPortPhoneNumbers(signupSessionId, secretKey, numberPortId, numbersToPort)
                    .fail(promise.reject)
                    .done((numberPortEntity) => {
                        _signupSessionState.signupDocument.numberPort.phoneNumbersToPort = numberPortEntity.phoneNumbersToPort;
                        _signupSessionState.headerPhoneNumbers(numbersToPort.map(n => n.phoneNumber));
                        promise.resolve();
                    });
            });
        };
        self.saveNumberTypeSelection = (numberTypeSelection) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.saveNumberTypeSelection(signupSessionId, secretKey, numberTypeSelection)
                    .fail(promise.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.numberTypeSelection = numberTypeSelection;
                        _signupSessionState.numberTypeSelection(numberTypeSelection);
                        promise.resolve();
                   });
            });
        };
        self.savePassword = (password) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionState.password = password;
                promise.resolve();
            });
        };
        self.savePaymentExpirationDate = (expirationMonth, expirationYear) => {
            return _promiseFactory.defer(deferredObject => {
                _signupSessionRepository.savePaymentExpirationDate(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, expirationMonth, expirationYear)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.expirationMonth = expirationMonth;
                        _signupSessionState.signupDocument.expirationYear = expirationYear;
                        deferredObject.resolve();
                    });
            });
        };
        self.savePaymentIsComplete = (isComplete) => _promiseFactory.defer(deferredObject => {
            const isPaymentMethodCompleted = isComplete === true;
            _signupSessionRepository.savePaymentIsComplete(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, isPaymentMethodCompleted)
                .fail(deferredObject.reject)
                .done(() => {
                    _signupSessionState.signupDocument.isPaymentMethodCompleted = isPaymentMethodCompleted;
                    deferredObject.resolve();
                });
        });
        self.savePaymentHolderName = (cardHolderName) => {
            return _promiseFactory.defer(deferredObject => {
                _signupSessionRepository.savePaymentHolderName(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, cardHolderName)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.cardHolderName = cardHolderName;
                        deferredObject.resolve();
                    });
            });
        };
        self.savePaymentPostalCode = (countryCode, postalCode) => {
            return _promiseFactory.defer(deferredObject => {
                _signupSessionRepository.savePaymentPostalCode(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, countryCode, postalCode)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.countryCode = countryCode;
                        _signupSessionState.signupDocument.postalCode = postalCode;
                        deferredObject.resolve();
                    });
            });
        };

        self.isFraudulentPaymentInformation = (cardHolderName, cardNumberHashed, firstSixDigits, expirationDate, country, postalCode) => {
            const signupSessionId = _authenticatedSignup.signupSessionId;
            const secretKey = _authenticatedSignup.secretKey;
            return _signupSessionRepository.checkIsFraudulentPaymentInformation(signupSessionId,
                secretKey,
                cardHolderName,
                cardNumberHashed,
                firstSixDigits,
                expirationDate,
                country,
                postalCode);
        };

        self.saveSignupSessionStatus = (signupSessionStatus) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionRepository.saveSignupSessionStatus(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, signupSessionStatus)
                    .fail(promise.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.signupSessionStatus = signupSessionStatus;
                        promise.resolve();
                    });
            });
        };
        self.saveVerificationAnswer = (verificationAnswer) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionRepository.saveVerificationAnswer(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, verificationAnswer)
                    .fail(promise.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.verificationAnswer = verificationAnswer;
                        promise.resolve();
                    });
            });
        };
        self.saveVerificationQuestion = (verificationQuestionId) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionRepository.saveVerificationQuestion(_authenticatedSignup.signupSessionId, _authenticatedSignup.secretKey, verificationQuestionId)
                    .fail(promise.reject)
                    .done(() => {
                        _signupSessionState.signupDocument.verificationQuestionId = verificationQuestionId;
                        promise.resolve();
                    });
            });
        };
        self.setEmailCompleted = (emailAddress, verificationCode) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.setEmailCompleted(signupSessionId, secretKey, emailAddress, verificationCode)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.emailAddress = signupSessionEntity.emailAddress;
                        _signupSessionState.signupDocument.isEmailCompleted = signupSessionEntity.isEmailCompleted;
                        _signupSessionState.signupDocument.emailVerificationCodeSendCount = signupSessionEntity.emailVerificationCodeSendCount;
                        _signupSessionState.signupDocument.emailVerificationFailureCount = signupSessionEntity.emailVerificationFailureCount;
                        promise.resolve(Object.assign({}, _signupSessionState.signupDocument));
                    });
            });
        };
        self.sendEmailVerificationCode = () => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.sendEmailVerificationCode(signupSessionId, secretKey)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.isEmailCompleted = false;
                        _signupSessionState.signupDocument.emailVerificationCodeSendCount = signupSessionEntity.emailVerificationCodeSendCount;
                        promise.resolve(Object.assign({}, _signupSessionState.signupDocument));
                    });
            });
        };
        self.setPhoneNumberCompleted = (isPhoneNumberCompleted) => {
            return _promiseFactory.defer((promise) => {
                const signupSessionId = _authenticatedSignup.signupSessionId;
                const secretKey = _authenticatedSignup.secretKey;
                _signupSessionRepository.setPhoneNumberCompleted(signupSessionId, secretKey, isPhoneNumberCompleted)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.isPhoneNumberCompleted = signupSessionEntity.isPhoneNumberCompleted;
                        promise.resolve();
                    });
            });
        };
        self.setProfileCompleted = (isProfileCompleted) => {
            return _promiseFactory.defer((promise) => {
                const {signupSessionId, secretKey} = _authenticatedSignup;
                _signupSessionRepository.setProfileCompleted(signupSessionId, secretKey, isProfileCompleted)
                    .fail(promise.reject)
                    .done((signupSessionEntity) => {
                        _signupSessionState.signupDocument.isProfileCompleted = signupSessionEntity.isProfileCompleted;
                        promise.resolve();
                    });
            });
        };
        self.saveSearchAndNumberType = ({searchType, numberType}) => _promiseFactory.deferIndefinitely(promise => {
            const {signupSessionId, secretKey} = _authenticatedSignup;
            _signupSessionRepository.saveSearchAndNumberType({signupSessionId, secretKey, searchType, numberType})
                .fail(promise.reject)
                .done(() => {
                    _signupSessionState.signupDocument.reservedPhoneNumberSearchType = searchType;
                    promise.resolve();
                });
        });
        self.saveResellerAndClickId = (resellerId, clickId) => _promiseFactory.deferIndefinitely(promise => {
            const {signupSessionId, secretKey} = _authenticatedSignup;
            _signupSessionRepository.saveResellerAndClickId({signupSessionId, secretKey, resellerId, clickId})
                .fail(promise.reject)
                .done(() => {
                    _signupSessionState.signupDocument.resllerId = resellerId;
                    _signupSessionState.signupDocument.clickId = clickId;
                    promise.resolve();
                });
        });
        self.clearSessionStateReservedPhoneNumber = () => _clearSessionStateReservedPhoneNumber();

        self.detached = function() {
            _signupSocket.disposeOfEvents();
        };
        self.init = () => {

            const SignupSessionRepository = require('persistence/repositories/signupRepository');
            _signupSessionRepository = new SignupSessionRepository();
            _signupSessionRepository.init();

            const SignupSessionDataProviderConstructor = require('persistence/dataProviders/signupDataProvider');
            _signupSessionDataProvider = new SignupSessionDataProviderConstructor();
            _signupSessionDataProvider.init();

            const SignupAuthenticationStoreConstructor = require('businessServices/authentication/stores/signupAuthenticationStore');
            _signupAuthenticationStore = new SignupAuthenticationStoreConstructor();
            _signupAuthenticationStore.init();

            _authenticatedSignup = require('common/authentication/authenticatedSignup');

            _signupSocket.subscribeToEvent("signupComplete", () => _loadSignupSession(false));
        };
    };
});

