define('presentation/settings/closeAccount/facades/closeAccountFacade',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket',
    'persistence/dataProviders/paymentMethodDataProvider',
    'persistence/webSocket/webSocketApp'
], function() {

    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    const ClientWebSocketConstructor = require('externalDependencies/clientWebSocket');
    const _closeAccountWebSocket = ClientWebSocketConstructor.forApplication("closeAccount");

    let _webSocketApp = null;
    let _paymentMethodDataProvider = null;

    const _closeAccount = (feedback, cancellationComment) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const params = {
                accountClosedFeedbackTypeDiscriminator: feedback.accountClosedFeedbackTypeDiscriminator,
                fraudStatus: feedback.fraudStatus,
                reasonForCancellation: feedback.reasonForCancellation,
                cancellationComment: cancellationComment
            };
            _webSocketApp.send("close_account", params, (result) => {
                if (result) {
                    _saveCloseAccountFeedback(feedback)
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                } else {
                    deferredObject.reject();
                }
            });
        });
    };

    const _getCloseAccountReasons = () => {
        return _promiseFactory.defer((deferredObject) => {
            _closeAccountWebSocket.send("getCloseAccountFeedbackReasons", {}, (reasons) => {
                const {closeAccountFeedbackReasons, tooExpensiveFeedbackReasons, noLongerNeededFeedbackReasons, featuresList } = reasons;
                deferredObject.resolve(closeAccountFeedbackReasons, tooExpensiveFeedbackReasons, noLongerNeededFeedbackReasons, featuresList);
            });
        });
    };

    const _isAccountInFreeTrial = () => {
        return _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getFreeTrialInfo()
                .fail(deferredObject.reject)
                .done((result) => {
                    const { isInFreeTrial } = result;
                    deferredObject.resolve(isInFreeTrial);
                });
        });
    };

    const _saveCloseAccountFeedback = (feedback) => {
        return _promiseFactory.defer((deferredObject) => {
            _closeAccountWebSocket.send("createAccountClosedFeedback", feedback, () => {
                deferredObject.resolve();
            });
        });
    };

    const _init = () => {
        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("account_profile");

        const PaymentMethodDataProviderConstructor = require('persistence/dataProviders/paymentMethodDataProvider');
        _paymentMethodDataProvider = new PaymentMethodDataProviderConstructor();
        _paymentMethodDataProvider.init();
    };

    return function() {
        const self = this;

        self.init = _init;
        self.closeAccount = _closeAccount;
        self.getCloseAccountReasons = _getCloseAccountReasons;
        self.isAccountInFreeTrial = _isAccountInFreeTrial;
    };
});

