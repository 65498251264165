define('constants/accountHostedNumberConstants',[], function() {
    return {
        status: {
            active: {
                id: 1,
                name: "Active"
            },
            deactivated: {
                id: 2,
                name: "Deactivated"
            }
        }
    };
});
