define('presentation/common/validation/validPatliveAccountNumberSpecification',[

    ],
function() {
    return function() {
        var self = this;

        self.isValidPatliveAccountNumber = function(patliveAccountNumber) {
            if (patliveAccountNumber === null) {
                return true;
            }
            if (patliveAccountNumber.startsWith("-") || patliveAccountNumber.endsWith("-") ) {
                return false;
            }
            if (/[^0-9-]/g.test(patliveAccountNumber)) { // test for anything other than 0-9 or -
                return false;
            }
            return true;
        };
    };
});
