define('presentation/common/voicemail/presentationObjects/voicemailGreetingPresentationObject',['constants/audioInputOptionConstants'],
    function() {
        return function() {
            const self = this;

            self.voicemailGreetingId = null;
            self.greetingId = null;
            self.name = ko.observable("").extend({observeState: true});
            self.file = ko.observable("").extend({observeState: true});
            self.greetingAudioObject = ko.observable();
            self.showAudioPlayer = ko.observable(false);
            self.showConversionMessage = ko.observable(false);
            self.showConversionFailedMessage = ko.observable(false);
            self.showPreview = ko.observable(false);
            self.conversionInProgress = ko.observable(false);
            self.conversionComplete = ko.observable(true);
            self.isEditable = ko.observable(false);
            self.isEditMode = ko.observable(false);
            self.isAddMode = ko.observable(false);
            self.addVoicemailGreetingViewModel = ko.observable();
            self.isDeletable = ko.observable(false);
            self.isDeleted = ko.observable(false).extend({observeState: true});
            self.isDefault = ko.observable(false).extend({observeState: true});
            self.isSelected = ko.observable(false).extend({observeState: true});
            self.isBuiltIn =  ko.observable(false);
            self.isAccount = false;
            self.createdDateTime = "";
            self.modifiedDateTime = "";
            self.requiresUpdate = false;

            self.showForm = ko.computed(() => self.isEditMode() || self.isAddMode());
            self.showSelected = ko.computed(() => self.isSelected());

            self.audioDurationFilterValue = ko.computed(() => {
                if (self.greetingAudioObject() && self.greetingAudioObject().duration()) {
                    return self.greetingAudioObject().duration();
                } else {
                    return "";
                }
            });
        };
    });

