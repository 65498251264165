define('presentation/common/analyticsGraph/graphRecordView',[],
    function() {

        /**
         * @param {keyof IAnalyticsGraphProjectionResult} fieldName 
         * @param {IAnalyticsGraphProjectionResult[fieldName]} defaultValue 
         * @returns {(record: IAnalyticsGraphProjectionResult) => IAnalyticsGraphProjectionResult[fieldName]}
         */
        const getFieldFn = (fieldName, defaultValue) => summaryRecord => summaryRecord[fieldName] ? summaryRecord[fieldName] : defaultValue;

        const summaryRecord = {
            dateHour: getFieldFn("a", null),
            inboundCount: getFieldFn("b", 0),
            inboundSeconds: getFieldFn("c", 0),
            outboundCount: getFieldFn("d", 0),
            outboundSeconds: getFieldFn("e", 0),
        };

        return summaryRecord;
    }
);


