define('presentation/settings/autoAttendant/validators/addAutoAttendantValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/inlinePromptDefaultOptionEnumerations'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _inlinePromptEnumerations = require('constants/inlinePromptDefaultOptionEnumerations');

        let _autoAttendantFacade = null;
        let _autoAttendantViewModel = null;
        let _commonValidator = null;

        const INACTIVE = "inactive";
        const INVALID = "invalid";
        const ACTIVE = "active";

        self.registerViewModel = (autoAttendantViewModel, autoAttendantFacade) => {
            _autoAttendantViewModel = autoAttendantViewModel;
            _autoAttendantFacade = autoAttendantFacade;
            _commonValidator = new CommonValidatorConstructor(_autoAttendantViewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("autoAttendantName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addAutoAttendant:autoAttendantNameRequired')
                .addValidationI18n(_validateAutoAttendantNameUniqueness, 'addAutoAttendant:uniqueNameRequired');
            validationRules.field("autoAttendantMenuError")
                .addValidationI18n(_validateAutoAttendantRoutesToOneEndpoint, 'addAutoAttendant:menuRoutesToAtLeastOneEndpoint');
            validationRules.field("autoAttendantSubMenuError")
                .addValidationI18n(_validateAutoAttendantSubMenuRoutesToOneEndpoint, 'addAutoAttendant:subMenuRoutesToAtLeastOneEndpoint');
            validationRules.field("autoAttendantPromptError")
                .addValidationI18n(_validateAutoAttendantMenuVoicePrompts, 'addAutoAttendant:voicePromptMissingOnMenu');
            validationRules.field("selectedPrompt")
                .addValidationI18n(_validateAutoAttendantPromptSelected, 'addAutoAttendant:addOrSelectPrompt');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.validateAutoAttendantNameUniqueness = (autoAttendantName) => {
            return _promiseFactory.defer((deferredResult) => {
                if (_commonValidator.isStringWithValue(autoAttendantName)) {
                    _validateAutoAttendantNameUniqueness(autoAttendantName)
                        .fail(deferredResult.reject)
                        .done(deferredResult.resolve);
                } else {
                    deferredResult.resolve(false);
                }
            });
        };

        const _validateAutoAttendantNameUniqueness = (autoAttendantName) => {
            return _promiseFactory.defer((deferredResult) => {
                const autoAttendantId = _autoAttendantViewModel.autoAttendantId();
                _autoAttendantFacade.isAutoAttendantNameUnique(autoAttendantId, autoAttendantName)
                    .done(deferredResult.resolve)
                    .fail((error) => {
                        error.autoAttendantId = autoAttendantId;
                        error.autoAttendantName = name;
                        deferredResult.reject(error);
                    });
            });
        };

        const _validateAutoAttendantRoutesToOneEndpoint = () => {
            return _promiseFactory.defer((deferredResult) => {
                const autoAttendantSubMenuItems = _autoAttendantViewModel.connector().subMenuItems();
                const isValid = autoAttendantSubMenuItems.some((subMenuItem) => {
                   return subMenuItem.connector() !== null;
                });
                _autoAttendantViewModel.isMenuInvalid(!isValid);
                deferredResult.resolve(isValid);
            });
        };

        const _validateAutoAttendantSubMenuRoutesToOneEndpoint = () => {
            return _promiseFactory.defer((deferredResult) => {
                const autoAttendantSubMenuItems = _autoAttendantViewModel.connector().subMenuItems();
                let isValid = false;
                if (!_autoAttendantViewModel.isMenuInvalid()) {
                    _validateSubMenuItems(autoAttendantSubMenuItems)
                        .done(result => {
                            isValid = result;
                        });
                    _autoAttendantViewModel.isSubMenusInvalid(!isValid);
                    deferredResult.resolve(isValid);
                } else {
                    deferredResult.resolve(true);
                }
            });
        };

        const _validateSubMenuItems = (subMenuItems) => {
            const validateSubMenuItem = (subMenuItem) => _promiseFactory.deferIndefinitely(
                submenuPromise => {
                    if (subMenuItem.connector() === null) {
                        submenuPromise.resolve(INACTIVE);
                    }
                    else if (subMenuItem.type() === _voiceRouteEnumerations.subMenu) {
                        _validateSubMenuItems(subMenuItem.connector().subMenuItems())
                            .fail(submenuPromise.reject)
                            .done(submenuItemsAreValid => {
                                submenuPromise.resolve(submenuItemsAreValid ? ACTIVE : INVALID);
                            });
                    } else {
                        submenuPromise.resolve(ACTIVE);
                    }
                });
            return _promiseFactory.deferIndefinitely(
                deferredResult => _promiseFactory.deferredList(subMenuItems, validateSubMenuItem)
                    .fail(deferredResult.reject)
                    .done(
                        (subMenuKeyStatusResults) => {
                            deferredResult.resolve(
                                !(subMenuKeyStatusResults.some(subMenuKeyStatus => subMenuKeyStatus === INVALID) ||
                                 subMenuKeyStatusResults.every(subMenuKeyStatus => subMenuKeyStatus === INACTIVE))
                            );
                        })
            );
        };

        const _validateAutoAttendantMenuVoicePrompts = () => {
            return _promiseFactory.defer((deferredResult) => {
                let subMenus = [];
                let areSubMenuPromptsValid = true;
                const autoAttendantSubMenu = _autoAttendantViewModel.connector();
                const autoAttendantSubMenuItems = autoAttendantSubMenu.subMenuItems();

                subMenus.push(autoAttendantSubMenu);
                _getSubMenus(autoAttendantSubMenuItems, subMenus);

                subMenus.forEach((subMenu) => {
                    subMenu.subMenuItems().forEach((subMenuItem) => {
                        if (subMenuItem.type() === _voiceRouteEnumerations.subMenu) {
                            subMenuItem.connector().forceValidation();
                            if (subMenuItem.connector().selectedPrompt().id === _inlinePromptEnumerations.SELECT_PROMPT.id) {
                                subMenuItem.isVoicePromptEnabled(false);
                                subMenuItem.isVoicePromptInvalid(true);
                                areSubMenuPromptsValid = false;
                            } else {
                                subMenuItem.isVoicePromptEnabled(true);
                                subMenuItem.isVoicePromptInvalid(false);
                            }
                        }
                    });
                });

                const isValid = areSubMenuPromptsValid;
                _autoAttendantViewModel.areMenuVoicePromptsInvalid(!isValid);
                deferredResult.resolve(isValid);
            });
        };

        const _getSubMenus = (subMenuItems, subMenus) => {
            subMenuItems.forEach((subMenuItem) => {
                if (subMenuItem.connector() !== null && subMenuItem.type() === _voiceRouteEnumerations.subMenu) {
                    subMenus.push(subMenuItem.connector());
                    _getSubMenus(subMenuItem.connector().subMenuItems(), subMenus);
                }
            });
        };

        const _validateAutoAttendantPromptSelected = () => {
            let isAutoAttendantPromptValid = true;
            if (_autoAttendantViewModel.selectedPrompt().id === _inlinePromptEnumerations.SELECT_PROMPT.id) {
                isAutoAttendantPromptValid = false;
                _autoAttendantViewModel.isVoiceAutoAttendantPromptInvalid(true);
            }
            return isAutoAttendantPromptValid;
        };
    };
});

