define('presentation/acceptUserInvite/viewModels/acceptUserInviteViewModel',['businessServices/authentication/sessionAccountInfo',
        'businessServices/browserSupport/browserType',
        'businessServices/url/url',
        'common/promises/promiseFactory',
        'presentation/acceptUserInvite/facades/acceptUserInviteFacade',
        'presentation/common/modal',
        'presentation/common/window/windowControl',
        'presentation/acceptUserInvite/viewModels/accountClosedViewModel',
        'presentation/acceptUserInvite/viewModels/addUserModalViewModel',
        'presentation/acceptUserInvite/viewModels/invalidLinkViewModel',
        'settings/navigationConfiguration'],
function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const URLBusinessServiceConstructor = require('businessServices/url/url');
        const _urlBusinessService = new URLBusinessServiceConstructor();

        const InvalidLinkContentConstructor = require('presentation/acceptUserInvite/viewModels/invalidLinkViewModel');
        const _browserType = require('businessServices/browserSupport/browserType');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _addUserModal = null;
        let _facade = null;
        let _modalService = null;
        let _sessionAccountInfo = null;
        let _windowControl = null;

        const _isLinkValid = (linkItems) => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.isLinkValid(linkItems)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        deferredObject.resolve(result.status);
                    });
            });
        };

        self.contentViewModel = ko.observable("");
        self.isContentCompositionComplete = ko.observable(false);
        self.isMobileDevice = ko.pureComputed(() => _browserType.isMobileDevice);

        self.onCompositionComplete = () => {
            self.isContentCompositionComplete(true);
        };

        self.navigateToHomePage = () => {
            _windowControl.openHomePage("_self");
        };

        self.activate = (accountId, userId, emailAddress, expirationDateTime, signature) => {
            const linkItems = {
                link: _urlBusinessService.getBaseURL() + "/invite-user/" + accountId + "/" + userId + "/" + emailAddress + "/" + expirationDateTime,
                signature: signature
            };

            const AcceptUserInviteFacadeConstructor = require('presentation/acceptUserInvite/facades/acceptUserInviteFacade');
            _facade = new AcceptUserInviteFacadeConstructor();
            _facade.init(_promiseFactory);

            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            const AddUserModalConstructor = require('presentation/acceptUserInvite/viewModels/addUserModalViewModel');
            _addUserModal = new AddUserModalConstructor();

            _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
            _windowControl = require('presentation/common/window/windowControl');

            return _initialize(linkItems);
        };

        const _initialize = (linkItems) => {
            _isLinkValid(linkItems)
                .fail((error) => {
                    throw error;
                })
                .done((linkValidationStatus) => {
                    switch (linkValidationStatus) {
                        case "valid":
                            _addUserModal.setLinkItems(linkItems);
                            self.contentViewModel(_addUserModal);
                            break;
                        case "complete":
                            const router = require('businessServices/router/router');
                            router.navigate(_navigationConfiguration.routesById.userProfile.url);
                            break;
                        case "invalid":
                            if (_sessionAccountInfo.isLoggedIn() && _sessionAccountInfo.isInviteInfoCapture()) {
                                _modalService.showModal(_addUserModal);
                                _addUserModal.setLinkItems({});
                            } else {
                                const invalidLinkContent = new InvalidLinkContentConstructor(false);
                                self.contentViewModel(invalidLinkContent);
                            }
                            break;
                        case "expired":
                            const expiredLinkContent = new InvalidLinkContentConstructor(true);
                            self.contentViewModel(expiredLinkContent);
                            break;
                        case "account_closed":
                            const AccountClosedModalConstructor = require('presentation/acceptUserInvite/viewModels/accountClosedViewModel');
                            const accountClosedModal = new AccountClosedModalConstructor();
                            _modalService.showModal(accountClosedModal);
                            break;
                        default:
                            const error = new Error("invalid accept invite status");
                            error.status = linkValidationStatus;
                            throw error;
                    }
                });

            return _promiseFactory.wait();
        };
    };
});

