define('presentation/analytics/viewModels/analyticsListViewModel',[
        'businessServices/authentication/sessionAccountInfo',
        'businessServices/contacts/contactsStateSingleton',
        'businessServices/events/eventManager',
        'common/collections/collectionSorter',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'common/time/datetimeFormatter',
        'constants/scrollEventSources',
        'constants/systemObjectIconNameConstants',
        'presentation/analytics/facades/analyticsListFacade',
        'presentation/common/analyticsGraph/callRecordView',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel',
        'common/converters/phoneNumberFormatter'
    ],
    function(
        /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
        SessionAccountInfo,
        /** @type import('businessServices/contacts/contactsStateSingleton') */
        _contactsState,
        /** @type import('businessServices/events/eventManager') */
        EventManager,
        /** @type typeof import('common/collections/collectionSorter') */
        CollectionSorter,
        /** @type typeof import('common/converters/phoneNumberFormatter') */
        PhoneNumberFormatter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('common/storage/commonState') */
        CommonState,
        /** @type typeof import('common/time/datetimeFormatter') */
        DateTimeFormatter,
        /** @type typeof import('constants/scrollEventSources') */
        ScrollEventSources,
        /** @type typeof import('constants/systemObjectIconNameConstants') */
        SystemObjectIconNameConstants
    ) {
        return function() {
            const self = this;

            const _promiseFactory = new PromiseFactory();

            const callRecordView = require('presentation/common/analyticsGraph/callRecordView');
            const FacadeConstructor = require('presentation/analytics/facades/analyticsListFacade');
            const _facade = new FacadeConstructor();

            const phoneNumberFormatter = new PhoneNumberFormatter();
            const _dateTimeFormatter = new DateTimeFormatter();

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            const DeleteCallMediaActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel');

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const _commonState = CommonState;
            const _messageConstants = require('constants/voicemailMessageConstants');
            const _userId = SessionAccountInfo.userId();
            const _systemObjectIconNames = SystemObjectIconNameConstants;
            const _i18n = require('i18next');

            let _deleteConfirmationViewModel = null;
            /** @type {IDisposable[]} */
            let _disposables = [];
            let _getNextPageEventId = null;
            const _eventManager = EventManager;
            let _outsideNumbers = [];
            const _LIST_ITEMS_PER_PAGE = 20;
            const DELETE_MEDIA_RESPONSE = 'yes';
            const CALL_RECORDING_TYPE = 'recording';
            const VOICEMAIL_TYPE = 'voicemail';
            let _getCallRecords = null;
            const _blockedText = _i18n.t('common:blocked');

            const TABLE_COLUMNS = {
                from: { sortValue: 'fromName', titleI18nKey: 'from' },
                to: { sortValue: 'toName', titleI18nKey: 'to' },
                status: { sortValue: 'statusI18nKey', titleI18nKey: 'type' },
                duration: { sortValue: 'duration', titleI18nKey: 'duration' },
                date: { sortValue: 'dateTime', titleI18nKey: 'date' }
            };

            self.callRecords = null;
            let _allCallRecordsLoaded = ko.observable(false);

            self.isLoadingNextPage = ko.observable(false);

            self.listItemsDisplayCount = ko.observable(0);
            self.listItems = ko.observable([]);

            self.sortBy = ko.observable(TABLE_COLUMNS.date.sortValue);
            self.sortAsc = ko.observable(false);

            self.dataGridTableHeaders = ko.observable(Object.keys(TABLE_COLUMNS).map((column) => {
                return {
                    value: column,
                    titleI18nKey: TABLE_COLUMNS[column].titleI18nKey,
                    isSorted: ko.pureComputed(() => self.sortBy() === TABLE_COLUMNS[column].sortValue),
                    isSortedAsc: self.sortAsc
                };
            }));

            const _updateSortBy = (columnSortValue) => {
                if (self.sortBy() === columnSortValue && self.sortAsc() === true) {
                    self.sortAsc(false);
                } else {
                    self.sortBy(columnSortValue);
                    self.sortAsc(true);
                }
            };


            self.listItemsSorted = ko.pureComputed(() => {
                let listItems = self.listItems();

                if (listItems && listItems.length === 0) {
                    self.listItemsDisplayCount(0);

                    return [];
                }

                self.isLoadingNextPage(true);

                if (_allCallRecordsLoaded() === false &&
                    (self.sortAsc() === true || self.sortBy() !== TABLE_COLUMNS.date.sortValue)
                ) {
                    _getCallRecords();

                    return;
                }

                const sorter = new CollectionSorter();

                sorter.sort(listItems, self.sortBy(), self.sortAsc());
                self.listItemsDisplayCount(_LIST_ITEMS_PER_PAGE);

                return listItems;
            });


            self.listItemsDisplay = ko.pureComputed(() => {
                if (self.listItemsSorted()) {
                    self.isLoadingNextPage(false);

                    return self.listItemsSorted().slice(0, self.listItemsDisplayCount());
                }

                self.isLoadingNextPage(true);

                return [];
            });

            self.showLoadingState = ko.observable(true);
            self.showEmptyState = ko.observable(false);

            const _getNextPage = (eventSource) => {
                if (eventSource !== ScrollEventSources.analytics) {
                    return;
                }

                if (_allCallRecordsLoaded()) {
                    if (self.listItemsDisplayCount() < self.listItems().length) {
                        self.isLoadingNextPage(true);
                        self.listItemsDisplayCount(self.listItemsDisplayCount() + _LIST_ITEMS_PER_PAGE);
                    }
                } else {
                    self.isLoadingNextPage(true);
                    _getCallRecords()
                        .done(() => {
                            self.listItemsDisplayCount(self.listItemsDisplayCount() + _LIST_ITEMS_PER_PAGE);
                        });
                }
            };

            const _populateList = () => {
                if (self.callRecords === null) {
                    self.listItems([]);
                    return;
                }

                let outsideNumbersMissingLocation = [];

                const getCallFromTo = (callRecord, getOutsideNumberFunction, getGuidFunctions) => {
                    const outsideNumber = getOutsideNumberFunction(callRecord);
                    if (outsideNumber !== null) {
                        if (_outsideNumbers[outsideNumber] === undefined) {
                            const contact = _contactsState.getContactByPhoneNumber(outsideNumber);
                            const contactNumber = contact ? contact.phoneNumbers().find(x => _phoneNumberFormatter.toEOneSixFour(x.rawPhoneNumber()) === outsideNumber) : null ;
                            const meta = contactNumber ? contactNumber.label() : ko.observable('');
                            _outsideNumbers[outsideNumber] = {
                                avatarUrl: contact ? contact.avatarUrl : null,
                                iconName: _systemObjectIconNames.user,
                                isForwardingNumber: false,
                                isHostedNumber: false,
                                isUser: false,
                                isUserGroup: false,
                                isVoicemailBox: false,
                                isContact: !!contact,
                                location: ko.observable(''),
                                displayMetaText: meta,
                                name: contact ? contact.displayName : phoneNumberFormatter.toInternationalWithParens(outsideNumber),
                                initials: contact ? contact.initials : ''
                            };

                            outsideNumbersMissingLocation.push(outsideNumber);

                        }
                        return _outsideNumbers[outsideNumber];
                    }

                    let guid = null;
                    let i = 0;
                    while (guid === null && i < getGuidFunctions.length) {
                        guid = getGuidFunctions[i](callRecord);
                        i++;
                    }
                    if (guid === null) {
                        return {
                            iconName: '',
                            isContact: false,
                            isUser: false,
                            isUserGroup: false,
                            isIconDisplay: false,
                            location: '',
                            name: '',
                            phoneNumber: '',
                        };
                    }

                    const _commonItem = Object.assign({},
                        _commonState.get(guid),
                        { isContact: false }
                    );

                    if (_commonItem.isVoicemailBox || _commonItem.isUser || _commonItem.isUserGroup) {
                        _commonItem.location = '';
                    }

                    if (_commonItem.isForwardingNumber || _commonItem.isHostedNumber) {
                        const isDefault = _commonItem.isHostedNumber ? _commonItem.hasDefaultName() : true;
                        _commonItem.location = isDefault ?  _commonItem.location :  _commonItem.phoneNumber;
                    }

                    return _commonItem;
                };

                const getCallDetailItemI18nStatus = (callDetailItem) => {
                    if (callDetailItem.humanDetectionFailed) {
                        return 'blocked';
                    } else if (callDetailItem.hasVoicemail) {
                        return 'voicemail';
                    } else if (callDetailItem.isOutbound) {
                        return 'outbound';
                    } else if (callDetailItem.wasMissed) {
                        return 'missed';
                    } else if (callDetailItem.wasSystem) {
                        return 'system';
                    } else {
                        return 'answered';
                    }
                };

                const buildCallDetailItem = (callRecord) => {
                    const callFrom = getCallFromTo(callRecord, callRecordView.fromOutsideNumber, [
                        callRecordView.fromUserId,
                        callRecordView.fromHostedNumberId,
                    ]);
                    const callTo = getCallFromTo(callRecord, callRecordView.toOutsideNumber, [
                        callRecordView.toForwardingNumberId,
                        callRecordView.toUserId,
                        callRecordView.toUserGroupId,
                        callRecordView.toVoicemailBoxId,
                        callRecordView.toHostedNumberId, // should be last
                    ]);

                    const callDetailItem = {
                        dateTime: callRecordView.callDateTime(callRecord),
                        date: _dateTimeFormatter.humanizedDay(callRecordView.callDateTime(callRecord)),
                        time: _dateTimeFormatter.customDateTimeTextFromIso(callRecordView.callDateTime(callRecord), "hh:mma"),
                        callFrom: callFrom,
                        fromName: callFrom.name,
                        callTo: callTo,
                        toName: callTo.name,
                        duration: callRecordView.durationInSeconds(callRecord),
                        hasRecording: callRecordView.hasCallRecording(callRecord),
                        hasVoicemail: callRecordView.hasVoicemail(callRecord),
                        isOutbound: callRecordView.isOutbound(callRecord),
                        callHistoryId: callRecordView.callHistoryId(callRecord),

                        iconName: '',
                        isExpanded: ko.observable(false),
                        isReady: ko.observable(false),
                        isLoading: ko.observable(false),
                        callDetails: ko.observable(null),

                        opacity: ko.observable(0),

                        wasMissed: callRecordView.wasMissed(callRecord),
                        wasAnswered: callRecordView.wasAnswered(callRecord),
                        wasSystem: callRecordView.wasSystem(callRecord),
                        humanDetectionFailed: callRecordView.humanDetectionFailed(callRecord),
                    };

                    callDetailItem.statusI18nKey = getCallDetailItemI18nStatus(callDetailItem);

                    return callDetailItem;
                };

                return _promiseFactory.deferIndefinitely(promise => {
                    if (_getNextPageEventId === null) {
                        _getNextPageEventId = _eventManager.subscribeBottomScrollBufferReached(_getNextPage);
                    }

                    const listItems = self.callRecords().map(callRecord => buildCallDetailItem(callRecord));
                    self.listItems(listItems);

                    if (outsideNumbersMissingLocation.length !== 0) {
                        _facade.getLocations(outsideNumbersMissingLocation)
                            .fail(promise.reject)
                            .done(numbersWithLocations => {
                                numbersWithLocations.forEach(numberWithLocation => {
                                    _outsideNumbers[numberWithLocation.number].location(phoneNumberFormatter.cleanupLocationSansTollFree(numberWithLocation.location));
                                });
                            });
                    }
                    promise.resolve();
                });
            };

            self.expandCallDetails = (callDetailItem) => {
                callDetailItem.isExpanded(!callDetailItem.isExpanded());

                if (callDetailItem.callDetails() === null) {
                    callDetailItem.isLoading(true);
                    buildExpandedCallDetailItem(callDetailItem);
                }
            };

            const _getMessageStatusI18n = (hasMessage, hasSubscription, isDeleted, deletedDateTime, messageStatus, isStatusMouseOver, humanDetectionFailed) => {
                if (isDeleted) {
                    return {key: 'deleted', options: {date: _dateTimeFormatter.displayDateTime(deletedDateTime)}};
                }
                if (humanDetectionFailed) {
                    return {key: 'blocked'};
                }
                if (!hasMessage) {
                    return {key: 'na'};
                }
                if (!hasSubscription) {
                    return {key: 'notSubscribed'};
                }
                if (isStatusMouseOver) {
                    return {key: messageStatus, options: {context: 'mouseOver'}};
                }
                return {key: messageStatus};
            };

            const toggleMessageStatus = (messageId, statusFn, facadeCallBack, isStatusMouseOver) => {
                const originalStatus = statusFn();
                const isStatusNewOrReviewed = [
                    _messageConstants.status.new.status,
                    _messageConstants.status.reviewed.status
                ].includes(originalStatus);

                if (isStatusNewOrReviewed) {
                    return _promiseFactory.defer(promise => {
                        const {id, status} = originalStatus === _messageConstants.status.new.status ?
                            _messageConstants.status.reviewed :
                            _messageConstants.status.new;

                        statusFn(status);
                        isStatusMouseOver(false);
                        facadeCallBack(messageId, id)
                            .done(promise.resolve)
                            .fail(() => {
                                statusFn(originalStatus);
                                promise.reject();
                            });
                    });
                }
            };

            const hostedNumbersSubscribers = [];

            const _isSubscriber = (subscriberIds => {
                if (subscriberIds === undefined) {
                    return false;
                }
                if (subscriberIds.includes(_userId)) {
                    return true;
                }
                return subscriberIds.some(id => {
                    const commonItem = _commonState.get(id);
                    if (commonItem.type === _commonState.types.userGroup) {
                        return commonItem.members().includes(_userId);
                    }
                });
            });

            const buildExpandedCallDetailItem = (analyticsListItem) => {
                const spinEndTime = 500 + new Date().getTime();

                _facade.getCallDetails(analyticsListItem.callHistoryId)
                    .done(callDetail => {
                        const {recording, voicemail} = callDetail;
                        const setCommonProperties = (object) => {
                            object.callerAniDisplay = analyticsListItem.callFrom.name;
                            object.callerAvatar = analyticsListItem.callFrom.avatarUrl;
                            object.callerInitials = analyticsListItem.callFrom.initials;
                            object.fromLocation = analyticsListItem.callFrom.location;
                            object.hasMessage = ko.observable(false);
                            object.hasSubscription = ko.observable(false);
                            object.isDeleted = ko.observable(false);
                            object.messageStatus = ko.observable("");
                            object.isStatusMouseOver = ko.observable(false);
                            object.humanDetectionFailed = ko.observable(analyticsListItem.humanDetectionFailed);
                            object.messageStatusI18n = ko.pureComputed(
                                () => _getMessageStatusI18n(
                                    object.hasMessage(),
                                    object.hasSubscription(),
                                    object.isDeleted(),
                                    object.deletedDateTime(),
                                    object.messageStatus(),
                                    object.isStatusMouseOver(),
                                    object.humanDetectionFailed()
                                )
                            );
                            object.isMessageReviewed = ko.pureComputed(() => object.messageStatusI18n().key === _messageConstants.status.reviewed.status);
                            object.isMessageNew = ko.pureComputed(() => object.messageStatusI18n().key === _messageConstants.status.new.status);
                            object.messageStatusMouseOver = () => object.isStatusMouseOver(true);
                            object.messageStatusMouseOut = () => object.isStatusMouseOver(false);

                            if (analyticsListItem.callFrom.isContact) {
                                object.callerType = _commonState.types.contact;
                                object.fromLocation = '';
                            } else if (analyticsListItem.callFrom.isUser) {
                                object.callerType = _commonState.types.user;
                                object.callerAvatar = analyticsListItem.callFrom.avatar;
                            } else {
                                object.callerType = 'external';
                            }
                        };

                        setCommonProperties(recording);

                        const phoneConnectorStep = callDetail.callDetailSteps.find(step => step.phoneConnectorId !== undefined);
                        recording.callRecordingBoxName = phoneConnectorStep.hasOwnProperty('phoneNumberName') ? phoneConnectorStep.phoneNumberName : "";
                        recording.toLocation = phoneConnectorStep.hasOwnProperty('phoneNumber') ? phoneNumberFormatter.toInternationalWithParens(phoneConnectorStep.phoneNumber) : "";
                        recording.callRecordingId = recording.recordingMessageId;
                        recording.isOutbound = analyticsListItem.isOutbound;
                        recording.deletedDateTime = ko.observable(recording.recordingDeletedDateTime);
                        recording.toggleMessageStatus = () => toggleMessageStatus(
                            recording.recordingMessageId,
                            recording.messageStatus,
                            _facade.setCallRecordingStatus,
                            recording.isStatusMouseOver
                        );

                        if (phoneConnectorStep) {
                            const hostedNumber = _commonState.get(phoneConnectorStep.phoneConnectorId);
                            recording.callRecordingBoxName = hostedNumber.name;
                            recording.toLocation = hostedNumber.hasDefaultName() ? hostedNumber.location() : phoneNumberFormatter.toInternationalWithParens(hostedNumber.phoneNumber());
                        }

                        if (analyticsListItem.callTo.isContact) {
                            recording.callRecordingBoxAvatar = analyticsListItem.callTo.avatarUrl;
                            recording.callRecordingBoxName = analyticsListItem.callTo.name;
                            recording.callRecordingBoxInitials = analyticsListItem.callTo.initials;
                            recording.toLocation = '';
                        }

                        setCommonProperties(voicemail);

                        voicemail.voicemailBoxName = ko.unwrap(analyticsListItem.toName);
                        voicemail.deletedDateTime = ko.observable(voicemail.voicemailDeletedDateTime);
                        voicemail.toggleMessageStatus = () => toggleMessageStatus(
                            voicemail.voicemailMessageId,
                            voicemail.messageStatus,
                            _facade.setVoicemailMessageStatus,
                            voicemail.isStatusMouseOver
                        );

                        let ownerId = analyticsListItem.callTo.id;
                        if (ownerId) {
                            let owner = _commonState.get(ownerId);
                            if (analyticsListItem.callTo.isUser) {
                                voicemail.ownerType = "user";
                                voicemail.ownerUserAvatar = owner.avatar;
                                voicemail.voicemailBoxName = owner.name;
                            } else if (analyticsListItem.callTo.isUserGroup) {
                                voicemail.ownerType = "userGroup";
                                voicemail.ownerUserGroupId = ownerId;
                                voicemail.voicemailBoxName = owner.name;
                            }
                        } else {
                            voicemail.ownerType = "standalone";
                            voicemail.voicemailBoxName = analyticsListItem.callTo.name;
                        }

                        if (voicemail.status === "success") {
                            if (voicemail.voicemailIsDeleted) {
                                voicemail.isDeleted(true);
                            }
                            voicemail.hasMessage(true);
                            voicemail.audioObject = ko.observable({
                                audioData: ko.observable(voicemail.voicemailAudioUrl),
                            });
                            const voicemailBoxId = callDetail.callDetailSteps.find(step => step.voicemailBoxId !== undefined).voicemailBoxId;
                            voicemail.subscriberIds = ko.pureComputed(() => _commonState.get(voicemailBoxId).subscribers());
                            voicemail.hasSubscription = ko.pureComputed(() => _isSubscriber(voicemail.subscriberIds()));
                            voicemail.messageStatus(voicemail.voicemailMessageStatus);
                            voicemail.setMessageReviewed = () => {
                                if (voicemail.messageStatus() === _messageConstants.status.new.status) {
                                    voicemail.toggleMessageStatus();
                                }
                            };
                            voicemail.delete = () => _deleteConfirmationModal(VOICEMAIL_TYPE,
                                voicemail,
                                _facade.deleteVoicemailMessage,
                                voicemail.isDeleted,
                                voicemail.deletedDateTime);
                            voicemail.getDownloadUrl = () => _promiseFactory.defer(promise => {
                                if (voicemail.hasMessage()) {
                                    promise.resolve(voicemail.audioObject().audioData());
                                }
                                promise.resolve(null);
                            });
                        }

                        if (recording.status === "success") {
                            if (recording.recordingIsDeleted) {
                                recording.isDeleted(true);
                            }
                            recording.hasMessage(true);
                            recording.audioObject = ko.observable({
                                audioData: ko.observable(recording.recordingAudioUrl),
                            });
                            const hostedNumberId = callDetail.callDetailSteps.find(step => step.phoneConnectorId !== undefined).phoneConnectorId;
                            if (!hostedNumbersSubscribers[hostedNumberId]) {
                                hostedNumbersSubscribers[hostedNumberId] = ko.observable(recording.subscriberIds);
                            }
                            recording.subscriberIds = ko.pureComputed(() => hostedNumbersSubscribers[hostedNumberId]());
                            recording.hasSubscription = ko.pureComputed(() => _isSubscriber(recording.subscriberIds()));
                            recording.messageStatus(recording.recordingMessageStatus);
                            recording.setMessageReviewed = () => {
                                if (recording.messageStatus() === _messageConstants.status.new.status) {
                                    recording.toggleMessageStatus();
                                }
                            };
                            recording.delete = () => _deleteConfirmationModal(CALL_RECORDING_TYPE,
                                recording,
                                _facade.deleteCallRecording,
                                recording.isDeleted,
                                recording.deletedDateTime);
                            recording.getDownloadUrl = () => _promiseFactory.defer(promise => {
                                if (recording.hasMessage()) {
                                    promise.resolve(recording.audioObject().audioData());
                                }
                                promise.resolve(null);
                            });
                        }

                        callDetail.callId = analyticsListItem.callHistoryId;
                        callDetail.shouldDisplayMap = callDetail.mapUrl !== null;

                        callDetail.callerLocation.cityState = phoneNumberFormatter.cleanupLocationSansTollFree(callDetail.callerLocation.cityState);
                        callDetail.calleeLocation.cityState = phoneNumberFormatter.cleanupLocationSansTollFree(callDetail.calleeLocation.cityState);

                        callDetail.humanDetectionFailed = analyticsListItem.humanDetectionFailed;
                        if (analyticsListItem.humanDetectionFailed) {
                            callDetail.firstCallDetailStep.humanDetectionFailed = true;
                        }

                        analyticsListItem.callDetails(callDetail);

                        analyticsListItem.isReady(true);

                        setTimeout(() => {
                            analyticsListItem.isLoading(false);
                            setTimeout(() => analyticsListItem.opacity(1), 100);
                        }, spinEndTime - new Date().getTime());
                    });
            };

            const _deleteConfirmationModal = (type, mediaObject, callback, isDeleted, deletedDateTime) => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    let id = type === VOICEMAIL_TYPE ? mediaObject.voicemailMessageId : mediaObject.recordingMessageId;
                    let modalConstructorParams = [mediaObject, mediaObject.getDownloadUrl, mediaObject.setMessageReviewed];

                    _deleteConfirmationViewModel
                        .clearModal()
                        .setHeaderText({i18n: 'deleteConfirmation'})
                        .setSubmitButtonText({i18n: 'delete'})
                        .setContentViewModel(DeleteCallMediaActionViewModelConstructor, modalConstructorParams)
                        .showModal()
                        .fail(promise.reject)
                        .done(response => {
                            if (response === DELETE_MEDIA_RESPONSE) {
                                isDeleted(true);
                                deletedDateTime(new Date());
                                callback(id)
                                    .done(promise.resolve)
                                    .fail(() => {
                                        isDeleted(false);
                                        promise.reject();
                                    });
                            } else {
                                promise.resolve();
                            }
                        });
                });
            };

            const findCallRecording = (callRecordingId) => {
                const message = self.listItems().find(item => {
                    const callDetail = item.callDetails();
                    return callDetail && callDetail.recording.recordingMessageId === callRecordingId;
                });
                return message;
            };

            const findVoicemailMessage = (voicemailMessageId) => {
                const message = self.listItems().find(item => {
                    const callDetail = item.callDetails();
                    return callDetail && callDetail.voicemail.voicemailMessageId === voicemailMessageId;
                });
                return message;
            };

            const getStatusFromId = (statusId) => {
                const {status} = statusId === _messageConstants.status.new.id ?
                    _messageConstants.status.new :
                    _messageConstants.status.reviewed;
                return status;
            };

            const onCallRecordingStatusChanged = ((callRecordingId, statusId) => {
                const message = findCallRecording(callRecordingId);

                if (message) {
                    message.callDetails().recording.messageStatus(getStatusFromId(statusId));
                }
            });

            const onVoicemailMessageStatusChanged = ((voicemailMessageId, statusId) => {
                const message = findVoicemailMessage(voicemailMessageId);

                if (message) {
                    message.callDetails().voicemail.messageStatus(getStatusFromId(statusId));
                }
            });

            const onCallRecordingDeleted = (callRecordingId) => {
                const message = findCallRecording(callRecordingId);
                if (message) {
                    message.callDetails().recording.isDeleted(true);
                }
            };

            const onVoicemailMessageDeleted = (voicemailMessageId) => {
                const message = findVoicemailMessage(voicemailMessageId);
                if (message) {
                    message.callDetails().voicemail.isDeleted(true);
                }
            };

            const onCallRecordingSubscribersChanged = (hostedNumberId, subscriberIds) => {
                const hostedNumberSubscribers = hostedNumbersSubscribers[hostedNumberId];
                if (hostedNumberSubscribers) {
                    hostedNumberSubscribers(subscriberIds);
                }
            };

            self.sortColumn = (column) => {
                _updateSortBy(TABLE_COLUMNS[column.value].sortValue);
            };

            self.detached = () => {
                _disposables.forEach(d => d.dispose());
                _disposables = [];
                _eventManager.unsubscribe(_getNextPageEventId);
            };

            self.activate = function(activationObject) {
                self.callRecords = activationObject.chartData.callRecords;
                _getCallRecords = activationObject.getCallRecords;
                _allCallRecordsLoaded = activationObject.allCallRecordsLoaded;
                _disposables.push(self.callRecords.subscribe(_populateList));

                _deleteConfirmationViewModel = new ActionModalViewModelConstructor();

                _facade.onCallRecordingDeleted(onCallRecordingDeleted);
                _facade.onCallRecordingStatusChanged(onCallRecordingStatusChanged);
                _facade.onCallRecordingSubscribersChanged(onCallRecordingSubscribersChanged);
                _facade.onVoicemailMessageDeleted(onVoicemailMessageDeleted);
                _facade.onVoicemailMessageStatusChanged(onVoicemailMessageStatusChanged);

                self.showLoadingState = activationObject.showLoadingState;
                self.showEmptyState = activationObject.showEmptyState;

                return _initialize();
            };

            const _initialize = () => {
                _facade.init(_promiseFactory);
                _populateList();
                return _promiseFactory.wait();
            };
        };
    });

