/**
 * @module common/uniqueId/userGroupIdUtil
 */
define('common/uniqueId/userGroupIdUtil',[], function() {
    /*
     Determines the textual groupId of a user group
     @returns string
     */
    const convertGroupTypeToGroupId = (userGroupId, groupType) => {
        switch (groupType) {
            case 1:
                return "systemGroup-allUsers";
            case 2:
                return "systemGroup-billingAdmins";
            case 3:
                return "systemGroup-systemAdmins";
            default:
                return userGroupId;
        }
    };

    return {
        convertGroupTypeToGroupId: convertGroupTypeToGroupId,
    };
});

