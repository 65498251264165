define('businessServices/login/accountStatusEvaluator',[
    'constants/accountStatusEnumerations',
    'businessServices/specifications/isFraudulentStatusSpecification',
    'common/promises/promiseFactory',
    'persistence/dataProviders/accountDataProvider',
    'businessServices/authentication/sessionWorkflowFlags',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/events/eventManager'
], function () {

    var _evaluateResponses = {
        closedBalanceOwed: "closedBalanceOwed",
        closedDoNotReactivate: "closedDoNotReactivate",
        closedNoBalance: "closedNoBalance",
        pastDueBalance: "pastDueBalance",
        miscellaneousProblems: "miscellaneousProblems",
        closedAndCannotReactivate: "closedAndCannotReactivate",
        deactivatedUser: "deactivatedUser",
        normal: "normal",
        error: "error",
        creationInProgress: "creationInProgress"
    };

    var _evaluatorState = {
        isInitialized: false,
        accountDataProvider: null
    };
    var _accountState = {
        status : null,
        currentBalance : null,
        pastDueBalance : null,
        fraudStatus : null,
        miscProblemsFlag : null,
        doNotReactivate : null,
        creationInProgress: null,
    };

    var _accountStatusEnumerations = require('constants/accountStatusEnumerations');
    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();

    var IsFraudStatusSpecificationConstructor = require('businessServices/specifications/isFraudulentStatusSpecification');
    var _isFraudStatusSpecification = new IsFraudStatusSpecificationConstructor();

    var _eventManager = require('businessServices/events/eventManager');
    var _sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');
    var _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

    var _loadAllData = function () {
        var loadAllDataPromise = new PromiseFactoryConstructor();

        loadAllDataPromise.defer(function(deferredObject) {
            loadAccountStatusData()
                .done(function() {
                    deferredObject.resolve();
                })
                .fail(function(error) {
                    deferredObject.reject(error);
                });
        });

        loadAllDataPromise.defer(function(deferredObject) {
            loadAccountBalanceData()
                .done(function() {
                    deferredObject.resolve();
                })
                .fail(function(error) {
                    deferredObject.reject(error);
                });
        });

        return loadAllDataPromise.wait();
    };

    function loadAccountStatusData() {
        return _promiseFactory.defer(function (deferredObject) {
            _evaluatorState.accountDataProvider.getAccount()
                .done(function (accountData) {
                    _accountState.status = accountData.status;
                    _accountState.fraudStatus = accountData.fraudStatus;
                    _accountState.miscProblemsFlag = accountData.flags.miscProblems;
                    _accountState.doNotReactivate = accountData.flags.doNotReactivate;
                    _accountState.creationInProgress = accountData.flags.signup;
                    deferredObject.resolve();
                })
                .fail(function (error) {
                    deferredObject.reject(error);
                });
        });
    }

    function loadAccountBalanceData() {
        return _promiseFactory.defer(function (deferredObject) {
            _evaluatorState.accountDataProvider.getBalanceInformation()
                .done(function (balanceData) {
                    if (_accountState.pastDueBalance !== balanceData.pastDueBalance) {
                        _eventManager.publishPastDueBalance(balanceData.pastDueBalance);
                    }
                    _accountState.currentBalance = balanceData.currentBalance;
                    _accountState.pastDueBalance = balanceData.pastDueBalance;
                    deferredObject.resolve();
                })
                .fail(function (error) {
                    deferredObject.reject(error);
                });
        });
    }

    // Evaluate state of the metadata
    var _isClosed = function () {
        return _accountState.status === _accountStatusEnumerations.status.closed;
    };

    var _isActive = function () {
        return _accountState.status === _accountStatusEnumerations.status.active;
    };

    // Evaluate what their route is
    function isClosedBalanceOwed() {
        var accountPermissions = _sessionAccountInfo.accountPermissions();
        if (_sessionWorkflowFlags.hasBypassedClosedAccount() === true) {
            return false;
        }

        if (_isClosed()) {
            if (_accountState.currentBalance > 0) {
                return accountPermissions["Billing.PayBalance"] === true;
            }
        }
        return false;
    }

    function isClosedDoNotReactivate() {
        var accountPermissions = _sessionAccountInfo.accountPermissions();
        if (_sessionWorkflowFlags.hasBypassedClosedAccount() === true) {
            return false;
        }

        if (_isClosed()) {
            if (_accountState.doNotReactivate === true) {
                return accountPermissions["Billing.CanReactivate"] === true;
            }
            if (_isFraudStatusSpecification.isSatisfiedBy(_accountState.fraudStatus)) {
                return accountPermissions["Billing.CanReactivate"] === true;
            }
        }
        return false;
    }

    function isClosedNoBalance() {
        var accountPermissions = _sessionAccountInfo.accountPermissions();
        if (_sessionWorkflowFlags.hasBypassedClosedAccount() === true) {
            return false;
        }

        if (_isClosed()) {
            return accountPermissions["Billing.CanReactivate"] === true;
        }
        return false;
    }

    function isClosedAndCannotReactivate() {
        var accountPermissions = _sessionAccountInfo.accountPermissions();
        if (_isClosed()) {
            return accountPermissions["Billing.CanReactivate"] !== true;
        }
        return false;
    }

    function isPastDueBalance() {
        if (_sessionWorkflowFlags.hasBypassedPastDueBalance() === true) {
            return false;
        }

        if (_isActive()) {
            if (_accountState.pastDueBalance > 0) {
                return true;
            }
        }
        return false;
    }

    function isMiscellaneousProblems() {

        if (_sessionWorkflowFlags.hasBypassedMiscProblems() === true) {
            return false;
        }
        if (_isActive()) {
            if (_accountState.miscProblemsFlag === true) {
                return true;
            }
        }
        return false;
    }

    const _isCreationInProgress = () => _accountState.signup === true;

    function isDeactivatedUser() {
        return _sessionAccountInfo.userStatus() === "deactivated";
    }

    function isNormalLogin() {
        if (_sessionWorkflowFlags.hasBypassedClosedAccount() === true) {
            return true;
        }

        if (_isActive()) {
            return true;
        }
        return false;
    }

    var _initialize = function () {
        if(_evaluatorState.accountDataProvider === null) {
            var AccountDataProviderConstructor = require('persistence/dataProviders/accountDataProvider');
            _evaluatorState.accountDataProvider = new AccountDataProviderConstructor();
        }
    };

    var _bypassRouteEvaluation = function() {
        var accountPermissions = _sessionAccountInfo.accountPermissions();

        if (accountPermissions["Billing.PayBalance"] !== true) {
            _sessionWorkflowFlags.hasBypassedPastDueBalance(true);
        }

        if (accountPermissions["ViewMiscDialog"] !== true) {
            _sessionWorkflowFlags.hasBypassedMiscProblems(true);
        }
    };

    var _performEvaluation = function() {
        _bypassRouteEvaluation();
        // Order of clauses is relevant
        if (_isCreationInProgress()) {
            return _evaluateResponses.creationInProgress;
        }
        if (isClosedBalanceOwed()) {
            return _evaluateResponses.closedBalanceOwed;
        } else if (isClosedDoNotReactivate()) {
            return _evaluateResponses.closedDoNotReactivate;
        } else if (isClosedNoBalance()) {
            return _evaluateResponses.closedNoBalance;
        } else if (isClosedAndCannotReactivate()) {
            return _evaluateResponses.closedAndCannotReactivate;
        } else if (isPastDueBalance()) {
            return _evaluateResponses.pastDueBalance;
        } else if (isMiscellaneousProblems()) {
            return _evaluateResponses.miscellaneousProblems;
        } else if (isDeactivatedUser()) {
            return _evaluateResponses.deactivatedUser;
        } else if (isNormalLogin()) {
            return _evaluateResponses.normal;
        } else {
            throw new Error("Unknown account state");
        }
    };

    var _evaluate = function () {
        return _promiseFactory.defer(function (deferredObject) {
            if (_evaluatorState.isInitialized) {
                var result = _performEvaluation();
                deferredObject.resolve(result);
            } else {
                _initialize();
                _loadAllData()
                    .done(function () {
                        _evaluatorState.isInitialized = true;
                        var result = _performEvaluation();
                        deferredObject.resolve(result);
                    })
                    .fail(function (error) {
                        deferredObject.reject(error);
                    });
            }
        });
    };

    function receiveAlertLoginSuccessful() {
        _evaluatorState.isInitialized = false;
    }

    function receiveAlertLogout() {
        _evaluatorState.isInitialized = false;
    }

    function refresh() {
        _evaluatorState.isInitialized = false;
    }

    return {
        evaluateResponses: _evaluateResponses,
        evaluate: _evaluate,
        refresh : refresh,
        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});

