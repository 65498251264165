define('presentation/settings/phoneNumbers/viewModels/addPhoneNumberTollFreeViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/numberPickerConstants',
    'constants/smsCampaignRegistrationConstants',
    'businessServices/numberProvisioning/numberProvisioningStore',
    'businessServices/state/modelStateObserver',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/unsavedChangesActionViewModel',
    'presentation/settings/phoneNumbers/facades/addPhoneNumberTollFreeFacade',
    'settings/navigationConfiguration',
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const UnsavedChangesActionViewModelConstructor = require('presentation/common/actionModal/viewModels/unsavedChangesActionViewModel');

        const _browserType = require('businessServices/browserSupport/browserType');
        const _router = require('businessServices/router/router');

        const _i18n = require('i18next');
        const _numberPickerConstants = require('constants/numberPickerConstants');
        const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _facade = null;
        let _urlFormatter = null;
        let _isActivated = false;
        let _numberProvisioningStore = null;

        const _buildResultsForSummary = (addPhoneNumbersResult) => {
            const successfulPhoneNumbers = addPhoneNumbersResult.successfulPhoneNumbers;
            const failedPhoneNumbers = addPhoneNumbersResult.failedPhoneNumbers;
            const selectedNumbersDict = self.selectedPhoneNumbers().reduce((selectedPhoneNumbers, selectedPhoneNumber) => {
                selectedPhoneNumbers[selectedPhoneNumber.e164PhoneNumber()] = selectedPhoneNumber;
                return selectedPhoneNumbers;
            }, {});

            const formattedSuccessfulNumbers = _formatNumbers(successfulPhoneNumbers, selectedNumbersDict);
            const formattedFailedNumbers = _formatNumbers(failedPhoneNumbers, selectedNumbersDict);

            return { formattedSuccessfulNumbers, formattedFailedNumbers };
        };

        const _formatNumbers = (numbers, numberDictionary) => {
            return numbers.map((number) => {
                const phoneNumberObject = numberDictionary[number];

                return {
                    number: phoneNumberObject.formattedPhoneNumber(),
                    location: "",
                    hasLocation: false,
                };
            });
        };

        const _exitWithWarning = (navigate) => {
            if (self.selectedPhoneNumbers().length === 0) {
                self.modelStateObserver.navigateWithoutWarning = true;
                navigate();
            } else {
                let actionModal = new ActionModalViewModelConstructor();
                actionModal
                    .clearModal()
                    .setContentViewModel(UnsavedChangesActionViewModelConstructor, [{i18n: 'actionModal:unsavedPhoneNumbersText'}])
                    .setHeaderText({i18n: 'unsavedPhoneNumbers'})
                    .setCancelButtonText({i18n: 'cancel'})
                    .setSubmitButtonText({i18n: 'continue'})
                    .showModal()
                    .then(result => {
                        if (result === "yes") {
                            const phoneNumbers = self.selectedPhoneNumbers().map(phoneNumber => phoneNumber.e164PhoneNumber());
                            _facade.cancelReservations(phoneNumbers);
                            self.modelStateObserver.navigateWithoutWarning = true;
                            navigate();
                        }
                    });
            }
        };

        self.numberType = _numberPickerConstants.numberTypes.tollFree;
        self.selectedPhoneNumbers = ko.observableArray([]).extend({observeState: true});

        self.failedReservations = ko.observableArray([]);
        self.showFailedReservations = ko.pureComputed(() => self.failedReservations().length > 0);

        self.showMustSelectANumber = ko.observable(false);

        self.modelStateObserver = null;
        self.pricingUrl = null;
        self.smsPhoneNumberLimit = ko.observable();
        self.smsPhoneNumberLimitReason = ko.observable();
        self.smsEnabledLocalHostedNumbers = ko.observable([]);
        self.isContentScrollable = ko.observable(false);
        self.isScrollActive = ko.computed(() => self.isContentScrollable() || _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
        self.headerButtonText = _i18n.t('addPhoneNumberTollFree:add');

        self.isSaveDisabled = ko.pureComputed(() => self.selectedPhoneNumbers().length > _numberPickerConstants.maxNumbersCanAdd || self.selectedPhoneNumbers().length === 0);

        self.provisionNumbers = () => _promiseFactory.deferIndefinitely(promise => {
            if (self.selectedPhoneNumbers().length === 0) {
                self.showMustSelectANumber(true);
                promise.resolve();
                return;
            }

            const phoneNumbers = self.selectedPhoneNumbers().map(phoneNumber => phoneNumber.e164PhoneNumber());
            _facade.provisionNumbers(phoneNumbers)
                .fail(promise.reject)
                .done(result => {
                    self.modelStateObserver.saveData();

                    const { formattedSuccessfulNumbers, formattedFailedNumbers } = _buildResultsForSummary(result);

                    _numberProvisioningStore.setNumberType(_numberPickerConstants.numberTypes.local);
                    _numberProvisioningStore.setSmsPhoneNumberLimit(self.smsPhoneNumberLimit());
                    _numberProvisioningStore.setSmsPhoneNumberLimitReason(self.smsPhoneNumberLimitReason());
                    _numberProvisioningStore.addSmsEnabledLocalHostedNumbers(self.smsEnabledLocalHostedNumbers());
                    _numberProvisioningStore.addSuccessfulNumbers(formattedSuccessfulNumbers);
                    _numberProvisioningStore.addFailedNumbers(formattedFailedNumbers);

                    promise.resolve();

                    _router.navigate(_navigationConfiguration.routesById.newPhoneNumberSummary.url);
                });
        });

        self.cancelClick = () => _exitWithWarning(() => _router.navigate(_navigationConfiguration.routesById.phoneNumbers.url));

        self.backClick = () => _exitWithWarning(() => _router.navigate(_navigationConfiguration.routesById.newPhoneNumberType.url));

        self.activate = () => {
            if (_isActivated) {
                return;
            }

            _isActivated = true;

            const FacadeConstructor = require('presentation/settings/phoneNumbers/facades/addPhoneNumberTollFreeFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const NumberProvisioningStoreConstructor = require('businessServices/numberProvisioning/numberProvisioningStore');
            _numberProvisioningStore = new NumberProvisioningStoreConstructor();
            _numberProvisioningStore.init();

            _urlFormatter = require('common/url/urlFormatter');

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.setWarningMessageKey('actionModal:unsavedPhoneNumbersText');
            self.modelStateObserver.commitData();
            self.pricingUrl = _urlFormatter.buildFrontendUrl(["pricing"]);

            _facade.getSmsPhoneNumberLimits()
                .done((response) => {
                    self.smsPhoneNumberLimit(response.accountSmsLimits.smsPhoneNumberLimit);
                    self.smsPhoneNumberLimitReason(response.accountSmsLimits.smsPhoneNumberLimitReason);
                    self.smsEnabledLocalHostedNumbers(response.smsEnabledLocalHostedNumbers);
                });

            return _promiseFactory.wait();
        };
    };
});

