define(
    'presentation/common/userAvatar/viewModels/userAvatarViewModel',['constants/systemObjectIconNameConstants'],
function(
    /** @type typeof import('constants/systemObjectIconNameConstants') */
    SystemObjectIconNames
){
    return function() {
        const self = this;

        /** @type {IUserAvatarProperties} */
        let _settings = null;

        /** @type {KnockoutObservable<IUserAvatar> | IUserAvatar} */
        self.avatar = ko.observable();
        self.avatarHeight = ko.observable('28');
        self.avatarWidth = ko.observable('28');
        self.containerHeight = ko.observable(48);
        self.containerWidth = ko.observable(48);
        /** @type {KnockoutObservable<string> | string} */
        self.iconName = SystemObjectIconNames.user;

        self.showDefaultImageBinding = ko.pureComputed(() => {
            const avatar = ko.unwrap(self.avatar);
            return avatar && avatar.showDefaultImage() || !avatar;
        });

        /**
         * @param {IUserAvatarProperties} settings
         */
        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.avatar === null) {
                const missingAvatar = new Error("Avatar must be provided");
                throw missingAvatar;
            }
            self.avatar = _settings.avatar;

            if (_settings.avatarHeight) {
                self.avatarHeight(_settings.avatarHeight);
            }

            if (_settings.avatarWidth) {
                self.avatarWidth(_settings.avatarWidth);
            }

            if (_settings.containerHeight) {
                self.containerHeight(_settings.containerHeight);
            }

            if (_settings.containerWidth) {
                self.containerWidth(_settings.containerWidth);
            }

            if (_settings.iconName) {
                self.iconName = _settings.iconName;
            }
        };
    };
});

