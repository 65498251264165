define('presentation/settings/userProfile/facades/confirmPasswordModalFacade',[
    'persistence/webSocket/webSocketAuthentication'
], function() {
    return function() {
        const self = this;

        let _promiseFactory = null;
        let _webSocketAuthentication = null;

        self.isPasswordCorrect = (emailAddress, password) => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketAuthentication.validatePassword(emailAddress, password)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        switch (result.status) {
                            case "invalid_user_name":
                            case "invalid_password":
                                deferredObject.resolve(false);
                                break;
                            case "success":
                                deferredObject.resolve(true);
                                break;
                            default:
                                let error = new Error("Invalid response message");
                                error.status = result.status;
                                deferredObject.reject(error);
                        }
                    });
            });
        };

        self.init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const WebSocketAuthenticationConstructor = require('persistence/webSocket/webSocketAuthentication');
            _webSocketAuthentication = new WebSocketAuthenticationConstructor();
            _webSocketAuthentication.init();
        };
    };
});

