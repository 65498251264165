define('presentation/acceptUserInvite/viewModels/createUserDevicesViewModel',[
    'common/promises/promiseFactory',
    'constants/userDeviceConstants',
    'presentation/acceptUserInvite/facades/createUserDevicesFacade',
    'presentation/acceptUserInvite/validators/createUserDevicesValidator',
    'presentation/acceptUserInvite/presentationObjects/createUserDevicePresentationObject',
    'presentation/settings/userDevices/validators/userDevicesValidatorUtility'
],
function() {
    return function(_userProfile) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const UserDevicesValidatorUtilityConstructor = require('presentation/settings/userDevices/validators/userDevicesValidatorUtility');
        const _userDevicesValidatorUtility = new UserDevicesValidatorUtilityConstructor();
        const ValidatorConstructor = require('presentation/acceptUserInvite/validators/createUserDevicesValidator');
        const _validator = new ValidatorConstructor();
        const CreateUserDevicePresentationObjectConstructor = require('presentation/acceptUserInvite/presentationObjects/createUserDevicePresentationObject');
        const _i18n = require('i18next');

        let _linkItems = null;
        let _facade = null;

        const _buildUserDevices = () => {
            self.devices.removeAll();

            let userDevice = new CreateUserDevicePresentationObjectConstructor();

            userDevice.deviceName = self.deviceName;
            userDevice.devicePhoneNumber = self.devicePhoneNumber;
            userDevice.deviceCountryAbbreviation = self.deviceCountryAbbreviation;

            self.devices.push(userDevice);
        };

        self.devices = ko.observableArray();
        self.deviceName = ko.observable(_i18n.t('acceptUserInvite:mobile'));
        self.devicePhoneNumber = ko.observable("");
        self.deviceCountryAbbreviation = ko.observable("us");
        self.existingDeviceIds = new Map();

        self.save = () => {
            return _promiseFactory.defer((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            promise.resolve(false);
                        } else {
                            _buildUserDevices();
                            _facade.saveDevices(self.devices(), _linkItems)
                                .done((result) => {
                                    if (result.status === "success") {
                                        promise.resolve(true);
                                    } else {
                                        const integrityWarning = _userDevicesValidatorUtility.returnIntegrityWarnings(result.status);
                                        self.integrityWarning(integrityWarning.message);
                                        promise.resolve(false);
                                    }
                                });
                        }
                    });
            });
        };

        self.isDevicePhoneNumberUniqueToUser = (devicePhoneNumber) => {
            return _userDevicesValidatorUtility.isDevicePhoneNumberUniqueToUser(devicePhoneNumber, self.userId);
        };

        self.activate = (linkItems) => {
            _linkItems = linkItems;

            return _initialize();
        };

        const _initialize = () => {
            const FacadeConstructor = require('presentation/acceptUserInvite/facades/createUserDevicesFacade');
            _facade = new FacadeConstructor();
            _facade.init();

            self.devicePhoneNumber = _userProfile.devicePhoneNumber;

            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});

