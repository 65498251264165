define('businessServices/userSettings/userSettingsSingleton',[], function () {
    var UserSettingsSingleton = function() {
        if (UserSettingsSingleton.prototype._singletonInstance) {
            return UserSettingsSingleton.prototype._singletonInstance;
        }
        UserSettingsSingleton.prototype._singletonInstance = this;

        this.userSettings = null;
        this.isLoaded = false;
    };
    return new UserSettingsSingleton();
});
