define('presentation/analytics/viewModels/analyticsSidebarViewModel',[
        'businessServices/authentication/sessionAccountInfo',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'presentation/analytics/facades/analyticsSidebarFacade',
        'presentation/analytics/validators/analyticsSidebarViewModelValidator',
        'businessServices/state/modelStateObserver'
], function(
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    _sessionAccountInfo,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/storage/commonState') */
    _commonState
) {
        return function() {
            const self = this;

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const _promiseFactory = new PromiseFactory();

            const SorterConstructor = require('common/collections/collectionSorter');
            const _sorter = new SorterConstructor();

            const _i18n = require('i18next');

            /** @type import('presentation/analytics/facades/analyticsSidebarFacade') */
            let _facade = null;
            /** @type {KnockoutSubscription[]} */
            let _knockoutSubscriptions = [];
            let _validator = null;
            let _detached = false;

            self.parentViewModel = null;
            self.modelStateObserver = null;
            self.isDirty = ko.observable(false);
            self.isRefreshVisible = ko.observable(false);

            self.availableShowFilters = [
                {title: _i18n.t('analyticsSidebar:calls'), value: 'calls'},
                {title: _i18n.t('analyticsSidebar:minutes'), value: 'minutes'}
            ];
            /** @type {KnockoutObservable<SelectedShowFilter>} */
            self.selectedShowFilter = ko.observable(/** @type {SelectedShowFilter} */('calls')).extend({observeState: true});

            self.allHostedNumbersSorted = ko.computed(() => {
                const allPhoneNumbersSorted = _commonState.hostedNumbers().map(item => {
                    return Object.assign({}, item, {
                        displayName: item.name,
                        subscriberType: _commonState.types.hostedNumber,
                    });
                });
                _sorter.multiSort(allPhoneNumbersSorted, ["isActive", "displayName"], [false, true]);

                return allPhoneNumbersSorted;
            });
            self.selectedPhoneNumbers = ko.observableArray().extend({observeState: true});

            self.allUsersAndUserGroups = ko.pureComputed(() => {
                const currentUserId = _sessionAccountInfo.userId();
                const users = _commonState.users()
                    .filter(user => {
                        return self.isSystemAdmin() ? true : user.id === currentUserId;
                    })
                    .map(user => {
                        const avatar = {avatar: user.avatar};
                        return Object.assign({}, user, avatar);
                    });
                const userGroups = _commonState.userGroups()
                    .filter(group => {
                        return self.isSystemAdmin() ? true : group.members().some((id) => id === currentUserId);
                    })
                    .map(group => {
                        const avatar = {avatar: true};
                        return Object.assign({}, group, avatar);
                    });
                const usersAndUserGroups = [...users, ...userGroups];
                _sorter.multiSort(usersAndUserGroups, ["isActive", "name"], [false, true]);
                return usersAndUserGroups;
            });
            self.selectedFromToItems = ko.observableArray().extend({observeState: true});

            self.firstAvailableOptionForUserOrUserGroup = (availableOptions) => {
                const sorter = new SorterConstructor();
                sorter.sort(availableOptions(), "name", true);
                const firstAvailableUser = availableOptions().find((option) => {
                    return option.type === "user";
                });
                return firstAvailableUser === undefined ? availableOptions()[0] : firstAvailableUser;
            };

            self.selectedDuringFilter = ko.observable('last_days_7').extend({observeState: true});

            self.duringSelected = ko.pureComputed({
                read: () => [self.selectedDuringFilter()],
                write: (value) => self.selectedDuringFilter(value[0])
            });

            self.showDuringCustomDate = ko.pureComputed(() => self.selectedDuringFilter() === "custom");

            self.duringCustomStartOn = ko.observable("").extend({observeState: true});
            self.duringCustomEndOn = ko.observable("").extend({observeState: true});
            self.customEndDate = ko.pureComputed({
                read: () => self.duringCustomEndOn(),
                write: (value) => self.duringCustomEndOn(`${value} 11:59 pm`)
            });

            self.availableCallTypeFilter = [
                {textI18n: _i18n.t('analyticsSidebar:all'), value: 'all', isDisabled: true},
                {textI18n: _i18n.t('analyticsSidebar:internal'), value: 'internal', isDisabled: true, tooltipI18n: 'analyticsSidebar:comingSoonTooltip'},
                {textI18n: _i18n.t('analyticsSidebar:external'), value: 'external'}
            ];
            /** @type {KnockoutObservable<SelectedCallType>} */
            self.selectedCallTypeFilter = ko.observable(/** @type {SelectedCallType} */('all')).extend({observeState: true});
            self.callTypeTooltip = _i18n.t('analyticsSidebar:callTypeTooltip');

            self.isCompositionComplete = ko.observable(false);
            self.isDataLoaded = ko.observable(false);
            self.isSearching = ko.observable(false);
            self.isSystemAdmin = ko.pureComputed(() => _sessionAccountInfo.isSystemAdmin());

            self.showSidebar = ko.pureComputed(() => {
                 return self.isCompositionComplete() && self.isDataLoaded();
            });

            self.search = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    _validator.validate()
                        .done((isValid) => {
                            if (_detached) {
                                promise.resolve();
                                return;
                            }

                            if (isValid) {
                                self.isSearching(true);
                                self.parentViewModel.contentPane().performSearch()
                                    .done(() => {
                                        self.isSearching(false);
                                        self.modelStateObserver.commitData();
                                        promise.resolve();
                                    })
                                    .fail(() => {
                                        self.isSearching(false);
                                        self.modelStateObserver.restoreData();
                                        promise.reject();
                                    });
                            } else {
                                promise.resolve();
                            }
                        });
                });
            };

            self.attached = () => {
                self.isDataLoaded(false);
                self.isCompositionComplete(false);
                return _getSidebarSelections();
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.detached = () => {
                _detached = true;
                _knockoutSubscriptions.forEach((knockoutSubscription) => {
                    knockoutSubscription.dispose();
                });
                _knockoutSubscriptions = [];
            };

            self.activate = () => {
                const ValidatorConstructor = require('presentation/analytics/validators/analyticsSidebarViewModelValidator');
                _validator = new ValidatorConstructor();

                const FacadeConstructor = require('presentation/analytics/facades/analyticsSidebarFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                return _initialize();
            };

            const _saveSidebarSettings = () => {
                /** @type {Partial<ISidebarSettings>} */
                const sidebarSettings = {
                    selectedShowFilter: self.selectedShowFilter(),
                    selectedPhoneNumbers: self.selectedPhoneNumbers().map((phoneNumber) => {
                        return ko.unwrap(phoneNumber);
                    }),
                    selectedFromToItems: self.selectedFromToItems().map((fromToItem) => {
                        return ko.unwrap(fromToItem);
                    }),
                    selectedDuringFilter: self.selectedDuringFilter(),
                    duringCustomStartOn: self.duringCustomStartOn(),
                    duringCustomEndOn: self.duringCustomEndOn(),
                    selectedCallTypeFilter: self.selectedCallTypeFilter(),
                };
                return _facade.saveSidebarSettings(sidebarSettings);
            };

            const _getSidebarSettings = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    _facade.getSidebarSettings()
                        .done((settings) => {
                            self.selectedShowFilter(settings.selectedShowFilter);

                            const selectedPhoneNumbers = _buildSelectedPhoneNumbers(settings.selectedPhoneNumbers);
                            self.selectedPhoneNumbers(selectedPhoneNumbers);

                            const selectedFromToItems = _buildSelectedFromToItems(settings.selectedFromToItems);
                            self.selectedFromToItems(selectedFromToItems);

                            self.selectedDuringFilter(settings.selectedDuringFilter);
                            self.duringCustomStartOn(settings.duringCustomStartOn);
                            self.duringCustomEndOn(settings.duringCustomEndOn);
                            self.selectedCallTypeFilter(settings.selectedCallTypeFilter);

                            _knockoutSubscriptions = [
                                self.selectedShowFilter.subscribe(_saveSidebarSettings),
                                self.selectedPhoneNumbers.subscribe(_saveSidebarSettings),
                                self.selectedFromToItems.subscribe(_saveSidebarSettings),
                                self.selectedDuringFilter.subscribe(_saveSidebarSettings),
                                self.duringCustomStartOn.subscribe(_saveSidebarSettings),
                                self.duringCustomEndOn.subscribe(_saveSidebarSettings),
                                self.selectedCallTypeFilter.subscribe(_saveSidebarSettings),
                            ];

                            promise.resolve(settings);
                        });
                });
            };

            const _buildSelectedPhoneNumbers = (/** @type {ISelectedPhoneNumber[]} */selectedPhoneNumbers) => {
                /** @type {{[key: string]: boolean}} */
                const selectedPhoneNumberIds = {};
                selectedPhoneNumbers.forEach(selectedPhoneNumber => selectedPhoneNumberIds[selectedPhoneNumber.id] = true);

                return self.allHostedNumbersSorted().filter(phoneNumber => selectedPhoneNumberIds[phoneNumber.id]);
            };

            const _buildSelectedFromToItems = (/** @type {ISelectedFromToItem[]} */selectedFromToItems) => {
                /** @type {{ [key: string]: boolean }} */
                const selectedFromToItemIds = {};
                selectedFromToItems.forEach(fromToItem => selectedFromToItemIds[fromToItem.id] = true);

                return self.allUsersAndUserGroups().filter(fromToItem => selectedFromToItemIds[fromToItem.id]);
            };

            const _getSidebarSelections = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    _getSidebarSettings()
                        .fail(promise.reject)
                        .done(() => {
                            self.search();
                            self.isDataLoaded(true);
                            promise.resolve();
                        });
                });
            };

            const _initialize = () => {
                _validator.registerViewModel(self);

                self.modelStateObserver = new ModelStateObserverConstructor(self, false);
                self.modelStateObserver.isDirty.subscribe((isDirty) => {
                    self.isDirty(isDirty);
                    self.isRefreshVisible(isDirty);
                });

                return _promiseFactory.wait();
            };
        };
    });

