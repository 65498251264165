define('presentation/settings/voicemailBoxes/presentationObjects/voicemailBoxSubscriberPresentationObject',[], function () {
    return function () {
        const self = this;
        self.voicemailBoxSubscriberId = null;
        self.subscriberType = "";
        self.avatar = null;
        self.avatarType  = "";
        self.displayName = "";
        self.subscriberId = "";
        self.readOnly = false;
    };
});

