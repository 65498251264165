define('presentation/common/listBox/viewModels/listBoxViewModel',[], function() {
    return function() {
        const self = this;

        let _settings = null;
        /** @type {IDisposable[]} */
        let _disposables = [];
        const DEFAULT_MAX_HEIGHT = 350;

        const _handleKeyPress = (event) => {
            if (self.options().length === 0) {
                return true;
            }

            switch (event.key) {
                case 'Enter':
                    _selectOption(self.focusedOptionId() || self.selectedOptionId());
                    break;
                case 'ArrowUp':
                case 'Up':
                    _focusNeighboringOption(-1);
                    _triggerScrollIntoView();
                    self.isCursorDisabled(true);
                    break;
                case 'ArrowDown':
                case 'Down':
                    _focusNeighboringOption(1);
                    _triggerScrollIntoView();
                    self.isCursorDisabled(true);
                    break;
                case 'Home':
                    _focusFirstOption();
                    self.isCursorDisabled(true);
                    break;
                case 'End':
                    _focusLastOption();
                    self.isCursorDisabled(true);
                    break;
                default:
                    // allow event default behavior
                    return true;
            }
        };

        const _focusNeighboringOption = (offset) => {
            const neighboringOptionIndex = _getFocusedElementIndex() + offset;
            const neighboringOption = self.options()[neighboringOptionIndex];

            if (neighboringOption !== undefined) {
                self.focusedOptionId(neighboringOption.id);
            }
        };

        const _focusFirstOption = () => {
            const firstOption = self.options()[0];

            if (firstOption !== undefined) {
                self.focusedOptionId(firstOption.id);
            }
        };

        const _focusLastOption = () => {
            const lastOption = self.options()[self.options().length - 1];

            if (lastOption !== undefined) {
                self.focusedOptionId(lastOption.id);
            }
        };

        const _selectOption = (optionId) => {
            self.selectedOptionId(optionId);

            if (_settings.hasOwnProperty("selectionChangedCallback")) {
                _settings.selectionChangedCallback(optionId);
            }
        };

        const _triggerScrollIntoView = () => {
            self.shouldScrollIntoView(true);
            self.shouldScrollIntoView(false);
        };

        const _getFocusedElementIndex = () => {
            return self.options().findIndex((option) => {
                return option.id === self.focusedOptionId();
            });
        };

        const _onOptionsChanged = (newOptions) => {
            const focusedOptionId = self.focusedOptionId();

            if (focusedOptionId) {
                const exist = newOptions.find(({id}) => id === focusedOptionId);
                if (!exist) {
                    self.focusedOptionId("");
                }
            }

            self.showNoResultsOption(!newOptions.length);
        };

        self.options = ko.observable([]);
        self.selectedOptionId = ko.observable("");
        self.focusedOptionId = ko.observable("");
        self.showNoResultsOption = ko.observable(false);
        self.isSelfContained = ko.observable(true);

        self.shouldScrollIntoView = ko.observable(false);

        // prevent mouseover and keyDown events from fighting over focus
        self.isCursorDisabled = ko.observable(false);
        self.shrinkToFitContent = false;
        self.maxHeight = DEFAULT_MAX_HEIGHT;
        self.minHeight = null;

        self.onOptionClick = (data) => {
            _selectOption(data.id);
        };

        self.onMouseOver = (data) => {
            self.focusedOptionId(data.id);
        };

        // allow parent container to use this as a callback to send keyDown events
        self.onKeyDown = (event) => {
            _handleKeyPress(event);
        };

        // listen for keyDown events for self-contained listBox components
        self.onKeyDownEvent = (vm, event) => {
            if (self.isSelfContained()) {
                return _handleKeyPress(event);
            }

            return true;
        };

        self.onMouseMove = () => {
            if (self.isCursorDisabled()) {
                self.isCursorDisabled(false);
            }
        };

        self.detached = () => {
            for (const d of _disposables) {
                d.dispose();
            }

            _disposables = [];
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {

            if (ko.isObservable(_settings.options) && Array.isArray(_settings.options())) {
                self.options = _settings.options;
            } else {
                throw new TypeError("expected an observable containing an array");
            }

            if (ko.isObservable(_settings.selectedOptionId) && typeof _settings.selectedOptionId() === 'string') {
                self.selectedOptionId = _settings.selectedOptionId;
                self.focusedOptionId(self.selectedOptionId());
            } else {
                throw new TypeError("expected an observable string");
            }
            
            if (_settings.hasOwnProperty('shrinkToFitContent')) {
                const shrinkToFitContent = ko.unwrap(_settings.shrinkToFitContent);

                if (typeof shrinkToFitContent === 'boolean') {
                    self.shrinkToFitContent = shrinkToFitContent;
                }
            }

            if (typeof _settings.maxHeight === 'number') {
                self.maxHeight = ko.unwrap(_settings.maxHeight);
            }

            const _minHeight = ko.unwrap(_settings.minHeight);
            if (typeof _minHeight === 'number') {
                self.minHeight = _minHeight;
            }

            if (typeof _settings.onKeyDownCallback === 'function') {
                _settings.onKeyDownCallback(self.onKeyDown);
                self.isSelfContained(false);
            }

            _disposables.push(
                self.options.subscribe(_onOptionsChanged)
            );
        };
    };
});
