define('presentation/common/routeToModal/viewModels/routeToUserViewModel',['common/promises/promiseFactory',
        'businessServices/events/eventManager',
        'constants/autoAttendantVoiceRouteEnumerations',
        'common/storage/commonState',
        'common/collections/collectionSorter',
        'presentation/common/routeToModal/common/routeToViewModelCommonSetup',
        'businessServices/state/modelStateObserver',
        'settings/navigationConfiguration',
        'constants/pressOneToAcceptConstants'
], function() {

    return function(initialRoutedToId, initialRouteToType, nameThisOption, routeToData, validateRouteToViewModelCallback, routeToOptions, parentViewModel, isRouteToModal) {
        const self = this;

        const {requireKeyPress, routingRuleExitConditions} = routeToData || {};

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const RouteToViewModelCommonSetupConstructor = require('presentation/common/routeToModal/common/routeToViewModelCommonSetup');

        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _pressOneToAcceptConstants = require('constants/pressOneToAcceptConstants');

        const _promiseFactory = new PromiseFactoryConstructor();
        const _routeToViewModelCommonSetup = new RouteToViewModelCommonSetupConstructor(validateRouteToViewModelCallback, routeToOptions);

        let _disposables = [];

        const triggerOwnerChange = () => {
            const user = self.selectedUser();
            const routeTo = self.routeToViewModel();
            if (user && routeTo) {
                routeTo.updateRouteOwner(user.id);
            }
        };

        self.usersUrl = _navigationConfiguration.routesById.users.url;
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.selectedUser = ko.observable().extend({observeState: true});
        _disposables.push(self.selectedUser.subscribe(triggerOwnerChange));
        self.routeToStrategy = null;
        self.routeToViewModel = ko.observable().extend({observeState: true});
        _disposables.push(self.routeToViewModel.subscribe(triggerOwnerChange));
        self.isValid = ko.observable(true);
        self.isNewRouteTo = true;

        self.users = ko.pureComputed(() => {
            const users = _commonState.users().filter((user) => {
                return user.isRoutable();
            });
            const sorter = new SorterConstructor();
            sorter.sort(users, "name", true);
            return users;
        });

        self.pressOneToAcceptOptions = ko.observableArray(_pressOneToAcceptConstants.options);
        self.pressOneToAcceptOption = ko.observable(_pressOneToAcceptConstants.on).extend({observeState: true});
        self.showPressOneToAcceptWarningMessage = ko.computed(() => self.pressOneToAcceptOption() === _pressOneToAcceptConstants.off);
        self.pressOneToAcceptWarningMessage = ko.observable('routeToUser.pressOneToAcceptWarningMessage');

        self.cancelForm = () => {
            return _promiseFactory.defer((deferredObject) => {
                _eventManager.publishRouteToCloseModal();
                deferredObject.resolve();
            });
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            if (initialRoutedToId && initialRouteToType ===  _voiceRouteEnumerations.user) {
                const user = _commonState.get(initialRoutedToId);
                self.selectedUser(user);
            } else {
                self.selectedUser(self.users()[0]);
            }
            if (requireKeyPress !== null && requireKeyPress !== undefined) {
                if (requireKeyPress === true) {
                    self.pressOneToAcceptOption(_pressOneToAcceptConstants.on);
                } else {
                    self.pressOneToAcceptOption(_pressOneToAcceptConstants.off);
                }
            } else {
                self.pressOneToAcceptOption(_pressOneToAcceptConstants.on);
            }
            self.routeToViewModel(_routeToViewModelCommonSetup.determineSubsequentRoutes(self.isNewRouteTo, self.routeToStrategy, self.selectedUser().id, _voiceRouteEnumerations.user, routingRuleExitConditions, parentViewModel, isRouteToModal));

            return _promiseFactory.wait();
        };
    };
});
