define('constants/companySizeEnumerations',[], function() {
    return {
        oneEmployee: "1 Employee",
        twoToTenEmployees: "2-10 Employees",
        elevenToFifty50Employees: "11-50 Employees",
        fiftyOneToTwoHundredFiftyEmployees: "51-250 Employees",
        twoHundredFiftyOneToOneThousandEmployees: "251-1000 Employees",
        moreThanOneThousandEmployees: "More than 1000 Employees",
    };
});

