define('presentation/settings/userGroup/presentationObjects/userGroupPresentationObject',[],function() {
    return function(){
        const self = this;

        self.createdDateTime = null;
        self.userGroupId = null;
        self.groupName = null;
        self.editUrl = null;
        self.extension = null;
        self.isBuiltInGroup = false;
        self.isGroupDeletable = true;
        self.isGroupNameEnabled = true;
        self.isMembershipSelectionEnabled = null;
        self.memberCount = ko.observable(0);
        self.members = null;
        self.modifiedDateTime = null;
        self.status =  ko.observable();

        self.isActiveStatus = ko.pureComputed(() => self.status() === "active");
        self.isDeactivatedStatus = ko.pureComputed(() => self.status() === "deactivated");
    };
});

