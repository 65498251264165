define('presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject',[],
function() {
    return function() {
        var self = this;

        self.audioConversionId = ko.observable();
        self.fileHandler = ko.observable();
        self.audioType = ko.observable();
        self.audioData = ko.observable();
        self.duration = ko.observable();
        self.showConversionMessage = ko.observable(false);
        self.showConversionFailedMessage = ko.observable(false);
        self.showAudioPlayer = ko.pureComputed(function() {
            if (self.showConversionMessage() === false && self.showConversionFailedMessage() === false) {
                return true;
            } else {
                return false;
            }
        });
    };
});

