define('presentation/common/topMost',['presentation/common/svgIcon/svgIcon'], (
    /** @type import('presentation/common/svgIcon/svgIcon' ) */
    svgIcon
) => {
    /**
     * @typedef {{
     *  xOffset: number
     *  yOffset: number
     *  className: string
     *  enable: boolean
     *  iconName?: string
     *  hoveringOver?: KnockoutObservable<boolean>
     *  onClick?: (this: HTMLDivElement, ev: MouseEvent) => any
     *  osScrollbars: boolean
     * }} Options
     */

    return {
        /**
         * @param {HTMLElement} element 
         * @param {() => ITopMostOptions} valueAccessor
         */
        init (element, valueAccessor) {
            /** @type {HTMLDivElement} */
            let onHoverElement = null;
            let isMouseOverHoverElement = false;
            let isMouseOverElement = false;
            let hideDelay = 0;
            /** @type {Options} */
            let options = null;
            /** @type {HTMLElement[]} */
            let osScrollbars = [];

            /**
             * @returns {Options}
             */
            const _getConfiguration = () => {
                const { xOffset, yOffset, className, enable, iconName, onClick, osScrollbars, hoveringOver } = ko.unwrap(valueAccessor());

                let enableParsed = ko.unwrap(enable);
                if (typeof enableParsed !== "boolean") {
                    enableParsed = true;
                }

                let osScrollbarsParsed = ko.unwrap(osScrollbars);
                if (typeof osScrollbars !== "boolean") {
                    osScrollbarsParsed = false;
                }

                return {
                    xOffset: ko.unwrap(xOffset),
                    yOffset: ko.unwrap(yOffset),
                    className: ko.unwrap(className),
                    iconName: ko.unwrap(iconName),
                    enable: enableParsed,
                    osScrollbars: osScrollbarsParsed,
                    onClick: onClick,
                    hoveringOver: hoveringOver
                };
            };

            const _adjustPosition = () => {
                const shouldBeVisible = options.enable && (isMouseOverElement || isMouseOverHoverElement);
                if (!shouldBeVisible) {
                    return;
                }

                const { yOffset, xOffset } = options;
                const { top, right } = element.getBoundingClientRect();

                const div = onHoverElement;
                div.style.top = `${top + yOffset}px`;
                div.style.left = `${right + xOffset}px`;
            };

            const _createHoverElement = () => {
                const { iconName, className, onClick } = options;

                const div = document.createElement('div');
                div.style.zIndex = `1000`;
                div.className = className;
                onHoverElement = div;
                _adjustPosition();
                document.body.appendChild(div);

                if (onClick) {
                    div.addEventListener('click', onClick);
                }

                div.addEventListener('mouseenter', () => {
                    isMouseOverHoverElement = true;
                    _calculateVisibility();

                });
                div.addEventListener('mouseleave', () => {
                    isMouseOverHoverElement = false;
                    _calculateVisibility();
                });

                if (iconName) {
                    svgIcon.init(div, () => {
                        return {
                            iconName: iconName,
                            useCss: true
                        };
                    }, null, null, null);
                }
            };

            const _onMouseEnter = () => {
                isMouseOverElement = true;
                _calculateVisibility();
            };

            const _onMouseLeave = () => {
                isMouseOverElement = false;
                _calculateVisibility();
            };

            const _onMouseOver = (/** @type {MouseEvent} */ev) => {
                if (!onHoverElement) {
                    return;
                }

                const { clientX, clientY } = ev;
                const elements = document.elementsFromPoint(clientX, clientY);

                isMouseOverElement = false;
                for (const elementAtPoint of elements) {
                    if (elementAtPoint === element) {
                        isMouseOverElement = true;
                        break;
                    }
                }

                _calculateVisibility();
            };

            const _calculateVisibility = () => {
                const shouldBeVisible = options.enable && (isMouseOverElement || isMouseOverHoverElement);

                clearTimeout(hideDelay);

                if (!onHoverElement) {
                    _createHoverElement();
                }

                if (options.hoveringOver) {
                    options.hoveringOver(isMouseOverHoverElement);
                }

                if (shouldBeVisible) {
                    _adjustPosition();
                    onHoverElement.style.visibility = 'visible';
                }
                else {
                    hideDelay = setTimeout(() => {
                        onHoverElement.style.visibility = 'hidden';
                    }, 50);
                }
            };

            const _getScrollbars = () => {
                if (!options.osScrollbars) {
                    return [];
                }

                let e = element;
                while (e && !e.classList.contains("os-host")) {
                    e = e.parentElement;
                }

                if (!e) {
                    return [];
                }
                
                /** @type {HTMLElement[]} */
                const scrollbars = Array.from(e.querySelectorAll('.os-scrollbar'));
                return scrollbars;
            };
            options = _getConfiguration();

            element.addEventListener('mouseenter', _onMouseEnter);
            element.addEventListener('mouseleave', _onMouseLeave);
            document.addEventListener('scroll', _adjustPosition, true);
            document.addEventListener('keyup', _adjustPosition, true);

            osScrollbars = _getScrollbars();

            for (const sb of osScrollbars) {
                sb.addEventListener('mouseover', _onMouseOver);
                sb.addEventListener('mouseleave', _onMouseLeave);
            }

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (onHoverElement) {
                    onHoverElement.remove();
                }

                for (const sb of osScrollbars) {
                    sb.removeEventListener('mouseover', _onMouseOver);
                    sb.removeEventListener('mouseleave', _onMouseLeave);
                }

                element.removeEventListener('mouseenter', _onMouseEnter);
                element.removeEventListener('mouseleave', _onMouseLeave);
                document.removeEventListener('scroll', _adjustPosition);
                document.removeEventListener('keyup', _adjustPosition);
            });
        }
    };
});
