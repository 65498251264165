define('presentation/shell/viewModels/interiorErrorShellViewModel',[
    'businessServices/events/eventManager',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'constants/shellNameEnumerations',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfig = require('settings/navigationConfiguration');
        const _shellNameEnumerations = require('constants/shellNameEnumerations');

        const _onShellUpdated = (shellName) => {
            if (shellName === _shellNameEnumerations.interiorError) {
                self.shellIsActive(true);
                if (self.router.activeItem() === null) {
                    self.contentPane(null);
                }
            } else {
                self.shellIsActive(false);
            }
        };

        const _setCssRootClass = () => {
            let cssRootClass = self.router.activeInstruction().config.customSettings.cssRootClass;
            if (cssRootClass === undefined || cssRootClass === null) {
                self.cssRootClass("");
            } else {
                self.cssRootClass(cssRootClass);
            }
        };

        self.router = null;
        self.homepageURL = "";
        self.shellIsActive = ko.observable(true);
        self.cssRootClass = ko.observable("");
        self.contentViewModel = ko.observable(null);

        self.activate = () => {
            self.router = require('businessServices/router/router');

            return _initialize();
        };

        const _initialize = () => {
            _setCssRootClass();

            self.homepageURL = _navigationConfig.homePageUrl;
            let activeItem = self.router.activeItem();

            self.contentViewModel(activeItem);

            _eventManager.subscribeShellUpdated(_onShellUpdated);

            return _promiseFactory.wait();
        };
    };
});
