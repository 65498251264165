define('businessServices/creditCards/creditCardValidations',[
    'businessServices/specifications/validPostalCodeSpecification',
    'constants/creditCardTypeEnumerations'
], function() {
    return function() {
        var self = this;

        var ValidPostalCodeSpecificationConstructor = require('businessServices/specifications/validPostalCodeSpecification');
        var _validPostalCodeSpecification = new ValidPostalCodeSpecificationConstructor();
        var _creditCardEnumerations = require('constants/creditCardTypeEnumerations');

        self.validateCardNumber = function(cardNumber) {
            if ($.payment.validateCardNumber(cardNumber)) {
                var cardType = $.payment.cardType(cardNumber);
                return _creditCardEnumerations[cardType] !== undefined;
            }

            return false;
        };

        self.validateCvv = function(cvvSecurityCode, cardNumber) {
            var cardType = $.payment.cardType(cardNumber);
            return $.payment.validateCardCVC(cvvSecurityCode, cardType);
        };
        
        self.validateExpirationDate = function(month, year) {
            return $.payment.validateCardExpiry(month, year);
        };

        self.validatePostalCodeFormat = function(postalCode, country) {
            return _validPostalCodeSpecification.isSatisfiedBy(postalCode, country.countryAbbreviation);
        };
    };
});

