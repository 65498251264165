define('presentation/common/filterMenu/viewModels/filterMenuViewModel',[
    'i18next',
    'common/converters/phoneNumberFormatter',
    'constants/smsConversationReviewedStatus'
], function(
    /** @type import('i18next')*/
    i18n,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('constants/smsConversationReviewedStatus')*/
    SmsConversationReviewedStatus
) {
    return function() {
        /** @typedef {import('presentation/common/filterMenu/viewModels/filterMenuViewModel')} FilterMenuViewModel */

        /** @type {FilterMenuViewModel}*/
        const self = this;

        const _phoneNumberFormatter = new PhoneNumberFormatter();

        /** @type {IFilterMenuSettings}*/
        let _settings = null;

        /**
         * @param {IFormattedPhoneNumberOption} formattedOption
         * @param {IWebMessagingNumber} option
         * */
        const _setPhoneNumberMetaTags = (formattedOption, option) => {
            const displayPhoneNumber = _phoneNumberFormatter.toUSAreaCode(option.hostedPhoneNumber);
            const displayLocation = _phoneNumberFormatter.formatLocation(option.city, option.state);

            if (_phoneNumberFormatter.isTollFree(option.hostedPhoneNumber)) {
                formattedOption.meta1 = i18n.t('tollFree');
                formattedOption.meta2 = displayPhoneNumber;
                return;
            }

            if (option.hostedPhoneNumberName && option.hostedPhoneNumberName !== option.hostedPhoneNumberDefaultName) {
                formattedOption.meta1 = option.hostedPhoneNumberName;
                formattedOption.meta2 = `${displayPhoneNumber} ${displayLocation}`;
                return;
            }

            formattedOption.meta1 = displayLocation;
            formattedOption.meta2 = displayPhoneNumber;
        };

        /**
         * @param {IShowOption[]} options
         * @returns IFormattedShowOption[]
        * */
        const _formatShowOptions = (options) => {
            const optionsCopy = [...options];
            return optionsCopy.map((option) => {
                /** @type {IFormattedShowOption}*/
                const formattedOption = Object.assign({}, option);
                const label = i18n.t(`filterOptions:${option.textI18n}`);
                // Dropdown
                formattedOption.isSelected = ko.observable(option.value === self.selectedShowOption());
                formattedOption.displayText = label;

                // Filter Pill
                formattedOption.pillLabel = label;
                formattedOption.showPillStatusIndicator = option.value === SmsConversationReviewedStatus.New;
                formattedOption.showPillIcon = false;

                formattedOption.optionClick = () => {
                    if (option.value !== self.selectedShowOption()) {
                        self.selectedShowOption(option.value);
                    }
                };

                return formattedOption;
            });
        };

        /**
         * @param {IWebMessagingNumber[]} options
         * @returns IFormattedPhoneNumberOption[]
         * */
        const _formatPhoneNumberOptions = (options) => {
            const optionsCopy = [...options];
            return optionsCopy.map((option) => {
                /** @type {IFormattedPhoneNumberOption}*/
                const formattedOption = Object.assign({}, option);
                // Dropdown
                formattedOption.isSelected = ko.observable(self.selectedPhoneNumberIds().some((accountHostedNumberId) => accountHostedNumberId === option.accountHostedNumberId));
                formattedOption.tooltipText = i18n.t('filterOptions:invalidTooltip');
                formattedOption.isTooltipEnabled = ko.observable(false);
                _setPhoneNumberMetaTags(formattedOption, option);

                formattedOption.isDisabled = ko.pureComputed(() => {
                    const isSelected = self.selectedPhoneNumberIds().some((accountHostedNumberId) => accountHostedNumberId === option.accountHostedNumberId);
                    return isSelected && self.selectedPhoneNumberIds().length <= 1;
                });

                // Filter pill
                formattedOption.pillLabel = formattedOption.meta1 === i18n.t('tollFree') ? formattedOption.meta2 : formattedOption.meta1;
                formattedOption.showPillStatusIndicator = false;
                formattedOption.showPillIcon = true;

                formattedOption.optionClick = () => {
                    formattedOption.isTooltipEnabled(false);
                    const currentSelection = self.selectedPhoneNumberIds();
                    let updatedSelection;

                    const alreadySelected = currentSelection.some((accountHostedNumberId) => accountHostedNumberId === option.accountHostedNumberId);
                    if (alreadySelected) {
                        updatedSelection = currentSelection.filter((accountHostedNumberId) => accountHostedNumberId !== option.accountHostedNumberId);
                        if (updatedSelection.length === 0) {
                            if (alreadySelected) {
                                formattedOption.isTooltipEnabled(true);

                                const twoSeconds = 2 * 1000;
                                setTimeout(() => formattedOption.isTooltipEnabled(false), twoSeconds);
                            }
                            return;
                        }
                    } else {
                        updatedSelection = currentSelection;
                        updatedSelection.push(option.accountHostedNumberId);
                    }

                    self.selectedPhoneNumberIds(updatedSelection);

                };

                return formattedOption;
            });
        };

        /**
         * @param {IFormattedShowOption[]} formattedShowOptions
         * @param {IFormattedPhoneNumberOption[]} formattedPhoneNumberOptions
         * @returns (IFormattedShowOption|IFormattedPhoneNumberOption)[]
         * */
        const _formatPillOptions = (formattedShowOptions, formattedPhoneNumberOptions) => {
            const selectedShowStatusOption = formattedShowOptions.find((option) => option.isSelected());
            const selectedPhoneNumbers = formattedPhoneNumberOptions.filter((option) => option.isSelected());

            if (!selectedShowStatusOption || !selectedPhoneNumbers) {
                return null;
            }

            selectedPhoneNumbers.unshift(selectedShowStatusOption);
            return selectedPhoneNumbers;
        };

        self.isExpanded = ko.observable(false).toggleable();
        self.selectedShowOption = ko.observable();
        self.showOptions = ko.observableArray([]);
        self.phoneNumberOptions = ko.observableArray([]);
        self.selectedPhoneNumberIds = ko.observableArray([]);
        self.scrollEventSource = 'filterMenu';
        self.scrollPosition = ko.observable(0);

        self.showFilterPills = ko.pureComputed(() => {
            const numberOptionsCount = self.phoneNumberOptions().length;
            const selectedOptionsCount = self.selectedPhoneNumberIds().length;
            return numberOptionsCount > selectedOptionsCount && selectedOptionsCount > 0;
        });
        self.formattedShowOptions = ko.pureComputed(() => _formatShowOptions(self.showOptions()));
        self.formattedPhoneNumberOptions = ko.pureComputed(() => _formatPhoneNumberOptions(self.phoneNumberOptions()));
        self.formattedFilterPills = ko.pureComputed(() => self.showFilterPills() ? _formatPillOptions(self.formattedShowOptions(), self.formattedPhoneNumberOptions()) : null);
        self.disableFilterMenuClick = ko.pureComputed(() => !self.phoneNumberOptions() || self.phoneNumberOptions().length === 0);


        self.hideFilterMenu = () => {
            self.isExpanded(false);
        };

        /** @type {FilterMenuViewModel["activate"]}*/
        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {

            if (_settings.selectedShowOption) {
                self.selectedShowOption = _settings.selectedShowOption;
            }

            if (_settings.selectedHostedPhoneNumberIds) {
                self.selectedPhoneNumberIds = _settings.selectedHostedPhoneNumberIds;
            }

            if (ko.isObservable(_settings.showOptions)) {
                self.showOptions = _settings.showOptions;
            } else {
                self.showOptions(_settings.showOptions);
            }

            if (ko.isObservable(_settings.phoneNumberOptions)){
                self.phoneNumberOptions = _settings.phoneNumberOptions;
            } else {
                self.phoneNumberOptions(_settings.phoneNumberOptions);
            }

        };
    };
});

