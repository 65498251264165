define('persistence/dataProviders/planDisplayDataProvider',['externalDependencies/kazooApi',
        'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.unauthenticatedCall('lobby_plans','GET')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(planId) {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_plans/' + planId, 'GET')
                    .fail(deferredObject.reject)
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    });
            });
        };
    };
});

