define('presentation/signup/validators/signupPhoneNumberPortAuthorizationValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator'],
    function() {
        return function() {
            let self = this;

            let _commonValidator = null;
            let _facade = null;
            let _viewModel = null;

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            self.registerViewModel = function(viewModel, facade) {
                let validationRules = new ValidationRulesConstructor();

                validationRules.field("portDate")
                    .addValidationKeyUp(_isDatePresent)
                    .addValidationI18n(_isDatePresent, 'signupPhoneNumberPortAuthorization:portDateRequired');
                validationRules.field("isAcknowledged")
                    .addValidationI18n(_validatePortDateAcknowledgement, 'signupPhoneNumberPortAuthorization:acknowledgementRequired');
                validationRules.field("authorizedSignature")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'signupPhoneNumberPortAuthorization:authorizedSignature');

                validationRules.validationGroupKeyUp('isValid', ["portDate", "authorizedSignature"]);

                _viewModel = viewModel;
                _facade = facade;
                _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
            };

            self.validate = function() {
                return _commonValidator.validate();
            };

            const _isFieldPresent = (fieldValue) => {
                return _commonValidator.isStringWithValue(fieldValue);
            };

            const _isDatePresent = (dateValue) => {
                return _commonValidator.isStringWithValue(dateValue.toString());
            };

            const _validatePortDateAcknowledgement = function(portDateAcknowledgement) {
                return portDateAcknowledgement;
            };
        };
    });

