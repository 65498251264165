define('presentation/signup/facades/signupSuccessFacade',[
        'externalDependencies/clientApi',
        'presentation/signup/presentationObjects/signupProfilePresentationObject',
    ],
    function() {
        let _promiseFactory = null;
        let _clientApi = null;
        let _signupSessionStore = null;

        const SignupProfilePresentationObjectConstructor = require('presentation/signup/presentationObjects/signupProfilePresentationObject');

        const _loadSignupSession = () => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.get()
                    .fail(promise.reject)
                    .done((signupSession) => {
                        let signupProfilePresentationObject = new SignupProfilePresentationObjectConstructor();
                        signupProfilePresentationObject = signupSession;
                        promise.resolve(signupProfilePresentationObject);
                    });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();
        };


        return function() {
            let self = this;
            self.init = _init;

            self.loadSignupSession = _loadSignupSession;
        };
    });
