define('presentation/settings/voicemailBoxes/validators/addVoicemailBoxViewModelValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _facade = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("standaloneName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addVoicemailBox:nameRequired')
                .addValidationI18n(_validateNameUnique, 'addVoicemailBox:nameNotUnique');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateNameUnique = (standaloneName) => {
            return _facade.isNameUnique(_viewModel.voicemailBoxId, standaloneName.trim());
        };
    };
});
