/**
 * @module presentation/common/dataGrid/viewModels/dataGridViewModel
 */
define('presentation/common/dataGrid/viewModels/dataGridViewModel',['businessServices/events/eventManager'],
function(){
    /**
     * ViewModel for dataGrid
     * @class dataGridViewModel
     * @constructor
     */
    return function() {
        var self = this;
        var _settings = null;
        var _eventManager = null;
        var _dataGridGetNextPageEventId = null;
        var DEFAULT_BUFFER_SIZE = 500;

        /**
         * List of items to display in control
         * @property dataSet
         * @type {observableArray}
         */
        self.dataSet = ko.observableArray([]);

        /**
         * Number of pixels to trigger infiniteScroll - next page
         * @property infiniteScrollBuffer
         * @type {int}
         */
        self.infiniteScrollBuffer = null;


        self.detached = function() {
            _eventManager.unsubscribe(_dataGridGetNextPageEventId);
        };

        /**
         * Entry point.  Manages dependencies
         * @method activate
         * @param  {settings} settings
         * @return {initialize}
         */
        self.activate = function(settings) {
            _eventManager = require('businessServices/events/eventManager');

            _initialize(settings);
        };

        /**
         * Entry point called by Unit Tests. Manages dependencies
         * @method activate
         * @param  {settings} settings
         * @param  {eventManager} eventManager
         * @return {initialize}
         */
        self.activate2 = function(settings, eventManager) {
            _settings = settings;
            _eventManager = eventManager;

            _initialize(settings);
        };

        var _initialize = function(settings) {
            self.dataSet = settings.dataSet;

            if (typeof settings.onGetNextPage === "function") {
                _dataGridGetNextPageEventId = _eventManager.subscribeDataGridGetNextPage(settings.onGetNextPage);
            } else {
                throw new Error("onGetNextPage is not a function");
            }

            if (settings.buffer) {
                self.infiniteScrollBuffer = settings.buffer;
            } else {
                self.infiniteScrollBuffer = DEFAULT_BUFFER_SIZE;
            }
        };
    };
});
