define('presentation/signup/validators/signupEmailValidator',[
    'common/promises/promiseFactory',
    'presentation/common/validation/validationRule',
    'common/specifications/validEmailAddressSpecification',
    'presentation/common/validation/commonValidator',
],
function() {
    return function() {
        let self = this;

        let _viewModel = null;
        let _facade = null;
        let _commonValidator = null;

        const ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        const _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        self.registerViewModel = (viewModel, facade) => {
            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("userEmail")
                .addValidationKeyUp(_validEmailAddressSpecification.isSatisfiedBy)
                .addValidationI18n(_commonValidator.isStringWithValue, 'signupEmail:emailAddressRequired')
                .addValidationI18n(_validEmailAddressSpecification.isSatisfiedBy, 'emailAddressInvalid')
                .addValidationI18n(_isEmailAddressAvailable, 'emailAddressUnique');

            _commonValidator.setValidationRules(validationRules);
            return _commonValidator;
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _isEmailAddressAvailable = (emailAddress) => {
            return _promiseFactory.defer((promise) => {
                _facade.getEmailStatus(emailAddress.trim())
                    .done((emailStatus) => {
                        promise.resolve(emailStatus === "email_not_found");
                    });
            });
        };
    };
});

