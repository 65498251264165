define('common/storage/machineStorage',[],
    function() {
        /** @typedef {import('common/storage/machineStorage')} IMachineStorage */

        /** @type {Storage} */
        const _storage = window.localStorage;

        const determineDateFromOffset = (/** @type {number} */offsetInHours) => {
            const date = new Date();
            date.setHours(date.getHours() + offsetInHours);
            return date;
        };

        /** @type {IMachineStorage["hasObject"]} */
        const hasObject = (key) => {
            if (getObject(key) === undefined) {
                return false;
            } else {
                return true;
            }
        };

        /** @type {IMachineStorage["saveObject"]} */
        const saveObject = (key, object) => {
            const serialized = JSON.stringify(object);
            _storage.setItem(key, serialized);
        };

        /** @type {IMachineStorage["getObject"]} */
        const getObject = (key) => {
            const serialized = _storage.getItem(key);
            if (serialized !== undefined) {
                const object = JSON.parse(serialized, dateReviver);
                return object;
            } else {
                return undefined;
            }
        };

        const dateReviver = (/** @type {string} */key, /** @type {string} */value) => {
            const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
            if ((key === "__expirationDate" || key === "__touchDate") && typeof value === "string" && dateFormat.test(value)) {
                return new Date(value);
            }
            return value;
        };

        /** @type {IMachineStorage["deleteObject"]} */
        const deleteObject = (key) => {
            _storage.removeItem(key);
        };

        /** @type {IMachineStorage["hasExpiringObject"]} */
        const hasExpiringObject = (key) => {
            if (getExpiringObject(key) === undefined) {
                return false;
            } else {
                return true;
            }
        };

        /** @type {IMachineStorage["saveExpiringObject"]} */
        const saveExpiringObject = (key, object, timeToExpireInHours, touchTimeInHours) => {
            const expirationDate = determineDateFromOffset(timeToExpireInHours);
            const touchDate = determineDateFromOffset(touchTimeInHours);

            const storedData = {
                __data : object,
                __expirationDate : expirationDate,
                __touchDate : touchDate,
                __expireTimeInHours : timeToExpireInHours,
                __touchTimeInHours : touchTimeInHours
            };
            saveObject(key, storedData);
        };

        /** @type {IMachineStorage["getExpiringObject"]} */
        const getExpiringObject = (key) => {
            const storedData = getObject(key);
            if ((storedData === undefined) || (storedData === null) || storedData.__expirationDate === undefined) {
                return undefined;
            }
            const currentTime = new Date();
            if (storedData.__expirationDate < currentTime) {
                deleteObject(key);
                return undefined;
            }
            if (storedData.__touchDate < currentTime) {
                saveExpiringObject(key, storedData.__data, storedData.__expireTimeInHours, storedData.__touchTimeInHours);
            }
            return storedData.__data;
        };

        /** @type {IMachineStorage["deleteExpiringObject"]} */
        const deleteExpiringObject = (key) => {
            deleteObject(key);
        };

        const cleanExpiredObjects = () => {
            const keys = [];
            for (let x = 0; x < _storage.length; x++) {
                keys.push(_storage.key(x));
            }
            const currentTime = new Date();

            keys.forEach(function(key) {
                try {
                    const storedData = getObject(key);
                    if ((storedData !== undefined) && (storedData.__expirationDate !== undefined)) {
                        if (storedData.__expirationDate < currentTime) {
                            deleteObject(key);
                        }
                    }
                } catch (ex) {
                    // Assuming that deserialization of the data failed - so this isn't a key we need to worry about
                }
            });
        };
        cleanExpiredObjects();

        return {
            hasObject : hasObject,
            saveObject: saveObject,
            getObject: getObject,
            deleteObject : deleteObject,
            hasExpiringObject : hasExpiringObject,
            saveExpiringObject : saveExpiringObject,
            getExpiringObject : getExpiringObject,
            deleteExpiringObject : deleteExpiringObject
        };
    }
);

