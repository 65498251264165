define('persistence/repositories/blockingRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('externalDependencies/clientWebSocket') */
    ClientWebSocket
) {
    const APPLICATION_NAME = `blocking`;

    return function () {
        /** @type { IBlockingRepository } */
        const self = this;

        const _promiseFactory = new PromiseFactory();

        const _blockingSocket = ClientWebSocket.forApplication(APPLICATION_NAME);

        /**
         * @template T
         * @param { string } eventName
         * @param { Callback<T> } callBack
         */
        const _subscribe = (eventName, callBack) => {
            _blockingSocket.subscribeToEvent(eventName, callBack);
        };

        /**
         * @template TOut
         * @param { string } command
         * @param { { [key: string]: any } } parameters
         * @returns { JQuery.Deferred<TOut?> }
         */
        const _send = (command, parameters) => {
            return _promiseFactory.defer((/** @type { JQuery.Deferred<TOut?> } */deferred) => {
                _blockingSocket.send(command, parameters || {}, (/** @type { TOut? } */ response, /** @type { any } */ errMsg) => {
                    if (errMsg) {
                        deferred.reject(new Error(errMsg));
                        return;
                    }

                    deferred.resolve(response);
                });
            });
        };

        /** @type { IBlockingRepository["getBlockedContacts"] } */
        self.getBlockedContacts = () => {
            return _send('getBlockedContactsList', {});
        };

        /** @type { IBlockingRepository["getBlockingPermissions"] } */
        self.getBlockingPermissions = () => {
            return _send('getBlockingPermissionList', {});
        };

        /** @type { IBlockingRepository["getBlockedPhoneNumberById"] } */
        self.getBlockedPhoneNumberById = (/** @type { string } **/ blockedPhoneNumberId) => {
            return _send('getBlockedPhoneNumberById', { blockedPhoneNumberId });
        };

        /** @type { IBlockingRepository["updateBlockingPermissions"] } */
        self.updateBlockingPermissions = (request) => {
            return _send('updateBlockingPermissionList', request);
        };

        /** @type { IBlockingRepository["blockContact"] } */
        self.blockContact = (request) => {
            return _send('blockContact', request);
        };

        /** @type { IBlockingRepository["blockPhoneNumber"]} */
        self.blockPhoneNumber = (request) => {
            return _send('blockPhoneNumber', request);
        };

        /** @type { IBlockingRepository["reportContact"] } */
        self.reportContact = (request) => {
            return _send('reportContact', request);
        };

        /** @type { IBlockingRepository["reportPhoneNumber"] } */
        self.reportPhoneNumber = (request) => {
            return _send('reportPhoneNumber', request);
        };

        /** @type { IBlockingRepository["unblockContact"] } */
        self.unblockContact = (request) => {
            return _send('unblockContact', request);
        };

        /** @type {IBlockingRepository["unblockPhoneNumber"]} */
        self.unblockPhoneNumber = (request) => {
            return _send('unblockPhoneNumber', request);
        };

        /** @type { IBlockingRepository["onBlockedPhoneNumberCreated"] } */
        self.onBlockedPhoneNumberCreated = (callBack) => {
            _subscribe(`blockedPhoneNumberCreated`, callBack);
        };

        /** @type { IBlockingRepository["onBlockedPhoneNumberDeleted"] } */
        self.onBlockedPhoneNumberDeleted = (callBack) => {
            _subscribe(`blockedPhoneNumberDeleted`, callBack);
        };

        /** @type { IBlockingRepository["onBlockingPermissionChanged"] } */
        self.onBlockingPermissionChanged = (callback) => {
            _subscribe('onBlockingPermissionChanged', callback);
        };

        /** @type { IBlockingRepository["dispose"] } */
        self.dispose = () => {
            _blockingSocket.disposeOfEvents();
        };
    };
});
