define('presentation/settings/accountProfile/validators/accountProfileBusinessValidator',['common/collections/enumerationValidator',
        'constants/companySizeEnumerations',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _enumerationValidator = require('common/collections/enumerationValidator');

        let _commonValidator = null;
        let _viewModel = null;

        const COMPANY_SIZES = require('constants/companySizeEnumerations');

        self.registerViewModel = (viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("businessName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'accountProfileBusiness:businessNameRequired');
            validationRules.field("companySize")
                .addValidationI18n(_validateCompanySize, 'accountProfileBusiness:companySizeRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        self.resetValidation = () => {
            _viewModel.businessName.resetValidation();
            _viewModel.companySize.resetValidation();
            _viewModel.industry.resetValidation();
        };

        const _validateCompanySize = (companySize) => {
            if (companySize !== "pleaseSelect") {
                return _enumerationValidator.isEnumerationValueValid(COMPANY_SIZES, companySize);
            }
            return true;
        };
    };
});

