define('presentation/settings/userProfile/viewModels/confirmPasswordModalViewModel',['businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'presentation/common/modal',
        'presentation/settings/userProfile/facades/confirmPasswordModalFacade',
        'presentation/settings/userProfile/validators/confirmPasswordModalValidator'],
function() {
    return function(emailAddress, onConfirmCallback) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _i18n = require('i18next');

        let _facade = null;
        let _modalService = null;
        let _validator = null;

        let _onConfirmCallback = onConfirmCallback;

        self.modelStateObserver = null;
        self.password = ko.observable('').extend({observeState: true});
        self.emailAddress = emailAddress;
        self.isCompositionComplete = ko.observable(false);
        self.submitButtonText = ko.pureComputed(() => _i18n.t(`confirmPasswordModal:next`));
        self.canDisplayPasswordValidation = ko.observable(false);
        self.passwordIsInvalid =  ko.computed(() => {
            if (typeof self.password.isInvalid === "function") {
                return self.password.isInvalid() && self.canDisplayPasswordValidation();
            }
            return self.canDisplayPasswordValidation();
        });

        self.validate = () => _validator.validate();

        self.confirmPassword = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        self.canDisplayPasswordValidation(true);
                        if (isValid) {
                            _onConfirmCallback(true, self.password())
                                .fail(deferredObject.reject)
                                .done(() => {
                                    deferredObject.resolve();
                                    self.modelStateObserver.navigateWithoutWarning = true;
                                    _modalService.closeModal(self);
                                });
                        } else {
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        self.closeModal = () => {
            self.isCompositionComplete(false);
            self.modelStateObserver.navigateWithoutWarning = true;
            _onConfirmCallback(false);
            _modalService.closeModal(self);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            const FacadeConstructor = require('presentation/settings/userProfile/facades/confirmPasswordModalFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/userProfile/validators/confirmPasswordModalValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.commitData();
            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});

