define('presentation/messages/viewModels/conversationMessageAttachmentViewModel',[
    'constants/smsConversationMessageAttachmentStatus',
    'presentation/common/smsAttachment/factories/smsAttachmentFactory',
    'i18next',
    'constants/attachmentFileTypesConstants',
    'constants/videoPlayerConstants',
    'businessServices/browserSupport/browserType'
], function (
    /** @type typeof import ('constants/smsConversationMessageAttachmentStatus') */
    SmsConversationMessageAttachmentStatus,
    /** @type typeof import ('presentation/common/smsAttachment/factories/smsAttachmentFactory') */
    SmsAttachmentFactory,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('constants/attachmentFileTypesConstants') */
    AttachmentFileTypes,
    /** @type typeof import('constants/videoPlayerConstants') */
    VideoPlayerConstants,
    /** @type typeof import('businessServices/browserSupport/browserType') */
    BrowserType
) {
    return function () {
        /** @type import('presentation/messages/viewModels/conversationMessageAttachmentViewModel') */
        const self = this;

        /** @type {number} **/
        let interval = null;
        /** @type {number} **/
        let animationStartTime = null;

        /** @type { IDisposable[] } */
        let _disposables = [];

        /** @type {KnockoutObservable<import ('constants/smsConversationMessageAttachmentStatus')>} */
        const _conversionStatus = ko.observable(null);

        /** @param { boolean } hasErrorMessage */
        const _onHasErrorMessageChanged = (hasErrorMessage) => {
            _maybeShowErrorOverlay(hasErrorMessage);
        };

        /** @param { boolean } hasErrorMessage */
        const _maybeShowErrorOverlay = (hasErrorMessage) => {
            if (hasErrorMessage) {
                _showAndFadeInErrorOverlay();
            }
        };

        const _showAndFadeInErrorOverlay = () => {
            self.fadeInErrorOverlay(false);
            self.fadeOutErrorOverlay(true);
            setTimeout(() => {
                self.fadeInErrorOverlay(true);
                self.fadeOutErrorOverlay(false);
            }, 100);
        };

        const _hideAndFadeOutLoadingBar = () => {
            self.fadeInLoadingBar(true);
            self.fadeOutLoadingBar(false);
            setTimeout(_hideLoadingBar, 100);
        };

        const _hideLoadingBar = () => {
            self.fadeInLoadingBar(false);
            self.fadeOutLoadingBar(true);
            self.showOnSuccessHover(true);
        };

        /** @param {number} conversionStatus */
        const _onConversionStatusChanged = (conversionStatus) => {
            if (conversionStatus === SmsConversationMessageAttachmentStatus.Converted) {
                _finishLoadingAnimation();
            }
        };

        const _startDefaultLoadingAnimation = () => {
            animationStartTime = Date.now();

            const fileMegaBytes = (self.fileSize() || 0) / (1024 * 1024);

            // specify MB/s loading speed
            const mbPerSecond = 0.35;

            const estimatedTimeoutForFileSize = fileMegaBytes * (1 / mbPerSecond) * 1000;

            const stopAtPercentage = 90;

            // load for at least 5 seconds, more if file is large enough.
            const fileUploadTimeout = Math.max(10000, estimatedTimeoutForFileSize);

            interval = setInterval(() => {
                const elapsedMilliseconds = Date.now() - animationStartTime;

                // 0 - 100
                const currentProgressValue = (elapsedMilliseconds / fileUploadTimeout) * 100;

                // 0 - stopAtPercentage
                const newProgressValue = Math.min(stopAtPercentage, currentProgressValue);

                if (newProgressValue === stopAtPercentage) {
                    clearInterval(interval);
                }

                self.uploadProgress(newProgressValue);
            }, 100);
        };

        const _finishLoadingAnimation = () => {
            clearInterval(interval);

            interval = setInterval(() => {
                let currentValue = self.uploadProgress();
                if (currentValue >= 100) {
                    clearInterval(interval);
                    _hideAndFadeOutLoadingBar();
                } else {
                    currentValue += 2;
                    self.uploadProgress(currentValue);
                }
            }, 50);
        };

        const _generateRandomFileName = (/** @type {File} */file) => {
            if (!file) {
                return null;
            }

            const fileNameParts = (file.name || ``).split(`.`);
            const extension = fileNameParts[fileNameParts.length - 1];

            let text = "";
            const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 25; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return `${text}.${extension}`;
        };

        self.attachmentId = ko.observable(null);
        self.owningMessageId = ko.observable(null);
        self.sequence = ko.observable(null);
        self.createdDateTime = ko.observable(null);
        self.putUrl = ko.observable(null);
        self.file = ko.observable(null);
        self.remoteObjectUrl = ko.observable(null);
        self.remoteObjectContentType = ko.observable(null);
        // 0 to 100
        self.uploadProgress = ko.observable(0);
        self.fadeInErrorOverlay = ko.observable(false);
        self.fadeOutErrorOverlay = ko.observable(false);
        self.fadeInLoadingBar = ko.observable(false);
        self.fadeOutLoadingBar = ko.observable(false);
        self.isGettingPutUrl = ko.observable(false);
        self.isSending = ko.observable(false);
        self.showLoadingState = ko.observable(false);
        self.showOnSuccessHover = ko.observable(false);

        self.imagePresentationObject = ko.observable(null);
        self.videoPresentationObject = ko.observable(null);

        self.conversionStatus = ko.pureComputed({
            read: _conversionStatus
        });

        self.localObjectUrl = ko.pureComputed(() => {
            return self.file() && URL.createObjectURL(self.file());
        });

        self.src = ko.pureComputed(() => {
            const localObjectUrl = self.localObjectUrl();
            const remoteObjectUrl = self.remoteObjectUrl();
            return remoteObjectUrl || localObjectUrl;
        });

        self.videoSrc = ko.pureComputed(() => {
            if (!self.isVideo()) {
                return null;
            }
            const source = self.src();
            return BrowserType.isSafari ? `${source}#t=${VideoPlayerConstants.firstMilliSecond}` : source;
        });

        self.contentType = ko.pureComputed(() => {
            return self.remoteObjectContentType() || (self.file() && self.file().type);
        });

        self.fileName = ko.observable();
        self.fileSize = ko.pureComputed(() => self.file() && self.file().size);

        self.isImage = ko.pureComputed(() => self.contentType() && self.contentType().includes(AttachmentFileTypes.image));
        self.isVideo = ko.pureComputed(() => self.contentType() && self.contentType().includes(AttachmentFileTypes.video));

        self.isConversionComplete = ko.pureComputed(() => {
           return self.conversionStatus() === SmsConversationMessageAttachmentStatus.NoConversion ||
               self.conversionStatus() === SmsConversationMessageAttachmentStatus.Converted ||
               self.conversionStatus() === SmsConversationMessageAttachmentStatus.Received;
        });

        self.isPlayable = ko.pureComputed(() => {
            return !!self.remoteObjectUrl() && self.isConversionComplete();
        });

        self.hasErrorMessage = ko.pureComputed(() => {
            if (self.errorBadgeMessage() || self.tooltipMessage()) {
                return true;
            }

            const conversionStatus = self.conversionStatus();

            return (
                conversionStatus === SmsConversationMessageAttachmentStatus.ConversionError ||
                conversionStatus === SmsConversationMessageAttachmentStatus.FileSizeTooLarge ||
                conversionStatus === SmsConversationMessageAttachmentStatus.TimedOut
            );
        });

        self.errorBadgeMessage = ko.pureComputed(() => {
            switch (self.conversionStatus()) {
                case SmsConversationMessageAttachmentStatus.FileSizeTooLarge:
                    return i18n.t("messages:fileLarge");
                default:
                    return '';
            }
        });

        self.tooltipMessage = ko.pureComputed(() => {
            switch (self.conversionStatus()) {
                case SmsConversationMessageAttachmentStatus.FileSizeTooLarge:
                    return i18n.t("messages:fileLargeExplanation");
                default:
                    return '';
            }
        });

        self.canShowOnSuccessHover = ko.pureComputed(() => self.showOnSuccessHover() && !self.hasErrorMessage());

        /** @type self["updateConversionStatus"] */
        self.updateConversionStatus = (newConversionStatus) => {
            const currentConversionStatus = self.conversionStatus();

            switch (currentConversionStatus) {
                case SmsConversationMessageAttachmentStatus.NoConversion:
                case SmsConversationMessageAttachmentStatus.Converted:
                    break;
                default:
                    _conversionStatus(newConversionStatus);
            }
        };

        self.isReadyToCreate = () => {
            return !!self.file() && !self.attachmentId();
        };

        self.isReadyToSend = () => {
            return !!self.attachmentId() && !self.hasErrorMessage();
        };

        self.isReadyToDisplay = () => {
            return !!self.src();
        };

        self.compositionComplete = () => {
            _disposables.push(
                self.hasErrorMessage.subscribe(_onHasErrorMessageChanged),
                self.conversionStatus.subscribe(_onConversionStatusChanged)
            );
        };

        /** @type self["updateAttachment"] */
        self.updateAttachment = (src, contentType, conversionStatus) => {
            self.remoteObjectUrl(src);
            self.remoteObjectContentType(contentType);
            if (contentType.includes(AttachmentFileTypes.video)) {
                self.videoPresentationObject(SmsAttachmentFactory.getVideoPlayer(src, contentType, self.attachmentId()));
            } else {
                self.imagePresentationObject(SmsAttachmentFactory.getImage(src, contentType));
            }
            self.updateConversionStatus(conversionStatus);
        };

        self.detached = () => {
            _disposables.forEach(disposable => disposable.dispose());
            _disposables = [];
        };

        /** @type self["activate"] */
        self.activate = (activationData) => {
            const {
                attachmentId = null,
                owningMessageId = null,
                file = null,
                conversionStatus = null,
                sequence = null,
                createdDateTime = null,
                remoteObjectUrl = null,
                remoteObjectContentType = null,
                showLoadingState = null
            } = activationData;

            self.attachmentId(attachmentId);
            self.owningMessageId(owningMessageId);
            self.sequence(sequence);
            self.createdDateTime(createdDateTime);
            self.file(file);
            self.remoteObjectUrl(remoteObjectUrl);
            self.remoteObjectContentType(remoteObjectContentType);
            self.showLoadingState(showLoadingState);

            _conversionStatus(conversionStatus);

            self.fileName(_generateRandomFileName(file));

            self.imagePresentationObject(SmsAttachmentFactory.getImage(self.src(), self.contentType()));
            self.videoPresentationObject(SmsAttachmentFactory.getVideoPlayer(self.src(), self.contentType()));

            if (showLoadingState) {
                _hideLoadingBar();
                return;
            }

            switch (conversionStatus) {
                case SmsConversationMessageAttachmentStatus.Uploaded:
                case SmsConversationMessageAttachmentStatus.ConversionRequested:
                    self.uploadProgress(50);
                    _startDefaultLoadingAnimation();
                    break;
                case SmsConversationMessageAttachmentStatus.ReadyToUpload:
                    _startDefaultLoadingAnimation();
                    break;
                default:
                    _hideLoadingBar();
            }
        };
    };
});
