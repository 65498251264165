define('presentation/settings/messagingCompliance/viewModels/authorizedSignatureActionModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/settings/messagingCompliance/validators/authorizedSignatureActionModalValidator'
], function (
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    return function (
        /** @type { (messagingCompliance: any) => JQueryDeferred<any> } */
        _saveMessagingComplianceCallback,
        /** @type { any } */
        messagingCompliance
    ) {
        /** @typedef { import('presentation/settings/messagingCompliance/validators/authorizedSignatureActionModalValidator') } AuthorizedSignatureActionModalValidator } */

        const self = this;

        const _promiseFactory = new PromiseFactory();

        /** @type { AuthorizedSignatureActionModalValidator | null } */
        let _validator = null;

        self.authorizedSignature = ko.observable();

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((/** @type {boolean} */ isValid) => {
                        if (isValid === false) {
                            promise.resolve({isValid});
                        } else {
                            messagingCompliance.authorizedSignature = self.authorizedSignature();
                            _saveMessagingComplianceCallback(messagingCompliance)
                                .fail(promise.reject)
                                .done((response) => {
                                    promise.resolve(response);
                                });
                        }
                    });
            });
        };

        self.activate = () => {
            const ValidatorConstructor = require('presentation/settings/messagingCompliance/validators/authorizedSignatureActionModalValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self);

            return _promiseFactory.wait();
        };
    };
});

