define('presentation/settings/userGroup/viewModels/addUserGroupViewModel',['businessServices/state/modelStateObserver',
        'businessServices/router/router',
        'common/promises/promiseFactory',
        'presentation/settings/userGroup/viewModels/userGroupProfileViewModel',
        'presentation/settings/userGroup/viewModels/userGroupVoicemailViewModel',
        'presentation/tabs/presentationObjects/tabsPresentationObject',
        'settings/navigationConfiguration'
], function() {
    return function() {
        const self = this;
       
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');
        const UserGroupProfileTabViewModelConstructor = require('presentation/settings/userGroup/viewModels/userGroupProfileViewModel');
        const UserGroupVoicemailTabViewModelConstructor = require('presentation/settings/userGroup/viewModels/userGroupVoicemailViewModel');

        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        let _disposables = [];

        const _updateActiveTabViewModel = () => {
            switch (self.tabActive()) {
                case self.tabMetadata.profile.id:
                    self.activeTabViewModel(self.userGroupProfileTabViewModel);
                    break;
                case self.tabMetadata.voicemail.id:
                    self.activeTabViewModel(self.userGroupVoicemailTabViewModel);
                    break;
            }
        };

        self.userGroupId = null;
        self.modelStateObserver = null;
        self.tabActive = ko.observable();
        self.tabMetadata = {
            profile: { id: "profile", isValid: ko.observable(true), title: _i18n.t('addUserGroup:profileTab') },
            voicemail: { id: "voicemail", isValid: ko.observable(true), title: _i18n.t('addUserGroup:voicemailTab') },
        };
        self.tabs = [
            new TabPresentationObjectConstructor(self.tabMetadata.profile.id, self.tabActive, self.tabMetadata.profile.isValid, self.tabMetadata.profile.title),
            new TabPresentationObjectConstructor(self.tabMetadata.voicemail.id, self.tabActive, self.tabMetadata.voicemail.isValid, self.tabMetadata.voicemail.title)
        ];

        self.tabActive(self.tabs[0].id);
        self.isTabActive = (tabId) => {
            return self.tabActive() === tabId;
        };
        self.userGroupProfileTabViewModel = null;
        self.userGroupVoicemailTabViewModel = null;
        self.activeTabViewModel = ko.observable();
        self.userGroupsUrl = _navigationConfiguration.routesById.userGroups.url;
        self.headerI18nKey = ko.pureComputed(() => self.userGroupId ? "addUserGroup:headerEdit" : "addUserGroup:headerAdd");
        self.saveButtonTitle = ko.pureComputed(() => self.userGroupId ? _i18n.t('addUserGroup:save') : _i18n.t('addUserGroup:add'));
        self.invalidTabs = ko.pureComputed(() => {
            return self.tabs.reduce((accumulator, presentationObject) => {
                if (!presentationObject.isTabValid()) {
                    accumulator.push(presentationObject.tabText);
                }

                return accumulator;
            }, []);
        });

        self.validationWarningMessages = ko.pureComputed(() => {
            return [
                {
                    message: _i18n.t('addUserGroup:validationTabDescription'),
                    listItems: self.invalidTabs()
                },
                {
                    message: _i18n.t('addUserGroup:validationPleaseFix'),
                    listItems: []
                }
            ];
        });

        self.forceValidation = () => {
            return _promiseFactory.defer((validatePromise) => {
                let isProfileValid = null;
                let isVoicemailValid = null;

                let validatePromiseFactory = new PromiseFactoryConstructor();
                validatePromiseFactory.defer((profileValidationDeferredObject) => {
                    self.userGroupProfileTabViewModel.forceValidation()
                        .fail(profileValidationDeferredObject.reject)
                        .done((isValid) => {
                            isProfileValid = isValid;
                            profileValidationDeferredObject.resolve();
                        });
                });

                validatePromiseFactory.defer((voicemailValidationPromise) => {
                    self.userGroupVoicemailTabViewModel.forceValidation()
                        .fail(voicemailValidationPromise.reject)
                        .done((isValid) => {
                            isVoicemailValid = isValid;
                            voicemailValidationPromise.resolve();
                        });
                });

                validatePromiseFactory.wait()
                    .fail(validatePromise.reject)
                    .done(() => {
                        self.tabMetadata.profile.isValid(isProfileValid);
                        self.tabMetadata.voicemail.isValid(isVoicemailValid);
                        validatePromise.resolve(isProfileValid && isVoicemailValid);
                    });

            });
        };

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                self.forceValidation()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            promise.resolve();
                            return;
                        }

                        self.userGroupProfileTabViewModel.save()
                            .fail(promise.reject)
                            .done((userGroup) => {
                                self.userGroupVoicemailTabViewModel.savePage(userGroup.groupId)
                                    .fail(promise.reject)
                                    .done(() => {
                                        self.modelStateObserver.saveData();
                                        _router.navigate(self.userGroupsUrl);
                                        promise.resolve();
                                    });
                            });
                    });
            });
        };

        self.cancelForm = () => {
            self.userGroupProfileTabViewModel.cancelForm();
            self.userGroupVoicemailTabViewModel.cancelForm();
            self.modelStateObserver.restoreData();

            _router.navigate(self.userGroupsUrl);
        };

        self.detached = () => {
            self.userGroupVoicemailTabViewModel.dispose();

            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = (editId) => {
            self.userGroupProfileTabViewModel = new UserGroupProfileTabViewModelConstructor();
            self.userGroupVoicemailTabViewModel = new UserGroupVoicemailTabViewModelConstructor();

            return _initialize(editId);
        };

        const _initialize = (editId) => {
            if (editId) {
                self.userGroupId = editId;
            }

            _promiseFactory.defer((initPromise) => {
                _initializeTabs()
                    .fail(initPromise.reject)
                    .done(() => {
                        self.modelStateObserver = new ModelStateObserverConstructor(self, true, [self.userGroupProfileTabViewModel.modelStateObserver, self.userGroupVoicemailTabViewModel.modelStateObserver]);
                        self.modelStateObserver.commitData();
                        initPromise.resolve();
                    });

            });

            return _promiseFactory.wait();
        };

        const _initializeTabs = () => {
            return _promiseFactory.defer((initTabsPromise) => {
                let initTabsPromiseFactory = new PromiseFactoryConstructor();
                initTabsPromiseFactory.defer((profilePromise) => {
                    self.userGroupProfileTabViewModel.activate(self.userGroupId)
                        .fail(profilePromise.reject)
                        .done(profilePromise.resolve);
                });
                initTabsPromiseFactory.defer((voicemailPromise) => {
                    self.userGroupVoicemailTabViewModel.activate(self.userGroupId)
                        .fail(voicemailPromise.reject)
                        .done(voicemailPromise.resolve);
                });

                initTabsPromiseFactory.wait()
                    .fail(initTabsPromise.reject)
                    .done(() => {
                        let firstTab = self.tabs.find(t => true);
                        self.tabActive(firstTab.id);

                        _updateActiveTabViewModel();
                        _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                        initTabsPromise.resolve();
                    });
            });
        };
    };
});
