define('presentation/settings/userDevices/presentationObjects/userDevicePresentationObject',[],function() {
    return function(){
        const self = this;

        self.deviceId = null;
        self.deviceName = "";
        self.devicePhoneNumber = "";
        self.deviceCountryAbbreviation = "us";
        self.selectedCallerId = null;
        self.physicalDeviceId = ko.observable(null);
        self.devicePlatform = ko.observable(null);
        self.mobileAppInstallationType = "";
        self.createdDateTime = null;
        self.modifiedDateTime = null;
        self.createdDateTimeFilter = null;
        self.modifiedDateTimeFilter = null;
    };
});

