define('presentation/settings/autoAttendant/presentationObjects/subMenuItemPresentationObject',[],function() {
    return function(){
        var self = this;

        self.key = null;
        self.optionName = ko.observable(null).extend({observeState: true});
        self.letters = null;
        self.routeId = ko.observable(null);
        self.typeId = ko.observable(null).extend({observeState: true});
        self.type = ko.observable(null).extend({observeState: true});
        self.voicePromptId = ko.observable(null).extend({observeState: true});
        self.isSystemPrompt = ko.observable(false);
        self.showVoicePromptOption = ko.observable(false);
        self.isVoicePromptEnabled = ko.observable(null);
        self.isVoicePromptInvalid = ko.observable(false);
        self.routingRules = ko.observable(null);
        self.connector = ko.observable(null);
        self.showControls = ko.observable(true);
        self.isActive = ko.pureComputed(() => self.routeId() || self.connector());
        self.keyDisplay = ko.pureComputed(() => self.key === '*' ? "＊" : self.key);
    };
});

