define('presentation/settings/userGroup/presentationObjects/voicemailGreetingPresentationObject',['i18next'],function() {
    return function() {
        const self = this;

        const _i18n = require('i18next');

        self.voicemailBoxGreetingId = null;
        self.voicemailGreetingId = null;
        self.greetingId = null;
        self.name = ko.observable("").extend({observeState: true});
        self.file = ko.observable("").extend({observeState: true});
        self.greetingAudioObject = ko.observable();
        self.isBuiltIn =  ko.observable(false);
        self.isDefault = ko.observable(false).extend({observeState: true});
        self.createdDateTime = "";
        self.modifiedDateTime = "";
        self.isAddMode = ko.observable(false);
        self.isEditMode = ko.observable(false);
        self.isDeleted = ko.observable(false).extend({observeState: true});
        self.isAccount = false;
        self.requiresUpdate = false;
        self.addVoicemailGreetingViewModel = ko.observable();
        self.showSpinner = ko.observable(false);

        self.showForm = ko.computed(() => self.isEditMode() || self.isAddMode());
        self.defaultIndicatorText = ko.pureComputed(() => self.isDefault() ?  _i18n.t('userGroupVoicemailGreetings:default') : _i18n.t('userGroupVoicemailGreetings:setDefault'));
        self.editTooltipMessage = ko.pureComputed(() => self.isBuiltIn() ? _i18n.t('userGroupVoicemailGreetings:editTooltip') : "");
        self.headerI18nKey = ko.pureComputed(() => self.voicemailBoxGreetingId ? "addVoicemailGreeting:headerEdit" : "addVoicemailGreeting:headerAdd");
        self.saveButtonTitle = ko.pureComputed(() => self.voicemailBoxGreetingId ? _i18n.t('addVoicemailGreeting:save') : _i18n.t('addVoicemailGreeting:add'));

        self.deleteTooltipMessage = ko.pureComputed(() => {
            if (self.isBuiltIn()) {
                return  _i18n.t('userGroupVoicemailGreetings:deleteTooltip');
            } else if (self.isDefault()) {
                return  _i18n.t('userGroupVoicemailGreetings:currentTooltip');
            } else {
                return "";
            }
        });
    };
});
