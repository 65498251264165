define('presentation/numberPicker/validators/numberPickerValidator',[
    'constants/numberPickerConstants',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
], function() {
    return function() {
        let self = this;

        let _viewModel = null;
        let _commonValidator = null;

        const _numberPickerConstants = require('constants/numberPickerConstants');

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        self.registerViewModel = function(viewModel) {
            let validationRules = new ValidationRulesConstructor();
            _viewModel = viewModel;

            validationRules.field("vanityString")
                .addValidationI18n(_validateVanityCharacters, 'addPhoneNumber:invalidVanityCharacter')
                .addValidationI18n(_validateStringLength, invalidVanityLengthI18n());
            
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = () => _commonValidator.validate();

        const _validateVanityCharacters = (vanityString) => /^[0-9A-Za-z]*$/.test(vanityString);

        const invalidVanityLengthI18n = () => _viewModel.isLocal() ? 'addPhoneNumber:invalidLocalVanityLength' : 'addPhoneNumber:invalidTollVanityLength';

        const _validateStringLength = (vanityString) => {
            return (
                (_viewModel.isLocal() && vanityString.length >= _numberPickerConstants.localVanityStringMinimumLength) ||
                (_viewModel.isTollFree() && vanityString.length >= _numberPickerConstants.tollFreeVanityStringMinimumLength)
            );
        };
    };
});

