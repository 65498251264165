define('persistence/dataProviders/audioConversionDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_audio_conversion','GET')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(audioConversionId) {

                return _promiseFactory.defer(function(deferredObject) {
                    if(audioConversionId === null){
                        var err = new Error("Missing audioConversionId");
                        deferredObject.reject(err);
                    } else {
                        _kazooApi.callAccount('/lobby_audio_conversion/' + audioConversionId, 'GET')
                            .done(function (result) {
                                deferredObject.resolve(result.data.data);
                            })
                            .fail(function (error) {
                                deferredObject.reject(error);
                            });
                    }
                });
        };
    };
});

