define('presentation/settings/userGroup/presentationObjects/userPresentationObject',[],function() {
    return function(){
        var self = this;

        self.userId = null;
        self.fullName =  null;
		self.emailAddress = null;
        self.emailAddressLink = null;
		
		self.avatarFileEncodingType =  null;
        self.userAvatar = null;

        self.isDetailsOpen = ko.observable(false);
    };
});

