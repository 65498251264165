define('presentation/acceptUserInvite/facades/createUserDevicesFacade',[
        'businessServices/authentication/authenticationManager',
        'persistence/webSocket/webSocketApp',
        'presentation/acceptUserInvite/presentationObjects/createUserDevicePresentationObject'
    ],
    function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        let _webSocketApp = null;
        const CreateUserDevicePresentationObject = require('presentation/acceptUserInvite/presentationObjects/createUserDevicePresentationObject');
        
        const _buildDevicesPresentation = (devices) => {
            return devices.map((device) => {
                let userDevice = new CreateUserDevicePresentationObject();
                userDevice.deviceId(device.deviceId);
                userDevice.deviceName(device.deviceName);
                userDevice.devicePhoneNumber(device.devicePhoneNumber);
                userDevice.selectedCallerId(device.callerId);
                if (device.deviceCountry !== null && device.deviceCountry !== undefined) {
                    userDevice.deviceCountryAbbreviation(device.deviceCountry);
                }
                return userDevice;
            });
        };

        const _getDevices = () => {
            return _promiseFactory.defer((promise) => {
                const params = {};

                _webSocketApp.send("get_user_devices", params, (result) => {
                    promise.resolve(_buildDevicesPresentation(result));
                });
            });
        };

        const _saveDevices = (devices, linkItems) => {
            const parameters = {
                devices: devices.map((device) => {
                    return {
                        callerId: device.selectedCallerId(),
                        deviceId: device.deviceId(),
                        deviceName: device.deviceName(),
                        devicePhoneNumber: device.devicePhoneNumber(),
                        deviceCountry: device.deviceCountryAbbreviation()
                    };
                }),
                link: linkItems.link,
                signature: linkItems.signature
            };
            return _promiseFactory.defer((promise) => {
                _webSocketApp.send("save_user_devices", parameters, (result) => {
                    promise.resolve({
                        status: result.status
                    });
                });
            });
        };

        const _getDefaultDevice = () => {
            return _promiseFactory.defer((promise) => {
                    const defaultDevice = new CreateUserDevicePresentationObject();
                    promise.resolve(defaultDevice);
            });
        };


        const _init = () => {
            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("user_accept_invite");
        };

        return function() {
            const self = this;

            self.init = _init;
            self.getDevices = _getDevices;
            self.saveDevices = _saveDevices;
            self.getDefaultDevice = _getDefaultDevice;
        };
    });

