define('presentation/common/audioPlayer/viewModels/audioPlayerViewModel',['businessServices/converters/durationTimeFormatter',
        'common/uniqueId/uniqueIdGenerator'
], function() {
    return function () {
        let self = this;
        let _settings = null;
        let _parentContext = null;
        let _onPlayFunction = null;

        const UniqueNameGeneratorConstructor = require('common/uniqueId/uniqueIdGenerator');
        const _uniqueNameGenerator = new UniqueNameGeneratorConstructor();

        const DurationTimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const _durationTimeFormatter = new DurationTimeFormatterConstructor();

        self.audioObject = null;
        self.audioPlayerId = null;
        self.audioFileDurationInSecondsDisplay = ko.observable(null);
        self.audioFileElapsedDurationDisplay = ko.observable();
        self.autoPlay = false;
        self.showDuration = true;
        self.showElapsedDuration = false;
        self.showProgressSlider = true;
        self.isDisabled = false;

        self.onPlay = () => {
            if (_onPlayFunction !== null) {
                _onPlayFunction(_parentContext);
            }
        };

        self.showDurationInSeconds = ko.computed(() => {
            return !!self.audioFileDurationInSecondsDisplay();
        });

        self.activate = (settings) => {
            _settings = settings;
            self.audioObject = _settings.audioObject;
            self.audioPlayerId = _uniqueNameGenerator.generateUniqueIdWithoutHyphens();

            if (_settings.onPlayFunction !== undefined) {
                _onPlayFunction = _settings.onPlayFunction;
            }

            if (_settings.context !== undefined) {
                _parentContext = _settings.context;
            }

            if (_settings.autoPlay !== undefined) {
                self.autoPlay = _settings.autoPlay;
            }

            if (_settings.showDuration !== undefined) {
                self.showDuration = _settings.showDuration;
            }

            if (_settings.showElapsedDuration !== undefined) {
                self.showElapsedDuration = _settings.showElapsedDuration;
            }

            if (_settings.showProgressSlider !== undefined) {
                self.showProgressSlider = _settings.showProgressSlider;
            }

            if (_settings.isDisabled !== undefined) {
                self.isDisabled = _settings.isDisabled;
            }

            const durationInSeconds = ko.unwrap(_settings.audioFileDurationInSeconds);
            const formattedSeconds = _durationTimeFormatter.formatTotalSeconds(durationInSeconds);
            self.audioFileDurationInSecondsDisplay(formattedSeconds);
            const formattedElapsedTime = _durationTimeFormatter.formatSecondsSummary(durationInSeconds);
            self.audioFileElapsedDurationDisplay(formattedElapsedTime);
        };
    };
});

