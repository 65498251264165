define('presentation/common/dateTimeValue',['common/converters/passedTimeSpanFormatter',
    'common/time/date'], function(){

    return function(dateISOString) {
        var self = this;

        var PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        var DateConstructor = require('common/time/date');
        var _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();
        var date = new DateConstructor();
        var now = new DateConstructor();

        now.setNow();

        if (dateISOString) {
            date.setFromDateString(dateISOString);
            self.displayValue = _passedTimeSpanFormatter.format(dateISOString);
            self.formattedDateString = _passedTimeSpanFormatter.formatDateString(dateISOString);
        } else {
            self.displayValue = _passedTimeSpanFormatter.format(date.getISOString());
            self.formattedDateString = _passedTimeSpanFormatter.formatDateString(date.getISOString());
        }

        self.formattedTime = _passedTimeSpanFormatter.formatTime(date);
        self.formattedDate = _passedTimeSpanFormatter.formatDate(date);
        self.formattedBillingHistoryDate = _passedTimeSpanFormatter.formatBillingHistoryDate(date);

        if(date.isOnSameDay(now)) {
            self.filterValue = self.formattedTime + " " + self.formattedDate;
        } else {
            self.filterValue = self.formattedDate;
        }

        self.sortValue = date.formatForSorting();
    };
});

