define('presentation/acceptUserInvite/facades/createUserProfileFacade',[
    'persistence/webSocket/webSocketApp',
    'presentation/acceptUserInvite/presentationObjects/createUserProfilePresentationObject',
    'presentation/common/userAvatar/userAvatarImageFactory',
    'persistence/fileStores/userAvatarFileStore',
],
function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = null;
    let _webSocketApp = null;
    const CreateUserProfilePresentationObject = require('presentation/acceptUserInvite/presentationObjects/createUserProfilePresentationObject');
    let _userAvatarImageFactory = null;
    let _userAvatarFileStore = null;

    const _getUserProfile = (linkItems) => {
        return _promiseFactory.defer((deferredObject) => {

            _webSocketApp.send("getUserProfile", linkItems, (result) => {
                const userProfile = new CreateUserProfilePresentationObject();
                userProfile.userId = result.userId;
                userProfile.firstName = result.firstName;
                userProfile.lastName = result.lastName;
                userProfile.title = result.title;
                userProfile.emailAddress = result.emailAddress;
                userProfile.verificationQuestion = result.verificationQuestion;
                userProfile.verificationAnswer = result.verificationAnswer;
                userProfile.timeZone = result.timeZone;

                _userAvatarImageFactory.getAvatarByUserId(result.userId)
                    .done((avatar) => {
                        userProfile.avatar = avatar;
                        deferredObject.resolve(userProfile);
                    })
                    .fail(deferredObject.reject);
            });
        });
    };

    const _saveUserProfile = (userProfileData, linkItems) => {
        return _promiseFactory.defer((deferredObject) => {

            const socketParameters = {
                firstName: userProfileData.firstName,
                lastName: userProfileData.lastName,
                title: userProfileData.title,
                emailAddress: userProfileData.emailAddress,
                password: userProfileData.password,
                verificationQuestion: userProfileData.verificationQuestion,
                verificationAnswer: userProfileData.verificationAnswer,
                timeZone: userProfileData.timeZone,
                link: linkItems.link,
                signature: linkItems.signature
            };

            const localPromise = new PromiseFactoryConstructor();
            let saveUserResult;
            localPromise.defer((userDeferredObject) => {
                _webSocketApp.send("save_user_profile", socketParameters, (result) => {
                    saveUserResult = result;
                    userDeferredObject.resolve();
                });
            });

            if (userProfileData.avatar &&
                    (userProfileData.avatar.showImage() === true) &&
                    (userProfileData.avatar.isSaved === false)) {
                localPromise.defer((userAvatarDeferredObject) => {
                    _userAvatarFileStore.uploadUserAvatar(userProfileData.userId, userProfileData.avatar)
                        .done(userAvatarDeferredObject.resolve)
                        .fail(userAvatarDeferredObject.reject);
                });
            }

            localPromise.wait()
                .done(() => {
                    deferredObject.resolve(saveUserResult);
                })
                .fail((error) => {
                    deferredObject.reject(error);
                });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_accept_invite");

        const UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
        _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
        _userAvatarImageFactory.init();

        const UserAvatarFileStoreConstructor = require('persistence/fileStores/userAvatarFileStore');
        _userAvatarFileStore = new UserAvatarFileStoreConstructor();
        _userAvatarFileStore.init();
    };

    return function() {
        const self = this;

        self.init = _init;
        self.getUserProfile = _getUserProfile;
        self.saveUserProfile = _saveUserProfile;
    };
});

