define('presentation/settings/schedules/viewModels/scheduleRangeViewModel',[
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/uniqueId/guidUtil',
    'common/uniqueId/userGroupIdUtil',
    'presentation/common/routeToModal/strategies/scheduleSegmentStrategy',
    'presentation/common/routeToModal/viewModels/routeToViewModel',
    'presentation/settings/schedules/validators/scheduleRangeViewModelValidator'
], function () {
    return function (parent, originalSegment, timeZoneName) {
        const self = this;

        const RouteToViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToViewModel');
        const RouteToStrategyConstructor = require('presentation/common/routeToModal/strategies/scheduleSegmentStrategy');
        const _i18n = require('i18next');
        const _originalSegment = originalSegment;
        const _parent = parent;

        const DEFAULT_START_TIME = '9:00 am';
        const DEFAULT_END_TIME = '6:00 pm';

        let _disposables = [];

        const applyEndHourMeridiem = (hour, minute, meridiem) => {
            if (hour === 12 && meridiem === "am" && minute === 0) {
                return 24;
            } else {
                return applyHourMeridiem(hour, meridiem);
            }
        };

        const applyHourMeridiem = (hour, meridiem) => {
            if (meridiem === "pm") {
                if (hour === 12) {
                    // 12pm = 12
                    return 12;
                } else {
                    // 1pm = 13
                    return hour + 12;
                }
            }
            if (hour === 12) {
                // 12 am = 0
                return 0;
            }
            return hour;
        };

        const convertMilitaryTimeToStandard = (hour) => {
            if ((hour === 0) || (hour === 24)) {
                return {
                    hour: 12,
                    meridiem: 'am'
                };
            }
            if (hour === 12) {
                return {
                    hour: 12,
                    meridiem: 'pm'
                };
            }
            if (hour < 12) {
                return {
                    hour: hour,
                    meridiem: 'am'
                };
            } else {
                return {
                    hour: hour - 12,
                    meridiem: 'pm'
                };
            }
        };

        const formatTimeValue = (hour, min, meridiem) => {
            if (min < 10) {
                min = '0' + min;
            }

            return hour + ':' + min + ' ' + meridiem;
        };

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const convertStartHourMinuteAmPmToTime2400 = (hour, minute, amPm) => applyHourMeridiem(hour, amPm) * 100 + minute;
        const convertEndHourMinuteAmPmToTime2400 = (hour, minute, amPm) => applyEndHourMeridiem(hour, minute, amPm) * 100 + minute;

        self.routeToViewModel = ko.observable().extend({observeState: true});
        self.routeToOptions = {};
        self.routeToStrategy = new RouteToStrategyConstructor();
        self.timeZoneName = timeZoneName;

        self.sequence = ko.observable(0).extend({observeState: true});
        self.rangeName = ko.observable('').extend({observeState: true});
        self.legendRangeName = ko.pureComputed(() => {
            const name = self.rangeName();
            if (name) {
                return name;
            }
            const sequence = self.sequence();
            return `Range ${sequence}`;
        });
        self.placeholderMessage = _i18n.t('addSchedule:rangePlaceholderMessage');

        self.selectedStartTime = ko.observable(DEFAULT_START_TIME).extend({observeState: true});
        self.currentStartTime = ko.pureComputed(() => {
            return self.availableTimes().find((time) => {
                return time.value === self.selectedStartTime();
            });
        });
        self.selectedStartTime2400 = ko.pureComputed(() => convertStartHourMinuteAmPmToTime2400(self.currentStartTime().hour, self.currentStartTime().min, self.currentStartTime().meridiem));

        self.selectedEndTime = ko.observable(DEFAULT_END_TIME).extend({observeState: true});
        self.currentEndTime = ko.pureComputed(() => {
            return self.availableTimes().find((time) => {
                return time.value === self.selectedEndTime();
            });
        });
        self.selectedEndTime2400 = ko.pureComputed(() => convertEndHourMinuteAmPmToTime2400(self.currentEndTime().hour, self.currentEndTime().min, self.currentEndTime().meridiem));

        self.availableTimes = ko.pureComputed(() => {
            return Array.from({
                length: 24 * 60 / 15
            }, (v, i) => {
                let hour = Math.floor(i * 15 / 60);
                let min = i * 15 - hour * 60;
                let meridiem = 'am';

                if (hour >= 12) {
                    meridiem = 'pm';
                    hour -= 12;
                }

                if (hour === 0) {
                    hour = 12;
                }

                return {
                    hour: hour,
                    min: min,
                    meridiem: meridiem,
                    value: formatTimeValue(hour, min, meridiem),
                    display: formatTimeValue(hour, min, meridiem.toUpperCase())
                };
            });
        });

        self.modelStateObserver = null;

        self.isSundaySelected = ko.observable().toggleable().extend({observeState: true});
        self.isMondaySelected = ko.observable().toggleable().extend({observeState: true});
        self.isTuesdaySelected = ko.observable().toggleable().extend({observeState: true});
        self.isWednesdaySelected = ko.observable().toggleable().extend({observeState: true});
        self.isThursdaySelected = ko.observable().toggleable().extend({observeState: true});
        self.isFridaySelected = ko.observable().toggleable().extend({observeState: true});
        self.isSaturdaySelected = ko.observable().toggleable().extend({observeState: true});

        self.deleteMe = () => {
            _parent.deleteRange(self);
        };

        self.validator = null;
        self.canDisplayValidation = ko.observable(false);
        self.areSelectedDaysValid = ko.observable();
        self.areTimeSelectionsValid = ko.observable();
        self.doesSegmentOverlap = ko.observable();
        self.isRoutingRuleValid = ko.observable();

        self.shouldPreventDeletion = ko.computed(() => {
            return !_parent.canDeleteRange();
        });
        
        self.deleteToolTip = ko.computed(() => {
            return self.shouldPreventDeletion() ? _i18n.t('addSchedule:lastItemTooltip') : '';
        });

        self.rangeClass = ko.pureComputed(() => `schedule-range__${self.sequence()}`);

        self.rangeIndicatorClass = ko.pureComputed(() => {
            const sequence = self.sequence();
            if (sequence === 0 ) {
                return "range-name-textbox-indicator__sequence-all-other";
            }

            let scheduleSequence = _parent.ranges().length;
            if (scheduleSequence >= 1 && scheduleSequence <= 3){
                if (sequence === 1) {
                    return "range-name-textbox-indicator__sequence-1";
                } else if (sequence === 2) {
                    return "range-name-textbox-indicator__sequence-3";
                } else {
                    return "range-name-textbox-indicator__sequence-6";
                }
            }

            if (scheduleSequence >= 4) {
                return `range-name-textbox-indicator__sequence-${sequence}`;
            } else {
                return "range-name-textbox-indicator__sequence-all-other";
            }
        });

        self.getSegment = () => {
            let accountAttendantScheduleSegmentId = null;
            if (_originalSegment) {
                accountAttendantScheduleSegmentId = originalSegment.accountAttendantScheduleSegmentId;
            }

            const endMinute = self.currentEndTime().min;
            const routingRules = self.routeToStrategy.buildRoutingRule(self.routeToViewModel());

            const result = {
                accountAttendantScheduleSegmentId: accountAttendantScheduleSegmentId,
                name : self.rangeName(),
                sequence: self.sequence(),
                isForSunday: self.isSundaySelected(),
                isForMonday: self.isMondaySelected(),
                isForTuesday: self.isTuesdaySelected(),
                isForWednesday: self.isWednesdaySelected(),
                isForThursday: self.isThursdaySelected(),
                isForFriday: self.isFridaySelected(),
                isForSaturday: self.isSaturdaySelected(),
                startHour: applyHourMeridiem(self.currentStartTime().hour, self.currentStartTime().meridiem),
                startMinute: self.currentStartTime().min,
                endHour: applyEndHourMeridiem(self.currentEndTime().hour, endMinute, self.currentEndTime().meridiem),
                endMinute: endMinute,
                routingRule: routingRules,
            };

            return result;
        };

        self.selectedRouteToOption = ko.observable().extend({observeState: true});

        self.routeToViewModel = ko.observable();

        const buildRouteToViewModel = (routeToType, routeToData) => {
            const routeToViewModel = new RouteToViewModelConstructor(routeToType, routeToData, _validateRouteToViewModelCallback, {schedule: {enabled: false}});
            routeToViewModel.routeToOptions.subMenu.enabled = false;
            routeToViewModel.routeToStrategy = self.routeToStrategy;
            routeToViewModel.showAnswerTimeoutOptions(false);
            routeToViewModel.routeTitle(_i18n.t("addSchedule:routeToTitle"));
            routeToViewModel.showRouteToTitle(true);
            routeToViewModel.showRouteToOptions(true);
            routeToViewModel.parentViewModel(parent);
            routeToViewModel.modelStateObserver.commitData();

            return routeToViewModel;
        };

        const _validateRouteToViewModelCallback = () => {
            self.canDisplayValidation(false);
            return true;
        };

        self.overlapRangeName = ko.observable('');
        self.overlapRangeI18nKey = ko.pureComputed(() => {
            return self.overlapRangeName() !== '' ?
                {key: 'addSchedule:segmentsShouldNotOverlap_name', options: {name: self.overlapRangeName()}} :
                "addSchedule:segmentsShouldNotOverlap";
        });
        self.shouldDisplayOverlapValidation = ko.computed(() => self.overlapRangeName() !== '');
        self.doAnySegmentsOverlap = () => {
            const doesSegmentOverlap = _parent.ranges().some((otherSegment, index) => {
                if ((index + 1) === self.sequence()) {
                    self.overlapRangeName('');
                    otherSegment.overlapRangeName('');
                    return false;
                }

                const doSegmentsHaveAMatchingDay =
                    self.isSundaySelected() && otherSegment.isSundaySelected() ||
                    self.isMondaySelected() && otherSegment.isMondaySelected() ||
                    self.isTuesdaySelected() && otherSegment.isTuesdaySelected() ||
                    self.isWednesdaySelected() && otherSegment.isWednesdaySelected() ||
                    self.isThursdaySelected() && otherSegment.isThursdaySelected() ||
                    self.isFridaySelected() && otherSegment.isFridaySelected() ||
                    self.isSaturdaySelected() && otherSegment.isSaturdaySelected();
                if (!doSegmentsHaveAMatchingDay) {
                    self.overlapRangeName('');
                    otherSegment.overlapRangeName('');
                    return false;
                }

                const doSegmentsOverlap = Math.max(self.selectedStartTime2400(), otherSegment.selectedStartTime2400()) < Math.min(self.selectedEndTime2400(), otherSegment.selectedEndTime2400());
                const isStartAndEndTheSame = self.selectedStartTime2400() === otherSegment.selectedStartTime2400() && self.selectedEndTime2400() === otherSegment.selectedEndTime2400();
                if (doSegmentsOverlap || isStartAndEndTheSame) {
                    self.overlapRangeName(otherSegment.rangeName());
                    otherSegment.overlapRangeName(self.rangeName());
                    return true;
                }

                self.overlapRangeName('');
                otherSegment.overlapRangeName('');
                return false;
            });
            return !doesSegmentOverlap;
        };

        const _subscribeToRangeObservables = () => {
            _disposables.push(self.isSundaySelected.subscribe(self.doAnySegmentsOverlap));
            _disposables.push(self.isMondaySelected.subscribe(self.doAnySegmentsOverlap));
            _disposables.push(self.isTuesdaySelected.subscribe(self.doAnySegmentsOverlap));
            _disposables.push(self.isWednesdaySelected.subscribe(self.doAnySegmentsOverlap));
            _disposables.push(self.isThursdaySelected.subscribe(self.doAnySegmentsOverlap));
            _disposables.push(self.isFridaySelected.subscribe(self.doAnySegmentsOverlap));
            _disposables.push(self.isSaturdaySelected.subscribe(self.doAnySegmentsOverlap));
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        const _initialize = () => {
            if (_originalSegment) {
                self.rangeName(_originalSegment.name);
                self.sequence(_originalSegment.sequence);

                self.isSundaySelected(_originalSegment.isForSunday);
                self.isMondaySelected(_originalSegment.isForMonday);
                self.isTuesdaySelected(_originalSegment.isForTuesday);
                self.isWednesdaySelected(_originalSegment.isForWednesday);
                self.isThursdaySelected(_originalSegment.isForThursday);
                self.isFridaySelected(_originalSegment.isForFriday);
                self.isSaturdaySelected(_originalSegment.isForSaturday);

                const startHourResult = convertMilitaryTimeToStandard(_originalSegment.startHour);
                const startTime = formatTimeValue(startHourResult.hour, _originalSegment.startMinute, startHourResult.meridiem);
                self.selectedStartTime(startTime);

                const endHourResult = convertMilitaryTimeToStandard(_originalSegment.endHour);
                const endTime = formatTimeValue(endHourResult.hour, _originalSegment.endMinute, endHourResult.meridiem);
                self.selectedEndTime(endTime);

                self.selectedRouteToOption(_originalSegment.routeToType);
                self.routeToViewModel(buildRouteToViewModel('routingRule', _originalSegment.routingRule));
            } else {
                self.isSundaySelected(false);
                self.isMondaySelected(false);
                self.isTuesdaySelected(false);
                self.isWednesdaySelected(false);
                self.isThursdaySelected(false);
                self.isFridaySelected(false);
                self.isSaturdaySelected(false);

                self.selectedStartTime(DEFAULT_START_TIME);
                self.selectedEndTime(DEFAULT_END_TIME);

                self.selectedRouteToOption('autoAttendant');
                self.routeToViewModel(buildRouteToViewModel(self.selectedRouteToOption(), {autoAttendantId : "[None]"}));
            }

            const ValidatorConstructor = require('presentation/settings/schedules/validators/scheduleRangeViewModelValidator');
            self.validator = new ValidatorConstructor();
            self.validator.registerViewModel(self, _parent.ranges);

            self.modelStateObserver = new ModelStateObserverConstructor(self, true, self.routeToViewModel().modelStateObserver);
            self.modelStateObserver.commitData();
            _subscribeToRangeObservables();
            return;
        };

        _initialize();
    };
});

