define('presentation/common/callFlow/facades/autoAttendantCallFlowFacade',['businessServices/converters/durationTimeFormatter',
    'externalDependencies/clientWebSocket',
    'presentation/common/callFlow/common/callFlowBuilder',
    'presentation/common/callFlow/presentationObjects/autoAttendantPresentationObject',
    'presentation/common/callFlow/presentationObjects/subMenuItemPresentationObject',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
], function() {

    const AutoAttendantPresentationObjectConstructor = require('presentation/common/callFlow/presentationObjects/autoAttendantPresentationObject');
    const SubMenuItemObjectConstructor = require('presentation/common/callFlow/presentationObjects/subMenuItemPresentationObject');
    const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
    const CallFlowBuilder = require('presentation/common/callFlow/common/callFlowBuilder');
    const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

    let _voicePromptAudioFactory = null;
    let _promiseFactory = null;

    const KEY_ORDER = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*'];

    const _getAutoAttendant = (autoAttendantId, availableVoicePrompts, availableSchedules, availableAutoAttendants) => {
        return _promiseFactory.defer((deferredObject) => {

            let autoAttendant = availableAutoAttendants.find(autoAttendant => {
               return autoAttendant.accountAutoAttendantId === autoAttendantId;
            });

            let exitConditions = autoAttendant.routingRule.routingRuleExitConditions;
            let subMenuItems = [];

            KEY_ORDER.forEach((key) => {
                let exitCondition = exitConditions.find(exitCondition => exitCondition.value === key);
                if (exitCondition) {
                    subMenuItems.push(exitCondition);
                }
            });

            let callFlowBuilder = new CallFlowBuilder();
            callFlowBuilder.init(_promiseFactory);
            callFlowBuilder.availableVoicePrompts = availableVoicePrompts;
            callFlowBuilder.availableSchedules = availableSchedules;

            let displayAutoAttendant = new AutoAttendantPresentationObjectConstructor();
            displayAutoAttendant.name = autoAttendant.name;
            displayAutoAttendant.accountAutoAttendantId = autoAttendant.accountAutoAttendantId;
            displayAutoAttendant.routeToVoicePromptId = autoAttendant.accountVoicePromptId;
            displayAutoAttendant.routeToSystemAudioClipId = autoAttendant.systemAudioClipId;

            callFlowBuilder.processCallFlowStep(autoAttendant, displayAutoAttendant)
                .done((voicePrompt) => {
                    displayAutoAttendant.voicePrompt = voicePrompt;
                });

            displayAutoAttendant.subMenuItems = subMenuItems.map(subMenuKey => {
                let displaySubMenuKey = new SubMenuItemObjectConstructor();
                let _routingRules = [];
                _routingRules.push(subMenuKey.nextRoutingRule);
                callFlowBuilder.buildRoutingRules(subMenuKey.nextRoutingRule, _routingRules);

                callFlowBuilder.formatCallFlowForDisplay(subMenuKey, _routingRules)
                    .done((callFlow) => {
                        displaySubMenuKey.routeSearchData(subMenuKey.name);
                        displaySubMenuKey.key = subMenuKey.value;
                        displaySubMenuKey.callFlow = callFlow;
                    });

                return displaySubMenuKey;
            });
            deferredObject.resolve(displayAutoAttendant);
        });
    };

    const _getCurrentVoicePrompt = (voicePromptId) => {
        return _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
    };

    const _getFormattedDurationProperty = (promptDurationInSeconds) => {
        let formatter = new TimeFormatterConstructor();
        let formattedTimeDuration = formatter.formatTotalSeconds(promptDurationInSeconds);
        return formattedTimeDuration;
    };

    const _onNewPromptConverted = (callback) => {

        promptSocket.subscribeToEvent("voicePromptMp3Converted", (eventData) => {
            const {accountVoicePromptId, durationInMilliseconds} = eventData;
            let updatedAudioData = _getCurrentVoicePrompt(accountVoicePromptId);
            let formattedTimeDuration = _getFormattedDurationProperty(durationInMilliseconds / 1000);
            updatedAudioData.duration = ko.observable(formattedTimeDuration);

            callback({
                accountVoicePromptId: accountVoicePromptId,
                audioData: updatedAudioData,
            });
        });
    };

    const _dispose = () => {
        promptSocket.disposeOfEvents();
    };
    

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();
    };

    return function() {
        let self = this;

        self.init = _init;
        self.getAutoAttendant = _getAutoAttendant;
        self.onNewPromptConverted = _onNewPromptConverted;
        self.dispose = _dispose;
    };
});

