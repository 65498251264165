define('presentation/shell/viewModels/interiorShellViewModel',[
    'businessServices/blocking/blockingStateSingleton',
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/events/eventManager',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'constants/shellNameEnumerations',
    'settings/navigationConfiguration',
    'businessServices/events/webSocketEventManager'
], function (
    /** @type import('businessServices/blocking/blockingStateSingleton') */
    _blockingState,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type import('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton') */
    _smsPhoneNumberOptOutsState
) {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfig = require('settings/navigationConfiguration');
        const _shellNameEnumerations = require('constants/shellNameEnumerations');
        const _webSocketEventManager = require('businessServices/events/webSocketEventManager');

        let _sidebarViewModelMap = {};

        const SETTINGS_SECTION = "settings";

        const _onShellUpdated = (shellName) => {
            if (shellName === _shellNameEnumerations.interior) {
                self.shellIsActive(true);
                if (self.router.activeItem() === null) {
                    self.contentPane(null);
                }
            } else {
                self.shellIsActive(false);
            }
        };

        const _determineSidebarDisplay = () => {
            let sidebarViewModel = self.router.activeInstruction().config.customSettings.sidebarViewModel;
            if (sidebarViewModel === undefined || sidebarViewModel === null) {
                self.isSidebarShown(false);
                self.sidebarPane(null);
            } else {
                self.isSidebarShown(true);
                let sidebarViewModelObj;
                if (_sidebarViewModelMap[sidebarViewModel] === undefined) {
                    let SidebarViewModelConstructor = require(sidebarViewModel);

                    if (typeof(SidebarViewModelConstructor) === "function") {
                        sidebarViewModelObj = new SidebarViewModelConstructor();
                    } else {
                        sidebarViewModelObj = SidebarViewModelConstructor;
                    }
                    sidebarViewModelObj.activate();

                    _sidebarViewModelMap[sidebarViewModel] = sidebarViewModelObj;
                } else {
                    sidebarViewModelObj = _sidebarViewModelMap[sidebarViewModel];
                }

                if ((sidebarViewModelObj.parentViewModel) && (ko.isObservable(sidebarViewModelObj.parentViewModel))) {
                    sidebarViewModelObj.parentViewModel(self);
                } else {
                    sidebarViewModelObj.parentViewModel = self;
                }

                let activeSection = self.router.activeInstruction().config.customSettings.section;
                if (activeSection === SETTINGS_SECTION){
                   self.isSidebarFixed(true);
                } else {
                    self.isSidebarFixed(false);
                }

                self.sidebarPane(sidebarViewModelObj);
            }
        };

        const _setCssRootClass = () => {
            let cssRootClass = self.router.activeInstruction().config.customSettings.cssRootClass;
            if (cssRootClass === undefined || cssRootClass === null) {
                self.cssRootClass("");
            } else {
                self.cssRootClass(cssRootClass);
            }
        };

        const _determineSnackbarDisplay = () => {
            self.isBillingAdmin(_sessionAccountInfo.isBillingAdmin());
            self.isSystemAdmin(_sessionAccountInfo.isSystemAdmin());
        };

        const _onActiveItemChange = (activeItem) => {
            _setCssRootClass();

            if ((activeItem.parentViewModel) && (ko.isObservable(activeItem.parentViewModel))) {
                activeItem.parentViewModel(self);
            } else {
                activeItem.parentViewModel = self;
            }

            self.contentPane(activeItem);
            _determineSidebarDisplay();
            _determineSnackbarDisplay();
        };

        self.router = null;
        self.sidebarPane = ko.observable(null);
        self.contentPane = ko.observable(null);
        self.homepageURL = "";
        self.isSidebarShown = ko.observable(false);
        self.isSidebarFixed = ko.observable(false);
        self.shellIsActive = ko.observable(true);
        self.cssRootClass = ko.observable("");
        self.isInFreeTrial = _sessionAccountInfo.isInFreeTrial();
        self.trialPeriodStart = _sessionAccountInfo.trialPeriodStart();
        self.isBillingAdmin = ko.observable(false);
        self.isSystemAdmin = ko.observable(false);


        self.showFreeTrialSnackbar = ko.pureComputed(() => {
            return (self.router.activeInstruction().config.customSettings.routeId === 'messages') &&
                self.isInFreeTrial() &&
                _sessionAccountInfo.isLoggedIn();
        });

        self.showSnackbar = ko.pureComputed(() => {
            return (self.router.activeInstruction().config.customSettings.routeId !== 'accountProfile') &&
                (self.isInFreeTrial() === false) &&
                (self.isSystemAdmin() || self.isBillingAdmin()) &&
                _sessionAccountInfo.isLoggedIn();
        });

        self.activate = () => {
            self.router = require('businessServices/router/router');
            _webSocketEventManager.init();
            _webSocketEventManager.subscribeFreeTrialConverted(() => {
                _sessionAccountInfo.setFreeTrialComplete();
                self.isInFreeTrial(false);
            });

            return _initialize();
        };

        const _initialize = () => {
            _determineSidebarDisplay();
            _determineSnackbarDisplay();
            _setCssRootClass();

            _blockingState.initialize();
            _smsPhoneNumberOptOutsState.initialize();

            self.homepageURL = _navigationConfig.homePageUrl;
            let activeItem = self.router.activeItem();

            if ((activeItem.parentViewModel) && (ko.isObservable(activeItem.parentViewModel))) {
                activeItem.parentViewModel(self);
            } else {
                activeItem.parentViewModel = self;
            }

            self.contentPane(activeItem);
            _eventManager.subscribeShellUpdated(_onShellUpdated);
            self.router.activeItem.subscribe(_onActiveItemChange);

            return _promiseFactory.wait();
        };
    };
});

