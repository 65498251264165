define('presentation/settings/phoneNumbers/viewModels/editPhoneNumberOutboundDialTabViewModel',[
    'common/promises/promiseFactory',
    'constants/permissionInheritanceConstants'
],
    function () {
        return function () {
            let self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const _inheritanceConstants = require('constants/permissionInheritanceConstants');
            const _i18n = require('i18next');

            let _disposables = [];

            const _updateInheritanceObservable = () => {
                self.isOutboundCallingPermissionInherited(self.isOutboundCallingPermissionInheritedSelectedOption() === _inheritanceConstants.enabled);
            };

            self.inheritanceOptions = ko.observableArray([
                { id: _inheritanceConstants.enabled, text: _i18n.t('editPhoneNumberOutboundDialTab:inheritPermissionEnabled') },
                { id: _inheritanceConstants.disabled, text: _i18n.t('editPhoneNumberOutboundDialTab:inheritPermissionDisabled') }
            ]);

            self.isOutboundCallingEnabled = ko.observable(false);
            self.isOutboundCallingPermissionInherited = ko.observable(false);
            self.isOutboundCallingPermissionInheritedSelectedOption = ko.observable();

            self.toggleMessageKey = ko.pureComputed(() =>  self.isOutboundCallingEnabled() ? 'editPhoneNumber:enabled' : 'editPhoneNumber:disabled' );

            self.dispose = () => {
                _disposables.forEach((disposable) => {
                    disposable.dispose();
                });

                _disposables = [];
            };

            self.activate = (isOutboundCallingEnabled, isOutboundCallingPermissionInherited, authorizedDialSubscriberViewModel) => {
                self.authorizedDialSubscriberViewModel = authorizedDialSubscriberViewModel;
                self.authorizedDialSubscriberViewModel.subscriberSelectionViewModel().membershipDescription(_i18n.t('editPhoneNumberOutboundDialTab:manualDescription'));
                self.authorizedDialSubscriberViewModel.yellowBoxInheritedAction(_i18n.t('editPhoneNumberOutboundDialTab:action'));
                self.authorizedDialSubscriberViewModel.yellowBoxAlternateInheritedAction(_i18n.t('editPhoneNumberOutboundDialTab:actionAlternate'));
                self.authorizedDialSubscriberViewModel.yellowBoxManualOption(_i18n.t('editPhoneNumberOutboundDialTab:inheritPermissionDisabled'));
                self.authorizedDialSubscriberViewModel.yellowBoxGrantedPermission(_i18n.t('editPhoneNumberOutboundDialTab:grantPermissions'));
                self.authorizedDialSubscriberViewModel.inheritedSubscriptionsDescription(_i18n.t('editPhoneNumberOutboundDialTab:inheritedDescription'));

                return _initialize(isOutboundCallingEnabled, isOutboundCallingPermissionInherited);
            };

            const _initialize = (isOutboundCallingEnabled, isOutboundCallingPermissionInherited) => {
                return _promiseFactory.defer((initPromise) => {
                    self.isOutboundCallingEnabled = isOutboundCallingEnabled;
                    self.isOutboundCallingPermissionInherited = isOutboundCallingPermissionInherited;
                    self.isOutboundCallingPermissionInheritedSelectedOption = ko.observable(
                        self.isOutboundCallingPermissionInherited() ? _inheritanceConstants.enabled : _inheritanceConstants.disabled
                    );

                    _disposables.push(self.isOutboundCallingPermissionInheritedSelectedOption.subscribe(_updateInheritanceObservable));
                    initPromise.resolve();
                });
            };
        };
    });
