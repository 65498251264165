define('presentation/settings/phoneNumbers/presentationObjects/recordingNotificationPresentationObject',[],function() {
    return function(){
        let self = this;

        self.recordingNotificationId = null;
        self.notificationName = ko.observable();
        self.isCurrent = ko.observable(false);
        self.isBuiltIn = ko.observable(false);
        self.isNew = ko.observable(false);
        self.notificationAudioObject = ko.observable();
        self.voicePromptDuration = ko.observable(null);
        self.conversionComplete = false;
        self.conversionInProgress = false;
        self.previewPrompt = null;
        self.voicePrompt = null;
        self.displayPrompt = ko.observable(false);
    };
});

