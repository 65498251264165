define('persistence/repositories/authorizedContactsRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(authorizedContactEntity) {
            if (authorizedContactEntity.authorizedContactId !== null) {
                var err = new Error("authorizedContactId should be null");
                err.lobbyAuthorizedContact = authorizedContactEntity;
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var authorizedContactDocument = {
                        data : authorizedContactEntity
                    };

                    _kazooApi.callAccount('/lobby_authorized_contacts', 'PUT', authorizedContactDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.authorizedContactEntity = authorizedContactEntity;
                            deferredObject.reject(error);
                        });
                });
            }

        };
        self.update = function(authorizedContactEntity) {
            if (authorizedContactEntity.authorizedContactId === null) {
                var err = new Error("Missing authorizedContactId");
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var authorizedContactDocument = {
                        data : authorizedContactEntity
                    };
                    _kazooApi.callAccount('/lobby_authorized_contacts/'+authorizedContactEntity.authorizedContactId, 'POST', authorizedContactDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.authorizedContactEntity = authorizedContactEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.remove = function(authorizedContactId) {
            if (authorizedContactId === null) {
                var err = new Error("Missing authorizedContactId");
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_authorized_contacts/'+authorizedContactId,'DELETE')
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.authorizedContactId = authorizedContactId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
        self.merge = function(authorizedContacts) {
            return _promiseFactory.defer(function(deferredObject) {
                var mergeRequestDocument = {
                    data : authorizedContacts
                };
                _kazooApi.callAccount('/lobby_authorized_contacts/merge','POST', mergeRequestDocument)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

