define('persistence/dataProviders/callHistoryDataProvider',['persistence/webSocket/webSocketApp'], function() {
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _webSocketApp = null;

        self.open = function(rowsPerPage, initialPageCount) {
            if (rowsPerPage === null || rowsPerPage === undefined || initialPageCount === null || initialPageCount === undefined) {
                var err = new Error("Open Call Recordings, paramaters failed");

                err.rowsPerPage = rowsPerPage;
                err.initialPageCount = initialPageCount;

                throw err;

            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    var webSocketParams = {
                        "rows_per_page": rowsPerPage,
                        "initial_page_count": initialPageCount
                    };

                    _webSocketApp.send("open", webSocketParams, function(result) {
                        deferredObject.resolve(result);
                    });
                });
            }
        };

        self.filter = function(filterParameters) {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("filter", filterParameters, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.next = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("next", null, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.close = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("close", null, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("call_history");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});
