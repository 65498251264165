define('presentation/common/loadingBar/viewModels/loadingBarViewModel',[], function() {
    /** @typedef {import('presentation/common/loadingBar/viewModels/loadingBarViewModel')} LoadingBarViewModel */

    return function () {
        /** @type {LoadingBarViewModel} */
        const self = this;

        /** @type {IDisposable[]} */
        let _disposables = [];

        const MIN_VALUE = 0;
        const MAX_VALUE = 100;
        const DEFAULT_HEIGHT = 8;

        self.progressValue = ko.observable(MIN_VALUE);
        self.height = ko.observable("");
        self.progressMaxValue = ko.observable(MAX_VALUE);

        let _inputProgressValue = ko.observable(null);

        const _updateProgressValue = (/** @type {number | null}*/ progressValue) => {
            const boundedProgressValue = Math.min(Math.max(progressValue, MIN_VALUE), MAX_VALUE);
            self.progressValue(boundedProgressValue);
        };

        /** @type {LoadingBarViewModel["activate"]} */
        self.activate = (activationData) => {
            const { progressValue, height } = activationData;

            self.height(`${height || DEFAULT_HEIGHT}px`);

            _inputProgressValue = progressValue;

            _disposables.push(
                _inputProgressValue.subscribe(_updateProgressValue)
            );

            _initialize();
        };

        const _initialize = () => {

        };

    };
});
