define('presentation/forgotPassword/facades/forgotPasswordFacade',['entities/emailEntity',
    'businessServices/login/forgotPasswordManager',
    'persistence/webSocket/webSocketApp',
    'persistence/dataProviders/customerServiceDataProvider'
], function () {
    let _promiseFactory = null;
    let _forgotPasswordManager = null;
    let _webSocketApp = null;
    let _customerServiceInfoDataProvider = null;

    const EmailEntityConstructor = require('entities/emailEntity');

    const _sendPasswordResetEmail = (userEmail) => {
        return _promiseFactory.defer((deferredObject) => {
            let emailEntity = new EmailEntityConstructor();
            emailEntity.to.push(userEmail);

            _forgotPasswordManager.forgotPassword(emailEntity)
                .fail(deferredObject.reject)
                .done((result) => {
                    if (result.data.data.status === "success") {
                        deferredObject.resolve("success");
                    } else {
                        deferredObject.resolve(result.data.data.reason);
                    }
                });
        });
    };

    const _getEmailStatus = (emailAddress) => {
        return _promiseFactory.defer((promise) => {
            if (emailAddress.trim() === "") {
                promise.resolve("");
            } else {
                const webSocketParameters = {emailAddress: emailAddress};
                _webSocketApp.send("get_email_address_status", webSocketParameters, (result) => {
                    promise.resolve(result);
                });
            }
        });
    };

    const _customerServiceSupportPhone = () => {
        return _customerServiceInfoDataProvider.getSupportPhone();
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        _forgotPasswordManager = require('businessServices/login/forgotPasswordManager');

        const WebSocketValidationConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketValidationConstructor();
        _webSocketApp.init("validation");

        const CustomerServiceInfoDataProviderConstructor = require('persistence/dataProviders/customerServiceDataProvider');
        _customerServiceInfoDataProvider = new CustomerServiceInfoDataProviderConstructor();
    };

    return function () {
        const self = this;

        self.init = _init;
        self.customerServiceSupportPhone = _customerServiceSupportPhone;
        self.getEmailStatus = _getEmailStatus;
        self.sendPasswordResetEmail = _sendPasswordResetEmail;
    };
});

