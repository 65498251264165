define('constants/contactPhoneNumberLabelEnumerations',[], function () {
    return  {
        Mobile: 1,
        Home: 2,
        Work: 3,
        School: 4,
        IPhone: 5,
        AppleWatch: 6,
        Main: 7,
        HomeFax: 8,
        WorkFax: 9,
        Pager: 10,
        Other: 11
    };
});

