define('presentation/settings/userDevices/validators/userDevicesValidatorUtility',[
        'common/promises/promiseFactory',
        'constants/validationMessageEnumerations',
        'persistence/webSocket/webSocketApp'
    ],
    function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        let _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("validation");

        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        const _isDeviceNameUnique = (deviceName, deviceId, userId) => {
            return _promiseFactory.defer((promise) => {
                if (deviceName === "") {
                    promise.resolve(true);

                } else {
                    const parameters = {
                        deviceId: deviceId,
                        deviceName: deviceName,
                        userId: userId
                    };
                    _webSocketApp.send("is_device_name_unique", parameters, (result) => {
                        promise.resolve(result.status);
                    });
                }
            });
        };

        const _isDeviceNameUniqueInList = (deviceName, devices, userId) => {
            return _promiseFactory.defer((promise) => {
                const deviceNameUpper = deviceName.trim().toUpperCase();
                if (deviceName === "") {
                    promise.resolve(true);

                } else {
                    let matchedDevices = devices.filter((device) => {
                        return device.deviceName().trim().toUpperCase() === deviceNameUpper;
                    });
                    if (matchedDevices.length !== 1) {
                        promise.resolve(false);
                    } else {
                        _isDeviceNameUnique(deviceName, matchedDevices[0].deviceId(), userId)
                            .done((isUnique) => {
                                promise.resolve(isUnique);
                            });
                    }
                }
            });
        };

        const _isDevicePhoneNumberUnique = (devicePhoneNumber, deviceId, userId) => {
            return _promiseFactory.defer((promise) => {
                if (devicePhoneNumber === "") {
                    promise.resolve(true);
                } else {
                    const parameters = {
                        deviceId: deviceId,
                        devicePhoneNumber: devicePhoneNumber,
                        userId: userId
                    };
                    _webSocketApp.send("is_device_phone_number_unique", parameters, (result) => {
                        promise.resolve(result.status);
                    });
                }
            });
        };

        const _isDevicePhoneNumberUniqueToUser = (devicePhoneNumber, userId) => {
            return _promiseFactory.defer((promise) => {
                if (devicePhoneNumber === "") {
                    promise.resolve(true);
                } else {
                    const parameters = {
                        devicePhoneNumber: devicePhoneNumber,
                        userId: userId
                    };
                    _webSocketApp.send("is_device_phone_number_unique_to_user", parameters, (result) => {
                        promise.resolve(result.status);
                    });
                }
            });
        };

        const _isDevicePhoneNumberUniqueInList = (devicePhoneNumber, devices, userId) => {
            return _promiseFactory.defer((promise) => {
                if (devicePhoneNumber === "") {
                    promise.resolve(true);

                } else {
                    let matchedDevices = devices.filter((device) => {
                        return device.devicePhoneNumber() === devicePhoneNumber;
                    });
                    if (matchedDevices.length !== 1) {
                        promise.resolve(false);
                    } else {
                        _isDevicePhoneNumberUnique(devicePhoneNumber, matchedDevices[0].deviceId(), userId)
                            .done((isUnique) => {
                                promise.resolve(isUnique);
                            });
                    }
                }
            });
        };

        const _returnIntegrityWarnings = (integrityStatus) => {
            const validationMessageBase = _validationMessageEnumerations.clientui.presentation.settings.userDevices.integrityWarnings;
            switch (integrityStatus) {
                case "duplicate_phone_number":
                case "last_device":
                case "maximum_devices":
                case "restricted_number":
                    return validationMessageBase[integrityStatus];
                default:
                    const error = new Error("unknown status");
                    error.statusValue = integrityStatus;
                    throw error;
            }
        };

        return function() {
            const self = this;

            self.isDeviceNameUnique = _isDeviceNameUnique;
            self.isDeviceNameUniqueInList = _isDeviceNameUniqueInList;
            self.isDevicePhoneNumberUnique = _isDevicePhoneNumberUnique;
            self.isDevicePhoneNumberUniqueInList = _isDevicePhoneNumberUniqueInList;
            self.isDevicePhoneNumberUniqueToUser = _isDevicePhoneNumberUniqueToUser;
            self.returnIntegrityWarnings = _returnIntegrityWarnings;
        };
    });
