define('businessServices/pathProviders/userAvatarUrlPathProvider',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp'
], function() {
    var FactorySingleton = function() {
        if (FactorySingleton.prototype._singletonInstance) {
            return FactorySingleton.prototype._singletonInstance;
        }
        var CACHE_EXPIRATION_IN_MS = 60; // may need to change to 157680000000 = 5 years
        var CACHE_OBJECT_STATUS_READING = "reading";
        var CACHE_OBJECT_STATUS_COMPLETE = "complete";
        var self = this;

        var _promiseFactory = null;
        var _webSocketApp = null;
        var _userUrlCache = {};
        var _userGroupUrlCache = {};

        var _urlHasExpired = function(cacheObject) {
            var hasExpired;
            var objectAge = Date.now() - cacheObject.createTimestamp;
            if (objectAge > CACHE_EXPIRATION_IN_MS) {
                hasExpired = true;
            } else {
                hasExpired = false;
            }
            return hasExpired;
        };

        self.getAvatar = function(userId, ignoreCache) {
            if (arguments.length < 2) {
                ignoreCache = false;
            }
            return _promiseFactory.deferIndefinitely(function(promise) {
                if ((_userUrlCache[userId] === undefined) ||
                        (_urlHasExpired(_userUrlCache[userId])) ||
                        (ignoreCache === true)) {
                    var cacheObject = {
                        avatar: null,
                        status: CACHE_OBJECT_STATUS_READING,
                        waitingPromises: [],
                        createTimestamp: Date.now()
                    };
                    _userUrlCache[userId] = cacheObject;
                    var params = {
                        userId: userId
                    };
                    _webSocketApp.send("get_signed_url_for_user", params, function(result) {
                        _userUrlCache[userId].avatar = result.avatar;
                        _userUrlCache[userId].status = CACHE_OBJECT_STATUS_COMPLETE;
                        _userUrlCache[userId].waitingPromises.forEach(function(waitingPromise) {
                            waitingPromise.resolve(result.avatar);
                        });
                        promise.resolve(result.avatar);
                    });
                } else {
                    if (_userUrlCache[userId].status === CACHE_OBJECT_STATUS_READING) {
                        _userUrlCache[userId].waitingPromises.push(promise);
                    } else {
                        promise.resolve(_userUrlCache[userId].avatar);
                    }
                }
            });
        };

        self.getAvatarsByUserGroupId = function (userGroupId) {
            return _promiseFactory.defer(function(promise) {
                if ((_userGroupUrlCache[userGroupId] === undefined) ||
                        (_urlHasExpired(_userGroupUrlCache[userGroupId]))) {
                    var cacheObject = {
                        avatars: null,
                        status: CACHE_OBJECT_STATUS_READING,
                        waitingPromises: [],
                        createTimestamp: Date.now()
                    };
                    _userGroupUrlCache[userGroupId] = cacheObject;

                    var params = {
                        userGroupId: userGroupId
                    };
                    _webSocketApp.send("get_signed_url_for_users_in_user_group", params, function(result) {
                        _userGroupUrlCache[userGroupId].avatars = result.avatars;
                        _userGroupUrlCache[userGroupId].status = CACHE_OBJECT_STATUS_COMPLETE;
                        _userGroupUrlCache[userGroupId].waitingPromises.forEach(function(waitingPromise) {
                            waitingPromise.resolve(result.avatars);
                        });
                        promise.resolve(result.avatars);
                    });

                } else {
                    if (_userGroupUrlCache[userGroupId].status === CACHE_OBJECT_STATUS_READING) {
                        _userGroupUrlCache[userGroupId].waitingPromises.push(promise);
                    } else {
                        promise.resolve(_userGroupUrlCache[userGroupId].avatars);
                    }
                }
            });
        };
        
        self.expireUserGroupAvatars = function (userGroupId) {
            delete _userGroupUrlCache[userGroupId];
        };

        self.getThumbnailsForUsers = function(userIds) {
            return _promiseFactory.defer(function(promise) {
                var params = {
                    userIds: userIds
                };

                _webSocketApp.send("get_signed_urls_for_users", params, function(result) {
                    promise.resolve(result.avatars);
                });
            });
        };

        self.init = function() {
            if (_promiseFactory === null) {
                var PromiseFactoryConstructor = require('common/promises/promiseFactory');
                _promiseFactory = new PromiseFactoryConstructor();
            }

            if (_webSocketApp === null) {
                var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
                _webSocketApp = new WebSocketAppConstructor();
                _webSocketApp.init("avatar_socket");
            }
        };
    };

    return new FactorySingleton();
});

