define('constants/callDistributionTypeConstants',[], function() {
   return function() {

    var self = this;
    self.callDistributionMethodOptions = [
                                            {name : "Simultaneous Ringing - First to Answer", value :"simultaneous"}
                                         ];
   
   };
});
