define('common/converters/extensionsFormatter',[],
    function() {
        const _getNextAvailableExtension = (startAt, takenExtensions, pendingExtensions) => {
            let activeExtensions = {};
            takenExtensions.forEach(e => activeExtensions[e] = true);

            if (pendingExtensions) {
                Object.values(pendingExtensions).forEach(e => activeExtensions[e] = true);
            }

            let nextAvailableExtension = startAt;
            while (activeExtensions[nextAvailableExtension]) {
                nextAvailableExtension++;
            }

            if (nextAvailableExtension > 9999) {
                return '0';
            }
            return nextAvailableExtension.toString();
        };

        return {
            getNextAvailableExtension: _getNextAvailableExtension,
        };
    });
