define('presentation/common/routeToModal/presentationObjects/routeToSelectedPresentationObject',[],function() {
    return function(){
        var self = this;

        self.routeToType = null;
        self.routeToUserId = null;
        self.routeToUserGroupId = null;
        self.routeToForwardingNumberId = null;
        self.routeToType = null;
        self.autoAttendantVoiceRouteName = null;
        self.routeToScheduleId = null;
    };
});

