define('common/specifications/validEmailAddressSpecification',[], function() {
    return function() {
        const self = this;

        const EMAIL_ADDRESS_REGULAR_EXPRESSION = new RegExp(/^[\w\-.+]+@[\w\-.]+\.[\w\-.]+$/i);

        self.isSatisfiedBy = (emailAddress) => {
            if (emailAddress) {
                const isValid = EMAIL_ADDRESS_REGULAR_EXPRESSION.test(emailAddress);
                return isValid;
            }

            return false;
        };

        self.isTrestaEmail = (emailAddress) => {
            if (emailAddress) {
                const emailDomain = emailAddress.split("@")[1].trim().toLowerCase();
                const isTrestaEmail = emailDomain === "patlive.com" || emailDomain === "tresta.com";
                return isTrestaEmail;
            }
            return false;
        };
    };
});

