define('presentation/common/userAvatar/userAvatarImageFactory',[
    'presentation/common/croppableImage/presentationObjects/avatar',
    'persistence/fileStores/userAvatarFileStore',
    'common/promises/promiseFactory',
    'businessServices/pathProviders/userAvatarUrlPathProvider'
], function () {
    return function () {
        var self = this;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var userAvatarPathProvider = null;
        var AvatarPresentationObjectConstructor = require('presentation/common/croppableImage/presentationObjects/avatar');

        const _commonState = require('common/storage/commonState');

        var _findImageForUserId = function(userId, ignoreCache) {
            userAvatarPathProvider.init();

            return _promiseFactory.deferIndefinitely(function(promise) {
                userAvatarPathProvider.getAvatar(userId, ignoreCache)
                    .done(function(avatar) {
                        var avatarPresentationObject = _mapToAvatarPresentationObject(avatar);
                        promise.resolve(avatarPresentationObject);
                    })
                    .fail(function(error) {
                        error.userId = userId;
                        promise.reject(error);
                    });
            });
        };

        var _findImagesForUserIds = function(userIds) {
            return _promiseFactory.deferIndefinitely(function(promise) {
                userAvatarPathProvider.init();
                userAvatarPathProvider.getThumbnailsForUsers(userIds)
                    .done(function(users) {
                        var usersImageData = users.map(function(currentUserAvatars) {
                            var avatarPresentationObject = _mapToAvatarPresentationObject(currentUserAvatars);
                            return avatarPresentationObject;
                        });
                        promise.resolve(usersImageData);
                    })
                    .fail(function(error) {
                        error.userIds = userIds;
                        promise.reject(error);
                    });
            });
        };

        const _mapIdToAvatarPresentationObject = (id) => {
            var avatarPresentationObject = new AvatarPresentationObjectConstructor();
            avatarPresentationObject.userId = id;
            avatarPresentationObject.avatarUrl = _commonState.get(id).avatarUrl;
            avatarPresentationObject.status = _commonState.get(id).status;
            return avatarPresentationObject;
        };

        var _mapToAvatarPresentationObject = function(avatar) {
            var avatarPresentationObject = new AvatarPresentationObjectConstructor();
            avatarPresentationObject.userId = avatar.userId;
            if (avatar.hasAvatar === true) {
                avatarPresentationObject.avatarUrl(avatar.url);
                avatarPresentationObject.avatarFileHash(avatar.avatarFileHash);
            } else {
                avatarPresentationObject.avatarUrl("");
                avatarPresentationObject.avatarFileHash("");
            }
            return avatarPresentationObject;
        };

        self.getAvatarsByUserIds = function(userIds) {
            return _findImagesForUserIds(userIds);
        };

        self.getAvatarByUserId = function (userId, ignoreCache) {
            return _findImageForUserId(userId, ignoreCache);
        };

        self.getAvatarByUser = function(user) {
            var userId = user.userId;
            return _findImageForUserId(userId);
        };

        self.getAvatarById = (id) => _mapIdToAvatarPresentationObject(id);

        self.init = function () {
            userAvatarPathProvider = require('businessServices/pathProviders/userAvatarUrlPathProvider');
        };

        self.init2 = function(userAvatarPathProvider) {
            userAvatarPathProvider = userAvatarPathProvider;
        };
    };
});

