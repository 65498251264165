define('presentation/common/routeToModal/presentationObjects/routeToOptionPresentationObject',[],function() {
    return function(){
        var self = this;

        self.value = null;
        self.title = null;
        self.icon = null;
    };
});

