define('presentation/settings/userGroup/facades/reactivateUserGroupFacade',['constants/userGroupConstants',
        'persistence/webSocket/webSocketApp',
        'presentation/settings/userGroup/presentationObjects/userGroupPresentationObject',
        'presentation/common/userAvatar/userAvatarImageFactory',
], function () {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const UserGroupPresentationObjectConstructor = require('presentation/settings/userGroup/presentationObjects/userGroupPresentationObject');

    const _userGroupConstants = require('constants/userGroupConstants');

    let _promiseFactory = null;
    let _userAvatarImageFactory = null;
    let _webSocketApp = null;
    let _webSocketValidator = null;

    const _buildMembers = (userGroupId, userId, userGroupMembers) => {
        return _promiseFactory.defer((promise) => {
            const buildMemberPromiseFactory = new PromiseFactoryConstructor();
            userGroupMembers.forEach((member) => {
                buildMemberPromiseFactory.defer((avatarDeferredObject) => {
                    _userAvatarImageFactory.getAvatarByUserId(member.subscriberId)
                        .fail(promise.reject)
                        .done((userAvatar) => {
                            member.avatar = userAvatar;
                            member.readOnly = false;
                            avatarDeferredObject.resolve();
                        });
                });
            });
            buildMemberPromiseFactory.wait()
                .fail(promise.reject)
                .done(() => {
                    promise.resolve(userGroupMembers);
                });
        });
    };

    const _getUserGroup = (userGroupId, userId) => {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("get_user_group", {userGroupId: userGroupId}, (userGroup) => {
                const userGroupPresentationObject = new UserGroupPresentationObjectConstructor();
                userGroupPresentationObject.userGroupId = userGroup.groupId;
                userGroupPresentationObject.groupName = userGroup.groupName;
                userGroupPresentationObject.extension = userGroup.extension;
                userGroupPresentationObject.members = userGroup.members;
                _buildMembers(userGroupId, userId, userGroup.availableMembers)
                    .fail(promise.reject)
                    .done((userGroupMembers) => {
                        userGroupPresentationObject.availableMembers = userGroupMembers;
                        if (userGroup.groupId === _userGroupConstants.allUserGroupId || userGroup.groupId === _userGroupConstants.billingAdminsGroupId || userGroup.groupId === _userGroupConstants.systemAdminsGroupId) {
                            userGroupPresentationObject.isGroupNameEnabled = false;
                        }
                        if (userGroup.groupId === _userGroupConstants.allUserGroupId) {
                            userGroupPresentationObject.isMembershipSelectionEnabled = true;
                        }

                        promise.resolve(userGroupPresentationObject);
                    });
            });
        });
    };

    const _reactivateUserGroup = (groupId) => {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("reactivate_user_group", {groupId: groupId}, (result) => {
                promise.resolve(result);
            });
        });
    };

    const _getAvailableUserGroupMembers = (userId) => {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("get_available_members", null, (availableMembers) => {
                _buildMembers(null, userId, availableMembers)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        });
    };

    const _isUserGroupNameUnique = (userGroupId, userGroupName) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                groupId: userGroupId,
                groupName: userGroupName
            };
            _webSocketApp.send("is_user_group_name_unique", request, (result) => {
                promise.resolve(result.isUserGroupNameUnique);
            });
        });
    };

    const _isExtensionUnique = (userGroupId, extension) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                documentId: userGroupId,
                extension: extension,
                type: "lobby_user_group"
            };
            _webSocketValidator.send("is_extension_unique", request, (result) => {
                promise.resolve(result);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_groups_reactivate");

        _webSocketValidator = new WebSocketAppConstructor();
        _webSocketValidator.init("validation");

        const UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
        _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
        _userAvatarImageFactory.init();
    };

    return function () {
        const self = this;

        self.init = _init;
        self.getUserGroup = _getUserGroup;
        self.getAvailableUserGroupMembers = _getAvailableUserGroupMembers;
        self.reactivateUserGroup = _reactivateUserGroup;
        self.isUserGroupNameUnique = _isUserGroupNameUnique;
        self.isExtensionUnique = _isExtensionUnique;
    };
});
