define('presentation/acceptUserInvite/validators/createUserSecurityValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'constants/validationMessageEnumerations',
    'common/specifications/validPasswordSpecification'
],
function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const ValidPasswordSpecificationConstructor = require('common/specifications/validPasswordSpecification');
        const _validPasswordSpecification = new ValidPasswordSpecificationConstructor();

        let _facade = null;
        let _viewModel = null;
        let _commonValidator = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("password")
                .addValidationI18n(_commonValidator.isStringWithValue, 'acceptUserInvite:passwordRequired')
                .addValidationI18n(_validPasswordSpecification.isSatisfiedBy, 'acceptUserInvite:passwordInvalid');
            validationRules.field("confirmPassword")
                .addValidationI18n(_commonValidator.isStringWithValue, 'acceptUserInvite:confirmPasswordRequired')
                .addValidationI18n(_passwordsMatch, 'passwordDoesNotMatch');
            validationRules.field("verificationAnswer")
                .addValidationKeyUp(_commonValidator.isStringWithValue)
                .addValidationI18n(_commonValidator.isStringWithValue, 'verificationAnswerRequired');

            _commonValidator.setValidationRules(validationRules);
        };

        self.validatePasswordsMatch = () => {
            return _passwordsMatch();
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _passwordsMatch = () => {
            const password = _viewModel.password();
            const confirmPassword = _viewModel.confirmPassword();

            if (_commonValidator.isStringWithValue(confirmPassword)) {
                return password === confirmPassword;
            }

            return true;
        };
    };
});
