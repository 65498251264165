define('presentation/calls/viewModels/callsSidebarViewModel',[
    'common/promises/promiseFactory',
], function () {
    return function () {
        const self = this;
        let _promiseFactory = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');

        self.callerAniDisplay = "Aaron Chevy";
        self.durationPlaceholder = "3m 7s";

        self.recentCalls = [
            {
                callerAni: "Aaron Chevy",
                duration: "Mobile",
                hasAvatar: true,
                avatarSource: '/assets/img/user-placeholder-4.png',
                hasCallDirection: false,
                isActive: false,
            },
            {
                callerAni: "Jaqueline Barretti",
                duration: "Mobile",
                hasAvatar: true,
                avatarSource: '/assets/img/user-placeholder-5.png',
                hasCallDirection: false,
                isActive: true,
            },
            {
                callerAni: "Sammy Henderson",
                duration: "Work",
                hasAvatar: true,
                avatarSource: '/assets/img/user-placeholder-6.png',
                hasCallDirection: true,
                isActive: false,
            },
            {
                callerAni: "Abi Baker",
                duration: "Home",
                hasAvatar: true,
                avatarSource: '/assets/img/user-placeholder-7.png',
                hasCallDirection: false,
                isActive: false,
            },
            {
                callerAni: "Amanda Stuart",
                duration: "Home",
                hasAvatar: true,
                avatarSource: '/assets/img/user-placeholder-8.png',
                hasCallDirection: true,
                isActive: false,
            },
            {
                callerAni: "+1 (850) 246-7842",
                duration: "Tallahassee, FL",
                hasAvatar: false,
                hasCallDirection: false,
                isActive: true,
            },
        ];

        self.activate = () => {
            _promiseFactory = new PromiseFactoryConstructor();

            return _initialize();
        };

        const _initialize = () => {


        };
    };
});
