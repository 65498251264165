define('presentation/loginNotifications/presentationObjects/declinedInformationPresentationObject',[],function() {
    return function(){
        var self = this;

        self.declinedDate = '';
        self.declinedAmount = '';
        self.pastDueBalance = '';
        self.paymentMethodId = '';
        self.autoCloseDate = '';
    };
});
