define('presentation/settings/userGroup/facades/deactivateUserGroupModalFacade',['common/storage/commonState',
        'externalDependencies/clientApi',
        'persistence/webSocket/webSocketApp',
        'presentation/common/voicePrompt/voicePromptAudioFactory'
], function () {
    const _commonState = require('common/storage/commonState');
    const _deactivateUserGroupSocket = require('externalDependencies/clientWebSocket').forApplication("deactivateUserGroup");

    let _clientApi = null;
    let _promiseFactory = null;
    let _webSocketApp = null;
    let _voicePromptAudioFactory = null;

    const GLOBAL_DEACTIVATED_PROMPT = "global_deactivated_user_prompt";

    const _getAboutThisUserGroup = (userGroupId) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _deactivateUserGroupSocket.send("getAboutThisUserGroup", {userGroupId: userGroupId}, (result, err) => {
                if (err) {
                    deferredObject.reject(new Error(err));
                    return;
                }
                
                let response = {};
                response.callsRoutedToUserGroup = result.callsRoutedToUserGroup;
                response.voicemailBoxes = result.onlyUserGroupSubscribedToVoicemailBoxes;
                response.callRecordingBoxes = result.onlyUserGroupSubscribedToCallRecordings;
                response.messagingHostedNumbers = result.onlyUserGroupMessagerOnHostedNumbers;

                deferredObject.resolve(response);
            });
        });
    };

    const _getDefaultDeactivatedVoicePrompt = () => {
        return _voicePromptAudioFactory.getVoicePrompt(GLOBAL_DEACTIVATED_PROMPT);
    };

    const _deactivateUserGroup = (userGroupId, sendAllCallsTo) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _deactivateUserGroupClientApi(userGroupId, sendAllCallsTo)
                .fail(deferredObject.reject)
                .done(() => {
                    _webSocketApp.send("deactivate_user_group", {userGroupId: userGroupId}, () => {
                        deferredObject.resolve();
                    });
                });
        });
    };

    const  _deactivateConstrainedUserGroup = (userGroupId, sendAllCallsTo) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _deactivateUserGroupClientApi(userGroupId, sendAllCallsTo)
                .fail(deferredObject.reject)
                .done(() => {
                    if (sendAllCallsTo.subscriberType === "userGroup") {
                        const userGroup = _commonState.get(sendAllCallsTo.id);
                        sendAllCallsTo.id = userGroup.id;
                    }

                    const request = {
                        userGroupId: userGroupId,
                        sendAllCallsTo: sendAllCallsTo
                    };

                    _webSocketApp.send("deactivate_constrained_user_group", request, () => {
                        deferredObject.resolve();
                    });
                });
        });
    };

    const _deactivateUserGroupClientApi = (userGroupId, sendAllCallsTo) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const request = {
                userGroupId: userGroupId,
                subscriber: {
                    userId: sendAllCallsTo.subscriberType === "user" ? sendAllCallsTo.id : null,
                    userGroupId: sendAllCallsTo.subscriberType === "userGroup" ? sendAllCallsTo.id : null
                }
            };

            _deactivateUserGroupSocket.send("deactivateUserGroup", request, (result, err) => {
                if (err) {
                    deferredObject.reject(new Error(err));
                    return;
                }
                deferredObject.resolve();
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const ClientApiConstructor = require('externalDependencies/clientApi');
        _clientApi = new ClientApiConstructor();
        _clientApi.init();

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_group_deactivate_modal");

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();
    };

    return function () {
        const self = this;

        self.init = _init;
        self.getAboutThisUserGroup = _getAboutThisUserGroup;
        self.getDefaultDeactivatedVoicePrompt = _getDefaultDeactivatedVoicePrompt;
        self.deactivateConstrainedUserGroup = _deactivateConstrainedUserGroup;
        self.deactivateUserGroup = _deactivateUserGroup;
    };
});

