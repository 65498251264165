define('businessServices/userSettings/sidebarUserSettingStore',['common/promises/promiseFactory',
    'businessServices/userSettings/userSettingStore'], function() {
    return function(){
        var self = this;
         
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _userSettingStore = null;
        var SETTINGS_MODULE_NAME = "sidebar";
         
        
        /**
         * Determines the state of the sidebar
         * @return {Promise.<Boolean>} [True = expanded, False = collapsed]
         */
        self.isSidebarExpanded = function(){
            return _promiseFactory.defer(function (deferredObject){
                _userSettingStore.get(SETTINGS_MODULE_NAME)
                    .done(function (sidebarSettings){
                        var isSidebarExpanded = true;
                        if (sidebarSettings.isSidebarExpanded !== undefined) { 
                            isSidebarExpanded = sidebarSettings.isSidebarExpanded;
                        }
                        deferredObject.resolve(isSidebarExpanded);
                    });
            });
        };

        /**
         * Update the state of the sidebar
         * @param  {Boolean} isSidebarExpanded [The new desired state of the sidebar]
         * @return {Promise}
         */
        self.updateExpandedSidebar = function(isSidebarExpanded){
            return _promiseFactory.defer(function (deferredObject){
                _userSettingStore.get(SETTINGS_MODULE_NAME)
                    .done(function (sidebarSettings){
                        sidebarSettings.isSidebarExpanded = isSidebarExpanded;
                        _userSettingStore.set(SETTINGS_MODULE_NAME, sidebarSettings)
                            .done(function() {
                                deferredObject.resolve();
                            });
                    });
            });
        };

        self.init = function() {
            var UserSettingStoreConstructor = require('businessServices/userSettings/userSettingStore');
            _userSettingStore = new UserSettingStoreConstructor();
            _userSettingStore.init();
        };

        self.init2 = function(userSettingStore) {
            _userSettingStore = userSettingStore;
        };
    };
});
