define('common/encryption/crypto',['lib/md5', 'lib/sha1', 'lib/sha256'], function() {
    return function()
    {
        var self = this;

        self.createMd5Hash = function(valueToHash) {
            var hashedValue = CryptoJS.MD5(valueToHash).toString(CryptoJS.enc.Base64);
            return hashedValue;
        };

        self.createSha1Hash = function(valueToHash) {
            var hashedValue = CryptoJS.SHA1(valueToHash).toString(CryptoJS.enc.Base64);
            return hashedValue;
        };

        self.createSha256Hash = function(valueToHash) {
            var hashedValue = CryptoJS.SHA256(valueToHash).toString(CryptoJS.enc.Base64);
            return hashedValue;
        };
    };
});

