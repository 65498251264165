define('presentation/common/warningBox/viewModels/warningBoxViewModel',[], function () {
    return function () {
        const self = this;

        const _i18n = require('i18next');

        let _disposables = [];
        let _settings;

        const _buildWarningMessage = () => {
            self.warningMessages([{
                message: self.message(),
                link: self.link(),
                externalLink: self.externalLink(),
                linkText: self.linkText(),
                listItems: []
            }]);
        };

        const _buildI18nWarningMessage = () => {
            self.warningMessages([{
                message: _i18n.t(self.messageI18nKey()),
                listItems: []
            }]);
        };

        self.link = ko.observable("");
        self.externalLink = ko.observable("");
        self.linkText = ko.observable("");
        self.message = ko.observable("");
        self.messageI18nKey = ko.observable("");
        self.warningMessages = ko.observable([]);
        self.title = ko.observable("");
        self.withClose = ko.observable(false);
        self.isClosed = ko.observable(false);
        self.hasTitle = ko.pureComputed(() => self.title() !== "");
        self.showMessageClose = ko.pureComputed(() => self.title() === "" && self.withClose());
        self.hasWarningMessages = ko.pureComputed(() => self.warningMessages().length > 0);

        self.onClose = () => {
            self.isClosed(true);
        };

        self.detached = () => {
            _disposables.forEach(disposable => disposable.dispose());
            _disposables = null;
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            if (_settings.link) {
                if (ko.isObservable(_settings.link)) {
                    self.link = _settings.link;
                } else {
                    self.link(_settings.link);
                }
            }
            if (_settings.externalLink) {
                if (ko.isObservable(_settings.externalLink)) {
                    self.externalLink = _settings.externalLink;
                } else {
                    self.externalLink(_settings.externalLink);
                }
            }
            if (_settings.linkText) {
                if (ko.isObservable(_settings.linkText)) {
                    self.linkText = _settings.linkText;
                } else {
                    self.linkText(_settings.linkText);
                }
            }
            if (_settings.message) {
                if (ko.isObservable(_settings.message)) {
                    self.message = _settings.message;
                } else {
                    self.message(_settings.message);
                }

                _buildWarningMessage();

                _disposables.push(self.message.subscribe(_buildWarningMessage));
            }

            if (_settings.messageI18nKey) {
                if (ko.isObservable(_settings.messageI18nKey)) {
                    self.messageI18nKey = _settings.messageI18nKey;
                } else {
                    self.messageI18nKey(_settings.messageI18nKey);
                }

                _buildI18nWarningMessage();

                _disposables.push(self.messageI18nKey.subscribe(_buildI18nWarningMessage));
            }

            if (_settings.title) {
                if (ko.isObservable(_settings.title)) {
                    self.title = _settings.title;
                } else {
                    self.title(_settings.title);
                }
            }

            if (_settings.titleI18nKey) {
                self.title(_i18n.t(ko.unwrap(_settings.titleI18nKey)));
            }

            if (_settings.warningMessages) {
                self.warningMessages = _settings.warningMessages;
            }

            if (_settings.hasOwnProperty('withClose')) {
                self.withClose(ko.unwrap(_settings.withClose));
            }
        };
    };
});
