define('constants/inlinePromptDefaultOptionEnumerations',[], function() {
    const _i18n = require('i18next');

    return {
        NEW_PROMPT: {
            id: "+ New Audio File",
            name: _i18n.t('common:newAudioFile'),
            type: "voicePrompt",
            duration: ko.observable(0),
            audioData: ko.observable("emptyData")
        },

        NONE_PROMPT: {
            id: "None Audio File",
            name: _i18n.t('common:pleaseSelect'),
            duration: ko.observable(0),
            audioData: ko.observable("emptyData")
        },

        SELECT_PROMPT: {
            id: "pleaseSelect",
            name: _i18n.t('common:pleaseSelect'),
            duration: ko.observable(0),
            audioData: ko.observable("emptyData")
        }
    };
});
