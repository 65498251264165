define('presentation/userProfile/facades/userProfileFacade',['businessServices/router/router',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/events/eventManager',
    'presentation/userProfile/presentationObjects/currentUserPresentationObject',
    'persistence/dataProviders/userDataProvider',
    'presentation/common/userAvatar/userAvatarImageFactory',
    'businessServices/authentication/authenticationManager',
    'persistence/webSocket/webSocketApp'
    ],
    function () {
        return function () {
            let self = this;

            let _userAvatarImageFactory = null;
            let _sessionAccountInfo = null;
            let _eventManager = null;
            let _userDataProvider = null;
            let _promiseFactory = null;
            let _authenticationManager = null;
            let _webSocketCrmAccountInfo = null;

            const CurrentUserPresentationObjectConstructor = require('presentation/userProfile/presentationObjects/currentUserPresentationObject');

            self.getOpenInCrmUrl = () => {
                return _promiseFactory.defer((promise) => {
                    const webSocketParams = {
                        "auth_token": _sessionAccountInfo.apiToken(),
                        "user_id": _sessionAccountInfo.userId(),
                        "account_id": _sessionAccountInfo.accountId()
                    };

                    _webSocketCrmAccountInfo.send("generate_open_in_crm_url", webSocketParams, (result) => {
                        promise.resolve(result.crmUrl);
                    });
                });
            };

            self.getCurrentUserProfileId = () => {
                return _sessionAccountInfo.userId();
            };
            
            self.isBillingAdmin = () => {
                return _promiseFactory.defer((promise) => {
                    promise.resolve(_sessionAccountInfo.accountPermissions().Billing);
                });
            };

            self.isImpersonating = () => {
                return _promiseFactory.defer((promise) => {
                    promise.resolve(_sessionAccountInfo.isImpersonating());
                });
            };

            self.getCurrentUserInfo = () => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    let userId = _sessionAccountInfo.userId();

                    const getInitials = (first, last) => {
                        return first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
                    };

                    _userDataProvider.getById(userId).then((user) => {
                        let currentUser = new CurrentUserPresentationObjectConstructor();
                        if (user.first_name === undefined) {
                            currentUser.fullName = user.firstName + " " + user.lastName;
                            currentUser.initials = getInitials(user.firstName, user.lastName);
                        } else {
                            currentUser.fullName = user.first_name + " " + user.last_name;
                            currentUser.initials = getInitials(user.first_name, user.last_name);
                        }

                        deferredObject.resolve(currentUser);
                    });
                });
            };

            self.logUserOut = () => {
                return _promiseFactory.defer((promise) => {
                    _authenticationManager.logout()
                        .done(promise.resolve)
                        .fail(promise.reject);
                });
            };

            self.getCurrentUserAvatar = () => {
                let userId = _sessionAccountInfo.userId();
                return _userAvatarImageFactory.getAvatarByUserId(userId, true);
            };

            self.init = (promiseFactory) => {
                _promiseFactory = promiseFactory;
                _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
                _eventManager = require('businessServices/events/eventManager');

                const UserDataProviderConstructor = require('persistence/dataProviders/userDataProvider');
                _userDataProvider = new UserDataProviderConstructor();

                const UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
                _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
                _userAvatarImageFactory.init();

                _authenticationManager = require('businessServices/authentication/authenticationManager');

                const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
                _webSocketCrmAccountInfo = new WebSocketAppConstructor();
                _webSocketCrmAccountInfo.init("crm_open_account_from_client_ui");
            };
        };
    }
);

