define('businessServices/authentication/featureToggleSettings',[
    'common/promises/promiseFactory', 'externalDependencies/clientWebSocket', 'constants/featureToggleConstants'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    let _featureTogglesWebSocket = clientWebSocket.forApplication("featureToggle");

    let _featureToggleConstants = require('constants/featureToggleConstants');

    /** @type { {accountId: null | string} } */
    let data = {
        accountId: null
    };

    // Feature toggle names added here must also have a getter below and exist in both the _featureToggleConstants and the FeatureToggle table
    const ACTIVE_TOGGLE_VALUES = {
        smsMessaging: ko.observable(false),
        webMessaging: ko.observable(false),
        contacts: ko.observable(false),
        humanDetection: ko.observable(false),
        webContacts: ko.observable(false),
        blocking: ko.observable(false)
    };

    const smsMessaging = ko.pureComputed(() => {
        return ACTIVE_TOGGLE_VALUES.smsMessaging();
    });

    const webMessaging = ko.pureComputed(() => {
        return ACTIVE_TOGGLE_VALUES.webMessaging();
    });

    const contacts = ko.pureComputed(() => {
        return ACTIVE_TOGGLE_VALUES.contacts();
    });

    const humanDetection = ko.pureComputed(() => {
        return ACTIVE_TOGGLE_VALUES.humanDetection();
    });

    const webContacts = ko.pureComputed(() => {
        return ACTIVE_TOGGLE_VALUES.webContacts();
    });

    const blocking = ko.pureComputed(() => {
        return ACTIVE_TOGGLE_VALUES.blocking();
    });

    const getById = (/** @type { number } */ id) => {
        switch (id) {
            case _featureToggleConstants.smsMessaging:
                return smsMessaging();
            case _featureToggleConstants.webMessaging:
                return webMessaging();
            case _featureToggleConstants.contacts:
                return contacts();
            case _featureToggleConstants.humanDetection:
                return humanDetection();
            case _featureToggleConstants.webContacts:
                return webContacts();
            case _featureToggleConstants.blocking:
                return blocking();
            default:
                return true;
        }
    };

    const receiveAlertLoginSuccessful = (/** @type { ILoginInfoForModule } */ loginInfo) => {
        data.accountId = loginInfo.accountId;
        getAccountFeatureToggleSettings();
    };

    const receiveAlertLogout = () => {
        data.accountId = null;
        _featureTogglesWebSocket.disposeOfEvents();
    };

    const getAccountFeatureToggleSettings = () => {
        return _promiseFactory.defer((promise) => {

            _featureTogglesWebSocket.send("getFeatureToggles", { accountId: data.accountId }, (response, errMsg) => {
                if (errMsg) {
                    throw new Error(errMsg);
                }
                updateFeatureTogglesList(response);
                promise.resolve();
            });
        });
    };

    const onFeatureTogglesUpdate = () => {
        _featureTogglesWebSocket.subscribeToEvent("featureTogglesChanged", (response, errMsg) => {
            if (errMsg) {
                throw new Error(errMsg);
            }
            updateFeatureTogglesList(response);
        });
    };

    const updateFeatureTogglesList = (/** @type { IGetFeatureTogglesResponse } */ response) => {
        if (response !== null) {
            Object.keys(ACTIVE_TOGGLE_VALUES).forEach((featureToggle) => {
                if (response.featureTogglesList.findIndex(item => item.featureToggleId === _featureToggleConstants[featureToggle]) !== -1) {
                    ACTIVE_TOGGLE_VALUES[featureToggle](true);
                } else {
                    ACTIVE_TOGGLE_VALUES[featureToggle](false);
                }
            });
        } else {
            Object.keys(ACTIVE_TOGGLE_VALUES).forEach((featureToggle) => {
                ACTIVE_TOGGLE_VALUES[featureToggle](false);
            });
        }
    };

    onFeatureTogglesUpdate();

    return {
        smsMessaging : smsMessaging,
        getById: getById,

        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});

