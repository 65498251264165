/**
 * @module promiseFactory
 */
define('common/promises/promiseFactory',['durandal/system',
        'common/logging/logger',
        'common/uniqueId/uniqueIdGenerator'],
    function(){

    /**
     * Manages promises and provides a way to continue action after a series of promises have been resolved
     * @class promiseFactory
     * @constructor
     */
    return function() {
        var self = this;
        var _system = require('durandal/system');
        var _promiseList = [];
        var _hasTimeout = true;
        var LoggerConstructor = require('common/logging/logger');
        var logger = new LoggerConstructor();
        logger.init();
        var UniqueIdGeneratorConstructor = require('common/uniqueId/uniqueIdGenerator');
        var _uniqueIdGenerator = new UniqueIdGeneratorConstructor();

        var _logException = function(exception) {
            logger.logError(exception);
        };

        var _applyTimeout = function(promise, action) {
            if (_hasTimeout) {
                var timeoutId = setTimeout(function () {
                    var promiseTimedOutError = new Error("Timed out after " + self.timeoutMilliSeconds + " ms");
                    promiseTimedOutError.attemptedAction = action;
                    promise.reject(promiseTimedOutError);
                }, self.timeoutMilliSeconds);

                promise.done(function () {
                    clearTimeout(timeoutId);
                })
                .fail(function () {
                    clearTimeout(timeoutId);
                });
            }
        };

        var waitForItems = function(deferredObject) {
            var itemsBeforeWaiting = _promiseList.length;
            $.when.apply(null, _promiseList)
                .done(function() {
                    var itemsAfterWaiting = _promiseList.length;
                    if (itemsBeforeWaiting === itemsAfterWaiting) {
                        // We are done waiting for everything
                        deferredObject.resolve();
                    } else {
                        // We finished waiting for everything that was originally in the queue, but the list of items has changed since then... so we have to wait more.
                        waitForItems(deferredObject);
                    }
                })
                .fail(function(error){
                    deferredObject.reject(error);
                });
        };

        var _onSuccess = function(promise) {
            _onSuccessOrFailure(promise);
        };

        var _onFailure = function(promise, error) {
            _onSuccessOrFailure(promise);
            _logException(error);
        };

        var _onSuccessOrFailure = function(promise) {
            _removePromise(promise);
        };

        var _removePromise = function(promise) {
            var promiseIndex = -1;
            for (var i = 0; i < _promiseList.length; i++) {
                if (_promiseList[i].id === promise.id) {
                    promiseIndex = i;
                    break;
                }
            }
            if (promiseIndex >= 0) {
                _promiseList.splice(promiseIndex,1);
            }
        };

        var _createPromise = function() {
            var promise = _system.defer();
            promise.id = _uniqueIdGenerator.generateUniqueId();
            promise.done(function() {
                _onSuccess(promise);
            })
            .fail(function(error) {
                _onFailure(promise, error);
            });

            _promiseList.push(promise);

            return promise;
        };

        /**
         * Determines timeout length for resolving promises (-1 means no timeout)
         * @property timeoutMilliSeconds
         * @type {int}
         */
        self.timeoutMilliSeconds = 30000;

        self.setHasTimeout = function(hasTimeout) {
            _hasTimeout = hasTimeout;
        };

        /**
         * Determines minimum execution time for resolving promises
         * @property minimumMillisecondsToWait
         * @type {int}
         */
        self.minimumMillisecondsToWait = 1000;

        self.setMinimumMillisecondsToWait = function(milliseconds) {
            self.minimumMillisecondsToWait = milliseconds;
        };

        /**
         * Returns a promise resolved when the action is completed
         * @method  defer
         * @param  {function} action The function to wrap in a deferred object
         * @return {promise}
         */
        self.defer = function(action) {
            var promise = _createPromise();

            _applyTimeout(promise, action);

            try {
                if (action !== undefined) {
                    action(promise);
                }
            } catch(exception) {
                _logException(exception);
                promise.reject(exception);
            }

            return promise;
        };

        /**
        * Returns a promise resolved when the action is completed
        * @method  defer
        * @param  {function} action The function to wrap in a deferred object
        * @return {promise}
        */
        self.deferIndefinitely = function(action) {
            var promise = _createPromise();

            try {
                if (action !== undefined) {
                    action(promise);
                }
            } catch(exception) {
                _logException(exception);
                promise.reject(exception);
            }

            return promise;
        };

        /**
         * Returns a promise resolved when the action is completed
         * @method  deferWithMinimumWait
         * @param  {function} action The function to wrap in a deferred object
         * @return {promise}
         */
        self.deferWithMinimumWait = (action) => {
            let promise = _createPromise();
            let minimumWaitTime = self.minimumMillisecondsToWait + new Date().getTime();

            _applyTimeout(promise, action);

            setTimeout(() => {
                try {
                    if (action !== undefined) {
                        action(promise);
                    }
                } catch(exception) {
                    _logException(exception);
                    promise.reject(exception);
                }
            }, minimumWaitTime - new Date().getTime());

            return promise;
        };

        self.deferredList = function (itemList, action) {
            return self.deferIndefinitely(function(promise){
                if ((action !== undefined) && (action !== null)) {
                    if (itemList.length > 0) {
                        var results = [];
                        var loop = function(itemList, index, action) {
                            action(itemList[index])
                                .done(function(result){
                                    results.push(result);
                                    index++;
                                    if (index < itemList.length) {
                                        loop(itemList, index, action);
                                    } else {
                                        promise.resolve(results);
                                    }
                                })
                                .fail(function(error){
                                    promise.reject(error);
                                });
                        };
                        loop(itemList, 0, action);
                    } else {
                        promise.resolve([]);
                    }
                } else {
                    throw new Error("No action provided");
                }
            });
        };

        /**
         * Returns a promise resolved when all actions have been completed
         * @method  wait
         * @return {promise}
         */
        self.wait = function() {
            return _system.defer(function(deferredObject) {
                waitForItems(deferredObject);
            });
        };

        self.convertValueToPromise = function(value) {
            if ((value !== undefined) &&  (value.done !== undefined) && (typeof value.done === 'function')) {
                return value;
            } else {
                var promise = _system.defer();
                promise.resolve(value);
                return promise;
            }
        };
    };
});

