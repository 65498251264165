define('presentation/common/smsAttachmentErrorOverlay/viewModels/smsAttachmentErrorOverlayViewModel',[], function () {
    /** @typedef {import('presentation/common/smsAttachmentErrorOverlay/viewModels/smsAttachmentErrorOverlayViewModel')} SmsAttachmentErrorOverlay */

    return function () {
        /** @type {SmsAttachmentErrorOverlay} */
        const self = this;

        const DEFAULT_ICON_HEIGHT = 32;

        self.isHoveringOverlay = ko.observable(false);
        self.errorBadgeMessage = ko.observable("");
        self.tooltipMessage = ko.observable("");
        self.iconHeight = null;

        self.showErrorBadge = ko.pureComputed(() => !!self.errorBadgeMessage());
        self.isTooltipEnabled = ko.pureComputed(() => !!self.tooltipMessage());

        self.onMouseEnterOverlay = () => {
            self.isHoveringOverlay(true);
        };

        self.onMouseLeaveOverlay = () => {
            self.isHoveringOverlay(false);
        };

        /** @type {SmsAttachmentErrorOverlay["activate"]} */
        self.activate = (activationData) => {
            const { errorBadgeMessage, tooltipMessage, iconHeight } = activationData;

            self.errorBadgeMessage = errorBadgeMessage || ko.observable("");
            self.tooltipMessage = tooltipMessage || ko.observable("");
            self.iconHeight = iconHeight ? iconHeight : DEFAULT_ICON_HEIGHT;

            _initialize();
        };

        const _initialize = () => {

        };
    };
});
