define('presentation/common/routeToModal/viewModels/routeToModalViewModel',[
    'common/promises/promiseFactory',
    'businessServices/browserSupport/browserType',
    'businessServices/events/eventManager',
    'presentation/common/modal',
    'presentation/common/routeToModal/common/routeToValidationHelper',
    'presentation/common/routeToModal/viewModels/routeToViewModel',
    'businessServices/state/modelStateObserver',
    'constants/autoAttendantVoiceRouteEnumerations'
], function() {
    return function(routeToType, routeToData) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const RouteToValidationHelperConstructor = require('presentation/common/routeToModal/common/routeToValidationHelper');
        const _validationHelper = new RouteToValidationHelperConstructor();

        const RouteToViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToViewModel');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        let _eventManager = null;
        let _modalService = null;

        self.routeToStrategy = null;
        self.modalTitle = ko.observable();
        self.routeTitle = ko.observable();
        self.addMode = true;
        self.isCompositionComplete = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.routeToViewModel = ko.observable();
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.isOptionNameEnabled = false;
        self.optionName = ko.observable(null).extend({observeState: true});
        self.shouldContentOverflow = ko.pureComputed(() => self.isCompositionComplete() && self.isScrollActive());

        self.routeToOptions = {};
        self.routeToOptions.autoAttendant = {
            enabled: true
        };
        self.routeToOptions.forwardingNumber = {
            enabled: true
        };
        self.routeToOptions.prompt = {
            enabled: true
        };
        self.routeToOptions.subMenu = {
            enabled: true
        };
        self.routeToOptions.user = {
            enabled: true
        };
        self.routeToOptions.userGroup = {
            enabled: true
        };
        self.routeToOptions.schedule = {
            enabled: true
        };
        self.routeToOptions.voicemailBox = {
            enabled: true
        };
        self.routeToOptions.list = [];

        self.isSaveButtonDisabled = ko.observable(false);

        const _validateRouteToViewModalCallback = ({skipValidation = false}) => {
            if (skipValidation) {
                self.isSaveButtonDisabled(false);
            }
            else {
                _validationHelper.validateRouteToViewModel(self.routeToViewModel())
                    .done(isValid => {
                        self.isSaveButtonDisabled(!isValid);
                    });
            }
        };

        self.submitForm = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validationHelper.validateRouteToViewModel(self.routeToViewModel())
                    .done((isValid) => {
                        if (isValid) {
                            self.save()
                                .done(deferredObject.resolve);
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        self.save = () => {
            return _promiseFactory.defer(
                deferredObject => {
                    const strategyPromise = self.addMode ?
                        self.routeToStrategy.save(self.routeToViewModel()) :
                        self.routeToStrategy.update(self.routeToViewModel());

                    strategyPromise
                        .fail(deferredObject.reject)
                        .done(() => {
                            self.isCompositionComplete(false);
                            self.modelStateObserver.saveData();

                            _modalService.closeModal(self);
                            _eventManager.publishRouteToModalChanged();

                            deferredObject.resolve();
                        });
                }
            );
        };

        self.cancelForm = () => {
            self.isCompositionComplete(false);
            self.modelStateObserver.navigateWithoutWarning = true;
            return _promiseFactory.defer((deferredObject) => {
                _modalService.closeModal(self);
                deferredObject.resolve();
            });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
            self.modelStateObserver.commitData();
        };

        self.activate = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            _eventManager.subscribeRouteToCloseModal(self.cancelForm);

            const routeToViewModel = new RouteToViewModelConstructor(routeToType, routeToData, _validateRouteToViewModalCallback);
            routeToViewModel.isOptionNameEnabled = self.isOptionNameEnabled;
            routeToViewModel.optionName = self.optionName;
            routeToViewModel.routeToOptions.subMenu = self.routeToOptions.subMenu;
            routeToViewModel.routeToOptions.autoAttendant = self.routeToOptions.autoAttendant;
            routeToViewModel.routeToStrategy = self.routeToStrategy;
            routeToViewModel.showAnswerTimeoutOptions(false);
            routeToViewModel.routeTitle = self.routeTitle;
            routeToViewModel.showRouteToTitle(true);
            routeToViewModel.showRouteToOptions(true);
            routeToViewModel.isRouteToModal(true);
            routeToViewModel.parentViewModel = ko.observable(self);
            self.routeToViewModel(routeToViewModel);

            self.modelStateObserver.addChildObserverAndWatch(routeToViewModel.modelStateObserver);

            return _promiseFactory.wait();
        };
    };
});


