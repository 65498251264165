define('presentation/settings/userGroup/facades/userGroupsFacade',[
    'constants/userGroupConstants',
    'persistence/webSocket/webSocketApp',
    'presentation/common/dateTimeValue',
    'presentation/settings/userGroup/presentationObjects/userGroupPresentationObject',
    'settings/navigationConfiguration'
], function () {
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const UserGroupPresentationObjectConstructor = require('presentation/settings/userGroup/presentationObjects/userGroupPresentationObject');

    const _commonState = require('common/storage/commonState');
    const _navigationConfiguration = require('settings/navigationConfiguration');
    const _userGroupConstants = require('constants/userGroupConstants');

    let _promiseFactory = null;
    let _webSocketApp = null;

    const _getMemberCount = (members) => {
        let activeMembers = members.filter((member) => _commonState.get(member.userId).isActive());
        return activeMembers.length;
    };

    const _getAllUserGroups = (showFilter) => {
        return _promiseFactory.defer((deferredObject) => {
            let request = {showFilter: showFilter};
            _webSocketApp.send("get_all_user_groups", request, (result) => {
                let userGroups = result.userGroups.map((userGroup) => {
                    const userGroupPresentationObject = new UserGroupPresentationObjectConstructor();
                    userGroupPresentationObject.userGroupId = userGroup.groupId;
                    userGroupPresentationObject.groupName = userGroup.groupName;
                    userGroupPresentationObject.memberCount(_getMemberCount(userGroup.members));
                    userGroupPresentationObject.modifiedDateTime = new DateTimeValueConstructor(userGroup.modifiedDateTime);
                    userGroupPresentationObject.createdDateTime = new DateTimeValueConstructor(userGroup.createdDateTime);
                    userGroupPresentationObject.editUrl = _navigationConfiguration.routesById.editUserGroup.baseUrl + userGroup.accountUserGroupId;
                    userGroupPresentationObject.status(userGroup.status);

                    if (userGroup.groupId === _userGroupConstants.allUserGroupId ||
                        userGroup.groupId === _userGroupConstants.billingAdminsGroupId ||
                        userGroup.groupId === _userGroupConstants.systemAdminsGroupId) {
                            userGroupPresentationObject.isGroupDeletable = false;
                            userGroupPresentationObject.isBuiltInGroup = true;
                    }

                    return userGroupPresentationObject;
                });

                deferredObject.resolve(userGroups);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketActivityAppConstructor();
        _webSocketApp.init("user_groups_listing");
    };

    return function () {
        let self = this;

        self.init = _init;
        self.getAllUserGroups = _getAllUserGroups;
    };
});
