define('presentation/common/actionModal/viewModels/deletePhoneNumberActionViewModel',[
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'presentation/common/actionModal/presentationObjects/deletePhoneNumberPresentationObject',
    'presentation/common/callFlow/common/callFlowBuilder'
], function() {
    return function(phoneNumber, availableVoicePrompts, availableSchedules, availableAutoAttendants) {
        const self = this;

        const CallFlowBuilder = require('presentation/common/callFlow/common/callFlowBuilder');
        const PhoneNumberPresentationObjectConstructor = require('presentation/common/actionModal/presentationObjects/deletePhoneNumberPresentationObject');

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _commonState = require('common/storage/commonState');

        let _routingRules = [];

        self.objectType = _commonState.typeDisplay.hostedNumber.toLowerCase();

        self.buildDeletePhoneNumber = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let _callFlowBuilder = new CallFlowBuilder();
                _callFlowBuilder.init(_promiseFactory);

                _callFlowBuilder.availableVoicePrompts = availableVoicePrompts;
                _callFlowBuilder.availableSchedules = availableSchedules;

                let numberToDelete = new PhoneNumberPresentationObjectConstructor();
                numberToDelete.phoneId = phoneNumber.phoneConnectorId;
                numberToDelete.phoneNumber = phoneNumber.phoneNumber;
                numberToDelete.formattedPhoneNumber(phoneNumber.formattedPhoneNumber());
                numberToDelete.phoneNumberName(phoneNumber.phoneNumberName());
                numberToDelete.phoneNumberDisplayName(phoneNumber.phoneNumberDisplayName());
                numberToDelete.showLocation = phoneNumber.showLocation;
                numberToDelete.isPortPending(phoneNumber.isPortPending());
                numberToDelete.formattedCityState = phoneNumber.formattedCityState;

                numberToDelete.isInboundCallingEnabled(phoneNumber.isInboundCallingEnabled());
                numberToDelete.isInboundCallRecordingEnabled(phoneNumber.isInboundCallRecordingEnabled());
                numberToDelete.isOutboundCallRecordingEnabled(phoneNumber.isOutboundCallRecordingEnabled());
                numberToDelete.isCallRecordingPermissionInherited(phoneNumber.isCallRecordingPermissionInherited());
                numberToDelete.callRecordingSubscribers(phoneNumber.callRecordingSubscribers());
                numberToDelete.recordingSearchData(phoneNumber.recordingSearchData());

                numberToDelete.isOutboundCallingEnabled(phoneNumber.isOutboundCallingEnabled());
                numberToDelete.isOutboundCallingPermissionInherited(phoneNumber.isOutboundCallingPermissionInherited());
                numberToDelete.outboundCallingPermission(phoneNumber.outboundCallingPermission());
                numberToDelete.outboundSearchData(phoneNumber.outboundSearchData());

                numberToDelete.isSmsEnabled(phoneNumber.isSmsEnabled());
                numberToDelete.isSmsPermissionInherited(phoneNumber.isSmsPermissionInherited());
                numberToDelete.smsAuthorizedMessagers(phoneNumber.smsAuthorizedMessagers());
                numberToDelete.smsSearchData(phoneNumber.smsSearchData());

                numberToDelete.editConnectorUrl = phoneNumber.editConnectorUrl;
                numberToDelete.activeDateTime = phoneNumber.activeDateTime;
                numberToDelete.modifiedDateTime = phoneNumber.modifiedDateTime;
                numberToDelete.modifiedDateTimeDisplayValue(phoneNumber.modifiedDateTimeDisplayValue());

                numberToDelete.routeToType(phoneNumber.routeToType());
                numberToDelete.routeToRoutingRuleId = phoneNumber.routeToRoutingRuleId;

                if (phoneNumber.routingRule() !== null) {
                    numberToDelete.routingRule(phoneNumber.routingRule());
                    _routingRules.push(numberToDelete.routingRule());
                    _callFlowBuilder.buildRoutingRules(numberToDelete.routingRule(), _routingRules);
                    _callFlowBuilder.formatCallFlowForDisplay(numberToDelete, _routingRules)
                        .done((callFlow) => {
                            numberToDelete.callFlow = callFlow;
                            promise.resolve(numberToDelete);
                        });
                } else {
                    numberToDelete.routingRule(phoneNumber.routingRule());
                    promise.resolve(numberToDelete);
                }
            });
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            self.buildDeletePhoneNumber()
                .done((numberToDelete) => {
                    self.phoneNumber = numberToDelete;
                    self.phoneNumber.inboundCallingTabViewModel.activate(numberToDelete, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                    self.phoneNumber.outboundDialTabViewModel.activate(numberToDelete);
                    self.phoneNumber.callRecordingTabViewModel.activate(numberToDelete);
                    self.phoneNumber.textMessagingTabViewModel.activate(numberToDelete);
                });
            return _promiseFactory.wait();
        };
    };
});
