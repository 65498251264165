define('constants/closeAccountConstants',[], function() {
    return {
        reasons: {
            lacksFeatures: "1",
            tooDifficult: "2",
            tooExpensive: "3",
            billingProblem: "4",
            technicalProblem: "5",
            poorCustomerService: "6",
            noLongerNeeded: "7",
            fraud: "8",
            termsOfUseViolation: "9",
            other: "10",
            writtenRequest: "11",
            nonPayment: "12",
            portOut: "13"
        },
        noLongerNeededReasons: {
            temporaryCampaign: 1,
            noLongerInBusiness: 2,
            notUsingIt: 3,
            other: 4
        },
        tooExpensiveReasons: {
            notGoodValue: 1,
            taxesAndFeesTooHigh: 2,
            other: 3
        }
    };
});

