define('presentation/settings/blocking/viewModels/blockingViewModel',[
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/storage/commonState',
    'common/promises/promiseFactory',
    'constants/userGroupConstants',
    'presentation/settings/blocking/facades/blockingFacade'
], function(
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('common/collections/collectionSorter') */
    CollectionSorter,
    /** @type typeof import('common/storage/commonState') */
    _commonState,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/userGroupConstants') */
    UserGroupConstants,
    /** @type typeof import('presentation/settings/blocking/facades/blockingFacade') */
    BlockingFacade
) {
    return function() {
        const self = this;

        const _promiseFactory = new PromiseFactory();
        const _facade = new BlockingFacade(_promiseFactory);
        const _modelStateObserver = new ModelStateObserver(self, true);
        const _sorter = new CollectionSorter();

        /** @type { (allSubscribers: Array<IBlockingPermissionPresentationObject>, selectedSubscribers: Array<KnockoutObservable<IBlockingPermissionPresentationObject>>) => Array<IBlockingPermissionPresentationObject> } */
        const _filterAvailableSubscribers = (allSubscribers, selectedSubscribers) => {
            _sorter.sort(allSubscribers, "displayName", true);

            return allSubscribers.filter((subscriber) => {
                return undefined === selectedSubscribers.find((selectedSubscriber) => {
                    return subscriber.id === selectedSubscriber().id;
                });
            });
        };

        /** @type { (initialPermissions: Array<IBlockingPermissionPresentationObject>) => Array<IBlockingPermissionPresentationObject> } */
        const _sortInitialPermissions = (initialPermissions) => {
            const systemAdminsGroup = _commonState.get(UserGroupConstants.systemAdminsGroupId);

            const systemGroup = initialPermissions.find((permission) => permission.id === systemAdminsGroup.id);

            const permissionList = initialPermissions.filter((permission) => permission.id !== systemAdminsGroup.id);

            _sorter.sort(permissionList, "displayName", true);

            permissionList.unshift(systemGroup);

            return permissionList;
        };

        self.modelStateObserver = null;

        self.blockingPermissionList = ko.observableArray([]).extend({observeState: true});

        self.allUsersAndUserGroups = ko.pureComputed(() => {
            const allUsersAndGroups = _facade.allUsersAndUserGroups();

            _sorter.sort(allUsersAndGroups, "displayName", true);

            return allUsersAndGroups;
        });

        self.cancelForm = () => {
            self.modelStateObserver.restoreData();
        };

        self.savePage = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                const blockingPermissionList = self.blockingPermissionList();

                _facade.updateBlockingPermissions(blockingPermissionList)
                    .fail((error) => {
                        deferredObject.reject(error);
                    })
                    .done(() => {
                        self.modelStateObserver.saveData();
                        deferredObject.resolve();
                    });
            });
        };

        self.filterAvailableSubscribers = _filterAvailableSubscribers;

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = _modelStateObserver;
            self.allUsersAndUserGroups = _facade.allUsersAndUserGroups;

            _facade.getBlockingPermissions()
                .then((/** @type { Array<IBlockingPermissionPresentationObject> } */ blockingPermissionList) => {
                    const sortedPermissions = _sortInitialPermissions(blockingPermissionList);
                    self.blockingPermissionList(sortedPermissions);

                    self.modelStateObserver.commitData();
                });

            return _promiseFactory.wait();
        };
    };
});
