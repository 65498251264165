/*jslint devel : true*/
define('presentation/styleGuide/viewModels/styleGuideViewModel',[
    'common/presentation/iconNames',
    'common/promises/promiseFactory',
    'businessServices/state/modelStateObserver',
    'presentation/tabs/presentationObjects/tabsPresentationObject'
], function() {
    var FakeModel = function() {
        var self = this;
        self.someProperty = ko.observable(true);
    };

    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');

        const _iconNames = require('common/presentation/iconNames');

        self.clicked = function() {
            alert("this item has been clicked");
        };

        self.threeStateSaveWaitingForUpdatesModel = new FakeModel();
        self.threeStateSaveWaitingForUpdatesObserver = new ModelStateObserverConstructor(self.threeStateSaveWaitingForUpdatesModel, false);
        self.threeStateSaveWaitingForUpdatesObserver.commitData();
        self.threeStateSaveWaitingForUpdatesClicked = function() {
            return _promiseFactory.defer(function(promise) {
                setTimeout(function() {
                    promise.resolve();
                }, 5000);
            });
        };

        self.threeStateSaveReadyToCommitUpdatesModel = new FakeModel();
        self.threeStateSaveReadyToCommitUpdatesObserver = new ModelStateObserverConstructor(self.threeStateSaveReadyToCommitUpdatesModel, false);
        self.threeStateSaveReadyToCommitUpdatesObserver.commitData();
        self.threeStateSaveReadyToCommitUpdatesModel.someProperty(false);
        self.threeStateSaveReadyToCommitUpdatesObserver.isDirty(true);
        self.threeStateSaveReadyToCommitUpdatesClicked = function() {
            return _promiseFactory.defer(function(promise) {
                setTimeout(function() {
                    promise.resolve();
                }, 5000);
            });
        };

        self.selectOptionsList = [...Array(10).keys()].map((number) => {
            return {
                id: `item${number}`,
                value: `Item ${number}`,
                displayName: `Item ${number}`
            };
        });

        self.activeTab = ko.observable('self-contained');
        self.selectTabs = [
            new TabPresentationObjectConstructor('self-contained', self.activeTab, true, 'Self Contained'),
            new TabPresentationObjectConstructor('resizable', self.activeTab, true, 'Resizable'),
        ];

        self.showSelfContainedListBox = ko.pureComputed(() => self.activeTab() === 'self-contained');
        self.showResizableListBox = ko.pureComputed(() => self.activeTab() === 'resizable');

        self.listBoxSelectedOptionId = ko.observable(self.selectOptionsList[5].id);
        self.listBoxDisplayOptions = ko.observable(self.selectOptionsList);

        self.resizingListBoxLength = ko.observable(10);
        self.resizingListBoxSelectedOptionId = ko.observable(self.selectOptionsList[5].id);
        self.resizingListBoxDisplayOptions = ko.pureComputed(() => {
            return self.selectOptionsList.slice(0, self.resizingListBoxLength());
        });

        self.webIcons = ko.observable(Object.keys(_iconNames).filter(i => i !== '__moduleId__'));

        self.detached = () => {
            clearInterval(self.interval);
        };

        self.filterListBoxInterval = null;

        self.activate = () => {
            self.filterListBoxInterval = setInterval(() => {
                self.resizingListBoxLength((self.resizingListBoxLength() - 1) % 10);
            }, 1000);
        };
    };
});

