define('presentation/settings/extensions/presentationObjects/extensionPresentationObject',['common/storage/commonState'],
function() {
    return function() {
        const self = this;

        const _commonState = require('common/storage/commonState');

        self.assignedToId = null;
        self.name = ko.observable("");
        self.extension = ko.observable("");
        self.originalExtension = null;
        self.type = ko.observable("");
        self.typeDisplayValue = ko.pureComputed(() => {
            switch (self.type()) {
                case _commonState.types.user:
                    return _commonState.typeDisplay.user;
                case _commonState.types.userGroup:
                    return _commonState.typeDisplay.userGroup;
                case  _commonState.types.forwardingNumber:
                    return _commonState.typeDisplay.forwardingNumber;
                default:
                    return "";
            }
        });
        self.assignedDisplayValue = ko.observable("");
        self.assignedSortValue = ko.observable("");
        self.assignedFilterValue = ko.observable("");
        self.isAssigned = ko.observable(false);
        self.showDropdown = ko.observable(false);
    };
});

