define('presentation/settings/userSubscriptions/viewModels/userCallRecordingSubscriptionsViewModel',['businessServices/authentication/sessionAccountInfo',
        'businessServices/state/modelStateObserver',
        'common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'presentation/settings/userSubscriptions/facades/userCallRecordingSubscriptionsFacade',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const FacadeConstructor = require('presentation/settings/userSubscriptions/facades/userCallRecordingSubscriptionsFacade');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        let _facade = null;
        let _userId = _sessionAccountInfo.userId();

        const HEADING = {
            phoneNumbers: 'phoneNumbers',
            mobile: 'mobile',
            web: 'web',
            email: 'email',
        };

        const _toggleColumnSort = (columnSort) => {
            if (columnSort === '') {
                return;
            }
            self.columnSortBy(columnSort);
            self.columnSortAscending.toggle();
        };

        const _headingColumnCss = (column) => ko.pureComputed(() => `subscriptions__grid-heading-cell-${column}`);
        const _setSortIndicatorCss = (sortKey) => ko.pureComputed(() => {
            if (sortKey === '') {
                return '';
            }
            if (self.columnSortAscending()) {
                return 'indicator-triangle--up';
            }
            return 'indicator-triangle--down';
        });

        const _buildHeadingObject = (key, column, sort) => ({
            key, column, sort,
            get click() {
                return () => _toggleColumnSort(this.sort);
            },
            get css() {
                return _headingColumnCss(this.column);
            },
            get sortIndicatorCss() {
                return _setSortIndicatorCss(this.sort);
            }
        });

        self.modelStateObserver = null;
        self.headingsDisplay = [
            _buildHeadingObject(HEADING.phoneNumbers, 'name', 'callRecordingBoxName'),
            _buildHeadingObject(HEADING.mobile, 'mobile', ''),
            _buildHeadingObject(HEADING.web, 'web', ''),
            _buildHeadingObject(HEADING.email, 'email', ''),
        ];
        self.columnSortBy = ko.observable('callRecordingBoxName');
        self.columnSortAscending = ko.observable(true).toggleable();

        self.subscriptions = ko.observableArray([]).extend({observeState: true});
        self.displayedSubscriptions = ko.pureComputed(() => {
            const sorter = new SorterConstructor();
            let displayedSubscriptions = self.subscriptions();
            sorter.sort(displayedSubscriptions, self.columnSortBy(), self.columnSortAscending());
            return displayedSubscriptions;
        });

        self.isLoading = ko.observable(true);
        self.isEmpty = ko.pureComputed(() => self.subscriptions().length === 0);

        self.toggleSubscribers = (subscription) => {
            if (subscription.subscribers().length === 0) {
                return;
            }
            return subscription.showSubscribers.toggle();
        };

        self.save = () => {
            return _promiseFactory.defer((promise) => {
                let dirtyCallRecordingBoxes = self.subscriptions().filter((callRecordingBox) =>
                    callRecordingBox.isEmailDeliveryEnabled.isDirty || callRecordingBox.isShowInSidebarEnabled.isDirty || callRecordingBox.isPushToMobileAppEnabled.isDirty
                );

                let formattedCallRecordingBoxes = Object.assign({}, {
                    userId: _userId,
                    updatedSettings: dirtyCallRecordingBoxes.map((callRecordingBox) => {
                        return Object.assign({}, {
                            accountHostedNumberId: callRecordingBox.callRecordingBoxId,
                            isEmailDeliveryEnabled: callRecordingBox.isEmailDeliveryEnabled(),
                            isShowInSidebarEnabled: callRecordingBox.isShowInSidebarEnabled(),
                            isPushToMobileAppEnabled: callRecordingBox.isPushToMobileAppEnabled()
                        });
                    })
                });

                _facade.saveSubscriptionSettings(formattedCallRecordingBoxes)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        self.dispose = () => {
            _facade.dispose();
        };

        const refreshPage = (refresh) => {
            if (refresh) {
                _initialize();
            }
        };

        self.activate = (userId) => {
            if (userId !== undefined) {
                _userId = userId;
            }
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory, refreshPage);

            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            return _initialize();
        };

        const _initialize = () =>  {
            _facade.getCallRecordingSubscriptions(_userId)
                .done((result) => {
                    self.subscriptions(result);
                    self.modelStateObserver.commitData();
                    self.isLoading(false);
                })
                .fail((error) => {
                    throw error;
                });

            _facade.onSubscriberSettingsUpdate();
            _facade.onSubscribersUpdate();

            return _promiseFactory.wait();
        };
    };
});

