define('presentation/common/actionModal/viewModels/confirmActionViewModel',[
    'common/promises/promiseFactory'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    return function(/** @type {KnockoutObservable<string> | string} */ confirmActionText) {
        const self = this;

        const _promiseFactory = new PromiseFactory();

        self.confirmActionText = confirmActionText;

        self.activate = () => {
            return _promiseFactory.wait();
        };
    };
});

