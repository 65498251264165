define('presentation/settings/autoAttendant/presentationObjects/userPresentationObject',[],function() {
    return function(){
        var self = this;

        self.userId = null;
        self.firstName = null;
        self.lastName = null;
        self.full_name =  null;
        self.status = null;
        self.emailAddress = null;
        self.extension = null;
        self.avatar = null;
        self.deactivatedMessage = null;
        self.createdDateTime = null;
        self.modifiedDateTime = null;
    };
});
