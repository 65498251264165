define('presentation/settings/phoneNumbers/validators/phoneNumbersValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _commonValidator = null;
        var _connectorViewModel = null;
        var _connectorFacade = null;

        self.registerViewModel = function(phoneConnectorsViewModel, phoneConnectorsFacade) {
            var validationMessageBase = _validationMessageEnumerations.clientui.presentation.settings.connectors;

            var validationRules = new ValidationRulesConstructor();

            _connectorViewModel = phoneConnectorsViewModel;
            _connectorFacade = phoneConnectorsFacade;
            _commonValidator = new CommonValidatorConstructor(_connectorViewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validatePhoneNumberNameUniqueness = function(phoneNumberName, phoneNumberPresentationObject) {
            return _promiseFactory.defer(function(deferredResult) {
                var phoneId = phoneNumberPresentationObject.phoneId;
                var name = phoneNumberName;
                _connectorFacade.isNameUnique(phoneId, name)
                    .done(function (isUnique) {
                        deferredResult.resolve(isUnique);
                    })
                    .fail(function (error) {
                        error.phoneNumberId = phoneId;
                        error.phoneNumberName = name;
                        deferredResult.reject(error);
                    });
            });
        };
    };
});
