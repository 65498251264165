define('presentation/settings/closeAccount/viewModels/closeAccountViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/login/logoutRouteEvaluator',
    'common/collections/collectionSorter',
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'constants/accountStatusEnumerations',
    'constants/closeAccountConstants',
    'entities/accountClosedFeedbackEntity',
    'plugins/router',
    'presentation/common/modal',
    'presentation/settings/closeAccount/facades/closeAccountFacade',
    'presentation/settings/closeAccount/presentationObjects/closeAccountFeaturePresentationObject',
    'presentation/settings/closeAccount/presentationObjects/closeAccountNoLongerNeededFeedbackReasonPresentationObject',
    'presentation/settings/closeAccount/presentationObjects/closeAccountTooExpensiveFeedbackReasonPresentationObject',
    'presentation/settings/closeAccount/validators/closeAccountValidator',
    'presentation/settings/closeAccount/viewModels/closeAccountActionModalViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const CloseAccountActionModalViewModelConstructor = require('presentation/settings/closeAccount/viewModels/closeAccountActionModalViewModel');
        const CloseAccountFeaturePresentationObjectConstructor = require('presentation/settings/closeAccount/presentationObjects/closeAccountFeaturePresentationObject');
        const CloseAccountNoLongerNeededFeedbackReasonPresentationObjectConstructor = require('presentation/settings/closeAccount/presentationObjects/closeAccountNoLongerNeededFeedbackReasonPresentationObject');
        const CloseAccountTooExpensiveFeedbackReasonPresentationObjectConstructor = require('presentation/settings/closeAccount/presentationObjects/closeAccountTooExpensiveFeedbackReasonPresentationObject');
        const CollectionSorterConstructor = require('common/collections/collectionSorter');
        const AccountClosedFeedbackEntity = require('entities/accountClosedFeedbackEntity');

        const _authenticationManager = require('businessServices/authentication/authenticationManager');
        const _accountStatusEnumerations = require('constants/accountStatusEnumerations');
        const _logoutRouteEvaluator = require('businessServices/login/logoutRouteEvaluator');
        const _i18n = require('i18next');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _router = require('plugins/router');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _actionModal = new ActionModalViewModelConstructor();
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _closeAccountConstants = require('constants/closeAccountConstants');
        const _closeAccountReasons = _closeAccountConstants.reasons;
        const _collectionSorter = new CollectionSorterConstructor();
        const _noLongerNeededReasons = _closeAccountConstants.noLongerNeededReasons;
        const _tooExpensiveReasons = _closeAccountConstants.tooExpensiveReasons;
        const _keyValueConverter = require('common/collections/enumerationKeyValueConverter');

        let _validator = null;
        let _facade = null;
        let _modalService = null;

        const DROPDOWN_OPTION_YES = {
            id: "1",
            description: "Yes"
        };
        const DROPDOWN_OPTION_NO = {
            id: "0",
            description: "No"
        };
        const YES_NO_DROPDOWN_OPTIONS = [DROPDOWN_OPTION_NO, DROPDOWN_OPTION_YES];

        const OTHER_OPTION = 'Other';

        self.modelStateObserver = null;
        self.isAccountInFreeTrial = false;

        self.closeAccountFeedbackReasons = ko.observableArray([]);
        self.tooExpensiveFeedbackReasons = ko.observableArray([]);
        self.noLongerNeededFeedbackReasons = ko.observableArray([]);
        self.featuresList = ko.observableArray([]);
        self.ratingOptions = ko.observableArray([]);
        self.yesNoDropdownOptions = ko.observableArray(YES_NO_DROPDOWN_OPTIONS);
        self.showPortOutInstructions = ko.observable(true);
        self.fraudStatuses = _keyValueConverter.convertToKeyValues(_accountStatusEnumerations.fraudStatus);

        self.selectedCloseAccountFeedbackReason = ko.observable().extend({observeState: true});
        self.selectedFraudStatus = ko.observable().extend({observeState: true});

        self.lacksFeaturesOtherFeedback = ko.observable().extend({observeState: true});
        self.otherFeatureSelected = ko.observable();

        self.billingIssueFeedback = ko.observable().extend({observeState: true});
        self.technicalIssueFeedback = ko.observable().extend({observeState: true});
        self.tooDifficultToUseFeedback = ko.observable().extend({observeState: true});
        self.poorCustomerServiceFeedback = ko.observable().extend({observeState: true});

        self.tooExpensiveReason = ko.observable().extend({observeState: true});
        self.tooExpensiveOtherFeedback = ko.observable().extend({observeState: true});

        self.noLongerNeededReason = ko.observable().extend({observeState: true});
        self.noLongerNeededOtherFeedback = ko.observable().extend({observeState: true});

        self.otherFeedback = ko.observable().extend({observeState: true});

        self.areYouSwitchingServices = ko.observable().extend({observeState: true});
        self.whichService = ko.observable().extend({observeState: true});
        self.howLikelyAreYouToRecommend = ko.observable().extend({observeState: true});
        self.additionalFeedback = ko.observable().extend({observeState: true});

        self.cancellationComment = ko.observable();

        self.closeAccountButtonText = ko.pureComputed(() => _i18n.t('closeAccount:closeAccount'));
        self.pleaseSelectPlaceHolderMessage = _i18n.t('closeAccount:pleaseSelect');

        self.showLacksFeaturesForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.lacksFeatures;
        });
        self.showLacksFeaturesOtherFeedback = ko.pureComputed(() => self.otherFeatureSelected());
        self.isLacksFeaturesValid = ko.pureComputed(() => {
            if (self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.lacksFeatures) {
                return !self.featuresList.isInvalid();
            }
            return true;
        });

        self.showTooDifficultToUseForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.tooDifficult;
        });
        self.showBillingIssueForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.billingProblem;
        });
        self.showFraudStatusForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.fraud;
        });
        self.showTechnicalIssueForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.technicalProblem;
        });
        self.showPoorCustomerServiceForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.poorCustomerService;
        });
        self.showTooExpensiveFeedbackForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.tooExpensive;
        });
        self.showTooExpensiveOtherFeedback = ko.pureComputed(() => self.tooExpensiveReason() === _tooExpensiveReasons.other);

        self.showNoLongerNeededFeedbackForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.noLongerNeeded;
        });
        self.showNoLongerNeededOtherFeedback = ko.pureComputed(() => self.noLongerNeededReason() === _noLongerNeededReasons.other);

        self.showOtherFeedbackForm = ko.pureComputed(() => {
            return self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.other;
        });
        self.showSwitchingServicesForm = ko.pureComputed(() => {
            if (!self.selectedCloseAccountFeedbackReason() ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.fraud ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.termsOfUseViolation ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.nonPayment ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.writtenRequest ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.portOut) {
                return false;
            }
            return true;
        });
        self.showWhichService = ko.pureComputed(() => {
            return self.areYouSwitchingServices() === DROPDOWN_OPTION_YES.id;
        });

        self.showRatingAndAdditionalFeedbackForm = ko.pureComputed(() => {
            if (self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.fraud ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.termsOfUseViolation ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.nonPayment ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.writtenRequest |
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.portOut) {
                return false;
            }
            return true;
        });

        self.showCancellationComment = ko.pureComputed(() => {
            if (self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.fraud ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.termsOfUseViolation ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.nonPayment ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.writtenRequest ||
                self.selectedCloseAccountFeedbackReason() === _closeAccountReasons.portOut) {
                return true;
            }
            return false;
        });

        self.onRatingOptionClicked = (selectedOption) => {
            self.howLikelyAreYouToRecommend(selectedOption.id);
            self.ratingOptions().forEach((option) => {
                if (option.id === selectedOption.id) {
                    option.isSelected(true);
                } else {
                    option.isSelected(false);
                }
            });
        };

        const _buildRatingOptions = () => {
            for (let i = 1; i <= 10; i++) {
                const ratingOption = {
                    id: i,
                    isSelected: ko.observable(false)
                };
                self.ratingOptions.push(ratingOption);
            }
        };

        const _populateCloseAccountReasons = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _facade.getCloseAccountReasons()
                    .fail(promise.reject)
                    .done((closeAccountFeedbackReasons, tooExpensiveFeedbackReasons, noLongerNeededFeedbackReasons, featuresList) => {
                        _collectionSorter.sort(closeAccountFeedbackReasons, "description", true);

                        // move other option to the end of the alphabetized list
                        closeAccountFeedbackReasons = closeAccountFeedbackReasons.concat(
                            closeAccountFeedbackReasons.splice(
                                closeAccountFeedbackReasons.findIndex(
                                    (reason) => reason.description === OTHER_OPTION
                                ), 1
                            )
                        );

                        self.closeAccountFeedbackReasons(closeAccountFeedbackReasons.filter((reason) => {
                            if(reason.id.toString() === _closeAccountReasons.portOut) {
                                return false;
                            }
                            if (reason.id.toString() === _closeAccountReasons.writtenRequest ||
                                reason.id.toString() === _closeAccountReasons.nonPayment ||
                                reason.id.toString() === _closeAccountReasons.fraud ||
                                reason.id.toString() === _closeAccountReasons.termsOfUseViolation) {
                                return _sessionAccountInfo.isImpersonating();
                            }
                            return true;
                        }));

                        _collectionSorter.sort(tooExpensiveFeedbackReasons, "description", true);
                        // move other option to the end of the alphabetized list
                        tooExpensiveFeedbackReasons = tooExpensiveFeedbackReasons.concat(
                            tooExpensiveFeedbackReasons.splice(
                                tooExpensiveFeedbackReasons.findIndex(
                                    (reason) => reason.description === OTHER_OPTION
                                ), 1
                            )
                        );

                        tooExpensiveFeedbackReasons.forEach((reason) => {
                            let closeAccountTooExpensiveFeedbackReasonPresentationObject = new CloseAccountTooExpensiveFeedbackReasonPresentationObjectConstructor();
                            closeAccountTooExpensiveFeedbackReasonPresentationObject.text = reason.description;
                            closeAccountTooExpensiveFeedbackReasonPresentationObject.value = reason.id;
                            self.tooExpensiveFeedbackReasons.push(closeAccountTooExpensiveFeedbackReasonPresentationObject);
                        });


                        _collectionSorter.sort(noLongerNeededFeedbackReasons, "description", true);
                        // move other option to the end of the alphabetized list
                        noLongerNeededFeedbackReasons = noLongerNeededFeedbackReasons.concat(
                            noLongerNeededFeedbackReasons.splice(
                                noLongerNeededFeedbackReasons.findIndex(
                                    (reason) => reason.description === OTHER_OPTION
                                ), 1
                            )
                        );

                        noLongerNeededFeedbackReasons.forEach((reason) => {
                            let closeAccountNoLongerNeededFeedbackReasonPresentationObject = new CloseAccountNoLongerNeededFeedbackReasonPresentationObjectConstructor();
                            closeAccountNoLongerNeededFeedbackReasonPresentationObject.text = reason.description;
                            closeAccountNoLongerNeededFeedbackReasonPresentationObject.value = reason.id;
                            self.noLongerNeededFeedbackReasons.push(closeAccountNoLongerNeededFeedbackReasonPresentationObject);
                        });

                        _collectionSorter.sort(featuresList, "displayName", true);

                        // move other option to the end of the alphabetized list
                        featuresList = featuresList.concat(
                            featuresList.splice(
                                featuresList.findIndex(
                                    (feature) => feature.displayName === OTHER_OPTION
                                ), 1
                            )
                        );

                        featuresList.forEach((feature) => {
                            let closeAccountFeaturePresentationObject = new CloseAccountFeaturePresentationObjectConstructor();
                            closeAccountFeaturePresentationObject.id = feature.id;
                            closeAccountFeaturePresentationObject.description = feature.displayName;
                            if (feature.displayName === OTHER_OPTION) {
                                closeAccountFeaturePresentationObject.isSelected = self.otherFeatureSelected;
                            }
                            self.featuresList.push(closeAccountFeaturePresentationObject);
                        });

                        _buildRatingOptions();
                    });
            });
        };

        const _getIsAccountInFreeTrial = () => {
            return _promiseFactory.defer((promise) => {
                _facade.isAccountInFreeTrial()
                    .fail(promise.reject)
                    .done((isAccountInFreeTrial) => {
                        self.isAccountInFreeTrial = isAccountInFreeTrial;
                        promise.resolve();
                    });
            });
        };

        const _showCloseAccountActionModal = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _actionModal.clearModal();
                _actionModal.setContentViewModel(CloseAccountActionModalViewModelConstructor);
                _actionModal.setHeaderText({i18n: 'closeAccount:areYouSure'});
                _actionModal.setSubmitButtonText({i18n: 'closeAccount'});
                _actionModal.setOnSubmitCallback(self.submitAction);
                _actionModal.shouldDisplayCancelButton(true);
                _actionModal.shouldDisplayAgentBypassButton(false);
                _actionModal.shouldDisplaySubmitButton(true);
                _actionModal.shouldDisplayDefaultFooterMessage(false);

                _actionModal.showModal()
                    .done((result) => {
                        if (result === "cancel") {
                            _modalService.closeModal(_actionModal);
                        }
                        promise.resolve(result);
                    });
            });
        };

        self.accountClosedFeedback = () => {
            const accountClosedFeedback = new AccountClosedFeedbackEntity();
            accountClosedFeedback.accountClosedFeedbackTypeDiscriminator = self.selectedCloseAccountFeedbackReason();
            accountClosedFeedback.howLikelyToRecommend = self.howLikelyAreYouToRecommend();
            accountClosedFeedback.generalFeedback = self.additionalFeedback();
            if (self.areYouSwitchingServices() !== null && self.areYouSwitchingServices() !== undefined) {
                accountClosedFeedback.isSwitchingService = self.areYouSwitchingServices() === DROPDOWN_OPTION_YES.id;
            }
            accountClosedFeedback.switchingServiceName = self.whichService();
            accountClosedFeedback.lacksFeaturesFeedbackReasons = self.featuresList().filter((feature) => {
                return feature.isSelected();
            }).map((feature) => {
                return {
                    lacksFeaturesReasonId: feature.id,
                    lacksFeaturesOtherFeedback: feature.description === OTHER_OPTION ? self.lacksFeaturesOtherFeedback() : null
                };
            });
            accountClosedFeedback.difficultToUseFeedback = self.tooDifficultToUseFeedback();
            accountClosedFeedback.accountClosedTooExpensiveFeedbackTypeDiscriminator = self.tooExpensiveReason();
            accountClosedFeedback.tooExpensiveOtherFeedback = self.tooExpensiveOtherFeedback();
            accountClosedFeedback.fraudStatus = self.selectedFraudStatus();
            accountClosedFeedback.billingProblemFeedback = self.billingIssueFeedback();
            accountClosedFeedback.technicalProblemFeedback = self.technicalIssueFeedback();
            accountClosedFeedback.poorCustomerServiceFeedback = self.poorCustomerServiceFeedback();
            accountClosedFeedback.accountClosedNoLongerNeededFeedbackTypeDiscriminator = self.noLongerNeededReason();
            accountClosedFeedback.noLongerNeededOtherFeedback = self.noLongerNeededOtherFeedback();
            accountClosedFeedback.otherFeedback = self.otherFeedback();

            const accountClosedFeedbackReason = self.closeAccountFeedbackReasons().find((reason) => {
                return reason.id.toString() === self.selectedCloseAccountFeedbackReason();
            });
            accountClosedFeedback.reasonForCancellation = accountClosedFeedbackReason.description;
            return accountClosedFeedback;
        };

        self.submitAction = () => {
            _promiseFactory.timeoutMilliSeconds = 60000;
            return _promiseFactory.deferWithMinimumWait((promise) => {
                self.modelStateObserver.saveData();
                if (self.isAccountInFreeTrial === true) {
                    _logoutRouteEvaluator.setLogoutRoute(_navigationConfiguration.accountClosedFreeTrial);
                } else {
                    _logoutRouteEvaluator.setLogoutRoute(_navigationConfiguration.accountClosed);
                }
                _facade.closeAccount(self.accountClosedFeedback(), self.cancellationComment())
                    .fail(promise.reject)
                    .done(() => {
                        _authenticationManager.logout()
                            .done(() => {
                                promise.resolve(true);
                            });
                    });
            });
        };

        self.validate = () => _validator.validate();

        self.closeAccount = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            promise.resolve(false);
                            return;
                        }
                        _showCloseAccountActionModal();
                        promise.resolve(true);
                    });
            });
        };

        self.onClosePortInstructionsWarningBox = () => {
            self.showPortOutInstructions(false);
        };

        self.cancelForm = () => {
            self.modelStateObserver.navigateWithoutWarning = true;
            _router.navigate(_navigationConfiguration.billingProfile);
        };

        self.activate = () => {
            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            const ValidatorConstructor = require('presentation/settings/closeAccount/validators/closeAccountValidator');
            _validator = new ValidatorConstructor();

            const CloseAccountFacadeConstructor = require('presentation/settings/closeAccount/facades/closeAccountFacade');
            _facade = new CloseAccountFacadeConstructor();
            _facade.init(_promiseFactory);

            _initialize();
        };

        const _initialize = () => {
            _populateCloseAccountReasons();
            _getIsAccountInFreeTrial();

            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.commitData();

            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();

        };
    };
});

