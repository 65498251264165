define('presentation/forgotPassword/validators/resetPasswordValidator',['common/specifications/validPasswordSpecification',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'], function () {
    return function () {
        const self = this;

        const ValidPasswordSpecificationConstructor = require('common/specifications/validPasswordSpecification');
        const _validPasswordSpecification = new ValidPasswordSpecificationConstructor();
        let _commonValidator = null;
        let _viewModel = null;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const _validatePassword = (password) => {
            return _commonValidator.isStringWithValue(password);
        };

        const _validateNewPasswordValid = (password) => {
            return _validPasswordSpecification.isSatisfiedBy(password);
        };

        const _validateConfirmPassword = (confirmPassword) => {
            return _commonValidator.isStringWithValue(confirmPassword);
        };

        const _validatePasswordsMatch = (confirmPassword) => {
            const userPassword = _viewModel.password();
            return (userPassword === confirmPassword);
        };

        self.registerViewModel = (viewModel) => {
            var validationRules = new ValidationRulesConstructor();

            validationRules.field("password")
                .addValidationI18n(_validatePassword, 'resetPassword:newPasswordRequired')
                .addValidationI18n(_validateNewPasswordValid, 'resetPassword:newPasswordInvalid');
            validationRules.field("confirmPassword")
                .addValidationI18n(_validateConfirmPassword, 'resetPassword:confirmPasswordRequired')
                .addValidationI18n(_validatePasswordsMatch, 'resetPassword:confirmPasswordDoesNotMatch');

            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };


        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

