define('presentation/common/callDetails/viewModels/callDetailsViewModel',['common/collections/collectionSorter',
        'common/storage/commonState',
], function() {
    return function() {
        const self = this;

        const SorterConstructor = require('common/collections/collectionSorter');
        const sorter = new SorterConstructor();

        const _commonState = require('common/storage/commonState');

        let _settings = null;
        let _subscriberIds = ko.observableArray([]);
        let _callDetails = ko.observable();
        let _voicemail = null;
        let _recording = null;
        let _showCallMedia = true;

        self.callDetails = null;
        self.voicemail = ko.observable(_voicemail);
        self.recording = ko.observable(_recording);
        self.showCallMedia = ko.observable(_showCallMedia);
        self.isCompositionComplete = ko.observable(false);

        self.showVoicemail = ko.pureComputed(() => {
            if (!self.showCallMedia()){
                return false;
            }

            return self.voicemail() &&
                self.voicemail().hasMessage();
        });

        self.isVoicemailDisabled = ko.pureComputed(() => {
            return !self.voicemail().hasSubscription() || self.voicemail().isDeleted();
        });

        self.showRecording = ko.pureComputed(() => {
            if (!self.showCallMedia()){
                return false;
            }

            return self.recording() &&
                self.recording().hasMessage();
        });

        self.isRecordingDisabled = ko.pureComputed(() => {
            return !self.recording().hasSubscription() || self.recording().isDeleted();
        });

        self.hasSubscribers = ko.pureComputed(() => _subscriberIds().length > 0);
        self.subscribers = ko.pureComputed(() => {
            let subscribers = _subscriberIds().map(subscriberId => {
                let subscriber = _commonState.get(subscriberId);
                if (subscriber.type === _commonState.types.user) {
                    return {
                        avatar: subscriber.avatar,
                        isUser: true,
                        isUserGroup: false,
                        sortName: subscriber.name,
                        toolTipText: subscriber.name,
                        userId: subscriber.id,
                    };
                }
                if (subscriber.type === _commonState.types.userGroup) {
                    const groupName = subscriber.name;
                    const groupMembers = subscriber.members()
                        .filter(member => _commonState.get(member).isSelectable())
                        .map(member => {
                            return {
                                name: _commonState.get(member).name,
                                id: member
                            };
                        });

                    sorter.sort(groupMembers, "name", true);

                    const groupMembersLastIndex = groupMembers.length - 1;
                    const toolTipText = ko.observable([
                        {
                            style: "bold",
                            text: groupName,
                            carriageReturn: true
                        },
                        ...groupMembers.map((member, index) => {
                            const isLastGroupMember = index === groupMembersLastIndex;
                            return {
                                style: "standard",
                                text: ko.pureComputed(() => isLastGroupMember ?
                                    member.name() :
                                    member.name() + ","
                                ),
                                carriageReturn: true
                            };
                        })
                    ]);

                    return {
                        avatar: subscriber.avatars,
                        isUser: false,
                        isUserGroup: true,
                        sortName: groupName,
                        toolTipText: toolTipText,
                        userId: subscriber.id,
                    };
                }
            });
            sorter.sort(subscribers, "sortName", true);
            return subscribers;
        });

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = (settings) => {
            _settings = settings;
            _callDetails = _settings.callDetails;
            _voicemail = _callDetails().voicemail;
            _recording = _callDetails().recording;

            if (_voicemail.status === "success") {
                _subscriberIds = _voicemail.subscriberIds ? _voicemail.subscriberIds : _subscriberIds;
            }
            if (_recording.status === "success") {
                _subscriberIds = _recording.subscriberIds ? _recording.subscriberIds : _subscriberIds;
            }

            if (_settings.showCallMedia !== undefined) {
                _showCallMedia = _settings.showCallMedia;
            }

            _initialize();
        };

        const _initialize = () => {

            self.callDetails = _callDetails;
            self.voicemail(_voicemail);
            self.recording(_recording);
            self.showCallMedia(_showCallMedia);
        };
    };
});
