define('common/calendar/monthList',[],function() {
    return function() {
        var self = this;

        self.monthList = [
            {
                name: "January",
                monthAbbreviation: "Jan",
                value: "01"
            },
            {
                name: "February",
                monthAbbreviation: "Feb",
                value: "02"
            },
            {
                name: "March",
                monthAbbreviation: "Mar",
                value: "03"
            },
            {
                name: "April",
                monthAbbreviation: "Apr",
                value: "04"
            },
            {
                name: "May",
                monthAbbreviation: "May",
                value: "05"
            },
            {
                name: "June",
                monthAbbreviation: "June",
                value: "06"
            },
            {
                name: "July",
                monthAbbreviation: "July",
                value: "07"
            },
            {
                name: "August",
                monthAbbreviation: "Aug",
                value: "08"
            },
            {
                name: "September",
                monthAbbreviation: "Sept",
                value: "09"
            },
            {
                name: "October",
                monthAbbreviation: "Oct",
                value: "10"
            },
            {
                name: "November",
                monthAbbreviation: "Nov",
                value: "11"
            },
            {
                name: "December",
                monthAbbreviation: "Dec",
                value: "12"
            },
        ];
    };
});

