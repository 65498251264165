define('presentation/settings/phoneNumbers/validators/addPhoneNumberPortViewModelValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'presentation/common/validation/validPhoneNumberSpecification',
        'businessServices/specifications/validPostalCodeSpecification',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'constants/countryEnumerations'
    ],
    function() {
        return function() {
            const self = this;

            let _commonValidator = null;
            let _facade = null;
            let _viewModel = null;

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
            const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

            const ValidPostalCodeConstructor = require('businessServices/specifications/validPostalCodeSpecification');
            const _validPostalCodeSpecification = new ValidPostalCodeConstructor();

            const _countryEnumerations = require('constants/countryEnumerations');

            self.registerViewModel = function(viewModel, facade) {
                _viewModel = viewModel;
                _facade = facade;

                let validationRules = new ValidationRulesConstructor();
                validationRules.field("currentCarrier")
                    .addValidationI18n(_isFieldPresent, 'addPhoneNumberPort:carrierRequired');
                validationRules.field("billingInfoName")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'addPhoneNumberPort:billingNameRequired');
                validationRules.field("billingInfoNumber")
                    .addValidationI18n(_isFieldPresent, 'addPhoneNumberPort:billingNumberRequired')
                    .addValidationI18n(_validatePhoneNumberLength, 'addPhoneNumberPort:billingNumberLength');
                validationRules.field("billingInfoAddress")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'addPhoneNumberPort:billingAddressRequired');
                validationRules.field("billingInfoCity")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'addPhoneNumberPort:billingCityRequired');
                validationRules.field("billingInfoPostalCode")
                    .addValidationI18n(_isFieldPresent, _validatePostalCodeI18nKey)
                    .addValidationI18n(_validatePostalCodeValid, 'addPhoneNumberPort:postalCodeInvalid')
                    .addValidationI18n(_validatePostalCodeExists, 'addPhoneNumberPort:postalCodeInvalid')
                    .addValidationI18n(_validateZipCodeValid, 'addPhoneNumberPort:zipCodeInvalid')
                    .addValidationI18n(_validateZipCodeExists, 'addPhoneNumberPort:zipCodeInvalid');
                validationRules.field("portDate")
                    .addValidationI18n(_isDatePresent, 'addPhoneNumberPort:portDateRequired');
                validationRules.field("authorizedSignature")
                    .addValidationI18n(_isFieldPresent, 'addPhoneNumberPort:authorizedSignature');

                _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules, true);
            };

            self.validate = () => _commonValidator.validate();

            const _isFieldPresent = (fieldValue) => {
                return _commonValidator.isStringWithValue(fieldValue);
            };

            const _validatePhoneNumberLength = (phoneNumber) => {
                if (phoneNumber) {
                    let cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                    return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
                }
                return true;
            };

            const _validatePostalCodeValid = (postalCode) => {
                if (_viewModel.billingInfoCountry() === _countryEnumerations.ca.toUpperCase()) {
                    let countryCode = _viewModel.billingInfoCountry().toLowerCase();
                    return _validPostalCodeSpecification.isSatisfiedBy(postalCode, countryCode);
                }
                return true;
            };

            const _validatePostalCodeExists = (postalCode) => {
                return _promiseFactory.defer((deferredResult) => {
                    if (_viewModel.billingInfoCountry() === _countryEnumerations.ca.toUpperCase()) {
                        _validPostalCodeSpecification.doesPostalCodeExist(postalCode)
                            .fail(deferredResult.reject)
                            .done(deferredResult.resolve);
                    }
                    deferredResult.resolve(true);
                });
            };

            const _validateZipCodeValid = (zipCode) => {
                if (_viewModel.billingInfoCountry() === _countryEnumerations.usa.toUpperCase()) {
                    let countryCode = _viewModel.billingInfoCountry().toLowerCase();
                    return _validPostalCodeSpecification.isSatisfiedBy(zipCode, countryCode);
                }
                return true;
            };

            const _validateZipCodeExists = (zipCode) => {
                return _promiseFactory.defer((deferredResult) => {
                    if (_viewModel.billingInfoCountry() === _countryEnumerations.usa.toUpperCase()) {
                        _validPostalCodeSpecification.doesPostalCodeExist(zipCode)
                            .fail(deferredResult.reject)
                            .done(deferredResult.resolve);
                    }
                    deferredResult.resolve(true);
                });
            };

            const _isDatePresent = (dateValue) => {
                return _commonValidator.isStringWithValue(dateValue.toString());
            };

            const _validatePostalCodeI18nKey = ko.pureComputed(() => {
                if (_viewModel.billingInfoCountry() === _countryEnumerations.ca.toUpperCase()) {
                    return 'addPhoneNumberPort:postalCodeRequired';
                }  else {
                    return 'addPhoneNumberPort:zipCodeRequired';
                }
            });
        };
    });

