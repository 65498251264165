define('presentation/settings/userGroupMembership/presentationObjects/userGroupPresentationObject',['common/storage/commonState'],
function() {
    return function(){
        const self = this;

        const _commonState = require('common/storage/commonState');

        self.id = null;
        self.groupId = ko.observable("");
        self.isGroupNameEnabled = ko.observable(true);
        self.groupName = ko.observable("");
        self.isDeletable = ko.observable(true);
        self.type = ko.observable(_commonState.types.userGroup);
        self.avatar = true;
        self.avatarType = _commonState.types.userGroup;
        self.members = null;
        self.isReadOnly = ko.pureComputed(() => self.isDeletable() === false);
        self.removeToolTipText = "";
    };
});

