define('businessServices/converters/phoneNumberFormatToPhoneNumberConverter',['durandal/system',
    'businessServices/country/countryCode'
], function () {
    var REPLACEMENTS = [
        { foundCharacters: ["1"], replaceWith: "1" },
        { foundCharacters: ["A", "B", "C", "2"], replaceWith: "2" },
        { foundCharacters: ["D", "E", "F", "3"], replaceWith: "3" },
        { foundCharacters: ["G", "H", "I", "4"], replaceWith: "4" },
        { foundCharacters: ["J", "K", "L", "5"], replaceWith: "5" },
        { foundCharacters: ["M", "N", "O", "6"], replaceWith: "6" },
        { foundCharacters: ["P", "Q", "R", "S", "7"], replaceWith: "7" },
        { foundCharacters: ["T", "U", "V", "8"], replaceWith: "8" },
        { foundCharacters: ["W", "X", "Y", "Z", '9'], replaceWith: "9" },
        { foundCharacters: ["0"], replaceWith: "0" }
    ];

    var _replaceCharacters = function (alphanumericPhoneNumber, countryAbbreviation) {
        // Take only alpha-numeric characters only
        var phoneNumber = alphanumericPhoneNumber.replace(/[^a-zA-Z0-9]/g, '');

        // Replace only when country code pattern is found in starting of the phone number
        var regEx = new RegExp('^' + findCountryNamePrefix(countryAbbreviation), 'gi');
        var countryCodeRemovedPhoneNumber = phoneNumber.replace(regEx, '');
        var replacedPhoneNumber = "";

        for (var x = 0; x < countryCodeRemovedPhoneNumber.length; x++) {
            var characterToReplace = countryCodeRemovedPhoneNumber[x];
            var replacedCharacter = findReplacement(characterToReplace);
            replacedPhoneNumber = replacedPhoneNumber + replacedCharacter;
        }

        return replacedPhoneNumber;
    };

    var CountryCodeConstructor = require('businessServices/country/countryCode');
    var countryCodes = new CountryCodeConstructor();

    var findCountryNamePrefix = function (countryAbbreviation) {
        const country = countryCodes.findByAbbreviation(countryAbbreviation);
        if (country) {
            return country.countryCode;
        }
        return "";
    };

    var findReplacement = function (character) {
        var uppercaseCharacter = character.toUpperCase();

        for (var replacementIndex = 0; replacementIndex < REPLACEMENTS.length; replacementIndex++) {
            var replacement = REPLACEMENTS[replacementIndex];
            for (var foundCharacterIndex = 0; foundCharacterIndex < replacement.foundCharacters.length; foundCharacterIndex++) {
                if (replacement.foundCharacters[foundCharacterIndex] === uppercaseCharacter) {
                    return replacement.replaceWith;
                }
            }
        }

        return "";
    };

    return function () {
        var self = this;
        self.convert = _replaceCharacters;
    };
});
