define('constants/creditCardTypeEnumerations',[], function() {
    return {
        amex : {
            type: "AMEX",
            displayCardName: "American Express",
            displayCardNameAbbreviation: "AMEX",
            validatingDigits: ["34", "37"],
            cssClass: "amex",
            maskedPrefix: "XXXX XXXXXX X"
        },
        mastercard: {
            type: "MASTERCARD",
            displayCardName: "Mastercard",
            displayCardNameAbbreviation: "MASTERCARD",
            validatingDigits: ["51", "52", "53", "54", "55", "22", "23", "24", "25", "26", "27"],
            cssClass: "mastercard",
            maskedPrefix: "XXXX XXXX XXXX "
        },
        americanExpress : {
            type: "AMEX",
            displayCardName: "American Express",
            displayCardNameAbbreviation: "AMEX",
            validatingDigits: ["34", "37"],
            cssClass: "amex",
            maskedPrefix: "XXXX XXXXXX X"
        },
        discover: {
            type: "DISCOVER",
            displayCardName: "Discover",
            displayCardNameAbbreviation: "DISCOVER",
            validatingDigits: ["6011"],
            cssClass: "discover",
            maskedPrefix: "XXXX XXXX XXXX "
        },
        masterCard: {
            type: "MASTERCARD",
            displayCardName: "Mastercard",
            displayCardNameAbbreviation: "MASTERCARD",
            validatingDigits: ["51", "52", "53", "54", "55", "22", "23", "24", "25", "26", "27"],
            cssClass: "mastercard",
            maskedPrefix: "XXXX XXXX XXXX "
        },
        visa: {
            type: "VISA",
            displayCardName: "Visa",
            displayCardNameAbbreviation: "VISA",
            validatingDigits: ["4"],
            cssClass: "visa",
            maskedPrefix: "XXXX XXXX XXXX "
        }
    };
});

