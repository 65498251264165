define('presentation/forgotPassword/validators/forgotPasswordValidator',[
    'common/promises/promiseFactory',
    'common/specifications/validEmailAddressSpecification',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function () {
    return function () {
        const self = this;

        let _forgotPasswordViewModel = null;
        let _forgotPasswordFacade = null;
        let _commonValidator = null;

        const ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        const _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        self.registerViewModel = (forgotPasswordViewModel, forgotPasswordFacade) => {
            const validationRules = new ValidationRulesConstructor();

            validationRules.field("emailAddress")
                .addValidationI18n(_validateEmailAddress, 'forgotPassword:emailAddressRequired')
                .addValidationI18n(_validateEmailAddressFormatting, 'forgotPassword:emailAddressInvalid')
                .addValidationI18n(_isEmailAddressRegistered, 'forgotPassword:emailAddressNotRegistered')
                .addValidationI18n(_isEmailAddressNotInvitePending, 'forgotPassword:emailAddressInvitePending')
                .addValidationI18n(_isEmailAddressNotInviteExpired, 'forgotPassword:emailAddressInviteExpired')
                .addValidationI18n(_isEmailAddressNotInviteCanceled, 'forgotPassword:emailAddressInviteCanceled')
                .addValidationI18n(_isUserActive, 'forgotPassword:userDeactivated');

            _commonValidator = new CommonValidatorConstructor(forgotPasswordViewModel, validationRules);
            _forgotPasswordViewModel = forgotPasswordViewModel;
            _forgotPasswordFacade = forgotPasswordFacade;
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateEmailAddressFormatting = (emailAddress) => {
            return _validEmailAddressSpecification.isSatisfiedBy(emailAddress.trim());
        };

        const _validateEmailAddress = (emailAddress) => {
            return _commonValidator.isStringWithValue(emailAddress);
        };

        const _isEmailAddressRegistered = () => {
            const emailStatus = _forgotPasswordViewModel.emailAddressStatus;
            _forgotPasswordViewModel.isResendInviteLinkVisible(emailStatus === "invite_pending");
            return emailStatus !== "email_not_found";
        };

        const _isEmailAddressNotInvitePending = () => {
            return _forgotPasswordViewModel.emailAddressStatus !== "invite_pending";
        };

        const _isEmailAddressNotInviteExpired = () => {
            return _forgotPasswordViewModel.emailAddressStatus !== "invite_expired";
        };

        const _isEmailAddressNotInviteCanceled = () => {
            return _forgotPasswordViewModel.emailAddressStatus !== "invite_canceled";
        };

        const _isUserActive = () => {
            return _forgotPasswordViewModel.isUserDeactivated() === false;
        };
    };
});

