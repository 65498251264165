define('presentation/settings/messagingCompliance/validators/messagingComplianceTollFreeMessagingTabValidator',[
    'common/collections/enumerationValidator',
    'constants/messagingTextsPerDayEnumerations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
], function(
    /** @type import('common/collections/enumerationValidator') */
    _enumerationValidator,
    /** @type typeof import('constants/messagingTextsPerDayEnumerations') */
    _messagingTextsPerDay,
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules
) {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/viewModels/messagingComplianceTollFreeMessagingTabViewModel') } MessagingComplianceTollFreeMessagingTabViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { MessagingComplianceTollFreeMessagingTabViewModel } */
        let _viewModel = null;

        self.registerViewModel = (/** @type { MessagingComplianceTollFreeMessagingTabViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.field("messageTextComplianceError")
                .addValidationI18n(_validateMessageTextCompliance, 'messagingComplianceTollFreeMessagingTab:textMessagingComplianceRequired');
            validationRules.field("summaryOfTexting")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceTollFreeMessagingTab:summaryOfTextingRequired')
                .addValidationI18n(_validateSummaryOfTexting, 'messagingComplianceTollFreeMessagingTab:summaryOfTextingInvalid');
            validationRules.field("sampleTextMessageOne")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageOne, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageInvalid');
            validationRules.field("sampleTextMessageTwo")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageTwo, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageInvalid');
            validationRules.field("sampleTextMessageThree")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageThree, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageInvalid');
            validationRules.field("sampleTextMessageFour")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageFour, 'messagingComplianceTollFreeMessagingTab:sampleTextMessageInvalid');
            validationRules.field("messagesPerDay")
                .addValidationI18n(_validateMessagesPerDayRequired, 'messagingComplianceTollFreeMessagingTab:messagingTextsPerDayRequired');
            validationRules.field("majorityOfMessagesSentToCanada")
                .addValidationI18n(_validateMajorityOfMessagesSentToCanadaRequired, 'messagingComplianceTollFreeMessagingTab:majorityOfMessagesSentToCanadaRequired');
            validationRules.field("customerConsentOther")
                .addValidationI18n(_validateCustomerConsentOtherRequired, 'messagingComplianceTollFreeMessagingTab:labelCustomerConsentOtherRequired')
                .addValidationI18n(_validateCustomerConsentOtherInvalid, 'messagingComplianceTollFreeMessagingTab:labelCustomerConsentOtherInvalid');
            validationRules.field("messageTextConsentError")
                .addValidationI18n(_validateMessageTextConsent, 'messagingComplianceTollFreeMessagingTab:textMessagingConsentRequired');
            validationRules.field("isUseEligibilityAcknowledged")
                .addValidationI18n(_validateUseEligibility, 'messagingComplianceTollFreeMessagingTab:useCaseEligibilityRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        self.resetValidation = () => {
            _viewModel.messageTextComplianceError.resetValidation();
            _viewModel.summaryOfTexting.resetValidation();
            _viewModel.sampleTextMessageOne.resetValidation();
            _viewModel.sampleTextMessageTwo.resetValidation();
            _viewModel.sampleTextMessageThree.resetValidation();
            _viewModel.sampleTextMessageFour.resetValidation();
            _viewModel.messagesPerDay.resetValidation();
            _viewModel.majorityOfMessagesSentToCanada.resetValidation();
            _viewModel.customerConsentOther.resetValidation();
            _viewModel.messageTextConsentError.resetValidation();
            _viewModel.isUseEligibilityAcknowledged.resetValidation();
        };

        const _validateMessageTextCompliance = (messageTextComplianceError) => {
            return _viewModel.selectedUseCases().length > 0;
        };

        const _validateSummaryOfTexting = (summaryOfTexting) => {
            return _commonValidator.isStringWithLength(summaryOfTexting, 40, 3000);
        };

        const _validateSampleTextMessageOne = (sampleTextMessageOne) => {
            return _commonValidator.isStringWithLength(sampleTextMessageOne, 20, 3000);
        };

        const _validateSampleTextMessageTwo = (sampleTextMessageTwo) => {
            return _commonValidator.isStringWithLength(sampleTextMessageTwo, 20, 3000);
        };

        const _validateSampleTextMessageThree = (sampleTextMessageThree) => {
            return _commonValidator.isStringWithLength(sampleTextMessageThree, 20, 3000);
        };

        const _validateSampleTextMessageFour = (sampleTextMessageFour) => {
            return _commonValidator.isStringWithLength(sampleTextMessageFour, 20, 3000);
        };

        const _validateMessagesPerDayRequired = (messagesPerDay) => {
            if (messagesPerDay === "pleaseSelect") {
                return false;
            }
            return _enumerationValidator.isEnumerationValueValid(_messagingTextsPerDay, parseInt(messagesPerDay));
        };

        const _validateMajorityOfMessagesSentToCanadaRequired = (majorityOfMessagesSentToCanada) => {
            if (majorityOfMessagesSentToCanada) {
                return majorityOfMessagesSentToCanada !== "pleaseSelect";
            }
            return false;
        };

        const _validateCustomerConsentOtherRequired = (customerConsentOther) => {
            if (_viewModel.isCustomerConsentOther()) {
                return _commonValidator.isStringWithValue(customerConsentOther);
            }
            return true;
        };

        const _validateCustomerConsentOtherInvalid = (customerConsentOther) => {
            if (_viewModel.isCustomerConsentOther()) {
                return _commonValidator.isStringWithLength(customerConsentOther, 40, 3000);
            }
            return true;
        };

        const _validateMessageTextConsent = (messageTextConsentError) => {
            if (!_viewModel.isCustomerConsentLeadCaptureForms() &&
                !_viewModel.isCustomerConsentInitiateConversation() &&
                !_viewModel.isCustomerConsentEmployeeRelationship() &&
                !_viewModel.isCustomerConsentOnlineCheckout() &&
                !_viewModel.isCustomerConsentPosTransaction() &&
                !_viewModel.isCustomerConsentOther()) {
                return false;
            }
            return true;
        };

        const _validateUseEligibility = (useEligibility) => {
            return _viewModel.isUseEligibilityAcknowledged();
        };
    };
});

