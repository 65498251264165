define('businessServices/authentication/stores/standardAuthenticationStore',[
    'common/storage/machineStorage',
], function(machineStorage) {
    var KEY = 'tresta-auth';

    var _isStandard = null;
    function receiveAlertLoginSuccessful(loginInfo) {
        if (loginInfo.loginType === "standard") {
            _isStandard = true;
            var value = {
                token : loginInfo.authenticationToken,
                userId : loginInfo.userId,
                accountId : loginInfo.accountId
            };
            saveValue(value);
        } else {
            _isStandard = false;
        }
    }

    function receiveAlertLogout() {
        if (_isStandard === true) {
            deleteValue();
            _isStandard = null;
        }
    }

    function hasValue() {
        return machineStorage.hasObject(KEY);
    }

    function getValue() {
        return machineStorage.getObject(KEY);
    }

    function saveValue(value) {
        machineStorage.saveObject(KEY, value);
    }

    function deleteValue() {
        machineStorage.deleteObject(KEY);
    }

    return {
        hasValue : hasValue,
        getValue : getValue,
        saveValue : saveValue,
        deleteValue : deleteValue,
        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});
