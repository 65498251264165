define('constants/messageDirection',[], () => {
    return Object.freeze({
        /**
         * Message sent from an outside source to the hosted number
         */
        incoming: 1,
        /**
         * Message sent from the hosted number to an outside recipient
         */
        outgoing: 2
    });
});
