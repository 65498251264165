define('presentation/analytics/facades/analyticsAvatarsAndNamesSingleton',[], function () {
    const AnalyticsAvatarsAndNamesSingleton = function () {
        /** @type import('presentation/analytics/facades/analyticsAvatarsAndNamesSingleton') */
        const self = this;
        if (AnalyticsAvatarsAndNamesSingleton.prototype._singletonInstance) {
            return AnalyticsAvatarsAndNamesSingleton.prototype._singletonInstance;
        }
        AnalyticsAvatarsAndNamesSingleton.prototype._singletonInstance = self;

        self.avatarsAndNames = {};
        self.areForwardingNumbersLoaded = ko.observable(false);

        /** @type {self['set']} */
        self.set = (
            ({
                 avatar = '',
                 id,
                 iconName = '',
                 isForwardingNumber = false,
                 isHostedNumber = false,
                 isUser = false,
                 isUserGroup = false,
                 location,
                 name,
                 tooltip = null
             }) => self.avatarsAndNames[id] = {
                avatar,
                id,
                iconName,
                isForwardingNumber,
                isHostedNumber,
                isUser,
                isUserGroup,
                location,
                name,
                tooltip
            }
        );

        /** @type {self['get']} */
        self.get = (id) => self.avatarsAndNames[id];
    };
    return new AnalyticsAvatarsAndNamesSingleton();
});
