define('presentation/settings/schedules/viewModels/addScheduleViewModel',[
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'common/uniqueId/guidUtil',
    'common/uniqueId/userGroupIdUtil',
    'constants/autoAttendantVoiceRouteEnumerations',
    'presentation/common/routeToModal/viewModels/routeToViewModel',
    'presentation/common/routeToModal/strategies/scheduleSegmentStrategy',
    'presentation/settings/schedules/facades/addScheduleFacade',
    'presentation/settings/schedules/validators/addScheduleViewModelValidator',
    'presentation/settings/schedules/viewModels/scheduleCommonFunctions',
    'presentation/settings/schedules/viewModels/scheduleRangeViewModel',
    'settings/navigationConfiguration'
], function () {
    const _scheduleCommonFunctions = require('presentation/settings/schedules/viewModels/scheduleCommonFunctions');
    const _navigationConfiguration = require('settings/navigationConfiguration');

    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const RouteToStrategyConstructor = require('presentation/common/routeToModal/strategies/scheduleSegmentStrategy');
        const RouteToViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToViewModel');
        const ScheduleRangeViewModelConstructor = require('presentation/settings/schedules/viewModels/scheduleRangeViewModel');
        const _i18n = require('i18next');
        const _router = require('businessServices/router/router');

        let _availableRoutes = null;
        let _disposables = [];
        let _facade = null;
        let _timeZone = null;
        let _validator = null;

        self.defaultRoutingRuleViewModel = ko.observable();
        self.routeToOptions = {};
        self.routeToStrategy = new RouteToStrategyConstructor();
        self.schedulesUrl = _navigationConfiguration.routesById.schedules.url;

        self.scheduleMapDayLabels = _scheduleCommonFunctions.scheduleMapDayLabels;
        self.scheduleMapTimeLabels = _scheduleCommonFunctions.scheduleMapTimeLabels;

        self.sundayGridCells = [];
        self.mondayGridCells = [];
        self.tuesdayGridCells = [];
        self.wednesdayGridCells = [];
        self.thursdayGridCells = [];
        self.fridayGridCells = [];
        self.saturdayGridCells = [];

        class GridCell {
            constructor() {
                this.inRange = ko.observable();
                this.rangeColor = ko.computed(() => {
                    const currentRange = this.inRange();
                    const scheduleSequence = self.ranges().length;

                    if (currentRange === 0 ) {
                        return "range-background__sequence-all-other";
                    }

                    if (scheduleSequence >= 1 && scheduleSequence <= 3){
                        if (currentRange === 1) {
                            return "range-background__sequence-1";
                        } else if (currentRange === 2) {
                            return "range-background__sequence-3";
                        } else {
                            return "range-background__sequence-6";
                        }
                    }

                    if (scheduleSequence >= 4) {
                        return `range-background__sequence-${currentRange}`;
                    } else {
                        return "range-background__sequence-all-other";
                    }
                });
            }
        }

        const _populateScheduleGridCells = () => {
            const totalQuarterHours = 96;
            const scheduleMapGridCells = [
                self.sundayGridCells,
                self.mondayGridCells,
                self.tuesdayGridCells,
                self.wednesdayGridCells,
                self.thursdayGridCells,
                self.fridayGridCells,
                self.saturdayGridCells
            ];
            scheduleMapGridCells.forEach((weekdayGridCells) => {
                for (let c = 1; c <= totalQuarterHours; c++) {
                    let cell = new GridCell();
                    weekdayGridCells.push(cell);
                }
            });
            _getScheduleGridRanges();
        };

        const applyEndHourMeridiem = (hour, minute, meridiem) => {
            if (hour === 12 && meridiem === "am" && minute === 0) {
                return 24;
            } else {
                return applyHourMeridiem(hour, meridiem);
            }
        };

        const applyHourMeridiem = (hour, meridiem) => {
            if (meridiem === "pm") {
                if (hour === 12) {
                    // 12pm = 12
                    return 12;
                } else {
                    // 1pm = 13
                    return hour + 12;
                }
            }
            if (hour === 12) {
                // 12 am = 0
                return 0;
            }
            return hour;
        };

        const _resetGridRanges = () => {
            for (let r = 0; r <= 95; r++) {
                self.sundayGridCells[r].inRange(0);
                self.mondayGridCells[r].inRange(0);
                self.tuesdayGridCells[r].inRange(0);
                self.wednesdayGridCells[r].inRange(0);
                self.thursdayGridCells[r].inRange(0);
                self.fridayGridCells[r].inRange(0);
                self.saturdayGridCells[r].inRange(0);
            }
        };

        const _getScheduleGridRanges = () => {
            _resetGridRanges();
            const ranges = self.ranges();

            ranges.forEach((range) => {
                const startMinute = range.currentStartTime().min;
                const startHour = applyHourMeridiem(range.currentStartTime().hour, range.currentStartTime().meridiem);
                const endMinute = range.currentEndTime().min;
                const endHour = applyEndHourMeridiem(range.currentEndTime().hour, endMinute, range.currentEndTime().meridiem);

                const rangeStartCell = _scheduleCommonFunctions.getGridCell(startHour, startMinute);
                const rangeEndCell = _scheduleCommonFunctions.getGridCell(endHour, endMinute) - 1 ;
                const rangeNumber = range.sequence();

                if (range.isSundaySelected()) {
                    for (let s = rangeStartCell; s <= rangeEndCell; s++ ){
                        self.sundayGridCells[s].inRange(rangeNumber);
                    }
                }
                if (range.isMondaySelected()) {
                    for (let m = rangeStartCell; m <= rangeEndCell; m++ ){
                        self.mondayGridCells[m].inRange(rangeNumber);
                    }
                }
                if (range.isTuesdaySelected()) {
                    for (let t = rangeStartCell; t <= rangeEndCell; t++ ){
                        self.tuesdayGridCells[t].inRange(rangeNumber);
                    }
                }
                if (range.isWednesdaySelected()) {
                    for (let w = rangeStartCell; w <= rangeEndCell; w++ ){
                        self.wednesdayGridCells[w].inRange(rangeNumber);
                    }
                }
                if (range.isThursdaySelected()) {
                    for (let r = rangeStartCell; r <= rangeEndCell; r++ ){
                        self.thursdayGridCells[r].inRange(rangeNumber);
                    }
                }
                if (range.isFridaySelected()) {
                    for (let f = rangeStartCell; f <= rangeEndCell; f++ ){
                        self.fridayGridCells[f].inRange(rangeNumber);
                    }
                }
                if (range.isSaturdaySelected()) {
                    for (let st = rangeStartCell; st <= rangeEndCell; st++ ){
                        self.saturdayGridCells[st].inRange(rangeNumber);
                    }
                }
            });
        };

        self.rangeIndicatorClass = (sequence) => {
            const rangeSequence = sequence();
            const scheduleSequence = self.ranges().length;

            if (rangeSequence === 0 ) {
                return "range-background__sequence-all-other";
            }

            if (scheduleSequence >= 1 && scheduleSequence <= 3){
                if (rangeSequence === 1) {
                    return "range-background__sequence-1";
                } else if (rangeSequence === 2) {
                    return "range-background__sequence-3";
                } else {
                    return "range-background__sequence-6";
                }
            }

            if (scheduleSequence >= 4) {
                return `range-background__sequence-${rangeSequence}`;
            } else {
                return "range-background__sequence-all-other";
            }
        };

        self.scheduleId = null;
        self.name = ko.observable('').extend({observeState: true});
        self.isNewSchedule = ko.observable(false);

        const buildDefaultRoutingRuleViewModel = (routeToType, routeToData) => {
            const routeToViewModel = new RouteToViewModelConstructor(routeToType, routeToData, _validateRouteToViewModelCallback, {schedule: {enabled: false}});
            routeToViewModel.routeToOptions.subMenu.enabled = false;
            routeToViewModel.routeToStrategy = self.routeToStrategy;
            routeToViewModel.showAnswerTimeoutOptions(false);
            routeToViewModel.routeTitle(_i18n.t("addSchedule:routeToTitle"));
            routeToViewModel.showRouteToTitle(true);
            routeToViewModel.showRouteToOptions(true);
            routeToViewModel.parentViewModel(self);
            return routeToViewModel;
        };

        self.canDisplayValidation = ko.observable(false);
        self.isDefaultRoutingRuleValid = ko.observable(true);

        const _validateRouteToViewModelCallback = () => {
            self.canDisplayValidation(false);
            return true;
        };

        const _subscribeToRangeObservables = (range) => {
            _disposables.push(range.sequence.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isSundaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isMondaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isTuesdaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isWednesdaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isThursdaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isFridaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.isSaturdaySelected.subscribe(_getScheduleGridRanges));
            _disposables.push(range.currentStartTime.subscribe(_getScheduleGridRanges));
            _disposables.push(range.currentEndTime.subscribe(_getScheduleGridRanges));
        };

        const _populateNewScheduleForm = () => {
            return _promiseFactory.defer((promise) => {
                const range = new ScheduleRangeViewModelConstructor(self, null, _timeZone, _availableRoutes);
                const sequence = self.ranges().length + 1;
                range.isMondaySelected(true);
                range.isTuesdaySelected(true);
                range.isWednesdaySelected(true);
                range.isThursdaySelected(true);
                range.isFridaySelected(true);
                range.sequence(sequence);
                self.ranges.push(range);

                _subscribeToRangeObservables(range);
                self.defaultRoutingRuleViewModel(buildDefaultRoutingRuleViewModel('autoAttendant', {autoAttendantId : "[None]"}));
                self.modelStateObserver.addChildObserver(self.defaultRoutingRuleViewModel().modelStateObserver);
                promise.resolve();
            });
        };

        const _populateExistingScheduleForm = () => {
            return _promiseFactory.defer((promise) => {

                _facade.get(self.scheduleId)
                    .fail(promise.reject)
                    .done((result) => {
                        const schedule = result.schedule;
                        self.name(schedule.name);
                        const defaultRoutingRuleViewModel = buildDefaultRoutingRuleViewModel('routingRule', schedule.defaultRoutingRule);
                        self.defaultRoutingRuleViewModel(defaultRoutingRuleViewModel);
                        self.modelStateObserver.addChildObserver(defaultRoutingRuleViewModel.modelStateObserver);

                        let segments = result.segments;
                        segments.sort((seg1, seg2) => seg1.sequence - seg2.sequence);
                        segments.forEach((segment) => {
                            const range = new ScheduleRangeViewModelConstructor(self, segment, _timeZone, _availableRoutes);
                            self.ranges.push(range);
                            self.modelStateObserver.addChildObserver(range.modelStateObserver);
                            _subscribeToRangeObservables(range);
                        });

                        promise.resolve();
                    });
            });
        };

        self.headerI18nKey = ko.pureComputed(() => self.scheduleId ? "addSchedule:headerEdit" : "addSchedule:headerAdd");
        self.saveButtonTitle = ko.pureComputed(() => self.scheduleId ? _i18n.t('addSchedule:save') : _i18n.t('addSchedule:add'));
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.isCompositionComplete = ko.observable(false);

        self.ranges = ko.observableArray([]).extend({observeState: true});

        self.addAnotherRange = () => {
            const range = new ScheduleRangeViewModelConstructor(self, null, _timeZone, _availableRoutes);
            const sequence = self.ranges().length + 1;
            range.sequence(sequence);
            self.ranges.push(range);
            self.modelStateObserver.addChildObserver(range.modelStateObserver);
            _subscribeToRangeObservables(range);
            _getScheduleGridRanges();
        };

        self.canAddAnotherRange = ko.pureComputed(() => self.ranges().length < 9);
        self.canDeleteRange = ko.computed(() => self.ranges().length > 1);
        self.addAnotherMessage = _i18n.t('addSchedule:maxRanges');

        self.deleteRange = (range) => {
            if (self.canDeleteRange() === false) {
                return;
            }

            self.ranges.remove(range);
            self.ranges().forEach((range, index) => range.sequence(index + 1));
            _getScheduleGridRanges();
        };

        self.invalidRangeNames = ko.observableArray([]);
        self.segmentValidationError = ko.observable(false);
        self.warningMessages = ko.pureComputed(() => {
            return [
                {
                    message: _i18n.t('addSchedule:validationTabDescription'),
                    listItems: self.invalidRangeNames()
                },
                {
                    message: _i18n.t('addSchedule:validationPleaseFix'),
                    listItems: []
                }
            ];
        });

        const _isValidToSave = () => {
            return _promiseFactory.defer((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((isValid) => {
                        self.invalidRangeNames.removeAll();
                        const rangesValidatorPromiseFactory = new PromiseFactoryConstructor();
                        let areAllRangesValid = isValid;
                        self.ranges().forEach((range) => {
                            rangesValidatorPromiseFactory.defer(rangesValidatorPromise => {
                                range.validator.validate()
                                    .fail(rangesValidatorPromise.reject)
                                    .done(isRangeValid => {
                                        if (!isRangeValid) {
                                            if (range.rangeName() === "") {
                                                self.invalidRangeNames.push(_i18n.t('addSchedule:unnamedSegment'));
                                            } else {
                                                self.invalidRangeNames.push(range.rangeName());
                                            }
                                        }

                                        self.canDisplayValidation(true);
                                        range.canDisplayValidation(true);
                                        areAllRangesValid = areAllRangesValid && isRangeValid;
                                        rangesValidatorPromise.resolve();
                                    });
                            });
                        });

                        rangesValidatorPromiseFactory.wait()
                            .fail(promise.reject)
                            .done(() => {
                                if (!isValid) {
                                    self.invalidRangeNames.push(_i18n.t('addSchedule:otherTimes'));
                                }

                                self.segmentValidationError(!areAllRangesValid);
                                promise.resolve(areAllRangesValid);
                            });
                    });
            });
        };

        self.shouldScrollTop = ko.observable(false);

        self.validate = () => _validator.validate();

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                _isValidToSave()
                    .fail(promise.reject)
                    .done((isValid) => {
                        self.shouldScrollTop(!isValid);
                        if (isValid === false) {
                            promise.resolve();
                            self.shouldScrollTop(false);
                            return;
                        }

                        const defaultRoutingRule = self.routeToStrategy.buildRoutingRule(self.defaultRoutingRuleViewModel());
                        const scheduleName = self.name().trim();
                        const ranges = self.ranges();
                        const segments = ranges.map(range => range.getSegment());

                        const facadePromise = self.scheduleId === null ?
                            _facade.create(scheduleName, segments, defaultRoutingRule) :
                            _facade.update(scheduleName, segments, defaultRoutingRule, self.scheduleId);

                        facadePromise
                            .fail(promise.reject)
                            .done(() => {
                                self.modelStateObserver.saveData();
                                _router.navigate(self.schedulesUrl);
                                promise.resolve();
                            });
                    });
            });
        };

        self.cancel = () => {
            self.modelStateObserver.navigateWithoutWarning = true;
            _router.navigate(self.schedulesUrl);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
            self.modelStateObserver.commitData();
            if (self.scheduleId === null) {
                self.isNewSchedule(true);
            }
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        self.activate = (scheduleId) => {
            if (scheduleId) {
                self.scheduleId = scheduleId;
            }

            const FacadeConstructor = require('presentation/settings/schedules/facades/addScheduleFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/schedules/validators/addScheduleViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.defer((allDonePromise) => {
                
                _facade.getTimeZone()
                    .fail(allDonePromise.reject)
                    .done(timeZone => {
                        _timeZone = timeZone;

                        let populatePromise;
                        if (self.scheduleId === null) {
                            populatePromise = _populateNewScheduleForm();
                        } else {
                            populatePromise = _populateExistingScheduleForm();
                        }

                        populatePromise.done(() => {
                            _populateScheduleGridCells();
                            _validator.registerViewModel(self, _facade);
                            allDonePromise.resolve();
                        });
                    });
                });
            
            return _promiseFactory.wait();
        };
    };
});
