define('presentation/navigation/viewModels/sidebarNavigationViewModel',[
    'businessServices/authentication/featureToggleSettings',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/events/eventManager',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'persistence/dataProviders/accountDataProvider',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        const _featureToggleSettings = require('businessServices/authentication/featureToggleSettings');

        let _accountDataProvider = null;
        let _eventManager = null;
        let _router = null;

        const buildDisplayName = (navigationItem) => {
            if (navigationItem.routeId) {
                return _i18n.t(`sidebarNavigation:${navigationItem.routeId}`);
            }

            switch (navigationItem.displayName){
                case "Billing":
                case "System":
                    return _i18n.t(`sidebarNavigation:${navigationItem.displayName.toLowerCase()}`);
                default:
                    return _i18n.t(`sidebarNavigation:userSettings`);
            }
        };

        const checkUserHasPermission = (permissionId) => {
            if (permissionId !== null) {
                const accountPermissions = _sessionAccountInfo.accountPermissions();
                if (accountPermissions === null) {
                    return false;
                } else {
                    return accountPermissions[permissionId] === true;
                }
            } else {
                return true;
            }
        };

        const filterChildRoutesByPermissions = (childRoutes) => {
            return childRoutes.filter((childRoute) => {
                if (childRoute.childRoutes !== undefined) {
                    childRoute.childRoutes = filterChildRoutesByPermissions(childRoute.childRoutes);
                }
                return checkUserHasPermission(childRoute.permission);
            });
        };

        const filterChildRoutesByFeatureToggle = (childRoutes) => {
            return childRoutes.filter((childRoute) => {
                return _featureToggleSettings.getById(childRoute.featureId);
            });
        };

        const filterSidebarItemsBySection = (items, section) => {
            return items.filter((item) => {
                return item.section !== undefined && item.section ===  section;
            });
        };

        const _updateNavigation = () => {
            let activeUrlFragment = _router.activeInstruction().config.hash;
            let activeParentRouteId = _router.activeInstruction().config.customSettings.parentItem;
            let activeSection = _router.activeInstruction().config.customSettings.section;

            let navigationModel = _router.navigationModel();
            let navigationItems = navigationModel.filter((navigationItem) => {
                if (navigationItem.customSettings.menuType === "Sidebar" &&
                    navigationItem.customSettings.section === activeSection &&
                    navigationItem.customSettings.childRoutes.length > 0) {
                    let permissionId = navigationItem.customSettings.permission;
                    return checkUserHasPermission(permissionId);
                }
                return false;
            });

            const showExclamation = (_hash) => {
                return false;
            };

            let navigationList = navigationItems.map((navigationItem) => {
                let navigationListItem = {
                    title: navigationItem.title,
                    displayName: buildDisplayName(navigationItem),
                    hash: navigationItem.hash,
                };

                let childRoutes = filterSidebarItemsBySection(navigationItem.customSettings.childRoutes, activeSection);

                let filteredChildRoutes = filterChildRoutesByFeatureToggle(
                    filterChildRoutesByPermissions(childRoutes)
                );

                navigationListItem.childRoutes = filteredChildRoutes.map((childRoute, i) => {
                    let isActiveItem = (
                        activeUrlFragment === childRoute.url ||
                        activeParentRouteId === childRoute.routeId
                    );
                    let isFirstItem = i === 0;
                    let isLastItem = i === filteredChildRoutes.length -1;
                    return {
                        hash: childRoute.url,
                        isActiveItem: ko.observable(isActiveItem),
                        icon: childRoute.icon,
                        routeId: childRoute.routeId,
                        isNested: childRoute.isNested,
                        showExclamation: showExclamation(childRoute.url),
                        isFirstItem: ko.observable(isFirstItem),
                        isLastItem: ko.observable(isLastItem),
                    };
                });

                return navigationListItem;
            });

            self.navigationList(navigationList);
        };

        self.navigationList = ko.observableArray([]);
        self.parentViewModel = null;
        self.pastDueBalance = ko.observable(0);

        self.toggleMenuExpansion = (navigationItem) => {
            navigationItem.settings.isExpanded(!navigationItem.settings.isExpanded());
        };

        self.navigateTo = (navItem) => {
            _router.navigate(navItem.hash);
        };

        const _resetHasBypassedPastDueBalance = (pastDueBalance) => {
            self.pastDueBalance(pastDueBalance);
        };


        self.activate = () => {
            _router = require('businessServices/router/router');
            _eventManager = require('businessServices/events/eventManager');

            const AccountDataProviderConstructor = require('persistence/dataProviders/accountDataProvider');
            _accountDataProvider = new AccountDataProviderConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _accountDataProvider.getBalanceInformation()
                .done((balanceData) => {
                    self.pastDueBalance(balanceData.pastDueBalance);
                });

            _eventManager.subscribePastDueBalance(_resetHasBypassedPastDueBalance);
            _router.activeItem.subscribe(_updateNavigation);

            _updateNavigation();

            return _promiseFactory.wait();
        };
    };
});


