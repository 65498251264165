define('presentation/numberPicker/presentationObjects/numberPickerPresentationObject',[
    'common/converters/phoneNumberFormatter',
    'constants/colorConstants'
], function() {
    const _colorConstants = require('constants/colorConstants');
    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    return function({phoneNumber, region, city, vanityString, humanReadablePhoneNumber}) {
        const self = this;

        const _e164PhoneNumber = phoneNumber ? _phoneNumberFormatter.toEOneSixFour(phoneNumber) : null;
        const _rawPhoneNumber = phoneNumber ? _phoneNumberFormatter.toNumbers(phoneNumber) : null;
        const _formattedPhoneNumber = humanReadablePhoneNumber ?
            humanReadablePhoneNumber :
            phoneNumber ?
                _phoneNumberFormatter.toUSAreaCode(phoneNumber) :
                null;

        const _region = region;
        const _city = city;

        self.e164PhoneNumber = () => _e164PhoneNumber;
        self.formattedPhoneNumber = () => _formattedPhoneNumber;
        self.rawPhoneNumber = () => _rawPhoneNumber;

        self.region = () => _region;
        self.city = () => _city;

        self.vanityString = ko.observable(vanityString ? vanityString : '');

        self.isSelected = ko.observable(false);
        self.isDisabled = ko.observable(false);
        self.isFailed = ko.observable(false);
        self.isChecked = ko.observable(false);
        self.isSpinning = ko.observable(false);

        self.hasLocation = ko.pureComputed(() => self.city() !== undefined && self.city() !== "");
        self.displayLocation = ko.pureComputed(() => {
            if (!self.hasLocation()) {
                return "";
            }

            const formattedRegion = self.region().substring(self.region().indexOf('-') + 1);

            return `${self.city()}, ${formattedRegion}`;
        });

        self.spinnerColor = ko.pureComputed(() => self.isSelected() ?
            _colorConstants.colorPrimaryWhite :
            _colorConstants.colorSecondaryCool200
        );

        self.setRegion = (region) => {
            self.region = () => region;
        };

        self.setCity = (city) => {
            self.city = () => city;
        };
    };
});

