define('presentation/signup/viewModels/signupPhoneNumberTypeViewModel',[
    'common/promises/promiseFactory',
    'common/storage/workSessionIdStorage',
    'common/url/urlFormatter',
    'constants/numberPickerConstants',
    'presentation/signup/common/signupNavigationManager',
    'presentation/signup/facades/signupPhoneNumberTypeFacade',
], function() {

    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _urlFormatter = require('common/url/urlFormatter');
        const _workSessionIdStorage = require('common/storage/workSessionIdStorage');
        const _isSignupForCustomer = _workSessionIdStorage.getWorkSessionId() !== null && _workSessionIdStorage.getWorkSessionId() !== undefined;

        const _numberPickerConstants = require('constants/numberPickerConstants');
        let _signupNavigationManager = null;

        let _facade = null;
        let _signupSession = null;

        self.isCompositionComplete = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.selectedCard = ko.observable(null);
        self.errorMessage = ko.observable('');
        self.showErrorMessage = ko.pureComputed(() => self.errorMessage() !== '');
        self.homeUrl = _urlFormatter.buildFrontendUrl("/");

        const buildNumberTypeCard = (numberType, icon, iconHeight, iconWidth, iconHeightMobile, iconWidthMobile) => ({
            numberType, icon, iconHeight, iconWidth, iconHeightMobile, iconWidthMobile,
            isSelected: ko.pureComputed(() => _numberPickerConstants.numberTypes[numberType] === self.selectedCard()),
            isSpinning: ko.observable(false),
            cardClick() {
                self.selectedCard(_numberPickerConstants.numberTypes[numberType]);
                _onCardClick(this.isSpinning);
            },
        });

        self.numberTypeCards = [
            buildNumberTypeCard('local', 'signupNumberLocal', '51', '31', '31', '20'),
            buildNumberTypeCard('tollFree', 'signupNumberTollfree', '51', '37', '29', '26'),
            buildNumberTypeCard('port', 'signupNumberTransfer', '51', '29', '34', '21'),
        ];

        const _onCardClick = (isSpinning) => {
            if (_isSignupForCustomer === true && self.selectedCard() === _numberPickerConstants.numberTypes.port) {
                self.errorMessage('noPortsWhenSignupForCustomer');
                return;
            }

            self.errorMessage('');
            isSpinning(true);
            const spinEndTime = 1000 + new Date().getTime();

            const localPromiseFactory = new PromiseFactoryConstructor();
            localPromiseFactory.deferIndefinitely(localDeferredObject => {
                switch (_signupSession.numberTypeSelection) {
                    case _numberPickerConstants.numberTypes.local:
                    case _numberPickerConstants.numberTypes.tollFree:
                        if (_signupSession.reservedPhoneNumber === null || _signupSession.reservedPhoneNumber === undefined) {
                            localDeferredObject.resolve();
                        } else {
                            _facade.cancelPhoneNumberReservation(_signupSession.reservedPhoneNumber)
                                .fail(localDeferredObject.reject)
                                .done(localDeferredObject.resolve);
                        }
                        break;
                    case _numberPickerConstants.numberTypes.port:
                        if (_signupSession.numberPort === null || _signupSession.numberPort.numberPortId === null) {
                            localDeferredObject.resolve();
                        } else {
                            _facade.clearPortForm(_signupSession.numberPort.numberPortId)
                                .fail(localDeferredObject.reject)
                                .done(localDeferredObject.resolve);
                        }
                        break;
                    default:
                        localDeferredObject.resolve();
                        break;
                }
            });

            localPromiseFactory.wait()
                .done(() => {
                    _facade.saveNumberTypeSelection(self.selectedCard())
                        .done(() => {
                            setTimeout(() => {
                                _signupNavigationManager.nextPage();
                            }, spinEndTime - new Date().getTime());
                        });
                });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = function() {
            _signupNavigationManager.detached();
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/signup/facades/signupPhoneNumberTypeFacade');
            _facade = new FacadeConstructor();
            _facade.init();

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            return _initialize();
        };

        const _initialize = () => _promiseFactory.deferIndefinitely(promise => {
            _facade.get()
                .done((signupSession) => {
                    _signupSession = signupSession;
                    promise.resolve();
                });

            return _promiseFactory.wait();
        });
    };
});
