define('presentation/common/paymentMethod/viewModels/addPaymentMethodViewModel',[
    'businessServices/country/countryRegions',
    'businessServices/creditCards/creditCardTypes',
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'constants/monthEnumerations',
    'constants/creditCardExpirationYearConstants',
    'entities/paymentMethodEntity',
    'presentation/common/paymentMethod/validators/addPaymentMethodValidator'
], function() {
    return function() {
        let self = this;
    
        const CountryRegionsConstructor = require('businessServices/country/countryRegions');
        const CreditCardTypesConstructor = require('businessServices/creditCards/creditCardTypes');
        const KeyValueConverterConstructor = require('common/collections/enumerationKeyValueConverter');
        const PaymentMethodEntityConstructor = require('entities/paymentMethodEntity');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const ValidatorConstructor = require('presentation/common/paymentMethod/validators/addPaymentMethodValidator');

        const _creditCardExpirationYear = require('constants/creditCardExpirationYearConstants');
        const _monthEnumerations = require('constants/monthEnumerations');
    
        const MONTH_LIST = new KeyValueConverterConstructor.convertToKeyValues(_monthEnumerations);
    
        const _countryRegions = new CountryRegionsConstructor();
        const _creditCardTypes = new CreditCardTypesConstructor();
        const _promiseFactory = new PromiseFactoryConstructor();
        const _validator = new ValidatorConstructor();
    
        const _createExpirationYearList = () => {
            const currentYear = new Date().getFullYear();
            return Array.from(new Array(_creditCardExpirationYear.yearsFromCurrent), (val,index) => {
                return {
                    key: currentYear + index,
                    value: currentYear + index
                };
            });
        };
    
        const _onCountryChange = () => {
            self.postalCode("");
            self.postalCode.isValid(null);
        };
    
        self.cardHolderName = ko.observable("");
        self.cardNumber = ko.observable("");
        self.cvvSecurityCode = ko.observable("");
        self.countries = ko.observableArray(_countryRegions.countryList);
        self.country = ko.observable(_countryRegions.findByAbbreviation("us"));
        self.country.subscribe(_onCountryChange);
        self.monthList = ko.observableArray(MONTH_LIST);
        self.monthSelected = ko.observable(MONTH_LIST[new Date().getMonth()].value);
        self.postalCode = ko.observable("");
        self.postalCodeMetaData = ko.pureComputed(() => self.country().hasProvinces ?
            {i18nKey: 'paymentMethod:postalCode', type: 'text', maxLength: 6} :
            {i18nKey: 'paymentMethod:zipCode', type: 'tel', maxLength: 5});
        self.expirationDate = ko.observable("");
        self.yearList = _createExpirationYearList();
        self.yearSelected = ko.observable(self.yearList.length > 0 ? self.yearList[0].value : "");

        self.paymentMethod = () => {
            let paymentMethod = new PaymentMethodEntityConstructor();
            if (self.cardHolderName() !== '') {
                paymentMethod.cardHolderName = self.cardHolderName();
            }
            if (self.cardNumber() !== '') {
                paymentMethod.creditCardNumber = self.cardNumber();
                paymentMethod.cardType = _creditCardTypes.getCreditCardType(paymentMethod.creditCardNumber);
            }
            if (self.monthSelected() !== '') {
                paymentMethod.expirationMonth = self.monthSelected();
            }
            if (self.yearSelected() !== '') {
                paymentMethod.expirationYear = self.yearSelected();
            }
            if (self.cvvSecurityCode() !== '') {
                paymentMethod.cvvSecurityCode = self.cvvSecurityCode();
            }
            if (self.postalCode() !== '') {
                paymentMethod.postalCode = self.postalCode();
            }
            paymentMethod.country = self.country().countryAbbreviation;
            
            return paymentMethod;
        };
    
        self.validate = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((validationItems) => {
                        deferredObject.resolve(validationItems);
                    });
            });
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self);
      
            return _promiseFactory.wait();
        };
    };
});
