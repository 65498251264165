define('businessServices/specifications/validCreditCardSpecification',[], function () {
    return function () {
        const self = this;

        const CREDIT_CARD_REGULAR_EXPRESSION = new RegExp(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/i);

        self.isSatisfiedBy = (creditCardNumber) => {
            if (creditCardNumber) {
                const isValid = CREDIT_CARD_REGULAR_EXPRESSION.test(creditCardNumber);
                return isValid;
            }
            return false;
        };

        self.isCreditCardExpired = (expirationDate) => {
            const today = new Date();
            const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            lastDayOfCurrentMonth.setUTCHours(0);
            const creditCardExpirationDate = new Date(expirationDate);
            return (creditCardExpirationDate < lastDayOfCurrentMonth);
        };
    };
});
