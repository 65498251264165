define('persistence/dataProviders/webMessagingDataProvider',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], function (
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactoryConstructor,
    /** @type typeof import('externalDependencies/clientWebSocket') */
    clientWebSocket
) {
    const APPLICATION_NAME = `WebMessaging`;

    return function() {
        /**
         * @typedef { import('persistence/dataProviders/webMessagingDataProvider') } WebMessagingDataProvider
         */

        /** @type {WebMessagingDataProvider} */
        const self = this;

        const _webMessagingSocket = clientWebSocket.forApplication(APPLICATION_NAME);

        /**
         * @template T
         * @param {string} eventName
         * @param {Callback<T>} callBack
         */
        const _subscribe = (eventName, callBack) => {
            _webMessagingSocket.subscribeToEvent(eventName, callBack);
        };

        /**
         * @template TOut
         * @param {string} command
         * @param {{ [key: string]: any }} parameters
         * @returns {JQuery.Deferred<TOut?>}
         */
        const _send = (command, parameters) => {
            const promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.defer((/** @type {JQuery.Deferred<TOut?>} */deferred) => {
                _webMessagingSocket.send(command, parameters || {}, (/** @type {TOut?} */ response, /** @type {any} */ errMsg) => {
                    if (errMsg) {
                        deferred.reject(new Error(errMsg));
                        return;
                    }

                    deferred.resolve(response);
                });
            });
        };

        /** @type {WebMessagingDataProvider["markConversationAsNew"]} */
        self.markConversationAsNew = (smsConversationId) => {
            return _send('markConversationAsNew', { smsConversationId });
        };

        /** @type {WebMessagingDataProvider["archiveConversation"]} */
        self.archiveConversation = (smsConversationId) => {
            return _send('archiveConversation', { smsConversationId });
        };

        /** @type {WebMessagingDataProvider["deleteDraftConversation"]} */
        self.deleteDraftConversation = (smsConversationDraftId) => {
            return _send(`deleteDraftConversation`, { smsConversationDraftId });
        };

        /** @type {WebMessagingDataProvider["deleteMessage"]} */
        self.deleteMessage = (smsConversationId, messageId) => {
            return _send(`deleteMessage`, { smsConversationId, messageId });
        };

        /** @type {WebMessagingDataProvider["getDraftConversation"]} */
        self.getDraftConversation = (smsConversationDraftId) => {
            return _send(`getDraftConversation`, { smsConversationDraftId });
        };

        /** @type {WebMessagingDataProvider["createDraftConversation"]} */
        self.createDraftConversation = (recipients) => {
            return _send(`createDraftConversation`, { recipients });
        };

        /** @type {WebMessagingDataProvider["createDraftAttachmentConversation"]} */
        self.createDraftAttachmentConversation = (attachmentId) => {
            return _send(`createDraftAttachmentConversation`, { attachmentId });
        };

        /** @type {WebMessagingDataProvider["createConversationDraftMessage"]} */
        self.createConversationDraftMessage = (request) => {
            return _send(`createConversationDraftMessage`, request);
        };

        /** @type {WebMessagingDataProvider["getAutoCompleteRecipients"]} */
        self.getAutoCompleteRecipients = (accountHostedNumberId) => {
            return _send(`getAutoCompleteRecipients`, { accountHostedNumberId });
        };

        /** @type {WebMessagingDataProvider["getMap"]} */
        self.getMap = (request) => {
            return _send(`getMap`, request);
        };

        /** @type {WebMessagingDataProvider["getUserConversationsWithMostRecentMessage"]} */
        self.getUserConversationsWithMostRecentMessage = (request) => {
            return _send(`getUserConversationsWithMostRecentMessage`, request);
        };

        /** @type {WebMessagingDataProvider["getUserConversationWithMostRecentMessageById"]} */
        self.getUserConversationWithMostRecentMessageById = (smsConversationId) => {
            return _send(`getUserConversationWithMostRecentMessageById`, { smsConversationId });
        };

        /** @type {WebMessagingDataProvider["getSmsConversation"]} */
        self.getSmsConversation = (smsConversationId, messageCount, jumpToMessageId = null) => {
            return _send(`getSmsConversation`, { smsConversationId, messageCount, jumpToMessageId });
        };

        /** @type {WebMessagingDataProvider["getSmsConversationMessagesBefore"]} */
        self.getSmsConversationMessagesBefore = (smsConversationId, messageCount, beforeDateTime) => {
            return _send('getSmsConversationMessagesBefore', { smsConversationId, messageCount, beforeDateTime });
        };

        /** @type {WebMessagingDataProvider["getSmsConversationMessagesSince"]} */
        self.getSmsConversationMessagesSince = (smsConversationId, sinceDateTime, messageCount) => {
            return _send('getSmsConversationMessagesSince', { smsConversationId, sinceDateTime, messageCount });
        };

        /** @type {WebMessagingDataProvider["getMatchingSmsConversation"]} */
        self.getMatchingSmsConversation = (sendFromHostedNumberId, recipientPhoneNumbers) => {
            return _send('getMatchingSmsConversation', { sendFromHostedNumberId, recipientPhoneNumbers });
        };

        /** @type {WebMessagingDataProvider["getSmsConversationMessageAttachment"]} */
        self.getSmsConversationMessageAttachment = (smsConversationMessageAttachmentId) => {
            return _send(`getSmsConversationMessageAttachment`, { smsConversationMessageAttachmentId });
        };

        /** @type {WebMessagingDataProvider["getSmsConversationMessageAttachmentDraft"]} */
        self.getSmsConversationMessageAttachmentDraft = (smsConversationMessageAttachmentId) => {
            return _send(`getSmsConversationMessageAttachmentDraft`, { smsConversationMessageAttachmentId });
        };

        /** @type {WebMessagingDataProvider["getPhoneNumberLocation"]} */
        self.getPhoneNumberLocation = (phoneNumber) => {
            return _send(`getPhoneNumberLocation`, { phoneNumber });
        };

        /** @type {WebMessagingDataProvider["updateConversationDraftContent"]} */
        self.updateConversationDraftContent = (request) => {
            return _send(`updateConversationDraftContent`, request);
        };

        /** @type {WebMessagingDataProvider["updateConversationDraftRecipients"]} */
        self.updateConversationDraftRecipients = (request) => {
            return _send(`updateConversationDraftRecipients`, request);
        };

        /** @type {WebMessagingDataProvider["updateConversationDraftAttachments"]} */
        self.updateConversationDraftAttachments = (request) => {
            return _send(`updateConversationDraftAttachments`, request);
        };

        /** @type {WebMessagingDataProvider["updateConversationDraftSendFromNumber"]} */
        self.updateConversationDraftSendFromNumber = (request) => {
            return _send(`updateConversationDraftSendFromNumber`, request);
        };

        /** @type {WebMessagingDataProvider["publishSmsUserIsTyping"]} */
        self.publishSmsUserIsTyping = (smsConversationId) => {
            return _send(`publishSmsUserIsTyping`, { smsConversationId });
        };

        /** @type {WebMessagingDataProvider["retryMessage"]} */
        self.retryMessage = (smsConversationId, messageId) => {
            return _send(`retryMessage`, { smsConversationId, messageId });
        };

        /** @type {WebMessagingDataProvider["updateSmsConversationLastViewedDate"]} */
        self.updateSmsConversationLastViewedDate = (smsConversationId) => {
            return _send(`updateSmsConversationLastViewedDate`, { smsConversationId });
        };

        /** @type {WebMessagingDataProvider["updateSmsConversationName"]} */
        self.updateSmsConversationName = (smsConversationId, conversationName) => {
            return _send('updateSmsConversationName', { smsConversationId, conversationName });
        };

        /** @type {WebMessagingDataProvider["sendNewSms"]} */
        self.sendNewSms = ({sendFromHostedNumberId, recipientPhoneNumbers, message, attachmentIds, smsConversationDraftId = null}) => {
            return _send(`sendNewSms`, { sendFromHostedNumberId, recipientPhoneNumbers, message, attachmentIds, smsConversationDraftId });
        };

        /** @type {WebMessagingDataProvider["sendSmsReply"]} */
        self.sendSmsReply = (smsConversationId, message = null, attachmentIds= []) => {
            return _send(`sendSmsReply`, { smsConversationId, message, attachmentIds });
        };

        /** @type {WebMessagingDataProvider["getSmsConversationNewMessagesCount"]} */
        self.getSmsConversationNewMessagesCount = (smsConversationId) => {
            return _send(`getSmsConversationNewMessagesCount`, { smsConversationId });
        };

        /** @type {WebMessagingDataProvider["createSmsConversationMessageAttachments"]} */
        self.createSmsConversationMessageAttachments = (createAttachmentsRequest) => {
            return _send('createSmsConversationMessageAttachments', createAttachmentsRequest);
        };

        /** @type {WebMessagingDataProvider["getAttachmentDownloadFilePath"]} */
        self.getAttachmentDownloadFilePath = (attachmentId) => {
            return _send('getAttachmentDownloadFilePath', {attachmentId});
        };

        /** @type {WebMessagingDataProvider["cancelAttachment"]} */
        self.cancelAttachment = (smsConversationMessageAttachmentId) => {
            return _send('cancelAttachment', {smsConversationMessageAttachmentId});
        };

        /** @type {WebMessagingDataProvider["getConversationSmsMessageAttachments"]} */
        self.getConversationSmsMessageAttachments = (request) => {
            return _send('getConversationSmsMessageAttachments', request);
        };

        /** @type {WebMessagingDataProvider["getMessageUrlPreview"]} */
        self.getMessageUrlPreview = (smsConversationMessageUrlId) => {
            return _send('getMessageUrlPreview', {smsConversationMessageUrlId});
        };

        /** @type {WebMessagingDataProvider["getConversationSmsMessageUrlPreviews"]} */
        self.getConversationSmsMessageUrlPreviews = (request) => {
            return _send('getConversationSmsMessageUrlPreviews', request);
        };

        //#endregion

        //#region Subscriptions

        /** @type {WebMessagingDataProvider["onAccountHostedNumberSmsStatusChanged"]} */
        self.onAccountHostedNumberSmsStatusChanged = (callBack) => {
            _subscribe(`accountHostedNumberSmsStatusChanged`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationMessageDeleted"]} */
        self.onSmsConversationMessageDeleted = (callBack) => {
            _subscribe(`smsConversationMessageDeleted`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationMessageReceived"]} */
        self.onSmsConversationMessageReceived = (callBack) => {
            _subscribe(`smsConversationMessageReceived`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationMessageSent"]} */
        self.onSmsConversationMessageSent = (callBack) => {
            _subscribe(`smsConversationMessageSent`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationMessageUpdated"]} */
        self.onSmsConversationMessageUpdated = (callBack) => {
            _subscribe(`smsConversationMessageUpdated`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationCreated"]} */
        self.onSmsConversationCreated = (callBack) => {
            _subscribe(`smsConversationCreated`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationNameChanged"]} */
        self.onSmsConversationNameChanged = (callBack) => {
            _subscribe(`smsConversationNameChanged`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationUserAssociationChanged"]} */
        self.onSmsConversationUserAssociationChanged = (callBack) => {
            _subscribe(`smsConversationUserAssociationChanged`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationDraftCreated"]} */
        self.onSmsConversationDraftCreated = (callBack) => {
            _subscribe(`smsConversationDraftCreated`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationDraftChanged"]} */
        self.onSmsConversationDraftChanged = (callBack) => {
            _subscribe(`smsConversationDraftChanged`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationDraftDeleted"]} */
        self.onSmsConversationDraftDeleted = (callBack) => {
            _subscribe(`smsConversationDraftDeleted`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsPermissionChanged"]} */
        self.onSmsPermissionChanged = (callBack) => {
            _subscribe(`smsPermissionChanged`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsUserIsTyping"]} */
        self.onSmsUserIsTyping = (callBack) => {
            _subscribe(`smsUserIsTyping`, callBack);
        };

        /** @type {WebMessagingDataProvider["onSmsConversationMessageAttachmentUpdated"]} */
        self.onSmsConversationMessageAttachmentUpdated = (callback) => {
            _subscribe('smsConversationMessageAttachmentUpdated', callback);
        };

        /** @type {WebMessagingDataProvider["onUrlPreviewConversionCompleted"]} */
        self.onUrlPreviewConversionCompleted = (callback) => {
            _subscribe('urlPreviewConversionCompleted', callback);
        };

        //#endregion

        /** @type {WebMessagingDataProvider["disposeOfEvents"]} */
        self.disposeOfEvents = () => {
            _webMessagingSocket.disposeOfEvents();
        };
    };
});

