define('presentation/settings/userGroupMembership/facades/userGroupMembershipFacade',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/authentication/sessionUserInfo',
    'common/storage/commonState',
    'constants/userGroupConstants',
    'externalDependencies/clientWebSocket',
    'persistence/webSocket/webSocketApp',
    'presentation/settings/userGroupMembership/presentationObjects/userGroupPresentationObject',
], function() {
    const GroupPresentationObjectConstructor = require('presentation/settings/userGroupMembership/presentationObjects/userGroupPresentationObject');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const groupMembershipSocket = clientWebSocket.forApplication("userGroupMembership");

    const _commonState = require('common/storage/commonState');
    const _i18n = require('i18next');
    const _userGroupConstants = require('constants/userGroupConstants');

    let _isUserSelf = null;
    let _promiseFactory = null;
    let _sessionAccountInfo = null;
    let _sessionUserInfo = null;
    let _userHasPermission = null;
    let _userId = null;
    let _webSocketApp = null;

    const _formatDesiredGroupsForSave = (userGroups) => {
        return userGroups.map((userGroup) => {
            // This could be an observable or an object depending on where the user came from
            return {groupId : ko.unwrap(userGroup).id};
        });
    };

    const _mapAllGroups = () => {
        let activeGroups = _commonState.userGroups().filter((userGroup) => {
            return userGroup.isActive();
        });
        return activeGroups.map(group => {
            return _mapUserGroup(group);
        });
    };

    const _mapUserGroups = (userGroupIds) => {
        return userGroupIds.map(groupId => {
            const commonStateGroup = _commonState.get(groupId);
            return _mapUserGroup(commonStateGroup);
        });
    };

    const _mapUserGroup = (group) => {
        let groupPresentationObject = new GroupPresentationObjectConstructor();
        groupPresentationObject.id = _commonState.resolveGroupIdToGuid(group.id);
        groupPresentationObject.groupId(group.id);
        groupPresentationObject.groupName(group.name());
        groupPresentationObject.type(group.type);

        let members = group.members().filter(member => {
            return _commonState.get(member).isSelectable();
        });
        groupPresentationObject.members = members;

        let foundMember = members.find((member) => {
            return member === _userId;
        });

        if (foundMember === undefined) {
            groupPresentationObject.isGroupNameEnabled(true);
            groupPresentationObject.isDeletable(true);
        } else {

            if (!_userHasPermission) {
                groupPresentationObject.isDeletable(false);
                groupPresentationObject.isGroupNameEnabled(false);
                groupPresentationObject.removeToolTipText = '';
            } else if (groupPresentationObject.groupId() === _userGroupConstants.allUserGroupId) {
                groupPresentationObject.isDeletable(false);
                groupPresentationObject.isGroupNameEnabled(false);
                groupPresentationObject.removeToolTipText = _isUserSelf ? _i18n.t('userGroupMembership:allUsersGroupSelf') : _i18n.t('userGroupMembership:allUsersGroup');
            } else if (groupPresentationObject.groupId() === _userGroupConstants.systemAdminsGroupId && _isUserSelf) {
                groupPresentationObject.isDeletable(false);
                groupPresentationObject.isGroupNameEnabled(false);
                groupPresentationObject.removeToolTipText = _i18n.t('userGroupMembership:systemAdminsGroup');
            } else if (members.length === 1) {
                groupPresentationObject.isDeletable(false);
                groupPresentationObject.isGroupNameEnabled(false);
                groupPresentationObject.removeToolTipText = _isUserSelf ? _i18n.t('userGroupMembership:lastMemberSelf') : _i18n.t('userGroupMembership:lastMember');
            } else {
                groupPresentationObject.isDeletable(group.isSelectable());
                groupPresentationObject.isGroupNameEnabled(group.isSelectable());
            }
        }

        return groupPresentationObject;
    };

    const _saveUserGroups = (userGroups, allUserGroups) => {
        return _promiseFactory.defer((promise) => {
            const desiredUserGroups = _formatDesiredGroupsForSave(userGroups);
            let addedGroupMembershipIds = [];
            let removedGroupMembershipIds = [];

            const setUpdateMembershipRequest = (userGroup) => {
                return _promiseFactory.defer((membershipPromise) => {
                    let wantsToBeInGroup = desiredUserGroups.some((desiredGroup) => desiredGroup.groupId === userGroup.id);
                    let isMemberOfGroup = userGroup.members.some((memberId) => memberId === _userId);

                    if (wantsToBeInGroup === true && isMemberOfGroup === false) {
                        addedGroupMembershipIds.push(userGroup.id);
                        membershipPromise.resolve();
                    } else if (wantsToBeInGroup === false && isMemberOfGroup === true) {
                        removedGroupMembershipIds.push(userGroup.id);
                        membershipPromise.resolve();
                    } else {
                        membershipPromise.resolve();
                    }
                });
            };

            _promiseFactory.deferredList(allUserGroups, setUpdateMembershipRequest)
                .fail(promise.reject)
                .done(() => {
                    const updateMembershipRequest = {
                        userId: _userId,
                        userGroups: desiredUserGroups
                    };

                    groupMembershipSocket.send("updateGroupMembership", updateMembershipRequest, (response, err) => {
                        if (err) {
                            promise.reject(new Error(err));
                            return;
                        }
                        if (response.isSuccessful === false) {
                            promise.reject(new Error("Error updating user groups"));
                            return;
                        }
                        if (addedGroupMembershipIds.length === 0 && removedGroupMembershipIds.length === 0) {
                            promise.resolve();
                            return;
                        }

                        const sendNotationsRequest = {
                            userId: _userId,
                            addedGroupMemberships: addedGroupMembershipIds,
                            removedGroupMemberships: removedGroupMembershipIds
                        };

                        _webSocketApp.send("sendUserGroupMembershipNotations", sendNotationsRequest, (result) => {
                            if (result.status === "error") {
                                promise.reject(new Error(result.errorMessage));
                            } else {
                                promise.resolve(result);
                            }
                        });
                    });
                });
        });
    };

    const _getUserGroups = (userId) => {
        return _promiseFactory.defer((promise) => {
            _userId = userId;
            _userHasPermission = _sessionAccountInfo.accountPermissions().SystemSettings;
            _isUserSelf = _userId ===  _sessionAccountInfo.userId();

            groupMembershipSocket.send("listGroupMembership", {userId: userId}, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                let userGroups = _mapUserGroups(response.userGroupList);
                let allGroups = _mapAllGroups();
                let availableGroups = allGroups.filter((userGroup) => {
                    return undefined === userGroups.find((existingUserGroup) => {
                        return userGroup.groupId() === existingUserGroup.groupId();
                    });
                });

                if (availableGroups.length === 0) {
                    userGroups.forEach((userGroup) => {
                        userGroup.isGroupNameEnabled(false);
                    });
                }

                let groups = {
                    allGroups: allGroups,
                    userGroups: userGroups,
                };

                promise.resolve(groups);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
        _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        _sessionUserInfo = require('businessServices/authentication/sessionUserInfo');

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_edit");
    };

    return function() {
        let self = this;

        self.init = _init;
        self.getUserGroups = _getUserGroups;
        self.saveUserGroups = _saveUserGroups;
    };
});

