define('presentation/settings/contacts/viewModels/contactPermissionsViewModel',[
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/storage/commonState',
    'common/promises/promiseFactory',
    'constants/userGroupConstants',
    'presentation/settings/contacts/facades/contactPermissionsFacade'
], function(
     /** @type import('businessServices/contacts/contactsStateSingleton') */
     _contactsState,
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('common/collections/collectionSorter') */
    CollectionSorter,
    /** @type typeof import('common/storage/commonState') */
    _commonState,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/userGroupConstants') */
    UserGroupConstants,
    /** @type typeof import('presentation/settings/contacts/facades/contactPermissionsFacade') */
    ContactPermissionsFacade
) {
    return function() {
        const self = this;

        /** @type { IDisposable[] } */
        let _disposables = [];

        const _promiseFactory = new PromiseFactory();
        const _facade = new ContactPermissionsFacade(_promiseFactory);
        const _modelStateObserver = new ModelStateObserver(self, true);
        const _sorter = new CollectionSorter();

        /** @type { (allSubscribers: Array<IContactPermissionsPresentationObject>, selectedSubscribers: Array<KnockoutObservable<IContactPermissionsPresentationObject>>) => Array<IContactPermissionsPresentationObject> } */
        const _filterAvailableSubscribers = (allSubscribers, selectedSubscribers) => {
            _sorter.sort(allSubscribers, "displayName", true);

            return allSubscribers.filter((subscriber) => {
                return undefined === selectedSubscribers.find((selectedSubscriber) => {
                    return subscriber.id === selectedSubscriber().id;
                });
            });
        };

        /** @type { (initialPermissions: Array<IContactPermissionsPresentationObject>) => Array<IContactPermissionsPresentationObject> } */
        const _sortInitialPermissions = (initialPermissions) => {
            const systemAdminsGroup = _commonState.get(UserGroupConstants.systemAdminsGroupId);

            const systemGroup = initialPermissions.find((permission) => permission.id === systemAdminsGroup.id);

            const permissionList = initialPermissions.filter((permission) => permission.id !== systemAdminsGroup.id);

            _sorter.sort(permissionList, "displayName", true);

            if (systemGroup) {
                permissionList.unshift(systemGroup);
            }

            return permissionList;
        };

        /** @type { (contactPermissions: Array<IContactPermissionsPresentationObject>) => void } */
        const _contactPermissionsUpdated = (contactPermissions) => {
            const sortedPermissions = _sortInitialPermissions(contactPermissions);
            self.contactPermissionsList(sortedPermissions);
            self.modelStateObserver.commitData();
        };

        self.modelStateObserver = null;

        self.contactPermissionsList = ko.observableArray([]).extend({observeState: true});
        self.contactPermissionsListState = _contactsState.contactPermissionsUsersAndUserGroups;

        self.allUsersAndUserGroups = ko.pureComputed(() => {
            const allUsersAndGroups = _facade.allUsersAndUserGroups();
            _sorter.sort(allUsersAndGroups, "displayName", true);
            return allUsersAndGroups;
        });

        self.cancelForm = () => {
            self.modelStateObserver.restoreData();
        };

        self.savePage = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                const contactPermissions = self.contactPermissionsList();

                _facade.updateContactPermissions(contactPermissions)
                    .fail((error) => {
                        deferredObject.reject(error);
                    })
                    .done(() => {
                        self.modelStateObserver.saveData();
                        deferredObject.resolve();
                    });
            });
        };


        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.filterAvailableSubscribers = _filterAvailableSubscribers;

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = _modelStateObserver;

            const sortedPermissions = _sortInitialPermissions(_contactsState.contactPermissionsUsersAndUserGroups());
            self.contactPermissionsList(sortedPermissions);
            self.allUsersAndUserGroups = _facade.allUsersAndUserGroups;

            _disposables.push(self.contactPermissionsListState.subscribe(_contactPermissionsUpdated));

            self.modelStateObserver.commitData();

            return _promiseFactory.wait();
        };
    };
});
