define('presentation/common/frontEndHeader/viewModels/frontEndHeaderViewModel',['presentation/common/window/windowControl',
        'common/url/urlFormatter',
        'businessServices/authentication/sessionAccountInfo',
        'presentation/common/frontEndHeader/facades/frontEndHeaderFacade'], function () {
    return function () {
        var self = this;
        var _windowControl = null;
        var _urlFormatter = null;
        var _sessionAccountInfo = null;
        var _facade = null;
        var _baseUrl = "";
        var _baseUrlFrontend = "";

        var _signupUrl = "/signup/cdn1/email";
        var _loginUrl = "/sign-in";
        var _featuresUrl = "/features";
        var _pricingUrl = "/pricing";
        var _numbersUrl = "/phone-numbers";
        var _homeUrl = "/";
        var _howItWorksUrl = "/how-it-works";

        var _loadUrl = function(url) {
            _windowControl.openNewWindow(_baseUrl + url, "_self");
        };

        var _loadFrontendUrl = function(url) {
            _windowControl.openNewWindow(_baseUrlFrontend + url, "_self");
        };

        self.menuIsActive = ko.observable(false);

        self.homeUrl = "";
        self.featuresUrl = "";
        self.numbersUrl = "";
        self.pricingUrl = "";
        self.loginurl = "";
        self.howItWorksUrl = "";
        self.showLoginLink = true;
        self.showLogoutLink = false;

        self.loadSignup = function() {
            _loadUrl(_signupUrl);
        };

        self.loadLogin = function() {
            _loadUrl(_loginUrl);
        };

        self.loadFeatures = function() {
            _loadFrontendUrl(_featuresUrl);
        };

        self.loadNumbers = function() {
            _loadFrontendUrl(_numbersUrl);
        };

        self.loadPricing = function() {
            _loadFrontendUrl(_pricingUrl);
        };

        self.loadHome = function() {
            _loadFrontendUrl(_homeUrl);
        };

        self.loadHowItWorks = function() {
            _loadFrontendUrl(_howItWorksUrl);
        };

        self.toggleMenu = function() {
            self.menuIsActive(!self.menuIsActive());
        };

        self.logUserOut = function() {
            _facade.logUserOut();
        };

        self.activate = function() {
            _windowControl = require('presentation/common/window/windowControl');
            _urlFormatter = require('common/url/urlFormatter');
            _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

            var FacadeConstructor = require('presentation/common/frontEndHeader/facades/frontEndHeaderFacade');
            _facade = new FacadeConstructor();
            _facade.init();

            return _initialize();
        };

        function _initialize() {
            _baseUrl = _windowControl.getBaseUrl();
            _baseUrlFrontend = _windowControl.getFrontendBaseUrl();

            self.homeUrl = _urlFormatter.buildFrontendUrl(_homeUrl);
            self.featuresUrl = _urlFormatter.buildFrontendUrl(_featuresUrl);
            self.numbersUrl = _urlFormatter.buildFrontendUrl(_numbersUrl);
            self.pricingUrl = _urlFormatter.buildFrontendUrl(_pricingUrl);
            self.loginUrl = _urlFormatter.buildWorkSessionUrl(_loginUrl, true);
            self.howItWorksUrl = _urlFormatter.buildFrontendUrl(_howItWorksUrl);
            self.signupUrl = _urlFormatter.buildFrontendUrl(_signupUrl, true);

            if (_sessionAccountInfo.isLoggedIn()) {
                self.showLoginLink = false;
                self.showLogoutLink = true;
            }

            return;
        }
    };
});
