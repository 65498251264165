define('persistence/repositories/phoneConnectorRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(phoneConnectorEntity) {
            if (phoneConnectorEntity.phoneConnectorId !== null) {
                var err = new Error("phoneConnectorId should be null");
                err.lobbyPhoneConnector = phoneConnectorEntity;
                throw err;
            }

            var phoneConnectorDocument = {
                data : phoneConnectorEntity
            };

            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount("/lobby_phone_connectors", 'PUT', phoneConnectorDocument)
                    .done(function(result){
                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        error.phoneConnectorEntity = phoneConnectorEntity;
                        deferredObject.reject(error);
                    });
            });
        };

        self.remove = function(phoneConnectorId) {
            return _promiseFactory.defer(function(deferredObject) {
                if(phoneConnectorId === null) {
                    var err = new Error("Missing phoneConnectorId");
                    throw err;
                }
                _kazooApi.callAccount("/lobby_phone_connectors/" + phoneConnectorId, 'DELETE')
                    .done(function() {
                        deferredObject.resolve();
                    })
                    .fail(function(error) {
                        error.phoneConnectorId = phoneConnectorId;
                        deferredObject.reject(error);
                    });
            });
        };

        self.update = function(phoneConnectorEntity) {
            if (phoneConnectorEntity.phoneConnectorId === null) {
                var err = new Error("Missing phoneConnectorId");
                throw err;
            }

            var phoneConnectorDocument = {
                data : phoneConnectorEntity
            };

            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount("/lobby_phone_connectors/" + phoneConnectorEntity.phoneConnectorId, 'POST', phoneConnectorDocument)
                    .done(function(result){
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        error.phoneConnectorEntity = phoneConnectorEntity;
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

