define('presentation/common/voicemail/viewModels/addVoicemailGreetingViewModel',['businessServices/browserSupport/audioDevices',
        'businessServices/events/webSocketEventManager',
        'businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'constants/audioInputOptionConstants',
        'presentation/common/voicemail/facades/addVoicemailGreetingFacade',
        'presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject',
        'presentation/common/voicePrompt/voicePromptAudioFactory',
        'presentation/common/voicemail/validators/addVoicemailGreetingValidator',
], function() {
    return function (greetingId, name, file, greetingAudioObject) {
        const self = this;

        const BrowserSupportConstructor = require('businessServices/browserSupport/audioDevices');
        const _browserSupport = new BrowserSupportConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const VoicemailGreetingAudioObjectConstructor = require('presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject');

        const _audioInputOptionConstants = require('constants/audioInputOptionConstants');

        let _conversionNeeded = false;
        let _detachedFromAddGreetingView = false;
        let _facade = null;
        let _fileSelected = false;
        let _validator = null;
        let _webSocketEventManager = null;
        let _voicePromptAudioFactory = null;

        const _onGreetingFileChange = () => {
            self.greetingFile.validate();
            let isValid = self.greetingFile.isValid();
            _fileSelected = true;
            _cancelPendingGetConversionRequests();

            if (isValid && self.greetingFile() !== "") {
                let selectedMethod = self.selectedMethod();
                let voicemailGreetingAudioObject = new VoicemailGreetingAudioObjectConstructor();

                switch (selectedMethod) {
                    case _audioInputOptionConstants.record:
                        let voicemailGreetingFileBlob = self.greetingFile();
                        if (voicemailGreetingFileBlob !== undefined) {
                            voicemailGreetingAudioObject.audioData(URL.createObjectURL(voicemailGreetingFileBlob));
                            voicemailGreetingAudioObject.audioType(voicemailGreetingFileBlob.type);
                            self.showConversionMessage(false);
                            self.showConversionFailedMessage(false);
                            self.currentVoicePrompt(voicemailGreetingAudioObject);
                            self.currentVoicePrompt().duration = self.greetingFileDuration;
                            self.showPreview(false);
                            self.showAudioPlayer(true);
                        }
                        break;
                    case _audioInputOptionConstants.upload:
                        voicemailGreetingAudioObject.fileHandler(self.greetingFile());
                        self.showConversionMessage(true);
                        self.currentVoicePrompt(voicemailGreetingAudioObject);
                        self.showPreview(true);
                        
                        _facade.convertFile(voicemailGreetingAudioObject)
                            .fail((error) => {
                                throw error;
                            })
                            .done(() => {
                                self.showPreview(true);
                                self.showAudioPlayer(true);
                                self.showConversionMessage(false);
                            });
                        break;
                }
            } else {
                self.showPreview(false);
                self.currentVoicePrompt(null);
            }
        };

        const _cancelPendingGetConversionRequests = () => {
            if (_conversionNeeded) {
                _facade.cancelGetConvertedFile();
                _conversionNeeded = false;
            }
        };

        const _onSelectedPromptMethodChange = () => {
            self.greetingFile.resetValidation();
            self.showPreview(false);
            self.showAudioPlayer(false);
            self.showConversionFailedMessage(false);
            self.showConversionMessage(false);
        };

        const _setDefaultSelectedMethod = () => {
            return _promiseFactory.defer((deferredObject) => {
                if (!_browserSupport.isRecordingSupported()) {
                    self.selectedMethod(_audioInputOptionConstants.upload);
                    self.modelStateObserver.commitData();
                    deferredObject.resolve();
                } else {
                    _browserSupport.hasRecordingDevices()
                        .fail(deferredObject.reject)
                        .done((hasRecordingDevices) => {
                            if (hasRecordingDevices) {
                                self.selectedMethod(_audioInputOptionConstants.record);
                            } else {
                                self.selectedMethod(_audioInputOptionConstants.upload);
                            }
                            self.modelStateObserver.commitData();
                            deferredObject.resolve();
                        });
                }
            });
        };

        if (greetingId) {
            self.greetingId = greetingId;
        } else {
            self.greetingId = null;
        }
        self.name = ko.observable(name).extend({observeState: true});
        self.greetingFile = ko.observable(file).extend({observeState: true});
        self.greetingFileDuration = ko.observable('');
        self.currentVoicePrompt = ko.observable().extend({observeState: true});
        self.showAudioPlayer = ko.observable(false);
        self.showConversionMessage = ko.observable(false);
        self.showConversionFailedMessage = ko.observable(false);
        self.showPreview = ko.observable(false);
        self.modelStateObserver = null;
        self.isAccount = false;
        self.isNameFocused = ko.observable(false);

        self.showDownload = ko.observable(false);
        self.showRecord = ko.pureComputed(() => self.selectedMethod() === _audioInputOptionConstants.record);
        self.showUpload = ko.pureComputed(() => self.selectedMethod() === _audioInputOptionConstants.upload);

        self.greetingMethods = ko.observableArray([
            {text: "Record", id: _audioInputOptionConstants.record},
            {text: "Upload", id: _audioInputOptionConstants.upload},
        ]);
        self.selectedMethod = ko.observable(self.greetingMethods());

        self.getVoicemailGreetingDownloadUrl = (voicemailBoxGreeting) => {
            return _promiseFactory.defer((promise) => {
                const audioObject = voicemailBoxGreeting.currentVoicePrompt();
                promise.resolve(audioObject.audioData());
            });
        };

        self.validate = () => {
            return _validator.validate();
        };
        
        self.compositionComplete = () => {
           if (!self.name()){
               self.isNameFocused(true);
           }
        };

        self.detached = (view, parent) => {
            _detachedFromAddGreetingView = true;
            _cancelPendingGetConversionRequests();
        };

        self.activate = () => {
            const Facade = require('presentation/common/voicemail/facades/addVoicemailGreetingFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/common/voicemail/validators/addVoicemailGreetingValidator');
            _validator = new ValidatorConstructor();

            _webSocketEventManager = require('businessServices/events/webSocketEventManager');
            _webSocketEventManager.init();

            const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
            _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
            _voicePromptAudioFactory.init();

            return _initialize();
        };

        const _initialize = () => {
            _detachedFromAddGreetingView = false;

            self.modelStateObserver = new ModelStateObserverConstructor(self, false);

            if (greetingAudioObject) {
                self.currentVoicePrompt(greetingAudioObject);
                self.showDownload(true);
                self.showPreview(true);
                self.showAudioPlayer(true);
            } else {
                self.currentVoicePrompt(_voicePromptAudioFactory.getMissingVoicePrompt());
            }

            _validator.registerViewModel(self, _facade);

            self.greetingFile.subscribe(_onGreetingFileChange);

            _setDefaultSelectedMethod()
                .done(() => {
                    self.selectedMethod.subscribe(_onSelectedPromptMethodChange);
                    self.modelStateObserver.commitData();
                })
                .fail((error) => {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

