define('businessServices/creditCards/creditCardTypes',[
    'common/time/date',
    'constants/creditCardTypeEnumerations'
], function () {
    return function () {
        let self = this;

        const _creditCardTypeEnumerations = require('constants/creditCardTypeEnumerations');
        const DateConstructor = require('common/time/date');
        const _date = new DateConstructor();

        const _getCreditCardType = (creditCardNumber) => {
            for (let creditCardType in _creditCardTypeEnumerations) {
                if (_creditCardTypeEnumerations[creditCardType].validatingDigits) {
                    const validatingDigitsKey = _creditCardTypeEnumerations[creditCardType].validatingDigits;
                    for (let validDigits in validatingDigitsKey) {
                        const validDigitsKey = _creditCardTypeEnumerations[creditCardType].validatingDigits[validDigits];
                        if (creditCardNumber.indexOf(validDigitsKey) === 0) {
                            return _creditCardTypeEnumerations[creditCardType].type;
                        }
                    }
                }
            }
            return "";
        };

        const _getCreditCardByNumber = (creditCardNumber) => {
            return _getCreditCardTypeByCode(_getCreditCardType(creditCardNumber));
        };

        const _getCreditCardTypeByCode = (creditCardTypeCode) => {
            switch (creditCardTypeCode) {
                case _creditCardTypeEnumerations.americanExpress.type:
                    return _creditCardTypeEnumerations.americanExpress;
                case _creditCardTypeEnumerations.visa.type:
                    return _creditCardTypeEnumerations.visa;
                case _creditCardTypeEnumerations.masterCard.type:
                    return _creditCardTypeEnumerations.masterCard;
                case _creditCardTypeEnumerations.discover.type:
                    return _creditCardTypeEnumerations.discover;
                default:
                    const error = new Error("unknown card type");
                    error.cardType = creditCardTypeCode;
                    throw error;
            }
        };

        const _getCreditCardDisplayName = (cardType) => {
            const creditCard = _getCreditCardTypeByCode(cardType);
            return creditCard.displayCardName;
        };

        const _getCreditCardMaskedDisplay = (cardType, lastFourDigits) => {
            const creditCard = _getCreditCardTypeByCode(cardType);
            creditCard.maskedDisplay = creditCard.displayCardNameAbbreviation + " " + creditCard.maskedPrefix + lastFourDigits;
            return creditCard;
        };

        const _getCreditCardShortDisplay = (cardType, lastFourDigits, expirationDate) => {
            const creditCard = _getCreditCardTypeByCode(cardType);
            const expirationDateFormatted = _date.formatShortExpirationDate(expirationDate);
            creditCard.shortDisplay = creditCard.displayCardName + " X" + lastFourDigits + " " + expirationDateFormatted;
            return creditCard;
        };

        const _getCreditCardLongDisplay = (cardType, lastFourDigits, expirationDate) => {
            const creditCard = _getCreditCardTypeByCode(cardType);
            const expirationDateFormatted = _date.formatShortExpirationDate(expirationDate);
            creditCard.longDisplay = `${creditCard.displayCardName} ending in ${lastFourDigits} ${expirationDateFormatted}`;
            return creditCard;
        };

        const _unformatCreditCard = (rawCardNumber) => {
            return rawCardNumber.replace(/\D/g, '');
        };

        const _getLastFourDigits = (cardNumber) => {
            const unformattedCreditCard = _unformatCreditCard(cardNumber);
            return unformattedCreditCard.substr(-4);
        };


        self.getCreditCardByNumber = _getCreditCardByNumber;
        self.getCreditCardDisplayName = _getCreditCardDisplayName;
        self.getCreditCardMaskedDisplay = _getCreditCardMaskedDisplay;
        self.getCreditCardShortDisplay = _getCreditCardShortDisplay;
        self.getCreditCardLongDisplay = _getCreditCardLongDisplay;
        self.getCreditCardType = _getCreditCardType;
        self.getCreditCardTypeByCode = _getCreditCardTypeByCode;
        self.getLastFourDigits = _getLastFourDigits;
        self.unformatCreditCard = _unformatCreditCard;

    };
});

