define('businessServices/signup/affiliateSignup',[
    'common/time/date',
    'cookie'
], () => {
    return function() {
        let self = this;
        const AFFILIATE_SIGNUP_COOKIE = "TrestaAffiliateSignup";
        const _cookieOptions = {
            path: '/'
        };

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        self.clear = () => {
            $.cookie(AFFILIATE_SIGNUP_COOKIE, {}, _cookieOptions);
        };

        self.get = () => {
            return _promiseFactory.defer((deferredObject) => {
                let resellerId = null;
                let clickId = null;

                const data = $.cookie(AFFILIATE_SIGNUP_COOKIE);

                const date = new Date();
                if (data && data.expirationDate) {
                    var cookieDateInMilliseconds = Date.parse(data.expirationDate);
                    if (date.getTime() > cookieDateInMilliseconds) {
                        self.clear();
                    }
                    else if (data.clickId || data.resellerId) {
                        resellerId = data.resellerId;
                        clickId = data.clickId;
                    }
                }
                deferredObject.resolve({resellerId, clickId});
            });
        };

        self.init = () => {
            _initialize();
        };

        const _initialize = () => {
            $.cookie.json = true;
        };
    };
});

