define('presentation/settings/phoneNumbers/facades/phoneNumbersFacade',[
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory',
    'common/converters/phoneNumberFormatter',
    'common/storage/commonState',
    'constants/audioFileConversionStatusConstants',
    'persistence/webSocket/webSocketApp',
    'presentation/common/audioPresentationObject',
    'presentation/common/callFlow/common/callFlowBuilder',
    'presentation/common/dateTimeValue',
    'presentation/settings/phoneNumbers/presentationObjects/phoneNumberPresentationObject',
    'presentation/settings/prompts/presentationObjects/promptsPresentationObject',
    'businessServices/converters/durationTimeFormatter',
    'externalDependencies/clientWebSocket',
    'presentation/common/audioPresentationObject',
    'settings/navigationConfiguration'
], function() {
    const PhoneNumberPresentationObjectConstructor = require('presentation/settings/phoneNumbers/presentationObjects/phoneNumberPresentationObject');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const PromptsPresentationObjectConstructor = require('presentation/settings/prompts/presentationObjects/promptsPresentationObject');
    const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');

    const CallFlowBuilder = require('presentation/common/callFlow/common/callFlowBuilder');
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');

    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    const _audioFileConversionStatusConstants = require('constants/audioFileConversionStatusConstants');
    const _commonState = require('common/storage/commonState');
    const _navigationConfiguration = require('settings/navigationConfiguration');
    const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

    const _callFlowsSocket = require('externalDependencies/clientWebSocket').forApplication("callFlows");
    const _voicePromptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

    let _promiseFactory = null;
    let _webSocketApp = null;
    let _accountWebSocket = null;
    let _voicePromptAudioFactory = null;
    let _routingRules = [];

    const NO_PERMISSION_SEARCH_DATA = "None";
    const DISABLED_SEARCH_DATA = "Disabled";

    const _findAllConnectors = () => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {

            let connectorCallFlowPromise = new PromiseFactoryConstructor();

            let combinedResults = {
                phoneConnectors: [],
                availableAutoAttendants: [],
                availableSchedules: [],
                availableVoicePrompts: [],
            };

            connectorCallFlowPromise.deferIndefinitely(promise => {
                _getCallFlows()
                    .done(result => {
                        combinedResults.phoneConnectors = result.hostedNumbers;
                        combinedResults.availableAutoAttendants = result.autoAttendants;
                        combinedResults.availableSchedules = result.schedules;
                        combinedResults.availableVoicePrompts = _buildPrompts(result.voicePrompts, result.systemAudioClips);
                        promise.resolve();
                    });
            });

            connectorCallFlowPromise.wait()
                .fail(deferredObject.reject)
                .done(() => {
                    let callFlowBuilder = new CallFlowBuilder();
                    callFlowBuilder.init(_promiseFactory);
                    callFlowBuilder.availableVoicePrompts = combinedResults.availableVoicePrompts;
                    callFlowBuilder.availableSchedules = combinedResults.availableSchedules;

                    let displayConnectors = combinedResults.phoneConnectors.map(phoneConnector => {
                        _routingRules = [];
                        let displayConnector = new PhoneNumberPresentationObjectConstructor();

                        const hostedNumber = _commonState.get(phoneConnector.phoneConnectorId);
                        const isDefaultName = hostedNumber.hasDefaultName();
                        const formattedNumber = hostedNumber.phoneNumber();
                        const formattedDisplayName = hostedNumber.name();

                        displayConnector.phoneId = phoneConnector.phoneConnectorId;
                        displayConnector.phoneNumber = phoneConnector.phoneNumber;
                        displayConnector.formattedPhoneNumber(formattedNumber);
                        displayConnector.phoneNumberName(formattedDisplayName);
                        displayConnector.phoneNumberDisplayName(formattedDisplayName);
                        displayConnector.showLocation = isDefaultName;
                        displayConnector.campaignRegistrationStatus = phoneConnector.campaignRegistrationStatus;

                        displayConnector.isPortPending(phoneConnector.isPortPending);
                        if (hostedNumber.location()) {
                            displayConnector.formattedCityState = hostedNumber.location();
                        } else {
                            displayConnector.formattedCityState = "";
                        }

                        displayConnector.isInboundCallingEnabled(phoneConnector.routingRule !== null);

                        displayConnector.isInboundCallRecordingEnabled(phoneConnector.isInboundCallRecordingEnabled);
                        displayConnector.isOutboundCallRecordingEnabled(phoneConnector.isOutboundCallRecordingEnabled);
                        displayConnector.isCallRecordingPermissionInherited(phoneConnector.isCallRecordingPermissionInherited);
                        displayConnector.callRecordingSubscribers(phoneConnector.callRecordingSubscribers);
                        if (phoneConnector.isInboundCallRecordingEnabled === false && phoneConnector.isOutboundCallRecordingEnabled === false) {
                            displayConnector.recordingSearchData(DISABLED_SEARCH_DATA);
                        } else if (phoneConnector.callRecordingSubscribers && phoneConnector.callRecordingSubscribers.length === 0) {
                            displayConnector.recordingSearchData(NO_PERMISSION_SEARCH_DATA);
                        }

                        displayConnector.isOutboundCallingEnabled(phoneConnector.isOutboundCallingEnabled);
                        displayConnector.isOutboundCallingPermissionInherited(phoneConnector.isOutboundCallingPermissionInherited);
                        displayConnector.outboundCallingPermission(phoneConnector.outboundCallingPermission);
                        if (phoneConnector.isOutboundCallingEnabled === false) {
                            displayConnector.outboundSearchData(DISABLED_SEARCH_DATA);
                        } else if (phoneConnector.outboundCallingPermission && phoneConnector.outboundCallingPermission.length === 0) {
                            displayConnector.outboundSearchData(NO_PERMISSION_SEARCH_DATA);
                        }

                        displayConnector.isSmsEnabled(phoneConnector.isSmsEnabled);
                        displayConnector.isSmsPermissionInherited(phoneConnector.isSmsPermissionInherited);
                        displayConnector.smsAuthorizedMessagers(phoneConnector.smsAuthorizedMessagers);
                        if (displayConnector.isSmsEnabled() === false) {
                            displayConnector.smsSearchData(DISABLED_SEARCH_DATA);
                        } else if (phoneConnector.smsAuthorizedMessagers && phoneConnector.smsAuthorizedMessagers.length === 0) {
                            displayConnector.smsSearchData(NO_PERMISSION_SEARCH_DATA);
                        }

                        displayConnector.editConnectorUrl = _navigationConfiguration.routesById.editPhoneNumbers.baseUrl + phoneConnector.phoneConnectorId;

                        displayConnector.activeDateTime = new DateTimeValueConstructor(phoneConnector.createdDateTime);
                        displayConnector.modifiedDateTime = ko.observable(new DateTimeValueConstructor(phoneConnector.modifiedDateTime));
                        displayConnector.modifiedDateTimeDisplayValue(displayConnector.modifiedDateTime().displayValue);

                        displayConnector.isTollFree = _phoneNumberFormatter.isTollFree(phoneConnector.phoneNumber);

                        if (phoneConnector.routingRule === null) {
                            displayConnector.routeToType(null);
                            displayConnector.routeSearchData(DISABLED_SEARCH_DATA);
                        } else {
                            displayConnector.routeToType("routingRule");
                            displayConnector.routeToRoutingRuleId = phoneConnector.routingRule.routeToRoutingRuleId;
                            displayConnector.routingRule(phoneConnector.routingRule);

                            _routingRules.push(phoneConnector.routingRule);
                            callFlowBuilder.buildRoutingRules(phoneConnector.routingRule, _routingRules);
                            callFlowBuilder.formatCallFlowForDisplay(phoneConnector, _routingRules)
                                .done((callFlow) => {
                                    displayConnector.callFlow = callFlow;
                                });
                        }

                        return displayConnector;
                    });

                    deferredObject.resolve(displayConnectors, combinedResults.availableVoicePrompts, combinedResults.availableSchedules, combinedResults.availableAutoAttendants);
                });
        });
    };

    const _getAccountProfile = () => {
        return _promiseFactory.defer((deferredObject) => {
            const socketParams = {};
            _accountWebSocket.send("get_account_profile", socketParams, (result) => {
                let accountProfile = result;
                deferredObject.resolve(accountProfile);
            });
        });
    };

    const _getCallFlows = () => {
        return _promiseFactory.deferIndefinitely(deferredObject => {
            _callFlowsSocket.send("retrieveAll", {}, (callFlows, err) => {
                if (err) {
                    deferredObject.reject(new Error(err));
                } else {
                    deferredObject.resolve(callFlows);
                }
            });
        });
    };

    const _buildPrompts = (voicePrompts, systemPrompts) => {
        const displayVoicePrompts = voicePrompts.map((currentPrompt) => {
            let displayPrompt = new PromptsPresentationObjectConstructor();
            displayPrompt.isSystemPrompt = false;
            displayPrompt.voicePromptId = currentPrompt.accountVoicePromptId;
            displayPrompt.promptName = currentPrompt.name;
            if (currentPrompt.conversionStatus === _audioFileConversionStatusConstants.converted) {
                displayPrompt.conversionComplete = ko.observable(true);
                displayPrompt.conversionInProgress = ko.observable(false);
                displayPrompt.currentVoicePrompt(_getCurrentVoicePrompt(currentPrompt.audioUrl, currentPrompt.durationInMilliseconds));
            } else {
                displayPrompt.conversionComplete = ko.observable(false);
                displayPrompt.conversionInProgress = ko.observable(true);
                displayPrompt.currentVoicePrompt(_getEmptyVoicePrompt());
            }
            displayPrompt.editPromptUrl = _navigationConfiguration.routesById.editPrompt.baseUrl + currentPrompt.accountVoicePromptId;
            return displayPrompt;
        });

        const displaySystemPrompts = systemPrompts.map((systemPrompt) => {
            let displaySystemPrompt = new PromptsPresentationObjectConstructor();
            displaySystemPrompt.isSystemPrompt = true;
            displaySystemPrompt.systemAudioClipId = systemPrompt.systemAudioClipId;
            displaySystemPrompt.promptName = systemPrompt.name;
            displaySystemPrompt.conversionComplete = ko.observable(true);
            displaySystemPrompt.conversionInProgress = ko.observable(false);
            displaySystemPrompt.currentVoicePrompt(_getCurrentVoicePrompt(systemPrompt.audioUrl, systemPrompt.durationInMilliseconds));
            return displaySystemPrompt;
        });

        return displayVoicePrompts.concat(displaySystemPrompts);
    };

    const _getFormattedDurationProperty = (durationInMilliseconds) => {
        let formatter = new TimeFormatterConstructor();
        return formatter.formatTotalMilliseconds(durationInMilliseconds);
    };

    const _getCurrentVoicePrompt = (audioUrl, durationInMilliseconds) => {
        return _voicePromptAudioFactory.formatVoicePrompt(audioUrl, durationInMilliseconds);
    };

    const _getEmptyVoicePrompt = () => {
        const audioData = _voicePromptAudioFactory.getEmptyVoicePrompt();
        audioData.duration("Unknown");
        return audioData;
    };

    const _onNewPromptConverted = (callback) => {
        _voicePromptSocket.subscribeToEvent("voicePromptMp3Converted", (eventData) => {
            const {accountVoicePromptId, durationInMilliseconds} = eventData;
            let updatedAudioData = _getCurrentVoicePrompt(accountVoicePromptId);
            let formattedTimeDuration = _getFormattedDurationProperty(durationInMilliseconds);
            updatedAudioData.duration = ko.observable(formattedTimeDuration);

            callback({
                accountVoicePromptId: accountVoicePromptId,
                audioData: updatedAudioData,
            });
        });
    };

    const _deletePhoneConnector = (phoneConnectorId) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketApp.send("delete_phone_connector", {phoneConnectorId: phoneConnectorId}, function (result) {
                deferredObject.resolve();
            });
        });
    };

    const _unassignConnector = (phoneConnectorId) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketApp.send("unassign_route_to_options", {phoneConnectorId: phoneConnectorId}, function (result) {
                deferredObject.resolve(result.modifiedDateTime);
            });
        });
    };

    const _sendMessagingFeeConfirmationAutoNotation = (hostedPhoneNumber) => {
        return _promiseFactory.defer((promise) => {
            const requestData = {
                hostedPhoneNumber
            };
            _webSocketApp.send("sendMessagingFeeConfirmationAutoNotation", requestData, (result) => {
                if (result.status === "error") {
                    promise.reject(new Error(result.errorMessage));
                } else {
                    promise.resolve(result);
                }
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketActivityAppConstructor();
        _webSocketApp.init("phone_numbers_listing");

        _accountWebSocket = new WebSocketActivityAppConstructor();
        _accountWebSocket.init("account_profile");

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();
    };

    const _dispose = () => {
        _voicePromptSocket.disposeOfEvents();
    };

    return function() {
        let self = this;

        self.init = _init;
        self.findAllConnectors = _findAllConnectors;
        self.getAccountProfile = _getAccountProfile;
        self.getCurrentVoicePrompt = _getCurrentVoicePrompt;
        self.deletePhoneConnector = _deletePhoneConnector;
        self.unassignConnector = _unassignConnector;
        self.dispose = _dispose;
        self.onNewPromptConverted = _onNewPromptConverted;
        self.sendMessagingFeeConfirmationAutoNotation = _sendMessagingFeeConfirmationAutoNotation;
    };
});
