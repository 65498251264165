define('presentation/settings/phoneNumbers/validators/messagingFeeConfirmationViewModelValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function () {
    return function () {
        let self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _viewModel = null;

        const _validateCustomerAgreesToPay = (customerAgreesToFee) => {
            return customerAgreesToFee;
        };

        self.registerViewModel = (viewModel) => {
            const validationRules = new ValidationRulesConstructor();
            validationRules.field("customerAgreesToFee")
                .addValidationI18n(_validateCustomerAgreesToPay, 'messagingFeeConfirmationModal:agreementRequired');

            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
            _viewModel = viewModel;
        };

        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

