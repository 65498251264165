define('businessServices/router/viewLocatorPlugins/releaseToggleConfigPlugin',['persistence/dataProviders/releaseTogglesDataProvider'], function (releaseTogglesDataProvider) {
    var releaseConfiguration = [
        {
            releaseId: "userGroups",
            memberRoutes: [
                {
                    routeId: "userGroups",
                    hasDisabledView: false
                },
                {
                    routeId: "addUserGroup",
                    hasDisabledView: true
                },
                {
                    routeId: "editUserGroup",
                    hasDisabledView: true
                }
            ]
        }

    ];

    var releaseRouteConfiguration = [
        {
            releaseId : "userGroups",
            viewModelId : "presentation/settings/userGroup/viewModels/userGroupsViewModel",
            disabledViewId : "presentation/settings/userGroup/viewModels/userGroupsViewModel"
        }
    ];

    var viewModelToViewMap = {};

    releaseTogglesDataProvider.waitUntilInitialized()
        .done(function() {
            releaseRouteConfiguration.forEach(function(configuration) {
                var isReleaseEnabled = releaseTogglesDataProvider.isReleaseEnabled(configuration.releaseId);
                if (!isReleaseEnabled) {
                    viewModelToViewMap[configuration.viewModelId] = { viewId : configuration.disabledViewId };
                }
            });
        });

    function _convertViewModelIdToView(viewModelId) {
        return viewModelToViewMap[viewModelId];
    }

    function _getMemberRoutes(releaseId) {
        var memberRoutes = null;
        releaseConfiguration.some(function(releaseConfig) {
            if (releaseConfig.releaseId === releaseId) {
                memberRoutes = releaseConfig.memberRoutes;
                return true;
            } else {
                return false;
            }
        });
        return memberRoutes;
    }

    return {
        convertViewModelIdToView : _convertViewModelIdToView,
        getMemberRoutes : _getMemberRoutes
    };
});

