define('presentation/settings/messagingCompliance/validators/authorizedSignatureActionModalValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'presentation/settings/messagingCompliance/viewModels/authorizedSignatureActionModalViewModel'
], function(
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules
) {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/viewModels/authorizedSignatureActionModalViewModel') } AuthorizedSignatureActionModalViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { AuthorizedSignatureActionModalViewModel } */
        let _viewModel = null;

        self.registerViewModel = (/** @type { AuthorizedSignatureActionModalViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.field("authorizedSignature")
                .addValidationI18n(_commonValidator.isStringWithValue, 'authorizedSignatureActionModal:authorizedSignatureRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

