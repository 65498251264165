define('constants/accountStatusEnumerations',[], function() {
    return {
        status: {
            active: "Active",
            closed: "Closed"
        },
        billingStatus: {
            ok: "OK",
            ccDeclined: "CC Declined",
            nonPayment: "Non-Payment",
            badDebt: "Bad Debt",
            collections: "Collections"
        },
        fraudStatus: {
            ok: "OK",
            fraudReview: "Fraud Review",
            abusiveCustomer: "Abusive Customer",
            creditCardFraud: "Credit Card Fraud",
            other: "Other"
        }
    };
});

