define('presentation/common/filterSortMenu/viewModels/filterSortMenuViewModel',[], function() {
    return function() {
        /** @typedef {import('presentation/common/filterSortMenu/viewModels/filterSortMenuViewModel')} FilterSortMenuViewModel */

        /** @type {FilterSortMenuViewModel}*/
        const self = this;

        /** @type {IFilterSortMenuSettings}*/
        let _settings = null;

        /**
         * @param {IShowOption[]} options
         * @returns IFormattedShowOption[]
         * */
        const _formatSortByOptions = (options) => {
            const optionsCopy = [...options];
            return optionsCopy.map((option) => {
                /** @type {IFormattedShowOption}*/
                const formattedOption = Object.assign({}, option);
                const label = option.textI18n;
                // Dropdown
                formattedOption.isSelected = ko.pureComputed(() => option.value === self.selectedSortByOption());
                formattedOption.displayText = label;

                formattedOption.optionClick = () => {
                    if (option.value !== self.selectedSortByOption()) {
                        self.selectedSortByOption(option.value);
                        self.hideFilterMenu();
                    }
                };

                return formattedOption;
            });
        };

        const _formatSelectableItems = (options) => {
            const optionsCopy = [...options];
            return optionsCopy.map((option) => {
                const formattedOption = Object.assign({}, option);
                // Dropdown
                formattedOption.isSelected = ko.observable(self.selectedItems().some((item) => item === option.value));
                formattedOption.tooltipText = self.tooltip();
                formattedOption.isTooltipEnabled = ko.observable(false);

                formattedOption.isDisabled = ko.pureComputed(() => {
                    const isSelected = self.selectedItems().some((item) => item === option.value);
                    return isSelected && self.selectedItems().length <= 1;
                });

                // Filter pill
                formattedOption.pillLabel = option.label;
                formattedOption.showPillStatusIndicator = false;
                formattedOption.showPillIcon = true;
                formattedOption.pillIconName = option.pillIconName;

                formattedOption.optionClick = () => {
                    formattedOption.isTooltipEnabled(false);
                    const currentSelection = self.selectedItems();
                    let updatedSelection;

                    const alreadySelected = currentSelection.some((item) => item === option.value);
                    if (alreadySelected) {
                        updatedSelection = currentSelection.filter((item) => item !== option.value);
                        if (updatedSelection.length === 0) {
                            if (alreadySelected) {
                                formattedOption.isTooltipEnabled(true);

                                const twoSeconds = 2 * 1000;
                                setTimeout(() => formattedOption.isTooltipEnabled(false), twoSeconds);
                            }
                            return;
                        }
                    } else {
                        updatedSelection = currentSelection;
                        updatedSelection.push(option.value);
                    }

                    self.selectedItems(updatedSelection);

                };

                return formattedOption;
            });
        };

        const _formatPillOptions = (formattedSelectableItems) => {
            const selectedItems = formattedSelectableItems.filter((option) => option.isSelected());
            if (!selectedItems) {
                return null;
            }

            return selectedItems;
        };

        self.isExpanded = ko.observable(false).toggleable();
        self.isDisabled = ko.observable(false);

        self.tooltip = ko.observable();

        self.selectedSortByOption = ko.observable();
        self.sortByOptions = ko.observableArray([]);

        self.selectedItems = ko.observableArray([]);
        self.selectableItems = ko.observableArray([]);

        self.scrollEventSource = 'filterMenu';
        self.scrollPosition = ko.observable(0);

        self.showFilterPills = ko.pureComputed(() => {
            const numberSelectableItems = self.selectableItems().length;
            const selectedItemsCount = self.selectedItems().length;
            return numberSelectableItems > selectedItemsCount && selectedItemsCount > 0;
        });

        self.formattedSortByOptions = ko.pureComputed(() => _formatSortByOptions(self.sortByOptions()));
        self.formattedSelectableItems = ko.pureComputed(() => _formatSelectableItems(self.selectableItems()));
        self.formattedFilterPills = ko.pureComputed(() => self.showFilterPills() ? _formatPillOptions(self.formattedSelectableItems()) : null);

        self.hideFilterMenu = () => {
            self.isExpanded(false);
        };

        self.filterClicked = () => {
            if (self.isDisabled()) {
                return;
            }
            self.isExpanded(!self.isExpanded());
        };

        /** @type {FilterSortMenuViewModel["activate"]}*/
        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (ko.isObservable(_settings.tooltip)) {
                self.tooltip = _settings.tooltip;
            } else {
                self.tooltip(_settings.tooltip);
            }

            if (ko.isObservable(_settings.isDisabled)) {
                self.isDisabled = _settings.isDisabled;
            } else {
                self.isDisabled(_settings.isDisabled);
            }

            if (_settings.selectedSortByOption) {
                self.selectedSortByOption = _settings.selectedSortByOption;
            }

            if (ko.isObservable(_settings.sortByOptions)) {
                self.sortByOptions = _settings.sortByOptions;
            } else {
                self.sortByOptions(_settings.sortByOptions);
            }

            if (_settings.selectedItems) {
                self.selectedItems = _settings.selectedItems;
            }

            if (ko.isObservable(_settings.selectableItems)){
                self.selectableItems = _settings.selectableItems;
            } else {
                self.selectableItems(_settings.selectableItems);
            }
        };
    };
});

