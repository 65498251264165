/**
Define values for UI animations that require a setTimeout.
Format: path_to_viewModel_[setting]: value
**/
define('settings/uiAnimationsConfiguration',[],function() {
    return {
        presentation_navigation_viewModels_sidebarNavigationViewModel_collapseSidebar: 450,
        presentation_navigation_viewModels_sidebarNavigationViewModel_expandSidebar: 450,
        presentation_shell_viewModels_shellViewModel_collapseContentPane: 450,
        presentation_shell_viewModels_shellViewModel_switchCollapsedToExpanded: 300,
        presentation_userProfile_viewModels_userProfileViewModel_hideUserProfileOptions: 500,
        presentation_common_modal_dialog: 180,
        presentation_common_modal_blockout: 400,
        presentation_common_datagrid_check_content_for_scroll_delay: 350,
        presentation_forEachWithAnimation_animate_in_add_class: 100,
        presentation_forEachWithAnimation_animate_out_remove_class: 100,
        presentation_forEachWithAnimation_animate_out_remove_item: 400,
        presentation_welcome_info_capture_modal_slide: 600,
        presentation_settings_billing_bill_cycle_to_date_details_expand_collapse_speed: 320
    };
});

