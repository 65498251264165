define('presentation/common/dateTimeStampValue',['common/converters/passedTimeSpanFormatter',
    'common/time/date'], function(){

    return function(dateISOString) {
        var self = this;

        var PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        var DateConstructor = require('common/time/date');
        var _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();
        var _date = new DateConstructor();
        var _now = new DateConstructor();

        _date.setFromDateString(dateISOString);
        _now.setNow();

        var formatted = _passedTimeSpanFormatter.format(dateISOString);
        var formattedTime = _passedTimeSpanFormatter.formatTime(_date);
        var formattedDate = _passedTimeSpanFormatter.formatDate(_date);

        if(_date.isOnSameDay(_now)) {
            self.filterValue = formattedTime;
            self.displayValue = formattedTime;
        } else {
            self.filterValue = formattedDate + " at " + formattedTime;
            self.displayValue = formattedDate + " at " + formattedTime;
        }

        self.sortValue = dateISOString;
    };
});

