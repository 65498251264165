define('persistence/repositories/callRecordingRepository',['persistence/webSocket/webSocketApp'], function() {
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _webSocketApp = null;

        self.update = function(callRecordingEntity) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "call_recording": callRecordingEntity
                };
                _webSocketApp.send("update", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.delete = function(callRecordingId) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "call_recording_id": callRecordingId
                };
                _webSocketApp.send("delete", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("call_recording");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

