define('presentation/settings/billing/history/facades/billingHistoryFacade',[
    'businessServices/converters/currencyFormatter',
    'businessServices/creditCards/creditCardTypes',
    'externalDependencies/kazooApi',
    'common/promises/promiseFactory',
    'common/time/date',
    'common/converters/passedTimeSpanFormatter',
    'constants/billingHistoryConstants',
    'presentation/common/dateTimeValue',
    'presentation/settings/billing/history/presentationObjects/billingHistoryPresentationObject',
    'presentation/settings/billing/history/presentationObjects/billingHistoryTransactionPresentationObject'
], function () {
    const BillingHistoryPresentationObjectConstructor = require('presentation/settings/billing/history/presentationObjects/billingHistoryPresentationObject');
    const BillingHistoryTransactionPresentationObjectConstructor = require('presentation/settings/billing/history/presentationObjects/billingHistoryTransactionPresentationObject');
    const CreditCardConstructor = require('businessServices/creditCards/creditCardTypes');
    const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
    const _billingHistoryEnumerations = require('constants/billingHistoryConstants');
    const _creditCardTypes = new CreditCardConstructor();
    const _currencyFormatter = new CurrencyFormatterConstructor();
    const _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

    let _kazooApi = null;
    let _promiseFactory = null;

    const _getBillingHistory = () => _promiseFactory.defer((deferredObject) => {
        _kazooApi.callAccount('/lobby_billing_history', 'GET')
            .done((result) => {
                let billingHistoryResponse = result.data.data;
                let billingHistoryPresentationObject = new BillingHistoryPresentationObjectConstructor();
                billingHistoryPresentationObject.currentBalance = _currencyFormatter.formatUSDForDisplay(billingHistoryResponse.currentBalance);
                billingHistoryPresentationObject.pastDueBalance = _currencyFormatter.formatUSDForDisplay(billingHistoryResponse.pastDueBalance);
                billingHistoryPresentationObject.isPastDueBalance = (billingHistoryPresentationObject.pastDueBalance !== "$0.00");

                billingHistoryPresentationObject.transactions = billingHistoryResponse.transactions.map((transaction) => {
                    let billingHistoryTransactionPresentationObject = new BillingHistoryTransactionPresentationObjectConstructor();

                    if (transaction.transactionId !== "") {
                        billingHistoryTransactionPresentationObject.transactionId = transaction.transactionId;
                    } else {
                        billingHistoryTransactionPresentationObject.transactionId = "-";
                    }

                    billingHistoryTransactionPresentationObject.outcome = transaction.outcome;
                    billingHistoryTransactionPresentationObject.amount =  Number(_currencyFormatter.formatUSD(transaction.amount));
                    billingHistoryTransactionPresentationObject.amountDisplayValue = _currencyFormatter.formatUSDForDisplay(transaction.amount);
                    billingHistoryTransactionPresentationObject.description = transaction.description;

                    let dateObject = null;

                    switch (transaction.outcome) {
                        case _billingHistoryEnumerations.outcome.approved:
                            dateObject = new DateTimeValueConstructor(transaction.date);
                            billingHistoryTransactionPresentationObject.isOutcomeApprove(true);
                            _setPaymentMethod(billingHistoryTransactionPresentationObject, transaction);

                            if (transaction.billStatement) {
                                if (transaction.description.toLowerCase() === "signup" || transaction.description.toLowerCase() === "reactivation") {
                                    billingHistoryTransactionPresentationObject.isBillingHistoryPdfAvailable(true);
                                    billingHistoryTransactionPresentationObject.statementId = transaction.billStatement;
                                }
                            }

                            break;
                        case _billingHistoryEnumerations.outcome.declined:
                            dateObject = new DateTimeValueConstructor(transaction.date);
                            billingHistoryTransactionPresentationObject.isOutcomeDecline(true);
                            billingHistoryTransactionPresentationObject.outcome = transaction.outcome;
                            _setPaymentMethod(billingHistoryTransactionPresentationObject, transaction);
                            break;
                        case _billingHistoryEnumerations.outcome.refunded:
                            let refundDateWithoutTime = _passedTimeSpanFormatter.formatDateStringUTC(transaction.date);
                            dateObject = new DateTimeValueConstructor(refundDateWithoutTime);
                            billingHistoryTransactionPresentationObject.isOutcomeRefunded(true);
                            billingHistoryTransactionPresentationObject.amountDisplayValue = _currencyFormatter.formatUSDForDisplay(transaction.amount);
                            _setPaymentMethod(billingHistoryTransactionPresentationObject, transaction);
                            break;
                        case _billingHistoryEnumerations.outcome.statement:
                            let statementDateWithoutTime = _passedTimeSpanFormatter.formatDateStringUTC(transaction.date);
                            dateObject = new DateTimeValueConstructor(statementDateWithoutTime);
                            billingHistoryTransactionPresentationObject.isBillingHistoryPdfAvailable(true);
                            billingHistoryTransactionPresentationObject.paymentMethod = "-";
                            billingHistoryTransactionPresentationObject.outcome = "-";
                            billingHistoryTransactionPresentationObject.statementId = transaction.billStatement;
                            break;
                        case _billingHistoryEnumerations.outcome.applied:
                            dateObject = new DateTimeValueConstructor(transaction.date);
                            billingHistoryTransactionPresentationObject.outcome = "-";
                            billingHistoryTransactionPresentationObject.paymentMethod = "-";
                            break;
                        default:
                            dateObject = new DateTimeValueConstructor(transaction.date);
                            billingHistoryTransactionPresentationObject.outcome = "-";
                    }

                    billingHistoryTransactionPresentationObject.date = dateObject.sortValue;
                    billingHistoryTransactionPresentationObject.dateDisplayValue = dateObject.formattedBillingHistoryDate;

                    return billingHistoryTransactionPresentationObject;
                });
                deferredObject.resolve(billingHistoryPresentationObject);
            })
            .fail((error) => {
                deferredObject.reject(error);
            });
    });

    const _setPaymentMethod = (billingHistoryTransactionPresentationObject, transaction) => {
        if (transaction.expirationDate !== "") {
            let creditCard = _creditCardTypes.getCreditCardShortDisplay(transaction.cardType, transaction.lastFourDigits, transaction.expirationDate);
            billingHistoryTransactionPresentationObject.paymentMethod = creditCard.shortDisplay;
        } else {
            billingHistoryTransactionPresentationObject.paymentMethod = "-";
        }
    };

    const _getStatementPdfUrl = (statementId) => {
        return _promiseFactory.defer((promise) => {
            _kazooApi.callAccount('/lobby_billing_history/' + statementId, 'GET')
                .done((result) => {
                    promise.resolve(result.data.data.StatementUrl);
                })
                .fail((error) => {
                    promise.reject(error);
                });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
        let KazooConstructor = require('externalDependencies/kazooApi');
        _kazooApi = new KazooConstructor();
    };

    return function () {
        let self = this;

        self.init = _init;

        self.getBillingHistory = _getBillingHistory;
        self.getStatementPdfUrl = _getStatementPdfUrl;
    };
});

