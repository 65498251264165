var config = {};
config.externalDependencies = {};
config.externalDependencies.kazooApiServerUrl = "https://lobbyapi.tresta.com:443/v1/";
config.externalDependencies.clientApiServerUrl = "https://www.tresta.com/api/";
config.externalDependencies.webSocketServerUrl = "https://lobbysocket.tresta.com:443/";

config.isInDebugMode = true;
config.signupExpireTimeInMilliseconds = 1000 * 60 * 60 * 24 * 365;
config.environmentDomain = "localDebugging";
config.environmentHostName = "localDebugging";
config.frontendDomain = "tresta.com";
config.googleAnalyticsEnabled = "true";
config.googleAnalyticsTrackingId = "UA-64698108-1";
config.googleAdWordsConversionId = "947683799";
config.googleAdWordsConversionLabel = "Crt5CIqt314Q14PywwM";
config.awsS3BucketName = "tresta-trestaprod01";

define("settings/environmentSettings", function(){});

