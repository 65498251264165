define('presentation/settings/phoneNumbers/viewModels/addPhoneNumberSummaryViewModel',[
    'businessServices/numberProvisioning/numberProvisioningStore',
    'businessServices/router/router',
    'businessServices/authentication/sessionAccountInfo',
    'constants/numberPickerConstants',
    'constants/smsCampaignRegistrationConstants',
    'presentation/common/window/windowControl',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;
        const _numberPickerConstants = require('constants/numberPickerConstants');
        const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');
        const _router = require('businessServices/router/router');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _windowControl = require('presentation/common/window/windowControl');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _urlFormatter = require('common/url/urlFormatter');

        let _numberProvisioningStore = null;

        const _formatHostedNumbersForWarning = (smsEnabledLocalHostedNumbers) => {
            return smsEnabledLocalHostedNumbers.map((hostedNumber) => {
                return {
                    number: hostedNumber.formattedPhoneNumber,
                    location: hostedNumber.location,
                    hasLocation: hostedNumber.location.length > 0
                };
            });
        };

        const _initializeObservables = () => {
            self.successfullyReservedNumbers(_numberProvisioningStore.getSuccessfulNumbers());
            self.failedNumbers(_numberProvisioningStore.getFailedNumbers());
            self.numberType(_numberProvisioningStore.getNumberType());
            self.smsPhoneNumberLimit(_numberProvisioningStore.getSmsPhoneNumberLimit());
            self.smsPhoneNumberLimitReason(_numberProvisioningStore.getSmsPhoneNumberLimitReason());
            self.smsEnabledLocalHostedNumbers(
                _formatHostedNumbersForWarning(_numberProvisioningStore.getSmsEnabledLocalHostedNumbers())
            );

            _numberProvisioningStore.clear();
        };

        self.numberType = ko.observable();
        self.successfullyReservedNumbers = ko.observable([]);
        self.failedNumbers = ko.observable([]);
        self.smsEnabledLocalHostedNumbers = ko.observable([]);
        self.smsPhoneNumberLimit = ko.observable();
        self.smsPhoneNumberLimitReason = ko.observable();
        self.pricingUrl = null;

        self.showFailedNumbers = ko.pureComputed(() => self.failedNumbers().length > 0);
        self.messagingComplianceRequiredLimit = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.NO_MESSAGING_COMPLIANCE);
        self.needMoreComplianceInfoLimit = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.REACHED_SP_LIMIT);
        self.personalAccountLimit = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.REACHED_PERSONAL_ACCOUNT_LIMIT);
        self.tcrRegistrationFailed = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.TCR_REGISTRATION_FAILED);

        self.showSmsLimitWarningBox = ko.pureComputed(() => {
            return (self.numberType() === _numberPickerConstants.numberTypes.local) &&
                (self.successfullyReservedNumbers().length > self.smsPhoneNumberLimit()) &&
                (self.smsPhoneNumberLimit() >= 0);
        });

        self.openTcrPage = () => _windowControl.openTcrUrl();
        self.openMessagingCompliancePage = () => _windowControl.openNewWindow(_navigationConfiguration.routesById.messagingCompliance.url);

        self.isSelectedPhoneNumberSmsEnabled = (index) => {
            if (self.numberType() === _numberPickerConstants.numberTypes.tollFree) {
                return !self.messagingComplianceRequiredLimit();
            }

            if (self.numberType() === _numberPickerConstants.numberTypes.port) {
                return false;
            }

            return (index < self.smsPhoneNumberLimit()) ||
                (self.smsPhoneNumberLimit() < 0) &&
                !self.messagingComplianceRequiredLimit();
        };

        self.close = () => {
            _router.navigate(_navigationConfiguration.routesById.phoneNumbers.url);
        };

        self.activate = () => {
            const NumberProvisioningStoreConstructor = require('businessServices/numberProvisioning/numberProvisioningStore');
            _numberProvisioningStore = new NumberProvisioningStoreConstructor();
            _numberProvisioningStore.init();

            return _initialize();
        };

        const _initialize = () => {
            self.pricingUrl = _urlFormatter.buildFrontendUrl(["pricing"]);
            _initializeObservables();
        };
    };
});

