define('presentation/common/contactAvatar/viewModels/contactAvatarViewModel',[
        'common/converters/userFormatter',
        'common/storage/commonState'
    ],
function(
    /** @type import('common/converters/userFormatter') */
    UserFormatterConstructor,
    /** @type typeof import('common/storage/commonState') */
    CommonState
){
    return function() {
        const self = this;

        /** @type {IContactAvatarProperties} */
        let _settings = null;

        /** @type {IDisposable[]} */
        let _disposables = [];

        const _userFormatter = new UserFormatterConstructor();

        self.contact = ko.observable(null);
        self.avatarUrl = ko.observable('');
        self.userGroupAvatars = ko.observableArray();
        self.initials = ko.observable('');
        self.cssClass = '';

        self.activeUsers = ko.pureComputed(() => {
            return CommonState.users().filter((u) => u.status() === 'active');
        });

        self.activeUserGroups = ko.pureComputed(() => {
            return CommonState.userGroups().filter((g) => g.status() === 'active');
        });

        self.computedInitials = ko.pureComputed(() => {
            if (self.initials()){
                return self.initials();
            }

            const contact = self.contact();
            if (contact !== null) {
                if (contact.initials && contact.initials() !== '') {
                    return contact.initials();
                } else {
                    const { name } = self.contact();
                    return _userFormatter.formatUserInitialsFromFullName(name());
                }
            }

            return '';
        });

        self.isContact = ko.pureComputed(() => {
            const contact = self.contact();
            if (contact !== null) {
                const isSystemContact = contact.isUser || contact.isUserGroup;
                return !isSystemContact && contact.accountContactId !== '';
            }
            return _isValidAvatarUrl(self.avatarUrl());
        });

        self.isUser = ko.pureComputed(() => {
            const contact = self.contact();
            if (contact !== null) {
                return contact.isUser;
            }
            return false;
        });

        self.isUserGroup = ko.pureComputed(() => {
            const contact = self.contact();
            if (contact !== null) {
                return contact.isUserGroup;
            }
            return false;
        });

        self.contactGroupMembers = ko.pureComputed(() => {
            if (self.isUserGroup() === false) {
                return [];
            }

            const activeGroups = self.activeUserGroups();
            const userGroupId = self.contact().accountUserGroupId || self.contact().id;
            const _commonStateGroup = activeGroups.find(g => g.id === userGroupId);
            return _commonStateGroup ? _commonStateGroup.members() : [];
        });

        self.showAvatar = ko.pureComputed(() => {
            if (self.contact() !== null) {
                if (self.isContact()) {
                    const { avatarUrl } = self.contact();
                    return self.contact().hasAvatar() && _isValidAvatarUrl(avatarUrl());
                }
                if (self.isUser()) {
                    const { avatar } = self.contact();
                    return avatar() && _isValidAvatarUrl(avatar().avatarUrl());
                }
                if (self.isUserGroup()) {
                    const { avatars } = self.contact();
                    return avatars() && avatars().filter((avatar) => avatar.showImage).length > 1;
                }
            }
            return _isValidAvatarUrl(self.avatarUrl());
        });


        const _isValidAvatarUrl = (/** @type {string} */ avatarUrl) => {
            return avatarUrl !== null && avatarUrl !== '';
        };

        const _maybePrepareGroupAvatarForDisplay = () => {
            if (self.isUserGroup() === false) {
                return;
            }

            const members = self.contactGroupMembers();
            self.userGroupAvatars.removeAll();

            const quadMembers = self.activeUsers()
                .filter(u => members.includes(u.id))
                .slice(0 , 4)
                .map((m, i) => {
                    const avatar = m.avatar();
                    avatar.containerCss = _prepareAvatarForDisplay(i);
                    avatar.initials = _userFormatter.formatUserInitialsFromFullName(m.name());
                    return avatar;
                });
            self.userGroupAvatars(quadMembers);
        };

        const _prepareAvatarForDisplay = (/** @type {number} */ index) => {
            const members = self.contactGroupMembers();
            if (members.length > 3) {
                switch (index) {
                    case 0:
                        return 'icon-group-quarter icon-group-quarter-top-left';
                    case 1:
                        return 'icon-group-quarter icon-group-quarter-top-right';
                    case 2:
                        return 'icon-group-quarter icon-group-quarter-bottom-left';
                    case 3:
                        return 'icon-group-quarter icon-group-quarter-bottom-right';
                    default:
                        return '';
                }
            } else if (members.length === 3) {
                switch (index) {
                    case 0:
                        return 'icon-group-half icon-group-half-left';
                    case 1:
                        return 'icon-group-quarter icon-group-quarter-top-right';
                    case 2:
                        return 'icon-group-quarter icon-group-quarter-bottom-right';
                    default:
                        return '';

                }
            }  else if (members.length === 2)  {
                switch (index) {
                    case 0:
                        return 'icon-group-half icon-group-half-left';
                    case 1:
                        return 'icon-group-half icon-group-half-right';
                    default:
                        return '';
                }
            } else {
                return '';
            }
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        /**
         * @param {IContactAvatarProperties} settings
         */
        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            // use the contact object's avatars
            if (_settings.contact) {
                if (ko.isObservable(_settings.contact)) {
                    self.contact = _settings.contact;
                } else {
                    self.contact(_settings.contact);
                }
            }

            // directly set the contact avatar and initials
            if (_settings.avatarUrl) {
                if (ko.isObservable(_settings.avatarUrl)) {
                    self.avatarUrl = _settings.avatarUrl;
                } else {
                    self.avatarUrl(_settings.avatarUrl);
                }
            }
            if (_settings.initials) {
                self.initials = _settings.initials;
            }

            // control avatar styles directly from their display context
            if (_settings.cssClass) {
                self.cssClass = ko.unwrap(_settings.cssClass);
            }

            _disposables.push(self.contactGroupMembers.subscribe(_maybePrepareGroupAvatarForDisplay));

            _maybePrepareGroupAvatarForDisplay();
        };
    };
});

