define('presentation/settings/closeAccount/viewModels/closeAccountActionModalViewModel',[
    'common/promises/promiseFactory',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        self.title = ko.pureComputed(() => _i18n.t('closeAccountActionModal:title'));

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

