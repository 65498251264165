define('presentation/settings/billing/paymentMethod/facades/addPaymentMethodFacade',['businessServices/creditCards/creditCardTypes',
        'common/time/date',
        'entities/paymentMethodEntity',
        'persistence/repositories/paymentMethodRepository',
        'persistence/dataProviders/paymentMethodDataProvider'
        ],

    function() {
        const CreditCardTypesConstructor = require('businessServices/creditCards/creditCardTypes');
        const DateConstructor = require('common/time/date');
        const PaymentMethodEntityConstructor = require('entities/paymentMethodEntity');
        const PaymentMethodRepositoryConstructor = require('persistence/repositories/paymentMethodRepository');
        const _creditCardTypes = new CreditCardTypesConstructor();
        const _paymentMethodRepository = new PaymentMethodRepositoryConstructor();
        const PaymentMethodDataProviderConstructor = require('persistence/dataProviders/paymentMethodDataProvider');
        const _paymentMethodDataProvider = new PaymentMethodDataProviderConstructor();

        let _promiseFactory = null;

        const _addPaymentMethod = (cardHolderName, cardNumber, month, year, cvvSecurityCode, country, postalCode) => {
            const paymentMethodEntity = new PaymentMethodEntityConstructor();
            const date = new DateConstructor();
            paymentMethodEntity.isDefault = false;
            paymentMethodEntity.cardHolderName = cardHolderName.trim();
            paymentMethodEntity.creditCardNumber = _creditCardTypes.unformatCreditCard(cardNumber);
            paymentMethodEntity.expirationDate = date.getExpirationDate(year, month);
            paymentMethodEntity.cvvSecurityCode = cvvSecurityCode.trim();
            paymentMethodEntity.cardType = _creditCardTypes.getCreditCardType(paymentMethodEntity.creditCardNumber);
            paymentMethodEntity.displayedCardName = _creditCardTypes.getCreditCardDisplayName(paymentMethodEntity.cardType);
            paymentMethodEntity.country = country;
            paymentMethodEntity.postalCode = postalCode;

            return _paymentMethodRepository.add(paymentMethodEntity);
        };

        const _editPaymentMethod = (paymentMethodId, cardHolderName, month, year, cvv, country, postalCode) => {
            return _promiseFactory.defer((deferredObject) => {
                const paymentMethodEntity = new PaymentMethodEntityConstructor();
                const date = new DateConstructor();
                paymentMethodEntity.paymentMethodId = paymentMethodId;
                paymentMethodEntity.cardHolderName = cardHolderName.trim();
                paymentMethodEntity.expirationDate = date.getExpirationDate(year, month);
                paymentMethodEntity.country = country;
                paymentMethodEntity.cvvSecurityCode = cvv;
                paymentMethodEntity.postalCode = postalCode;

                _paymentMethodRepository.edit(paymentMethodEntity)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        const _getPaymentMethodById = (paymentMethodId) => {
            return _promiseFactory.defer((deferredObject) => {
                _paymentMethodDataProvider.getPaymentMethodById(paymentMethodId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
            _paymentMethodDataProvider.init();
            _paymentMethodRepository.init();
        };

        return function() {
            let self = this;

            self.init = _init;
            self.addPaymentMethod = _addPaymentMethod;
            self.editPaymentMethod = _editPaymentMethod;
            self.getPaymentMethodById = _getPaymentMethodById;
        };
    });
