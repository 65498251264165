define('businessServices/ipQualityScore/ipQualityScore',['common/promises/promiseFactory'
], function() {
    const IpQualityScoreSingleton = function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _isLoaded = false;

        let _deviceId = null;
        let _ipAddress = null;
        let _requestId = null;

        const _init = () => {
            return _promiseFactory.defer((promise) => {
                /* jshint ignore:start */
                if (typeof Startup !== 'undefined') {
                    Startup.Init();
                    Startup.AfterResult((result) => {
                        if (result.success) {
                            _deviceId = result.device_id;
                            _ipAddress = result.ip_address;
                            _requestId = result.request_id;
                            _isLoaded = true;
                        }
                        promise.resolve();
                    });
                    Startup.Resume();
                } else {
                    promise.resolve();
                }
                /* jshint ignore:end */
            });
        };

        _init();

        self.getDeviceId = () => {
            if (_isLoaded) {
                return _deviceId;
            }
        };

        self.getIpAddress = () => {
            if (_isLoaded) {
                return _ipAddress;
            }
        };

        self.getRequestId = () => {
            if(_isLoaded) {
                return _requestId;
            }
        };

        self.waitForInitComplete = () => {
            return _promiseFactory.wait();
        };
    };

    if (!IpQualityScoreSingleton._singletonInstance) {
        IpQualityScoreSingleton._singletonInstance = new IpQualityScoreSingleton();
    }
    return IpQualityScoreSingleton._singletonInstance;
});

