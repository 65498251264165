define('presentation/settings/messagingCompliance/facades/otpVerificationFacade',[
    'common/promises/promiseFactory',
    'persistence/repositories/messagingComplianceRepository'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('persistence/repositories/messagingComplianceRepository') */
    MessagingComplianceRepository
) {
    /** @typedef { import('presentation/settings/messagingCompliance/facades/otpVerificationFacade') } OtpVerificationFacade } */

    const _promiseFactory = new PromiseFactory();

    const _messagingComplianceRepository = new MessagingComplianceRepository();

    const _requestSmsPin = () => {
        return _promiseFactory.defer((promise) => {
            _messagingComplianceRepository.requestSmsPin()
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    const _submitSmsPin = (/** @type { string } */ smsPin) => {
        return _promiseFactory.defer((promise) => {
            /** @type { ISubmitSmsPinRequest } */
            const request = {
                smsPin
            };

            _messagingComplianceRepository.submitSmsPin(request)
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    const _dispose = () => {
        _messagingComplianceRepository.dispose();
    };

    const _init = () => {

    };

    return function() {
        /** @typeof { OtpVerificationFacade } */
        let self = this;

        self.init = _init;

        self.dispose = _dispose;

        self.requestSmsPin = _requestSmsPin;
        self.submitSmsPin = _submitSmsPin;
    };
});
