define('presentation/acceptUserInvite/validators/createUserDevicesValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/validPhoneNumberSpecification',
    'common/converters/phoneNumberFormatConverter',
    'constants/validationMessageEnumerations',
    'constants/countryEnumerations'
],
function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _countryEnumerations = require('constants/countryEnumerations');

        const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

        const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatConverter');
        const _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

        let _facade = null;
        let _viewModel = null;
        let _commonValidator = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("devicePhoneNumber")
                .addValidationI18n(_commonValidator.isStringWithValue, 'acceptUserInvite:phoneNumberRequired')
                .addValidationI18n(_isPhoneNumberValid, 'phoneNumberInvalid')
                .addValidationI18n(_isPhoneNumberUniqueToUser, 'devicePhoneNumberUnique')
                .addValidationI18n(_isAllowedPhoneNumber, 'restrictedPhoneNumber');

            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _isPhoneNumberValid = (phoneNumber) => {
            if (_commonValidator.isStringWithValue(phoneNumber)) {
                const cleanPhoneNumber = _phoneNumberFormatConverter.clean(phoneNumber);
                return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
            } else {
                return true;
            }
        };

        const _isPhoneNumberUniqueToUser = (phoneNumber) => {
            if (_commonValidator.isStringWithValue(phoneNumber)) {
                return _viewModel.isDevicePhoneNumberUniqueToUser(phoneNumber);
            } else {
                return true;
            }
        };

        const _isAllowedPhoneNumber = (phoneNumber) => {
            return _validPhoneNumberSpecification.isAllowedPhoneNumber(phoneNumber);
        };
    };
});

