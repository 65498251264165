define('presentation/signup/validators/signupPhoneNumberPortValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
],
    function() {
        return function() {
            let self = this;

            let _commonValidator = null;
            let _facade = null;
            let _viewModel = null;

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            self.registerViewModel = function(viewModel, facade) {
                let validationRules = new ValidationRulesConstructor();
                validationRules.field("currentCarrier")
                    .addValidationKeyUp(_validateCurrentCarrierRequired)
                    .addValidationI18n(_validateCurrentCarrierRequired, 'signupPhoneNumberPort:carrierRequired');

                _viewModel = viewModel;
                _facade = facade;
                _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
            };

            self.validate = function() {
                return _commonValidator.validate();
            };

            const _validateCurrentCarrierRequired = (fieldValue) => {
                return _commonValidator.isStringWithValue(fieldValue);
            };
        };
    });

