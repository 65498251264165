define('presentation/settings/blocking/facades/blockingFacade',[
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/selectControlAvatarType',
    'constants/userGroupConstants',
    'i18next',
    'persistence/repositories/blockingRepository'
], function(
    /** @type import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/storage/commonState') */
    _commonState,
    /** @type typeof import('constants/selectControlAvatarType') */
    SelectControlAvatarType,
    /** @type typeof import('constants/userGroupConstants') */
    UserGroupConstants,
    /** @type import('i18next') */
    _i18n,
    /** @type typeof import('persistence/repositories/blockingRepository') */
    BlockingRepository
) {
    /** @typedef { import('presentation/settings/blocking/facades/blockingFacade') } BlockingFacade */

    return function (/** @type { PromiseFactory } */ promiseFactory) {

        /**@type { BlockingFacade }*/
        const self = this;

        const _promiseFactory = promiseFactory;

        const _blockingRepository = new BlockingRepository();

        //#region private methods
        /** @type { (commonStateUser: any) => IBlockingPermissionPresentationObject } */
        const _commonStateUserToPresentationObject = (commonStateUser) => {
            return {
                id: commonStateUser.id,
                avatar: commonStateUser.avatar,
                userId: commonStateUser.id,
                userGroupId: null,
                displayName: commonStateUser.name,
                avatarType: SelectControlAvatarType.user,
                removeToolTipText: "",
                isReadOnly: ko.observable(false)
            };
        };

        /** @type { (commonStateUserGroup: any) => IBlockingPermissionPresentationObject } */
        const _commonStateUserGroupToPresentationObject = (commonStateUserGroup) => {
            let systemAdminsGroup = _commonState.get(UserGroupConstants.systemAdminsGroupId);
            let isSystemAdminsGroup = commonStateUserGroup.id === systemAdminsGroup.id;

            return {
                id: commonStateUserGroup.id,
                avatar: commonStateUserGroup.avatars,
                userId: null,
                userGroupId: commonStateUserGroup.id,
                displayName: commonStateUserGroup.name,
                avatarType: SelectControlAvatarType.userGroup,
                removeToolTipText: isSystemAdminsGroup ? _i18n.t('blocking:cannotRemoveSystemAdminsGroup') : "",
                isReadOnly: ko.observable(isSystemAdminsGroup)
            };
        };

        /** @type { (userIdsWithPermission: Array<string>, userGroupIdsWithPermission: Array<string>) => Array<IBlockingPermissionPresentationObject> } */
        const _idsToPresentationObjects = (userIdsWithPermission, userGroupIdsWithPermission) => {
            const userPermissionPresentationObjects = userIdsWithPermission.map((userId) => {
                const commonStateUser = _commonState.get(userId);

                return _commonStateUserToPresentationObject(commonStateUser);
            });

            const userGroupPermissionPresentationObjects = userGroupIdsWithPermission.map((userGroupId) => {
                const commonStateUserGroup = _commonState.get(userGroupId);

                return _commonStateUserGroupToPresentationObject(commonStateUserGroup);
            });

            return userPermissionPresentationObjects.concat(userGroupPermissionPresentationObjects);
        };
        //#endregion

        /**  @type BlockingFacade["allUsersAndUserGroups"] */
        self.allUsersAndUserGroups = ko.pureComputed(() => {
            const userPresentationObjects = _commonState.users()
                .filter(u => u.isActive())
                .map(_commonStateUserToPresentationObject);

            const userGroupPresentationObjects = _commonState.userGroups()
                .filter(g => g.isActive())
                .map(_commonStateUserGroupToPresentationObject);

            return [...userPresentationObjects, ...userGroupPresentationObjects];
        });

        /**  @type BlockingFacade["getBlockingPermissions"] */
        self.getBlockingPermissions = () => {
            return _promiseFactory.defer((deferredObject) => {
                _blockingRepository.getBlockingPermissions()
                    .fail(deferredObject.reject)
                    .done(((/** @type {IGetBlockingPermissionsResponse} */ response) => {
                        const initialPermissions = _idsToPresentationObjects(response.userIdsWithBlockingPermission, response.userGroupIdsWithBlockingPermission);

                        deferredObject.resolve(initialPermissions);
                    }));
            });
        };

        /**  @type BlockingFacade["updateBlockingPermissions"] */
        self.updateBlockingPermissions = (updatedBlockingPermissions) => {
            /** @type{Array<string>} */
            const newUserIdsWithBlockingPermission = [];
            /** @type{Array<string>} */
            const newUserGroupIdsWithBlockingPermission = [];

            updatedBlockingPermissions.forEach((permission) => {
                if (permission.userId) {
                    newUserIdsWithBlockingPermission.push(permission.userId);
                } else if (permission.userGroupId) {
                    const groupId = _commonState.resolveGroupIdToGuid(permission.userGroupId);
                    newUserGroupIdsWithBlockingPermission.push(groupId);
                }
            });

            const updateBlockingPermissionsRequest = {
                userIdsWithBlockingPermission: newUserIdsWithBlockingPermission,
                userGroupIdsWithBlockingPermission: newUserGroupIdsWithBlockingPermission
            };

            return _promiseFactory.defer((deferredObject) => {
                _blockingRepository.updateBlockingPermissions(updateBlockingPermissionsRequest)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };
    };
});
