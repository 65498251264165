define('presentation/inbox/viewModels/inboxCallRecordingsViewModel',['businessServices/contacts/contactsStateSingleton',
        'businessServices/events/eventManager',
        'common/collections/collectionFilter',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'common/time/datetimeFormatter',
        'constants/callRecordingMessageConstants',
        'constants/scrollEventSources',
        'presentation/common/callRecording/callRecordingAudioFactory',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel',
        'presentation/inbox/facades/inboxFacade'
    ],
    function (
        /** @type import('businessServices/contacts/contactsStateSingleton') */
        _contactsState
    ) {
        const DateTimeFormatterConstructor = require('common/time/datetimeFormatter');
        const _dateTimeFormatter = new DateTimeFormatterConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const DeleteCallMediaActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel');
        const _actionModal = new ActionModalViewModelConstructor();

        const callRecordingAudioFactory = require('presentation/common/callRecording/callRecordingAudioFactory');
        const FilterConstructor = require('common/collections/collectionFilter');

        const _callRecordingMessageConstants = require('constants/callRecordingMessageConstants');
        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        let _facade = null;
        let _callRecordingContentPaneScrollEventId = null;

        let isScreenAlreadyInitialized = false;
        let isListInitialized = false;
        let sidebar = null;
        let spinEndTime = null;

        const MINIMUM_ITEM_COUNT = 10;
        const DELETE_RECORDING_RESPONSE = "yes";
        const _state = {
            pageNumber: 0,
            numberOfCallRecordingsShown: 0,
            availableCallRecordings: [],
            isLastPage: false,
            PAGE_ITEM_COUNT: 150,
            ITEMS_PER_PAGE_DISPLAYED: 20
        };

        const parentViewModel = ko.observable();

        const scrollEventSource = ScrollEventSources.inboxRecordings;

        const debounceEvent = (callback, time) => {
            let interval;
            return (...args) => {
                clearTimeout(interval);
                interval = setTimeout(() => {
                    interval = null;
                    callback(...args);
                }, time);
            };
        };

        const formatPhoneNumber = (phoneNumber) => {
            return _phoneNumberFormatter.toInternationalWithParens(phoneNumber);
        };

        const isDataLoading = ko.observable(true);
        const showEmptyState = ko.computed(() => {
            if (isDataLoading() === false) {
                if (callRecordings().length === 0) {
                    return true;
                }
            }
            return false;
        });

        const anyCallRecordings = ko.observable(true);
        const emptyStateContentKey = ko.pureComputed(() => anyCallRecordings() ?
            "noResults" :
            "noRecordings");
        const callRecordings = ko.observableArray([]);
        const hostedNumbersSubscribers = [];

        const prepareCallRecordingForDisplay = (originalCallRecording, hostedNumbers) => {
            let callRecordingStatus = ko.observable(_callRecordingMessageConstants.status.new.name);
            switch (originalCallRecording.status) {
                case _callRecordingMessageConstants.status.new.id:
                    callRecordingStatus(_callRecordingMessageConstants.status.new.name);
                    break;
                case _callRecordingMessageConstants.status.reviewed.id:
                    callRecordingStatus(_callRecordingMessageConstants.status.reviewed.name);
                    break;
            }
            let isCallRecordingStatusNew = ko.pureComputed(() => {
                return (callRecordingStatus() === _callRecordingMessageConstants.status.new.name);
            });
            let i18nText = ko.pureComputed(() => {
                return {key: 'isNew', options: {context: callRecordingStatus().toLowerCase()}};
            });
            let hostedNumber = hostedNumbers.find((hostedNumber) => {
                return hostedNumber.id === originalCallRecording.hostedNumberId;
            });

            const isDefault = hostedNumber.hasDefaultName();

            let hostedPhoneNumberFormatted = null;
            let hostedPhoneNumberName = null;
            if (hostedNumber) {
                hostedPhoneNumberFormatted = formatPhoneNumber(hostedNumber.phoneNumber());
                hostedPhoneNumberName = hostedNumber.name;
            }

            let callerAvatar = null;
            let callerAni = null;
            let callerAniDisplay = null;
            let callerInitials = null;
            let callerType = 'external';

            let callRecordingBoxType = null;
            let callRecordingBoxName = null;
            let callRecordingBoxAvatar = null;
            let callRecordingBoxInitials = null;

            let fromLocation = null;
            let toLocation = null;

            if (originalCallRecording.isOutbound) {
                callRecordingBoxType = hostedNumber.type;
                const contact = _contactsState.getContactByPhoneNumber(originalCallRecording.destinationPhoneNumber);

                if (contact) {
                    callRecordingBoxAvatar = contact.avatarUrl;
                    callRecordingBoxName = contact.displayName;
                    callRecordingBoxInitials = contact.initials;

                    const matchingNumber = contact.phoneNumbers().find(x => _phoneNumberFormatter.toEOneSixFour(x.rawPhoneNumber()) === originalCallRecording.destinationPhoneNumber);
                    toLocation = matchingNumber ? matchingNumber.label : null;
                } else {
                    callRecordingBoxName = formatPhoneNumber(originalCallRecording.destinationPhoneNumber);
                    callerAniDisplay = formatPhoneNumber(originalCallRecording.callerAni);
                    toLocation = _phoneNumberFormatter.buildLocation(originalCallRecording.destinationPhoneNumber,
                        originalCallRecording.destinationLocationCity,
                        originalCallRecording.destinationLocationState);
                }

                let initiatedFromUser = _commonState.get(originalCallRecording.initiatedFromUser);

                if (initiatedFromUser) {
                    callerAvatar = initiatedFromUser.avatar;
                    callerAniDisplay = initiatedFromUser.name();
                    callerType = _commonState.types.user;
                }
            } else {
                const contact = _contactsState.getContactByPhoneNumber(originalCallRecording.callerAni);
                if (contact) {
                    callerAvatar = contact.avatarUrl;
                    callerAniDisplay = contact.displayName;
                    callerInitials = contact.initials;
                    callerType = _commonState.types.contact;

                    const matchingNumber = contact.phoneNumbers().find(x => _phoneNumberFormatter.toEOneSixFour(x.rawPhoneNumber()) === originalCallRecording.callerAni);
                    fromLocation = matchingNumber ? matchingNumber.label : null;
                } else {
                    callerAni = originalCallRecording.callerAni;
                    callerAniDisplay = formatPhoneNumber(originalCallRecording.callerAni);
                    fromLocation = _phoneNumberFormatter.isTollFree(originalCallRecording.callerAni) ? null : _phoneNumberFormatter.buildLocation(originalCallRecording.callerAni, originalCallRecording.callerLocationCity, originalCallRecording.callerLocationState);
                }

                callRecordingBoxType = hostedNumber.type;
                callRecordingBoxName = hostedNumber.name;
                toLocation = isDefault ? hostedNumber.location() : hostedPhoneNumberFormatted;
            }

            let durationInSeconds = Math.floor(originalCallRecording.durationInMilliseconds / 1000);

            let callRecordingAudio = ko.observable(callRecordingAudioFactory.getEmptyCallRecording());

            if (!hostedNumbersSubscribers[hostedNumber.id]) {
                hostedNumbersSubscribers[hostedNumber.id] = ko.observable(originalCallRecording.subscriberIds);
            }

            return {
                callRecordingId: originalCallRecording.callRecordingId,

                status: callRecordingStatus,
                isStatusNew: isCallRecordingStatusNew,

                callerAvatar: callerAvatar,
                callerAni: callerAni,
                callerAniDisplay: callerAniDisplay,
                callerInitials: callerInitials,
                callerType: callerType,
                callHistoryId: originalCallRecording.callHistoryId,
                fromLocation: fromLocation,

                callRecordingBoxType: callRecordingBoxType,
                callRecordingBoxName: callRecordingBoxName,
                callRecordingBoxAvatar: callRecordingBoxAvatar,
                callRecordingBoxInitials: callRecordingBoxInitials,

                hostedNumberId: originalCallRecording.hostedNumberId,
                hostedPhoneNumber: originalCallRecording.hostedPhoneNumber,
                hostedPhoneNumberFormatted: hostedPhoneNumberFormatted,
                hostedPhoneNumberName: hostedPhoneNumberName,
                toLocation: toLocation,

                isOutbound: originalCallRecording.isOutbound,

                recordingLeftDateTime: originalCallRecording.recordingLeftDateTime,
                date: _dateTimeFormatter.humanizedDay(originalCallRecording.recordingLeftDateTime),
                durationInSeconds: durationInSeconds,
                callRecordingAudio: callRecordingAudio,
                isCallRecordingAudioLoaded: false,

                isExpanded: ko.observable(false),
                isReady: ko.observable(false),
                isStatusChanging: ko.observable(true),
                textI18n: i18nText,

                callDetails: ko.observable(null)
            };
        };

        const removeCallRecordingFromUi = (callRecordingId) => {
            callRecordings.remove((item) => {
                return item.callRecordingId === callRecordingId;
            });
        };

        const setCallRecordingUiStatus = (callRecordingId, newStatus) => {
            let allCallRecordings = callRecordings();
            let callRecording = allCallRecordings.find((m) => {
                return m.callRecordingId === callRecordingId;
            });

            if (callRecording) {
                callRecording.status(newStatus);
                callRecording.isStatusChanging(false);
            }

        };

        const setCallRecordingStatus = (callRecording, newStatus) => {
            return _promiseFactory.defer((promise) => {
                if (callRecording.status() === newStatus) {
                    callRecording.isStatusChanging(false);
                    promise.resolve();
                    return;
                }
                callRecording.isStatusChanging(true);

                _facade.setCallRecordingStatus(callRecording.callRecordingId, newStatus)
                    .fail(promise.reject)
                    .done(() => {
                        setCallRecordingUiStatus(callRecording.callRecordingId, newStatus);
                        promise.resolve();
                    });
            });
        };

        const onCallRecordingStatusChanged = (hostedNumberId, callRecordingId, newStatus) => {
            let actualStatus = _callRecordingMessageConstants.status.new.name;
            switch (newStatus) {
                case _callRecordingMessageConstants.status.new.id:
                    actualStatus = _callRecordingMessageConstants.status.new.name;
                    break;
                case _callRecordingMessageConstants.status.reviewed.id:
                    actualStatus = _callRecordingMessageConstants.status.reviewed.name;
                    break;
            }

            setCallRecordingUiStatus(callRecordingId, actualStatus);
        };

        const onNewCallRecording = (callRecording) => {
            const isRecordingNotAlreadyPresent = callRecordings().every(recording => recording.callRecordingId !== callRecording.callRecordingId);
            if (isRecordingNotAlreadyPresent) {
                const searchOptions = sidebar.getSearchOptions();

                _facade.getFilteredCallRecording(callRecording.callRecordingId, searchOptions)
                    .done(({hasCallRecording, foundCallRecording}) => {
                        if (hasCallRecording) {
                            const preparedCallRecording = prepareCallRecordingForDisplay(foundCallRecording, _commonState.hostedNumbers());
                            const {searchText} = searchOptions;
                            const callRecordingExistsInFilter = searchFilterCallRecordings(searchText, [preparedCallRecording]).length > 0;
                            if (callRecordingExistsInFilter) {
                                callRecordings.unshift(prepareCallRecordingForDisplay(callRecording, _commonState.hostedNumbers()));
                            }
                        }
                    });
            }
        };

        const onSubscribersChanged = (hostedNumberId, subscriberIds) => {
            const hostedNumberSubscribers = hostedNumbersSubscribers[hostedNumberId];
            if (hostedNumberSubscribers) {
                hostedNumberSubscribers(subscriberIds);
            }
        };

        const onSubscriberSettingsChanged = (hostedNumberId, isActiveSubscriber, isShowInSidebarEnabled) => {
            const isCallRecordingSubscriptionPresent = callRecordings().some(recording => recording.hostedNumberId === hostedNumberId);
            if (isActiveSubscriber === true)
            {
                if (isShowInSidebarEnabled === true) {
                    if (isCallRecordingSubscriptionPresent === false) {
                        refreshCallRecordingListing();
                    }
                }
                else
                {
                    if (isCallRecordingSubscriptionPresent === true) {
                        refreshCallRecordingListing();
                    }
                }
            }
            else
            {
                if (isCallRecordingSubscriptionPresent === true) {
                    refreshCallRecordingListing();
                }
            }
        };

        const onCallRecordingDeleted = (callRecordingId) => {
            removeCallRecordingFromUi(callRecordingId);
        };


        const onSearchTextChanged = () => {
            refreshCallRecordingListing();
        };

        const onHostedNumberStatusChanged = (hostedNumberId) => {
            const areCallRecordingsPresent = callRecordings().some(recording => recording.hostedNumberId === hostedNumberId);
            if (areCallRecordingsPresent) {
                refreshCallRecordingListing();
            }
        };

        const refreshCallRecordingListing = () => {
            return _promiseFactory.defer((promise) => {
                spinEndTime = 1500 + new Date().getTime();
                isDataLoading(true);

                _state.pageNumber = 0;

                if (sidebar === null) {
                    promise.resolve();
                    return;
                }

                const searchOptions = sidebar.getSearchOptions();
                const {startDate, endDate, searchText, searchStatus, selectedHostedNumberIds} = searchOptions;
                const pageNumber = _state.pageNumber;
                const itemCount = _state.PAGE_ITEM_COUNT;

                _facade.getCallRecordings({startDate, endDate, searchText, searchStatus, selectedHostedNumberIds, pageNumber, itemCount})
                    .fail(promise.reject)
                    .done((foundRecordings) => {

                        const hostedNumbers = _commonState.hostedNumbers();

                        _state.isLastPage = foundRecordings.length === 0;

                        if (_state.isLastPage) {
                            _state.availableCallRecordings = [];
                            setCallRecordings();

                            _facade.checkAnyCallRecordings()
                                .fail(promise.reject)
                                .done(anyRecordings => {
                                    anyCallRecordings(anyRecordings);
                                    promise.resolve();
                                });
                        }
                        else {
                            const displayRecordings = foundRecordings.map((recording) => {
                                return prepareCallRecordingForDisplay(recording, hostedNumbers);
                            });
                            _state.availableCallRecordings = searchFilterCallRecordings(searchText, displayRecordings);

                            if (_state.availableCallRecordings.length < MINIMUM_ITEM_COUNT) {
                                getNextCallRecordingsFromDatabase()
                                    .fail(promise.reject)
                                    .done(() => {
                                        setCallRecordings();
                                        promise.resolve();
                                    });
                            } else {
                                setCallRecordings();
                                promise.resolve();
                            }
                        }
                    });
            });
        };

        const setCallRecordings = () => {
            setTimeout(() => {
                _state.numberOfCallRecordingsShown = _state.ITEMS_PER_PAGE_DISPLAYED;
                callRecordings(_state.availableCallRecordings.slice(0, _state.numberOfCallRecordingsShown));
                isDataLoading(false);
                if(_callRecordingContentPaneScrollEventId === null){
                    _callRecordingContentPaneScrollEventId = _eventManager.subscribeBottomScrollBufferReached(getNextDataGridEvent);
                }
            }, spinEndTime - new Date().getTime());
        };

        const getNextCallRecordings = (eventSource) => {
            if (eventSource !== scrollEventSource) {
                return;
            }

            return _promiseFactory.defer((promise) => {
                if (sidebar && _state.isLastPage === false) {
                    const additionalCallRecordingsShown = _state.numberOfCallRecordingsShown + _state.ITEMS_PER_PAGE_DISPLAYED;
                    const nextAvailableCallRecordings = _state.availableCallRecordings.slice(_state.numberOfCallRecordingsShown, additionalCallRecordingsShown);
                    callRecordings.push.apply(callRecordings, nextAvailableCallRecordings);
                    _state.numberOfCallRecordingsShown = additionalCallRecordingsShown;

                    if (_state.numberOfCallRecordingsShown >= _state.availableCallRecordings.length) {
                        getNextCallRecordingsFromDatabase()
                            .fail(promise.reject)
                            .done(promise.resolve);
                    } else {
                        promise.resolve();
                    }
                } else {
                    promise.resolve();
                }
            });
        };

        const getNextCallRecordingsFromDatabase = () => {
            return _promiseFactory.defer((promise) => {
                _state.pageNumber++;
                _state.numberOfCallRecordingsShown = _state.availableCallRecordings.length;

                const searchOptions = sidebar.getSearchOptions();
                const {startDate, endDate, searchText, searchStatus, selectedHostedNumberIds} = searchOptions;
                const pageNumber = _state.pageNumber;
                const itemCount = _state.PAGE_ITEM_COUNT;

                _facade.getCallRecordings({startDate, endDate, searchText, searchStatus, selectedHostedNumberIds, pageNumber, itemCount})
                    .fail(promise.reject)
                    .done((foundRecordings) => {
                        _state.isLastPage = foundRecordings.length === 0;

                        const hostedNumbers = _commonState.hostedNumbers();

                        const displayRecordings = foundRecordings.map((recording) => {
                            return prepareCallRecordingForDisplay(recording, hostedNumbers);
                        });
                        const searchFilterRecordings = searchFilterCallRecordings(searchText, displayRecordings);
                        _state.availableCallRecordings.push.apply(_state.availableCallRecordings, searchFilterRecordings);

                        if (_state.availableCallRecordings.length < MINIMUM_ITEM_COUNT && _state.isLastPage === false) {
                            getNextCallRecordingsFromDatabase()
                                .fail(promise.reject)
                                .done(promise.resolve);
                        } else {
                            promise.resolve();
                        }
                    });
            });
        };

        const getNextDataGridEvent = debounceEvent(getNextCallRecordings, 100);
        const searchTextChanged = debounceEvent(onSearchTextChanged, 750);

        const searchFilterCallRecordings = (searchText, availableCallRecordings) => {
            const callRecordingSearchTextFilter = new FilterConstructor();
            callRecordingSearchTextFilter
                .addProperty("callerAni")
                .addProperty("callerAniDisplay")
                .addProperty("fromLocation")
                .addProperty("recordingLeftDateTimeDisplay")
                .addProperty("status")
                .addProperty("toLocation")
                .addProperty("callRecordingBoxName")
                .addProperty("hostedPhoneNumber")
                .addProperty("hostedPhoneNumberFormatted")
                .addProperty("hostedPhoneNumberName")
                .addValue(searchText);
            return callRecordingSearchTextFilter.filter(availableCallRecordings);
        };

        const toggleCallRecordingStatus = (callRecording) => {
            return _promiseFactory.defer((promise) => {
                const currentStatus = callRecording.status();

                let newStatus;
                if (currentStatus ===  _callRecordingMessageConstants.status.new.name) {
                    newStatus = _callRecordingMessageConstants.status.reviewed.name;
                } else {
                    newStatus = _callRecordingMessageConstants.status.new.name;
                }

                setCallRecordingStatus(callRecording, newStatus)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const playCallRecording = (callRecording) => {
            return _promiseFactory.defer((promise) => {
                if (callRecording.isCallRecordingAudioLoaded === false) {
                    _facade.getCallRecordingDownloadUrl(callRecording.callRecordingId)
                        .fail(promise.reject)
                        .done((url) => {
                            let newAudio = callRecordingAudioFactory.playCallRecording(url);
                            callRecording.callRecordingAudio(newAudio);
                            callRecording.isCallRecordingAudioLoaded = true;

                            setCallRecordingStatus(callRecording, _callRecordingMessageConstants.status.reviewed.name)
                                .fail(promise.reject)
                                .done(promise.resolve);

                        });
                } else {
                    // Force the audio to play again.
                    callRecording.callRecordingAudio.valueHasMutated();

                    setCallRecordingStatus(callRecording, _callRecordingMessageConstants.status.reviewed.name)
                        .fail(promise.reject)
                        .done(promise.resolve);
                }

            });
        };

        const getCallRecordingUrl = (callRecording) => {
            return _promiseFactory.defer((promise) => {
                _facade.getCallRecordingDownloadUrl(callRecording.callRecordingId)
                    .fail(promise.reject)
                    .done((url) => {
                        promise.resolve(url);
                    });
            });
        };

        const deleteCallRecording = (callRecording) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let callRecordingId = callRecording.callRecordingId;
                let modalConstructorParams = [callRecording, _facade.getCallRecordingDownloadUrl, setCallRecordingStatus];

                _actionModal
                    .clearModal()
                    .setHeaderText({i18n: 'deleteConfirmation'})
                    .setSubmitButtonText({i18n: 'delete'})
                    .setContentViewModel(DeleteCallMediaActionViewModelConstructor, modalConstructorParams)
                    .showModal()
                    .then((result => {
                        if (result === DELETE_RECORDING_RESPONSE) {
                            _facade.deleteCallRecording(callRecordingId)
                                .fail(promise.reject)
                                .done(() => {
                                    removeCallRecordingFromUi(callRecordingId);
                                    promise.resolve();
                                });
                        }
                    }));
            });
        };

        const buildExpandedCallDetailItem = (callDetailItem) => {
            _facade.getCallDetails(callDetailItem.callHistoryId)
                .done(callDetails => {
                    callDetails.callId = callDetailItem.callHistoryId;

                    const callerLocation = callDetails.callerLocation;
                    const calleeLocation = callDetails.calleeLocation;
                    callDetails.shouldDisplayMap = callDetails.mapUrl !== null;
                    callDetails.recording.subscriberIds = ko.observable(callDetails.recording.subscriberIds);
                    callDetails.recording.isDeleted = ko.observable(callDetails.recording.voicemailIsDeleted);

                    callerLocation.cityState = _phoneNumberFormatter.cleanupLocation(callerLocation.cityState);
                    calleeLocation.cityState = _phoneNumberFormatter.cleanupLocation(calleeLocation.cityState);

                    callDetailItem.callDetails(callDetails);
                    
                    callDetailItem.isReady(true);
                    callDetailItem.isStatusChanging(false);
                    return callDetailItem;
                });
        };

        const expandCallDetails = (callDetailItem) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                callDetailItem.isExpanded(!callDetailItem.isExpanded());
                callDetailItem.isStatusChanging(true);

                if (callDetailItem.callDetails() === null) {
                    buildExpandedCallDetailItem(callDetailItem);
                }

                if (callDetailItem.isExpanded() === true &&
                    callDetailItem.callHistoryId !== null) {
                    setCallRecordingStatus(callDetailItem, _callRecordingMessageConstants.status.reviewed.name)
                        .fail(promise.reject)
                        .done(promise.resolve);
                }
            });
        };

        const showRecording = (elem) => {
            if (!isListInitialized) {
                return;
            }

            if (elem.nodeType === 1) {
                $(elem).hide().slideDown().fadeIn("normal");
            }
        };

        const renderComplete = () => {
            isListInitialized = true;
        };


        const _initializeScreen = () => {
            if (isScreenAlreadyInitialized) {
                return;
            }
            isScreenAlreadyInitialized = true;

            const FacadeConstructor = require('presentation/inbox/facades/inboxFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            _facade.onCallRecordingStatusChanged(onCallRecordingStatusChanged);
            _facade.onNewCallRecording(onNewCallRecording);
            _facade.onCallRecordingSubscribersChanged(onSubscribersChanged);
            _facade.onCallRecordingSubscriberSettingsChanged(onSubscriberSettingsChanged);
            _facade.onCallRecordingDeleted(onCallRecordingDeleted);
            _facade.onHostedNumberStatusChanged(onHostedNumberStatusChanged);

            refreshCallRecordingListing();
            return _promiseFactory.wait();
        };

        const _detachScreen = () => {
            isScreenAlreadyInitialized = false;
            _eventManager.unsubscribe(_callRecordingContentPaneScrollEventId);
            _callRecordingContentPaneScrollEventId = null;
            _facade.disposeCallRecordings();
        };

        const setSidebar = (mySidebar) => {
            sidebar = mySidebar;
        };


        return {
            refreshCallRecordingListing,
            searchTextChanged,
            getCallRecordingUrl,
            playCallRecording,
            deleteCallRecording,
            expandCallDetails,
            toggleCallRecordingStatus,
            showRecording,
            renderComplete,

            isDataLoading,
            showEmptyState,

            emptyStateContentKey,
            callRecordings,

            setSidebar,

            parentViewModel,
            scrollEventSource,

            // Durandal events:
            activate: _initializeScreen,
            detached: _detachScreen,
        };
    });

