define('businessServices/authentication/sessionAccountInfo',[], function() {
    var data = {
        accountId : null,
        apiToken : null,
        isLoggedIn : false,
        userId : null,
        userStatus : null,
        isImpersonating : false,
        accountPermissions : null,
        userDeviceIsMobilePhone : null,
        isInFreeTrial : ko.observable(false),
        trialPeriodStart : null,
        trialPeriodEnd : null,
        isInviteInfoCapture: false
    };

    function receiveAlertLoginSuccessful(loginInfo) {
        if (loginInfo.loginType === "impersonation") {
            data.isImpersonating = true;
        } else {
            data.isImpersonating = false;
        }
        data.accountId = loginInfo.accountId;
        data.apiToken = loginInfo.authenticationToken;
        data.isLoggedIn = true;
        data.userId = loginInfo.userId;
        data.userStatus = loginInfo.userStatus;
        data.accountPermissions = loginInfo.accountPermissions;
        data.userDeviceIsMobilePhone = loginInfo.userDeviceIsMobilePhone;
        data.isInFreeTrial(loginInfo.isInFreeTrial);
        data.trialPeriodStart = loginInfo.trialPeriodStart;
        data.trialPeriodEnd = loginInfo.trialPeriodEnd;
        data.isInviteInfoCapture = loginInfo.isInviteInfoCapture;
    }

    function receiveAlertLogout() {
        data.accountId = null;
        data.apiToken = null;
        data.isLoggedIn = false;
        data.userId = null;
        data.accountPermissions = null;
        data.userDeviceIsMobilePhone = null;
        data.isInFreeTrial(false);
        data.trialPeriodStart = null;
        data.trialPeriodEnd = null;
        data.isInviteInfoCapture = false;
    }

    function setFreeTrialComplete() {
        data.isInFreeTrial(false);
    }

    function isLoggedIn() {
        return data.isLoggedIn;
    }

    function userId() {
        return data.userId;
    }

    function accountId() {
        return data.accountId;
    }

    function apiToken() {
        return data.apiToken;
    }

    function isImpersonating() {
        return data.isImpersonating;
    }

    function accountPermissions() {
        return data.accountPermissions;
    }

    function userStatus() {
        return data.userStatus;
    }

    function userDeviceIsMobilePhone() {
        return data.userDeviceIsMobilePhone;
    }

    function isInFreeTrial() {
        return data.isInFreeTrial;
    }

    function trialPeriodStart() {
        return data.trialPeriodStart;
    }

    function trialPeriodEnd() {
        return data.trialPeriodEnd;
    }

    function isInviteInfoCapture() {
        return data.isInviteInfoCapture;
    }

    function isBillingAdmin() {
        if (data.accountPermissions) {
            return data.accountPermissions.Billing === true;
        }
        return false;

    }

    function isSystemAdmin() {
        if (data.accountPermissions) {
            return data.accountPermissions.SystemSettings === true;
        }
        return false;
    }

    return {
        isLoggedIn : isLoggedIn,
        userId : userId,
        accountId : accountId,
        apiToken : apiToken,
        isImpersonating : isImpersonating,
        accountPermissions : accountPermissions,
        userStatus : userStatus,
        userDeviceIsMobilePhone : userDeviceIsMobilePhone,
        isInFreeTrial: isInFreeTrial,
        trialPeriodStart: trialPeriodStart,
        trialPeriodEnd: trialPeriodEnd,
        isInviteInfoCapture : isInviteInfoCapture,
        isBillingAdmin: isBillingAdmin,
        isSystemAdmin: isSystemAdmin,
        setFreeTrialComplete: setFreeTrialComplete,

        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});

