define('presentation/common/validation/validPhoneNumberSpecification',[
    'businessServices/country/countryCode',
    'common/converters/phoneNumberFormatConverter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp',
],
function() {
    return function() {
        const self = this;

        const CountryCodeConstructor = require('businessServices/country/countryCode');
        const _countryCodeList = new CountryCodeConstructor();
        const _countries = _countryCodeList.countryCodes;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        const _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("validation");

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatConverter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        self.isSatisfiedBy = (phoneNumber, countryAbbreviation) => {
            let digits = null;
			if (countryAbbreviation) {
				digits = _getMinMaxDigits(countryAbbreviation);
            }
			if (phoneNumber.indexOf(digits.countryCode) === 0) {
				phoneNumber = phoneNumber.slice(digits.countryCode.length);
			}
			if (phoneNumber && digits) {
                if (phoneNumber.length >= digits.minDigits && phoneNumber.length <= digits.maxDigits) {
                    return true;
				} 
			}
			return false;
        };

		const _getMinMaxDigits = (countryAbbreviation) => {
			if (countryAbbreviation) {
				for (let i = 0; i < _countries.length; i++) {
					if (_countries[i].countryAbbreviation === countryAbbreviation) {
						const maxDigits = _countries[i].maxDigits;
                        const minDigits = _countries[i].minDigits;
                        const countryCode = _countries[i].countryCode;
						return {"maxDigits" : maxDigits, "minDigits" : minDigits, "countryCode" : countryCode};
					}
				}
				return null;
			}
		};

        self.isAllowedPhoneNumber = (phoneNumber) => {
            return _promiseFactory.defer((promise) => {
                if (phoneNumber === "") {
                    promise.resolve(true);
                } else {
                    const parameters = {
                        phoneNumber: phoneNumber
                    };
                    _webSocketApp.send("is_restricted_phone_number", parameters, (result) => {
                        const isAllowedPhoneNumber = !result.status;
                        promise.resolve(isAllowedPhoneNumber);
                    });
                }
            });
        };

        self.isPhoneNumberOnAnotherAccount = (phoneNumber, accountId) => {
            return _promiseFactory.defer((promise) => {
                if (phoneNumber === "") {
                    promise.resolve(false);
                } else {
                    const parameters = {
                        account_id: accountId,
                        phone_number: _phoneNumberFormatter.clean(phoneNumber)
                    };
                    _webSocketApp.send("is_phone_number_on_another_account", parameters, (result) => {
                        const isPhoneNumberOnAnotherAccount = result.status;
                        promise.resolve(!isPhoneNumberOnAnotherAccount);
                    });
                }
            });
        };

        self.isPhoneNumberOnThisAccount = (accountId, phoneNumber) => {
            return _promiseFactory.defer((promise) => {
                if (phoneNumber === "") {
                    promise.resolve(false);
                } else {
                    const parameters = {
                        account_id: accountId,
                        phone_number: _phoneNumberFormatter.clean(phoneNumber)
                    };
                    _webSocketApp.send("is_phone_number_on_account", parameters, (result) => {
                        const isPhoneNumberOnThisAccount = result.status;
                        promise.resolve(isPhoneNumberOnThisAccount);
                    });
                }
            });
        };
    };
});
