/* jshint ignore:start */
define('presentation/common/scrollAtMaxHeightWidthBinding',[],
function() {
    return {
        /** @type {KnockoutBindingHandler<HTMLElement, IScrollAtMaxHeightWidthBindingOptions>["init"]} */
        update: function(element, valueAccessor) {
            const settings = valueAccessor();
            const scrollIntoView = settings.scrollIntoView;
            const scrollObservable = settings.scrollObservable;
            const $containerSelector = $(settings.containerSelector);
            const $contentSelector = $(settings.contentSelector);
            const _scrollXBuffer = 60;
            const _scrollYBuffer = 160;

            /** @type {OverlayScrollbars}*/
            let scrollbarInstance = null;
            let isScrollbarInitialized = false;

            const _bindOverlayScrollbar = () => {
                scrollbarInstance = $(element).overlayScrollbars({
                    className: "os-theme-tresta",
                    overflowBehavior : {
                        x : "scroll",
                        y : "scroll"
                    },
                    scrollbars: {
                        autoHide: "leave",
                        autoHideDelay: 100
                    },
                    sizeAutoCapable: false,
                    callbacks : {
                        onInitialized: () => {
                            isScrollbarInitialized = true;
                        },
                        onContentSizeChanged: () => {
                            if (ko.unwrap(scrollIntoView)) {
                                _scrollIntoView();
                            }
                        },
                    }
                }).overlayScrollbars();
            };

            const _scrollIntoView = () => {
                if (scrollbarInstance) {
                    scrollbarInstance.scroll({ el : $contentSelector, block : "end" , margin : 10 }, 200);
                }
            };

            const checkOverflow = () => {

                const contentWidth = $contentSelector.find('.common-list__item').width();
                const shouldXContentScroll = contentWidth ? $containerSelector.width() < (contentWidth + _scrollXBuffer) : false;
                const shouldYContentScroll = $containerSelector.height() < ($contentSelector.height() + _scrollYBuffer);

                if (shouldYContentScroll) {
                    scrollObservable(true);
                    if (!isScrollbarInitialized) {
                        $containerSelector.height("100%");
                        _bindOverlayScrollbar();
                    }
                }
                else if (shouldXContentScroll) {
                    scrollObservable(true);
                    if (!isScrollbarInitialized) {
                        _bindOverlayScrollbar();
                    }
                }
                else {
                    scrollObservable(false);
                    $containerSelector.height("");
                    if (isScrollbarInitialized) {
                        scrollbarInstance.destroy();
                        isScrollbarInitialized = false;
                        $containerSelector.height("100%");
                    }
                }
            };

            const _mutationObserver = new MutationObserver(checkOverflow);
            const _resizeObserver = new ResizeObserver(checkOverflow);

            _resizeObserver.observe($contentSelector[0]);
            _resizeObserver.observe($containerSelector[0]);
            _mutationObserver.observe(element, {
                attributes: true,
                attributeFilter: ['style'],
                childList: true,
                subtree: true
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (scrollbarInstance) {
                    scrollbarInstance.destroy();
                }

                _mutationObserver.disconnect();
                _resizeObserver.disconnect();
            });
        }
    };
});
/* jshint ignore:end */
;
