define('businessServices/router/plugins/inviteInfoCaptureRouterPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/sessionAccountInfo'
], function(navigationConfiguration, sessionAccountInfo) {
    const _navigationConfiguration = navigationConfiguration;
    const _sessionAccountInfo = sessionAccountInfo;

    function routeToInviteInfoCapture(routeId) {
        if (_navigationConfiguration.routesById === undefined ||
            _navigationConfiguration.routesById[routeId] === undefined) {
            return true;
        }

        if ((
                _navigationConfiguration.routesById[routeId].allowInviteInfoCapture === undefined ||
                _navigationConfiguration.routesById[routeId].allowInviteInfoCapture === false
            ) && _sessionAccountInfo.isInviteInfoCapture()
        ) {
            return {routeUrl: navigationConfiguration.routesById.acceptUserInvite.url};
        }

        return true;
    }

    return {
        guardRoute: routeToInviteInfoCapture
    };
});

