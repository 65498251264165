define('presentation/common/croppableImage/presentationObjects/avatar',[],function() {
    return function(){
        const self = this;

        self.avatarUrl = ko.observable("");
        self.status = ko.observable("");
        self.avatarFileHash = ko.observable("");

        self.showDefaultImage = ko.pureComputed(function() {return self.avatarUrl() === "";});
        self.showImage = ko.pureComputed(function() {return !self.showDefaultImage();});
        self.encodingType = '';
        self.userId = null;
        self.isSaved = true;
        self.isGrayedOut = ko.pureComputed(function() {return ["deactivated", "expired"].includes(self.status());});
    };
});

