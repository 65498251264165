define('persistence/repositories/deviceRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(deviceEntity) {
            if (deviceEntity.deviceId !== null) {
                var err = new Error("Device Id should be null");
                err.lobbyDevice = deviceEntity;
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var deviceDocument = {
                        data : deviceEntity
                    };

                    _kazooApi.callAccount('/lobby_devices', 'PUT', deviceDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.deviceEntity = deviceEntity;
                            deferredObject.reject(error);
                        });
                });
            }

        };

        self.update = function(deviceEntity) {
            if (deviceEntity.deviceId === null) {
                var err = new Error("Missing Device Id");
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var deviceDocument = {
                        data : deviceEntity
                    };
                    _kazooApi.callAccount('/lobby_devices/'+deviceEntity.deviceId, 'POST', deviceDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.deviceEntity = deviceEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.remove = function(deviceId) {
            if (deviceId === null) {
                var err = new Error("Missing Device Id");
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_devices/'+deviceId,'DELETE')
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.deviceId = deviceId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

