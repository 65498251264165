define('persistence/repositories/resetPasswordRepository',[
    'persistence/webSocket/webSocketApp',
    'common/promises/promiseFactory'
], function() {
    return function() {
        var self = this;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        var _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_auth");

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.resetPassword = function(emailAddress, password, url, signature) {
            if (emailAddress === null) {
                throw new Error("Missing emailAddress");
            }
            if (password === null) {
                throw new Error("Missing password");
            }

            var socketData = {
                emailAddress: emailAddress,
                password: password,
                url: url,
                signature: signature
            };

            return _promiseFactory.defer(function(promise) {
                _webSocketApp.send("reset_password", socketData, function (result) {
                    promise.resolve(result);
                });
            });
        };
    };
});

