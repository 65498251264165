define('presentation/common/videoPlayer/videoPresentationObject',[], function() {
    return function() {
        const self = this;

        self.attachmentId = ko.observable(null);
        self.src = ko.observable(null);
        self.contentType = ko.observable();
        self.wasVideoStarted = ko.observable(false);
        self.videoCurrentTime = ko.observable();
        self.videoTotalTime = ko.observable();
        self.videoInitialLoadTime = ko.observable();
    };
});
