define('presentation/settings/messagingCompliance/viewModels/messagingComplianceTollFreeMessagingTabViewModel',[
        'businessServices/converters/textConverter',
        'businessServices/state/modelStateObserver',
        'common/collections/enumerationKeyValueConverter',
        'common/promises/promiseFactory',
        'constants/countryEnumerations',
        'constants/messagingTextsPerDayEnumerations',
        'constants/tollfreeUseCaseConstants',
        'i18next',
        'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceTollfreePresentationObject',
        'presentation/settings/messagingCompliance/validators/messagingComplianceTollFreeMessagingTabValidator'
    ],
    function (
        /** @type typeof import('businessServices/converters/textConverter') */
        TextConverter,
        /** @type typeof import('businessServices/state/modelStateObserver') */
        ModelStateObserver,
        /** @type import('common/collections/enumerationKeyValueConverter') */
        _keyValueConverter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('constants/countryEnumerations') */
        _countries,
        /** @type typeof import('constants/messagingTextsPerDayEnumerations') */
        _messagingTextsPerDay,
        /** @type typeof import('constants/tollfreeUseCaseConstants') */
        _tollfreeUseCases,
        /** @type import('i18next') */
        _i18n,
        /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceTollfreePresentationObject') */
        MessagingComplianceTollfreePresentationObject
    ) {
        return function (parent) {
            /** @typedef { import('presentation/settings/messagingCompliance/validators/messagingComplianceTollFreeMessagingTabValidator') } MessagingComplianceTollFreeMessagingTabValidator } */

            const self = this;
            const _parent = parent;

            const _promiseFactory = new PromiseFactory();
            const _textConverter = new TextConverter();

            /** @type { MessagingComplianceTollFreeMessagingTabValidator | null } */
            let _validator = null;

            /** @type { IDisposable[] } */
            let _disposables = [];

            const DROPDOWN_OPTION_YES = {
                id: "1",
                description: "Yes"
            };
            const DROPDOWN_OPTION_NO = {
                id: "0",
                description: "No"
            };
            const YES_NO_DROPDOWN_OPTIONS = [DROPDOWN_OPTION_NO, DROPDOWN_OPTION_YES];

            self.yesNoDropdownOptions = ko.observableArray(YES_NO_DROPDOWN_OPTIONS);

            self.messagingTextsPerDayList = _keyValueConverter.convertToKeyValues(_messagingTextsPerDay).map((option) => {
                const i18nKey = _textConverter.camelCase(option.key);
                return { key: option.value, title: _i18n.t(`messagingComplianceTollFreeMessagingTab:${i18nKey}`), value: option.value };
            });

            self.isCompositionComplete = ko.observable(false);
            self.isInitialRender = true;
            self.modelStateObserver = null;

            self.isTollfreeSmsFormEnabled = ko.observable(false).extend({observeState: true});
            self.showTollFreeNumberIsEnabledMessage = ko.pureComputed(() => {
                return self.isTollfreeSmsFormEnabled() && self.hasTollFreeNumberWithMessagingEnabled();
            });
            self.showInFreeTrialMessage = ko.pureComputed(() => _parent.isAccountInFreeTrial());
            self.isToggleDisabled = ko.pureComputed(() => self.showTollFreeNumberIsEnabledMessage() || self.showInFreeTrialMessage());

            self.hasTollFreeNumberWithMessagingEnabled = ko.observable(false);
            self.toggleMessageKey = ko.pureComputed(() =>  self.isTollfreeSmsFormEnabled() ? 'messagingComplianceTollFreeMessagingTab:enabled' : 'messagingComplianceTollFreeMessagingTab:disabled' );

            self.smsComplianceTollfreeId = null;
            self.useCases = ko.observableArray([]);
            self.selectedUseCases = ko.observableArray([]).extend({observeState: true});

            self.messageTextComplianceError = ko.observable("").extend({observeState: true});

            self.summaryOfTexting = ko.observable("").extend({observeState: true});
            self.sampleTextMessageOne = ko.observable("").extend({observeState: true});
            self.sampleTextMessageTwo = ko.observable("").extend({observeState: true});
            self.sampleTextMessageThree = ko.observable("").extend({observeState: true});
            self.sampleTextMessageFour = ko.observable("").extend({observeState: true});

            self.sampleTextMessageOneOutput = ko.pureComputed(() => {
                let input = self.sampleTextMessageOne().trim().length > 0 ?
                    self.sampleTextMessageOne().trim() :
                    `[${_i18n.t('messagingComplianceTollFreeMessagingTab:labelTextMessageSampleOne')}]`;

                return `${input} ${_i18n.t('messagingComplianceTollFreeMessagingTab:replyStop')}`;
            });

            self.messagesPerDay = ko.observable().extend({observeState: true});
            self.majorityOfMessagesSentToCanada = ko.observable("").extend({observeState: true});

            self.isCustomerConsentLeadCaptureForms = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentInitiateConversation = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentEmployeeRelationship = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentOnlineCheckout = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentPosTransaction = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentOther = ko.observable(false).extend({observeState: true});
            self.customerConsentOther = ko.observable("").extend({observeState: true});

            self.messageTextConsentError = ko.observable("").extend({observeState: true});

            self.isUseEligibilityAcknowledged = ko.observable(false).extend({observeState: true});
            self.messageUseEligibilityError = ko.observable("").extend({observeState: true});

            self.selectPlaceHolderMessage = ko.pureComputed(() => _i18n.t("messagingComplianceTollFreeMessagingTab:select"));

            self.validate = () => _validator.validate();

            self.resetValidation = () => {
                _validator.resetValidation();
            };

            self.cancelForm = () => {
                if (self.messageTextComplianceError.isDirty) {
                    self.messageTextComplianceError.forceUpdate(true);
                }
                if (self.messageTextConsentError.isDirty) {
                    self.messageTextConsentError.forceUpdate(true);
                }
            };

            self.getForm = () => {
                const messagingComplianceTollfree = new MessagingComplianceTollfreePresentationObject();
                messagingComplianceTollfree.smsComplianceTollfreeId = self.smsComplianceTollfreeId;
                messagingComplianceTollfree.majorityOfMessagesSentToCanada = self.majorityOfMessagesSentToCanada;
                messagingComplianceTollfree.messagesPerDay = self.messagesPerDay;
                messagingComplianceTollfree.summaryOfTexting = self.summaryOfTexting;
                messagingComplianceTollfree.sampleTextMessageOne = self.sampleTextMessageOne;
                messagingComplianceTollfree.sampleTextMessageTwo = self.sampleTextMessageTwo;
                messagingComplianceTollfree.sampleTextMessageThree = self.sampleTextMessageThree;
                messagingComplianceTollfree.sampleTextMessageFour = self.sampleTextMessageFour;
                messagingComplianceTollfree.isCustomerConsentLeadCaptureForms = self.isCustomerConsentLeadCaptureForms;
                messagingComplianceTollfree.isCustomerConsentInitiateConversation = self.isCustomerConsentInitiateConversation;
                messagingComplianceTollfree.isCustomerConsentEmployeeRelationship = self.isCustomerConsentEmployeeRelationship;
                messagingComplianceTollfree.isCustomerConsentOnlineCheckout = self.isCustomerConsentOnlineCheckout;
                messagingComplianceTollfree.isCustomerConsentPosTransaction = self.isCustomerConsentPosTransaction;
                messagingComplianceTollfree.isCustomerConsentOther = self.isCustomerConsentOther;
                messagingComplianceTollfree.customerConsentOther = self.customerConsentOther;
                messagingComplianceTollfree.isIneligibleUseCaseAcknowledged = self.isUseEligibilityAcknowledged;
                messagingComplianceTollfree.useCase(self.selectedUseCases().join());
                messagingComplianceTollfree.isTollfreeSmsFormEnabled = self.isTollfreeSmsFormEnabled;

                return messagingComplianceTollfree;
            };

            self.dispose = () => {
                self.isCompositionComplete(false);
                _disposables.forEach(d => d.dispose());
                _disposables = [];
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = (/** @type { MessagingComplianceTollfreePresentationObject } */ tollfreeData) => {
                let modelStateObserver = new ModelStateObserver(self);

                const ValidatorConstructor = require('presentation/settings/messagingCompliance/validators/messagingComplianceTollFreeMessagingTabValidator');
                _validator = new ValidatorConstructor();

                return _initialize(tollfreeData, modelStateObserver);
            };

            const _initialize = (tollfreeData, modelStateObserver) => {
                return _promiseFactory.defer((initPromise) => {

                    if (tollfreeData) {
                        self.smsComplianceTollfreeId = tollfreeData.smsComplianceTollfreeId;
                        _initializeObservables(tollfreeData);
                    }

                    self.modelStateObserver = modelStateObserver;
                    self.modelStateObserver.commitData();

                    _validator.registerViewModel(self);

                    initPromise.resolve();
                });
            };

            const _initializeObservables = (tollfreeData) => {
                self.messagesPerDay(tollfreeData.messagesPerDay());
                self.majorityOfMessagesSentToCanada(tollfreeData.majorityOfMessagesSentToCanada());
                self.summaryOfTexting(tollfreeData.summaryOfTexting());
                self.sampleTextMessageOne(tollfreeData.sampleTextMessageOne());
                self.sampleTextMessageTwo(tollfreeData.sampleTextMessageTwo());
                self.sampleTextMessageThree(tollfreeData.sampleTextMessageThree());
                self.sampleTextMessageFour(tollfreeData.sampleTextMessageFour());
                self.isCustomerConsentLeadCaptureForms(tollfreeData.isCustomerConsentLeadCaptureForms());
                self.isCustomerConsentInitiateConversation(tollfreeData.isCustomerConsentInitiateConversation());
                self.isCustomerConsentEmployeeRelationship(tollfreeData.isCustomerConsentEmployeeRelationship());
                self.isCustomerConsentOnlineCheckout(tollfreeData.isCustomerConsentOnlineCheckout());
                self.isCustomerConsentPosTransaction(tollfreeData.isCustomerConsentPosTransaction());
                self.isCustomerConsentOther(tollfreeData.isCustomerConsentOther());
                self.customerConsentOther(tollfreeData.customerConsentOther());
                self.isUseEligibilityAcknowledged(tollfreeData.isIneligibleUseCaseAcknowledged());
                self.isTollfreeSmsFormEnabled(tollfreeData.isTollfreeSmsFormEnabled());

                if (tollfreeData.useCase()) {
                    const useCases = tollfreeData.useCase().split(',');
                    self.selectedUseCases(useCases);
                }

                self.useCases(_formatUseCaseOptions());

                self.hasTollFreeNumberWithMessagingEnabled(tollfreeData.hasTollFreeNumberWithMessagingEnabled());
            };

            const _formatUseCaseOptions = () => {
                const allUseCases = _keyValueConverter.convertToKeyValues(_tollfreeUseCases)
                    .filter((useCase) => useCase.value !== _tollfreeUseCases.Mixed)
                    .map((option) => {
                        const i18nKey = _textConverter.camelCase(option.key);

                        /** @type { IUseCaseOption } */
                        const useCaseOption = Object.assign({}, option, {
                            labelI18nKey: `messagingComplianceTollFreeMessagingTab:useCase.${i18nKey}`,
                            isSelected: ko.observable(self.selectedUseCases().includes(option.value)),
                            isDisabled: ko.pureComputed(() => {
                                const isSelected = self.selectedUseCases().includes(option.value);
                                return !isSelected && self.selectedUseCases().length >= 5;
                            })
                        });

                        _disposables.push(
                            useCaseOption.isSelected.subscribe(() => {
                                const currentSelection = self.selectedUseCases();
                                let updatedSelection;

                                const alreadySelected = currentSelection.includes(option.value);

                                if (alreadySelected) {
                                    updatedSelection = currentSelection.filter((u) => u !== option.value);
                                } else {
                                    updatedSelection = currentSelection;
                                    updatedSelection.push(option.value);
                                }

                                self.selectedUseCases(updatedSelection);
                            })
                        );

                        return useCaseOption;
                    });

                /** @type { IUseCaseOption[] }*/
                return allUseCases;
            };
        };
    });
