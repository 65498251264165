define('common/specifications/validAvatarSpecification',[], function() {
    var AVATAR_MAX_LENGTH = 4194304;

    var _isAvatarTypeValid = function(avatar) {
        if (avatar) {
            switch (avatar.type) {
                case "image/gif":
                case "image/jpg":
                case "image/jpeg":
                case "image/png":
                    return true;
                default:
                    return false;
            }
        }
        return true;
    };

    var _isAvatarSizeValid = function(avatar) {
        if (avatar) {
            return avatar.size <= AVATAR_MAX_LENGTH;
        }
        return true;
    };

    return function() {
        var self = this;
        self.isAvatarTypeValid = _isAvatarTypeValid;
        self.isAvatarSizeValid = _isAvatarSizeValid;
    };
});

