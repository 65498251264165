/* globals intlTelInputUtils */
define('common/presentation/commonKnockoutConfiguration',[
        'common/uniqueId/uniqueIdGenerator',
        'common/logging/logger',
        'settings/uiAnimationsConfiguration',
        'common/promises/promiseFactory',
        'constants/countryEnumerations',
        'constants/colorConstants',
        'businessServices/validationMessages/validationMessage',
        'common/converters/phoneNumberFormatter',
        'common/presentation/i18nResources',
        'common/presentation/toolTipIcon',
        'common/time/datetimeFormatter',
        'common/url/urlFormatter',
        'presentation/common/dateTimeValue',
        'businessServices/browserSupport/browserType',
        'jquery',
        'tooltip',
        'spin',
        'spin-jquery',
        'jquery-resize',
        'jquery-ui',
        'jquery-mousewheel',
        'timepicker',
        'uniform',
        'headroom',
        'jquery-headroom',
        'jquery-payment',
        'i18next-locale',
        'i18next',
        'intlTelInput',
        'intlTelInputUtils'
    ], function() {
    var configure = function() {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const ToolTipIconConstructor = require('common/presentation/toolTipIcon');
        var _validationMessage = require('businessServices/validationMessages/validationMessage');
        _validationMessage.init();
        var _urlFormatter = require('common/url/urlFormatter');

        const _browserType = require('businessServices/browserSupport/browserType');
        const _colorConstants = require('constants/colorConstants');
        const _i18n = require('i18next');
        const _i18nLocale = require('i18next-locale');
        _i18nLocale.subscribe(function (value) {
            _i18n.changeLanguage(value);
        });

        const _validationProcessValidationRules = function(validationArguments, validationRules, shouldKeyUpValidate, validationSequence) {
            var promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.defer(function(deferredObject) {
                _validationProcessValidationRule(validationArguments, validationRules, shouldKeyUpValidate, validationSequence, deferredObject);
            });
        };

        const _validationProcessValidationRule = function(validationArguments, validationRules, shouldKeyUpValidate, validationSequence, deferredObject) {
            if (validationSequence.observable() !== validationSequence.index) {
                deferredObject.resolve({validationIndex: validationSequence.index, isValid: null});
                return;
            }
            if (validationRules.length > 0) {
                const validationRule = validationRules.shift();
                if (validationRule.isKeyUp) {
                    if (!shouldKeyUpValidate) {
                        _validationProcessValidationRule(validationArguments, validationRules, false, validationSequence, deferredObject);
                    }
                    else {
                        $.when(validationRule.rule.apply(null, validationArguments))
                            .fail(deferredObject.reject)
                            .done(isValidResult => {
                                if (isValidResult === true) {
                                    _validationProcessValidationRule(validationArguments, validationRules, false, validationSequence, deferredObject);
                                } else {
                                    deferredObject.resolve({isValid: null});
                                }
                            });
                    }
                } else {
                    $.when(validationRule.rule.apply(null, validationArguments))
                        .fail(deferredObject.reject)
                        .done(function(isValidResult) {
                            if (isValidResult === false) {
                                if (validationRule.message === undefined) {
                                    let validationMessage;
                                    if (typeof validationRule.i18nKey === 'string') {
                                        validationMessage = _i18n.t(ko.unwrap(validationRule.i18nKey));
                                    } else if (validationRule.i18nKey.key && validationRule.i18nKey.options) {
                                        validationMessage = _i18n.t(ko.unwrap(validationRule.i18nKey.key), ko.unwrap(validationRule.i18nKey.options));
                                    } else if (ko.isObservable(validationRule.i18nKey)) {
                                        validationMessage = _i18n.t(ko.unwrap(validationRule.i18nKey));
                                    }
                                    deferredObject.resolve({isValid: false, validationMessage: validationMessage});
                                } else {
                                    _validationMessage.get(validationRule.message)
                                        .fail(deferredObject.reject)
                                        .done(function(validationMessage) {
                                            deferredObject.resolve({isValid: false, validationMessage: validationMessage});
                                        });
                                }
                            } else {
                                _validationProcessValidationRule(validationArguments, validationRules, shouldKeyUpValidate, validationSequence, deferredObject);
                            }
                        });
                }
            } else {
                deferredObject.resolve({isValid: true, validationMessage: ""});
            }
        };

        const _addValidationExtensions = function(validationSettings, object, objectIndex) {
            if (validationSettings.fields && validationSettings.fields.length > 0) {
                validationSettings.fields.forEach(function(fieldValidationRule) {
                    if (object[fieldValidationRule.field]) {
                        let validationSettings = {};
                        validationSettings.validationRules = fieldValidationRule.validators;
                        validationSettings.isArray = true;
                        validationSettings.parent = object;
                        validationSettings.itemIndex = objectIndex;
                        object[fieldValidationRule.field].extend({lobbyValidation: validationSettings});
                    }
                });
            }

            if (validationSettings.validators && validationSettings.triggerFields) {
                _addValidationExtensionsToObservable(object);
                object.validationMessage = ko.observable();
                object.validationIndex = ko.observable(0);
                let validationRules = validationSettings.validators;
                object.validate = (forceValidation) => {
                    const promiseFactory = new PromiseFactoryConstructor();
                    return promiseFactory.defer(function(deferredObject) {
                        let validationRulesCopy = validationRules.slice(0);
                        let validationArguments = [object];

                        const shouldKeyUpValidate = forceValidation !== true &&
                            ko.unwrap(object.didKeyUpPass) !== true &&
                            ko.unwrap(object.isKeyUp) === true;

                        object.validationIndex(object.validationIndex() +1);
                        const validationSequence = {
                            observable: object.validationIndex,
                            index: object.validationIndex()
                        };

                        _validationProcessValidationRules(validationArguments, validationRulesCopy, shouldKeyUpValidate, validationSequence)
                            .done(result => {
                                if (result.validationIndex && result.validationIndex !== object.validationIndex()) {
                                    deferredObject.resolve(false);
                                    return;
                                }
                                if (result.isValid !== null) {
                                    object.isValid(result.isValid);
                                    object.didKeyUpPass = true;
                                    object.validationMessage(result.validationMessage);
                                }
                                deferredObject.resolve(result.isValid);
                            })
                            .fail(function(error) {
                                object.isValid(false);
                                deferredObject.reject(error);
                            });
                    });
                };

                object.resetValidation = function() {
                    object.isValid(null);
                    object.validationMessage("");
                };

                validationSettings.triggerFields.forEach(function(triggerField) {
                    if (object[triggerField]) {
                        object[triggerField].subscribe(function() {
                            object.validate();
                        });
                    }
                });
            } else if (validationSettings.fields) {
                _addValidationExtensionsToObservable(object);
                object.validationMessages = ko.observableArray([]);
                object.validate = (forceValidation) => {
                    const promiseFactory = new PromiseFactoryConstructor();
                    return promiseFactory.defer(function(deferredObject) {
                        var objectIsValid = true;
                        var objectValidationMessages = [];

                        var itemsWaitingValidation = validationSettings.fields.map(function(field) {
                            const promise = object[field.field].validate(forceValidation);
                            promise.done(result => {
                                if (result !== null) {
                                    object.isValid(result);
                                    if (result === false) {
                                        objectIsValid = false;
                                        objectValidationMessages.push(object[field.field].validationMessage());
                                    }
                                }
                            });
                            return promise;
                        });

                        $.when.apply(null, itemsWaitingValidation)
                            .done(function() {
                                object.isValid(objectIsValid);
                                object.validationMessages(objectValidationMessages);
                                deferredObject.resolve(objectIsValid);
                            })
                            .fail(function(error) {
                                deferredObject.reject(error);
                            });
                        });
                };
            }
        };

        const _addValidationExtensionsToObservable = (observable) => {
            observable.isValid = ko.observable(null);
            observable.isValid.extend({notify: "always"});
            observable.isInvalid = ko.computed(function() {
                if (observable.isValid() === true) {
                    return false;
                } else if (observable.isValid() === false) {
                    return true;
                } else {
                    return null;
                }
            });
        };

        const _trestaValidationGroup = (validationSettings, validationGroupObservable, isValid) => {
            const isEveryObservableValid = function() {
                return validationSettings.validationGroupObservables.every(function(observable) {
                    if (ko.isObservable(observable) && Array.isArray(observable())) {
                        return observable().every(isValid);
                    } else {
                        return isValid(observable);
                    }
                });
            };
            validationSettings.validationGroupObservables.forEach(function(observable) {
                if (ko.isObservable(observable) && Array.isArray(observable())) {
                    observable().forEach(function(itemObservable) {
                        itemObservable.isValid.subscribe(function() {
                            validationGroupObservable(isEveryObservableValid());
                        });
                    });
                    observable.subscribe(function(changes) {
                        var isDeletes = false;
                        changes.forEach(function(change) {
                            if (change.status === "added" && change.moved === undefined) {
                                change.value.isValid.subscribe(function() {
                                    validationGroupObservable(isEveryObservableValid());
                                });
                            } else if (change.status === "deleted" && change.moved === undefined) {
                                isDeletes = true;
                            }
                        });
                        if (isDeletes) {
                            validationGroupObservable(isEveryObservableValid());
                        }
                    }, null, "arrayChange");
                } else {
                    observable.isValid.subscribe(function() {
                        validationGroupObservable(isEveryObservableValid());
                    });
                }
            });
        };

        ko.extenders.lobbyValidationGroup = function(validationGroupObservable, validationSettings) {
            const isValid = function(observable) {
                const isValid = observable.isValid();
                return isValid === null || isValid;
            };
            _trestaValidationGroup(validationSettings, validationGroupObservable, isValid);
        };

        ko.extenders.lobbyValidationGroupKeyUp = (validationGroupObservable, validationSettings) => {
            const isValid = (observable) => observable.isValid() === true;
            _trestaValidationGroup(validationSettings, validationGroupObservable, isValid);
        };

        ko.extenders.lobbyValidationArray = function(observable, validationSettings) {
            observable().forEach(function(item, index) {
                _addValidationExtensions(validationSettings, item, index);
            });
            observable.subscribe(function(changes) {
                changes.forEach(function(change) {
                    if (change.status === "added") {
                        _addValidationExtensions(validationSettings, change.value, change.index);
                    }
                });
            }, null, "arrayChange");

            _addValidationExtensionsToObservable(observable);

            observable.validationMessages = ko.observableArray([]);
            observable.validationMessage = ko.observable();

            observable.validate = function() {
                var promiseFactory = new PromiseFactoryConstructor();
                return promiseFactory.defer(function(deferredObject) {
                    var isValid = true;
                    var arrayItems = observable();
                    var itemsWaitingValidation = arrayItems.map(function(arrayItem) {
                        var promise = arrayItem.validate();
                        promise.done(function(currentArrayItemIsValid) {
                            if (currentArrayItemIsValid === false) {
                                isValid = false;
                            }
                        });
                        return promise;
                    });

                    $.when.apply(null, itemsWaitingValidation)
                        .done(function() {
                            observable.isValid(isValid);
                            deferredObject.resolve(isValid);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            };
        };

        ko.extenders.lobbyValidation = function(observable, settings) {
            var validationRules = settings.validationRules;

            _addValidationExtensionsToObservable(observable);

            observable.validationMessage = ko.observable();
            observable.validationIndex = ko.observable(0);
            observable.validate = (forceValidation) => {
                const promiseFactory = new PromiseFactoryConstructor();
                return promiseFactory.defer(function(deferredObject) {
                    const validationRulesCopy = validationRules.slice(0);
                    let validationArguments = [];
                    if (settings.triggerFields) {
                        validationArguments = settings.triggerFields.map(triggerField => triggerField());
                    } else {
                        validationArguments = [observable()];
                    }

                    if (settings.isArray === true) {
                        validationArguments.push(settings.parent);
                        validationArguments.push(settings.itemIndex);
                    }

                    const shouldKeyUpValidate = forceValidation !== true &&
                        ko.unwrap(observable.didKeyUpPass) !== true &&
                        ko.unwrap(observable.isKeyUp) === true;

                    observable.validationIndex(observable.validationIndex() +1);
                    const validationSequence = {
                        observable: observable.validationIndex,
                        index: observable.validationIndex()
                    };

                    _validationProcessValidationRules(validationArguments, validationRulesCopy, shouldKeyUpValidate, validationSequence)
                        .done(result => {
                            if (result.validationIndex && result.validationIndex !== observable.validationIndex()) {
                                deferredObject.resolve(false);
                                return;
                            }
                            if (result.isValid !== null) {
                                observable.isValid(result.isValid);
                                observable.didKeyUpPass = true;
                                observable.validationMessage(result.validationMessage);
                            }
                            deferredObject.resolve(result.isValid);
                        })
                        .fail(error => {
                            observable.isValid(false);
                            deferredObject.reject(error);
                        });
                });
            };

            observable.resetValidation = function() {
                observable.isValid(null);
                observable.validationMessage("");
            };
            observable.subscribe(function() {
                observable.validate();
            });

            if (settings.triggerFields) {
                settings.triggerFields.forEach(function(triggerField) {
                    if (triggerField) {
                        triggerField.subscribe(function() {
                            observable.validate();
                        });
                    }
                });
            }
        };

        ko.extenders.manualValidation = function(observable, settings) {
            var validationRules = settings.validationRules;

            _addValidationExtensionsToObservable(observable);

            observable.validationMessage = ko.observable();
            observable.validationIndex = ko.observable(0);
            observable.validate = () => {
                const promiseFactory = new PromiseFactoryConstructor();
                return promiseFactory.defer(function(deferredObject) {
                    const validationRulesCopy = validationRules.slice(0);
                    let validationArguments = [];

                    if (settings.triggerFields) {
                        validationArguments = settings.triggerFields.map(triggerField => triggerField());
                    } else {
                        validationArguments = [observable()];
                    }

                    if (settings.isArray === true) {
                        validationArguments.push(settings.parent);
                        validationArguments.push(settings.itemIndex);
                    }

                    observable.validationIndex(observable.validationIndex() +1);

                    const validationSequence = {
                        observable: observable.validationIndex,
                        index: observable.validationIndex()
                    };

                    _validationProcessValidationRules(validationArguments, validationRulesCopy, false, validationSequence)
                        .done((result) => {
                            if (result.validationIndex && result.validationIndex !== observable.validationIndex()) {
                                deferredObject.resolve(false);
                                return;
                            }
                            if (result.isValid !== null) {
                                observable.isValid(result.isValid);
                                observable.didKeyUpPass = true;
                                observable.validationMessage(result.validationMessage);
                            }
                            deferredObject.resolve(result.isValid);
                        })
                        .fail(error => {
                            observable.isValid(false);
                            deferredObject.reject(error);
                        });
                });
            };

            observable.resetValidation = function() {
                observable.isValid(null);
                observable.validationMessage("");
            };
        };

        var _uiAnimationsConfiguration = require('settings/uiAnimationsConfiguration');

        // Provide error logging for data binding failures.
        var existingBindingProvider = ko.bindingProvider.instance;
        ko.bindingProvider.instance = {
            nodeHasBindings: existingBindingProvider.nodeHasBindings,

            getBindings: function(node, bindingContext) {
                var bindings;
                try {
                    bindings = existingBindingProvider.getBindings(node, bindingContext);
                }
                catch (ex) {
                    var LoggerConstructor = require('common/logging/logger');
                    var logger = new LoggerConstructor();
                    ex.node = node;
                    ex.bindingContext = bindingContext;
                    logger.init();
                    logger.logError(ex);
                }

                return bindings;
            }
        };

        ko.bindingHandlers.uploadThemed = {
            init: function(element, valueAccessor, allBindingsAccessor) {
                var defaults = {
                    wrapperClass: $(element).attr("class"),
                    fileClass: "uploader"
                };

                for(var prop in allBindingsAccessor().uploadThemed) {
                    defaults[prop] = allBindingsAccessor().uploadThemed[prop];
                }

                setTimeout(function() {
                    $(element).uniform(defaults);
                    $(element).siblings('.action').on('click', function() {
                        $(element).click();
                    });
                }, 1);

            },
            update: function(element) {
                $.uniform.update($(element));
            }
        };

        ko.bindingHandlers.checkboxThemed = {
            init: function(element) {
                var $labeledCheckbox = $(element);
                $labeledCheckbox
                    .after("<span class='custom-checkbox'><span class='icon-check'></span></span>")
                    .parents("label")
                    .wrap("<div class='checkbox' />");
                $labeledCheckbox.on("click", function() {
                    var $checkbox = $(this).find("input[type='checkbox']");
                    if ($checkbox.is(":disabled") === true) {
                        return false;
                    } else {
                        $checkbox.prop("checked", !$checkbox.prop("checked"));
                    }
                });
            }
        };

        const _setupClick = function(loadingContainer, element, valueAccessor, spinnerOptions) {
            const spinClass= ".fade";
            loadingContainer.addClass("spinner-button");
            loadingContainer.wrapInner("<span class='will-fade' />");
            loadingContainer.append($("<span class='fade' />"));
            
            $(element).on("click", function(event) {
                event.stopPropagation();
                let $loadingBlockout;
                if ($(element).prev().hasClass("loading-blockout")) {
                    $loadingBlockout = $(element).prev();
                } else {
                    $loadingBlockout = $(".loading-blockout");
                }

                if (!$loadingBlockout.is(":visible")) {
                    $loadingBlockout.show();

                    if(spinnerOptions) {
                        loadingContainer.addClass("loading");
                        $(element).addClass("loading");
                        $(spinClass, loadingContainer).spin(spinnerOptions);
                    }

                    valueAccessor().call(this, ko.dataFor(element), event)
                        .done(function(resolutionOptions) {
                            var keepSpinning;
                            if ((typeof resolutionOptions !== 'undefined') &&
                                (resolutionOptions.keepSpinning)) {
                                keepSpinning = true;
                            } else {
                                keepSpinning = false;
                            }
                            if (!keepSpinning) {
                                loadingContainer.removeClass("loading");
                                $(element).removeClass("loading");
                            }
                            setTimeout(function() {
                                $loadingBlockout.hide();
                            }, 1);
                        })
                        .fail(function() {
                            loadingContainer.removeClass("loading");
                            $(element).removeClass("loading");

                            setTimeout(function() {
                                $loadingBlockout.hide();
                            }, 1);
                        });
                }
            });
        };

        ko.bindingHandlers.longClickNoSpinner = {
            init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                const loadingContainer = $(".selector", element);
                _setupClick(loadingContainer, element, valueAccessor);
            }
        };

        ko.bindingHandlers.longClick = {
            init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                const settings = ko.utils.unwrapObservable(valueAccessor());
    
                const spinnerOptionsDefault = {
                    lines: 8,
                    length: 3,
                    width: 3,
                    radius: 6,
                    color: _colorConstants.colorPrimaryWhite,
                    corners: 1
                };
                
                let spinnerOptions, settingsValueAccessor;
                switch (typeof settings) {
                    case "function":
                        spinnerOptions = spinnerOptionsDefault;
                        settingsValueAccessor = () => settings;
                        break;
                    case "object":
                        spinnerOptions = Object.assign(spinnerOptionsDefault, settings);
                        settingsValueAccessor = () => settings.onButtonClick;
                        break;
                }

                const loadingContainer = $(element);
                _setupClick(loadingContainer, element, settingsValueAccessor, spinnerOptions);
            }
        };

        ko.bindingHandlers.tooltipButton = new ToolTipIconConstructor(true);

        ko.bindingHandlers.tooltipIcon = new ToolTipIconConstructor(false);



        ko.bindingHandlers.pushContent = {
            init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var value = ko.utils.unwrapObservable(valueAccessor());

                if(value === true) {
                    $(element).addClass("pushed-right");
                }
            },

            update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var value = ko.utils.unwrapObservable(valueAccessor());

                if(value === true) {
                    var collapseDuration = _uiAnimationsConfiguration.presentation_navigation_viewModels_sidebarNavigationViewModel_collapseSidebar;

                    setTimeout(function() {
                        if(!$(element).hasClass("pushed-right")) {
                            $(element).addClass("pushed-right");
                        }
                    }, collapseDuration);
                } else {
                    $(element).removeClass("pushed-right");
                }
            }
        };

        var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        var _buildPhoneNumberSpannedText = function(valueAccessor) {
            var value = ko.unwrap(valueAccessor());
            if (value === null) {
                return "";
            }
            if (typeof value === "object") {
                value = value.formattedNumber;
            }
            var spannedText = "";
            var phoneNumberFormatter = new PhoneNumberFormatterConstructor();
            var internationalFormatPhoneNumber = phoneNumberFormatter.toInternational(value);
            if(internationalFormatPhoneNumber.indexOf("-") > -1) {
                var dashSplit = internationalFormatPhoneNumber.split("-");
                for(var i=0; i < dashSplit.length; i++) {
                    spannedText += "<span class='inline'>" + dashSplit[i] + "</span>" + "-";
                }
            } else if(internationalFormatPhoneNumber.indexOf(" ") > -1) {
                var spaceSplit = internationalFormatPhoneNumber.split(" ");
                for(var j=0; j < spaceSplit.length; j++) {
                    spannedText += "<span class='inline'>" + spaceSplit[j] + "</span>" + " ";
                }
            } else if(internationalFormatPhoneNumber) {
                spannedText = "<span class='inline'>" + internationalFormatPhoneNumber + "</span> ";
            }
            spannedText = spannedText.substr(0, spannedText.length-1);

            return spannedText;
        };

        ko.bindingHandlers.phoneNumber = {
            init: function(element, valueAccessor) {
                $(element).append($("<span class='phone-wrapper' />"));
                var spannedText = _buildPhoneNumberSpannedText(valueAccessor);
                $(element).children(".phone-wrapper").append(spannedText);
            },
            update: function(element, valueAccessor) {
                var spannedText = _buildPhoneNumberSpannedText(valueAccessor);
                $(element).children(".phone-wrapper").html(spannedText);
            }
        };

        const getUpdatedNumber = function(element, countryData){
            let updatedNumber;
            let intlTelNumber = element.intlTelInput("getNumber");
            if (intlTelNumber === "+" + countryData.dialCode) {
                updatedNumber = "";
            } else {
                updatedNumber = intlTelNumber;
            }
            
            return updatedNumber;
        };

        ko.bindingHandlers.inputMask = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var dataBindOptions = valueAccessor();
                if (dataBindOptions === null || dataBindOptions === undefined) {
                    return;
                }
                var mask = dataBindOptions.mask;
                const settings = ko.unwrap(valueAccessor());
                if (settings.alias && settings.alias === 'currency') {
                    $(element).inputmask({
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false
                    });
                } else {
                    $(element).inputmask({
                        mask: mask,
                        placeholder: "",
                    });
                }
            },
        };

        const _applyIntlTelPhoneInputStyleEventListeners = (element) => {
            let $flagContainer = $(element).parent().find('.flag-container');
            let $selectedFlag = $flagContainer.find('.selected-flag');
            let $countryList = $flagContainer.find('.country-list');

            $(element).hover(
                () => {
                    $selectedFlag.addClass("intl-tel-input2--glow");
                }, () => {
                    $selectedFlag.removeClass("intl-tel-input2--glow");
                }
            ).blur(() => {
                if (!$countryList.is(':visible')) {
                    $(element).removeClass("intl-tel-input2--open");
                    $(element).removeClass("intl-tel-input2--glow");
                    $selectedFlag.removeClass("intl-tel-input2--glow");
                }
            });

            $flagContainer.hover(
                () => {
                    $(element).addClass("intl-tel-input2--glow");
                }, () => {
                    if (!$countryList.is(':visible')) {
                        $(element).removeClass("intl-tel-input2--glow");
                    }
                }
            );

            $selectedFlag.on("click",() => {
                $(element).addClass("intl-tel-input2--open");
                $selectedFlag.addClass("intl-tel-input2--open");
            }).focus(() => {
                $(element).addClass("intl-tel-input2--glow");
                $selectedFlag.addClass("intl-tel-input2--glow");
            }).blur(() => {
                $(element).removeClass("intl-tel-input2--open");
                $(element).removeClass("intl-tel-input2--glow");
                $selectedFlag.removeClass("intl-tel-input2--open");
                $selectedFlag.removeClass("intl-tel-input2--glow");
            });
        };

        ko.bindingHandlers.intlTelPhone = {
            init: function(element, valueAccessor) {
                const _countryEnumerations = require('constants/countryEnumerations');
                let phoneNumberFormatter = new PhoneNumberFormatterConstructor();
                let dataBindOptions = valueAccessor();
                let countryAbbreviation = dataBindOptions.countryAbbreviation;
                let countryCode = dataBindOptions.countryCode;
                let isSmallWidth = dataBindOptions.isSmallWidth();
                let valueObservable = dataBindOptions.value;
                let valueUpdate = dataBindOptions.valueUpdate();

                $(element).intlTelInput({
                    allowDropdown: true,
                    initialCountry: countryAbbreviation(),
                    onlyCountries: [_countryEnumerations.usa, _countryEnumerations.ca],
                    separateDialCode: true,
                    autoPlaceholder: "off",
                    utilsScript: intlTelInputUtils,
                    preferredCountries: [],
                });

                let $flagContainer = $(element).parent().find('.flag-container');
                let $countryList = $flagContainer.find('.country-list');

                if (isSmallWidth) {
                    $countryList.css("width", "183px");
                }  else {
                    let $inputWidth = $(element).parents().find('.intl-tel-input2').outerWidth();
                    let dropdownWidth = $inputWidth + 'px';
                    $countryList.css("width", dropdownWidth);

                    $(window).on("resize",() => {
                        let $currentInputWidth = $(element).parents().find('.intl-tel-input2').outerWidth();
                        let currentDropdownWidth = $currentInputWidth + 'px';
                        $countryList.css("width", currentDropdownWidth);
                    });
                }

                _applyIntlTelPhoneInputStyleEventListeners(element);

                $(element).intlTelInput("setNumber", phoneNumberFormatter.toNational(valueObservable(), countryAbbreviation()));
                $(element).on('input', function() {
                    let numberValue = $(this).val().replace(/[^\d]/g, '');
                    if (numberValue.length === 10 || valueUpdate === 'keyup') {
                        numberValue = phoneNumberFormatter.toNational(numberValue, countryAbbreviation());
                        let countryData = $(this).intlTelInput("getSelectedCountryData");
                        let updatedNumber = getUpdatedNumber($(this), countryData);

                        if (countryData.iso2 !== countryAbbreviation()) {
                            countryAbbreviation(countryData.iso2);
                        }
                        if (countryData.dialCode !== countryCode()) {
                            countryCode(countryData.dialCode);
                        }
                        if (updatedNumber !== valueObservable()) {
                            $(this).intlTelInput("setNumber", phoneNumberFormatter.toNational(updatedNumber, countryAbbreviation()));
                            valueObservable(updatedNumber);
                        }
                    }
                    valueObservable.isKeyUp(true);
                    $(this).val(numberValue);
                });
                $(element).blur(function(){
                    let countryData = $(this).intlTelInput("getSelectedCountryData");
                    let updatedNumber = getUpdatedNumber($(this), countryData);

                    if (countryData.iso2 !== countryAbbreviation()) {
                        countryAbbreviation(countryData.iso2);
                    }
                    if (countryData.dialCode !== countryCode()) {
                        countryCode(countryData.dialCode);
                    }
                    if (updatedNumber !== valueObservable()) {
                        $(this).intlTelInput("setNumber", phoneNumberFormatter.toNational(updatedNumber, countryAbbreviation()));
                        valueObservable(updatedNumber);
                    }
                    let numberValue = $(this).val().replace(/[^\d]/g, '');
                    if (numberValue.length === 10) {
                        numberValue = phoneNumberFormatter.toNational(numberValue, countryAbbreviation());
                    }
                    $(this).val(numberValue);
                });
            },
            update: function(element, valueAccessor) {
                let dataBindOptions = valueAccessor();
                let valueObservable = dataBindOptions.value;
                let countryAbbreviationObservable = dataBindOptions.countryAbbreviation;
                let phoneNumberFormatter = new PhoneNumberFormatterConstructor();
                let formattedNumber = phoneNumberFormatter.toNational(valueObservable(), countryAbbreviationObservable());
                $(element).intlTelInput("setNumber", formattedNumber);
                $(element).intlTelInput("setCountry", countryAbbreviationObservable());
                $(element).val(formattedNumber);
            }
        };

        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        ko.bindingHandlers.date = {
            update: function(element, valueAccessor) {
                return ko.bindingHandlers.text.update(element, function(){
                    var value = ko.utils.unwrapObservable(valueAccessor());
                    if (value === null) {
                        return null;
                    }
                    var dateTimeValue = new DateTimeValueConstructor(value);
                    return dateTimeValue.displayValue;
                });
            }
        };

        const DateTimeFormatterConstructor = require('common/time/datetimeFormatter');
        ko.bindingHandlers.dateTime = {
            update: function(element, valueAccessor, allBindings) {
                return ko.bindingHandlers.html.update(element, () => {
                    const dateTime = ko.unwrap(valueAccessor());
                    const format = allBindings.get('format');
                    const dateTimeFormatter = new DateTimeFormatterConstructor();
                    let htmlDateTime = dateTimeFormatter.displayDateTime(dateTime, format);

                    let dateSuffixRegex = /\d+(th|st|nd|rd)/gi;
                    let hasDateSuffix = dateSuffixRegex.test(htmlDateTime);
                    if (hasDateSuffix) {
                        htmlDateTime = htmlDateTime.replace(/th|st|nd|rd/gi, (s) => {
                            return '<sup>' + s + '</sup>';
                        });   
                    }

                    return htmlDateTime;
                });
            }
        };

        var _autocompleteEvaluationItems = [];

        ko.bindingHandlers.autocompleteEvaluation = {
            init: function(element, valueAccessor) {
                var _valueAccessor = ko.unwrap(valueAccessor());
                if (_valueAccessor) {
                    if (ko.isWriteableObservable(_valueAccessor)) {
                        _autocompleteEvaluationItems.push({element: element, valueAccessor: _valueAccessor});

                    } else {
                        $(element).one("input", function() {
                            _autocompleteEvaluationItems.forEach(function(autocompleteEvaluationItem) {
                                var _elementValue = $(autocompleteEvaluationItem.element).val();
                                var _valueAccessor = autocompleteEvaluationItem.valueAccessor;
                                if (_valueAccessor !== _elementValue) {
                                    _valueAccessor(_elementValue);
                                }
                            });
                            _autocompleteEvaluationItems = [];
                        });
                    }
                }
            }
        };

        ko.bindingHandlers.autocompleteEvaluationReset = {
            init: function() {
                _autocompleteEvaluationItems = [];
            }
        };

        ko.bindingHandlers.autocompleteForceValues = {
            init: function(element, valueAccessor) {
                if (ko.unwrap(valueAccessor())) {
                    $(element).one("focus", function() {
                        var _elementValue = $(element).val();
                        var _valueAccessor = ko.unwrap(valueAccessor());
                        if (_valueAccessor !== _elementValue) {
                            _valueAccessor(_elementValue);
                        }
                    });
                }
            }
        };

        var _updateElementWithValidationCssError = function(element, isValid, canDisplay = true) {
            var $element = null;
            if (element.nodeName === "SELECT") {
                $element = $(element).parent();
            }else if (element.nodeName === "INPUT" && $(element).attr("type") === "file") {
                $element = $(element).parent();
            } else {
                $element = $(element);
            }

            if (isValid === false && canDisplay) {
                $element.addClass("invalid");
            } else {
                $element.removeClass("invalid");
            }
        };

        ko.bindingHandlers.cssError = {
            init: function(element, valueAccessor) {
                const settings = ko.unwrap(valueAccessor());

                let canDisplay = true;
                if (settings.canDisplay && !settings.canDisplay()) {
                    canDisplay = false;
                } else {
                    canDisplay = true;
                }

                if (settings.field && settings.field.isValid && ko.isObservable(settings.field.isValid)) {
                    settings.field.isValid.subscribe(function(isValid) {
                        _updateElementWithValidationCssError(element, isValid, canDisplay);
                    });

                    _updateElementWithValidationCssError(element, settings.field.isValid(), canDisplay);
                }
            },
            update: function (element, valueAccessor) {
                const settings = ko.unwrap(valueAccessor());
                let canDisplay = true;

                if (settings.canDisplay && !settings.canDisplay()) {
                    canDisplay = false;
                }

                if (settings.field && settings.field.isValid && ko.isObservable(settings.field.isValid)) {
                    _updateElementWithValidationCssError(element, settings.field.isValid(), canDisplay);
                }
            }
        };

        ko.bindingHandlers.numericInput = {
            init: function (element, valueAccessor) {
                if (ko.unwrap(valueAccessor())) {
                    $(element).payment('restrictNumeric');
                }
            }
        };

        ko.bindingHandlers.creditCardInput = {
            init: function (element) {
                $(element).payment('formatCardNumber');
            }
        };

        ko.bindingHandlers.openPopupUrl = {
            init :  function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var settings = valueAccessor();

                var UniqueIdGeneratorConstructor = require('common/uniqueId/uniqueIdGenerator');
                var uniqueIdGenerator = new UniqueIdGeneratorConstructor();

                var targetWindowId = uniqueIdGenerator.generateUniqueIdWithoutHyphens();

                $(element).attr("href","redirecting");
                $(element).attr("target", targetWindowId);
                $(element).on("contextmenu", function() {
                    return false;
                });
                $(element).on("click", function(event) {
                    $.when(settings(bindingContext.$data))
                        .done(function(url) {
                            window.open(url, targetWindowId);
                            targetWindowId = uniqueIdGenerator.generateUniqueIdWithoutHyphens();
                            $(element).attr("target", targetWindowId);
                        });
                });
            }
        };

        ko.bindingHandlers.customId = {
            init : function(element, valueAccessor) {
                var idValue = valueAccessor();

                if (typeof(idValue) === 'string') {
                    element.id = idValue;
                } else if (idValue !== null) {
                    var idFormat = idValue.format;
                    var idFormatArgs = idValue.formatArgs;
                    for (var x = 0; x < idFormatArgs.length; x++) {
                        var formatArg;
                        if (ko.isWriteableObservable(idFormatArgs[x]) === true) {
                            formatArg = idFormatArgs[x]();
                        } else {
                            formatArg = idFormatArgs[x];
                        }
                        var formatArgString;
                        if (formatArg === null || formatArg === undefined) {
                            formatArgString = "";
                        } else {
                            formatArgString = formatArg.toString().replaceAll(" ", "");
                        }

                        idFormat = idFormat.replaceAll("{" + x.toString() + "}", formatArgString);
                    }

                    element.id = idFormat;
                }
            }
        };

        ko.bindingHandlers.urlLink = {
            update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var url = ko.unwrap(valueAccessor());
                if (typeof(url) === 'string') {
                    var newUrl = _urlFormatter.buildWorkSessionUrl(url);
                    $(element).attr("href", newUrl);
                }
            }
        };

        ko.bindingHandlers.customerServiceTelLink = {
            init: function(element, valueAccessor) {
                const phoneNumberFormatter = new PhoneNumberFormatterConstructor();
                const customerServicePhoneNumberDisplay = _i18n.t('customerServiceNumberFormatted');
                const customerServicePhoneNumberFormatted = "tel:" + phoneNumberFormatter.toWebLinkFormat(customerServicePhoneNumberDisplay);
                $(element).attr("href", customerServicePhoneNumberFormatted);
                $(element).text(customerServicePhoneNumberDisplay);
            }
        };

        ko.bindingHandlers.supportMailTolLink = {
            init: function(element, valueAccessor) {
                const supportEmail = _i18n.t('supportEmail');
                const supportMailto = "mailto:" + supportEmail;
                $(element).attr("href", supportMailto);
                $(element).text(supportEmail);
                $(element).on('click', (e) => {
                    e.stopPropagation();
                });

                ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                    $(element).off();
                });
            }
        };

        ko.bindingHandlers.setValue = {
            init : function(element, valueAccessor, allBindingsAccessor, viewModel) {
                var settings = ko.unwrap(valueAccessor());
                var property = settings.viewModelProperty;
                var value = settings.value;
                property(value);
            }
        };

        ko.extenders.observeState = function(observable, isObservedByModelStateObserver) {
            if (isObservedByModelStateObserver) {
                observable.isObservedByModelStateObserver = true;
            }
        };

        const getScrollHeight = (element) => {
            const savedValue = element.value;
            element.value = '';
            element._baseScrollHeight = element.scrollHeight;
            element.value = savedValue;
        };

        ko.bindingHandlers.autoExpandTextArea = {
            init: function(element, valueAccessor) {
                if(element.nodeName !== "TEXTAREA") {
                    return;
                }
                const settings = ko.utils.unwrapObservable(valueAccessor());
                $(element).on("input", function() {
                    const minRows = settings.minRows || 0;
                    const lineHeight = settings.lineHeight || 22;
                    let rows = 0;
                    if (!element._baseScrollHeight) {
                        getScrollHeight(element);
                    }
                    element.rows = minRows;
                    rows = Math.ceil((element.scrollHeight - element._baseScrollHeight) / lineHeight);
                    element.rows = minRows + rows;
                });
            }
        };

        ko.bindingHandlers.i18n = {
            update: function(element, valueAccessor, allBindingsAccessor, data, context) {
                _i18nLocale(); 
                const value = ko.toJS(valueAccessor());
                const moduleId = context.$root.__moduleId__;
                const buildKeyString = (key) => {
                    if (key.includes(":")) {
                        return key;
                    }
                    const viewModelName = moduleId.slice(moduleId.lastIndexOf("/") +1, -9);
                    return `${viewModelName}:${key}`;
                };
                const formatOptions = (options) => {
                    if (options.hasOwnProperty('context') && typeof options.context === 'boolean') {
                        options.context = options.context.toString();
                        return options;
                    }
                    return options;
                };
                if (typeof value === 'string') {
                    element.innerHTML = _i18n.t(buildKeyString(value));
                } else if (value.key && value.options) {
                    element.innerHTML = _i18n.t(buildKeyString(value.key), formatOptions(value.options));
                } else if (value.key) {
                    element.innerHTML = _i18n.t(buildKeyString(value.key));
                }
            }
        };

        ko.bindingHandlers.scrollCheck = {
            update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                const scrollObservable = valueAccessor();
                setTimeout(() => {
                    if ($(element)[0].clientHeight < $(element)[0].scrollHeight) {
                        scrollObservable(true);
                    } else {
                        scrollObservable(false);
                    }
                }, 100);
            }
        };

        ko.bindingHandlers.dismissSoftKeyboardOnLostFocus = {
            update: function(element, valueAccessor) {
                if (valueAccessor){
                    $(document).on('touchstart', function (e) {
                        if (!$(e.target).is('input') && $('input').is(':focus')) {
                            document.activeElement.blur();
                        }
                    });
                }
            }
        };
        
        ko.bindingHandlers.dismissSoftKeyboardOnReturn = {
            update: function(element, valueAccessor) {
                if (valueAccessor && _browserType.isMobile()){
                    $(document).on('keyup', function (e) {
                        if (e.which === 13) {
                            e.target.blur();
                        }
                    });
                }
            }
        };

        ko.bindingHandlers.scrollToKeepContentAboveFold = {
            init: function (element, valueAccessor) {
                let settings = ko.unwrap(valueAccessor());
                let $contentSelector = $(element);
                let $containerSelector = $(settings.containerSelector);

                if ($containerSelector) {
                    $contentSelector.on("click", (e) => {
                        let spaceAboveFold = $(window).height() - e.target.getBoundingClientRect().bottom;

                        if (spaceAboveFold <= $contentSelector.height()) {
                            let scrollTop = e.target.offsetTop - $containerSelector.innerHeight()  + $contentSelector.height() + 'px';
                            $containerSelector.animate({'scrollTop': scrollTop}, "slow");
                        }
                    });
                }
            }
        };

        // https://github.com/knockout/knockout/issues/914
        ko.subscribable.fn.subscribePrevious = function (callbackFn) {
            var savedValue = this.peek();
            return this.subscribe(function (currentValue) {
                const previousValue = savedValue;
                savedValue = currentValue;
                callbackFn(currentValue, previousValue);
            });
        };
    };

    return {
        configureKnockout : configure
    };
});

