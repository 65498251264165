define('presentation/common/creditCard/viewModels/creditCardNumberViewModel',[
        'constants/creditCardTypeEnumerations',
        'jquery-payment'
], function() {
    return function() {
        const self = this;

        const _creditCardEnumerations = require('constants/creditCardTypeEnumerations');
        let _settings = null;

        const _initialize = () => {
            if (_settings.value !== undefined) {
                self.creditCardNumber(_settings.value());
                _settings.value.isKeyUp = ko.observable(false);
            }

            if (ko.isWriteableObservable(_settings.readonly)) {
                self.readonly = _settings.readonly;
            } else if (_settings.readonly !== undefined) {
                self.readonly(_settings.readonly);
            }

            if (_settings.hasOwnProperty('validateOnBlur')) {
                self.validateOnBlur = ko.unwrap(_settings.validateOnBlur);
            }

            const id = _settings.customId || _settings.id;
            if (id !== undefined) {
                self.customId = id;
            }
        };

        self.keyUpEvent = () => {
            if (_settings.valueUpdate === "keyup") {
                _settings.value.isKeyUp(true);
                _settings.value(self.creditCardNumber());
            }
        };
        self.setCardNumberValue = () => {
            _settings.value.isKeyUp(false);
            if (_settings.value() !== self.creditCardNumber()) {
                _settings.value(self.creditCardNumber());
            } else if (_settings.value.validate && self.validateOnBlur) {
                _settings.value.validate();
            }
        };

        self.customId = "";
        self.readonly = ko.observable(false);
        self.creditCardNumber = ko.observable('');
        self.validateOnBlur = true;

        self.cardType = ko.pureComputed(() => {
            if ((_settings.readonlyCreditCardType !== undefined) && (_settings.readonlyCreditCardType !== null)) {
                return _settings.readonlyCreditCardType.toLowerCase();
            } else {
                const cardType = $.payment.cardType(self.creditCardNumber());
                const creditCardEnumeration = _creditCardEnumerations[cardType];
                if (creditCardEnumeration) {
                    return creditCardEnumeration.cssClass;
                } else {
                    return "";
                }
            }
        });

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };
    };
});

