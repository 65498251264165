define('presentation/settings/schedules/viewModels/schedulesViewModel',[
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/storage/commonState',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/deleteActionViewModel',
    'presentation/common/warningModal/viewModels/integrityWarningModalViewModel',
    'presentation/settings/schedules/facades/schedulesFacade',
    'presentation/settings/schedules/viewModels/scheduleCommonFunctions',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteActionViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _commonState = require('common/storage/commonState');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');
        const _scheduleCommonFunctions = require('presentation/settings/schedules/viewModels/scheduleCommonFunctions');

        let _facade = null;

        const DELETE_RESPONSE = "yes";
        const _totalQuarterHours = 96;

        class GridCell {
            constructor() {
                this.inRange = ko.observable();
                this.rangeColor = ko.pureComputed(() => {
                    const currentRange = this.inRange();
                    if (currentRange > 0) {
                        return `range-background__sequence-${currentRange}`;
                    } else {
                        return "range-background__sequence-all-other";
                    }
                });
            }
        }

        const _emptyGridCellsForDay = () => Array.from(new Array(_totalQuarterHours), () => new GridCell());

        const _rangeIndicatorClassNumber = (segmentsCount, segmentSequence) => {
            const classNumbers = [0,1,3,6];
            if (segmentsCount < 4) {
                return classNumbers[segmentSequence];
            } else {
                return segmentSequence;
            }
        };

        const _setScheduleGridRanges = (schedule, segments) => {
            const dayGrids = [
                schedule.sundayGridCells,
                schedule.mondayGridCells,
                schedule.tuesdayGridCells,
                schedule.wednesdayGridCells,
                schedule.thursdayGridCells,
                schedule.fridayGridCells,
                schedule.saturdayGridCells,
            ];
            dayGrids.forEach(dayGrid => dayGrid.forEach(dayGridCell => dayGridCell.inRange(0)));

            segments.forEach(segment => {
                let rangeStartCell = _scheduleCommonFunctions.getGridCell(segment.startHour(), segment.startMinute());
                let rangeEndCell = _scheduleCommonFunctions.getGridCell(segment.endHour(), segment.endMinute()) - 1;
                let rangeNumber = _rangeIndicatorClassNumber(schedule.segments().length, segment.sequence());

                [
                    {isForDay: segment.isForSunday(), dayGridCells: schedule.sundayGridCells},
                    {isForDay: segment.isForMonday(), dayGridCells: schedule.mondayGridCells},
                    {isForDay: segment.isForTuesday(), dayGridCells: schedule.tuesdayGridCells},
                    {isForDay: segment.isForWednesday(), dayGridCells: schedule.wednesdayGridCells},
                    {isForDay: segment.isForThursday(), dayGridCells: schedule.thursdayGridCells},
                    {isForDay: segment.isForFriday(), dayGridCells: schedule.fridayGridCells},
                    {isForDay: segment.isForSaturday(), dayGridCells: schedule.saturdayGridCells}
                ]
                    .forEach(({isForDay, dayGridCells}) => {
                        if (isForDay) {
                            for (let i = rangeStartCell; i <= rangeEndCell; i++) {
                                dayGridCells[i].inRange(rangeNumber);
                            }
                        }
                    });
            });
        };

        const _showIntegrityWarningsPrompt = (scheduleId, integrityWarningPresentationObjects) => {
            _buildIntegrityWarningsModal(scheduleId);
            self.integrityWarningModal.addIntegrityWarnings(integrityWarningPresentationObjects);
            self.integrityWarningModal.showModal();
        };


        const _buildDeleteModal = (scheduleId) => {
            const displayType = _commonState.typeDisplay.schedule;
            let constructorParams = [scheduleId];
            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: {key: 'deleteHeader', options: {type: displayType}}})
                .setSubmitButtonText({i18n: 'delete'});
        };

        const _buildIntegrityWarningsModal = (scheduleId) => {
            const displayType = _commonState.typeDisplay.schedule;
            self.integrityWarningModal
                .clearModal()
                .setHeading({key: 'delete', options: {type: displayType}})
                .addText({i18n: {key: 'inUse', options: {type: displayType.toLowerCase()}}})
                .setSystemObject({objectId: scheduleId})
                .addIntegrityWarningText({i18n: {key: 'conflicts'}});
        };


        const _showDeletePrompt = (schedule) => {
            const scheduleId = schedule.accountAttendantScheduleId;
            _buildDeleteModal(scheduleId);
            self.actionModal.showModal()
                .done((result) => {
                    if (result === DELETE_RESPONSE) {
                        _facade.deleteSchedule(scheduleId)
                            .done(() => {
                                self.availableSchedules.remove(schedule);
                            })
                            .fail((error) => {
                                if (error.constraintViolations && error.constraintViolations.length > 0) {
                                    _showIntegrityWarningsPrompt(scheduleId, error.constraintViolations);
                                } else {
                                    error.accountAttendantScheduleId = scheduleId;
                                    throw error;
                                }
                            });
                    }
                })
                .fail((error) => {
                    throw error;
                });
        };

        const _getSchedules = () => {
            _facade.getSchedules()
                .done((schedules) => {
                    let formattedSchedules = schedules.map((schedule) => {
                        schedule = Object.assign({}, schedule, {
                            sundayGridCells: _emptyGridCellsForDay(),
                            mondayGridCells: _emptyGridCellsForDay(),
                            tuesdayGridCells: _emptyGridCellsForDay(),
                            wednesdayGridCells: _emptyGridCellsForDay(),
                            thursdayGridCells: _emptyGridCellsForDay(),
                            fridayGridCells: _emptyGridCellsForDay(),
                            saturdayGridCells: _emptyGridCellsForDay(),
                            segments: ko.pureComputed(() => {
                                return schedule.segments.sort((a, b) => a.sequence() - b.sequence());
                            }),
                            get setGridRanges() {
                                return ko.computed(() => _setScheduleGridRanges(this, this.segments()));
                            }
                        });

                        return schedule;
                    });

                    self.availableSchedules(formattedSchedules);
                });
        };

        self.actionModal = null;
        self.integrityWarningModal = null;
        self.isCompositionComplete = ko.observable(false);
        self.addScheduleUrl = _navigationConfiguration.routesById.newSchedule.url;
        self.availableSchedules = ko.observableArray([]);

        self.filteredSchedules = ko.pureComputed(() => {
            const filterContent = self.searchFilter();
            const availableSchedules = self.availableSchedules();

            if (filterContent === '') {
                return availableSchedules;
            }

            const scheduleFilter = new FilterConstructor();
            scheduleFilter.addProperty('name');
            scheduleFilter.addValue(filterContent);
            return scheduleFilter.filter(availableSchedules);
        });

        self.displaySchedules = ko.pureComputed(() => {
            const sortableSchedules = self.filteredSchedules();
            const sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(sortableSchedules);
            }
            return sortableSchedules;
        });

        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "name", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.viewOptions = [
            {textI18n: 'expandAll', iconName: 'sortExpandAll', onClick: () => self.expandAllView()},
            {textI18n: 'collapseAll', iconName: 'sortCollapseAll', onClick: () => self.collapseAllView()},
        ];
        self.sortType = ko.observable("name");
        self.searchFilter = ko.observable('');

        self.showResults = ko.pureComputed(() => self.displaySchedules().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => self.availableSchedules().length > 0 ? "noResults" : "noSchedules");

        self.expandAllView = () => {
            self.displaySchedules().forEach((schedule) => {
                schedule.showMap(true);
            });
        };

        self.collapseAllView = () => {
            self.displaySchedules().forEach((schedule) => {
                schedule.showMap(false);
            });
        };

        self.toggleMap = (schedule) => {
            schedule.showMap(!schedule.showMap());
            self.scheduleId = schedule.accountAttendantScheduleId;
        };

        self.launchDeleteSchedule = (schedule) => {
            const scheduleId = schedule.accountAttendantScheduleId;
            return _promiseFactory.deferIndefinitely((promise) => {
                _facade.getConstraints(scheduleId)
                    .done((integrityWarningPresentationObjects) => {
                        if (integrityWarningPresentationObjects.length > 0) {
                            _showIntegrityWarningsPrompt(scheduleId, integrityWarningPresentationObjects);
                        } else {
                            _showDeletePrompt(schedule);
                        }
                        promise.resolve();
                    });
            });
        };

        self.navigateToEditSchedule = (schedule) => {
            _router.navigate(schedule.editScheduleUrl);
        };


        self.scheduleMapDayLabels = _scheduleCommonFunctions.scheduleMapDayLabels;
        self.scheduleMapTimeLabels = _scheduleCommonFunctions.scheduleMapTimeLabels;

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };
        
        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/schedules/facades/schedulesFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const IntegrityWarningModalViewModelConstructor = require('presentation/common/warningModal/viewModels/integrityWarningModalViewModel');
            self.integrityWarningModal = new IntegrityWarningModalViewModelConstructor();


            return _initialize();
        };

        const _initialize = () => {
            _getSchedules();

            return _promiseFactory.wait();
        };
    };
});

