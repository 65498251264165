define('presentation/common/select/viewModels/selectViewModel',[], function() {
    return function () {
        const self = this;

        let _settings;
        let _disposables = [];

        const _updateValue = () => {
            if (self.useOptionObjectValue) {
                self.selectedOption(self.optionsMap().get(self.selectedOptionArray()[0]));
            } else {
                self.selectedOption(self.selectedOptionArray()[0]);
            }
        };

        const _updateSelectedValue = () => {
            if (self.selectedOption() !== undefined && self.useOptionObjectValue === false) {
                self.selectedOptionArray([self.selectedOption()]);
            }
        };

        const _buildPlaceholderOption = () => {
            let placeholderOption = {};
            placeholderOption[self.avatarProperty] = "";
            placeholderOption[self.avatarTypeProperty] = "";
            placeholderOption[self.textProperty] = self.placeholderMessage;
            placeholderOption[self.searchProperty] = "";
            placeholderOption[self.iconProperty] = "";
            placeholderOption[self.valueProperty] = -1;

            return placeholderOption;
        };

        const _formatOptions = (options, selectedOption) => {
            let optionsCopy = [...options];

            if (self.includeSelectedAsOption) {
                optionsCopy.unshift(selectedOption);
            }

            if (self.placeholderMessage) {
                optionsCopy.unshift(_buildPlaceholderOption());
            }

            return optionsCopy.map((option) => {
                let formattedOption = Object.assign({}, option);
                if (self.avatarProperty !== null) {
                    formattedOption.avatar = option[self.avatarProperty];
                }

                if (self.avatarTypeProperty !== null) {
                    formattedOption.avatarType = option[self.avatarTypeProperty];
                }

                if (self.textProperty !== null) {
                    formattedOption.text = ko.unwrap(option[self.textProperty]);
                }

                if (self.searchProperty !== null) {
                    formattedOption.displayText = formattedOption.text;
                    formattedOption.text = ko.unwrap(option[self.searchProperty]);
                } else {
                    formattedOption.displayText = formattedOption.text;
                }

                if (self.iconProperty !== null) {
                    formattedOption.iconProperty = option[self.iconProperty];
                }

                if (self.valueProperty !== null) {
                    formattedOption.id = ko.unwrap(option[self.valueProperty]);
                }

                if (self.placeholderMessage !== null) {
                    formattedOption.placeholder = self.placeholderMessage;
                }

                formattedOption.groupId = ko.unwrap(option[self.groupIdProperty]);

                return formattedOption;
            });
        };

        const _buildOptionsMap = (formattedOptions) => {
            let optionsMap = new Map();
            formattedOptions.forEach((option) => {
                optionsMap.set(option.id, option);
            });

            return optionsMap;
        };

        self.options = ko.observableArray([]);
        self.selectedOptionArray = ko.observable();
        self.selectedOption = ko.observable();
        self.scrollOnSelect = ko.observable(false);
        self.disabled = ko.observable(false);

        self.includeSelectedAsOption = false;
        self.avatarProperty = null;
        self.avatarTypeProperty = null;
        self.emptyMessage = null;
        self.emptyIcon = null;
        self.groupIdProperty = "id";
        self.textProperty = "text";
        self.searchProperty = null;
        self.dropdownCssClass = "default";
        self.iconProperty = null;
        self.isMembershipControl = false;
        self.noResultsMessage = null;
        self.placeholderMessage = null;
        self.useOptionObjectValue = false;
        self.theme = "default select-theme-light";
        self.valueProperty = "id";
        self.minimumResultsForSearch = 0;

        self.formattedOptions = ko.pureComputed(() => _formatOptions(self.options(), self.selectedOption()));
        self.optionsMap = ko.pureComputed(() => _buildOptionsMap(self.formattedOptions()));

        self.detached = () => {
            _disposables.forEach(disposable => disposable.dispose());
            _disposables = [];
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            if (ko.isObservable(_settings.options)) {
                self.options = _settings.options;
            } else {
                self.options(_settings.options);
            }

            if (ko.isObservable(_settings.selectedOption) === false) {
                throw new Error('selectedOption must be observable');
            }

            self.selectedOption = _settings.selectedOption;
            _disposables.push(_settings.selectedOption.subscribe(_updateSelectedValue));

            if (_settings.avatarProperty) {
                self.avatarProperty = ko.unwrap(_settings.avatarProperty);
            }

            if (_settings.avatarTypeProperty) {
                self.avatarTypeProperty = ko.unwrap(_settings.avatarTypeProperty);
            }

            if (_settings.disabled) {
                if (ko.isObservable(_settings.disabled)) {
                    self.disabled = _settings.disabled;
                } else {
                    self.disabled(_settings.disabled);
                }
            }

            if (_settings.emptyMessage) {
                self.emptyMessage = ko.unwrap(_settings.emptyMessage);
            }

            if (_settings.emptyIcon) {
                self.emptyIcon = ko.unwrap(_settings.emptyIcon);
            }

            if (_settings.groupIdProperty) {
                self.groupIdProperty = ko.unwrap(_settings.groupIdProperty);
            }

            if (_settings.scrollOnSelect) {
                if (ko.isObservable(_settings.scrollOnSelect)) {
                    self.scrollOnSelect = _settings.scrollOnSelect;
                } else {
                    self.scrollOnSelect(_settings.scrollOnSelect);
                }
            }

            if (_settings.searchProperty) {
                self.searchProperty = ko.unwrap(_settings.searchProperty);
            }

            if (_settings.textProperty) {
                self.textProperty = ko.unwrap(_settings.textProperty);
            }

            if (_settings.dropdownCssClass) {
                self.dropdownCssClass = ko.unwrap(_settings.dropdownCssClass);
            }

            if (_settings.iconProperty) {
                self.iconProperty = ko.unwrap(_settings.iconProperty);
            }

            if (_settings.includeSelectedAsOption) {
                self.includeSelectedAsOption = ko.unwrap(_settings.includeSelectedAsOption);
            }

            if (_settings.isMembershipControl) {
                self.isMembershipControl = ko.unwrap(_settings.isMembershipControl);
            }

            if (_settings.noResultsMessage) {
                self.noResultsMessage = ko.unwrap(_settings.noResultsMessage);
            }

            if (_settings.placeholderMessage) {
                self.placeholderMessage = ko.unwrap(_settings.placeholderMessage);
            }

            if (_settings.useOptionObjectValue) {
                self.useOptionObjectValue = ko.unwrap(_settings.useOptionObjectValue);
            }

            if (_settings.theme) {
                self.theme = ko.unwrap(_settings.theme);
            }

            if (_settings.valueProperty) {
                self.valueProperty = ko.unwrap(_settings.valueProperty);
            }

            if (_settings.isSearchHidden) {
                self.minimumResultsForSearch = Infinity;
            }

            if (self.useOptionObjectValue) {
                self.selectedOptionArray([self.selectedOption()[self.valueProperty]]);
            } else {
                self.selectedOptionArray([self.selectedOption()]);
            }

            _disposables.push(self.selectedOptionArray.subscribe(_updateValue));
        };
    };
});
