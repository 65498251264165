define('presentation/common/voicemail/facades/addVoicemailGreetingFacade',['businessServices/converters/durationTimeFormatter',
        'businessServices/uploads/customerUpload',
        'common/encryption/crypto',
        'common/files/encoding',
        'common/promises/waitHandle',
        'common/time/timeSpan',
        'constants/referentialConstraintEnumerations',
        'externalDependencies/clientWebSocket',
        'persistence/fileStores/fileManagement/fileReader',
        'presentation/common/audioPresentationObject',
        'presentation/common/voicemail/presentationObjects/voicemailGreetingPresentationObject',
        'persistence/webSocket/webSocketApp',
], function () {
    return function () {
        const self = this;

        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const TimeSpanConstructor = require('common/time/timeSpan');
        const WaitHandleConstructor = require('common/promises/waitHandle');

        const clientWebSocket = require('externalDependencies/clientWebSocket');
        const _audioPreviewSocket = clientWebSocket.forApplication("audioPreview");

        const _customerUpload = require('businessServices/uploads/customerUpload');

        let _encryption = null;
        let _encoding = null;
        let _fileReader = null;
        let _promiseFactory = null;
        let _webSocketApp = null;

        const onAudioPreviewCompleted = (previewInfo) => {
            if (previewInfo.uploadId === waitingForPreviewUploadId) {
                if (previewInfo.isSuccessful) {
                    waitingForPreviewInfo = previewInfo;
                    waitingForPreviewUploadIdFound = true;
                } else {
                    waitingForPreviewUploadIdFound = false;
                }

                waitingForPreview.setGreenLight();
            }
        };

        _audioPreviewSocket.subscribeToEvent("audioPreviewConversionCompleted", onAudioPreviewCompleted);

        const waitingForPreview = new WaitHandleConstructor();
        waitingForPreview.setRedLight();
        let waitingForPreviewInfo = null;
        let waitingForPreviewUploadId = null;
        let waitingForPreviewUploadIdFound = false;

        const _getFormattedDurationProperty = (greetingDurationInSeconds) => {
            const duration = new TimeSpanConstructor();
            const formatter = new TimeFormatterConstructor();
            duration.fromSeconds(greetingDurationInSeconds);
            const formattedTimeDuration = formatter.format(duration);

            return formattedTimeDuration;
        };

        self.cancelGetConvertedFile = () => {
            waitingForPreviewUploadIdFound = false;
            waitingForPreview.setGreenLight();
        };

        self.getAudioConversionAudio = (audioConversionId) => {
            return _promiseFactory.defer((deferredObject) => {
                const data = {audioConversionId: audioConversionId};
                _webSocketApp.send("getAudioConversionUrl", data, (result) => {
                    const audioConversionResult = {};
                    audioConversionResult.status = result.status;
                    audioConversionResult.audioConversionId = audioConversionId;

                    if (result.status === "success") {
                        audioConversionResult.url = result.url;
                        audioConversionResult.duration = _getFormattedDurationProperty(result.durationInSeconds);
                    }
                    deferredObject.resolve(audioConversionResult);
                });
            });
        };

        self.convertFile = (voicemailGreetingAudioObject) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                const fileHandler = voicemailGreetingAudioObject.fileHandler();

                _customerUpload.uploadFile(fileHandler)
                    .fail(promise.reject)
                    .done(({uploadId}) => {
                        waitingForPreviewUploadIdFound = false;
                        waitingForPreviewUploadId = uploadId;
                        waitingForPreview.setRedLight();

                        const convertRequest = {
                            uploadId : uploadId
                        };

                        voicemailGreetingAudioObject.audioConversionId(uploadId);
                        voicemailGreetingAudioObject.showConversionMessage(true);

                        _audioPreviewSocket.send("convertToMp3", convertRequest, (convertResponse) => {
                            if (convertResponse.isQueued === false) {
                                promise.reject(new Error("error requesting mp3 conversion"));
                                return;
                            }

                            waitingForPreview.waitForSignal(() => {
                                if (waitingForPreviewUploadIdFound === false) {
                                    voicemailGreetingAudioObject.showConversionFailedMessage(true);
                                    return;
                                }

                                // This has to be formatted here for stupid reasons
                                const DurationTimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
                                const durationTimeFormatter = new DurationTimeFormatterConstructor();
                                const duration = durationTimeFormatter.formatTotalMilliseconds(waitingForPreviewInfo.durationInMilliseconds);

                                voicemailGreetingAudioObject.audioData(waitingForPreviewInfo.playUrl);
                                voicemailGreetingAudioObject.duration(duration);
                                voicemailGreetingAudioObject.showConversionMessage(false);
                                voicemailGreetingAudioObject.showConversionFailedMessage(false);

                                promise.resolve();
                            });
                        });
                    });
            });
        };

        self.init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const FileReaderConstructor = require('persistence/fileStores/fileManagement/fileReader');
            _fileReader = new FileReaderConstructor();

            const EncryptionConstructor = require('common/encryption/crypto');
            _encryption = new EncryptionConstructor();

            const EncodingConstructor = require('common/files/encoding');
            _encoding = new EncodingConstructor();

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("account_voicemail_greetings");
        };
    };
});

