define('persistence/repositories/autoAttendantVoiceSubMenuRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        /**
         * Add AutoAttendantVoceSubMenu
         * @method  add
         * @return {promise<Object>}
         */
        self.add = function(autoAttendantVoiceSubMenuEntity) {
            return _promiseFactory.defer(function(deferredObject) {
                var autoAttendantVoiceSubMenuDocument = {
                    data : autoAttendantVoiceSubMenuEntity
                };
                _kazooApi.callAccount('/lobby_autoattendant_voice_submenu', 'PUT', autoAttendantVoiceSubMenuDocument)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        error.autoAttendantVoiceSubMenuEntity = autoAttendantVoiceSubMenuEntity;
                        deferredObject.reject(error);
                    });
            });
        };

        /**
         * Update AutoAttendantVoceSubMenu
         * @method  update
         * @param {Object} autoAttendantVoiceSubMenuEntity
         * @return {promise<Object>}
         */
        self.update = function(autoAttendantVoiceSubMenuEntity) {
            if (autoAttendantVoiceSubMenuEntity.autoAttendantVoiceRouteId === null) {
                var err = new Error("Missing autoAttendantVoiceRouteId");
                err.lobbyAutoAttendantVoiceSubMenu = autoAttendantVoiceSubMenuEntity;
                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    var autoAttendantVoiceSubMenuDocument = {
                        data: autoAttendantVoiceSubMenuEntity
                    };
                    _kazooApi.callAccount('/lobby_autoattendant_voice_submenu/' + autoAttendantVoiceSubMenuEntity.autoAttendantVoiceSubMenuId, 'POST', autoAttendantVoiceSubMenuDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.autoAttendantVoiceSubMenuEntity = autoAttendantVoiceSubMenuEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        /**
         * Remove AutoAttendantVoceSubMenu
         * @method  remove
         * @param {Object} autoAttendantVoiceSubMenuId
         * @return {promise<Object>}
         */
        self.remove = function(autoAttendantVoiceSubMenuId) {
            if (autoAttendantVoiceSubMenuId === null) {
                var err = new Error("Missing autoAttendantVoiceSubMenuId");
                err.lobbyAutoAttendantVoiceSubMenu = {autoAttendantVoiceSubMenuId: autoAttendantVoiceSubMenuId};
                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_autoattendant_voice_submenu/' + autoAttendantVoiceSubMenuId,'DELETE')
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.autoAttendantVoiceSubMenuId = autoAttendantVoiceSubMenuId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

