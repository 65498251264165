define('presentation/settings/contacts/facades/contactPermissionsFacade',[
    'businessServices/contacts/contactsStateSingleton',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'i18next'
], function (
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/storage/commonState') */
    _commonState,
    /** @type import('i18next') */
    _i18n
) {
    /** @typedef {import('presentation/settings/contacts/facades/contactPermissionsFacade')} ContactPermissionsFacade */

    return function (/** @type { PromiseFactory } */ promiseFactory) {

        /**@type { ContactPermissionsFacade }*/
        const self = this;

        const _promiseFactory = promiseFactory;

        /**  @type ContactPermissionsFacade["allUsersAndUserGroups"] */
        self.allUsersAndUserGroups = ko.pureComputed(() => {
            return _contactsState.allUsersAndUserGroups();
        });

        /**  @type ContactPermissionsFacade["updateContactPermissions"] */
        self.updateContactPermissions = (updateContactPermissions) => {
            const newUserIdsWithContactPermissions = updateContactPermissions
                .filter((permission) => Boolean(permission.userId))
                .map((permission) => permission.userId);

            const newUserGroupIdsWithContactPermissions = updateContactPermissions
                .filter((permission) => Boolean(permission.userGroupId))
                .map((permission) => _commonState.resolveGroupIdToGuid(permission.userGroupId));

            const updateContactPermissionsRequest = {
                userIdsWithContactPermissions: newUserIdsWithContactPermissions,
                userGroupIdsWithContactPermissions: newUserGroupIdsWithContactPermissions
            };

            return _promiseFactory.defer((deferredObject) => {
                _contactsState.updateContactPermissions(updateContactPermissionsRequest)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };
    };
});
