define('presentation/common/videoPlayer/viewModels/videoPlayerViewModel',[
    'i18next',
    'constants/videoPlayerConstants'
], function (
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('constants/videoPlayerConstants') */
    VideoPlayerConstants
) {
    /** @typedef {import('presentation/common/videoPlayer/viewModels/videoPlayerViewModel')} VideoPlayerViewModel */

    return function () {
        /** @type {VideoPlayerViewModel} */
        const self = this;

        self.attachmentId = ko.observable(null);
        self.src = ko.observable(null);
        self.contentType = ko.observable(null);
        self.videoInitialLoadTime = ko.observable(null);
        self.wasVideoStarted = ko.observable(false);
        self.isVideoPlaying = ko.observable(false);
        self.isVideoMuted = ko.observable(false);
        self.isVideoInFullScreen = ko.observable(false);
        self.isInFullScreenMediaModal = ko.observable(false);
        self.videoCurrentTime = ko.observable(null);
        self.videoTotalTime = ko.observable(null);
        self.isHoveringOverVideo = ko.observable(false);
        self.togglePlayPauseVideo = ko.observable(false);
        self.toggleMuteVideo = ko.observable(false);
        self.toggleFullScreen = ko.observable(false);
        self.mouseHoveredPassedTimer = ko.observable(false);
        self.mouseHoverTimerTriggered = ko.observable(false);
        self.isVideoLoaded = ko.observable(false);
        self.isHoveringOverVolumeControls = ko.observable(false);
        self.volumeSliderWasClicked = ko.observable(false);

        /** @type {null | ReturnType<typeof setTimeout>} */
        let videoMouseHoverTimeoutId = null;

        self.videoFullScreenTooltip = i18n.t("messages:fullScreen");
        self.videoPlayTooltip = i18n.t("messages:play");
        self.videoPauseTooltip = i18n.t("messages:pause");
        self.videoMuteTooltip = i18n.t("messages:mute");
        self.videoUnmuteTooltip = i18n.t("messages:unmute");

        self.showPlayPill = ko.pureComputed(() => !self.wasVideoStarted() && self.videoTotalTime() !== VideoPlayerConstants.defaultTotalTime);
        self.allowControlButtonTooltips = ko.pureComputed(() => self.showControlsOverlay() && self.fadeInControls());
        self.dataStatePlayPause = ko.pureComputed(() => self.isVideoPlaying() ? 'play' : 'pause');
        self.dataStateMute = ko.pureComputed(() => self.isVideoMuted() ? 'mute' : 'unmute');
        self.showVideoControlsPlaySvg = ko.pureComputed(() => !self.isVideoPlaying());
        self.showVideoControlsPauseSvg = ko.pureComputed(() => self.isVideoPlaying());
        self.showVideoControlsMuteSvg = ko.pureComputed(() => !self.isVideoMuted());
        self.showVideoControlsUnmuteSvg = ko.pureComputed(() => self.isVideoMuted());
        self.isHoveringOverPlayingVideo = ko.pureComputed(() => self.isVideoPlaying() && self.isHoveringOverVideo());
        self.showVideoControlsExpandSvg = ko.pureComputed(() => !self.isVideoInFullScreen());
        self.showVideoControlsMinimizeSvg = ko.pureComputed(() => self.isVideoInFullScreen());
        self.showControlsOverlay = ko.pureComputed(() => {
            const videoIsPaused = self.wasVideoStarted() && !self.isVideoPlaying();
            const hoverTimerOkay = self.mouseHoveredPassedTimer() === false;
            return self.isVideoLoaded() && (videoIsPaused || (hoverTimerOkay && self.isHoveringOverPlayingVideo()));
        });
        self.fadeInControls = ko.pureComputed(() => self.showControlsOverlay());
        self.fadeOutControls = ko.pureComputed(() => !self.showControlsOverlay());
        self.showVolumeSlider = ko.pureComputed(() => self.isHoveringOverVolumeControls() || self.volumeSliderWasClicked());
        self.showFullScreenStyles = ko.pureComputed(() => self.isVideoInFullScreen() || self.isInFullScreenMediaModal());

        //#region Hovering Video
        self.startMouseHoverTimer = () => {
            self.mouseHoverTimerTriggered(true);

            const fourSeconds = 4 * 1000;
            videoMouseHoverTimeoutId = setTimeout(function () {
                self.mouseHoveredPassedTimer(true);
            }, fourSeconds);
        };

        self.clearMouseHoverTimer = () => {
            if (videoMouseHoverTimeoutId) {
                clearTimeout(videoMouseHoverTimeoutId);
            }
            self.mouseHoverTimerTriggered(false);
            self.mouseHoveredPassedTimer(false);
        };

        self.onMouseEnterVideoPlayer = () => {
            self.isHoveringOverVideo(true);
            if (self.isVideoPlaying() && !self.mouseHoverTimerTriggered()) {
                self.startMouseHoverTimer();
            }
        };

        self.onMouseLeaveVideoPlayer = () => {
            self.isHoveringOverVideo(false);
            self.clearMouseHoverTimer();
        };

        self.onMouseMoveOverVideoPlayer = () => {
            if (self.isHoveringOverPlayingVideo() && self.mouseHoverTimerTriggered()) {
                resetMouseHoverTime();
            }
        };

        const resetMouseHoverTime = () => {
            self.clearMouseHoverTimer();
            self.startMouseHoverTimer();
        };
        //#endregion

        //#region Hovering Mute // Volume
        self.onMouseOverVolumeControls = () => {
            self.isHoveringOverVolumeControls(true);
        };

        self.onMouseOutVolumeControls = () => {
            self.isHoveringOverVolumeControls(false);
        };

        self.onMouseVolumeSliderClick = (/** @type {VideoPlayerViewModel} */data, /** @type {JQuery.ClickEvent} */event) => {
            self.volumeSliderWasClicked(true);
            self.clearMouseHoverTimer();
            event.target.focus();
        };

        self.onMouseVolumeSliderFocusOut = (/** @type {VideoPlayerViewModel} */data, /** @type {JQuery.ClickEvent} */event) => {
            resetMouseHoverTime();

            if (event.relatedTarget === null) {
                self.volumeSliderWasClicked(false);
                return;
            }

            const videoPlayerWasClicked = Array.from(event.relatedTarget.classList).some((/** @type {string} */className) => className.includes('video'));
            if (videoPlayerWasClicked) {
                event.target.focus();
            } else {
                self.volumeSliderWasClicked(false);
            }
        };

        self.onMouseOutVolumeSlider = () => {
            if (self.isHoveringOverPlayingVideo()) {
                resetMouseHoverTime();
            }
        };
        //#endregion

        //#region Video Controls
        self.onPlayClick = () => {
            self.togglePlayPauseVideo(true);
        };

        self.onMuteClick = () => {
            self.toggleMuteVideo(true);
        };

        self.onFullScreenClick = () => {
            self.toggleFullScreen(true);
        };
        //#endregion

        /** @type {VideoPlayerViewModel["activate"]} */
        self.activate = (activationData) => {
            const {videoObject, isInFullScreenMediaModal} = activationData;

            self.attachmentId = videoObject.attachmentId || ko.observable(null);
            self.contentType = videoObject.contentType || ko.observable(null);
            self.src = videoObject.src || ko.observable(null);
            self.wasVideoStarted = videoObject.wasVideoStarted || ko.observable(false);
            self.videoCurrentTime = videoObject.videoCurrentTime || ko.observable(null);
            self.videoTotalTime = videoObject.videoTotalTime || ko.observable(null);
            self.videoInitialLoadTime = videoObject.videoInitialLoadTime || ko.observable(null);
            self.isInFullScreenMediaModal(isInFullScreenMediaModal || false);

            return _initialize();
        };

        const _initialize = () => {

        };
    };
});
