define('presentation/common/membershipGroupAvatar/viewModels/membershipGroupAvatarViewModel',[
    'common/storage/commonState',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/systemObjectIconNameConstants'
], function(){
    return function() {
        const self = this;
    
        const _autoAttendantVoiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _commonState = require('common/storage/commonState');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _members = null;
        let _settings = null;

        let _membershipAvatarContainerHeight = 48;
        let _membershipAvatarContainerWidth = 48;
    
        const _getPreparedMemberById = (id, index) => {
            const member = Object.assign({}, _commonState.get(id));
            member.showImage = member.isUser ? member.avatar().showImage : ko.observable(false);
        
            return _prepareMemberForDisplay(member, index);
        };

        const _prepareMemberForDisplay = (member, index) => {
            if (_members.length > 3) {
                member.containerHeight = self.quarterIconContainerHeightBinding;
                member.containerWidth = self.quarterIconContainerWidthBinding;
                member.groupIconHeight = self.quarterIconHeightBinding;
                member.groupIconWidth = self.quarterIconWidthBinding;
                switch (index) {
                    case 0:
                        member.containerCss = 'icon-group-quarter icon-group-quarter-top-left';
                        break;
                    case 1:
                        member.containerCss = 'icon-group-quarter icon-group-quarter-top-right';
                        break;
                    case 2:
                        member.containerCss = 'icon-group-quarter icon-group-quarter-bottom-left';
                        break;
                    case 3:
                        member.containerCss = 'icon-group-quarter icon-group-quarter-bottom-right';
                        break;
                    default:
                        member.containerCss = '';
                        break;
                }
                return member;
            } else if (_members.length === 3) {
                let isFirstMember = index === 0;
                member.containerHeight = isFirstMember ? self.halfIconContainerHeightBinding : self.quarterIconContainerHeightBinding;
                member.containerWidth = isFirstMember ? self.halfIconContainerWidthBinding : self.quarterIconContainerWidthBinding;
                member.groupIconHeight = isFirstMember ? self.halfIconHeightBinding : self.quarterIconHeightBinding;
                member.groupIconWidth = isFirstMember ? self.halfIconWidthBinding : self.quarterIconWidthBinding;
                switch (index) {
                    case 0:
                        member.containerCss = 'icon-group-half icon-group-half-left';
                        break;
                    case 1:
                        member.containerCss = 'icon-group-quarter icon-group-quarter-top-right';
                        break;
                    case 2:
                        member.containerCss = 'icon-group-quarter icon-group-quarter-bottom-right';
                        break;
                    default:
                        member.containerCss = '';
                        break;
                }
                return member;
            }  else if (_members.length === 2)  {
                member.containerHeight = self.halfIconContainerHeightBinding;
                member.containerWidth = self.halfIconContainerWidthBinding;
                member.groupIconHeight = self.halfIconHeightBinding;
                member.groupIconWidth = self.halfIconWidthBinding;
                switch (index) {
                    case 0:
                        member.containerCss = 'icon-group-half icon-group-half-left';
                        break;
                    case 1:
                        member.containerCss = 'icon-group-half icon-group-half-right';
                        break;
                    default:
                        member.containerCss = '';
                        break;
                }
                return member;
            } else {
                member.containerHeight = self.fullIconContainerHeightBinding;
                member.containerWidth = self.fullIconContainerWidthBinding;
                member.containerCss = 'user-avatar-icon';
                member.groupIconHeight = self.fullIconHeightBinding;
                member.groupIconWidth = self.fullIconWidthBinding;
                return member;
            }
        };

        self.membershipAvatarContainerHeight = ko.observable(_membershipAvatarContainerHeight);
        self.membershipAvatarContainerWidth = ko.observable(_membershipAvatarContainerWidth);

        self.quarterIconContainerHeightBinding = ko.pureComputed(() => (self.membershipAvatarContainerHeight() / 2.0) - 1);
        self.quarterIconContainerWidthBinding = ko.pureComputed(() => (self.membershipAvatarContainerWidth() / 2.0) - 1);
        self.quarterIconHeightBinding = ko.pureComputed(() => self.quarterIconContainerHeightBinding() - 4.0);
        self.quarterIconWidthBinding = ko.pureComputed(() => self.quarterIconContainerWidthBinding() - 4.0);

        self.halfIconContainerHeightBinding = ko.pureComputed(() => self.membershipAvatarContainerHeight());
        self.halfIconContainerWidthBinding = ko.pureComputed(() => (self.membershipAvatarContainerWidth() / 2.0) - 1);
        self.halfIconHeightBinding = ko.pureComputed(() => self.halfIconContainerHeightBinding() - 2.0);
        self.halfIconWidthBinding = ko.pureComputed(() => self.halfIconContainerWidthBinding() - 2.0);

        self.fullIconContainerHeightBinding = ko.pureComputed(() => self.membershipAvatarContainerHeight());
        self.fullIconContainerWidthBinding = ko.pureComputed(() => self.membershipAvatarContainerWidth());
        self.fullIconHeightBinding = ko.pureComputed(() => (self.fullIconContainerHeightBinding() / 2.0) + 4);
        self.fullIconWidthBinding = ko.pureComputed(() => (self.fullIconContainerWidthBinding() / 2.0) + 4);

        self.members = ko.observableArray([]);
        self.memberCount = ko.pureComputed(() => self.members().length);
        

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            
            if (_settings.memberIds) {
                _members = ko.unwrap(_settings.memberIds).slice(0, 4);

                self.members = _members.map((id, index) => {
                    return _getPreparedMemberById(id, index);
                });
            }
    
            if (_settings.autoAttendantMemberIds) {
                _members = ko.unwrap(_settings.autoAttendantMemberIds).slice(0, 4);
        
                self.members = _members.map((id, index) => {
                    if (id === _autoAttendantVoiceRouteEnumerations.subMenu) {
                        const subMenuMember = {
                            showImage: ko.observable(false),
                            iconName: _systemObjectIconNames.subMenu,
                        };
                        return _prepareMemberForDisplay(subMenuMember, index);
                    }
                    return _getPreparedMemberById(id, index);
                });
            }

            if (_settings.members) {
                _members = _settings.members.slice(0, 4);

                self.members = _members.map((member, index) => {
                    member.avatar = member.userAvatar;
                    member.showImage = member.userAvatar() ? member.userAvatar().showImage : ko.observable(false);

                    return _prepareMemberForDisplay(member, index);
                });
            }

            if (_settings.membershipAvatarContainerHeight) {
                _membershipAvatarContainerHeight = ko.unwrap(_settings.membershipAvatarContainerHeight);
                self.membershipAvatarContainerHeight(_membershipAvatarContainerHeight);
            }

            if (_settings.membershipAvatarContainerWidth) {
                _membershipAvatarContainerWidth = ko.unwrap(_settings.membershipAvatarContainerWidth);
                self.membershipAvatarContainerWidth(_membershipAvatarContainerWidth);
            }
        };
    };
});

