define('businessServices/authentication/stores/frontendAuthenticationStore',[
    'common/storage/cookieStorage',
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket',
], function(cookieStorage) {
    var KEY = 'tresta-auth';

    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = new PromiseFactoryConstructor();

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    let _frontendAuthenticationWebSocket = clientWebSocket.forApplication("frontendAuthentication");

    var _isStandard = null;

    const getFrontendAuthenticationToken = (clientToken) => {
        return _promiseFactory.defer((promise) => {

            _frontendAuthenticationWebSocket.send("getFrontendAuthenticationToken", {clientToken: clientToken}, (response, errMsg) => {
                if (errMsg) {
                    throw new Error(errMsg);
                }
                let frontendAuthenticationToken;
                if(response) {
                    frontendAuthenticationToken = response.frontendAuthenticationToken;
                }
                promise.resolve(frontendAuthenticationToken);
            });
        });
    };

    const receiveAlertLoginSuccessful = (loginInfo) => {
        if (loginInfo.loginType === "standard") {
            _isStandard = true;
            var value = {
                token : loginInfo.authenticationToken,
                userId : loginInfo.userId,
                accountId : loginInfo.accountId
            };
            saveValue(value);
        } else {
            _isStandard = false;
        }
    };

    const receiveAlertLogout = () => {
        if (_isStandard === true) {
            deleteValue();
            _isStandard = null;
        }
    };

    const saveValue = (value) => {
        getFrontendAuthenticationToken(value.token)
            .done((frontendAuthenticationToken) => {
                const authObject = {
                    token: frontendAuthenticationToken
                };

                cookieStorage.saveValueForKey(KEY, authObject);
            });
    };

    const deleteValue = () => {
        cookieStorage.deleteValueForKey(KEY);
    };

    return {
        saveValue : saveValue,
        deleteValue : deleteValue,
        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});
