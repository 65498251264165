define('presentation/signup/viewModels/signupPhoneNumberPortBillingViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'constants/countryEnumerations',
    'presentation/signup/facades/signupPhoneNumberPortFacade',
    'presentation/signup/validators/signupPhoneNumberPortBillingValidator',
], function() {

    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _browserType = require('businessServices/browserSupport/browserType');
        const _countryEnumerations = require('constants/countryEnumerations');
        const CANADA = _countryEnumerations.ca.toUpperCase();
        const _i18n = require('i18next');

        let _disposables = [];
        let _facade = null;
        let _validator = null;

        const DEFAULT_COUNTRY_CODE = "US";

        self.countries = null;
        self.regions = null;

        self.billingInfoAddress = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoApt = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoCity = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoCountry = ko.observable([]);
        self.billingInfoName = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoNumber = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoState = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoZip = ko.observable("").extend({ rateLimit: 500 });
        self.countryAbbreviation = ko.observable("us");
        self.pinOrPasscode = ko.observable("").extend({ rateLimit: 500 });
        self.portAccountNumber = ko.observable("").extend({ rateLimit: 500 });

        self.loadingPlaceholder = _i18n.t('signupPhoneNumberPortBilling:placeholderLoading');
        self.modalMatchStatement = _i18n.t('signupPhoneNumberPortBilling:modalMatchStatement1') + '<br/>' + '<u>' +  _i18n.t('signupPhoneNumberPortBilling:modalMatchStatementUnderlined') + '</u>' + _i18n.t('signupPhoneNumberPortBilling:modalMatchStatement2');
        self.pinOrPasscodeTooltip = _i18n.t('signupPhoneNumberPortBilling:tooltipPinOrPasscodeLine1') + '<br/>' +  _i18n.t('signupPhoneNumberPortBilling:tooltipPinOrPasscodeLine2' )+ '<br/>' +  _i18n.t('signupPhoneNumberPortBilling:tooltipPinOrPasscodeLine3');
        self.portAccountNumberTooltip = _i18n.t('signupPhoneNumberPortBilling:tooltipAccountNumberLine1') + '<br/>' +  _i18n.t('signupPhoneNumberPortBilling:tooltipAccountNumberLine2');

        self.isCompositionComplete = ko.observable(false);
        self.isLoadingLocations = ko.observable(true);
        self.isModalActive = ko.observable(false);
        self.isModalVisible = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.isValid = ko.observable(false);
        self.postalZipCodeLength = ko.pureComputed(() => self.billingInfoCountry()[0] === CANADA ? 6 : 5);
        self.postalZipCodeLabel = ko.pureComputed(() => self.billingInfoCountry()[0] === CANADA ? 'labelPostalCode' : 'labelZipCode');
        self.regionType = ko.pureComputed(() => self.billingInfoCountry()[0] === CANADA ? 'labelProvince' : 'labelState');

        self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);

        self.numbersLabel = ko.computed(() => {
            if (self.isMobile){
                return _i18n.t('signupPhoneNumberPortBilling:tabTitleNumbers');
            } else {
                return _i18n.t('signupPhoneNumberPortBilling:tabTitlePort');
            }
        });

        self.isNavigateToNextPageActive = ko.computed(() => {
            return (self.billingInfoName().length > 0 &&
                self.billingInfoNumber().length > 9 &&
                self.billingInfoZip().length > 4 &&
                self.billingInfoAddress().length > 0 &&
                self.billingInfoCity().length > 0);
        });

        const _onBillingInfoAddressChange = () => {
            if (self.billingInfoAddress().length > 0) {
                _facade.saveNumberPortBillingAddressLine1(self.billingInfoAddress());
            }
        };

        const _onBillingInfoAptChange = () => {
            _facade.saveNumberPortBillingAddressLine2(self.billingInfoApt());
        };

        const _onBillingInfoCityChange = () => {
            if (self.billingInfoCity().length > 0) {
                _facade.saveNumberPortBillingCity(self.billingInfoCity());
            }
        };

        const _onBillingInfoCountryChange = () => {
            if (self.billingInfoCountry()[0]) {
                _facade.saveNumberPortBillingCountry(self.billingInfoCountry()[0]);
            }
        };

        const _onBillingInfoNameChange = () => {
            if (self.billingInfoName().length > 0) {
                _facade.saveNumberPortBillingName(self.billingInfoName());
            }
        };

        const _onBillingInfoNumberChange = () => {
            if (self.billingInfoNumber().length > 0) {
                _facade.saveNumberPortBillingPhoneNumber(self.billingInfoNumber());
            }
        };

        const _onBillingInfoStateChange = () => {
            if (self.billingInfoState()[0]){
                _facade.saveNumberPortBillingState(self.billingInfoState()[0]);
            }
        };

        const _onBillingInfoZipChange = () => {
            if (self.billingInfoZip().length > 0) {
                _facade.saveNumberPortBillingZipPostalCode(self.billingInfoZip());
            }
        };

        const _onCountryAbbreviationChange = () => {
            _facade.saveNumberPortBillingPhoneNumberCountry(self.countryAbbreviation());
        };

        const _onPinOrPasscodeChange = () => {
            if (self.pinOrPasscode().length > 0) {
                _facade.saveNumberPortBillingPinPasscode(self.pinOrPasscode());
            }
        };

        const _onPortAccountNumberChange = () => {
            if (self.portAccountNumber().length > 0){
                _facade.saveNumberPortBillingAccountNumber(self.portAccountNumber());
            }
        };

        const _populateCountriesDropdown = (countryCode) => {
            if (self.countries) {
                const initialCountry = self.countries.find(country => {
                    return country.code === countryCode;
                });
                self.billingInfoCountry([initialCountry.code]);
            }
        };

        const _populateRegionsDropdown = (region = null, useRegion = false) => {
            if (self.billingInfoCountry()[0]) {
                const countryCode = self.billingInfoCountry()[0];
                const regionsForCountry = self.countries.find(country => {
                    return country.code === countryCode;
                }).regions;
                self.regions = regionsForCountry.map((region) => {
                    region.id = region.code;
                    region.text = region.name;
                    return region;
                });
                self.billingInfoState(useRegion ? [region] : [regionsForCountry[0].code]);
            }
        };

        const _setInitialStates = (countryCode = DEFAULT_COUNTRY_CODE, regionCode = null) => {
            _populateCountriesDropdown(countryCode);
            _populateRegionsDropdown(regionCode, true);
            self.isLoadingLocations(false);
            _onBillingInfoCountryChange();
            _onCountryAbbreviationChange();
            _disposables.push(self.billingInfoCountry.subscribe(_populateRegionsDropdown));
            _subscribeToObservables();
        };

        const _subscribeToObservables = () => {
            _disposables.push(self.billingInfoAddress.isValid.subscribe(_onBillingInfoAddressChange));
            _disposables.push(self.billingInfoApt.subscribe(_onBillingInfoAptChange));
            _disposables.push(self.billingInfoCity.isValid.subscribe(_onBillingInfoCityChange));
            _disposables.push(self.billingInfoCountry.subscribe(_onBillingInfoCountryChange));
            _disposables.push(self.billingInfoName.isValid.subscribe(_onBillingInfoNameChange));
            _disposables.push(self.billingInfoNumber.isValid.subscribe(_onBillingInfoNumberChange));
            _disposables.push(self.billingInfoState.subscribe(_onBillingInfoStateChange));
            _disposables.push(self.billingInfoZip.isValid.subscribe(_onBillingInfoZipChange));
            _disposables.push(self.countryAbbreviation.subscribe(_onCountryAbbreviationChange));
            _disposables.push(self.portAccountNumber.subscribe(_onPortAccountNumberChange));
            _disposables.push(self.pinOrPasscode.subscribe(_onPinOrPasscodeChange));
        };

        self.closeBillingInfoModal = () => {
            self.isModalVisible(false);
            _facade.setNumberPortBillingModalCompleted();
            setTimeout(() => {
                self.isModalActive(false);
            }, 250);
        };

        self.showBillingInfoModal = () => {
            self.isModalActive(true);
            setTimeout(() => {
                self.isModalVisible(true);
            }, 100);
        };

        self.onForwardArrowClicked = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        self.detached = () => {
            _disposables.forEach(subscription => subscription.dispose);
            _disposables = [];
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/signup/facades/signupPhoneNumberPortFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/signup/validators/signupPhoneNumberPortBillingValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.deferIndefinitely((promise) => {
                _facade.getCountriesAndRegions()
                    .done((countriesResult) => {
                        self.countries = countriesResult.map((country) => {
                             country.id = country.code;
                             country.text = country.name;
                             return country;
                        });
                        _facade.loadSignupSession()
                            .done((signupSession) => {

                                if (!signupSession.isNumberPortBillingModalCompleted){
                                    self.showBillingInfoModal();
                                }
                                if (signupSession.billingInfoAddress){
                                    self.billingInfoAddress(signupSession.billingInfoAddress);
                                }
                                if (signupSession.billingInfoApt){
                                    self.billingInfoApt(signupSession.billingInfoApt);
                                }
                                if (signupSession.billingInfoCity){
                                    self.billingInfoCity(signupSession.billingInfoCity);
                                }
                                if (signupSession.billingInfoName){
                                    self.billingInfoName(signupSession.billingInfoName);
                                }
                                if (signupSession.billingInfoNumber){
                                    self.billingInfoNumber(signupSession.billingInfoNumber);
                                }
                                if (signupSession.billingInfoNumberCountryAbbreviation){
                                    self.countryAbbreviation(signupSession.billingInfoNumberCountryAbbreviation);
                                }
                                if (signupSession.billingInfoZip){
                                    self.billingInfoZip(signupSession.billingInfoZip);
                                }
                                if (signupSession.pinOrPasscode){
                                    self.pinOrPasscode(signupSession.pinOrPasscode);
                                }
                                if (signupSession.portAccountNumber){
                                    self.portAccountNumber(signupSession.portAccountNumber);
                                }

                                _validator.registerViewModel(self, _facade);

                                if (signupSession.billingInfoCountry && signupSession.billingInfoState) {
                                    _setInitialStates(signupSession.billingInfoCountry, signupSession.billingInfoState);
                                } else {
                                    _setInitialStates();
                                }
                            });

                        promise.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
