define('presentation/analytics/facades/analyticsFacade',[
        'externalDependencies/clientWebSocket'
    ],
    function (
        /** @type typeof import('externalDependencies/clientWebSocket') */
        ClientWebSocket
    ) {
        /** @typedef {import('presentation/analytics/facades/analyticsFacade')} AnalyticsFacade */
        /** @typedef {import('common/promises/promiseFactory')} PromiseFactory   */

        /** @type {PromiseFactory} */
        let _promiseFactory = null;

        const _analyticsSocket = ClientWebSocket.forApplication("analytics");

        const _init = function (/** @type {PromiseFactory} */promiseFactory) {
            _promiseFactory = promiseFactory;
        };

        /**
         * @param {IIdType[]} filterOnHostedNumbers
         * @param {IIdType[]} filterOnSystemObjects
         * @returns {{ hostedNumbers: IHostedNumber[], userGroups: IUserAndUserGroup[] }}
         */
        const _unwrap = (filterOnHostedNumbers, filterOnSystemObjects) => {
            const hostedNumbers = filterOnHostedNumbers.map(function(item) {
                const unwrapped = ko.unwrap(item);
                return {id: unwrapped.id};
            });

            const userGroups = filterOnSystemObjects.map(function(item) {
                const unwrapped = ko.unwrap(item);
                return {id: unwrapped.id, type: unwrapped.type};
            });

            return {
                hostedNumbers,
                userGroups
            };
        };

        /** @type {AnalyticsFacade['getCallsCsv']} */
        const _getCallsCsv = (startMoment, endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects) => {
            const { hostedNumbers, userGroups } = _unwrap(filterOnHostedNumbers, filterOnSystemObjects);

            return _promiseFactory.deferIndefinitely(function(promise) {
                /** @type {IAnalyticsGetRequest} */
                const requestData = {
                    callType: callType,
                    endDate: endMoment.format(),
                    hostedNumbers: hostedNumbers,
                    startDate: startMoment.format(),
                    usersAndUserGroups: userGroups,
                };

                _analyticsSocket.send(`getCallsCsv`, requestData, (results, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    promise.resolve(results);
                });
            });
        };

         /** @type {AnalyticsFacade['getStatsCsv']} */
        const _getStatsCsv = (startMoment, endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects, filter, searchByFilter) => {
            const { hostedNumbers, userGroups } = _unwrap(filterOnHostedNumbers, filterOnSystemObjects);

            return _promiseFactory.deferIndefinitely(function(promise) {
                /** @type {IGetStatsCsvRequest} */
                const requestData = {
                    callType: callType,
                    endDate: endMoment.format(),
                    hostedNumbers: hostedNumbers,
                    startDate: startMoment.format(),
                    usersAndUserGroups: userGroups,
                    filter: filter,
                    searchByFilter: searchByFilter
                };

                _analyticsSocket.send(`getStatsCsv`, requestData, (results, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    promise.resolve(results);
                });
            });
        };

         /** @type {AnalyticsFacade['getDataForCallChart']} */
        const _getDataForCallChart = function(
            startMoment,
            endMoment,
            callType,
            filterOnHostedNumbers,
            filterOnSystemObjects) {
            const { hostedNumbers, userGroups } = _unwrap(filterOnHostedNumbers, filterOnSystemObjects);

            return _promiseFactory.deferIndefinitely(function(promise) {
                /** @type {IAnalyticsGetRequest} */
                const requestData = {
                    callType: callType,
                    endDate: endMoment.format(),
                    hostedNumbers: hostedNumbers,
                    startDate: startMoment.format(),
                    usersAndUserGroups: userGroups,
                };

                _analyticsSocket.send(`getInitial`, requestData, (results, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    promise.resolve(results);
                });
            });
        };

        /** @type {AnalyticsFacade['getCallRecords']} */
        const _getCallRecords = function(startMoment, endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects) {
            const { hostedNumbers, userGroups } = _unwrap(filterOnHostedNumbers, filterOnSystemObjects);

            return _promiseFactory.deferIndefinitely(function(promise) {
                /** @type {IAnalyticsGetRequest} */
                const requestData = {
                    callType: callType,
                    endDate: endMoment.format(),
                    hostedNumbers: hostedNumbers,
                    startDate: startMoment.format(),
                    usersAndUserGroups: userGroups,
                };

                _analyticsSocket.send("getCallRecords", requestData, (results, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    promise.resolve(results);
                });
            });
        };

        /** @type {AnalyticsFacade['getTimeZoneForUser']} */
        const _getTimeZoneForUser = function() {
            return _promiseFactory.defer(function(promise) {
                _analyticsSocket.send("getTimeZone", {}, (timeZone, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    promise.resolve(timeZone);
                });
            });
        };

        return function () {
            const self = this;

            self.init = _init;
            self.getCallRecords = _getCallRecords;
            self.getDataForCallChart = _getDataForCallChart;
            self.getTimeZoneForUser = _getTimeZoneForUser;
            self.getCallsCsv = _getCallsCsv;
            self.getStatsCsv = _getStatsCsv;
        };
    }
);

