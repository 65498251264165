define('presentation/common/smsAttachmentLoadingOverlay/viewModels/smsAttachmentLoadingOverlayViewModel',[], function () {
    /** @typedef {import('presentation/common/smsAttachmentLoadingOverlay/viewModels/smsAttachmentLoadingOverlayViewModel')} SmsAttachmentLoadingOverlay */

    return function () {
        /** @type {SmsAttachmentLoadingOverlay} */
        const self = this;

        self.loadingBarProgressValue = ko.observable(0);

        /** @type {SmsAttachmentLoadingOverlay["activate"]} */
        self.activate = (activationData) => {
            const { loadingBarProgressValue } = activationData;

            self.loadingBarProgressValue = loadingBarProgressValue;

            _initialize();
        };

        const _initialize = () => {

        };
    };
});
