define('presentation/common/croppableImage/validators/uploadImageModalValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'constants/validationMessageEnumerations',
        'common/specifications/validAvatarSpecification'],
function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        var ValidAvatarSpecificationConstructor = require('common/specifications/validAvatarSpecification');
        var _validAvatarSpecification = new ValidAvatarSpecificationConstructor();

        var _viewModel = null;
        var _commonValidator = null;

        self.registerViewModel = function(viewModel) {
            _viewModel = viewModel;

            var validationMessageBase = _validationMessageEnumerations.commonPresentation.croppableImage;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("imageFile")
                                .addValidation(_validAvatarSpecification.isAvatarTypeValid, validationMessageBase.avatarTypeInvalid);

            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };
     };
});

