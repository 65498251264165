define('presentation/common/snackbar/viewModels/snackbarViewModel',[

], function() {
    return function() {
        const self = this;

        let _settings = null;

        self.isActionSnackbar = ko.observable(false);
        self.isWarningSnackbar = ko.observable(false);

        self.activate = (settings) => {
            _settings = settings;
            return _initialize();
        };

        const _initialize = () => {
            if (_settings.hasOwnProperty('isActionSnackbar')) {
                self.isActionSnackbar(ko.unwrap(_settings.isActionSnackbar));
            }

            if (_settings.hasOwnProperty('isWarningSnackbar')) {
                self.isWarningSnackbar(ko.unwrap(_settings.isWarningSnackbar));
            }
        };
    };
});
