define('presentation/signup/viewModels/signupEmailViewModel',[
    'businessServices/browserSupport/browserType',
    'common/converters/phoneNumberFormatter',
    'common/url/urlFormatter',
    'constants/signupSessionFlowConstants',
    'constants/signupEmailVerificationConstants',
    'presentation/signup/common/signupNavigationManager',
    'presentation/signup/facades/signupEmailFacade',
    'presentation/signup/validators/signupEmailValidator',
    'presentation/signup/validators/signupEmailVerificationValidator',
    'settings/navigationConfiguration'
], function() {

    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _browserType = require('businessServices/browserSupport/browserType');
        const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
        const _signupEmailVerificationConstants = require('constants/signupEmailVerificationConstants');

        const PhoneNumberFormatter = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatter();

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _urlFormatter = require('common/url/urlFormatter');

        const SUCCESSFULLY_RESERVED_STATUS = "reserved";

        let _facade = null;
        let _validator = null;
        let _verificationValidator = null;
        let _signupNavigationManager = null;
        let _disposables = [];

        let _savedUserEmail = null;
        let _isEmailStepComplete = false;
        let _verificationCodeSendCount = 0;
        let _isFraudulentDevice = false;
        let _isFraudulentEmail = false;

        const _numberPickerConstants = require('constants/numberPickerConstants');

        const _reservePhoneNumber = (phoneNumberObject) => {
            const phoneNumber = _phoneNumberFormatter.toEOneSixFour(phoneNumberObject.phoneNumber);

            self.isModalActive(true);
            self.isModalVisible(true);
            self.isNumberSpinnerVisible(true);
            self.phoneNumberToReserve(phoneNumber);

            const numberType = _phoneNumberFormatter.isTollFree(phoneNumber) ? _numberPickerConstants.numberTypes.tollFree : _numberPickerConstants.numberTypes.local;
            const region = phoneNumberObject.region ? phoneNumberObject.region : null;
            const city = phoneNumberObject.city ? phoneNumberObject.city : null;
            const vanityString = phoneNumberObject.vanityString ? phoneNumberObject.vanityString : null;
            const searchType = _numberPickerConstants.searchTypeEnumeration[phoneNumberObject.searchType] ?
                _numberPickerConstants.searchTypeEnumeration[phoneNumberObject.searchType] :
                null;

            _facade.reservePhoneNumber({phoneNumber, region, city, numberType, searchType, vanityString})
                .fail(_handleReservationFailure)
                .done((response) => {
                    if (response.status !== SUCCESSFULLY_RESERVED_STATUS) {
                        _handleReservationFailure();
                    } else {
                        _facade.setPhoneNumberCompleted(true)
                            .fail(_handleReservationFailure)
                            .done(() => {
                                _handleReservationSuccess(phoneNumber);
                            });
                    }
                });
        };

        const _handleReservationFailure = () => {
            self.isNumberSpinnerVisible(false);
        };

        const _handleReservationSuccess = (phoneNumber) => {
            self.isModalVisible(false);
            self.reservedPhoneNumber = phoneNumber;
            setTimeout(() => {
                self.isModalActive(false);
            }, 2500);
        };

        const _updateIsMaxRetryCountReached = (resendCount, verificationAttempts) => {
            if (
                resendCount >= _signupEmailVerificationConstants.MaxVerificationCodeSendCount ||
                verificationAttempts >= _signupEmailVerificationConstants.MaxVerificationAttempts
            ) {
                self.isMaxRetryCountReached(true);
            } else {
                self.isMaxRetryCountReached(false);
            }
        };

        const _handleEmailSubmit = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            deferredObject.resolve(false);
                            return;
                        }

                        const userEmail = self.userEmail();

                        _facade.saveEmailAddress(userEmail)
                            .fail()
                            .done(() => {
                                _savedUserEmail = userEmail;
                                _isEmailStepComplete = false;

                                _facade.isFraudulentEmailAddress(userEmail)
                                    .done((isFraudlentEmailAddress) => {
                                        if (isFraudlentEmailAddress) {
                                            _isFraudulentEmail = true;
                                            _signupNavigationManager.navigateToErrorPage();
                                            deferredObject.resolve(false);
                                            return;
                                        }

                                        if(_isFraudulentDevice === true) {
                                            _signupNavigationManager.navigateToErrorPage();
                                            deferredObject.resolve(false);
                                            return;
                                        }

                                        _facade.sendEmailVerificationCode()
                                            .fail(deferredObject.reject)
                                            .done((response) => {
                                                self.verificationCodeSendCount(response.emailVerificationCodeSendCount);

                                                _updateIsMaxRetryCountReached(response.emailVerificationCodeSendCount, response.emailVerificationFailureCount);

                                                deferredObject.resolve(false);
                                                _navigateToVerificationStep();
                                            });
                                    });
                            });
                    });
            });
        };

        const _handleVerificationCodeSubmit = () => {
            return _promiseFactory.defer((deferredObject) => {
                _verificationValidator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            deferredObject.resolve(false);
                            return;
                        }

                        const userEmail = self.userEmail();
                        const verificationCode = self.emailVerificationCode();

                        _facade.setEmailStepCompleted(userEmail, verificationCode)
                            .done((result) => {
                                self.verificationCodeSendCount(result.emailVerificationCodeSendCount);
                                self.verificationFailureCount(result.emailVerificationFailureCount);

                                _updateIsMaxRetryCountReached(result.emailVerificationCodeSendCount, result.emailVerificationFailureCount);

                                if (!result.isEmailCompleted) {
                                    if (result.emailVerificationFailureCount >= _signupEmailVerificationConstants.MaxVerificationAttempts) {
                                        self.showIncorrectVerificationCodeError(false);
                                    } else {
                                        self.showIncorrectVerificationCodeError(true);
                                    }

                                    _verificationValidator.validate();
                                    deferredObject.resolve(false);
                                    return;
                                }

                                self.showIncorrectVerificationCodeError(false);
                                deferredObject.resolve(true);
                            });
                    });
            });
        };

        const _sendVerificationCode = () => {
            if (self.showResendEmailSpinner() || self.isMaxRetryCountReached()) {
                return;
            }

            self.showResendEmailSpinner(true);
            _facade.sendEmailVerificationCode()
                .fail(() => {
                    self.showResendEmailSpinner(false);
                    self.isMaxRetryCountReached(true);
                })
                .done((response) => {
                    self.showResendEmailSpinner(false);

                    self.verificationCodeSendCount(response.emailVerificationCodeSendCount);
                    self.verificationFailureCount(response.emailVerificationFailureCount);

                    _updateIsMaxRetryCountReached(response.emailVerificationCodeSendCount, response.emailVerificationFailureCount);
                });
        };

        const _navigateToVerificationStep = () => {
            self.isOnEmailStep(false);
            self.isOnEmailVerificationStep(true);
        };

        const _navigateToEmailStep = () => {
            self.isOnEmailVerificationStep(false);
            self.isOnEmailStep(true);
        };

        self.flowId = ko.observable();
        self.userEmail = ko.observable('');
        self.emailVerificationCode = ko.observable('');
        self.isEmailArrowValid = ko.observable(false);
        self.isVerificationStepValid = ko.observable(false);
        self.isCompositionComplete = ko.observable(false);
        self.isNavigateToNextPageActive = ko.observable(true);
        self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
        self.isMobileBackgroundRed = ko.computed(() => {
            return self.flowId() === _signupSessionFlowConstants.flowA && self.isMobile();
        });
        self.isModalActive = ko.observable(false);
        self.isModalVisible = ko.observable(false);
        self.isNumberSpinnerVisible = ko.observable(false);
        self.phoneNumberToReserve = ko.observable("");
        self.modalPhoneNumber = ko.pureComputed(() => {
            return _phoneNumberFormatter.toUSAreaCode(self.phoneNumberToReserve());
        });
        self.modalHeaderKey = ko.pureComputed(() => {
            return self.isNumberSpinnerVisible() ? "reservationInProgressHeader" : "reservationFailedHeader";
        });
        self.modalMessageKey = ko.pureComputed(() => {
            return self.isNumberSpinnerVisible() ? "reservationInProgressMessage" : "reservationFailedMessage";
        });
        self.reservedPhoneNumber = "";
        self.homeUrl = _urlFormatter.buildFrontendUrl("/");

        self.isOnEmailStep = ko.observable(true);
        self.isOnEmailVerificationStep = ko.observable(false);
        self.isMaxRetryCountReached = ko.observable(false);

        self.showEmailInput = ko.observable(true);
        self.showVerificationInput = ko.observable(false);

        self.showIncorrectVerificationCodeError = ko.observable(false);
        self.verificationCodeSendCount = ko.observable(0);
        self.verificationFailureCount = ko.observable(0);

        self.showResendEmailSpinner = ko.observable(false);

        self.showNavigateToPreviousPage = ko.pureComputed(() => {
            return self.isOnEmailVerificationStep();
        });

        self.hasReachedMaxFailures = ko.pureComputed(() => {
            return self.verificationFailureCount() >= _signupEmailVerificationConstants.MaxVerificationAttempts;
        });

        self.isVerificationArrowValid = ko.pureComputed(() => {
            return self.isVerificationStepValid() && !self.hasReachedMaxFailures();
        });

        self.isArrowValid = ko.pureComputed(() => {
            if (self.isOnEmailStep()) {
                return self.isEmailArrowValid();
            }

            return self.isVerificationArrowValid();
        });

        self.navigateToFlowB = () => {
            const isTollFree = _phoneNumberFormatter.isTollFree(self.phoneNumberToReserve());
            if (isTollFree) {
                _signupNavigationManager.navigateToRoute(_navigationConfiguration.routesById.signupPhoneNumberTollfree.routeId, _signupSessionFlowConstants.flowB);
            } else {
                _signupNavigationManager.navigateToRoute(_navigationConfiguration.routesById.signupPhoneNumberLocal.routeId, _signupSessionFlowConstants.flowB);
            }
        };

        self.onForwardArrowClicked = () => {
            if (!self.isOnEmailStep()) {
                // if on validation page
                return _handleVerificationCodeSubmit();
            }

            return _handleEmailSubmit();
        };

        self.onBackArrowClicked = () => {
            _navigateToEmailStep();
        };

        self.onResendEmailClicked = () => {
            _sendVerificationCode();
        };

        self.validate = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .done((isValid) => {
                        const accountInfo = {
                             emailAddress: self.userEmail()
                        };

                        deferredObject.resolve({isValid: isValid, accountInfo: accountInfo});
                    })
                    .fail(deferredObject.reject);
            });
        };

        self.detached = () => {
            _signupNavigationManager.detached();
            _disposeSubscriptions();
        };

        const _disposeSubscriptions = () => {
            _disposables.forEach(subscription => subscription.dispose());
            _disposables = [];
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

		self.activate = (flow = null, phoneNumberToReserve = null) => {
            const FacadeConstructor = require('presentation/signup/facades/signupEmailFacade');
            _facade = new FacadeConstructor();
            _facade.init();

            const ValidatorConstructor = require('presentation/signup/validators/signupEmailValidator');
            _validator = new ValidatorConstructor();

            const VerificationValidator = require('presentation/signup/validators/signupEmailVerificationValidator');
            _verificationValidator = new VerificationValidator();

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            self.flowId(_signupNavigationManager.getFlowId());

            return _initialize(phoneNumberToReserve);
        };

        const _initialize = (phoneNumberToReserve) => {
            _promiseFactory.deferIndefinitely(promise => _facade.loadSignupSession()
                .fail(promise.reject)
                .done(signupEmailPresentationObject => {
                    self.reservedPhoneNumber = signupEmailPresentationObject.reservedPhoneNumber;
                    if (signupEmailPresentationObject.emailAddress) {
                        self.isEmailArrowValid(true);
                        self.userEmail(signupEmailPresentationObject.emailAddress);
                        _savedUserEmail = signupEmailPresentationObject.emailAddress;
                        _isEmailStepComplete = signupEmailPresentationObject.isEmailCompleted;
                        _verificationCodeSendCount = signupEmailPresentationObject.emailVerificationCodeSendCount;

                        self.verificationCodeSendCount(signupEmailPresentationObject.emailVerificationCodeSendCount);
                        self.verificationFailureCount(signupEmailPresentationObject.emailVerificationFailureCount);

                        if (_verificationCodeSendCount > 0 && !_isEmailStepComplete) {
                            self.isOnEmailStep(false);
                            self.isOnEmailVerificationStep(true);
                        }

                        if (
                            _verificationCodeSendCount >= _signupEmailVerificationConstants.MaxVerificationCodeSendCount ||
                            signupEmailPresentationObject.emailVerificationFailureCount >= _signupEmailVerificationConstants.MaxVerificationAttempts
                        ) {
                            self.isMaxRetryCountReached(true);
                        }
                    }
                    promise.resolve();
                })
            );

            _promiseFactory.defer((saveDeviceFingerPrintPromise) => {
                _facade.saveDeviceFingerPrint()
                    .fail(saveDeviceFingerPrintPromise.resolve)
                    .done(saveDeviceFingerPrintPromise.resolve);
            });

            _promiseFactory.defer((saveResellerInfoPromise) => {
                _facade.saveResellerInfo()
                    .fail(saveResellerInfoPromise.resolve)
                    .done(saveResellerInfoPromise.resolve);
            });

            _promiseFactory.defer((isFraudulentDevicePromise) => {
                _facade.isFraudulentDevice()
                    .fail(isFraudulentDevicePromise.resolve)
                    .done((isFraudulentDevice) => {
                        _isFraudulentDevice = isFraudulentDevice;
                        isFraudulentDevicePromise.resolve();
                    });
            });

            _validator.registerViewModel(self, _facade);
            _verificationValidator.registerViewModel(self);

            _disposables.push(self.userEmail.isValid.subscribe(self.isEmailArrowValid));
            _disposables.push(self.emailVerificationCode.isValid.subscribe(self.isVerificationStepValid));
            _disposables.push(self.emailVerificationCode.subscribe(() => {
                self.showIncorrectVerificationCodeError(false);
            }));

            if (phoneNumberToReserve !== null &&
                phoneNumberToReserve.phoneNumber !== undefined &&
                self.flowId() === _signupSessionFlowConstants.flowC &&
                !self.reservedPhoneNumber
            ) {
                _reservePhoneNumber(phoneNumberToReserve);
            }

            return _promiseFactory.wait();
        };
    };
});

