define('presentation/settings/phoneNumbers/viewModels/messagingFeeConfirmationModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/settings/phoneNumbers/validators/messagingFeeConfirmationViewModelValidator'
], function () {
    return function (isSoleProprietor, isOnlyEnabledLocalNumber) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        let _validator = null;

        self.customerAgreesToFee = ko.observable(false);
        self.showValidation = ko.observable(false);

        self.messagingSetupFee = isSoleProprietor() ? "20" : "69";

        self.acknowledgementMessagePart1 = ko.pureComputed(() => {
            if (isOnlyEnabledLocalNumber()) {
                return _i18n.t("messagingFeeConfirmationModal:disableSmsMessagePart1");
            }
            return _i18n.t("messagingFeeConfirmationModal:enableSmsMessagePart1", { amount: self.messagingSetupFee });
        });
        self.acknowledgementMessagePart2 = ko.pureComputed(() => {
            if (isOnlyEnabledLocalNumber()) {
                return _i18n.t("messagingFeeConfirmationModal:disableSmsMessagePart2");
            }
            return _i18n.t("messagingFeeConfirmationModal:enableSmsMessagePart2", { amount: self.messagingSetupFee });
        });

        self.customerAgreesToFeeAcknowledgement = ko.pureComputed(() => {
            if (isOnlyEnabledLocalNumber()) {
                return _i18n.t("messagingFeeConfirmationModal:disableSmsMessageAcknowledgement");
            }
            return _i18n.t("messagingFeeConfirmationModal:enableSmsMessageAcknowledgement", { amount: self.messagingSetupFee });
        });

        self.customerAgreesToFeeIsValid = ko.pureComputed(() => {
            if (self.showValidation()) {
                return self.customerAgreesToFee();
            }
            return true;
        });

        self.submitAction = () => {
            self.showValidation(false);
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            self.showValidation(true);
                            deferredObject.resolve({isValid});
                        } else {
                            deferredObject.resolve({isValid});
                        }
                    });
            });
        };


        self.activate = () => {
            const ValidatorConstructor = require('presentation/settings/phoneNumbers/validators/messagingFeeConfirmationViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {

            _validator.registerViewModel(self);
            return _promiseFactory.wait();
        };
    };
});
