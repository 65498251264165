define('persistence/repositories/contactsRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], function (
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('externalDependencies/clientWebSocket') */
    ClientWebSocket
) {
    const APPLICATION_NAME = `contact`;

    return function() {
        /** @type { IContactsRepository } */
        const self = this;

        const _promiseFactory = new PromiseFactory();

        const _webContactsSocket = ClientWebSocket.forApplication(APPLICATION_NAME);

        /**
         * @template T
         * @param { string } eventName
         * @param { (event: T) => void } callBack
         */
        const _subscribe = (eventName, callBack) => {
            _webContactsSocket.subscribeToEvent(eventName, callBack);
        };

        /**
         * @template TOut
         * @param { string } command
         * @param { { [key: string]: any } } parameters
         * @returns { JQuery.Deferred<TOut?> }
         */
        const _send = (command, parameters) => {
            return _promiseFactory.deferIndefinitely((/** @type { JQuery.Deferred<TOut?> } */deferred) => {
                _webContactsSocket.send(command, parameters || {}, (/** @type { TOut? } */ response, /** @type { any } */ errMsg) => {
                    if (errMsg) {
                        deferred.reject(new Error(errMsg));
                        return;
                    }

                    deferred.resolve(response);
                });
            });
        };

        /** @type { IContactsRepository["getContacts"] } */
        self.getContacts = () => {
            return _send('getContacts', {});
        };

        /** @type { IContactsRepository["getContactPermissions"] } */
        self.getContactPermissions = () => {
            return _send('getContactPermissions', {});
        };

        /** @type { IContactsRepository["getContactById"] } */
        self.getContactById = (accountContactId) => {
            return _send('getContact', { accountContactId });
        };

        /** @type { IContactsRepository["getContactHostedPhoneNumbers"] } */
        self.getContactHostedPhoneNumbers = (subscriberId, subscriberType) => {
            return _send('getContactHostedPhoneNumbers', {subscriberId, subscriberType});
        };

        /** @type { IContactsRepository["createContact"] } */
        self.createContact = (createRequest) => {
            return _send('addContact', createRequest);
        };

        /** @type { IContactsRepository["updateContact"] } */
        self.updateContact = (updateRequest) => {
            return _send('updateContact', updateRequest);
        };

        /** @type { IContactsRepository["updateContactPermissions"] } */
        self.updateContactPermissions = (request) => {
            return _send('updateContactPermissions', request);
        };

        /** @type { IContactsRepository["deleteContact"] } */
        self.deleteContact = (deleteRequest) => {
            return _send('deleteContact', deleteRequest);
        };

        //#endregion

        //#region Subscriptions

        /** @type { IContactsRepository["onContactCreated"] } */
        self.onContactCreated = (callBack) => {
            _subscribe(`contactAdded`, callBack);
        };

        /** @type { IContactsRepository["onContactUpdated"] } */
        self.onContactUpdated = (callBack) => {
            _subscribe(`contactUpdated`, callBack);
        };

        /** @type { IContactsRepository["onContactDeleted"] } */
        self.onContactDeleted = (callBack) => {
            _subscribe(`contactDeleted`, callBack);
        };

        /** @type { IContactsRepository["onContactAvatarUpdated"] } */
        self.onContactAvatarUpdated = (callBack) => {
            _subscribe(`contactAvatarUpdated`, callBack);
        };

        /** @type { IContactsRepository["onContactPermissionAdded"] } */
        self.onContactPermissionAdded = (callback) => {
            _subscribe('contactPermissionAdded', callback);
        };

        /** @type { IContactsRepository["onContactPermissionRemoved"] } */
        self.onContactPermissionRemoved = (callback) => {
            _subscribe('contactPermissionRemoved', callback);
        };

        //#endregion

        /** @type { IContactsRepository["dispose"] } */
        self.dispose = () => {
            _webContactsSocket.disposeOfEvents();
        };
    };
});
