define('presentation/common/routeToModal/strategies/connectorStrategy',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp',
    'presentation/common/routeToModal/common/routingRuleBuilder'
], function() {

    return function() {
        
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const RoutingRuleBuilderConstructor = require('presentation/common/routeToModal/common/routingRuleBuilder');

        const _promiseFactory = new PromiseFactoryConstructor();
        const _routingRuleBuilder = new RoutingRuleBuilderConstructor();
        let _webSocketApp = null;

        const _save = (routeToViewModel, permissionObject) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = Object.assign(permissionObject, {
                    phoneConnectorId: self.phoneConnectorId,
                    routingRule: _routingRuleBuilder.buildRoutingRule(routeToViewModel),
                });
                _webSocketApp.send("assign_routing_rules", parameters, (phoneConnectorDocument) => {
                    deferredObject.resolve(phoneConnectorDocument);
                });
            });
        };

        const _update = (routeToViewModel, permissionObject) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = Object.assign(permissionObject, {
                    phoneConnectorId: self.phoneConnectorId,
                    routingRule: _routingRuleBuilder.buildRoutingRule(routeToViewModel),
                });
                _webSocketApp.send("edit_routing_rules", parameters, (phoneConnectorDocument) => {
                    deferredObject.resolve(phoneConnectorDocument);
                });
            });
        };

        const _unassign = () => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("unassign_route_to_options", {phoneConnectorId: self.phoneConnectorId}, (result) => {
                    deferredObject.resolve(result.modifiedDateTime);
                });
            });
        };

        const _init = (phoneConnectorId) => {
            const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketActivityAppConstructor();
            _webSocketApp.init("phone_numbers_routing");

            self.phoneConnectorId = phoneConnectorId;
        };

        const self = this;

        self.init = _init;
        self.phoneConnectorId = null;
        self.presentationObject = null;
        self.save = _save;
        self.update = _update;
        self.unassign = _unassign;
    };
});
