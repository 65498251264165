define('presentation/loginNotifications/viewModels/reactivationViewModel',[
    'businessServices/login/accountStatusEvaluator',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/creditCardResponseConstants',
    'constants/accountStatusEnumerations',
    'presentation/common/modal',
    'presentation/loginNotifications/viewModels/addPaymentMethodModalViewModel',
    'presentation/loginNotifications/facades/reactivationFacade',
    'presentation/loginNotifications/validators/reactivationValidator',
    'businessServices/converters/currencyFormatter',
    'presentation/common/window/windowControl'
], function () {
    return function (reactivationCompletedPromise) {
        const self = this;

        const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
        const _currencyFormatter = new CurrencyFormatterConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _accountStatusEvaluator = require('businessServices/login/accountStatusEvaluator');
        const _accountStatusEnumeration = require('constants/accountStatusEnumerations');

        let _addPaymentMethodViewModel = null;
        let _sessionWorkflowFlags = null;
        let _availablePaymentMethods = null;
        const _creditCardResponse = require('constants/creditCardResponseConstants');
        let _facade = null;
        const _promiseFactory = new PromiseFactoryConstructor();
        let _validator = null;
        let _reactivationCompletedPromise = reactivationCompletedPromise;

        const _i18n = require('i18next');
        const _windowControl = require('presentation/common/window/windowControl');
        const _urlFormatter = require('common/url/urlFormatter');

        const NEW_PAYMENT_METHOD =  {
            paymentMethodId: "new",
            description: _i18n.t("reactivation:newCard")
        };

        self.addPaymentMethodViewModel = ko.observable();
        self.isBalancePaid = ko.observable(false);
        self.customerAgreesToPay = ko.observable(false);
        self.customerAgreesToTermsOfUse = ko.observable(false);
        self.paymentMethodDropDownOptions = ko.observableArray([]);
        self.selectedPaymentMethod = ko.observable('');
        self.isCreditCardDeclined = ko.observable(true);
        self.balanceDue = ko.observable(null);
        self.hasPastDueBalance = ko.observable(false);
        self.showValidation = ko.observable(false);
        self.showReactivationFailureMessage = ko.observable(false);
        self.paymentFailureTitle = ko.observable('');
        self.paymentFailureMessage = ko.observable('');
        self.termsUrl = _urlFormatter.buildFrontendUrl("/terms");
        self.isAccountAbleToBeActivated = ko.observable(true);
        self.planId = ko.observable(null);
        self.packageId = ko.observable(null);

        self.isNewPaymentMethod = ko.pureComputed(() => {
            return self.selectedPaymentMethod().paymentMethodId === NEW_PAYMENT_METHOD.paymentMethodId;
        });

        self.warningTitle = ko.pureComputed(() => {
            if (self.hasPastDueBalance()) {
                return _i18n.t("reactivation:warningTitlePastDueBalance", {
                    pastDueBalance: _currencyFormatter.formatUSDForDisplay(self.balanceDue())
                });
            }
            return _i18n.t("reactivation:warningTitle");
        });

        self.warningMessage = ko.pureComputed(() => {
            if (self.isAccountAbleToBeActivated()) {
                return _i18n.t("reactivation:warningMessage");
            } else {
                return _i18n.t("reactivation:warningMessageCannotReactivate");
            }
        });

        self.customerAgreesToTermsOfUseLabel = ko.pureComputed(() => {
            let message = _i18n.t('reactivation:customerAgreesToTermsOfUse_part1');
            message += '<a class="check-box-link" target="_blank" href=' + self.termsUrl + '>';
            message += _i18n.t('reactivation:customerAgreesToTermsOfUse_part2') + '</a>';
            return message;
        });

        self.customerAgreesToPayAcknowledgement = ko.pureComputed(() => {
            return _i18n.t("reactivation:customerAgreesToPayAcknowledgement", {
                pastDueBalance: _currencyFormatter.formatUSDForDisplay(self.balanceDue())
            });
        });

        self.customerAgreesToPayIsValid = ko.pureComputed(() => {
            if (self.showValidation() && self.hasPastDueBalance()) {
                return self.customerAgreesToPay();
            }
            return true;
        });

        self.customerAgreesToTermsOfUseIsValid = ko.pureComputed(() => {
            if (self.showValidation() && self.isAccountAbleToBeActivated()) {
                return self.customerAgreesToTermsOfUse();
            }
            return true;
        });

        self.openTermsOfAgreement = () => {
            _windowControl.openTermsOfAgreement();
        };

        const _getAccountInformation = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAccountInformation()
                    .fail(deferredObject.reject)
                    .done((accountInformation) => {
                        self.planId(accountInformation.planId);
                        self.packageId(accountInformation.packageId);

                        const accountFraudStatus = accountInformation.fraudStatus;
                        const accountFlags = accountInformation.flags;

                        if (accountFlags.doNotReactivate) {
                            self.isAccountAbleToBeActivated(false);
                        } else {
                            switch (accountFraudStatus) {
                                case _accountStatusEnumeration.fraudStatus.ok:
                                case _accountStatusEnumeration.fraudStatus.fraudReview:
                                    self.isAccountAbleToBeActivated(true);
                                    break;
                                default:
                                    self.isAccountAbleToBeActivated(false);
                                    break;
                            }
                        }
                        deferredObject.resolve();
                    });
            });
        };

        const _getBalanceInformation = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getBalanceInformation()
                    .fail(deferredObject.reject)
                    .done((balanceDue) => {
                        const hasPastDueBalance = balanceDue > 0;
                        self.hasPastDueBalance(hasPastDueBalance);
                        self.balanceDue(balanceDue);
                        deferredObject.resolve();
                    });
            });
        };

        const _getAllPaymentMethods = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAllPaymentMethods()
                    .fail(deferredObject.reject)
                    .done((paymentMethods) => {
                        _availablePaymentMethods = paymentMethods.filter((paymentMethod) => {
                            return paymentMethod.isExpired() === false;
                        });
                        _buildPaymentMethodsDropDownOptions()
                            .fail(deferredObject.reject)
                            .done((paymentMethodDropDownOptions) => {
                                self.paymentMethodDropDownOptions(paymentMethodDropDownOptions);
                                _setSelectedPaymentMethod();
                                deferredObject.resolve();
                            });
                    });
            });
        };

        const _buildPaymentMethodsDropDownOptions = () => {
            return _promiseFactory.defer((deferredObject) => {
                let paymentMethodDropDownOptions = _availablePaymentMethods.map((paymentMethod) => {
                    return {
                        paymentMethodId: paymentMethod.paymentMethodId,
                        cardHolderName: paymentMethod.cardHolderName,
                        cardType: paymentMethod.cardType,
                        expirationDate: paymentMethod.expirationDate,
                        country: paymentMethod.country,
                        postalCode: paymentMethod.postalCode,
                        description: paymentMethod.description
                    };
                });

                _facade.getAddPaymentMethodAllowed()
                    .fail(deferredObject.reject)
                    .done((addPaymentMethodAllowed) => {
                        if (addPaymentMethodAllowed) {
                            paymentMethodDropDownOptions.push(NEW_PAYMENT_METHOD);
                        }
                        deferredObject.resolve(paymentMethodDropDownOptions);
                    });
            });
        };

        const _setSelectedPaymentMethod = () => {
            let defaultPaymentMethod;
            defaultPaymentMethod = _availablePaymentMethods.find((paymentMethod) => {
                return paymentMethod.isDefault();
            });
            if (defaultPaymentMethod === undefined) {
                self.selectedPaymentMethod(NEW_PAYMENT_METHOD);
            } else {
                self.selectedPaymentMethod(defaultPaymentMethod);
            }
        };

        const _addPaymentMethodAndMakePayment = () => {
            return _promiseFactory.defer((deferredObject) => {
                let paymentMethod = _addPaymentMethodViewModel.paymentMethod();
                _facade.addPaymentMethodAndMakePayment(paymentMethod, self.balanceDue())
                    .fail(deferredObject.reject)
                    .done((addPaymentMethodAndMakePaymentResponse) => {
                        if (_validatePaymentProcessing(addPaymentMethodAndMakePaymentResponse.status)) {
                            let paymentMethodPresentationObject = _facade.buildPaymentMethodPresentationObject(addPaymentMethodAndMakePaymentResponse.paymentMethod);
                            self.selectedPaymentMethod(paymentMethodPresentationObject);
                            deferredObject.resolve(true);
                        } else {
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        const _makePayment = (paymentMethodId) => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.makePayment(paymentMethodId, self.balanceDue().toString())
                    .fail(deferredObject.reject)
                    .done((paymentResults) => {
                        if (_validatePaymentProcessing(paymentResults.status)) {
                            let selectedPaymentMethod = self.selectedPaymentMethod();
                            self.selectedPaymentMethod(selectedPaymentMethod);
                            deferredObject.resolve(true);
                        } else {
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        const _payBalance = () => {
            return _promiseFactory.defer((deferredObject) => {
                let payNowPromiseFactory = new PromiseFactoryConstructor();
                let addPaymentMethodIsValid = false;
                let reactivationIsValid = false;

                if (self.isNewPaymentMethod()) {
                    payNowPromiseFactory.defer((newPaymentDeferred) => {
                        self.addPaymentMethodViewModel().validate()
                            .fail(newPaymentDeferred.reject)
                            .done((isValid) => {
                                addPaymentMethodIsValid = isValid;
                                newPaymentDeferred.resolve();
                            });
                    });
                } else {
                    addPaymentMethodIsValid = true;
                }

                payNowPromiseFactory.defer((validatorDeferred) => {
                    _validator.validate()
                        .fail(validatorDeferred.reject)
                        .done((isValid) => {
                            reactivationIsValid = isValid;
                            validatorDeferred.resolve();
                        });
                });

                payNowPromiseFactory.wait()
                    .fail(deferredObject.reject)
                    .done(() => {
                        if (addPaymentMethodIsValid && reactivationIsValid) {
                            let promise;
                            if (self.isNewPaymentMethod()) {
                                promise = _addPaymentMethodAndMakePayment();
                            } else {
                                promise = _makePayment(self.selectedPaymentMethod().paymentMethodId);
                            }
                            promise
                                .fail(deferredObject.reject)
                                .done((paymentProcessed) => {
                                    if (paymentProcessed) {
                                        deferredObject.resolve(paymentProcessed);
                                    } else {
                                        self.showReactivationFailureMessage(true);
                                        deferredObject.resolve(false);
                                    }
                                });
                        } else {
                            self.showValidation(true);
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        const _reactivateAccount = () => {
            return _promiseFactory.defer((deferredObject) => {
                let reactivationPromiseFactory = new PromiseFactoryConstructor();
                let addPaymentMethodIsValid = false;
                let reactivationIsValid = false;

                if (self.isNewPaymentMethod()) {
                    reactivationPromiseFactory.defer((newPaymentDeferred) => {
                        self.addPaymentMethodViewModel().validate()
                            .fail(newPaymentDeferred.reject)
                            .done((isValid) => {
                                addPaymentMethodIsValid = isValid;
                                newPaymentDeferred.resolve();
                            });
                    });
                } else {
                    addPaymentMethodIsValid = true;
                }

                reactivationPromiseFactory.defer((validatorDeferred) => {
                    _validator.validate()
                        .fail(validatorDeferred.reject)
                        .done((isValid) => {
                            reactivationIsValid = isValid;
                            validatorDeferred.resolve();
                        });
                });

                reactivationPromiseFactory.wait()
                    .fail(deferredObject.reject)
                    .done(() => {
                        if (addPaymentMethodIsValid && reactivationIsValid) {
                            const reactivationData = {
                                planId: self.planId(),
                                packageId: self.packageId(),
                                paymentMethodId: self.isNewPaymentMethod() ? 0 : self.selectedPaymentMethod().paymentMethodId,
                                newPaymentMethod: self.isNewPaymentMethod() ? self.addPaymentMethodViewModel().paymentMethod() : self.selectedPaymentMethod()
                            };
                            _facade.reactivateAccount(reactivationData)
                                .fail(deferredObject.reject)
                                .done(function (reactivationResult) {
                                    if (_validatePaymentProcessing(reactivationResult.status)) {
                                        deferredObject.resolve(true);
                                    } else {
                                        self.showReactivationFailureMessage(true);
                                        deferredObject.resolve(false);
                                    }
                                });
                        } else {
                            self.showValidation(true);
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        self.reactivateAccount = () => {
            self.showValidation(false);
            self.showReactivationFailureMessage(false);
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _reactivateAccount()
                    .fail(deferredObject.reject)
                    .done((reactivationSuccessful) => {
                        if (reactivationSuccessful) {
                            const result = {
                                isBalancePaid: false,
                                isAccountReactivated: true,
                                paymentAmount: _currencyFormatter.formatUSDForDisplay(self.balanceDue())
                            };
                            _accountStatusEvaluator.refresh();
                            _reactivationCompletedPromise.resolve(result);
                            deferredObject.resolve();
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        self.payBalanceAndReactivateAccount = () => {
            self.showValidation(false);
            self.showReactivationFailureMessage(false);
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _payBalance()
                    .fail(deferredObject.reject)
                    .done((paymentProcessed) => {
                        if (paymentProcessed) {
                            _reactivateAccount()
                                .fail(deferredObject.reject)
                                .done(() => {
                                    const result = {
                                        isBalancePaid: true,
                                        isAccountReactivated: true,
                                        paymentAmount: _currencyFormatter.formatUSDForDisplay(self.balanceDue())
                                    };
                                    _accountStatusEvaluator.refresh();
                                    _reactivationCompletedPromise.resolve(result);
                                    deferredObject.resolve();
                                });
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        self.payBalanceAndKeepAccountClosed = () => {
            self.showValidation(false);
            self.showReactivationFailureMessage(false);
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _payBalance()
                    .fail(deferredObject.reject)
                    .done((paymentProcessed) => {
                        if (paymentProcessed) {
                            const result = {
                                isBalancePaid: true,
                                isAccountReactivated: false,
                                paymentAmount: _currencyFormatter.formatUSDForDisplay(self.balanceDue())
                            };
                            _accountStatusEvaluator.refresh();
                            _reactivationCompletedPromise.resolve(result);
                            deferredObject.resolve();
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        const _validatePaymentProcessing = (paymentStatus) => {
            switch (paymentStatus) {
                case _creditCardResponse.success:
                case _creditCardResponse.approved:
                    self.paymentFailureTitle('');
                    self.paymentFailureMessage('');
                    return true;
                case _creditCardResponse.declined:
                    self.paymentFailureTitle(_i18n.t('reactivation:declinedTitle'));
                    self.paymentFailureMessage(_i18n.t('reactivation:declinedMessage'));
                    return false;
                case _creditCardResponse.duplicateCard:
                case _creditCardResponse.invalidCard:
                case _creditCardResponse.invalidConnection:
                    self.paymentFailureTitle(_i18n.t('reactivation:genericFailureTitle'));
                    self.paymentFailureMessage(_i18n.t('reactivation:genericFailureMessage'));
                    return false;
                default:
                    throw new Error(paymentStatus);
            }
        };

        self.activate = () => {
            const AddPaymentMethodViewModel = require('presentation/loginNotifications/viewModels/addPaymentMethodModalViewModel');
            const FacadeConstructor = require('presentation/loginNotifications/facades/reactivationFacade');
            const ValidatorConstructor = require('presentation/loginNotifications/validators/reactivationValidator');

            _addPaymentMethodViewModel = new AddPaymentMethodViewModel();
            self.addPaymentMethodViewModel(_addPaymentMethodViewModel);
            _sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');
            _facade = new FacadeConstructor();
            _facade.init();
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _getBalanceInformation();
            _getAccountInformation();
            _getAllPaymentMethods();
            _validator.registerViewModel(self);
            return _promiseFactory.wait();
        };
    };
});
