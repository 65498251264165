define('presentation/messages/viewModels/conversationDetailsViewModel',[
    'constants/colorConstants',
    'businessServices/authentication/sessionAccountInfo',
    'common/collections/collectionSorter',
    'common/converters/phoneNumberFormatter',
    'i18next',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/confirmActionViewModel',
    'presentation/messages/dataSources/webMessagingDataSource',
    'presentation/messages/viewModels/conversationDetailsMediaViewModel',
    'presentation/messages/viewModels/conversationDetailsLinksViewModel'
], function(
    /** @type typeof import('constants/colorConstants') */
    colorConstants,
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type typeof import('common/collections/collectionSorter') */
    CollectionSorter,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type import('i18next') */
    i18n,
    /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
    ActionModalViewModel,
    /** @type typeof import('presentation/common/actionModal/viewModels/confirmActionViewModel') */
    ConfirmAction,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    webMessagingDataSource,
    /** @type typeof import('presentation/messages/viewModels/conversationDetailsMediaViewModel') */
    ConversationDetailsMediaViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationDetailsLinksViewModel') */
    ConversationDetailsLinksViewModel
) {
    return function(
        /** @type {() => void} */
        onCloseDetailsClickedCallback,
        /** @type {() => void} */
        markConversationAsReviewedCallback,
        /** @type {() => void} */
        markConversationAsNewCallback,
        /** @type {() => void} */
        archiveConversation,
        /** @type {(conversationName: string) => JQueryDeferred<any>} */
        updateConversationNameCallback,
        /** @type {() => void} */
        onSeeAllAttachmentsClickedCallback,
        /** @type {() => void} */
        onSeeAllLinksClickedCallback,
        /** @type import('presentation/messages/utils/conversationMediaManager') */
        mediaManager,
        /** @type import('businessServices/events/eventManager') */
        eventManager,
        /** @type import('presentation/messages/utils/conversationLinksManager') */
        linksManager
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationDetailsViewModel')} ConversationDetailsViewModel */

        /** @type {ConversationDetailsViewModel} */
        const self = this;
        const _actionModal = new ActionModalViewModel();
        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _sorter = new CollectionSorter();
        /** @type {KnockoutObservableArray<IRecipient>} */
        let _participants = null;
        /** @type {KnockoutObservableArray<IGetSmsConversationSubscriber>} */
        let _subscribers = null;
        /** @type {KnockoutObservable<string>} */
        let _sendFromHostedNumberId = null;
        /** @type {IReadInfo} */
        let _conversationReadState = null;

        self.conversationDetailsMediaViewModel = new ConversationDetailsMediaViewModel(
            onSeeAllAttachmentsClickedCallback,
            mediaManager,
            eventManager
        );

        self.conversationDetailsLinksViewModel = new ConversationDetailsLinksViewModel(
            onSeeAllLinksClickedCallback,
            linksManager
        );

        self.scrollPosition = ko.observable(0);

        self.subscribers = ko.pureComputed(() => {
            const currentUserId = sessionAccountInfo.userId();

            const subscriberList = _subscribers()
                .map((sub) => {
                    const { userId, firstName, lastName, groupName } = sub;
                    return Object.assign({
                        isCurrentUser: userId === currentUserId,
                        subscriberName: userId ? `${firstName} ${lastName}` : groupName,
                    }, sub);
                });

            _sorter.multiSort(subscriberList, ["isCurrentUser", "subscriberName"], [false, false]);

            return subscriberList;
        });

        self.participants = ko.pureComputed(() => {
            const hostedNumberId = _sendFromHostedNumberId();

            if (!hostedNumberId) {
                return [];
            }

            /** @type {IFormattedRecipient[]} */
            const formattedRecipients = [];

            const phoneNumber = webMessagingDataSource.webMessagingNumbers()
                .find(x => x.accountHostedNumberId === hostedNumberId);

            if (!phoneNumber) {
                return [];
            }

            const hostedPhoneNumberName = phoneNumber.hostedPhoneNumberName;
            const defaultPhoneNumberName = phoneNumber.hostedPhoneNumberDefaultName;
            const hostedPhoneNumber = phoneNumber.hostedPhoneNumber;

            let sendFromNumber;

            if (!hostedPhoneNumberName || /** TODO: DEV-870 */ hostedPhoneNumberName === defaultPhoneNumberName) {

                const defaultDescription = _phoneNumberFormatter.isTollFree(hostedPhoneNumber) ?
                    i18n.t('tollFree') :
                    _phoneNumberFormatter.formatLocation(phoneNumber.city, phoneNumber.state);

                sendFromNumber = {
                    hostedPhoneNumber: hostedPhoneNumber,
                    phoneNumber: defaultPhoneNumberName,
                    location: defaultDescription,
                    isSender: true,
                    longitude: phoneNumber.longitude ? Number(phoneNumber.longitude) : null,
                    latitude: phoneNumber.latitude ? Number(phoneNumber.latitude) : null
                };
            }
            else {
                sendFromNumber = {
                    hostedPhoneNumber: hostedPhoneNumber,
                    phoneNumber: hostedPhoneNumberName,
                    location: _phoneNumberFormatter.toNumericDefault(phoneNumber.hostedPhoneNumber),
                    isSender: true,
                    longitude: phoneNumber.longitude ? Number(phoneNumber.longitude) : null,
                    latitude: phoneNumber.latitude ? Number(phoneNumber.latitude) : null
                };
            }

            formattedRecipients.push(sendFromNumber);

            for (const participant of _participants()) {
                formattedRecipients.push({
                    phoneNumber: _phoneNumberFormatter.toNumericDefault(participant.phoneNumber),
                    location: participant.meta,
                    isSender: false,
                    longitude: participant.longitude,
                    latitude: participant.latitude
                });
            }

            return formattedRecipients;
        });

        self.showConversationDetails = null;
        self.getMap = null;
        self.conversationName = null;
        self.conversationName = ko.observable('');
        self.conversationNameInputValue = ko.observable('');
        self.createDraftConversation = null;
        self.conversationCreatedDateTime = null;

        self.isConversationNameInputFocused = ko.observable(false);
        self.isMouseoverNameInput = ko.observable(false);
        self.showSaveSpinner = ko.observable(false);
        self.spinnerColor = colorConstants.colorPrimaryWhite;

        self.showMarkAsNewButton = ko.pureComputed(() => {
            const isMarkedAsNew = _conversationReadState.isMarkedAsNew();
            const lastMessageDate = _conversationReadState.lastMessageDate();
            const lastViewedDate = _conversationReadState.lastViewedDate();

            if (isMarkedAsNew) {
                return false;
            }

            return lastMessageDate < lastViewedDate;
        });

        self.markAsI18nKey = ko.pureComputed(() => {
            return self.showMarkAsNewButton() ? 'markAsNew' : 'markAsReviewed';
        });

        self.hasConversationNameChanged = ko.pureComputed(() => {
            return self.conversationNameInputValue() !== self.conversationName();
        });

        self.isConversationNameEmpty = ko.pureComputed(() => {
            return self.conversationNameInputValue() === "";
        });

        self.showConversationNameClearButton = ko.pureComputed(() => {
            return (self.isConversationNameInputFocused() || self.isMouseoverNameInput()) &&
                !self.isConversationNameEmpty();
        });

        self.onCloseClicked = () => {
            self.conversationNameInputValue(self.conversationName());

            onCloseDetailsClickedCallback();
        };

        self.onArchiveClicked = () => {
            _actionModal
                .clearModal()
                .setContentViewModel(ConfirmAction, [i18n.t('conversationDetails:archiveConfirmation')])
                .setHeaderText({i18n: 'conversationDetails:archiveConfirmationHeader'})
                .setCancelButtonText({i18n: 'cancel'})
                .setSubmitButtonText({i18n: 'archive'})
                .showModal()
                .done((result) => {
                    if (result === ActionModalViewModel.defaultResponse) {
                        archiveConversation();
                    }
                });
        };

        self.onMarkAsClicked = () => {
            if (self.showMarkAsNewButton()) {
                markConversationAsNewCallback();
            } else {
                markConversationAsReviewedCallback();
            }
        };

        self.onNameInputMouseover = () => {
            self.isMouseoverNameInput(true);
        };

        self.onNameInputMouseout = () => {
            self.isMouseoverNameInput(false);
        };

        self.onConversationNameClearClicked = () => {
            self.conversationNameInputValue("");
            self.isConversationNameInputFocused(true);
        };

        self.onConversationNameCancelClicked = () => {
            const currentConversationName = self.conversationName();

            self.conversationNameInputValue(currentConversationName);
        };

        self.onConversationNameSaveClicked = () => {
            const currentConversationName = self.conversationName();
            const conversationNameInputValue = self.conversationNameInputValue().trim().substring(0, 250);

            if (currentConversationName === conversationNameInputValue) {
                return;
            }

            self.showSaveSpinner(true);

            updateConversationNameCallback(conversationNameInputValue)
                .done(() => {
                    self.showSaveSpinner(false);
                });
        };

        /** @type ConversationDetailsViewModel["activate"] */
        self.activate = (activationData) => {
            self.conversationName = activationData.conversationName;
            self.conversationNameInputValue = activationData.conversationNameInputValue;
            self.conversationCreatedDateTime = activationData.conversationCreatedDateTime;
            self.createDraftConversation = activationData.createDraftConversation;
            self.showConversationDetails = activationData.showConversationDetails;

            _conversationReadState = activationData.conversationReadState;
            _subscribers = activationData.subscribers;
            _participants = activationData.participants;
            _sendFromHostedNumberId = activationData.sendFromHostedNumberId;

            self.getMap = () => {
                const locations = _participants()
                    .filter(x => x.latitude && x.longitude)
                    .map(x => {
                        return {
                            latitude: Number(x.latitude),
                            longitude: Number(x.longitude),
                            useDarkPin: false
                        };
                    });

                const hostedNumberId = _sendFromHostedNumberId();
                const hostedPhoneNumberInfo = webMessagingDataSource.webMessagingNumbers()
                    .find(x => x.accountHostedNumberId === hostedNumberId);
                if (hostedPhoneNumberInfo && hostedPhoneNumberInfo.latitude) {
                    locations.push({
                        latitude: Number(hostedPhoneNumberInfo.latitude),
                        longitude: Number(hostedPhoneNumberInfo.longitude),
                        useDarkPin: true
                    });
                }

                if (locations.length) {
                    return activationData.getMap({
                        width: 257,
                        height: 150,
                        locations: locations
                    });
                }

                return Promise.resolve();
            };
        };
    };
});
