define('presentation/settings/billing/billCycleToDate/facades/billCycleToDateFacade',['businessServices/converters/creditCardEnumerationValueConverter',
        'businessServices/converters/textConverter',
        'businessServices/creditCards/creditCardTypes',
        'common/converters/phoneNumberFormatter',
        'common/collections/collectionSorter',
        'common/converters/passedTimeSpanFormatter',
        'common/storage/commonState',
        'common/time/date',
        'common/time/datetimeFormatter',
        'externalDependencies/kazooApi',
        'presentation/settings/billing/billCycleToDate/presentationObjects/billCycleToDatePresentationObject'],
function() {
    const BillCycleToDatePresentationObjectConstructor = require('presentation/settings/billing/billCycleToDate/presentationObjects/billCycleToDatePresentationObject');
    const _creditCardEnumerationValueConverter = require('businessServices/converters/creditCardEnumerationValueConverter');
    const CreditCardConstructor = require('businessServices/creditCards/creditCardTypes');
    let _creditCard = new CreditCardConstructor();
    let _promiseFactory = null;
    let _kazooApi = null;

    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    let _phoneNumberFormatter = new PhoneNumberFormatterConstructor();
    const CollectionSorterConstructor = require('common/collections/collectionSorter');
    let _collectionSorter = new CollectionSorterConstructor();
    const DateConstructor = require('common/time/date');

    const TextConverterConstructor = require('businessServices/converters/textConverter');
    let _textConverter = new TextConverterConstructor();

    const _commonState = require('common/storage/commonState');
    const _i18n = require('i18next');

    const PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
    let _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

    const DateTimeFormatterConstructor = require('common/time/datetimeFormatter');
    let _dateTimeFormatter = new DateTimeFormatterConstructor();

    const STARTING_BALANCE_LINE_ITEM = {
        previousBalance: {
            id: "previousbal",
            sortOrder: 1
        },
        oneTimePayment: {
            id: "onetime-payment",
            sortOrder: 2
        },
        creditNote: {
            id: "credit-note",
            sortOrder: 3
        },
        oneTimeCharge: {
            id: "onetime-charge",
            sortOrder: 3
        },
        oneTimePaymentApproved: {
            id: "approved",
            sortOrder: 4
        },
        oneTimePaymentDeclined: {
            id: "declined",
            sortOrder: 5
        },
        balanceForward: {
            id: "balanceforward",
            sortOrder: 6
        }
    };

    const _filterAdjustments = (adjustments, typeFilter1, typeFilter2) => {
        return adjustments.filter((adjustment) => {
            return adjustment.type === typeFilter1 || adjustment.type === typeFilter2;
        }).map((adjustment) => {
            return {
                title: adjustment.title,
                description: adjustment.description,
                amount: adjustment.amount
            };
        });
    };

    const _getDefaultCreditCardInfo = (defaultCreditCardData) => {
        const defaultCreditCardInfo = _creditCardEnumerationValueConverter.getEnumerationValueByType(defaultCreditCardData.cardType);
        if (defaultCreditCardInfo === undefined) {
            let error = new Error("Invalid credit card type");
            error.defaultCreditCardData = defaultCreditCardData;
            throw error;
        } else {
            const creditCard = _creditCard.getCreditCardShortDisplay(defaultCreditCardData.cardType, defaultCreditCardData.lastFourDigits, defaultCreditCardData.expirationDate);
            return {
                lastFourDigits: defaultCreditCardData.lastFourDigits,
                displayCardNameAbbreviation: defaultCreditCardInfo.displayCardNameAbbreviation,
                shortDisplayName: creditCard.shortDisplay,
                cssClass: "icon-" + defaultCreditCardInfo.cssClass
            };
        }
    };

    const _formatDate = (dateString) => {
        if (dateString !== "") {
            let date = new DateConstructor();
            date.setFromDateString(dateString);
            return _passedTimeSpanFormatter.formatDate(date);
        } else {
            return dateString;
        }
    };

    const _formatProcessingDateDisplay = (dateString) => {
        if (dateString !== "") {
            let date = new DateConstructor();
            date.setFromDateString(dateString);
            return _dateTimeFormatter.displayDateTime(date.getISOString(), "MMM D");
        } else {
            return dateString;
        }
    };

    const _formatUsers = (userList) => {
        let users = userList.map((userItem) => {
            return {
                amount: userItem.userCost,
                description: userItem.emailAddress,
                title: userItem.displayName,
            };
        });
        _collectionSorter.sort(users, "title", true);
        return users;
    };

    const _formatPhoneNumbers = (phoneNumberList) => {
        const getPhoneNumber = (did) => {
            return _commonState.hostedNumbers().find((hostedNumber) => {
                return hostedNumber.phoneNumber() === _phoneNumberFormatter.toInternationalWithParens(did);
            });
        };

        let phoneNumbers = phoneNumberList.map((phoneNumberItem) => {
            let title = phoneNumberItem.phoneNumberName;
            let description = _phoneNumberFormatter.toInternationalWithParens(phoneNumberItem.phoneNumber);
            let commonStateHostedNumber = getPhoneNumber(phoneNumberItem.phoneNumber);

            if (commonStateHostedNumber) {
                let isTollfree = _phoneNumberFormatter.isTollFree(_phoneNumberFormatter.clean(commonStateHostedNumber.phoneNumber()));
                title = commonStateHostedNumber.hasDefaultName() ? commonStateHostedNumber.phoneNumber() : commonStateHostedNumber.name();
                description = isTollfree === true ? "Toll-Free" : commonStateHostedNumber.location();
            }
            return {
                title: title,
                phoneNumber: phoneNumberItem.phoneNumber,
                isIncluded: phoneNumberItem.isIncluded,
                description: description,
                amount: phoneNumberItem.cost,
            };
        });
        _collectionSorter.multiSort(phoneNumbers, ["isIncluded", "title"], [false, true]);
        return phoneNumbers;
    };

    const _formatPlan = (planDescription, planAmount) => {
        return [{
            title: planDescription,
            description: "",
            order: 1,
            amount: planAmount
        }];
    };

    const _formatTaxesFeesSurcharges = (taxesFeesSurchargesList) => {
        let taxesFeesSurcharges = taxesFeesSurchargesList.map((taxFeeSurcharge) => {
            return {
                title: _textConverter.capitalizeEachWord(taxFeeSurcharge.taxDescription),
                description: "",
                order: taxFeeSurcharge.order,
                amount: taxFeeSurcharge.taxAmount
            };
        });

        _collectionSorter.sort(taxesFeesSurcharges, "order", true);
        return taxesFeesSurcharges;
    };

    const _formatStartingBalance = (currentStatement) => {
        const sortOrder = (type) => {
            switch (type) {
                case STARTING_BALANCE_LINE_ITEM.previousBalance.id:
                    return STARTING_BALANCE_LINE_ITEM.previousBalance.sortOrder;
                case STARTING_BALANCE_LINE_ITEM.oneTimePayment.id:
                    return STARTING_BALANCE_LINE_ITEM.oneTimePayment.sortOrder;
                case STARTING_BALANCE_LINE_ITEM.oneTimeCharge.id:
                    return STARTING_BALANCE_LINE_ITEM.oneTimeCharge.sortOrder;
                case STARTING_BALANCE_LINE_ITEM.oneTimePaymentApproved.id:
                    return STARTING_BALANCE_LINE_ITEM.oneTimePaymentApproved.sortOrder;
                case STARTING_BALANCE_LINE_ITEM.oneTimePaymentDeclined.id:
                    return STARTING_BALANCE_LINE_ITEM.oneTimePaymentDeclined.sortOrder;
                case STARTING_BALANCE_LINE_ITEM.balanceForward.id:
                    return STARTING_BALANCE_LINE_ITEM.balanceForward.sortOrder;
                default:
                    let unknownTypeError = new Error("item has an unknown type");
                    unknownTypeError.type = type;
                    throw unknownTypeError;
            }
        };

        let payments = currentStatement.previousBalanceAndPaymentAttempts
            .map((startingBalanceItem) => {
                let title;
                if (currentStatement.isInFreeTrial === true) {
                    const options = {
                        freeTrialStartDate: _formatDate(currentStatement.freeTrialStartDate)
                    };
                    title = _i18n.t(`billCycleToDate:freeTrialStartedOn`, options);
                } else if (currentStatement.hasCurrentBalance && startingBalanceItem.type === STARTING_BALANCE_LINE_ITEM.previousBalance) {
                    const options = {
                        cycleStartDate: _formatDate(currentStatement.currentBalanceCycleStartDate),
                        cycleEndDate: _formatDate(currentStatement.currentBalanceCycleEndDate)
                    };
                    title = _i18n.t(`billCycleToDate:invoiceFor`, options);
                } else {
                    title = startingBalanceItem.title + " " + startingBalanceItem.description;
                }
                return {
                    title: title,
                    amount: startingBalanceItem.amount,
                    order: sortOrder(startingBalanceItem.type)
                };
            });

        let credits = _filterAdjustments(currentStatement.adjustments, STARTING_BALANCE_LINE_ITEM.creditNote.id, STARTING_BALANCE_LINE_ITEM.oneTimeCharge.id)
            .map((credit) => {
                return {
                    title: credit.description,
                    description: "",
                    amount: credit.amount,
                    order: sortOrder(STARTING_BALANCE_LINE_ITEM.oneTimeCharge.id)
                };
            });

        let remainingBalance = {
            title: _i18n.t(`billCycleToDate:remainingBalance`),
            description: "",
            amount: currentStatement.currentBalance,
            order: sortOrder(STARTING_BALANCE_LINE_ITEM.balanceForward.id),
            includeInTotal: false
        };

        let startingItems = payments.concat(credits).concat(remainingBalance);
        _collectionSorter.multiSort(startingItems, ["order", "title"]);
        return startingItems;
    };

    const _getCurrentStatement = () => {
        return _promiseFactory.defer((deferredObject) => {
            let billCycleToDatePresentationObject = new BillCycleToDatePresentationObjectConstructor();
            _kazooApi.callAccount('/lobby_bill_cycle_to_date','GET')
                .fail(deferredObject.reject)
                .done((result) => {
                    let currentStatement = result.data.data;
                    billCycleToDatePresentationObject.estimatedCharges = currentStatement.estimatedCharges;
                    billCycleToDatePresentationObject.estimatedChargesProcessingDateDisplay = _formatProcessingDateDisplay(currentStatement.cycleChargeDate);

                    billCycleToDatePresentationObject.doNotAutoCharge = currentStatement.doNotAutoCharge;

                    billCycleToDatePresentationObject.hasPastDueBalance = currentStatement.hasPastDueBalance;
                    billCycleToDatePresentationObject.pastDueBalance = currentStatement.pastDueBalance;
                    billCycleToDatePresentationObject.pastDueBalanceCycleStartDate = _formatDate(currentStatement.pastDueBalanceCycleStartDate);
                    billCycleToDatePresentationObject.pastDueBalanceCycleEndDate = _formatDate(currentStatement.pastDueBalanceCycleEndDate);
                    billCycleToDatePresentationObject.pastDueBalanceAutoCloseDate = _formatDate(currentStatement.pastDueBalanceAutoCloseDate);

                    billCycleToDatePresentationObject.hasCurrentBalance = currentStatement.hasCurrentBalance;
                    billCycleToDatePresentationObject.currentBalance = currentStatement.currentBalance;
                    billCycleToDatePresentationObject.currentBalanceCycleStartDate = _formatDate(currentStatement.currentBalanceCycleStartDate);
                    billCycleToDatePresentationObject.currentBalanceCycleEndDate = _formatDate(currentStatement.currentBalanceCycleEndDate);

                    if (currentStatement.currentBalanceChargeDate !== "") {
                        billCycleToDatePresentationObject.currentBalanceChargeDate = _formatDate(currentStatement.currentBalanceChargeDate);
                        billCycleToDatePresentationObject.currentBalanceProcessingDateDisplay = _formatProcessingDateDisplay(currentStatement.currentBalanceChargeDate);

                    } else {
                        billCycleToDatePresentationObject.currentBalanceChargeDate = _formatDate(currentStatement.cycleChargeDate);
                        billCycleToDatePresentationObject.currentBalanceProcessingDateDisplay = _formatProcessingDateDisplay(currentStatement.cycleChargeDate);
                    }

                    billCycleToDatePresentationObject.defaultPaymentMethod = _getDefaultCreditCardInfo(currentStatement.defaultPaymentMethod);
                    billCycleToDatePresentationObject.currentChargeDate = _formatDate(currentStatement.cycleChargeDate);

                    if (currentStatement.planAmount > 0) {
                        billCycleToDatePresentationObject.displayPlan = true;
                    }
                    billCycleToDatePresentationObject.plan = _formatPlan(currentStatement.planDescription, currentStatement.planAmount);
                    billCycleToDatePresentationObject.users = _formatUsers(currentStatement.users);
                    billCycleToDatePresentationObject.phoneNumbers = _formatPhoneNumbers(currentStatement.phoneNumbers);

                    billCycleToDatePresentationObject.taxesFeesSurcharges = _formatTaxesFeesSurcharges(currentStatement.taxesFeesSurcharges);

                    billCycleToDatePresentationObject.startingBalance = _formatStartingBalance(currentStatement);

                    billCycleToDatePresentationObject.isInFreeTrial = currentStatement.isInFreeTrial;
                    billCycleToDatePresentationObject.freeTrialStartDate = _formatDate(currentStatement.freeTrialStartDate);
                    billCycleToDatePresentationObject.freeTrialEndDate = _formatDate(currentStatement.freeTrialEndDate);
                    billCycleToDatePresentationObject.numberOfDaysRemainingInFreeTrial = currentStatement.numberOfDaysRemainingInFreeTrial;
                    billCycleToDatePresentationObject.cycleStartDate = _formatDate(currentStatement.cycleStartDate);
                    billCycleToDatePresentationObject.cycleEndDate = _formatDate(currentStatement.cycleEndDate);
                    deferredObject.resolve(billCycleToDatePresentationObject);
                });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const KazooConstructor = require('externalDependencies/kazooApi');
        _kazooApi = new KazooConstructor();
    };

    return function() {
        const self = this;

        self.init = _init;
        self.getCurrentStatement = _getCurrentStatement;
    };
});

