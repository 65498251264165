define('presentation/messages/viewModels/conversationLocationViewModel',[], function () {
    return function() {
        const self = this;

        const _loadMap = () => {
            self.getMap()
                .then((mapUrl) => {
                    self.isLoading(false);
                    self.mapUrl(mapUrl);
                    self.shouldDisplayMap(!!mapUrl);
                });
        };

        /** @type {() => PromiseLike<string>} */
        self.getMap = null;
        /** @type {KnockoutObservable<string>} */
        self.mapUrl = ko.observable();
        self.isLoading = ko.observable(true);
        self.shouldDisplayMap = ko.observable(true);

        /**
         * @param { { getMap: () => PromiseLike<string>, showConversationDetails: KnockoutObservable<Boolean> } } activationData
         */
        self.activate = (activationData) => {
            const { getMap, showConversationDetails } = activationData;
            self.getMap = getMap;

            if (showConversationDetails()) {
                _loadMap();
                return;
            }

            const subscription = showConversationDetails.subscribe(() => {
                _loadMap();
                subscription.dispose();
            });
        };
    };
});
