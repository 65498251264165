define('presentation/shell/viewModels/emptyShellViewModel',['businessServices/router/router',
		'common/promises/promiseFactory',
		'businessServices/events/eventManager',
		'constants/shellNameEnumerations'], function() {

	return function() {
		var self = this;

		var PromiseFactoryConstructor = require('common/promises/promiseFactory');
		var _promiseFactory = new PromiseFactoryConstructor();
		var _eventManager = null;
		var _shellNameEnumerations = require('constants/shellNameEnumerations');

		self.router = null;
		self.childViewModel = ko.observable("");
		self.shellIsActive = ko.observable(true);

		var _onShellUpdated = function (shellName) {
			if(shellName === _shellNameEnumerations.empty) {
				self.childViewModel(self.router.activeItem());
				self.shellIsActive(true);
			} else {
				self.childViewModel(null);
				self.shellIsActive(false);
			}
		};

		self.activate = function() {
			self.router = require('businessServices/router/router');
			_eventManager = require('businessServices/events/eventManager');

			return _initialize();
		};

		self.activate2 = function(router, eventManager) {
			self.router = router;
			_eventManager = eventManager;

			return _initialize();
		};

		var _initialize = function () {
			self.childViewModel(self.router.activeItem());

			_eventManager.subscribeShellUpdated(_onShellUpdated);

			return _promiseFactory.wait();
		};
	};
});
