define('presentation/settings/index/viewModels/indexViewModel',['common/promises/promiseFactory',
    'businessServices/router/router',
    'settings/navigationConfiguration'
], function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        let _router = require('businessServices/router/router');
        let _navigationConfig = require('settings/navigationConfiguration');

        const _findFirstNavWithSidebar = (route) => {
            if (route.sidebarViewModel === null &&
                route.childRoutes.length > 0) {
                return _findFirstNavWithSidebar(route.childRoutes[0]);
            } else {
                return route;
            }
        };

        self.activate = ()  =>{
            return _initialize();
        };

        const _initialize = () => {
            const settingsNav = _navigationConfig.routesById["system-settings"];
            const firstAvailableSubNav = _findFirstNavWithSidebar(settingsNav);

            _router.navigate(firstAvailableSubNav.url);

            return _promiseFactory.wait();
        };
    };
});

