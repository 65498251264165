define('presentation/contacts/viewModels/contactsViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'presentation/contacts/viewModels/addContactViewModel',
    'presentation/contacts/viewModels/contactCardViewModel',
    'presentation/contacts/viewModels/contactDetailsViewModel',
    'settings/navigationConfiguration'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    _sessionAccountInfo,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('businessServices/router/router') */
    _router,
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('presentation/contacts/viewModels/addContactViewModel') */
    AddContactViewModel,
    /** @type typeof import('presentation/contacts/viewModels/contactCardViewModel') */
    ContactCardViewModel,
    /** @type typeof import('presentation/contacts/viewModels/contactDetailsViewModel') */
    ContactDetailsViewModel,
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration
) {
    return function () {
        const self = this;

        /** @type { (typeof PromiseFactory)["prototype"] } */
        let _promiseFactory = null;

        /** @type { IDisposable[] } */
        let _disposables = [];

        self.modelStateObserver = null;
        self.isDirty = ko.observable(false).extend({observeState: true});

        self.sidebarContacts = _contactsState.sidebarContacts;
        self.currentView = ko.observable(null);
        self.selectedContactDetailsViewModel = ko.observable(null);
        self.showContactNotFoundMessage = ko.observable(false);
        self.showDetails = ko.observable(false);

        self.showProtectedContentNotice = ko.pureComputed(() => {
            return _sessionAccountInfo.isImpersonating();
        });

        const _navigateToContactId = (/** @type { string } */ contactId) => {
            const contactUrl = `${_navigationConfiguration.routesById.contact.baseUrl}/${contactId}`;
            _router.navigate(contactUrl);
        };

        const _handleRoute = () => {
            return _promiseFactory.defer((promise) => {
                const routeId = _router.activeInstruction().config.customSettings.routeId;
                switch (routeId) {
                    case _navigationConfiguration.routesById.contacts.routeId:
                        self.showContactNotFoundMessage(false);
                        self.currentView(null);
                        self.selectedContactDetailsViewModel(null);

                        const selectedContactId = _contactsState.selectedContactId();
                        if (selectedContactId) {
                            _contactsState.setSelectedContactId(selectedContactId);
                            _navigateToContactId(selectedContactId);
                            promise.resolve();
                            return;
                        }

                        _contactsState.getDefaultContactId()
                            .then((firstContactId) => {
                                if (firstContactId) {
                                    _contactsState.setSelectedContactId(firstContactId);
                                    _navigateToContactId(firstContactId);
                                    promise.resolve();
                                }
                            });
                        break;
                    case _navigationConfiguration.routesById.contact.routeId:
                        self.showContactNotFoundMessage(false);
                        const [contactId] = _router.activeInstruction().params;
                        _contactsState.setSelectedContactId(contactId);
                        const contactCard = new ContactCardViewModel(
                            contactId,
                            self.onCloseDetailsClickedCallback,
                            self.onInfoBubbleClickedCallback,
                            _router
                        );

                        self.currentView(contactCard);
                        contactCard.activate()
                            .fail(promise.reject)
                            .done(() => {
                                self.selectedContactDetailsViewModel(new ContactDetailsViewModel(
                                    contactId,
                                    self.onCloseDetailsClickedCallback
                                ));
                                promise.resolve();
                            });
                        break;
                    case _navigationConfiguration.routesById.addContact.routeId:
                        self.showContactNotFoundMessage(false);
                        const [phoneNumber] = _router.activeInstruction().params;
                        if (phoneNumber) {
                            const addContact = new AddContactViewModel(self, null, phoneNumber);
                            self.currentView(addContact);
                            self.showDetails(false);
                            addContact.activate()
                                .fail(promise.reject)
                                .done(() => {
                                    _contactsState.setSelectedContactId(null);
                                    self.modelStateObserver.addChildObserver(self.currentView().modelStateObserver);
                                    promise.resolve();
                                });
                        } else {
                            const addContact = new AddContactViewModel(self);
                            self.currentView(addContact);
                            self.showDetails(false);
                            addContact.activate()
                                .fail(promise.reject)
                                .done(() => {
                                    _contactsState.setSelectedContactId(null);
                                    self.modelStateObserver.addChildObserver(self.currentView().modelStateObserver);
                                    promise.resolve();
                                });
                        }
                        break;
                    case _navigationConfiguration.routesById.editContact.routeId:
                        self.showContactNotFoundMessage(false);
                        const [accountContactId] = _router.activeInstruction().params;
                        const editContact = new AddContactViewModel(self, accountContactId);
                        self.currentView(editContact);
                        editContact.activate()
                            .fail(promise.reject)
                            .done(() => {
                                self.modelStateObserver.addChildObserver(self.currentView().modelStateObserver);
                                promise.resolve();
                            });

                        break;
                    case _navigationConfiguration.routesById.contactNotFound.routeId:
                        self.currentView(null);
                        self.showContactNotFoundMessage(true);
                        promise.resolve();
                }
            });
        };

        const _onSidebarContactsChanged = () => {
            const selectedContact = self.currentView();
            if (selectedContact) {
                return;
            }
            _contactsState.getDefaultContactId()
                .then((accountContactId) => {
                    if (accountContactId) {
                        _navigateToContactId(accountContactId);
                    }
                });
        };

        self.onInfoBubbleClickedCallback = () => {
            self.showDetails(true);
        };

        self.onCloseDetailsClickedCallback = () => {
            self.showDetails(false);
        };

        self.canReuseForRoute = () => {
            return true;
        };

        self.detached = () => {
            _disposables.forEach(subscription => subscription.dispose());
            _disposables = [];
        };

        self.activate = () => {
            _promiseFactory = new PromiseFactory();

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserver(self, true);

            if (_sessionAccountInfo.isImpersonating()) {
                return _promiseFactory.wait();
            }

            _promiseFactory.defer((initializePromise) => {
                _handleRoute()
                    .fail(initializePromise.reject)
                    .done(() => {
                        _disposables.push(self.sidebarContacts.subscribe(_onSidebarContactsChanged));
                        self.modelStateObserver.commitData();
                        initializePromise.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
