define('presentation/messages/viewModels/messageComposeAreaViewModel',[
    'i18next',
    'common/promises/promiseFactory',
    'settings/navigationConfiguration',
    'common/converters/phoneNumberFormatter',
    'common/files/fileUploader',
    'presentation/messages/viewModels/conversationMessageAttachmentViewModel',
    'constants/smsConversationMessageAttachmentStatus'
],
    function (
        /** @type typeof import('i18next') */
        i18n,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactoryConstructor,
        /** @type typeof import('settings/navigationConfiguration') */
        _navigationConfiguration,
        /** @type typeof import('common/converters/phoneNumberFormatter') */
        PhoneNumberFormatter,
        /** @type typeof import('common/files/fileUploader') */
        FileUploader,
        /** @type typeof import('presentation/messages/viewModels/conversationMessageAttachmentViewModel') */
        ConversationMessageAttachmentViewModel,
        /** @type typeof import ('constants/smsConversationMessageAttachmentStatus') */
        SmsConversationMessageAttachmentStatus
    ) {
    return function (
        /** @type {(attachmentId: string) => JQueryDeferred<ICancelAttachmentResponse>} */
        cancelAttachmentCallback,
        /** @type {KnockoutComputed<boolean>} **/
        isAnyRecipientBlocked
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */

        /** @type import('presentation/messages/viewModels/messageComposeAreaViewModel') */
        const self = this;
        const _promiseFactory = new PromiseFactoryConstructor();

        let _isOverSubmit = false;
        /** @type {KnockoutObservable<string>} */
        let _conversationName = null;
        /** @type {KnockoutObservableArray<IRecipient>} */
        let _recipients = null;
        /** @type {KnockoutObservable<boolean>} */
        const _textAreaFocused = ko.observable(false);

        /** @type {number} */
        const MAX_ATTACHMENTS = 10;
        const MAX_FILE_SIZE = 1024 * 1024 * 25; // 25MB

        /** @type {IDisposable[]} */
        let _disposables = [];

        /** @type {import('common/files/fileUploader')} */
        let _fileUploader = null;

        const _phoneNumberFormatter = new PhoneNumberFormatter();

        /** @type {self['handleDrop']} */
        self.handleDrop = (_, event) => {
            if (event.originalEvent.dataTransfer) {
                const files = event.originalEvent.dataTransfer.files;
                _fileUploader.handleFiles(files);
            }

            event.preventDefault();
        };

        /** @type {self['handleDragOver']} */
        self.handleDragOver = (_, event) => {
            event.stopPropagation();
            event.preventDefault();
        };

        self.handleEnter = (/** @type {self} */_, /** @type {JQuery.KeyDownEvent<HTMLTextAreaElement>} */jQueryEvent) => {
            const evt = jQueryEvent.originalEvent;

            if (evt.key !== "Enter" || !(evt.target instanceof HTMLTextAreaElement)) {
                return true;
            }

            if (evt.shiftKey) {
                const element = evt.target;

                element.focus();
                const { selectionStart, selectionEnd, value } = element;

                element.value = value.slice(0, selectionStart) + `\n` + value.slice(selectionEnd);
                element.selectionEnd = element.selectionStart = selectionStart + `\n`.length;
            }
            else if (self.submitEnabled())
            {
                self.onSubmit();
            }

            evt.preventDefault();
            return false;
        };

        self.conversationMessageAttachmentViewModel = new ConversationMessageAttachmentViewModel();
        self.hasMaximumAttachments = ko.pureComputed(() => {
            return self.messageAttachments && self.messageAttachments().length === MAX_ATTACHMENTS;
        });

        self.comingSoon = i18n.t("comingSoon");
        self.attachmentIconText = i18n.t("messages:attachmentText");
        self.isSubmitting = null;
        self.onBlur = null;
        self.onSubmit = null;
        self.messageContent = null;
        self.messageAttachments = null;
        self.isNumberLimited = null;
        self.isMouseDownOnDragBar = ko.observable(false);
        self.isAnyRecipientBlocked = isAnyRecipientBlocked;

        self.textAreaFocused = ko.pureComputed(() => {
            const { length } = self.messageContent();
            const textAreaFocused = _textAreaFocused();
            return textAreaFocused || length > 0 || self.hasAttachmentsInComposeArea();
        });

        self.onFocusInTextArea = () => {
            _textAreaFocused(true);
        };

        self.onFocusOutTextArea = () => {
            _textAreaFocused(false);
        };

        /** @type self["setFocusOnMenuBarClick"] */
        self.setFocusOnMenuBarClick = () => {
            document.getElementById('message-compose-area__textarea').focus();
        };

        self.onFocusIn = () => {
            _isOverSubmit = true;
        };

        self.onFocusOut = () => {
            _isOverSubmit = false;
        };

        self.onDragBarMouseDown = () => {
            self.isMouseDownOnDragBar(true);
        };

        self.onDragBarMouseUp = () => {
            self.isMouseDownOnDragBar(false);
        };

        self.submitEnabled = null;

        self.hasAttachmentsInComposeArea = ko.pureComputed(() => !!self.messageAttachments().length);

        self.attachmentRowCount = ko.observable(0);

        self.attemptedMaxAttachments = ko.observable(false);
        self.maxAttachmentsPillMessage = ko.observable(null);

        self.hasOneRowOfAttachments = ko.pureComputed(() => self.attachmentRowCount() === 1);
        self.hasTwoRowsOfAttachments = ko.pureComputed(() => self.attachmentRowCount() === 2);
        self.hasThreeRowsOfAttachments = ko.pureComputed(() => self.attachmentRowCount() === 3);

        self.onAttachmentButtonClick = () => {
            if (!self.hasMaximumAttachments()) {
                _fileUploader.showUploader();
            }
        };

        self.removeAttachment = (/** @type {ConversationMessageAttachmentViewModel}*/ attachmentToRemove) => {
            self.messageAttachments.remove(attachmentToRemove);
            cancelAttachmentCallback(attachmentToRemove.attachmentId());
        };

        const _handleSelectedFile = (/** @type {File}*/file) => {
            const updatedAttachments = self.messageAttachments();

            if (updatedAttachments.length === MAX_ATTACHMENTS) {
                _handleTooManyFiles();
                return;
            }

            const newAttachment = new ConversationMessageAttachmentViewModel();
            newAttachment.activate({file: file, conversionStatus: SmsConversationMessageAttachmentStatus.ReadyToUpload});

            updatedAttachments.push(newAttachment);
            self.messageAttachments(updatedAttachments);
        };

        const _handleFileTooLarge = (/** @type {File}*/ file) => {
            const updatedAttachments = self.messageAttachments();

            const attachment = new ConversationMessageAttachmentViewModel();

            attachment.activate({file: file, conversionStatus: SmsConversationMessageAttachmentStatus.FileSizeTooLarge});

            updatedAttachments.push(attachment);
            self.messageAttachments(updatedAttachments);
        };

        const _handleTooManyFiles = () => {
            self.maxAttachmentsPillMessage(i18n.t('messages:reachedMaxAttachments'));
            self.attemptedMaxAttachments(true);
        };

        const _handleInvalidFilesOnSubmit = () => {
            const invalidAttachments = self.messageAttachments().filter((a) => a.hasErrorMessage());
            if (!invalidAttachments.length) {
                self.attemptedMaxAttachments(false);
            } else {
                const pillMessage = invalidAttachments.length === 1 ? i18n.t('messages:invalidFileUploadAttempted') : i18n.t('messages:nInvalidFilesUploadAttempted', {invalidFiles: invalidAttachments.length});
                self.maxAttachmentsPillMessage(pillMessage);
                self.attemptedMaxAttachments(true);
            }
        };

        self.compositionComplete = () => {
            _fileUploader = new FileUploader('message-compose-area__menu-bar-attachment-input');
            _fileUploader
                .onFileTooLarge(_handleFileTooLarge)
                .onFileSelected(_handleSelectedFile)
                .onTooManyFiles(_handleTooManyFiles)
                .setFileSizeLimit(MAX_FILE_SIZE)
                .setFileLimit(MAX_ATTACHMENTS)
                .setAllowAudio(false);

            _fileUploader.startListening();

            _disposables.push(_fileUploader);
        };

        self.detached = () => {
            _disposables.forEach(disposable => disposable.dispose());
            _disposables = [];
        };

        self.placeholder = ko.pureComputed(() => {
            let messageText = i18n.t(`messageComposeArea:Message`);
            const conversationName = _conversationName();

            if (conversationName) {
                messageText += ` ` + conversationName;
            }
            else {
                const phoneNumbers = _recipients().map(x => _phoneNumberFormatter.toNumericDefault(x.phoneNumber));

                for (let i = 0; i < phoneNumbers.length; i++) {
                    const phoneNumber = phoneNumbers[i];
                    const isFirst = i === 0;
                    const isLast = i + 1 === phoneNumbers.length;
                    const connector = isFirst ? ` ` : (isLast ? ` & `: `, `);
                    messageText = messageText + connector + phoneNumber;
                }
            }

            return messageText;
        });

        self.helpCenterUrl = _navigationConfiguration.routesById.helpCenter.externalUrl;

        /** @type self["activate"] */
        self.activate = (activationData) => {
            const {
                canSubmit,
                messageContent,
                messageAttachments,
                onMessageContentEdited,
                onSubmit,
                isSubmittingMessage,
                isNumberLimited,
                recipients,
                conversationName,
                attemptedMaxAttachments,
                maxAttachmentsPillMessage
            } = activationData;

            self.submitEnabled = canSubmit;
            _recipients = recipients;
            _conversationName = conversationName;
            self.isNumberLimited = isNumberLimited;
            self.isSubmitting = isSubmittingMessage;
            self.messageContent = messageContent;
            self.messageAttachments = messageAttachments;
            self.attemptedMaxAttachments = attemptedMaxAttachments;
            self.maxAttachmentsPillMessage = maxAttachmentsPillMessage;
            self.onBlur = () => {
                if (!_isOverSubmit) {
                    onMessageContentEdited();
                }
            };
            self.onSubmit = () => {
                const message = self.messageContent();
                _handleInvalidFilesOnSubmit();
                onSubmit(message);
            };

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
