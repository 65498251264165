define('businessServices/router/plugins/signupSessionStatePlugin',[
    'constants/numberPickerConstants',
    'settings/navigationConfiguration',
    'constants/signupSessionFlowConstants',
    'constants/signupNumberTypeSelectionConstants',
    'businessServices/signup/signupSessionStore'
], function () {
    const _navigationConfiguration = require('settings/navigationConfiguration');
    const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
    const _signupNumberTypeSelectionConstants = require('constants/signupNumberTypeSelectionConstants');

    const PhoneNumberFormatter = require('common/converters/phoneNumberFormatter');
    const _phoneNumberFormatter = new PhoneNumberFormatter();

    const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
    const _signupSessionStore = new SignupSessionStoreConstructor();
    _signupSessionStore.init();

    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    const _numberPickerConstants = require('constants/numberPickerConstants');

    const FLOW_B = _signupSessionFlowConstants.flowB;
    const FLOW_C = _signupSessionFlowConstants.flowC;

    const ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL =  _navigationConfiguration.routesById.signupPhoneNumberLocal.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE =  _navigationConfiguration.routesById.signupPhoneNumberTollfree.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT =  _navigationConfiguration.routesById.signupPhoneNumberPort.routeId;

    const ROUTE_ID__SIGNUP_EMAIL = _navigationConfiguration.routesById.signupEmail.routeId;

    const routeToSignup = (routeId, routeInfo) => {
        return _promiseFactory.defer((promise) => {
            switch (routeId) {
                case ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL:
                case ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE:
                case ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT:
                    if (routeInfo.params.length > 0) {
                        const flowId = routeInfo.params[0];
                        if (flowId === FLOW_B) {
                            _saveNumberTypeSelection(routeId)
                                .fail(promise.reject)
                                .done(() => {
                                    promise.resolve(true);
                                });
                        } else {
                            promise.resolve(true);
                        }
                    }
                    break;
                case ROUTE_ID__SIGNUP_EMAIL:
                    const flowId = routeInfo.params[0];
                    if (flowId === FLOW_C && routeInfo.queryParams && routeInfo.queryParams.hasOwnProperty("phoneNumber")) {
                        const numberType = _phoneNumberFormatter.isTollFree(routeInfo.queryParams.phoneNumber) ?
                            _signupNumberTypeSelectionConstants.tollFree :
                            _signupNumberTypeSelectionConstants.local;

                        let searchType = _numberPickerConstants.searchTypeEnumeration[routeInfo.queryParams["searchType"]];
                        if (isNaN(searchType)) {
                            searchType = _numberPickerConstants.searchTypeEnumeration.default;
                        }

                        _signupSessionStore.saveSearchAndNumberType({searchType, numberType})
                            .fail(promise.reject)
                            .done(() => {
                                promise.resolve(true);
                            });

                        return;
                    }

                    promise.resolve(true);

                    break;
                default:
                    promise.resolve(true);
            }
        });
    };

    const _saveNumberTypeSelection = (routeId) => {
        return _promiseFactory.defer((promise) => {
            let numberTypeSelection = null;
            switch (routeId) {
                case ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL:
                    numberTypeSelection = _signupNumberTypeSelectionConstants.local;
                    break;
                case ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE:
                    numberTypeSelection = _signupNumberTypeSelectionConstants.tollFree;
                    break;
                case ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT:
                    numberTypeSelection = _signupNumberTypeSelectionConstants.port;
                    break;
            }
            _signupSessionStore.saveNumberTypeSelection(numberTypeSelection)
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    return {
        guardRoute: routeToSignup
    };
});

