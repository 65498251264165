define('businessServices/converters/textConverter',[], function() {
    return function() {

        const self = this;
        
        // Removes all the whites spaces including carriage feeds, tabs in a given string
        const _removeAllWhiteSpaces = (/** @type { string } */ string) => {
            return string.replace(/\s/g, '');
        };

        const _camelCase = (/** @type { string } */ string) => {
            const toString = string.charAt(0).toLowerCase() + string.slice(1)
                .split(' ')
                .reduce((result, word) => result + word.charAt(0).toUpperCase() + word.slice(1));
            return toString.charAt(0).toLowerCase() + toString.slice(1);
        };

        const _capitalizeEachWord = (/** @type { string } */ string) => {
            return string.replace(/\w\S*/g, (toString) => {
                return toString.charAt(0).toUpperCase() + toString.slice(1).toLowerCase();
            });
        };
    
        const _capitalizeFirstLetter = (/** @type { string } */ string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };


        self.removeAllWhiteSpaces = _removeAllWhiteSpaces;
        self.camelCase = _camelCase;
        self.capitalizeEachWord = _capitalizeEachWord;
        self.capitalizeFirstLetter = _capitalizeFirstLetter;
    };
});
