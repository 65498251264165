define('presentation/common/croppableImage/viewModels/croppableImageViewModel',['common/promises/promiseFactory',
        'constants/userProfileConstants',
        'presentation/common/croppableImage/viewModels/uploadImageModalViewModel',
        'presentation/common/modal',
],function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const UploadImageModalConstructor = require('presentation/common/croppableImage/viewModels/uploadImageModalViewModel');

        const _userProfileConstants = require('constants/userProfileConstants');

        let _modalService = null;
        let _settings = null;
        /* settings has the following properties:
         * avatar           [required] contains the avatar to show. This must be an observable.
         * onSaveCroppedImage [optional] called when the user clicks the save button. The signature of this
         *                  functions will need to be onSaveCroppedImage(imageData).
         */

        const _onAvatarChanged = () => {
            if (self.avatar().showDefaultImage()) {
                self.tooltip(_userProfileConstants.noFileTooltip);
            } else {
                self.tooltip(_userProfileConstants.fileSelectedTooltip);
            }
        };

        const _onSaveCroppedImage = (avatar) => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                self.avatar(avatar);

                if (_settings.onSaveCroppedImage) {
                    _settings.onSaveCroppedImage(avatar)
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                } else {
                    deferredObject.resolve();
                }
            });
        };

        self.avatar = ko.observable();
        self.tooltip = ko.observable('');

        self.openUploadAvatarModal = () => {
            const uploadImageModal = new UploadImageModalConstructor(_onSaveCroppedImage);
            _modalService.showModal(uploadImageModal);
        };

        self.activate = (settings) => {
            _settings = settings;

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            _initialize();
        };

        const _initialize = () => {
            if (_settings.avatar === undefined) {
                throw new Error("avatar is required");
            }
            if (!ko.isObservable(_settings.avatar)) {
                throw new Error("avatar must be an observable");
            }
            self.avatar = _settings.avatar;
            self.avatar.subscribe(_onAvatarChanged);
            _onAvatarChanged();

            if ((_settings.onSaveCroppedImage) && (typeof(_settings.onSaveCroppedImage) !== "function")) {
                throw new Error("onSaveCroppedImage must be a function");
            }
        };
    };
});

