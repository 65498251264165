/**
 * @module presentation/common/userGroupAvatar/userGroupImagePresentationObject
 */
define('presentation/common/userGroupAvatar/userGroupImagePresentationObject',[], function(){
    /**
     * @constructor
     */
    return function() {
        var self = this;
		/**
         * The image data should be shown
         * @type {ko.observaryArray<array>}
         * @public
         */
        self.images = ko.observableArray([]);
    };
});

