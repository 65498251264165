define('presentation/shell/viewModels/gradientShellViewModel',[
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'businessServices/events/eventManager',
    'constants/shellNameEnumerations'
],
function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _shellNameEnumerations = require('constants/shellNameEnumerations');

        let _eventManager = null;

        self.router = null;
        self.childViewModel = ko.observable("");
        self.shellIsActive = ko.observable(true);

        const _onShellUpdated = (shellName) => {
            if (shellName === _shellNameEnumerations.gradient) {
                self.childViewModel(self.router.activeItem());
                self.shellIsActive(true);
            } else {
                self.childViewModel(null);
                self.shellIsActive(false);
            }
        };

        self.activate = () => {
            self.router = require('businessServices/router/router');
            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            self.childViewModel(self.router.activeItem());

            _eventManager.subscribeShellUpdated(_onShellUpdated);

            return _promiseFactory.wait();
        };
    };
});
