define('presentation/settings/extensions/viewModels/extensionsViewModel',[
    'presentation/settings/extensions/facades/extensionsFacade',
    'common/promises/promiseFactory',
    'businessServices/events/eventManager',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'constants/scrollEventSources'
],
function() {
    return function() {
        const self = this;

        const FacadeConstructor = require('presentation/settings/extensions/facades/extensionsFacade');
        const CollectionFilterConstructor = require('common/collections/collectionFilter');
        const CollectionSorterConstructor = require('common/collections/collectionSorter');

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _eventManager = require('businessServices/events/eventManager');
        const _i18n = require('i18next');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        let _facade = null;
        let _getNextPageEventId = null;
        let _sortAsc = ko.observable(true);
        let _allExtensions = ko.observableArray([]);

        /** @type {IDisposable[]} */
        let _disposables = [];

        const ROWS_PER_PAGE = 20;
        const TABLE_COLUMNS = [
            "type",
            "name",
            "extension",
            "assigned"
        ];

        const _populateExtensionsNextPageOnScroll = (scrollEventSource) => {
            if (scrollEventSource !== ScrollEventSources.settingsExtensions) {
                return;
            }

            _populateExtensionsNextPage();
        };

        const _populateExtensionsNextPage = () => {
            const filteredAndSortedExtensions = self.filteredAndSortedExtensions();
            if (filteredAndSortedExtensions.length > 0) {
                const displayedExtensions = self.displayedExtensions();
                const nextPagedExtensions = filteredAndSortedExtensions.slice(displayedExtensions.length, displayedExtensions.length + ROWS_PER_PAGE);
                nextPagedExtensions.forEach((pagedExtension) => {
                    self.displayedExtensions.push(pagedExtension);
                });
            }
        };

        const _updateSortBy = (columnName) => {
            if (self.sortBy() === columnName && _sortAsc() === true) {
                _sortAsc(false);
            } else {
                self.sortBy(columnName);
                _sortAsc(true);
            }
        };

        const _getSortValue = (columnName) => {
            if (columnName === "assigned") {
                return "assignedSortValue";
            }

            return columnName;
        };

        const _createColumnSortClassComputed = (columnName) => {
            return ko.pureComputed(() => {
                if (self.sortBy() === columnName) {
                    if (_sortAsc() === true) {
                        return "sortup";
                    } else {
                        return "sortdown";
                    }
                } else {
                    return "";
                }
            });
        };

        const _updateExtensionsListWithUpdatedExtensions = (extensionsInfo) => {
            const allExtensions = _allExtensions();
            const displayedExtensions = self.displayedExtensions();

            extensionsInfo.forEach((extensionInfo) => {
                const updatedAllExtension = allExtensions.find((extensionFromAllExtensionsList) => {
                    return extensionFromAllExtensionsList.originalExtension === extensionInfo.extension();
                });
                const updatedDisplayedExtension = displayedExtensions.find((extensionFromDisplayedExtensionsList) => {
                    return extensionFromDisplayedExtensionsList.originalExtension === extensionInfo.extension();
                });

                if (updatedAllExtension) {
                    updatedAllExtension.assignedToId = extensionInfo.assignedToId;
                    updatedAllExtension.name(extensionInfo.name());
                    updatedAllExtension.type(extensionInfo.type());
                    updatedAllExtension.assignedDisplayValue(extensionInfo.assignedDisplayValue());
                    updatedAllExtension.assignedSortValue(extensionInfo.assignedSortValue());
                    updatedAllExtension.assignedFilterValue(extensionInfo.assignedFilterValue());
                    updatedAllExtension.isAssigned(extensionInfo.isAssigned());
                    updatedAllExtension.extension(extensionInfo.extension());
                    updatedAllExtension.originalExtension = extensionInfo.originalExtension;
                }

                if (updatedDisplayedExtension) {
                    updatedDisplayedExtension.assignedToId = extensionInfo.assignedToId;
                    updatedDisplayedExtension.name(extensionInfo.name());
                    updatedDisplayedExtension.type(extensionInfo.type());
                    updatedDisplayedExtension.assignedDisplayValue(extensionInfo.assignedDisplayValue());
                    updatedDisplayedExtension.assignedSortValue(extensionInfo.assignedSortValue());
                    updatedDisplayedExtension.assignedFilterValue(extensionInfo.assignedFilterValue());
                    updatedDisplayedExtension.isAssigned(extensionInfo.isAssigned());
                    updatedDisplayedExtension.extension(extensionInfo.extension());
                    updatedDisplayedExtension.originalExtension = extensionInfo.originalExtension;
                }
            });
        };

        self.isCompositionComplete = ko.observable(false);
        self.scrollEventSource = ScrollEventSources.settingsExtensions;
        self.filterText = ko.observable("");
        self.columnHeaderSortClassName = _createColumnSortClassComputed("name");
        self.columnHeaderSortClassExtensions = _createColumnSortClassComputed("extension");
        self.columnHeaderSortClassType = _createColumnSortClassComputed("type");
        self.columnHeaderSortClassAssigned = _createColumnSortClassComputed("assignedSortValue");
        self.displayedExtensions = ko.observableArray([]);
        self.sortBy = ko.observable("extension");
        self.extensionTableHeaders = ko.observable(TABLE_COLUMNS.map((column) => {
            return {
                value: column,
                title: _i18n.t(`extensions:${column}`),
                isSorted: ko.pureComputed(() => self.sortBy() === _getSortValue(column))
            };
        }));

        self.showResults = ko.pureComputed(() => {
            return self.displayedExtensions().length > 0;
        });

        self.sortDescending = ko.pureComputed(() => _sortAsc() === false);

        self.filteredAndSortedExtensions = ko.pureComputed(() => {
            const allExtensions = _allExtensions();
            const filterText = self.filterText();
            const sortBy = self.sortBy();
            const sortAsc = _sortAsc();
            let _filteredExtensions;

            let availableExtensions = allExtensions.filter((extensionToEvaluate) => {
                return extensionToEvaluate.isAssigned();
            });

            if (filterText !== "") {
                const collectionFilter = new CollectionFilterConstructor();
                collectionFilter.addProperty('name');
                collectionFilter.addProperty('extension');
                collectionFilter.addProperty('typeDisplayValue');
                collectionFilter.addProperty('assignedFilterValue');
                collectionFilter.addValue(filterText);
                _filteredExtensions = collectionFilter.filter(availableExtensions);
            } else {
                _filteredExtensions = availableExtensions;
            }

            const collectionSorter = new CollectionSorterConstructor();
            collectionSorter.sort(_filteredExtensions, sortBy, sortAsc);

            return _filteredExtensions;
        }).extend({throttle: 1});

        self.sortColumn = (column) => {
            _updateSortBy(_getSortValue(column.value));
        };

        self.isColumnSorted = (column) => {
            return self.sortBy() === _getSortValue(column.value);
        };

        self.onExtensionApplied = (selectedExtension, previousExtension) => {
            return _promiseFactory.defer((deferredObject) => {
                const allExtensions = _allExtensions();
                const previousExtensionObject = allExtensions.find((extensionFromAllExtensionsList) => {
                    return extensionFromAllExtensionsList.originalExtension === previousExtension;
                });
                previousExtensionObject.extension(previousExtensionObject.originalExtension);

                _facade.saveExtensionChange(selectedExtension, previousExtensionObject)
                    .done((extensionsInfo) => {
                        _updateExtensionsListWithUpdatedExtensions(extensionsInfo);
                        deferredObject.resolve();
                    })
                    .fail(deferredObject.reject);
            });
        };

        self.activate = () => {
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _eventManager.unsubscribe(_getNextPageEventId);
            _disposables.forEach((disposable) => {
                disposable.dispose();
            });

            _disposables = [];
        };

        const _initialize = () => {
            _getNextPageEventId = _eventManager.subscribeBottomScrollBufferReached(_populateExtensionsNextPageOnScroll);
            _facade.getExtensions()
                .done((extensions) => {
                    _allExtensions(extensions);
                    _populateExtensionsNextPage();
                })
                .fail((error) => {
                    throw error;
                });

            _disposables.push(self.filteredAndSortedExtensions.subscribe(() => {
                self.displayedExtensions([]);
                _populateExtensionsNextPage();
            }));

            return _promiseFactory.wait();
        };
    };
});

