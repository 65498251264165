define('presentation/contacts/validators/addContactAddressViewModelValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function(
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules
) {
    return function() {
        /** @typedef { import('presentation/contacts/viewModels/addContactAddressViewModel') } AddContactAddressViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { AddContactAddressViewModel } */
        let _viewModel = null;

        self.registerViewModel = (/** @type { AddContactAddressViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.fieldGroup("isFullAddressValid", ['addressLineOne', 'addressLineTwo', 'city', 'region', 'postalCode', 'country'])
                .addValidationKeyUp(_isAddressUnique)
                .addValidationI18n(_isAddressUnique, 'addContact:validation.addressDuplicate');

            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        const _isAddressUnique = (
            /** @type { string } */ addressLineOne,
            /** @type { string } */ addressLineTwo,
            /** @type { string } */ city,
            /** @type { string } */ region,
            /** @type { string } */ postalCode,
            /** @type { number } */ country
        ) => {
            const addresses = _viewModel.parent.addresses();
            const matchingAddresses = addresses.filter((/** @type any */ a) => {
                return a.addressLineOne().trim().toLowerCase() === addressLineOne.trim().toLowerCase() &&
                    a.addressLineTwo().trim().toLowerCase() === addressLineTwo.trim().toLowerCase() &&
                    a.city().trim().toLowerCase() === city.trim().toLowerCase() &&
                    a.region().trim().toLowerCase() === region.trim().toLowerCase() &&
                    a.postalCode().trim().toLowerCase() === postalCode.trim().toLowerCase() &&
                    a.country() === country;
            });
            return matchingAddresses.length === 1;
        };
    };
});
