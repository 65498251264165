define('presentation/settings/phoneNumbers/viewModels/phoneNumberCallRecordingTabViewModel',[
    'businessServices/router/router',
    'common/collections/collectionSorter',
    'common/converters/multiItemMessage',
    'common/promises/promiseFactory',
    'common/storage/commonState',
], function () {
        return function () {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');

            const _commonState = require('common/storage/commonState');
            const _multiItemMessage = require('common/converters/multiItemMessage');
            const _router = require('businessServices/router/router');

            let _connector = null;

            const _prepareSubscribersForDisplay = (callRecordingSubscriber) => {
                let displayCallRecordingSubscribers = callRecordingSubscriber.map((subscriber) => {
                    if (subscriber.userId) {
                        const commonStateItem = _commonState.get(subscriber.userId);
                        return Object.assign({}, subscriber, {
                            avatar: commonStateItem.avatar(),
                            id: commonStateItem.id,
                            name: commonStateItem.name(),
                            firstName: commonStateItem.name().split(' ')[0]
                        });
                    }
                    if (subscriber.userGroupId) {
                        const commonStateItem = _commonState.get(subscriber.userGroupId);
                        return Object.assign({}, subscriber, {
                            id: commonStateItem.id,
                            name: commonStateItem.name()
                        });
                    }
                });

                const sorter = new SorterConstructor();
                sorter.sort(displayCallRecordingSubscribers, "name");

                return displayCallRecordingSubscribers;
            };

            self.callRecordingSubscribers = ko.observableArray([]);
            self.editPhoneNumberCallRecordingUrl = null;
            self.isCallRecordingEnabled = null;
            self.hasCallRecordingSubscribers = ko.pureComputed(() => self.callRecordingSubscribers().length > 0);
            self.showDetails = ko.observable(true);
            self.callRecordingSubscriberIds = ko.pureComputed(() => self.callRecordingSubscribers().map(s => s.id));
            self.callRecordingSubscriberNames = ko.pureComputed(() => {
                let names = self.callRecordingSubscribers().map(s => s.firstName ? s.firstName : s.name);
                return _multiItemMessage.generateMultiItemMessage(names, "{0}", '& ');
            });

            self.isCollapsible = ko.pureComputed(() => self.callRecordingSubscribers().length > 1);

            self.expand = () => {
                if (self.isCollapsible()) {
                    self.showDetails(true);
                }
            };

            self.collapse = () => {
                if (self.isCollapsible()) {
                    self.showDetails(false);
                }
            };

            self.toggleDetails = () => {
                if (self.isCollapsible()) {
                    self.showDetails(!self.showDetails());
                }
            };

            self.navigateToEditPhoneNumber = () => {
                _router.navigate(self.editPhoneNumberCallRecordingUrl);
            };

            self.activate = (connector) => {
                _connector = connector;

                return _initialize();
            };

            const _initialize = () => {
                self.editPhoneNumberCallRecordingUrl = _connector.editConnectorUrl + '/callRecording';
                self.isCallRecordingEnabled = _connector.isCallRecordingEnabled;
                const isCallRecordingPermissionInherited = _connector.isCallRecordingPermissionInherited();

                if (isCallRecordingPermissionInherited && _connector.routeToType() === null) {
                    self.callRecordingSubscribers([]);
                } else if (_connector.callRecordingSubscribers()) {
                    self.callRecordingSubscribers(_prepareSubscribersForDisplay(_connector.callRecordingSubscribers()));
                }

                return _promiseFactory.wait();
            };
        };
    });
