requirejs.config({
    'baseUrl': '/assets/js/app/',
    catchError: {
        define: true
    },
    //*** Any changes made in the paths/shim section need to be duplicated in the Gruntfile durandal section ***//
    paths: {
        'jquery': '../lib/jquery-2.2.3.min',
        'jquery-ui': '../lib/jquery-ui-1.9.2.custom.min',
        'knockout': '../lib/knockout-3.4.0',
        'cookie': '../lib/jquery.cookie',
        'knockout-validation': '../lib/knockout.validation.min',
        'tooltip': '../lib/bootstrap-tooltip-2.3.2',
        'jquery-mousewheel': '../lib/jquery.mousewheel',
        'jquery-resize': '../lib/jquery.resize',
        'overlay-scrollbars' : '../lib/jquery.overlayScrollbars.min',
        'spin': '../lib/spin.min',
        'spin-jquery': '../lib/spin-jquery',
        'timepicker': '../lib/jquery-ui-timepicker-addon',
        'jquery-inputmask': '../lib/jquery.inputmask.bundle.min',
        'uniform': '../lib/jquery.uniform.min',
        'headroom': '../lib/headroom.min',
        'i18next': '../lib/i18next',
        'i18next-locale': '../lib/i18next-locale',
        'jquery-headroom': '../lib/jquery.headroom.min',
        'jquery-payment': '../lib/jquery.payment.min',
        'jplayer': '../lib/jquery.jplayer.min',
        'polyfill': '../lib/polyfill.js',
        'socketio': '../lib/socket.io',
        'text': '../lib/text',
        'moment': '../lib/moment.min',
        'moment-timezone': '../lib/moment-timezone-with-data.min',
        'money' : '../lib/big-3.0.1.min',
        'entities' : '../entities/',
        'constants' : '../constants/',
        'durandal' : 'durandal',
        'plugins': 'durandal/plugins',
        'transitions' : 'durandal/transitions',
        'lib' : '../lib',
        'webrtc-adapter' : '../lib/webrtc-adapter',
        'recordRTC' : '../lib/RecordRTC.min',
        'cropper' : '../lib/cropper.min',
        'intlTelInput' : '../lib/intlTelInput.min',
        'intlTelInputUtils' : '../lib/intlTelInputUtils',
        'velocity' : '../lib/velocity-1.2.3.min',
        'semantic-ui-transition' : '../lib/semantic-ui/transition',
        'semantic-ui-dropdown' : '../lib/semantic-ui/dropdown',
        'select2' : '../lib/select2.full.min',
    },
    shim: {
        'jquery': {
            exports: 'jquery'
        },
        'jquery-ui': {
            deps: ['jquery']
        },
        'durandal': {
            deps: ['jquery', 'knockout']
        },
        'cookie':{
            deps: ['jquery'],
            exports: 'cookie'
        },
        'spin-jquery': {
            deps: ['jquery', 'spin']
        },
        'knockout-validation': {
            deps: ['knockout']
        },
        'tooltip': {
            deps: ['jquery']
        },
        'jquery-mousewheel': {
            deps: ['jquery']
        },
        'jquery-resize': {
            deps: ['jquery']
        },
        'overlay-scrollbars' :{
            deps: ['jquery']
        },
        'timepicker': {
            deps: ['jquery', 'jquery-ui']
        },
        'uniform': {
            deps: ['jquery']
        },
        'jquery-headroom': {
            deps: ['jquery', 'headroom']
        },
        'jquery-payment': {
            deps: ['jquery']
        },
        'jquery-inputmask': {
            deps: ['jquery']
        },
        'recordRTC': {
            deps: ['webrtc-adapter']
        },
        'cropper': {
            deps: ['jquery']
        },
        'intlTelInputUtils' : {
            deps: ['jquery'],
            exports: 'intlTelInputUtils'
        },
        'intlTelInput' : {
            deps: ['intlTelInputUtils']
        },
        'select2' : {
            deps: ['jquery']
        }
    }
});


var _logException = function(exception) {
    var LoggerConstructor = require('common/logging/logger');
    var logger = new LoggerConstructor();
    logger.init();
    logger.logError(exception);
};

requirejs.onError = function(error) {
    _logException(error);
    throw error;
};

define('jquery', [],function () { return jQuery; });
define('knockout', ko);

define('main',['require','durandal/app','durandal/system','settings/settings','i18next','settings/i18nOptions','common/presentation/viewLocatorConfiguration','common/presentation/commonKnockoutConfiguration','presentation/common/knockoutConfiguration','common/presentation/commonWidgetConfiguration','presentation/common/widgetConfiguration','common/presentation/modalConfiguration'],function (require) {
    var app = require('durandal/app');
    var system = require('durandal/system');
    var settings = require('settings/settings');

    system.debug(settings.isInDebugMode);

    system.error = function (error) {
        _logException(error);
    };

    /** @type {OnErrorEventHandlerNonNull} */
    window.onerror = function (msg, url, line, column, errorObject) {
        if (typeof msg === "string" && msg.includes(`ResizeObserver`)) {
            window.console.warn(msg);
            return;
        }

        if (errorObject && errorObject.stack) {
            system.log(errorObject);
        }

        const error = new Error("Caught unHandled Exception: '" + msg + "' from " + url + ":" + line);
        _logException(error);
        throw error;
    };

    const _i18n = require('i18next');
    const i18nOptions = require('settings/i18nOptions');
    _i18n.init(i18nOptions);

    app.title = 'Tresta';
    app.configurePlugins({
        router: true,
        dialog: true
    });

    app.start().then(function () {
        var viewLocatorConfiguration = require('common/presentation/viewLocatorConfiguration');
        viewLocatorConfiguration.configureViewLocator();

        var commonKnockoutConfiguration = require('common/presentation/commonKnockoutConfiguration');
        commonKnockoutConfiguration.configureKnockout();

        var knockoutConfiguration = require('presentation/common/knockoutConfiguration');
        knockoutConfiguration.configureKnockout();

        var commonWidgetConfiguration = require('common/presentation/commonWidgetConfiguration');
        commonWidgetConfiguration.configureWidgets();

        var widgetConfiguration = require('presentation/common/widgetConfiguration');
        widgetConfiguration.configureWidgets();

        var modalConfiguration = require('common/presentation/modalConfiguration');
        modalConfiguration.configureModal();

        app.setRoot('presentation/shell/viewModels/shellViewModel');
    });
});

