define('presentation/settings/users/facades/addUsersModalFacade',['entities/userInviteEntity',
        'persistence/webSocket/webSocketApp',
        'externalDependencies/clientWebSocket'
], function () {
    const UserInviteEntityConstructor = require('entities/userInviteEntity');
    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _extensionWebSocket = clientWebSocket.forApplication("extension");

    let _promiseFactory = null;
    let _webSocketAppAddUsers = null;
    let _webSocketAppValidate = null;

    const _sendUserInvites = (userInvites) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const sendInvites = userInvites.map((userInvite) => {
                const userInviteEntity = new UserInviteEntityConstructor();
                userInviteEntity.emailAddress = userInvite.emailAddress();
                userInviteEntity.extension = userInvite.extension();
                userInviteEntity.billingAdmin = userInvite.isBillingAdmin();
                userInviteEntity.systemAdmin = userInvite.isSystemAdmin();
                return userInviteEntity;
            });
            _webSocketAppAddUsers.send("send_user_invites", {sendInvites: sendInvites}, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _isEmailAddressUnique = (emailAddress) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _webSocketAppValidate.send("is_email_address_unique", {emailAddress: emailAddress}, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _isExtensionUnique = (extension) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketAppValidate.send("is_extension_unique", {extension: extension}, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _getExtensions = () => {
        return _promiseFactory.defer((promise) => {
            _extensionWebSocket.send("listExtensions", {}, (response) => {
                promise.resolve(response.extensions);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketAppAddUsers = new WebSocketActivityAppConstructor();
        _webSocketAppAddUsers.init("users_add_modal");

        _webSocketAppValidate = new WebSocketActivityAppConstructor();
        _webSocketAppValidate.init("validation");
    };

    return function () {
        const self = this;

        self.init = _init;
        self.sendUserInvites = _sendUserInvites;
        self.isEmailAddressUnique = _isEmailAddressUnique;
        self.isExtensionUnique = _isExtensionUnique;
        self.getExtensions = _getExtensions;
    };
});
