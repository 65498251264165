define('businessServices/authentication/sessionUserInfo',['businessServices/events/eventManager',
        'businessServices/authentication/sessionAccountInfo',
        'persistence/dataProviders/userDataProvider'], function() {
    var _firstName = ko.observable("");
    var _fullName = ko.observable("");
    var _eventManager = require('businessServices/events/eventManager');
    var _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
    var UserDataProviderConstructor = require('persistence/dataProviders/userDataProvider');
    var _userDataProvider = new UserDataProviderConstructor();

    var _updateUserInfo = function(userId) {
        var currentUserId = _sessionAccountInfo.userId();
        if (currentUserId === userId) {
            _userDataProvider.getById(userId)
                .done(function(user) {
                    _firstName(user.firstName);
                    _fullName(user.firstName + " " + user.lastName);
                })
                .fail(function(error) {
                    throw error;
                });
        }
    };

    var _receiveAlertLoginSuccessful = function(loginInfo) {
        _updateUserInfo(loginInfo.userId);
    };

    var _receiveAlertLogout = function() {};

    _eventManager.subscribeUserInfoChanged(_updateUserInfo);

    return {
        firstName: _firstName,
        fullName: _fullName,
        alerts : {
            receiveAlertLoginSuccessful : _receiveAlertLoginSuccessful,
            receiveAlertLogout : _receiveAlertLogout
        }
    };
});

