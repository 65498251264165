define('presentation/common/routeToModal/common/routeToViewModelCommonSetup',['presentation/common/routeToModal/viewModels/routeToViewModel',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/routingRuleExitConditionsConstants',
    'common/storage/commonState'], function() {
    return function(validateRouteToViewModelCallback, routeToOptions) {
        var self = this;

        const RouteToViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToViewModel');

        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _commonState = require('common/storage/commonState');
        const _routingRuleExitConditionConstants = require('constants/routingRuleExitConditionsConstants');
        const _i18n = require('i18next');

        let _routeToStrategy = null;
        let _isRouteToModal = false;

        const _defaultRouteToViewModel = () => {
            const routeToViewModel = new RouteToViewModelConstructor(_voiceRouteEnumerations.pleaseSelect, {}, validateRouteToViewModelCallback, routeToOptions);
            routeToViewModel.routeToOptions.subMenu.enabled = false;
            routeToViewModel.routeToStrategy = _routeToStrategy;
            routeToViewModel.routeTo();
            routeToViewModel.routeTitle = _i18n.t("routeTo:atThisPoint");
            routeToViewModel.showRouteToTitle(true);
            routeToViewModel.isChild(true);
            routeToViewModel.isRouteToModal(_isRouteToModal);
            return routeToViewModel;
        };

        const _setRouteTo_newDefaultState = (id, routeToType, parentViewModel) => {
            const routeToViewModel = _defaultRouteToViewModel();
            switch (routeToType) {
                case _voiceRouteEnumerations.user:
                    routeToViewModel.setAnswerTimeoutDropDownMenu_rolloverToUserVoicemailBox(id);
                    break;
                case _voiceRouteEnumerations.userGroup:
                    routeToViewModel.setAnswerTimeoutDropDownMenu_rolloverToUserGroupVoicemailBox(id);
                    break;
                case _voiceRouteEnumerations.prompt:
                    routeToViewModel.setAnswerTimeoutDropDownMenu_hangup();
                    break;
                default:
                    routeToViewModel.setAnswerTimeoutDropDownMenu_noTimeout(id, routeToType);
                    break;
            }
            routeToViewModel.previousRouteToSelection = routeToType;
            routeToViewModel.parentViewModel(parentViewModel);
            return routeToViewModel;
        };

        const _setRouteTo_noTimeout = (id, routeToType, parentViewModel) => {
            const routeToViewModel = _defaultRouteToViewModel();
            routeToViewModel.setAnswerTimeoutDropDownMenu_noTimeout(id, routeToType);
            routeToViewModel.previousRouteToSelection = routeToType;
            routeToViewModel.parentViewModel(parentViewModel);
            return routeToViewModel;
        };

        const _setRouteTo_answerTimeout = (id, routeToType, routingRuleExitCondition, parentViewModel) => {
            let routeToViewModel = null;
            const answerTimeoutInSeconds = routingRuleExitCondition.value;
            const nextRoutingRule = routingRuleExitCondition.nextRoutingRule;

            if ((answerTimeoutInSeconds && nextRoutingRule) || (routeToType === _voiceRouteEnumerations.prompt && nextRoutingRule)) {
                routeToViewModel = new RouteToViewModelConstructor(_voiceRouteEnumerations.routingRule, nextRoutingRule, validateRouteToViewModelCallback, routeToOptions);

                if (nextRoutingRule.routeToVoicemailBoxId) {
                    const voicemailBox = _commonState.get(nextRoutingRule.routeToVoicemailBoxId);
                    if (voicemailBox.ownerId() === id) {
                        switch (routeToType) {
                            case _voiceRouteEnumerations.user:
                                routeToViewModel.setAnswerTimeoutDropDownMenu_rolloverToUserVoicemailBox(id, answerTimeoutInSeconds);
                                break;
                            case _voiceRouteEnumerations.userGroup:
                                routeToViewModel.setAnswerTimeoutDropDownMenu_rolloverToUserGroupVoicemailBox(id, answerTimeoutInSeconds);
                                break;
                            case _voiceRouteEnumerations.prompt:
                                routeToViewModel.setAnswerTimeoutDropDownMenu_hangup();
                                break;
                            default:
                                routeToViewModel.setAnswerTimeoutDropDownMenu_routeTo(answerTimeoutInSeconds, routeToType);
                                break;
                        }
                    } else {
                        routeToViewModel.setAnswerTimeoutDropDownMenu_routeTo(answerTimeoutInSeconds, routeToType);
                    }
                } else {
                    routeToViewModel.setAnswerTimeoutDropDownMenu_routeTo(answerTimeoutInSeconds, routeToType);
                }
            } else if (answerTimeoutInSeconds) {
                routeToViewModel = new RouteToViewModelConstructor(_voiceRouteEnumerations.autoAttendant, { autoAttendantId : "[None]"}, validateRouteToViewModelCallback, routeToOptions);
                if (routeToType === _voiceRouteEnumerations.user || routeToType === _voiceRouteEnumerations.userGroup) {
                    routeToViewModel.answerTimeoutDropDownMenuOptions_addVoicemailBoxRoutes(routeToType);
                }
                routeToViewModel.setAnswerTimeoutDropDownMenu_hangup(answerTimeoutInSeconds, routeToType);
            } else if (routeToType === _voiceRouteEnumerations.prompt) {
                routeToViewModel = new RouteToViewModelConstructor(_voiceRouteEnumerations.autoAttendant, { autoAttendantId : "[None]"}, validateRouteToViewModelCallback, routeToOptions);
                routeToViewModel.setAnswerTimeoutDropDownMenu_hangup(answerTimeoutInSeconds, routeToType);
            } else {
                routeToViewModel = new RouteToViewModelConstructor(_voiceRouteEnumerations.autoAttendant, { autoAttendantId : "[None]"}, validateRouteToViewModelCallback, routeToOptions);
                routeToViewModel.setAnswerTimeoutDropDownMenu_noTimeout(id, routeToType);
            }

            routeToViewModel.previousRouteToSelection = routeToType;
            routeToViewModel.parentViewModel(parentViewModel);
            routeToViewModel.routeToOptions.subMenu.enabled = false;
            routeToViewModel.routeToStrategy = _routeToStrategy;
            routeToViewModel.routeTitle = _i18n.t("routeTo:atThisPoint");
            routeToViewModel.showRouteToTitle(true);
            routeToViewModel.isChild(true);
            return routeToViewModel;
        };


        self.determineSubsequentRoutes = (isNewRoute, routeToStrategy, id, routeToType, routingRuleExitConditions, parentViewModel, isRouteToModal) => {
            _routeToStrategy = routeToStrategy;
            _isRouteToModal = isRouteToModal;

            if (isNewRoute) {
                return _setRouteTo_newDefaultState(id, routeToType, parentViewModel);
            } else {
                if (routingRuleExitConditions.length === 1) {
                    const routingRuleExitCondition = routingRuleExitConditions[0];
                    switch (routingRuleExitCondition.exitType) {
                        case _routingRuleExitConditionConstants.donePlayingAudioFile:
                        case _routingRuleExitConditionConstants.timeoutInSeconds:
                            return _setRouteTo_answerTimeout(id, routeToType, routingRuleExitCondition, parentViewModel);
                        default:
                            throw new Error("unknown exit condition " + routingRuleExitCondition.exitType);
                    }
                } else {
                    return _setRouteTo_noTimeout(id, routeToType, parentViewModel);
                }
            }
        };
    };
});

