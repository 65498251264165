define('constants/systemObjectIconNameConstants',[], function() {
    return {
        autoAttendant: 'autoAttendant',
        blocked: 'blockedContact',
        contact: 'contact',
        device: 'device',
        forwardingNumber: 'forwardingNumber',
        hangup: 'callFlowHangup',
        hostedNumber: 'phoneNumber',
        prompt: 'audioFile',
        ringing: 'callFlowRinging',
        schedule: 'schedule',
        scheduleSegment: 'scheduleSegment',
        subMenu: 'autoAttendantSubMenu',
        tresta: 'logoBadge',
        user: 'user',
        userGroup: 'group',
        voicemail: 'voicemail',
        voicemailBox: 'voicemailBox'
    };
});
