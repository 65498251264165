define('persistence/dataProviders/signupDataProvider',['externalDependencies/clientWebSocket',
    'common/promises/promiseFactory'],
function() {
    return function() {
        let self = this;
        let _promiseFactory = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');

        let clientWebSocket = require('externalDependencies/clientWebSocket');

        self.getById = function(signupSessionId, secretKey) {
            return _promiseFactory.defer(function(promise) {
                var request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey
                };

                clientWebSocket.sendUnAuthenticated("signup", "getSignupSession", request, (response) => {
                    promise.resolve(response);
                });
            });
        };

        self.connectSocket = (signupSessionId, secretKey) => _promiseFactory.defer(promise => {
            const request = {
                signupSessionId: signupSessionId,
                secretKey: secretKey
            };

            clientWebSocket.sendUnAuthenticated("signup", "connectWebsocket", request, (response) => {
                promise.resolve(response);
            });
        });

        self.getAffiliates = (signupSessionId, secretKey) => _promiseFactory.defer((promise) => {
            const request = {
                signupSessionId: signupSessionId,
                secretKey: secretKey
            };

            clientWebSocket.sendUnAuthenticated("signup", "getAffiliates", request, (response) => {
                promise.resolve(response);
            });
        });

        self.init = function () {
            _promiseFactory = new PromiseFactoryConstructor();
        };
    };
});
