define('presentation/settings/autoAttendant/facades/autoAttendantFacade',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/converters/durationTimeFormatter',
    'businessServices/converters/textConverter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/referentialConstraintEnumerations',
    'constants/routingRuleConstants',
    'externalDependencies/clientApi',
    'externalDependencies/clientWebSocket',
    'presentation/common/dateTimeValue',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
    'presentation/settings/autoAttendant/presentationObjects/autoAttendantPresentationObject',
    'presentation/settings/prompts/presentationObjects/promptsPresentationObject',
    'settings/navigationConfiguration',
    ],
    function() {

        const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');
        const integrityWarningMessagesEnumeration = require('constants/referentialConstraintEnumerations');

        const scheduleSocket = require('externalDependencies/clientWebSocket').forApplication("schedule");
        const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");
        const PromptsPresentationObjectConstructor = require('presentation/settings/prompts/presentationObjects/promptsPresentationObject');

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const AutoAttendantPresentationObjectConstructor = require('presentation/settings/autoAttendant/presentationObjects/autoAttendantPresentationObject');
        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const TextConverterConstructor = require('businessServices/converters/textConverter');

        const _commonState = require('common/storage/commonState');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _routingRuleConstants = require('constants/routingRuleConstants');
        const _autoAttendantVoiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _textConverter = new TextConverterConstructor();
        
        const KEY_ORDER = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*'];
        const DEFAULT_MENU_KEY_NAME = "Menu Key";
        const DEFAULT_MENU_KEY_NAMES = ["Menu Key 1", "Menu Key 2", "Menu Key 3", "Menu Key 4", "Menu Key 5", "Menu Key 6", "Menu Key 7", "Menu Key 8", "Menu Key 9", "Menu Key *"];

        let DateTimeValueConstructor = null;
        let _voicePromptAudioFactory = null;
        let _promiseFactory = null;
        let _clientApi = null;
        
        const generateCollapsedAutoAttendantData = (exitConditions) => {
            let collapsedAutoAttendantMenuLabel = "";
            let collapsedMemberIds = [];
            KEY_ORDER.forEach((key) => {
                let exitCondition = exitConditions.find(exitCondition => exitCondition.value === key);
                if (exitCondition) {
                    let menuLabel;
                    if (DEFAULT_MENU_KEY_NAMES.find(defaultMenuKeyName => defaultMenuKeyName === exitCondition.name)) {
                        menuLabel = `${key} ${DEFAULT_MENU_KEY_NAME}`;
                    } else {
                        menuLabel = `${key} ${_textConverter.capitalizeFirstLetter(exitCondition.name)}`;
                    }
            
                    if (collapsedAutoAttendantMenuLabel === "") {
                        collapsedAutoAttendantMenuLabel = menuLabel;
                    } else {
                        collapsedAutoAttendantMenuLabel += `, ${menuLabel}`;
                    }
                    
                    const routingRule = exitCondition.nextRoutingRule;
                    const collapsedMemberId = _generateCollapsedMemberId(routingRule);
                    if (collapsedMemberId !== null) {
                        collapsedMemberIds.push(collapsedMemberId);
                    }
                }
            });
            return {collapsedAutoAttendantMenuLabel, collapsedMemberIds};
        };
        
        const _generateCollapsedMemberId = (routingRule) => {
            switch (routingRule.routingType) {
                case _routingRuleConstants.allUserDevices:
                    return routingRule.routeToUserId;
                case _routingRuleConstants.allUserGroupDevices:
                    return routingRule.routeToUserGroupId;
                case _routingRuleConstants.forwardingNumber:
                    return routingRule.routeToForwardingNumberId;
                case _routingRuleConstants.schedule:
                    return routingRule.routeToScheduleId;
                case _routingRuleConstants.voicemailBox:
                    return routingRule.routeToVoicemailBoxId;
                case _routingRuleConstants.autoAttendant:
                    return routingRule.routeToAutoAttendantId;
                case _routingRuleConstants.subMenu:
                    return _autoAttendantVoiceRouteEnumerations.subMenu;
                case _routingRuleConstants.voicePrompt:
                    if (routingRule.routingRuleExitConditions === null || routingRule.routingRuleExitConditions.length === 0 || routingRule.routingRuleExitConditions[0].nextRoutingRule === null) {
                        return routingRule.routeToVoicePromptId;
                    }
                    return _generateCollapsedMemberId(routingRule.routingRuleExitConditions[0].nextRoutingRule);
                case _routingRuleConstants.systemAudioClip:
                    if (routingRule.routingRuleExitConditions === null || routingRule.routingRuleExitConditions.length === 0 || routingRule.routingRuleExitConditions[0].nextRoutingRule === null) {
                        return routingRule.routeToSystemAudioClipId;
                    }
                    return _generateCollapsedMemberId(routingRule.routingRuleExitConditions[0].nextRoutingRule);
                default:
                    return null;
            }
        };
        
        const _getAutoAttendantCallFlowObjects = () => {
            return _promiseFactory.defer((deferredObject) => {

                let accountCallFlowConstructorPromise = new PromiseFactoryConstructor();
                let availableAutoAttendants = [];
                let availableSchedules = [];
                let availableVoicePrompts = [];

                accountCallFlowConstructorPromise.deferIndefinitely((promise) => {
                    _getAutoAttendants()
                        .done((autoAttendants) => {
                            availableAutoAttendants = autoAttendants;
                            promise.resolve();
                        });
                });

                accountCallFlowConstructorPromise.deferIndefinitely((promise) => {
                    _getSchedules()
                        .done((schedules) => {
                            availableSchedules = schedules;
                            promise.resolve();
                        });
                });

                accountCallFlowConstructorPromise.deferIndefinitely((promise) => {
                    _getVoicePrompts()
                        .done((voicePrompts) => {
                            availableVoicePrompts = voicePrompts;
                            promise.resolve();
                        });
                });


                accountCallFlowConstructorPromise.wait()
                    .fail((error) => {
                        deferredObject.reject(error);
                    })
                    .done(() => {
                        deferredObject.resolve(availableAutoAttendants, availableSchedules, availableVoicePrompts);
                    });
            });
        };

        const _getAutoAttendants = () => {
            return _promiseFactory.defer((deferredObject) => {
                _clientApi.call("settings/autoAttendants/retrieveAll", "POST", {}, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        const autoAttendants = result.data;
                        const autoAttendantPresentationList = autoAttendants.map(function (autoAttendant) {
                            const autoAttendantPresentationObject = new AutoAttendantPresentationObjectConstructor();
                            autoAttendantPresentationObject.name(autoAttendant.name);
                            autoAttendantPresentationObject.accountAutoAttendantId = autoAttendant.accountAutoAttendantId;
                            autoAttendantPresentationObject.accountVoicePromptId = autoAttendant.accountVoicePromptId;
                            autoAttendantPresentationObject.systemAudioClipId = autoAttendant.systemAudioClipId;
                            autoAttendantPresentationObject.routingRule = autoAttendant.routingRule;
                            autoAttendantPresentationObject.createdDateTime = new DateTimeValueConstructor(autoAttendant.createdDateTime);
                            autoAttendantPresentationObject.modifiedDateTime = new DateTimeValueConstructor(autoAttendant.updatedDateTime);
                            autoAttendantPresentationObject.editAutoAttendantUrl = _navigationConfiguration.routesById.editAutoAttendant.baseUrl + autoAttendant.accountAutoAttendantId;
                         
                            const {collapsedAutoAttendantMenuLabel, collapsedMemberIds} = generateCollapsedAutoAttendantData(autoAttendant.routingRule.routingRuleExitConditions);
                            autoAttendantPresentationObject.collapsedAutoAttendantMenuLabel(collapsedAutoAttendantMenuLabel);
                            autoAttendantPresentationObject.collapsedMemberIds(collapsedMemberIds);
                            return autoAttendantPresentationObject;
                        });
                        deferredObject.resolve(autoAttendantPresentationList);
                        deferredObject.resolve(result);
                    });
            });
        };

        const _getSchedules = () => {
            return _promiseFactory.defer((deferredObject) => {

                scheduleSocket.send("retrieveAll", {}, (accountSchedules, err) => {
                    if (err) {
                        deferredObject.reject(new Error(err));
                    } else {
                        deferredObject.resolve(accountSchedules);
                    }
                });
            });
        };

        const _getVoicePrompts = () => {
            return _promiseFactory.defer(function (promise) {

                promptSocket.send("retrieveAll", {}, (accountVoicePrompts, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }
                    const displayVoicePrompts = accountVoicePrompts.map((currentPrompt) => {
                        let displayPrompt = new PromptsPresentationObjectConstructor();
                        displayPrompt.isSystemPrompt = false;
                        displayPrompt.voicePromptId = currentPrompt.accountVoicePromptId;
                        displayPrompt.createdDateTime = new DateTimeValueConstructor(currentPrompt.createdDateTime);
                        displayPrompt.modifiedDateTime = new DateTimeValueConstructor(currentPrompt.updatedDateTime);
                        displayPrompt.promptName = currentPrompt.name;

                        if (currentPrompt.conversionStatus === 1) {
                            const currentVoicePrompt = _getCurrentVoicePrompt(currentPrompt.accountVoicePromptId);
                            displayPrompt.conversionComplete = ko.observable(true);
                            displayPrompt.conversionInProgress = ko.observable(false);
                            displayPrompt.currentVoicePrompt = ko.observable(currentVoicePrompt);
                        } else {
                            const emptyVoicePrompt = _getEmptyVoicePrompt();
                            displayPrompt.conversionComplete = ko.observable(false);
                            displayPrompt.conversionInProgress = ko.observable(true);
                            displayPrompt.currentVoicePrompt = ko.observable(emptyVoicePrompt);
                        }

                        if (currentPrompt.durationInMilliseconds && displayPrompt.conversionComplete) {
                            displayPrompt.currentVoicePrompt().duration = ko.observable(_getFormattedDurationProperty(currentPrompt.durationInMilliseconds / 1000));
                        } else {
                            displayPrompt.currentVoicePrompt().duration = ko.observable("Unknown");
                        }
                        displayPrompt.editPromptUrl = _navigationConfiguration.routesById.editPrompt.baseUrl + currentPrompt.accountVoicePromptId;

                        return displayPrompt;
                    });

                    promptSocket.send("retrieveSystemPrompts", {}, (systemVoicePrompts) => {
                        const displaySystemPrompts = systemVoicePrompts.map((systemPrompt) => {
                            let displaySystemPrompt = new PromptsPresentationObjectConstructor();
                            displaySystemPrompt.isSystemPrompt = true;
                            displaySystemPrompt.systemAudioClipId = systemPrompt.systemAudioClipId;
                            displaySystemPrompt.modifiedDateTime = null;
                            displaySystemPrompt.promptName = systemPrompt.name;
                            displaySystemPrompt.conversionComplete = ko.observable(true);
                            displaySystemPrompt.conversionInProgress = ko.observable(false);
                            displaySystemPrompt.currentVoicePrompt(_getCurrentSystemPrompt(systemPrompt.systemAudioClipId));
                            displaySystemPrompt.currentVoicePrompt().duration = ko.observable(_getFormattedDurationProperty(systemPrompt.durationInMilliseconds / 1000));
                            return displaySystemPrompt;
                        });

                        const allPrompts = displayVoicePrompts.concat(displaySystemPrompts);
                        promise.resolve(allPrompts);
                    });
                });
            });
        };

        const _getFormattedDurationProperty = (promptDurationInSeconds) => {
            let formatter = new TimeFormatterConstructor();
            let formattedTimeDuration = formatter.formatTotalSeconds(promptDurationInSeconds);
            return formattedTimeDuration;
        };

        const _getCurrentVoicePrompt = (voicePromptId) => {
            return _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
        };

        const _getCurrentSystemPrompt = (systemPromptId) => {
            return _voicePromptAudioFactory.getSystemVoicePrompt(systemPromptId);
        };

        const _getEmptyVoicePrompt = () => {
            return _voicePromptAudioFactory.getEmptyVoicePrompt();
        };

        const _onNewPromptConverted = (callback) => {

            promptSocket.subscribeToEvent("voicePromptMp3Converted", (eventData) => {
                const {accountVoicePromptId, durationInMilliseconds} = eventData;
                let updatedAudioData = _getCurrentVoicePrompt(accountVoicePromptId);
                let formattedTimeDuration = _getFormattedDurationProperty(durationInMilliseconds / 1000);
                updatedAudioData.duration = ko.observable(formattedTimeDuration);

                callback({
                    accountVoicePromptId: accountVoicePromptId,
                    audioData: updatedAudioData,
                });
            });
        };

        const _copyAutoAttendant = (autoAttendantId, autoAttendantName) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    accountAutoAttendantId: autoAttendantId,
                    name: autoAttendantName
                };
                _clientApi.call("settings/autoAttendants/copy", "POST", request, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        deferredObject.resolve(result.data);
                    });
            });
        };

        const  _deleteAutoAttendant = (autoAttendantId) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    accountAutoAttendantId: autoAttendantId
                };
                _clientApi.call("settings/autoAttendants/delete", "POST", request, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        const response = result.data;
                        if (response.status === "success") {
                            deferredObject.resolve();
                        } else {
                            const integrityWarnings = getIntegrityWarnings(response.constraints);
                            deferredObject.reject({constraintViolations: integrityWarnings});
                        }
                    });
            });
        };

        const _getConstraints = (autoAttendantId) => {
            return _promiseFactory.defer(function(deferredObject) {
                const request = {
                    accountAutoAttendantId: autoAttendantId
                };
                _clientApi.call("settings/autoAttendants/constraints", "POST", request, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        const integrityWarnings = getIntegrityWarnings(result.data);
                        deferredObject.resolve(integrityWarnings);
                    });
            });
        };

        const getIntegrityWarnings = (constraints) => {
            return constraints.map((constraint) => {
                const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                integrityWarning.entityText = integrityWarningMessagesEnumeration[constraint.type].entityText;
                integrityWarning.entityName = constraint.name;
                integrityWarning.icon = integrityWarningMessagesEnumeration[constraint.type].icon;
                integrityWarning.message = integrityWarningMessagesEnumeration[constraint.type].message;
                return integrityWarning;
            });
        };

        const _isAutoAttendantNameUnique = (autoAttendantId, autoAttendantName) => {
            return _promiseFactory.defer((deferredObject) => {
                const autoAttendants = _commonState.autoAttendants();
                const isValid = autoAttendants.every((autoAttendant) => {
                    if (autoAttendantId !== autoAttendant.id) {
                        return autoAttendantName.trim().toLowerCase() !== autoAttendant.name().trim().toLowerCase();
                    }
                    return true;
                });
                deferredObject.resolve(isValid);
            });
        };

        const _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();

            const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
            _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
            _voicePromptAudioFactory.init();

            DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        };

        const _dispose = () => {
            promptSocket.disposeOfEvents();
            scheduleSocket.disposeOfEvents();
        };

        return function() {
            const self = this;

            self.init = _init;

            self.getAutoAttendantCallFlowObjects = _getAutoAttendantCallFlowObjects;
            self.deleteAutoAttendant = _deleteAutoAttendant;
            self.getAutoAttendants = _getAutoAttendants;
            self.copyAutoAttendant = _copyAutoAttendant;
            self.getConstraints = _getConstraints;
            self.getSchedules = _getSchedules;
            self.getVoicePrompts = _getVoicePrompts;
            self.isAutoAttendantNameUnique = _isAutoAttendantNameUnique;
            self.onNewPromptConverted = _onNewPromptConverted;
            self.dispose = _dispose;
        };
    });

