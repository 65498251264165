define('presentation/messages/facades/conversationFacade',[
    'businessServices/blocking/blockingStateSingleton',
    'businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton',
    'common/converters/phoneNumberFormatter',
    'constants/deliveryStatus',
    'persistence/dataProviders/webMessagingDataProvider',
    'presentation/messages/presentationObjects/phoneNumberDetailsPresentationObject',
    'presentation/messages/dataSources/webMessagingDataSource',
    'settings/navigationConfiguration'
], function(
    /** @type import('businessServices/blocking/blockingStateSingleton') */
    _blockingState,
    /** @type import('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton') */
    _smsPhoneNumberOptOutsState,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatterConstructor,
    /** @type typeof import('constants/deliveryStatus') */
    DeliveryStatus,
    /** @type typeof import('persistence/dataProviders/webMessagingDataProvider') */
    WebMessagingDataProviderConstructor,
    /** @type typeof import('presentation/messages/presentationObjects/phoneNumberDetailsPresentationObject') */
    PhoneNumberDetailsPresentationObjectConstructor,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    _webMessagingDataSource,
    /** @type typeof import('settings/navigationConfiguration') */
    navigationConfiguration
) {
    return function() {
        /**@type {ConversationFacade}*/
        const self = this;
        /** @typedef {PhoneNumberDetailsPresentationObjectConstructor["prototype"]} PhoneNumberDetailsPresentationObject */
        /** @typedef {import('presentation/messages/facades/conversationFacade')} ConversationFacade */
        /** @typedef {import('common/promises/promiseFactory')} PromiseFactory */

        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        /** @type {PromiseFactory} */
        let _promiseFactory = null;
        /** @type WebMessagingDataProviderConstructor["prototype"]  */
        let _webMessagingDataProvider = null;

        /** @type ConversationFacade["init"] */
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
            _webMessagingDataProvider = new WebMessagingDataProviderConstructor();
        };

        /** @type ConversationFacade["dispose"] */
        const _dispose = () => {
            _webMessagingDataProvider.disposeOfEvents();
        };

        /** @type ConversationFacade["getSmsConversation"] */
        const _getSmsConversation = (conversationId, messageCount, jumpToMessageId = null) => {
            const notFound = navigationConfiguration.routesById.conversationNotFound.urlSuffix.substring(1);
            const jumpToMessageIdRequest = jumpToMessageId && !jumpToMessageId.includes(notFound) ? jumpToMessageId : null;

            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getSmsConversation(conversationId, messageCount, jumpToMessageIdRequest)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getSmsConversations"] */
        const _getSmsConversations = () => {
            return _webMessagingDataSource.smsConversations();
        };

        /** @type ConversationFacade["getWebMessagingNumbers"] */
        const _getWebMessagingNumbers = () => {
            return _webMessagingDataSource.webMessagingNumbers;
        };

        /** @type ConversationFacade["getSmsConversationMessagesBefore"] */
        const _getSmsConversationMessagesBefore = (conversationId, messageCount, beforeDateTime) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getSmsConversationMessagesBefore(conversationId, messageCount, beforeDateTime)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getSmsConversationMessagesSince"] */
        const _getSmsConversationMessagesSince = (conversationId, sinceDateTime, messageCount) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getSmsConversationMessagesSince(conversationId, sinceDateTime, messageCount)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getSmsConversationMessageAttachment"] */
        const _getSmsConversationMessageAttachment = (smsConversationMessageAttachmentId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getSmsConversationMessageAttachment(smsConversationMessageAttachmentId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getSmsConversationMessageAttachmentDraft"] */
        const _getSmsConversationMessageAttachmentDraft = (smsConversationMessageAttachmentId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getSmsConversationMessageAttachmentDraft(smsConversationMessageAttachmentId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getMap"] */
        const _getMap = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getMap(request)
                    .fail(deferredObject.reject)
                    .done(({ signedUrl }) => {
                        deferredObject.resolve(signedUrl);
                    });
                });
        };

        /** @type ConversationFacade["getMatchingSmsConversation"] */
        const _getMatchingSmsConversation = (sendFromHostedNumberId, recipientPhoneNumbers) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getMatchingSmsConversation(sendFromHostedNumberId, recipientPhoneNumbers)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getAutoCompleteRecipients"] */
        const _getAutoCompleteRecipients = (accountHostedNumberId) => {
            const _filterNumbers = (/** @type {PhoneNumberDetailsPresentationObject[]} */numbersArray) => {
                const filtered = [];
                const map = new Map();

                for (const item of numbersArray) {
                    if (!map.has(item.phoneNumber)) {
                        map.set(item.phoneNumber, true);
                        filtered.push(Object.assign({}, item));
                    }
                }

                return filtered;
            };

            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getAutoCompleteRecipients(accountHostedNumberId)
                    .fail(deferredObject.reject)
                    .done((response) => {
                        const {additionalRecipients, userConversations} = response;
                        /** @type {PhoneNumberDetailsPresentationObject[]} */
                        let allOptions = [];

                        if (additionalRecipients.length > 0) {
                            additionalRecipients.forEach((recipient) => {
                                const newRecipient = _createPhoneNumberDetailsPresentationObjectForAutoComplete(recipient);
                                allOptions.push(newRecipient);
                            });
                        }

                        if (userConversations.length > 0) {
                            userConversations.forEach((conversation) => {
                                    const numberOfRecipients = conversation.recipients.length;

                                    if (numberOfRecipients === 1) {
                                        conversation.recipients.forEach((recipient) => {
                                            const newRecipient = _createPhoneNumberDetailsPresentationObjectForAutoComplete(recipient);
                                            allOptions.push(newRecipient);
                                        });
                                    }

                                    if (numberOfRecipients > 1) {
                                        _addEachRecipientToAutoCompleteOptions(conversation.recipients, allOptions);
                                        //TODO - will be handled in a different ticket
                                        // _addRecipientsGroupToAutoCompleteOptions(conversation.recipients, allOptions);
                                    }
                                }
                            );
                        }

                        allOptions = _filterNumbers(allOptions);
                        deferredObject.resolve(allOptions);
                    });
            });
        };

        const _createPhoneNumberDetailsPresentationObjectForAutoComplete = (/** @type {IAutoCompleteRecipient} */recipient) => {
            const phoneNumberDetailsPresentationObject = new PhoneNumberDetailsPresentationObjectConstructor();
            phoneNumberDetailsPresentationObject.id = recipient.phoneNumber.replace(/\D/g, '');
            phoneNumberDetailsPresentationObject.phoneNumber(_phoneNumberFormatter.toNumericDefault(recipient.phoneNumber));
            phoneNumberDetailsPresentationObject.buildMeta(recipient.isShortCode, recipient.isTollFree, recipient.location);
            phoneNumberDetailsPresentationObject.isAutoCompleteOption = true;
            return phoneNumberDetailsPresentationObject;
        };

        /**
         * @param {IAutoCompleteRecipient[]} conversationRecipients
         * @param {PhoneNumberDetailsPresentationObject[]} allOptionsArray
         */
        const _addEachRecipientToAutoCompleteOptions = (conversationRecipients, allOptionsArray) => {
            conversationRecipients.forEach((recipient) => {
                const newRecipient = _createPhoneNumberDetailsPresentationObjectForAutoComplete(recipient);
                allOptionsArray.push(newRecipient);
            });
        };

        /** @type ConversationFacade["onSmsUserIsTyping"] */
        const _onSmsUserIsTyping = (callback) => {
            _webMessagingDataProvider.onSmsUserIsTyping(callback);
        };

        /** @type ConversationFacade["getDraftConversation"] */
        const _getDraftConversation = (conversationDraftId, hostedNumbers) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getDraftConversation(conversationDraftId)
                    .fail(deferredObject.reject)
                    .done((draft) => {
                        const foundHostedPhoneNumber = hostedNumbers.find((hostedNumber) => {
                            return hostedNumber.accountHostedNumberId === draft.accountHostedNumberId;
                        });
                        const fromPhoneNumber = foundHostedPhoneNumber === undefined ? '' : foundHostedPhoneNumber.hostedPhoneNumber;
                        for (const recipient of draft.recipients) {
                            const { phoneNumber, city, region } = recipient;
                            const isBlockedContact = _blockingState.blockedPhoneNumbers().some((x) => {
                                return x.phoneNumber === _phoneNumberFormatter.toEOneSixFour(phoneNumber);
                            });
                            const isPhoneNumberOptedOut = _smsPhoneNumberOptOutsState.getOptedOutPhoneNumbers(fromPhoneNumber, [_phoneNumberFormatter.toEOneSixFour(phoneNumber)]).length > 0;
                            recipient.meta = _buildMetaForNumbersNotInOptions(phoneNumber, city, region);
                            recipient.avatar = _phoneNumberFormatter.isShortCode(phoneNumber.replace(/\D/g, '')) ? 'phoneNumber' : 'contact';
                            recipient.phoneNumber = _phoneNumberFormatter.toNumericDefault(phoneNumber);
                            recipient.isBlockedContact = ko.pureComputed(() => {
                                return isBlockedContact;
                            });
                            recipient.isPhoneNumberOptedOut = ko.pureComputed(() => {
                                return isPhoneNumberOptedOut;
                            });
                            recipient.isRecipientBlockedOrUnsubscribed = ko.pureComputed(() => {
                                return isBlockedContact || isPhoneNumberOptedOut;
                            });
                        }

                        deferredObject.resolve(draft);
                    });
            });
        };

        /** @type ConversationFacade["getPhoneNumberLocation"] */
        const _getPhoneNumberLocation = (phoneNumber) => {
            return _promiseFactory.defer((promise) => {
                _webMessagingDataProvider.getPhoneNumberLocation(phoneNumber)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        /** @type ConversationFacade["buildMetaForNumbersNotInOptions"] */
        const _buildMetaForNumbersNotInOptions = (ani, city, state) => {
            if (city && state) {
                return `${city}, ${state}`;
            }
            if (_phoneNumberFormatter.isTollFree(ani)) {
                return "Toll-Free";
            }
            if (_phoneNumberFormatter.isShortCode(ani.replace(/\D/g, ''))) {
                return "Short Code";
            }
            return "Unknown Location";
        };

        /** @type ConversationFacade["updateConversationDraftRecipients"] */
        const _updateConversationDraftRecipients = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.updateConversationDraftRecipients(request)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["updateConversationDraftAttachments"] */
        const _updateConversationDraftAttachments = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.updateConversationDraftAttachments(request)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["updateConversationDraftContent"] */
        const _updateConversationDraftContent = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.updateConversationDraftContent(request)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["createConversationDraftMessage"] */
        const _createConversationDraftMessage = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.createConversationDraftMessage(request)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["createDraftConversation"] */
        const _createDraftConversation = (recipients) => {
            const formatted = recipients ? recipients.map(n => _phoneNumberFormatter.toNumbers(n)) : [];
            return _promiseFactory.defer((promise) => {
                _webMessagingDataProvider.createDraftConversation(formatted)
                    .fail(promise.reject)
                    .done(({smsConversationDraftId}) => {
                        promise.resolve(smsConversationDraftId);
                    });
            });
        };

        /** @type ConversationFacade["createDraftAttachmentConversation"] */
        const _createDraftAttachmentConversation = (attachmentId) => {
            return _promiseFactory.defer((promise) => {
                _webMessagingDataProvider.createDraftAttachmentConversation(attachmentId)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        /** @type ConversationFacade["sendNewSms"] */
        const _sendNewSms = (sendNewSmsRequest) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.sendNewSms(sendNewSmsRequest)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["sendSmsReply"] */
        const _sendSmsReply = (smsConversationId, message, attachmentIds) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.sendSmsReply(smsConversationId, message, attachmentIds)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["updateConversationDraftSendFromNumber"] */
        const _updateConversationDraftSendFromNumber = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.updateConversationDraftSendFromNumber(request)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["publishSmsUserIsTyping"] */
        const _publishSmsUserIsTyping = (smsConversationId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.publishSmsUserIsTyping(smsConversationId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["deleteDraftConversation"] */
        const _deleteDraftConversation = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.deleteDraftConversation(request)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["archiveConversation"] */
        const _archiveConversation = (smsConversationId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.archiveConversation(smsConversationId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["updateSmsConversationLastViewedDate"] */
        const _updateSmsConversationLastViewedDate = (smsConversationId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.updateSmsConversationLastViewedDate(smsConversationId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getSmsConversationNewMessagesCount"] */
        const _getSmsConversationNewMessagesCount = (smsConversationId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getSmsConversationNewMessagesCount(smsConversationId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["deleteMessage"] */
        const _deleteMessage = (conversationId, messageId) => {
            return _webMessagingDataProvider.deleteMessage(conversationId, messageId);
        };

        /** @type ConversationFacade["retryMessage"] */
        const _retryMessage = (conversationId, messageId) => {
            return _webMessagingDataProvider.retryMessage(conversationId, messageId);
        };

        /** @type ConversationFacade["createSmsConversationMessageAttachments"] */
        const _createSmsConversationMessageAttachments = (createRequests) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.createSmsConversationMessageAttachments(createRequests)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["getAttachmentDownloadFilePath"] */
        const _getAttachmentDownloadFilePath = (attachmentId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getAttachmentDownloadFilePath(attachmentId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["cancelAttachment"] */
        const _cancelAttachment = (smsConversationMessageAttachmentId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.cancelAttachment(smsConversationMessageAttachmentId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["onSmsPermissionChanged"] */
        const _onSmsPermissionChanged = (callBack) => {
            _webMessagingDataProvider.onSmsPermissionChanged(callBack);
        };

        /** @type ConversationFacade["onSmsConversationMessageDeleted"] */
        const _onSmsConversationMessageDeleted = (callBack) => {
            _webMessagingDataProvider.onSmsConversationMessageDeleted(callBack);
        };

        /** @type ConversationFacade["onSmsConversationMessage"] */
        const _onSmsConversationMessage = (callBack) => {
            _webMessagingDataProvider.onSmsConversationMessageReceived((receivedInfo) => {
                callBack({
                    accountId: receivedInfo.accountId,
                    smsConversationId: receivedInfo.smsConversationId,
                    smsConversationMessageId: receivedInfo.smsConversationMessageId,
                    deliveryStatus: DeliveryStatus.Received
                });
            });

            _webMessagingDataProvider.onSmsConversationMessageSent((sentInfo) => {
                callBack({
                    accountId: sentInfo.accountId,
                    smsConversationId: sentInfo.smsConversationId,
                    smsConversationMessageId: sentInfo.smsConversationMessageId,
                    deliveryStatus: DeliveryStatus.Sending,
                    userId: sentInfo.userId
                });
            });

            _webMessagingDataProvider.onSmsConversationMessageUpdated(callBack);
        };

        /** @type ConversationFacade["markConversationAsNew"] */
        const _markConversationAsNew = (smsConversationId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.markConversationAsNew(smsConversationId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["updateSmsConversationName"] */
        const _updateSmsConversationName = (smsConversationId, conversationName) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.updateSmsConversationName(smsConversationId, conversationName)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        /** @type ConversationFacade["onSmsConversationNameChanged"] */
        const _onSmsConversationNameChanged = (callback) => {
            _webMessagingDataProvider.onSmsConversationNameChanged((event) => {
                callback(event);
            });
        };

        /** @type ConversationFacade["onSmsConversationUserAssociationChanged"] */
        const _onSmsConversationUserAssociationChanged = (callback) => {
            _webMessagingDataProvider.onSmsConversationUserAssociationChanged((event) => {
                callback(event);
            });
        };

        /** @type ConversationFacade["onSmsConversationMessageAttachmentUpdated"] */
        const _onSmsConversationMessageAttachmentUpdated = (callback) => {
            _webMessagingDataProvider.onSmsConversationMessageAttachmentUpdated((event) => {
                callback(event);
            });
        };

        /** @type ConversationFacade["onUrlPreviewConversionCompleted"] */
        const _onUrlPreviewConversionCompleted = (callback) => {
            _webMessagingDataProvider.onUrlPreviewConversionCompleted((event) => {
                callback(event);
            });
        };

        /** @type ConversationFacade["getMessageUrlPreview"] */
        const _getMessageUrlPreview = (smsConversationMessageUrlId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getMessageUrlPreview(smsConversationMessageUrlId)
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        self.init = _init;
        self.cancelAttachment = _cancelAttachment;
        self.createDraftConversation = _createDraftConversation;
        self.createDraftAttachmentConversation = _createDraftAttachmentConversation;
        self.dispose = _dispose;
        self.deleteMessage = _deleteMessage;
        self.getMap = _getMap;
        self.retryMessage = _retryMessage;
        self.archiveConversation = _archiveConversation;
        self.deleteDraftConversation = _deleteDraftConversation;
        self.getSmsConversation = _getSmsConversation;
        self.getDraftConversation = _getDraftConversation;
        self.getWebMessagingNumbers = _getWebMessagingNumbers;
        self.getAutoCompleteRecipients = _getAutoCompleteRecipients;
        self.getSmsConversationMessagesBefore = _getSmsConversationMessagesBefore;
        self.getSmsConversationMessagesSince = _getSmsConversationMessagesSince;
        self.getSmsConversationMessageAttachment = _getSmsConversationMessageAttachment;
        self.getSmsConversationMessageAttachmentDraft = _getSmsConversationMessageAttachmentDraft;
        self.getMatchingSmsConversation = _getMatchingSmsConversation;
        self.buildMetaForNumbersNotInOptions = _buildMetaForNumbersNotInOptions;
        self.getPhoneNumberLocation = _getPhoneNumberLocation;
        self.updateConversationDraftRecipients = _updateConversationDraftRecipients;
        self.updateConversationDraftAttachments = _updateConversationDraftAttachments;
        self.updateConversationDraftContent = _updateConversationDraftContent;
        self.createConversationDraftMessage = _createConversationDraftMessage;
        self.updateConversationDraftSendFromNumber = _updateConversationDraftSendFromNumber;
        self.onSmsUserIsTyping = _onSmsUserIsTyping;
        self.publishSmsUserIsTyping = _publishSmsUserIsTyping;
        self.getSmsConversations = _getSmsConversations;
        self.sendNewSms = _sendNewSms;
        self.sendSmsReply = _sendSmsReply;
        self.updateSmsConversationLastViewedDate = _updateSmsConversationLastViewedDate;
        self.onSmsPermissionChanged = _onSmsPermissionChanged;
        self.getSmsConversationNewMessagesCount = _getSmsConversationNewMessagesCount;
        self.createSmsConversationMessageAttachments = _createSmsConversationMessageAttachments;
        self.getAttachmentDownloadFilePath = _getAttachmentDownloadFilePath;
        self.onSmsConversationMessageDeleted = _onSmsConversationMessageDeleted;
        self.onSmsConversationMessage = _onSmsConversationMessage;
        self.markConversationAsNew = _markConversationAsNew;
        self.updateSmsConversationName = _updateSmsConversationName;
        self.onSmsConversationNameChanged = _onSmsConversationNameChanged;
        self.onSmsConversationUserAssociationChanged = _onSmsConversationUserAssociationChanged;
        self.onSmsConversationMessageAttachmentUpdated = _onSmsConversationMessageAttachmentUpdated;
        self.onUrlPreviewConversionCompleted = _onUrlPreviewConversionCompleted;
        self.getMessageUrlPreview = _getMessageUrlPreview;
    };
});

