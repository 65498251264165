define('presentation/messages/utils/conversationMediaManager',[

], function() {
    return function (
        /** @type string */
        conversationId,
        /** @type import('presentation/messages/facades/conversationMediaFacade') */
        conversationMediaFacade,
        /** @type boolean */
        isDraft
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationMessageViewModel')} ConversationMessageViewModel */
        /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */
        /** @typedef {import('presentation/messages/utils/conversationMediaManager')} ConversationMediaManager */

        /** @type {ConversationMediaManager}*/
        const self = this;

        // TODO - 2.3.0: figure out how to make sure the messages manager and media manager can stay in sync without creating excessive load or huge page sizes
        const PAGE_SIZE = 100;

        /** @type KnockoutObservable<boolean>*/
        const _isLoading = ko.observable();
        const _isInitialized = ko.observable(false);
        const _isGettingNextPage = ko.observable();
        const _haveReachedEnd = ko.observable(false);

        // newest to oldest
        /** @type {KnockoutObservableArray<ConversationMessageAttachmentViewModel>} */
        const _attachments = ko.observableArray([]);

        /** @type {Map<string, ConversationMessageViewModel>} */
        const _messageIdToMessage = new Map();

        let _recipients;
        let _hostedPhoneNumber;

        //#region private methods

        const _attachmentComparer = (a, b) => {
            if (a.createdDateTime() === b.createdDateTime()) {
                if (a.sequence() === b.sequence()) {
                    return 0;
                }

                if (a.sequence() > b.sequence()) {
                    return 1;
                }
                return -1;
            }

            if (a.createdDateTime() > b.createdDateTime()) {
                return -1;
            }
            return 1;
        };

        const _isNewMessage = (message) => {
            return !_messageIdToMessage.has(message.smsConversationMessageId);
        };

        const _getNextAttachmentsPage = () => {
            if (_isGettingNextPage() === true || _haveReachedEnd() || isDraft) {
                return;
            }

            _isGettingNextPage(true);

            const currentMessageCount = _messageIdToMessage.size;

            conversationMediaFacade.getConversationSmsMessagesWithAttachments(conversationId, PAGE_SIZE, currentMessageCount)
                .then((result) => {
                    if (!result) {
                        _isGettingNextPage(false);
                        return;
                    }

                    const orderedAttachments = [];

                    if (result.messages.length < PAGE_SIZE) {
                        _haveReachedEnd(true);
                    }

                    for (let message of result.messages.filter(_isNewMessage)) {
                        _messageIdToMessage.set(message.smsConversationMessageId, message);
                        orderedAttachments.push.apply(orderedAttachments, message.attachments());
                    }

                    const updatedAttachments = _attachments().concat(orderedAttachments);

                    _attachments(updatedAttachments);
                    _isGettingNextPage(false);
                });
        };

        const _onMessage = ({smsConversationId}) => {
            // get the first 10 messages with attachments and load them up into here (this should be ok for now)
            if (smsConversationId !== conversationId || isDraft) {
                return;
            }

            conversationMediaFacade.getConversationSmsMessagesWithAttachments(conversationId, PAGE_SIZE, 0)
                .then(result => {
                    if (!result) {
                        return;
                    }

                    const orderedAttachments = [];

                    for (let message of result.messages.filter(_isNewMessage)) {
                        _messageIdToMessage.set(message.smsConversationMessageId, message);
                        orderedAttachments.push.apply(orderedAttachments, message.attachments());
                    }

                    const updatedAttachments = orderedAttachments.concat(_attachments());

                    _attachments(updatedAttachments);
                });
        };

        //#endregion

        self.attachments = ko.pureComputed(() => {
            return _attachments().sort(_attachmentComparer);
        });

        self.isLoading = ko.pureComputed(() => {
            return _isLoading();
        });

        /** @type {ConversationMediaManager["getAttachmentById"]}*/
        self.getAttachmentById = (attachmentId) => {
            return _attachments().find(attachment => attachment.attachmentId() === attachmentId);
        };

        /** @type {ConversationMediaManager["getMessageById"]}*/
        self.getMessageById = (messageId) => {
            return _messageIdToMessage.get(messageId);
        };

        /** @type {ConversationMediaManager["getNextPage"]}*/
        self.getNextPage = () => {
            _getNextAttachmentsPage();
        };

        /** @type {ConversationMediaManager["dispose"]}*/
        self.dispose = () => {
            conversationMediaFacade.dispose();
        };

        /** @type {ConversationMediaManager["init"]}*/
        self.init = () => {
            if (_isInitialized() === true || isDraft) {
                return;
            }

            _isLoading(true);

            conversationMediaFacade.getConversationSmsMessagesWithAttachments(conversationId, PAGE_SIZE, 0)
                .then((result) => {
                    if (!result) {
                        _isLoading(false);
                        _isInitialized(true);
                        return;
                    }

                    const orderedAttachments = [];

                    if (result.messages.length < PAGE_SIZE) {
                        _haveReachedEnd(true);
                    }

                    for (let message of result.messages) {
                        _messageIdToMessage.set(message.smsConversationMessageId, message);
                        orderedAttachments.push.apply(orderedAttachments, message.attachments());
                    }

                    _attachments(orderedAttachments);
                    _recipients = result.recipients;
                    _hostedPhoneNumber = result.hostedPhoneNumber;
                    _isLoading(false);
                    _isInitialized(true);
                });

            conversationMediaFacade.onSmsConversationMessage(_onMessage);
        };
    };
});
