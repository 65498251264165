define('presentation/settings/userGroup/viewModels/reactivateUserGroupModalViewModel',['common/promises/promiseFactory',
        'presentation/settings/userGroup/facades/reactivateUserGroupFacade',
], function () {
    return function (userGroupPresentationObject) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _facade;

        const _groupId = userGroupPresentationObject.userGroupId;

        self.userGroupPresentationObject = userGroupPresentationObject;

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((deferredResult) => {
                _facade.reactivateUserGroup(_groupId)
                    .fail(deferredResult.reject)
                    .done(deferredResult.resolve);
            });
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/userGroup/facades/reactivateUserGroupFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {

            return _promiseFactory.wait();
        };
    };
});

