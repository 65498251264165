define('common/time/timeSpan',[], function () {
    return function () {
        /** @typedef {import('common/time/timeSpan')} TimeSpan */

        /** @type {TimeSpan} */
        const self = this;

        let __timeInMiliseconds = 0;

        const _setTime = function(/** @type {number} */miliseconds) {
            __timeInMiliseconds = miliseconds;
        };

        const _getTime = function() {
            return __timeInMiliseconds;
        };

        /** @type {TimeSpan['fromMiliseconds']} */
        const _fromMiliseconds = function(miliseconds) {
            _setTime(miliseconds);
        };

        /** @type {TimeSpan['fromSeconds']} */
        const _fromSeconds = function(seconds) {
            _fromMiliseconds(seconds * 1000);
        };

        /** @type {TimeSpan['fromMinutes']} */
        const _fromMinutes = function (minutes) {
            _fromSeconds(minutes * 60);
        };

        /** @type {TimeSpan['fromHours']} */
        const _fromHours = function (hours) {
            _fromMinutes(hours * 60);
        };

        /** @type {TimeSpan['addMiliseconds']} */
        const _addMiliseconds = function(milisecondsToAdd) {
            const currentMiliseconds = _getTime();

            const newMiliseconds = currentMiliseconds + milisecondsToAdd;
            _setTime(newMiliseconds);
        };

        /** @type {TimeSpan['addSeconds']} */
        const _addSeconds = function(secondsToAdd) {
            self.addMiliseconds(secondsToAdd * 1000);
        };

        /** @type {TimeSpan['addMinutes']} */
        const _addMinutes = function(minutesToAdd) {
            self.addSeconds(minutesToAdd * 60);
        };

        /** @type {TimeSpan['addHours']} */
        const _addHours = function(hoursToAdd) {
            self.addMinutes(hoursToAdd * 60);
        };

        /** @type {TimeSpan['seconds']} */
        const _getSeconds = function() {
            const miliseconds = _getTime();
            const totalSeconds = miliseconds / 1000;
            const seconds = Math.floor(totalSeconds % 60);
            return seconds;
        };

        /** @type {TimeSpan['minutes']} */
        const _getMinutes = function() {
            const miliseconds = _getTime();
            const totalSeconds = miliseconds / 1000;
            const totalMinutes = totalSeconds / 60;
            const minutes = Math.floor(totalMinutes % 60);
            return minutes;
        };

        /** @type {TimeSpan['hours']} */
        const _getHours = function() {
            const miliseconds = _getTime();
            const totalSeconds = miliseconds / 1000;
            const totalMinutes = totalSeconds / 60;
            const totalHours = totalMinutes / 60;
            const hours = Math.floor(totalHours % 24);
            return hours;
        };

        /** @type {TimeSpan['getTotalSeconds']} */
        const _getTotalSeconds = function() {
            const miliseconds = _getTime();
            const totalSeconds = miliseconds / 1000;
            const totalSecondsRounded = Math.floor(totalSeconds);
            return totalSecondsRounded;
        };

        /** @type {TimeSpan['getTotalMinutes']} */
        const _getTotalMinutes = function() {
            const miliseconds = _getTime();
            const totalSeconds = miliseconds / 1000;
            const totalMinutes = totalSeconds / 60;
            const totalMinutesRounded = Math.floor(totalMinutes);
            return totalMinutesRounded;
        };

        /** @type {TimeSpan['getTotalHours']} */
        const _getTotalHours = function() {
            const miliseconds = _getTime();
            const totalSeconds = miliseconds / 1000;
            const totalMinutes = totalSeconds / 60;
            const totalHours = totalMinutes / 60;
            const totalHoursRounded = Math.floor(totalHours);
            return totalHoursRounded;
        };

        self.fromMiliseconds = _fromMiliseconds;
        self.fromSeconds = _fromSeconds;
        self.fromMinutes = _fromMinutes;
        self.fromHours = _fromHours;

        self.addMiliseconds = _addMiliseconds;
        self.addSeconds = _addSeconds;
        self.addMinutes = _addMinutes;
        self.addHours = _addHours;

        self.seconds = _getSeconds;
        self.minutes = _getMinutes;
        self.hours = _getHours;

        self.getTotalSeconds = _getTotalSeconds;
        self.getTotalMinutes = _getTotalMinutes;
        self.getTotalHours = _getTotalHours;
    };
});
