define('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',[],function() {
    return function(){
        const self = this;

        self.entityText = null;
        self.entityName = null;
        self.icon = null;
        self.message = null;

    };
});

