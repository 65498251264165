define('common/storage/browserTabStorage',['common/uniqueId/uniqueIdGenerator', 'common/storage/temporaryStorage'],
    function() {
        var UniqueIdGeneratorConstructor = require('common/uniqueId/uniqueIdGenerator');
        var uniqueIdGenerator = new UniqueIdGeneratorConstructor();
        var temporaryStorage = require('common/storage/temporaryStorage');

        var tabId;
        if (window.name.indexOf("tresta-") !== 0) {
            tabId = "tresta-" + uniqueIdGenerator.generateUniqueIdWithoutHyphens();
            window.name = tabId;
        } else {
            tabId = window.name;
        }

        function saveObject(key, object) {
            var tabObject = temporaryStorage.getObject(tabId);
            tabObject[key] = object;
            temporaryStorage.saveObject(tabId, tabObject);
        }

        function getObject(key) {
            var tabObject = temporaryStorage.getObject(tabId);
            if (tabObject[key]) {
                return tabObject[key];
            } else {
                return null;
            }
        }

        function deleteObject(key) {
            var tabObject = temporaryStorage.getObject(tabId);
            tabObject[key] = undefined;
            temporaryStorage.saveObject(tabId, tabObject);
        }

        return {
            saveObject: saveObject,
            getObject: getObject,
            deleteObject : deleteObject
        };
    }
);

