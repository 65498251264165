define('presentation/signup/viewModels/signupPhoneNumberPortViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'constants/signupSessionFlowConstants',
    'presentation/signup/common/signupNavigationManager',
    'presentation/signup/facades/signupPhoneNumberPortFacade',
    'presentation/signup/validators/signupPhoneNumberPortValidator',
    'presentation/signup/viewModels/numberToPortViewModel',
    'settings/navigationConfiguration',
], function() {

    return function() {
        let self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const NumberToPortViewModelConstructor = require('presentation/signup/viewModels/numberToPortViewModel');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _browserType = require('businessServices/browserSupport/browserType');
        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');

        let _disposables = [];
        let _facade = null;
        let _signupNavigationManager = null;
        let _validator = null;

        self.modelStateObserver = new ModelStateObserverConstructor(self, true);

        self.currentCarrier = ko.observable('').extend({ rateLimit: 100 });
        self.flow = ko.observable();
        self.isCompositionComplete = ko.observable(false);
        self.isGoogleMessageVisible = ko.observable(false);
        self.isModalActive = ko.observable(false);
        self.isModalVisible = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.numbersToPort = ko.observableArray([]).extend({observeState: true});
        self.portMessageAcknowledged = ko.observable(false);
        self.selectedOnlyNumberOption = ko.observable(true);

        self.canAddAnotherNumber = ko.pureComputed(() => self.numbersToPort().length < 3);
        self.canDeleteNumber = ko.pureComputed(() => self.numbersToPort().length > 1);
        self.googleWarningLink = _i18n.t('signupPhoneNumberPort:googleWarning1') + '<a href="https://www.google.com/voice/unlock" target="blank">' + _i18n.t('signupPhoneNumberPort:googleWarning1Link') + '</a>' + '.';
        self.isFlowBTextVisible = ko.computed(() => self.flow() === _signupSessionFlowConstants.flowB && self.isMobile());
        self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);

        self.isNavigateToNextPageActive = ko.pureComputed(() => {
            let areAllNumbersToPortValid = self.numbersToPort().length > 0 && self.numbersToPort().every((item) => {
                return item.numberToPort.isValid();
            });
            return areAllNumbersToPortValid && self.currentCarrier().length > 0;
        });

        self.numbersLabel = ko.pureComputed(() => {
            if (self.isMobile){
                return _i18n.t('signupPhoneNumberPort:tabTitleNumbers');
            } else {
                return _i18n.t('signupPhoneNumberPort:tabTitlePort');
            }
        });

        self.portType = ko.pureComputed(() => {
            if (self.selectedOnlyNumberOption()){
                return "FULL";
            } else {
                return "PARTIAL";
            }
        });

        self.hideNumber = (elem) => {
            if (elem.nodeType === 1) {
                $(elem).slideUp(() => {$(elem).remove();});
            }
        };

        self.showNumber = (elem) => {
            if (elem.nodeType === 1) {$(elem).hide().slideDown();}
        };

        self.navigateToFlowD = () => {
            _signupNavigationManager.navigateToRoute(_navigationConfiguration.routesById.signupPhoneNumberType.routeId, _signupSessionFlowConstants.flowD);
        };

        self.onForwardArrowClicked = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isCarrierValid) => {
                        let areNumbersValid = true;
                        _promiseFactory.deferredList(self.numbersToPort(), (number) => {
                            return _promiseFactory.deferIndefinitely((numberPromise) => {
                                number.validate()
                                    .fail(numberPromise.reject)
                                    .done(isNumberValid => {
                                        areNumbersValid = areNumbersValid && isNumberValid;
                                        numberPromise.resolve();
                                    });
                            });
                        })
                            .fail(deferredObject.reject)
                            .done(() => deferredObject.resolve(areNumbersValid && isCarrierValid));
                    });
            });
        };

        const _onCurrentCarrierChange = (carrier) => {
            if (carrier){
                const googleCarrier = "google";
                const carrierToCheck = carrier.trim().toLowerCase();
                self.isGoogleMessageVisible(carrierToCheck.includes(googleCarrier));
            }
            if (carrier.length > 0) {
                _facade.saveNumberPortCarrier(carrier);
            }
        };

        const _onNumberToPortChanged = () => {
            let areAllNumbersToPortValid = self.numbersToPort().length > 0 && self.numbersToPort().every((item) => {
                return item.numberToPort.isValid();
            });
            if (areAllNumbersToPortValid) {
                _facade.saveNumberPortPhoneNumbers(self.numbersToPort());
            }
        };

        const _onSelectedOnlyNumberOptionChange = () => {
            _facade.saveNumberPortOtherNumbersOnAccount(!self.selectedOnlyNumberOption());
        };

        const _subscribeToObservables = (numberToPort) => {
            _disposables.push(numberToPort.numberToPort.isValid.subscribe(_onNumberToPortChanged));
            _disposables.push(numberToPort.countryAbbreviation.subscribe(_onNumberToPortChanged));
        };

        const _reIndexNumbersToPort = () => {
            self.numbersToPort().forEach((numberToPort, index) => numberToPort.index = index);
        };

        self.addAnotherNumber = () => {
            self.hideGoogleMessage();

            let numberToPort = new NumberToPortViewModelConstructor(self, _facade);
            self.numbersToPort.push(numberToPort);
            _reIndexNumbersToPort();
            self.modelStateObserver.addChildObserver(numberToPort.modelStateObserver);

            _subscribeToObservables(numberToPort);
        };

        self.deleteNumber = (numberToPort) => {
            if (self.canDeleteNumber() === false) {
                return;
            }
            self.numbersToPort.remove(numberToPort);
            _reIndexNumbersToPort();
        };

        self.hideGoogleMessage = () => {
            self.isGoogleMessageVisible(false);
        };

        self.closeInfoModal = () => {
            self.isModalVisible(false);
            _facade.setNumberPortModalCompleted();
            setTimeout(() => {
                self.isModalActive(false);
            }, 250);
        };

        self.showInfoModal = () => {
            self.isModalActive(true);
            setTimeout(() => {
                self.isModalVisible(true);
            }, 100);
        };

        self.togglePortNumberOptions = () => {
            if (self.selectedOnlyNumberOption()) {
                self.selectedOnlyNumberOption(false);
            } else {
                self.selectedOnlyNumberOption(true);
            }
        };
        
        self.detached = () => {
            _signupNavigationManager.detached();
            _disposables.forEach(subscription => subscription.dispose);
            _disposables = [];
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = (flow = null, phoneNumberToPort = null) => {
            const FacadeConstructor = require('presentation/signup/facades/signupPhoneNumberPortFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            const ValidatorConstructor = require('presentation/signup/validators/signupPhoneNumberPortValidator');
            _validator = new ValidatorConstructor();

            self.flow(_signupNavigationManager.getFlowId());

            return _initialize(phoneNumberToPort);
        };

        const _initialize = (phoneNumberToPort) => {
            _promiseFactory.deferIndefinitely((initializeDeferredObject) => {
                _facade.loadSignupSession()
                    .done((signupSession) => {

                        self.selectedOnlyNumberOption(signupSession.selectedOnlyNumberOption);
                        
                        if (!signupSession.isNumberPortModalCompleted){
                            self.showInfoModal();
                        }

                        if (signupSession.currentCarrier){
                            self.currentCarrier(signupSession.currentCarrier);
                        } else if (phoneNumberToPort !== null && phoneNumberToPort.carrier) {
                            if(phoneNumberToPort.carrier === "Other" && phoneNumberToPort.otherCarrier) {
                                self.currentCarrier(phoneNumberToPort.otherCarrier);
                            } else {
                                self.currentCarrier(phoneNumberToPort.carrier);
                            }
                        }

                        if (signupSession.phoneNumbersToPort && signupSession.phoneNumbersToPort.length > 0) {
                            signupSession.phoneNumbersToPort.forEach((phoneNumberToPort) => {
                                let numberToPort = new NumberToPortViewModelConstructor(self, _facade);
                                if (phoneNumberToPort.countryAbbreviation){
                                    numberToPort.countryAbbreviation(phoneNumberToPort.countryAbbreviation);
                                }
                                numberToPort.numberToPort(phoneNumberToPort.phoneNumber);
                                self.numbersToPort.push(numberToPort);
                                _reIndexNumbersToPort();
                                self.modelStateObserver.addChildObserver(numberToPort.modelStateObserver);
                                _subscribeToObservables(numberToPort);
                            });

                            initializeDeferredObject.resolve();
                        } else if (phoneNumberToPort !== null && phoneNumberToPort.phoneNumber && phoneNumberToPort.country) {
                            let numberToPort = new NumberToPortViewModelConstructor(self, _facade);
                            numberToPort.countryAbbreviation(phoneNumberToPort.country);
                            numberToPort.numberToPort(phoneNumberToPort.phoneNumber);
                            self.numbersToPort.push(numberToPort);
                            _reIndexNumbersToPort();
                            self.modelStateObserver.addChildObserver(numberToPort.modelStateObserver);
                            _subscribeToObservables(numberToPort);

                            initializeDeferredObject.resolve();
                        } else {
                            let numberToPort = new NumberToPortViewModelConstructor(self, _facade);
                            self.numbersToPort.push(numberToPort);
                            _reIndexNumbersToPort();
                            self.modelStateObserver.addChildObserver(numberToPort.modelStateObserver);
                            _subscribeToObservables(numberToPort);

                            initializeDeferredObject.resolve();
                        }

                        _validator.registerViewModel(self, _facade);

                        _disposables.push(self.currentCarrier.subscribe(_onCurrentCarrierChange));
                        _disposables.push(self.numbersToPort.subscribe(_onNumberToPortChanged));
                        _disposables.push(self.selectedOnlyNumberOption.subscribe(_onSelectedOnlyNumberOptionChange));
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
