define('presentation/common/searchSortMenu/viewModels/searchSortMenuViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'i18next'
], function(
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type import('i18next')*/
    i18n
) {
    return function() {
        const self = this;

        /** @type {number} */
        let _hideSearchSortMenuTimeout = null;
        let _settings = null;

        const HIDE_SEARCH_SORT_TIMEOUT = 500;
        const DEFAULT_PLACEHOLDER = i18n.t('search');

        const _formatOptions = (options, useValue = true) => {
            const optionsCopy = [...options];
            return optionsCopy.map((option) => {
                const formattedOption = Object.assign({}, option);
                formattedOption.isSelected = ko.pureComputed(() => option.value === self.selectedOption());
                formattedOption.iconName = option.iconName ? option.iconName : null;
                if (option.textI18n) {
                    formattedOption.displayText = i18n.t(`sortOptions.${option.textI18n}`);
                } else if (option.text) {
                    formattedOption.displayText = option.text;
                }
                if (useValue && self.sortValueProperty !== null) {
                    option.value = ko.unwrap(option[self.sortValueProperty]);
                }
                formattedOption.optionClick = () => {
                    if (useValue && option.value !== self.selectedOption()) {
                        self.selectedOption(option.value);
                    }

                    if (option.onClick) {
                        option.onClick();
                    }
                };

                return formattedOption;
            });
        };

        self.searchDisabled = false;
        self.isExpanded = ko.observable(false).toggleable();
        self.placeholder = ko.observable(DEFAULT_PLACEHOLDER);
        self.searchFilter = ko.observable('');
        self.selectedOption = ko.observable('');
        self.sortOptions = ko.observableArray([]);
        self.sortValueProperty = "id";
        self.viewOptions = ko.observableArray([]);

        self.formattedSortOptions = ko.pureComputed(() => _formatOptions(self.sortOptions()));
        self.formattedViewOptions = ko.pureComputed(() => _formatOptions(self.viewOptions(), false));
        self.showViewOptions = ko.computed(() => self.viewOptions() && self.viewOptions().length > 0);
        self.showComingSoonTooltip = ko.pureComputed(() => self.searchDisabled && !sessionAccountInfo.isImpersonating());

        self.hideSearchSortMenu = () => {
            _hideSearchSortMenuTimeout = setTimeout(() => {
                self.isExpanded(false);
            }, HIDE_SEARCH_SORT_TIMEOUT);
        };

        self.resetHideSearchSortMenu = () => {
            clearTimeout(_hideSearchSortMenuTimeout);
        };

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {

            if (typeof _settings.searchDisabled === "boolean") {
                self.searchDisabled = _settings.searchDisabled;
            }

            if (_settings.placeholder) {
                if (ko.isObservable(_settings.placeholder)) {
                    self.placeholder = _settings.placeholder;
                } else {
                    self.placeholder(_settings.placeholder);
                }
            }

            if (_settings.searchFilter) {
                self.searchFilter = _settings.searchFilter;
            }

            if (_settings.selectedOption) {
                self.selectedOption = _settings.selectedOption;
            }

            if (_settings.sortValueProperty) {
                self.sortValueProperty = ko.unwrap(_settings.sortValueProperty);
            }

            if (ko.isObservable(_settings.sortOptions)) {
                self.sortOptions = _settings.sortOptions;
            } else {
                self.sortOptions(_settings.sortOptions);
            }

            if (ko.isObservable(_settings.viewOptions)){
                self.viewOptions = _settings.viewOptions;
            } else {
                self.viewOptions(_settings.viewOptions);
            }
        };
    };
});

