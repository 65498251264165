define('persistence/webSocket/webSocketAuthentication',[
    'persistence/webSocket/webSocketApp',
    'constants/webSocketSendMessagePriorities'
], function() {

    return function() {
        var self = this;
        var PERMISSION_INFO_CAPTURE_BYPASS = "UI.BypassInfoCapture";
        var PERMISSION_CLOSED_ACCOUNT_BYPASS = "UI.BypassClosedAccount";

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _webSocketMessagePriorities = require('constants/webSocketSendMessagePriorities');

        var _webSocketApp = null;

        var _hasPermission = function(permissionType) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "permission_type": permissionType
                };
                _webSocketApp.send("has_permission", webSocketParams, function(result) {
                    if (result.hasPermission) {
                        deferredObject.resolve(true);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            });
        };

        self.loginUser = function(username, password) {
            return _promiseFactory.defer(function(deferredObject) {
                var usernameLowerCase = username.toLowerCase();

                var webSocketParams = {
                    "emailAddress": usernameLowerCase,
                    "password": password
                };

                _webSocketApp.send("login_user", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.loginToken = function(authenticationToken, accountId, userId) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "authentication_token": authenticationToken,
                    "account_id" : accountId,
                    "user_id": userId
                };

                _webSocketApp.send("login_token", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                }, _webSocketMessagePriorities.high);
            });
        };

        self.hasPermissionInfoCaptureBypass = function() {
            return _hasPermission(PERMISSION_INFO_CAPTURE_BYPASS);
        };
        self.hasPermissionClosedAccountBypass = function() {
            return _hasPermission(PERMISSION_CLOSED_ACCOUNT_BYPASS);
        };

        self.logout = function(authenticationToken) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    authenticationToken: authenticationToken
                };
                _webSocketApp.send("logout", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.validatePassword = function(username, password) {
            return _promiseFactory.defer(function(deferredObject) {
                var usernameLowerCase = username.toLowerCase();

                var webSocketParams = {
                    "emailAddress": usernameLowerCase,
                    "password": password
                };

                _webSocketApp.send("validate_password", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("authentication_user");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

