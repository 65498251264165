define('presentation/common/widgetConfiguration',['plugins/widget'], function() {
    return {
        configureWidgets: function() {
            const widget = require('plugins/widget');

            widget.registerKind('analyticsGraph');
            widget.mapKind('analyticsGraph',
                'presentation/common/analyticsGraph/views/analyticsGraph',
                'presentation/common/analyticsGraph/viewModels/analyticsGraphViewModel');

           widget.registerKind('answerTimeout');
            widget.mapKind('answerTimeout',
                'presentation/common/answerTimeout/views/answerTimeout',
                'presentation/common/answerTimeout/viewModels/answerTimeoutViewModel');

            widget.registerKind('audioPlayer');
            widget.mapKind('audioPlayer',
                'presentation/common/audioPlayer/views/audioPlayer',
                'presentation/common/audioPlayer/viewModels/audioPlayerViewModel');

            widget.registerKind('audioRecorder');
            widget.mapKind('audioRecorder',
                'presentation/common/audioRecorder/views/audioRecorder',
                'presentation/common/audioRecorder/viewModels/audioRecorderViewModel');

            widget.registerKind('birthdayInput');
            widget.mapKind('birthdayInput',
                'presentation/common/birthdayInput/views/birthdayInput',
                'presentation/common/birthdayInput/viewModels/birthdayInputViewModel');

            widget.registerKind('borderedIcon');
            widget.mapKind('borderedIcon',
                'presentation/common/borderedIcon/views/borderedIcon',
                'presentation/common/borderedIcon/viewModels/borderedIconViewModel');

            widget.registerKind('callDetails');
            widget.mapKind('callDetails',
                'presentation/common/callDetails/views/callDetails',
                'presentation/common/callDetails/viewModels/callDetailsViewModel');

            widget.registerKind('callRoute');
            widget.mapKind('callRoute',
                'presentation/common/callRoute/views/callRoute',
                'presentation/common/callRoute/viewModels/callRouteViewModel');

            widget.registerKind('checkBox');
            widget.mapKind('checkBox',
                'presentation/common/checkBox/views/checkBox',
                'presentation/common/checkBox/viewModels/checkBoxViewModel');

            widget.registerKind('comboBox');
            widget.mapKind('comboBox',
                'presentation/common/comboBox/views/comboBox',
                'presentation/common/comboBox/viewModels/comboBoxViewModel');

            widget.registerKind('connectedTabCollection');
            widget.mapKind('connectedTabCollection',
                'presentation/common/connectedTabCollection/views/connectedTabCollection',
                'presentation/common/connectedTabCollection/viewModels/connectedTabCollectionViewModel');

            widget.registerKind('conversationImagesGrid');
            widget.mapKind('conversationImagesGrid',
                'presentation/common/conversationImagesGrid/views/conversationImagesGrid',
                'presentation/common/conversationImagesGrid/viewModels/conversationImagesGridViewModel');

            widget.registerKind('creditCardNumber');
            widget.mapKind('creditCardNumber',
                'presentation/common/creditCard/views/creditCardNumber',
                'presentation/common/creditCard/viewModels/creditCardNumberViewModel');

            widget.registerKind('dataGrid');
            widget.mapKind('dataGrid',
                'presentation/common/dataGrid/views/dataGrid',
                'presentation/common/dataGrid/viewModels/dataGridViewModel');

            widget.registerKind('dateRangeSelection');
            widget.mapKind('dateRangeSelection',
                'presentation/common/dateRangeSelection/views/dateRangeSelection',
                'presentation/common/dateRangeSelection/viewModels/dateRangeSelectionViewModel');

            widget.registerKind('extensionPicker');
            widget.mapKind('extensionPicker',
                'presentation/common/extensionPicker/views/extensionPicker',
                'presentation/common/extensionPicker/viewModels/extensionPickerViewModel');

            widget.registerKind('frontEndHeader');
            widget.mapKind('frontEndHeader',
                'presentation/common/frontEndHeader/views/frontEndHeader',
                'presentation/common/frontEndHeader/viewModels/frontEndHeaderViewModel');

            widget.registerKind('integrityWarningBox');
            widget.mapKind('integrityWarningBox',
                'presentation/common/warningBox/views/integrityWarningBox',
                'presentation/common/warningBox/viewModels/integrityWarningBoxViewModel'
            );

            widget.registerKind('interiorShellStickyFooter');
            widget.mapKind('interiorShellStickyFooter',
                'presentation/common/interiorShellStickyFooter/views/interiorShellStickyFooter',
                'presentation/common/interiorShellStickyFooter/viewModels/interiorShellStickyFooterViewModel');

            widget.registerKind('interiorShellStickyHeader');
            widget.mapKind('interiorShellStickyHeader',
                'presentation/common/interiorShellStickyHeader/views/interiorShellStickyHeader',
                'presentation/common/interiorShellStickyHeader/viewModels/interiorShellStickyHeaderViewModel');

         widget.registerKind('listBox');
         widget.mapKind('listBox',
             'presentation/common/listBox/views/listBox',
             'presentation/common/listBox/viewModels/listBoxViewModel');

         widget.registerKind('loadingBar');
         widget.mapKind('loadingBar',
             'presentation/common/loadingBar/views/loadingBar',
             'presentation/common/loadingBar/viewModels/loadingBarViewModel');

            widget.registerKind('membershipGroupAvatar');
            widget.mapKind('membershipGroupAvatar',
                'presentation/common/membershipGroupAvatar/views/membershipGroupAvatar',
                'presentation/common/membershipGroupAvatar/viewModels/membershipGroupAvatarViewModel');

            widget.registerKind('optionsBox');
            widget.mapKind('optionsBox',
                'presentation/common/optionsBox/views/optionsBox',
                'presentation/common/optionsBox/viewModels/optionsBoxViewModel');

            widget.registerKind('smsAttachmentErrorOverlay');
            widget.mapKind('smsAttachmentErrorOverlay',
                'presentation/common/smsAttachmentErrorOverlay/views/smsAttachmentErrorOverlay',
                'presentation/common/smsAttachmentErrorOverlay/viewModels/smsAttachmentErrorOverlayViewModel');

            widget.registerKind('smsAttachmentLoadingOverlay');
            widget.mapKind('smsAttachmentLoadingOverlay',
                'presentation/common/smsAttachmentLoadingOverlay/views/smsAttachmentLoadingOverlay',
                'presentation/common/smsAttachmentLoadingOverlay/viewModels/smsAttachmentLoadingOverlayViewModel');

            widget.registerKind('snackbar');
            widget.mapKind('snackbar',
                'presentation/common/snackbar/views/snackbar',
                'presentation/common/snackbar/viewModels/snackbarViewModel');

            widget.registerKind('threeStateSaveButton');
            widget.mapKind('threeStateSaveButton',
                'presentation/common/threeStateSaveButton/views/threeStateSaveButton',
                'presentation/common/threeStateSaveButton/viewModels/threeStateSaveButtonViewModel'
            );

            widget.registerKind('timeframe');
            widget.mapKind('timeframe',
                'presentation/common/timeframe/views/timeframe',
                'presentation/common/timeframe/viewModels/timeframeViewModel');

            widget.registerKind('contactAvatar');
            widget.mapKind('contactAvatar',
                'presentation/common/contactAvatar/views/contactAvatar',
                'presentation/common/contactAvatar/viewModels/contactAvatarViewModel');

            widget.registerKind('userAvatar');
            widget.mapKind('userAvatar',
                'presentation/common/userAvatar/views/userAvatar',
                'presentation/common/userAvatar/viewModels/userAvatarViewModel');

            widget.registerKind('userGroupAvatar');
            widget.mapKind('userGroupAvatar',
                'presentation/common/userGroupAvatar/views/userGroupAvatar',
                'presentation/common/userGroupAvatar/viewModels/userGroupAvatarViewModel');

            widget.registerKind('videoPlayer');
            widget.mapKind('videoPlayer',
                'presentation/common/videoPlayer/views/videoPlayer',
                'presentation/common/videoPlayer/viewModels/videoPlayerViewModel');

            widget.registerKind('warningBox');
            widget.mapKind('warningBox',
                'presentation/common/warningBox/views/warningBox',
                'presentation/common/warningBox/viewModels/warningBoxViewModel'
            );

            widget.registerKind('warningBoxShell');
            widget.mapKind('warningBoxShell',
                'presentation/common/warningBox/views/warningBoxShell',
                'presentation/common/warningBox/viewModels/warningBoxShellViewModel'
            );

            widget.registerKind('infoBoxShell');
            widget.mapKind('infoBoxShell',
                'presentation/common/infoBox/views/infoBoxShell',
                'presentation/common/infoBox/viewModels/infoBoxShellViewModel'
            );
        }
    };
});

