define('presentation/settings/voicemailBoxes/facades/addVoicemailBoxSubscribersFacade',['businessServices/authentication/sessionAccountInfo',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'externalDependencies/clientWebSocket',
        'presentation/settings/voicemailBoxes/presentationObjects/voicemailBoxSubscriberPresentationObject',
], function() {
    const VoicemailBoxSubscriberPresentationObjectConstructor = require('presentation/settings/voicemailBoxes/presentationObjects/voicemailBoxSubscriberPresentationObject');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _addVoicemailBoxWebSocket = clientWebSocket.forApplication("addVoicemailBox");

    const _commonState = require('common/storage/commonState');

    let _promiseFactory = null;

    const _getVoicemailBoxSubscribers = (voicemailBoxId) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const request = {
                voicemailBoxId: voicemailBoxId
            };

            _addVoicemailBoxWebSocket.send("listSubscribers", request, (result) => {
                let subscribersList = result.subscribers.map(subscriber => {
                    let subscriberPresentationObject = new VoicemailBoxSubscriberPresentationObjectConstructor();
                    subscriberPresentationObject.voicemailBoxSubscriberId = subscriber.voicemailBoxSubscriberId;
                    if(subscriber.userId) {
                        subscriberPresentationObject.subscriberId = subscriber.userId;
                        subscriberPresentationObject.subscriberType = "user";
                        subscriberPresentationObject.avatarType = "user";
                    }
                    else if(subscriber.userGroupId) {
                        subscriberPresentationObject.subscriberId = subscriber.userGroupId;
                        subscriberPresentationObject.subscriberType = "userGroup";
                        subscriberPresentationObject.avatarType = "userGroup";
                    }
                    return subscriberPresentationObject;
                });

                promise.resolve({
                    subscribers : subscribersList,
                    subscribersLog : result.subscribersLog,
                });
            });
        });
    };

    const _saveVoicemailBoxSubscribers = (voicemailBoxId, subscribers) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const subscribersToUpdate = subscribers.subscribers.map(subscriber => {
                const subscriberToUpdate = {
                    userId : subscriber.userId,
                    userGroupId : _commonState.resolveGroupIdToGuid(subscriber.userGroupId),
                };
                return subscriberToUpdate;
            });
            const request = {
                voicemailBoxId: voicemailBoxId,
                subscribers: subscribersToUpdate,
            };

            _addVoicemailBoxWebSocket.send("updateSubscribers", request, (result) => {
                if (result.isSuccessful) {
                    promise.resolve();
                } else {
                    promise.reject(new Error("error updating subscribers"));
                }
            });
        });
    };
    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    return function () {
        let self = this;

        self.init = _init;
        self.getVoicemailBoxSubscribers = _getVoicemailBoxSubscribers;
        self.saveVoicemailBoxSubscribers = _saveVoicemailBoxSubscribers;
    };
});

