define('presentation/signup/facades/signupPhoneNumberPortFacade',['businessServices/numberProvisioning/numberProvisioner',
        'businessServices/signup/signupSessionStore',
        'entities/signupNumberPortEntity',
        'entities/signupNumberPortPhoneNumberEntity',
        'externalDependencies/clientApi',
        'presentation/signup/presentationObjects/signupNumberPortPresentationObject',
        'presentation/signup/presentationObjects/signupNumberPortPhoneNumberPresentationObject'],
    function() {
        let _clientApi = null;
        let _numberProvisioner = null;
        let _numberPortId = null;
        let _promiseFactory = null;
        let _signupSessionStore = null;

        let _isNumberPortModalCompleted = false;
        let _isNumberPortBillingModalCompleted = false;

        const SignupNumberPortEntityConstructor = require('entities/signupNumberPortEntity');
        const SignupNumberPortPresentationObjectConstructor = require('presentation/signup/presentationObjects/signupNumberPortPresentationObject');
        const SignupNumberPortPhoneNumberEntityConstructor = require('entities/signupNumberPortPhoneNumberEntity');
        const SignupNumberPortPhoneNumberPresentationObjectConstructor = require('presentation/signup/presentationObjects/signupNumberPortPhoneNumberPresentationObject');

        const _loadSignupSession = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.getState()
                    .fail(promise.reject)
                    .done((signupSession) => {
                        const signupDocument = signupSession.signupDocument;
                        const numberPortDocument = signupDocument.numberPort;

                        let signupNumberPortPresentationObject = new SignupNumberPortPresentationObjectConstructor();
                        signupNumberPortPresentationObject.isNumberPortModalCompleted = _isNumberPortModalCompleted;
                        signupNumberPortPresentationObject.isNumberPortBillingModalCompleted = _isNumberPortBillingModalCompleted;

                        signupNumberPortPresentationObject.isAcknowledged = signupSession.isAcknowledged;
                        signupNumberPortPresentationObject.authorizedSignature = signupSession.authorizedSignature;

                        if (numberPortDocument && numberPortDocument.numberPortId !== null){
                            _numberPortId = numberPortDocument.numberPortId;

                            signupNumberPortPresentationObject.numberPortId = numberPortDocument.numberPortId;
                            signupNumberPortPresentationObject.currentCarrier = numberPortDocument.carrier;
                            signupNumberPortPresentationObject.billingInfoName = numberPortDocument.billingName;
                            signupNumberPortPresentationObject.billingInfoNumber = numberPortDocument.billingPhoneNumber;
                            signupNumberPortPresentationObject.billingInfoNumberCountryAbbreviation = numberPortDocument.billingPhoneNumberCountry;
                            signupNumberPortPresentationObject.billingInfoAddress = numberPortDocument.billingAddressLine1;
                            signupNumberPortPresentationObject.billingInfoApt = numberPortDocument.billingAddressLine2;
                            signupNumberPortPresentationObject.billingInfoCity = numberPortDocument.billingCity;
                            signupNumberPortPresentationObject.billingInfoState = numberPortDocument.billingState;
                            signupNumberPortPresentationObject.billingInfoZip = numberPortDocument.billingZipPostalCode;
                            signupNumberPortPresentationObject.billingInfoCountry = numberPortDocument.billingCountry;
                            signupNumberPortPresentationObject.portAccountNumber = numberPortDocument.billingAccountNumber;
                            signupNumberPortPresentationObject.pinOrPasscode = numberPortDocument.billingPinPasscode;
                            signupNumberPortPresentationObject.selectedOnlyNumberOption = !numberPortDocument.otherNumbersOnAccount;
                            signupNumberPortPresentationObject.portDate = numberPortDocument.desiredPortDate;
                            
                            if (numberPortDocument && numberPortDocument.phoneNumbersToPort){
                                signupNumberPortPresentationObject.phoneNumbersToPort = numberPortDocument.phoneNumbersToPort.map((phoneNumberToPort) => {
                                    let signupNumberPortPhoneNumberPresentationObject = new SignupNumberPortPhoneNumberPresentationObjectConstructor();
                                    signupNumberPortPhoneNumberPresentationObject.phoneNumber = phoneNumberToPort.phoneNumber;
                                    signupNumberPortPhoneNumberPresentationObject.countryAbbreviation = phoneNumberToPort.country;
                                    return signupNumberPortPhoneNumberPresentationObject;
                                });
                            } else {
                                signupNumberPortPresentationObject.phoneNumbersToPort = [];
                            }

                            promise.resolve(signupNumberPortPresentationObject);
                        } else {
                            _signupSessionStore.createNumberPort()
                                .fail(promise.reject)
                                .done((newNumberPort) => {
                                    _numberPortId = newNumberPort.numberPortId;

                                    signupNumberPortPresentationObject.numberPortId = newNumberPort.numberPortId;
                                    signupNumberPortPresentationObject.phoneNumbersToPort = [];

                                    promise.resolve(signupNumberPortPresentationObject);
                                });
                        }
                    });
            });
        };


        const _setNumberPortModalCompleted = () => {
            _isNumberPortModalCompleted = true;
        };

        const _saveNumberPortPhoneNumbers = (numbersToPort) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                const phoneNumbersToPort = numbersToPort.map((phoneNumberToPort) => {
                    const numberPortPhoneNumberEntity = new SignupNumberPortPhoneNumberEntityConstructor();
                    numberPortPhoneNumberEntity.phoneNumber = phoneNumberToPort.numberToPort();
                    numberPortPhoneNumberEntity.country = phoneNumberToPort.countryAbbreviation().toUpperCase();
                    return numberPortPhoneNumberEntity;
                });
                _signupSessionStore.saveNumberPortPhoneNumbers(_numberPortId, phoneNumbersToPort)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortCarrier = (carrier) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortCarrier(_numberPortId, carrier)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortOtherNumbersOnAccount = (areOtherNumbersOnAccount) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortOtherNumbersOnAccount(_numberPortId, areOtherNumbersOnAccount)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _setNumberPortBillingModalCompleted = () => {
            _isNumberPortBillingModalCompleted = true;
        };

        const _storeNumberPortAuthorizedSignature = (authorizedSignature) => _signupSessionStore.storeNumberPortAuthorizedSignature(authorizedSignature);
        const _storeNumberPortIsAcknowledged = (isAcknowledged) => _signupSessionStore.storeNumberPortIsAcknowledged(isAcknowledged);

        const _saveNumberPortBillingAccountNumber = (accountNumber) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingAccountNumber(_numberPortId, accountNumber)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingPinPasscode = (pinOrPasscode) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingPinPasscode(_numberPortId, pinOrPasscode)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingName = (billingName) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingName(_numberPortId, billingName)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingPhoneNumberCountry = (countryAbbreviation) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingPhoneNumberCountry(_numberPortId, countryAbbreviation)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingPhoneNumber = (phoneNumber) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingPhoneNumber(_numberPortId, phoneNumber)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingCountry = (countryAbbreviation) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingCountry(_numberPortId, countryAbbreviation)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingState = (billingState) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingState(_numberPortId, billingState)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingZipPostalCode = (zipPostalCode) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingZipPostalCode(_numberPortId, zipPostalCode)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingAddressLine1 = (billingAddress) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingAddressLine1(_numberPortId, billingAddress)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingAddressLine2 = (billingApt) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingAddressLine2(_numberPortId, billingApt)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortBillingCity = (billingCity) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortBillingCity(_numberPortId, billingCity)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPortDesiredPortDate = (portDate) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.saveNumberPortDesiredPortDate(_numberPortId, portDate)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNumberPort = (signupNumberPortPresentationObject) => {
            return _promiseFactory.defer((promise) => {
                const numberPortEntity = new SignupNumberPortEntityConstructor();
                numberPortEntity.numberPortId = signupNumberPortPresentationObject.numberPortId;
                numberPortEntity.carrier = signupNumberPortPresentationObject.currentCarrier;
                numberPortEntity.billingName = signupNumberPortPresentationObject.billingInfoName;
                numberPortEntity.billingPhoneNumber = signupNumberPortPresentationObject.billingInfoNumber;
                numberPortEntity.billingPhoneNumberCountry = signupNumberPortPresentationObject.billingInfoNumberCountryAbbreviation;
                numberPortEntity.billingAddressLine1 = signupNumberPortPresentationObject.billingInfoAddress;
                numberPortEntity.billingAddressLine2 = signupNumberPortPresentationObject.billingInfoApt;
                numberPortEntity.billingCity = signupNumberPortPresentationObject.billingInfoCity;
                numberPortEntity.billingCountry = signupNumberPortPresentationObject.billingInfoCountry;
                numberPortEntity.billingState = signupNumberPortPresentationObject.billingInfoState;
                numberPortEntity.billingZipPostalCode = signupNumberPortPresentationObject.billingInfoZip;
                numberPortEntity.billingAccountNumber = signupNumberPortPresentationObject.portAccountNumber;
                numberPortEntity.billingPinPasscode = signupNumberPortPresentationObject.pinOrPasscode;
                numberPortEntity.otherNumbersOnAccount = !signupNumberPortPresentationObject.selectedOnlyNumberOption;
                numberPortEntity.whenToPort = signupNumberPortPresentationObject.portDateSelection;
                numberPortEntity.desiredPortDate = signupNumberPortPresentationObject.portDate;

                if (signupNumberPortPresentationObject.phoneNumbersToPort){
                    numberPortEntity.phoneNumbersToPort = signupNumberPortPresentationObject.phoneNumbersToPort.map((phoneNumberToPort) => {
                        const numberPortPhoneNumberEntity = new SignupNumberPortPhoneNumberEntityConstructor();
                        numberPortPhoneNumberEntity.phoneNumber = phoneNumberToPort.phoneNumber;
                        numberPortPhoneNumberEntity.country = phoneNumberToPort.countryAbbreviation;
                        return numberPortPhoneNumberEntity;
                    });
                }

                _signupSessionStore.saveNumberPort(numberPortEntity)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _setPhoneNumberCompleted = (isPhoneNumberCompleted) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.setPhoneNumberCompleted(isPhoneNumberCompleted)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _getCountriesAndRegions = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _numberProvisioner.getCountries()
                    .fail(deferredObject.reject)
                    .done((result) => {
                        deferredObject.resolve(result.data);
                    });
            });
        };

        const _checkNumberPortability = (numberToPort) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _numberProvisioner.checkNumberPortability(numberToPort)
                    .fail(promise.reject)
                    .done((result) => {
                        promise.resolve(result.isPortable);
                    });
            });
        };

        const _isNumberToPortValid = (phoneNumber) => {
            return _signupSessionStore.isRestrictedPhoneNumber(phoneNumber);
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();

            const NumberProvisionerConstructor = require('businessServices/numberProvisioning/numberProvisioner');
            _numberProvisioner = new NumberProvisionerConstructor();
            _numberProvisioner.init();

            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();
        };

        return function() {
            let self = this;

            self.init = _init;

            self.checkNumberPortability = _checkNumberPortability;
            self.getCountriesAndRegions = _getCountriesAndRegions;
            self.isNumberToPortValid = _isNumberToPortValid;
            self.loadSignupSession = _loadSignupSession;
            self.saveNumberPort = _saveNumberPort;
            self.saveNumberPortBillingAccountNumber = _saveNumberPortBillingAccountNumber;
            self.saveNumberPortBillingAddressLine1 = _saveNumberPortBillingAddressLine1;
            self.saveNumberPortBillingAddressLine2 = _saveNumberPortBillingAddressLine2;
            self.saveNumberPortBillingCity = _saveNumberPortBillingCity;
            self.saveNumberPortBillingCountry = _saveNumberPortBillingCountry;
            self.saveNumberPortBillingName = _saveNumberPortBillingName;
            self.saveNumberPortBillingPinPasscode = _saveNumberPortBillingPinPasscode;
            self.saveNumberPortBillingPhoneNumberCountry = _saveNumberPortBillingPhoneNumberCountry;
            self.saveNumberPortBillingPhoneNumber = _saveNumberPortBillingPhoneNumber;
            self.saveNumberPortBillingState = _saveNumberPortBillingState;
            self.saveNumberPortBillingZipPostalCode = _saveNumberPortBillingZipPostalCode;
            self.saveNumberPortCarrier = _saveNumberPortCarrier;
            self.saveNumberPortDesiredPortDate = _saveNumberPortDesiredPortDate;
            self.saveNumberPortOtherNumbersOnAccount = _saveNumberPortOtherNumbersOnAccount;
            self.saveNumberPortPhoneNumbers = _saveNumberPortPhoneNumbers;
            self.setNumberPortModalCompleted = _setNumberPortModalCompleted;
            self.setNumberPortBillingModalCompleted = _setNumberPortBillingModalCompleted;
            self.setPhoneNumberCompleted = _setPhoneNumberCompleted;
            self.storeNumberPortAuthorizedSignature = _storeNumberPortAuthorizedSignature;
            self.storeNumberPortIsAcknowledged = _storeNumberPortIsAcknowledged;
        };
    });

