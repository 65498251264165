define('presentation/messages/viewModels/accessRevokedModalViewModel',['common/promises/promiseFactory'],() => {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const PromiseFactory = new PromiseFactoryConstructor();

    return function() {
        this.activate = () => {
            return PromiseFactory.wait();
        };
    };
});
