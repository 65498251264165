define('presentation/settings/phoneNumbers/presentationObjects/phoneNumberPresentationObject',[
    'common/converters/phoneNumberFormatter',
    'presentation/settings/phoneNumbers/viewModels/phoneNumberCallRecordingTabViewModel',
    'presentation/settings/phoneNumbers/viewModels/phoneNumberInboundCallingTabViewModel',
    'presentation/settings/phoneNumbers/viewModels/phoneNumberOutboundDialTabViewModel',
    'presentation/settings/phoneNumbers/viewModels/phoneNumberTextMessagingTabViewModel',
    'presentation/tabs/presentationObjects/tabsPresentationObject'
],function() {
    return function(){
        let self = this;

        const CallRecordingTabConstructor = require('presentation/settings/phoneNumbers/viewModels/phoneNumberCallRecordingTabViewModel');
        const InboundCallingTabConstructor = require('presentation/settings/phoneNumbers/viewModels/phoneNumberInboundCallingTabViewModel');
        const OutboundDialTabConstructor = require('presentation/settings/phoneNumbers/viewModels/phoneNumberOutboundDialTabViewModel');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');
        const TextMessagingTabConstructor = require('presentation/settings/phoneNumbers/viewModels/phoneNumberTextMessagingTabViewModel');

        const _i18n = require('i18next');

        const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatter');
        let _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

        self.connectorType = 'phone';

        self.phoneId = null;
        self.phoneNumber = null;
        self.phoneNumberName = ko.observable(null);
        self.phoneNumberDisplayName = ko.observable(null);
        self.formattedPhoneNumber = ko.observable(null);
        self.formattedCityState = null;
        self.rateCenter = null;
        self.stateCode = null;
        self.lataCode = null;
        self.activeDateTime = null;
        self.modifiedDateTime = null;
        self.modifiedDateTimeDisplayValue = ko.observable(null);
        self.routeToType = ko.observable(null);
        self.routeToUserId = null;
        self.routeToUserGroupId = ko.observable(null);
        self.routeToAutoAttendantId = null;
        self.routeToForwardingNumberId = null;
        self.routeToScheduleId = null;
        self.routeToRoutingRuleId = null;
        self.routingRule = ko.observable(null);
        self.scheduleName = ko.observable(null);
        self.scheduleSegments = ko.observableArray([]);
        self.showScheduleDetails = ko.observable(true);
        self.showCallFlowDetails = ko.observable(true);
        self.routeToRuleName = ko.observable(null);
        self.routeToData = null;
        self.routeSearchData = ko.observable(null);
        self.outboundSearchData = ko.observable(null);
        self.recordingSearchData = ko.observable(null);
        self.smsSearchData = ko.observable(null);
        self.userAvatar = ko.observable(null);
        self.hasAvatar = ko.observable(false);
        self.voicePromptId = null;
        self.userGroupRingType = ko.observable(null);
        self.forwardingNumberPhoneNumber = ko.observable(null);
        self.forwardingNumberCountryName = null;
        self.isPortPending = ko.observable(null);
        self.isInboundCallingEnabled = ko.observable(null);
        self.isInboundCallRecordingEnabled = ko.observable(null);
        self.isOutboundCallRecordingEnabled = ko.observable(null);
        self.callRecordingSubscribers = ko.observableArray([]);
        self.isOutboundCallingEnabled = ko.observable(null);
        self.outboundCallingPermission = ko.observableArray([]);
        self.isSmsEnabled = ko.observable(null);
        self.isOutboundCallingPermissionInherited  = ko.observable(null);
        self.isCallRecordingPermissionInherited  = ko.observable(null);
        self.isSmsPermissionInherited  = ko.observable(null);
        self.smsAuthorizedMessagers = ko.observableArray([]);
        self.isVoicePromptEnabled = ko.observable(null);
        self.recordingNotificationId = null;
        self.showDeactivated = ko.observable(false);
        self.deactivatedMessage = ko.observable("");

        self.deleteToolTip = ko.computed(() => {
             if (self.isPortPending()) {
                return _i18n.t('phoneNumbers:deletePortInProgress');
             } else {
                return _i18n.t("phoneNumbers:atLeastOneRequired");
             }
        });

        self.isLocalPhoneNumber = ko.pureComputed(() => !_phoneNumberFormatConverter.isTollFree(self.phoneNumber));

        self.isCallRecordingEnabled = ko.pureComputed(() => {
            return self.isInboundCallRecordingEnabled() || self.isOutboundCallRecordingEnabled();
        });

        self.recordingIconName =  ko.pureComputed(() => {
            if (self.isInboundCallRecordingEnabled() && self.isOutboundCallRecordingEnabled()) {
                return 'callRecordingBadgeBoth';
            } else {
                return 'callRecordingBadge';
            }
        });

        self.tabActive = ko.observable();
        self.tabMetadata = {
            inboundCalling: { id: "inboundCalling", isValid: ko.observable(true), title: _i18n.t('phoneNumbers:tab', {context: 'inbound'})},
            outboundCalling: { id: "outboundCalling", isValid: ko.observable(true), title: _i18n.t('phoneNumbers:tab', {context: 'outbound'})},
            callRecording: { id: "callRecording", isValid: ko.observable(true), title: _i18n.t('phoneNumbers:tab', {context: 'recording'})},
            messaging: { id: "messaging", isValid: ko.observable(true), title: _i18n.t('phoneNumbers:tab', {context: 'messages'}) }
        };
        self.tabs = [
            new TabPresentationObjectConstructor(self.tabMetadata.inboundCalling.id, self.tabActive, self.tabMetadata.inboundCalling.isValid, self.tabMetadata.inboundCalling.title),
            new TabPresentationObjectConstructor(self.tabMetadata.outboundCalling.id, self.tabActive, self.tabMetadata.outboundCalling.isValid, self.tabMetadata.outboundCalling.title),
            new TabPresentationObjectConstructor(self.tabMetadata.callRecording.id, self.tabActive, self.tabMetadata.callRecording.isValid, self.tabMetadata.callRecording.title),
            new TabPresentationObjectConstructor(self.tabMetadata.messaging.id, self.tabActive, self.tabMetadata.messaging.isValid, self.tabMetadata.messaging.title)
        ];

        self.tabActive(self.tabs[0].id);
        self.isTabActive = (tabId) => {
            return self.tabActive() === tabId;
        };

        self.inboundCallingTabViewModel = new InboundCallingTabConstructor();
        self.outboundDialTabViewModel = new OutboundDialTabConstructor();
        self.callRecordingTabViewModel = new CallRecordingTabConstructor();
        self.textMessagingTabViewModel = new TextMessagingTabConstructor();

        self.activeTabViewModel = ko.computed(() => {
            switch (self.tabActive()) {
                case self.tabMetadata.inboundCalling.id:
                    return self.inboundCallingTabViewModel;
                case self.tabMetadata.outboundCalling.id:
                    return self.outboundDialTabViewModel;
                case self.tabMetadata.callRecording.id:
                    return self.callRecordingTabViewModel;
                case self.tabMetadata.messaging.id:
                    return self.textMessagingTabViewModel;
                default:
                    return self.inboundCallingTabViewModel;
            }
        });
    };
});

