define('presentation/settings/userGroup/viewModels/userGroupProfileViewModel',['common/promises/promiseFactory',
    'constants/userGroupConstants',
    'common/collections/collectionSorter',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/state/modelStateObserver',
    'presentation/settings/userGroup/facades/userGroupProfileFacade',
    'presentation/settings/userGroup/validators/userGroupProfileValidator',
    'common/converters/extensionsFormatter'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _userGroupConstants = require('constants/userGroupConstants');
        const _userId = _sessionAccountInfo.userId();

        const _extensionsFormatter = require('common/converters/extensionsFormatter');

        let _facade = null;
        let _validator = null;

        const _initializeMembers = (currentMembers) => {
            return _promiseFactory.defer((promise) => {
                const members = currentMembers.reduce((members, member) => {
                    const commonStateItem = _commonState.get(member.subscriberId);
                    if (commonStateItem.isActive() || commonStateItem.isInvited()) {
                        const isAllUsersGroup = self.userGroupId === _userGroupConstants.allUserGroupId;
                        const isBillingAdminGroup = self.userGroupId === _userGroupConstants.billingAdminsGroupId;
                        const isSystemAdminGroup = self.userGroupId === _userGroupConstants.systemAdminsGroupId;
                        let readOnly = false;
                        let removeItemTooltip = '';
                        if (isAllUsersGroup) {
                            readOnly = true;
                            removeItemTooltip = _i18n.t('userGroupProfile:allUsersGroupMember');
                        }
                        if (member.subscriberId === _userId && (isBillingAdminGroup || isSystemAdminGroup)) {
                            readOnly = true;
                            removeItemTooltip = isBillingAdminGroup ? _i18n.t('userGroupProfile:billingAdminsGroupMember') : _i18n.t('userGroupProfile:systemAdminsGroupMember');
                        }

                        members.push({
                            avatar: commonStateItem.avatar(),
                            displayName: commonStateItem.name,
                            id: commonStateItem.id,
                            isActive: commonStateItem.isActive,
                            isDeletable: ko.observable(false),
                            isInvited: commonStateItem.isInvited,
                            isReadOnly: ko.observable(readOnly),
                            removeToolTipText: removeItemTooltip,
                            type: commonStateItem.type
                        });
                    }

                    return members;

                }, []);

                _sorter.multiSort(members, ["isDeletable", "displayName"], [true, true]);
                self.members(members);
                promise.resolve();
            });
        };

        const _initializePage = () => {
            return _promiseFactory.defer((promise) => {
                if (self.userGroupId === null) {
                    if (self.extension() === "") {
                        _facade.getExtensions()
                            .fail(promise.reject)
                            .done((takenExtensions) => {
                                const extension = _extensionsFormatter.getNextAvailableExtension(2000, takenExtensions);
                                self.extension(extension);
                            });
                    }
                    const initialMembers = [{"subscriberId": _userId}];
                    _initializeMembers(initialMembers)
                        .fail(promise.reject)
                        .done(promise.resolve);
                } else {
                    _facade.getUserGroup(self.userGroupId, _userId)
                        .fail(promise.reject)
                        .done((userGroup) => {
                            self.userGroupId = userGroup.userGroupId;
                            self.groupName(userGroup.groupName);
                            self.extension(userGroup.extension);
                            self.isGroupNameEnabled(userGroup.isGroupNameEnabled);
                            _initializeMembers(userGroup.members)
                                .fail(promise.reject)
                                .done(promise.resolve);
                        });
                }
            });
        };

        self.userGroupId = null;
        self.isNewEditableUserGroup = ko.observable(false);
        self.modelStateObserver = null;
        self.groupName = ko.observable("").extend({observeState: true});
        self.isGroupNameEnabled = ko.observable(true);
        self.extension = ko.observable("");
        self.members = ko.observableArray([]).extend({observeState: true});
        self.lastItemTooltip = _i18n.t('userGroupProfile:lastItemTooltip');
        self.groupNameTooltip =  ko.pureComputed(() => {
            return  self.isGroupNameEnabled() ?
                "" :
                _i18n.t('userGroupProfile:groupNameTooltip');
        });
        self.addAnotherMessage =  ko.pureComputed(() => {
            return  self.userGroupId === _userGroupConstants.allUserGroupId ?
                _i18n.t('userGroupProfile:allUsersGroupMember') :
                _i18n.t('membershipSelection:addAnotherMessage', {optionText: 'members'});
        });
        self.selectableUsers = ko.pureComputed(() => {
            return _commonState.users().filter(user => user.isSelectable());
        });
        self.availableUsers = ko.computed(() => {
            return self.selectableUsers().map(user => {
                return {
                    avatar: user.avatar(),
                    displayName: user.name,
                    id: user.id,
                    isActive: user.isActive,
                    isDeletable: ko.observable(true),
                    isInvited: user.isInvited,
                    isReadOnly: ko.observable(false),
                    type: user.type,
                };
            });
        });

        self.filterAvailableUsers = (allUsers, selectedUsers) => {
            _sorter.sort(allUsers, "displayName", true);
            return allUsers.filter((user) => {
                return undefined === selectedUsers.find((selectedUser) => {
                    return user.id === selectedUser().id;
                });
            });
        };

        self.forceValidation = () => {
            return _validator.validate();
        };

        self.save = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            deferredObject.resolve();
                            return;
                        }

                        const groupName = self.groupName().trim();
                        const extension = self.extension();
                        const members = self.members();

                        const facadePromise = self.userGroupId ?
                            _facade.updateUserGroup(self.userGroupId, groupName, extension, members) :
                            _facade.addUserGroup(groupName, extension, members);

                        facadePromise
                            .fail(deferredObject.reject)
                            .done((result) => {
                                self.modelStateObserver.saveData();
                                deferredObject.resolve(result);
                            });
                    });
            });
        };

        self.cancelForm = () => {
            self.modelStateObserver.restoreData();
        };

        self.compositionComplete = () => {
            if (self.userGroupId === null && self.isGroupNameEnabled()) {
                self.isNewEditableUserGroup(true);
            }
        };

        self.activate = (userGroupId) => {
            self.userGroupId = userGroupId;

            const FacadeConstructor = require('presentation/settings/userGroup/facades/userGroupProfileFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/userGroup/validators/userGroupProfileValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.defer((initializePromise) => {
                _validator.registerViewModel(self, _facade);
                self.modelStateObserver = new ModelStateObserverConstructor(self, true);

                _initializePage()
                    .fail(initializePromise.reject)
                    .done(function(){
                        self.modelStateObserver.commitData();
                        initializePromise.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});

