define('presentation/loginNotifications/viewModels/accountClosedAndCannotReactivateModalViewModel',[
    'common/promises/promiseFactory'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

