define('presentation/settings/users/viewModels/deactivateUserModalViewModel',['common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/autoAttendantVoiceRouteEnumerations',
        'constants/systemObjectIconNameConstants',
        'constants/userGroupConstants',
        'presentation/settings/users/facades/deactivateUserModalFacade',
        'presentation/settings/users/validators/deactivateUserModalViewModelValidator'
], function() {
    return function (userPresentationObject) {
        const self = this;

        const SorterConstructor = require('common/collections/collectionSorter');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');
        const _userGroupConstants = require('constants/userGroupConstants');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        let _facade = null;
        let _validator = null;

        const PLEASE_SELECT = _voiceRouteEnumerations.pleaseSelect;

        const _getAboutThisUser = () => {
            return _promiseFactory.defer((deferredObject) => {
                const userId = userPresentationObject.userId;
                _facade.getAboutThisUser(userId)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        self.callsRoutedToUser = _formatIntegrityWarnings(result.callsRoutedToUser);
                        self.voicemailBoxes = _formatIntegrityWarnings(result.voicemailBoxes, true);
                        self.callRecordingBoxes = _formatIntegrityWarnings(result.callRecordingBoxes);
                        self.messagingHostedNumbers = _formatIntegrityWarnings(result.messagingHostedNumbers);
                        self.userGroupsUserIsOnlyMemberOf = _formatIntegrityWarnings(result.userGroupsUserIsOnlyMemberOf);

                        self.showCallsRoutedToUserWarning(self.callsRoutedToUser.length > 0);
                        self.showOnlySubscriberVoicemailBoxesWarning(self.voicemailBoxes.length > 0);
                        self.showOnlySubscriberCallRecordingBoxesWarning(self.callRecordingBoxes.length > 0);
                        self.showOnlyAuthorizedMessagerWarning(self.messagingHostedNumbers.length > 0);
                        self.showOnlyMemberOfUserGroupsWarning(self.userGroupsUserIsOnlyMemberOf.length > 0);

                        self.sendAllCallsToSubscriber(PLEASE_SELECT);

                        if (self.showCallsRoutedToUserWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserType = 'constraints';
                        } else if (self.showOnlySubscriberVoicemailBoxesWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserType = 'constraints';
                        } else if (self.showOnlySubscriberCallRecordingBoxesWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserType = 'constraints';
                        } else if (self.showOnlyAuthorizedMessagerWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserType = 'constraints';
                        } else if (self.showOnlyMemberOfUserGroupsWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserType = 'constraints';
                        } else {
                            self.showSendsCallsToQuestion(false);
                            self.deactivateUserType = 'normal';
                        }

                        deferredObject.resolve();
                    });
            });
        };

        const _formatIntegrityWarnings = (integrityWarnings, isVoicemailBoxes = false) => {
            return integrityWarnings.map((integrityWarning) => {
                switch (integrityWarning.icon) {
                    case 'phoneNumber':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.hostedNumber,
                            iconName: _systemObjectIconNames.hostedNumber,
                        });
                    case 'autoAttendant':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.autoAttendant,
                            iconName: _systemObjectIconNames.autoAttendant,
                        });
                    case 'schedule':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.schedule,
                            iconName: _systemObjectIconNames.schedule,
                        });
                    case 'user':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.voicemailBox,
                            iconName: _systemObjectIconNames.user,
                        });
                    case 'userGroup':
                        return Object.assign({}, integrityWarning, {
                            entityType: isVoicemailBoxes ? _commonState.typeDisplay.voicemailBox: _commonState.typeDisplay.userGroup,
                            iconName: _systemObjectIconNames.userGroup,
                        });
                    case 'standalone':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.voicemailBox,
                            iconName: _systemObjectIconNames.voicemailBox,
                        });
                    default:
                        return null;
                }

            });
        };

        self.userPresentationObject = userPresentationObject;
        self.callsRoutedToUser = null;
        self.voicemailBoxes = null;
        self.callRecordingBoxes  = null;
        self.userGroupsUserIsOnlyMemberOf = [];
        self.sendAllCallsToSubscriber = ko.observable();
        self.showCallsRoutedToUserWarning = ko.observable(false);
        self.showOnlySubscriberVoicemailBoxesWarning = ko.observable(false);
        self.showOnlySubscriberCallRecordingBoxesWarning = ko.observable(false);
        self.showOnlyAuthorizedMessagerWarning = ko.observable(false);
        self.showOnlyMemberOfUserGroupsWarning = ko.observable(false);
        self.showSendsCallsToQuestion = ko.observable(true);
        self.canDisplayValidation = ko.observable(false);
        self.deactivateUserType = null;
        self.placeholderMessage = _i18n.t('deactivateUserModal:pleaseSelect');

        self.areYouSureKey = ko.pureComputed(() => {
            return self.userPresentationObject.isInvitedStatus() ? 'areYouSure_cancel' : 'areYouSure_deactivate';
        });

        self.showDeactivateUserWarningBox = ko.pureComputed(() => self.deactivateUserType === 'constraints');
        self.deactivateUserWarningMessages = ko.observableArray([
            {
                message: _i18n.t('deactivateUserModal:proceedWarning', {firstName: userPresentationObject.firstName}),
                listItems: []
            },
            {
                message: _i18n.t('deactivateUserModal:proceedInstruct', {firstName: userPresentationObject.firstName}),
                listItems: []
            }
        ]);

        self.availableSubscribers = ko.pureComputed(() => {
            const users = _commonState.users().reduce((accumulator, user) => {
                if (user.isActive() && user.id !== userPresentationObject.userId) {
                    accumulator.push({
                        id: user.id,
                        subscriberType: user.type,
                        displayName: user.name(),
                        avatar: user.avatar()
                    });
                }
                return accumulator;
            }, []);
            const userGroups = _commonState.userGroups().reduce((accumulator, userGroup) => {
                if (userGroup.isActive()) {
                    accumulator.push({
                        id: _commonState.resolveGroupIdToGuid(userGroup.id),
                        subscriberType: userGroup.type,
                        displayName: userGroup.name(),
                        avatar: userGroup.avatars()
                    });
                }
                return accumulator;
            }, []);
            const availableSubscribers = users.concat(userGroups);
            const sorter = new SorterConstructor();
            sorter.sort(availableSubscribers, "displayName", true);
            return availableSubscribers;
        });
        
        self.sendAllCallsToDefaultSubscriberSelection = ko.pureComputed(() => {
            const systemAdminsId = _commonState.resolveGroupIdToGuid(_userGroupConstants.systemAdminsGroupId);
            return self.availableSubscribers().find((subscriber) => {
                return subscriber.id === systemAdminsId;
            });
        });

        self.disabledClick = () => _validator.validate();

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                if ((self.showSendsCallsToQuestion() === false) || self.userPresentationObject.isInvitedStatus()) {
                    self.sendAllCallsToSubscriber(self.sendAllCallsToDefaultSubscriberSelection());
                }
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            self.canDisplayValidation(true);
                            deferredObject.resolve({isValid: isValid});
                            return;
                        }

                        const userId = userPresentationObject.userId;
                        const sendAllCallsTo = ko.unwrap(self.sendAllCallsToSubscriber());
                        if (self.deactivateUserType === 'constraints') {
                            _facade.deactivateConstrainedUser(userId, sendAllCallsTo)
                                .fail(deferredObject.reject)
                                .done(deferredObject.resolve);
                        } else {
                            _facade.deactivateUser(userId, sendAllCallsTo)
                                .fail(deferredObject.reject)
                                .done(deferredObject.resolve);
                        }
                    });
            });
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/users/facades/deactivateUserModalFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/users/validators/deactivateUserModalViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _getAboutThisUser();
            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();   
        };
    };
});
