define('presentation/settings/billing/paymentMethod/validators/addPaymentMethodValidator',[
    'businessServices/country/countryCode',
    'businessServices/creditCards/creditCardValidations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const CountryCodeConstructor = require('businessServices/country/countryCode');
        const CreditCardValidationConstructor = require('businessServices/creditCards/creditCardValidations');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const _creditCardValidations = new CreditCardValidationConstructor();
        const _countries = new CountryCodeConstructor();

        let _commonValidator = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const _cvvI18nKey = _viewModel.isEditScreen() ? 'addPaymentMethod:cvvRequiredToEdit' : 'addPaymentMethod:cvvRequiredToAdd';

            const validationRules = new ValidationRulesConstructor();

            if (!_viewModel.isEditScreen()) {
                validationRules.field("cardNumber")
                    .addValidationI18n(_commonValidator.isStringWithValue, 'addPaymentMethod:cardNumberRequired')
                    .addValidationI18n(_creditCardValidations.validateCardNumber, 'addPaymentMethod:cardNumberInvalid');
            }

            validationRules.field("cardHolderName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addPaymentMethod:nameOnCardRequired')
                .addValidationI18n(_noSpecialCharacters, 'addPaymentMethod:noSpecialCharacters');
            validationRules.field('cvvSecurityCode')
                .addValidationI18n(_commonValidator.isStringWithValue, _cvvI18nKey)
                .addValidationI18n(_isCvvValid, 'addPaymentMethod:cvvInvalid');
            validationRules.fieldGroup("expirationDate", ["monthSelected", "yearSelected"])
                .addValidationI18n(_validateExpirationDate, 'addPaymentMethod:expirationDateInvalid');
            validationRules.field("postalCode")
                .addValidationI18n(_commonValidator.isStringWithValue, _postalCodeRequiredI18nKey)
                .addValidationI18n(_validatePostalCodeValid, _postalCodeInvalidI18nKey);

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        const _noSpecialCharacters = (name) => /^[a-zA-Z0-9 ']*$/.test(name);
    
        const _isCvvValid = (cvv) => _creditCardValidations.validateCvv(cvv, _viewModel.cardNumber());

        const _removeSpacesFromString = (string) => string.replace(/\s+/g, '');

        const _validatePostalCodeValid = (postalCode) => {
            return _creditCardValidations.validatePostalCodeFormat(_removeSpacesFromString(postalCode), _currentCountry());
        };

        const _validateExpirationDate = (monthSelected, yearSelected) => {
            return _creditCardValidations.validateExpirationDate(monthSelected, yearSelected);
        };
    
        const _currentCountry = ko.pureComputed(() => {
            if (_viewModel.countrySelected()) {
                return _countries.findByAbbreviation(_viewModel.countrySelected());
            }
        
            return _countries.getDefaultCountry();
        });
    
        const _postalCodeRequiredI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'addPaymentMethod:postalCodeRequired' : 'addPaymentMethod:zipCodeRequired');
    
        const _postalCodeInvalidI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'addPaymentMethod:postalCodeInvalid' : 'addPaymentMethod:zipCodeInvalid');
    };
});

