define('businessServices/authentication/authenticationManager',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketAuthentication',
    'externalDependencies/webSocket',
    'businessServices/authentication/stores/impersonationAuthenticationStore',
    'businessServices/authentication/stores/standardAuthenticationStore',
    'businessServices/authentication/stores/frontendAuthenticationStore',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/authentication/sessionUserInfo',
    'businessServices/authentication/sessionWorkflowFlags',
    'businessServices/authentication/featureToggleSettings',
    'businessServices/login/accountStatusEvaluator',
    'businessServices/login/logoutRouteEvaluator',
    'businessServices/login/manageCommonState',
    'businessServices/messages/messagesStateSingleton',
    'businessServices/events/eventManager',
    'common/storage/workSessionIdStorage',
    'businessServices/events/webSocketEventManager',
    'presentation/analytics/viewModels/analyticsViewModel',
    'presentation/inbox/dataSources/voicemailSubscriptionDataSource',
    'presentation/inbox/viewModels/inboxVoicemailSidebarViewModel',
    'presentation/inbox/dataSources/recordingSubscriptionDataSource',
    'presentation/inbox/viewModels/inboxCallRecordingsSidebarViewModel',
    'presentation/messages/dataSources/webMessagingDataSource',
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('persistence/webSocket/webSocketAuthentication') */
    WebSocketAuthentication,
    /** @type typeof import('externalDependencies/webSocket') */
    WebSocket
) {
    const _promiseFactory = new PromiseFactory();
    const _webSocketAuthentication = new WebSocketAuthentication();
    _webSocketAuthentication.init();

    const webSocketEventManager = require('businessServices/events/webSocketEventManager');

    const _authenticationState = {
        loginType: /** @type {string} */(null),
        accountId: /** @type {string} */(null),
        authenticationToken: /** @type {string} */(null),
        userId: /** @type {string} */(null),
        userStatus: /** @type {string} */(null),
        accountPermissions: /** @type {string} */(null),
        accountFeatureToggles: /** @type {string} */(null),
        userDeviceIsMobilePhone: /** @type {Boolean} */(null),
        isInFreeTrial: /** @type {Boolean} */(null),
        trialPeriodStart: /** @type {string} */ (null),
        trialPeriodEnd: /** @type {string} */ (null),
        isInviteInfoCapture: /** @type {Boolean} */(null),
        isCreationInProgress: /** @type {Boolean} */(null),
        workSessionId : /** @type {string} */(null),
        systemAdmin: /** @type {string} */(null),
        billingAdmin: /** @type {string} */(null)
    };

    /** @type {LoginStatusesEnum} */
    const LOGIN_STATUSES = {
        deactivated_user: "deactivated_user",
        invalid_user_name: "invalid_user_name",
        invalid_password: "invalid_password",
        invite_pending: "invite_pending",
        invite_expired: "invite_expired",
        success: "success",
        is_creation_in_progress: "is_creation_in_progress"
    };

    let _suppressNotifications = false;

    /**
     * @returns {IAuthenticationListeningModule[]}
     */
    function getInterestedModules() {
        return [
            // Change application state
            require('businessServices/authentication/stores/impersonationAuthenticationStore'),
            require('businessServices/authentication/stores/standardAuthenticationStore'),
            require('businessServices/authentication/stores/frontendAuthenticationStore'),
            require('businessServices/authentication/sessionAccountInfo'),
            require('businessServices/authentication/sessionUserInfo'),
            require('businessServices/authentication/sessionWorkflowFlags'),
            require('externalDependencies/clientWebSocket'),
            require('businessServices/login/accountStatusEvaluator'),
            require('businessServices/login/manageCommonState'),
            require('businessServices/authentication/featureToggleSettings'),
            // Respond to new state
            require('businessServices/login/logoutRouteEvaluator'),
            require('presentation/analytics/viewModels/analyticsViewModel'),
            require('presentation/inbox/dataSources/voicemailSubscriptionDataSource'),
            require('presentation/inbox/viewModels/inboxVoicemailSidebarViewModel'),
            require('presentation/inbox/dataSources/recordingSubscriptionDataSource'),
            require('presentation/inbox/viewModels/inboxCallRecordingsSidebarViewModel'),
            require('presentation/messages/dataSources/webMessagingDataSource'),
            require('businessServices/messages/messagesStateSingleton'),
            require('businessServices/contacts/contactsStateSingleton')
        ];
    }

    function clearAuthenticationState() {
        _authenticationState.loginType = null;
        _authenticationState.accountId = null;
        _authenticationState.authenticationToken = null;
        _authenticationState.userId = null;
        _authenticationState.userStatus = null;
        _authenticationState.workSessionId = null;
        _authenticationState.systemAdmin = null;
        _authenticationState.billingAdmin = null;
        _authenticationState.accountPermissions = null;
        _authenticationState.userDeviceIsMobilePhone = null;
        _authenticationState.isInFreeTrial = null;
        _authenticationState.trialPeriodStart = null;
        _authenticationState.trialPeriodEnd = null;
        _authenticationState.isInviteInfoCapture = null;
        _authenticationState.isCreationInProgress = null;
    }

    function notifyLoginSuccessful() {
        return _promiseFactory.defer(function(promise) {
            if (_authenticationState.workSessionId !== null) {
                const workSessionIdStorage = require('common/storage/workSessionIdStorage');
                workSessionIdStorage.setWorkSessionId(_authenticationState.workSessionId);
            }

            if (_suppressNotifications) {
                promise.resolve();
            } else {
                const modules = getInterestedModules();
                const loginInfo = {
                    loginType: _authenticationState.loginType,
                    accountId: _authenticationState.accountId,
                    authenticationToken: _authenticationState.authenticationToken,
                    userId: _authenticationState.userId,
                    userStatus: _authenticationState.userStatus,
                    workSessionId: _authenticationState.workSessionId,
                    accountPermissions: _authenticationState.accountPermissions,
                    accountFeatureToggles: _authenticationState.accountFeatureToggles,
                    userDeviceIsMobilePhone: _authenticationState.userDeviceIsMobilePhone,
                    isInFreeTrial: _authenticationState.isInFreeTrial,
                    trialPeriodStart: _authenticationState.trialPeriodStart,
                    trialPeriodEnd: _authenticationState.trialPeriodEnd,
                    isInviteInfoCapture: _authenticationState.isInviteInfoCapture,
                    isCreationInProgress: _authenticationState.isCreationInProgress,
                };

                notifyNextLoginSuccessful(modules, loginInfo, promise);
            }
        });
    }

    /**
     * @param {IAuthenticationListeningModule[]} remainingModules
     * @param {ILoginInfoForModule} loginInfo
     * @param {JQueryDeferred<any>} allDonePromise
     */
    function notifyNextLoginSuccessful(remainingModules, loginInfo, allDonePromise) {
        if (remainingModules.length === 0) {
            allDonePromise.resolve();
        } else {
            const module = remainingModules[0];
            _promiseFactory.convertValueToPromise(module.alerts.receiveAlertLoginSuccessful(loginInfo))
                .fail(allDonePromise.reject)
                .done(function() {
                    const nextModules = remainingModules.slice(1);
                    notifyNextLoginSuccessful(nextModules, loginInfo, allDonePromise);
                });
        }
    }

    function notifyLogout() {
        return _promiseFactory.defer(function(promise) {
            const previousWorkSessionId = _authenticationState.workSessionId;
            clearAuthenticationState();

            if (_suppressNotifications) {
                promise.resolve();
            } else {
                const notificationsCompletedPromise = _promiseFactory.defer();

                const modules = getInterestedModules();
                notifyNextLogout(modules, notificationsCompletedPromise);

                notificationsCompletedPromise
                    .fail(promise.reject)
                    .done(function() {
                        if (previousWorkSessionId !== null) {
                            const workSessionIdStorage = require('common/storage/workSessionIdStorage');
                            workSessionIdStorage.deleteWorkSessionId();
                        }

                        promise.resolve();
                    });
            }
        });
    }

    /**
     * @param {IAuthenticationListeningModule[]} remainingModules
     * @param {JQueryDeferred<any>} allDonePromise
     */
    function notifyNextLogout(remainingModules, allDonePromise) {
        if (remainingModules.length === 0) {
            allDonePromise.resolve();
        } else {
            const module = remainingModules[0];
            _promiseFactory.convertValueToPromise(module.alerts.receiveAlertLogout())
                .fail(allDonePromise.reject)
                .done(function() {
                    const nextModules = remainingModules.slice(1);
                    notifyNextLogout(nextModules, allDonePromise);
                });
        }
    }

    /**
     * @param {any} authenticationResult
     * @param {string} loginType
     * @param {string} accountId
     * @param {string} token
     * @param {string} userId
     */
    const _setAuthenticationState = (authenticationResult, loginType, accountId = authenticationResult.accountId, token = authenticationResult.authenticationToken, userId = authenticationResult.userId) => {
        _authenticationState.accountId = accountId;
        _authenticationState.accountPermissions = authenticationResult.accountPermissions;
        _authenticationState.authenticationToken = token;
        _authenticationState.isCreationInProgress = authenticationResult.isCreationInProgress;
        _authenticationState.isInFreeTrial = authenticationResult.isInFreeTrial;
        _authenticationState.trialPeriodStart = authenticationResult.trialPeriodStart;
        _authenticationState.trialPeriodEnd = authenticationResult.trialPeriodEnd;
        _authenticationState.isInviteInfoCapture = authenticationResult.isInviteInfoCapture;
        _authenticationState.loginType = loginType;
        _authenticationState.userDeviceIsMobilePhone = authenticationResult.userDeviceIsMobilePhone;
        _authenticationState.userId = userId;
        _authenticationState.userStatus = authenticationResult.userStatus;
        _authenticationState.workSessionId = authenticationResult.workSessionId;
    };

    /**
     * @param {string} loginType
     * @param {IImpersonationResult} authenticationResult
     * @param {JQueryDeferred<{ success: boolean }>} promise
     */
    const _loginSuccessful = (loginType, authenticationResult, promise) => {
        _setAuthenticationState(authenticationResult, loginType);

        const validCredentials = {
            success: true
        };

        notifyLoginSuccessful()
            .fail(promise.reject)
            .done(function() {
                promise.resolve(validCredentials);
            });
    };

    /**
     * @param {string} username
     * @param {string} password
     * @returns {JQueryDeferred<{ success: boolean, errorMessage?: string }>}
     */
    function loginWithCredentials(username, password) {
        return _promiseFactory.defer(function(promise) {
            _webSocketAuthentication.loginUser(username, password)
                .fail(promise.reject)
                .done(function(authenticationResult) {
                    switch (authenticationResult.status) {
                        case LOGIN_STATUSES.deactivated_user:
                        case LOGIN_STATUSES.invalid_user_name:
                        case LOGIN_STATUSES.invalid_password:
                        case LOGIN_STATUSES.invite_pending:
                        case LOGIN_STATUSES.invite_expired: {
                            clearAuthenticationState();
                            const invalidCredentials = {
                                success : false,
                                errorMessage : authenticationResult.status
                            };

                            promise.resolve(invalidCredentials);
                            break;
                        }
                        case LOGIN_STATUSES.is_creation_in_progress: {
                            clearAuthenticationState();
                            const signupCredentials = {
                                success : false,
                                isSignupInProgress: true,
                                signupSessionId: authenticationResult.signupSessionId,
                                secretKey: authenticationResult.secretKey,
                            };
                            promise.resolve(signupCredentials);
                            break;
                        }
                        case LOGIN_STATUSES.success:
                            _loginSuccessful("standard", authenticationResult, promise);
                            break;
                        default: {
                            clearAuthenticationState();
                            const authenticationError = new Error("Invalid response message");
                            authenticationError.status = authenticationResult.status;
                            promise.reject(authenticationError);
                        }
                    }
                });
        });
    }

    /**
     * @param {string} loginToken
     * @param {string} accountId
     * @param {string} userId
     * @returns {JQueryDeferred<boolean>}
     */
    function loginWithToken(loginToken, accountId, userId) {
        return _promiseFactory.defer(function(promise) {
            _webSocketAuthentication.loginToken(loginToken, accountId, userId)
                .fail(promise.reject)
                .done(function(authenticationResult) {
                    if (authenticationResult.status === "success") {
                        _setAuthenticationState(authenticationResult, "standard", accountId, loginToken, userId);

                        notifyLoginSuccessful()
                            .fail(promise.reject)
                            .done(function() {
                                promise.resolve(true);
                            });
                    } else {
                        promise.resolve(false);
                    }
                });
        });
    }

    /**
     * @param {any} impersonationNonce
     * @returns {JQueryDeferred<{ success: boolean, errorMessage?: string }>}
     */
    function loginWithImpersonationNonce(impersonationNonce) {
        return _promiseFactory.defer(function(promise) {
            const WebSocketApp = require('persistence/webSocket/webSocketApp');
            const webSocket = new WebSocketApp();
            webSocket.init("authentication_impersonation");

            const params = {
                impersonationNonce: impersonationNonce
            };

            webSocket.send("impersonate_nonce", params, function (/** @type {IImpersonationResult} */impersonationResult) {
                if (impersonationResult.status === "success") {
                    _loginSuccessful("impersonation", impersonationResult, promise);
                } else {
                    const invalid = {
                        success: false,
                        errorMessage: impersonationResult.status
                    };
                    promise.resolve(invalid);
                }
            });
        });
    }

    /**
     * @param {string} impersonationToken
     * @param {string} accountId
     * @param {string} userId
     * @param {string} workSessionId
     * @returns 
     */
    function loginWithImpersonationToken(impersonationToken, accountId, userId, workSessionId) {
        return _promiseFactory.defer(function(promise) {
            const WebSocketApp = require('persistence/webSocket/webSocketApp');
            const webSocket = new WebSocketApp();
            webSocket.init("authentication_impersonation");

            const params = {
                impersonationToken : impersonationToken,
                accountId : accountId,
                userId : userId,
                workSessionId : workSessionId
            };

            webSocket.send("impersonate_token", params, function (/** @type {IImpersonationResult} */impersonationResult) {
                if (impersonationResult.status === "success") {
                    _loginSuccessful("impersonation", impersonationResult, promise);

                } else {
                    const invalid = {
                        success : false,
                        errorMessage : impersonationResult.status
                    };
                    promise.resolve(invalid);
                }
            });
        });
    }

    /**
     * @param {IWorkSessionInfo} eventData
     * @returns {JQueryDeferred<never>}
     */
    function userSessionExpired(eventData) {
        return _promiseFactory.defer(function(promise) {
            const workSessionId = eventData.workSessionId;
            if (workSessionId === _authenticationState.workSessionId) {
                switch (_authenticationState.loginType) {
                    case "standard":
                        logout()
                            .done(promise.resolve)
                            .fail(promise.reject);
                        break;
                    case "impersonation":
                        promise.resolve();
                        break;
                    default:
                        promise.resolve();
                        break;
                }
            } else {
                promise.resolve();
            }
        });
    }

    /**
     * @param {IWorkSessionInfo} eventData
     * @returns {JQueryDeferred<never>}
     */
    function userImpersonationExpired(eventData) {
        return _promiseFactory.defer(function(promise) {
            const workSessionId = eventData.workSessionId;
            if (workSessionId === _authenticationState.workSessionId) {
                switch (_authenticationState.loginType) {
                    case "standard":
                        promise.resolve();
                        break;
                    case "impersonation":
                        logout()
                            .done(promise.resolve)
                            .fail(promise.reject);
                        break;
                    default:
                        promise.resolve();
                        break;
                }
            } else {
                promise.resolve();
            }
        });
    }

    /**
     * @returns {JQueryDeferred<never>}
     */
    function logout() {
        return _promiseFactory.defer(function(promise) {
            _webSocketAuthentication.logout(_authenticationState.authenticationToken)
                .done(function() {
                    notifyLogout()
                        .done(function() {
                            promise.resolve();
                        })
                        .fail(function(error) {
                            promise.reject(error);
                        });
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    function onWebSocketReconnected() {
        switch (_authenticationState.loginType) {
            case "standard":
                _suppressNotifications = true;
                loginWithToken(_authenticationState.authenticationToken, _authenticationState.accountId, _authenticationState.userId)
                    .done(function() {
                        _suppressNotifications = false;
                    })
                    .fail(function() {
                        _suppressNotifications = false;
                    });
                break;
            case "impersonation":
                _suppressNotifications = true;
                loginWithImpersonationToken(_authenticationState.authenticationToken, _authenticationState.accountId, _authenticationState.userId, _authenticationState.workSessionId)
                    .done(function() {
                        _suppressNotifications = false;
                    })
                    .fail(function() {
                        _suppressNotifications = false;
                    });
                break;
            default:
                // Not logged in
                return;
        }
    }

    function onAuthenticationExpired() {
        if (_authenticationState.loginType !== null) {
            const WebSocketAuthenticationConstructor = require('persistence/webSocket/webSocketAuthentication');
            const webSocketAuthentication = new WebSocketAuthenticationConstructor();
            webSocketAuthentication.init();
            webSocketAuthentication.logout()
                .done(function() {
                    notifyLogout();
                })
                .fail(function() {
                    notifyLogout();
                });
        }
    }

    WebSocket.on.reconnect(onWebSocketReconnected);

    webSocketEventManager.subscribeUserSessionExpired(userSessionExpired);
    webSocketEventManager.subscribeUserImpersonationSessionExpired(userImpersonationExpired);
    webSocketEventManager.subscribeUserLogout(logout);

    const _eventManager = require('businessServices/events/eventManager');
    _eventManager.subscribeAuthenticationExpired(onAuthenticationExpired);

    return {
        loginWithCredentials : loginWithCredentials,
        loginWithToken : loginWithToken,
        loginWithImpersonationNonce : loginWithImpersonationNonce,
        loginWithImpersonationToken : loginWithImpersonationToken,
        logout : logout,
        login_statuses : LOGIN_STATUSES
    };
});

