define('businessServices/googleAnalytics/conversionTracking',['settings/settings'], function () {

    var _settings = require('settings/settings');

    function googleReportConversion(value, currency) {
        var conversionId = _settings.googleAdWordsConversionId;
        var conversionLabel = _settings.googleAdWordsConversionLabel;
        if(conversionId !== "0" && conversionLabel !== "") {
            window.google_conversion_id = parseInt(conversionId, 10);
            window.google_conversion_label = conversionLabel;
            window.google_remarketing_only = false;
            if (value !== undefined) {
                window.google_conversion_value = value;
                window.google_conversion_currency = currency;
            }
            window.google_conversion_format = "3";
            window.google_is_call = true;
            var opt = {};
            var conv_handler = window['google_trackConversion'];
            if (typeof(conv_handler) === 'function') {
                conv_handler(opt);
            }
        }
    }

    return {
        googleReportConversion: googleReportConversion
    };
});

