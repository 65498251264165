define('presentation/common/overlayScrollbars',[
    'businessServices/events/eventManager'
], function (
    /** @type import('businessServices/events/eventManager') */
    EventManager
) {
    /** @typedef {import('presentation/common/overlayScrollbars')} IOverlayScrollbars */

    /**
     * @param {() => IOverlayScrollbarsOptions} valueAccessor
     */
    const _unwrapOptions = (valueAccessor) => {
        const { height, onlyIf, overflowX, overflowY, scrollBuffer, scrollEventSource, scrollIntoView, scrollIntoViewElement, scrollPosition, reachedEnd } = valueAccessor();

        return {
            onlyIf: ko.unwrap(onlyIf),
            overflowX: ko.unwrap(overflowX),
            overflowY: ko.unwrap(overflowY),
            scrollBuffer: ko.unwrap(scrollBuffer),
            scrollEventSource: scrollEventSource,
            scrollIntoView: ko.unwrap(scrollIntoView),
            height: ko.unwrap(height),
            scrollIntoViewElement: ko.unwrap(scrollIntoViewElement),
            scrollPosition: scrollPosition,
            reachedEnd: reachedEnd
        };
    };

    /**
     * @param {() => IOverlayScrollbarsOptions} valueAccessor
     */
    const _parseOptions = (valueAccessor) => {
        const options = _unwrapOptions(valueAccessor);
        let { height, onlyIf, overflowX, overflowY, scrollBuffer, scrollIntoView } = options;
        const { scrollIntoViewElement, scrollEventSource, scrollPosition, reachedEnd } = options;

        onlyIf = typeof onlyIf === "boolean" ? onlyIf : true;
        scrollBuffer = typeof scrollBuffer === "number" ? scrollBuffer : 200;
        scrollIntoView = typeof scrollIntoView === "boolean" ? scrollIntoView : false;
        overflowX = overflowX || "scroll";
        overflowY = overflowY || "scroll";
        height = height || null;

        return {
            onlyIf: onlyIf,
            overflowX: overflowX,
            overflowY: overflowY,
            scrollBuffer: scrollBuffer,
            scrollEventSource: scrollEventSource,
            scrollIntoView: scrollIntoView,
            height: height,
            scrollIntoViewElement: scrollIntoViewElement,
            scrollPosition: scrollPosition,
            reachedEnd: reachedEnd
        };
    };

    return {
        /** @type {IOverlayScrollbars["init"]} */
        init: function (element, valueAccessor) {
            const { height, onlyIf, overflowX, overflowY, scrollBuffer, scrollEventSource, scrollIntoView, scrollIntoViewElement, scrollPosition, reachedEnd } = _parseOptions(valueAccessor);

            if (onlyIf === false) {
                return;
            }

            const scrollbarInstance = $(element).overlayScrollbars({
                className: "os-theme-tresta",
                overflowBehavior: {
                    x: overflowX,
                    y: overflowY
                },
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 100
                },
                sizeAutoCapable: false,
                callbacks : {
                    onScroll: () => {
                        const scroll = scrollbarInstance.scroll();
                        const scrollPositionY = scroll.position.y;

                        if (scrollBuffer && scrollEventSource) {
                            if (scrollPositionY < scrollBuffer) {
                                EventManager.publishTopScrollBufferReached(scrollEventSource);
                            }

                            if ((scroll.max.y - scrollPositionY) < scrollBuffer) {
                                EventManager.publishBottomScrollBufferReached(scrollEventSource);
                            }
                        }

                        if (scrollPosition) {
                            const { contentScrollSize, viewportSize } = scrollbarInstance.getState();
                            if (!viewportSize.width) {
                                return;
                            }

                            if (reachedEnd) {
                                const pages = Math.floor(contentScrollSize.height / viewportSize.height);
                                const currentPage = Math.ceil(scrollPositionY / viewportSize.height);
                                reachedEnd((currentPage + 1) >= pages);
                            }

                            scrollPosition(scrollPositionY);
                        }
                    },
                    onContentSizeChanged: () => {
                        if (scrollIntoView && scrollIntoViewElement) {
                            _scrollIntoView();
                        }
                    },
                }
            }).overlayScrollbars();

            if (height) {
                $(element).find('.os-content').height(height);
            }

            const _scrollIntoView = () => {
                if (scrollbarInstance) {
                    const element = scrollIntoViewElement;
                    scrollbarInstance.scroll({ el : $('.' + element), block : "end" }, 200);
                }
            };

            if (scrollPosition && scrollPosition()) {
                scrollbarInstance.scroll({ y: scrollPosition() });
            }

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (scrollbarInstance) {
                    scrollbarInstance.destroy();
                }
            });
        }
    };
});
