define('presentation/settings/users/viewModels/reactivateUserModalViewModel',['common/promises/promiseFactory',
        'common/url/urlFormatter',
        'presentation/settings/users/facades/reactivateUserModalFacade',
], function() {
    return function (userPresentationObject) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _urlFormatter = require('common/url/urlFormatter');
        const _userId = userPresentationObject.userId;

        let _facade = null;

        self.userPresentationObject = userPresentationObject;
        self.pricingUrl = "";

        self.areYouSureKey = ko.pureComputed(() => {
            return self.userPresentationObject.isExpiredStatus() ? 'areYouSure_resend' : 'areYouSure_reactivate';
        });

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                if (self.userPresentationObject.isExpiredStatus()) {
                    _facade.resendInvite(_userId)
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                } else {
                    _facade.sendReactivation(_userId)
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                }
            });
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/users/facades/reactivateUserModalFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            self.pricingUrl = _urlFormatter.buildFrontendUrl(["pricing"]);

            return _promiseFactory.wait();
        };
    };
});

