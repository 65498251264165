define('presentation/settings/users/presentationObjects/usersPresentationObject',['i18next'],function() {
    return function(){
        const self = this;
        const _i18n = require('i18next');
        
        self.userId = null;
        self.firstName = null;
        self.lastName = null;
        self.fullName = ko.observable(null);
        self.emailAddress = null;
        self.extension = null;
        self.allGroups = null;
        self.groups = null;
        self.availableGroups = [];
        self.status = ko.observable(null);
        self.isActiveStatus = ko.pureComputed(() => self.status() === "active");
        self.isInvitedStatus = ko.pureComputed(() => self.status() === "invited");
        self.isInviteExpired = ko.pureComputed(() => self.inviteStatus() === "expired");
        self.isExpiredStatus = ko.pureComputed(() => self.status() === "expired");
        self.isDeactivatedStatus = ko.pureComputed(() => self.status() === "deactivated");
        self.isItemPendingView = ko.computed(() => self.isInvitedStatus() || self.isExpiredStatus());
        self.isUserSelf = ko.observable(false);
        self.password = null;
        self.confirmPassword = null;
        self.userAvatar = ko.observable();
        self.hasAvatar = null;
        self.editUrl = null;
        self.createdDateTime = null;
        self.defaultDevice = null;
        self.invitedByFullName = null;
        self.inviteStatus = ko.observable(null);
        self.inviteSentDateTime = null;

        self.deactivateTooltipText = ko.pureComputed(() => {
            if (self.isUserSelf()) {
                return _i18n.t('users:deactivateSelf');
            } else if (self.isInvitedStatus() && !self.isExpiredStatus()) {
                return _i18n.t('users:cancelInvite');
            } else {
                return '';
            }
        });

        self.reactivateTooltipText = ko.pureComputed(() => {
            if (self.isInviteExpired() || self.isExpiredStatus()) {
                return _i18n.t('users:resendInvite');
            } else {
                return _i18n.t('users:reactivate');
            }
        });
    };
});

