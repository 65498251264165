define('presentation/messages/presentationObjects/conversationRecipientsPresentationObject',[
    'businessServices/blocking/blockingStateSingleton',
    'businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton',
    'common/converters/phoneNumberFormatter',
    'i18next'
], function (
    /** @type import('businessServices/blocking/blockingStateSingleton') */
    _blockingState,
    /** @type import('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton') */
    _smsPhoneNumberOptOutsState,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatterConstructor
) {
    const _i18n = require('i18next');
    const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    return function () {
        const self = this;

        self.id = null;
        self.phoneNumber = ko.observable('');
        self.fromPhoneNumber = ko.observable('');
        self.meta = null;
        self.isAutoCompleteOption = null;
        self.isContact = false;
        self.contact = null;

        self.isBlockedContact = ko.pureComputed(() => {
            return _blockingState.blockedPhoneNumbers().some((x) => x.phoneNumber === _phoneNumberFormatter.toEOneSixFour(self.phoneNumber()));
        });

        self.isPhoneNumberOptedOut = ko.pureComputed(() => {
            return _smsPhoneNumberOptOutsState.getOptedOutPhoneNumbers(self.fromPhoneNumber(), [self.phoneNumber()]).length > 0;
        });

        self.isRecipientBlockedOrUnsubscribed = ko.pureComputed(() => {
            return self.isBlockedContact() || self.isPhoneNumberOptedOut();
        });

        self.buildMeta = (/** @type {boolean} **/isShortCode, /** @type {boolean} **/ isTollFree, /** @type {boolean} **/ location) => {
            if (isShortCode) {
                return self.meta = _i18n.t('shortCode');
            }

            if (isTollFree) {
                return self.meta = _i18n.t('tollFree');
            }

            if (location) {
                // expects formatted as "City, Region"
                return self.meta = location;
            }
            return self.meta = _i18n.t('unknownLocation');
        };
    };
});
