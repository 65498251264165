define('presentation/settings/users/facades/usersFacade',[
    'businessServices/authentication/sessionAccountInfo',
    'common/storage/commonState',
    'persistence/webSocket/webSocketApp',
    'presentation/common/dateTimeValue',
    'presentation/settings/users/presentationObjects/usersPresentationObject',
    'settings/navigationConfiguration'
], function() {
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const UsersPresentationObjectConstructor = require('presentation/settings/users/presentationObjects/usersPresentationObject');

    const _commonState = require('common/storage/commonState');
    const _i18n = require('i18next');
    const _navigationConfiguration = require('settings/navigationConfiguration');
    const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

    let _promiseFactory = null;
    let _webSocketApp = null;

    const _getAllUsers = (showFilter) => {
        return _promiseFactory.defer((deferredObject) => {
            let request = {showFilter: showFilter};
            _webSocketApp.send("get_all_users", request, (result) => {
                let userPresentationObjects = result.users.map((user) => {
                    const commonStateItem = _commonState.get(user.userId);
                    let userPresentationObject = new UsersPresentationObjectConstructor();
                    userPresentationObject.userId = user.userId;
                    userPresentationObject.modifiedDateTime = new DateTimeValueConstructor(user.modifiedDateTime);
                    userPresentationObject.status(user.status);
                    userPresentationObject.extension = user.extension;
                    userPresentationObject.editUrl = _navigationConfiguration.routesById.editUser.baseUrl + user.userId;
                    userPresentationObject.emailAddress = user.emailAddress;
                    userPresentationObject.groups = user.groups;

                    if (userPresentationObject.userId === _sessionAccountInfo.userId()) {
                        userPresentationObject.isUserSelf(true);
                    }

                    if (user.firstName === "" && user.lastName === ""){
                        userPresentationObject.fullName(_i18n.t('users:invitedUser'));
                        userPresentationObject.firstName = _i18n.t('users:invitedUser');
                    } else {
                        userPresentationObject.fullName = commonStateItem.name;
                        userPresentationObject.firstName = user.firstName;
                    }

                    if (user.hasAvatar) {
                        userPresentationObject.userAvatar(commonStateItem.avatar());
                        userPresentationObject.hasAvatar = commonStateItem.avatar().showImage();
                    } else {
                        userPresentationObject.hasAvatar = user.hasAvatar;
                    }

                    if (userPresentationObject.isActiveStatus() || userPresentationObject.isDeactivatedStatus()) {
                        userPresentationObject.createdDateTime = new DateTimeValueConstructor(user.createdDateTime);
                    } else if (userPresentationObject.isInvitedStatus() || userPresentationObject.isExpiredStatus()) {
                        userPresentationObject.inviteStatus(user.inviteInformation.status);
                        userPresentationObject.invitedByFullName = user.inviteInformation.invitedByUserFullName;
                        userPresentationObject.createdDateTime = new DateTimeValueConstructor(user.inviteInformation.inviteSentDateTime);
                    } else {
                        let error = new Error("unexpected user status");
                        error.status = user.status;
                        deferredObject.reject(error);
                    }

                    return userPresentationObject;
                });

                deferredObject.resolve(userPresentationObjects);
            });
        });
    };

    const _isUserDeletable = (userId) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketApp.send("is_user_deletable", {userId: userId}, (constraints) => {
                deferredObject.resolve(constraints);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketActivityAppConstructor();
        _webSocketApp.init("users_listing");
    };
    
    return function() {
        let self = this;

        self.init = _init;
        self.getAllUsers = _getAllUsers;
        self.isUserDeletable = _isUserDeletable;
    };
});

