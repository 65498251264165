define('businessServices/numberProvisioning/numberProvisioningSingleton',[
    'constants/smsCampaignRegistrationConstants',
], function() {
    const NumberProvisioningSingleton = function() {
        const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');

        if (NumberProvisioningSingleton.prototype._singletonInstance) {
            return NumberProvisioningSingleton.prototype._singletonInstance;
        }
        NumberProvisioningSingleton.prototype._singletonInstance = this;

        this.numberType = null;
        this.smsPhoneNumberLimit = _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimits.NONE;
        this.smsPhoneNumberLimitReason = _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.NONE;
        this.smsEnabledLocalHostedNumbers = [];
        this.successfulPhoneNumbers = [];
        this.failedPhoneNumbers = [];
    };
    return new NumberProvisioningSingleton();
});
