define('presentation/settings/extensions/facades/extensionsFacade',['presentation/common/dateTimeValue',
        'presentation/settings/extensions/presentationObjects/extensionPresentationObject',
        'persistence/webSocket/webSocketApp',],
    function() {
        var _promiseFactory = null;
        var _webSocketApp = null;
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var ExtensionPresentationObjectConstructor = require('presentation/settings/extensions/presentationObjects/extensionPresentationObject');

        var _getExtensions = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var extensionsList = {};
                for (var i=1000; i <= 9999; i++) {
                    var extensionPO = new ExtensionPresentationObjectConstructor();
                    extensionPO.name("Available");
                    extensionPO.extension(i);
                    extensionPO.originalExtension = i;
                    extensionPO.type("");
                    extensionPO.assignedDisplayValue("");
                    extensionPO.assignedSortValue("");
                    extensionPO.assignedFilterValue("");
                    extensionPO.isAssigned(false);

                    extensionsList[i] = extensionPO;
                }

                var params = {};
                _webSocketApp.send("get_all_extensions", params, function (assignedExtensions) {
                    assignedExtensions.forEach(function(assignedExtension) {
                        var currentExtension = extensionsList[assignedExtension.extension];
                        if (currentExtension !== undefined) {
                            var assignedDateTime = new DateTimeValueConstructor(assignedExtension.assignedDateTime);
                            currentExtension.assignedToId = assignedExtension.assignedToId;
                            currentExtension.name(assignedExtension.displayName);
                            currentExtension.type(assignedExtension.type);
                            currentExtension.assignedDisplayValue(assignedDateTime.displayValue);
                            currentExtension.assignedSortValue(assignedDateTime.sortValue);
                            currentExtension.assignedFilterValue(assignedDateTime.filterValue);
                            currentExtension.isAssigned(true);
                        }
                    });

                    var results = [];
                    for (var extension in extensionsList) {
                        if (extensionsList.hasOwnProperty(extension)) {
                            results.push(extensionsList[extension]);
                        }
                    }

                    deferredObject.resolve(results);
                });
            });
        };

        var _saveExtensionChange = function(newExtension, previousExtensionObject) {
            return _promiseFactory.defer(function(deferredObject) {
                var data = {};
                data.assignedToId = previousExtensionObject.assignedToId;
                data.type = previousExtensionObject.type();
                data.extension = newExtension;

                _webSocketApp.send("save_extension_change", data, function(result) {
                    var newExtensionAssignmentPO = new ExtensionPresentationObjectConstructor();
                    var assignedDateTime = new DateTimeValueConstructor(result.assignedDateTime);
                    newExtensionAssignmentPO.assignedToId = result.assignedToId;
                    newExtensionAssignmentPO.name(result.displayName);
                    newExtensionAssignmentPO.type(result.type);
                    newExtensionAssignmentPO.assignedDisplayValue(assignedDateTime.displayValue);
                    newExtensionAssignmentPO.assignedSortValue(assignedDateTime.sortValue);
                    newExtensionAssignmentPO.assignedFilterValue(assignedDateTime.filterValue);
                    newExtensionAssignmentPO.isAssigned(true);
                    newExtensionAssignmentPO.extension(result.extension);
                    newExtensionAssignmentPO.originalExtension = result.extension;

                    var originalExtensionAssignmentPO = new ExtensionPresentationObjectConstructor();
                    originalExtensionAssignmentPO.name("Available");
                    originalExtensionAssignmentPO.extension(previousExtensionObject.originalExtension);
                    originalExtensionAssignmentPO.originalExtension = previousExtensionObject.originalExtension;
                    originalExtensionAssignmentPO.type("");
                    originalExtensionAssignmentPO.assignedDisplayValue("");
                    originalExtensionAssignmentPO.assignedSortValue("");
                    originalExtensionAssignmentPO.assignedFilterValue("");
                    originalExtensionAssignmentPO.isAssigned(false);

                    deferredObject.resolve([newExtensionAssignmentPO, originalExtensionAssignmentPO]);
                });
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            var WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketActivityAppConstructor();
            _webSocketApp.init("extensions_settings_list");
        };

        return function() {
            var self = this;

            self.init = _init;
            self.getExtensions = _getExtensions;
            self.saveExtensionChange = _saveExtensionChange;
        };
    });

