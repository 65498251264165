define('persistence/repositories/smsPhoneNumberOptOutRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('externalDependencies/clientWebSocket') */
    ClientWebSocket
) {
    const APPLICATION_NAME = `smsPhoneNumberOptOut`;

    return function () {
        /** @type {ISmsPhoneNumberOptOutRepository} */
        const self = this;

        const _smsPhoneNumberOptOutSocket = ClientWebSocket.forApplication(APPLICATION_NAME);

        const _promiseFactory = new PromiseFactory();

        /**
         * @template T
         * @param {string} eventName
         * @param {Callback<T>} callBack
         */
        const _subscribe = (eventName, callBack) => {
            _smsPhoneNumberOptOutSocket.subscribeToEvent(eventName, callBack);
        };

        /**
         * @template TOut
         * @param {string} command
         * @param {{ [key: string]: any }} parameters
         * @returns {JQuery.Deferred<TOut?>}
         */
        const _send = (command, parameters) => {
            return _promiseFactory.defer((/** @type {JQuery.Deferred<TOut?>} */deferred) => {
                _smsPhoneNumberOptOutSocket.send(command, parameters || {}, (/** @type {TOut?} */ response, /** @type {any} */ errMsg) => {
                    if (errMsg) {
                        deferred.reject(new Error(errMsg));
                        return;
                    }

                    deferred.resolve(response);
                });
            });
        };

        /** @type {ISmsPhoneNumberOptOutRepository["getSmsPhoneNumberOptOuts"]} */
        self.getSmsPhoneNumberOptOuts = () => {
            return _send('getSmsPhoneNumberOptOuts', {});
        };

        /** @type {ISmsPhoneNumberOptOutRepository["onSmsPhoneNumberOptOutCreated"]} */
        self.onSmsPhoneNumberOptOutCreated = (callBack) => {
            _subscribe(`smsPhoneNumberOptOutCreated`, callBack);
        };

        /** @type {ISmsPhoneNumberOptOutRepository["onSmsPhoneNumberOptOutDeleted"]} */
        self.onSmsPhoneNumberOptOutDeleted = (callBack) => {
            _subscribe(`smsPhoneNumberOptOutDeleted`, callBack);
        };


        /** @type {ISmsPhoneNumberOptOutRepository["dispose"]} */
        self.dispose = () => {
            _smsPhoneNumberOptOutSocket.disposeOfEvents();
        };
    };
});
