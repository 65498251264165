define('persistence/repositories/autoAttendantRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(autoAttendantEntity) {
            return _promiseFactory.defer(function(deferredObject) {
                var autoAttendantDocument = {
                        data : autoAttendantEntity
                    };
                _kazooApi.callAccount('/lobby_autoattendant', 'PUT', autoAttendantDocument)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        error.autoAttendantEntity = autoAttendantEntity;
                        deferredObject.reject(error);
                    });
            });

        };

        self.insert = function(autoAttendantDocuments, autoAttendantVoiceRouteDocuments, autoAttendantVoiceSubMenuDocuments, autoAttendantVoiceRoutesNeedDeleting) {
            return _promiseFactory.defer(function(deferredObject) {
                var autoAttendantEntities = {
                    data: {
                        autoAttendantDocuments : autoAttendantDocuments,
                        autoAttendantVoiceRouteDocuments : autoAttendantVoiceRouteDocuments,
                        autoAttendantVoiceSubMenuDocuments : autoAttendantVoiceSubMenuDocuments,
                        autoAttendantVoiceRoutesNeedDeleting : autoAttendantVoiceRoutesNeedDeleting
                    }
                };

                _kazooApi.callAccount('/lobby_autoattendant/insert', 'PUT', autoAttendantEntities)
                    .done(function() {
                        deferredObject.resolve();
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.update = function(autoAttendantEntity) {
            if (autoAttendantEntity.autoAttendantId === null) {
                var err = new Error("Missing autoAttendantId");
                err.lobbyAutoAttendant = autoAttendantEntity;
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var autoAttendantDocument = {
                            data: autoAttendantEntity
                        };
                    _kazooApi.callAccount('/lobby_autoattendant/'+autoAttendantEntity.autoAttendantId, 'POST', autoAttendantDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.autoAttendantEntity = autoAttendantEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

