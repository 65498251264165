define('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton',[
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'i18next',
    'persistence/repositories/smsPhoneNumberOptOutRepository'
], function (
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('persistence/repositories/smsPhoneNumberOptOutRepository') */
    SmsPhoneNumberOptOutRepository
) {
    /** @typedef {import('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton')} SmsPhoneNumberOptOutSingleton */

    const SmsPhoneNumberOptOutSingleton = function () {

        const _phoneNumberFormatter = new PhoneNumberFormatter();

        const _promiseFactory = new PromiseFactory();

        /** @type {KnockoutObservableArray<string>} */
        const _smsPhoneNumberOptOuts = ko.observableArray([]);

        /** @type {ISmsPhoneNumberOptOutRepository} */
        let _smsPhoneNumberOptOutRepository = null;
        _smsPhoneNumberOptOutRepository = new SmsPhoneNumberOptOutRepository();

        /** @type {IDisposable[]} */
        let _disposables = [];

        this.smsPhoneNumberOptOuts = ko.pureComputed(() => _smsPhoneNumberOptOuts());

        this.isLoaded = false;

        /** @type {SmsPhoneNumberOptOutSingleton["initialize"]} */
        this.initialize = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _smsPhoneNumberOptOutRepository.getSmsPhoneNumberOptOuts()
                    .then((/** @type {IGetSmsPhoneNumberOptOutsResponse} **/ result) => {
                        const { smsPhoneNumberOptOuts } = result;
                        if (smsPhoneNumberOptOuts && smsPhoneNumberOptOuts.length > 0) {
                            _setSmsPhoneNumberOptOuts(smsPhoneNumberOptOuts);
                            deferredObject.resolve();
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        /** @type {SmsPhoneNumberOptOutSingleton["getOptedOutPhoneNumbers"]} */
        this.getOptedOutPhoneNumbers = (/** @type string */ fromPhoneNumber, /** @type Array<string> */ toPhoneNumbers) => {
            const formattedFromPhoneNumber = _phoneNumberFormatter.toEOneSixFour(fromPhoneNumber);

            /**
             * @type {string[]}
             */
            const result = [];
            toPhoneNumbers.forEach((toPhoneNumber) => {
                const optOutPhoneNumber = `${formattedFromPhoneNumber}-${_phoneNumberFormatter.toEOneSixFour(toPhoneNumber)}`;
                if (_smsPhoneNumberOptOuts().includes(optOutPhoneNumber)) {
                    result.push(toPhoneNumber);
                }
            });
            return result;
        };

        /** @type {(repository: ISmsPhoneNumberOptOutRepository) => void} */
        this.setRepository = (repository) => {
            if (_smsPhoneNumberOptOutRepository !== null) {
                return;
            }

            _smsPhoneNumberOptOutRepository = repository;

            // register event listeners
            _smsPhoneNumberOptOutRepository.onSmsPhoneNumberOptOutCreated(_onSmsPhoneNumberOptOutCreated);
            _smsPhoneNumberOptOutRepository.onSmsPhoneNumberOptOutDeleted(_onSmsPhoneNumberOptOutDeleted);

            _disposables.push(_smsPhoneNumberOptOutRepository);
        };

        /** @type {IAuthenticationListeningModule["alerts"]} */
        this.alerts = {
            receiveAlertLoginSuccessful: () => {

            },
            receiveAlertLogout: () => {
                this.isLoaded = false;
                _smsPhoneNumberOptOuts([]);
                _disposables.forEach((disposable) => disposable.dispose());
            }
        };

        /** @type {(event: ISmsPhoneNumberOptOutCreatedEvent) => void} */
        const _onSmsPhoneNumberOptOutCreated = (/** @type {ISmsPhoneNumberOptOut} */ event) => {
            const smsPhoneNumberOptOut = `${event.fromPhoneNumber}-${event.toPhoneNumber}`;
            if (_smsPhoneNumberOptOuts().includes(smsPhoneNumberOptOut) === false) {
                const phoneNumberOptOuts = [];
                _smsPhoneNumberOptOuts().forEach((phoneNumberKey) => {
                    phoneNumberOptOuts.push(phoneNumberKey);
                });
                phoneNumberOptOuts.push(smsPhoneNumberOptOut);
                _smsPhoneNumberOptOuts(phoneNumberOptOuts);
            }
        };

        /** @type {(event: ISmsPhoneNumberOptOutDeletedEvent) => void} */
        const _onSmsPhoneNumberOptOutDeleted = (/** @type {ISmsPhoneNumberOptOut} */ event) => {
            const smsPhoneNumberOptOut = `${event.fromPhoneNumber}-${event.toPhoneNumber}`;
            const phoneNumberOptOuts = _smsPhoneNumberOptOuts().filter((phoneNumberKey) => {
                return phoneNumberKey !== smsPhoneNumberOptOut;
            });
            _smsPhoneNumberOptOuts(phoneNumberOptOuts);
        };

        /** @type {(smsPhoneNumberOptOuts: Array<ISmsPhoneNumberOptOut>) => void} */
        const _setSmsPhoneNumberOptOuts = (/** @type {Array<ISmsPhoneNumberOptOut>} */ smsPhoneNumberOptOuts) => {
            _smsPhoneNumberOptOuts([]);
            /** @type Array<string> */
            const phoneNumberOptOuts = [];
            smsPhoneNumberOptOuts.forEach((smsPhoneNumberOptOut) => {
                phoneNumberOptOuts.push(
                    `${smsPhoneNumberOptOut.fromPhoneNumber}-${smsPhoneNumberOptOut.toPhoneNumber}`
                );
            });
            _smsPhoneNumberOptOuts(phoneNumberOptOuts);
        };

        _smsPhoneNumberOptOutRepository.onSmsPhoneNumberOptOutCreated(_onSmsPhoneNumberOptOutCreated);
        _smsPhoneNumberOptOutRepository.onSmsPhoneNumberOptOutDeleted(_onSmsPhoneNumberOptOutDeleted);
        _disposables.push(_smsPhoneNumberOptOutRepository);
    };


    SmsPhoneNumberOptOutSingleton.singleton = new SmsPhoneNumberOptOutSingleton();

    return SmsPhoneNumberOptOutSingleton.singleton;
});
