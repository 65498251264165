define('businessServices/authentication/authorizationManager',['persistence/webSocket/webSocketAuthentication', 'common/promises/promiseFactory'], function(WebSocketAuthenticationConstructor, PromiseFactoryConstructor) {

    function hasPermissionInfoCaptureBypass() {
        var promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.defer(function(promise) {
            var webSocketAuthentication = new WebSocketAuthenticationConstructor();
            webSocketAuthentication.init();
            webSocketAuthentication.hasPermissionInfoCaptureBypass()
                .done(function(booleanResult) {
                    promise.resolve(booleanResult);
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    function hasPermissionClosedAccountBypass() {
        var promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.defer(function(promise) {
            var webSocketAuthentication = new WebSocketAuthenticationConstructor();
            webSocketAuthentication.init();
            webSocketAuthentication.hasPermissionClosedAccountBypass()
                .done(function(booleanResult) {
                    promise.resolve(booleanResult);
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    return {
        hasPermissionInfoCaptureBypass : hasPermissionInfoCaptureBypass,
        hasPermissionClosedAccountBypass : hasPermissionClosedAccountBypass
    };
});

