define('presentation/common/routeToModal/viewModels/routeToPromptViewModel',['common/promises/promiseFactory',
    'businessServices/events/eventManager',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/inlinePromptDefaultOptionEnumerations',
    'common/storage/commonState',
    'common/collections/collectionSorter',
    'presentation/common/routeToModal/common/routeToViewModelCommonSetup',
    'businessServices/state/modelStateObserver',
    'presentation/settings/prompts/viewModels/addPromptsViewModel',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'settings/navigationConfiguration'
], function() {

    return function(initialRoutedToId, initialRouteToType, routingRuleExitConditions, validateRouteToViewModelCallback, routeToOptions, parentViewModel, determineSubsequentRoute, isRouteToModal) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const RouteToViewModelCommonSetupConstructor = require('presentation/common/routeToModal/common/routeToViewModelCommonSetup');
        const AddPromptsViewModelConstructor = require('presentation/settings/prompts/viewModels/addPromptsViewModel');
        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _eventManager = require('businessServices/events/eventManager');
        const _inlinePromptEnumerations = require('constants/inlinePromptDefaultOptionEnumerations');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        const _promiseFactory = new PromiseFactoryConstructor();
        const _routeToViewModelCommonSetup = new RouteToViewModelCommonSetupConstructor(validateRouteToViewModelCallback, routeToOptions);
        const _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();

        let _disposables = [];

        self.availablePrompts = ko.pureComputed(() => {
            let availablePrompts = _commonState.prompts().filter((prompt) => {
                return prompt.isSystemPrompt === false;
            }).concat([]);
            const sorter = new SorterConstructor();
            sorter.sort(availablePrompts, "name", true);

            if (availablePrompts.length === 0) {
                self.selectedPrompt(_inlinePromptEnumerations.NONE_PROMPT);
                self.showNoAudioFilesMessage(true);
            }
            else if (self.selectedPrompt() === _inlinePromptEnumerations.NONE_PROMPT) {
                self.selectedPrompt(availablePrompts[0]);
                self.showNoAudioFilesMessage(false);
            }

            return availablePrompts;
        });

        self.cancelForm = () => {
            return _promiseFactory.defer((deferredObject) => {
                _eventManager.publishRouteToCloseModal();
                deferredObject.resolve();
            });
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {
            self.addPromptsViewModel = new AddPromptsViewModelConstructor();
            self.addPromptsViewModel.activate();
            self.addPromptsViewModel.isCalledFromAnotherViewModel = true;
            if (parentViewModel.inlinePromptNames) {
                parentViewModel.inlinePromptNames.addPromptName(self.addPromptsViewModel.promptName);
                self.addPromptsViewModel.inlinePromptNames = parentViewModel.inlinePromptNames;
            }

            _voicePromptAudioFactory.init();

            return _initialize();
        };

        self.determineSubsequentRoutes = () => {
            const subsequentRoutes = _routeToViewModelCommonSetup.determineSubsequentRoutes(self.isNewRouteTo, self.routeToStrategy, self.selectedPrompt().id, _voiceRouteEnumerations.prompt, routingRuleExitConditions, parentViewModel, isRouteToModal);
            self.routeToViewModel(subsequentRoutes);
        };

        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.addPromptsViewModel = ko.observable();
        self.addPromptUrl = _navigationConfiguration.routesById.newPrompt.url;
        self.createAudioFileLinkText = _i18n.t('routeToPrompt:createAudioFile');
        self.noAudioFilesWarningMessage = _i18n.t('routeToPrompt:noAudioFilesWarningMessage');
        self.placeholderMessage = _inlinePromptEnumerations.NONE_PROMPT.name;
        self.showNoAudioFilesMessage = ko.observable(false);
        self.selectedPrompt = ko.observable().extend({observeState: true});
        self.selectedPromptAudio = ko.observable();
        self.routeToStrategy = null;
        self.routeToViewModel = ko.observable().extend({observeState: true});
        self.isValid = ko.observable(true);
        self.isNewRouteTo = true;
        self.isNewPrompt = ko.pureComputed(() => self.selectedPrompt().id === _inlinePromptEnumerations.NEW_PROMPT.id);

        const _promptSelectionChanged = () => {
            const selectedPrompt = self.selectedPrompt();

            self.isValid(!(selectedPrompt && selectedPrompt.id === _inlinePromptEnumerations.NONE_PROMPT.id));
            validateRouteToViewModelCallback({});

            switch (selectedPrompt.id) {
                case _inlinePromptEnumerations.NONE_PROMPT.id:
                    self.selectedPromptAudio(_inlinePromptEnumerations.NONE_PROMPT);
                    break;
                case _inlinePromptEnumerations.SELECT_PROMPT.id:
                    self.selectedPromptAudio(_inlinePromptEnumerations.SELECT_PROMPT);
                    break;
                case null:
                    break;
                default:
                    const promptAudioObject = selectedPrompt.isSystemPrompt ?
                        _voicePromptAudioFactory.getSystemVoicePrompt(selectedPrompt.id) :
                        _voicePromptAudioFactory.getVoicePrompt(selectedPrompt.id);

                    promptAudioObject.duration = ko.pureComputed(() => selectedPrompt.durationInMilliseconds() / 1000);

                    self.selectedPromptAudio(promptAudioObject);
            }
        };

        const _initialize = () => {
            _disposables.push(self.selectedPrompt.subscribe(_promptSelectionChanged));
            if ((initialRoutedToId) &&
                (initialRoutedToId !== _inlinePromptEnumerations.NEW_PROMPT.id) &&
                (initialRoutedToId !== _inlinePromptEnumerations.NONE_PROMPT.id) &&
                (initialRouteToType ===  _voiceRouteEnumerations.prompt)) {
                const prompt = _commonState.get(initialRoutedToId);
                self.selectedPrompt(prompt);
            }
            else if (self.availablePrompts().length === 0) {
                self.selectedPrompt(_inlinePromptEnumerations.NONE_PROMPT);
                self.showNoAudioFilesMessage(true);
            }
            else {
                self.selectedPrompt(self.availablePrompts()[0]);
                self.showNoAudioFilesMessage(false);
            }

            if (determineSubsequentRoute) {
                const selectedPrompt = self.selectedPrompt();
                const subsequentRoutes = _routeToViewModelCommonSetup.determineSubsequentRoutes(self.isNewRouteTo, self.routeToStrategy, selectedPrompt.id, _voiceRouteEnumerations.prompt, routingRuleExitConditions, parentViewModel, isRouteToModal);
                self.routeToViewModel(subsequentRoutes);
            }

            return _promiseFactory.wait();
        };
    };
});

