define('presentation/common/actionModal/viewModels/deleteActionViewModel',[
    'businessServices/converters/durationTimeFormatter',
    'common/converters/phoneNumberFormatter',
    'common/storage/commonState',
    'common/promises/promiseFactory',
    'presentation/common/voicePrompt/voicePromptAudioFactory'
], function() {
    return function(systemObjectId) {
        const self = this;

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const _timeFormatter = new TimeFormatterConstructor();

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        let _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();

        const _commonState = require('common/storage/commonState');

        self.component = {};
        self.systemObjectName = ko.pureComputed(() => self.component.typeDisplay.toLowerCase());

        const _buildSystemComponentItem = (systemComponentId) => {
            let systemComponent = {};
            systemComponent.isAudioFile = false;
            systemComponent.isAutoAttendant = false;
            systemComponent.isUser = false;
            systemComponent.isUserGroup = false;

            let commonStateObject = _commonState.get(systemComponentId);

            switch (commonStateObject.type) {
                case _commonState.types.autoAttendant:

                    return Object.assign({}, systemComponent, {
                        iconName: commonStateObject.iconName,
                        isAutoAttendant: true,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.forwardingNumber:

                    return Object.assign({}, systemComponent, {
                        iconName: commonStateObject.iconName,
                        metadata: _phoneNumberFormatter.toInternationalWithParens(commonStateObject.phoneNumber()),
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.hostedNumber:
                    const isDefault = commonStateObject.hasDefaultName();
                    const formattedNumber = _phoneNumberFormatter.toInternationalWithParens(commonStateObject.phoneNumber());
                    const formattedLocation = _phoneNumberFormatter.cleanupLocation(commonStateObject.location().cityState);

                    return Object.assign({}, systemComponent, {
                        iconName: commonStateObject.iconName,
                        metadata: isDefault ? formattedLocation : formattedNumber,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.schedule:

                    return Object.assign({}, systemComponent, {
                        iconName: commonStateObject.iconName,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.user:

                    return Object.assign({}, systemComponent, {
                        avatar: commonStateObject.avatar,
                        hasAvatar: commonStateObject.avatar().showImage(),
                        isUser: true,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.userGroup:

                    return Object.assign({}, systemComponent, {
                        groupId: systemComponentId,
                        hasAvatar: commonStateObject.avatars().length > 1,
                        isUserGroup: true,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.voicemailBox:

                    return Object.assign({}, systemComponent, {
                        iconName: commonStateObject.iconName,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                case _commonState.types.prompt:
                    let formattedDuration = _timeFormatter.formatTotalMilliseconds(commonStateObject.durationInMilliseconds());
                    let audioObject = ko.observable(_voicePromptAudioFactory.getVoicePrompt(systemComponentId));
                    audioObject().duration = ko.observable(formattedDuration);

                    return Object.assign({}, systemComponent, {
                        audioObject: audioObject,
                        durationInSeconds: formattedDuration,
                        iconName: commonStateObject.iconName,
                        isAudioFile: true,
                        name: commonStateObject.name,
                        typeDisplay: commonStateObject.typeDisplay,
                    });
                default:
                    return null;
            }
        };

        self.activate = () => {
            self.component = _buildSystemComponentItem(systemObjectId);

            return _promiseFactory.wait();
        };
    };
});
