define('presentation/settings/messagingCompliance/viewModels/messagingComplianceAutoResponseTabViewModel',[
        'businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'i18next',
        'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceAutoResponsePresentationObject',
        'presentation/settings/messagingCompliance/validators/messagingComplianceAutoResponseTabValidator'
    ],
    function (
        /** @type typeof import('businessServices/state/modelStateObserver') */
        ModelStateObserver,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type import('i18next') */
        _i18n,
        /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceAutoResponsePresentationObject') */
        MessagingComplianceAutoResponsePresentationObject
    ) {
        return function (parent) {
            /** @typedef { import('presentation/settings/messagingCompliance/validators/messagingComplianceAutoResponseTabValidator') } MessagingComplianceAutoResponseTabValidator } */

            const self = this;
            const _parent = parent;

            const _promiseFactory = new PromiseFactory();

            /** @type { MessagingComplianceAutoResponseTabValidator } */
            let _validator = null;

            self.isInitialRender = true;
            self.modelStateObserver = null;
            self.isCompositionComplete = ko.observable(false);

            self.labelHelpPlaceholder = _i18n.t('messagingComplianceAutoResponseTab:labelHelpPlaceholder');
            self.labelStopPlaceholder = _i18n.t('messagingComplianceAutoResponseTab:labelStopPlaceholder');

            self.helpAutoResponse = ko.observable("").extend({observeState: true});
            self.stopAutoResponse = ko.observable("").extend({observeState: true});

            self.generatedHelpAutoResponse = ko.pureComputed(() => {
                const isSoleProprietor =  _parent.messagingComplianceProfileTabViewModel.isSoleProprietor();
                const accountHolderFirstName = _parent.messagingComplianceProfileTabViewModel.accountHolderFirstName();
                const accountHolderLastName = _parent.messagingComplianceProfileTabViewModel.accountHolderLastName();
                const brandName = _parent.messagingComplianceProfileTabViewModel.brandName();

                if (isSoleProprietor && accountHolderFirstName || accountHolderLastName) {
                    return `${accountHolderFirstName} ${accountHolderLastName}: ${self.labelHelpPlaceholder}`;
                }
                if (!isSoleProprietor && brandName) {
                    return `${brandName}: ${self.labelHelpPlaceholder}`;
                }
                return `DBA: ${self.labelHelpPlaceholder}`;
            });

            self.generatedStopAutoResponse = ko.pureComputed(() => {
                const isSoleProprietor =  _parent.messagingComplianceProfileTabViewModel.isSoleProprietor();
                const accountHolderFirstName = _parent.messagingComplianceProfileTabViewModel.accountHolderFirstName();
                const accountHolderLastName = _parent.messagingComplianceProfileTabViewModel.accountHolderLastName();
                const brandName = _parent.messagingComplianceProfileTabViewModel.brandName();

                if (isSoleProprietor && accountHolderFirstName || accountHolderLastName) {
                    return `${accountHolderFirstName} ${accountHolderLastName}: ${self.labelStopPlaceholder}`;
                }
                if (!isSoleProprietor && brandName) {
                    return `${brandName}: ${self.labelStopPlaceholder}`;
                }
                return `DBA: ${self.labelStopPlaceholder}`;
            });

            self.validate = () => _validator.validate();

            self.resetValidation = () => {
                _validator.resetValidation();
            };

            self.dispose = () => {
                self.isCompositionComplete(false);
            };

            self.cancelForm = () => {
                if (self.helpAutoResponse.isDirty) {
                    self.helpAutoResponse.forceUpdate(true);
                }
                if (self.stopAutoResponse.isDirty) {
                    self.stopAutoResponse.forceUpdate(true);
                }
            };

            self.getForm = () => {
                const messagingComplianceAutoResponse = new MessagingComplianceAutoResponsePresentationObject();
                messagingComplianceAutoResponse.helpAutoResponse = self.helpAutoResponse;
                messagingComplianceAutoResponse.stopAutoResponse = self.stopAutoResponse;

                return messagingComplianceAutoResponse;
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = (/** @type { MessagingComplianceAutoResponsePresentationObject } */ autoResponseData) => {
                let modelStateObserver = new ModelStateObserver(self);

                const ValidatorConstructor = require('presentation/settings/messagingCompliance/validators/messagingComplianceAutoResponseTabValidator');
                _validator = new ValidatorConstructor();

                return _initialize(autoResponseData, modelStateObserver);
            };

            const _initialize = (autoResponseData, modelStateObserver) => {
                return _promiseFactory.defer((initPromise) => {

                    if (autoResponseData) {
                        self.helpAutoResponse(autoResponseData.helpAutoResponse());
                        self.stopAutoResponse(autoResponseData.stopAutoResponse());
                    }

                    self.modelStateObserver = modelStateObserver;
                    self.modelStateObserver.commitData();

                    _validator.registerViewModel(self);

                    initPromise.resolve();
                });
            };
        };
    });
