define('presentation/common/routeToModal/viewModels/routeToAutoAttendantViewModel',['common/promises/promiseFactory',
        'businessServices/events/eventManager',
        'constants/autoAttendantVoiceRouteEnumerations',
        'common/storage/commonState',
        'common/collections/collectionSorter',
        'businessServices/state/modelStateObserver',
        'settings/navigationConfiguration'
], function() {

    return function(initialRoutedToId, initialRouteToType, nameThisOption, routingRuleExitConditions, validateRouteToViewModelCallback) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _i18n = require('i18next');

        const _promiseFactory = new PromiseFactoryConstructor();

        const NONE_AUTO_ATTENDANT_ID = "[None]";
        const NONE_AUTO_ATTENDANT_NAME = _i18n.t('routeToAutoAttendant:none');
        const NONE_AUTO_ATTENDANT = {
            id: NONE_AUTO_ATTENDANT_ID,
            name: NONE_AUTO_ATTENDANT_NAME,
            type: _commonState.types.autoAttendant
        };

        let _disposables = [];

        const _onSelectedChange = () => {
            self.isValid(!(self.selectedAutoAttendant() && self.selectedAutoAttendant().id === NONE_AUTO_ATTENDANT_ID));
            validateRouteToViewModelCallback({});
        };

        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.selectedAutoAttendant = ko.observable().extend({observeState: true});
        _disposables.push(self.selectedAutoAttendant.subscribe(_onSelectedChange));
        self.showNoAutoAttendantsMessage = ko.observable(false);
        self.routeToStrategy = null;
        self.isNewRouteTo = true;
        self.isValid = ko.observable(false);
        self.addAutoAttendantUrl = _navigationConfiguration.routesById.addAutoAttendant.url;
        self.createAutoAttendantNumberLinkText = _i18n.t('routeToAutoAttendant:createAutoAttendant');
        self.noAutoAttendantsWarningMessage = _i18n.t('routeToAutoAttendant:noAutoAttendantsWarningMessage');
        self.placeholderMessage = NONE_AUTO_ATTENDANT_NAME;

        self.autoAttendants = ko.computed(() => {
            const autoAttendants = _commonState.autoAttendants();
            const sorter = new SorterConstructor();
            sorter.sort(autoAttendants, "name", true);

            if (autoAttendants.length === 0) {
                self.selectedAutoAttendant(NONE_AUTO_ATTENDANT);
                self.showNoAutoAttendantsMessage(true);
            }
            else if (self.selectedAutoAttendant() === NONE_AUTO_ATTENDANT) {
                self.selectedAutoAttendant(autoAttendants[0]);
                self.showNoAutoAttendantsMessage(false);
            }

            return autoAttendants;
        });
        self.autoAttendantsIsEnabled = ko.pureComputed(() => self.autoAttendants().length > 0);

        self.cancelForm = () => {
            return _promiseFactory.defer(function (deferredObject) {
                _eventManager.publishRouteToCloseModal();
                deferredObject.resolve();
            });
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            if(initialRoutedToId && initialRoutedToId !== NONE_AUTO_ATTENDANT_ID && initialRouteToType === _voiceRouteEnumerations.autoAttendant) {
                self.selectedAutoAttendant(_commonState.get(initialRoutedToId));
            }
            else if (self.autoAttendants().length === 0) {
                self.selectedAutoAttendant(NONE_AUTO_ATTENDANT);
                self.showNoAutoAttendantsMessage(true);
            }
            else {
                self.selectedAutoAttendant(self.autoAttendants()[0]);
                self.showNoAutoAttendantsMessage(false);
            }

            return _promiseFactory.wait();
        };
    };
});
