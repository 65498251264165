define('presentation/forgotPassword/viewModels/forgotPasswordViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'common/specifications/validEmailAddressSpecification',
    'presentation/common/modal',
    'presentation/common/window/windowControl',
    'presentation/forgotPassword/facades/forgotPasswordFacade',
    'presentation/forgotPassword/validators/forgotPasswordValidator',
    'presentation/loginNotifications/viewModels/resendInviteViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _i18n = require('i18next');
        const _browserType = require('businessServices/browserSupport/browserType');

        let ResendInviteViewModelConstructor = null;
        let _facade = null;
        let _validator = null;
        let _modalService = null;
        let _router = null;
        let _windowControl = null;
        let _messageType = null;

        self.emailAddress = ko.observable('');
        self.showConfirmationMessage = ko.observable(false);
        self.customerServicePhoneNumber = ko.observable('');
        self.isResendInviteLinkVisible = ko.observable(false);
        self.canDisplayUsernameValidation = ko.observable(false);

        self.emailAddressStatus = null;
        self.isUserDeactivated = ko.observable(false);

        self.isMobileDevice = ko.pureComputed(() => _browserType.isMobileDevice);

        self.prompt = ko.pureComputed(() => {
            if (_messageType === "expired-link") {
                return _i18n.t('forgotPassword:expiredLinkHeader');
            } else {
                return _i18n.t('forgotPassword:header');
            }
        });

        self.message = ko.pureComputed(() => {
            if (_messageType === "expired-link") {
                return _i18n.t('forgotPassword:expiredLinkMessage');
            } else {
                return _i18n.t('forgotPassword:message');
            }
        });

        self.customerServiceMessage = ko.pureComputed(() => {
            const options = {
                customerServicePhoneNumber: self.customerServicePhoneNumber()
            };
            const message = _i18n.t('forgotPassword:customerServiceMessage', options);
            return message;
        });

        self.sendEmail = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                self.canDisplayUsernameValidation(true);
                self.isUserDeactivated(false);
                let forgotPasswordEmail = self.emailAddress().trim();
                _facade.getEmailStatus(forgotPasswordEmail)
                    .fail(promise.reject)
                    .done((emailStatus) => {
                        self.emailAddressStatus = emailStatus;
                        _validator.validate()
                            .fail(promise.reject)
                            .done((isValid) => {
                                if (isValid) {
                                    _facade.sendPasswordResetEmail(forgotPasswordEmail)
                                        .fail(promise.reject)
                                        .done((result) => {
                                            if (result === "success") {
                                                self.showConfirmationMessage(true);
                                            } else if (result === "user_deactivated") {
                                                self.isUserDeactivated(true);
                                                _validator.validate();
                                            }
                                            promise.resolve();
                                        });
                                } else {
                                    promise.resolve();
                                }
                            });
                    });
            });
        };

        self.resendInvite = () => {
            _promiseFactory.deferIndefinitely((promise) => {
                let modalViewModel = new ResendInviteViewModelConstructor(promise, self.emailAddress());
                _modalService.showModal(modalViewModel);
            });
            return _promiseFactory.wait();
        };

        self.navigateToHomePage = () => {
            _windowControl.openHomePage("_self");
        };

        self.redirectToSignIn = () => {
            _router.navigate(_navigationConfiguration.loginPageUrl);
        };

        self.activate = (messageType) => {
            const Facade = require('presentation/forgotPassword/facades/forgotPasswordFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const ForgotPasswordValidatorConstructor = require('presentation/forgotPassword/validators/forgotPasswordValidator');
            _validator = new ForgotPasswordValidatorConstructor();

            let ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            _router = require('businessServices/router/router');
            _windowControl = require('presentation/common/window/windowControl');

            ResendInviteViewModelConstructor = require('presentation/loginNotifications/viewModels/resendInviteViewModel');

            if (messageType) {
                _messageType = messageType;
            }

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self, _facade);
            _facade.customerServiceSupportPhone()
                .done((supportPhone) => {
                    self.customerServicePhoneNumber(supportPhone);
                });

            return _promiseFactory.wait();
        };
    };
});

