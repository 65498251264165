define('presentation/analytics/viewModels/analyticsSummaryViewModel',[
        'businessServices/browserSupport/browserType',
        'businessServices/converters/durationTimeFormatter',
        'common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'i18next',
        'presentation/common/analyticsGraph/summaryRecordView',
        'presentation/common/analyticsGraph/summaryTotalRecordView'
    ],
    function(
        /** @type typeof import('businessServices/browserSupport/browserType') */
        BrowserType,
        /** @type typeof import('businessServices/converters/durationTimeFormatter') */
        DurationTimeFormatter,
        /** @type typeof import('common/collections/collectionSorter') */
        CollectionSorter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('common/storage/commonState') */
        CommonState,
        /** @type import('i18next') */
        _i18n,
        /** @type import('presentation/common/analyticsGraph/summaryRecordView') */
        SummaryRecordView,
        /** @type import('presentation/common/analyticsGraph/summaryTotalRecordView') */
        SummaryTotalRecordView
    ) {
        return function() {

            /**
             * @typedef {{
             *  sortValue: string
             *  headerCss: string
             * }} Column
             * @typedef {{
             *  name: Column
             *  totalCalls: Column
             *  outbound: Column
             *  inbound: Column
             *  answered: Column
             *  missed: Column
             *  voicemails: Column
             *  totalDuration: Column
             *  averageDuration: Column
             * }} CallsTableColumns
             * @typedef {{
             *  name: Column
             *  minutes: Column
             *  outbound: Column
             *  inbound: Column
             *  answered: Column
             *  missed: Column
             *  voicemails: Column
             *  totalDuration: Column
             * }} MinutesTableColumns
             */

            const self = this;

            const _durationFormatter = new DurationTimeFormatter();
            const _promiseFactory = new PromiseFactory();
            const SorterConstructor = CollectionSorter;

            const _browserType = BrowserType;
            const _commonState = CommonState;
            const _summaryRecordView = SummaryRecordView;
            const _summaryTotalRecordView = SummaryTotalRecordView;

            /** @type {IDisposable[]} */
            let _disposables = [];
            let _searchByFilter = null;
            /** @type {KnockoutObservableArray<IAnalyticsSummaryProjectionResult>} */
            let _summaryData = null;
            /** @type {KnockoutObservableArray<IAnalyticsTotalsProjectionResult>} */
            let _summaryTotalData = null;

            /** @type {CallsTableColumns} */
            const CALLS_TABLE_COLUMNS = {
                name: { sortValue: 'displayName', headerCss: 'phone-number' },
                totalCalls: { sortValue: 'callCount', headerCss: 'calls' },
                outbound: { sortValue: 'outboundCount', headerCss: 'outbound' },
                inbound: { sortValue: 'inboundCount', headerCss: 'inbound' },
                answered: { sortValue: 'answeredCount', headerCss: 'answered' },
                missed: { sortValue: 'missedCount', headerCss: 'missed' },
                voicemails: { sortValue: 'voicemailCount', headerCss: 'inbox' },
                totalDuration: { sortValue: 'callSeconds', headerCss: 'duration' },
                averageDuration: { sortValue: 'averageSeconds', headerCss: 'average' }
            };

            /** @type {MinutesTableColumns} */
            const MINUTES_TABLE_COLUMNS = {
                name: { sortValue: 'displayName', headerCss: 'phone-number' },
                minutes: { sortValue: 'callSeconds', headerCss: 'calls' },
                outbound: { sortValue: 'outboundSeconds', headerCss: 'outbound' },
                inbound: { sortValue: 'inboundSeconds', headerCss: 'inbound' },
                answered: { sortValue: 'answeredSeconds', headerCss: 'answered' },
                missed: { sortValue: 'missedSeconds', headerCss: 'missed' },
                voicemails: { sortValue: 'voicemailSeconds', headerCss: 'inbox' },
                totalDuration: { sortValue: 'averageSeconds', headerCss: 'duration' }
            };

            _summaryData = ko.observableArray([]);
            _summaryTotalData = ko.observableArray();

            const HEADING = {
                answered: 'answered',
                averageDuration: 'averageDuration',
                calls: 'calls',
                forwardingNumber: 'forwardingNumber',
                inbound: 'inbound',
                minutes: 'minutes',
                missed: 'missed',
                outbound: 'outbound',
                phoneNumber: 'phoneNumber',
                system: 'system',
                totalCalls: 'totalCalls',
                totalDuration: 'totalDuration',
                user: 'user',
                userGroup: 'userGroup',
                voicemails: 'voicemails',
            };

            const _getColumnHeaderKey = (/** @type {string} */columnName) => {
                if (columnName === 'name') {
                    return self.selectedSummaryTypeKey();
                }

                return columnName;
            };

            self.parentViewModel = ko.observable(null);
            self.selectedSummaryTypeCaption = ko.observable(HEADING.phoneNumber);
            self.selectedSummaryTypeKey = ko.observable(_commonState.types.hostedNumber);
            self.summaryCalls = ko.observableArray([]);
            self.summaryMinutes = ko.observableArray([]);
            self.searchByFilter = ko.observable('calls');
            self.isCalls = ko.pureComputed(() => self.searchByFilter() === 'calls');
            self.isMinutes = ko.pureComputed(() => !self.isCalls());
            self.isMinified = ko.pureComputed(() => _browserType.windowWidth() <= 1200);
            self.showSummaryItemPhoneNumber = ko.observable(false);

            self.callsSortValue = ko.observable(CALLS_TABLE_COLUMNS.name.sortValue);
            self.callsSortAsc = ko.observable(true);

            self.minutesSortValue = ko.observable(MINUTES_TABLE_COLUMNS.name.sortValue);
            self.minutesSortAsc = ko.observable(true);

            self.columnSortAscending = ko.pureComputed(() => self.isCalls() ? self.callsSortAsc() : self.minutesSortAsc());
            self.columnBeingSorted = ko.pureComputed(() => self.isCalls() ? self.callsSortValue() : self.minutesSortValue());

            self.dataGridTableHeaders = ko.pureComputed(() => {
                const tableColumns = self.isCalls() ? CALLS_TABLE_COLUMNS : MINUTES_TABLE_COLUMNS;
                const sortBy = self.isCalls() ? self.callsSortValue : self.minutesSortValue;
                const sortAsc = self.isCalls() ? self.callsSortAsc : self.minutesSortAsc;

                return Object.keys(tableColumns).map((column) => {
                    return {
                        value: column,
                        title: self.isMinified() ? _i18n.t(`analyticsSummary:${_getColumnHeaderKey(column, 'phoneNumber')}Minified`) : _i18n.t(`analyticsSummary:${_getColumnHeaderKey(column, 'phoneNumber')}`),
                        headerCss: self.isCalls() ? `analytics-summary__grid-body-cell-${tableColumns[column].headerCss}` : `analytics-summary__grid-body-cell-${tableColumns[column].headerCss}--minutes`,
                        isSorted: ko.pureComputed(() => sortBy() === tableColumns[column].sortValue),
                        isSortedAsc: sortAsc
                    };
                });
            });

            const _updateSortBy = (columnSortValue) => {
                const sortBy = self.isCalls() ? self.callsSortValue : self.minutesSortValue;
                const sortAsc = self.isCalls() ? self.callsSortAsc : self.minutesSortAsc;

                if (sortBy() === columnSortValue && sortAsc() === true) {
                    sortAsc(false);
                } else {
                    sortBy(columnSortValue);
                    sortAsc(true);
                }
            };

            const _buildSummaryLine = (/** @type {string} */title, hideValue = false) => ({
                title,
                value: ko.observable(0),
                get valueDisplay() {
                    return self.showLoadingState() || hideValue ?
                        "-" :
                        this.value();
                },
                get durationDisplay() {
                    return self.showLoadingState() || hideValue ?
                        "-" :
                        _durationFormatter.formatSecondsSummary(this.value());
                }
            });

            self.totalCalls = {
                calls: _buildSummaryLine(HEADING.calls),
                outbound: _buildSummaryLine(HEADING.outbound),
                inbound: _buildSummaryLine(HEADING.inbound),
                answered: _buildSummaryLine(HEADING.answered),
                missed: _buildSummaryLine(HEADING.missed),
                system: _buildSummaryLine(HEADING.system),
            };

            self.totalMinutes = {
                minutes: _buildSummaryLine(HEADING.minutes),
                outbound: _buildSummaryLine(HEADING.outbound),
                inbound: _buildSummaryLine(HEADING.inbound),
                answered: _buildSummaryLine(HEADING.answered),
                missed: _buildSummaryLine(HEADING.missed, true),
                system: _buildSummaryLine(HEADING.system),
            };

            self.summaryRecords = ko.observableArray([]);
            self.summaryRecordsForSummaryType = ko.pureComputed(() => {
                return self.summaryRecords().filter(s => s.itemType === self.selectedSummaryTypeKey());
            });

            self.displayedSummaryRecords = ko.pureComputed(() => {
                const sorter = new SorterConstructor();
                const summaryRecordsForSummaryType = self.summaryRecordsForSummaryType();
                sorter.sort(summaryRecordsForSummaryType, self.columnBeingSorted(), self.columnSortAscending());
                return summaryRecordsForSummaryType;
            });

            const setSummaryTotals = () => {
                if (!_summaryTotalData()) {
                    return;
                }

                if (_searchByFilter()) {
                    _setSearchByFilter();
                }

                const summaryTotalRecord = _summaryTotalData();
                const totals = {
                    answeredCount: _summaryTotalRecordView.answeredCount(summaryTotalRecord),
                    inboundCount: _summaryTotalRecordView.inboundCount(summaryTotalRecord),
                    missedCount: _summaryTotalRecordView.missedCount(summaryTotalRecord),
                    outboundCount: _summaryTotalRecordView.outboundCount(summaryTotalRecord),
                    systemCount: _summaryTotalRecordView.systemCount(summaryTotalRecord),

                    answeredSeconds: _summaryTotalRecordView.answeredSeconds(summaryTotalRecord),
                    inboundSeconds: _summaryTotalRecordView.inboundSeconds(summaryTotalRecord),
                    missedSeconds: _summaryTotalRecordView.missedSeconds(summaryTotalRecord),
                    outboundSeconds: _summaryTotalRecordView.outboundSeconds(summaryTotalRecord),
                    systemSeconds: _summaryTotalRecordView.systemSeconds(summaryTotalRecord),
                };

                const totalCallCount = totals.inboundCount + totals.outboundCount;
                self.totalCalls.calls.value(totalCallCount);
                self.totalCalls.answered.value(totals.answeredCount);
                self.totalCalls.inbound.value(totals.inboundCount);
                self.totalCalls.missed.value(totals.missedCount);
                self.totalCalls.outbound.value(totals.outboundCount);
                self.totalCalls.system.value(totals.systemCount);

                const totalSeconds = totals.inboundSeconds + totals.outboundSeconds;
                self.totalMinutes.answered.value(totals.answeredSeconds);
                self.totalMinutes.inbound.value(totals.inboundSeconds);
                self.totalMinutes.minutes.value(totalSeconds);
                self.totalMinutes.missed.value(totals.missedSeconds);
                self.totalMinutes.outbound.value(totals.outboundSeconds);
                self.totalMinutes.system.value(totals.systemSeconds);
            };

            const setSummaryRecords = () => {

                if (_summaryData() === null) {
                    return [];
                }

                const simpleCallRecords = _summaryData().map(summaryRecord => {
                    const simpleCallRecord = {
                        itemGuid: _summaryRecordView.itemGuid(summaryRecord),
                        itemType: _summaryRecordView.itemType(summaryRecord),

                        answeredCount: _summaryRecordView.answeredCount(summaryRecord),
                        callCount: _summaryRecordView.callCount(summaryRecord),
                        inboundCount: _summaryRecordView.inboundCount(summaryRecord),
                        missedCount: _summaryRecordView.missedCount(summaryRecord),
                        outboundCount: _summaryRecordView.outboundCount(summaryRecord),
                        systemCount: _summaryRecordView.systemCount(summaryRecord),
                        voicemailCount: _summaryRecordView.voicemailCount(summaryRecord),

                        answeredSeconds: _summaryRecordView.answeredSeconds(summaryRecord),
                        callSeconds: _summaryRecordView.callSeconds(summaryRecord),
                        inboundSeconds: _summaryRecordView.inboundSeconds(summaryRecord),
                        missedSeconds: _summaryRecordView.missedSeconds(summaryRecord),
                        outboundSeconds: _summaryRecordView.outboundSeconds(summaryRecord),
                        systemSeconds: _summaryRecordView.systemSeconds(summaryRecord),
                        voicemailSeconds: _summaryRecordView.voicemailSeconds(summaryRecord),

                        isForwardingNumber: false,
                        isHostedNumber: false,
                        isUser: false,
                        isUserGroup: false,

                        averageSeconds: /** @type {number} */(undefined),
                        itemName:  /** @type {KnockoutObservable<string>} */(undefined),
                        iconName:  /** @type {string} */(undefined),
                        displayName:  /** @type {KnockoutObservable<string>} */(undefined),
                        phoneNumber:  /** @type {KnockoutObservable<string>} */(undefined)
                    };

                    simpleCallRecord.averageSeconds = simpleCallRecord.callSeconds / simpleCallRecord.callCount;
                    const commonStateItem = _commonState.get(simpleCallRecord.itemGuid);
                    simpleCallRecord.itemName = commonStateItem.name;
                    simpleCallRecord.iconName = commonStateItem.iconName;
                    if (simpleCallRecord.itemType !== _commonState.types.hostedNumber) {
                        simpleCallRecord.displayName = simpleCallRecord.itemName;
                    } else {
                        simpleCallRecord.phoneNumber = commonStateItem.phoneNumber;
                        simpleCallRecord.displayName = ko.pureComputed(() => self.showSummaryItemPhoneNumber() ?
                            simpleCallRecord.phoneNumber() :
                            simpleCallRecord.itemName());
                    }

                    switch (simpleCallRecord.itemType) {
                        case _commonState.types.forwardingNumber:
                            simpleCallRecord.isForwardingNumber = true;
                            break;
                        case _commonState.types.hostedNumber:
                            simpleCallRecord.isHostedNumber = true;
                            break;
                        case _commonState.types.user:
                            simpleCallRecord.isUser = true;
                            break;
                        case _commonState.types.userGroup:
                            simpleCallRecord.isUserGroup = true;
                            break;
                    }
                    return simpleCallRecord;
                });

                self.summaryRecords(simpleCallRecords);
            };

            self.anyItemHasAlternativeText = ko.pureComputed(() => {
                return self.selectedSummaryTypeKey() === _commonState.types.hostedNumber &&
                    self.summaryRecordsForSummaryType().some(s => s.itemName() !== s.phoneNumber());
            });

            self.showLoadingState = ko.observable(true);
            self.showEmptyState = ko.pureComputed(() => {
                return (self.summaryRecordsForSummaryType().length === 0);
            });

            const _setColumnSort = (columnSort) => {
                const isNewColumnSelected = self.columnBeingSorted() !== columnSort;
                self.columnBeingSorted(columnSort);
                self.columnSortAscending(isNewColumnSelected || self.columnSortAscending() === false);
            };

            const _setSortIndicatorCss = (sortKey) => ko.pureComputed(() => {
                if (self.columnBeingSorted() !== sortKey) {
                    return '';
                }
                if (self.columnSortAscending()) {
                    return 'indicator-triangle--up';
                }
                return 'indicator-triangle--down';
            });

            const _headingColumnCss = (/** @type {string} */column) => ko.pureComputed(() => `analytics-summary__grid-heading-cell-${column}`);

            const _buildHeadingObject = (key, column, sort) => ({
                key, column, sort,
                get click() {
                    return () => _setColumnSort(this.sort);
                },
                get css() {
                    return _headingColumnCss(this.column);
                },
                get sortIndicatorCss() {
                    return _setSortIndicatorCss(this.sort);
                }
            });

            const _callHeadings = [
                _buildHeadingObject(self.selectedSummaryTypeCaption, 'name', 'displayName'),
                _buildHeadingObject(HEADING.totalCalls, 'calls', 'callCount'),
                _buildHeadingObject(HEADING.outbound, 'outbound', 'outboundCount'),
                _buildHeadingObject(HEADING.inbound,'inbound', 'inboundCount'),
                _buildHeadingObject(HEADING.answered, 'answered', 'answeredCount'),
                _buildHeadingObject(HEADING.missed,'missed', 'missedCount'),
                _buildHeadingObject(HEADING.voicemails, 'voicemails', 'voicemailCount'),
                _buildHeadingObject(HEADING.totalDuration,'totalDuration', 'callSeconds'),
                _buildHeadingObject(HEADING.averageDuration, 'averageDuration', 'averageSeconds'),
            ];
            const _minuteHeadings = [
                _buildHeadingObject(self.selectedSummaryTypeCaption, 'name', 'displayName'),
                _buildHeadingObject(HEADING.minutes, 'duration', 'callSeconds'),
                _buildHeadingObject(HEADING.outbound, 'outbound', 'outboundSeconds'),
                _buildHeadingObject(HEADING.inbound, 'inbound', 'inboundSeconds'),
                _buildHeadingObject(HEADING.answered, 'answered', 'answeredSeconds'),
                _buildHeadingObject(HEADING.missed, 'missed', 'missedSeconds'),
                _buildHeadingObject(HEADING.voicemails, 'voicemails', 'voicemailSeconds'),
                _buildHeadingObject(HEADING.averageDuration, 'averageDuration', 'averageSeconds'),
            ];

            self.headingsDisplay = ko.pureComputed(() => self.isCalls() ? _callHeadings : _minuteHeadings);

            const createSummaryType = (label, type) => {
                const summaryType = {
                    label: label,
                    type: type
                };

                summaryType.isSelected = ko.pureComputed(() => type === self.selectedSummaryTypeKey());

                return summaryType;
            };

            self.summaryTypes = [
                createSummaryType(HEADING.phoneNumber, _commonState.types.hostedNumber),
                createSummaryType(HEADING.user, _commonState.types.user),
                createSummaryType(HEADING.userGroup, _commonState.types.userGroup),
                createSummaryType(HEADING.forwardingNumber, _commonState.types.forwardingNumber),
            ];

            self.toggleAlternativeName = () => {
                if (self.anyItemHasAlternativeText() === true) {
                    self.showSummaryItemPhoneNumber(!self.showSummaryItemPhoneNumber());
                }
            };

            const _setSearchByFilter = () => {
                self.searchByFilter(_searchByFilter());
            };

            self.sortColumn = (column) => {
                const columns = self.isCalls() ? CALLS_TABLE_COLUMNS : MINUTES_TABLE_COLUMNS;

                _updateSortBy(columns[column.value].sortValue);
            };

            self.activate = (activationObject) => {
                _summaryTotalData = activationObject.chartData.summaryTotalData;
                _disposables.push(_summaryTotalData.subscribe(setSummaryTotals));

                _summaryData = activationObject.chartData.summaryData;
                _disposables.push(_summaryData.subscribe(setSummaryRecords));

                self.showLoadingState = activationObject.showLoadingState;
                _searchByFilter = activationObject.parentViewModel().sidebarPane().selectedShowFilter;
                self.searchByFilter(_searchByFilter());

                self.selectedSummaryTypeCaption = ko.pureComputed(() => activationObject.selectedHeaderOption().label);
                self.selectedSummaryTypeKey = ko.pureComputed(() => activationObject.selectedHeaderOption().type);

                return _initialize();
            };
            self.detached = () => {
                _disposables.forEach(d => d.dispose());
                _disposables = [];
            };

            const _initialize = () => {
                setSummaryTotals();
                setSummaryRecords();
                return _promiseFactory.wait();
            };
        };
    }
);
