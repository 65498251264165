define('presentation/settings/userProfile/validators/changePasswordModalValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'common/specifications/validPasswordSpecification',
        'common/promises/promiseFactory'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ValidPasswordSpecificationConstructor = require('common/specifications/validPasswordSpecification');
        const _validPasswordSpecification = new ValidPasswordSpecificationConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        
        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("currentPassword")
                .addValidationI18n(_commonValidator.isStringWithValue, 'changePasswordModal:currentPasswordRequired')
                .addValidationI18n(_validPasswordSpecification.isSatisfiedBy, 'changePasswordModal:currentPasswordValid')
                .addValidationI18n(_passwordCorrect, 'changePasswordModal:currentPasswordValid');
            validationRules.field("newPassword")
                .addValidationI18n(_commonValidator.isStringWithValue, 'changePasswordModal:newPasswordRequired')
                .addValidationI18n(_validPasswordSpecification.isSatisfiedBy, 'changePasswordModal:newPasswordInvalid');
            validationRules.field("confirmPassword")
                .addValidationI18n(_passwordConfirmRequired, 'changePasswordModal:confirmPasswordRequired')
                .addValidationI18n(_passwordsMatch, 'passwordDoesNotMatch');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        const _passwordConfirmRequired = () => {
            const confirmPassword = _viewModel.confirmPassword();
            return _commonValidator.isStringWithValue(confirmPassword);
        };

        const _passwordCorrect = (password) => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.isPasswordCorrect(_viewModel.emailAddress, password)
                    .done(deferredObject.resolve)
                    .fail(deferredObject.reject);
            });
        };

        const _passwordsMatch = () => {
            const newPassword = _viewModel.newPassword();
            const confirmPassword = _viewModel.confirmPassword();
            if (_commonValidator.isStringWithValue(confirmPassword)) {
                return newPassword === confirmPassword;
            }

            return true;
        };

        self.validatePasswordsMatch = () => {
            return _passwordsMatch();
        };

        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

