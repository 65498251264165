define('common/converters/accountNumberFormatter',[], function() {
    return function() {
        var self = this;

        var _formatAccountNumber = function(accountId) {
            if (accountId === null || accountId === undefined) {
                return "";
            } else if (accountId.length === 9) {
                return accountId.slice(0, 3) + "-" + accountId.slice(3, 6) + "-" + accountId.slice(6, 9);
            } else {
                return accountId;
            }
        };

        self.formatAccountNumber = _formatAccountNumber;
    };
});

