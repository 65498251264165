define('presentation/signup/presentationObjects/signupEmailPresentationObject',[],function() {
    return function(){
        let self = this;

        self.emailAddress = null;
        self.isEmailStepCompleted = false;
        self.reservedPhoneNumber = null;
        self.emailVerificationCodeSendCount = 0;
        self.emailVerificationFailureCount = 0;
    };
});

