define('presentation/common/optionsBox/viewModels/optionsBoxViewModel',[], function() {
    return function() {
        const self = this;

        const _i18n = require('i18next');

        let _settings = {
            horizontalAlignment: null,
            disabledOptionTooltip: null,
            iconClass: null,
            optionClass: null,
            optionsClass: null,
            optionsClick: null,
            textClass: null,
        };

        let _horizontalAlignment = true;
        let _disabledOptionTooltip = '';
        let _iconName = 'optionIcon';
        let _iconClass = 'options-box__icon';
        let _optionClass = 'options-box__option';
        let _optionsClass = 'options-box';
        let _textClass = 'options-box__text';

        self.horizontalAlignment = ko.observable(_horizontalAlignment);
        self.tooltipText = ko.observable(_disabledOptionTooltip);
        self.options = ko.observableArray();
        self.optionClass = ko.pureComputed(() => self.horizontalAlignment() ?
            _optionClass :
            `${_optionClass} ${_optionClass}--vertical`
        );
        self.optionsClass = ko.pureComputed(() => self.horizontalAlignment() ?
            _optionsClass :
            `${_optionsClass}--vertical`
        );

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.horizontalAlignment === false) {
                _horizontalAlignment = ko.unwrap(_settings.horizontalAlignment);
                self.horizontalAlignment(_horizontalAlignment);
            }
            if (_settings.iconName) {
                _iconName = ko.unwrap(_settings.iconName);
            }
            if (_settings.iconClass) {
                _iconClass = ko.unwrap(_settings.iconClass);
            }
            if (_settings.optionClass) {
                _optionClass = ko.unwrap(_settings.optionClass);
            }
            if (_settings.optionsClass) {
                _optionsClass = ko.unwrap(_settings.optionsClass);
            }
            if (_settings.textClass) {
                _textClass = ko.unwrap(_settings.textClass);
            }
            if (_settings.disabledOptionTooltip) {
                _disabledOptionTooltip = ko.unwrap(_settings.disabledOptionTooltip);
            }

            const options = ko.unwrap(_settings.options);
            options.forEach((option, index) => {
                option.isSelected = ko.pureComputed(() => option.value === _settings.value());
                option.iconName = option.iconName ? option.iconName : _iconName;
                option.iconClass = ko.pureComputed(() => option.isSelected() ?
                    `${_iconClass} ${_iconClass}--selected` :
                    ko.unwrap(option.isDisabled) ?
                        `${_iconClass} ${_iconClass}--disabled` :
                        _iconClass
                );
                option.optionClass = ko.pureComputed(() => option.isSelected() ?
                    `${self.optionClass()} ${_optionClass}--selected` :
                    ko.unwrap(option.isDisabled) ?
                        `${self.optionClass()} ${_optionClass}--disabled` :
                        self.optionClass()
                );
                option.textClass = ko.pureComputed(() => option.isSelected() ?
                    `${_textClass} ${_textClass}--selected` :
                    ko.unwrap(option.isDisabled) ?
                        `${_textClass} ${_textClass}--disabled` :
                        _textClass
                );
                if (option.textI18n) {
                    option.displayText = _i18n.t(option.textI18n);
                } else if (option.text) {
                    option.displayText = option.text;
                }
                option.tooltipText = option.tooltipI18n ? _i18n.t(option.tooltipI18n) : _disabledOptionTooltip;
                option.hasTooltip = ko.pureComputed(() => option.tooltipI18n !== undefined);
                option.optionClick = () => {
                    if (ko.unwrap(option.isDisabled)) {
                        return;
                    }
                    if (option.value !== _settings.value()) {
                        _settings.value(option.value);

                        if (option.optionClick) {
                            option.optionClick();
                        }
                        if (_settings.optionsClick) {
                            _settings.optionsClick(this.value);
                        }
                    }
                };
            });

            self.options(options);
        };
    };
});
