define('entities/accountClosedFeedbackEntity',[],function() {
    return function(){
        const self = this;
        self.accountClosedFeedbackTypeDiscriminator = null;
        self.howLikelyToRecommend = null;
        self.generalFeedback = null;
        self.isSwitchingService = null;
        self.switchingServiceName = null;
        self.lacksFeaturesFeedbackReasons = null;
        self.lackFeaturesFeedback = null;
        self.difficultToUseFeedback = null;
        self.accountClosedTooExpensiveFeedbackTypeDiscriminator = null;
        self.tooExpensiveOtherFeedback = null;
        self.fraudStatus = null;
        self.billingProblemFeedback = null;
        self.technicalProblemFeedback = null;
        self.poorCustomerServiceFeedback = null;
        self.accountClosedNoLongerNeededFeedbackTypeDiscriminator = null;
        self.noLongerNeededOtherFeedback = null;
        self.otherFeedback = null;
    };
});

