define('presentation/common/voicemail/voicemailAudioFactory',['presentation/common/audioPresentationObject',
        'constants/audioTypeEnumerations'],
        function (AudioPresentationObjectConstructor, webAudioFormatsEnumeration) {

    const getEmptyVoicemail = () => {
        let emptyVoicemail = new AudioPresentationObjectConstructor();
        emptyVoicemail.audioData("about:blank");
        emptyVoicemail.audioType(webAudioFormatsEnumeration.mp3);
        return emptyVoicemail;
    };

    const playVoicemail = (voicemailMessageUrl) => {
        let audioData = new AudioPresentationObjectConstructor();
        audioData.audioType(webAudioFormatsEnumeration.mp3);
        audioData.audioData(voicemailMessageUrl);
        audioData.showAudio(true);
        return audioData;
    };

    return {
        getEmptyVoicemail,
        playVoicemail,
    };
});
