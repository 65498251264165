define('presentation/signup/viewModels/signupPhoneNumberTollfreeViewModel',[
    'businessServices/browserSupport/browserType',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'constants/numberPickerConstants',
    'constants/signupSessionFlowConstants',
    'constants/signupSessionStatusConstants',
    'presentation/signupNumberPicker/presentationObjects/signupNumberPickerPresentationObject',
    'presentation/signup/common/signupNavigationManager',
    'presentation/signup/facades/signupPhoneNumberTollfreeFacade',
    'settings/navigationConfiguration'
], function() {

    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const NumberPresentationObjectConstructor = require('presentation/signupNumberPicker/presentationObjects/signupNumberPickerPresentationObject');

        const _browserType = require('businessServices/browserSupport/browserType');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
        const _numberPickerConstants = require('constants/numberPickerConstants');
        const _signupSessionStatusConstants = require('constants/signupSessionStatusConstants');

        let _facade = null;
        let _signupNavigationManager = null;
        let _disposables = [];

        self.isCompositionComplete = ko.observable(false);
        self.isModalActive = ko.observable(false);
        self.isModalVisible = ko.observable(false);
        self.isXCloseVisible = true;
        self.modalPhoneNumber = ko.observable("");
        self.isScrollActive = ko.observable(false);

        self.numberType = _numberPickerConstants.numberTypes.tollFree;
        self.searchType = ko.observable(_numberPickerConstants.searchTypeConstants.default);
        self.selectedPhoneNumbers = ko.observableArray([]);
        self.vanityString = ko.observable('');
        self.geoLocation = null;

        self.signupSessionHasNumber = ko.pureComputed(() => {
            return self.selectedPhoneNumbers().length > 0;
        });

        self.showValidationMessage = ko.observable(false);
        const _resetShowValidationMessage = (selectedPhoneNumbers => {
            if (self.showValidationMessage()) {
                self.showValidationMessage(selectedPhoneNumbers.length < 1);
            }
        });
        _disposables.push(self.selectedPhoneNumbers.subscribe(_resetShowValidationMessage));

        self.flow = ko.observable();
        self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
        self.isFlowBTextVisible = ko.computed(() => self.flow() === _signupSessionFlowConstants.flowB && self.isMobile());
        self.navigateToFlowD = () => {
            _signupNavigationManager.navigateToRoute(_navigationConfiguration.routesById.signupPhoneNumberType.routeId, _signupSessionFlowConstants.flowD);
        };

        self.isEmptyButton = (prefix) => {
            return prefix === "";
        };

        self.onForwardArrowClicked = () => {
            return _promiseFactory.defer((deferredObject) => {
                if (!self.signupSessionHasNumber()) {
                    self.showValidationMessage(true);
                    deferredObject.resolve(false);
                } else {
                    _facade.setPhoneNumberCompleted(self.signupSessionHasNumber())
                        .fail(deferredObject.reject)
                        .done(() => {
                            deferredObject.resolve(true);
                        });
                }
            });
        };

        const _showNumberReservationModal = (phoneNumber) => {
            self.modalPhoneNumber(_phoneNumberFormatter.toUSAreaCode(phoneNumber));
            self.isModalActive(true);
            setTimeout(() => {
                self.isModalVisible(true);
            }, 100);
        };

        self.modalCloseClick = () => {
            self.isModalVisible(false);
            setTimeout(() => {
                self.isModalActive(false);
            }, 250);
        };

        self.detached = () => {
            _signupNavigationManager.detached();
            _disposables.forEach(d => d.dispose());
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/signup/facades/signupPhoneNumberTollfreeFacade');
            _facade = new FacadeConstructor();
            _facade.init();

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            self.flow(_signupNavigationManager.getFlowId());

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.deferIndefinitely(promise => {
                _facade.get()
                    .done((signupSession) => {
                        const phoneNumber = signupSession.reservedPhoneNumber;
                        const vanityString = signupSession.reservedPhoneNumberVanityString || '';
                        self.vanityString(vanityString);
                        self.searchType(_numberPickerConstants.searchTypeLookup[signupSession.reservedPhoneNumberSearchType] || _numberPickerConstants.searchTypeConstants.default);

                        if (signupSession.signupSessionStatus === _signupSessionStatusConstants.numberProvisioningFailed) {
                            _facade.clearSessionStateReservedPhoneNumber();
                            promise.resolve();
                        } else if (_phoneNumberFormatter.isTollFree(phoneNumber) === false) {
                            promise.resolve();
                        } else {
                            self.geoLocation = {
                                prefix: _phoneNumberFormatter.getNpa(phoneNumber),
                            };
                            _facade.reReserveSessionPhoneNumber()
                                .fail(promise.reject)
                                .done((response) => {
                                    if (response.status !== _numberPickerConstants.successfullyReserved) {
                                        _showNumberReservationModal(phoneNumber);
                                    } else {
                                        const numberPresentationObject = new NumberPresentationObjectConstructor({
                                            phoneNumber,
                                            vanityString
                                        });
                                        numberPresentationObject.isSelected(true);
                                        numberPresentationObject.isChecked(true);
                                        self.selectedPhoneNumbers([numberPresentationObject]);
                                    }
                                    promise.resolve();
                                });
                        }
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
