define('presentation/settings/userVoicemail/viewModels/userVoicemailSubscribersViewModel',['businessServices/state/modelStateObserver',
        'common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/voicemailConstants',
        'presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel',
        'presentation/settings/userVoicemail/facades/userVoicemailSubscribersFacade',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        let _facade = null;
        let _userId = null;

        const _initializeSubscribers = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getVoicemailSubscribers(_userId)
                    .fail(promise.reject)
                    .done((subscriberInfo) => {
                        const subscribers = subscriberInfo.subscribers;
                        const subscribersLog = subscriberInfo.subscribersLog;
                        _setSelectedSubscribers(subscribers);
                        self.subscriberSelectionViewModel.activate(self.selectedSubscribers())
                            .fail(promise.reject)
                            .done(() => {
                                self.subscriberSelectionViewModel.setSubscribersLog(subscribersLog);
                                self.modelStateObserver.addChildObserver(self.subscriberSelectionViewModel.modelStateObserver);
                                promise.resolve();
                            });
                    });
            });
        };

        const _reloadSubscribers = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _facade.getVoicemailSubscribers(_userId)
                    .fail(promise.reject)
                    .done((subscriberInfo) => {
                        _setSelectedSubscribers(subscriberInfo.subscribers);
                        self.subscriberSelectionViewModel.reloadSubscribers(self.selectedSubscribers())
                            .fail(promise.reject)
                            .done(() => {
                                self.subscriberSelectionViewModel.setSubscribersLog(subscriberInfo.subscribersLog);
                                promise.resolve();
                            });
                    });
            });
        };

        const _setSelectedSubscribers = (subscribers) => {
            self.selectedSubscribers = ko.pureComputed(() => {
                let selectedSubscribers = subscribers.map(subscriber => {
                    const commonStateItem = _commonState.get(subscriber.subscriberId);
                    const avatar = commonStateItem.type === "user" ? {avatar: commonStateItem.avatar()} : {};
                    const ownerProperties = commonStateItem.id !== _userId ? {} : {
                        readOnly: true,
                        removeToolTipText: self.isUserSelf() ?
                            _i18n.t('userVoicemail:lastMemberSelf') :
                            _i18n.t('userVoicemail:lastMember')
                    };

                    return Object.assign({}, subscriber, {
                            id: commonStateItem.id,
                            subscriberType: commonStateItem.type,
                            displayName: commonStateItem.name()
                        },
                        avatar,
                        ownerProperties);
                });

                _sorter.sort(selectedSubscribers, "displayName", true);
                return selectedSubscribers;
            });
        };

        self.modelStateObserver = null;
        self.subscriberSelectionViewModel = null;
        self.isUserSelf = ko.observable(true);

        self.save = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                self.subscriberSelectionViewModel.getSubscribers()
                    .fail(promise.reject)
                    .done((subscribers) => {
                        _facade.saveVoicemailSubscribers(_userId, subscribers)
                            .fail(promise.reject)
                            .done(() => {
                                _reloadSubscribers()
                                    .fail(promise.reject)
                                    .done(promise.resolve);
                            });
                    });
            });
        };


        self.cancelForm = () => {
            return _promiseFactory.defer((promise) =>{
                self.modelStateObserver.restoreData();
                _reloadSubscribers()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        self.activate = (userId) => {
            _userId = userId;
            self.isUserSelf(_userId === _sessionAccountInfo.userId());

            const FacadeConstructor = require('presentation/settings/userVoicemail/facades/userVoicemailSubscribersFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const SubscriberSelectionConstructor = require('presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel');
            self.subscriberSelectionViewModel = new SubscriberSelectionConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            _initializeSubscribers()
                .done(() => {
                    self.modelStateObserver.commitData();
                })
                .fail((error) => {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

