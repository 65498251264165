define('presentation/settings/autoAttendant/facades/autoAttendantRoutingRulesFacade',[
        'common/promises/promiseFactory',
        'presentation/common/callFlow/common/callFlowBuilder',
    ],
    function () {

        const CallFlowBuilder = require('presentation/common/callFlow/common/callFlowBuilder');
        let _promiseFactory = null;
        let _routingRules = null;

        const _buildRoutingRulesForDisplay = (_subMenuItem, availableObjects) => {
            return _promiseFactory.defer((deferredObject) => {
                let callFlowBuilder = new CallFlowBuilder();
                callFlowBuilder.availableVoicePrompts = availableObjects.availableVoicePrompts;
                callFlowBuilder.availableSchedules = availableObjects.availableSchedules;
                callFlowBuilder.init(_promiseFactory);

                _routingRules = [];
                _routingRules.push(_subMenuItem.routingRules());
                callFlowBuilder.buildRoutingRules(_subMenuItem.routingRules(), _routingRules);
                callFlowBuilder.formatCallFlowForDisplay(_subMenuItem, _routingRules)
                    .done((routingRules) => {
                        deferredObject.resolve(routingRules);
                    });
            });
        };

        const _init = (promiseFactory) => {

            _promiseFactory = promiseFactory;
        };

        return function () {
            let self = this;

            self.init = _init;
            self.buildRoutingRulesForDisplay = _buildRoutingRulesForDisplay;
        };
    });
