define('presentation/settings/phoneNumbers/viewModels/phoneNumbersViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/events/eventManager',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/formOfBusinessEnumerations',
    'constants/howWillYouUseTrestaEnumerations',
    'constants/scrollEventSources',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/deletePhoneNumberActionViewModel',
    'presentation/common/modal',
    'common/converters/phoneNumberFormatter',
    'presentation/settings/phoneNumbers/facades/phoneNumbersFacade',
    'presentation/settings/phoneNumbers/viewModels/messagingFeeConfirmationModalViewModel',
    'settings/navigationConfiguration',
    'i18next',
], function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deletePhoneNumberActionViewModel');
        const MessagingFeeConfirmationModalViewModel = require('presentation/settings/phoneNumbers/viewModels/messagingFeeConfirmationModalViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _commonState = require('common/storage/commonState');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        let _disposables = [];
        let _eventManager = null;
        let _facade = null;
        let _getNextBatchOfPhoneNumbersEventId = null;
        let _getNextBatchCalled = false;
        let _modalService = null;

        const _i18n = require('i18next');

        const _howWillYouUseTrestaEnumerations = require('constants/howWillYouUseTrestaEnumerations');
        const _formOfBusinessEnumerations = require('constants/formOfBusinessEnumerations');

        const CANCEL_RESPONSE = "cancel";
        const DELETE_RESPONSE = "yes";
        const ROWS_PER_PAGE = 15;

        let _state = {
            availablePhoneNumbers: null,
            availableVoicePrompts: null,
            availableSchedules: null,
            availableAutoAttendants: null,
            filteredPhoneNumbers: null,
            phoneNumbersShown: 0
        };

        const _isDeleteInactive = () => {
            let activePhoneNumbers = 0;
            for (let i = 0; activePhoneNumbers < 2 && i < _state.availablePhoneNumbers.length; i++) {
                if (!_state.availablePhoneNumbers[i].isPortPending()) {
                    activePhoneNumbers++;
                }
            }
            return activePhoneNumbers < 2;
        };

        const _refreshDisplayedPhoneNumbers = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.findAllConnectors()
                    .done((connectors, availableVoicePrompts, availableSchedules, availableAutoAttendants) => {
                        _state.availablePhoneNumbers = connectors;
                        _state.availableVoicePrompts = availableVoicePrompts;
                        _state.availableSchedules = availableSchedules;
                        _state.availableAutoAttendants = availableAutoAttendants;
                        if (_state.phoneNumbersShown < 1) {
                            _state.phoneNumbersShown = ROWS_PER_PAGE;
                        }
                        _onSortTypeHandlerChanged();
                        self.isDeleteInactive(_isDeleteInactive());
                        self.displayedPhoneNumbers(_state.filteredPhoneNumbers.slice(0, _state.phoneNumbersShown));
                        if (self.loadConnectorsCollapsed) {
                            self.displayedPhoneNumbers().forEach((phoneNumber) => {
                                phoneNumber.showCallFlowDetails(false);
                            });
                        }
                        if (_getNextBatchOfPhoneNumbersEventId === null) {
                            _getNextBatchOfPhoneNumbersEventId = _eventManager.subscribeBottomScrollBufferReached(_getNextBatchOfPhoneNumbers);
                        }

                        if (!connectors.length) {
                            deferredObject.resolve();
                        }

                        connectors.forEach(connector => {
                            connector.inboundCallingTabViewModel.activate(connector, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                            connector.outboundDialTabViewModel.activate(connector);
                            connector.callRecordingTabViewModel.activate(connector);
                            connector.textMessagingTabViewModel.activate(connector);
                            deferredObject.resolve();
                        });
                    });
            });
        };

        const _getNextBatchOfPhoneNumbers = (scrollEventSource) => {
            if (scrollEventSource !== self.scrollEventSource) {
                return;
            }

            if (self.lastPage === false && _getNextBatchCalled === false){
                _getNextBatchCalled = true;
                let additionalPhoneNumbersShown = _state.phoneNumbersShown + ROWS_PER_PAGE;
                let additionalPhoneNumbers = _state.filteredPhoneNumbers.slice(_state.phoneNumbersShown, additionalPhoneNumbersShown);
                if (self.loadConnectorsCollapsed) {
                    additionalPhoneNumbers.forEach((phoneNumber) => {
                        phoneNumber.showScheduleDetails(false);
                        phoneNumber.showCallFlowDetails(false);
                    });
                }
                self.displayedPhoneNumbers.push.apply(self.displayedPhoneNumbers, additionalPhoneNumbers);
                _state.phoneNumbersShown = additionalPhoneNumbersShown;
                if(_state.phoneNumbersShown >= _state.filteredPhoneNumbers.length) {
                    self.lastPage = true;
                }
                _getNextBatchCalled = false;
            }
        };

        const _updateDisplayedPhoneNumbers = () => {
            let filteredConnectors;
            let filterContent = self.searchText();
            if (filterContent !== '') {
                let connectorFilter = new FilterConstructor();
                connectorFilter.addProperty('phoneNumber');
                connectorFilter.addProperty('phoneNumberDisplayName');
                connectorFilter.addProperty('formattedPhoneNumber');
                connectorFilter.addProperty('formattedCityState');
                connectorFilter.addProperty('activeDateTime.filterValue');
                connectorFilter.addProperty('modifiedDateTime.filterValue');
                connectorFilter.addProperty('name');
                connectorFilter.addProperty('routeSearchData');
                connectorFilter.addProperty('outboundSearchData');
                connectorFilter.addProperty('recordingSearchData');
                connectorFilter.addProperty('smsSearchData');
                connectorFilter.addProperty('routeToType');
                connectorFilter.addProperty('routeType');
                connectorFilter.addProperty('forwardingNumberPhoneNumber');
                connectorFilter.addValue(filterContent);

                filteredConnectors = connectorFilter.filter(_state.availablePhoneNumbers);

                _state.availablePhoneNumbers.forEach(connector => {
                    if (connector.callFlow) {
                        let filteredCallFlowItem = connectorFilter.filter(connector.callFlow);
                        _maybeAddToFilteredConnectors(filteredCallFlowItem, connector, filteredConnectors);
                        _filterCallFlow(connector.callFlow, connector, connectorFilter, filteredConnectors);
                    }
                });
            }
            else {

                filteredConnectors = _state.availablePhoneNumbers;
            }

            _state.filteredPhoneNumbers = filteredConnectors;
        };

        const _maybeAddToFilteredConnectors = (filteredItem, connector, filteredConnectors) => {
            if (filteredItem.length > 0) {
                if (filteredConnectors.every(filteredConnector => {
                        return filteredConnector.phoneId !== connector.phoneId;
                    })) {
                    filteredConnectors.push(connector);
                }
            }
        };

        const _filterCallFlow = (callFlowToFilter, connector, connectorFilter, filteredConnectors) => {
            callFlowToFilter.forEach(callFlow => {

                if (callFlow.autoAttendant()) {
                    let autoAttendant = callFlow.autoAttendant();
                    let subMenuItemsToFilter = autoAttendant.autoAttendant().subMenuItems;

                    let filteredSubMenuItem = connectorFilter.filter(subMenuItemsToFilter);
                    _maybeAddToFilteredConnectors(filteredSubMenuItem, connector, filteredConnectors);

                    subMenuItemsToFilter.forEach(subMenuItem => {
                        let subMenuCallFlowsToFilter = subMenuItem.callFlow;

                        let filteredCallFlowItem = connectorFilter.filter(subMenuCallFlowsToFilter);
                        _maybeAddToFilteredConnectors(filteredCallFlowItem, connector, filteredConnectors);
                        _filterCallFlow(subMenuCallFlowsToFilter, connector, connectorFilter, filteredConnectors);
                    });
                }

                if (callFlow.subMenuItems) {
                    let childSubMenuItems = callFlow.subMenuItems;

                    childSubMenuItems.forEach(childSubMenuItem => {
                        let childSubMenuCallFlowsToFilter = childSubMenuItem.callFlow;

                        let filteredChildSubMenuItem = connectorFilter.filter(childSubMenuCallFlowsToFilter);
                        _maybeAddToFilteredConnectors(filteredChildSubMenuItem, connector, filteredConnectors);
                        _filterCallFlow(childSubMenuCallFlowsToFilter, connector, connectorFilter, filteredConnectors);
                    });
                }

                if (callFlow.scheduleSegments) {
                    let scheduleSegmentsToFilter = callFlow.scheduleSegments;

                    scheduleSegmentsToFilter.forEach(scheduleSegment => {

                        let filteredScheduleSegment = connectorFilter.filter(scheduleSegmentsToFilter);
                        _maybeAddToFilteredConnectors(filteredScheduleSegment, connector, filteredConnectors);

                        let filteredScheduleSegmentItem = connectorFilter.filter(scheduleSegment.callFlow);
                        _maybeAddToFilteredConnectors(filteredScheduleSegmentItem, connector, filteredConnectors);
                        _filterCallFlow(scheduleSegment.callFlow, connector, connectorFilter, filteredConnectors);
                    });
                }
            });
        };

        const _onPhoneNumberFilterChanged = () => {
            _updateDisplayedPhoneNumbers();
            self.lastPage = false;
            _state.phoneNumbersShown = ROWS_PER_PAGE;
            self.displayedPhoneNumbers(_state.filteredPhoneNumbers.slice(0, _state.phoneNumbersShown));
        };

        const _onSortTypeHandlerChanged = () => {
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(_state.availablePhoneNumbers);
                _updateDisplayedPhoneNumbers();
                self.lastPage = false;
                _state.phoneNumbersShown = ROWS_PER_PAGE;
                self.displayedPhoneNumbers(_state.filteredPhoneNumbers.slice(0, _state.phoneNumbersShown));
            }
        };

        const _buildConfirmationModal = () => {
            self.actionModal
                .clearModal()
                .setContentViewModel(MessagingFeeConfirmationModalViewModel, [self.isSoleProprietor, self.hasOnlyOneEnabledLocalNumber])
                .setHeaderText({i18n: 'messagingFeeConfirmationModal:headerTitle'})
                .setShouldDisplayCancelButton(true)
                .setShouldDisplaySubmitButton(true)
                .setSubmitButtonText({i18n: 'continue'})
                .setShouldScrollIntoView(false)
                .setIsScrollDisabled(true);
        };

        const _showDisableSmsConfirmationModal = (phoneNumberPresentationObject) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _buildConfirmationModal(phoneNumberPresentationObject);

                self.actionModal.showModal()
                    .done((result) => {
                        if (result.isValid) {
                            promise.resolve({isValid: result.isValid});
                        }
                        if (result === CANCEL_RESPONSE) {
                            promise.resolve();
                        }
                    });
            });
        };

        const _buildDeleteModal = (phoneNumberPresentationObject) => {
            const displayType = _commonState.typeDisplay.hostedNumber;
            let constructorParams = [
                phoneNumberPresentationObject,
                _state.availableVoicePrompts,
                _state.availableSchedules,
                _state.availableAutoAttendants
            ];

            self.actionModal
                .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: {key: 'deleteHeader', options: {type: displayType}}})
                .setSubmitButtonText({i18n: 'delete'})
                .setShouldScrollIntoView(false);
        };

        const _showDeletePrompt = (phoneNumberPresentationObject) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                const phoneId = phoneNumberPresentationObject.phoneId;

                _buildDeleteModal(phoneNumberPresentationObject);
                self.actionModal.showModal()
                    .then(result => {
                        if (result === DELETE_RESPONSE) {
                            _facade.deletePhoneConnector(phoneId)
                                .done(() => {
                                    self.displayedPhoneNumbers.remove(phoneNumberPresentationObject);

                                    _state.filteredPhoneNumbers = _state.filteredPhoneNumbers.filter((phoneConnector) => {
                                        return phoneConnector.phoneId !== phoneId;
                                    });

                                    _state.availablePhoneNumbers = _state.availablePhoneNumbers.filter((phoneConnector) => {
                                        return phoneConnector.phoneId !== phoneId;
                                    });
                                    self.isDeleteInactive(_isDeleteInactive());

                                    if (self.displayedPhoneNumbers().length < ROWS_PER_PAGE) {
                                        _getNextBatchOfPhoneNumbers();
                                    }

                                    if (self.customerAcknowledgedSmsNotice()) {
                                        _facade.sendMessagingFeeConfirmationAutoNotation(phoneNumberPresentationObject.formattedPhoneNumber())
                                            .fail(promise.reject)
                                            .done(promise.resolve);
                                    } else {
                                        promise.resolve();
                                    }
                                })
                                .fail((error) => {
                                    error.phoneConnectorPresentationObject = phoneNumberPresentationObject;
                                    error.phoneConnectorId = phoneId;
                                    promise.reject(error);
                                    throw error;
                                });
                        }
                    })
                    .fail((error) => {
                        error.phoneConnectorPresentationObject = phoneNumberPresentationObject;
                        promise.reject(error);
                        throw error;
                    });
            });
        };

        self.tollFreeText = _i18n.t('common:tollFree');
        self.lastPage = false;
        self.scrollEventSource = ScrollEventSources.settingsPhoneNumbers;
        self.isCompositionComplete = ko.observable(false);
        self.isSoleProprietor = ko.observable(false);
        self.loadConnectorsCollapsed = ko.pureComputed(() => self.displayedPhoneNumbers().length > 2);
        self.addPhoneNumberUrl = _navigationConfiguration.routesById.newPhoneNumberType.url;
        self.closeAccountUrl = _navigationConfiguration.routesById.closeAccount.url;
        self.customerAcknowledgedSmsNotice = ko.observable(false);

        self.displayedPhoneNumbers = ko.observableArray([]);

        self.searchText = ko.observable("");
        _disposables.push(self.searchText.subscribe(_onPhoneNumberFilterChanged));
        self.sortOptions = ko.observableArray([
            {textI18n: "name",  iconName: 'sortName', sortPath : "phoneNumberName", isAscending : true},
            {textI18n: "phoneNumber", iconName: 'sortPhoneNumber', sortPath : "phoneNumber", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "activeDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.viewOptions = [
            {textI18n: 'expandAll', iconName: 'sortExpandAll', onClick: () => self.expandAllView()},
            {textI18n: 'collapseAll', iconName: 'sortCollapseAll', onClick: () => self.collapseAllView()},
        ];
        self.sortType = ko.observable("phoneNumberName");
        _disposables.push(self.sortType.subscribe(_onSortTypeHandlerChanged));
        self.isDeleteInactive = ko.observable(false);
        self.showResults = ko.pureComputed(() => {
            return self.displayedPhoneNumbers().length > 0;
        });
        self.showEmptyStateIcons = ko.observable(false);
        self.emptyStateContentKey = ko.pureComputed(() => _state.availablePhoneNumbers.length > 0 ? "noResults" : "noPhoneNumbers");
        self.showNoPhoneNumbersWarningMessage = ko.pureComputed(() => _state.availablePhoneNumbers.length === 0);
        self.isBillingAdmin = ko.pureComputed(() => _sessionAccountInfo.accountPermissions().Billing);
        self.hasOnlyOneEnabledLocalNumber = ko.pureComputed(() => {
            const enabledLocalNumbers = _state.availablePhoneNumbers.filter((n) => {
                return n.isSmsEnabled() && n.isLocalPhoneNumber();
            });
            return enabledLocalNumbers.length === 1;
        });

        self.deletePhoneConnector = (phoneNumberPresentationObject) => {
            self.customerAcknowledgedSmsNotice(false);
            const showDisableSmsConfirmationModal = phoneNumberPresentationObject.isLocalPhoneNumber() &&
                phoneNumberPresentationObject.isSmsEnabled() &&
                self.hasOnlyOneEnabledLocalNumber();

            if (showDisableSmsConfirmationModal) {
                _showDisableSmsConfirmationModal(phoneNumberPresentationObject)
                    .done((result) => {
                        _modalService.closeModal(self.actionModal);
                        if (result.isValid) {
                            self.customerAcknowledgedSmsNotice(true);
                            _showDeletePrompt(phoneNumberPresentationObject);
                        }
                    });
            } else {
                _showDeletePrompt(phoneNumberPresentationObject);
            }
        };

        self.expandAllView = () => {
            const expandCallFlows = (callFlows) => {
                if (!callFlows) {
                    return;
                }

                callFlows.forEach(callFlow => {
                    if (callFlow.scheduleSegments) {
                        callFlow.scheduleSegments.forEach((segment) => {
                            segment.showScheduleSegmentDetails(true);
                            expandCallFlows(segment.callFlow);
                        });
                    }
                    if (callFlow.autoAttendant() !== null) {
                        callFlow.autoAttendant().expandAllView();
                    }
                });
            };
            self.displayedPhoneNumbers().forEach((phoneNumber) => {
                phoneNumber.showScheduleDetails(true);
                phoneNumber.showCallFlowDetails(true);
                expandCallFlows(phoneNumber.callFlow);
                phoneNumber.outboundDialTabViewModel.expand();
                phoneNumber.callRecordingTabViewModel.expand();
                phoneNumber.textMessagingTabViewModel.expand();
            });
        };

        self.collapseAllView = () => {
            self.displayedPhoneNumbers().forEach((phoneNumber) => {
                phoneNumber.showScheduleDetails(false);
                phoneNumber.showCallFlowDetails(false);
                phoneNumber.outboundDialTabViewModel.collapse();
                phoneNumber.callRecordingTabViewModel.collapse();
                phoneNumber.textMessagingTabViewModel.collapse();
            });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () =>{
            _disposables.forEach(s => s.dispose);
            _disposables = [];
            _eventManager.unsubscribe(_getNextBatchOfPhoneNumbersEventId);
            _facade.dispose();
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/phoneNumbers/facades/phoneNumbersFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            _refreshDisplayedPhoneNumbers();

            _facade.getAccountProfile()
                .done((accountProfile) => {
                    self.isSoleProprietor(accountProfile.howWillYouUseTresta === _howWillYouUseTrestaEnumerations.personal ||
                        accountProfile.formOfBusiness === _formOfBusinessEnumerations.soleProprietor);
                });

            _facade.onNewPromptConverted((promptInfo) => {
                const {accountVoicePromptId, audioData} = promptInfo;

                if (_state.availablePhoneNumbers) {
                    _state.availablePhoneNumbers.forEach((phoneNumber) => {
                        if (phoneNumber.callFlow) {
                            phoneNumber.callFlow.forEach((callFlowSegment) => {
                                if (callFlowSegment.routeToItemId === accountVoicePromptId) {
                                    callFlowSegment.currentVoicePrompt(audioData);
                                    callFlowSegment.conversionComplete(true);
                                    callFlowSegment.conversionInProgress(false);
                                }
                            });
                        }
                    });
                }
            });

            _eventManager.subscribeRouteToModalChanged(_refreshDisplayedPhoneNumbers);

            return _promiseFactory.wait();
        };
    };
});

