define('presentation/settings/messagingCompliance/facades/messagingComplianceFacade',[
    'businessServices/converters/textConverter',
    'common/promises/promiseFactory',
    'persistence/repositories/messagingComplianceRepository',
    'presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject',
    'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceAutoResponsePresentationObject',
    'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceLocalPresentationObject',
    'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject',
    'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceTollfreePresentationObject',
], function(
    /** @type typeof import('businessServices/converters/textConverter') */
    TextConverter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('persistence/repositories/messagingComplianceRepository') */
    MessagingComplianceRepository,
    /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject') */
    MessagingCompliancePresentationObject,
    /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceAutoResponsePresentationObject') */
    MessagingComplianceAutoResponsePresentationObject,
    /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceLocalPresentationObject') */
    MessagingComplianceLocalPresentationObject,
    /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject') */
    MessagingComplianceProfilePresentationObject,
    /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceTollfreePresentationObject') */
    MessagingComplianceTollfreePresentationObject
) {
    /** @typedef { import('presentation/settings/messagingCompliance/facades/messagingComplianceFacade') } MessagingComplianceFacade } */
    /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject') } MessagingCompliancePresentationObject } */

    const _promiseFactory = new PromiseFactory();
    const _textConverter = new TextConverter();

    const _messagingComplianceRepository = new MessagingComplianceRepository();

    const _cleanDataResults = (/** @type { any } */ dataObject) => {
        for (let property in dataObject) {
            if (dataObject[property] === null || dataObject[property] === undefined) {
                dataObject[property] = "";
            }
        }
    };

    const _buildMessagingCompliancePresentationObject = (/** @type { IMessagingCompliance } */ messagingComplianceData) => {

        const { profile , localMessaging, tollfreeMessaging } = messagingComplianceData;

        const messagingComplianceProfilePresentationObject = new MessagingComplianceProfilePresentationObject();
        if (profile) {
            _cleanDataResults(profile);
            messagingComplianceProfilePresentationObject.smsComplianceProfileId = profile.smsComplianceProfileId;
            if (profile.formOfBusiness) {
                messagingComplianceProfilePresentationObject.formOfBusiness(profile.formOfBusiness.toString());
            }
            messagingComplianceProfilePresentationObject.accountHolderFirstName(profile.accountHolderFirstName);
            messagingComplianceProfilePresentationObject.accountHolderLastName(profile.accountHolderLastName);
            messagingComplianceProfilePresentationObject.brandName(profile.brandName);
            messagingComplianceProfilePresentationObject.businessName(profile.businessName);
            messagingComplianceProfilePresentationObject.einTaxId(profile.einTaxId);
            messagingComplianceProfilePresentationObject.registryId(profile.registryId);
            messagingComplianceProfilePresentationObject.businessNumber(profile.businessNumber);
            messagingComplianceProfilePresentationObject.stateProvinceOfIncorporation(profile.stateProvinceOfIncorporation);
            messagingComplianceProfilePresentationObject.addressLineOne(profile.addressLineOne);
            messagingComplianceProfilePresentationObject.addressLineTwo(profile.addressLineTwo);
            messagingComplianceProfilePresentationObject.city(profile.city);
            messagingComplianceProfilePresentationObject.stateProvince(profile.regionCode);
            messagingComplianceProfilePresentationObject.postalCode(profile.postalCode);
            messagingComplianceProfilePresentationObject.country(profile.countryCode.toLowerCase());
            messagingComplianceProfilePresentationObject.phoneNumber(profile.phoneNumber);
            if (profile.phoneNumberCountryCode) {
                messagingComplianceProfilePresentationObject.phoneNumberCountryAbbreviation(profile.phoneNumberCountryCode.toLowerCase());
            }
            messagingComplianceProfilePresentationObject.websiteUrl(profile.websiteUrl);
            if (profile.industry) {
                messagingComplianceProfilePresentationObject.industry(profile.industry.toString());
            }
            if (profile.exchange) {
                messagingComplianceProfilePresentationObject.exchange(profile.exchange.toString());
            }
            messagingComplianceProfilePresentationObject.stockSymbol(profile.stockSymbol);
            messagingComplianceProfilePresentationObject.pointOfContactFirstName(profile.pointOfContactFirstName);
            messagingComplianceProfilePresentationObject.pointOfContactLastName(profile.pointOfContactLastName);
            messagingComplianceProfilePresentationObject.pointOfContactJobTitle(profile.pointOfContactJobTitle);
            messagingComplianceProfilePresentationObject.pointOfContactEmailAddress(profile.pointOfContactEmailAddress);
            messagingComplianceProfilePresentationObject.pointOfContactPhoneNumber(profile.pointOfContactPhoneNumber);
            if (profile.pointOfContactPhoneNumberCountryCode) {
                messagingComplianceProfilePresentationObject.pointOfContactPhoneNumberCountryAbbreviation(profile.pointOfContactPhoneNumberCountryCode.toLowerCase());
            }
            messagingComplianceProfilePresentationObject.pointOfContactAddressLineOne(profile.pointOfContactAddressLineOne);
            messagingComplianceProfilePresentationObject.pointOfContactAddressLineTwo(profile.pointOfContactAddressLineTwo);
            messagingComplianceProfilePresentationObject.pointOfContactCity(profile.pointOfContactCity);
            messagingComplianceProfilePresentationObject.pointOfContactStateOrProvince(profile.pointOfContactStateOrProvince);
            messagingComplianceProfilePresentationObject.pointOfContactZipOrPostalCode(profile.pointOfContactZipOrPostalCode);
            messagingComplianceProfilePresentationObject.pointOfContactCountry(profile.pointOfContactCountry);
        }

        const messagingComplianceLocalPresentationObject = new MessagingComplianceLocalPresentationObject();
        if (localMessaging) {
            _cleanDataResults(localMessaging);
            messagingComplianceLocalPresentationObject.smsComplianceLocalId = localMessaging.smsComplianceLocalId;
            messagingComplianceLocalPresentationObject.subUseCases(localMessaging.subUseCases);
            messagingComplianceLocalPresentationObject.summaryOfTexting(localMessaging.summaryOfTexting);
            messagingComplianceLocalPresentationObject.sampleTextMessageOne(localMessaging.sampleTextMessageOne);
            messagingComplianceLocalPresentationObject.sampleTextMessageTwo(localMessaging.sampleTextMessageTwo);
            messagingComplianceLocalPresentationObject.sampleTextMessageThree(localMessaging.sampleTextMessageThree);
            messagingComplianceLocalPresentationObject.sampleTextMessageFour(localMessaging.sampleTextMessageFour);
            messagingComplianceLocalPresentationObject.isCustomerConsentEmployeeRelationship(localMessaging.isCustomerConsentEmployeeRelationship);
            messagingComplianceLocalPresentationObject.isCustomerConsentInitiateConversation(localMessaging.isCustomerConsentInitiateConversation);
            messagingComplianceLocalPresentationObject.isCustomerConsentLeadCaptureForms(localMessaging.isCustomerConsentLeadCaptureForms);
            messagingComplianceLocalPresentationObject.isCustomerConsentOnlineCheckout(localMessaging.isCustomerConsentOnlineCheckout);
            messagingComplianceLocalPresentationObject.isCustomerConsentOther(localMessaging.isCustomerConsentOther);
            messagingComplianceLocalPresentationObject.customerConsentOther(localMessaging.customerConsentOther);
            messagingComplianceLocalPresentationObject.isIneligibleUseCaseAcknowledged(localMessaging.isIneligibleUseCaseAcknowledged);
            messagingComplianceLocalPresentationObject.isLocalSmsFormEnabled(localMessaging.isEnabled);
            messagingComplianceLocalPresentationObject.mobilePhoneNumber(localMessaging.mobilePhoneNumber);
            if (localMessaging.mobilePhoneNumberCountryCode) {
                messagingComplianceLocalPresentationObject.mobilePhoneNumberCountryAbbreviation(localMessaging.mobilePhoneNumberCountryCode.toString().toLowerCase());
            }
        }

        const messagingComplianceTollfreePresentationObject = new MessagingComplianceTollfreePresentationObject();
        if (tollfreeMessaging) {
            _cleanDataResults(tollfreeMessaging);
            messagingComplianceTollfreePresentationObject.smsComplianceTollfreeId = tollfreeMessaging.smsComplianceTollfreeId;
            if (tollfreeMessaging.messagesPerDay) {
                messagingComplianceTollfreePresentationObject.messagesPerDay(tollfreeMessaging.messagesPerDay.toString());
            }
            messagingComplianceTollfreePresentationObject.useCase(tollfreeMessaging.useCase);
            messagingComplianceTollfreePresentationObject.summaryOfTexting(tollfreeMessaging.summaryOfTexting);
            messagingComplianceTollfreePresentationObject.sampleTextMessageOne(tollfreeMessaging.sampleTextMessageOne);
            messagingComplianceTollfreePresentationObject.sampleTextMessageTwo(tollfreeMessaging.sampleTextMessageTwo);
            messagingComplianceTollfreePresentationObject.sampleTextMessageThree(tollfreeMessaging.sampleTextMessageThree);
            messagingComplianceTollfreePresentationObject.sampleTextMessageFour(tollfreeMessaging.sampleTextMessageFour);
            messagingComplianceTollfreePresentationObject.isCustomerConsentEmployeeRelationship(tollfreeMessaging.isCustomerConsentEmployeeRelationship);
            messagingComplianceTollfreePresentationObject.isCustomerConsentInitiateConversation(tollfreeMessaging.isCustomerConsentInitiateConversation);
            messagingComplianceTollfreePresentationObject.isCustomerConsentLeadCaptureForms(tollfreeMessaging.isCustomerConsentLeadCaptureForms);
            messagingComplianceTollfreePresentationObject.isCustomerConsentOnlineCheckout(tollfreeMessaging.isCustomerConsentOnlineCheckout);
            messagingComplianceTollfreePresentationObject.isCustomerConsentOther(tollfreeMessaging.isCustomerConsentOther);
            messagingComplianceTollfreePresentationObject.customerConsentOther(tollfreeMessaging.customerConsentOther);
            messagingComplianceTollfreePresentationObject.isIneligibleUseCaseAcknowledged(tollfreeMessaging.isIneligibleUseCaseAcknowledged);
            messagingComplianceTollfreePresentationObject.isTollfreeSmsFormEnabled(tollfreeMessaging.isEnabled);
            if (tollfreeMessaging.majorityOfMessagesSentToCanada) {
                messagingComplianceTollfreePresentationObject.majorityOfMessagesSentToCanada("Yes");
            } else {
                messagingComplianceTollfreePresentationObject.majorityOfMessagesSentToCanada("No");
            }
        }

        const messagingComplianceAutoResponsePresentationObject = new MessagingComplianceAutoResponsePresentationObject();
        if (localMessaging) {
            messagingComplianceAutoResponsePresentationObject.helpAutoResponse(localMessaging.helpAutoResponse);
            messagingComplianceAutoResponsePresentationObject.stopAutoResponse(localMessaging.stopAutoResponse);
        }

        const messagingCompliancePresentationObject = new MessagingCompliancePresentationObject();
        messagingCompliancePresentationObject.profile(messagingComplianceProfilePresentationObject);
        messagingCompliancePresentationObject.localMessaging(messagingComplianceLocalPresentationObject);
        messagingCompliancePresentationObject.tollfreeMessaging(messagingComplianceTollfreePresentationObject);
        messagingCompliancePresentationObject.autoResponse(messagingComplianceAutoResponsePresentationObject);

        return messagingCompliancePresentationObject;
    };

    /** @type { MessagingComplianceFacade["getMessagingCompliance"] } */
    const _getMessagingCompliance = () => {
        return _promiseFactory.defer((promise) => {

            /** @type { MessagingCompliancePresentationObject }*/
            let messagingCompliancePresentationObject;

            /** @type { IGetAccountMessagingStatusResponse } */
            let messagingStatus;

            let getMessagingCompliancePromiseFactory = new PromiseFactory();
            getMessagingCompliancePromiseFactory.defer((messagingStatusPromise) => {
                _messagingComplianceRepository.getAccountMessagingStatus()
                    .fail(messagingStatusPromise.reject)
                    .done(((/** @type { IGetAccountMessagingStatusResponse } */ response) => {
                        messagingStatus = response;
                        messagingStatusPromise.resolve();
                    }));
            });
            getMessagingCompliancePromiseFactory.defer((messagingCompliancePromise) => {
                _messagingComplianceRepository.getMessagingCompliance()
                .fail(messagingCompliancePromise.reject)
                .done(((/** @type { IMessagingCompliance } */ response) => {
                    messagingCompliancePresentationObject = _buildMessagingCompliancePresentationObject(response);
                    messagingCompliancePromise.resolve();
                }));
            });

            getMessagingCompliancePromiseFactory.wait()
                .fail(promise.reject)
                .done(() => {
                    messagingCompliancePresentationObject.localMessaging().hasLocalNumberWithMessagingEnabled(messagingStatus.accountHasLocalNumberWithSmsEnabled);
                    messagingCompliancePresentationObject.tollfreeMessaging().hasTollFreeNumberWithMessagingEnabled(messagingStatus.accountHasTollFreeNumberWithSmsEnabled);
                    messagingCompliancePresentationObject.accountHasCompletedLocalRegistration(messagingStatus.accountHasCompletedLocalRegistration);
                    messagingCompliancePresentationObject.accountHasCompletedTollFreeRegistration(messagingStatus.accountHasCompletedTollFreeRegistration);
                    messagingCompliancePresentationObject.isAccountInFreeTrial(messagingStatus.accountIsInFreeTrial);
                    promise.resolve(messagingCompliancePresentationObject);
                });
        });
    };

    /** @type { MessagingComplianceFacade["saveMessagingCompliance"] } */
    const _saveMessagingCompliance = (messagingCompliance) => {
        return _promiseFactory.deferIndefinitely((promise) => {

            const profileData = messagingCompliance.profile();
            const localMessagingData = messagingCompliance.localMessaging();
            const tollfreeMessagingData = messagingCompliance.tollfreeMessaging();
            const autoResponseData = messagingCompliance.autoResponse();


            /** @type { IMessagingComplianceProfile } */
            const profile = {
                smsComplianceProfileId: profileData.smsComplianceProfileId,
                formOfBusiness: profileData.formOfBusiness() ? parseInt(profileData.formOfBusiness()) : null,
                accountHolderFirstName: profileData.accountHolderFirstName(),
                accountHolderLastName: profileData.accountHolderLastName(),
                brandName: profileData.brandName(),
                businessName: profileData.businessName(),
                einTaxId: profileData.einTaxId(),
                registryId: profileData.registryId(),
                businessNumber: profileData.businessNumber(),
                stateProvinceOfIncorporation: profileData.stateProvinceOfIncorporation(),
                addressLineOne: profileData.addressLineOne(),
                addressLineTwo: profileData.addressLineTwo(),
                city: profileData.city(),
                regionCode: profileData.stateProvince(),
                postalCode: _textConverter.removeAllWhiteSpaces(profileData.postalCode()),
                countryCode: profileData.country(),
                phoneNumber: profileData.phoneNumber(),
                phoneNumberCountryCode: profileData.phoneNumberCountryAbbreviation(),
                websiteUrl: profileData.websiteUrl(),
                industry: profileData.industry() ? parseInt(profileData.industry()) : null,
                exchange: profileData.exchange() ? parseInt(profileData.exchange()) : null,
                stockSymbol: profileData.stockSymbol(),
                pointOfContactFirstName: profileData.pointOfContactFirstName(),
                pointOfContactLastName: profileData.pointOfContactLastName(),
                pointOfContactJobTitle: profileData.pointOfContactJobTitle(),
                pointOfContactEmailAddress: profileData.pointOfContactEmailAddress(),
                pointOfContactPhoneNumber: profileData.pointOfContactPhoneNumber(),
                pointOfContactPhoneNumberCountryCode: profileData.pointOfContactPhoneNumberCountryAbbreviation(),
                pointOfContactAddressLineOne: profileData.pointOfContactAddressLineOne(),
                pointOfContactAddressLineTwo: profileData.pointOfContactAddressLineTwo(),
                pointOfContactCity: profileData.pointOfContactCity(),
                pointOfContactStateOrProvince: profileData.pointOfContactStateOrProvince(),
                pointOfContactZipOrPostalCode: profileData.pointOfContactZipOrPostalCode(),
                pointOfContactCountry: profileData.pointOfContactCountry()
            };

            /** @type { IMessagingComplianceLocal } */
            const localMessaging = {
                smsComplianceLocalId: localMessagingData.smsComplianceLocalId,
                useCase: localMessagingData.useCase(),
                subUseCases: localMessagingData.subUseCases(),
                summaryOfTexting: localMessagingData.summaryOfTexting(),
                sampleTextMessageOne: localMessagingData.sampleTextMessageOne(),
                sampleTextMessageTwo: localMessagingData.sampleTextMessageTwo(),
                sampleTextMessageThree: localMessagingData.sampleTextMessageThree(),
                sampleTextMessageFour: localMessagingData.sampleTextMessageFour(),
                isCustomerConsentEmployeeRelationship: localMessagingData.isCustomerConsentEmployeeRelationship(),
                isCustomerConsentInitiateConversation: localMessagingData.isCustomerConsentInitiateConversation(),
                isCustomerConsentLeadCaptureForms: localMessagingData.isCustomerConsentLeadCaptureForms(),
                isCustomerConsentOnlineCheckout: localMessagingData.isCustomerConsentOnlineCheckout(),
                isCustomerConsentOther: localMessagingData.isCustomerConsentOther(),
                isCustomerConsentPosTransaction: localMessagingData.isCustomerConsentPosTransaction(),
                customerConsentOther: localMessagingData.customerConsentOther(),
                isIneligibleUseCaseAcknowledged: localMessagingData.isIneligibleUseCaseAcknowledged(),
                helpAutoResponse: autoResponseData.helpAutoResponse(),
                stopAutoResponse: autoResponseData.stopAutoResponse(),
                mobilePhoneNumber: localMessagingData.mobilePhoneNumber(),
                mobilePhoneNumberCountryCode: localMessagingData.mobilePhoneNumberCountryAbbreviation(),
                isEnabled: localMessagingData.isLocalSmsFormEnabled()
            };

            /** @type { IMessagingComplianceTollfree } */
            const tollfreeMessaging = {
                smsComplianceTollfreeId: tollfreeMessagingData.smsComplianceTollfreeId,
                majorityOfMessagesSentToCanada: tollfreeMessagingData.majorityOfMessagesSentToCanada() === "Yes",
                messagesPerDay: tollfreeMessagingData.messagesPerDay() ? parseInt(tollfreeMessagingData.messagesPerDay()) : null,
                useCase: tollfreeMessagingData.useCase(),
                summaryOfTexting: tollfreeMessagingData.summaryOfTexting(),
                sampleTextMessageOne: tollfreeMessagingData.sampleTextMessageOne(),
                sampleTextMessageTwo: tollfreeMessagingData.sampleTextMessageTwo(),
                sampleTextMessageThree: tollfreeMessagingData.sampleTextMessageThree(),
                sampleTextMessageFour: tollfreeMessagingData.sampleTextMessageFour(),
                isCustomerConsentEmployeeRelationship: tollfreeMessagingData.isCustomerConsentEmployeeRelationship(),
                isCustomerConsentInitiateConversation: tollfreeMessagingData.isCustomerConsentInitiateConversation(),
                isCustomerConsentLeadCaptureForms: tollfreeMessagingData.isCustomerConsentLeadCaptureForms(),
                isCustomerConsentOnlineCheckout: tollfreeMessagingData.isCustomerConsentOnlineCheckout(),
                isCustomerConsentOther: tollfreeMessagingData.isCustomerConsentOther(),
                isCustomerConsentPosTransaction: tollfreeMessagingData.isCustomerConsentPosTransaction(),
                customerConsentOther: tollfreeMessagingData.customerConsentOther(),
                isIneligibleUseCaseAcknowledged: tollfreeMessagingData.isIneligibleUseCaseAcknowledged(),
                isEnabled: tollfreeMessagingData.isTollfreeSmsFormEnabled()
            };

            /** @type { IUpdateMessagingComplianceRequest } */
            const updateRequest = {
                profile,
                localMessaging,
                tollfreeMessaging
            };

            _messagingComplianceRepository.updateMessagingCompliance(updateRequest)
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    /** @type { MessagingComplianceFacade["getAccountBrandStatus"] } */
    const _getAccountBrandStatus = () => {
        return _promiseFactory.defer((promise) => {
            _messagingComplianceRepository.getAccountBrandStatus()
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    /** @type { MessagingComplianceFacade["getAccountMessagingStatus"] } */
    const _getAccountMessagingStatus = () => {
        return _promiseFactory.defer((promise) => {
            _messagingComplianceRepository.getAccountMessagingStatus()
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    /** @type { MessagingComplianceFacade["tryCreateSoleProprietorBrand"] } */
    const _tryCreateSoleProprietorBrand = () => {
        return _promiseFactory.defer((promise) => {
            _messagingComplianceRepository.registerSoleProprietorBrand()
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    /** @type { MessagingComplianceFacade["resubmitBrand"] } */
    const _resubmitBrand = () => {
        return _promiseFactory.defer((promise) => {
            _messagingComplianceRepository.resubmitBrandForAccount()
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    /** @type { MessagingComplianceFacade["requestSmsPin"] } */
    const _requestSmsPin = () => {
        return _promiseFactory.defer((promise) => {
            _messagingComplianceRepository.requestSmsPin()
                .fail(promise.reject)
                .done(promise.resolve);
        });
    };

    /** @type { MessagingComplianceFacade["onBrandStatusUpdated"] } */
    const _onBrandStatusUpdated = (callback) => {
        _messagingComplianceRepository.onBrandStatusUpdated((event) => {
            callback(event);
        });
    };

    const _dispose = () => {
        _messagingComplianceRepository.dispose();
    };

    const _init = () => {

    };

    return function() {
        /** @typeof { MessagingComplianceFacade } */
        let self = this;

        self.init = _init;

        self.dispose = _dispose;

        self.getAccountBrandStatus = _getAccountBrandStatus;
        self.getAccountMessagingStatus = _getAccountMessagingStatus;
        self.getMessagingCompliance = _getMessagingCompliance;
        self.requestSmsPin = _requestSmsPin;
        self.resubmitBrand = _resubmitBrand;
        self.saveMessagingCompliance = _saveMessagingCompliance;
        self.tryCreateSoleProprietorBrand = _tryCreateSoleProprietorBrand;

        self.onBrandStatusUpdated = _onBrandStatusUpdated;
    };
});
