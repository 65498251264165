define('businessServices/router/authorizationPlugins/signupForCustomerAuthorizationPlugin',[
    'businessServices/authentication/signupForCustomerManager',
    'common/promises/promiseFactory'
], function() {
    function performImpersonation(impersonationNonceId, affiliateId, patliveAccountNumber) {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.defer(function(promise) {
            var signupForCustomerManager = require('businessServices/authentication/signupForCustomerManager');
            signupForCustomerManager.signupForCustomerWithImpersonationNonce(impersonationNonceId, affiliateId, patliveAccountNumber)
                .done(function(result) {
                    if (result.isSuccess) {
                        promise.resolve({status : "success"});
                    } else {
                        promise.resolve({status : "failed"});
                    }
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    function signupForCustomerAuthorization(fragment) {
        if (fragment.startsWith("signupForCustomer/") === false) {
            return { status : "ignored" };
        }

        let [url, impersonationNonce, affiliateId, patliveAccountNumber] = fragment.split('/');

        if (patliveAccountNumber === undefined) {
            if (/[0-9]/.test(affiliateId)) {
                patliveAccountNumber = affiliateId;
                affiliateId = null;
            }
        }
        return performImpersonation(impersonationNonce, affiliateId, patliveAccountNumber);
    }

    return {
        configureAuthorization : signupForCustomerAuthorization
    };
});
