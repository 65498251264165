define('presentation/common/routeToModal/strategies/scheduleSegmentStrategy',[
    'presentation/common/routeToModal/common/routingRuleBuilder'
], function () {

    return function () {
        const RoutingRuleBuilderConstructor = require('presentation/common/routeToModal/common/routingRuleBuilder');
        const _routingRuleBuilder = new RoutingRuleBuilderConstructor();

        const self = this;

        self.scheduleSegmentId = null;
        self.presentationObject = null;
        self.buildRoutingRule = _routingRuleBuilder.buildRoutingRule;
    };
});
