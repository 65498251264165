define('entities/userInviteEntity',[],function() {
    return function(){
        var self = this;
        self.emailAddress = null;
        self.extension = null;
        self.billingAdmin = null;
        self.systemAdmin = null;
    };
});

