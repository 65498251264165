define('presentation/contacts/viewModels/contactEmailViewModel',[
    'common/collections/collectionSorter',
    'common/promises/promiseFactory'
], function(
    /** @type typeof import('common/collections/collectionSorter') */
    SorterConstructor,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    return function() {
        const self = this;

        const _promiseFactory = new PromiseFactory();
        const _sorter = new SorterConstructor();

        self.emailAddresses = ko.observableArray([]);

        self.activate = (/** @type { IContactEmailViewModelActivationObject } */ activationObject) => {
            self.emailAddresses = activationObject.emailAddresses;
            _sorter.sort(self.emailAddresses, "emailAddress");

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
