define('presentation/settings/userDevices/facades/userDevicesFacade',['constants/userDeviceConstants',
        'persistence/webSocket/webSocketApp',
        'presentation/common/dateTimeValue',
        'presentation/settings/userDevices/presentationObjects/userDevicePresentationObject',
], function() {
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const UserDevicePresentationObjectConstructor = require('presentation/settings/userDevices/presentationObjects/userDevicePresentationObject');

    const _userDeviceConstants = require('constants/userDeviceConstants');

    let _promiseFactory = null;
    let _webSocketApp = null;

    const _buildUserDevices = (results) => {
        return results.map((result) => {
            const userDevice = new UserDevicePresentationObjectConstructor();
            userDevice.deviceId = result.deviceId;
            userDevice.deviceName = result.deviceName;
            userDevice.devicePhoneNumber = result.devicePhoneNumber;
            userDevice.deviceCountryAbbreviation = result.deviceCountry;
            userDevice.selectedCallerId = result.callerId;
            userDevice.physicalDeviceId(result.physicalDeviceId);

            if (_userDeviceConstants.mobile_app_installation_type[result.devicePlatform]) {
                userDevice.mobileAppInstallationType = _userDeviceConstants.mobile_app_installation_type[result.devicePlatform];
            } else {
                userDevice.mobileAppInstallationType = _userDeviceConstants.mobile_app_installation_type.none;
            }

            userDevice.devicePlatform(result.devicePlatform);
            userDevice.createdDateTime = result.createdDateTime;
            userDevice.createdDateTimeFilter = new DateTimeValueConstructor(result.createdDateTime);
            userDevice.modifiedDateTime = result.modifiedDateTime;
            userDevice.modifiedDateTimeFilter = new DateTimeValueConstructor(result.modifiedDateTime);

            return userDevice;
        });
    };

    const _getDevices = (userId) => {
        const parameters = {
            userId: userId
        };
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("get_user_devices", parameters, (results) => {
                promise.resolve(_buildUserDevices(results));
            });
        });
    };

    const _deleteDevice = (deviceId, userId) => {
        const parameters = {
            deviceId: deviceId,
            userId: userId
        };
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("delete_user_device", parameters, (result) => {
                promise.resolve(result);
            });
        });
    };

    const _saveDevices = (devices, userId) => {
        const parameters = {
            userId: userId,
            devices: devices.map((device) => {
                return {
                    callerId: device.selectedCallerId(),
                    deviceId: device.deviceId(),
                    deviceName: device.deviceName(),
                    devicePhoneNumber: device.devicePhoneNumber(),
                    deviceCountry: device.deviceCountryAbbreviation()
                };
            })
        };
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("save_user_devices", parameters, (result) => {
                promise.resolve(result);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_devices");
    };

    return function() {
        const self = this;

        self.init = _init;
        self.buildUserDevices = _buildUserDevices;
        self.deleteDevice = _deleteDevice;
        self.getDevices = _getDevices;
        self.saveDevices = _saveDevices;
    };
});

