define('presentation/common/listBox/bindings/listBoxBinding',[
    'overlay-scrollbars',
], function() {
    const _pxToRem = (pxValue) => {
        return pxValue * 1.0 / 16;
    };

    const _isOptionOutOfFrame = (rootElement, optionId) => {
        const rootBoundary = rootElement.getBoundingClientRect();
        const optionBoundary = document.getElementById(optionId).getBoundingClientRect();

        return (rootBoundary.top > optionBoundary.top || rootBoundary.bottom < optionBoundary.bottom);
    };

    const _resizeContainer = (rootElement, maxHeight, minHeight) => {
        maxHeight = maxHeight || Number.MAX_SAFE_INTEGER;
        minHeight = minHeight || 0;

        const options = rootElement.querySelectorAll(".list-box__option");
        if (options.length === 0) {
            rootElement.style.height = "0";
            return;
        }

        const firstOptionBoundingRect = options[0].getBoundingClientRect();
        const lastOptionBoundingRect = options[options.length - 1].getBoundingClientRect();
        const bottomMargin = 25;
        const visibleHeight = window.innerHeight - firstOptionBoundingRect.top - bottomMargin;

        const optionHeight = firstOptionBoundingRect.bottom - firstOptionBoundingRect.top;
        const contentHeight = lastOptionBoundingRect.bottom - firstOptionBoundingRect.top;

        const clampedMinHeight = Math.max(minHeight, visibleHeight, optionHeight);
        const clampedMaxHeight = Math.min(maxHeight, contentHeight);
        const remHeight = _pxToRem(Math.min(clampedMinHeight, clampedMaxHeight));

        rootElement.style.height = `${remHeight}rem`;
    };

    return {
        init: function (element, valueAccessor) {
            const _options = ko.unwrap(valueAccessor());
            const _focusedOptionId = _options.focusedOptionId;
            const _selectedOptionId = _options.selectedOptionId;
            const _shouldScrollIntoView = _options.shouldScrollIntoView;
            const _maxHeight = _options.maxHeight;
            const _minHeight = _options.minHeight;
            const _shrinkToFitContent = _options.shrinkToFitContent;
            const _onMouseMoveCallback = _options.onMouseMoveCallback;

            let disposables = [];
            let scrollbarInstance = null;
            let isInitialized = false;

            const _scrollIntoView = (optionId) => {
                if (scrollbarInstance && isInitialized) {
                    scrollbarInstance.scroll({
                        el: $('#' + optionId), block: {y: 'nearest'}
                    }, 0);
                }
            };

            const _onShouldScrollIntoViewChange = (shouldScrollIntoView) => {
                const focusedOptionId = _focusedOptionId();

                if (!focusedOptionId) {
                    return;
                }

                const isOptionOutOfFrame = _isOptionOutOfFrame(element, focusedOptionId);
                if (shouldScrollIntoView && isOptionOutOfFrame) {
                    _scrollIntoView(focusedOptionId);
                }
            };

            const _onResize = () => {
                _resizeContainer(element, _maxHeight, _minHeight);
            };

            const _onMutation = (mutationsList) => {
                if (mutationsList.some(mutation => mutation.type === 'childList')) {
                    if (_shrinkToFitContent) {
                        _resizeContainer(element, _maxHeight, _minHeight);
                    }

                    const isSelectedOptionInDOM = document.getElementById(_selectedOptionId()) !== null;

                    if (isSelectedOptionInDOM) {
                        const isSelectedOptionOutOfFrame = _isOptionOutOfFrame(element, _selectedOptionId());

                        if (isSelectedOptionOutOfFrame) {
                            _scrollIntoView(_selectedOptionId());
                        }
                    }
                }
            };

            scrollbarInstance = $(element).overlayScrollbars({
                className: "os-theme-tresta",
                overflowBehavior : {
                    x : 'hidden',
                    y : 'scroll'
                },
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 100
                },
                sizeAutoCapable: false,
                callbacks: {
                    onInitialized: () => {
                        isInitialized = true;
                        _scrollIntoView(_focusedOptionId());
                    }
                }
            }).overlayScrollbars();

            const config = { childList: true, subtree: true };
            const observer = new MutationObserver(_onMutation);
            observer.observe(element, config);

            disposables.push(_shouldScrollIntoView.subscribe(_onShouldScrollIntoViewChange));

            window.addEventListener('mousemove', _onMouseMoveCallback);

            if (_shrinkToFitContent) {
                window.addEventListener('resize', _onResize);
            }

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (scrollbarInstance && isInitialized) {
                    scrollbarInstance.destroy();
                }

                observer.disconnect();
                disposables.forEach(disposable => disposable.dispose());
                window.removeEventListener('mousemove', _onMouseMoveCallback);
                window.removeEventListener('resize', _onResize);
            });
        }
    };
});
