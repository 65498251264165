define('common/converters/phoneNumberFormatConverter',[],
function () {
    var _convert = function (phoneNumber) {
        if (phoneNumber === null || phoneNumber === undefined) {
            return "";
        }

        var onlyContainsDigits = isFinite(phoneNumber);

        if (phoneNumber.length === 10 && onlyContainsDigits) {
            return phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3, 6) + "-" + phoneNumber.slice(6, 10);
        } else if (phoneNumber.length === 11 && phoneNumber[0] === '1' && onlyContainsDigits) {
            return phoneNumber.slice(1, 4) + "-" + phoneNumber.slice(4, 7) + "-" + phoneNumber.slice(7, 11);
        } else {
            return phoneNumber;
        }
    };

    var _getRaw = function(phoneNumber) {
        if (phoneNumber === null || phoneNumber === undefined) {
            return "";
        }

        if (phoneNumber.length === 12 && phoneNumber[0] === "+" && phoneNumber[1] === "1") {
            return phoneNumber.slice(2, 12);
        } else {
            return phoneNumber;
        }
    };

    var _clean = function (phoneNumber) {
        return phoneNumber.trim().replace(/[^a-zA-Z0-9]/g,'');


    };

    return function() {
        var self = this;
        self.convert = _convert;
        self.getRaw = _getRaw;
        self.clean = _clean;
    };
});

