define('constants/scrollEventSources',[], function() {
    return {
        analytics: "analytics",
        inboxRecordings: "inboxRecordings",
        inboxVoicemails: "inboxVoicemails",
        settingsAudioFiles: "settingsAudioFiles",
        settingsAutoAttendants: "settingsAutoAttendants",
        settingsForwardingNumbers:"settingsForwardingNumbers",
        settingsPhoneNumbers: "settingsPhoneNumbers",
        settingsExtensions: "settingsExtensions"
    };
});
