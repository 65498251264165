define('presentation/common/textBox/viewModels/textBox',[],function () {
    return function () {
        const self = this;

        let _disposables = [];
        let _settings = null;

        const BULLET = 8226;
        const PASSWORD_CHARACTER = String.fromCharCode(BULLET);
        const PASSWORD_MIN_LENGTH = 8;

        const CREDIT_CARD_INPUT_MASK = {
            mask: '(3999 999999 99999|4999 9999 9999 9999|5999 9999 9999 9999|6999 9999 9999 9999)'
        };

        const POSTAL_INPUT_MASK = {
            mask: 'A9A 9A9'
        };

        const ZIPCODE_INPUT_MASK = {
            mask: '99999'
        };

        const MONTH_YEAR_INPUT_MASK = {
            mask: '99 / 9999'
        };

        const BIRTHDAY_INPUT_MASK = {
            mask: '99/99/9999'
        };

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        const _initialize = () => {
            if (_settings.autoFocus !== undefined) {
                if (ko.isWriteableObservable(_settings.autoFocus) === true) {
                    self.autoFocus = _settings.autoFocus;
                } else {
                    self.autoFocus(_settings.autoFocus);
                }
            }

            self.hasFocus = ko.computed(() => {
                return self.autoFocus();
            });

            if (_settings.maxLength !== undefined) {
                if (ko.isWriteableObservable(_settings.maxLength) === true) {
                    self.maxLength = _settings.maxLength;
                } else {
                    self.maxLength(_settings.maxLength);
                }
            }

            if (_settings.autocompleteEvaluation) {
                self.autocompleteEvaluation(_settings.autocompleteEvaluation);
            }

            if (_settings.autocompleteForceValues) {
                self.autocompleteForceValues(_settings.autocompleteForceValues);
            }

            if (_settings.placeholder !== undefined) {
                if (ko.isWriteableObservable(_settings.placeholder) === true) {
                    self.placeholder = _settings.placeholder;
                } else {
                    self.placeholder(_settings.placeholder);
                }
            }

            if (_settings.enabled !== undefined) {
                self.enabled = _settings.enabled;
            }

            switch (_settings.type) {
                case "creditcard":
                    self.type = 'text';
                    self.inputMask = CREDIT_CARD_INPUT_MASK;
                    break;
                case "editPassword":
                    self.type = "password";
                    self.placeholder = new Array(PASSWORD_MIN_LENGTH + 1).join(PASSWORD_CHARACTER);
                    break;
                case "password":
                    self.type = "password";
                    break;
                case "text":
                    self.type = "text";
                    break;
                case "email":
                    self.type = "email";
                    break;
                case "tel":
                    self.type = "tel";
                    break;
                case "monthYear":
                    self.type = 'text';
                    self.inputMask = MONTH_YEAR_INPUT_MASK;
                    break;
                case "postalcode":
                    self.type = 'text';
                    self.inputMask = POSTAL_INPUT_MASK;
                    break;
                case "zipcode":
                    self.type = 'text';
                    self.inputMask = ZIPCODE_INPUT_MASK;
                    break;
                case "birthday":
                    self.inputMask = BIRTHDAY_INPUT_MASK;
                    break;
            }

            self.value(_settings.value());
            _disposables.push(_settings.value.subscribe(_updateLocalValue, this, "change"));

            _settings.value.forceUpdate = ko.observable(false);
            _settings.value.isKeyUp = ko.observable(false);

            if (_settings.valueUpdate !== undefined) {
                self.valueUpdate = _settings.valueUpdate;
            }

            if (_settings.tooltipButton !== undefined) {
                self.tooltipButton(_settings.tooltipButton);
            }
            if (_settings.numericInput) {
                self.numericInput = _settings.numericInput;
            }
            const id = _settings.customId || _settings.id;
            if (id !== undefined) {
                self.customId = id;
            }

            if (_settings.hasOwnProperty('validateOnBlur')) {
                self.validateOnBlur = ko.unwrap(_settings.validateOnBlur);
            }
        };

        self.maxLength = ko.observable('200');
        self.autocompleteEvaluation = ko.observable('');
        self.autocompleteForceValues = ko.observable('');
        self.autoFocus = ko.observable(false);
        self.numericInput = false;
        self.placeholder = ko.observable('');
        self.tooltipButton = ko.observable('');
        self.type = 'text';
        self.value = ko.observable('');
        self.valueUpdate = null;
        self.enabled = ko.observable(true);
        self.customId = "";
        self.mask = '';
        self.inputMask = null;
        self.useInputMask = false;
        self.validateOnBlur = true;

        const _updateLocalValue = (newValue) => {
            if (_settings.value.forceUpdate()) {
                _settings.value.forceUpdate(false);
                self.value(newValue);
            }
        };

        self.keyUpEvent = () => {
            if (_settings.valueUpdate === "keyup") {
                _settings.value.isKeyUp(true);
                _settings.value(self.value());
            }
        };

        self.updateEvent = () => {
            _settings.value.isKeyUp(false);
            if (_settings.value() !== self.value()) {
                _settings.value(self.value());
            } else if (_settings.value.validate && self.validateOnBlur) {
                _settings.value.validate();
            }
        };
    };
});

