define('presentation/loginNotifications/facades/payBalanceFacade',[
    'businessServices/creditCards/creditCardTypes',
    'businessServices/specifications/validCreditCardSpecification',
    'common/collections/collectionSorter',
    'common/converters/passedTimeSpanFormatter',
    'common/promises/promiseFactory',
    'common/time/date',
    'constants/creditCardResponseConstants',
    'constants/creditCardResponseEnumerations',
    'entities/paymentMethodEntity',
    'persistence/dataProviders/paymentMethodDataProvider',
    'persistence/repositories/payBalanceRepository',
    'presentation/common/dateTimeValue',
    'presentation/loginNotifications/presentationObjects/declinedInformationPresentationObject',
    'presentation/loginNotifications/presentationObjects/paymentMethodPresentationObject'
], function () {

    const CollectionSorterConstructor = require('common/collections/collectionSorter');
    const CreditCardConstructor = require('businessServices/creditCards/creditCardTypes');
    const DateConstructor = require('common/time/date');
    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const DeclinedInformationPresentationObject = require('presentation/loginNotifications/presentationObjects/declinedInformationPresentationObject');
    const PaymentMethodPresentationObjectConstructor = require('presentation/loginNotifications/presentationObjects/paymentMethodPresentationObject');
    const PayBalanceRepositoryConstructor = require('persistence/repositories/payBalanceRepository');
    const PaymentMethodDataProvider = require('persistence/dataProviders/paymentMethodDataProvider');
    const PaymentMethodEntityConstructor = require('entities/paymentMethodEntity');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
    const ValidCreditCardSpecification = require('businessServices/specifications/validCreditCardSpecification');

    const _collectionSorter = new CollectionSorterConstructor();
    const _creditCard = new CreditCardConstructor();
    const _creditCardResponse = require('constants/creditCardResponseConstants');
    const _date = new DateConstructor();
    const _payBalanceRepository = new PayBalanceRepositoryConstructor();
    const _paymentMethodDataProvider = new PaymentMethodDataProvider();
    const _promiseFactory = new PromiseFactoryConstructor();
    const _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();
    const _validCreditCardSpecification = new ValidCreditCardSpecification();

    const _addPaymentMethodAndMakePayment = (paymentMethod, paymentAmount) => {
        return _promiseFactory.defer((deferredObject) => {
            const paymentMethodEntity = new PaymentMethodEntityConstructor();

            paymentMethodEntity.cardHolderName = paymentMethod.cardHolderName.trim();
            paymentMethodEntity.cardType = paymentMethod.cardType.trim();
            paymentMethodEntity.creditCardNumber = _creditCard.unformatCreditCard(paymentMethod.creditCardNumber);
            paymentMethodEntity.cvvSecurityCode = paymentMethod.cvvSecurityCode.trim();
            paymentMethodEntity.expirationDate = _date.getExpirationDate(paymentMethod.expirationYear, paymentMethod.expirationMonth);
            paymentMethodEntity.isDefault = false;
            paymentMethodEntity.country = paymentMethod.country;
            paymentMethodEntity.postalCode = paymentMethod.postalCode.trim();

            _payBalanceRepository.addPaymentMethodAndMakePayment(paymentMethodEntity, paymentAmount)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _makePayment = (paymentMethodId, paymentAmount) => {
        return _promiseFactory.defer((deferredObject) => {
            _payBalanceRepository.applyPayment(paymentMethodId, paymentAmount)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _getDeclinedInformation = () => {
        return _promiseFactory.defer((deferredObject) => {
            let declinedInformationPresentationObject = new DeclinedInformationPresentationObject();
            _paymentMethodDataProvider.getDeclinedInformation()
                .fail(deferredObject.reject)
                .done((declinedInformation) => {
                    if (declinedInformation.status === "declined_payment") {
                        const declinedDateFormatted = new DateTimeValueConstructor(declinedInformation.declinedDate).formattedDateString;
                        const declinedDate = new DateConstructor();
                        declinedDate.setFromDateString(declinedInformation.declinedDate);
                        const today = new DateConstructor();
                        today.setToday();
                        if (declinedDate.isOnSameDay(today)) {
                            declinedInformationPresentationObject.declinedDate = declinedDateFormatted;
                        } else {
                            declinedInformationPresentationObject.declinedDate = "on " + declinedDateFormatted;
                        }
                        declinedInformationPresentationObject.declinedAmount = declinedInformation.declinedAmount;
                        if (declinedInformation.autoCloseDate) {
                            const autoCloseDateFormatted = new DateConstructor();
                            autoCloseDateFormatted.setFromDateString(declinedInformation.autoCloseDate);
                            declinedInformationPresentationObject.autoCloseDate = _passedTimeSpanFormatter.formatDate(autoCloseDateFormatted);
                        }
                        declinedInformationPresentationObject.paymentMethodId = declinedInformation.paymentMethodId;
                        declinedInformationPresentationObject.pastDueBalance = declinedInformation.pastDueBalance;
                    } else if (declinedInformation.status === "past_due_balance") {
                        const todaysDate = new Date().toISOString();
                        declinedInformationPresentationObject.declinedDate = new DateTimeValueConstructor(todaysDate).formattedDateString;
                        declinedInformationPresentationObject.declinedAmount = "0.00";
                        declinedInformationPresentationObject.autoCloseDate = '';
                        declinedInformationPresentationObject.paymentMethodId = '';
                        declinedInformationPresentationObject.pastDueBalance = declinedInformation.pastDueBalance;
                    }

                    deferredObject.resolve(declinedInformationPresentationObject);
                });
        });
    };

    const _getAllPaymentMethods = () => {
        return _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getAll()
                .fail(deferredObject.reject)
                .done((paymentMethods) => {
                    const paymentMethodsFormatted = paymentMethods.map((currentPaymentMethod) => {
                        return _buildPaymentMethodPresentationObject(currentPaymentMethod);
                    });
                    _collectionSorter.sort(paymentMethodsFormatted, "description", true);
                    deferredObject.resolve(paymentMethodsFormatted);
                });
        });
    };

    const _buildPaymentMethodPresentationObject = (currentPaymentMethod) => {
        const paymentMethodPresentationObject = new PaymentMethodPresentationObjectConstructor();
        paymentMethodPresentationObject.paymentMethodId = currentPaymentMethod.paymentMethodId;
        paymentMethodPresentationObject.cardHolderName = currentPaymentMethod.cardHolderName;
        paymentMethodPresentationObject.cardType = currentPaymentMethod.cardType;
        paymentMethodPresentationObject.expirationDate = currentPaymentMethod.expirationDate;
        paymentMethodPresentationObject.isDefault(currentPaymentMethod.isDefault);
        paymentMethodPresentationObject.isExpired(_isPaymentMethodExpired(currentPaymentMethod.expirationDate));
        paymentMethodPresentationObject.country = currentPaymentMethod.country;
        paymentMethodPresentationObject.postalCode = currentPaymentMethod.postalCode;
        const creditCard = _creditCard.getCreditCardLongDisplay(currentPaymentMethod.cardType, currentPaymentMethod.lastFourDigits, currentPaymentMethod.expirationDate);
        paymentMethodPresentationObject.description = creditCard.longDisplay;
        return paymentMethodPresentationObject;
    };

    const _getAddPaymentMethodAllowed = () => {
        return _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getAddPaymentMethodAttempts()
                .fail(deferredObject.reject)
                .done((attemptsStatus) => {
                    deferredObject.resolve(attemptsStatus.status === _creditCardResponse.success);
                });
        });
    };

    const _isPaymentMethodExpired = (expirationDate) => {
        return _validCreditCardSpecification.isCreditCardExpired(expirationDate);
    };

    const _init = () => {
        _paymentMethodDataProvider.init();
        _payBalanceRepository.init();
    };

    return function () {
        let self = this;

        self.addPaymentMethodAndMakePayment = _addPaymentMethodAndMakePayment;
        self.buildPaymentMethodPresentationObject = _buildPaymentMethodPresentationObject;
        self.getAddPaymentMethodAllowed = _getAddPaymentMethodAllowed;
        self.getDeclinedInformation = _getDeclinedInformation;
        self.getAllPaymentMethods = _getAllPaymentMethods;
        self.makePayment = _makePayment;

        self.init = _init;
    };
});

