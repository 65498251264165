define('presentation/common/warningBox/viewModels/integrityWarningBoxViewModel',['constants/systemObjectIconNameConstants'], function () {
    return function () {
        const self = this;

        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _disposables = [];
        let _settings;

        const _formatIntegrityWarning = (integrityWarning) => {
            switch (integrityWarning.icon){
                case 'phone':
                case 'recording':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.hostedNumber,
                    });
                case 'attendant':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.autoAttendant,
                    });
                case 'forwarding':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.forwardingNumber,
                    });
                case 'schedule':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.schedule,
                    });
                case 'user-device':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.device,
                    });
                case 'user':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.user,
                    });
                case 'userGroup':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.userGroup,
                    });
                default:
                    return null;
            }
        };

        self.warning = ko.observable("");

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            if (_settings.warning) {
                let formattedWarning = _formatIntegrityWarning(ko.unwrap(_settings.warning));
                self.warning(formattedWarning);

                _disposables.push(self.warning.subscribe(_formatIntegrityWarning));
            }
        };
    };
});
