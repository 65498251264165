define('presentation/signup/facades/signupEmailFacade',[
        'businessServices/ipQualityScore/ipQualityScore',
        'businessServices/signup/affiliateSignup',
        'businessServices/signup/signupSessionStore',
        'common/promises/promiseFactory',
        'constants/numberPickerConstants',
        'persistence/webSocket/webSocketApp',
        'presentation/signup/presentationObjects/signupEmailPresentationObject'],
    function() {
        let _webSocketApp = null;
        let _signupSessionStore = null;
        let _ipQualityScore = null;
        let _affiliateSignup = null;

        let SignupEmailPresentationObjectConstructor = require('presentation/signup/presentationObjects/signupEmailPresentationObject');
        const _numberPickerConstants = require('constants/numberPickerConstants');

        const MINIMUM_TIME_TO_WAIT = 3000;
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _getEmailStatus = (emailAddress) => {
            return _promiseFactory.defer((promise) => {
                const webSocketParameters = {emailAddress: emailAddress};
                _webSocketApp.send("get_email_address_status", webSocketParameters, (result) => {
                    promise.resolve(result);
                });
            });
        };

        const _isFraudulentEmailAddress = (emailAddress) => _signupSessionStore.isFraudulentEmailAddress(emailAddress);

        const _saveEmailAddress = (emailAddress) => _signupSessionStore.saveEmailAddress(emailAddress);

        const _saveDeviceFingerPrint = () => {
            return _promiseFactory.defer((promise) => {
                _ipQualityScore.waitForInitComplete()
                    .done(() => {
                        const deviceFingerPrint = _ipQualityScore.getDeviceId();
                        if (deviceFingerPrint) {
                            _signupSessionStore.saveDeviceFingerPrint(deviceFingerPrint)
                                .fail(promise.reject)
                                .done(promise.resolve);
                        } else {
                            promise.resolve();
                        }
                    });
            });
        };

        const _saveResellerInfo = () => {
            return _promiseFactory.defer((promise) => {
                _affiliateSignup.get()
                    .done(({resellerId, clickId}) => {
                        if (resellerId || clickId) {
                            _signupSessionStore.saveResellerAndClickId(resellerId, clickId)
                                .fail(promise.reject)
                                .done(promise.resolve);
                        } else {
                            promise.resolve();
                        }
                    });
            });
        };

        const _isFraudulentDevice = () => {
            return _promiseFactory.defer((promise) => {
                _ipQualityScore.waitForInitComplete()
                    .done(() => {
                        const requestId = _ipQualityScore.getRequestId();
                        _signupSessionStore.checkIsFraudulentDeviceOrIp(requestId)
                            .fail(promise.reject)
                            .done(promise.resolve);
                    });
            });
        };

        const _sendEmailVerificationCode = () => _signupSessionStore.sendEmailVerificationCode();

        const _setEmailStepCompleted = (emailAddress, verificationCode) => _signupSessionStore.setEmailCompleted(emailAddress, verificationCode);

        const _loadSignupSession = () => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.get()
                    .fail(promise.reject)
                    .done((signupDocument) => {
                        let signupEmailPresentationObject = new SignupEmailPresentationObjectConstructor();
                        signupEmailPresentationObject.emailAddress = signupDocument.emailAddress;
                        signupEmailPresentationObject.isEmailStepCompleted = signupDocument.isEmailCompleted;
                        signupEmailPresentationObject.reservedPhoneNumber = signupDocument.reservedPhoneNumber;
                        signupEmailPresentationObject.emailVerificationCodeSendCount = signupDocument.emailVerificationCodeSendCount;
                        signupEmailPresentationObject.emailVerificationFailureCount = signupDocument.emailVerificationFailureCount;
                        promise.resolve(signupEmailPresentationObject);
                    });
            });
        };

        const waitForMinimumSecondsToPass = (asyncFunctionToExecute) => {
            return _promiseFactory.defer(promise => {
                const endTime = MINIMUM_TIME_TO_WAIT + new Date().getTime();

                const resolveAfterEndTime = result => {
                    const endTimeRemaining = endTime - new Date().getTime();
                    setTimeout(() => {
                        promise.resolve(result);
                    }, endTimeRemaining);
                };

                asyncFunctionToExecute
                    .fail(promise.fail)
                    .done(resolveAfterEndTime);
            });
        };

        const _reservePhoneNumber = ({phoneNumber, region = null, city = null, vanityString = null, numberType, searchType}) => {
            const reservePhoneNumber = _promiseFactory.deferIndefinitely((reserveNumberPromise) => {
                if (numberType === _numberPickerConstants.numberTypes.tollFree) {
                    _signupSessionStore.reservePhoneNumber({phoneNumber, searchType, vanityString})
                        .fail(reserveNumberPromise.reject)
                        .done(reserveNumberPromise.resolve);
                } else if (searchType === _numberPickerConstants.searchTypeEnumeration.default) {
                    _signupSessionStore.reservePhoneNumber({phoneNumber, region, city, searchType})
                        .fail(reserveNumberPromise.reject)
                        .done(reserveNumberPromise.resolve);
                } else {
                    _signupSessionStore.reserveLocalPhoneNumberWithoutLocation({phoneNumber, searchType, vanityString})
                        .fail(reserveNumberPromise.reject)
                        .done(reserveNumberPromise.resolve);
                }
            });
            return waitForMinimumSecondsToPass(reservePhoneNumber);
        };

        const _setPhoneNumberCompleted = (isPhoneNumberCompleted) => _signupSessionStore.setPhoneNumberCompleted(isPhoneNumberCompleted);

        const _init = () => {
            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();

            const WebSocketAppConstructor= require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("validation");

            _ipQualityScore = require('businessServices/ipQualityScore/ipQualityScore');

            const AffiliateSignupConstructor = require('businessServices/signup/affiliateSignup');
            _affiliateSignup = new AffiliateSignupConstructor();
            _affiliateSignup.init();

        };


        return function() {
            let self = this;

            self.init = _init;

            self.getEmailStatus = _getEmailStatus;
            self.isFraudulentEmailAddress = _isFraudulentEmailAddress;
            self.isFraudulentDevice = _isFraudulentDevice;
            self.saveDeviceFingerPrint = _saveDeviceFingerPrint;
            self.saveEmailAddress = _saveEmailAddress;
            self.saveResellerInfo = _saveResellerInfo;
            self.sendEmailVerificationCode = _sendEmailVerificationCode;
            self.loadSignupSession = _loadSignupSession;
            self.setEmailStepCompleted = _setEmailStepCompleted;
            self.reservePhoneNumber = _reservePhoneNumber;
            self.setPhoneNumberCompleted = _setPhoneNumberCompleted;
        };
});

