define('presentation/messages/viewModels/conversationHeaderViewModel',[
    'businessServices/contacts/contactsStateSingleton',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'i18next'
], function(
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type import('i18next') */
    i18n
) {
    return function(
        /** @type {() => void} */
        onInfoBubbleClickedCallback
    ) {
        const self = this;

        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _promiseFactory = new PromiseFactory();

        const _formatPhoneNumber = (/** @type {IWebMessagingNumber} */phoneNumber) => {
            const id = phoneNumber.accountHostedNumberId;
            const hostedPhoneNumberName = phoneNumber.hostedPhoneNumberName;
            const defaultPhoneNumberName = phoneNumber.hostedPhoneNumberDefaultName;
            const hostedPhoneNumber = phoneNumber.hostedPhoneNumber;
            const reachedLimit = phoneNumber.reachedLimit;

            const searchPhoneNumber = _phoneNumberFormatter.toNumbers(hostedPhoneNumber);
            const formattedLocation = _phoneNumberFormatter.formatLocation(phoneNumber.city, phoneNumber.state);
            const defaultDescription = _phoneNumberFormatter.isTollFree(hostedPhoneNumber) ? i18n.t('tollFree') : formattedLocation;

            // TODO - DEV-870: Can we delete this comparison?
            const description = hostedPhoneNumberName === defaultPhoneNumberName ? defaultDescription : hostedPhoneNumberName;
            const searchText = searchPhoneNumber + defaultPhoneNumberName + description;

            return {
                id,
                phoneNumber: defaultPhoneNumberName,
                description,
                searchText,
                reachedLimit,
            };
        };

        self.comingSoon = i18n.t("comingSoon");
        self.onDeleteDraft = null;
        self.onAddRecipient = null;
        /** @type {KnockoutObservableArray<IRecipient>} */
        self.recipients = ko.observableArray([]);
        self.conversationName = ko.observable('');
        self.sendFromHostedNumberId = ko.observable('');
        self.autoCompleteRecipients = ko.observableArray([]);
        /** @type {KnockoutReadonlyComputed<IWebMessagingNumber[]>} */
        self.sendFromHostedNumbers = null;
        self.isLoadingLocations = ko.observable(true);
        self.isNewConversation = ko.observable(true);
        self.showConversationDetails = ko.observable(false);
        self.sidebarContacts = _contactsState.sidebarContacts;

        self.headerSearchPlaceholder = i18n.t('search');
        self.headerSearchValue = ko.observable('');

        self.showClearSearchIcon = ko.pureComputed(() => {
            return self.headerSearchValue().length > 0;
        });

        self.displaySendFromHostedNumbers = ko.pureComputed(() => {
            return self.sendFromHostedNumbers().map(_formatPhoneNumber);
        });

        self.conversationTitle = ko.pureComputed(() => {
            if (self.conversationName()) {
                return self.conversationName();
            }

            return self.recipients().map((recipient) => {
                if (self.sidebarContacts()){
                    const contact = _contactsState.getContactByPhoneNumber(recipient.phoneNumber);
                    if (contact) {
                        return contact.displayName();
                    }
                }
                return _phoneNumberFormatter.toNumericDefault(recipient.phoneNumber);
            }).join(', ');
        });

        self.avatarRecipients = ko.pureComputed(() => {
            return self.recipients().map((recipient) => recipient.phoneNumber);
        });

        self.conversationMetaText = ``;

        self.onDeleteClick = () => {
            self.onDeleteDraft(true);
        };

        self.onClearSearchClicked = () => {
            self.headerSearchValue('');
        };

        self.onDetailsIconClicked = onInfoBubbleClickedCallback;

        self.activate = (/** @type {IConversationHeaderViewModelActivationObject} */activationObject) => {
            const {
                autoCompleteRecipients,
                conversationName,
                conversationMetaText,
                isNewConversation,
                onAddRecipient,
                onDeleteDraft,
                recipients,
                sendFromHostedNumbers,
                sendFromHostedNumberId,
                showConversationDetails
            } = activationObject;

            self.conversationMetaText = conversationMetaText;
            self.onDeleteDraft = onDeleteDraft;
            self.onAddRecipient = onAddRecipient;
            self.recipients = recipients;
            self.conversationName = conversationName;
            self.sendFromHostedNumberId = sendFromHostedNumberId;
            self.autoCompleteRecipients = autoCompleteRecipients;
            self.sendFromHostedNumbers = sendFromHostedNumbers;
            self.isNewConversation = isNewConversation;
            self.showConversationDetails = showConversationDetails;

            return _initialize();
        };

        const _initialize = () => {

            return _promiseFactory.wait();
        };
    };
});

