define('presentation/settings/forwardingNumbers/viewModels/addForwardingNumbersViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/country/countryCode',
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/settings/forwardingNumbers/facades/addForwardingNumbersFacade',
    'presentation/settings/forwardingNumbers/validators/addForwardingNumbersViewModelValidator',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const FacadeConstructor = require('presentation/settings/forwardingNumbers/facades/addForwardingNumbersFacade');
        const _facade = new FacadeConstructor();

        const ValidatorConstructor = require('presentation/settings/forwardingNumbers/validators/addForwardingNumbersViewModelValidator');
        const _validator = new ValidatorConstructor();

        const CountryCodesConstructor = require('businessServices/country/countryCode');
        const _countryCodesUtil = new CountryCodesConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');
        const _i18n = require('i18next');

        self.forwardingNumberName = ko.observable('').extend({observeState: true});
        self.isNewForwardingNumber = ko.observable(false);
        self.phoneNumber = ko.observable('').extend({observeState: true});
        self.countryAbbreviation = ko.observable('us').extend({observeState: true});
        self.forwardingNumberId = null;
        self.retryCount = 0;

        self.modelStateObserver = null;
        self.forwardingNumbersUrl = _navigationConfiguration.routesById.forwardingNumbers.url;

        self.availableCallerIds = [
            {id: "callerNumber", text: _i18n.t('addForwardingNumbers:callerNumber')},
            {id: "dialedNumber", text: _i18n.t('addForwardingNumbers:dialedNumber')}
        ];
        self.selectedCallerId = ko.observable('dialedNumber').extend({observeState: true});

        self.countryCode = ko.pureComputed(() => _countryCodesUtil.findByAbbreviation(self.countryAbbreviation()).countryCode);
        self.headerKey = ko.pureComputed(() => self.forwardingNumberId ? 'addForwardingNumbers:editHeader' : 'addForwardingNumbers:addHeader');
        self.saveButtonTitle = ko.pureComputed(() => self.forwardingNumberId ? _i18n.t('addForwardingNumbers:save') : _i18n.t('addForwardingNumbers:add'));

        self.cancelForm = () => {
            _router.navigate(self.forwardingNumbersUrl);
        };

        const _isFacadeStatusSuccess = (status) => {
            switch (status) {
                case 'restricted_phone_number':
                    self.phoneNumber.validationMessage(_i18n.t('addForwardingNumbers:numberRestricted'));
                    self.phoneNumber.isValid(false);
                    return false;
                case 'forwarding_number_name_already_in_use':
                    self.forwardingNumberName.validationMessage('addForwardingNumbers:nameUnique');
                    self.forwardingNumberName.isValid(false);
                    return false;
                case 'extension_in_use':
                    return false;
                default:
                    return true;
            }
        };

        self.validate = () => _validator.validate();

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            deferredObject.resolve(false);
                        } else {
                            if (self.forwardingNumberId) {
                                _facade.updateForwardingNumber(self.forwardingNumberId, self.forwardingNumberName(), self.countryAbbreviation(), self.countryCode(), self.phoneNumber(), self.selectedCallerId())
                                    .done(result => {
                                        if (_isFacadeStatusSuccess(result.status)) {
                                            self.modelStateObserver.saveData();
                                            deferredObject.resolve();
                                            _router.navigate(self.forwardingNumbersUrl);
                                        } else {
                                            deferredObject.resolve(false);
                                        }
                                    })
                                    .fail((error) => {
                                        error.forwardingNumberId = self.forwardingNumberId;
                                        error.forwardingNumberName = self.forwardingNumberName();
                                        error.countryAbbreviation = self.countryAbbreviation();
                                        error.countryCode = self.countryCode();
                                        error.phoneNumber = self.phoneNumber();
                                        deferredObject.reject(error);
                                    });
                            } else {
                                _facade.addForwardingNumber(self.forwardingNumberName(), self.countryAbbreviation(), self.countryCode(), self.phoneNumber(), self.selectedCallerId())
                                    .done(result => {
                                        if (_isFacadeStatusSuccess(result.status)) {
                                            self.modelStateObserver.saveData();
                                            deferredObject.resolve();
                                            _router.navigate(self.forwardingNumbersUrl);
                                        } else {
                                            deferredObject.resolve(false);
                                        }
                                    })
                                    .fail((error) => {
                                        error.forwardingNumberName = self.forwardingNumberName();
                                        error.countryAbbreviation = self.countryAbbreviation();
                                        error.countryCode = self.countryCode();
                                        error.phoneNumber = self.phoneNumber();
                                        deferredObject.reject(error);
                                    });
                            }
                        }
                    });
            });
        };

        self.compositionComplete = () => {
            if (self.forwardingNumberId === null) {
                self.isNewForwardingNumber(true);
            }
        };

        self.activate = (forwardingNumberId) => {
            _facade.init(_promiseFactory);

            if (forwardingNumberId) {
                self.forwardingNumberId = forwardingNumberId;
            }

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.defer((promise) => {
                const initDonePromiseFactory = new PromiseFactoryConstructor();
                initDonePromiseFactory.defer((initDeferredObject) => {
                    self.modelStateObserver = new ModelStateObserverConstructor(self, true);
                    if (self.forwardingNumberId) {

                        _facade.getForwardingNumber(self.forwardingNumberId)
                            .fail(initDeferredObject.reject)
                            .done((forwardingNumberResponse) => {
                                const forwardingNumber = forwardingNumberResponse.forwardingNumber;

                                self.forwardingNumberName(forwardingNumber.forwardingNumberName);
                                self.phoneNumber(forwardingNumber.phoneNumber);
                                self.countryAbbreviation(forwardingNumber.countryAbbreviation);
                                self.selectedCallerId(forwardingNumber.aniToDisplay);
                                initDeferredObject.resolve();
                            });
                    } else {
                        initDeferredObject.resolve();
                    }
                });
                initDonePromiseFactory.wait()
                    .fail(promise.reject)
                    .done(() => {
                        _validator.registerViewModel(self, _facade);
                        self.modelStateObserver.commitData();
                        promise.resolve();
                    });
            });
        };
    };
});

