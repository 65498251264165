define('presentation/contacts/viewModels/addContactUrlViewModel',[
    'businessServices/state/modelStateObserver'
], function (
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver
) {
    /** @typedef { import('presentation/contacts/viewModels/addContactViewModel') } AddContactViewModel */

    return function (/** @type AddContactViewModel */ parent) {

        let self = this;

        self.parent = parent;

        self.modelStateObserver = null;

        self.accountContactUrlId = '';
        self.url = ko.observable('').extend({observeState: true});

        self.onRemoveUrlClick = () => {
            self.parent.onRemoveUrlClick(self);
        };

        self.cancelForm = () => {
            if (self.url.isDirty) {
                self.url.forceUpdate(true);
            }

            self.modelStateObserver.restoreData();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserver(self, true);
            self.modelStateObserver.commitData();
        };

        _initialize();
    };
});
