define('presentation/settings/userProfile/presentationObjects/userProfilePresentationObject',[],function() {
    return function(){
        const self = this;

        self.accountNumber = "";
        self.avatar = null;
        self.firstName = "";
        self.lastName = "";
        self.emailAddress = "";
        self.timeZone = "";
        self.status =  "";
    };
});

