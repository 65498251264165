define('businessServices/login/forgotPasswordManager',[
    'common/promises/promiseFactory',
    'externalDependencies/kazooApi'
], function() {
    function forgotPassword(emailEntity) {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.defer(function(promise) {
            var emailDocument = {
                data : emailEntity
            };

            var KazooConstructor = require('externalDependencies/kazooApi');
            var kazoo = new KazooConstructor();
            kazoo.init();
            kazoo.unauthenticatedCall('lobby_user_auth/forgotPassword', 'PUT', emailDocument)
                .done(function(result) {
                    promise.resolve(result);
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    return {
        forgotPassword : forgotPassword
    };
});

