/**
 * @module date
 */
define('common/time/date',[
    'common/calendar/monthList'
], function() {
    var ANTE_MERIDIEM = "AM";
    var POST_MERIDIEM = "PM";

    const MonthConstructor = require('common/calendar/monthList');
    const _months = new MonthConstructor().monthList;

    return function() {
        var self = this;

        var _date = new Date(0);

        var _setFromDate = function(date) {
            _date = date;
        };

        var _setFromDateString = function(dateString) {
            var fromISODate = new Date(dateString);
            _date = fromISODate;
        };

        var _setToday = function() {
            var now = new Date();
            var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            _date = today;
        };

        var _getExpirationDate = function(year, month) {
            var date = new Date(year, month, 0);
            date.setUTCHours(0);
            return date.toISOString();
        };

        var _formatExpirationDate = function (dateISOString) {
            _setFromDateString(dateISOString);
            var month = self.month().toString();
            var year = self.year().toString();
            var formattedMonth = ("00" + month).substr(-2);
            return formattedMonth + "/" + year;
        };

        const _formatShortExpirationDate = (dateISOString) => {
            _setFromDateString(dateISOString);
            let month = self.month().toString();
            let year = self.year().toString();
            let formattedMonth = ("00" + month).substr(-2);
            let formattedYear = year.substr(2);
            return formattedMonth + "/" + formattedYear;
        };

        var _getCurrentTime = function() {
            // The Date() includes milliseconds, and we do not want milliseconds
            var now = new Date();
            var nowWithoutMilliseconds = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
            return nowWithoutMilliseconds;
        };

        var _setNow = function() {
            _date = _getCurrentTime();
        };

        var _year = function() {
            var year = _date.getFullYear();
            return year;
        };

        var _utcYear = function() {
            var year = _date.getUTCFullYear();
            return year;
        };

        var _yearShort = function() {
            return _year().toString().slice(-2);
        };

        var _month = function() {
            var month = _date.getMonth() + 1;
            return month;
        };

        const _monthName = function() {
            return _months[_month() - 1].name;
        };

        var _utcMonth = function() {
            var month = _date.getUTCMonth() + 1;
            return month;
        };

        var _day = function() {
            var day = _date.getDate();
            return day;
        };

        var _utcDay = function() {
            var day = _date.getUTCDate();
            return day;
        };

        var _dayPadded = function() {
            return ('0' + _day()).slice(-2);
        };

        var _dayRank = function() {
            var ordinalText = "";

            var day = _day();
            if (day > 3 && day < 21) {
                ordinalText = "th";
            } else {
                switch (day % 10) {
                    case 1:
                        ordinalText = "st";
                        break;
                    case 2:
                        ordinalText = "nd";
                        break;
                    case 3:
                        ordinalText = "rd";
                        break;
                    default:
                        ordinalText = "th";
                        break;
                }
            }

            return day + ordinalText;
        };

        var _twelveHour = function() {
            var hours = _date.getHours();

            var standard;
            if (hours === 0) {
                standard = 12;
            } else if (hours >= 13) {
                standard = hours - 12;
            } else {
                standard = hours;
            }
            return standard;
        };

        var _standardHour = function() {
            var hours = _date.getHours();
            return hours;
        };

        var _minute = function() {
            var minutes = _date.getMinutes();
            return minutes;
        };

        var _meridiem = function() {
            var hours = _date.getHours();

            var meridiem;
            if ((hours >= 0) && (hours < 12)) {
                meridiem = ANTE_MERIDIEM;
            } else {
                meridiem = POST_MERIDIEM;
            }
            return meridiem;
        };

        var _isOnSameDay = function(otherDate) {
            var otherDay = otherDate.day();
            var thisDay = _day();
            if (otherDay !== thisDay) {
                return false;
            }

            var otherMonth = otherDate.month();
            var thisMonth = _month();
            if (otherMonth !== thisMonth) {
                return false;
            }

            var otherYear = otherDate.year();
            var thisYear = _year();
            if (otherYear !== thisYear) {
                return false;
            }

            return true;
        };

        var _isInTheFuture = function() {
            var currentTime = _getCurrentTime();
            if (_date > currentTime) {
                return true;
            } else {
                return false;
            }
        };

        var _monthPadded = function() {
            return ('0' + _month()).slice(-2);
        };

        var _utcMonthPadded = function() {
            return ('0' + _utcMonth()).slice(-2);
        };

        var _formatForSorting = function() {
            return _date.toISOString();
        };

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        
        var _getDaysBetweenDates = function(otherDate) {
            var utc1 = Date.UTC(_year(), _month(), _day());
            var utc2 = Date.UTC(otherDate.getFullYear(), otherDate.getMonth() + 1, otherDate.getDate());
            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        };

        var _getISOString = function () {
            return _date.toISOString();
        };

        var _beginningOfDay = function() {
            _date.setHours(0);
            _date.setMinutes(0);
            _date.setSeconds(0);
            _date.setMilliseconds(0);
        };

        var _beginningOfWeek = function() {
            _beginningOfDay();
            var dayNumber = _date.getDay();

            _addDays(dayNumber * -1);
        };

        var _beginningOfMonth = function() {
            _beginningOfDay();
            _date.setDate(1);
        };

        var _beginningOfQuarter = function() {
            _beginningOfMonth();

            var monthNumber = _date.getMonth();
            var quarterDifference = monthNumber % 3;
            _addMonths(quarterDifference * -1);
        };

        var _beginningOfYear = function() {
            _beginningOfMonth();
            _date.setMonth(0);
        };

        var _addDays = function(count) {
            var dayOfMonth = _date.getDate();
            _date.setDate(dayOfMonth + count);
        };

        var _addMonths = function(count) {
            var monthNumber = _date.getMonth();
            _date.setMonth(monthNumber + count);
        };

        var _addYears = function(count) {
            var yearNumber = _date.getFullYear();
            _date.setFullYear(yearNumber + count);
        };

        var _rawDate = function() {
            return _date;
        };

        self.getExpirationDate = _getExpirationDate;
        self.formatExpirationDate = _formatExpirationDate;
        self.formatShortExpirationDate = _formatShortExpirationDate;
        self.formatForSorting = _formatForSorting;

        self.setFromDate = _setFromDate;
        self.setFromDateString = _setFromDateString;
        self.setToday = _setToday;
        self.setNow = _setNow;

        self.year = _year;
        self.utcYear = _utcYear;
        self.yearShort = _yearShort;
        self.month = _month;
        self.monthName = _monthName;
        self.utcMonth = _utcMonth;
        self.monthPadded = _monthPadded;
        self.utcMonthPadded = _utcMonthPadded;
        self.day = _day;
        self.utcDay = _utcDay;
        self.dayPadded = _dayPadded;
        self.dayRank = _dayRank;

        self.twelveHour = _twelveHour;
        self.standardHour = _standardHour;
        self.minute = _minute;
        self.meridiem = _meridiem;

        self.isOnSameDay = _isOnSameDay;
        self.isInTheFuture = _isInTheFuture;
        self.getDaysBetweenDates = _getDaysBetweenDates;
        self.getISOString = _getISOString;

        self.beginningOfDay = _beginningOfDay;
        self.beginningOfWeek = _beginningOfWeek;
        self.beginningOfMonth = _beginningOfMonth;
        self.beginningOfQuarter = _beginningOfQuarter;
        self.beginningOfYear = _beginningOfYear;
        self.addDays = _addDays;
        self.addMonths = _addMonths;
        self.addYears = _addYears;
        
        self.rawDate = _rawDate;
	};
});

