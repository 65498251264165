define('presentation/common/actionModal/viewModels/duplicateContactActionViewModel',[
    'common/promises/promiseFactory'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    return function(
        /** @type { () => JQueryDeferred<any> } */
        _saveContactCallback,
        /** @type Array<IContactPresentationObject> */ duplicateContacts,
        /** @type boolean */ isEdit
    ) {

        const self = this;

        /** @type { (typeof PromiseFactory)["prototype"] } */
        const _promiseFactory = new PromiseFactory();

        self.duplicateContacts = duplicateContacts;
        self.confirmationI18nKey= isEdit ? 'duplicateContactActionModal:editConfirmation' : 'duplicateContactActionModal:addConfirmation';

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _saveContactCallback()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        self.activate = () => {

            return _promiseFactory.wait();
        };
    };
});
