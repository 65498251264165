define('persistence/dataProviders/userSettingsDataProvider',['common/promises/promiseFactory',
        'externalDependencies/kazooApi'], function() {
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();
        _kazooApi.init();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_user_settings', 'GET')
                .done(function(result) {
                        deferredObject.resolve(result.data.data);
                })
                .fail(function(error) {
                    deferredObject.reject(error);
                });
            });
        };
    };
});

