define('presentation/common/actionModal/viewModels/deleteVoicemailGreetingActionViewModel',[
    'common/promises/promiseFactory',
], function() {
    return function(greeting) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        self.greeting = {};
        self.type = "greeting";

        self.activate = () => {
            self.greeting = greeting;

            return _promiseFactory.wait();
        };
    };
});
