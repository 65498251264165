define('presentation/contacts/facades/contactsSidebarFacade',[
    'businessServices/contacts/contactsStateSingleton'
], function(
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState
) {
    /** @typedef { import('presentation/contacts/facades/contactsSidebarFacade') } ContactsSidebarFacade */

    return function() {
        /**@type { ContactsSidebarFacade }*/
        const self = this;

        /** @type import('common/promises/promiseFactory') */
        let _promiseFactory = null;

        /** @type ContactsSidebarFacade["init"] */
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
        };

        /**  @type ContactsSidebarFacade["getContactsSidebar"] */
        const _getContactsSidebar = () => {
            return _contactsState.sidebarContacts;
        };

        /**  @type ContactsSidebarFacade["setSidebarContactsSort"] */
        const _setSidebarContactsSort = (sortBy) => {
            _contactsState.setSidebarContactsSort(sortBy);
        };

        /**  @type ContactsSidebarFacade["setSidebarContactsFilter"] */
        const _setSidebarContactsFilter = (filterBy) => {
            _contactsState.setSidebarContactsFilter(filterBy);
        };

        /**  @type ContactsSidebarFacade["getSelectedContactId"] */
        const _getSelectedContactId = () => {
            return _contactsState.selectedContactId();
        };

        /** @type ContactsSidebarFacade["getScrollPosition"] */
        const _getScrollPosition = () => {
            return _promiseFactory.defer((deferredObject) => {
                _contactsState.getSidebarScrollPosition().then(position => {
                    deferredObject.resolve(position);
                });
            });
        };

        /** @type ContactsSidebarFacade["setScrollPosition"] */
        const _setScrollPosition = (/** @type { number } */ position) => {
            _contactsState.setSidebarScrollPosition(position);
        };


        self.init = _init;
        self.getContactsSidebar = _getContactsSidebar;
        self.getSelectedContactId = _getSelectedContactId;
        self.setSidebarContactsSort = _setSidebarContactsSort;
        self.setSidebarContactsFilter = _setSidebarContactsFilter;
        self.setScrollPosition = _setScrollPosition;
        self.getScrollPosition = _getScrollPosition;
    };
});
