define('presentation/settings/phoneNumbers/viewModels/addPhoneNumberPortViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/numberProvisioning/numberProvisioningStore',
    'businessServices/router/router',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/countryEnumerations',
    'constants/numberPickerConstants',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/unsavedChangesActionViewModel',
    'presentation/settings/phoneNumbers/facades/addPhoneNumberPortFacade',
    'presentation/settings/phoneNumbers/validators/addPhoneNumberPortViewModelValidator',
    'presentation/settings/phoneNumbers/viewModels/numberToPortViewModel',
    'settings/navigationConfiguration'
], function() {

    return function() {
        const self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const NumberToPortViewModelConstructor = require('presentation/settings/phoneNumbers/viewModels/numberToPortViewModel');
        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const UnsavedChangesActionViewModelConstructor = require('presentation/common/actionModal/viewModels/unsavedChangesActionViewModel');

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _browserType = require('businessServices/browserSupport/browserType');
        const _countryEnumerations = require('constants/countryEnumerations');
        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _numberPickerConstants = require('constants/numberPickerConstants');
        const _router = require('businessServices/router/router');
        
        let _facade = null;
        let _phoneNumberFormatter = null;
        let _urlFormatter = null;
        let _validator = null;
        let _numberProvisioningStore = null;

        let _disposables = [];
        let _isActivated = false;

        const DEFAULT_COUNTRY_CODE = "US";
        const TAB_ENUMERATIONS = {
            numbers: "numbers",
            billing: "billing",
            authorization: "authorization"
        };

        const _exitWithWarning = (navigation) => {
            if (self.portingPhoneNumbers().length === 0) {
                self.modelStateObserver.navigateWithoutWarning = true;
                navigation();
            } else {
                let actionModal = new ActionModalViewModelConstructor();
                actionModal
                    .clearModal()
                    .setContentViewModel(UnsavedChangesActionViewModelConstructor, [{i18n: 'actionModal:unsavedChangesText'}])
                    .setHeaderText({i18n: 'unsavedChangesHeader'})
                    .setCancelButtonText({i18n: 'cancel'})
                    .setSubmitButtonText({i18n: 'continue'})
                    .showModal()
                    .then(result => {
                        if (result === "yes") {
                            self.modelStateObserver.navigateWithoutWarning = true;
                            navigation();
                        }
                    });
            }
        };

        const _onCurrentCarrierChange = (carrier) => {
            if (carrier){
                const googleCarrier = "google";
                const carrierToCheck = carrier.trim().toLowerCase();
                self.isGoogleMessageVisible(carrierToCheck.includes(googleCarrier));
            }
        };

        const _populateCountriesDropdown = (countryCode) => {
            if (self.countries) {
                const initialCountry = self.countries.find(country => {
                    return country.code === countryCode;
                });
                self.billingInfoCountry(initialCountry.code);
            }
        };

        const _populateRegionsDropdown = (region = null, useRegion = false) => {
            if (self.billingInfoCountry()) {
                const countryCode = self.billingInfoCountry();
                const regionsForCountry = self.countries.find(country => {
                    return country.code === countryCode;
                }).regions;

                self.regions(regionsForCountry);
                self.billingInfoState(useRegion ? region : regionsForCountry[0].code);
                self.showPostalCode(self.billingInfoCountry() === _countryEnumerations.ca.toUpperCase());
                self.showZipCode(self.billingInfoCountry() === _countryEnumerations.usa.toUpperCase());
            }
        };

        const _reIndexNumbersToPort = () => {
            self.numbersToPort().forEach((numberToPort, index) => numberToPort.index = index);
        };

        const _buildResultsForSummary = (addPhoneNumbersResult) => {
            const successfulPhoneNumbers = addPhoneNumbersResult.successfulPhoneNumbers;
            const failedPhoneNumbers = addPhoneNumbersResult.failedPhoneNumbers;
            const numbersToPortDict = self.numbersToPort().reduce((numbersToPort, numberToPort) => {
                numbersToPort[numberToPort.rawNumberToPort()] = numberToPort;
                return numbersToPort;
            }, {});

            const formattedSuccessfulNumbers = _formatNumbers(successfulPhoneNumbers, numbersToPortDict);
            const formattedFailedNumbers = _formatNumbers(failedPhoneNumbers, numbersToPortDict);

            return { formattedSuccessfulNumbers, formattedFailedNumbers };
        };

        const _formatNumbers = (numbers, numberDictionary) => {
            return numbers.map((number) => {
                const phoneNumberObject = numberDictionary[number];

                return {
                    number: phoneNumberObject.formattedNumberToPort(),
                    location: "",
                    hasLocation: false,
                };
            });
        };

        const _setInitialStates = (countryCode = DEFAULT_COUNTRY_CODE, regionCode = null) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                self.isCompositionComplete(false);
                _facade.getCountriesAndRegions()
                    .fail(promise.reject)
                    .done((countriesResult) => {
                        self.countries = countriesResult;
                        _populateCountriesDropdown(countryCode);
                        _populateRegionsDropdown(regionCode, false);
                        self.isLoadingLocations(false);
                        _disposables.push(self.billingInfoCountry.subscribe(_populateRegionsDropdown));

                        setTimeout(() => {
                            self.isCompositionComplete(true);
                        }, 200);

                        promise.resolve();
                    });
            });
        };

        self.modelStateObserver = null;
        self.pricingUrl = null;
        self.headerButtonText = _i18n.t('addPhoneNumberPort:transfer');

        self.activeTab = ko.observable(TAB_ENUMERATIONS.numbers);
        self.isCompositionComplete = ko.observable(false);
        self.isImpersonating = ko.observable(false);

        self.numbersToPort = ko.observableArray([]).extend({observeState: true});

        self.isContentScrollable = ko.observable(false);
        self.isScrollActive = ko.pureComputed(() => self.isContentScrollable() || _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
        self.showNextButton = ko.pureComputed(() => self.activeTab() !== TAB_ENUMERATIONS.authorization);
        self.portingPhoneNumbers = ko.pureComputed(() => {
            const validNumbersToPort = self.numbersToPort().filter(number => number.numberToPort.isValid());
            return validNumbersToPort.map(numberToPort => numberToPort.numberToPort());
        });

        self.canAddAnotherNumber = ko.pureComputed(() => self.numbersToPort().length < _numberPickerConstants.maxNumbersCanAdd);
        self.canDeleteNumber = ko.pureComputed(() => self.numbersToPort().length > 1);
        self.isSaveDisabled = ko.pureComputed(() => {
            let areAllNumbersToPortValid = self.numbersToPort().length > 0 && self.numbersToPort().every((item) => {
                return item.numberToPort.isValid();
            });
            return self.isImpersonating() || !areAllNumbersToPortValid;
        });
        self.showSelectedPhoneNumbers = ko.pureComputed(() => self.activeTab() !== TAB_ENUMERATIONS.numbers);

        self.currentCarrier = ko.observable('').extend({ rateLimit: 100 });
        self.isGoogleMessageVisible = ko.observable(false);
        self.selectedOnlyNumberOption = ko.observable(true);

        self.portingTypeOptions = ko.pureComputed(() => {
            return [
                {textI18n: _i18n.t('addPhoneNumberPort:optionOnlyNumber', {count: self.numbersToPort().length}) , value: true},
                {textI18n: _i18n.t('addPhoneNumberPort:optionOtherNumbers'), value: false}
            ];
        });

        self.googleWarningLink = _i18n.t('addPhoneNumberPort:googleWarning1') + '<a href="https://www.google.com/voice/unlock" target="blank">' + _i18n.t('addPhoneNumberPort:googleWarning1Link') + '</a>' + '.';

        self.portType = ko.pureComputed(() => {
            if (self.selectedOnlyNumberOption()){
                return "FULL";
            } else {
                return "PARTIAL";
            }
        });

        self.showFullPreview = ko.observable(false);
        self.previewNumbers = ko.pureComputed(() => self.showFullPreview() ? self.expandedPreviewNumbers() : self.collapsedPreviewNumbers());

        self.expandedPreviewNumbers = ko.pureComputed(() => {
            let formattedNumbersToPort = self.numbersToPort().map((numberToPort) => {
                return numberToPort.formattedNumberToPort();
            });

            return formattedNumbersToPort.map((formattedNumber, i) => {
                if ((i < (formattedNumbersToPort.length - 1))) {
                    return `${formattedNumber},`;
                }

                return formattedNumber;
            });
        });

        self.collapsedPreviewNumbers = ko.pureComputed(() => {
            let formattedNumbersToPort = self.numbersToPort().map((numberToPort) => {
                return numberToPort.formattedNumberToPort();
            });

            let sliceEnd = Math.min(formattedNumbersToPort.length, 4);

            return formattedNumbersToPort.slice(0, sliceEnd).map((formattedNumber, i) => {
                if ((i < (sliceEnd - 1))) {
                    return `${formattedNumber},`;
                }

                if ((i === (sliceEnd - 1))) {
                    if (formattedNumbersToPort.length <= 4) {
                        return formattedNumber;
                    }

                    return `${formattedNumber}... +${(formattedNumbersToPort.length - 4)} `;
                }
            });
        });

        self.previewCarrier = ko.pureComputed(() => ` (${self.currentCarrier()})`);

        self.countries = [];
        self.regions = ko.observable([]);

        self.billingInfoAddress = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoApt = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoCity = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoCountry = ko.observable("");
        self.billingInfoName = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoNumber = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoState = ko.observable("");
        self.billingInfoZip = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoPostal = ko.observable("").extend({ rateLimit: 500 });
        self.billingInfoPostalCode = ko.pureComputed(() => {
            return self.billingInfoCountry() === _countryEnumerations.ca.toUpperCase() ? self.billingInfoPostal() : self.billingInfoZip();
        });
        self.countryAbbreviation = ko.observable("us");
        self.pinOrPasscode = ko.observable("").extend({ rateLimit: 500 });
        self.portAccountNumber = ko.observable("").extend({ rateLimit: 500 });

        self.loadingPlaceholder = _i18n.t('addPhoneNumberPort:placeholderLoading');
        self.modalMatchStatement = _i18n.t('addPhoneNumberPort:modalMatchStatement1') + '<br/>' + '<u>' +  _i18n.t('signupPhoneNumberPortBilling:modalMatchStatementUnderlined') + '</u>' + _i18n.t('signupPhoneNumberPortBilling:modalMatchStatement2');
        self.pinOrPasscodeTooltip = _i18n.t('addPhoneNumberPort:tooltipPinOrPasscode');
        self.portAccountNumberTooltip = _i18n.t('addPhoneNumberPort:tooltipAccountNumberLine1') + '<br/>' +  _i18n.t('signupPhoneNumberPortBilling:tooltipAccountNumberLine2');

        self.isLoadingLocations = ko.observable(true);
        self.isValid = ko.observable(false);
        self.showPostalCode = ko.observable(false);
        self.showZipCode = ko.observable(true);

        self.regionType = ko.pureComputed(() => {
            if (self.showZipCode()){
                return _i18n.t('addPhoneNumberPort:labelState');
            } else {
                return _i18n.t('addPhoneNumberPort:labelProvince');
            }
        });

        self.authorizedSignature = ko.observable("");
        self.portDate = ko.observable("");
        self.portDateSelection = ko.observable("default");
        
        self.isNavigateToNextPageActive = ko.pureComputed(() => {
            switch (self.activeTab()) {
                case TAB_ENUMERATIONS.numbers:
                    let areAllNumbersToPortValid = self.numbersToPort().length > 0 && self.numbersToPort().every((item) => {
                        return item.numberToPort.isValid();
                    });
                    return areAllNumbersToPortValid && self.currentCarrier().length > 0;
                case TAB_ENUMERATIONS.billing:
                    return self.billingInfoName().length > 0 &&
                        self.billingInfoNumber().length > 9 &&
                        self.billingInfoPostalCode().length > 4 &&
                        self.billingInfoAddress().length > 0 &&
                        self.billingInfoCity().length > 0;
                case TAB_ENUMERATIONS.authorization:
                    return self.portDate() &&
                        self.portDate().length > 0 &&
                        self.authorizedSignature().length > 0;
                default:
                    break;
            }
        });

        self.validatePage = () => {
            switch (self.activeTab()) {
                case TAB_ENUMERATIONS.numbers:
                    self.numbersToPort().every((item) => {
                        item.numberToPort.validate();
                    });

                    self.currentCarrier.validate();
                    break;
                case TAB_ENUMERATIONS.billing:
                    self.billingInfoName.validate();
                    self.billingInfoNumber.validate();
                    self.billingInfoPostalCode.validate();
                    self.billingInfoAddress.validate();
                    self.billingInfoCity.validate();
                    break;
                case TAB_ENUMERATIONS.authorization:
                    self.portDate.validate();
                    self.authorizedSignature.validate();
                    break;
                default:
                    break;
            }
        };

        self.tabProgressWidth = ko.pureComputed(() => {
            switch (self.activeTab()) {
                case TAB_ENUMERATIONS.numbers:
                    return 1/3;
                case TAB_ENUMERATIONS.billing:
                    return 2/3;
                case TAB_ENUMERATIONS.authorization:
                    return 1;
            }
        });

        self.togglePreviewSize = () => {
            self.showFullPreview(!self.showFullPreview());
        };
        
        self.isTabActive = (testTabEnumeration) => {
            return testTabEnumeration === self.activeTab();
        };

        self.isTabInProgress = (testTabEnumeration) => {
            switch (testTabEnumeration) {
                case TAB_ENUMERATIONS.numbers:
                    return true;
                case TAB_ENUMERATIONS.billing:
                    return (self.activeTab() === TAB_ENUMERATIONS.billing) || (self.activeTab() === TAB_ENUMERATIONS.authorization);
                case TAB_ENUMERATIONS.authorization:
                    return self.activeTab() === TAB_ENUMERATIONS.authorization;
            }
        };

        self.cancelClick = () => _exitWithWarning(() => _router.navigate(_navigationConfiguration.routesById.phoneNumbers.url));

        self.backClick = () => {
            switch (self.activeTab()) {
                case TAB_ENUMERATIONS.numbers:
                    _exitWithWarning(() => _router.navigate(_navigationConfiguration.routesById.newPhoneNumberType.url));
                    break;
                case TAB_ENUMERATIONS.billing:
                    self.activeTab(TAB_ENUMERATIONS.numbers);
                    break;
                case TAB_ENUMERATIONS.authorization:
                    self.activeTab(TAB_ENUMERATIONS.billing);
                    break;
                default:
                    break;
            }
        };

        self.nextClick = () => {
            return _promiseFactory.defer((deferredObject) => {

                self.validatePage();

                if (self.isNavigateToNextPageActive() === false) {
                    return;
                }

                switch (self.activeTab()) {
                    case TAB_ENUMERATIONS.numbers:
                        self.activeTab(TAB_ENUMERATIONS.billing);
                        if (self.countries.length === 0) {
                            _setInitialStates()
                                .fail(deferredObject.reject)
                                .done(deferredObject.resolve);
                        } else {
                            deferredObject.resolve();
                        }
                        break;
                    case TAB_ENUMERATIONS.billing:
                        self.activeTab(TAB_ENUMERATIONS.authorization);
                        deferredObject.resolve();
                        break;
                    default:
                        deferredObject.resolve();
                        break;
                }
            });
        };

        self.hideNumber = (elem) => {
            if (elem.nodeType === 1) {
                $(elem).slideUp(() => {$(elem).remove();});
            }
        };

        self.showNumber = (elem) => {
            if (elem.nodeType === 1) {$(elem).hide().slideDown();}
        };

        self.hideGoogleMessage = () => {
            self.isGoogleMessageVisible(false);
        };

        self.addAnotherNumber = () => {
            self.hideGoogleMessage();

            let numberToPort = new NumberToPortViewModelConstructor(self, _facade);
            self.numbersToPort.push(numberToPort);
            _reIndexNumbersToPort();
            self.modelStateObserver.addChildObserver(numberToPort.modelStateObserver);
        };

        self.deleteNumber = (numberToPort) => {
            if (self.canDeleteNumber() === false) {
                return;
            }
            self.numbersToPort.remove(numberToPort);
            _reIndexNumbersToPort();
        };

        self.removeSelectedPhoneNumber = (phoneNumber) => {
            self.numbersToPort.find(number => number.numberToPort() === phoneNumber.remove(number));
        };

        self.portNumbers = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {

                        if (!isValid) {
                            deferredObject.resolve();
                            return;
                        }

                        let areNumbersValid = true;
                        _promiseFactory.deferredList(self.numbersToPort(), (number) => {
                            return _promiseFactory.deferIndefinitely((numberPromise) => {
                                number.validate()
                                    .fail(numberPromise.reject)
                                    .done(isNumberValid => {
                                        areNumbersValid = areNumbersValid && isNumberValid;
                                        numberPromise.resolve(areNumbersValid);
                                    });
                            });
                        })
                            .fail(deferredObject.reject)
                            .done((areNumbersValid) => {
                                if (areNumbersValid && isValid) {
                                    const numbersToAdd = self.numbersToPort().filter(n => n.numberToPort() !== "" && n.validator.isValid());
                                    _facade.addPortedPhoneNumbers(numbersToAdd, self)
                                        .fail(deferredObject.reject)
                                        .done((result) => {
                                            self.modelStateObserver.saveData();

                                            const { formattedSuccessfulNumbers, formattedFailedNumbers } = _buildResultsForSummary(result);

                                            _numberProvisioningStore.setNumberType(_numberPickerConstants.numberTypes.port);
                                            _numberProvisioningStore.addSuccessfulNumbers(formattedSuccessfulNumbers);
                                            _numberProvisioningStore.addFailedNumbers(formattedFailedNumbers);

                                            _router.navigate(_navigationConfiguration.routesById.newPhoneNumberSummary.url);

                                            deferredObject.resolve();
                                        });
                                }
                                deferredObject.resolve();
                            });    
                    });
            });
        };

        self.togglePortNumberOptions = () => {
            if (self.selectedOnlyNumberOption()) {
                self.selectedOnlyNumberOption(false);
            } else {
                self.selectedOnlyNumberOption(true);
            }
        };

        self.compositionComplete = () => {
            setTimeout(() => {
                self.isCompositionComplete(true);
            }, 200);
        };

        self.detached = () => {
            _disposables.forEach(subscription => subscription.dispose);
            _disposables = [];
        };

        
        self.activate = () => {
            if (_isActivated) {
                return;
            }
            _isActivated = true;

            _urlFormatter = require('common/url/urlFormatter');

            const FacadeConstructor = require('presentation/settings/phoneNumbers/facades/addPhoneNumberPortFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const ValidatorConstructor = require('presentation/settings/phoneNumbers/validators/addPhoneNumberPortViewModelValidator');
            _validator = new ValidatorConstructor();

            const NumberProvisioningStoreConstructor = require('businessServices/numberProvisioning/numberProvisioningStore');
            _numberProvisioningStore = new NumberProvisioningStoreConstructor();
            _numberProvisioningStore.init();

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.isImpersonating(_facade.isImpersonating());

            self.pricingUrl = _urlFormatter.buildFrontendUrl(["pricing"]);
            self.pricingUrlText = _i18n.t('numberPicker:pricingPage');
            self.pricingMessage = _i18n.t('numberPicker:pricingMessage');

            let numberToPort = new NumberToPortViewModelConstructor(self, _facade);
            self.numbersToPort.push(numberToPort);
            _reIndexNumbersToPort();
            self.modelStateObserver.addChildObserver(numberToPort.modelStateObserver);

            _validator.registerViewModel(self, _facade);
            _disposables.push(self.currentCarrier.subscribe(_onCurrentCarrierChange));
            self.modelStateObserver.commitData();

            return _promiseFactory.wait();
        };
    };
});
