define('common/converters/phoneNumberFormatter',['businessServices/country/countryCode',
        'intlTelInputUtils'],
function () {
    var CountryCodeConstructor = require('businessServices/country/countryCode');
    var _countryCodes = new CountryCodeConstructor();
    var _clean = function(phoneNumber) {
        if (typeof phoneNumber !== "string") {
            return "";
        }
        return phoneNumber.replace(/[^a-zA-Z0-9]/g, '');
    };

    const TEN_OR_ELEVEN_DIGIT_OR_PLUS_PHONE_NUMBER_REGEX = /^\+?1?(\d{3})(\d{3})(\d{4})$/;
    const VALID_INTERNATIONAL_FORMAT = /^\+1 \d{3}-\d{3}-\d{4}$/;

    var REPLACE_ALPHA = {
        A: "2", B: "2", C: "2",
        D: "3", E: "3", F: "3",
        G: "4", H: "4", I: "4",
        J: "5", K: "5", L: "5",
        M: "6", N: "6", O: "6",
        P: "7", Q: "7", R: "7", S: "7",
        T: "8", U: "8", V: "8",
        W: "9", X: "9", Y: "9", Z: "9"
    };

    var CHECK_NUMERIC_TO_APLHA = {
        "0": new Set([]),
        "1": new Set([]),
        "2": new Set(["A", "B", "C"]),
        "3": new Set(["D", "E", "F"]),
        "4": new Set(["G", "H", "I"]),
        "5": new Set(["J", "K", "L"]),
        "6": new Set(["M", "N", "O"]),
        "7": new Set(["P", "Q", "R", "S"]),
        "8": new Set(["T", "U", "V"]),
        "9": new Set(["W", "X", "Y", "Z"])
    };

    var NUMBER_FORMATS = {
        "E164": 0,
        "INTERNATIONAL": 1,
        "NATIONAL": 2,
        "RFC3966": 3
    };

    const Unknown = "Unknown";

    var _toNumbers = function (alphanumericPhoneNumber, countryAbbreviation) {
        var phoneNumber = _clean(alphanumericPhoneNumber);

        if (countryAbbreviation) {
            phoneNumber = replaceCountryCodeWithPrefix(phoneNumber, countryAbbreviation);
        } else {
            phoneNumber = replaceCountryCodeWithPrefix(phoneNumber, _countryCodes.defaultCountryAbbreviation);
        }

        phoneNumber = phoneNumber.replace(/([A-Z])/gi, function($0) {
            return REPLACE_ALPHA[$0.toUpperCase()];
        });

        return phoneNumber;
    };

    var _toHumanReadable = (phoneNumber, humanReadableString) => {
        const cleanedNumber = _clean(phoneNumber);
        const lastSevenDigits = cleanedNumber.slice(-7);
        const npa = _getNpa(cleanedNumber);
        const upperCaseString = humanReadableString.toUpperCase();
        const humanReadableStringLength = humanReadableString.length;
        let humanReadableIndex = 0;
        let matchStartIndex = -1;
        Array.from(lastSevenDigits).forEach((digit, index) => {
            if (humanReadableIndex < humanReadableStringLength) {
                if (CHECK_NUMERIC_TO_APLHA[digit].has(upperCaseString[humanReadableIndex])) {
                    if (matchStartIndex === -1) {
                        matchStartIndex = index;
                    }

                    humanReadableIndex++;
                } else {
                    matchStartIndex = -1;
                    humanReadableIndex = 0;
                }
            }
        });

        if (humanReadableIndex === humanReadableStringLength) {
            const preVanityStringNumbers = lastSevenDigits.slice(0, matchStartIndex);
            const postVanityStringNumbers = lastSevenDigits.slice(matchStartIndex + humanReadableStringLength);

            let responsePhoneNumber = "(" + npa + ") ";
            if (preVanityStringNumbers.length > 0){
                responsePhoneNumber += (preVanityStringNumbers + "-");
            }

            responsePhoneNumber += upperCaseString;

            if (postVanityStringNumbers.length > 0) {
                responsePhoneNumber += ("-" + postVanityStringNumbers);
            }

            return responsePhoneNumber;
        }

        return _toUSAreaCode(cleanedNumber);
    };

    var _toNational = function (phoneNumberInput, countryAbbreviation) {
        var isoCountry = countryAbbreviation || _countryCodes.defaultCountryAbbreviation;
        return intlTelInputUtils.formatNumber(phoneNumberInput,  isoCountry, NUMBER_FORMATS.NATIONAL);
    };

    // ex: "+1 850-422-2527"
    var _toInternational = function (phoneNumberInput, countryAbbreviation) {
        var phoneNumber;
        if (countryAbbreviation) {
            phoneNumber = intlTelInputUtils.formatNumber(phoneNumberInput, countryAbbreviation, NUMBER_FORMATS.INTERNATIONAL);
        } else {
            phoneNumber = intlTelInputUtils.formatNumber(phoneNumberInput,  _countryCodes.defaultCountryAbbreviation, NUMBER_FORMATS.INTERNATIONAL);
        }
        if (!VALID_INTERNATIONAL_FORMAT.test(phoneNumber)) {
            return Unknown;
        }
        return phoneNumber;
    };

    // ex: "+1 (850) 422-2527"
    const _toInternationalWithParens = (phoneNumberInput, countryAbbreviation) => {
        let phoneNumber;
        if (countryAbbreviation) {
            phoneNumber = intlTelInputUtils.formatNumber(phoneNumberInput, countryAbbreviation, NUMBER_FORMATS.INTERNATIONAL);
        } else {
            phoneNumber = intlTelInputUtils.formatNumber(phoneNumberInput, _countryCodes.defaultCountryAbbreviation, NUMBER_FORMATS.INTERNATIONAL);

            if (phoneNumberInput && phoneNumberInput.length > 2 && phoneNumberInput[0] === "1" && phoneNumberInput[1] === "1") {
                //replacing the 1 that was mistakenly removed by intlTelInputUtils.formatNumber. it removes an extra 1 when area code entered starts with 1
                phoneNumber = phoneNumber.replace(" ", " 1");
            }
        }

        if (VALID_INTERNATIONAL_FORMAT.test(phoneNumber)) {
            return _toNumbers(phoneNumber).replace(TEN_OR_ELEVEN_DIGIT_OR_PLUS_PHONE_NUMBER_REGEX, "+1 ($1) $2-$3");
        } else {
            return Unknown;
        }
    };

    // ex: "+18504222527"
    const _toEOneSixFour = (phoneNumberInput, countryAbbreviation) => {
        const country = countryAbbreviation ? countryAbbreviation : _countryCodes.defaultCountryAbbreviation;
        return intlTelInputUtils.formatNumber(phoneNumberInput, country, NUMBER_FORMATS.E164);
    };

    const _toNumericDefault = (phoneNumber) => {
        const numeric = phoneNumber.replace(/\D/g, '');
        if (numeric.length <= 6) {
            return phoneNumber;
        }

        return _toInternationalWithParens(numeric);
    };

    const _cleanupLocation = (location) => {
        switch (location) {
            case "notfound":
            case "":
                return "Unknown Location";
            case null:
            case undefined:
            case "TOLL-FREE":
                return "Toll-Free";
            default:
                return location;
        }
    };

    const _cleanupLocationSansTollFree = (location) => {
        switch (location) {
            case "notfound":
            case "":
                return "Unknown Location";
            case null:
            case undefined:
            case "TOLL-FREE":
                return "";
            default:
                return location;
        }
    };

    const _isTollFree = (ani) => /^[+1 ]*?(?:800|888|877|866|855|844|833)/.test(ani);

    const _isShortCode = (number) => {
        return number.length === 5 || number.length === 6;
    };

    const _buildLocation = (ani, city, state) => {
        if (city && state) {
            return `${city}, ${state}`;
        }
        if (_isTollFree(ani)) {
            return "";
        }
        return "Unknown Location";
    };

    // ex: "850-422-2527"
    var _toDashed = function(phoneNumber) {
        return _toNumbers(phoneNumber).replace(TEN_OR_ELEVEN_DIGIT_OR_PLUS_PHONE_NUMBER_REGEX, "$1-$2-$3");
    };

    // ex: "(850) 422-2527"
    var _toUSAreaCode = function(phoneNumber) {
        return _toNumbers(phoneNumber).replace(TEN_OR_ELEVEN_DIGIT_OR_PLUS_PHONE_NUMBER_REGEX, "($1) $2-$3");
    };

    var _getNpa = function(phoneNumber) {
        return _toNumbers(phoneNumber).replace(TEN_OR_ELEVEN_DIGIT_OR_PLUS_PHONE_NUMBER_REGEX, "$1");
    };

    // ex: "<span>(850)</span> <span>422</span>-<span>2527</span>"
    // this is to prevent browsers from enabling their own click-to-call when things like Skype are installed
    var _toUSAreaCodeSpanned = function(phoneNumber) {
        return _toNumbers(phoneNumber).replace(TEN_OR_ELEVEN_DIGIT_OR_PLUS_PHONE_NUMBER_REGEX, "<span>($1)</span> <span>$2</span>-<span>$3</span>");
    };

    var _toWebLinkFormat = function(phoneNumber) {
        return intlTelInputUtils.formatNumber(phoneNumber,  _countryCodes.defaultCountryAbbreviation, NUMBER_FORMATS.E164);
    };

    /**
     *
     * @param {string} city
     * @param {string} region
     * @returns {string} "City, Region" or ""
     */
    const _formatLocation = (city, region) => {
        const trimmedCity = (city || "").trim();
        const trimmedRegion = (region || "").trim();

        if (trimmedCity && trimmedRegion) {
            return `${trimmedCity}, ${trimmedRegion}`;
        }
        else if (trimmedCity) {
            return trimmedCity;
        }
        else if (trimmedRegion) {
            return trimmedRegion;
        }

        return "";
    };

    var replaceCountryCodeWithPrefix = function (phoneNumber, countryAbbreviation) {
        const country = _countryCodes.findByAbbreviation(countryAbbreviation);

        if (country === undefined) {
            return phoneNumber;
        }
        const regexp = new RegExp('^' + country.countryCode, 'i');
        return phoneNumber.replace(regexp, '');
    };

    const _maybeAddCountryCode = (phoneNumber, addSpace = true) => {
        if (phoneNumber.startsWith("+1")) {
            return phoneNumber;
        }

        let maybeSpace = addSpace ? " " : "";
        return "+1" + maybeSpace + phoneNumber;
    };

    const _removeNonNumericalAndLeadingOne = (phoneNumber) => {
        if (typeof phoneNumber !== "string") {
            return "";
        }
        return phoneNumber.replace(/\D/g, '').replace(/^1/, '');
    };

    return function() {
        var self = this;
        self.Unknown = Unknown;
        self.buildLocation = _buildLocation;
        self.clean = _clean;
        self.cleanupLocation = _cleanupLocation;
        self.cleanupLocationSansTollFree = _cleanupLocationSansTollFree;
        self.getNpa = _getNpa;
        self.isTollFree = _isTollFree;
        self.isShortCode = _isShortCode;
        self.maybeAddCountryCode = _maybeAddCountryCode;
        self.toDashed = _toDashed;
        self.toEOneSixFour = _toEOneSixFour;
        self.toNumericDefault = _toNumericDefault;
        self.toNational = _toNational;
        self.toNumbers = _toNumbers;
        self.toHumanReadable = _toHumanReadable;
        self.toUSAreaCode = _toUSAreaCode;
        self.toUSAreaCodeSpanned = _toUSAreaCodeSpanned;
        self.toInternational = _toInternational;
        self.toInternationalWithParens = _toInternationalWithParens;
        self.toWebLinkFormat = _toWebLinkFormat;
        self.formatLocation = _formatLocation;
        self.removeNonNumericalAndLeadingOne = _removeNonNumericalAndLeadingOne;
    };
});

