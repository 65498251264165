define('persistence/dataProviders/forwardingNumberDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();


        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_forwarding_numbers','GET')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(forwardingNumberId) {
            if (forwardingNumberId === null) {
                var err = new Error("Missing forwardingNumberId");
                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_forwarding_numbers/' + forwardingNumberId, 'GET')
                        .done(function(result) {
                            if (result.data.error) {
                                deferredObject.reject(result.data);
                            } else {
                                deferredObject.resolve(result.data.data);
                            }
                        })
                        .fail(function(error) {
                            error.forwardingNumberId = forwardingNumberId;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.isPhoneNumberRestricted = function(phoneNumber) {
            return _promiseFactory.defer(function(promise) {
                _kazooApi.callAccount('/lobby_forwarding_numbers/validate_number/' + phoneNumber, 'GET')
                    .done(function(result) {
                        promise.resolve(result.data);
                    })
                    .fail(function(error) {
                        error.phoneNumber = phoneNumber;
                        promise.reject(error);
                    });
            });
        };
    };
});

