define('presentation/acceptUserInvite/facades/acceptUserInviteFacade',['persistence/webSocket/webSocketApp'],
function() {
    let _promiseFactory = null;
    let _webSocketApp = null;

    const _isLinkValid = (linkItems) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketApp.send("isLinkValid", linkItems, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_accept_invite");
    };

    return function() {
        const self = this;

        self.init = _init;
        self.isLinkValid = _isLinkValid;
    };
});

