define('presentation/common/warningModal/viewModels/integrityWarningModalViewModel',[
    'businessServices/converters/durationTimeFormatter',
    'common/collections/collectionSorter',
    'common/converters/phoneNumberFormatter',
    'common/presentation/iconNames',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/systemObjectIconNameConstants',
    'presentation/common/modal',
    'presentation/common/voicePrompt/voicePromptAudioFactory'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    return function() {
        const self = this;

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();

        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const _timeFormatter = new TimeFormatterConstructor();

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        let _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();

        const _commonState = require('common/storage/commonState');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _modalService = null;
        let _promise = null;

        const _onIntegrityWarningsChanged = (integrityWarnings) => {
            self.showWarningMessage(integrityWarnings.length > 0);
        };

        const _buildSystemObjectItem = (systemObjectId) => {
            let systemObject = {};
            systemObject.isAudioFile = false;
            systemObject.isAutoAttendant = false;
            systemObject.isUser = false;
            systemObject.isUserGroup = false;

            let commonStateObject = _commonState.get(systemObjectId);

            switch (commonStateObject.type) {
                case _commonState.types.autoAttendant:
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        isAutoAttendant: true,
                    });
                case _commonState.types.forwardingNumber:
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        metadata: _phoneNumberFormatter.toInternationalWithParens(commonStateObject.phoneNumber()),
                    });
                case _commonState.types.hostedNumber:
                    const isDefault = commonStateObject.hasDefaultName();
                    const formattedNumber = _phoneNumberFormatter.toInternationalWithParens(commonStateObject.phoneNumber());
                    const formattedLocation = _phoneNumberFormatter.cleanupLocation(commonStateObject.location().cityState);
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        metadata: isDefault ? formattedLocation : formattedNumber,
                    });
                case _commonState.types.schedule:
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                    });
                case _commonState.types.user:
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        avatar: commonStateObject.avatar,
                        hasAvatar: commonStateObject.avatar().showImage(),
                        isUser: true,
                    });
                case _commonState.types.userGroup:
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        groupId: systemObjectId,
                        hasAvatar: commonStateObject.avatars().length > 1,
                        isUserGroup: true,
                    });
                case _commonState.types.voicemailBox:
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                    });
                case _commonState.types.prompt:
                    let formattedDuration = _timeFormatter.formatTotalMilliseconds(commonStateObject.durationInMilliseconds());
                    let audioObject = ko.observable(_voicePromptAudioFactory.getVoicePrompt(systemObjectId));
                    audioObject().duration = ko.observable(formattedDuration);
                    return Object.assign({}, systemObject, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        isAudioFile: true,
                        audioObject: audioObject,
                        durationInSeconds: formattedDuration,
                    });
                default:
                    return null;
            }
        };

        const _formatIntegrityWarning = (integrityWarning) => {
            const css = `${self.css()}__integrity-item`;
            switch (integrityWarning.icon){
                case 'phone':
                case 'recording':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.hostedNumber,
                        css: css
                    });
                case 'attendant':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.autoAttendant,
                        css: css
                    });
                case 'forwarding':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.forwardingNumber,
                        css: css
                    });
                case 'schedule':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.schedule,
                        css: css
                    });
                case 'user-device':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.device,
                        css: css
                    });
                case 'user':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.user,
                        css: css
                    });
                case 'userGroup':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.userGroup,
                        css: css
                    });
                default:
                    return null;
            }
        };

        self.css = ko.observable('integrity-warning-modal');
        self.isCompositionComplete = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.shouldScrollIntoView = ko.observable(true);
        self.shouldContentOverflow = ko.pureComputed(() => self.isCompositionComplete() && self.isScrollActive());

        self.setCss = (css) => {
            self.css(css);
            return self;
        };

        self.header = ko.observable('');
        self.setHeading = (i18n) => self.header(i18n);

        self.texts = ko.observableArray([]);
        self.addText = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__text ${addCss}`);
            self.texts.push({i18n, css});
            return self;
        };

        self.systemObject = ko.observable('');
        self.showSystemObject = ko.pureComputed(() => self.systemObject() !== '');
        self.setSystemObject = ({objectId, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__object ${addCss}`);
            const object = _buildSystemObjectItem(objectId);
            self.systemObject({object, css});
            return self;
        };

        self.integrityWarningTexts = ko.observableArray([]);
        self.addIntegrityWarningText = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__text ${addCss}`);
            self.integrityWarningTexts.push({i18n, css});
            return self;
        };

        self.integrityWarnings = ko.observableArray([]);
        self.integrityWarnings.subscribe(_onIntegrityWarningsChanged);
        self.showWarningMessage = ko.observable(false);
        self.addIntegrityWarnings = (integrityWarnings) => {
            _sorter.multiSort(integrityWarnings, ["entityText", "entityName"]);
            let formattedIntegrityWarnings = integrityWarnings.map((integrityWarning) => _formatIntegrityWarning(integrityWarning));
            self.integrityWarnings(formattedIntegrityWarnings);
            return self;
        };

        self.onCancel = () => {
            self.isCompositionComplete(false);
            _promise.resolve();
            _modalService.closeModal(self);
        };

        self.clearModal = () => {
            self.css('integrity-warning-modal');
            self.texts.removeAll();
            self.integrityWarnings.removeAll();
            self.integrityWarningTexts.removeAll();
            self.systemObject('');
            self.setHeading({});
            return self;
        };

        self.showModal = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();
            _modalService.showModal(self);
            _promise = _promiseFactory.deferIndefinitely();
            return _promise;
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };
    };
});
