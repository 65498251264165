define('presentation/inbox/viewModels/inboxViewModel',['businessServices/router/router',
        'common/promises/promiseFactory',
        'settings/navigationConfiguration'
    ],
    function () {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _navigationConfiguration = require('settings/navigationConfiguration');
        let _router = require('businessServices/router/router');

        const _findFirstNavWithSidebar = (route) => {
            if (route.sidebarViewModel === null &&
                route.childRoutes.length > 0) {
                return _findFirstNavWithSidebar(route.childRoutes[0]);
            } else {
                return route;
            }
        };

        const _initializeScreen = () => {
            const inboxNav = _navigationConfiguration.routesById["inbox"];
            const firstAvailableSubNav = _findFirstNavWithSidebar(inboxNav);

            _router.navigate(firstAvailableSubNav.url);

            return _promiseFactory.wait();
        };

        return {
            activate: _initializeScreen,
        };
    });

