/* global dataLayer */
define('businessServices/googleAnalytics/analyticsTracking',['settings/settings'], function () {

    var _settings = require('settings/settings');

    var EVENT = {
        "CreateAccount": {
            eventCategory: 'Acquisition',
            eventAction: 'CreateAccount'
        }
    };

    function trackSignup(lobbyAccountId, total) {
        var isAnalyticsEnabled = _settings.googleAnalyticsEnabled;
        
        if (isAnalyticsEnabled) {
            var analyticsId = _settings.googleAnalyticsTrackingId;
            
            /* jshint ignore:start */
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

            ga('create', analyticsId, 'auto');
            ga('require', 'ecommerce');
            ga('ecommerce:addTransaction', {
                'id': lobbyAccountId,
                'revenue': total
            });
            ga('ecommerce:send');
            /* jshint ignore:end */
        }
    }

    function trackEvent(event) {

        var isAnalyticsEnabled = _settings.googleAnalyticsEnabled;

        if (isAnalyticsEnabled && event) {
            var analyticsId = _settings.googleAnalyticsTrackingId;

            /* jshint ignore:start */
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

            ga('create', analyticsId, 'auto', {'name': 'analyticsTracking'});
            ga('analyticsTracking.send', 'event', event);

            try {
                window.dataLayer.push({
                    'event' : event.eventAction
                });
            } catch(ex) {
                // The browser is blocking analytics
            }


            /* jshint ignore:end */
        }
    }

    function trackCreateAccount() {
        trackEvent(EVENT.CreateAccount);
    }

    function trackPageView(path, title) {
        try {
            window.dataLayer.push({
                'event' : 'pageView',
                'page' : {
                    'url' : path,
                    'title' : title
                }
            });
        } catch (ex) {
            // The browser is blocking analytics
        }
    }

    return {
        trackSignup: trackSignup,
        trackCreateAccount: trackCreateAccount,
        trackPageView : trackPageView
    };
});

