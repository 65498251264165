define('businessServices/login/logoutRouteEvaluator',['businessServices/router/router',
    'settings/navigationConfiguration'
], function () {
    let _logoutRoute = null;

    function setLogoutRoute(logoutRoute) {
        _logoutRoute = logoutRoute;
    }

    function receiveAlertLoginSuccessful() {
        _logoutRoute = null;
    }

    function receiveAlertLogout() {
        var navigationConfiguration = require('settings/navigationConfiguration');
        var router = require('businessServices/router/router');

        if (_logoutRoute) {
            router.navigate(_logoutRoute);
        } else {
            router.navigate(navigationConfiguration.loginPageUrl);
        }
    }

    return {
        alerts: {
            receiveAlertLoginSuccessful: receiveAlertLoginSuccessful,
            receiveAlertLogout: receiveAlertLogout
        },
        setLogoutRoute: setLogoutRoute
    };
});
