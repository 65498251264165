define('presentation/analytics/viewModels/analyticsTotalsViewModel',[
        'businessServices/browserSupport/browserType',
        'businessServices/converters/durationTimeFormatter',
        'businessServices/converters/numberFormatter',
        'common/promises/promiseFactory',
        'presentation/common/analyticsGraph/summaryTotalRecordView'
    ],
    function(
        /** @type typeof import('businessServices/browserSupport/browserType') */
        _browserType,
        /** @type typeof import('businessServices/converters/durationTimeFormatter') */
        DurationTimeFormatter,
        /** @type typeof import('businessServices/converters/numberFormatter') */
        NumberFormatter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type import('presentation/common/analyticsGraph/summaryTotalRecordView') */
        _summaryTotalRecordView
    ) {
        return function() {
            const self = this;

            const _promiseFactory = new PromiseFactory();

            /** @type {IDisposable[]} */
            let _disposables = [];
            const _durationFormatter = new DurationTimeFormatter();
            const _numberFormatter = new NumberFormatter();

            /** @type {KnockoutObservable<IAnalyticsTotalsProjectionResult>} */
            let _summaryTotalData = null;
            /** @type {() => keyof HEADING} */
            let _searchByFilter = null;

            _summaryTotalData = ko.observableArray();

            const HEADING = {
                answered: 'answered',
                averageDuration: 'averageDuration',
                calls: 'calls',
                forwardingNumber: 'forwardingNumber',
                inbound: 'inbound',
                minutes: 'minutes',
                missed: 'missed',
                outbound: 'outbound',
                phoneNumber: 'phoneNumber',
                system: 'system',
                totalCalls: 'totalCalls',
                totalDuration: 'totalDuration',
                user: 'user',
                userGroup: 'userGroup',
                voicemails: 'voicemails',
            };

            self.parentViewModel = ko.observable(null);
            self.summaryCalls = ko.observableArray([]);
            self.summaryMinutes = ko.observableArray([]);
            /** @type {KnockoutObservable<keyof HEADING>} */
            self.searchByFilter = ko.observable('calls');
            self.isCalls = ko.pureComputed(() => self.searchByFilter() === 'calls');
            self.isMinutes = ko.pureComputed(() => !self.isCalls());
            self.isMinified = ko.pureComputed(() => _browserType.windowWidth() <= 1200);
            /** @type {KnockoutObservable<boolean>} */
            self.showLoadingState = null;
            /** @type {KnockoutObservable<boolean>} */
            self.showEmptyState = null;

            const _buildSummaryLine = (/** @type {string} */title, hideValue = false) => ({
                title,
                value: ko.observable(0),
                get valueDisplay() {
                        return self.showLoadingState() || hideValue ?
                                "-" :
                                this.value();
                },
                get durationDisplay() {
                    return self.showLoadingState() || hideValue ?
                        "-" :
                        _durationFormatter.formatMinutesSummary(this.value());
                },
                get prefixedDisplay() {
                    return self.showLoadingState() || hideValue ?
                        "-" :
                        _numberFormatter.formatDigitsWithPrefix(this.value());
                }
            });

            self.totalCalls = {
                calls: _buildSummaryLine(HEADING.calls),
                outbound: _buildSummaryLine(HEADING.outbound),
                inbound: _buildSummaryLine(HEADING.inbound),
                answered: _buildSummaryLine(HEADING.answered),
                missed: _buildSummaryLine(HEADING.missed),
                system: _buildSummaryLine(HEADING.system),
            };

            self.totalMinutes = {
                minutes: _buildSummaryLine(HEADING.minutes),
                outbound: _buildSummaryLine(HEADING.outbound),
                inbound: _buildSummaryLine(HEADING.inbound),
                answered: _buildSummaryLine(HEADING.answered),
                missed: _buildSummaryLine(HEADING.missed, true),
                system: _buildSummaryLine(HEADING.system),
            };

            const setSummaryTotals = () => {
                if (!_summaryTotalData()) {
                    return;
                }

                if (_searchByFilter()) {
                    _setSearchByFilter();
                }

                const summaryTotalRecord = _summaryTotalData();
                const totals = {
                    answeredCount: _summaryTotalRecordView.answeredCount(summaryTotalRecord),
                    inboundCount: _summaryTotalRecordView.inboundCount(summaryTotalRecord),
                    missedCount: _summaryTotalRecordView.missedCount(summaryTotalRecord),
                    outboundCount: _summaryTotalRecordView.outboundCount(summaryTotalRecord),
                    systemCount: _summaryTotalRecordView.systemCount(summaryTotalRecord),

                    answeredSeconds: _summaryTotalRecordView.answeredSeconds(summaryTotalRecord),
                    inboundSeconds: _summaryTotalRecordView.inboundSeconds(summaryTotalRecord),
                    missedSeconds: _summaryTotalRecordView.missedSeconds(summaryTotalRecord),
                    outboundSeconds: _summaryTotalRecordView.outboundSeconds(summaryTotalRecord),
                    systemSeconds: _summaryTotalRecordView.systemSeconds(summaryTotalRecord),
                };

                const totalCallCount = totals.inboundCount + totals.outboundCount;
                self.totalCalls.calls.value(totalCallCount);
                self.totalCalls.answered.value(totals.answeredCount);
                self.totalCalls.inbound.value(totals.inboundCount);
                self.totalCalls.missed.value(totals.missedCount);
                self.totalCalls.outbound.value(totals.outboundCount);
                self.totalCalls.system.value(totals.systemCount);

                const totalSeconds = totals.inboundSeconds + totals.outboundSeconds;
                self.totalMinutes.answered.value(totals.answeredSeconds);
                self.totalMinutes.inbound.value(totals.inboundSeconds);
                self.totalMinutes.minutes.value(totalSeconds);
                self.totalMinutes.missed.value(totals.missedSeconds);
                self.totalMinutes.outbound.value(totals.outboundSeconds);
                self.totalMinutes.system.value(totals.systemSeconds);
            };

            const _setSearchByFilter = () => {
                self.searchByFilter(_searchByFilter());
            };

            self.activate = (activationObject) => {
                _summaryTotalData = activationObject.chartData.summaryTotalData;
                _disposables.push(_summaryTotalData.subscribe(setSummaryTotals));

                self.showLoadingState = activationObject.showLoadingState;
                self.showEmptyState = activationObject.showEmptyState;
                _searchByFilter = activationObject.parentViewModel().sidebarPane().selectedShowFilter;
                self.searchByFilter(_searchByFilter());

                return _initialize();
            };
            self.detached = () => {
                _disposables.forEach(d => d.dispose());
                _disposables = [];
            };

            const _initialize = () => {
                setSummaryTotals();
                return _promiseFactory.wait();
            };
        };
    }
);

