define('businessServices/specifications/validAuthorizedSignatureSpecification',[], function() {
    return function() {
        const self = this;

        self.isSatisfiedBy = (authorizedSignature) => {
            if (authorizedSignature) {
                // TODO: Implement method
                // Per Austin - validation requirement “a string of two of more letters separated by a space followed
                // by a string of two of more letters”
                return true;
            }
            return false;
        };
    };
});

