define('common/collections/enumerationValidator',[], function() {
    return {
        isEnumerationValueValid : function(enumeration, value) {
            for (var propertyKey in enumeration) {
                if (enumeration.hasOwnProperty(propertyKey)) {
                    var propertyValue = enumeration[propertyKey];
                    if (propertyValue === value) {
                        return true;
                    }
                }
            }
            return false;
        },
        isEnumerationKeyPresent : function(enumeration, key) {
            return enumeration.hasOwnProperty(key);
        }
    };
});
