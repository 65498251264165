define('common/presentation/i18nResources',[],
    function() {
        const shared = {
            enUS: {
                add: "Add",
                addPhoneNumber: "Add Phone Number",
                areaCodeInvalid: "Please enter a valid area code.",
                areaCodeRequired: "Please enter an area code.",
                at: "at",
                back: 'Back',
                blocked: 'Blocked',
                cancel: 'Cancel',
                campaignRegistry: "Campaign Registry",
                theCampaignRegistry: "The Campaign Registry",
                close: 'Close',
                comingSoon: 'Coming Soon',
                continue: "Continue",
                create: "Create",
                customerServiceNumberFormatted: "+1 (844) 2-TRESTA",
                default: 'Default',
                delete: 'Delete',
                deviceNameRequired: "Name is required.",
                deviceNameUnique: "This name is already used by another device.",
                devicePhoneNumberUnique: "This number is already in use by another user.",
                devicePhoneNumberUniqueToUser: "This phone number is already registered as a device.",
                discard: "Discard",
                edit: "Edit",
                emailAddressInvalid: "Please enter a valid email address.",
                emailAddressNotFound: "The email address you entered is not associated with a Tresta account.",
                emailAddressRequired: "Please enter an email address.",
                emailAddressUnique: "Sorry, the email address you entered is already in use. Please try again.",
                error: "Error",
                extensionAlreadyInUse: "Sorry, this extension is already in use.",
                extensionInvalid: "Please select an extension.",
                fileTooBig: "The selected audio file is too large. File size cannot exceed 4 MB.",
                firstNameIsRequired: "Please enter a first name.",
                invalidAudioFile: "The selected file is not a valid audio file. Valid file types are MP3, WAV, WMA, and M4A.",
                lastNameIsRequired: "Please enter a last name.",
                next: "Next",
                none: "[None]",
                noPermission: "You don't have permissions to do this.",
                noResultsFound: "No Results Found",
                off: "Off",
                ok: "OK",
                on: "On",
                passwordConfirmRequired: "Please re-enter password.",
                passwordDoesNotMatch: "Passwords do not match.",
                passwordIncorrect: "Incorrect password. Please try again.",
                passwordInvalid: "Password must contain 8-32 characters, 1 upper case letter, 1 lower case letter & 1 number.",
                passwordRequired: "Please enter a password.",
                phoneNumber: "Phone Number",
                phoneNumberInvalid: "Please enter a valid phone number.",
                phoneNumberRequired: "Please enter a phone number.",
                phoneNumberRequiredAtLeastOne: "Please enter a phone number. At least one phone number is required.",
                pleaseSelect: "Please select...",
                postalCodeInvalid: "Please enter a valid postal code.",
                postalCodeRequired: "Please enter a postal code.",
                pressOneToAccept: "Require the callee to \"Press 1 to Accept\"?",
                pressOneToAcceptWarningMessage: "<strong>Important!</strong> If you turn this prompt off, Tresta will not be able to tell the difference between a live person that’s answered and an automated system that’s answered." +
                    "<br><br><strong>The practical implication is this…</strong><br>" +
                    "When this feature is turned off and calls are answered by an automated system (like a cell phone’s voicemail for example), any additional actions that may have followed will not be reached. ",
                processing: "Processing...",
                repeating: 'Repeating',
                regionType: {
                    province: 'Province',
                    state: 'State',
                },
                restrictedPhoneNumber: "Must be a US or Canadian phone number.",
                restrictedPhoneNumber_us: "This doesn’t seem to be a valid US phone number.",
                restrictedPhoneNumber_ca: "This doesn’t seem to be a valid Canadian phone number.",
                save: "Save",
                search: "Search",
                sortBy: "Sort By",
                sortOptions: {
                    collapseAll: 'Collapse All',
                    emailAddress: 'Email Address',
                    expandAll: 'Expand All',
                    name: 'Name',
                    phoneNumber: 'Phone Number',
                    phoneNumber_narrow: 'Number',
                    phoneNumberName: 'Phone Name',
                    phoneNumberName_narrow: 'Name',
                    recentlyAdded: 'Recently Added',
                    recentlyAdded_narrow: 'Added',
                    recentlyModified: 'Recently Modified',
                    recentlyModified_narrow: 'Modified',
                },
                submit: "Submit",
                success: "Success",
                supportEmail: "support@tresta.com",
                titleIsRequired: "Please enter a title.",
                vanity: 'Vanity',
                verificationAnswerRequired: "Verification answer is required.",
                zipCodeInvalid: "Please enter a valid ZIP code.",
                zipCodeRequired: "Please enter a ZIP code.",
            }
        };

        const sharedMessagingCompliance = {
            enUS: {
                exampleFourDescriptionOne: "Example 4: ",
                exampleFourDescriptionTwo: "Please call me when you get a minute to go over your order.",
                exampleNote: "Note - do not copy and paste these messages verbatim or your account may fail compliance validation.",
                exampleOneDescriptionOne: "Example 1: ",
                exampleOneDescriptionTwo: "Hi Bob. I got your message and will give you a call back this afternoon.",
                exampleThreeDescriptionOne: "Example 3: ",
                exampleThreeDescriptionTwo: "My office is located at 123 Main Street. You can come by anytime M-F between 9 AM and 5 PM.",
                exampleTwoDescriptionOne: "Example 2: ",
                exampleTwoDescriptionTwo: "Hi {Customer Name}. I would be happy to provide a quote for my services. When would be a good time to meet?",
                helpAutoResponseInvalid:  "Requires a minimum of 20 characters.",
                helpAutoResponseRequired: "Help auto response is required.",
                jobTitleRequired: "Job Title required.",
                labelAccountNotifications: "Account Notifications",
                labelAppointmentReminders: "Appointment Reminders",
                labelConversationMessaging: "Conversation Messaging",
                labelCustomerConsentEmployeeRelationship: "Customers build relationships with one or more of our employees and express a desire to communicate by text for convenience.",
                labelCustomerConsentInitiateConversation: "Customers initiate a conversation by texting our business and we reply.",
                labelCustomerConsentLeadCaptureForms: "Customers provide their phone number and consent to receive messages via a form on our website.",
                labelCustomerConsentOnlineCheckout: "Customers provide their phone number and consent to receive messages during online checkout.",
                labelCustomerConsentOther: "Other",
                labelCustomerConsentOtherInvalid:  "Requires a minimum of 40 characters.",
                labelCustomerConsentOtherRequired: "Other is required.",
                labelCustomerConsentPosTransaction: "Customers provide their phone number and consent to receive messages via POS transactions.",
                labelDeliveryStatusUpdates: "Delivery Status Updates",
                labelEmailAddress: "Email Address",
                labelGeneralMarketing: "General Marketing",
                labelHelpAutoResponse: "Help Messaging Auto Response",
                labelHelpAutoResponseDescription: "If recipients reply with HELP, Tresta will send the following auto response. You may edit a portion of the message to suit your business, but please note that you will fail compliance verification if you do not include an email or phone number that you can be reached at.",
                labelHelpPlaceholder: "For help, email support@company.com. To opt-out, reply STOP",
                labelHelpResponse: "Help Response",
                labelHowManyTextsPerDay: "How many texts do you anticipate sending per day?",
                labelJobTitle: "Job Title",
                labelMonthYearFounded: "Month/Year Founded",
                labelPercentOfTextsToCanada: "Do you anticipate >50% texts sent to Canadian phone numbers?",
                labelPointOfContact: "Point of Contact for any Compliance or Fraud Inquiries",
                labelPointOfContactAddress: "Point of Contact Address",
                labelPointOfContactCity: "Point of Contact City",
                labelPointOfContactEmailAddress: "Point of Contact Email Address",
                labelPointOfContactFirstName: "Point of Contact First Name",
                labelPointOfContactJobTitle: "Point of Contact Job Title",
                labelPointOfContactLastName: "Point of Contact Last Name",
                labelPointOfContactPhoneNumber: "Point of Contact Phone Number",
                labelPointOfContactPostalCode: "Point of Contact Postal Code",
                labelPointOfContactProvince: "Point of Contact Province",
                labelPointOfContactState: "Point of Contact State",
                labelPointOfContactZipCode: "Point of Contact Zip",
                labelPollingAndVoting: "Polling and Voting",
                labelSecurityOrSystemAlerts: "Security or System Alerts",
                labelSmsUseEligibility: "I understand that I cannot use Messaging for the following:",
                labelStopAutoResponse: "Stop, Quit, or Unsubscribe Messaging Auto Response",
                labelStopAutoResponseDescriptionOne: "If recipients reply with STOP, QUIT or UNSUBSCRIBE, Tresta will send the following auto response and will add the recipient’s phone number to the opt-out list for the Tresta number that was being used to text them.",
                labelStopAutoResponseDescriptionTwo: "You may edit a portion of the message to suit your business, but please note that you will fail compliance verification if you do not include an email or phone number that you can be reached at.",
                labelStopPlaceholder: "You have opted out and will not receive any further messages from this number",
                labelStopResponse: "Opt Out Response",
                labelSummaryOfTexting: "Summary of Texting",
                labelTextMessageSampleFour: "Sample 4",
                labelTextMessageSampleOne: "Sample 1",
                labelTextMessageSampleThree: "Sample 3",
                labelTextMessageSampleTwo: "Sample 2",
                labelTextMessagingAutoResponses: "Text Messaging Auto Responses",
                labelTextMessagingBadExampleOne: "Bad Example: ",
                labelTextMessagingBadExampleTwo: "Text messages are used for our team members to communicate with our customers and partners.",
                labelTextMessagingBadExampleWhyOne: "Why is it bad? ",
                labelTextMessagingBadExampleWhyTwo: "It doesn’t say who you are or what you want to communicate.",
                labelTextMessagingCompliance: "Text Messaging Compliance",
                labelTextMessagingConsent: "Text Messaging Consent",
                labelTextMessagingConsentDescription: "How are your recipients providing their numbers and consenting to receive text messages from your business? Select all that apply.",
                labelTextMessagingDelivery: "Text Messaging Delivery",
                labelTextMessagingExamples: "Text Messaging Examples",
                labelTextMessagingGoodExampleOne: "Good Example: ",
                labelTextMessagingGoodExampleTwo: "Messages aimed at customers of a car dealership service center.  Appointment reminders, repair updates, satisfaction follow-up, online bill payment, and 2-way conversations.",
                labelTextMessagingIneligibleUseCases: "Ineligible Use Cases",
                labelTextMessagingPurpose: "Text Messaging Purpose",
                labelTextMessagingPurposeDescription: "Provide a brief description that summarizes how your business will be texting. The description must touch on a) who you are, b) who you will be texting and c) why you are texting them. ",
                labelTypesOfTextMessaging: "What type of text messages will you be sending? Select all that apply.",
                labelWebsiteUrl: "Website URL",
                majorityOfMessagesSentToCanadaRequired: "A Selection is required.",
                messagingTextsPerDayRequired: "A Selection is required.",
                noPlaceholderEmail: "You must include your company’s email or phone number.",
                pleaseSpecify: "Please specify",
                replyStop: "Reply STOP to opt-out.",
                sampleTextMessageInvalid: "Requires a minimum of 20 characters.",
                sampleTextMessageRegulatoryNotice: "Important Note About Regulatory Approval",
                sampleTextMessageRequired: "Sample Message is required.",
                smsIneligibleUseCaseFive: "General:  ",
                smsIneligibleUseCaseFiveExamples: "Phishing, Fraud or scams, Deceptive marketing, Pornography, Sex-related content, Profanity or hate speech, Firearms",
                smsIneligibleUseCaseFour: "Illegal Substances/ Activities: ",
                smsIneligibleUseCaseFourExamples: "Cannabis, Alcohol, Tobacco or vape",
                smsIneligibleUseCaseOne: "High-Risk Financial Services: ",
                smsIneligibleUseCaseOneExamples: "Payday loans, Short-term, high-interest loans, Auto loans, Mortgage loans, Student loans, Gambling, Sweepstakes, Stock alerts, Cryptocurrency",
                smsIneligibleUseCaseThree: "Debt Forgiveness: ",
                smsIneligibleUseCaseThreeExamples: "Work from home programs, Risk investment opportunities, Debt collection or consolidation",
                smsIneligibleUseCaseTwo: "Get Rich Quick Schemes:  ",
                smsIneligibleUseCaseTwoExamples: "Debt consolidation, Debt reduction, Credit repair programs, Deceptive work from home programs, Multi-level marketing",
                smsCaseEligibilityRequired: "Agreement is required.",
                stopAutoResponseInvalid:  "Requires a minimum of 20 characters.",
                stopAutoResponseRequired: "Stop, Quit, or Unsubscribe auto response is required.",
                summaryOfTextingInvalid: "Requires a minimum of 40 characters.",
                summaryOfTextingRequired: "Summary of Texting is required.",
                textMessagingComplianceRequired: "Please select type of text messages you will be sending.",
                textMessagingConsentRequired: "Please select how your recipients are consenting to receive text messages.",
                textMessagingDescription: "Provide 4 sample messages of the types of message you may send",
                useCaseEligibilityRequired: "Agreement is required.",
                weAppendOptOut: "When we submit your sample messages to the regulatory entity that approves new brand messaging requests, we are required to append your Opt Out message to the end of one of your sample messages. For example, your Sample 1 message will actually be presented to the regulatory entity as:",
                websiteDescription: "Wireless carriers require a website presence in order to verify your identity and permit messaging to their customers. Please provide the url of your business website, such as www.tresta.com. If you do not have a business website, you may use a social media URL instead, for example, www.facebook.com/Tresta.  If you do not have a business website or social media profile, you may provide a personal social media profile, but Tresta cannot guarantee that it will be accepted/approved for messaging by the wireless carriers.",
                websiteUrlInvalid: "Please enter a valid URL.",
                websiteUrlRequired: "Website URL is required.",
                weWillNotSendOptOut: "However, when  you actually go to send messages we will NOT automatically append your Opt Out instructions to messages; it is your responsibility to include your Opt Out instructions in messages that require it."
            }
        };

        return {
            "en-US": {
                "common": {
                    "accountNumber": "Account Number",
                    "all-users": "All Users",
                    "allUsers": "All Users",
                    "analytics": "Analytics",
                    "autoAttendants": "Auto Attendants",
                    "billing-admins": "Billing Admins",
                    "billingAdmin": "Billing Admins",
                    "billingAndSystemAdmin": "Billing Admins, System Admins",
                    "blocked": "Blocked",
                    "callRecordings": "Call Recordings",
                    "helpCenter": "Help Center",
                    "none": "None",
                    "okay": "Okay",
                    "tollFree": "Toll-Free",
                    "unknownLocation": "Unknown Location",
                    "settings": "Settings",
                    "system": "System",
                    "system-admins": "System Admins",
                    "systemAdmin": "System Admins",
                    "userGroups": "User Groups",
                    "voicemail": "Voicemail",
                    "region": {
                        "default": "Region",
                        "US": "State",
                        "CA": "Region"
                    },
                    areaCodeInvalid: "Please enter a valid area code.",
                    areaCodeRequired: "Please enter an area code.",
                    back: shared.enUS.back,
                    cancel: shared.enUS.cancel,
                    comingSoon: 'Coming Soon',
                    continue: "Continue",
                    copied: "Copied!",
                    customerServiceNumberFormatted: shared.enUS.customerServiceNumberFormatted,
                    deviceNameRequired: "Name is required.",
                    deviceNameUnique: "This name is already used by another device.",
                    devicePhoneNumberUnique: "This number is already in use by another user.",
                    devicePhoneNumberUniqueToUser: "This phone number is already registered as a device.",
                    discard: "Discard",
                    dismiss: "Dismiss",
                    edit: "Edit",
                    emailAddressInvalid: "Please enter a valid email address.",
                    emailAddressNotFound: "The email address you entered is not associated with a Tresta account.",
                    emailAddressRequired: "Email address is required.",
                    emailAddressUnique: "This email address is already in use by another Tresta user.",
                    extensionAlreadyInUse: "Sorry, this extension is already in use.",
                    extensionInvalid: "Please select an extension.",
                    fileTooBig: "Sorry, the selected audio file is too large. File size cannot exceed 4 MB. Please try again.",
                    firstNameIsRequired: "First name is required.",
                    invalidAudioFile: "Sorry, the selected file is not a valid audio file. Valid file types are MP3, WAV, WMA, and M4A and cannot exceed 4 MB.",
                    lastNameIsRequired: "Last name is required.",
                    needHelp: "Need Help? Call",
                    newAudioFile: "+ New Audio File",
                    next: shared.enUS.next,
                    noPermission: "You don't have permissions to do this.",
                    noResults: "No results found",
                    off: shared.enUS.off,
                    ok: shared.enUS.ok,
                    on: shared.enUS.on,
                    passwordConfirmRequired: "Please re-enter your password.",
                    passwordDoesNotMatch: "The passwords you entered do not match.",
                    passwordIncorrect: "Incorrect password. Please try again.",
                    passwordInvalid: "The password you entered is incorrect.",
                    passwordRequired: "Please enter a password.",
                    phoneNumberInvalid: "Please enter a valid phone number.",
                    phoneNumberRequired: "Please enter a phone number.",
                    phoneNumberRequiredAtLeastOne: "Please enter a phone number. At least one phone number is required.",
                    pleaseSelect: shared.enUS.pleaseSelect,
                    postalCodeInvalid: "Please enter a valid postal code.",
                    postalCodeRequired: "Please enter a postal code.",
                    processing: shared.enUS.processing,
                    restrictedPhoneNumber: "Please enter a US or Canadian Phone Number.",
                    restrictedPhoneNumber_us: "This doesn't seem to be a valid US phone number.",
                    restrictedPhoneNumber_ca: "This doesn't seem to be a valid Canadian phone number.",
                    search: shared.enUS.search,
                    selectACountry: "Select a country...",
                    shortCode: "Short Code",
                    signOut: "Sign Out",
                    sortBy: shared.enUS.sortBy,
                    sortOptions: shared.enUS.sortOptions,
                    submit: shared.enUS.submit,
                    supportEmail: shared.enUS.supportEmail,
                    titleIsRequired: "Please enter a title.",
                    verificationAnswerRequired: "Please enter a verification answer.",
                    zipCodeInvalid: "Please enter a valid ZIP code.",
                    zipCodeRequired: "Please enter a ZIP code.",
                },
                aboutUser: {
                    aboutYourUser: "About Your User",
                    admins: "Admins",
                    autoAttendantsRouted: "The following auto attendants may route calls to you:",
                    english: "English",
                    extensions: "Extensions",
                    extensionAssignments: "The following extension has been assigned to your user",
                    french: "French",
                    phoneNumber: "Phone Number",
                    phoneNumbersRouted: "The following phone number is routed to your user:",
                    phoneNumbersRouted_plural: "The following phone numbers are routed to your user:",
                    requestChangesTalk: "To request changes, talk to one of your account's admins:",
                    userGroupMemberships: "You are a member of the following user groups:",
                },
                accountCreationInProgress: {
                    headerText: "Welcome to Tresta",
                    configuringAccount: "We're still configuring your account for first-time use...",
                    contactUs: "If you're still seeing this message after 2-3 minutes, <br /> please contact customer service at +1 (844) 2-TRESTA. "
                },
                appEntryShell: {
                    helpMessage: "Need help? Call ",
                    warningModalHeading: "Are you sure?",
                    warningModalBodyText: "If you exit signup, you may not be able to get the same phone number when you return.",
                    warningModalDecline: "No, Continue Signup",
                    warningModalConfirm: "Yes, Exit Signup"
                },
                acceptUserInvite: {
                    accountClosed: "Account Closed",
                    accountClosedMessage: "Sorry, the Tresta account that you were invited to has been closed. Please contact your company's system administrator to find out why the account was closed or, if you need assistance from Tresta customer service, call {{customerServicePhoneNumber}}.",
                    back: shared.enUS.back,
                    confirmPassword: "Confirm Password",
                    confirmPasswordRequired: "Please re-enter your password.",
                    firstName: "First Name",
                    firstNameRequired: "First name is required.",
                    firstSetUp: "First let’s set up your user profile.",
                    jobTitle: "Job Title",
                    lastName: "Last Name",
                    lastNameRequired: "Last name is required.",
                    mobile: "Mobile",
                    mobileUnsupportedTitle: "Sorry! You can't accept this invite on a mobile device.",
                    mobileUnsupportedMessage: "Accept the invite on your desktop or laptop computer. Then, after creating your account, download the mobile app to use Tresta on your phone.",
                    needHelp: "Need Help? Call&nbsp;",
                    next: shared.enUS.next,
                    nextPassword: "Next let’s add your password.",
                    nowDevice: "And now let’s link your profile to your mobile phone.",
                    password: "Password",
                    passwordInvalid: "Password must contain 8-32 characters, 1 upper case letter, 1 lower case letter & 1 number.",
                    passwordPlaceholder: "8-32 characters, 1 upper case, 1 lower case, and 1 number",
                    passwordRequired: "Please enter a password.",
                    phoneNumberRequired: "Mobile phone number is required.",
                    done: "Done",
                    timeZone: "Time Zone",
                    userSetup: "User Setup",
                    verificationQuestion: "Verification Question",
                    verificationAnswer: "Verification Answer",
                    whenYouReceiveACall: "When you receive a Tresta call, we’ll forward it here.",
                    yourMobilePhoneNumber: "Your Mobile Phone Number"
                },
                invalidLink: {
                    expired: "Expired Link",
                    expiredMessage: "Sorry, this invite link has expired. Please contact your company's system administrator to request a new invite, or if you need assistance from Tresta customer service, call {{customerServicePhoneNumber}}.",
                    invalid: "Invalid Link",
                    invalidMessage: "We're sorry, but this invite is invalid. If you copied the link from your email, please verify that you copied the entire URL. If you feel that you have reached this message in error, please contact your system administrator to receive another invite."
                },
                analytics: {
                    analytics: 'Analytics',
                    calls: 'Calls',
                    day: 'Day',
                    hour: 'Hour',
                    inbound: 'Inbound',
                    month: 'Month',
                    noResultsFound: shared.enUS.noResultsFound,
                    outbound: 'Outbound',
                    stats: 'Stats',
                    total: 'Total',
                    week: 'Week',
                    nonAdminWarning: "Your analytics reporting is limited to calls that you placed or received - you don't have permissions to report on call data associated with other Tresta users. If you need to report on calls placed or received by other users, please have a System Admin add you to the System Admins group.",
                },
                analyticsList: {
                    answered: 'Answered',
                    areYouSureYouWantToDeleteThis_recording: "Are you sure you want to delete this Call Recording?",
                    areYouSureYouWantToDeleteThis_voicemail: "Are you sure you want to delete this Voicemail?",
                    blocked: 'Blocked',
                    callRecording: 'Call Recording',
                    cancel: shared.enUS.cancel,
                    date: 'Date',
                    delete: "Delete",
                    deleted: 'Deleted {{date}}',
                    deletedRecording: 'This call recording has been deleted and is no longer available to play.',
                    deletedVoicemail: 'This voicemail has been deleted and is no longer available to play.',
                    delete_recording: "Delete Call Recording",
                    delete_voicemail: "Delete Voicemail",
                    deleteThis_recording: "Delete this Call Recording",
                    deleteThis_voicemail: "Delete this Voicemail",
                    details: 'Details',
                    download: 'Download',
                    duration: 'Duration',
                    from: "From",
                    missed: 'Missed',
                    na: 'N/A',
                    noResults: shared.enUS.noResultsFound,
                    noResultsFound: shared.enUS.noResultsFound,
                    notRecordingSubscriber: "You don't have the required permissions to play this call recording.",
                    notSubscribed: 'Not Subscribed',
                    notVoicemailSubscriber: "You don't have the required permissions to play this voicemail.",
                    new: 'Mark as Reviewed',
                    outbound: 'Outbound',
                    reviewed: 'Mark As New',
                    system: 'System',
                    to: 'To',
                    type: 'Type',
                    voicemail: 'Voicemail',
                },
                analyticsSidebar: {
                    all: "All",
                    anyNumber: 'Any Number',
                    anyUserOrGroup: 'Any User or Group',
                    calls: 'Calls',
                    external: "External",
                    filters: "Filters",
                    inbound: 'Inbound',
                    internal: "Internal",
                    minutes: "Minutes",
                    month: 'Month',
                    outbound: 'Outbound',
                    refresh: 'Refresh',
                    selectNumbers: 'Select Numbers',
                    selectUsersOrGroups: 'Select Users or Groups',
                    total: 'Total',
                    callTypeTooltip: "Analytics only shows calls that <br/> were routed through a phone number. User <br/> to user calls will be added in a future update.",
                    comingSoonTooltip: 'Coming Soon - report on <br/> internal user to user calls.',
                    show: 'Show',
                    connected: 'Phone Numbers',
                    involving: 'Users and Groups',
                },
                analyticsTotals: {
                    answered: 'Answered',
                    inbound: 'Inbound',
                    minutes: 'Minutes',
                    missed: 'Missed',
                    outbound: 'Outbound',
                    system: 'System',
                },
                analyticsSummary: {
                    answered: 'Answered',
                    answeredMinified: 'Answered',
                    averageDuration: 'Average',
                    averageDurationMinified: 'Avg',
                    downloadCsv: 'Download CSV',
                    forwardingNumber: 'Forwarding Number',
                    forwardingNumberMinified: 'Forwarding Number',
                    group: 'Group',
                    hostedNumber: 'Phone Number',
                    hostedNumberMinified: 'Phone Number',
                    inbound: 'Inbound',
                    inboundMinified: 'In',
                    minutes: 'Minutes',
                    minutesMinified: 'Minutes',
                    missed: 'Missed',
                    missedMinified: 'Missed',
                    noResults: shared.enUS.noResultsFound,
                    noResults_user: 'There are no results for Users.',
                    noResults_userGroup: 'There are no results for User Groups.',
                    noResults_forwardingNumber: 'There are no results for Forwarding Numbers.',
                    noResults_hostedNumber: 'There are no results for Phone Numbers.',
                    outbound: 'Outbound',
                    outboundMinified: 'Out',
                    phoneNumber: 'Phone Number',
                    phoneNumberMinified: 'Phone Number',
                    summary: "Summary",
                    system: 'System',
                    total: 'Total',
                    totalCalls: 'Calls',
                    totalCallsMinified: 'Calls',
                    totalDuration: 'Duration',
                    totalDurationMinified: 'Duration',
                    user: 'User',
                    userMinified: 'User',
                    userGroup: 'Group',
                    userGroupMinified: 'Group',
                    voicemails: 'Inbox',
                    voicemailsMinified: 'Inbox',
                },
                filterOptions: {
                    phoneNumber: 'Phone Number',
                    show: 'Show',
                    sortBy: 'Sort By',
                    all: 'All',
                    new: 'New',
                    reviewed: 'Reviewed',
                    invalidTooltip: 'At least one phone number is required'
                },
                forgotPassword: {
                    buttonText: "Send Password Reset Email",
                    customerServiceMessage: "If you don't receive it in the next few minutes, please check your spam folder, and if you still don't see it, call us at " + shared.enUS.customerServiceNumberFormatted,
                    emailAddress: "Email Address",
                    emailAddressRequired: shared.enUS.emailAddressRequired,
                    emailAddressInvalid: shared.enUS.emailAddressInvalid,
                    emailAddressUnique: shared.enUS.emailAddressNotFound,
                    emailAddressNotRegistered: shared.enUS.emailAddressNotFound,
                    emailAddressInviteCanceled: shared.enUS.emailAddressNotFound,
                    expiredLinkHeader: "Password reset expired",
                    expiredLinkMessage: "Enter your account email below and we will send you a link to create a new password.",
                    header: "Forgot Your Password?",
                    needHelp: "Need Help? Call&nbsp;",
                    message: "Enter your account email below and we will send you a link to create a new password.",
                    mobileUnsupportedTitle: "Sorry! You can’t reset your password on a mobile device.",
                    mobileUnsupportedMessage: "Use your desktop or laptop computer.",
                    resetConfirmation: "We've sent a password reset email to",
                    signInLink: "Sign In",
                    userDeactivated: "Your login has been deactivated. Please contact your System Admin or call us at +1 (844) 2-TRESTA."
                },
                login: {
                    buttonText: "Sign In",
                    dontHaveAnAccount: "Don't have an account yet?&nbsp;",
                    downloadTheApp: "Download The App",
                    startFreeTrial: "Start a Free Trial",
                    emailAddress: "Email Address",
                    emailAddressRequired: shared.enUS.emailAddressRequired,
                    emailAddressInvalid: shared.enUS.emailAddressInvalid,
                    forgotPassword: "Forgot Password",
                    greeting: "Sign In",
                    inviteExpired: "Your invite was never accepted and is now expired.",
                    invitePending: "You must accept your invite before you can sign in. Resend Invite",
                    message: "Sign in to your account.",
                    mobileUnsupportedTitle: "Sorry! You can’t sign in here. We’re working on it. ",
                    mobileUnsupportedMessage: "You must be on a desktop or laptop computer to use the web app. Alternatively, you can download the mobile app to use Tresta on your phone.",
                    needHelp: "Need Help? Call&nbsp;",
                    password: "Password",
                    passwordInvalid: "The password you entered is incorrect.",
                    passwordRequired: "Please enter a password.",
                    userDeactivated: "This user has been deactivated.",
                    usernameInvalid: "The email address you entered is not associated with a Tresta account.",
                },
                accountClosed: {
                    accountClosed: "Your account has been closed.",
                    finalInvoiceMessage: "You will receive a final pro-rated statement by email within the next 24 hours. Your credit card will be automatically charged 5 days after the final statement is generated.",
                    needHelp: "Need Help? Call&nbsp;",
                    signInLink: "Sign In",
                    sorryToSeeYouGo: "If you ever need to reopen your account, you can do so by signing in to the web app and following the prompts, or call " + shared.enUS.customerServiceNumberFormatted + " and a member of our Customer Service team will be happy to assist you.",
                },
                accountClosedAndCannotReactivate: {
                    headerTitle: "Account Closed",
                    warningTitle: "Contact your company's billing admin.",
                    warningMessage: "Your company's Tresta account is closed. You're not a billing admin, so if you'd like to reactivate, you'll need to ask one of your company's billing admins to do so."
                },
                accountClosedDoNotReactivate: {
                    headerTitle: "Account Closed",
                    warningTitle: "Your account is closed and cannot be reactivated."
                },
                accountClosedBalanceOwed: {
                    headerTitle: "Past Due Balance",
                },
                miscProblems: {
                    headerTitle: "Security Hold",
                    warningTitle: "Your account has been flagged for a possible security violation."
                },
                payBalance: {
                    balancePaid: "Balance Paid",
                    balancePaidMessage: "Thanks, your payment of {{paymentAmount}} has been received.",
                    customerAgreesToPayAcknowledgement: "I understand that {{pastDueBalance}} will be charged to the payment method above.",
                    headerTitle: "Past Due Balance",
                    newCard: "New Card",
                    payLater: "I’ll pay this later >",
                    payBalance: "Pay Balance",
                    paymentMethod: "Payment Method",
                    agreementRequired: "You must acknowledge that you understand to continue.",
                    warningTitle: "Your account has a past due balance of {{pastDueBalance}}.",
                    warningMessage: "Please check the box below to pay the balance due.",
                    warningMessageAutoClose: "If the balance is not paid by {{- autoCloseDate}}, your account will be closed.",
                    declinedTitle: "Payment failed",
                    declinedMessage: "Verify the details above and try again.",
                    genericFailureTitle: "We’ve encountered an issue",
                    genericFailureMessage: "Please call " + shared.enUS.customerServiceNumberFormatted + " for assistance processing your payment."
                },
                reactivation: {
                    customerAgreesToPayAcknowledgement: "I understand that {{pastDueBalance}} will be charged to the payment method above.",
                    customerAgreesToTermsOfUse_part1: "I agree to Tresta's ",
                    customerAgreesToTermsOfUse_part2: "terms of use.",
                    headerTitle: "Reactivate Account",
                    newCard: "New Card",
                    paymentMethod: "Payment Method",
                    reactivateAccount: "Reactivate Account",
                    payBalance: "Pay Balance",
                    payBalanceAndReactivateAccount: "Pay & Reactivate Account",
                    payBalanceAndKeepAccountClosed: "Pay & Keep Account Closed",
                    agreementRequired: "You must agree to continue.",
                    acknowledgementRequired: "You must acknowledge that you understand to continue.",
                    warningTitle: "Your account is closed.",
                    warningTitlePastDueBalance: "Your account is closed and you have a past due balance of {{pastDueBalance}}.",
                    warningMessage: "To reactivate, provide a method of payment.",
                    warningMessageCannotReactivate: "To continue, provide a method of payment.",
                    declinedTitle: "Payment failed",
                    declinedMessage: "Verify the details above and try again.",
                    genericFailureTitle: "We’ve encountered an issue",
                    genericFailureMessage: "Please call " + shared.enUS.customerServiceNumberFormatted + " for assistance processing your payment."
                },
                resendInvite: {
                    headerTitle: "Invite Sent",
                    dismiss: "Dismiss",
                    inviteSentMessage: "A new invite email has been sent. It expires {{ expirationDate }}.",
                },
                addPaymentMethodModal: {
                    nameOnCard: "Name on Card",
                    nameOnCardRequired: "Name on card is required.",
                    cardNumber: "Card Number",
                    cardNumberInvalid: "Invalid card number.",
                    cardNumberRequired: "Card number is required.",
                    country: "Country",
                    countryInvalid: "Please select a valid country.",
                    cvv: "Card Verification Code (CVV)",
                    cvvInvalid: "Invalid CVV",
                    cvvRequiredToAdd: "CVV is required.",
                    duplicateCard: "This card is already on your account.",
                    expirationDate: "Expiration Date",
                    expirationDateInvalid: "Invalid expiration date.",
                    noSpecialCharacters: "Special characters are not allowed.",
                    postalCode: "Postal Code",
                    postalCodeInvalid: "Invalid postal code.",
                    postalCodeRequired: "Postal code is required.",
                    zipCode: "Zip Code",
                    zipCodeInvalid: "Invalid zip code.",
                    zipCodeRequired: "Zip code is required.",
                },
                addPhoneNumber: {
                    invalidVanityCharacter: "Search string can only contain letters and numbers.",
                    invalidLocalVanityLength: "Please enter at least 3 characters.",
                    invalidTollVanityLength: "Please enter at least 4 characters.",
                    pricingPage: "pricing page"
                },
                addPhoneNumberTypeChooser: {
                    addPhoneNumber: shared.enUS.addPhoneNumber,
                    cancel: shared.enUS.cancel,
                    local: "Local",
                    transfer: "Transfer",
                    tollFree: "Toll-Free",
                    whatTypeNumber: 'What type of number do you want to add?',
                },
                addPhoneNumberLocal: {
                    addPhoneNumber: "Add a Local Phone Number",
                    add: shared.enUS.add,
                    back: shared.enUS.back,
                    cancel: shared.enUS.cancel,
                    default: shared.enUS.default,
                    mustSelectAPhoneNumber: "Please select at least one phone number.",
                    pricingMessage: "You'll be charged a monthly fee for each additional phone number. For details, please visit our ",
                    moreComplianceInfoNeeded: {
                        title: "Local phone number messaging limit reached.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the ",
                        warningLink1: shared.enUS.campaignRegistry,
                        warningPart2: " before they can be used for text messaging. To register more than 1 local phone number, you must have an EIN or Registry ID on file.",
                        warningPart3: "To enable messaging on more than 1 local phone number, update your ",
                        warningLink2: "Messaging Compliance",
                        warningPart4: " to include your EIN or Registry ID.",
                        warningPart5: "Alternatively, you can enable messaging on a new phone number if you disable messaging on one of these local phone numbers:",
                    },
                    personalAccountLimitReached: {
                        title: "Local phone number messaging limit reached.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the ",
                        warningLink1: shared.enUS.campaignRegistry,
                        warningPart2: " before they can be used for text messaging. To register more than 1 local phone number, you must be a business or non-profit organization with an EIN or Registry ID on file.",
                        warningPart3: "As a personal account, you cannot enable messaging on more than 1 local phone number. If you need to change your account type and have a valid EIN or Registry ID, call +1 (844) 2-TRESTA or email ",
                        warningLink2: shared.enUS.supportEmail,
                        warningPart4: ".",
                        warningPart5: "Otherwise, you can add the selected number(s), but some will have messaging disabled.",
                    },
                    tcrRegistrationFailed: {
                        title: "We encountered a problem enabling messaging.",
                        warningPart1: "We were unable to verify your business information. The number(s) you selected have been added to your account, but text messaging is currently disabled on the new local number(s) you added.",
                        warningPart2: "For help resolving this error, please call +1 (844) 2-TRESTA or email ",
                        warningLink1: shared.enUS.supportEmail,
                        warningPart3: ".",
                    }
                },
                addPhoneNumberTollFree: {
                    addPhoneNumber: "Add a Toll-Free Phone Number",
                    add: shared.enUS.add,
                    back: shared.enUS.back,
                    cancel: shared.enUS.cancel,
                    default: shared.enUS.default,
                    mustSelectAPhoneNumber: "Please select at least one phone number.",
                    pricingMessage: "You'll be charged a monthly fee for each additional phone number. For details, please visit our ",
                },
                numberPickerConstants: {
                    begins: "Begins With",
                    contains: "Contains",
                    ends: "Ends With",
                    error_begins: "begin with",
                    error_contains: "contain",
                    error_ends: "end with",
                },
                addPhoneNumberPort: {
                    acknowledgementStatement: "By signing below, I attest under penalty of law that I am (a) the legal owner of the phone number(s) included in this port request; or (b) am an authorized employee or representative of the entity requesting the port.  I am, or the entity identified above, is the exclusive end-user subscriber of the phone number(s) included in this port request and I/we assume all liability for the use of the phone number(s).  I acknowledge and understand that I must notify my existing carrier of my intention to change my service provider to Tresta and bear all responsibility for costs that continue to accrue with my former carrier/service provider.",
                    addFailure: "We encountered a problem with the following number - it was not added to your account.",
                    addFailure_plural: "We encountered a problem with the following numbers - they were not added to your account.",
                    addSuccess: 'The following number was successfully queued for transfer to your account.',
                    addSuccess_plural: 'The following numbers were successfully queued for transfer to your account.',
                    addSuccessInfo: 'You’ll receive a notification when the transfer completes. A customer service team member will reach out if we need additional information to complete the transfer.',
                    authorizedSignature: "Authorized signature is required.",
                    addMoreMessage: "To port in more than twenty-five phone numbers, please call Tresta customer service at +1 (844) 2-TRESTA.",
                    addPhoneNumber: "Transfer a Number",
                    addPhoneNumbers: 'Add Phone Numbers',
                    back: shared.enUS.back,
                    billingAddressRequired: "Please enter an address.",
                    billingCityRequired: "Please enter a city.",
                    billingNameRequired: "Please enter a name.",
                    billingNumberLength: shared.enUS.phoneNumberInvalid,
                    billingNumberRequired: shared.enUS.phoneNumberRequired,
                    cancel: shared.enUS.cancel,
                    carrierRequired: "Please enter your current carrier.",
                    desiredPortDateStatement: "I understand that my Desired Port Date is not a guaranteed port-on date and may complete before or after this date due to circumstances outside of Tresta's control.",
                    error: shared.enUS.error,
                    googleWarning: "Before porting in your Google Voice number, you'll need to unlock it. There is a fee that comes with unlocking your number.",
                    googleWarning1: "1. On your computer, go to the ",
                    googleWarning1Link: "Google Voice unlock page",
                    googleWarning2: "2. Point to the Google Voice number you want to transfer, and click Unlock my number.",
                    googleWarning3: "3. Click Continue.",
                    googleWarning4: "4. Pay with your Google Account. You’ll get your receipt by email.",
                    labelAccountNumber: "Account Number",
                    labelAddress: "Address",
                    labelApt: "Apt/Suite",
                    labelAuthorizedSignature: "Authorized Signature",
                    labelCarrier: "Carrier and Phone Number To Port",
                    labelCity: "City",
                    labelCountry: "Country",
                    labelCurrentCarrier: "Current Carrier",
                    labelDesiredPortDate: "Desired Port Date",
                    labelName: "Name",
                    labelNumberToPort: "Phone Number to Port",
                    labelOptional: "Optional",
                    labelPhoneNumber: "Phone Number",
                    labelPinOrPasscode: "Pin/Passcode",
                    labelPostalCode: "Postal Code",
                    labelProvince: "Province",
                    labelState: "State",
                    labelZipCode: "Zip Code",
                    linkAdd: "+ Add Another Number",
                    loggedInAs: "Agents cannot submit transfer number requests for customers.",
                    matchStatement1: "The billing information",
                    matchStatementBold: " must match the billing information on file with your current carrier.",
                    matchStatement2: " If you're unsure, please consult your most recent bill.",
                    next: shared.enUS.next,
                    noPortsWhenForCustomer: "Agents can not submit port number requests for customers",
                    optionOnlyNumber: "This is the only number on the account.",
                    optionOnlyNumber_plural: "These are the only numbers on this account.",
                    optionOtherNumbers: "There are other numbers on the account&semi; do not port them.",
                    placeholderLoading: "Loading...",
                    portDateRequired: "Please specify your desired port date.",
                    portDateAcknowledgment: "You must acknowledge that you understand to continue.",
                    portingWarningTitle: "Tresta is Not a Cell Phone Carrier",
                    portingWarningMessage: "If you want to port your number to Tresta and use it for calling, texting and data services, you will need a second number with your current carrier.",
                    postalCodeInvalid: shared.enUS.postalCodeInvalid,
                    postalCodeRequired: shared.enUS.postalCodeRequired,
                    pricingMessage: "You'll be charged a monthly fee for each additional phone number. For details, please visit our ",
                    selectedNumbers: "Numbers to Port from {{carrierName}}",
                    success: shared.enUS.success,
                    tabTitleAuthorization: "3 ) Authorization",
                    tabTitleBilling: "2 ) Billing",
                    tabTitleNumbers: "1 ) Numbers",
                    transfer: 'Transfer',
                    tooltipAccountNumberLine1: "The account number listed on the",
                    tooltipAccountNumberLine2: "invoice from your current carrier.",
                    tooltipPinOrPasscode: "If a PIN or passcode is required to <br/> access your account, we’ll need it <br/> to port your number away from <br/> your current carrier.",
                    zipCodeInvalid: shared.enUS.zipCodeInvalid,
                    zipCodeRequired: shared.enUS.zipCodeRequired
                },
                addPhoneNumberSummary: {
                    close: shared.enUS.close,
                    messagingDisabled: "Messaging Disabled",
                    phoneNumbersAdded: "Phone numbers added to your account",
                    phoneNumbersFailed: `Phone numbers <strong>not added to your account</strong> because they are no longer available or we encountered an error`,
                    pricingMessage: "You'll be charged a monthly fee for each additional phone number. For details, please visit our ",
                    purchaseSummary: "Purchase Summary",
                    moreComplianceInfoNeeded: {
                        title: "Local phone number messaging limit reached.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the ",
                        warningLink1: shared.enUS.campaignRegistry,
                        warningPart2: " before they can be used for text messaging. To register more than 1 local phone number, you must have an EIN or Registry ID on file.",
                        warningPart3: "To enable messaging on more than 1 local phone number, update your ",
                        warningLink2: "Messaging Compliance",
                        warningPart4: " to include your EIN or Registry ID.",
                        warningPart5: "Alternatively, you can enable messaging on a new phone number if you disable messaging on one of these local phone numbers:",
                    },
                    personalAccountLimitReached: {
                        title: "Local phone number messaging limit reached.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the ",
                        warningLink1: shared.enUS.campaignRegistry,
                        warningPart2: " before they can be used for text messaging. To register more than 1 local phone number, you must be a business or non-profit organization with an EIN or Registry ID on file.",
                        warningPart3: "As a personal account, you cannot enable messaging on more than 1 local phone number. If you need to change your account type and have a valid EIN or Registry ID, call +1 (844) 2-TRESTA or email ",
                        warningLink2: shared.enUS.supportEmail,
                        warningPart4: ".",
                        warningPart5: "Alternatively, you can enable messaging on a new phone number if you disable messaging on one of these local phone numbers:",
                    },
                    tcrRegistrationFailed: {
                        title: "We encountered a problem enabling messaging.",
                        warningPart1: "We were unable to verify your business information. The number(s) you selected have been added to your account, but text messaging is currently disabled on the new local number(s) you added.",
                        warningPart2: "For help resolving this error, please call +1 (844) 2-TRESTA or email ",
                        warningLink1: shared.enUS.supportEmail,
                        warningPart3: ".",
                    },
                },
                calls: {
                    call: "Call",
                    comingSoon: "Coming Soon",
                    enterNumberOrName: "Enter a phone number or name",
                    joiningWebApp: "Call History is joining the Web App!",
                    deviceLabel: "Houston Office",
                    deviceNumber: "+1 (234) 234-5566",
                    newCall: "New Call"
                },
                callsSidebar: {
                    headerAll: "All",
                    headerFavorites: "Favorites",
                    labelActiveCalls: "Active Calls",
                    labelConnectedVia: "Connected Via",
                    labelDate: "Date",
                    labelOptions: "Options",
                    labelRecentCalls: "Recent",
                    labelSearch: "Search",
                    labelShow: "Show",
                    searchCalls: "Search Calls",
                },
                comboBox: {
                    dailyLimitHit: "Daily Limit Reached",
                    noResultsFound: "No Results Found"
                },
                contacts: {
                    added: "Added",
                    address: "Address",
                    appleWatch: "Apple Watch",
                    birthday: "Birthday",
                    call: "Call",
                    ca: "Canada",
                    comingSoon: "Coming Soon",
                    companyAddressBook: "Company Address Book",
                    deviceLabel: "Houston Office",
                    deviceNumber: "+1 (234) 234-5566",
                    edit: "Edit",
                    email: "Email",
                    extension: "Extension",
                    home: "Home",
                    homeFax: "Home Fax",
                    iPhone: "iPhone",
                    joiningWebApp: "Contacts is joining the Web App!",
                    main: "Main",
                    map: "Map",
                    message: "Message",
                    mobile: "Mobile",
                    modified: "Modified",
                    newCall: "New Call",
                    note: "Notes",
                    other: "Other",
                    pager: "Pager",
                    personal: "Personal",
                    phone: "Phone",
                    school: "School",
                    sendMessage: "Send Message",
                    source: "Source",
                    tresta: "Tresta",
                    trestaUserGroups: "Tresta Users & Groups",
                    url: "URL",
                    work: "Work",
                    workFax: "Work Fax",
                    yourAddressBook: "Your Address Book",
                    us: "United States of America",
                    unsupportedModalTitle: "Unsupported Recipient",
                    unsupportedModalText: "This phone number does not appear to be a North American phone number that's capable of receiving text messages. Check that the number is formatted correctly and try again."
                },
                contactDetails: {
                    edit: "Edit",
                    noPermissionUsers: "You don’t have permissions to edit users.",
                    members: "Members",
                    membership: "Group Membership"
                },
                addContact: {
                    add: shared.enUS.add,
                    addAddress: "+ Add Address",
                    added: "Added",
                    addEmailAddress: "+ Add Email Address",
                    addPhoneNumber: "+ Add Phone Number",
                    address: "Address",
                    addressLineOne: "Address",
                    addressLineTwo: "Address 2",
                    addUrl: "+ Add URL",
                    birthday: "Birthday",
                    block: "Block Contact",
                    cancel: shared.enUS.cancel,
                    city: "City",
                    country: "Country",
                    delete: "Delete Contact",
                    deleteConfirmation: "Are you sure you want to delete this contact?",
                    disabledAddAddress: "Cannot add more than 10 addresses",
                    disabledAddEmailAddress: "Cannot add more than 10 email addresses",
                    disabledAddPhoneNumber: "Cannot add more than 10 phone numbers",
                    disabledAddUrl: "Cannot add more than 10 URLs",
                    disabledEdit: "You don’t have permissions <br> to edit this contact.",
                    disabledShared: "You don't have permissions to save to the shared address book",
                    discard: "Discard Changes",
                    discardConfirmation: "You’re about to discard your changes.",
                    editHeader: "Edit Contact",
                    email: "Email",
                    header: "Add Contact",
                    labelCompany: "Company",
                    labelFirstName: "First Name",
                    labelJobTitle: "Job Title",
                    labelLastName: "Last Name",
                    modified: "Modified",
                    notes: "Notes",
                    number: "Number",
                    other: "Other",
                    phone: "Phone",
                    postal: "Postal",
                    private: "Your Address Book (Private Contact)",
                    province: "Province",
                    save: "Save",
                    shared: "Company Address Book (Shared Contacts)",
                    source: "Source",
                    state: "State",
                    type: "Type",
                    url: "URL",
                    validation: {
                        requiredMessage: "At least one of the following is required:",
                        requiredItems: "First Name, Last Name, Company, Email, or Phone",
                        phoneNumberDuplicate: "This number has already been added.",
                        emailDuplicate: "This email address has already been added.",
                        addressDuplicate: "This address has already been added.",
                        invalidBirthday: "Please enter a valid birthday."
                    },
                    zip: "Zip"
                },
                duplicateContactActionModal: {
                    title: "Contact Already Exists",
                    nDuplicateContacts: "This contact has the same phone or email as another contact.",
                    nDuplicateContacts_plural: "These contacts have the same phone or email.",
                    addConfirmation: "Do you want to create a new contact anyway?",
                    editConfirmation: "Do you want to save anyway?"
                },
                contactsSidebar: {
                    all: "All",
                    companyAddressBook: "Company Address Book",
                    firstName: "First Name",
                    labelAdded: "Added",
                    labelContacts: "Contacts",
                    labelFilters: "Filters",
                    labelModified: "Modified",
                    lastName: "Last Name",
                    noContacts: "No Contacts to display",
                    searchContacts: "Search Contacts",
                    show: "Show",
                    sortBy: "Sort By",
                    trestaUserGroups: "Tresta Users & Groups",
                    yourAddressBook: "Your Address Book",
                    tooltip: "At least one contact source is required."
                },
                messages: {
                    alpha: "Alpha",
                    attachmentText: "Attach File",
                    blocked: "Blocked",
                    blockedContact: "Contact Blocked",
                    nBlockedContacts: "{{ count }} participant is blocked",
                    nBlockedContacts_plural: "{{ count }} participants are blocked",
                    blockedNewConversation: "Start a new conversation with remaining participants",
                    comingSoon: "Coming Soon",
                    conversationNotFound: "Sorry, we can't find that conversation.",
                    dailyLimitHit: "Daily Limit Reached",
                    enterToContinue: "Hit Enter to Continue",
                    maxRecipientsReached: "You've reached the max number of recipients",
                    mobile: "Mobile",
                    newMessage: "New Message",
                    nNewMessages: "{{ newMessagesCount }} New Messages",
                    noMessages: "No Messages to display",
                    oneNewMessage: "1 New Message",
                    recipientInputPlaceholder: "Enter a phone number",
                    search: "Search",
                    phoneNumberAccessRevoked: "Phone Number Access Revoked",
                    phoneNumberNoLongerAvailable: "The phone number you selected is no longer available. Please select another phone number to send messages from.",
                    textingHasNotBeenEnabled: "Messaging Hasn't Been Enabled for any Phone Numbers",
                    textingNotEnabled: "Messaging Isn't Enabled for Your User",
                    toEnableMessages: "To enable messages, contact one of your company's System Admins.",
                    toEnableMessagesAdmin1: "To enable messages, go to ",
                    toEnableMessagesAdmin2: "Phone Numbers",
                    toEnableMessagesAdmin3: " > Edit Phone Numbers > Messages tab.",
                    tollFree: "Toll-free",
                    reachedDailyLimit: "You’ve reached your daily limit for text messaging.",
                    reachedDailyLimitReadArticle: "Read Help Article",
                    retrySendingMessage: "Retry Sending Message",
                    deleteMessage: "Delete Message",
                    welcomeToMessages: "Welcome to Messages",
                    welcomeMessage1: "We’re excited to be adding Messages to the web app! Please note that this feature is an Alpha release and not all functionality is included yet. Updates will be rolling out every couple of weeks.",
                    welcomeMessage2: "In the meantime, if you need features that have not yet been released here, please use the mobile app which has the full feature set.",
                    fullScreen: "Full Screen",
                    play: "Play",
                    pause: "Pause",
                    mute: "Mute",
                    unmute: "Unmute",
                    reachedMaxAttachments: "10 attachment limit reached. Some files were excluded.",
                    fileLarge: "File Too Large",
                    fileLargeExplanation: "Unable to upload the file due to Wireless Carrier size limits.",
                    invalidFileUploadAttempted: "1 file failed to upload and was removed from your message.",
                    nInvalidFilesUploadAttempted: "{{ invalidFiles }} files failed to upload and were removed from your message.",
                    unsubscribed: "Unsubscribed",
                    unsubscribedPhoneNumber: "Phone Number Unsubscribed",
                    nUnsubscribedPhoneNumber: "{{count}} Phone Number Unsubscribed",
                    nUnsubscribedPhoneNumber_plural: "{{count}} Phone Numbers Unsubscribed",
                },
                messageComposeArea: {
                    message: "Message"
                },
                messagesSidebar: {
                    labelMessages: "Messages",
                    noMessages: "No Messages to Display",
                    draftLimitReached: "New Message Draft Limit Reached",
                    searchMessages: "Search Messages"
                },
                messagesSidebarComingSoon: {
                    labelFilters: "Filters",
                    labelMessages: "Messages",
                    searchMessages: "Search Messages"
                },
                conversation: {
                    close: "Close",
                    nRecipients: "{{ recipientCount }} Recipients",
                    unsupported: "Unsupported",
                    tollFreeNoGroupMessagingText: "Currently, toll-free numbers can’t participate in group messaging."
                },
                conversationDetails: {
                    archive: "Archive",
                    archiveConfirmation: "Are you sure you want to archive this conversation?",
                    archiveConfirmationHeader: "Archive Message Thread",
                    cancel: shared.enUS.cancel,
                    conversationName: "Conversation Name",
                    conversationStarted: "Conversation Started",
                    conversationParticipants: "Participants",
                    conversationSubscribers: "Subscribers",
                    links: "Links",
                    location: "Location",
                    markAsNew: "Mark as New",
                    markAsReviewed: "Mark as Reviewed",
                    media: "Photos and Videos",
                    save: shared.enUS.save,
                    seeAll: "See All",
                    tollFree: "Toll-Free",
                    you: "You"
                },
                conversationMedia: {
                    all: "All",
                    photos: "Photos",
                    videos: "Videos",
                    media: "Photos and Videos",
                    noMediaToDisplay: "No {{media}} to Display"
                },
                conversationParticipantCard: {
                    call: "Call",
                    comingSoon: "Coming Soon",
                    contact: "View",
                    sendMessage: "Message",
                    conversationParticipants: "Participants",
                    you: "You",
                    addContact: "Add Contact",
                },
                conversationSubscriberCard: {
                    call: "Call",
                    comingSoon: "Coming Soon",
                    userToUserNotSupported: "User to User Messaging is Not Supported",
                    sendMessage: "Message",
                    viewContact: "View"
                },
                conversationLocation: {
                    tollFreeMapMessage: "Location Data Unavailable"
                },
                inboxSidebar: {
                    clearFilters: "Clear Filters",
                    headerRecordings: "Call Recordings",
                    headerVoicemail: "Voicemail",
                    labelInbox: "Inbox",
                    labelFilters: "Filters",
                    labelShow: "Show",
                    labelSubscriptions: "Subscriptions",
                    noSubscriptionsAvailable: "No Subscriptions Available",
                },
                inboxCallRecordings: {
                    delete: "Delete",
                    download: "Download",
                    headerRecordings: "Call Recordings",
                    isNew: "",
                    isNew_new: "Mark as Reviewed",
                    isNew_reviewed: "Mark as New",
                    noRecordings: "No Call Recordings to Display",
                    noResults: "No Results Found",
                },
                inboxVoicemail: {
                    delete: "Delete",
                    download: "Download",
                    headerVoicemail: "Voicemail",
                    isNew: "",
                    isNew_new: "Mark as Reviewed",
                    isNew_reviewed: "Mark as New",
                    noResults: "No Results Found",
                    noVoicemail: "No Voicemails to Display",
                },
                audioFiles: {
                    header: "Audio Files",
                    noResults: shared.enUS.noResultsFound,
                    noAudioFiles: "No Audio Files to Display",
                },
                addAudioFile: {
                    add: shared.enUS.add,
                    cancel: shared.enUS.cancel,
                    conversionFailed: "Conversion failed, please choose another file.",
                    download: "Download",
                    headerAdd: "Add Audio File",
                    headerEdit: "Edit Audio File",
                    labelFile: "File",
                    labelMethod: "Method",
                    labelName: "Name",
                    labelPreview: "Preview",
                    promptNameInvalid: "Please enter a valid audio file name.",
                    promptNameRequired: "Please enter an audio file name.",
                    promptAudioRecordingRequired: "Please record an audio file.",
                    promptAudioUploadRequired: "Please select an audio file.",
                    promptAudioUploadSizeExceedsMax: shared.enUS.fileTooBig,
                    promptAudioUploadTypeInvalid: shared.enUS.invalidAudioFile,
                    promptNameNotUnique: "The name you entered is already in use by another audio file.",
                    promptNameNotLocallyUnique: "The name you entered is already in use by another new audio file.",
                    save: shared.enUS.save,
                },
                autoAttendant: {
                    cancel: shared.enUS.cancel,
                    copy: "Copy Auto Attendant",
                    copyAs: "Copy As",
                    expandCallFlow: "Expand Call Flow",
                    headerAutoAttendant: "Auto Attendants",
                    loopedCallFlowMessage: "You have routed an auto attendant to itself. You can do this, but the call flow cannot be displayed here since it is a self-referencing loop.",
                    nameRequired: "Please enter an auto attendant name.",
                    noResults: "No Results Found",
                    noAutoAttendants: "No Auto Attendants to Display",
                    portInProgress: "Port In Progress",
                    save: shared.enUS.save,
                    uniqueNameRequired: "This name is already in use by another auto attendant.",
                    defaultSeparatorMessage: "Then send it to",
                    hangup: "Hang Up",
                    playRinging: "Ring until answered or caller hangs up",
                    timeoutMessage: " seconds",
                    timeoutSeparatorMessage: "If no answer after ",
                },
                addAutoAttendant: {
                    add: shared.enUS.add,
                    addOrSelectPrompt: "A main greeting is required.",
                    autoAttendantNameRequired: "Auto attendant name is required.",
                    cancel: shared.enUS.cancel,
                    copy: "Save a Copy",
                    createAudioFile: " or create a new one",
                    createAudioFileLinkText: 'Create a new one.',
                    headerAdd: "Add Auto Attendant",
                    headerEdit: "Edit Auto Attendant",
                    inactiveItem: "Disabled",
                    labelName: "Name",
                    menuOptionAdd: "Add Menu Option",
                    menuOptionEdit: "Edit Menu Option",
                    menuRoutesToAtLeastOneEndpoint: "At least one keypress option must be configured.",
                    noAudioFilesWarningMessage: "You haven't created an audio file yet.&nbsp;",
                    pleaseSelect: shared.enUS.pleaseSelect,
                    save: shared.enUS.save,
                    selectAudioFile: "Greet and instruct callers with this audio file ",
                    subMenuRoutesToAtLeastOneEndpoint: "Sub-menu: at least one keypress option must be configured.",
                    uniqueNameRequired: "Auto attendant name is already in use.",
                    validationPleaseFix: "Please correct the problems in these sections and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these sections:",
                    voicePromptMissingOnMenu: "Sub-menu: a greeting is required.",
                },
                contactPermissions: {
                    title: "Contacts",
                    description: "Shared Company Address Book", 
                    cancel: shared.enUS.cancel,
                    permissionFormLabel: "The following users and groups can add, edit and delete contacts from the Shared Company Address Book",
                    cannotRemoveSystemAdminsGroup: "Cannot Remove System Admins"
                },
                blocking: {
                    title: "Blocking",
                    cancel: shared.enUS.cancel,
                    permissionFormLabel: "The following users and groups can block contacts",
                    cannotRemoveSystemAdminsGroup: "Cannot Remove System Admins"
                },
                extensions: {
                    assigned: "Assigned",
                    cancel: shared.enUS.cancel,
                    changeTo: "Change to {{extension}}",
                    ext: "Ext {{extension}}",
                    extension: "Extension",
                    header: "Extensions",
                    name: "Name",
                    noResultsFound: "No Results Found",
                    save: shared.enUS.save,
                    search: shared.enUS.search,
                    type: "Type",
                },
                forwardingNumbers: {
                    header: "Forwarding Numbers",
                    noResults: shared.enUS.noResultsFound,
                    noForwardingNumbers: "No Forwarding Numbers to Display",
                    search: shared.enUS.search,
                },
                addForwardingNumbers: {
                    add: shared.enUS.add,
                    addHeader: "Add Forwarding Number",
                    callerIdLabel: "Caller ID",
                    callerNumber: "Display Caller's Number",
                    cancel: shared.enUS.cancel,
                    dialedNumber: "Display Tresta Number",
                    editHeader: "Edit Forwarding Number",
                    nameLabel: "Name",
                    nameRequired: "Name is required.",
                    nameUnique: "This name is already used by another forwarding number.",
                    numberLabel: "Phone Number",
                    numberRestricted: "This number is not supported.",
                    numberRequired: "Phone number is required.",
                    numberUnique: "This number is already used by another forwarding number.",
                    numberInvalid: shared.enUS.phoneNumberInvalid,
                    save: shared.enUS.save
                },
                phoneNumbers: {
                    atLeastOneRequired: "At least one active phone number is required.",
                    deletePortInProgress: "Please contact customer service to cancel phone number port.",
                    disabledItemInbound: "Inbound Calls are Disabled",
                    disabledItemMessages: "Text Messages are Disabled",
                    disabledItemOutbound: "Outbound Calls are Disabled",
                    disabledItemRecording: "Call Recording is Disabled",
                    headerPhoneNumbers: "Phone Numbers",
                    inactiveItem: "Inactive - Click to Activate",
                    loopedCallFlowMessage: "You have routed an auto attendant to itself. You can do this, but the call flow cannot be displayed here since it is a self-referencing loop.",
                    noInheritors: "None",
                    noPhoneNumbersWarningTitle: "You don’t have any phone numbers.",
                    noPhoneNumbersWarningMessage_part1: "It looks like you’ve ported out all of your phone numbers. Either have a billing admin",
                    noPhoneNumbersWarningMessage_part2: "close your account",
                    noPhoneNumbersWarningMessage_part3: "to stop new charges from accruing, or",
                    noPhoneNumbersWarningMessage_part4: "add a new phone number",
                    noPhoneNumbersWarningMessage_part5: "to resume using Tresta.",
                    noPhoneNumbersBillingAdminWarningMessage_part1: "It looks like you’ve ported out all of your phone numbers. Either",
                    noPhoneNumbersBillingAdminWarningMessage_part2: "close your account",
                    noPhoneNumbersBillingAdminWarningMessage_part3: "to stop new charges from accruing, or",
                    noPhoneNumbersBillingAdminWarningMessage_part4: "add a new phone number",
                    noPhoneNumbersBillingAdminWarningMessage_part5: "to resume using Tresta.",
                    tabDescriptionInbound_default: "When a call is received, send it to",
                    tabDescriptionInbound_voicePrompt: "When a call is received, play this audio file",
                    tabDescriptionInbound_schedule: "When a call is received, use this schedule",
                    tabDescriptionMessages: "These users and groups can send and receive text messages",
                    tabDescriptionOutbound: "These users and groups can place calls",
                    tabDescriptionRecording: "These users and groups can access the call recordings",
                    noPhoneNumbers: "No Phone Numbers to Display",
                    noResults: "No Results Found",
                    portInProgress: "Port In Progress",
                    pressOne: "Press 1",
                    search: 'Search',
                    tab_inbound: "Inbound Calls",
                    tab_outbound: "Outbound Calls",
                    tab_recording: "Call Recording",
                    tab_messages: "Messages"
                },
                phoneNumberTextMessagingTab: {
                    deniedItemMessages: {
                        label: "We encountered a problem enabling messaging.",
                        descriptionPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered before they can be used for text messaging. Unfortunately, the ",
                        descriptionLink1: shared.enUS.campaignRegistry,
                        descriptionPart2: " denied your registration request.",
                        descriptionPart3: "For help resolving this error and resubmitting your number for registration, please call +1 (844) 2-TRESTA or email ",
                        descriptionLink2: shared.enUS.supportEmail,
                        descriptionPart4: ".",
                    },
                    deniedItemMessagesTollFree: {
                        label: "We encountered a problem enabling messaging.",
                        descriptionPart1: "Due to FCC and wireless carrier regulations, VOIP toll-free phone numbers must go through a verification process before they can be used for text messaging. Unfortunately, this toll-free number was unable to be verified.",
                        descriptionPart2: "For help resolving this error and resubmitting your number for verification, please call +1 (844) 2-TRESTA or email ",
                        descriptionLink1: shared.enUS.supportEmail,
                        descriptionPart3: ".",
                    },
                    pendingItemMessages: {
                        label: "Pending",
                        descriptionPart1: "Why is my request to enable messages queued?",
                        descriptionPart2: "FCC and wireless carrier regulations require VOIP accounts to be vetted before they can be used to send text messages. This process is currently pending and can take up to 30 days. You will be notified by email when your vetting status changes from Pending to either Approved or Rejected.",
                        descriptionPart3: " If your account is approved, Messages will be enabled automatically. If your account is rejected, please reach out to our customer support team for further assistance.",
                    },
                    pendingItemMessagesTollFree: {
                        label: "Pending",
                        descriptionPart1: "Why is my request to enable messages queued?",
                        descriptionPart2: "FCC and wireless carrier regulations require VOIP toll-free phone numbers to go through a verification process. This process is currently pending and can take up to 30 days. You will be notified by email when your vetting status changes from Pending to either Approved or Rejected.",
                        descriptionPart3: " If this number is approved, Messages will be enabled automatically. If this number is rejected, please reach out to our customer support team for further assistance.",
                    },
                },
                protectedContent: {
                    protectedContent: "Protected Content"
                },
                editPhoneNumber: {
                    addAnother: "+ Add Another",
                    authorizedHistory: "Authorized User History:",
                    callRecordingTab: "Call Recording",
                    cancel: "Cancel",
                    disabled: "Disabled",
                    editPhoneNumberTitle: "Edit Phone Number",
                    enabled: "Enabled",
                    messaging: "Messaging",
                    messagingTab: "Messages",
                    none: "None",
                    numberLabel: "Number: ",
                    numberLocationLabel: "Location: ",
                    numberNameLabel: "Name",
                    inboundCallingTab: "Inbound Calls",
                    inboundCallingDisabled: "When this phone number receives a call it will play an out of service message followed by a busy signal.",
                    outboundCallingTab: "Outbound Calls",
                    permissionsWillBeDetermined: "Once saved, {{permissionType}} permissions will be determined based on the inbound call flow",
                    revokePermission: "Revoke Permissions",
                    validationLastAuthorized: "Sorry, you cannot remove the last authorized user",
                    validationPhoneNumberName: "Phone number name must be unique.",
                    validationPleaseFix: "Please correct the problems in these sections and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these sections:",
                    humanDetection: "Human Detection",
                    humanDetectionTooltip: "Calls from numbers that aren’t in your contacts will be asked to press 1 to prove they’re human."
                },
                editPhoneNumberCallRecordingTab: {
                    act: "view call recordings",
                    action: "view call recordings from this phone number",
                    actionAlternate: "view call recordings from this phone number",
                    agreeToNotifyInbound: "I agree to notify callers that they are being recorded.",
                    agreeToNotifyOutbound: "I agree to notify all parties that they are being recorded.",
                    callRecordingOptions: {
                        disabled: "Disabled",
                        allCalls: "All Calls",
                        onlyInbound: "Only Inbound Calls",
                        onlyOutbound: "Only Outbound Calls"
                    },
                    controlWhoGetsRecordings: "How do you want to control who sees call recordings from this number?",
                    grantPermissions: "call recording access",
                    inheritedDescription: "The following users and groups are currently included in the call flow (on the Inbound Calls tab) and, as a result, have inherited access to call recordings from this phone number",
                    inheritPermissionEnabled: "Inherit call recording permissions from the Inbound Calls tab",
                    inheritPermissionDisabled: "Manually control call recording permissions",
                    manualDescription: "Who should have access to the call recordings?",
                    onInboundCalls: "On inbound calls, notify callers by playing this audio file",
                    onInboundCallsError: "You must agree to notify callers.",
                    onOutboundCalls: "On outbound calls",
                    onOutboundCallsError: "You must agree to notify all parties.",
                    permissionType: "recording access",
                    record: "Record",
                },
                editPhoneNumberTextMessagingTab: {
                    act: "message",
                    action: "text on this phone number",
                    actionAlternate: "text on this phone number",
                    beAbleTo: "be able to start new conversations from this phone number",
                    beIncludedIn: "be included in all conversations on this phone number",
                    controlWhoCanMessage: "How do you want to control who can send and receive texts from this number?",
                    grantPermissions: "texting permissions",
                    inheritedDescription: "The following users and groups are currently included in the call flow (on the Inbound Calls tab) and, as a result, will",
                    inheritPermissionEnabled: "Inherit texting permissions from the Inbound Calls tab",
                    inheritPermissionDisabled: "Manually control texting permissions",
                    manualDescription: "The following users and groups have been manually granted texting permissions on this phone number",
                    messagingRequiresMessagingCompliance: {
                        title: "Your messaging compliance must be complete to enable messaging.",
                        warningPart1: "To enable messaging, please complete your ",
                        warningLink1: "Messaging Compliance",
                        warningPart2: ".",
                    },
                    messagingRequiresMoreComplianceInfo: {
                        title: "Cannot enable messaging without more info.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the Campaign Registry ",
                        warningPart2: " before they can be used for text messaging. To register more than 1 local phone number, you must have an EIN or Registry ID on file.",
                        warningPart3: "To enable messaging on more than 1 local phone number, update your ",
                        warningLink2: "Messaging Compliance",
                        warningPart4: " to include your EIN or Registry ID.",
                        warningPart5: "Alternatively, you can disable messaging on the one local phone number that has messaging enabled, and then return here to enable messaging on this number.",
                    },
                    messagingRequiresBusinessAccount: {
                        title: "Local phone number messaging limit reached.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the Campaign Registry",
                        warningPart2: " before they can be used for text messaging. To register more than 1 local phone number, you must be a business, non-profit or government organization with an EIN or Registry ID on file.",
                        warningPart3: "As a personal account, you cannot enable messaging on more than 1 local phone number. If you need to change your account type and you have a valid EIN or Registry ID, call Tresta Customer Service at +1 (844) 2-TRESTA or email ",
                        warningLink2: shared.enUS.supportEmail,
                        warningPart4: ".",
                        warningPart5: "Alternatively, you can disable messaging on the local number that has messaging enabled, and then return here to enable messaging on this number.",
                    },
                    messagingTollFreeLimit: {
                        title: "Toll-Free number messaging limit reached.",
                        warningPart1: "Due to wireless carrier regulations, a business can only have 4 toll-free numbers enabled to send/receive text messages.  Tresta recommends that you set your main customer support number, if toll-free, to receive and send text messages. ",
                        warningPart2: "If you need additional phone numbers for sending and receiving text messages, we recommend setting up local numbers. (Please note, only verified businesses with an EIN number may text on more than one local number.)",
                        warningPart3: "",
                        warningPart4: "",
                        warningPart5: "If you would like this number to send/receive text messages, you can disable messaging on any other numbers with toll-free messaging enabled, and then return here to enable messaging on this number.",
                    },
                    messagingTcrRegistrationFailed: {
                        title: "We encountered a problem enabling messaging.",
                        warningPart1: "We were unable to verify your business information. For help resolving this error, please call +1 (844) 2-TRESTA or email ",
                        warningLink1: shared.enUS.supportEmail,
                        warningPart2: ".",
                    },
                    messagingBandwidthRegistrationDenied: {
                        title: "We encountered a problem enabling messaging.",
                        warningPart1: "For help resolving this error, please call +1 (844) 2-TRESTA or email ",
                        warningLink1: shared.enUS.supportEmail,
                        warningPart2: ".",
                    },
                    messagingDisabledDuringPort: {
                        title: "Cannot enable messaging while number is porting.",
                        warningPart1: "Messaging cannot be enabled until the port process is complete."
                    },
                    messagingDisabledFreeTrial: {
                        title: "Cannot enable messaging while in free trial.",
                        warningPart1: "Messaging cannot be enabled while in a free trial."
                    },
                    messagingRegistrationWillBeQueued: {
                        title: "Why is my request to enable messaging queued?",
                        warningPart1: "FCC and wireless carrier regulations require VOIP accounts to be vetted before they can be used to send text messages. This process is currently pending and can take up to 30 days. You will be notified by email when your vetting status changes from Pending to either Approved or Rejected. ",
                        warningPart2: " If your account is approved, Messages will be enabled automatically. If your account is rejected, please reach out to our customer support team for further assistance.",
                    },
                    messagingRegistrationIsPending: {
                        title: "We’re processing your request to enable messaging.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP local phone numbers must be registered with the Campaign Registry",
                        warningPart2: " before they can be used for text messaging. Your registration has been requested and messaging will be enabled once we receive approval, which can take up to 30 days. An email will be sent once processing is complete.",
                    },
                    messagingRegistrationIsPendingTollFree: {
                        title: "We’re processing your request to enable messaging.",
                        warningPart1: "Due to FCC and wireless carrier regulations, VOIP toll-free phone numbers must go through a verification process",
                        warningPart2: " before they can be used for text messaging. Your verification has been requested and messaging will be enabled once we receive approval, which can take up to 30 days. An email will be sent once processing is complete.",
                    },
                    pending: "Pending",
                    permissionType: "texting",
                    resubmit: "Resubmit"
                },
                messagingFeeConfirmationModal: {
                    agreementRequired: "You must agree to continue.",
                    enableSmsMessagePart1: "FCC and wireless carrier regulations require VOIP accounts to be vetted before they can be used to send text messages. This vetting process is administered by a third-party company and costs ${{amount}}.",
                    enableSmsMessagePart2: "By continuing, you are agreeing to pay a one-time Messaging Activation Cost Recovery Fee of ${{amount}} on your next bill to reimburse Tresta for the cost of vetting your account.",
                    enableSmsMessageAcknowledgement: "I understand that I will incur a ${{amount}} one-time fee on my next bill.",
                    disableSmsMessageAcknowledgement: "I understand that disabling Messages and then re-enabling Messages more than 30 days from today will subject my account to re-vetting, which may take up to 30 days and will incur either a $20 or a $69 one-time fee, based on my business type.",
                    disableSmsMessagePart1: "This is your last phone number with Messages enabled. If you disable Messages on this number, you will have a 30-day window from today during which you may re-enable Messages without having to go through the vetting process again. ",
                    disableSmsMessagePart2: "After 30 days, if you re-enable Messages on any number, you will be required to go through the vetting process again, which may take up to 30 days and will incur another instance of the Messaging Activation Cost Recovery fee that you paid the first time your account was vetted.",
                    headerTitle: "Messaging Activation Fee",
                },
                editPhoneNumberOutboundDialTab: {
                    act: "dial",
                    action: "dial out from this phone number",
                    actionAlternate: "place calls from this phone number",
                    controlWhoCanDial: "How do you want to control who can dial from this number?",
                    grantPermissions: "dialing permissions",
                    inheritedDescription: "The following users and groups are currently included in the call flow (on the Inbound Calls tab) and, as a result, have permissions to dial outbound from this phone number",
                    inheritPermissionEnabled: "Inherit dialing permissions from the Inbound Calls tab",
                    inheritPermissionDisabled: "Manually control dialing permissions",
                    manualDescription: "The following users and groups have been manually granted permissions to dial outbound from this phone number",
                    permissionType: "dialing",
                },
                schedules: {
                    header: "Schedules",
                    noResults: shared.enUS.noResultsFound,
                    noSchedules: "No Schedules to Display",
                    search: shared.enUS.search
                },
                addSchedule: {
                    add: shared.enUS.add,
                    addRange: "+ Add Schedule Range",
                    cancel: shared.enUS.cancel,
                    days: "Days of Week",
                    friday: "F",
                    from: "From",
                    headerAdd: "Add Schedule",
                    headerEdit: "Edit Schedule",
                    lastItemTooltip: "At least one schedule range is required.",
                    map: "Schedule Map",
                    maxRanges: "You have reached the limit of 9 ranges per schedule.",
                    monday: "M",
                    name: "Name",
                    nameRequired: "Name is required.",
                    nameNotUnique: "This name is already used by another schedule.",
                    otherTimes: "All Other Times",
                    otherTimesDescription: "When the current day and time doesn’t fall within one of the schedule ranges above, we’ll send calls here.",
                    range: "Schedule Range",
                    rangeNameMustBeUnique: "This name is already used by another range within this schedule.",
                    rangePlaceholderMessage: "Office Hours, Lunch, etc.",
                    routeToTitle: "When a call is received during this schedule range",
                    routingRuleError: "Please make a selection.",
                    saturday: "S",
                    save: shared.enUS.save,
                    segmentsShouldNotOverlap: "Schedule ranges cannot overlap; this range conflicts with another range.",
                    segmentsShouldNotOverlap_name: "Schedule ranges cannot overlap; this range conflicts with range {{name}}",
                    selectedDayRequired: "At least one day must be selected.",
                    sunday: "S",
                    thursday: "Th",
                    timeSelectionsInvalid: "Start time must be before end time.",
                    to: "To",
                    tuesday: "T",
                    unnamedSegment: "Unnamed Schedule Segment",
                    validationPleaseFix: "Please correct the problems in these ranges and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these ranges:",
                    wednesday: "W",
                },
                humanDetection: {
                    aboutTitle: "About Human Detection",
                    aboutDescription: "Block automated spam and robocalls with Human Detection. When enabled, calls from numbers that aren’t in your contacts will be asked to press 1 to prove they’re human. If you need to receive calls from automated systems, you can whitelist them here, or you can turn off Human Detection at the phone number level.",
                    addPhoneNumberTitle: "Add Phone Number to Whitelist",
                    cancel: shared.enUS.cancel,
                    humanDetection: "Human Detection",
                    noPhoneNumbers: "No Phone Numbers to Display",
                    phoneNumber: shared.enUS.phoneNumber,
                    phoneNumberRequired: "Phone number is required.",
                    save: shared.enUS.save,
                    toEnableHumanDetection1: "To enable or disable, go to ",
                    toEnableHumanDetection2: "Phone Numbers",
                    toEnableHumanDetection3: " > Edit Phone Numbers > Inbound Calls tab",
                    whitelistTitle: "Human Detection Whitelist",
                },
                messagingCompliance: {
                    autoResponseTab: "Help & Opt-Out",
                    cancel: shared.enUS.cancel,
                    enableSmsTitle: "Ready to Enable SMS",
                    enableSmsMessage: "Your SMS registration is complete! In order to begin sending/receiving text messages, you must enable text messaging on a phone number from your Phone Numbers settings.",
                    headerTitle: "Messaging Compliance",
                    localSmsTab: "Local Messaging",
                    profileTab: "Profile",
                    resubmitBrand: "Resubmit Brand",
                    save: shared.enUS.save,
                    smsPinRequiredWarningTitle: "SMS Registration PIN Required",
                    smsPinRequiredWarningDescriptionPart1: "Your SMS registration requires verification with a one time passcode. ",
                    smsPinRequiredWarningDescriptionLink1: "Click here to verify",
                    smsPinRejected: "Incorrect SMS PIN, please try again.",
                    tcrBrandValidationFailedWarningTitle: "SMS Registration Error",
                    tcrBrandValidationFailedWarningPart1: "We were unable to create your SMS registration with the Campaign Registry. Please double check your Messaging Compliance information to verify that it is correct.",
                    tcrBrandValidationFailedWarningPart2: "If you continue to receive this error, please contact support at +1 (844) 2-TRESTA or email ",
                    tcrBrandValidationFailedWarningLink1: shared.enUS.supportEmail,
                    tcrBrandValidationFailedWarningPart3: ".",
                    tcrBrandValidationPendingTitle: "Your SMS Registration is Pending",
                    tcrBrandValidationPendingPart1: "FCC and wireless carrier regulations require VOIP accounts to be vetted before they can be used to send text messages. This process is currently pending and could take up to 1-2 hours. You will be notified by email when your vetting status changes from Pending to either Approved or Rejected.",
                    tcrBrandValidationPendingPart2: "If you continue to receive this message, please contact support at +1 (844) 2-TRESTA or email ",
                    tcrBrandValidationPendingLink1: shared.enUS.supportEmail,
                    tcrBrandValidationPendingPart3: ".",
                    tollfreeSmsTab: "Toll-Free Messaging",
                    validationPleaseFix: "Please correct the problems in these sections and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these sections:"
                    },
                messagingComplianceAutoResponseTab: {
                    helpAutoResponseInvalid: sharedMessagingCompliance.enUS.helpAutoResponseInvalid,
                    helpAutoResponseRequired: sharedMessagingCompliance.enUS.helpAutoResponseRequired,
                    labelHelpAutoResponse: sharedMessagingCompliance.enUS.labelHelpAutoResponse,
                    labelHelpAutoResponseDescription: sharedMessagingCompliance.enUS.labelHelpAutoResponseDescription,
                    labelHelpPlaceholder: sharedMessagingCompliance.enUS.labelHelpPlaceholder,
                    labelHelpResponse: sharedMessagingCompliance.enUS.labelHelpResponse,
                    labelStopAutoResponse: sharedMessagingCompliance.enUS.labelStopAutoResponse,
                    labelStopAutoResponseDescriptionOne: sharedMessagingCompliance.enUS.labelStopAutoResponseDescriptionOne,
                    labelStopAutoResponseDescriptionTwo: sharedMessagingCompliance.enUS.labelStopAutoResponseDescriptionTwo,
                    labelStopPlaceholder: sharedMessagingCompliance.enUS.labelStopPlaceholder,
                    labelStopResponse: sharedMessagingCompliance.enUS.labelStopResponse,
                    labelTextMessagingAutoResponses: sharedMessagingCompliance.enUS.labelTextMessagingAutoResponses,
                    noPlaceholderEmail: sharedMessagingCompliance.enUS.noPlaceholderEmail,
                    stopAutoResponseInvalid: sharedMessagingCompliance.enUS.stopAutoResponseInvalid,
                    stopAutoResponseRequired: sharedMessagingCompliance.enUS.stopAutoResponseRequired,
                },
                messagingComplianceLocalMessagingTab: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    exampleFourDescriptionOne: sharedMessagingCompliance.enUS.exampleFourDescriptionOne,
                    exampleFourDescriptionTwo: sharedMessagingCompliance.enUS.exampleFourDescriptionTwo,
                    exampleNote: sharedMessagingCompliance.enUS.exampleNote,
                    exampleOneDescriptionOne: sharedMessagingCompliance.enUS.exampleOneDescriptionOne,
                    exampleOneDescriptionTwo: sharedMessagingCompliance.enUS.exampleOneDescriptionTwo,
                    exampleThreeDescriptionOne: sharedMessagingCompliance.enUS.exampleThreeDescriptionOne,
                    exampleThreeDescriptionTwo: sharedMessagingCompliance.enUS.exampleThreeDescriptionTwo,
                    exampleTwoDescriptionOne: sharedMessagingCompliance.enUS.exampleTwoDescriptionOne,
                    exampleTwoDescriptionTwo: sharedMessagingCompliance.enUS.exampleTwoDescriptionTwo,
                    labelAccountNotifications: sharedMessagingCompliance.enUS.labelAccountNotifications,
                    labelAppointmentReminders: sharedMessagingCompliance.enUS.labelAppointmentReminders,
                    labelConversationMessaging: sharedMessagingCompliance.enUS.labelConversationMessaging,
                    labelCustomerConsentEmployeeRelationship: sharedMessagingCompliance.enUS.labelCustomerConsentEmployeeRelationship,
                    labelCustomerConsentInitiateConversation: sharedMessagingCompliance.enUS.labelCustomerConsentInitiateConversation,
                    labelCustomerConsentLeadCaptureForms: sharedMessagingCompliance.enUS.labelCustomerConsentLeadCaptureForms,
                    labelCustomerConsentOnlineCheckout: sharedMessagingCompliance.enUS.labelCustomerConsentOnlineCheckout,
                    labelCustomerConsentOther: sharedMessagingCompliance.enUS.labelCustomerConsentOther,
                    labelCustomerConsentOtherInvalid: sharedMessagingCompliance.enUS.labelCustomerConsentOtherInvalid,
                    labelCustomerConsentOtherRequired: sharedMessagingCompliance.enUS.labelCustomerConsentOtherRequired,
                    labelCustomerConsentPosTransaction: sharedMessagingCompliance.enUS.labelCustomerConsentPosTransaction,
                    labelDeliveryStatusUpdates: sharedMessagingCompliance.enUS.labelDeliveryStatusUpdates,
                    labelGeneralMarketing: sharedMessagingCompliance.enUS.labelGeneralMarketing,
                    labelMobilePhoneNumberDescription: "PIN Verification",
                    labelMobilePhoneNumber: "Mobile Phone Number",
                    labelVerificationDescriptionUnverified: "Unverified - Will Verify on Save",
                    labelVerificationDescriptionVerified: "Verified",
                    labelVerificationLink: "Unverified - Click to Verify",
                    labelPollingAndVoting: sharedMessagingCompliance.enUS.labelPollingAndVoting,
                    labelSecurityOrSystemAlerts: sharedMessagingCompliance.enUS.labelSecurityOrSystemAlerts,
                    labelSmsUseEligibility: sharedMessagingCompliance.enUS.labelSmsUseEligibility,
                    labelSummaryOfTexting: sharedMessagingCompliance.enUS.labelSummaryOfTexting,
                    labelTextMessageSampleFour: sharedMessagingCompliance.enUS.labelTextMessageSampleFour,
                    labelTextMessageSampleOne: sharedMessagingCompliance.enUS.labelTextMessageSampleOne,
                    labelTextMessageSampleThree: sharedMessagingCompliance.enUS.labelTextMessageSampleThree,
                    labelTextMessageSampleTwo: sharedMessagingCompliance.enUS.labelTextMessageSampleTwo,
                    labelTextMessagingBadExampleOne: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleOne,
                    labelTextMessagingBadExampleTwo: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleTwo,
                    labelTextMessagingBadExampleWhyOne: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleWhyOne,
                    labelTextMessagingBadExampleWhyTwo: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleWhyTwo,
                    labelTextMessagingCompliance: sharedMessagingCompliance.enUS.labelTextMessagingCompliance,
                    labelTextMessagingConsent: sharedMessagingCompliance.enUS.labelTextMessagingConsent,
                    labelTextMessagingConsentDescription: sharedMessagingCompliance.enUS.labelTextMessagingConsentDescription,
                    labelTextMessagingDelivery: sharedMessagingCompliance.enUS.labelTextMessagingDelivery,
                    labelTextMessagingExamples: sharedMessagingCompliance.enUS.labelTextMessagingExamples,
                    labelTextMessagingGoodExampleOne: sharedMessagingCompliance.enUS.labelTextMessagingGoodExampleOne,
                    labelTextMessagingGoodExampleTwo: sharedMessagingCompliance.enUS.labelTextMessagingGoodExampleTwo,
                    labelTextMessagingIneligibleUseCases: sharedMessagingCompliance.enUS.labelTextMessagingIneligibleUseCases,
                    labelTextMessagingPurpose: sharedMessagingCompliance.enUS.labelTextMessagingPurpose,
                    labelTextMessagingPurposeDescription: sharedMessagingCompliance.enUS.labelTextMessagingPurposeDescription,
                    labelTypesOfTextMessaging: sharedMessagingCompliance.enUS.labelTypesOfTextMessaging,
                    mobilePhoneNumberDescription: "Please enter the mobile phone number where you would like your one-time verification code to be sent.",
                    phoneNumberInvalid: shared.enUS.phoneNumberInvalid,
                    phoneNumberRequired: "Phone Number is required.",
                    phoneNumberOnThisAccount: "Please enter a mobile phone number that is not a Tresta phone number.",
                    pleaseSpecify: sharedMessagingCompliance.enUS.pleaseSpecify,
                    replyStop: sharedMessagingCompliance.enUS.replyStop,
                    sampleTextMessageInvalid: sharedMessagingCompliance.enUS.sampleTextMessageInvalid,
                    sampleTextMessageRegulatoryNotice: sharedMessagingCompliance.enUS.sampleTextMessageRegulatoryNotice,
                    sampleTextMessageRequired: sharedMessagingCompliance.enUS.sampleTextMessageRequired,
                    select: "Select",
                    smsIneligibleUseCaseFive: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFive,
                    smsIneligibleUseCaseFiveExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFiveExamples,
                    smsIneligibleUseCaseFour: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFour,
                    smsIneligibleUseCaseFourExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFourExamples,
                    smsIneligibleUseCaseOne: sharedMessagingCompliance.enUS.smsIneligibleUseCaseOne,
                    smsIneligibleUseCaseOneExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseOneExamples,
                    smsIneligibleUseCaseThree: sharedMessagingCompliance.enUS.smsIneligibleUseCaseThree,
                    smsIneligibleUseCaseThreeExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseThreeExamples,
                    smsIneligibleUseCaseTwo: sharedMessagingCompliance.enUS.smsIneligibleUseCaseTwo,
                    smsIneligibleUseCaseTwoExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseTwoExamples,
                    useCaseEligibilityRequired: sharedMessagingCompliance.enUS.useCaseEligibilityRequired,
                    summaryOfTextingInvalid: sharedMessagingCompliance.enUS.summaryOfTextingInvalid,
                    summaryOfTextingRequired: sharedMessagingCompliance.enUS.summaryOfTextingRequired,
                    textMessagingComplianceRequired: sharedMessagingCompliance.enUS.textMessagingComplianceRequired,
                    textMessagingConsentRequired: sharedMessagingCompliance.enUS.textMessagingConsentRequired,
                    textMessagingDescription: sharedMessagingCompliance.enUS.textMessagingDescription,
                    weAppendOptOut: sharedMessagingCompliance.enUS.weAppendOptOut,
                    weWillNotSendOptOut: sharedMessagingCompliance.enUS.weWillNotSendOptOut,
                    messagingDisabledFreeTrial: {
                        title: "Cannot complete local registration while in free trial.",
                        warningPart1: "This form will become available once your free trial is complete."
                    },
                    messagingEnabledForLocalNumber: {
                        title: "Local Phone Number(s) currently have messaging enabled.",
                        warning: "All Local phone numbers must have messaging disabled before you can disable your Local Messaging registration."
                    },
                    useCaseTwoFactorAuthentication: {
                        label:  "Two-Factor Authentication (2FA)",
                        tooltip: "Two-Factor authentication, one-time use password, password reset."
                    },
                    useCaseAccountNotification: {
                        label:  "Account Notifications",
                        tooltip: "All reminders, alerts, and notifications.  (Examples include:  flight delayed, hotel booked, appointment reminders)."
                    },
                    useCaseCustomerCare: {
                        label: "Customer Care",
                        tooltip: "All customer care messaging, including account management and customer support."
                    },
                    useCaseDeliveryNotification: {
                        label: "Delivery Notifications",
                        tooltip: "Information about the status of the delivery of a product or service."
                    },
                    useCaseFraudAlert: {
                        label: "Fraud Alerts",
                        tooltip: "Fraud alert notifications."
                    },
                    useCaseMarketing: {
                        label: "Marketing",
                        tooltip: "Any communication with marketing and/or promotional content."
                    },
                    useCasePollingAndVoting: {
                        label: "Polling and Voting",
                        tooltip: "The sending of surveys and polling/voting campaigns."
                    },
                    useCasePublicServiceAnnouncement: {
                        label: "Public Service Announcements",
                        tooltip: "An informational message that is meant to raise the audience's awareness about an important issue."
                    },
                    useCaseSecurityAlert: {
                        label: "Security Alerts",
                        tooltip: "A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take."
                    },
                },
                messagingComplianceProfileTab: {
                    acknowledgementBulletOne: "all information above is true and accurate",
                    acknowledgementBulletThree: "all calls and texts and content therein will comply with applicable local, state, and federal laws",
                    acknowledgementBulletTwo: "this account will be used for business, non-personal communications only",
                    acknowledgementLineOne: "I attest under penalty of perjury that",
                    acknowledgementSignature: "Type Your Name to Acknowledge Your Agreement",
                    acknowledgementStatement: "I understand that improperly classifying a business account as personal, submitting false information, or failure to abide by applicable laws governing calling, texting and content therein may result in non-delivery of calls/texts, termination of my service and fines of up to $10,000 per violation.",
                    authorizedSignatureRequired: "Agreement is required.",
                    addressRequired: "Address is required.",
                    brandNameRequired: "DBA or Brand Name is required.",
                    businessNameRequired: "Legal Name of Business is required.",
                    businessNumberDescription: "Enter your Business Number (BN). Do NOT enter your Registry ID (also known as Corporation Number) or Federal Tax ID Number (TIN). Ensure that your legal company name is consistent with your registration and is properly spelled. The address you enter should also be the same as that used in registering with the Canada Revenue Agency.",
                    businessNumberRequired: "Business Number is required",
                    charity: "Non-Profit Organization",
                    cityRequired: "City is required.",
                    companySizeRequired: "Company Size is required.",
                    einDescriptionOne: "If you are a US company or a foreign company with a US IRS Employer Identification Number (EIN), please enter that 9-digit number here and ensure that your legal company name is consistent with your IRS registration and is properly spelled. The address you enter should also be the same as that used in registering with the IRS.",
                    einDescriptionTwo: "This is required for text messaging to be enabled on more than 5 local numbers.",
                    einTaxIdInvalid: "EIN / Tax ID is invalid.",
                    einTaxIdRequired: "EIN / Tax ID is required.",
                    emailAddressInvalid: "Please enter a valid email address.",
                    emailAddressRequired: "Please enter an email address.",
                    exchangeRequired: "Exchange is required.",
                    firstNameRequired: "First Name is required.",
                    formOfBusinessRequired: "Form of Business is required.",
                    franchise: "Franchise",
                    government: "Government",
                    industryRequired: "Industry is required.",
                    jobTitleRequired: "Job Title is required.",
                    labelAddressLineOne: "Address",
                    labelAddressLineTwo: "Address 2 (optional)",
                    labelAgreement: "Agreement",
                    labelBrandName: "DBA or Brand Name",
                    labelBusinessName: "Legal Name of Business",
                    labelBusinessNumber: "Business Number",
                    labelCity: "City",
                    labelCountry: "Country",
                    labelCountryOfRegistration: "Country of Registration",
                    labelEinTaxId: "EIN",
                    labelEmailAddress: "Email Address",
                    labelExchange: "Exchange",
                    labelFirstName: "First Name",
                    labelFormOfBusiness: "Form of Business",
                    labelIndustry: "Industry",
                    labelJobTitle: "Job Title",
                    labelLastName: "Last Name",
                    labelPhoneNumber: "Phone Number",
                    labelPointOfContact: "Point of Contact for any Compliance or Fraud Inquiries",
                    labelPostalCode: "Postal Code",
                    labelProvince: "Province",
                    labelProvinceOfIncorporation: "Province of Incorporation",
                    labelRegistryId: "Registry ID",
                    labelSameAsBusinessAddress: "Same as Business Address",
                    labelState: "State",
                    labelStateOfIncorporation: "State of Incorporation",
                    labelStockSymbol: "Stock Symbol",
                    labelWebsiteUrl: "Website URL",
                    labelZipCode: "Zip",
                    lastNameRequired: "Last Name is required.",
                    locationDescriptionOne: "Please provide the address associated with your ",
                    locationDescriptionThree: "which could be different than the address of your payment method.",
                    locationDescriptionTwo: "business registration,",
                    nonProfitWarningTitle: "Registering as a Non-Profit Organization",
                    nonProfitWarningDescription: "This type should be selected only by organizations with the NON-PROFIT status. Organizations with the NOT-FOR-PROFIT status should instead register as a Private Company.",
                    phoneNumberInvalid: shared.enUS.phoneNumberInvalid,
                    phoneNumberRequired: "Phone Number is required.",
                    postalCodeInvalid: "Please enter a valid postal code.",
                    postalCodeRequired: "Postal code is required.",
                    privateCompany: "Registered Private Corporation",
                    provinceRequired: "Province is required.",
                    provinceOfIncorporationRequired: "Province of Incorporation is required.",
                    publiclyTraded: "Publicly Traded Corporation",
                    publicUtility: "Public Utility",
                    registryIdTooltip: "Registry Id is no longer accepted by The Campaign Registry",
                    select: "Select",
                    soleProprietor: "Sole Proprietor",
                    stateRequired: "State is required.",
                    stateOfIncorporationRequired: "State of Incorporation is required.",
                    stockSymbolRequired: "Stock Symbol is required.",
                    verifiedBrandTooltip: "Please contact support at <br/> +1 (844) 2-TRESTA to update.",
                    websiteDescription: "Wireless carriers require a website presence in order to verify your identity and permit messaging to their customers. Please provide the url of your business website, such as www.tresta.com. If you do not have a business website, you may use a social media URL instead, for example, www.facebook.com/Tresta.  If you do not have a business website or social media profile, you may provide a personal social media profile, but Tresta cannot guarantee that it will be accepted/approved for messaging by the wireless carriers.",
                    websiteUrlInvalid: "Please enter a valid URL.",
                    websiteUrlRequired: "Website URL is required.",
                    zipCodeInvalid: "Please enter a valid ZIP code.",
                    zipCodeRequired: "Please enter a ZIP code.",
                    none: "NONE",
                    nasdaq: "NASDAQ",
                    nyse: "NYSE",
                    amex: "AMEX",
                    amx: "AMX",
                    asx: "ASX",
                    b3: "B3",
                    bme: "BME",
                    bse: "BSE",
                    fra: "FRA",
                    icex: "ICEX",
                    jpx: "JPX",
                    jse: "JSE",
                    krx: "KRX",
                    lon: "LON",
                    nse: "NSE",
                    omx: "OMX",
                    sehk: "SEHK",
                    sgx: "SGX",
                    sse: "SSE",
                    sto: "STO",
                    swx: "SWX",
                    szse: "SZSE",
                    tsx: "TSX",
                    twse: "TWSE",
                    vse: "VSE",
                    other: "OTHER",
                    agriculture: "Agriculture",
                    architecture: "Architecture",
                    arts: "Arts and Artists",
                    beauty: "Beauty and Personal Care",
                    business: "Business Services",
                    computers: "Computers and Electronics",
                    construction: "Construction",
                    consulting: "Consulting",
                    creative: "Creative Services/Agency",
                    daily: "Daily Deals/E-Coupons",
                    eCommerce: "ECommerce",
                    education: "Education and Training",
                    entertainment: "Entertainment and Events",
                    gambling: "Gambling",
                    games: "Gaming",
                    health: "Health and Fitness",
                    home: "Home and Garden",
                    insurance: "Insurance",
                    legal: "Legal",
                    manufacturing: "Manufacturing",
                    marketing: "Marketing and Advertising",
                    media: "Media and Publishing",
                    medical: "Medical, Dental, and Healthcare",
                    music: "Music and Musicians",
                    non: "Non-Profit",
                    pharmaceuticals: "Pharmaceuticals",
                    photo: "Photo and Video",
                    politics: "Politics",
                    professional: "Professional Services",
                    "public": "Public Relations",
                    real: "Real Estate",
                    recruitment: "Recruitment and Staffing",
                    religion: "Religion",
                    restaurant: "Restaurant and Venue",
                    retail: "Retail",
                    social: "Social Networks and Online Communities",
                    software: "Software and Web App",
                    sports: "Sports",
                    telecommunications: "Telecommunications",
                    travel: "Travel and Transportation",
                    vitamin: "Vitamin Supplements",
                    energy: "Energy and Utilities",
                    financial: "Financial and Banking Services",
                    food: "Food Services"
                },
                messagingComplianceTollFreeMessagingTab: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    exampleFourDescriptionOne: sharedMessagingCompliance.enUS.exampleFourDescriptionOne,
                    exampleFourDescriptionTwo: sharedMessagingCompliance.enUS.exampleFourDescriptionTwo,
                    exampleNote: sharedMessagingCompliance.enUS.exampleNote,
                    exampleOneDescriptionOne: sharedMessagingCompliance.enUS.exampleOneDescriptionOne,
                    exampleOneDescriptionTwo: sharedMessagingCompliance.enUS.exampleOneDescriptionTwo,
                    exampleThreeDescriptionOne: sharedMessagingCompliance.enUS.exampleThreeDescriptionOne,
                    exampleThreeDescriptionTwo: sharedMessagingCompliance.enUS.exampleThreeDescriptionTwo,
                    exampleTwoDescriptionOne: sharedMessagingCompliance.enUS.exampleTwoDescriptionOne,
                    exampleTwoDescriptionTwo: sharedMessagingCompliance.enUS.exampleTwoDescriptionTwo,
                    labelAccountNotifications: sharedMessagingCompliance.enUS.labelAccountNotifications,
                    labelAppointmentReminders: sharedMessagingCompliance.enUS.labelAppointmentReminders,
                    labelConversationMessaging: sharedMessagingCompliance.enUS.labelConversationMessaging,
                    labelCustomerConsentEmployeeRelationship: sharedMessagingCompliance.enUS.labelCustomerConsentEmployeeRelationship,
                    labelCustomerConsentInitiateConversation: sharedMessagingCompliance.enUS.labelCustomerConsentInitiateConversation,
                    labelCustomerConsentLeadCaptureForms: sharedMessagingCompliance.enUS.labelCustomerConsentLeadCaptureForms,
                    labelCustomerConsentOnlineCheckout: sharedMessagingCompliance.enUS.labelCustomerConsentOnlineCheckout,
                    labelCustomerConsentOther: sharedMessagingCompliance.enUS.labelCustomerConsentOther,
                    labelCustomerConsentOtherInvalid: sharedMessagingCompliance.enUS.labelCustomerConsentOtherInvalid,
                    labelCustomerConsentOtherRequired: sharedMessagingCompliance.enUS.labelCustomerConsentOtherRequired,
                    labelCustomerConsentPosTransaction: sharedMessagingCompliance.enUS.labelCustomerConsentPosTransaction,
                    labelDeliveryStatusUpdates: sharedMessagingCompliance.enUS.labelDeliveryStatusUpdates,
                    labelGeneralMarketing: sharedMessagingCompliance.enUS.labelGeneralMarketing,
                    labelHowManyTextsPerDay: sharedMessagingCompliance.enUS.labelHowManyTextsPerDay,
                    labelPercentOfTextsToCanada: sharedMessagingCompliance.enUS.labelPercentOfTextsToCanada,
                    labelPollingAndVoting: sharedMessagingCompliance.enUS.labelPollingAndVoting,
                    labelSecurityOrSystemAlerts: sharedMessagingCompliance.enUS.labelSecurityOrSystemAlerts,
                    labelSummaryOfTexting: sharedMessagingCompliance.enUS.labelSummaryOfTexting,
                    labelTextMessageSampleFour: sharedMessagingCompliance.enUS.labelTextMessageSampleFour,
                    labelTextMessageSampleOne: sharedMessagingCompliance.enUS.labelTextMessageSampleOne,
                    labelTextMessageSampleThree: sharedMessagingCompliance.enUS.labelTextMessageSampleThree,
                    labelTextMessageSampleTwo: sharedMessagingCompliance.enUS.labelTextMessageSampleTwo,
                    labelTextMessagingBadExampleOne: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleOne,
                    labelTextMessagingBadExampleTwo: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleTwo,
                    labelTextMessagingBadExampleWhyOne: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleWhyOne,
                    labelTextMessagingBadExampleWhyTwo: sharedMessagingCompliance.enUS.labelTextMessagingBadExampleWhyTwo,
                    labelTextMessagingCompliance: sharedMessagingCompliance.enUS.labelTextMessagingCompliance,
                    labelTextMessagingConsent: sharedMessagingCompliance.enUS.labelTextMessagingConsent,
                    labelTextMessagingConsentDescription: sharedMessagingCompliance.enUS.labelTextMessagingConsentDescription,
                    labelTextMessagingDelivery: sharedMessagingCompliance.enUS.labelTextMessagingDelivery,
                    labelTextMessagingExamples: sharedMessagingCompliance.enUS.labelTextMessagingExamples,
                    labelTextMessagingIneligibleUseCases: sharedMessagingCompliance.enUS.labelTextMessagingIneligibleUseCases,
                    labelTextMessagingGoodExampleOne: sharedMessagingCompliance.enUS.labelTextMessagingGoodExampleOne,
                    labelTextMessagingGoodExampleTwo: sharedMessagingCompliance.enUS.labelTextMessagingGoodExampleTwo,
                    labelTextMessagingPurpose: sharedMessagingCompliance.enUS.labelTextMessagingPurpose,
                    labelTextMessagingPurposeDescription: sharedMessagingCompliance.enUS.labelTextMessagingPurposeDescription,
                    labelSmsUseEligibility: sharedMessagingCompliance.enUS.labelSmsUseEligibility,
                    labelTypesOfTextMessaging: sharedMessagingCompliance.enUS.labelTypesOfTextMessaging,
                    majorityOfMessagesSentToCanadaRequired: sharedMessagingCompliance.enUS.majorityOfMessagesSentToCanadaRequired,
                    messagingTextsPerDayRequired: sharedMessagingCompliance.enUS.messagingTextsPerDayRequired,
                    pleaseSpecify: sharedMessagingCompliance.enUS.pleaseSpecify,
                    replyStop: sharedMessagingCompliance.enUS.replyStop,
                    sampleTextMessageInvalid: sharedMessagingCompliance.enUS.sampleTextMessageInvalid,
                    sampleTextMessageRegulatoryNotice: sharedMessagingCompliance.enUS.sampleTextMessageRegulatoryNotice,
                    sampleTextMessageRequired: sharedMessagingCompliance.enUS.sampleTextMessageRequired,
                    select: "Select",
                    smsIneligibleUseCaseFive: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFive,
                    smsIneligibleUseCaseFiveExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFiveExamples,
                    smsIneligibleUseCaseFour: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFour,
                    smsIneligibleUseCaseFourExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseFourExamples,
                    smsIneligibleUseCaseOne: sharedMessagingCompliance.enUS.smsIneligibleUseCaseOne,
                    smsIneligibleUseCaseOneExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseOneExamples,
                    smsIneligibleUseCaseThree: sharedMessagingCompliance.enUS.smsIneligibleUseCaseThree,
                    smsIneligibleUseCaseThreeExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseThreeExamples,
                    smsIneligibleUseCaseTwo: sharedMessagingCompliance.enUS.smsIneligibleUseCaseTwo,
                    smsIneligibleUseCaseTwoExamples: sharedMessagingCompliance.enUS.smsIneligibleUseCaseTwoExamples,
                    useCaseEligibilityRequired: sharedMessagingCompliance.enUS.useCaseEligibilityRequired,
                    summaryOfTextingInvalid: sharedMessagingCompliance.enUS.summaryOfTextingInvalid,
                    summaryOfTextingRequired: sharedMessagingCompliance.enUS.summaryOfTextingRequired,
                    textMessagingComplianceRequired: sharedMessagingCompliance.enUS.textMessagingComplianceRequired,
                    textMessagingConsentRequired: sharedMessagingCompliance.enUS.textMessagingConsentRequired,
                    textMessagingDescription: sharedMessagingCompliance.enUS.textMessagingDescription,
                    zeroToFiveHundred: "0-500",
                    fiveHundredAndOneToOneThousand: "501-1000",
                    oneThousandAndOneToTwoThousand: "1001-2000",
                    moreThanTwoThousand: "More than 2000/day",
                    weAppendOptOut: sharedMessagingCompliance.enUS.weAppendOptOut,
                    weWillNotSendOptOut: sharedMessagingCompliance.enUS.weWillNotSendOptOut,
                    messagingDisabledFreeTrial: {
                        title: "Cannot complete toll-free registration while in free trial.",
                        warningPart1: "This form will become available once your free trial is complete."
                    },
                    messagingEnabledForTollfreeNumber: {
                        title: "Toll-Free Phone Number(s) currently have messaging enabled.",
                        warning: "All Toll-Free phone numbers must have messaging disabled before you can disable your Toll-Free Messaging registration."
                    },
                    useCase: {
                        twoFactorAuthentication: "Two-Factor Authentication",
                        appNotifications: "App Notifications",
                        appointments: "Appointments",
                        auctions: "Auctions",
                        autoRepairServices: "Auto Repair Services",
                        bankTransfers: "Bank Transfers",
                        billing: "Billing",
                        bookingConfirmations: "Booking Confirmations",
                        businessUpdates: "Business Updates",
                        careerTraining: "Career Training",
                        chatbot: "Chatbot",
                        conversationalAlerts: "Conversational / Alerts",
                        courierServicesDeliveries: "Courier Services & Deliveries",
                        covid19Alerts: "COVID-19 Alerts",
                        emergencyAlerts: "Emergency Alerts",
                        eventsPlanning: "Events & Planning",
                        financialServices: "Financial Services",
                        fraudAlerts: "Fraud Alerts",
                        fundraising: "Fundraising",
                        generalMarketing: "General Marketing",
                        generalSchoolUpdates: "General School Updates",
                        healthcareAlerts: "Healthcare Alerts",
                        housingCommunityUpdates: "Housing Community Updates",
                        hrStaffing: "HR / Staffing",
                        insuranceServices: "Insurance Services",
                        jobDispatch: "Job Dispatch",
                        legalServices: "Legal Services",
                        motivationalReminders: "Motivational Reminders",
                        notaryNotifications: "Notary Notifications",
                        orderNotifications: "Order Notifications",
                        political: "Political",
                        publicWorks: "Public Works",
                        realEstateServices: "Real Estate Services",
                        religiousServices: "Religious Services",
                        repairAndDiagnosticsAlerts: "Repair and Diagnostics Alerts",
                        rewardsProgram: "Rewards Program",
                        surveys: "Surveys",
                        systemAlerts: "System Alerts",
                        votingReminders: "Voting Reminders",
                        waitListAlerts: "Waitlist Alerts",
                        webinarReminders: "Webinar Reminders",
                        workshopAlerts: "Workshop Alerts"
                    }
                },
                authorizedSignatureActionModal: {
                    acknowledgementSignature: "Type Your Name to Acknowledge Your Agreement",
                    authorizedSignatureRequired: "Agreement is required.",
                    headerText: "Agreement Required",
                    warningTitle: "I attest under penalty of perjury that all information in this form is true and accurate.",
                    warningMessage: "I understand that submitting false information may result in non-delivery of calls/texts, termination of my service and fines of up to $10,000 per violation.",
                },
                otpVerificationActionModal: {
                    headerText: "Local Messaging Verification",
                    smsPinCaptureDescription: "Please enter the PIN sent to {{mobilePhoneNumber}}. If you would like the PIN to be sent to a different number, please click Cancel and update the \"PIN Verification\" field on Local Messaging with your desired number.",
                    smsPinCaptureTitle: "SMS Registration PIN",
                    smsPinInputLabel: "SMS PIN:",
                    smsPinRequired: "SMS PIN cannot be empty",
                    smsPinResend: "Resend SMS PIN"
                },
                confirmDisabledNumbersModal: {
                    disabledSmsMessage: "To comply with new regulations, messaging has been disabled for all local number(s) <b>except</b> the one below. A System Admin may change which number is enabled for messaging in the Phone Number Settings at any time.",
                    requirementMessage: "Thank you for updating your information.  Unfortunately, wireless carriers such as T-Mobile and Verizon have put in place new restrictions on who can text customers on their networks.  Personal and sole proprietor accounts are now only allowed to use messaging on one (1) local phone number.  These restrictions are industry-wide and are not imposed by Tresta.",
                    smsDisabledTitle: "SMS Notice"
                },
                tcrBrandRegistrationFailures: {
                    invalidInput: "",
                    duplicateRecord: "Duplicate record detected. This maybe be triggered by attempts to submit two identical brand registration concurrently.",
                    brandLimitExceeded: "Exceeded the maximum number of brands for your CSP account.",
                    countryRestricted: "Brand's country is blacklisted and not allowed to be registered in TCR.",
                    soleProprietorNotEnabled: "CSP account not enabled for sole proprietor feature.",
                    duplicateBrand: "Duplicate threshold detected while updating/creating brand record.",
                    unsupportedPhoneNumber: "Unsupported phone number or mobile phone number. E.g. SP brand requires US/CA wireless phone number in mobile phone number.",
                    unsupportedEmailAddress: "Unsupported email address. Disposable email is not permitted.",
                    invalidAddress: "Invalid street address. Must ensure all address fields collectively form a valid postal address.",
                    unsupportedAddress: "Unsupported street address. E.g. SP brand requires US/CA street address.",
                    invalidBrandName: "Obfuscation check failed on brand fields, including first name, last name, and brand name for SP brand",
                    tcrSystemError: "TCR internal system error. Please contact TCR support.",
                    tempSystemError: "Temporary system error. Unable to complete brand registration due to temporary system error. Please retry request in 30 seconds.",
                    externalVetUnavailable: "The external vetting provider is unable to accept request at this time. Please retry request in 30 seconds."
                },
                voicemailBoxes: {
                    deactivateTooltip: "Cannot delete system voicemail boxes.",
                    deactivateUserTooltip: "To remove this voicemail box, deactivate the user.",
                    deactivateGroupTooltip: "To remove this voicemail box, deactivate the group.",
                    header: "Voicemail Boxes",
                    noResults: shared.enUS.noResultsFound,
                    noVoicemailBoxes: "No Voicemail Boxes to Display",
                    search: shared.enUS.search,
                },
                addVoicemailBox: {
                    add: shared.enUS.add,
                    cancel: shared.enUS.cancel,
                    greetingsTab: "Greetings",
                    headerAdd: "Add Voicemail Box",
                    headerEdit: "Edit Voicemail Box",
                    lastMember: "Cannot remove user from their own voicemail box.",
                    lastMemberSelf: "Cannot remove yourself from your own voicemail box.",
                    name: "Name",
                    nameInline: "Name: ",
                    nameRequired: "Name is required.",
                    nameNotUnique: "This name is already used by another voicemail box.",
                    save: shared.enUS.save,
                    subscribersTab: "Subscribers",
                    type: "Type: ",
                    validationPleaseFix: "Please correct the problems in these sections and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these sections:",
                },
                addVoicemailBoxGreetings: {
                    addItemTitle: "+ Add Another",
                    currentTooltip: "Cannot delete the current greeting.",
                    default: "Current Greeting",
                    deleteTooltip: "Cannot delete the default greeting.",
                    editTooltip: "Cannot edit the default greeting.",
                    nameRequired: "Name is required.",
                    nameNotUnique: "This name is already used by another greeting.",
                    setDefault: "Make Current",
                },
                users: {
                    cancelInvite: "Cancel Invite",
                    deactivateSelf: "Cannot deactivate yourself.",
                    header: "Users",
                    invitedUser: "Invited User",
                    noResults: shared.enUS.noResultsFound,
                    noUsers: "No Users to Display",
                    reactivate: "Reactivate User",
                    resendInvite: "Resend Invite",
                    search: shared.enUS.search,
                },
                addUserModal: {
                    addAnother: "+ Add Another",
                    addUser: "Add User",
                    additionalCharge: "You'll be charged a monthly fee for each additional user.",
                    emailAddressDuplicate: "You’ve already provided this email in the invite list.",
                    forDetails: "For details, please visit our &nbsp;",
                    labelBillingAdmin: "Do you want this user to be a Billing Admin?",
                    labelEmail: "Email Address",
                    labelSystemAdmin: "Do you want this user to be a System Admin?",
                    maxUserInvites: "Limit of 10 user invites per batch.",
                    pricingPage: "pricing page",
                    remove: "Remove",
                    tooltipBilling: "Billing Admins have access to Billing configurations in Tresta and can view and alter all billing settings, including statements, payment methods, one-time payments and account status changes like closing or reopening your account.",
                    tooltipSystem: "System Admins have access to System configurations in Tresta and can view and alter all system settings. System Admins may make changes that impact your monthly charges, but they do not have the ability to view statements or alter billing settings.",
                },
                deactivateUserModal: {
                    areYouSure_cancel: "Are you sure you want to cancel this invite?",
                    areYouSure_deactivate: "Are you sure you want to deactivate this user?",
                    callsRoutedToUser: "Calls are being routed to {{firstName}} (or their voicemail box) from",
                    cancel: shared.enUS.cancel,
                    cancelInvite: "Cancel Invite",
                    deactivateHeader: "Deactivate User",
                    onlyAuthorizedMessager: "{{firstName}} is the only person receiving texts from these numbers",
                    onlyMemberOfUserGroup: "{{firstName}} is the only member of these groups",
                    onlySubscriberCallRecordings: "{{firstName}} is the only subscriber of these numbers' call recordings",
                    onlySubscriberVoicemailBoxes: "{{firstName}} is the only subscriber of these voicemail boxes",
                    pleaseSelect: shared.enUS.pleaseSelect,
                    proceedWarning: "To proceed, you must designate another user or group to take {{firstName, makePossessive}} place in the following scenarios.",
                    proceedInstruct: "Alternatively, you can manually resolve these conflicts and then come back to try deactivating {{firstName}} again.",
                    selectionRequired: "Please make a selection to continue",
                    sendCallsToQuestion: "Select a user or group to replace {{firstName}} in the below scenarios",
                },
                reactivateUserModal: {
                    areYouSure_reactivate: "Are you sure you want to reactivate this user?",
                    areYouSure_resend: "Are you sure you want to send a new invite?",
                    cancel: shared.enUS.cancel,
                    monthlyCharges: "Monthly charges will resume as listed on our&nbsp;",
                    reactivateUser: "Reactivate User",
                    resendInvite: "Resend Invite",
                    pricingPage: "pricing page",
                },
                editUser: {
                    cancel: shared.enUS.cancel,
                    devicesTab: "Devices",
                    groupsTab: "Groups",
                    header: "Edit User",
                    profileTab: "Profile",
                    subscriptionsTab: "Subscriptions",
                    validationPleaseFix: "Please correct the problems in these sections and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these sections:",
                    voicemailTab: "Voicemail"
                },
                userGroups: {
                    deactivateTooltip: "Cannot deactivate system groups.",
                    header: "Groups",
                    member: "{{count}} Member",
                    member_plural: "{{count}} Members",
                    noResults: shared.enUS.noResultsFound,
                    noUserGroups: "No Groups to Display",
                    search: shared.enUS.search,
                },
                deactivateUserGroupModal: {
                    areYouSure: "Are you sure you want to deactivate this group?",
                    callsRoutedToUserGroup: "Calls are being routed to {{groupName}} (or their voicemail box) from",
                    deactivateHeader: "Deactivate Group",
                    member: "{{count}} Member",
                    member_plural: "{{count}} Members",
                    onlyAuthorizedMessager: "{{groupName}} is the only one receiving texts from these numbers",
                    onlySubscriberCallRecordings: "{{groupName}} is the only subscriber of these numbers' call recordings",
                    onlySubscriberVoicemailBoxes: "{{groupName}} is the only subscriber of these voicemail boxes",
                    pleaseSelect: shared.enUS.pleaseSelect,
                    proceedWarning: "To proceed, you must designate another user or group to take {{groupName, makePossessive}} place in the following scenarios.",
                    proceedInstruct: "Alternatively, you can manually resolve these conflicts and then come back to try deactivating {{groupName}} again.",
                    selectionRequired: "Please make a selection to continue",
                    sendCallsToQuestion: "Select a user or group to replace {{groupName}} in the below scenarios",
                },
                reactivateUserGroupModal: {
                    areYouSure: "Are you sure you want to reactivate this group?",
                    member: "{{count}} Member",
                    member_plural: "{{count}} Members",
                    reactivateGroup: "Reactivate Group",
                },
                addUserGroup: {
                    add: shared.enUS.add,
                    cancel: shared.enUS.cancel,
                    headerAdd: "Add Group",
                    headerEdit: "Edit Group",
                    profileTab: "Profile",
                    save: shared.enUS.save,
                    validationPleaseFix: "Please correct the problems in these sections and try again.",
                    validationTabDescription: "Oops, it looks like there are some issues with these sections:",
                    voicemailTab: "Voicemail"
                },
                userGroupProfile: {
                    allUsersGroupMember: "Cannot modify members of All Users group.",
                    billingAdminsGroupMember: "Cannot remove yourself from Billing Admins group.",
                    groupNameTooltip: "Cannot rename system groups.",
                    nameRequired: "Please enter a group name.",
                    nameNotUnique: "Group name is already in use.",
                    labelGroupMembers: "Members",
                    labelGroupName: "Name",
                    labelGroupNameDisabled: "Name:",
                    lastItemTooltip: "At least one member is required.",
                    systemAdminsGroupMember: "Cannot remove yourself from System Admins group.",
                },
                userGroupVoicemail: {
                    cancel: shared.enUS.cancel,
                    greetingsTab: "Greetings",
                    save: shared.enUS.save,
                    subscribersTab: "Subscribers",
                },
                userGroupVoicemailGreetings: {
                    addItemTitle: "+ Add Another",
                    currentTooltip: "Cannot delete the current greeting.",
                    default: "Current Greeting",
                    deleteTooltip: "Cannot delete the default greeting.",
                    editTooltip: "Cannot edit the default greeting.",
                    setDefault: "Make Current",
                },
                numberPicker: {
                    alternatePrefix: "We don't have any {{prefix}} numbers in stock right now.",
                    availableNumbers: "Available Numbers",
                    back: shared.enUS.back,
                    default: shared.enUS.default,
                    failedReservation: "We were unable to reserve the following number: {{phoneNumber}}",
                    failedProvision: "We were unable to provision the following number: {{phoneNumber}}",
                    maxNumbersCanAdd: "You can only select {{maxNumber}} phone numbers",
                    messagingDisabled: "Messaging Disabled",
                    mustSelectAPhoneNumber: "Please select at least one phone number.",
                    next: "Refresh Numbers",
                    noNpaResults: "We don't have any {{city}} {{npa}} numbers in stock right now, but we do have the {{npa}} area code available in these cities that are local to {{city}}.",
                    noRegionCityResults: "We don't have any {{city}} {{npa}} numbers in stock right now, but we do have these area codes that are local to {{city}} {{npa}}.",
                    noRepeatingNumberResults: "We don't have any repeating numbers in the {{npa}} area code.",
                    noResultsFound: shared.enUS.noResultsFound,
                    noResults: "We don't have any {{city}} {{npa}} numbers in stock right now. To inquire about backorder status, please call +1 (844) 2-TRESTA.",
                    noVanityResults: 'Sorry, we can’t find any numbers that {{vanityType}} "{{vanityString}}"',
                    noTollFreeVanityResults: 'Sorry, we can’t find any numbers that {{vanityType}} "{{vanityString}}" - even when we expanded your search to include all toll-free prefixes.',
                    numberPickerLoading: "Loading...",
                    prefix: 'Prefix',
                    pricingMessage: "You'll be charged a monthly fee for each additional phone number. For details, please visit our ",
                    pricingPage: "pricing page.",
                    province: shared.enUS.regionType.province,
                    resultsHere: "Search results will appear here.",
                    repeating: shared.enUS.repeating,
                    search: shared.enUS.search,
                    selectCity: "City",
                    selectCountry: "Country",
                    selectedNumbers: "Selected Numbers",
                    selectNpa: "Area Code",
                    selectPrefix: "Area Code",
                    selectVanity: "Vanity Number",
                    state: shared.enUS.regionType.state,
                    unavailableButLocalEquivalent: "We don't have any {{city}} {{npa}} numbers currently, but we do have other area codes that are local equivalents to {{city}} {{npa}}:",
                    unavailableInCity: "We don't have any {{npa}} numbers in {{city}} currently, but we do have {{npa}} numbers available in these cities.",
                    unavailableInRegionCity: "Sorry, but we don't have any {{city}} {{npa}} numbers. We automatically searched for numbers in other area codes and cities that are local equivalents to your selection, but we don't have any of those in stock, either. <br/> <br/> However, in most cases, we can replenish out-of-stock areas in as little as 3-5 business days. For assistance with a custom order, please call +1 (844) 2-TRESTA.",
                    vanity: shared.enUS.vanity,
                },
                signupNumberPicker: {
                    alternatePrefix: "We don't have any {{prefix}} numbers in stock right now.",
                    availableNumbers: "Available Numbers",
                    back: shared.enUS.back,
                    default: shared.enUS.default,
                    failedReservation: "We were unable to reserve the following number: {{phoneNumber}}",
                    failedProvision: "We were unable to provision the following number: {{phoneNumber}}",
                    maxNumbersCanAdd: "You can only select {{maxNumber}} phone numbers",
                    mustSelectAPhoneNumber: "Please select at least one phone number.",
                    next: "Refresh Numbers",
                    noNpaResults: "We don't have any {{city}} {{npa}} numbers in stock right now, but we do have the {{npa}} area code available in these cities that are local to {{city}}.",
                    noRegionCityResults: "We don't have any {{city}} {{npa}} numbers in stock right now, but we do have these area codes that are local to {{city}} {{npa}}.",
                    noRepeatingNumberResults: "We don't have any repeating numbers in the {{npa}} area code.",
                    noResultsFound: shared.enUS.noResultsFound,
                    noResults: "We don't have any {{city}} {{npa}} numbers in stock right now. To inquire about backorder status, please call +1 (844) 2-TRESTA.",
                    noVanityResults: 'Sorry, we can’t find any numbers that {{vanityType}} "{{vanityString}}"',
                    numberPickerLoading: "Loading...",
                    prefix: 'Prefix',
                    pricingMessage: "You'll be charged a monthly fee for each additional phone number. For details, please visit our ",
                    province: shared.enUS.regionType.province,
                    resultsHere: "Search results will appear here.",
                    repeating: shared.enUS.repeating,
                    selectCity: "City",
                    selectCountry: "Country",
                    selectedNumbers: "Selected Numbers",
                    selectNpa: "Area Code",
                    selectPrefix: "Area Code",
                    selectVanity: "Vanity Number",
                    state: shared.enUS.regionType.state,
                    unavailableButLocalEquivalent: "We don't have any {{city}} {{npa}} numbers currently, but we do have other area codes that are local equivalents to {{city}} {{npa}}:",
                    unavailableInCity: "We don't have any {{npa}} numbers in {{city}} currently, but we do have {{npa}} numbers available in these cities.",
                    unavailableInRegionCity: "Sorry, but we don't have any {{city}} {{npa}} numbers. We automatically searched for numbers in other area codes and cities that are local equivalents to your selection, but we don't have any of those in stock, either. <br/> <br/> However, in most cases, we can replenish out-of-stock areas in as little as 3-5 business days. For assistance with a custom order, please call +1 (844) 2-TRESTA.",
                    vanity: shared.enUS.vanity,
                },
                paymentMethod: {
                    paymentMethods: "Payment Methods",
                    noPaymentDuringTrial: "One-time Payments cannot be made<br/>during the free trial period.",
                    noBalanceDue: "No balance due at this time.",
                    makeAPayment: "Make a Payment",
                    defaultCard: "Default Card",
                    makeDefaultCard: "Make Default",
                    deletePaymentMethodTitle: "Delete Payment Method",
                    deletePaymentMethodContent: "Are you sure you want to delete this payment method?",
                    editPaymentMethod: "Edit Payment Method",
                    defaultTooltip: 'Cannot delete the default payment method.',
                    lastItemTooltip: 'At least one payment method is required.',
                    lastValidItemTooltip: 'Cannot delete the last valid payment method.',
                    endingIn: "Ending in {{digits}}",
                },
                addPaymentMethod: {
                    cancel: 'Cancel',
                    nameOnCard: "Name on Card",
                    nameOnCardRequired: "Name on card is required.",
                    cardNumber: "Card Number",
                    cardNumberInvalid: "Invalid card number.",
                    cardNumberRequired: "Card number is required.",
                    cardType: "Card Type",
                    country: "Country",
                    countryInvalid: "Please select a valid country.",
                    cvv: "Card Verification Code (CVV)",
                    cvvInvalid: "Invalid CVV",
                    cvvRequiredToAdd: "CVV is required.",
                    cvvRequiredToEdit: "Please re-enter your CVV to save.",
                    declinedTitle: "Card authorization failed.",
                    declinedMessage: "Please verify your card details and try again.",
                    duplicateCard: "This card is already on your account.",
                    endingIn: "Ending in {{digits}}",
                    expired: "Expired",
                    expirationDate: "Expiration Date",
                    expirationDateInvalid: "Invalid expiration date.",
                    genericFailureTitle: "We’ve encountered a problem.",
                    genericFailureMessage: "Please call " + shared.enUS.customerServiceNumberFormatted + " for assistance.",
                    headerAdd: "Add Payment Method",
                    headerEdit: "Edit Payment Method",
                    makeASelection: "Please make a selection.",
                    noSpecialCharacters: "Special characters are not allowed.",
                    postalCode: "Postal Code",
                    postalCodeInvalid: "Invalid postal code.",
                    postalCodeRequired: "Postal code is required.",
                    zipCode: "Zip Code",
                    zipCodeInvalid: "Invalid zip code.",
                    zipCodeRequired: "Zip code is required.",
                },
                makeAPayment: {
                    amount: "Amount",
                    currentBalance: "Current Balance of ",
                    endingIn: " ending in ",
                    headerButtonText: 'Next',
                    otherAmount: "Other Amount",
                    pastDueBalance: "Past Due Balance of ",
                    paymentMethod: "Payment Method",
                    specifyAmount: "Specify Amount",
                    title: "Make a Payment",
                    validationIsInFreeTrial: "You cannot make a payment during the free trial.",
                    validationNoBalance: "Your account has a current balance of $0. To make a one-time payment, you must have an outstanding balance.",
                    validationNoPaymentMethod_part1: "You don't have a valid payment method on file. To make a one-time payment, please ",
                    validationNoPaymentMethod_part2: "add a new payment method or update your current payment method.",
                    validationCannotExceedCurrentBalance: 'Amount cannot exceed current balance.',
                    declinedTitle: "Payment failed",
                    declinedMessage: "Verify the details above and try again.",
                    genericFailureTitle: "We’ve encountered an issue",
                    genericFailureMessage: "Please call " + shared.enUS.customerServiceNumberFormatted + " for assistance processing your payment."
                },
                resetPassword: {
                    changePassword: "Change Password",
                    chooseNewPassword: "Please set a new password",
                    confirmPassword: "Confirm Password",
                    confirmPasswordRequired: "Please re-enter your new password.",
                    confirmPasswordDoesNotMatch: "Passwords do not match.",
                    emailAddress: "Email Address",
                    mobileUnsupportedTitle: "Sorry! You can’t reset your password on a mobile device.",
                    mobileUnsupportedMessage: "Use your desktop or laptop computer.",
                    needHelp: "Need Help? Call&nbsp;",
                    newPasswordRequired: "Please enter a password.",
                    newPasswordInvalid: "Password must contain 8-32 characters, 1 upper case letter, 1 lower case letter & 1 number.",
                    password: "New Password",
                    passwordResetConfirmation: "Password Successfully Reset!",
                    passwordResetConfirmationMessage: "You may now sign in with your new password.",
                    resetPassword: "Reset Password",
                    signIn: "Sign In"
                },
                signupAside: {
                    asideHeader: "Let's Get Started",
                    asideMainText: "Sign up for your virtual business phone number, and get your first 30 days free.",
                    asideCaptionText: "Already have an account?",
                    asideCaptionTitle: "Click to Sign In",
                    asideEmail: "Email Address",
                    asideContinue: "Continue",
                    headerText: {
                        email: "Enter Your Email",
                        numberLocal: "Get a Local Number",
                        numberPort: "Transfer a Number",
                        numberTollfree: "Get a Toll-Free Number",
                        numberType: "Select a Type",
                        paymentMethod: "Payment Method",
                        profile: "User Profile",
                    },
                    messageText: {
                        email: "You can add other users to your account after signup.",
                        numberLocal: "Pick one number for now. You can add more numbers after signup.",
                        numberPort: "Port in your existing business phone number free of charge. You’ll still own the number and can port it back out at any time.",
                        numberTollfree: "Pick one number for now. You can add more numbers after signup.",
                        numberType: "Tresta includes unlimited calling and texting regardless of whether you choose a local or toll-free phone number.",
                        paymentMethod: "If you cancel before the free trial ends, we’ll never use this card.",
                        profile: "Tell us a little about yourself.",
                    }
                },
                signupForCustomer: {
                    forCustomer: "Agent Signup for Customer",
                    affiliate: "Affiliate: {{affiliate}}",
                    patLive: "Patlive: {{account}}",
                },
                signupHeader: {
                    buttonConfirmation: "Okay, Got It!",
                    carrier: "Carrier",
                    localNumber: "Local Number",
                    mobileModalTitle: "Your Porting Numbers",
                    portNumber: "Transfer a Number",
                    tollFreeNumber: "Toll-Free Number",
                    carrierName: "Carrier {{carrierName}}",
                },
                signupFooter: {
                    flowBFooterLocalText: 'Don’t want a local number? &nbsp; Click here to see all options.',
                    flowBFooterTollFreeText: 'Don’t want a toll-free number? &nbsp; Click here to see all options.',
                    flowBFooterPortText: 'Don’t want to port your number? &nbsp; Click here to see all options.',
                    footerCaptionText: "Already have an account?",
                    footerCaptionTitle: "Click to Sign In",
                },
                signupError: {
                    title: "Something about this signup seemed suspicious and was blocked.",
                    contact_support_message_1: "If you were not using a VPN and believe this was a mistake, please reach out to",
                    contact_support_message_2: "Customer Support",
                    contact_support_message_3: "for assistance.",
                    disable_vpn_message_1: "If you are using a VPN, please disable it and then",
                    disable_vpn_message_2: "click here",
                    disable_vpn_message_3: "to retry."
                },
                signupSuccess: {
                    confirmationMessageTitle: "Congratulations!",
                    confirmationMessageBody: "Your account has been created and we've linked your Tresta number to your mobile number. ",
                    confirmationMessageBodyForPorting: "However, calling and texting will not work until your port is complete.",
                    ctaMobileTitle: "Tresta Mobile",
                    ctaMobileBody: "Download the mobile app to start calling and texting.",
                    ctaDesktopTitle: "Tresta Web",
                    ctaDesktopBody: "To add users or make changes to how Tresta routes your calls, ",
                    ctaDesktopBodyLink: "sign in to the web app.",
                    helpMessage: "Need help? Call ",
                },
                signupEmail: {
                    emailAddressRequired: "Please enter your email address.",
                    enterTheVerificationCode: "Enter the verification code we emailed to {{sentToEmail}}",
                    labelEmail: "Email Address",
                    labelVerificationCode: "Verification Code",
                    resendEmail: "Resend Email",
                    reservationInProgressHeader: "Reserving your number...",
                    reservationFailedHeader: "Reservation Failed",
                    reservationFailedMessage: "There was an issue reserving the phone number you selected.",
                    maxVerificationAttemptsReached: "Too many incorrect codes have been attempted.",
                    modalButtonText: "Select a New Number",
                    verificationCodeRequired: "Please enter a verification code.",
                    incorrectVerificationCode: "Invalid verification code."
                },
                signupModal: {
                    modalButtonText: "Select a New Number",
                    reservationFailedMessage: "There was an issue trying to reserve the number you selected, please try selecting a new number.",
                },
                signupPaymentMethod: {
                    agreeToTerms: "By clicking the above button, I agree to Tresta's Terms of Use.",
                    cardHolderName: "Cardholder's Name",
                    cardHolderNameRequired: "Please enter the cardholder's name.",
                    trialEnd: "Your free trial will end on {{freeTrialEndDate}}.",
                    cardNumber: "Credit Card Number",
                    cardNumberInvalid: "Please enter a valid card number.",
                    cardNumberRequired: "Please enter a card number.",
                    country: "Country",
                    countryRequired: "Please select a country.",
                    create_account_failed: "We're having trouble authorizing your card. Please try again later, or call +1 (844) 2-TRESTA for assistance.",
                    credit_card_declined: "Your credit card failed to authorize. Please verify your card information and try again.",
                    cvv: "CVV",
                    cvvInvalid: "Invalid",
                    cvvRequired: "Required",
                    duplicateCard: "This card is already on your account.",
                    expirationDate: "Expiration Date",
                    expirationDateInvalid: "Invalid expiration date.",
                    failed_to_reserve_phone_numbers: "failed_to_reserve_phone_numbers",
                    header: "Payment Method",
                    invalid_email_address: "This email address is already in use on another Tresta account.",
                    invalid_postal_code: shared.enUS.postalCodeInvalid,
                    missingAuthorizedSignature: "It looks like you refreshed your browser. For security, we'll need you to re-enter your authorized signature to continue.",
                    missingPassword: "It looks like you refreshed your browser. For security, we'll need you to re-enter your password to continue.",
                    modalAuthorizedSignatureButtonText: "Okay",
                    modalPasswordButtonText: "Okay",
                    no_email_address_provided: "Please enter an email address.",
                    no_mobile_phone_number_provided: "We need a mobile phone number to generate your default device",
                    noSpecialCharacters: "Special characters are not allowed.",
                    payment_gateway_down: "We're having trouble authorizing your card. Please try again later, or call +1 (844) 2-TRESTA for assistance.",
                    postalCode: "Postal Code",
                    startTrial: "Start Your Free Trial - Let's Go!",
                    trialEndDate: "After {{freeTrialEndDate}}, your credit card will be charged automatically each month for the users and phone numbers on your account, as well as applicable taxes and fees.",
                    zipCode: "Zip Code",
                },
                signupProfile: {
                    businessName: "Business Name",
                    city_mother_and_father_met: "In what city or town did your mother and father meet?",
                    firstName: "First Name",
                    firstNameIsRequired: "Please enter your first name.",
                    first_pet: "What was the name of your first pet?",
                    high_school_mascot: "What was your high school mascot?",
                    lastName: "Last Name",
                    lastNameIsRequired: "Please enter your last name.",
                    mobileNumber: "Your Mobile Number",
                    nickname: "What was your childhood nickname?",
                    noSpecialCharacters: "Special characters are not allowed.",
                    ownPortedNumber: "You cannot use the same number that you're porting in to Tresta.",
                    ownPurchasedNumber: "You entered the number you're buying. Please enter your mobile number.",
                    password: "Password",
                    passwordInvalid: "Invalid password. Must be 8-32 characters and contain 1 uppercase letter, 1 lowercase letter and 1 number.",
                    passwordRequired: "Please enter a password. Must be 8-32 characters and contain 1 uppercase letter, 1 lowercase letter and 1 number.",
                    passwordRules: "Passwords must contain 8-32 characters, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number.",
                    phoneNumberInvalid: "Please enter a valid phone number.",
                    phoneNumberRequired: "Please enter your mobile phone number.",
                    restrictedPhoneNumber: shared.enUS.restrictedPhoneNumber,
                    sixth_grade_school: "What school did you attend for sixth grade?",
                    verificationAnswer: "Verification Answer",
                    verificationAnswerRequired: "Please enter your verification answer.",
                    verificationQuestion: "Verification Question",
                },
                signupPhoneNumberPort: {
                    addMoreMessage: "To port in more than three phone numbers, please call +1 (844) 2-TRESTA.",
                    carrierRequired: "Please enter your current carrier.",
                    flowBText: 'Don’t want to port your number? <br />  Click here to see all options.',
                    googleWarning: "Before porting in your Google Voice number, you'll need to unlock it. There is a fee that comes with unlocking your number.",
                    googleWarning1: "1. On your computer, go to the ",
                    googleWarning1Link: "Google Voice unlock page",
                    googleWarning2: "2. Point to the Google Voice number you want to transfer, and click Unlock my number.",
                    googleWarning3: "3. Click Continue.",
                    googleWarning4: "4. Pay with your Google Account. You’ll get your receipt by email.",
                    labelCarrier: "Carrier and Phone Number To Port",
                    labelCurrentCarrier: "Current Carrier",
                    labelNumberToPort: "Phone Number to Port",
                    linkAdd: "+ Add Another Number",
                    modalGotIt: "Ok, Got It",
                    modalHeader: "Tresta is Not a Cell Phone Carrier...",
                    modalMessage: "You cannot port your cell phone number to Tresta and continue to use it on your cell phone for calling, texting, and data services. In order to continue using calling, texting, and data services on your current cell phone, you will need to add another phone number with your current carrier before porting the original number to Tresta.",
                    optionOnlyNumber: "This is the only number on this account.",
                    optionOnlyNumber_plural: "These are the only numbers on this account.",
                    optionOtherNumbers: "I have other numbers on this account and do not want to port them.",
                    tabTitleAuthorization: "Authorization",
                    tabTitleBilling: "Billing",
                    tabTitlePort: "Numbers to Port",
                    tabTitleNumbers: "Numbers"
                },
                numberToPort: {
                    duplicatePhoneNumber: "This number is already being ported into Tresta.",
                    phoneNumberInvalid: "Please enter a valid phone number.",
                    phoneNumberNotPortable: "This number cannot be ported into Tresta.",
                    phoneNumberOnAnotherAccount: "This number is already associated with a Tresta account.",
                    phoneNumberRequired: "Please enter a phone number.",
                    restrictedPhoneNumber: shared.enUS.restrictedPhoneNumber,
                    tooltipLine1: "To port numbers from multiple ",
                    tooltipLine2: "carriers or accounts, call",
                    tooltipLine3: "+1 (844) 2-TRESTA for assistance."
                },
                routeTo: {
                    addOrSelectPrompt: "A greeting is required.",
                    afterPlayingAudioFile: "After playing this audio file, do the following",
                    answerTimeoutLabel: "If the call is not answered after&nbsp",
                    atThisPoint: "At this point in the call flow",
                    createNew: "create a new one",
                    hangUp: "Hang up",
                    internalUse: "For internal use only. System will not reveal this label to callers.",
                    labelSubMenu: "Menu Label",
                    noTimeout: "no timeout",
                    playAudioFile: 'Play an&nbsp;<strong>audio file</strong>',
                    pleaseSelect: shared.enUS.pleaseSelect,
                    ringingWillPlay: 'Ringing will play until user answers or caller hangs up',
                    rolloverTo: 'Rollover to',
                    rolloverToUserVoicemail: 'Rollover to user\'s voicemail box',
                    rolloverToUserGroupVoicemail: 'Rollover to user group\'s voicemail box',
                    rolloverToVoicemailBox: 'Rollover to {{ownerName, makePossessive}} voicemail box',
                    routeToAnotherDestination: 'Continue with another action',
                    seconds: 'seconds',
                    sendToAutoAttendant: 'Send the call to an&nbsp;<strong>auto attendant</strong>',
                    sendToForwardingNumber: 'Send the call to a&nbsp;<strong>forwarding number</strong>',
                    sendToSubMenu: 'Send the call to a&nbsp;<strong>submenu</strong>',
                    sendToUser: 'Send the call to a&nbsp;<strong>user</strong>',
                    sendToGroup: 'Send the call to a&nbsp;<strong>group</strong>',
                    sendToVoicemailBox: 'Send the call to a&nbsp;<strong>voicemail box</strong>',
                    useSchedule: 'Use a&nbsp;<strong>schedule</strong>&nbsp;to determine where the call should go',
                    voicemailBox: 'voicemail box',
                    whenOptionSelected: "When this menu option is selected",
                    whenReceivingCall: "When this phone number receives a call"
                },
                routeToModal: {
                    answerTimeoutLabel: "If no answer after",
                    cancel: shared.enUS.cancel,
                },
                routeToAutoAttendant: {
                    createAutoAttendant: 'create an auto attendant.',
                    noAutoAttendantsWarningMessage: 'You haven\'t created an auto attendant yet. Select a different option or&nbsp;',
                    none: shared.enUS.none,
                    selectAutoAttendant: "Select which auto attendant or&nbsp;",
                },
                routeToForwardingNumber: {
                    createForwardingNumber: 'create a forwarding number.',
                    noForwardingNumbersWarningMessage: 'You haven\'t created a forwarding number yet. Select a different option or&nbsp;',
                    none: shared.enUS.none,
                    pressOneToAccept: shared.enUS.pressOneToAccept,
                    pressOneToAcceptWarningMessage: shared.enUS.pressOneToAcceptWarningMessage,
                    selectForwardingNumber: "Select which forwarding number or&nbsp;",
                },
                routeToPrompt: {
                    createAudioFile: 'create an audio file.',
                    noAudioFilesWarningMessage: 'You haven\'t created an audio file yet. Select a different option or&nbsp;',
                    none: shared.enUS.none,
                    selectPrompt: "Select an audio file or&nbsp;",
                },
                routeToSchedule: {
                    createSchedule: 'create a schedule.',
                    noSchedulesWarningMessage: 'You haven\'t created a schedule yet. Select a different option or&nbsp;',
                    none: shared.enUS.none,
                    selectSchedule: "Select which schedule or&nbsp;",
                },
                routeToSubmenu: {
                    selectPrompt: "Play these menu instructions or&nbsp;",
                    createNew: "create a new audio file",
                },
                routeToUser: {
                    pressOneToAccept: shared.enUS.pressOneToAccept,
                    pressOneToAcceptWarningMessage: shared.enUS.pressOneToAcceptWarningMessage,
                    selectUser: "Select which user or&nbsp;",
                },
                routeToUserGroup: {
                    pressOneToAccept: shared.enUS.pressOneToAccept,
                    pressOneToAcceptWarningMessage: shared.enUS.pressOneToAcceptWarningMessage,
                    selectUserGroup: "Select which group or&nbsp;",
                },
                routeToVoicemailBox: {
                    selectVoicemailBox: "Select which voicemail box or&nbsp;",
                },
                signupPhoneNumberPortBilling: {
                    billingAddressRequired: "Please enter an address.",
                    billingCityRequired: "Please enter a city.",
                    billingNameRequired: "Please enter a name.",
                    billingNumberLength: shared.enUS.phoneNumberInvalid,
                    billingNumberRequired: shared.enUS.phoneNumberRequired,
                    labelAccountNumber: "Account Number",
                    labelAddress: "Address",
                    labelApt: "Apt/Suite",
                    labelCountry: "Country",
                    labelCity: "City",
                    labelName: "Name",
                    labelPhoneNumber: "Phone Number",
                    labelPinOrPasscode: "Pin/Passcode",
                    labelPostalCode: "Postal Code",
                    labelProvince: "Province",
                    labelState: "State",
                    labelZipCode: "Zip Code",
                    modalGotIt: "Ok, Got It",
                    modalHeader: "Important Information Below",
                    modalHelpStatement: "If you're unsure of how your information is listed with your current carrier, please consult your most recent bill.",
                    modalMatchStatement1: "The billing information you're about to enter",
                    modalMatchStatement2: " on file with your current carrier, exactly as listed on your bill.",
                    modalMatchStatementUnderlined: " must match the billing information",
                    placeholderLoading: "Loading...",
                    postalCodeInvalid: shared.enUS.postalCodeInvalid,
                    postalCodeRequired: shared.enUS.postalCodeRequired,
                    tabTitleAuthorization: "Authorization",
                    tabTitleBilling: "Billing",
                    tabTitleNumbers: "Numbers",
                    tabTitlePort: "Numbers to Port",
                    tooltipAccountNumberLine1: "The account number listed on the",
                    tooltipAccountNumberLine2: "invoice from your current carrier.",
                    tooltipPinOrPasscodeLine1: "If a PIN or passcode is required to",
                    tooltipPinOrPasscodeLine2: "access your account, we’ll need it",
                    tooltipPinOrPasscodeLine3: "to port your number away from your current carrier.",
                    zipCodeInvalid: shared.enUS.zipCodeInvalid,
                    zipCodeRequired: shared.enUS.zipCodeRequired
                },
                signupPhoneNumberPortAuthorization: {
                    acknowledgementStatement: "By signing below, I attest under penalty of law that I am (a) the legal owner of the phone number(s) included in this port request; or (b) am an authorized employee or representative of the entity requesting the port.  I am, or the entity identified above, is the exclusive end-user subscriber of the phone number(s) included in this port request and I/we assume all liability for the use of the phone number(s).  I acknowledge and understand that I must notify my existing carrier of my intention to change my service provider to Tresta and bear all responsibility for costs that continue to accrue with my former carrier/service provider.",
                    acknowledgementRequired: "You must acknowledge that you understand to continue.",
                    authorizedSignature: "Authorized signature is required.",
                    desiredPortDateStatement: "I understand that my Desired Port Date is not a guaranteed port-on date and may complete before or after this date due to circumstances outside of Tresta's control. If your port date changes, Tresta will reach out ASAP to let you know.",
                    labelAuthorizedSignature: "Signature",
                    labelDesiredPortDate: "Desired Port Date",
                    portDateRequired: "Please specify your desired port date.",
                    portDateAcknowledgment: "Required",
                    tabTitleAuthorization: "Authorization",
                    tabTitleBilling: "Billing",
                    tabTitleNumbers: "Numbers",
                    tabTitlePort: "Numbers to Port"
                },
                signupPhoneNumberType: {
                    asideHeader: "Pick a Number",
                    asideMainText: "Choose whether you'd like a local or toll-free business number, or port in your current number.",
                    local: "Local Number",
                    noPortsWhenSignupForCustomer: "Agents can not choose to port numbers for customers",
                    tollFree: "Toll-Free Number",
                    port: "Transfer a Number",
                    selectionRequired: "Please select an option."
                },
                signupPhoneNumberLocal: {
                    default: shared.enUS.default,
                    flowBText: 'Don’t want a local number? <br /> Click here to see all options.',
                    gotIt: "Ok, Got it",
                    modalButtonText: "Ok, Got it",
                    selectANumber: "Please select a number to continue.",
                },
                signupPhoneNumberTollfree: {
                    default: shared.enUS.default,
                    flowBText: 'Don’t want a toll-free number? <br />  Click here to see all options.',
                    selectANumber: "Please select a number to continue.",
                },
                sidebarNavigation: {
                    accountProfile: "Profile",
                    autoAttendant: "Auto Attendants",
                    billCycleToDate: "Bill Cycle to Date",
                    billing: "Billing Settings",
                    billingHistory: "History",
                    blocking: "Blocking",
                    callerId: "Caller ID",
                    contactPermissions: 'Contacts', 
                    extensions: "Extensions",
                    forwardingNumbers: "Forwarding Numbers",
                    humanDetection: "Human Detection",
                    packageMinutes: "Package Minutes",
                    paymentMethods: "Payment Methods",
                    makeAPayment: "Make a Payment",
                    messagingCompliance: "Messaging Compliance",
                    phoneNumbers: "Phone Numbers",
                    prompts: "Audio Files",
                    schedules: "Schedules",
                    system: "System Settings",
                    userDevices: "Devices",
                    userGroupMembership: "Groups",
                    userGroups: "Groups",
                    userProfile: "Profile",
                    userSettings: "Your Settings",
                    users: "Users",
                    userSubscriptions: "Subscriptions",
                    userVoicemail: "Voicemail",
                    voicemailBoxes: "Voicemail Boxes"
                },
                userProfile: {
                    billing: "Billing",
                    cancel: shared.enUS.cancel,
                    helpCenter: "Help",
                    loggedInAs: "Logged in as",
                    signOut: "Sign Out",
                    userSettings: "Your Settings",
                    profile: "Your Profile",
                    labelFirstName: "First Name",
                    labelLastName: "Last Name",
                    labelTitle: "Job Title",
                    labelEmail: "Email Address",
                    labelSecurity: "Security",
                    labelTimeZone: "Time Zone",
                    changePassword: "Change Password",
                    changeVerificationQA: "Change Verification Question &amp; Answer",
                    resendInvite: "Resend Invite",
                    sendPasswordReset: "Send Password Reset Link",
                },
                userDevices: {
                    add: shared.enUS.add,
                    addDevice:"Add Device",
                    addAnother: "+ Add Another",
                    callersNumber: "Caller's Number",
                    cancel: shared.enUS.cancel,
                    devices: "Your Devices",
                    devicePhoneNumberRequired: "Phone number is required.",
                    deviceTooltip: "At least one device is required.",
                    editDevice:"Edit Device",
                    labelCallerId: "Caller ID",
                    labelName: "Name",
                    labelNumber: "Phone Number",
                    maxDevices: "You have reached the limit of five devices per user.",
                    mobileAppWarning: "You have an instance of the mobile app linked to this device, so the device phone number cannot be edited. <br /><br /> To change the device phone number, create a new device with the updated phone number and then delete this device. You will be required to verify the new number with a two-factor authentication code that we will text to you the first time you log into the mobile app using the new device / phone number. ",
                    save: shared.enUS.save,
                    trestaNumber: "Tresta Number",
                    validCanadianNumber: "This doesn't seem to be a valid Canadian phone number.",
                    validUSNumber: "This doesn't seem to be a valid US phone number."
                },
                userGroupMembership: {
                    addAnother: "+ Add Another",
                    allUsersGroup: "Cannot remove user from All Users.",
                    allUsersGroupSelf: "Cannot remove yourself from All Users.",
                    cancel: shared.enUS.cancel,
                    groups: "Your Groups",
                    groupMembership: "User is a member of the following groups:",
                    groupMembershipSelf: "You are a member of the following groups:",
                    lastMember: "User is the last member of this group and cannot be removed.",
                    lastMemberSelf: "You are the last member of this group and cannot be removed.",
                    save: shared.enUS.save,
                    systemAdminsGroup: "Cannot remove yourself from System Admins.",
                },
                userSubscriptions: {
                    cancel: shared.enUS.cancel,
                    recordingTab: "Call Recordings",
                    save: shared.enUS.save,
                    subscriptions: "Your Subscriptions",
                    voicemailTab: "Voicemail Boxes",
                },
                userCallRecordingSubscriptions: {
                    email: "Email",
                    mobile: "Mobile",
                    web: "Web",
                    noResults: "No Call Recording Subscriptions to Display",
                    phoneNumbers: 'Phone Numbers',
                    subscribers: "{{count}} Subscriber",
                    subscribers_plural: "{{count}} Subscribers",
                },
                userVoicemailSubscriptions: {
                    email: "Email",
                    mobile: "Mobile",
                    web: "Web",
                    noResults: "No Voicemail Subscriptions to Display",
                    subscribers: "{{count}} Subscriber",
                    subscribers_plural: "{{count}} Subscribers",
                    voicemailBoxes: 'Voicemail Boxes',
                },
                userVoicemail: {
                    cancel: shared.enUS.cancel,
                    greetingsTab: "Greetings",
                    lastMember: "Cannot remove user from their own voicemail box.",
                    lastMemberSelf: "Cannot remove yourself from your own voicemail box.",
                    save: shared.enUS.save,
                    subscribersTab: "Subscribers",
                    voicemail: "Your Voicemail",
                },
                userVoicemailGreetings: {
                    addItemTitle: "+ Add Another",
                    currentTooltip: "Cannot delete the current greeting.",
                    default: "Current Greeting",
                    deleteTooltip: "Cannot delete the default greeting.",
                    editTooltip: "Cannot edit the default greeting.",
                    setDefault: "Make Current",
                },
                addVoicemailGreeting: {
                    add: shared.enUS.add,
                    cancel: shared.enUS.cancel,
                    conversionFailed: "Conversion failed, please choose another file.",
                    download: "Download",
                    headerAdd: "Add Greeting",
                    headerEdit: "Edit Greeting",
                    labelFile: "File",
                    labelMethod: "Method",
                    labelName: "Name",
                    labelPreview: "Preview",
                    greetingNameInvalid: "Please enter a valid greeting name.",
                    greetingNameRequired: "Name is required.",
                    greetingAudioRecordingRequired: "Please record a greeting.",
                    greetingAudioUploadRequired: "Please select a greeting.",
                    greetingAudioUploadSizeExceedsMax: shared.enUS.fileTooBig,
                    greetingAudioUploadTypeInvalid: shared.enUS.invalidAudioFile,
                    greetingNameNotUnique: "This name is already used by another greeting.",
                    greetingNameNotLocallyUnique: "This name is already in use by another new greeting.",
                    greetingRequired: "Greeting is required.",
                    save: shared.enUS.save,
                },
                oneTimePaymentModal: {
                    paymentError: 'Error Making one-time Payment'
                },
                billCycleToDate: {
                    billCycleToDate: "Bill Cycle to Date",
                    currentCharges: {
                        header: "Current Charges"
                    },
                    estimatedBalance: {
                        header: "Estimated Balance",
                        processingInfoDisplay: "Processing on {{- processingDateDisplay}}",
                        billingPeriodDisplay: "For the billing period from {{- startDate}} to {{- endDate}}",
                        tooltip: "Your estimated balance is based on the<br/>current number of users and phone numbers<br/>on your account. If you add or remove users<br/>or phone numbers, your balance for the current<br/>bill cycle will adjust accordingly.",
                        totalsHeader: "Estimated Balance",
                    },
                    freeTrialWarningTitle: "You have {{freeTrialDaysRemaining}} day remaining in your free trial.",
                    freeTrialWarningTitle_plural: "You have {{freeTrialDaysRemaining}} days remaining in your free trial.",
                    freeTrialWarningMessage: "If you close your account before your free trial ends on {{freeTrialEndDate}}, you won’t be billed. Here’s an estimate of the monthly recurring charges, based on your account’s current configuration, that will apply after the free trial. This estimate may change if you add or remove users or phone numbers.",
                    freeTrialStartedOn: "Free Trial started on {{- freeTrialStartDate}}",
                    invoiceFor: "Statement for {{- cycleStartDate}} - {{- cycleEndDate)",
                    pastDueBalance: {
                        header: "Starting Balance",
                        tooltip: "Please pay your past due balance<br/>in order to avoid an interruption of service.",
                        billingPeriodDisplay: "For the billing period from {{- startDate}} to {{- endDate}}",
                        processingInfoDisplay: "Processing on {{- processingDateDisplay}}",
                        totalsHeader: "Pending Charges",
                    },
                    pastDueBalance_part1: "Your account has a&nbsp;<b>past due balance</b>&nbsp;of <b>{{pastDueBalanceAmount}}</b>.&nbsp;To avoid an interruption, please",
                    pastDueBalance_part2: "&nbsp;<b>make a payment</b>&nbsp;",
                    pastDueBalance_part3: "as soon as possible to clear the past due portion of your balance.",
                    pendingPayment: {
                        header: "Starting Balance",
                        tooltip: "This is the balance you currently owe on <br/>your account. It will be automatically billed<br/> to your default payment method on {{processingDateDisplay}}.",
                        billingPeriodDisplay: "For the billing period from {{- startDate}} to {{- endDate}}",
                        processingInfoDisplay: "Processing on {{- processingDateDisplay}}",
                        totalsHeader: "Pending Charges",
                    },
                    phoneNumbers: {
                        header: "Phone Number ({{countDisplay}})",
                        header_plural: "Phone Numbers ({{countDisplay}})",
                    },
                    plan: {
                        header: "Platform Fee"
                    },
                    remainingBalance: "Remaining Balance",
                    taxesAndFees: {
                        header: "Taxes and Fees",
                    },
                    startingBalance: {
                        header: "Starting Balance"
                    },
                    users: {
                        header: "User ({{countDisplay}})",
                        header_plural: "Users ({{countDisplay}})",
                    },

                },
                billingHistory: {
                    title: "History",
                    currentBalance: "Current Balance:&nbsp;",
                    pastDueBalance_part1: "Your account has a&nbsp;<b>past due balance</b>&nbsp;of <b>{{pastDueBalanceAmount}}</b>.&nbsp;To avoid an interruption, please",
                    pastDueBalance_part2: "&nbsp;<b>make a payment</b>&nbsp;",
                    pastDueBalance_part3: "as soon as possible to clear the past due portion of your balance.",
                    noResults: "No Billing History to Display",
                    invoiceGenerated: "Statement Generated",
                    refundToCard: "Refund to Card",
                    oneTimePayment: "One-time Payment",
                    autoPay: "Auto-pay",
                    autoPayRetry: "Auto-pay Retry",
                    completed: "Completed",
                    failed: "Failed",
                    billingHistoryTableHeaders: {
                        date: 'Date',
                        transactionType: 'Transaction Type',
                        paymentMethod: 'Payment Method',
                        amount: 'Amount',
                        status: 'Status'
                    }
                },
                accountProfile: {
                    accountClosed: "Account Closed",
                    accountNumber: "Account Number",
                    accountType: "I'm using Tresta for",
                    accountTypeRequired: "Account type selection is required.",
                    add: '+ Add',
                    addAnother: '+ Add Another',
                    business: "Business Use",
                    cancel: shared.enUS.cancel,
                    closeAccount: "Close Account",
                    emailAddressNotUnique: "Email address is already in use.",
                    header: "Billing Profile",
                    howYouWillUseTrestaRequired: "Please make a selection.",
                    howWillYouUseTrestaTooltip: "The account type classification cannot be changed.",
                    labelBillingCC: "CC These Email Addresses",
                    labelBillingCommunication: "Send Invoices and Billing Notifications to These Users and Groups",
                    lastBillingSubscriber: "At least one is required.",
                    maxCCEmailsMessage: "You've reached the maximum number of CCs.",
                    noMoreBillingSubscribers: "There are no more users or groups to add.",
                    oopsInvalidItems: "Oops, it looks like there are some issues with these sections:",
                    personal: "Personal Use",
                    pleaseCorrectInvalidFields: "Please correct the highlighted fields and try again.",
                    save: shared.enUS.save
                },
                accountProfileBusiness: {
                    businessNameRequired: "Business Name is required.",
                    companySizeRequired: "Company Size is required.",
                    labelBusinessName: "Business Name",
                    labelBusinessType: "Business Type",
                    labelCompanySize: "Company Size",
                    lastBillingSubscriber: "At least one is required.",
                    oopsInvalidItems: "Oops, it looks like there are some issues with these sections:",
                    pleaseCorrectInvalidFields: "Please correct the highlighted fields and try again.",
                    pleaseSpecify: "Please specify",
                    select: "Select",
                },
                accountProfilePersonal: {
                    firstNameRequired: "First Name is required.",
                    labelFirstName: "First Name",
                    labelLastName: "Last Name",
                    lastNameRequired: "Last Name is required.",
                    pleaseCorrectInvalidFields: "Please correct the highlighted fields and try again.",
                },
                audioRecorder: {
                    deviceSelectorTooltip: "Microphone selection is only supported in Chrome",
                    labelMic: "Microphone",
                    labelPreview: "Preview",
                    noDevices: "Your browser did not detect a microphone. If you have a microphone and believe this is an error, please confirm the microphone settings and retry.",
                    notSupported: "Your current browser does not support this feature.",
                    permissionRequired: "To record audio using your microphone, you must first allow permission to access the microphone in your browser's settings.",
                    record: "Start Recording",
                    reRecord: "Re-Record",
                    starting: "Starting in... ",
                    stop: "Stop "
                },
                callDetails: {
                    callId: "Call ID",
                    callRoute: "Call Flow",
                    location: "Location",
                    subscribers: "Subscribers",
                    tollFreeMapMessage: "No map is displayed for Toll-Free to Toll-Free calls.",
                },
                callFlow: {
                    audioSeparatorMessage: "After playing the audio file, send it to",
                    defaultScheduleSegment: "All Other Times",
                    defaultSeparatorMessage: "Then send it to",
                    hangup: "Hang Up",
                    playRinging: "Ring until answered or caller hangs up",
                    timeoutMessage: " seconds, send it to",
                    timeoutSeparatorMessage: "If it's not answered after ",
                },
                closeAccount: {
                    areYouSure: "Are you sure?",
                    areYouSwitchingServices: "Are you switching to another service?",
                    canYouExplainTheIssue: "Please describe the issue(s) you were having.",
                    canYouProvideSummaryOfCustomerServiceIssue: "Please provide a summary of the customer service issue.",
                    doYouHaveFeedback: "Do you have any feedback you'd like to share with us?",
                    cancel: "Cancel",
                    cancellationComment: "Cancellation Comment",
                    closeAccount: "Close Account",
                    fraudStatus: "Fraud Status",
                    header: "Close Account",
                    howLikelyAreYouToRecommend: "How likely are you to recommend Tresta to a friend?",
                    pleaseMakeSelection: "Please make a selection.",
                    pleaseProvideResponse: "Please provide a response.",
                    pleaseSelect: "Select",
                    pleaseSelectReason: "Please select a reason.",
                    pleaseSpecify: "Please specify",
                    pleaseSpecifyWhichService: "Please specify the service you are switching to.",
                    pleaseSpecifyFeaturesNeeded: "What features did you need?",
                    pleaseSpecifyOtherReasonForCancelling: "Please specify the reason you want to close your account.",
                    pleaseSpecifyReasonForCancelling: "Please specify the reason you are cancelling.",
                    portInProgress: "Port in progress",
                    portInProgressWarningMessage_part1: "You have a phone number in an active port status and cannot close your account until the port has completed. If you have questions or need assistance with your port, please email",
                    portInProgressWarningMessage_part2: " or call " + shared.enUS.customerServiceNumberFormatted + ".",
                    portOutInstructionsWarningTitle: "Port outs must be completed before closing your account.",
                    portOutInstructionsWarningMessage: "If you want to keep your current number, you must port it out before closing your account. Failure to follow these instructions may result in an interruption of service and/or loss of your number.",
                    portOutInstructionsSteps: "To port your number out:",
                    portOutInstructions_step1: "1) Initiate the number port with your new service provider",
                    portOutInstructions_step2: "2) Wait for confirmation that the port is complete",
                    portOutInstructions_step3: "3) Verify that the number is working correctly with the new provider",
                    portOutInstructions_step4: "4) Then return here to close your account",
                    whatDidYouHaveTroubleWith: "Please describe the issue(s) you were having.",
                    whatsYourViewOfPricing: "Which best fits your view of Tresta’s pricing?",
                    whyDoYouNoLongerNeedTresta: "Which best describes why you no longer need  Tresta?",
                    whatWasTheIssue: "What was the issue?",
                    whichService: "Which service?",
                    whyDoYouWantToCloseYourAccount: "Why do you want to close your account?"
                },
                closeAccountActionModal: {
                    title: "Pressing the Close Account button will close your account immediately.",
                    accountAccessTitle: "Account Access",
                    accountAccessMessage: "All users on your account will be signed out from the web app and the mobile app.",
                    numberPortingTitle: "Number Porting",
                    numberPortingMessage: "You will no longer be eligible to port your number to a new service provider. If you want to port your number, complete the transfer to your new provider first and then close your Tresta account.",
                    callingMessagingReportingTitle: "Calling, Messaging & Reporting",
                    callingMessagingReportingMessage: "You will no longer be able to place or receive calls and messages on your Tresta number, and you'll no longer be able to access reporting data associated with your Tresta number."
                },
                dateRangeSelection: {
                    endPlaceholder: "To",
                    startPlaceholder: "From"
                },
                integrityWarningModal: {
                    cancel: shared.enUS.cancel,
                    conflicts: "Please resolve the following conflicts and try again.",
                    delete: "Delete {{type}}",
                    inUse: "This {{type}} cannot be deleted because it is in use.",
                },
                autoAttendantIntegrityWarningModal: {
                    cancel: shared.enUS.cancel,
                    conflicts: "Please resolve the following conflicts and try again.",
                    delete: "Delete {{type}}",
                    inUse: "This {{type}} cannot be deleted because it is in use.",
                },
                warningModal: {
                    areYouSure: "Are you sure?",
                    areYouSure_navigate: "There are some unsaved changes. Navigating away from this page will discard any changes made. Are you sure you want to navigate away from this page?",
                    areYouSure_phoneNumber: "Are you sure you want to delete this phone number? You will lose any associated Call Recordings.",
                    areYouSure_unassign: "Are you sure you want to unassign this {{type}}?",
                    areYouSure_delete: "Are you sure you want to delete this {{type}}?",
                    cancel: shared.enUS.cancel,
                    cancelPort: "Please contact customer service to cancel number port.",
                    delete: "Delete {{type}}",
                    delete_callRecording: "Delete Call Recording",
                    delete_voicemail: "Delete Voicemail",
                    noCancel: "No, Cancel",
                    oneNumberRequired: "Sorry, at least one active phone number is required.",
                    inUse: "Sorry, this {{type}} cannot be deleted because it is in use.",
                    unassign: "Unassign",
                    yesDelete: "Yes, Delete",
                    yesNavigate: "Yes, Navigate Away"
                },
                actionModal: {
                    add: shared.enUS.add,
                    agentBypass: "Agent Bypass >",
                    archive: "Archive",
                    areYouSure_charge: "Are you sure you want to charge {{amount}} to this card?",
                    areYouSure_delete: "Are you sure you want to delete this {{type}}?",
                    areYouSure_remove: "Are you sure you want to remove this option from the menu?",
                    cancel: shared.enUS.cancel,
                    cancelInvite: "Cancel Invite",
                    close: "Close",
                    closeAccount: "Close Account",
                    copy: " Copy",
                    continue: "Continue",
                    deactivate: "Deactivate",
                    delete: "Delete",
                    deleteConfirmation: "Delete Confirmation",
                    deleteHeader: "Delete {{type}}",
                    reactivate: "Reactivate",
                    oneTimePaymentHeader: "One-time Payment",
                    pay: "Pay",
                    paymentApproved: "Your payment of {{amount}} was approved.",
                    paymentDeclined: "Your payment of {{amount}} was declined.",
                    paymentDeclinedBody: "Please verify your saved card details and retry, <br/> or try using another payment method.",
                    paymentError: "We’re having trouble processing your payment.",
                    paymentErrorBody: "Please call +1 (844) 2-TRESTA for assistance.",
                    remove: "Remove",
                    removeHeader: "Remove Menu Option",
                    save: shared.enUS.save,
                    saveCopy: "Save a Copy",
                    send: "Send",
                    unsavedChangesHeader: "Unsaved Changes",
                    unsavedChangesText: "You have unsaved changes that will be lost if you navigate away.",
                    unsavedPhoneNumbers: "Unsaved Phone Numbers",
                    unsavedPhoneNumbersText: "You have unsaved numbers that will be lost if you navigate away.",
                },
                deletePaymentMethodModal: {
                    areYouSure: "Are you sure you want to delete this payment method?",
                },
                changePasswordModal: {
                    areYouSure: "Are you sure you want to send a password reset link?",
                    cancel: shared.enUS.cancel,
                    change: "Change",
                    changePassword: "Change Password",
                    close: "Close",
                    confirmationMessage: "Your password has been updated.",
                    confirmPasswordRequired: "Please re-enter your new password.",
                    currentPasswordRequired: "Please enter your current password.",
                    currentPasswordValid: "The password you entered is incorrect.",
                    labelConfirm: "New Password Confirm",
                    labelCurrent: "Current Password",
                    labelNew: "New Password",
                    newPasswordInvalid: "Password must contain 8-32 characters, 1 upper case letter, 1 lower case letter & 1 number.",
                    newPasswordRequired: "Please enter a new password.",
                    passwordPlaceholder: "8-32 characters, 1 upper case, 1 lower case, and 1 number",
                    resetPassword: "Send Password Reset",
                    send: "Send"
                },
                changeVerificationQAModal: {
                    cancel: shared.enUS.cancel,
                    change: "Change",
                    changeVerificationQA: "Change Verification Q&A",
                    close: "Close",
                    confirmationMessage: "Your verification question and answer have been updated.",
                    labelAnswer: "Answer",
                    labelQuestion: "Verification Question",
                },
                confirmPasswordModal: {
                    cancel: shared.enUS.cancel,
                    changeVerificationQA: "Change Verification Q&A",
                    continueMessage: "To continue, enter your password and click Next.",
                    labelPassword: "Password",
                    next: "Next",
                },
                resendInviteModal: {
                    areYouSure: "Are you sure you want to send a new invite?",
                    cancel: shared.enUS.cancel,
                    header: "Resend Invite",
                    send: "Send"
                },
                uploadImageModal: {
                    cancel: shared.enUS.cancel,
                    cropEmptyText: "No Photo Selected",
                    cropLoadingText: "Uploading ...",
                    preview: "Preview",
                    save: shared.enUS.save,
                    stepOne: "Step 1: Upload a Photo",
                    stepTwo: "Step 2: Crop Your Photo",
                    uploadPhoto: "Upload Photo",
                },
                freeTrialMessagingSnackbar: {
                    message: "Messaging cannot be enabled while in a free trial."
                },
                filterSelection: {
                    addAnotherMessage: "There are no more {{optionText}} available to add.",
                    all: "All",
                    clear: "Clear",
                },
                membershipSelection: {
                    addAnotherMessage: "There are no more {{optionText}} available to add",
                    lastItemRemoveTitle: "Cannot remove the last member.",
                },
                subscriberSelection: {
                    addAnotherMessage: "There are no more subscribers available to add.",
                    added: "added",
                    addItemTitle: "+ Add Another",
                    allMembers: "All Members",
                    at: "at",
                    by: " by ",
                    currentlyDisabled: "currently disabled",
                    customerServiceAgent: "Customer Service Agent",
                    disabled: "disabled",
                    enabled: "enabled",
                    enabledButNoPermissions: "enabled but there are no users or groups included in the call flow",
                    inbound: "Inbound",
                    lastItemRemoveTitle: "At least one subscriber is required.",
                    on: "on",
                    outbound: "Outbound",
                    removed: "removed",
                    subscriberLogPermissionTitle: "Feature",
                    subscriberLogTitle: "History:",
                    systemProcess: "System",
                    today: "today",
                    unknown: "Unknown",
                    viewMore: "View More",
                    yesterday: "yesterday"
                },
                inheritedSubscriberSelection: {
                    noInheritedActors: "Inbound calls are {{inboundCallingStatus}}, so no one is inheriting permissions to {{inheritedAction}}.",
                    toAllowAction: "To allow users to {{inheritedAction}}, either:",
                    noInheritedActorsBulletOne: "Enable inbound calls on the Inbound Calls tab and send calls to the users and groups that need to be able to {{inheritedAction}}, or",
                    noInheritedActorsBulletTwo: "Use the dropdown above to select “{{manualOption}}” and grant {{grantedPermission}} to the user and groups that need to be able to {{inheritedAction}}.",
                },
                timeframe: {
                    timeframe: "Timeframe",
                    clear: "Clear",
                    all: "All",
                    now: "Now",
                    today: 'Today',
                    thisWeek: 'This Week',
                    thisMonth: 'This Month',
                    thisQuarter: 'This Quarter',
                    thisYear: 'This Year',
                    yesterday: 'Yesterday',
                    lastWeek: 'Last Week',
                    lastMonth: 'Last Month',
                    lastQuarter: 'Last Quarter',
                    lastYear: 'Last Year',
                    monthToDate: 'Month to Date',
                    quarterToDate: 'Quarter to Date',
                    yearToDate: 'Year to Date',
                    lastDays7: 'Last 7 Days',
                    lastDays14: 'Last 14 Days',
                    lastDays30: 'Last 30 Days',
                    lastDays60: 'Last 60 Days',
                    lastDays90: 'Last 90 Days',
                    lastMonths12: 'Last 12 Months',
                    custom: 'Custom',
                },
                notFound: {
                    headline: 'Oops! We can\'t find the page you\'re looking for.',
                    message: 'Go to ',
                    previousPage: 'Previous Page'
                },
                navigationConfiguration: {
                    login: {
                        title: "Sign In to Tresta | The #1 Cloud Phone System",
                        description: " Sign in to your Tresta phone system.",
                        displayName: "Sign In",
                    },
                    accountClosed: {
                        title: "Account Closed | Tresta",
                        description: "Close your Tresta account.",
                        displayName: "Tresta Account Closed"
                    },
                    accountClosedFreeTrial: {
                        title: "Account Closed | Tresta",
                        description: "Your Tresta account has been closed.",
                        displayName: "Tresta Account Closed"
                    },
                    closeAccount: {
                        title: "Close Account | Tresta",
                        description: "Close your Tresta account.",
                        displayName: "Close Tresta Account"
                    },
                    closedBalanceOwed: {
                        title: "Sign In | Tresta",
                        description: "Sign in to your Tresta account.",
                        displayName: "Sign In - Account Closed - Balance Owed",
                    },
                    closedDoNotReactivate: {
                        title: "Sign In | Tresta",
                        description: "Sign in to your Tresta account.",
                        displayName: "Sign In - Account Closed - Do Not Reactivate",
                    },
                    miscProblems: {
                        title: "Sign In | Tresta",
                        description: "Sign in to your Tresta account.",
                        displayName: "Sign In - Miscellaneous Problems",
                    },
                    payBalance: {
                        title: "Sign In | Tresta",
                        description: "Sign in to your Tresta account.",
                        displayName: "Sign In - Pay Balance",
                    },
                    forgotPassword: {
                        title: "Tresta | Forgot Password",
                        description: "",
                        displayName: "Forgot Password",
                    },
                    resetPassword: {
                        title: "Tresta | Reset Password",
                        description: "",
                        displayName: "Reset Password",
                    },
                    acceptUserInvite: {
                        title: "Welcome to Tresta | Tresta",
                        description: "",
                        displayName: "Welcome to Tresta",
                    },
                    resendInvite: {
                        title: "Resend Invite | Tresta",
                        description: "",
                        displayName: "Resend Invite",
                    },
                    index: {
                        title: "Index | Tresta",
                        description: "",
                        displayName: "Index",
                    },
                    signupSuccess: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Success",
                    },
                    signupError: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Error",
                    },
                    signupEmail: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Email",
                    },
                    signupPhoneNumberType: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Phone Number Type",
                    },
                    signupPhoneNumberLocal: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Phone Number Local",
                    },
                    signupPhoneNumberTollfree: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Phone Number Toll-Free",
                    },
                    signupPhoneNumberPort: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Phone Number Port",
                    },
                    signupPhoneNumberPortBilling: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Phone Number Port",
                    },
                    signupPhoneNumberPortAuthorization: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Phone Number Port",
                    },
                    signupProfile: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Signup Profile",
                    },
                    signupPaymentMethod: {
                        title: "Sign Up for Tresta | The #1 Virtual Phone System",
                        description: "Sign up  for the #1 ranked cloud based phone system for your business. Easy to use. Tons of features. Unlimited per user pricing. 30 day free trial.",
                        displayName: "Payment Method",
                    },
                    analytics: {
                        title: "Analytics | Tresta",
                        description: "",
                        displayName: "Analytics",
                    },
                    calls: {
                        title: "Calls | Tresta",
                        description: "",
                        displayName: "Calls",
                    },
                    contacts: {
                        title: "Contacts | Tresta",
                        description: "",
                        displayName: "Contacts",
                    },
                    messages: {
                        title: "Messages | Tresta",
                        description: "",
                        displayName: "Messages",
                    },
                    newMessage: {
                        title: "New Message | Tresta",
                        description: "",
                        displayName: "New Message"
                    },
                    conversation: {
                        title: "Conversation | Tresta",
                        description: "",
                        displayName: "Conversation"
                    },
                    inbox: {
                        title: "Inbox | Tresta",
                        description: "",
                        displayName: "Inbox",
                    },
                    inboxRecordings: {
                        title: "Inbox | Tresta",
                        description: "",
                        displayName: "Call Recordings",
                    },
                    inboxRecordingsFiltered: {
                        title: "Inbox | Tresta",
                        description: "",
                        displayName: "Call Recordings",
                    },
                    inboxVoicemail: {
                        title: "Inbox | Tresta",
                        description: "",
                        displayName: "Voicemail",
                    },
                    inboxVoicemailFiltered: {
                        title: "Inbox | Tresta",
                        description: "",
                        displayName: "Voicemail",
                    },
                    settings: {
                        title: "Settings | Tresta",
                        description: "",
                        displayName: "System",
                    },
                    helpCenter: {
                        title: "Help Center | Tresta",
                        description: "",
                        displayName: "Help Center",
                    },
                    'my-profile': {
                        title: "{{fullName}} | Tresta",
                        description: "",
                        displayName: "{{fullName}}",
                    },
                    userProfile: {
                        title: "User Profile | Tresta",
                        description: "",
                        displayName: "User Profile",
                    },
                    userDevices: {
                        title: "Devices | Tresta",
                        description: "",
                        displayName: 'Devices',
                    },
                    userSubscriptions: {
                        title: "Subscriptions | Tresta",
                        description: "",
                        displayName: 'Subscriptions',
                    },
                    userSubscriptionsFiltered: {
                        title: "Subscriptions | Tresta",
                        description: "",
                        displayName: 'Subscriptions',
                    },
                    userGroupMembership: {
                        title: "Groups | Tresta",
                        description: "",
                        displayName: "Groups",
                    },
                    userVoicemail: {
                        title: "Voicemail | Tresta",
                        description: "",
                        displayName: 'Voicemail',
                    },
                    'system-settings': {
                        title: "System Settings | Tresta",
                        description: "",
                        displayName: "System",
                    },
                    prompts: {
                        title: "Audio Files | Tresta",
                        description: "",
                        displayName: "Audio Files",
                    },
                    newPrompt: {
                        title: "Add Audio File | Tresta",
                        description: "",
                        displayName: "Add Audio File",
                    },
                    editPrompt: {
                        title: "Edit Audio File | Tresta",
                        description: "",
                        displayName: "Edit Audio File",
                    },
                    autoAttendant: {
                        title: "Auto Attendants | Tresta",
                        description: "",
                        displayName: "Auto Attendants",
                    },
                    addAutoAttendant: {
                        title: "Add Auto Attendant | Tresta",
                        description: "",
                        displayName: "Add Auto Attendant",
                    },
                    editAutoAttendant: {
                        title: "Edit Auto Attendant | Tresta",
                        description: "",
                        displayName: "Edit Auto Attendant",
                    },
                    contactPermissions: {
                        title: "Contacts | Tresta",
                        description: "",
                        displayName: "Contacts"
                    },
                    blocking: {
                        title: "Blocking | Tresta",
                        description: "",
                        displayName: "Blocking"
                    },
                    extensions: {
                        title: "Extensions | Tresta",
                        description: "",
                        displayName: "Extensions",
                    },
                    forwardingNumbers: {
                        title: "Forwarding Numbers | Tresta",
                        description: "",
                        displayName: "Forwarding Numbers",
                    },
                    newForwardingNumber: {
                        title: "Add Forwarding Number | Tresta",
                        description: "",
                        displayName: "Add Forwarding Number",
                    },
                    editForwardingNumber: {
                        title: "Edit Forwarding Number | Tresta",
                        description: "",
                        displayName: "Edit Forwarding Number",
                    },
                    messagingCompliance: {
                        title: "Messaging Compliance | Tresta",
                        description: "",
                        displayName: "Messaging Compliance",
                    },
                    phoneNumbers: {
                        title: "Phone Numbers | Tresta",
                        description: "",
                        displayName: "Phone Numbers",
                    },
                    newPhoneNumberType: {
                        title: "Add Phone Number | Tresta",
                        description: "",
                        displayName: "Add Phone Number",
                    },
                    newPhoneNumberLocal: {
                        title: "Add Phone Number | Tresta",
                        description: "",
                        displayName: "Add Phone Number",
                    },
                    newPhoneNumberTollFree: {
                        title: "Add Phone Number | Tresta",
                        description: "",
                        displayName: "Add Phone Number",
                    },
                    newPhoneNumberPort: {
                        title: "Add Phone Number | Tresta",
                        description: "",
                        displayName: "Add Phone Number",
                    },
                    newPhoneNumberSummary: {
                        title: "Add Phone Number | Tresta",
                        description: "",
                        displayName: "Add Phone Number",
                    },
                    editPhoneNumbers: {
                        title: "Edit Phone Number | Tresta",
                        description: "",
                        displayName: "Edit Phone Number",
                    },
                    schedules: {
                        title: "Schedules | Tresta",
                        description: "",
                        displayName: "Schedules",
                    },
                    newSchedule: {
                        title: "Add Schedule | Tresta",
                        description: "",
                        displayName: "Add Schedule",
                    },
                    editSchedule: {
                        title: "Edit Schedule | Tresta",
                        description: "",
                        displayName: "Edit Schedule",
                    },
                    humanDetection: {
                        title: "Human Detection | Tresta",
                        description: "",
                        displayName: "Human Detection",
                    },
                    newHumanDetectionPhoneNumber: {
                        title: "Add Phone Number to Whitelist | Tresta",
                        description: "",
                        displayName: "Add Phone Number to Whitelist",
                    },
                    users: {
                        title: "Users | Tresta",
                        description: "",
                        displayName: "Users",
                    },
                    editUser: {
                        title: "Edit User | Tresta",
                        description: "",
                        displayName: "Edit User",
                    },
                    userGroups: {
                        title: "Groups | Tresta",
                        description: "",
                        displayName: "Groups",
                    },
                    addUserGroup: {
                        title: "Add Group | Tresta",
                        description: "",
                        displayName: "Add Group",
                    },
                    editUserGroup: {
                        title: "Edit Group | Tresta",
                        description: "",
                        displayName: "Edit Group",
                    },
                    voicemailBoxes: {
                        title: "Voicemail Boxes | Tresta",
                        description: "",
                        displayName: "Voicemail Boxes",
                    },
                    newVoicemailBox: {
                        title: "Add Voicemail Box | Tresta",
                        description: "",
                        displayName: "Add Voicemail Box",
                    },
                    editVoicemailBox: {
                        title: "Edit Voicemail Box | Tresta",
                        description: "",
                        displayName: "Edit Voicemail Box",
                    },
                    billing: {
                        title: "Billing | Tresta",
                        description: "",
                        displayName: "Billing",
                    },
                    accountProfile: {
                        title: "Billing Profile | Tresta",
                        description: "",
                        displayName: "Profile",
                    },
                    billCycleToDate: {
                        title: "Bill Cycle to Date | Tresta",
                        description: "",
                        displayName: "Bill Cycle to Date",
                    },
                    billingHistory: {
                        title: "History | Tresta",
                        description: "",
                        displayName: "History",
                    },
                    paymentMethods: {
                        title: "Payment Methods | Tresta",
                        description: "",
                        displayName: "Payment Methods",
                    },
                    addPaymentMethods: {
                        title: "Add Payment Method | Tresta",
                        description: "",
                        displayName: "Add Payment Method",
                    },
                    editPaymentMethods: {
                        title: "Edit Payment Method | Tresta",
                        description: "",
                        displayName: "Edit Payment Method",
                    },
                    makeAPayment: {
                        title: 'Make a Payment | Tresta',
                        description: '',
                        displayName: 'Make a Payment',
                    },
                    styleGuide: {
                        title: "Style Guide",
                        description: "",
                        displayName: "Style Guide",
                    },
                    notFound: {
                        title: "Error",
                        description: "",
                        displayName: "Page not found"
                    }
                },
                referentialConstraints: {
                    audioFile: {
                        message: " is using this audio file.",
                    },
                    autoAttendant: {
                        entityText: "Auto Attendant ",
                        message: " is routed to this auto attendant.",
                    },
                    forwardingNumber: {
                        entityText: "Forwarding Number ",
                        message: " is routed to this forwarding number.",
                    },
                    phoneNumber: {
                        entityText: "Phone Number ",
                    },
                    recordingNotification: {
                        message: " is using this notification in its call recording settings.",
                    },
                    schedule: {
                        entityText: "Schedule ",
                        message: " is using this schedule.",
                    },
                    user: {
                        entityText: "User ",
                        message: " is routed to this user.",
                    },
                    userGroup: {
                        entityText: "Group ",
                        message: " is routed to this group.",
                    },
                    voicemailBox: {
                        entityText: "Voicemail Box ",
                        message: " is routed to this voicemail box.",
                    },
                    voicemailGreeting: {
                        message: " is using this audio file as a voicemail greeting.",
                    }
                },
            },
        };
    }
);

