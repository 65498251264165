define('presentation/loginNotifications/viewModels/balancePaidViewModel',['common/promises/promiseFactory'
], function () {
    return function (paymentAmount) {
        const self = this;
        const _paymentAmount = paymentAmount;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        self.balancePaidMessage = ko.computed(() => {
            const options = {
                paymentAmount: _paymentAmount
            };
            return _i18n.t("payBalance:balancePaidMessage", options);
        });

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

