define('persistence/dataProviders/releaseTogglesDataProvider',[
    'persistence/webSocket/webSocketApp',
    'common/promises/promiseFactory'
], function(
    /** @type typeof import('persistence/webSocket/webSocketApp') */
    WebSocketApp,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory) {

    const _promiseFactory = new PromiseFactory();
    const _webSocketApp = new WebSocketApp();
    /** @type {any[]} */
    const _releaseTogglesByReleaseId = [];
    const _waitHandle = _promiseFactory.deferIndefinitely();

    function _waitUntilInitialized() {
        return _waitHandle;
    }

    function _getReleaseToggles() {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.init("release_toggles");
            _webSocketApp.send("get_release_toggles", null, (result) => {
                result.forEach((releaseToggle) => {
                    _releaseTogglesByReleaseId[releaseToggle.releaseId] = {isEnabled: releaseToggle.isEnabled};
                });
                promise.resolve(result);
                _waitHandle.resolve();
            });
        });
    }

    const _isReleaseEnabled = (/** @type {number} */releaseId) => {
        return _releaseTogglesByReleaseId[releaseId] === undefined || _releaseTogglesByReleaseId[releaseId].isEnabled;
    };

    return {
        waitUntilInitialized : _waitUntilInitialized,
        getReleaseToggles: _getReleaseToggles,
        isReleaseEnabled: _isReleaseEnabled
    };

});

