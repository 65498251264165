define('persistence/dataProviders/paymentMethodDataProvider',['persistence/webSocket/webSocketApp',
    'common/promises/promiseFactory'], function() {

    return function() {
        let self = this;
        let _webSocketApp = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = () => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("getAll", {}, function (result) {
                    deferredObject.resolve(result.paymentMethodDocuments);
                });
            });
        };

        self.getPaymentMethodById = (id) => self.getAll().then((result) => {
            return result.filter((paymentMethod) => paymentMethod.paymentMethodId === id)[0];
        });

        self.getDeclinedInformation = () => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("getDeclinedInformation", {},  (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        self.getAddPaymentMethodAttempts = () => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("getAddPaymentMethodAttempts", {},  (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        self.getFreeTrialInfo = () => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("getFreeTrialInfo", {},  (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = () => {
            const WebSocketAppConstruction = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstruction();
            _webSocketApp.init("payment_method");
        };
    };
});

