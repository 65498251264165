define('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceAutoResponsePresentationObject',[],function() {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceAutoResponsePresentationObject') } MessagingComplianceAutoResponsePresentationObject } */

        /** @typedef { MessagingComplianceAutoResponsePresentationObject }*/
        const self = this;

        self.helpAutoResponse = ko.observable("");
        self.stopAutoResponse = ko.observable("");
    };
});

