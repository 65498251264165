define('persistence/repositories/signupRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket',
    'persistence/webSocket/webSocketApp',],
function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _clientWebSocket = require('externalDependencies/clientWebSocket');
        let _webSocketApp = null;

        self.cancelReservation = (signupSessionId, secretKey, phoneNumberToCancel) => {
            const socketParams = {
                signupSessionId : signupSessionId,
                secretKey: secretKey,
                phoneNumberToCancel : phoneNumberToCancel
            };

            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("cancelPhoneNumberReservation", socketParams,  (result) => {
                    if(result.status !== "success") {
                        deferredObject.reject(new Error("reservation could not be cancelled"));
                    } else {
                        deferredObject.resolve();
                    }
                });
            });
        };

        self.checkIsFraudulentIpAddress = (signupSessionId, secretKey) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    userAgent: navigator.userAgent
                };
                _clientWebSocket.sendUnAuthenticated("signup", "isFraudulentIpAddress", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.isFraudulent);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            });
        };

        self.checkRestrictedPhoneNumber = (signupSessionId, secretKey, phoneNumber) => {
            return _promiseFactory.defer((deferredObject) => {
                const socketParams = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    phoneNumber: phoneNumber
                };

                _webSocketApp.send("isRestrictedNumber", socketParams,  (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        self.checkIsFraudulentPhoneNumber = (signupSessionId, secretKey, phoneNumber, phoneNumberCountryCode) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    phoneNumber: phoneNumber,
                    phoneNumberCountryCode: phoneNumberCountryCode
                };
                _clientWebSocket.sendUnAuthenticated("signup", "isFraudulentPhoneNumber", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.isFraudulent);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            });
        };

        self.checkIsFraudulentPaymentInformation = (signupSessionId, secretKey, cardholderName, cardNumberHashed, firstSixDigits, expirationDate, country, postalCode) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    cardholderName: cardholderName,
                    cardNumberHashed: cardNumberHashed,
                    firstSixDigits: firstSixDigits,
                    expirationDate: expirationDate,
                    country: country,
                    postalCode: postalCode
                };
                _clientWebSocket.sendUnAuthenticated("signup", "isFraudulentPaymentInformation", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.isFraudulent);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            });
        };

        self.createAccount = (signupData) => {
            if (signupData.sessionId === null || signupData.secretKey === null) {
                throw new Error("SessionID or Secret Key is null");
            }

            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _webSocketApp.send("createAccount", signupData,  (result) => {
                    deferredObject.resolve(result);
                });
            });
        };
        self.createNew = (deviceFingerPrint) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    userAgent: navigator.userAgent,
                    deviceFingerPrint: deviceFingerPrint
                };
                _clientWebSocket.sendUnAuthenticated("signup", "createSignupSession", request, (response) => {
                    if (response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to create new signup session"));
                    }
                });
            });
        };
        self.createNumberPort = (signupSessionId, secretKey) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                };
                _clientWebSocket.sendUnAuthenticated("signup", "createNumberPort", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to create new number port"));
                    }
                });
            });
        };
        self.reReservePreviousNumbers = (signupSessionId, secretKey, phoneNumbers) => {
            return _promiseFactory.defer((deferredObject) => {
                const socketParams = {
                    signupSessionId : signupSessionId,
                    secretKey: secretKey,
                    phoneNumbers: phoneNumbers
                };
                _webSocketApp.send("reReservePhoneNumbers", socketParams,  (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        self.reReserveSessionPhoneNumber = (signupSessionId, secretKey) => {
            return _promiseFactory.defer( (deferredObject) => {
                const socketParams = {
                    signupSessionId : signupSessionId,
                    secretKey: secretKey
                };

                _webSocketApp.send("reReserveSessionPhoneNumber", socketParams,  (result) => {
                    if(result.status === "error") {
                        deferredObject.reject(new Error("Error reserving phone number"));
                    } else {
                        deferredObject.resolve(result);
                    }
                });
            });
        };

        self.reserveNumber = ({signupSessionId, secretKey, phoneNumber, region, city, searchType, vanityString = null}) => {
            return _promiseFactory.defer((deferredObject) => {
                const socketParams = {
                    signupSessionId,
                    secretKey,
                    phoneNumber,
                    city,
                    region,
                    vanityString,
                    searchType,
                };

                _webSocketApp.send("reservePhoneNumber", socketParams, (result) => {
                    if (result.status === "error") {
                        deferredObject.reject(new Error("Error reserving phone number"));
                    } else {
                        deferredObject.resolve(result);
                    }
                });
            });
        };

        self.reserveLocalPhoneNumberWithoutLocation = ({signupSessionId, secretKey, phoneNumber, searchType, vanityString}) => {
            return _promiseFactory.defer( (deferredObject) => {
                const socketParams = {
                    signupSessionId,
                    secretKey,
                    phoneNumber,
                    vanityString,
                    searchType,
                };
                _webSocketApp.send("reserveLocalPhoneNumberWithoutLocation", socketParams,  (result) => {
                    if(result.status === "error") {
                        deferredObject.reject(new Error("Error reserving phone number"));
                    } else {
                        deferredObject.resolve(result);
                    }
                });
            });
        };

        self.saveBusinessName = (signupSessionId, secretKey, businessName) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    businessName: businessName
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateBusinessName", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update first name"));
                    }
                });
            });
        };

        self.checkIsFraudulentDevice = (signupSessionId, secretKey, requestId) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    requestId: requestId
                };
                _clientWebSocket.sendUnAuthenticated("signup", "isFraudulentDevice", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.isFraudulent);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            });
        };

        self.checkIsFraudulentEmailAddress = (signupSessionId, secretKey, emailAddress) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    emailAddress: emailAddress
                };
                _clientWebSocket.sendUnAuthenticated("signup", "isFraudulentEmailAddress", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.isFraudulent);
                    } else {
                        deferredObject.resolve(false);
                    }
                });
            });
        };

        self.saveDeviceFingerPrint = (signupSessionId, secretKey, deviceFingerPrint) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    deviceFingerPrint: deviceFingerPrint
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateDeviceFingerPrint", request, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update device finger print"));
                    }
                });
            });
        };
        self.saveEmailAddress = (signupSessionId, secretKey, emailAddress) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    emailAddress: emailAddress
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateEmailAddress", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update email address"));
                    }
                });
            });
        };
        self.saveFirstName = (signupSessionId, secretKey, firstName) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    firstName: firstName
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateFirstName", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update first name"));
                    }
                });
            });
        };
        self.saveFlow = (signupSessionId, secretKey, flow) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    flow: flow
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateFlow", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update flow"));
                    }
                });
            });
        };
        self.saveLastName = (signupSessionId, secretKey, lastName) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    lastName: lastName
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateLastName", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update last name"));
                    }
                });
            });
        };
        self.saveMobileNumber = (signupSessionId, secretKey, mobileNumber) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    mobilePhoneNumber: mobileNumber
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateMobilePhoneNumber", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update mobile number"));
                    }
                });
            });
        };

        self.saveMobileNumberCountryCode = (signupSessionId, secretKey, mobilePhoneCountryCode) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    mobilePhoneNumberCountryCode: mobilePhoneCountryCode
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateMobilePhoneNumberCountry", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update mobile phone number country code"));
                    }
                });
            });
        };
        self.saveNumberPort = (signupSessionId, secretKey, numberPort) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPort: numberPort
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPort", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port"));
                    }
                });
            });
        };
        self.saveNumberPortBillingAccountNumber = (signupSessionId, secretKey, numberPortId, accountNumber) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingAccountNumber: accountNumber
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingAccountNumber", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port account number"));
                    }
                });
            });
        };
        self.saveNumberPortBillingAddressLine1 = (signupSessionId, secretKey, numberPortId, billingAddress) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingAddressLine1: billingAddress
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingAddressLine1", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing address line 1"));
                    }
                });
            });
        };
        self.saveNumberPortBillingAddressLine2 = (signupSessionId, secretKey, numberPortId, billingApt) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingAddressLine2: billingApt
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingAddressLine2", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing address line 2"));
                    }
                });
            });
        };
        self.saveNumberPortBillingCity = (signupSessionId, secretKey, numberPortId, billingCity) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingCity: billingCity
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingCity", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing city"));
                    }
                });
            });
        };
        self.saveNumberPortBillingCountry = (signupSessionId, secretKey, numberPortId, countryAbbreviation) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingCountry: countryAbbreviation
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingCountry", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing country"));
                    }
                });
            });
        };
        self.saveNumberPortBillingName = (signupSessionId, secretKey, numberPortId, billingName) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingName: billingName
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingName", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing name"));
                    }
                });
            });
        };
        self.saveNumberPortBillingPhoneNumber = (signupSessionId, secretKey, numberPortId, phoneNumber) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingPhoneNumber: phoneNumber
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingPhoneNumber", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing phone number"));
                    }
                });
            });
        };
        self.saveNumberPortBillingPhoneNumberCountry = (signupSessionId, secretKey, numberPortId, countryAbbreviation) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingPhoneNumberCountry: countryAbbreviation
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingPhoneNumberCountry", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing phone number country"));
                    }
                });
            });
        };

        self.saveNumberPortBillingPinPasscode = (signupSessionId, secretKey, numberPortId, pinOrPasscode) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingPinPasscode: pinOrPasscode
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingPinPasscode", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port pin or passcode"));
                    }
                });
            });
        };
        self.saveNumberPortBillingState = (signupSessionId, secretKey, numberPortId, billingState) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingState: billingState
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingState", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing country"));
                    }
                });
            });
        };
        self.saveNumberPortBillingZipPostalCode = (signupSessionId, secretKey, numberPortId, zipPostalCode) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    billingZipPostalCode: zipPostalCode
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortBillingZipPostalCode", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port billing zip code"));
                    }
                });
            });
        };
        self.saveNumberPortCarrier = (signupSessionId, secretKey, numberPortId, carrier) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    carrier: carrier
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortCarrier", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port carrier"));
                    }
                });
            });
        };
        self.saveNumberPortDesiredPortDate = (signupSessionId, secretKey, numberPortId, portDate) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    desiredPortDate: portDate
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortDesiredPortDate", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port desired port date"));
                    }
                });
            });
        };
        self.saveNumberPortPhoneNumbers = (signupSessionId, secretKey, numberPortId, numbersToPort) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    phoneNumbersToPort: numbersToPort
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortPhoneNumbers", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port phone numbers"));
                    }
                });
            });
        };
        self.saveNumberPortOtherNumbersOnAccount = (signupSessionId, secretKey, numberPortId, areOtherNumbersOnAccount) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberPortId: numberPortId,
                    otherNumbersOnAccount: areOtherNumbersOnAccount
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPortOtherNumbersOnAccount", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.numberPortDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update number port other numbers on account"));
                    }
                });
            });
        };
        self.saveNumberTypeSelection = (signupSessionId, secretKey, numberTypeSelection) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    numberTypeSelection: numberTypeSelection
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberTypeSelection", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to update phone type selection"));
                    }
                });
            });
        };
        self.savePaymentExpirationDate = (sessionId, secretKey, expirationMonth, expirationYear) => _promiseFactory.defer(deferredObject => {
            const socketParams = {
                signupSessionId: sessionId,
                secretKey: secretKey,
                expirationMonth: expirationMonth,
                expirationYear: expirationYear,
            };
            _clientWebSocket.sendUnAuthenticated("signup", 'saveExpirationDate', socketParams, result => {
                deferredObject.resolve(result);
            });
        });
        self.savePaymentHolderName = (sessionId, secretKey, cardHolderName) => _promiseFactory.defer(deferredObject => {
            const socketParams = {
                signupSessionId: sessionId,
                secretKey: secretKey,
                cardHolderName: cardHolderName
            };
            _clientWebSocket.sendUnAuthenticated("signup", 'saveCardHolderName', socketParams, result => {
                deferredObject.resolve(result);
            });
        });
        self.savePaymentIsComplete = (sessionId, secretKey, isPaymentMethodCompleted) => _promiseFactory.defer(deferredObject => {
            const socketParams = {
                signupSessionId: sessionId,
                secretKey: secretKey,
                isPaymentMethodCompleted: isPaymentMethodCompleted
            };
            _clientWebSocket.sendUnAuthenticated("signup", 'savePaymentIsComplete', socketParams, result => {
                deferredObject.resolve(result);
            });
        });
        self.savePaymentPostalCode = (sessionId, secretKey, countryCode, postalCode) => _promiseFactory.defer(deferredObject => {
            const socketParams = {
                signupSessionId: sessionId,
                secretKey: secretKey,
                countryCode: countryCode,
                postalCode: postalCode,
            };
            _clientWebSocket.sendUnAuthenticated("signup", 'savePaymentPostalCode', socketParams, result => {
                deferredObject.resolve(result);
            });
        });
        self.saveSignupSessionStatus = (sessionId, secretKey, signupSessionStatus) => {
            return _promiseFactory.defer((deferredObject) => {
                const socketParams = {
                    signupSessionId: sessionId,
                    secretKey: secretKey,
                    signupSessionStatus: signupSessionStatus
                };
                _clientWebSocket.sendUnAuthenticated("signup", 'saveSessionStatus', socketParams, result => {
                    deferredObject.resolve(result);
                });
            });
        };
        self.saveVerificationAnswer = (signupSessionId, secretKey, verificationAnswer) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    verificationAnswer: verificationAnswer
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateVerificationAnswer", parameters, (result) => {
                    deferredObject.resolve(result);
                });
            });
        };
        self.saveVerificationQuestion = (sessionId, secretKey, verificationQuestionId) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: sessionId,
                    secretKey: secretKey,
                    verificationQuestionId: verificationQuestionId
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateVerificationQuestion", parameters, (result) => {
                    deferredObject.resolve(result);
                });
            });
        };
        self.setEmailCompleted = (signupSessionId, secretKey, emailAddress, emailVerificationCode) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    emailAddress: emailAddress,
                    emailVerificationCode: emailVerificationCode,
                };
                _clientWebSocket.sendUnAuthenticated("signup", "setEmailCompleted", parameters, (response) => {
                    deferredObject.resolve(response.signupDocument);
                });
            });
        };
        self.sendEmailVerificationCode = (signupSessionId, secretKey) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey
                };
                _clientWebSocket.sendUnAuthenticated("signup", "sendEmailVerificationCode", parameters, (response) => {
                    deferredObject.resolve(response.signupDocument);
                });
            });
        };
        self.setPhoneNumberCompleted = (signupSessionId, secretKey, isPhoneNumberCompleted) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    isPhoneNumberCompleted: isPhoneNumberCompleted
                };
                _clientWebSocket.sendUnAuthenticated("signup", "setPhoneNumberCompleted", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to set phone number step to completed"));
                    }
                });
            });
        };
        self.setProfileCompleted = (signupSessionId, secretKey, isProfileCompleted) => {
            return _promiseFactory.defer((deferredObject) => {
                const parameters = {
                    signupSessionId: signupSessionId,
                    secretKey: secretKey,
                    isProfileCompleted: isProfileCompleted
                };
                _clientWebSocket.sendUnAuthenticated("signup", "setProfileCompleted", parameters, (response) => {
                    if(response.isSuccessful) {
                        deferredObject.resolve(response.signupDocument);
                    } else {
                        deferredObject.reject(new Error("Unable to set profile step to completed"));
                    }
                });
            });
        };
        self.update = (signupEntity) => {
            _validateSignupEntity(signupEntity);

            return _promiseFactory.defer((deferredObject) => {
                deferredObject.resolve(signupEntity);
            });
        };
        self.updateNumberPort = (numberPortEntity) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    numberPort: numberPortEntity
                };
                _clientWebSocket.sendUnAuthenticated("signup", "updateNumberPort", request, (result) => {
                    deferredObject.resolve(result.isSuccessful);
                });
            });
        };
        const _validateSignupEntity = (signupEntity) => {
            if (signupEntity.signupSessionId === null || signupEntity.signupSessionId === undefined) {
                throw new Error("Session Signup Id should not be null");
            }
            if (signupEntity.secretKey === null || signupEntity.secretKey === undefined) {
                throw new Error("secretKey should not be null");
            }
        };
        self.saveSearchAndNumberType = ({signupSessionId, secretKey, searchType, numberType}) => _promiseFactory.defer(promise => {
            const parameters = {signupSessionId, secretKey, searchType, numberType};
            _clientWebSocket.sendUnAuthenticated("signup", "saveSearchAndNumberType", parameters, (response) => {
                promise.resolve();
            });
        });

        self.saveResellerAndClickId = ({signupSessionId, secretKey, resellerId, clickId}) => _promiseFactory.defer(promise => {
            const parameters = {signupSessionId, secretKey, resellerId, clickId};
            _clientWebSocket.sendUnAuthenticated("signup", "saveResellerAndClickId", parameters, (response) => {
                promise.resolve(response.isSuccessful);
            });
        });

        self.init =  () => {
            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("signup");
        };
    };
});

