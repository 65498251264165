define('persistence/repositories/paymentMethodRepository',['persistence/webSocket/webSocketApp',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var _webSocketApp = null;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        
        var _validatePaymentMethodIdPresent = function(paymentMethodId) {
            if (paymentMethodId === null) {
                throw new Error("paymentMethodId should not be null");
            }
        };

        self.add = function(paymentMethodEntity) {
            if (paymentMethodEntity.paymentMethodId !== null) {
                throw new Error("paymentMethodId should be null");
            }
            return _promiseFactory.defer(function(deferredObject) {
                var socketParams = {
                    paymentMethod : paymentMethodEntity
                };

                _webSocketApp.send("addPaymentMethod", socketParams, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.edit = function(paymentMethodEntity) {
            _validatePaymentMethodIdPresent(paymentMethodEntity.paymentMethodId);
            return _promiseFactory.defer(function(deferredObject) {
                var socketParams = {
                    paymentMethod : paymentMethodEntity
                };

                _webSocketApp.send("editPaymentMethod", socketParams, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.setDefault = function(paymentMethodEntity) {
            _validatePaymentMethodIdPresent(paymentMethodEntity.paymentMethodId);
            return _promiseFactory.defer(function(deferredObject) {
                var socketParams = {
                    paymentMethodId : paymentMethodEntity.paymentMethodId
                };

                _webSocketApp.send("setDefault", socketParams, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.remove = function(paymentMethodId) {
            _validatePaymentMethodIdPresent(paymentMethodId);
            var socketParams = {
                paymentMethodId: paymentMethodId
            };
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("removePaymentMethod", socketParams, function (result) {
                    if(result.status === "success") {
                        deferredObject.resolve(result);
                    } else {
                        var error = new Error(result.status);
                        deferredObject.reject(error);
                    }
                });
            });
        };

        self.init = function () {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("payment_method");
        };
    };
});

