define('presentation/signup/validators/signupPhoneNumberPortBillingValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validPhoneNumberSpecification',
        'businessServices/specifications/validPostalCodeSpecification',
        'common/converters/phoneNumberFormatter',
        'constants/countryEnumerations'],
    function() {
        return function() {
            let self = this;

            let _commonValidator = null;
            let _facade = null;
            let _viewModel = null;

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
            const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

            const ValidPostalCodeConstructor = require('businessServices/specifications/validPostalCodeSpecification');
            const _validPostalCodeSpecification = new ValidPostalCodeConstructor();

            const _countryEnumerations = require('constants/countryEnumerations');

            self.registerViewModel = function(viewModel, facade) {
                const validationRules = new ValidationRulesConstructor();

                validationRules.field("billingInfoName")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'signupPhoneNumberPortBilling:billingNameRequired');
                validationRules.field("billingInfoNumber")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'signupPhoneNumberPortBilling:billingNumberRequired')
                    .addValidationKeyUp(_validatePhoneNumberLength)
                    .addValidationI18n(_validatePhoneNumberLength, 'signupPhoneNumberPortBilling:billingNumberLength');
                validationRules.field("billingInfoAddress")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'signupPhoneNumberPortBilling:billingAddressRequired');
                validationRules.field("billingInfoCity")
                    .addValidationKeyUp(_isFieldPresent)
                    .addValidationI18n(_isFieldPresent, 'signupPhoneNumberPortBilling:billingCityRequired');
                validationRules.field("billingInfoZip")
                    .addValidationKeyUp(_validatePostalCodeRequired)
                    .addValidationI18n(_validatePostalCodeRequired, _validatePostalCodeI18nKey)
                    .addValidationI18n(_validatePostalCodeValid, 'signupPhoneNumberPortBilling:postalCodeInvalid')
                    .addValidationI18n(_validatePostalCodeExists, 'signupPhoneNumberPortBilling:postalCodeInvalid')
                    .addValidationI18n(_validateZipCodeValid, 'signupPhoneNumberPortBilling:zipCodeInvalid')
                    .addValidationI18n(_validateZipCodeExists, 'signupPhoneNumberPortBilling:zipCodeInvalid');

                validationRules.validationGroupKeyUp('isValid', ["billingInfoName", "billingInfoNumber", "billingInfoAddress", "billingInfoCity", "billingInfoZip"]);

                _viewModel = viewModel;
                _facade = facade;
                _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
            };

            self.validate = function() {
                return _commonValidator.validate();
            };

            const _isFieldPresent = (fieldValue) => {
                return _commonValidator.isStringWithValue(fieldValue);
            };

            const _validatePhoneNumberLength = (phoneNumber) => {
                if (phoneNumber) {
                    let cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                    return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
                }
                return true;
            };

            const _validatePostalCodeRequired = (postalCode) => {
                return _commonValidator.isStringWithValue(postalCode);
            };

            const _validatePostalCodeValid = (postalCode) => {
                if (_viewModel.billingInfoCountry()[0] === _countryEnumerations.ca.toUpperCase()) {
                    let countryCode = _viewModel.billingInfoCountry()[0].toLowerCase();
                    return _validPostalCodeSpecification.isSatisfiedBy(postalCode, countryCode);
                }
                return true;
            };

            const _validatePostalCodeExists = (postalCode) => {
                if (_viewModel.billingInfoCountry()[0] === _countryEnumerations.ca.toUpperCase()) {
                    return _validPostalCodeSpecification.doesPostalCodeExist(postalCode);
                }
                return true;
            };

            const _validateZipCodeValid = (zipCode) => {
                if (_viewModel.billingInfoCountry()[0] === _countryEnumerations.usa.toUpperCase()) {
                    let countryCode = _viewModel.billingInfoCountry()[0].toLowerCase();
                    return _validPostalCodeSpecification.isSatisfiedBy(zipCode, countryCode);
                }
                return true;
            };

            const _validateZipCodeExists = (zipCode) => {
                if (_viewModel.billingInfoCountry()[0] === _countryEnumerations.usa.toUpperCase()) {
                    return _validPostalCodeSpecification.doesPostalCodeExist(zipCode);
                }
                return true;
            };

            const _validatePostalCodeI18nKey = ko.pureComputed(() => {
                if (_viewModel.billingInfoCountry()[0] === _countryEnumerations.ca.toUpperCase()) {
                         return 'signupPhoneNumberPortBilling:postalCodeRequired';
                }  else {
                    return 'signupPhoneNumberPortBilling:zipCodeRequired';
                }
            });

        };
    });
