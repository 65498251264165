define('presentation/messages/viewModels/conversationSubscriberCardViewModel',[
    'i18next',
    'common/converters/userFormatter',
    'common/storage/commonState',
    'settings/navigationConfiguration',
    'businessServices/router/router',
    'constants/userGroupConstants'
], function (
    /** @type import('i18next') */
    i18n,
    /** @type typeof import('common/converters/userFormatter') */
    UserFormatter,
    /** @type typeof import('common/storage/commonState') */
    CommonState,
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration,
    /** @type typeof import('businessServices/router/router') */
    _router
) {
    return function() {
        const self = this;

        const _userFormatter = new UserFormatter();
        const _userGroupConstants = require('constants/userGroupConstants');


        /** @type {KnockoutObservable<IUserAvatar>} */
        self.userAvatar = null;
        self.membersCount = 0;
        self.contactUrl = ko.observable("");

        self.viewContact = () => {
            if (self.contactUrl()) {
                const contactUrl = `${_navigationConfiguration.routesById.contact.baseUrl}/${self.contactUrl()}`;
                _router.navigate(contactUrl);
            }
        };

        /** @type {IParsedSubscriber} */
        self.subscriber = null;
        /** @type {string} */
        self.userGroupId = null;
        /** @type {string} */
        self.userInitials = null;
        self.showMenu = ko.observable(false);
        self.isHoveringOver = ko.observable(false);
        self.onMouseEnter = () => {
            self.isHoveringOver(true);
        };

        self.comingSoon = i18n.t("conversationSubscriberCard:comingSoon");
        self.userToUserNotSupported = i18n.t("conversationSubscriberCard:userToUserNotSupported");

        self.showAvatar = ko.pureComputed(() => {
            if (!self.userAvatar) {
                return false;
            }

            const { showDefaultImage } = self.userAvatar();
            return !showDefaultImage();
        });

        self.showInitials = ko.pureComputed(() => {
            if (!self.userAvatar) {
                return false;
            }

            const { showDefaultImage } = self.userAvatar();
            return showDefaultImage();
        });

        self.onMouseLeave = () => {
            self.isHoveringOver(false);
        };

        self.onMenuButtonClick = () => {
            self.showMenu(!self.showMenu());
        };

        self.activate = (/** @type { { subscriber: IParsedSubscriber }} */activationData) => {
            const { subscriber } = activationData;
            self.subscriber = subscriber;

            if (subscriber.userId) {
                const { avatar, name } = CommonState.get(subscriber.userId);
                const [first, last] = name().split(' ');
                self.userInitials = _userFormatter.formatUserInitials(first, last);
                self.userAvatar = avatar;
                self.contactUrl(subscriber.userId);
            }
            else {
                const { members } = CommonState.get(subscriber.groupId);
                self.membersCount = members().length;
                self.userGroupId = subscriber.groupId;

                switch (subscriber.subscriberName) {
                    case i18n.t('allUsers'):
                        self.contactUrl(_userGroupConstants.allUserGroupId);
                        break;
                    case i18n.t('billingAdmin'):
                        self.contactUrl(_userGroupConstants.billingAdminsGroupId);
                        break;
                    case i18n.t('systemAdmin'):
                        self.contactUrl(_userGroupConstants.systemAdminsGroupId);
                        break;
                    default:
                        self.contactUrl(subscriber.groupId);
                            break;
                }
            }
        };
    };
});
