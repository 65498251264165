define('businessServices/router/plugins/signupForCustomerRoutePlugin',[
        'constants/signupSessionFlowConstants',
        'settings/navigationConfiguration'
    ],
    function() {
        function signupForCustomerUrlRedirect(urlFragment) {
            if (urlFragment.startsWith("signupForCustomer/") === false || urlFragment.split("/").length < 2) {
                return true;
            }

            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
            const signupEmailRouteUrl = _navigationConfiguration.routesById.signupEmail.url.replace(":flow", _signupSessionFlowConstants.flowA);
            return {routeUrl: signupEmailRouteUrl};
        }

        return {
            loadUrlRedirect: signupForCustomerUrlRedirect
        };
    });

