define('presentation/settings/schedules/validators/addScheduleViewModelValidator',[
    'common/storage/commonState',
    'common/uniqueId/guidUtil',
    'constants/autoAttendantVoiceRouteEnumerations',
    'presentation/common/routeToModal/common/routeToValidationHelper',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator'
], function() {
    return function() {
        const self = this;

        const RouteToValidationHelperConstructor = require('presentation/common/routeToModal/common/routeToValidationHelper');
        const _validationHelper = new RouteToValidationHelperConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _commonState = require('common/storage/commonState');
        const _guidUtil = require('common/uniqueId/guidUtil');

        let _commonValidator = null;
        let _viewModel;
        let _facade;

        self.registerViewModel = (viewModel, facade) => {
            _commonValidator = new CommonValidatorConstructor(viewModel);
            _facade = facade;
            _viewModel = viewModel;

            let validationRules = new ValidationRulesConstructor();
            validationRules.field("name")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addSchedule:nameRequired')
                .addValidationI18n(_validateNameUnique, 'addSchedule:nameNotUnique');
            validationRules.field('isDefaultRoutingRuleValid')
                .addValidationI18n(_isDefaultRoutingRuleValid, 'addSchedule:routingRuleError');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        const _validateNameUnique = (scheduleName) => {
            const _scheduleId = _guidUtil.maybeGuidToId(_viewModel.scheduleId);
            return _commonState.schedules().every(schedule => schedule.name() !== scheduleName || schedule.id === _scheduleId);
        };

        const _isDefaultRoutingRuleValid = () => _validationHelper.validateRouteToViewModel(_viewModel.defaultRoutingRuleViewModel());

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.resetValidation = () => {
            _viewModel.name.resetValidation();
        };
    };
});

