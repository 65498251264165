define('persistence/repositories/audioConversionRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(audioConversionEntity) {
            if (audioConversionEntity.voicePromptId !== null) {
                throw new Error("Audio Conversion should be empty");
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var audioConversionDocument = {
                        data : audioConversionEntity
                    };
                    _kazooApi.callAccount('/lobby_audio_conversion', 'PUT', audioConversionDocument)
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.audioConversionEntity = audioConversionEntity;
                            deferredObject.reject(error);
                        });
                });
            }

        };

        self.update = function(audioConversionEntity) {
            if (audioConversionEntity.audioConversionId === null) {
                throw new Error("Audio Conversion Id doesn't exist");
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var audioConversionDocument = {
                        data : audioConversionEntity
                    };

                    _kazooApi.callAccount('/lobby_audio_conversion/'+audioConversionEntity.audioConversionId, 'POST', audioConversionDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.audioConversionEntity = audioConversionEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.remove = function(audioConversionId) {
            if (audioConversionId === null) {
                throw new Error("Audio Conversion Id doesn't exist");
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_audio_conversion/'+audioConversionId,'DELETE')
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.audioConversionId = audioConversionId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});
