define('persistence/fileStores/userAvatarFileStore',[
    'businessServices/events/eventManager',
    'common/files/encoding',
    'common/promises/promiseFactory',
    'persistence/fileStores/fileManagement/fileReader',
    'persistence/webSocket/webSocketApp'
], function() {
    return function() {
        var self = this;
        var JPEG_PREFIX = "data:image/jpeg;base64,";

        var _encoding = null;
        var _eventManager = null;
        var _fileReader = null;
        var _promiseFactory = null;
        var _webSocketApp = null;

        self.isValidAvatarFile = function(fileHandler) {
            var fileInfo = _fileReader.getFileMetadata(fileHandler);
            switch (fileInfo.fileEncodingType.toLowerCase()) {
                case "image/bmp":
                case "image/png":
                case "image/jpg":
                case "image/jpeg":
                    return true;
            }
            return false;
        };

        self.uploadUserAvatar = function(userId, avatar) {
            return _promiseFactory.defer(function(promise) {
                var fileData = avatar.avatarUrl();
                if (fileData.indexOf(JPEG_PREFIX) === 0) {
                    fileData = fileData.substr(JPEG_PREFIX.length);
                }

                var socketParams = {
                    userId: userId,
                    avatarFileEncodingType: avatar.encodingType,
                    avatarFileData: fileData
                };

                _webSocketApp.send("new_avatar", socketParams, function() {
                    promise.resolve();
                });
            });
        };

        self.init = function() {
            var EncodingConstructor = require('common/files/encoding');
            _encoding = new EncodingConstructor();

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            _promiseFactory = new PromiseFactoryConstructor();

            var FileReaderConstructor = require('persistence/fileStores/fileManagement/fileReader');
            _fileReader = new FileReaderConstructor();

            _eventManager = require('businessServices/events/eventManager');

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("avatar_socket");
        };
    };
});

