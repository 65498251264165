define('presentation/messages/viewModels/conversationGroupAvatarViewModel',[
    'businessServices/contacts/contactsStateSingleton',
    'common/converters/userFormatter',
    'common/storage/commonState'
],(
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('common/converters/userFormatter') */
    UserFormatter,
    /** @type typeof import('common/storage/commonState') */
    CommonState
) => {
    /**
     * @typedef { {
     *  showAvatar: KnockoutObservable<boolean> | boolean
     *  showText: boolean
     *  showIcon: boolean
     *  containerHeight: number
     *  containerWidth: number
     *  groupIconHeight: number
     *  groupIconWidth: number
     *  containerCss: string,
     *  iconName: string,
     *  text: number | KnockoutObservable<string> | string
     *  avatarUrl?: KnockoutObservable<string> | string
     * } } Member
     * 
     * @typedef { {
     *  avatarHeight: number,
     *  avatarWidth: number,
     *  quarterIconContainerHeight: number,
     *  quarterIconContainerWidth: number,
     *  quarterIconHeight: number,
     *  quarterIconWidth: number,
     *  halfIconContainerHeight: number,
     *  halfIconContainerWidth: number,
     *  halfIconHeight: number,
     *  halfIconWidth: number,
     *  fullIconContainerHeight: number,
     *  fullIconContainerWidth: number,
     *  fullIconHeight: number,
     *  fullIconWidth: number
     * } } Dimensions
     * 
     * @typedef { {
     *  activate: (params: any) => void,
     *  detached: (params: any) => void
     *  userGroupId: KnockoutObservable<string>
     *  members: KnockoutComputed<Member[]>
     *  recipients: KnockoutObservable<string[]>
     *  sidebarContacts: KnockoutComputed<ISidebarContactPresentationObject[]>
     *  toRem: (value: number) => string,
     *  avatarHeight?: string,
     *  avatarWidth?: string
     * } } ViewModel
     * 
     */

    return function() {
        /** @type {ViewModel} */
        const self = this;

        const _userFormatter = new UserFormatter();

        const _defaultAvatarContainerHeightWidth = 40;

        /** @type {IDisposable[]} */
        let _disposables = [];

        /**
         * 
         * @param {number} memberCount Total Members
         * @param {number} index Member Position
         * @returns {Member} Member Container/Icon Binding and Styling
         */
        const _prepareDefaultMemberDisplay = (memberCount, index) => {
            /**
             * @type {Partial<Member>}
             */
            const member = {
                containerCss: '',
                iconName: 'contact',
                text: null,
                avatarUrl: null,
                showAvatar: false,
                showIcon: false,
                showText: false
            };

            switch (memberCount) {
                case 0:
                case 1:
                    member.containerHeight = _dimensions.fullIconContainerHeight;
                    member.containerWidth = _dimensions.fullIconContainerWidth;
                    member.containerCss = 'user-avatar-icon';
                    member.groupIconHeight = _dimensions.fullIconHeight;
                    member.groupIconWidth = _dimensions.fullIconWidth;
                    break;
                case 2:
                    member.containerHeight = _dimensions.halfIconContainerHeight;
                    member.containerWidth = _dimensions.halfIconContainerWidth;
                    member.groupIconHeight = _dimensions.halfIconHeight;
                    member.groupIconWidth = _dimensions.halfIconWidth;
                    switch (index) {
                        case 0:
                            member.containerCss = 'icon-group-half icon-group-half-left';
                            break;
                        case 1:
                            member.containerCss = 'icon-group-half icon-group-half-right';
                            break;
                    }
                    break;
                case 3:
                    // eslint-disable-next-line no-case-declarations
                    const isFirstMember = index === 0;
                    member.containerHeight = isFirstMember ? _dimensions.halfIconContainerHeight : _dimensions.quarterIconContainerHeight;
                    member.containerWidth = isFirstMember ? _dimensions.halfIconContainerWidth : _dimensions.quarterIconContainerWidth;
                    member.groupIconHeight = isFirstMember ? _dimensions.halfIconHeight : _dimensions.quarterIconHeight;
                    member.groupIconWidth = isFirstMember ? _dimensions.halfIconWidth : _dimensions.quarterIconWidth;
                    switch (index) {
                        case 0:
                            member.containerCss = 'icon-group-half icon-group-half-left';
                            break;
                        case 1:
                            member.containerCss = 'icon-group-quarter icon-group-quarter-top-right';
                            break;
                        case 2:
                            member.containerCss = 'icon-group-quarter icon-group-quarter-bottom-right';
                            break;
                    }
                    break;
                default:
                    member.containerHeight = _dimensions.quarterIconContainerHeight;
                    member.containerWidth = _dimensions.quarterIconContainerWidth;
                    member.groupIconHeight = _dimensions.quarterIconHeight;
                    member.groupIconWidth = _dimensions.quarterIconWidth;
                    switch (index) {
                        case 0:
                            member.containerCss = 'icon-group-quarter icon-group-quarter-top-left';
                            break;
                        case 1:
                            member.containerCss = 'icon-group-quarter icon-group-quarter-top-right';
                            break;
                        case 2:
                            member.containerCss = 'icon-group-quarter icon-group-quarter-bottom-left';
                            break;
                        case 3:
                            member.containerCss = 'icon-group-quarter icon-group-quarter-bottom-right';
                            member.text = memberCount > 4 ? memberCount : 0;
                            break;
                    }
                    break;
            }

            return /** @type {Member} */(member);
        };

        const _prepareUserGroupForDisplay = () => {
            const userGroupId = self.userGroupId();
            const memberCount = _memberCount;
            const maxAvatars = Math.min(memberCount, 4);

            /** @type {Member[]} */
            const members = [];

            for (let i = 0; i < maxAvatars; i++) {
                const member = _prepareDefaultMemberDisplay(memberCount, i);
                members.push(member);

                if (member.text) {
                    member.showText = true;
                }
                else if (!userGroupId) {
                    member.showIcon = true;
                }
            }

            if (userGroupId) {
                const commonStateGroup = CommonState.get(userGroupId);
                const avatars = commonStateGroup.avatars();
                const groupMembers = commonStateGroup.members();

                if (maxAvatars === 1) {
                    const [first, last = ""] = commonStateGroup.name().split(' ');
                    members[0].text = (first[0] + last[0]).toUpperCase();
                    members[0].showText = true;
                }

                for (let i = 0; i < maxAvatars; i++) {
                    const member = members[i];
                    const avatar = avatars[i];

                    if (member.text) {
                        continue;
                    }
                    else if (avatar === undefined || avatar.showDefaultImage()) {
                        const { name } = CommonState.get(groupMembers[i]);
                        const [first, last] = name().split(' ');
                        member.text = _userFormatter.formatUserInitials(first, last);
                        member.showText = true;
                    }
                    else {
                        member.avatarUrl = avatar.avatarUrl;
                        member.showAvatar = true;
                    }
                }
            }
            return members;
        };

        const _prepareRecipientsForDisplay = () => {
            const memberCount = _memberCount;
            const maxAvatars = Math.min(memberCount, 4);
            const maxMembers = self.recipients().slice(0, maxAvatars);

            let draftMembers = [];
            if (maxMembers.length === 0) {
                for (let i = 0; i < memberCount; i++) {
                    const member = _prepareDefaultMemberDisplay(memberCount, i);
                    member.showIcon = true;
                    draftMembers.push(member);
                }

                return draftMembers;
            }

            const formattedMembers = maxMembers.map((recipient, i) => {
                const member = _prepareDefaultMemberDisplay(memberCount, i);
                if (member.text) {
                    member.showText = true;
                    return member;
                }

                const contact = _contactsState.getContactByPhoneNumber(recipient);
                if (contact) {
                    member.avatarUrl = contact.avatarUrl;
                    member.showAvatar = contact.hasAvatar;
                    member.text = contact.initials;
                    member.showText = !contact.hasAvatar();
                    return member;
                }

                member.showIcon = true;
                return member;
            });

            return formattedMembers;
        };

        /**
         * @param {number?} height
         * @param {number?} width
         */
         const _computeDimensions = (height, width) => {
            const avatarHeight = height || _defaultAvatarContainerHeightWidth;
            const avatarWidth = width || _defaultAvatarContainerHeightWidth;
            const quarterIconContainerHeight = avatarHeight / 2.0 - 1;
            const quarterIconContainerWidth = avatarWidth / 2.0 - 1;
            const quarterIconHeight = quarterIconContainerHeight - 4.0;
            const quarterIconWidth = quarterIconContainerWidth - 4.0;
            const halfIconContainerHeight = avatarHeight;
            const halfIconContainerWidth = avatarWidth / 2.0 - 1;
            const halfIconHeight = halfIconContainerHeight - 2.0;
            const halfIconWidth = halfIconContainerWidth - 2.0;
            const fullIconContainerHeight = avatarHeight;
            const fullIconContainerWidth = avatarWidth;
            const fullIconHeight = fullIconContainerHeight / 2.0 + 4;
            const fullIconWidth = fullIconContainerWidth / 2.0 + 4;

            return {
                avatarHeight,
                avatarWidth,
                quarterIconContainerHeight,
                quarterIconContainerWidth,
                quarterIconHeight,
                quarterIconWidth,
                halfIconContainerHeight,
                halfIconContainerWidth,
                halfIconHeight,
                halfIconWidth,
                fullIconContainerHeight,
                fullIconContainerWidth,
                fullIconHeight,
                fullIconWidth
            };
        };

        let _memberCount = 0;
        /** @type {Dimensions} */
        let _dimensions;

        self.userGroupId = ko.observable();
        self.recipients = ko.observableArray([]);
        self.sidebarContacts = _contactsState.sidebarContacts;

        self.members = ko.pureComputed(() => {
            if (self.userGroupId()){
                return _prepareUserGroupForDisplay();
            }

            if (self.sidebarContacts() || self.recipients()){
                return _prepareRecipientsForDisplay();
            }
        });

        /**
         * @param {number} value Pixel Value
         */
         self.toRem = (value) => {
            return `${value / 16}rem`;
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        /**
         * 
         * @param {{
         *  memberCount: number | KnockoutObservable<number>,
         *  recipients?: string[] | KnockoutObservableArray<string>,
         *  avatarHeight: number | KnockoutObservable<number>,
         *  avatarWidth: number | KnockoutObservable<number>
         *  userGroupId?: string | KnockoutObservable<string>
         * }} settings
         */
        self.activate = (settings) => {
            _memberCount = ko.unwrap(settings.memberCount) || 0;
            if (ko.isObservable(settings.recipients)) {
                self.recipients = settings.recipients;
            } else {
                self.recipients(settings.recipients);
            }

            if (ko.isObservable(settings.userGroupId)) {
                self.userGroupId = settings.userGroupId;
            } else {
                self.userGroupId(settings.userGroupId);
            }

            _dimensions = _computeDimensions(
                ko.unwrap(settings.avatarHeight),
                ko.unwrap(settings.avatarWidth)
            );

            self.avatarHeight = self.toRem(_dimensions.avatarHeight);
            self.avatarWidth = self.toRem(_dimensions.avatarWidth);

            _disposables.push(
                self.recipients.subscribe(_prepareRecipientsForDisplay),
                self.sidebarContacts.subscribe(_prepareRecipientsForDisplay)
            );
        };
    };
});
