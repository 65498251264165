define('presentation/messages/utils/messagesHelper',[
    'constants/messageDirection',
    'constants/attachmentFileTypesConstants'
], function () {
    /** @typedef {import('presentation/messages/utils/messagesHelper')} MessagesHelper */

    const MessageDirection = require('constants/messageDirection');
    const AttachmentTypes = require('constants/attachmentFileTypesConstants');

    /** @type {MessagesHelper[_determineMessageText]} */
    const _determineMessageText = (content, direction, attachments) => {
        if (content && content.length) {
            return content;
        }

        if (!attachments.length) {
            return null;
        }

        const allAttachments = attachments.length;
        let videoAttachments = 0;
        let imageAttachments = 0;
        let audioAttachments = 0;
        let otherAttachments = 0;

        for (const attachment of attachments) {
            const contentType = attachment.contentType;

            if (contentType.includes(AttachmentTypes.video)) {
                videoAttachments++;
            }
            else if (contentType.includes(AttachmentTypes.image)) {
                imageAttachments++;
            }
            else if (contentType.includes(AttachmentTypes.audio)) {
                audioAttachments++;
            }
            else {
                otherAttachments++;
            }
        }

        const sentOrReceived = direction === MessageDirection.incoming ? `Received` : `Sent`;

        const mixedMedia = otherAttachments ||
            (videoAttachments && imageAttachments) ||
            (videoAttachments && audioAttachments) ||
            (imageAttachments && audioAttachments);

        if (mixedMedia) {
            const plural = attachments.length > 1 ? "s" : "";
            return `${allAttachments} File${plural} ${sentOrReceived}`;
        }

        if (videoAttachments) {
            const plural = videoAttachments > 1 ? "s" : "";
            return `${videoAttachments} Video${plural} ${sentOrReceived}`;
        }

        if (imageAttachments) {
            const plural = imageAttachments > 1 ? "s" : "";
            return `${imageAttachments} Photo${plural} ${sentOrReceived}`;
        }

        const plural = audioAttachments > 1 ? "s" : "";
        return `${audioAttachments} Audio Clip${plural} ${sentOrReceived}`;
    };

    /** @type {MessagesHelper[_determineMessageTextFromLastMessage]} */
    const _determineMessageTextFromLastMessage = (lastMessage) => {
        const {content, direction, attachments} = lastMessage;
        return _determineMessageText(content, direction, attachments);
    };

    /** @type {MessagesHelper} */
    return function() {
        /** @type {MessagesHelper} */
        const self = this;
        self.determineMessageText = _determineMessageText;
        self.determineMessageTextFromLastMessage = _determineMessageTextFromLastMessage;
    };
});

