define('presentation/messages/bindings/messagesComposeAreaBinding',[

],
function(){
    return {
        /** @type import('presentation/messages/bindings/messagesComposeAreaBinding')["init"] */
        init: function (element) {
            /** @type {OverlayScrollbars} */
            let scrollbarInstance;
            const conversationMainContent = document.querySelector('.conversation__main-content');
            const conversationHeader = document.querySelector('.conversation__header');
            const conversationMessageComposeArea = document.querySelector('.conversation__message-compose-area');
            /** @type {HTMLDivElement} */
            const composeAreaComponent = conversationMessageComposeArea.querySelector('.message-compose-area');
            /** @type {HTMLDivElement} */
            const composeAreaComponentTextArea = composeAreaComponent.querySelector('.message-compose-area__textarea');
            const composeAreaComponentMinHeight = 94;
            /** @type {Number} */
            let asyncResizeHandle;

            const createScrollbars = () => {
                return $(element).overlayScrollbars({
                    className: "os-theme-tresta message-compose-area__overlay-scrollbars-textarea-wrapper",
                    sizeAutoCapable: true,
                    overflowBehavior : {
                        y: "scroll",
                        x: "hidden"
                    },
                    textarea: {
                        dynWidth  : false,
                        dynHeight : true,
                        inheritedAttrs : ["style", "class"]
                    }
                }).overlayScrollbars();
            };

            /** @type {ResizeObserverCallback} */
            const onResize = (entries) => {
                const composeAreaMaxHeightUpperBound = 512;
                const minimumMessageHeight = 70;

                let conversationMainContentRect;
                for (const entry of entries) {
                    const { target, contentRect } = entry;

                    if (target === conversationMainContent) {
                        conversationMainContentRect = contentRect;
                        break;
                    }
                }

                const composeAreaComponentRect = composeAreaComponent.getBoundingClientRect();
                const conversationHeaderRect = conversationHeader.getBoundingClientRect();
                const conversationMessageComposeAreaRect = conversationMessageComposeArea.getBoundingClientRect();
                conversationMainContentRect = conversationMainContentRect || conversationMainContent.getBoundingClientRect();

                clearTimeout(asyncResizeHandle);

                asyncResizeHandle = setTimeout(() => {
                    const composeAreaComponentMargin = conversationMessageComposeAreaRect.height - composeAreaComponentRect.height;
                    const composeAreaComponentHeight = conversationHeaderRect.height;

                    const availableComposeAreaComponentHeight =
                        Math.max(conversationMainContentRect.height - conversationHeaderRect.height - minimumMessageHeight - composeAreaComponentMargin,
                            composeAreaComponentMinHeight);

                    const maxComposeAreaComponentHeight = Math.min(composeAreaMaxHeightUpperBound, availableComposeAreaComponentHeight);

                    const composeAreaComponentMaxHeight = `${maxComposeAreaComponentHeight}px`;

                    if (composeAreaComponent.style.maxHeight !== composeAreaComponentMaxHeight) {
                        composeAreaComponent.style.maxHeight = composeAreaComponentMaxHeight;
                    }

                    if (composeAreaComponentHeight > availableComposeAreaComponentHeight) {
                        composeAreaComponent.style.height = composeAreaComponentMaxHeight;
                        const textAreaElementRect = composeAreaComponentTextArea.getBoundingClientRect();
                        const textAreaElementHeight = textAreaElementRect.height - composeAreaComponentHeight + availableComposeAreaComponentHeight;
                        composeAreaComponentTextArea.style.height = `${textAreaElementHeight}px`;
                    }
                }, 20);
            };

            /* jshint ignore:start */
            const resizeObserver = new ResizeObserver(onResize);
            resizeObserver.observe(conversationHeader);
            resizeObserver.observe(conversationMainContent);
            /* jshint ignore:end */

            scrollbarInstance = createScrollbars();

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (scrollbarInstance) {
                    scrollbarInstance.destroy();
                }

                /* jshint ignore:start */
                resizeObserver.disconnect();
                /* jshint ignore:end */
            });
        }
    };
});
