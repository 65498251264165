define('presentation/common/routeToModal/viewModels/routeToViewModel',['common/promises/promiseFactory',
    'common/collections/collectionSorter',
    'constants/routingRuleConstants',
    'constants/trestaIconConstants',
    'presentation/common/routeToModal/presentationObjects/routeToOptionPresentationObject',
    'presentation/common/routeToModal/presentationObjects/routeToSelectedPresentationObject',
    'presentation/common/routeToModal/presentationObjects/forwardingNumbersPresentationObject',
    'presentation/common/routeToModal/viewModels/routeToAutoAttendantViewModel',
    'presentation/common/routeToModal/viewModels/routeToForwardingNumberViewModel',
    'presentation/common/routeToModal/viewModels/routeToPromptViewModel',
    'presentation/common/routeToModal/viewModels/routeToScheduleViewModel',
    'presentation/common/routeToModal/viewModels/routeToSubmenuViewModel',
    'presentation/common/routeToModal/viewModels/routeToUserViewModel',
    'presentation/common/routeToModal/viewModels/routeToUserGroupViewModel',
    'presentation/common/routeToModal/viewModels/routeToVoicemailBoxViewModel',
    'presentation/settings/autoAttendant/presentationObjects/subMenuPresentationObject',
    'common/storage/commonState',
    'common/collections/collectionSorter'
], function() {
    return function(routeToType, routeToData, validateRouteToViewModelCallback, routeToOptions) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const RouteToAutoAttendantViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToAutoAttendantViewModel');
        const RouteToForwardingNumberViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToForwardingNumberViewModel');
        const RouteToPromptViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToPromptViewModel');
        const RouteToScheduleViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToScheduleViewModel');
        const RouteToSubmenuViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToSubmenuViewModel');
        const RouteToUserViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToUserViewModel');
        const RouteToUserGroupViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToUserGroupViewModel');
        const RouteToVoicemailBoxViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToVoicemailBoxViewModel');
        const RouteToOptionPresentationObjectConstructor = require('presentation/common/routeToModal/presentationObjects/routeToOptionPresentationObject');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _routingRuleConstants = require('constants/routingRuleConstants');
        const _trestaIconConstants = require('constants/trestaIconConstants');
        const _commonState = require('common/storage/commonState');
        const _sorter = new SorterConstructor();
        const _i18n = require('i18next');

        const _promiseFactory = new PromiseFactoryConstructor();
        let _initialSelectedAnswerTimeoutDropDownMenuOption = null;
        let _selectedFormAttached = false;
        let _disposables = [];

        const PLEASE_SELECT = _voiceRouteEnumerations.pleaseSelect;
        const OWNER_VOICEMAIL = 'owner_voicemail';
        const OTHER_VOICEMAIL = 'other_voicemail';
        const NO_TIMEOUT = 'no_timeout';
        const ROUTE_TO = 'route_to';
        const HANGUP = 'hangup';

        const ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX = {
            avatarType: 'voicemailBox',
            displayText: ko.pureComputed(() => {
                const ownerId = self.routeToOwnerId();
                if (ownerId) {
                    const owner = _commonState.get(ownerId);
                    if (owner) {
                        const ownerName = owner.name();
                        return _i18n.t('routeTo:rolloverToVoicemailBox', {ownerName: ownerName});
                    }
                }
                return _i18n.t('routeTo:rolloverToUserVoicemail');
            }),
            value: OWNER_VOICEMAIL,
            ownerType: 'user',
            ownerId: null,
            voicemailBoxId: null
        };

        const ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX = {
            avatarType: 'voicemailBox',
            displayText : ko.pureComputed(() => {
                const ownerId = self.routeToOwnerId();
                if (ownerId) {
                    const owner = _commonState.get(ownerId);
                    if (owner) {
                        const ownerName = owner.name();
                        return _i18n.t('routeTo:rolloverToVoicemailBox', {ownerName: ownerName});
                    }
                }
                return _i18n.t('routeTo:rolloverToUserGroupVoicemail');
            }),
            value: OWNER_VOICEMAIL,
            ownerType: 'userGroup',
            ownerId: null,
            voicemailBoxId: null
        };

        const ANSWER_TIMEOUT_DROPDOWN_MENU__NO_TIMEOUT = {
            avatarType: 'ringing',
            displayText: _i18n.t('routeTo:ringingWillPlay'),
            value: NO_TIMEOUT,
            ownerType: null,
            ownerId: null,
            voicemailBoxId: null
        };
        const ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO = {
            avatarType: 'ringing',
            displayText: _i18n.t('routeTo:routeToAnotherDestination'),
            value: ROUTE_TO,
            ownerType: null,
            ownerId: null,
            voicemailBoxId: null
        };
        const ANSWER_TIMEOUT_DROPDOWN_MENU_HANGUP = {
            avatarType: 'hangup',
            displayText: _i18n.t('routeTo:hangUp'),
            value: HANGUP,
            ownerType: null,
            ownerId: null,
            voicemailBoxId: null
        };
        const ANSWER_TIMEOUT_DROPDOWN_MENU__DEFAULT_OPTIONS = [
            ANSWER_TIMEOUT_DROPDOWN_MENU_HANGUP,
            ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO
        ];

        const _buildRouteToOptionsList = (routeToOptions) => {
            if (routeToOptions.autoAttendant.enabled === true) {
                const autoAttendantRouteToOption = new RouteToOptionPresentationObjectConstructor();
                autoAttendantRouteToOption.value = _voiceRouteEnumerations.autoAttendant;
                autoAttendantRouteToOption.title = _i18n.t('routeTo:sendToAutoAttendant');
                autoAttendantRouteToOption.sortValue = 'sendToAutoAttendant';
                autoAttendantRouteToOption.searchableTitle = _removeHtmlTags(autoAttendantRouteToOption.title);
                autoAttendantRouteToOption.icon = _trestaIconConstants.autoAttendant;
                routeToOptions.autoAttendant = autoAttendantRouteToOption;
                routeToOptions.list.push(autoAttendantRouteToOption);
            }

            if (routeToOptions.forwardingNumber.enabled === true) {
                const forwardingNumberRouteToOption = new RouteToOptionPresentationObjectConstructor();
                forwardingNumberRouteToOption.value = _voiceRouteEnumerations.forwardingNumber;
                forwardingNumberRouteToOption.title = _i18n.t('routeTo:sendToForwardingNumber');
                forwardingNumberRouteToOption.sortValue = 'sendToForwardingNumber';
                forwardingNumberRouteToOption.searchableTitle = _removeHtmlTags(forwardingNumberRouteToOption.title);
                forwardingNumberRouteToOption.icon = _trestaIconConstants.forwardingNumber;
                routeToOptions.forwardingNumber = forwardingNumberRouteToOption;
                routeToOptions.list.push(forwardingNumberRouteToOption);
            }

            if (routeToOptions.prompt.enabled === true) {
                const promptRouteToOption = new RouteToOptionPresentationObjectConstructor();
                promptRouteToOption.value = _voiceRouteEnumerations.prompt;
                promptRouteToOption.title = _i18n.t('routeTo:playAudioFile');
                promptRouteToOption.sortValue = 'playAudioFile';
                promptRouteToOption.searchableTitle = _removeHtmlTags(promptRouteToOption.title);
                promptRouteToOption.icon = _trestaIconConstants.prompt;
                routeToOptions.prompt = promptRouteToOption;
                routeToOptions.list.push(promptRouteToOption);
            }

            if (routeToOptions.schedule.enabled === true) {
                const scheduleRouteToOption = new RouteToOptionPresentationObjectConstructor();
                scheduleRouteToOption.value = _voiceRouteEnumerations.schedule;
                scheduleRouteToOption.title = _i18n.t('routeTo:useSchedule');
                scheduleRouteToOption.sortValue = 'useSchedule';
                scheduleRouteToOption.searchableTitle = _removeHtmlTags(scheduleRouteToOption.title);
                scheduleRouteToOption.icon = _trestaIconConstants.schedule;
                routeToOptions.schedule = scheduleRouteToOption;
                routeToOptions.list.push(scheduleRouteToOption);
            }

            if (routeToOptions.subMenu.enabled === true) {
                const submenuRouteToOption = new RouteToOptionPresentationObjectConstructor();
                submenuRouteToOption.value = _voiceRouteEnumerations.subMenu;
                submenuRouteToOption.title = _i18n.t('routeTo:sendToSubMenu');
                submenuRouteToOption.sortValue = 'sendToSubMenu';
                submenuRouteToOption.searchableTitle = _removeHtmlTags(submenuRouteToOption.title);
                submenuRouteToOption.icon = _trestaIconConstants.subMenu;
                routeToOptions.subMenu = submenuRouteToOption;
                routeToOptions.list.push(submenuRouteToOption);
            }

            if (routeToOptions.user.enabled === true) {
                const userRouteToOption = new RouteToOptionPresentationObjectConstructor();
                userRouteToOption.value = _voiceRouteEnumerations.user;
                userRouteToOption.title = _i18n.t('routeTo:sendToUser');
                userRouteToOption.sortValue = 'sendToUser';
                userRouteToOption.searchableTitle = _removeHtmlTags(userRouteToOption.title);
                userRouteToOption.icon = _trestaIconConstants.user;
                routeToOptions.user = userRouteToOption;
                routeToOptions.list.push(userRouteToOption);
            }

            if (routeToOptions.userGroup.enabled === true) {
                const userGroupRouteToOption = new RouteToOptionPresentationObjectConstructor();
                userGroupRouteToOption.id = null;
                userGroupRouteToOption.value = _voiceRouteEnumerations.userGroup;
                userGroupRouteToOption.title = _i18n.t('routeTo:sendToGroup');
                userGroupRouteToOption.sortValue = 'sendToGroup';
                userGroupRouteToOption.searchableTitle = _removeHtmlTags(userGroupRouteToOption.title);
                userGroupRouteToOption.icon = _trestaIconConstants.userGroup;
                routeToOptions.userGroup = userGroupRouteToOption;
                routeToOptions.list.push(userGroupRouteToOption);
            }

            if (routeToOptions.voicemailBox.enabled === true) {
                const voicemailBoxRouteToOption = new RouteToOptionPresentationObjectConstructor();
                voicemailBoxRouteToOption.value = _voiceRouteEnumerations.voicemailBox;
                voicemailBoxRouteToOption.title = _i18n.t('routeTo:sendToVoicemailBox');
                voicemailBoxRouteToOption.sortValue = 'sendToVoicemailBox';
                voicemailBoxRouteToOption.searchableTitle = _removeHtmlTags(voicemailBoxRouteToOption.title);
                voicemailBoxRouteToOption.icon = _trestaIconConstants.voicemail;
                routeToOptions.voicemailBox = voicemailBoxRouteToOption;
                routeToOptions.list.push(voicemailBoxRouteToOption);
            }

            _sorter.sort(routeToOptions.list, "sortValue", true);

            return routeToOptions;
        };

        const _removeHtmlTags = (stringWithTags) => {
            let stringWithoutTags = stringWithTags.replaceAll('<strong>', "").replaceAll('</strong>', "");
            return stringWithoutTags.replaceAll('&nbsp;', " ");
        };

        const _addVoicemailBoxOptionToAnswerTimeoutDropDownMenu = (routeToType) => {
            switch (routeToType) {
                case _voiceRouteEnumerations.user:
                    self.answerTimeoutDropDownMenuOptions().splice(1, 0, ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX);
                    break;
                case _voiceRouteEnumerations.userGroup:
                    self.answerTimeoutDropDownMenuOptions().splice(1, 0, ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX);
                    break;
            }
        };

        const _setAnswerTimeoutDropDownMenu_noTimeout = () => {
            self.answerTimeoutDropDownMenuOptions().splice(1, 0, ANSWER_TIMEOUT_DROPDOWN_MENU__NO_TIMEOUT);
            self.selectedAnswerTimeoutDropDownMenuOption(ANSWER_TIMEOUT_DROPDOWN_MENU__NO_TIMEOUT);
            self.answerTimeoutDropMenuOptionsEnabled(false);
        };

        const _answerTimeoutChanged = (value) => {
            if (value === NO_TIMEOUT) {
                self.answerTimeoutDropMenuOptionsEnabled(false);
                _setAnswerTimeoutDropDownMenu_noTimeout();
            } else {
                self.answerTimeoutDropMenuOptionsEnabled(true);
                self.answerTimeoutDropDownMenuOptions(
                    self.answerTimeoutDropDownMenuOptions().filter((option) => {
                        return option.value !== NO_TIMEOUT;
                    })
                );
                // If we've removed NO_TIMEOUT from the list of Dropdown Menu Options and the selected option is set to
                // NO_TIMEOUT we need to also update the selected value.
                if (self.selectedAnswerTimeoutDropDownMenuOption() === ANSWER_TIMEOUT_DROPDOWN_MENU__NO_TIMEOUT) {
                    self.selectedAnswerTimeoutDropDownMenuOption(ANSWER_TIMEOUT_DROPDOWN_MENU_HANGUP);
                }
            }
        };

        const _selectedAnswerTimeoutDropDownMenuChanged = (selectedAnswerTimeoutDropDownMenu) => {
            if (selectedAnswerTimeoutDropDownMenu.value === ROUTE_TO) {
                if (self.routeToSelection() !== _voiceRouteEnumerations.prompt) {
                    self.routeToSelection(PLEASE_SELECT);
                } else {
                    self.routeToTypeSelectedForm().determineSubsequentRoutes();
                }
            }
            self.showRouteToOptions(selectedAnswerTimeoutDropDownMenu.value === ROUTE_TO);
            self.showAnotherVoicemailBoxDropDown(selectedAnswerTimeoutDropDownMenu.value === OTHER_VOICEMAIL);
        };

        const _changeRouteToOption = () => {
            return _promiseFactory.defer((deferredObject) => {
                self.routeToData(null);
                if (self.routeToSelection() === null || self.routeToSelection().length === 0) {
                    deferredObject.resolve();
                    return;
                }

                if (self.routeToSelection() === PLEASE_SELECT) {
                    self.routeToTypeSelectedForm(null);
                    deferredObject.resolve();
                    return;
                }

                self.routeTo(self.routeToOptions[self.routeToSelection()]);
                let routingRuleExitCondition = null;

                let routeToConstructor;
                switch (self.routeTo().value) {
                    case self.routeToOptions.autoAttendant.value:
                        routeToConstructor = new RouteToAutoAttendantViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routingRuleExitCondition, validateRouteToViewModelCallback);
                        break;
                    case self.routeToOptions.forwardingNumber.value:
                        routeToConstructor = new RouteToForwardingNumberViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, self.routeToData(), validateRouteToViewModelCallback, routeToOptions, self.parentViewModel(), self.isRouteToModal());
                        break;
                    case self.routeToOptions.prompt.value:
                        routeToConstructor = new RouteToPromptViewModelConstructor(self.initialRouteToId, self.initialRouteToType, routingRuleExitCondition, validateRouteToViewModelCallback, routeToOptions, self.parentViewModel(), true, self.isRouteToModal());
                        break;
                    case self.routeToOptions.subMenu.value:
                        routeToConstructor = new RouteToSubmenuViewModelConstructor();
                        break;
                    case self.routeToOptions.schedule.value:
                        routeToConstructor = new RouteToScheduleViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routingRuleExitCondition, validateRouteToViewModelCallback);
                        break;
                    case self.routeToOptions.user.value:
                        routeToConstructor = new RouteToUserViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, self.routeToData(), validateRouteToViewModelCallback, routeToOptions, self.parentViewModel(), self.isRouteToModal());
                        break;
                    case self.routeToOptions.userGroup.value:
                        routeToConstructor = new RouteToUserGroupViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, self.routeToData(), validateRouteToViewModelCallback, routeToOptions, self.parentViewModel(), self.isRouteToModal());
                        break;
                    case self.routeToOptions.voicemailBox.value:
                        routeToConstructor = new RouteToVoicemailBoxViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routingRuleExitCondition, validateRouteToViewModelCallback);
                }
                self.routeToTypeSelectedForm(routeToConstructor);
                self.routeToTypeSelectedForm().routeToStrategy = self.routeToStrategy;

                validateRouteToViewModelCallback(false);

                deferredObject.resolve();
            });
        };

        const _populateInitialRouteToSelection = () => {
            if (self.routeToOptions.list.length === 1) {
                self.routeToSelection(self.routeToOptions.list[0].value);
            } else {
                let initialSelection = self.routeToOptions.list.find((option) => {
                    return option.value === self.initialRouteToType;
                });

                self.routeToSelection(initialSelection === undefined ? PLEASE_SELECT : initialSelection.value);
            }
        };

        self.routeToStrategy = null;
        self.routeTo = ko.observable();
        self.routeToData = ko.observable();
        self.initialRouteToId = null;
        self.initialRouteToType = null;
        self.routeToTypeSelectedForm = ko.observable().extend({observeState: true});
        self.routeToSelection = ko.observable({}).extend({observeState: true});
        self.parentViewModel = ko.observable(null);
        self.placeholderMessage = _i18n.t('routeTo:pleaseSelect');
        self.previousRouteToSelection = null;
        self.modalTitle = ko.observable();
        self.routeTitle = ko.observable();
        self.scrollOnSelect = ko.observable(false);
        self.showRouteToTitle = ko.observable(false);
        self.isOptionNameEnabled = false;
        self.isChild = ko.observable(false);
        self.isCompositionComplete = ko.observable(false);
        self.isRouteToModal = ko.observable(false);
        self.scrollIntoView = ko.observable(true);
        self.hasAnotherDestination = ko.pureComputed(() => self.selectedAnswerTimeoutDropDownMenuOption().value === ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO.value);
        self.optionName = ko.observable(null);
        self.routeToOptions = {
            autoAttendant: {
                enabled: routeToOptions && routeToOptions.autoAttendant ? routeToOptions.autoAttendant.enabled : true
            },
            forwardingNumber: {
                enabled: routeToOptions && routeToOptions.forwardingNumber ? routeToOptions.forwardingNumber.enabled : true
            },
            prompt: {
                enabled: routeToOptions && routeToOptions.prompt ? routeToOptions.prompt.enabled : true
            },
            schedule: {
                enabled: routeToOptions && routeToOptions.schedule ? routeToOptions.schedule.enabled : true
            },
            subMenu: {
                enabled: routeToOptions && routeToOptions.subMenu ? routeToOptions.subMenu.enabled : true
            },
            user: {
                enabled: routeToOptions && routeToOptions.user ? routeToOptions.user.enabled : true
            },
            userGroup: {
                enabled: routeToOptions && routeToOptions.userGroup ? routeToOptions.userGroup.enabled : true
            },
            voicemailBox: {
                enabled: routeToOptions && routeToOptions.voicemailBox ? routeToOptions.voicemailBox.enabled : true
            },
            list: []
        };

        self.showRouteToOptions = ko.observable(false);
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.showAnswerTimeoutOptions = ko.observable(true);
        self.isPromptPreviousRoute = false;
        self.secondsText = _i18n.t('routeTo:seconds');
        self.noTimeoutText = _i18n.t('routeTo:noTimeout');
        self.answerTimeoutOptions = [
            {title: `10 ${self.secondsText}`, value: '10'},
            {title: `20 ${self.secondsText}`, value: '20'},
            {title: `30 ${self.secondsText}`, value: '30'},
            {title: `40 ${self.secondsText}`, value: '40'},
            {title: `50 ${self.secondsText}`, value: '50'},
            {title: `60 ${self.secondsText}`, value: '60'},
            {title: self.noTimeoutText, value: NO_TIMEOUT}
        ];
        self.currentAnswerTimeout = ko.observable('30').extend({observeState: true});
        self.answerTimeoutDropMenuOptionsEnabled = ko.observable(true);
        self.answerTimeoutDropDownMenuOptions = ko.observable(ANSWER_TIMEOUT_DROPDOWN_MENU__DEFAULT_OPTIONS);
        self.selectedAnswerTimeoutDropDownMenuOption = ko.observable(ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO).extend({observeState: true});
        self.showAnotherVoicemailBoxDropDown = ko.observable(false);
        self.isCallflowValid = ko.observable(null);
        self.hasNoRouteSelected = ko.pureComputed(() => { return self.routeToSelection() === PLEASE_SELECT; });
        self.voicemailBoxes = ko.pureComputed(() => {
            const voicemailBoxes = _commonState.voicemailBoxes().map(voicemailBox => {
                const commonStateOwnerItem = _commonState.get(voicemailBox.ownerId());
                const name = commonStateOwnerItem && voicemailBox.name() === "undefined" ? {name: commonStateOwnerItem.name()} : {};
                const avatarType = commonStateOwnerItem ? {avatarType: _commonState.get(voicemailBox.ownerId()).type} : {avatarType: voicemailBox.type};
                const avatar = commonStateOwnerItem && commonStateOwnerItem.type === "user" ? {avatar: commonStateOwnerItem.avatar()} : {};
                return Object.assign({}, voicemailBox, {}, avatarType, avatar, name);
            });
            _sorter.sort(voicemailBoxes, "name", true);
            return voicemailBoxes;
        });
        self.selectedVoicemailBox = ko.observable(self.voicemailBoxes()[0]).extend({observeState: true});
        self.validateRouteToModalCallback = null;
        self.autoFocus = false;
        self.shouldFadeIn = ko.computed(() => self.isRouteToModal() && self.routeToSelection() === PLEASE_SELECT);

        self.setAnswerTimeoutDropDownMenu_routeTo = (answerTimeoutInSeconds, routeToType) => {
            _addVoicemailBoxOptionToAnswerTimeoutDropDownMenu(routeToType);
            _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO;
            self.selectedAnswerTimeoutDropDownMenuOption(ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO);
            self.currentAnswerTimeout(answerTimeoutInSeconds);
        };

        self.setAnswerTimeoutDropDownMenu_hangup = (answerTimeoutInSeconds, routeToType) => {
            _addVoicemailBoxOptionToAnswerTimeoutDropDownMenu(routeToType);
            _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU_HANGUP;
            self.selectedAnswerTimeoutDropDownMenuOption(ANSWER_TIMEOUT_DROPDOWN_MENU_HANGUP);
            self.currentAnswerTimeout(answerTimeoutInSeconds);
        };

        self.setAnswerTimeoutDropDownMenu_noTimeout = (ownerId, routeToType) => {
            _addVoicemailBoxOptionToAnswerTimeoutDropDownMenu(routeToType);
            if (routeToType === _voiceRouteEnumerations.user) {
                _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX;
            } else if (routeToType === _voiceRouteEnumerations.userGroup) {
                _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX;
            } else {
                _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU_ROUTE_TO;
            }

            self.currentAnswerTimeout(NO_TIMEOUT);
            _setAnswerTimeoutDropDownMenu_noTimeout();
        };

        self.setAnswerTimeoutDropDownMenu_rolloverToUserVoicemailBox = (userId, answerTimeoutInSeconds) => {
            self.updateRouteOwner(userId);
            ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX.ownerId = userId;
            _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX;
            self.selectedAnswerTimeoutDropDownMenuOption(ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX);
            self.answerTimeoutDropDownMenuOptions().splice(1, 0, ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX);
            if (answerTimeoutInSeconds) {
                self.currentAnswerTimeout(answerTimeoutInSeconds);
            }
        };

        self.setAnswerTimeoutDropDownMenu_rolloverToUserGroupVoicemailBox = (userGroupId, answerTimeoutInSeconds) => {
            self.updateRouteOwner(userGroupId);
            ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX.ownerId = userGroupId;
            _initialSelectedAnswerTimeoutDropDownMenuOption = ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX;
            self.selectedAnswerTimeoutDropDownMenuOption(ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX);
            self.answerTimeoutDropDownMenuOptions().splice(1, 0, ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX);
            if (answerTimeoutInSeconds) {
                self.currentAnswerTimeout(answerTimeoutInSeconds);
            }
        };

        self.answerTimeoutDropDownMenuOptions_addVoicemailBoxRoutes = (routeToType) => {
            _addVoicemailBoxOptionToAnswerTimeoutDropDownMenu(routeToType);
        };

        self.routeToOwnerId = ko.observable();

        self.updateRouteOwner = (ownerId) => {
            self.routeToOwnerId(ownerId);
        };

        self.selectedFormAttached = () => {
            if (self.isRouteToModal()) {
                if (_selectedFormAttached === false) {
                    _selectedFormAttached = true;
                }
                self.isCompositionComplete(false);
            }
        };

        self.selectedFormCompositionComplete = () => {
            if (self.isRouteToModal()) {
                if (_selectedFormAttached){
                    self.isCompositionComplete(true);
                }
            }

            if (self.isCompositionComplete()) {
                self.scrollOnSelect(true);
            }
        };

        self.compositionComplete = () => {
            if (!self.modelStateObserver.isChild) {
                self.modelStateObserver.commitData();
            }
            if (!self.isRouteToModal()){
                self.isCompositionComplete(true);
            }
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            _buildRouteToOptionsList(self.routeToOptions);

            let routeToTypeSelectedForm;
            if (routeToType === "routingRule") {
                switch (routeToData.routingType) {
                    case _routingRuleConstants.allUserDevices:
                        self.initialRouteToType = _voiceRouteEnumerations.user;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({userId: routeToData.routeToUserId});
                        self.initialRouteToId = self.routeToData().userId;
                        routeToTypeSelectedForm = new RouteToUserViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routeToData, validateRouteToViewModelCallback, routeToOptions, self.parentViewModel());
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.allUserGroupDevices:
                        self.initialRouteToType = _voiceRouteEnumerations.userGroup;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({userGroupId: _commonState.get(routeToData.routeToUserGroupId).id});
                        self.initialRouteToId = self.routeToData().userGroupId;
                        routeToTypeSelectedForm = new RouteToUserGroupViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routeToData, validateRouteToViewModelCallback, routeToOptions, self.parentViewModel());
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.autoAttendant:
                        self.initialRouteToType = _voiceRouteEnumerations.autoAttendant;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({autoAttendantId: routeToData.routeToAutoAttendantId});
                        self.initialRouteToId = self.routeToData().autoAttendantId;
                        routeToTypeSelectedForm = new RouteToAutoAttendantViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routeToData.routingRuleExitConditions, validateRouteToViewModelCallback);
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        self.routeToTypeSelectedForm().routeToStrategy = self.routeToStrategy;
                        break;
                    case _routingRuleConstants.forwardingNumber:
                        self.initialRouteToType = _voiceRouteEnumerations.forwardingNumber;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({forwardingNumberId: routeToData.routeToForwardingNumberId});
                        self.initialRouteToId = self.routeToData().forwardingNumberId;
                        routeToTypeSelectedForm = new RouteToForwardingNumberViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routeToData, validateRouteToViewModelCallback, routeToOptions, self.parentViewModel());
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.schedule:
                        self.initialRouteToType = _voiceRouteEnumerations.schedule;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({scheduleId: routeToData.routeToScheduleId});
                        self.initialRouteToId = self.routeToData().scheduleId;
                        routeToTypeSelectedForm = new RouteToScheduleViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routeToData.routingRuleExitConditions[0], validateRouteToViewModelCallback, routeToOptions);
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.subMenu:
                        self.initialRouteToType = _voiceRouteEnumerations.subMenu;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({subMenu: routeToData.routeToSubMenu});
                        self.initialRouteToId = self.routeToData().subMenuId;
                        routeToTypeSelectedForm = new RouteToSubmenuViewModelConstructor();
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.systemAudioClip:
                        self.initialRouteToType = _voiceRouteEnumerations.prompt;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({promptId: routeToData.routeToSystemAudioClipId});
                        self.initialRouteToId = self.routeToData().promptId;
                        routeToTypeSelectedForm = new RouteToPromptViewModelConstructor(self.initialRouteToId, self.initialRouteToType, routeToData.routingRuleExitConditions, validateRouteToViewModelCallback, routeToOptions, self.parentViewModel(), true);
                        routeToTypeSelectedForm.activate();
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.voicemailBox:
                        self.initialRouteToType = _voiceRouteEnumerations.voicemailBox;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({voicemailBoxId: routeToData.routeToVoicemailBoxId});
                        self.initialRouteToId = self.routeToData().voicemailBoxId;
                        routeToTypeSelectedForm = new RouteToVoicemailBoxViewModelConstructor(self.initialRouteToId, self.initialRouteToType, self.optionName, routeToData.routingRuleExitConditions[0], validateRouteToViewModelCallback);
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                    case _routingRuleConstants.voicePrompt:
                        self.initialRouteToType = _voiceRouteEnumerations.prompt;
                        self.routeTo(self.routeToOptions[self.initialRouteToType]);
                        self.routeToData({promptId: routeToData.routeToVoicePromptId});
                        self.initialRouteToId = self.routeToData().promptId;
                        routeToTypeSelectedForm = new RouteToPromptViewModelConstructor(self.initialRouteToId, self.initialRouteToType, routeToData.routingRuleExitConditions, validateRouteToViewModelCallback, routeToOptions, self.parentViewModel(), true);
                        routeToTypeSelectedForm.activate();
                        self.routeToTypeSelectedForm(routeToTypeSelectedForm);
                        break;
                }

                if (routeToTypeSelectedForm !== undefined) {
                    self.routeToTypeSelectedForm().isNewRouteTo = false;
                    self.routeToTypeSelectedForm().routeToStrategy = self.routeToStrategy;
                    self.modelStateObserver.addChildObserverAndWatch(routeToTypeSelectedForm.modelStateObserver);
                    if ((_initialSelectedAnswerTimeoutDropDownMenuOption !== ANSWER_TIMEOUT_DROPDOWN_MENU__USER_VOICEMAIL_BOX) &&
                        (_initialSelectedAnswerTimeoutDropDownMenuOption !== ANSWER_TIMEOUT_DROPDOWN_MENU__USER_GROUP_VOICEMAIL_BOX)) {
                        self.showRouteToOptions(true);
                    }
                }
            }

            _populateInitialRouteToSelection();
            _disposables.push(self.selectedAnswerTimeoutDropDownMenuOption.subscribe(_selectedAnswerTimeoutDropDownMenuChanged));
            _disposables.push(self.selectedAnswerTimeoutDropDownMenuOption.subscribe(validateRouteToViewModelCallback));
            _disposables.push(self.routeToSelection.subscribe(_changeRouteToOption));
            _disposables.push(self.currentAnswerTimeout.subscribe(_answerTimeoutChanged));
            self.isCallflowValid = self.parentViewModel().isCallflowValid;
            self.isPromptPreviousRoute = self.previousRouteToSelection === _voiceRouteEnumerations.prompt;

            return _promiseFactory.wait();
        };
    };
});

