define('presentation/signup/viewModels/signupAsideViewModel',[
        'businessServices/browserSupport/browserType',
        'businessServices/router/router',
        'businessServices/signup/signupSessionStore',
        'common/promises/promiseFactory',
        'common/storage/workSessionIdStorage',
        'presentation/signup/common/signupNavigationManager',
        'presentation/signup/common/signupPageIndicatorWidget',
        'settings/navigationConfiguration'
    ],
    function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            let _signupSessionStore = null;
            let _signupNavigationManager = null;

            const _browserType = require('businessServices/browserSupport/browserType');
            const _indicatorWidget = require('presentation/signup/common/signupPageIndicatorWidget');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _router = require('businessServices/router/router');
            const _urlFragment = _router.activeInstruction().config.hash;

            let _disposables = [];

            const _setAsideState = () => {
                switch (_urlFragment) {
                    case _navigationConfiguration.routesById.signupProfile.url:
                        self.asideHeaderText('headerText.profile');
                        self.asideMessageText('messageText.profile');
                        break;
                    case _navigationConfiguration.routesById.signupPaymentMethod.url:
                        self.asideHeaderText('headerText.paymentMethod');
                        self.asideMessageText('messageText.paymentMethod');
                        break;
                    case _navigationConfiguration.routesById.signupPhoneNumberLocal.url:
                        self.asideHeaderText('headerText.numberLocal');
                        self.asideMessageText('messageText.numberLocal');
                        break;
                    case _navigationConfiguration.routesById.signupPhoneNumberTollfree.url:
                        self.asideHeaderText('headerText.numberTollfree');
                        self.asideMessageText('messageText.numberTollfree');
                        break;
                    case _navigationConfiguration.routesById.signupPhoneNumberPort.url:
                    case _navigationConfiguration.routesById.signupPhoneNumberPortBilling.url:
                    case _navigationConfiguration.routesById.signupPhoneNumberPortAuthorization.url:
                        self.asideHeaderText('headerText.numberPort');
                        self.asideMessageText('messageText.numberPort');
                        break;
                    case _navigationConfiguration.routesById.signupPhoneNumberType.url:
                        self.asideHeaderText('headerText.numberType');
                        self.asideMessageText('messageText.numberType');
                        break;
                    case _navigationConfiguration.routesById.signupEmail.url:
                        self.asideHeaderText('headerText.email');
                        self.asideMessageText('messageText.email');
                }
            };

            self.onLogInClicked = () => {
                _router.navigate(_navigationConfiguration.loginPageUrl);
            };

            self.isEmailScreenAndFlowA = () => {
                return (
                    self.statusIndicatorIconObjects().length === 0 &&
                    _urlFragment === _navigationConfiguration.routesById.signupEmail.url
                );
            };

            self.statusIndicatorUnderBars = ko.observableArray([]);
            self.statusIndicatorIconObjects = ko.observableArray([]);
            self.isPageIndicatorVisible = ko.observable(false);
            self.asideHeaderText = ko.observable('');
            self.asideMessageText = ko.observable('');
            self.shouldDisplayLoginLink = ko.observable(false);

            self.detached = function() {
                _signupNavigationManager.detached();
                _disposables.forEach(subscription => subscription.dispose());
                _disposables = [];
            };

            self.activate = function() {
                const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
                _signupNavigationManager = new SignupNavigationManagerConstructor();
                _signupNavigationManager.init();

                _signupSessionStore = new SignupSessionStoreConstructor();
                _signupSessionStore.init();

                self.shouldDisplayLoginLink = _signupNavigationManager.isFlowStartPage();

                return _initialize();
            };

            const _initialize = () => {
                self.isMobile = () => _browserType.isMobile();
                _indicatorWidget.setIndicator(self.statusIndicatorUnderBars, self.statusIndicatorIconObjects, self.isPageIndicatorVisible);
                _setAsideState();
                _promiseFactory.wait();
            };
        };
    });
