define('presentation/common/analyticsGraph/summaryRecordView',[], function() {
        /**
         * @param {keyof IAnalyticsSummaryProjectionResult} fieldName 
         * @param {IAnalyticsSummaryProjectionResult[fieldName]} defaultValue 
         * @returns {(record: IAnalyticsSummaryProjectionResult) => IAnalyticsSummaryProjectionResult[fieldName]}
         */
        const getFieldFn = (fieldName, defaultValue) =>
            (summaryRecord) => {
                if (summaryRecord[fieldName]) {
                    return summaryRecord[fieldName];
                }
                return defaultValue;
            };

        const summaryRecord = {
            itemGuid: getFieldFn("a", null),
            itemType: getFieldFn("b", ""),
            callCount: getFieldFn("c", 0),
            callSeconds: getFieldFn("d", 0),
            outboundCount: getFieldFn("e", 0),
            outboundSeconds: getFieldFn("f", 0),
            inboundCount: getFieldFn("g", 0),
            inboundSeconds: getFieldFn("h", 0),
            answeredCount: getFieldFn("i", 0),
            answeredSeconds: getFieldFn("j", 0),
            missedCount: getFieldFn("k", 0),
            missedSeconds: getFieldFn("l", 0),
            systemCount: getFieldFn("m", 0),
            systemSeconds: getFieldFn("n", 0),
            voicemailCount: getFieldFn("o", 0),
            voicemailSeconds: getFieldFn("p", 0)
        };

        return summaryRecord;
    }
);


