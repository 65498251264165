define('presentation/common/voicePrompt/voicePromptAudioFactory',[
    'businessServices/converters/durationTimeFormatter',
    'businessServices/pathProviders/voicePromptUrlPathProvider',
    'common/promises/promiseFactory',
    'presentation/common/audioPresentationObject',
], function(){
    return function() {
        let self = this;

        const VoicePromptUrlPathProviderConstructor = require('businessServices/pathProviders/voicePromptUrlPathProvider');
        const _voicePromptUrlPathProvider = new VoicePromptUrlPathProviderConstructor();

        const AudioPresentationObjectConstructor = require('presentation/common/audioPresentationObject');
        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');

        const _readData = function(voicePromptId, audioData) {
            audioData.audioType("audio/mp3");
            _voicePromptUrlPathProvider.getPromptUrls(voicePromptId)
                .done(function(result) {
                    if (result !== '') {
                        audioData.audioData(result);
                        audioData.showAudio(true);
                        audioData.showAudioPlayer(true);
                        audioData.showConversionMessage(false);
                    } else {
                        audioData.audioData(result);
                        audioData.showAudio(false);
                        audioData.showConversionMessage(true);
                    }
                    return result;
                });
        };

        const _readSystemPromptData = (systemVoicePromptId, audioData) => {
            audioData.audioType("audio/mp3");
            _voicePromptUrlPathProvider.getSystemPromptUrl(systemVoicePromptId)
                .done(function(result) {
                    if (result !== '') {
                        audioData.audioData(result);
                        audioData.showAudio(true);
                        audioData.showAudioPlayer(true);
                        audioData.showConversionMessage(false);
                    } else {
                        audioData.audioData(result);
                        audioData.showAudio(false);
                        audioData.showConversionMessage(true);
                    }
                    return result;
                });
        };

        const _findAudioForVoicePromptId = function(voicePromptId) {
            let audioData = new AudioPresentationObjectConstructor();

            // This is an asynchronous call
            _readData(voicePromptId, audioData);

            return audioData;
        };

        const _getFormattedDurationProperty = (durationInMilliseconds) => {
            let formatter = new TimeFormatterConstructor();
            return formatter.formatTotalMilliseconds(durationInMilliseconds);
        };

        self.formatAudioSource = function(audioDataObj) {
            if (audioDataObj.audioUrl !== null) {
                audioDataObj.audioData(audioDataObj.audioUrl);
                audioDataObj.showDefaultAudio(false);
                audioDataObj.showAudio(true);
            } else if ((audioDataObj.audioType !== null) && (audioDataObj.audioBase64 !== null)) {
                audioDataObj.audioData("data:" + audioDataObj.audioType + ";base64," + audioDataObj.audioBase64);
                audioDataObj.showDefaultAudio(false);
                audioDataObj.showAudio(true);
            } else {
                audioDataObj.audioData(null);
                audioDataObj.showDefaultAudio(true);
                audioDataObj.showAudio(false);
            }
        };

        self.formatVoicePrompt = function(audioUrl, audioDuration) {
            const audioData = new AudioPresentationObjectConstructor();
            audioData.audioData(audioUrl);
            audioData.audioType("audio/mp3");
            audioData.showAudio(true);
            audioData.duration(_getFormattedDurationProperty(audioDuration));
            return audioData;
        };

        self.getVoicePrompt = function(voicePromptId) {
            const audioToCache = _findAudioForVoicePromptId(voicePromptId);
            return audioToCache;
        };

        self.getSystemVoicePrompt = (systemVoicePromptId) => {
            let audioData = new AudioPresentationObjectConstructor();

            // This is an asynchronous call
            _readSystemPromptData(systemVoicePromptId, audioData);

            return audioData;
        };

        self.getEmptyVoicePrompt = function() {
            let prompt = new AudioPresentationObjectConstructor();
            prompt.audioData("about:blank");
            prompt.audioType("audio/mp3");

            return prompt;
        };

        self.getMissingVoicePrompt = function() {
            let audio = new AudioPresentationObjectConstructor();
            audio.showAudioPlayer(false);
            audio.audioUrl = null;
            return audio;
        };

        self.init = function() {

        };
    };
});

