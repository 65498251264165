define('businessServices/router/plugins/workSessionRouterPlugin',[], function() {
    function workSessionRedirect(urlFragment) {
        var parts = urlFragment.split("/");
        if (parts.length >= 2) {
            if (parts[0] === "ws") {
                var newParts = parts.slice(2);
                var newPath = newParts.join("/");
                return { routeUrl : newPath };
            }
        }
        return true;
    }

    return {
        loadUrlRedirect : workSessionRedirect
    };
});

