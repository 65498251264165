define('constants/routingRuleConstants',[], function() {
    return {
        allUserDevices: 1,
        allUserGroupDevices: 2,
        forwardingNumber: 3,
        voicemailBox: 4,
        voicemailGreeting: 5,
        systemVoicemailGreeting: 6,
        schedule: 7,
        autoAttendant: 8,
        subMenu: 9,
        voicePrompt: 10,
        systemAudioClip: 11
    };
});
