define('businessServices/router/plugins/accountStatusEvaluatorRouterPlugin',[
    'businessServices/login/accountStatusEvaluator',
    'settings/navigationConfiguration',
    'common/promises/promiseFactory',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/authentication/sessionWorkflowFlags'
], function (accountStatusEvaluator, navigationConfiguration, PromiseFactoryConstructor, sessionAccountInfo, sessionWorkflowFlags) {
    const _accountStatusEvaluator = accountStatusEvaluator;
    const _navigationConfiguration = navigationConfiguration;
    const _promiseFactory = new PromiseFactoryConstructor();
    const _sessionAccountInfo = sessionAccountInfo;
    const _sessionWorkflowFlags = sessionWorkflowFlags;

    const ROUTE_ID__REACTIVATION = _navigationConfiguration.routesById.reactivation.routeId;
    const ROUTE_ID__LOGIN = _navigationConfiguration.routesById.login.routeId;
    const ROUTE_ID__PAY_BALANCE = _navigationConfiguration.routesById.payBalance.routeId;
    const ROUTE_ID__CLOSED_BALANCE_OWED = _navigationConfiguration.routesById.closedBalanceOwed.routeId;
    const ROUTE_ID__CLOSED_DO_NOT_REACTIVATE = _navigationConfiguration.routesById.closedDoNotReactivate.routeId;
    const ROUTE_ID__CLOSED_AND_CANNOT_REACTIVATE = _navigationConfiguration.routesById.closedAndCannotReactivate.routeId;
    const ROUTE_ID__MISCELLANEOUS_PROBLEMS = _navigationConfiguration.routesById.miscellaneousProblems.routeId;

    function isRouteSecure(routeId) {
        const navigationRouteInfo = _navigationConfiguration.routesById[routeId];
        return navigationRouteInfo.isSecure;
    }

    function routeByStatus(routeId) {
        if (_sessionAccountInfo.isLoggedIn() === false) {
            if (isRouteSecure(routeId)) {
                if (routeId !== ROUTE_ID__LOGIN) {
                    _sessionWorkflowFlags.setOriginalPathname(window.location.pathname);
                }
                return {routeUrl: _navigationConfiguration.loginPageUrl};
            } else {
                return true;
            }
        } else {
            return _promiseFactory.defer(function (deferredObject) {
                _accountStatusEvaluator.evaluate()
                    .fail(() => {
                        deferredObject.resolve(true);
                    })
                    .done(function (evaluation) {
                        switch (evaluation) {
                            case _accountStatusEvaluator.evaluateResponses.closedNoBalance:
                                if (routeId === ROUTE_ID__REACTIVATION) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.reactivation});
                                }
                                break;
                            case _accountStatusEvaluator.evaluateResponses.normal:
                                if (routeId === ROUTE_ID__REACTIVATION ||
                                    routeId === ROUTE_ID__LOGIN ||
                                    routeId === ROUTE_ID__PAY_BALANCE ||
                                    routeId === ROUTE_ID__CLOSED_BALANCE_OWED ||
                                    routeId === ROUTE_ID__CLOSED_DO_NOT_REACTIVATE ||
                                    routeId === ROUTE_ID__CLOSED_BALANCE_OWED ||
                                    routeId === ROUTE_ID__MISCELLANEOUS_PROBLEMS) {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.homePageUrl});
                                } else {
                                    deferredObject.resolve(true);
                                }
                                break;
                            case _accountStatusEvaluator.evaluateResponses.pastDueBalance:
                                if (routeId === ROUTE_ID__PAY_BALANCE) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.payBalance});
                                }
                                break;
                            case _accountStatusEvaluator.evaluateResponses.miscellaneousProblems:
                                if (routeId === ROUTE_ID__MISCELLANEOUS_PROBLEMS) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.miscellaneousProblems});
                                }
                                break;
                            case _accountStatusEvaluator.evaluateResponses.closedBalanceOwed:
                                if (routeId === ROUTE_ID__CLOSED_BALANCE_OWED) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.closedBalanceOwed});
                                }
                                break;
                            case _accountStatusEvaluator.evaluateResponses.closedDoNotReactivate:
                                if (routeId === ROUTE_ID__CLOSED_DO_NOT_REACTIVATE) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.closedDoNotReactivate});
                                }
                                break;
                            case _accountStatusEvaluator.evaluateResponses.closedAndCannotReactivate:
                                if (routeId === ROUTE_ID__CLOSED_AND_CANNOT_REACTIVATE) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.closedAndCannotReactivate});
                                }
                                break;
                            default:
                                if (routeId === _navigationConfiguration.loginPageUrl) {
                                    deferredObject.resolve(true);
                                } else {
                                    deferredObject.resolve({routeUrl: _navigationConfiguration.loginPageUrl});
                                }
                                break;
                        }
                    });
            });
        }
    }

    return {
        guardRoute: routeByStatus
    };
});

