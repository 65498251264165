define('common/presentation/viewLocatorConfiguration',['durandal/viewLocator'], function(viewLocator) {
    var _viewLocator = viewLocator;

    var _plugins = [];

    function addPlugin(plugin) {
        _plugins.push(plugin);
    }

    function defaultViewModelToViewId(viewModelId) {
        var viewDirectory = viewModelId.replace(new RegExp('viewModels', 'g'), 'views');
        var viewName = viewDirectory.replace(new RegExp('ViewModel', 'g'), '');
        var underscoresFixed = viewName.replace(new RegExp('_', 'g'), '/');
        return underscoresFixed;
    }

    function convertViewModelIdToViewId(viewModelId) {
        for (var x = 0; x < _plugins.length; x++) {
            var plugin = _plugins[x];
            var result = plugin.convertViewModelIdToView(viewModelId);
            if ((result !== undefined) && (result !== false)) {
                return result.viewId;
            }
        }
        return defaultViewModelToViewId(viewModelId);
    }

    function translateViewIdToArea(viewId, area) {
        switch (area) {
            case 'partial':
                return viewId;
            case 'phoneNumbers':
                return '/assets/js/app/presentation/settings/phoneNumbers/views/' + viewId;
            case 'prompts':
                return '/assets/js/app/presentation/common/' + viewId;
            case 'devices':
                return '/assets/js/app/presentation/common/' + viewId;
            default:
                var err = new Error("Unknown area: viewId: '" + viewId + "' area: '" + area);
                err.viewId = viewId;
                err.area = area;
                throw err;
        }
    }

    var configure = function() {
        _viewLocator.useConvention();
        _viewLocator.convertModuleIdToViewId = convertViewModelIdToViewId;
        _viewLocator.translateViewIdToArea = translateViewIdToArea;
    };

    return {
        configureViewLocator : configure,
        addPlugin : addPlugin
    };
});
