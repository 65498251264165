define('businessServices/signup/signupSessionSingleton',[], function() {
    const SignupSessionSingleton = function() {
        if (SignupSessionSingleton.prototype._singletonInstance) {
            return SignupSessionSingleton.prototype._singletonInstance;
        }
        SignupSessionSingleton.prototype._singletonInstance = this;

        this.isLoaded = false;
        this.authorizedSignature = '';
        this.headerCarrier = ko.observable('');
        this.headerPhoneNumbers = ko.observable([]);
        this.isAcknowledged = false;
        this.numberTypeSelection = ko.observable('');
        this.password = null;
        this.signupDocument = null;
    };
    return new SignupSessionSingleton();
});
