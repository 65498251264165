define('common/converters/unicodeFormatter',[],() => {
    // Adapted from code by Wiktor Stribiżew,
    // https://stackoverflow.com/questions/9849754/how-can-i-replace-newlines-line-breaks-with-spaces-in-javascript
    const _newLineRegex = /[\r\n\x0B\x0C\u0085\u2028\u2029]+/g;

    /**
     * Restore / unescape unicode characters (\u****),
     * Allows emoji to show properly
     * @param {string} message string with escaped unicode
     * @returns {string} string with unicode characters restored 
     */
    const _unescape = (message) => {
        if (!message) {
            return message;
        }

        return message.replace(/\\u(\w\w\w\w)/gi, (_, code) => {
            const charcode = parseInt(code, 16);
            return String.fromCharCode(charcode);
        });
    };

    /**
     * Replaces new lines with a specified character.
     * @param {string} unicodeString Unicode string with new line characters
     * @param {string} replacement What to replace the new line characters with
     * @returns {string} String without new line characters
     */
    const _replaceNewLines = (unicodeString, replacement = ' ') => {
        if (!unicodeString) {
            return unicodeString;
        }

        return unicodeString.replace(_newLineRegex, replacement);
    };

    return function() {
        const self = this;
        self.newLineRegex = _newLineRegex;
        self.unescape = _unescape;
        self.replaceNewLines = _replaceNewLines;
    };
});
