define('common/converters/userFormatter',[],
function() {
    /** @typedef {import('common/converters/userFormatter')} UserFormatter */

    return function() {
        const self = this;

        const _isNonEmptyString = (/** @type {string} */ test) => {
            return (typeof test === 'string') && (test.length > 0);
        };

        /** @type {UserFormatter["formatUserInitials"]} */
        self.formatUserInitials = (first, last) => {
            let result = '';

            if (_isNonEmptyString(first)) {
                result += first[0].toUpperCase();
            }

            if (_isNonEmptyString(last)) {
                result += last[0].toUpperCase();
            }

            return result;
        };

        /** @type {UserFormatter["formatUserInitialsFromFullName"]} */
        self.formatUserInitialsFromFullName = (name) => {
            let result = '';

            if (_isNonEmptyString(name)) {
                const splitName = name.trim().split(' ');
                result = splitName[0].substring(0, 1).toUpperCase();
                if (splitName.length > 1) {
                    result  += splitName[splitName.length - 1].substring(0, 1).toUpperCase();
                }
            }

            return result;
        };


        /** @type {UserFormatter["formatUserFullName"]} */
        self.formatUserFullName = (first, last) => {
            first = (first || "").trim();
            last = (last || "").trim();

            if (_isNonEmptyString(first) && _isNonEmptyString(last)) {
                return `${first} ${last}`;
            }
            else if(!_isNonEmptyString(first)){
                return last;
            }
            else if (!_isNonEmptyString(last)){
                return first;
            }

            return "";
        };
    };
});
