define('presentation/settings/userVoicemail/facades/userVoicemailGreetingsFacade',['businessServices/authentication/sessionAccountInfo',
        'businessServices/converters/durationTimeFormatter',
        'businessServices/uploads/customerUpload',
        'common/promises/promiseFactory',
        'constants/audioFileConversionStatusConstants',
        'externalDependencies/clientWebSocket',
        'presentation/common/dateTimeValue',
        'presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject',
        'presentation/settings/userVoicemail/presentationObjects/voicemailGreetingPresentationObject',
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = null;

    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
    const VoicemailGreetingAudioObjectConstructor = require('presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject');
    const VoicemailGreetingPresentationObjectConstructor = require('presentation/settings/userVoicemail/presentationObjects/voicemailGreetingPresentationObject');

    const _audioFileConversionStatusConstants = require('constants/audioFileConversionStatusConstants');
    const _customerUpload = require('businessServices/uploads/customerUpload');
    const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _greetingSocket = clientWebSocket.forApplication("userVoicemailGreetings");
    const _voicemailSocket = clientWebSocket.forApplication("userVoicemail");

    const _getUserVoicemailBox = (otherUserId) => {
        return _promiseFactory.defer((promise) => {
            let userId = otherUserId;
            if (userId === null) {
                userId = _sessionAccountInfo.userId();
            }

            const request = {
                userId: userId
            };

            _voicemailSocket.send("get", request, (result, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }

                promise.resolve(result);
            });
        });
    };

    const _updateUserVoicemailBox = (otherUserId, customGreetingId, systemGreetingId) => {
        return _promiseFactory.defer((promise) => {
            let userId = otherUserId;
            if (userId === null) {
                userId = _sessionAccountInfo.userId();
            }

            const request = {
                userId: userId,
                customGreetingId : customGreetingId,
                systemGreetingId : systemGreetingId,
            };

            _voicemailSocket.send("update", request, (result, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                promise.resolve(result);
            });
        });
    };

    const _buildVoicemailBoxGreetingForDisplay = (voicemailBoxGreeting) => {
        let voicemailGreetingPresentationObject = new VoicemailGreetingPresentationObjectConstructor();
        voicemailGreetingPresentationObject.voicemailBoxGreetingId = voicemailBoxGreeting.voicemailBoxGreetingId;
        voicemailGreetingPresentationObject.name(voicemailBoxGreeting.name);
        voicemailGreetingPresentationObject.isBuiltIn(voicemailBoxGreeting.isSystemGreeting);
        if (voicemailBoxGreeting.createdDateTime) {
            voicemailGreetingPresentationObject.createdDateTime = new DateTimeValueConstructor(voicemailBoxGreeting.createdDateTime);
        }
        if (voicemailBoxGreeting.updatedDateTime) {
            voicemailGreetingPresentationObject.modifiedDateTime = new DateTimeValueConstructor(voicemailBoxGreeting.updatedDateTime);
        }

        let audioObject = new VoicemailGreetingAudioObjectConstructor();
        if (voicemailBoxGreeting.conversionStatus === _audioFileConversionStatusConstants.converted) {
            audioObject.audioData(voicemailBoxGreeting.mp3Url);
            audioObject.duration(_getFormattedDurationProperty(voicemailBoxGreeting.durationInMilliseconds/1000));
        } else {
            audioObject.showConversionMessage(true);
        }
        voicemailGreetingPresentationObject.greetingAudioObject(audioObject);

        return voicemailGreetingPresentationObject;
    };

    const _getFormattedDurationProperty = (greetingDurationInSeconds) => {
        let formatter = new TimeFormatterConstructor();
        let formattedTimeDuration = formatter.formatTotalSeconds(greetingDurationInSeconds);
        return formattedTimeDuration;
    };

    const _getCurrentGreetingId = (userId) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                userId: userId
            };

            _greetingSocket.send("getCurrentGreeting", request, (result) => {
                if (result.isSuccessful === false) {
                    promise.reject(new Error("error retrieving greeting"));
                    return;
                }

                if (result.customGreetingId) {
                    promise.resolve(result.customGreetingId);
                    return;
                } else if (result.systemGreetingId) {
                    promise.resolve(result.systemGreetingId);
                    return;
                }

                promise.reject(new Error("unable to determine greeting"));
            });
        });
    };

    const _getVoicemailBoxGreetings = (userId) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                userId: userId
            };

            _greetingSocket.send("listGreetings", request, (result) => {
                const voicemailGreetingsList = result.map(voicemailGreetingResult => {
                    return _buildVoicemailBoxGreetingForDisplay(voicemailGreetingResult);
                });
                promise.resolve(voicemailGreetingsList);
            });
        });
    };


    const _addGreeting = (voicemailBoxId, voicemailBoxGreeting) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const fileHandle = voicemailBoxGreeting.file();
            _customerUpload.uploadFile(fileHandle)
                .fail(promise.reject)
                .done(({uploadId}) => {
                    const createGreetingRequest = {
                        voicemailBoxId : voicemailBoxId,
                        name: voicemailBoxGreeting.name(),
                        uploadId: uploadId,
                    };
                    _greetingSocket.send("createGreeting", createGreetingRequest, (createGreetingResponse) => {
                        if (createGreetingResponse.isSuccessful) {
                            promise.resolve(createGreetingResponse.voicemailGreetingId);
                        } else {
                            promise.reject(new Error("error creating greeting"));
                        }
                    });
                });
        });
    };

    const _updateGreetingPrompt = (voicemailBoxGreeting) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const fileHandle = voicemailBoxGreeting.file();
            _customerUpload.uploadFile(fileHandle)
                .fail(promise.reject)
                .done(({uploadId}) => {
                    const updateRequest = {
                        voicemailBoxGreetingId : voicemailBoxGreeting.voicemailBoxGreetingId,
                        name : voicemailBoxGreeting.name(),
                        uploadId: uploadId,
                    };
                    _greetingSocket.send("updateGreetingPrompt", updateRequest, (response) => {
                        if (response.isSuccessful) {
                            promise.resolve();
                        } else {
                            promise.reject(new Error("error updating greeting"));
                        }
                    });
                });
        });
    };

    const _updateGreetingName = (voicemailBoxGreeting) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const request = {
                voicemailBoxGreetingId: voicemailBoxGreeting.voicemailBoxGreetingId,
                name: voicemailBoxGreeting.name(),
            };
            _greetingSocket.send("updateGreetingName", request, (response) => {
                if (response.isSuccessful) {
                    promise.resolve();
                } else {
                    promise.reject(new Error("error updating greeting"));
                }
            });
        });
    };

    const _processVoicemailBoxGreetingForSave = (voicemailBoxId, voicemailBoxGreeting) => {
        let promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.deferIndefinitely((promise) => {
            if (voicemailBoxGreeting.requiresUpdate === false || voicemailBoxGreeting.isBuiltIn() || voicemailBoxGreeting.isAccount) {
                promise.resolve();
            } else if (voicemailBoxGreeting.isDeleted() === true) {
                _deleteVoicemailBoxGreeting(voicemailBoxId, voicemailBoxGreeting)
                    .fail(promise.reject)
                    .done(promise.resolve);
            } else {
                if (voicemailBoxGreeting.voicemailBoxGreetingId === null) {
                    _addGreeting(voicemailBoxId, voicemailBoxGreeting)
                        .fail(promise.reject)
                        .done(promise.resolve);
                } else {
                    if (voicemailBoxGreeting.file()) {
                        _updateGreetingPrompt(voicemailBoxGreeting)
                            .fail(promise.reject)
                            .done(promise.resolve);
                    } else {
                        _updateGreetingName(voicemailBoxGreeting)
                            .fail(promise.reject)
                            .done(promise.resolve);
                    }
                }
            }
        });
    };

    const _saveVoicemailBoxGreetings = (voicemailBoxId, voicemailBoxGreetings) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            let saveVoicemailGreetingsPromise = new PromiseFactoryConstructor();
            voicemailBoxGreetings.forEach((voicemailBoxGreeting) => {
                saveVoicemailGreetingsPromise.deferIndefinitely((saveDeferredObject) => {
                    _processVoicemailBoxGreetingForSave(voicemailBoxId, voicemailBoxGreeting)
                        .fail(saveDeferredObject.reject)
                        .done((greetingId) => {
                            if (voicemailBoxGreeting.voicemailBoxGreetingId === null && greetingId !== undefined) {
                                voicemailBoxGreeting.voicemailBoxGreetingId = greetingId;
                            }
                            saveDeferredObject.resolve();
                        });
                });
            });
            saveVoicemailGreetingsPromise.wait()
                .fail(promise.reject)
                .done(() => {
                    promise.resolve({
                        status : "success",
                        voicemailGreetings : voicemailBoxGreetings
                    });
                });
        });
    };

    const _deleteVoicemailBoxGreeting = (voicemailBoxId, voicemailBoxGreeting) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                voicemailBoxId : voicemailBoxId,
                voicemailBoxGreetingId : voicemailBoxGreeting.voicemailBoxGreetingId
            };
            _greetingSocket.send("deleteGreeting", request, (result) => {
                if (result.isSuccessful) {
                    promise.resolve();
                } else {
                    promise.reject(new Error("error deleting voicemail box greeting"));
                }
            });
        });
    };

    const _onVoicemailGreetingConverted = (callback) => {
        _greetingSocket.subscribeToEvent("voicemailBoxGreetingConverted", (voicemailBoxGreeting) => {
            let voicemailBoxGreetingPresentationObject = _buildVoicemailBoxGreetingForDisplay(voicemailBoxGreeting);
            callback(voicemailBoxGreetingPresentationObject);
        });
    };

    const _dispose = () => {
        _greetingSocket.disposeOfEvents();
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    return function() {
        const self = this;

        self.dispose = _dispose;
        self.init = _init;
        self.getCurrentGreetingId = _getCurrentGreetingId;
        self.getUserVoicemailBox = _getUserVoicemailBox;
        self.getVoicemailBoxGreetings = _getVoicemailBoxGreetings;
        self.onVoicemailBoxGreetingConverted = _onVoicemailGreetingConverted;
        self.updateUserVoicemailBox = _updateUserVoicemailBox;
        self.saveVoicemailBoxGreetings = _saveVoicemailBoxGreetings;
    };
});
