define('businessServices/authentication/stores/impersonationAuthenticationStore',['common/storage/workSessionDataStorage'], function(workSessionDataStorage) {
    var KEY = 'tresta-impersonation-auth';

    var _isImpersonating = null;
    function receiveAlertLoginSuccessful(loginInfo) {
        if (loginInfo.loginType === "impersonation") {
            _isImpersonating = true;
            var value = {
                token : loginInfo.authenticationToken,
                userId : loginInfo.userId,
                workSessionId : loginInfo.workSessionId,
                accountId : loginInfo.accountId
            };
            saveValue(value);
        } else {
            _isImpersonating = false;
        }
    }

    function receiveAlertLogout() {
        if (_isImpersonating === true) {
            deleteValue();
            _isImpersonating = null;
        }
    }

    function hasValue() {
        return workSessionDataStorage.hasObject(KEY);
    }

    function getValue() {
        return workSessionDataStorage.getObject(KEY);
    }

    function saveValue(value) {
        workSessionDataStorage.saveObject(KEY, value);
    }

    function deleteValue() {
        workSessionDataStorage.deleteObject(KEY);
    }

    return {
        hasValue : hasValue,
        getValue : getValue,
        saveValue : saveValue,
        deleteValue : deleteValue,
        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});
