define('constants/pressOneToAcceptConstants',[], function() {
    const _i18n = require('i18next');

    const _on = "on";
    const _off = "off";

    return {
        on: _on,
        off: _off,
        options: [
            {text: _i18n.t("common:on"), id: _on},
            {text: _i18n.t("common:off"), id: _off}
        ]
    };
});
