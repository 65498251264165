define('presentation/login/viewModels/reactivationActionModalViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/authentication/sessionWorkflowFlags',
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory',
    'plugins/router',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/modal',
    'presentation/loginNotifications/viewModels/reactivationViewModel',
    'presentation/loginNotifications/viewModels/balancePaidViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const ReactivationViewModelConstructor = require('presentation/loginNotifications/viewModels/reactivationViewModel');
        const BalancePaidViewModelConstructor = require('presentation/loginNotifications/viewModels/balancePaidViewModel');

        const _actionModal = new ActionModalViewModelConstructor();
        const _authenticationManager = require('businessServices/authentication/authenticationManager');
        const _router = require('plugins/router');
        const _navigationConfig = require('settings/navigationConfiguration');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');

        let _modalService = null;

        self.showReactivationModal = () => {
            return _promiseFactory.deferIndefinitely((reactivationCompletedPromise) => {
                _actionModal.setContentViewModel(ReactivationViewModelConstructor, [reactivationCompletedPromise]);
                _actionModal.setHeaderText({i18n: 'reactivation:headerTitle'});
                _actionModal.setCancelButtonText({i18n: 'common:signOut'});
                _actionModal.shouldDisplayCancelButton(true);
                _actionModal.shouldDisplayAgentBypassButton(false);
                _actionModal.shouldDisplaySubmitButton(false);
                _actionModal.shouldDisplayDefaultFooterMessage(true);

                if (_sessionAccountInfo.isImpersonating()) {
                    _actionModal.shouldDisplayAgentBypassButton(true);
                    _actionModal.shouldDisplayCancelButton(false);
                }

                _actionModal.showModal()
                    .done((result) => {
                        if (result === "cancel") {
                            _authenticationManager.logout()
                                .done(() => {
                                    _router.navigate(_navigationConfig.loginPageUrl);
                                    reactivationCompletedPromise.resolve();
                                });
                        } else if (result === "agentBypass") {
                            _sessionWorkflowFlags.hasBypassedClosedAccount(true);
                            _sessionWorkflowFlags.hasBypassedPastDueBalance(true);
                            _router.navigate(_navigationConfig.loginLandingPageUrl);
                            reactivationCompletedPromise.resolve();
                        }
                    });
            });
        };

        self.showBalancePaidActionModal = (paymentAmount) => {
            _actionModal.setContentViewModel(BalancePaidViewModelConstructor, [paymentAmount]);
            _actionModal.setHeaderText({i18n: 'reactivation:balancePaid'});
            _actionModal.setCancelButtonText({i18n: 'common:dismiss'});
            _actionModal.shouldDisplayCancelButton(true);
            _actionModal.shouldDisplayAgentBypassButton(false);
            _actionModal.shouldDisplaySubmitButton(false);
            _actionModal.shouldDisplayDefaultFooterMessage(true);

            if (_sessionAccountInfo.isImpersonating()) {
                _actionModal.shouldDisplayAgentBypassButton(true);
                _actionModal.shouldDisplayCancelButton(false);
            }

            _actionModal.showModal()
                .done((result) => {
                    if (result === "cancel") {
                        _authenticationManager.logout()
                            .done(() => {
                                _modalService.closeModal(_actionModal);
                                _router.navigate(_navigationConfig.loginPageUrl);
                            });
                    }
                });
        };

        self.activate = () => {
            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.showReactivationModal()
                .done((reactivationResult) => {
                    _modalService.closeModal(_actionModal);
                    if (reactivationResult && reactivationResult.isAccountReactivated) {
                        _router.navigate(_navigationConfig.loginLandingPageUrl);
                    } else if (reactivationResult && reactivationResult.isBalancePaid) {
                        self.showBalancePaidActionModal(reactivationResult.paymentAmount);
                    }
                });
            return _promiseFactory.wait();
        };
    };
});
