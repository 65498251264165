define('presentation/acceptUserInvite/viewModels/addUserModalViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'presentation/acceptUserInvite/facades/createUserProfileFacade',
    'presentation/acceptUserInvite/viewModels/createUserDevicesViewModel',
    'presentation/acceptUserInvite/viewModels/createUserProfileViewModel',
    'presentation/acceptUserInvite/viewModels/createUserSecurityViewModel',
    'presentation/common/modal',
    'settings/navigationConfiguration',
], function() {
    return function() {
        const self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const CreateUserProfileViewModelConstructor = require('presentation/acceptUserInvite/viewModels/createUserProfileViewModel');
        const CreateUserSecurityViewModelConstructor = require('presentation/acceptUserInvite/viewModels/createUserSecurityViewModel');
        const CreateUserDevicesViewModelConstructor = require('presentation/acceptUserInvite/viewModels/createUserDevicesViewModel');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _router = require('businessServices/router/router');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _i18n = require('i18next');

        let _facade = null;
        let _loginToken = null;
        let _accountId = null;
        let _userId = null;

        self.avatar = ko.observable().extend({observeState: true});
        self.firstName = ko.observable("").extend({observeState: true});
        self.lastName = ko.observable("").extend({observeState: true});
        self.title = ko.observable("").extend({observeState: true});
        self.emailAddress = ko.observable("").extend({observeState: true});
        self.password = ko.observable("").extend({observeState: true});
        self.verificationQuestion = ko.observable("").extend({observeState: true});
        self.verificationAnswer = ko.observable("").extend({observeState: true});
        self.timeZone = ko.observable("").extend({observeState: true});
        self.devicePhoneNumber = ko.observable("").extend({observeState: true});
        self.isFormPopulated = ko.observable(false).extend({observeState: true});

        const _userProfile = {
            avatar: self.avatar,
            firstName: self.firstName,
            lastName: self.lastName,
            title: self.title,
            emailAddress: self.emailAddress,
            password: self.password,
            verificationQuestion: self.verificationQuestion,
            verificationAnswer: self.verificationAnswer,
            timeZone: self.timeZone,
            devicePhoneNumber: self.devicePhoneNumber
        };

        const _buildUserProfileData = () => {
            return {
                userId: _userId,
                firstName: self.firstName().trim(),
                lastName: self.lastName().trim(),
                title: self.title().trim(),
                emailAddress: self.emailAddress().trim(),
                password: self.password(),
                verificationQuestion: self.verificationQuestion(),
                verificationAnswer: self.verificationAnswer().trim(),
                timeZone: self.timeZone(),
                avatar: self.avatar()
            };
        };

        const _getCreateUserSections = () => {
            const createUserProfileViewModel = new CreateUserProfileViewModelConstructor(_userProfile);
            const createUserSecurityViewModel = new CreateUserSecurityViewModelConstructor(_userProfile);
            const createUserDevicesViewModel = new CreateUserDevicesViewModelConstructor(_userProfile);

            return [
                createUserProfileViewModel,
                createUserSecurityViewModel,
                createUserDevicesViewModel
            ];
        };

        self.linkItems = null;
        self.modelStateObserver = null;
        self.createUserSections = _getCreateUserSections();
        self.currentSectionIndex = ko.observable(0);
        self.isCompositionComplete = ko.observable(false);

        self.activeSectionView = ko.pureComputed(() => self.createUserSections[self.currentSectionIndex()]);
        self.isOnFirstSection = ko.pureComputed(() => self.currentSectionIndex() === 0);
        self.isOnSecondSection = ko.pureComputed(() => self.currentSectionIndex() === 1);
        self.isOnLastSection = ko.pureComputed(() => self.currentSectionIndex() === self.createUserSections.length - 1);
        self.isProfilePopulated = ko.pureComputed(() => (self.firstName() + self.lastName() + self.title()) !== "");
        self.isSecurityPopulated = ko.pureComputed(() => (self.password() + self.verificationAnswer()) !== "");
        self.isDevicePopulated = ko.pureComputed(() => self.devicePhoneNumber() !== "");
        self.saveButtonText = ko.pureComputed(() => self.isOnLastSection() ? _i18n.t('acceptUserInvite:done') : _i18n.t('acceptUserInvite:next'));

        self.previousSectionClick = () => {
            if (! self.isOnFirstSection()) {
                self.currentSectionIndex(self.currentSectionIndex() - 1);
            }
        };

        const _login = () => {
            return _promiseFactory.defer((promise) => {
                const authenticationManager = require('businessServices/authentication/authenticationManager');
                authenticationManager.loginWithToken(_loginToken, _accountId, _userId)
                    .fail(promise.reject)
                    .done((credentialsResponse) => {
                        if (credentialsResponse) {
                            _router.navigate(_navigationConfiguration.loginLandingPageUrl);
                            setTimeout(() => {
                                promise.resolve();
                            }, 10000);
                        } else {
                            const loginFailedError = new Error("Login failed for new user");
                            loginFailedError.errorMessage = credentialsResponse.errorMessage;
                            promise.reject(loginFailedError);
                        }
                    });
            });
        };

        const _isSectionPopulated = () => {
            return (self.isOnFirstSection() && self.isProfilePopulated()) ||
                (self.isOnSecondSection() && self.isSecurityPopulated()) ||
                (self.isOnLastSection() && self.isDevicePopulated());
        };

        const _saveUserProfile = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.saveUserProfile(_buildUserProfileData(), self.linkItems)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        if (result.status === "success") {
                            _loginToken = result.token;
                            _accountId = result.accountId;
                            deferredObject.resolve("success");
                        } else {
                            deferredObject.reject();
                        }
                    });
            });
        };

        self.nextSectionClick = () => {
            return _promiseFactory.defer((promise) => {
                const currentSection = self.activeSectionView();
                if (self.isOnLastSection()) {
                    _saveUserProfile()
                        .fail(promise.reject)
                        .done(() => {
                            currentSection.save()
                                .fail(promise.reject)
                                .done((isSaveSuccess) => {
                                    if (isSaveSuccess !== true) {
                                        promise.resolve(false);
                                    } else {
                                        _login()
                                            .fail(promise.reject)
                                            .done(() => {
                                                promise.resolve();
                                            });
                                    }
                                });
                        });
                } else {
                    currentSection.save()
                        .fail(promise.reject)
                        .done((isSaveSuccess) => {
                            if (isSaveSuccess !== true) {
                                promise.resolve(false);
                            } else {
                                self.isFormPopulated(false);
                                self.currentSectionIndex(self.currentSectionIndex() + 1);
                                promise.resolve(true);
                            }
                        });
                }
            });
        };
        
        self.setLinkItems = (linkItems) => {
            self.linkItems = linkItems;
        };

        self.navigateToIndex = () => {
            _router.navigate(_navigationConfiguration.index);
        };

        self.compositionComplete = () => {
            self.modelStateObserver.commitData();
            self.isCompositionComplete(true);
            self.isFormPopulated(_isSectionPopulated());
        };

        self.childCompositionComplete = () => {
            if (_isSectionPopulated() === false) {
                self.modelStateObserver.commitData();
            }

            if (self.isCompositionComplete()) {
                self.isFormPopulated(_isSectionPopulated());
            }
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/acceptUserInvite/facades/createUserProfileFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            _facade.getUserProfile(self.linkItems)
                .fail((error) => {
                    throw error;
                })
                .done((userProfile) => {
                    _userId = userProfile.userId;
                    self.firstName(userProfile.firstName);
                    self.lastName(userProfile.lastName);
                    self.title(userProfile.title);
                    self.emailAddress(userProfile.emailAddress);
                    self.verificationQuestion(userProfile.verificationQuestion);
                    self.verificationAnswer(userProfile.verificationAnswer);
                    self.timeZone(userProfile.timeZone);
                    self.avatar(userProfile.avatar);

                    self.modelStateObserver = new ModelStateObserverConstructor(self);
                });

            return _promiseFactory.wait();
        };
    };
});

