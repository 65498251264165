define('presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel',[
        'presentation/common/routeToModal/viewModels/routeToModalViewModel',
        'presentation/common/routeToModal/strategies/autoAttendantSubMenuStrategy',
        'presentation/common/modal',
        'constants/routingRuleConstants',
        'common/collections/collectionSorter',
        'constants/autoAttendantVoiceRouteEnumerations',
        'constants/inlinePromptDefaultOptionEnumerations',
        'businessServices/state/modelStateObserver',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/deleteSubMenuActionViewModel',
        'presentation/settings/autoAttendant/presentationObjects/subMenuPresentationObject',
        'presentation/settings/autoAttendant/presentationObjects/subMenuItemPresentationObject',
        'presentation/settings/autoAttendant/validators/autoAttendantSubMenuValidator',
        'presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel',
        'presentation/settings/autoAttendant/viewModels/autoAttendantRoutingRulesViewModel',
        'presentation/settings/prompts/viewModels/addPromptsViewModel',
        'presentation/common/voicePrompt/voicePromptAudioFactory',
        'common/storage/commonState'
], function() {

    return function(autoAttendantId, availablePrompts, routingRule, promiseFactory, facade, hasAutoAttendantChanged, subMenuItem, autoAttendants, availableObjects) {
        const self = this;

        const AutoAttendantSubMenuSaveStrategyConstructor = require('presentation/common/routeToModal/strategies/autoAttendantSubMenuStrategy');
        const DeleteSubMenuActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteSubMenuActionViewModel');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const RouteToModalViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToModalViewModel');
        const RoutingRulesViewModelConstructor = require('presentation/settings/autoAttendant/viewModels/autoAttendantRoutingRulesViewModel');
        const SubMenuConnectorConstructor = require('presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel');
        const SubMenuPresentationObjectConstructor = require('presentation/settings/autoAttendant/presentationObjects/subMenuPresentationObject');
        const SubMenuItemPresentationObjectConstructor = require('presentation/settings/autoAttendant/presentationObjects/subMenuItemPresentationObject');
        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        const ValidatorConstructor = require('presentation/settings/autoAttendant/validators/autoAttendantSubMenuValidator');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _inlinePromptEnumerations = require('constants/inlinePromptDefaultOptionEnumerations');
        const _routingRuleConstants = require('constants/routingRuleConstants');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        const _facade = facade;
        const _promiseFactory = promiseFactory;

        let _selectedVoicePromptChangedSubscription = null;
        let _modalService = null;
        let _voicePromptAudioFactory = null;
        let _validator = null;

        const DELETE_RESPONSE = "yes";

        self.isSelectPrompt = ko.pureComputed(() => self.selectedPrompt().id === _inlinePromptEnumerations.SELECT_PROMPT.id);
        self.isCurrentPrompt = ko.pureComputed(() => !self.isSelectPrompt());


        const _buildDeleteModal = function(subMenuItem) {
            let constructorParams = [subMenuItem];

            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteSubMenuActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: 'removeHeader'})
                .setSubmitButtonText({i18n: 'remove'});
        };

        const _promptSelectionChanged = () => {
            const selectedPrompt = self.selectedPrompt();
            switch (selectedPrompt.id) {
                case _inlinePromptEnumerations.NONE_PROMPT.id:
                    self.selectedPromptAudio(_inlinePromptEnumerations.NONE_PROMPT);
                    self.voicePromptId(null);
                    break;
                case _inlinePromptEnumerations.SELECT_PROMPT.id:
                    self.selectedPromptAudio(_inlinePromptEnumerations.SELECT_PROMPT);
                    self.voicePromptId(null);
                    break;
                case null:
                    break;
                default:
                    const promptAudioObject = _commonState.get(selectedPrompt.id).status() === "system" ?
                        _voicePromptAudioFactory.getSystemVoicePrompt(selectedPrompt.id) :
                        _voicePromptAudioFactory.getVoicePrompt(selectedPrompt.id);

                    promptAudioObject.duration = ko.pureComputed(() => selectedPrompt.durationInMilliseconds() / 1000);

                    self.selectedPromptAudio(promptAudioObject);
                    self.voicePromptId(selectedPrompt.id);
            }

            if (subMenuItem !== undefined) {
                subMenuItem.voicePromptId(self.voicePromptId());
                subMenuItem.isSystemPrompt(selectedPrompt.isSystemPrompt);
            }
        };

        const _showCallFlowControls = (connector) => {
            if (connector()) {
                connector().callFlow.map((c) => {
                    return c.showControls(true);
                });
            }
        };

        const _showDeletePrompt = () => {
            _buildDeleteModal(subMenuItem);
            self.actionModal.showModal()
                .done((result) => {

                    if (self.subMenuItems){
                        self.subMenuItems().map((s) => {
                            s.showControls(true);
                            return _showCallFlowControls(s.connector);
                        });
                    }

                    if (result === DELETE_RESPONSE) {
                        hasAutoAttendantChanged(true);
                        subMenuItem.optionName(null);
                        subMenuItem.routeId("");
                        subMenuItem.type(null);
                        subMenuItem.typeId(null);
                        subMenuItem.connector(null);
                        subMenuItem.isVoicePromptInvalid(false);
                        subMenuItem.isVoicePromptEnabled(false);
                        subMenuItem.routingRules(null);
                    }
                })
                .fail((error) => {
                    throw error;
                });

        };

        const SUB_MENU_KEYS = [
            {key: '1', letters: ''},
            {key: '2', letters: 'abc'},
            {key: '3', letters: 'def'},
            {key: '4', letters: 'ghi'},
            {key: '5', letters: 'jkl'},
            {key: '6', letters: 'mno'},
            {key: '7', letters: 'pqrs'},
            {key: '8', letters: 'tuv'},
            {key: '9', letters: 'wxyz'},
            {key: '0', letters: ''},
            {key: '*', letters: ''},
        ];

        const _createAutoAttendantSubMenu = () => {
            const subMenuPresentationObject = new SubMenuPresentationObjectConstructor();
            subMenuPresentationObject.subMenuItems = SUB_MENU_KEYS.map(({key, letters}) => {
                const subMenuItemPresentationObject = new SubMenuItemPresentationObjectConstructor();
                subMenuItemPresentationObject.key = key;
                subMenuItemPresentationObject.letters = letters;
                return subMenuItemPresentationObject;
            });
            if (routingRule) {
                if (routingRule.routeToSubMenu.accountVoicePromptId !== null) {
                    self.selectedPrompt(_commonState.get(routingRule.routeToSubMenu.accountVoicePromptId));
                } else if  (routingRule.routeToSubMenu.systemAudioClipId !== null) {
                    self.selectedPrompt(_commonState.get(routingRule.routeToSubMenu.systemAudioClipId));
                } else {
                    self.selectedPrompt(_inlinePromptEnumerations.SELECT_PROMPT);
                }

                subMenuPresentationObject.subMenuItems.forEach(subMenuItemPresentationObject => {
                    const exitConditionMatch = routingRule.routingRuleExitConditions.find(
                        exitCondition => exitCondition.value === subMenuItemPresentationObject.key
                    );

                    if (exitConditionMatch) {
                        subMenuItemPresentationObject.optionName(exitConditionMatch.name);
                        switch (exitConditionMatch.nextRoutingRule.routingType) {
                            case _routingRuleConstants.subMenu:
                                const routeToSubMenu = exitConditionMatch.nextRoutingRule.routeToSubMenu;
                                let subMenuVoicePromptId = null;
                                if (routeToSubMenu.accountVoicePromptId) {
                                    subMenuVoicePromptId = routeToSubMenu.accountVoicePromptId;
                                    subMenuItemPresentationObject.isSystemPrompt(false);
                                } else if (routeToSubMenu.systemAudioClipId) {
                                    subMenuVoicePromptId = routeToSubMenu.systemAudioClipId;
                                    subMenuItemPresentationObject.isSystemPrompt(true);
                                }
                                if (subMenuVoicePromptId) {
                                    subMenuItemPresentationObject.showVoicePromptOption(true);
                                    subMenuItemPresentationObject.isVoicePromptEnabled(true);
                                    subMenuItemPresentationObject.isVoicePromptInvalid(false);
                                    subMenuItemPresentationObject.voicePromptId(subMenuVoicePromptId);
                                }
                                subMenuItemPresentationObject.routeId("subMenuId");
                                subMenuItemPresentationObject.type(_voiceRouteEnumerations.subMenu);
                                subMenuItemPresentationObject.routingRules(exitConditionMatch.nextRoutingRule);

                                const subMenuConnection = new SubMenuConnectorConstructor(self.autoAttendantId, availablePrompts, exitConditionMatch.nextRoutingRule, _promiseFactory, self.saveStorageFacade, hasAutoAttendantChanged, subMenuItemPresentationObject, self.autoAttendants, availableObjects);
                                subMenuConnection.activate();
                                subMenuItemPresentationObject.connector(subMenuConnection);
                                break;
                            default:
                                let nextRoutingRule = exitConditionMatch.nextRoutingRule;
                                subMenuItemPresentationObject.routeId("routingRuleId");
                                subMenuItemPresentationObject.type(_voiceRouteEnumerations.routingRule);
                                subMenuItemPresentationObject.routingRules(nextRoutingRule);

                                const routingRuleConnection = new RoutingRulesViewModelConstructor(self.autoAttendantId, hasAutoAttendantChanged, _promiseFactory, self.saveStorageFacade, subMenuItemPresentationObject, self.autoAttendants, availableObjects);
                                routingRuleConnection.activate();
                                subMenuItemPresentationObject.connector(routingRuleConnection);
                                break;
                        }
                    }
                });
            }

            self.subMenuItems(subMenuPresentationObject.subMenuItems);
        };

        self.autoAttendantId = autoAttendantId;
        self.autoAttendants = null;
        self.availableObjects = ko.observable(availableObjects);
        self.availablePrompts = ko.pureComputed(() => {
            const availablePrompts = _commonState.prompts().concat([]);
            const sorter = new SorterConstructor();
            sorter.sort(availablePrompts, "name", true);
            return availablePrompts;
        });
        self.selectedPrompt = ko.observable(_inlinePromptEnumerations.SELECT_PROMPT);
        self.selectedPromptAudio = ko.observable(_inlinePromptEnumerations.SELECT_PROMPT);
        self.routeSearchData = "";
        self.subMenuId = ko.observable();
        self.subMenuItems = ko.observableArray([]).extend({observeState: true});
        self.isInitialVoiceRoute = ko.observable(false);
        self.isSubMenuExpanded = ko.observable(true).toggleable();
        self.showExpandedSubmenu = ko.pureComputed(() => this.isSubMenuExpanded());
        self.voicePromptId = ko.observable("");
        self.connector = ko.observable(null);
        self.modelStateObserver = null;
        self.actionModal = null;
        self.deactivatedMessage = ko.observable(_i18n.t('addAutoAttendant:deactivatedAudioMessage'));


        self.deleteSubMenu = () => {
            _showDeletePrompt();
        };

        self.launchEditRouteToModal = () => {
            const saveStrategy = new AutoAttendantSubMenuSaveStrategyConstructor();
            saveStrategy.init();

            let routingRules = subMenuItem.routingRules();
            if (!routingRules) {
                routingRules = {
                    routingType: _routingRuleConstants.subMenu,
                    routeToSubMenu: "subMenuId",
                    routingRuleExitConditions: []
                };
            }

            const modal = new RouteToModalViewModelConstructor(_voiceRouteEnumerations.routingRule, routingRules);
            modal.isOptionNameEnabled = true;
            modal.optionName(subMenuItem.optionName());
            modal.addMode = false;
            modal.modalTitle(_i18n.t('addAutoAttendant:menuOptionEdit'));
            modal.routeTitle(_i18n.t('routeTo:whenOptionSelected'));
            modal.routeToStrategy = saveStrategy;
            modal.routeToStrategy.saveStorageFacade = _facade;
            modal.routeToStrategy.subMenuItemPresentationObject = subMenuItem;
            modal.routeToStrategy.hasAutoAttendantChanged = hasAutoAttendantChanged;
            modal.routeToStrategy.availablePrompts = availablePrompts;
            modal.routeToStrategy.availableObjects = availableObjects;

            _modalService.showModal(modal);
        };

        self.launchRouteToModal = (subMenuItemPresentationObject) => {
            if (subMenuItemPresentationObject.showControls() === false) {
                return;
            }
            
            let routeToData = {
                promptId: "+ New Audio File",
                routingRuleExitConditions: []
            };
            let modal = new RouteToModalViewModelConstructor(_voiceRouteEnumerations.prompt, routeToData);
            const saveStrategy = new AutoAttendantSubMenuSaveStrategyConstructor();
            saveStrategy.init();
            modal.isOptionNameEnabled = true;
            modal.modalTitle(_i18n.t('addAutoAttendant:menuOptionAdd'));
            modal.routeTitle(_i18n.t('routeTo:whenOptionSelected'));
            modal.routeToStrategy = saveStrategy;
            modal.routeToStrategy.autoAttendantId = autoAttendantId;
            modal.routeToStrategy.autoAttendants = self.autoAttendants;
            modal.routeToStrategy.saveStorageFacade = _facade;
            modal.routeToStrategy.subMenuItemPresentationObject = subMenuItemPresentationObject;
            modal.routeToStrategy.hasAutoAttendantChanged = hasAutoAttendantChanged;
            modal.routeToStrategy.availablePrompts = availablePrompts;
            modal.routeToStrategy.availableObjects = availableObjects;

            _modalService.showModal(modal);
        };

        self.forceValidation = () => {
            _validator.validate();
        };

        self.detached = () => {
            _selectedVoicePromptChangedSubscription.dispose();
        };

        self.activate = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            _validator = new ValidatorConstructor();
            _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();

            self.availablePrompts = availablePrompts;

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self);

            return _promiseFactory.defer(function(deferredObject) {
                self.modelStateObserver = new ModelStateObserverConstructor(self);

                _selectedVoicePromptChangedSubscription = self.selectedPrompt.subscribe(_promptSelectionChanged);

                self.autoAttendants = _commonState.autoAttendants();
                _createAutoAttendantSubMenu();
                self.modelStateObserver.commitData();
                deferredObject.resolve();
            });
        };
    };
});

