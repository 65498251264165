define('presentation/common/voicemail/validators/addVoicemailGreetingValidator',['common/promises/promiseFactory',
        'presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _viewModel = null;
        let _commonValidator = null;

        const GREETING_FILE_MIN_LENGTH = 50; // 50 bytes, which is usually just header information
        const GREETING_FILE_MAX_LENGTH = 4194304; // 4Mb

        self.registerViewModel = (viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("name")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addVoicemailGreeting:greetingNameRequired')
                .addValidationI18n(_validateGreetingNameUnique, 'addVoicemailGreeting:greetingNameNotUnique');
            validationRules.field("greetingFile")
                .addValidationI18n(_validateGreetingFileType, 'addVoicemailGreeting:greetingAudioUploadTypeInvalid')
                .addValidationI18n(_validateGreetingFileSizeTooSmall, 'addVoicemailGreeting:greetingAudioUploadTypeInvalid')
                .addValidationI18n(_validateGreetingFileSizeTooBig, 'addVoicemailGreeting:greetingAudioUploadSizeExceedsMax')
                .addValidationI18n(_validateGreetingUploadRequired, 'addVoicemailGreeting:greetingAudioUploadRequired')
                .addValidationI18n(_validateGreetingRecordingRequired, 'addVoicemailGreeting:greetingAudioRecordingRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.resetValidation = () => {
            _viewModel.name.resetValidation();
            _viewModel.greetingFile.resetValidation();
        };

        const _validateGreetingNameUnique = (notificationName) => {
            return _promiseFactory.defer((deferredResult) => {
                if (notificationName) {
                    const trimmedGreetingName = notificationName.trim();
                    if (trimmedGreetingName.length !== 0) {
                        const voicemailGreetingId = _viewModel.greetingId;
                        const isAccount = _viewModel.isAccount;
                        _viewModel.isNameUnique(voicemailGreetingId, trimmedGreetingName, isAccount)
                            .done(deferredResult.resolve)
                            .fail((error) => {
                                error.voicemailGreetingId = voicemailGreetingId;
                                error.notificationName = notificationName;
                                deferredResult.reject(error);
                            });
                    } else {
                        deferredResult.resolve(true);
                    }
                } else {
                    deferredResult.resolve(true);
                }
            });
        };

        const _validateGreetingUploadRequired = (file) => {
            const method = _viewModel.selectedMethod();
            if (method === "upload") {
                const greetingId = _viewModel.greetingId;
                if (((file === "") || file === undefined) && (greetingId === null)) {
                    return false;
                }
            }
            return true;
        };

        const _validateGreetingRecordingRequired = (file) => {
            const method = _viewModel.selectedMethod();
            if (method === "record") {
                const greetingId = _viewModel.greetingId;
                if (((file === "") || file === undefined) && (greetingId === null)) {
                    return false;
                }
            }
            return true;
        };

        const _validateGreetingFileType = (file) => {
            const method = _viewModel.selectedMethod();
            if (method === "upload") {
                if (file) {
                    if (file.type.match('audio.*')) {
                        return true;
                    }
                    else if (file.type === "application/wav") {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        const _validateGreetingFileSizeTooSmall = (file) => {
            const method = _viewModel.selectedMethod();
            if (method === "upload") {
                if (file) {
                    if (file.size < GREETING_FILE_MIN_LENGTH) {
                        return false;
                    }
                }
                return true;
            } else {
                return true;
            }
        };

        const _validateGreetingFileSizeTooBig = (file)=> {
            const method = _viewModel.selectedMethod();
            if (method === "upload") {
                if (file) {
                    if (file.size > GREETING_FILE_MAX_LENGTH) {
                        return false;
                    }
                }
                return true;
            } else {
                return true;
            }
        };
    };
});

