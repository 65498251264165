define('presentation/common/threeStateSaveButton/viewModels/threeStateSaveButtonViewModel',[
    'common/promises/promiseFactory'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _settings = null;
        /* settings has the following properties:
         * onClickAction            [required]
         * onDisabledClick          [optional - defaulted to ()=>{}]
         * modelStateObserver       [required]
         * customId                 [optional - defaulted to '']
         * buttonTextInitial        [optional - defaulted to 'Save']
         * buttonTextDataChanged    [optional - defaulted to 'Save']
         * buttonTextAfterAction    [optional - defaulted to 'Save']
         * forceDisabled            [optional - default is false]
         */
        let _modelStateObserver = null;

        self.buttonTextInitial = ko.observable("Save");
        self.buttonTextDataChanged = ko.observable("Save");
        self.buttonTextAfterAction = ko.observable("Save");

        self.onButtonClick = null;
        self.forceDisabled = ko.observable(false);
        self.isDirty = ko.observable(false);
        self.isDisabled = ko.observable(false);
        self.customId = "";

        self.onClick = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                if (self.isDisabled() === false) {
                    _promiseFactory.convertValueToPromise(self.onButtonClick())
                        .fail(promise.reject)
                        .done(promise.resolve);
                    return;
                }

                _promiseFactory.convertValueToPromise(self.onDisabledClick())
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        self.onDisabledClick = () => {
            return _promiseFactory.defer((disabledClickPromise) => {
                disabledClickPromise.resolve();
            });
        };

        self.buttonText = ko.pureComputed(() => {
            if (self.isDirty()) {
                return self.buttonTextDataChanged();
            } else if (_modelStateObserver && _modelStateObserver.hasSaveBeenCalled === true) {
                return self.buttonTextAfterAction();
            } else {
               return self.buttonTextInitial();
            }
        });

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.onClickAction !== undefined) {
                if (typeof _settings.onClickAction === "function") {
                    self.onButtonClick = _settings.onClickAction;
                } else {
                    throw new Error("onClickAction is not a function");
                }
            } else {
                throw new Error("onClickAction is required");
            }

            if ((_settings.onDisabledClick !== undefined) && (typeof _settings.onDisabledClick === "function")) {
                self.onDisabledClick = _settings.onDisabledClick;
            }

            if (_settings.modelStateObserver !== undefined) {
                _modelStateObserver = _settings.modelStateObserver;
                self.isDirty = _modelStateObserver.isDirty;
                _modelStateObserver.isDirty.subscribe((isDirty) => {
                    self.isDirty(isDirty);
                });
            } else {
                throw new Error("modelStateObserver is required");
            }

            if (_settings.customId) {
                self.customId = _settings.customId;
            }

            if (_settings.buttonTextInitial) {
                if (ko.isObservable(_settings.buttonTextInitial)) {
                    self.buttonTextInitial = _settings.buttonTextInitial;
                } else {
                    self.buttonTextInitial(_settings.buttonTextInitial);
                }
            }

            if (_settings.buttonTextDataChanged) {
                if (ko.isObservable(_settings.buttonTextDataChanged)) {
                    self.buttonTextDataChanged = _settings.buttonTextDataChanged;
                } else {
                    self.buttonTextDataChanged(_settings.buttonTextDataChanged);
                }
            }

            if (_settings.buttonTextAfterAction) {
                if (ko.isObservable(_settings.buttonTextAfterAction)) {
                    self.buttonTextAfterAction = _settings.buttonTextAfterAction;
                } else {
                    self.buttonTextAfterAction(_settings.buttonTextAfterAction);
                }
            }

            if (_settings.forceDisabled) {
                self.forceDisabled = _settings.forceDisabled;
            }

            self.isDisabled = ko.computed(() => {
                if (self.forceDisabled()) {
                    return true;
                }
                return self.isDirty() === false;
            });

        };
    };
});

