define('presentation/settings/billing/makeAPayment/viewModels/makeAPaymentViewModel',[
    'businessServices/converters/currencyFormatter',
    'businessServices/events/eventManager',
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/creditCardResponseConstants',
    'moment',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/makeAPaymentModalViewModel',
    'presentation/common/modal',
    'presentation/settings/billing/paymentMethod/viewModels/addPaymentMethodViewModel',
    'presentation/common/actionModal/viewModels/paymentConfirmationViewModel',
    'presentation/settings/billing/makeAPayment/facades/makeAPaymentFacade',
    ], function(){
        return function() {
            const self = this;
            const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const MakeAPaymentModalConstructor = require('presentation/common/actionModal/viewModels/makeAPaymentModalViewModel');
            const PaymentConfirmationViewModelConstructor = require('presentation/common/actionModal/viewModels/paymentConfirmationViewModel');
            const _creditCardResponseConstants = require('constants/creditCardResponseConstants');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _currencyFormatter = new CurrencyFormatterConstructor();

            let _facade = null;
            let _eventManager = null;
            let ActionModalViewModelConstructor = null;
            const PAYMENT_AMOUNT_REGEX = /\$([0-9]*\.[0-9][0-9])/;
            const _i18n = require('i18next');
            const CURRENT_BALANCE = "current";
            const PAST_DUE_BALANCE = "past";
            const OTHER_AMOUNT = 'other';

            const _getCustomerBalanceData = () => {
                return _promiseFactory.defer((deferredObject) => {
                    _facade.getCustomerBalanceData()
                        .fail(deferredObject.reject)
                        .done((balanceData) => {
                            self.currentBalance(balanceData.currentBalance);
                            self.pastDueBalance(balanceData.pastDueBalance);
                            deferredObject.resolve();
                        });
                });
            };

            const _getPaymentMethods = () => {
                return _promiseFactory.defer((deferredObject) => {
                    _facade.getAllPaymentMethods()
                        .fail(deferredObject.reject)
                        .done((paymentMethods) => {
                            const validPaymentMethods = paymentMethods.filter((paymentMethod) => {
                                return !paymentMethod.isExpired();
                            });
                            self.paymentMethods(validPaymentMethods);
                            _selectDefaultPaymentMethod();
                            deferredObject.resolve();
                        });
                });
            };

            const _getIsInFreeTrial = () => {
                return _promiseFactory.defer((deferredObject) => {
                    _facade.isInFreeTrial()
                        .fail(deferredObject.reject)
                        .done((isInFreeTrial) => {
                            self.isInFreeTrial(isInFreeTrial);
                            deferredObject.resolve();
                        });
                });
            };

            const _getSelectedPaymentAmount = () => {
                const _isCurrentBalanceSelected = self.selectedRadioButton() === CURRENT_BALANCE;
                const _isPastDueBalanceSelected = self.selectedRadioButton() === PAST_DUE_BALANCE;
                const _isOtherAmountSelected = self.selectedRadioButton() === OTHER_AMOUNT;
                const _getDollarAmountFromSelectedRadioButton = () => {
                    if (_isCurrentBalanceSelected) {
                        return self.currentBalance();
                    }
                    if (_isPastDueBalanceSelected) {
                        return self.pastDueBalance();
                    }
                    if (_isOtherAmountSelected) {
                        return self.otherAmount();
                    }
                    return null;
                };

                if (_isCurrentBalanceSelected || _isPastDueBalanceSelected) {
                    return _currencyFormatter.formatUSDForDisplay(_getDollarAmountFromSelectedRadioButton());
                }
                if (_isOtherAmountSelected) {
                    return self.otherAmount();
                }
            };

            const _buildMakeAPaymentModalContent = () => {
                let constructorParams = [self.selectedPaymentMethod(), _getSelectedPaymentAmount()];

                self.actionModal
                    .clearModal()
                    .shouldDisplaySubmitButton(true)
                    .setContentViewModel(MakeAPaymentModalConstructor, constructorParams)
                    .setHeaderText({i18n: {key: 'oneTimePaymentHeader'}})
                    .setSubmitButtonText({i18n: 'pay'});
            };

            const _selectDefaultPaymentMethod = () => {
                let selectedPaymentMethod = self.paymentMethods()[0];
                const defaultPaymentMethod = self.paymentMethods().find((paymentMethod) => paymentMethod.isDefault());
                if (defaultPaymentMethod) {
                    selectedPaymentMethod = defaultPaymentMethod;
                }
                self.selectedPaymentMethod(selectedPaymentMethod);
            };

            const _buildPaymentSuccessModal = () => {
                let constructorParams = [_getSelectedPaymentAmount()];

                self.actionModal
                    .setContentViewModel(PaymentConfirmationViewModelConstructor, constructorParams)
                    .shouldDisplaySubmitButton(false)
                    .setCancelButtonText({i18n: {key: 'close'}})
                    .setHeaderText({i18n: {key: 'oneTimePaymentHeader'}});
            };

            const _showPaymentConfirmation = () => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    _buildPaymentSuccessModal();
                    self.actionModal.showModal()
                        .then(deferredObject.resolve);
                });
            };

            const _handleSelectedRadioButtonChanged = () => {
                self.modelStateObserver.navigateWithoutWarning = false;

                const _isCurrentBalanceSelected = self.selectedRadioButton() === CURRENT_BALANCE;
                const _isPastDueBalanceSelected = self.selectedRadioButton() === PAST_DUE_BALANCE;
                const _isOtherAmountSelected = self.selectedRadioButton() === OTHER_AMOUNT;

                const _hasPastDueBalance = _currencyFormatter.removeFormattingFromUSD(self.pastDueBalance()) > 0;

                if (_isCurrentBalanceSelected) {
                    self.shouldDisableButton(false);
                    self.shouldDisplayOtherAmountInput(false);
                } else if (_isPastDueBalanceSelected && !_hasPastDueBalance) {
                    self.shouldDisableButton(true);
                    self.shouldDisplayOtherAmountInput(false);
                } else if (_isPastDueBalanceSelected) {
                    self.shouldDisableButton(false);
                    self.shouldDisplayOtherAmountInput(false);
                } else if (_isOtherAmountSelected) {
                    self.shouldDisplayOtherAmountInput(true);
                    let _otherAmountCleaned;
                    if (self.otherAmount()) {
                        _otherAmountCleaned = parseInt(self.otherAmount().match(PAYMENT_AMOUNT_REGEX)[1]);
                    }
                    if (!_otherAmountCleaned) {
                        self.shouldDisableButton(true);
                    }
                } else {
                    self.shouldDisableButton(false);
                    self.shouldDisplayOtherAmountInput(false);
                }
            };

            const _handleOtherAmountChanged = () => {
                self.modelStateObserver.navigateWithoutWarning = false;

                if (self.otherAmount() && self.currentBalance()) {
                    const otherAmount = ko.observable(_currencyFormatter.removeFormattingFromUSD(self.otherAmount()));
                    const currentBalance = ko.observable(_currencyFormatter.removeFormattingFromUSD(self.currentBalance()));

                    if (otherAmount() > currentBalance()) {
                        self.otherAmount(currentBalance());
                        self.amountExceedsCurrentBalance(true);
                    } else if (otherAmount() <= 0) {
                        self.shouldDisableButton(true);
                    } else if (otherAmount() > 0) {
                        self.shouldDisableButton(false);
                        self.amountExceedsCurrentBalance(false);
                    }
                }
            };

            self.amountExceedsCurrentBalance = ko.observable(false);
            self.shouldDisableButton = ko.observable(false);
            self.currentBalance = ko.observable('');
            self.pastDueBalance = ko.observable('');
            self.otherAmount = ko.observable('');
            self.otherAmount.subscribe(_handleOtherAmountChanged);
            self.selectedPaymentMethod = ko.observable();
            self.paymentMethods = ko.observableArray([]);
            self.shouldDisplayOtherAmountInput = ko.observable(false);
            self.headerI18n = _i18n.t('makeAPayment:title');
            self.headerButtonText = _i18n.t('makeAPayment:headerButtonText');
            self.modelStateObserver = null;
            self.isInFreeTrial = ko.observable("");
            self.hasCurrentBalance = ko.pureComputed(() => {
                return _currencyFormatter.removeFormattingFromUSD(self.currentBalance()) > 0;
            });
            self.hasPastDueBalance = ko.pureComputed(() => {
                return _currencyFormatter.removeFormattingFromUSD(self.pastDueBalance()) > 0;
            });
            self.isInFreeTrialOrAccountHasZeroBalance = ko.computed(() => {
                return !self.hasCurrentBalance() || self.isInFreeTrial();
            });

            self.displayPaymentModal = () => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    if (!_getSelectedPaymentAmount()) {
                        deferredObject.resolve();
                    } else {
                        _buildMakeAPaymentModalContent();
                        self.actionModal.showModal()
                            .fail(deferredObject.reject)
                            .done((paymentStatus) => {
                                if (paymentStatus === _creditCardResponseConstants.success) {
                                    const billingPromiseFactory = new PromiseFactoryConstructor();
                                    billingPromiseFactory.deferIndefinitely((billingPromise) => {
                                        _getCustomerBalanceData()
                                            .fail(billingPromise.reject)
                                            .done(billingPromise.resolve);
                                    });
                                    billingPromiseFactory.deferIndefinitely((billingPromise) => {
                                        _showPaymentConfirmation()
                                            .fail(billingPromise.reject)
                                            .done(billingPromise.resolve);
                                    });
                                    billingPromiseFactory.wait()
                                        .done(() => {
                                            self.otherAmount('');
                                            self.modelStateObserver.navigateWithoutWarning = true;
                                        });
                                } else {
                                    deferredObject.resolve();
                                }
                            });
                        deferredObject.resolve();
                    }
                });
            };
            self.shouldDisplayPaymentForm = ko.computed(() => {
                return !self.isInFreeTrial() && self.hasCurrentBalance() && self.paymentMethods().length > 0;
            });

            self.warningMessageNoPaymentMethod = _i18n.t('makeAPayment:validationNoPaymentMethod_part1');
            self.warningMessageNoPaymentMethodLinkText = _i18n.t('makeAPayment:validationNoPaymentMethod_part2');
            self.paymentMethodUrl = _navigationConfiguration.routesById.paymentMethods.url;
            self.showContentViewModel = ko.observable(false);
            self.actionModal = null;
            self.showValidation = false;

            self.warningBoxLink = ko.computed(() => {
                return self.hasCurrentBalance() && self.paymentMethods().length === 0 ?
                    self.paymentMethodUrl :
                    null;
            });
            self.warningBoxLinkText = ko.computed(() => {
                return self.hasCurrentBalance() && self.paymentMethods().length === 0 ?
                    self.warningMessageNoPaymentMethodLinkText :
                    null;
            });
            self.warningBoxMessage = ko.pureComputed(() => {
                if (self.isInFreeTrial()) {
                    return _i18n.t('makeAPayment:validationIsInFreeTrial');
                }
                if (!self.hasCurrentBalance() && !self.isInFreeTrial()) {
                    return _i18n.t('makeAPayment:validationNoBalance');
                }
                if (self.paymentMethods().length === 0) {
                    let message = _i18n.t('makeAPayment:validationNoPaymentMethod_part1');
                    message += '<a class="warning-box__message-link" href="' + self.paymentMethodUrl + '">';
                    message += _i18n.t('makeAPayment:validationNoPaymentMethod_part2') + '</a>';
                    return message;
                }
                return '';
            });
            self.shouldDisplayWarning = ko.computed(() => {
                const noPaymentMethods = self.paymentMethods().length === 0;
                return self.isInFreeTrialOrAccountHasZeroBalance() || noPaymentMethods;
            });
            self.selectedRadioButton = ko.observable('').extend({observeState: true});
            self.selectedRadioButton.subscribe(_handleSelectedRadioButtonChanged);
            self.radioButtonOptions = [
                {
                    text: ko.computed(() => _i18n.t('makeAPayment:currentBalance') + self.currentBalance()),
                    value: CURRENT_BALANCE,
                },
                {
                    text: ko.computed(() => _i18n.t('makeAPayment:pastDueBalance') + self.pastDueBalance()),
                    value: PAST_DUE_BALANCE,
                },
                {
                    text: _i18n.t('makeAPayment:otherAmount'),
                    value: OTHER_AMOUNT,
                }
            ];

            self.activate = () => {
                _eventManager = require('businessServices/events/eventManager');

                ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
                self.actionModal = new ActionModalViewModelConstructor();

                const MakeAPaymentFacadeConstructor = require('presentation/settings/billing/makeAPayment/facades/makeAPaymentFacade');
                _facade = new MakeAPaymentFacadeConstructor();
                _facade.init(_promiseFactory);

                return _initialize();
            };


            const _initialize = () => {
                self.modelStateObserver = new ModelStateObserverConstructor(self, true);
                self.modelStateObserver.commitData();

                _getCustomerBalanceData();
                _getPaymentMethods();
                _getIsInFreeTrial();
                self.selectedRadioButton(CURRENT_BALANCE);
                self.modelStateObserver.navigateWithoutWarning = true;

                return _promiseFactory.wait();
            };
        };
});


