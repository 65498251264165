define('presentation/signup/facades/signupPhoneNumberTypeFacade',[
    'externalDependencies/clientApi',
    'businessServices/signup/signupSessionStore',
    'entities/signupNumberPortEntity'
],
    function() {
        let _signupSessionStore = null;

        const SignupNumberPortEntityConstructor = require('entities/signupNumberPortEntity');

        const _clearPortForm = (numberPortId) => {
            const numberPortEntity = new SignupNumberPortEntityConstructor();
            numberPortEntity.numberPortId = numberPortId;
            numberPortEntity.phoneNumbersToPort = [];
            numberPortEntity.otherNumbersOnAccount = false;

            return _signupSessionStore.saveNumberPort(numberPortEntity);
        };

        const _cancelPhoneNumberReservation = (reservedPhoneNumber) => _signupSessionStore.cancelPhoneNumberReservation(reservedPhoneNumber);
        const _get = () => _signupSessionStore.get();
        const _saveNumberTypeSelection = (selectedCard) => _signupSessionStore.saveNumberTypeSelection(selectedCard);

        const _init = () => {
            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();
        };

        return function() {
            let self = this;
            self.init = _init;
            self.clearPortForm = _clearPortForm;
            self.cancelPhoneNumberReservation = _cancelPhoneNumberReservation;
            self.get = _get;
            self.saveNumberTypeSelection = _saveNumberTypeSelection;
        };
    });

