define('presentation/common/analyticsGraph/callRecordView',[],
    function () {
        var getFieldFn = function(fieldName, defaultValue) {
            return function(callRecord) {
                if (callRecord[fieldName]) {
                    return callRecord[fieldName];
                } else {
                    return defaultValue;
                }
            };
        };

        var testValueFn = function(valueFn, value) {
            return function(callRecord) {
                return valueFn(callRecord) === value;
            };
        };
        var testValuesFn = function(valueFn, values) {
            return function (callRecord) {
                return values.includes(valueFn(callRecord));
            };
        };

        const ORIGINATION_DISCRIMINATOR = {
            internal: 1,
            external: 2,
        };

        const DISPOSITION = {
            answered: 1,
            missed: 2,
            abandoned: 3,
            completed: 4,
            deactivated: 5,
            humanDetectionFailed: 6,
        };

        var callRecord = {
            originationDiscriminator : getFieldFn("a", null),
            durationInSeconds : getFieldFn("b", null),
            callDateTime : getFieldFn("c", null),
            hasVoicemail : getFieldFn("d", false),
            hasCallRecording : getFieldFn("e", false),
            fromOutsideNumber : getFieldFn("f", null),
            fromHostedNumberId : getFieldFn("g", null),
            fromUserId : getFieldFn("h", null),
            toHostedNumberId : getFieldFn("i", null),
            toUserGroupId : getFieldFn("j", null),
            toUserId : getFieldFn("k", null),
            toForwardingNumberId : getFieldFn("l", null),
            toOutsideNumber : getFieldFn("m", null),
            dispositionId : getFieldFn("n", null),
            toVoicemailBoxId : getFieldFn("o", null),
            callHistoryId : getFieldFn("p", null),
            humanDetectionFailed : getFieldFn("q", false),
        };

        callRecord.wasAnswered = testValueFn(callRecord.dispositionId, DISPOSITION.answered);
        callRecord.wasMissed = testValueFn(callRecord.dispositionId, DISPOSITION.missed);
        callRecord.wasSystem = testValuesFn(callRecord.dispositionId, [DISPOSITION.abandoned, DISPOSITION.completed]);
        callRecord.isOutbound = testValueFn(callRecord.originationDiscriminator, ORIGINATION_DISCRIMINATOR.internal);
        callRecord.humanDetectionFailed = testValueFn(callRecord.dispositionId, DISPOSITION.humanDetectionFailed);

        return callRecord;
    }
);


