define('presentation/common/routeToModal/presentationObjects/scheduleSegmentPresentationObject',[],function() {
    return function(){
        var self = this;
        self.accountAttendantScheduleSegmentId = null;
        self.accountAttendantScheduleId = null;
        self.routeToId = null;
        self.routeToType = null;
        self.routeToData = null;
        self.name = null;
        self.sequence = null;
        self.isForSunday = false;
        self.isForMonday = false;
        self.isForTuesday = false;
        self.isForWednesday = false;
        self.isForThursday = false;
        self.isForFriday = false;
        self.isForSaturday = false;
        self.startHour = 0;
        self.startMinute = 0;
        self.endHour = 0;
        self.endMinute = 0;

        self.routeToData = null;
        self.routeSearchData = ko.observable(null);
        self.routeType = ko.observable("");

        self.userAvatar = ko.observable(null);
        self.hasAvatar = ko.observable(false);
        self.userGroupRingType = ko.observable(null);
        self.forwardingNumberPhoneNumber = ko.observable(null);
        self.forwardingNumberCountryName = null;
        self.showDeactivated = ko.observable(false);
        self.deactivatedMessage = ko.observable("");
    };
});

