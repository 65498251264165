define('businessServices/browserSupport/browserType',[],
function() {
    const self = this;

    // From: http://stackoverflow.com/a/9851769
    const _isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    const _isFirefox = typeof InstallTrigger !== 'undefined';
    const _isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    const _isMobileDevice = navigator.userAgent.indexOf('Mobi') !== -1;
    const _isAndroidMobileDevice = _isMobileDevice && navigator.userAgent.indexOf('Android') !== -1;

    self.isMobile = ko.observable(window.innerWidth <= 960);
    self.windowHeight = ko.observable(window.innerHeight);
    self.windowWidth = ko.observable(window.innerWidth);
    window.onresize = () => {
        self.isMobile(window.innerWidth <= 960);
        self.windowHeight(window.innerHeight);
        self.windowWidth(window.innerWidth);
    };

    return {
        isOpera: _isOpera,
        isFirefox: _isFirefox,
        isSafari: _isSafari,
        isMobile: self.isMobile,
        isMobileDevice: _isMobileDevice,
        isAndroidDevice: _isAndroidMobileDevice,
        windowHeight: self.windowHeight,
        windowWidth: self.windowWidth,
    };
});

