define('presentation/settings/userDevices/viewModels/userDevicesViewModel',['businessServices/state/modelStateObserver',
        'common/collections/collectionSorter',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'constants/userDeviceConstants',
        'presentation/settings/userDevices/facades/userDevicesFacade',
        'presentation/settings/userDevices/presentationObjects/userDevicePresentationObject',
        'presentation/settings/userDevices/viewModels/userDeviceViewModel',
], function() {
        return function() {
            const self = this;

            const SorterConstructor = require('common/collections/collectionSorter');
            const _sorter = new SorterConstructor();

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const UserDevicePresentationObjectConstructor = require('presentation/settings/userDevices/presentationObjects/userDevicePresentationObject');
            const UserDeviceViewModelConstructor = require('presentation/settings/userDevices/viewModels/userDeviceViewModel');

            const _i18n = require('i18next');
            const _userDeviceConstants = require('constants/userDeviceConstants');

            let _facade = null;
            let _promiseFactory = null;

            let _addedItemSort = 1;

            const _refreshDevices = () => {
               return _promiseFactory.defer((promise) => {
                   self.devices.removeAll();
                   _facade.getDevices(self.userId)
                        .fail(promise.reject)
                        .done((devices) => {
                            devices.forEach((device) => {
                                let userDevice = new UserDeviceViewModelConstructor(device, self, _facade);
                                userDevice.selectedCallerId(device.selectedCallerId);
                                if (device.physicalDeviceId()) {
                                    userDevice.phoneNumberEnabled(false);
                                }
                                self.devices.push(userDevice);
                                self.modelStateObserver.addChildObserver(userDevice.modelStateObserver);
                            });

                            _sortDisplayDevices();
                            promise.resolve();
                        });
                });
            };

            const _sortDisplayDevices = () => {
                _sorter.multiSort(self.devices, ["addedItemSort", "deviceName"], [true, true]);
            };

            const _removeEmptyDevicesBeforeSave = () => {
                let emptyAddedDevices = self.devices().filter((device) => {
                    return device.deviceName() === "" && device.devicePhoneNumber() === "";
                });
                self.devices.removeAll(emptyAddedDevices);
            };

            self.userId = null;
            self.isSubForm = ko.observable(false);
            self.isValid = ko.observable(true);
            self.isDirty = ko.observable(false);
            self.modelStateObserver = null;
            self.devices = ko.observableArray([]).extend({observeState: true});
            self.canDeleteDevice = ko.pureComputed(() => {
                let devicesReadyToSave = self.devices().filter((device) => {
                   if (device.deviceId() === null) {
                       return !device.isEditing();
                   }
                    return true;
                });

                return devicesReadyToSave.length > 1;
            });
            self.isMaxDevices = ko.pureComputed(() => self.devices().length >= _userDeviceConstants.maximum_devices);
            self.addAnotherI18nKey = ko.pureComputed(() => self.isMaxDevices() ? 'maxDevices' : 'addAnother');
            self.maxDevicesTooltip =  _i18n.t('userDevices:maxDevices');
            self.deleteToolTip = _i18n.t('userDevices:deviceTooltip');

            self.addAnotherDevice = () => {
                if (self.isMaxDevices()){
                    return;
                }

                const newDevice = new UserDevicePresentationObjectConstructor();
                let userDevice = new UserDeviceViewModelConstructor(newDevice, self, _facade);
                userDevice.isEditing(true);
                userDevice.addedItemSort(_addedItemSort++);
                
                self.devices.push(userDevice);
                self.modelStateObserver.addChildObserver(userDevice.modelStateObserver);

                _sortDisplayDevices();
            };

            self.cancelForm = () => {
                return _promiseFactory.defer((promise) => {
                    self.modelStateObserver.restoreData();
                    _sortDisplayDevices();
                    promise.resolve();
                });
            };

            self.cancelDevice = (device) => {
                if (device.deviceId()) {
                    device.modelStateObserver.restoreData();
                    device.isEditing(false);
                    device.validate();
                } else {
                    self.devices.remove(device);
                    _sortDisplayDevices();
                }
            };

            self.editDevice = (device) => {
                device.isEditing(true);
            };

            self.refreshDisplayDevices = () => {
                _sortDisplayDevices();
            };

            self.deleteDevice = (device) => {
                if (!self.canDeleteDevice()) {
                    return;
                }
                self.devices.remove(device);
                _sortDisplayDevices();
            };

            self.forceValidation = () => {
                return _promiseFactory.defer((deferredObject) => {
                    let areDevicesValid = true;
                    
                    const validateDevice = (device) => {
                        return _promiseFactory.deferIndefinitely((devicePromise) => {
                            device.validate()
                                .fail(devicePromise.reject)
                                .done((isDeviceValid) => {
                                    areDevicesValid = areDevicesValid && isDeviceValid;
                                    devicePromise.resolve();
                                });
                        });
                    };
                    
                    _promiseFactory.deferredList(self.devices(), validateDevice)
                        .fail(deferredObject.reject)
                        .done(() => deferredObject.resolve(areDevicesValid));
                });
            };

            self.savePage = () => {
                const _savePromise = self.isSubForm() ? _promiseFactory.deferIndefinitely() : _promiseFactory.deferWithMinimumWait();

                _removeEmptyDevicesBeforeSave();
                self.forceValidation()
                    .fail(_savePromise.reject)
                    .done((isValid) => {
                        if (isValid) {
                            _facade.saveDevices(self.devices(), self.userId)
                                .fail(_savePromise.reject)
                                .done((result) => {
                                    if (result.status === "success") {
                                        _refreshDevices()
                                            .fail(_savePromise.reject)
                                            .done(() => {
                                                self.modelStateObserver.saveData();
                                                _savePromise.resolve();
                                            });
                                    } else {
                                        _savePromise.reject();
                                    }
                                });
                        } else {
                            _savePromise.resolve();
                        }
                    });

                return _savePromise;
            };

            self.activate = (userId) => {
                if (userId !== undefined) {
                    self.userId = userId;
                    self.isSubForm(true);
                }

                const PromiseFactoryConstructor = require('common/promises/promiseFactory');
                _promiseFactory = new PromiseFactoryConstructor();

                const FacadeConstructor = require('presentation/settings/userDevices/facades/userDevicesFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                return _initialize();
            };

            const _initialize = () => {
                self.modelStateObserver = new ModelStateObserverConstructor(self, true);

                _refreshDevices();

                return _promiseFactory.wait()
                    .done(() => {
                        self.modelStateObserver.commitData();
                        self.modelStateObserver.isDirty.subscribe((isDirty) => {
                            self.isDirty(isDirty);
                        });
                    });
            };

        };
    });

