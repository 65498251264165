define('presentation/signup/facades/signupPhoneNumberTollfreeFacade',[
    'businessServices/signup/signupSessionStore',
],
    function() {
        let _signupSessionStore = null;

        const _get = () => _signupSessionStore.get();
        const _reReserveSessionPhoneNumber = () => _signupSessionStore.reReserveSessionPhoneNumber();
        const _setPhoneNumberCompleted = (signupSessionHasNumber) => _signupSessionStore.setPhoneNumberCompleted(signupSessionHasNumber);
        const _clearSessionStateReservedPhoneNumber = () => _signupSessionStore.clearSessionStateReservedPhoneNumber();

        const _init = () => {
            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();
        };

        return function() {
            let self = this;
            self.init = _init;
            self.get = _get;
            self.reReserveSessionPhoneNumber = _reReserveSessionPhoneNumber;
            self.setPhoneNumberCompleted = _setPhoneNumberCompleted;
            self.clearSessionStateReservedPhoneNumber = _clearSessionStateReservedPhoneNumber;
        };
    });

