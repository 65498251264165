define('common/storage/workSessionIdStorage',['common/storage/browserTabStorage'], function(browserTabStorage) {
    var STORE_NAME = "workSessionIdStorage";

    var _workSessionId = null;

    function getWorkSessionId() {
        if (_workSessionId === null) {
            var workSession = browserTabStorage.getObject(STORE_NAME);
            if (workSession) {
                _workSessionId = workSession.workSessionId;
            } else {
                _workSessionId = undefined;
            }
        }
        return _workSessionId;
    }

    function setWorkSessionId(workSessionId) {
        var workSession = {
            workSessionId : workSessionId
        };
        browserTabStorage.saveObject(STORE_NAME, workSession);
        _workSessionId = workSessionId;
    }

    function deleteWorkSessionId() {
        browserTabStorage.deleteObject(STORE_NAME);
        _workSessionId = null;
    }

    return {
        getWorkSessionId: getWorkSessionId,
        setWorkSessionId : setWorkSessionId,
        deleteWorkSessionId : deleteWorkSessionId
    };
});

