define('presentation/common/routeToModal/viewModels/routeToVoicemailBoxViewModel',['common/promises/promiseFactory',
        'businessServices/events/eventManager',
        'businessServices/authentication/sessionAccountInfo',
        'constants/autoAttendantVoiceRouteEnumerations',
        'common/storage/commonState',
        'common/collections/collectionSorter',
        'businessServices/state/modelStateObserver',
        'settings/navigationConfiguration'
], function() {

    return function(initialRoutedToId, initialRouteToType, nameThisOption, routingRuleExitConditions, validateRouteToViewModelCallback) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        const _promiseFactory = new PromiseFactoryConstructor();

        let _disposables = [];

        const onSelectedVoicemailBoxChanged = () => {
            validateRouteToViewModelCallback({});
        };

        self.addVoicemailBoxUrl = _navigationConfiguration.routesById.newVoicemailBox.url;
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.selectedVoicemailBox = ko.observable().extend({observeState: true});
        _disposables.push(self.selectedVoicemailBox.subscribe(onSelectedVoicemailBoxChanged));
        self.routeToStrategy = null;
        self.isNewRouteTo = true;
        self.isValid = ko.observable(true);

        self.voicemailBoxes = ko.pureComputed(() => {
            const voicemailBoxes = _commonState.voicemailBoxes()
                .filter(voicemailBox => {
                    return voicemailBox.isSelectable();
                })
                .map(voicemailBox => {
                    const commonStateOwnerItem = _commonState.get(voicemailBox.ownerId());
                    if (commonStateOwnerItem) {
                        const name = voicemailBox.name() === "undefined" ? {name: commonStateOwnerItem.name()} : {};
                        const avatarType = {avatarType: commonStateOwnerItem.type};
                        const avatar = commonStateOwnerItem.isUser ? {avatar: commonStateOwnerItem.avatar} : {avatar: true};
                        return Object.assign({}, voicemailBox, avatarType, avatar, name);
                    } else {
                        const iconProperty = {iconProperty: _commonState.types.voicemailBox};
                        return Object.assign({}, voicemailBox, iconProperty);
                    }
                });
            const sorter = new SorterConstructor();
            sorter.sort(voicemailBoxes, "name", true);
            return voicemailBoxes;
        });

        self.cancelForm = () => {
            return _promiseFactory.defer((deferredObject) => {
                _eventManager.publishRouteToCloseModal();
                deferredObject.resolve();
            });
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };
        
        self.activate = () => {

            return _initialize();
        };

        const _initialize = () => {
            if(initialRoutedToId !== null  && initialRouteToType === _voiceRouteEnumerations.voicemailBox) {
                const voicemailBox = self.voicemailBoxes().find(voicemailBox => initialRoutedToId === voicemailBox.id);
                self.selectedVoicemailBox(voicemailBox);
            } else {
                const currentUserId = _sessionAccountInfo.userId();
                const currentUserVoicemailBox = self.voicemailBoxes().find(voicemailBox => voicemailBox.ownerId() === currentUserId);
                self.selectedVoicemailBox(currentUserVoicemailBox);
            }
            return _promiseFactory.wait();
        };
    };
});
