define('presentation/settings/autoAttendant/presentationObjects/autoAttendantPresentationObject',[],function() {
    return function(){
        let self = this;

        self.name =  ko.observable(null);
        self.accountAutoAttendantId = null;
        self.voicePrompt = ko.observable(null);
        self.collapsedAutoAttendantMenuLabel = ko.observable("");
        self.collapsedMemberIds = ko.observable([]);
        self.subMenuItems =  ko.observableArray([]);
        self.autoAttendantCallFlowViewModel = ko.observable(null);
        self.routingRule = null;
        self.isVoiceEnabled = ko.observable(false);
        self.isVoicePromptInvalid = ko.observable(false);
        self.autoAttendantVoiceRoute = null;
        self.editAutoAttendantUrl = null;
        self.modifiedDateTime = null;
        self.createdDateTime = null;
        self.showCallFlow = ko.observable(false);
    };
});

