define('presentation/contacts/facades/contactCardFacade',[
    'businessServices/contacts/contactsStateSingleton',
    'common/converters/phoneNumberFormatter',
    'persistence/dataProviders/webMessagingDataProvider',
], function(
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('persistence/dataProviders/webMessagingDataProvider') */
    WebMessagingDataProviderConstructor
) {
    /** @typedef { import('presentation/contacts/facades/contactCardFacade') } ContactCardFacade */

    return function() {
        /**@type { ContactCardFacade }*/
        const self = this;

        const _phoneNumberFormatter = new PhoneNumberFormatter();

        /** @type import('common/promises/promiseFactory') */
        let _promiseFactory = null;

        /** @type { import("persistence/webSocket/webSocketApp" )} */
        let _webSocketApp = null;

        /** @type WebMessagingDataProviderConstructor["prototype"]  */
        let _webMessagingDataProvider = null;

        /** @type ContactCardFacade["init"] */
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
            _webMessagingDataProvider = new WebMessagingDataProviderConstructor();

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("mobile_contacts");
        };

        /** @type ContactCardFacade["getContactById"] */
        const _getContactById = (/** @type string */ accountContactId) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _contactsState.getContactById(accountContactId)
                    .fail(deferredObject.reject)
                    .done((/** @type { IContactPresentationObject } */ contactPresentationObject) => {
                        deferredObject.resolve(contactPresentationObject);
                    });
            });
        };

        /** @type ContactCardFacade["createDraftConversation"] */
        const _createDraftConversation = (/** @type string */ recipient) => {
            return _promiseFactory.defer((deferredObject) => {
                const recipientNumber = _phoneNumberFormatter.toNumbers(recipient);
                _webMessagingDataProvider.createDraftConversation([recipientNumber])
                    .fail(deferredObject.reject)
                    .done(({ smsConversationDraftId }) => {
                        deferredObject.resolve(smsConversationDraftId);
                    });
            });
        };

        /** @type ContactCardFacade["getLatLongFromAddress"] */
        const _getLatLongFromAddress = (/** @type { Array<IContactAddress> } */ addresses) => {
            const request = {
                postal_addresses: addresses.map(address => (
                    {
                        street: address.addressLineOne,
                        addressLineTwo: address.addressLineTwo,
                        city: address.city,
                        region: address.regionName,
                        postCode: address.postalCode
                    }
                ))
            };

            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("get_address_longitude_latitude", request, (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        /** @type ContactCardFacade["getMap"] */
        const _getMap = (request) => {
            return _promiseFactory.defer((deferredObject) => {
                _webMessagingDataProvider.getMap(request)
                    .fail(deferredObject.reject)
                    .done(({ signedUrl }) => {
                        deferredObject.resolve(signedUrl);
                    });
            });
        };

        /** @type ContactCardFacade["getScrollPositionById"] */
        const _getScrollPositionById = (/** @type string */ accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                _contactsState.getScrollPositionById(accountContactId).then(position => {
                    deferredObject.resolve(position);
                });
            });
        };

        /** @type ContactCardFacade["getDetailScrollPositionById"] */
        const _getDetailScrollPositionById = (/** @type string */ accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                _contactsState.getDetailScrollPositionById(accountContactId).then(position => {
                    deferredObject.resolve(position);
                });
            });
        };

        /** @type ContactCardFacade["setScrollPositionById"] */
        const _setScrollPositionById = (/** @type string */ accountContactId, /** @type { number } */ position) => {
            _contactsState.setScrollPositionById(accountContactId, position);
        };

        /** @type ContactCardFacade["setScrollPositionById"] */
        const _setDetailScrollPositionById = (/** @type string */ accountContactId, /** @type { number } */ position) => {
            _contactsState.setDetailScrollPositionById(accountContactId, position);
        };

        /** @type ContactCardFacade["setOpenStateById"] */
        const _setOpenStateById = (/** @type string*/ accountContactId, /** @type {boolean} */ isOpen) => {
            _contactsState.setOpenStateById(accountContactId, isOpen);
        };

        /** @type ContactCardFacade["getOpenStateById"] */
        const _getOpenStateById = (/** @type string*/ accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                _contactsState.getOpenStateById(accountContactId).then(isOpen => {
                    deferredObject.resolve(isOpen);
                });
            });
        };

        self.init = _init;
        self.createDraftConversation = _createDraftConversation;
        self.getContactById = _getContactById;
        self.getDetailScrollPositionById = _getDetailScrollPositionById;
        self.getLatLongFromAddress = _getLatLongFromAddress;
        self.getMap = _getMap;
        self.getOpenStateById = _getOpenStateById;
        self.getScrollPositionById = _getScrollPositionById;
        self.setDetailScrollPositionById = _setDetailScrollPositionById;
        self.setOpenStateById = _setOpenStateById;
        self.setScrollPositionById = _setScrollPositionById;
    };
});
