define('presentation/settings/userVoicemail/facades/userVoicemailSubscribersFacade',['businessServices/authentication/sessionAccountInfo',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'externalDependencies/clientWebSocket',
        'presentation/settings/userVoicemail/presentationObjects/voicemailSubscribersPresentationObject',
    ],
    function() {
        const VoicemailSubscribersPresentationObjectConstructor = require('presentation/settings/userVoicemail/presentationObjects/voicemailSubscribersPresentationObject');

        const clientWebSocket = require('externalDependencies/clientWebSocket');
        const _userVoicemailSocket = clientWebSocket.forApplication("userVoicemail");

        const _commonState = require('common/storage/commonState');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        let _promiseFactory = null;

        const _getVoicemailSubscribers = (otherUserId) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let userId = otherUserId;
                if (userId === null) {
                    userId = _sessionAccountInfo.userId();
                }
                let request = {
                    userId: userId
                };
                _userVoicemailSocket.send("listSubscribers", request, (result, err) => {
                    if (err) {
                        throw new Error(err);
                    }

                    let subscribers = result.subscribers;
                    let subscribersList = subscribers.map(subscriber => {
                        const subscriberPresentationObject = new VoicemailSubscribersPresentationObjectConstructor();
                        if (subscriber.userId) {
                            subscriberPresentationObject.subscriberId = subscriber.userId;
                            subscriberPresentationObject.avatarType = "user";
                        }
                        else if (subscriber.userGroupId) {
                            subscriberPresentationObject.subscriberId = subscriber.userGroupId;
                            subscriberPresentationObject.avatarType = "userGroup";
                        }
                        return subscriberPresentationObject;
                    });

                    promise.resolve({
                        subscribers : subscribersList,
                        subscribersLog: result.subscribersLog,
                    });
                });
            });
        };

        const _saveVoicemailSubscribers = (otherUserId, subscribers) => {
            let userId = otherUserId;
            if (userId === null) {
                userId = _sessionAccountInfo.userId();
            }

            return _promiseFactory.deferIndefinitely((promise) => {
                let subscribersToUpdate = subscribers.subscribers.map(subscriber => {
                    let subscriberToUpdate = {};
                    subscriberToUpdate.userId = subscriber.userId;
                    subscriberToUpdate.userGroupId = _commonState.resolveGroupIdToGuid(subscriber.userGroupId);
                    return subscriberToUpdate;
                });
                let request = {
                    userId: userId,
                    subscribers: subscribersToUpdate,
                };
                _userVoicemailSocket.send("updateSubscribers", request, (result, err) => {
                    if (err) {
                        throw new Error(err);
                    }

                    promise.resolve(result);
                });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
        };

        return function () {
            let self = this;

            self.init = _init;
            self.getVoicemailSubscribers = _getVoicemailSubscribers;
            self.saveVoicemailSubscribers = _saveVoicemailSubscribers;
        };
    });

