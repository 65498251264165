define('presentation/loginNotifications/facades/reactivationFacade',[
    'businessServices/creditCards/creditCardTypes',
    'businessServices/specifications/validCreditCardSpecification',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/time/date',
    'constants/creditCardResponseConstants',
    'constants/creditCardResponseEnumerations',
    'entities/paymentMethodEntity',
    'persistence/dataProviders/accountDataProvider',
    'persistence/dataProviders/paymentMethodDataProvider',
    'persistence/repositories/payBalanceRepository',
    'presentation/common/dateTimeValue',
    'presentation/loginNotifications/presentationObjects/paymentMethodPresentationObject',
], function () {

    const CollectionSorterConstructor = require('common/collections/collectionSorter');
    const CreditCardConstructor = require('businessServices/creditCards/creditCardTypes');
    const DateConstructor = require('common/time/date');
    const PaymentMethodPresentationObjectConstructor = require('presentation/loginNotifications/presentationObjects/paymentMethodPresentationObject');
    const PayBalanceRepositoryConstructor = require('persistence/repositories/payBalanceRepository');
    const PaymentMethodDataProvider = require('persistence/dataProviders/paymentMethodDataProvider');
    const AccountDataProviderConstructor = require('persistence/dataProviders/accountDataProvider');
    const PaymentMethodEntityConstructor = require('entities/paymentMethodEntity');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const ValidCreditCardSpecification = require('businessServices/specifications/validCreditCardSpecification');

    const _collectionSorter = new CollectionSorterConstructor();
    const _creditCard = new CreditCardConstructor();
    const _creditCardResponse = require('constants/creditCardResponseConstants');
    const _date = new DateConstructor();
    const _payBalanceRepository = new PayBalanceRepositoryConstructor();
    const _paymentMethodDataProvider = new PaymentMethodDataProvider();
    const _accountDataProvider = new AccountDataProviderConstructor();
    const _promiseFactory = new PromiseFactoryConstructor();
    const _validCreditCardSpecification = new ValidCreditCardSpecification();
    let _webSocketApp = null;

    const _addPaymentMethodAndMakePayment = (paymentMethod, paymentAmount) => {
        return _promiseFactory.defer((deferredObject) => {
            const paymentMethodEntity = new PaymentMethodEntityConstructor();

            paymentMethodEntity.cardHolderName = paymentMethod.cardHolderName.trim();
            paymentMethodEntity.cardType = paymentMethod.cardType.trim();
            paymentMethodEntity.creditCardNumber = _creditCard.unformatCreditCard(paymentMethod.creditCardNumber);
            paymentMethodEntity.cvvSecurityCode = paymentMethod.cvvSecurityCode.trim();
            paymentMethodEntity.expirationDate = _date.getExpirationDate(paymentMethod.expirationYear, paymentMethod.expirationMonth);
            paymentMethodEntity.isDefault = false;
            paymentMethodEntity.country = paymentMethod.country;
            paymentMethodEntity.postalCode = paymentMethod.postalCode.trim();

            _payBalanceRepository.addPaymentMethodAndMakePayment(paymentMethodEntity, paymentAmount)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _makePayment = (paymentMethodId, paymentAmount) => {
        return _promiseFactory.defer((deferredObject) => {
            _payBalanceRepository.applyPayment(paymentMethodId, paymentAmount)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _reactivateAccount = (reactivationData) => {
        _promiseFactory.timeoutMilliSeconds = 90000;
        return _promiseFactory.defer((deferredObject) => {
            const socketParams = {
                planId: reactivationData.planId,
                packageId: reactivationData.packageId,
                paymentMethodId: reactivationData.paymentMethodId,
                newPaymentMethod: {
                    cardHolderName: reactivationData.newPaymentMethod.cardHolderName,
                    cardNumber: reactivationData.newPaymentMethod.creditCardNumber,
                    expirationDate: reactivationData.newPaymentMethod.expirationDate,
                    cvvSecurityCode: reactivationData.newPaymentMethod.cvvSecurityCode,
                    postalCode: reactivationData.newPaymentMethod.postalCode,
                    country: reactivationData.newPaymentMethod.country,
                    cardType: reactivationData.newPaymentMethod.cardType
                },
                reservedPhoneNumbers: [],
                removedPhoneConnectors: []
            };

            _webSocketApp.send("reactivateAccount", socketParams, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _getBalanceInformation = () => {
        return _promiseFactory.defer((promise) => {
            _accountDataProvider.getBalanceInformation()
                .fail(promise.reject)
                .done((balanceInformation) => {
                    promise.resolve(balanceInformation.currentBalance);
                });
        });
    };

    const _getAccountInformation = () => {
        return _promiseFactory.defer((deferredObject) => {
            _accountDataProvider.getAccount()
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _getAllPaymentMethods = () => {
        return _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getAll()
                .fail(deferredObject.reject)
                .done((paymentMethods) => {
                    const paymentMethodsFormatted = paymentMethods.map((currentPaymentMethod) => {
                        return _buildPaymentMethodPresentationObject(currentPaymentMethod);
                    });
                    _collectionSorter.sort(paymentMethodsFormatted, "description", true);
                    deferredObject.resolve(paymentMethodsFormatted);
                });
        });
    };

    const _buildPaymentMethodPresentationObject = (currentPaymentMethod) => {
        const paymentMethodPresentationObject = new PaymentMethodPresentationObjectConstructor();
        paymentMethodPresentationObject.paymentMethodId = currentPaymentMethod.paymentMethodId;
        paymentMethodPresentationObject.cardHolderName = currentPaymentMethod.cardHolderName;
        paymentMethodPresentationObject.cardType = currentPaymentMethod.cardType;
        paymentMethodPresentationObject.expirationDate = currentPaymentMethod.expirationDate;
        paymentMethodPresentationObject.isDefault(currentPaymentMethod.isDefault);
        paymentMethodPresentationObject.isExpired(_isPaymentMethodExpired(currentPaymentMethod.expirationDate));
        paymentMethodPresentationObject.country = currentPaymentMethod.country;
        paymentMethodPresentationObject.postalCode = currentPaymentMethod.postalCode;
        const creditCard = _creditCard.getCreditCardLongDisplay(currentPaymentMethod.cardType, currentPaymentMethod.lastFourDigits, currentPaymentMethod.expirationDate);
        paymentMethodPresentationObject.description = creditCard.longDisplay;
        return paymentMethodPresentationObject;
    };

    const _getAddPaymentMethodAllowed = () => {
        return _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getAddPaymentMethodAttempts()
                .fail(deferredObject.reject)
                .done((attemptsStatus) => {
                    deferredObject.resolve(attemptsStatus.status === _creditCardResponse.success);
                });
        });
    };

    const _isPaymentMethodExpired = (expirationDate) => {
        return _validCreditCardSpecification.isCreditCardExpired(expirationDate);
    };

    const _init = () => {

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("reactivation");

        _paymentMethodDataProvider.init();
        _payBalanceRepository.init();
    };

    return function () {
        let self = this;

        self.addPaymentMethodAndMakePayment = _addPaymentMethodAndMakePayment;
        self.makePayment = _makePayment;
        self.buildPaymentMethodPresentationObject = _buildPaymentMethodPresentationObject;
        self.getAddPaymentMethodAllowed = _getAddPaymentMethodAllowed;
        self.getAllPaymentMethods = _getAllPaymentMethods;
        self.getBalanceInformation = _getBalanceInformation;
        self.getAccountInformation = _getAccountInformation;
        self.reactivateAccount = _reactivateAccount;
        self.init = _init;
    };
});

