define('presentation/settings/userProfile/facades/changePasswordModalFacade',['businessServices/authentication/sessionAccountInfo',
        'businessServices/login/forgotPasswordManager',
        'entities/emailEntity',
        'persistence/webSocket/webSocketApp',
        'persistence/webSocket/webSocketAuthentication'
], function () {
    return function () {
        const self = this;

        const EmailEntityConstructor = require('entities/emailEntity');

        let _forgotPasswordManager = null;
        let _promiseFactory = null;
        let _sessionAccountInfo = null;
        let _webSocketApp = null;
        let _webSocketAuthentication = null;

        self.isImpersonating = () => {
            return _sessionAccountInfo.isImpersonating();
        };

        self.sendForgotPasswordEmail = (emailAddress) => {
            return _promiseFactory.defer((deferredObject) => {
                let emailEntity = new EmailEntityConstructor();
                emailEntity.to.push(emailAddress);

                _forgotPasswordManager.forgotPassword(emailEntity)
                    .done(deferredObject.resolve)
                    .fail(deferredObject.reject);
            });
        };

        self.isPasswordCorrect = (username, password) => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketAuthentication.validatePassword(username, password)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        switch (result.status) {
                            case "invalid_user_name":
                            case "invalid_password":
                                deferredObject.resolve(false);
                                break;
                            case "success":
                                deferredObject.resolve(true);
                                break;
                            default:
                                let error = new Error("Invalid response message");
                                error.status = result.status;
                                deferredObject.reject(error);
                        }
                    });
            });
        };

        self.savePassword = (username, newPassword, oldPassword) => {
            return _promiseFactory.defer((deferredObject) => {
                const params = {
                    "userId": _sessionAccountInfo.userId(),
                    "emailAddress": username,
                    "password": newPassword,
                    "currentPassword": oldPassword
                };

                _webSocketApp.send("updateUserPassword", params, () => {
                    deferredObject.resolve();
                });
            });
        };

        self.init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            _forgotPasswordManager = require('businessServices/login/forgotPasswordManager');
            _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("user_profile");

            const WebSocketAuthenticationConstructor = require('persistence/webSocket/webSocketAuthentication');
            _webSocketAuthentication = new WebSocketAuthenticationConstructor();
            _webSocketAuthentication.init();
        };
    };
});

