define('presentation/common/videoPlayer/bindings/videoPlayerBinding',[
    'businessServices/converters/durationTimeFormatter',
    'constants/videoPlayerConstants',
    'businessServices/browserSupport/browserType'
], function (
    /** @type typeof import('businessServices/converters/durationTimeFormatter') */
    DurationTimeFormatterConstructor,
    /** @type typeof import('constants/videoPlayerConstants') */
    VideoPlayerConstants,
    /** @type typeof import('businessServices/browserSupport/browserType') */
    BrowserType
) {
    return {
        /** @type {KnockoutBindingHandler<HTMLElement, IVideoPlayerBindingOptions>["init"]} */
        init: function (element, valueAccessor) {
            const {
                src,
                videoInitialLoadTime,
                videoTotalTime,
                videoCurrentTime,
                isVideoLoaded,
                isVideoPlaying,
                wasVideoStarted,
                togglePlayPauseVideo,
                toggleMuteVideo,
                toggleFullScreen,
                isVideoMuted,
                isVideoInFullScreen,
                startMouseHoverTimer,
                clearMouseHoverTimer,
                volumeSliderWasClicked
            } = ko.unwrap(valueAccessor());

            const durationTimeFormatter = new DurationTimeFormatterConstructor();

            let disposables = [];

            /** @type {boolean}*/
            let cursorCaughtOnProgressBar = false;

            const volumeMax = '1';
            const volumeMin = '0';

            /** @type {boolean}*/
            const supportsVideo = !!document.createElement('video').canPlayType;
            if (!supportsVideo) {
                return;
            }

            //#region Video
            const hideDefaultControls = () => {
                // Hide the default controls
                video.controls = false;
                // Display the custom video controls overlay
                videoControls.setAttribute('data-state', 'visible');
            };

            const initializeVideo = () => {
                updateMaxAttributes();
                updateVideoTotalTime();
                trySetCurrentTime();
                setVolumeOnInit();
            };

            const setInitialSrc = (/** @type string*/newValue) => {
                if (!video.getAttribute('src') && newValue) {
                    const initialLoadTime = videoInitialLoadTime();
                    const src = `${newValue}#t=${initialLoadTime}`;
                    video.src = src;
                    videoSource.src = src;
                    video.load();
                }
            };

            const isVideoLoadedCheck = () => {
                const videoHasSource = video.getAttribute('src');
                const progressBarHasDuration = progress.getAttribute('max') && seek.getAttribute('max');
                if (videoHasSource && progressBarHasDuration) {
                    isVideoLoaded(true);
                }
            };

            const trySetCurrentTime = () => {
                const currentTimeString = videoCurrentTime();
                if (currentTimeString !== VideoPlayerConstants.defaultTotalTime) {
                    video.currentTime = durationTimeFormatter.revertFormattedTotalSeconds(currentTimeString);
                }
            };

            const playPauseVideo = () => {
                if (!togglePlayPauseVideo()) {
                    return;
                }

                if (!(video.ended || video.paused)) {
                    pauseVideo();
                    togglePlayPauseVideo(false);
                    return;
                }

                playVideo();
                togglePlayPauseVideo(false);
            };

            const playVideo = () => {
                tryPauseSiblingVideos();
                isVideoPlaying(true);
                wasVideoStarted(true);
                video.play();
                startMouseHoverTimer();
            };

            const pauseVideo = () => {
                onVideoPaused();
                video.pause();
            };

            const tryPauseSiblingVideos = () => {
                const allVideoElements = document.getElementsByClassName('video-player-video');
                if (allVideoElements.length <= 1) {
                    return;
                }

                Array.from(allVideoElements).forEach((/** @type HTMLVideoElement */siblingVideo) => {
                    if (siblingVideo.id !== video.id) {
                        siblingVideo.pause();
                    }
                });
            };

            const onVideoPaused = () => {
                isVideoPlaying(false);
                clearMouseHoverTimer();
            };

            const onVideoEnd = () => {
                isVideoPlaying(false);
                wasVideoStarted(false);
                volumeSliderWasClicked(false);
                clearMouseHoverTimer();
                video.currentTime = 0;
            };
            //#endregion

            //#region Progress Bar
            const updateMaxAttributes = () => {
                const videoDuration = video.duration.toString();
                progress.setAttribute('max', videoDuration);
                seek.setAttribute('max', videoDuration);
            };

            const updateProgressBarValue = () => {
                if (!progress.getAttribute('max') || !seek.getAttribute('max')) {
                    updateMaxAttributes();
                }

                if (!cursorCaughtOnProgressBar) {
                    const videoCurrentTime = video.currentTime.toString();
                    progress.value = videoCurrentTime;
                    seek.value = videoCurrentTime;
                }
            };

            const saveMouseClickPredictionOnProgressBar = (/** @type {MouseEvent} */e) => {
                const skipTo = Math.round((e.offsetX / e.target.clientWidth) * video.duration);
                seek.setAttribute('data-seek', skipTo.toString());
                if (cursorCaughtOnProgressBar) {
                    onSkipAhead(e);
                }
            };

            const onSkipAhead = (/** @type {MouseEvent} */e) => {
                const skipTo = e.target.dataset.seek ? e.target.dataset.seek : e.target.value;
                video.currentTime = skipTo;
                seek.value = skipTo;
                progress.value = skipTo;
            };

            const catchCursorOnProgressBar = (/** @type {MouseEvent} */e) => {
                cursorCaughtOnProgressBar = true;
                pauseVideo();
                onSkipAhead(e);
            };

            const freeCursorFromProgressBar = (/** @type {MouseEvent} */e) => {
                cursorCaughtOnProgressBar = false;
                onSkipAhead(e);
            };
            //#endregion

            //#region Time // Duration
            const updateVideoTotalTime = () => {
                const totalSeconds = durationTimeFormatter.formatTotalSecondsNotPadded(video.duration);
                videoTotalTime(totalSeconds);
            };

            const updateVideoCurrentTime = () => {
                const currentSeconds = durationTimeFormatter.formatTotalSecondsNotPadded(video.currentTime);
                videoCurrentTime(currentSeconds);
                videoInitialLoadTime(video.currentTime);
            };
            //#endregion

            //#region Volume Controls
            const setVolumeOnInit = () => {
                setVolumeValue(volumeMax);
                updateVolumeSliderColors();
                volume.setAttribute('data-volume', volumeMax);
            };

            const setVideoVolume = (/** @type {string}*/newVolume) => {
                setVolumeValue(newVolume);
                video.volume = newVolume;
            };

            const setVolumeValue = (/** @type {string}*/newVolume) => {
                volume.value = newVolume;
                volume.setAttribute('value', newVolume);
            };

            const muteVideo = () => {
                if (!toggleMuteVideo()) {
                    return;
                }

                if (video.volume === 0 && video.muted) {
                    const userVolume = volume.getAttribute('data-volume');
                    const newVolume = userVolume === volumeMin ? volumeMax : userVolume;
                    setVideoVolume(newVolume);
                } else if (video.volume <= 1 && !video.muted) {
                    setVideoVolume(volumeMin);
                }

                video.muted = !video.muted;
                isVideoMuted(video.muted);
                toggleMuteVideo(false);
            };

            const onUpdateVolume = () => {
                clearMouseHoverTimer();

                if (video.muted) {
                    video.muted = false;
                }

                const newVolume = volume.value;
                setVideoVolume(newVolume);
                volume.setAttribute('data-volume', newVolume);

                if (newVolume === volumeMin) {
                    video.muted = true;
                }
            };

            const updateVolumeControlStyles = () => {
                updateVolumeSliderColors();
                const showMuteIcon = video.volume === 0 || video.muted;
                isVideoMuted(showMuteIcon);
            };

            const updateVolumeSliderColors = () => {
                const {value, max, min} = volume;
                const newMinValue = value - min;
                const newMaxValue = max - min;
                const newVolumeValue = newMinValue / newMaxValue * 100;
                const sliderTrackBackground = `linear-gradient(to right, #ffffff 0%, #ffffff ${newVolumeValue}%, #ffffff33 ${newVolumeValue}%, #ffffff33 100%)`;
                volume.style.setProperty('--volumeValue', sliderTrackBackground);
            };
            //#endregion

            //#region Fullscreen Controls
            const onFullScreenChanged = () => {
                const isInFullScreen = BrowserType.isSafari ? !!document.webkitCurrentFullScreenElement : !!document.fullscreenElement;
                isVideoInFullScreen(isInFullScreen);
            };

            const expandToFullScreen = () => {
                if (!toggleFullScreen()) {
                    return;
                }

                const _minimizeFullScreen = () => {
                    if (BrowserType.isSafari) {
                        document.webkitCancelFullScreen();
                    } else {
                        document.exitFullscreen(); // Chrome, Edge and Firefox
                    }
                };

                const _expandToFullScreen = () => {
                    if (BrowserType.isSafari) {
                        element.webkitRequestFullscreen();
                    } else {
                        videoContainer.requestFullscreen(); // Chrome, Edge and Firefox
                    }
                };

                if (isVideoInFullScreen()) {
                    _minimizeFullScreen();
                } else {
                    _expandToFullScreen();
                }

                toggleFullScreen(false);
            };
            //#endregion

            /** @type {HTMLVideoElement} */
            const video = element.getElementsByClassName('video-player-video')[0];
            /** @type {HTMLSourceElement} */
            const videoSource = element.getElementsByClassName('video-player-video-source')[0];
            /** @type {HTMLElement} */
            const videoControls = element.getElementsByClassName('video-player__video-controls-overlay')[0];
            /** @type {HTMLElement} */
            const progress = element.getElementsByClassName('video-player__video-controls-progress-bar')[0];
            /** @type {HTMLInputElement} */
            const seek = element.getElementsByClassName('video-player__video-controls-seek-bar')[0];
            /** @type {HTMLInputElement} */
            const volume = element.getElementsByClassName('video-player__video-controls-volume-controls__volume-slider')[0];
            /** @type {HTMLElement} */
            const videoContainer = element.getElementsByClassName('video-player-container')[0];

            hideDefaultControls();

            video.addEventListener('loadedmetadata', initializeVideo);
            video.addEventListener('loadeddata', isVideoLoadedCheck);
            video.addEventListener('timeupdate', updateVideoCurrentTime);
            video.addEventListener('timeupdate', updateProgressBarValue);
            video.addEventListener('ended', onVideoEnd);
            video.addEventListener('pause', onVideoPaused);
            video.addEventListener('volumechange', updateVolumeControlStyles);
            seek.addEventListener('mousemove', saveMouseClickPredictionOnProgressBar);
            seek.addEventListener('input', onSkipAhead);
            seek.addEventListener('mousedown', catchCursorOnProgressBar);
            seek.addEventListener('mouseup', freeCursorFromProgressBar);
            volume.addEventListener('input', onUpdateVolume);

            if (BrowserType.isSafari) {
                element.addEventListener('webkitfullscreenchange', onFullScreenChanged);
            } else {
                videoContainer.addEventListener('fullscreenchange', onFullScreenChanged); // Chrome, Firefox and Edge
            }

            disposables.push(
                togglePlayPauseVideo.subscribe(playPauseVideo),
                toggleMuteVideo.subscribe(muteVideo),
                toggleFullScreen.subscribe(expandToFullScreen),
                src.subscribe(setInitialSrc)
            );

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                disposables.forEach(disposable => disposable.dispose());

                video.removeEventListener('loadedmetadata', initializeVideo);
                video.removeEventListener('loadeddata', isVideoLoadedCheck);
                video.removeEventListener('timeupdate', updateVideoCurrentTime);
                video.removeEventListener('timeupdate', updateProgressBarValue);
                video.removeEventListener('ended', onVideoEnd);
                video.removeEventListener('pause', onVideoPaused);
                seek.removeEventListener('mousemove', saveMouseClickPredictionOnProgressBar);
                seek.removeEventListener('input', onSkipAhead);
                seek.removeEventListener('mousedown', catchCursorOnProgressBar);
                seek.removeEventListener('mouseup', freeCursorFromProgressBar);
                volume.removeEventListener('input', onUpdateVolume);
                video.removeEventListener('volumechange', updateVolumeControlStyles);

                if (BrowserType.isSafari) {
                    element.removeEventListener('webkitfullscreenchange', onFullScreenChanged);
                } else {
                    videoContainer.removeEventListener('fullscreenchange', onFullScreenChanged); // Chrome, Firefox and Edge
                }
            });
        },
        /** @type {KnockoutBindingHandler<HTMLElement, IVideoPlayerBindingOptions>["update"]} */
        update: function (element, valueAccessor) {
            const {
                src,
                videoInitialLoadTime
            } = ko.unwrap(valueAccessor());

            /** @type {HTMLVideoElement} */
            const video = element.getElementsByClassName('video-player-video')[0];
            const signedPath = src();
            if (!video.getAttribute('src') && signedPath) {
                const initialLoadTime = videoInitialLoadTime();
                video.src = `${signedPath}#t=${initialLoadTime}`;
            }

        }
    };
});
