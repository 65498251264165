define('presentation/common/actionModal/viewModels/paymentConfirmationViewModel',['common/promises/promiseFactory'
], function () {
    return function (paymentAmount) {
        const self = this;
        const _paymentAmount = paymentAmount;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        self.paymentConfirmationMessage = ko.pureComputed(() => {
            return _i18n.t("actionModal:paymentApproved", {amount: _paymentAmount});
        });

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

