define('presentation/settings/autoAttendant/facades/addAutoAttendantFacade',['businessServices/authentication/sessionAccountInfo',
        'businessServices/converters/durationTimeFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'entities/routingRuleEntity',
        'entities/routingRuleExitCondition',
        'externalDependencies/clientApi',
        'externalDependencies/clientWebSocket',
        'constants/routingRuleConstants',
        'constants/routingRuleExitConditionsConstants',
        'constants/autoAttendantVoiceRouteEnumerations',
        'presentation/common/dateTimeValue',
        'presentation/common/voicePrompt/voicePromptAudioFactory',
        'presentation/settings/autoAttendant/presentationObjects/autoAttendantPresentationObject',
        'presentation/settings/prompts/presentationObjects/promptsPresentationObject',
        'settings/navigationConfiguration'
    ],
    function() {
        const RoutingRuleEntityConstructor = require('entities/routingRuleEntity');
        const RoutingRuleExitConditionConstructor = require('entities/routingRuleExitCondition');
        const PromptsPresentationObjectConstructor = require('presentation/settings/prompts/presentationObjects/promptsPresentationObject');
        const AutoAttendantPresentationObjectConstructor = require('presentation/settings/autoAttendant/presentationObjects/autoAttendantPresentationObject');
        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _routingRuleConstants = require('constants/routingRuleConstants');
        const _routingRuleExitConditionsConstants = require('constants/routingRuleExitConditionsConstants');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _commonState = require('common/storage/commonState');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        const scheduleSocket = require('externalDependencies/clientWebSocket').forApplication("schedule");
        const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

        let DateTimeValueConstructor = null;
        let _voicePromptAudioFactory = null;
        let _promiseFactory = null;
        let _clientApi = null;

        const _getAutoAttendant = (autoAttendantId) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    accountAutoAttendantId: autoAttendantId
                };

                _clientApi.call("settings/autoAttendants/retrieve", "POST", request, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        const autoAttendant = result.data;
                        _getAutoAttendantCallFlowObjects()
                            .fail(deferredObject.reject)
                            .done((result) => {
                                autoAttendant.combinedResults = result;
                                deferredObject.resolve(autoAttendant);
                            });
                    });
            });
        };

        const _getAutoAttendantCallFlowObjects = () => {
            return _promiseFactory.defer((deferredObject) => {

                let accountCallFlowConstructorPromise = new PromiseFactoryConstructor();

                let combinedResults = {
                    availableAutoAttendants: [],
                    availableSchedules: [],
                    availableVoicePrompts: [],
                };

                accountCallFlowConstructorPromise.deferIndefinitely((promise) => {
                    _getAutoAttendants()
                        .done((autoAttendants) => {
                            combinedResults.availableAutoAttendants = autoAttendants;
                            promise.resolve();
                        });
                });

                accountCallFlowConstructorPromise.deferIndefinitely((promise) => {
                    _getSchedules()
                        .done((schedules) => {
                            combinedResults.availableSchedules = schedules;
                            promise.resolve();
                        });
                });

                accountCallFlowConstructorPromise.deferIndefinitely((promise) => {
                    _getVoicePrompts()
                        .done((voicePrompts) => {
                            combinedResults.availableVoicePrompts = voicePrompts;
                            promise.resolve();
                        });
                });


                accountCallFlowConstructorPromise.wait()
                    .fail((error) => {
                        deferredObject.reject(error);
                    })
                    .done(() => {
                        deferredObject.resolve(combinedResults);
                    });
            });
        };

        const _getAutoAttendants = () => {
            return _promiseFactory.defer((deferredObject) => {
                _clientApi.call("settings/autoAttendants/retrieveAll", "POST", {}, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        const autoAttendants = result.data;
                        const autoAttendantPresentationList = autoAttendants.map(function (autoAttendant) {
                            const autoAttendantPresentationObject = new AutoAttendantPresentationObjectConstructor();
                            autoAttendantPresentationObject.name(autoAttendant.name);
                            autoAttendantPresentationObject.accountAutoAttendantId = autoAttendant.accountAutoAttendantId;
                            autoAttendantPresentationObject.accountVoicePromptId = autoAttendant.accountVoicePromptId;
                            autoAttendantPresentationObject.systemAudioClipId = autoAttendant.systemAudioClipId;
                            autoAttendantPresentationObject.routingRule = autoAttendant.routingRule;
                            autoAttendantPresentationObject.createdDateTime = new DateTimeValueConstructor(autoAttendant.createdDateTime);
                            autoAttendantPresentationObject.modifiedDateTime = new DateTimeValueConstructor(autoAttendant.updatedDateTime);
                            autoAttendantPresentationObject.editAutoAttendantUrl = _navigationConfiguration.routesById.editAutoAttendant.baseUrl + autoAttendant.accountAutoAttendantId;
                            return autoAttendantPresentationObject;
                        });
                        deferredObject.resolve(autoAttendantPresentationList);
                    });
            });
        };

        const _getSchedules = () => {
            return _promiseFactory.defer((deferredObject) => {

                scheduleSocket.send("retrieveAll", {}, (accountSchedules, err) => {
                    if (err) {
                        deferredObject.reject(new Error(err));
                    } else {
                        deferredObject.resolve(accountSchedules);
                    }
                });
            });
        };

        const _getVoicePrompts = () => {
            return _promiseFactory.defer(function (promise) {

                promptSocket.send("retrieveAll", {}, (accountVoicePrompts, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }
                    const displayVoicePrompts = accountVoicePrompts.map((currentPrompt) => {
                        let displayPrompt = new PromptsPresentationObjectConstructor();
                        displayPrompt.isSystemPrompt = false;
                        displayPrompt.voicePromptId = currentPrompt.accountVoicePromptId;
                        displayPrompt.createdDateTime = new DateTimeValueConstructor(currentPrompt.createdDateTime);
                        displayPrompt.modifiedDateTime = new DateTimeValueConstructor(currentPrompt.updatedDateTime);
                        displayPrompt.promptName = currentPrompt.name;

                        if (currentPrompt.conversionStatus === 1) {
                            const currentVoicePrompt = _getCurrentVoicePrompt(currentPrompt.accountVoicePromptId);
                            displayPrompt.conversionComplete = ko.observable(true);
                            displayPrompt.conversionInProgress = ko.observable(false);
                            displayPrompt.currentVoicePrompt = ko.observable(currentVoicePrompt);
                        } else {
                            const emptyVoicePrompt = _getEmptyVoicePrompt();
                            displayPrompt.conversionComplete = ko.observable(false);
                            displayPrompt.conversionInProgress = ko.observable(true);
                            displayPrompt.currentVoicePrompt = ko.observable(emptyVoicePrompt);
                        }

                        if (currentPrompt.durationInMilliseconds && displayPrompt.conversionComplete) {
                            displayPrompt.currentVoicePrompt().duration = ko.observable(_getFormattedDurationProperty(currentPrompt.durationInMilliseconds / 1000));
                        } else {
                            displayPrompt.currentVoicePrompt().duration = ko.observable("Unknown");
                        }
                        displayPrompt.editPromptUrl = _navigationConfiguration.routesById.editPrompt.baseUrl  + currentPrompt.accountVoicePromptId;

                        return displayPrompt;
                    });

                    promptSocket.send("retrieveSystemPrompts", {}, (systemVoicePrompts) => {
                        const displaySystemPrompts = systemVoicePrompts.map((systemPrompt) => {
                            let displaySystemPrompt = new PromptsPresentationObjectConstructor();
                            displaySystemPrompt.isSystemPrompt = true;
                            displaySystemPrompt.systemAudioClipId = systemPrompt.systemAudioClipId;
                            displaySystemPrompt.modifiedDateTime = null;
                            displaySystemPrompt.promptName = systemPrompt.name;
                            displaySystemPrompt.conversionComplete = ko.observable(true);
                            displaySystemPrompt.conversionInProgress = ko.observable(false);
                            displaySystemPrompt.currentVoicePrompt(_getCurrentSystemPrompt(systemPrompt.systemAudioClipId));
                            displaySystemPrompt.currentVoicePrompt().duration = ko.observable(_getFormattedDurationProperty(systemPrompt.durationInMilliseconds / 1000));
                            return displaySystemPrompt;
                        });

                        const allPrompts = displayVoicePrompts.concat(displaySystemPrompts);
                        promise.resolve(allPrompts);
                    });
                });
            });
        };

        const _getFormattedDurationProperty = (promptDurationInSeconds) => {
            let formatter = new TimeFormatterConstructor();
            let formattedTimeDuration = formatter.formatTotalSeconds(promptDurationInSeconds);
            return formattedTimeDuration;
        };

        const _getCurrentVoicePrompt = (voicePromptId) => {
            return _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
        };

        const _getCurrentSystemPrompt = (systemPromptId) => {
            return _voicePromptAudioFactory.getSystemVoicePrompt(systemPromptId);
        };

        const _getEmptyVoicePrompt = () => {
            return _voicePromptAudioFactory.getEmptyVoicePrompt();
        };

        const _onNewPromptConverted = (callback) => {

            promptSocket.subscribeToEvent("voicePromptMp3Converted", (eventData) => {
                const {accountVoicePromptId, durationInMilliseconds} = eventData;
                let updatedAudioData = _getCurrentVoicePrompt(accountVoicePromptId);
                let formattedTimeDuration = _getFormattedDurationProperty(durationInMilliseconds / 1000);
                updatedAudioData.duration = ko.observable(formattedTimeDuration);

                callback({
                    accountVoicePromptId: accountVoicePromptId,
                    audioData: updatedAudioData,
                });
            });
        };

        const _addAutoAttendant = (autoAttendantName, voicePromptId, isSystemPrompt, subMenuItems) => {
            return _promiseFactory.defer(function(deferredObject) {
                const autoAttendant = _createAutoAttendant(autoAttendantName, voicePromptId, isSystemPrompt, subMenuItems);
                const request = {
                    accountId: _sessionAccountInfo.accountId(),
                    autoAttendant: autoAttendant
                };

                _clientApi.call("settings/autoAttendants/create", "POST", request, 'application/json')
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        const _updateAutoAttendant = (autoAttendantId, autoAttendantName, voicePromptId, isSystemPrompt, subMenuItems) => {
            return _promiseFactory.defer(function(deferredObject) {
                const autoAttendant = _createAutoAttendant(autoAttendantName, voicePromptId, isSystemPrompt, subMenuItems);
                autoAttendant.accountAutoAttendantId = autoAttendantId;
                const request = {
                    accountId: _sessionAccountInfo.accountId(),
                    accountAutoAttendantId: autoAttendantId,
                    autoAttendant: autoAttendant
                };

                _clientApi.call("settings/autoAttendants/update", "POST", request, 'application/json')
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        const _createAutoAttendant = (autoAttendantName, voicePromptId, isSystemPrompt, subMenuItems) => {
            const routingRule = new RoutingRuleEntityConstructor();
            routingRule.accountId = _sessionAccountInfo.accountId();
            routingRule.routingType = _routingRuleConstants.subMenu;
            routingRule.routeToSubMenu = {
                name: autoAttendantName,
                voicePromptId: null,
                systemAudioClipId: null,
                accountId: _sessionAccountInfo.accountId()
            };

            const autoAttendant = {
                name: autoAttendantName,
                voicePromptId: null,
                systemAudioClipId: null,
                routingRule: routingRule,
                accountId: _sessionAccountInfo.accountId()
            };

            if (isSystemPrompt) {
                routingRule.routeToSubMenu.systemAudioClipId = voicePromptId;
                autoAttendant.systemAudioClipId = voicePromptId;
            } else if (voicePromptId) {
                routingRule.routeToSubMenu.voicePromptId = voicePromptId;
                autoAttendant.voicePromptId = voicePromptId;
            } else {
                routingRule.routeToSubMenu.voicePromptId = voicePromptId;
                autoAttendant.voicePromptId = voicePromptId;
            }

            routingRule.routingRuleExitConditions = _createAutoAttendantRoutingRules(subMenuItems);

            return autoAttendant;
        };

        const _createAutoAttendantRoutingRules = (subMenuItems) => {
            return subMenuItems.reduce((accumulator, subMenuItem) => {
                if (subMenuItem.connector()) {
                    const routingRuleExitCondition = new RoutingRuleExitConditionConstructor();
                    routingRuleExitCondition.exitType = _routingRuleExitConditionsConstants.keyPress;
                    routingRuleExitCondition.value = subMenuItem.key;
                    routingRuleExitCondition.name = subMenuItem.optionName();

                    switch (subMenuItem.type()) {
                        case _voiceRouteEnumerations.subMenu:
                            const routingRule = new RoutingRuleEntityConstructor();
                            routingRule.accountId = _sessionAccountInfo.accountId();
                            routingRule.routingType = _routingRuleConstants.subMenu;
                            routingRule.routeToSubMenu = {
                                name: subMenuItem.optionName(),
                                accountId: _sessionAccountInfo.accountId()
                            };
                            if (subMenuItem.isSystemPrompt()) {
                                routingRule.routeToSubMenu.systemAudioClipId = subMenuItem.voicePromptId();
                            } else {
                                routingRule.routeToSubMenu.voicePromptId = subMenuItem.voicePromptId();
                            }
                            routingRule.routingRuleExitConditions = _createAutoAttendantRoutingRules(subMenuItem.connector().subMenuItems());
                            routingRuleExitCondition.nextRoutingRule = routingRule;
                            break;
                        default:
                            subMenuItem.routingRules().accountId = _sessionAccountInfo.accountId();
                            routingRuleExitCondition.nextRoutingRule = subMenuItem.routingRules();
                            break;
                    }
                    accumulator.push(routingRuleExitCondition);
                }
                return accumulator;
            }, []);
        };

        const _isAutoAttendantNameUnique = (autoAttendantId, autoAttendantName) => {
            return _promiseFactory.defer((deferredObject) => {
                const autoAttendants = _commonState.autoAttendants();
                const isValid = autoAttendants.every((autoAttendant) => {
                    if (autoAttendantId !== autoAttendant.id) {
                        return autoAttendantName !== autoAttendant.name();
                    }
                    return true;
               });
               deferredObject.resolve(isValid);
            });
        };

        const _dispose = () => {
            promptSocket.disposeOfEvents();
            scheduleSocket.disposeOfEvents();
        };
        

        const _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();

            const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
            _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
            _voicePromptAudioFactory.init();

            DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        };

        return function() {
            const self = this;

            self.init = _init;

            self.getAutoAttendant = _getAutoAttendant;
            self.getAutoAttendantCallFlowObjects = _getAutoAttendantCallFlowObjects;
            self.addAutoAttendant = _addAutoAttendant;
            self.updateAutoAttendant = _updateAutoAttendant;
            self.isAutoAttendantNameUnique = _isAutoAttendantNameUnique;
            self.onNewPromptConverted = _onNewPromptConverted;
            self.dispose = _dispose;
        };
    });

