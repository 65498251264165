define('presentation/messages/facades/conversationLinksFacade',[
    'persistence/dataProviders/webMessagingDataProvider',
    'presentation/messages/viewModels/messageLinkPreviewCardViewModel'
], function(
    /** @type typeof import('persistence/dataProviders/webMessagingDataProvider') */
    WebMessagingDataProvider,
    /** @type typeof import('presentation/messages/viewModels/messageLinkPreviewCardViewModel') */
    MessageLinkPreviewCardViewModel
) {
    return function () {
        /** @typedef {import('presentation/messages/viewModels/messageLinkPreviewCardViewModel')} MessageLinkPreviewCardViewModel */
        /** @typedef {import('presentation/messages/facades/conversationLinksFacade')} ConversationLinksFacade */

        /** @type {ConversationLinksFacade}*/
        const self = this;

        const _webMessagingDataProvider = new WebMessagingDataProvider();

        /**
         * @param {IMessageUrlPreviewResponse[]} urlPreviews
         * @returns {MessageLinkPreviewCardViewModel[]}
         * */
        const _createAllLinkPreviews = (urlPreviews) => {
            /** @type {MessageLinkPreviewCardViewModel[]}*/
            const newLinks = [];

            for (let urlPreview of urlPreviews) {
                const linkPreviewViewModel = self.createLinkPreviewViewModel(urlPreview);
                newLinks.push(linkPreviewViewModel);
            }

            return newLinks;
        };

        /** @type ConversationLinksFacade["createLinkPreviewViewModel"] */
        self.createLinkPreviewViewModel = (urlPreview) => {
            const linkPreviewViewModel = new MessageLinkPreviewCardViewModel();
            linkPreviewViewModel.activate(urlPreview);
            return linkPreviewViewModel;
        };

        /** @type ConversationLinksFacade["getConversationSmsMessageUrlPreviews"] */
        self.getConversationSmsMessageUrlPreviews = (smsConversationId, take, skip) => {
           return _webMessagingDataProvider.getConversationSmsMessageUrlPreviews({
                smsConversationId: smsConversationId,
                take: take,
                skip: skip
            }).then((data) => !data ? null : _createAllLinkPreviews(data.urlPreviews));
        };

        /** @type ConversationLinksFacade["onSmsConversationMessageForUrlPreviews"] */
        self.onSmsConversationMessageForUrlPreviews = (callback) => {
            _webMessagingDataProvider.onSmsConversationMessageReceived(callback);
            _webMessagingDataProvider.onSmsConversationMessageSent(callback);
            _webMessagingDataProvider.onSmsConversationMessageUpdated(callback);
        };

        /** @type ConversationLinksFacade["dispose"] */
        self.dispose = () => {
            _webMessagingDataProvider.dispose();
        };
    };
});
