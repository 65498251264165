define('constants/userDeviceConstants',[], function() {
    return {
        maximum_devices: 5,
        mobile_app_installation_type: {
            "Android": "Installed via Android",
            "iOS": "Installed via iOS",
            "none": "Not Installed"
        },
    };
});

