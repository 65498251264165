define('presentation/common/routeToModal/presentationObjects/schedulePresentationObject',[],function() {
    return function(){
        var self = this;
        self.scheduleId = null;
        self.name = null;
        self.segments = null;
        self.routeSearchData = null;
    };
});

