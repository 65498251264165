define('presentation/common/validation/commonValidator',['common/promises/promiseFactory'],
    function() {
        return function(viewModel, validationRules, useManualValidation = false) {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const _viewModel = viewModel;

            let _validationRules = validationRules;
            let _allValidationItems = [];
            let _useManualValidation = useManualValidation;

            viewModel.validator = self;

            const setValidationRules = () => {
                if (_validationRules && _validationRules.rules) {
                    _validationRules.rules.forEach((validationRule) => {
                        let validationSettings = {};
                        if (validationRule.field && validationRule.validators) {
                            if (_viewModel[validationRule.field]) {
                                let observable = _viewModel[validationRule.field];
                                validationSettings = {validationRules: validationRule.validators};

                                if (validationRule.triggerFields) {
                                    validationSettings.triggerFields = validationRule.triggerFields.filter((triggerField) => {
                                        if (_viewModel[triggerField]) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    })
                                        .map((triggerField) => {
                                            return _viewModel[triggerField];
                                        });
                                }

                                if (_useManualValidation) {
                                    observable.extend({manualValidation: validationSettings});
                                } else {
                                    observable.extend({lobbyValidation: validationSettings});
                                }
                                _allValidationItems.push(observable.isValid);
                            }
                        } else if (validationRule.array && (validationRule.fields || validationRule.validators)) {
                            if (_viewModel[validationRule.array]) {
                                let observableArray = _viewModel[validationRule.array];
                                if (validationRule.fields) {
                                    validationSettings.fields = validationRule.fields;
                                }

                                if (validationRule.validators) {
                                    validationSettings.validators = validationRule.validators;
                                    validationSettings.triggerFields = validationRule.triggerFields;
                                }

                                observableArray.extend({lobbyValidationArray: validationSettings});
                                _allValidationItems.push(observableArray.isValid);
                            }
                        } else if (validationRule.validationGroup) {
                            if (_viewModel[validationRule.validationGroup]) {
                                let observableGroup = _viewModel[validationRule.validationGroup];
                                validationSettings = {
                                    validationGroupObservables: validationRule.validationGroupFields.map((field) => {
                                        return _viewModel[field];
                                    })
                                };
                                observableGroup.extend({lobbyValidationGroup: validationSettings});
                            }
                        } else if (validationRule.validationGroupKeyUp) {
                            if (_viewModel[validationRule.validationGroupKeyUp]) {
                                const observableForGroup = _viewModel[validationRule.validationGroupKeyUp];
                                validationSettings = {
                                    validationGroupObservables: validationRule.validationGroupFields.map(field => _viewModel[field])
                                };
                                observableForGroup.extend({lobbyValidationGroupKeyUp: validationSettings});
                            }
                        }
                    });
                }
            };

            setValidationRules();

            self.setValidationRules = (validationRules) => {
                _validationRules = validationRules;
                setValidationRules();
            };

            self.useManualValidation = () => {
                _useManualValidation = true;
            };

            self.isValid = ko.computed(() => {
                let hasError = false;
                let hasUnvalidated = false;

                _allValidationItems.forEach((itemIsValid) => {
                    if (itemIsValid() === false) {
                        hasError = true;
                    } else if (itemIsValid() === null) {
                        hasUnvalidated = true;
                    }
                });

                if (hasError) {
                    return false;
                } else if (hasUnvalidated === true) {
                    return null;
                } else {
                    return true;
                }
            });

            // Perform all validations for the view model
            self.validate = () => {
                return _promiseFactory.defer((deferredObject) => {
                    let isValid = true;
                    let itemsWaitingValidation = _validationRules.rules.map((validationRule) => {
                        if (validationRule.field || validationRule.array) {
                            let currentField = null;
                            if (validationRule.field && _viewModel[validationRule.field]) {
                                currentField = _viewModel[validationRule.field];
                            } else if (validationRule.array && _viewModel[validationRule.array]) {
                                currentField = _viewModel[validationRule.array];
                            }

                            if (currentField !== null) {
                                return currentField.validate()
                                    .done((currentFieldIsValid) => {
                                        if (currentFieldIsValid === false) {
                                            isValid = false;
                                        }
                                    })
                                    .fail((error) => {
                                        deferredObject.reject(error);
                                    });
                            }
                        }
                    });

                    $.when.apply(null, itemsWaitingValidation)
                        .done(() => {
                            deferredObject.resolve(isValid);
                        })
                        .fail((error) => {
                            deferredObject.reject(error);
                        });
                });
            };

            self.isStringWithValue = (text) => {
                if(text === null) {
                    return false;
                }
                if (typeof (text) !== 'undefined') {
                    const trimmedText = text.trim();
                    if (trimmedText.length > 0) {
                        return true;
                    }
                }
                return false;
            };

            self.isEmptyString = (text) => {
                if(text === null) {
                    return true;
                }
                if(typeof(text) !== 'undefined') {
                    const trimmedText = text.trim();
                    if(trimmedText.length > 0) {
                        return false;
                    }
                } else {
                    return true;
                }
            };

            self.isNumber = (numeric) => {
                if (typeof (numeric) !== 'undefined') {
                    if (isNaN(numeric) === false) {
                        return true;
                    }
                }
                return false;
            };

            self.isStringWithLength = (text, minLength, maxLength) => {
                if (typeof (text) !== 'undefined') {
                    const trimmedText = text.trim();
                    if ((trimmedText.length >= minLength) && (trimmedText.length <= maxLength)) {
                        return true;
                    }
                }
                return false;
            };

            self.isNumberBetweenValues = (numeric, minValue, maxValue) => {
                if (typeof (numeric) !== 'undefined') {
                    if (isNaN(numeric) === false) {
                        const num = numeric;
                        if ((num >= minValue) && (num <= maxValue)) {
                            return true;
                        }
                    }
                }
                return false;
            };

            self.isAlphanumericValues = (text) => {
                if (typeof (text) !== 'undefined') {
                    const trimmedText = text.trim();
                    return (/^[0-9a-zA-Z]+$/).test(trimmedText);
                }
                return false;
            };

            self.isDate = (date) => {
                if (date instanceof Date && !isNaN(date.valueOf())) {
                    return true;
                } else {
                    return false;
                }
            };

            self.isValidDateString = (/** @type { string } */ dateString) => {
                if (dateString.length !== 10) {
                    return false;
                }

                const dateParts = dateString.split("/");
                if (dateParts.length !== 3) {
                    return false;
                }

                const date = new Date(dateString);
                if (isNaN(date.valueOf())) {
                    return false;
                }

                let month = parseInt(dateParts[0]);
                let day = parseInt(dateParts[1]);
                let year = parseInt(dateParts[2]);
                const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                if (month !== 2) {
                    return day <= daysPerMonth[month - 1];
                } else {
                    const leapYear = ((0 === year % 4) && (0 !== year % 100) || (0 === year % 400));
                    if (leapYear === false && day >= 29) {
                        return false;
                    }

                    if ((leapYear === true) && (day > 29)) {
                        return false;
                    }

                    return true;
                }
            };

            self.isUnique = (itemToTest, otherItems, idProperty, valueProperty) => {
                const itemToTestValue = ko.unwrap(itemToTest[valueProperty]).trim().toLowerCase();
                if (itemToTestValue === "") {
                    return true;
                }

                const itemToTestId = ko.unwrap(itemToTest[idProperty]);

                return ko.unwrap(otherItems).every(otherItem => {
                    const otherItemValue = ko.unwrap(otherItem[valueProperty]).trim().toLowerCase();

                    return itemToTestValue !== otherItemValue ||
                        itemToTestId === ko.unwrap(otherItem[idProperty]);
                });
            };

            self.areAllValidatorsValid = (validators) => {
                return _promiseFactory.defer((allValidPromise) => {
                    const checkAllValidatorsPromiseFactory = new PromiseFactoryConstructor();
                    let isValid = true;
                    validators.forEach((validator) => {
                        checkAllValidatorsPromiseFactory.defer((promise) => {
                            validator.validate()
                                .fail(promise.reject)
                                .done((isThisValidatorValid) => {
                                    if (isThisValidatorValid === false) {
                                        // Signal that at least one is invalid
                                        isValid = false;
                                    }
                                    promise.resolve(isThisValidatorValid);
                                });
                        });
                    });

                    checkAllValidatorsPromiseFactory.wait()
                        .fail(allValidPromise.reject)
                        .done(() => {
                            allValidPromise.resolve(isValid);
                        });
                });
            };
    };
});

