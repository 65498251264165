define('presentation/notFound/viewModels/notFoundViewModel',[
    'businessServices/router/router',
],
function () {
    return function () {
        const self = this;

        const _router = require('businessServices/router/router');

        self.onBackClick = () => {
            _router.navigateBack();
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {};
    };
});
