define('presentation/settings/userGroup/facades/userGroupProfileFacade',['businessServices/pathProviders/userAvatarUrlPathProvider',
        'constants/userGroupConstants',
        'presentation/common/userAvatar/userAvatarImageFactory',
        'presentation/settings/userGroup/presentationObjects/userGroupPresentationObject',
        'persistence/webSocket/webSocketApp',
        'externalDependencies/clientWebSocket'
], function () {
    const UserGroupPresentationObjectConstructor = require('presentation/settings/userGroup/presentationObjects/userGroupPresentationObject');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _extensionWebSocket = clientWebSocket.forApplication("extension");

    let _promiseFactory = null;
    let _webSocketApp = null;
    let _webSocketValidator = null;
    let _userAvatarImageFactory = null;
    let _userAvatarPathProvider = null;

    const _userGroupConstants = require('constants/userGroupConstants');

    const _formatDesiredMembersForSave = (members) => {
        return members.map((member) => {
            return {userId: member.id};
        });
    };

    const _buildMembers = (userGroupId, userId, userGroupMembers) => {
        return _promiseFactory.defer((promise) => {
            const buildMemberPromiseFactory = new PromiseFactoryConstructor();
            userGroupMembers.forEach((member) => {
                buildMemberPromiseFactory.defer((avatarDeferredObject) => {
                    _userAvatarImageFactory.getAvatarByUserId(member.subscriberId)
                        .fail(promise.reject)
                        .done((userAvatar) => {
                            member.avatar = userAvatar;
                            avatarDeferredObject.resolve();
                        });
                });
            });
            buildMemberPromiseFactory.wait()
                .fail(promise.reject)
                .done(() => {
                    promise.resolve(userGroupMembers);
                });
        });
    };

    const _getUserGroup = (userGroupId, userId) => {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("get_user_group", {userGroupId: userGroupId}, (userGroup) => {
                const userGroupPresentationObject = new UserGroupPresentationObjectConstructor();
                userGroupPresentationObject.userGroupId = userGroup.groupId;
                userGroupPresentationObject.groupName = userGroup.groupName;
                userGroupPresentationObject.extension = userGroup.extension;
                userGroupPresentationObject.members = userGroup.members;
                _buildMembers(userGroupId, userId, userGroup.availableMembers)
                    .fail(promise.reject)
                    .done((userGroupMembers) => {
                        userGroupPresentationObject.availableMembers = userGroupMembers;
                        if (userGroup.groupId === _userGroupConstants.allUserGroupId || userGroup.groupId === _userGroupConstants.billingAdminsGroupId || userGroup.groupId === _userGroupConstants.systemAdminsGroupId) {
                            userGroupPresentationObject.isGroupNameEnabled = false;
                        }
                        if (userGroup.groupId === _userGroupConstants.allUserGroupId) {
                            userGroupPresentationObject.isMembershipSelectionEnabled = true;
                        }

                        promise.resolve(userGroupPresentationObject);
                    });
            });
        });
    };

    const _addUserGroup = (groupName, extension, members) => {
        return _promiseFactory.defer((promise) => {
            let formattedMembers = _formatDesiredMembersForSave(members);

            const request = {
                groupName: groupName,
                extension: extension,
                members: formattedMembers
            };
            _webSocketApp.send("create_user_group", request, (userGroup) => {
                promise.resolve(userGroup);
            });
        });
    };

    const _updateUserGroup = (groupId, groupName, extension, members) => {
        return _promiseFactory.defer((promise) => {
            let formattedMembers = _formatDesiredMembersForSave(members);

            const request = {
                groupId: groupId,
                groupName: groupName,
                extension: extension,
                members: formattedMembers
            };
            _webSocketApp.send("update_user_group", request, (result) => {
                _userAvatarPathProvider.expireUserGroupAvatars(groupId);
                promise.resolve(result);
            });
        });
    };

    const _isUserGroupNameUnique = (userGroupId, userGroupName) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                groupId: userGroupId,
                groupName: userGroupName
            };
            _webSocketApp.send("is_user_group_name_unique", request, (result) => {
                promise.resolve(result.isUserGroupNameUnique);
            });
        });
    };

    const _isExtensionUnique = (userGroupId, extension) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                documentId: userGroupId,
                extension: extension,
                type: "lobby_user_group"
            };
            _webSocketValidator.send("is_extension_unique", request, (result) => {
                promise.resolve(result);
            });
        });
    };

    const _getExtensions = () => {
        return _promiseFactory.defer((promise) => {
            _extensionWebSocket.send("listExtensions", {}, (response) => {
                promise.resolve(response.extensions);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_groups_add_edit");

        _webSocketValidator = new WebSocketAppConstructor();
        _webSocketValidator.init("validation");

        const UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
        _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
        _userAvatarImageFactory.init();

        _userAvatarPathProvider = require('businessServices/pathProviders/userAvatarUrlPathProvider');
        _userAvatarPathProvider.init();
    };

    return function () {
        const self = this;

        self.init = _init;
        self.getUserGroup = _getUserGroup;
        self.addUserGroup = _addUserGroup;
        self.updateUserGroup = _updateUserGroup;
        self.isUserGroupNameUnique = _isUserGroupNameUnique;
        self.isExtensionUnique = _isExtensionUnique;
        self.getExtensions = _getExtensions;
    };
});

