define('presentation/contacts/facades/addContactFacade',[
    'businessServices/contacts/contactsStateSingleton',
    'common/promises/promiseFactory'
], function(
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    /** @typedef { import('presentation/contacts/facades/addContactFacade') } AddContactFacade */

    return function() {
        /**@type { AddContactFacade }*/
        const self = this;

        /** @type { (typeof PromiseFactory)["prototype"] } */
        let _promiseFactory = null;

        /** @type AddContactFacade["init"] */
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
        };

        /** @type AddContactFacade["getContactById"] */
        const _getContactById = (/** @type string*/ accountContactId) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _contactsState.getContactById(accountContactId)
                    .fail(deferredObject.reject)
                    .done((/** @type {IContactPresentationObject} */ contactPresentationObject) => {
                        deferredObject.resolve(contactPresentationObject);
                    });
            });
        };

        /** @type AddContactFacade["getDuplicateContacts"] */
        const _getDuplicateContacts = (/** @type {Array<string>} */ phoneNumbers, /** @type {Array<string>} */ emailAddresses) => {
            const result = _contactsState.getDuplicateContacts(phoneNumbers, emailAddresses);
            return Array.from(result);
        };

        /**  @type AddContactFacade["createContact"] */
        const _createContact = (isSharedImport, newContact) => {
            return _promiseFactory.defer((deferredObject) => {
                const createContactRequest = {
                    isSharedImport,
                    contact: newContact
                };
                _contactsState.createContact(createContactRequest)
                    .fail(deferredObject.reject)
                    .done((/** @type { ICreateNewContactResponse } */ response) => {
                        deferredObject.resolve(response);
                    });
            });
        };

        /**  @type AddContactFacade["deleteContact"] */
        const _deleteContact = (accountContactId) => {
            return _promiseFactory.defer((deferredObject) => {
                const deleteContactRequest = {
                    accountContactId
                };
                _contactsState.deleteContact(deleteContactRequest)
                    .fail(deferredObject.reject)
                    .done((/** @type { IDeleteContactResponse } */ response) => {
                        deferredObject.resolve(response);
                    });
            });
        };

        /**  @type AddContactFacade["updateContact"] */
        const _updateContact = (isSharedImport, updateContact) => {
            return _promiseFactory.defer((deferredObject) => {
                const updateContactRequest = {
                    isSharedImport,
                    contact: updateContact
                };
                _contactsState.updateContact(updateContactRequest)
                    .fail(deferredObject.reject)
                    .done((/** @type { IUpdateContactResponse } */ response) => {
                        deferredObject.resolve(response);
                    });
            });
        };


        self.init = _init;
        self.getContactById = _getContactById;
        self.getDuplicateContacts = _getDuplicateContacts;
        self.createContact = _createContact;
        self.deleteContact = _deleteContact;
        self.updateContact = _updateContact;
    };
});
