define('presentation/settings/userGroup/validators/userGroupProfileValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _facade = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("groupName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'userGroupProfile:nameRequired')
                .addValidationI18n(_validateUserGroupNameUnique, 'userGroupProfile:nameNotUnique');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateUserGroupNameUnique = (userGroupName) => {
            return _facade.isUserGroupNameUnique(_viewModel.userGroupId, userGroupName.trim());
        };
    };
});

