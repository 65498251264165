define('presentation/signup/viewModels/signupErrorViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/window/windowControl',
    'plugins/router'
],
function() {
    return function() {
        const self = this;

        let _windowControl = null;
        let _router = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        self.openHomePage = () => {
            _windowControl.openHomePage("_self");
        };

        self.openContactUs = () => {
            _windowControl.openContactUs("_self");
        };

        self.navigateBack = () => {
            _router.navigateBack();
        };

        self.activate = () => {
            _windowControl = require('presentation/common/window/windowControl');
            _router = require('plugins/router');

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

