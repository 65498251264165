define('presentation/settings/userSubscriptions/presentationObjects/userCallRecordingSubscriber',[],function() {
    return function(){
        let self = this;

        self.subscriberId = null;
        self.subscriberType = null;
        self.displayName = null;
        self.avatar = ko.observable();
        self.isUser = null;
        self.isUserGroup = null;
    };
});

