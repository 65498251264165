define('presentation/analytics/viewModels/analyticsViewModel',[
        'businessServices/authentication/sessionAccountInfo',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/scrollEventSources',
        'presentation/analytics/facades/analyticsFacade',
        'moment-timezone',
        'i18next'
    ],
    function(
        /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
        SessionAccountInfo,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('common/storage/commonState') */
        _commonState,
        /** @type typeof import('constants/scrollEventSources') */
        ScrollEventSources,
        /** @type typeof import('presentation/analytics/facades/analyticsFacade') */
        AnalyticsFacade,
        /** @type {import('moment-timezone')} */
        moment,
        /** @type {import('i18next')} */
        _i18n
    ) {
        const _promiseFactory = new PromiseFactory();

        let _hasScreenBeenInitialized = false;
        /** @type {import('presentation/analytics/facades/analyticsFacade')} */
        let _facade = null;
        /** @type {moment.Moment} */
        let _startMoment = null;
        /** @type {moment.Moment} */
        let _endMoment = null;
        const parentViewModel = ko.observable(null);

        const activate = () => {
            _facade = new AnalyticsFacade();
            _facade.init(_promiseFactory);
            return _initialize();
        };

        const _setChartGroupingOption = (groupingOption) => {
            if (groupingOption.isDisabled() === false) {
                analyticsHeader.selectedOption(groupingOption);
            }
        };

        const _setStatsHeaderOption = (/** @type {IHeaderOption} */statsHeaderOption) => {
            statsHeader.selectedOption(statsHeaderOption);
        };

        const _downloadCsv = (/** @type {string} */csvString, /** @type {string} */ fileName) => {
            const pom = document.createElement('a');
            pom.setAttribute('href', `data:text/csv;charset=utf-8,` + encodeURIComponent(csvString));
            pom.setAttribute('download', `tresta-${fileName}.csv`);
            pom.dispatchEvent(new MouseEvent(`click`));
            pom.remove();
        };

        const _getCallsCsv = () => {
            return _promiseFactory.deferIndefinitely(promise => {
                if (_gettingCallsCsv()) {
                    promise.resolve();
                }

                _gettingCallsCsv(true);

                _facade.getTimeZoneForUser()
                    .fail(() => {
                        showLoadingState(false);
                        showEmptyState(true);
                        promise.reject(new Error(""));
                    })
                    .done(timeZoneIanaCode => {
                        const sidebarPane = parentViewModel().sidebarPane();
                        /** @type {SelectedCallType} */
                        const callType = sidebarPane.selectedCallTypeFilter();
                        const duringValue = sidebarPane.selectedDuringFilter();
                        const {startMoment, endMoment} = getStartEndMoments(duringValue, timeZoneIanaCode, sidebarPane);

                        const filterOnSystemObjects = sidebarPane.selectedFromToItems();
                        const filterOnHostedNumbers = sidebarPane.selectedPhoneNumbers();

                        let endDate;
                        if (startMoment.isSame(new Date(), "day")) {
                            endDate = ``;
                        }
                        else {
                            endDate = `-${endMoment.format("YYYYMMDD")}`;
                        }

                        const fileName = `calls-${startMoment.format("YYYYMMDD")}${endDate}`;

                        _facade.getCallsCsv(startMoment, endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects)
                            .fail(promise.reject)
                            .done(analyticsListData => {
                                _gettingCallsCsv(false);
                                _downloadCsv(analyticsListData, fileName);
                                promise.resolve();
                            });
                    });
            });
        };

        const _getStatsCsv = () => {
            return _promiseFactory.deferIndefinitely(promise => {
                if (_gettingStatsCsv()) {
                    promise.resolve();
                }

                _gettingStatsCsv(true);
                const selectedOption = statsHeader.selectedOption().label;

                /** @type {number} */
                let filter;
                switch (selectedOption) {
                    case _i18n.t('analyticsSummary:phoneNumber'):
                        filter = 0;
                        break;
                    case _i18n.t('analyticsSummary:user'):
                        filter = 1;
                        break;
                    case _i18n.t('analyticsSummary:group'):
                        filter = 2;
                        break;
                    case  _i18n.t('analyticsSummary:forwardingNumber'):
                        filter = 3;
                        break;
                    default:
                        filter = 0;
                        break;
                }

                _facade.getTimeZoneForUser()
                    .fail(() => {
                        showLoadingState(false);
                        showEmptyState(true);
                        promise.reject(new Error(""));
                    })
                    .done(timeZoneIanaCode => {
                        const sidebarPane = parentViewModel().sidebarPane();
                        /** @type {SelectedCallType} */
                        const callType = sidebarPane.selectedCallTypeFilter();
                        const duringValue = sidebarPane.selectedDuringFilter();
                        const filterOnSystemObjects = sidebarPane.selectedFromToItems();
                        const filterOnHostedNumbers = sidebarPane.selectedPhoneNumbers();
                        const selectedShowFilter = sidebarPane.selectedShowFilter();
                        const {startMoment, endMoment} = getStartEndMoments(duringValue, timeZoneIanaCode, sidebarPane);

                        let endDate;
                        if (startMoment.isSame(new Date(), "day")) {
                            endDate = ``;
                        }
                        else {
                            endDate = `-${endMoment.format("YYYYMMDD")}`;
                        }

                        const statsType = selectedShowFilter === "calls" ? "counts" : "durations";
                        const fileName = `stats-${statsType}-${selectedOption.replaceAll(' ', '').toLowerCase()}-${startMoment.format("YYYYMMDD")}${endDate}`;

                        _facade.getStatsCsv(startMoment, endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects, filter, selectedShowFilter)
                            .fail(promise.reject)
                            .done(analyticsListData => {
                                _gettingStatsCsv(false);
                                _downloadCsv(analyticsListData, fileName);
                                promise.resolve();
                            });
                    });
            });
        };

        /** @type {IChartData} */
        const chartData = {
            searchByFilter: ko.observable(),
            startMoment: ko.observable(),
            endMoment: ko.observable(),
            callRecords: ko.observableArray([]),
            graphData: ko.observableArray([]),
            summaryData: ko.observableArray([]),
            summaryTotalData: ko.observableArray()
        };

        const scrollEventSource = ScrollEventSources.analytics;

        const analyticsHeader = {
            title: _i18n.t('analytics:analytics'),
            selectedOption: ko.observable({ label: _i18n.t('analytics:week'), value: 'week', isDisabled: ko.observable(false) }),
            clickHandler: _setChartGroupingOption,
            hasBottomBorder: true,
            onDownload: null,
            options: [
                { label: _i18n.t('analytics:hour'), value: 'hour', isDisabled: ko.observable(false) },
                { label: _i18n.t('analytics:day'), value: 'day', isDisabled: ko.observable(true) },
                { label: _i18n.t('analytics:week'), value: 'week', isDisabled: ko.observable(true) },
                { label: _i18n.t('analytics:month'), value: 'month', isDisabled: ko.observable(true) }
            ]
        };

        const statsHeader = {
            title: _i18n.t('analytics:stats'),
            selectedOption: ko.observable({ label: _i18n.t('analyticsSummary:phoneNumber'), value: _i18n.t('analyticsSummary:phoneNumber'), type: _commonState.types.hostedNumber }),
            clickHandler: _setStatsHeaderOption,
            toolTipIconText: _i18n.t('analyticsSummary:downloadCsv'),
            hasBottomBorder: false,
            onDownload: _getStatsCsv,
            options: [
                { label: _i18n.t('analyticsSummary:phoneNumber'), value: _i18n.t('analyticsSummary:phoneNumber'), type: _commonState.types.hostedNumber },
                { label: _i18n.t('analyticsSummary:user'), value: _i18n.t('analyticsSummary:user'), type: _commonState.types.user },
                { label: _i18n.t('analyticsSummary:group'), value: _i18n.t('analyticsSummary:group'), type: _commonState.types.userGroup },
                { label: _i18n.t('analyticsSummary:forwardingNumber'), value: _i18n.t('analyticsSummary:forwardingNumber'), type: _commonState.types.forwardingNumber }
            ]
        };

        const callsHeader = {
            title: _i18n.t('analytics:calls'),
            selectedOption: ko.observable(''),
            clickHandler: () => {},
            toolTipIconText: _i18n.t('analyticsSummary:downloadCsv'),
            hasBottomBorder: false,
            onDownload: _getCallsCsv,
            options: []
        };

        const showLoadingState = ko.observable(true);
        const showEmptyState = ko.observable(false);

        const showSystemAdminWarningMessage = () => !SessionAccountInfo.isSystemAdmin();
        const _gettingCallRecords = ko.observable(false);
        const _gettingCallsCsv = ko.observable(false);
        const _gettingStatsCsv = ko.observable(false);
        const allCallRecordsLoaded = ko.observable(false);

        const getCallRecords = () => {

            return _promiseFactory.deferIndefinitely(promise => {
                if (_gettingCallRecords()) {
                    promise.resolve();
                }

                _gettingCallRecords(true);

                const sidebarPane = parentViewModel().sidebarPane();
                /** @type {SelectedCallType} */
                const callType = sidebarPane.selectedCallTypeFilter();

                const filterOnSystemObjects = sidebarPane.selectedFromToItems();
                const filterOnHostedNumbers = sidebarPane.selectedPhoneNumbers();

                _facade.getCallRecords(_startMoment, _endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects)
                    .fail(promise.reject)
                    .done(analyticsListData => {
                        chartData.callRecords(analyticsListData);
                        allCallRecordsLoaded(true);
                        promise.resolve();
                    });
            });
        };

        const performSearch = () => {
            return _promiseFactory.deferIndefinitely(promise => {
                const artificialDelayEndTime = 500 + new Date().getTime();
                showLoadingState(true);  // is used by child views
                showEmptyState(false);
                _gettingCallRecords(false);

                const sidebarPane = parentViewModel().sidebarPane();
                const duringValue = sidebarPane.selectedDuringFilter();
                /** @type {SelectedCallType} */
                const callType = sidebarPane.selectedCallTypeFilter();

                chartData.searchByFilter = sidebarPane.selectedShowFilter;
                chartData.callRecords([]);
                chartData.summaryData([]);

                /** @type {ISelectedFromToItem[]} */
                const filterOnSystemObjects = sidebarPane.selectedFromToItems();
                /** @type {ISelectedPhoneNumber[]} */
                const filterOnHostedNumbers = sidebarPane.selectedPhoneNumbers();

                _facade.getTimeZoneForUser()
                    .fail(() => {
                        showLoadingState(false);
                        showEmptyState(true);
                        promise.reject(new Error(""));
                    })
                    .done(timeZoneIanaCode => {
                        const {startMoment, endMoment} = getStartEndMoments(duringValue, timeZoneIanaCode, sidebarPane);
                        _startMoment = startMoment;
                        _endMoment = endMoment;
                        chartData.startMoment(_startMoment);
                        chartData.endMoment(_endMoment);
                        chartData.graphData([]);

                        _facade.getDataForCallChart(_startMoment, _endMoment, callType, filterOnHostedNumbers, filterOnSystemObjects)
                            .fail(() => {
                                showLoadingState(false);
                                showEmptyState(true);
                                promise.reject(new Error(""));
                            })
                            .done(analyticsData => {
                                setTimeout(() => {
                                    chartData.callRecords(analyticsData.list);
                                    chartData.graphData(analyticsData.graph);
                                    chartData.summaryData(analyticsData.summary);
                                    chartData.summaryTotalData(analyticsData.totals);
                                    allCallRecordsLoaded(analyticsData.isAllCallRecords);
                                    showLoadingState(false);
                                    showEmptyState(analyticsData.graph.length < 1);
                                    promise.resolve();
                                }, artificialDelayEndTime - new Date().getTime());
                            });

                    });
            });
        };

        /**
         * @param {string} duringValue
         * @param {string} timeZone
         * @param {any} sidebarPane
         * @returns {{ startMoment: moment.Moment, endMoment: moment.Moment }}
         */
        const getStartEndMoments = (duringValue, timeZone, sidebarPane) => {
            let startMoment = moment.tz(timeZone);
            let endMoment = startMoment.clone();
            switch (duringValue) {
                case 'today':
                    startMoment.startOf('day');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'this_week':
                    startMoment.startOf('week');
                    endMoment.startOf('week').add(1, 'weeks');
                    break;
                case 'this_month':
                    startMoment.startOf('month');
                    endMoment.startOf('month').add(1, 'months');
                    break;
                case 'this_quarter':
                    startMoment.startOf('quarter');
                    endMoment.startOf('quarter').add(1, 'quarter');
                    break;
                case 'this_year':
                    startMoment.startOf('year');
                    endMoment.startOf('year').add(1, 'years');
                    break;
                case 'yesterday':
                    startMoment.startOf('day').subtract(1, 'days');
                    endMoment.startOf('day');
                    break;
                case 'last_week':
                    startMoment.startOf('week').subtract(7, 'days');
                    endMoment.startOf('week');
                    break;
                case 'last_month':
                    startMoment.startOf('month').subtract(1, 'months');
                    endMoment.startOf('month');
                    break;
                case 'last_quarter':
                    startMoment.startOf('quarter').subtract(1, 'quarters');
                    endMoment.startOf('quarter');
                    break;
                case 'last_year':
                    startMoment.startOf('year').subtract(1, 'years');
                    endMoment.startOf('year');
                    break;
                case 'month_to_date':
                    startMoment.startOf('month');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'quarter_to_date':
                    startMoment.startOf('quarter');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'year_to_date':
                    startMoment.startOf('year');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_7':
                    startMoment.startOf('day').subtract(6, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_14':
                    startMoment.startOf('day').subtract(13, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_30':
                    startMoment.startOf('day').subtract(29, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_60':
                    startMoment.startOf('day').subtract(59, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_days_90':
                    startMoment.startOf('day').subtract(89, 'days');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'last_months_12':
                    startMoment.startOf('day').subtract(12, 'months');
                    endMoment.startOf('day').add(1, 'days');
                    break;
                case 'custom': {
                    const customStartDateTimeText = sidebarPane.duringCustomStartOn();
                    const customStartDateTime = new Date(customStartDateTimeText);
                    startMoment = moment.tz(customStartDateTime, timeZone);

                    const customEndDateTimeText = sidebarPane.duringCustomEndOn();
                    const customEndDateTime = new Date(customEndDateTimeText);
                    endMoment = moment.tz(customEndDateTime, "MM:DD:YYYY hh:mm a", timeZone);
                }
                    break;
            }

            return {startMoment, endMoment};
        };

        const _initialize = () => {
            if (_hasScreenBeenInitialized) {
                return _promiseFactory.wait();
            }

            _hasScreenBeenInitialized = true;

            return _promiseFactory.wait();
        };

        const onLogin = () => {
            // Nothing to do
        };

        const onLogout = () => {
            _hasScreenBeenInitialized = false;
        };

        return {
            scrollEventSource,

            analyticsHeader,
            statsHeader,
            callsHeader,
            alerts: {
                receiveAlertLoginSuccessful: onLogin,
                receiveAlertLogout: onLogout,
            },
            parentViewModel,
            activate,
            allCallRecordsLoaded,
            chartData,
            getCallRecords,
            showEmptyState,
            showLoadingState,
            showSystemAdminWarningMessage,
            performSearch,
        };
    });


