define('presentation/messages/viewModels/conversationParticipantCardViewModel',[
    'businessServices/blocking/blockingStateSingleton',
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/router/router',
    'common/converters/phoneNumberFormatter',
    'i18next',
    'settings/navigationConfiguration'
], function (
    /** @type import('businessServices/blocking/blockingStateSingleton') */
    _blockingState,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('businessServices/router/router') */
    _router,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type import('i18next') */
    i18n,
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration
) {
    return function() {
        const self = this;

        const _phoneNumberFormatter = new PhoneNumberFormatter();

        /** @type {IRecipient} */
        self.participant = null;
        self.displayName = ko.observable('');
        self.displayMetaText = ko.observable('');
        self.isContact = ko.observable(false);
        self.contact = ko.observable(null);
        self.showMenu = ko.observable(false);
        self.isHoveringOver = ko.observable(false);
        self.onMouseEnter = () => {
            self.isHoveringOver(true);
        };

        self.onMouseLeave = () => {
            self.isHoveringOver(false);
        };
        self.onMenuButtonClick = () => {
            self.showMenu(!self.showMenu());
        };

        self.iconName = ko.pureComputed(() => {
            if (self.participant && self.participant.isSender) {
                return 'phoneNumber';
            }

            return 'contact';
        });

        self.isBlockedContact = ko.pureComputed(() => {
            if (!self.participant) {
                return false;
            }

            const blockedPhoneNumbers = _blockingState.blockedPhoneNumbers();
            return blockedPhoneNumbers.some(x => x.phoneNumber === _phoneNumberFormatter.toEOneSixFour(self.participant.phoneNumber));
        });

        self.createDraftConversation = null;

        self.navigateToContact = () => {
            if (self.contact()) {
                const contactId = self.contact().accountContactId;
                const contactUrl = `${_navigationConfiguration.routesById.contact.baseUrl}/${contactId}`;
                _router.navigate(contactUrl);
            }
        };

        self.navigateToAddContact = () => {
            const phoneNumber = _phoneNumberFormatter.removeNonNumericalAndLeadingOne(self.participant.phoneNumber);
            const addContactURL = `${_navigationConfiguration.routesById.addContact.baseUrl}/${phoneNumber}`;
            _router.navigate(addContactURL);
        };

        self.comingSoon = i18n.t("conversationParticipantCard:comingSoon");
        self.blockedIconTooltip = i18n.t("messages:blocked");
        self.blockedActionTooltip = i18n.t("messages:blockedContact");

        /**
         * @typedef {{
         *  hostedPhoneNumber?: string;
         *  phoneNumber: string;
         *  location: string;
         *  isSender: boolean;
         *  longitude: number;
         *  latitude: number;
         * }} Participant
         * */

        self.activate = (/** @type { { participant: Participant, createDraftConversation: (recipients: string[]) => JQueryDeferred<string>  } } */activationData) => {
            const { participant, createDraftConversation } = activationData;
            self.participant = participant;

            if (participant){
                const contact = _contactsState.getContactByPhoneNumber(participant.phoneNumber);
                if (contact) {
                    self.isContact(true);
                    self.contact(contact);
                    self.displayName = contact.displayName;

                    const matchingNumber = contact.phoneNumbers().find(x => x.phoneNumber() === participant.phoneNumber);
                    const contactLocation = matchingNumber ? matchingNumber.label() : '';
                    self.displayMetaText(contactLocation);
                } else {
                    self.displayName(participant.phoneNumber);
                }
            }

            const phoneNumber = participant.hostedPhoneNumber || participant.phoneNumber;

            self.createDraftConversation = () => {
                return createDraftConversation([ phoneNumber ]);
            };
        };
    };
});
