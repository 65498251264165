define('constants/smsConversationMessageAttachmentStatus',[],() => {
    return Object.freeze({
        // Attachment Received
        Received: 0,

        // Upload URL Generated
        ReadyToUpload: 4,

        // Uploaded By User
        Uploaded: 5,

        // Uploaded File Too Large
        FileSizeTooLarge: 6,

        // File Conversion in Progress
        ConversionRequested: 3,

        // File Converted
        Converted: 1,

        // File Failed to Convert
        ConversionError: 2,

        // Attachment Upload Cancelled By User
        Cancelled: 7,

        // No Conversion Necessary
        NoConversion: 8,

        // Message was sent without this attachment
        TimedOut: 9
    });
});
