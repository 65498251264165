define('constants/industryEnumerations',[], function() {
    return {
        Agriculture: 1,
        Architecture: 2,
        Arts: 3,
        Beauty: 4,
        Business: 5,
        Computers: 6,
        Construction: 7,
        Consulting: 8,
        Creative: 9,
        Daily: 10,
        ECommerce: 11,
        Education: 12,
        Entertainment: 13,
        Gambling: 14,
        Games: 15,
        Government: 16,
        Health: 17,
        Home: 19,
        Insurance: 20,
        Legal: 21,
        Manufacturing: 22,
        Marketing: 23,
        Media: 24,
        Medical: 25,
        Music: 27,
        Non: 28,
        Pharmaceuticals: 29,
        Photo: 30,
        Politics: 31,
        Professional: 32,
        Public: 33,
        Real: 34,
        Recruitment: 35,
        Religion: 36,
        Restaurant: 37,
        Retail: 38,
        Social: 39,
        Software: 40,
        Sports: 41,
        Telecommunications: 42,
        Travel: 43,
        Vitamin: 44,
        Energy: 46,
        Financial: 47,
        Food: 48
    };
});
