define('presentation/settings/messagingCompliance/validators/messagingComplianceProfileTabValidator',[
    'businessServices/converters/textConverter',
    'businessServices/country/countryRegions',
    'businessServices/specifications/validAuthorizedSignatureSpecification',
    'businessServices/specifications/validPostalCodeSpecification',
    'common/collections/enumerationValidator',
    'common/converters/phoneNumberFormatConverter',
    'common/time/date',
    'common/specifications/validEmailAddressSpecification',
    'common/specifications/validUrlAddressSpecification',
    'constants/countryEnumerations',
    'constants/formOfBusinessEnumerations',
    'constants/industryEnumerations',
    'constants/stockExchangeEnumerations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/validPhoneNumberSpecification',
], function(
    /** @type typeof import('businessServices/converters/textConverter') */
    TextConverter,
    /** @type typeof import('businessServices/country/countryRegions') */
    CountryRegionsConstructor,
    /** @type typeof import('businessServices/specifications/validAuthorizedSignatureSpecification') */
    ValidAuthorizedSignatureSpecification,
    /** @type typeof import('businessServices/specifications/validPostalCodeSpecification') */
    ValidPostalCodeSpecification,
    /** @type import('common/collections/enumerationValidator') */
    _enumerationValidator,
    /** @type typeof import('common/converters/phoneNumberFormatConverter') */
    PhoneNumberFormatConverter,
    /** @type typeof import('common/time/date') */
    DateTime,
    /** @type typeof import('common/specifications/validEmailAddressSpecification') */
    ValidEmailAddressSpecification,
    /** @type typeof import('common/specifications/validUrlAddressSpecification') */
    ValidUrlAddressSpecification,
    /** @type typeof import('constants/countryEnumerations') */
    _countries,
    /** @type typeof import('constants/formOfBusinessEnumerations') */
    _formOfBusiness,
    /** @type typeof import('constants/industryEnumerations') */
    _industries,
    /** @type typeof import('constants/stockExchangeEnumerations') */
    _stockExchanges,
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules,
    /** @type typeof import('presentation/common/validation/validPhoneNumberSpecification') */
    ValidPhoneNumberSpecification
) {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/viewModels/messagingComplianceProfileTabViewModel') } MessagingComplianceProfileTabViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        const _validEmailAddressSpecification = new ValidEmailAddressSpecification();
        const _validPhoneNumberSpecification = new ValidPhoneNumberSpecification();
        const _validPostalCodeSpecification = new ValidPostalCodeSpecification();
        const _validUrlAddressSpecification = new ValidUrlAddressSpecification();

        const _countryRegions = new CountryRegionsConstructor();
        const _phoneNumberFormatConverter = new PhoneNumberFormatConverter();
        const _textConverter = new TextConverter();

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { MessagingComplianceProfileTabViewModel } */
        let _viewModel = null;

        self.registerViewModel = (/** @type { MessagingComplianceProfileTabViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.field("formOfBusiness")
                .addValidationI18n(_validateFormOfBusinessRequired, 'messagingComplianceProfileTab:formOfBusinessRequired');
            validationRules.field("accountHolderFirstName")
                .addValidationI18n(_validateAccountHolderName, 'messagingComplianceProfileTab:firstNameRequired');
            validationRules.field("accountHolderLastName")
                .addValidationI18n(_validateAccountHolderName, 'messagingComplianceProfileTab:lastNameRequired');
            validationRules.field("businessName")
                .addValidationI18n(_validateBusinessName, 'messagingComplianceProfileTab:businessNameRequired');
            validationRules.field("brandName")
                .addValidationI18n(_validateBusinessName, 'messagingComplianceProfileTab:brandNameRequired');
            validationRules.field("phoneNumber")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:phoneNumberRequired')
                .addValidationI18n(_isPhoneNumberValid, 'messagingComplianceProfileTab:phoneNumberInvalid');
            validationRules.field("addressLineOne")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:addressRequired');
            validationRules.field("city")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:cityRequired');
            validationRules.field("stateOrProvince")
                .addValidationI18n(_validateStateOrProvince, _stateOrProvinceRequiredI18nKey);
            validationRules.field("zipOrPostalCode")
                .addValidationI18n(_commonValidator.isStringWithValue, _zipOrPostalCodeRequiredI18nKey)
                .addValidationI18n(_validateZipOrPostalCodeInvalid, _zipOrPostalCodeInvalidI18nKey)
                .addValidationI18n(_validateZipOrPostalCodeExists, _zipOrPostalCodeInvalidI18nKey);
            validationRules.field("stateOrProvinceOfIncorporation")
                .addValidationI18n(_validateStateOrProvinceOfIncorporation, _stateOrProvinceOfIncorporationRequiredI18nKey);
            validationRules.field("stockSymbol")
                .addValidationI18n(_validateStockSymbolRequired, 'messagingComplianceProfileTab:stockSymbolRequired');
            validationRules.field("industry")
                .addValidationI18n(_validateIndustryRequired, 'messagingComplianceProfileTab:industryRequired');
            validationRules.field("exchange")
                .addValidationI18n(_validateExchangeRequired, 'messagingComplianceProfileTab:exchangeRequired');
            validationRules.field("einTaxId")
                .addValidationI18n(_validateEinTaxId, 'messagingComplianceProfileTab:einTaxIdRequired')
                .addValidationI18n(_validateEinTaxIdFormatting, 'messagingComplianceProfileTab:einTaxIdInvalid');
            validationRules.field("businessNumber")
                .addValidationI18n(_validateBusinessNumber, 'messagingComplianceProfileTab:businessNumberRequired');
            validationRules.field("websiteUrl")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:websiteUrlRequired')
                .addValidationI18n(_validateWebsiteUrl, 'messagingComplianceProfileTab:websiteUrlInvalid');
            validationRules.field("pointOfContactFirstName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:firstNameRequired');
            validationRules.field("pointOfContactLastName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:lastNameRequired');
            validationRules.field("pointOfContactJobTitle")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:jobTitleRequired');
            validationRules.field("pointOfContactEmailAddress")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:emailAddressRequired')
                .addValidationI18n(_validEmailAddressSpecification.isSatisfiedBy, 'messagingComplianceProfileTab:emailAddressInvalid');
            validationRules.field("pointOfContactPhoneNumber")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:phoneNumberRequired')
                .addValidationI18n(_isPhoneNumberValid, 'messagingComplianceProfileTab:phoneNumberInvalid')
                .addValidationI18n(_isAllowedPhoneNumber, 'messagingComplianceProfileTab:phoneNumberInvalid');
            validationRules.field("pointOfContactAddressLineOne")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:addressRequired');
            validationRules.field("pointOfContactCity")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceProfileTab:cityRequired');
            validationRules.field("pointOfContactStateOrProvince")
                .addValidationI18n(_validatePointOfContactStateOrProvince, _pointOfContactStateOrProvinceRequiredI18nKey);
            validationRules.field("pointOfContactZipOrPostalCode")
                .addValidationI18n(_commonValidator.isStringWithValue, _pointOfContactZipOrPostalCodeRequiredI18nKey)
                .addValidationI18n(_validatePointOfContactZipOrPostalCodeInvalid, _pointOfContactZipOrPostalCodeInvalidI18nKey)
                .addValidationI18n(_validatePointOfContactZipOrPostalCodeExists, _pointOfContactZipOrPostalCodeInvalidI18nKey);
            validationRules.field("authorizedSignature")
                .addValidationI18n(_validateAuthorizedSignature, 'messagingComplianceProfileTab:authorizedSignatureRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        self.resetValidation = () => {
            _viewModel.accountHolderFirstName.resetValidation();
            _viewModel.accountHolderLastName.resetValidation();
            _viewModel.businessName.resetValidation();
            _viewModel.brandName.resetValidation();
            _viewModel.phoneNumber.resetValidation();
            _viewModel.addressLineOne.resetValidation();
            _viewModel.city.resetValidation();
            _viewModel.stateOrProvince.resetValidation();
            _viewModel.zipOrPostalCode.resetValidation();

            _viewModel.formOfBusiness.resetValidation();
            _viewModel.stateOrProvinceOfIncorporation.resetValidation();
            _viewModel.stockSymbol.resetValidation();
            _viewModel.industry.resetValidation();
            _viewModel.exchange.resetValidation();
            _viewModel.einTaxId.resetValidation();
            _viewModel.businessNumber.resetValidation();
            _viewModel.websiteUrl.resetValidation();

            _viewModel.pointOfContactFirstName.resetValidation();
            _viewModel.pointOfContactLastName.resetValidation();
            _viewModel.pointOfContactJobTitle.resetValidation();
            _viewModel.pointOfContactEmailAddress.resetValidation();
            _viewModel.pointOfContactPhoneNumber.resetValidation();
            _viewModel.pointOfContactAddressLineOne.resetValidation();
            _viewModel.pointOfContactCity.resetValidation();
            _viewModel.pointOfContactStateOrProvince.resetValidation();
            _viewModel.pointOfContactZipOrPostalCode.resetValidation();

            _viewModel.authorizedSignature.resetValidation();

        };

        const _isPhoneNumberValid = (/** @type { any } */ phoneNumber) => {
            if (_commonValidator.isStringWithValue(phoneNumber)) {
                const cleanPhoneNumber = _phoneNumberFormatConverter.clean(phoneNumber);
                return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countries.usa);
            }
            return false;
        };

        const _isAllowedPhoneNumber = (/** @type { any } */ phoneNumber) => {
            return _validPhoneNumberSpecification.isAllowedPhoneNumber(phoneNumber);
        };

        const _validateAccountHolderName = (/** @type { any } */ name) => {
            if (_viewModel.isSoleProprietor()) {
                return _commonValidator.isStringWithValue(name);
            }
            return true;
        };

        const _validateBusinessName = (/** @type { any } */ name) => {
            if (!_viewModel.isSoleProprietor()) {
                return _commonValidator.isStringWithValue(name);
            }
            return true;
        };

        const _validateStateOrProvince = (/** @type { any } */ stateOrProvince) => {
            if (_commonValidator.isStringWithValue(stateOrProvince)) {
                return _countryRegions.isValidStateOrProvince(stateOrProvince, _viewModel.country());
            }
            return false;
        };

        const _validateStateOrProvinceOfIncorporation = (/** @type { any } */ stateOrProvince) => {
            if (!_viewModel.isSoleProprietor()) {
                if (_commonValidator.isStringWithValue(stateOrProvince)) {
                    return _countryRegions.isValidStateOrProvince(stateOrProvince, _viewModel.country());
                }
                return false;
            }
            return true;
        };

        const _validateZipOrPostalCodeInvalid = (/** @type { any } */ zipOrPostalCode) => {
            return _validPostalCodeSpecification.isSatisfiedBy(_textConverter.removeAllWhiteSpaces(zipOrPostalCode), _viewModel.country());
        };

        const _validateZipOrPostalCodeExists = (/** @type { any } */ zipOrPostalCode) => {
            return _validPostalCodeSpecification.doesPostalCodeExist(_textConverter.removeAllWhiteSpaces(zipOrPostalCode));
        };

        const _validateFormOfBusinessRequired = (/** @type { any } */ formOfBusiness) => {
            if (_viewModel.isSoleProprietor()) {
                return true;
            }
            if (formOfBusiness === "pleaseSelect") {
                return false;
            }
            return _enumerationValidator.isEnumerationValueValid(_formOfBusiness, parseInt(formOfBusiness));
        };

        const _validateStockSymbolRequired = (/** @type { any } */ stockSymbol) => {
            if (_viewModel.showStockSymbolExchange()) {
                return _commonValidator.isStringWithValue(stockSymbol);
            }
            return true;
        };

        const _validateIndustryRequired = (/** @type { any } */ industry) => {
            if (industry === "pleaseSelect") {
                return false;
            }
            return _enumerationValidator.isEnumerationValueValid(_industries, parseInt(industry));
        };

        const _validateExchangeRequired = (/** @type { any } */ exchange) => {
            if (_viewModel.showStockSymbolExchange()) {
                return _enumerationValidator.isEnumerationValueValid(_stockExchanges, parseInt(exchange));
            }
            return true;
        };

        const _validateEinTaxId = (/** @type { any } */ einTaxId) => {
            if (_viewModel.isSoleProprietor() || _viewModel.country() === _countries.ca) {
                return true;
            }
            return _commonValidator.isStringWithValue(einTaxId);
        };

        const _validateEinTaxIdFormatting = (/** @type { any } */ einTaxId) => {
            if (_viewModel.isSoleProprietor() || _viewModel.country() === _countries.ca) {
                return true;
            }
            if (_commonValidator.isStringWithValue(einTaxId)) {
                const EIN_REGEX = /^\d{9}$/;
                return EIN_REGEX.test(einTaxId);
            }
            return true;
        };

        const _validateBusinessNumber = (/** @type { any } */ businessNumber) => {
            if (_viewModel.isBrandVerified() || _viewModel.isSoleProprietor() || _viewModel.country() === _countries.usa) {
                return true;
            }
            return _commonValidator.isStringWithValue(businessNumber);
        };

        const _validateWebsiteUrl = (/** @type { any } */ websiteUrl) => {
            if (_commonValidator.isStringWithValue(websiteUrl)) {
                return _validUrlAddressSpecification.isSatisfiedBy(websiteUrl);
            }
            return true;
        };

        const _validatePointOfContactStateOrProvince = (/** @type { any } */ stateOrProvince) => {
            if (_commonValidator.isStringWithValue(stateOrProvince)) {
                return _countryRegions.isValidStateOrProvince(stateOrProvince, _viewModel.pointOfContactCountry());
            }
            return false;
        };

        const _validatePointOfContactZipOrPostalCodeInvalid = (/** @type { any } */ zipOrPostalCode) => {
            return _validPostalCodeSpecification.isSatisfiedBy(_textConverter.removeAllWhiteSpaces(zipOrPostalCode), _viewModel.pointOfContactCountry());
        };

        const _validatePointOfContactZipOrPostalCodeExists = (/** @type { any } */ zipOrPostalCode) => {
            return _validPostalCodeSpecification.doesPostalCodeExist(_textConverter.removeAllWhiteSpaces(zipOrPostalCode));
        };

        const _validateAuthorizedSignature = (/** @type { any } */ authorizedSignature) => {
            if (_viewModel.isEditMode()) {
                return true;
            }
            return _commonValidator.isStringWithValue(authorizedSignature);
        };

        const _stateOrProvinceRequiredI18nKey = ko.pureComputed(() => {
            return _viewModel.country() === _countries.ca ?
                'messagingComplianceProfileTab:provinceRequired' :
                'messagingComplianceProfileTab:stateRequired';
        });

        const _stateOrProvinceOfIncorporationRequiredI18nKey = ko.pureComputed(() => {
            return _viewModel.country() === _countries.ca ?
                'messagingComplianceProfileTab:provinceOfIncorporationRequired' :
                'messagingComplianceProfileTab:stateOfIncorporationRequired';
        });

        const _zipOrPostalCodeRequiredI18nKey = ko.pureComputed(() => {
            return _viewModel.country() === _countries.ca ?
                'messagingComplianceProfileTab:postalCodeRequired' :
                'messagingComplianceProfileTab:zipCodeRequired';
        });

        const _zipOrPostalCodeInvalidI18nKey = ko.pureComputed(() => {
            return _viewModel.country() === _countries.ca ?
                'messagingComplianceProfileTab:postalCodeInvalid' :
                'messagingComplianceProfileTab:zipCodeInvalid';
        });

        const _pointOfContactStateOrProvinceRequiredI18nKey = ko.pureComputed(() => {
            return _viewModel.pointOfContactCountry() === _countries.ca ?
                'messagingComplianceProfileTab:provinceRequired' :
                'messagingComplianceProfileTab:stateRequired';
        });

        const _pointOfContactZipOrPostalCodeRequiredI18nKey = ko.pureComputed(() => {
            return _viewModel.pointOfContactCountry() === _countries.ca ?
                'messagingComplianceProfileTab:postalCodeRequired' :
                'messagingComplianceProfileTab:zipCodeRequired';
        });

        const _pointOfContactZipOrPostalCodeInvalidI18nKey = ko.pureComputed(() => {
            return _viewModel.pointOfContactCountry() === _countries.ca ?
                'messagingComplianceProfileTab:postalCodeInvalid' :
                'messagingComplianceProfileTab:zipCodeInvalid';
        });
    };
});

