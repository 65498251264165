/**
 * @module persistence/fileStores/fileManagement/fileReaderResult
 */
define('persistence/fileStores/fileManagement/fileReaderResult',[], function() {
    /**
     * @constructor
     */
    return function() {
        var self = this;

        /**
         * The name of the file
         * @type {string}
         * @public
         */
        self.fileName = null;

        /**
         * The type of html encoding for the file.  This is what can be used to determine how render the document.
         * @type {string}
         * @public
         */
        self.fileEncodingType = null;

        /**
         * The size of the file, in bytes
         * @type {integer}
         * @public
         */
        self.fileSize = null;

        /**
         * The content of the file in standard UTF8 encoding (0-255 bytes)
         * @type {string}
         * @public
         */
        self.fileContent = null;
    };
});

