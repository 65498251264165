define('presentation/common/analyticsGraph/analyticsGraphBinding',[
    'durandal/composition',
    'businessServices/events/eventManager'
],
    function (
        /** @type import('durandal/composition') */
        composition,
        /** @type import('businessServices/events/eventManager') */
        eventManager
) {
        return function (/** @type {KnockoutStatic} */ko) {
            //Adding custom position config so the tooltips' carets point at the x/y axis intersection
            //(mode "custom" in chart.options.tooltips.position in analyticsGraphChart binding)
            Chart.Tooltip.positioners.custom = (elements) => {
                if (elements.length < 1) {
                    return;
                }

                const {x, y} = elements[0]._model;
                const distanceToTooltipCaretX = 10;
                const distanceToTooltipCaretY = 5;

                return {
                    x: x + distanceToTooltipCaretX,
                    y: y - distanceToTooltipCaretY
                };
            };

            // this cannot be anonymous, needs to access correct .this context
            function _buildCustomTooltip(/** @type {Chart.ChartTooltipModel} */tooltipModel) {
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = _createTooltipElement();
                    document.body.appendChild(tooltipEl);
                }

                tooltipEl.classList.add("analytics-chart__tooltip-caret--above");

                // Hide if no tooltip (0 opacity is a hidden tooltip)
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set Text
                if (tooltipModel.body) {
                    let innerHtml = _buildTooltipInnerHtml(tooltipModel);
                    let tooltipRoot = tooltipEl.querySelector('.analytics-chart__tooltip-caret');
                    tooltipRoot.innerHTML = innerHtml;
                }

                // `this` refers to the overall tooltip
                _setTooltipWrapperStyle(this._chart.canvas, tooltipEl, tooltipModel);
            }

            const _createTooltipElement = () => {
                const tooltipElement = document.createElement('div');
                tooltipElement.id = 'chartjs-tooltip';
                tooltipElement.innerHTML = '<div class="analytics-chart__tooltip-caret"></div>';
                return tooltipElement;
            };

            const _buildTooltipInnerHtml = (/** @type {Chart.ChartTooltipModel} */tooltipModel) => {
                let titleLines = tooltipModel.title || [];
                let bodyLines = tooltipModel.body.map((bodyItem) => bodyItem.lines);
                let innerHtml = '<div class="analytics-chart__tooltip analytics-chart__tooltip--above">';

                titleLines.forEach((title) => {
                    innerHtml += `<div class="analytics-chart__tooltip-header-text">${title}</div>`;
                });

                innerHtml += '<div class="analytics-chart__tooltip-body">';

                bodyLines.forEach((body, i) => {
                    if (i === 2) {
                        innerHtml += '<div class="analytics-chart__tooltip-divider"></div>';
                    }
                    innerHtml += `<div class="analytics-chart__tooltip-body-text">${body}</div>`;
                });

                innerHtml += '</div>';

                return innerHtml;
            };

            /**
             * 
             * @param {HTMLCanvasElement} chartCanvas 
             * @param {HTMLElement} tooltipElement 
             * @param {Chart.ChartTooltipModel} tooltipModel 
             */
            const _setTooltipWrapperStyle = (chartCanvas, tooltipElement, tooltipModel) => {
                let position = chartCanvas.getBoundingClientRect();
                tooltipElement.style.opacity = '1';
                tooltipElement.style.zIndex = '5'; //$z-index-tooltip;
                tooltipElement.style.position = 'absolute';
                tooltipElement.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipElement.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipElement.style.pointerEvents = 'none';
            };

            const analyticsGraphChart = {
                /** @type {KnockoutBindingHandler<HTMLCanvasElement, KnockoutObservable<Chart>>["init"]} */
                init: (element, valueAccessor) => {
                    let chart = new Chart(element, {
                        type: 'line',
                        options: {
                            layout: {
                                padding: {
                                    top: 10         // Padding is added to the top to account for the size of the hover-circles
                                }
                            },
                            responsive: true,       // let the chart fill the container instead of growing as big as it wants
                            legend: {
                                display: false,    // turn off the default legend, because we're going to build our own
                            },
                            scales: {
                                yAxes: [
                                    {
                                        gridLines: {
                                            borderDash: [2, 4],        // this gives the dashed horizontal lines, 2 pixels of dash followed by 4 pixels of spacing.
                                            borderDashOffset: 2,       // This makes sure that the first dash on the horizontal doesn't touch the vertical 0 line, because that looks weird.
                                            drawTicks: true,           // We want to pretend like we're going to draw ticks to the right of the labels on the Y-axis so that it makes some room for them
                                            tickMarkLength: 0,         // Here we tell the framework to not actually draw the ticks, we'll add our own padding in ticks.callback below
                                            zeroLineColor: '#dedede',  // The bottom line on the chart should be colored $color-secondary-warm-100
                                            drawBorder: false,         // This is the left-most line that normally appears on the grid.
                                        },
                                        ticks: {
                                            beginAtZero: true,           // Make sure 0 is the baseline
                                            callback: function (value, index, values) {
                                                // Add padding to all values that show as labels on the Y-Axis. If we do not do this, then the horizontal line extends to touch the label, which is weird
                                                var tickName = value.toFixed(1);
                                                if (tickName.indexOf(".0") !== -1) {
                                                    return tickName.slice(0, -2) + "      ";
                                                } else {
                                                    // If this is not a whole number, then it should be hidden
                                                    return null;
                                                }
                                            },
                                            fontColor: '#bcbcbc',      // The color of the labels on the y-axis.  $color-secondary-warm-300
                                            fontFamily: '"ProximaNova", "Helvetica", "Arial", sans-serif' // The various font fallbacks to draw the text that appears in the y-axis
                                        }
                                    }
                                ],
                                xAxes: [
                                    {
                                        gridLines: {
                                            display: false,        // we do not want to show any vertical lines for the X-axis
                                        },
                                        ticks: {
                                            fontColor: '#5d6470',  // the color of the labels on the x-axis. $color-secondary-cool-300
                                            fontFamily: '"ProximaNova", "Helvetica", "Arial", sans-serif', // The various font fallbacks to draw the text that appears in the x-axis
                                            callback: function (value, index, values) {
                                                return value.label;
                                            }
                                        }
                                    }
                                ]
                            },
                            tooltips: {
                                enabled: false,
                                mode: "index",        // When the tooltip displays, show data for all lines that correlate to this position on the x-axis
                                position: "custom",
                                intersect: false,     // Don't require that the mouse is exactly on the data point on the line
                                custom: _buildCustomTooltip,
                                callbacks: {
                                    title: (tooltipItems, data) => {
                                        // Gives the full name of the data represented by the current position in the x-axis
                                        const tooltipItem = tooltipItems[0];
                                        return data.labelsTooltip[tooltipItem.index];
                                    },
                                    label: (tooltipItem, data) => {
                                        let tooltipFilterTitle;
                                        switch (data.searchByFilter) {
                                            case "calls":
                                                tooltipFilterTitle = "Calls";
                                                break;
                                            case "minutes":
                                                tooltipFilterTitle = "Minutes";
                                                break;
                                        }

                                        //Convert label's text to correct format, i.e.- "27 Outbound Calls", instead of "Outbound 27"
                                        let itemCount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                        let itemTooltipLabel = data.datasets[tooltipItem.datasetIndex].label;
                                        return itemCount.toString() + " " + itemTooltipLabel + " " + tooltipFilterTitle;
                                    }
                                }
                            },
                            hover: {
                                mode: "point"  // Ensures that only the point you are hovering over has the hover state applied. By default it hovers all points on the x-axis as well
                            }
                        },
                        data: {
                            datasets: [
                                {
                                    label: 'Inbound',
                                    fill: false,
                                    cubicInterpolationMode: 'monotone',
                                    backgroundColor: '#e84c38',            // $color-primary-300
                                    borderColor: '#e84c38',                // $color-primary-300
                                    pointBorderColor: 'white',
                                    pointHoverBackgroundColor: '#e84c38',  // $color-primary-300
                                    pointHoverBorderColor: '#f3c8c4',      // $color-primary-050
                                },
                                {
                                    label: 'Outbound',
                                    backgroundColor: '#5d6470',        // The color of the inside of the line, $color-secondary-cool-300
                                    borderColor: '#5d6470',            // The color of the outside of the line, $color-secondary-cool-300
                                    fill: false,
                                    cubicInterpolationMode: 'monotone', // shapes the curves so that the apex of the curve is at the point
                                    pointBorderColor: 'white',         // we make a white border around the data point
                                    pointHoverBackgroundColor: '#5d6470',  // when hovering, the point will stay $color-secondary-cool-300
                                    pointHoverBorderColor: '#808795',      // when hovering, this is the color of the border that appears around the red dot.  $color-secondary-warm-300;
                                },
                                {
                                    label: 'Total',
                                    backgroundColor: '#d5d5d5',          // $color-secondary-warm-200
                                    borderColor: '#d5d5d5',              //  $color-secondary-warm-200
                                    fill: false,
                                    cubicInterpolationMode: 'monotone',
                                    pointBorderColor: 'white',
                                    pointHoverBackgroundColor: '#d5d5d5', // $color-secondary-warm-200
                                    pointHoverBorderColor: '#dedede',     // $color-secondary-warm-100
                                }
                            ]
                        }
                    });

                    var valueObserver = valueAccessor();
                    valueObserver(chart);
                },
                /** @type {KnockoutBindingHandler<HTMLCanvasElement, KnockoutObservable<Chart>>["update"]} */
                update: (element, valueAccessor) => {
                    let value = valueAccessor();
                    let chartArea = ko.unwrap(value).chartArea;
                    let analyticsScrollbarInstance = $('.analytics .os-content').overlayScrollbars({
                            className: "os-theme-tresta",
                            scrollbars: {
                                autoHide: "leave",
                                autoHideDelay: 100
                            },
                            sizeAutoCapable: false,
                        callbacks: {
                            onScroll: function (event) {
                                const BUFFER = 200;
                                const tooltip = $("#chartjs-tooltip");
                                let scrollTop = event.target.scrollTop;
                                let scrollHeight = event.target.scrollHeight;
                                let offsetHeight = event.target.offsetHeight;
                                let offset = scrollHeight - offsetHeight - scrollTop;
                                let yAxisScrollPosition = analyticsScrollbarInstance.scroll().position.y;

                                if (yAxisScrollPosition > chartArea.bottom) {
                                    tooltip.css("opacity", 0);
                                } else {
                                    tooltip.css("opacity", 1);
                                }

                                if (offset < BUFFER) {
                                    eventManager.publishDataGridGetNextPage();
                                }
                            }
                        }
                    }).overlayScrollbars();
                }
            };

            composition.addBindingHandler(`analyticsGraphChart`, analyticsGraphChart);
        };
    }
);

