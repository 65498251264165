define('presentation/common/window/windowControl',["settings/settings"], function() {
    const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.tresta";
    const IOS_APP_URL = "https://itunes.apple.com/us/app/tresta/id1207868583?mt=8";
    const TCR_URL = "https://www.campaignregistry.com/";

    return {
        openNewWindow: (/** @type { string | URL } */ url, /** @type { string } */ target) => {
            if (target === undefined) {
                target = "_blank";
            }
            window.open(url, target);
        },
        setConfirmationMessageOnNavigateAway: (/** @type { any } */ message) => {
            window.onbeforeunload = () => message;
        },
        clearConfirmationMessageOnNavigateAway: () => {
            window.onbeforeunload = null;
        },
        getBaseUrl: () => window.location.protocol + "//" + window.location.host,
        getCouchBaseUrl: () => {
            const settings = require('settings/settings');
            return settings.couchDatabaseUrl;
        },
        getFrontendBaseUrl: () => {
            const settings = require('settings/settings');
            return window.location.protocol + "//" + settings.frontendDomain;
        },
        get openContactUs() {
            return (/** @type { string } */ target) => {
                const contactUrl = this.getFrontendBaseUrl() + '/contact';
                this.openNewWindow(contactUrl, target);
            };
        },
        get openHomePage() {
            return (/** @type { string } */ target) => {
                const homeUrl = this.getFrontendBaseUrl();
                this.openNewWindow(homeUrl, target);
            };
        },
        get openIosAppUrl() {
            return (/** @type { string } */ target) => {
                this.openNewWindow(IOS_APP_URL, target);
            };
        },
        get openAndroidAppUrl() {
            return (/** @type { string } */ target) => {
                this.openNewWindow(ANDROID_APP_URL, target);
            };
        },
        get openTcrUrl() {
            return (/** @type { string } */ target) => {
                this.openNewWindow(TCR_URL, target);
            };
        },
        get openTermsOfAgreement() {
            return (/** @type { string } */ target) => {
                const termsUrl = this.getFrontendBaseUrl() + '/terms';
                this.openNewWindow(termsUrl, target);
            };
        },
        get openExternalUrl() {
            return (/** @type { string } */ url) => {
                const httpsRegex = new RegExp(/^https:\/\//i); // check for https:// prefixes (case insensitive)

                if (httpsRegex.test(url)) {
                    this.openNewWindow(url);
                    return;
                }

                const httpRegex = new RegExp(/^http:\/\//i); // check for http:// prefixes (case insensitive)

                const formattedUrl = httpRegex.test(url) ? `https://${url.slice(7)}` : `https://${url}`;
                this.openNewWindow(formattedUrl);
            };
        }
    };
});
