define('constants/voicemailConstants',[], function() {
    return {
        defaultGreetingIdUserGroups:  "[Default Greeting]",
        defaultGreetingIdForwardingNumber:  "[Default Greeting]",
        newGreetingId: "+ New Greeting",
        defaultVoicemailGreetingId: "voicemailGreetings-account",
        defaultGreetingId: "default_voicemail_greeting",
        defaultVoicemailGreeting: "global_voicemail_message",
        defaultAnswerTimeout: "30",
        defaultVoicemailSettings: {
            voicemailEnabled: true,
            answerTimeout: "30"
        },
        defaultForwardingNumberVoicemailSettings: {
            voicemailEnabled: false,
            answerTimeout: "30"
        }
    };
});
