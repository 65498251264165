define('presentation/signupNumberPicker/facades/signupNumberPickerFacade',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/numberProvisioning/numberProvisioner',
    'common/promises/promiseFactory',
    'constants/numberPickerConstants',
    'common/converters/phoneNumberFormatter',
    'persistence/webSocket/webSocketApp',
    'presentation/signupNumberPicker/presentationObjects/signupNumberPickerPresentationObject',
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    const NumberPresentationObjectConstructor = require('presentation/signupNumberPicker/presentationObjects/signupNumberPickerPresentationObject');

    const _numberPickerConstants = require('constants/numberPickerConstants');
    const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

    const accountId = _sessionAccountInfo.accountId();
    const signupSessionId = null;

    let _geoLocation = null;
    let _webSocketApp = null;
    let _numberProvisioner = null;
    let _signupSessionStore = null;

    const _getInitial = () => _promiseFactory.deferIndefinitely(promise => {
        _numberProvisioner.getInitial()
            .fail(promise.reject)
            .done(result => {
                promise.resolve(result.data);
            });
    });

    const _formatDefaultResult = ({region, result}) => {
        const status = result.data.status;
        switch (status) {
            case _numberPickerConstants.localStatuses.regionCityNpa: {
                const cityNumbers = result.data.cityNumbers;
                const cityNumber = cityNumbers[0];
                const availableNumbers = cityNumber.numbers.map(phoneNumber => new NumberPresentationObjectConstructor({phoneNumber, region, city: cityNumber.city}));
                return {status, availableNumbers};
            }
            case _numberPickerConstants.localStatuses.regionCity:
            case _numberPickerConstants.localStatuses.npa: {
                const cityNumbers = result.data.cityNumbers;
                const availableOptions = cityNumbers.map(cityNumber => ({
                    availableCity: `${cityNumber.city} (${cityNumber.numbers[0].substring(0, 3)})`,
                    numbers: cityNumber.numbers
                }));
                return {status, availableOptions};
            }
            case _numberPickerConstants.tollFreeStatuses.success:
            case _numberPickerConstants.tollFreeStatuses.alternatePrefix: {
                const availableNumbers = result.data.numbers.map(phoneNumber => new NumberPresentationObjectConstructor({phoneNumber}));
                return {status, availableNumbers};
            }
            default:
                return {status};
        }
    };

    const _findDefaultNumbers = ({region, npaCity, prefix, numberType, requestType}) => _promiseFactory.deferIndefinitely(promise => {
        if (numberType === _numberPickerConstants.numberTypes.local) {
            _numberProvisioner.searchLocalNumbers({region, city: npaCity, requestType, accountId, signupSessionId})
                .fail(promise.reject)
                .done(result => {
                    promise.resolve(_formatDefaultResult({region, result}));
                });
        } else {
            _numberProvisioner.searchTollFreeNumbers({prefix, requestType, accountId, signupSessionId})
                .fail(promise.reject)
                .done(result => {
                    promise.resolve(_formatDefaultResult({result}));
                });
        }
    });

    const _findRepeatingNumbers = ({region, npa, prefix, numberType, requestType}) => _promiseFactory.deferIndefinitely(promise => {
        if (numberType === _numberPickerConstants.numberTypes.local) {
            _numberProvisioner.searchLocalRepeatingNumbers({npa, region, requestType, accountId, signupSessionId})
                .fail(promise.reject)
                .done(result => {
                    const availableNumbers = result.data.numbers.map(
                        phoneNumber => new NumberPresentationObjectConstructor({phoneNumber, region})
                    );
                    promise.resolve({status: result.data.status, availableNumbers});
                });
        } else {
            _numberProvisioner.searchTollFreeRepeatingDigits({prefix, requestType, accountId, signupSessionId})
                .fail(promise.reject)
                .done(result => {
                    const availableNumbers = result.data.numbers.map(
                        phoneNumber => new NumberPresentationObjectConstructor({phoneNumber})
                    );
                    promise.resolve({status: result.data.status, availableNumbers});
                });
        }
    });

    const _findVanityNumbers = ({region, npa, prefix, vanityString, vanityType, numberType, requestType}) => _promiseFactory.deferIndefinitely(promise => {
        const startsWith = vanityType === _numberPickerConstants.vanityTypeSelectConstants.begins;
        const endsWith = vanityType === _numberPickerConstants.vanityTypeSelectConstants.ends;

        if (numberType === _numberPickerConstants.numberTypes.local) {
            _numberProvisioner.searchLocalVanityNumbers({region, npa, vanityString, startsWith, endsWith, requestType, accountId, signupSessionId})
                .fail(promise.reject)
                .done(result => {
                    const availableNumbers = result.data.numbers.map(
                        ({phoneNumber, humanReadablePhoneNumber}) => new NumberPresentationObjectConstructor({phoneNumber, region, humanReadablePhoneNumber})
                    );
                    promise.resolve({status: result.data.status, availableNumbers});
                });
        } else {
            _numberProvisioner.searchTollFreeVanityNumbers({prefix, vanityString, startsWith, endsWith, requestType, accountId, signupSessionId})
                .fail(promise.reject)
                .done(result => {
                    const availableNumbers = result.data.numbers.map(
                        ({phoneNumber, humanReadablePhoneNumber}) => new NumberPresentationObjectConstructor({phoneNumber, humanReadablePhoneNumber})
                    );
                    promise.resolve({status: result.data.status, availableNumbers});
                });
        }
    });

    const _reservePhoneNumber = ({phoneNumberPresentationObject, numberType, searchType, isSignup = false}) => _promiseFactory.deferIndefinitely(promise => {
        const phoneNumber = phoneNumberPresentationObject.e164PhoneNumber();
        const region = phoneNumberPresentationObject.region();
        const city = phoneNumberPresentationObject.city();
        let vanityString = phoneNumberPresentationObject.vanityString() !== '' ?
            phoneNumberPresentationObject.vanityString() :
            null;

        if (isSignup === false) {
            const reserveNumberObject = {
                phoneNumber, region, city, vanityString, searchType,
            };

            _webSocketApp.send("reserve_number", reserveNumberObject, promise.resolve);

        } else if (numberType === _numberPickerConstants.numberTypes.tollFree) {
            _signupSessionStore.reservePhoneNumber({phoneNumber, searchType, vanityString})
                .fail(promise.reject)
                .done(promise.resolve);

        } else if (searchType === _numberPickerConstants.searchTypeEnumeration.default) {
            _signupSessionStore.reservePhoneNumber({phoneNumber, region, city, searchType})
                .fail(promise.reject)
                .done(promise.resolve);
        } else {
            _signupSessionStore.reserveLocalPhoneNumberWithoutLocation({phoneNumber, searchType, vanityString})
                .fail(promise.reject)
                .done(promise.resolve);
        }
    });

    const _cancelReservation = ({phoneNumber, isSignup}) => _promiseFactory.deferIndefinitely(promise => {
        if (isSignup) {
            _signupSessionStore.cancelPhoneNumberReservation(phoneNumber)
                .fail(promise.reject)
                .done(promise.resolve);
        } else {
            _webSocketApp.send("cancel_reservation", {phoneNumber}, promise.resolve);
        }
    });

    const _getGeoLocation = () => _promiseFactory.deferIndefinitely(promise => {
        if (_geoLocation) {
            promise.resolve(_geoLocation);
        } else {
            _numberProvisioner.getInitialGeolocation({accountId: accountId})
                .fail(promise.reject)
                .done((result) => {
                    _geoLocation = result.data;
                    promise.resolve(_geoLocation);
                });
        }
    });

    const _init = () => {
        const NumberProvisionerConstructor = require('businessServices/numberProvisioning/numberProvisioner');
        _numberProvisioner = new NumberProvisionerConstructor();
        _numberProvisioner.init();

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("number_picker");

        const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
        _signupSessionStore = new SignupSessionStoreConstructor();
        _signupSessionStore.init();
    };

    return function() {
        const self = this;

        self.init = _init;

        self.cancelReservation = _cancelReservation;
        self.findDefaultNumbers = _findDefaultNumbers;
        self.findRepeatingNumbers = _findRepeatingNumbers;
        self.findVanityNumbers = _findVanityNumbers;
        self.getGeoLocation = _getGeoLocation;
        self.getInitial = _getInitial;
        self.reservePhoneNumber = _reservePhoneNumber;
    };
});

