define('presentation/inbox/viewModels/inboxCallRecordingsSidebarViewModel',['common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/colorConstants',
        'presentation/inbox/dataSources/recordingSubscriptionDataSource',
        'presentation/inbox/facades/inboxSidebarFacade',
        'presentation/inbox/viewModels/inboxCallRecordingsViewModel',
        'presentation/inbox/viewModels/inboxSidebarHeaderViewModel'
], function () {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const inboxSidebarHeaderViewModel = require('presentation/inbox/viewModels/inboxSidebarHeaderViewModel');

        const _colorConstants = require('constants/colorConstants');
        const _commonState = require('common/storage/commonState');
        const _inboxCallRecordingsViewModel = require('presentation/inbox/viewModels/inboxCallRecordingsViewModel');
        const _recordingSubscriptionDataSource = require('presentation/inbox/dataSources/recordingSubscriptionDataSource');

        let _disposables = [];
        let _facade = null;
        let _hasScreenBeenInitialized = false;

        let _availableSubscriptions = _recordingSubscriptionDataSource.subscriptions;

        let isDataLoaded = ko.observable(false);

        const _initializeScreen = () => {
            inboxSidebarHeaderViewModel.isVoicemailActive(false);
            inboxSidebarHeaderViewModel.searchFilter = searchFilter;

            if (_hasScreenBeenInitialized) {
                return;
            }
            _hasScreenBeenInitialized = true;

            return _promiseFactory.defer((promise) => {
                _initializeFilters()
                    .fail(promise.reject)
                    .done(() => {
                        _initializeSubscriptions()
                            .fail(promise.reject)
                            .done(() => {
                                isDataLoaded(true);
                                _initializeCallRecordings()
                                    .fail(promise.reject)
                                    .done(promise.resolve);
                            });
                    });
            });
        };

        const _initializeFilters = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getAllNewReviewedCallRecordingsFilterState()
                    .fail(promise.reject)
                    .done((newOrReviewed) => {
                        searchFilter('');
                        startOn('');
                        endOn('');
                        selectedShowFilter(newOrReviewed);

                        promise.resolve();
                    });
            });
        };

        const _initializeSubscriptions = () => {
            return _promiseFactory.defer((promise) => {
            _recordingSubscriptionDataSource.waitForDataWaitHandle.waitForSignal(() => {
                    promise.resolve();
                });
            });
        };

        const _initializeCallRecordings = () => {
            return _promiseFactory.defer((promise) => {
                if (isDataLoaded() === false) {
                    promise.resolve();
                    return;
                }

                _inboxCallRecordingsViewModel.refreshCallRecordingListing()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _onShowFilterChanged = () => {
            const status = selectedShowFilter();
            _facade.setAllNewReviewedCallRecordingsFilterStatus(status);

            _onSearchCriteriaChanged();
        };

        const _onSearchCriteriaChanged = () => {
            _initializeCallRecordings();
        };

        const _onSearchTextChanged = () =>  {
            let searchText = searchFilter();
            _inboxCallRecordingsViewModel.searchTextChanged(searchText);
        };

        const clearFilters = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                selectedShowFilter('all');
                selectedTimeframeFilter('');
                startOn('');
                endOn('');
                selectedSubscriptions([]);
                promise.resolve();
            });
        };

        const getSearchOptions = () => {
            let selectedHostedNumberIds = [];

            if (selectedSubscriptions().length > 0) {
                selectedSubscriptions().forEach((subscription) => {
                    selectedHostedNumberIds.push(subscription.hostedNumberId);
                });
            } else {
                let options = subscriptionOptions();
                options.forEach((option) => {
                    selectedHostedNumberIds.push(option.hostedNumberId);
                });
            }

            let startDate = startOn();
            let endDate = endOn();
            let searchText = searchFilter();
            let searchStatus = selectedShowFilter();

            return {
                startDate,
                endDate,
                searchText,
                searchStatus,
                selectedHostedNumberIds,
            };
        };

        const longClickSpinnerColor = _colorConstants.colorSecondaryCool200;

        const searchFilter = ko.observable("").extend({throttle: 300});
        _disposables.push(searchFilter.subscribe(_onSearchTextChanged));

        const startOn = ko.observable("");
        _disposables.push(startOn.subscribe(_onSearchCriteriaChanged));

        const endOn = ko.observable("");
        _disposables.push(endOn.subscribe(_onSearchCriteriaChanged));

        const selectedShowFilter = ko.observable('');
        _disposables.push(selectedShowFilter.subscribe(_onShowFilterChanged));

        const selectedTimeframeFilter = ko.observable('');

        const showFilterOptions = [
            {title: 'All', value: 'all'},
            {title: 'New', value: 'new'},
            {title: 'Reviewed', value: 'reviewed'}
        ];

        const subscriptionOptions = ko.computed(() => {
            if (isDataLoaded() === false) {
                return [];
            }
            const availableSubscriptions = _availableSubscriptions();
            if (availableSubscriptions.length === 0) {
                return [];
            }

            const hostedNumbers = _commonState.hostedNumbers()
                .filter(number => {
                    return availableSubscriptions.find((subscription) => {
                        return number.id === subscription.hostedNumberId;
                    });
                })
                .map(number => {
                    const displayName = {displayName: number.name};
                    const iconProperty = {iconProperty: _commonState.types.hostedNumber};
                    const hostedNumberId = {hostedNumberId: number.id};
                    return Object.assign({}, number, iconProperty, displayName, hostedNumberId);

                });
            const sorter = new SorterConstructor();
            sorter.sort(hostedNumbers, "displayName");
            return hostedNumbers;
        });

        const areSubscriptionOptionsAvailable = ko.computed(() => {
            return subscriptionOptions().length > 0;
        });

        const selectedSubscriptions = ko.observableArray().extend({observeState: true});
        _disposables.push(selectedSubscriptions.subscribe(_onSearchCriteriaChanged));

        const isClearFiltersButtonVisible = ko.computed(() => {
            return (selectedShowFilter() !== '' && selectedShowFilter() !== 'all') ||
                selectedTimeframeFilter() !== '' ||
                selectedSubscriptions().length > 0;
        });

        const _detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        const _initialize = () => {
            const FacadeConstructor = require('presentation/inbox/facades/inboxSidebarFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            inboxSidebarHeaderViewModel.activate();

            return _promiseFactory.wait();
        };

        const onLogin = () => {
            // Nothing to do
        };

        const onLogout = () => {
            _hasScreenBeenInitialized = false;
        };

        const sidebar = {
            clearFilters,
            getSearchOptions,

            longClickSpinnerColor,
            isClearFiltersButtonVisible,
            searchFilter,
            startOn,
            endOn,
            selectedShowFilter,
            selectedSubscriptions,
            showFilterOptions,
            subscriptionOptions,
            areSubscriptionOptionsAvailable,
            inboxSidebarHeaderViewModel,
            isDataLoaded,
            selectedTimeframeFilter,

            // Durandal Events
            attached : _initializeScreen,
            activate : _initialize,
            detached : _detached,

            alerts : {
                receiveAlertLoginSuccessful : onLogin,
                receiveAlertLogout : onLogout,
            },
        };

        _inboxCallRecordingsViewModel.setSidebar(sidebar);
        return sidebar;
    });

