/*jslint devel : true*/
define('common/logging/logger',['settings/settings',
    'persistence/webSocket/webSocketEvent'
], function() {

    return function() {
        var self = this;
        var _webSocketEvent = null;
        var _settings = require('settings/settings');

        self.logError = function(exception) {
            if (exception.__hasErrorBeenLogged === true) {
                return;
            }

            var foundMetaData = false;
            var errorMetaData = {};
            for (var propertyKey in exception) {
                if (exception.hasOwnProperty(propertyKey)) {
                    if (propertyKey !== "stack" &&
                        propertyKey !== "message" &&
                        propertyKey !== 'creditCardNumber' &&
                        propertyKey !== 'cvvSecurityCode'
                    ) {
                        errorMetaData[propertyKey] = exception[propertyKey];
                        foundMetaData = true;
                    }
                }
            }

            if (foundMetaData === false) {
                errorMetaData = null;
            }

            var msg;
            if (exception.stack !== undefined) {
                msg = exception.stack;
            } else {
                msg = exception.message;
            }

            var errData = {};
            errData.eventType = "error";
            errData.errorMetaData = errorMetaData;
            errData.errorMessage = msg;

            // Flag the exception to indicate that it has been logged
            exception.__hasErrorBeenLogged = true;
            _webSocketEvent.postEvent(errData);

            if (_settings.isInDebugMode) {
                if (exception.stack) {
                    console.error("Exception Occurred [Message]: " + exception);
                    console.error("Exception Occurred [CallStack]: " + exception.stack);
                } else {
                    console.error("Exception Occurred:[Message, no callstack]" + exception);
                }
                console.debug(errData);
            }
        };

        self.init = function() {
            var WebSocketEventConstructor = require('persistence/webSocket/webSocketEvent');
            _webSocketEvent = new WebSocketEventConstructor();
            _webSocketEvent.init();

        };

        self.init2 = function(WebSocketEvent) {
            _webSocketEvent = WebSocketEvent;
        };
    };
});

