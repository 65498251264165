define('presentation/settings/billing/history/presentationObjects/billingHistoryTransactionPresentationObject',[], function () {
    return function () {
        const self = this;

        self.transactionId = null;
        self.description = null;
        self.paymentMethod = null;
        self.amount = null;
        self.amountDisplayValue = null;
        self.outcome = null;
        self.statementId = null;
        self.isBillingHistoryPdfAvailable = ko.observable(false);
        self.isOutcomeApprove = ko.observable(false);
        self.isOutcomeDecline = ko.observable(false);
        self.isOutcomeRefunded = ko.observable(false);
        self.isPastDueBalance = null;
        self.date = null;
        self.dateDisplayValue = null;
    };
});
