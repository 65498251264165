define('presentation/contacts/viewModels/contactOtherViewModel',[
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/window/windowControl'
], function(
    /** @type typeof import('common/collections/collectionSorter') */
    SorterConstructor,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type import('presentation/common/window/windowControl') */
    _windowControl
) {
    return function() {
        const self = this;

        const _promiseFactory = new PromiseFactory();
        const _sorter = new SorterConstructor();

        self.birthday = ko.observable('');
        self.urls = ko.observableArray([]);

        self.showBirthday = ko.pureComputed(() => {
            return self.birthday() !== '';
        });

        self.showUrls = ko.pureComputed(() => {
            return self.urls().length > 0;
        });

        self.openUrl = (url) => _windowControl.openExternalUrl(url.url());

        self.activate = (/** @type { IContactOtherViewModelActivationObject } */ activationObject) => {
            const {
                birthday,
                urls
            } = activationObject;

            self.birthday = birthday;
            self.urls = urls;
            _sorter.sort(self.urls, "url");

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
