define('presentation/settings/billing/paymentMethod/facades/paymentMethodFacade',[
        'businessServices/converters/currencyFormatter',
        'businessServices/creditCards/creditCardTypes',
        'businessServices/specifications/validCreditCardSpecification',
        'common/promises/promiseFactory',
        'common/time/date',
        'constants/creditCardResponseConstants',
        'constants/creditCardResponseEnumerations',
        'entities/paymentMethodEntity',
        'persistence/dataProviders/paymentMethodDataProvider',
        'persistence/repositories/paymentMethodRepository',
        'presentation/common/dateTimeValue',
        'presentation/settings/billing/paymentMethod/presentationObjects/paymentMethodPresentationObject'
    ],

    function () {
        const CreditCardConstructor = require('businessServices/creditCards/creditCardTypes');
        const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
        const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        const PaymentMethodEntityConstructor = require('entities/paymentMethodEntity');
        const PaymentMethodPresentationObjectConstructor = require('presentation/settings/billing/paymentMethod/presentationObjects/paymentMethodPresentationObject');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const DateConstructor = require('common/time/date');
        const ValidCreditCardSpecification = require('businessServices/specifications/validCreditCardSpecification');

        const _creditCard = new CreditCardConstructor();
        const _creditCardResponse = require('constants/creditCardResponseConstants');
        const _creditCardResponseEnumerations = require('constants/creditCardResponseEnumerations');
        const _currencyFormatterConstructor = new CurrencyFormatterConstructor();
        let _paymentMethodDataProvider = null;
        let _paymentMethodRepository = null;
        const _promiseFactory = new PromiseFactoryConstructor();
        const _date = new DateConstructor();
        let _accountDataProvider = null;
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _validCreditCardSpecification = new ValidCreditCardSpecification();

        const _getAddPaymentMethodAttempts = () => _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getAddPaymentMethodAttempts()
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });

        const _deletePaymentMethod = (paymentMethods, paymentMethodId) => _promiseFactory.defer((deferredObject) => {
            _paymentMethodRepository.remove(paymentMethodId)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });

        const _getAllPaymentMethods = () => _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getAll()
                .done((paymentMethods) => {
                    const paymentMethodsFormatted = paymentMethods.map((currentPaymentMethod) => _buildPaymentMethodPresentationObject(currentPaymentMethod));
                    deferredObject.resolve(paymentMethodsFormatted);
                })
                .fail(deferredObject.reject);
        });

        const _buildPaymentMethodPresentationObject = (currentPaymentMethod) => {
            const paymentMethodPresentationObject = new PaymentMethodPresentationObjectConstructor();
            paymentMethodPresentationObject.lastFourDigits = currentPaymentMethod.lastFourDigits;
            paymentMethodPresentationObject.paymentMethodId = currentPaymentMethod.paymentMethodId;
            paymentMethodPresentationObject.cardHolderName = currentPaymentMethod.cardHolderName;
            paymentMethodPresentationObject.expirationDate = _date.formatShortExpirationDate(currentPaymentMethod.expirationDate);
            paymentMethodPresentationObject.editPaymentMethodUrl = _navigationConfiguration.routesById.editPaymentMethod.baseUrl + currentPaymentMethod.paymentMethodId;
            paymentMethodPresentationObject.isDefault(currentPaymentMethod.isDefault);
            paymentMethodPresentationObject.isExpired(_isPaymentMethodExpired(currentPaymentMethod.expirationDate));
            paymentMethodPresentationObject.country = currentPaymentMethod.country;
            paymentMethodPresentationObject.postalCode = currentPaymentMethod.postalCode;

            if (currentPaymentMethod.lastChargeDate === null || currentPaymentMethod.lastChargeDate === undefined) {
                paymentMethodPresentationObject.lastChargeDate = "Never";
            } else {
                paymentMethodPresentationObject.lastChargeDate = new DateTimeValueConstructor(currentPaymentMethod.lastChargeDate).displayValue;
            }

            if (currentPaymentMethod.lastChargeAmount === null || currentPaymentMethod.lastChargeAmount === undefined) {
                paymentMethodPresentationObject.lastChargeAmount = "N/A";
            } else {
                paymentMethodPresentationObject.lastChargeAmount = _currencyFormatterConstructor.formatUSDForDisplay(currentPaymentMethod.lastChargeAmount);
            }
            if (currentPaymentMethod.lastChargeOutcome === null || currentPaymentMethod.lastChargeOutcome === undefined) {
                paymentMethodPresentationObject.lastChargeOutcome = "N/A";
            } else {
                switch (currentPaymentMethod.lastChargeOutcome) {
                    case _creditCardResponse.approved:
                        paymentMethodPresentationObject.isLastChargeApproved(true);
                        paymentMethodPresentationObject.lastChargeOutcome = _creditCardResponseEnumerations.approved.formattedOutcome;
                        break;
                    case _creditCardResponse.declined:
                        paymentMethodPresentationObject.isLastChargeDeclined(true);
                        paymentMethodPresentationObject.lastChargeOutcome = _creditCardResponseEnumerations.declined.formattedOutcome;
                        break;
                }
            }

            const creditCard = _creditCard.getCreditCardMaskedDisplay(currentPaymentMethod.cardType, currentPaymentMethod.lastFourDigits);
            paymentMethodPresentationObject.displayedCardName = creditCard.displayCardName;
            paymentMethodPresentationObject.cardCssType = creditCard.cssClass;
            paymentMethodPresentationObject.cardType = creditCard.type;
            paymentMethodPresentationObject.maskedPrefix = creditCard.maskedPrefix;
            paymentMethodPresentationObject.cardIdentifierFormatted = creditCard.maskedDisplay;
            return paymentMethodPresentationObject;
        };

        const _isPaymentMethodExpired = (expirationDate) => {
            return _validCreditCardSpecification.isCreditCardExpired(expirationDate);
        };

        const _updatePaymentMethodDefaultStatus = (paymentMethod) => _promiseFactory.defer((deferredObject) => {
            const paymentMethodEntity = new PaymentMethodEntityConstructor();
            paymentMethodEntity.paymentMethodId = paymentMethod.paymentMethodId;
            _paymentMethodRepository.setDefault(paymentMethodEntity)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });

        const _getFreeTrialInfo = () => _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getFreeTrialInfo()
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });

        const _isBalanceGreaterThanZero = () => _promiseFactory.defer((deferredObject) => {
            _accountDataProvider.getBalanceInformation()
                .done((balanceData) => {
                    let isBalanceGreaterThanZero = false;
                    const currentBalance = balanceData.currentBalance || 0;
                    const pastDueBalance = balanceData.pastDueBalance || 0;
                    if (currentBalance > 0 || pastDueBalance > 0) {
                        isBalanceGreaterThanZero = true;
                    }
                    deferredObject.resolve(isBalanceGreaterThanZero);
                })
                .fail(deferredObject.reject);
        });

        const _init = () => {
            const PaymentMethodRepositoryConstructor = require('persistence/repositories/paymentMethodRepository');
            _paymentMethodRepository = new PaymentMethodRepositoryConstructor();

            const PaymentMethodDataProviderConstructor = require('persistence/dataProviders/paymentMethodDataProvider');
            _paymentMethodDataProvider = new PaymentMethodDataProviderConstructor();

            const AccountDataProviderConstructor = require('persistence/dataProviders/accountDataProvider');
            _accountDataProvider = new AccountDataProviderConstructor();

            _paymentMethodRepository.init();
            _paymentMethodDataProvider.init();
        };

        return function () {
            let self = this;

            self.init = _init;

            self.getAddPaymentMethodAttempts = _getAddPaymentMethodAttempts;
            self.getAllPaymentMethods = _getAllPaymentMethods;
            self.updatePaymentMethodDefaultStatus = _updatePaymentMethodDefaultStatus;
            self.deletePaymentMethod = _deletePaymentMethod;
            self.buildPaymentMethodPresentationObject = _buildPaymentMethodPresentationObject;
            self.getFreeTrialInfo = _getFreeTrialInfo;
            self.isBalanceGreaterThanZero = _isBalanceGreaterThanZero;

        };
    });
