define('common/collections/enumerationKeyValueConverter',[], function() {
    return {
        convertToKeyValues: function(/** @type { any } */ enumeration) {
            const values = [];
            for (const propertyKey in enumeration) {
                if (enumeration.hasOwnProperty(propertyKey)) {
                    if (propertyKey !== "__moduleId__") {
                        const propertyValue = enumeration[propertyKey];
                        values.push({
                            key: propertyKey,
                            value: propertyValue
                        });
                    }
                }
            }
            return values;
        }
    };
});
