define('presentation/settings/closeAccount/viewModels/closeAccountPortInProgressActionModalViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/login/logoutRouteEvaluator',
    'common/promises/promiseFactory',
    'presentation/settings/closeAccount/facades/closeAccountFacade',
    'plugins/router',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

