define('presentation/settings/userGroup/viewModels/userGroupsViewModel',[
    'businessServices/router/router',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/settings/userGroup/facades/userGroupsFacade',
    'presentation/settings/userGroup/viewModels/deactivateUserGroupModalViewModel',
    'presentation/settings/userGroup/viewModels/reactivateUserGroupModalViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DeactivateUserGroupModalConstructor = require('presentation/settings/userGroup/viewModels/deactivateUserGroupModalViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const ReactivateUserGroupModalConstructor = require('presentation/settings/userGroup/viewModels/reactivateUserGroupModalViewModel');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        let _disposables = [];
        let _facade = null;

        const _buildDeactivateModal = (userGroup) => {
            const constructorParams = [userGroup];
            self.actionModal
                .clearModal()
                .setContentViewModel(DeactivateUserGroupModalConstructor, constructorParams)
                .setHeaderText({i18n: 'deactivateUserGroupModal:deactivateHeader'})
                .setSubmitButtonText({i18n: 'deactivate'})
                .shouldScrollIntoView(false);
        };

        const _buildReactivateModal = (userGroup) => {
            const constructorParams = [userGroup];
            self.actionModal
                .clearModal()
                .setContentViewModel(ReactivateUserGroupModalConstructor, constructorParams)
                .setHeaderText({i18n: 'reactivateUserGroupModal:reactivateGroup'})
                .setSubmitButtonText({i18n: 'reactivate'});
        };

        const _refreshUserGroups = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAllUserGroups(self.currentShowFilterOption())
                    .fail(deferredObject.reject)
                    .done((userGroups) => {
                        self.availableUserGroups = userGroups;
                        _updateDisplayedUserGroups();
                        deferredObject.resolve();
                    });
            });
        };

        const _updateDisplayedUserGroups = () => {
            let filteredGroups;
            let filterContent = self.userGroupFilter();
            if (filterContent !== '') {
                let userGroupFilter = new FilterConstructor();
                userGroupFilter
                    .addProperty('groupName')
                    .addProperty('memberCount')
                    .addProperty('createdDateTime.filterValue')
                    .addProperty('modifiedDateTime.filterValue')
                    .addProperty('status')
                    .addValue(filterContent);
                filteredGroups = userGroupFilter.filter(self.availableUserGroups);
            }
            else {
                filteredGroups = self.availableUserGroups;
            }

            self.displayedGroups(filteredGroups);
            _sortGroups();
        };

        const _sortGroups = () => {
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(self.displayedGroups);
            }
        };

        self.actionModal = null;
        self.addUserGroupUrl = _navigationConfiguration.routesById.addUserGroup.url;
        self.isCompositionComplete = ko.observable(false);
        self.displayedGroups = ko.observableArray();
        self.availableUserGroups = null;

        self.sortType = ko.observable("groupName");
        _disposables.push(self.sortType.subscribe(_sortGroups));

        self.currentShowFilterOption = ko.observable("all");
        self.userGroupFilter = ko.observable('');
        _disposables.push(self.userGroupFilter.subscribe(_updateDisplayedUserGroups));

        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "groupName", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.showResults = ko.pureComputed(() => self.displayedGroups().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => self.availableUserGroups.length > 0 ? "noResults" : "noUserGroups");
        self.deactivateTooltip = _i18n.t('userGroups:deactivateTooltip');

        self.deactivateUserGroup = (userGroupPresentationObject) => {
            return _promiseFactory.deferIndefinitely(deferredObject => {
                _buildDeactivateModal(userGroupPresentationObject);
                self.actionModal.showModal()
                    .done(_refreshUserGroups);
                deferredObject.resolve();
            });
        };

        self.reactivateUserGroup = (userGroupPresentationObject) => {
            return _promiseFactory.deferIndefinitely(deferredObject => {
                _buildReactivateModal(userGroupPresentationObject);
                self.actionModal.showModal()
                    .done(_refreshUserGroups);
                deferredObject.resolve();
            });
        };

        self.navigateToEditUserGroup = (userGroup) => {
            _router.navigate(userGroup.editUrl);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        self.activate = () => {
            const Facade = require('presentation/settings/userGroup/facades/userGroupsFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _refreshUserGroups();

            return _promiseFactory.wait();
        };
    };
});

