define('presentation/settings/closeAccount/validators/closeAccountValidator',['presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _facade = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();

            validationRules.field("selectedCloseAccountFeedbackReason")
                .addValidationI18n(_commonValidator.isStringWithValue, 'closeAccount:pleaseSelectReason');
            validationRules.field("featuresList")
                .addValidationI18n(_validateLacksFeaturesFeedbackReasons, 'closeAccount:pleaseMakeSelection');
            validationRules.field("lacksFeaturesOtherFeedback")
                .addValidationI18n(_validateLacksFeaturesOtherFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("tooDifficultToUseFeedback")
                .addValidationI18n(_validateToDifficultToUseFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("billingIssueFeedback")
                .addValidationI18n(_validateBillingIssueFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("selectedFraudStatus")
                .addValidationI18n(_validateFraudStatus, 'closeAccount:pleaseMakeSelection');
            validationRules.field("technicalIssueFeedback")
                .addValidationI18n(_validateTechnicalIssueFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("poorCustomerServiceFeedback")
                .addValidationI18n(_validatePoorCustomerServiceFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("tooExpensiveReason")
                .addValidationI18n(_validateTooExpensiveReason, 'closeAccount:pleaseMakeSelection');
            validationRules.field("tooExpensiveOtherFeedback")
                .addValidationI18n(_validateTooExpensiveOtherFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("noLongerNeededReason")
                .addValidationI18n(_validateNoLongerNeededReason, 'closeAccount:pleaseMakeSelection');
            validationRules.field("noLongerNeededOtherFeedback")
                .addValidationI18n(_validateNoLongerNeededOtherFeedback, 'closeAccount:pleaseProvideResponse');
            validationRules.field("otherFeedback")
                .addValidationI18n(_validateOtherFeedback, 'closeAccount:pleaseSpecifyReasonForCancelling');
            validationRules.field("areYouSwitchingServices")
                .addValidationI18n(_validateAreYouSwitchingServices, 'closeAccount:pleaseMakeSelection');
            validationRules.field("whichService")
                .addValidationI18n(_validateWhichService, 'closeAccount:pleaseSpecifyWhichService');
            validationRules.field("howLikelyAreYouToRecommend")
                .addValidationI18n(_validateHowLikelyAreYouToRecommend, 'closeAccount:pleaseMakeSelection');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        const _validateLacksFeaturesFeedbackReasons = (lacksFeaturesFeedbackReasons) => {
            if (_viewModel.showLacksFeaturesForm()) {
                return lacksFeaturesFeedbackReasons.some((reason) => reason.isSelected());
            }
            return true;
        };

        const _validateLacksFeaturesOtherFeedback = (lacksFeaturesOtherFeedback) => {
            if (_viewModel.showLacksFeaturesForm() && _viewModel.showLacksFeaturesOtherFeedback()) {
                return _commonValidator.isStringWithValue(lacksFeaturesOtherFeedback);
            }
            return true;
        };

        const _validateToDifficultToUseFeedback = (tooDifficultToUseFeedback) => {
            if (_viewModel.showTooDifficultToUseForm()) {
                return _commonValidator.isStringWithValue(tooDifficultToUseFeedback);
            }
            return true;
        };

        const _validateBillingIssueFeedback = (billingIssueFeedback) => {
            if (_viewModel.showBillingIssueForm()) {
                return _commonValidator.isStringWithValue(billingIssueFeedback);
            }
            return true;
        };

        const _validateFraudStatus = (fraudStatus) => {
            if (_viewModel.showFraudStatusForm()) {
                return _commonValidator.isStringWithValue(fraudStatus);
            }
            return true;
        };

        const _validateTechnicalIssueFeedback = (technicalIssueFeedback) => {
            if (_viewModel.showTechnicalIssueForm()) {
                return _commonValidator.isStringWithValue(technicalIssueFeedback);
            }
            return true;
        };

        const _validatePoorCustomerServiceFeedback = (poorCustomerServiceFeedback) => {
            if (_viewModel.showPoorCustomerServiceForm()) {
                return _commonValidator.isStringWithValue(poorCustomerServiceFeedback);
            }
            return true;
        };

        const _validateTooExpensiveReason = (tooExpensiveReason) => {
            if (_viewModel.showTooExpensiveFeedbackForm()) {
                return _commonValidator.isNumber(tooExpensiveReason);
            }
            return true;
        };

        const _validateTooExpensiveOtherFeedback = (tooExpensiveOtherFeedback) => {
            if (_viewModel.showTooExpensiveFeedbackForm() && _viewModel.showTooExpensiveOtherFeedback()) {
                return _commonValidator.isStringWithValue(tooExpensiveOtherFeedback);
            }
            return true;
        };

        const _validateNoLongerNeededReason = (noLongerNeededReason) => {
            if (_viewModel.showNoLongerNeededFeedbackForm()) {
                return _commonValidator.isNumber(noLongerNeededReason);
            }
            return true;
        };

        const _validateNoLongerNeededOtherFeedback = (noLongerNeededOtherFeedback) => {
            if (_viewModel.showNoLongerNeededFeedbackForm() && _viewModel.showNoLongerNeededOtherFeedback()) {
                return _commonValidator.isStringWithValue(noLongerNeededOtherFeedback);
            }
            return true;
        };

        const _validateOtherFeedback = (otherFeedback) => {
            if (_viewModel.showOtherFeedbackForm()) {
                return _commonValidator.isStringWithValue(otherFeedback);
            }
            return true;
        };

        const _validateAreYouSwitchingServices = (areYouSwitchingServices) => {
            if (_viewModel.showSwitchingServicesForm()) {
                return _commonValidator.isStringWithValue(areYouSwitchingServices);
            }
            return true;
        };

        const _validateWhichService = (whichService) => {
            if (_viewModel.showSwitchingServicesForm() && _viewModel.showWhichService()) {
                return _commonValidator.isStringWithValue(whichService);
            }
            return true;
        };

        const _validateHowLikelyAreYouToRecommend = (howLikelyAreYouToRecommend) => {
            if (_viewModel.showRatingAndAdditionalFeedbackForm()) {
                return _commonValidator.isNumber(howLikelyAreYouToRecommend);
            }
            return true;
        };

        self.validate = () => _commonValidator.validate();
    };
});

