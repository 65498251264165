/**
 Responsible for _ specific data interactions to the server

 @module _
 @submodule _
 **/
define('presentation/settings/autoAttendant/facades/autoAttendantVoicePromptFacade',['presentation/settings/prompts/presentationObjects/voicePromptPresentationObject',
    'persistence/dataProviders/voicePromptDataProvider',
    'persistence/dataProviders/autoAttendantVoiceRouteDataProvider',
    'persistence/repositories/autoAttendantVoiceRouteRepository',
    'entities/autoAttendantVoiceRouteEntity',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'constants/couchDbEnumeration'
], function () {
    return function () {
        var self = this;

        var VoicePromptPresentationObjectConstructor = require('presentation/settings/prompts/presentationObjects/voicePromptPresentationObject');
        var _dbNullConstants = require('constants/couchDbEnumeration');

        var _voicePromptDataProvider = null;
        var _autoAttendantVoiceRouteRepository = null;
        var _voicePromptAudioFactory = null;
        var _promiseFactory = null;

        self.updateVoicePrompt = function (voicePromptId, autoAttendantVoiceRouteId) {
            return _promiseFactory.defer(function (deferredObject) {
                var AutoAttendantVoiceRouteConstructor = require('entities/autoAttendantVoiceRouteEntity');
                var autoAttendantVoiceRouteEntity = new AutoAttendantVoiceRouteConstructor();
                autoAttendantVoiceRouteEntity.autoAttendantVoiceRouteId = autoAttendantVoiceRouteId;

                if (voicePromptId === null) {
                    autoAttendantVoiceRouteEntity.voicePromptId = _dbNullConstants.setToNull;
                } else {
                    autoAttendantVoiceRouteEntity.voicePromptId = voicePromptId;
                }

                _autoAttendantVoiceRouteRepository.update(autoAttendantVoiceRouteEntity).done(function (result) {
                    deferredObject.resolve(result);
                })
                .fail(function(error) {
                    error.voicePromptId = voicePromptId;
                    error.autoAttendantVoiceRouteId = autoAttendantVoiceRouteId;
                    deferredObject.reject(error);
                });
            });
        };


        self.getCurrentVoicePrompt = function (voicePromptId) {
            var voicePrompt = _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
            return voicePrompt;
        };

        self.getMissingVoicePrompt = function () {
            var missingVoicePrompt = _voicePromptAudioFactory.getMissingVoicePrompt();
            return missingVoicePrompt;
        };

        self.getAllPrompts = function () {
            return _promiseFactory.defer(function (deferredObject) {
                var voicePromptsList = [];
                _voicePromptDataProvider.getAll()
                    .done(function (voicePrompts) {
                        for (var x = 0; x < voicePrompts.length; x++) {
                            var currentVoicePrompt = voicePrompts[x];
                            var displayVoicePrompt = new VoicePromptPresentationObjectConstructor();
                            displayVoicePrompt.voicePromptId = currentVoicePrompt.voicePromptId;
                            displayVoicePrompt.promptName = currentVoicePrompt.promptName;
                            voicePromptsList.push(displayVoicePrompt);

                        }
                        deferredObject.resolve(voicePromptsList);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
                /**
        * Verifying if the prompt name is uni que
        * @method isPromptNameUnique
        * @param {voicePromptId, promptName}
        * @return {promise}
        */
        self.isPromptNameUnique = function(voicePromptId, promptName) {
            return _promiseFactory.defer(function(deferredObject) {
                self.getAllPrompts()
                    .done(function(prompts) {
                        var trimmedPromptName = promptName.trim();
                        for (var x = 0; x < prompts.length; x++) {
                            var prompt = prompts[x];
                            if (prompt.promptName.toLowerCase() === trimmedPromptName.toLowerCase()) {
                                if (prompt.voicePromptId !== voicePromptId) {
                                    deferredObject.resolve(false);
                                    return;
                                }
                            }
                        }
                        deferredObject.resolve(true);
                    })
                    .fail(function(error) {
                        error.voicePromptId = voicePromptId;
                        error.promptName = promptName;
                        deferredObject.reject(error);
                    });
            });
        };

        self.init = function (promiseFactory) {
            _promiseFactory = promiseFactory;

            var VoicePromptDataProviderConstructor = require('persistence/dataProviders/voicePromptDataProvider');
            _voicePromptDataProvider = new VoicePromptDataProviderConstructor();

            var AutoAttendantVoiceRouteRepositoryConstructor = require('persistence/repositories/autoAttendantVoiceRouteRepository');
            _autoAttendantVoiceRouteRepository = new AutoAttendantVoiceRouteRepositoryConstructor();

            var VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
            _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
            _voicePromptAudioFactory.init();
        };

        self.init2 = function (promiseFactory, voicePromptDataProvider, voicePromptRepository, autoAttendantVoiceRouteDataProvider, autoAttendantVoiceRouteRepository, voicePromptAudioStore, voicePromptAudioFactory) {
            _promiseFactory = promiseFactory;
            _voicePromptDataProvider = voicePromptDataProvider;
            _autoAttendantVoiceRouteRepository = autoAttendantVoiceRouteRepository;
            _voicePromptAudioFactory = voicePromptAudioFactory;
        };
    };
});
