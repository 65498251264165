define('persistence/dataProviders/accountDataProvider',['externalDependencies/kazooApi',
        'common/promises/promiseFactory'],
    function () {
        return function () {
            var self = this;

            var KazooConstructor = require('externalDependencies/kazooApi');
            var _kazooApi = new KazooConstructor();
            _kazooApi.init();

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();

            self.getAccount = function () {
                return _promiseFactory.defer(function (deferredObject) {
                    _kazooApi.callAccount('/lobby_account', 'GET')
                        .done(function (result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function (error) {
                            deferredObject.reject(error);
                        });
                });
            };

            self.getBalanceInformation = function () {
                return _promiseFactory.defer(function (promise) {
                    _kazooApi.callAccount('/lobby_account/balanceInformation', 'GET')
                        .done(function (result) {
                            promise.resolve(result.data.data);
                        })
                        .fail(function (error) {
                            promise.reject(error);
                        });
                });
            };

            self.getBillingProfile = function () {
                return _promiseFactory.defer(function (deferredObject) {
                    _kazooApi.callAccount('/lobby_account/billing_profile', 'GET')
                        .done(function (result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function (error) {
                            deferredObject.reject(error);
                        });
                });
            };

            self.getMarketingInfo = function () {
                return _promiseFactory.defer(function (deferredObject) {
                    _kazooApi.callAccount('/lobby_account/marketing_info', 'GET')
                        .done(function (result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function (error) {
                            deferredObject.reject(error);
                        });
                });
            };


            self.calculateTaxes = function (planId, packageId, phoneNumbersCount) {
                return _promiseFactory.defer(function (promise) {
                    var accountDocument = {
                        data : {
                            planId : planId,
                            packageId : packageId,
                            phoneNumbersCount : phoneNumbersCount
                        }
                    };

                    _kazooApi.callAccount('/lobby_account/calculate_taxes', 'POST', accountDocument)
                        .done(function (result) {
                            promise.resolve(result.data.data);
                        })
                        .fail(function (error) {
                            promise.reject(error);
                        });
                });
            };
        };
    });

