define('presentation/common/actionModal/viewModels/makeAPaymentModalViewModel',[
    'businessServices/converters/currencyFormatter',
    'businessServices/events/eventManager',
    'businessServices/login/accountStatusEvaluator',
    'common/promises/promiseFactory',
    'constants/creditCardResponseConstants',
    'presentation/settings/billing/makeAPayment/facades/makeAPaymentFacade',
], function () {
    return function (paymentMethod, paymentAmount) {
        const self = this;

        const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
        const _currencyFormatter = new CurrencyFormatterConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _accountStatusEvaluator = require('businessServices/login/accountStatusEvaluator');
        const _creditCardResponseConstants = require('constants/creditCardResponseConstants');
        const _eventManager = require('businessServices/events/eventManager');
        const _i18n = require('i18next');

        let _facade = null;

        const formattedPaymentAmount = typeof paymentAmount === 'string' ?
            _currencyFormatter.formatUSDForDisplay(parseFloat(paymentAmount.replace(/\$|,/g, ''))) :
            _currencyFormatter.formatUSDForDisplay(paymentAmount);

        self.paymentMethod = paymentMethod;
        self.displayPaymentAmount = typeof paymentAmount === 'string' ?
            _currencyFormatter.formatUSDForDisplay(parseFloat(paymentAmount.replace(/\$|,/g, ''))) :
            _currencyFormatter.formatUSDForDisplay(paymentAmount);
        self.displayCardNumber = `${_i18n.t('paymentMethod:endingIn')} ${paymentMethod.lastFourDigits}`;
        self.paymentResults = null;
        self.showPaymentFailureMessage = ko.observable(false);
        self.paymentFailureTitle = ko.observable('');
        self.paymentFailureMessage = ko.observable('');

        const _validatePaymentProcessing = (paymentStatus) => {
            switch (paymentStatus) {
                case _creditCardResponseConstants.success:
                case _creditCardResponseConstants.approved:
                    self.paymentFailureTitle('');
                    self.paymentFailureMessage('');
                    return true;
                case _creditCardResponseConstants.declined:
                    self.paymentFailureTitle(_i18n.t('makeAPayment:declinedTitle'));
                    self.paymentFailureMessage(_i18n.t('makeAPayment:declinedMessage'));
                    return false;
                case _creditCardResponseConstants.duplicateCard:
                case _creditCardResponseConstants.invalidCard:
                case _creditCardResponseConstants.invalidConnection:
                    self.paymentFailureTitle(_i18n.t('makeAPayment:genericFailureTitle'));
                    self.paymentFailureMessage(_i18n.t('makeAPayment:genericFailureMessage'));
                    return false;
                default:
                    throw new Error(paymentStatus);
            }
        };

        self.submitAction = () => {
            self.showPaymentFailureMessage(false);
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.applyPayment(self.paymentMethod.paymentMethodId, formattedPaymentAmount)
                    .fail(deferredObject.reject)
                    .done((paymentResult) => {
                        if (_validatePaymentProcessing(paymentResult.status)) {
                            _accountStatusEvaluator.refresh();
                            _facade.getCustomerBalanceData()
                                .done((balanceData) => {
                                    _eventManager.publishPastDueBalance(balanceData.pastDueBalance);
                                    deferredObject.resolve(paymentResult.status);
                                });
                        } else {
                            self.showPaymentFailureMessage(true);
                            const failureResult = { isValid: false };
                            deferredObject.resolve(failureResult);
                        }
                    });
            });
        };

        self.activate = () => {
            const MakeAPaymentFacadeConstructor = require('presentation/settings/billing/makeAPayment/facades/makeAPaymentFacade');
            _facade = new MakeAPaymentFacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
