define('presentation/contacts/validators/addContactViewModelValidator',[
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'common/specifications/validEmailAddressSpecification',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function(
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/specifications/validEmailAddressSpecification') */
    ValidEmailAddressSpecification,
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules
) {
    return function() {
        /** @typedef { import('presentation/contacts/viewModels/addContactViewModel') } AddContactViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _promiseFactory = new PromiseFactory();
        const _validEmailAddressSpecification = new ValidEmailAddressSpecification();

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { AddContactViewModel }*/
        let _viewModel = null;

        self.registerViewModel = (/** @type { AddContactViewModel }*/ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.array("phoneNumbers")
                .arrayField("number")
                .addValidationKeyUp(_validatePhoneNumber)
                .addValidationI18n(_validatePhoneNumber, 'phoneNumberInvalid')
                .addValidationI18n(_isPhoneNumberUnique, 'addContact:validation.phoneNumberDuplicate');
            validationRules.array("emailAddresses")
                .arrayField("email")
                .addValidationKeyUp(_validateEmailAddress)
                .addValidationI18n(_validateEmailAddress, 'emailAddressInvalid')
                .addValidationI18n(_isEmailAddressUnique, 'addContact:validation.emailDuplicate');
            validationRules.field("birthday")
                .addValidationI18n(_validateBirthday, 'addContact:validation.invalidBirthday');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        self.validateMinimumRequiredFields = () => {
            return _promiseFactory.defer((deferredObject) => {
                const hasAtLeastOneRequiredField = !_areRequiredInputsEmpty();
                deferredObject.resolve(hasAtLeastOneRequiredField);
            });
        };

        const _isInputEmpty = (/** @type { string } */ input) => {
            return !_commonValidator.isStringWithValue(input);
        };

        const _isArrayOfInputsEmpty = (/** @type { Array<string> } */ inputs) => {
            return !inputs.some((input) => _commonValidator.isStringWithValue(input));
        };

        const _areRequiredInputsEmpty = () => {
            const numbers = _viewModel.phoneNumbers().map((phoneNumber) => phoneNumber.number());
            const emailAddresses = _viewModel.emailAddresses().map((email) => email.email());

            return (
                _isInputEmpty(_viewModel.firstName()) &&
                _isInputEmpty(_viewModel.lastName()) &&
                _isInputEmpty(_viewModel.company()) &&
                _isArrayOfInputsEmpty(numbers) &&
                _isArrayOfInputsEmpty(emailAddresses)
            );
        };

        const _validatePhoneNumber = (/** @type { string } */ phoneNumber) => {
            if (_commonValidator.isStringWithValue(phoneNumber)) {
                return phoneNumber.trim().length > 9;
            } else {
                return true;
            }
        };

        const _isPhoneNumberUnique = (/** @type { string } */ phoneNumber) => {
            const phoneNumbers = _viewModel.phoneNumbers();
            const matchingPhoneNumbers = phoneNumbers.filter((n) => {
                return _phoneNumberFormatter.clean(n.number().trim()) ===  _phoneNumberFormatter.clean(phoneNumber.trim());
            });
            return matchingPhoneNumbers.length === 1;
        };

        const _validateEmailAddress = (/** @type { string } */ emailAddress) => {
            if (_commonValidator.isStringWithValue(emailAddress)) {
                return _validEmailAddressSpecification.isSatisfiedBy(emailAddress.trim());
            } else {
                return true;
            }
        };

        const _isEmailAddressUnique = (/** @type { string } */ emailAddress) => {
            const emailAddresses = _viewModel.emailAddresses();
            const matchingEmailAddresses = emailAddresses.filter((e) => {
                return e.email().trim().toLowerCase() === emailAddress.trim().toLowerCase();
            });
            return matchingEmailAddresses.length === 1;
        };

        const _validateBirthday = (/** @type { string } */ birthday) => {
            if (birthday === null || birthday === '') {
                return true;
            }

            if (_commonValidator.isValidDateString(birthday) === false) {
                return false;
            }

            const date = new Date(birthday);
            return new Date() >= date;
        };
    };
});
