define('presentation/settings/voicemailBoxes/viewModels/voicemailBoxesViewModel',[
        'businessServices/router/router',
        'common/promises/promiseFactory',
        'common/collections/collectionFilter',
        'common/collections/collectionSorter',
        'common/storage/commonState',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/deleteActionViewModel',
        'presentation/common/warningModal/viewModels/integrityWarningModalViewModel',
        'presentation/settings/voicemailBoxes/facades/voicemailBoxesFacade',
        'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteActionViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _commonState = require('common/storage/commonState');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        let _disposables = [];
        let _facade = null;

        const DELETE_RESPONSE = "yes";

        self.addVoicemailBoxUrl = _navigationConfiguration.routesById.newVoicemailBox.url;

        const _filterAndSortVoicemailBoxes = () => {
            let filterContent = self.searchFilter();
            let filteredVoicemailBoxes = null;
            if (filterContent !== '') {
                let voicemailBoxFilter = new FilterConstructor();
                voicemailBoxFilter.addProperty('name');
                voicemailBoxFilter.addProperty('type');
                voicemailBoxFilter.addProperty('currentGreetingName');
                voicemailBoxFilter.addProperty('modifiedDateTime.filterValue');
                voicemailBoxFilter.addProperty('createdDateTime.filterValue');
                voicemailBoxFilter.addValue(filterContent);
                filteredVoicemailBoxes = voicemailBoxFilter.filter(self.availableVoicemailBoxes());
            } else {
                filteredVoicemailBoxes = self.availableVoicemailBoxes();
            }

            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(filteredVoicemailBoxes);
            }
            self.displayVoicemailBoxes(filteredVoicemailBoxes);
        };

        self.allUsers = ko.pureComputed(() => _commonState.users());
        self.allUserGroups = ko.pureComputed(() => _commonState.userGroups());

        const _loadVoicemailBoxes = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getVoicemailBoxes()
                    .fail(promise.reject)
                    .done((voicemailBoxes) => {
                        voicemailBoxes.map(voicemailBox => {
                            let voicemailBoxUserIdToCheck = '';
                            let voicemailBoxUserGroupIdToCheck = '';

                            if (voicemailBox.type() === _commonState.typeDisplay.user) {
                                voicemailBoxUserIdToCheck = voicemailBox.owningUserId;
                                self.allUsers().map(user => {
                                    if (user.id === (voicemailBoxUserIdToCheck)) {
                                        voicemailBox.name(user.name());
                                        voicemailBox.userAvatar(user.avatar());
                                        voicemailBox.hasAvatar = user.avatar().showImage();
                                        voicemailBox.isDeactivated(user.isDeactivated());
                                        voicemailBox.isExpired(user.isExpired());
                                    }
                                });
                            }
                            if (voicemailBox.type() === _commonState.typeDisplay.userGroup) {
                                voicemailBoxUserGroupIdToCheck = voicemailBox.owningUserGroupId;
                                self.allUserGroups().map(userGroup => {
                                    if (userGroup.id === voicemailBoxUserGroupIdToCheck) {
                                        voicemailBox.name(userGroup.name());
                                        voicemailBox.owningUserGroupId = voicemailBoxUserGroupIdToCheck;
                                        voicemailBox.isDeactivated(userGroup.isDeactivated());
                                    } else {
                                        let systemGroup = _commonState.get(voicemailBox.owningUserGroupId);
                                        voicemailBox.name(systemGroup.name());
                                        voicemailBox.isDeactivated(systemGroup.isDeactivated());
                                    }
                                });
                            }
                        });
                        self.availableVoicemailBoxes(voicemailBoxes.filter(voicemailBox => {
                            return !(voicemailBox.isDeactivated() || voicemailBox.isExpired());
                        }));

                        _filterAndSortVoicemailBoxes();
                        promise.resolve();
                    });
            });
        };

        self.actionModal = null;
        self.integrityWarningModal = null;
        self.isCompositionComplete = ko.observable(false);

        self.searchFilter = ko.observable('');
        _disposables.push(self.searchFilter.subscribe(_filterAndSortVoicemailBoxes));

        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "name", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.sortType = ko.observable("name");
        _disposables.push(self.sortType.subscribe(_filterAndSortVoicemailBoxes));

        self.availableVoicemailBoxes = ko.observableArray([]);
        self.displayVoicemailBoxes = ko.observableArray([]);

        self.showResults = ko.pureComputed(() => self.displayVoicemailBoxes().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => self.availableVoicemailBoxes().length > 0 ? "noResults" : "noVoicemailBoxes");

        const _buildDeleteModal = (voicemailBoxId) => {
            const displayType = _commonState.typeDisplay.voicemailBox;
            let constructorParams = [voicemailBoxId];
            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: {key: 'deleteHeader', options: {type: displayType}}})
                .setSubmitButtonText({i18n: 'delete'});
        };

        const _buildIntegrityWarningsModal = (voicemailBoxId) => {
            const displayType = _commonState.typeDisplay.voicemailBox;
            self.integrityWarningModal
                .clearModal()
                .setHeading({key: 'delete', options: {type: displayType}})
                .addText({i18n: {key: 'inUse', options: {type: displayType.toLowerCase()}}})
                .setSystemObject({objectId: voicemailBoxId})
                .addIntegrityWarningText({i18n: {key: 'conflicts'}});
        };

        const _showDeletePrompt = (voicemailBox) => {
            const voicemailBoxId = voicemailBox.voicemailBoxId;
            _buildDeleteModal(voicemailBoxId);
            self.actionModal.showModal()
                .done((result) => {
                    if (result === DELETE_RESPONSE) {
                        _facade.deleteVoicemailBox(voicemailBoxId)
                            .done(() => {
                                self.availableVoicemailBoxes.remove(voicemailBox);
                                self.displayVoicemailBoxes.remove(voicemailBox);
                                _filterAndSortVoicemailBoxes();
                            })
                            .fail((error) => {
                                if (error.constraintViolations && error.constraintViolations.length > 0) {
                                    _showIntegrityWarningsPrompt(voicemailBoxId, error.constraintViolations);
                                } else {
                                    error.voicemailBoxId = voicemailBoxId;
                                    throw error;
                                }
                            });
                    }
                })
                .fail((error) => {
                    throw error;
                });
        };

        const _showIntegrityWarningsPrompt = (voicemailBoxId, integrityWarningPresentationObjects) => {
            _buildIntegrityWarningsModal(voicemailBoxId);
            self.integrityWarningModal.addIntegrityWarnings(integrityWarningPresentationObjects);
            self.integrityWarningModal.showModal();
        };

        self.launchDeleteVoicemailBox = (voicemailBox) => {
            const voicemailBoxId = voicemailBox.voicemailBoxId;
            return _promiseFactory.deferIndefinitely(promise => {
                _facade.checkConstraints(voicemailBoxId)
                    .fail(promise.reject)
                    .done((integrityWarningPresentationObjects) => {
                    if (integrityWarningPresentationObjects.length > 0) {
                        _showIntegrityWarningsPrompt(voicemailBoxId, integrityWarningPresentationObjects);
                    } else {
                        _showDeletePrompt(voicemailBox);
                    }
                    promise.resolve();
                });
            });
        };

        self.navigateToEditVoicemailBox = (voicemailBox) => {
            _router.navigate(voicemailBox.editVoicemailBoxUrl);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/voicemailBoxes/facades/voicemailBoxesFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const WarningModalViewModelConstructor = require('presentation/common/warningModal/viewModels/integrityWarningModalViewModel');
            self.integrityWarningModal = new WarningModalViewModelConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _loadVoicemailBoxes();

            return _promiseFactory.wait();
        };
    };
});

