define('presentation/contacts/bindings/contactCardScrollBinding',[

], function() {
    return {
        /** @type import('presentation/contacts/bindings/contactCardScrollBinding')["init"] */
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            const {
                contactCardHeaderElementId,
                toggleElementClass,
                resizeAvatarClass,
                minHeaderHeight,
                maxHeaderHeight,
                scrollPositionY
            } = ko.unwrap(valueAccessor());

            /** @type { OverlayScrollbars } */
            let scrollbarInstance = null;
            let isInitialized = false;

            /** @type { number }*/
            let maxHeaderShrink = 0;

            /** @type { any }*/
            let toggleVisibleClasses = '';
            /** @type { any }*/
            let contactCardHeaderElement = null;
            /** @type { any }*/
            let resizeAvatarElement = null;

            if (toggleElementClass) {
                maxHeaderShrink = maxHeaderHeight - minHeaderHeight;

                toggleVisibleClasses = toggleElementClass.split(' ');

                contactCardHeaderElement = document.getElementById(contactCardHeaderElementId);
                resizeAvatarElement = document.getElementsByClassName(resizeAvatarClass)[0];
            }


            const _isInitialized = () => {
                return scrollbarInstance && isInitialized;
            };

            const _onScroll = () => {
                if (!_isInitialized()) {
                    return;
                }

                _updateContactCardHeaderSize();
            };

            const _onScrollStop = () => {
                const viewModel = bindingContext.$data;
                viewModel.updateScrollPositionY(scrollbarInstance.scroll().position.y);
            };

            scrollbarInstance = $(element).overlayScrollbars({
                className: "os-theme-tresta",
                overflowBehavior : {
                    x : 'hidden',
                    y : 'scroll'
                },
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 100
                },
                sizeAutoCapable: false,
                callbacks: {
                    onInitialized: () => {
                        isInitialized = true;
                    },
                    onScroll: _onScroll,
                    onScrollStop: _onScrollStop,
                }
            }).overlayScrollbars();

            if (scrollPositionY && scrollPositionY()) {
                scrollbarInstance.scroll({ y: scrollPositionY() });
            }


            const _updateContactCardHeaderSize = () => {
                if (contactCardHeaderElement && resizeAvatarElement) {
                    const scrollData = scrollbarInstance.scroll();
                    const currentHeaderHeight = contactCardHeaderElement.getBoundingClientRect().height;

                    const yScroll = scrollData.position.y;

                    if (currentHeaderHeight === minHeaderHeight || yScroll >= maxHeaderShrink) {
                        toggleVisibleClasses.forEach(item => {
                            let toggleVisibleElement = document.getElementsByClassName(item);
                            for (let element of toggleVisibleElement) {
                                element.classList.add(`${item}--hidden`);
                            }
                        });
                        resizeAvatarElement.style.transform = `scale(${0.8})`;
                        return;
                    }

                    const newHeightValue = Math.max(minHeaderHeight, maxHeaderHeight - yScroll);

                    // resize header, avatar, display captions
                    contactCardHeaderElement.style.height = `${newHeightValue}px`;
                    toggleVisibleClasses.forEach(item => {
                        let toggleVisibleElement = document.getElementsByClassName(item);
                        for (let element of toggleVisibleElement) {
                            element.classList.remove(`${item}--hidden`);
                        }
                    });
                    const ratio = (yScroll * 0.2) / maxHeaderShrink;
                    const newResizeValue = (1 - ratio);
                    resizeAvatarElement.style.transform = `scale(${newResizeValue})`;
                }
            };
        }
    };
});
