define('presentation/analytics/facades/analyticsListFacade',[
        'persistence/webSocket/webSocketApp',
        'externalDependencies/clientWebSocket',
    ],
    function (
        /** @type typeof import('persistence/webSocket/webSocketApp') */
        WebSocketApp,
        /** @type typeof import('externalDependencies/clientWebSocket') */
        ClientWebSocket
    ) {
        const clientWebSocket = ClientWebSocket;
        const _callRecordingSubscribersWebSocket = clientWebSocket.forApplication("callRecordingSubscribers");
        const _callRecordingSubscriptionWebSocket = clientWebSocket.forApplication("callRecordingSubscriptionDataSource");
        const _callRecordingWebSocket = clientWebSocket.forApplication("callRecording");
        const _voicemailSubscriptionWebSocket = clientWebSocket.forApplication("voicemailSubscriptionDataSource");
        const _voicemailWebSocket = clientWebSocket.forApplication("voicemail");

        /** @type import('common/promises/promiseFactory') */
        let _promiseFactory = null;
        /** @type {WebSocketApp['prototype']} */
        let _socket = null;


        const _init = function (/** @type import('common/promises/promiseFactory') */promiseFactory) {
            _promiseFactory = promiseFactory;

            _socket = new WebSocketApp();
            _socket.init("analytics");
        };

        const _getLocations = function(outsideNumbers) {
            return _promiseFactory.deferIndefinitely(function(promise) {
                const requestData = {
                    phoneNumbers: outsideNumbers
                };

                _socket.send("get_locations", requestData, function(results) {
                    if (results === "error") {
                        promise.reject(new Error(""));
                        return;
                    }
                    promise.resolve(results.numbersWithLocations);
                });
            });
        };

        const _getCallDetails = (callHistoryId) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                const requestData = {
                    callHistoryId: callHistoryId,
                    height: 200,
                    width: 744
                };

                _socket.send("get_call_details", requestData, (results) => {
                    if (results === "error") {
                        promise.reject(new Error(""));
                        return;
                    }
                    promise.resolve(results);
                });
            });
        };

        const _deleteCallRecording = (callRecordingId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    callRecordingId: callRecordingId,
                };

                _callRecordingWebSocket.send("deleteCallRecording", data, () => {
                    promise.resolve();
                });
            });
        };

        const _deleteVoicemailMessage = (voicemailMessageId) => {
            return _promiseFactory.defer((promise) => {
                const data = {
                    voicemailMessageId: voicemailMessageId,
                };

                _voicemailWebSocket.send("deleteMessage", data, () => {
                    promise.resolve();
                });
            });
        };

        const _setCallRecordingStatus = (messageId, statusId) => {
            const data = {
                callRecordingId: messageId,
                status: statusId
            };

            return _promiseFactory.defer((promise) => {
                _callRecordingWebSocket.send("setCallRecordingStatus", data, () => {
                    promise.resolve();
                });
            });
        };

        const _setVoicemailMessageStatus = (messageId, statusId) => {
            const data = {
                voicemailMessageId: messageId,
                status: statusId
            };

            return _promiseFactory.defer((promise) => {
                _voicemailWebSocket.send("setMessageStatus", data, () => {
                    promise.resolve();
                });
            });
        };

        const onVoicemailMessageStatusChanged = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailMessageStatusChanged", (eventData) => {
                const {voicemailMessageId, newStatus} = eventData;
                callback(voicemailMessageId, newStatus);
            });
        };

        const onVoicemailMessageDeleted = (callback) => {
            _voicemailSubscriptionWebSocket.subscribeToEvent("voicemailMessageDeleted", (eventData) => {
                const {voicemailMessageId} = eventData;
                callback(voicemailMessageId);
            });
        };

        const onCallRecordingStatusChanged = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("callRecordingStatusChanged", (eventData) => {
                const {callRecordingId, newStatus} = eventData;
                callback(callRecordingId, newStatus);
            });
        };

        const onCallRecordingSubscribersChanged = (callback) => {
            _callRecordingSubscribersWebSocket.subscribeToEvent("callRecordingSubscribersChanged", (eventData) => {
                const {accountHostedNumberId, subscriberIds} = eventData;
                callback(accountHostedNumberId, subscriberIds);
            });
        };

        const onCallRecordingDeleted = (callback) => {
            _callRecordingSubscriptionWebSocket.subscribeToEvent("callRecordingDeleted", (eventData) => {
                const {callRecordingId} = eventData;
                callback(callRecordingId);
            });
        };

        return function () {
            const self = this;

            self.init = _init;
            self.deleteCallRecording = _deleteCallRecording;
            self.deleteVoicemailMessage = _deleteVoicemailMessage;
            self.getCallDetails = _getCallDetails;
            self.getLocations = _getLocations;
            self.onCallRecordingDeleted = onCallRecordingDeleted;
            self.onCallRecordingStatusChanged = onCallRecordingStatusChanged;
            self.onCallRecordingSubscribersChanged = onCallRecordingSubscribersChanged;
            self.onVoicemailMessageDeleted = onVoicemailMessageDeleted;
            self.onVoicemailMessageStatusChanged = onVoicemailMessageStatusChanged;
            self.setCallRecordingStatus = _setCallRecordingStatus;
            self.setVoicemailMessageStatus = _setVoicemailMessageStatus;
        };
    }
);

