define('presentation/settings/autoAttendant/presentationObjects/autoAttendantVoiceRoutePresentationObject',[],function() {
    return function(){
        var self = this;

        self.autoAttendantVoiceRouteId = null;
        self.autoAttendantId = null;
        self.isInitialVoiceRoute = null;
        self.autoAttendantVoiceRouteName = null;
        self.voicePromptId = null;
        self.routeToUserId = null;
        self.routeToUserGroupId = null;
        self.routeToAutoAttendantVoiceSubMenuId = null;
        self.routeToForwardingNumberId = null;
        self.routeToScheduleId = null;
        self.routeToType = null;
        self.routeToAutoAttendant = null;
    };
});

