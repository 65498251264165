define('presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject',[],function() {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject') } MessagingCompliancePresentationObject } */

        /** @typedef { MessagingCompliancePresentationObject }*/
        const self = this;

        self.profile = ko.observable(null);
        self.localMessaging = ko.observable(null);
        self.tollfreeMessaging = ko.observable(null);
        self.autoResponse = ko.observable(null);
        self.authorizedSignature = ko.observable(null);
        self.accountHasCompletedLocalRegistration = ko.observable(false);
        self.accountHasCompletedTollFreeRegistration = ko.observable(false);
        self.isAccountInFreeTrial = ko.observable(false);
    };
});

