define('constants/shellNameEnumerations',[],function () {
    return {
        appEntry: "appEntry",
        empty: "empty",
        frontEnd: "frontEnd",
        gradient: "gradient",
        interiorError: "interiorError",
        login: "login",
        interior: "interior"
    };
});

