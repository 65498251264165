define('presentation/settings/autoAttendant/viewModels/autoAttendantViewModel',[
    'businessServices/router/router',
    'businessServices/events/eventManager',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/scrollEventSources',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',
    'presentation/common/modal',
    'presentation/settings/autoAttendant/facades/autoAttendantFacade',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/deleteAutoAttendantActionViewModel',
    'presentation/common/warningModal/viewModels/autoAttendantIntegrityWarningModalViewModel',
    'settings/navigationConfiguration'
], function(){
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');
        const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteAutoAttendantActionViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const SorterConstructor = require('common/collections/collectionSorter');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        const _commonState = require('common/storage/commonState');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        let _eventManager = null;
        let _facade = null;
        let _getNextBatchOfAutoAttendantsEventId = null;
        let _getNextBatchCalled = false;

        const ROWS_PER_PAGE = 15;
        const DELETE_RESPONSE = "yes";

        let _state = {
            availableAutoAttendants: ko.observableArray(null),
            availableSchedules: null,
            availableVoicePrompts: null,
            filteredAutoAttendants: null,
            autoAttendantsShown: 0
        };

        const _getAutoAttendants = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAutoAttendantCallFlowObjects()
                    .done((availableAutoAttendants, availableSchedules, availableVoicePrompts) => {

                        _state.availableAutoAttendants(availableAutoAttendants);
                        _state.availableSchedules = availableSchedules;
                        _state.availableVoicePrompts = availableVoicePrompts;
                        _state.filteredAutoAttendants = availableAutoAttendants;
                        _state.autoAttendantsShown = ROWS_PER_PAGE;
                        _sortDisplayedAutoAttendants();

                        self.displayAutoAttendants(_state.filteredAutoAttendants.slice(0, _state.autoAttendantsShown));
                        if (_getNextBatchOfAutoAttendantsEventId === null) {
                            _getNextBatchOfAutoAttendantsEventId = _eventManager.subscribeBottomScrollBufferReached(_getNextBatchOfAutoAttendants);
                        }

                        let autoAttendantConstructorPromise = new PromiseFactoryConstructor();

                        availableAutoAttendants.forEach(attendant => {
                            autoAttendantConstructorPromise.deferIndefinitely((promise) => {
                                let autoAttendantsInCurrentCallFlow = [attendant.accountAutoAttendantId];
                                let autoAttendantCallFlow = new AutoAttendantCallFlowConstructor(attendant.accountAutoAttendantId, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                                autoAttendantCallFlow.isAutoAttendantPromptCollapsible(true);
                                autoAttendantCallFlow.toggleAutoAttendantCallFlow = self.toggleAutoAttendantCallFlow;
                                autoAttendantCallFlow.activate();

                                attendant.autoAttendantCallFlowViewModel(autoAttendantCallFlow);
                                promise.resolve();
                            });
                        });

                        autoAttendantConstructorPromise.wait()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };

        const _getNextBatchOfAutoAttendants = () => {
            if (self.lastPage === false && _getNextBatchCalled === false){
                _getNextBatchCalled = true;
                let additionalAutoAttendantsShown = _state.autoAttendantsShown + ROWS_PER_PAGE;
                let additionalAutoAttendants = _state.filteredAutoAttendants.slice(_state.autoAttendantsShown, additionalAutoAttendantsShown);
                self.displayAutoAttendants.push.apply(self.displayAutoAttendants, additionalAutoAttendants);
                _state.autoAttendantsShown = additionalAutoAttendantsShown;
                if(_state.autoAttendantsShown >= _state.filteredAutoAttendants.length) {
                    self.lastPage = true;
                }
                _getNextBatchCalled = false;
            }
        };

        const _updateDisplayedAutoAttendants = () => {
            let filteredAutoAttendants;
            let filterContent = self.autoAttendantFilter();
            if (filterContent !== '') {
                let autoAttendantFilter = new FilterConstructor();
                autoAttendantFilter.addProperty('name');
                autoAttendantFilter.addProperty('modifiedDateTime.filterValue');
                autoAttendantFilter.addProperty('createdDateTime.filterValue');
                autoAttendantFilter.addProperty('routeSearchData');
                autoAttendantFilter.addProperty('routeToType');
                autoAttendantFilter.addProperty('routeType');
                autoAttendantFilter.addProperty('forwardingNumberPhoneNumber');
                autoAttendantFilter.addValue(filterContent);

                filteredAutoAttendants = autoAttendantFilter.filter(_state.availableAutoAttendants());

                _state.availableAutoAttendants().forEach(autoAttendant => {
                    let autoAttendantViewModel = autoAttendant.autoAttendantCallFlowViewModel();

                    if (autoAttendantViewModel) {
                        let subMenuItemsToFilter = autoAttendantViewModel.autoAttendant().subMenuItems;
                        let filteredSubMenuItem = autoAttendantFilter.filter(subMenuItemsToFilter);
                        _maybeAddToFilteredAutoAttendants(filteredSubMenuItem, autoAttendant, filteredAutoAttendants);

                        subMenuItemsToFilter.forEach(subMenuItem => {
                            let subMenuCallFlowsToFilter = subMenuItem.callFlow;

                            let filteredCallFlowItem = autoAttendantFilter.filter(subMenuCallFlowsToFilter);
                            _maybeAddToFilteredAutoAttendants(filteredCallFlowItem, autoAttendant, filteredAutoAttendants);
                            _filterCallFlow(subMenuCallFlowsToFilter, autoAttendant, autoAttendantFilter, filteredAutoAttendants);
                        });
                    }
                });
            }
            else {

                filteredAutoAttendants = _state.availableAutoAttendants();
            }

            _state.filteredAutoAttendants = filteredAutoAttendants;
        };

        const _maybeAddToFilteredAutoAttendants = (filteredItem, autoAttendant, filteredAutoAttendants) => {
            if (filteredItem.length > 0) {
                if (filteredAutoAttendants.every(filteredAutoAttendant => {
                        return filteredAutoAttendant.accountAutoAttendantId !== autoAttendant.accountAutoAttendantId;
                    })) {
                    filteredAutoAttendants.push(autoAttendant);
                }
            }
        };

        const _filterCallFlow = (callFlowToFilter, autoAttendant, autoAttendantFilter, filteredAutoAttendants) => {
            callFlowToFilter.forEach(callFlow => {

                if (callFlow.autoAttendant()) {
                    let autoAttendantViewModel = callFlow.autoAttendant();
                    let subMenuItemsToFilter = autoAttendantViewModel.autoAttendant().subMenuItems;

                    let filteredSubMenuItem = autoAttendantFilter.filter(subMenuItemsToFilter);
                    _maybeAddToFilteredAutoAttendants(filteredSubMenuItem, autoAttendant, filteredAutoAttendants);

                    subMenuItemsToFilter.forEach(subMenuItem => {
                        let subMenuCallFlowsToFilter = subMenuItem.callFlow;

                        let filteredCallFlowItem = autoAttendantFilter.filter(subMenuCallFlowsToFilter);
                        _maybeAddToFilteredAutoAttendants(filteredCallFlowItem, autoAttendant, filteredAutoAttendants);
                        _filterCallFlow(subMenuCallFlowsToFilter, autoAttendant, autoAttendantFilter, filteredAutoAttendants);
                    });
                }

                if (callFlow.subMenuItems) {
                    let childSubMenuItems = callFlow.subMenuItems;

                    childSubMenuItems.forEach(childSubMenuItem => {
                        let childSubMenuCallFlowsToFilter = childSubMenuItem.callFlow;

                        let filteredChildSubMenuItem = autoAttendantFilter.filter(childSubMenuCallFlowsToFilter);
                        _maybeAddToFilteredAutoAttendants(filteredChildSubMenuItem, autoAttendant, filteredAutoAttendants);
                        _filterCallFlow(childSubMenuCallFlowsToFilter, autoAttendant, autoAttendantFilter, filteredAutoAttendants);
                    });
                }

                if (callFlow.scheduleSegments) {
                    let scheduleSegmentsToFilter = callFlow.scheduleSegments;

                    scheduleSegmentsToFilter.forEach(scheduleSegment => {

                        let filteredScheduleSegment = autoAttendantFilter.filter(scheduleSegmentsToFilter);
                        _maybeAddToFilteredAutoAttendants(filteredScheduleSegment, autoAttendant, filteredAutoAttendants);

                        let filteredScheduleSegmentItem = autoAttendantFilter.filter(scheduleSegment.callFlow);
                        _maybeAddToFilteredAutoAttendants(filteredScheduleSegmentItem, autoAttendant, filteredAutoAttendants);
                        _filterCallFlow(scheduleSegment.callFlow, autoAttendant, autoAttendantFilter, filteredAutoAttendants);
                    });
                }
            });
        };

        const _onAutoAttendantFilterChanged = () => {
            _updateDisplayedAutoAttendants();
            self.lastPage = false;
            _state.autoAttendantsShown = ROWS_PER_PAGE;
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(_state.filteredAutoAttendants);
            }
            self.displayAutoAttendants(_state.filteredAutoAttendants.slice(0, _state.autoAttendantsShown));
        };

        const _sortDisplayedAutoAttendants = () => {
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(_state.availableAutoAttendants);
                _updateDisplayedAutoAttendants();
                self.lastPage = false;
                _state.autoAttendantsShown = ROWS_PER_PAGE;
                self.displayAutoAttendants(_state.filteredAutoAttendants.slice(0, _state.autoAttendantsShown));
            }
        };

        const _showIntegrityWarningsPrompt = (autoAttendantPresentationObject, integrityWarningPresentationObjects) => {
            _buildIntegrityWarningsModal(autoAttendantPresentationObject);
            self.integrityWarningModal.addIntegrityWarnings(integrityWarningPresentationObjects);
            self.integrityWarningModal.showModal();
        };

        const _showDeletePrompt = (autoAttendantPresentationObject) => {
            let autoAttendantId = autoAttendantPresentationObject.accountAutoAttendantId;
            _buildDeleteModal(autoAttendantPresentationObject);
            self.actionModal.showModal()
                .done((result) => {
                    if (result === DELETE_RESPONSE) {
                        _facade.deleteAutoAttendant(autoAttendantId)
                            .done(() => {
                                self.displayAutoAttendants.remove(autoAttendantPresentationObject);

                                _state.filteredAutoAttendants = _state.filteredAutoAttendants.filter(autoAttendant => {
                                    return autoAttendant.accountAutoAttendantId !== autoAttendantId;
                                });

                                _state.availableAutoAttendants(_state.availableAutoAttendants().filter(autoAttendant => {
                                    return autoAttendant.accountAutoAttendantId !== autoAttendantId;
                                }));

                                if(self.displayAutoAttendants().length < ROWS_PER_PAGE) {
                                    _getNextBatchOfAutoAttendants();
                                }
                            })
                            .fail((error) => {
                                if(error.constraintViolations && error.constraintViolations.length > 0) {
                                    _showIntegrityWarningsPrompt(autoAttendantPresentationObject, error.constraintViolations);
                                } else {
                                    error.accountAutoAttendantId = autoAttendantId;
                                    throw error;
                                }
                            });
                    }
                })
                .fail((error) => {
                    throw error;
                });

        };

        const _buildDeleteModal = (autoAttendantPresentationObject) => {
            let constructorParams = [
                autoAttendantPresentationObject,
                _state.availableAutoAttendants(),
                _state.availableSchedules,
                _state.availableVoicePrompts
            ];
            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: {key: 'deleteHeader', options: {type: _commonState.typeDisplay.autoAttendant}}})
                .setSubmitButtonText({i18n: 'delete'});
        };

        const _buildIntegrityWarningsModal = (autoAttendantPresentationObject) => {
            const displayType = _commonState.typeDisplay.autoAttendant;
            let autoAttendantConstructorParams = {
                autoAttendant: autoAttendantPresentationObject,
                availableAutoAttendants: _state.availableAutoAttendants(),
                availableSchedules: _state.availableSchedules,
                availableVoicePrompts: _state.availableVoicePrompts
            };
            self.integrityWarningModal
                .clearModal()
                .setHeading({key: 'delete', options: {type: displayType}})
                .addText({i18n: {key: 'inUse', options: {type: displayType.toLowerCase()}}})
                .setAutoAttendant(autoAttendantConstructorParams)
                .addIntegrityWarningText({i18n: {key: 'conflicts'}});
        };

        const _findAndReplacePrompts = (subMenuItems, accountVoicePromptId, audioData) => {
            subMenuItems.forEach(subMenuItem => {
                if (subMenuItem.callFlow) {
                    subMenuItem.callFlow.forEach((callFlowSegment) => {
                        if (callFlowSegment.routeToItemId === accountVoicePromptId) {
                            callFlowSegment.currentVoicePrompt(audioData);
                            callFlowSegment.conversionComplete(true);
                            callFlowSegment.conversionInProgress(false);
                        }
                        if (callFlowSegment.routeType() === _commonState.types.subMenu) {
                            if (callFlowSegment.routeToVoicePromptId === accountVoicePromptId || callFlowSegment.routeToSystemAudioClipId === accountVoicePromptId) {
                                callFlowSegment.voicePrompt.currentVoicePrompt(audioData);
                                callFlowSegment.voicePrompt.conversionComplete(true);
                                callFlowSegment.voicePrompt.conversionInProgress(false);
                            }
                        }
                        if (callFlowSegment.subMenuItems !== undefined) {
                            _findAndReplacePrompts(callFlowSegment.subMenuItems, accountVoicePromptId, audioData);
                        }
                    });
                }
            });
        };

        self.scrollEventSource = ScrollEventSources.settingsAutoAttendants;
        self.lastPage = false;
        self.isCompositionComplete = ko.observable(false);
        self.displayAutoAttendants = ko.observableArray();
        self.actionModal = null;
        self.integrityWarningModal = null;
        self.sortType = ko.observable("name");
        self.sortType.subscribe(_sortDisplayedAutoAttendants);
        self.addAutoAttendantUrl = _navigationConfiguration.routesById.addAutoAttendant.url;

        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "name", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.viewOptions = [
            {textI18n: 'expandAll', iconName: 'sortExpandAll', onClick: () => self.expandAllView()},
            {textI18n: 'collapseAll', iconName: 'sortCollapseAll', onClick: () => self.collapseAllView()},
        ];

        self.autoAttendantFilter = ko.observable('');
        self.autoAttendantFilter.subscribe(_onAutoAttendantFilterChanged);
        self.showSearch = ko.pureComputed(() => _state.availableAutoAttendants().length > 0);
        self.showResults = ko.pureComputed(() => self.displayAutoAttendants().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => _state.availableAutoAttendants().length > 0 ? "noResults" : "noAutoAttendants");

        self.navigateToEditAutoAttendant = (autoAttendantPresentationObject) => {
            _router.navigate(autoAttendantPresentationObject.editAutoAttendantUrl);
        };

        self.launchDeleteAutoAttendant = (autoAttendantPresentationObject) => {
            let autoAttendantId = autoAttendantPresentationObject.accountAutoAttendantId;
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.getConstraints(autoAttendantId)
                    .done((integrityWarningPresentationObjects) => {
                        if(integrityWarningPresentationObjects.length > 0) {
                            _showIntegrityWarningsPrompt(autoAttendantPresentationObject, integrityWarningPresentationObjects);
                        } else {
                            _showDeletePrompt(autoAttendantPresentationObject);
                        }
                        deferredObject.resolve();
                    });
            });
        };

        self.expandAllView = () => {
            self.displayAutoAttendants().forEach(autoAttendant => {
                autoAttendant.showCallFlow(true);
                autoAttendant.autoAttendantCallFlowViewModel().autoAttendant().subMenuItems.forEach(subMenuItem => {
                    subMenuItem.showSubMenuCallFlow(true);
                    subMenuItem.showSubMenuCallFlowDetails(true);
                    subMenuItem.showScheduleDetails(true);

                    subMenuItem.callFlow.forEach(item => {
                        if (item.scheduleSegments) {
                            item.showSubMenuCallFlow(true);
                            item.scheduleSegments.forEach(segment => segment.showScheduleSegmentDetails(true));
                        }
                        if (item.autoAttendant()) {
                            item.showSubMenuCallFlow(true);
                            item.autoAttendant().expandAllView();
                        }
                        if (item.subMenu()) {
                            item.showCallFlowDetails(true);
                            item.subMenu().expandAllView();
                        }
                    });
                });
            });
        };

        self.collapseAllView = () => {
            self.displayAutoAttendants().forEach((autoAttendant) => {
                autoAttendant.showCallFlow(false);
            });
        };

        self.toggleAutoAttendantCallFlow = (autoAttendant) => {
            const displayedAutoAttendant = self.displayAutoAttendants().find(displayedAutoAttendant => autoAttendant.accountAutoAttendantId === displayedAutoAttendant.accountAutoAttendantId);
            displayedAutoAttendant.showCallFlow(!displayedAutoAttendant.showCallFlow());
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _eventManager.unsubscribe(_getNextBatchOfAutoAttendantsEventId);
            _facade.dispose();
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/autoAttendant/facades/autoAttendantFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const WarningModalViewModelConstructor = require('presentation/common/warningModal/viewModels/autoAttendantIntegrityWarningModalViewModel');
            self.integrityWarningModal = new WarningModalViewModelConstructor();

            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            _getAutoAttendants();

            _facade.onNewPromptConverted((promptInfo) => {
                const {accountVoicePromptId, audioData} = promptInfo;

                if (_state.availableAutoAttendants() !== []) {
                    _state.availableAutoAttendants().forEach((autoAttendant) => {
                        let autoAttendantObject = autoAttendant.autoAttendantCallFlowViewModel().autoAttendant;
                        if (autoAttendantObject().routeToVoicePromptId === accountVoicePromptId || autoAttendantObject().routeToSystemAudioClipId === accountVoicePromptId) {
                            autoAttendantObject().voicePrompt.currentVoicePrompt(audioData);
                            autoAttendantObject().voicePrompt.conversionComplete(true);
                            autoAttendantObject().voicePrompt.conversionInProgress(false);
                        }
                        _findAndReplacePrompts(autoAttendantObject().subMenuItems, accountVoicePromptId, audioData);
                    });
                }
            });

            return _promiseFactory.wait();
        };
    };
});

