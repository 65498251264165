define('presentation/messages/facades/conversationMediaFacade',[
    'i18next',
    'businessServices/messages/messagesStateSingleton',
    'common/converters/phoneNumberFormatter',
    'common/converters/userFormatter',
    'common/storage/commonState',
    'persistence/dataProviders/webMessagingDataProvider',
    'presentation/messages/dataSources/webMessagingDataSource',
    'presentation/messages/viewModels/conversationMessageAttachmentViewModel',
    'presentation/messages/viewModels/conversationMessageViewModel'
], function(
    /** @type import('i18next') */
    i18n,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    MessagesState,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatterConstructor,
    /** @type typeof import('common/converters/userFormatter') */
    UserFormatterConstructor,
    /** @type typeof import('common/storage/commonState') */
    CommonState,
    /** @type typeof import('persistence/dataProviders/webMessagingDataProvider') */
    WebMessagingDataProvider,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    WebMessagingDataSource,
    /** @type typeof import('presentation/messages/viewModels/conversationMessageAttachmentViewModel') */
    ConversationMessageAttachmentViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationMessageViewModel') */
    ConversationMessageViewModel
) {
    /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */
    /** @typedef {import('presentation/messages/viewModels/conversationMessageViewModel')} ConversationMessageViewModel */

    return function () {
        /** @typedef {import('presentation/messages/facades/conversationMediaFacade')} ConversationMediaFacade } */

        /** @typeof {ConversationMediaFacade} */
        const self = this;

        const _webMessagingDataProvider = new WebMessagingDataProvider();
        const _userFormatter = new UserFormatterConstructor();
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        /**
         * @param {ISmsConversationMessage} smsMessage
         * @param {ILocationDataWithPhoneNumber[]} recipients
         * @param {string} hostedPhoneNumber
         * @returns {ConversationMessageViewModel}
         */
        const _parseMessage = (smsMessage, recipients, hostedPhoneNumber) => {
            const messageViewModel = new ConversationMessageViewModel();

            /** @type {ConversationMessageAttachmentViewModel[]} */
            const attachments = [];

            for (const attachment of smsMessage.smsConversationMessageAttachments) {
                const attachmentViewModel = new ConversationMessageAttachmentViewModel();
                attachmentViewModel.activate({
                    attachmentId: attachment.smsConversationMessageAttachmentId,
                    owningMessageId: smsMessage.smsConversationMessageId,
                    conversionStatus: attachment.conversionStatus,
                    sequence: attachment.sequence,
                    createdDateTime: attachment.createdDateTime,
                    remoteObjectUrl: attachment.s3FilePath,
                    remoteObjectContentType: attachment.contentType,
                    showLoadingState: false
                });

                attachments.push(attachmentViewModel);
            }

            const commonStateUser = CommonState.get(smsMessage.sendingUserId);
            const isSentByUser = !!smsMessage.sendingUserId;

            let messageLocationMeta = "";
            if (smsMessage.sendingMemberPhoneNumber) {
                const { phoneNumber, city, state } = recipients.find(x => x.phoneNumber === smsMessage.sendingMemberPhoneNumber);
                messageLocationMeta = _phoneNumberFormatter.isTollFree(phoneNumber) ?
                    i18n.t('tollFree') : _phoneNumberFormatter.formatLocation(city, state);
            }
            else {
                const numbers = WebMessagingDataSource.webMessagingNumbers();
                const hostedNumber = numbers.find(x => x.hostedPhoneNumber === hostedPhoneNumber);
                messageLocationMeta = _phoneNumberFormatter.formatLocation(hostedNumber.city, hostedNumber.state);
            }

            messageViewModel.activate({
                content: smsMessage.content,
                direction: smsMessage.direction,
                messageDateTime: smsMessage.messageDateTime,
                showMessageMetadata: true,
                sendingUserId: smsMessage.sendingUserId,
                sendingUserInitials: _userFormatter.formatUserInitials(smsMessage.sendingUserFirstName, smsMessage.sendingUserLastName),
                sendingUserAvatar: commonStateUser ? commonStateUser.avatar : undefined,
                sendingMemberPhoneNumber: smsMessage.sendingMemberPhoneNumber,
                sentByName: isSentByUser ?
                    _userFormatter.formatUserFullName(smsMessage.sendingUserFirstName, smsMessage.sendingUserLastName) :
                    _phoneNumberFormatter.toInternationalWithParens(smsMessage.sendingMemberPhoneNumber),
                showDateDivider: false,
                smsConversationMessageId: smsMessage.smsConversationMessageId,
                deliveryStatus: smsMessage.deliveryStatus,
                isNumberLimited: ko.pureComputed(() => true),
                attachments: attachments,
                locationMetaText: messageLocationMeta
            });

            return messageViewModel;
        };

        /** @type ConversationMediaFacade["getConversationSmsMessagesWithAttachments"] */
        self.getConversationSmsMessagesWithAttachments = (smsConversationId, take, skip) => {
            return _webMessagingDataProvider.getConversationSmsMessageAttachments({
                smsConversationId: smsConversationId,
                take: take,
                skip: skip
            })
                .then((data) => {
                    if (!data) {
                        return null;
                    }

                    // TODO - 2.3.0: we don't need to fetch the recipients and hosted number info every time...
                    const messages = data.messages.map(message => {
                        return _parseMessage(message, data.recipients, data.hostedPhoneNumber);
                    });

                    return {
                        messages,
                        recipients: data.recipients,
                        hostedPhoneNumber: data.hostedPhoneNumber
                    };
                });
        };

        /** @type ConversationMediaFacade["onSmsConversationMessage"] */
        self.onSmsConversationMessage = (callback) => {
            _webMessagingDataProvider.onSmsConversationMessageReceived(callback);
            _webMessagingDataProvider.onSmsConversationMessageSent(callback);
            _webMessagingDataProvider.onSmsConversationMessageUpdated(callback);
        };

        /** @type ConversationMediaFacade["dispose"] */
        self.dispose = () => {
            _webMessagingDataProvider.dispose();
        };
    };
});
