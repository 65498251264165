define('persistence/repositories/autoAttendantVoiceRouteRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(autoAttendantVoiceRouteEntity) {
            return _promiseFactory.defer(function(deferredObject) {
                var autoAttendantDocument = {
                        data : autoAttendantVoiceRouteEntity
                    };
                _kazooApi.callAccount('/lobby_autoattendant_voice_route', 'PUT', autoAttendantDocument)
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        error.autoAttendantVoiceRouteEntity = autoAttendantVoiceRouteEntity;
                        deferredObject.reject(error);
                    });
            });
        };

        self.update = function(autoAttendantVoiceRouteEntity) {
            if (autoAttendantVoiceRouteEntity.autoAttendantVoiceRouteId === null) {
                var err = new Error("Missing autoAttendantVoiceRouteId");
                err.lobbyAutoAttendantVoiceRoute = autoAttendantVoiceRouteEntity;
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var autoAttendantDocument = {
                            data: autoAttendantVoiceRouteEntity
                        };
                    _kazooApi.callAccount('/lobby_autoattendant_voice_route/'+autoAttendantVoiceRouteEntity.autoAttendantVoiceRouteId, 'POST', autoAttendantDocument)
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.autoAttendantVoiceRouteEntity = autoAttendantVoiceRouteEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.remove = function(autoAttendantVoiceRouteId) {
            if (autoAttendantVoiceRouteId === null) {
                throw new Error("Invalid value for autoAttendantVoiceRouteId");
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_autoattendant_voice_route/' + autoAttendantVoiceRouteId,'DELETE')
                        .done(function(result) {
                            deferredObject.resolve(result);
                        })
                        .fail(function(error) {
                            error.autoAttendantVoiceRouteId = autoAttendantVoiceRouteId;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.removeList = function(autoAttendantVoiceRouteIds) {
            return _promiseFactory.defer(function(deferredObject) {
                var autoAttendantVoiceRouteIdsDocument = {
                    data : autoAttendantVoiceRouteIds
                };

                _kazooApi.callAccount('/lobby_autoattendant_voice_route/manyDeletes','DELETE', autoAttendantVoiceRouteIdsDocument)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

