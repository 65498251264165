define('presentation/inbox/viewModels/inboxVoicemailSidebarViewModel',['common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/colorConstants',
        'presentation/inbox/dataSources/voicemailSubscriptionDataSource',
        'presentation/inbox/facades/inboxSidebarFacade',
        'presentation/inbox/viewModels/inboxSidebarHeaderViewModel',
        'presentation/inbox/viewModels/inboxVoicemailViewModel'
], function () {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const inboxSidebarHeaderViewModel = require('presentation/inbox/viewModels/inboxSidebarHeaderViewModel');

        const _colorConstants = require('constants/colorConstants');
        const _commonState = require('common/storage/commonState');
        const _inboxVoicemailViewModel = require('presentation/inbox/viewModels/inboxVoicemailViewModel');
        const _voicemailSubscriptionDataSource = require('presentation/inbox/dataSources/voicemailSubscriptionDataSource');

        let _disposables = [];
        let _facade = null;
        let _hasScreenBeenInitialized = false;

        let _availableSubscriptions = _voicemailSubscriptionDataSource.subscriptions;

        let isDataLoaded = ko.observable(false);

        const _initializeScreen = () => {
            inboxSidebarHeaderViewModel.isVoicemailActive(true);
            inboxSidebarHeaderViewModel.searchFilter = searchFilter;

            if (_hasScreenBeenInitialized) {
                return;
            }
            _hasScreenBeenInitialized = true;

            return _promiseFactory.defer((promise) => {
                _initializeFilters()
                    .fail(promise.reject)
                    .done(() => {
                        _initializeSubscriptions()
                            .fail(promise.reject)
                            .done(() => {
                                isDataLoaded(true);
                                _initializeMessages()
                                    .fail(promise.reject)
                                    .done(promise.resolve);
                            });
                    });
            });
        };

        const _initializeFilters = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getAllNewReviewedVoicemailFilterState()
                    .fail(promise.reject)
                    .done((newOrReviewed) => {
                        searchFilter('');
                        startOn('');
                        endOn('');
                        selectedShowFilter(newOrReviewed);

                        promise.resolve();
                    });
            });
        };

        const _initializeSubscriptions = () => {
            return _promiseFactory.defer((promise) => {
                _voicemailSubscriptionDataSource.waitForDataWaitHandle.waitForSignal(() => {
                    promise.resolve();
                });
            });
        };

        const _initializeMessages = () => {
            return _promiseFactory.defer((promise) => {
                if (isDataLoaded() === false) {
                    promise.resolve();
                    return;
                }

                _inboxVoicemailViewModel.refreshMessageListing()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _onShowFilterChanged = () => {
            const status = selectedShowFilter();
            _facade.setAllNewReviewedVoicemailFilterStatus(status);

            _onSearchCriteriaChanged();
        };

        const _onSearchCriteriaChanged = () => {
            _initializeMessages();
        };

        const _onSearchTextChanged = () =>  {
            let searchText = searchFilter();
            _inboxVoicemailViewModel.searchTextChanged(searchText);
        };

        const clearFilters = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                selectedShowFilter('all');
                selectedTimeframeFilter('');
                startOn('');
                endOn('');
                selectedSubscriptions([]);
                promise.resolve();
            });
        };

        const getSearchOptions = () => {
            let selectedVoicemailBoxIds = [];

            if (selectedSubscriptions().length > 0) {
                selectedSubscriptions().forEach((subscription) => {
                    selectedVoicemailBoxIds.push(subscription.voicemailBoxId);
                });
            } else {
                let options = subscriptionOptions();
                options.forEach((option) => {
                    selectedVoicemailBoxIds.push(option.voicemailBoxId);
                });
            }

            let startDate = startOn();
            let endDate = endOn();
            let searchText = searchFilter();
            let searchStatus = selectedShowFilter();

            return {
                startDate,
                endDate,
                searchText,
                searchStatus,
                selectedVoicemailBoxIds,
            };
        };

        const longClickSpinnerColor = _colorConstants.colorSecondaryCool200;

        const searchFilter = ko.observable("").extend({throttle: 300});
        _disposables.push(searchFilter.subscribe(_onSearchTextChanged));

        const startOn = ko.observable("");
        _disposables.push(startOn.subscribe(_onSearchCriteriaChanged));

        const endOn = ko.observable("");
        _disposables.push(endOn.subscribe(_onSearchCriteriaChanged));

        const selectedShowFilter = ko.observable('');
        _disposables.push(selectedShowFilter.subscribe(_onShowFilterChanged));

        const selectedTimeframeFilter = ko.observable('');

        const showFilterOptions = [
            {title: 'All', value: 'all'},
            {title: 'New', value: 'new'},
            {title: 'Reviewed', value: 'reviewed'}
        ];

        const subscriptionOptions = ko.computed(() => {
            if (isDataLoaded() === false) {
                return [];
            }

            const availableSubscriptions = _availableSubscriptions();
            if (availableSubscriptions.length === 0) {
                return [];
            }

            const voicemailBoxes = _commonState.voicemailBoxes()
                .filter(voicemailBox => {
                    return availableSubscriptions.find((subscription) => {
                        return voicemailBox.id === subscription.voicemailBoxId;
                    });
                })
                .map(voicemailBox => {
                    const commonStateOwnerItem = _commonState.get(voicemailBox.ownerId());
                    if (commonStateOwnerItem) {
                        const name = voicemailBox.name() === "undefined" ? {name: commonStateOwnerItem.name()} : {};
                        const avatarType = {avatarType: commonStateOwnerItem.type};
                        const avatar = commonStateOwnerItem.isUser ? {avatar: commonStateOwnerItem.avatar} : {avatar: true};
                        const voicemailBoxId = {voicemailBoxId: voicemailBox.id};
                        return Object.assign({}, voicemailBox, avatarType, avatar, name, voicemailBoxId);
                    } else {
                        const iconProperty = {iconProperty: _commonState.types.voicemailBox};
                        const voicemailBoxId = {voicemailBoxId: voicemailBox.id};
                        return Object.assign({}, voicemailBox, iconProperty, voicemailBoxId);
                    }
                });
            const sorter = new SorterConstructor();
            sorter.sort(voicemailBoxes, "name");
            return voicemailBoxes;
        });

        const areSubscriptionOptionsAvailable = ko.computed(() => {
            return subscriptionOptions().length > 0;
        });

        const selectedSubscriptions = ko.observableArray([]).extend({observeState: true});
        _disposables.push(selectedSubscriptions.subscribe(_onSearchCriteriaChanged));

        const isClearFiltersButtonVisible = ko.computed(() => {
            return (selectedShowFilter() !== '' && selectedShowFilter() !== 'all') ||
                selectedTimeframeFilter() !== '' ||
                selectedSubscriptions().length > 0;
        });

        const filterAvailableSubscriptions = (allSubscriptions, selectedSubscriptions) => {
            const sorter = new SorterConstructor();
            sorter.sort(allSubscriptions, "name", true);
            return allSubscriptions.filter((subscription) => {
                return undefined === selectedSubscriptions.find((selectedSubscription) => {
                    return subscription.voicemailBoxId === selectedSubscription().voicemailBoxId;
                });
            });
        };

        const _detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        const _initialize = () => {
            const FacadeConstructor = require('presentation/inbox/facades/inboxSidebarFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            inboxSidebarHeaderViewModel.activate();

            return _promiseFactory.wait();
        };

        const onLogin = () => {
            // Nothing to do
        };

        const onLogout = () => {
            _hasScreenBeenInitialized = false;
        };

        const sidebar = {
            clearFilters,
            getSearchOptions,
            filterAvailableSubscriptions,

            longClickSpinnerColor,
            isClearFiltersButtonVisible,
            searchFilter,
            startOn,
            endOn,
            selectedShowFilter,
            selectedSubscriptions,
            showFilterOptions,
            subscriptionOptions,
            areSubscriptionOptionsAvailable,
            inboxSidebarHeaderViewModel,
            isDataLoaded,
            selectedTimeframeFilter,

            // Durandal Events
            attached : _initializeScreen,
            activate : _initialize,
            detached : _detached,

            alerts : {
                receiveAlertLoginSuccessful : onLogin,
                receiveAlertLogout : onLogout,
            },
        };

        _inboxVoicemailViewModel.setSidebar(sidebar);
        
        return sidebar;
    });

