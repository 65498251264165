define('common/authentication/authenticatedSignup',[], function() {
    var AuthenticatedSignupSingleton = function() {
        var self = this;

        if (AuthenticatedSignupSingleton.prototype._singletonInstance) {
            return AuthenticatedSignupSingleton.prototype._singletonInstance;
        }
        AuthenticatedSignupSingleton.prototype._singletonInstance = self;

        self.signupSessionId = "";
        self.accountNumber = "";
        self.secretKey = "";
        self.workSessionId = "";
    };

    return new AuthenticatedSignupSingleton();
});

