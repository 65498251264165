define('persistence/repositories/accountRepository',['externalDependencies/kazooApi',
        'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.updateWelcomeInfoComplete = function() {
            return _promiseFactory.defer(function(deferredObject) {
               _kazooApi.callAccount('/lobby_account/welcome_info_complete', 'POST')
                   .fail(deferredObject.reject)
                   .done(function(result) {
                       deferredObject.resolve(result.success);
                   });
            });
        };
    };
});
