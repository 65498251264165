define('presentation/settings/prompts/presentationObjects/promptsPresentationObject',[],function() {
    return function(){
        const self = this;

        self.voicePromptId = null;
        self.promptName = null;
        self.voicePromptDuration = ko.observable(null);
        self.conversionComplete = false;
        self.conversionInProgress = false;
        self.previewPrompt = null;
        self.voicePrompt = null;
        self.createdDateTime = null;
        self.currentVoicePrompt = ko.observable(null);
        self.editPromptUrl = null;
        self.displayPrompt = ko.observable(false);
    };
});

