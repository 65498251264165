define('constants/signupSessionStatusConstants',[], function() {
    return {
        'inProgress': 1,
        'initiated': 2,
        'validated': 3,
        'numberProvisioningFailed': 4,
        'numberProvisioningSucceeded': 5,
        'preAuthorizationFailed': 6,
        'preAuthorizationSucceeded': 7,
        'accountCreationPending': 8,
        'accountCreationInProgress': 9,
        'accountCreationCompleted': 10,
        'accountCreationFailedRetryQueued': 11,
        'accountCreationFailed': 12
    };
});
