define('businessServices/authentication/stores/sessionWorkflowFlagsStore',['common/storage/workSessionDataStorage', 'common/storage/machineStorage'], function(workSessionDataStorage, machineStorage) {
    var _useWorkSessionStorage = false;

    var KEY = "tresta-flags";

    function hasValue() {
        if (_useWorkSessionStorage) {
            return workSessionDataStorage.hasObject(KEY);
        } else {
            return machineStorage.hasExpiringObject(KEY);
        }
    }

    function getValue() {
        if (_useWorkSessionStorage) {
            return workSessionDataStorage.getObject(KEY);
        } else {
            return machineStorage.getExpiringObject(KEY);
        }
    }

    function saveValue(value) {
        if (_useWorkSessionStorage) {
            return workSessionDataStorage.saveObject(KEY, value);
        } else {
            return machineStorage.saveExpiringObject(KEY, value, 4, 1);
        }
    }

    function deleteValue() {
        if (_useWorkSessionStorage) {
            workSessionDataStorage.deleteObject(KEY);
        } else {
            machineStorage.deleteObject(KEY);
        }
    }

    function setStoreForLoginType(loginType) {
        switch (loginType) {
            case "impersonation":
                _useWorkSessionStorage = true;
                break;
            case "standard":
                _useWorkSessionStorage = false;
                break;
            default:
                var err = new Error("Unknown login type");
                err.loginType = loginType;
                throw err;
        }
    }

    return {
        hasValue : hasValue,
        getValue : getValue,
        saveValue : saveValue,
        deleteValue : deleteValue,
        setStoreForLoginType : setStoreForLoginType
    };
});
