define('businessServices/specifications/validPostalCodeSpecification',[
    'common/promises/promiseFactory',
    'constants/countryEnumerations',
    'persistence/webSocket/webSocketApp'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const promiseFactory = new PromiseFactoryConstructor();

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        const _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("validation");

        const _countryEnumerations = require('constants/countryEnumerations');

        const _validatePostalCode = (/** @type { string } */ postalCode, postalCodeRegularExpression) => {
            const postalCodeTrimmed = postalCode.trim();
            return postalCodeRegularExpression.test(postalCodeTrimmed);
        };

        self.isSatisfiedBy = (/** @type { string } */ postalCode, /** @type { string } */ country) => {
            switch (country) {
                case _countryEnumerations.usa:
                    return _validatePostalCode(postalCode, /^\d{5}$/);

                case _countryEnumerations.ca:
                    return _validatePostalCode(postalCode, /^(?:[ABCEGHJKLMNPRSTVXY]\d)(?:[ABCEGHJKLMNPRSTVWXYZ]\d){2}$/i);

                default:
                    return false;
            }
        };

        self.doesPostalCodeExist = (/** @type { string } */ postalCode) => {
            const socketParams = {
                postalCode: postalCode
            };
            return promiseFactory.deferIndefinitely((promise) => {
                _webSocketApp.send("validatePostalCode", socketParams, (result) => {
                    promise.resolve(result.status === "success");
                });
            });
        };
    };
});
