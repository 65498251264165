define('presentation/settings/messagingCompliance/viewModels/messagingComplianceLocalMessagingTabViewModel',[
        'businessServices/country/countryRegions',
        'businessServices/state/modelStateObserver',
        'common/collections/enumerationKeyValueConverter',
        'common/promises/promiseFactory',
        'constants/countryEnumerations',
        'constants/formOfBusinessEnumerations',
        'constants/localUseCaseConstants',
        'constants/smsCampaignRegistrationConstants',
        'i18next',
        'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceLocalPresentationObject',
        'presentation/settings/messagingCompliance/validators/messagingComplianceLocalMessagingTabValidator'
    ],
    function (
        /** @type typeof import('businessServices/country/countryRegions') */
        CountryRegionsConstructor,
        /** @type typeof import('businessServices/state/modelStateObserver') */
        ModelStateObserver,
        /** @type import('common/collections/enumerationKeyValueConverter') */
        _keyValueConverter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('constants/countryEnumerations') */
        _countries,
        /** @type typeof import('constants/formOfBusinessEnumerations') */
        _formOfBusiness,
        /** @type typeof import('constants/localUseCaseConstants') */
        _localUseCases,
        /** @type typeof import('constants/smsCampaignRegistrationConstants') */
        _smsCampaignRegistrationConstants,
        /** @type import('i18next') */
        _i18n,
        /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceLocalPresentationObject') */
        MessagingComplianceLocalPresentationObject
    ) {
        return function (parent) {
            /** @typedef { import('presentation/settings/messagingCompliance/validators/messagingComplianceLocalMessagingTabValidator') } MessagingComplianceLocalMessagingTabValidator } */

            const self = this;
            const _parent = parent;

            const _countryRegions = new CountryRegionsConstructor();
            const _promiseFactory = new PromiseFactory();

            /** @type { MessagingComplianceLocalMessagingTabValidator } */
            let _validator = null;

            /** @type { IDisposable[] } */
            let _disposables = [];

            const DROPDOWN_OPTION_YES = {
                id: "1",
                description: "Yes"
            };
            const DROPDOWN_OPTION_NO = {
                id: "0",
                description: "No"
            };
            const YES_NO_DROPDOWN_OPTIONS = [DROPDOWN_OPTION_NO, DROPDOWN_OPTION_YES];

            self.isCompositionComplete = ko.observable(false);
            self.modelStateObserver = null;
            self.yesNoDropdownOptions = ko.observableArray(YES_NO_DROPDOWN_OPTIONS);

            self.isLocalSmsFormEnabled = ko.observable(false).extend({observeState: true});
            self.showLocalNumberIsEnabledMessage = ko.pureComputed(() => {
                return self.isLocalSmsFormEnabled() && self.hasLocalNumberWithMessagingEnabled();
            });
            self.showInFreeTrialMessage = ko.pureComputed(() => _parent.isAccountInFreeTrial());
            self.isToggleDisabled = ko.pureComputed(() => self.showLocalNumberIsEnabledMessage() || self.showInFreeTrialMessage());

            self.hasLocalNumberWithMessagingEnabled = ko.observable(false);
            self.toggleMessageKey = ko.pureComputed(() =>  self.isLocalSmsFormEnabled() ? 'messagingComplianceLocalMessagingTab:enabled' : 'messagingComplianceLocalMessagingTab:disabled' );

            self.smsComplianceLocalId = null;
            self.useCases = ko.observableArray([]);
            self.selectedUseCases = ko.observableArray([]).extend({observeState: true});

            self.messageTextComplianceError = ko.observable("").extend({observeState: true});

            self.summaryOfTexting = ko.observable("").extend({observeState: true});
            self.sampleTextMessageOne = ko.observable("").extend({observeState: true});
            self.sampleTextMessageTwo = ko.observable("").extend({observeState: true});
            self.sampleTextMessageThree = ko.observable("").extend({observeState: true});
            self.sampleTextMessageFour = ko.observable("").extend({observeState: true});

            self.sampleTextMessageOneOutput = ko.pureComputed(() => {
                let input = self.sampleTextMessageOne().trim().length > 0 ?
                    self.sampleTextMessageOne().trim() :
                    `[${_i18n.t('messagingComplianceLocalMessagingTab:labelTextMessageSampleOne')}]`;

                return `${input} ${_i18n.t('messagingComplianceLocalMessagingTab:replyStop')}`;
            });

            self.isCustomerConsentLeadCaptureForms = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentInitiateConversation = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentEmployeeRelationship = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentOnlineCheckout = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentPosTransaction = ko.observable(false).extend({observeState: true});
            self.isCustomerConsentOther = ko.observable(false).extend({observeState: true});
            self.customerConsentOther = ko.observable("").extend({observeState: true});

            self.messageTextConsentError = ko.observable("").extend({observeState: true});

            self.isUseEligibilityAcknowledged = ko.observable(false).extend({observeState: true});
            self.messageUseEligibilityError = ko.observable("").extend({observeState: true});

            self.mobilePhoneNumber = ko.observable("").extend({observeState: true});
            self.mobilePhoneNumberCountryAbbreviation = ko.observable(_countries.usa).extend({observeState: true});

            self.selectPlaceHolderMessage = ko.pureComputed(() => _i18n.t("messagingComplianceLocalMessagingTab:select"));

            self.showMobilePhoneNumberField = ko.pureComputed(() => {
                return _parent.messagingComplianceProfileTabViewModel.formOfBusiness() === _formOfBusiness.SoleProprietor.toString();
            });

            self.isOtpVerificationDisabled = ko.pureComputed(() => {
                return !_parent.isEditMode() ||
                    _parent.tcrRegistrationStatus() === _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.NONE ||
                    _parent.tcrRegistrationStatus() === _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.FAILED;
            });

            self.isOtpVerificationPending = ko.pureComputed(() => {
                return _parent.accountHasSoleProprietorBrandPendingSmsPinVerification();
            });

            self.otpVerificationLinkTitle = ko.pureComputed(() => {
                if (self.isOtpVerificationDisabled()) {
                    return _i18n.t('messagingComplianceLocalMessagingTab:labelVerificationDescriptionUnverified');
                } else if (self.isOtpVerificationPending()) {
                    return _i18n.t('messagingComplianceLocalMessagingTab:labelVerificationLink');
                } else {
                    return _i18n.t('messagingComplianceLocalMessagingTab:labelVerificationDescriptionVerified');
                }
            });

            self.useCase = ko.pureComputed(() => {
                if (_parent.messagingComplianceProfileTabViewModel.formOfBusiness() === _formOfBusiness.SoleProprietor.toString()){
                    return _localUseCases.SoleProprietor;
                } else {
                    return _localUseCases.LowVolumeMixed;
                }
            });

            self.openSmsOtpVerificationModal = _parent.openSmsOtpVerificationModal;

            self.validate = () => _validator.validate();

            self.resetValidation = () => {
                _validator.resetValidation();
            };

            self.cancelForm = () => {
                if (self.messageTextComplianceError.isDirty) {
                    self.messageTextComplianceError.forceUpdate(true);
                }
                if (self.messageTextConsentError.isDirty) {
                    self.messageTextConsentError.forceUpdate(true);
                }
            };


            self.getForm = () => {
                const messagingComplianceLocal = new MessagingComplianceLocalPresentationObject();
                messagingComplianceLocal.smsComplianceLocalId = self.smsComplianceLocalId;
                messagingComplianceLocal.summaryOfTexting = self.summaryOfTexting;
                messagingComplianceLocal.sampleTextMessageOne = self.sampleTextMessageOne;
                messagingComplianceLocal.sampleTextMessageTwo = self.sampleTextMessageTwo;
                messagingComplianceLocal.sampleTextMessageThree = self.sampleTextMessageThree;
                messagingComplianceLocal.sampleTextMessageFour = self.sampleTextMessageFour;
                messagingComplianceLocal.isCustomerConsentLeadCaptureForms = self.isCustomerConsentLeadCaptureForms;
                messagingComplianceLocal.isCustomerConsentInitiateConversation = self.isCustomerConsentInitiateConversation;
                messagingComplianceLocal.isCustomerConsentEmployeeRelationship = self.isCustomerConsentEmployeeRelationship;
                messagingComplianceLocal.isCustomerConsentOnlineCheckout = self.isCustomerConsentOnlineCheckout;
                messagingComplianceLocal.isCustomerConsentPosTransaction = self.isCustomerConsentPosTransaction;
                messagingComplianceLocal.isCustomerConsentOther = self.isCustomerConsentOther;
                messagingComplianceLocal.customerConsentOther = self.customerConsentOther;
                messagingComplianceLocal.isIneligibleUseCaseAcknowledged = self.isUseEligibilityAcknowledged;
                messagingComplianceLocal.mobilePhoneNumber = self.mobilePhoneNumber;
                messagingComplianceLocal.mobilePhoneNumberCountryAbbreviation = self.mobilePhoneNumberCountryAbbreviation;
                messagingComplianceLocal.useCase(self.useCase());
                messagingComplianceLocal.subUseCases(self.selectedUseCases().join());
                messagingComplianceLocal.isLocalSmsFormEnabled = self.isLocalSmsFormEnabled;

                return messagingComplianceLocal;
            };

            self.dispose = () => {
                self.isCompositionComplete(false);
                _disposables.forEach(d => d.dispose());
                _disposables = [];
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = (/** @type { MessagingComplianceLocalPresentationObject } */ localData) => {
                let modelStateObserver = new ModelStateObserver(self);

                const ValidatorConstructor = require('presentation/settings/messagingCompliance/validators/messagingComplianceLocalMessagingTabValidator');
                _validator = new ValidatorConstructor();

                return _initialize(localData, modelStateObserver);
            };

            const _initialize = (localData, modelStateObserver) => {
                return _promiseFactory.defer((initPromise) => {

                    if (localData) {
                        self.smsComplianceLocalId = localData.smsComplianceLocalId;
                        _initializeObservables(localData);
                    }

                    self.modelStateObserver = modelStateObserver;
                    self.modelStateObserver.commitData();

                    _validator.registerViewModel(self);

                    initPromise.resolve();
                });
            };

            const _initializeObservables = (localData) => {
                self.summaryOfTexting(localData.summaryOfTexting());
                self.sampleTextMessageOne(localData.sampleTextMessageOne());
                self.sampleTextMessageTwo(localData.sampleTextMessageTwo());
                self.sampleTextMessageThree(localData.sampleTextMessageThree());
                self.sampleTextMessageFour(localData.sampleTextMessageFour());
                self.isCustomerConsentLeadCaptureForms(localData.isCustomerConsentLeadCaptureForms());
                self.isCustomerConsentInitiateConversation(localData.isCustomerConsentInitiateConversation());
                self.isCustomerConsentEmployeeRelationship(localData.isCustomerConsentEmployeeRelationship());
                self.isCustomerConsentOnlineCheckout(localData.isCustomerConsentOnlineCheckout());
                self.isCustomerConsentPosTransaction(localData.isCustomerConsentPosTransaction());
                self.isCustomerConsentOther(localData.isCustomerConsentOther());
                self.customerConsentOther(localData.customerConsentOther());
                self.isUseEligibilityAcknowledged(localData.isIneligibleUseCaseAcknowledged());
                self.isLocalSmsFormEnabled(localData.isLocalSmsFormEnabled());
                self.mobilePhoneNumber(localData.mobilePhoneNumber());
                if (localData.mobilePhoneNumberCountryAbbreviation) {
                    const mobilePhoneNumberCountry = _countryRegions.findByAbbreviation(localData.mobilePhoneNumberCountryAbbreviation()).countryAbbreviation;
                    self.mobilePhoneNumberCountryAbbreviation(mobilePhoneNumberCountry);
                }
                if (localData.subUseCases()) {
                    const useCases = localData.subUseCases().split(',');
                    self.selectedUseCases(useCases);
                }

                self.useCases(_formatUseCaseOptions());

                self.hasLocalNumberWithMessagingEnabled(localData.hasLocalNumberWithMessagingEnabled());
            };

            const _formatUseCaseOptions = () => {
                const allUseCases = _keyValueConverter.convertToKeyValues(_localUseCases)
                    .filter((useCase) => {
                        return useCase.value !== _localUseCases.LowVolumeMixed &&
                            useCase.value !== _localUseCases.SoleProprietor;
                    })
                    .map((option) => {

                        /** @type { IUseCaseOption } */
                        const useCaseOption = Object.assign({}, option, {
                            labelI18nKey: `messagingComplianceLocalMessagingTab:useCase${option.key}.label`,
                            tooltipText: _i18n.t(`messagingComplianceLocalMessagingTab:useCase${option.key}.tooltip`),
                            isSelected: ko.observable(self.selectedUseCases().includes(option.value)),
                            isDisabled: ko.pureComputed(() => {
                                const isSelected = self.selectedUseCases().includes(option.value);
                                return !isSelected && self.selectedUseCases().length >= 5;
                            })
                        });

                        _disposables.push(
                            useCaseOption.isSelected.subscribe(() => {
                                const currentSelection = self.selectedUseCases();
                                let updatedSelection;

                                const alreadySelected = currentSelection.includes(option.value);

                                if (alreadySelected) {
                                    updatedSelection = currentSelection.filter((u) => u !== option.value);
                                } else {
                                    updatedSelection = currentSelection;
                                    updatedSelection.push(option.value);
                                }

                                self.selectedUseCases(updatedSelection);
                            })
                        );

                        return useCaseOption;
                    });

                /** @type { IUseCaseOption[] }*/
                return allUseCases;
            };
        };
    });
