define('presentation/settings/voicemailBoxes/facades/addVoicemailBoxFacade',[
        'externalDependencies/clientApi',
        'externalDependencies/clientWebSocket',
], function() {
    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _addVoicemailBoxWebSocket = clientWebSocket.forApplication("addVoicemailBox");

    let _clientApi = null;
    let _promiseFactory = null;

    const _getVoicemailBox = (voicemailBoxId) => {
        return _promiseFactory.defer((promise) => {
            _clientApi.call("settings/voicemailBoxes/retrieve", "POST", {voicemailBoxId : voicemailBoxId})
                .done(promise.resolve);
        });
    };

    const _createVoicemailBox = (standaloneName) => {
        return _promiseFactory.defer((promise) => {
            _addVoicemailBoxWebSocket.send("create", {standaloneName : standaloneName}, (response) => {
                promise.resolve(response.voicemailBoxId);
            });
        });
    };

    const _updateVoicemailBox = (voicemailBoxId, voicemailBox) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                voicemailBoxId : voicemailBoxId,
                standaloneName : voicemailBox.standaloneName,
                customGreetingId : voicemailBox.customGreetingId,
                systemGreetingId : voicemailBox.systemGreetingId,
            };

            _addVoicemailBoxWebSocket.send("update", request, (result) => {
                if (result.isSuccessful) {
                    promise.resolve();
                } else {
                    promise.reject(new Error("error updating voicemailbox"));
                }
            });
        });
    };

    const _isNameUnique = (voicemailBoxId, standaloneName) => {
        return _promiseFactory.defer((promise) => {
            const request = {};
            _clientApi.call("settings/voicemailBoxes/retrieve", "POST", request)
                .done((result) => {
                    let standaloneBoxes = result.data;
                    let isUnique = standaloneBoxes.every(standaloneBox => standaloneName !== standaloneBox.standaloneName || voicemailBoxId === standaloneBox.voicemailBoxId);
                    promise.resolve(isUnique);
                });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const ClientApiConstructor = require('externalDependencies/clientApi');
        _clientApi = new ClientApiConstructor();
        _clientApi.init();
    };

    return function() {
        const self = this;

        self.init = _init;
        self.createVoicemailBox = _createVoicemailBox;
        self.getVoicemailBox = _getVoicemailBox;
        self.updateVoicemailBox = _updateVoicemailBox;
        self.isNameUnique = _isNameUnique;
    };
});
