define('presentation/settings/userProfile/facades/userProfileFacade',['businessServices/authentication/sessionAccountInfo',
        'businessServices/events/eventManager',
        'common/converters/accountNumberFormatter',
        'common/promises/promiseFactory',
        'persistence/fileStores/userAvatarFileStore',
        'persistence/webSocket/webSocketApp',
        'presentation/common/userAvatar/userAvatarImageFactory',
        'presentation/settings/userProfile/presentationObjects/userProfilePresentationObject',
], function() {
    const AccountNumberFormatterConstructor = require('common/converters/accountNumberFormatter');
    const _accountNumberFormatter = new AccountNumberFormatterConstructor();

    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = null;

    const UserProfilePresentationObjectConstructor = require('presentation/settings/userProfile/presentationObjects/userProfilePresentationObject');

    let _eventManager = null;
    let _sessionAccountInfo = null;
    let _userAvatarFileStore = null;
    let _userAvatarImageFactory = null;
    let _webSocketApp = null;
    let _webSocketAppValidate = null;

    const _isImpersonating = () => {
        return _sessionAccountInfo.isImpersonating();
    };

    const _saveUserProfile = (userId, avatar, firstName, lastName, title, emailAddress, password, timeZone) => {
        return _promiseFactory.defer((deferredObject) => {

            const userProfileData = {
                userId: userId,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                title: title.trim(),
                emailAddress: emailAddress.trim(),
                timeZone: timeZone
            };

            const localPromise = new PromiseFactoryConstructor();
            localPromise.deferIndefinitely((userProfileDeferredObject) => {
                _webSocketApp.send("updateUser", userProfileData, (result) => {
                    if (result.status === "error") {
                        if (result.reason === "extension_already_used") {
                            _eventManager.publishUserInfoChanged(userId);
                        }
                        userProfileDeferredObject.reject(new Error(result.reason));
                    } else {
                        userProfileDeferredObject.resolve(result);
                    }
                });
            });

            if (avatar &&
                    (avatar.showImage() === true) &&
                    (avatar.isSaved === false)) {
                localPromise.defer((userAvatarDeferredObject) => {
                    _userAvatarFileStore.uploadUserAvatar(userId, avatar)
                        .done(userAvatarDeferredObject.resolve)
                        .fail(userAvatarDeferredObject.reject);
                });
            }

            localPromise.wait()
                .fail(deferredObject.reject)
                .done((result) => {
                    _eventManager.publishUserInfoChanged(userId);
                    deferredObject.resolve();
                });
        });
    };

    const _getUserProfile = (userId, ignoreCache) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketApp.send("getUserProfile", {userId: userId}, (result) => {
                const userProfile = new UserProfilePresentationObjectConstructor();
                userProfile.accountNumber = _accountNumberFormatter.formatAccountNumber(result.accountNumber);
                userProfile.firstName = result.firstName;
                userProfile.lastName = result.lastName;
                userProfile.title = result.title;
                userProfile.emailAddress = result.emailAddress;
                userProfile.timeZone = result.timeZone;
                userProfile.status = result.status;

                _userAvatarImageFactory.getAvatarByUserId(userId, ignoreCache)
                    .fail(deferredObject.reject)
                    .done((avatar) => {
                        userProfile.avatar = avatar;
                        deferredObject.resolve(userProfile);
                    });
            });
        });
    };

    const _isEmailAddressUnique = (emailAddress) => {
        return _promiseFactory.defer((deferredObject) => {
            _webSocketAppValidate.send("is_email_address_unique", {emailAddress: emailAddress}, (result) => {
                deferredObject.resolve(result);
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_profile");

        _webSocketAppValidate = new WebSocketAppConstructor();
        _webSocketAppValidate.init("validation");

        const UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
        _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
        _userAvatarImageFactory.init();

        const UserAvatarFileStoreConstructor = require('persistence/fileStores/userAvatarFileStore');
        _userAvatarFileStore = new UserAvatarFileStoreConstructor();
        _userAvatarFileStore.init();

        _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        _eventManager = require('businessServices/events/eventManager');
    };

    return function() {
        const self = this;

        self.init = _init;

        self.getUserProfile = _getUserProfile;
        self.saveUserProfile = _saveUserProfile;
        self.isEmailAddressUnique = _isEmailAddressUnique;
        self.isImpersonating = _isImpersonating;
    };
});

