/**
 Configuration for site navigation.  Configuration is used to associate URLs to viewModules
 and to provide enumerations for navigation items.

 settings = array of Navigation Objects:
 Navigation Object:
 {
     title: "Dashboard | Tresta"     // Title of the page
     description: "Tresta is great." // Description of page
     displayName: 'Dashboard'        // Name to show in navigation
     url: '/'                        // URL
     isSecure: true                  // true | false - require user to be authenticated in order to view
     allowMobilePhoneUser:           // true | false - is the user allowed to view the route if they are using a mobile phone
     moduleId: 'dashboardViewModel'  // Full path to viewModel to load for given URL
     icon: 'dashboard'               // iconNames lookup key to use.  null for none
     menuType: 'MainMenu'            // 'MainMenu' | 'Sidebar' - where does this navigation item live
     parentItem:                     // routeId of the parent to this route
     template: '_shellEnumerations.login'             // Type of template (shell) this route should use for its base
 }

 type = enum: (name: url)
 {
     dashboard: "/",
     settingsAutoAttendant: "system/components/autoAttendant"
 }

 i18n when you add a navigation item here, make sure to add a key with the routeId to i18nResources sidebarNavigation.

 NOTE: If you add a top level navigation item here, add a "Disallow" rule to "robots.txt"
 **/
define('settings/navigationConfiguration',[
    'constants/shellNameEnumerations',
    'common/promises/promiseFactory',
    'constants/featureToggleConstants',
    'businessServices/authentication/sessionUserInfo',
    'presentation/analytics/viewModels/analyticsSidebarViewModel',
    'presentation/contacts/viewModels/contactsSidebarViewModel',
    'presentation/inbox/viewModels/inboxCallRecordingsSidebarViewModel',
    'presentation/inbox/viewModels/inboxVoicemailSidebarViewModel',
    'presentation/messages/viewModels/messagesSidebarViewModel',
    'presentation/navigation/viewModels/sidebarNavigationViewModel',
    'presentation/calls/viewModels/callsSidebarViewModel',
], function (
    _shellNameEnumerations,
    PromiseFactoryConstructor,
    _featureToggleConstants,
    _sessionUserInfo
) {
    const _i18n = require('i18next');

    const ERROR_URL = "/error";

    const SECTIONS = {
        billing: { url: 'billing' },
        settings: { url: 'system' },
        userSettings: { url: 'user' }
    };

    const ICONS = {
        helpCenter: 'userSettingsHelpCenter',
        main: {
            analytics: 'mainNavAnalytics',
            analyticsActive: 'mainNavAnalyticsActive',
            calls: 'mainNavCalls',
            callsActive: 'mainNavCallsActive',
            contacts: 'mainNavContacts',
            contactsActive: 'mainNavContactsActive',
            inbox: 'mainNavInbox',
            inboxActive: 'mainNavInboxActive',
            messages: 'mainNavMessages',
            messagesActive: 'mainNavMessagesActive',
            settings: 'mainNavSettings',
            settingsActive: 'mainNavSettingsActive'
        },
        sidebar: {
            accountProfile: 'sidebarAccountProfile',
            autoAttendants: 'sidebarAutoAttendants',
            contactPermissions: 'contactPermissions',
            blocking: 'blocking',
            billCycleToDate: 'sidebarBillCycleToDate',
            billingHistory: 'sidebarBillingHistory',
            devices: 'sidebarDevices',
            extensions: 'sidebarExtensions',
            forwardingNumbers: 'sidebarForwardingNumbers',
            humanDetection: 'blockedContact',
            messagingCompliance: 'sidebarMessagingCompliance',
            groups: 'sidebarGroups',
            makeAPayment: 'sidebarMakeAPayment',
            paymentMethods: 'sidebarPaymentMethods',
            phoneNumbers: 'sidebarHostedNumbers',
            prompts: 'sidebarPrompts',
            schedules: 'sidebarSchedules',
            subscriptions: 'sidebarSubscriptions',
            userProfile: 'sidebarMyProfile',
            users: 'sidebarUsers',
            voicemail: 'sidebarVoicemail'
        },
    };

    const _sectionErrorRoutes = () => {
        let baseSectionError = {
            routeId: "",
            title: _i18n.t('navigationConfiguration:notFound.title'),
            description: _i18n.t('navigationConfiguration:notFound.description'),
            displayName: _i18n.t('navigationConfiguration:notFound.displayName'),
            url: "",
            isSecure: true,
            permission: null,
            moduleId: "presentation/notFound/viewModels/notFoundViewModel",
            section: "",
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: null,
            iconActive: null,
            parentItem: null,
            cssRootClass: null
        };

        return Object.keys(SECTIONS).map((sectionKey) => {
            const sectionFields = {
                routeId: `${sectionKey}Error`,
                url: SECTIONS[sectionKey].url + ERROR_URL,
                section: sectionKey
            };

            return JSON.parse(JSON.stringify(Object.assign(baseSectionError, sectionFields)));
        });
    };

    const ROUTES = [
        {
            routeId: "payBalance",
            title: _i18n.t('navigationConfiguration:payBalance.title'),
            description: _i18n.t('navigationConfiguration:payBalance.description'),
            displayName: _i18n.t('navigationConfiguration:payBalance.displayName'),
            url: "sign-in/past-due-balance",
            isSecure: true,
            allowMobilePhoneUser: false,
            allowInviteInfoCapture: true,
            permission: "Billing.PayBalance",
            moduleId: "presentation/login/viewModels/payBalanceActionModalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "closedBalanceOwed",
            title: _i18n.t('navigationConfiguration:closedBalanceOwed.title'),
            description: _i18n.t('navigationConfiguration:closedBalanceOwed.description'),
            displayName: _i18n.t('navigationConfiguration:closedBalanceOwed.displayName'),
            url: "sign-in/past-due-balance/closed",
            isSecure: true,
            allowMobilePhoneUser: true,
            allowInviteInfoCapture: true,
            permission: "Billing.PayBalance",
            moduleId: "presentation/login/viewModels/closedBalanceOwedActionModalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "closedAndCannotReactivate",
            title: _i18n.t('navigationConfiguration:closedAndCannotReactivate.title'),
            description: _i18n.t('navigationConfiguration:closedAndCannotReactivate.description'),
            displayName: _i18n.t('navigationConfiguration:closedAndCannotReactivate.displayName'),
            url: "sign-in/reactivation/insufficient-permissions",
            isSecure: true,
            allowMobilePhoneUser: false,
            allowInviteInfoCapture: true,
            permission: null,
            moduleId: "presentation/login/viewModels/closedAndCannotReactivateActionModalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "closedDoNotReactivate",
            title: _i18n.t('navigationConfiguration:closedDoNotReactivate.title'),
            description: _i18n.t('navigationConfiguration:closedDoNotReactivate.description'),
            displayName: _i18n.t('navigationConfiguration:closedDoNotReactivate.displayName'),
            url: "sign-in/reactivation-hold",
            isSecure: true,
            allowMobilePhoneUser: false,
            allowInviteInfoCapture: true,
            permission: null,
            moduleId: "presentation/login/viewModels/closedDoNotReactivateActionModalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "miscellaneousProblems",
            title: _i18n.t('navigationConfiguration:miscProblems.title'),
            description: _i18n.t('navigationConfiguration:miscProblems.description'),
            displayName: _i18n.t('navigationConfiguration:miscProblems.displayName'),
            url: "sign-in/security-hold",
            isSecure: true,
            allowMobilePhoneUser: false,
            allowInviteInfoCapture: true,
            permission: "ViewMiscDialog",
            moduleId: "presentation/login/viewModels/miscellaneousProblemsActionModalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "login",
            title: _i18n.t('navigationConfiguration:login.title'),
            description: _i18n.t('navigationConfiguration:login.description'),
            displayName: _i18n.t('navigationConfiguration:login.displayName'),
            url: "sign-in(/:messageType)",
            isSecure: false,
            allowMobilePhoneUser: true,
            allowInviteInfoCapture: true,
            permission: null,
            moduleId: "presentation/login/viewModels/loginViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.login,
            cssRootClass: null
        },
        {
            routeId: "forgotPassword",
            title: _i18n.t('navigationConfiguration:forgotPassword.title'),
            description: _i18n.t('navigationConfiguration:forgotPassword.description'),
            displayName: _i18n.t('navigationConfiguration:forgotPassword.displayName'),
            url: "forgot-password(/:messageType)",
            isSecure: false,
            allowMobilePhoneUser: true,
            allowInviteInfoCapture: true,
            permission: null,
            moduleId: "presentation/forgotPassword/viewModels/forgotPasswordViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.login,
            cssRootClass: null
        },
        {
            routeId: "resetPassword",
            title: _i18n.t('navigationConfiguration:resetPassword.title'),
            description: _i18n.t('navigationConfiguration:resetPassword.description'),
            displayName: _i18n.t('navigationConfiguration:resetPassword.displayName'),
            url: "reset-password/:emailAddress/:expirationDateTime/*signature",
            isSecure: false,
            allowMobilePhoneUser: true,
            allowInviteInfoCapture: true,
            permission: null,
            moduleId: "presentation/forgotPassword/viewModels/resetPasswordViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.login,
            cssRootClass: null
        },
        {
            routeId: "acceptUserInvite",
            title: _i18n.t('navigationConfiguration:acceptUserInvite.title'),
            description: _i18n.t('navigationConfiguration:acceptUserInvite.description'),
            displayName: _i18n.t('navigationConfiguration:acceptUserInvite.displayName'),
            url: "invite-user/:accountId/:userId/:emailAddress/:expirationDateTime/*signature",
            isSecure: false,
            allowMobilePhoneUser: false,
            allowInviteInfoCapture: true,
            permission: null,
            moduleId: "presentation/acceptUserInvite/viewModels/acceptUserInviteViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.gradient,
            cssRootClass: null
        },
        {
            routeId: "resendInvite",
            title: _i18n.t('navigationConfiguration:resendInvite.title'),
            description: _i18n.t('navigationConfiguration:resendInvite.description'),
            displayName: _i18n.t('navigationConfiguration:resendInvite.displayName'),
            url: "resend-invite/:emailAddress/:expirationDateTime/*signature",
            isSecure: false,
            allowMobilePhoneUser: true,
            moduleId: "presentation/forgotPassword/viewModels/resetPasswordViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.login,
            cssRootClass: null
        },
        {
            routeId: "index",
            title: _i18n.t('navigationConfiguration:index.title'),
            description: _i18n.t('navigationConfiguration:index.description'),
            displayName: _i18n.t('navigationConfiguration:index.displayName'),
            url: "",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/shell/viewModels/indexViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: null,
            cssRootClass: null
        },
        {
            routeId: "signupSuccess",
            title: _i18n.t('navigationConfiguration:signupSuccess.title'),
            description: _i18n.t('navigationConfiguration:signupSuccess.description'),
            displayName: _i18n.t('navigationConfiguration:signupSuccess.displayName'),
            url: "signup/:flow/success",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupSuccessViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupEmail",
            title: _i18n.t('navigationConfiguration:signupEmail.title'),
            description: _i18n.t('navigationConfiguration:signupEmail.description'),
            displayName: _i18n.t('navigationConfiguration:signupEmail.displayName'),
            url: "signup/:flow/email",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupEmailViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPhoneNumberType",
            title: _i18n.t('navigationConfiguration:signupPhoneNumberType.title'),
            description: _i18n.t('navigationConfiguration:signupPhoneNumberType.description'),
            displayName: _i18n.t('navigationConfiguration:signupPhoneNumberType.displayName'),
            url: "signup/:flow/phone-number/type",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPhoneNumberTypeViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPhoneNumberLocal",
            title: _i18n.t('navigationConfiguration:signupPhoneNumberLocal.title'),
            description: _i18n.t('navigationConfiguration:signupPhoneNumberLocal.description'),
            displayName: _i18n.t('navigationConfiguration:signupPhoneNumberLocal.displayName'),
            url: "signup/:flow/phone-number/local",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPhoneNumberLocalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPhoneNumberTollfree",
            title: _i18n.t('navigationConfiguration:signupPhoneNumberTollfree.title'),
            description: _i18n.t('navigationConfiguration:signupPhoneNumberTollfree.description'),
            displayName: _i18n.t('navigationConfiguration:signupPhoneNumberTollfree.displayName'),
            url: "signup/:flow/phone-number/tollfree",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPhoneNumberTollfreeViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPhoneNumberPort",
            title: _i18n.t('navigationConfiguration:signupPhoneNumberPort.title'),
            description: _i18n.t('navigationConfiguration:signupPhoneNumberPort.description'),
            displayName: _i18n.t('navigationConfiguration:signupPhoneNumberPort.displayName'),
            url: "signup/:flow/phone-number/port/number",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPhoneNumberPortViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPhoneNumberPortBilling",
            title: _i18n.t('navigationConfiguration:signupPhoneNumberPortBilling.title'),
            description: _i18n.t('navigationConfiguration:signupPhoneNumberPortBilling.description'),
            displayName: _i18n.t('navigationConfiguration:signupPhoneNumberPortBilling.displayName'),
            url: "signup/:flow/phone-number/port/account-info",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPhoneNumberPortBillingViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPhoneNumberPortAuthorization",
            title: _i18n.t('navigationConfiguration:signupPhoneNumberPortAuthorization.title'),
            description: _i18n.t('navigationConfiguration:signupPhoneNumberPortAuthorization.description'),
            displayName: _i18n.t('navigationConfiguration:signupPhoneNumberPortAuthorization.displayName'),
            url: "signup/:flow/phone-number/port/authorization",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPhoneNumberPortAuthorizationViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupProfile",
            title: _i18n.t('navigationConfiguration:signupProfile.title'),
            description: _i18n.t('navigationConfiguration:signupProfile.description'),
            displayName: _i18n.t('navigationConfiguration:signupProfile.displayName'),
            url: "signup/:flow/user-profile",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupProfileViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupPaymentMethod",
            title: _i18n.t('navigationConfiguration:signupPaymentMethod.title'),
            description: _i18n.t('navigationConfiguration:signupPaymentMethod.description'),
            displayName: _i18n.t('navigationConfiguration:signupPaymentMethod.displayName'),
            url: "signup/:flow/payment-method",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupPaymentMethodViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.appEntry,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "signupError",
            title: _i18n.t('navigationConfiguration:signupError.title'),
            description: _i18n.t('navigationConfiguration:signupError.description'),
            displayName: _i18n.t('navigationConfiguration:signupError.displayName'),
            url: "signup/error",
            isSecure: false,
            allowMobilePhoneUser: true,
            permission: null,
            moduleId: "presentation/signup/viewModels/signupErrorViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.gradient,
            menuType: "appEntry",
            cssRootClass: null
        },
        {
            routeId: "reactivation",
            title: _i18n.t('navigationConfiguration:reactivation.title'),
            description: _i18n.t('navigationConfiguration:reactivation.description'),
            displayName: _i18n.t('navigationConfiguration:reactivation.displayName'),
            url: "reactivation",
            isSecure: true,
            allowMobilePhoneUser: false,
            moduleId: "presentation/login/viewModels/reactivationActionModalViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "analytics",
            title: _i18n.t('navigationConfiguration:analytics.title'),
            description: _i18n.t('navigationConfiguration:analytics.description'),
            displayName: _i18n.t('navigationConfiguration:analytics.displayName'),
            featureId: "analytics",
            url: "analytics",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/analytics/viewModels/analyticsViewModel",
            sidebarViewModel: "presentation/analytics/viewModels/analyticsSidebarViewModel",
            icon: ICONS.main.analytics,
            iconActive: ICONS.main.analyticsActive,
            menuType: "MainMenu",
            parentItem: null,
            section: "analytics",
            cssRootClass: null
        },
        {
            routeId: "calls",
            title: _i18n.t('navigationConfiguration:calls.title'),
            description: _i18n.t('navigationConfiguration:calls.description'),
            displayName: _i18n.t('navigationConfiguration:calls.displayName'),
            featureId: "calls",
            url: "calls",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/calls/viewModels/callsViewModel",
            sidebarViewModel: "presentation/calls/viewModels/callsSidebarViewModel",
            icon: ICONS.main.calls,
            iconActive: ICONS.main.callsActive,
            menuType: "MainMenu",
            parentItem: null,
            section: "calls",
            cssRootClass: null
        },
        {
            routeId: "contacts",
            title: _i18n.t('navigationConfiguration:contacts.title'),
            description: _i18n.t('navigationConfiguration:contacts.description'),
            displayName: _i18n.t('navigationConfiguration:contacts.displayName'),
            featureId: "contacts",
            url: "contacts",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/contacts/viewModels/contactsViewModel",
            sidebarViewModel: "presentation/contacts/viewModels/contactsSidebarViewModel",
            icon: ICONS.main.contacts,
            iconActive: ICONS.main.contactsActive,
            menuType: "MainMenu",
            parentItem: null,
            section: "contacts",
            cssRootClass: null
        },
        { // THIS ORDER IS INTENTIONAL SO THAT `contacts/add` URL DOES NOT MATCH ON `contacts/:accountContactId`
            routeId: "addContact",
            title: _i18n.t('navigationConfiguration:contacts.title'),
            description: _i18n.t('navigationConfiguration:contacts.description'),
            displayName: _i18n.t('navigationConfiguration:contacts.displayName'),
            featureId: "contacts",
            baseUrl: "contacts/add",
            url: "contacts/add(/:phoneNumber)",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/contacts/viewModels/contactsViewModel",
            sidebarViewModel: "presentation/contacts/viewModels/contactsSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "contacts",
            section: "contacts",
            cssRootClass: null
        },
        {
            routeId: "editContact",
            title: _i18n.t('navigationConfiguration:contacts.title'),
            description: _i18n.t('navigationConfiguration:contacts.description'),
            displayName: _i18n.t('navigationConfiguration:contacts.displayName'),
            featureId: "contacts",
            baseUrl: "contacts/edit",
            url: "contacts/edit/:accountContactId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/contacts/viewModels/contactsViewModel",
            sidebarViewModel: "presentation/contacts/viewModels/contactsSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "contacts",
            section: "contacts",
            cssRootClass: null
        },
        {
            routeId: "contact",
            title: _i18n.t('navigationConfiguration:contacts.title'),
            description: _i18n.t('navigationConfiguration:contacts.description'),
            displayName: _i18n.t('navigationConfiguration:contacts.displayName'),
            baseUrl: "contacts",
            featureId: "contacts",
            url: "contacts/:accountContactId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/contacts/viewModels/contactsViewModel",
            sidebarViewModel: "presentation/contacts/viewModels/contactsSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "contacts",
            section: "contacts",
            cssRootClass: null
        },
        {
            routeId: "inbox",
            title: _i18n.t('navigationConfiguration:inbox.title'),
            description: _i18n.t('navigationConfiguration:inbox.description'),
            displayName: _i18n.t('navigationConfiguration:inbox.displayName'),
            url: "inbox",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/inbox/viewModels/inboxViewModel",
            icon: ICONS.main.inbox,
            iconActive: ICONS.main.inboxActive,
            menuType: "MainMenu",
            sidebarViewModel: null,
            parentItem: null,
            section: "inbox",
            cssRootClass: "inbox",
            hasBadgeCount: true,
        },
        {
            routeId: "inboxVoicemail",
            title: _i18n.t('navigationConfiguration:inboxVoicemail.title'),
            description: _i18n.t('navigationConfiguration:inboxVoicemail.description'),
            displayName: _i18n.t('navigationConfiguration:inboxVoicemail.displayName'),
            featureId: "voicemail",
            url: "inbox/voicemail",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/inbox/viewModels/inboxVoicemailViewModel",
            sidebarViewModel: "presentation/inbox/viewModels/inboxVoicemailSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "inbox",
            section: "inbox",
            cssRootClass: "voicemail"
        },
        {
            routeId: "inboxVoicemailFiltered",
            title: _i18n.t('navigationConfiguration:inboxVoicemailFiltered.title'),
            description: _i18n.t('navigationConfiguration:inboxVoicemailFiltered.description'),
            displayName: _i18n.t('navigationConfiguration:inboxVoicemailFiltered.displayName'),
            featureId: "voicemail",
            url: "inbox/voicemail/:voicemailBoxId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/inbox/viewModels/inboxVoicemailViewModel",
            sidebarViewModel: "presentation/inbox/viewModels/inboxVoicemailSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "inbox",
            section: "inbox",
            cssRootClass: "voicemail"
        },
        {
            routeId: "inboxRecordings",
            title: _i18n.t('navigationConfiguration:inboxRecordings.title'),
            description: _i18n.t('navigationConfiguration:inboxRecordings.description'),
            displayName: _i18n.t('navigationConfiguration:inboxRecordings.displayName'),
            url: "inbox/recordings",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/inbox/viewModels/inboxCallRecordingsViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            sidebarViewModel: "presentation/inbox/viewModels/inboxCallRecordingsSidebarViewModel",
            parentItem: "inbox",
            section: "inbox",
            cssRootClass: "callRecording"
        },
        {
            routeId: "inboxRecordingsFiltered",
            title: _i18n.t('navigationConfiguration:inboxRecordingsFiltered.title'),
            description: _i18n.t('navigationConfiguration:inboxRecordingsFiltered.description'),
            displayName: _i18n.t('navigationConfiguration:inboxRecordingsFiltered.displayName'),
            url: "inbox/recordings/:callRecordingBoxId",
            isSecure: true,
            allowMobilePhoneUser: false,
            moduleId: "presentation/inbox/viewModels/inboxCallRecordingsViewModel",
            sidebarViewModel: "presentation/inbox/viewModels/inboxCallRecordingsSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "inbox",
            section: "inbox",
            cssRootClass: "callRecording"
        },
        {
            routeId: "messages",
            title: _i18n.t('navigationConfiguration:messages.title'),
            description: _i18n.t('navigationConfiguration:messages.description'),
            displayName: _i18n.t('navigationConfiguration:messages.displayName'),
            featureId: "messages",
            url: "messages",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/messages/viewModels/messagesViewModel",
            sidebarViewModel: "presentation/messages/viewModels/messagesSidebarViewModel",
            icon: ICONS.main.messages,
            iconActive: ICONS.main.messagesActive,
            menuType: "MainMenu",
            parentItem: null,
            section: "messages",
            cssRootClass: null,
            hasBadgeCount: true
        },
        {
            routeId: "newMessage",
            title: _i18n.t('navigationConfiguration:messages.title'),
            description: _i18n.t('navigationConfiguration:messages.description'),
            displayName: _i18n.t('navigationConfiguration:messages.displayName'),
            baseUrl: "messages/new",
            featureId: "messages",
            url: "messages/new(/:messageId)",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/messages/viewModels/messagesViewModel",
            sidebarViewModel: "presentation/messages/viewModels/messagesSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "messages",
            section: "messages",
            cssRootClass: null
        },
        {
            routeId: "conversation",
            title: _i18n.t('navigationConfiguration:messages.title'),
            description: _i18n.t('navigationConfiguration:messages.description'),
            displayName: _i18n.t('navigationConfiguration:messages.displayName'),
            baseUrl: "messages/conversation",
            featureId: "messages",
            url: "messages/conversation/:conversationId(/:messageId)",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/messages/viewModels/messagesViewModel",
            sidebarViewModel: "presentation/messages/viewModels/messagesSidebarViewModel",
            icon: null,
            iconActive: null,
            menuType: null,
            parentItem: "messages",
            section: "messages",
            cssRootClass: null
        },
        {
            routeId: "conversationNotFound",
            title: _i18n.t('navigationConfiguration:messages.title'),
            description: _i18n.t('navigationConfiguration:messages.description'),
            displayName: _i18n.t('navigationConfiguration:messages.displayName'),
            baseUrl: "messages/conversation/",
            urlSuffix: "/not-found",
            featureId: "messages",
            url: "messages/conversation/:conversationId/not-found",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/messages/viewModels/messagesViewModel",
            sidebarViewModel: "presentation/messages/viewModels/messagesSidebarViewModel",
            icon: null,
            iconActive: null,
            parentItem: "messages",
            section: "messages",
            cssRootClass: null
        },
        {
            routeId: "settings",
            title: _i18n.t('navigationConfiguration:settings.title'),
            description: _i18n.t('navigationConfiguration:settings.description'),
            displayName: _i18n.t('navigationConfiguration:settings.displayName'),
            url: "settings",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings",
            moduleId: "presentation/settings/index/viewModels/indexViewModel",
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.main.settings,
            iconActive: ICONS.main.settingsActive,
            menuType: "MainMenu",
            parentItem: null,
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "helpCenter",
            title: _i18n.t('navigationConfiguration:helpCenter.title'),
            description: _i18n.t('navigationConfiguration:helpCenter.description'),
            displayName: _i18n.t('navigationConfiguration:helpCenter.displayName'),
            url: "help-center",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: null,
            sidebarViewModel: null,
            icon: ICONS.helpCenter,
            iconActive: null,
            menuType: null,
            parentItem: null,
            section: "helpCenter",
            externalUrl: "https://www.tresta.com/contact",
            isNavigationExternal: true,
            cssRootClass: null
        },
        {
            routeId: "my-profile",
            title: _i18n.t('navigationConfiguration:my-profile.title'),
            description: _i18n.t('navigationConfiguration:my-profile.description'),
            displayName: _i18n.t('navigationConfiguration:my-profile.displayName'),
            url: "",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            menuType: "Sidebar",
            parentItem: "settings",
            section: "userSettings",
            cssRootClass: null
        },
        {
            routeId: 'userProfile',
            title: _i18n.t('navigationConfiguration:userProfile.title'),
            description: _i18n.t('navigationConfiguration:userProfile.description'),
            displayName: _i18n.t('navigationConfiguration:userProfile.displayName'),
            url: 'user/profile',
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: 'presentation/settings/userProfile/viewModels/userProfileViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.userProfile,
            iconActive: null,
            parentItem: 'my-profile',
            section: 'userSettings',
            cssRootClass: null
        },
        {
            routeId: 'userDevices',
            title: _i18n.t('navigationConfiguration:userDevices.title'),
            description: _i18n.t('navigationConfiguration:userDevices.description'),
            displayName: _i18n.t('navigationConfiguration:userDevices.displayName'),
            url: 'user/devices',
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: 'presentation/settings/userDevices/viewModels/userDevicesViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.devices,
            iconActive: null,
            parentItem: 'my-profile',
            section: 'userSettings',
            cssRootClass: null
        },
        {
            routeId: "userGroupMembership",
            title: _i18n.t('navigationConfiguration:userGroupMembership.title'),
            description: _i18n.t('navigationConfiguration:userGroupMembership.description'),
            displayName: _i18n.t('navigationConfiguration:userGroupMembership.displayName'),
            url: "user/groups",
            isSecure: true,
            permission: null,
            moduleId: 'presentation/settings/userGroupMembership/viewModels/userGroupMembershipViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.groups,
            iconActive: null,
            parentItem: 'my-profile',
            section: 'userSettings',
            cssRootClass: null
        },
        {
            routeId: 'userSubscriptions',
            title: _i18n.t('navigationConfiguration:userSubscriptions.title'),
            description: _i18n.t('navigationConfiguration:userSubscriptions.description'),
            displayName: _i18n.t('navigationConfiguration:userSubscriptions.displayName'),
            url: 'user/subscriptions',
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: 'presentation/settings/userSubscriptions/viewModels/userSubscriptionsViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.subscriptions,
            iconActive: null,
            parentItem: 'my-profile',
            section: 'userSettings',
            cssRootClass: null
        },
        {
            routeId: 'userSubscriptionsFiltered',
            title: _i18n.t('navigationConfiguration:userSubscriptionsFiltered.title'),
            description: _i18n.t('navigationConfiguration:userSubscriptionsFiltered.description'),
            displayName: _i18n.t('navigationConfiguration:userSubscriptionsFiltered.displayName'),
            url: 'user/subscriptions/:subscriptionType',
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: 'presentation/settings/userSubscriptions/viewModels/userSubscriptionsViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.subscriptions,
            iconActive: null,
            parentItem: 'userSubscriptions',
            section: 'userSettings',
            cssRootClass: null
        },
        {
            routeId: 'userVoicemail',
            title: _i18n.t('navigationConfiguration:userVoicemail.title'),
            description: _i18n.t('navigationConfiguration:userVoicemail.description'),
            displayName: _i18n.t('navigationConfiguration:userVoicemail.displayName'),
            url: 'user/voicemail',
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: 'presentation/settings/userVoicemail/viewModels/userVoicemailViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.voicemail,
            iconActive: null,
            parentItem: 'my-profile',
            section: 'userSettings',
            cssRootClass: null
        },
        {
            routeId: "system-settings",
            title: _i18n.t('navigationConfiguration:system-settings.title'),
            description: _i18n.t('navigationConfiguration:system-settings.description'),
            displayName: _i18n.t('navigationConfiguration:system-settings.displayName'),
            url: "",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings",
            moduleId: "",
            sidebarViewModel: null,
            icon: ICONS.sidebar.userProfile,
            iconActive: null,
            menuType: "Sidebar",
            parentItem: "settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "prompts",
            title: _i18n.t('navigationConfiguration:prompts.title'),
            description: _i18n.t('navigationConfiguration:prompts.description'),
            displayName: _i18n.t('navigationConfiguration:prompts.displayName'),
            url: "system/audio-files",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Prompts.View",
            moduleId: "presentation/settings/prompts/viewModels/promptsViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.prompts,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newPrompt",
            title: _i18n.t('navigationConfiguration:newPrompt.title'),
            description: _i18n.t('navigationConfiguration:newPrompt.description'),
            displayName: _i18n.t('navigationConfiguration:newPrompt.displayName'),
            url: "system/audio-files/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Prompts.Modify",
            moduleId: "presentation/settings/prompts/viewModels/addPromptsViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.prompts,
            iconActive: null,
            parentItem: "prompts",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editPrompt",
            title: _i18n.t('navigationConfiguration:editPrompt.title'),
            description: _i18n.t('navigationConfiguration:editPrompt.description'),
            displayName: _i18n.t('navigationConfiguration:editPrompt.displayName'),
            baseUrl: "system/audio-files/edit/",
            url: "system/audio-files/edit/:voicePromptId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Prompts.Modify",
            moduleId: "presentation/settings/prompts/viewModels/addPromptsViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.prompts,
            iconActive: null,
            parentItem: "prompts",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "autoAttendant",
            title: _i18n.t('navigationConfiguration:autoAttendant.title'),
            description: _i18n.t('navigationConfiguration:autoAttendant.description'),
            displayName: _i18n.t('navigationConfiguration:autoAttendant.displayName'),
            url: "system/auto-attendants",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.AutoAttendants.View",
            moduleId: "presentation/settings/autoAttendant/viewModels/autoAttendantViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.autoAttendants,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "addAutoAttendant",
            title: _i18n.t('navigationConfiguration:addAutoAttendant.title'),
            description: _i18n.t('navigationConfiguration:addAutoAttendant.description'),
            displayName: _i18n.t('navigationConfiguration:addAutoAttendant.displayName'),
            url: "system/auto-attendants/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.AutoAttendants.Modify",
            moduleId: "presentation/settings/autoAttendant/viewModels/addAutoAttendantViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.autoAttendants,
            iconActive: null,
            parentItem: "autoAttendant",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editAutoAttendant",
            title: _i18n.t('navigationConfiguration:editAutoAttendant.title'),
            description: _i18n.t('navigationConfiguration:editAutoAttendant.description'),
            displayName: _i18n.t('navigationConfiguration:editAutoAttendant.displayName'),
            baseUrl: "system/auto-attendants/edit/",
            url: "system/auto-attendants/edit/:autoAttendantId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.AutoAttendants.Modify",
            moduleId: "presentation/settings/autoAttendant/viewModels/addAutoAttendantViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.autoAttendants,
            iconActive: null,
            parentItem: "autoAttendant",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "contactPermissions",
            title: _i18n.t('navigationConfiguration:contactPermissions.title'),
            description: _i18n.t('navigationConfiguration:contactPermissions.description'),
            displayName: _i18n.t('navigationConfiguration:contactPermissions.displayName'),
            featureId: _featureToggleConstants.contactPermissions,
            url: "system/contacts-permissions",
            isSecure: true,
            permission: null,
            moduleId: 'presentation/settings/contacts/viewModels/contactPermissionsViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.contactPermissions,
            iconActive: null,
            parentItem: 'system-settings',
            section: 'settings',
            cssRootClass: null,
            allowMobilePhoneUser: false,
            isLargeIcon: true,
        },
        {
            routeId: "blocking",
            title: _i18n.t('navigationConfiguration:blocking.title'),
            description: _i18n.t('navigationConfiguration:blocking.description'),
            displayName: _i18n.t('navigationConfiguration:blocking.displayName'),
            featureId: "blocking",
            url: "system/blocking",
            isSecure: true,
            permission: null,
            moduleId: 'presentation/settings/blocking/viewModels/blockingViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.blocking,
            iconActive: null,
            parentItem: 'system-settings',
            section: 'settings',
            cssRootClass: null
        },
        {
            routeId: "extensions",
            title: _i18n.t('navigationConfiguration:extensions.title'),
            description: _i18n.t('navigationConfiguration:extensions.description'),
            displayName: _i18n.t('navigationConfiguration:extensions.displayName'),
            url: "system/extensions",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Extensions.Modify",
            moduleId: "presentation/settings/extensions/viewModels/extensionsViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.extensions,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "forwardingNumbers",
            title: _i18n.t('navigationConfiguration:forwardingNumbers.title'),
            description: _i18n.t('navigationConfiguration:forwardingNumbers.description'),
            displayName: _i18n.t('navigationConfiguration:forwardingNumbers.displayName'),
            url: "system/forwarding-numbers",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.ForwardingNumbers.View",
            moduleId: "presentation/settings/forwardingNumbers/viewModels/forwardingNumbersViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.forwardingNumbers,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newForwardingNumber",
            title: _i18n.t('navigationConfiguration:newForwardingNumber.title'),
            description: _i18n.t('navigationConfiguration:newForwardingNumber.description'),
            displayName: _i18n.t('navigationConfiguration:newForwardingNumber.displayName'),
            url: "system/forwarding-numbers/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.ForwardingNumbers.Modify",
            moduleId: "presentation/settings/forwardingNumbers/viewModels/addForwardingNumbersViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.forwardingNumbers,
            iconActive: null,
            parentItem: "forwardingNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editForwardingNumber",
            title: _i18n.t('navigationConfiguration:editForwardingNumber.title'),
            description: _i18n.t('navigationConfiguration:editForwardingNumber.description'),
            displayName: _i18n.t('navigationConfiguration:editForwardingNumber.displayName'),
            baseUrl: "system/forwarding-numbers/edit/",
            url: "system/forwarding-numbers/edit/:forwardingNumberId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.ForwardingNumbers.Modify",
            moduleId: "presentation/settings/forwardingNumbers/viewModels/addForwardingNumbersViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.forwardingNumbers,
            iconActive: null,
            parentItem: "forwardingNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "userGroups",
            title: _i18n.t('navigationConfiguration:userGroups.title'),
            description: _i18n.t('navigationConfiguration:userGroups.description'),
            displayName: _i18n.t('navigationConfiguration:userGroups.displayName'),
            url: "system/groups",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.UserGroups.View",
            moduleId: "presentation/settings/userGroup/viewModels/userGroupsViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.groups,
            iconActive: null,
            isLargeIcon: true,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "addUserGroup",
            title: _i18n.t('navigationConfiguration:addUserGroup.title'),
            description: _i18n.t('navigationConfiguration:addUserGroup.description'),
            displayName: _i18n.t('navigationConfiguration:addUserGroup.displayName'),
            url: "system/groups/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.UserGroups.Modify",
            moduleId: "presentation/settings/userGroup/viewModels/addUserGroupViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.groups,
            iconActive: null,
            parentItem: "userGroups",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editUserGroup",
            title: _i18n.t('navigationConfiguration:editUserGroup.title'),
            description: _i18n.t('navigationConfiguration:editUserGroup.description'),
            displayName: _i18n.t('navigationConfiguration:editUserGroup.displayName'),
            baseUrl: "system/groups/edit/",
            url: "system/groups/edit/:userGroupId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.UserGroups.Modify",
            moduleId: "presentation/settings/userGroup/viewModels/addUserGroupViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.groups,
            iconActive: null,
            menuType: null,
            isHeader: false,
            parentItem: "userGroups",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "humanDetection",
            title: _i18n.t('navigationConfiguration:humanDetection.title'),
            description: _i18n.t('navigationConfiguration:humanDetection.description'),
            displayName: _i18n.t('navigationConfiguration:humanDetection.displayName'),
            featureId: "humanDetection",
            url: "system/human-detection",
            isSecure: true,
            permission: null,
            moduleId: 'presentation/settings/humanDetection/viewModels/humanDetectionViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.humanDetection,
            iconActive: null,
            parentItem: 'system-settings',
            section: 'settings',
            cssRootClass: null
        },
        {
            routeId: "newHumanDetectionPhoneNumber",
            title: _i18n.t('navigationConfiguration:newHumanDetectionPhoneNumber.title'),
            description: _i18n.t('navigationConfiguration:newHumanDetectionPhoneNumber.description'),
            displayName: _i18n.t('navigationConfiguration:newHumanDetectionPhoneNumber.displayName'),
            featureId: "humanDetection",
            url: "system/human-detection/add-phone-number",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: null,
            moduleId: "presentation/settings/humanDetection/viewModels/addHumanDetectionPhoneNumberViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.humanDetection,
            iconActive: null,
            parentItem: "humanDetection",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "messagingCompliance",
            title: _i18n.t('navigationConfiguration:messagingCompliance.title'),
            description: _i18n.t('navigationConfiguration:messagingCompliance.description'),
            displayName: _i18n.t('navigationConfiguration:messagingCompliance.displayName'),
            url: "system/messaging-compliance",
            isSecure: true,
            permission: null,
            moduleId: 'presentation/settings/messagingCompliance/viewModels/messagingComplianceViewModel',
            sidebarViewModel: 'presentation/navigation/viewModels/sidebarNavigationViewModel',
            icon: ICONS.sidebar.messagingCompliance,
            iconActive: null,
            parentItem: 'system-settings',
            section: 'settings',
            cssRootClass: null
        },
        {
            routeId: "phoneNumbers",
            title: _i18n.t('navigationConfiguration:phoneNumbers.title'),
            description: _i18n.t('navigationConfiguration:phoneNumbers.description'),
            displayName: _i18n.t('navigationConfiguration:phoneNumbers.displayName'),
            url: "system/phone-numbers",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.PhoneNumbers.View",
            moduleId: "presentation/settings/phoneNumbers/viewModels/phoneNumbersViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newPhoneNumberType",
            title: _i18n.t('navigationConfiguration:newPhoneNumberType.title'),
            description: _i18n.t('navigationConfiguration:newPhoneNumberType.description'),
            displayName: _i18n.t('navigationConfiguration:newPhoneNumberType.displayName'),
            url: "system/phone-numbers/add/type",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.PhoneNumbers.Modify",
            moduleId: "presentation/settings/phoneNumbers/viewModels/addPhoneNumberTypeChooserViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "phoneNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newPhoneNumberLocal",
            title: _i18n.t('navigationConfiguration:newPhoneNumberLocal.title'),
            description: _i18n.t('navigationConfiguration:newPhoneNumberLocal.description'),
            displayName: _i18n.t('navigationConfiguration:newPhoneNumberLocal.displayName'),
            url: "system/phone-numbers/add/local",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.PhoneNumbers.Modify",
            moduleId: "presentation/settings/phoneNumbers/viewModels/addPhoneNumberLocalViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "phoneNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newPhoneNumberTollFree",
            title: _i18n.t('navigationConfiguration:newPhoneNumberTollFree.title'),
            description: _i18n.t('navigationConfiguration:newPhoneNumberTollFree.description'),
            displayName: _i18n.t('navigationConfiguration:newPhoneNumberTollFree.displayName'),
            url: "system/phone-numbers/add/toll-free",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.PhoneNumbers.Modify",
            moduleId: "presentation/settings/phoneNumbers/viewModels/addPhoneNumberTollFreeViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "phoneNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newPhoneNumberPort",
            title: _i18n.t('navigationConfiguration:newPhoneNumberPort.title'),
            description: _i18n.t('navigationConfiguration:newPhoneNumberPort.description'),
            displayName: _i18n.t('navigationConfiguration:newPhoneNumberPort.displayName'),
            url: "system/phone-numbers/add/port",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.PhoneNumbers.Modify",
            moduleId: "presentation/settings/phoneNumbers/viewModels/addPhoneNumberPortViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "phoneNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newPhoneNumberSummary",
            title: _i18n.t('navigationConfiguration:newPhoneNumberSummary.title'),
            description: _i18n.t('navigationConfiguration:newPhoneNumberSummary.description'),
            displayName: _i18n.t('navigationConfiguration:newPhoneNumberSummary.displayName'),
            url: "system/phone-numbers/add/summary",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.PhoneNumbers.Modify",
            moduleId: "presentation/settings/phoneNumbers/viewModels/addPhoneNumberSummaryViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "phoneNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editPhoneNumbers",
            title: _i18n.t('navigationConfiguration:editPhoneNumbers.title'),
            description: _i18n.t('navigationConfiguration:editPhoneNumbers.description'),
            displayName: _i18n.t('navigationConfiguration:editPhoneNumbers.displayName'),
            baseUrl: "system/phone-numbers/edit/",
            url: "system/phone-numbers/edit/:connectorId(/:defaultTabId)",
            isSecure: true,
            permission: "SystemSettings.PhoneNumbers.Modify",
            moduleId: "presentation/settings/phoneNumbers/viewModels/editPhoneNumberViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.phoneNumbers,
            iconActive: null,
            parentItem: "phoneNumbers",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "schedules",
            title: _i18n.t('navigationConfiguration:schedules.title'),
            description: _i18n.t('navigationConfiguration:schedules.description'),
            displayName: _i18n.t('navigationConfiguration:schedules.displayName'),
            url: "system/schedules",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Schedules.View",
            moduleId: "presentation/settings/schedules/viewModels/schedulesViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.schedules,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newSchedule",
            title: _i18n.t('navigationConfiguration:newSchedule.title'),
            description: _i18n.t('navigationConfiguration:newSchedule.description'),
            displayName: _i18n.t('navigationConfiguration:newSchedule.displayName'),
            url: "system/schedules/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Schedules.Modify",
            moduleId: "presentation/settings/schedules/viewModels/addScheduleViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.schedules,
            iconActive: null,
            parentItem: "schedules",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editSchedule",
            title: _i18n.t('navigationConfiguration:editSchedule.title'),
            description: _i18n.t('navigationConfiguration:editSchedule.description'),
            displayName: _i18n.t('navigationConfiguration:editSchedule.displayName'),
            baseUrl: "system/schedules/edit/",
            url: "system/schedules/edit/:scheduleId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Schedules.Modify",
            moduleId: "presentation/settings/schedules/viewModels/addScheduleViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.schedules,
            iconActive: null,
            parentItem: "schedules",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "users",
            title: _i18n.t('navigationConfiguration:users.title'),
            description: _i18n.t('navigationConfiguration:users.description'),
            displayName: _i18n.t('navigationConfiguration:users.displayName'),
            url: "system/users",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Users.View",
            moduleId: "presentation/settings/users/viewModels/usersViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.users,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editUser",
            title: _i18n.t('navigationConfiguration:editUser.title'),
            description: _i18n.t('navigationConfiguration:editUser.description'),
            displayName: _i18n.t('navigationConfiguration:editUser.displayName'),
            baseUrl: "system/users/edit/",
            url: "system/users/edit/:userId(/:defaultTabId)",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.Users.Modify",
            moduleId: "presentation/settings/users/viewModels/editUserViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.users,
            iconActive: null,
            parentItem: "users",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "voicemailBoxes",
            title: _i18n.t('navigationConfiguration:voicemailBoxes.title'),
            description: _i18n.t('navigationConfiguration:voicemailBoxes.description'),
            displayName: _i18n.t('navigationConfiguration:voicemailBoxes.displayName'),
            url: "system/voicemail-boxes",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.VoicemailBoxes.View",
            moduleId: "presentation/settings/voicemailBoxes/viewModels/voicemailBoxesViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.voicemail,
            iconActive: null,
            parentItem: "system-settings",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "newVoicemailBox",
            title: _i18n.t('navigationConfiguration:newVoicemailBox.title'),
            description: _i18n.t('navigationConfiguration:newVoicemailBox.description'),
            displayName: _i18n.t('navigationConfiguration:newVoicemailBox.displayName'),
            url: "system/voicemail-boxes/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.VoicemailBoxes.Modify",
            moduleId: "presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.voicemail,
            iconActive: null,
            parentItem: "voicemailBoxes",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "editVoicemailBox",
            title: _i18n.t('navigationConfiguration:editVoicemailBox.title'),
            description: _i18n.t('navigationConfiguration:editVoicemailBox.description'),
            displayName: _i18n.t('navigationConfiguration:editVoicemailBox.displayName'),
            baseUrl: "system/voicemail-boxes/edit/",
            url: "system/voicemail-boxes/edit/:voicemailBoxId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "SystemSettings.VoicemailBoxes.Modify",
            moduleId: "presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.voicemail,
            iconActive: null,
            parentItem: "voicemailBoxes",
            section: "settings",
            cssRootClass: null
        },
        {
            routeId: "billing",
            title: _i18n.t('navigationConfiguration:billing.title'),
            description: _i18n.t('navigationConfiguration:billing.description'),
            displayName: _i18n.t('navigationConfiguration:billing.displayName'),
            url: "",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing",
            moduleId: "",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: null,
            iconActive: null,
            menuType: "Sidebar",
            parentItem: "settings",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "billCycleToDate",
            title: _i18n.t('navigationConfiguration:billCycleToDate.title'),
            description: _i18n.t('navigationConfiguration:billCycleToDate.description'),
            displayName: _i18n.t('navigationConfiguration:billCycleToDate.displayName'),
            url: "billing/bill-cycle-to-date",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.BillCycleToDate",
            moduleId: "presentation/settings/billing/billCycleToDate/viewModels/billCycleToDateViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.billCycleToDate,
            iconActive: null,
            parentItem: "billing",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "billingHistory",
            title: _i18n.t('navigationConfiguration:billingHistory.title'),
            description: _i18n.t('navigationConfiguration:billingHistory.description'),
            displayName: _i18n.t('navigationConfiguration:billingHistory.displayName'),
            url: "billing/history",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.History",
            moduleId: "presentation/settings/billing/history/viewModels/billingHistoryViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.billingHistory,
            iconActive: null,
            parentItem: "billing",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "makeAPayment",
            title: _i18n.t('navigationConfiguration:makeAPayment.title'),
            description: _i18n.t('navigationConfiguration:makeAPayment.description'),
            displayName: _i18n.t('navigationConfiguration:makeAPayment.displayName'),
            url: "billing/make-a-payment",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing",
            moduleId: "presentation/settings/billing/makeAPayment/viewModels/makeAPaymentViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.makeAPayment,
            iconActive: null,
            parentItem: "billing",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "paymentMethods",
            title: _i18n.t('navigationConfiguration:paymentMethods.title'),
            description: _i18n.t('navigationConfiguration:paymentMethods.description'),
            displayName: _i18n.t('navigationConfiguration:paymentMethods.displayName'),
            url: "billing/payment-methods",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.PaymentMethods",
            moduleId: "presentation/settings/billing/paymentMethod/viewModels/paymentMethodViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.paymentMethods,
            iconActive: null,
            parentItem: "billing",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "addPaymentMethod",
            title: _i18n.t('navigationConfiguration:addPaymentMethods.title'),
            description: _i18n.t('navigationConfiguration:addPaymentMethods.description'),
            displayName: _i18n.t('navigationConfiguration:addPaymentMethods.displayName'),
            url: "billing/payment-methods/add",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.PaymentMethods",
            moduleId: "presentation/settings/billing/paymentMethod/viewModels/addPaymentMethodViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.paymentMethods,
            iconActive: null,
            parentItem: 'paymentMethods',
            section: 'billing',
            cssRootClass: null
        },
        {
            routeId: "editPaymentMethod",
            title: _i18n.t('navigationConfiguration:editPaymentMethods.title'),
            description: _i18n.t('navigationConfiguration:editPaymentMethods.description'),
            displayName: _i18n.t('navigationConfiguration:editPaymentMethods.displayName'),
            baseUrl: 'billing/payment-methods/edit/',
            url: "billing/payment-methods/edit/:paymentMethodId",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.PaymentMethods",
            moduleId: "presentation/settings/billing/paymentMethod/viewModels/addPaymentMethodViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.paymentMethods,
            iconActive: null,
            parentItem: 'paymentMethods',
            section: 'billing',
            cssRootClass: null
        },
        {
            routeId: "accountProfile",
            title: _i18n.t('navigationConfiguration:accountProfile.title'),
            description: _i18n.t('navigationConfiguration:accountProfile.description'),
            displayName: _i18n.t('navigationConfiguration:accountProfile.displayName'),
            url: "billing/profile",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.AccountProfile",
            moduleId: "presentation/settings/accountProfile/viewModels/accountProfileViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: ICONS.sidebar.accountProfile,
            iconActive: null,
            parentItem: "billing",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "closeAccount",
            title: _i18n.t('navigationConfiguration:closeAccount.title'),
            description: _i18n.t('navigationConfiguration:closeAccount.description'),
            displayName: _i18n.t('navigationConfiguration:closeAccount.displayName'),
            url: "billing/profile/close-account",
            isSecure: true,
            allowMobilePhoneUser: false,
            permission: "Billing.CanClose",
            moduleId: "presentation/settings/closeAccount/viewModels/closeAccountViewModel",
            sidebarViewModel: "presentation/navigation/viewModels/sidebarNavigationViewModel",
            icon: null,
            iconActive: null,
            parentItem: "accountProfile",
            section: "billing",
            cssRootClass: null
        },
        {
            routeId: "accountClosed",
            title: _i18n.t('navigationConfiguration:accountClosed.title'),
            description: _i18n.t('navigationConfiguration:accountClosed.description'),
            displayName: _i18n.t('navigationConfiguration:accountClosed.displayName'),
            url: "account-closed",
            isSecure: false,
            allowMobilePhoneUser: true,
            allowInviteInfoCapture: false,
            permission: null,
            moduleId: "presentation/settings/closeAccount/viewModels/accountClosedViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.login,
            cssRootClass: null
        },
        {
            routeId: "accountClosedFreeTrial",
            title: _i18n.t('navigationConfiguration:accountClosedFreeTrial.title'), // TODO: Add this to i18n
            description: _i18n.t('navigationConfiguration:accountClosedFreeTrial.description'), // TODO: Add this to i18n
            displayName: _i18n.t('navigationConfiguration:accountClosedFreeTrial.displayName'), // TODO: Add this to i18n
            url: "account-closed/free-trial",
            isSecure: false,
            allowMobilePhoneUser: true,
            allowInviteInfoCapture: false,
            permission: null,
            moduleId: "presentation/settings/closeAccount/viewModels/accountClosedViewModel",
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.login,
            cssRootClass: null
        },
        {
            routeId: "styleGuide",
            title: _i18n.t('navigationConfiguration:styleGuide.title'),
            description: _i18n.t('navigationConfiguration:styleGuide.description'),
            displayName: _i18n.t('navigationConfiguration:styleGuide.displayName'),
            url: "style-guide",
            isSecure: false,
            permission: null,
            moduleId: 'presentation/styleGuide/viewModels/styleGuideViewModel',
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.empty,
            cssRootClass: null
        },
        {
            routeId: "error",
            title: _i18n.t('navigationConfiguration:notFound.title'),
            description: _i18n.t('navigationConfiguration:notFound.description'),
            displayName: _i18n.t('navigationConfiguration:notFound.displayName'),
            url: "error",
            isSecure: true,
            permission: null,
            moduleId: 'presentation/notFound/viewModels/notFoundViewModel',
            sidebarViewModel: null,
            icon: null,
            iconActive: null,
            parentItem: null,
            template: _shellNameEnumerations.interiorError,
            cssRootClass: null
        },
        ..._sectionErrorRoutes()
    ];

    let routesById = {};
    let routeIds = [];

    ROUTES.forEach((route) => {
        const routeId = route.routeId;
        routesById[routeId] = route;
        routeIds.push(routeId);
        if (route.parentItem !== null) {
            let parentRoute = routesById[route.parentItem];
            if (parentRoute.childRoutes === undefined) {
                parentRoute.childRoutes = [];
            }
            parentRoute.childRoutes.push(route);
        }
    });

    return {
        accountClosed: "account-closed",
        accountClosedFreeTrial: "account-closed/free-trial",
        billingProfile: "billing/profile",
        conversationUrl: "messages/conversation",
        errorPageUrl: ERROR_URL,
        sections: SECTIONS,
        forgotPasswordPageUrl: "/forgot-password",
        homePageUrl: "analytics",
        index: "/",
        loginContinuationPrompt: "sign-in/continue",
        newMessageUrl: "messages/new",
        payBalance: "sign-in/past-due-balance",
        closedBalanceOwed: "sign-in/past-due-balance/closed",
        closedDoNotReactivate: "sign-in/reactivation-hold",
        closedAndCannotReactivate: "sign-in/reactivation/insufficient-permissions",
        miscellaneousProblems: "sign-in/security-hold",
        reactivation: "reactivation",
        loginLandingPageUrl: "analytics",
        loginPageUrl: "sign-in",
        routesById: routesById,
        routeIds: routeIds,
        signupLandingPage: "system/phone-numbers",
        sidebarViewModel: null,
        signupError: "signup/error"
    };
});

