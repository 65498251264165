define('presentation/common/callFlow/presentationObjects/callFlowPresentationObject',['common/storage/commonState'], function() {
    return function(){
        let self = this;

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');

        self.name = null;
        self.routeSearchData = ko.observable(null);
        self.routeType = ko.observable("");
        self.userAvatar = ko.observable(null);
        self.userGroupId = null;
        self.iconName = '';
        self.iconHeight = ko.observable("");
        self.iconWidth = ko.observable("");
        self.forwardingNumberPhoneNumber = ko.observable(null);
        self.showControls = ko.observable(true);
        self.showDeactivated = ko.observable(false);
        self.deactivatedMessage = ko.observable("");
        self.timeout = ko.observable(null);
        self.showSubMenuCallFlow = ko.observable(true);
        self.showSubMenuCallFlowDetails = ko.observable(true);
        self.showScheduleDetails = ko.observable(true);
        self.currentVoicePrompt = ko.observable(null);
        self.showLoopedCallFlow = ko.observable(false);
        self.conversionComplete = false;
        self.conversionInProgress = false;
        self.autoAttendant = ko.observable(null);
        self.subMenu = ko.observable(null);
        self.endWithHangup = ko.observable(false);
        self.endWithNoTimeout = ko.observable(false);
        self.isAutoAttendantView = ko.observable(false);
        self.requireKeyPress = false;
        self.i18nKey = ko.computed(() => self.isAutoAttendantView() ? 'autoAttendant' : 'callFlow');
        self.showEndWithMessage = ko.computed(() => self.endWithHangup() || self.endWithNoTimeout());
        self.showSeparatorMessage = ko.computed(() => self.endWithHangup() === false && self.endWithNoTimeout() === false);
        self.endWithMessage = ko.pureComputed(() => this.endWithHangup() ? _i18n.t(`${self.i18nKey()}:hangup`) : _i18n.t(`${self.i18nKey()}:playRinging`));

        self.isAutoAttendant = ko.computed(() => self.routeType() === _commonState.types.autoAttendant);
        self.isForwardingNumber = ko.computed(() => self.routeType() === _commonState.types.forwardingNumber);
        self.isSchedule = ko.computed(() => self.routeType() === _commonState.types.schedule);
        self.isSubMenu = ko.computed(() => self.routeType() === _commonState.types.subMenu);
        self.isUser = ko.computed(() => self.routeType() === _commonState.types.user);
        self.isUserGroup = ko.computed(() => self.routeType() === _commonState.types.userGroup);
        self.isVoicePrompt = ko.computed(() => self.routeType() === _commonState.types.prompt);

        self.callFlowSeparatorMessage = ko.computed(() => {
            if (self.timeout()) {
                return _i18n.t(`${self.i18nKey()}:timeoutSeparatorMessage`);
            } else if (self.isVoicePrompt()) {
                return _i18n.t('callFlow:audioSeparatorMessage');
            } else {
                return _i18n.t(`${self.i18nKey()}:defaultSeparatorMessage`);
            }
        });

        self.callFlowTimeoutMessage = ko.computed(() => {
            if (self.timeout()) {
                return self.timeout() + _i18n.t(`${self.i18nKey()}:timeoutMessage`);
            } else {
                return "";
            }
        });
    };
});

