define('presentation/settings/users/viewModels/usersViewModel',[
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/settings/users/facades/usersFacade',
    'presentation/settings/users/viewModels/addUserModalViewModel',
    'presentation/settings/users/viewModels/deactivateUserModalViewModel',
    'presentation/settings/users/viewModels/reactivateUserModalViewModel',
], function(){
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const AddUserModalConstructor = require('presentation/settings/users/viewModels/addUserModalViewModel');
        const DeactivateUserModalConstructor = require('presentation/settings/users/viewModels/deactivateUserModalViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const ReactivateUserModalConstructor = require('presentation/settings/users/viewModels/reactivateUserModalViewModel');
        const SorterConstructor = require('common/collections/collectionSorter');

        const _router = require('businessServices/router/router');

        let _disposables = [];
        let _facade = null;

        const _buildAddUserModal = () => {
            self.actionModal
                .clearModal()
                .setContentViewModel(AddUserModalConstructor)
                .setHeaderText({i18n: 'addUserModal:addUser'})
                .setSubmitButtonText({i18n: 'add'});
        };

        const _buildDeactivateModal = (user) => {
            const constructorParams = [user];
            const headerI18nKey = user.isInvitedStatus() ? 'cancelInvite' : 'deactivateHeader';
            const submitButtonText = user.isInvitedStatus() ? 'cancelInvite' : 'deactivate';
            self.actionModal
                .clearModal()
                .setContentViewModel(DeactivateUserModalConstructor, constructorParams)
                .setHeaderText({i18n: `deactivateUserModal:${headerI18nKey}`})
                .setSubmitButtonText({i18n: submitButtonText})
                .shouldScrollIntoView(false);
        };

        const _buildReactivateModal = (user) => {
            const constructorParams = [user];
            const headerI18nKey = user.isExpiredStatus() ? 'resendInvite' : 'reactivateUser';
            const submitButtonText = user.isExpiredStatus() ? 'send' : 'reactivate';
            self.actionModal
                .clearModal()
                .setContentViewModel(ReactivateUserModalConstructor, constructorParams)
                .setHeaderText({i18n: `reactivateUserModal:${headerI18nKey}`})
                .setSubmitButtonText({i18n: submitButtonText});
        };

        const _refreshUsers = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAllUsers(self.currentShowFilterOption())
                    .fail(deferredObject.reject)
                    .done((users) => {
                        self.availableUsers = users;

                        _updateDisplayedUsers();
                        deferredObject.resolve();
                    });
            });
        };

        const _updateDisplayedUsers = () => {
            let filteredUsers;
            let filterContent = self.userFilter();
            if (filterContent !== '') {
                let usersFilter = new FilterConstructor();
                usersFilter
                    .addProperty('fullName')
                    .addProperty('emailAddress')
                    .addProperty('groups')
                    .addProperty('status')
                    .addProperty('inviteStatus')
                    .addProperty('modifiedDateTime.filterValue')
                    .addProperty('createdDateTime.filterValue')
                    .addValue(filterContent);
                filteredUsers = usersFilter.filter(self.availableUsers);
            }
            else {
                filteredUsers = self.availableUsers;
            }

            self.displayUsers(filteredUsers);
            _sortDisplayedUsers();
        };

        const _sortDisplayedUsers = () => {
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(self.displayUsers);
            }
        };

        const _showDeactivateUserModal = (userPresentationObject) => {
            return _promiseFactory.deferIndefinitely(deferredObject => {
                _buildDeactivateModal(userPresentationObject);
                self.actionModal.showModal()
                    .done(_refreshUsers);
                deferredObject.resolve();
            });
        };

        self.actionModal = null;
        self.isCompositionComplete = ko.observable(false);
        self.displayUsers = ko.observableArray();
        self.availableUsers = null;

        self.sortType = ko.observable("fullName");
        _disposables.push(self.sortType.subscribe(_sortDisplayedUsers));

        self.currentShowFilterOption = ko.observable("all");
        self.userFilter = ko.observable('');
        _disposables.push(self.userFilter.subscribe(_updateDisplayedUsers));

        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "fullName", isAscending : true},
            {textI18n: "emailAddress", iconName: 'sortEmail', sortPath : "emailAddress", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.showResults = ko.pureComputed(() => self.displayUsers().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => self.availableUsers.length > 0 ? "noResults" : "noUsers");

        self.launchAddUserModal = () => {
            _buildAddUserModal();
            self.actionModal.showModal()
                .done(_refreshUsers);
        };

        self.deactivateUser = (userPresentationObject) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.isUserDeletable(userPresentationObject.userId)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        const status = result.status;
                        switch (status) {
                            case "cancel_invite":
                            case "last_active_group_member":
                            case "success":
                                _showDeactivateUserModal(userPresentationObject);
                                deferredObject.resolve();
                                break;
                            default:
                                deferredObject.resolve();
                        }
                    });
            });
        };

        self.reactivateUser = (userPresentationObject) => {
            return _promiseFactory.deferIndefinitely(deferredObject => {
                _buildReactivateModal(userPresentationObject);
                self.actionModal.showModal()
                    .done(_refreshUsers);
                deferredObject.resolve();
            });
        };

        self.navigateToEditUser = (user) => {
            _router.navigate(user.editUrl);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/users/facades/usersFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _refreshUsers();

            return _promiseFactory.wait();
        };

    };
});

