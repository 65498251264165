define('presentation/common/progressSpinner/progressSpinner',['constants/colorConstants',
        'jquery',
        'knockout',
        'spin-jquery'
    ],
    function(colorConstants, $, ko) {
        return {
            init: function(element, valueAccessor) {
                var options = valueAccessor();
                var $element = null;
                var visibleObservable = null;

                var removeSpinner = function() {
                    $element.removeClass("loading");
                };

                var setupSpinner = function() {
                    $element.addClass("spinner");
                    $element.wrapInner("<span class='label' />");
                    $element.append($("<span class='spin' />"));

                    if ($element.hasClass("loading")) {
                        event.preventDefault();
                    } else {
                        $element.addClass("loading");
                        $(".spin", $element).spin(spinnerOptions);

                        if (visibleObservable() === true)   {
                            removeSpinner();
                        }
                    }
                };

                var spinnerOptions = {
                    lines: 8,          // number of lines to draw
                    length: 3,          // length of each line
                    width: 3,           // line thickness
                    radius: 6,          // radius of the inner circle
                    color: colorConstants.colorSecondaryCool200,   // color or array of colors
                    corners: 1,         // corner roundness (0..1)
                };
                if (options.lines) {
                    spinnerOptions.lines = options.lines;
                }
                if (options.length) {
                    spinnerOptions.length = options.length;
                }
                if (options.width) {
                    spinnerOptions.width = options.width;
                }
                if (options.radius) {
                    spinnerOptions.radius = options.radius;
                }
                if (options.color) {
                    spinnerOptions.color = options.color;
                }
                if (options.corners) {
                    spinnerOptions.corners = options.corners;
                }
                if (options.top) {
                    spinnerOptions.top = options.top;
                }
                if (options.left) {
                    spinnerOptions.left = options.left;
                }
                if (options.visible) {
                    visibleObservable = options.visible;
                } else {
                    visibleObservable = ko.observable(true);
                }

                $element = $(element);

                setupSpinner();
            }
        };
    });
