define('entities/paymentMethodEntity',[],function() {
    return function(){
        var self = this;
        self.cardHolderName = null;
        self.cardType = null;
        self.country = null;
        self.createdDateTime = null;
        self.creditCardNumber = null;
        self.cvvSecurityCode = null;
        self.expirationDate = null;
        self.isDefault = null;
        self.modifiedDateTime = null;
        self.paymentMethodId = null;
        self.postalCode = null;
    };
});

