define('presentation/common/routeToModal/common/routingRuleBuilder',['entities/routingRuleEntity',
    'entities/routingRuleExitCondition',
    'constants/routingRuleConstants',
    'constants/routingRuleExitConditionsConstants',
    'businessServices/authentication/sessionAccountInfo',
    'constants/autoAttendantVoiceRouteEnumerations',
    'common/storage/commonState',
    'constants/pressOneToAcceptConstants'
], function() {
    return function() {
        const self = this;

        const RoutingRuleEntityConstructor = require('entities/routingRuleEntity');
        const RoutingRuleExitConditionConstructor = require('entities/routingRuleExitCondition');

        const _routingRuleConstants = require('constants/routingRuleConstants');
        const _routingRuleExitConditionsConstants = require('constants/routingRuleExitConditionsConstants');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _commonState = require('common/storage/commonState');
        const _pressOneToAcceptConstants = require("constants/pressOneToAcceptConstants");

        const _buildRoutingRule = (routeToViewModel) => {
            const routingRule = new RoutingRuleEntityConstructor();
            routingRule.accountId = _sessionAccountInfo.accountId();
            routingRule.routingRuleId = routeToViewModel.routingRuleId;

            const routeToSelection = routeToViewModel.routeToSelection();
            const routeToTypeSelectedForm = routeToViewModel.routeToTypeSelectedForm();

            switch (routeToSelection) {
                case _voiceRouteEnumerations.autoAttendant:
                    routingRule.routingType = _routingRuleConstants.autoAttendant;
                    routingRule.routeToAutoAttendantId = routeToTypeSelectedForm.selectedAutoAttendant().id;
                    break;
                case _voiceRouteEnumerations.forwardingNumber:
                    routingRule.routingType = _routingRuleConstants.forwardingNumber;
                    routingRule.routeToForwardingNumberId = routeToTypeSelectedForm.selectedForwardingNumber().id;
                    routingRule.requireKeyPress = routeToTypeSelectedForm.pressOneToAcceptOption() === _pressOneToAcceptConstants.on;
                    _maybeAddRoutingRuleExitConditionToRoutingRule(routeToTypeSelectedForm, routingRule);
                    break;
                case _voiceRouteEnumerations.prompt:
                    const selectedVoicePrompt = routeToTypeSelectedForm.selectedPrompt();
                    if (selectedVoicePrompt.isSystemPrompt) {
                        routingRule.routingType = _routingRuleConstants.systemAudioClip;
                        routingRule.routeToSystemAudioClipId = selectedVoicePrompt.id;
                    } else {
                        routingRule.routingType = _routingRuleConstants.voicePrompt;
                        routingRule.routeToVoicePromptId = selectedVoicePrompt.id;
                    }
                    _maybeAddRoutingRuleExitConditionToRoutingRule(routeToTypeSelectedForm, routingRule);
                    break;
                case _voiceRouteEnumerations.schedule:
                    routingRule.routingType = _routingRuleConstants.schedule;
                    routingRule.routeToScheduleId = routeToTypeSelectedForm.selectedSchedule().id;
                    break;
                case _voiceRouteEnumerations.subMenu:
                    routingRule.routingType = _routingRuleConstants.subMenu;
                    const selectedPrompt = routeToTypeSelectedForm.selectedPrompt();
                    if (selectedPrompt.isSystemPrompt) {
                        routingRule.routeToSystemAudioClipId = selectedPrompt.id;
                    } else {
                        routingRule.routeToVoicePromptId = selectedPrompt.id;
                    }
                    break;
                case _voiceRouteEnumerations.user:
                    routingRule.routingType = _routingRuleConstants.allUserDevices;
                    routingRule.routeToUserId = routeToTypeSelectedForm.selectedUser().id;
                    routingRule.requireKeyPress = routeToTypeSelectedForm.pressOneToAcceptOption() === _pressOneToAcceptConstants.on;
                    _maybeAddRoutingRuleExitConditionToRoutingRule(routeToTypeSelectedForm, routingRule);
                    break;
                case _voiceRouteEnumerations.userGroup:
                    routingRule.routingType = _routingRuleConstants.allUserGroupDevices;
                    routingRule.routeToUserGroupId = routeToTypeSelectedForm.selectedUserGroup().id;
                    routingRule.requireKeyPress = routeToTypeSelectedForm.pressOneToAcceptOption() === _pressOneToAcceptConstants.on;
                    _maybeAddRoutingRuleExitConditionToRoutingRule(routeToTypeSelectedForm, routingRule);
                    break;
                case _voiceRouteEnumerations.voicemailBox:
                    routingRule.routingType = _routingRuleConstants.voicemailBox;
                    routingRule.routeToVoicemailBoxId = routeToTypeSelectedForm.selectedVoicemailBox().id;
                    break;
            }
            return routingRule;
        };

        const _maybeAddRoutingRuleExitConditionToRoutingRule = (routeToTypeSelectedForm, routingRule) => {
            const timeout = routeToTypeSelectedForm.routeToViewModel().currentAnswerTimeout();
            if (timeout !== 'no_timeout') {
                const routingRuleExitConditions = new RoutingRuleExitConditionConstructor();
                routingRule.routingRuleExitConditions = [routingRuleExitConditions];

                switch (routingRule.routingType) {
                    case _routingRuleConstants.systemAudioClip:
                    case _routingRuleConstants.voicePrompt:
                        routingRuleExitConditions.exitType = _routingRuleExitConditionsConstants.donePlayingAudioFile;
                        routingRuleExitConditions.nextRoutingRule = _buildNextRoutingRule(routeToTypeSelectedForm.routeToViewModel(), routingRule);
                        break;
                    default:
                        routingRuleExitConditions.exitType = _routingRuleExitConditionsConstants.timeoutInSeconds;
                        routingRuleExitConditions.value = timeout;
                        routingRuleExitConditions.nextRoutingRule = _buildNextRoutingRule(routeToTypeSelectedForm.routeToViewModel(), routingRule);
                        break;
                }
            }
        };

        const _buildNextRoutingRule = (routeToViewModel, previousRoutingRule) => {
            let routingRule = new RoutingRuleEntityConstructor();

            const selectedAnswerTimeoutDropDownMenuOption = routeToViewModel.selectedAnswerTimeoutDropDownMenuOption().value;

            switch (selectedAnswerTimeoutDropDownMenuOption) {
                case 'owner_voicemail':
                    let voicemailBox = null;
                    const voicemailBoxes = _commonState.voicemailBoxes();
                    switch (previousRoutingRule.routingType) {
                        case _routingRuleConstants.allUserDevices:
                            voicemailBox = voicemailBoxes.find((voicemailBox) => {
                                return voicemailBox.ownerId() === previousRoutingRule.routeToUserId;
                            });
                            break;
                        case _routingRuleConstants.allUserGroupDevices:
                            voicemailBox = voicemailBoxes.find((voicemailBox) => {
                                return voicemailBox.ownerId() === previousRoutingRule.routeToUserGroupId;
                            });
                            break;
                    }
                    routingRule.accountId = _sessionAccountInfo.accountId();
                    routingRule.routingType = _routingRuleConstants.voicemailBox;
                    routingRule.routeToVoicemailBoxId = voicemailBox.id;
                    break;
                case 'other_voicemail':
                    routingRule.accountId = _sessionAccountInfo.accountId();
                    routingRule.routingType = _routingRuleConstants.voicemailBox;
                    routingRule.routeToVoicemailBoxId = routeToViewModel.selectedVoicemailBox().id;
                    break;
                case 'hangup':
                    routingRule = null;
                    break;
                case 'route_to':
                    routingRule = _buildRoutingRule(routeToViewModel);
                    break;
            }
            return routingRule;
        };

        const _flattenNestedParticipants = (routingRule, participants = []) => {
            if (routingRule === null) {
                return participants;
            }

            switch (routingRule.routingType) {
                case _routingRuleConstants.autoAttendant:
                    return [...participants, {type: _commonState.types.autoAttendant, id: routingRule.routeToAutoAttendantId}];
                case _routingRuleConstants.schedule:
                    return [...participants, {type: _commonState.types.schedule, id: routingRule.routeToScheduleId}];
                case _routingRuleConstants.allUserDevices:
                    participants = [...participants, {type: _commonState.types.user, id: routingRule.routeToUserId}];
                    if (routingRule.routingRuleExitConditions.length > 0) {
                        return _flattenNestedParticipants(routingRule.routingRuleExitConditions[0].nextRoutingRule, participants);
                    }

                    return participants;
                case _routingRuleConstants.allUserGroupDevices:
                    participants = [...participants, {type: _commonState.types.userGroup, id: _commonState.resolveGroupIdToGuid(routingRule.routeToUserGroupId)}];
                    if (routingRule.routingRuleExitConditions.length > 0) {
                        return _flattenNestedParticipants(routingRule.routingRuleExitConditions[0].nextRoutingRule, participants);
                    }

                    return participants;
                case _routingRuleConstants.forwardingNumber:
                case _routingRuleConstants.systemAudioClip:
                case _routingRuleConstants.voicePrompt:
                    if (routingRule.routingRuleExitConditions.length > 0) {
                        return _flattenNestedParticipants(routingRule.routingRuleExitConditions[0].nextRoutingRule, participants);
                    }

                    return participants;
                default:
                    return participants;
            }
        };

        self.buildRoutingRule = function(routeToViewModel) {
            return _buildRoutingRule(routeToViewModel);
        };

        self.buildRoutingRuleParticipants = (routeToViewModel) => {
            const routingRule = _buildRoutingRule(routeToViewModel);
            return _flattenNestedParticipants(routingRule);
        };
    };
});
