define('presentation/signupNumberPicker/validators/signupNumberPickerValidator',[
  'presentation/common/validation/validationRule',
  'presentation/common/validation/commonValidator',
], function() {
    return function() {
        let self = this;

        let _viewModel = null;
        let _commonValidator = null;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        self.registerViewModel = function(viewModel) {
            let validationRules = new ValidationRulesConstructor();

            validationRules.field("vanityString")
                .addValidationI18n(_validateVanityCharacters, 'addPhoneNumber:invalidVanityCharacter');
            
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
            _viewModel = viewModel;
        };

        self.validate = () => _commonValidator.validate();

        const _validateVanityCharacters = (vanityString) => /^[0-9A-Za-z]*$/.test(vanityString);
    };
});

