define('presentation/common/dateRangeSelection/viewModels/dateRangeSelectionViewModel',['constants/systemObjectIconNameConstants'], function() {
    return function() {
        const self = this;

        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _disposables = [];
        let _settings = {
            endOn: null,
            startOn: null,
            datePickerClass: null,
            icon: null,
        };

        let _endOn = "";
        let _startOn = "";
        let _datePickerClass = 'date-range-selection__date-picker';
        let _icon = _systemObjectIconNames.schedule;
        let _iconClass = 'date-range-selection__calendar-icon';
        let _placeholderClass = 'date-range-selection__calendar-placeholder';

        self.endOn = ko.observable(_endOn);
        self.startOn = ko.observable(_startOn);
        self.datePickerClass = ko.observable(_datePickerClass);
        self.icon = ko.observable(_icon);
        self.iconClass = ko.observable(_iconClass);
        self.placeholderClass = ko.observable(_placeholderClass);

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.endOn) {
                self.endOn = _settings.endOn;
            }
            if (_settings.startOn) {
                self.startOn = _settings.startOn;
            }
            if (_settings.datePickerClass) {
                _datePickerClass = ko.unwrap(_settings.datePickerClass);
                self.datePickerClass(_datePickerClass);
            }
            if (_settings.icon) {
                _icon = ko.unwrap(_settings.icon);
                self.icon(_icon);
            }
        };
    };
});
