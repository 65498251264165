define('businessServices/specifications/isFraudulentStatusSpecification',['constants/accountStatusEnumerations'], function() {
    return function() {
        var self = this;

        var _accountStatusEnumerations = require('constants/accountStatusEnumerations');
        var _fraudStatusEnumerations = _accountStatusEnumerations.fraudStatus;

        self.isSatisfiedBy = function(fraudulentStatus) {
            switch (fraudulentStatus) {
                case _fraudStatusEnumerations.ok:
                case _fraudStatusEnumerations.fraudReview:
                    return false;
                case _fraudStatusEnumerations.creditCardFraud:
                case _fraudStatusEnumerations.abusiveCustomer:
                case _fraudStatusEnumerations.other:
                    return true;
                default:
                    var error = new Error("Unknown fraud status");
                    error.fraudStatus = fraudulentStatus;
                    throw error;
            }
        };
    };
});
