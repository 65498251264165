define('businessServices/router/authorizationPlugins/impersonationTokenAuthorizationPlugin',[
    'common/promises/promiseFactory',
    'businessServices/authentication/authenticationManager',
    'common/storage/workSessionIdStorage',
    'businessServices/authentication/stores/impersonationAuthenticationStore'], function() {

    var PromiseFactoryConstructor = require('common/promises/promiseFactory');

    function ensureWorkSessionSetCorrectly(fragment) {
        var parts = fragment.split("/");
        if (parts.length >= 2) {
            if (parts[0] === "ws") {
                var workSessionIdStorage = require('common/storage/workSessionIdStorage');

                var targetWorkSessionId = parts[1];
                var currentWorkSessionId = workSessionIdStorage.getWorkSessionId();
                if (targetWorkSessionId !== currentWorkSessionId) {
                    workSessionIdStorage.setWorkSessionId(targetWorkSessionId);
                }
            }
        }
    }

    function configureAuthorization(fragment) {
        ensureWorkSessionSetCorrectly(fragment);

        var impersonationAuthenticationStore = require('businessServices/authentication/stores/impersonationAuthenticationStore');
        if (impersonationAuthenticationStore.hasValue()) {
            var promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.defer(function(promise) {
                var impersonationObject = impersonationAuthenticationStore.getValue();
                var impersonationToken = impersonationObject.token;
                var accountId = impersonationObject.accountId;
                var userId = impersonationObject.userId;
                var workSessionId = impersonationObject.workSessionId;

                var authenticationManager = require('businessServices/authentication/authenticationManager');
                authenticationManager.loginWithImpersonationToken(impersonationToken, accountId, userId, workSessionId)
                    .done(function(isSuccessful) {
                        if (isSuccessful) {
                            var valid = {
                                status : "success"
                            };
                            promise.resolve(valid);
                        } else {
                            var failed = {
                                status : "failed"
                            };
                            promise.resolve(failed);
                        }
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        }

        return { status : "ignored" };
    }

    return {
        configureAuthorization : configureAuthorization
    };
});
