define('presentation/common/actionModal/viewModels/actionModalViewModel',[
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'presentation/common/modal',
], function() {
    const DEFAULT_RESPONSE = "yes";
    const DEFAULT_CANCEL_RESPONSE = "cancel";
    const AGENT_BYPASS_RESPONSE = "agentBypass";

    function actionModalViewModel() {
        const self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');

        let _modalService = null;
        let _promise = null;

        const _defaultDisabledClickCallback = () => {
            return _promiseFactory.defer((deferredObject) => {
                if (self.contentViewModel().disabledClick !== undefined) {
                    self.contentViewModel().disabledClick()
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                }  else {
                    deferredObject.resolve();
                }
            });
        };

        const _defaultSubmitCallback = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                if (self.contentViewModel().submitAction !== undefined) {
                    self.contentViewModel().submitAction()
                        .fail(deferredObject.reject)
                        .done(deferredObject.resolve);
                }  else {
                    deferredObject.resolve(DEFAULT_RESPONSE);
                }
            });
        };

        self.modelStateObserver = new ModelStateObserverConstructor(self, true);

        self.defaultAffirmativeResponse = DEFAULT_RESPONSE;
        self.defaultCancelResponse = DEFAULT_CANCEL_RESPONSE;

        self.headerText = ko.observable({i18n: ''});
        self.submitButtonTextKey = ko.observable({i18n: 'save'});
        self.cancelButtonText = ko.observable({i18n: 'cancel'});
        self.agentBypassButtonText = ko.observable({i18n: 'agentBypass'});
        self.contentViewModel = ko.observable(null);
        self.isCompositionComplete = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.shouldDisplayAgentBypassButton = ko.observable(false);
        self.shouldDisplayCancelButton = ko.observable(true);
        self.shouldDisplaySubmitButton = ko.observable(true);
        self.shouldScrollIntoView = ko.observable(true);
        self.isSubmitInProgress = ko.observable(false);
        self.shouldDisplayDefaultFooterMessage = ko.observable(false);

        self.submitButtonText = ko.pureComputed(() => _i18n.t(`actionModal:${self.submitButtonTextKey().i18n}`));
        self.shouldContentOverflow = ko.pureComputed(() => self.isCompositionComplete() && self.isScrollActive());
        self.showContentViewModel = ko.pureComputed(() => self.contentViewModel() !== null);

        self.isScrollDisabled = ko.observable(false);

        self.onDisabledClickCallback = _defaultDisabledClickCallback;
        self.onSubmitCallback = _defaultSubmitCallback;

        self.setHeaderText = ({i18n}) => {
            self.headerText({i18n});
            return self;
        };

        self.setCancelButtonText = ({i18n}) => {
            self.cancelButtonText({i18n});
            return self;
        };

        self.setSubmitButtonText = ({i18n}) => {
            self.submitButtonTextKey({i18n});
            return self;
        };

        self.setOnDisabledClickCallback = (callback) => {
            self.onDisabledClickCallback = callback;
            return self;
        };

        self.setOnSubmitCallback = (callback) => {
            self.onSubmitCallback = callback;
            return self;
        };

        self.setShouldDisplayAgentBypassButton = (shouldDisplay = true) => {
            self.shouldDisplayAgentBypassButton(shouldDisplay);
            return self;
        };

        self.setShouldDisplayCancelButton = (shouldDisplay = true) => {
            self.shouldDisplayCancelButton(shouldDisplay);
            return self;
        };

        self.setShouldDisplaySubmitButton = (shouldDisplay = true) => {
            self.shouldDisplaySubmitButton(shouldDisplay);
            return self;
        };

        self.setShouldScrollIntoView = (shouldScroll = true) => {
            self.shouldScrollIntoView(shouldScroll);
            return self;
        };

        self.setIsScrollDisabled = (isDisabled = false) => {
            self.isScrollDisabled(isDisabled);
            return self;
        };

        self.setContentViewModel = (ContentViewModelConstructor, parameterArray = []) => {
            self.contentViewModel(new ContentViewModelConstructor(...parameterArray));
            return self;
        };

        self.clearModal = () => {
            self.setHeaderText({i18n: ''});
            self.submitButtonTextKey({i18n: 'save'});
            self.onDisabledClickCallback = _defaultDisabledClickCallback;
            self.onSubmitCallback = _defaultSubmitCallback;
            self.contentViewModel(null);
            return self;
        };

        self.onCancel = () => {
            if (self.isSubmitInProgress()) {
                return;
            }

            self.isCompositionComplete(false);
            _promise.resolve(DEFAULT_CANCEL_RESPONSE);
            _modalService.closeModal(self);
        };

        self.onAgentBypass = () => {
            if (self.isSubmitInProgress()) {
                return;
            }

            self.isCompositionComplete(false);
            _promise.resolve(AGENT_BYPASS_RESPONSE);
            _modalService.closeModal(self);
        };

        self.onDisabledClick = () => self.onDisabledClickCallback();

        self.onSubmit = () => {
            self.isSubmitInProgress(true);
            return _promiseFactory.deferWithMinimumWait(deferredObject => {
                self.onSubmitCallback()
                    .done((result) => {
                        if (result && result.hasOwnProperty('isValid') && !result.isValid) {
                            self.isSubmitInProgress(false);
                            deferredObject.resolve();
                            return;
                        }

                        self.isCompositionComplete(false);
                        _modalService.closeModal(self);
                        _promise.resolve(result);
                        self.isSubmitInProgress(false);
                        deferredObject.resolve();
                    }).fail((error) => {
                        _promise.reject(error);
                        self.isSubmitInProgress(false);
                        deferredObject.reject();
                    });
            });
        };

        self.showModal = () => {
            _promise = _promiseFactory.deferIndefinitely();
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();
            self.contentViewModel().activate()
                .done(() => {
                    _modalService.showModal(self);
                });

            return _promise;
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };
    }

    actionModalViewModel.defaultResponse = DEFAULT_RESPONSE;
    actionModalViewModel.defaultCancelResponse = DEFAULT_CANCEL_RESPONSE;
    actionModalViewModel.agentBypassResponse = AGENT_BYPASS_RESPONSE;

    return actionModalViewModel;
});

