define('businessServices/router/plugins/loginAsUserRoutePlugin',['settings/navigationConfiguration'], function(navigationConfiguration) {
    function loginAsUserRedirect(urlFragment) {
        var parts = urlFragment.split("/");
        if (parts.length >= 2) {
            if (parts[0] === "loginAsUser") {
                return { routeUrl : navigationConfiguration.loginLandingPageUrl };
            }
        }
        return true;
    }

    return {
        loadUrlRedirect : loginAsUserRedirect
    };
});

