define('presentation/signup/common/signupNavigationManager',[
    'businessServices/router/router',
    'constants/signupNumberTypeSelectionConstants',
    'settings/navigationConfiguration',
    'constants/signupSessionFlowConstants',
    'businessServices/signup/signupSessionSingleton',
], function() {
    const _navigationConfiguration = require('settings/navigationConfiguration');
    const _router = require('businessServices/router/router');
    const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
    const _signupNumberTypeSelectionConstants = require('constants/signupNumberTypeSelectionConstants');
    const _signupSessionState = require('businessServices/signup/signupSessionSingleton');

    let _signupSessionStore = null;
    let _disposables = [];
    let _numberTypeSelection = ko.observable(null);

    const ROUTE_ID__SIGNUP_EMAIL = _navigationConfiguration.routesById.signupEmail.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_TYPE = _navigationConfiguration.routesById.signupPhoneNumberType.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL =  _navigationConfiguration.routesById.signupPhoneNumberLocal.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE =  _navigationConfiguration.routesById.signupPhoneNumberTollfree.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT =  _navigationConfiguration.routesById.signupPhoneNumberPort.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING =  _navigationConfiguration.routesById.signupPhoneNumberPortBilling.routeId;
    const ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION =  _navigationConfiguration.routesById.signupPhoneNumberPortAuthorization.routeId;
    const ROUTE_ID__SIGNUP_PROFILE =  _navigationConfiguration.routesById.signupProfile.routeId;
    const ROUTE_ID__SIGNUP_PAYMENT_METHOD =  _navigationConfiguration.routesById.signupPaymentMethod.routeId;
    const ROUTE_ID__SIGNUP_SUCCESS = _navigationConfiguration.routesById.signupSuccess.routeId;
    const ROUTE_ID__SIGNUP_ERROR = _navigationConfiguration.routesById.signupError.routeId;

    const FLOW_A = _signupSessionFlowConstants.flowA;
    const FLOW_B = _signupSessionFlowConstants.flowB;
    const FLOW_C = _signupSessionFlowConstants.flowC;
    const FLOW_D = _signupSessionFlowConstants.flowD;

    const PREVIOUS_OPTIONAL_ROUTES = "previousOptionalRoutes";
    const NEXT_OPTIONAL_ROUTES = "nextOptionalRoutes";

    const SIGNUP_FLOWS = {
        [FLOW_A]: {
            routes: [
                () => _constructRoute(ROUTE_ID__SIGNUP_EMAIL, FLOW_A),
                () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_TYPE, FLOW_A),
                () => _constructRouteByNumberType(FLOW_A),
                () => _constructRoute(ROUTE_ID__SIGNUP_PROFILE, FLOW_A),
                () => _constructRoute(ROUTE_ID__SIGNUP_PAYMENT_METHOD, FLOW_A)
            ],
            routeIndexes: {
                [ROUTE_ID__SIGNUP_EMAIL]: 0,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_TYPE]: 1,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL]: 2,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE]: 2,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT]: 2,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: 2,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION]: 2,
                [ROUTE_ID__SIGNUP_PROFILE]: 3,
                [ROUTE_ID__SIGNUP_PAYMENT_METHOD]: 4
            },
            [PREVIOUS_OPTIONAL_ROUTES]: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING, FLOW_A),
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT, FLOW_A)
            },
            [NEXT_OPTIONAL_ROUTES]: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING, FLOW_A),
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION, FLOW_A)
            }
        },
        [FLOW_B]: {
            routes: [
                () => _constructRouteByNumberType(FLOW_B),
                () => _constructRoute(ROUTE_ID__SIGNUP_EMAIL, FLOW_B),
                () => _constructRoute(ROUTE_ID__SIGNUP_PROFILE, FLOW_B),
                () => _constructRoute(ROUTE_ID__SIGNUP_PAYMENT_METHOD, FLOW_B)
            ],
            routeIndexes: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL]: 0,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE]: 0,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT]: 0,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: 0,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION]: 0,
                [ROUTE_ID__SIGNUP_EMAIL]: 1,
                [ROUTE_ID__SIGNUP_PROFILE]: 2,
                [ROUTE_ID__SIGNUP_PAYMENT_METHOD]: 3
            },
            [PREVIOUS_OPTIONAL_ROUTES]: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING, FLOW_B),
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT, FLOW_B)
            },
            [NEXT_OPTIONAL_ROUTES]: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING, FLOW_B),
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION, FLOW_B)
            }
        },
        [FLOW_C]: {
            routes: [
                () => _constructRoute(ROUTE_ID__SIGNUP_EMAIL, FLOW_C),
                () => _constructRoute(ROUTE_ID__SIGNUP_PROFILE, FLOW_C),
                () => _constructRoute(ROUTE_ID__SIGNUP_PAYMENT_METHOD, FLOW_C)
            ],
            routeIndexes: {
                [ROUTE_ID__SIGNUP_EMAIL]: 0,
                [ROUTE_ID__SIGNUP_PROFILE]: 1,
                [ROUTE_ID__SIGNUP_PAYMENT_METHOD]: 2
            },
            [PREVIOUS_OPTIONAL_ROUTES]: {},
            [NEXT_OPTIONAL_ROUTES]: {}
        },
        [FLOW_D]: {
            routes: [
                () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_TYPE, FLOW_D),
                () => _constructRouteByNumberType(FLOW_D),
                () => _constructRoute(ROUTE_ID__SIGNUP_EMAIL, FLOW_D),
                () => _constructRoute(ROUTE_ID__SIGNUP_PROFILE, FLOW_D),
                () => _constructRoute(ROUTE_ID__SIGNUP_PAYMENT_METHOD, FLOW_D)
            ],
            routeIndexes: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_TYPE]: 0,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL]: 1,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE]: 1,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT]: 1,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: 1,
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION]: 1,
                [ROUTE_ID__SIGNUP_EMAIL]: 2,
                [ROUTE_ID__SIGNUP_PROFILE]: 3,
                [ROUTE_ID__SIGNUP_PAYMENT_METHOD]: 4
            },
            [PREVIOUS_OPTIONAL_ROUTES]: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING, FLOW_D),
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT, FLOW_D)
            },
            [NEXT_OPTIONAL_ROUTES]: {
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING, FLOW_D),
                [ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_BILLING]: () => _constructRoute(ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION, FLOW_D)
            }
        }
    };

    const _constructRoute = (routeId, flowId) => {
        let url = _navigationConfiguration.routesById[routeId].url;
        return url.replace(":flow", flowId);
    };

    const _constructRouteByNumberType = (flowId) => {
        let url = "";
        switch (_numberTypeSelection()) {
            case _signupNumberTypeSelectionConstants.local:
                url = _navigationConfiguration.routesById[ROUTE_ID__SIGNUP_PHONE_NUMBER_LOCAL].url;
                break;
            case _signupNumberTypeSelectionConstants.tollFree:
                url = _navigationConfiguration.routesById[ROUTE_ID__SIGNUP_PHONE_NUMBER_TOLL_FREE].url;
                break;
            case _signupNumberTypeSelectionConstants.port:
                url = _navigationConfiguration.routesById[ROUTE_ID__SIGNUP_PHONE_NUMBER_PORT].url;
                break;
        }
        return url.replace(":flow", flowId);
    };

    const _navigateToDefaultSignupPage = () => {
        _router.navigate(_constructRoute(ROUTE_ID__SIGNUP_EMAIL, FLOW_A));
    };

    const _previousPage = () =>  {
        const url = _getPreviousPageUrl();
        if (url !== null) {
            _router.navigate(url);
        }
    };

    const _nextPage = () => {
        const url = _getNextPageUrl();
        if (url !== null) {
            _router.navigate(url);
        }
    };

    const _getFlowId = () => {
        return _router.activeInstruction().params[0];
    };

    const _getRouteId = () => {
        return _router.activeInstruction().config.customSettings.routeId;
    };

    const _getFlow = (flowId) => {
        return SIGNUP_FLOWS[flowId];
    };

    const _isFlowStartPage = () => {
        const routeId = _getRouteId();
        const flowId = _getFlowId();
        const flow = _getFlow(flowId);

        return flow && flow.routeIndexes[routeId] === 0;
    };

    const _isPreviousPageValid = () => {
        return _getPreviousPageUrl() !== null;
    };

    const _isNextPageValid = () => {
        return _getNextPageUrl() !== null;
    };

    const _getPreviousPageUrl = () => {
        const routeId = _getRouteId();
        const flowId = _getFlowId();
        const optionalPageUrl = _getOptionalPageUrl(routeId, flowId, PREVIOUS_OPTIONAL_ROUTES);

        if (optionalPageUrl !== null) {
            return optionalPageUrl;
        }

        const flow = SIGNUP_FLOWS[flowId];
        const previousPageIndex = flow.routeIndexes[routeId] - 1;

        if (previousPageIndex >= 0) {
            return flow.routes[previousPageIndex]();
        }

        return null;
    };

    const _getNextPageUrl = () => {
        const routeId = _getRouteId();
        const flowId = _getFlowId();
        const optionalPageUrl = _getOptionalPageUrl(routeId, flowId, NEXT_OPTIONAL_ROUTES);

        if (optionalPageUrl !== null) {
            return optionalPageUrl;
        }

        const flow = SIGNUP_FLOWS[flowId];
        const nextPageIndex = flow.routeIndexes[routeId] + 1;
        if (nextPageIndex < flow.routes.length) {
            return flow.routes[nextPageIndex]();
        }

        return null;
    };

    const _getOptionalPageUrl = (routeId, flowId, optionalRoutes) => {
        const optionalUrlFunction = SIGNUP_FLOWS[flowId][optionalRoutes][routeId];
        if (typeof optionalUrlFunction !== 'undefined') {
            return optionalUrlFunction();
        }
        return null;
    };

    const _navigateToRoute = (routeId, flowId) => {
        const flow = SIGNUP_FLOWS[flowId];
        if (typeof flow === 'undefined') {
            return;
        }

        const routeIndex = flow.routeIndexes[routeId];
        if (typeof routeIndex === 'undefined') {
            return;
        }

        const url = _constructRoute(routeId, flowId);
        _router.navigate(url);
    };

    const _navigateToSuccessPage = () => {
        const flowId = _getFlowId();
        _router.navigate(_constructRoute(ROUTE_ID__SIGNUP_SUCCESS, flowId));
    };

    const _navigateToErrorPage = () => {
        _router.navigate(_constructRoute(ROUTE_ID__SIGNUP_ERROR));
    };

    const _detached = () => {
        _disposables.forEach(subscription => subscription.dispose());
        _disposables = [];
    };

    const _initialize = () => {
        const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
        _signupSessionStore = new SignupSessionStoreConstructor();
        _signupSessionStore.init();

        _signupSessionStore.get(false)
            .done((result) => {
                _numberTypeSelection(_signupSessionState.numberTypeSelection());
                _disposables.push(_signupSessionState.numberTypeSelection.subscribe(_numberTypeSelection));
            });
    };

    return function() {
        let self = this;

        self.init = _initialize;
        self.detached = _detached;

        self.getFlowId = _getFlowId;
        self.getRouteId = _getRouteId;
        self.getFlow = _getFlow;
        self.isFlowStartPage = _isFlowStartPage;
        self.isPreviousPageValid = _isPreviousPageValid;
        self.isNextPageValid = _isNextPageValid;

        self.navigateToDefaultSignupPage  = _navigateToDefaultSignupPage;
        self.navigateToRoute = _navigateToRoute;
        self.navigateToSuccessPage = _navigateToSuccessPage;
        self.navigateToErrorPage = _navigateToErrorPage;
        self.previousPage = _previousPage;
        self.nextPage = _nextPage;
    };
});

