/* jshint ignore:start */
define('presentation/common/enableLinksInText',['common/converters/unicodeFormatter', 'businessServices/browserSupport/browserType'], (
    /** @type typeof import('common/converters/unicodeFormatter') */
    UnicodeFormatter,
    /** @type typeof import('businessServices/browserSupport/browserType') */
    BrowserType
) => {
    // By Tom Winzig
    // TODO: ESPrima prevents regex, needed to escape
    // https://gist.github.com/winzig/8894715
    const LINK_REGEX = "\\b(https?:\\/{1,3})?((?:(?:[\\w.\\-]+\\.(?:[a-z]{2,13})|(?<=http:\\/\\/|https:\\/\\/)[\\w.\\-]+)\\/)(?:[^\\s()<>{}\\[\\]]+|\\([^\\s()]*?\\([^\\s()]+\\)[^\\s()]*?\\)|\\([^\\s]+?\\))+(?:\\([^\\s()]*?\\([^\\s()]+\\)[^\\s()]*?\\)|\\([^\\s]+?\\)|[^\\s`!()\\[\\]{};:'\\\".,<>?«»“”‘’])|(?:(?<!@)(?:\\w+(?:[.\\-]+\\w+)*\\.(?:[a-z]{2,13})|(?:(?:[0-9](?!\\d)|[1-9][0-9](?!\\d)|1[0-9]{2}(?!\\d)|2[0-4][0-9](?!\\d)|25[0-5](?!\\d))[.]?){4})\\b\\/?(?!@)(?:[^\\s()<>{}\\[\\]]+|\\([^\\s()]*?\\([^\\s()]+\\)[^\\s()]*?\\)|\\([^\\s]+?\\))*(?:\\([^\\s()]*?\\([^\\s()]+\\)[^\\s()]*?\\)|\\([^\\s]+?\\)|[^\\s`!()\\[\\]{};:'\\\".,<>?«»“”‘’])?))";

    const _unicodeFormatter = new UnicodeFormatter();

    const _formatUrl = (/**@type {string}*/ urlText) => {
        urlText = urlText.toLowerCase();

        const https = "https://", http = "http://";

        if (urlText.startsWith(https)) {
            return urlText;
        }

        if (urlText.startsWith(http)) {
            return urlText.replace(http, https);
        }

        return https + urlText;
    };

    const _findMatches = (/**@type {string}*/ string) => {
        const regex = new RegExp(LINK_REGEX, "gmi");
        const matches = string.matchAll(regex);
        const parsedMatches = [];
        for (const match of matches) {

            parsedMatches.push({
                value: match[0],
                matchIndex: match.index
            });
        }

        return parsedMatches;
    };

    const _splitTextAndLinks = (/**@type {string}*/ messageText) => {
        const matches = _findMatches(messageText);

        /** @type {ITextOrLinkSegment[]} */
        const textAndLinks = [];

        for (let match_i = 0, string_position = 0; string_position < messageText.length; match_i++) {
            const match = matches[match_i];

            if (!match) {
                const beforeLinkText = messageText.substring(string_position);

                textAndLinks.push({
                    text: beforeLinkText
                });

                break;
            }

            const { value, matchIndex } = match;
            const beforeLinkText = messageText.substring(string_position, matchIndex);

            if (beforeLinkText) {
                textAndLinks.push({
                    text: beforeLinkText
                });

                string_position += beforeLinkText.length;
            }

            textAndLinks.push({
                link: _formatUrl(value),
                text: value
            });

            string_position += value.length;
        }

        return textAndLinks;
    };

    const _unescape = (/**@type {ITextOrLinkSegment[] | string}*/ textOrParsedText) => {
        if (typeof textOrParsedText === "string") {
            const text = _unicodeFormatter.unescape(textOrParsedText);

            if (BrowserType.isSafari) {
                return [
                    {
                        text: text
                    }
                ];
            }

            return _splitTextAndLinks(text);
        }

        return textOrParsedText.map(x => {
            return {
                link: _unicodeFormatter.unescape(x.link),
                text: _unicodeFormatter.unescape(x.text)
            };
        });
    };

    const _createLinks = (/**@type {ITextOrLinkSegment[]}*/ textAndLinks) => {
        const fragment = document.createDocumentFragment();

        for (const textOrLink of textAndLinks) {
            if (textOrLink.link) {
                const anchorElement = document.createElement("a");
                anchorElement.innerText = textOrLink.text;
                anchorElement.setAttribute("href", textOrLink.link);
                anchorElement.setAttribute("target", "_blank");
                anchorElement.setAttribute("referrerpolicy", "no-referrer");
                fragment.appendChild(anchorElement);
            }
            else {
                const lines = textOrLink.text.split(_unicodeFormatter.newLineRegex);
                for (let i = 0; i < lines.length; i++) {
                    const line = lines[i];
                    if (line !== "") {
                        const textNode = document.createTextNode(line);
                        fragment.appendChild(textNode);
                    }

                    if (i + 1 !== lines.length) {
                        const brNode = document.createElement("br");
                        fragment.appendChild(brNode);
                    }
                }
            }
        }

        return fragment;
    };

    return {
        /** @type {KnockoutBindingHandler<HTMLElement, EnableLinksInTextInput>["init"]} */
        init: function (element, valueAccessor) {
            const textOrLinkSegments = ko.unwrap(valueAccessor());
            const textOrLinkSegmentsUnicode = _unescape(textOrLinkSegments);
            const documentFragment = _createLinks(textOrLinkSegmentsUnicode);
            element.replaceChildren(documentFragment);
        }
    };
});
