define('common/presentation/toolTipIcon',[],() => {
    /**
     * @param {ITextInfo[]} text
     * @returns {string}
     */
    const _formatTooltipText = (text) => {
        let formattedText = "";
        text.forEach((displayObject) => {
            const style = displayObject.style;
            if (style === "standard") {
                formattedText += ko.unwrap(displayObject.text);
            } else if (style === "bold") {
                formattedText += "<strong>" + ko.unwrap(displayObject.text) + "</strong>";
            }
            const carriageReturn = displayObject.carriageReturn;
            if (carriageReturn) {
                formattedText += "<br>";
            }
        });

        return formattedText;
    };

    /**
     * @param {string} className
     * @returns {string?}
     */
    const _createTemplate = (className) => {
        if (className)
        {
            return `<div class="tooltip ${className}"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`;
        }

        // eslint-disable-next-line consistent-return
        return;
    };

    /**
     * @param {HTMLElement} element
     * @param {() => (string | IToolTipIconData | Function)} valueAccessor
     * @returns {Unwrapped<IToolTipIconData>}
     */
    const _parseSettings = (element, valueAccessor) => {
        const settings = ko.utils.unwrapObservable(valueAccessor());

        /** @type {any} */
        let text;
        let enabled = true;
        let placement = "top";
        /** @type {string?} */
        let className = null;
        /** @type {IToolTipIconData["container"]} */
        let container = $(element).parent();

        switch (typeof settings) {
            case "string":
                text = settings;
                break;
            case "function": {
                const setting = settings();
                if (typeof setting === "object") {
                    placement = setting.position;
                    text = setting.text;
                    className = ko.utils.unwrapObservable(setting.className);
                    enabled = ko.utils.unwrapObservable(setting.enabled);
                } else {
                    text = settings;
                }
                break;
            }
            case "object":
                placement = settings.position;
                if (settings.container) {
                    container = settings.container;
                }

                text = ko.utils.unwrapObservable(settings.text);
                if (Array.isArray(text)) {
                    text = _formatTooltipText(text);
                }

                enabled = ko.utils.unwrapObservable(settings.enabled);
                className = ko.utils.unwrapObservable(settings.className);

                if (ko.isObservable(settings.text)) {
                    settings.text.subscribe(function(newText) {
                        if ($(element).tooltip) {
                            if (newText.length === 0) {
                                $(element).tooltip('disable');
                            } else {
                                $(element).attr('title', newText).tooltip('fixTitle');
                            }
                        }
                    });
                }
        }

        return {
            container: container,
            position: placement,
            text: text,
            enabled: enabled,
            className: className
        };
    };

    /**
     * @param {boolean} suppressOnTouchDevices
     * @returns {KnockoutBindingHandler}
     */
    return function(suppressOnTouchDevices) {
        return {
            /** @type {KnockoutBindingHandler<HTMLElement, IToolTipIconData | string>["init"]} */
            init(element, valueAccessor) {
                if (!(suppressOnTouchDevices && Modernizr.touch)) {
                    const { container, enabled, position, text, className } = _parseSettings(element, valueAccessor);
                    if (enabled === false) {
                        return;
                    }

                    const trigger = Modernizr.touch ? 'click' : 'hover focus';

                    element.classList.add("has-tooltip");

                    /** @type {HTMLElement[]} */
                    const toolTipDom = $(element).tooltip({
                        "animation": false,
                        "container": container,
                        "placement": position,
                        "trigger": trigger,
                        "html": true,
                        "title": text,
                        "template": _createTemplate(className)
                    });

                    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                        $(element).tooltip('destroy');
                    });
                }
            },

            /** @type {KnockoutBindingHandler<HTMLElement, IToolTipIconData | string>["update"]} */
            update(element, valueAccessor) {
                if (!(suppressOnTouchDevices && Modernizr.touch)) {
                    const { container, enabled, position, text, className } = _parseSettings(element, valueAccessor);
                    const { forceReveal } = ko.utils.unwrapObservable(valueAccessor());

                    if (enabled === false) {
                        $(element).tooltip('destroy');
                        element.classList.remove("has-tooltip");
                        return;
                    }

                    const trigger = Modernizr.touch ? 'click' : 'hover focus';

                    element.classList.add("has-tooltip");

                    /** @type {HTMLElement[]} */
                    const toolTipDom = $(element).tooltip({
                        "animation": false,
                        "container": container,
                        "placement": position,
                        "trigger": trigger,
                        "html": true,
                        "title": text,
                        "template": _createTemplate(className)
                    });

                    if (forceReveal !== undefined) {
                        const visibility = forceReveal ? 'show' : 'hide';
                        $(element).tooltip( visibility);
                    }
                }
            }
        };
    };
});
