define('businessServices/converters/currencyFormatter',[], function() {
    return function() {
        let self = this;

        const _removeFormattingFromUSD = (amount) => {
            if (typeof amount === "string") {
                return Number(amount.replace(/[^0-9\.-]+/g,""));
            }
            return amount;
        };

        const _formatUSD = (amount) => {
            const unformattedAmount = _removeFormattingFromUSD(amount);
            return parseFloat(unformattedAmount).toFixed(2);
        };

        const _formatUSDForDisplay = (amountRaw, accounting) => {
            let amount = amountRaw;
            if (typeof amountRaw === 'string') {
                amount = Number(amountRaw.replace(/[$,]/g, ''));
            }

            let currency = parseFloat(Math.abs(amount)).toFixed(2);
            currency = currency.replace(/\B(?=(\d{3})+\.)/g, ",");

            if (amount >= 0) {
                return "$" + currency;
            }
            if (accounting) {
                return "($" + currency + ")";
            }
            return "-$" + currency;
        };

        self.removeFormattingFromUSD = _removeFormattingFromUSD;
        self.formatUSDForDisplay = _formatUSDForDisplay;
        self.formatUSD = _formatUSD;
    };
});

