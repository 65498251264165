define('presentation/common/warningModal/viewModels/warningModalViewModel',[
    'businessServices/converters/durationTimeFormatter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'presentation/common/modal',
    'presentation/common/voicePrompt/voicePromptAudioFactory'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    return function() {
        const self = this;

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const _timeFormatter = new TimeFormatterConstructor();

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        let _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();

        const _commonState = require('common/storage/commonState');

        let _modalService = null;
        let _promise = null;

        const _buttonClick = (key) => {
            _promise.resolve(key);
            _modalService.closeModal(self);
        };

        const _buildSystemComponentItem = (systemComponentId) => {
            let systemComponent = {};
            systemComponent.isAudioFile = false;
            systemComponent.isAutoAttendant = false;
            systemComponent.isUser = false;
            systemComponent.isUserGroup = false;

            let commonStateObject = _commonState.get(systemComponentId);

            switch (commonStateObject.type) {
                case _commonState.types.autoAttendant:

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        isAutoAttendant: true,
                    });
                case _commonState.types.forwardingNumber:

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        metadata: _phoneNumberFormatter.toInternationalWithParens(commonStateObject.phoneNumber()),
                    });
                case _commonState.types.hostedNumber:
                    const isDefault = commonStateObject.hasDefaultName();
                    const formattedNumber = _phoneNumberFormatter.toInternationalWithParens(commonStateObject.phoneNumber());
                    const formattedLocation = _phoneNumberFormatter.cleanupLocation(commonStateObject.location().cityState);

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        metadata: isDefault ? formattedLocation : formattedNumber,
                    });
                case _commonState.types.schedule:

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                    });
                case _commonState.types.user:

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        avatar: commonStateObject.avatar,
                        hasAvatar: commonStateObject.avatar().showImage(),
                        isUser: true,
                    });
                case _commonState.types.userGroup:

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        groupId: systemComponentId,
                        hasAvatar: commonStateObject.avatars().length > 1,
                        isUserGroup: true,
                    });
                case _commonState.types.voicemailBox:

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                    });
                case _commonState.types.prompt:
                    let formattedDuration = _timeFormatter.formatTotalMilliseconds(commonStateObject.durationInMilliseconds());
                    let audioObject = ko.observable(_voicePromptAudioFactory.getVoicePrompt(systemComponentId));
                    audioObject().duration = ko.observable(formattedDuration);

                    return Object.assign({}, systemComponent, {
                        name: commonStateObject.name,
                        iconName: commonStateObject.iconName,
                        isAudioFile: true,
                        audioObject: audioObject,
                        durationInSeconds: formattedDuration,
                    });
                default:
                    return null;
            }
        };

        self.css = ko.observable('warning-modal');
        self.modal = ko.pureComputed(() => `${self.css()}__modal`);
        self.buttonsCss = ko.pureComputed(() => `${self.css()}__buttons`);
        self.textsCss = ko.pureComputed(() => `${self.css()}__texts`);
        self.iconCss = ko.pureComputed(() => `${self.css()}__icon ${self.iconAddCss}`);
        self.phoneNumbersCss = ko.pureComputed(() => `${self.css()}__numbers`);

        self.setCss = (css) => {
            self.css(css);
            return self;
        };

        self.icon = ko.observable('');
        self.iconAddCss = ko.observable('');
        self.showIcon = ko.pureComputed(() => self.icon() !== '');

        self.setIcon = ({icon = 'signupWarning', addCss = ''}) => {
            self.iconAddCss(addCss);
            self.icon(icon);
            return self;
        };

        self.heading = ko.observable({i18n: ''});
        self.setHeading = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__heading ${addCss}`);
            self.heading({i18n, css});
            return self;
        };

        self.html = ko.observable({htmlString: ''});
        self.setHtml = ({htmlString, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__html ${addCss}`);
            self.html({htmlString, css});
            return self;
        };

        self.systemComponent = ko.observable('');
        self.showSystemComponent = ko.pureComputed(()=> self.systemComponent() !== '');
        self.setSystemComponent = ({componentId, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__object ${addCss}`);
            const component = _buildSystemComponentItem(componentId);
            self.systemComponent({component, css});
            return self;
        };

        self.texts = ko.observableArray([]);
        self.addText = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__text ${addCss}`);
            self.texts.push({i18n, css});
            return self;
        };

        self.buttons = ko.observableArray([]);
        self.addButton = ({i18n = 'ok', key = 'ok', addCss = '', isActive = true}) => {
            const css = isActive ?
                ko.pureComputed(() => `${self.css()}__button ${self.css()}__button-active ${addCss}`) :
                ko.pureComputed(() => `${self.css()}__button ${self.css()}__button-text ${addCss}`);
            self.buttons.push({i18n, key, css, click: () => _buttonClick(key)});
            return self;
        };

        self.phoneNumbers = ko.observableArray([]);
        self.showPhoneNumbers = ko.pureComputed(()=> self.phoneNumbers.length > 0);
        self.addPhoneNumber = ({number, addCss = '', isError = true}) => {
            const statusIcon = isError ? 'warningAttention' : 'warningCheckmark';
            const css = isError ?
                ko.pureComputed(() => `${self.css()}__number ${self.css()}__number-error ${addCss}`) :
                ko.pureComputed(() => `${self.css()}__number ${addCss}`);
            self.phoneNumbers.push({number, css, statusIcon});
            return self;
        };

        self.clearModal = () => {
            self.css('warning-modal');
            self.buttons.removeAll();
            self.html({htmlString: ''});
            self.texts.removeAll();
            self.phoneNumbers.removeAll();
            self.systemComponent('');
            self.setIcon({icon: ''});
            self.setHeading({});
            return self;
        };

        self.showModal = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();
            _modalService.showModal(self);
            _promise = _promiseFactory.deferIndefinitely();
            return _promise;
        };
    };
});

