define('persistence/repositories/payBalanceRepository',[
    'businessServices/converters/currencyFormatter',
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp'
    ], function() {

    return function() {
        var self = this;

        var CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');

        var _currencyFormatter = new CurrencyFormatterConstructor();
        var _promiseFactory = new PromiseFactoryConstructor();
        var _webSocketApp = null;

        self.addPaymentMethodAndMakePayment = function(paymentMethodEntity, paymentAmount) {
            if (paymentMethodEntity.paymentMethodId !== null) {
                var err = new Error("paymentMethodId should be null");
                throw err;
            }

            return _promiseFactory.defer(function(deferredObject) {
                var socketParams = {
                    paymentMethod : paymentMethodEntity,
                    amount : _currencyFormatter.formatUSD(paymentAmount)
                };

                _webSocketApp.send("addAndChargePaymentMethod", socketParams, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.applyPayment = function(paymentMethodId, amount) {
            if (paymentMethodId === null) {
                var err = new Error("Missing paymentId");
                throw err;
            }

            return _promiseFactory.defer(function(deferredObject) {
                var socketParams = {
                    paymentMethodId: paymentMethodId,
                    amount: _currencyFormatter.formatUSD(amount)
                };

                _webSocketApp.send("chargePaymentMethod", socketParams, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = function () {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("pay_balance");
        };
    };
});

