/**
 * @module businessServices/searchFilters/callHistorySearchFilter
 */
define('businessServices/searchFilters/callHistorySearchFilter',[], function() {
    return function() {
        var self = this;

        /**
         * Main object all filter parameters are assigned to
         * @property filterParameters
         * @type {object}
         */
        self.filterParameters = {
            sort_options: {},
            filter_fields: {}
        };

        /**
         * Set parameters for sorting
         * @method setSortOptions
         * @param {string} sortField The field to sort
         * @param {boolean} isSortAscending The direction to sort
         * @returns void
         */
        self.setSortOptions = function(sortField, isSortAscending) {
            self.filterParameters.sort_options = {};

            if (isSortAscending) {
                self.filterParameters.sort_options[sortField] = 'ascending';
            } else {
                self.filterParameters.sort_options[sortField] = 'descending';
            }
        };

        /**
         * Set parameters for filtering text
         * @method setFilterText
         * @param {string} text The text to filter against
         * @returns void
         */
        self.setFilterText = function(text) {
            if (text) {
                self.filterParameters.filter_text = text;
            } else {
                delete self.filterParameters.filter_text;
            }
        };

        /**
         * Set parameters for filtering connected field
         * @method setFilterConnected
         * @param {date} startDate Filter start date for connected
         * @param {date} endDate Filter end date for connected
         * @returns void
         */
        self.setFilterConnected = function(startDate, endDate) {
            if (startDate !== "*" || endDate !== "*") {
                self.filterParameters.filter_fields.connected = {};
                self.filterParameters.filter_fields.connected.from = startDate;
                self.filterParameters.filter_fields.connected.to = endDate;
            } else {
                delete self.filterParameters.filter_fields.connected;
            }
        };

        /**
         * Set parameters for filtering direction
         * @method setFilterDirection
         * @param {string} direction Filter direction field
         * @returns void
         */
        self.setFilterDirection = function(direction) {
            if(direction.length) {
                self.filterParameters.filter_fields.direction = direction;
            } else {
                delete self.filterParameters.filter_fields.direction;
            }
        };

        /**
         * Set parameters for filtering call status
         * @method setFilterCallStatus
         * @param {string} callStatus Filter call status field
         * @returns void
         */
        self.setFilterCallStatus = function(callStatus) {
            if(callStatus.length) {
                self.filterParameters.filter_fields.callStatus = callStatus;
            } else {
                delete self.filterParameters.filter_fields.callStatus;
            }
        };

        /**
         * Set parameters for filtering has voicemail/recording
         * @method setFilterAudio
         * @param {array} audioOptions Array containg possibly 'hasVoiceMail', 'hasRecording'
         * @returns void
         */
        self.setFilterAudio = function(audioOptions) {
            if (audioOptions.length) {
                if (audioOptions.indexOf("hasVoiceMail") > -1) {
                    self.filterParameters.filter_fields.hasVoiceMail = [true];
                }

                if (audioOptions.indexOf("hasRecording") > -1) {
                    self.filterParameters.filter_fields.hasRecording = [true];
                }
            } else {
                delete self.filterParameters.filter_fields.hasVoiceMail;
                delete self.filterParameters.filter_fields.hasRecording;
            }
        };
    };
});

