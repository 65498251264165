define('presentation/settings/billing/history/viewModels/billingHistoryViewModel',['common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/billingHistoryConstants',
    'presentation/settings/billing/history/facades/billingHistoryFacade'
], function () {
    return function () {
        let self = this;

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _billingHistoryConstants = require('constants/billingHistoryConstants');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _i18n = require('i18next');
        const _urlFormatter = require('common/url/urlFormatter');
        let _facade = null;

        const _getBillingHistory = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getBillingHistory()
                    .done((billingHistory) => {
                        self.currentBalance(billingHistory.currentBalance);
                        self.pastDueBalance(billingHistory.pastDueBalance);
                        self.isPastDueBalance(billingHistory.isPastDueBalance);
                        self.transactionsResponse(billingHistory.transactions);
                        self.toggleSort({value: _billingHistoryConstants.header.date});
                        deferredObject.resolve();
                    })
                    .fail((error) => {
                        deferredObject.reject(error);
                    });
            });
        };

        const _getArrowStyles = (name, value) => {
            let classes = [];
            const { transactionType, status } = _billingHistoryConstants.header;
            const _isColumnSorting = () => {
                if (self.currentSortingField() === transactionType) {
                    return value === transactionType;
                } else if (self.currentSortingField() === status) {
                    return value === status;
                } else {
                    return self.currentSortingField() === _billingHistoryConstants.header[value];
                }
            };

            if (!self.currentSortingDirection() && _isColumnSorting()) {
                classes.push('billing-history__sort-arrow--pointed-down');
            }
            if (!_isColumnSorting()) {
                classes.push('billing-history__sort-arrow--hidden');
            }
            return classes.join(' ');
        };

        self.transactionsResponse = ko.observableArray([]);
        self.makeAPaymentUrl = _urlFormatter.buildWorkSessionUrl(_navigationConfiguration.routesById.makeAPayment.url);
        self.currentBalance = ko.observable();
        self.pastDueBalance = ko.observable();
        self.isPastDueBalance = ko.observable();
        self.currentSortingDirection = ko.observable(true);
        self.currentSortingField = ko.observable(_billingHistoryConstants.header.date);
        self.callHistoryGetNext = ko.observable();
        self.showResults = ko.pureComputed(() => self.transactions().length > 0);
        self.shouldDisplayArrow = (value) => value === self.currentSortingField();
        self.billingHistoryTableHeaders = ko.observableArray([
            {
                title: _i18n.t('billingHistory:billingHistoryTableHeaders:date'),
                value: _billingHistoryConstants.header.date,
                arrowStyles: () => _getArrowStyles('date', _billingHistoryConstants.header.date),
            },
            {
                title: _i18n.t('billingHistory:billingHistoryTableHeaders:transactionType'),
                value: _billingHistoryConstants.header.transactionType,
                arrowStyles: () => _getArrowStyles('transaction-type', _billingHistoryConstants.header.transactionType),
            },
            {
                title: _i18n.t('billingHistory:billingHistoryTableHeaders:paymentMethod'),
                value: _billingHistoryConstants.header.paymentMethod,
                arrowStyles: () => _getArrowStyles('payment-method', _billingHistoryConstants.header.paymentMethod),
            },
            {
                title: _i18n.t('billingHistory:billingHistoryTableHeaders:amount'),
                value: _billingHistoryConstants.header.amount,
                arrowStyles: () => _getArrowStyles('amount', _billingHistoryConstants.header.amount),
            },
            {
                title: _i18n.t('billingHistory:billingHistoryTableHeaders:status'),
                value: _billingHistoryConstants.header.status,
                arrowStyles: () => _getArrowStyles('status', _billingHistoryConstants.header.status),
            }
        ]);

        self.pastDueBalanceWarningMessage = ko.pureComputed(() => {
            let options = {
                pastDueBalanceAmount: self.isPastDueBalance() ? self.pastDueBalance() : 0
            };
            let message = _i18n.t('billingHistory:pastDueBalance_part1', options);
            message += '<a class="warning-box__message-link" href="' + self.makeAPaymentUrl + '">';
            message += _i18n.t('billingHistory:pastDueBalance_part2') + '</a>';
            message += _i18n.t('billingHistory:pastDueBalance_part3');
            return message;
        });


        self.transactions = ko.pureComputed(() => {
            _sorter.sort(self.transactionsResponse(), self.currentSortingField(), self.currentSortingDirection());

            return self.transactionsResponse();
        });

        self.toggleSort = ({value}) => {
            if (self.currentSortingField() === value) {
                self.currentSortingDirection(!self.currentSortingDirection());
            } else {
                self.currentSortingDirection(true);
            }
            self.currentSortingField(value);
        };

        self.getSortBehavior = (value) => {
            let classes = [];
            classes.push('billing-history-header__column');
            classes.push(value);
            return classes.join(' ');
        };

        self.getStatementPdfUrl = (billingHistoryDisplayItem) => {
            return _promiseFactory.defer((promise) => {
                _facade.getStatementPdfUrl(billingHistoryDisplayItem.statementId)
                    .done(promise.resolve)
                    .fail(promise.reject);
            });
        };

        self.activate = () => {
            let BillingHistoryFacadeConstructor = require('presentation/settings/billing/history/facades/billingHistoryFacade');
            _facade = new BillingHistoryFacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        let _initialize = () => {
            _getBillingHistory();

            return _promiseFactory.wait();
        };
    };
});

