define('presentation/inbox/dataSources/recordingSubscriptionDataSource',['common/promises/promiseFactory',
        'common/promises/waitHandle',
        'constants/accountHostedNumberConstants',
        'constants/callRecordingMessageConstants',
        'externalDependencies/clientWebSocket'
    ],
    function(PromiseFactoryConstructor, WaitHandleConstructor, accountHostedNumberConstants, callRecordingMessageConstants, clientWebSocket) {
        let subscriptionWebSocket = clientWebSocket.forApplication("callRecordingSubscriptionDataSource");

        let promiseFactory = new PromiseFactoryConstructor();
        let waitForDataWaitHandle = new WaitHandleConstructor();
        waitForDataWaitHandle.setRedLight();

        subscriptionWebSocket.subscribeToEvent("callRecordingConverted", (eventData) => {
            const {hostedNumberId, callRecordingId, status} = eventData;

            onNewCallRecording(hostedNumberId, callRecordingId, status);
        });

        subscriptionWebSocket.subscribeToEvent("callRecordingStatusChanged", (eventData) => {
            const {hostedNumberId, callRecordingId, newStatus} = eventData;

            onCallRecordingStatusChanged(hostedNumberId, callRecordingId, newStatus);
        });

        subscriptionWebSocket.subscribeToEvent("callRecordingDeleted", (eventData) => {
            const {hostedNumberId, callRecordingId} = eventData;

            onCallRecordingDeleted(hostedNumberId, callRecordingId);
        });

        subscriptionWebSocket.subscribeToEvent("callRecordingSubscriberSettingsChanged", (eventData) => {
            const {hostedNumberId, newCallRecordingIds, isActiveSubscriber, isShowInSidebarEnabled} = eventData;

            onSubscriberSettingsChanged(hostedNumberId, newCallRecordingIds, isActiveSubscriber, isShowInSidebarEnabled);
        });

        subscriptionWebSocket.subscribeToEvent("accountHostedNumberStatusChanged", (eventData) => {
            const {hostedNumberId, newStatus} = eventData;

            onHostedNumberStatusChanged(hostedNumberId, newStatus);
        });

        const onCallRecordingStatusChanged = (hostedNumberId, callRecordingId, newStatus) => {
            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.hostedNumberId === hostedNumberId);
            if (foundSubscription) {
                let newMessageIds = foundSubscription.newCallRecordingIds();

                switch (newStatus) {
                    case callRecordingMessageConstants.status.new.id:
                        if (newMessageIds.includes(callRecordingId) === false) {
                            foundSubscription.newCallRecordingIds.push(callRecordingId);
                        }
                        break;
                    case callRecordingMessageConstants.status.reviewed.id:
                        if (newMessageIds.includes(callRecordingId)) {
                            foundSubscription.newCallRecordingIds.remove(callRecordingId);
                        }
                        break;
                }
            }
        };

        const onCallRecordingDeleted = (hostedNumberId, callRecordingId) => {
            _removeCallRecording(hostedNumberId, callRecordingId);
        };

        const onNewCallRecording = (hostedNumberId, callRecordingId, status) => {
            if(status === callRecordingMessageConstants.status.new.id) {
                _addCallRecording(hostedNumberId, callRecordingId);
            }
        };

        const onSubscriberSettingsChanged = (hostedNumberId, newCallRecordingIds, isActiveSubscriber, isShowInSidebarEnabled) => {
            if(isActiveSubscriber === true && isShowInSidebarEnabled === true) {
                _addSubscription(hostedNumberId, newCallRecordingIds);
            } else {
                _removeSubscription(hostedNumberId);
            }
        };

        const onHostedNumberStatusChanged = (hostedNumberId, newStatus) => {
            if(newStatus === accountHostedNumberConstants.status.deactivated.id)
            {
                _removeSubscription(hostedNumberId);
            }
        };

        const _addCallRecording = (hostedNumberId, callRecordingId) => {
            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.hostedNumberId === hostedNumberId);
            if (foundSubscription) {
                let newMessageIds = foundSubscription.newCallRecordingIds();
                if (newMessageIds.includes(callRecordingId) === false) {
                    foundSubscription.newCallRecordingIds.push(callRecordingId);
                }
            }
        };

        const _removeCallRecording = (hostedNumberId, callRecordingId) => {
            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.hostedNumberId === hostedNumberId);
            if (foundSubscription) {
                let newMessageIds = foundSubscription.newCallRecordingIds();
                if (newMessageIds.includes(callRecordingId) === true) {
                    foundSubscription.newCallRecordingIds.remove(callRecordingId);
                }
            }
        };

        const _removeSubscription = (hostedNumberId) => {
            subscriptions.remove(function (subscription) {
                return subscription.hostedNumberId === hostedNumberId;
            });
        };

        const _addSubscription = (hostedNumberId, newMessageIds) => {
            const newCallRecordingIds = ko.observableArray(newMessageIds);
            const newCallRecordingMessageCount = ko.pureComputed(() => {
                return newCallRecordingIds().length;
            });

            const newSubscription = {
                hostedNumberId: hostedNumberId,
                newCallRecordingIds: newCallRecordingIds,
                newCallRecordingCount: newCallRecordingMessageCount
            };

            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.hostedNumberId === hostedNumberId);
            if(foundSubscription) {
                subscriptions.replace(foundSubscription, newSubscription);
            } else {
                subscriptions.push(newSubscription);
            }
        };

        const init = () => {
            return promiseFactory.defer((promise) => {
                subscriptionWebSocket.send("getInitialWebSubscriptions", {}, (data, errMsg) => {
                    if (errMsg) {
                        throw new Error(errMsg);
                    }

                    let subs = data.map((item) => {

                        const newCallRecordingIds = ko.observableArray(item.newCallRecordingIds);
                        const newCallRecordingCount = ko.pureComputed(() => {
                            return newCallRecordingIds().length;
                        });

                        return {
                            hostedNumberId: item.hostedNumberId,
                            newCallRecordingIds : newCallRecordingIds,
                            newCallRecordingCount : newCallRecordingCount,
                        };
                    });

                    subscriptions(subs);

                    waitForDataWaitHandle.setGreenLight();
                    promise.resolve();
                });
            });
        };
        
        const subscriptions = ko.observableArray([]);
        const totalNewMessages = ko.computed(() => {
            let total = 0;

            subscriptions().forEach((subscription) => {
                total = total + subscription.newCallRecordingCount();
            });
            return total;
        });

        const onLogin = () => {
            return init();
        };

        const onLogout = () => {
            waitForDataWaitHandle.setRedLight();
            subscriptions([]);
        };

        return {
            waitForDataWaitHandle,
            subscriptions,
            totalNewMessages,
            alerts : {
                receiveAlertLoginSuccessful : onLogin,
                receiveAlertLogout : onLogout,
            }
        };
    });
