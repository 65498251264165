define('presentation/settings/billing/paymentMethod/viewModels/addPaymentMethodViewModel',[
    'businessServices/country/countryCode',
    'businessServices/events/eventManager',
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'constants/creditCardExpirationYearConstants',
    'constants/creditCardResponseConstants',
    'constants/monthEnumerations',
    'constants/validationMessageEnumerations',
    'presentation/common/modal',
    'constants/countryEnumerations',
    'businessServices/state/modelStateObserver',
    'presentation/settings/billing/paymentMethod/facades/addPaymentMethodFacade',
    'presentation/settings/billing/paymentMethod/validators/addPaymentMethodValidator',
],
    function() {
    return function () {
        let self = this;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const DateConstructor = require('common/time/date');

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
    
        const CountryCodeConstructor = require('businessServices/country/countryCode');
        const _countryCodes = new CountryCodeConstructor();

        const CreditCardTypesConstructor = require('businessServices/creditCards/creditCardTypes');
        const _creditCardTypes = new CreditCardTypesConstructor();

        const AddPaymentMethodValidatorConstructor = require('presentation/settings/billing/paymentMethod/validators/addPaymentMethodValidator');
        const _validator = new AddPaymentMethodValidatorConstructor();

        const MonthConstructor = require('common/calendar/monthList');
        const _months = new MonthConstructor().monthList;

        const AddPaymentMethodModalFacade = require('presentation/settings/billing/paymentMethod/facades/addPaymentMethodFacade');
        const _facade = new AddPaymentMethodModalFacade();

        const _i18n = require('i18next');
        const _router = require('businessServices/router/router');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _countryEnumerations = require('constants/countryEnumerations');
        const _creditCardExpirationYear = require('constants/creditCardExpirationYearConstants');
        const _creditCardResponses = require('constants/creditCardResponseConstants');
        const _eventManager = require('businessServices/events/eventManager');

        let _disposables = [];

        const _currentCountry = (country = null) => {
            if (country) {
                const currentCountry = _countryCodes.findByAbbreviation(country);
                return self.countryList.find(item => item.text === currentCountry.name);
            } else {
                const currentCountry = _countryCodes.getDefaultCountry();
                return self.countryList.find(item => item.text === currentCountry.name);
            }
        };

        const _currentMonth = (month = null) => {
            if (month) {
                return _months.find(item => item.value === month);
            } else {
                const currentMonth = new DateConstructor();
                currentMonth.setToday();
                return _months.find(item => item.value === currentMonth.monthPadded());
            }
        };

        const _currentYear = (year = null) => {
            if (year) {
                const isPaymentMethodExpirationYearInYearList = !!self.yearList.find(item => item.text === year);
                if (isPaymentMethodExpirationYearInYearList) {
                    return self.yearList.find(item => item.text === year);
                }
                const currentYear = new Date().getFullYear();
                return currentYear;
            } else {
                const currentYear = new Date().getFullYear();
                return self.yearList.find(item => item.text === currentYear);
            }
        };

        const _onCountryChange = () => {
            self.postalCode("");
            self.postalCode.isValid(null);
        };

        const _populateCountryDropdown = () => {
            self.countryList = _countryCodes.countryCodes.map((country) => {
                return Object.assign({}, country, {
                    id: country.countryAbbreviation,
                    text: country.name,
                });
            });
        };

        const _populateExpirationDateDropdowns = () => {
            self.monthList =_months.map((month) => {
                month.id = month.value;
                month.text = month.value;
                return month;
            });

            const currentYear = new Date().getFullYear();
            self.yearList = Array.from(Array(_creditCardExpirationYear.yearsFromCurrent),
                (_, i) => ({id: currentYear + i, text: currentYear + i})
            );
        };

        const _addOrEditPaymentMethod = () => {
            if (self.isEditScreen()) {
                return _facade.editPaymentMethod(
                    self.paymentMethodId,
                    self.cardHolderName(),
                    self.monthSelected(),
                    self.yearSelected(),
                    self.cvvSecurityCode(),
                    self.countrySelected(),
                    self.postalCode()
                );
            }
            return _facade.addPaymentMethod(
                self.cardHolderName(),
                self.cardNumber(),
                self.monthSelected(),
                self.yearSelected(),
                self.cvvSecurityCode(),
                self.countrySelected(),
                self.postalCode()
            );
        };

        self.isCompositionComplete = ko.observable(false);
        self.cardHolderName = ko.observable("").extend({observeState: true});
        self.cardNumber = ko.observable("").extend({observeState: true});
        self.creditCardMask = '(3999 999999 99999|4999 9999 9999 9999|5999 9999 9999 9999|6999 9999 9999 9999)';
        self.cvvSecurityCode = ko.observable("").extend({observeState: true});
        self.countryList = null;
        self.countrySelected = ko.observable("").extend({observeState: true});
        self.expirationDate = ko.observable("");
        self.isCanada = ko.computed(() => self.countrySelected().toString() === _countryEnumerations.ca);
        self.isDefault = ko.observable(false);
        self.isDefaultEnabled = ko.observable(true);
        self.isDuplicateCard = ko.observable(false);
        self.displayedCardName = ko.observable('');
        self.lastFourDigits = ko.observable('');
        self.monthList = null;
        self.monthSelected = ko.observable("").extend({observeState: true});
        self.postalCode = ko.observable("").extend({observeState: true});
        self.postalCodeMask = ko.computed(() => self.isCanada() ? 'A9A 9A9' : '99999');
        self.postalCodeMetaData = ko.pureComputed(() => _countryCodes.findByAbbreviation(self.countrySelected()).hasProvinces ?
            {i18nKey: 'paymentMethod:postalCode', type: 'text', maxLength: 6} :
            {i18nKey: 'paymentMethod:zipCode', type: 'tel', maxLength: 5});
        self.yearList = null;
        self.yearSelected = ko.observable("").extend({observeState: true});
        self.zipCodeOrPostalCode = ko.computed(() => self.isCanada() ? _i18n.t('addPaymentMethod:postalCode') : _i18n.t('addPaymentMethod:zipCode'));
        self.toggleDefaultPaymentMethod = () => {
            self.isDefault(!self.isDefault());
        };
        self.headerI18n = ko.pureComputed(() => self.isEditScreen() ? "addPaymentMethod:headerEdit" : "addPaymentMethod:headerAdd");
        self.isEditScreen = ko.observable(false);

        self.showPaymentFailureMessage = ko.observable(false);
        self.paymentFailureTitle = ko.observable('');
        self.paymentFailureMessage = ko.observable('');

        self.exitAddNewCardScreen = () => {
            self.modelStateObserver.restoreData();
            _router.navigate(_navigationConfiguration.routesById.paymentMethods.url);
        };

        self.validate = () => _validator.validate();

        self.save = () => {
            self.showPaymentFailureMessage(false);
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                  .fail(deferredObject.reject)
                  .done((isValid) => {
                      if (!isValid) {
                          deferredObject.resolve();
                          return;
                      }
                      _addOrEditPaymentMethod()
                        .fail(deferredObject.reject)
                        .done((processingCard) => {
                            switch (processingCard.status) {
                                case _creditCardResponses.success:
                                case _creditCardResponses.approved:
                                    self.modelStateObserver.saveData();
                                    deferredObject.resolve();
                                    _router.navigate(_navigationConfiguration.routesById.paymentMethods.url);
                                    break;
                                case _creditCardResponses.declined:
                                case _creditCardResponses.invalidCard:
                                    self.paymentFailureTitle(_i18n.t('addPaymentMethod:declinedTitle'));
                                    self.paymentFailureMessage(_i18n.t('addPaymentMethod:declinedMessage'));
                                    self.showPaymentFailureMessage(true);
                                    deferredObject.resolve();
                                    break;
                                case _creditCardResponses.duplicateCard:
                                    self.isDuplicateCard(true);
                                    self.showPaymentFailureMessage(false);
                                    deferredObject.resolve();
                                    break;
                                case _creditCardResponses.invalidConnection:
                                    self.paymentFailureTitle(_i18n.t('addPaymentMethod:genericFailureTitle'));
                                    self.paymentFailureMessage(_i18n.t('addPaymentMethod:genericFailureMessage'));
                                    self.showPaymentFailureMessage(true);
                                    deferredObject.resolve();
                                    break;
                                default:
                                    deferredObject.reject(processingCard.status);
                            }
                        });
                  });
            });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = function() {
            _disposables.forEach(s => s.dispose());
            _disposables = [];
        };

        self.activate = (paymentMethodId) => {
            _facade.init(_promiseFactory);

            return _initialize(paymentMethodId);
        };

        const _initialize = (paymentMethodId) => {
            _promiseFactory.defer((initPromise) => {

                let initPromiseFactory = new PromiseFactoryConstructor();
                initPromiseFactory.deferIndefinitely((paymentMethodPromise) => {
                    _populateExpirationDateDropdowns();
                    _populateCountryDropdown();

                    if (paymentMethodId) {
                        self.isEditScreen(true);
                        _facade.getPaymentMethodById(paymentMethodId)
                            .fail(paymentMethodPromise.reject)
                            .done((paymentMethod) => {
                                self.paymentMethodId = paymentMethod.paymentMethodId;
                                self.displayedCardName(_creditCardTypes.getCreditCardDisplayName(paymentMethod.cardType));
                                self.lastFourDigits(paymentMethod.lastFourDigits);
                                self.cardHolderName(paymentMethod.cardHolderName);
                                const _date = new DateConstructor();
                                _date.setFromDateString(paymentMethod.expirationDate);
                                self.monthSelected(_currentMonth(_date.monthPadded()).id);
                                self.yearSelected(_currentYear(_date.year()).id);
                                if (paymentMethod.country) {
                                    self.countrySelected(_currentCountry(paymentMethod.country).id);
                                } else {
                                    self.countrySelected(_currentCountry().id);
                                }
                                self.postalCode(paymentMethod.postalCode);
                                paymentMethodPromise.resolve();
                            });
                    } else {
                        self.countrySelected(_currentCountry().id);
                        self.monthSelected(_currentMonth().id);
                        self.yearSelected(_currentYear().id);
                        paymentMethodPromise.resolve();
                    }
                });

                initPromiseFactory.wait()
                    .done(() => {
                        _validator.registerViewModel(self);
                        _disposables.push(self.countrySelected.subscribe(_onCountryChange));
                        _eventManager.publishInitializeAddPaymentModalComplete();
                        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
                        self.modelStateObserver.commitData();
                        initPromise.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});

