define('businessServices/url/urlBuilder',[],
    function() {
    let _baseUrl = null;
    let _urlParts = [];
    let _queryStringParts = [];

    const _setBaseUrl = (baseUrl) => {
        _baseUrl = baseUrl;
    };

    const _addUrlPath = (path) => {
        _urlParts.push(path);
    };

    const _addQueryString = (key, value) => {
        _queryStringParts.push(key + "=" + value);
    };

    const _buildUrl = () => {
        let url = _baseUrl;

        if (_urlParts.length > 0) {
            url = url += _urlParts.join("/");
        }

        if (_queryStringParts.length > 0) {
            url = url += "?" + _queryStringParts.join("&");
        }

        _urlParts = [];
        _queryStringParts = [];

        return url;
    };

    return function(baseUrl) {
        _setBaseUrl(baseUrl);

        var self = this;

        self.addUrlPath = _addUrlPath;
        self.addQueryString = _addQueryString;
        self.buildUrl = _buildUrl;
    };
});

