define('presentation/settings/billing/history/presentationObjects/billingHistoryPresentationObject',[], function () {
    return function () {
        const self = this;

        self.currentBalance = null;
        self.pastDueBalance = null;
        self.isPastDueBalance = null;
        self.transactions = [];
    };
});
