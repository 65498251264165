 define('presentation/common/routeToModal/viewModels/routeToForwardingNumberViewModel',['common/promises/promiseFactory',
         'businessServices/events/eventManager',
         'constants/autoAttendantVoiceRouteEnumerations',
         'common/storage/commonState',
         'common/collections/collectionSorter',
         'presentation/common/routeToModal/common/routeToViewModelCommonSetup',
         'businessServices/state/modelStateObserver',
         'settings/navigationConfiguration',
         'constants/pressOneToAcceptConstants'
 ], function() {

    return function(initialRoutedToId, initialRouteToType, nameThisOption, routeToData, validateRouteToViewModelCallback, routeToOptions, parentViewModel, isRouteToModal) {
        const self = this;

        const {routingRuleExitConditions, requireKeyPress} = routeToData || {};

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const RouteToViewModelCommonSetupConstructor = require('presentation/common/routeToModal/common/routeToViewModelCommonSetup');

        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _i18n = require('i18next');
        const _pressOneToAcceptConstants = require('constants/pressOneToAcceptConstants');

        const _promiseFactory = new PromiseFactoryConstructor();
        const _routeToViewModelCommonSetup = new RouteToViewModelCommonSetupConstructor(validateRouteToViewModelCallback, routeToOptions);

        const NONE_FORWARDING_NUMBER_ID = "[None]";
        const NONE_FORWARDING_NUMBER_NAME = _i18n.t('routeToForwardingNumber:none');
        const NONE_FORWARDING_NUMBER = {
            id: NONE_FORWARDING_NUMBER_ID,
            name: NONE_FORWARDING_NUMBER_NAME,
            type: _commonState.types.forwardingNumber
        };

        let _disposables = [];

        const _onSelectedChange = () => {
            self.isValid(!(self.selectedForwardingNumber() && self.selectedForwardingNumber().id === NONE_FORWARDING_NUMBER_ID));
            validateRouteToViewModelCallback({});
        };

        self.addForwardingNumberUrl = _navigationConfiguration.routesById.newForwardingNumber.url;
        self.createForwardingNumberLinkText = _i18n.t('routeToForwardingNumber:createForwardingNumber');
        self.noForwardingNumbersWarningMessage = _i18n.t('routeToForwardingNumber:noForwardingNumbersWarningMessage');
        self.pressOneToAcceptWarningMessage = _i18n.t('routeToForwardingNumber:pressOneToAcceptWarningMessage');
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.routeToStrategy = null;
        self.routeToViewModel = ko.observable().extend({observeState: true});
        self.selectedForwardingNumber = ko.observable().extend({observeState: true});
        _disposables.push(self.selectedForwardingNumber.subscribe(_onSelectedChange));
        self.showNoForwardingNumbersMessage = ko.observable(false);
        self.isNewRouteTo = true;
        self.isValid = ko.observable(false);
        self.placeholderMessage = NONE_FORWARDING_NUMBER_NAME;

        self.forwardingNumbers = ko.computed(() => {
            const forwardingNumbers = _commonState.forwardingNumbers();
            const sorter = new SorterConstructor();
            sorter.sort(forwardingNumbers, "name", true);

            if (forwardingNumbers.length === 0) {
                self.selectedForwardingNumber(NONE_FORWARDING_NUMBER);
                self.routeToViewModel(null);
                self.showNoForwardingNumbersMessage(true);
            }
            else if (self.selectedForwardingNumber() === NONE_FORWARDING_NUMBER) {
                self.selectedForwardingNumber(forwardingNumbers[0]);
                self.routeToViewModel(_routeToViewModelCommonSetup.determineSubsequentRoutes(self.isNewRouteTo, self.routeToStrategy, self.selectedForwardingNumber().id, _voiceRouteEnumerations.forwardingNumber, routingRuleExitConditions, parentViewModel));
                self.showNoForwardingNumbersMessage(false);
            }
            return forwardingNumbers;
        });
        self.forwardingNumbersIsEnabled = ko.pureComputed(() => self.forwardingNumbers().length > 0);

        self.pressOneToAcceptOptions = ko.observableArray(_pressOneToAcceptConstants.options);
        self.pressOneToAcceptOption = ko.observable(_pressOneToAcceptConstants.on).extend({observeState: true});
        self.showPressOneToAcceptWarningMessage = ko.computed(() => self.pressOneToAcceptOption() === _pressOneToAcceptConstants.off);

        self.cancelForm = () => {
            return _promiseFactory.defer(function (deferredObject) {
                _eventManager.publishRouteToCloseModal();
                deferredObject.resolve();
            });
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            let forwardingNumber = NONE_FORWARDING_NUMBER;

            if (initialRoutedToId !== null  && initialRouteToType === _voiceRouteEnumerations.forwardingNumber) {
                forwardingNumber = _commonState.get(initialRoutedToId);
            }
            else if (self.forwardingNumbers().length > 0) {
                forwardingNumber = self.forwardingNumbers()[0];
            }
            self.selectedForwardingNumber(forwardingNumber);

            if (forwardingNumber === NONE_FORWARDING_NUMBER) {
                self.showNoForwardingNumbersMessage(true);
            }
            if (forwardingNumber !== NONE_FORWARDING_NUMBER) {
                self.routeToViewModel(_routeToViewModelCommonSetup.determineSubsequentRoutes(self.isNewRouteTo, self.routeToStrategy, forwardingNumber.id, _voiceRouteEnumerations.forwardingNumber, routingRuleExitConditions, parentViewModel, isRouteToModal));
            }
            if (requireKeyPress !== null && requireKeyPress !== undefined) {
                if (requireKeyPress === true) {
                    self.pressOneToAcceptOption(_pressOneToAcceptConstants.on);
                } else {
                    self.pressOneToAcceptOption(_pressOneToAcceptConstants.off);
                }
            } else {
                self.pressOneToAcceptOption(_pressOneToAcceptConstants.on);
            }
            return _promiseFactory.wait();
        };
    };
});

