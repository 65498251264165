define('presentation/settings/users/validators/addUsersModalViewModelValidator',[
    'common/specifications/validEmailAddressSpecification',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        const _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.array("userInvites")
                .arrayField("extension")
                .addValidationI18n(_validateExtension, 'extensionInvalid')
                .addValidationI18n(_validateUniqueExtension, 'extensionAlreadyInUse')
                .arrayField("emailAddress")
                .addValidationI18n(_validateEmailAddressRequired, 'emailAddressRequired')
                .addValidationI18n(_validateEmailAddress, 'emailAddressInvalid')
                .addValidationI18n(_isEmailAddressUnique, 'addUserModal:emailAddressDuplicate')
                .addValidationI18n(_isEmailAddressUniqueInTresta, 'emailAddressUnique');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateEmailAddressRequired = (emailAddress) => {
            const userInvites = _viewModel.userInvites();
            const emptyCount = userInvites.filter((userInvite) => {
                const isStringWithValue = _commonValidator.isStringWithValue(userInvite.emailAddress());
                return !isStringWithValue;
            }).length;
            if ((userInvites.length > 1) && (emptyCount === 1)) {
                return true;
            } else {
                return _commonValidator.isStringWithValue(emailAddress);
            }
        };

        const _validateEmailAddress = (emailAddress) => {
            if (_commonValidator.isStringWithValue(emailAddress)) {
                return _validEmailAddressSpecification.isSatisfiedBy(emailAddress.trim());
            } else {
                return true;
            }
        };

        const _isEmailAddressUnique = (emailAddress) => {
            const userInvites = _viewModel.userInvites();
            const matchingEmailAddressCount = userInvites.filter((userInvite) => {
                return userInvite.emailAddress() === emailAddress;
            }).length;
            return matchingEmailAddressCount === 1;
        };

        const _isEmailAddressUniqueInTresta = (emailAddress) => {
            return _facade.isEmailAddressUnique(emailAddress.trim());
        };

        const _validateExtension = (extension) => {
            if (extension !== "") {
                return _commonValidator.isNumber(extension);
            } else {
                return false;
            }
            return true;
        };

        const _validateUniqueExtension = (extension) => {
            if (_validateExtension(extension)) {
                const addUsersPresentationObjects = _viewModel.userInvites();
                const matchingExtensionCount = addUsersPresentationObjects.filter((addUserPresentationObject) => {
                    return addUserPresentationObject.extension() === extension;
                }).length;
                if (matchingExtensionCount === 1) {
                    return _facade.isExtensionUnique(extension);
                } else {
                    return false;
                }
            }
            return true;
        };
    };
});

