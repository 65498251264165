define('businessServices/validationMessages/validationMessage',['persistence/dataProviders/customerServiceDataProvider',
        'common/promises/promiseFactory'],
    function() {
        var _customerServiceInfoDataProvider = null;
        var _customerServicePhoneNumber = "";
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var TAG_CUSTOMER_SERVICE_PHONE_NUMBER = "[customer service phone number]";

        var _getCustomerServicePhoneNumber = function() {
            return _promiseFactory.defer(function(deferredObject) {
                if (_customerServicePhoneNumber === "") {
                    _customerServiceInfoDataProvider.getSupportPhone()
                        .done(function(customerServicePhoneNumber) {
                            _customerServicePhoneNumber = customerServicePhoneNumber;
                            deferredObject.resolve(_customerServicePhoneNumber);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                } else {
                    deferredObject.resolve(_customerServicePhoneNumber);
                }
            });
        };

        var _formatMessage = function(message) {
            return _promiseFactory.defer(function(deferredObject) {
                if (message.indexOf(TAG_CUSTOMER_SERVICE_PHONE_NUMBER) > -1) {
                    _getCustomerServicePhoneNumber()
                        .done(function(customerServicePhoneNumber) {
                            var updatedMessage = _replaceAll(message, TAG_CUSTOMER_SERVICE_PHONE_NUMBER, customerServicePhoneNumber);
                            deferredObject.resolve(updatedMessage);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                } else {
                    deferredObject.resolve(message);
                }
            });
        };

        var _replaceAll = function(string, find, replace) {
            return string.replace(new RegExp(_escapeRegExp(find), 'g'), replace);
        };

        var _escapeRegExp = function(string) {
            return string.replace(/[|\\{}()\[\]\^$+*?.]/g, '\\$&');
        };

        return {
            get: function(validationMessage) {
                if (validationMessage === undefined) {
                    throw new Error('the validationMessage passed to validationRule.addValidation was undefined.');
                }
                return _formatMessage(validationMessage);
            },
            init: function() {
                var CustomerServiceInfoDataProviderConstructor = require('persistence/dataProviders/customerServiceDataProvider');
                _customerServiceInfoDataProvider = new CustomerServiceInfoDataProviderConstructor();
            },
            init2: function(customerServiceInfoDataProvider) {
                _customerServiceInfoDataProvider = customerServiceInfoDataProvider;
            }
        };
});

