define('businessServices/router/plugins/userDeviceEvaluationRouterPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/sessionAccountInfo'
], function (navigationConfiguration, sessionAccountInfo) {
    var _navigationConfiguration = navigationConfiguration;
    var _sessionAccountInfo = sessionAccountInfo;

    function isUserDeviceAllowedForRoute(routeId) {
        if (_navigationConfiguration.routesById !== undefined) {
            var navigationRouteInfo = _navigationConfiguration.routesById[routeId];
            var routeAllowsMobilePhoneUser = navigationRouteInfo.allowMobilePhoneUser;
            
            if (routeAllowsMobilePhoneUser === false && _sessionAccountInfo.userDeviceIsMobilePhone() === true) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    function routeByUserDevice(routeId) {
        if (isUserDeviceAllowedForRoute(routeId)) {
            return true;
        } else {
            return {routeUrl : navigationConfiguration.loginPageUrl};
        }
    }

    return {
        guardRoute: routeByUserDevice
    };
});

