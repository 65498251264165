define('businessServices/converters/phoneNumberFormatToAccentedAreaCode',[],
function () {

    var _convert = function(phoneNumber) {
        if (phoneNumber === null || phoneNumber === undefined) {
            return "";
        } else if (phoneNumber.length === 10 && isNaN(phoneNumber) === false) {
            return "(" + phoneNumber.slice(0, 3) + ") " + phoneNumber.slice(3, 6) + "-" + phoneNumber.slice(6, 10);
        } else if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
            return "(" + phoneNumber.slice(1, 4) +  ") " + phoneNumber.slice(4, 7) + "-" + phoneNumber.slice(7, 11);
        } else if(phoneNumber.length === 12 && phoneNumber[0] === '+') {
            return "(" + phoneNumber.slice(2, 5) +  ") " + phoneNumber.slice(5, 8) + "-" + phoneNumber.slice(8, 12);
        } else {
            return phoneNumber;
        }
    };

    return function() {
        var self = this;
        self.convert = _convert;
    };
});

