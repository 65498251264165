define('presentation/settings/userProfile/viewModels/userProfileViewModel',['businessServices/country/timeZones',
        'businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'constants/secretQuestionEnumerations',
        'presentation/common/croppableImage/presentationObjects/avatar',
        'presentation/common/modal',
        'presentation/settings/userProfile/facades/userProfileFacade',
        'presentation/settings/userProfile/validators/userProfileViewModelValidator',
        'presentation/settings/userProfile/viewModels/changePasswordModalViewModel',
        'presentation/settings/userProfile/viewModels/changeVerificationQAModalViewModel',
        'presentation/settings/userProfile/viewModels/confirmPasswordModalViewModel',
        'presentation/settings/userProfile/viewModels/resendInviteModalViewModel'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const TimeZoneProviderConstructor = require('businessServices/country/timeZones');
        const _timeZoneProvider = new TimeZoneProviderConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        let ChangePasswordModalConstructor = null;
        let ChangeVerificationQAModalConstructor = null;
        let ConfirmPasswordModalConstructor = null;
        let ResendInviteModalConstructor = null;

        let _facade = null;
        let _lastSavedEmailAddress = null;
        let _modalService = null;
        let _userId = _sessionAccountInfo.userId();
        let _validator = null;

        const _populateFormData = (userProfile) => {
            self.accountNumber = userProfile.accountNumber;
            self.hasAvatar(userProfile.avatar.showImage());
            self.avatar(userProfile.avatar);
            self.firstName(userProfile.firstName);
            self.lastName(userProfile.lastName);
            self.title(userProfile.title);
            self.emailAddress(userProfile.emailAddress);
            self.originalEmailAddress = userProfile.emailAddress;
            self.timeZone(userProfile.timeZone);
            self.status(userProfile.status);
            _lastSavedEmailAddress = self.emailAddress();
        };

        const _saveUserProfile = (password) => {
            return _promiseFactory.defer((deferredObject) => {
                if (password === undefined) {
                    password = null;
                }

                _facade.saveUserProfile(_userId, self.avatar(), self.firstName(), self.lastName(), self.title(), self.emailAddress(), password, self.timeZone())
                    .fail(deferredObject.reject)
                    .done(() => {
                        _facade.getUserProfile(_userId, true)
                            .done((userProfile) => {
                                _populateFormData(userProfile);
                                self.modelStateObserver.saveData();
                                deferredObject.resolve();
                            })
                            .fail((error) => {
                                throw error;
                            });
                    });
            });
        };

        self.isSubForm = ko.observable(false);
        self.isUserSelf = ko.observable(true);
        self.modelStateObserver = null;
        self.accountNumber = null;
        self.hasAvatar = ko.observable(false);
        self.avatar = ko.observable().extend({observeState: true});
        self.firstName = ko.observable('').extend({observeState: true});
        self.lastName = ko.observable('').extend({observeState: true});
        self.title = ko.observable('').extend({observeState: true});
        self.emailAddress = ko.observable('').extend({observeState: true});
        self.originalEmailAddress = null;
        self.timeZone = ko.observable('').extend({observeState: true});
        self.status = ko.observable('');
        self.isImpersonating = ko.observable(false);
        self.isSystemAdmin = ko.observable(false);

        self.status = ko.observable(null);
        self.isInvitedStatus = ko.pureComputed(() => self.status() === "invited");
        self.isExpiredStatus = ko.pureComputed(() => self.status() === "expired");
        self.isUserPending = ko.computed(() => self.isInvitedStatus() || self.isExpiredStatus());
        
        self.showSecurityChangeLinks = ko.pureComputed(() => {
            return self.isUserSelf() &&
                   !self.isImpersonating();
        });
        self.showResetPassword = ko.pureComputed(() => {
            if (self.isImpersonating()) {
               return true;
            }  else {
                return !self.isUserPending() &&
                    !self.isUserSelf() &&
                    self.isSystemAdmin();
            }
        });
        self.showResendInvite = ko.pureComputed(() => {
            return self.isUserPending()  &&
                self.isSystemAdmin() &&
                self.emailAddress() === self.originalEmailAddress;
        });

        const _availableTimezones = _timeZoneProvider.getSortedTimeZones();
        self.timeZones = _availableTimezones.map((timeZone) => {
            timeZone.id = timeZone.key;
            timeZone.text = timeZone.name;
            return timeZone;
        });

        self.changePassword = () => {
            const changePasswordModal = new ChangePasswordModalConstructor(_userId, _lastSavedEmailAddress);
            _modalService.showModal(changePasswordModal);
        };

        self.changeVerificationQA = () => {
            const changeVerificationQAModal = new ChangeVerificationQAModalConstructor();
            _modalService.showModal(changeVerificationQAModal);
        };

        self.resendInvite = () => {
            const resentInviteModal = new ResendInviteModalConstructor(_userId);
            _modalService.showModal(resentInviteModal);
        };

        self.validate = () => _validator.validate();

        self.save = () => {
            const _savePromise = self.isSubForm() ? _promiseFactory.deferIndefinitely() : _promiseFactory.deferWithMinimumWait();

            _validator.validate()
                .fail(_savePromise.reject)
                .done((isValid) => {
                    if (isValid === false) {
                        _savePromise.resolve();
                        return;
                    }

                    _saveUserProfile()
                        .done(_savePromise.resolve)
                        .fail(_savePromise.reject);
                });

            return _savePromise;
        };

        self.cancelForm = () => {
            if (self.firstName.isDirty){
                self.firstName.forceUpdate(true);
            }
            if (self.lastName.isDirty){
                self.lastName.forceUpdate(true);
            }
            if (self.title.isDirty){
                self.title.forceUpdate(true);
            }
            if (self.emailAddress.isDirty){
                self.emailAddress.forceUpdate(true);
            }

            self.modelStateObserver.restoreData();
        };

        self.activate = (userId) => {
            if (userId !== undefined) {
                _userId = userId;
                self.isSubForm(true);
                self.isUserSelf(userId === _sessionAccountInfo.userId());
            }

            const FacadeConstructor = require('presentation/settings/userProfile/facades/userProfileFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            const ValidatorConstructor = require('presentation/settings/userProfile/validators/userProfileViewModelValidator');
            _validator = new ValidatorConstructor();

            ChangePasswordModalConstructor = require('presentation/settings/userProfile/viewModels/changePasswordModalViewModel');
            ChangeVerificationQAModalConstructor = require('presentation/settings/userProfile/viewModels/changeVerificationQAModalViewModel');
            ConfirmPasswordModalConstructor = require('presentation/settings/userProfile/viewModels/confirmPasswordModalViewModel');
            ResendInviteModalConstructor = require('presentation/settings/userProfile/viewModels/resendInviteModalViewModel');

            return _initialize();
        };

        const _initialize = () => {
            self.isImpersonating(_facade.isImpersonating());
            self.isSystemAdmin(_sessionAccountInfo.accountPermissions().SystemSettings);
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            _facade.getUserProfile(_userId, false)
                .done((userProfile) => {
                    _populateFormData(userProfile);
                    self.modelStateObserver.commitData();
                    _validator.registerViewModel(self, _facade);
                })
                .fail((error) => {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

