define('common/presentation/commonWidgetConfiguration',['plugins/widget'], function() {
    return {
        configureWidgets: function() {
            var widget = require('plugins/widget');

            widget.registerKind('errorNotification');
            widget.mapKind('errorNotification',
                'presentation/common/errorNotification/views/errorNotification',
                'presentation/common/errorNotification/viewModels/errorNotificationViewModel');

            widget.registerKind('textBox');
            widget.mapKind('textBox',
                'presentation/common/textBox/views/textBox',
                'presentation/common/textBox/viewModels/textBox');

            widget.registerKind('intlTelPhoneInput');
            widget.mapKind('intlTelPhoneInput',
                'presentation/common/intlTelPhoneInput/views/intlTelPhoneInput',
                'presentation/common/intlTelPhoneInput/viewModels/intlTelPhoneInputViewModel');

            widget.registerKind('intlTelPhoneInput2');
            widget.mapKind('intlTelPhoneInput2',
                'presentation/common/intlTelPhoneInput/views/intlTelPhoneInput2',
                'presentation/common/intlTelPhoneInput/viewModels/intlTelPhoneInputViewModel');

            widget.registerKind('dropDownThemed2');
            widget.mapKind('dropDownThemed2',
                'presentation/common/dropDownThemed/views/dropDownThemed',
                'presentation/common/dropDownThemed/viewModels/dropDownThemedViewModel');

            widget.registerKind('select');
            widget.mapKind('select',
                'presentation/common/select/views/select',
                'presentation/common/select/viewModels/selectViewModel');

            widget.registerKind('filterSelection');
            widget.mapKind('filterSelection',
                'presentation/common/filterSelection/views/filterSelection',
                'presentation/common/filterSelection/viewModels/filterSelectionViewModel');

            widget.registerKind('membershipSelection');
            widget.mapKind('membershipSelection',
                'presentation/common/membershipSelection/views/membershipSelection',
                'presentation/common/membershipSelection/viewModels/membershipSelectionViewModel');

            widget.registerKind('toggleButton');
            widget.mapKind('toggleButton',
                'presentation/common/toggleButton/views/toggleButton',
                'presentation/common/toggleButton/viewModels/toggleButtonViewModel');

            widget.registerKind('longToggleButton');
            widget.mapKind('longToggleButton',
                'presentation/common/toggleButton/views/longToggleButton',
                'presentation/common/toggleButton/viewModels/longToggleButtonViewModel');

            widget.registerKind('croppableImage');
            widget.mapKind('croppableImage',
                'presentation/common/croppableImage/views/croppableImage',
                'presentation/common/croppableImage/viewModels/croppableImageViewModel');

            widget.registerKind('filterMenu');
            widget.mapKind('filterMenu',
                'presentation/common/filterMenu/views/filterMenu',
                'presentation/common/filterMenu/viewModels/filterMenuViewModel');

            widget.registerKind('filterSortMenu');
            widget.mapKind('filterSortMenu',
                'presentation/common/filterSortMenu/views/filterSortMenu',
                'presentation/common/filterSortMenu/viewModels/filterSortMenuViewModel');

            widget.registerKind('searchSortMenu');
            widget.mapKind('searchSortMenu',
                'presentation/common/searchSortMenu/views/searchSortMenu',
                'presentation/common/searchSortMenu/viewModels/searchSortMenuViewModel');
            
            widget.registerKind('searchTextBox');
            widget.mapKind('searchTextBox',
                'presentation/common/searchTextBox/views/searchTextBox',
                'presentation/common/searchTextBox/viewModels/searchTextBoxViewModel');
        }
    };
});

