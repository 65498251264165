define('presentation/settings/users/viewModels/editUserViewModel',['businessServices/state/modelStateObserver',
        'businessServices/router/router',
        'common/promises/promiseFactory',
        'presentation/settings/userDevices/viewModels/userDevicesViewModel',
        'presentation/settings/userGroupMembership/viewModels/userGroupMembershipViewModel',
        'presentation/settings/userProfile/viewModels/userProfileViewModel',
        'presentation/settings/userSubscriptions/viewModels/userSubscriptionsViewModel',
        'presentation/settings/userVoicemail/viewModels/userVoicemailViewModel',
        'presentation/tabs/presentationObjects/tabsPresentationObject',
        'settings/navigationConfiguration'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');
        const UserDevicesTabViewModelConstructor = require('presentation/settings/userDevices/viewModels/userDevicesViewModel');
        const UserGroupMembershipTabViewModelConstructor = require('presentation/settings/userGroupMembership/viewModels/userGroupMembershipViewModel');
        const UserProfileTabViewModelConstructor = require('presentation/settings/userProfile/viewModels/userProfileViewModel');
        const UserSubscriptionsTabViewModelConstructor = require('presentation/settings/userSubscriptions/viewModels/userSubscriptionsViewModel');
        const UserVoicemailTabViewModelConstructor = require('presentation/settings/userVoicemail/viewModels/userVoicemailViewModel');

        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        let _disposables = [];

        const _updateActiveTabViewModel = () => {
            switch (self.tabActive()) {
                case self.tabMetadata.profile.id:
                    self.activeTabViewModel(self.userProfileTabViewModel);
                    break;
                case self.tabMetadata.devices.id:
                    self.activeTabViewModel(self.userDevicesTabViewModel);
                    break;
                case self.tabMetadata.groups.id:
                    self.activeTabViewModel(self.userGroupMembershipTabViewModel);
                    break;
                case self.tabMetadata.subscriptions.id:
                    self.activeTabViewModel(self.userSubscriptionsTabViewModel);
                    break;
                case self.tabMetadata.voicemail.id:
                    self.activeTabViewModel(self.userVoicemailTabViewModel);
                    break;
            }
        };

        self.userId = null;
        self.usersUrl = _navigationConfiguration.routesById.users.url;
        self.modelStateObserver = null;
        self.tabActive = ko.observable();
        self.tabMetadata = {
            profile: { id: "profile", isValid: ko.observable(true), title: _i18n.t('editUser:profileTab') },
            devices: { id: "devices", isValid: ko.observable(true), title: _i18n.t('editUser:devicesTab') },
            groups: { id: "groups", isValid: ko.observable(true), title: _i18n.t('editUser:groupsTab') },
            subscriptions: { id: "subscriptions", isValid: ko.observable(true), title: _i18n.t('editUser:subscriptionsTab') },
            voicemail: { id: "voicemail", isValid: ko.observable(true), title: _i18n.t('editUser:voicemailTab') },
        };
        self.tabs = [
            new TabPresentationObjectConstructor(self.tabMetadata.profile.id, self.tabActive, self.tabMetadata.profile.isValid, self.tabMetadata.profile.title),
            new TabPresentationObjectConstructor(self.tabMetadata.devices.id, self.tabActive, self.tabMetadata.devices.isValid, self.tabMetadata.devices.title),
            new TabPresentationObjectConstructor(self.tabMetadata.groups.id, self.tabActive, self.tabMetadata.groups.isValid, self.tabMetadata.groups.title),
            new TabPresentationObjectConstructor(self.tabMetadata.subscriptions.id, self.tabActive, self.tabMetadata.subscriptions.isValid, self.tabMetadata.subscriptions.title),
            new TabPresentationObjectConstructor(self.tabMetadata.voicemail.id, self.tabActive, self.tabMetadata.voicemail.isValid, self.tabMetadata.voicemail.title)
        ];

        self.tabActive(self.tabs[0].id);
        self.isTabActive = (tabId) => {
            return self.tabActive() === tabId;
        };
        self.userDevicesTabViewModel = null;
        self.userGroupMembershipTabViewModel = null;
        self.userProfileTabViewModel = null;
        self.userSubscriptionsTabViewModel = null;
        self.userVoicemailTabViewModel = null;
        self.activeTabViewModel = ko.observable();
        self.isDeviceTabDirty = ko.observable(false).extend({observeState: true});

        self.invalidTabs = ko.pureComputed(() => {
            return self.tabs.reduce((accumulator, presentationObject) => {
                if (!presentationObject.isTabValid()) {
                    accumulator.push(presentationObject.tabText);
                }

                return accumulator;
            }, []);
        });

        self.validationWarningMessages = ko.pureComputed(() => {
            return [
                {
                    message: _i18n.t('editUser:validationTabDescription'),
                    listItems: self.invalidTabs()
                },
                {
                    message: _i18n.t('editUser:validationPleaseFix'),
                    listItems: []
                }
            ];
        });

        self.forceValidation = () => {
            return _promiseFactory.defer((validatePromise) => {
                let isProfileValid = null;
                let isDevicesValid = null;
                let isVoicemailValid = null;

                let validatePromiseFactory = new PromiseFactoryConstructor();
                validatePromiseFactory.defer((profileValidationDeferredObject) => {
                    self.userProfileTabViewModel.validate()
                        .fail(profileValidationDeferredObject.reject)
                        .done((isValid) => {
                            isProfileValid = isValid;
                            profileValidationDeferredObject.resolve();
                        });
                });

                if (self.isDeviceTabDirty) {
                    validatePromiseFactory.defer((devicesValidationPromise) => {
                        self.userDevicesTabViewModel.forceValidation()
                            .fail(devicesValidationPromise.reject)
                            .done((isValid) => {
                                isDevicesValid = isValid;
                                devicesValidationPromise.resolve();
                            });
                    });
                } else {
                    isDevicesValid = true;
                }

                validatePromiseFactory.defer((voicemailValidationPromise) => {
                    self.userVoicemailTabViewModel.forceValidation()
                        .fail(voicemailValidationPromise.reject)
                        .done((isValid) => {
                            isVoicemailValid = isValid;
                            voicemailValidationPromise.resolve();
                        });
                });

                validatePromiseFactory.wait()
                    .fail(validatePromise.reject)
                    .done(() => {
                        self.tabMetadata.profile.isValid(isProfileValid);
                        self.tabMetadata.devices.isValid(isDevicesValid);
                        self.tabMetadata.voicemail.isValid(isVoicemailValid);
                        validatePromise.resolve(isProfileValid && isDevicesValid && isVoicemailValid);
                    });

            });
        };

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                self.forceValidation()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            promise.resolve();
                            return;
                        }

                        const innerPromiseFactory = new PromiseFactoryConstructor();

                        if (self.userProfileTabViewModel.modelStateObserver.isDirty()) {
                            innerPromiseFactory.deferIndefinitely((profileInnerPromise) => {
                                self.userProfileTabViewModel.save()
                                    .fail(profileInnerPromise.reject)
                                    .done(profileInnerPromise.resolve);
                            });
                        }

                        if (self.userDevicesTabViewModel.modelStateObserver.isDirty() ||
                            self.isDeviceTabDirty) {
                            innerPromiseFactory.deferIndefinitely((deviceInnerPromise) => {
                                self.userDevicesTabViewModel.savePage()
                                    .fail(deviceInnerPromise.reject)
                                    .done(deviceInnerPromise.resolve);
                            });
                        }

                        if (self.userGroupMembershipTabViewModel.modelStateObserver.isDirty()) {
                            innerPromiseFactory.deferIndefinitely((groupInnerPromise) => {
                                self.userGroupMembershipTabViewModel.savePage()
                                    .fail(groupInnerPromise.reject)
                                    .done(groupInnerPromise.resolve);
                            });
                        }

                        if (self.userSubscriptionsTabViewModel.modelStateObserver.isDirty()) {
                            innerPromiseFactory.deferIndefinitely((subscriptionsInnerPromise) => {
                                self.userSubscriptionsTabViewModel.save()
                                    .fail(subscriptionsInnerPromise.reject)
                                    .done(subscriptionsInnerPromise.resolve);
                            });
                        }

                        if (self.userVoicemailTabViewModel.modelStateObserver.isDirty()) {
                            innerPromiseFactory.deferIndefinitely((voicemailInnerPromise) => {
                                self.userVoicemailTabViewModel.savePage()
                                    .fail(voicemailInnerPromise.reject)
                                    .done(voicemailInnerPromise.resolve);
                            });
                        }

                        innerPromiseFactory.wait()
                            .fail(promise.reject)
                            .done(() => {
                                self.modelStateObserver.saveData();
                                _router.navigate(self.usersUrl);
                                promise.resolve();
                            });
                    });
            });
        };

        self.cancelForm = () => {
            self.userProfileTabViewModel.cancelForm();
            self.userDevicesTabViewModel.cancelForm();
            self.userGroupMembershipTabViewModel.cancelForm();
            self.userSubscriptionsTabViewModel.cancelForm();
            self.userVoicemailTabViewModel.cancelForm();
            self.modelStateObserver.restoreData();

            _router.navigate(self.usersUrl);
        };

        self.detached = () => {
            self.userSubscriptionsTabViewModel.dispose();
            self.userVoicemailTabViewModel.dispose();

            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = (editId, tabId) => {
            self.userProfileTabViewModel = new UserProfileTabViewModelConstructor();
            self.userDevicesTabViewModel = new UserDevicesTabViewModelConstructor();
            self.userGroupMembershipTabViewModel = new UserGroupMembershipTabViewModelConstructor();
            self.userSubscriptionsTabViewModel = new UserSubscriptionsTabViewModelConstructor();
            self.userVoicemailTabViewModel = new UserVoicemailTabViewModelConstructor();

            return _initialize(editId, tabId);
        };

        const _initialize = (editId, tabId) => {
            self.userId = editId;

            _promiseFactory.defer((initPromise) => {
                _initializeTabs(tabId)
                    .fail(initPromise.reject)
                    .done(() => {

                        _disposables.push(self.userDevicesTabViewModel.isDirty.subscribe((isDirty) => {
                            self.isDeviceTabDirty(isDirty);
                        }));

                        self.modelStateObserver = new ModelStateObserverConstructor(self, true, [self.userProfileTabViewModel.modelStateObserver, self.userDevicesTabViewModel.modelStateObserver, self.userGroupMembershipTabViewModel.modelStateObserver, self.userSubscriptionsTabViewModel.modelStateObserver, self.userVoicemailTabViewModel.modelStateObserver]);
                        self.modelStateObserver.commitData();
                        initPromise.resolve();
                    });

            });

            return _promiseFactory.wait();
        };

        const _initializeTabs = (tabId) => {
            return _promiseFactory.defer((initTabsPromise) => {
                let initTabsPromiseFactory = new PromiseFactoryConstructor();
                initTabsPromiseFactory.defer((profilePromise) => {
                    self.userProfileTabViewModel.activate(self.userId)
                        .fail(profilePromise.reject)
                        .done(profilePromise.resolve);
                });
                initTabsPromiseFactory.defer((devicesPromise) => {
                    self.userDevicesTabViewModel.activate(self.userId)
                        .fail(devicesPromise.reject)
                        .done(devicesPromise.resolve);
                });
                initTabsPromiseFactory.defer((groupsPromise) => {
                    self.userGroupMembershipTabViewModel.activate(self.userId)
                        .fail(groupsPromise.reject)
                        .done(groupsPromise.resolve);
                });
                initTabsPromiseFactory.defer((subscriptionsPromise) => {
                    self.userSubscriptionsTabViewModel.activate(self.userId)
                        .fail(subscriptionsPromise.reject)
                        .done(subscriptionsPromise.resolve);
                });
                initTabsPromiseFactory.defer((voicemailPromise) => {
                    self.userVoicemailTabViewModel.activate({
                        userId: self.userId,
                        status: self.status
                    })
                        .fail(voicemailPromise.reject)
                        .done(voicemailPromise.resolve);
                });

                initTabsPromiseFactory.wait()
                    .fail(initTabsPromise.reject)
                    .done(() => {
                        if (self.tabs.some(t => t.id === tabId)) {
                            self.tabActive(tabId);
                        } else {
                            let firstTab = self.tabs.find(t => true);
                            self.tabActive(firstTab.id);
                        }

                        _updateActiveTabViewModel();
                        _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                        initTabsPromise.resolve();
                    });
            });
        };
    };
});

