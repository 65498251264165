define('presentation/common/smsAttachment/factories/smsAttachmentFactory',[
        'constants/attachmentFileTypesConstants',
        'presentation/common/conversationImagesGrid/conversationImagesGridPresentationObject',
        'presentation/common/conversationImagesGrid/conversationImagePresentationObject',
        'presentation/common/videoPlayer/videoPresentationObject',
        'constants/videoPlayerConstants'
    ],
    function (
        /** @type typeof import('constants/attachmentFileTypesConstants') */
        AttachmentFileTypes,
        /** @type typeof import('presentation/common/conversationImagesGrid/conversationImagesGridPresentationObject') */
        ConversationImagesGridPresentationObject,
        /** @type typeof import('presentation/common/conversationImagesGrid/conversationImagePresentationObject') */
        ConversationImagePresentationObject,
        /** @type typeof import('presentation/common/videoPlayer/videoPresentationObject') */
        VideoPresentationObject,
        /** @type typeof import('constants/videoPlayerConstants') */
        VideoPlayerConstants
    ) {

        //#region Images
        /**
         * @param {string} src
         * @param {string} contentType
         * @returns {ConversationImagePresentationObject}
         * */
        const getImage = (src, contentType) => {
            const image = new ConversationImagePresentationObject();
            image.src(src);
            image.contentType(contentType);
            return image;
        };

        const getImagesGrid = (/** @type {ConversationImagePresentationObject[]} */ images) => {
            const imagesGrid = new ConversationImagesGridPresentationObject();
            imagesGrid.images(images);
            return imagesGrid;
        };

        const getEmptyImage = (/** @type {string}*/ contentType) => {
            const image = new ConversationImagePresentationObject();
            image.contentType(contentType);
            return image;
        };
        //#endregion

        //#region Videos
        const _setInitVideoPlayer = (videoPlayer) => {
            videoPlayer.wasVideoStarted(false);
            videoPlayer.videoCurrentTime(VideoPlayerConstants.defaultTotalTime);
            videoPlayer.videoTotalTime(VideoPlayerConstants.defaultTotalTime);
            videoPlayer.videoInitialLoadTime(VideoPlayerConstants.firstMilliSecond);
            return videoPlayer;
        };

        const getEmptyVideoPlayer = (/** @type {string} */ contentType) => {
            const emptyVideoPlayer = new VideoPresentationObject();
            emptyVideoPlayer.contentType(contentType);
            return _setInitVideoPlayer(emptyVideoPlayer);
        };

        /**
         * @param {string} src
         * @param {string} contentType
         * @param {string} attachmentId
         * @returns {IVideoPresentationObject}
         */
        const getVideoPlayer = (src, contentType, attachmentId) => {
            const videoPlayer = new VideoPresentationObject();
            videoPlayer.contentType(contentType);
            videoPlayer.src(src);
            videoPlayer.attachmentId(attachmentId);
            return _setInitVideoPlayer(videoPlayer);
        };
        //#endregion

        /**
         * @param {string} src
         * @param {string} contentType
         * @param {string?} attachmentId
         * @returns {ConversationImagePresentationObject | IVideoPresentationObject}
         */
        const getAttachmentPresentation = (src, contentType, attachmentId) => {
            if (contentType.includes(AttachmentFileTypes.image)) {
                return getImage(src, contentType);
            } else if (contentType.includes(AttachmentFileTypes.video)) {
                return getVideoPlayer(src, contentType, attachmentId);
            } else {
                return null;
            }
        };

        return {
            getAttachmentPresentation,
            getEmptyImage,
            getImagesGrid,
            getImage,
            getEmptyVideoPlayer,
            getVideoPlayer
        };
    }
);

