define('presentation/analytics/facades/analyticsSidebarFacade',[
        'businessServices/userSettings/userSettingStore',
        'common/time/datetimeFormatter',
    ], function () {
        /** @typedef {import('presentation/analytics/facades/analyticsSidebarFacade')} AnalyticsSidebarFacade */
        /** @type import('common/promises/promiseFactory') */
        let _promiseFactory = null;
        let _socket = null;
        /** @type import('businessServices/userSettings/userSettingStore') */
        let _userSettingStore = null;
        const DateTimeFormatterConstructor = require('common/time/datetimeFormatter');
        const _dateTimeFormatter = new DateTimeFormatterConstructor();

        /** @type {ISidebarSettings} */
        const DEFAULT_SETTINGS = {
            selectedShowFilter: "calls",
            selectedPhoneNumbers: [],
            includeCallsRoutedToUsersThroughUserGroups: true,
            selectedFromToItems: [],
            selectedDuringFilter: 'last_days_7',
            duringCustomStartOn: '',
            duringCustomEndOn: '',
            selectedCallTypeFilter: 'all',
        };

        /** @type {AnalyticsSidebarFacade['init']} */
        const _init = function (promiseFactory) {
            _promiseFactory = promiseFactory;

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _socket = new WebSocketAppConstructor();
            _socket.init("analytics");

            const UserSettingStoreConstructor = require('businessServices/userSettings/userSettingStore');
            _userSettingStore = new UserSettingStoreConstructor();
            _userSettingStore.init();
        };

        /**
         * @template T
         * @param {T | null | undefined} value
         * @param {T} defaultValue
         * @returns {T}
         */
        const _getSetting = function(value, defaultValue) {
            return (value === undefined || value === null) ? defaultValue : value;
        };

        /** @type {AnalyticsSidebarFacade['getSidebarSettings']} */
        const _getSidebarSettings = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _userSettingStore.get("analyticsSidebar")
                    .fail(promise.reject)
                    .done((sidebarSettings) => {
                        /** @type {ISidebarSettings} */
                        const settings = {
                            selectedShowFilter: _getSetting(sidebarSettings.selectedShowFilter, DEFAULT_SETTINGS.selectedShowFilter),
                            selectedPhoneNumbers: _getSetting(sidebarSettings.numbers, DEFAULT_SETTINGS.selectedPhoneNumbers),
                            includeCallsRoutedToUsersThroughUserGroups: _getSetting(sidebarSettings.includeCallsRoutedUG, DEFAULT_SETTINGS.includeCallsRoutedToUsersThroughUserGroups),
                            selectedFromToItems: _getSetting(sidebarSettings.fromTo, DEFAULT_SETTINGS.selectedFromToItems),
                            selectedDuringFilter: _getSetting(sidebarSettings.selectedDuringFilter, DEFAULT_SETTINGS.selectedDuringFilter),
                            duringCustomStartOn: _formatCustomDateTextFromIso(_getSetting(sidebarSettings.duringCustomStartOn, DEFAULT_SETTINGS.duringCustomStartOn)),
                            duringCustomEndOn: _formatCustomDateTextFromIso(_getSetting(sidebarSettings.duringCustomEndOn, DEFAULT_SETTINGS.duringCustomEndOn)),
                            selectedCallTypeFilter: 'external', // TODO restore when internal calls are enabled in analyticsSidebarViewModel _getSetting(sidebarSettings.selectedCallTypeFilter, DEFAULT_SETTINGS.selectedCallTypeFilter),
                        };
                        promise.resolve(settings);
                    });
            });
        };

        const _formatCustomDateTextToIso = (/** @type {string} */ customDateTimeText) => {
            return _dateTimeFormatter.customDateTimeTextToIso(customDateTimeText, "MM/DD/YYYY hh:mm a");
        };
        const _formatCustomDateTextFromIso = (/** @type {string} */customDateTimeIso) => {
            return _dateTimeFormatter.customDateTimeTextFromIso(customDateTimeIso, "MM/DD/YYYY hh:mm a");
        };

        /** @type {AnalyticsSidebarFacade['saveSidebarSettings']} */
        const _saveSidebarSettings = function (sidebarSettings) {
            return _promiseFactory.deferIndefinitely(function (promise) {
                const settings = {
                    selectedShowFilter: _getSetting(sidebarSettings.selectedShowFilter, DEFAULT_SETTINGS.selectedShowFilter),
                    numbers: _getSetting(sidebarSettings.selectedPhoneNumbers, DEFAULT_SETTINGS.selectedPhoneNumbers),
                    includeCallsRoutedUG: _getSetting(sidebarSettings.includeCallsRoutedToUsersThroughUserGroups, DEFAULT_SETTINGS.includeCallsRoutedToUsersThroughUserGroups),
                    fromTo: _getSetting(sidebarSettings.selectedFromToItems, DEFAULT_SETTINGS.selectedFromToItems),
                    selectedDuringFilter: _getSetting(sidebarSettings.selectedDuringFilter, DEFAULT_SETTINGS.selectedDuringFilter),
                    duringCustomStartOn: _formatCustomDateTextToIso(_getSetting(sidebarSettings.duringCustomStartOn, DEFAULT_SETTINGS.duringCustomStartOn)),
                    duringCustomEndOn: _formatCustomDateTextToIso(_getSetting(sidebarSettings.duringCustomEndOn, DEFAULT_SETTINGS.duringCustomEndOn)),
                    selectedCallTypeFilter: _getSetting(sidebarSettings.selectedCallTypeFilter, DEFAULT_SETTINGS.selectedCallTypeFilter),
                };
                _userSettingStore.set("analyticsSidebar", settings)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        return function () {
            const self = this;

            self.init = _init;
            self.getSidebarSettings = _getSidebarSettings;
            self.saveSidebarSettings = _saveSidebarSettings;
        };
    }
);

