define('persistence/dataProviders/customerServiceDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();
        _kazooApi.init();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.get = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.unauthenticatedCall('lobby_customer_service_info','GET')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getSupportPhone = function() {
            return _promiseFactory.defer(function(promise) {
                _kazooApi.unauthenticatedCall('lobby_customer_service_info','GET')
                    .done(function(result) {
                        promise.resolve(result.data.data.support_phone);
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        };
    };
});

