define('presentation/settings/phoneNumbers/viewModels/phoneNumberInboundCallingTabViewModel',[
    'businessServices/router/router',
    'common/converters/multiItemMessage',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'common/time/datetimeFormatter',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',
    'presentation/settings/phoneNumbers/facades/phoneNumbersFacade'
    ], function () {
        return function () {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const DateFormatterConstructor = require('common/time/datetimeFormatter');
            const _dateTimeFormatter = new DateFormatterConstructor();

            const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');

            const _commonState = require('common/storage/commonState');
            const _multiItemMessage = require('common/converters/multiItemMessage');
            const _router = require('businessServices/router/router');

            self.connector = null;
            self.isInboundCallingEnabled = null;
            self.editPhoneNumberInboundCallingUrl = null;

            self.conversionInProgress = false;
            self.hasCallFlow = ko.pureComputed(() => self.connector.callFlow !== undefined);
            self.callFlowNames = ko.pureComputed(() => {
                if (self.hasCallFlow) {
                    let names = self.connector.callFlow.map(c => c.routeSearchData());
                    return _multiItemMessage.generateMultiItemMessage(names, "{0}", '& ');
                }

                return [];
            });
            self.firstCallFlowType = ko.pureComputed(() => {
                if (self.hasCallFlow) {
                   if (self.connector.callFlow[0].routeType() === _commonState.types.prompt ||
                       self.connector.callFlow[0].routeType() === _commonState.types.schedule) {
                       return  self.connector.callFlow[0].routeType();
                   }

                    return 'default';
                }
            });
            self.isCallFlowExpandable = ko.pureComputed(() => {
                if (self.hasCallFlow()) {
                    return self.connector.callFlow[0].routeType() !== _commonState.types.voicemailBox;
                }

                return false;
            });

            self.getSegmentDays = (scheduleSegment) => {
                let segmentDays = [];
                if(scheduleSegment.isForMonday()) {
                    segmentDays.push("Mon");
                }
                if(scheduleSegment.isForTuesday()) {
                    segmentDays.push("Tue");
                }
                if(scheduleSegment.isForWednesday()) {
                    segmentDays.push("Wed");
                }
                if(scheduleSegment.isForThursday()) {
                    segmentDays.push("Thur");
                }
                if(scheduleSegment.isForFriday()) {
                    segmentDays.push("Fri");
                }
                if(scheduleSegment.isForSaturday()) {
                    segmentDays.push("Sat");
                }
                if(scheduleSegment.isForSunday()) {
                    segmentDays.push("Sun");
                }
                return segmentDays.join(", ");
            };

            self.getSegmentHours = (scheduleSegment) => {
                let today = new Date();
                let startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                startTime.setHours(scheduleSegment.startHour());
                startTime.setMinutes(scheduleSegment.startMinute());
                let startTimeString = _dateTimeFormatter.format(startTime, "hhh:nn tt");

                let endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                endTime.setHours(scheduleSegment.endHour());
                endTime.setMinutes(scheduleSegment.endMinute());
                let endTimeString = _dateTimeFormatter.format(endTime, "hhh:nn tt");

                return startTimeString + " to " + endTimeString;
            };

            self.navigateToEditPhoneNumber = () => {
                _router.navigate(self.editPhoneNumberInboundCallingUrl);
            };

            self.toggleCallFlowDetails = (phoneNumberPresentationObject) => {
                if (self.isCallFlowExpandable()) {
                    phoneNumberPresentationObject.showCallFlowDetails(!phoneNumberPresentationObject.showCallFlowDetails());
                }
            };

            self.activate = (connector, availableVoicePrompts, availableSchedules, availableAutoAttendants) => {

                return _initialize(connector, availableVoicePrompts, availableSchedules, availableAutoAttendants);
            };

            const _initialize = (connector, availableVoicePrompts, availableSchedules, availableAutoAttendants) => {
                self.connector = connector;
                self.editPhoneNumberInboundCallingUrl = connector.editConnectorUrl + '/inboundCalling';
                self.isInboundCallingEnabled = connector.isInboundCallingEnabled;

                if (connector.callFlow){
                    connector.callFlow.forEach(flow => {
                        let autoAttendantsInCurrentCallFlow = [];

                        if (flow.routeType() === _commonState.types.autoAttendant) {
                            let autoAttendant = new AutoAttendantCallFlowConstructor(flow.routeToItemId, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                            autoAttendantsInCurrentCallFlow.push(flow.routeToItemId);
                            autoAttendant.activate();
                            flow.autoAttendant(autoAttendant);
                        } else if (flow.routeType() === _commonState.types.schedule) {
                            flow.scheduleSegments.forEach(segment => {
                                segment.callFlow.forEach(segmentCallFlow => {
                                    if (segmentCallFlow.routeType() === _commonState.types.autoAttendant) {
                                        let isLoopedCallFlow = autoAttendantsInCurrentCallFlow.some(autoAttendantId => autoAttendantId === segmentCallFlow.routeToItemId);
                                        autoAttendantsInCurrentCallFlow.push(flow.routeToItemId);
                                        flow.showLoopedCallFlow(isLoopedCallFlow);
                                        if (!isLoopedCallFlow) {
                                            let autoAttendant = new AutoAttendantCallFlowConstructor(segmentCallFlow.routeToItemId, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                                            autoAttendant.activate();
                                            segmentCallFlow.autoAttendant(autoAttendant);
                                        }
                                    }
                                });
                            });
                        }
                    });
                }
                
                return _promiseFactory.wait();
            };
        };
});
