define('common/promises/waitHandle',['common/logging/logger',
        'constants/webSocketSendMessagePriorities'],
    function() {
        return function() {
            var self = this;

            var _isGreenLightSet = true;
            var _pendingLowPriorityCallbacks = [];
            var _pendingHighPriorityCallbacks = [];
            var _webSocketMessagePriorities = require('constants/webSocketSendMessagePriorities');


            function processPendingCallbacks() {
                if (_isGreenLightSet === true) {
                    var currentCallback = null;
                    if (_pendingHighPriorityCallbacks.length > 0) {
                        currentCallback = _pendingHighPriorityCallbacks.shift();
                    } else if (_pendingLowPriorityCallbacks.length > 0) {
                        currentCallback = _pendingLowPriorityCallbacks.shift();
                    }
                    if(currentCallback !== null) {
                        try {
                            currentCallback();
                            setTimeout(function() {
                                processPendingCallbacks();
                            }, 1);
                        } catch (ex) {
                            // Resolving this earlier results in a circular dependency.
                            var LoggerConstructor = require('common/logging/logger');
                            var logger = new LoggerConstructor();
                            logger.init();
                            logger.logException(ex);
                        }
                    }
                }
            }

            function setGreenLight() {
                _isGreenLightSet = true;
                processPendingCallbacks();
            }

            function setRedLight() {
                _isGreenLightSet = false;
            }

            function waitForSignal(callbackFunction, priority) {
                if (callbackFunction === undefined) {
                    throw new Error("Must specify a callbackFunction");
                }
                if (typeof(callbackFunction) !== 'function') {
                    throw new Error("callbackFunction must be a function");
                }

                if (_isGreenLightSet === true) {
                    callbackFunction();
                } else {
                    switch(priority) {
                        case undefined :
                        case _webSocketMessagePriorities.low :
                            _pendingLowPriorityCallbacks.push(callbackFunction);
                            break;
                        case _webSocketMessagePriorities.high :
                            _pendingHighPriorityCallbacks.push(callbackFunction);
                    }

                }
            }

            self.setGreenLight = setGreenLight;
            self.setRedLight = setRedLight;
            self.waitForSignal = waitForSignal;
        };
    }
);

