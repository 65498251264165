define('presentation/common/borderedIcon/viewModels/borderedIconViewModel',[],function() {
    return function() {
        const self = this;
        let _settings = null;

        const _setProperty = (property) => {
            if (_settings[property] !== undefined) {
                if (typeof(_settings[property]) === "function") {
                    self[property](_settings[property]());
                } else {
                    self[property](_settings[property]);
                }
            }
        };

        const _splitPadding = (spacing) => {
            const padding = spacing / 2;
            return Math.max(0, padding) + "px";
        };
        
        self.containerWidth = ko.observable(48);
        self.containerHeight = ko.observable(48);
        self.iconName = ko.observable();
        self.iconHeight = ko.observable(28);
        self.iconWidth = ko.observable(28);

        self.containerWidthBinding = ko.pureComputed(() => self.containerWidth() + "px");
        self.containerHeightBinding = ko.pureComputed(() => self.containerHeight() + "px");
        self.iconPaddingHorizontalBinding = ko.pureComputed(() => {
            return _splitPadding(self.containerWidth() - self.iconWidth());
        });
        self.iconPaddingVerticalBinding = ko.pureComputed(() => {
            return _splitPadding(self.containerHeight() - self.iconHeight());
        });


        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            _setProperty('containerWidth');
            _setProperty('containerHeight');
            _setProperty('iconName');
            _setProperty('iconHeight');
            _setProperty('iconWidth');
        };
    };

});

