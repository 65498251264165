define('presentation/messages/viewModels/messagesSidebarViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/events/eventManager',
    'businessServices/messages/messagesStateSingleton',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'constants/transitionDurations',
    'i18next',
    'presentation/messages/dataSources/webMessagingDataSource',
    'presentation/messages/facades/messagesSidebarFacade',
    'presentation/messages/dataSources/conversationReadStateDataSource',
    'constants/smsConversationReviewedStatus'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type import('businessServices/events/eventManager') */
    EventManager,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    messagesState,
    /** @type typeof import('common/collections/collectionSorter') */
    Sorter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/transitionDurations') */
    TransitionDurations,
    /** @type typeof import('i18next') */
    i18n,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    webMessagingDataSource,
    /** @type typeof import('presentation/messages/facades/messagesSidebarFacade') */
    MessagesSidebarFacade,
    /** @type import('presentation/messages/dataSources/conversationReadStateDataSource') */
    ConversationReadState,
    /** @type typeof import('constants/smsConversationReviewedStatus')*/
    SmsConversationReviewedStatus
) {
    return function () {
        /** @typedef {import('presentation/messages/presentationObjects/sidebarConversationPresentationObject')} SidebarConversationPresentationObject */

        const MAX_DRAFTS = 10;
        const MAX_CONVERSATIONS_TO_LOAD_PER_BATCH = 25;

        const self = this;
        /** @type {(typeof PromiseFactory)["prototype"]} */
        let _promiseFactory = null;
        /** @type import('presentation/messages/facades/messagesSidebarFacade') */
        let _facade = null;
        let _isCreatingDraft = false;
        let _isFetchingData = false;

        const _sorter = new Sorter();
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        const _updateAndSortConversations = (/** @type {SidebarConversationPresentationObject[]} */conversations) => {
            _sorter.multiSort(conversations, ["isDraft", "lastMessageDateTime", "conversationLastViewedDateTime"], [false, false, false]);
            self.conversations(conversations);
        };

        const _setConversationReadStates = (/** @type {SidebarConversationPresentationObject[]} */ conversations) => {
            for (const conversation of conversations) {
                ConversationReadState.setConversationReadState(conversation);
            }
        };

        const _fadeOutAndRemove = (/** @type {SidebarConversationPresentationObject} */draftOrConversation) => {
            // 1. Trigger fade out animation
            // 2. Wait for both transitions of length transitionDurations.medium to occur
            // 3. Remove faded out element
            const fadeOutAnimationLength = TransitionDurations.medium * 2;

            draftOrConversation.fadeIn(false);
            draftOrConversation.fadeOut(true);

            setTimeout(() => {
                self.conversations.remove(draftOrConversation);
            }, fadeOutAnimationLength);
        };

        const _addAndFadeIn = (/** @type {SidebarConversationPresentationObject} */draftOrConversation) => {
            // 1. Add new conversation faded out (hidden) and sort
            // 2. Trigger fade in animation (in 50ms)
            draftOrConversation.fadeOut(true);
            draftOrConversation.fadeIn(false);

            const conversations = self.conversations();
            conversations.push(draftOrConversation);
            _updateAndSortConversations(conversations);

            setTimeout(() => {
                draftOrConversation.fadeOut(false);
                draftOrConversation.fadeIn(true);
            }, 50);
        };

        const _upsertAndFade = (
            /** @type {SidebarConversationPresentationObject} */draftOrConversation,
            /** @type {boolean} */isConversation) => {
            if (draftOrConversation.smsConversationId) {
                ConversationReadState.setConversationReadState(draftOrConversation);
            }

            const conversations = self.conversations();
            const inReviewedFilterStatus = self.selectedReviewedStatus() === SmsConversationReviewedStatus.Reviewed;

            const foundConvOrDraft = conversations.find((conversation) => {
                if (isConversation) {
                    return conversation.smsConversationId === draftOrConversation.smsConversationId;
                } else {
                    return conversation.smsConversationDraftId === draftOrConversation.smsConversationDraftId;
                }
            });

            if (foundConvOrDraft) {
                const oldSortedConversations = self.conversations();
                const oldIndex = oldSortedConversations.indexOf(foundConvOrDraft);

                const newSortedConversations = oldSortedConversations.slice();
                newSortedConversations[oldIndex] = draftOrConversation;
                _sorter.multiSort(newSortedConversations, ["isDraft", "lastMessageDateTime"], [false, false]);
                const newIndex = newSortedConversations.indexOf(draftOrConversation);

                // Conversation doesn't move, just gets updated
                if (oldIndex === newIndex) {
                    foundConvOrDraft.membersCount(draftOrConversation.membersCount());
                    foundConvOrDraft.cardPhoneNumber(draftOrConversation.cardPhoneNumber());
                    foundConvOrDraft.lastMessageDateTime(draftOrConversation.lastMessageDateTime());
                    foundConvOrDraft.conversationLastViewedDateTime(draftOrConversation.conversationLastViewedDateTime());
                    foundConvOrDraft.conversationName(draftOrConversation.conversationName());
                    foundConvOrDraft.conversationDate(draftOrConversation.conversationDate());
                    foundConvOrDraft.failedSending(draftOrConversation.failedSending());
                    foundConvOrDraft.message(draftOrConversation.message());
                    foundConvOrDraft.lastMessage(draftOrConversation.lastMessage());

                    if (foundConvOrDraft.hasUnreadMessages()) {
                        foundConvOrDraft.hasUnsentMessage(false);
                    }

                    if (inReviewedFilterStatus) {
                        _fadeOutAndRemove(foundConvOrDraft);
                    }
                }
                // Conversation is moved up to reflect new message
                else {
                    _fadeOutAndRemove(foundConvOrDraft);

                    if (!inReviewedFilterStatus) {
                        _addAndFadeIn(draftOrConversation);
                    }
                }
            }
            else {
                _addAndFadeIn(draftOrConversation);
            }
        };

        const _batchUpdateConversationsWithFade = (/** @type {SidebarConversationPresentationObject[]} */ updatedConversations) => {
            const _buildConversationIdSet = (/** @type {SidebarConversationPresentationObject[]} */ conversations) => {
                const conversationIds = new Set();

                conversations.forEach((conversation) => {
                    conversationIds.add(conversation.smsConversationId);
                });

                return conversationIds;
            };

            const currentConversations = self.conversations();

            const currentConversationIds = _buildConversationIdSet(currentConversations);
            const updatedConversationIds = _buildConversationIdSet(updatedConversations);

            const conversationsToAdd = updatedConversations
                .filter((updatedConversation) => {
                    return !currentConversationIds.has(updatedConversation.smsConversationId);
                });

            const conversationsToRemove = currentConversations
                .filter((currentConversation) => {
                    return !updatedConversationIds.has(currentConversation.smsConversationId);
                });

            conversationsToRemove.forEach((conversationToRemove) => {
                _fadeOutAndRemove(conversationToRemove);
            });

            conversationsToAdd.forEach((conversationToAdd) => {
                _addAndFadeIn(conversationToAdd);
            });
        };

        const _upsertDraft = (/** @type {SidebarConversationPresentationObject} */draft) => {
            const conversations = self.conversations();

            const foundConversation = draft.smsConversationId && conversations.find((existingConversation) => {
                return existingConversation.smsConversationId === draft.smsConversationId;
            });

            if (foundConversation) {
                if (foundConversation.hasUnreadMessages()) {
                    return;
                }

                const draftMessage = draft.message();
                const hasUnsentMessage = !!draftMessage;
                const message = hasUnsentMessage ? draftMessage : foundConversation.lastMessage();
                foundConversation.message(message);
                foundConversation.hasUnsentMessage(hasUnsentMessage);
                return;
            }

            _upsertAndFade(draft, false);
        };

        const _deleteDraft = (/** @type {string} */smsConversationDraftId) => {
            webMessagingDataSource.addConversationDraftIdPendingDeletion(smsConversationDraftId);
            const draft = self.conversations().find(x => x.smsConversationDraftId === smsConversationDraftId);
            if (draft) {
                _fadeOutAndRemove(draft);
            }

            const selectedConversationDraftId = messagesState.selectedConversationDraftId();

            if (smsConversationDraftId === selectedConversationDraftId)
            {
                messagesState.selectedConversationDraftId(null);
                _router.navigate(_navigationConfiguration.routesById.messages.routeId);
            }
        };

        const _addOrUpdateConversation = (/** @type {SidebarConversationPresentationObject} */conversation) => {
            _upsertAndFade(conversation, true);
        };

        const _onConversationCreated = (/** @type {SidebarConversationPresentationObject} */conversation) => {
            _addOrUpdateConversation(conversation);
        };

        const _onConversationUserAssociationChanged = (/** @type {ISmsConversationUserAssociationChanged} */event) => {
            const conversations = self.conversations();
            const foundConversation = conversations.find((existingConversation) => {
                return existingConversation.smsConversationId === event.smsConversationId;
            });

            if (event.isArchived === true) {
                _fadeOutAndRemove(foundConversation);
                return;
            }

            if (foundConversation !== undefined) {
                ConversationReadState.setConversationReadState(event.smsConversationId, event.isMarkedAsNew, event.conversationLastViewedDateTime);
                const conversationLastViewedDateTime = event.conversationLastViewedDateTime && Date.parse(event.conversationLastViewedDateTime);
                foundConversation.conversationLastViewedDateTime(conversationLastViewedDateTime);
                foundConversation.isMarkedAsNew(event.isMarkedAsNew);
                return;
            }

            _refreshSidebarConversations();
        };

        const _onConversationNameChanged = (/** @type {ISmsConversationNameChanged} */conversation) => {
            const existingConversations = self.conversations();
            const foundConversation = existingConversations.find((existingConversation) => {
                return existingConversation.smsConversationId === conversation.smsConversationId;
            });

            if (foundConversation) {
                foundConversation.conversationName(conversation.smsConversationName);
            }
        };

        const _onAuthorizedHostedNumbersChanged = () => {
            _refreshSidebarConversations();
        };

        const _onSidebarContactsUpdated = () => {
            const existingConversations = self.conversations();
            existingConversations.map((conversation) => {
               conversation.cardPhoneNumber.valueHasMutated();
            });
        };

        const _onFilterMenuUpdated = () => {
            if (!_isFetchingData) {
                _refreshSidebarConversations();
            }
        };

        let _loadingNextBatch = false;

        const _loadNextBatchOfConversations = (/** @type {string} */scrollEventSource) => {
            if (scrollEventSource !== self.scrollEventSource) {
                return;
            }

            if (_loadingNextBatch) {
                _promiseFactory
                    .wait()
                    .then(_loadNextBatchOfConversations);

                return;
            }

            _loadingNextBatch = true;

            const offset = self.conversations()
                .filter(x => x.smsConversationId)
                .length;

            _promiseFactory.defer((promise) => {
                _facade.getUserConversations({
                    maxConversations: MAX_CONVERSATIONS_TO_LOAD_PER_BATCH,
                    offset: offset,
                    reviewedStatus: self.selectedReviewedStatus(),
                    accountHostedNumberIds: self.selectedHostedPhoneNumberIds()
                })
                    .fail(promise.reject)
                    .done((/** @type {SidebarConversationPresentationObject[]} */conversations ) => {
                        _setConversationReadStates(conversations);
                        conversations.forEach(_addAndFadeIn);
                        _loadingNextBatch = false;
                        promise.resolve();
                    });
            });
        };

        const _refreshSidebarConversations = () => {
            if (!sessionAccountInfo.isLoggedIn() || _isFetchingData) {
                return _promiseFactory.wait();
            }

            _isFetchingData = true;

            return _promiseFactory.defer((promise) => {
                _facade.getUserConversations({
                    maxConversations: MAX_CONVERSATIONS_TO_LOAD_PER_BATCH,
                    reviewedStatus: self.selectedReviewedStatus(),
                    accountHostedNumberIds: self.selectedHostedPhoneNumberIds()
                })
                    .fail(promise.reject)
                    .done((/** @type {SidebarConversationPresentationObject[]} */conversations) => {
                        _isFetchingData = false;

                        _setConversationReadStates(conversations);
                        _batchUpdateConversationsWithFade(conversations);
                        promise.resolve();
                    });
            });
        };

        const _getDraftCount = () => {
            const conversations = self.conversations();

            let count = 0;
            for (const conversation of conversations) {
                if (conversation.isDraft) {
                    count++;
                }
            }

            return count;
        };

        self.placeholderCards = new Array(17);
        self.scrollPosition = ko.observable(0);
        self.scrollEventSource = 'messagesSidebar';
        self.sidebarContacts = _contactsState.sidebarContacts;

        self.showWebMessaging = ko.pureComputed(() => {
            const isImpersonating = sessionAccountInfo.isImpersonating();
            return !isImpersonating;
        });
        self.showPlaceholderConversations = ko.pureComputed(() => {
            return sessionAccountInfo.isImpersonating();
        });

        self.authorizedHostedNumberIds = webMessagingDataSource.authorizedHostedNumberIds;
        self.searchText = ko.observable("");
        self.draftLimitHit = i18n.t("messagesSidebar:draftLimitReached");
        self.filterShowOptions = Object.keys(SmsConversationReviewedStatus).map((statusKey) => {
            return { textI18n: statusKey, value: SmsConversationReviewedStatus[statusKey] };
        });
        self.phoneNumberOptions = webMessagingDataSource.webMessagingNumbers;
        self.selectedHostedPhoneNumberIds = messagesState.selectedAccountHostedNumberIds;
        self.selectedReviewedStatus = messagesState.selectedConversationsReviewedStatus;
        self.revealSearchField = ko.observable(false);
        self.showSearchLayout = ko.observable(false);
        self.showDefaultLayout = ko.observable();

        // TODO: Delete when DEV-493 is ready to be included in release
        self.comingSoonTooltip = i18n.t('comingSoon');

        self.showEmptyState = ko.pureComputed(() => self.conversations().length === 0);
        self.hasPermissions = ko.pureComputed(() => self.authorizedHostedNumberIds().length > 0);
        self.isSearchFilterEnabled = ko.pureComputed(() => {
            return self.hasPermissions() && self.showWebMessaging();
        });
        self.canComposeNewMessage = ko.pureComputed(() => {
            return !self.draftLimitReached() && self.hasPermissions() && self.showWebMessaging();
        });
        self.draftLimitReached = ko.pureComputed(() => {
            return _getDraftCount() >= MAX_DRAFTS && self.showWebMessaging();
        });

        /** @type {KnockoutObservableArray<SidebarConversationPresentationObject>} */
        self.conversations = ko.observableArray();

        const _updateSearchFieldVisibilityAfterAnimations = (/** @type {boolean}*/newValue) => {
            const allAnimationSeconds = 1.5 * 1000;
            setTimeout(() => self.revealSearchField(newValue), allAnimationSeconds);
        };

        self.openSearch = () => {
            // TODO: Uncomment when DEV-493 is ready to be included in release
            // self.showDefaultLayout(false);
            // self.showSearchLayout(true);
            // _updateSearchFieldVisibilityAfterAnimations(true);
        };

        self.closeSearch = () => {
            self.showSearchLayout(false);
            self.showDefaultLayout(true);
            _updateSearchFieldVisibilityAfterAnimations(false);
        };

        self.openNewMessage = () => {
            if (_isCreatingDraft) {
                return;
            }

            _isCreatingDraft = true;

            _facade.createDraftConversation()
                .fail(() => {
                    _isCreatingDraft = false;
                })
                .done((/** @type string */conversationDraftId) => {
                    const newMessageUrl = `${_navigationConfiguration.routesById.newMessage.baseUrl}/${conversationDraftId}`;
                    _router.navigate(newMessageUrl);
                    messagesState.selectedConversationDraftId(conversationDraftId);
                    messagesState.selectedConversationId(null);
                    _isCreatingDraft = false;
                });
        };

        self.navigateToConversation = ((/** @type {SidebarConversationPresentationObject} */{smsConversationId, smsConversationDraftId}) => {
            if (smsConversationDraftId) {
                messagesState.selectedConversationId(null);
                messagesState.selectedConversationDraftId(smsConversationDraftId);
                const newMessageUrl = `${_navigationConfiguration.routesById.newMessage.baseUrl}/${smsConversationDraftId}`;
                _router.navigate(newMessageUrl);
            } else {
                messagesState.selectedConversationId(smsConversationId);
                messagesState.selectedConversationDraftId(null);
                const url = `${_navigationConfiguration.routesById.conversation.baseUrl}/${smsConversationId}`;
                _router.navigate(url);
            }
        });

        self.deleteDraft = (/** @type {SidebarConversationPresentationObject} */conversation) => {
            const { smsConversationDraftId } = conversation;
            if (!smsConversationDraftId) {
                return null;
            }

            return () => {
                _facade.deleteDraftConversation(smsConversationDraftId)
                    .then(() => {
                        _deleteDraft(smsConversationDraftId);
                    });
            };
        };

        self.isSelected = ((/** @type {SidebarConversationPresentationObject} */{smsConversationId, smsConversationDraftId}) => {
            if (smsConversationId) {
                return messagesState.selectedConversationId() === smsConversationId;
            }

            return messagesState.selectedConversationDraftId() === smsConversationDraftId;
        });

        self.showIcon = (/** @type {SidebarConversationPresentationObject} */{hasUnsentMessage, failedSending}) => {
            return hasUnsentMessage() || failedSending();
        };

        self.showErrorIcon = (/** @type {SidebarConversationPresentationObject} */ conv) => {
            if (conv.failedSending() && !conv.hasUnsentMessage()) {
                return true;
            }

            return false;
        };

        self.showEditIcon = (/** @type {SidebarConversationPresentationObject} */{hasUnsentMessage}) => {
            return hasUnsentMessage();
        };

        const _initializeWebMessagingDataSource = () => {
            return _promiseFactory.defer((promise) => {
                webMessagingDataSource.waitForData()
                    .done(promise.resolve);
            });
        };

        self.activate = () => {
            _promiseFactory = new PromiseFactory();

            _facade = new MessagesSidebarFacade();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            if (sessionAccountInfo.isImpersonating() || _isFetchingData) {
                return _promiseFactory.wait();
            }

            _isFetchingData = true;

            const initialHostedNumberIds = webMessagingDataSource.webMessagingNumbers()
                .map((n) => n.accountHostedNumberId)
                .filter((value, index, self) => self.indexOf(value) === index);

            self.selectedReviewedStatus(SmsConversationReviewedStatus.All);
            self.selectedHostedPhoneNumberIds(initialHostedNumberIds);

            _facade.getUserConversations({
                maxConversations: MAX_CONVERSATIONS_TO_LOAD_PER_BATCH,
                reviewedStatus: self.selectedReviewedStatus(),
                accountHostedNumberIds: []
            })
                .done((/** @type {SidebarConversationPresentationObject[]} */conversations) => {
                    _isFetchingData = false;

                    _updateAndSortConversations(conversations);
                    _setConversationReadStates(conversations);

                    _facade.onConversationCreated(_onConversationCreated);
                    _facade.onConversationNameChanged(_onConversationNameChanged);
                    _facade.onSmsConversationUserAssociationChanged(_onConversationUserAssociationChanged);
                    _facade.onSmsConversationDraftCreated(_upsertDraft);
                    _facade.onSmsConversationDraftChanged(_upsertDraft);
                    _facade.onSmsConversationDraftDeleted(_deleteDraft);
                    _facade.onSmsConversationMessage(_addOrUpdateConversation);

                    EventManager.subscribeBottomScrollBufferReached(_loadNextBatchOfConversations);
                });

            _promiseFactory.defer((promise) => {
                _initializeWebMessagingDataSource()
                    .done(promise.resolve);
            });

            self.authorizedHostedNumberIds.subscribe(_onAuthorizedHostedNumbersChanged);
            self.selectedHostedPhoneNumberIds.subscribe(_onFilterMenuUpdated);
            self.selectedReviewedStatus.subscribe(_onFilterMenuUpdated);
            self.sidebarContacts.subscribe(_onSidebarContactsUpdated);

            return _promiseFactory.wait();
        };
    };
});

