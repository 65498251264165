/*global describe:true*/
define('presentation/common/croppableImage/viewModels/uploadImageModalViewModel',['common/promises/promiseFactory',
        'cropper',
        'presentation/common/croppableImage/presentationObjects/avatar',
        'presentation/common/croppableImage/validators/uploadImageModalValidator',
        'presentation/common/modal',
], function() {
        return function (onSaveCroppedImage) {
            const  self = this;

            const AvatarPresentationObjectConstructor = require('presentation/common/croppableImage/presentationObjects/avatar');
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const _i18n = require('i18next');

            const ENCODING_TYPE = "image/jpeg";
            const MAX_AVATAR_SIZE = 128;
            const MIN_PROGRESS_TIME = 1000;

            let _fileReader = null;
            let _fileReaderResult = null;
            let _modalService = null;
            let _onSaveCroppedImage = onSaveCroppedImage;
            let _validator = null;

            const _onAnimationCompleted = () => {
                self.isLoading(false);
                $('#image').attr('src', _fileReaderResult);
                $('#image').cropper({
                    viewMode: 1,
                    aspectRatio: 1,
                    zoomable: false,
                    guides: false,
                    preview: ".upload-image-modal__preview-image"
                });
                self.isPreviewActive(true);
                self.isSaveDisabled(false);
            };

            const _onFileReaderLoad = (e) => {
                _fileReaderResult = e.target.result;
            };

            const _onFileReaderLoadStart = () => {
                self.isLoading(true);
                $('#image').attr('src', null);
                $('#image').cropper("destroy");
                $('#percent').width('0');
                $('#percent').animate({
                    width: '100%',
                }, MIN_PROGRESS_TIME, () => _onAnimationCompleted());
            };

            const _onImageChanged = () => {
                _validator.validate()
                    .done((isValid) => {
                        if (isValid === true) {
                            _fileReader.readAsDataURL(self.imageFile());
                        }
                    })
                    .fail((error) => {
                        throw error;
                    });
            };

            const _downSampleCanvasByHalf = (canvas) => {
                let canvasCopy = document.createElement("canvas");
                canvasCopy.width = canvas.width / 2;
                canvasCopy.height = canvas.height / 2;
                let copyContext = canvasCopy.getContext("2d");
                copyContext.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, canvasCopy.width, canvasCopy.height);
                return canvasCopy;
            };

            self.modelStateObserver = null;
            self.buttonText = _i18n.t('uploadImageModal:save');
            self.imageFile = ko.observable('');
            self.imageFile.subscribe(_onImageChanged);
            self.isCompositionComplete = ko.observable(false);
            self.isLoading = ko.observable(false);
            self.isPreviewActive = ko.observable(false);
            self.isSaveDisabled = ko.observable(true);

            self.saveCroppedImage = () => {
                return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                    let croppedCanvas = $('#image').cropper('getCroppedCanvas', {
                        fillColor: "#ffffff",
                        width: (MAX_AVATAR_SIZE * 4),
                        height: (MAX_AVATAR_SIZE * 4)
                    });
                    let canvasCopyHalfSize = _downSampleCanvasByHalf(croppedCanvas);
                    let canvasCopyQuarterSize = _downSampleCanvasByHalf(canvasCopyHalfSize);
                    let imgData = canvasCopyQuarterSize.toDataURL('image/jpeg');

                    let avatar = new AvatarPresentationObjectConstructor();
                    avatar.avatarUrl(imgData);
                    avatar.encodingType = ENCODING_TYPE;
                    avatar.isSaved = false;

                    if (_onSaveCroppedImage === null) {
                        self.closeModal();
                        deferredObject.resolve();
                    } else {
                        _onSaveCroppedImage(avatar)
                            .fail(deferredObject.reject)
                            .done(() => {
                                self.closeModal();
                                deferredObject.resolve();
                            });
                    }
                });
            };

            self.closeModal = () => {
                self.isCompositionComplete(false);
                _modalService.closeModal(self);
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = () => {
                const Modal = require('presentation/common/modal');
                _modalService = new Modal();

                const ValidatorConstructor = require('presentation/common/croppableImage/validators/uploadImageModalValidator');
                _validator = new ValidatorConstructor();

                return _initialize();
            };

            const _initialize = () => {
                _fileReader = new FileReader();
                _fileReader.onload = _onFileReaderLoad;
                _fileReader.onloadstart = _onFileReaderLoadStart;
                _validator.registerViewModel(self);

                return _promiseFactory.wait();
            };
        };
    }
);

