define('common/storage/workSessionDataStorage',['common/storage/workSessionIdStorage', 'common/storage/machineStorage'], function(workSessionIdStorage, machineStorage) {
    var EXPIRATION_TIME_IN_HOURS = 4;
    var TOUCH_TIME_IN_HOURS = 1;

    function getStorageId() {
        var workSessionId = workSessionIdStorage.getWorkSessionId();
        return "tresta-ws-" + workSessionId;
    }

    function hasObject(key) {
        var storageId = getStorageId();
        var root = machineStorage.getExpiringObject(storageId);
        if (root === undefined) {
            return false;
        } else if (root[key]) {
            return true;
        } else {
            return false;
        }
    }

    function getObject(key) {
        var storageId = getStorageId();
        var root = machineStorage.getExpiringObject(storageId);
        if (root === undefined) {
            return {};
        } else if (root[key]) {
            return root[key];
        } else {
            return {};
        }
    }

    function saveObject(key, value) {
        var storageId = getStorageId();
        var root = machineStorage.getExpiringObject(storageId);
        if (root === undefined) {
            root = {};
        }
        root[key] = value;
        machineStorage.saveExpiringObject(storageId, root, EXPIRATION_TIME_IN_HOURS, TOUCH_TIME_IN_HOURS);
    }

    function deleteObject(key) {
        var storageId = getStorageId();
        var root = machineStorage.getExpiringObject(storageId);
        if (root === undefined) {
            root = {};
        }
        root[key] = undefined;
        machineStorage.saveExpiringObject(storageId, root, EXPIRATION_TIME_IN_HOURS, TOUCH_TIME_IN_HOURS);
    }

    return {
        hasObject : hasObject,
        getObject : getObject,
        saveObject : saveObject,
        deleteObject : deleteObject
    };
});
