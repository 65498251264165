define('presentation/settings/humanDetection/presentationObjects/humanDetectionAllowListedPhoneNumberPresentationObject',[
    'common/converters/phoneNumberFormatter'
], function () {
    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    return function () {
        /** @typedef {import('presentation/settings/humanDetection/presentationObjects/humanDetectionAllowListedPhoneNumberPresentationObject')} HumanDetectionAllowListedPhoneNumberPresentationObject } */

        /** @typedef {HumanDetectionAllowListedPhoneNumberPresentationObject}*/
        const self = this;

        self.humanDetectionAllowListedPhoneNumberId = null;
        self.phoneNumber = "";
        self.addedItemSort = 0;
        self.countryAbbreviation = "us";
        self.city = null;
        self.state = null;
        self.formattedPhoneNumber = ko.pureComputed(() => {
            return _phoneNumberFormatter.toInternationalWithParens(self.phoneNumber);
        });
        self.formattedLocation = ko.pureComputed(() => {
            return _phoneNumberFormatter.formatLocation(self.city, self.state);
        });
    };
});

