define('presentation/messages/viewModels/conversationRecipientsViewModel',[
        'businessServices/blocking/blockingStateSingleton',
        'businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'i18next',
        'presentation/messages/presentationObjects/conversationRecipientsPresentationObject'
    ],
    function (
        /** @type import('businessServices/blocking/blockingStateSingleton') */
        _blockingState,
        /** @type import('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton') */
        _smsPhoneNumberOptOutsState,
        /** @type typeof import('common/converters/phoneNumberFormatter') */
        PhoneNumberFormatter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type import('i18next') */
        i18next,
        /** @type typeof import('presentation/messages/presentationObjects/conversationRecipientsPresentationObject') */
        ConversationRecipientsPresentationObject
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationRecipientsViewModel')} ConversationRecipientsViewModel */
        /** @typedef {import('presentation/messages/presentationObjects/conversationRecipientsPresentationObject')} ConversationRecipientsPresentationObject */

        return function () {
            const MAX_RECIPIENTS = 10;

            /** @type {ConversationRecipientsViewModel} */
            const self = this;

            const _promiseFactory = new PromiseFactory();
            const _phoneNumberFormatter = new PhoneNumberFormatter();

            /** @type {IDisposable[]} */
            let _disposables = [];
            /** @type {(recipient: IRecipient) => void} */
            let _onAddRecipient = null;

            const _clearInput = () => {
                self.phoneNumberInputValue("");
                self.isListBoxVisible(false);
            };

            const _determineListBoxVisibility = (/** @type {string} */trimmedValue) => {
                if (trimmedValue) {
                    self.isListBoxVisible(true);
                    return;
                }

                for (const option of self.filteredOptions()) {
                    const isVisible = option.phoneNumber().includes(trimmedValue);
                    if (isVisible) {
                        self.isListBoxVisible(true);
                        return;
                    }
                }

                self.isListBoxVisible(false);
            };

            const _createConversationRecipientPresentationObject = (/** @type ConversationRecipientsPresentationObject */ recipient) => {
                const conversationRecipientsPresentationObject = new ConversationRecipientsPresentationObject();
                conversationRecipientsPresentationObject.id = recipient.id;
                conversationRecipientsPresentationObject.phoneNumber = recipient.phoneNumber;
                conversationRecipientsPresentationObject.fromPhoneNumber = self.sendFromPhoneNumber;
                conversationRecipientsPresentationObject.meta = recipient.meta;
                conversationRecipientsPresentationObject.isAutoCompleteOption = recipient.isAutoCompleteOption;
                return conversationRecipientsPresentationObject;
            };

            const _onPhoneNumberValueChanged = (/** @type {string} */ phoneNumberInputValue = "") => {
                phoneNumberInputValue = phoneNumberInputValue.trim();
                const inputNumbersOnly = phoneNumberInputValue.replace(/\D/g, '');
                const isValid = _phoneNumberFormatter.toNumericDefault(inputNumbersOnly) !== _phoneNumberFormatter.Unknown;
                const isDuplicate = self.selectedPhoneNumbers().some(n => n.phoneNumber === phoneNumberInputValue);

                if (isValid && !isDuplicate) {
                    _determineListBoxVisibility(phoneNumberInputValue);
                }

                const selectedPhoneNumbers = self.selectedPhoneNumbers();

                const filteredOptions = self.autoCompleteRecipients()
                    .filter(({phoneNumber}) => !selectedPhoneNumbers.find(x => x.phoneNumber === phoneNumber()))
                    .filter(({phoneNumber}) => phoneNumber().replace(/\D/g, '').includes(phoneNumberInputValue))
                    .filter((option) => {
                        if (inputNumbersOnly.length <= 9) {
                            if (option.isBlockedContact()) {
                                return false;
                            }
                            return !option.isPhoneNumberOptedOut();
                        }

                        return true;
                    });

                if (filteredOptions.length === 1) {
                    const lastOption = filteredOptions[0].phoneNumber();
                    const isBlocked = filteredOptions[0].isBlockedContact();
                    const isPhoneNumberOptedOut = filteredOptions[0].isPhoneNumberOptedOut();
                    const lastOptionNumbersOnly = lastOption.replace(/\D/g, '');

                    if (inputNumbersOnly.length > 9 && lastOptionNumbersOnly.includes(inputNumbersOnly) && !isBlocked && !isPhoneNumberOptedOut) {
                        self.showEnterToContinueMeta(true);
                    }
                }


                //if no options match the input at 5-6 or 10-12 digits typed, create new option and push it to filtered ones
                const lengthsToAutoFormat = [5, 6, 10, 11, 12];
                if (isValid && !isDuplicate && inputNumbersOnly.length > 0 && lengthsToAutoFormat.includes(inputNumbersOnly.length) && filteredOptions.length === 0) {
                    const conversationRecipientsPresentationObject = new ConversationRecipientsPresentationObject();
                    conversationRecipientsPresentationObject.id = inputNumbersOnly;
                    conversationRecipientsPresentationObject.phoneNumber(_phoneNumberFormatter.toNumericDefault(phoneNumberInputValue));
                    conversationRecipientsPresentationObject.meta = i18next.t("messages:enterToContinue");
                    conversationRecipientsPresentationObject.isAutoCompleteOption = false;
                    conversationRecipientsPresentationObject.fromPhoneNumber = self.sendFromPhoneNumber;
                    filteredOptions.push(conversationRecipientsPresentationObject);
                }

                const selectedOptionId = filteredOptions.length ? _phoneNumberFormatter.toNumericDefault(filteredOptions[0].id) : "";
                self.filteredOptions(filteredOptions);
                self.selectedOptionId(selectedOptionId);
            };

            const _onRecipientsChanged = (/** @type {IRecipient[]} */ {length}) => {
                if (!length) {
                    self.focusOnRecipients(true);
                }
            };

            const _onSendFromHostedNumberChanged = (/** @type string */ accountHostedNumberId) => {
                const foundHostedNumber = self.sendFromHostedNumbers().find(x => x.accountHostedNumberId === accountHostedNumberId);
                if (foundHostedNumber !== undefined) {
                    self.sendFromPhoneNumber(foundHostedNumber.hostedPhoneNumber);
                }
            };

            const _addSelectedNumber = () => {
                const numberToAdd = self.phoneNumberInputValue();
                const isBlockedContact = self.blockedPhoneNumbers().some((x) => x.phoneNumber === _phoneNumberFormatter.toEOneSixFour(numberToAdd));
                const isPhoneNumberOptedOut = _smsPhoneNumberOptOutsState.getOptedOutPhoneNumbers(self.sendFromPhoneNumber(), [_phoneNumberFormatter.toEOneSixFour(numberToAdd)]).length > 0;
                _onAddRecipient({
                    meta: null,
                    avatar: _phoneNumberFormatter.isShortCode(numberToAdd.replace(/\D/g, '')) ? 'phoneNumber' : 'contact',
                    phoneNumber: numberToAdd,
                    isBlockedContact: ko.pureComputed(() => {
                        return isBlockedContact;
                    }),
                    isPhoneNumberOptedOut: ko.pureComputed(() => {
                        return isPhoneNumberOptedOut;
                    }),
                    isRecipientBlockedOrUnsubscribed: ko.pureComputed(() => {
                        return isBlockedContact || isPhoneNumberOptedOut;
                    })
                });
            };

            const _isValidPhoneNumberDigitCount = (/** @type {string} */phoneNumberInputValue) => {
                const digitCountNotAllowed = [0,1,2,3,4,7,8,9];
                return !digitCountNotAllowed.includes(phoneNumberInputValue.length);
            };

            const _isValidInputEntered = (/** @type {string} */phoneNumberInputValue) => {
                // Prevent more than ten recipient numbers
                const hasReachedMaxRecipients = self.selectedPhoneNumbers().length >= MAX_RECIPIENTS;

                // Prevent when entering 0 or 3-4 or 7-9 digits & no matches found
                const missingDigitsOrNotFound = !(_isValidPhoneNumberDigitCount(phoneNumberInputValue) || self.showEnterToContinueMeta());

                // Prevent same number being entered twice
                const isDuplicate = self.selectedPhoneNumbers().some(n => n.phoneNumber === phoneNumberInputValue);

                // Prevent blocked numbers from being selected
                const isBlocked = self.blockedPhoneNumbers().some(n => n.phoneNumber === _phoneNumberFormatter.toEOneSixFour(phoneNumberInputValue));

                //Prevent opted out numbers from being selected
                const isPhoneNumberOptedOut = _smsPhoneNumberOptOutsState.getOptedOutPhoneNumbers(self.sendFromPhoneNumber(), [_phoneNumberFormatter.toEOneSixFour(phoneNumberInputValue)]).length > 0;

                return !(hasReachedMaxRecipients || missingDigitsOrNotFound || isDuplicate || isBlocked || isPhoneNumberOptedOut);
            };

            self.focusOnRecipients = ko.observable(false);
            self.selectedPhoneNumbers = ko.observableArray();
            self.selectedOptionId = ko.observable("");
            self.showEnterToContinueMeta = ko.observable(false);
            self.isListBoxVisible = ko.observable(false);
            self.autoCompleteRecipients = ko.observableArray([]);
            self.filteredOptions = ko.observableArray([]);
            self.phoneNumberInputValue = ko.observable("");
            self.onRecipientsKeyDownCallback = ko.observable();
            self.blockedPhoneNumbers = _blockingState.blockedPhoneNumbers;
            self.sendFromHostedNumberId = ko.observable("");
            self.sendFromHostedNumbers = null;
            self.sendFromPhoneNumber = ko.observable("");

            self.showListBox = () => {
                self.isListBoxVisible(true);
            };

            self.numberInputPlaceholder = ko.pureComputed(() => {
                return this.selectedPhoneNumbers().length > 0 ? "" : i18next.t("messages:recipientInputPlaceholder");
            });

            self.showMatchingPhoneNumbers = ko.pureComputed(() => {
                const inputValue = this.phoneNumberInputValue();
                const { length } = this.filteredOptions();
                return !!inputValue && length > 0;
            });

            self.hasMaxRecipientsSelected = ko.pureComputed(() => {
                return this.selectedPhoneNumbers().length === MAX_RECIPIENTS;
            });

            /** @type {self["onOptionClicked"]} */
            self.onOptionClicked = (optionId) => {
                if (!_isValidInputEntered(optionId)) {
                    return;
                }

                const formattedNumber = _phoneNumberFormatter.toNumericDefault(optionId);
                self.phoneNumberInputValue(formattedNumber);
                _addSelectedNumber();
                _clearInput();
                self.isListBoxVisible(false);
                self.showEnterToContinueMeta(false);
                /** @type {HTMLElement} */(document.querySelector('.conversation-recipients__phone-number-input'))
                    .focus();
            };

            /** @type {self["removeSelectedNumber"]} */
            self.removeSelectedNumber = (item) => {
                self.selectedPhoneNumbers.remove(item);
            };

            /** @type {self["onInputKeyUp"]} */
            self.onInputKeyUp = (_data, e) => {
                // Add Number on Enter
                if (e.key !== 'Enter') {
                    return;
                }

                const phoneNumberInputValue = self.phoneNumberInputValue().trim();

                if (!_isValidInputEntered(phoneNumberInputValue)) {
                    return;
                }

                const formattedNumber = _phoneNumberFormatter.toNumericDefault(phoneNumberInputValue);

                // Prevent an invalid phone number being submitted
                if (formattedNumber === _phoneNumberFormatter.Unknown) {
                    return;
                }

                self.phoneNumberInputValue(formattedNumber);

                _addSelectedNumber();
                _clearInput();

                self.isListBoxVisible(false);
                self.showEnterToContinueMeta(false);
                const phoneNumberInputElement = /** @type {HTMLElement} */(document.querySelector('.conversation-recipients__phone-number-input'));
                phoneNumberInputElement.focus();
            };

            /** @type {self["onInputPaste"]} */
            self.onInputPaste = (_data, e) => {
                if (self.selectedPhoneNumbers().length === MAX_RECIPIENTS) {
                    return;
                }

                const pastedNumber = e.originalEvent.clipboardData
                    .getData('text/plain')
                    .trim()
                    .match(/[0-9 \-+()]/g)
                    .join("")
                    .replace(/\s/g,'');

                let numbersOnly = pastedNumber.replace(/\D/g, '');
                numbersOnly = numbersOnly.slice(0, 11);
                self.phoneNumberInputValue(numbersOnly);
            };

            /** @type {self["onInputKeyDown"]} */
            self.onInputKeyDown = (_data, jQueryEvent) => {
                const filteredOptions = self.filteredOptions();
                const keyDown = self.onRecipientsKeyDownCallback();
                const isNewNumber = filteredOptions.length === 1 && filteredOptions[0].isAutoCompleteOption === false;
                if (keyDown && filteredOptions.length > 0 && !isNewNumber) {
                    keyDown(jQueryEvent);
                }

                const e = jQueryEvent.originalEvent;

                //filter allowed keys
                const allowedKeys = ['Enter', "-", "(", ")", "+", " ", "Backspace", 'ArrowUp', 'Up', 'ArrowDown', 'Down', 'Home', 'End'];
                const isModifierKeyPressed = e.metaKey || e.ctrlKey || e.shiftKey;
                const isCursorMoveOrDeleteAction = ["Delete", "Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(e.code);
                const isNumKeyPressed = Number.isInteger(Number(e.code.replace("Numpad", "").replace("Digit", "")));

                switch (true) {
                    case allowedKeys.includes(e.key):
                    case isCursorMoveOrDeleteAction:
                    case isModifierKeyPressed === false && isNumKeyPressed:
                    case (e.metaKey || e.ctrlKey) && ["KeyV", "KeyC", "KeyA"].includes(e.code):
                        break;
                    default:
                        e.preventDefault();
                }
                return true;
            };

            /** @type {self["onKeyPress"]} */
            self.onKeyPress = (data, e) => {
                const inputNumbersOnly = self.phoneNumberInputValue().replace(/\D/g, '');
                const isFirstNumOne = inputNumbersOnly.startsWith('1');
                const elevenDigitsOkay = isFirstNumOne && inputNumbersOnly.length < 11;
                const tenDigitsOkay = !isFirstNumOne && inputNumbersOnly.length < 10;
                return elevenDigitsOkay || tenDigitsOkay;
            };

            /** @type {self["detached"]} */
            self.detached = () => {
                for (const subscription of _disposables) {
                    subscription.dispose();
                }

                _disposables = [];
            };

            /** @type {self["onInputBlur"]} */
            self.onInputBlur = () => {
                return self.isListBoxVisible(false);
            };

            /**
             * Prevent blur event from interfering with click event for autocomplete options
             * @type {self["onOptionMouseDown"]}
             */
            self.onOptionMouseDown = (_data, event) =>{
                event.preventDefault();
            };

            self.compositionComplete = () => {
                _onRecipientsChanged(self.selectedPhoneNumbers());
            };

            /** @type {self["activate"]} */
            self.activate = (activationObject) => {
                const { onAddRecipient, autoCompleteRecipients, recipients, sendFromHostedNumberId, sendFromHostedNumbers } = activationObject;
                self.isListBoxVisible(false);
                self.selectedPhoneNumbers = recipients;
                self.autoCompleteRecipients(autoCompleteRecipients().map((recipient) => {
                    return _createConversationRecipientPresentationObject(recipient);
                }));
                self.sendFromHostedNumberId = sendFromHostedNumberId;
                self.sendFromHostedNumbers = sendFromHostedNumbers;
                _onSendFromHostedNumberChanged(self.sendFromHostedNumberId());
                _onAddRecipient = onAddRecipient;
                _onPhoneNumberValueChanged();

                _disposables.push(
                    self.phoneNumberInputValue.subscribe(_onPhoneNumberValueChanged),
                    self.selectedPhoneNumbers.subscribe(_onRecipientsChanged),
                    self.sendFromHostedNumberId.subscribe(_onSendFromHostedNumberChanged)
                );

                return _initialize();
            };

            const _initialize = () => {
                return _promiseFactory.wait();
            };
        };
    });

