define('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',['common/promises/promiseFactory',
    'common/storage/commonState',
    'presentation/common/callFlow/facades/autoAttendantCallFlowFacade',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',
    'presentation/common/callFlow/viewModels/autoAttendantSubMenuCallFlowViewModel',
    'presentation/common/callFlow/viewModels/callFlowViewModel',
    'businessServices/events/eventManager'], function() {
    return function(autoAttendantId, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');
        const SubMenuCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantSubMenuCallFlowViewModel');
        const CallFlowViewModelConstructor = require('presentation/common/callFlow/viewModels/callFlowViewModel');

        const _commonState = require('common/storage/commonState');

        let _facade = null;

        const _getAutoAttendant = (autoAttendantId) => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAutoAttendant(autoAttendantId, availableVoicePrompts, availableSchedules, availableAutoAttendants)
                    .done((autoAttendant) => {
                        let autoAttendantsInNextCallFlow = autoAttendantsInCurrentCallFlow.slice(0);
                        autoAttendantsInNextCallFlow.push(autoAttendant.accountAutoAttendantId);

                        var isLoopedCallFlow;

                        autoAttendant.subMenuItems.forEach(subMenu => {
                            subMenu.callFlow.forEach(callFlow => {
                                if (callFlow.routeType() === _commonState.types.autoAttendant) {

                                    isLoopedCallFlow = autoAttendantsInNextCallFlow.some(autoAttendantId => autoAttendantId === callFlow.routeToItemId );
                                    callFlow.showLoopedCallFlow(isLoopedCallFlow);

                                    if (!isLoopedCallFlow) {
                                        let autoAttendantViewModel = new AutoAttendantCallFlowConstructor(callFlow.routeToItemId, autoAttendantsInNextCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                                        autoAttendantViewModel.activate();
                                        callFlow.autoAttendant(autoAttendantViewModel);
                                    }
                                }
                                if (callFlow.routeType() === _commonState.types.subMenu) {
                                    let subMenuViewModel = new SubMenuCallFlowConstructor(callFlow, autoAttendantsInNextCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                                    subMenuViewModel.activate();
                                    callFlow.subMenu(subMenuViewModel);
                                }
                                if (callFlow.routeType() === _commonState.types.schedule) {
                                    callFlow.scheduleSegments.forEach(segment => {
                                        segment.callFlow.forEach(segmentCallFlow => {
                                            if (segmentCallFlow.routeType() === _commonState.types.autoAttendant) {
                                                isLoopedCallFlow = autoAttendantsInNextCallFlow.some(autoAttendantId => autoAttendantId === segmentCallFlow.routeToItemId);
                                                segmentCallFlow.showLoopedCallFlow(isLoopedCallFlow);
                                                if (!isLoopedCallFlow) {
                                                    let autoAttendant = new AutoAttendantCallFlowConstructor(segmentCallFlow.routeToItemId, autoAttendantsInNextCallFlow,  availableVoicePrompts, availableSchedules, availableAutoAttendants);
                                                    autoAttendant.activate();
                                                    segmentCallFlow.autoAttendant(autoAttendant);
                                                }
                                            }
                                        });
                                    });
                                }
                                
                            });
                        });

                        self.autoAttendant(autoAttendant);
                        self.callFlowViewModel = new CallFlowViewModelConstructor();
                        self.callFlowViewModel.activate(self);
                        deferredObject.resolve();

                    })
                    .fail((error) => {
                        deferredObject.reject(error);
                    });
            });
        };

        self.autoAttendant = ko.observable(null);
        self.isInitialAutoAttendant = autoAttendantsInCurrentCallFlow.length > 0 && autoAttendantsInCurrentCallFlow[0] === autoAttendantId;
        self.showCallFlowDetails = ko.observable(true);
        self.isAutoAttendantPromptCollapsible = ko.observable(false);
        self.toggleAutoAttendantCallFlow = ko.observable();

        self.expandAllView = () => {
            self.autoAttendant().subMenuItems.forEach((subMenuItem) => {
                subMenuItem.showSubMenuCallFlow(true);
                subMenuItem.showSubMenuCallFlowDetails(true);
                subMenuItem.showScheduleDetails(true);

                subMenuItem.callFlow.forEach((item) => {
                    if (item.scheduleSegments) {
                        item.showSubMenuCallFlow(true);
                        item.scheduleSegments.forEach((segment) => {
                            segment.showScheduleSegmentDetails(true);
                        });
                    }
                    if (item.autoAttendant()) {
                        item.showSubMenuCallFlow(true);
                        item.autoAttendant().expandAllView();
                    }
                    if (item.subMenu()) {
                        item.showCallFlowDetails(true);
                        item.subMenu().expandAllView();
                    }
                });
            });
        };

        self.toggleSubMenuCallFlow = (subMenuPresentationObject) => {
            subMenuPresentationObject.showSubMenuCallFlow(!subMenuPresentationObject.showSubMenuCallFlow());
        };

        self.toggleScheduleSegmentDetails = (subMenuPresentationObject) => {
            subMenuPresentationObject.showScheduleSegmentDetails(!subMenuPresentationObject.showScheduleSegmentDetails());
        };


        self.detached = () =>{
            _facade.dispose();
        };


        self.activate = () => {
            const FacadeConstructor = require('presentation/common/callFlow/facades/autoAttendantCallFlowFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            _getAutoAttendant(autoAttendantId);

            _facade.onNewPromptConverted((promptInfo) => {
                const {accountVoicePromptId, audioData} = promptInfo;

                if (self.autoAttendant().subMenuItems) {
                    self.autoAttendant().subMenuItems.forEach((subMenuItem) => {
                        if (subMenuItem.callFlow) {
                            subMenuItem.callFlow.forEach((callFlowSegment) => {
                                if (callFlowSegment.routeToItemId === accountVoicePromptId) {
                                    callFlowSegment.currentVoicePrompt(audioData);
                                    callFlowSegment.conversionComplete(true);
                                    callFlowSegment.conversionInProgress(false);
                                }
                            });
                        }
                    });
                }
            });

            return _promiseFactory.wait();
        };
    };
});

