define('businessServices/audio/browserSupportedAudioFormat',['constants/audioTypeEnumerations'],
    function() {
        return function() {
            var self = this;

            var _webAudioFormatsEnumeration = require('constants/audioTypeEnumerations');
            var _webAudioFormats = [_webAudioFormatsEnumeration.mp3, _webAudioFormatsEnumeration.ogg];

            self.get = function() {
                var audioFormat = null;
                var audioConstructor = new Audio();

                for (var i = 0; i <= _webAudioFormats.length; i++) {
                    var audioType = "audio/"+_webAudioFormats[i];
                    if (audioConstructor.canPlayType(audioType) !== "") {
                        audioFormat = _webAudioFormats[i];
                        break;
                    }
                }
                
                return audioFormat;
            };
        };
});
