define('presentation/settings/accountProfile/viewModels/accountProfileBusinessViewModel',['businessServices/state/modelStateObserver',
        'common/collections/enumerationKeyValueConverter',
        'common/promises/promiseFactory',
        'constants/companySizeEnumerations',
        'presentation/settings/accountProfile/presentationObjects/accountProfileBusinessPresentationObject',
        'presentation/settings/accountProfile/validators/accountProfileBusinessValidator',
    ], function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const AccountProfileBusinessPresentationObjectConstructor = require('presentation/settings/accountProfile/presentationObjects/accountProfileBusinessPresentationObject');

            const _i18n = require('i18next');
            const _companySizeEnumerations = require('constants/companySizeEnumerations');
            const _keyValueConverter = require('common/collections/enumerationKeyValueConverter');

            let _validator = null;

            self.companySizes = [];

            self.accountHolderFirstName = ko.observable().extend({observeState: true});
            self.accountHolderLastName = ko.observable().extend({observeState: true});
            self.businessName = ko.observable().extend({observeState: true});
            self.companySize = ko.observable().extend({observeState: true});

            self.isEditMode = ko.observable(false);

            self.selectPlaceHolderMessage = ko.pureComputed(() => _i18n.t("accountProfileBusiness:select"));

            self.modelStateObserver = null;

            self.validate = () => _validator.validate();

            self.getAccountProfile = () => {
                const accountProfileBusinessPresentationObject = new AccountProfileBusinessPresentationObjectConstructor();
                accountProfileBusinessPresentationObject.accountHolderFirstName = self.accountHolderFirstName();
                accountProfileBusinessPresentationObject.accountHolderLastName = self.accountHolderLastName();
                accountProfileBusinessPresentationObject.businessName  = self.businessName();
                accountProfileBusinessPresentationObject.companySize = self.companySize();
                return accountProfileBusinessPresentationObject;
            };

            self.invalidItems = () => {
                let invalidFields = [];
                if (self.businessName.isInvalid()) {
                    invalidFields.push(_i18n.t('accountProfileBusiness:labelBusinessName'));
                }
                if (self.companySize.isInvalid()) {
                    invalidFields.push(_i18n.t('accountProfileBusiness:labelCompanySize'));
                }
                return invalidFields;
            };

            self.resetValidation = () => {
                _validator.resetValidation();
            };

            self.cancelForm = () => {
                if (self.businessName.isDirty) {
                    self.businessName.forceUpdate(true);
                }

                self.modelStateObserver.restoreData();
            };

            self.activate = (activationData) => {
                const ValidatorConstructor = require('presentation/settings/accountProfile/validators/accountProfileBusinessValidator');
                _validator = new ValidatorConstructor();

                return _initialize(activationData);
            };

            const _initialize = (accountProfileData) => {
                self.companySizes = _keyValueConverter.convertToKeyValues(_companySizeEnumerations);

                if (accountProfileData) {
                    self.isEditMode(true);
                    self.accountHolderFirstName(accountProfileData.accountHolderFirstName);
                    self.accountHolderLastName(accountProfileData.accountHolderLastName);
                    self.businessName(accountProfileData.businessName);
                    self.companySize(accountProfileData.companySize);
                }

                self.modelStateObserver = new ModelStateObserverConstructor(self, true);

                _validator.registerViewModel(self);

                return _promiseFactory.wait();
            };
        };
    }
);

