define('persistence/dataProviders/reservationsDataProvider',['externalDependencies/kazooApi',
        'common/promises/promiseFactory'],
    function() {
        return function() {
            var self = this;

            var KazooConstructor = require('externalDependencies/kazooApi');
            var _kazooApi = new KazooConstructor();

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();

            self.getReservations = function() {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_account_phone_number_reservations/reserveNumber','GET')
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            };
    };
});

