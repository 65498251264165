define('presentation/common/callFlow/facades/autoAttendantSubMenuCallFlowFacade',['businessServices/converters/durationTimeFormatter',
    'externalDependencies/clientWebSocket',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
], function() {

    const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
    const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

    let _voicePromptAudioFactory = null;
    let _promiseFactory = null;

    const _getFormattedDurationProperty = (promptDurationInSeconds) => {
        let formatter = new TimeFormatterConstructor();
        let formattedTimeDuration = formatter.formatTotalSeconds(promptDurationInSeconds);
        return formattedTimeDuration;
    };

    const _getCurrentVoicePrompt = (voicePromptId) => {
        return _voicePromptAudioFactory.getVoicePrompt(voicePromptId);
    };

    const _onNewPromptConverted = (callback) => {

        promptSocket.subscribeToEvent("voicePromptMp3Converted", (eventData) => {
            const {accountVoicePromptId, durationInMilliseconds} = eventData;
            let updatedAudioData = _getCurrentVoicePrompt(accountVoicePromptId);
            let formattedTimeDuration = _getFormattedDurationProperty(durationInMilliseconds / 1000);
            updatedAudioData.duration = ko.observable(formattedTimeDuration);

            callback({
                accountVoicePromptId: accountVoicePromptId,
                audioData: updatedAudioData,
            });
        });
    };

    const _dispose = () => {
        promptSocket.disposeOfEvents();
    };


    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();

    };

    return function() {
        let self = this;

        self.init = _init;
        self.onNewPromptConverted = _onNewPromptConverted;
        self.dispose = _dispose;
    };
});

