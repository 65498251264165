define('presentation/settings/userGroup/viewModels/deactivateUserGroupModalViewModel',['common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/autoAttendantVoiceRouteEnumerations',
        'constants/systemObjectIconNameConstants',
        'constants/userGroupConstants',
        'presentation/settings/userGroup/facades/deactivateUserGroupModalFacade',
        'presentation/settings/userGroup/validators/deactivateUserGroupModalValidator'
], function() {
    return function (userGroupPresentationObject) {
        const self = this;

        const SorterConstructor = require('common/collections/collectionSorter');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');
        const _userGroupConstants = require('constants/userGroupConstants');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        let _facade = null;
        let _validator = null;

        const PLEASE_SELECT = _voiceRouteEnumerations.pleaseSelect;

        const _getAboutThisUserGroup = () => {
            return _promiseFactory.defer((deferredObject) => {
                const userGroupId = _commonState.resolveGroupIdToGuid(userGroupPresentationObject.userGroupId);
                _facade.getAboutThisUserGroup(userGroupId)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        self.callsRoutedToUserGroup = _formatIntegrityWarnings(result.callsRoutedToUserGroup);
                        self.voicemailBoxes = _formatIntegrityWarnings(result.voicemailBoxes);
                        self.callRecordingBoxes = _formatIntegrityWarnings(result.callRecordingBoxes);
                        self.messagingHostedNumbers = _formatIntegrityWarnings(result.messagingHostedNumbers);

                        self.showCallsRoutedToUserGroupWarning(self.callsRoutedToUserGroup.length > 0);
                        self.showOnlySubscriberVoicemailBoxesWarning(self.voicemailBoxes.length > 0);
                        self.showOnlySubscriberCallRecordingBoxesWarning(self.callRecordingBoxes.length > 0);
                        self.showOnlyAuthorizedMessagerWarning(self.messagingHostedNumbers.length > 0);

                        self.sendAllCallsToSubscriber(PLEASE_SELECT);

                        if (self.showCallsRoutedToUserGroupWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserGroupType = 'constraints';
                        } else if (self.showOnlySubscriberVoicemailBoxesWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserGroupType = 'constraints';
                        } else if (self.showOnlySubscriberCallRecordingBoxesWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserGroupType = 'constraints';
                        } else if (self.showOnlyAuthorizedMessagerWarning()) {
                            self.showSendsCallsToQuestion(true);
                            self.deactivateUserGroupType = 'constraints';
                        } else {
                            self.showSendsCallsToQuestion(false);
                            self.deactivateUserGroupType = 'normal';
                        }

                        deferredObject.resolve();
                    });
            });
        };

        const _formatIntegrityWarnings = (integrityWarnings, isVoicemailBoxes = false) => {
            return integrityWarnings.map((integrityWarning) => {
                switch (integrityWarning.icon) {
                    case 'phoneNumber':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.hostedNumber,
                            iconName: _systemObjectIconNames.hostedNumber,
                        });
                    case 'autoAttendant':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.autoAttendant,
                            iconName: _systemObjectIconNames.autoAttendant,
                        });
                    case 'schedule':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.schedule,
                            iconName: _systemObjectIconNames.schedule,
                        });
                    case 'user':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.voicemailBox,
                            iconName: _systemObjectIconNames.user,
                        });
                    case 'userGroup':
                        return Object.assign({}, integrityWarning, {
                            entityType: isVoicemailBoxes ? _commonState.typeDisplay.voicemailBox: _commonState.typeDisplay.userGroup,
                            iconName: _systemObjectIconNames.userGroup,
                        });
                    case 'standalone':
                        return Object.assign({}, integrityWarning, {
                            entityType: _commonState.typeDisplay.voicemailBox,
                            iconName: _systemObjectIconNames.voicemailBox,
                        });
                    default:
                        return null;
                }

            });
        };

        self.modelStateObserver = null;
        self.isCompositionComplete = ko.observable(false);
        self.userGroupPresentationObject = userGroupPresentationObject;
        self.callsRoutedToUserGroup = null;
        self.voicemailBoxes = null;
        self.callRecordingBoxes  = null;
        self.sendAllCallsToSubscriber = ko.observable();
        self.showCallsRoutedToUserGroupWarning = ko.observable(false);
        self.showOnlySubscriberVoicemailBoxesWarning = ko.observable(false);
        self.showOnlySubscriberCallRecordingBoxesWarning = ko.observable(false);
        self.showOnlyAuthorizedMessagerWarning = ko.observable(false);
        self.showSendsCallsToQuestion = ko.observable(false);
        self.canDisplayValidation = ko.observable(false);
        self.deactivateUserGroupType = null;
        self.placeholderMessage = _i18n.t('deactivateUserGroupModal:pleaseSelect');
        self.submitButtonText = _i18n.t(`deactivateUserGroupModal:deactivate`);

        self.showDeactivateUserGroupWarningBox = ko.pureComputed(() => self.deactivateUserGroupType === 'constraints');
        self.deactivateUserGroupWarningMessages = ko.observableArray([
            {
                message: _i18n.t('deactivateUserGroupModal:proceedWarning', {groupName: userGroupPresentationObject.groupName}),
                listItems: []
            },
            {
                message: _i18n.t('deactivateUserGroupModal:proceedInstruct', {groupName: userGroupPresentationObject.groupName}),
                listItems: []
            }
        ]);

        self.availableSubscribers = ko.pureComputed(() => {
            const users = _commonState.users().reduce((accumulator, user) => {
                if (user.isActive()) {
                    accumulator.push({
                        id: user.id,
                        subscriberType: user.type,
                        displayName: user.name(),
                        avatar: user.avatar()
                    });
                }
                return accumulator;
            }, []);
            const userGroups = _commonState.userGroups().reduce((accumulator, userGroup) => {
                if (userGroup.isActive() && userGroup.id !== userGroupPresentationObject.userGroupId) {
                    accumulator.push({
                        id: _commonState.resolveGroupIdToGuid(userGroup.id),
                        subscriberType: userGroup.type,
                        displayName: userGroup.name(),
                        avatar: userGroup.avatars()
                    });
                }
                return accumulator;
            }, []);
            const availableSubscribers = users.concat(userGroups);
            const sorter = new SorterConstructor();
            sorter.sort(availableSubscribers, "displayName", true);
            return availableSubscribers;
        });

        self.sendAllCallsToDefaultSubscriberSelection = ko.pureComputed(() => {
            const systemAdminsId = _commonState.resolveGroupIdToGuid(_userGroupConstants.systemAdminsGroupId);
            return self.availableSubscribers().find((subscriber) => {
                return subscriber.id === systemAdminsId;
            });
        });

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                if (self.showSendsCallsToQuestion() === false) {
                    self.sendAllCallsToSubscriber(self.sendAllCallsToDefaultSubscriberSelection());
                }
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            self.canDisplayValidation(true);
                            deferredObject.resolve({isValid: isValid});
                            return;
                        }

                        const userGroupId = _commonState.resolveGroupIdToGuid(userGroupPresentationObject.userGroupId);
                        const sendAllCallsTo = ko.unwrap(self.sendAllCallsToSubscriber());
                        if (self.deactivateUserGroupType === 'constraints') {
                            _facade.deactivateConstrainedUserGroup(userGroupId, sendAllCallsTo)
                                .fail(deferredObject.reject)
                                .done(deferredObject.resolve);
                        } else {
                            _facade.deactivateUserGroup(userGroupId, sendAllCallsTo)
                                .fail(deferredObject.reject)
                                .done(deferredObject.resolve);
                        }
                    });
            });
        };

        self.activate = () => {
            const Facade = require('presentation/settings/userGroup/facades/deactivateUserGroupModalFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/userGroup/validators/deactivateUserGroupModalValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const  _initialize = () => {
            _getAboutThisUserGroup();
            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});

