define('presentation/signup/viewModels/signupPhoneNumberPortAuthorizationViewModel',[
    'businessServices/browserSupport/browserType',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'presentation/signup/facades/signupPhoneNumberPortFacade',
    'presentation/signup/validators/signupPhoneNumberPortAuthorizationValidator'
], function() {

    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _browserType = require('businessServices/browserSupport/browserType');
        const _i18n = require('i18next');

        let _disposables = [];
        let _facade = null;
        let _validator = null;

        self.authorizedSignature = ko.observable("");
        self.isAcknowledged = ko.observable(false);
        self.isCompositionComplete = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.isValid = ko.observable(false);
        self.portDate = ko.observable("");
        self.portDateSelection = ko.observable("default");
        self.showValidation = ko.observable(false);

        self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
        
        self.customerAgreesToStatement = ko.pureComputed(() => {
            if (self.showValidation()) {
                return self.isAcknowledged();
            }
            return true;
        });

        self.numbersLabel = ko.computed(() => {
            if (self.isMobile){
                return _i18n.t('signupPhoneNumberPortAuthorization:tabTitleNumbers');
            } else {
                return _i18n.t('signupPhoneNumberPortAuthorization:tabTitlePort');
            }
        });

        self.isNavigateToNextPageActive = ko.computed(() => {
            return self.isAcknowledged() &&
                self.portDate() &&
                self.portDate().length > 0 &&
                self.authorizedSignature().length > 0;
        });

        const _onPortDateChange = () => {
            if (self.portDate().length > 0) {
                _facade.saveNumberPortDesiredPortDate(self.portDate());
            }
        };

        self.onForwardArrowClicked = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        self.showValidation(true);
                        if (!isValid) {
                            deferredObject.resolve(false);
                        } else {
                            _facade.storeNumberPortAuthorizedSignature(self.authorizedSignature());
                            _facade.storeNumberPortIsAcknowledged(self.isAcknowledged());
                            _facade.setPhoneNumberCompleted(true)
                                .fail(deferredObject.reject)
                                .done(() => deferredObject.resolve(true));
                        }
                    });
            });
        };

        self.toggleAcknowledgement = () => {
            self.isAcknowledged(!self.isAcknowledged());
        };

        self.detached = () => {
            _disposables.forEach(subscription => subscription.dispose);
            _disposables = [];
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/signup/facades/signupPhoneNumberPortFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/signup/validators/signupPhoneNumberPortAuthorizationValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.deferIndefinitely((initializeDeferredObject) => {
                _facade.loadSignupSession()
                    .done((signupSession) => {
                        // self.isAcknowledged(signupSession.isAcknowledged);
                        // self.authorizedSignature(signupSession.authorizedSignature);

                        if (signupSession.portDate) {
                            const date = new Date(signupSession.portDate);
                            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                            self.portDate(date.toISOString());
                        }
                        _disposables.push(self.portDate.subscribe(_onPortDateChange));

                        _validator.registerViewModel(self, _facade);

                        initializeDeferredObject.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
