define('presentation/common/callRoute/viewModels/callRouteViewModel',[
        'businessServices/contacts/contactsStateSingleton',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/systemObjectIconNameConstants'
], function (
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState
) {
    return function () {
        const self = this;

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _commonState = require('common/storage/commonState');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        const _i18n = require('i18next');

        let _callDetails = null;

        self.tollFreeText = _i18n.t('common:tollFree');
        self.callRoute = ko.observable();
        self.iconClass = ko.observable();

        const shouldShowExitCondition = (value, isInLastStep) => {
            if (isInLastStep) {
                return false;
            } else if (value) {
                return true;
            } else {
                return false;
            }
        };

        const shouldShowTime = (/** @type string */dateTimeStarted) => {
            return dateTimeStarted !== null;
        };

        const buildCallDetailItem = (callRouteStep) => {
            callRouteStep.showAutoAttendantNavigation = false;
            callRouteStep.showAudioPlayer = false;
            callRouteStep.showUserAvatar = false;
            callRouteStep.showUserGroupAvatar = false;
            callRouteStep.showContactAvatar = false;
            callRouteStep.isLastStep = callRouteStep.lastStep !== undefined;
            callRouteStep.showExitCondition = false;
            callRouteStep.showLocation = false;
            callRouteStep.showPhoneNumber = false;
            callRouteStep.showTime = false;
            callRouteStep.showLabel = false;

            if (callRouteStep.otherPartyType){
                callRouteStep.type = callRouteStep.otherPartyType;

                if (callRouteStep.type === _commonState.types.user) {
                    callRouteStep.userId = callRouteStep.otherPartyObjectId;
                    callRouteStep.name = _commonState.get(callRouteStep.otherPartyObjectId).name();
                }
            }

            const _getCallRouteStepName = (contact, number) => {
                if (callRouteStep.humanDetectionFailed){
                    return _i18n.t('common:blocked');
                }

                return contact ? contact.displayName : _phoneNumberFormatter.toInternationalWithParens(number);
            };

            const _getCallRouteStepIcon = (icon) => callRouteStep.humanDetectionFailed ? _systemObjectIconNames.blocked : icon;

            const _getCallRouteShowLocation = (contact, number) => {
                if (callRouteStep.humanDetectionFailed || contact){
                    return false;
                }
                return !_phoneNumberFormatter.isTollFree(number);
            };

            switch (callRouteStep.type) {
                case _commonState.types.autoAttendant:

                    return Object.assign({}, callRouteStep, {
                        name: callRouteStep.autoAttendantName,
                        iconName: _systemObjectIconNames.autoAttendant,
                        displayType: _commonState.typeDisplay.autoAttendant,
                        showAutoAttendantNavigation: callRouteStep.navigationNames !== '',
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.forwardingNumber:
                    return Object.assign({}, callRouteStep, {
                        name: callRouteStep.forwardingNumberName,
                        iconName: _systemObjectIconNames.forwardingNumber,
                        displayNumber: _phoneNumberFormatter.toInternationalWithParens(callRouteStep.phoneNumber),
                        displayType: _commonState.typeDisplay.forwardingNumber,
                        showPhoneNumber: true,
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.hostedNumber:
                    const commonStateHostedNumber = _commonState.get(callRouteStep.phoneConnectorId);
                    const isDefault = commonStateHostedNumber.hasDefaultName();

                    return Object.assign({}, callRouteStep, {
                        name: commonStateHostedNumber.name,
                        iconName: commonStateHostedNumber.iconName,
                        displayNumber: commonStateHostedNumber.phoneNumber,
                        displayType: _commonState.typeDisplay.hostedNumber,
                        showLocation: isDefault,
                        showPhoneNumber: !isDefault,
                        isTollFree: _phoneNumberFormatter.isTollFree(commonStateHostedNumber.phoneNumber),
                        location: {
                            cityState: _phoneNumberFormatter.cleanupLocation(callRouteStep.location.cityState)
                        },
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.schedule:

                    return Object.assign({}, callRouteStep, {
                        name: callRouteStep.name,
                        iconName: _systemObjectIconNames.schedule,
                        displayType: _commonState.typeDisplay.schedule,
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.user:

                    let commonStateUser = _commonState.get(callRouteStep.userId);

                    return Object.assign({}, callRouteStep, {
                        avatar: commonStateUser.avatar,
                        hasAvatar: commonStateUser.avatar().showImage(),
                        displayType: _commonState.typeDisplay.user,
                        showUserAvatar: true,
                        showExitCondition: shouldShowExitCondition(callRouteStep.exitConditionText, callRouteStep.isLastStep),
                        showLocation: false,
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.userGroup:

                    let commonStateUserGroup = _commonState.get(callRouteStep.groupId);

                    return Object.assign({}, callRouteStep, {
                        name: callRouteStep.groupName,
                        hasAvatar: commonStateUserGroup.avatars().length > 1,
                        displayType: _commonState.typeDisplay.userGroup,
                        showUserGroupAvatar: true,
                        showExitCondition: shouldShowExitCondition(callRouteStep.exitConditionText, callRouteStep.isLastStep),
                        showLocation: false,
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.voicemailBox:
                    const commonStateVoicemailBox = _commonState.get(callRouteStep.voicemailBoxId);
                    const ownerId = commonStateVoicemailBox.ownerId();

                    let isOwnerUser = false;
                    let ownerUserAvatar = '';
                    let isOwnerGroup = false;
                    let ownerUserGroupId = '';

                    if (ownerId) {
                        let owner = _commonState.get(ownerId);
                        switch (owner.type) {
                            case _commonState.types.user:
                                isOwnerUser = true;
                                ownerUserAvatar = owner.avatar;
                                break;
                            case _commonState.types.userGroup:
                                isOwnerGroup = true;
                                ownerUserGroupId = ownerId;
                                break;
                            default:
                                break;
                        }
                    }
                    
                    return Object.assign({}, callRouteStep, {
                        iconName: _systemObjectIconNames.voicemailBox,
                        displayType: _commonState.typeDisplay.voicemailBox,
                        showUserAvatar: isOwnerUser,
                        avatar: ownerUserAvatar,
                        showUserGroupAvatar: isOwnerGroup,
                        groupId: ownerUserGroupId,
                        showLocation: false,
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case _commonState.types.systemAudioClip:
                case _commonState.types.prompt:
                    return Object.assign({}, callRouteStep, {
                        iconName: _systemObjectIconNames.prompt,
                        showAudioPlayer: true,
                        showLocation: false,
                        displayType: _commonState.typeDisplay.prompt,
                        audioObject: ko.observable({
                            audioData: ko.observable(callRouteStep.audioUrl),
                        }),
                        time: callRouteStep.dateTimeStarted,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted)
                    });
                case "phoneNumber":
                    const otherPartyContact = _contactsState.getContactByPhoneNumber(callRouteStep.otherPartyPhoneNumber);
                    const otherPartyNumber = otherPartyContact ? otherPartyContact.phoneNumbers().find(x => x.rawPhoneNumber() === callRouteStep.otherPartyPhoneNumber) : null;
                    const otherPartyLabel = otherPartyNumber ? otherPartyNumber.label() : '';
                    return Object.assign({}, callRouteStep, {
                        name: _getCallRouteStepName(otherPartyContact, callRouteStep.otherPartyPhoneNumber),
                        initials: otherPartyContact ? otherPartyContact.initials : '',
                        iconName: _getCallRouteStepIcon(_systemObjectIconNames.user),
                        displayType: "Phone Number",
                        humanDetectionFailed: callRouteStep.humanDetectionFailed,
                        showContactAvatar: !!otherPartyContact,
                        avatarUrl: otherPartyContact ? otherPartyContact.avatarUrl : null,
                        showLocation: _getCallRouteShowLocation(otherPartyContact, callRouteStep.otherPartyPhoneNumber),
                        location: {
                            cityState: callRouteStep.humanDetectionFailed || otherPartyContact ? '' : _phoneNumberFormatter.cleanupLocationSansTollFree(callRouteStep.otherPartyLocation)
                        },
                        time: callRouteStep.callDateTime,
                        showTime: shouldShowTime(callRouteStep.dateTimeStarted),
                        showLabel: !!otherPartyLabel,
                        label: otherPartyLabel
                    });
                case "contact":
                    const contact = _contactsState.getContactByPhoneNumber(callRouteStep.phoneNumber);
                    const matchingNumber = contact ? contact.phoneNumbers().find(x => x.rawPhoneNumber() === callRouteStep.phoneNumber) : null;
                    const contactLabel = matchingNumber ? matchingNumber.label() : '';
                    return Object.assign({}, callRouteStep, {
                        name: _getCallRouteStepName(contact, callRouteStep.phoneNumber),
                        initials: contact ? contact.initials : '',
                        iconName: _getCallRouteStepIcon(_systemObjectIconNames.user),
                        displayType: "Phone Number",
                        humanDetectionFailed: callRouteStep.humanDetectionFailed,
                        showContactAvatar: !!contact,
                        avatarUrl: contact ? contact.avatarUrl : null,
                        showLocation: _getCallRouteShowLocation(contact, callRouteStep.phoneNumber),
                        location: {
                            cityState: callRouteStep.humanDetectionFailed || contact ? '' : _phoneNumberFormatter.cleanupLocationSansTollFree(callRouteStep.otherPartyLocation)
                        },
                        showLabel: !!contactLabel,
                        label: contactLabel
                    });
                default:
                    return null;
            }
        };

        const buildCallRoute = (callDetails) => {

            let firstStep = buildCallDetailItem(callDetails.firstCallDetailStep);

            if (!firstStep.hasOwnProperty("location") && !firstStep.hasOwnProperty("otherPartyLocation")) {
                firstStep.location = callDetails.callerLocation;
            }

            let callRoute = callDetails.callDetailSteps.map(step => {
                return buildCallDetailItem(step);
            });

            if (firstStep.humanDetectionFailed) {
                const firstCallDetailStep = Object.assign({}, callDetails.firstCallDetailStep);
                firstCallDetailStep.humanDetectionFailed = false;
                const detailedBlockedFirstStep = buildCallDetailItem(firstCallDetailStep);
                callRoute.unshift(detailedBlockedFirstStep);
                callRoute.push(firstStep);
            } else {
                callRoute.unshift(firstStep);
            }

            self.callRoute(callRoute);
        };


        self.activate = (callDetails) => {
            _callDetails = callDetails;

            return _initialize();
        };

        const _initialize = () => {
            buildCallRoute(_callDetails);

            return _promiseFactory.wait();
        };
    };
});

