define('presentation/common/highlightSearchedText',[],function() {
    const ELLIPSES = "...";
    const DEFAULT_MAX_LENGTH = 250;

    /**
     * @param {string} segment
     * @param {string} searchValue
     * @returns {[indexOf: number, highlightLength: number]}
     */
    const numberSearch = (segment, searchValue) => {
        // Attempt basic match first
        let indexOf = segment.indexOf(searchValue);
        let highlightLength = searchValue.length;
        if (indexOf !== -1) {
            return [indexOf, highlightLength];
        }

        // Attempt Digits Only Match
        const digitsOnlySearchValue = searchValue.replace(/\D/g, '');
        const digitsOnlySegment = segment.replace(/\D/g, '');
        const digitsOnlyIndexOf = digitsOnlySegment.indexOf(digitsOnlySearchValue);
        const digitsOnlyhighlightLength = digitsOnlySearchValue.length;

        if (digitsOnlyIndexOf === -1 || !digitsOnlyhighlightLength || !digitsOnlySegment) {
            return [-1, digitsOnlyhighlightLength];
        }

        // Map digits only index to string indexes
        const digitsOnlyIndexToStringIndex = new Map([
            [digitsOnlySegment.length, segment.length]
        ]);
        for (let i = 0, digitsOnlyIndex = 0; i < segment.length; i++) {
            const c = segment[i];
            if (c >= '0' && c <= '9') {
                if (!digitsOnlyIndexToStringIndex.has(digitsOnlyIndex)) {
                    digitsOnlyIndexToStringIndex.set(digitsOnlyIndex, i);
                }

                digitsOnlyIndex++;
            }
        }

        const digitsOnlyEndIndex = digitsOnlyhighlightLength + digitsOnlyIndexOf;
        const endIndex = digitsOnlyIndexToStringIndex.get(digitsOnlyEndIndex);
        indexOf = digitsOnlyIndexToStringIndex.get(digitsOnlyIndexOf);
        highlightLength = endIndex - indexOf;
        return [indexOf, highlightLength];
    };

    /**
     * 
     * @param {string} segment 
     * @param {string} searchValue 
     * @returns {[indexOf: number, highlightLength: number]}
     */
    const caseSensitiveSearch = (segment, searchValue) => {
        const indexOf = segment.indexOf(searchValue);
        return [indexOf, searchValue.length];
    };

    /**
     * @param {string} segment
     * @param {string} searchValue
     * @returns {[indexOf: number, highlightLength: number]}
     */
    const caseInsensitiveSearch = (segment, searchValue) => {
        const indexOf = segment.toLowerCase().indexOf(searchValue.toLowerCase());
        return [indexOf, searchValue.length];
    };

    /**
     * @param {string} textToSearch
     * @param {string} searchValue
     * @param {(segment: string, searchValue: string) => [indexOf: number, highlightLength: number]} searchFunc
     * @param {boolean?} matchFirstOnly
     * @returns {{match: boolean, segment: string}[]}
     */
    const parseTextForSearchValue = (textToSearch, searchValue, searchFunc, matchFirstOnly) => {
        /**
         * @type {{ match: boolean; segment: string; }[]}
         */
        const segments = [];

        if (!searchValue || !textToSearch) {
            return segments;
        }

        for (let rightSegment = textToSearch; rightSegment;) {
            const [indexOf, highlightLength] = searchFunc(rightSegment, searchValue);

            if (indexOf === -1) {
                segments.push({ match: false, segment: rightSegment });
                break;
            }

            const beforeSegment = { match: false, segment: rightSegment.substring(0, indexOf) };
            const foundSegment = { match: true, segment: rightSegment.substring(indexOf, indexOf + highlightLength) };

            segments.push(
                beforeSegment,
                foundSegment
            );

            rightSegment = rightSegment.substring(indexOf + highlightLength);

            if (matchFirstOnly) {
                segments.push(
                    { match: false, segment: rightSegment }
                );

                break;
            }
        }

        return segments;
    };

    /**
     * @param {{match: boolean, segment: string}[]} parsedText
     * @returns {{match: boolean, segment: string}[]}
     */
    const simplifyMatches = (parsedText) => {
        return parsedText.reduce((previousSegments, currentSegment) => {
            if (!currentSegment.segment) {
                return previousSegments;
            }

            if (previousSegments.length) {
                const previousSegment = previousSegments[previousSegments.length - 1];
                if (previousSegment.match === currentSegment.match) {
                    previousSegment.segment += currentSegment.segment;
                    return previousSegments;
                }

            }

            previousSegments.push(currentSegment);
            return previousSegments;
        }, []);
    };

    /**
     * @param {{match: boolean, segment: string}[]} reducedMatches Text broken down into matching and not maching segments
     * @param {string} text
     * @param {number} maxLength
     */
    const truncateSearchedText = (reducedMatches, text, maxLength) => {
        // 1. Max length not reached
        // 2. Whole string either matches or doesn't
        if (text.length <= maxLength || reducedMatches.length === 1) {
            return reducedMatches;
        }

        // Add ellipses to the left side
        const [firstSegment, secondSegment] = reducedMatches;
        if (!firstSegment.match) {
            const searchSegment = secondSegment.segment;
            const searchSegmentLength = searchSegment.length;
            const leftBuffer = firstSegment.segment.length;
            const leftOver = Math.max(Math.min(maxLength - searchSegmentLength, text.length - searchSegmentLength), 0);
            const rightBuffer = Math.max(text.length - leftBuffer - searchSegmentLength, 0);

            let maxBufferLength = Math.floor(rightBuffer ? leftOver / 2 : leftOver);

            // Show more of the right side than left side of the text
            const shortLeftBuffer = 6;
            if (shortLeftBuffer + rightBuffer + searchSegmentLength > maxLength) {
                maxBufferLength = Math.min(maxBufferLength, shortLeftBuffer);
            }

            if (maxBufferLength + ELLIPSES.length < leftBuffer) {
                firstSegment.segment = ELLIPSES + firstSegment.segment.substring(leftBuffer - maxBufferLength);
            }
        }

        return reducedMatches;
    };

    /**
     * @param {string} text
     * @param {number} maxLength
     * @returns {string} truncated text
     */
    const truncateText = (text, maxLength) => {
        maxLength = maxLength - ELLIPSES.length;

        if (text.length > maxLength) {
            return text.substring(0, maxLength) + ELLIPSES;
        }

        return text;
    };

    /**
     * @param {ReturnType<unwrapOptions>} options
     * @returns {DocumentFragment}
     */
    const generateDocumentFragment = (options) => {
        const { numericOnly, matchFirstOnly, caseSensitive, selectedClass, text, searchValue, maxLength } = options;
        const fragment = document.createDocumentFragment();
        const maxTextLength = maxLength || DEFAULT_MAX_LENGTH;

        // No text. Leave element blank.
        if (!text) {
            return fragment;
        }

        // No search value. Return text node.
        if (!searchValue) {
            const truncatedText = truncateText(text, maxLength);
            const textNode = document.createTextNode(truncatedText);
            fragment.append(textNode);
            return fragment;
        }

        const searchFunc = numericOnly ? numberSearch :
            (caseSensitive ? caseSensitiveSearch : caseInsensitiveSearch);
        const trimmedSearchValue = searchValue.trim();
        const allMatches = parseTextForSearchValue(text, trimmedSearchValue, searchFunc, matchFirstOnly);
        const reducedMatches = simplifyMatches(allMatches);
        const reducedTruncatedMatches = truncateSearchedText(reducedMatches, text, maxTextLength);

        const classForHighlight = selectedClass || 'highlighted-searched-value';

        for (const parsedTextSegment of reducedTruncatedMatches) {
            const { segment, match } = parsedTextSegment;
            if (match) {
                const span = document.createElement('span');
                span.textContent = segment;
                span.classList.add(classForHighlight);
                fragment.append(span);
            } else {
                const textNode = document.createTextNode(segment);
                fragment.append(textNode);
            }
        }

        return fragment;
    };

    const unwrapOptions = (/** @type {() => HighlightSearchedTextOptions} */valueAccessor) => {
        const options = ko.utils.unwrapObservable(valueAccessor());
        const selectedClass = ko.utils.unwrapObservable(options.selectedClass);
        const text = ko.utils.unwrapObservable(options.text);
        const searchValue = ko.utils.unwrapObservable(options.searchValue);
        const caseSensitive = ko.utils.unwrapObservable(options.caseSensitive);
        const matchFirstOnly = ko.utils.unwrapObservable(options.matchFirstOnly);
        const numericOnly = ko.utils.unwrapObservable(options.numericOnly);
        const maxLength = ko.utils.unwrapObservable(options.maxLength);

        return { numericOnly, matchFirstOnly, caseSensitive, selectedClass, text, searchValue, maxLength };
    };

    return {
        /** @type {KnockoutBindingHandler<HTMLElement, HighlightSearchedTextOptions>["init"]} */
        init: function (element, valueAccessor) {
            const options = unwrapOptions(valueAccessor);
            const fragment = generateDocumentFragment(options);
            element.replaceChildren(fragment);
        },

        /** @type {KnockoutBindingHandler<HTMLElement, HighlightSearchedTextOptions>["update"]} */
        update: function (element, valueAccessor) {
            const options = unwrapOptions(valueAccessor);
            const fragment = generateDocumentFragment(options);
            element.replaceChildren(fragment);
        }
    };
});
