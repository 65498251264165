define('presentation/settings/closeAccount/viewModels/accountClosedViewModel',[
    'common/promises/promiseFactory',
    'plugins/router',
    'presentation/common/window/windowControl',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('plugins/router');
        const _windowControl = require('presentation/common/window/windowControl');

        self.isAccountClosedInFreeTrial = false;

        self.navigateToHomePage = () => {
            _windowControl.openHomePage();
        };

        self.redirectToSignIn = () => {
            _router.navigate(_navigationConfiguration.loginPageUrl);
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            const currentRouteId = _router.activeInstruction().config.customSettings.routeId;
            if (currentRouteId === _navigationConfiguration.routesById.accountClosedFreeTrial.routeId) {
                self.isAccountClosedInFreeTrial = true;
            }
            return _promiseFactory.wait();
        };
    };
});

