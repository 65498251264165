define('presentation/signup/validators/signupPaymentMethodValidator',[
        'businessServices/country/countryCode',
        'businessServices/creditCards/creditCardValidations',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
    ],
    function() {
        return function() {
            const self = this;

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            const CreditCardValidationConstructor = require('businessServices/creditCards/creditCardValidations');
            const _creditCardValidations = new CreditCardValidationConstructor();

            const CountryCodeConstructor = require('businessServices/country/countryCode');
            const _countries = new CountryCodeConstructor();

            let _commonValidator = null;
            let _viewModel;

            self.registerViewModel = function(viewModel) {
                _viewModel = viewModel;
                _commonValidator = new CommonValidatorConstructor(_viewModel);

                const validationRules = new ValidationRulesConstructor();
                validationRules.field("cardHolderName")
                    .addValidationKeyUp(_commonValidator.isStringWithValue)
                    .addValidationI18n(_commonValidator.isStringWithValue, 'signupPaymentMethod:cardHolderNameRequired')
                    .addValidationI18n(_noSpecialCharacters, 'signupPaymentMethod:noSpecialCharacters');
                validationRules.field("cardNumber")
                    .addValidationKeyUp(_creditCardValidations.validateCardNumber)
                    .addValidationI18n(_commonValidator.isStringWithValue, 'signupPaymentMethod:cardNumberRequired')
                    .addValidationI18n(_creditCardValidations.validateCardNumber, 'signupPaymentMethod:cardNumberInvalid');
                validationRules.field('cvvCode')
                    .addValidationKeyUp(_isCvvMaybeValid)
                    .addValidationI18n(_commonValidator.isStringWithValue, 'signupPaymentMethod:cvvRequired')
                    .addValidationI18n(_isCvvValid, 'signupPaymentMethod:cvvInvalid');
                validationRules.fieldGroup("expirationDate", ["expirationMonth", "expirationYear"])
                    .addValidationI18n(_validateExpirationDate, 'signupPaymentMethod:expirationDateInvalid');
                validationRules.field("postalCode")
                    .addValidationKeyUp(_validatePostalCodeValid)
                    .addValidationI18n(_commonValidator.isStringWithValue, _postalCodeRequiredI18nKey)
                    .addValidationI18n(_validatePostalCodeValid, _postalCodeInvalidI18nKey);

                validationRules.validationGroupKeyUp('isValid', ["cardHolderName", "cardNumber", "expirationDate", "cvvCode", "postalCode"]);

                _commonValidator.setValidationRules(validationRules);
            };

            self.validate = () =>_commonValidator.validate();

            const _noSpecialCharacters = (name) => /^[a-zA-Z0-9 ']*$/.test(name);

            const _isCvvValid = (cvv) => _creditCardValidations.validateCvv(cvv, _viewModel.cardNumber());

            const _isCvvMaybeValid = (cvv) => /^\d{3,4}$/.test(cvv);

            const _validateExpirationDate = (expirationMonth, expirationYear) => {
                if (expirationMonth[0] && expirationYear[0]){
                    return _creditCardValidations.validateExpirationDate(expirationMonth[0], expirationYear[0]);
                }

               return false;
            };

            const _validatePostalCodeValid = (postalCode) => _creditCardValidations.validatePostalCodeFormat(postalCode, _currentCountry());

            const _currentCountry = ko.pureComputed(() => {
                if (_viewModel.country()[0]) {
                    return _countries.findByAbbreviation(_viewModel.country()[0]);
                }

                return _countries.getDefaultCountry();
            });

            const _postalCodeRequiredI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'postalCodeRequired' : 'zipCodeRequired');

            const _postalCodeInvalidI18nKey = ko.pureComputed(() => _currentCountry().hasProvinces ? 'postalCodeInvalid' : 'zipCodeInvalid');
        };
    });

