define('businessServices/url/url',[], function() {
    return function() {
        var self = this;

        var _getProtocol = function() {
            return window.location.protocol + "//";
        };

        var _getHost = function() {
            return window.location.host;
        };

        self.getBaseURL = function() {
            return _getProtocol() + _getHost();
        };
    };
});

