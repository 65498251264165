define('presentation/common/dropDownThemed/viewModels/dropDownThemedViewModel',[],function () {
    return function () {
        var self = this;
        var _settings = null;
        /* settings has the following properties:
         * optionsList              [required]
         * optionsTextPath          [required]
         * optionsTextMaxLength
         * optionsValuePath
         * selectedValueObject      [required]
         * id                       [no longer used]
         * customId
         * isWideDropdown
         * isEnabled
         * onSelectedItemChanged
         */

        self.activate = function (settings) {
            _settings = settings;
            _initialize();
        };

        self.attached = function () {

        };

        var _initialize = function () {
            if (_settings.optionsList !== undefined) {
                if (ko.isObservable(_settings.optionsList) === true) {
                    self.optionsList = _settings.optionsList;
                } else {
                    self.optionsList(_settings.optionsList);
                }
            } else {
                throw new Error("optionsList is required");
            }

            if (_settings.optionsTextPath) {
                if (ko.isWriteableObservable(_settings.optionsTextPath) === true) {
                    self.optionsTextPath = _settings.optionsTextPath();
                } else {
                    self.optionsTextPath = _settings.optionsTextPath;
                }
            } else {
                self.optionsTextPath = null;
            }

            if (_settings.optionsValuePath) {
                if (ko.isWriteableObservable(_settings.optionsValuePath) === true) {
                    self.optionsValuePath = _settings.optionsValuePath();
                } else {
                    self.optionsValuePath = _settings.optionsValuePath;
                }
            } else {
                self.optionsValuePath = null;
            }

            if (_settings.selectedValueObject) {
                if (ko.isWriteableObservable(_settings.selectedValueObject) === true) {
                    self.selectedValueObject = _settings.selectedValueObject;
                } else {
                    self.selectedValueObject(_settings.selectedValueObject);
                }

                self.selectedValueObject.subscribe(_onSelectedValueObjectChanged);
            } else {
                throw new Error("selectedValueObject is required.");
            }

            var isWideDropDown = false;
            if (_settings.isWideDropDown) {
                if (ko.isWriteableObservable(_settings.isWideDropDown) === true) {
                    isWideDropDown = _settings.isWideDropDown();
                } else {
                    isWideDropDown = _settings.isWideDropDown;
                }
            }
            if (isWideDropDown) {
                self.selectClass('large');
            } else {
                self.selectClass('dropdown');
            }

            if (_settings.isEnabled) {
                if (ko.isWriteableObservable(_settings.isEnabled) === true) {
                    self.isEnabled = _settings.isEnabled;
                } else {
                    self.isEnabled(_settings.isEnabled);
                }
                self.isEnabled.subscribe(_onIsEnabledValueChanged);
            }

            if (_settings.customId) {
                self.customId = _settings.customId;
            }

            if (_settings.optionsTextMaxLength) {
                self.optionsTitle = {
                    property : self.optionsTextPath,
                    maxLength : _settings.optionsTextMaxLength
                };
            }
            else {
                self.optionsTitle = {
                    property: null,
                    maxLength: null
                };
            }
        };

        var _onSelectedValueObjectChanged = function() {
            setTimeout(function() {
                $.uniform.update();
            }, 1);
        };

        var _onIsEnabledValueChanged = function() {
            setTimeout(function() {
                $.uniform.update();
            }, 1);
        };

        self.notifySelectedItemChanged = function() {
            if (_settings.onSelectedItemChanged) {
                _settings.onSelectedItemChanged(self.selectedValueObject());
            }
        };

        self.optionsList = ko.observableArray();
        self.optionsTextPath = ko.observable();
        self.optionsValuePath = ko.observable();
        self.selectedValueObject = ko.observable();
        self.isEnabled = ko.observable(true);
        self.id = ko.observable();
        self.customId = "";
        self.selectClass = ko.observable();
        self.optionsTitle = ko.observable();
    };
});

