define('constants/accountContactAvatarStatus',[], function() {
    return {
        // Upload URL Generated
        ReadyToUpload: 0,
        // Uploaded By User
        Uploaded: 1,
        // File Conversion in Progress
        ConversionRequested: 2,
        // File Converted
        Converted: 3,
        // File Failed to Convert
        ConversionError: 4,
        // Avatar Upload Cancelled By User
        Cancelled: 5
    };
});
