define('presentation/messages/viewModels/messageLinkPreviewCardViewModel',[
    'constants/smsConversationMessageUrlPreviewConversionStatus',
    'common/time/datetimeFormatter'
], function (
    /** @type typeof import('constants/smsConversationMessageUrlPreviewConversionStatus')*/
    SmsConversationMessageUrlPreviewConversionStatus,
    /** @type typeof import('common/time/datetimeFormatter') */
    DatetimeFormatterConstructor
) {
    return function () {
        /** @typedef {import('presentation/messages/viewModels/messageLinkPreviewCardViewModel')} MessageLinkPreviewCardViewModel */

        /** @type {MessageLinkPreviewCardViewModel}*/
        const self = this;

        const _datetimeFormatter = new DatetimeFormatterConstructor();

        self.smsConversationMessageUrlId = ko.observable(null);
        self.smsConversationMessageId = ko.observable(null);
        self.previewS3FilePath = ko.observable(null);
        self.faviconS3FilePath = ko.observable(null);
        self.url = ko.observable(null);
        self.siteName = ko.observable(null);self.pageTitle = ko.observable(null);
        self.description = ko.observable(null);
        self.conversionStatus = ko.observable(null);
        self.messageDateTime = ko.observable(null);

        //#region private
        /** Source: Mobile's Urls.ts */
        const _removeProtocol = (/** @type {string}*/urlFragment) => {
            const regExHttp = new RegExp('http://', 'ig');
            const regExHttps = new RegExp('https://', 'ig');
            return urlFragment.replace(regExHttp, '').replace(regExHttps, '');
        };

        const _removeUrlPrefix = (/** @type {string}*/url) => {
            const lUrl = _removeProtocol(url);
            return lUrl.replace('www.', '');
        };

        const _getCanonicalName = (/** @type {string}*/url) => {
            const lUrl = _removeUrlPrefix(url);
            return lUrl.split(/[/]/)[0];
        };
        //#endregion

        self.showPreviewImage = ko.pureComputed(() => {
            return !!(self.conversionDone() && self.previewS3FilePath());
        });

        self.conversionDone = ko.pureComputed(() => {
            const currentStatus = self.conversionStatus();
            switch (currentStatus) {
                case null:
                case SmsConversationMessageUrlPreviewConversionStatus.Pending:
                case SmsConversationMessageUrlPreviewConversionStatus.ConversionError:
                    return false;
                default:
                    return true;
            }
        });

        self.website = ko.pureComputed(() => {
            const siteName = self.siteName();
            if (siteName) {
                return siteName;
            }
            const url = self.url();
            return url ? _getCanonicalName(url) : null;
        });

        self.displayUrl = ko.pureComputed(() => {
            const url = self.url();
            return url ? _removeUrlPrefix(url) : null;
        });

        self.displayMessageDate = ko.pureComputed(() => {
            const messageDateTime = self.messageDateTime();
            if (!messageDateTime) {
                return null;
            }
            return _datetimeFormatter.formatDateForLinkPreview(messageDateTime);
        });

        /** @type {MessageLinkPreviewCardViewModel["onLinkPreviewClick"]}*/
        self.onLinkPreviewClick = () => {
            const httpsRegex = new RegExp(/^https:\/\//i); // check for https:// prefixes (case insensitive)
            const url = self.url();

            if (httpsRegex.test(url)) {
                window.open(url, '_blank');
                return;
            }

            const httpRegex = new RegExp(/^http:\/\//i); // check for http:// prefixes (case insensitive)

            const formattedUrl = httpRegex.test(url) ? `https://${url.slice(7)}` : `https://${url}`;
            window.open(formattedUrl, '_blank');
        };

        /** @type {MessageLinkPreviewCardViewModel["activate"]}*/
        self.activate = (data) => {
            const {
                smsConversationMessageUrlId,
                smsConversationMessageId,
                previewS3FilePath,
                faviconS3FilePath,
                url,
                siteName,
                pageTitle,
                description,
                conversionStatus,
                messageDateTime
            } = data;

            self.smsConversationMessageUrlId(smsConversationMessageUrlId);
            self.smsConversationMessageId(smsConversationMessageId);
            self.previewS3FilePath(previewS3FilePath);
            self.faviconS3FilePath(faviconS3FilePath);
            self.url(url);
            self.siteName(siteName);
            self.pageTitle(pageTitle);
            self.description(description);
            self.conversionStatus(conversionStatus);
            self.messageDateTime(messageDateTime);

            return _initialize();
        };

        const _initialize = () => {

        };
    };
});
