define('presentation/numberPicker/viewModels/numberPickerViewModel',[
    'businessServices/browserSupport/browserType',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/numberPickerConstants',
    'constants/smsCampaignRegistrationConstants',
    'presentation/numberPicker/facades/numberPickerFacade',
    'presentation/numberPicker/presentationObjects/numberPickerPresentationObject',
    'presentation/numberPicker/validators/numberPickerValidator',
    'presentation/settings/phoneNumbers/viewModels/addPhoneNumberWarningModalViewModel'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const NumberPickerPresentationObjectConstructor = require('presentation/numberPicker/presentationObjects/numberPickerPresentationObject');
        const _browserType = require('businessServices/browserSupport/browserType');

        const _urlFormatter = require('common/url/urlFormatter');
        const _i18n = require('i18next');

        const NUMBERS_PER_PAGE = 4;
        const REFRESH_NUMBER_ARTIFICIAL_DELAY = 300;
        const SELECT_THEME = 'select-theme-light';
        const ENTER_KEY = 13;

        const _numberPickerConstants = require('constants/numberPickerConstants');
        const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');

        let _facade = null;
        let _validator = null;
        let _isSingleSelect = null;
        let _isSignup = null;
        let _initialSelectedPhoneNumbers = null;
        let _backClick = () => {
        };

        let _warningModal = null;

        let _disposables = [];

        let _isActivated = false;
        let _isVanitySearchNeeded = false;
        let _isAvailableOptionClick = false;
        let _geoLocation = null;

        const _numberType = ko.observable(0);
        const _showSelectedResults = ko.observable(false);

        const _buildPrefixes = (type) => _numberPickerConstants.tollFreePrefixes.map(prefix => ({
            id: prefix,
            text: prefix,
            get isSelected() {
                return this.id === _numberPickers[type].prefix();
            },
            get setPrefix() {
                return () => _numberPickers[type].prefix(this.id);
            }
        }));

        const _numberPickers = {
            placeholder: _i18n.t('numberPicker:numberPickerLoading'),
            noResults: _i18n.t('numberPicker:noResultsFound'),
            default: {
                country: ko.observable([]),
                region: ko.observable([]),
                city: ko.observable([]),
                prefix: ko.observable(_numberPickerConstants.tollFreePrefixDefault),
                prefixes: _buildPrefixes(_numberPickerConstants.searchTypeConstants.default),
                isInitialized: ko.observable(false),
                availableNumbers: ko.observableArray([]),
                currentPage: ko.observable(1),
                hasBeenSeen: ko.observable(false),
                errorMessage: ko.observable(''),
            },
            repeating: {
                country: ko.observable([]),
                region: ko.observable([]),
                npa: ko.observable([]),
                prefix: ko.observable(_numberPickerConstants.tollFreePrefixDefault),
                prefixes: _buildPrefixes(_numberPickerConstants.searchTypeConstants.repeating),
                isInitialized: ko.observable(false),
                availableNumbers: ko.observableArray([]),
                currentPage: ko.observable(1),
                hasBeenSeen: ko.observable(false),
                errorMessage: ko.observable(''),
            },
            vanity: {
                country: ko.observable([]),
                region: ko.observable([]),
                npa: ko.observable([]),
                prefix: ko.observable([_numberPickerConstants.tollFreePrefixDefault]),
                prefixes: _buildPrefixes(_numberPickerConstants.searchTypeConstants.vanity),
                isInitialized: ko.observable(false),
                availableNumbers: ko.observableArray([]),
                currentPage: ko.observable(1),
                hasBeenSeen: ko.observable(false),
                errorMessage: ko.observable(''),
            },
        };

        self.searchType = ko.observable(_numberPickerConstants.searchTypeConstants.default);

        const _availableOptions = ko.observableArray([]);
        const _numberPicker = ko.pureComputed(() => _numberPickers[self.searchType()]);
        const _availableNumbers = ko.computed({
            read: () => _numberPicker().availableNumbers(),
            write: (value) => _numberPicker().availableNumbers(value)
        });
        const _currentPage = ko.computed({
            read: () => _numberPicker().currentPage(),
            write: (value) => _numberPicker().currentPage(value)
        });
        const _getSelect2Value = (observable) => observable && observable() && observable().length ?
            observable()[0] :
            '';

        const _determineSearchRequestType = ({searchType, isUserChange, isInitialSearch, isSearchTypeChange}) => {
            isUserChange = isUserChange === true ||
                self.didCityOrNpaChange() === true && self.didCountryOrRegionChange() === false;
            self.didCityOrNpaChange(false);
            self.didCountryOrRegionChange(false);

            if (isInitialSearch) {
                return _numberPickerConstants.requestTypes.initial;
            }

            if (isSearchTypeChange) {
                return _numberPickerConstants.requestTypes.searchTypeTabSelected;
            }

            if (isUserChange === true || searchType === _numberPickerConstants.searchTypeConstants.vanity) {
                return _numberPickerConstants.requestTypes.user;
            }

            return _numberPickerConstants.requestTypes.default;
        };

        const _findPhoneNumbers = ({searchType, shouldSearch = true, isUserChange = false, isInitialSearch = false, isSearchTypeChange = false}) => {
            if (_isAvailableOptionClick === true) {
                _isAvailableOptionClick = false;
                return;
            }

            if (searchType === self.searchType() && shouldSearch === true) {
                _numberPicker().availableNumbers.removeAll();
                _numberPicker().hasBeenSeen(true);
                self.pickerErrorMessage('');

                self.showNumbersLoadingSpinner(true);
                _availableOptions.removeAll();

                const region = _getSelect2Value(_numberPicker().region);
                const npa = _getSelect2Value(_numberPicker().npa);
                const prefix = _numberPicker().prefix() ? _numberPicker().prefix() : '';

                const requestType = _determineSearchRequestType({searchType, isUserChange, isInitialSearch, isSearchTypeChange});

                switch (searchType) {
                    case _numberPickerConstants.searchTypeConstants.default:
                        _findDefaultNumbers({region, prefix, requestType});
                        break;
                    case _numberPickerConstants.searchTypeConstants.repeating:
                        _findRepeatingNumbers({region, npa, prefix, requestType});
                        break;
                    case _numberPickerConstants.searchTypeConstants.vanity:
                        _findVanityNumbers({region, npa, prefix, requestType});
                        break;
                    default:
                        throw new Error(`unknown search type ${searchType}`);
                }
            }
        };

        const _findDefaultNumbers = ({region, prefix, requestType}) => {
            const npaCity = _getSelect2Value(_numberPicker().city);

            _facade.findDefaultNumbers({region, npaCity, prefix, requestType, numberType: _numberType()})
                .fail()
                .done(result => {
                    const setPickerErrorMessage = key => {
                        const city = npaCity.substring(3);
                        const npa = npaCity.substring(0, 3);
                        return self.pickerErrorMessage(key, {city, npa, prefix});
                    };

                    self.showNumbersLoadingSpinner(false);

                    switch (result.status) {
                        case _numberPickerConstants.tollFreeStatuses.success:
                        case _numberPickerConstants.localStatuses.regionCityNpa:
                            _setAvailableNumbers(result.availableNumbers);
                            break;
                        case _numberPickerConstants.tollFreeStatuses.alternatePrefix:
                            setPickerErrorMessage('alternatePrefix');
                            _setAvailableNumbers(result.availableNumbers);
                            break;
                        case _numberPickerConstants.localStatuses.regionCity:
                            setPickerErrorMessage('noRegionCityResults');
                            _availableOptions(result.availableOptions);
                            break;
                        case _numberPickerConstants.localStatuses.npa:
                            setPickerErrorMessage('noNpaResults');
                            _availableOptions(result.availableOptions);
                            break;
                        default:
                            setPickerErrorMessage('noResults');
                    }
                });
        };

        const _findRepeatingNumbers = ({region, npa, prefix, requestType}) => {
            _facade.findRepeatingNumbers({region, npa, prefix, requestType, numberType: _numberType()})
                .fail()
                .done(result => {
                    self.showNumbersLoadingSpinner(false);
                    switch (result.status) {
                        case _numberPickerConstants.tollFreeStatuses.success:
                        case _numberPickerConstants.localStatuses.regionCityNpa:
                            _setAvailableNumbers(result.availableNumbers);
                            break;
                        case _numberPickerConstants.tollFreeStatuses.alternatePrefix:
                            self.pickerErrorMessage('alternatePrefix', {prefix});
                            _setAvailableNumbers(result.availableNumbers);
                            break;
                        case _numberPickerConstants.localStatuses.none:
                        case _numberPickerConstants.tollFreeStatuses.none:
                            self.pickerErrorMessage('noRepeatingNumberResults', {npa, prefix});
                            break;
                        default:
                            throw new Error(`unknown status: ${result.status}`);
                    }
                });
        };

        const _findVanityNumbers = ({region, npa, prefix, requestType}) => {
            const vanityType = self.vanityType()[0];
            const vanityString = self.vanityString().toUpperCase();

            _facade.findVanityNumbers({region, npa, prefix, vanityString, vanityType, requestType, numberType: _numberType()})
                .fail()
                .done(result => {
                    self.showNumbersLoadingSpinner(false);
                    switch (result.status) {
                        case _numberPickerConstants.tollFreeStatuses.success:
                        case _numberPickerConstants.localStatuses.regionCityNpa:
                            _setAvailableNumbers(result.availableNumbers);
                            break;
                        case _numberPickerConstants.tollFreeStatuses.alternatePrefix:
                            self.pickerErrorMessage('alternatePrefix', {prefix});
                            _setAvailableNumbers(result.availableNumbers);
                            break;
                        case _numberPickerConstants.localStatuses.none:
                        case _numberPickerConstants.tollFreeStatuses.none:
                            const i18nKey = _numberType() === _numberPickerConstants.numberTypes.local ? 'noVanityResults' : 'noTollFreeVanityResults';
                            self.pickerErrorMessage(i18nKey, {vanityString, vanityType: `$t(numberPickerConstants:error_${vanityType})`});
                            break;
                        default:
                            throw new Error(`unknown status: ${result.status}`);
                    }
                });
        };

        const _setAvailableNumbers = (availableNumbers) => {
            if (_initialSelectedPhoneNumbers.length) {
                availableNumbers = [..._initialSelectedPhoneNumbers, ...availableNumbers];
                _initialSelectedPhoneNumbers = [];
            }
            _availableNumbers(availableNumbers);
            _currentPage(1);

            if (self.selectedPhoneNumbers().length > 0) {
                let selectedPhoneNumbers = {};
                self.selectedPhoneNumbers().forEach(selectedPhoneNumber => selectedPhoneNumbers[selectedPhoneNumber.rawPhoneNumber()] = true);

                _availableNumbers().forEach(availableNumber => {
                    if (selectedPhoneNumbers[availableNumber.rawPhoneNumber()]) {
                        availableNumber.isSelected(true);
                    }
                });
            }
        };

        const _clearOtherSelectedPhoneNumber = () => {
            const phoneNumberPresentationObject = self.selectedPhoneNumbers().find(p => p.isSelected() === true);
            if (phoneNumberPresentationObject) {
                _removeSelectedPhoneNumber(phoneNumberPresentationObject);
            }
        };

        const _removeSelectedPhoneNumber = (phoneNumberPresentationObject) => _promiseFactory.deferIndefinitely(promise => {
            phoneNumberPresentationObject.isChecked(false);
            phoneNumberPresentationObject.isSpinning(true);

            _facade.cancelReservation({phoneNumber: phoneNumberPresentationObject.e164PhoneNumber()})
                .fail(promise.reject)
                .done(() => {
                    self.selectedPhoneNumbers.remove(phoneNumberPresentationObject);
                    phoneNumberPresentationObject.isSelected(false);
                    phoneNumberPresentationObject.isSpinning(false);
                    promise.resolve();
                });
        });

        const _maxNumbersWarning = () => _promiseFactory.deferIndefinitely(promise => {
            _warningModal
                .clearModal()
                .setHeading({i18n: 'maxNumbers'})
                .setIcon({})
                .addText({i18n: {key: 'maxNumbersText', options: {count: _numberPickerConstants.maxNumbersCanAdd}}})
                .addButton({i18n: 'okay', key: 'ok', isActive: true})
                .showModal()
                .then(promise.resolve);
        });
        const _removeAllResults =  (current, previous) => {
            if (current[0] !== previous[0]) {
                _numberPicker().availableNumbers.removeAll();
                self.pickerErrorMessage('');
                _availableOptions.removeAll();
            }
        };
        const _vanityChange = (current, previous) => {
            if (current[0] !== previous[0]) {
                _numberPickers.vanity.availableNumbers.removeAll();
                self.pickerErrorMessage('');
                _isVanitySearchNeeded = true;
            }
        };

        self.numberPickers = _numberPickers;

        self.countriesRegionsCities = ko.observable({
            countries: [],
        });

        self.searchTypes = Object.values(_numberPickerConstants.searchTypeConstants).map(searchType => ({label: _i18n.t(`numberPicker:${searchType}`), id: searchType}));

        self.isDefaultSearch = ko.pureComputed(() => self.searchType() === _numberPickerConstants.searchTypeConstants.default);
        self.isRepeatingSearch = ko.pureComputed(() => self.searchType() === _numberPickerConstants.searchTypeConstants.repeating);
        self.isVanitySearch = ko.pureComputed(() => self.searchType() === _numberPickerConstants.searchTypeConstants.vanity);

        self.didCountryOrRegionChange = ko.observable(false);
        self.didCityOrNpaChange = ko.observable(false);

        self.isLocal = ko.pureComputed(() => _numberType() === _numberPickerConstants.numberTypes.local);
        self.isTollFree = ko.pureComputed(() => _numberType() === _numberPickerConstants.numberTypes.tollFree);

        self.smsPhoneNumberLimit = ko.observable();
        self.smsPhoneNumberLimitReason = ko.observable();

        self.messagingComplianceRequiredLimit = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.NO_MESSAGING_COMPLIANCE);
        self.needMoreComplianceInfoLimit = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.REACHED_SP_LIMIT);
        self.personalAccountLimit = ko.pureComputed(() => self.smsPhoneNumberLimitReason() === _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.REACHED_PERSONAL_ACCOUNT_LIMIT);

        self.regionType = ko.pureComputed(() => {
            const countryCode = _numberPicker().country()[0];
            const country = self.countriesRegionsCities().countries.find(country => country.id === countryCode);
            return country ? country.regionType : "State";
        });
        self.npaOrPrefix = ko.pureComputed(() => self.isTollFree() ? 'prefix' : 'npa');
        self.numbersSearched = ko.observable(false);
        self.backClick = null;

        self.pickerErrorMessage = ko.computed({
            read: () => _numberPicker().errorMessage(),
            write: (key, options = {}) => {
                const i18nObject = key === '' ? '' : {key: `numberPicker:${key}`, options};
                _numberPicker().errorMessage(i18nObject);
            },
        });

        self.showVanityErrorMessage = ko.observable(false);
        self.showPickerErrorMessage = ko.pureComputed(() => self.pickerErrorMessage() !== '');
        self.showAvailablePhoneNumbers = ko.pureComputed(() => {
            return _availableNumbers().length > 0 && self.showNumbersLoadingSpinner() === false;
        });

        self.availablePhoneNumbersDisplayed = ko.pureComputed(() => {
            const endIndex = _currentPage() * NUMBERS_PER_PAGE;
            const startIndex = endIndex - NUMBERS_PER_PAGE;
            return _availableNumbers().slice(startIndex, endIndex);
        });

        self.availableOptionsDisplayed = ko.pureComputed(()=> _isSignup || _browserType.isMobile() ?
            _availableOptions().slice(0,4) :
            _availableOptions()
        );
        self.showAvailableOptions = ko.pureComputed(() => {
            return self.searchType() === _numberPickerConstants.searchTypeConstants.default &&
                _availableOptions().length > 0 &&
                self.showNumbersLoadingSpinner() === false;
        });
        self.selectedPhoneNumbers = ko.observableArray([]);
        self.showSelectedPhoneNumbers = ko.pureComputed(() => {
            return _showSelectedResults() && self.selectedPhoneNumbers().length > 0;
        });

        self.failedReservations = ko.observableArray([]);
        self.showFailedReservations = ko.pureComputed(() => self.failedReservations().length > 0);

        self.showNumbersLoading = ko.pureComputed(() => self.showNumbersLoadingSpinner() || self.showResultsHereText());
        self.showNumbersLoadingSpinner = ko.observable(true);
        self.showResultsHereText = ko.pureComputed(() => {
            return self.searchType() === _numberPickerConstants.searchTypeConstants.vanity &&
                self.showNumbersLoadingSpinner() === false &&
                self.showPickerErrorMessage() === false &&
                _availableNumbers().length === 0;
        });
        self.showMustSelectANumber = ko.observable(false);

        self.preventStutter = ko.observable(0);

        self.vanityString = ko.observable('');
        self.vanityTypes = [
            {
                id: _numberPickerConstants.vanityTypeSelectConstants.begins,
                text: _i18n.t(`numberPickerConstants:${_numberPickerConstants.vanityTypeSelectConstants.begins}`),
            },
            {
                id: _numberPickerConstants.vanityTypeSelectConstants.contains,
                text: _i18n.t(`numberPickerConstants:${_numberPickerConstants.vanityTypeSelectConstants.contains}`),
            },
            {
                id: _numberPickerConstants.vanityTypeSelectConstants.ends,
                text: _i18n.t(`numberPickerConstants:${_numberPickerConstants.vanityTypeSelectConstants.ends}`),
            },
        ];
        self.vanityType = ko.observable([_numberPickerConstants.vanityTypeSelectConstants.contains]);
        self.isVanityStringValid = ko.pureComputed(() => {
            const length = self.vanityString().length;
            return (
                (self.isLocal() && length >= _numberPickerConstants.localVanityStringMinimumLength) ||
                (self.isTollFree() && length >= _numberPickerConstants.tollFreeVanityStringMinimumLength)
            ) && self.vanityString.isValid();
        });

        self.theme = '';

        _disposables.push(
            self.vanityString.subscribe(() => _isVanitySearchNeeded = true),
            self.vanityType.subscribe(() => _isVanitySearchNeeded = true)
        );

        self.vanitySearchClick = () => {
            self.showVanityErrorMessage(true);

            if (_isVanitySearchNeeded && self.isVanityStringValid()) {
                _isVanitySearchNeeded = false;
                _findPhoneNumbers({searchType: _numberPickerConstants.searchTypeConstants.vanity});
            }
        };

        self.dismissFailedReservationMessage = (phoneNumber) => {
            Object.values(_numberPickerConstants.searchTypeConstants).forEach(
                searchType => _numberPickers[searchType].availableNumbers.remove(
                    availableNumber => availableNumber.formattedPhoneNumber() === phoneNumber
                )
            );
            self.failedReservations.remove(phoneNumber);
        };

        self.togglePhoneNumberSelection = (phoneNumberPresentationObject) => _promiseFactory.deferIndefinitely(promise => {
            if (phoneNumberPresentationObject.isSpinning() || phoneNumberPresentationObject.isDisabled()) {
                promise.resolve();
                return;
            }

            if (phoneNumberPresentationObject.isSelected() === true) {
                _removeSelectedPhoneNumber(phoneNumberPresentationObject)
                    .fail(promise.reject)
                    .done(promise.resolve);
                return;
            }

            if (self.selectedPhoneNumbers().length >= _numberPickerConstants.maxNumbersCanAdd) {
                _maxNumbersWarning()
                    .done(() => {
                        promise.resolve();
                    });
                return;
            }

            phoneNumberPresentationObject.isSpinning(true);
            phoneNumberPresentationObject.isChecked(false);
            self.showMustSelectANumber(false);

            if (self.isVanitySearch()) {
                phoneNumberPresentationObject.vanityString(self.vanityString());
            }

            if (_isSingleSelect) {
                _clearOtherSelectedPhoneNumber();
            }

            const searchType = _numberPickerConstants.searchTypeEnumeration[self.searchType()];

            _facade.getPhoneNumberBandwidthLocation(phoneNumberPresentationObject)
                .fail(promise.reject)
                .done((location) => {
                    phoneNumberPresentationObject.setRegion(location.region);
                    phoneNumberPresentationObject.setCity(location.city);

                    _facade.reservePhoneNumber({phoneNumberPresentationObject, numberType: _numberType(), searchType, isSignup: _isSignup})
                        .fail(promise.reject)
                        .done(result => {
                            const isReserved = result.status === "reserved";
                            phoneNumberPresentationObject.isSelected(isReserved);
                            phoneNumberPresentationObject.isChecked(isReserved);
                            phoneNumberPresentationObject.isFailed(!isReserved);
                            phoneNumberPresentationObject.isDisabled(!isReserved);
                            phoneNumberPresentationObject.isSpinning(false);

                            if (isReserved) {
                                self.selectedPhoneNumbers.push(phoneNumberPresentationObject);
                            } else {
                                self.failedReservations.push(phoneNumberPresentationObject.formattedPhoneNumber());
                            }

                            promise.resolve();
                        });
                });
        });

        self.isSelectedPhoneNumberSmsEnabled = (index) => {
            if (self.isTollFree()) {
                return !self.messagingComplianceRequiredLimit();
            }

            return (index < self.smsPhoneNumberLimit()) ||
                (self.smsPhoneNumberLimit() < 0) &&
                !self.messagingComplianceRequiredLimit();
        };

        self.removeSelectedPhoneNumber = (phoneNumberPresentationObject) => _removeSelectedPhoneNumber(phoneNumberPresentationObject);

        self.availableOptionClick = (availableOption) => {
            const npa = availableOption.availableCity.slice(-4, -1);
            const city = availableOption.availableCity.slice(0, -6);
            const npaCity = `${npa}${city}`;
            const region = _getSelect2Value(_numberPicker().region);
            _setAvailableNumbers(availableOption.numbers.map(phoneNumber => new NumberPickerPresentationObjectConstructor({phoneNumber, region, city})));
            _availableOptions.removeAll();
            self.pickerErrorMessage('');

            _isAvailableOptionClick = true;
            _numberPicker().city([npaCity]);
        };

        self.refreshClick = () => {
            self.showNumbersLoadingSpinner(true);
            setTimeout(() => {
                self.showNumbersLoadingSpinner(false);
            }, REFRESH_NUMBER_ARTIFICIAL_DELAY);

            const newCurrentPage = _currentPage() < Math.ceil(_availableNumbers().length / NUMBERS_PER_PAGE) ?
                _currentPage() + 1 :
                1;
            _currentPage(newCurrentPage);
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.backClick = () => _backClick();

        self.onVanityInputKeyup = (_viewModel, e) => {
            if (e.which === ENTER_KEY) {
                self.vanitySearchClick();
            }
        };

        self.activate = (
            {
                 backClick,
                 numberType,
                 searchType,
                 vanityString,
                 geoLocation,
                 selectedPhoneNumbers,
                 isSingleSelect,
                 hideSelectedResults,
                 isSignup,
                 theme,
                 smsPhoneNumberLimit = ko.observable(_smsCampaignRegistrationConstants.smsAccountPhoneNumberLimits.NONE),
                 smsPhoneNumberLimitReason = ko.observable(_smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.NONE)
        }) => {
            if (_isActivated) {
                return;
            }
            _isActivated = true;

            const FacadeContructor = require('presentation/numberPicker/facades/numberPickerFacade');
            _facade = new FacadeContructor();
            _facade.init();

            const ValidatorConstructor = require('presentation/numberPicker/validators/numberPickerValidator');
            _validator = new ValidatorConstructor();

            const AddPhoneNumberWarningModalConstructor = require('presentation/settings/phoneNumbers/viewModels/addPhoneNumberWarningModalViewModel');
            _warningModal = new AddPhoneNumberWarningModalConstructor();

            _numberType(numberType);

            if (searchType) {
                self.searchType(ko.unwrap(searchType));
            }

            if (backClick !== undefined) {
                _backClick = backClick;
            }

            self.selectedPhoneNumbers = selectedPhoneNumbers;
            _initialSelectedPhoneNumbers = selectedPhoneNumbers().slice();
            _isSingleSelect = isSingleSelect === true;
            _isSignup = isSignup === true;
            _showSelectedResults(hideSelectedResults !== true);
            _geoLocation = geoLocation;

            self.theme = theme !== undefined ? theme : SELECT_THEME;
            self.smsPhoneNumberLimit = smsPhoneNumberLimit;
            self.smsPhoneNumberLimitReason = smsPhoneNumberLimitReason;

            return _initialize(vanityString);
        };

        const _initialize = (vanityString) => {
            _validator.registerViewModel(self);
            if (vanityString && vanityString()) {
                self.vanityString(vanityString());
            }

            self.pricingUrl = _urlFormatter.buildFrontendUrl(["pricing"]);
            self.pricingUrlText = _i18n.t('numberPicker:pricingPage');
            self.pricingMessage = _i18n.t('numberPicker:pricingMessage');

            let geoLocation;
            const innerPromiseFactory = new PromiseFactoryConstructor();

            if (_geoLocation) {
                geoLocation = _geoLocation;
            } else if (self.isTollFree()) {
                geoLocation = {prefix: _numberPickerConstants.tollFreePrefixDefault};
            } else {
                innerPromiseFactory.deferIndefinitely(innerPromise => _facade.getGeoLocation()
                    .fail(innerPromise.reject)
                    .done(result => {
                        geoLocation = result;
                        geoLocation.npa = geoLocation.npa.toString();
                        innerPromise.resolve();
                    })
                );
            }

            innerPromiseFactory.deferIndefinitely(innerPromise => _facade.getInitial()
                .fail(innerPromise.reject)
                .done(result => {
                    self.countriesRegionsCities(result);
                    innerPromise.resolve();
                })
            );

            innerPromiseFactory.wait()
                .fail((error) => {
                    throw new Error(error);
                })
                .done(() => {
                    if (self.isTollFree()) {
                        _numberPickers.default.prefix(geoLocation.prefix);
                        _numberPickers.repeating.prefix(geoLocation.prefix);
                        _numberPickers.vanity.prefix(geoLocation.prefix);
                    } else {
                        Object.values(_numberPickerConstants.searchTypeConstants).forEach(searchType => {
                            _numberPickers[searchType].country([geoLocation.countryCode]);
                            _numberPickers[searchType].region([geoLocation.regionCode]);
                        });

                        _numberPickers.default.city([`${geoLocation.npa}${geoLocation.city}`]);
                        _numberPickers.repeating.npa([geoLocation.npa]);
                        _numberPickers.vanity.npa([geoLocation.npa]);
                    }

                    _findPhoneNumbers({searchType: self.searchType(), isInitialSearch: true});

                    _disposables.push(
                        self.searchType.subscribe(searchType => {
                            if (searchType !== _numberPickerConstants.searchTypeConstants.vanity) {
                                const isSearchTypeChange = _numberPicker().hasBeenSeen() === false;
                                const shouldSearch = isSearchTypeChange;
                                _findPhoneNumbers({searchType, shouldSearch, isSearchTypeChange});
                            }
                        }),
                        _numberPickers.default.region.subscribePrevious(_removeAllResults),
                        _numberPickers.repeating.region.subscribePrevious(_removeAllResults),
                        _numberPickers.vanity.region.subscribePrevious(_removeAllResults),
                        _numberPickers.default.city.subscribePrevious((current, previous) => {
                            const shouldSearch = current[0] !== previous[0];
                            _findPhoneNumbers({searchType: _numberPickerConstants.searchTypeConstants.default, shouldSearch});
                        }),
                        _numberPickers.repeating.npa.subscribePrevious((current, previous) => {
                            const shouldSearch = current[0] !== previous[0];
                            _findPhoneNumbers({searchType: _numberPickerConstants.searchTypeConstants.repeating, shouldSearch});
                        }),
                        _numberPickers.default.prefix.subscribe(() => {
                            _findPhoneNumbers({searchType: _numberPickerConstants.searchTypeConstants.default, isUserChange: true});
                        }),
                        _numberPickers.repeating.prefix.subscribe(() => {
                            _findPhoneNumbers({searchType: _numberPickerConstants.searchTypeConstants.repeating, isUserChange: true});
                        }),
                        _numberPickers.vanity.npa.subscribePrevious(_vanityChange),
                        _numberPickers.vanity.prefix.subscribePrevious(_vanityChange)
                    );
                });

            self.preventStutter(1);
        };
    };
});

