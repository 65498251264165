define('presentation/login/viewModels/payBalanceActionModalViewModel',[
    'businessServices/authentication/sessionWorkflowFlags',
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory',
    'plugins/router',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/modal',
    'presentation/loginNotifications/viewModels/payBalanceViewModel',
    'presentation/loginNotifications/viewModels/balancePaidViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const PayBalanceViewModelConstructor = require('presentation/loginNotifications/viewModels/payBalanceViewModel');
        const BalancePaidViewModelConstructor = require('presentation/loginNotifications/viewModels/balancePaidViewModel');

        const _actionModal = new ActionModalViewModelConstructor();
        const _router = require('plugins/router');
        const _navigationConfig = require('settings/navigationConfiguration');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');
        let _modalService = null;

        self.showPayBalanceModal = () => {
            return _promiseFactory.deferIndefinitely((payBalanceCompletedPromise) => {
                _actionModal.setContentViewModel(PayBalanceViewModelConstructor, [payBalanceCompletedPromise]);
                _actionModal.setHeaderText({i18n: 'payBalance:headerTitle'});
                _actionModal.setCancelButtonText({i18n: 'payBalance:payLater'});
                _actionModal.shouldDisplayCancelButton(true);
                _actionModal.shouldDisplayAgentBypassButton(false);
                _actionModal.shouldDisplaySubmitButton(false);
                _actionModal.shouldDisplayDefaultFooterMessage(true);

                if (_sessionAccountInfo.isImpersonating()) {
                    _actionModal.shouldDisplayAgentBypassButton(true);
                    _actionModal.shouldDisplayCancelButton(false);
                }

                _actionModal.showModal()
                    .done((result) => {
                        if (result === "cancel") {
                            _sessionWorkflowFlags.hasBypassedPastDueBalance(true);
                            _router.navigate(_navigationConfig.loginContinuationPrompt);
                            payBalanceCompletedPromise.resolve();
                        } else if (result === "agentBypass") {
                            _sessionWorkflowFlags.hasBypassedPastDueBalance(true);
                            _router.navigate(_navigationConfig.loginLandingPageUrl);
                            payBalanceCompletedPromise.resolve();
                        }
                    });
            });
        };

        self.showBalancePaidActionModal = (paymentAmount) => {
            _actionModal.setContentViewModel(BalancePaidViewModelConstructor, [paymentAmount]);
            _actionModal.setHeaderText({i18n: 'payBalance:balancePaid'});
            _actionModal.setCancelButtonText({i18n: 'common:dismiss'});
            _actionModal.shouldDisplayCancelButton(true);
            _actionModal.shouldDisplayAgentBypassButton(false);
            _actionModal.shouldDisplaySubmitButton(false);
            _actionModal.shouldDisplayDefaultFooterMessage(true);

            if (_sessionAccountInfo.isImpersonating()) {
                _actionModal.shouldDisplayAgentBypassButton(true);
                _actionModal.shouldDisplayCancelButton(false);
            }

            _actionModal.showModal()
                .done((result) => {
                    if(result === "cancel") {
                        _router.navigate(_navigationConfig.loginContinuationPrompt);
                    } else if (result === "agentBypass") {
                        _sessionWorkflowFlags.hasBypassedPastDueBalance(true);
                        _router.navigate(_navigationConfig.loginLandingPageUrl);
                    }
                });
        };

        self.activate = () => {
            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.showPayBalanceModal()
                .done((payBalanceResult) => {
                    _modalService.closeModal(_actionModal);
                    if (payBalanceResult && payBalanceResult.isBalancePaid) {
                        self.showBalancePaidActionModal(payBalanceResult.paymentAmount);
                    }
                });
            return _promiseFactory.wait();
        };
    };
});
