define('constants/referentialConstraintEnumerations',[], function () {
    const _i18n = require('i18next');

    return {
        "autoAttendant-autoAttendant": {
            entityText: _i18n.t('referentialConstraints:autoAttendant.entityText'),
            message: _i18n.t('referentialConstraints:autoAttendant.message'),
            icon: "attendant"
        },
        "autoAttendant-forwardingNumber": {
            entityText: _i18n.t('referentialConstraints:autoAttendant.entityText'),
            message: _i18n.t('referentialConstraints:forwardingNumber.message'),
            icon: "attendant"
        },
        "autoAttendant-schedule": {
            entityText: _i18n.t('referentialConstraints:autoAttendant.entityText'),
            message: _i18n.t('referentialConstraints:schedule.message'),
            icon: "attendant"
        },
        "autoAttendant-voicePrompt": {
            entityText: _i18n.t('referentialConstraints:autoAttendant.entityText'),
            message: _i18n.t('referentialConstraints:audioFile.message'),
            icon: "attendant"
        },
        "autoAttendant-voicemailBox": {
            entityText: _i18n.t('referentialConstraints:autoAttendant.entityText'),
            message: _i18n.t('referentialConstraints:voicemailBox.message'),
            icon: "attendant"
        },
        "forwardingNumber-voicemailGreeting": {
            entityText: _i18n.t('referentialConstraints:forwardingNumber.entityText'),
            message: _i18n.t('referentialConstraints:voicemailGreeting.message'),
            icon: "forwarding"
        },
        "phoneNumber-autoAttendant": {
            entityText: _i18n.t('referentialConstraints:phoneNumber.entityText'),
            message: _i18n.t('referentialConstraints:autoAttendant.message'),
            icon: "phone"
        },
        "phoneNumber-forwardingNumber": {
            entityText: _i18n.t('referentialConstraints:phoneNumber.entityText'),
            message: _i18n.t('referentialConstraints:forwardingNumber.message'),
            icon: "phone"
        },
        "phoneNumber-recordingNotification": {
            entityText: _i18n.t('referentialConstraints:phoneNumber.entityText'),
            message: _i18n.t('referentialConstraints:recordingNotification.message'),
            icon: "recording"
        },
        "phoneNumber-schedule": {
            entityText: _i18n.t('referentialConstraints:phoneNumber.entityText'),
            message: _i18n.t('referentialConstraints:schedule.message'),
            icon: "phone"
        },
        "phoneNumber-voicemailBox": {
            entityText: _i18n.t('referentialConstraints:phoneNumber.entityText'),
            message: _i18n.t('referentialConstraints:voicemailBox.message'),
            icon: "phone"
        },
        "phoneNumber-voicePrompt": {
            entityText: _i18n.t('referentialConstraints:phoneNumber.entityText'),
            message: _i18n.t('referentialConstraints:audioFile.message'),
            icon: "phone"
        },
        "schedule-autoAttendant": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:autoAttendant.message'),
            icon: "schedule"
        },
        "schedule-forwardingNumber": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:forwardingNumber.message'),
            icon: "schedule"
        },
        "schedule-user": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:user.message'),
            icon: "schedule"
        },
        "schedule-userGroup": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:userGroup.message'),
            icon: "schedule"
        },
        "schedule-voicemailBox": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:voicemailBox.message'),
            icon: "schedule"
        },
        "schedule-voicePrompt": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:audioFile.message'),
            icon: "schedule"
        },
        "scheduleSegment-autoAttendant": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:autoAttendant.message'),
            icon: "schedule"
        },
        "scheduleSegment-forwardingNumber": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:forwardingNumber.message'),
            icon: "schedule"
        },
        "scheduleSegment-user": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:user.message'),
            icon: "schedule"
        },
        "scheduleSegment-userGroup": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:userGroup.message'),
            icon: "schedule"
        },
        "scheduleSegment-voicemailBox": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:voicemailBox.message'),
            icon: "schedule"
        },
        "scheduleSegment-voicePrompt": {
            entityText: _i18n.t('referentialConstraints:schedule.entityText'),
            message: _i18n.t('referentialConstraints:audioFile.message'),
            icon: "schedule"
        },
        "user-voicemailGreeting": {
            entityText: _i18n.t('referentialConstraints:user.entityText'),
            message: _i18n.t('referentialConstraints:voicemailGreeting.message'),
            icon: "user"
        },
        "user-AuthorizedDialer": {
            entityText: "",
            message: "",
            icon: "user"
        },
        "userGroup-voicemailGreeting": {
            entityText: _i18n.t('referentialConstraints:userGroup.entityText'),
            message: _i18n.t('referentialConstraints:voicemailGreeting.message'),
            icon: "userGroup"
        }
    };
});

