define('presentation/messages/bindings/conversationMessagesScrollBinding',[
    'common/throttling/debounce'
], function(
    /** @type typeof import('common/throttling/debounce') */
    throttle
) {
    const newMessagesDividerLineId = 'newMessagesDividerLineId';

    return {
        /** @type import('presentation/messages/bindings/conversationMessagesScrollBinding')["init"] */
        init: function (element, valueAccessor) {
            const {
                elementToScrollTo,
                isLoadingNextMessagesPage,
                isLoadingPreviousMessagesPage,
                isNewMessagePillFading,
                isScrolledToBottom,
                onBottomReachedCallback,
                requestNextMessagesPageCallback,
                requestPreviousMessagesPageCallback,
                scrollBuffer,
                showNewMessagesPill,
                scrollPositionY,
                scrollToBottom,
                messageIdToJumpTo
            } = ko.unwrap(valueAccessor());

            // we reserve 75px between the compose area and top of messages pane
            const MIN_VALID_SCROLL_HEIGHT = 75;

            /** @type {OverlayScrollbars} */
            let scrollbarInstance = null;
            let isInitialized = false;
            let isInitialScrollPositionSet = false;
            /** @type {null | number} */
            let setInitialScrollPositionTimeout = null;
            let isAtBottom = isScrolledToBottom();
            /** @type {Array<IDisposable>} */
            let disposables = [];

            /** @type {Element} */
            let dividerLineTarget = null;
            /** @type {Element} */
            let messageToJumpToTarget = null;
            /** @type {IntersectionObserver} */
            let observer = null;

            const options = {
                root: document.querySelector('.conversation-messages-container'),
                rootMargin: '0px',
                trackVisibility: true,
                delay: 100
            };

            const _requestPreviousMessagePage = throttle(500, requestPreviousMessagesPageCallback);
            const _requestNextMessagePage = throttle(500, requestNextMessagesPageCallback);

            // once there are no content shifts for 100ms, set isInitialScrollPositionSet = true
            const _finalizeInitialScrollPositionAfterWait = () => {
                if (isInitialScrollPositionSet) {
                    return;
                }

                if (setInitialScrollPositionTimeout) {
                    clearTimeout(setInitialScrollPositionTimeout);
                }

                setInitialScrollPositionTimeout = setTimeout(() => {
                    isInitialScrollPositionSet = true;
                }, 100);
            };

            const _setInitialScrollToPreviousLocation = (/** @type{number} */initialScrollPosition) => {
                scrollbarInstance.scroll({ y: initialScrollPosition });
                _finalizeInitialScrollPositionAfterWait();
            };

            const _setInitialScrollToElement = (/** @type {HTMLElement} */ selectedElement) => {
                scrollbarInstance.scroll({
                    el       : selectedElement,
                    block    : 'begin',
                    margin   : { top: 12 }
                });

                _finalizeInitialScrollPositionAfterWait();
            };

            const _jumpToMessage = (/** @type {HTMLElement}*/ target = null) => {
                const jumpToMessage = target ? target : document.getElementById(messageIdToJumpTo());
                _setInitialScrollToElement(jumpToMessage);
                messageIdToJumpTo(null);
            };

            const _setInitialScrollToBottom = () => {
                scrollbarInstance.scroll({ y: '100%' });
                _finalizeInitialScrollPositionAfterWait();
            };

            /** @type {IntersectionObserverCallback} */
            const _onIntersectionChange = (entries) => {
                entries.forEach(({ isIntersecting, isVisible, boundingClientRect, target }) => {
                    if (!target) {
                        return;
                    }

                    if (target.id === newMessagesDividerLineId) {
                        if (!showNewMessagesPill()) {
                            const rootBoundary = element.getBoundingClientRect();
                            const areNewMessagesBelowView = boundingClientRect.bottom >= rootBoundary.bottom;
                            showNewMessagesPill(areNewMessagesBelowView);
                        }
                        isNewMessagePillFading(isIntersecting || isVisible);
                        return;
                    }

                    if (messageIdToJumpTo() && (!isIntersecting && !isVisible)) {
                        _jumpToMessage(target);
                    }
                });
            };

            /* jshint ignore:start */
            observer = new IntersectionObserver(_onIntersectionChange, options);
            /* jshint ignore:end */

            const _checkScrollBuffer = () => {
                if (isInitialScrollPositionSet === false) {
                    return;
                }

                if (scrollbarInstance && isInitialized) {
                    const scroll = scrollbarInstance.scroll();

                    if (scroll.position.y < scrollBuffer) {
                        /*
                         * if vertical scroll ratio is 1 then user is scrolled to the bottom and triggering the scroll buffer
                         *   - don't change value of isScrolledToBottom
                         * if max vertical scroll area is less than the valid minimum, then content has not finished rendering
                         * if scroll position is not at the bottom:
                         *   - update isScrolledToBottom value so when new page is fetched and onContentSizeChange
                         *     callback is called we don't auto scroll back to the bottom
                         **/
                        if (scroll.ratio.y !== 1 && scroll.max.y > MIN_VALID_SCROLL_HEIGHT) {
                            isScrolledToBottom(false);
                        }

                        _requestPreviousMessagePage();
                    }

                    if ((scroll.max.y - scroll.position.y) < scrollBuffer) {
                        _requestNextMessagePage();
                    }

                    if (isLoadingPreviousMessagesPage() && scroll.position.y === 0) {
                        // scroll 1px from the top so you aren't scrolled to the top of the next messages page when it loads in
                        scrollbarInstance.scroll({ y: 1 });
                    }

                    if (scroll.ratio.y === 1) {
                        isNewMessagePillFading(true);
                        onBottomReachedCallback();
                    }

                }
            };

            const _animatedScrollToBottom = () => {
                if (scrollbarInstance && isInitialized) {
                    if (scrollbarInstance.scroll().ratio.y !== 1) {
                        scrollbarInstance.scroll({y: "100%"}, 500);
                        _updateScrollPositionTracking();
                    }
                }
            };

            const _scrollToBottom = () => {
                if (scrollbarInstance && isInitialized) {
                    if (scrollbarInstance.scroll().ratio.y !== 1) {
                        scrollbarInstance.scroll({y: "100%"});
                        _updateScrollPositionTracking();
                    }
                }
            };

            const _updateScrollPositionTracking = () => {
                if (scrollbarInstance && isInitialized) {
                    const state = scrollbarInstance.getState();
                    const scroll = scrollbarInstance.scroll();
                    const messageHeight = 56;
                    const contentHeight = state.contentScrollSize.height;
                    const yPositionFromTop = scroll.position.y + state.viewportSize.height;
                    const canSeeLastMessage = yPositionFromTop + messageHeight >= contentHeight;
                    isAtBottom = canSeeLastMessage || state.hasOverflow.y === false;

                    if (isLoadingNextMessagesPage()) {
                        isScrolledToBottom(false);
                    } else {
                        isScrolledToBottom(isAtBottom);
                    }

                    scrollPositionY(scroll.position.y);
                }
            };

            const _tryObserveNewMessage = () => {
                if (dividerLineTarget !== null && messageToJumpToTarget !== null) {
                    return;
                }

                dividerLineTarget = document.querySelector(`#${newMessagesDividerLineId}`);

                if (dividerLineTarget) {
                    observer.observe(dividerLineTarget);
                }

                if (messageIdToJumpTo()) {
                    messageToJumpToTarget = document.getElementById(messageIdToJumpTo());
                }

                if (messageToJumpToTarget) {
                    observer.observe(messageToJumpToTarget);
                }
            };

            scrollbarInstance = $(element).overlayScrollbars({
                className: "os-theme-tresta",
                overflowBehavior : {
                    x : 'hidden',
                    y : 'scroll'
                },
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 100
                },
                sizeAutoCapable: false,
                callbacks: {
                    onInitialized: () => {
                        isInitialized = true;
                        _updateScrollPositionTracking();
                    },
                    onScroll: _checkScrollBuffer,
                    onScrollStop: _updateScrollPositionTracking,
                    onContentSizeChanged: () => {
                        /*
                        * This callback must handle two scenarios:
                        * 1. Element resizing during initial render. In order to correctly initialize
                        *    scroll position, we wait for a timeout of 100ms after each content size
                        *    change. If another content size change occurs before the timeout expires,
                        *    we reset the timer and reset the scroll position to the correct location.
                        *    This location could either be (in order of precedence):
                        *       a. the users previous scroll position,
                        *       b. the new message divider above newly received messages, or
                        *       c. the bottom of the conversation
                        *       d. route updated to jump to message
                        *    Once the full timeout expires we consider the scroll position stable and
                        *    mark isInitialScrollPositionSet = true
                        * 2. A new messages come in. When a new message comes in there are two scenarios:
                        *       a. the user is scrolled to the bottom.
                        *       b. the user is not at the bottom (scrolled higher up in the conversation).
                        *    In scenario a. we want to keep the user scrolled to the bottom of the
                        *    conversation. In scenario b. we don't need to do anything :)
                        * */

                        // if initial scroll position is not yet finalized
                        if (scrollbarInstance && isInitialized && !isInitialScrollPositionSet) {
                            const newMessageDivider = document.getElementById(newMessagesDividerLineId);

                            // scenario 1d
                            if (messageIdToJumpTo()) {
                                _jumpToMessage();
                                return;
                            }

                            // scenario 1b
                            if (isScrolledToBottom() && newMessageDivider) {
                                _setInitialScrollToElement(newMessageDivider);
                                return;
                            }

                            // scenario 1c
                            if (isScrolledToBottom()) {
                                _setInitialScrollToBottom();
                                return;
                            }

                            // scenario 1a
                            const initialScrollPosition = scrollPositionY();
                            _setInitialScrollToPreviousLocation(initialScrollPosition);
                            return;
                        }

                        _tryObserveNewMessage();

                        // scenario 2a
                        if (isScrolledToBottom()) {
                            _scrollToBottom();
                        }
                    },
                    onHostSizeChanged: () => {
                        if (isInitialScrollPositionSet === false) {
                            return;
                        }

                        if (isScrolledToBottom()) {
                            _scrollToBottom();
                        }
                    }
                }
            }).overlayScrollbars();

            scrollToBottom(_animatedScrollToBottom);

            // use this to scroll to an arbitrary list item at any time
            disposables.push(
                elementToScrollTo.subscribe((/** @type HTMLElement */ element) => {
                    scrollbarInstance.scroll({
                        el       : element,
                        block    : 'begin',
                        margin   : { top: 12 }
                    }, 500);
                })
            );

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (scrollbarInstance && isInitialized) {
                    scrollbarInstance.destroy();
                    observer.disconnect();

                    disposables.forEach((disposable) => {
                        disposable.dispose();
                    });

                    disposables = [];
                }
            });

            // the following runs once

            if (messageIdToJumpTo()) {
                _jumpToMessage();
                return;
            }

            // Use this to set the initial scroll position. Only executes once after conversationMessagesViewModel composition completes.
            const newMessageDivider = document.getElementById(newMessagesDividerLineId);

            // if a user is scrolled to the bottom but a new message divider is present, scroll that into view
            if (isAtBottom && newMessageDivider) {
                _setInitialScrollToElement(newMessageDivider);
                return;
            }

            // if a user is coming back to a conversation and they were not scrolled to the bottom, scroll them to where they were
            const initialScrollPosition = scrollPositionY();

            if (initialScrollPosition !== null) {
                _setInitialScrollToPreviousLocation(initialScrollPosition);
                return;
            }

            // if a user is scrolled to the bottom but no new message divider is present, make sure they stay at the bottom
            _setInitialScrollToBottom();
        }
    };
});
