define('businessServices/browserSupport/audioDevices',['webrtc-adapter',
        'common/promises/promiseFactory'],
function() {
    return function() {
        var self = this;

        var _webrtcAdapter = require('webrtc-adapter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.isRecordingSupported = function() {
            return _webrtcAdapter.browserDetails.browser !== "Not a supported browser.";
        };

        self.hasRecordingDevices = function() {
            return _promiseFactory.defer(function(deferredObject) {
                navigator.mediaDevices.enumerateDevices()
                    .then(function(deviceInfos) {
                        var hasRecordingDevices = false;

                        for (var i = 0; i < deviceInfos.length; i++) {
                            var deviceInfo = deviceInfos[i];
                            if (deviceInfo.kind === "audioinput") {
                                hasRecordingDevices = true;
                                break;
                            }
                        }

                        deferredObject.resolve(hasRecordingDevices);
                    })
                    .catch(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

