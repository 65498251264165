define('presentation/tabs/presentationObjects/tabsPresentationObject',[],function() {
    return function(tabId, tabActive, isTabValid, tabText = null) {
        var tab = this;
        tab.id = tabId.replace(/ /g, '');
        tab.tabText = tabText != null ? tabText : tabId;
        tab.label = tab.tabText;
        tab.setTabActive = function() {
            tabActive(tab.id);
        };
        tab.isActiveTab = ko.pureComputed(function() {
            return tabActive() === tab.id;
        });

        tab.isTabValid = isTabValid;
        if(isTabValid === undefined){
            tab.isTabInvalid = ko.pureComputed(function() {
                return false;
            });
        } else {
            tab.isTabInvalid = ko.pureComputed(function() {
                return ! isTabValid();
            });
        }
    };
});
