define('presentation/messages/viewModels/conversationMessageViewModel',[
    'businessServices/contacts/contactsStateSingleton',
    'constants/systemObjectIconNameConstants',
    'constants/deliveryStatus',
    'common/time/datetimeFormatter',
    'presentation/common/smsAttachment/factories/smsAttachmentFactory',
    'i18next'
], function (
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('constants/systemObjectIconNameConstants') */
    systemObjectIconNames,
    /** @type typeof import('constants/deliveryStatus') */
    DeliveryStatus,
    /** @type typeof import('common/time/datetimeFormatter') */
    DatetimeFormatterConstructor,
    /** @type typeof import ('presentation/common/smsAttachment/factories/smsAttachmentFactory') */
    SmsAttachmentFactory,
    /** @type typeof import('i18next') */
    i18n
) {
    /** @typedef {import('presentation/messages/viewModels/conversationMessageViewModel')} ConversationMessageViewModel */
    return function () {
        const MessagesHelperConstructor = require('presentation/messages/utils/messagesHelper');
        const _messagesHelper = new MessagesHelperConstructor();

        const _datetimeFormatter = new DatetimeFormatterConstructor();

        /** @type {ConversationMessageViewModel} */
        const self = this;

        /** @type {(smsConversationMessageId: string) => JQueryDeferred<IDeleteMessageResponse>} */
        let _onDeleteMessage;
        /** @type {(message: ConversationMessageViewModel) => PromiseLike<IRetryMessageResponse>} */
        let _onRetryMessage;

        let _preventInteraction = false;

        /** @type KnockoutObservable<DeliveryStatus> */
        const _deliveryStatus = ko.observable();

        self.defaultUserIcon = systemObjectIconNames.contact;
        self.systemIcon = systemObjectIconNames.tresta;
        self.externalParticipantIcon = systemObjectIconNames.contact;
        /** @type {ITextOrLinkSegment[] | string} */
        self.content = [];
        self.direction = null;
        self.sentByName = ko.observable("");
        self.sendingUserId = null;
        self.sendingUserInitials = "";
        self.sendingContactInitials = ko.observable("");
        self.sendingContactAvatar = ko.observable(null);
        self.sendingMemberPhoneNumber = "";
        self.messageDateTime = "";
        self.formattedSentTime = "";
        self.shortFormattedSentTime = "";
        self.locationMetaText = "";
        self.showDateDivider = ko.observable(false);
        self.formattedSentDateTime = "";
        self.sendingUserAvatar = null;
        self.isNumberLimited = null;
        self.isSystemGeneratedMessage = ko.observable(false);
        self.isSentByUser = ko.observable(false);
        self.isSentByContact = ko.observable(false);
        self.showMessageMetadata = ko.observable(true);
        self.isSomeoneTypingNotification = ko.observable(false);
        self.smsConversationMessageId = "";
        self.showRetryMenu = ko.observable(false);
        self.attachments = ko.observableArray([]);
        self.linkPreviews = ko.observableArray([]);
        self.isHoveringOver = ko.observable(false);
        self.showNewMessageDivider = ko.observable(false);
        self.sidebarContacts = _contactsState.sidebarContacts;

        self.draftLimitReached = i18n.t("messages:dailyLimitHit");

        self.deliveryStatus = ko.pureComputed(() => {
            return _deliveryStatus();
        });

        self.isSentByExternalParticipant = ko.pureComputed(() => {
            return !self.isSentByUser() && !self.isSentByContact() && !self.isSystemGeneratedMessage();
        });

        self.isSentBySystem = ko.pureComputed(() => {
           return self.isSystemGeneratedMessage();
        });

        self.sentByDisplayName = ko.pureComputed(() => {
            if (self.sentByName()) {
               if (self.sidebarContacts()) {
                   const contact = _contactsState.getContactByPhoneNumber(self.sentByName());
                   if (contact) {
                       self.isSentByContact(true);
                       self.sendingContactInitials(contact.initials());
                       return contact.displayName();
                   }

                   return self.sentByName();
               }

                return self.sentByName();
            }

            return '';
        });

        self.isSentByUserWithAvatar = ko.pureComputed(() => {
            return self.isSentByUser() &&
                ko.isObservable(self.sendingUserAvatar) &&
                // @ts-ignore
                self.sendingUserAvatar().showImage();
        });

        self.isSentByUserWithoutAvatar = ko.pureComputed(() => {
            return self.isSentByUser() && !self.isSentByUserWithAvatar();
        });

        self.imagesGrid = ko.pureComputed(() => {
            return SmsAttachmentFactory.getImagesGrid(self.imageAttachments());
        });

        self.hasLinkPreviews = ko.pureComputed(() => self.linkPreviews() && self.linkPreviews().length ? true : false);

        /** @type {self["updateDeliveryStatus"]} */
        self.updateDeliveryStatus = (deliveryStatus) => {
            // TODO - split business logic out into domain entities that are separate from view model
            switch (self.deliveryStatus()) {
                // If message is currently in a terminal status, all further status updates should be ignored.
                case DeliveryStatus.Failed:
                case DeliveryStatus.Delivered:
                    return;
                case DeliveryStatus.Sending:
                    // If message is currently in sending status, we can only move into one of the following statuses.
                    if (deliveryStatus !== DeliveryStatus.Sent &&
                        deliveryStatus !== DeliveryStatus.Failed &&
                        deliveryStatus !== DeliveryStatus.Delivered) {
                        return;
                    }

                    break;
                case DeliveryStatus.Sent:
                    // If message is currently in sent status, we can only move into the failed status.
                    if (deliveryStatus !== DeliveryStatus.Failed) {
                        return;
                    }

                    break;
            }

            _deliveryStatus(deliveryStatus);

            switch (deliveryStatus) {
                case DeliveryStatus.Sending:
                case DeliveryStatus.Sent:
                case DeliveryStatus.QueuedForSend:
                case DeliveryStatus.WaitingForAttachments:
                    self.isVisible(!self.isInErroredState());
                    break;
                default:
                    self.isVisible(true);
                    break;
            }
        };

        self.onClickRetrySend = (/** @type {never} */_, /** @type {JQuery.Event} */ e) => {
            if (!_preventInteraction && !self.isNumberLimited()) {
                _preventInteraction = true;
                self.isHoveringOver(false);
                self.showRetryMenu(false);
                _onRetryMessage(self)
                    .then(() => {
                        _preventInteraction = false;
                    });
            }

            e.preventDefault();
        };

        self.onClickDeleteMessage = () => {
            if (!_preventInteraction) {
                _preventInteraction = true;
                self.isHoveringOver(false);
                self.showRetryMenu(false);
                _onDeleteMessage(self.smsConversationMessageId)
                    .then(() => {
                        _preventInteraction = false;
                    });
            }
        };

        self.onMouseEnter = () => {
            self.isHoveringOver(true);
        };

        self.onMouseLeave = () => {
            self.isHoveringOver(false);
        };

        self.statusIcon = ko.pureComputed(() => {
            switch (self.deliveryStatus()) {
                case DeliveryStatus.Sending:
                case DeliveryStatus.WaitingForAttachments:
                    return `messageSending`;
                case DeliveryStatus.Sent:
                case DeliveryStatus.Delivered:
                    return `messagesSent`;
                case DeliveryStatus.QueuedForSend:
                    return `messagesHourglass`;
                case DeliveryStatus.Failed:
                    return `messagesFailed`;
                default:
                    return null;
            }
        });

        self.statusIconText = ko.pureComputed(() => {
            const attachmentWithErrorMsg = self.attachments().find((a) => a.hasErrorMessage());
            return attachmentWithErrorMsg ? attachmentWithErrorMsg.errorBadgeMessage() : null;
        });

        self.onClickOnIcon = () => {
            if (!self.isInErroredState() || _preventInteraction) {
                return;
            }

            const showRetryMenu = self.showRetryMenu();
            self.showRetryMenu(!showRetryMenu);

            if (showRetryMenu) {
                self.isHoveringOver(false);
            }
        };

        self.isBeingSent = ko.pureComputed(() => self.deliveryStatus() === DeliveryStatus.Sending);
        self.isInErroredState = ko.pureComputed(() => {
            switch (self.deliveryStatus()) {
                case DeliveryStatus.Failed:
                case DeliveryStatus.FailedNoRetry:
                        return true;
                default:
                    return false;
            }
        });

        self.allowRetry = ko.pureComputed(() => self.deliveryStatus() === DeliveryStatus.Failed);

        self.imageAttachments = ko.pureComputed(() => {
            return self.attachments().filter((a) => a.isImage());
        });

        self.videoAttachments = ko.pureComputed(() => {
            return self.attachments().filter((a) => a.isVideo());
        });

        self.hasVideos = ko.pureComputed(() => self.videoAttachments().length > 0);
        self.showVideoPlayer = self.hasVideos;
        self.showImagesGrid = ko.pureComputed(() => {
            return self.imagesGrid() && self.imagesGrid().images().length > 0;
        });

        self.hasAttachments = ko.pureComputed(() => self.attachments().length > 0);

        self.attachmentsText = ko.pureComputed(() => {
            if (!self.hasAttachments()) {
                return null;
            }

            if (self.attachments().some((a) => a.hasErrorMessage())) {
                return null;
            }

            /** @type { ISmsUserConversationMessageAttachment[] } */
            const attachments = self.attachments().map(attachment => {
                return {
                    contentType: attachment.contentType(),
                    attachmentPath: attachment.fileName()
                };
            });

            return _messagesHelper.determineMessageText(self.content, self.direction, attachments);
        });

        self.showAttachmentAltText = ko.pureComputed(() => self.hasAttachments() && (self.content === "" || !self.content.length));

        self.fadeIn = ko.pureComputed(() => self.isVisible() && !self.showRetryMenu());
        self.fadeOut = ko.pureComputed(() => !self.isVisible() && !self.showRetryMenu());

        self.isVisible = ko.observable(true);

        /** @type {ConversationMessageViewModel["activate"]} */
        self.activate = (activationData) => {
            _onDeleteMessage = activationData.deleteMessage;
            _onRetryMessage = activationData.retryMessage;

            let sentByName = activationData.sentByName || "";
            const contact = _contactsState.getContactByPhoneNumber(sentByName);
            if (contact) {
                self.isSentByContact(true);
                self.sendingContactInitials(contact.initials());
                self.sendingContactAvatar(contact.avatarUrl());
                sentByName = contact.displayName();
            }

            self.isSomeoneTypingNotification(activationData.isPendingMessage || false);
            self.content = activationData.content || [];
            self.direction = activationData.direction;
            self.sentByName(sentByName);
            self.sendingUserId = activationData.sendingUserId || "";
            self.sendingUserInitials = activationData.sendingUserInitials || "";
            self.sendingMemberPhoneNumber = activationData.sendingMemberPhoneNumber || "";
            self.messageDateTime = activationData.messageDateTime || "";
            self.formattedSentTime = activationData.isPendingMessage ? "" : _datetimeFormatter.buildHourMinuteMeridiemTime(activationData.messageDateTime);
            self.formattedSentDateTime = _datetimeFormatter.formatWithinDayOrLongDate(activationData.messageDateTime);
            self.shortFormattedSentTime = _datetimeFormatter.buildHourMinuteMeridiemTime(activationData.messageDateTime, true);
            self.showDateDivider(activationData.showDateDivider || false);
            self.sendingUserAvatar = activationData.sendingUserAvatar || "";
            self.isSentByUser(!!activationData.sendingUserId);
            self.isSystemGeneratedMessage(activationData.isSystemGeneratedMessage);
            self.showMessageMetadata(activationData.showMessageMetadata || false);
            self.smsConversationMessageId = activationData.smsConversationMessageId || "";
            self.isNumberLimited = activationData.isNumberLimited;
            self.attachments(activationData.attachments);
            self.linkPreviews(activationData.linkPreviews);
            self.locationMetaText = activationData.locationMetaText || "";

            _deliveryStatus(activationData.deliveryStatus);

            return _initialize();
        };

        const _initialize = () => {

        };
    };
});
