define('presentation/settings/phoneNumbers/viewModels/addPhoneNumberWarningModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    return function() {
        const self = this;

        let _modalService = null;
        let _promise = null;

        const _buttonClick = (key) => {
            _promise.resolve(key);
            _modalService.closeModal(self);
        };

        self.css = ko.observable('warning-modal');
        self.modal = ko.pureComputed(() => `${self.css()}__modal`);
        self.buttonsCss = ko.pureComputed(() => `${self.css()}__buttons`);
        self.textsCss = ko.pureComputed(() => `${self.css()}__texts`);
        self.iconCss = ko.pureComputed(() => `${self.css()}__icon ${self.iconAddCss}`);
        self.phoneNumbersCss = ko.pureComputed(() => `${self.css()}__numbers`);

        self.setCss = (css) => {
            self.css(css);
            return self;
        };

        self.icon = ko.observable('');
        self.iconAddCss = ko.observable('');
        self.showIcon = ko.pureComputed(() => self.icon() !== '');

        self.setIcon = ({icon = 'signupWarning', addCss = ''}) => {
            self.iconAddCss(addCss);
            self.icon(icon);
            return self;
        };

        self.heading = ko.observable({i18n: ''});
        self.setHeading = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__heading ${addCss}`);
            self.heading({i18n, css});
            return self;
        };

        self.html = ko.observable({htmlString: ''});
        self.setHtml = ({htmlString, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__html ${addCss}`);
            self.html({htmlString, css});
            return self;
        };

        self.texts = ko.observableArray([]);
        self.addText = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__text ${addCss}`);
            self.texts.push({i18n, css});
            return self;
        };

        self.buttons = ko.observableArray([]);
        self.addButton = ({i18n = 'ok', key = 'ok', addCss = '', isActive = true}) => {
            const css = isActive ?
                ko.pureComputed(() => `${self.css()}__button ${self.css()}__button-active ${addCss}`) :
                ko.pureComputed(() => `${self.css()}__button ${self.css()}__button-text ${addCss}`);
            self.buttons.push({i18n, key, css, click: () => _buttonClick(key)});
            return self;
        };

        self.phoneNumbers = ko.observableArray([]);
        self.showPhoneNumbers = ko.pureComputed(()=> self.phoneNumbers.length > 0);
        self.addPhoneNumber = ({number, addCss = '', isError = true}) => {
            const statusIcon = isError ? 'warningAttention' : 'warningCheckmark';
            const css = isError ?
                ko.pureComputed(() => `${self.css()}__number ${self.css()}__number-error ${addCss}`) :
                ko.pureComputed(() => `${self.css()}__number ${addCss}`);
            self.phoneNumbers.push({number, css, statusIcon});
            return self;
        };

        self.clearModal = () => {
            self.css('warning-modal');
            self.buttons.removeAll();
            self.html({htmlString: ''});
            self.texts.removeAll();
            self.phoneNumbers.removeAll();
            self.setIcon({icon: ''});
            self.setHeading({});
            return self;
        };

        self.showModal = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();
            _modalService.showModal(self);
            _promise = _promiseFactory.deferIndefinitely();
            return _promise;
        };
    };
});

