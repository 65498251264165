define('presentation/settings/userProfile/validators/confirmPasswordModalValidator',['common/promises/promiseFactory',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("password")
                .addValidationKeyUp(_commonValidator.isStringWithValue)
                .addValidationI18n(_commonValidator.isStringWithValue, 'passwordRequired')
                .addValidationI18n(_passwordCorrect, 'passwordInvalid');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _passwordCorrect = (password) => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.isPasswordCorrect(_viewModel.emailAddress, password)
                    .done(deferredObject.resolve)
                    .fail(deferredObject.reject);
            });
        };
    };
});

