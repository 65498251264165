/**
 Data Service Object
 Responsible for sending/receiving data through websockets

 @module externalDependencies/websocket

 See https://github.com/Automattic/socket.io-client/blob/0.9.17/README.md
 for socket.io.js documentation which applies equally to version 0.9.16.
 **/
define('externalDependencies/webSocket',['common/uniqueId/uniqueIdGenerator',
        'externalDependencies/configuration/webSocketConfiguration',
        'settings/settings',
        'common/logging/logger',
        'socketio',
        'common/promises/waitHandle'],
function() {
    var WebSocketSingleton = function() {
        if (WebSocketSingleton.prototype._singletonInstance) {
            return WebSocketSingleton.prototype._singletonInstance;
        }

        WebSocketSingleton.prototype._singletonInstance = this;
        var self = this;
        var _eventListeners = [];
        var _webSocketEventHandler = null;
        var _webSocket = null;
        var _logger = null;
        var SOCKET_CHANNEL = "message";
        var EVENT_CHANNEL = "event";
        var _reconnectCallbacks = [];

        var WaitHandleConstructor = require('common/promises/waitHandle');
        var _waitHandle = new WaitHandleConstructor();
        _waitHandle.setRedLight();

        var _onWebSocketMessage = function(data) {
            if (data.status === "success") {
                if (typeof _eventListeners[data.command_id].callback === "function") {
                    _eventListeners[data.command_id].callback(data.result);
                }
            } else {
                var error = new Error("Web Socket Message Received Error");
                error.data =  data;
                _logger.logError(error);
            }
            delete _eventListeners[data.command_id];
        };

        var _onWebSocketEvent = function(data) {
            var eventName = data.event_name;
            var eventData = data.event_data;
            if (typeof _webSocketEventHandler === "function") {
                _webSocketEventHandler(eventName, eventData);
            }
        };

        var _onConnected = function() {
            _waitHandle.setGreenLight();
        };

        var _onDisconnected = function() {
            _waitHandle.setRedLight();
        };

        var _onReconnected = function() {
            _reconnectCallbacks.forEach(function(callback) {
                callback();
            });
            _waitHandle.setGreenLight();
        };

        self.send = function(application, command, parameters, callback, priority) {
            _waitHandle.waitForSignal(function() {
                var CommandIdGeneratorConstructor = require('common/uniqueId/uniqueIdGenerator');
                var commandIdGenerator = new CommandIdGeneratorConstructor();
                var commandId = commandIdGenerator.generateUniqueIdWithoutHyphens();
                var payload = {
                    command_id: commandId,
                    application: application,
                    command: command,
                    parameters: parameters
                };

                _eventListeners[commandId] = {
                    "application": application,
                    "callback": callback,
                    "payload" : payload
                };

                _webSocket.emit(SOCKET_CHANNEL, payload);
            }, priority);
        };

        self.init = function() {
            if (_logger === null) {
                var LoggerConstructor = require('common/logging/logger');
                _logger = new LoggerConstructor();
                _logger.init();
            }
            if (_webSocket === null) {
                var config = require('externalDependencies/configuration/webSocketConfiguration');
                _webSocket = io.connect(config.webSocketServerUrl, {
                    'reconnect': true,
                    'reconnection delay': 500,
                    'max reconnection attempts': 20
                });
                _webSocket.on(SOCKET_CHANNEL, _onWebSocketMessage);
                _webSocket.on(EVENT_CHANNEL, _onWebSocketEvent);
                _webSocket.on('connect', _onConnected);
                _webSocket.on('disconnect', _onDisconnected);
                _webSocket.on('reconnect', _onReconnected);
            } else if(_webSocket.socket.connected === false && _webSocket.socket.connecting === false) {
                _webSocket.socket.reconnect();
            }
        };

        self.on = {};
        self.on.reconnect = function(callback) {
            _reconnectCallbacks.push(callback);
        };
        self.on.webSocketEvent = function(callback) {
            _webSocketEventHandler = callback;
        };
    };

    return new WebSocketSingleton();
});

