define('presentation/acceptUserInvite/viewModels/accountClosedViewModel',['persistence/dataProviders/customerServiceDataProvider'],
    function() {
        return function() {
            const self = this;

            const CustomerServiceDataProviderConstructor = require('persistence/dataProviders/customerServiceDataProvider');
            const customerServiceDataProvider = new CustomerServiceDataProviderConstructor();
            const _i18n = require('i18next');

            self.customerServicePhoneNumber = ko.observable("");
            self.notificationTitle(_i18n.t('acceptUserInvite:accountClosed'));
            self.notificationContent = ko.observable("");

            self.activate = () => {
                return _initialize();
            };

            const _initialize = () => {
                customerServiceDataProvider.getSupportPhone()
                    .fail((error) => {
                        throw error;
                    })
                    .done((customerServicePhoneNumber) => {
                        self.notificationContent(_i18n.t('acceptUserInvite:accountClosedMessage', {customerServicePhoneNumber: customerServicePhoneNumber.support_phone}));
                    });
            };
        };
    });
