define('presentation/settings/phoneNumbers/facades/addPhoneNumberLocalFacade',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket',
    'persistence/webSocket/webSocketApp',
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _smsRegistrationWebSocket = clientWebSocket.forApplication("smsRegistration");

    let _promiseFactory = null;
    let _webSocketApp = null;

    const _getSmsPhoneNumberLimits = () => {
        return _promiseFactory.defer((promise) => {
            _smsRegistrationWebSocket.send("getAccountSmsLimits", {}, (results, error) => {
                if (error) {
                    promise.reject(new Error(error));
                    return;
                }

                promise.resolve(results);
            });
        });
    };

    const _cancelReservations = (phoneNumbers) => _promiseFactory.deferIndefinitely(promise => {
        _webSocketApp.send("cancel_reservations", {phoneNumbers}, promise.resolve);
    });

    const _provisionNumbers = (phoneNumbers) => _promiseFactory.deferIndefinitely(promise => {
        _webSocketApp.send("provision_numbers", {phoneNumbers}, promise.resolve);
    });

    const _init = (promiseFactory = new PromiseFactoryConstructor()) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("add_phone_number_local");
    };

    return function() {
        const self = this;

        self.init = _init;

        self.getSmsPhoneNumberLimits = _getSmsPhoneNumberLimits;
        self.cancelReservations = _cancelReservations;
        self.provisionNumbers = _provisionNumbers;
    };
});

