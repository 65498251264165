define('presentation/acceptUserInvite/viewModels/invalidLinkViewModel',[
    'persistence/dataProviders/customerServiceDataProvider',
    'businessServices/router/router',
    'settings/navigationConfiguration'
],
function() {
    return function(isLinkExpired) {
        const self = this;
        const _isLinkExpired = isLinkExpired;
        const CustomerServiceInfoDataProviderConstructor = require('persistence/dataProviders/customerServiceDataProvider');
        const _customerServiceInfoDataProvider = new CustomerServiceInfoDataProviderConstructor();

        const _router = require('businessServices/router/router');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _i18n = require('i18next');

        self.notificationTitle = ko.observable("");
        self.notificationContent = ko.observable("");

        self.navigateToIndex = () => {
            _router.navigate(_navigationConfiguration.index);
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            if (_isLinkExpired === true) {
                self.notificationTitle(_i18n.t('invalidLink:expired'));
                _customerServiceInfoDataProvider.get()
                    .fail((error) => {
                        throw error;
                    })
                    .done((customerServicePhoneNumber) =>  {
                        self.notificationContent(_i18n.t('invalidLink:expiredMessage', {customerServicePhoneNumber: customerServicePhoneNumber.support_phone}));
                    });
            } else {
                self.notificationTitle(_i18n.t('invalidLink:invalid'));
                self.notificationContent(_i18n.t('invalidLink:invalidMessage'));
            }
        };
    };
});

