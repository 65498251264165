define('presentation/common/connectedTabCollection/viewModels/connectedTabCollectionViewModel',[],
function() {
    return function() {
        const self = this;

        let _settings = null;

        self.tabs = ko.observable([]);
        self.selectedTab = ko.observable();

        self.underlineWidth = ko.pureComputed(() => {
            return 1.0 / self.tabs().length;
        });

        self.underlineOffset = ko.pureComputed(() => {
            return self.underlineWidth() * self.selectedTabIndex();
        });

        self.selectedTabIndex = ko.pureComputed(() => {
            return self.tabs().findIndex((tab) => {
                return tab.id === self.selectedTab();
            });
        });

        self.onClick = (clickedOption) => {
            self.selectedTab(clickedOption.id);
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            self.tabs(ko.unwrap(_settings.tabs));

            if (ko.isObservable(_settings.selectedTab)) {
                self.selectedTab =  _settings.selectedTab;
            } else {
                self.selectedTab(_settings.selectedTab);
            }
        };
    };
});
