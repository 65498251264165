define('common/throttling/debounce',[],() => {
    // Adapted from throttle-debounce 2.1,
    // Project: https://github.com/niksy/throttle-debounce

    /**
     * 
     * @param {number} delay 
     * @param {boolean} noTrailing 
     * @param {() => void} callback 
     * @param {any} debounceMode 
     * @returns 
     */
    const debounce = function (delay, noTrailing, callback, debounceMode) {
        /*
         * After wrapper has stopped being called, this timeout ensures that
         * `callback` is executed at the proper times in `throttle` and `end`
         * debounce modes.
         */
        let timeoutID;
        let cancelled = false;
    
        // Keep track of the last time `callback` was executed.
        let lastExec = 0;
    
        // Function to clear existing timeout
        function clearExistingTimeout() {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        }
    
        // Function to cancel next exec
        function cancel() {
            clearExistingTimeout();
            cancelled = true;
        }
    
        // `noTrailing` defaults to falsy.
        if (typeof noTrailing !== 'boolean') {
            debounceMode = callback;
            callback = noTrailing;
            noTrailing = undefined;
        }
    
        /*
         * The `wrapper` function encapsulates all of the throttling / debouncing
         * functionality and when executed will limit the rate at which `callback`
         * is executed.
         */
        function wrapper() {
            const self = this;
            const elapsed = Date.now() - lastExec;
            const args = arguments;
    
            if (cancelled) {
                return;
            }
    
            // Execute `callback` and update the `lastExec` timestamp.
            function exec() {
                lastExec = Date.now();
                callback.apply(self, args);
            }
    
            /*
             * If `debounceMode` is true (at begin) this is used to clear the flag
             * to allow future `callback` executions.
             */
            function clear() {
                timeoutID = undefined;
            }
    
            if (debounceMode && !timeoutID) {
                /*
                 * Since `wrapper` is being called for the first time and
                 * `debounceMode` is true (at begin), execute `callback`.
                 */
                exec();
            }
    
            clearExistingTimeout();
    
            if (debounceMode === undefined && elapsed > delay) {
                /*
                 * In throttle mode, if `delay` time has been exceeded, execute
                 * `callback`.
                 */
                exec();
            } else if (noTrailing !== true) {
                /*
                 * In trailing throttle mode, since `delay` time has not been
                 * exceeded, schedule `callback` to execute `delay` ms after most
                 * recent execution.
                 *
                 * If `debounceMode` is true (at begin), schedule `clear` to execute
                 * after `delay` ms.
                 *
                 * If `debounceMode` is false (at end), schedule `callback` to
                 * execute after `delay` ms.
                 */
                timeoutID = setTimeout(
                    debounceMode ? clear : exec,
                    debounceMode === undefined ? delay - elapsed : delay
                );
            }
        }
    
        wrapper.cancel = cancel;
    
        // Return the wrapper function.
        return wrapper;
    };

    return debounce;
});
