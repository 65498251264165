define('presentation/settings/userProfile/facades/changeVerificationQAModalFacade',['persistence/webSocket/webSocketApp'],
function() {
    return function() {
        const self = this;

        let _promiseFactory = null;
        let _webSocketApp = null;

        self.getVerificationQA = () => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("getVerificationQA", {}, (result) => {
                    deferredObject.resolve(result);
                });
            });
        };

        self.saveVerificationQA = (verificationQuestion, verificationAnswer) => {
            return _promiseFactory.defer((deferredObject) => {
                const params = {
                    "verificationQuestion": verificationQuestion,
                    "verificationAnswer": verificationAnswer
                };

                _webSocketApp.send("saveVerificationQA", params, (result) => {
                    deferredObject.resolve();
                });
            });
        };

        self.init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const WebSocketAppConstruction = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstruction();
            _webSocketApp.init("user_profile");
        };
    };
});

