define('presentation/settings/accountProfile/validators/accountProfileValidator',['common/specifications/validEmailAddressSpecification',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        const _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _facade = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("howWillYouUseTresta")
                .addValidationI18n(_validateUseRequired, 'accountProfile:howYouWillUseTrestaRequired');
            validationRules.array("billingCommunicationEmailAddresses")
                .arrayField("value")
                .addValidationI18n(_validEmailAddressSpecification.isSatisfiedBy, 'emailAddressInvalid')
                .addValidationI18n(_isEmailAddressUnique, 'accountProfile:emailAddressNotUnique');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.resetValidation = () => {
            _viewModel.howWillYouUseTresta.resetValidation();
        };

        const _validateUseRequired = (howWillYouUseTresta) => {
            return _commonValidator.isStringWithValue(howWillYouUseTresta);
        };

        const _isEmailAddressUnique = (emailAddress) => {
            let emailAddressMatches = 0;
            _viewModel.billingCommunicationEmailAddresses().forEach((billingCommunicationEmailAddress) => {
                if (billingCommunicationEmailAddress.value() === emailAddress) {
                    emailAddressMatches++;
                }
            });
            return emailAddressMatches < 2;
        };
    };
});

