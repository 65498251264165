define('businessServices/converters/voicemailBoxIdConverter',[], function() {
    return function() {
        var self = this;

        var _getUserId = function(voicemailBoxId) {
            return _getEntityId(voicemailBoxId, 18);
        };

        var _getUserGroupId = function(voicemailBoxId) {
            return _getEntityId(voicemailBoxId, 23);
        };

        var _getForwardingNumberId = function(voicemailBoxId) {
            return _getEntityId(voicemailBoxId, 30);
        };

        var _getEntityId = function(voicemailBoxId, subStringStart) {
            if (voicemailBoxId === null || voicemailBoxId === undefined) {
                return "";
            }  else {
                return voicemailBoxId.substring(subStringStart, voicemailBoxId.length);
            }
        };

        self.getUserId = _getUserId;
        self.getUserGroupId = _getUserGroupId;
        self.getForwardingNumberId = _getForwardingNumberId;
    };
});

