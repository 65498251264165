/**
 * @module persistence/dataProviders/callRecordingDataProvider
 */
define('persistence/dataProviders/callRecordingDataProvider',['persistence/webSocket/webSocketApp'], function() {
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _webSocketApp = null;

        self.open = function(rowsPerPage, initialPageCount, initialFilter) {
            if (rowsPerPage === null || rowsPerPage === undefined || initialPageCount === null || initialPageCount === undefined){
                var err = new Error("Open Call Recordings, paramaters failed");
                
                err.rowsPerPage = rowsPerPage;
                err.initialPageCount = initialPageCount;
                err.initialFilter = initialFilter;

                throw err;
            }

            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "rows_per_page": rowsPerPage,
                    "initial_page_count": initialPageCount,
                    "review_status": initialFilter
                };

                _webSocketApp.send("open", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
            
        };

        /**
         * Send filter command
         * @param  {object} filterParameters Paramters to filter against
         * @return {promise}
         */
        self.filter = function(filterParameters) {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("filter", filterParameters, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        /**
         * Send next command
         * @return {promise}
         */
        self.next = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("next", null, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        /**
         * Send getURL command
         * @param  {string} callRecordingId Call recording id to retrieve audio for
         * @param  {string} audioType Type of audio to return. IE: mp3/wav
         * @return {promise}
         */
        self.getURL = function(callRecordingId, audioType) {
            if (callRecordingId === null || audioType === null) {
                 var err = new Error("Get URL, paramaters failed");
                
                err.callRecordingId = callRecordingId;
                err.audioType = audioType;

                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    var webSocketParams = {
                        "call_recording_id": callRecordingId,
                        "audio_type": audioType
                    };

                    _webSocketApp.send("getURL", webSocketParams, function(result) {
                        deferredObject.resolve(result);
                    });
                });
            }
        };

        /**
         * Send close command
         * @return {promise}
         */
        self.close = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("close", null, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        /**
         * Entry point for app
         * @return {void}
         */
        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("call_recording");
        };

        /**
         * Entry point for unit tests
         * @param  {webSocketApp} webSocketApp
         * @return {void}
         */
        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});
