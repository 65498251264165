define('presentation/common/answerTimeout/viewModels/answerTimeoutViewModel',['presentation/common/answerTimeout/presentationObjects/answerTimeoutPresentationObject'], function() {

    return function() {
        const self = this;
        let _settings = null;
        const AnswerTimeoutPresentationObjectConstructor = require('presentation/common/answerTimeout/presentationObjects/answerTimeoutPresentationObject');

        const _updateSelectedValue = function() {
            let options = self.options();
            let selectedOption = options.find((option) => {
                return option.isSelected();
            });

            if (selectedOption === undefined) { return; }

            self.selectedValue(selectedOption.value);
        };

        const _clearCurrentOptions = function() {
            let options = self.options();
            options.forEach((option) => {
                option.isSelected(false);
            });
        };

        const _isOptionSelected = function(optionValue) {
            return ko.unwrap(_settings.value) === optionValue;
        };

        const _setSelectedItem = function(option) {
            if (!option.isSelected()) {
                _clearCurrentOptions();
                option.isSelected(true);
            }
        };

        const _onValueChanged = function(newValue) {
            let optionsList = self.options();
            let selectedOption = optionsList.find((option) => {
                return option.value === newValue;
            });

            if (selectedOption === undefined) { return; }

            _setSelectedItem(selectedOption);
        };

        self.options = ko.observableArray([]);
        self.selectedValue = ko.observable();
        self.isExpanded = ko.observable(false);
        self.id = ko.observable();
        self.selectedTitle = ko.pureComputed(() => {
            let selectedOption = self.options().find((option) => {
                return option.value === self.selectedValue();
            });

            if (selectedOption !== undefined) {
                return selectedOption.title;
            }
        });

        self.openSelector = () => {
            self.isExpanded(true);
        };

        self.handleBackdropClick = () => {
            self.isExpanded(false);
        };

        self.onOptionClicked = function(option) {
            _setSelectedItem(option);
            _updateSelectedValue();
            self.isExpanded(false);
        };

        self.activate = function(settings) {
            _settings = settings;

            _initialize();
        };

        const _initialize = function() {
            if (_settings.value) {
                self.selectedValue = _settings.value;
                self.selectedValue.subscribe(_onValueChanged);
            }

            if (_settings.options) {
                const options = _settings.options.map(settingsOption => {
                    let option = new AnswerTimeoutPresentationObjectConstructor();
                    if (settingsOption.title) {
                        option.title = settingsOption.title;
                    }

                    if (settingsOption.value) {
                        option.value = settingsOption.value;
                        option.isSelected(_isOptionSelected(option.value));
                    }

                    return option;
                });

                self.options(options);
            }

            _updateSelectedValue();
        };
    };
});

