define('presentation/settings/autoAttendant/presentationObjects/routingRulePresentationObject',['i18next'],function() {
    return function(){
        let self = this;

        const _i18n = require('i18next');

        self.routeSearchData = ko.observable(null);
        self.routeType = ko.observable("");
        self.userAvatar = ko.observable(null);
        self.userGroupId = null;
        self.iconName = '';
        self.timeout = ko.observable(null);
        self.forwardingNumberPhoneNumber = null;
        self.currentVoicePrompt = ko.observable(null);
        self.conversionComplete = false;
        self.conversionInProgress = false;
        self.endWithHangup = ko.observable(false);
        self.endWithNoTimeout = ko.observable(false);
        self.showEndWithMessage = ko.computed(() => self.endWithHangup() || self.endWithNoTimeout());
        self.showSeparatorMessage = ko.computed(() => self.endWithHangup() === false && self.endWithNoTimeout() === false);
        self.endWithMessage = ko.pureComputed(() => this.endWithHangup() ? _i18n.t(`autoAttendant:hangup`) : _i18n.t(`autoAttendant:playRinging`));

        self.callFlowSeparatorMessage = ko.computed(() => {
            if (self.timeout()) {
                return _i18n.t('autoAttendant:timeoutSeparatorMessage');
            } else {
                return _i18n.t('autoAttendant:defaultSeparatorMessage');
            }
        });

        self.callFlowTimeoutMessage = ko.computed(() => {
            if (self.timeout()) {
                return self.timeout() + _i18n.t('autoAttendant:timeoutMessage');
            } else {
                return "";
            }
        });
    };
});
