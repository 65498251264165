define('presentation/contacts/viewModels/addContactViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/contacts/enumToLabelConverter',
    'businessServices/converters/birthdayFormatter',
    'businessServices/state/modelStateObserver',
    'common/collections/enumerationKeyValueConverter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'constants/contactAddressLabelEnumerations',
    'constants/contactAddressCountryLabelEnumerations',
    'constants/contactEmailAddressLabelEnumerations',
    'constants/contactPhoneNumberLabelEnumerations',
    'constants/contactFilterByOptions',
    'i18next',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/confirmActionViewModel',
    'presentation/common/actionModal/viewModels/duplicateContactActionViewModel',
    'presentation/contacts/facades/addContactFacade',
    'presentation/contacts/validators/addContactViewModelValidator',
    'presentation/contacts/viewModels/addContactAddressViewModel',
    'presentation/contacts/viewModels/addContactEmailAddressViewModel',
    'presentation/contacts/viewModels/addContactPhoneNumberViewModel',
    'presentation/contacts/viewModels/addContactUrlViewModel'
], function(
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type typeof import('businessServices/contacts/enumToLabelConverter') */
    EnumToLabelConverter,
    /** @type typeof import('businessServices/converters/birthdayFormatter') */
    BirthdayFormatter,
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type import('common/collections/enumerationKeyValueConverter') */
    _keyValueConverter,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/contactAddressLabelEnumerations') */
    ContactAddressLabel,
    /** @type typeof import('constants/contactAddressCountryLabelEnumerations') */
    ContactAddressCountryLabel,
    /** @type typeof import('constants/contactEmailAddressLabelEnumerations') */
    ContactEmailAddressLabel,
    /** @type typeof import('constants/contactPhoneNumberLabelEnumerations') */
    ContactPhoneNumberLabel,
    /** @type typeof import('constants/contactFilterByOptions') */
    ContactFilterByOptions,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
    ActionModal,
    /** @type typeof import('presentation/common/actionModal/viewModels/confirmActionViewModel') */
    ConfirmActionModal,
    /** @type typeof import('presentation/common/actionModal/viewModels/duplicateContactActionViewModel') */
    DuplicateContactActionModal,
    /** @type typeof import('presentation/contacts/facades/addContactFacade') */
    AddContactFacade,
    /** @type typeof import('presentation/contacts/validators/addContactViewModelValidator') */
    ValidatorConstructor,
    /** @type typeof import('presentation/contacts/viewModels/addContactAddressViewModel') */
    AddContactAddressViewModel,
    /** @type typeof import('presentation/contacts/viewModels/addContactEmailAddressViewModel') */
    AddContactEmailAddressViewModel,
    /** @type typeof import('presentation/contacts/viewModels/addContactPhoneNumberViewModel') */
    AddContactPhoneNumberViewModel,
    /** @type typeof import('presentation/contacts/viewModels/addContactUrlViewModel') */
    AddContactUrlViewModel
) {
    return function(parent, /** @type { string | null } */ accountContactId = null, /** @type { string | null } */ phoneNumber = null) {
        const self = this;

        const _actionModal = new ActionModal();
        const _parent = parent;
        const _phoneNumberFormatter = new PhoneNumberFormatter();

        /** @type { (typeof PromiseFactory)["prototype"] } */
        let _promiseFactory = null;

        /** @type import('presentation/contacts/facades/addContactFacade') */
        let _addContactFacade = null;

        /** @type import('presentation/contacts/validators/addContactViewModelValidator') */
        let _validator = null;

        const _enumToLabelConverter = new EnumToLabelConverter();
        const _birthdayFormatter = new BirthdayFormatter();

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        const MAX_ITEMS_CAN_ADD = 10;

        self.addressLabels = _keyValueConverter
            .convertToKeyValues(ContactAddressLabel)
            .map((option) => {
                const i18nKey = option.key.toLowerCase();
                return { key: i18n.t(`contacts:${i18nKey}`), value: option.value };
            });

        self.addressCountryLabels = _keyValueConverter
            .convertToKeyValues(ContactAddressCountryLabel)
            .map((option) => {
                const i18nKey = option.key.toLowerCase();
                return { key: i18n.t(`contacts:${i18nKey}`), value: option.value };
            });

        self.emailLabels = _keyValueConverter
            .convertToKeyValues(ContactEmailAddressLabel)
            .map((option) => {
                const i18nKey = option.key.toLowerCase();
                return { key: i18n.t(`contacts:${i18nKey}`), value: option.value };
            });

        self.phoneNumberLabels = _keyValueConverter
            .convertToKeyValues(ContactPhoneNumberLabel)
            .map((option) => {
                return { key: _enumToLabelConverter.getPhoneLabel(option.value), value: option.value };
            });

        self.sourceLabels = [
            {
                key: i18n.t('addContact:private'),
                value: false,
                disabled: false
            },
            {
                key: i18n.t('addContact:shared'),
                value: true,
                disabled: false,
                meta: ''
            }
        ];

        self.titleText = ko.pureComputed(() => {
            return self.accountContactId() ? i18n.t('addContact:editHeader') : i18n.t('addContact:header');
        });

        self.headerButtonText = ko.pureComputed(() => {
            return self.accountContactId() ? i18n.t('addContact:save') : i18n.t('addContact:add');
        });

        self.modelStateObserver = null;
        self.isInitialized = ko.observable(false);
        self.isCompositionComplete = ko.observable(false);
        self.isSystemAdmin = ko.pureComputed(() => sessionAccountInfo.isSystemAdmin());

        self.accountContactId = ko.observable('');
        self.firstName = ko.observable('').extend({observeState: true});
        self.lastName = ko.observable('').extend({observeState: true});
        self.company = ko.observable('').extend({observeState: true});
        self.jobTitle = ko.observable('').extend({observeState: true});
        self.birthday = ko.observable('').extend({observeState: true});
        self.notes = ko.observable('').extend({observeState: true});
        self.isShared = ko.observable(false).extend({observeState: true});
        self.createdDateTime = ko.observable('');
        self.modifiedDateTime = ko.observable('');

        self.phoneNumbers = ko.observableArray([]).extend({observeState: true});
        self.canAddAnotherNumber = ko.pureComputed(() => self.phoneNumbers().length < MAX_ITEMS_CAN_ADD);
        self.canDeleteNumber = ko.pureComputed(() => self.phoneNumbers().length > 0);
        self.addPhoneNumberBtnText = ko.pureComputed(() => {
            if (self.canAddAnotherNumber()) {
                return i18n.t('addContact:addPhoneNumber');
            } else {
                return i18n.t('addContact:disabledAddPhoneNumber');
            }
        });

        self.emailAddresses = ko.observableArray([]).extend({observeState: true});
        self.canAddAnotherEmail = ko.pureComputed(() => self.emailAddresses().length < MAX_ITEMS_CAN_ADD);
        self.canDeleteEmail = ko.pureComputed(() => self.emailAddresses().length > 0);
        self.addEmailAddressBtnText = ko.pureComputed(() => {
            if (self.canAddAnotherEmail()) {
                return i18n.t('addContact:addEmailAddress');
            } else {
                return i18n.t('addContact:disabledAddEmailAddress');
            }
        });

        self.addresses = ko.observableArray([]).extend({observeState: true});
        self.canAddAnotherAddress = ko.pureComputed(() => self.addresses().length < MAX_ITEMS_CAN_ADD);
        self.canDeleteAddress = ko.pureComputed(() => self.addresses().length > 0);
        self.addAddressBtnText = ko.pureComputed(() => {
            if (self.canAddAnotherAddress()) {
                return i18n.t('addContact:addAddress');
            } else {
                return i18n.t('addContact:disabledAddAddress');
            }
        });

        self.urls = ko.observableArray([]).extend({observeState: true});
        self.canAddAnotherUrl = ko.pureComputed(() => self.urls().length < MAX_ITEMS_CAN_ADD);
        self.canDeleteUrl = ko.pureComputed(() => self.urls().length > 0);
        self.addUrlBtnText = ko.pureComputed(() => {
            if (self.canAddAnotherUrl()) {
                return i18n.t('addContact:addUrl');
            } else {
                return i18n.t('addContact:disabledAddUrl');
            }
        });

        self.shouldScrollToTop = ko.observable(false);
        self.showValidationWarnings = ko.observable(false);
        self.showRequiredItemsWarning = ko.observable(false);
        self.validationWarningMessages = ko.pureComputed(() => {
            return [
                {
                    message: i18n.t('addContact:validation.requiredMessage'),
                    listItems: [i18n.t('addContact:validation.requiredItems')]
                }
            ];
        });

        self.cancelClick = () => {
            if (self.accountContactId() && self.modelStateObserver.isDirty()) {
                return _promiseFactory.defer(deferredObject => {
                    _actionModal
                        .clearModal()
                        .setContentViewModel(ConfirmActionModal, [i18n.t('addContact:discardConfirmation')])
                        .setHeaderText({i18n: 'addContact:discard'})
                        .setSubmitButtonText({i18n: 'discard'})
                        .setShouldScrollIntoView(false)
                        .showModal()
                        .fail(deferredObject.reject)
                        .done((actionModalResult) => {
                            if (actionModalResult === "cancel") {
                                deferredObject.resolve();
                            } else {
                                self.cancelForm();
                                deferredObject.resolve();
                            }
                        });
                });
            } else {
                self.cancelForm();
            }
        };

        self.cancelForm = () => {
            self.showRequiredItemsWarning(false);
            self.showValidationWarnings(false);

            if (self.firstName.isDirty) {
                self.firstName.forceUpdate(true);
            }
            if (self.lastName.isDirty) {
                self.lastName.forceUpdate(true);
            }
            if (self.company.isDirty) {
                self.company.forceUpdate(true);
            }
            if (self.jobTitle.isDirty) {
                self.jobTitle.forceUpdate(true);
            }
            if (self.birthday.isDirty) {
                self.birthday.forceUpdate(true);
            }
            if (self.notes.isDirty) {
                self.notes.forceUpdate(true);
            }

            self.phoneNumbers().map((n) => n.cancelForm());
            self.emailAddresses().map((e) => e.cancelForm());
            self.addresses().map((a) => a.cancelForm());
            self.urls().map((u) => u.cancelForm());

            self.modelStateObserver.restoreData();
            if (self.accountContactId()) {
                const contactUrl = `${_navigationConfiguration.routesById.contact.baseUrl}/${self.accountContactId()}`;
                _router.navigate(contactUrl);
            }
        };

        self.onDeleteContactClick = () => {
            if (self.accountContactId()) {
                return _promiseFactory.defer(deferredObject => {
                    _actionModal
                        .clearModal()
                        .setContentViewModel(ConfirmActionModal, [i18n.t('addContact:deleteConfirmation')])
                        .setHeaderText({i18n: 'addContact:delete'})
                        .setSubmitButtonText({i18n: 'delete'})
                        .setShouldScrollIntoView(false)
                        .showModal()
                        .fail(deferredObject.reject)
                        .done((actionModalResult) => {
                            if (actionModalResult === "cancel") {
                                deferredObject.resolve();
                            } else {
                                _addContactFacade.deleteContact(self.accountContactId());
                                _contactsState.setSelectedContactId(null);
                                _router.navigate(_navigationConfiguration.routesById.contacts.url);
                                deferredObject.resolve();
                            }
                        });
                });
            }
        };

        self.onAddPhoneNumberClick = () => {
            if (self.canAddAnotherNumber() === false) {
                return;
            }
            _addDefaultPhoneNumber();
        };

        self.onRemovePhoneNumberClick = (/** @type any */ numberToDelete) => {
            if (self.canDeleteNumber() === false) {
                return;
            }
            self.phoneNumbers.remove(numberToDelete);
        };

        self.onAddEmailAddressClick = () => {
            if (self.canAddAnotherEmail() === false) {
                return;
            }
            _addDefaultEmailAddress();
        };

        self.onRemoveEmailAddressClick = (/** @type any */ emailAddressToDelete) => {
            if (self.canDeleteEmail() === false) {
                return;
            }
            self.emailAddresses.remove(emailAddressToDelete);
        };

        self.onAddAddressClick = () => {
            if (self.canAddAnotherAddress() === false) {
                return;
            }
            _addDefaultAddress();
        };

        self.onRemoveAddressClick = (/** @type any */ addressToDelete) => {
            if (self.canDeleteAddress() === false) {
                return;
            }
            self.addresses.remove(addressToDelete);
        };

        self.onAddUrlClick = () => {
            if (self.canAddAnotherUrl() === false) {
                return;
            }
            _addDefaultUrl();
        };

        self.onRemoveUrlClick = (/** @type any */ urlToDelete) => {
            if (self.canDeleteUrl() === false) {
                return;
            }
            self.urls.remove(urlToDelete);
        };

        self.validate = () => {
            self.shouldScrollToTop(false);
            return _promiseFactory.defer((promise) => {
                _validator.validateMinimumRequiredFields()
                    .fail(promise.reject)
                    .done((/** @type { boolean } */ hasAtLeastOneRequiredField) => {
                        if (hasAtLeastOneRequiredField === false) {
                            self.showRequiredItemsWarning(true);
                            self.shouldScrollToTop(true);
                        }

                        let areAddressesValid = true;
                        _promiseFactory.deferredList(self.addresses(), (address) => {
                            return _promiseFactory.deferIndefinitely((addressPromise) => {
                                address.validate()
                                    .fail(addressPromise.reject)
                                    .done((/** @type { boolean } */ isAddressValid) => {
                                        areAddressesValid = areAddressesValid && isAddressValid;
                                        addressPromise.resolve(areAddressesValid);
                                    });
                            });
                        })
                            .fail(promise.reject)
                            .done((areAddressesValid) => {
                                const areAllAddressesValid = areAddressesValid.every((v) => v === true);
                                if (areAllAddressesValid === false) {
                                    self.showValidationWarnings(true);
                                }

                                _validator.validate()
                                    .fail(promise.reject)
                                    .done((/** @type { boolean } */  isFormValid) => {
                                        if (isFormValid === false) {
                                            self.showValidationWarnings(true);
                                        }
                                        promise.resolve(hasAtLeastOneRequiredField && areAllAddressesValid && isFormValid);
                                    });
                            });
                    });
            });
        };

        const _showDuplicateContactsModal = (/** @type { Array<IContactPresentationObject> } */ duplicateContacts) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                const isEdit = !!self.accountContactId();

                _actionModal
                    .clearModal()
                    .setHeaderText({i18n: 'duplicateContactActionModal:title'})
                    .setSubmitButtonText({i18n: isEdit ? 'save' : 'add'})
                    .setContentViewModel(DuplicateContactActionModal, [_saveContact, duplicateContacts, isEdit])
                    .showModal()
                        .fail(promise.reject)
                        .done(promise.resolve);
            });
        };

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                _removeDuplicateFieldsBeforeValidation();
                self.validate()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            promise.resolve();
                            return;
                        }

                        _removeEmptyFieldsBeforeSave();
                        const phoneNumbers = self.phoneNumbers().map((phoneNumber) => {
                            return _phoneNumberFormatter.toNumbers(phoneNumber.number().trim());
                        });
                        const emailAddresses = self.emailAddresses().map((e) => e.email().trim().toLowerCase());

                        const duplicateContacts = _addContactFacade.getDuplicateContacts(phoneNumbers, emailAddresses);
                        const filteredDuplicates = duplicateContacts.filter(c => c.accountContactId !== self.accountContactId());

                        if (filteredDuplicates.length > 0) {
                            _showDuplicateContactsModal(filteredDuplicates);
                            promise.resolve();
                        } else {
                            _saveContact()
                                .fail(promise.reject)
                                .done(promise.resolve);
                        }
                    });
            });
        };

        const _saveContact = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                /** @type { IValidContact } */
                const contact = {
                    accountContactId: self.accountContactId() || null,
                    firstName: self.firstName(),
                    lastName: self.lastName(),
                    company: self.company(),
                    jobTitle: self.jobTitle(),
                    birthday: _birthdayFormatter.formatBirthdayString(self.birthday()),
                    notes: self.notes(),
                    phoneNumbers: self.phoneNumbers().map((n) => {
                        return {
                            label: _enumToLabelConverter.getPhoneLabel(parseInt(n.label())).replace(' ', ''),
                            number: n.number()
                        };
                    }),
                    emailAddresses: self.emailAddresses().map((e) => {
                        return {
                            label: _enumToLabelConverter.getEmailLabel(parseInt(e.label())),
                            email: e.email()
                        };
                    }),
                    addresses: self.addresses().map((a) => {
                        return {
                            addressLineOne: a.addressLineOne(),
                            addressLineTwo: a.addressLineTwo(),
                            city: a.city(),
                            postalCode: a.postalCode(),
                            region: a.region(),
                            country: _enumToLabelConverter.getAddressCountryLabel(parseInt(a.country())),
                            label: _enumToLabelConverter.getAddressLabel(parseInt(a.label()))
                        };
                    }),
                    urls: self.urls().map((u) => {
                        return {
                            url: u.url()
                        };
                    }),
                    hasAvatar: false
                };

                const facadePromise = self.accountContactId() ?
                    _addContactFacade.updateContact(self.isShared(), contact):
                    _addContactFacade.createContact(self.isShared(), contact);

                facadePromise
                    .fail(promise.reject)
                    .done((result) => {
                        self.modelStateObserver.saveData();
                        self.isInitialized(false);
                        _contactsState.setSelectedContactId(result.accountContactId);

                        const contactUrl = `${_navigationConfiguration.routesById.contact.baseUrl}/${result.accountContactId}`;
                        _router.navigate(contactUrl);
                        promise.resolve();
                    });
            });
        };

        const _removeDuplicateFieldsBeforeValidation = () => {
            const duplicatePhoneNumbers = self.phoneNumbers().filter((phoneNumber, index) => {
                return self.phoneNumbers().find((n, i) => {
                    return  _phoneNumberFormatter.clean(n.number()) ===  _phoneNumberFormatter.clean(phoneNumber.number()) &&
                        n.label().toString() === phoneNumber.label().toString() &&
                        index !== 0 &&
                        index !== i;
                });
            });
            self.phoneNumbers.removeAll(duplicatePhoneNumbers);

            const duplicateEmailAddresses = self.emailAddresses().filter((email, index) => {
                return self.emailAddresses().find((e, i) => {
                    return e.email().trim() === email.email().trim() &&
                        e.label().toString()  === email.label().toString()  &&
                        index !== 0 &&
                        index !== i;
                });
            });
            self.emailAddresses.removeAll(duplicateEmailAddresses);

            const duplicateAddresses = self.addresses().filter((address, index) => {
                return self.addresses().find((a, i) => {
                    return a.addressLineOne().trim().toLowerCase() === address.addressLineOne().trim().toLowerCase() &&
                        a.addressLineTwo().trim().toLowerCase() === address.addressLineTwo().trim().toLowerCase() &&
                        a.city().trim().toLowerCase() === address.city().trim().toLowerCase() &&
                        a.region().trim().toLowerCase() === address.region().trim().toLowerCase() &&
                        a.postalCode().trim().toLowerCase() === address.postalCode().trim().toLowerCase() &&
                        a.country() === address.country() &&
                        a.label().toString()  === address.label().toString()  &&
                        index !== 0 &&
                        index !== i;
                });
            });
            self.addresses.removeAll(duplicateAddresses);

            const duplicateUrls = self.urls().filter((url, index) => {
                return self.urls().find((u, i) => {
                    return u.url().trim() === url.url().trim() &&
                        index !== 0 &&
                        index !== i;
                });
            });
            self.urls.removeAll(duplicateUrls);
        };

        const _removeEmptyFieldsBeforeSave = () => {
            const emptyAddedPhoneNumbers = self.phoneNumbers().filter((number) => {
                return number.number().trim() === "";
            });
            self.phoneNumbers.removeAll(emptyAddedPhoneNumbers);

            const emptyAddedEmailAddresses = self.emailAddresses().filter((email) => {
                return email.email().trim() === "";
            });
            self.emailAddresses.removeAll(emptyAddedEmailAddresses);

            const emptyAddedAddresses = self.addresses().filter((address) => {
                return address.addressLineOne().trim() === "" &&
                    address.addressLineTwo().trim() === "" &&
                    address.city().trim() === "" &&
                    address.region().trim() === "" &&
                    address.postalCode().trim() === "";
            });
            self.addresses.removeAll(emptyAddedAddresses);

            const emptyAddedUrls = self.urls().filter((url) => {
                return url.url().trim() === "";
            });
            self.urls.removeAll(emptyAddedUrls);
        };

        const _addDefaultPhoneNumber = () => {
            const number = new AddContactPhoneNumberViewModel(self);
            self.modelStateObserver.addChildObserver(number.modelStateObserver);
            self.phoneNumbers.push(number);
            _reIndexPhoneNumbers();
        };

        const _reIndexPhoneNumbers = () => {
            self.phoneNumbers().forEach((number, index) => number.index = index);
        };

        const _setPhoneNumbers = (/** @type { KnockoutObservableArray<IContactPhoneNumberPresentationObject> } */ phoneNumbers) => {
            if (phoneNumbers().length === 0) {
                _addDefaultPhoneNumber();
            } else {
                phoneNumbers().map((contactNumber, index) => {
                    const number = new AddContactPhoneNumberViewModel(self);
                    number.accountContactPhoneNumberId = contactNumber.accountContactPhoneNumberId;
                    number.label(_enumToLabelConverter.getPhoneEnumValue(ko.unwrap(contactNumber.label)).toString());
                    number.number(ko.unwrap(contactNumber.phoneNumber));
                    number.index = index;
                    self.modelStateObserver.addChildObserver(number.modelStateObserver);
                    self.phoneNumbers.push(number);
                });
            }
        };

        const _addDefaultEmailAddress = () => {
            const email = new AddContactEmailAddressViewModel(self);
            self.modelStateObserver.addChildObserver(email.modelStateObserver);
            self.emailAddresses.push(email);
        };

        const _setEmailAddresses = (/** @type { KnockoutObservableArray<IContactEmailAddressPresentationObject> } */ emailAddresses) => {
            if (emailAddresses().length === 0) {
                _addDefaultEmailAddress();
            } else {
                emailAddresses().map((contactEmail) => {
                    const email = new AddContactEmailAddressViewModel(self);
                    email.accountContactEmailAddressId = contactEmail.accountContactEmailAddressId;
                    email.label(_enumToLabelConverter.getEmailEnumValue(ko.unwrap(contactEmail.label)).toString());
                    email.email(ko.unwrap(contactEmail.emailAddress));
                    self.modelStateObserver.addChildObserver(email.modelStateObserver);
                    self.emailAddresses.push(email);
                });
            }
        };

        const _addDefaultAddress = () => {
            const address = new AddContactAddressViewModel(self);
            self.modelStateObserver.addChildObserver(address.modelStateObserver);
            self.addresses.push(address);
        };

        const _setAddresses = (/** @type { KnockoutObservableArray<IContactAddressPresentationObject> } */addresses) => {
            if (addresses().length === 0) {
                _addDefaultAddress();
            } else {
                addresses().map((contactAddress) => {
                    const address = new AddContactAddressViewModel(self);
                    address.label(_enumToLabelConverter.getAddressEnumValue(ko.unwrap(contactAddress.label)).toString());
                    address.country(_enumToLabelConverter.getAddressCountryEnumValue(ko.unwrap(contactAddress.countryName)));
                    address.accountContactAddressId = contactAddress.accountContactAddressId;
                    address.addressLineOne(ko.unwrap(contactAddress.addressLineOne));
                    address.addressLineTwo(ko.unwrap(contactAddress.addressLineTwo));
                    address.city(ko.unwrap(contactAddress.city));
                    address.region(ko.unwrap(contactAddress.regionName));
                    address.postalCode(ko.unwrap(contactAddress.postalCode));
                    self.modelStateObserver.addChildObserver(address.modelStateObserver);
                    self.addresses.push(address);
                });
            }
        };

        const _addDefaultUrl = () => {
            const url = new AddContactUrlViewModel(self);
            self.modelStateObserver.addChildObserver(url.modelStateObserver);
            self.urls.push(url);
        };

        const _setURLs = (/** @type { KnockoutObservableArray<IContactUrlPresentationObject> } */urls) => {
            if (urls().length === 0) {
                _addDefaultUrl();
            } else {
                urls().map((contactUrl) => {
                    const url = new AddContactUrlViewModel(self);
                    url.accountContactUrlId = contactUrl.accountContactUrlId;
                    url.url(ko.unwrap(contactUrl.url));
                    self.modelStateObserver.addChildObserver(url.modelStateObserver);
                    self.urls.push(url);
                });
            }
        };

        /** @param { string } accountContactId */
        const _populateExistingContactForm = (accountContactId) => {
            return _promiseFactory.defer((promise) => {

                _addContactFacade.getContactById(accountContactId)
                    .fail(promise.reject)
                    .done((contact) => {
                        const {
                            accountContactId,
                            firstName,
                            lastName,
                            jobTitle,
                            company,
                            addresses,
                            emailAddresses,
                            phoneNumbers,
                            urls,
                            birthday,
                            notes,
                            contactSource,
                            createdDateTime,
                            modifiedDateTime
                        } = contact;

                        self.accountContactId(accountContactId);
                        self.firstName(ko.unwrap(firstName));
                        self.lastName(ko.unwrap(lastName));
                        self.company(ko.unwrap(company));
                        self.jobTitle(ko.unwrap(jobTitle));
                        self.birthday(ko.unwrap(birthday));
                        self.notes(ko.unwrap(notes));
                        self.isShared(contactSource() !== ContactFilterByOptions.yourAddressBook);

                        _setPhoneNumbers(phoneNumbers);
                        _setEmailAddresses(emailAddresses);
                        _setAddresses(addresses);
                        _setURLs(urls);

                        self.createdDateTime(ko.unwrap(createdDateTime));
                        self.modifiedDateTime(ko.unwrap(modifiedDateTime));

                        promise.resolve();
                    });
            });
        };

        const _populateNewContactForm = (/** @type { string | null } */ phoneNumber = null) => {
            return _promiseFactory.defer((promise) => {
                const number = new AddContactPhoneNumberViewModel(self);
                if (phoneNumber) {
                    number.number(phoneNumber);
                }
                self.modelStateObserver.addChildObserver(number.modelStateObserver);
                self.phoneNumbers([number]);

                _addDefaultEmailAddress();
                _addDefaultAddress();
                _addDefaultUrl();

                promise.resolve();
            });
        };

        self.detached = () => {
            self.isCompositionComplete(false);
            self.isInitialized(false);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };


        self.activate = () => {
            _promiseFactory = new PromiseFactory();

            _addContactFacade = new AddContactFacade();
            _addContactFacade.init(_promiseFactory);

            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            if (self.isInitialized()) {
                self.modelStateObserver.commitData();
                _validator.registerViewModel(self);
                return _promiseFactory.wait();
            }

            self.modelStateObserver = new ModelStateObserver(self, true);
            self.isInitialized(true);

            if (!self.isSystemAdmin()) {
                self.sourceLabels[1].meta = i18n.t('addContact:disabledShared');
                self.sourceLabels[1].disabled = true;
            }

            let initPromiseFactory = new PromiseFactory();
            initPromiseFactory.defer((initPromise) => {

                let populatePromise;
                if (accountContactId) {
                    populatePromise = _populateExistingContactForm(accountContactId);
                } else if (phoneNumber) {
                    populatePromise = _populateNewContactForm(phoneNumber);
                } else {
                    populatePromise = _populateNewContactForm();
                }
                populatePromise.done(() => {
                    initPromise.resolve();
                });
            });

            _promiseFactory.defer((initializePromise) => {
                initPromiseFactory.wait()
                    .done(() => {
                        self.modelStateObserver.commitData();
                        self.modelStateObserver.isDirty.subscribe((/** @type { boolean } */ isDirty) => {
                            _parent.isDirty(isDirty);
                        });

                        _validator.registerViewModel(self);

                        initializePromise.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
