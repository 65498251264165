define('presentation/settings/messagingCompliance/validators/otpVerificationActionModalValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule'
], function(
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules
) {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/viewModels/otpVerificationActionModalViewModel') } OtpVerificationActionModalViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { OtpVerificationActionModalViewModel } */
        let _viewModel = null;

        self.registerViewModel = (/** @type { OtpVerificationActionModalViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.field("smsPin")
                .addValidationI18n(_isSmsPinValid, 'otpVerificationActionModal:smsPinRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _isSmsPinValid = (/** @type { any } */ smsPin) => {
            return _commonValidator.isStringWithValue(smsPin);
        };
    };
});

