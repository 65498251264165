define('presentation/acceptUserInvite/validators/createUserProfileValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'constants/validationMessageEnumerations',
    'common/specifications/validPasswordSpecification'
],
function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _facade = null;
        let _viewModel = null;
        let _commonValidator = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("firstName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'acceptUserInvite:firstNameRequired');
            validationRules.field("lastName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'acceptUserInvite:lastNameRequired');

            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };
    };
});

