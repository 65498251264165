define('presentation/settings/phoneNumbers/viewModels/editPhoneNumberInboundCallingTabViewModel',[
    'businessServices/authentication/featureToggleSettings',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'constants/routingRuleActionConstants',
    'presentation/common/routeToModal/viewModels/routeToViewModel',
    'presentation/common/routeToModal/common/routeToValidationHelper',
    'presentation/common/routeToModal/common/routingRuleBuilder',
    'presentation/common/routeToModal/strategies/connectorStrategy'
],
    function () {
        return function () {
            const self = this;

            const ConnectorSaveStrategyConstructor = require('presentation/common/routeToModal/strategies/connectorStrategy');
            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const RouteToViewModelConstructor = require('presentation/common/routeToModal/viewModels/routeToViewModel');
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const RouteToValidationHelperConstructor = require('presentation/common/routeToModal/common/routeToValidationHelper');
            const RoutingRuleBuilderConstructor = require('presentation/common/routeToModal/common/routingRuleBuilder');

            const _validationHelper = new RouteToValidationHelperConstructor();
            const _routingRuleBuilder = new RoutingRuleBuilderConstructor();
            const _routingRuleActionConstants = require('constants/routingRuleActionConstants');
            const _i18n = require('i18next');

            let _disposables = [];
            let _promiseFactory = new PromiseFactoryConstructor();

            const _validateRouteToViewModalCallback = ({skipValidation = false}) => {
                if (skipValidation) {
                    self.isCallflowValid(false);
                }
                else {
                    _validationHelper.validateRouteToViewModel(self.routeToViewModel())
                        .done(isValid => self.isCallflowValid(isValid));
                }
            };

            const _setupRouteTo = (routeToType, routeToData, validationCallback) => {
                self.routeToStrategy = new ConnectorSaveStrategyConstructor();
                self.routeToStrategy.init(self.phoneConnectorId);
                let routeToViewModel = new RouteToViewModelConstructor(routeToType, routeToData, validationCallback);
                routeToViewModel.isOptionNameEnabled = self.isOptionNameEnabled;
                routeToViewModel.optionName = self.optionName;
                routeToViewModel.routeTitle = _i18n.t('routeTo:whenReceivingCall');
                routeToViewModel.routeToOptions.subMenu = self.routeToOptions.subMenu;
                routeToViewModel.routeToOptions.autoAttendant = self.routeToOptions.autoAttendant;
                routeToViewModel.routeToStrategy = self.routeToStrategy;
                routeToViewModel.showAnswerTimeoutOptions(false);
                routeToViewModel.showRouteToTitle(true);
                routeToViewModel.showRouteToOptions(true);
                routeToViewModel.parentViewModel = ko.observable(self);

                self.routeToViewModel(routeToViewModel);

                routeToViewModel.modelStateObserver.commitData();

                _disposables.push(routeToViewModel.modelStateObserver.isDirty.subscribe(_updateIsRouteToDirty));

                self.isNewRoute = self.isInboundCallingEnabled() && routeToType === "prompt" && routeToData && routeToData.promptId && routeToData.promptId === "+ New Audio File";
            };

            const _updateIsRouteToDirty = () => {
                if (self.isCompositionComplete() && self.routeToViewModel().modelStateObserver.isDirty()) {
                    self.isRouteToDirty(true);
                }
            };

            const _shouldUpdateCallflow = () => {
                return self.isInboundCallingEnabled() && self.modelStateObserver.isDirty();
            };

            const _shouldUnassignCallflow = () => {
                return !self.isInboundCallingEnabled()  && self.modelStateObserver.isDirty();
            };

            self.addMode = false;
            self.isNewRoute = false;
            self.isInitialRender = true;
            self.isCallflowValid = ko.observable(true);
            self.isSaveButtonDisabled = ko.observable(false);
            self.isInboundCallingEnabled = ko.observable(false);
            self.isHumanDetectionEnabled = ko.observable(true);
            self.isRouteToDirty = ko.observable(false);
            self.initialInboundCallingValue = false;
            self.modelStateObserver = ko.observable(null);
            self.routeToViewModel = ko.observable(null);
            self.optionName = ko.observable(null).extend({observeState: true});
            self.phoneConnectorId = null;
            self.routeToStrategy = null;
            self.routeToOptions = {};
            self.routeToOptions.autoAttendant = { enabled: true };
            self.routeToOptions.forwardingNumber = { enabled: true };
            self.routeToOptions.prompt = { enabled: true };
            self.routeToOptions.subMenu = { enabled: false };
            self.routeToOptions.user = { enabled: true };
            self.routeToOptions.userGroup = { enabled: true };
            self.routeToOptions.schedule = { enabled: true };
            self.routeToOptions.voicemailBox = { enabled: true };
            self.routeToOptions.list = [];
            self.isCompositionComplete = ko.observable(false);
            self.humanDetectionToolTipIconText = _i18n.t('editPhoneNumber:humanDetectionTooltip');
            self.toggleMessageKey = ko.pureComputed(() => self.isInboundCallingEnabled() ? 'editPhoneNumber:enabled' : 'editPhoneNumber:disabled');

            self.showHumanDetectionToggle = ko.pureComputed(() => {
                return self.isInboundCallingEnabled();
            });

            self.validate = () => {
                return _promiseFactory.defer((deferredObject) => {
                    _validationHelper.validateRouteToViewModel(self.routeToViewModel())
                        .fail(deferredObject.reject)
                        .done((isValid) => {
                            self.isCallflowValid(isValid);
                            deferredObject.resolve(isValid);
                        });
                });
            };

            self.updateRouteTo = (routeToData) => {
                _setupRouteTo("routingRule", routeToData, _validateRouteToViewModalCallback);
            };

            self.getCallflowParticipants = () => {
                return _routingRuleBuilder.buildRoutingRuleParticipants(self.routeToViewModel());
            };

            self.getRoutingRule = () => {
                return _routingRuleBuilder.buildRoutingRule(self.routeToViewModel());
            };

            self.saveCallflow = () => {
                return _promiseFactory.defer(deferredObject => {
                    let saveCallflowResponse = {
                        routingRule: null,
                        response: null
                    };
                    
                    if (_shouldUnassignCallflow()) {
                        saveCallflowResponse.response = _routingRuleActionConstants.unassigned;
                        deferredObject.resolve(saveCallflowResponse);
                        return;
                    } else if (_shouldUpdateCallflow()) {
                        saveCallflowResponse.routingRule = _routingRuleBuilder.buildRoutingRule(self.routeToViewModel());
                        saveCallflowResponse.response = self.addMode ? _routingRuleActionConstants.created : _routingRuleActionConstants.updated;
                        deferredObject.resolve(saveCallflowResponse);
                        return;
                    }
                    saveCallflowResponse.response = _routingRuleActionConstants.unchanged;
                    deferredObject.resolve(saveCallflowResponse);
                });
            };

            self.detached = () => {
                _disposables.forEach((disposable) => {
                    disposable.dispose();
                });

                _disposables = [];

                self.isCompositionComplete(false);
            };

            self.compositionComplete = () => {
                if (self.isInitialRender) {
                    self.modelStateObserver.commitData();
                    if (self.isNewRoute) {
                        self.modelStateObserver.isDirty(true);
                    }

                    self.isInitialRender = false;
                }

                self.isCompositionComplete(true);
            };

            self.activate = (isInboundCallingEnabled, isHumanDetectionEnabled, routingRuleObject, phoneConnectorId, isRouteToDirty) => {
                let modelStateObserver = new ModelStateObserverConstructor(self);

                return _initialize(isInboundCallingEnabled, isHumanDetectionEnabled, routingRuleObject, phoneConnectorId, modelStateObserver, isRouteToDirty);
            };

            const _initialize = (isInboundCallingEnabled, isHumanDetectionEnabled, routingRuleObject, phoneConnectorId, modelStateObserver, isRouteToDirty) => {
                return _promiseFactory.defer((initPromise) => {
                    self.isInboundCallingEnabled = isInboundCallingEnabled;
                    self.isHumanDetectionEnabled = isHumanDetectionEnabled;
                    self.addMode = self.isInboundCallingEnabled() === false;
                    self.phoneConnectorId = phoneConnectorId;
                    self.modelStateObserver = modelStateObserver;
                    self.isRouteToDirty = isRouteToDirty;
                    let routeToData = routingRuleObject().routingRule() ? routingRuleObject().routingRule() : "";
                    let routeToType = routingRuleObject().routeToType() ? routingRuleObject().routeToType() : "";
                    self.initialRoutingRule = routeToData;
                    _setupRouteTo(routeToType, routeToData, _validateRouteToViewModalCallback);
                    initPromise.resolve();
                });
            };
        };
    });
