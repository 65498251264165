define('businessServices/state/modelStateObserver',['plugins/router',
        'common/promises/promiseFactory',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/unsavedChangesActionViewModel',
        'presentation/common/window/windowControl'],
    function() {
        /**
         * @class modelStateObserver
         * @constructor
         */
        return function(/** @type {any} */model, /** @type {boolean} */showUnsavedNavigationWarning, /** @type {any} */childModelStateObservers) {
            const self = this;

            const _router = require('plugins/router');
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            const UnsavedChangesActionViewModelConstructor = require('presentation/common/actionModal/viewModels/unsavedChangesActionViewModel');
            const _windowControl = require('presentation/common/window/windowControl');

            const _model = model;
            let _subscriptions = [];
            let _properties = [];
            let _hasCommitBeenCalled = false;
            let _showUnsavedNavigationWarning;
            if (showUnsavedNavigationWarning) {
                _showUnsavedNavigationWarning = true;
            } else {
                _showUnsavedNavigationWarning = false;
            }

            let _childModelStateObservers;
            if (childModelStateObservers) {
                if (Array.isArray(childModelStateObservers)) {
                    _childModelStateObservers = childModelStateObservers;
                } else {
                    _childModelStateObservers = [];
                    _childModelStateObservers.push(childModelStateObservers);
                }
            } else {
                _childModelStateObservers = [];
            }

            let _warningMessageKey = 'actionModal:unsavedChangesText';

            const _calculateViewModelIsDirty = () => {
                let isDirty = false;
                for (let i=0; i < _properties.length; i++) {
                    if (_properties[i].isDirty) {
                        isDirty = true;
                        break;
                    }
                }
                if (!isDirty) {
                    for (let k=0; k < _childModelStateObservers.length; k++) {
                        if (_childModelStateObservers[k].isDirty()) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                self.isDirty(isDirty);
            };

            const _disposeSubscriptions = () => {
                _subscriptions.forEach((subscription) => {
                    subscription.dispose();
                });
                _subscriptions = [];
                _properties = [];
            };

            const _observeProperty = (property) => {
                const propertyValue = property();
                if (Array.isArray(propertyValue)) {
                    _observeArray(property);
                } else {
                    property.committedValue = property();
                    property.isDirty = false;
                    property.restoreCommittedValue = () => {
                        property(property.committedValue);
                    };
                    _properties.push(property);
                    const subscription = property.subscribe((newValue) => {
                        if (property.committedValue === newValue) {
                            property.isDirty = false;
                        } else {
                            property.isDirty = true;
                        }
                        _calculateViewModelIsDirty();
                    });
                    _subscriptions.push(subscription);

                    if (propertyValue &&
                        (typeof propertyValue === "object")) {
                        const objectKeys = Object.keys(propertyValue);
                        objectKeys.forEach((key) => {
                            if (ko.isObservable(propertyValue[key]) &&
                                propertyValue[key].isObservedByModelStateObserver) {
                                _observeProperty(propertyValue[key]);
                            }
                        });
                    }
                }
            };

            const _observeArray = (property) => {
                const observableArrayItems = property();
                property.committedValue = observableArrayItems.slice(0);
                property.isDirty = false;
                property.restoreCommittedValue = () => {
                    property(property.committedValue.slice(0));
                };
                _properties.push(property);
                const arraySubscription = property.subscribe((newValue) => {
                    if (property.committedValue.length === newValue.length) {
                        property.isDirty = false;
                        for (let i = 0; i < property.committedValue.length; i++) {
                            let committedValueArrayObject = ko.unwrap(property.committedValue[i]);
                            let newValueArrayObject = ko.unwrap(newValue[i]);
                            if (committedValueArrayObject !== newValueArrayObject) {
                                property.isDirty = true;
                                break;
                            } else {
                                newValue[i] = property.committedValue[i];
                            }
                        }
                    } else {
                        property.isDirty = true;
                    }
                    _calculateViewModelIsDirty();
                });
                _subscriptions.push(arraySubscription);

                observableArrayItems.forEach((arrayItem) => {
                    if (ko.isObservable(arrayItem)) {
                        _observeProperty(arrayItem);
                    } else {
                        const arrayItemKeys = Object.keys(arrayItem);
                        arrayItemKeys.forEach((key) => {
                            if (ko.isObservable(arrayItem[key]) &&
                                arrayItem[key].isObservedByModelStateObserver) {
                                _observeProperty(arrayItem[key]);
                            }
                        });
                    }
                });
            };

            const _showDialog = () => {
                return _promiseFactory.defer(deferredObject => {
                    const actionModal = new ActionModalViewModelConstructor();
                    actionModal
                        .clearModal()
                        .setContentViewModel(UnsavedChangesActionViewModelConstructor, [{i18n: _warningMessageKey}])
                        .setHeaderText({i18n: 'unsavedChangesHeader'})
                        .setCancelButtonText({i18n: 'cancel'})
                        .setSubmitButtonText({i18n: 'continue'})
                        .showModal()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                });
            };

            const _addCanDeactivate = () => {
                if (_showUnsavedNavigationWarning === true) {
                    _model.canDeactivate = () => {
                        if (self.isDirty() && (self.navigateWithoutWarning === false)) {
                            const activeInstruction = _router.activeInstruction().fragment;
                            const NAVIGATE_AWAY = "yes";
                            _showDialog()
                                .done((response) => {
                                    if (response === NAVIGATE_AWAY) {
                                        self.navigateWithoutWarning = true;
                                        _deactivateWindowNavigationWarning();
                                        _router.navigate(activeInstruction);
                                    } else {
                                        self.navigateWithoutWarning = false;
                                    }
                                });
                            return false;
                        } else {
                            _deactivateWindowNavigationWarning();
                            return true;
                        }
                    };
                }
            };

            const _activateWindowNavigationWarning = () => {
                _windowControl.setConfirmationMessageOnNavigateAway("There are some unsaved changes. Navigating away from this page will discard any changes made.");
            };

            const _deactivateWindowNavigationWarning = () => {
                _windowControl.clearConfirmationMessageOnNavigateAway();
            };

            const _subscribeToIsDirty = () => {
                if (_showUnsavedNavigationWarning === true) {
                    const subscription = self.isDirty.subscribe(function(newValue) {
                        if (newValue === true) {
                            _activateWindowNavigationWarning();
                        } else {
                            _deactivateWindowNavigationWarning();
                        }
                    });
                    _subscriptions.push(subscription);
                }
            };

            const _subscribeChildStateObservers = () => {
                _childModelStateObservers.forEach((childModelStateObserver) => {
                    const subscription = childModelStateObserver.isDirty.subscribe(function(newValue) {
                        _calculateViewModelIsDirty();
                    });
                    _subscriptions.push(subscription);
                });
            };

            self.isDirty = ko.observable(true);
            self.isChild = false;
            self.hasSaveBeenCalled = false;
            self.navigateWithoutWarning = false;

            self.saveData = () => {
                self.hasSaveBeenCalled = true;
                self.commitData();
                _childModelStateObservers.forEach((childModelStateObserver) => {
                    childModelStateObserver.saveData();
                });
            };

            self.commitData = () => {
                _disposeSubscriptions();
                _deactivateWindowNavigationWarning();
                const viewModelKeys = Object.keys(_model);
                viewModelKeys.forEach((key) => {
                    if (ko.isObservable(_model[key]) &&
                        _model[key].isObservedByModelStateObserver) {
                        _observeProperty(_model[key]);
                    }
                });
                _childModelStateObservers.forEach((childModelStateObserver) => {
                    childModelStateObserver.commitData();
                });
                _calculateViewModelIsDirty();
                _addCanDeactivate();
                _subscribeToIsDirty();
                _subscribeChildStateObservers();
                _hasCommitBeenCalled = true;
            };

            self.restoreData = () => {
                if (_hasCommitBeenCalled) {
                    _properties.forEach((property) => {
                        property.restoreCommittedValue();
                    });
                }
                _childModelStateObservers.forEach((childModelStateObserver) => {
                    childModelStateObserver.restoreData();
                });
            };

            self.addChildObserver = (/** @type {any} */childObserver) => {
                childObserver.isChild = true;
                _childModelStateObservers.push(childObserver);
            };

            self.addChildObserverAndWatch = (/** @type {any} */childObserver) => {
                childObserver.isChild = true;
                _childModelStateObservers.push(childObserver);
                _subscribeChildStateObservers();
            };

            self.setWarningMessageKey = (/** @type {string} */warningMessageKey) => {
                _warningMessageKey = warningMessageKey;
            };
        };
    });

