define('presentation/settings/messagingCompliance/validators/messagingComplianceLocalMessagingTabValidator',[
    'businessServices/authentication/sessionAccountInfo',
    'common/collections/enumerationValidator',
    'common/converters/phoneNumberFormatConverter',
    'common/converters/phoneNumberFormatter',
    'constants/countryEnumerations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/validPhoneNumberSpecification'
], function(
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    _sessionAccountInfo,
    /** @type import('common/collections/enumerationValidator') */
    _enumerationValidator,
    /** @type typeof import('common/converters/phoneNumberFormatConverter') */
    PhoneNumberFormatConverter,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('constants/countryEnumerations') */
    _countries,
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules,
    /** @type typeof import('presentation/common/validation/validPhoneNumberSpecification') */
    ValidPhoneNumberSpecification
) {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/viewModels/messagingComplianceLocalMessagingTabViewModel') } MessagingComplianceLocalMessagingTabViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        const _validPhoneNumberSpecification = new ValidPhoneNumberSpecification();

        const _phoneNumberFormatConverter = new PhoneNumberFormatConverter();
        const _phoneNumberFormatter = new PhoneNumberFormatter();

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { MessagingComplianceLocalMessagingTabViewModel } */
        let _viewModel = null;

        const accountId = _sessionAccountInfo.accountId();

        self.registerViewModel = (/** @type { MessagingComplianceLocalMessagingTabViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.field("messageTextComplianceError")
                .addValidationI18n(_validateMessageTextCompliance, 'messagingComplianceLocalMessagingTab:textMessagingComplianceRequired');
            validationRules.field("summaryOfTexting")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceLocalMessagingTab:summaryOfTextingRequired')
                .addValidationI18n(_validateSummaryOfTexting, 'messagingComplianceLocalMessagingTab:summaryOfTextingInvalid');
            validationRules.field("sampleTextMessageOne")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceLocalMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageOne, 'messagingComplianceLocalMessagingTab:sampleTextMessageInvalid');
            validationRules.field("sampleTextMessageTwo")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceLocalMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageTwo, 'messagingComplianceLocalMessagingTab:sampleTextMessageInvalid');
            validationRules.field("sampleTextMessageThree")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceLocalMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageThree, 'messagingComplianceLocalMessagingTab:sampleTextMessageInvalid');
            validationRules.field("sampleTextMessageFour")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceLocalMessagingTab:sampleTextMessageRequired')
                .addValidationI18n(_validateSampleTextMessageFour, 'messagingComplianceLocalMessagingTab:sampleTextMessageInvalid');
           validationRules.field("customerConsentOther")
                .addValidationI18n(_validateCustomerConsentOtherRequired, 'messagingComplianceLocalMessagingTab:labelCustomerConsentOtherRequired')
                .addValidationI18n(_validateCustomerConsentOtherInvalid, 'messagingComplianceLocalMessagingTab:labelCustomerConsentOtherInvalid');
            validationRules.field("messageTextConsentError")
                .addValidationI18n(_validateMessageTextConsent, 'messagingComplianceLocalMessagingTab:textMessagingConsentRequired');
            validationRules.field("isUseEligibilityAcknowledged")
                .addValidationI18n(_validateUseEligibility, 'messagingComplianceLocalMessagingTab:useCaseEligibilityRequired');
            validationRules.field("mobilePhoneNumber")
                .addValidationI18n(_validateMobilePhoneNumber, 'messagingComplianceLocalMessagingTab:phoneNumberRequired')
                .addValidationI18n(_isPhoneNumberValid, 'messagingComplianceLocalMessagingTab:phoneNumberInvalid')
                .addValidationI18n(_isPhoneNumberNotOnThisAccount, 'messagingComplianceLocalMessagingTab:phoneNumberOnThisAccount');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        self.resetValidation = () => {
            _viewModel.messageTextComplianceError.resetValidation();
            _viewModel.summaryOfTexting.resetValidation();
            _viewModel.sampleTextMessageOne.resetValidation();
            _viewModel.sampleTextMessageTwo.resetValidation();
            _viewModel.sampleTextMessageThree.resetValidation();
            _viewModel.sampleTextMessageFour.resetValidation();
            _viewModel.customerConsentOther.resetValidation();
            _viewModel.messageTextConsentError.resetValidation();
            _viewModel.isUseEligibilityAcknowledged.resetValidation();
        };

        const _validateMessageTextCompliance = (messageTextComplianceError) => {
            return _viewModel.selectedUseCases().length > 0;
        };

        const _validateSummaryOfTexting = (summaryOfTexting) => {
            return _commonValidator.isStringWithLength(summaryOfTexting, 40, 3000);
        };

        const _validateSampleTextMessageOne = (sampleTextMessageOne) => {
            return _commonValidator.isStringWithLength(sampleTextMessageOne, 20, 3000);
        };

        const _validateSampleTextMessageTwo = (sampleTextMessageTwo) => {
            return _commonValidator.isStringWithLength(sampleTextMessageTwo, 20, 3000);
        };

        const _validateSampleTextMessageThree = (sampleTextMessageThree) => {
            return _commonValidator.isStringWithLength(sampleTextMessageThree, 20, 3000);
        };

        const _validateSampleTextMessageFour = (sampleTextMessageFour) => {
            return _commonValidator.isStringWithLength(sampleTextMessageFour, 20, 3000);
        };

        const _validateCustomerConsentOtherRequired = (customerConsentOther) => {
            if (_viewModel.isCustomerConsentOther()) {
                return _commonValidator.isStringWithValue(customerConsentOther);
            }
            return true;
        };

        const _validateCustomerConsentOtherInvalid = (customerConsentOther) => {
            if (_viewModel.isCustomerConsentOther()) {
                return _commonValidator.isStringWithLength(customerConsentOther, 40, 3000);
            }
            return true;
        };

        const _validateMessageTextConsent = (messageTextConsentError) => {
            if (!_viewModel.isCustomerConsentLeadCaptureForms() &&
                !_viewModel.isCustomerConsentInitiateConversation() &&
                !_viewModel.isCustomerConsentEmployeeRelationship() &&
                !_viewModel.isCustomerConsentOnlineCheckout() &&
                !_viewModel.isCustomerConsentPosTransaction() &&
                !_viewModel.isCustomerConsentOther()) {
                return false;
            }
            return true;
        };

        const _validateUseEligibility = (useEligibility) => {
            return _viewModel.isUseEligibilityAcknowledged();
        };

        const _validateMobilePhoneNumber = (/** @type { any } */ phoneNumber) => {
            if (!_viewModel.showMobilePhoneNumberField()) {
                return true;
            }

            return _commonValidator.isStringWithValue(phoneNumber);
        };

        const _isPhoneNumberValid = (/** @type { any } */ phoneNumber) => {
            if (!_viewModel.showMobilePhoneNumberField()) {
                return true;
            }

            const cleanPhoneNumber = _phoneNumberFormatConverter.clean(phoneNumber);
            return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countries.usa);
        };

        const _isPhoneNumberNotOnThisAccount = (/** @type { any } */ phoneNumber) => {
            if (!_viewModel.showMobilePhoneNumberField()) {
                return true;
            }

            const cleanNumber = _phoneNumberFormatter.toNumbers(phoneNumber);
            return !!_validPhoneNumberSpecification.isPhoneNumberOnThisAccount(accountId, cleanNumber);
        };

    };
});

