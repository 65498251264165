define('businessServices/router/authorizationPlugins/loginAsUserAuthorizationPlugin',[
    'common/promises/promiseFactory',
    'businessServices/authentication/authenticationManager',
    'businessServices/authentication/sessionWorkflowFlags'
], function() {
    function performImpersonation(impersonationNonce) {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.defer(function(promise) {
            var authenticationManager = require('businessServices/authentication/authenticationManager');
            authenticationManager.loginWithImpersonationNonce(impersonationNonce)
                .done(function(isSuccessful) {
                    var sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');
                    sessionWorkflowFlags.hasBypassedClosedAccount(false);
                    var navigationConfiguration = require('settings/navigationConfiguration');
                    if (isSuccessful) {
                        var valid = {
                            status : "success"
                        };
                        promise.resolve(valid);
                    } else {
                        var invalid = {
                            status : "failed"
                        };
                        promise.resolve(invalid);
                    }
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    function configureAuthorization(fragment) {
        var fragments = fragment.split('/');
        if (fragments.length === 2) {
            if (fragments[0] === "loginAsUser") {
                var impersonationNonce = fragments[1];
                return performImpersonation(impersonationNonce);
            }
        }
        return { status : "ignored" };
    }

    return {
        configureAuthorization : configureAuthorization
    };
});
