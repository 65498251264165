define('common/storage/cookieStorage',['settings/settings'],
    function(settings) {
        const ENV_DOMAIN = settings.environmentDomain + ".com";
        const ALL_SUBDOMAINS = "";
        const PATH = "/";
        const ONE_YEAR = 31536000;

        // MDN's Simple Cookie Framework v3.0
        const simpleCookies = {
            getItem: function (sKey) {
                if (!sKey) { return null; }
                return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
            },
            setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
                if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
                var sExpires = "";
                if (vEnd) {
                    switch (vEnd.constructor) {
                        case Number:
                            sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                            break;
                        case String:
                            sExpires = "; expires=" + vEnd;
                            break;
                        case Date:
                            sExpires = "; expires=" + vEnd.toUTCString();
                            break;
                    }
                }
                document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
                return true;
            },
            removeItem: function (sKey, sPath, sDomain) {
                if (!this.hasItem(sKey)) { return false; }
                document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
                return true;
            },
            hasItem: function (sKey) {
                if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
                return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
            },
            keys: function () {
                var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
                for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
                return aKeys;
            }
        };

        const buildFullDomain = (subdomain) => {
            return `${subdomain}.${ENV_DOMAIN}`;
        };

        const getValueForKey = (key) => {
            return simpleCookies.getItem(key);
        };

        const saveValueForKey = (key, value,  subdomain = ALL_SUBDOMAINS) => {
            const domain = buildFullDomain(subdomain);
            const stringifiedValue = JSON.stringify(value);
            return simpleCookies.setItem(key, stringifiedValue, ONE_YEAR, PATH, domain, true);
        };

        const deleteValueForKey = (key, subdomain = ALL_SUBDOMAINS) => {
            const domain = buildFullDomain(subdomain);
            return simpleCookies.removeItem(key, PATH, domain);
        };

        return {
            getValueForKey : getValueForKey,
            saveValueForKey : saveValueForKey,
            deleteValueForKey : deleteValueForKey
        };
    }
);

