define('presentation/shell/viewModels/shellViewModel',[
    'businessServices/events/eventManager',
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'constants/shellNameEnumerations'
], function () {
    return function () {
        const self = this;
        let _eventManager = null;
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _shellNameEnumerations = require('constants/shellNameEnumerations');

        const _updateShell = (isNavigateComplete) => {
            if (isNavigateComplete === true) {
                if (self.router.activeInstruction()) {
                    switch (self.router.activeInstruction().config.customSettings.template) {
                        case _shellNameEnumerations.appEntry:
                            self.shellViewModel('presentation/shell/viewModels/appEntryShellViewModel');
                            self.shellName(_shellNameEnumerations.appEntry);
                            break;

                        case _shellNameEnumerations.empty:
                            self.shellViewModel('presentation/shell/viewModels/emptyShellViewModel');
                            self.shellName(_shellNameEnumerations.empty);
                            break;

                        case _shellNameEnumerations.frontEnd:
                            self.shellViewModel('presentation/shell/viewModels/frontEndShellViewModel');
                            self.shellName(_shellNameEnumerations.frontEnd);
                            break;

                        case _shellNameEnumerations.gradient:
                            self.shellViewModel('presentation/shell/viewModels/gradientShellViewModel');
                            self.shellName(_shellNameEnumerations.gradient);
                            break;

                        case _shellNameEnumerations.interiorError:
                            self.shellViewModel('presentation/shell/viewModels/interiorErrorShellViewModel');
                            self.shellName(_shellNameEnumerations.interiorError);
                            break;

                        case _shellNameEnumerations.login:
                            self.shellViewModel('presentation/shell/viewModels/loginShellViewModel');
                            self.shellName(_shellNameEnumerations.login);
                            break;

                        default:
                            self.shellViewModel('presentation/shell/viewModels/interiorShellViewModel');
                            self.shellName(_shellNameEnumerations.interior);
                            break;
                    }
                    _eventManager.publishShellUpdated(self.shellName());
                }
            }
        };

        self.shellViewModel = ko.observable();

        self.shellName = ko.observable("");

        self.router = null;

        self.activate = () => {
            self.router = require('businessServices/router/router');
            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _activateRouter = () => {
            self.router.activate({ pushState: true })
                .done(() => {
                    self.router.customState.isActivated = true;
                })
                .fail((error) => {
                    throw error;
                });
        };

        const _initialize = () => {
            if (self.router.customState.isActivated === false) {
                if (self.router.customState.isNavigationBuilt()) {
                    _activateRouter();
                } else {
                    self.router.customState.isNavigationBuilt.subscribe(_activateRouter);
                }
            }

            self.router.customState.isNavigationComplete.subscribe(_updateShell);

            return _promiseFactory.wait();
        };
    };
});

