define('persistence/dataProviders/deviceDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_devices','GET')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(deviceId) {
            if (deviceId === null) {

                var err = new Error("deviceId not provided");
                throw err;

            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_devices/' + deviceId, 'GET')
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.deviceId = deviceId;
                            deferredObject.reject(error);
                        });
                });
            }
        };

        self.getByUserId = function(userId) {
            if (userId === null) {

                var err = new Error("userId not provided");
                throw err;

            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_devices', 'GET')
                        .done(function(result) {
                            var usersDevices = [];
                            for (var i = 0; i < result.data.data.length; i++) {
                                if (result.data.data[i].userId === userId) {
                                    usersDevices.push(result.data.data[i]);
                                }
                            }
                            deferredObject.resolve(usersDevices);
                        })
                        .fail(function(error) {
                            error.userId = userId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

