define('presentation/inbox/dataSources/voicemailSubscriptionDataSource',['common/promises/promiseFactory',
        'common/promises/waitHandle',
        'constants/voicemailMessageConstants',
        'externalDependencies/clientWebSocket',
    ],
    function(PromiseFactoryConstructor, WaitHandleConstructor, voicemailMessageConstants, clientWebSocket) {
        let subscriptionWebSocket = clientWebSocket.forApplication("voicemailSubscriptionDataSource");

        let promiseFactory = new PromiseFactoryConstructor();
        let waitForDataWaitHandle = new WaitHandleConstructor();
        waitForDataWaitHandle.setRedLight();

        subscriptionWebSocket.subscribeToEvent("voicemailMessageStatusChanged", (eventData) => {
            const {voicemailBoxId, voicemailMessageId, newStatus} = eventData;

            onVoicemailMessageStatusChanged(voicemailBoxId, voicemailMessageId, newStatus);
        });

        subscriptionWebSocket.subscribeToEvent("voicemailMessageDeleted", (eventData) => {
            const {voicemailBoxId, voicemailMessageId} = eventData;

            onVoicemailMessageDeleted(voicemailBoxId, voicemailMessageId);
        });

        subscriptionWebSocket.subscribeToEvent("voicemailMessageConverted", (eventData) => {
            const {voicemailBoxId, voicemailMessageId, status} = eventData;

            onNewVoicemailMessage(voicemailBoxId, voicemailMessageId, status);
        });

        subscriptionWebSocket.subscribeToEvent("voicemailBoxSubscriberSettingsChanged", (eventData) => {
            const {voicemailBoxId, newVoicemailMessageIds, isActiveSubscriber, isShowInSidebarEnabled} = eventData;

            onSubscriberSettingsChanged(voicemailBoxId, newVoicemailMessageIds, isActiveSubscriber, isShowInSidebarEnabled);
        });

        subscriptionWebSocket.subscribeToEvent("voicemailBoxDeleted", (eventData) => {
            const {voicemailBoxId} = eventData;

            onVoicemailBoxDeleted(voicemailBoxId);
        });

        subscriptionWebSocket.subscribeToEvent("voicemailBoxReactivated", (eventData) => {
            const {voicemailBoxId, newVoicemailMessageIds} = eventData;

            onVoicemailBoxReactivated(voicemailBoxId, newVoicemailMessageIds);
        });

        const onVoicemailMessageStatusChanged = (voicemailBoxId, voicemailMessageId, newStatus) => {
            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.voicemailBoxId === voicemailBoxId);
            if (foundSubscription) {
                let newMessageIds = foundSubscription.newVoicemailMessageIds();

                switch (newStatus) {
                    case voicemailMessageConstants.status.new.id:
                        if (newMessageIds.includes(voicemailMessageId) === false) {
                            foundSubscription.newVoicemailMessageIds.push(voicemailMessageId);
                        }
                        break;
                    case voicemailMessageConstants.status.reviewed.id:
                        if (newMessageIds.includes(voicemailMessageId)) {
                            foundSubscription.newVoicemailMessageIds.remove(voicemailMessageId);
                        }
                        break;
                }
            }
        };

        const onVoicemailMessageDeleted = (voicemailBoxId, voicemailMessageId) => {
            _removeVoicemailMessage(voicemailBoxId, voicemailMessageId);
        };

        const onNewVoicemailMessage = (voicemailBoxId, voicemailMessageId, status) => {
            if(status === voicemailMessageConstants.status.new.id) {
                _addVoicemailMessage(voicemailBoxId, voicemailMessageId);
            }
        };

        const onSubscriberSettingsChanged = (voicemailBoxId, newVoiceMessageIds, isActiveSubscriber, isShowInSidebarEnabled) => {
            if(isActiveSubscriber === true) {

                if (isShowInSidebarEnabled === true) {
                    _addSubscription(voicemailBoxId, newVoiceMessageIds);
                }
                else {
                    _removeSubscription(voicemailBoxId);
                }
            } else {
                _removeSubscription(voicemailBoxId);
            }
        };

        const onVoicemailBoxDeleted = (voicemailBoxId) => {
            _removeSubscription(voicemailBoxId);
        };

        const onVoicemailBoxReactivated = (voicemailBoxId, newVoiceMessageIds) => {
            _addSubscription(voicemailBoxId, newVoiceMessageIds);
        };

        const _addVoicemailMessage = (voicemailBoxId, voicemailMessageId) => {
            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.voicemailBoxId === voicemailBoxId);
            if (foundSubscription) {
                let newMessageIds = foundSubscription.newVoicemailMessageIds();
                if (newMessageIds.includes(voicemailMessageId) === false) {
                    foundSubscription.newVoicemailMessageIds.push(voicemailMessageId);
                }
            }
        };

        const _removeVoicemailMessage = (voicemailBoxId, voicemailMessageId) => {
            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.voicemailBoxId === voicemailBoxId);
            if (foundSubscription) {
                let newMessageIds = foundSubscription.newVoicemailMessageIds();
                if (newMessageIds.includes(voicemailMessageId) === true) {
                    foundSubscription.newVoicemailMessageIds.remove(voicemailMessageId);
                }
            }
        };

        const _removeSubscription = (voicemailBoxId) => {
            subscriptions.remove(function (subscription) {
                return subscription.voicemailBoxId === voicemailBoxId;
            });
        };

        const _addSubscription = (voicemailBoxId, newMessageIds) => {
            const newVoicemailMessageIds = ko.observableArray(newMessageIds);
            const newVoicemailMessageCount = ko.pureComputed(() => {
                return newVoicemailMessageIds().length;
            });

            const newSubscription = {
                voicemailBoxId: voicemailBoxId,
                newVoicemailMessageIds : newVoicemailMessageIds,
                newVoicemailMessageCount : newVoicemailMessageCount,
            };


            let subs = subscriptions();
            let foundSubscription = subs.find((sub) => sub.voicemailBoxId === voicemailBoxId);
            if(foundSubscription) {
                subscriptions.replace(foundSubscription, newSubscription);
            } else {
                subscriptions.push(newSubscription);
            }
        };

        const init = () => {
            return promiseFactory.defer((promise) => {
                subscriptionWebSocket.send("getInitialWebSubscriptions", {}, (data, errMsg) => {
                    if (errMsg) {
                        throw new Error(errMsg);
                    }

                    let subs = data.map((item) => {

                        const newVoicemailMessageIds = ko.observableArray(item.newVoicemailMessageIds);
                        const newVoicemailMessageCount = ko.pureComputed(() => {
                            return newVoicemailMessageIds().length;
                        });

                        return {
                            voicemailBoxId: item.voicemailBoxId,
                            newVoicemailMessageIds : newVoicemailMessageIds,
                            newVoicemailMessageCount : newVoicemailMessageCount,
                        };
                    });

                    subscriptions(subs);

                    waitForDataWaitHandle.setGreenLight();
                    promise.resolve();
                });
            });
        };
        
        const subscriptions = ko.observableArray([]);
        const totalNewMessages = ko.computed(() => {
            let total = 0;

            subscriptions().forEach((subscription) => {
                total = total + subscription.newVoicemailMessageCount();
            });
            return total;
        });

        const onLogin = () => {
            return init();
        };

        const onLogout = () => {
            waitForDataWaitHandle.setRedLight();
            subscriptions([]);
        };

        return {
            waitForDataWaitHandle,
            subscriptions,
            totalNewMessages,
            alerts : {
                receiveAlertLoginSuccessful : onLogin,
                receiveAlertLogout : onLogout,
            }
        };
    });
