define('presentation/messages/viewModels/fullScreenMediaModalViewModel',[
    'common/promises/promiseFactory',
    'constants/systemObjectIconNameConstants',
    'presentation/common/modal',
    'common/time/datetimeFormatter',
    'presentation/messages/facades/conversationFacade',
    'settings/navigationConfiguration',
    'businessServices/router/router',
    'businessServices/messages/messagesStateSingleton',
    'businessServices/browserSupport/browserType'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/systemObjectIconNameConstants') */
    systemObjectIconNames,
    /** @type typeof import('presentation/common/modal') */
    Modal,
    /** @type typeof import('common/time/datetimeFormatter') */
    DateTimeFormatter,
    /** @type typeof import('presentation/messages/facades/conversationFacade') */
    ConversationFacade,
    /** @type typeof import('settings/navigationConfiguration') */
    navigationConfiguration,
    /** @type typeof import('businessServices/router/router') */
    router,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    messagesState,
    /** @type typeof import('businessServices/browserSupport/browserType') */
    BrowserType
) {
    return function(showAllMedia) {
        /** @type import('presentation/messages/viewModels/fullScreenMediaModalViewModel') */
        const self = this;

        const _promiseFactory = new PromiseFactory();
        const _dateTimeFormatter = new DateTimeFormatter();
        const _facade = new ConversationFacade();

        /** @type import('presentation/common/modal') */
        let _modalService = null;

        /** @type JQuery.Deferred<any> */
        let _promise = null;

        const _attachments = ko.observableArray([]);
        const _selectedAttachmentId = ko.observable("");
        const _hasSelectedAttachment = ko.pureComputed(() => !!self.selectedAttachment());

        self.showAllMedia = showAllMedia;

        self.isDownloading = ko.observable(false);
        self.isModalOpen = ko.observable(false);
        self.requestNextMessagesPage = ko.observable(false);
        self.requestPreviousMessagesPage = ko.observable(false);
        self.isInvertedImageDirection = false;
        self.externalParticipantIcon = systemObjectIconNames.contact;

        self.selectedAttachment = ko.pureComputed(() => {
            return _attachments().find((attachmentObject) => {
                return attachmentObject.attachment.attachmentId() === _selectedAttachmentId();
            });
        });

        self.sentByUserAvatar = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return undefined;
            }
            return self.selectedAttachment().message.sendingUserAvatar;
        });

        self.showSentByUserAvatar = ko.pureComputed(() => {
            return _hasSelectedAttachment() && self.selectedAttachment().message.isSentByUserWithAvatar();
        });

        self.sentByUserInitials = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return null;
            }

            return self.selectedAttachment().message.sendingUserInitials;
        });

        self.showSentByUserInitials = ko.pureComputed(() => {
            return _hasSelectedAttachment() && self.selectedAttachment().message.isSentByUserWithoutAvatar();
        });

        self.showSentByExternalParticipantAvatar = ko.pureComputed(() => {
            return _hasSelectedAttachment() && self.selectedAttachment().message.isSentByExternalParticipant();
        });

        self.sentByMeta1 = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return null;
            }

            if (ko.isObservable(self.selectedAttachment().message.sentByName)) {
                return self.selectedAttachment().message.sentByName();
            }

            return self.selectedAttachment().message.sentByName;
        });

        self.sentByMeta2 = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return null;
            }
            return self.selectedAttachment().message.locationMetaText;
        });

        self.attachmentName = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return null;
            }
            const { attachment } = self.selectedAttachment();
            const extension = attachment.contentType().split("/").pop();
            return `${attachment.attachmentId()}.${extension}`;
        });

        self.messageDateTime = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return null;
            }
            return _dateTimeFormatter.formatDateForMediaModal(self.selectedAttachment().message.messageDateTime);
        });

        self.attachmentSrc = ko.pureComputed(() => {
            if (!_hasSelectedAttachment()) {
                return null;
            }
            return self.selectedAttachment().attachment.src();
        });

        self.videoAttachment = ko.pureComputed(() => {
           if (!self.isVideo()) {
               return null;
           }
           return self.selectedAttachment().attachment.videoPresentationObject();
        });

        self.isImage = ko.pureComputed(() => !_hasSelectedAttachment() ? false :  self.selectedAttachment().attachment.isImage());
        self.isVideo = ko.pureComputed(() => !_hasSelectedAttachment() ? false : self.selectedAttachment().attachment.isVideo());
        self.isPlayable = ko.pureComputed(() => !_hasSelectedAttachment() ? false : self.selectedAttachment().attachment.isPlayable());

        self.inSafari = ko.pureComputed(() => BrowserType.isSafari);

        self.onCreateNewDraftClicked = () => {
            _facade.createDraftAttachmentConversation(_selectedAttachmentId())
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    const { smsConversationDraftId } = response;
                    router.navigate(`${navigationConfiguration.routesById.newMessage.baseUrl}/${smsConversationDraftId}`);
                    messagesState.selectedConversationDraftId(smsConversationDraftId);
                    messagesState.selectedConversationId(null);
                    self.closeModal();
                });
        };

        self.onCloseClicked = () => {
            self.closeModal();
        };

        self.onDownloadClicked = () => {
            self.isDownloading(true);

            _facade.getAttachmentDownloadFilePath(_selectedAttachmentId())
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    const { downloadFilePath } = response;
                    const hiddenLink = document.createElement('a');
                    hiddenLink.setAttribute('href', downloadFilePath);
                    hiddenLink.setAttribute('download', self.attachmentName());
                    hiddenLink.dispatchEvent(new MouseEvent(`click`));

                    const oneSecond = 1000;
                    setTimeout(() => self.isDownloading(false), oneSecond);
                });
        };

        /**@returns {boolean}*/
        const _setNewSelectedAttachment = (/** @type {boolean}*/getNext) => {
            const currentAttachmentIndex = _attachments().findIndex((a) => a.attachment.attachmentId() === _selectedAttachmentId());
            const selectedAttachment = getNext ? _attachments()[currentAttachmentIndex - 1] : _attachments()[currentAttachmentIndex + 1];
            if (!selectedAttachment) {
                return false;
            }

            self.setSelectedAttachment(selectedAttachment.attachment.attachmentId());
            return true;
        };

        self.onLeftArrowClicked = () => {
            const getNext = self.isInvertedImageDirection;
            const updatedSelectedAttachment = _setNewSelectedAttachment(getNext);
            if (!updatedSelectedAttachment) {
                self.requestPreviousMessagesPage(true);
            }
        };

        self.onRightArrowClicked = () => {
            const getNext = !self.isInvertedImageDirection;
            const updatedSelectedAttachment = _setNewSelectedAttachment(getNext);
            if (!updatedSelectedAttachment) {
                self.requestNextMessagesPage(true);
            }
        };

        self.onViewInConversationClicked = () => {
            const conversationId = messagesState.selectedConversationId();
            const jumpToMessageId = self.selectedAttachment().message.smsConversationMessageId;
            router.navigate(`${navigationConfiguration.routesById.conversation.baseUrl}/${conversationId}/${jumpToMessageId}`);
            messagesState.selectedMessageId(jumpToMessageId);
            if (self.showAllMedia()) {
                self.showAllMedia(false);
            }
            self.closeModal();
        };

        /** @type self["setAttachments"] */
        self.setAttachments = (attachments) => {
            _attachments(attachments);
            return self;
        };

        /** @type self["updateAttachments"] */
        self.updateAttachments = (incomingAttachments) => {
            const newAttachments = incomingAttachments.filter((incomingAttachment) => {
               return !_attachments().some((existingAttachment) => {
                   return incomingAttachment.attachment.attachmentId() === existingAttachment.attachment.attachmentId();
               });
            });

            if (newAttachments.length) {
                _attachments().push.apply(_attachments(), newAttachments);
                _selectedAttachmentId(newAttachments[0].attachment.attachmentId());
            }

            self.requestPreviousMessagesPage(false);
            self.requestNextMessagesPage(false);
        };

        /** @type self["setSelectedAttachment"] */
        self.setSelectedAttachment = (attachmentId) => {
            _selectedAttachmentId(attachmentId);
            return self;
        };

        /** @type self["getSelectedAttachment"] */
        self.getSelectedAttachment = () => {
            return self.selectedAttachment();
        };

        /** @type self["setPagingRequests"] */
        self.setPagingRequests = (requestPreviousMessagesPage, requestNextMessagesPage) => {
            self.requestPreviousMessagesPage = requestPreviousMessagesPage;
            self.requestNextMessagesPage = requestNextMessagesPage;
            return self;
        };

        /** @type self["setInvertedImageDirection"] */
        self.setInvertedImageDirection = (isInverted) => {
            self.isInvertedImageDirection = isInverted;
            return self;
        };

        self.showModal = () => {
            _promise = _promiseFactory.deferIndefinitely();
            _facade.init(_promiseFactory);
            _modalService = new Modal();

            _modalService.showModal(self);

            self.isModalOpen(true);

            return _promise;
        };

        self.closeModal = () => {
            _promise.resolve();
            self.isModalOpen(false);
            _modalService.closeModal(self);
        };
    };
});

