define('presentation/settings/phoneNumbers/viewModels/editPhoneNumberViewModel',['businessServices/authentication/sessionAccountInfo',
        'businessServices/router/router',
        'businessServices/state/modelStateObserver',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/routingRuleActionConstants',
        'presentation/common/membershipSelection/viewModels/membershipSelectionViewModel',
        'presentation/common/subscriberSelection/viewModels/inheritedSubscriberSelectionViewModel',
        'presentation/tabs/presentationObjects/tabsPresentationObject',
        'presentation/settings/phoneNumbers/facades/editPhoneNumberFacade',
        'presentation/settings/phoneNumbers/validators/editPhoneNumberViewModelValidator',
        'presentation/settings/phoneNumbers/viewModels/editPhoneNumberCallRecordingTabViewModel',
        'presentation/settings/phoneNumbers/viewModels/editPhoneNumberInboundCallingTabViewModel',
        'presentation/settings/phoneNumbers/viewModels/editPhoneNumberOutboundDialTabViewModel',
        'presentation/settings/phoneNumbers/viewModels/editPhoneNumberTextMessagingTabViewModel',
        'settings/navigationConfiguration'
    ],
    function () {
        return function () {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');
            const EditPhoneNumberInboundCallingTabConstructor = require('presentation/settings/phoneNumbers/viewModels/editPhoneNumberInboundCallingTabViewModel');
            const EditPhoneNumberOutboundDialTabConstructor = require('presentation/settings/phoneNumbers/viewModels/editPhoneNumberOutboundDialTabViewModel');
            const EditPhoneNumberCallRecordingTabConstructor = require('presentation/settings/phoneNumbers/viewModels/editPhoneNumberCallRecordingTabViewModel');
            const EditPhoneNumberTextMessagingTabConstructor = require('presentation/settings/phoneNumbers/viewModels/editPhoneNumberTextMessagingTabViewModel');
            const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatter');
            const SorterConstructor = require('common/collections/collectionSorter');
            const _sorter = new SorterConstructor();
            const _commonState = require('common/storage/commonState');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _i18n = require('i18next');
            const _routingRuleActionConstants = require('constants/routingRuleActionConstants');

            const ADD_MEMBER_TITLE = _i18n.t('editPhoneNumber:addAnother');
            const REMOVE_ITEM_TITLE = _i18n.t('editPhoneNumber:revokePermission');
            const LAST_ITEM_REMOVE_TITLE = _i18n.t('editPhoneNumber:validationLastAuthorized');
            const LOG_TITLE = _i18n.t('editPhoneNumber:authorizedHistory');
            const SUBSCRIBER_LOG_PERMISSION_TITLES = {
                outboundCalling: _i18n.t('editPhoneNumber:outboundCallingTab'),
                callRecording: _i18n.t('editPhoneNumber:callRecordingTab'),
                sms: _i18n.t('editPhoneNumber:messaging')
            };

            let _promiseFactory = new PromiseFactoryConstructor();
            let _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
            let _router = require('businessServices/router/router');
            let _facade = null;
            let _validator = null;
            let _canCreateNewUsers = false;
            let _canCreateNewUserGroups = false;
            let _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();
            let _phoneNumberFormatted = null;
            let _initialCallflowParticipants = null;
            let _initialInheritedSubscribers = null;
            let _attendantAndScheduleParticipants = new Map();
            let _disposables = [];

            self.isCompositionComplete = ko.observable(false);
            self.phoneNumbersUrl = _navigationConfiguration.routesById.phoneNumbers.url;
            self.phoneConnectorId = null;
            self.hostedNumberRoutingRule = ko.observable();
            self.modelStateObserver = null;
            self.tabActive = ko.observable();
            self.tabMetadata = {
                inboundCalling: { id: "inboundCalling", isValid: ko.observable(true), title: _i18n.t('editPhoneNumber:inboundCallingTab') },
                outboundCalling: { id: "outboundCalling", isValid: ko.observable(true), title: _i18n.t('editPhoneNumber:outboundCallingTab') },
                callRecording: { id: "callRecording", isValid: ko.observable(true), title: _i18n.t('editPhoneNumber:callRecordingTab') },
                messaging: { id: "messaging", isValid: ko.observable(true), title: _i18n.t('editPhoneNumber:messagingTab') }
            };
            self.tabs = [
                new TabPresentationObjectConstructor(self.tabMetadata.inboundCalling.id, self.tabActive, self.tabMetadata.inboundCalling.isValid, self.tabMetadata.inboundCalling.title),
                new TabPresentationObjectConstructor(self.tabMetadata.outboundCalling.id, self.tabActive, self.tabMetadata.outboundCalling.isValid, self.tabMetadata.outboundCalling.title),
                new TabPresentationObjectConstructor(self.tabMetadata.callRecording.id, self.tabActive, self.tabMetadata.callRecording.isValid, self.tabMetadata.callRecording.title),
                new TabPresentationObjectConstructor(self.tabMetadata.messaging.id, self.tabActive, self.tabMetadata.messaging.isValid, self.tabMetadata.messaging.title)
            ];

            self.tabActive(self.tabs[0].id);
            self.isTabActive = (tabId) => {
                return self.tabActive() === tabId;
            };
            self.editPhoneNumberInboundCallingTabViewModel = null;
            self.editPhoneNumberOutboundDialTabViewModel = null;
            self.editPhoneNumberCallRecordingTabViewModel = null;
            self.editPhoneNumberTextMessagingTabViewModel = null;
            self.activeTabViewModel = ko.observable();
            self.phoneNumber = null;
            self.phoneNumberLocation = null;
            self.isLocalPhoneNumber = true;
            self.defaultPhoneNumberName = "";
            self.phoneNumberName = ko.observable("").extend({observeState: true});
            self.isInboundCallRecordingEnabled = ko.observable().extend({observeState: true});
            self.isOutboundCallRecordingEnabled = ko.observable().extend({observeState: true});
            self.isCallRecordingToggleEnabled = ko.observable().extend({observerState: true});
            self.currentNotificationId = ko.observable().extend({observeState: true});
            self.recordingNotificationSelected = ko.observable().extend({observeState: true});
            self.notificationList = ko.observable().extend({observeState: true});
            self.isInboundCallRecordingWarningVerified = ko.observable();
            self.isOutboundCallRecordingWarningVerified = ko.observable();
            self.currentCallRecordingSubscribers = ko.observable().extend({observeState: true});
            self.availableCallRecordingSubscribers = ko.observable().extend({observeState: true});
            self.isInboundCallingEnabled = ko.observable().extend({observeState: true});
            self.isOutboundCallingEnabled = ko.observable().extend({observeState: true});
            self.isSmsEnabled = ko.observable().extend({observeState: true});
            self.isHumanDetectionEnabled = ko.observable().extend({observeState: true});
            self.isOutboundCallingPermissionInherited = ko.observable().extend({observeState: true});
            self.isCallRecordingPermissionInherited = ko.observable().extend({observeState: true});
            self.isSmsPermissionInherited = ko.observable().extend({observeState: true});
            self.inheritedSubscriberList = ko.observable([]);
            self.initialCallflowParticipants = null;
            self.smsRegistrationStatus = ko.observable();

            self.isRouteToDirty = ko.observable(false).extend({observeState: true});

            self.invalidTabs = ko.pureComputed(() => {
                return self.tabs.reduce((accumulator, presentationObject) => {
                    if (!presentationObject.isTabValid()) {
                        accumulator.push(presentationObject.tabText);
                    }

                    return accumulator;
                }, []);
            });

            self.validationWarningMessages = ko.pureComputed(() => {
                return [
                    {
                        message: _i18n.t('editPhoneNumber:validationTabDescription'),
                        listItems: self.invalidTabs()
                    },
                    {
                        message: _i18n.t('editPhoneNumber:validationPleaseFix'),
                        listItems: []
                    }
                ];
            });

            self.save = () => {
                return _promiseFactory.defer((savePromise) => {
                    self.validate()
                        .fail(savePromise.reject)
                        .done((isValid) => {
                            if (isValid) {
                                self.subscriberSelectionViewModel.getSubscribers()
                                    .fail(savePromise.reject)
                                    .done((subscribers) => {
                                        self.authorizedDialersSelectionViewModel.getSubscribers()
                                            .fail(savePromise.reject)
                                            .done((authorizedDialers) => {
                                                authorizedDialers.subscribers = authorizedDialers.subscribers.map((authorizedDialer) => {
                                                    if (authorizedDialer.subscriberType === "userGroup") {
                                                        const userGroup = _commonState.get(authorizedDialer.id);
                                                        authorizedDialer.id = userGroup.id;
                                                    }
                                                    return authorizedDialer;
                                                });

                                                const permissionObject = {
                                                    isInboundCallingEnabled: self.isInboundCallingEnabled(),
                                                    isOutboundCallingEnabled: self.isOutboundCallingEnabled(),
                                                    isOutboundCallingPermissionInherited: self.isOutboundCallingPermissionInherited(),
                                                    isInboundCallRecordingEnabled: self.isInboundCallRecordingEnabled(),
                                                    isOutboundCallRecordingEnabled: self.isOutboundCallRecordingEnabled(),
                                                    isCallRecordingPermissionInherited: self.isCallRecordingPermissionInherited(),
                                                    isSmsEnabled: self.isSmsEnabled(),
                                                    isSmsPermissionInherited: self.isSmsPermissionInherited(),
                                                    isHumanDetectionEnabled: self.isHumanDetectionEnabled()
                                                };

                                                self.authorizedMessagersSelectionViewModel.getSubscribers()
                                                    .fail(savePromise.reject)
                                                    .done((authorizedMessagers) => {
                                                        self.editPhoneNumberInboundCallingTabViewModel.saveCallflow()
                                                            .fail(savePromise.reject)
                                                            .done((result) => {
                                                                const routingRule = result.routingRule;
                                                                const routingRuleAction = result.response;
                                                                const name = self.phoneNumberName().trim() === "" ? self.defaultPhoneNumberName : self.phoneNumberName();
                                                                const shouldUpdatePermissions = (routingRuleAction === _routingRuleActionConstants.unchanged) || (routingRuleAction === _routingRuleActionConstants.unassigned);
                                                                permissionObject.publishInboundDialingDisabled = routingRuleAction === _routingRuleActionConstants.unassigned;

                                                                _facade.savePhoneNumber(
                                                                    self.phoneConnectorId,
                                                                    name,
                                                                    self.currentNotificationId(),
                                                                    permissionObject,
                                                                    shouldUpdatePermissions,
                                                                    routingRule,
                                                                    routingRuleAction,
                                                                    subscribers,
                                                                    authorizedDialers,
                                                                    authorizedMessagers
                                                                )
                                                                    .fail(savePromise.reject)
                                                                    .done(() => {
                                                                        self.editPhoneNumberTextMessagingTabViewModel.sendFeeAutoNotation()
                                                                            .fail(savePromise.reject)
                                                                            .done(() => {
                                                                                self.modelStateObserver.saveData();
                                                                                savePromise.resolve();
                                                                                _router.navigate(self.phoneNumbersUrl);
                                                                            });
                                                                    });
                                                            });
                                                    });
                                            });
                                    });
                            } else {
                                savePromise.resolve();
                            }
                        });
                });
            };

            self.validate = () => {
                return _promiseFactory.defer((validatePromise) => {
                    let isSubscribersValid = null;
                    let isAuthorizedDialerValid = null;
                    let isValid = null;
                    let isInboundCallflowValid = null;
                    let isMessagingValid = null;

                    let validatePromiseFactory = new PromiseFactoryConstructor();
                    validatePromiseFactory.defer((subscriberValidationDeferredObject) => {
                        self.subscriberSelectionViewModel.validate()
                            .fail(subscriberValidationDeferredObject.reject)
                            .done((subscriberValidationResult) => {
                                isSubscribersValid = subscriberValidationResult;
                                subscriberValidationDeferredObject.resolve();
                            });
                    });

                    validatePromiseFactory.defer((authorizedDialerValidationPromise) => {
                        self.authorizedDialersSelectionViewModel.validate()
                            .fail(authorizedDialerValidationPromise.reject)
                            .done((authorizedDialerResult) => {
                                isAuthorizedDialerValid = authorizedDialerResult;
                                authorizedDialerValidationPromise.resolve();
                            });
                    });

                    validatePromiseFactory.defer((modalValidationDeferredObject) => {
                        _validator.validate()
                            .fail(modalValidationDeferredObject.reject)
                            .done((modalValidationResult) => {
                                isValid = modalValidationResult;
                                modalValidationDeferredObject.resolve();
                            });
                    });

                    validatePromiseFactory.defer((inboundCallflowPromise) => {
                        if (!self.isInboundCallingEnabled()) {
                            isInboundCallflowValid = true;
                            inboundCallflowPromise.resolve();
                            return;
                        }

                        self.editPhoneNumberInboundCallingTabViewModel.validate()
                            .fail(inboundCallflowPromise.reject)
                            .done((isValid) => {
                                isInboundCallflowValid = isValid;
                                inboundCallflowPromise.resolve();
                            });
                    });

                    validatePromiseFactory.defer((textMessagingPromise) => {
                        self.editPhoneNumberTextMessagingTabViewModel.validate()
                            .fail(textMessagingPromise.reject)
                            .done((isValid) => {
                                isMessagingValid = isValid;
                                textMessagingPromise.resolve();
                            });
                    });

                    validatePromiseFactory.wait()
                        .fail(validatePromise.reject)
                        .done(() => {
                            self.editPhoneNumberCallRecordingTabViewModel.showValidation(true);
                            self.tabMetadata.callRecording.isValid(isSubscribersValid && isValid);
                            self.tabMetadata.outboundCalling.isValid(isAuthorizedDialerValid);
                            self.tabMetadata.inboundCalling.isValid(isInboundCallflowValid);
                            validatePromise.resolve(isSubscribersValid && isAuthorizedDialerValid && isInboundCallflowValid && isMessagingValid && isValid);
                        });

                });
            };

            self.cancelForm = () => {
                self.modelStateObserver.navigateWithoutWarning = true;
                _router.navigateBack();
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.detached = () => {
                self.editPhoneNumberOutboundDialTabViewModel.dispose();
                self.editPhoneNumberCallRecordingTabViewModel.dispose();
                self.editPhoneNumberTextMessagingTabViewModel.dispose();

                _disposables.forEach(d => d.dispose());
                _disposables = [];
                _facade.dispose();
            };


            self.activate = (editId, tabId) => {
                self.editPhoneNumberInboundCallingTabViewModel = new EditPhoneNumberInboundCallingTabConstructor();
                self.editPhoneNumberOutboundDialTabViewModel = new EditPhoneNumberOutboundDialTabConstructor();
                self.editPhoneNumberCallRecordingTabViewModel = new EditPhoneNumberCallRecordingTabConstructor();
                self.editPhoneNumberTextMessagingTabViewModel = new EditPhoneNumberTextMessagingTabConstructor();
                const FacadeConstructor = require('presentation/settings/phoneNumbers/facades/editPhoneNumberFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                const RecordingSubscriberSelectionConstructor = require('presentation/common/subscriberSelection/viewModels/inheritedSubscriberSelectionViewModel');
                self.subscriberSelectionViewModel = new RecordingSubscriberSelectionConstructor();

                const AuthorizedDialerSelectionConstructor = require('presentation/common/subscriberSelection/viewModels/inheritedSubscriberSelectionViewModel');
                self.authorizedDialersSelectionViewModel = new AuthorizedDialerSelectionConstructor();

                const AuthorizedMessagersSelectionConstructor = require('presentation/common/subscriberSelection/viewModels/inheritedSubscriberSelectionViewModel');
                self.authorizedMessagersSelectionViewModel = new AuthorizedMessagersSelectionConstructor();

                const ValidatorConstructor = require('presentation/settings/phoneNumbers/validators/editPhoneNumberViewModelValidator');
                _validator = new ValidatorConstructor();

                const accountPermissions = _sessionAccountInfo.accountPermissions();
                _canCreateNewUsers = accountPermissions['SystemSettings.Users.Modify'];
                _canCreateNewUserGroups = accountPermissions['SystemSettings.UserGroups.Modify'];

                return _initialize(editId, tabId);
            };

            const _initialize = (editId, tabId) => {
                self.phoneConnectorId = editId;
                _promiseFactory.defer((initPromise) => {
                    _facade.getPhoneNumber(editId)
                        .fail(initPromise.reject)
                        .done((phoneNumberData) => {
                            self.phoneNumber = _phoneNumberFormatConverter.toInternationalWithParens(phoneNumberData.hostedPhoneNumber);
                            self.defaultPhoneNumberName = phoneNumberData.hostedPhoneNumberDefaultName;
                            self.phoneNumberLocation = _phoneNumberFormatConverter.buildLocation(phoneNumberData.hostedPhoneNumber, phoneNumberData.cityOfPurchase, phoneNumberData.regionOfPurchase);
                            self.isLocalPhoneNumber = !_phoneNumberFormatConverter.isTollFree(phoneNumberData.hostedPhoneNumber);
                            _initializeObservables(phoneNumberData)
                                .fail(initPromise.reject)
                                .done(() => {
                                    _initializeTabs(tabId)
                                        .fail(initPromise.reject)
                                        .done(() => {
                                            self.subscriberSelectionViewModel.setSubscribersLog(phoneNumberData.callRecordingSubscribersLog);
                                            self.authorizedDialersSelectionViewModel.setSubscribersLog(phoneNumberData.outboundCallingPermissionLog);
                                            self.authorizedMessagersSelectionViewModel.setSubscribersLog(phoneNumberData.smsAuthorizedMessagersLog);

                                            _initializeSubscriberInheritance(phoneNumberData);

                                            _validator.registerViewModel(self, _facade);
                                            self.modelStateObserver = new ModelStateObserverConstructor(self, true, [self.subscriberSelectionViewModel.modelStateObserver, self.authorizedDialersSelectionViewModel.modelStateObserver, self.authorizedMessagersSelectionViewModel.modelStateObserver, self.editPhoneNumberInboundCallingTabViewModel.modelStateObserver]);
                                            self.modelStateObserver.commitData();
                                            initPromise.resolve();
                                        });
                                });
                        });
                });

                return _promiseFactory.wait();
            };

            const _updateActiveTabViewModel = () => {
                _updateInheritedSubscriberList();

                let currentRoutingRule;

                if (self.editPhoneNumberInboundCallingTabViewModel.isRouteToDirty()) {
                    currentRoutingRule = self.editPhoneNumberInboundCallingTabViewModel.getRoutingRule();
                } else {
                    currentRoutingRule = self.editPhoneNumberInboundCallingTabViewModel.initialRoutingRule;
                }

                switch (self.tabActive()) {
                    case self.tabMetadata.inboundCalling.id:
                        if (self.isCompositionComplete()) {
                            self.editPhoneNumberInboundCallingTabViewModel.updateRouteTo(currentRoutingRule);
                        }
                        self.activeTabViewModel(self.editPhoneNumberInboundCallingTabViewModel);
                        break;
                    case self.tabMetadata.outboundCalling.id:
                        self.activeTabViewModel(self.editPhoneNumberOutboundDialTabViewModel);
                        break;
                    case self.tabMetadata.callRecording.id:
                        self.activeTabViewModel(self.editPhoneNumberCallRecordingTabViewModel);
                        break;
                    case self.tabMetadata.messaging.id:
                        self.activeTabViewModel(self.editPhoneNumberTextMessagingTabViewModel);
                        break;
                }
            };

            const _updateInheritedSubscriberList = () => {
                if (self.tabActive() === self.tabMetadata.inboundCalling.id) {
                    return;
                }

                const currentCallflowParticipantList = self.editPhoneNumberInboundCallingTabViewModel.getCallflowParticipants();
                const currentCallflowParticipants = new Set(currentCallflowParticipantList.map(p => p.id));

                if (_setEquality(currentCallflowParticipants, _initialCallflowParticipants)) {
                    self.inheritedSubscriberList(_initialInheritedSubscribers);
                    return;
                }

                const participants = currentCallflowParticipantList.filter((participant) => {
                    return (participant.type === _commonState.types.user) || (participant.type === _commonState.types.userGroup);
                });

                let uniqueParticipantIds = new Set(participants.map(p => p.id));

                const attendantOrSchedule = currentCallflowParticipantList.find(p => ((p.type === _commonState.types.autoAttendant) || (p.type === _commonState.types.schedule)));

                if (attendantOrSchedule === undefined) {
                    self.inheritedSubscriberList(_setSelectedItems(
                        [...uniqueParticipantIds].map((p) => {
                            return {id: p};
                        })
                    ));
                    return;
                }

                const cachedParticipants = _attendantAndScheduleParticipants.get(attendantOrSchedule.id);

                if (cachedParticipants !== undefined) {
                    cachedParticipants.forEach(cachedParticipant => uniqueParticipantIds.add(cachedParticipant.id));
                    self.inheritedSubscriberList(_setSelectedItems(
                        [...uniqueParticipantIds].map((p) => {
                            return {id: p};
                        })
                    ));
                    return;
                }

                _facade.getParticipantsForRouteObject(attendantOrSchedule.id, attendantOrSchedule.type)
                    .fail(() => {
                        self.inheritedSubscriberList(_setSelectedItems(participants));
                    })
                    .done((participants) => {
                        _attendantAndScheduleParticipants.set(attendantOrSchedule.id, participants);
                        participants.forEach(participant => uniqueParticipantIds.add(participant.id));
                        self.inheritedSubscriberList(
                            _setSelectedItems(
                                [...uniqueParticipantIds].map((p) => {
                                    return {id: p};
                                })
                            )
                        );
                    });
            };

            const _setEquality = (s1, s2) => {
                const isEitherArgumentNull = !s1 || !s2;

                if (isEitherArgumentNull || s1.size !== s2.size) {
                    return false;
                }

                return Array.from(s1).every(val => s2.has(val));
            };

            const _setSelectedItems = (subscribers) => {
                let selectedSubscribers = subscribers.map(subscriber => {
                    const commonStateItem = _commonState.get(subscriber.id);
                    if (commonStateItem.isUser) {
                        return Object.assign({}, subscriber, {
                            id: commonStateItem.id,
                            subscriberType: commonStateItem.type,
                            displayName: commonStateItem.name(),
                            avatar: commonStateItem.avatar()
                        });
                    }
                    if (commonStateItem.isUserGroup) {
                        return Object.assign({}, subscriber, {
                            id: _commonState.resolveGroupIdToGuid(commonStateItem.id),
                            subscriberType: commonStateItem.type,
                            displayName: commonStateItem.name(),
                            avatar: commonStateItem.avatars()
                        });
                    }
                });

                _sorter.sort(selectedSubscribers, 'displayName', true);

                return selectedSubscribers;
            };

            const _initializeObservables = (phoneNumberData) => {
                return _promiseFactory.defer((initObservablePromise) => {
                    _phoneNumberFormatted = _phoneNumberFormatConverter.toInternational(phoneNumberData.hostedPhoneNumber);
                    self.phoneNumberName(phoneNumberData.hostedPhoneNumberName === phoneNumberData.hostedPhoneNumberDefaultName ? "" : phoneNumberData.hostedPhoneNumberName);
                    self.isInboundCallRecordingEnabled(phoneNumberData.isInboundCallRecordingEnabled);
                    self.isOutboundCallRecordingEnabled(phoneNumberData.isOutboundCallRecordingEnabled);
                    self.isHumanDetectionEnabled(phoneNumberData.isHumanDetectionEnabled);
                    self.isInboundCallRecordingWarningVerified(phoneNumberData.isInboundCallRecordingWarningVerified);
                    self.isOutboundCallRecordingWarningVerified(phoneNumberData.isOutboundCallRecordingWarningVerified);
                    self.isCallRecordingToggleEnabled(self.isInboundCallRecordingEnabled() === true || self.isOutboundCallRecordingEnabled() === true);
                    self.notificationList(phoneNumberData.recordingNotifications);
                    self.currentNotificationId(phoneNumberData.recordingNotificationId);
                    self.isOutboundCallingEnabled(phoneNumberData.isOutboundCallingEnabled);
                    self.isSmsEnabled(phoneNumberData.isSmsEnabled);
                    self.hostedNumberRoutingRule(phoneNumberData.hostedNumberRoutingRule);
                    self.isInboundCallingEnabled(phoneNumberData.hostedNumberRoutingRule.routingRule() !== null);
                    self.isOutboundCallingPermissionInherited(phoneNumberData.isOutboundCallingPermissionInherited);
                    self.isCallRecordingPermissionInherited(phoneNumberData.isCallRecordingPermissionInherited);
                    self.isSmsPermissionInherited(phoneNumberData.isSmsPermissionInherited);
                    self.smsRegistrationStatus(phoneNumberData.smsRegistrationStatus);

                    let subscribersPromiseFactory = new PromiseFactoryConstructor();
                    subscribersPromiseFactory.defer((recordingNotificationsSubscriberInitPromise) => {
                        let selectedSubscribers = _setSelectedItems(phoneNumberData.selectedCallRecordingSubscribers());
                        self.subscriberSelectionViewModel.addItemTitle(ADD_MEMBER_TITLE);
                        self.subscriberSelectionViewModel.removeItemTitle(REMOVE_ITEM_TITLE);
                        self.subscriberSelectionViewModel.lastItemRemoveTitle(LAST_ITEM_REMOVE_TITLE);
                        self.subscriberSelectionViewModel.subscriberLogTitle(LOG_TITLE);
                        self.subscriberSelectionViewModel.subscriberLogPermissionTitle(SUBSCRIBER_LOG_PERMISSION_TITLES.callRecording);
                        self.subscriberSelectionViewModel.isInboundCallingEnabled = self.isInboundCallingEnabled;
                        self.subscriberSelectionViewModel.inheritedSubscriberList = self.inheritedSubscriberList;
                        self.subscriberSelectionViewModel.activate(selectedSubscribers, self.isCallRecordingToggleEnabled, self.isCallRecordingPermissionInherited)
                            .fail(recordingNotificationsSubscriberInitPromise.reject)
                            .done(recordingNotificationsSubscriberInitPromise.resolve);
                    });

                    subscribersPromiseFactory.defer((authorizedDialerInitPromise) => {
                        let selectedDialers = _setSelectedItems(phoneNumberData.selectedAuthorizedDialers());
                        self.authorizedDialersSelectionViewModel.addItemTitle(ADD_MEMBER_TITLE);
                        self.authorizedDialersSelectionViewModel.removeItemTitle(REMOVE_ITEM_TITLE);
                        self.authorizedDialersSelectionViewModel.lastItemRemoveTitle(LAST_ITEM_REMOVE_TITLE);
                        self.authorizedDialersSelectionViewModel.subscriberLogTitle(LOG_TITLE);
                        self.authorizedDialersSelectionViewModel.subscriberLogPermissionTitle(SUBSCRIBER_LOG_PERMISSION_TITLES.outboundCalling);
                        self.authorizedDialersSelectionViewModel.isInboundCallingEnabled = self.isInboundCallingEnabled;
                        self.authorizedDialersSelectionViewModel.inheritedSubscriberList = self.inheritedSubscriberList;
                        self.authorizedDialersSelectionViewModel.activate(selectedDialers, self.isOutboundCallingEnabled, self.isOutboundCallingPermissionInherited)
                            .fail(authorizedDialerInitPromise.reject)
                            .done(authorizedDialerInitPromise.resolve);
                    });

                    subscribersPromiseFactory.defer((authorizedMessagersInitPromise) => {
                        let selectedMessagers = _setSelectedItems(phoneNumberData.selectedAuthorizedMessagers());
                        self.authorizedMessagersSelectionViewModel.addItemTitle(ADD_MEMBER_TITLE);
                        self.authorizedMessagersSelectionViewModel.removeItemTitle(REMOVE_ITEM_TITLE);
                        self.authorizedMessagersSelectionViewModel.lastItemRemoveTitle(LAST_ITEM_REMOVE_TITLE);
                        self.authorizedMessagersSelectionViewModel.subscriberLogTitle(LOG_TITLE);
                        self.authorizedMessagersSelectionViewModel.subscriberLogPermissionTitle(SUBSCRIBER_LOG_PERMISSION_TITLES.sms);
                        self.authorizedMessagersSelectionViewModel.isInboundCallingEnabled = self.isInboundCallingEnabled;
                        self.authorizedMessagersSelectionViewModel.inheritedSubscriberList = self.inheritedSubscriberList;
                        self.authorizedMessagersSelectionViewModel.activate(selectedMessagers, self.isSmsEnabled, self.isSmsPermissionInherited)
                            .fail(authorizedMessagersInitPromise.reject)
                            .done(authorizedMessagersInitPromise.resolve);
                    });

                    subscribersPromiseFactory.wait()
                        .fail(initObservablePromise.reject)
                        .done(initObservablePromise.resolve);
                });
            };

            const _initializeTabs = (tabId) => {
                return _promiseFactory.defer((initTabsPromise) => {
                    let initTabsPromiseFactory = new PromiseFactoryConstructor();
                    initTabsPromiseFactory.defer((inboundCallingPromise) => {
                        self.editPhoneNumberInboundCallingTabViewModel.activate(self.isInboundCallingEnabled, self.isHumanDetectionEnabled, self.hostedNumberRoutingRule, self.phoneConnectorId, self.isRouteToDirty)
                            .fail(inboundCallingPromise.reject)
                            .done(inboundCallingPromise.resolve);
                    });
                    initTabsPromiseFactory.defer((outboundDialPromise) => {
                        self.editPhoneNumberOutboundDialTabViewModel.activate(self.isOutboundCallingEnabled, self.isOutboundCallingPermissionInherited, self.authorizedDialersSelectionViewModel)
                            .fail(outboundDialPromise.reject)
                            .done(outboundDialPromise.resolve);
                    });
                    initTabsPromiseFactory.defer((callRecordingPromise) => {
                        self.editPhoneNumberCallRecordingTabViewModel.activate(self.isInboundCallingEnabled, self.isCallRecordingToggleEnabled, self.isInboundCallRecordingEnabled, self.isOutboundCallRecordingEnabled, self.isCallRecordingPermissionInherited, self.currentNotificationId, self.notificationList, self.isInboundCallRecordingWarningVerified, self.recordingNotificationSelected, self.isOutboundCallRecordingWarningVerified, self.subscriberSelectionViewModel)
                            .fail(callRecordingPromise.reject)
                            .done(callRecordingPromise.resolve);
                    });
                    initTabsPromiseFactory.defer((textMessagingPromise) => {
                        self.editPhoneNumberTextMessagingTabViewModel.activate(self.phoneNumber, self.isLocalPhoneNumber, self.isSmsEnabled, self.isSmsPermissionInherited, self.authorizedMessagersSelectionViewModel, self.phoneConnectorId)
                            .fail(textMessagingPromise.reject)
                            .done(textMessagingPromise.resolve);
                    });

                    initTabsPromiseFactory.wait()
                        .fail(initTabsPromise.reject)
                        .done(() => {
                            if (self.tabs.some(t => t.id === tabId)) {
                                self.tabActive(tabId);
                            } else {
                                let firstTab = self.tabs.find(t => true);
                                self.tabActive(firstTab.id);
                            }

                            _updateActiveTabViewModel();
                            _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                            initTabsPromise.resolve();
                        });
                });
            };

            const _initializeSubscriberInheritance = (phoneNumberData) => {
                if (phoneNumberData.isOutboundCallingPermissionInherited) {
                    _setInitialInheritanceValues(phoneNumberData.selectedAuthorizedDialers());
                } else if (phoneNumberData.isCallRecordingPermissionInherited) {
                    _setInitialInheritanceValues(phoneNumberData.selectedCallRecordingSubscribers());
                } else if (phoneNumberData.isSmsPermissionInherited) {
                    _setInitialInheritanceValues(phoneNumberData.selectedAuthorizedMessagers());
                } else {
                    _setInitialInheritanceValues([]);
                }
            };

            const _setInitialInheritanceValues = (initialSubscribers) => {
                const formattedSubscribers = _setSelectedItems(initialSubscribers);
                self.inheritedSubscriberList(formattedSubscribers);
                _initialInheritedSubscribers = formattedSubscribers;
                _initialCallflowParticipants = new Set(initialSubscribers.map(s => s.id));
            };
        };
    });

