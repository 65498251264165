define('presentation/settings/billing/paymentMethod/viewModels/paymentMethodViewModel',['common/promises/promiseFactory',
        'common/collections/collectionSorter',
        'presentation/settings/billing/paymentMethod/facades/paymentMethodFacade',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/deleteActionViewModel',
        'constants/creditCardResponseConstants',
        'businessServices/events/eventManager',
        'presentation/settings/billing/paymentMethod/viewModels/addPaymentMethodViewModel',
        'presentation/common/actionModal/viewModels/deletePaymentMethodViewModel',
        'presentation/common/paymentMethod/validators/addPaymentMethodValidator',
        'presentation/common/modal'],

    function(){
        return function() {
            let self = this;

            const DeletePaymentMethodViewModelConstructor = require('presentation/common/actionModal/viewModels/deletePaymentMethodViewModel');
            const AddPaymentMethodConstructor = require('presentation/settings/billing/paymentMethod/viewModels/addPaymentMethodViewModel');
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const SorterConstructor = require('common/collections/collectionSorter');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _creditCardExpirationYear = require('constants/creditCardExpirationYearConstants');
            const _commonState = require('common/storage/commonState');
            const _promiseFactory = new PromiseFactoryConstructor();

            let _facade = null;
            let _router = null;
            let _addPaymentMethod = null;
            let _validator = null;

            const DELETE_RESPONSE = "yes";

            const _loadCards = () => _promiseFactory.defer((deferredObject) => {
                    _facade.getAllPaymentMethods()
                        .fail(deferredObject.reject)
                        .done((paymentMethods) => {
                            if (paymentMethods.length > 0) {
                                self.paymentMethods(paymentMethods);
                                _sortCards();
                                deferredObject.resolve();
                            } else {
                                deferredObject.resolve();
                            }
                        });
                });

            const _sortCards = () => {
                const sorter = new SorterConstructor();
                sorter.sort(self.paymentMethods, "lastFourDigits", true);
            };

            const _buildDeleteConfirmationModelContent = (paymentMethodInfo) => {
                const displayType = _commonState.typeDisplay.paymentMethod;
                let constructorParams = [paymentMethodInfo];

                self.actionModal
                  .clearModal()
                  .setContentViewModel(DeletePaymentMethodViewModelConstructor, constructorParams)
                  .setHeaderText({i18n: {key: 'deleteHeader', options: {type: displayType}}})
                  .setSubmitButtonText({i18n: 'delete'});
            };

            const _createExpirationYearList = () => {
                const currentYear = new Date().getFullYear();
                return Array.from(new Array(_creditCardExpirationYear.yearsFromCurrent), (val,index) => {
                    return {
                        text: currentYear + index,
                        id: currentYear + index,
                    };
                });
            };

            self.cardHolderName = ko.observable("");
            self.cardNumber = ko.observable("");
            self.cvvSecurityCode = ko.observable("");
            self.countrySelected = ko.observable("");
            self.monthSelected = ko.observable('');
            self.postalCode = ko.observable("");
            self.postalCodeMetaData = ko.pureComputed(() => self.countrySelected().hasProvinces ?
              {i18nKey: 'paymentMethod:postalCode', type: 'text', maxLength: 6} :
              {i18nKey: 'paymentMethod:zipCode', type: 'tel', maxLength: 5});
            self.expirationDate = ko.observable("");
            self.yearList = _createExpirationYearList();
            self.yearSelected = ko.observable(self.yearList.length > 0 ? self.yearList[0].value : "");
            self.paymentMethods = ko.observableArray([]);
            self.displayEditPaymentMethodScreen = ko.observable(false);
            self.currentlyEditablePaymentMethod = ko.observable('');
            self.isASinglePaymentMethod = ko.computed(() => self.paymentMethods().length === 1);
            self.isBalanceGreaterThanZero = ko.observable(false);
            self.addPaymentMethodUrl = _navigationConfiguration.routesById.addPaymentMethod.url;
            self.navigateToAddPaymentMethod = () => {
                _addPaymentMethod = new AddPaymentMethodConstructor();
                _addPaymentMethod.activate();
                _router.navigate(self.addPaymentMethodUrl);
            };

            self.navigateToEditPaymentMethod = (paymentMethod) => {
                const _editPaymentMethodUrl = _navigationConfiguration.routesById.editPaymentMethod.baseUrl + paymentMethod.paymentMethodId;
                _router.navigate(_editPaymentMethodUrl);
            };

            self.makePaymentMethodDefault = (paymentMethod) => {
                return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                        _facade.updatePaymentMethodDefaultStatus(paymentMethod)
                            .fail(deferredObject.reject)
                            .done(() => {
                                self.paymentMethods().forEach((payment) => {
                                    payment.isDefault(payment.paymentMethodId === paymentMethod.paymentMethodId);
                                });
                                deferredObject.resolve();
                            });
                });
            };

            self.shouldPreventDeletion = (paymentMethod) => {
                const hasOtherValidCards = self.paymentMethods().some((p) => {
                    return paymentMethod !== p && !p.isExpired();
                });

                if (!hasOtherValidCards) {
                    paymentMethod.isLastValidCard(true);
                    return true;
                } else {
                    paymentMethod.isLastValidCard(false);
                    return self.isASinglePaymentMethod() || paymentMethod.isDefault();
                }
            };

            self.deletePaymentMethod = (paymentMethod) => {
                return _promiseFactory.deferIndefinitely((deferredObject) => {
                    if (_isPaymentDeletable(paymentMethod)) {
                        _buildDeleteConfirmationModelContent(paymentMethod);
                        self.actionModal.showModal()
                          .done((response) => {
                              if (response === DELETE_RESPONSE) {
                                    _facade.deletePaymentMethod(self.paymentMethods(), paymentMethod.paymentMethodId)
                                        .done(() => {
                                            _loadCards()
                                                .done(deferredObject.resolve)
                                                .fail(deferredObject.reject);
                                        })
                                        .fail((error) => {
                                            error.paymentMethodId = paymentMethod.id;
                                            deferredObject.reject(error);
                                        });
                                } else {
                                    deferredObject.resolve();
                                }
                            })
                            .fail((error) => {
                                error.paymentMethod = paymentMethod;
                                deferredObject.reject(error);
                            });
                    }
                });
            };

            const _isPaymentDeletable = (currentPaymentMethod) => {
                if (currentPaymentMethod.isExpired()) {
                    return self.paymentMethods().length >= 2;
                }

                const anyOtherValidPaymentMethods = self.paymentMethods().some((paymentMethod) => {
                    return currentPaymentMethod !== paymentMethod && paymentMethod.isExpired() === false;
                });
                return anyOtherValidPaymentMethods;
            };

            self.activate = () => {
                const ValidatorConstructor = require('presentation/common/paymentMethod/validators/addPaymentMethodValidator');
                _validator = new ValidatorConstructor();

                _router = require('businessServices/router/router');

                const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
                self.actionModal = new ActionModalViewModelConstructor();

                const PaymentMethodFacade = require('presentation/settings/billing/paymentMethod/facades/paymentMethodFacade');
                _facade = new PaymentMethodFacade();
                _facade.init();

                return _initialize();
            };

            const _initialize = () => {
                _validator.registerViewModel(self);
                _loadCards();
                return _promiseFactory.wait();
            };
        };
});

