define('persistence/dataProviders/resetPasswordDataProvider',['externalDependencies/kazooApi',
        'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.validateLink = function(emailAddress, url, signature) {
            var err = null;

            if (url === null) {
                err = new Error("Missing url");
                throw err;
            } else if (signature === null) {
                err = new Error("Missing signature");
                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    var params = { "data": {
                                                "emailAddress": emailAddress,
                                                "url": url,
                                                "signature": signature
                                            }
                                 };

                    _kazooApi.unauthenticatedCall('lobby_user_auth/validateForgotPassword', 'POST', params)
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

