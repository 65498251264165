define('presentation/settings/prompts/validators/addPromptViewModelValidator',[
    'businessServices/converters/textConverter',
    'constants/audioInputOptionConstants',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const TextConverterConstructor = require('businessServices/converters/textConverter');
        const _textConverter = new TextConverterConstructor();

        const _audioInputOptionConstants = require('constants/audioInputOptionConstants');

        const PROMPT_NAME_NONE = "[none]";
        const PROMPT_NAME_NEWFORM = "[+newprompt]";
        const PROMPT_NAME_DEFAULT = "[default]";
        const VOICEPROMPT_MIN_LENGTH = 50; // 50 bytes, which is usually just header information
        const VOICEPROMPT_MAX_LENGTH = 4194304; // 4Mb

        let _commonValidator = null;
        let _validateFileSize = true;
        let _voicePromptViewModel;
        let _voicePromptFacade;

        self.registerViewModel = (voicePromptViewModel, voicePromptFacade) => {
            _voicePromptViewModel = voicePromptViewModel;
            _voicePromptFacade = voicePromptFacade;

            let validationRules = new ValidationRulesConstructor();
            validationRules.field("promptName")
                .addValidationI18n(_validatePromptNameRequired, 'addAudioFile:promptNameRequired')
                .addValidationI18n(_validatePromptNameValid, 'addAudioFile:promptNameInvalid')
                .addValidationI18n(_validatePromptNameUnique, 'addAudioFile:promptNameNotUnique')
                .addValidationI18n(_validatePromptNameLocallyUnique, 'addAudioFile:promptNameNotLocallyUnique');
            validationRules.field("promptFile")
                .addValidationI18n(_validatePromptFileRequired, 'addAudioFile:promptAudioUploadRequired')
                .addValidationI18n(_validatePromptRecordingRequired, 'addAudioFile:promptAudioRecordingRequired')
                .addValidationI18n(_validatePromptFileType, 'addAudioFile:promptAudioUploadTypeInvalid')
                .addValidationI18n(_validatePromptFileSizeTooSmall, 'addAudioFile:promptAudioUploadTypeInvalid')
                .addValidationI18n(_validatePromptFileSizeTooBig, 'addAudioFile:promptAudioUploadSizeExceedsMax');

            _commonValidator = new CommonValidatorConstructor(_voicePromptViewModel, validationRules, true);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.resetValidation = () => {
            _voicePromptViewModel.promptName.resetValidation();
            _voicePromptViewModel.promptFile.resetValidation();
        };

        const _validatePromptNameRequired = (promptName) => {
            return _commonValidator.isStringWithValue(promptName);
        };

        const _validatePromptNameValid = (promptName) => {
            let trimmedPromptName = _textConverter.removeAllWhiteSpaces(promptName);
            switch(trimmedPromptName.toLowerCase()) {
                case PROMPT_NAME_NONE:
                case PROMPT_NAME_NEWFORM:
                case PROMPT_NAME_DEFAULT:
                    return false;
            }
            return true;
        };

        const _validatePromptNameUnique = (promptName) => {
            if (!_commonValidator.isStringWithValue(promptName)) {
                return true;
            }

            return _voicePromptFacade.isPromptNameUnique(_voicePromptViewModel.voicePromptId, promptName);
        };

        const _validatePromptNameLocallyUnique = (promptName) => {
            if (_voicePromptViewModel.inlinePromptNames !== undefined) {
                return _voicePromptViewModel.inlinePromptNames.isNameUnique(promptName);
            } else {
                return true;
            }
        };

        const _validatePromptFileRequired = () => {
            const promptMethod = _voicePromptViewModel.selectedPromptMethod();
            if (promptMethod === _audioInputOptionConstants.upload) {
                const voicePrompt = _voicePromptViewModel.promptFile();
                const voicePromptId = _voicePromptViewModel.voicePromptId;
                if ((voicePrompt === "") && (voicePromptId === null)) {
                    return false;
                }
            }
            return true;
        };

        const _validatePromptRecordingRequired = () => {
            const promptMethod = _voicePromptViewModel.selectedPromptMethod();
            if (promptMethod === _audioInputOptionConstants.record) {
                const voicePrompt = _voicePromptViewModel.promptFile();
                const voicePromptId = _voicePromptViewModel.voicePromptId;
                if ((voicePrompt === "") && (voicePromptId === null)) {
                    return false;
                }
            }
            return true;
        };

        const _validatePromptFileType = () => {
            const promptMethod = _voicePromptViewModel.selectedPromptMethod();
            if (promptMethod === _audioInputOptionConstants.upload) {
                const voicePrompt = _voicePromptViewModel.promptFile();
                const voicePromptId = _voicePromptViewModel.voicePromptId;
                if (voicePrompt) {
                    if (voicePrompt.type.match('audio.*')) {
                        return true;
                    }
                    else if (voicePrompt.type === "application/wav") {
                        return true;
                    }
                } else if (voicePromptId) {
                    return true;
                }

                return false;
            } else {
                return true;
            }
        };

        const _validatePromptFileSizeTooSmall = () => {
            const promptMethod = _voicePromptViewModel.selectedPromptMethod();
            if (promptMethod === _audioInputOptionConstants.upload) {
                const voicePrompt = _voicePromptViewModel.promptFile();
                if(voicePrompt && _validateFileSize === true){
                    if (voicePrompt.size < VOICEPROMPT_MIN_LENGTH) {
                        return false;
                    }
                }
                return true;
            } else {
                return true;
            }
        };

        const _validatePromptFileSizeTooBig = () => {
            const promptMethod = _voicePromptViewModel.selectedPromptMethod();
            if (promptMethod === _audioInputOptionConstants.upload) {
                const voicePrompt = _voicePromptViewModel.promptFile();
                if(voicePrompt && _validateFileSize === true){
                    if (voicePrompt.size > VOICEPROMPT_MAX_LENGTH) {
                        return false;
                    }
                }
                return true;
            } else {
                return true;
            }
        };
    };
});

