define('common/presentation/modalConfiguration',['plugins/dialog',
        'settings/uiAnimationsConfiguration'],

    function() {
        var _modalDialog = require('plugins/dialog');
        var _uiAnimationsConfig = require('settings/uiAnimationsConfiguration');

        var _updateMinimizedModalPlacements = function() {
            var currentOffset = 0;
            var spacerOffset = 3;
            $(".modal-container.minimized").each(function(index, element) {
                $(element).css({"right": currentOffset + "px"});
                currentOffset += $(".modal", element).outerWidth();
                currentOffset += spacerOffset;
            });
        };

        var _minimizeModal = function($modal) {
            var $blockout = $(".modal-backdrop");
            $modal.addClass("minimized");
            $(".modal", $modal).addClass("minimized");
            _updateMinimizedModalPlacements();

            setTimeout(function() {
                $blockout.removeClass("visible");
            }, _uiAnimationsConfig.presentation_common_modal_dialog);

            setTimeout(function() {
                $blockout.remove();
            }, _uiAnimationsConfig.presentation_common_modal_blockout);
        };

        var _restoreModal = function($modal) {
            var $body = $('body');
            if($modal.hasClass("minimized")) {
            var $blockout = $('<div class="modal-backdrop visible" />')
                                   .appendTo($body);
                $modal.removeClass("minimized");
                $modal.css({"right": ""});
                $(".modal", $modal).removeClass("minimized");
                _updateMinimizedModalPlacements();
            }
        };

        var configure = function() {
            _modalDialog.addContext('default', {
                addHost: function(modal) {
                    var $body = $('body');
                    var $blockout = $('<div class="modal-backdrop visible" />')
                        .appendTo($body);

                    var $host = $('<div class="modal-container" />')
                        .appendTo($body);

                    modal.host = $host.get(0);
                    modal.blockout = $blockout.get(0);

                    $body.bind("keydown.modalTabCapture", { host: $host }, function(event) {
                        if (event.keyCode === $.ui.keyCode.TAB) {
                            var tabbables = event.data.host.find(":tabbable");
                            if ($.inArray(event.target, tabbables) === -1) {
                                tabbables.filter(":first").focus();
                                event.preventDefault();
                            } else if ( event.shiftKey && event.target === tabbables.filter(":first")[0]) {
                                tabbables.filter(":last").focus();
                                event.preventDefault();
                                event.stopPropagation();
                            } else if (!event.shiftKey && event.target === tabbables.filter(":last")[0] ) {
                                tabbables.filter(":first").focus();
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }
                    });
                },
                removeHost: function(modal) {
                    $('body').unbind("keydown.modalTabCapture");
                    var $host = $(modal.host);
                    var $blockout = $host.prev(".modal-backdrop");
                    if($blockout.length === 0) {
                        $blockout = $(".modal-backdrop").first();
                    }
                    $host.removeClass("minimized");
                    $host.children().eq(0).removeClass("visible");
                    _updateMinimizedModalPlacements();

                    setTimeout(function() {
                        $blockout.removeClass("visible");
                    }, _uiAnimationsConfig.presentation_common_modal_dialog);

                    setTimeout(function() {
                        $host.css("opacity", 0);
                        ko.removeNode(modal.host);
                        $blockout.remove();
                    }, _uiAnimationsConfig.presentation_common_modal_blockout);
                },
                compositionComplete: function(child, parent, context) {
                    $(child).width();
                    $(parent).css("opacity", 1);
                    $(child).addClass("visible");
                    var focusedElements = $(child).find(":focus");
                    if(focusedElements.length === 0) {
                        var buttons = $(child).find(".button-primary");
                        if(buttons.length > 0) {
                            buttons[0].focus();
                        }
                    }
                }
            });

            _modalDialog.toggleMinimize = function(viewModel) {
                var dialog = _modalDialog.getDialog(viewModel);
                if (dialog === undefined) {
                    return;
                }

                var $modal = $(dialog.host);

                if ($modal.hasClass("minimized")) {
                    _restoreModal($modal);
                } else {
                    _minimizeModal($modal);
                }
            };

            _modalDialog.restore = function(viewModel) {
                var dialog = _modalDialog.getDialog(viewModel);
                if (dialog === undefined) {
                    return;
                }
                var $modal = $(dialog.host);

                _restoreModal($modal);
            };

            _modalDialog.confirmMessage = function(viewModel, confirmationMessage, confirmButtonLabel, cancelButtonLabel, confirmCallback) {
                var dialog = _modalDialog.getDialog(viewModel);
                if (dialog === undefined) {
                    return;
                }
                var $modal = $(dialog.host);
                $modal.addClass("confirm-message-open");

                var cancelMessageClickAction = function() {
                    $modal.removeClass("confirm-message-open");
                    $(".confirmation-message-backdrop", $modal).remove();
                    $(".confirmation-message", $modal).remove();
                };

                var confirmMessageClickAction = function() {
                    confirmCallback();
                    $modal.removeClass("confirm-message-open");
                    $(".confirmation-message-backdrop", $modal).remove();
                    $(".confirmation-message", $modal).remove();
                };

                var $modalConfirmationBackdrop = $("<div />")
                                                    .addClass("confirmation-message-backdrop");
                var $modalConfirmationMessage = $("<div />")
                                                    .addClass("confirmation-message");
                var $confirmationMessage = $("<strong />")
                                                .text(confirmationMessage)
                                                .append("<br />");
                var $confirmButton = $("<button />")
                                        .attr("type", "submit")
                                        .text(confirmButtonLabel)
                                        .click(confirmMessageClickAction);
                var $cancelButton = $("<button />")
                                        .text(cancelButtonLabel)
                                        .click(cancelMessageClickAction);

                $modalConfirmationMessage
                    .append($confirmationMessage)
                    .append($confirmButton)
                    .append($cancelButton);

                $modal
                    .append($modalConfirmationBackdrop)
                    .append($modalConfirmationMessage);
            };
        };

        return {
            configureModal: configure
        };
    }
);

