define('presentation/settings/userSubscriptions/facades/userVoicemailSubscriptionsFacade',['common/collections/collectionSorter',
         'common/promises/promiseFactory',
         'externalDependencies/clientWebSocket',
         'presentation/settings/userSubscriptions/presentationObjects/userVoicemailSubscriber',
         'presentation/settings/userSubscriptions/presentationObjects/userVoicemailSubscription',
], function() {
    const SorterConstructor = require('common/collections/collectionSorter');
    const _sorter = new SorterConstructor();

    const UserVoicemailSubscriptionPresentationObject = require('presentation/settings/userSubscriptions/presentationObjects/userVoicemailSubscription');
    const UserVoicemailSubscriberPresentationObject = require('presentation/settings/userSubscriptions/presentationObjects/userVoicemailSubscriber');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _userVoicemailBoxSubscriberSettingsWebSocket = clientWebSocket.forApplication("voicemailBoxSubscriberSettings");

    const _commonState = require('common/storage/commonState');

    let _promiseFactory = null;
    let _voicemailBoxes = null;

    const _getVoicemailBoxSubscriptions = (userId) => {
        return _promiseFactory.defer((deferredObject) => {

            _userVoicemailBoxSubscriberSettingsWebSocket.send("getInitialVoicemailBoxSubscriberSettings", {userId: userId}, (data, errMsg) => {
                if (errMsg) {
                    throw new Error(errMsg);
                }

                _voicemailBoxes = data.filter(settings => settings.isActiveSubscriber === true);
                const voicemailBoxSubscriptionsForDisplay = _buildVoicemailBoxSubscriptions(_voicemailBoxes).filter(subscriptions => subscriptions !== null);
                deferredObject.resolve(voicemailBoxSubscriptionsForDisplay);
            });
        });
    };

    const _buildVoicemailBoxSubscriptions = (activeSubscriberSettings) => {
        return activeSubscriberSettings.map((voicemailBox) => {
            if (_commonState.get(voicemailBox.voicemailBoxId) !== undefined) {
                let subscriptionPresentationObject = new UserVoicemailSubscriptionPresentationObject();
                subscriptionPresentationObject.voicemailBoxId = voicemailBox.voicemailBoxId;
                subscriptionPresentationObject.userId = voicemailBox.userId;
                subscriptionPresentationObject.isEmailDeliveryEnabled(voicemailBox.isEmailDeliveryEnabled);
                subscriptionPresentationObject.isPushToMobileAppEnabled(voicemailBox.isPushToMobileAppEnabled);
                subscriptionPresentationObject.isShowInSidebarEnabled(voicemailBox.isShowInSidebarEnabled);
                subscriptionPresentationObject.subscribers = _buildSubscribers(voicemailBox.voicemailBoxId);

                let ownerId = _commonState.get(voicemailBox.voicemailBoxId).ownerId;
                if (ownerId() !== null && ownerId() !== undefined) {
                    if (_commonState.get(ownerId()).type === _commonState.types.user) {
                        subscriptionPresentationObject.voicemailBoxName = _commonState.get(ownerId()).name;
                        subscriptionPresentationObject.voicemailBoxType = _commonState.types.user;
                        subscriptionPresentationObject.userAvatar = _commonState.get(ownerId()).avatar;
                    } else if (_commonState.get(ownerId()).type === _commonState.types.userGroup) {
                        subscriptionPresentationObject.voicemailBoxName = _commonState.get(ownerId()).name;
                        subscriptionPresentationObject.voicemailBoxType = _commonState.types.userGroup;
                        subscriptionPresentationObject.userGroupId = ownerId();
                    }
                } else {
                    subscriptionPresentationObject.voicemailBoxName = voicemailBox.standaloneName;
                    subscriptionPresentationObject.voicemailBoxType = _commonState.types.standalone;
                }
                return subscriptionPresentationObject;
            } else {
                return null;
            }
        });
    };

    const _buildSubscribers = (voicemailBoxId) => {
        const subscribers = ko.pureComputed(() => {
            let formattedSubscribers = _commonState.get(voicemailBoxId).subscribers().map((subscriber) => {
                let subscriberPresentationObject = new UserVoicemailSubscriberPresentationObject();
                subscriberPresentationObject.subscriberType = _commonState.get(subscriber).type;
                subscriberPresentationObject.displayName = _commonState.get(subscriber).name;
                subscriberPresentationObject.subscriberId = _commonState.get(subscriber).id;
                if (_commonState.get(subscriber).type === _commonState.types.user) {
                    subscriberPresentationObject.avatar = _commonState.get(subscriber).avatar;
                    subscriberPresentationObject.isUser = true;
                } else if (_commonState.get(subscriber).type === _commonState.types.userGroup) {
                    subscriberPresentationObject.isUserGroup = true;
                }
                return subscriberPresentationObject;
            });
            _sorter.sort(formattedSubscribers, "displayName", true);
            return formattedSubscribers;
        });
        return subscribers;
    };

    const _saveSubscriptionSettings = (request) => {
        return _promiseFactory.defer((deferredObject) => {
            _userVoicemailBoxSubscriberSettingsWebSocket.send("updateVoicemailBoxSubscriberSettings", request, (data, errMsg) => {
                if (errMsg) {
                    throw new Error(errMsg);
                }
                deferredObject.resolve();
            });
        });
    };

    const _onSubscriberSettingUpdate = (callback) => {
        _userVoicemailBoxSubscriberSettingsWebSocket.subscribeToEvent("voicemailBoxSubscriberSettingsChanged", (voicemailBoxSubscriberSettings) => {
            callback(voicemailBoxSubscriberSettings);
        });
    };

    const _dispose = () => {
        _userVoicemailBoxSubscriberSettingsWebSocket.disposeOfEvents();
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    return function() {
        let self = this;

        self.init = _init;
        self.dispose = _dispose;
        self.getVoicemailBoxSubscriptions = _getVoicemailBoxSubscriptions;
        self.onSubscriberSettingUpdate = _onSubscriberSettingUpdate;
        self.saveSubscriptionSettings = _saveSubscriptionSettings;
    };
});
