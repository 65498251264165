define('presentation/settings/phoneNumbers/presentationObjects/addPhoneNumberPortPresentationObject',[],function() {
    return function(){
        let self = this;

        self.phoneNumbers = null;
        self.losingCarrier = "";
        self.billingInfoName = "";
        self.billingInfoNumber = "";
        self.billingInfoAddressLineOne = "";
        self.billingInfoAddressLineTwo = "";
        self.billingInfoCity = "";
        self.billingInfoCountry = "";
        self.billingInfoStateProvince = "";
        self.billingInfoPostalCode = "";
        self.portAccountNumber = "";
        self.pinOrPasscode = "";
        self.authorizedSignature = "";
        self.selectedOnlyNumberOption = true;
        self.portType = "FULL";
        self.portDateSelection = "default";
        self.portDate = null;
    };
});
