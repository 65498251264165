define('persistence/repositories/userGroupRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(userGroupEntity) {
            if (userGroupEntity.userGroupId !== null) {
                var err = new Error("userGroupId should be null");
                throw err;
            }
            var userGroupDocument = {
                    data : userGroupEntity
            };

            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_groups/', 'PUT', userGroupDocument)
                    .done(function(result) {
                        deferredObject.resolve(result.data);
                    })
                    .fail(function(error) {
                        error.userGroupEntity = userGroupEntity;
                        deferredObject.reject(error);
                    });

            });
        };

        self.remove = function(userGroupId) {

            return _promiseFactory.defer(function(deferredObject) {
                if(userGroupId === null) {
                    var err = new Error("Missing userGroupId");
                    throw err;
                }
                _kazooApi.callAccount('/lobby_groups/' + userGroupId, 'DELETE')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        error.userGroupId = userGroupId;
                        deferredObject.reject(error);
                    });

            });

        };

        self.update = function(userGroupEntity) {
            var userGroupDocument = {
                           data : userGroupEntity
            };

            return _promiseFactory.defer(function(deferredObject) {
                if(userGroupEntity.userGroupId === null) {
                    var err = new Error("Missing userGroupId");
                    throw err;
                }
                _kazooApi.callAccount('/lobby_groups/' + userGroupEntity.userGroupId, 'POST', userGroupDocument)
                    .done(function(result) {
                        deferredObject.resolve(result.data);
                    })
                    .fail(function(error) {
                        error.userGroupEntity = userGroupEntity;
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

