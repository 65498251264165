define('presentation/common/callFlow/viewModels/callFlowViewModel',['common/time/datetimeFormatter'],
    function() {

    return function() {
        let self = this;

        const DateFormatterConstructor = require('common/time/datetimeFormatter');
        let _dateTimeFormatter = new DateFormatterConstructor();

        let _callFlow = null;

        self.callFlow = null;
        self.showVoicePrompt = false;

        self.toggleCallFlowDetails = () => {
            _callFlow.showCallFlowDetails(!_callFlow.showCallFlowDetails());
        };

        self.toggleSubMenuCallFlowDetails = (subMenuPresentationObject) => {
            subMenuPresentationObject.showSubMenuCallFlowDetails(!subMenuPresentationObject.showSubMenuCallFlowDetails());
        };

        self.toggleScheduleSegmentDetails = (subMenuPresentationObject) => {
            subMenuPresentationObject.showScheduleSegmentDetails(!subMenuPresentationObject.showScheduleSegmentDetails());
        };

        self.getSegmentDays = (scheduleSegment) => {
            let segmentDays = [];
            if(scheduleSegment.isForMonday()) {
                segmentDays.push("Mon");
            }
            if(scheduleSegment.isForTuesday()) {
                segmentDays.push("Tue");
            }
            if(scheduleSegment.isForWednesday()) {
                segmentDays.push("Wed");
            }
            if(scheduleSegment.isForThursday()) {
                segmentDays.push("Thur");
            }
            if(scheduleSegment.isForFriday()) {
                segmentDays.push("Fri");
            }
            if(scheduleSegment.isForSaturday()) {
                segmentDays.push("Sat");
            }
            if(scheduleSegment.isForSunday()) {
                segmentDays.push("Sun");
            }
            return segmentDays.join(", ");
        };

        self.getSegmentHours = (scheduleSegment) => {
            let today = new Date();
            let startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            startTime.setHours(scheduleSegment.startHour());
            startTime.setMinutes(scheduleSegment.startMinute());
            let startTimeString = _dateTimeFormatter.format(startTime, "hhh:nn tt");

            let endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            endTime.setHours(scheduleSegment.endHour());
            endTime.setMinutes(scheduleSegment.endMinute());
            let endTimeString = _dateTimeFormatter.format(endTime, "hhh:nn tt");

            return startTimeString + " to " + endTimeString;
        };

        self.activate = function(callFlowViewModel) {
            _callFlow = callFlowViewModel;

            _initialize();
        };

        let _initialize = function() {
            if (_callFlow.autoAttendant) {
                self.callFlow = _callFlow.autoAttendant;
            }

            if (_callFlow.subMenuCallFlow) {
                self.callFlow = _callFlow.subMenuCallFlow;
                self.showVoicePrompt = true;
            }
        };
    };

});

