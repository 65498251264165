define('presentation/settings/userGroup/facades/userGroupVoicemailGreetingsFacade',['businessServices/converters/durationTimeFormatter',
        'businessServices/uploads/customerUpload',
        'common/promises/promiseFactory',
        'constants/audioFileConversionStatusConstants',
        'externalDependencies/clientApi',
        'presentation/common/dateTimeValue',
        'presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject',
        'presentation/settings/userGroup/presentationObjects/voicemailGreetingPresentationObject',
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = null;

    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
    const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
    const VoicemailGreetingAudioObjectConstructor = require('presentation/common/voicemail/presentationObjects/voicemailGreetingAudioObject');
    const VoicemailGreetingPresentationObjectConstructor = require('presentation/settings/userGroup/presentationObjects/voicemailGreetingPresentationObject');

    const _audioFileConversionStatusConstants = require('constants/audioFileConversionStatusConstants');
    const _commonState = require('common/storage/commonState');
    const _customerUpload = require('businessServices/uploads/customerUpload');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _greetingsSocket = clientWebSocket.forApplication("userGroupVoicemailGreetings");
    const _voicemailSocket = clientWebSocket.forApplication("userGroupVoicemail");

    const _getUserGroupVoicemailBox = (userGroupId) => {
        return _promiseFactory.defer((promise) => {
            const userGroupGuid = _commonState.resolveGroupIdToGuid(userGroupId);

            const request = {
                userGroupId: userGroupGuid,
            };

            _voicemailSocket.send("getVoicemailBox", request, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }

                promise.resolve(response);
            });
        });
    };

    const _updateUserGroupVoicemailBox = (userGroupId, customGreetingId, systemGreetingId) => {
        return _promiseFactory.defer((promise) => {
            const userGroupGuid = _commonState.resolveGroupIdToGuid(userGroupId);

            const request = {
                userGroupId: userGroupGuid,
                customGreetingId: customGreetingId,
                systemGreetingId: systemGreetingId,
            };

            _voicemailSocket.send("updateVoicemailBox", request, (result, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                if (result.isSuccessful === false) {
                    promise.reject(new Error("error updating voicemailbox"));
                    return;
                }
                promise.resolve(result);
            });
        });
    };

    const _buildVoicemailBoxGreetingForDisplay = (voicemailBoxGreeting) => {
        let voicemailGreetingPresentationObject = new VoicemailGreetingPresentationObjectConstructor();
        voicemailGreetingPresentationObject.voicemailBoxGreetingId = voicemailBoxGreeting.voicemailBoxGreetingId;
        voicemailGreetingPresentationObject.name(voicemailBoxGreeting.name);
        voicemailGreetingPresentationObject.isBuiltIn(voicemailBoxGreeting.isSystemGreeting);
        if (voicemailBoxGreeting.createdDateTime) {
            voicemailGreetingPresentationObject.createdDateTime = new DateTimeValueConstructor(voicemailBoxGreeting.createdDateTime);
        }
        if (voicemailBoxGreeting.updatedDateTime) {
            voicemailGreetingPresentationObject.modifiedDateTime = new DateTimeValueConstructor(voicemailBoxGreeting.updatedDateTime);
        }

        let audioObject = new VoicemailGreetingAudioObjectConstructor();
        if (voicemailBoxGreeting.conversionStatus === _audioFileConversionStatusConstants.converted) {
            audioObject.audioData(voicemailBoxGreeting.mp3Url);
            audioObject.duration(_getFormattedDurationProperty(voicemailBoxGreeting.durationInMilliseconds/1000));
        } else {
            audioObject.showConversionMessage(true);
        }
        voicemailGreetingPresentationObject.greetingAudioObject(audioObject);

        return voicemailGreetingPresentationObject;
    };

    const _getFormattedDurationProperty = (greetingDurationInSeconds) => {
        let formatter = new TimeFormatterConstructor();
        let formattedTimeDuration = formatter.formatTotalSeconds(greetingDurationInSeconds);
        return formattedTimeDuration;
    };

    const _getCurrentGreetingId = (userGroupId) => {
        return _promiseFactory.defer((promise) => {
            if (userGroupId === null) {
                _getDefaultVoicemailGreeting()
                    .fail(promise.reject)
                    .done((defaultVoicemailBoxGreeting) => {
                        promise.resolve(defaultVoicemailBoxGreeting.voicemailBoxGreetingId);
                    });
            } else {
                _getUserGroupVoicemailBox(userGroupId)
                    .fail(promise.reject)
                    .done((result) => {
                        if (result.customGreetingId) {
                            promise.resolve(result.customGreetingId);
                            return;
                        } else if (result.systemGreetingId) {
                            promise.resolve(result.systemGreetingId);
                            return;
                        }

                        promise.reject(new Error("unable to determine greeting"));
                    });
            }
        });
    };

    const _getDefaultVoicemailGreeting = () => {
        return _promiseFactory.defer((promise) => {
            _greetingsSocket.send("getDefaultGreeting", {}, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }

                const defaultVoicemailGreeting = _buildVoicemailBoxGreetingForDisplay(response);
                promise.resolve(defaultVoicemailGreeting);
            });

        });
    };

    const _listVoicemailBoxGreetings = (userGroupId) => {
        return _promiseFactory.defer((promise) => {
            const userGroupGuid = _commonState.resolveGroupIdToGuid(userGroupId);

            const request = {
                userGroupId: userGroupGuid,
            };

            _greetingsSocket.send("listGreetings", request, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }

                const voicemailGreetingsList = response.map(voicemailGreetingResult => {
                    return _buildVoicemailBoxGreetingForDisplay(voicemailGreetingResult);
                });
                promise.resolve(voicemailGreetingsList);
            });
        });
    };

    const _getVoicemailBoxGreetings = (userGroupId) => {
        return _promiseFactory.defer((promise) => {
            let allVoicemailGreetings = [];
            const greetingsPromiseFactory = new PromiseFactoryConstructor();
            greetingsPromiseFactory.deferIndefinitely((greetingsPromise) => {
                _getDefaultVoicemailGreeting()
                    .fail(greetingsPromise.reject)
                    .done((defaultGreeting) => {
                        allVoicemailGreetings.push(defaultGreeting);
                        greetingsPromise.resolve();
                    });
            });

            if (userGroupId !== null) {
                greetingsPromiseFactory.deferIndefinitely((greetingsPromise) => {
                    _listVoicemailBoxGreetings(userGroupId)
                        .fail(greetingsPromise.reject)
                        .done((voicemailBoxGreetings) => {
                            allVoicemailGreetings.push(...voicemailBoxGreetings);
                            greetingsPromise.resolve();
                        });
                });
            }

            greetingsPromiseFactory.wait()
                .fail(promise.reject)
                .done(() => {
                    promise.resolve(allVoicemailGreetings);
                });
        });
    };

    const _addGreeting = (userGroupId, voicemailBoxGreeting) => {
        return _promiseFactory.defer((promise) => {
            const fileHandle = voicemailBoxGreeting.file();
            _customerUpload.uploadFile(fileHandle)
                .fail(promise.reject)
                .done(({uploadId}) => {
                    const userGroupGuid = _commonState.resolveGroupIdToGuid(userGroupId);

                    const request = {
                        userGroupId: userGroupGuid,
                        uploadId : uploadId,
                        greetingName : voicemailBoxGreeting.name(),
                    };

                    _greetingsSocket.send("createGreeting", request, (response) => {
                        if (response.isSuccessful) {
                            promise.resolve(response.voicemailBoxGreetingId);
                        } else {
                            promise.reject(new Error("error creating greeting"));
                        }
                    });
                });
        });
    };

    const _updateGreetingPrompt = (userGroupId, voicemailBoxGreeting) => {
        return _promiseFactory.defer((promise) => {
            const fileHandle = voicemailBoxGreeting.file();
            const userGroupGuid = _commonState.resolveGroupIdToGuid(userGroupId);

            _customerUpload.uploadFile(fileHandle)
                .fail(promise.reject)
                .done(({uploadId}) => {

                    const updateRequest = {
                        userGroupId : userGroupGuid,
                        voicemailBoxGreetingId: voicemailBoxGreeting.voicemailBoxGreetingId,
                        greetingName : voicemailBoxGreeting.name(),
                        uploadId : uploadId,
                    };

                    _greetingsSocket.send("updateGreetingPrompt", updateRequest, (response) => {
                        if (response.isSuccessful) {
                            promise.resolve();
                        } else {
                            promise.reject(new Error("error updating greeting"));
                        }
                    });
                });
        });
    };

    const _updateGreetingName = (userGroupGuid, voicemailBoxGreeting) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            const request = {
                userGroupId : userGroupGuid,
                voicemailBoxGreetingId: voicemailBoxGreeting.voicemailBoxGreetingId,
                name: voicemailBoxGreeting.name(),
            };
            _greetingsSocket.send("updateGreetingName", request, (response) => {
                if (response.isSuccessful) {
                    promise.resolve();
                } else {
                    promise.reject(new Error("error updating greeting"));
                }
            });
        });
    };

    const _processVoicemailBoxGreetingForSave = (userGroupId, voicemailBoxGreeting) => {
        let promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.deferIndefinitely((promise) => {
            if (voicemailBoxGreeting.requiresUpdate === false || voicemailBoxGreeting.isBuiltIn()) {
                promise.resolve();
            } else if (voicemailBoxGreeting.isDeleted() === true) {
                _deleteVoicemailBoxGreeting(userGroupId, voicemailBoxGreeting)
                    .fail(promise.reject)
                    .done(promise.resolve);
            } else {
                if (voicemailBoxGreeting.voicemailBoxGreetingId === null) {
                    _addGreeting(userGroupId, voicemailBoxGreeting)
                        .fail(promise.reject)
                        .done(promise.resolve);
                } else {
                    if (voicemailBoxGreeting.file()) {
                        _updateGreetingPrompt(userGroupId, voicemailBoxGreeting)
                            .fail(promise.reject)
                            .done(promise.resolve);
                    } else {
                        _updateGreetingName(userGroupId, voicemailBoxGreeting)
                            .fail(promise.reject)
                            .done(promise.resolve);
                    }
                }
            }
        });
    };

    const _saveVoicemailBoxGreetings = (userGroupId, voicemailGreetings) => {
        return _promiseFactory.deferIndefinitely((promise) => {
            let saveVoicemailGreetingsPromise = new PromiseFactoryConstructor();
            voicemailGreetings.forEach(voicemailBoxGreeting => {
                saveVoicemailGreetingsPromise.deferIndefinitely((saveDeferredObject) => {
                    _processVoicemailBoxGreetingForSave(userGroupId, voicemailBoxGreeting)
                        .fail(saveDeferredObject.reject)
                        .done((greetingId) => {
                            if (voicemailBoxGreeting.voicemailBoxGreetingId === null && greetingId !== undefined) {
                                voicemailBoxGreeting.voicemailBoxGreetingId = greetingId;
                            }
                            saveDeferredObject.resolve();
                        });
                });
            });
            saveVoicemailGreetingsPromise.wait()
                .fail(promise.reject)
                .done(() => {
                    promise.resolve({
                        "status" : "success",
                        "voicemailGreetings" : voicemailGreetings
                    });
                });
        });
    };

    const _deleteVoicemailBoxGreeting = (userGroupId, voicemailBoxGreeting) => {
        return _promiseFactory.defer((promise) => {
            const request = {
                userGroupId : userGroupId,
                voicemailBoxGreetingId: voicemailBoxGreeting.voicemailBoxGreetingId
            };

            _greetingsSocket.send("deleteGreeting", request, (response) => {
                if (response.isSuccessful) {
                    promise.resolve();
                } else {
                    promise.reject(new Error("error deleting voicemail box greeting"));
                }
            });
        });
    };

    const _onVoicemailGreetingConverted = (callback) => {
        _greetingsSocket.subscribeToEvent("voicemailBoxGreetingConverted", (voicemailBoxGreeting) => {
            let voicemailGreetingPresentationObject = _buildVoicemailBoxGreetingForDisplay(voicemailBoxGreeting);
            callback(voicemailGreetingPresentationObject);
        });
    };

    const _dispose = () => {
        _greetingsSocket.disposeOfEvents();
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    return function() {
        const self = this;

        self.dispose = _dispose;
        self.init = _init;
        self.getCurrentGreetingId = _getCurrentGreetingId;
        self.getUserGroupVoicemailBox = _getUserGroupVoicemailBox;
        self.getVoicemailBoxGreetings = _getVoicemailBoxGreetings;
        self.getDefaultVoicemailGreeting = _getDefaultVoicemailGreeting;
        self.onVoicemailBoxGreetingConverted = _onVoicemailGreetingConverted;
        self.updateUserGroupVoicemailBox = _updateUserGroupVoicemailBox;
        self.saveVoicemailBoxGreetings = _saveVoicemailBoxGreetings;
    };
});

