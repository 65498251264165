define('presentation/userProfile/viewModels/userProfileViewModel',['businessServices/events/eventManager',
        'businessServices/router/router',
        'common/promises/promiseFactory',
        'presentation/common/window/windowControl',
        'presentation/userProfile/facades/userProfileFacade',
        'settings/navigationConfiguration',
        'settings/uiAnimationsConfiguration'
], function (
    /** @type import('businessServices/events/eventManager') */
    eventManager,
    /** @type typeof import('businessServices/router/router') */
    router,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type import('presentation/common/window/windowControl') */
    windowControl,
    /** @type typeof import('presentation/userProfile/facades/userProfileFacade') */
    UserProfileFacade,
    /** @type typeof import('settings/navigationConfiguration') */
    navigationConfiguration,
    /** @type import('settings/uiAnimationsConfiguration') */
    UIAnimationsConfig
) {
    return function () {
        const self = this;

        const _promiseFactory = new PromiseFactory();

        /** @type {UserProfileFacade['prototype']} */
        let _facade = null;
        /** @type {number} */
        let _hideUserProfileOptionsTimeout = null;

        const _updateUserProfile = (/** @type {string} */userId) => {
            const userProfileId = _facade.getCurrentUserProfileId();
            if (userId === userProfileId) {
                _refreshAvatar();
                _updateUserFullName();
            }
        };

        const _updateUserFullName = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getCurrentUserInfo()
                    .fail(promise.reject)
                    .done((userProfile) => {
                        self.userName(userProfile.fullName);
                        promise.resolve();
                    });
            });
        };

        const _refreshAvatar = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getCurrentUserAvatar()
                    .fail(promise.reject)
                    .done((avatar) => {
                        self.userAvatar(avatar);
                        self.showInitials(avatar.showDefaultImage());
                        promise.resolve();
                    });
            });
        };

        self.isBillingAdmin = ko.observable(false);
        self.isImpersonating = ko.observable(false);
        self.isUserProfileOptionsOpen = ko.observable(false);
        self.userAvatar = ko.observable();
        self.userInitials = ko.observable("");
        self.userName = ko.observable("");
        self.showInitials = ko.observable(false);

        self.userProfileOptions = ko.computed(() => {
            const options = [
                {textI18n: 'helpCenter', iconName: 'userSettingsHelpCenter', onClick: () => self.openHelpCenter(), showSeparator: false},
                {textI18n: 'userSettings', iconName: 'userSettings', onClick: () => self.navigateToUserSettings(), showSeparator: false},
                {textI18n: 'signOut', iconName: 'userSettingsSignOut', onClick: () => self.logUserOut(), showSeparator: true},
            ];

            if (self.isBillingAdmin()) {
               options.unshift({textI18n: 'billing', iconName: 'userSettingsBilling', onClick: () => self.navigateToBillingSettings(), showSeparator: false});
            }

            return options;
        });

        self.hideUserProfileOptions = () => {
            _hideUserProfileOptionsTimeout = setTimeout(() => {
                self.isUserProfileOptionsOpen(false);
            }, UIAnimationsConfig.presentation_userProfile_viewModels_userProfileViewModel_hideUserProfileOptions);
        };

        self.logUserOut = () => {
            _facade.logUserOut();
        };

        self.navigateToBillingSettings = () => {
            self.isUserProfileOptionsOpen(false);
            router.navigate(navigationConfiguration.routesById.billCycleToDate.url);
        };

        self.navigateToUserSettings = () => {
            self.isUserProfileOptionsOpen(false);
            router.navigate(navigationConfiguration.routesById.userProfile.url);
        };

        self.openHelpCenter = () => {
            windowControl.openNewWindow(navigationConfiguration.routesById.helpCenter.externalUrl);
        };

        self.resetHideUserProfileOptions = () => {
            clearTimeout(_hideUserProfileOptionsTimeout);
        };

        self.toggleUserProfileOptions = () => {
            self.isUserProfileOptionsOpen(!self.isUserProfileOptionsOpen());
        };

        self.activate = () => {
            _facade = new UserProfileFacade();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            _facade.getCurrentUserInfo().done((userProfile) => {
                self.userName(userProfile.fullName);
                self.userInitials(userProfile.initials);
            });

            _facade.isImpersonating().done((isImpersonating) => {
                self.isImpersonating(isImpersonating);
            });

            _facade.isBillingAdmin().done((isBillingAdmin) => {
                self.isBillingAdmin(isBillingAdmin);
            });

            _refreshAvatar();

            eventManager.subscribeUserInfoChanged(_updateUserProfile);

            return _promiseFactory.wait();
        };
    };
});

