/**
 * @module presentation/common/audioPresentationObject
 */
define('presentation/common/audioPresentationObject',[], function(){
    /**
     * @constructor
     */
    return function() {
        var self = this;

        self.audioConversionId = ko.observable();
        self.audioUrl = null;
        self.audioBase64 = null;
        self.audioType = ko.observable();
        self.audioData = ko.observable();
        self.duration = ko.observable();
        self.showDefaultAudio = ko.observable(true);
        self.showAudio = ko.observable(false);
        self.showAudioPlayer = ko.observable(true);
        self.showConversionMessage = ko.observable(false);
        self.showConversionFailedMessage = ko.observable(false);
    };
});
