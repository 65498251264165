define('presentation/settings/userDevices/validators/userDeviceValidator',[
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'constants/countryEnumerations',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'presentation/common/validation/validPhoneNumberSpecification',
    ],
    function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
            const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            const _countryEnumerations = require('constants/countryEnumerations');

            let _viewModel = null;
            let _commonValidator = null;

            self.registerViewModel = (viewModel) => {
                _viewModel = viewModel;
                _commonValidator = new CommonValidatorConstructor(_viewModel);

                const validationRules = new ValidationRulesConstructor();
                validationRules.field("deviceName")
                    .addValidationKeyUp(_commonValidator.isStringWithValue)
                    .addValidationI18n(_commonValidator.isStringWithValue, 'deviceNameRequired')
                    .addValidationI18n(_isDeviceNameUnique, 'deviceNameUnique');
                validationRules.field("devicePhoneNumber")
                    .addValidationKeyUp(_validatePhoneNumberLength)
                    .addValidationI18n(_commonValidator.isStringWithValue, 'userDevices:devicePhoneNumberRequired')
                    .addValidationI18n(_validatePhoneNumberLength, 'phoneNumberInvalid')
                    .addValidationI18n(_isDevicePhoneNumberUniqueToUser, 'devicePhoneNumberUniqueToUser')
                    .addValidationI18n(_isDevicePhoneNumberUnique, 'devicePhoneNumberUnique')
                    .addValidationI18n(_isAllowedPhoneNumber, _restrictedNumberI18nKey());

                _commonValidator.useManualValidation();
                _commonValidator.setValidationRules(validationRules);
            };

            self.validate = () => {
                return _commonValidator.validate();
            };

            const _isDeviceNameUnique = (deviceName) => {
                return _promiseFactory.defer((promise) => {
                    if (_commonValidator.isStringWithValue(deviceName)) {
                        _viewModel.isDeviceNameUnique(deviceName)
                            .done(promise.resolve)
                            .fail(promise.reject);
                    } else {
                        promise.resolve(true);
                    }
                });
            };

            const _validatePhoneNumberLength = (phoneNumber) => {
                if (phoneNumber) {
                    let cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                    return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
                }
                return true;
            };

            const _isDevicePhoneNumberUnique = (devicePhoneNumber) => {
                return _promiseFactory.defer((promise) => {
                    if (!_commonValidator.isStringWithValue(devicePhoneNumber)) {
                        promise.resolve(true);
                    }
                    _viewModel.isDevicePhoneNumberUnique(devicePhoneNumber)
                        .done(promise.resolve)
                        .fail(promise.reject);
                });
            };

            const _isDevicePhoneNumberUniqueToUser = (devicePhoneNumber) => {
                return _promiseFactory.defer((promise) => {
                    if (!_commonValidator.isStringWithValue(devicePhoneNumber)) {
                        promise.resolve(true);
                    }
                    _viewModel.isDevicePhoneNumberUniqueToUser(devicePhoneNumber)
                        .done(promise.resolve)
                        .fail(promise.reject);
                });
            };

            const _isAllowedPhoneNumber = (devicePhoneNumber) => {
                if (!_commonValidator.isStringWithValue(devicePhoneNumber)) {
                    return true;
                }
                return _validPhoneNumberSpecification.isAllowedPhoneNumber(devicePhoneNumber);
            };

            const _restrictedNumberI18nKey = () => {
                return `restrictedPhoneNumber_${_viewModel.deviceCountryAbbreviation()}`;
            };
        };
    });

