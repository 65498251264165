define('presentation/common/answerTimeout/presentationObjects/answerTimeoutPresentationObject',[],
function() {
    return function() {
        const self = this;

        self.title = "";
        self.value = "";
        self.containerHeight = '';
        self.containerWidth = '';
        self.isSelected = ko.observable();
    };
});
