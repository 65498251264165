define('presentation/settings/accountProfile/viewModels/accountProfilePersonalViewModel',['businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'constants/countryEnumerations',
        'presentation/settings/accountProfile/presentationObjects/accountProfilePersonalPresentationObject',
        'presentation/settings/accountProfile/validators/accountProfilePersonalValidator',
], function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            const AccountProfilePersonalPresentationObjectConstructor = require('presentation/settings/accountProfile/presentationObjects/accountProfilePersonalPresentationObject');

            const _i18n = require('i18next');

            let _validator = null;

            self.accountHolderFirstName = ko.observable().extend({observeState: true});
            self.accountHolderLastName = ko.observable().extend({observeState: true});
            self.businessName = ko.observable();
            self.isEditMode = ko.observable(false);
            self.modelStateObserver = null;

            self.validate = () => _validator.validate();

            self.getAccountProfile = () => {
                const accountProfilePersonalPresentationObject = new AccountProfilePersonalPresentationObjectConstructor();
                accountProfilePersonalPresentationObject.accountHolderFirstName = self.accountHolderFirstName();
                accountProfilePersonalPresentationObject.accountHolderLastName = self.accountHolderLastName();
                accountProfilePersonalPresentationObject.businessName = self.businessName();
                return accountProfilePersonalPresentationObject;
            };

            self.invalidItems = () => {
                let invalidFields = [];
                if (self.accountHolderFirstName.isInvalid()) {
                    invalidFields.push(_i18n.t('accountProfilePersonal:labelFirstName'));
                }
                if (self.accountHolderLastName.isInvalid()) {
                    invalidFields.push(_i18n.t('accountProfilePersonal:labelLastName'));
                }

                return invalidFields;
            };

            self.resetValidation = () => {
                _validator.resetValidation();
            };

            self.cancelForm = () => {
                if (self.accountHolderFirstName.isDirty) {
                    self.accountHolderFirstName.forceUpdate(true);
                }
                if (self.accountHolderLastName.isDirty) {
                    self.accountHolderLastName.forceUpdate(true);
                }

                self.modelStateObserver.restoreData();
            };

            self.activate = (accountProfileData) => {
                const ValidatorConstructor = require('presentation/settings/accountProfile/validators/accountProfilePersonalValidator');
                _validator = new ValidatorConstructor();

                return _initialize(accountProfileData);
            };

            const _initialize = (accountProfileData) => {
                if (accountProfileData) {
                    self.isEditMode(true);
                    self.accountHolderFirstName(accountProfileData.accountHolderFirstName);
                    self.accountHolderLastName(accountProfileData.accountHolderLastName);
                    self.businessName(accountProfileData.businessName);
            }

                self.modelStateObserver = new ModelStateObserverConstructor(self, true);
                self.modelStateObserver.commitData();

                _validator.registerViewModel(self);

                return _promiseFactory.wait();
            };
        };
    }
);

