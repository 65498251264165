define('entities/routingRuleExitCondition',[],function() {
    return function(){
        const self = this;

        self.routingRuleExitConditionId = null;
        self.exitType = null;
        self.value = null;
        self.name = null;
        self.nextRoutingRule = {};
    };
});

