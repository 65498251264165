define('presentation/settings/messagingCompliance/viewModels/otpVerificationActionModalViewModel',[
    'businessServices/state/modelStateObserver',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'constants/countryEnumerations',
    'i18next',
    'presentation/common/modal',
    'presentation/settings/messagingCompliance/facades/otpVerificationFacade',
    'presentation/settings/messagingCompliance/validators/otpVerificationActionModalValidator'
], function (
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('constants/countryEnumerations') */
    _countryEnumerations,
    /** @type import('i18next') */
    _i18n,
    /** @type typeof import('presentation/common/modal') */
    Modal,
    /** @type typeof import('presentation/settings/messagingCompliance/facades/otpVerificationFacade') */
    OtpVerificationFacade
) {
    return function (
        /** @type { KnockoutObservable<string> } */
        displayMobilePhoneNumberWithPin,
        /** @type { () => JQueryDeferred<any> } */
        _submitPinCallback
    ) {
        /** @typedef { import('presentation/settings/messagingCompliance/facades/otpVerificationFacade') } OtpVerificationFacade } */
        /** @typedef { import('presentation/settings/messagingCompliance/validators/otpVerificationActionModalValidator') } OtpVerificationActionModalViewModelValidator } */

        const self = this;

        /** @type import('presentation/common/modal') */
        let _modalService = null;

        /** @type { OtpVerificationFacade }*/
        let _facade = null;

        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _promiseFactory = new PromiseFactory();

        /** @type { OtpVerificationActionModalViewModelValidator } */
        let _validator = null;

        self.modelStateObserver = null;
        self.displayMobilePhoneNumberWithPin = displayMobilePhoneNumberWithPin;
        self.isCompositionComplete = ko.observable(false);
        self.submitButtonText = _i18n.t('submit');
        self.smsPin = ko.observable("").extend({observeState: true});

        self.showResendSmsPin = ko.observable(true);
        self.smsPinRejected = ko.observable(false);

        self.displayMobilePhoneNumber = ko.pureComputed(() => {
            return _phoneNumberFormatter.toNumericDefault(self.displayMobilePhoneNumberWithPin());
        });

        const _submitSmsPin = () => {
            return _promiseFactory.defer((promise) => {
                _facade.submitSmsPin(self.smsPin())
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _requestSmsPin = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                if (!self.showResendSmsPin()) {
                    promise.resolve();
                    return;
                }

                _facade.requestSmsPin()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        self.resendSmsPin = _requestSmsPin;

        self.closeModal = () => {
            self.isCompositionComplete(false);
            self.modelStateObserver.navigateWithoutWarning = true;
            _modalService.closeModal(self);
        };

        self.onCancelClick = () => {
            self.closeModal();
        };

        self.onSubmitClick = () => {
            return _promiseFactory.defer((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((/** @type { boolean } */ isValid) => {
                        if (isValid === false) {
                            promise.resolve();
                            return;
                        }

                        _submitSmsPin()
                            .fail(promise.reject)
                            .done((result) => {
                                if (result.pinAccepted) {
                                    _submitPinCallback()
                                        .fail(promise.reject)
                                        .done(() => {
                                            self.closeModal();
                                            promise.resolve();
                                        });
                                } else {
                                    self.smsPinRejected(true);
                                    promise.resolve();
                                }
                            });
                    });
            });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            _facade = new OtpVerificationFacade();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/messagingCompliance/validators/otpVerificationActionModalValidator');
            _validator = new ValidatorConstructor();

            _modalService = new Modal();

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self);

            self.modelStateObserver = new ModelStateObserver(self, true);

            self.modelStateObserver.commitData();

            return _promiseFactory.wait();
        };
    };
});

