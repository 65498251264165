define('presentation/settings/phoneNumbers/presentationObjects/recordingNotificationAudioObject',[],
    function() {
        return function() {
            let self = this;

            self.audioConversionId = ko.observable();
            self.fileHandler = ko.observable();
            self.audioType = ko.observable();
            self.audioData = ko.observable();
            self.duration = ko.observable("Unknown");
            self.showConversionMessage = ko.observable(false);
            self.showConversionFailedMessage = ko.observable(false);
            self.showAudioPlayer = ko.pureComputed(function() {
                if (self.showConversionMessage() === false && self.showConversionFailedMessage() === false) {
                    return true;
                } else {
                    return false;
                }
            });
        };
    });
