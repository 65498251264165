define('presentation/loginNotifications/presentationObjects/paymentMethodPresentationObject',[],function() {
    return function(){
        let self = this;

        self.cardCssType = null;
        self.cardHolderName = ko.observable('');
        self.cardIdentifierFormatted = null;
        self.cardType = null;
        self.country = null;
        self.displayedCardName = null;
        self.description = null;
        self.expirationDate = null;
        self.isDefault = ko.observable(false);
        self.isExpired = ko.observable(false);
        self.isSelected = ko.observable(false);
        self.isLastChargeApproved = ko.observable(false);
        self.isLastChargeDeclined = ko.observable(false);
        self.lastChargeAmount = null;
        self.lastChargeDate = null;
        self.lastChargeOutcome = null;
        self.lastFourDigits = null;
        self.maskedPrefix = null;
        self.paymentMethodId = null;
        self.postalCode = null;
    };
});

