define('entities/emailEntity',[],function() {
    return function(){
        var self = this;

        self.emailId = null;
        self.to = [];
        self.from = null;
        self.attachments = [];
    };
});
