define('common/storage/commonState',[
        'businessServices/events/webSocketEventManager',
        'common/collections/collectionSorter',
        'common/converters/phoneNumberFormatter',
        'common/uniqueId/guidUtil',
        'constants/systemObjectIconNameConstants',
        'persistence/webSocket/webSocketApp',
        'presentation/common/croppableImage/presentationObjects/avatar',
        'presentation/common/dateTimeValue',
    ], function () {
        const CommonStateSingleton = function () {
            const self = this;
            CommonStateSingleton.prototype._singletonInstance = self;

            const TYPES = {
                autoAttendant: 'autoAttendant',
                company: 'company',
                contact: 'contact',
                forwardingNumber: 'forwardingNumber',
                hostedNumber: 'hostedNumber',
                prompt: 'voicePrompt',
                schedule: 'schedule',
                scheduleSegment: 'scheduleSegment',
                standalone: 'standalone',
                systemAudioClip: 'systemAudioClip',
                subMenu: 'subMenu',
                user: 'user',
                userGroup: 'userGroup',
                voicemailBox: 'voicemailBox'
            };

            const TYPE_DISPLAY = {
                autoAttendant: 'Auto Attendant',
                forwardingNumber: 'Forwarding Number',
                hostedNumber: 'Phone Number',
                paymentMethod: 'Payment Method',
                prompt: 'Audio File',
                schedule: 'Schedule',
                scheduleSegment: 'Segment',
                standalone: 'Standalone',
                subMenu: 'Sub Menu',
                user: 'User',
                userGroup: 'Group',
                voicemailBox: 'Voicemail Box'
            };

            const READY = 'ready';
            const READYING = 'readying';
            const NOT_READY = 'notReady';

            let _commonState = [];
            const _autoAttendants = ko.observableArray([]);
            const _company = {
                location: ko.observable(),
                postalAddress: ko.observable(),
            };
            const _extensions = ko.observableArray([]);
            const _forwardingNumbers = ko.observableArray([]);
            const _hostedNumbers = ko.observableArray([]);
            const _prompts = ko.observableArray([]);
            const _schedules = ko.observableArray([]);
            const _scheduleSegments = ko.observableArray([]);
            const _status = ko.observable(NOT_READY);
            const _users = ko.observableArray([]);
            const _userGroups = ko.observableArray([]);
            const _voicemailBoxes = ko.observableArray([]);
            let _lastUpdated = "";

            let _systemGroupAliases = [];
            let _systemGroupIdToGuid = [];

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            const _socket = new WebSocketAppConstructor();
            _socket.init("common_state");

            const AvatarPresentationObjectConstructor = require('presentation/common/croppableImage/presentationObjects/avatar');

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
            const _guidUtil = require('common/uniqueId/guidUtil');

            const SorterConstructor = require('common/collections/collectionSorter');
            const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

            const _commonStateChange = (() => {
                _socket.send("get_common_state", {last_updated: _lastUpdated}, function (results) {
                    if (results.lastUpdated === undefined) {
                        return;
                    }

                    _lastUpdated = results.lastUpdated;

                    // sorting so that voicemailBox is processed last
                    const sorter = new SorterConstructor();
                    sorter.sort(results.commonState, "type");

                    results.commonState.forEach(result => {
                        const _id = _guidUtil.maybeGuidToId(result.id);

                        if (_commonState[_id] === undefined) {
                            _addCommonStateItem(result);
                            return;
                        }

                        const item = _commonState[_id];
                        const modifiedDateTime = new DateTimeValueConstructor(result.modifiedDateTime);
                        if (item.modifiedDateTime().sortValue === modifiedDateTime.sortValue) {
                            return;
                        }

                        item.isActive(result.status === 'active');
                        item.isDeactivated(result.status === 'deactivated');
                        item.isExpired(result.status === 'expired');
                        item.isInactivate(result.status === 'deactivated' || result.status === 'expired');
                        item.isInvited(result.status === 'invited');
                        item.isDeleted(result.isDeleted === true);

                        if (item.isDeleted() || item.isDeactivated()) {
                            _removeExtension(_id);
                        }

                        item.modifiedDateTime(modifiedDateTime);
                        if (ko.isWriteableObservable(item.name)) {
                            item.name(result.name);
                        }
                        item.status(result.status);

                        switch (result.type) {
                            case TYPES.autoAttendant:
                                break;
                            case TYPES.company:
                                item.location(result.location);
                                item.postalAddress(result.postalAddress);
                                _company.location(result.location);
                                _company.postalAddress(result.postalAddress);
                                break;
                            case TYPES.forwardingNumber:
                                _updateExtension(item.extension(), result.extension, _id);
                                item.extension(result.extension);
                                item.extensionAssignedDateTime(new DateTimeValueConstructor(result.extensionAssignedDateTime));
                                item.location(_phoneNumberFormatter.cleanupLocationSansTollFree(result.location));
                                item.phoneNumber(_phoneNumberFormatter.toInternationalWithParens(result.phoneNumber));
                                break;
                            case TYPES.hostedNumber:
                                item.location(_phoneNumberFormatter.cleanupLocationSansTollFree(result.location));
                                break;
                            case TYPES.prompt:
                                item.durationInMilliseconds(result.durationInMilliseconds);
                                item.mp3FilePath(result.mp3FilePath);
                                break;
                            case TYPES.schedule:
                                break;
                            case TYPES.scheduleSegment:
                                item.scheduleId = _guidUtil.maybeGuidToId(result.ownerId);
                                item.endHour(result.endHour);
                                item.endMinute(result.endMinute);
                                item.isForSunday(result.segmentDays.includes('S'));
                                item.isForMonday(result.segmentDays.includes('M'));
                                item.isForTuesday(result.segmentDays.includes('T'));
                                item.isForWednesday(result.segmentDays.includes('W'));
                                item.isForThursday(result.segmentDays.includes('R'));
                                item.isForFriday(result.segmentDays.includes('F'));
                                item.isForSaturday(result.segmentDays.includes('A'));
                                item.sequence(result.sequence);
                                item.startHour(result.startHour);
                                item.startMinute(result.startMinute);
                                break;
                            case TYPES.user:
                                _updateExtension(item.extension(), result.extension, _id);

                                item.avatar(_userAvatar(result));
                                item.emailAddress(result.emailAddress);
                                item.extension(result.extension);
                                item.extensionAssignedDateTime(new DateTimeValueConstructor(result.extensionAssignedDateTime));
                                item.hostedPhoneNumbers(result.hostedPhoneNumbers);
                                item.invitedByUserId(result.invitedByUserId);
                                item.inviteSentDateTime(result.inviteSentDateTime);
                                item.inviteStatus(result.inviteStatus);
                                item.title(result.title);
                                item.phoneNumber(result.phoneNumber);
                                break;
                            case TYPES.userGroup:
                                _updateExtension(item.extension(), result.extension, _id);

                                item.avatars(_userGroupAvatars(result.avatars));
                                item.extension(result.extension);
                                item.extensionAssignedDateTime(new DateTimeValueConstructor(result.extensionAssignedDateTime));
                                item.hostedPhoneNumbers(result.hostedPhoneNumbers);
                                item.members(result.members);
                                break;
                            case TYPES.voicemailBox:
                                item.greetingId(result.greetingId);
                                item.subscribers(result.subscribers);
                                break;
                        }
                    });
                });
            });

            const _webSocketEventManager = require('businessServices/events/webSocketEventManager');
            _webSocketEventManager.init();
            _webSocketEventManager.subscribeCommonStateChange(_commonStateChange);

            const _addExtension = (item) => {
                if (! item.isDeleted()) {
                    _extensions.push({
                        extension: item.extension(),
                        ownerId: item.id
                    });
                }
            };

            const _updateExtension = (previousExtension, newExtension, newOwnerId) => {
                const extension = _extensions().find(e => e.extension === previousExtension);
                if (extension) {
                    extension.extension = newExtension;
                    extension.ownerId = newOwnerId;
                    _extensions.valueHasMutated();
                }
            };

            const _removeExtension = (itemId) => {
                const item = _commonState[itemId];
                if (item !== undefined) {
                    switch (item.type) {
                        case TYPES.user:
                        case TYPES.userGroup:
                        case TYPES.forwardingNumber:
                            const extensionToRemove = item.extension();
                            _extensions.remove(extension => extension.extension === extensionToRemove);
                    }
                }
            };


            const _userAvatar = (item => {
                var avatarPresentationObject = new AvatarPresentationObjectConstructor();
                avatarPresentationObject.avatarUrl(item.avatarUrl ? item.avatarUrl : "");
                avatarPresentationObject.status(item.status);
                return avatarPresentationObject;
            });

            const _userGroupAvatars = (avatars => avatars.map(avatar => {
                    var avatarPresentationObject = new AvatarPresentationObjectConstructor();
                    avatarPresentationObject.avatarUrl(avatar.url);
                    avatarPresentationObject.status(avatar.status);
                    return avatarPresentationObject;
                })
            );

            const _addCommonStateItem = (result => {

                if (result.type === "systemGroupAlias") {
                    _systemGroupAliases[result.id] = result.ownerId;
                    _systemGroupIdToGuid[result.ownerId] = result.id;
                    return;
                }

                const _id = _guidUtil.maybeGuidToId(result.id);

                const item = {
                    createdDateTime: new DateTimeValueConstructor(result.createdDateTime),
                    id: _id,

                    isActive: ko.observable(result.status === 'active'),
                    isDeactivated: ko.observable(result.status === 'deactivated'),
                    isExpired: ko.observable(result.status === 'expired'),
                    isInactivate: ko.observable(result.status === 'deactivated' || result.status === 'expired'),
                    isInvited: ko.observable(result.status === 'invited'),
                    isSelectable: ko.pureComputed(function() {
                        return result.status === 'active';
                    }),
                    isRoutable: ko.pureComputed(() => result.status === 'active'),
                    isDeleted: ko.observable(result.isDeleted === true),

                    isAutoAttendant: result.type === TYPES.autoAttendant,
                    isForwardingNumber: result.type === TYPES.forwardingNumber,
                    isHostedNumber: result.type === TYPES.hostedNumber,
                    isPrompt: result.type === TYPES.prompt,
                    isSchedule: result.type === TYPES.schedule,
                    isScheduleSegment: result.type === TYPES.scheduleSegment,
                    isUser: result.type === TYPES.user,
                    isUserGroup: result.type === TYPES.userGroup,
                    isVoicemailBox: result.type === TYPES.voicemailBox,
                    modifiedDateTime: ko.observable(new DateTimeValueConstructor(result.modifiedDateTime)),
                    name: ko.observable(result.name),
                    status: ko.observable(result.status),
                    type: result.type,
                };

                switch (result.type) {
                    case TYPES.autoAttendant:
                        item.iconName = _systemObjectIconNames.autoAttendant;
                        item.typeDisplay = TYPE_DISPLAY.autoAttendant;
                        _autoAttendants.push(item);
                        break;
                    case TYPES.company:
                        item.iconName = _systemObjectIconNames.user;
                        item.location = ko.observable(result.location);
                        item.postalAddress = ko.observable(result.postalAddress);
                        _company.location(result.location);
                        _company.postalAddress(result.postalAddress);
                        break;
                    case TYPES.forwardingNumber:
                        item.extension = ko.observable(result.extension);
                        item.extensionAssignedDateTime = ko.observable(new DateTimeValueConstructor(result.extensionAssignedDateTime));
                        item.iconName = _systemObjectIconNames.forwardingNumber;
                        item.location = ko.observable(_phoneNumberFormatter.cleanupLocationSansTollFree(result.location));
                        item.phoneNumber = ko.observable(_phoneNumberFormatter.toInternationalWithParens(result.phoneNumber));
                        item.typeDisplay = TYPE_DISPLAY.forwardingNumber;
                        _addExtension(item);
                        _forwardingNumbers.push(item);
                        break;
                    case TYPES.hostedNumber:
                        item.iconName = _systemObjectIconNames.hostedNumber;
                        item.location = ko.observable(_phoneNumberFormatter.cleanupLocationSansTollFree(result.location));
                        item.phoneNumber = ko.observable(_phoneNumberFormatter.toInternationalWithParens(result.phoneNumber));
                        item.hostedPhoneNumberDefaultName = ko.observable(result.hasOwnProperty("hostedPhoneNumberDefaultName") ? result.hostedPhoneNumberDefaultName : item.phoneNumber());
                        item.typeDisplay = TYPE_DISPLAY.hostedNumber;
                        item.hasDefaultName = ko.pureComputed(() => {
                            return item.name() === item.hostedPhoneNumberDefaultName();
                        });

                        if (item.isDeactivated()) {
                            item.name = item.phoneNumber;
                            item.hasDefaultName = ko.observable(true);
                        }
                        _hostedNumbers.push(item);
                        break;
                    case TYPES.prompt:
                        item.durationInMilliseconds = ko.observable(result.durationInMilliseconds);
                        item.iconName = _systemObjectIconNames.prompt;
                        item.mp3FilePath = ko.observable(result.mp3FilePath);
                        item.typeDisplay = TYPE_DISPLAY.prompt;
                        item.isSystemPrompt = result.status === 'system';
                        _prompts.push(item);
                        break;
                    case TYPES.schedule:
                        item.iconName = _systemObjectIconNames.schedule;
                        item.typeDisplay = TYPE_DISPLAY.schedule;
                        _schedules.push(item);
                        break;
                    case TYPES.scheduleSegment:
                        item.endHour = ko.observable(result.endHour);
                        item.endMinute = ko.observable(result.endMinute);
                        item.iconName = _systemObjectIconNames.scheduleSegment;
                        item.isForSunday = ko.observable(result.segmentDays.includes('S'));
                        item.isForMonday = ko.observable(result.segmentDays.includes('M'));
                        item.isForTuesday = ko.observable(result.segmentDays.includes('T'));
                        item.isForWednesday = ko.observable(result.segmentDays.includes('W'));
                        item.isForThursday = ko.observable(result.segmentDays.includes('R'));
                        item.isForFriday = ko.observable(result.segmentDays.includes('F'));
                        item.isForSaturday = ko.observable(result.segmentDays.includes('A'));
                        item.sequence = ko.observable(result.sequence);
                        item.startHour = ko.observable(result.startHour);
                        item.startMinute = ko.observable(result.startMinute);
                        item.scheduleId = _guidUtil.maybeGuidToId(result.ownerId);
                        item.typeDisplay = TYPE_DISPLAY.scheduleSegment;
                        _scheduleSegments.push(item);
                        break;
                    case TYPES.user:
                        item.avatar = ko.observable(_userAvatar(result));
                        item.emailAddress = ko.observable(result.emailAddress);
                        item.extension = ko.observable(result.extension);
                        item.extensionAssignedDateTime = ko.observable(new DateTimeValueConstructor(result.extensionAssignedDateTime));
                        item.hostedPhoneNumbers = ko.observableArray(result.hostedPhoneNumbers);
                        item.iconName = _systemObjectIconNames.user;
                        item.invitedByUserId = ko.observable(result.invitedByUserId);
                        item.inviteSentDateTime = ko.observable(result.inviteSentDateTime);
                        item.inviteStatus = ko.observable(result.inviteStatus);
                        item.location = _company.location;
                        item.phoneNumber = ko.observable(result.phoneNumber);
                        item.title = ko.observable(result.title);
                        item.typeDisplay = TYPE_DISPLAY.user;
                        _addExtension(item);
                        _users.push(item);
                        break;
                    case TYPES.userGroup:
                        item.avatars = ko.observable(_userGroupAvatars(result.avatars));
                        item.extension = ko.observable(result.extension);
                        item.extensionAssignedDateTime = ko.observable(new DateTimeValueConstructor(result.extensionAssignedDateTime));
                        item.hostedPhoneNumbers = ko.observableArray(result.hostedPhoneNumbers);
                        item.iconName = _systemObjectIconNames.userGroup;
                        item.location = _company.location;
                        item.members = ko.observableArray(result.members);
                        item.typeDisplay = TYPE_DISPLAY.userGroup;
                        _addExtension(item);
                        _userGroups.push(item);
                        break;
                    case TYPES.voicemailBox:
                        item.greetingId = ko.observable(result.greetingId);
                        item.iconName = _systemObjectIconNames.voicemailBox;
                        item.location = _company.location;
                        item.typeDisplay = TYPE_DISPLAY.voicemailBox;
                        item.ownerId = ko.observable();
                        if (result.ownerId) {
                            let owner = self.get(result.ownerId);
                            item.ownerId(owner.id);
                            item.name = ko.pureComputed(() => owner.name());
                            item.ownerType = owner.type;
                            item.ownerAvatar = owner.avatar;
                            item.ownerTypeDisplay = owner.typeDisplay;
                        } else {
                            item.ownerType = TYPES.standalone;
                            item.ownerTypeDisplay = TYPE_DISPLAY.standalone;
                        }
                        item.subscribers = ko.observableArray(result.subscribers);
                        item.isSelectable = ko.pureComputed(() => {
                            let value = false;
                            if (item.ownerId()) {
                                let owningUser = _users().find(user => user.id === item.ownerId());
                                let owningUserGroup = _userGroups().find(userGroup => userGroup.id === item.ownerId());
                                value = owningUser !== undefined ? owningUser.isSelectable() : owningUserGroup.isSelectable();
                            } else {
                                value = true;
                            }
                            return value;
                        });

                        _voicemailBoxes.push(item);
                        break;
                }

                _commonState[_id] = item;
            });

            self.clear = () => {
                _commonState = [];
                _autoAttendants([]);
                _company.location('');
                _company.postalAddress('');
                _extensions([]);
                _forwardingNumbers([]);
                _hostedNumbers([]);
                _prompts([]);
                _schedules([]);
                _scheduleSegments([]);
                _status(NOT_READY);
                _users([]);
                _userGroups([]);
                _lastUpdated = "";
                _systemGroupAliases = [];
                _voicemailBoxes([]);
            };

            self.init = (_promiseFactory) => {
                return _promiseFactory.defer(promise => {
                    switch (_status()) {
                        case NOT_READY:
                            _status(READYING);
                            _socket.send("get_initial_common_state", {}, function (results) {
                                if (results.status !== 'unauthorized') {
                                    _lastUpdated = results.lastUpdated;

                                    // sorting so that voicemailBox is processed last
                                    const sorter = new SorterConstructor();
                                    sorter.sort(results.commonState, "type");

                                    results.commonState.forEach(result => _addCommonStateItem(result));
                                    _status(READY);
                                }
                                promise.resolve();
                            });
                            break;
                        case READY:
                            promise.resolve();
                            break;
                        case READYING:
                            const waitForReady = _status.subscribe(currentStatus => {
                                if (currentStatus === READY) {
                                    promise.resolve();
                                    waitForReady.dispose();
                                }
                            });
                            break;
                    }
                });
            };

            self.get = (id => {
                if (id) {
                    const _id = _guidUtil.maybeGuidToId(id);
                    const actualId = _systemGroupAliases[_id] ? _systemGroupAliases[_id] : _id;
                    return _commonState[actualId];
                }
            });
            self.resolveGroupIdToGuid = (id) => {
                if (id) {
                    const formalId = _guidUtil.maybeGuidToId(id);

                    if (_systemGroupIdToGuid[formalId]) {
                        return _systemGroupIdToGuid[formalId];
                    } else {
                        return formalId;
                    }
                } else {
                    return id;
                }
            };

            self.autoAttendants = () => _autoAttendants().filter(autoAttendant => autoAttendant.isDeleted() !== true);
            self.extensions = () => _extensions();
            self.forwardingNumbers = () => _forwardingNumbers().filter(forwardingNumber => forwardingNumber.isDeleted() !== true);
            self.hostedNumbers = () => _hostedNumbers().filter(hostedNumber => hostedNumber.isDeleted() !== true);
            self.prompts = () => _prompts().filter(prompt => prompt.isDeleted() !== true);
            self.schedules = () => _schedules().filter(schedule => schedule.isDeleted() !== true);
            self.scheduleSegments = () => _scheduleSegments().filter(scheduleSegment => scheduleSegment.isDeleted() !== true);
            self.scheduleSegmentsForSchedule = (scheduleId) => _scheduleSegments().filter(segment => segment.scheduleId === scheduleId);
            self.users = () => _users();
            self.userGroups = ko.pureComputed(() => _userGroups()).extend({trackArrayChanges: true});
            self.userGroupsForUserId = (userId) => _userGroups().filter(userGroup => userGroup.members().includes(userId));
            self.voicemailBoxes = () => _voicemailBoxes().filter(voicemailBox => voicemailBox.isDeleted() !== true);
            self.companyLocation = _company.location;
            self.companyPostalAddress = _company.postalAddress;
            self.types = TYPES;
            self.typeDisplay = TYPE_DISPLAY;
        };

        if (CommonStateSingleton.prototype._singletonInstance) {
            return CommonStateSingleton.prototype._singletonInstance;
        } else {
            return new CommonStateSingleton();
        }
    }
);

