define('presentation/settings/userProfile/facades/resendInviteModalFacade',[
    'persistence/webSocket/webSocketApp'
], function () {
    return function () {
        const self = this;

        let _promiseFactory = null;
        let _webSocketApp = null;

        self.resendInvite = (userId) => {
            return _promiseFactory.defer((promise) => {
                const params = {
                    userId: userId
                };
                _webSocketApp.send("resendInvite", params, () => {
                    promise.resolve();
                });
            });
        };

        self.init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("user_profile");
        };
    };
});

