define('presentation/analytics/validators/analyticsSidebarViewModelValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        let _commonValidator = null;
        let _analyticsSidebarViewModel = null;

        self.registerViewModel = function(analyticsSidebarViewModel) {
            const validationMessageBase = _validationMessageEnumerations.clientui.presentation.analytics.sidebar;
            const validationRules = new ValidationRulesConstructor();

            validationRules.field("duringCustomStartOn")
                .addValidation(_isValidCustomStartDate, validationMessageBase.invalidStartDate);
            validationRules.field("duringCustomEndOn")
                .addValidation(_isValidCustomEndDate, validationMessageBase.invalidEndDate);

            _commonValidator = new CommonValidatorConstructor(analyticsSidebarViewModel, validationRules);
            _analyticsSidebarViewModel = analyticsSidebarViewModel;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        const _isValidCustomStartDate = function() {
            if (_analyticsSidebarViewModel.selectedDuringFilter() === 'custom') {
                const startOnDateText = _analyticsSidebarViewModel.duringCustomStartOn();
                const startOnDate = new Date(startOnDateText);
                if (_commonValidator.isDate(startOnDate)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        };

        const _isValidCustomEndDate = function() {
            if (_analyticsSidebarViewModel.selectedDuringFilter() === 'custom') {
                const endOnDateText = _analyticsSidebarViewModel.duringCustomEndOn();
                const endOnDate = new Date(endOnDateText);
                if (_commonValidator.isDate(endOnDate)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        };
    };
});

