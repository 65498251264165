define('persistence/dataProviders/autoAttendantVoiceSubMenuDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_autoattendant_voice_submenu', 'GET')
                    .done(function(result) {
                            deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(autoAttendantVoiceSubMenuId) {
            if (autoAttendantVoiceSubMenuId === null) {
                var err = new Error("Missing autoAttendantVoiceSubMenuId");
                
                err.lobbyAutoAttendantVoiceSubMenu = {
                    autoAttendantVoiceSubMenuId: autoAttendantVoiceSubMenuId
                };

                throw err;

            } else {
                
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_autoattendant_voice_submenu/' + autoAttendantVoiceSubMenuId, 'GET')
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.autoAttendantVoiceSubMenuId = autoAttendantVoiceSubMenuId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

