define('presentation/signup/viewModels/signupProfileViewModel',[
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'constants/verificationQuestionConstants',
    'presentation/signup/common/signupNavigationManager',
    'presentation/signup/facades/signupProfileFacade',
    'presentation/signup/validators/signupProfileValidator',

], function() {

    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');
        const _verificationQuestionsConstants = require('constants/verificationQuestionConstants');
        const KeyValueConverterConstructor = require('common/collections/enumerationKeyValueConverter');

        const VERIFICATION_QUESTIONS_LIST = new KeyValueConverterConstructor.convertToKeyValues(_verificationQuestionsConstants);
        const DEFAULT_VERIFICATION_QUESTION_ID = VERIFICATION_QUESTIONS_LIST[0].value;

        let _facade = null;
        let _validator = null;
        let _disposables = [];
        let _signupNavigationManager = null;

        self.firstName = ko.observable('').extend({ rateLimit: 500 });
        self.lastName = ko.observable('').extend({ rateLimit: 500 });
        self.password = ko.observable('').extend({ rateLimit: 500 });
        self.businessName = ko.observable('').extend({ rateLimit: 500 });
        self.mobileNumber = ko.observable('').extend({ rateLimit: 500 });
        self.verificationQuestion = ko.observable([]);
        self.verificationAnswer = ko.observable('').extend({ rateLimit: 500 });
        self.countryAbbreviation = ko.observable('us');
        self.isCompositionComplete = ko.observable(false);
        self.isNavigateToNextPageActive = ko.observable(true);
        self.isScrollActive = ko.observable(false);
        self.isValid = ko.observable(false);
        self.passwordFieldHasFocus = ko.observable(false);
        self.shouldHidePassword = ko.observable(true).toggleable();
        self.signupPhoneNumbers = null;
        self.signupNumberType = null;
        self.verificationQuestions = null;
        self.noResults = "No Results Found";

        self.togglePasswordVisible = () => {
            self.shouldHidePassword.toggle();
            const input = document.getElementById('txt_password');
            input.type = self.shouldHidePassword() ? 'password' : 'text';
        };

        self.onForwardArrowClicked = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            deferredObject.resolve(false);
                        } else {
                            _facade.checkIsFraudulentMobileNumber(self.mobileNumber(), self.countryAbbreviation())
                                .fail(deferredObject.reject)
                                .done((isFraudulent) => {
                                    if (isFraudulent)
                                    {
                                        _signupNavigationManager.navigateToErrorPage();
                                        deferredObject.resolve(false);
                                        return;
                                    }

                                    _facade.setProfileCompleted(true)
                                        .fail(deferredObject.reject)
                                        .done(() => deferredObject.resolve(true));
                                });
                        }
                    });
            });
        };

        const _handleFirstNameChanged = () => {
            if (self.firstName()) {
                _facade.saveFirstName(self.firstName());
            }
        };

        const _handleLastNameChanged = () => {
            if (self.lastName()) {
                _facade.saveLastName(self.lastName());
            }
        };

        const _handlePasswordChanged = () => {
            if (self.password()) {
                _facade.savePassword(self.password());
            }
        };

        const _handleBusinessNameChanged = () => {
            if (self.businessName()) {
                _facade.saveBusinessName(self.businessName());
            }
        };

        const _handleMobileNumberChanged = () => {
            if (self.mobileNumber.isValid()) {
                let cleanPhoneNumber = self.mobileNumber().replace('+1', '');
                _facade.saveMobileNumber(cleanPhoneNumber);
            }
        };

        const _handleCountryAbbreviationChanged = () => {
            if (self.countryAbbreviation()) {
                _facade.saveMobileNumberCountryCode(self.countryAbbreviation());
            }
        };

        const _handleVerificationAnswerChanged = () => {
            if (self.verificationAnswer()) {
                _facade.saveVerificationAnswer(self.verificationAnswer());
            }
        };

        const _handleVerificationQuestionChanged = () => {
            const verificationQuestionId = parseInt(self.verificationQuestion()[0]);
            if (verificationQuestionId) {
                _facade.saveVerificationQuestion(verificationQuestionId);
            }
        };

        const _prePopulateInputValues = (signupProfilePresentationObject) => {
            const { firstName, lastName, password, businessName, mobileNumber, mobileNumberCountryCode, verificationQuestionId, verificationAnswer } = signupProfilePresentationObject;
            if (firstName) {
                self.firstName(firstName);
            }
            if (lastName) {
                self.lastName(lastName);
            }
            if (password) {
                self.password(password);
            }
            if (businessName) {
                self.businessName(businessName);
            }
            if (mobileNumber) {
                self.mobileNumber(mobileNumber);
            }
            if (mobileNumberCountryCode) {
                self.countryAbbreviation(mobileNumberCountryCode);
            }

            const isValidVerificationQuestionId = VERIFICATION_QUESTIONS_LIST.some(question => question.value === verificationQuestionId);
            if (verificationQuestionId && isValidVerificationQuestionId) {
                self.verificationQuestion([verificationQuestionId]);
            } else {
                self.verificationQuestion([DEFAULT_VERIFICATION_QUESTION_ID]);
                _handleVerificationQuestionChanged();
            }

            if (verificationAnswer) {
                self.verificationAnswer(verificationAnswer);
            }

            if (firstName && lastName && password && mobileNumber && verificationQuestionId && verificationAnswer) {
                self.isValid(true);
            }
        };

        const _populateVerificationQuestionsDropdown = () => {
            self.verificationQuestions = VERIFICATION_QUESTIONS_LIST.map((question) => {
                question.id = question.value;
                question.text = _i18n.t(`signupProfile:${question.key}`);
                return question;
            });
        };

        self.validatePassword = () => {
            return _promiseFactory.defer((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        const accountInfo = {
                            userPassword: self.password()
                        };

                        deferredObject.resolve({isValid: isValid, accountInfo: accountInfo});
                    });
            });
        };

        self.detached = () => {
            _disposables.forEach(subscription => subscription.dispose());
            _disposables = [];
        };
        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/signup/facades/signupProfileFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/signup/validators/signupProfileValidator');
            _validator = new ValidatorConstructor();

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self, _facade);

            _promiseFactory.deferIndefinitely(deferredObject => {
                _populateVerificationQuestionsDropdown();

                _facade.loadSignupSession()
                    .fail(deferredObject.reject)
                    .done((signupProfilePresentationObject) => {
                        _prePopulateInputValues(signupProfilePresentationObject);

                        self.signupPhoneNumbers = signupProfilePresentationObject.signupPhoneNumbers;
                        self.signupNumberType = signupProfilePresentationObject.signupNumberType;

                        _disposables.push(self.businessName.subscribe(_handleBusinessNameChanged));
                        _disposables.push(self.countryAbbreviation.subscribe(_handleCountryAbbreviationChanged));
                        _disposables.push(self.firstName.isValid.subscribe(_handleFirstNameChanged));
                        _disposables.push(self.lastName.isValid.subscribe(_handleLastNameChanged));
                        _disposables.push(self.mobileNumber.isValid.subscribe(_handleMobileNumberChanged));
                        _disposables.push(self.password.isValid.subscribe(_handlePasswordChanged));
                        _disposables.push(self.verificationAnswer.isValid.subscribe(_handleVerificationAnswerChanged));
                        _disposables.push(self.verificationQuestion.subscribe(_handleVerificationQuestionChanged));

                        deferredObject.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});

