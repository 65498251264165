define('presentation/contacts/viewModels/contactMetaViewModel',[
    'common/promises/promiseFactory'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    return function() {
        const self = this;

        const _promiseFactory = new PromiseFactory();

        self.contactSource = ko.observable('');
        self.createdDateTime = ko.observable(null);
        self.modifiedDateTime = ko.observable(null);

        self.activate = (/** @type { IContactMetaViewModelActivationObject } */activationObject) => {
            const {
                contactSource,
                createdDateTime,
                modifiedDateTime
            } = activationObject;

            self.contactSource = contactSource;
            self.createdDateTime = createdDateTime;
            self.modifiedDateTime = modifiedDateTime;

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
