define('presentation/acceptUserInvite/viewModels/createUserProfileViewModel',[
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'businessServices/country/timeZones',
    'common/files/encoding',
    'presentation/acceptUserInvite/validators/createUserProfileValidator',
],
function() {
    return function(_userProfile) {
        const self = this;

        let _validator = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const TimeZoneProviderConstructor = require('businessServices/country/timeZones');
        const _timeZoneProvider = new TimeZoneProviderConstructor();

        self.avatar = ko.observable();
        self.firstName = ko.observable("");
        self.lastName = ko.observable("");
        self.title = ko.observable("");
        self.timeZone = ko.observable("");

        const _availableTimezones = _timeZoneProvider.getSortedTimeZones();
        self.timeZones = _availableTimezones.map((timeZone) => {
            timeZone.id = timeZone.key;
            timeZone.text = timeZone.name;
            return timeZone;
        });

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                _validator.validate()
                    .fail(promise.reject)
                    .done((isValid) => {
                        promise.resolve(isValid);
                    });
            });
        };

        self.activate = () => {
            const ValidatorConstructor = require('presentation/acceptUserInvite/validators/createUserProfileValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.avatar = _userProfile.avatar;
            self.firstName = _userProfile.firstName;
            self.lastName = _userProfile.lastName;
            self.title = _userProfile.title;
            self.timeZone = _userProfile.timeZone;

            _validator.registerViewModel(self);

            return _promiseFactory.wait();
        };
    };
});

