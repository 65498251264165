define('presentation/common/snackbar/viewModels/freeTrialMessagingSnackbarViewModel',[
], function() {
    return function() {
        const self = this;

        self.messageKey = ko.pureComputed(() => {
            return 'message';
        });

        self.activate = () => {

            _initialize();
        };

        const _initialize = () => {

        };
    };
});

