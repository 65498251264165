define('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceLocalPresentationObject',[],function() {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceLocalPresentationObject') } MessagingComplianceLocalPresentationObject } */

        /** @typedef { MessagingComplianceLocalPresentationObject }*/
        const self = this;

        self.smsComplianceLocalId = "";
        self.useCase = ko.observable("");
        self.subUseCases = ko.observable("");
        self.summaryOfTexting = ko.observable("");
        self.sampleTextMessageOne = ko.observable("");
        self.sampleTextMessageTwo = ko.observable("");
        self.sampleTextMessageThree = ko.observable("");
        self.sampleTextMessageFour = ko.observable("");
        self.isCustomerConsentEmployeeRelationship = ko.observable(false);
        self.isCustomerConsentInitiateConversation = ko.observable(false);
        self.isCustomerConsentLeadCaptureForms = ko.observable(false);
        self.isCustomerConsentOnlineCheckout = ko.observable(false);
        self.isCustomerConsentOther = ko.observable(false);
        self.isCustomerConsentPosTransaction = ko.observable(false);
        self.customerConsentOther = ko.observable("");
        self.isIneligibleUseCaseAcknowledged = ko.observable(false);
        self.mobilePhoneNumber = ko.observable("");
        self.mobilePhoneNumberCountryAbbreviation = ko.observable("");
        self.hasLocalNumberWithMessagingEnabled = ko.observable(false);
        self.isLocalSmsFormEnabled = ko.observable(false);
    };
});

