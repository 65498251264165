define('entities/forwardingNumbersEntity',[],function () {
    return function () {
        var self = this;
        self.forwardingNumberId = null;
        self.forwardingNumberName = null;
        self.extension = null;
        self.phoneNumber = null;
        self.countryAbbreviation = null;
        self.countryCode = null;
        self.modifiedDateTime = null;
        self.createdDateTime = null;
        self.kazooDeviceId = null;
        self.kazooVoicemailBoxId = null;
    };
});

