define('presentation/common/frontEndHeader/facades/frontEndHeaderFacade',['businessServices/authentication/authenticationManager',
        'common/promises/promiseFactory'],
function () {
    return function () {
        var self = this;

        var _authenticationManager = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.logUserOut = function () {
            return _promiseFactory.defer(function(promise) {
                _authenticationManager.logout()
                    .done(function() {
                        promise.resolve();
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        };

        self.init = function () {
            _authenticationManager = require('businessServices/authentication/authenticationManager');
        };
    };
});

