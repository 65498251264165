// Based on the SoundMeter class in volume sample on https://github.com/webrtc/samples/tree/gh-pages/src/content/getusermedia/volume
define('presentation/common/audioRecorder/audioRecorderSoundMeter',[],
    function () {
        return function() {
            const self = this;

            let _volume = 0.0;

            const _initialize = (context) => {
                self.context = context;
                self.script = context.createScriptProcessor(2048, 1, 1);
                self.script.onaudioprocess = (event) => {
                    const input = event.inputBuffer.getChannelData(0);

                    let i;
                    let sum = 0.0;
                    for (i = 0; i < input.length; ++i) {
                        sum += input[i] * input[i];
                    }

                    _volume = Math.sqrt(sum / input.length);
                };
            };

            const _connectToSource = (stream, callback) => {
                try {
                    self.mic = self.context.createMediaStreamSource(stream);
                    self.mic.connect(self.script);
                    self.script.connect(self.context.destination);
                    if (typeof callback !== 'undefined') {
                        callback(null);
                    }
                } catch (e) {
                    if (typeof callback !== 'undefined') {
                        callback(e);
                    }
                }
            };

            const _getCurrentVolume = () => {
               return (_volume * 1000).toFixed();
            };

            const _stop = () => {
                if (self.mic) {
                    self.mic.disconnect();
                }
                self.script.disconnect();
            };

            return {
                initialize : _initialize,
                connectToSource: _connectToSource,
                getCurrentVolume: _getCurrentVolume,
                stop: _stop

            };
        };
    });
