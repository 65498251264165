define('presentation/acceptUserInvite/presentationObjects/createUserProfilePresentationObject',[],function() {
    return function(){
        var self = this;

        self.userId = null;
        self.avatar = null;
        self.firstName = "";
        self.lastName = "";
        self.emailAddress = "";
        self.verificationQuestion = "";
        self.verificationAnswer = "";
        self.timeZone = "";
    };
});

