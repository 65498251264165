define('presentation/settings/billing/makeAPayment/facades/makeAPaymentFacade',[
    'businessServices/converters/currencyFormatter',
    'businessServices/creditCards/creditCardTypes',
    'businessServices/payments/paymentProcessor',
    'businessServices/specifications/validCreditCardSpecification',
    'common/time/date',
    'common/promises/promiseFactory',
    'externalDependencies/kazooApi',
    'persistence/dataProviders/accountDataProvider',
    'persistence/dataProviders/paymentMethodDataProvider',
    'persistence/repositories/payBalanceRepository',
    'presentation/settings/billing/history/presentationObjects/billingHistoryPresentationObject',
    'presentation/settings/billing/paymentMethod/presentationObjects/paymentMethodPresentationObject'
], function () {
    const BillingHistoryPresentationObjectConstructor = require('presentation/settings/billing/history/presentationObjects/billingHistoryPresentationObject');
    const PaymentMethodPresentationObjectConstructor = require('presentation/settings/billing/paymentMethod/presentationObjects/paymentMethodPresentationObject');

    const CreditCardConstructor = require('businessServices/creditCards/creditCardTypes');
    const _creditCard = new CreditCardConstructor();

    const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
    const _currencyFormatter = new CurrencyFormatterConstructor();

    const DateConstructor = require('common/time/date');
    const _date = new DateConstructor();

    const ValidCreditCardSpecification = require('businessServices/specifications/validCreditCardSpecification');
    const _validCreditCardSpecification = new ValidCreditCardSpecification();

    let _accountDataProvider = null;
    let _kazooApi = null;
    let _paymentMethodDataProvider = null;
    let _payBalanceRepository = null;
    let _promiseFactory = null;

    const _applyPayment = (paymentMethodId, paymentAmount) => {
        return _promiseFactory.defer((deferredObject) => {
            _payBalanceRepository.applyPayment(paymentMethodId, paymentAmount)
                .done(deferredObject.resolve)
                .fail(deferredObject.reject);
        });
    };

    const _getAllPaymentMethods = () => _promiseFactory.defer((deferredObject) => {
        _paymentMethodDataProvider.getAll()
            .done((paymentMethods) => {
                const paymentMethodsFormatted = paymentMethods.map((currentPaymentMethod) => _buildPaymentMethodPresentationObject(currentPaymentMethod));
                deferredObject.resolve(paymentMethodsFormatted);
            })
            .fail(deferredObject.reject);
    });

    const _buildPaymentMethodPresentationObject = (currentPaymentMethod) => {
        const paymentMethodPresentationObject = new PaymentMethodPresentationObjectConstructor();
        paymentMethodPresentationObject.paymentMethodId = currentPaymentMethod.paymentMethodId;
        paymentMethodPresentationObject.cardHolderName = currentPaymentMethod.cardHolderName;
        paymentMethodPresentationObject.expirationDate = _date.formatShortExpirationDate(currentPaymentMethod.expirationDate);
        paymentMethodPresentationObject.isExpired(_isPaymentMethodExpired(currentPaymentMethod.expirationDate));
        paymentMethodPresentationObject.lastFourDigits = currentPaymentMethod.lastFourDigits;
        paymentMethodPresentationObject.isDefault(currentPaymentMethod.isDefault);
        const creditCard = _creditCard.getCreditCardMaskedDisplay(currentPaymentMethod.cardType, currentPaymentMethod.lastFourDigits);
        paymentMethodPresentationObject.displayedCardName = creditCard.displayCardName;
        paymentMethodPresentationObject.cardCssType = creditCard.cssClass;
        paymentMethodPresentationObject.cardType = creditCard.type;
        const creditCardLongDisplay = _creditCard.getCreditCardLongDisplay(currentPaymentMethod.cardType, currentPaymentMethod.lastFourDigits, currentPaymentMethod.expirationDate);
        paymentMethodPresentationObject.longDescription = creditCardLongDisplay.longDisplay;
        return paymentMethodPresentationObject;
    };

    const _isPaymentMethodExpired = (expirationDate) => {
        return _validCreditCardSpecification.isCreditCardExpired(expirationDate);
    };

    const _getBillingHistory = () => _promiseFactory.defer((deferredObject) => {
        _kazooApi.callAccount('/lobby_billing_history', 'GET')
            .done((result) => {
                let billingHistoryResponse = result.data.data;
                let billingHistoryPresentationObject = new BillingHistoryPresentationObjectConstructor();
                billingHistoryPresentationObject.currentBalance = _currencyFormatter.formatUSDForDisplay(billingHistoryResponse.currentBalance);
                billingHistoryPresentationObject.pastDueBalance = _currencyFormatter.formatUSDForDisplay(billingHistoryResponse.pastDueBalance);
                deferredObject.resolve(billingHistoryPresentationObject);
            })
            .fail(deferredObject.reject);
    });

    const _getCustomerBalanceData = () => {
        return _promiseFactory.defer((deferredObject) => {
            _accountDataProvider.getBalanceInformation()
                .fail(deferredObject.reject)
                .done((response) => {
                    const balanceData = {
                        currentBalance: _currencyFormatter.formatUSDForDisplay(response.currentBalance),
                        pastDueBalance: _currencyFormatter.formatUSDForDisplay(response.pastDueBalance)
                    };
                    deferredObject.resolve(balanceData);
                });
        });
    };

    const _isInFreeTrial = () => {
        return _promiseFactory.defer((deferredObject) => {
            _paymentMethodDataProvider.getFreeTrialInfo()
                .fail(deferredObject.reject)
                .done((result) => {
                    const { isInFreeTrial } = result;
                    deferredObject.resolve(isInFreeTrial);
                });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const AccountDataProviderConstructor = require('persistence/dataProviders/accountDataProvider');
        _accountDataProvider = new AccountDataProviderConstructor();

        const KazooConstructor = require('externalDependencies/kazooApi');
        _kazooApi = new KazooConstructor();

        const PaymentMethodDataProviderConstructor = require('persistence/dataProviders/paymentMethodDataProvider');
        _paymentMethodDataProvider = new PaymentMethodDataProviderConstructor();
        _paymentMethodDataProvider.init();

        const PayBalanceRepositoryConstructor = require('persistence/repositories/payBalanceRepository');
        _payBalanceRepository = new PayBalanceRepositoryConstructor();
        _payBalanceRepository.init();
    };

    return function () {
        let self = this;

        self.init = _init;
        self.applyPayment = _applyPayment;
        self.getAllPaymentMethods = _getAllPaymentMethods;
        self.getBillingHistory = _getBillingHistory;
        self.getCustomerBalanceData = _getCustomerBalanceData;
        self.isInFreeTrial = _isInFreeTrial;
    };
});

