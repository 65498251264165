define('constants/tollfreeUseCaseConstants',[], function() {
    return {
        TwoFactorAuthentication: "2FA",
        AppNotifications: "App Notifications",
        Appointments: "Appointments",
        Auctions: "Auctions",
        AutoRepairServices: "Auto Repair Services",
        BankTransfers: "Bank Transfers",
        Billing: "Billing",
        BookingConfirmations: "Booking Confirmations",
        BusinessUpdates: "Business Updates",
        CareerTraining: "Career Training",
        Chatbot: "Chatbot",
        ConversationalAlerts: "Conversational / Alerts",
        CourierServicesDeliveries: "Courier Services & Deliveries",
        Covid19Alerts: "COVID-19 Alerts",
        EmergencyAlerts: "Emergency Alerts",
        EventsPlanning: "Events & Planning",
        FinancialServices: "Financial Services",
        FraudAlerts: "Fraud Alerts",
        Fundraising: "Fundraising",
        GeneralMarketing: "General Marketing",
        GeneralSchoolUpdates: "General School Updates",
        HealthcareAlerts: "Healthcare Alerts",
        HousingCommunityUpdates: "Housing Community Updates",
        HrStaffing: "HR / Staffing",
        InsuranceServices: "Insurance Services",
        JobDispatch: "Job Dispatch",
        LegalServices: "Legal Services",
        Mixed: "Mixed",
        MotivationalReminders: "Motivational Reminders",
        NotaryNotifications: "Notary Notifications",
        OrderNotifications: "Order Notifications",
        Political: "Political",
        PublicWorks: "Public Works",
        RealEstateServices: "Real Estate Services",
        ReligiousServices: "Religious Services",
        RepairAndDiagnosticsAlerts: "Repair and Diagnostics Alerts",
        RewardsProgram: "Rewards Program",
        Surveys: "Surveys",
        SystemAlerts: "System Alerts",
        VotingReminders: "Voting Reminders",
        WaitListAlerts: "Waitlist Alerts",
        WebinarReminders: "Webinar Reminders",
        WorkshopAlerts: "Workshop Alerts"
    };
});

