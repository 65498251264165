define('externalDependencies/clientResult',[], function() {

    return function() {
        let self = this;

        self.success = false;
        self.errorMessage = null;
        self.data = null;
    };
});
