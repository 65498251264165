define('presentation/common/userGroupAvatar/viewModels/userGroupAvatarViewModel',[
    'common/storage/commonState',
    'constants/systemObjectIconNameConstants'
], function(){
    return function() {
        const self = this;

        const _commonState = require('common/storage/commonState');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _avatars = null;
        let _userGroupId = null;
        let _settings = null;

        const _generateUserGroupImage = () => {
            if (_userGroupId) {
                const _commonStateGroup = _commonState.get(_userGroupId);
                _avatars = _commonStateGroup ? _commonStateGroup.avatars() : null;
                if (_avatars) {
                    self.avatars = _avatars.map((avatar, index) => {
                        return _prepareAvatarForDisplay(avatar, index);
                    });
                }
            }
        };

        const _prepareAvatarForDisplay = (avatar, index) => {

            if (_avatars.length > 3) {
                avatar.containerHeight = self.quarterIconContainerHeightBinding;
                avatar.containerWidth = self.quarterIconContainerWidthBinding;
                avatar.groupIconHeight = self.quarterIconHeightBinding;
                avatar.groupIconWidth = self.quarterIconWidthBinding;
                avatar.iconName = _systemObjectIconNames.user;
                switch (index) {
                    case 0:
                        avatar.containerCss = 'icon-group-quarter icon-group-quarter-top-left';
                        break;
                    case 1:
                        avatar.containerCss = 'icon-group-quarter icon-group-quarter-top-right';
                        break;
                    case 2:
                        avatar.containerCss = 'icon-group-quarter icon-group-quarter-bottom-left';
                        break;
                    case 3:
                        avatar.containerCss = 'icon-group-quarter icon-group-quarter-bottom-right';
                        break;
                    default:
                        avatar.containerCss = '';
                        break;
                }
                return avatar;
            } else if (_avatars.length === 3) {
                let isFirstMember = index === 0;
                avatar.containerHeight = isFirstMember ? self.halfIconContainerHeightBinding : self.quarterIconContainerHeightBinding;
                avatar.containerWidth = isFirstMember ? self.halfIconContainerWidthBinding : self.quarterIconContainerWidthBinding;
                avatar.groupIconHeight = isFirstMember ? self.halfIconHeightBinding : self.quarterIconHeightBinding;
                avatar.groupIconWidth = isFirstMember ? self.halfIconWidthBinding : self.quarterIconWidthBinding;
                avatar.iconName = _systemObjectIconNames.user;
                switch (index) {
                    case 0:
                        avatar.containerCss = 'icon-group-half icon-group-half-left';
                        break;
                    case 1:
                        avatar.containerCss = 'icon-group-quarter icon-group-quarter-top-right';
                        break;
                    case 2:
                        avatar.containerCss = 'icon-group-quarter icon-group-quarter-bottom-right';
                        break;
                    default:
                        avatar.containerCss = '';
                        break;
                }
                return avatar;
            }  else if (_avatars.length === 2)  {
                avatar.containerHeight = self.halfIconContainerHeightBinding;
                avatar.containerWidth = self.halfIconContainerWidthBinding;
                avatar.groupIconHeight = self.halfIconHeightBinding;
                avatar.groupIconWidth = self.halfIconWidthBinding;
                avatar.iconName = _systemObjectIconNames.user;
                switch (index) {
                    case 0:
                        avatar.containerCss = 'icon-group-half icon-group-half-left';
                        break;
                    case 1:
                        avatar.containerCss = 'icon-group-half icon-group-half-right';
                        break;
                    default:
                        avatar.containerCss = '';
                        break;
                }
                return avatar;
            } else {
                avatar.containerCss = '';
                avatar.containerHeight = self.fullIconContainerHeightBinding;
                avatar.containerWidth = self.fullIconContainerWidthBinding;
                avatar.groupIconHeight = self.fullIconHeightBinding;
                avatar.groupIconWidth = self.fullIconWidthBinding;
                avatar.iconName = _systemObjectIconNames.userGroup;
                avatar.showImage = false;
                return avatar;
            }
        };

        self.groupAvatarContainerHeight = ko.observable(48);
        self.groupAvatarContainerWidth = ko.observable(48);

        self.quarterIconContainerHeightBinding = ko.pureComputed(() => (self.groupAvatarContainerHeight() / 2.0) - 1);
        self.quarterIconContainerWidthBinding = ko.pureComputed(() => (self.groupAvatarContainerWidth() / 2.0) - 1);
        self.quarterIconHeightBinding = ko.pureComputed(() => self.quarterIconContainerHeightBinding() - 4.0);
        self.quarterIconWidthBinding = ko.pureComputed(() => self.quarterIconContainerWidthBinding() - 4.0);

        self.halfIconContainerHeightBinding = ko.pureComputed(() => self.groupAvatarContainerHeight());
        self.halfIconContainerWidthBinding = ko.pureComputed(() => (self.groupAvatarContainerWidth() / 2.0) - 1);
        self.halfIconHeightBinding = ko.pureComputed(() => self.halfIconContainerHeightBinding() - 2.0);
        self.halfIconWidthBinding = ko.pureComputed(() => self.halfIconContainerWidthBinding() - 2.0);

        self.fullIconContainerHeightBinding = ko.pureComputed(() => self.groupAvatarContainerHeight());
        self.fullIconContainerWidthBinding = ko.pureComputed(() => self.groupAvatarContainerWidth());
        self.fullIconHeightBinding = ko.pureComputed(() => (self.fullIconContainerHeightBinding() / 2.0) + 4);
        self.fullIconWidthBinding = ko.pureComputed(() => (self.fullIconContainerWidthBinding() / 2.0) + 4);

        self.avatars = ko.observableArray([]);
        self.avatarCount = ko.pureComputed(() => self.avatars().length);

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            if (_settings.userGroupId) {
                _userGroupId = ko.unwrap(_settings.userGroupId);
            }
            if (_settings.groupAvatarContainerHeight) {
                let containerHeight = ko.unwrap(_settings.groupAvatarContainerHeight);
                self.groupAvatarContainerHeight(containerHeight);
            }
            if (_settings.groupAvatarContainerWidth) {
                let containerWidth = ko.unwrap(_settings.groupAvatarContainerWidth);
                self.groupAvatarContainerWidth(containerWidth);
            }

            _generateUserGroupImage();
        };
    };
});

