define('presentation/common/callFlow/presentationObjects/subMenuPresentationObject',['common/storage/commonState'], function() {
    return function(){
        let self = this;

        const _commonState = require('common/storage/commonState');

        self.name =  ko.observable(null);
        self.voicePrompt = ko.observable(null);
        self.routeType = ko.observable("");
        self.subMenuItems =  ko.observableArray([]);
        self.autoAttendant = ko.observable(null);
        self.subMenu = ko.observable(null);
        self.showCallFlowDetails = ko.observable(true);

        self.isAutoAttendant = ko.computed(() => self.routeType() === _commonState.types.autoAttendant);
        self.isForwardingNumber = ko.computed(() => self.routeType() === _commonState.types.forwardingNumber);
        self.isSchedule = ko.computed(() => self.routeType() === _commonState.types.schedule);
        self.isSubMenu = ko.computed(() => self.routeType() === _commonState.types.subMenu);
        self.isUser = ko.computed(() => self.routeType() === _commonState.types.user);
        self.isUserGroup = ko.computed(() => self.routeType() === _commonState.types.userGroup);
        self.isVoicePrompt = ko.computed(() => self.routeType() === _commonState.types.prompt);
    };
});

