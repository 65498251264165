define('externalDependencies/clientApi',['businessServices/events/eventManager',
    'externalDependencies/clientResult',
    'externalDependencies/configuration/clientApiConfiguration',
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory'
], function() {

    const ClientResultConstructor = require('externalDependencies/clientResult');
    const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();
    let _eventManager = null;
    let _apiUrl = null;

    return function() {
        let self = this;

        let _updateConfigurationValues = (clientConfiguration) => {
            _apiUrl = clientConfiguration.apiUrl;
        };

        self.init = () => {
            _eventManager = require('businessServices/events/eventManager');

            let clientConfiguration = require('externalDependencies/configuration/clientApiConfiguration');
            _updateConfigurationValues(clientConfiguration);
        };

        self.call = (url, method, requestData, contentType) => {
            return _promiseFactory.defer((promise) => {

                let actualContentType = contentType || 'application/json';
                let fullUrl = _apiUrl + url;

                let errorHandler = (jQueryRequest, textStatus, exceptionThrown) => {
                    let jQueryResponse;
                    let requestStatus;
                    let exceptionMessage;

                    if (jQueryRequest.status === 401) {
                        _eventManager.publishAuthenticationExpired();
                        promise.reject(new Error("Not Authorized"));
                        return;
                    }

                    if (jQueryRequest.responseText) {
                        try {
                            jQueryResponse = JSON.parse(jQueryRequest.responseText);
                        }
                        catch (exception) {
                            jQueryResponse = "[Error in parsing response: " + exception + "]";
                        }
                    } else {
                        jQueryResponse = "[undefined]";
                    }

                    if (textStatus) {
                        requestStatus = textStatus;
                    }
                    else {
                        requestStatus = "[undefined]";
                    }

                    if (exceptionThrown) {
                        exceptionMessage = exceptionThrown;
                    }
                    else {
                        exceptionMessage = "[undefined]";
                    }

                    let result = new ClientResultConstructor();
                    result.success = false;
                    result.data = jQueryResponse;
                    result.errorMessage = "JQueryResponse: {" + jQueryResponse + "}, RequestStatus: {" + requestStatus + "}, ExceptionMessage: {" + exceptionMessage + "}";
                    promise.resolve(result);
                };

                const performRequest = () => {
                    let ajaxSettings = {};
                    ajaxSettings.type = method;
                    ajaxSettings.contentType = actualContentType;
                    ajaxSettings.data = JSON.stringify(requestData);
                    ajaxSettings.cache = false;
                    ajaxSettings.async = true;
                    ajaxSettings.beforeSend = (jQueryRequest) => {
                        if (_sessionAccountInfo.isLoggedIn()) {
                            let apiToken = _sessionAccountInfo.apiToken();
                            if (apiToken) {
                                jQueryRequest.setRequestHeader('X-Authentication', apiToken);
                            }
                        }
                    };
                    ajaxSettings.error = errorHandler;
                    ajaxSettings.success = (jsonResult) => {
                        let dataResult;
                        try {
                            dataResult = JSON.parse(jsonResult);
                        }
                        catch (e) {
                            dataResult = jsonResult;
                        }

                        let result = new ClientResultConstructor();
                        result.success = true;
                        result.data = dataResult;
                        result.errorMessage = "";
                        promise.resolve(result);
                    };

                    $.ajax(fullUrl, ajaxSettings);

                };

                performRequest();
            });
        };
    };
});

