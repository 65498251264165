define('presentation/common/analyticsGraph/summaryTotalRecordView',[],
    function() {
        /**
         * @param {keyof IAnalyticsTotalsProjectionResult} fieldName 
         * @param {IAnalyticsTotalsProjectionResult[fieldName]} defaultValue 
         * @returns {(record: IAnalyticsTotalsProjectionResult) => IAnalyticsTotalsProjectionResult[fieldName]}
         */
        const getFieldFn = (fieldName, defaultValue) =>
            (summaryRecord) => summaryRecord[fieldName] ? summaryRecord[fieldName] : defaultValue;

        const summaryRecord = {
            inboundCount: getFieldFn("a", 0),
            inboundSeconds: getFieldFn("b", 0),
            outboundCount: getFieldFn("c", 0),
            outboundSeconds: getFieldFn("d", 0),
            answeredCount: getFieldFn("e", 0),
            answeredSeconds: getFieldFn("f", 0),
            missedCount: getFieldFn("g", 0),
            missedSeconds: getFieldFn("h", 0),
            systemCount: getFieldFn("i", 0),
            systemSeconds: getFieldFn("j", 0),
        };

        return summaryRecord;
    }
);


