define('presentation/settings/billing/paymentMethod/presentationObjects/paymentMethodPresentationObject',['i18next'],function() {
    return function(){
        const _i18n = require('i18next');

        let self = this;

        self.cardCssType = null;
        self.cardHolderName = ko.observable('');
        self.cardIdentifierFormatted = null;
        self.cardType = null;
        self.country = null;
        self.displayedCardName = null;
        self.expirationDate = null;
        self.isDefault = ko.observable(false);
        self.isExpired = ko.observable(false);
        self.isSelected = ko.observable(false);
        self.isLastValidCard = ko.observable(false);
        self.isLastChargeApproved = ko.observable(false);
        self.isLastChargeDeclined = ko.observable(false);
        self.lastChargeAmount = null;
        self.lastChargeDate = null;
        self.lastChargeOutcome = null;
        self.lastFourDigits = null;
        self.longDescription = null;
        self.maskedPrefix = null;
        self.paymentMethodId = null;
        self.postalCode = null;
        self.deleteIconTooltipText = ko.pureComputed(() => {
            if (self.isDefault()) {
               return _i18n.t('paymentMethod:defaultTooltip');
            } else if (self.isLastValidCard()) {
               return _i18n.t('paymentMethod:lastValidItemTooltip');
            } else {
               return _i18n.t('paymentMethod:lastItemTooltip');
            }
        });
        self.cssStyles = ko.computed(() => {
            let styles = [];
            if (self.isDefault()) {
                styles.push('payment-method__card--default');
            }
            if (self.isExpired()) {
                styles.push('payment-method__card--expired');
            }
            return styles.join(' ');
        });
    };
});

