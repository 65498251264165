define('presentation/settings/userDevices/viewModels/userDeviceViewModel',[
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'presentation/settings/userDevices/validators/userDeviceValidator',
    'presentation/settings/userDevices/validators/userDevicesValidatorUtility'
], function () {
    return function (device, parent, facade) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const UserDevicesValidatorUtilityConstructor = require('presentation/settings/userDevices/validators/userDevicesValidatorUtility');
        const _userDevicesValidatorUtility = new UserDevicesValidatorUtilityConstructor();

        const _device = device;
        const _parent = parent;
        const _facade = facade;

        const _i18n = require('i18next');

        self.modelStateObserver = null;
        self.validator = null;
        
        self.addedItemSort = ko.observable(0);
        self.deviceId = ko.observable(_device.deviceId).extend({observeState: true});
        self.deviceName = ko.observable(_device.deviceName).extend({observeState: true, rateLimit: 500});
        self.devicePhoneNumber = ko.observable(_device.devicePhoneNumber).extend({observeState: true, rateLimit: {timeout: 500, method: 'notifyWhenChangesStop'}});
        self.deviceCountryAbbreviation = ko.observable(_device.deviceCountryAbbreviation).extend({observeState: true});
        self.isEditing = ko.observable(false);
        self.phoneNumberEnabled = ko.observable(true);
        self.availableCallerIds = [
            {id: "caller_ani", key: "caller_ani", name: _i18n.t('userDevices:callersNumber'), text: _i18n.t('userDevices:callersNumber')},
            {id: "hosted_number", key: "hosted_number", name: _i18n.t('userDevices:trestaNumber'), text: _i18n.t('userDevices:trestaNumber')}
        ];
        self.selectedCallerId = ko.observable(self.availableCallerIds[1].id).extend({observeState: true});
        self.saveButtonTitle = ko.pureComputed(() => self.addedItemSort() ? _i18n.t('userDevices:add') : _i18n.t('userDevices:save'));
        self.headerI18nKey = ko.pureComputed(() => self.addedItemSort() ? _i18n.t('userDevices:addDevice') : _i18n.t('userDevices:editDevice'));

        self.formattedDeviceNumber = ko.pureComputed(() => {
            return _phoneNumberFormatter.toInternationalWithParens(self.devicePhoneNumber());
        });
        
        self.isSaveButtonDisabled = ko.pureComputed(() => {
            if (self.deviceName().length > 0 && self.phoneNumberEnabled() === false) {
                return self.deviceName.isInvalid();
            } else if (self.deviceName().length > 0 && self.devicePhoneNumber().length > 0) {
                return self.deviceName.isInvalid() &&
                    self.devicePhoneNumber.isInvalid();
            } else {
                return true;
            }
        });

        self.shouldPreventDeletion = ko.computed(() => {
            if (self.deviceId() === null && _parent.canDeleteDevice()) {
                return false;
            }
            return !_parent.canDeleteDevice();
        });

        self.deleteDevice = () => {
            _parent.deleteDevice(self);
        };

        self.doneDevice = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                self.validate()
                    .done((isValid) => {
                        if (isValid) {
                            self.isEditing(false);
                            self.addedItemSort(0);
                            _parent.refreshDisplayDevices();
                        }
                        promise.resolve();
                    })
                    .fail((error) => {
                        throw error;
                    });
            });
        };

        self.isDeviceNameUnique = (deviceName) => {
            return _promiseFactory.defer((promise) => {
                const deviceNameUpper = deviceName.toUpperCase();
                const matchedDevices = _parent.devices().filter((device) => {
                    return device.deviceName().toUpperCase() === deviceNameUpper;
                });
                if (matchedDevices.length === 1) {
                    _userDevicesValidatorUtility.isDeviceNameUnique(deviceName, matchedDevices[0].deviceId(), _parent.userId)
                        .done(promise.resolve)
                        .fail(promise.reject);
                } else {
                    promise.resolve(false);
                }
            });
        };

        const _getMatchingDevicesByNumber = (phoneNumber) => {
            const devicePhoneNumberCleaned = _phoneNumberFormatter.toNumbers(phoneNumber);
            return _parent.devices().filter((device) => {
                return _phoneNumberFormatter.toNumbers(device.devicePhoneNumber()) === devicePhoneNumberCleaned;
            });
        };

        self.isDevicePhoneNumberUnique = (devicePhoneNumber) => {
            return _promiseFactory.defer((promise) => {
                const matchedDevices = _getMatchingDevicesByNumber(devicePhoneNumber);
                if (matchedDevices.length === 1) {
                    _userDevicesValidatorUtility.isDevicePhoneNumberUnique(devicePhoneNumber, matchedDevices[0].deviceId(), _parent.userId)
                        .done(promise.resolve)
                        .fail(promise.reject);
                } else {
                    promise.resolve(false);
                }
            });
        };

        self.isDevicePhoneNumberUniqueToUser = (devicePhoneNumber) => {
            return _promiseFactory.defer((promise) => {
                const matchedDevices = _getMatchingDevicesByNumber(devicePhoneNumber);
                if (matchedDevices.length > 1) {
                    promise.resolve(false);
                } else {
                    promise.resolve(true);
                }
            });
        };

        self.validate = () => {
            return self.validator.validate();
        };

        const _initialize = () => {
            const ValidatorConstructor = require('presentation/settings/userDevices/validators/userDeviceValidator');
            self.validator = new ValidatorConstructor();
            self.validator.registerViewModel(self, _facade);

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.commitData();
        };

        _initialize();
    };
});

