define('businessServices/userSettings/userSettingStore',['common/promises/promiseFactory',
    'businessServices/userSettings/userSettingsSingleton',
    'persistence/dataProviders/userSettingsDataProvider',
    'persistence/repositories/userSettingsRepository',
    'businessServices/authentication/sessionAccountInfo',
    'entities/userSettingsEntity'], function(
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory
    ){

    return function (){
        const self = this;
        const _promiseFactory = new PromiseFactory();

        const UserSettingsEntityConstructor = require('entities/userSettingsEntity');

        const _settings = require('businessServices/userSettings/userSettingsSingleton');

        let _userSettingsRepository = null;
        let _userSettingsDataProvider = null;
        let _sessionAccountInfo = null;

        const _getByUserId = function(){
            return _promiseFactory.defer(function (deferredObject) {
                const userId = _sessionAccountInfo.userId();

                _userSettingsDataProvider.getAll()
                    .done(function (users){
                        const result = users.find(function(user) {
                            return user.userId === userId;
                        });
                        deferredObject.resolve(result);
                    })
                    .fail(function (error){
                        error.userId = userId;
                        deferredObject.reject(error);
                    });
            });
        };

        const _createDefault = function (){
            return _promiseFactory.defer(function (deferredObject){
                const userSettingsEntity = new UserSettingsEntityConstructor();
                userSettingsEntity.userId = _sessionAccountInfo.userId();
                userSettingsEntity.settings = {};
                _userSettingsRepository.add(userSettingsEntity)
                    .done(function (newUserSettingsDocument){
                        deferredObject.resolve(newUserSettingsDocument);
                    })
                    .fail(function (error){
                        deferredObject.reject(error);
                    });
            });
        };

        const _loadSettings = function(){
            return _promiseFactory.defer(function (deferredObject){
                _getByUserId()
                    .done(function (userSettings){
                        if (userSettings === null || userSettings === undefined || userSettings.settings === undefined){
                            _createDefault()
                                .done(function(defaultUserSettings){
                                    _settings.userSettings = defaultUserSettings;
                                    _settings.isLoaded = true;
                                    deferredObject.resolve();
                                })
                                .fail(function (error){
                                    deferredObject.reject(error);
                                });
                        } else {
                            _settings.userSettings = userSettings;
                            _settings.isLoaded = true;
                            deferredObject.resolve();
                        }
                    })
                    .fail(function (error){
                        deferredObject.reject(error);
                    });
            });
        };

        self.get = function(module){
            return _promiseFactory.defer(function (deferredObject){
                if (_settings.isLoaded === false){
                    _loadSettings()
                        .done(function (){
                            if (_settings.userSettings.settings === undefined || _settings.userSettings.settings === null) {
                                _settings.userSettings.settings = {};
                            }
                            if (_settings.userSettings.settings[module] === undefined) {
                                _settings.userSettings.settings[module] = {};
                            }

                            deferredObject.resolve(_settings.userSettings.settings[module]);
                        })
                        .fail(function (error){
                            deferredObject.reject(error);
                        });
                } else {
                    if (_settings.userSettings.settings[module] === undefined) {
                        _settings.userSettings.settings[module] = {};
                    }
                    deferredObject.resolve(_settings.userSettings.settings[module]);
                }
            });
        };

        self.set = function(module, data){
            return _promiseFactory.defer(function (deferredObject){
                if (_settings.isLoaded === true){
                    if (_settings.userSettings.settings[module] === undefined) {
                        _settings.userSettings.settings[module] = {};
                    }
                    _settings.userSettings.settings[module] = data;
                    _userSettingsRepository.update(_settings.userSettings)
                        .done(function (){
                            deferredObject.resolve();
                        })
                        .fail(function (error){
                            error.userSettingId = _settings.userSettings.userSettingId;
                            deferredObject.reject(error);
                        });
                } else {
                    _loadSettings()
                        .done(function(){
                            self.set(module, data)
                                .done(function(){
                                    deferredObject.resolve();
                                })
                                .fail(function(error){
                                    deferredObject.reject(error);
                                });
                        })
                        .fail(function (error){
                            deferredObject.reject(error);
                        });
                }

            });
        };


        self.init = function(){
            const UserSettingRepositoryConstructor = require('persistence/repositories/userSettingsRepository');
            _userSettingsRepository = new UserSettingRepositoryConstructor();

            const UserSettingsDataProviderConstructor = require('persistence/dataProviders/userSettingsDataProvider');
            _userSettingsDataProvider = new UserSettingsDataProviderConstructor();
            _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

            if(_settings.isLoaded && _settings.userSettings !== null && _sessionAccountInfo.userId() !== _settings.userSettings.userId){
                _settings.isLoaded = false;
            }
        };

        self.init2 = function(userSettingsRepository, userSettingsDataProvider, sessionAccountInfo){
            _userSettingsRepository = userSettingsRepository;
            _userSettingsDataProvider = userSettingsDataProvider;
            _sessionAccountInfo = sessionAccountInfo;

            _settings.userSettings = new UserSettingsEntityConstructor();
            _settings.isLoaded = false;
        };
    };
});

