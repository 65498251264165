define('presentation/common/membershipSelection/viewModels/membershipSelectionViewModel',[
    'common/collections/collectionSorter',
    'common/storage/commonState',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const commonState = require('common/storage/commonState');

        const _i18n = require('i18next');

        let _isItemEnabledProperty = "";
        let _readOnlyProperty = "";
        let _removeItemToolTipProperty = "removeToolTipText";
        let _settings = null;
        let _subscriptions = [];

        const _filterAvailableOptions = ko.pureComputed(() => {
            const filteredOptions = self.allOptions().filter(allOption => {
                const allOptionJson = ko.toJSON(allOption);
                return ! self.selectedValuesJson().some(selectedValueJson => selectedValueJson === allOptionJson);
            });

            return filteredOptions;
        });

        self.allOptions = ko.observableArray();
        self.selectedValues = ko.observableArray();
        self.selectedValuesJson = ko.pureComputed(() => self.selectedValues().map(selectedValue => ko.toJSON(selectedValue)));
        self.displayProperty = 'displayName';
        self.avatarProperty = 'avatar';
        self.avatarTypeProperty = 'avatarType';
        self.groupIdProperty = "id";
        self.isOptionActiveProperty = "";
        self.scrollableContainerSelector = null;
        self.isEnabled = ko.observable(true);
        self.allowZeroItems = ko.observable(false);
        self.addItemTitle = ko.observable("+ Add Member");
        self.removeItemTitle = ko.observable("");
        self.lastItemRemoveTitle = ko.observable(_i18n.t('membershipSelection:lastItemRemoveTitle'));
        self.optionTypeTitle = ko.observable('items');
        self.addAnotherMessage = ko.observable(_i18n.t('membershipSelection:addAnotherMessage', {optionText: self.optionTypeTitle()}));
        self.noResultsText = ko.computed(() => _i18n.t('membershipSelection:addAnotherMessage', {optionText: self.optionTypeTitle()}));
        self.addSubscriberEnabled = ko.observable(true);
        self.addAnotherIsDisabled = ko.pureComputed(() => {
            var allowAddAnother = self.isEnabled() &&
                self.addSubscriberEnabled() &&
                self.addItemTitle().length > 0 &&
                self.availableOptions().length > 0;
            return !allowAddAnother;
        });

        self.itemIsDisabled = (item) => {
            return ko.computed(() => {
                if (self.isEnabled() === false) {
                    return true;
                }

                if (_readOnlyProperty !== "") {
                    return ko.unwrap(item[_readOnlyProperty]);
                }

                return false;
            });
        };
        
        self.showDisabledDeleteIcon = (item) => {
            return ko.computed(() => {
                if (self.selectedValues().length === 1) {
                    return true;
                }

                if (self.isEnabled() === false) {
                    return true;
                }

                if (_readOnlyProperty !== "") {
                    return item[_readOnlyProperty]();
                } else {
                    return false;
                }
            });
        };

        self.removeItemToolTip = (item) => {
            return {
                'container': 'body',
                'position': "top",
                'text': ko.computed(() => {
                    if (item[_removeItemToolTipProperty]) {
                        return item[_removeItemToolTipProperty];
                    } else if (self.selectedValues().length === 1) {
                        return self.lastItemRemoveTitle();
                    } else {
                        return self.removeItemTitle();
                    }
                })
            };
        };

        self.deleteSelectedValue = (itemToDelete) => {
            if (self.isEnabled() && self.selectedValues().length > 1) {
                if (_readOnlyProperty === "" || itemToDelete().isReadOnly() === false) {
                    self.selectedValues.remove(itemToDelete);
                    _deleteParentSelectedItem(itemToDelete);
                }
            }
        };

        const _deleteParentSelectedItem = (itemToDelete) => {
            const actualItemToDelete = ko.unwrap(itemToDelete);
            if (ko.isObservable(_settings.selectedValues)) {
                _settings.selectedValues.remove(actualItemToDelete);
            } else {
                const itemIndex = _settings.selectedValues.indexOf(actualItemToDelete);
                if (itemIndex > -1) {
                    _settings.selectedValues.splice(itemIndex, 1);
                }
            }
        };

        self.addSelectedValue = () => {
            if (self.addAnotherIsDisabled() === true) {
                return;
            }
            const firstAvailableOption = self.availableOptions()[0];
            _addLocalSelectedItem(firstAvailableOption);
            _settings.selectedValues.push(firstAvailableOption);
        };

        const _addLocalSelectedItem = (selectedItem) => {
            if (ko.isObservable(selectedItem)) {
                self.selectedValues.push(selectedItem);
            } else {
                const selectedValueObservable = ko.observable(selectedItem);
                self.selectedValues.push(selectedValueObservable);
                _subscriptions.push(selectedValueObservable.subscribePrevious(_updateParentSelectedItem));
            }
        };

        const _updateParentSelectedItem = (newValue, oldValue) => {
            if (ko.isObservable(_settings.selectedValues)) {
                _settings.selectedValues.replace(oldValue, newValue);
            } else {
                const selectedIndex = _settings.selectedValues.findIndex(selectedValue => ko.unwrap(selectedValue) === oldValue);
                if (selectedIndex === -1) {
                    _settings.selectedValues.push(newValue);
                } else {
                    if (ko.isWriteableObservable(_settings.selectedValues[selectedIndex])) {
                        _settings.selectedValues[selectedIndex](newValue);
                    } else {
                        _settings.selectedValues[selectedIndex] = newValue;
                    }
                }
            }
        };

        const _updateLocalSelectedItems = (arrayChanges) => {
            arrayChanges.forEach(arrayChange => {
                if (!arrayChange.moved) {
                    const localSelectedItemIndex = self.selectedValues().findIndex(localSelectedItem => arrayChange.value === ko.unwrap(localSelectedItem));
                    switch (arrayChange.status) {
                        case "added":
                            if(localSelectedItemIndex === -1) {
                                _addLocalSelectedItem(arrayChange.value);
                            }
                            break;
                        case "deleted":
                            if (localSelectedItemIndex > -1) {
                                self.selectedValues.splice(localSelectedItemIndex,1);
                            }
                            break;
                    }
                }

            });
        };

        self.activate = (settings, isEnabled = true) => {
            _settings = settings;
            return _initialize(isEnabled);
        };

        self.detached = () => {
            _subscriptions.forEach((s) => s.dispose());
            _subscriptions = [];
        };

         const _initialize = (isEnabled) => {
            if (_settings.possibleOptions) {
                if (ko.isObservable(_settings.possibleOptions)) {
                    self.allOptions = _settings.possibleOptions;
                } else {
                    self.allOptions(_settings.possibleOptions);
                }
            } else {
                self.allOptions = ko.pureComputed(() => {
                    const users = commonState.users().reduce((accumulator, user) => {
                        if (user.isActive() || user.isInvited()) {
                            accumulator.push({
                                id: user.id,
                                subscriberType: user.type,
                                displayName: user.name(),
                                avatar: user.avatar()
                            });
                        }
                        return accumulator;
                    }, []);
                    const userGroups = commonState.userGroups().reduce((accumulator, userGroup) => {
                        if (userGroup.isActive()) {
                            accumulator.push({
                                id: commonState.resolveGroupIdToGuid(userGroup.id),
                                subscriberType: userGroup.type,
                                displayName: userGroup.name(),
                                avatar: userGroup.avatars()
                            });
                        }
                        return accumulator;
                    }, []);
                    const sorter = new SorterConstructor();
                    let combinedResults = users.concat(userGroups);
                    sorter.sort(combinedResults, "displayName", true);
                    return combinedResults;
                });
            }

             if (ko.isObservable(isEnabled)) {
                 self.isEnabled = isEnabled;
             } else {
                 self.isEnabled(isEnabled);
             }

            if (_settings.selectedValues) {
                ko.unwrap(_settings.selectedValues).forEach(selectedItem => _addLocalSelectedItem(selectedItem));
                if (ko.isObservable(_settings.selectedValues)) {
                    _subscriptions.push(_settings.selectedValues.subscribe(_updateLocalSelectedItems, this, "arrayChange"));
                }
            } else {
                throw new Error("selectedValues is required");
            }

            if (_settings.filterAvailableFunction) {
                self.availableOptions = ko.pureComputed(() => {
                    return _settings.filterAvailableFunction(self.allOptions(), self.selectedValues());
                });
            } else {
                self.availableOptions = _filterAvailableOptions;
            }

            if (_settings.displayProperty) {
                self.displayProperty = ko.unwrap(_settings.displayProperty);
            }

            if (_settings.avatarProperty) {
                if (ko.isObservable(_settings.avatarProperty)) {
                    self.avatarProperty = _settings.avatarProperty;
                } else {
                    self.avatarProperty = ko.unwrap(_settings.avatarProperty);
                }
            }

            if (_settings.avatarTypeProperty) {
                self.avatarTypeProperty = ko.unwrap(_settings.avatarTypeProperty);
            }

             if ((_settings.groupIdProperty) && (typeof _settings.groupIdProperty === "string")) {
                 self.groupIdProperty = _settings.groupIdProperty;
             }

             if (_settings.isOptionActiveProperty) {
                self.isOptionActiveProperty = ko.unwrap(_settings.isOptionActiveProperty);
            }

            if (_settings.readOnlyProperty) {
                _readOnlyProperty = ko.unwrap(_settings.readOnlyProperty);
            }

            if (_settings.isItemEnabledProperty) {
                _isItemEnabledProperty = ko.unwrap(_settings.isItemEnabledProperty);
            }

            if (_settings.isEnabled) {
                if (ko.isObservable(_settings.isEnabled)) {
                    self.isEnabled = _settings.isEnabled;
                } else {
                    self.isEnabled(_settings.isEnabled);
                }
            }

            if (_settings.allowZeroItems) {
                if (ko.isObservable(_settings.allowZeroItems)) {
                    self.allowZeroItems = _settings.allowZeroItems;
                } else {
                    self.allowZeroItems(_settings.allowZeroItems);
                }
            }

            if (_settings.addItemTitle) {
                if (ko.isObservable(_settings.addItemTitle)) {
                    self.addItemTitle = _settings.addItemTitle;
                } else {
                    self.addItemTitle(_settings.addItemTitle);
                }
            }

            if (_settings.addAnotherMessage !== undefined) {
                if (ko.isObservable(_settings.addAnotherMessage)) {
                    self.addAnotherMessage = _settings.addAnotherMessage;
                } else {
                    self.addAnotherMessage(_settings.addAnotherMessage);
                }
            }

             if (_settings.noResultsText) {
                 if (ko.isObservable(_settings.noResultsText)) {
                     self.noResultsText = _settings.noResultsText;
                 } else {
                     self.noResultsText(_settings.noResultsText);
                 }
             }

            if (_settings.removeItemTitle !== undefined) {
                if (ko.isObservable(_settings.removeItemTitle)) {
                    self.removeItemTitle = _settings.removeItemTitle;
                } else {
                    self.removeItemTitle(_settings.removeItemTitle);
                }
            }

            if (_settings.lastItemRemoveTitle !== undefined) {
                if (ko.isObservable(_settings.lastItemRemoveTitle)) {
                    self.lastItemRemoveTitle = _settings.lastItemRemoveTitle;
                } else {
                    self.lastItemRemoveTitle(_settings.lastItemRemoveTitle);
                }
            }

             if (_settings.optionTypeTitle) {
                 if (ko.isObservable(_settings.optionTypeTitle)) {
                     self.optionTypeTitle = _settings.optionTypeTitle;
                 } else {
                     self.optionTypeTitle(_settings.optionTypeTitle);
                 }
             }

            if (_settings.addSubscriberEnabled) {
                if (ko.isObservable(_settings.addSubscriberEnabled)) {
                    self.addSubscriberEnabled = _settings.addSubscriberEnabled;
                } else {
                    self.addSubscriberEnabled(_settings.addSubscriberEnabled);
                }
            }
             if (_settings.scrollableContainerSelector) {
                 self.scrollableContainerSelector = ko.unwrap(_settings.scrollableContainerSelector);
             }

            return _promiseFactory.wait();
        };
    };
});
