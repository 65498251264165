define('persistence/dataProviders/autoAttendantDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_autoattendant', 'GET')
                    .done(function(result) {
                            deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.getById = function(autoAttendantId) {
            if(autoAttendantId === null) {
                var err = new Error("Missing autoAttendantId");
                err.lobbyAutoAttendant = autoAttendantId;
                throw err;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _kazooApi.callAccount('/lobby_autoattendant/' + autoAttendantId,'GET')
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.autoAttendantId = autoAttendantId;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

