define('presentation/signup/facades/signupProfileFacade',[
    'businessServices/signup/signupSessionStore',
    'externalDependencies/clientApi',
    'presentation/signup/presentationObjects/signupProfilePresentationObject',
],
    function() {
        let _promiseFactory = null;
        let _clientApi = null;
        let _signupSessionStore = null;

        const SignupProfilePresentationObjectConstructor = require('presentation/signup/presentationObjects/signupProfilePresentationObject');

        const _saveFirstName = (firstName) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveFirstName(firstName)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveLastName = (lastName) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveLastName(lastName)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _savePassword = (password) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.savePassword(password)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveBusinessName = (businessName) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveBusinessName(businessName)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveMobileNumber = (mobileNumber) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveMobileNumber(mobileNumber)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveMobileNumberCountryCode = (mobileNumberCountryCode) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveMobileNumberCountryCode(mobileNumberCountryCode)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveVerificationAnswer = (verificationAnswer) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveVerificationAnswer(verificationAnswer)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveVerificationQuestion = (verificationQuestionId) => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.saveVerificationQuestion(verificationQuestionId)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _setProfileCompleted = (isProfileCompleted) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _signupSessionStore.setProfileCompleted(isProfileCompleted)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _isMobilePhoneNumberValid = (phoneNumber) => {
            return _signupSessionStore.isRestrictedPhoneNumber(phoneNumber);
        };

        const _isFraudulentMobileNumber = (phoneNumber, countryCode) => {
            return _signupSessionStore.isFraudulentPhoneNumber(phoneNumber, countryCode);
        };

        const _loadSignupSession = () => {
            return _promiseFactory.defer((promise) => {
                _signupSessionStore.getState()
                    .fail(promise.reject)
                    .done((signupSession) => {
                        let signupProfilePresentationObject = new SignupProfilePresentationObjectConstructor();
                        signupProfilePresentationObject.firstName = signupSession.signupDocument.firstName;
                        signupProfilePresentationObject.lastName = signupSession.signupDocument.lastName;
                        signupProfilePresentationObject.password = signupSession.password;
                        signupProfilePresentationObject.businessName = signupSession.signupDocument.businessName;
                        signupProfilePresentationObject.mobileNumber = signupSession.signupDocument.mobilePhoneNumber;
                        signupProfilePresentationObject.mobileNumberCountryCode = signupSession.signupDocument.mobilePhoneNumberCountry;
                        signupProfilePresentationObject.verificationQuestionId = signupSession.signupDocument.verificationQuestionId;
                        signupProfilePresentationObject.verificationAnswer = signupSession.signupDocument.verificationAnswer;
                        signupProfilePresentationObject.signupPhoneNumbers = signupSession.headerPhoneNumbers();
                        signupProfilePresentationObject.signupNumberType = signupSession.numberTypeSelection();
                        promise.resolve(signupProfilePresentationObject);
                    });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();

            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            _signupSessionStore = new SignupSessionStoreConstructor();
            _signupSessionStore.init();
        };


        return function() {
            let self = this;
            self.init = _init;

            self.isMobilePhoneNumberValid = _isMobilePhoneNumberValid;
            self.saveFirstName = _saveFirstName;
            self.saveLastName = _saveLastName;
            self.savePassword = _savePassword;
            self.saveBusinessName = _saveBusinessName;
            self.saveMobileNumber = _saveMobileNumber;
            self.checkIsFraudulentMobileNumber = _isFraudulentMobileNumber;
            self.saveMobileNumberCountryCode = _saveMobileNumberCountryCode;
            self.saveVerificationAnswer = _saveVerificationAnswer;
            self.saveVerificationQuestion = _saveVerificationQuestion;
            self.setProfileCompleted = _setProfileCompleted;
            self.loadSignupSession = _loadSignupSession;
        };
    });

