define('persistence/repositories/reservationsRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/kazooApi',
    'persistence/webSocket/webSocketApp'
], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.reserveNumber = function(phoneNumberEntity) {
            return _promiseFactory.defer(function(deferredObject) {
                var phoneNumberDocument = {
                    data : phoneNumberEntity
                };
                _kazooApi.callAccount('/lobby_account_phone_number_reservations/reserveNumber', 'POST', phoneNumberDocument)
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.reReserveNumbers = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_account_phone_number_reservations/reReserveNumbers', 'POST')
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.cancelReservation = function(phoneNumber) {
                var phoneNumberDocument = {
                    data : {phoneNumber : phoneNumber}
                };

            return _promiseFactory.defer(function(deferredObject) {
                _kazooApi.callAccount('/lobby_account_phone_number_reservations/cancelReservation/', 'POST', phoneNumberDocument)
                    .done(function(result) {
                        deferredObject.resolve(result.data.data);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
    };
});
