define('presentation/settings/billing/billCycleToDate/presentationObjects/billCycleToDatePresentationObject',[],function() {
    return function() {
        var self = this;
        self.currentChargeDate = "";

        self.cycleEndDate = "";
        self.cycleStartDate = "";

        self.defaultPaymentMethod = {};
        self.defaultPaymentMethod.cardType = "";
        self.defaultPaymentMethod.lastFourDigits = "";
        self.estimatedCharges = "";
        self.estimatedChargesProcessingDateDisplay = "";

        self.hasPastDueBalance = false;
        self.showPastDueBalance = false;
        self.pastDueBalance = "";
        self.pastDueBalanceCycleEndDate = "";
        self.pastDueBalanceCycleStartDate = "";
        self.pastDueBalanceAutoCloseDate = "";

        self.hasCurrentBalance = false;
        self.showCurrentBalance = false;
        self.currentBalance = "";
        self.currentBalanceCycleEndDate = "";
        self.currentBalanceCycleStartDate = "";
        self.currentBalanceChargeDate = "";
        self.currentBalanceProcessingDateDisplay = "";
        self.currentBalanceToolTipText = "";

        self.phoneNumbers = [];
        self.planAmount = 0;
        self.plan = "";
        self.users = [];
        self.refunds = [];

        self.taxesFeesSurcharges = [];
        self.startingBalance = [];

        self.isInFreeTrial = false;
        self.numberOfDaysRemainingInFreeTrial = 0;
        self.displayPlan = false;
    };
});

