define('presentation/common/warningModal/viewModels/autoAttendantIntegrityWarningModalViewModel',[
    'businessServices/converters/durationTimeFormatter',
    'common/collections/collectionSorter',
    'common/converters/phoneNumberFormatter',
    'common/presentation/iconNames',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/systemObjectIconNameConstants',
    'presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel',
    'presentation/common/modal',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'common/collections/collectionSorter',
    'presentation/common/warningModal/presentationObjects/autoAttendantIntegrityWarningPresentationObject'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    return function() {
        const self = this;

        const AutoAttendantCallFlowConstructor = require('presentation/common/callFlow/viewModels/autoAttendantCallFlowViewModel');
        const AutoAttendantPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/autoAttendantIntegrityWarningPresentationObject');

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        let _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();

        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _modalService = null;
        let _promise = null;

        const _buildAutoAttendant = (autoAttendant, availableAutoAttendants, availableSchedules, availableVoicePrompts) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const displayAutoAttendant = new AutoAttendantPresentationObjectConstructor();
                displayAutoAttendant.name(autoAttendant.name());
                displayAutoAttendant.accountAutoAttendantId = autoAttendant.accountAutoAttendantId;
                displayAutoAttendant.accountVoicePromptId = autoAttendant.accountVoicePromptId;
                displayAutoAttendant.systemAudioClipId = autoAttendant.systemAudioClipId;
                displayAutoAttendant.routingRule = autoAttendant.routingRule;
                displayAutoAttendant.createdDateTime = autoAttendant.createdDateTime;
                displayAutoAttendant.modifiedDateTime = autoAttendant.updatedDateTime;
                displayAutoAttendant.editAutoAttendantUrl = autoAttendant.editAutoAttendantUrl;
                displayAutoAttendant.collapsedAutoAttendantMenuLabel(autoAttendant.collapsedAutoAttendantMenuLabel());
                displayAutoAttendant.collapsedMemberIds(autoAttendant.collapsedMemberIds());

                let autoAttendantConstructorPromise = new PromiseFactoryConstructor();
                autoAttendantConstructorPromise.deferIndefinitely((promise) => {
                    let autoAttendantsInCurrentCallFlow = [autoAttendant.accountAutoAttendantId];
                    let autoAttendantCallFlow = new AutoAttendantCallFlowConstructor(autoAttendant.accountAutoAttendantId, autoAttendantsInCurrentCallFlow, availableVoicePrompts, availableSchedules, availableAutoAttendants);
                    autoAttendantCallFlow.isAutoAttendantPromptCollapsible(true);
                    autoAttendantCallFlow.toggleAutoAttendantCallFlow = self.toggleAutoAttendantCallFlow;
                    autoAttendantCallFlow.activate();

                    displayAutoAttendant.autoAttendantCallFlowViewModel(autoAttendantCallFlow);
                    promise.resolve();
                });

                autoAttendantConstructorPromise.wait()
                    .fail(deferredObject.reject)
                    .done(() => {
                        deferredObject.resolve(displayAutoAttendant);
                    });
            });
        };

        const _onIntegrityWarningsChanged = (integrityWarnings) => {
            self.showWarningMessage(integrityWarnings.length > 0);
        };

        const _formatIntegrityWarning = (integrityWarning) => {
            const css = `${self.css()}__integrity-item`;
            switch (integrityWarning.icon){
                case 'phone':
                case 'recording':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.hostedNumber,
                        css: css
                    });
                case 'attendant':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.autoAttendant,
                        css: css
                    });
                case 'forwarding':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.forwardingNumber,
                        css: css
                    });
                case 'schedule':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.schedule,
                        css: css
                    });
                case 'user-device':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.device,
                        css: css
                    });
                case 'user':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.user,
                        css: css
                    });
                case 'userGroup':
                    return Object.assign({}, integrityWarning, {
                        iconName: _systemObjectIconNames.userGroup,
                        css: css
                    });
                default:
                    return null;
            }
        };

        self.css = ko.observable('integrity-warning-modal');
        self.isCompositionComplete = ko.observable(false);
        self.isScrollActive = ko.observable(false);
        self.shouldScrollIntoView = ko.observable(false);
        self.shouldContentOverflow = ko.pureComputed(() => self.isCompositionComplete() && self.isScrollActive());

        self.setCss = (css) => {
            self.css(css);
            return self;
        };

        self.header = ko.observable('');
        self.setHeading = (i18n) => self.header(i18n);

        self.texts = ko.observableArray([]);
        self.addText = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__text--expandable ${addCss}`);
            self.texts.push({i18n, css});
            return self;
        };

        self.autoAttendant = ko.observable();
        self.showAutoAttendant = ko.pureComputed(() => self.autoAttendant() !== '');
        self.setAutoAttendant = (autoAttendantConstructorParams) => {
            const {autoAttendant, availableAutoAttendants, availableSchedules, availableVoicePrompts} = autoAttendantConstructorParams;
            _buildAutoAttendant(autoAttendant, availableAutoAttendants, availableSchedules, availableVoicePrompts)
                .done((displayAutoAttendant) => {
                    self.autoAttendant(displayAutoAttendant);
                });
            return self;
        };

        self.integrityWarningTexts = ko.observableArray([]);
        self.addIntegrityWarningText = ({i18n, addCss = ''}) => {
            const css = ko.pureComputed(() => `${self.css()}__text--expandable ${addCss}`);
            self.integrityWarningTexts.push({i18n, css});
            return self;
        };

        self.integrityWarnings = ko.observableArray([]);
        self.integrityWarnings.subscribe(_onIntegrityWarningsChanged);
        self.showWarningMessage = ko.observable(false);
        self.addIntegrityWarnings = (integrityWarnings) => {
            _sorter.multiSort(integrityWarnings, ["entityText", "entityName"]);
            let formattedIntegrityWarnings = integrityWarnings.map((integrityWarning) => _formatIntegrityWarning(integrityWarning));
            self.integrityWarnings(formattedIntegrityWarnings);
            return self;
        };

        self.onCancel = () => {
            self.isCompositionComplete(false);
            _promise.resolve();
            _modalService.closeModal(self);
        };

        self.clearModal = () => {
            self.css('integrity-warning-modal');
            self.texts.removeAll();
            self.integrityWarnings.removeAll();
            self.integrityWarningTexts.removeAll();
            self.autoAttendant();
            self.setHeading({});
            return self;
        };

        self.showModal = () => {
            const Modal = require('presentation/common/modal');
            _modalService = new Modal();
            _modalService.showModal(self);
            _promise = _promiseFactory.deferIndefinitely();
            return _promise;
        };

        self.toggleAutoAttendantCallFlow = () => {
            self.autoAttendant().showCallFlow(!self.autoAttendant().showCallFlow());
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };
    };
});
