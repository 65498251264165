define('presentation/messages/viewModels/conversationMessagesViewModel',[
    'businessServices/blocking/blockingStateSingleton',
    'i18next',
    'constants/deliveryStatus',
], (
    /** @type import('businessServices/blocking/blockingStateSingleton') */
    _blockingState,
    /** @type import('i18next') */
    _i18n,
    /** @type typeof import('constants/deliveryStatus') */
    DeliveryStatus
) => {

return function (
    /** @type {() => void} */
    requestPreviousMessagesPageCallback,
    /** @type {() => void} */
    requestNextMessagesPageCallback,
    /** @type {() => void} */
    onBottomReachedCallback,
    /** @type {() => JQuery.Deferred<any>} */
    reloadConversationAtBottom
    ) {
    /** @type import('presentation/messages/viewModels/conversationMessagesViewModel') */
    const self = this;

    /** @type {IDisposable[]} */
    let _disposables = [];

    /** @type {number} */
    let _retryPillFadingTimeout;

    /** @type {IReadInfo} */
    let _conversationReadState;

    const _onMessageToBeSentChanged = () => {
        clearTimeout(_retryPillFadingTimeout);
        if (!self.isScrolledToBottom()) {
            self.retryPillFading(false);
        }
    };

    const _onIsScrolledToBottom = (/** @type {boolean} */scrolledToBottom) => {
        const currentMessageToBeSent = self.messageToBeSent();

        if (!currentMessageToBeSent) {
            self.retryPillFading(false);
            return;
        }

        // fade out pill on scroll to bottom
        if (scrolledToBottom) {
            self.retryPillFading(true);
            return;
        }

        // scrolled up before retry finished
        if (!(self.retryPillError() || self.retryPillSuccess())) {
            self.retryPillFading(false);
        }
    };

    const _onMessageToBeSentStatusChanged  = () => {
        if (self.isScrolledToBottom()) {
            self.retryPillFading(true);
        }

        if (self.retryPillError() || self.retryPillSuccess()) {
            // fade retry pill out after 6 seconds once it has reached a terminal state
            _retryPillFadingTimeout = setTimeout(() => {
                self.retryPillFading(true);
                self.overrideNewMessagePill(false);
            }, 1000 * 6);
        }
    };

    const _onMaxAttachmentsAttemptedUpdated = (/** @type {Boolean} */maxAttachmentsAttempted) => {
        const sixSeconds = 6 * 1000;
        const halfSecond = 500;

        const showPill = maxAttachmentsAttempted && !self.renderNewMessagesPill();

        if (showPill) {
            self.canShowMaxAttachmentsPill(true);
            self.maxAttachmentsPillFading(false);

            // Start fading out after six seconds
            setTimeout(() => {
                const stillShowingPill = self.attemptedMaxAttachments() && !self.renderNewMessagesPill();

                if (stillShowingPill) {
                    self.attemptedMaxAttachments(false);
                }
            }, sixSeconds);
        }
        else {
            self.maxAttachmentsPillFading(true);

            // Hide pill after half second of fading out
            setTimeout(() => {
                const stillNotShowingPill = !self.attemptedMaxAttachments() || self.renderNewMessagesPill();

                if (stillNotShowingPill) {
                    self.attemptedMaxAttachments(false);
                    self.canShowMaxAttachmentsPill(false);
                    self.maxAttachmentsPillFading(false);
                }
            }, halfSecond);
        }
    };

    self.scrollToBottom = ko.observable(() => {});
    self.conversationMessages = null;
    self.isLoadingPreviousMessagesPage = ko.observable(false);
    self.isLoadingNextMessagesPage = ko.observable(false);
    self.showMessagesLoadingState = null;
    self.scrollBuffer = 500;
    self.showNewMessagesPill = ko.observable(false);
    self.isNewMessagePillFading = ko.observable(false);
    self.newMessagesDividerLineId = 'newMessagesDividerLineId';
    self.elementToScrollTo = ko.observable(null);
    self.isCompositionComplete = ko.observable(false);
    self.scrollPositionY = ko.observable(null);
    self.isScrolledToBottom = ko.observable(true);
    self.isConversationInitialized = ko.observable(false);
    self.messageToBeSent = ko.observable(null);
    self.retryPillFading = ko.observable(false);
    self.maxAttachmentsPillMessage = ko.observable(null);
    self.attemptedMaxAttachments = ko.observable(false);
    self.canShowMaxAttachmentsPill = ko.observable(false);
    self.maxAttachmentsPillFading = ko.observable(false);
    self.messageIdToJumpTo = ko.observable(null);
    self.isAnyRecipientBlocked = null;

    // if a message is retried while the new message pill is visible, we should override it until another new message comes or the retry pill disappears
    self.overrideNewMessagePill = ko.observable(false);

    // 18 loading state messages ~ = the height of a full page of messages
    self.loadingStateMessages = Array(18);

    self.renderNewMessagesPill = ko.pureComputed(() => {
        return self.showNewMessagesPill() && self.newMessagesLabel() && !self.overrideNewMessagePill() && !self.isAnyRecipientBlocked();
    });

    self.canShowRetryPill = ko.pureComputed(() => {
        return self.messageToBeSentStatus() && !self.renderNewMessagesPill() && !self.isAnyRecipientBlocked();
    });

    self.messageToBeSentStatus = ko.pureComputed(() => {
        const messageToBeSent = self.messageToBeSent();

        if (messageToBeSent === null) {
            return null;
        }

        return messageToBeSent.deliveryStatus();
    });

    self.retryPillLoading = ko.pureComputed(() => {
        switch (self.messageToBeSentStatus()) {
            case DeliveryStatus.Sending:
            case DeliveryStatus.QueuedForSend:
                return true;
            default:
                return false;
        }
    });

    self.retryPillSending = ko.pureComputed(
        () => self.messageToBeSentStatus() === DeliveryStatus.Sending);

    self.retryPillError = ko.pureComputed(
        () => self.messageToBeSentStatus() === DeliveryStatus.Failed);

    self.retryPillSuccess = ko.pureComputed(() => {
        switch (self.messageToBeSentStatus()) {
            case DeliveryStatus.Sent:
            case DeliveryStatus.Delivered:
                return true;
            default:
                return false;
        }
    });

    self.newMessagesLabel = ko.pureComputed(() => {
        const newMessageCount = _conversationReadState.newMessageCount();

        switch(newMessageCount) {
            case 0:
                return '';
            case 1:
                return _i18n.t('messages:oneNewMessage');
            default:
                return _i18n.t('messages:nNewMessages', { newMessagesCount: newMessageCount });
        }
    });

    self.retryPillLabel = ko.pureComputed(() => {
        switch (self.messageToBeSentStatus()) {
            case DeliveryStatus.Sent:
            case DeliveryStatus.Delivered:
                return `Success!`;
            case DeliveryStatus.Failed:
                return `Failed to Send`;
            default:
                return '';
        }
    });

    self.retryPillIcon = ko.pureComputed(() => {
        switch (self.messageToBeSentStatus()) {
            case DeliveryStatus.Sending:
                return `messageSending`;
            case DeliveryStatus.QueuedForSend:
                return `messagesHourglass`;
            case DeliveryStatus.Sent:
            case DeliveryStatus.Delivered:
            case DeliveryStatus.Failed:
                return `arrowDown`;
            default:
                return null;
        }
    });

    self.requestPreviousMessagesPageCallback = requestPreviousMessagesPageCallback;
    self.requestNextMessagesPageCallback = requestNextMessagesPageCallback;
    self.onBottomReachedCallback = onBottomReachedCallback;

    self.onNewMessageReceived = () => {
        self.overrideNewMessagePill(false);
    };

    self.onMessageRetry = () => {
        self.overrideNewMessagePill(true);
    };

    self.onBottomReached = () => {
        self.retryPillFading(true);
        self.overrideNewMessagePill(false);
    };

    self.onNewMessagesPillClick = () => {
        const element = document.getElementById(self.newMessagesDividerLineId);
        self.elementToScrollTo(element);
        self.onBottomReachedCallback();
    };

    self.onRetryStatusPillClick = () => {
        if (self.retryPillFading()) {
            return;
        }

        const scrollToBottomFunc = self.scrollToBottom();
        reloadConversationAtBottom()
            .then(scrollToBottomFunc);

        scrollToBottomFunc();
        self.retryPillFading(true);
    };

    self.scrollToBottomOfConversation = () => {
        self.scrollToBottom()();
    };

    self.compositionComplete = () => {
        self.isCompositionComplete(true);
    };

    self.detached = () => {
        _disposables.forEach(disposable => disposable.dispose());
        _disposables = [];
        self.showNewMessagesPill(false);
        self.isNewMessagePillFading(false);
        self.isCompositionComplete(false);
    };

    /** @type {self["activate"]} */
    self.activate = (activationData) => {
        self.conversationMessages = activationData.conversationMessages;
        self.scrollPositionY = activationData.scrollPositionY;
        self.isScrolledToBottom = activationData.isScrolledToBottom;
        self.isLoadingPreviousMessagesPage = activationData.isLoadingPreviousMessagesPage;
        self.isLoadingNextMessagesPage = activationData.isLoadingNextMessagesPage;
        self.isConversationInitialized = activationData.isConversationInitialized;
        self.messageToBeSent = activationData.messageToBeSent;
        self.showMessagesLoadingState = activationData.showMessagesLoadingState;
        self.attemptedMaxAttachments = activationData.attemptedMaxAttachments;
        self.maxAttachmentsPillMessage = activationData.maxAttachmentsPillMessage;
        self.messageIdToJumpTo = activationData.messageIdToJumpTo;
        self.isAnyRecipientBlocked = activationData.isAnyRecipientBlocked;

        _conversationReadState = activationData.conversationReadState;

        _disposables.push(
            self.messageToBeSent.subscribe(_onMessageToBeSentChanged),
            self.messageToBeSentStatus.subscribe(_onMessageToBeSentStatusChanged),
            self.isScrolledToBottom.subscribe(_onIsScrolledToBottom),
            self.attemptedMaxAttachments.subscribe(_onMaxAttachmentsAttemptedUpdated)
        );

        return _initialize();
    };

    const _initialize = () => {

    };
};
});
