// @ts-check

define('persistence/repositories/webMessagingDataRepository',[
    'common/promises/promiseFactory',
    'externalDependencies/clientWebSocket'
], (PromiseFactoryConstructor, clientWebSocket) => {
    const APPLICATION_NAME = `WebMessaging`;

    return function() {
        const self = this;

        const _webMessagingSocket = clientWebSocket.forApplication(APPLICATION_NAME);
        
        /**
         * @template TOut
         * @param {string} command 
         * @param {{ [key: string]: any }} parameters  
         * @returns {JQuery.Deferred<TOut?>}
         */
        const _send = (command, parameters) => {
            const promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.defer((/** @type {JQuery.Deferred} */defferred) => {
                _webMessagingSocket.send(command, parameters || {}, (/** @type {TOut?} */ response, /** @type {any} */ errMsg) => {
                    if (errMsg) {
                        throw new Error(errMsg);
                    }
                    
                    defferred.resolve(response);
                });
            });
        };

        // TODO: Write APIs


        self.disposeOfEvents = () => {
            _webMessagingSocket.disposeOfEvents();
        };
    };
});
