/**
 * @module common/generators/commandIdGenerator
 */
define('common/uniqueId/uniqueIdGenerator',[], function() {
    return function() {
        var self = this;

        var _generateUniqueIdWithHyphens = function() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        };

        var _generateUniqueIdWithoutHyphens = function() {
            var withHyphens = _generateUniqueIdWithHyphens();
            var hyphensRemoved  = withHyphens.replace(/-/g, "");
            return hyphensRemoved;
        };


        self.generateUniqueId = _generateUniqueIdWithHyphens;
        self.generateUniqueIdWithoutHyphens = _generateUniqueIdWithoutHyphens;
    };
});

