define('presentation/common/knockoutConfiguration',[
        'common/logging/logger',
        'businessServices/browserSupport/browserType',
        'businessServices/converters/currencyFormatter',
        'businessServices/events/eventManager',
        'businessServices/converters/numberFormatter',
        'businessServices/converters/durationTimeFormatter',
        'settings/uiAnimationsConfiguration',
        'jquery',
        'tooltip',
        'spin',
        'spin-jquery',
        'jquery-resize',
        'overlay-scrollbars',
        'jquery-ui',
        'jquery-mousewheel',
        'timepicker',
        'moment',
        'uniform',
        'headroom',
        'jquery-headroom',
        'jplayer',
        'jquery-inputmask',
        'velocity',
        'presentation/common/enableLinksInText',
        'presentation/common/svgIcon/svgIcon',
        'presentation/common/audioPlayer/audioPlayerKnockoutBinding',
        'presentation/common/analyticsGraph/analyticsGraphBinding',
        'presentation/common/highlightSearchedText',
        'presentation/common/setMaxHeight',
        'presentation/common/scrollAtMaxHeightWidthBinding',
        'presentation/common/unicodeText',
        'presentation/common/topMost',
        'presentation/common/overlayScrollbars',
        'presentation/common/listBox/bindings/listBoxBinding',
        'presentation/contacts/bindings/contactCardScrollBinding',
        'presentation/messages/bindings/conversationMessagesScrollBinding',
        'presentation/messages/bindings/messagesComposeAreaBinding',
        'presentation/messages/bindings/messagesComposeAreaAttachmentsBinding',
        'presentation/messages/bindings/textAreaHandleEnter',
        'presentation/common/progressSpinner/progressSpinner',
        'presentation/common/videoPlayer/bindings/videoPlayerBinding',
        'presentation/common/filterMenu/bindings/filterMenuBinding',
        'presentation/common/filterSortMenu/bindings/filterSortMenuBinding',
        'semantic-ui-transition',
        'semantic-ui-dropdown',  // are these still used???
        'select2',
        'constants/systemObjectIconNameConstants',
        'durandal/composition'
    ], function() {
    const configure = function() {
        var _eventManager = require('businessServices/events/eventManager');
        var _uiAnimationsConfiguration = require('settings/uiAnimationsConfiguration');
        var _moment = require('moment');
        const _i18n = require('i18next');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');
        const composition = require('durandal/composition');

        const SELECT_ICON_SIZE = 26;
        const SCROLL_CONTAINER_SELECTOR = '.interior-shell__scrollable-content';
        const SCROLL_CONTENT_WRAPPER_SELECTOR = '.overlay-scrollbars-content-wrapper';
        const SCHEDULE_RANGE_SELECTOR = '.schedule-range';

        ko.bindingHandlers.topMost = require('presentation/common/topMost');
        ko.bindingHandlers.svgIcon = require('presentation/common/svgIcon/svgIcon');
        ko.bindingHandlers.enableLinksInText = require('presentation/common/enableLinksInText');
        ko.bindingHandlers.highlightSearchedText = require('presentation/common/highlightSearchedText');
        ko.bindingHandlers.listBoxBinding = require('presentation/common/listBox/bindings/listBoxBinding');
        ko.bindingHandlers.messagesComposeAreaBinding = require('presentation/messages/bindings/messagesComposeAreaBinding');
        ko.bindingHandlers.messagesComposeAreaAttachmentsBinding = require('presentation/messages/bindings/messagesComposeAreaAttachmentsBinding');
        ko.bindingHandlers.unicode = require('presentation/common/unicodeText');
        ko.bindingHandlers.videoPlayerBinding  = require('presentation/common/videoPlayer/bindings/videoPlayerBinding');
        ko.bindingHandlers.filterMenuBinding  = require('presentation/common/filterMenu/bindings/filterMenuBinding');
        ko.bindingHandlers.filterSortMenuBinding = require('presentation/common/filterSortMenu/bindings/filterSortMenuBinding');

        composition.addBindingHandler('contactCardScroll', require('presentation/contacts/bindings/contactCardScrollBinding'));
        composition.addBindingHandler('conversationMessagesScroll', require('presentation/messages/bindings/conversationMessagesScrollBinding'));
        composition.addBindingHandler('scrollAtMaxHeightWidth', require('presentation/common/scrollAtMaxHeightWidthBinding'));
        composition.addBindingHandler(`setMaxHeight`, require('presentation/common/setMaxHeight'));
        composition.addBindingHandler(`overlayScrollbars`, require('presentation/common/overlayScrollbars'));
        composition.addBindingHandler(`textAreaHandleEnter`, require('presentation/messages/bindings/textAreaHandleEnter'));

        var _handleFileSelect = function (element, allBindingsAccessor) {
            var files = element.files; // FileList object
            var accessor = allBindingsAccessor().lobbyFileHandler;
            if (files.length === 0) {
                accessor(undefined);
            } else {
                for (var index = 0; index < files.length; index++) {
                    var file = files[index];
                    accessor(file);
                }
            }
        };

        ko.bindingHandlers.lobbyFileHandler = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                element.addEventListener('change', function () {
                    _handleFileSelect(element, allBindingsAccessor);
                }, false);
            }
        };

        var _buildPhoneNumberSpannedText = function (valueAccessor) {
            var value = ko.unwrap(valueAccessor());
            if (typeof value === "object") {
                value = value.formattedNumber;
            }
            var spannedText = "";
            if (value.indexOf("-") > -1) {
                var dashSplit = value.split("-");
                for (var i = 0; i < dashSplit.length; i++) {
                    spannedText += "<span class='inline'>" + dashSplit[i] + "</span>" + "-";
                }
            } else if (value.indexOf(" ") > -1) {
                var spaceSplit = value.split(" ");
                for (var j = 0; j < spaceSplit.length; j++) {
                    spannedText += "<span class='inline'>" + spaceSplit[j] + "</span>" + " ";
                }
            } else if (value) {
                spannedText = "<span class='inline'>" + value + "</span> ";
            }
            spannedText = spannedText.substr(0, spannedText.length - 1);

            return spannedText;
        };

        var _applyPhoneNumberFormatToSpan = function (element, allBindingsAccessor) {
            // check if has phone numbers to apply linkjacker fix
            var hasPhoneNumbers = allBindingsAccessor().dropdownThemed.hasPhoneNumbers;
            if (hasPhoneNumbers !== undefined) {
                if (hasPhoneNumbers === true) {
                    var spannedText = _buildPhoneNumberSpannedText(allBindingsAccessor().value);
                    $(element).siblings("span").addClass('phone-wrapper');
                    $(element).siblings("span").html(spannedText);
                }
            }
        };

        var _updateDropdownThemedSettings = function (element, settings) {
            var defaults = {
                "wrapperClass": "dropdown",
                "selectAutoWidth": false,
                "selectClass": "dropdown"
            };

            for (var property in settings) {
                if (property === "wrapperClass" || property === "selectClass") {
                    defaults[property] += (" " + ko.unwrap(settings[property]));
                } else {
                    defaults[property] = ko.unwrap(settings[property]);
                }
            }

            //restore is required to reset the element back to the default, otherwise, changes won't apply
            $.uniform.restore(element);
            $(element).uniform(defaults);
        };

        // todo - reskin - this should be able to be removed after DEV-127
        // Create a binding handler for custom themed drop downs
        ko.bindingHandlers.dropdownThemed = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                // check if control has an enabled state
                var isControlEnabled = allBindingsAccessor().enable;
                if (isControlEnabled !== undefined) {
                    if (ko.isWriteableObservable(isControlEnabled)) {
                        isControlEnabled.subscribe(function () {
                            $.uniform.update($(element));
                        });
                    }
                }

                var controlValue = allBindingsAccessor().value;
                if (ko.isObservable(controlValue)) {
                    controlValue.subscribe(function() {
                        setTimeout(function() {
                            $.uniform.update($(element));
                        }, 1);
                    });
                }

                // Adding a slight delay helps drop down display properly when other items on page are loading.
                setTimeout(function () {
                    _updateDropdownThemedSettings(element, valueAccessor());
                    _applyPhoneNumberFormatToSpan(element, allBindingsAccessor);
                }, 1);
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                // In order to get css changes to take affect we must restore and reapply
                _updateDropdownThemedSettings(element, valueAccessor());
                _applyPhoneNumberFormatToSpan(element, allBindingsAccessor);
            }
        };

        ko.bindingHandlers.processingSpinner = {
            init: function (element, valueAccessor) {
                const $container = $(element);
                const options = ko.unwrap(valueAccessor);

                let message = _i18n.t('processing');
                if (options.message) {
                    message = options.message;
                } else if (options.messageI18n) {
                    message = _i18n.t(options.messageI18n);
                }

                $container
                    .append(`<span class="processing-spinner"/><span class="processing-message">${message}</span>`)
                    .addClass("processing-spinner__container");
            }
        };

        ko.bindingHandlers.optionsTitle = {
            'update': function (element, valueAccessor, allBindingsAccessor) {
                var allBindings = allBindingsAccessor();
                //get our array of options
                var options = ko.utils.unwrapObservable(allBindings['options']);
                //get the property that contains our title
                if(allBindings.optionsTitle.property !== null) {
                    var property = allBindings.optionsTitle.property;
                    var maxLength = allBindings.optionsTitle.maxLength;

                    //get the option elements for this select element
                    var optionElements = $("option", element);
                    //if a caption was specified, then skip it when assigning title
                    var skipCaption = allBindings["optionsCaption"] ? 1 : 0;

                    //loop through options and assign title to appropriate optionElement
                    for (var i = 0, j = options.length; i < j; i++) {
                        var option = optionElements[i + skipCaption];
                        var optionText = options[i][property];
                        if (optionText.length > maxLength) {
                            option.title = options[i][property];
                            option.text = optionText.substring(0, maxLength) + "...";
                        }
                    }
                }
            }
        };

        ko.bindingHandlers.addConnectorsLoading = {
            init: function (element) {
                var loadingContainer = $(element);
                loadingContainer.addClass("spinner-button");
                loadingContainer.wrapInner("<span class='label' />");
                loadingContainer.append($("<span class='spin' />"));
            },
            update: function (element, valueAccessor) {
                var loadingContainer = $(element);
                var spinnerOptions = {
                    length: 6,
                    radius: 12,
                    width: 6,
                    lines: 8,
                    color: '#868686'  // $color-secondary-warm-400
                };
                var value;
                if (ko.isWriteableObservable(valueAccessor())) {
                    value = valueAccessor()();
                } else {
                    value = valueAccessor();
                }

                var $loadingBlockout;
                if ($(element).prev().hasClass("available-numbers-loading-blockout")) {
                    $loadingBlockout = $(element).prev();
                } else {
                    $loadingBlockout = $(".available-numbers-loading-blockout");
                }

                if (value === true) {
                    if (!$loadingBlockout.is(":visible")) {
                        $loadingBlockout.show();

                        if (spinnerOptions) {
                            loadingContainer.addClass("loading");
                            $(element).addClass("loading");
                            $(".spin", loadingContainer).spin(spinnerOptions);
                        }
                    }
                } else {
                    loadingContainer.removeClass("loading");
                    $(element).removeClass("loading");
                    setTimeout(function () {
                        $loadingBlockout.hide();
                    }, 1);
                }
            }
        };

        ko.bindingHandlers.autoComplete = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                var options = valueAccessor() || {};
                var allBindings = allBindingsAccessor();
                var unwrap = ko.utils.unwrapObservable;
                var modelValue = allBindings.boundValue;
                var source = allBindings.source;
                var valueProp = allBindings.sourceValue;
                var inputValueProp = allBindings.sourceInputValue || valueProp;
                var labelProp = allBindings.sourceLabel || valueProp;
                var allowCustomEntry = allBindings.allowCustomEntry || false;

                //function that is shared by both select and change event handlers
                function writeValueToModel(valueToWrite) {
                    if (ko.isWriteableObservable(modelValue)) {
                        modelValue(valueToWrite);
                    } else {  //write to non-observable
                        if (allBindings['_ko_property_writers'] && allBindings['_ko_property_writers']['value']) {
                            allBindings['_ko_property_writers']['value'](valueToWrite);
                        }
                    }
                }

                //on a selection write the proper value to the model
                options.select = function (event, ui) {
                    writeValueToModel(ui.item ? ui.item.actualValue : null);
                    if (event) {
                        event.stopPropagation();
                    }
                };

                //on a change, make sure that it is a valid value or clear out the model value
                options.change = function () {
                    var currentValue = $(element).val();
                    var matchingItem = ko.utils.arrayFirst(unwrap(source), function (item) {
                        return unwrap(inputValueProp ? item[inputValueProp] : item) === currentValue;
                    });

                    if (!matchingItem && currentValue === '') {
                        writeValueToModel(null);
                    } else if (!matchingItem && currentValue !== '') {
                        if (allowCustomEntry === true) {
                            writeValueToModel(currentValue);
                        } else {
                            var result = {};
                            result.isError = true;
                            result.invalidData = currentValue;
                            writeValueToModel(result);
                        }
                    }
                };


                //handle the choices being updated in a DO, to decouple value updates from source (options) updates
                var mappedSource = ko.dependentObservable(function () {
                    var mapped = ko.utils.arrayMap(unwrap(source), function (item) {
                        var result = {};
                        result.label = labelProp ? unwrap(item[labelProp]) : unwrap(item).toString();  //show in pop-up choices
                        result.value = inputValueProp ? unwrap(item[inputValueProp]) : unwrap(item).toString();  //show in input box
                        result.actualValue = valueProp ? unwrap(item[valueProp]) : item;  //store in model
                        return result;
                    });
                    return mapped;
                }, null, {disposeWhenNodeIsRemoved: element});

                //whenever the items that make up the source are updated, make sure that autocomplete knows it
                mappedSource.subscribe(function (newValue) {
                    $(element).autocomplete("option", "source", newValue);
                });

                options.source = mappedSource();

                $(element).change(function () {
                    writeValueToModel(null);
                });

                //initialize autocomplete
                $(element).autocomplete(options);
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                //update value based on a model change
                var allBindings = allBindingsAccessor();
                var unwrap = ko.utils.unwrapObservable;
                var modelValue = unwrap(allBindings.boundValue) || '';
                var valueProp = allBindings.sourceValue;
                var inputValueProp = allBindings.sourceInputValue || valueProp;
                var allowCustomEntry = allBindings.allowCustomEntry || false;

                //if we are writing a different property to the input than we are writing to the model, then locate the object
                if (valueProp && inputValueProp !== valueProp) {
                    var source = unwrap(allBindings.source) || [];
                    modelValue = ko.utils.arrayFirst(source, function (item) {
                            return unwrap(item[valueProp]) === modelValue;
                        }) || {};
                }

                //update the element with the value that should be shown in the input
                setTimeout(function () {
                    if (allowCustomEntry === true) {
                        $(element).val(modelValue && modelValue[inputValueProp] ? unwrap(modelValue[inputValueProp]) : modelValue.toString());
                    } else {
                        $(element).val(modelValue && inputValueProp !== valueProp ? unwrap(modelValue[inputValueProp]) : modelValue.toString());
                    }
                }, 1);
            }
        };

        const _buildIconDataBind = (icon) => {
            return  {
                borderedIcon: {
                    containerWidth: SELECT_ICON_SIZE,
                    containerHeight: SELECT_ICON_SIZE,
                    iconName: _mapIconToAsset(icon),
                    iconHeight: 20,
                    iconWidth: 20
                }
            };
        };

        const _buildUserAvatarDataBind = (userAvatarObject) => {
            return {
                userAvatar: {
                    avatar: userAvatarObject,
                    avatarHeight: 20,
                    avatarWidth: 20,
                    containerHeight: SELECT_ICON_SIZE,
                    containerWidth: SELECT_ICON_SIZE
                }
            };
        };

        const _buildUserGroupAvatarDataBind = (userGroupId) => {
            return {
                userGroupAvatar: {
                    userGroupId: userGroupId,
                    groupAvatarContainerWidth: SELECT_ICON_SIZE,
                    groupAvatarContainerHeight: SELECT_ICON_SIZE
                }
            };
        };

        const _bindOnCreate = ($element, binding) => {
            let observer = new MutationObserver(() => {
                if (document.contains($element[0])) {
                    ko.applyBindingsToNode($element[0].firstChild, binding);
                    observer.disconnect();
                }
            });

            observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});
        };

        const _hideOnCreate = ($element) => {
            let observer = new MutationObserver(() => {
                if (document.contains($element[0])) {
                    $element[0].parent().addClass('select2-result__option--hidden');
                    observer.disconnect();
                }
            });

            observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});
        };

        const _buildElementWithIcon = (option) => {
            let $optionElement = $(`<span class="select-option"><div class="select-option__icon"/><div class="select-option__text">${option.displayText}</div></span>`);
            _bindOnCreate($optionElement, _buildIconDataBind(option.iconProperty));
            return $optionElement;
        };

        const _buildElementWithAvatar = (option) => {
            let elementClass;
            let binding;

            switch (option.avatarType) {
                case "user":
                    binding = _buildUserAvatarDataBind(option.avatar);
                    elementClass = 'select-option__avatar';
                    break;
                case "userGroup":
                    binding = _buildUserGroupAvatarDataBind(option.groupId);
                    elementClass = 'select-option__avatar';
                    break;
            }

            let $optionElement = $(`<span class="select-option"><span class="${elementClass}"/><div class="select-option__text">${option.displayText}</div></span>`);

            _bindOnCreate($optionElement, binding);
            return $optionElement;
        };

        const _buildHiddenElement = () => {
            let $hiddenElement = $('<span class="select-option--hidden"/>');

            _hideOnCreate($hiddenElement);
        };

        const _mapIconToAsset = (iconName) => {
            switch (iconName) {
                case "autoAttendant":
                    return _systemObjectIconNames.autoAttendant;
                case 'user':
                    return _systemObjectIconNames.user;
                case 'userGroup':
                    return _systemObjectIconNames.userGroup;
                case 'forwardingNumber':
                    return _systemObjectIconNames.forwardingNumber;
                case 'hostedNumber':
                    return _systemObjectIconNames.hostedNumber;
                case 'voicePrompt':
                    return _systemObjectIconNames.prompt;
                case 'prompt':
                    return _systemObjectIconNames.prompt;
                case 'schedule':
                    return _systemObjectIconNames.schedule;
                case 'subMenu':
                    return _systemObjectIconNames.subMenu;
                case 'voicemailBox':
                    return _systemObjectIconNames.voicemailBox;
                case 'voicemail':
                    return _systemObjectIconNames.voicemail;
                case 'hangup':
                    return _systemObjectIconNames.hangup;
                case 'ringing':
                    return _systemObjectIconNames.ringing;
                default:
                    return null;
            }
        };

        const _buildElementWithMeta = (option) => {
            let $optionElement;
            if (option.disabled) {
                $optionElement = $(`
                    <span class="select-option-with-meta__disabled">
                        <span class="select-option__text">${option.text}</span>
                        <span class="select-option__meta-text">${option.meta}</span>
                    </span>`);
            } else {
                $optionElement = $(`
                    <span class="select-option-with-meta">
                        <span class="select-option__text">${option.text}</span>
                        <span class="select-option__meta-text">${option.meta}</span>
                    </span>`);
            }
            return $optionElement;
        };

        const _formatOption = (option) => {
            if (option.customElement) {
                return option.customElement.clone();
            } else if (option.iconProperty) {
                return _buildElementWithIcon(option);
            } else if (option.avatar) {
                return _buildElementWithAvatar(option);
            } else if (option.id === "-1") {
                return _buildHiddenElement();
            } else if (option.meta) {
                return _buildElementWithMeta(option);
            }

            return option.text;
        };

        const _formatSelection = (option) => {
            if (option.id === -1) {
                if (option.iconProperty) {
                    return _buildElementWithIcon({iconProperty: option.iconProperty, displayText: option.text});
                }

                return option.text;
            }
            if (option.placeholder && option.selected === false) {
                return option.placeholder;
            }
            option.title = '';

            return _formatOption(option);
        };

        const _select2PrepareData = (options) => {
            const _data = ko.unwrap(options.data);
            const setOptionValue = (options.optionValue !== undefined && options.optionValue !== 'id') ?
                (d) => {
                    d.id = ko.unwrap(d[options.optionValue]);
                } :
                () => {};
            const setOptionText = (options.optionText !== undefined && options.optionText !== 'text') ?
                (d) => d.text = ko.unwrap(d[options.optionText]) :
                (d) => {
                    if (d.textI18n) {
                        d.text = _i18n.t(d.textI18n);
                    }
                };
            const setOptionTitle = (d) => {
                d.title = '';
            };
            const mapData = options.selectedData ?
                (d) => {
                    const _originalData = Object.assign({}, d);
                    setOptionValue(d);
                    setOptionText(d);
                    setOptionTitle(d);
                    return Object.assign({}, d, {_originalData});
                } :
                (d) => {
                    setOptionValue(d);
                    setOptionText(d);
                    setOptionTitle(d);
                    return d;
                };
            return Object.values(_data).map(mapData);
        };

        const _setupEmptySelection = (options) => {
            if (options.emptyMessage && (options.data.length === 0)) {
                options.placeholder = {
                    id: -1,
                    text: options.emptyMessage,
                    iconProperty: options.emptyIcon
                };
            }
        };

        const _setupSelect2Observer = (element) => {
            const $element = $(element);
            let observer = null;
            $element.on('select2:open', (e) => {
                observer = new MutationObserver(() => {
                    let $aboveDropdown = $('.select2-dropdown--above');
                    if ($aboveDropdown.length > 0) {
                        let $dropdownContainer = $aboveDropdown.parent();
                        let targetPosition = e.target.getBoundingClientRect();
                        if (document.contains($dropdownContainer[0])) {
                            $dropdownContainer.css('left', targetPosition.left + 'px');
                        }
                    }
                });
                observer.observe(document, {attributes: false, childList: true, characterData: false, subtree: true});
            }).on('select2:close', () => {
                if (observer !== null) {
                    observer.disconnect();
                }
            });
        };

        const _setupSelect2 = (element, valueAccessor, allBindings, shouldSetSelectedValue) => {
            const $element = $(element);

            let options = ko.utils.unwrapObservable(valueAccessor());
            options.width = options.width !== undefined ? options.width : "100%";

            ko.utils.unwrapObservable(allBindings.get('selectedOptions'));
            let noResults = options.noResults && options.data.length === 1 ? options.noResults : _i18n.t('common:noResults');
            $.fn.select2.defaults.set('language', {
                noResults: () => {
                    return noResults;
                },
            });

            options.data = _select2PrepareData(options);

            let selected = null;
            if (shouldSetSelectedValue) {
                selected = allBindings.has("selectedOptions") ?
                    ko.unwrap(allBindings.get('selectedOptions')) :
                    options.selectedValue ?
                        options.selectedValue() :
                        options.selectedData()[options.optionValue || 'id'];
            }

            const isDisabled = ko.utils.unwrapObservable(options.disabled);
            options.disabled = isDisabled;
            options.templateResult = _formatOption;
            options.templateSelection = _formatSelection;

            _setupEmptySelection(options);

            if (selected !== null) {
                $element.select2(options).val(selected);
            }
            $element.select2(options);

            // remove selected item tooltip
            $('.select2-selection__rendered').hover(function () {
                $(this).removeAttr('title');
            });

            // open dropdown on focus
            $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
                $(this).closest(".select2-container").siblings('select:enabled').select2('open');
            });

            // steal focus during close to prevent infinite loop
            $('select.select2').on('select2:closing', function (e) {
                $(e.target).data("select2").$selection.one('focus focusin', function (e) {
                    e.stopPropagation();
                });
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if ($element.select2()) {
                    $element.select2('destroy');
                    $element.off('select2:select');
                    $element.off('select2:close');
                    $element.off('select2:open');
                }
            });
        };

        ko.bindingHandlers.select2 = {
            init: function (element, valueAccessor, allBindings) {
                _setupSelect2(element, valueAccessor, allBindings, false);
                _setupSelect2Observer(element);
            },
            update: function (element, valueAccessor, allBindings) {
                let options = ko.utils.unwrapObservable(valueAccessor());

                // close and rebuild the control with new data
                const $element = $(element);
                $element.off('select2:select');
                $element.select2('close');
                $element.select2('destroy');
                $element.html('');

                _setupSelect2(element, valueAccessor, allBindings, true);

                const onSelect = (e) => {
                    if (options.selectedData) {
                        options.selectedData(e.params.data._originalData);
                    } else {
                        options.selectedValue(e.params.data.id);
                    }
                };

                if (options.selectedData || options.selectedValue) {
                    $element.on('select2:select', onSelect);
                }

                if (ko.unwrap(options.scrollOnSelect)) {
                    setTimeout(() => {
                        const $scrollableContainer = $(SCROLL_CONTAINER_SELECTOR);
                        const $scheduleRange = element.closest(SCHEDULE_RANGE_SELECTOR);

                        let scrollbarInstance = $scrollableContainer.overlayScrollbars({ }).overlayScrollbars();
                        scrollbarInstance.scroll({ el : $scheduleRange, block : "end" , margin : [0, 0, 20, 0 ]}, 200);
                    }, 500);
                }
            }
        };

        ko.bindingHandlers.select2Adjacent = {
            init: function (element, valueAccessor, allBindings) {
                const $element = $(element);
                const options = ko.utils.unwrapObservable(valueAccessor());

                _setupSelect2(element, valueAccessor, allBindings, false);
                _setupSelect2Observer(element);

                let $containerSelector = $element.parents().find('.' + options.containerSelector);
                if ($containerSelector.length !== 0) {
                    $element.on('select2:close', () => {
                        $containerSelector.removeClass('select2-adjacent--open');
                    }).on('select2:open', () => {
                        $containerSelector.addClass('select2-adjacent--open');
                    });
                }
            },
            update: function (element, valueAccessor, allBindings) {
                let options = ko.utils.unwrapObservable(valueAccessor());

                // close and rebuild the control with new data
                const $element = $(element);
                $element.off('select2:select');
                $element.select2('close');
                $element.select2('destroy');
                $element.html('');

                _setupSelect2(element, valueAccessor, allBindings, true);

                const onSelect = (e) => {
                    if (options.selectedData) {
                        options.selectedData(e.params.data._originalData);
                    } else {
                        options.selectedValue(e.params.data.id);
                    }
                };

                if (options.selectedData || options.selectedValue) {
                    $element.on('select2:select', onSelect);
                }
            }
        };

        const _getCitiesForRegion = (regionId, locations) => {
            let citiesForRegion = locations.cities.find(city => city.region === regionId);
            if (!citiesForRegion) {
                return [];
            }
            let mappedCities = citiesForRegion.cities.reduce((reducer, city) => {
                let cityName = city[0];
                let cityNpas = city[1];

                cityNpas.forEach((npa) => {
                    reducer.push({
                        id: npa + cityName,
                        text: cityName + " (" + npa + ")"
                    });
                });

                return reducer;
            }, []);
            return mappedCities;
        };

        const _getNpasForRegion = (regionId, locations) => {
            let citiesForRegion = locations.cities.find(city => city.region === regionId);
            if (!citiesForRegion) {
                return [];
            }
            var uniqueNpas = new Set();
            citiesForRegion.cities.forEach((city) => {
                city[1].forEach((npa) => {
                    uniqueNpas.add(npa);
                });
            });

            return Array.from(uniqueNpas).sort().map((npa) => {
                return {
                    id: npa,
                    text: "(" + npa + ")"
                };
            });
        };

        ko.bindingHandlers.numberPickerDropdowns = {
            init: function (element, valueAccessor) {
                let parameters = valueAccessor();
                let baseObject = {
                    placeholder: ko.utils.unwrapObservable(parameters.placeholder),
                    noResults: ko.utils.unwrapObservable(parameters.noResults),
                    dropdownCssClass: parameters.dropdownCssClass ? parameters.dropdownCssClass : '',
                    data: [],
                    width: "100%",
                    theme: `default ${parameters.theme ? parameters.theme : ''}`,
                };

                let countryDropdown = $("." + parameters.countryDropdownClass);
                let regionDropdown = $("." + parameters.regionDropdownClass);
                let cityDropdown = parameters.hasOwnProperty("cityDropdownClass") ? $("." + parameters.cityDropdownClass) : null;
                let npaDropdown = parameters.hasOwnProperty("npaDropdownClass") ? $("." + parameters.npaDropdownClass) : null;

                let countryOptions = Object.assign({}, baseObject);
                let regionOptions = Object.assign({}, baseObject);
                let cityOptions = cityDropdown ? Object.assign({}, baseObject) : null;
                let npaOptions = npaDropdown ? Object.assign({}, baseObject) : null;

                $.fn.select2.defaults.set('language', {
                    noResults: function () {
                        return baseObject.noResults;
                    },
                });

                countryDropdown.select2(countryOptions);
                regionDropdown.select2(regionOptions);
                if (cityDropdown) {
                    cityDropdown.select2(cityOptions);
                }
                if (npaDropdown) {
                    npaDropdown.select2(npaOptions);
                }
            },
            update: function (element, valueAccessor) {
                let parameters = valueAccessor();
                let baseObject = {
                    placeholder: ko.utils.unwrapObservable(parameters.noResults),
                    noResults: ko.utils.unwrapObservable(parameters.noResults),
                    dropdownCssClass: parameters.dropdownCssClass ? parameters.dropdownCssClass : '',
                    disabled: true,
                    data: [],
                    width: "100%",
                    theme: `default ${parameters.theme ? parameters.theme : ''}`
                };

                $.fn.select2.defaults.set('language', {
                    noResults: function () {
                        return baseObject.noResults;
                    },
                });

                let locations = ko.utils.unwrapObservable(parameters.locations);
                let countryDropdown = $("." + parameters.countryDropdownClass);
                let regionDropdown = $("." + parameters.regionDropdownClass);
                let cityDropdown = parameters.hasOwnProperty("cityDropdownClass") ? $("." + parameters.cityDropdownClass) : null;
                let npaDropdown = parameters.hasOwnProperty("npaDropdownClass") ? $("." + parameters.npaDropdownClass) : null;

                let countrySelection = ko.utils.unwrapObservable(parameters.countrySelection) || [];
                let regionSelection = ko.utils.unwrapObservable(parameters.regionSelection) || [];
                let citySelection = cityDropdown ? ko.utils.unwrapObservable(parameters.citySelection) || []: null;
                let npaSelection = npaDropdown ? ko.utils.unwrapObservable(parameters.npaSelection) || [] : null;
                let isInitialized = ko.utils.unwrapObservable(parameters.isInitialized);

                if ((countrySelection.length !== 0) &&
                    (regionSelection.length !== 0) &&
                    ((citySelection && citySelection.length !== 0) || (npaSelection && npaSelection.length !== 0)) &&
                    (locations !== null) &&
                    (!isInitialized))
                {
                    parameters.isInitialized(true);
                    baseObject.disabled = false;

                    let countryOptions = Object.assign({}, baseObject);
                    countryOptions.data = locations.countries;

                    let regionOptions = Object.assign({}, baseObject);
                    regionOptions.data = locations.regions[countrySelection[0]];

                    let cityOptions = cityDropdown ? Object.assign({}, baseObject): null;
                    if (cityDropdown) {
                        cityOptions.data = _getCitiesForRegion(regionSelection[0], locations);
                    }

                    let npaOptions = npaDropdown ? Object.assign({}, baseObject) : null;
                    if (npaDropdown) {
                        npaOptions.data = _getNpasForRegion(regionSelection[0], locations);
                    }

                    countryDropdown
                        .select2(countryOptions)
                        .val(countrySelection[0])
                        .trigger('change');

                    regionDropdown
                        .select2(regionOptions)
                        .val(regionSelection[0])
                        .trigger('change');

                    if (cityDropdown) {
                        cityDropdown
                            .select2(cityOptions)
                            .val(citySelection[0])
                            .trigger('change');
                    }

                    if (npaDropdown) {
                        npaDropdown
                            .select2(npaOptions)
                            .val(npaSelection[0])
                            .trigger('change');
                    }

                    countryDropdown
                        .on('select2:select', (selectEvent) => {
                            let selectedCountryCode = selectEvent.params.data.id;
                            parameters.countrySelection([selectedCountryCode]);
                            if (ko.isObservable(parameters.didCountryChange)) {
                                parameters.didCountryChange(true);
                            }

                            let regionOptions = Object.assign({}, baseObject);
                            regionOptions.data = locations.regions[selectedCountryCode];
                            let defaultRegion = regionOptions.data[0].id;

                            regionDropdown
                                .select2('destroy')
                                .html('')
                                .select2(regionOptions)
                                .val(defaultRegion)
                                .trigger('change');
                        });

                    regionDropdown
                        .on('change', () => {
                            let selectedRegion = regionDropdown.select2('data')[0].id;
                            parameters.regionSelection([selectedRegion]);
                            if (ko.isObservable(parameters.didRegionChange)) {
                                parameters.didRegionChange(true);
                            }

                            if (cityDropdown) {
                                let cityOptions = Object.assign({}, baseObject);
                                cityOptions.data = _getCitiesForRegion(selectedRegion, locations);
                                let citySelection = "";
                                if (cityOptions.data.length) {
                                    citySelection = cityOptions.data[0].id;
                                }

                                cityDropdown
                                    .select2('destroy')
                                    .html('')
                                    .select2(cityOptions)
                                    .val(citySelection)
                                    .trigger('change');
                            }

                            if (npaDropdown) {
                                let npaOptions = Object.assign({}, baseObject);
                                npaOptions.data = _getNpasForRegion(selectedRegion, locations);
                                let npaSelection = "";
                                if (npaOptions.data.length) {
                                    npaSelection = npaOptions.data[0].id;
                                }

                                npaDropdown
                                    .select2('destroy')
                                    .html('')
                                    .select2(npaOptions)
                                    .val(npaSelection)
                                    .trigger('change');
                            }
                        });

                    if (cityDropdown) {
                        cityDropdown
                            .on('change', () => {
                                if (cityDropdown.select2('data').length) {
                                    let selectedCity = cityDropdown.select2('data')[0].id;
                                    if (ko.isObservable(parameters.didCityChange)) {
                                        parameters.didCityChange(true);
                                    }

                                    if (parameters.citySelection()[0] !== selectedCity) {
                                        parameters.citySelection([selectedCity]);
                                    }
                                }
                            });
                    }

                    if (npaDropdown) {
                        npaDropdown
                            .on('change', () => {
                                if (npaDropdown.select2('data').length) {
                                    let selectedNpa = npaDropdown.select2('data')[0].id;
                                    if (ko.isObservable(parameters.didNpaChange)) {
                                        parameters.didNpaChange(true);
                                    }

                                    if (parameters.npaSelection()[0] !== selectedNpa) {
                                        parameters.npaSelection([selectedNpa]);
                                    }
                                }
                            });
                    }
                }

                if (ko.utils.unwrapObservable(parameters.isInitialized)) {
                    if (cityDropdown && cityDropdown.select2('data').length) {
                        if (ko.utils.unwrapObservable(parameters.citySelection)[0] !== cityDropdown.select2('data')[0].id) {
                            cityDropdown.val(ko.utils.unwrapObservable(parameters.citySelection)[0])
                                .trigger('change');
                        }
                    }

                    if (npaDropdown && npaDropdown.select2('data').length) {
                        if (ko.utils.unwrapObservable(parameters.npaSelection)[0] !== npaDropdown.select2('data')[0].id) {
                            npaDropdown.val(ko.utils.unwrapObservable(parameters.npaSelection)[0])
                                .trigger('change');
                        }
                    }
                }
            }
        };

        var _getContentPaneScrollPositionBottom = function (scrollInfo) {
            var $container = scrollInfo.container;
            var $content = $container.find(".mCSB_container");
            var extraContentHeight = $content.height() - $container.height();
            var contentOffset = $content.offset().top - $container.offset().top;
            var bottom = extraContentHeight + contentOffset;

            return bottom;
        };

        var _contentPaneHasScrollbar = function (element) {
            var $content = $(element).parents(".mCSB_container");

            return !$content.hasClass("mCS_no_scrollbar");
        };
        ko.bindingHandlers.contentPaneScroll = {
            init: function (element) {
                const $contentContainer = $(element).find(SCROLL_CONTAINER_SELECTOR);

                const BUFFER = 200;
                const onChange = (event) => {
                    const scrollTop = event.target.scrollTop;
                    const scrollHeight = event.target.scrollHeight;
                    const offsetHeight = event.target.offsetHeight;
                    const offset = scrollHeight - offsetHeight - scrollTop;

                    if (offset < BUFFER) {
                        _eventManager.publishDataGridGetNextPage();
                    }
                };

                const scrollbarInstance = $contentContainer.overlayScrollbars({}).overlayScrollbars();
                if (scrollbarInstance) {
                    scrollbarInstance.options("callbacks.onScroll", onChange);
                }

                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    if (scrollbarInstance) {
                        scrollbarInstance.destroy();
                    }
                });

            }
        };

        ko.bindingHandlers.contentPaneInfiniteScroll = {
            init: function (element, valueAccessor) {
                var buffer = 500;
                var settings = valueAccessor();

                if (settings.buffer) {
                    buffer = ko.utils.unwrapObservable(settings.buffer);
                }

                var scrollEventId = _eventManager.subscribeContentPaneScroll(function (scrollInfo) {
                    setTimeout(function () {
                        var bottom = _getContentPaneScrollPositionBottom(scrollInfo);

                        if (bottom < buffer) {
                            _eventManager.publishDataGridGetNextPage();
                        }
                    }, _uiAnimationsConfiguration.presentation_common_datagrid_check_content_for_scroll_delay);
                });

                setTimeout(function () {
                    var hasScrollbar = _contentPaneHasScrollbar(element);

                    if (hasScrollbar === false) {
                        _eventManager.publishDataGridGetNextPage();
                    }
                }, _uiAnimationsConfiguration.presentation_common_datagrid_check_content_for_scroll_delay);

                _eventManager.publishContentPaneScrollEventId(scrollEventId);
            }
        };

        var addBusinessDays = function(date, days) {
            date = _moment(date);
            while (days > 0) {
                date = date.add(1, 'd');
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    days -= 1;
                }
            }
            return date.toDate();
        };

        ko.bindingHandlers.datePicker = {
            init: function (element, valueAccessor) {
                const dataBindOptions = valueAccessor();
                const valueObservable = dataBindOptions.value;
                const cssClass = dataBindOptions.cssClass;

                $(element).datetimepicker({
                    autoclose: true,
                    controlType: 'select',
                    minDate: addBusinessDays(_moment(), 6),
                    maxDate: '+30d',
                    beforeShowDay: $.datepicker.noWeekends,
                    showButtonPanel: false,
                    dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                    beforeShow: function (input, inst) {
                        const calendar = inst.dpDiv;
                        const inputOffset = $(input).offset();
                        const left = inputOffset.left;
                        const top = inputOffset.top;

                        setTimeout(function () {
                            // check for touch screen devices and mobile view width to remove focus of input
                            // and avoid native keyboards doing weird things
                            const width = $(window).width();
                            if ("ontouchstart" in document.documentElement && width <= 980) {
                                $(element).blur();
                            }
                            if (valueObservable() !== null && valueObservable() !== '') {
                                $(element).datetimepicker('disable');
                                $(element).datetimepicker('setDate', valueObservable());
                                $(element).datetimepicker('enable');
                            }

                            calendar.position({
                                my: 'left top',
                                at: 'left bottom',
                                collision: 'none',
                                of: input
                            });
                            calendar.css({
                                top: top - 290 + 'px', // top of input - max height of calendar
                                left: left + 'px',
                                zIndex: 8,
                            });
                            $("#ui-datepicker-div").addClass(cssClass);
                        }, 0);
                    },
                    onSelect: function (datetimeText) {
                        valueObservable(datetimeText);
                    },
                });
                $(element).inputmask({
                    inputFormat: "mm/dd/yyyy",
                    alias: "datetime",
                    clearIncomplete: true
                });
                $(element).blur(function(){
                    if($(this).val() !== valueObservable()) {
                        valueObservable($(this).val());
                    }
                });
                $('.calendar-icon').on("click", () => {
                    $(element).datetimepicker("show");
                });
            },
            update: function(element, valueAccessor) {
                const dateValue = valueAccessor().value();
                if (dateValue !== null && dateValue !== '') {
                    $(element).datetimepicker('disable');
                    $(element).datetimepicker('setDate', dateValue);
                    $(element).datetimepicker('enable');
                }
                if (dateValue === '') {
                    const sixBusinessDaysFromNow = addBusinessDays(_moment(), 6);
                    valueAccessor().value(sixBusinessDaysFromNow.toISOString());
                    $(element).datetimepicker('setDate', sixBusinessDaysFromNow);
                }
            }
        };

        const setCalendarPosition = (element, input, calendar) => {
            const inputOffset = $(input).offset();
            const top = inputOffset.top - $(document).scrollTop() + 45;   // top of input relative relative to window  + height of input
            const left = inputOffset.left + ($(input).width() / 2) - 30;  // right of input - icon position

            setTimeout(function () {
                // check for touch screen devices and mobile view width to remove focus of input
                // and avoid native keyboards doing weird things
                const width = $(window).width();
                if ("ontouchstart" in document.documentElement && width <= 980) {
                    $(element).blur();
                }
                calendar.position({
                    my: 'left top',
                    at: 'left bottom',
                    collision: 'none',
                    of: input
                });
                calendar.css({
                    top: top,
                    left: left,
                    zIndex: 10,
                });
            }, 0);
        };

        ko.bindingHandlers.dateRangePicker = {
            init: function (element, valueAccessor) {
                const dataBindOptions = valueAccessor();
                const valueObservable = dataBindOptions.value;
                const cssClass = dataBindOptions.cssClass;
                const iconClass = "." + dataBindOptions.iconClass;
                const placeholderClass = "." + dataBindOptions.placeholderClass;

                $(element).datetimepicker({
                    autoclose: true,
                    controlType: 'select',
                    showButtonPanel: false,
                    showOn: '',
                    dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                    defaultDate: 0,
                    beforeShow: function (input, inst) {
                        const calendar = inst.dpDiv;
                        setCalendarPosition(element, input, calendar);
                        $("#ui-datepicker-div").addClass(cssClass);

                        const $scrollableContainer = $(SCROLL_CONTENT_WRAPPER_SELECTOR);
                        if ($scrollableContainer) {
                            let scrollbarInstance = $scrollableContainer.overlayScrollbars({
                                callbacks: {
                                    onScroll: function(e) {
                                        setCalendarPosition(element, input, calendar);
                                    }
                                }
                            }).overlayScrollbars();
                        }
                    },
                    onSelect: function (datetimeText) {
                        valueObservable(datetimeText);
                        $(element).datetimepicker("hide");
                    },
                    onClose: function () {
                        if($(this).val() !== valueObservable()) {
                            valueObservable($(this).val());
                        }
                    }
                }).parent().find(iconClass).on("click", function () {
                    $(element).datetimepicker("show");
                }).parent().find(placeholderClass).on("click", function () {
                    $(element).focus();
                });
                $(element).focus(function () {
                    $(element).parent().find(placeholderClass).css({"opacity": 0, display: "none"});
                });
                $(element).blur(function () {
                    if ($(this).val() === null || $(this).val() === '') {
                        $(element).parent().find(placeholderClass).css({"opacity": 1, display: "block" });
                    }
                    if($(this).val() !== valueObservable()) {
                        valueObservable($(this).val());
                    }
                });
                $(element).keypress(function (e) {
                    let keycode = (e.keyCode ? e.keyCode : e.which);
                    if (keycode === $.ui.keyCode.ENTER) {
                        $(element).blur();
                    }
                });
                $(element).inputmask({
                    inputFormat: "mm/dd/yyyy",
                    alias: "datetime",
                    clearIncomplete: true
                });
            },
            update: function(element, valueAccessor) {
                const dateValue = valueAccessor().value();
                const placeholderClass = "." + valueAccessor().placeholderClass;
                if (dateValue !== null && dateValue !== '') {
                    $(element).datetimepicker('disable');
                    $(element).datetimepicker('setDate', dateValue);
                    $(element).datetimepicker('enable');
                    $(element).parent().find(placeholderClass).css("opacity", 0);
                } else {
                    $(element).parent().find(placeholderClass).css("opacity", 1);
                }
            }
        };

        var _listSelectorIcon = null;
        var _listSelectorIconInitialPositionSet = false;
        var _listSelectorItemOnClickHandler = function (event) {
            var selectedItem = event.currentTarget;

            if (_listSelectorIconInitialPositionSet === false) {
                var selectorIconIndex = _listSelectorIcon.index();
                var currentPosition = $(selectedItem).outerHeight(true) * (selectorIconIndex);
                _listSelectorIcon.css("top", currentPosition);
                $(selectedItem).parent("ul").prepend(_listSelectorIcon);

                _listSelectorIconInitialPositionSet = true;
            }

            var newPosition = $(selectedItem).outerHeight(true) * ($(selectedItem).index() - 1);
            _listSelectorIcon.css("top", newPosition);
        };

        const _copyToClipboardClickHandler = (element, text) => {
            navigator.clipboard.writeText(text)
                .then(() => {
                    $(element).tooltip({
                        "animation": false,
                        "placement": "top",
                        "trigger": "manual",
                        "html": true,
                        "title": _i18n.t('copied')
                    }).tooltip('show');

                    setTimeout(() => {
                        $(element).tooltip('destroy');
                    }, 1000);
                });
        };

        ko.bindingHandlers.copyToClipboard = {
            init: function (element, valueAccessor) {
                const text = valueAccessor();
                $(element).click(() => {
                    _copyToClipboardClickHandler(element, text);
                });
            }
        };

        ko.bindingHandlers.listSelector = {
            init: function (element) {
                var listItems = $("li", element);
                var selectedIndex = -1;

                for (var i = 0; i < listItems.length; i++) {
                    $(listItems[i]).on("click", _listSelectorItemOnClickHandler);
                    if ($(listItems[i]).hasClass("active")) {
                        selectedIndex = i;
                    }
                }

                _listSelectorIcon = $("<li>");
                _listSelectorIcon.addClass("selected-list-item-indicator");
                $("li", element).eq(selectedIndex).before(_listSelectorIcon);
            }
        };

        ko.bindingHandlers.foreachWithAnimation = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                return ko.bindingHandlers.foreach.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
                var value = ko.unwrap(valueAccessor());
                var newValue = function () {
                    return {
                        data: value,
                        afterAdd: function (element) {
                            setTimeout(function () {
                                $(element).addClass('zoomed-in');
                            }, _uiAnimationsConfiguration.presentation_forEachWithAnimation_animate_in_add_class);

                        },
                        beforeRemove: function (element) {
                            setTimeout(function () {
                                $(element).addClass('slide-up');
                            }, _uiAnimationsConfiguration.presentation_forEachWithAnimation_animate_out_remove_class);
                            setTimeout(function () {
                                $(element).remove();
                            }, _uiAnimationsConfiguration.presentation_forEachWithAnimation_animate_out_remove_item);
                        }
                    };
                };

                ko.bindingHandlers.foreach.update(element, newValue, allBindingsAccessor, viewModel, context);
            }
        };

        ko.bindingHandlers.foreachWithSlide = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                return ko.bindingHandlers.foreach.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
                const settings = ko.unwrap(valueAccessor());
                const data = settings.data;
                const scrollIntoView = settings.scrollIntoView;
                const $container = $(element).parent();

                const newValue = () => {
                    return {
                        data: data,
                        afterAdd: (e) => {
                            if (e.nodeType === 1) {
                                $(e).hide().fadeIn();
                                if (scrollIntoView === true) {
                                    setTimeout(() => {
                                        $($container)[0].scrollIntoView({behavior: "smooth", block: "end"});
                                    }, 100);
                                }
                            }
                        },
                        beforeRemove: (e) => {
                            if (e.nodeType === 1) {
                                $(e).animate({height: 0, opacity: 0}, 400);
                                setTimeout(() => {
                                    $(e).remove();
                                },400);
                            }
                        }
                    };
                };

                ko.bindingHandlers.foreach.update(element, newValue, allBindingsAccessor, viewModel, context);
            }
        };

        var _urlDownloaderOnClickHandler = function (getUrlFunction, context) {
            getUrlFunction(context.$data).done(function (result) {
                var userAgent = (navigator.userAgent || navigator.vendor || window.opera).toLowerCase();
                var isFirefox = userAgent.indexOf('firefox') !== -1;
                if(result !== undefined) {
                    if(!isFirefox) {
                        window.location.href = result;
                    } else {
                        $("<iframe>")
                            .hide()
                            .prop("src", result)
                            .appendTo("body");
                    }
                }
            });

        };

        ko.bindingHandlers.urlDownloader = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var getUrl = valueAccessor();
                $(element).on("click", function (event) {
                    event.stopPropagation();
                    _urlDownloaderOnClickHandler(getUrl, bindingContext);
                });
            }
        };

        ko.bindingHandlers.removeFixedOnFocusInTouchDevices = {
            init: function (element) {
                if (Modernizr.touch) {
                    $(document)
                        .on('focus', 'input', function () {
                            _updateRemoveFixedOnFocusInTouchDevicesClass(element);
                        })
                        .on('blur', 'input', function () {
                            _updateRemoveFixedOnFocusInTouchDevicesClass(element);
                        });
                }
            }
        };

        var _updateRemoveFixedOnFocusInTouchDevicesClass = function (element) {
            var focusCount = $('input:focus').length;
            if (focusCount > 0) {
                setTimeout(function () {
                    $(element).addClass('remove-fixed-for-touch-devices');
                }, 50);
            } else {
                setTimeout(function () {
                    $(element).removeClass('remove-fixed-for-touch-devices');
                    $(window).scrollTop($(window).scrollTop() + 1);
                    $(window).scrollTop($(window).scrollTop() - 1);
                }, 1);
            }
        };

        var CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
        var _currencyFormatter = new CurrencyFormatterConstructor();

        ko.bindingHandlers.currency = {
            update: function (element, valueAccessor, allBindingsAccessor) {
                return ko.bindingHandlers.text.update(element, function () {
                    var value = (ko.unwrap(valueAccessor()) || 0);
                    var accounting = allBindingsAccessor().accounting;
                    return _currencyFormatter.formatUSDForDisplay(value, accounting);
                });
            }
        };

        ko.bindingHandlers.detailsGroupContainer = {
            init: function (element, valueAccessor) {
                var config = ko.unwrap(valueAccessor());

                var $detailsGroupContainer = $(element);
                var $detailsGroupTitle = $("." + config.header, $detailsGroupContainer);
                var $detailsGroupContent = $("." + config.content, $detailsGroupContainer);

                $detailsGroupTitle.on("click", function () {
                    $detailsGroupContainer.toggleClass("expanded");

                    if ($detailsGroupContent.length > 0) {
                        if ($detailsGroupContainer.hasClass("expanded")) {
                            $detailsGroupContent.slideDown(_uiAnimationsConfiguration.presentation_settings_billing_bill_cycle_to_date_details_expand_collapse_speed);
                        } else {
                            $detailsGroupContent.slideUp(_uiAnimationsConfiguration.presentation_settings_billing_bill_cycle_to_date_details_expand_collapse_speed);
                        }
                    }
                });
            }
        };

        ko.bindingHandlers.scrollToTop = {
            init: function(element) {
                const $scrollableContainer = $(element);
                $scrollableContainer.overlayScrollbars({ }).overlayScrollbars().scroll(0, 0);
            }
        };

        ko.bindingHandlers.scrollToTopOnChange = {
            init: function(element, valueAccessor) {
                const observableToWatchForChanges = valueAccessor();
                const $scrollableContainer = $(element);
                if (ko.isObservable(observableToWatchForChanges)) {
                    observableToWatchForChanges.subscribe(function() {
                        if (observableToWatchForChanges()) {
                            $scrollableContainer.overlayScrollbars({ }).overlayScrollbars().scroll(0, 200);
                        }
                    });
                }
            }
        };

        ko.bindingHandlers.progressSpinner = require('presentation/common/progressSpinner/progressSpinner');

        const configureAudioPlayerKnockoutBinding = require('presentation/common/audioPlayer/audioPlayerKnockoutBinding');
        configureAudioPlayerKnockoutBinding(ko);

        const configureAnalyticsBinding = require('presentation/common/analyticsGraph/analyticsGraphBinding');
        configureAnalyticsBinding(ko);
    };

    ko.bindingHandlers.mobileNavbar = {
        init: function(element) {
            var isMenuOpen = false;
            var initialTop = null;

            var closeMenu = function() {
                $(".mobile-menu-slider").velocity({
                    top: initialTop
                }, {
                    duration: 600,
                    easing: "easeOutQuart"
                });
                isMenuOpen = false;
            };

            var openMenu = function() {
                $(".mobile-menu-slider").velocity({
                    top: 0
                }, {
                    duration: 600,
                    easing: "easeOutQuart"
                });
                isMenuOpen = true;
            };

            var toggleMenu = function() {
                if (initialTop === null) {
                    initialTop = $(".mobile-menu-slider").css("top");
                }
                if (isMenuOpen) {
                    closeMenu();
                } else {
                    openMenu();
                }
                return false;
            };

            $(element).on("click", toggleMenu);
        }
    };

    ko.bindingHandlers.stickyHeaderResize = {
        init: function(element, valueAccessor) {
            const config = ko.unwrap(valueAccessor());
            const $element = $(element);

            const parentSelector = config.parentSelector ? config.parentSelector : ".interior-shell__content";
            const $parent = $element.parents(parentSelector);

            const setElementWidth = () => {
                $element.width($parent.outerWidth());
            };

            ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                $(window).off('resize', setElementWidth);
            });

            setElementWidth();
            $(window).resize(setElementWidth);
        }
    };

    ko.bindingHandlers.scrollModalToTopOnContentChange = {
        init: function(element, valueAccessor) {
            var observableToWatchForChanges = valueAccessor();
            if (ko.isObservable(observableToWatchForChanges)) {
                observableToWatchForChanges.subscribe(function() {
                    $(".modal-container").scrollTop(0);
                });
            }
        }
    };

    ko.bindingHandlers.accountProfileTooltipScrollAndFade = {
        init: function(element, valueAccessor) {
            const CONTENT_PANE_Y = 81;
            const params = ko.utils.unwrapObservable(valueAccessor());

            const targetElement = document.getElementById(params.id);
            const scrollElement = document.getElementById(params.scrollContainerId);
            const $scrollContainer = $('#scrollableContent');

            const onChange = () => {
                const newTop = targetElement.getBoundingClientRect().y - CONTENT_PANE_Y;
                element.style.top = `${newTop}px`;

                if (newTop <= CONTENT_PANE_Y) {
                    if (element.style.visibility !== 'hidden') {
                        element.style.visibility = 'hidden';
                    }
                } else {
                    if (element.style.visibility !== 'visible') {
                        element.style.visibility = 'visible';
                    }
                }

                if (newTop > CONTENT_PANE_Y && newTop <= (CONTENT_PANE_Y + 50)) {
                    if (element.style.opacity !== '0') {
                        element.style.opacity = 0;
                    }
                } else if (newTop > (CONTENT_PANE_Y + 50) && newTop <= 350) {
                    if (element.style.opacity !== '0.5') {
                        element.style.opacity = 0.5;
                    }
                } else if (newTop > 350) {
                    if (element.style.opacity !== '1') {
                        element.style.opacity = 1;
                    }
                }
            };

            const observer = new MutationObserver(onChange);

            observer.observe(scrollElement, { childList: true, subtree: true });

            const scrollbarInstance = $scrollContainer.overlayScrollbars({}).overlayScrollbars();
            if (scrollbarInstance) {
                scrollbarInstance.options("callbacks.onScroll", onChange);
            }

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                observer.disconnect();

                if (scrollbarInstance) {
                    scrollbarInstance.destroy();
                }
            });
        },
    };

    var NumberFormatterConstructor = require('businessServices/converters/numberFormatter');
    var _numberFormatter = new NumberFormatterConstructor();
    ko.bindingHandlers.numberFormatted = {
        update: function(element, valueAccessor) {
            return ko.bindingHandlers.html.update(element, function() {
                var numberValue = ko.utils.unwrapObservable(valueAccessor());
                return _numberFormatter.formatForDisplay(numberValue);
            });
        }
    };

    var DurationTimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
    var _durationFormatter = new DurationTimeFormatterConstructor();
    ko.bindingHandlers.durationDisplay = {
        update: function(element, valueAccessor) {
            return ko.bindingHandlers.html.update(element, function(){
                var seconds = ko.utils.unwrapObservable(valueAccessor());
                return _durationFormatter.formatSecondsSummary(seconds);
            });
        }
    };

    ko.bindingHandlers.fadeInVisible = {
        update: function(element, valueAccessor) {
            var value = valueAccessor();
            $(element).hide();
            if (ko.unwrap(value)){
                setTimeout(function() {
                    $(element).fadeIn("slow");
                }, 500);
            }
        }
    };

    ko.bindingHandlers.fadeIn = {
        update: function(element, valueAccessor) {
            var value = valueAccessor();
            if (value.onlyIf !== undefined) {
                if (ko.unwrap(value.onlyIf)) {
                    $(element).hide();
                    $(element).fadeIn(500);
                }
            } else {
                $(element).hide();
                if (ko.unwrap(value)) {
                    $(element).fadeIn(500);
                }
            }
        }
    };

    ko.bindingHandlers.floatToPropertyPercent = {
        update: function(element, valueAccessor) {
            const _setValue = ({property, percent}) => {
                const normalizedPercent = percent <= 1 ? percent * 100 : percent;
                const convertedPercent = Number.parseFloat(normalizedPercent).toPrecision(3).toString();

                $(element).css(property, convertedPercent + '%');
            };

            const params = valueAccessor();

            if (params.properties && params.properties.length) {
                params.properties.forEach(_setValue);
            } else {
                _setValue({property: params.property, percent: params.percent});
            }
        }
    };

    ko.bindingHandlers.composeAreaResizer = {
        init: function (element) {
            const dragBar = element;
            /** @type {HTMLElement} */
            const textAreaWrapperElement = document.querySelector('.message-compose-area');
            /** @type {HTMLElement} */
            const textAreaElement = document.querySelector('.message-compose-area__textarea');
            const defaultTextAreaWrapperElementHeight = _getParsedHeightForElement(textAreaWrapperElement);
            const defaultTextAreaElementHeight = _getParsedHeightForElement(textAreaElement);
            const heightDifference = defaultTextAreaWrapperElementHeight - defaultTextAreaElementHeight;
            /** @type {Number | string} */
            let rowLineHeight = document.defaultView.getComputedStyle(textAreaElement).getPropertyValue('line-height');
            rowLineHeight = parseInt(rowLineHeight, 10);
            let textAreaWrapperElementHeight = 0;
            let textAreaElementHeight = 0;
            let initialYAxisMousePosition = 0;
            let isManuallyResized = false;

            const _onMouseDown = (/** @type {MouseEvent} */e) => {
                initialYAxisMousePosition = e.clientY;
                textAreaWrapperElementHeight = _getParsedHeightForElement(textAreaWrapperElement);
                textAreaElementHeight = _getParsedHeightForElement(textAreaElement);

                document.addEventListener('mousemove', _onMouseMove);
                document.addEventListener('mouseup', _onMouseUp);
            };

            const _onMouseMove = (/** @type {MouseEvent} */ e) => {
                isManuallyResized = true;
                const dy = initialYAxisMousePosition - e.clientY;
                textAreaWrapperElement.style.height = `${textAreaWrapperElementHeight + dy}px`;
            };

            const _onMouseUp = (/** @type {MouseEvent} */ e) => {
                document.removeEventListener('mousemove', _onMouseMove);
            };

            const _onTextAreaElementInput = (/** @type {Event} */e) => {
                if (isManuallyResized) {
                    textAreaElementHeight = _getParsedHeightForElement(textAreaElement);
                    const rows = document.querySelector('textarea').value.split(/\r\n|\r|\n/).length;
                    /** @type {string | number} */
                    let textAreaPaddingTop = window.getComputedStyle(textAreaElement).getPropertyValue('padding-top');
                    textAreaPaddingTop = parseInt(textAreaPaddingTop, 10);
                    /** @type {string | number} */
                    let textAreaPaddingBottom = window.getComputedStyle(textAreaElement).getPropertyValue('padding-bottom');
                    textAreaPaddingBottom = parseInt(textAreaPaddingBottom, 10);
                    const textareaRowsHeight = (rows * /** @type {Number} */(rowLineHeight)) + textAreaPaddingTop + textAreaPaddingBottom;

                    if (textAreaElementHeight < textareaRowsHeight) {
                        textAreaElement.style.overflowY = "scroll";

                        textAreaWrapperElement.style.height = "auto";
                        textAreaElement.style.height = "auto";

                        textAreaWrapperElement.style.height = `${textareaRowsHeight + heightDifference}px`;
                        textAreaElement.style.height = `${textareaRowsHeight}px`;
                    }
                }
            };

            const _onTextAreaElementPaste = (/** @type {ClipboardEvent} */e) => {
                if (isManuallyResized) {
                    textAreaElementHeight = _getParsedHeightForElement(textAreaElement);
                    let pastedText = (e.clipboardData || window.clipboardData).getData('text');
                    pastedText = pastedText.replace(/(\r\n|\n|\r)/gm, "");
                    const textAreaCols = 100;
                    const rows = pastedText.length / textAreaCols;
                    const textareaRowsHeight = (rows * /**@type {Number}*/(rowLineHeight));

                    if (textAreaElementHeight < textareaRowsHeight) {
                        textAreaElement.style.overflowY = "scroll";

                        textAreaWrapperElement.style.height = "auto";
                        textAreaElement.style.height = "auto";

                        textAreaWrapperElement.style.height = `${textareaRowsHeight + heightDifference}px`;
                        textAreaElement.style.height = `${textareaRowsHeight}px`;
                    }
                }
            };

            dragBar.addEventListener('mousedown', _onMouseDown);
            textAreaElement.addEventListener('input', _onTextAreaElementInput);
            textAreaElement.addEventListener('paste', _onTextAreaElementPaste);

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                document.removeEventListener('mousedown', _onMouseDown);
                document.removeEventListener('mousemove', _onMouseMove);
                document.removeEventListener('mouseup', _onMouseUp);
                textAreaElement.removeEventListener('input', _onTextAreaElementInput);
                textAreaElement.removeEventListener('paste', _onTextAreaElementPaste);
            });
        }
    };

    const _getParsedHeightForElement = (element) => {
        let elementComputedStyle = window.getComputedStyle(element);
        return parseInt(elementComputedStyle.height, 10);
    };

    ko.observable.fn.toggleable = function () {
        const self = this;
        self.toggle = function () {
            self(!self());
        };

        return self;
    };

    return {
        configureKnockout: configure
    };
});

