/** Formats the duration from Seconds to Minutes : Seconds format.
*   @module businessServices/converters/durationTimeFormatter
*/

define('businessServices/converters/durationTimeFormatter',[], function() {
    return function() {
        /** @typedef {import('common/time/timeSpan')} TimeSpan */

        const self = this;

        /**
        * Formats the time duration as Min : Sec when the duration provided is in Seconds
        * @param {TimeSpan} duration
        * @returns {string}
        */
        const _format = (duration) => {
            const minutes = duration.getTotalMinutes();
            const seconds = duration.seconds();

            const paddedMinutes = "00" + minutes.toString();
            const trimmedMinutes = paddedMinutes.substr(-2);
            const paddedSeconds = "00" + seconds.toString();
            const trimmedSeconds = paddedSeconds.substr(-2);

            return trimmedMinutes + ':' + trimmedSeconds;
        };

        // Formats a timespam as "#h #m #s"
        const _formatElapsedTime = (duration) => {
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            let formattedDuration = "";

            if (hours) {
                formattedDuration += hours +"h";
            }

            if (minutes) {
                formattedDuration += " " + minutes + "m";
            }

            if (seconds) {
                formattedDuration += " " + seconds + "s";
            }

            return formattedDuration;
        };

        const _getTotalMinutesAndSeconds = (/** @type number */ totalSeconds) => {
            const roundedSeconds = Math.floor(totalSeconds);
            const totalMinutes = Math.floor(roundedSeconds / 60);
            const seconds = roundedSeconds % 60;

            return {
                totalMinutes: totalMinutes,
                seconds: seconds
            };
        };

        // formats the total number of seconds (as an integer/decimal) to "00:00"
        const _formatTotalSeconds = (/** @type number */ totalSeconds) => {
            if (typeof totalSeconds !== "number") {
                return totalSeconds;
            }

            const { totalMinutes, seconds } = _getTotalMinutesAndSeconds(totalSeconds);

            let trimmedMinutes = totalMinutes.toString();
            if (totalMinutes < 10) {
                const paddedMinutes = "00" + totalMinutes.toString();
                trimmedMinutes = paddedMinutes.substr(-2);
            }

            const paddedSeconds = "00" + seconds.toString();
            const trimmedSeconds = paddedSeconds.substr(-2);

            return trimmedMinutes + ":" + trimmedSeconds;
        };

        // formats the total number of seconds to "0:00"
        const _formatTotalSecondsNotPadded = (/** @type number */ totalSeconds) => {
            const { totalMinutes, seconds } = _getTotalMinutesAndSeconds(totalSeconds);

            const minutes = totalMinutes.toString();
            const paddedSeconds = seconds < 10 ? '0' : '';
            const trimmedSeconds = paddedSeconds + seconds.toString();

            return minutes + ":" + trimmedSeconds;
        };

        const _revertFormattedTotalSeconds = (/** @type string */ totalTime) => {
            const [minutes, seconds] = totalTime.split(':').map(Number);
            return minutes * 60 + seconds;
        };

        const MINUTE = 60;
        const HOUR = 3600;
        const DAY = 86400;
        const WEEK = 604800;
        const THOUSAND = 1000;
        const MILLION = 1000000;

        const _formatSecondsSummary = (seconds) => {
            const formatDuration = (interval1, indicator1, interval2, indicator2) => {
                return `${Math.floor(seconds / interval1)}${indicator1} ${Math.round(seconds % interval1 / interval2)}${indicator2}`;
            };
            if (seconds < MINUTE) {
                return `${Math.round(seconds)}s`;
            }
            if (seconds < HOUR) {
                return formatDuration(MINUTE, "m", 1, "s");
            }
            if (seconds < DAY) {
                return formatDuration(HOUR, "h", MINUTE, "m");
            }
            if (seconds < WEEK) {
                return formatDuration(DAY, "d", HOUR, "h");
            }
            return formatDuration(WEEK, "w", DAY, "d");
        };

        const  _formatMinutesSummary = (seconds) => {
            const minutes = Math.floor(seconds / MINUTE);
            const formatDuration = (interval1, indicator1) => {
                return `${Math.round((minutes / interval1) * 10) / 10}${indicator1}`;
            };
            if (minutes === 0) {
                return `0`;
            }
            if (minutes < THOUSAND) {
                return `${minutes}`;
            }
            if (minutes < MILLION) {
                return formatDuration(THOUSAND, "k");
            }
            return formatDuration(MILLION, "m");
        };

        const _formatTotalMilliseconds = (milliseconds) => {
            return _formatTotalSeconds(Math.floor(milliseconds / 1000));
        };

        self.format = _format;
        self.formatElapsedTime = _formatElapsedTime;
        self.formatMinutesSummary = _formatMinutesSummary;
        self.formatSecondsSummary = _formatSecondsSummary;
        self.formatTotalSeconds = _formatTotalSeconds;
        self.formatTotalSecondsNotPadded = _formatTotalSecondsNotPadded;
        self.revertFormattedTotalSeconds = _revertFormattedTotalSeconds;
        self.formatTotalMilliseconds = _formatTotalMilliseconds;
    };
});
