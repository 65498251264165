define('constants/stockExchangeEnumerations',[], function() {
    return {
        None: 1,
        Nasdaq: 2,
        Nyse: 3,
        Amex: 4,
        Amx: 5,
        Asx: 6,
        B3: 7,
        Bme: 8,
        Bse: 9,
        Fra: 10,
        Icex: 11,
        Jpx: 12,
        Jse: 13,
        Krx: 14,
        Lon: 15,
        Nse: 16,
        Omx: 17,
        Sehk: 18,
        Sgx: 19,
        Sse: 20,
        Sto: 21,
        Swx: 22,
        Szse: 23,
        Tsx: 24,
        Twse: 25,
        Vse: 26,
        Other: 27
    };
});

