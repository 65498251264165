define('presentation/common/actionModal/viewModels/deleteSubMenuActionViewModel',[
    'common/promises/promiseFactory',
    'common/storage/commonState'
], function() {
    return function(subMenuItem) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _commonState = require('common/storage/commonState');

        const _hideCallFlowControls = (connector) => {
            if (connector()) {
                connector().callFlow.map((c) => {
                    return c.showControls(false);
                });
            }
        };

        self.subMenuItem = subMenuItem;
        self.connector = subMenuItem.connector;
        self.keyDisplay = subMenuItem.keyDisplay;
        self.showVoicePromptOption = subMenuItem.showVoicePromptOption;
        self.selectedPrompt = ko.observable('');

        self.activate = () => {
            let connector = self.subMenuItem.connector;
            let subMenuItems = ko.unwrap(connector().subMenuItems);
            if (subMenuItems){
                subMenuItems.map((s) => {
                    s.showControls(false);
                    return _hideCallFlowControls(s.connector);
                });
            } else {
                _hideCallFlowControls(connector);
            }

            if (self.subMenuItem.showVoicePromptOption()) {
                self.selectedPrompt(_commonState.get(connector().voicePromptId()));
            }

            return _promiseFactory.wait();
        };
    };
});
