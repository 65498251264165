define('presentation/messages/dataSources/webMessagingDataSource',[
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory',
    'common/promises/waitHandle',
    'externalDependencies/clientWebSocket'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type typeof import('common/promises/promiseFactory')       */
    PromiseFactoryConstructor,
    /** @type typeof import('common/promises/waitHandle')           */
    WaitHandleConstructor,
    /** @type typeof import('externalDependencies/clientWebSocket') */
    clientWebSocket
) {

    /** @typedef {import('presentation/messages/dataSources/webMessagingDataSource')} WebMessagingDataSource */

    const WebMessagingDataSourceSingleton = function () {

        /** @type {WebMessagingDataSource} */
        const self = this;
        const _webMessagingDataSourceSocket = clientWebSocket.forApplication("webMessagingDataSource");
        const _promiseFactory = new PromiseFactoryConstructor();
        const _waitForDataWaitHandle = new WaitHandleConstructor();

        /** @type {KnockoutObservableArray<IWebMessagingNumber>} */
        const _webMessagingNumbers = ko.observableArray([]);
        /** @type {KnockoutObservableArray<string>} */
        const _authorizedHostedNumberIds = ko.observableArray([]);
        /** @type {KnockoutObservableArray<ISmsConversation>} */
        const _smsConversations = ko.observableArray([]);
        /** @type {KnockoutObservableArray<string>} */
        const _smsConversationIds = ko.observableArray([]);
        /** @type {KnockoutObservableArray<string>} */
        const _smsConversationDraftIds = ko.observableArray([]);
        /** @type {KnockoutObservable<Record<string, boolean>>} */
        const _smsConversationDraftIdsPendingDeletion = ko.observable({}).extend({notify: 'always'});
        /** @type {KnockoutObservable<Record<string, boolean>>} */
        const _smsConversationIdsPendingArchive = ko.observable({}).extend({notify: 'always'});
        /** @type {KnockoutObservable<number>} */
        const _newMessagesCount = ko.observable(0);

        /**
         *
         * @param {IDataSourceResponse} data
         */
        const _updateData = (data) => {
            const {webMessagingNumbers, smsConversationDraftIds, smsConversations, newMessagesCount} = data;
            const authorizedHostedNumberIds = webMessagingNumbers.map(number => number.accountHostedNumberId);
            const smsConversationIds = smsConversations.map(conversation => conversation.smsConversationId);
            _smsConversations(smsConversations);
            _smsConversationIds(smsConversationIds);
            _smsConversationDraftIds(smsConversationDraftIds);
            _webMessagingNumbers(webMessagingNumbers);
            _authorizedHostedNumberIds(authorizedHostedNumberIds);
            _newMessagesCount(newMessagesCount);
        };

        const _updateConversationData = (/** @type {IDataSourceResponse} */data) => {
            const {smsConversations, newMessagesCount} = data;
            const smsConversationIds = smsConversations.map(conversation => conversation.smsConversationId);
            _smsConversationIdsPendingArchive({});
            _smsConversationIds(smsConversationIds);
            _smsConversations(smsConversations);
            _newMessagesCount(newMessagesCount);
        };

        _waitForDataWaitHandle.setRedLight();

        const _init = () => {
            if (sessionAccountInfo.isImpersonating()) {
                return _promiseFactory.wait();
            }

            // refresh data on hosted number permission changes,
            _webMessagingDataSourceSocket.subscribeToEvent("accountHostedNumberSmsStatusChanged", _updateData);
            _webMessagingDataSourceSocket.subscribeToEvent("smsPermissionChanged", _updateData);
            _webMessagingDataSourceSocket.subscribeToEvent("accountHostedNumberCampaignAssociationChanged", _updateData);
            _webMessagingDataSourceSocket.subscribeToEvent("smsLimitsChanged", _updateData);

            // refresh data on conversation changes,
            _webMessagingDataSourceSocket.subscribeToEvent("smsConversationCreated", _updateConversationData);
            _webMessagingDataSourceSocket.subscribeToEvent("smsConversationUserAssociationChanged", _updateConversationData);
            _webMessagingDataSourceSocket.subscribeToEvent("smsConversationMessageNewCountChanged", _updateConversationData);

            // refresh data on conversation draft changes,
            _webMessagingDataSourceSocket.subscribeToEvent("smsConversationDraftCreated", (data) => {
                const {smsConversationDraftIds} = data;
                _smsConversationDraftIdsPendingDeletion({});
                _smsConversationDraftIds(smsConversationDraftIds);
            });

            _webMessagingDataSourceSocket.subscribeToEvent("smsConversationDraftDeleted", (data) => {
                const {smsConversationDraftIds} = data;
                _smsConversationDraftIdsPendingDeletion({});
                _smsConversationDraftIds(smsConversationDraftIds);
            });

            return _promiseFactory.defer((promise) => {
                _webMessagingDataSourceSocket.send("InitializeDataSource", {}, (/** @type {IDataSourceResponse} */data, errMsg) => {
                    if (errMsg) {
                        promise.reject(new Error(errMsg));
                        return;
                    }

                    _updateData(data);

                    _waitForDataWaitHandle.setGreenLight();
                    promise.resolve();
                });
            });
        };

        const _onLogin = () => {
            return _init();
        };

        const _onLogout = () => {
            _waitForDataWaitHandle.setRedLight();
            _webMessagingDataSourceSocket.disposeOfEvents();

            _webMessagingNumbers([]);
            _authorizedHostedNumberIds([]);
            _smsConversationIds([]);
            _smsConversationDraftIds([]);
            _smsConversations([]);
            _smsConversationDraftIdsPendingDeletion({});
            _smsConversationIdsPendingArchive({});
            _newMessagesCount(0);
        };

        /** @type {WebMessagingDataSource["webMessagingNumbers"]} */
        self.webMessagingNumbers = ko.pureComputed({
            read: _webMessagingNumbers
        });

        /** @type {WebMessagingDataSource["authorizedHostedNumberIds"]} */
        self.authorizedHostedNumberIds = ko.pureComputed({
            read: _authorizedHostedNumberIds
        });

        /** @type {WebMessagingDataSource["smsConversations"]} */
        self.smsConversations = ko.pureComputed({
            read: _smsConversations,
        });

        /** @type {WebMessagingDataSource["smsConversationIds"]} */
        self.smsConversationIds = ko.pureComputed(() => {
            return _smsConversationIds().filter((conversationId) => {
                return _smsConversationIdsPendingArchive()[conversationId] !== true;
            });
        });

        /** @type {WebMessagingDataSource["smsConversationDraftIds"]} */
        self.smsConversationDraftIds = ko.pureComputed(() => {
            return _smsConversationDraftIds().filter((draftId) => {
                return _smsConversationDraftIdsPendingDeletion()[draftId] !== true;
            });
        });

        /** @type {WebMessagingDataSource["totalNewMessages"]} */
        self.totalNewMessages = ko.pureComputed({
            read: _newMessagesCount
        });

        /** @type {WebMessagingDataSource["waitForData"]} */
        self.waitForData = () => {
            return _promiseFactory.defer((promise) => {
                _waitForDataWaitHandle.waitForSignal(() => promise.resolve());
            });
        };

        /** @type {WebMessagingDataSource["addConversationDraftIdPendingDeletion"]} */
        self.addConversationDraftIdPendingDeletion = (conversationDraftId) => {
            const draftsPendingDeletion = _smsConversationDraftIdsPendingDeletion();
            draftsPendingDeletion[conversationDraftId] = true;

            _smsConversationDraftIdsPendingDeletion(draftsPendingDeletion);
        };

        /** @type {WebMessagingDataSource["addConversationIdPendingArchive"]} */
        self.addConversationIdPendingArchive = (conversationId) => {
            const conversationsPendingArchive = _smsConversationIdsPendingArchive();
            conversationsPendingArchive[conversationId] = true;

            _smsConversationIdsPendingArchive(conversationsPendingArchive);
        };

        /** @type {WebMessagingDataSource["alerts"]} */
        self.alerts = {
            receiveAlertLoginSuccessful: _onLogin,
            receiveAlertLogout: _onLogout,
        };
    };

    if (!WebMessagingDataSourceSingleton._singletonInstance) {
        WebMessagingDataSourceSingleton._singletonInstance = new WebMessagingDataSourceSingleton();
    }

    return WebMessagingDataSourceSingleton._singletonInstance;
});

