define('presentation/settings/prompts/viewModels/promptsViewModel',[
    'businessServices/events/eventManager',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/deleteActionViewModel',
    'presentation/common/warningModal/viewModels/integrityWarningModalViewModel',
    'presentation/settings/prompts/facades/promptsFacade',
    'settings/navigationConfiguration'
], function(){
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteActionViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const SorterConstructor = require('common/collections/collectionSorter');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        const _commonState = require('common/storage/commonState');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _disposables = [];
        let _eventManager = null;
        let _facade = null;
        let _getNextBatchCalled = false;
        let _getNextBatchOfVoicePromptsEventId = null;

        const DELETE_RESPONSE = "yes";
        const ROWS_PER_PAGE = 20;

        let _state = {
            availableVoicePrompts: ko.observableArray(null),
            filteredVoicePrompts: null,
            voicePromptsShown: 0
        };

        const _showIntegrityWarningsPrompt = (voicePromptId, integrityWarningPresentationObjects) => {
            _buildIntegrityWarningsModal(voicePromptId);
            self.integrityWarningModal.addIntegrityWarnings(integrityWarningPresentationObjects);
            self.integrityWarningModal.showModal();
        };

        const _showDeletePrompt = (promptsPresentationObject) => {
            const voicePromptId = promptsPresentationObject.voicePromptId;
            _buildDeleteModal(voicePromptId);
            self.actionModal.showModal()
                .done((result) => {
                    if (result === DELETE_RESPONSE) {
                        _facade.deletePrompt(voicePromptId)
                            .done(() => {
                                self.displayVoicePrompts.remove(promptsPresentationObject);

                                _state.filteredVoicePrompts = _state.filteredVoicePrompts.filter((voicePrompt) => {
                                    return voicePrompt.voicePromptId !== voicePromptId;
                                });

                                _state.availableVoicePrompts(_state.availableVoicePrompts().filter((voicePrompt) => {
                                    return voicePrompt.voicePromptId !== voicePromptId;
                                }));

                                if (self.displayVoicePrompts().length < ROWS_PER_PAGE) {
                                    _getNextBatchOfVoicePrompts();
                                }
                            })
                            .fail((error) => {
                                if (error.constraintViolations && error.constraintViolations.length > 0) {
                                    _showIntegrityWarningsPrompt(voicePromptId, error.constraintViolations);
                                } else {
                                    error.voicePromptId = voicePromptId;
                                    throw error;
                                }
                            });
                    }
                })
                .fail((error) => {
                    throw error;
                });
        };

        const _updateDisplayedVoicePrompts = () => {
            let filteredVoicePrompts;
            let filterContent = self.voicePromptFilter();
            if (filterContent !== '') {
                let voicePromptsFilter = new FilterConstructor();
                voicePromptsFilter.addProperty('promptName');
                voicePromptsFilter.addProperty('modifiedDateTime.filterValue');
                voicePromptsFilter.addProperty('createdDateTime.filterValue');
                voicePromptsFilter.addProperty('voicePromptDuration');
                voicePromptsFilter.addValue(filterContent);
                filteredVoicePrompts = voicePromptsFilter.filter(_state.availableVoicePrompts());
            }
            else {
                filteredVoicePrompts = _state.availableVoicePrompts();
            }
            _state.filteredVoicePrompts = filteredVoicePrompts;
        };

        const _voicePromptFilterOnChange = () =>{
            _updateDisplayedVoicePrompts();
            self.lastPage = false;
            _state.voicePromptsShown = ROWS_PER_PAGE;
            
            self.displayVoicePrompts(_state.filteredVoicePrompts.slice(0, _state.voicePromptsShown));
        };

        const _refreshVoicePrompts = () => {
            _facade.findAllVoicePrompts()
                .done((prompts) => {
                    _state.availableVoicePrompts(prompts);
                    _state.filteredVoicePrompts = prompts;
                    _state.voicePromptsShown = ROWS_PER_PAGE;
                    _sortDisplayedPrompts();
                    if (_getNextBatchOfVoicePromptsEventId === null) {
                        _getNextBatchOfVoicePromptsEventId = _eventManager.subscribeBottomScrollBufferReached(_getNextBatchOfVoicePrompts);
                    }
                });
        };

        const _getNextBatchOfVoicePrompts = (eventSource) => {
            if (eventSource !== self.scrollEventSource) {
                return;
            }

            if (self.lastPage === false && _getNextBatchCalled === false){
                _getNextBatchCalled = true;
                let additionalVoicePromptsShown = _state.voicePromptsShown + ROWS_PER_PAGE;
                let additionalVoicePrompts = _state.filteredVoicePrompts.slice(_state.voicePromptsShown, additionalVoicePromptsShown);
                self.displayVoicePrompts.push.apply(self.displayVoicePrompts, additionalVoicePrompts);
                _state.voicePromptsShown = additionalVoicePromptsShown;
                if(_state.voicePromptsShown >= _state.filteredVoicePrompts.length) {
                    self.lastPage = true;
                }
                _getNextBatchCalled = false;
            }
        };

        const _sortDisplayedPrompts = () => {
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(_state.availableVoicePrompts);
                _updateDisplayedVoicePrompts();
                self.lastPage = false;
                _state.voicePromptsShown = ROWS_PER_PAGE;
                self.displayVoicePrompts(_state.filteredVoicePrompts.slice(0, _state.voicePromptsShown));
            }
        };

        const _buildDeleteModal = (voicePromptId) => {
            const constructorParams = [voicePromptId];
            const displayType = _commonState.typeDisplay.prompt;
            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: {key: 'deleteHeader', options: {type: displayType}}})
                .setSubmitButtonText({i18n: 'delete'});
        };

        const _buildIntegrityWarningsModal = (voicePromptId) => {
            const displayType = _commonState.typeDisplay.prompt;
            self.integrityWarningModal
                .clearModal()
                .setHeading({key: 'delete', options: {type: displayType}})
                .addText({i18n: {key: 'inUse', options: {type: displayType.toLowerCase()}}})
                .setSystemObject({objectId: voicePromptId})
                .addIntegrityWarningText({i18n: {key: 'conflicts'}});
        };

        self.scrollEventSource = ScrollEventSources.settingsAudioFiles;
        self.actionModal = null;
        self.integrityWarningModal = null;
        self.lastPage = false;
        self.isCompositionComplete = ko.observable(false);
        self.displayVoicePrompts = ko.observableArray();
        self.addPromptsUrl = _navigationConfiguration.routesById.newPrompt.url;

        self.sortType = ko.observable("promptName");
        _disposables.push(self.sortType.subscribe(_sortDisplayedPrompts));
        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "promptName", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.voicePromptFilter = ko.observable('');
        _disposables.push(self.voicePromptFilter.subscribe(_voicePromptFilterOnChange));

        self.conversionInProgress = false;
        self.showResults = ko.pureComputed(() => self.displayVoicePrompts().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => _state.availableVoicePrompts().length > 0 ?
            "audioFiles:noResults" :
            "audioFiles:noAudioFiles");

        self.launchDeleteVoicePrompt = (promptsPresentationObject) => {
            const voicePromptId = promptsPresentationObject.voicePromptId;
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.getConstraints(voicePromptId)
                    .done((integrityWarningPresentationObjects) => {
                        if (integrityWarningPresentationObjects.length > 0) {
                            _showIntegrityWarningsPrompt(voicePromptId, integrityWarningPresentationObjects);
                        } else {
                            _showDeletePrompt(promptsPresentationObject);
                        }
                        deferredObject.resolve();
                    });
            });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
            _eventManager.unsubscribe(_getNextBatchOfVoicePromptsEventId);
            _facade.dispose();
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/prompts/facades/promptsFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const IntegrityWarningModalViewModelConstructor = require('presentation/common/warningModal/viewModels/integrityWarningModalViewModel');
            self.integrityWarningModal = new IntegrityWarningModalViewModelConstructor();

            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            _refreshVoicePrompts();

            _facade.onNewPromptConverted((promptInfo) => {
                const {accountVoicePromptId, audioData} = promptInfo;
                const prompts = _state.availableVoicePrompts();
                const foundPrompt = prompts.find((prompt) => prompt.voicePromptId === accountVoicePromptId);

                if (foundPrompt === undefined) {
                    return;
                }

                foundPrompt.conversionComplete(true);
                foundPrompt.conversionInProgress(false);
                foundPrompt.currentVoicePrompt(audioData);
            });

            return _promiseFactory.wait();
        };
    };
});

