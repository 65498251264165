 define('presentation/common/routeToModal/viewModels/routeToScheduleViewModel',['common/promises/promiseFactory',
         'businessServices/events/eventManager',
         'constants/autoAttendantVoiceRouteEnumerations',
         'common/storage/commonState',
         'common/collections/collectionSorter',
         'businessServices/state/modelStateObserver',
         'settings/navigationConfiguration'
 ], function() {
    return function(initialRoutedToId, initialRouteToType, nameThisOption, routingRuleExitCondition, validateRouteToViewModelCallback) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
        const _i18n = require('i18next');

        const _promiseFactory = new PromiseFactoryConstructor();

        const NONE_SCHEDULE_ID = "[None]";
        const NONE_SCHEDULE_NAME = _i18n.t('routeToSchedule:none');
        const NONE_SCHEDULE = {
            id: NONE_SCHEDULE_ID,
            name: NONE_SCHEDULE_NAME,
            type: _commonState.types.schedule
        };

        let _disposables = [];

        const _onSelectedChange = () => {
            self.isValid(!(self.selectedSchedule() && self.selectedSchedule().id === NONE_SCHEDULE_ID));
            validateRouteToViewModelCallback({});
        };

        self.addScheduleUrl = _navigationConfiguration.routesById.newSchedule.url;
        self.createScheduleLinkText = _i18n.t('routeToSchedule:createSchedule');
        self.noSchedulesWarningMessage = _i18n.t('routeToSchedule:noSchedulesWarningMessage');
        self.modelStateObserver = new ModelStateObserverConstructor(self, true);
        self.routeToStrategy = null;
        self.isNewRouteTo = true;
        self.selectedSchedule = ko.observable().extend({observeState: true});
        _disposables.push(self.selectedSchedule.subscribe(_onSelectedChange));
        self.showNoSchedulesMessage = ko.observable(false);
        self.isValid = ko.observable(false);
        self.placeholderMessage = NONE_SCHEDULE_NAME;

        self.schedules = ko.computed(() => {
            const schedules = _commonState.schedules();
            const sorter = new SorterConstructor();
            sorter.sort(schedules, "name", true);

            if (schedules.length === 0) {
                self.selectedSchedule(NONE_SCHEDULE);
                self.showNoSchedulesMessage(true);
            }
            else if (self.selectedSchedule() === NONE_SCHEDULE) {
                self.selectedSchedule(schedules[0]);
                self.showNoSchedulesMessage(false);
            }

            return schedules;
        });
        self.schedulesIsEnabled = ko.pureComputed(() => self.schedules().length > 0);

        self.cancelForm = () => {
            return _promiseFactory.defer(function (deferredObject) {
                _eventManager.publishRouteToCloseModal();
                deferredObject.resolve();
            });
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            if(initialRoutedToId !== null  && initialRouteToType === _voiceRouteEnumerations.schedule) {
                const schedule = self.schedules().find(schedule => schedule.id === initialRoutedToId);
                self.selectedSchedule(schedule);
            }
            else if (self.schedules().length === 0) {
                self.selectedSchedule(NONE_SCHEDULE);
                self.showNoSchedulesMessage(true);
            }
            else {
                self.selectedSchedule(self.schedules()[0]);
                self.showNoSchedulesMessage(false);
            }

            return _promiseFactory.wait();
        };
    };
});
