define('presentation/settings/users/facades/reactivateUserModalFacade',[
        'persistence/webSocket/webSocketApp'
], function () {
    let _promiseFactory = null;
    let _webSocketApp = null;

    const _resendInvite = (userId) => {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("resend_invite", {userId: userId}, () => {
                promise.resolve();
            });
        });
    };

    const _sendReactivation = (userId) => {
        return _promiseFactory.defer((promise) => {
            _webSocketApp.send("send_reactivation", {userId: userId}, () => {
                promise.resolve();
            });
        });
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_reactivate_modal");
    };

    return function () {
        const self = this;

        self.init = _init;
        self.resendInvite = _resendInvite;
        self.sendReactivation = _sendReactivation;
    };
});
