define('presentation/contacts/viewModels/contactHeaderViewModel',[
    'common/promises/promiseFactory',
    'i18next',
    'presentation/contacts/facades/contactCardFacade',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/confirmActionViewModel'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('presentation/contacts/facades/contactCardFacade') */
    ContactCardFacade,
    /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
    ActionModalViewModel,
    /** @type typeof import('presentation/common/actionModal/viewModels/confirmActionViewModel') */
    ConfirmAction
) {
    return function() {
        const self = this;
        const _actionModal = new ActionModalViewModel();

        const _promiseFactory = new PromiseFactory();

        /** @type import('presentation/contacts/facades/contactCardFacade') */
        let _contactCardFacade = new ContactCardFacade();

        const _navigationConfiguration = require('settings/navigationConfiguration');

        /** @type { KnockoutObservable<IContactPresentationObject> } */
        self.contact = ko.observable(null);
        self.router = null;

        self.showCall = ko.observable(false);
        self.showMessage = ko.observable(false);
        self.showEmail = ko.observable(false);

        self.comingSoon = i18n.t('comingSoon');

        self.isCallButtonDisabled = ko.observable(true);
        self.callDisplayOptions = ko.pureComputed(() => {
            return (self.contact().phoneNumbers()).map((/** @type { IContactPhoneNumberPresentationObject } */ item) => (
                {
                    id: item.hostedNumberId ? item.hostedNumberId : item.accountContactPhoneNumberId,
                    label: item.label,
                    number: item.phoneNumber
                }
            ));
        });
        self.callSelectedOptionId = ko.observable("");

        self.isMessageButtonDisabled = ko.pureComputed(() => {
            return self.contact().phoneNumbers().length === 0 ||
                   (self.contact().phoneNumbers().length === 1 && !self.contact().phoneNumbers()[0].isValid);
        });
        self.messageDisplayOptions = ko.pureComputed(() => {
            const filteredNumbers = self.contact().phoneNumbers().filter((/** @type {IContactPhoneNumberPresentationObject} */ item) => item.isValid);
            return filteredNumbers.map((/** @type { IContactPhoneNumberPresentationObject } */ item) => (
                {
                    id: item.hostedNumberId ? item.hostedNumberId : item.accountContactPhoneNumberId,
                    label: item.label,
                    number: item.phoneNumber,
                    isValid: item.isValid
                }
            ));
        });
        self.messageSelectedOptionId = ko.observable("");

        self.isEmailButtonDisabled = ko.pureComputed(() => {
            return self.contact().emailAddresses().length === 0;
        });
        self.emailDisplayOptions = ko.pureComputed(() => {
            return (self.contact().emailAddresses()).map((/** @type { IContactEmailAddressPresentationObject } */ item) => (
                {
                    id: item.accountContactEmailAddressId,
                    label: item.label,
                    email: item.emailAddress
                }
            ));
        });
        self.emailSelectedOptionId = ko.observable("");

        self.onCallButtonClicked = () => {
            if (!self.isCallButtonDisabled()){
                self.showCall(!self.showCall());
            }
        };

        self.onMessageButtonClicked = () => {
            if (self.isMessageButtonDisabled()) {
                return;
            }

            if (self.contact().phoneNumbers().length === 1) {
                self.sendMessage(self.contact().phoneNumbers()[0].phoneNumber());
                return;
            }

            self.showMessage(!self.showMessage());
        };

        self.onEmailButtonClicked = () => {
            if (self.isEmailButtonDisabled()) {
                return;
            }

            if (self.contact().emailAddresses().length === 1) {
                self.sendEmail(self.contact().emailAddresses()[0].emailAddress());
                return;
            }

            self.showEmail(!self.showEmail());
        };

        // Prevent blur event from interfering with click event
        self.onOptionMouseDown = (/** @type { any } */ _data, /** @type { JQuery.Event } */ event) =>{
            event.preventDefault();
        };

        self.sendMessage = (/** @type { string } */ number) => {
            _contactCardFacade.createDraftConversation(number)
                .fail(() => {
                })
                .done((/** @type string */conversationDraftId) => {
                    const newMessageUrl = `${_navigationConfiguration.routesById.newMessage.baseUrl}/${conversationDraftId}`;
                    self.router.navigate(newMessageUrl);
                });
        };

        self.sendEmail = (/** @type { string } */ emailAddress) => {
            window.open(`mailto:${emailAddress}`);
        };

        self.onMessageOptionSelected = (/** @type { string } */ optionId) => {
            const option = self.messageDisplayOptions().find((o) => o.id === optionId);
            if (option) {
                return _promiseFactory.defer(deferredObject => {
                    if (!ko.unwrap(option.isValid)) {
                        _actionModal
                            .clearModal()
                            .setContentViewModel(ConfirmAction, [i18n.t('contacts:unsupportedModalText')])
                            .setHeaderText({i18n: 'contacts:unsupportedModalTitle'})
                            .setShouldDisplayCancelButton(false)
                            .setShouldDisplaySubmitButton(true)
                            .setSubmitButtonText({i18n: 'ok'})
                            .setShouldScrollIntoView(false)
                            .showModal()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    } else {
                        self.sendMessage(option.number());
                    }
                });
            }
        };

        self.onEmailOptionSelected = (/** @type { string } */ optionId)  => {
            const option = self.emailDisplayOptions().find((o) => o.id === optionId);
            if (option) {
                self.sendEmail(option.email());
            }
        };

        self.activate = (/** @type { IContactHeaderViewModelActivationObject } */ activationObject) => {
            const {
                contactPresentationObject,
            } = activationObject;

            if (contactPresentationObject) {
                self.contact = contactPresentationObject;
            }

            self.router = activationObject.router;

            _contactCardFacade = new ContactCardFacade();
            _contactCardFacade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
