define('presentation/settings/humanDetection/validators/humanDetectionValidator',[
        'common/converters/phoneNumberFormatter',
        'constants/countryEnumerations',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'presentation/common/validation/validPhoneNumberSpecification',
    ],
    function(
        /** @type typeof import('common/converters/phoneNumberFormatter') */
        PhoneNumberFormatterConstructor,
        /** @type typeof import('constants/countryEnumerations') */
        _countryEnumerations,
        /** @type typeof import('presentation/common/validation/commonValidator') */
        CommonValidatorConstructor,
        /** @type typeof import('presentation/common/validation/validationRule') */
        ValidationRulesConstructor,
        /** @type typeof import('presentation/common/validation/validPhoneNumberSpecification') */
        ValidPhoneNumberSpecificationConstructor
    ) {
        return function() {
            /** @typedef {import('presentation/settings/humanDetection/validators/humanDetectionValidator')} HumanDetectionValidator } */
            /** @typedef {import('presentation/settings/humanDetection/viewModels/addHumanDetectionPhoneNumberViewModel')} AddHumanDetectionPhoneNumberViewModel } */

            /** @type {HumanDetectionValidator} */
            const self = this;

            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();
            const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

            /** @type {AddHumanDetectionPhoneNumberViewModel}*/
            let _viewModel = null;

            let _commonValidator = null;

            self.registerViewModel = (/** @type {AddHumanDetectionPhoneNumberViewModel}*/viewModel) => {
                _viewModel = viewModel;
                _commonValidator = new CommonValidatorConstructor(_viewModel);

                const validationRules = new ValidationRulesConstructor();
                validationRules.field("newHumanDetectionPhoneNumber")
                    .addValidationKeyUp(_validatePhoneNumberLength)
                    .addValidationI18n(_commonValidator.isStringWithValue, 'humanDetection:phoneNumberRequired')
                    .addValidationI18n(_validatePhoneNumberLength, 'phoneNumberInvalid');

                _commonValidator.useManualValidation();
                _commonValidator.setValidationRules(validationRules);
            };

            self.validate = () => _commonValidator.validate();

            const _validatePhoneNumberLength = (/** @type {string}*/phoneNumber) => {
                if (phoneNumber) {
                    let cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                    return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
                }
                return true;
            };

        };
    });

