define('businessServices/converters/birthdayFormatter',[], function() {
    return function() {
        /** @typedef { import('businessServices/converters/birthdayFormatter') } BirthdayFormatter */

        /** @type { BirthdayFormatter } */
        const self = this;

        /** @type { BirthdayFormatter['formatBirthdayString'] } */
        const _formatBirthdayString = (value) => {
            if (!value) {
                return value;
            }

            const birthday = value.replace(/\D/g, '');
            if (birthday.length < 3) {
                return birthday;
            }
            if (birthday.length < 5) {
                return `${birthday.slice(0, 2)}/${birthday.slice(2)}`;
            }

            return `${birthday.slice(0, 2)}/${birthday.slice(2, 4)}/${birthday.slice(4, 8)}`;
        };

        self.formatBirthdayString = _formatBirthdayString;
    };
});

