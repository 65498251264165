define('presentation/settings/autoAttendant/validators/autoAttendantSubMenuValidator',[
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'constants/validationMessageEnumerations',
    'constants/inlinePromptDefaultOptionEnumerations'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        const _inlinePromptEnumerations = require('constants/inlinePromptDefaultOptionEnumerations');

        let _commonValidator = null;
        let _viewModel = null;

        self.registerViewModel = function(viewModel) {
            const validationMessageBase = _validationMessageEnumerations.clientui.presentation.settings.autoattendants;

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("selectedPrompt")
                .addValidation(_isSomePromptSelected, validationMessageBase.voicePromptMissingOnMenu);

            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        const _isSomePromptSelected = (selectedPrompt) => selectedPrompt.id !== _inlinePromptEnumerations.SELECT_PROMPT.id;
    };
});
