define('presentation/settings/autoAttendant/presentationObjects/autoAttendantVoiceRouteToOptionPresentationObject',[],function() {
    return function(){
        var self = this;

        self.type = null;
        self.displayName = null;
        self.iconSet1 = null;
        self.iconSet2 = null;
    };
});

