define('presentation/settings/humanDetection/facades/humanDetectionFacade',[
    'externalDependencies/clientWebSocket',
], function() {
    /** @typedef {import('presentation/settings/humanDetection/facades/humanDetectionFacade')} HumanDetectionFacade } */
    /** @typedef {import('common/promises/promiseFactory')} PromiseFactory   */

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const humanDetectionSocket = clientWebSocket.forApplication("humanDetection");

    /** @type {PromiseFactory} */
    let _promiseFactory = null;

    const _getAllowListedPhoneNumbers = () => {
        return _promiseFactory.defer((promise) => {
            humanDetectionSocket.send("getAllowListedPhoneNumbers", {}, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                const { allowListedPhoneNumbers } = response.result;
                promise.resolve(allowListedPhoneNumbers);
            });
        });
    };

    const _createAllowListedPhoneNumber = (/** @type {string}*/newPhoneNumber) => {
        return _promiseFactory.defer((promise) => {
            humanDetectionSocket.send("createAllowListedPhoneNumber", {phoneNumber: newPhoneNumber}, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                return promise.resolve(response);
            });
        });
    };

    const _deleteAllowListedPhoneNumber = (/** @type {string}*/allowListedPhoneNumberId) => {
        return _promiseFactory.defer((promise) => {
            humanDetectionSocket.send("deleteAllowListedPhoneNumber", {humanDetectionAllowListedPhoneNumberId: allowListedPhoneNumberId}, (response, err) => {
                if (err) {
                    promise.reject(new Error(err));
                    return;
                }
                return promise.resolve(response);
            });
        });
    };

    const _init = (/** @type {PromiseFactory} */ promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    return function() {
        /** @typeof {HumanDetectionFacade} */
        let self = this;

        self.init = _init;
        self.getAllowListedPhoneNumbers = _getAllowListedPhoneNumbers;
        self.createAllowListedPhoneNumber = _createAllowListedPhoneNumber;
        self.deleteAllowListedPhoneNumber = _deleteAllowListedPhoneNumber;
    };
});

