define('presentation/contacts/viewModels/contactNoteViewModel',[
    'common/promises/promiseFactory'
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory
) {
    return function() {
        const self = this;

        const _promiseFactory = new PromiseFactory();

        self.notes = null;

        self.activate = (/** @type { IContactNoteViewModelActivationObject } */ activationObject) => {
            self.notes = activationObject.notes;

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});
