define('presentation/settings/userProfile/viewModels/resendInviteModalViewModel',['businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'presentation/common/modal',
        'presentation/settings/userProfile/facades/resendInviteModalFacade'
], function() {
        return function(userId) {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

            const _commonState = require('common/storage/commonState');
            const _i18n = require('i18next');

            let _facade = null;
            let _modalService = null;

            const _buildUserObjectForDisplay = () => {
                let commonStateObject = _commonState.get(userId);
                let hasAvatar = commonStateObject.avatar().showImage();

                self.user({
                    avatar: commonStateObject.avatar,
                    hasAvatar: hasAvatar,
                    name: commonStateObject.name,
                    emailAddress: commonStateObject.emailAddress
                });
            };

            self.modelStateObserver = null;
            self.isCompositionComplete = ko.observable(false);
            self.submitButtonText = _i18n.t(`resendInviteModal:send`);
            self.user = ko.observable({});

            self.submitForm = () => {
                return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                        _facade.resendInvite(userId)
                            .fail(deferredObject.reject)
                            .done(() => {
                                self.closeModal();
                                deferredObject.resolve();
                            });
                    });
            };

            self.closeModal = () => {
                self.isCompositionComplete(false);
                self.modelStateObserver.navigateWithoutWarning = true;
                _modalService.closeModal(self);
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = () => {
                const FacadeConstructor = require('presentation/settings/userProfile/facades/resendInviteModalFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                const Modal = require('presentation/common/modal');
                _modalService = new Modal();

                return _initialize();
            };

            const _initialize = () => {
                self.modelStateObserver = new ModelStateObserverConstructor(self, true);
                self.modelStateObserver.commitData();

                _buildUserObjectForDisplay();
                self.modelStateObserver.isDirty(true);
            };
        };
    });

