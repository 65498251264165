define('constants/creditCardResponseConstants',[], function() {
    return {
        success: "success",
        invalidCard: "invalid_card",
        createAccountFailed: "create_account_failed",
        duplicateCard: "duplicate_card_or_customer",
        invalidConnection: "invalid_connection",
        approved: "approved",
        declined: "declined",
        creditCardDeclined: "credit_card_declined",
        paymentGatewayDown: "payment_gateway_down",
        failedToReservePhoneNumbers: "failed_to_reserve_phone_numbers"
    };
});


