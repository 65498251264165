define('businessServices/country/timeZones',['constants/timeZoneEnumerations'], function() {
    return function() {
        var self = this;

        var timeZoneEnumerations = require('constants/timeZoneEnumerations');

        var _getTimeZones = function() {
            return [
                {
                    key : timeZoneEnumerations.newfoundland,
                    name: "Newfoundland",
                    offset : -3.5,
                    sortOrder : 0
                },
                {
                    key : timeZoneEnumerations.atlantic,
                    name: "Atlantic",
                    offset : -4,
                    sortOrder : 1
                },
                {
                    key : timeZoneEnumerations.eastern,
                    name: "Eastern",
                    offset : -5,
                    sortOrder : 2
                },
                {
                    key : timeZoneEnumerations.central,
                    name: "Central",
                    offset : -6,
                    sortOrder : 3
                },
                {
                    key : timeZoneEnumerations.mountain,
                    name: "Mountain",
                    offset : -7,
                    sortOrder : 4
                },
                {
                    key : timeZoneEnumerations.arizona,
                    name: "Arizona",
                    offset : -7,
                    sortOrder : 5
                },
                {
                    key : timeZoneEnumerations.pacific,
                    name: "Pacific",
                    offset : -8,
                    sortOrder : 6
                },
                {
                    key : timeZoneEnumerations.alaska,
                    name: "Alaska",
                    offset : -9,
                    sortOrder : 7
                },
                {
                    key : timeZoneEnumerations.hawaii_aleutian,
                    name: "Hawaii-Aleutian",
                    offset : -10,
                    sortOrder : 8
                }
            ];
        };

        self.getSortedTimeZones = function() {
            var timeZones = _getTimeZones();
            timeZones.sort(function(zone1, zone2) {
                if (zone1.sortOrder < zone2.sortOrder) {
                    return -1;
                } else if (zone1.sortOrder > zone2.sortOrder) {
                    return 1;
                } else {
                    return 0;
                }
            });
            return timeZones;
        };
    };
});

