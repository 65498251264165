define('businessServices/tutorial/tutorialDateMath',['common/time/date'],
    function() {
        return function() {
            var self = this;
            var DateConstructor = require('common/time/date');

            self.getFormattedPriorMonthDate = function(monthsInPast, day) {
                var jsDate = new Date();
                jsDate.setMonth(jsDate.getMonth() - monthsInPast);
                jsDate.setDate(day);
                var date = new DateConstructor();
                date.setFromDate(jsDate);
                var formattedDate = date.monthPadded() + "-" + date.dayPadded() + "-" + date.yearShort();
                return formattedDate;
            };
        };
});

