define('businessServices/events/eventManager',[
    'common/uniqueId/uniqueIdGenerator'
], function(
    /** @type typeof import('common/uniqueId/uniqueIdGenerator') */
    UniqueNameGenerator
) {
    /** @typedef {import('businessServices/events/eventManager')} EventManager */

    /**
     * @typedef {{
     *  callback: Function
     *  id: string
     * }} CallBack
     * @typedef {{
     *  topicName: string
     *  subscribedCallbacks: CallBack[]
     *  eventId: string
     * }} Event
     */

    const _uniqueNameGenerator = new UniqueNameGenerator();

    const EventManagerSingleton = function() {
        if (EventManagerSingleton.prototype._singletonInstance) {
            return EventManagerSingleton.prototype._singletonInstance;
        }
        /** @type {EventManager} */
        const self = this;
        EventManagerSingleton.prototype._singletonInstance = self;

        /** @type {Event[]} */
        const _events = [];

        /**
         * @param {string} topicName
         * @returns {Event | null}
         */
        const _findEvent = (topicName) => {
            for (let x = 0; x < _events.length; x++) {
                const currentEvent = _events[x];
                if (currentEvent.topicName === topicName) {
                    return currentEvent;
                }
            }
            return null;
        };

        /**
         * @param {string} topicName
         * @param {string} eventId
         * @returns {Event}
         */
        const _getOrAddEvent = (topicName, eventId) => {
            const currentEvent = _findEvent(topicName);
            if (currentEvent === null) {
                /** @type {Event} */
                const newEvent = {
                    topicName : topicName,
                    subscribedCallbacks : [],
                    eventId: eventId
                };
                _events.push(newEvent);
                return newEvent;
            } else {
                return currentEvent;
            }
        };

        /**
         * @param {string} topicName
         * @param {*} callbackFunction
         * @returns {string}
         */
        const _subscribe = (topicName, callbackFunction) => {
            const uniqueId = _uniqueNameGenerator.generateUniqueIdWithoutHyphens();
            const currentEvent = _getOrAddEvent(topicName, uniqueId);

            currentEvent.subscribedCallbacks.push({"callback": callbackFunction, "id": uniqueId});
            return uniqueId;
        };

        /**
         * @template T
         * @param {string} topicName
         * @param {T} eventData
         */
        const _publish = (topicName, eventData) => {
            const currentEvent = _findEvent(topicName);
            if (currentEvent === null) {
                return;
            }
            for (let x = 0; x < currentEvent.subscribedCallbacks.length; x++) {
                const currentCallback = currentEvent.subscribedCallbacks[x].callback;
                currentCallback(eventData);
            }
        };

        const EVENT_NAME_AUTHENTICATION_EXPIRED = "authentication_token_expired";
        const EVENT_NAME_CONTENT_PANE_SCROLLED = "content_pane_scrolled";
        const EVENT_NAME_CONTENT_PANE_SCROLL_EVENT_ID = "content_pane_scroll_even_id";
        const EVENT_NAME_DATAGRID_GET_NEXT_PAGE = "datagrid_get_next_page";
        const EVENT_NAME_INITIALIZE_ADD_PAYMENT_MODAL_COMPLETE = "modal_initialize_complete";
        const EVENT_NAME_BOTTOM_SCROLL_BUFFER_REACHED = "bottom_scroll_buffer_reached";
        const EVENT_NAME_TOP_SCROLL_BUFFER_REACHED = "top_scroll_buffer_reached";
        const EVENT_NAME_PAST_DUE_BALANCE = "past_due_balance";
        const EVENT_NAME_ROUTE_TO_CLOSE_MODAL = "route_to_close_modal";
        const EVENT_NAME_ROUTE_TO_MODAL_CHANGED = "route_to_modal_changed";
        const EVENT_NAME_SHELL_UPDATED = "shell_updated";
        const EVENT_NAME_USER_INFO_CHANGED = "user_info_changed";

        const SMS_ATTACHMENT_CLICKED = "sms_attachment_clicked";
        const SMS_ATTACHMENT_FROM_DETAILS_CLICKED = "sms_attachment_from_details_clicked";

        /** @type {EventManager['unsubscribe']} */
        self.unsubscribe = (callbackFunctionId) => {
            for(let x = 0; x < _events.length; x++) {
                for(let i = 0; i < _events[x].subscribedCallbacks.length; i++) {
                    if(_events[x].subscribedCallbacks[i].id === callbackFunctionId) {
                        _events[x].subscribedCallbacks.splice(i, 1);
                        break;
                    }
                }
            }
        };

        /** @type {EventManager['subscribePastDueBalance']} */
        self.subscribePastDueBalance = (callbackFunction) => {
            return _subscribe(EVENT_NAME_PAST_DUE_BALANCE, callbackFunction);
        };

        /** @type {EventManager['publishPastDueBalance']} */
        self.publishPastDueBalance = (pastDueBalance) => {
            _publish(EVENT_NAME_PAST_DUE_BALANCE, pastDueBalance);
        };

        /** @type {EventManager['subscribeAuthenticationExpired']} */
        self.subscribeAuthenticationExpired = (callbackFunction) => {
            return _subscribe(EVENT_NAME_AUTHENTICATION_EXPIRED, callbackFunction);
        };

        /** @type {EventManager['publishAuthenticationExpired']} */
        self.publishAuthenticationExpired = () => {
            _publish(EVENT_NAME_AUTHENTICATION_EXPIRED, null);
        };

        /** @type {EventManager['subscribeRouteToCloseModal']} */
        self.subscribeRouteToCloseModal = (callbackFunction) => {
            return _subscribe(EVENT_NAME_ROUTE_TO_CLOSE_MODAL, callbackFunction);
        };

        /** @type {EventManager['publishRouteToCloseModal']} */
        self.publishRouteToCloseModal = () => {
            _publish(EVENT_NAME_ROUTE_TO_CLOSE_MODAL, null);
        };

        /** @type {EventManager['subscribeRouteToModalChanged']} */
        self.subscribeRouteToModalChanged = (callbackFunction) => {
            return _subscribe(EVENT_NAME_ROUTE_TO_MODAL_CHANGED, callbackFunction);
        };

        /** @type {EventManager['publishRouteToModalChanged']} */
        self.publishRouteToModalChanged = () => {
            _publish(EVENT_NAME_ROUTE_TO_MODAL_CHANGED, null);
        };

        /** @type {EventManager['publishContentPaneScroll']} */
        self.publishContentPaneScroll = (scrollInfo) => {
            _publish(EVENT_NAME_CONTENT_PANE_SCROLLED, scrollInfo);
        };

        /** @type {EventManager['subscribeContentPaneScroll']} */
        self.subscribeContentPaneScroll = (callbackFunction) => {
            return _subscribe(EVENT_NAME_CONTENT_PANE_SCROLLED, callbackFunction);
        };

        /** @type {EventManager['publishDataGridGetNextPage']} */
        self.publishDataGridGetNextPage = () => {
            return _publish(EVENT_NAME_DATAGRID_GET_NEXT_PAGE, null);
        };

        /** @type {EventManager['subscribeDataGridGetNextPage']} */
        self.subscribeDataGridGetNextPage = (callbackFunction) => {
            return _subscribe(EVENT_NAME_DATAGRID_GET_NEXT_PAGE, callbackFunction);
        };

        /** @type {EventManager['publishBottomScrollBufferReached']} */
        self.publishBottomScrollBufferReached = (scrollEventSource) => {
            return _publish(EVENT_NAME_BOTTOM_SCROLL_BUFFER_REACHED, scrollEventSource);
        };

        /** @type {EventManager['subscribeBottomScrollBufferReached']} */
        self.subscribeBottomScrollBufferReached = (callback) => {
            return _subscribe(EVENT_NAME_BOTTOM_SCROLL_BUFFER_REACHED, callback);
        };

        /** @type {EventManager['publishTopScrollBufferReached']} */
        self.publishTopScrollBufferReached = (scrollEventSource) => {
            return _publish(EVENT_NAME_TOP_SCROLL_BUFFER_REACHED, scrollEventSource);
        };

        /** @type {EventManager['subscribeTopScrollBufferReached']} */
        self.subscribeTopScrollBufferReached = (callback) => {
            return _subscribe(EVENT_NAME_TOP_SCROLL_BUFFER_REACHED, callback);
        };

        /** @type {EventManager['publishContentPaneScrollEventId']} */
        self.publishContentPaneScrollEventId = (scrollEventId) => {
            _publish(EVENT_NAME_CONTENT_PANE_SCROLL_EVENT_ID, scrollEventId);
        };

        /** @type {EventManager['subscribeContentPaneScrollEventId']} */
        self.subscribeContentPaneScrollEventId = (callbackFunction) => {
            return _subscribe(EVENT_NAME_CONTENT_PANE_SCROLL_EVENT_ID, callbackFunction);
        };

        /** @type {EventManager['publishUserInfoChanged']} */
        self.publishUserInfoChanged = (userId) => {
            _publish(EVENT_NAME_USER_INFO_CHANGED, userId);
        };

        /** @type {EventManager['subscribeUserInfoChanged']} */
        self.subscribeUserInfoChanged = (callbackFunction) => {
            return _subscribe(EVENT_NAME_USER_INFO_CHANGED, callbackFunction);
        };

        /** @type {EventManager['publishInitializeAddPaymentModalComplete']} */
        self.publishInitializeAddPaymentModalComplete = () => {
            _publish(EVENT_NAME_INITIALIZE_ADD_PAYMENT_MODAL_COMPLETE, null);
        };

        /** @type {EventManager['subscribeInitializeAddPaymentModalComplete']} */
        self.subscribeInitializeAddPaymentModalComplete = (callbackFunction) => {
            return _subscribe(EVENT_NAME_INITIALIZE_ADD_PAYMENT_MODAL_COMPLETE, callbackFunction);
        };

        /** @type {EventManager['publishShellUpdated']} */
        self.publishShellUpdated = (shellName) => {
            _publish(EVENT_NAME_SHELL_UPDATED, shellName);
        };

        /** @type {EventManager['subscribeShellUpdated']} */
        self.subscribeShellUpdated = (callbackFunction) => {
            return _subscribe(EVENT_NAME_SHELL_UPDATED, callbackFunction);
        };

        /** @type {EventManager['publishSmsAttachmentClicked']} */
        self.publishSmsAttachmentClicked = (attachmentId) => {
            _publish(SMS_ATTACHMENT_CLICKED, {attachmentId});
        };

        /** @type {EventManager['subscribeSmsAttachmentClicked']} */
        self.subscribeSmsAttachmentClicked = (callback) => {
            return _subscribe(SMS_ATTACHMENT_CLICKED, callback);
        };

        /** @type {EventManager['publishSmsAttachmentFromDetailsClicked']} */
        self.publishSmsAttachmentFromDetailsClicked = (attachmentId) => {
            _publish(SMS_ATTACHMENT_FROM_DETAILS_CLICKED, {attachmentId});
        };

        /** @type {EventManager['subscribeSmsAttachmentFromDetailsClicked']} */
        self.subscribeSmsAttachmentFromDetailsClicked = (callback) => {
            return _subscribe(SMS_ATTACHMENT_FROM_DETAILS_CLICKED, callback);
        };
    };

    return new EventManagerSingleton();
});

