define('presentation/common/validation/validationRule',[],
    function() {
        return function() {
            var self = this;
            var _currentArray = null;
            var _currentField = null;

            self.rules = [];

            self.array = function(field) {
                _currentArray = {};
                _currentField = null;
                _currentArray.array = field;
                _currentArray.fields = [];
                self.rules.push(_currentArray);

                return self;
            };

            self.arrayItem = function(fieldsToListenForChangesOn) {
                _currentArray.validators = [];
                _currentArray.triggerFields = fieldsToListenForChangesOn;
                _currentField = _currentArray;

                return self;
            };

            self.arrayField = function(field) {
                _currentField = {};
                _currentField.field = field;
                _currentField.validators = [];
                _currentArray.fields.push(_currentField);

                return self;
            };

            self.field = function(field) {
                _currentArray = null;
                _currentField = {};
                _currentField.field = field;
                _currentField.validators = [];
                self.rules.push(_currentField);

                return self;
            };

            self.fieldGroup = function(fieldGroup, fieldsToListenForChangeOn) {
                _currentArray = null;
                _currentField = {};
                _currentField.field = fieldGroup;
                _currentField.triggerFields = fieldsToListenForChangeOn;
                _currentField.validators = [];
                self.rules.push(_currentField);

                return self;
            };
            
            self.validationGroup = function(group, fields) {
                _currentArray = null;
                _currentField = {
                    validationGroup: group,
                    validationGroupFields: fields
                };
                self.rules.push(_currentField);
            };

            self.validationGroupKeyUp = function(group, fields) {
                _currentArray = null;
                _currentField = {
                    validationGroupKeyUp: group,
                    validationGroupFields: fields
                };
                self.rules.push(_currentField);
            };

            self.addValidation = function(validatorRule, validationMessage) {
                if (_currentField === null) {
                    throw new Error("A 'field' must first be specified");
                } else {
                    var validator = {
                                        rule: validatorRule,
                                        message: validationMessage
                                    };
                    _currentField.validators.push(validator);
                }

                return self;
            };

            self.addValidationI18n = function(validatorRule, i18nKey) {
                if (_currentField === null) {
                    throw new Error("A 'field' must first be specified");
                }
                const validator = {
                    rule: validatorRule,
                    i18nKey: i18nKey,
                };
                _currentField.validators.push(validator);

                return self;
            };

            self.addValidationKeyUp = function(validatorRule) {
                if (_currentField === null) {
                    throw new Error("A 'field' must first be specified");
                }
                const validator = {
                    rule: validatorRule,
                    isKeyUp: true,
                };
                _currentField.validators.push(validator);

                return self;
            };
        };
});

