define('persistence/repositories/userSettingsRepository',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var KazooConstructor = require('externalDependencies/kazooApi');
        var _kazooApi = new KazooConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.add = function(userSettingsEntity) {
            if (userSettingsEntity.userSettingId !== null) {
                var err = new Error("userSettingId should be null");
                err.lobbyUserSettings = userSettingsEntity;
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var userSettingsDocument = {
                        data : userSettingsEntity
                    };
                    _kazooApi.callAccount('/lobby_user_settings', 'PUT', userSettingsDocument)
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.userSettingsEntity = userSettingsEntity;
                            deferredObject.reject(error);
                        });
                });
            }

        };

        self.update = function(userSettingsEntity) {
            if (userSettingsEntity.userSettingId === null) {
                var err = new Error("Missing userSettingId");
                err.lobbyUserSettings = userSettingsEntity;
                throw err;
            }
            else {
                return _promiseFactory.defer(function(deferredObject) {
                    var userSettingsDocument = {
                            data: userSettingsEntity
                        };
                    _kazooApi.callAccount('/lobby_user_settings/'+userSettingsEntity.userSettingId, 'POST', userSettingsDocument)
                        .done(function(result) {
                            deferredObject.resolve(result.data.data);
                        })
                        .fail(function(error) {
                            error.userSettingsEntity = userSettingsEntity;
                            deferredObject.reject(error);
                        });
                });
            }
        };
    };
});

