define('presentation/common/callFlow/common/callFlowBuilder',[
    'businessServices/converters/durationTimeFormatter',
    'businessServices/router/router',
    'common/storage/commonState',
    'constants/systemObjectIconNameConstants',
    'presentation/common/audioPresentationObject',
    'presentation/common/callFlow/presentationObjects/subMenuPresentationObject',
    'presentation/common/callFlow/presentationObjects/subMenuItemPresentationObject',
    'presentation/common/callFlow/presentationObjects/callFlowPresentationObject',
    'presentation/common/callFlow/presentationObjects/scheduleSegmentPresentationObject',
    'presentation/settings/autoAttendant/facades/autoAttendantFacade',
],  function() {
    return function() {
        let self = this;

        const AudioPresentationObjectConstructor = require('presentation/common/audioPresentationObject');
        const SubMenuObjectConstructor = require('presentation/common/callFlow/presentationObjects/subMenuPresentationObject');
        const SubMenuItemObjectConstructor = require('presentation/common/callFlow/presentationObjects/subMenuItemPresentationObject');
        const CallFlowPresentationObjectConstructor = require('presentation/common/callFlow/presentationObjects/callFlowPresentationObject');
        const ScheduleSegmentPresentationObjectConstructor = require('presentation/common/callFlow/presentationObjects/scheduleSegmentPresentationObject');

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();
        
        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _router = require('businessServices/router/router');
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        let _promiseFactory = null;
        let _facade = null;

        let _activeRouteId = _router.activeInstruction().config.customSettings.routeId;
        let _activeParentRouteId = _router.activeInstruction().config.customSettings.parentItem;

        self.isAutoAttendantView = ko.observable(_activeRouteId === _commonState.types.autoAttendant || _activeParentRouteId === _commonState.types.autoAttendant);

        const _buildRoutingRules = (routingRule, routingRuleArray) => {
            if (routingRule.routeToSubMenu === null) {
                if (routingRule.routingRuleExitConditions && routingRule.routingRuleExitConditions.length > 0) {
                    let exitConditions = routingRule.routingRuleExitConditions[0];
                    if (exitConditions.nextRoutingRule !== null) {
                        routingRuleArray.push(exitConditions.nextRoutingRule);
                        _buildRoutingRules(exitConditions.nextRoutingRule, routingRuleArray);
                    } else {
                        return routingRuleArray;
                    }
                } else {
                    return routingRuleArray;
                }
            }
        };

        const _processCallFlowStep = (routeToItem, callFlowStep) => {
            return _promiseFactory.deferIndefinitely((promise) => {

                let callFlowPresentationObject = new CallFlowPresentationObjectConstructor();
                callFlowPresentationObject.isAutoAttendantView(self.isAutoAttendantView);

                if (callFlowStep.routeToSubMenu) {
                    let subMenuItem = callFlowStep.routeToSubMenu;
                    let subMenuItems = callFlowStep.routingRuleExitConditions;
                    let displaySubMenu = new SubMenuObjectConstructor();

                    displaySubMenu.name = subMenuItem.name;
                    displaySubMenu.routeToVoicePromptId = subMenuItem.accountVoicePromptId;
                    displaySubMenu.routeToSystemAudioClipId = subMenuItem.systemAudioClipId;
                    displaySubMenu.routeType(_commonState.types.subMenu);

                    _processCallFlowStep(subMenuItem, displaySubMenu)
                        .done((voicePrompt) => {
                            displaySubMenu.voicePrompt = voicePrompt;
                        });

                    let formattedSubMenuItems = subMenuItems.map(subMenu => {
                        let displaySubMenuItem = new SubMenuItemObjectConstructor();
                        let _subMenuRoutingRules = [];

                        _subMenuRoutingRules.push(subMenu.nextRoutingRule);
                        _buildRoutingRules(subMenu.nextRoutingRule, _subMenuRoutingRules);

                        _formatCallFlowForDisplay(subMenu, _subMenuRoutingRules)
                            .done((callFlow) => {
                                displaySubMenuItem.routeSearchData(subMenu.name);
                                displaySubMenuItem.key = subMenu.value;
                                displaySubMenuItem.callFlow = callFlow;
                            });

                        return displaySubMenuItem;
                    });

                    displaySubMenu.subMenuItems = formattedSubMenuItems;

                    promise.resolve(displaySubMenu);
                    return;
                }

                if (callFlowStep.routeToUserId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToUserId;

                    let commonStateItem = _commonState.get(callFlowStep.routeToUserId);
                    callFlowPresentationObject.iconName = commonStateItem.iconName;
                    callFlowPresentationObject.routeSearchData(commonStateItem.name());
                    callFlowPresentationObject.routeType(commonStateItem.type);
                    callFlowPresentationObject.requireKeyPress = callFlowStep.requireKeyPress;

                    callFlowPresentationObject.userAvatar = commonStateItem.avatar;
                    if (callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(callFlowStep.routingRuleExitConditions[0].value);
                        if (callFlowStep.routingRuleExitConditions[0].nextRoutingRule === null && callFlowPresentationObject.timeout() !== null) {
                            callFlowPresentationObject.endWithHangup(true);
                        }
                    } else {
                        callFlowPresentationObject.endWithNoTimeout(true);
                    }
                    promise.resolve(callFlowPresentationObject);
                    return;
                }

                if (callFlowStep.routeToAutoAttendantId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToAutoAttendantId;

                    let commonStateItem = _commonState.get(callFlowStep.routeToAutoAttendantId);
                    callFlowPresentationObject.routeSearchData(commonStateItem.name());
                    callFlowPresentationObject.routeType(commonStateItem.type);
                    callFlowPresentationObject.iconName = commonStateItem.iconName;

                    if (callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(callFlowStep.routingRuleExitConditions[0].value);
                    }
                    promise.resolve(callFlowPresentationObject);
                    return;
                }

                if (callFlowStep.routeToForwardingNumberId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToForwardingNumberId;

                    let commonStateItem = _commonState.get(callFlowStep.routeToForwardingNumberId);
                    callFlowPresentationObject.routeSearchData(commonStateItem.name());
                    callFlowPresentationObject.routeType(commonStateItem.type);
                    callFlowPresentationObject.iconName = commonStateItem.iconName;
                    callFlowPresentationObject.requireKeyPress = callFlowStep.requireKeyPress;

                    callFlowPresentationObject.forwardingNumberPhoneNumber = _phoneNumberFormatter.toInternationalWithParens(commonStateItem.phoneNumber());
                    if (callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(callFlowStep.routingRuleExitConditions[0].value);
                        if (callFlowStep.routingRuleExitConditions[0].nextRoutingRule === null && callFlowPresentationObject.timeout() !== null) {
                            callFlowPresentationObject.endWithHangup(true);
                        }
                    } else {
                        callFlowPresentationObject.endWithNoTimeout(true);
                    }
                    promise.resolve(callFlowPresentationObject);
                    return;
                }

                if (callFlowStep.routeToVoicePromptId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToVoicePromptId;

                    callFlowPresentationObject.routeSearchData = ko.pureComputed(() => {
                        let commonStatePrompt = _commonState.prompts().find(prompt => prompt.id === callFlowStep.routeToVoicePromptId);
                        if (commonStatePrompt === undefined) {
                            return "";
                        } else {
                            return commonStatePrompt.name();
                        }
                    });
                    callFlowPresentationObject.routeType(_commonState.types.prompt);
                    callFlowPresentationObject.iconName = _systemObjectIconNames.prompt;
                    if (callFlowStep.routingRuleExitConditions && callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(null);
                        if (callFlowStep.routingRuleExitConditions[0].nextRoutingRule === null) {
                            callFlowPresentationObject.endWithHangup(true);
                        }
                    }
                    let foundPrompt = self.availableVoicePrompts.find((prompt) => {
                        if (prompt.voicePromptId === callFlowStep.routeToVoicePromptId) {
                            return true;
                        }
                        return false;
                    });

                    if (foundPrompt) {
                        callFlowPresentationObject.currentVoicePrompt = foundPrompt.currentVoicePrompt;
                        callFlowPresentationObject.conversionComplete = foundPrompt.conversionComplete;
                        callFlowPresentationObject.conversionInProgress = foundPrompt.conversionInProgress;
                        promise.resolve(callFlowPresentationObject);
                        return;
                    }
                    else {
                            _facade.getVoicePrompts()
                                .done(availablePrompts => {
                                    self.availableVoicePrompts = availablePrompts;
                                    foundPrompt = availablePrompts.find(p => p.voicePromptId === callFlowStep.routeToVoicePromptId);

                                    if (foundPrompt) {
                                        callFlowPresentationObject.currentVoicePrompt = foundPrompt.currentVoicePrompt;
                                        callFlowPresentationObject.conversionComplete = foundPrompt.conversionComplete;
                                        callFlowPresentationObject.conversionInProgress = foundPrompt.conversionInProgress;
                                        promise.resolve(callFlowPresentationObject);
                                        return;
                                    }
                                    else {
                                        let emptyPrompt = new AudioPresentationObjectConstructor();
                                        emptyPrompt.audioData("about:blank");
                                        emptyPrompt.audioType("audio/mp3");
                                        emptyPrompt.duration("00:00");
                                        callFlowPresentationObject.currentVoicePrompt = ko.observable(emptyPrompt);
                                        callFlowPresentationObject.conversionComplete = ko.observable(false);
                                        callFlowPresentationObject.conversionInProgress = ko.observable(true);
                                        promise.resolve(callFlowPresentationObject);
                                        return;
                                    }
                                });
                    }
                }

                if (callFlowStep.routeToSystemAudioClipId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToSystemAudioClipId;

                    callFlowPresentationObject.routeSearchData = ko.pureComputed(() => {
                        let commonStatePrompt = _commonState.prompts().find(prompt => prompt.id === callFlowStep.routeToSystemAudioClipId);
                        if (commonStatePrompt === undefined) {
                            return "";
                        } else {
                            return commonStatePrompt.name();
                        }
                    });
                    callFlowPresentationObject.routeType(_commonState.types.prompt);
                    callFlowPresentationObject.iconName = _systemObjectIconNames.prompt;
                    if (callFlowStep.routingRuleExitConditions && callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(null);
                        if (callFlowStep.routingRuleExitConditions[0].nextRoutingRule === null) {
                            callFlowPresentationObject.endWithHangup(true);
                        }
                    }
                    const foundPrompt = self.availableVoicePrompts.find((prompt) => {
                        return prompt.systemAudioClipId === callFlowStep.routeToSystemAudioClipId;
                    });

                    if (foundPrompt) {
                        callFlowPresentationObject.currentVoicePrompt = foundPrompt.currentVoicePrompt;
                        callFlowPresentationObject.conversionComplete = foundPrompt.conversionComplete;
                        callFlowPresentationObject.conversionInProgress = foundPrompt.conversionInProgress;
                        promise.resolve(callFlowPresentationObject);
                        return;
                    } else {
                        promise.reject(new Error("Prompt not found: " + callFlowStep.routeToSystemAudioClipId));
                        return;
                    }
                }

                if (callFlowStep.routeToScheduleId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToScheduleId;

                    let commonStateItem = _commonState.get(callFlowStep.routeToScheduleId);
                    callFlowPresentationObject.routeSearchData(commonStateItem.name());
                    callFlowPresentationObject.routeType(commonStateItem.type);
                    callFlowPresentationObject.iconName = commonStateItem.iconName;
                    let foundSchedule = self.availableSchedules.find((schedule) => {
                        return schedule.accountAttendantScheduleId === callFlowStep.routeToScheduleId;
                    });

                    if (foundSchedule) {
                        _processScheduleCallFlowStep(foundSchedule)
                            .done((callFlowPresentationObject) => {
                                promise.resolve(callFlowPresentationObject);
                            });
                    }
                    else {
                        _facade.getSchedules()
                            .done(availableSchedules => {
                                self.availableSchedules = availableSchedules;
                                foundSchedule = availableSchedules.find(s => s.accountAttendantScheduleId === callFlowStep.routeToScheduleId);

                                _processScheduleCallFlowStep(foundSchedule)
                                    .done((callFlowPresentationObject) => {
                                        promise.resolve(callFlowPresentationObject);
                                    });
                            });
                    }
                }

                if (callFlowStep.routeToUserGroupId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToUserGroupId;

                    let commonStateItem = _commonState.get(callFlowStep.routeToUserGroupId);
                    callFlowPresentationObject.iconName = commonStateItem.iconName;
                    callFlowPresentationObject.userGroupId = commonStateItem.id;
                    callFlowPresentationObject.routeSearchData(commonStateItem.name());
                    callFlowPresentationObject.routeType(commonStateItem.type);
                    callFlowPresentationObject.requireKeyPress = callFlowStep.requireKeyPress;

                    if (callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(callFlowStep.routingRuleExitConditions[0].value);
                        if (callFlowStep.routingRuleExitConditions[0].nextRoutingRule === null && callFlowPresentationObject.timeout() !== null) {
                            callFlowPresentationObject.endWithHangup(true);
                        }
                    } else {
                        callFlowPresentationObject.endWithNoTimeout(true);
                    }
                    promise.resolve(callFlowPresentationObject);
                    return;
                }

                if (callFlowStep.routeToVoicemailBoxId) {
                    callFlowPresentationObject.routeToItemId = callFlowStep.routeToVoicemailBoxId;

                    let commonStateItem = _commonState.get(callFlowStep.routeToVoicemailBoxId);
                    let displayName ='';
                    callFlowPresentationObject.routeType(commonStateItem.type);
                    callFlowPresentationObject.iconName = commonStateItem.iconName;
                    if (commonStateItem.ownerId()) {
                        let owner = _commonState.get(commonStateItem.ownerId());
                        const ownerName = owner.name();
                        if (ownerName.endsWith('s')) {
                            displayName = ownerName + "' Voicemail";
                        } else {
                            displayName = ownerName + "'s Voicemail";
                        }
                    } else {
                        displayName = commonStateItem.name() + " Voicemail";
                    }
                    callFlowPresentationObject.routeSearchData(displayName);

                    if (callFlowStep.routingRuleExitConditions.length > 0) {
                        callFlowPresentationObject.timeout(callFlowStep.routingRuleExitConditions[0].value);
                    }
                    promise.resolve(callFlowPresentationObject);
                    return;
                }
            });
        };

        const _processScheduleCallFlowStep = (schedule) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let callFlowPresentationObject = new CallFlowPresentationObjectConstructor();
                let commonStateItem = _commonState.get(schedule.accountAttendantScheduleId);
                callFlowPresentationObject.isAutoAttendantView = self.isAutoAttendantView;
                callFlowPresentationObject.routeSearchData(commonStateItem.name());
                callFlowPresentationObject.routeType(commonStateItem.type);
                callFlowPresentationObject.iconName = commonStateItem.iconName;
                
                _formatDefaultScheduleSegment(schedule.defaultRoutingRule)
                    .done((defaultRoutingRule) => {
                        _formatScheduleSegments(schedule.segments)
                            .done((segmentPresentationObjects) => {
                                callFlowPresentationObject.showScheduleDetails(true);
                                callFlowPresentationObject.scheduleSegments = segmentPresentationObjects.concat(defaultRoutingRule);
                                promise.resolve(callFlowPresentationObject);
                            });
                    });
            });
        };

        const _formatCallFlowForDisplay = (routeToItem, routingRules) => {
            return _promiseFactory.defer((deferredObject) => {
                _promiseFactory.deferredList(routingRules, (routingRule) => {
                    return _processCallFlowStep(routeToItem, routingRule);
                }).done((allCallflowSteps) => {
                    deferredObject.resolve(allCallflowSteps);
                });
            });
        };

        const _formatDefaultScheduleSegment = (defaultSegmentRoutingRule) => {
            return _promiseFactory.defer((deferredObject) => {

                let defaultSegmentPresentationObject = new ScheduleSegmentPresentationObjectConstructor();
                defaultSegmentPresentationObject.name = _i18n.t('callFlow:defaultScheduleSegment');
                defaultSegmentPresentationObject.isDefault = true;
                defaultSegmentPresentationObject.callFlow = [];

                let segmentRoutingRules = [defaultSegmentRoutingRule];
                _buildRoutingRules(defaultSegmentRoutingRule, segmentRoutingRules);

                _promiseFactory.deferredList(segmentRoutingRules, (segmentRoutingRule) => {
                    return _processCallFlowStep(defaultSegmentRoutingRule, segmentRoutingRule);
                }).done((allCallflows) => {
                    defaultSegmentPresentationObject.callFlow = allCallflows;
                    deferredObject.resolve(defaultSegmentPresentationObject);
                });

            });
        };

        const _formatScheduleSegments = (scheduleSegments) => {
            return _promiseFactory.defer((deferredObject) => {

                _promiseFactory.deferredList(scheduleSegments, (scheduleSegment) => {
                    return _promiseFactory.defer((segmentPromise) => {
                        let commonStateItem = _commonState.get(scheduleSegment.accountAttendantScheduleSegmentId);
                        let scheduleSegmentPresentationObject = new ScheduleSegmentPresentationObjectConstructor();
                        scheduleSegmentPresentationObject.accountAttendantScheduleId = commonStateItem.scheduleId;
                        scheduleSegmentPresentationObject.accountAttendantScheduleSegmentId = commonStateItem.id;
                        scheduleSegmentPresentationObject.name = commonStateItem.name;
                        scheduleSegmentPresentationObject.isForMonday = commonStateItem.isForMonday;
                        scheduleSegmentPresentationObject.isForTuesday = commonStateItem.isForTuesday;
                        scheduleSegmentPresentationObject.isForWednesday = commonStateItem.isForWednesday;
                        scheduleSegmentPresentationObject.isForThursday = commonStateItem.isForThursday;
                        scheduleSegmentPresentationObject.isForFriday = commonStateItem.isForFriday;
                        scheduleSegmentPresentationObject.isForSaturday = commonStateItem.isForSaturday;
                        scheduleSegmentPresentationObject.isForSunday = commonStateItem.isForSunday;
                        scheduleSegmentPresentationObject.startHour = commonStateItem.startHour;
                        scheduleSegmentPresentationObject.startMinute = commonStateItem.startMinute;
                        scheduleSegmentPresentationObject.endHour = commonStateItem.endHour;
                        scheduleSegmentPresentationObject.endMinute = commonStateItem.endMinute;
                        scheduleSegmentPresentationObject.sequence = commonStateItem.sequence;
                        scheduleSegmentPresentationObject.routingRule = scheduleSegment.routingRule;
                        scheduleSegmentPresentationObject.callFlow = [];

                        let segmentRoutingRules = [scheduleSegment.routingRule];
                        _buildRoutingRules(scheduleSegment.routingRule, segmentRoutingRules);

                        _promiseFactory.deferredList(segmentRoutingRules, (segmentRoutingRule) => {
                            return _processCallFlowStep(scheduleSegments, segmentRoutingRule);
                        }).done((allCallflowSteps) => {
                            scheduleSegmentPresentationObject.callFlow = allCallflowSteps;
                            segmentPromise.resolve(scheduleSegmentPresentationObject);
                        });
                    });
                }).done((formattedSegments) => {
                    formattedSegments.sort((seg1, seg2) => {
                        return seg1.sequence - seg2.sequence;
                    });

                    deferredObject.resolve(formattedSegments);
                });
            });
        };


        self.buildRoutingRules = (routingRule, routingRuleArray) => {
            return _buildRoutingRules(routingRule, routingRuleArray);
        };

        self.formatCallFlowForDisplay = (routeToItem, routingRules) => {
            return _formatCallFlowForDisplay(routeToItem, routingRules);
        };

        self.processCallFlowStep = (routeToItem, callFlowStep) => {
            return _processCallFlowStep(routeToItem, callFlowStep);
        };

        self.init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const Facade = require('presentation/settings/autoAttendant/facades/autoAttendantFacade');
            const _facade = new Facade();
            _facade.init(_promiseFactory);
        };

        // set externally, for example phoneNumbersFacade, _findAllConnectors function
        self.availableVoicePrompts = [];
        self.availableSchedules = [];
    };
});

