define('presentation/inbox/viewModels/inboxSidebarHeaderViewModel',['businessServices/router/router',
        'businessServices/converters/numberFormatter',
        'common/promises/promiseFactory',
        'presentation/inbox/dataSources/recordingSubscriptionDataSource',
        'presentation/inbox/dataSources/voicemailSubscriptionDataSource',
        'settings/navigationConfiguration',
], function () {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const NumberFormatterConstructor = require('businessServices/converters/numberFormatter');
        const _numberFormatter = new NumberFormatterConstructor();

        const _callRecordingsSubscriptionsDataSource = require('presentation/inbox/dataSources/recordingSubscriptionDataSource');
        const _voicemailSubscriptionDataSource = require('presentation/inbox/dataSources/voicemailSubscriptionDataSource');

        let isVoicemailActive = ko.observable(false);
        let searchFilter = null;
        let _navigationConfiguration = null;
        let _router = null;
        
        let newCallRecordingCount = _callRecordingsSubscriptionsDataSource.totalNewMessages;
        let newVoicemailCount = _voicemailSubscriptionDataSource.totalNewMessages;

        let displayCallRecordingCount = ko.computed(() => {
            return _numberFormatter.formatForDisplay(newCallRecordingCount());
        });
        let displayVoicemailCount = ko.computed(() => {
            return _numberFormatter.formatForDisplay(newVoicemailCount());
        });

        let isCallRecordingBadgeVisible = ko.computed(() => {
            return newCallRecordingCount() > 0;
        });
        let isVoicemailBadgeVisible = ko.computed(() => {
            return newVoicemailCount() > 0;
        });

        const _initializeScreen = () => {
            _navigationConfiguration = require('settings/navigationConfiguration');
            _router = require('businessServices/router/router');

            return _promiseFactory.wait();
        };

        const navigateToRecordings = () => {
            _router.navigate(_navigationConfiguration.routesById.inboxRecordings.url);
        };

        const navigateToVoicemail = () => {
            _router.navigate(_navigationConfiguration.routesById.inboxVoicemail.url);
        };

        return {
            navigateToRecordings,
            navigateToVoicemail,

            isCallRecordingBadgeVisible,
            isVoicemailActive,
            isVoicemailBadgeVisible,
            displayCallRecordingCount,
            displayVoicemailCount,
            newCallRecordingCount,
            newVoicemailCount,
            searchFilter,

            // Durandal events:
            activate: _initializeScreen,
        };
    });
