define('presentation/settings/phoneNumbers/viewModels/phoneNumberTextMessagingTabViewModel',[
    'businessServices/router/router',
    'common/collections/collectionSorter',
    'common/converters/multiItemMessage',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/smsCampaignRegistrationConstants',
    'presentation/common/window/windowControl'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _multiItemMessage = require('common/converters/multiItemMessage');
        const _router = require('businessServices/router/router');
        const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');
        const _windowControl = require('presentation/common/window/windowControl');

        let _connector = null;

        const _preparePermissionsForDisplay = (authorizedMessagers) => {
            let displayAuthorizedMessagers = authorizedMessagers.map((permission) => {
                if (permission.userId) {
                    const commonStateItem = _commonState.get(permission.userId);
                    return Object.assign({}, permission, {
                        avatar: commonStateItem.avatar(),
                        id: commonStateItem.id,
                        name: commonStateItem.name(),
                        firstName: commonStateItem.name().split(' ')[0]
                    });
                }
                if (permission.userGroupId) {
                    const commonStateItem = _commonState.get(permission.userGroupId);
                    return Object.assign({}, permission, {
                        id: commonStateItem.id,
                        name: commonStateItem.name()
                    });
                }
            });

            const sorter = new SorterConstructor();
            sorter.sort(displayAuthorizedMessagers, "name");

            return displayAuthorizedMessagers;
        };

        self.authorizedMessagers = ko.observableArray([]);
        self.editPhoneNumberMessagesUrl = null;
        self.hasAuthorizedMessagers = ko.pureComputed(() => self.authorizedMessagers().length > 0);
        self.isSmsEnabled = null;
        self.isPortPending = null;
        self.isTollFree = ko.observable(false);
        self.campaignRegistrationStatus = ko.observable();
        self.showDetails = ko.observable(true);
        self.authorizedMessagerIds = ko.pureComputed(() => {
            return self.authorizedMessagers().map(a => a.id);
        });
        self.authorizedMessagerNames = ko.pureComputed(() => {
            let names = self.authorizedMessagers().map(a => a.firstName ? a.firstName : a.name);
            return _multiItemMessage.generateMultiItemMessage(names, "{0}", '& ');
        });
        self.isCollapsible = ko.pureComputed(() => self.authorizedMessagers().length > 1);

        self.isCampaignRegistrationDefault = ko.pureComputed(() => self.campaignRegistrationStatus() === _smsCampaignRegistrationConstants.hostedNumberCampaignRegistrationStatuses.DEFAULT);
        self.isCampaignRegistrationPending = ko.pureComputed(() => self.campaignRegistrationStatus() === _smsCampaignRegistrationConstants.hostedNumberCampaignRegistrationStatuses.PENDING);
        self.hasCampaignRegistrationSucceeded = ko.pureComputed(() => self.campaignRegistrationStatus() === _smsCampaignRegistrationConstants.hostedNumberCampaignRegistrationStatuses.SUCCESS);
        self.hasCampaignRegistrationFailed = ko.pureComputed(() => self.campaignRegistrationStatus() === _smsCampaignRegistrationConstants.hostedNumberCampaignRegistrationStatuses.FAILED);

        self.pendingItemMessagesLabel = ko.observable(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessages:label'));
        self.pendingItemMessagesDescriptionPart1 = ko.observable(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessages:descriptionPart1'));
        self.pendingItemMessagesDescriptionPart2 = ko.observable(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessages:descriptionPart2'));
        self.pendingItemMessagesDescriptionPart3 = ko.observable(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessages:descriptionPart3'));

        self.showMessagesDisabled = ko.pureComputed(() => {
            return !self.isSmsEnabled() ||
                self.isPortPending() ||
                (self.isCampaignRegistrationDefault() && !self.messagingRegistrationWillBeQueued());
        });
        self.showMessagesPending = ko.pureComputed(() => {
            return self.isSmsEnabled() &&
                !self.isPortPending() &&
                (self.isCampaignRegistrationPending() || self.messagingRegistrationWillBeQueued());
        });
        self.showMessagesFailed = ko.pureComputed(() => {
            return self.isSmsEnabled() && self.hasCampaignRegistrationFailed();
        });
        self.showMessagesSucceeded = ko.pureComputed(() => {
            return self.isSmsEnabled() && self.hasCampaignRegistrationSucceeded();
        });

        self.messagingRegistrationWillBeQueued = ko.pureComputed(() => {
            return self.isSmsEnabled() &&
                !self.isCampaignRegistrationPending() &&
                !self.hasCampaignRegistrationSucceeded() &&
                !self.hasCampaignRegistrationFailed();
        });

        self.showPendingDescription = ko.observable(false);
        self.showFailedDescription = ko.observable(false);

        self.togglePendingDescription = () => {
            self.showPendingDescription(!self.showPendingDescription());
        };

        self.toggleFailedDescription = () => {
            self.showFailedDescription(!self.showFailedDescription());
        };

        self.openTcrPage = () => _windowControl.openTcrUrl();

        self.expand = () => {
            if (self.isCollapsible()) {
                self.showDetails(true);
            }
        };

        self.collapse = () => {
            if (self.isCollapsible()) {
                self.showDetails(false);
            }
        };
        
        self.toggleDetails = () => {
            if (self.isCollapsible()) {
                self.showDetails(!self.showDetails());
            }
        };

        self.navigateToEditPhoneNumber = () => {
            _router.navigate(self.editPhoneNumberMessagesUrl);
        };


        self.activate = (connector) => {
            _connector = connector;

            return _initialize();
        };

        const _initialize = () => {
            self.editPhoneNumberMessagesUrl = _connector.editConnectorUrl + '/messaging';
            self.isSmsEnabled = _connector.isSmsEnabled;
            self.isPortPending = _connector.isPortPending;
            self.campaignRegistrationStatus(_connector.campaignRegistrationStatus);

            if (_connector.isTollFree) {
                self.isTollFree(true);
                self.pendingItemMessagesLabel(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessagesTollFree:label'));
                self.pendingItemMessagesDescriptionPart1(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessagesTollFree:descriptionPart1'));
                self.pendingItemMessagesDescriptionPart2(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessagesTollFree:descriptionPart2'));
                self.pendingItemMessagesDescriptionPart3(_i18n.t('phoneNumberTextMessagingTab:pendingItemMessagesTollFree:descriptionPart3'));
            }

            const isSmsPermissionInherited = _connector.isSmsPermissionInherited();

            if (isSmsPermissionInherited && _connector.routeToType() === null) {
                self.authorizedMessagers([]);
            } else if (_connector.smsAuthorizedMessagers()) {
                self.authorizedMessagers(_preparePermissionsForDisplay(_connector.smsAuthorizedMessagers()));
            }

            _promiseFactory.wait();
        };
    };
});
