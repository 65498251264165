define('presentation/login/viewModels/closedDoNotReactivateActionModalViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/authentication/sessionWorkflowFlags',
    'businessServices/authentication/sessionAccountInfo',
    'common/promises/promiseFactory',
    'plugins/router',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/loginNotifications/viewModels/accountClosedDoNotReactivateViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const ClosedDoNotReactivateViewModel = require('presentation/loginNotifications/viewModels/accountClosedDoNotReactivateViewModel');

        const _actionModal = new ActionModalViewModelConstructor();
        const _authenticationManager = require('businessServices/authentication/authenticationManager');
        const _router = require('plugins/router');
        const _navigationConfig = require('settings/navigationConfiguration');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
        const _sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');

        self.showModal = () => {
            _actionModal.clearModal();
            _actionModal.setContentViewModel(ClosedDoNotReactivateViewModel);
            _actionModal.setHeaderText({i18n: 'accountClosedDoNotReactivate:headerTitle'});
            _actionModal.setCancelButtonText({i18n: 'common:signOut'});
            _actionModal.shouldDisplayCancelButton(true);
            _actionModal.shouldDisplayAgentBypassButton(false);
            _actionModal.shouldDisplaySubmitButton(false);
            _actionModal.shouldDisplayDefaultFooterMessage(true);

            if (_sessionAccountInfo.isImpersonating()) {
                _actionModal.shouldDisplayAgentBypassButton(true);
                _actionModal.shouldDisplayCancelButton(false);
            }

            _actionModal.showModal()
                .done((result) => {
                    if (result === "cancel") {
                        _authenticationManager.logout()
                            .done(() => {
                                _router.navigate(_navigationConfig.loginPageUrl);
                            });
                    } else if (result === "agentBypass") {
                        _sessionWorkflowFlags.hasBypassedClosedAccount(true);
                        _router.navigate(_navigationConfig.loginLandingPageUrl);
                    }
                });
        };

        self.activate = () => {
            return _initialize();
        };

        const _initialize = () => {
            self.showModal();
            return _promiseFactory.wait();
        };
    };
});
