define('presentation/settings/phoneNumbers/viewModels/phoneNumberOutboundDialTabViewModel',[
    'businessServices/router/router',
    'common/collections/collectionSorter',
    'common/converters/multiItemMessage',
    'common/promises/promiseFactory',
    'common/storage/commonState',
], function () {
        return function () {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');

            const _commonState = require('common/storage/commonState');
            const _multiItemMessage = require('common/converters/multiItemMessage');
            const _router = require('businessServices/router/router');

            let _connector = null;

            const _preparePermissionsForDisplay = (outboundCallingPermission) => {
                let displayOutboundCallingPermission = outboundCallingPermission.map((permission) => {
                    if (permission.userId) {
                        const commonStateItem = _commonState.get(permission.userId);
                        return Object.assign({}, permission, {
                            avatar: commonStateItem.avatar(),
                            id: commonStateItem.id,
                            name: commonStateItem.name(),
                            firstName: commonStateItem.name().split(' ')[0]
                        });
                    }
                    if (permission.userGroupId) {
                        const commonStateItem = _commonState.get(permission.userGroupId);
                        return Object.assign({}, permission, {
                            id: commonStateItem.id,
                            name: commonStateItem.name()
                        });
                    }
                });

                const sorter = new SorterConstructor();
                sorter.sort(displayOutboundCallingPermission, "name");

                return displayOutboundCallingPermission;
            };

            self.authorizedDialers = ko.observableArray([]);
            self.editPhoneNumberOutboundCallingUrl = null;
            self.hasAuthorizedDialers = ko.pureComputed(() => self.authorizedDialers().length > 0);
            self.isOutboundCallingEnabled = null;
            self.showDetails = ko.observable(true);
            self.authorizedDialerIds = ko.pureComputed(() => self.authorizedDialers().map(a => a.id));
            self.authorizedDialerNames = ko.pureComputed(() => {
                let names = self.authorizedDialers().map(a => a.firstName ? a.firstName : a.name);
                return _multiItemMessage.generateMultiItemMessage(names, "{0}", '& ');
            });
            self.isCollapsible = ko.pureComputed(() => self.authorizedDialers().length > 1);

            self.expand = () => {
                if (self.isCollapsible()) {
                    self.showDetails(true);
                }
            };

            self.collapse = () => {
                if (self.isCollapsible()) {
                    self.showDetails(false);
                }
            };

            self.toggleDetails = () => {
                if (self.isCollapsible()) {
                    self.showDetails(!self.showDetails());
                }
            };

            self.navigateToEditPhoneNumber = () => {
                _router.navigate(self.editPhoneNumberOutboundCallingUrl);
            };


            self.activate = (connector) => {
                _connector = connector;

                return _initialize();
            };

            const _initialize = () => {
                self.editPhoneNumberOutboundCallingUrl = _connector.editConnectorUrl + '/outboundCalling';
                self.isOutboundCallingEnabled = _connector.isOutboundCallingEnabled;
                const isOutboundCallingPermissionInherited = _connector.isOutboundCallingPermissionInherited();

                if (isOutboundCallingPermissionInherited && _connector.routeToType() === null) {
                    self.authorizedDialers([]);
                } else if (_connector.outboundCallingPermission()) {
                    self.authorizedDialers(_preparePermissionsForDisplay(_connector.outboundCallingPermission()));
                }

                return _promiseFactory.wait();
            };
        };
    });
