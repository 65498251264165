define('presentation/common/interiorShellStickyHeader/viewModels/interiorShellStickyHeaderViewModel',[], function() {
    return function() {
        const self = this;

        let _settings = {
            headerI18nKey: null,
        };

        self.headerI18nKey = ko.observable();
        self.buttons = ko.observableArray([]);
        self.isWide = ko.observable(true);

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.headerI18nKey) {
                let headerI18nKey = ko.unwrap(_settings.headerI18nKey);
                self.headerI18nKey(headerI18nKey);
            }

            if (_settings.hasOwnProperty('isWide')) {
                self.isWide(ko.unwrap(_settings.isWide));
            }
        };
    };
});

