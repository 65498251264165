define('businessServices/router/clientUiRouterPluginManager',[
    'businessServices/router/plugins/accountStatusEvaluatorRouterPlugin',
    'businessServices/router/plugins/featureToggleEvaluatorRouterPlugin',
    'businessServices/router/plugins/workSessionRouterPlugin',
    'businessServices/router/plugins/unknownRoutePlugin',
    'businessServices/router/plugins/loginAsUserRoutePlugin',
    'businessServices/router/plugins/messagesRouterPlugin',
    'businessServices/router/plugins/signupForCustomerRoutePlugin',
    'businessServices/router/plugins/signupRouteAuthorizationPlugin',
    'businessServices/router/plugins/signupSessionStatePlugin',
    'businessServices/router/plugins/userAccessLevelEvaluationRouterPlugin',
    'businessServices/router/plugins/userDeviceEvaluationRouterPlugin',
    'businessServices/router/authorizationPlugins/impersonationTokenAuthorizationPlugin',
    'businessServices/router/authorizationPlugins/loginAsUserAuthorizationPlugin',
    'businessServices/router/authorizationPlugins/signupForCustomerAuthorizationPlugin',
    'businessServices/router/authorizationPlugins/tokenAuthorizationPlugin',
    'businessServices/router/viewLocatorPlugins/releaseToggleConfigPlugin',
    'businessServices/router/plugins/inviteInfoCaptureRouterPlugin'
    ], function (
        accountStatusEvaluatorRouterPlugin,
        featureToggleEvaluatorRouterPlugin,
        workSessionRouterPlugin,
        unknownRoutePlugin,
        loginAsUserRoutePlugin,
        messagesRouterPlugin,
        signupForCustomerRoutePlugin,
        signupRouteAuthorizationPlugin,
        signupSessionStatePlugin,
        userAccessLevelEvaluationRouterPlugin,
        userDeviceEvaluationRouterPlugin,
        impersonationTokenAuthorizationPlugin,
        loginAsUserAuthorizationPlugin,
        signupForCustomerAuthorizationPlugin,
        tokenAuthorizationPlugin,
        releaseToggleConfigPlugin,
        inviteInfoCaptureRouterPlugin
) {

    // Note: the order of these is important.
    var plugins = [
        workSessionRouterPlugin,
        signupForCustomerRoutePlugin,
        loginAsUserRoutePlugin,
        signupRouteAuthorizationPlugin,
        signupSessionStatePlugin,
        inviteInfoCaptureRouterPlugin,
        accountStatusEvaluatorRouterPlugin,
        featureToggleEvaluatorRouterPlugin,
        userAccessLevelEvaluationRouterPlugin,
        userDeviceEvaluationRouterPlugin,
        messagesRouterPlugin,
        unknownRoutePlugin,
    ];

    // Note: the order of these is important.
    var authorizationPlugins = [
        signupForCustomerAuthorizationPlugin,
        loginAsUserAuthorizationPlugin,
        impersonationTokenAuthorizationPlugin,
        tokenAuthorizationPlugin
    ];

    var viewLocatorPlugins = [
        releaseToggleConfigPlugin
    ];

    function getPluginConfigurations() {
        var allRouteGuards = [];
        var allLoadUrlRedirects = [];
        var allUnknownRouteRedirects = [];
        var allAuthorizationPlugins = [];
        var allViewLocatorPlugins = [];

        plugins.forEach(function(plugin){
            if(plugin.guardRoute) {
                allRouteGuards.push(plugin.guardRoute);
            }
            if(plugin.loadUrlRedirect) {
                allLoadUrlRedirects.push(plugin.loadUrlRedirect);
            }
            if (plugin.unknownRouteRedirect) {
                allUnknownRouteRedirects.push(plugin.unknownRouteRedirect);
            }
        });

        authorizationPlugins.forEach(function(authorizationPlugin) {
            if (authorizationPlugin.configureAuthorization) {
                allAuthorizationPlugins.push(authorizationPlugin.configureAuthorization);
            }
        });

        viewLocatorPlugins.forEach(function(viewLocatorPlugin) {
            allViewLocatorPlugins.push(viewLocatorPlugin);
        });

        return {
            routeGuards: allRouteGuards,
            loadUrlRedirects: allLoadUrlRedirects,
            unknownRouteRedirects : allUnknownRouteRedirects,
            authorizationPlugins : allAuthorizationPlugins,
            viewLocatorPlugins : allViewLocatorPlugins
        };
    }

    return {
        getPluginConfigurations: getPluginConfigurations
    };

});

