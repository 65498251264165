define('presentation/settings/messagingCompliance/viewModels/messagingComplianceViewModel',[
        'businessServices/authentication/sessionAccountInfo',
        'businessServices/state/modelStateObserver',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/formOfBusinessEnumerations',
        'constants/smsCampaignRegistrationConstants',
        'constants/smsRegistrationFailureEnumerations',
        'i18next',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/modal',
        'presentation/tabs/presentationObjects/tabsPresentationObject',
        'presentation/settings/messagingCompliance/facades/messagingComplianceFacade',
        'presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject',
        'presentation/settings/messagingCompliance/viewModels/authorizedSignatureActionModalViewModel',
        'presentation/settings/messagingCompliance/viewModels/otpVerificationActionModalViewModel',
        'presentation/settings/messagingCompliance/viewModels/messagingComplianceAutoResponseTabViewModel',
        'presentation/settings/messagingCompliance/viewModels/messagingComplianceLocalMessagingTabViewModel',
        'presentation/settings/messagingCompliance/viewModels/messagingComplianceProfileTabViewModel',
        'presentation/settings/messagingCompliance/viewModels/messagingComplianceTollFreeMessagingTabViewModel'
    ],
    function (
        /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
        _sessionAccountInfo,
        /** @type typeof import('businessServices/state/modelStateObserver') */
        ModelStateObserver,
        /** @type typeof import('common/converters/phoneNumberFormatter') */
        PhoneNumberFormatter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('common/storage/commonState') */
        CommonState,
        /** @type typeof import('constants/formOfBusinessEnumerations') */
        _formOfBusiness,
        /** @type typeof import('constants/smsCampaignRegistrationConstants') */
        _smsCampaignRegistrationConstants,
        /** @type typeof import('constants/smsRegistrationFailureEnumerations') */
        _smsRegistrationFailureEnumerations,
        /** @type import('i18next') */
        _i18n,
        /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
        ActionModalViewModel,
        /** @type typeof import('presentation/common/modal') */
        Modal,
        /** @type typeof import('presentation/tabs/presentationObjects/tabsPresentationObject') */
        TabPresentationObject,
        /** @type typeof import('presentation/settings/messagingCompliance/facades/messagingComplianceFacade') */
        MessagingComplianceFacade,
        /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject') */
        MessagingCompliancePresentationObject
    ) {
        return function () {
            /** @typedef { import('presentation/settings/messagingCompliance/facades/messagingComplianceFacade') } MessagingComplianceFacade } */
            /** @typedef { import('presentation/settings/messagingCompliance/viewModels/messagingComplianceViewModel') } MessagingComplianceViewModel } */
            /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingCompliancePresentationObject') } MessagingCompliancePresentationObject } */

            /** @typedef { MessagingComplianceViewModel }*/
            const self = this;

            const AuthorizedSignatureActionModalViewModelConstructor = require('presentation/settings/messagingCompliance/viewModels/authorizedSignatureActionModalViewModel');
            const OtpVerificationActionModalViewModel = require('presentation/settings/messagingCompliance/viewModels/otpVerificationActionModalViewModel');
            const MessagingComplianceProfileTab = require('presentation/settings/messagingCompliance/viewModels/messagingComplianceProfileTabViewModel');
            const MessagingComplianceLocalMessagingTab = require('presentation/settings/messagingCompliance/viewModels/messagingComplianceLocalMessagingTabViewModel');
            const MessagingComplianceTollFreeMessagingTab = require('presentation/settings/messagingCompliance/viewModels/messagingComplianceTollFreeMessagingTabViewModel');
            const MessagingComplianceAutoResponseTab = require('presentation/settings/messagingCompliance/viewModels/messagingComplianceAutoResponseTabViewModel');

            const _phoneNumberFormatter = new PhoneNumberFormatter();
            const _promiseFactory = new PromiseFactory();

            /** @type import('presentation/common/modal') */
            let _modalService = null;

            /** @type { MessagingComplianceFacade }*/
            let _facade = null;

            /** @type { IDisposable[] } */
            let _disposables = [];

            const _formatFailureReasonsForDisplay = (/** @type { Array<IBrandRegistrationFailureReason> } */ failureReasons) => {
                return failureReasons.map((reason) => {
                    const failureCodeKey = Object.keys(_smsRegistrationFailureEnumerations.brandRegistrationFailureCodes)
                        .find(key => _smsRegistrationFailureEnumerations.brandRegistrationFailureCodes[key] === reason.code);

                    return Object.assign({}, reason, {
                        i18nKey: `tcrBrandRegistrationFailures:${failureCodeKey}`
                    });
                });
            };

            const _openSmsOtpVerificationModal = () => {
                if (!self.accountHasSoleProprietorBrandPendingSmsPinVerification()){
                    return;
                }

                const otpModal = new OtpVerificationActionModalViewModel(self.displayMobilePhoneNumberWithPin, _refreshMessagingComplianceStatus);
                _modalService.showModal(otpModal);
            };

            const _resendSmsPin = () => {
                if (!self.showResendSmsPin()) {
                    return;
                }

                self.showResendSmsPin(false);

                _promiseFactory.defer((promise) => {
                    _facade.requestSmsPin()
                        .fail(promise.reject)
                        .done(promise.resolve);
                });
            };

            const _showAuthorizeSignatureActionModal = (/** @type { MessagingCompliancePresentationObject } */ messagingCompliance) => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    const actionModal = new ActionModalViewModel();
                    actionModal.setContentViewModel(AuthorizedSignatureActionModalViewModelConstructor, [_saveMessagingCompliance, messagingCompliance]);
                    actionModal.setHeaderText({i18n: 'authorizedSignatureActionModal:headerText'});
                    actionModal.showModal()
                        .fail(promise.reject)
                        .done((result) => {
                            if (result === "cancel") {
                                promise.resolve();
                                return;
                            }

                            _refreshMessagingComplianceStatus()
                                .fail(promise.reject)
                                .done(() => {
                                    self.shouldScrollToTop(true);
                                    promise.resolve();
                                });
                        });
                });
            };

            const _saveMessagingCompliance = (/** @type { MessagingCompliancePresentationObject } */ messagingCompliance) => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    _facade.saveMessagingCompliance(messagingCompliance)
                        .fail(promise.reject)
                        .done((result) => {
                            if (result) {
                                _facade.tryCreateSoleProprietorBrand()
                                     .fail(promise.reject)
                                     .done((response) => {
                                             self.modelStateObserver.saveData();
                                             promise.resolve(response);
                                     });
                            } else {
                                self.modelStateObserver.saveData();
                                promise.resolve();
                            }
                        });
                });
            };

            const _refreshMessagingComplianceStatus = () => {
                let refreshPromiseFactory = new PromiseFactory();

                refreshPromiseFactory.defer((brandStatusPromise) => {
                    _refreshBrandStatus()
                        .fail(brandStatusPromise.reject)
                        .done(brandStatusPromise.resolve);
                });

                refreshPromiseFactory.defer((messagingStatusPromise) => {
                    _refreshMessagingStatus()
                        .fail(messagingStatusPromise.reject)
                        .done(messagingStatusPromise.resolve);
                });

                return _promiseFactory.deferIndefinitely((promise) => {
                    refreshPromiseFactory.wait()
                        .fail(promise.reject)
                        .done(promise.resolve);
                });
            };

            const _onBrandStatusUpdated = (/** @type { IBrandStatusUpdatedEvent } */ event) => {
                return _promiseFactory.deferIndefinitely((promise) => {

                    if (event.status !== _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.NONE){
                        self.tcrRegistrationStatus(event.status);
                    }

                    _refreshMessagingComplianceStatus()
                        .fail(promise.reject)
                        .done(promise.resolve);
                });
            };

            const _refreshBrandStatus = () => {
                return _promiseFactory.deferIndefinitely((brandStatusPromise) => {
                    _facade.getAccountBrandStatus()
                        .fail(brandStatusPromise.reject)
                        .done((result) => {
                            self.accountHasFailedSoleProprietorBrandValidation(result.accountHasFailedSoleProprietorBrandValidation);
                            self.accountHasSoleProprietorBrandPendingSmsPinVerification(result.accountHasSoleProprietorBrandPendingSmsPinVerification);
                            self.mobilePhoneNumberWithPin(result.mobilePhoneNumberForSmsPin);

                            if (result.tcrRegistrationStatus !== _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.NONE) {
                                self.tcrRegistrationStatus(result.tcrRegistrationStatus);
                            }

                            const failureReasons = result.tcrRegistrationFailureReasons;
                            if (failureReasons && failureReasons.length > 0) {
                                self.tcrRegistrationFailureReasons(_formatFailureReasonsForDisplay(failureReasons));
                            }

                            brandStatusPromise.resolve();
                        });
                });
            };

            const _refreshMessagingStatus = () => {
                return _promiseFactory.deferIndefinitely((messagingStatusPromise) => {
                    _facade.getAccountMessagingStatus()
                        .fail(messagingStatusPromise.reject)
                        .done((result) => {
                            self.accountHasCompletedLocalRegistration(result.accountHasCompletedLocalRegistration);
                            self.accountHasCompletedTollFreeRegistration(result.accountHasCompletedTollFreeRegistration);

                            messagingStatusPromise.resolve();
                        });
                });
            };

            self.isCompositionComplete = ko.observable(false);
            self.isEditMode = ko.observable(false);
            self.modelStateObserver = null;
            self.shouldScrollToTop = ko.observable(false);
            self.tabActive = ko.observable();
            self.tabMetadata = {
                profile: { id: "profile", isValid: ko.observable(true), title: _i18n.t('messagingCompliance:profileTab') },
                localSms: { id: "local", isValid: ko.observable(true), title: _i18n.t('messagingCompliance:localSmsTab') },
                tollfreeSms: { id: "tollfree", isValid: ko.observable(true), title: _i18n.t('messagingCompliance:tollfreeSmsTab') },
                autoResponse: { id: "autoResponse", isValid: ko.observable(true), title: _i18n.t('messagingCompliance:autoResponseTab') }
            };
            self.tabs = [
                new TabPresentationObject(self.tabMetadata.profile.id, self.tabActive, self.tabMetadata.profile.isValid, self.tabMetadata.profile.title),
                new TabPresentationObject(self.tabMetadata.localSms.id, self.tabActive, self.tabMetadata.localSms.isValid, self.tabMetadata.localSms.title),
                new TabPresentationObject(self.tabMetadata.tollfreeSms.id, self.tabActive, self.tabMetadata.tollfreeSms.isValid, self.tabMetadata.tollfreeSms.title),
                new TabPresentationObject(self.tabMetadata.autoResponse.id, self.tabActive, self.tabMetadata.autoResponse.isValid, self.tabMetadata.autoResponse.title)
            ];

            self.tabActive(self.tabs[0].id);
            self.isTabActive = (/** @type {string} */ tabId) => {
                return self.tabActive() === tabId;
            };

            self.messagingCompliance = null;

            self.isAccountInFreeTrial = ko.observable(false);
            self.accountHasCompletedLocalRegistration = ko.observable(false);
            self.accountHasCompletedTollFreeRegistration = ko.observable(false);

            self.messagingComplianceProfileTabViewModel = null;
            self.messagingComplianceLocalMessagingTabViewModel = null;
            self.messagingComplianceTollFreeMessagingTabViewModel = null;
            self.messagingComplianceAutoResponseTabViewModel = null;
            self.activeTabViewModel = ko.observable();

            self.invalidTabs = ko.pureComputed(() => {
                return self.tabs.reduce((accumulator, presentationObject) => {
                    if (!presentationObject.isTabValid()) {
                        accumulator.push(presentationObject.tabText);
                    }

                    return accumulator;
                }, []);
            });

            self.validationWarningMessages = ko.pureComputed(() => {
                return [
                    {
                        message: _i18n.t('messagingCompliance:validationTabDescription'),
                        listItems: self.invalidTabs()
                    },
                    {
                        message: _i18n.t('messagingCompliance:validationPleaseFix'),
                        listItems: []
                    }
                ];
            });

            self.accountHasFailedSoleProprietorBrandValidation = ko.observable(false);
            self.accountHasSoleProprietorBrandPendingSmsPinVerification = ko.observable(false);
            self.tcrRegistrationStatus = ko.observable(_smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.NONE);
            self.tcrRegistrationFailureReasons = ko.observableArray([]);

            self.mobilePhoneNumberWithPin = ko.observable("");
            self.smsPinRejected = ko.observable(false);

            self.showTcrErrorMessage = ko.pureComputed(() => {
                return self.accountHasFailedSoleProprietorBrandValidation() ||
                    self.tcrRegistrationStatus() === _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.FAILED;
            });

            self.showTcrPendingMessage = ko.pureComputed(() => {
                return !self.showSmsPinRequiredMessage() &&
                    self.tcrRegistrationStatus() === _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.PENDING;
            });

            self.showSmsPinRequiredMessage = ko.pureComputed(() => {
                return self.accountHasSoleProprietorBrandPendingSmsPinVerification();
            });

            self.hasLocalNumberWithMessagingEnabled = ko.pureComputed(() => {
                return self.tcrRegistrationStatus() === _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.SUCCEEDED &&
                    !self.showSmsPinRequiredMessage() &&
                    self.messagingComplianceLocalMessagingTabViewModel.hasLocalNumberWithMessagingEnabled();
            });

            self.hasTollFreeNumberWithMessagingEnabled = ko.pureComputed(() => {
                return self.messagingComplianceTollFreeMessagingTabViewModel.hasTollFreeNumberWithMessagingEnabled();
            });

            self.showReadyToEnableSmsMessage = ko.pureComputed(() => {
                if (self.showTcrErrorMessage() || self.showTcrPendingMessage() || self.showSmsPinRequiredMessage()) {
                    return false;
                }

                const isLocalRegistrationComplete = self.accountHasCompletedLocalRegistration();
                const isTollFreeRegistrationComplete = self.accountHasCompletedTollFreeRegistration();

                if (isLocalRegistrationComplete && isTollFreeRegistrationComplete) {
                    return !self.hasLocalNumberWithMessagingEnabled() && !self.hasTollFreeNumberWithMessagingEnabled();
                } else if (isLocalRegistrationComplete && !isTollFreeRegistrationComplete) {
                    return !self.hasLocalNumberWithMessagingEnabled();
                } else if (!isLocalRegistrationComplete && isTollFreeRegistrationComplete) {
                    return !self.hasTollFreeNumberWithMessagingEnabled();
                } else {
                     return false;
                }
            });

            self.displayMobilePhoneNumberWithPin = ko.pureComputed(() => {
                return _phoneNumberFormatter.toNumericDefault(self.mobilePhoneNumberWithPin());
            });

            self.showResendSmsPin = ko.observable(true);

            self.resubmitButtonText = _i18n.t('messagingCompliance:resubmitBrand');
            self.showResubmitBrandButton = ko.pureComputed(() => {
                return _sessionAccountInfo.isImpersonating();
            });

            self.getMessagingComplianceFormData = () => {

                if (!self.messagingComplianceLocalMessagingTabViewModel.isLocalSmsFormEnabled()) {
                    self.messagingComplianceLocalMessagingTabViewModel.cancelForm();
                    self.messagingComplianceLocalMessagingTabViewModel.isLocalSmsFormEnabled(false);
                }
                if (!self.messagingComplianceTollFreeMessagingTabViewModel.isTollfreeSmsFormEnabled()) {
                    self.messagingComplianceTollFreeMessagingTabViewModel.cancelForm();
                    self.messagingComplianceTollFreeMessagingTabViewModel.isTollfreeSmsFormEnabled(false);
                }

                const presentationObjectForSave = new MessagingCompliancePresentationObject();
                presentationObjectForSave.profile(self.messagingComplianceProfileTabViewModel.getForm());
                presentationObjectForSave.localMessaging(self.messagingComplianceLocalMessagingTabViewModel.getForm());
                presentationObjectForSave.tollfreeMessaging(self.messagingComplianceTollFreeMessagingTabViewModel.getForm());
                presentationObjectForSave.autoResponse(self.messagingComplianceAutoResponseTabViewModel.getForm());

                return presentationObjectForSave;
            };

            self.openSmsOtpVerificationModal = _openSmsOtpVerificationModal;

            self.resendSmsPin = _resendSmsPin;

            self.cancelForm = () => {
                return _promiseFactory.defer((promise) => {
                    self.resetValidation();
                    self.messagingComplianceProfileTabViewModel.cancelForm();
                    self.messagingComplianceLocalMessagingTabViewModel.cancelForm();
                    self.messagingComplianceTollFreeMessagingTabViewModel.cancelForm();
                    self.messagingComplianceAutoResponseTabViewModel.cancelForm();
                    self.modelStateObserver.restoreData();
                    promise.resolve();
                });
            };

            self.resubmitBrand = () => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    self.validate()
                        .fail(promise.reject)
                        .done((isValid) => {
                            if (isValid === false) {
                                self.shouldScrollToTop(true);
                                promise.resolve(false);
                                return;
                            }

                            _facade.resubmitBrand()
                                .fail(promise.reject)
                                .done(() => {
                                    _refreshMessagingComplianceStatus()
                                        .fail(promise.reject)
                                        .done(() => {
                                            self.shouldScrollToTop(true);
                                            promise.resolve();
                                        });
                                });
                        });
                });
            };

            self.save = () => {
                self.shouldScrollToTop(false);
                return _promiseFactory.deferIndefinitely((promise) => {
                    self.validate()
                        .fail(promise.reject)
                        .done((isValid) => {
                            if (isValid === false) {
                                self.shouldScrollToTop(true);
                                promise.resolve(false);
                                return;
                            }

                            const messagingCompliance = self.getMessagingComplianceFormData();

                            const isSignatureRequired = self.isEditMode() &&
                                self.modelStateObserver.isDirty();

                            if (isSignatureRequired) {
                                _showAuthorizeSignatureActionModal(messagingCompliance);
                                promise.resolve();
                            } else {
                                _saveMessagingCompliance(messagingCompliance)
                                    .fail(promise.reject)
                                    .done(() => {
                                        _refreshMessagingComplianceStatus()
                                            .fail(promise.reject)
                                            .done(() => {
                                                self.shouldScrollToTop(true);
                                                promise.resolve();
                                            });
                                    });
                            }

                        });
                });
            };

            self.validate = () => {
                return _promiseFactory.defer((validatePromise) => {
                    /** @type {boolean} */
                    let isProfileValid = false;
                    /** @type {boolean} */
                    let isLocalMessagingValid = false;
                    /** @type {boolean} */
                    let isTollFreeMessagingValid = false;
                    /** @type {boolean} */
                    let isAutoResponseValid = false;

                    let validatePromiseFactory = new PromiseFactory();
                    validatePromiseFactory.defer((profileValidationPromise) => {
                        self.messagingComplianceProfileTabViewModel.validate()
                            .fail(profileValidationPromise.reject)
                            .done((/** @type {boolean} */ profileValidationResult) => {
                                isProfileValid = profileValidationResult;
                                profileValidationPromise.resolve();
                            });
                    });

                    validatePromiseFactory.defer((localMessagingValidationPromise) => {
                        if (self.messagingComplianceLocalMessagingTabViewModel.isLocalSmsFormEnabled()) {
                            self.messagingComplianceLocalMessagingTabViewModel.validate()
                                .fail(localMessagingValidationPromise.reject)
                                .done((/** @type {boolean} */ localMessagingValidationResult) => {
                                    isLocalMessagingValid = localMessagingValidationResult;
                                    localMessagingValidationPromise.resolve();
                                });
                        } else {
                            isLocalMessagingValid = true;
                            localMessagingValidationPromise.resolve();
                        }
                    });

                    validatePromiseFactory.defer((tollFreeMessagingValidationPromise) => {
                        if (self.messagingComplianceTollFreeMessagingTabViewModel.isTollfreeSmsFormEnabled()) {
                            self.messagingComplianceTollFreeMessagingTabViewModel.validate()
                            .fail(tollFreeMessagingValidationPromise.reject)
                            .done((/** @type {boolean} */ tollFreeMessagingValidationResult) => {
                                isTollFreeMessagingValid = tollFreeMessagingValidationResult;
                                tollFreeMessagingValidationPromise.resolve();
                            });
                        } else {
                            isTollFreeMessagingValid = true;
                            tollFreeMessagingValidationPromise.resolve();
                        }
                    });

                    validatePromiseFactory.defer((autoResponseValidationPromise) => {
                        self.messagingComplianceAutoResponseTabViewModel.validate()
                            .fail(autoResponseValidationPromise.reject)
                            .done((/** @type {boolean} */ autoResponseValidationResult) => {
                                isAutoResponseValid = autoResponseValidationResult;
                                autoResponseValidationPromise.resolve();
                            });
                    });

                    validatePromiseFactory.wait()
                        .fail(validatePromise.reject)
                        .done(() => {
                            self.tabMetadata.profile.isValid(isProfileValid);
                            self.tabMetadata.localSms.isValid(isLocalMessagingValid);
                            self.tabMetadata.tollfreeSms.isValid(isTollFreeMessagingValid);
                            self.tabMetadata.autoResponse.isValid(isAutoResponseValid);
                            validatePromise.resolve(isProfileValid && isLocalMessagingValid && isTollFreeMessagingValid && isAutoResponseValid);
                        });
                });
            };

            self.resetValidation = () => {
                self.messagingComplianceProfileTabViewModel.resetValidation();
                self.tabMetadata.profile.isValid(true);
                self.messagingComplianceLocalMessagingTabViewModel.resetValidation();
                self.tabMetadata.localSms.isValid(true);
                self.messagingComplianceTollFreeMessagingTabViewModel.resetValidation();
                self.tabMetadata.tollfreeSms.isValid(true);
                self.messagingComplianceAutoResponseTabViewModel.resetValidation();
                self.tabMetadata.autoResponse.isValid(true);
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.detached = () => {
                self.isCompositionComplete(false);

                self.messagingComplianceProfileTabViewModel.dispose();
                self.messagingComplianceLocalMessagingTabViewModel.dispose();
                self.messagingComplianceTollFreeMessagingTabViewModel.dispose();
                self.messagingComplianceAutoResponseTabViewModel.dispose();

                _disposables.forEach(s => s.dispose());
                _disposables = [];

                _facade.dispose();
            };

            self.activate = () => {
                _facade = new MessagingComplianceFacade();
                _facade.init(_promiseFactory);

                _modalService = new Modal();

                self.messagingComplianceProfileTabViewModel = new MessagingComplianceProfileTab(self.isEditMode, self.tcrRegistrationStatus);
                self.messagingComplianceLocalMessagingTabViewModel = new MessagingComplianceLocalMessagingTab(self);
                self.messagingComplianceTollFreeMessagingTabViewModel = new MessagingComplianceTollFreeMessagingTab(self);
                self.messagingComplianceAutoResponseTabViewModel = new MessagingComplianceAutoResponseTab(self);

                return _initialize();
            };

            const _initialize = () => {
                let initPromiseFactory = new PromiseFactory();

                initPromiseFactory.defer((messagingCompliancePromise) => {
                    _facade.getMessagingCompliance()
                        .fail(messagingCompliancePromise.reject)
                        .done((messagingCompliance) => {
                            self.messagingCompliance = messagingCompliance;
                            self.accountHasCompletedLocalRegistration(messagingCompliance.accountHasCompletedLocalRegistration());
                            self.accountHasCompletedTollFreeRegistration(messagingCompliance.accountHasCompletedTollFreeRegistration());
                            self.isAccountInFreeTrial(messagingCompliance.isAccountInFreeTrial());

                            _initializeTabs(messagingCompliance)
                                .fail(messagingCompliancePromise.reject)
                                .done(messagingCompliancePromise.resolve);
                        });
                });

                initPromiseFactory.defer((brandStatusPromise) => {
                    _refreshBrandStatus()
                        .fail(brandStatusPromise.reject)
                        .done(brandStatusPromise.resolve);
                });

                _promiseFactory.defer((initializePromise) => {
                    initPromiseFactory.wait()
                        .done(() => {
                            self.modelStateObserver = new ModelStateObserver(self, true,
                                [
                                    self.messagingComplianceProfileTabViewModel.modelStateObserver,
                                    self.messagingComplianceLocalMessagingTabViewModel.modelStateObserver,
                                    self.messagingComplianceTollFreeMessagingTabViewModel.modelStateObserver,
                                    self.messagingComplianceAutoResponseTabViewModel.modelStateObserver
                                ]);
                            self.modelStateObserver.commitData();

                            _facade.onBrandStatusUpdated(_onBrandStatusUpdated);

                            initializePromise.resolve();
                        });
                });

                return _promiseFactory.wait();
            };

            const _updateActiveTabViewModel = () => {
                switch (self.tabActive()) {
                    case self.tabMetadata.profile.id:
                        self.activeTabViewModel(self.messagingComplianceProfileTabViewModel);
                        break;
                    case self.tabMetadata.localSms.id:
                        self.activeTabViewModel(self.messagingComplianceLocalMessagingTabViewModel);
                        break;
                    case self.tabMetadata.tollfreeSms.id:
                        self.activeTabViewModel(self.messagingComplianceTollFreeMessagingTabViewModel);
                        break;
                    case self.tabMetadata.autoResponse.id:
                        self.activeTabViewModel(self.messagingComplianceAutoResponseTabViewModel);
                        break;
                }
            };

            const _initializeTabs = (/** @type { MessagingCompliancePresentationObject } */ messagingCompliance) => {
                return _promiseFactory.defer((initTabsPromise) => {
                    let initTabsPromiseFactory = new PromiseFactory();

                    const { profile , localMessaging, tollfreeMessaging, autoResponse } = messagingCompliance;

                    if (profile().smsComplianceProfileId) {
                        self.isEditMode(true);
                    }

                    initTabsPromiseFactory.defer((profilePromise) => {
                        self.messagingComplianceProfileTabViewModel.activate(profile())
                            .fail(profilePromise.reject)
                            .done(profilePromise.resolve);
                    });

                    initTabsPromiseFactory.defer((localMessagingPromise) => {
                        self.messagingComplianceLocalMessagingTabViewModel.activate(localMessaging())
                            .fail(localMessagingPromise.reject)
                            .done(localMessagingPromise.resolve);
                    });

                    initTabsPromiseFactory.defer((tollFreeMessagingPromise) => {
                        self.messagingComplianceTollFreeMessagingTabViewModel.activate(tollfreeMessaging())
                            .fail(tollFreeMessagingPromise.reject)
                            .done(tollFreeMessagingPromise.resolve);
                    });

                    initTabsPromiseFactory.defer((autoResponsePromise) => {
                        self.messagingComplianceAutoResponseTabViewModel.activate(autoResponse())
                            .fail(autoResponsePromise.reject)
                            .done(autoResponsePromise.resolve);
                    });

                    initTabsPromiseFactory.wait()
                        .fail(initTabsPromise.reject)
                        .done(() => {
                            let firstTab = self.tabs.find(t => true);
                            self.tabActive(firstTab.id);

                            _updateActiveTabViewModel();
                            _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                            initTabsPromise.resolve();
                        });
                });
            };
        };
    });

