define('businessServices/router/plugins/unknownRoutePlugin',['settings/navigationConfiguration'], function(navigationConfiguration) {
    const _sectionRedirects = () => {
        return Object.keys(navigationConfiguration.sections).map((sectionKey) => {
            return {
                stall: true,
                url: navigationConfiguration.sections[sectionKey].url + navigationConfiguration.errorPageUrl,
                test: (testUrl) => {
                    const matchUrl = navigationConfiguration.sections[sectionKey].url;
                    return testUrl.startsWith(`/${matchUrl}/`);
                }
            };
        });
    };

    const _unknownRouteRedirect = () => {
        return {
            default: {
                stall : true,
                url: navigationConfiguration.errorPageUrl
            },
            sections: _sectionRedirects()
        };
    };

    return {
        unknownRouteRedirect : _unknownRouteRedirect
    };
});

