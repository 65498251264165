define('businessServices/router/plugins/userAccessLevelEvaluationRouterPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/sessionAccountInfo'
], function (navigationConfiguration, sessionAccountInfo) {
    var _navigationConfiguration = navigationConfiguration;
    var _sessionAccountInfo = sessionAccountInfo;

    function checkUserPermissionMatch(permissionId) {
        var accountPermissions = _sessionAccountInfo.accountPermissions();
        return accountPermissions[permissionId] === true;
    }

    function userHasPermissionForRoute(routeId) {
        var navigationRouteInfo = _navigationConfiguration.routesById[routeId];
        var permissionId = navigationRouteInfo.permission;
        if (permissionId !== null && permissionId !== undefined) {
            return checkUserPermissionMatch(permissionId);
        } else {
            return true;
        }
    }

    function routeByUserAccessLevel(routeId) {
        if(userHasPermissionForRoute(routeId)) {
            return true;
        } else {
            return {routeUrl : navigationConfiguration.errorPageUrl};
        }
    }

    return {
        guardRoute: routeByUserAccessLevel
    };
});

