define('presentation/contacts/viewModels/contactCardViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/contacts/contactsStateSingleton',
    'common/promises/promiseFactory',
    'common/converters/phoneNumberFormatter',
    'i18next',
    'presentation/contacts/facades/contactCardFacade'
], function(
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    contactsState,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('presentation/contacts/facades/contactCardFacade') */
    ContactCardFacade
) {
    return function(/** @type { string } */ contactId, /** @type { () => void } */ onCloseDetailsClickedCallback, /** @type { () => void } */ onInfoBubbleClickedCallback, /** @type { any } */ router) {
        const self = this;

        /** @type import('common/promises/promiseFactory') */
        const _promiseFactory = new PromiseFactory();

        /** @type import('presentation/contacts/facades/contactCardFacade') */
        let _contactCardFacade = null;

        const _navigationConfiguration = require('settings/navigationConfiguration');

        self.comingSoonTooltip = i18n.t('comingSoon');
        self.disabledTooltip = i18n.t('addContact:disabledEdit');
        self.isCompositionComplete = ko.observable(false);
        self.isSystemAdmin = ko.observable(false);
        self.positionY = ko.observable();
        self.router = router;

        self.contactPresentationObject = ko.observable(null);
        self.accountContactId = '';
        self.accountUserId = '';
        self.accountUserGroupId = '';
        self.avatarUrl = ko.observable(null);
        self.firstName = ko.observable('');
        self.lastName = ko.observable('');
        self.displayName = ko.observable('');
        self.initials = ko.observable('');
        self.company = ko.observable('');
        self.addresses = ko.observableArray([]);
        self.emailAddresses = ko.observableArray([]);
        self.phoneNumbers = ko.observableArray([]);
        self.urls = ko.observableArray([]);
        self.birthday = ko.observable('');
        self.notes = ko.observable('');
        self.contactSource = ko.observable('');
        self.createdDateTime = ko.observable(null);
        self.modifiedDateTime = ko.observable(null);

        self.isContactVisible = ko.pureComputed(() => {
            return self.isCompositionComplete();
        });

        self.isUserOrGroup = ko.pureComputed(() => !!(self.accountUserId || self.accountUserGroupId));

        self.showAddresses = ko.pureComputed(() => {
            return self.addresses().length > 0;
        });

        self.showEmailAddresses = ko.pureComputed(() => {
            return self.emailAddresses().length > 0;
        });

        self.showPhoneNumbers = ko.pureComputed(() => {
            return self.phoneNumbers().length > 0;
        });

        self.showOther = ko.pureComputed(() => {
            return self.birthday() !== '' || self.urls().length > 0;
        });

        self.showNotes = ko.pureComputed(() => {
            return self.notes() !== '';
        });

        self.canEdit = ko.pureComputed(() => {
            const isValidContact = self.accountContactId && !self.isUserOrGroup();
            const isPrivateContact = self.contactSource() === 'yourAddressBook';
            const userHasSharedPermission = self.isSystemAdmin() || self.userHasEditContactsPermission();
            return isValidContact && (isPrivateContact || userHasSharedPermission);
        });

        self.userHasEditContactsPermission = ko.pureComputed(() => {
            const currentUserId = sessionAccountInfo.userId();
            return contactsState.usersWithContactPermissions().includes(currentUserId);
        });

        self.navigateToEditContact = () => {
            if (self.canEdit()) {
                const url = `${_navigationConfiguration.routesById.editContact.baseUrl}/${self.accountContactId}`;
                self.router.navigate(url);
            }
        };

        self.onInfoBubbleClickedCallback = () => {
            _contactCardFacade.setOpenStateById(contactId, true);
            onInfoBubbleClickedCallback();
        };

        self.detached = () => {
            self.isCompositionComplete(false);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            _contactCardFacade = new ContactCardFacade();
            _contactCardFacade.init(_promiseFactory);

            return _initialize();
        };

        self.updateScrollPositionY = (/** @type {number}*/ position) => {
            _contactCardFacade.setScrollPositionById(contactId, position);
        };

        const _initialize = () => {
            self.isSystemAdmin(sessionAccountInfo.accountPermissions().SystemSettings);
            _contactCardFacade.getContactById(contactId)
                .fail(() => {
                    contactsState.setSelectedContactId(null);
                })
                .done((result) => {
                    if (result.accountContactId || result.accountUserId || result.accountUserGroupId) {
                        _setExistingContactData(result);
                    }
                });

            _contactCardFacade.getScrollPositionById(contactId).then(position => {
                self.positionY(position);
            });

            _contactCardFacade.getOpenStateById(contactId).then(isOpen => {
                if (isOpen) {
                    onInfoBubbleClickedCallback();
                }
                else {
                    onCloseDetailsClickedCallback();
                }
            });

            return _promiseFactory.wait();
        };

        /** @param { IContactPresentationObject } data */
        const _setExistingContactData = (data) => {
            const {
                accountContactId,
                accountUserId,
                accountUserGroupId,
                avatarUrl,
                firstName,
                lastName,
                displayName,
                initials,
                company,
                addresses,
                emailAddresses,
                phoneNumbers,
                urls,
                birthday,
                notes,
                contactSource,
                createdDateTime,
                modifiedDateTime
            } = data;

            self.accountContactId = accountContactId;
            self.accountUserId = accountUserId;
            self.accountUserGroupId = accountUserGroupId;
            self.avatarUrl = avatarUrl;
            self.firstName = firstName;
            self.lastName = lastName;
            self.displayName = displayName;
            self.initials = initials;
            self.company = company;
            self.addresses = addresses;
            self.emailAddresses = emailAddresses;
            self.phoneNumbers = phoneNumbers;
            self.urls = urls;
            self.birthday = birthday;
            self.notes = notes;
            self.contactSource = contactSource;
            self.createdDateTime = createdDateTime;
            self.modifiedDateTime = modifiedDateTime;

            self.contactPresentationObject(data);
        };
    };
});
