define('businessServices/login/manageCommonState',[
    'common/promises/promiseFactory',
    'common/storage/commonState'
], function() {
    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();
    const _commonState = require('common/storage/commonState');
    function receiveAlertLoginSuccessful() {
        _commonState.clear();
        return _commonState.init(_promiseFactory);
    }
    function receiveAlertLogout() {
        _commonState.clear();
    }

    return {
        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});
