define('presentation/signup/viewModels/signupFooterViewModel',[
        'businessServices/router/router',
        'businessServices/signup/signupSessionStore',
        'common/promises/promiseFactory',
        'presentation/signup/common/signupPageIndicatorWidget',
        'settings/navigationConfiguration',
        'presentation/signup/common/signupPageIndicatorWidget',
        'presentation/signup/common/signupNavigationManager',
        'businessServices/browserSupport/browserType',
        'common/url/urlFormatter',
        'constants/signupSessionFlowConstants'
    ],
    function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            let _signupSessionStore = null;
            let _signupNavigationManager = null;

            const _browserType = require('businessServices/browserSupport/browserType');
            const _i18n = require('i18next');
            const _indicatorWidget = require('presentation/signup/common/signupPageIndicatorWidget');
            const _navigationConfiguration = require('settings/navigationConfiguration');
            const _router = require('businessServices/router/router');
            const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
            const _urlFormatter = require('common/url/urlFormatter');

            const _isLoginScreen = () => _router.activeInstruction().fragment === _navigationConfiguration.loginPageUrl;
            const _isEmailScreen = () => _router.activeInstruction().config.hash === _navigationConfiguration.routesById.signupEmail.url;
            
            let _disposables = [];

            let _onForwardArrowClicked = () => _promiseFactory.deferIndefinitely(promise => promise.resolve(false));

            self.isLoginOrEmailScreen = ko.computed(() => {
                return _isLoginScreen() || _isEmailScreen();
            });
            self.isNavigateToPreviousPageVisible = ko.observable(true);
            self.isNavigateToNextPageVisible = ko.observable(true);
            self.isNavigateToNextPageActive = ko.observable(false);
            self.onLogInClicked = () => {
                _router.navigate(_navigationConfiguration.loginPageUrl);
            };
            self.statusIndicatorUnderBars = ko.observableArray([]);
            self.statusIndicatorIconObjects = ko.observableArray([]);
            self.isPageIndicatorVisible = ko.observable(false);
            self.agreeToTerms = _i18n.t("signupPaymentMethod:agreeToTerms");
            self.agreeToTermsUrl = _urlFormatter.buildFrontendUrl("/terms");
            self.isAgreeToTermsVisible = false;
            self.isFooterVisible = ko.observable(true);
            self.isFooterCaptionVisible = ko.observable(false);
            self.isScrollActive = ko.observable(false);
            self.isSpinning = ko.observable(false);
            self.flow = ko.observable();
            self.flowBFooterText = ko.observable('');
            self.routeId = ko.observable();

            self.isFlowBFooterTextVisible = ko.computed(() => self.flow() === _signupSessionFlowConstants.flowB && !self.isMobile());
            self.isMobile = ko.computed(() => _browserType.windowWidth() <= 960 || _browserType.windowHeight() <= 800);
            self.isMobileFlowAEmail = ko.computed(() => self.flow() === _signupSessionFlowConstants.flowA &&
                _signupNavigationManager.isFlowStartPage() &&
                self.isMobile());

            self.navigateToFlowD = () => {
                _signupNavigationManager.navigateToRoute(_navigationConfiguration.routesById.signupPhoneNumberType.routeId, _signupSessionFlowConstants.flowD);
            };

            self.navigateToPreviousPage = () => {
                _signupNavigationManager.previousPage();
            };

            let _navigationClicked = true;
            self.navigateToNextPage = () => {
                return _promiseFactory.defer((deferredObject) => {
                    if (_navigationClicked) {
                        deferredObject.resolve();
                    } else {
                        _navigationClicked = true;
                        self.isSpinning(true);
                        const minimumSpinTime = 1000 + new Date().getTime();
                        _onForwardArrowClicked()
                            .fail(deferredObject.reject)
                            .done(shouldNavigate => {
                                if (shouldNavigate) {
                                    setTimeout(() => {
                                        _onForwardArrowClicked = () => _promiseFactory.deferIndefinitely(promise => promise.resolve(false));
                                        _signupNavigationManager.nextPage();
                                        deferredObject.resolve();
                                    }, minimumSpinTime - new Date().getTime());
                                } else {
                                    _navigationClicked = false;
                                    self.isSpinning(false);
                                    deferredObject.resolve();
                                }
                            });
                    }
                });
            };

            self.detached = function() {
                _signupNavigationManager.detached();
                _disposables.forEach(subscription => subscription.dispose());
                _disposables = [];
            };

            self.activate = function(data) {
                const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
                _signupSessionStore = new SignupSessionStoreConstructor();
                _signupSessionStore.init();

                const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
                _signupNavigationManager = new SignupNavigationManagerConstructor();
                _signupNavigationManager.init();

                self.flow(_signupNavigationManager.getFlowId());
                self.routeId(_signupNavigationManager.getRouteId());

                const setSelfValues = (element) => {
                    const dataElement = data[element];
                    if (dataElement !== undefined) {
                        const selfElement = self[element];
                        if (ko.isObservable(dataElement)) {
                            selfElement(dataElement());
                            _disposables.push(dataElement.subscribe(selfElement));
                        } else {
                            selfElement(dataElement);
                        }
                    }
                };
                setSelfValues('isNavigateToNextPageActive');
                setSelfValues('isFooterVisible');
                setSelfValues('isFooterCaptionVisible');

                if (data.isNavigateToPreviousPageVisible) {
                    self.isNavigateToPreviousPageVisible = data.isNavigateToPreviousPageVisible;
                } else {
                    self.isNavigateToPreviousPageVisible(_signupNavigationManager.isPreviousPageValid());
                }

                if (data.isNavigateToNextPageVisible !== undefined) {
                    setSelfValues('isNavigateToNextPageVisible');
                } else {
                    self.isNavigateToNextPageVisible(_signupNavigationManager.isNextPageValid());
                }

                if (data.onForwardArrowClicked) {
                    _onForwardArrowClicked = data.onForwardArrowClicked;
                }

                if (data.onBackArrowClicked) {
                    self.navigateToPreviousPage = data.onBackArrowClicked;
                }

                if (self.routeId() === _navigationConfiguration.routesById.signupPaymentMethod.routeId) {
                    self.isAgreeToTermsVisible = true;
                }

                if (self.flow() === _signupSessionFlowConstants.flowB) {
                    switch (self.routeId()) {
                        case _navigationConfiguration.routesById.signupPhoneNumberLocal.routeId:
                            self.flowBFooterText('flowBFooterLocalText');
                            break;
                        case _navigationConfiguration.routesById.signupPhoneNumberTollfree.routeId:
                            self.flowBFooterText('flowBFooterTollFreeText');
                            break;
                        case _navigationConfiguration.routesById.signupPhoneNumberPort.routeId:
                            self.flowBFooterText('flowBFooterPortText');
                            break;
                        default:
                            break;
                    }
                }
                return _initialize();
            };

            const _initialize = () => {
                _indicatorWidget.setIndicator(self.statusIndicatorUnderBars, self.statusIndicatorIconObjects, self.isPageIndicatorVisible);
                _navigationClicked = false;
                _promiseFactory.wait();
            };
        };
    });

