define('persistence/dataProviders/userDataProvider',[
    'persistence/webSocket/webSocketApp',
    'common/promises/promiseFactory'
], function() {

    return function() {
        var self = this;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        var _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_socket");

        var _websocketAppValidator = new WebSocketAppConstructor();
        _websocketAppValidator.init("validation");

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function () {
            return _promiseFactory.deferIndefinitely(function (promise) {
                var socketParams = {};

                _webSocketApp.send("get_all_users", socketParams, function (results) {
                    promise.resolve(results);
                });
            });
        };

        self.getById = function (userId) {
            if (userId === null) {
                throw new Error("Missing userId");
            }

            return _promiseFactory.deferIndefinitely(function (promise) {

                var socketParams = {
                    userId: userId
                };

                _webSocketApp.send("get_a_user", socketParams, function (results) {
                    promise.resolve(results);
                });
            });
        };

        self.getAvailableSubscribers = function () {
            return _promiseFactory.deferIndefinitely(function (promise) {
                var socketParams = {};

                _webSocketApp.send("get_available_subscribers", socketParams, function (results) {
                    promise.resolve(results);
                });
            });
        };

        self.isEmailAddressUnique = function(emailAddress) {
            return _promiseFactory.deferIndefinitely(function(deferredObject) {
                var webSocketParameters = {emailAddress: emailAddress};
                _websocketAppValidator.send("is_email_address_unique", webSocketParameters, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.isValidUser = function (user) {
            return _promiseFactory.deferIndefinitely(function (promise) {
                var webSocketParameters = {'user': user};
                _webSocketApp.send("is_valid_user", webSocketParameters, function (result) {
                    promise.resolve(result);
                });
            });
        };
    };
});

