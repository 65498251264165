define('constants/deliveryStatus',[],() => {
    return Object.freeze({
        // Unknown
        Unknown: 0,
        // We have successfully received this message
        Received: 1,
        // The message has been sent, but delivery has not been confirmed
        Sent: 2,
        // The message has been sent, and we have confirmed delivery
        Delivered: 3,
        // The message has failed to be sent
        Failed: 4,
        // The campaign is currently limited; the message is waiting in queue to be sent
        QueuedForSend: 5,
        // The campaign is not limited; the message has been added to the sending queue
        Sending: 6,
        // The message in a sending state waiting for its attachments to process
        WaitingForAttachments: 7,
        // The message cannot be sent because it exceeds carrier limits or no attachments succeeded. Re-sending will not succeed.
        FailedNoRetry: 8
    });
});
