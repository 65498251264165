define('presentation/inbox/viewModels/inboxVoicemailViewModel',['businessServices/contacts/contactsStateSingleton',
        'businessServices/events/eventManager',
        'common/collections/collectionFilter',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'common/time/datetimeFormatter',
        'constants/scrollEventSources',
        'constants/voicemailMessageConstants',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel',
        'presentation/common/voicemail/voicemailAudioFactory',
        'presentation/inbox/facades/inboxFacade'
    ],
    function (
        /** @type import('businessServices/contacts/contactsStateSingleton') */
        _contactsState
    ){
        const DateTimeFormatterConstructor = require('common/time/datetimeFormatter');
        const _dateTimeFormatter = new DateTimeFormatterConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const DeleteCallMediaActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteCallMediaActionViewModel');
        const _actionModal = new ActionModalViewModelConstructor();

        const voicemailAudioFactory = require('presentation/common/voicemail/voicemailAudioFactory');
        const FilterConstructor = require('common/collections/collectionFilter');

        const _voicemailMessageConstants = require('constants/voicemailMessageConstants');
        const _commonState = require('common/storage/commonState');
        const _eventManager = require('businessServices/events/eventManager');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        let _facade = null;
        let _voicemailMessageContentPaneScrollEventId = null;

        let isScreenAlreadyInitialized = false;
        let isListInitialized = false;
        let sidebar = null;
        let spinEndTime = null;

        const MINIMUM_ITEM_COUNT = 10;
        const DELETE_VOICEMAIL_RESPONSE = "yes";
        const _state = {
            pageCount: 0,
            voicemailMessagesShown: 0,
            availableVoicemailMessages: [],
            isLastPage: false,
            PAGE_ITEM_COUNT: 150,
            ITEMS_PER_PAGE_DISPLAYED: 20
        };

        const parentViewModel = ko.observable();

        const scrollEventSource = ScrollEventSources.inboxVoicemails;

        const debounceEvent = (callback, time) => {
            let interval;
            return (...args) => {
                clearTimeout(interval);
                interval = setTimeout(() => {
                    interval = null;
                    callback(...args);
                }, time);
            };
        };

        const formatPhoneNumber = (phoneNumber) => {
            return _phoneNumberFormatter.toInternationalWithParens(phoneNumber);
        };

        const isDataLoading = ko.observable(true);
        const showEmptyState = ko.computed(() => {
            if (isDataLoading() === false) {
                if (messages().length === 0) {
                    return true;
                }
            }
            return false;
        });

        const anyVoicemailMessages = ko.observable(true);
        const emptyStateContentKey = ko.pureComputed(() => anyVoicemailMessages() ?
            "noResults" :
            "noVoicemail");
        const messages = ko.observableArray([]);

        const prepareMessageForDisplay = (originalMessage, voicemailBoxes) => {
            let messageStatus = ko.observable(_voicemailMessageConstants.status.new.name);
            switch (originalMessage.status) {
                case _voicemailMessageConstants.status.new.id:
                    messageStatus(_voicemailMessageConstants.status.new.name);
                    break;
                case _voicemailMessageConstants.status.reviewed.id:
                    messageStatus(_voicemailMessageConstants.status.reviewed.name);
                    break;
            }
            let isMessageStatusNew = ko.pureComputed(() => {
                return (messageStatus() === _voicemailMessageConstants.status.new.name);
            });
            let i18nText = ko.pureComputed(() => {
                return {key: 'isNew', options: {context: messageStatus().toLowerCase()}};
            });

            let callerAniDisplay;
            let callerAvatar = null;
            let callerInitials = null;
            let callerType = 'external';
            let showLocation = false;
            let fromLocation = null;

            switch (originalMessage.callType) {
                case 1: // Internal
                    const originatingUser = _commonState.get(originalMessage.originatingUserId);
                    if (originatingUser) {
                        callerAvatar = originatingUser.avatar;
                        callerAniDisplay = originatingUser.name;
                        callerType = _commonState.types.user;
                    }
                    break;
                default: // 2=External, 3=Unknown

                    const contact = _contactsState.getContactByPhoneNumber(originalMessage.callerAni);
                    if (contact) {
                        callerAvatar = contact.avatarUrl;
                        callerAniDisplay = contact.displayName;
                        callerInitials = contact.initials;
                        callerType = _commonState.types.contact;

                        const matchingNumber = contact.phoneNumbers().find(x => _phoneNumberFormatter.toEOneSixFour(x.rawPhoneNumber()) === originalMessage.callerAni);
                        fromLocation = matchingNumber ? matchingNumber.label : null;
                    } else {
                        callerAniDisplay = formatPhoneNumber(originalMessage.callerAni);
                        showLocation = true;
                    }

                    break;
            }

            let voicemailBox = voicemailBoxes.find((box) => {
                return box.id === originalMessage.voicemailBoxId;
            });

            let ownerUserAvatar = null;
            let ownerUserGroupId = null;
            let ownerType;
            let voicemailBoxName;

            let ownerId = voicemailBox.ownerId();
            if (ownerId) {
                let owner = _commonState.get(ownerId);
                switch (owner.type) {
                    case _commonState.types.user:
                        ownerType = "user";
                        ownerUserAvatar = owner.avatar;
                        voicemailBoxName = owner.name;
                        break;
                    case _commonState.types.userGroup:
                        ownerType = "userGroup";
                        ownerUserGroupId = ownerId;
                        voicemailBoxName = owner.name;
                        break;
                    default:
                        ownerType = "unknown";
                        voicemailBoxName = ko.observable("Unknown");
                        break;
                }
            } else {
                ownerType = "standalone";
                voicemailBoxName = voicemailBox.name;
            }

            if (showLocation) {
                fromLocation = _phoneNumberFormatter.buildLocation(originalMessage.callerAni, originalMessage.callerLocationCity, originalMessage.callerLocationState);
            }

            let durationInSeconds = Math.floor(originalMessage.durationInMilliseconds / 1000);

            let voicemailAudio = ko.observable(voicemailAudioFactory.getEmptyVoicemail());

            return {
                voicemailMessageId: originalMessage.voicemailMessageId,

                status: messageStatus,
                isStatusNew: isMessageStatusNew,

                callerAvatar: callerAvatar,
                callerAniDisplay: callerAniDisplay,
                callerInitials: callerInitials,
                callerType: callerType,
                callHistoryId: originalMessage.callHistoryId,
                fromLocation: fromLocation,

                ownerType: ownerType,
                ownerUserAvatar: ownerUserAvatar,
                ownerUserGroupId: ownerUserGroupId,

                voicemailBoxName: voicemailBoxName,
                voicemailBoxId: originalMessage.voicemailBoxId,
                messageLeftDateTime: originalMessage.createdDateTime,
                date: _dateTimeFormatter.humanizedDay(originalMessage.createdDateTime),
                durationInSeconds: durationInSeconds,
                voicemailAudio: voicemailAudio,
                isVoicemailAudioLoaded: false,

                isExpanded: ko.observable(false),
                isReady: ko.observable(false),
                isStatusChanging: ko.observable(true),
                textI18n: i18nText,

                callDetails: ko.observable(null),
            };
        };

        const removeVoicemailFromUi = (voicemailMessageId) => {
            messages.remove((item) => {
                return item.voicemailMessageId === voicemailMessageId;
            });
        };

        const setVoicemailUiStatus = (voicemailMessageId, newStatus) => {
            let allMessages = messages();
            let message = allMessages.find((m) => {
                return m.voicemailMessageId === voicemailMessageId;
            });

            if (message) {
                message.status(newStatus);
                message.isStatusChanging(false);
            }
        };

        const setMessageStatus = (message, newStatus) => {
            return _promiseFactory.defer((promise) => {
                if (message.status() === newStatus) {
                    message.isStatusChanging(false);
                    promise.resolve();
                    return;
                }
                message.isStatusChanging(true);

                _facade.setVoicemailMessageStatus(message.voicemailMessageId, newStatus)
                    .fail(promise.reject)
                    .done(() => {
                        setVoicemailUiStatus(message.voicemailMessageId, newStatus);
                        promise.resolve();
                    });
            });
        };

        const onVoicemailMessageStatusChanged = (voicemailBoxId, voicemailMessageId, newStatus) => {
            let actualStatus = _voicemailMessageConstants.status.new.name;
            switch (newStatus) {
                case _voicemailMessageConstants.status.new.id:
                    actualStatus = _voicemailMessageConstants.status.new.name;
                    break;
                case _voicemailMessageConstants.status.reviewed.id:
                    actualStatus = _voicemailMessageConstants.status.reviewed.name;
                    break;
            }

            setVoicemailUiStatus(voicemailMessageId, actualStatus);
        };

        const onNewVoicemailMessage = (voicemailMessage) => {
            const isMessageNotAlreadyPresent = messages().every(message => message.voicemailMessageId !== voicemailMessage.voicemailMessageId);
            if (isMessageNotAlreadyPresent) {
                const searchOptions = sidebar.getSearchOptions();

                _facade.getFilteredVoicemailMessage(voicemailMessage.voicemailMessageId, searchOptions)
                    .done(({hasVoicemailMessage, foundVoicemailMessage}) => {
                        if (hasVoicemailMessage) {
                            const preparedVoicemailMessage = prepareMessageForDisplay(foundVoicemailMessage, _commonState.voicemailBoxes());
                            const {searchText} = searchOptions;
                            const voicemailMessageExistsInFilter = searchFilterMessageListing(searchText, [preparedVoicemailMessage]).length > 0;
                            if (voicemailMessageExistsInFilter) {
                                messages.unshift(prepareMessageForDisplay(voicemailMessage, _commonState.voicemailBoxes()));
                            }
                        }
                    });
            }
        };

        const onSubscriberSettingsChanged = (eventData) => {
            const {voicemailBoxId, isActiveSubscriber, isShowInSidebarEnabled} = eventData;
            const areVoicemailBoxMessagesPresent = messages().some(message => message.voicemailBoxId === voicemailBoxId);
            if (isActiveSubscriber === true)
            {
                if (isShowInSidebarEnabled === true) {
                    if (areVoicemailBoxMessagesPresent === false) {
                        refreshMessageListing();
                    }
                }
                else
                {
                    if (areVoicemailBoxMessagesPresent === true) {
                        refreshMessageListing();
                    }
                }
            }
            else
            {
                if (areVoicemailBoxMessagesPresent === true) {
                    refreshMessageListing();
                }
            }
        };

        const onVoicemailBoxDeleted = (eventData) => {
            const {voicemailBoxId} = eventData;

            const areVoicemailBoxMessagesPresent = messages().some(message => message.voicemailBoxId === voicemailBoxId);
            if (areVoicemailBoxMessagesPresent) {
                refreshMessageListing();
            }
        };

        const onVoicemailBoxReactivated = (eventData) => {
            const {voicemailBoxId} = eventData;

            const areVoicemailBoxMessagesPresent = messages().some(message => message.voicemailBoxId === voicemailBoxId);
            if (areVoicemailBoxMessagesPresent === false) {
                refreshMessageListing();
            }
        };

        const onVoicemailMessageDeleted = (voicemailMessage) => {
            removeVoicemailFromUi(voicemailMessage.voicemailMessageId);
        };

        const onSearchTextChanged = () => {
            refreshMessageListing();
        };

        const refreshMessageListing = () => {
            return _promiseFactory.defer((promise) => {
                spinEndTime = 1500 + new Date().getTime();
                isDataLoading(true);

                _state.pageCount = 0;

                if (sidebar === null) {
                    promise.resolve();
                    return;
                }

                const searchOptions = sidebar.getSearchOptions();
                const {startDate, endDate, searchText, searchStatus, selectedVoicemailBoxIds} = searchOptions;
                const pageCount = _state.pageCount;
                const itemCount = _state.PAGE_ITEM_COUNT;

                _facade.getVoicemailMessages({startDate, endDate, searchStatus, selectedVoicemailBoxIds, pageCount, itemCount})
                    .fail(promise.reject)
                    .done((foundMessages) => {
                        const voicemailBoxes = _commonState.voicemailBoxes();

                        _state.isLastPage = foundMessages.length === 0;

                        if (_state.isLastPage) {
                            _state.availableVoicemailMessages = [];
                            setMessages();

                            _facade.checkAnyVoicemailMessages()
                                .fail(promise.reject)
                                .done(anyMessages => {
                                    anyVoicemailMessages(anyMessages);
                                    promise.resolve();
                                });
                        } else {
                            const displayMessages = foundMessages.map((message) => {
                                return prepareMessageForDisplay(message, voicemailBoxes);
                            });
                            _state.availableVoicemailMessages = searchFilterMessageListing(searchText, displayMessages);

                            if (_state.availableVoicemailMessages.length < MINIMUM_ITEM_COUNT) {
                                getNextVoicemailMessagesFromDatabase()
                                    .fail(promise.reject)
                                    .done(() => {
                                        setMessages();
                                        promise.resolve();
                                    });
                            } else {
                                setMessages();
                                promise.resolve();
                            }
                        }
                    });

            });
        };

        const setMessages = () => {
            setTimeout(() => {
                _state.voicemailMessagesShown = _state.ITEMS_PER_PAGE_DISPLAYED;
                messages(_state.availableVoicemailMessages.slice(0, _state.voicemailMessagesShown));
                isDataLoading(false);
                if (_voicemailMessageContentPaneScrollEventId === null){
                    _voicemailMessageContentPaneScrollEventId = _eventManager.subscribeBottomScrollBufferReached(getNextDataGridEvent);
                }
            }, spinEndTime - new Date().getTime());
        };

        const getNextVoicemailMessages = (eventSource) => {
            if (eventSource !== scrollEventSource) {
                return;
            }

            return _promiseFactory.defer((promise) => {
                if (sidebar && _state.isLastPage === false) {
                    const additionalVoicemailMessagesShown = _state.voicemailMessagesShown + _state.ITEMS_PER_PAGE_DISPLAYED;
                    const nextAvailableVoicemailMessages = _state.availableVoicemailMessages.slice(_state.voicemailMessagesShown, additionalVoicemailMessagesShown);
                    messages.push.apply(messages, nextAvailableVoicemailMessages);
                    _state.voicemailMessagesShown = additionalVoicemailMessagesShown;

                    if (_state.voicemailMessagesShown >= _state.availableVoicemailMessages.length) {
                        getNextVoicemailMessagesFromDatabase()
                            .fail(promise.reject)
                            .done(promise.resolve);
                    } else {
                        promise.resolve();
                    }
                } else {
                    promise.resolve();
                }
            });
        };

        const getNextVoicemailMessagesFromDatabase = () => {
            return _promiseFactory.defer((promise) => {
                _state.pageCount++;
                _state.voicemailMessagesShown = _state.availableVoicemailMessages.length;

                const searchOptions = sidebar.getSearchOptions();
                const {startDate, endDate, searchText, searchStatus, selectedVoicemailBoxIds} = searchOptions;
                const pageCount = _state.pageCount;
                const itemCount = _state.PAGE_ITEM_COUNT;

                _facade.getVoicemailMessages({startDate, endDate, searchStatus, selectedVoicemailBoxIds, pageCount, itemCount})
                    .fail(promise.reject)
                    .done((foundMessages) => {
                        _state.isLastPage = foundMessages.length === 0;

                        const voicemailBoxes = _commonState.voicemailBoxes();

                        const displayMessages = foundMessages.map((message) => {
                            return prepareMessageForDisplay(message, voicemailBoxes);
                        });
                        const searchFilteredMessages = searchFilterMessageListing(searchText, displayMessages);
                        _state.availableVoicemailMessages.push.apply(_state.availableVoicemailMessages, searchFilteredMessages);

                        if (_state.availableVoicemailMessages.length < MINIMUM_ITEM_COUNT && _state.isLastPage === false) {
                            getNextVoicemailMessagesFromDatabase()
                                .fail(promise.reject)
                                .done(promise.resolve);
                        } else {
                            promise.resolve();
                        }
                    });
            });
        };

        const getNextDataGridEvent = debounceEvent(getNextVoicemailMessages, 100);
        const searchTextChanged = debounceEvent(onSearchTextChanged, 750);

        const searchFilterMessageListing = (searchText, availableMessages) => {
            const voicemailMessageSearchTextFilter = new FilterConstructor();
            voicemailMessageSearchTextFilter
                .addProperty("callerAniDisplay")
                .addProperty("fromLocation")
                .addProperty("messageLeftDateTimeDisplay")
                .addProperty("status")
                .addProperty("toLocation")
                .addProperty("voicemailBoxName")
                .addProperty("voicemailBoxTypeDisplayName")
                .addValue(searchText);
            return voicemailMessageSearchTextFilter.filter(availableMessages);
        };

        const toggleMessageStatus = (message) => {
            return _promiseFactory.defer((promise) => {
                const currentStatus = message.status();

                let newStatus;
                if (currentStatus === _voicemailMessageConstants.status.new.name) {
                    newStatus = _voicemailMessageConstants.status.reviewed.name;
                } else {
                    newStatus = _voicemailMessageConstants.status.new.name;
                }

                setMessageStatus(message, newStatus)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const playVoicemail = (message) => {
            return _promiseFactory.defer((promise) => {
                if (message.isVoicemailAudioLoaded === false) {
                    _facade.getVoicemailDownloadUrl(message.voicemailMessageId)
                        .fail(promise.reject)
                        .done((url) => {
                            let newAudio = voicemailAudioFactory.playVoicemail(url);
                            message.voicemailAudio(newAudio);
                            message.isVoicemailAudioLoaded = true;

                            setMessageStatus(message, _voicemailMessageConstants.status.reviewed.name)
                                .fail(promise.reject)
                                .done(promise.resolve);

                        });
                } else {
                    // Force the audio to play again.
                    message.voicemailAudio.valueHasMutated();

                    setMessageStatus(message, _voicemailMessageConstants.status.reviewed.name)
                        .fail(promise.reject)
                        .done(promise.resolve);
                }

            });
        };

        const getVoicemailUrl = (message) => {
            return _promiseFactory.defer((promise) => {
                _facade.getVoicemailDownloadUrl(message.voicemailMessageId)
                    .fail(promise.reject)
                    .done((url) => {
                        promise.resolve(url);
                    });
            });
        };

        const deleteVoicemail = (message) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let voicemailMessageId = message.voicemailMessageId;
                let modalConstructorParams = [message, _facade.getVoicemailDownloadUrl, setMessageStatus];

                _actionModal
                    .clearModal()
                    .setHeaderText({i18n: 'deleteConfirmation'})
                    .setSubmitButtonText({i18n: 'delete'})
                    .setContentViewModel(DeleteCallMediaActionViewModelConstructor, modalConstructorParams)
                    .showModal()
                    .then((result => {
                        if (result === DELETE_VOICEMAIL_RESPONSE) {
                            _facade.deleteVoicemailMessage(voicemailMessageId)
                                .fail(promise.reject)
                                .done(() => {
                                    removeVoicemailFromUi(voicemailMessageId);
                                    promise.resolve();
                                });
                        }
                    }));
            });
        };

        const buildExpandedCallDetailItem = (callDetailItem) => {
            _facade.getCallDetails(callDetailItem.callHistoryId)
                .done(callDetails => {
                    callDetails.callId = callDetailItem.callHistoryId;

                    const callerLocation = callDetails.callerLocation;
                    const calleeLocation = callDetails.calleeLocation;
                    callDetails.shouldDisplayMap = callDetails.mapUrl !== null;
                    callDetails.voicemail.subscriberIds = ko.observable(callDetails.voicemail.subscriberIds);
                    callDetails.voicemail.isDeleted = ko.observable(callDetails.voicemail.voicemailIsDeleted);

                    callerLocation.cityState = _phoneNumberFormatter.cleanupLocation(callerLocation.cityState);
                    calleeLocation.cityState = _phoneNumberFormatter.cleanupLocation(calleeLocation.cityState);

                    callDetailItem.callDetails(callDetails);

                    callDetailItem.isReady(true);
                    callDetailItem.isStatusChanging(false);
                    return callDetailItem;
                });
        };

        const expandCallDetails = (callDetailItem) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                callDetailItem.isExpanded(!callDetailItem.isExpanded());
                callDetailItem.isStatusChanging(true);

                if (callDetailItem.callDetails() === null) {
                    buildExpandedCallDetailItem(callDetailItem);
                }

                if (callDetailItem.isExpanded() === true &&
                    callDetailItem.callHistoryId !== null) {
                    setMessageStatus(callDetailItem, _voicemailMessageConstants.status.reviewed.name)
                        .fail(promise.reject)
                        .done(promise.resolve);
                }
            });
        };

        const showMessage = (elem) => {
            if (!isListInitialized) {
                return;
            }

            if (elem.nodeType === 1) {
                $(elem).hide().slideDown().fadeIn("normal");
            }
        };

        const renderComplete = () => {
            isListInitialized = true;
        };

        const _initializeScreen = () => {
            if (isScreenAlreadyInitialized) {
                return _promiseFactory.wait();
            }
            isScreenAlreadyInitialized = true;

            const FacadeConstructor = require('presentation/inbox/facades/inboxFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            _facade.onVoicemailMessageStatusChanged(onVoicemailMessageStatusChanged);
            _facade.onNewVoicemailMessage(onNewVoicemailMessage);
            _facade.onVoicemailSubscriberSettingsChanged(onSubscriberSettingsChanged);
            _facade.onVoicemailBoxDeleted(onVoicemailBoxDeleted);
            _facade.onVoicemailBoxReactivated(onVoicemailBoxReactivated);
            _facade.onVoicemailMessageDeleted(onVoicemailMessageDeleted);

            refreshMessageListing();
            return _promiseFactory.wait();
        };

        const _detachScreen = () => {
            isScreenAlreadyInitialized = false;
            _eventManager.unsubscribe(_voicemailMessageContentPaneScrollEventId);
            _voicemailMessageContentPaneScrollEventId = null;
            _facade.disposeVoicemail();
        };

        const setSidebar = (mySidebar) => {
            sidebar = mySidebar;
        };


        return {
            refreshMessageListing,
            searchTextChanged,
            getVoicemailUrl,
            playVoicemail,
            deleteVoicemail,
            expandCallDetails,
            toggleMessageStatus,
            showMessage,
            renderComplete,

            isDataLoading,
            showEmptyState,

            emptyStateContentKey,
            messages,

            setSidebar,

            parentViewModel,
            scrollEventSource,

            // Durandal events:
            activate : _initializeScreen,
            detached: _detachScreen,
        };
    });

