define('presentation/settings/forwardingNumbers/viewModels/forwardingNumbersViewModel',[
    'businessServices/events/eventManager',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/storage/commonState',
    'common/promises/promiseFactory',
    'constants/scrollEventSources',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/deleteActionViewModel',
    'presentation/common/warningModal/viewModels/integrityWarningModalViewModel',
    'presentation/settings/forwardingNumbers/facades/forwardingNumbersFacade',
    'settings/navigationConfiguration'
], function(){
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const DeleteActionViewModelConstructor = require('presentation/common/actionModal/viewModels/deleteActionViewModel');
        const FilterConstructor = require('common/collections/collectionFilter');
        const SorterConstructor = require('common/collections/collectionSorter');

        /** @type typeof import('constants/scrollEventSources') */
        const ScrollEventSources = require('constants/scrollEventSources');

        const _commonState = require('common/storage/commonState');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _disposables = [];
        let _eventManager = null;
        let _facade = null;
        let _getNextBatchCalled = false;
        let _getNextBatchOfForwardingNumbersEventId = null;

        const ROWS_PER_PAGE = 25;
        const DELETE_RESPONSE = "yes";

        let _state = {
            availableForwardingNumbers: ko.observableArray(null),
            filteredForwardingNumbers: null,
            forwardingNumbersShown: 0
        };

        const _showIntegrityWarningsPrompt = (forwardingNumberId, integrityWarningPresentationObjects) => {
            _buildIntegrityWarningsModal(forwardingNumberId);
            self.integrityWarningModal.addIntegrityWarnings(integrityWarningPresentationObjects);
            self.integrityWarningModal.showModal();
        };

        const _showDeletePrompt = (forwardingNumbersPresentationObject) => {
            const forwardingNumberId = forwardingNumbersPresentationObject.forwardingNumberId;
            _buildDeleteModal(forwardingNumberId);
            self.actionModal.showModal()
                .done((result) => {
                    if (result === DELETE_RESPONSE) {
                        _facade.deleteForwardingNumber(forwardingNumberId)
                            .done(() => {
                                self.displayForwardingNumbers.remove(forwardingNumbersPresentationObject);

                                _state.filteredForwardingNumbers = _state.filteredForwardingNumbers.filter((forwardingNumber) => {
                                    return forwardingNumber.forwardingNumberId !== forwardingNumberId;
                                });

                                _state.availableForwardingNumbers(_state.availableForwardingNumbers().filter((forwardingNumber) => {
                                    return forwardingNumber.forwardingNumberId !== forwardingNumberId;
                                }));

                                if (self.displayForwardingNumbers().length < ROWS_PER_PAGE) {
                                    _getNextBatchOfForwardingNumbers();
                                }
                            })
                            .fail((error) => {
                                if (error.constraintViolations && error.constraintViolations.length > 0) {
                                    _showIntegrityWarningsPrompt(forwardingNumberId, error.constraintViolations);
                                } else {
                                    error.forwardingNumberId = forwardingNumberId;
                                    throw error;
                                }
                            });
                    }
                })
                .fail((error) => {
                    throw error;
                });

        };

        const _updateDisplayedForwardingNumbers = () => {
            let filteredForwardingNumbers;
            let filterContent = self.forwardingNumberFilter();
            if (filterContent !== '') {
                let forwardingNumbersFilter = new FilterConstructor();
                forwardingNumbersFilter
                    .addProperty('forwardingNumberName')
                    .addProperty('phoneNumber')
                    .addProperty('countryName')
                    .addProperty('modifiedDateTime.filterValue')
                    .addProperty('createdDateTime.filterValue')
                    .addValue(filterContent);
                filteredForwardingNumbers = forwardingNumbersFilter.filter(_state.availableForwardingNumbers());
            }
            else {
                filteredForwardingNumbers = _state.availableForwardingNumbers();
            }
            _state.filteredForwardingNumbers = filteredForwardingNumbers;
        };

        const _onForwardingNumberFilterChanged = () => {
            _updateDisplayedForwardingNumbers();
            self.lastPage = false;
            _state.forwardingNumbersShown = ROWS_PER_PAGE;
            self.displayForwardingNumbers(_state.filteredForwardingNumbers.slice(0, _state.forwardingNumbersShown));
        };

        const _refreshForwardingNumbers = () => {
            _facade.findAllForwardingNumbers()
                .done((forwardingNumbers) => {
                    _state.availableForwardingNumbers(forwardingNumbers);
                    _state.filteredForwardingNumbers = forwardingNumbers;
                    _state.forwardingNumbersShown = ROWS_PER_PAGE;
                    self.displayForwardingNumbers(_state.filteredForwardingNumbers.slice(0, _state.forwardingNumbersShown));
                    if (_getNextBatchOfForwardingNumbersEventId === null) {
                        _getNextBatchOfForwardingNumbersEventId = _eventManager.subscribeBottomScrollBufferReached(_getNextBatchOfForwardingNumbers);
                    }
                });
        };

        const _getNextBatchOfForwardingNumbers = (eventSource) => {
            if (eventSource !== self.scrollEventSource) {
                return;
            }

            if (self.lastPage === false && _getNextBatchCalled === false) {
                _getNextBatchCalled = true;
                let additionalForwardingNumbersShown = _state.forwardingNumbersShown + ROWS_PER_PAGE;
                let additionalForwardingNumbers = _state.filteredForwardingNumbers.slice(_state.forwardingNumbersShown, additionalForwardingNumbersShown);
                self.displayForwardingNumbers.push.apply(self.displayForwardingNumbers, additionalForwardingNumbers);
                _state.forwardingNumbersShown = additionalForwardingNumbersShown;
                if(_state.forwardingNumbersShown >= _state.filteredForwardingNumbers.length) {
                    self.lastPage = true;
                }
                _getNextBatchCalled = false;
            }
        };

        const _sortDisplayedForwardingNumbers = () => {
            let sorter = new SorterConstructor();
            if (sorter.setOptions(self.sortOptions(), self.sortType())) {
                sorter.sort(_state.availableForwardingNumbers);
                _updateDisplayedForwardingNumbers();
                self.lastPage = false;
                _state.forwardingNumbersShown = ROWS_PER_PAGE;
                self.displayForwardingNumbers(_state.filteredForwardingNumbers.slice(0, _state.forwardingNumbersShown));
            }
        };

        const _buildDeleteModal = (forwardingNumberId) => {
            const displayType = _commonState.typeDisplay.forwardingNumber;
            let constructorParams = [forwardingNumberId];
            self.actionModal
                .clearModal()
                .setContentViewModel(DeleteActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: {key: 'deleteHeader', options: {type: displayType}}})
                .setSubmitButtonText({i18n: 'delete'});
        };

        const _buildIntegrityWarningsModal = (forwardingNumberId) => {
            const displayType = _commonState.typeDisplay.forwardingNumber;
            self.integrityWarningModal
                .clearModal()
                .setHeading({key: 'delete', options: {type: displayType}})
                .addText({i18n: {key: 'inUse', options: {type: displayType.toLowerCase()}}})
                .setSystemObject({objectId: forwardingNumberId})
                .addIntegrityWarningText({i18n: {key: 'conflicts'}});
        };

        self.scrollEventSource = ScrollEventSources.settingsForwardingNumbers;
        self.actionModal = null;
        self.integrityWarningModal = null;
        self.lastPage = false;
        self.isCompositionComplete = ko.observable(false);
        self.displayForwardingNumbers = ko.observableArray();
        self.addForwardingNumberUrl = _navigationConfiguration.routesById.newForwardingNumber.url;

        self.sortType = ko.observable("forwardingNumberName");
        _disposables.push(self.sortType.subscribe(_sortDisplayedForwardingNumbers));
        self.sortOptions = ko.observableArray([
            {textI18n: "name", iconName: 'sortName', sortPath : "forwardingNumberName", isAscending : true},
            {textI18n: "recentlyAdded", iconName: 'sortRecentlyAdded', sortPath : "createdDateTime.sortValue", isAscending : false},
            {textI18n: "recentlyModified", iconName: 'sortRecentlyModified', sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.forwardingNumberFilter = ko.observable('');
        _disposables.push(self.forwardingNumberFilter.subscribe(_onForwardingNumberFilterChanged));

        self.showResults = ko.pureComputed(() => self.displayForwardingNumbers().length > 0);
        self.emptyStateContentKey = ko.pureComputed(() => _state.availableForwardingNumbers().length > 0 ?
            "forwardingNumbers:noResults" :
            "forwardingNumbers:noForwardingNumbers");

        self.launchDeleteForwardingNumber = (forwardingNumbersPresentationObject) => {
            const forwardingNumberId = forwardingNumbersPresentationObject.forwardingNumberId;
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.getConstraints(forwardingNumberId)
                    .done((integrityWarningPresentationObjects) => {
                        if (integrityWarningPresentationObjects.length > 0) {
                            _showIntegrityWarningsPrompt(forwardingNumberId, integrityWarningPresentationObjects);
                        } else {
                            _showDeletePrompt(forwardingNumbersPresentationObject);
                        }
                        deferredObject.resolve();
                    });
            });
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.detached = () => {
            _disposables.forEach(s => s.dispose);
            _disposables = [];
            _eventManager.unsubscribe(_getNextBatchOfForwardingNumbersEventId);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/forwardingNumbers/facades/forwardingNumbersFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            const WarningModalViewModelConstructor = require('presentation/common/warningModal/viewModels/integrityWarningModalViewModel');
            self.integrityWarningModal = new WarningModalViewModelConstructor();

            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };


        const _initialize = () => {
            _refreshForwardingNumbers();

            return _promiseFactory.wait();
        };
    };
});

