
define('businessServices/router/plugins/signupRouteAuthorizationPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/sessionAccountInfo',
    'common/converters/phoneNumberFormatter',
    'constants/signupSessionFlowConstants',
    'constants/signupSessionStatusConstants',
    'constants/signupNumberTypeSelectionConstants',
    'businessServices/signup/signupSessionStore'
], function () {
    const _navigationConfiguration = require('settings/navigationConfiguration');
    const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
    const _signupSessionFlowConstants = require('constants/signupSessionFlowConstants');
    const _signupSessionStatusConstants = require('constants/signupSessionStatusConstants');
    const _signupNumberTypeSelectionConstants =require('constants/signupNumberTypeSelectionConstants');

    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();
    const _signupSessionStore = new SignupSessionStoreConstructor();
    _signupSessionStore.init();

    const FLOW_A = _signupSessionFlowConstants.flowA;
    const FLOW_B = _signupSessionFlowConstants.flowB;
    const FLOW_C = _signupSessionFlowConstants.flowC;
    const FLOW_D = _signupSessionFlowConstants.flowD;

    const ROUTE__SIGNUP_EMAIL = _navigationConfiguration.routesById.signupEmail;
    const ROUTE__SIGNUP_PHONE_NUMBER_TYPE = _navigationConfiguration.routesById.signupPhoneNumberType;
    const ROUTE__SIGNUP_PHONE_NUMBER_LOCAL =  _navigationConfiguration.routesById.signupPhoneNumberLocal;
    const ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE =  _navigationConfiguration.routesById.signupPhoneNumberTollfree;
    const ROUTE__SIGNUP_PHONE_NUMBER_PORT =  _navigationConfiguration.routesById.signupPhoneNumberPort;
    const ROUTE__SIGNUP_PHONE_NUMBER_PORT_BILLING =  _navigationConfiguration.routesById.signupPhoneNumberPortBilling;
    const ROUTE__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION =  _navigationConfiguration.routesById.signupPhoneNumberPortAuthorization;
    const ROUTE__SIGNUP_PROFILE =  _navigationConfiguration.routesById.signupProfile;
    const ROUTE__SIGNUP_PAYMENT_METHOD =  _navigationConfiguration.routesById.signupPaymentMethod;
    const ROUTE__SIGNUP_SUCCESS =  _navigationConfiguration.routesById.signupSuccess;

    const FLOW_A_ROUTE_START = ROUTE__SIGNUP_EMAIL.url.replace(":flow", FLOW_A);
    const FLOW_B_ROUTE_START_LOCAL = ROUTE__SIGNUP_PHONE_NUMBER_LOCAL.url.replace(":flow", FLOW_B);
    const FLOW_B_ROUTE_START_TOLL_FREE = ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE.url.replace(":flow", FLOW_B);
    const FLOW_B_ROUTE_START_PORT_NUMBER = ROUTE__SIGNUP_PHONE_NUMBER_PORT.url.replace(":flow", FLOW_B);
    const FLOW_C_ROUTE_START = ROUTE__SIGNUP_EMAIL.url.replace(":flow", FLOW_C);
    const FLOW_D_ROUTE_START = ROUTE__SIGNUP_PHONE_NUMBER_TYPE.url.replace(":flow", FLOW_D);

    const IS_EMAIL_COMPLETED = "isEmailCompleted";
    const IS_PHONE_NUMBER_COMPLETED = "isPhoneNumberCompleted";
    const IS_PROFILE_COMPLETED = "isProfileCompleted";
    const IS_PAYMENT_METHOD_COMPLETED ="isPaymentMethodCompleted";

    const ARE_PRIOR_ROUTES_COMPLETED = {
        [FLOW_A]: {
            [ROUTE__SIGNUP_EMAIL.routeId]:[],
            [ROUTE__SIGNUP_PHONE_NUMBER_TYPE.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PHONE_NUMBER_LOCAL.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_BILLING.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PROFILE.routeId]: [IS_EMAIL_COMPLETED, IS_PHONE_NUMBER_COMPLETED],
            [ROUTE__SIGNUP_PAYMENT_METHOD.routeId]: [IS_EMAIL_COMPLETED, IS_PHONE_NUMBER_COMPLETED, IS_PROFILE_COMPLETED]
        },
        [FLOW_B]: {
            [ROUTE__SIGNUP_PHONE_NUMBER_TYPE.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_LOCAL.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_BILLING.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION.routeId]: [],
            [ROUTE__SIGNUP_EMAIL.routeId]:[IS_PHONE_NUMBER_COMPLETED],
            [ROUTE__SIGNUP_PROFILE.routeId]: [IS_PHONE_NUMBER_COMPLETED, IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PAYMENT_METHOD.routeId]: [IS_PHONE_NUMBER_COMPLETED, IS_EMAIL_COMPLETED, IS_PROFILE_COMPLETED]
        },
        [FLOW_C]: {
            [ROUTE__SIGNUP_PHONE_NUMBER_TYPE.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_LOCAL.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_BILLING.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION.routeId]: [],
            [ROUTE__SIGNUP_EMAIL.routeId]:[],
            [ROUTE__SIGNUP_PROFILE.routeId]: [IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PAYMENT_METHOD.routeId]: [IS_EMAIL_COMPLETED, IS_PROFILE_COMPLETED]
        },
        [FLOW_D]: {
            [ROUTE__SIGNUP_PHONE_NUMBER_TYPE.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_LOCAL.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_BILLING.routeId]: [],
            [ROUTE__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION.routeId]: [],
            [ROUTE__SIGNUP_EMAIL.routeId]:[IS_PHONE_NUMBER_COMPLETED],
            [ROUTE__SIGNUP_PROFILE.routeId]: [IS_PHONE_NUMBER_COMPLETED, IS_EMAIL_COMPLETED],
            [ROUTE__SIGNUP_PAYMENT_METHOD.routeId]: [IS_PHONE_NUMBER_COMPLETED, IS_EMAIL_COMPLETED, IS_PROFILE_COMPLETED]
        }
    };

    const ARE_SIGNUP_SUCCESS_PRIOR_ROUTES_COMPLETED = {
        [ROUTE__SIGNUP_SUCCESS.routeId]: [IS_PHONE_NUMBER_COMPLETED, IS_EMAIL_COMPLETED, IS_PROFILE_COMPLETED, IS_PAYMENT_METHOD_COMPLETED]
    };

    const SEARCH_TYPE_ENUMERATIONS = {
        any: "any",
        default: "default",
        repeat: "repeat",
        vanity: "vanity"
    };

    const routeToSignup = (routeId, routeInfo) => {
        switch (routeId) {
            case ROUTE__SIGNUP_EMAIL.routeId:
            case ROUTE__SIGNUP_PHONE_NUMBER_TYPE.routeId:
            case ROUTE__SIGNUP_PHONE_NUMBER_LOCAL.routeId:
            case ROUTE__SIGNUP_PHONE_NUMBER_TOLL_FREE.routeId:
            case ROUTE__SIGNUP_PHONE_NUMBER_PORT.routeId:
            case ROUTE__SIGNUP_PHONE_NUMBER_PORT_BILLING.routeId:
            case ROUTE__SIGNUP_PHONE_NUMBER_PORT_AUTHORIZATION.routeId:
            case ROUTE__SIGNUP_PROFILE.routeId:
            case ROUTE__SIGNUP_PAYMENT_METHOD.routeId:
            case ROUTE__SIGNUP_SUCCESS.routeId:
                break;
            default:
                return true;
        }

        if (_sessionAccountInfo.isLoggedIn() !== false) {
            return _newRoute(_navigationConfiguration.homePageUrl);
        }

        return _promiseFactory.defer((promise) => {
            _signupSessionStore.get()
                .fail(promise.reject)
                .done((signupDocument) => {
                    const isFlowStart = _isFlowStartPage(routeInfo.fragment);
                    const isValidSignupSession = _isSignupSessionStatusValid(signupDocument.signupSessionStatus);
                    const localPromise = new PromiseFactoryConstructor();

                    if (isValidSignupSession === false && routeId !== ROUTE__SIGNUP_SUCCESS.routeId) {
                        localPromise.defer((localDeferredObject) => {
                            if (isFlowStart){
                               _signupSessionStore.generateNewSignup()
                                   .fail(localDeferredObject.reject)
                                   .done((newSignupDocument) => {
                                       signupDocument = newSignupDocument;
                                       localDeferredObject.resolve();
                                   });
                           } else {
                               localDeferredObject.resolve();
                           }
                        });
                    }

                    localPromise.wait()
                        .fail(promise.reject)
                        .done(() => {
                            if (routeInfo.params.length > 0) {
                                const flowId = routeInfo.params[0];
                                if (_isFlowIdValid(flowId)) {
                                    if (_areQueryParametersValid(flowId, routeInfo, signupDocument.reservedPhoneNumber)) {
                                        if (routeId === ROUTE__SIGNUP_SUCCESS.routeId)  {
                                            promise.resolve(_navigateToSignupSuccessRoute(routeInfo,  signupDocument));
                                        } else if (isValidSignupSession === false && isFlowStart === false) {
                                            promise.resolve(_newRoute(_navigationConfiguration.loginPageUrl));
                                        } else {
                                            _navigateToValidSignupRoute(routeId, routeInfo, signupDocument)
                                                .fail(promise.reject)
                                                .done((result) => {
                                                    promise.resolve(result);
                                                });
                                        }
                                    } else {
                                        promise.resolve(_routeToDefaultFlow(FLOW_A));
                                    }
                                }
                            } else {
                                promise.resolve(_routeToDefaultFlow(signupDocument.flow, null, signupDocument.numberTypeSelection));
                            }
                        });
                });
        });
    };

    const _isSignupSessionStatusValid = (signupSessionStatus) => {
        switch (signupSessionStatus) {
            case _signupSessionStatusConstants.inProgress:
            case _signupSessionStatusConstants.initiated:
            case _signupSessionStatusConstants.validated:
            case _signupSessionStatusConstants.numberProvisioningFailed:
            case _signupSessionStatusConstants.numberProvisioningSucceeded:
            case _signupSessionStatusConstants.preAuthorizationFailed:
            case _signupSessionStatusConstants.preAuthorizationSucceeded:
                return true;
            default:
                return false;
        }
    };

    const _isFlowIdValid = (flowId) => {
        switch (flowId) {
            case FLOW_A:
            case FLOW_B:
            case FLOW_C:
            case FLOW_D:
                return true;
            default:
                return false;
        }
    };

    const _isFlowStartPage = (url) => {
        switch (url) {
            case FLOW_A_ROUTE_START:
            case FLOW_B_ROUTE_START_LOCAL:
            case FLOW_B_ROUTE_START_TOLL_FREE:
            case FLOW_B_ROUTE_START_PORT_NUMBER:
            case FLOW_C_ROUTE_START:
            case FLOW_D_ROUTE_START:
                return true;
            default:
                return false;
        }
    };

    const _areFlowCQueryParametersValid = (params) => {
        if (params === null) {
            return false;
        }

        switch (params.searchType) {
            case SEARCH_TYPE_ENUMERATIONS.any:
                return params.hasOwnProperty("phoneNumber") &&
                    params.hasOwnProperty("region") &&
                    params.hasOwnProperty("city");
            case SEARCH_TYPE_ENUMERATIONS.default:
                return params.hasOwnProperty("phoneNumber") &&
                    params.hasOwnProperty("region") &&
                    params.hasOwnProperty("city");
            case SEARCH_TYPE_ENUMERATIONS.repeat:
                return params.hasOwnProperty("phoneNumber") &&
                    params.hasOwnProperty("region");
            case SEARCH_TYPE_ENUMERATIONS.vanity:
                return params.hasOwnProperty("phoneNumber") &&
                    params.hasOwnProperty("vanityString");
            default:
                return false;
        }

    };

    const _areQueryParametersValid = (flowId, routeInfo, reservedPhoneNumber) => {
        let url = routeInfo.fragment;
        let params = routeInfo.queryParams;

        switch (flowId) {
            case FLOW_A:
            case FLOW_D:
                return true;
            case FLOW_B:
                if (url !== FLOW_B_ROUTE_START_PORT_NUMBER) {
                    return true;
                }

                if (params === null) {
                    return true;
                }

                return params.hasOwnProperty("phoneNumber") &&
                    params.hasOwnProperty("carrier") &&
                    params.hasOwnProperty("country");

            case FLOW_C:
                if (url !== FLOW_C_ROUTE_START) {
                    return true;
                }
                if (reservedPhoneNumber) {
                    return true;
                }

                return _areFlowCQueryParametersValid(params);

            default:
                return false;
        }
    };

    const _navigateToValidSignupRoute = (routeId, routeInfo, signupDocument) => {
        return _promiseFactory.defer((promise) => {
            const flowId = routeInfo.params[0];
            if (flowId === signupDocument.flow || signupDocument.flow === null) {
                const localPromise = new PromiseFactoryConstructor();

                if (signupDocument.flow === null) {
                    localPromise.defer((deferredLocalPromise) => {
                        _signupSessionStore.saveFlow(flowId)
                            .fail(deferredLocalPromise.reject)
                            .done(deferredLocalPromise.resolve);
                    });
                }

                localPromise.wait()
                    .fail(promise.reject)
                    .done(() => {
                        if (routeInfo.queryParams &&
                            signupDocument.reservedPhoneNumber &&
                            signupDocument.reservedPhoneNumber !== _phoneNumberFormatter.toEOneSixFour(routeInfo.queryParams.phoneNumber)
                        ) {
                            _signupSessionStore.generateNewSignup()
                                .fail(promise.reject)
                                .done(() => {
                                    _signupSessionStore.saveFlow(flowId)
                                        .fail(promise.reject)
                                        .done(() => {
                                            promise.resolve(true);
                                        });
                                });
                        } else {
                            const arePriorRoutesCompletedList = ARE_PRIOR_ROUTES_COMPLETED[flowId][routeId];
                            const arePriorRoutesCompleted = arePriorRoutesCompletedList && arePriorRoutesCompletedList.every((isPriorRouteCompleted) => {
                                return signupDocument[isPriorRouteCompleted] === true;
                            });

                            if (arePriorRoutesCompleted) {
                                promise.resolve(true);
                            } else {
                                promise.resolve(_routeToDefaultFlow(flowId, routeInfo.queryString));
                            }
                        }
                    });
            } else {
                if (_isFlowStartPage(routeInfo.fragment)) {
                    _signupSessionStore.generateNewSignup()
                        .fail(promise.reject)
                        .done(() => {
                            _signupSessionStore.saveFlow(flowId)
                                .fail(promise.reject)
                                .done(() => {
                                    promise.resolve(true);
                                });
                        });
                } else {
                    promise.resolve(_routeToDefaultFlow(signupDocument.flow, routeInfo.queryString));
                }
            }
        });
    };

    const _navigateToSignupSuccessRoute = (routeInfo, signupSession) => {
        const arePriorRoutesCompletedList = ARE_SIGNUP_SUCCESS_PRIOR_ROUTES_COMPLETED[ROUTE__SIGNUP_SUCCESS.routeId];
        const arePriorRoutesCompleted = arePriorRoutesCompletedList.every((isPriorRouteCompleted) => {
            return signupSession[isPriorRouteCompleted] === true;
        });

        if (arePriorRoutesCompleted) {
            return true;
        }

        const flowId = signupSession.flow;
        return _routeToDefaultFlow(flowId, routeInfo.queryString);
    };


    const _routeToDefaultFlow = (flowId, queryString=null, numberTypeSelection=null) => {
        let flowRouteStart = null;
        switch (flowId) {
            case FLOW_A:
                flowRouteStart = FLOW_A_ROUTE_START;
                break;
            case FLOW_B:
                switch (numberTypeSelection) {
                    case _signupNumberTypeSelectionConstants.local:
                        flowRouteStart = FLOW_B_ROUTE_START_LOCAL;
                        break;
                    case _signupNumberTypeSelectionConstants.tollFree:
                        flowRouteStart = FLOW_B_ROUTE_START_TOLL_FREE;
                        break;
                    case _signupNumberTypeSelectionConstants.port:
                        flowRouteStart = FLOW_B_ROUTE_START_PORT_NUMBER;
                        break;
                    default:
                         flowRouteStart = FLOW_B_ROUTE_START_LOCAL;
                }
                break;
            case FLOW_C:
                flowRouteStart = FLOW_C_ROUTE_START;
                break;
            case FLOW_D:
                flowRouteStart = FLOW_D_ROUTE_START;
                break;
            default:
                flowRouteStart = FLOW_A_ROUTE_START;
        }

        if (queryString !== null) {
            flowRouteStart = flowRouteStart + "?" + queryString;
        }

        return _newRoute(flowRouteStart);
    };

    const _newRoute = (routeUrl) => {
        return { routeUrl: routeUrl};
    };

    return {
        guardRoute: routeToSignup
    };
});

