define('persistence/dataProviders/userGroupDataProvider',['externalDependencies/kazooApi',
    'common/promises/promiseFactory'
], function() {

    return function() {
        var self = this;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        var _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("user_group");

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.getAll = function () {
            return _promiseFactory.defer(function (deferredObject) {
                var socketParams = {};
                _webSocketApp.send("get_all_user_groups", socketParams, function (userGroups) {
                    deferredObject.resolve(userGroups);
                });
            });
        };

        self.getById = function (userGroupId) {
            return _promiseFactory.defer(function (deferredObject) {
                var socketParams = {groupId: userGroupId};
                _webSocketApp.send("get_user_group", socketParams, function (userGroup) {
                    deferredObject.resolve(userGroup);
                });
            });
        };
    };
});
