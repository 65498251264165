define('presentation/loginNotifications/facades/accountClosedAndCannotReactivateModalFacade',['businessServices/authentication/authorizationManager',
        'persistence/dataProviders/customerServiceDataProvider'
], function() {
    let _promiseFactory = null;
    let _customerServiceInfoDataProvider = null;
    let _authorizationManager = null;

    const _getCustomerServiceNumber = () => {
        return _promiseFactory.defer((deferredObject) => {
            _customerServiceInfoDataProvider.get()
                .fail(deferredObject.resolve)
                .done((customerServiceInfo) => {
                    deferredObject.resolve(customerServiceInfo.support_phone);
                });
        });
    };

    const _hasPermissionClosedAccountBypass = () => {
        return _authorizationManager.hasPermissionClosedAccountBypass();
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const CustomerServiceDataProviderConstructor = require('persistence/dataProviders/customerServiceDataProvider');
        _customerServiceInfoDataProvider = new CustomerServiceDataProviderConstructor();
        _authorizationManager = require('businessServices/authentication/authorizationManager');
    };

    return function() {
        const self = this;

        self.init = _init;
        self.getCustomerServiceNumber = _getCustomerServiceNumber;
        self.hasPermissionClosedAccountBypass = _hasPermissionClosedAccountBypass;
    };
});

