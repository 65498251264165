define('presentation/settings/prompts/viewModels/addPromptsViewModel',[
    'businessServices/browserSupport/audioDevices',
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/promises/promiseFactory',
    'constants/audioInputOptionConstants',
    'persistence/fileStores/fileManagement/fileReader',
    'presentation/common/audioPresentationObject',
    'presentation/common/modal',
    'presentation/settings/prompts/facades/addPromptsFacade',
    'presentation/settings/prompts/validators/addPromptViewModelValidator',
    'settings/navigationConfiguration'
], function() {
    return function () {
        const self = this;

        const BrowserSupportConstructor = require('businessServices/browserSupport/audioDevices');
        const _browserSupport = new BrowserSupportConstructor();

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const AudioPresentationObjectConstructor = require('presentation/common/audioPresentationObject');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _audioInputOptionConstants = require('constants/audioInputOptionConstants');
        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _conversionNeeded = false;
        let _detachedFromAddPromptView;
        let _facade = null;
        let _fileReader = null;
        let _fileSelected = false;
        let _router = null;
        let _validator = null;

        const _onPromptFileChange = () => {
            self.promptFile.validate();
            let isValid = self.promptFile.isValid();
            _fileSelected = true;
            _cancelPendingGetConversionRequests();

            if (isValid && self.promptFile() !== "") {
                let selectedMethod = self.selectedPromptMethod();

                switch (selectedMethod) {
                    case _audioInputOptionConstants.record:
                        let recordedPromptFileBlob = self.promptFile();
                        let recordedPromptAudioObject = new AudioPresentationObjectConstructor();
                        recordedPromptAudioObject.audioData(URL.createObjectURL(recordedPromptFileBlob));
                        recordedPromptAudioObject.audioType(recordedPromptFileBlob.type);
                        recordedPromptAudioObject.duration = self.promptFileDuration;
                        self.currentVoicePrompt(recordedPromptAudioObject);
                        self.showPreview(false);
                        break;
                    case _audioInputOptionConstants.upload:
                        self.showAudioPlayer(false);
                        self.showPreview(true);
                        self.showConversionMessage(true);

                        _conversionNeeded = true;
                        _facade.requestForFileConversion(self.promptFile())
                            .fail(() => {
                                self.showAudioPlayer(false);
                                self.showPreview(false);
                                self.showConversionFailedMessage(true);
                            })
                            .done((result) => {
                                if (!_detachedFromAddPromptView) {
                                    if (result.isSuccessful) {
                                        self.currentVoicePrompt(result.audioFile);
                                        self.showAudioPlayer(true);
                                        self.showConversionMessage(false);
                                        self.showConversionFailedMessage(false);
                                    } else {
                                        self.showAudioPlayer(false);
                                        self.showPreview(false);
                                        self.showConversionFailedMessage(true);
                                    }
                                }
                           });
                        break;
                }
            } else {
                _fileSelected = false;
                const audioDataObj = new AudioPresentationObjectConstructor();
                audioDataObj.audioUrl = null;
                _facade.formatAudioSource(audioDataObj);
                self.currentVoicePrompt(audioDataObj);
            }
        };

        const _cancelPendingGetConversionRequests = () => {
            if (_conversionNeeded) {
                _facade.cancelGetConvertedFile();
                _conversionNeeded = false;
            }
        };

        const _updateValidationStatus = () => {
            self.isValid(self.promptName.isValid() && self.promptFile.isValid());
        };

        const _onSelectedPromptMethodChange = () => {
            self.promptFile.resetValidation();
        };

        const _setDefaultSelectedPromptMethod = () => {
            _promiseFactory.defer((deferredObject) => {
                if (!_browserSupport.isRecordingSupported()) {
                    self.selectedPromptMethod(_audioInputOptionConstants.upload);
                    self.modelStateObserver.commitData();
                    deferredObject.resolve();
                } else {
                    _browserSupport.hasRecordingDevices()
                        .fail(deferredObject.reject)
                        .done((hasRecordingDevices) => {
                            if (hasRecordingDevices) {
                                self.selectedPromptMethod(_audioInputOptionConstants.record);
                            } else {
                                self.selectedPromptMethod(_audioInputOptionConstants.upload);
                            }
                            self.modelStateObserver.commitData();
                            deferredObject.resolve();
                        });
                }
            });
        };

        self.promptsUrl = _navigationConfiguration.routesById.prompts.url;
        self.isDirty = ko.observable(false);
        self.voicePromptId = null;
        self.isNewAudioFile = ko.observable(false);
        self.currentVoicePrompt = ko.observable().extend({observeState: true});

        self.showRecordPrompt = ko.pureComputed(() => self.selectedPromptMethod() === _audioInputOptionConstants.record);
        self.showUploadPrompt = ko.pureComputed(() => self.selectedPromptMethod() === _audioInputOptionConstants.upload);

        self.promptName = ko.observable('').extend({observeState: true});
        self.promptFile = ko.observable('').extend({observeState: true});
        self.promptFileDuration = ko.observable('');
        self.promptNameAutoFocus = ko.observable(false);
        self.routeToPromptUpload = ko.observable(false);
        self.showAudioPlayer = ko.observable(false);

        self.isCalledFromAnotherViewModel = false;
        self.isValid = ko.observable(true);
        self.showConversionMessage = ko.observable(false);
        self.showConversionFailedMessage = ko.observable(false);
        self.showDownloadPrompt = ko.observable(false);
        self.showPreview = ko.observable(false);

        self.promptMethods = ko.observableArray([
            {text: "Record", id: _audioInputOptionConstants.record},
            {text: "Upload", id: _audioInputOptionConstants.upload},
        ]);
        self.selectedPromptMethod = ko.observable(self.promptMethods()[0]);

        self.headerI18n = ko.pureComputed(() => self.voicePromptId ? "addAudioFile:headerEdit" : "addAudioFile:headerAdd");
        self.saveButtonTitle = ko.pureComputed(() => self.voicePromptId ? _i18n.t('addAudioFile:save') : _i18n.t('addAudioFile:add'));

        self.validate = () => _validator.validate();

        self.update = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                if (self.showConversionFailedMessage()) {
                    deferredObject.resolve(false);
                    return;
                }
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            deferredObject.resolve(false);
                            return;
                        }

                        const voicePromptName = self.promptName().trim();
                        const promptFile = self.promptFile();

                        const facadePromise = self.voicePromptId ?
                            _facade.updateVoicePrompt(self.voicePromptId, voicePromptName, promptFile) :
                            _facade.addVoicePrompt(voicePromptName, promptFile);

                        facadePromise
                            .fail(deferredObject.reject)
                            .done((result) => {
                                self.modelStateObserver.saveData();
                                if (!self.isCalledFromAnotherViewModel) {
                                    deferredObject.resolve(result);
                                    _router.navigate(self.promptsUrl);
                                } else {
                                    deferredObject.resolve(result);
                                }
                            });
                        });
            });
        };

        self.cancelForm = () => {
            return _promiseFactory.defer((deferredObject) => {
                _cancelPendingGetConversionRequests();
                if (self.isCalledFromAnotherViewModel) {
                    self.modelStateObserver.restoreData();
                    _validator.resetValidation();
                } else {
                    self.modelStateObserver.navigateWithoutWarning = true;
                    _router.navigate(self.promptsUrl);
                }
                deferredObject.resolve();
            });
        };

        self.getVoicePromptDownloadUrl = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getVoicePromptDownloadUrl(self.voicePromptId)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        self.compositionComplete = () => {
            if (self.voicePromptId === null) {
                self.isNewAudioFile(true);
            }
        };

        self.detached = () => {
            _detachedFromAddPromptView = true;
            _cancelPendingGetConversionRequests();
            _facade.dispose();
        };

        self.activate = (promptId) => {
            const Facade = require('presentation/settings/prompts/facades/addPromptsFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const FileReaderConstructor = require('persistence/fileStores/fileManagement/fileReader');
            _fileReader = new FileReaderConstructor();

            const ValidatorConstructor = require('presentation/settings/prompts/validators/addPromptViewModelValidator');
            _validator = new ValidatorConstructor();

            _router = require('businessServices/router/router');

            if (promptId) {
                self.voicePromptId = promptId;
            }

            return _initialize();
        };


        const _initialize = () => {
            _detachedFromAddPromptView = false;
            let showUnsavedNavigationWarning = !self.isCalledFromAnotherViewModel;
            self.modelStateObserver = new ModelStateObserverConstructor(self, showUnsavedNavigationWarning);
            self.modelStateObserver.isDirty.subscribe((isDirty) => {
                self.isDirty(isDirty);
            });

            if (self.voicePromptId !== null) {
                _facade.findVoicePrompt(self.voicePromptId)
                    .done((voicePrompt) => {
                        const currentVoicePrompt = _facade.getCurrentVoicePrompt(self.voicePromptId);
                        self.currentVoicePrompt(currentVoicePrompt);
                        self.currentVoicePrompt().duration = voicePrompt.voicePromptDurationSeconds;
                        self.promptName(voicePrompt.promptName);
                        self.promptName.extend({observeState: true});
                        self.showPreview(true);
                        self.showAudioPlayer(true);
                        self.showDownloadPrompt(true);
                        self.modelStateObserver.commitData();
                    });

            } else {
                const missingVoicePrompt = _facade.getMissingVoicePrompt();
                self.promptNameAutoFocus(true);
                self.promptName("");
                self.currentVoicePrompt(missingVoicePrompt);
                self.showAudioPlayer(false);
            }

            _validator.registerViewModel(self, _facade);

            self.promptFile.subscribe(_onPromptFileChange);
            self.promptName.isValid.subscribe(_updateValidationStatus);
            self.selectedPromptMethod.subscribe(_onSelectedPromptMethodChange);

            _setDefaultSelectedPromptMethod();

            return _promiseFactory.wait();
        };
    };
});

