define('presentation/signup/validators/signupProfileValidator',[
    'common/converters/phoneNumberFormatConverter',
    'common/promises/promiseFactory',
    'common/specifications/validEmailAddressSpecification',
    'common/specifications/validPasswordSpecification',
    'constants/signupNumberTypeSelectionConstants',
    'constants/validationMessageEnumerations',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/validPhoneNumberSpecification',
],
function() {
    return function() {
        let self = this;

        let _facade = null;
        let _viewModel = null;
        let _commonValidator = null;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');

        const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatConverter');
        const _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const ValidPasswordSpecificationConstructor = require('common/specifications/validPasswordSpecification');
        const _validPasswordSpecification = new ValidPasswordSpecificationConstructor();

        const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

        const _countryEnumerations = require('constants/countryEnumerations');
        const _signupNumberType = require('constants/signupNumberTypeSelectionConstants');

        self.registerViewModel = (viewModel, facade) => {

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("firstName")
                .addValidationKeyUp(_validateIsRequired)
                .addValidationI18n(_validateIsRequired, 'signupProfile:firstNameIsRequired');
            validationRules.field("lastName")
                .addValidationKeyUp(_validateIsRequired)
                .addValidationI18n(_validateIsRequired, 'signupProfile:lastNameIsRequired');
            validationRules.field("password")
                .addValidationKeyUp(_validatePassword)
                .addValidationI18n(_validateIsRequired, 'signupProfile:passwordRequired')
                .addValidationI18n(_validatePassword, 'signupProfile:passwordInvalid');
            validationRules.field("mobileNumber")
                .addValidationKeyUp(_validatePhoneNumberLength)
                .addValidationI18n(_validateIsRequired, 'signupProfile:phoneNumberRequired')
                .addValidationI18n(_validatePhoneNumberLength, 'signupProfile:phoneNumberInvalid')
                .addValidationI18n(_validateOwnPhoneNumber, _validateOwnPhoneNumberI18nKey)
                .addValidationI18n(_isMobilePhoneNumberValid, 'signupProfile:restrictedPhoneNumber');
            validationRules.field("verificationAnswer")
                .addValidationKeyUp(_validateIsRequired)
                .addValidationI18n(_validateIsRequired, 'signupProfile:verificationAnswerRequired');

            validationRules.validationGroupKeyUp('isValid', ['firstName', 'lastName', 'password', 'mobileNumber', 'verificationAnswer']);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
        };


        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validatePassword = (userPassword) => {
            return _validPasswordSpecification.isSatisfiedBy(userPassword);
        };

        const _validateIsRequired = (field) => {
            return _commonValidator.isStringWithValue(field);
        };

        const _validatePhoneNumberLength = (phoneNumber) => {
            if (phoneNumber) {
                let cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
            }
            return true;
        };

        const _validateOwnPhoneNumber = (phoneNumber) => {
            const e164Number = _phoneNumberFormatter.toEOneSixFour(phoneNumber);
            return !_viewModel.signupPhoneNumbers.includes(e164Number);
        };

        const _isMobilePhoneNumberValid = (phoneNumber) => {
            if (phoneNumber) {
                return _facade.isMobilePhoneNumberValid(_phoneNumberFormatConverter.getRaw(phoneNumber));
            }
            return true;
        };

        const _validateOwnPhoneNumberI18nKey = ko.pureComputed(() => _viewModel.signupNumberType === _signupNumberType.port ? 'signupProfile:ownPortedNumber' : 'signupProfile:ownPurchasedNumber');
    };
});
