define('presentation/settings/userSubscriptions/presentationObjects/userCallRecordingSubscription',[],function() {
    return function(){
        let self = this;

        self.callRecordingBoxId = null;
        self.callRecordingBoxName = null;
        self.callRecordingBoxType = null;
        self.userAvatar = ko.observable();
        self.userId = null;
        self.userGroupId = null;
        self.isEmailDeliveryEnabled = ko.observable().toggleable().extend({observeState: true});
        self.isShowInSidebarEnabled = ko.observable().toggleable().extend({observeState: true});
        self.isPushToMobileAppEnabled = ko.observable().toggleable().extend({observeState: true});
        self.subscribers = [];
        self.showSubscribers = ko.observable(false).toggleable();
    };
});
