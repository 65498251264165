define('presentation/settings/phoneNumbers/validators/editPhoneNumberViewModelValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'constants/validationMessageEnumerations'], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = (viewModal, facade) => {
            const validationMessageBase = _validationMessageEnumerations.clientui.presentation.settings.connectors;

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("isInboundCallRecordingWarningVerified")
                .addValidation(_validateInboundCallRecordingWarningVerified, validationMessageBase.recording.notificationDisclaimerRequired);
            validationRules.field("isOutboundCallRecordingWarningVerified")
                .addValidation(_validateOutboundCallRecordingWarningVerified, validationMessageBase.recording.outboundCallRecordingDisclaimerRequired);
            validationRules.field("phoneNumberName")
                .addValidationI18n(_validatePhoneNumberNameUniqueness, 'editPhoneNumber:validationPhoneNumberName');

            _commonValidator = new CommonValidatorConstructor(viewModal, validationRules, true);
            _viewModel = viewModal;
            _facade = facade;
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateInboundCallRecordingWarningVerified = () => {
            const isInboundCallRecordingEnabled = _viewModel.isInboundCallRecordingEnabled();
            const isInboundCallRecordingWarningVerified = _viewModel.isInboundCallRecordingWarningVerified();
            const recordingNotificationSelected = _viewModel.recordingNotificationSelected();

            if (isInboundCallRecordingEnabled === false || isInboundCallRecordingWarningVerified === true || (recordingNotificationSelected && !recordingNotificationSelected.isBuiltIn())) {
                return true;
            }

            return false;
        };

        const _validateOutboundCallRecordingWarningVerified = () => {
            const isOutboundCallRecordingEnabled = _viewModel.isOutboundCallRecordingEnabled();
            const isOutboundCallRecordingWarningVerified = _viewModel.isOutboundCallRecordingWarningVerified();

            if (isOutboundCallRecordingEnabled === true && isOutboundCallRecordingWarningVerified !== true) {
                return false;
            }
            return true;
        };

        const _validatePhoneNumberNameUniqueness = (phoneNumberName) => {
            return _promiseFactory.defer((deferredResult) => {
                if (phoneNumberName === "") {
                    deferredResult.resolve(true);
                }

                const phoneId = _viewModel.phoneConnectorId;
                _facade.isNameUnique(phoneId, phoneNumberName)
                    .done(deferredResult.resolve)
                    .fail(deferredResult.reject);
            });
        };
    };
});

