/** @module common/files/encoding */
define('common/files/encoding',[], function () {
    /** @constructor */
    return function () {
        var self = this;
        /**
         * The mapping of 0-63 to text representing the base64 values
         * @type {string}  */
        var BASE64_MAPPING = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

        /**
         * Encodes plaintext to a string with the base64 values
         * @param text The plaintext to encode
         * @returns {string} The 64byte value
         * @private
         */
        var _encode = function (text) {
            var i = 0;
            var output = "";
            while (i < text.length) {
                var chr1 = text.charCodeAt(i++);
                var chr2 = text.charCodeAt(i++);
                var chr3 = text.charCodeAt(i++);

                var enc1 = chr1 >> 2;
                var enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                var enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                var enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output + BASE64_MAPPING.charAt(enc1) + BASE64_MAPPING.charAt(enc2) + BASE64_MAPPING.charAt(enc3) + BASE64_MAPPING.charAt(enc4);
            }

            return output;
        };

        /**
         * Converts the base64 encoded string to a plaintext string
         * @param encodedInput The base64 encoded string to convert to plaintext
         * @returns {string}
         * @private
         */
        var _decode = function (encodedInput) {
            // This cleans up the input, but probably shouldn't be needed
            var input = encodedInput.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            var output = "";
            var i = 0;
            while (i < input.length) {

                var enc1 = BASE64_MAPPING.indexOf(input.charAt(i++));
                var enc2 = BASE64_MAPPING.indexOf(input.charAt(i++));
                var enc3 = BASE64_MAPPING.indexOf(input.charAt(i++));
                var enc4 = BASE64_MAPPING.indexOf(input.charAt(i++));

                var chr1 = (enc1 << 2) | (enc2 >> 4);
                var chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                var chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 !== 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 !== 64) {
                    output = output + String.fromCharCode(chr3);
                }
            }

            return output;
        };

        /**
         * Converts a 0-255 byte array to a base64 string
         * @param byteArray the 0-255 byte array to be encoded
         * @returns {string} the 64byte encoded string
         * @private
         */
        var _encodeByteArrayToBase64String = function(byteArray) {
            var text = "";
            for (var index = 0; index < byteArray.length; index++) {
                var dataItem = byteArray[index];
                var itemText = String.fromCharCode(dataItem);
                text = text + itemText;
            }

            var base64Encoded = _encode(text);
            return base64Encoded;
        };

        /**
         * Converts a 64byte string to a standard 0-255 byte array
         * @param base64String the base64 encoded string to be decoded
         * @returns {Array}
         * @private
         */
        var _decodeBase64StringToByteArray = function(base64String) {
            var text = _decode(base64String);

            var byteArray = [];
            for (var index = 0; index < text.length; index++) {
                var dataItem = text.charCodeAt(index);
                byteArray.push(dataItem);
            }

            return byteArray;
        };

        var _encodeArrayBufferToBase64String = function(arrayBuffer) {
            var binary = '';
            var bytes = new Uint8Array(arrayBuffer);
            var len = bytes.byteLength;
            for(var i=0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }

            return window.btoa(binary);
        };

        /**
         * Encodes plaintext to a string with the base64 values
         * @param text The plaintext to encode
         * @returns {string} The 64byte value
         * @private
         */
        self.encode = _encode;
        /**
         * Converts the base64 encoded string to a plaintext string
         * @param encodedInput The base64 encoded string to convert to plaintext
         * @returns {string}
         * @private
         */
        self.decode = _decode;
        /**
         * Converts a 0-255 byte array to a base64 string
         * @param byteArray the 0-255 byte array to be encoded
         * @returns {string} the 64byte encoded string
         * @private
         */
        self.encodeByteArrayToBase64String = _encodeByteArrayToBase64String;
        /**
         * Converts a 64byte string to a standard 0-255 byte array
         * @param base64String the base64 encoded string to be decoded
         * @returns {Array}
         * @private
         */
        self.decodeBase64StringToByteArray = _decodeBase64StringToByteArray;

        self.encodeArrayBufferToBase64String = _encodeArrayBufferToBase64String;
    };
});
