define('presentation/settings/userGroup/viewModels/userGroupVoicemailSubscribersViewModel',[
        'businessServices/state/modelStateObserver',
        'common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/voicemailConstants',
        'presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel',
        'presentation/settings/userGroup/facades/userGroupVoicemailSubscribersFacade',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _commonState = require('common/storage/commonState');

        let _facade = null;
        const ALL_MEMBERS_SUBSCRIBER_ID = "All Members";

        const _initializeSubscribers = () => {
            return _promiseFactory.defer((promise) => {
                if (self.userGroupId === null) {
                    const initialMembers = [{"subscriberId": ALL_MEMBERS_SUBSCRIBER_ID}];
                    _setSelectedSubscribers(initialMembers);
                    self.subscriberSelectionViewModel.activate(self.selectedSubscribers())
                        .fail(promise.reject)
                        .done(() => {
                            self.modelStateObserver.addChildObserver(self.subscriberSelectionViewModel.modelStateObserver);
                            promise.resolve();
                        });
                } else {
                    _facade.getVoicemailBoxSubscribers(self.userGroupId)
                        .fail(promise.reject)
                        .done((subscriberInfo) => {
                            const subscribers = subscriberInfo.subscribers;
                            const subscribersLog = subscriberInfo.subscribersLog;
                            _setSelectedSubscribers(subscribers);
                            self.subscriberSelectionViewModel.activate(self.selectedSubscribers())
                                .fail(promise.reject)
                                .done(() => {
                                    self.subscriberSelectionViewModel.setSubscribersLog(subscribersLog);
                                    self.modelStateObserver.addChildObserver(self.subscriberSelectionViewModel.modelStateObserver);
                                    promise.resolve();
                                });
                        });
                }
            });
        };

        const _setSelectedSubscribers = (subscribers) => {
            self.selectedSubscribers = ko.pureComputed(() => {
                let selectedSubscribers = subscribers.map(subscriber => {
                    if (subscriber.subscriberId === ALL_MEMBERS_SUBSCRIBER_ID) {
                        return subscriber;
                    }
                    const commonStateItem = _commonState.get(subscriber.subscriberId);
                    const avatar = commonStateItem.type === "user" ? {avatar: commonStateItem.avatar()} : {};
                    return Object.assign({}, subscriber, {
                            id: commonStateItem.id,
                            subscriberType: commonStateItem.type,
                            displayName: commonStateItem.name(),
                        },
                        avatar);
                });
                _sorter.sort(selectedSubscribers, "displayName", true);
                return selectedSubscribers;
            });
        };

        self.modelStateObserver = null;
        self.subscriberSelectionViewModel = null;
        self.userGroupId = null;

        self.save = (groupId = null) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                if (groupId) {
                    self.userGroupId = groupId;
                }
                self.subscriberSelectionViewModel.getSubscribers()
                    .fail(promise.reject)
                    .done((subscribers) => {
                        _facade.saveVoicemailBoxSubscribers(self.userGroupId, subscribers)
                            .fail(promise.reject)
                            .done(() => {
                                self.modelStateObserver.saveData();
                                promise.resolve();
                            });
                    });
            });
        };

        self.activate = (userGroupId) => {
            if (userGroupId) {
                self.userGroupId = userGroupId;
            }
            
            const FacadeConstructor = require('presentation/settings/userGroup/facades/userGroupVoicemailSubscribersFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const SubscriberSelectionConstructor = require('presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel');
            self.subscriberSelectionViewModel = new SubscriberSelectionConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            _initializeSubscribers()
                .done(() => {
                    self.modelStateObserver.commitData();
                })
                .fail((error) => {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

