/**
 * @module presentation/common/userGroupAvatar/imageViewModel
 */
define('presentation/common/userGroupAvatar/imageViewModel',[], function(){
    /**
     * @constructor
     */
    return function() {
        var self = this;

        /**
         * The html file encoding type
         * @type {string}
         * @private
         */
        var _imageType = null;

        /**
         * The content of the image as a base64 string
         * @type {string}
         * @private
         */
        var _imageBase64 = null;

        /**
         * Formats the presentation object to show the image
         * @private
         */
        var _formatImageSource = function() {
            if ((_imageType !== null) && (_imageBase64 !== null)) {
                self.imageData("data:" + _imageType + ";base64," + _imageBase64);
            } else {
                self.imageData("");
            }
        };

        /**
         * Sets the html file encoding type
         * @param {string} imageType the html file encoding type
         */
        self.setImageType = function(imageType) {
            _imageType = imageType;
            _formatImageSource();
        };

        /**
         * Sets the base64 image data
         * @param {string} base64Data the image file, as a base64 encoded string
         * @public
         */
        self.setImage = function(base64Data) {
            _imageBase64 = base64Data;
            _formatImageSource();
        };


        /**
         * Indicates that the image was no found.  Updates the object to display the default image
         * @public
         */
        self.setImageNotFound = function() {
            self.imageData("");
            self.showDefaultImage(true);
            self.showImage(false);
        };
		
        /**
         * The full SRC data for an html image
         * @type {string}
         * @public
         */
        self.imageData = ko.observableArray([]);

        /**
         * Indicates that the default image should be shown
         * @type {boolean}
         * @public
         */
        self.showDefaultImage = ko.observable(true);

        /**
         * Indicates that the image data should be shown
         * @type {boolean}
         * @public
         */
        self.showImage = ko.observable(false);
    };
});
