define('common/businessObjects/phoneNumber',[
        'common/converters/phoneNumberFormatter'
    ],
    function (
        /** @type typeof import('common/converters/phoneNumberFormatter') */
        PhoneNumberFormatter
    ) {
        /**
         * Manages a phone number and provides a way to always reference the same number.
         * For short codes, it'll be just numbers. For long codes it'll be in a e164 format. But both in strings.
         * @class phoneNumber
         * @constructor
         */
        return function (/** @type {string} */newPhoneNumber) {
            const _phoneNumberFormatter = new PhoneNumberFormatter();

            const self = this;

            /** @type {string} */
            let phoneNumber = newPhoneNumber;

            const _onlyNumbers = () => {
                return phoneNumber.replace(/\D/g, '');
            };

            self.setDigits = (/** @type {string} */updatedPhoneNumber) => {
                phoneNumber = updatedPhoneNumber;
            };

            self.getDigits = () => {
                return phoneNumber;
            };

            self.isShortCode = () => {
                const onlyNumber = _onlyNumbers();
                return _phoneNumberFormatter.isShortCode(onlyNumber);
            };

            self.isLongCode = () => {
                const onlyNumber = _onlyNumbers();
                return !_phoneNumberFormatter.isShortCode(onlyNumber);
            };

        };
    });
