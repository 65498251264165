define('presentation/forgotPassword/viewModels/resetPasswordViewModel',['businessServices/browserSupport/browserType',
    'businessServices/router/router',
    'settings/navigationConfiguration',
    'common/promises/promiseFactory',
    'presentation/forgotPassword/validators/resetPasswordValidator',
    'presentation/forgotPassword/facades/resetPasswordFacade'], function () {
    return function () {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        const _browserType = require('businessServices/browserSupport/browserType');

        let _emailAddress = null;
        let _expirationDateTime = null;
        let _signature = null;
        let _validator = null;
        let _facade = null;
        let _router = null;
        let _navigationConfiguration = null;
        let _windowControl = null;

        self.emailAddress = ko.observable("");
        self.password = ko.observable("");
        self.confirmPassword = ko.observable("");
        self.isValidLink = ko.observable(false);
        self.shouldDisplayConfirmation = ko.observable(false);
        self.shouldDisplay = ko.observable(true);
        self.shouldHidePassword = ko.observable(true).toggleable();
        self.shouldHideConfirmPassword = ko.observable(true).toggleable();
        self.isMobileDevice = ko.pureComputed(() => _browserType.isMobileDevice);
        self.canDisplayResetPasswordValidation = ko.observable(false);

        const _validateLink = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.validateLink(_emailAddress, _expirationDateTime, _signature)
                    .done((result) => {
                        switch (result) {
                            case "valid":
                                self.isValidLink(true);
                                break;
                            default:
                                _router.navigate(_navigationConfiguration.forgotPasswordPageUrl + '/expired-link');
                                break;
                        }
                        deferredObject.resolve(result);
                    })
                    .fail(deferredObject.reject);
            });
        };

        self.changePassword = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        self.canDisplayResetPasswordValidation(true);
                        if (isValid) {
                            _facade.resetPassword(_emailAddress, self.password(), _expirationDateTime, _signature)
                                .fail(deferredObject.reject)
                                .done(() => {
                                    self.shouldDisplayConfirmation(true);
                                    self.shouldDisplay(false);
                                    deferredObject.resolve();
                                });
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        self.navigateToHomePage = () => {
            _windowControl.openHomePage("_self");
        };

        self.navigateToForgotPassword = () => {
            _router.navigate(_navigationConfiguration.forgotPasswordPageUrl);
        };

        self.redirectToSignIn = () => {
            _router.navigate(_navigationConfiguration.loginPageUrl);
        };

        self.togglePasswordVisible = () => {
            self.shouldHidePassword.toggle();
        };

        self.toggleConfirmPasswordVisible = () => {
            self.shouldHideConfirmPassword.toggle();
        };

        self.activate = (emailAddress, expirationDateTime, signature) => {
            _emailAddress = emailAddress;
            _expirationDateTime = expirationDateTime;
            _signature = signature;

            _router = require('businessServices/router/router');
            _windowControl = require('presentation/common/window/windowControl');
            _navigationConfiguration = require('settings/navigationConfiguration');

            const ResetPasswordFacadeConstructor = require('presentation/forgotPassword/facades/resetPasswordFacade');
            _facade = new ResetPasswordFacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/forgotPassword/validators/resetPasswordValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _validateLink()
                .done((result) => {
                    _validator.registerViewModel(self);
                })
                .fail((error) => {
                    self.isValidLink(false);
                });

            return _promiseFactory.wait();
        };
    };
});

