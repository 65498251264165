define('businessServices/uploads/customerUpload',['externalDependencies/clientWebSocket',
        'common/promises/waitHandle',
        'common/promises/promiseFactory'
    ],
    function () {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const promiseFactory = new PromiseFactoryConstructor();

        let clientWebSocket = require('externalDependencies/clientWebSocket');
        let uploadSocket = clientWebSocket.forApplication("customerUpload");

        const sendFile = (fileHandle, url) => {
            return promiseFactory.deferIndefinitely((promise) => {
                $.ajax({
                    url: url,
                    type: 'PUT',
                    data: fileHandle,
                    processData: false,
                    contentType: fileHandle.type,
                    success: () => {
                        promise.resolve();
                    },
                    error: () => {
                        const uploadFailed = new Error("Upload failed");
                        promise.reject(uploadFailed);
                    }
                });
            });
        };

        const requestUploadId = (fileHandle) =>{
            return promiseFactory.deferIndefinitely((promise) => {
                const uploadUrlRequest = {
                    contentType: fileHandle.type,
                };

                uploadSocket.send("getUploadUrl",uploadUrlRequest, (response, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    const {url, uploadId} = response;
                    promise.resolve({url, uploadId});
                });
            });
        };

        const uploadFile = (fileHandle) =>{
            return promiseFactory.deferIndefinitely((promise) => {
                requestUploadId(fileHandle)
                    .fail(promise.reject)
                    .done(({url, uploadId}) => {
                        sendFile(fileHandle, url)
                            .fail(promise.reject)
                            .done(() => {
                                const result = {
                                    uploadId : uploadId
                                };
                                promise.resolve(result);
                            });
                    });
            });
        };

        return {
            uploadFile
        };
    });

