define('presentation/settings/messagingCompliance/validators/messagingComplianceAutoResponseTabValidator',[
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
], function(
    /** @type typeof import('presentation/common/validation/commonValidator') */
    CommonValidator,
    /** @type typeof import('presentation/common/validation/validationRule') */
    ValidationRules
) {
    return function() {
        /** @typedef { import('presentation/settings/messagingCompliance/viewModels/messagingComplianceAutoResponseTabViewModel') } MessagingComplianceAutoResponseTabViewModel } */
        /** @typedef { import('presentation/common/validation/commonValidator') } CommonValidator } */

        const self = this;

        const placeholderEmailAddress = "support@company.com";

        /** @type { CommonValidator } */
        let _commonValidator = null;

        /** @type { MessagingComplianceAutoResponseTabViewModel } */
        let _viewModel = null;

        self.registerViewModel = (/** @type { MessagingComplianceAutoResponseTabViewModel } */ viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidator(viewModel);

            const validationRules = new ValidationRules();
            validationRules.field("helpAutoResponse")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceAutoResponseTab:helpAutoResponseRequired')
                .addValidationI18n(_validateHelpAutoResponse, 'messagingComplianceAutoResponseTab:helpAutoResponseInvalid')
                .addValidationI18n(_validateNoPlaceHolderEmailUsed, 'messagingComplianceAutoResponseTab:noPlaceholderEmail');
            validationRules.field("stopAutoResponse")
                .addValidationI18n(_commonValidator.isStringWithValue, 'messagingComplianceAutoResponseTab:stopAutoResponseRequired')
                .addValidationI18n(_validateStopAutoResponse, 'messagingComplianceAutoResponseTab:stopAutoResponseInvalid')
                .addValidationI18n(_validateNoPlaceHolderEmailUsed, 'messagingComplianceAutoResponseTab:noPlaceholderEmail');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => _commonValidator.validate();

        self.resetValidation = () => {
            _viewModel.helpAutoResponse.resetValidation();
            _viewModel.stopAutoResponse.resetValidation();
        };

        const _validateHelpAutoResponse = (helpAutoResponse) => {
            return _commonValidator.isStringWithLength(helpAutoResponse, 20, 3000);
        };

        const _validateStopAutoResponse = (stopAutoResponse) => {
            return _commonValidator.isStringWithLength(stopAutoResponse, 20, 3000);
        };

        const _validateNoPlaceHolderEmailUsed = (response) => {
            return !response.includes(placeholderEmailAddress);
        };
    };
});
