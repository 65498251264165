define('businessServices/payments/paymentProcessor',['externalDependencies/kazooApi',
    'common/promises/promiseFactory',
    'businessServices/converters/currencyFormatter'],

    function() {
        return function() {
            let self = this;

            const KazooConstructor = require('externalDependencies/kazooApi');
            const _kazooApi = new KazooConstructor();

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
            const _currencyFormatter = new CurrencyFormatterConstructor();

            self.applyPayment = (paymentMethodId, amount) => {
                if (paymentMethodId === null) {
                    throw new Error("Missing paymentId");
                } else {
                    return _promiseFactory.defer((deferredObject) => {
                        const payment = {
                            data: {
                                paymentMethodId: paymentMethodId,
                                amount: _currencyFormatter.formatUSD(amount)
                            }
                        };
                        _kazooApi.callAccount('/lobby_payments/'+paymentMethodId, 'POST', payment)
                            .done(({data}) => {
                                deferredObject.resolve(data);
                            })
                            .fail((error) => {
                                error.paymentId = paymentMethodId;
                                deferredObject.reject(error);
                            });
                    });
                }
            };
        };
});

