define('businessServices/router/authorizationPlugins/tokenAuthorizationPlugin',['common/promises/promiseFactory', 'businessServices/authentication/authenticationManager', 'businessServices/authentication/stores/standardAuthenticationStore'], function() {

    function configureAuthorization(fragment) {
        var standardAuthenticationStore = require('businessServices/authentication/stores/standardAuthenticationStore');

        var authenticationObject = standardAuthenticationStore.getValue();
        if (authenticationObject !== undefined && authenticationObject !== null) {
            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.defer(function(promise) {
                var authenticationToken = authenticationObject.token;
                var accountId = authenticationObject.accountId;
                var userId = authenticationObject.userId;

                var authenticationManager = require('businessServices/authentication/authenticationManager');
                authenticationManager.loginWithToken(authenticationToken, accountId, userId)
                    .done(function(isSuccessful) {
                        if (isSuccessful) {
                            var valid = {
                                status : "success"
                            };
                            promise.resolve(valid);
                        } else {
                            var failed = {
                                status : "failed"
                            };
                            promise.resolve(failed);
                        }
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        } else {
            return { status : "ignored" };
        }
    }

    return {
        configureAuthorization : configureAuthorization
    };
});
