/**
 * @module fileStoreResult
 */
define('persistence/fileStores/fileStoreResult',[], function() {
    /**
     * @constructor
     */
    return function() {
        var self = this;

        /**
         * The html encoding type of the file
         * @type {string}
         * @public
         */
        self.encodingType = null;

        /**
         * The content of the document expressed as a base64 string
         * @type {string}
         * @public
         */
        self.base64Content = null;
    };
});

