define('presentation/common/conversationImagesGrid/viewModels/conversationImagesGridViewModel',[
    'businessServices/events/eventManager'
], function (
    /** @type import('businessServices/events/eventManager')*/
    eventManager
) {
    /** @typedef {import('presentation/common/conversationImagesGrid/viewModels/conversationImagesGridViewModel')} ConversationImagesGridViewModel */

    return function () {
        /** @type {ConversationImagesGridViewModel} */
        const self = this;

        self.images = ko.observableArray([]);

        self.hasSingleImage = ko.pureComputed(() => self.images() && self.images().length === 1);

        /** @type {ConversationImagesGridViewModel["onImageClicked"]} */
        self.onImageClicked = (attachmentPresentationObject) => {
            eventManager.publishSmsAttachmentClicked(attachmentPresentationObject.attachmentId());
        };

        /** @type {ConversationImagesGridViewModel["activate"]} */
        self.activate = (activationData) => {
            self.images = activationData.imagesGridObject.images || ko.observableArray([]);
            return _initialize();
        };

        const _initialize = () => {

        };
    };
});
