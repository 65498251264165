define('presentation/settings/userProfile/viewModels/changePasswordModalViewModel',['businessServices/authentication/sessionAccountInfo',
        'businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'presentation/common/modal',
        'presentation/settings/userProfile/facades/changePasswordModalFacade',
        'presentation/settings/userProfile/validators/changePasswordModalValidator'],
function() {
    return function(userId, emailAddress) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        
        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');

        let _facade = null;
        let _modalService = null;
        let _sessionAccountInfo = null;
        let _validator = null;

        const _buildUserObjectForDisplay = () => {
            let commonStateObject = _commonState.get(userId);
            let hasAvatar = commonStateObject.avatar().showImage();

            self.user({
                avatar: commonStateObject.avatar,
                hasAvatar: hasAvatar,
                name: commonStateObject.name,
                emailAddress: emailAddress
            });
        };

        self.modelStateObserver = null;
        self.currentPassword = ko.observable('').extend({observeState: true, rateLimit: 500});
        self.newPassword = ko.observable('').extend({observeState: true});
        self.confirmPassword = ko.observable('').extend({observeState: true});
        self.emailAddress = emailAddress;
        self.isImpersonating = ko.observable(false);
        self.isUserSelf = ko.observable(false);
        self.isCompositionComplete = ko.observable(false);
        self.showConfirmationMessage = ko.observable(false);
        self.passwordPlaceholder = _i18n.t('changePasswordModal:passwordPlaceholder');
        self.user = ko.observable({});

        self.showResetPassword = ko.pureComputed(() => self.isImpersonating() || !self.isUserSelf());
        self.headerI18nKey = ko.pureComputed(() => self.showResetPassword() ? 'resetPassword' : 'changePassword');
        self.closeButtonI18nKey = ko.pureComputed(() => self.showConfirmationMessage() ? 'close' : 'cancel');
        self.submitButtonTextKey = ko.computed(() => self.showResetPassword() ? 'send' : 'change');
        self.submitButtonText = ko.pureComputed(() => _i18n.t(`changePasswordModal:${self.submitButtonTextKey()}`));

        self.validate = () => _validator.validate();

        self.submitForm = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                if (self.showResetPassword()) {
                    _facade.sendForgotPasswordEmail(self.emailAddress)
                        .fail(deferredObject.reject)
                        .done(() => {
                            self.isCompositionComplete(false);
                            self.modelStateObserver.navigateWithoutWarning = true;
                            _modalService.closeModal(self);
                            deferredObject.resolve();
                        });
                } else {
                    _validator.validate()
                        .fail(deferredObject.reject)
                        .done((isValid) => {
                            if (isValid === false) {
                                deferredObject.resolve();
                                return;
                            }

                            _facade.savePassword(self.emailAddress, self.newPassword(), self.currentPassword())
                                .fail(deferredObject.reject)
                                .done(() => {
                                    self.showConfirmationMessage(true);
                                    deferredObject.resolve();
                                });
                        });
                }
            });
        };

        self.closeModal = () => {
            self.isCompositionComplete(false);
            self.modelStateObserver.navigateWithoutWarning = true;
            _modalService.closeModal(self);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };


        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/userProfile/facades/changePasswordModalFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            const ValidatorConstructor = require('presentation/settings/userProfile/validators/changePasswordModalValidator');
            _validator = new ValidatorConstructor();

            _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

            return _initialize();
        };

        const _initialize = () => {
            self.isImpersonating(_facade.isImpersonating());
            self.isUserSelf(userId === _sessionAccountInfo.userId());

            _validator.registerViewModel(self, _facade);

            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.commitData();
            
            if (self.showResetPassword()) {
                _buildUserObjectForDisplay();
                self.modelStateObserver.isDirty(true);
            }
        };
    };
});

