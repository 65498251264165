define('presentation/settings/prompts/validators/inlinePromptNames',[
    'constants/autoAttendantVoiceRouteEnumerations'
], () => {
    const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');
    const _buildInlinePromptNames = (promptNames, viewModel) => {
        if (!viewModel) {
            return;
        }
        const isAutoAttendant = ko.isObservable(viewModel.connector) !== false && viewModel.connector() !== null;
        if (isAutoAttendant) {
            if (viewModel.addPromptsViewModel) {
                promptNames.push(viewModel.addPromptsViewModel.promptName);
                viewModel.connector().subMenuItems().forEach(
                    subMenu => _buildInlinePromptNames(subMenu.connector())
                );
            }
        }
        else if (viewModel.routeToSelection) {
            switch (viewModel.routeToSelection()) {
                case _voiceRouteEnumerations.schedule:
                    _buildInlinePromptNames(viewModel.defaultRoutingRuleViewModel());
                    viewModel.ranges().forEach(
                        range => _buildInlinePromptNames(range.routeToViewModel())
                    );
                    break;
                case _voiceRouteEnumerations.prompt:
                    promptNames.push(viewModel.routeToTypeSelectedForm().addPromptsViewModel.promptName);
                    const promptViewModel = viewModel.routeToTypeSelectedForm().routeToViewModel();
                    if (promptViewModel) {
                        _buildInlinePromptNames(promptViewModel);
                    }
                    break;
                case _voiceRouteEnumerations.subMenu:
                case _voiceRouteEnumerations.voicemailBox:
                    break;
                case _voiceRouteEnumerations.user:
                case _voiceRouteEnumerations.userGroup:
                case _voiceRouteEnumerations.forwardingNumber:
                    const childViewModel = viewModel.routeToTypeSelectedForm().routeToViewModel();
                    if (childViewModel) {
                        _buildInlinePromptNames(childViewModel);
                    }
                    break;
            }
        }
    };

    class InlinePromptNames {
        constructor(viewModel, initialPromptName) {
            this.initialPromptName = initialPromptName;
            this.promptNames = initialPromptName ?
                [initialPromptName] :
                [];
            this.viewModel = viewModel;
            _buildInlinePromptNames(this.promptNames, this.viewModel);
        }

        addPromptName(promptName) {
            this.promptNames.push(promptName);
        }

        rebuild() {
            this.promptNames = this.initialPromptName ?
                [this.initialPromptName] :
                [];
            _buildInlinePromptNames(this.promptNames, this.viewModel);
        }

        isNameUnique(promptName) {
            const promptNameToCompare = promptName.trim().toLowerCase();
            const matchingPromptNameCount = this.promptNames.reduce(
                (count, inlinePromptName) => inlinePromptName().trim().toLowerCase() === promptNameToCompare ?
                    count + 1 :
                    count,
                0);
            return matchingPromptNameCount <= 1;
        }
    }

    return {
        inlinePromptNames: InlinePromptNames
    };
});

