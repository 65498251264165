define('presentation/settings/userProfile/viewModels/changeVerificationQAModalViewModel',['businessServices/state/modelStateObserver',
        'common/collections/enumerationKeyValueConverter',
        'common/promises/promiseFactory',
        'constants/secretQuestionEnumerations',
        'presentation/common/modal',
        'presentation/settings/userProfile/facades/changeVerificationQAModalFacade',
        'presentation/settings/userProfile/validators/changeVerificationQAModalValidator'
], function() {
    return function() {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _i18n = require('i18next');
        const _keyValueConverter = require('common/collections/enumerationKeyValueConverter');
        const _verificationQuestionsEnumerations = require('constants/secretQuestionEnumerations');

        let _facade = null;
        let _modalService = null;
        let _validator = null;

        const VERIFICATION_QUESTIONS_LIST = _keyValueConverter.convertToKeyValues(_verificationQuestionsEnumerations);

        self.modelStateObserver = null;
        self.verificationQuestion = ko.observable('').extend({observeState: true});
        self.verificationAnswer = ko.observable('').extend({observeState: true});
        self.isCompositionComplete = ko.observable(false);
        self.showConfirmationMessage = ko.observable(false);
        self.closeButtonI18nKey = ko.pureComputed(() => self.showConfirmationMessage() ? 'close' : 'cancel');
        self.submitButtonText = ko.pureComputed(() => _i18n.t(`changeVerificationQAModal:change`));
        self.verificationQuestions = VERIFICATION_QUESTIONS_LIST.map((question) => {
            question.id = question.key;
            question.text = question.value;
            return question;
        });

        self.validate = () => _validator.validate();

        self.saveVerificationQA = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid) {
                            _facade.saveVerificationQA(self.verificationQuestion(), self.verificationAnswer())
                                .fail(deferredObject.reject)
                                .done(() => {
                                    self.showConfirmationMessage(true);
                                    deferredObject.resolve();
                                });
                        } else {
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        self.closeModal = () => {
            self.isCompositionComplete(false);
            self.modelStateObserver.navigateWithoutWarning = true;
            _modalService.closeModal(self);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/userProfile/facades/changeVerificationQAModalFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            const ValidatorConstructor = require('presentation/settings/userProfile/validators/changeVerificationQAModalValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            _facade.getVerificationQA()
                .done((results) => {
                    self.verificationQuestion(results.verificationQuestion);
                    self.verificationAnswer(results.verificationAnswer);
                    self.modelStateObserver.commitData();
                    _validator.registerViewModel(self, _facade);
                })
                .fail((error) => {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

