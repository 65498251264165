define('presentation/common/checkBox/viewModels/checkBoxViewModel',[],function() {
    return function() {
        const self = this;
        let _settings = null;

        self.isSelected = ko.observable();
        self.isDisabled = ko.observable(false);
        self.isValid = ko.observable(true);
        self.labelI18nKey = ko.observable('');
        self.labelHtml = ko.observable('');
        self.errorI18nKey = ko.observable('');
        self.isPressed = ko.observable(false);
        self.tooltipText = ko.observable('');

        self.showLabel = ko.pureComputed(() =>  self.labelI18nKey() !== '');
        self.showLabelHtml = ko.pureComputed(() =>  self.labelHtml() !== '');
        self.showTooltip = ko.pureComputed(() => self.tooltipText() !== '');
        self.showError = ko.pureComputed(() => {
            return (self.errorI18nKey() !== '') && !self.isValid();
        });

        self.toggleValue = () => {
            if (self.isDisabled()){
                return;
            }
            self.isSelected(!self.isSelected());
        };

        self.onMousedown = () => {
            self.isPressed(true);
        };

        self.onTouchStart = () => {
            self.isPressed(true);
        };

        self.onMouseup = () => {
            self.isPressed(false);
        };

        self.onMouseout = () => {
            self.isPressed(false);
        };

        self.onTouchEnd = () => {
            self.isPressed(false);
        };

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {
            if (_settings.labelI18nKey) {
                if (ko.isObservable(_settings.labelI18nKey)){
                    self.labelI18nKey = _settings.labelI18nKey;
                } else {
                    self.labelI18nKey(_settings.labelI18nKey);
                }
            }

            if (_settings.labelHtml) {
                if (ko.isObservable(_settings.labelHtml)){
                    self.labelHtml = _settings.labelHtml;
                } else {
                    self.labelHtml(_settings.labelHtml);
                }
            }

            if (_settings.errorI18nKey) {
                if (ko.isObservable(_settings.errorI18nKey)){
                    self.errorI18nKey = _settings.errorI18nKey;
                } else {
                    self.errorI18nKey(_settings.errorI18nKey);
                }
            }

            if (_settings.isDisabled) {
                if (ko.isObservable(_settings.isDisabled)){
                    self.isDisabled = _settings.isDisabled;
                } else {
                    self.isDisabled(_settings.isDisabled);
                }
            }

            if (_settings.isSelected) {
                if (ko.isObservable(_settings.isSelected)){
                    self.isSelected = _settings.isSelected;
                } else {
                    self.isSelected(_settings.isSelected);
                }
            }

            if (_settings.isValid) {
                if (ko.isObservable(_settings.isValid)){
                    self.isValid = _settings.isValid;
                } else {
                    self.isValid(_settings.isValid);
                }
            }

            if (_settings.tooltipText) {
                if (ko.isObservable(_settings.tooltipText)){
                    self.tooltipText = _settings.tooltipText;
                } else {
                    self.tooltipText(_settings.tooltipText);
                }
            }

        };
    };
});

