define('presentation/settings/userSubscriptions/viewModels/userSubscriptionsViewModel',['businessServices/authentication/sessionAccountInfo',
        'businessServices/state/modelStateObserver',
        'common/promises/promiseFactory',
        'presentation/settings/userSubscriptions/viewModels/userCallRecordingSubscriptionsViewModel',
        'presentation/settings/userSubscriptions/viewModels/userVoicemailSubscriptionsViewModel',
        'presentation/tabs/presentationObjects/tabsPresentationObject',
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const RecordingTabViewModelConstructor = require('presentation/settings/userSubscriptions/viewModels/userCallRecordingSubscriptionsViewModel');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');
        const VoicemailTabViewModelConstructor = require('presentation/settings/userSubscriptions/viewModels/userVoicemailSubscriptionsViewModel');

        const _i18n = require('i18next');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        let _disposables = [];

        const _updateActiveTabViewModel = () => {
            switch (self.tabActive()) {
                case self.tabMetadata.voicemail.id:
                    self.activeTabViewModel(self.voicemailTabViewModel);
                    break;
                case self.tabMetadata.recording.id:
                    self.activeTabViewModel(self.recordingTabViewModel);
                    break;
            }
        };

        self.userId = _sessionAccountInfo.userId();
        self.isSubForm = ko.observable(false);
        self.modelStateObserver = null;
        self.tabActive = ko.observable();
        self.tabMetadata = {
            voicemail: { id: "voicemail", isValid: ko.observable(true), title: _i18n.t('userSubscriptions:voicemailTab') },
            recording: { id: "recording", isValid: ko.observable(true), title: _i18n.t('userSubscriptions:recordingTab') },
        };
        self.tabs = [
            new TabPresentationObjectConstructor(self.tabMetadata.voicemail.id, self.tabActive, self.tabMetadata.voicemail.isValid, self.tabMetadata.voicemail.title),
            new TabPresentationObjectConstructor(self.tabMetadata.recording.id, self.tabActive, self.tabMetadata.recording.isValid, self.tabMetadata.recording.title)
        ];
        self.tabActive(self.tabs[0].id);
        self.isFirstTabActive = ko.computed(() => self.tabActive() === self.tabs[0].id);
        self.isTabActive = (tabId) => {
            return self.tabActive() === tabId;
        };
        self.voicemailTabViewModel = null;
        self.recordingTabViewModel = null;
        self.activeTabViewModel = ko.observable();

        self.save = () => {
            const _savePromise = self.isSubForm() ? _promiseFactory.deferIndefinitely() : _promiseFactory.deferWithMinimumWait();

            const tabPromiseFactory = new PromiseFactoryConstructor();
            tabPromiseFactory.defer((recordingPromise) => {
                self.recordingTabViewModel.save()
                    .fail(recordingPromise.reject)
                    .done(recordingPromise.resolve);
            });
            tabPromiseFactory.defer((voicemailPromise) =>{
                self.voicemailTabViewModel.save()
                    .fail(voicemailPromise.reject)
                    .done(voicemailPromise.resolve);
            });
            tabPromiseFactory.wait()
                .fail(_savePromise.reject)
                .done(() => {
                    self.modelStateObserver.saveData();
                    _savePromise.resolve();
                });

            return _savePromise;
        };

        self.cancelForm = () => {
            return _promiseFactory.defer((promise) => {
                self.modelStateObserver.restoreData();
                promise.resolve();
            });
        };

        self.dispose = () => {
            self.voicemailTabViewModel.dispose();
            self.recordingTabViewModel.dispose();

            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.detached = () => {
            if (self.isSubForm()) {
                return;
            }

            self.dispose();
        };

        self.activate = (userId, subscriptionType) => {
            if (userId !== undefined) {
                self.userId = userId;
                self.isSubForm(true);
            }
            let tabId = subscriptionType !== undefined ?  subscriptionType.toLowerCase() : null;

            self.voicemailTabViewModel = new VoicemailTabViewModelConstructor();
            self.recordingTabViewModel = new RecordingTabViewModelConstructor();

            return _initialize(tabId);
        };

        const _initialize = (tabId) => {

            _initializeTabs(tabId)
                .done(() => {
                    self.modelStateObserver = new ModelStateObserverConstructor(self, true, [self.voicemailTabViewModel.modelStateObserver, self.recordingTabViewModel.modelStateObserver]);
                    self.modelStateObserver.commitData();
                });

            return _promiseFactory.wait();
        };

        const _initializeTabs = (tabId) => {
            return _promiseFactory.defer((initTabsPromise) => {
                let initTabsPromiseFactory = new PromiseFactoryConstructor();
                initTabsPromiseFactory.defer((voicemailPromise) => {
                    self.voicemailTabViewModel.activate(self.userId)
                        .fail(voicemailPromise.reject)
                        .done(voicemailPromise.resolve);
                });
                initTabsPromiseFactory.defer((recordingPromise) => {
                    self.recordingTabViewModel.activate(self.userId)
                        .fail(recordingPromise.reject)
                        .done(recordingPromise.resolve);
                });

                initTabsPromiseFactory.wait()
                    .fail(initTabsPromise.reject)
                    .done(() => {
                        if (self.tabs.some(t => t.id === tabId)) {
                            self.tabActive(tabId);
                        } else {
                            let firstTab = self.tabs.find(t => true);
                            self.tabActive(firstTab.id);
                        }

                        _updateActiveTabViewModel();
                        _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                        initTabsPromise.resolve();
                    });
            });
        };
    };
});
