define('constants/featureToggleConstants',[], function() {
    return {
        smsMessaging: 1,
        webMessaging: 2,
        contacts: 3,
        humanDetection: 4,
        webContacts: 5,
        blocking: 6
    };
});

