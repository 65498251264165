define('businessServices/authentication/signupForCustomerManager',[
    'businessServices/authentication/stores/signupAuthenticationStore',
    'common/authentication/employeeAuthenticationStore',
    'common/promises/promiseFactory',
    'common/storage/workSessionIdStorage',
    'externalDependencies/webSocket'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    function signupForCustomerWithImpersonationNonce(signupForCustomerNonce, affiliateId, patliveAccountNumber) {
        return _promiseFactory.defer(function(promise) {
            var WebSocketApp = require('persistence/webSocket/webSocketApp');
            var webSocket = new WebSocketApp();
            webSocket.init("signup_for_customer");

            const workSessionIdStorage = require('common/storage/workSessionIdStorage');
            const employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
            const SignupAuthenticationStoreConstructor = require('businessServices/authentication/stores/signupAuthenticationStore');
            const signupAuthenticationStore = new SignupAuthenticationStoreConstructor();
            signupAuthenticationStore.init();

            const params = {
                signupForCustomerNonce : signupForCustomerNonce,
                affiliateId : affiliateId,
                patliveAccountNumber: patliveAccountNumber,
                workSessionId : workSessionIdStorage.getWorkSessionId(),
                userAgent: navigator.userAgent
            };

            webSocket.send("signup_for_customer_nonce", params, function (result) {
                if (result.status === "success") {
                    workSessionIdStorage.setWorkSessionId(result.workSessionId);
                    employeeAuthenticationStore.setEmployeeAuthentication(result.employeeId, result.reconnectionToken);
                    signupAuthenticationStore.clear();
                    signupAuthenticationStore.save(result.signupSessionId, result.accountNumber, result.secretKey, result.workSessionId);
                }

                promise.resolve({isSuccess : true});
            });
        });
    }

    return {
        signupForCustomerWithImpersonationNonce : signupForCustomerWithImpersonationNonce
    };
});
