define('constants/localUseCaseConstants',[], function() {
    return {
        AccountNotification: "ACCOUNT_NOTIFICATION",
        CustomerCare: "CUSTOMER_CARE",
        DeliveryNotification: "DELIVERY_NOTIFICATION",
        FraudAlert: "FRAUD_ALERT",
        LowVolumeMixed: "LOW_VOLUME",
        Marketing: "MARKETING",
        PollingAndVoting: "POLLING_VOTING",
        PublicServiceAnnouncement: "PUBLIC_SERVICE_ANNOUNCEMENT",
        SecurityAlert: "SECURITY_ALERT",
        SoleProprietor: "SOLE_PROPRIETOR",
        TwoFactorAuthentication: "2FA"
    };
});

