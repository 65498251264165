define('presentation/settings/phoneNumbers/viewModels/editPhoneNumberCallRecordingTabViewModel',[
    'common/promises/promiseFactory',
    'constants/permissionInheritanceConstants',
    'i18next'
],
    function () {
        return function () {
            let self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const _inheritanceConstants = require('constants/permissionInheritanceConstants');
            const _i18n = require('i18next');

            let _disposables = [];

            const CALL_RECORDING_TOGGLE_OPTIONS = {
                disabled: 'Disabled',
                allCalls: 'All Calls',
                inboundOnly: 'Inbound Only',
                outboundOnly: 'Outbound Only'
            };

            const _updateInheritanceObservable = () => {
                self.isCallRecordingPermissionInherited(self.isCallRecordingPermissionInheritedSelectedOption() === _inheritanceConstants.enabled);
            };

            const _updateRecordingNotificationSelectedObservable = () => {
                const recordingNotification = self.notificationList().find(notification => notification.id === self.recordingNotificationSelectedOption());
                self.recordingNotificationSelected(recordingNotification);
                self.isInboundCallRecordingWarningVerified(false);
                self.selectedRecordingNotificationAudioObject(self.recordingNotificationSelected().notificationAudioObject());
                self.currentNotificationId(self.recordingNotificationSelected().recordingNotificationId);
            };

            const _initializeRecordingNotificationList = () => {
                return _promiseFactory.defer((deferredObject) => {
                    let isDefaultSet = false;
                    let defaultRecordingNotification = null;
                    let recordingNotifications = self.notificationList();
                    let currentNotificationId = self.currentNotificationId();

                    recordingNotifications.forEach(recordingNotification => {
                        if (recordingNotification.isCurrent() === true) {
                            defaultRecordingNotification = recordingNotification;
                        }

                        if (currentNotificationId === null) {
                            if (recordingNotification.isCurrent() === true) {
                                self.recordingNotificationSelected(recordingNotification);
                                self.currentNotificationId(recordingNotification.recordingNotificationId);
                                isDefaultSet = true;
                            }
                        } else if (recordingNotification.recordingNotificationId === currentNotificationId) {
                            self.recordingNotificationSelected(recordingNotification);
                            isDefaultSet = true;
                        }

                        recordingNotification.text = recordingNotification.notificationName();
                        recordingNotification.id = recordingNotification.recordingNotificationId;
                    });

                    if (isDefaultSet === false) {
                        self.recordingNotificationSelected(defaultRecordingNotification);
                        self.currentNotificationId(defaultRecordingNotification.recordingNotificationId);
                    }

                    self.selectedRecordingNotificationAudioObject(self.recordingNotificationSelected().notificationAudioObject());
                    self.recordingNotificationSelectedOption(currentNotificationId);
                    deferredObject.resolve();
                });
            };

            const _initializeCallRecordingSelectedOption = () => {
                const isInboundCallRecordingEnabled = self.isInboundCallRecordingEnabled();
                const isOutboundCallRecordingEnabled = self.isOutboundCallRecordingEnabled();
                self.callRecordingSelectedOption(CALL_RECORDING_TOGGLE_OPTIONS.allCalls);
                if (isInboundCallRecordingEnabled === true && isOutboundCallRecordingEnabled === true) {
                    self.callRecordingSelectedOption(CALL_RECORDING_TOGGLE_OPTIONS.allCalls);
                    self.isInboundCallRecordingWarningVerified(true);
                    self.isOutboundCallRecordingWarningVerified(true);
                } else if (isInboundCallRecordingEnabled === true) {
                    self.callRecordingSelectedOption(CALL_RECORDING_TOGGLE_OPTIONS.inboundOnly);
                    self.isInboundCallRecordingWarningVerified(true);
                } else if (isOutboundCallRecordingEnabled === true) {
                    self.callRecordingSelectedOption(CALL_RECORDING_TOGGLE_OPTIONS.outboundOnly);
                    self.isOutboundCallRecordingWarningVerified(true);
                }
            };

            const _onCallRecordingChanged = () => {
                if (self.isCallRecordingToggleEnabled()) {
                    const callRecordingSelectedOption = self.callRecordingSelectedOption();
                    self.callRecordingOption(callRecordingSelectedOption);
                    if (callRecordingSelectedOption === CALL_RECORDING_TOGGLE_OPTIONS.allCalls) {
                        self.isInboundCallRecordingEnabled(true);
                        self.isOutboundCallRecordingEnabled(true);
                    } else if (callRecordingSelectedOption === CALL_RECORDING_TOGGLE_OPTIONS.inboundOnly) {
                        self.isInboundCallRecordingEnabled(true);
                        self.isOutboundCallRecordingEnabled(false);
                    } else if (callRecordingSelectedOption === CALL_RECORDING_TOGGLE_OPTIONS.outboundOnly) {
                        self.isInboundCallRecordingEnabled(false);
                        self.isOutboundCallRecordingEnabled(true);
                    }
                } else {
                    self.isInboundCallRecordingEnabled(false);
                    self.isOutboundCallRecordingEnabled(false);
                    self.callRecordingOption(CALL_RECORDING_TOGGLE_OPTIONS.disabled);
                }
            };

            self.recordingOptions = ko.observableArray([
                { id: CALL_RECORDING_TOGGLE_OPTIONS.allCalls, text: _i18n.t('editPhoneNumberCallRecordingTab:callRecordingOptions:allCalls') },
                { id: CALL_RECORDING_TOGGLE_OPTIONS.inboundOnly, text: _i18n.t('editPhoneNumberCallRecordingTab:callRecordingOptions:onlyInbound') },
                { id: CALL_RECORDING_TOGGLE_OPTIONS.outboundOnly, text: _i18n.t('editPhoneNumberCallRecordingTab:callRecordingOptions:onlyOutbound') }
            ]);

            self.inheritanceOptions = ko.observableArray([
                { id: _inheritanceConstants.enabled, text: _i18n.t('editPhoneNumberCallRecordingTab:inheritPermissionEnabled') },
                { id: _inheritanceConstants.disabled, text: _i18n.t('editPhoneNumberCallRecordingTab:inheritPermissionDisabled') }
            ]);

            self.callRecordingOption = ko.observable();
            self.callRecordingSelectedOption = ko.observable();
            self.isCallRecordingToggleEnabled = ko.observable(false);
            self.isInboundCallRecordingEnabled = ko.observable(false);
            self.isOutboundCallRecordingEnabled = ko.observable(false);
            self.isCallRecordingPermissionInherited = ko.observable(false);
            self.isCallRecordingPermissionInheritedSelectedOption = ko.observable();
            self.currentNotificationId = null;
            self.notificationList = null;
            self.currentCallRecordingSubscribers = null;
            self.addPromptsViewModel = ko.observable();
            self.showValidation = ko.observable(false);
            self.isInboundCallRecordingWarningVerified = ko.observable(false);
            self.isOutboundCallRecordingWarningVerified = ko.observable(false);
            self.recordingNotificationSelected = ko.observable();
            self.recordingNotificationSelectedOption = ko.observable();
            self.selectedRecordingNotificationAudioObject = ko.observable();

            self.customerAgreesToNotifyInboundIsValid = ko.pureComputed(() => {
                if (self.showValidation() && self.isInboundCallRecordingEnabled()) {
                    return self.isInboundCallRecordingWarningVerified();
                }
                return true;
            });

            self.customerAgreesToNotifyOutboundIsValid = ko.pureComputed(() => {
                if (self.showValidation() && self.isOutboundCallRecordingEnabled()) {
                    return self.isOutboundCallRecordingWarningVerified();
                }
                return true;
            });

            self.toggleMessageKey = ko.pureComputed(() => {
                return self.isCallRecordingToggleEnabled() ? 'editPhoneNumber:enabled' : 'editPhoneNumber:disabled';
            });

            self.showPreview = ko.pureComputed(() => {
                if (self.isInboundCallRecordingEnabled() && self.recordingNotificationSelected()) {
                    if (self.recordingNotificationSelected().recordingNotificationId !== null &&
                        self.recordingNotificationSelected().recordingNotificationId !== "") {
                        return true;
                    }
                }

                return false;
            });

            self.showWarningMessage = ko.pureComputed(() => {
                return !((self.recordingNotificationSelected() && self.recordingNotificationSelected().isBuiltIn() === false) || self.isInboundCallRecordingEnabled() === false);
            });

            self.dispose = () => {
                _disposables.forEach((disposable) => {
                    disposable.dispose();
                });

                _disposables = [];
            };

            self.activate = (
                isInboundCallingEnabled,
                isCallRecordingToggleEnabled,
                isInboundCallRecordingEnabled,
                isOutboundCallRecordingEnabled,
                isCallRecordingPermissionInherited,
                currentNotificationId,
                notificationList,
                isInboundCallRecordingWarningVerified,
                inboundCallRecordingNotificationSelected,
                isOutboundCallRecordingWarningVerified,
                subscriberSelectionViewModel
            ) => {
                self.subscriberSelectionViewModel = subscriberSelectionViewModel;
                self.subscriberSelectionViewModel.subscriberSelectionViewModel().membershipDescription(_i18n.t('editPhoneNumberCallRecordingTab:manualDescription'));
                self.subscriberSelectionViewModel.yellowBoxInheritedAction(_i18n.t('editPhoneNumberCallRecordingTab:action'));
                self.subscriberSelectionViewModel.yellowBoxAlternateInheritedAction(_i18n.t('editPhoneNumberCallRecordingTab:actionAlternate'));
                self.subscriberSelectionViewModel.yellowBoxManualOption(_i18n.t('editPhoneNumberCallRecordingTab:inheritPermissionDisabled'));
                self.subscriberSelectionViewModel.yellowBoxGrantedPermission(_i18n.t('editPhoneNumberCallRecordingTab:grantPermissions'));
                self.subscriberSelectionViewModel.inheritedSubscriptionsDescription(_i18n.t('editPhoneNumberCallRecordingTab:inheritedDescription'));

                return _initialize(isInboundCallingEnabled, isCallRecordingToggleEnabled, isInboundCallRecordingEnabled, isOutboundCallRecordingEnabled, isCallRecordingPermissionInherited, currentNotificationId, notificationList, isInboundCallRecordingWarningVerified, inboundCallRecordingNotificationSelected, isOutboundCallRecordingWarningVerified);
            };

            const _initialize = (
                isInboundCallingEnabled,
                isCallRecordingToggleEnabled,
                isInboundCallRecordingEnabled,
                isOutboundCallRecordingEnabled,
                isCallRecordingPermissionInherited,
                currentNotificationId,
                notificationList,
                isInboundCallRecordingWarningVerified,
                inboundRecordingNotificationSelected,
                isOutboundCallRecordingWarningVerified
            ) => {
                return _promiseFactory.defer((initPromise) => {
                    self.isCallRecordingToggleEnabled = isCallRecordingToggleEnabled;
                    self.isInboundCallRecordingEnabled = isInboundCallRecordingEnabled;
                    self.isOutboundCallRecordingEnabled = isOutboundCallRecordingEnabled;
                    self.isCallRecordingPermissionInherited = isCallRecordingPermissionInherited;
                    self.currentNotificationId = currentNotificationId;
                    self.notificationList = notificationList;
                    self.isInboundCallRecordingWarningVerified = isInboundCallRecordingWarningVerified;
                    self.isOutboundCallRecordingWarningVerified = isOutboundCallRecordingWarningVerified;
                    self.recordingNotificationSelected = inboundRecordingNotificationSelected;
                    self.isCallRecordingPermissionInheritedSelectedOption(
                        self.isCallRecordingPermissionInherited() ? _inheritanceConstants.enabled : _inheritanceConstants.disabled
                    );

                    _initializeCallRecordingSelectedOption();
                    _onCallRecordingChanged();

                    let initPromiseFactory = new PromiseFactoryConstructor();
                    initPromiseFactory.defer((callRecordingNotificationsPromise) => {
                        _initializeRecordingNotificationList()
                            .fail(callRecordingNotificationsPromise.reject)
                            .done(callRecordingNotificationsPromise.resolve);
                    });
                    initPromiseFactory.wait()
                        .fail(initPromise.reject)
                        .done(() => {
                            _disposables.push(self.isCallRecordingPermissionInheritedSelectedOption.subscribe(_updateInheritanceObservable));
                            _disposables.push(self.isCallRecordingToggleEnabled.subscribe(_onCallRecordingChanged));
                            _disposables.push(self.callRecordingSelectedOption.subscribe(_onCallRecordingChanged));
                            _disposables.push(self.recordingNotificationSelectedOption.subscribe(_updateRecordingNotificationSelectedObservable));
                            initPromise.resolve();
                        });
                });
            };
        };
    });
