define('presentation/settings/humanDetection/viewModels/addHumanDetectionPhoneNumberViewModel',[
    'businessServices/router/router',
    'settings/navigationConfiguration',
    'common/promises/promiseFactory',
    'presentation/settings/humanDetection/validators/humanDetectionValidator',
    'businessServices/state/modelStateObserver',
    'presentation/settings/humanDetection/facades/humanDetectionFacade',
], function (
    /** @type typeof import('businessServices/router/router') */
    _router,
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactoryConstructor
) {
    return function () {
        /** @typedef {import('presentation/settings/humanDetection/viewModels/addHumanDetectionPhoneNumberViewModel')} AddHumanDetectionPhoneNumberViewModel } */
        /** @typedef {import('presentation/settings/humanDetection/facades/humanDetectionFacade')} HumanDetectionFacade } */

        /** @type {AddHumanDetectionPhoneNumberViewModel}*/
        let self = this;

        /** @type {(typeof PromiseFactory)["prototype"]} */
        const _promiseFactory = new PromiseFactoryConstructor();

        /** @type {HumanDetectionFacade}*/
        let _facade = null;

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const ValidatorConstructor = require('presentation/settings/humanDetection/validators/humanDetectionValidator');

        self.newHumanDetectionPhoneNumber = ko.observable("").extend({observeState: true});
        self.countryAbbreviation = ko.observable("us");
        self.modelStateObserver = null;
        self.validator = null;

        const _returnToHumanDetection = () => {
            _router.navigate(_navigationConfiguration.routesById.humanDetection.url);
        };

        self.validate = () => self.validator.validate();

        self.cancelForm = () => {
            self.modelStateObserver.restoreData();
            _returnToHumanDetection();
        };

        self.savePage = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                self.validator.validate()
                    .fail(deferredObject.reject)
                    .done((/** @type {boolean}*/isValid) => {
                        if (isValid === false) {
                            deferredObject.resolve(false);
                            return;
                        }

                        _facade.createAllowListedPhoneNumber(self.newHumanDetectionPhoneNumber())
                            .fail((error) => {
                                error.countryAbbreviation = self.countryAbbreviation();
                                error.newHumanDetectionPhoneNumber = self.newHumanDetectionPhoneNumber();
                                deferredObject.reject(error);
                            })
                            .done(() => {
                                self.modelStateObserver.saveData();
                                deferredObject.resolve();
                                _returnToHumanDetection();
                            });
                    });
            });
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/humanDetection/facades/humanDetectionFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
            self.modelStateObserver.commitData();

            self.validator = new ValidatorConstructor();
            self.validator.registerViewModel(self);

            return _promiseFactory.wait();
        };
    };
});

