define('presentation/settings/schedules/presentationObjects/scheduleSegmentPresentationObject',[],function() {
    return function(){
        const self = this;

        self.scheduleId = null;
        self.endHour = ko.observable();
        self.endMinute = ko.observable();
        self.isForSunday= ko.observable();
        self.isForMonday = ko.observable();
        self.isForTuesday = ko.observable();
        self.isForWednesday = ko.observable();
        self.isForThursday = ko.observable();
        self.isForFriday = ko.observable();
        self.isForSaturday = ko.observable();
        self.sequence = ko.observable();
        self.startHour = ko.observable();
        self.startMinute = ko.observable();
    };
});

