define('presentation/messages/facades/messagesSidebarFacade',[
    'common/time/datetimeFormatter',
    'common/converters/phoneNumberFormatter',
    'constants/attachmentFileTypesConstants',
    'constants/deliveryStatus',
    'constants/smsConversationMessageAttachmentStatus',
    'persistence/dataProviders/webMessagingDataProvider',
    'presentation/messages/presentationObjects/sidebarConversationPresentationObject',
    'presentation/messages/dataSources/conversationReadStateDataSource',
    'presentation/messages/utils/messagesHelper',
    'common/businessObjects/phoneNumber'
], function(
    /** @type typeof import('common/time/datetimeFormatter') */
    DateTimeFormatter,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('constants/attachmentFileTypesConstants') */
    AttachmentTypes,
    /** @type typeof import('constants/deliveryStatus') */
    DeliveryStatus,
    /** @type typeof import('constants/smsConversationMessageAttachmentStatus') */
    SmsConversationMessageAttachmentStatus,
    /** @type typeof import('persistence/dataProviders/webMessagingDataProvider') */
    WebMessagingDataProvider,
    /** @type typeof import('presentation/messages/presentationObjects/sidebarConversationPresentationObject') */
    SidebarConversation,
    /** @type import('presentation/messages/dataSources/conversationReadStateDataSource') */
    ConversationReadState,
    /** @type typeof import('presentation/messages/utils/messagesHelper') */
    MessagesHelper,
    /** @type typeof import('common/businessObjects/phoneNumber') */
    PhoneNumber
) {
    /** @typedef {import('constants/deliveryStatus')} DeliveryStatus */
    /** @typedef {import('presentation/messages/facades/messagesSidebarFacade')} MessagesSidebarFacade */
    /** @typedef {import('common/businessObjects/phoneNumber')} PhoneNumber */

    const _dateTimeFormatter = new DateTimeFormatter();
    const _phoneNumberFormatter = new PhoneNumberFormatter();
    const _webMessagingDataProvider = new WebMessagingDataProvider();
    const _messagesHelper = new MessagesHelper();

    /** @type import('common/promises/promiseFactory') */
    let _promiseFactory = null;

    /** @type {MessagesSidebarFacade["onConversationCreated"]} */
    const _onSmsConversationCreated = (callback) => {
        _webMessagingDataProvider.onSmsConversationCreated((event) => {
            _getUserConversationById(event.smsConversationId)
                .then(callback);
        });
    };

    /** @type {MessagesSidebarFacade["onSmsConversationDraftChanged"]} */
    const _onSmsConversationDraftChanged = (callback) => {
        _webMessagingDataProvider.onSmsConversationMessageAttachmentUpdated(({smsConversationMessageAttachmentId}) => {
            _webMessagingDataProvider
                .getSmsConversationMessageAttachmentDraft(smsConversationMessageAttachmentId)
                .then(draft => {
                    if (draft) {
                        const formattedDraft = _formatSidebarDraftForDisplay(draft);
                        callback(formattedDraft);
                    }
                });
        });

        _webMessagingDataProvider.onSmsConversationDraftChanged((draft) => {
            const formattedDraft = _formatSidebarDraftForDisplay(draft);
            callback(formattedDraft);
        });
    };

    /** @type {MessagesSidebarFacade["onSmsConversationDraftCreated"]} */
    const _onSmsConversationDraftCreated = (callback) => {
        _webMessagingDataProvider.onSmsConversationDraftCreated((draft) => {
            const formattedDraft = _formatSidebarDraftForDisplay(draft);
            callback(formattedDraft);
        });
    };

    // TODO - web messaging - This creates a new subscription every time a draft is deleted. Not sure we want this
    /** @type {MessagesSidebarFacade["onSmsConversationDraftDeleted"]} */
    const _onSmsConversationDraftDeleted = (callback) => {
        _webMessagingDataProvider.onSmsConversationDraftDeleted(({smsConversationDraftId}) => {
            callback(smsConversationDraftId);
        });
    };

    /** @type {MessagesSidebarFacade["onConversationNameChanged"]} */
    const _onSmsConversationNameChanged = (callback) => {
        _webMessagingDataProvider.onSmsConversationNameChanged((event) => {
            callback(event);
        });
    };

    /** @type {MessagesSidebarFacade["onSmsConversationUserAssociationChanged"]} */
    const _onSmsUserConversationAssociationChanged = (callback) => {
        _webMessagingDataProvider.onSmsConversationUserAssociationChanged((event) => {
            callback(event);
        });
    };

    /**
     *
     * @param {ISmsUserConversationWithMostRecentMessageProjectionResult} conversation
     * @param {ISmsUserDraftProjectionResult[]} drafts
     * @returns {SidebarConversation["prototype"]}
     */
    const _formatSidebarConversationForDisplay = (conversation, drafts) => {
        const members = conversation.memberPhoneNumbers.split(',');
        const memberPhoneNumbers = members.map(r => new PhoneNumber(r));

        const lastMessage = conversation.lastMessage;
        const membersCount = memberPhoneNumbers.length;
        const cardPhoneNumber = _formatPhoneNumbers(memberPhoneNumbers);
        const conversationDate = _dateTimeFormatter.formatWithinMinOrShortDayDate(conversation.lastMessageDateTime);
        const failedSending = lastMessage && lastMessage.deliveryStatus === DeliveryStatus.Failed;
        const lastMessageDateTime = Date.parse(conversation.lastMessageDateTime);
        const conversationLastViewedDateTime = Date.parse(conversation.lastViewedDateTime);
        const hasUnreadMessages = lastMessageDateTime > conversationLastViewedDateTime;
        const lastMessageText = lastMessage && _messagesHelper.determineMessageTextFromLastMessage(lastMessage);

        const draft = drafts.find(d => d.smsConversationId === conversation.smsConversationId);
        const draftMessage = !hasUnreadMessages && _determineDraftMessage(draft);

        const sidebarConversation = new SidebarConversation();
        sidebarConversation.accountHostedNumberId = conversation.accountHostedNumberId;
        sidebarConversation.isDraft = false;
        sidebarConversation.hasUnsentMessage(!!draftMessage);
        sidebarConversation.smsConversationDraftId = null;
        sidebarConversation.smsConversationId = conversation.smsConversationId;
        sidebarConversation.readState = ConversationReadState.getOrGenerateInfo(conversation.smsConversationId);
        sidebarConversation.lastMessageDateTime(lastMessageDateTime);
        sidebarConversation.conversationLastViewedDateTime(conversationLastViewedDateTime);
        sidebarConversation.cardPhoneNumber(cardPhoneNumber);
        sidebarConversation.conversationName(conversation.smsConversationName);
        sidebarConversation.conversationDate(conversationDate);
        sidebarConversation.failedSending(failedSending);
        sidebarConversation.members(members);
        sidebarConversation.membersCount(membersCount);
        sidebarConversation.message(draftMessage || lastMessageText);
        sidebarConversation.lastMessage(lastMessageText);
        sidebarConversation.isMarkedAsNew(conversation.isMarkedAsNew);

        return sidebarConversation;
    };

    const _determineDraftMessage = (/** @type {ISmsUserDraftProjectionResult} */ draft) => {
        if (!draft) {
            return null;
        }

        if (draft.content) {
            return draft.content;
        }

        const allAttachments = draft.attachments
            .filter(attachment => {
                switch (attachment.conversionStatus) {
                    case SmsConversationMessageAttachmentStatus.ConversionError:
                    case SmsConversationMessageAttachmentStatus.FileSizeTooLarge:
                        return false;
                    default:
                        return true;
                }
            });

        if (!allAttachments.length) {
            return null;
        }

        let videoAttachments = 0;
        let imageAttachments = 0;
        let audioAttachments = 0;
        let otherAttachments = 0;

        for (const attachment of allAttachments) {
            const contentType = attachment.contentType;

            if (contentType.includes(AttachmentTypes.video)) {
                videoAttachments++;
            }
            else if (contentType.includes(AttachmentTypes.image)) {
                imageAttachments++;
            }
            else if (contentType.includes(AttachmentTypes.audio)) {
                audioAttachments++;
            }
            else {
                otherAttachments++;
            }
        }

        const mixedMedia = otherAttachments ||
            (videoAttachments && imageAttachments) ||
            (videoAttachments && audioAttachments) ||
            (imageAttachments && audioAttachments);

        if (mixedMedia) {
            const plural = allAttachments.length > 1 ? "s" : "";
            return `${allAttachments.length} File${plural}`;
        }

        if (videoAttachments) {
            const plural = videoAttachments > 1 ? "s" : "";
            return `${videoAttachments} Video${plural}`;
        }

        if (imageAttachments) {
            const plural = imageAttachments > 1 ? "s" : "";
            return `${imageAttachments} Photo${plural}`;
        }

        const plural = audioAttachments > 1 ? "s" : "";
        return `${audioAttachments} Audio Clip${plural}`;
    };

    const _formatSidebarDraftForDisplay = (/** @type {ISmsUserDraftProjectionResult} */draft) => {
        const { lastUpdateDateTime, recipients, smsConversationDraftId, smsConversationId, accountHostedNumberId } = draft;

        const membersCount = recipients.length;
        const conversationDate = _dateTimeFormatter.formatWithinMinOrShortDayDate(lastUpdateDateTime);

        const memberPhoneNumbers = recipients.map(r => new PhoneNumber(r.phoneNumber));
        const cardPhoneNumber = memberPhoneNumbers.length ? _formatPhoneNumbers(memberPhoneNumbers) : `...`;
        const message = _determineDraftMessage(draft) || (smsConversationId ? `` : `...`);

        const sidebarDraft = new SidebarConversation();
        sidebarDraft.accountHostedNumberId = accountHostedNumberId;
        sidebarDraft.isDraft = true;
        sidebarDraft.hasUnsentMessage(true);
        sidebarDraft.smsConversationDraftId = smsConversationDraftId;
        sidebarDraft.smsConversationId = smsConversationId;
        sidebarDraft.conversationLastViewedDateTime(Date.parse(lastUpdateDateTime));
        sidebarDraft.conversationDate(conversationDate);
        sidebarDraft.membersCount(membersCount || 1);
        sidebarDraft.cardPhoneNumber(cardPhoneNumber);
        sidebarDraft.message(message);

        return sidebarDraft;
    };

    const _formatPhoneNumbers = (/** @type {PhoneNumber[]} */memberPhoneNumbers) => {
        const [first, ...rest] = memberPhoneNumbers;
        let cardPhoneNumber = _phoneNumberFormatter.toNumericDefault(first.getDigits());
        if (rest.length !== 0) {
            cardPhoneNumber = `${cardPhoneNumber}...(+${rest.length})`;
        }

        return cardPhoneNumber;
    };

    /** @type {MessagesSidebarFacade["dispose"]} */
    const _dispose = () => {
        _webMessagingDataProvider.disposeOfEvents();
    };

    /** @type {MessagesSidebarFacade["init"]} */
    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;
    };

    /** @type {MessagesSidebarFacade["getUserConversations"]} */
    const _getUserConversations = (request) => {
        return _promiseFactory.defer((promise) => {
            _webMessagingDataProvider.getUserConversationsWithMostRecentMessage(request)
                .fail(promise.reject)
                .done((response) => {
                    const conversations = (response.conversations || []).map((convo) => _formatSidebarConversationForDisplay(convo, response.drafts || []));
                    const drafts = (response.drafts || []).filter(d => !d.smsConversationId).map(_formatSidebarDraftForDisplay);
                    conversations.push(...drafts);

                    promise.resolve(conversations);
                });
        });
    };

    /** @type {MessagesSidebarFacade["getUserConversation"]} */
    const _getUserConversation = (smsConversationId) => {
        return _promiseFactory.defer((promise) => {
            _webMessagingDataProvider.getUserConversationWithMostRecentMessageById(smsConversationId)
                .fail(promise.reject)
                .done((response) => {
                    const conversation = _formatSidebarConversationForDisplay(response.conversation, response.draft ? [response.draft] : []);
                    promise.resolve(conversation);
                });
        });
    };

    /**
     * @param {string} smsConversationId
     * @returns {JQuery.Deferred<SidebarConversation["prototype"]>}
     */
    const _getUserConversationById = (/** @type {string} */smsConversationId) => {
        return _promiseFactory.defer((promise) => {
            _webMessagingDataProvider.getUserConversationWithMostRecentMessageById(smsConversationId)
                .fail(promise.reject)
                .done(({conversation, draft}) => {
                    if (conversation) {
                        const drafts = draft ? [draft] : [];
                        promise.resolve(_formatSidebarConversationForDisplay(conversation, drafts));
                        return;
                    }
                    promise.resolve();
                });
            });
    };

    /** @type {MessagesSidebarFacade["createDraftConversation"]} */
    const _createDraftConversation = () => {
        return _promiseFactory.defer((promise) => {
            _webMessagingDataProvider.createDraftConversation()
                .fail(promise.reject)
                .done(({smsConversationDraftId}) => {
                    promise.resolve(smsConversationDraftId);
                });
        });
    };

    /** @type {MessagesSidebarFacade["deleteDraftConversation"]} */
    const _deleteDraftConversation = (smsConversationDraftId) => {
        return _promiseFactory.defer((promise) => {
            _webMessagingDataProvider.deleteDraftConversation(smsConversationDraftId)
                .fail(promise.reject)
                .done(({smsConversationDraftId}) => {
                    promise.resolve(smsConversationDraftId);
                });
        });
    };

    /** @type {MessagesSidebarFacade["onSmsConversationMessage"]} */
    const _onSmsConversationMessage = (callBack) => {
        const sharedCallBack = (/** @type {{ smsConversationId: string }} */event) => {
            _getUserConversationById(event.smsConversationId)
                .then(callBack);
        };

        _webMessagingDataProvider.onSmsConversationMessageDeleted(sharedCallBack);
        _webMessagingDataProvider.onSmsConversationMessageUpdated(messageUpdateInfo => {
            if (messageUpdateInfo.deliveryStatus === DeliveryStatus.QueuedForSend) {
                return;
            }

            sharedCallBack(messageUpdateInfo);
        });
        _webMessagingDataProvider.onSmsConversationMessageReceived(sharedCallBack);
        _webMessagingDataProvider.onSmsConversationMessageSent(sharedCallBack);
    };

    return function() {
        /** @type {MessagesSidebarFacade} */
        const self = this;

        self.init = _init;
        self.dispose = _dispose;

        self.getUserConversation = _getUserConversation;
        self.getUserConversations = _getUserConversations;
        self.createDraftConversation = _createDraftConversation;
        self.deleteDraftConversation = _deleteDraftConversation;
        self.onConversationCreated = _onSmsConversationCreated;
        self.onConversationNameChanged = _onSmsConversationNameChanged;
        self.onSmsConversationDraftChanged = _onSmsConversationDraftChanged;
        self.onSmsConversationDraftCreated = _onSmsConversationDraftCreated;
        self.onSmsConversationDraftDeleted = _onSmsConversationDraftDeleted;
        self.onSmsConversationUserAssociationChanged = _onSmsUserConversationAssociationChanged;
        self.onSmsConversationMessage = _onSmsConversationMessage;
    };
});

