define('presentation/messages/viewModels/conversationDetailsLinksViewModel',[
], function() {
    return function(
        /** @type {() => void} */
        onSeeAllLinksClickedCallback,
        /** @type import('presentation/messages/utils/conversationLinksManager') */
        linksManager
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationDetailsLinksViewModel')} ConversationDetailsLinksViewModel */

        /** @type {ConversationDetailsLinksViewModel}*/
        const self = this;

        const MAX_LINKS_TO_DISPLAY = 4;

        self.showSection = ko.pureComputed(() => {
            const isLoading = linksManager.isLoading();
            const {length} = linksManager.messageLinkPreviews();
            return !isLoading && !!length;
        });

        self.linkPreviews = ko.pureComputed(() => {
            const linkPreviews = linksManager.messageLinkPreviews();
            return linkPreviews.slice(0, MAX_LINKS_TO_DISPLAY);
        });

        self.showSeeAll = ko.pureComputed(() => {
            const isLoading = linksManager.isLoading();
            const linkPreviews = linksManager.messageLinkPreviews();
            // Show "See All" if there are more than 4 links
            return !isLoading && linkPreviews && linkPreviews.length > MAX_LINKS_TO_DISPLAY;
        });

        self.onSeeAllClick = () => {
            onSeeAllLinksClickedCallback();
        };

        self.activate = () => {

        };
    };
});
