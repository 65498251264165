define('presentation/common/infoBox/viewModels/infoBoxShellViewModel',[], function () {
    return function () {
        const self = this;

        let _settings;

        self.activate = (settings) => {
            _settings = settings;

            _initialize();
        };

        const _initialize = () => {

        };
    };
});
