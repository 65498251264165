define('presentation/messages/presentationObjects/sidebarConversationPresentationObject',[
        'businessServices/contacts/contactsStateSingleton',
    ],
   function (
        /** @type import('businessServices/contacts/contactsStateSingleton') */
        _contactsState
) {
    return function () {
        /** @type import('presentation/messages/presentationObjects/sidebarConversationPresentationObject') */
        const self = this;
        self.smsConversationId = null;
        self.accountHostedNumberId = null;

        /** @type {IReadInfo} */
        self.readState = null;

        self.fadeIn = ko.observable(false);
        self.fadeOut = ko.observable(false);
        self.smsConversationDraftId = null;
        self.isDraft = false;
        self.hasUnsentMessage = ko.observable(false);
        self.cardPhoneNumber = ko.observable("");
        self.conversationName = ko.observable(null);
        self.lastMessageDateTime = ko.observable(null);
        self.conversationLastViewedDateTime = ko.observable(null);
        self.conversationDate = ko.observable(null);
        self.failedSending = ko.observable(false);
        self.isHoveringOverDeleteButton = ko.observable(false);
        self.members = ko.observableArray([]);
        self.membersCount = ko.observable(0);
        self.message = ko.observable("");
        self.lastMessage = ko.observable("");
        self.isMarkedAsNew = ko.observable(false);
        self.hasUnreadMessages = ko.pureComputed(() => {
            return self.lastMessageDateTime() !== null && self.lastMessageDateTime() > self.conversationLastViewedDateTime();
        });
        self.showConversationInNewState = ko.pureComputed(() => {
            if (!this.readState) {
                return false;
            }

            const isMarkedAsNew = this.readState.isMarkedAsNew();
            const lastMessageDate =  this.readState.lastMessageDate();
            const lastViewedDate =  this.readState.lastViewedDate();

            return isMarkedAsNew || (lastMessageDate > lastViewedDate);
        });
        self.conversationDisplayName = ko.pureComputed(() => {
            if (self.conversationName()) {
                return self.conversationName();
            }

            if (self.cardPhoneNumber()) {
                const splitCardPhoneNumber = self.cardPhoneNumber().split('...');
                const numberToReplace = splitCardPhoneNumber[0];
                const contact = _contactsState.getContactByPhoneNumber(numberToReplace);
                if (contact) {
                    return splitCardPhoneNumber.length > 1 ? contact.displayName() + '...' + splitCardPhoneNumber[1] : contact.displayName();
                }

                return self.cardPhoneNumber();
            }

            return null;
        });
    };
});

