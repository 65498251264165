define('presentation/common/filterSortMenu/bindings/filterSortMenuBinding',[], function () {
    return {
        /** @type {KnockoutBindingHandler<HTMLElement, IFilterMenuBindingOptions>["init"]} */
        init: function (element, valueAccessor) {
            const {hideFilterMenu, isExpanded, maxHeight, minHeight} = ko.unwrap(valueAccessor());

            /** @type {boolean}*/
            let mouseDown = false;

            /** @type {number}*/
            let startX;

            /** @type {number}*/
            let scrollLeft;

            /** @type {IDisposable[]} */
            let disposables = [];

            //#region Resize Height
            const _pxToRem = (/** @type {number}*/pxValue) => {
                return pxValue * 1.0 / 16;
            };

            const _resizeFilterMenuPopup = () => {
                if (!isExpanded()) {
                    return;
                }

                const options = element.querySelectorAll(".filter-menu__option");
                const optionsCount = options.length;

                if (optionsCount === 0) {
                    filterMenuPopupEl.style.height = `${_pxToRem(minHeight)}rem`;
                    return;
                }

                const labelHeight = 51;
                const optionHeight = 56;
                const allLabelsHeight = labelHeight * 2;
                const contentHeight = optionHeight * optionsCount + allLabelsHeight;

                const clampedMinHeight = Math.max(minHeight, contentHeight);
                const clampedMaxHeight = Math.min(maxHeight, contentHeight);
                const remHeight = _pxToRem(Math.min(clampedMinHeight, clampedMaxHeight));

                filterMenuPopupEl.style.height = `${remHeight}rem`;
            };
            //#endregion

            //#region Outside Clicked Check
            const _checkIfOutsideDropdownClicked = (/** @type {MouseEvent}*/evt) => {
                /** @type {EventTarget}*/
                let clickedElement = evt.target;
                do {
                    if (clickedElement === element) {
                        return;
                    }
                    // Go up the DOM
                    clickedElement = clickedElement.parentNode;
                } while (clickedElement);
                hideFilterMenu(true);
            };
            //#endregion

            //#region Click & Drag
            const _startDragging = (/** @type {MouseEvent}*/evt) => {
                mouseDown = true;
                startX = evt.pageX - slidingPills.offsetLeft;
                scrollLeft = slidingPills.scrollLeft;
                slidingPills.style.cursor = 'grabbing';
            };

            const _stopDragging = () => {
                mouseDown = false;
                slidingPills.style.cursor = 'grab';
            };

            const _onMouseMoveOnPills = (/** @type {MouseEvent}*/evt) => {
                evt.preventDefault();
                if (!mouseDown) {
                    return;
                }
                const x = evt.pageX - slidingPills.offsetLeft;
                const scroll = x - startX;
                slidingPills.scrollLeft = scrollLeft - scroll;
            };
            //#endregion

            /** @type {HTMLElement}*/
            const slidingPills = element.getElementsByClassName('filter-menu__filter-pills')[0];

            /** @type {HTMLElement}*/
            const filterMenuPopupEl = element.querySelector(".filter-menu__popup");

            disposables.push(isExpanded.subscribe(_resizeFilterMenuPopup));

            document.addEventListener("click", _checkIfOutsideDropdownClicked);
            slidingPills.addEventListener("mousemove", _onMouseMoveOnPills);
            slidingPills.addEventListener('mousedown', _startDragging, false);
            slidingPills.addEventListener('mouseup', _stopDragging, false);
            slidingPills.addEventListener('mouseleave', _stopDragging, false);

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                disposables.forEach(disposable => disposable.dispose());
                document.removeEventListener('click', _checkIfOutsideDropdownClicked);
                slidingPills.removeEventListener("mousemove", _onMouseMoveOnPills);
                slidingPills.removeEventListener('mousedown', _startDragging);
                slidingPills.removeEventListener('mouseup', _stopDragging);
                slidingPills.removeEventListener('mouseleave', _stopDragging);
            });
        }
    };
});
