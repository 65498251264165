define('presentation/settings/users/validators/deactivateUserModalViewModelValidator',[
    'constants/autoAttendantVoiceRouteEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = (viewModel, facade) => {
            _facade = facade;
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("sendAllCallsToSubscriber")
                .addValidationI18n(_validateSubscriberSelection, 'deactivateUserModal:selectionRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateSubscriberSelection = (subscriber) => {
            return subscriber && subscriber !== _voiceRouteEnumerations.pleaseSelect;
        };

    };
});

