define('presentation/common/searchTextBox/viewModels/searchTextBoxViewModel',['i18next'], function (
    /** @type import('i18next') */
    i18n
) {
    return function () {
        const self = this;

        self.searchText = null;
        self.placeholder = null;
        self.disabled = false;
        self.tooltip = null;
        self.showTooltip = false;
        const DEFAULT_PLACEHOLDER = i18n.t('search');

        self.activate = function (settings) {
            if (settings.value === null) {
                throw new Error("'value' binding is required for searchTextBox");
            }

            const disabled = ko.unwrap(settings.disabled);
            self.disabled = typeof disabled === "boolean" ? disabled : null;

            if (settings.tooltip) {
                self.tooltip = i18n.t(ko.unwrap(settings.tooltip));
            }

            const showTooltip = ko.unwrap(settings.showTooltip);
            self.showTooltip = typeof showTooltip === "boolean" ? showTooltip : !!self.tooltip;

            self.searchText = settings.value;

            if (settings.placeholder) {
                self.placeholder = i18n.t(ko.unwrap(settings.placeholder));
            } else {
                self.placeholder = DEFAULT_PLACEHOLDER;
            }
        };
    };
});

