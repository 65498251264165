define('presentation/settings/accountProfile/validators/accountProfilePersonalValidator',['presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;
        let _viewModel = null;

        self.registerViewModel = (viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel);

            const validationRules = new ValidationRulesConstructor();
            validationRules.field("accountHolderFirstName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'accountProfilePersonal:firstNameRequired');
            validationRules.field("accountHolderLastName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'accountProfilePersonal:lastNameRequired');

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        self.resetValidation = () => {
            _viewModel.accountHolderFirstName.resetValidation();
            _viewModel.accountHolderLastName.resetValidation();
        };
    };
});

