define('presentation/contacts/viewModels/addContactAddressViewModel',[
    'businessServices/country/countryRegions',
    'businessServices/state/modelStateObserver',
    'constants/contactAddressCountryLabelEnumerations',
    'constants/contactAddressLabelEnumerations',
    'presentation/contacts/validators/addContactAddressViewModelValidator'
], function (
    /** @type typeof import('businessServices/country/countryRegions') */
    CountryRegionsConstructor,
    /** @type typeof import('businessServices/state/modelStateObserver') */
    ModelStateObserver,
    /** @type typeof import('constants/contactAddressCountryLabelEnumerations') */
    ContactAddressCountryLabel,
    /** @type typeof import('constants/contactAddressLabelEnumerations') */
    ContactAddressLabel
) {
    /** @typedef { import('presentation/contacts/viewModels/addContactViewModel') } AddContactViewModel */

    return function (/** @type AddContactViewModel */ parent) {

        let self = this;

        self.parent = parent;

        self.modelStateObserver = null;
        self.validator = null;

        let _countryRegions = new CountryRegionsConstructor();

        self.states = _countryRegions.countryList[0].statesProvinces.map((stateProvince) => {
            return {
                key: stateProvince.name,
                value: stateProvince.name
            };
        });

        self.provinces = _countryRegions.countryList[1].statesProvinces.map((stateProvince) => {
            return {
                key: stateProvince.name,
                value: stateProvince.name
            };
        });

        self.accountContactAddressId = '';
        self.label = ko.observable(ContactAddressLabel.Work.toString()).extend({observeState: true});
        self.country = ko.observable(ContactAddressCountryLabel.US).extend({observeState: true});
        self.addressLineOne = ko.observable('').extend({observeState: true});
        self.addressLineTwo = ko.observable('').extend({observeState: true});
        self.city = ko.observable('').extend({observeState: true});
        self.region = ko.observable('').extend({observeState: true});
        self.postalCode = ko.observable('').extend({observeState: true});
        self.isFullAddressValid = ko.observable();

        self.showStatesSelect = ko.pureComputed(() => {
            return self.country().toString() === ContactAddressCountryLabel.US.toString();
        });

        self.showOtherInputs = ko.pureComputed(() => {
            return self.country().toString() === ContactAddressCountryLabel.Other.toString();
        });

        self.addressLineTwoLabel = ko.pureComputed(() => {
            if (self.country().toString() === ContactAddressCountryLabel.Other.toString()) {
                return 'addContact:addressLineOne';
            } else {
                return 'addContact:addressLineTwo';
            }
        });

        self.cityLabel = ko.pureComputed(() => {
            if (self.country().toString() === ContactAddressCountryLabel.Other.toString()) {
                return 'addContact:addressLineOne';
            } else {
                return 'addContact:city';
            }
        });

        self.regionLabel = ko.pureComputed(() => {
            if (self.country().toString() === ContactAddressCountryLabel.US.toString()) {
                return 'addContact:state';
            } else if (self.country().toString() === ContactAddressCountryLabel.CA.toString()) {
                return 'addContact:province';
            } else {
                return 'addContact:addressLineOne';
            }
        });

        self.postalCodeLabel = ko.pureComputed(() => {
            if (self.country().toString() === ContactAddressCountryLabel.US.toString()) {
                return 'addContact:zip';
            } else if (self.country().toString() === ContactAddressCountryLabel.CA.toString()) {
                return 'addContact:postal';
            } else {
                return 'addContact:addressLineOne';
            }
        });

        self.country.subscribe(function() {
            self.region('');
        });

        self.onRemoveAddressClick = () => {
            self.parent.onRemoveAddressClick(self);
        };

        self.cancelForm = () => {
            if (self.addressLineOne.isDirty) {
                self.addressLineOne.forceUpdate(true);
            }
            if (self.addressLineTwo.isDirty) {
                self.addressLineTwo.forceUpdate(true);
            }
            if (self.city.isDirty) {
                self.city.forceUpdate(true);
            }
            if (self.postalCode.isDirty) {
                self.postalCode.forceUpdate(true);
            }

            self.modelStateObserver.restoreData();
        };

        self.validate = () => {
            return self.validator.validate();
        };

        const _initialize = () => {
            const ValidatorConstructor = require('presentation/contacts/validators/addContactAddressViewModelValidator');
            self.validator = new ValidatorConstructor();
            self.validator.registerViewModel(self);

            self.modelStateObserver = new ModelStateObserver(self, true);
            self.modelStateObserver.commitData();
        };

        _initialize();
    };
});
