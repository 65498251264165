define('presentation/common/audioPlayer/audioPlayerKnockoutBinding',['businessServices/converters/durationTimeFormatter'],
    function () {
        return function (ko) {
            const DurationTimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
            let durationTimeFormatter = new DurationTimeFormatterConstructor();

            const PLAY_BUTTON = "play";
            const PAUSE_BUTTON = "pause";
            const LOADING_SPINNER = "loading";

            const AUDIO_PLAYER_SELECTORS = {
                currentPositionText: '.audio-player__current-position span',
                elapsedDurationText: '.audio-player__elapsed-duration span',
                totalDurationText: 'audio-player__total-duration span',
                playButton: '.audio-player__play',
                pauseButton: '.audio-player__pause',
                progressSpinner: '.audio-player__loading-spinner',
                sliderBar: '.audio-player__circle-path',
            };

            const createInterval = (millisecondInterval, fn) => {
                let _monitoredInterval = null;

                const _start = () => {
                    if (_monitoredInterval === null) {
                        _monitoredInterval = setInterval(fn, millisecondInterval);
                    }
                };

                const _stop = () => {
                    if (_monitoredInterval !== null) {
                        clearInterval(_monitoredInterval);
                        _monitoredInterval = null;
                    }
                };

                return {
                    start : _start,
                    stop : _stop
                };
            };

            const createAudioStream = () => {
                let currentAudio = null;

                const _takeStream = (audio) => {
                    if (currentAudio !== null) {
                        currentAudio.pause();
                    }
                    currentAudio = audio;
                };

                return {
                    takeStream : _takeStream
                };
            };
            let _globalAudioStream = createAudioStream();

            const createPlayer = (element, settings) => {

                const showPlayButton = () => {
                    if (uiControls.visibleButton === PLAY_BUTTON) {
                        return;
                    }

                    uiControls.visibleButton = PLAY_BUTTON;
                    uiControls.$playButton.show();
                    uiControls.$pauseButton.hide();
                    uiControls.$progressSpinner.hide();
                };

                const showPauseButton = () => {
                    if (uiControls.visibleButton === PAUSE_BUTTON) {
                        return;
                    }

                    uiControls.visibleButton = PAUSE_BUTTON;
                    uiControls.$playButton.hide();
                    uiControls.$pauseButton.show();
                    uiControls.$progressSpinner.hide();
                };

                const showProgressSpinner = () => {
                    if (uiControls.visibleButton === LOADING_SPINNER) {
                        return;
                    }
                    uiControls.visibleButton = LOADING_SPINNER;
                    uiControls.$playButton.hide();
                    uiControls.$pauseButton.hide();
                    uiControls.$progressSpinner.show();
                };


                const onPlayClicked = () => {
                    showProgressSpinner();

                    // add a purely aesthetic .5s spinner animation
                    setTimeout(() => {
                        if (settings.onPlay() === undefined && settings.src().audioData() !== undefined) {
                            let audioSrc =  settings.src().audioData();
                            playTheAudio(audioSrc);
                        } else {
                            // Note: This eventually updates settings.src.audioData
                            settings.onPlay();
                        }
                    }, 500);
                };

                const onPauseClicked = () => {
                    audio.pause();
                };

                const onAudioEnded = () => {
                    updateButtonStates();
                    updateProgressIndicator();
                    updateElapsedDurationText(audio.duration);
                };

                const onAudioPause = () => {
                    updateButtonStates();
                    _updateProgressIndicatorInterval.stop();
                    updateProgressIndicator();
                };

                const onAudioPlay = () => {
                    updateButtonStates();
                    _updateProgressIndicatorInterval.start();
                };

                const onAudioPlaying = () => {
                    updateButtonStates();
                    _updateProgressIndicatorInterval.start();
                };

                const updateButtonStates = () => {
                    if (audio.ended) {
                        showPlayButton();
                        _updateProgressIndicatorInterval.stop();
                        updateProgressIndicator();
                    } else if (audio.paused) {
                        showPlayButton();
                    } else {
                        showPauseButton();
                    }
                };

                let _updateProgressIndicatorInterval = createInterval(100, () => {
                    updateProgressIndicator();
                });
                const updateProgressIndicator = () => {
                    const setSliderValue = (value, attempt) => {
                        attempt = attempt || 1;
                        if (attempt > 10) {
                            return;
                        }

                        let sliderWidget = uiControls.$sliderBar.data("slider");
                        if (!sliderWidget) {
                            setTimeout(() => {
                                setSliderValue(value, attempt + 1);
                            }, 100);
                        } else {
                            uiControls.$sliderBar.slider("value", value);
                        }
                    };

                    if ((audio.ended) || (audio.duration === 0) || (isNaN(audio.duration))) {
                        setSliderValue(0);
                        updateCurrentPositionText(0);
                    } else {
                        let currentTimePercentage = (audio.currentTime / audio.duration) * 100;
                        setSliderValue(currentTimePercentage);
                        updateCurrentPositionText(audio.currentTime);
                    }
                };

                const updateCurrentPositionText = (currentTime) => {
                    uiControls.$currentPositionText.text(durationTimeFormatter.formatTotalSeconds(currentTime));
                    uiControls.$elapsedDurationText.text(durationTimeFormatter.formatSecondsSummary(currentTime));
                };

                const updateTotalDurationText = (totalDuration) => {
                    uiControls.$totalDurationText.text(durationTimeFormatter.formatTotalSeconds(totalDuration));
                };

                const updateElapsedDurationText = (totalDuration) => {
                    uiControls.$elapsedDurationText.text(durationTimeFormatter.formatSecondsSummary(totalDuration));
                };

                const playTheAudio = (audioSrc) => {
                    if ((audioSrc === undefined) || (audioSrc === null) || (audioSrc === "about:blank")) {
                        // Ignore this request because it is invalid
                        return false;
                    }
                    if (audio.src !== audioSrc) {
                        audio.src = audioSrc;
                    }

                    _globalAudioStream.takeStream(audio);
                    audio.play();
                    return true;
                };

                const onAudioSrcChanged = (audioSrc) => {
                    if ((audioSrc === undefined) || (audioSrc === null) || (audioSrc === "about:blank")) {
                        // Ignore this request because it is invalid
                        return false;
                    }
                    if (audio.src !== audioSrc) {
                        audio.src = audioSrc;
                    }

                    if(audio.autoplay === true) {
                        _globalAudioStream.takeStream(audio);
                        audio.play()
                            .catch((error) => {
                                showPlayButton();
                            });
                    } else {
                        showPlayButton();
                    }
                    return true;
                };

                const dispose = () => {
                    audio.pause();
                };

                // Monitor changes to "src" because the audioData on the new object will eventually be populated with the source url.
                settings.src.subscribe((audioPresentationObject) => {
                    let audioSrc = audioPresentationObject.audioData();
                    updateTotalDurationText(audio.duration);
                    // Try to play the audio
                    if (onAudioSrcChanged(audioSrc) === false) {
                        // Couldn't play the audio, so lets wait for the data to be updated in the caller's facade
                        let audioDataSubscription = audioPresentationObject.audioData.subscribe((newAudioData) => {
                            if(audio.autoplay === true) {
                                if (onAudioSrcChanged(newAudioData) === true) {
                                    // We received a valid update from the caller, so we can stop watching for the audioData to change.
                                    audioDataSubscription.dispose();
                                }
                            }
                        });
                    }
                });

                let audio = new Audio();
                audio.preload = "none";
                audio.volume = 1;
                audio.autoplay = settings.autoPlay;
                audio.addEventListener('ended', onAudioEnded);
                audio.addEventListener('pause', onAudioPause);
                audio.addEventListener('play', onAudioPlay);
                audio.addEventListener('playing', onAudioPlaying);

                let uiControls = {
                    $playButton : $(AUDIO_PLAYER_SELECTORS.playButton, element),
                    $pauseButton : $(AUDIO_PLAYER_SELECTORS.pauseButton, element),
                    $progressSpinner: $(AUDIO_PLAYER_SELECTORS.progressSpinner, element),
                    $sliderBar : $(AUDIO_PLAYER_SELECTORS.sliderBar, element),
                    $currentPositionText : $(AUDIO_PLAYER_SELECTORS.currentPositionText, element),
                    $elapsedDurationText : $(AUDIO_PLAYER_SELECTORS.elapsedDurationText, element),
                    $totalDurationText : $(AUDIO_PLAYER_SELECTORS.totalDurationText, element),
                    visibleButton : PLAY_BUTTON
                };
                uiControls.$playButton.show();
                uiControls.$pauseButton.hide();
                uiControls.$progressSpinner.hide();
                uiControls.$playButton.on('click', (event) => {
                    event.stopPropagation();
                    onPlayClicked();
                });
                uiControls.$pauseButton.on('click', (event) => {
                    event.stopPropagation();
                    onPauseClicked();
                });

                uiControls.$sliderBar.slider(
                    {
                        max : 100,
                        min: 0,
                        range: 'min',
                        step: 0.1,
                        value: 0,
                        animate: 100,
                        slide: (event, ui) => {
                            // This function occurs when someone clicks or drags on the sliderbar

                            // 0 - 100% for the length of the bar
                            if ((audio.duration !== undefined) && (audio.duration !== null) && (isNaN(audio.duration) === false) &&
                                (audio.currentTime !== undefined) && (audio.currentTime !== null) && (isNaN(audio.currentTime) === false)) {

                                audio.currentTime = audio.duration * (ui.value / 100);
                                updateCurrentPositionText(audio.currentTime);
                            }

                        }
                    }
                );

                return {
                    dispose : dispose
                };
            };

            ko.bindingHandlers.audioControls = {
                init: (element, valueAccessor) => {
                    let settings = ko.unwrap(valueAccessor());
                    let audioPlayer = createPlayer(element, settings);
                    let $element = $(element);
                    $element.data('audioPlayer', audioPlayer);

                    $element.on("click", (event) => {
                        event.stopPropagation();
                    });

                    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                        audioPlayer.dispose();
                        audioPlayer = null;
                        $element.removeData('audioPlayer');
                    });
                }
            };
        };
    }
);

