define('presentation/signup/validators/signupEmailVerificationValidator',[
    'constants/signupEmailVerificationConstants',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator'
],
function() {
    return function() {
        const self = this;

        let _viewModel = null;
        let _commonValidator = null;

        const _signupEmailVerificationConstants = require('constants/signupEmailVerificationConstants');

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        self.registerViewModel = (viewModel) => {
            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(_viewModel);

            const validationRules = new ValidationRulesConstructor();

            validationRules.field("emailVerificationCode")
                .addValidationI18n(_isVerificationCodeCorrect, 'signupEmail:incorrectVerificationCode')
                .addValidationI18n(_isBelowMaxVerificationAttempts, 'signupEmail:maxVerificationAttemptsReached')
                .addValidationI18n(_commonValidator.isStringWithValue, 'signupEmail:verificationCodeRequired');

            _commonValidator.setValidationRules(validationRules);
            return _commonValidator;
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _isVerificationCodeCorrect = () => {
            return !_viewModel.showIncorrectVerificationCodeError();
        };

        const _isBelowMaxVerificationAttempts = () => {
            return _viewModel.verificationFailureCount() < _signupEmailVerificationConstants.MaxVerificationAttempts;
        };
    };
});

