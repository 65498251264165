define('presentation/settings/autoAttendant/presentationObjects/forwardingNumberPresentationObject',[],function() {
    return function(){
        var self = this;
        self.forwardingNumberId = null;
        self.forwardingNumberName = null;
        self.phoneNumber = null;
        self.countryName = null;
    };
});

