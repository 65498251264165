define('presentation/messages/viewModels/conversationDetailsMediaViewModel',[
], function() {
    return function(
        /** @type {() => void} */
        onSeeAllAttachmentsClickedCallback,
        /** @type {import('presentation/messages/utils/conversationMediaManager')} */
        mediaManager,
        /** @type import('businessServices/events/eventManager') */
        eventManager
    ) {
        /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */
        /** @typedef {import('presentation/messages/viewModels/conversationDetailsMediaViewModel')} ConversationDetailsMediaViewModel */

        const MAX_ATTACHMENTS_TO_DISPLAY = 9;

        /** @type {ConversationDetailsMediaViewModel}*/
        const self = this;

        self.requestPreviousMessagesPage = ko.observable(false);
        self.requestNextMessagesPage = ko.observable(false);

        self.showSection = ko.pureComputed(() => {
            const isLoading = mediaManager.isLoading();
            const {length} = mediaManager.attachments();
            return !isLoading && !!length;
        });

        self.attachments = ko.pureComputed(() => {
            const attachments = mediaManager.attachments();
            return attachments.slice(0, MAX_ATTACHMENTS_TO_DISPLAY);
        });

        self.showSeeAll = ko.pureComputed(() => {
            const isLoading = mediaManager.isLoading();
            const attachments = mediaManager.attachments();

            // Show "See All" if there are more than 9 attachments
            return !isLoading && attachments && attachments.length > MAX_ATTACHMENTS_TO_DISPLAY;
        });

        self.onSeeAllClick = () => {
            onSeeAllAttachmentsClickedCallback();
        };

        self.onClickAttachment = (/** @type {ConversationMessageAttachmentViewModel}*/ attachment) => {
            eventManager.publishSmsAttachmentFromDetailsClicked(attachment.attachmentId());
        };

        self.activate = () => {

        };
    };
});
