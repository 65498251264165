define('presentation/userProfile/presentationObjects/currentUserPresentationObject',[],function() {
    return function(){
        var self = this;
        self.userId = null;
        self.userAvatar = null;
        self.firstName = null;
        self.lastName = null;
        self.fullName = null;
        self.emailAddress = null;
        self.password = null;
        self.isPasswordNew = null;
        self.confirmPassword = null;
        self.verificationQuestion = null;
        self.verificationAnswer = null;
        self.timeZone = null;
        self.otherContactInfoEmailAddresses = [];
        self.country = null;
        self.addressLineOne = null;
        self.addressLineTwo = null;
        self.city = null;
        self.stateProvince = null;
        self.postalCode = null;
    };
});

