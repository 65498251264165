define('common/url/urlFormatter',['common/storage/workSessionIdStorage',
        'presentation/common/window/windowControl'],
    function() {
        var _workSessionIdStorage = require('common/storage/workSessionIdStorage');
        var _windowControl = require('presentation/common/window/windowControl');

        const _databasePathComponents =  ["_utils", "database.html"];

        function getUrlComponents(/** @type {Array<string> | string} */pathComponents) {
            var urlComponents;
            if (Array.isArray(pathComponents)) {
                urlComponents = pathComponents;
            } else if (typeof(pathComponents) === "string") {
                urlComponents = pathComponents.split('/');
            } else {
                var err = new Error("pathComponents must be a string or array: Type:" + typeof(pathComponents) + " Value: " + pathComponents);
                err.pathComponents = pathComponents;
                throw err;
            }

            return urlComponents.filter(function(item) {
                return (item !== null && item !== undefined && item !== '');
            });
        }

        function buildWorkSessionUrl(/** @type {Array<string> | string} */pathComponents, /** @type boolean */ includeBaseUrl) {
            var urlComponents = getUrlComponents(pathComponents);

            var workSessionId = _workSessionIdStorage.getWorkSessionId();
            var baseUrl = _windowControl.getBaseUrl();
            var path;
            var sessionPath;
            if (workSessionId === undefined) {
                path = urlComponents.join('/');

                if (includeBaseUrl === true) {
                    path = baseUrl + "/" + path;
                }
            } else {
                if (includeBaseUrl === true) {
                    sessionPath = [baseUrl, "ws", workSessionId, baseUrl].concat(urlComponents);
                } else {
                    sessionPath = ["ws", workSessionId].concat(urlComponents);
                }

                path = sessionPath.join('/');
            }
            return path;
        }

        function buildUrl(/** @type {Array<string> | string} */pathComponents) {
            var urlComponents = getUrlComponents(pathComponents);
            return urlComponents.join("/");
        }

        function buildFrontendUrl(/** @type {Array<string> | string} */pathComponents) {
            var baseFrontendUrl = _windowControl.getFrontendBaseUrl();
            var urlComponents = getUrlComponents(pathComponents);
            return baseFrontendUrl + "/" + urlComponents.join("/");
        }

        const buildCouchAccountDbUrl = (/** @type string */accountDB) => {
            let baseCouchDbUrl = _windowControl.getCouchBaseUrl();
            let urlComponents = getUrlComponents(_databasePathComponents);
            return baseCouchDbUrl + urlComponents.join("/") + "?" + accountDB;
        };


        const buildMapUrl = (/** @type {Array<string> | string} */pathComponents) => {
            const baseMapUrl = 'https://www.google.com/maps/place/';
            const urlComponents = getUrlComponents(pathComponents);
            return baseMapUrl + urlComponents.join(",+");
        };

        return {
            buildCouchAccountDbUrl : buildCouchAccountDbUrl,
            buildWorkSessionUrl : buildWorkSessionUrl,
            buildUrl : buildUrl,
            buildFrontendUrl : buildFrontendUrl,
            buildMapUrl: buildMapUrl
        };
    });

