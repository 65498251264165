define('businessServices/converters/numberFormatter',[], function() {
    return function() {
        /** @typedef {import('businessServices/converters/numberFormatter')} NumberFormatter */

        /** @type {NumberFormatter} */
        const self = this;

        // using International System of Units (SI)
        const SI_PREFIXES = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "k" },
            { value: 1E6, symbol: "M" },
        ];

        /** @type {NumberFormatter['formatDigitsWithPrefix']} */
        const _formatDigitsWithPrefix = (numberValue, toFixedDigits) => {
            const RegExTrimTrailingZeros = /\.0+$|(\.[0-9]*[1-9])0+$/;
            let i;
            for (i = SI_PREFIXES.length - 1; i > 0; i--) {
                if (numberValue >= SI_PREFIXES[i].value) {
                    break;
                }
            }

            return (numberValue / SI_PREFIXES[i].value).toFixed(toFixedDigits).replace(RegExTrimTrailingZeros, "$1") + SI_PREFIXES[i].symbol;
        };

        /** @type {NumberFormatter['formatForDisplay']} */
        const _formatForDisplay = (numberValue) => {
            return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

        self.formatForDisplay = _formatForDisplay;
        self.formatDigitsWithPrefix = _formatDigitsWithPrefix;

    };
});

