define('presentation/messages/viewModels/conversationViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/blocking/blockingStateSingleton',
    'businessServices/contacts/contactsStateSingleton',
    'businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton',
    'businessServices/messages/messagesStateSingleton',
    'businessServices/router/router',
    'businessServices/events/eventManager',
    'common/converters/phoneNumberFormatter',
    'common/throttling/debounce',
    'common/promises/promiseFactory',
    'common/time/datetimeFormatter',
    'constants/deliveryStatus',
    'constants/smsConversationMessageAttachmentStatus',
    'constants/transitionDurations',
    'i18next',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/confirmActionViewModel',
    'presentation/messages/dataSources/conversationReadStateDataSource',
    'presentation/messages/dataSources/webMessagingDataSource',
    'presentation/messages/utils/conversationMessageManager',
    'presentation/messages/utils/conversationMediaManager',
    'presentation/messages/viewModels/conversationDetailsViewModel',
    'presentation/messages/viewModels/conversationMediaViewModel',
    'presentation/messages/viewModels/conversationHeaderViewModel',
    'presentation/messages/viewModels/conversationMessagesViewModel',
    'presentation/messages/viewModels/messageComposeAreaViewModel',
    'presentation/messages/viewModels/conversationMessageAttachmentViewModel',
    'presentation/messages/viewModels/fullScreenMediaModalViewModel',
    'presentation/messages/facades/conversationFacade',
    'presentation/messages/facades/conversationMediaFacade',
    'presentation/messages/facades/conversationLinksFacade',
    'settings/navigationConfiguration',
    'constants/smsConversationMessageUrlPreviewConversionStatus',
    'presentation/messages/utils/conversationLinksManager',
    'presentation/messages/viewModels/conversationLinksViewModel'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    sessionAccountInfo,
    /** @type import('businessServices/blocking/blockingStateSingleton') */
    _blockingState,
    /** @type import('businessServices/contacts/contactsStateSingleton') */
    _contactsState,
    /** @type import('businessServices/smsPhoneNumberOptOut/smsPhoneNumberOptOutStateSingleton') */
    _smsPhoneNumberOptOutsState,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    messagesState,
    /** @type typeof import('businessServices/router/router') */
    router,
    /** @type import('businessServices/events/eventManager') */
    eventManager,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/throttling/debounce') */
    debounce,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/time/datetimeFormatter'), */
    DatetimeFormatter,
    /** @type typeof import('constants/deliveryStatus') */
    DeliveryStatus,
    /** @type typeof import('constants/smsConversationMessageAttachmentStatus') */
    SmsConversationMessageAttachmentStatus,
    /** @type typeof import('constants/transitionDurations') */
    TransitionDurations,
    /** @type typeof import('i18next') */
    i18next,
    /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
    ActionModal,
    /** @type typeof import('presentation/common/actionModal/viewModels/confirmActionViewModel') */
    ConfirmAction,
    /** @type import('presentation/messages/dataSources/conversationReadStateDataSource') */
    ConversationReadState,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    webMessagingDataSource,
    /** @type typeof import('presentation/messages/utils/conversationMessageManager') */
    ConversationMessageManager,
    /** @type typeof import('presentation/messages/utils/conversationMediaManager') */
    ConversationMediaManager,
    /** @type typeof import('presentation/messages/viewModels/conversationDetailsViewModel') */
    ConversationDetailsViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationMediaViewModel') */
    ConversationMediaViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationHeaderViewModel') */
    ConversationHeaderViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationMessagesViewModel') */
    ConversationMessagesViewModel,
    /** @type typeof import('presentation/messages/viewModels/messageComposeAreaViewModel') */
    MessageComposeAreaViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationMessageAttachmentViewModel') */
    ConversationMessageAttachmentViewModel,
    /** @type typeof import('presentation/messages/viewModels/fullScreenMediaModalViewModel') */
    FullScreenMediaModalViewModel,
    /** @type typeof import('presentation/messages/facades/conversationFacade') */
    ConversationFacade,
    /** @type typeof import('presentation/messages/facades/conversationMediaFacade') */
    ConversationMediaFacade,
    /** @type typeof import('presentation/messages/facades/conversationLinksFacade') */
    ConversationLinksFacade,
    /** @type typeof import('settings/navigationConfiguration') */
    navigationConfiguration,
    /** @type typeof import('constants/smsConversationMessageUrlPreviewConversionStatus')*/
    SmsConversationMessageUrlPreviewConversionStatus,
    /** @type typeof import('presentation/messages/utils/conversationLinksManager')*/
    ConversationLinksManager,
    /** @type typeof import('presentation/messages/viewModels/conversationLinksViewModel')*/
    ConversationLinksViewModel
) {
    return function (
        /** @type {string} */conversationId, /** @type {string} */conversationDraftId = null, /** @type {boolean} */ isNew = false) {
        /** @typedef {import('presentation/messages/viewModels/conversationMessageViewModel')} ConversationMessageViewModel */
        /** @typedef {import('presentation/messages/presentationObjects/phoneNumberDetailsPresentationObject')} PhoneNumberDetailsPresentationObject */
        /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */

        const self = this;
        const _phoneNumberFormatter = new PhoneNumberFormatter();
        const _datetimeFormatter = new DatetimeFormatter();
        const MESSAGE_PAGE_SIZE = 25;
        const CONVERSATION_FADE_DURATION = TransitionDurations.slow;

        /** @type import('common/promises/promiseFactory') */
        let _promiseFactory = null;
        /** @type import('presentation/messages/facades/conversationFacade') */
        let _facade = null;
        /** @type {import('presentation/messages/utils/conversationMessageManager')} */
        let _messageManager = null;
        /** @type {import('presentation/messages/utils/conversationMessageManager')} */
        let _mediaManager = null;
        /** @type {import('presentation/messages/utils/conversationLinksManager')} */
        let _linksManager = null;
        /** @type {IDisposable[]} */
        let _disposables = [];
        /** @type {string} */
        let _previousSendFromHostedNumberId = null;
        /** @type {string} */
        let _attachmentClickedSubscription = null;
        /** @type {string} */
        let _attachmentFromDetailsClickedSubscription = null;

        /**
         * @param {ISmsConversationMessageAttachment[]} attachments
         * @returns {ConversationMessageAttachmentViewModel[]}
         * */
        const _parseDraftAttachments = (attachments) => {
            return attachments.map(attachment => {
                const attachmentViewModel = new ConversationMessageAttachmentViewModel();

                attachmentViewModel.activate({
                    attachmentId: attachment.smsConversationMessageAttachmentId,
                    conversionStatus: attachment.conversionStatus,
                    remoteObjectUrl: attachment.s3FilePath,
                    remoteObjectContentType: attachment.contentType
                });

                return attachmentViewModel;
            });
        };

        /** @param {IGetSmsConversationResponse} data */
        const _setExistingConversationData = (data) => {
            ConversationReadState.loadConversation(data);
            const {
                conversationName,
                conversationCreatedDateTime,
                owningHostedNumberId,
                hostedPhoneNumber,
                recipients,
                draft,
                smsMessages,
                includesBeginningOfConversation,
                includesEndOfConversation,
                oldestUnreadMessageId,
                subscribers
            } = data;

            /** @type {IRecipient[]} */
            const parsedRecipients = recipients.map(data => {
                const { phoneNumber, city, state, latitude, longitude } = data;
                const meta = _phoneNumberFormatter.isTollFree(phoneNumber) ?
                    i18next.t('tollFree') : _phoneNumberFormatter.formatLocation(city, state);
                return {
                    phoneNumber: phoneNumber,
                    meta: meta,
                    latitude: latitude ? Number(latitude) : null,
                    longitude: longitude ? Number(longitude) : null
                };
            });

            _messageManager.setRecipients(parsedRecipients);
            _messageManager.setOwningHostedNumberId(owningHostedNumberId);

            if (recipients.length === 1) {
                const { phoneNumber, city, state } = recipients[0];
                const contact = _contactsState.getContactByPhoneNumber(phoneNumber);
                if (contact){
                    const matchingNumber = contact.phoneNumbers().find(x => _phoneNumberFormatter.toEOneSixFour(x.rawPhoneNumber()) === phoneNumber);
                    const contactMetaText = matchingNumber ? matchingNumber.label() : null;
                    self.conversationMetaText(contactMetaText);
                }
                else if (_phoneNumberFormatter.isTollFree(phoneNumber)) {
                    self.conversationMetaText(i18next.t('tollFree'));
                } else {
                    self.conversationMetaText(_phoneNumberFormatter.formatLocation(city, state));
                }
            }
            else {
                self.conversationMetaText(i18next.t('conversation:nRecipients', { recipientCount: recipients.length }));
            }

            if (draft) {
                self.messageContent(draft.content || ``);

                if (draft.attachments && draft.attachments.length) {
                    self.messageAttachments(_parseDraftAttachments(draft.attachments));
                }

                conversationDraftId = draft.smsConversationDraftId;
            }

            const formattedCreatedDateTime = _datetimeFormatter.formatDateForSidebar(conversationCreatedDateTime);

            _messageManager.setInitialMessages(smsMessages, oldestUnreadMessageId);

            self.subscribers(subscribers);
            const initialConversationName = conversationName || '';

            self.conversationName(initialConversationName);
            self.conversationNameInputValue(initialConversationName);
            self.conversationCreatedDateTime(formattedCreatedDateTime);
            self.sendFromHostedNumberId(owningHostedNumberId);
            self.recipients(parsedRecipients);
            self.haveReachedBeginningOfConversation(includesBeginningOfConversation);
            self.haveReachedEndOfConversation(includesEndOfConversation);
            self.hostedPhoneNumber(hostedPhoneNumber);
        };

        /** @param {ISmsUserDraftProjectionResult} draft */
        const _setNewConversationData = ({ accountHostedNumberId, content, recipients, attachments }) => {
            const webMessagingNumbers = self.sendFromHostedNumbers();
            let sendFromHostedNumberId = webMessagingNumbers.length ? webMessagingNumbers[0].accountHostedNumberId : '';

            if (accountHostedNumberId && webMessagingNumbers.find(x => x.accountHostedNumberId === accountHostedNumberId)) {
                sendFromHostedNumberId = accountHostedNumberId;
            }

            const parsedRecipients = recipients
                .map(x => Object.assign({}, x, { latitude: Number(x.latitude), longitude: Number(x.longitude) }));

            /** @type {ConversationMessageAttachmentViewModel[]} */
            let parsedAttachments = [];

            if (attachments && attachments.length) {
                parsedAttachments = _parseDraftAttachments(attachments);
            }

            self.messageContent(content || ``);
            self.conversationName(``);
            self.recipients(parsedRecipients);
            self.sendFromHostedNumberId(sendFromHostedNumberId);
            self.messageAttachments(parsedAttachments);

            if (sendFromHostedNumberId) {
                _updateAutoCompleteRecipients(sendFromHostedNumberId);
                _facade.updateConversationDraftSendFromNumber({
                    smsConversationDraftId: conversationDraftId,
                    accountHostedNumberId: sendFromHostedNumberId
                });
            }
        };

        const _updateAutoCompleteRecipients = (/** @type {string} */sendFromHostedNumberId) => {
            _facade.getAutoCompleteRecipients(sendFromHostedNumberId)
                .fail(_navigateToConversationErrorPage)
                .done((autoCompleteRecipients) => {
                    self.autoCompleteRecipients(autoCompleteRecipients);
                });
        };

        const _getNewMessages = () => {
            if (self.isLoadingNewMessages()) {
                return;
            }

            self.isLoadingNewMessages(true);
            self.newMessageEventReceived(false);

            const mostRecentMessage = _messageManager.newestMessage();
            const mostRecentMessageDateTime = mostRecentMessage ? mostRecentMessage.messageDateTime : null;
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            _facade.getSmsConversationMessagesSince(conversationId, mostRecentMessageDateTime)
                .done((result) => {
                    _messageManager.addNewMessages(result.smsMessages, result.oldestUnreadMessageId);
                    _updatePendingLinkPreviews();

                    self.isLoadingNewMessages(false);

                    _getSmsConversationNewMessagesCount()
                        .then(() => {
                            if (self.newMessageEventReceived()) {
                                _getNewMessages();
                            }
                            else if (self.isScrolledToBottom()) {
                                _markConversationAsReviewed();
                            }
                        });
                });
        };

        const _onInfoBubbleClickedCallback = () => {
            self.showConversationDetails(true);
        };

        const _onCloseDetailsClickedCallback = () => {
            self.showConversationDetails(false);
        };

        const _onSeeAllAttachmentsClickedCallback = () => {
            self.showAllMedia(true);
        };

        const _onSeeAllLinksClickedCallback = () => {
            self.showAllLinks(true);
        };

        const _onGetPreviousMessagesPageSuccess = (result) => {
            _messageManager.addPreviousMessages(result.smsMessages);

            if (result.smsMessages.length < MESSAGE_PAGE_SIZE) {
                self.haveReachedBeginningOfConversation(true);
            }

            self.isLoadingPreviousMessagesPage(false);
            self.showMessagesLoadingState(false);

            if (self.requestPreviousMessagesPage()) {
                _fullScreenMediaModal.updateAttachments(_messageManager.getMediaModalAttachments());
            }
        };

        const _onGetNextMessagesPageSuccess = (result) => {
            _messageManager.addNewMessages(result.smsMessages, result.oldestUnreadMessageId);

            if (result.smsMessages.length < MESSAGE_PAGE_SIZE) {
                self.haveReachedEndOfConversation(true);
            }

            self.isLoadingNextMessagesPage(false);

            if (self.requestNextMessagesPage()) {
                _fullScreenMediaModal.updateAttachments(_messageManager.getMediaModalAttachments());
            }
        };

        const _getPreviousMessagesAttachmentsPage = () => {
            if (!self.requestPreviousMessagesPage()) {
                return;
            }

            const currentSelectedAttachment = _fullScreenMediaModal.getSelectedAttachment();
            const beforeDateTime = currentSelectedAttachment.message.messageDateTime;
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            _facade.getSmsConversationMessagesBefore(conversationId, MESSAGE_PAGE_SIZE, beforeDateTime)
                .done(_onGetPreviousMessagesPageSuccess);
        };

        const _getNextMessagesAttachmentsPage = () => {
            if (!self.requestNextMessagesPage()) {
                return;
            }

            const currentSelectedAttachment = _fullScreenMediaModal.getSelectedAttachment();
            const sinceDateTime = currentSelectedAttachment.message.messageDateTime;
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            _facade.getSmsConversationMessagesSince(conversationId, sinceDateTime, MESSAGE_PAGE_SIZE)
                .done(_onGetNextMessagesPageSuccess);
        };

        const _getPreviousMessagesPage = () => {
            if (self.isLoadingPreviousMessagesPage() || self.haveReachedBeginningOfConversation()) {
                return;
            }

            if (_messageManager.isConversationEmpty() === false) {
                self.showMessagesLoadingState(true);
            }

            self.isLoadingPreviousMessagesPage(true);

            const oldestExistingMessage = _messageManager.oldestMessage();
            const beforeDateTime = oldestExistingMessage ? oldestExistingMessage.messageDateTime : new Date(Date.now());
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            _facade.getSmsConversationMessagesBefore(conversationId, MESSAGE_PAGE_SIZE, beforeDateTime)
                .done(_onGetPreviousMessagesPageSuccess);
        };

        const _getNextMessagesPage = () => {
            if (self.isLoadingNextMessagesPage() || self.haveReachedEndOfConversation()) {
                return;
            }

            self.isLoadingNextMessagesPage(true);

            const newestExistingMessage = _messageManager.newestMessage();
            const sinceDateTime = newestExistingMessage.messageDateTime;
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            _facade.getSmsConversationMessagesSince(conversationId, sinceDateTime, MESSAGE_PAGE_SIZE)
                .done(_onGetNextMessagesPageSuccess);
        };

        const _navigateToConversationErrorPage = () => {
            const errorConversationId = conversationId || conversationDraftId;

            const conversationErrorRoute = navigationConfiguration.routesById.conversationNotFound.baseUrl +
                errorConversationId +
                navigationConfiguration.routesById.conversationNotFound.urlSuffix;

            router.navigate(conversationErrorRoute);
        };

        const _checkForMatchingConversation = () => {
            const sendFromHostedNumberId = self.sendFromHostedNumberId();
            const recipientPhoneNumbers = self.recipients()
                .map((recipient) => {
                    if (_phoneNumberFormatter.isShortCode(recipient.phoneNumber)) {
                        return recipient.phoneNumber;
                    }

                    return _phoneNumberFormatter.toEOneSixFour(recipient.phoneNumber);
                });

            _facade.getMatchingSmsConversation(sendFromHostedNumberId, recipientPhoneNumbers)
                .done((result) => {
                    self.draftMatchingConversationId(result.smsConversationId);
                });
        };

        const _onRecipientsChanged = (/** @type {IRecipient[]} */recipients) => {
            const recipientPhoneNumbers = recipients
                .map(x => {
                    const numbersOnly = x.phoneNumber.replace(/\D/g, '');
                    return _phoneNumberFormatter.isShortCode(numbersOnly) ? numbersOnly : _phoneNumberFormatter.toEOneSixFour(numbersOnly);
                });

            // Allow only one recipient for Toll Free
            if (recipientPhoneNumbers.length > 1) {
                const sendFromHostedNumberId = self.sendFromHostedNumberId();
                const numberInfo = self.sendFromHostedNumbers()
                    .find(x => x.accountHostedNumberId === sendFromHostedNumberId);

                if (_phoneNumberFormatter.isTollFree(numberInfo.hostedPhoneNumber)) {
                    _showOnlyOneRecipientModal();
                    self.recipients(recipients.slice(0, 1));
                    return;
                }
            }

            _facade.updateConversationDraftRecipients({
                recipients: recipientPhoneNumbers,
                smsConversationDraftId: conversationDraftId
            })
            .then(() => {});

            _onDraftMatchingConversationIdChanged(null);
            _checkForMatchingConversation();
        };

        const _onSendFromNumberChangedBeforeChange = (/** @type {string} */accountHostedNumberId) => {
            _previousSendFromHostedNumberId = accountHostedNumberId;
        };

        const _onSendFromNumberChanged = (/** @type {string} */accountHostedNumberId) => {
            if (!accountHostedNumberId) {
                return;
            }

            // Allow only one recipient for Toll Free
            const sendFromHostedNumberId = self.sendFromHostedNumberId();
            const numberInfo = self.sendFromHostedNumbers()
                .find(x => x.accountHostedNumberId === sendFromHostedNumberId);
            const recipients = self.recipients();
            if (recipients.length > 1 && _phoneNumberFormatter.isTollFree(numberInfo.hostedPhoneNumber)) {
                _showOnlyOneRecipientModal();
                self.sendFromHostedNumberId(_previousSendFromHostedNumberId);
                return;
            }

            _updateAutoCompleteRecipients(accountHostedNumberId);

            _facade.updateConversationDraftSendFromNumber({
                smsConversationDraftId: conversationDraftId,
                accountHostedNumberId: accountHostedNumberId
            })
            .then(() => {});

            _onDraftMatchingConversationIdChanged(null);
            _checkForMatchingConversation();
        };

        const _onDraftMatchingConversationIdChanged = (/** @type {string} */draftMatchingConversationId) => {
            self.isLoadingPreviousMessagesPage(false);
            self.haveReachedBeginningOfConversation(true);
            _messageManager.clearState();
            self.isScrolledToBottom(true);

            if (draftMatchingConversationId !== null) {
                self.haveReachedBeginningOfConversation(false);
                _getPreviousMessagesPage();
            }
        };

        const _onSmsPermissionChanged = (/** @type {ISubscribersChanged} */event) => {
            if (event.accountHostedNumberId === self.sendFromHostedNumberId()) {
                self.subscribers(event.subscribers);
            }
        };

        const _onSmsConversationMessageDeleted = (/** @type {ISmsConversationMessageDeleted} */ event) => {
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            if (conversationId !== event.smsConversationId) {
                return;
            }

            const eventTriggeredByUser = self.isSubmittingMessage() && event.userId === sessionAccountInfo.userId();
            if (eventTriggeredByUser) {
                return;
            }

            _messageManager.deleteMessage(event.smsConversationMessageId);
        };

        const _onSmsConversationMessage = (/**@type {ISmsConversationMessageStatusChanged}*/ event) => {
            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            if (conversationId !== event.smsConversationId) {
                return;
            }

            const eventTriggeredByUser = self.isSubmittingMessage() && event.userId === sessionAccountInfo.userId();
            if (eventTriggeredByUser && event.deliveryStatus === DeliveryStatus.Sending) {
                return;
            }

            if (_messageManager.hasMessage(event.smsConversationMessageId)) {
                _messageManager.onSmsConversationMessageStatusChanged(event.smsConversationMessageId, event.deliveryStatus);
                return;
            }

            self.newMessageEventReceived(true);

            self.conversationMessagesViewModel.onNewMessageReceived();

            if (self.haveReachedEndOfConversation()) {
                _getNewMessages();
            } else {
                _getSmsConversationNewMessagesCount();
            }
        };

        const _onSmsConversationMessageAttachmentUpdated = (/**@type {ISmsConversationMessageAttachmentUpdated}*/ event) => {
            const attachmentToUpdate = self.messageAttachments()
                .find(attachment => attachment.attachmentId() === event.smsConversationMessageAttachmentId);

            if (attachmentToUpdate) {
                attachmentToUpdate.updateConversionStatus(event.conversionStatus);
            } else {
                const attachmentsPendingConversion = _messageManager.getInboundAttachmentsPendingConversion();

                if (attachmentsPendingConversion.includes(event.smsConversationMessageAttachmentId)) {
                    _updateAttachment(event.smsConversationMessageAttachmentId);
                } else {
                    _messageManager.onSmsConversationMessageAttachmentUpdated(event);
                }
            }
        };

        /** @param {string} attachmentId */
        const _updateAttachment = (attachmentId) => {
            _facade.getSmsConversationMessageAttachment(attachmentId)
                .done(result => {
                    _messageManager.updateSmsConversationMessageAttachment(result.attachment);
                });
        };

        const _deleteMessage = (/** @type {string} */smsConversationMessageId) => {
            const conversationId = self.conversationId() || self.draftMatchingConversationId();
            return _facade.deleteMessage(conversationId, smsConversationMessageId);
        };

        const _retryMessage = (/** @type {ConversationMessageViewModel} */message) => {
            if (self.isSubmittingMessage()) {
                return null;
            }

            self.conversationMessagesViewModel.onMessageRetry();

            const isNewestMessage = _messageManager.newestMessage() === message;

            self.isSubmittingMessage(true);
            const messageToBeSent = isNewestMessage ? message : _messageManager.addSendingMessage(message.content, message.attachments(), isNewestMessage);
            self.messageToBeSent(messageToBeSent);

            if (!isNewestMessage) {
                message.updateDeliveryStatus(DeliveryStatus.Sending);
                _messageManager.deleteMessage(message.smsConversationMessageId);

                setTimeout(() => {
                    messageToBeSent.isVisible(true);
                }, 100);
            }

            const conversationId = self.conversationId() || self.draftMatchingConversationId();

            return _facade
                .retryMessage(conversationId, message.smsConversationMessageId)
                .then(result => {
                    self.isSubmittingMessage(false);

                    if (!isNewestMessage) {
                        messageToBeSent.isVisible(true);
                    }

                    if (_messageManager.hasMessage(result.messageId)) {
                        _messageManager.deleteMessage(messageToBeSent.smsConversationMessageId);
                    }
                    else if (self.haveReachedEndOfConversation()) {
                        _messageManager.updateMessageAfterSend(messageToBeSent.smsConversationMessageId, result.messageId, result.deliveryStatus);
                    }
                    else {
                        _messageManager.deleteMessage(messageToBeSent.smsConversationMessageId);
                    }

                    if (isNew) {
                        const url = `${navigationConfiguration.routesById.conversation.baseUrl}/${conversationId}`;
                        router.navigate(url);
                        return null;
                    }

                    return result;
                });
        };

        const _reloadConversationAtBottom = () => {
            const message = self.messageToBeSent();
            if (!message) {
                return _promiseFactory.wait();
            }

            if (self.haveReachedEndOfConversation()) {
                // don't need to do anything if the bottom has been reached
                return _promiseFactory.defer((deferredObject) => deferredObject.resolve());
            }

            _messageManager.clearState();
            _resetUnreadIdentifiersAndCount();

            self.showMessagesLoadingState(true);
            self.isSubmittingMessage(true);
            self.messageToBeSent(null);
            self.isLoadingNewMessages(true);

            _messageManager.addSendingMessage(message.content, message.attachments(), true);

            return _facade.getSmsConversation(conversationId, MESSAGE_PAGE_SIZE)
                .done((result) => {
                    _setExistingConversationData(result);
                    self.isLoadingNewMessages(false);
                    self.isSubmittingMessage(false);
                    self.messageToBeSent(null);
                    self.showMessagesLoadingState(false);
                });
        };

        //#region User Is Typing

        const _oneSecond = 1000;

        // Don't publish SMS User Is Typing events
        // when submitting message

        const _publishSomeoneIsTyping = () => {
            if (self.isSubmittingMessage()) {
                return;
            }

            _onMessageContentChangedDebounced();
        };

        const _onMessageContentChangedDebounced = debounce(_oneSecond, () => {
            if (self.isSubmittingMessage()) {
                return;
            }

            // TODO: Check if messageContent is empty and issue a deletion
            _facade.publishSmsUserIsTyping(conversationId);
        });

        //#endregion

        //#region New Messages Count & Label
        const _getSmsConversationNewMessagesCount = () => {
            return _promiseFactory.defer((deferredObject) => {
                const conversationId = self.conversationId() || self.draftMatchingConversationId();
                if (!conversationId) {
                    deferredObject.resolve();
                }

                if (self.haveReachedEndOfConversation()) {
                    const newMessagesCount = _messageManager.getReceivedMessagesCountSinceMessageId(_messageManager.oldestNewMessageId());
                    ConversationReadState.setConversationNewMessagesCount(conversationId, newMessagesCount);
                    deferredObject.resolve();
                }

                _facade.getSmsConversationNewMessagesCount(conversationId).done((results) => {
                    ConversationReadState.setConversationNewMessagesCount(conversationId, results.newMessagesCount);
                    deferredObject.resolve();
                });
            });
        };

        const _markConversationAsReviewed = () => {
            if (!isNew) {
                ConversationReadState.markConversationAsReviewed(self.conversationId());
                _facade.updateSmsConversationLastViewedDate(self.conversationId());
            }
        };

        const _markConversationAsReviewedDetailsCallback = () => {
            _markConversationAsReviewed();
            _messageManager.setOldestNewMessageId(null);
            ConversationReadState.markConversationAsReviewed(self.conversationId());
            ConversationReadState.setConversationNewMessagesCount(self.conversationId(), 0);
        };

        const _markConversationAsNew = () => {
            _facade.markConversationAsNew(self.conversationId());
            ConversationReadState.markConversationAsNew(self.conversationId());
        };

        const _resetUnreadIdentifiersAndCount = () => {
            ConversationReadState.setConversationNewMessagesCount(self.conversationId(), 0);
            _messageManager.setOldestNewMessageId(null);
        };

        const _onBottomReached = () => {
            if (_messageManager.oldestNewMessageId() !== null) {
                _markConversationAsReviewed();
                self.conversationMessagesViewModel.onBottomReached();
            }
        };
        //#endregion

        //#region Attachments

        const _cancelAttachmentCallback = (/** @type {string} */ attachmentId) => {
            _facade.cancelAttachment(attachmentId);
        };

        const _onMessageAttachmentsChanged = () => {
            if (self.isSubmittingMessage()) {
                return;
            }

            const uploadAttachmentToServer = (/** @type {ConversationMessageAttachmentViewModel} */ attachmentToUpload) => {
                const file = attachmentToUpload.file();
                const putUrl = attachmentToUpload.putUrl();

                const contentTypeHeader = new Headers(); // jshint ignore:line
                contentTypeHeader.append("Content-Type", file.type);

                const options = {
                    method: 'PUT',
                    body: file,
                    headers: contentTypeHeader
                };

                return fetch(putUrl, options)
                    .then(response => {
                        if (!response.ok) {
                            attachmentToUpload.updateConversionStatus(SmsConversationMessageAttachmentStatus.TimedOut);
                        }
                    });
            };

            _createNewAttachments()
                .then((newAttachmentsResponses) => {
                    newAttachmentsResponses.forEach((newAttachmentResponse) => {
                        const attachmentToUpload = self.messageAttachments()
                            .find(attachment => attachment.fileName() === newAttachmentResponse.fileName);

                        attachmentToUpload.attachmentId(newAttachmentResponse.smsConversationMessageAttachmentId);
                        attachmentToUpload.putUrl(newAttachmentResponse.putUrl);

                        if (newAttachmentResponse.putUrl) {
                            uploadAttachmentToServer(attachmentToUpload);
                        }
                    });
                })
                .then(_updateAttachments);
        };

        const _updateAttachments = () => {
            const attachmentIds = self.messageAttachments()
                .map(x => x.attachmentId())
                .filter(id => id);

            if (conversationDraftId) {
                return _facade.updateConversationDraftAttachments({
                    smsConversationDraftId: conversationDraftId,
                    attachmentIds: attachmentIds
                });
            }
            else {
                const message = self.messageContent();
                return _facade.createConversationDraftMessage({
                    content: message,
                    attachmentIds: attachmentIds,
                    smsConversationId: conversationId
                }).then(({smsConversationDraftId}) => {
                    conversationDraftId = smsConversationDraftId;
                });
            }
        };

        /** @returns {JQueryDeferred<ICreateSmsAttachmentResponse[]>} */
        const _createNewAttachments = () => {
            return _promiseFactory.defer((deferredObject) => {
                /** type{ICreateSmsAttachmentRequest[]} */
                const newAttachments = self.messageAttachments()
                    .filter((attachment) => {
                        return attachment.isReadyToCreate();
                    });

                newAttachments.forEach((attachment) => {
                    attachment.isGettingPutUrl(true);
                });

                if (newAttachments.length === 0) {
                    deferredObject.resolve([]);
                    return;
                }

                _facade.createSmsConversationMessageAttachments({
                    createSmsAttachmentRequests: newAttachments
                        .map(attachment => {
                            return {
                                contentType: attachment.contentType(),
                                fileName: attachment.fileName(),
                                conversionStatus: attachment.conversionStatus()
                            };
                        })
                }).done((result) => {
                    deferredObject.resolve(result.createSmsAttachmentResponses);
                });
            });
        };

        const _formatAttachmentsForModal = (attachments, mediaManager) => {
            return attachments.map((attachment) => {
                return {
                    attachment: attachment,
                    message: mediaManager.getMessageById(attachment.owningMessageId())
                };
            });
        };

        const _onSmsAttachmentClicked = (/** @type {ISmsAttachmentClickedEvent} */ event) => {
            _showFullScreenMedia(event, false);
        };

        const _onSmsAttachmentFromDetailsClicked = (/** @type {ISmsAttachmentClickedEvent} */ event) => {
            _showFullScreenMedia(event, true);
        };

        const _showFullScreenMedia = (/** @type {ISmsAttachmentClickedEvent} */ event, isInverted) => {
            const attachmentId = event.attachmentId;
            const attachment = _mediaManager.getAttachmentById(attachmentId);

            if (!attachment) {
                return;
            }

            _fullScreenMediaModal
                .setAttachments(_formatAttachmentsForModal(_mediaManager.attachments(), _mediaManager))
                .setSelectedAttachment(attachment.attachmentId())
                .setPagingRequests(self.requestPreviousMessagesPage, self.requestNextMessagesPage)
                .setInvertedImageDirection(isInverted)
                .showModal();
        };

        const _onPagingRequestChanged = () => {
            if (!_fullScreenMediaModal.isModalOpen()) {
                return;
            }

            if (self.requestPreviousMessagesPage()) {
                _getPreviousMessagesAttachmentsPage();
            } else if (self.requestNextMessagesPage()) {
                _getNextMessagesAttachmentsPage();
            }
        };
        //#endregion

        const _archiveConversation = () => {
            const conversationId = self.conversationId();

            if (self.isArchivingConversation()) {
                return;
            }

            self.isArchivingConversation(true);

            setTimeout(() => {
                // Optimistically archive after the fade out animation. It will just come back in the sidebar if it fails.
                _facade.archiveConversation(conversationId);
                webMessagingDataSource.addConversationIdPendingArchive(conversationId);
                messagesState.selectedConversationId(null);
                router.navigate(navigationConfiguration.routesById.messages.routeId);
            }, CONVERSATION_FADE_DURATION);
        };

        const _updateConversationName = (/** @type {string} */conversationName) => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _facade.updateSmsConversationName(self.conversationId(), conversationName)
                    .done(() => {
                        self.conversationName(conversationName);
                        self.conversationNameInputValue(conversationName);
                        deferredObject.resolve();
                    });
            });
        };

        const _onSidebarContactsChanged = () => {
            if (self.recipients().length === 1) {
                const { phoneNumber, city, state } = self.recipients()[0];
                const contact = _contactsState.getContactByPhoneNumber(phoneNumber);
                if (contact){
                    const matchingNumber = contact.phoneNumbers().find(x => _phoneNumberFormatter.toEOneSixFour(x.rawPhoneNumber()) === phoneNumber);
                    const contactMetaText = matchingNumber ? matchingNumber.label() : null;
                    self.conversationMetaText(contactMetaText);
                }
                else if (_phoneNumberFormatter.isTollFree(phoneNumber)) {
                    self.conversationMetaText(i18next.t('tollFree'));
                } else {
                    self.conversationMetaText(_phoneNumberFormatter.formatLocation(city, state));
                }
            }
        };

        const _onSmsConversationNameChanged = (/** @type {ISmsConversationNameChanged} */conversation) => {
            if (self.conversationId() !== conversation.smsConversationId) {
                return;
            }

            if (self.conversationNameInputValue() === self.conversationName()) {
                self.conversationNameInputValue(conversation.smsConversationName);
            }

            self.conversationName(conversation.smsConversationName);
        };

        const _onSmsConversationUserAssociationChanged = (/** @type {ISmsConversationUserAssociationChanged} */event) => {
            ConversationReadState.setConversationReadState(event.smsConversationId, event.isMarkedAsNew, event.conversationLastViewedDateTime);
        };

        const _showOnlyOneRecipientModal = () => {
            const actionModal = new ActionModal()
                .setHeaderText({i18n: 'conversation:unsupported'})
                .setContentViewModel(ConfirmAction, [i18next.t("conversation:tollFreeNoGroupMessagingText")])
                .setCancelButtonText({i18n: 'conversation:close'});
            actionModal.shouldDisplaySubmitButton(false);
            actionModal.shouldDisplayCancelButton(true);

            actionModal.showModal();
        };

        //#region Url Link Previews
        const _onUrlPreviewConversionCompleted = (/**@type {IUrlPreviewConversionResponse}*/ event) => {
            _facade.getMessageUrlPreview(event.smsConversationMessageUrlId)
                .then(results => {
                    _messageManager.updateMessageLinkPreview(event.smsConversationMessageId, results);
                    _linksManager.updateDetailsLinkPreviews(results);
                });
        };

        const _tryUpdateLinkPreviews = (/** @type {string[]}*/urlIds, /** @type {string}*/messageId) => {
            if (!urlIds || !urlIds.length) {
                return;
            }

            urlIds.forEach((urlId) => {
                _facade.getMessageUrlPreview(urlId)
                    .then(results => {
                        _messageManager.updateMessageLinkPreview(messageId, results);
                        _linksManager.updateDetailsLinkPreviews(results);
                    });
            });
        };

        const _updatePendingLinkPreviews = () => {
            const messagesWithPendingLink = self.conversationMessages().filter(message => message.hasLinkPreviews() && message.linkPreviews().find(link => link.conversionStatus() === SmsConversationMessageUrlPreviewConversionStatus.Pending));
            if (!messagesWithPendingLink) {
                return;
            }

            messagesWithPendingLink.forEach((messageViewModel) => {
                messageViewModel.linkPreviews().forEach((linkPreviewViewModel) => {
                    const messageUrlId = linkPreviewViewModel.smsConversationMessageUrlId();
                    if (!messageUrlId) {
                        return;
                    }
                    _facade.getMessageUrlPreview(messageUrlId)
                        .then(results => {
                            linkPreviewViewModel.activate(results);
                            _linksManager.updateDetailsLinkPreviews(results);
                        });
                });
            });
        };
        //#endregion

        self.isInitialized = ko.observable(false);
        self.isCompositionComplete = ko.observable(false);

        /** @type {(request: IGetMapRequest) => JQueryDeferred<string>} */
        self.getMap = null;

        self.conversationDetailsViewModel = null;
        self.conversationMediaViewModel = null;
        self.conversationLinksViewModel = null;
        self.conversationHeaderViewModel = null;
        self.conversationMessagesViewModel = null;
        self.messageComposeAreaViewModel = null;
        self.conversationMetaText = ko.observable(``);
        self.messageContent = ko.observable(``);
        /** @type {KnockoutObservableArray<ConversationMessageAttachmentViewModel>} */
        self.messageAttachments = ko.observableArray([]);
        self.conversationCreatedDateTime = ko.observable('');
        self.conversationName = ko.observable('');
        self.conversationNameInputValue = ko.observable('');
        /** @type {KnockoutObservableArray<IRecipient>} */
        self.recipients = ko.observableArray([]);
        /** @type {KnockoutObservable<string>} */
        self.sendFromHostedNumberId = ko.observable();
        /** @type {KnockoutReadonlyComputed<IWebMessagingNumber[]>} */
        self.sendFromHostedNumbers = null;
        /** @type {KnockoutObservableArray<PhoneNumberDetailsPresentationObject>} */
        self.autoCompleteRecipients = ko.observableArray([]);
        self.showConversationDetails = ko.observable(false);
        self.scrollPositionY = ko.observable(null);
        self.isScrolledToBottom = ko.observable(true);
        self.haveReachedBeginningOfConversation = ko.observable(true);
        self.haveReachedEndOfConversation = ko.observable(true);
        /** @type {KnockoutObservable<string>} */
        self.hostedPhoneNumber = ko.observable(null);
        self.isLoadingPreviousMessagesPage = ko.observable(false);
        self.isLoadingNextMessagesPage = ko.observable(false);
        self.showMessagesLoadingState = ko.observable(false);
        self.isLoadingNewMessages = ko.observable(false);
        self.newMessageEventReceived = ko.observable(false);
        self.isLoadingAttachments = ko.observable(false);
        /** @type {KnockoutComputed<ConversationMessageViewModel[]>} */
        self.conversationMessages = null;
        self.conversationReadState = ConversationReadState.getOrGenerateInfo(conversationId);
        self.attemptedMaxAttachments = ko.observable(false);
        self.maxAttachmentsPillMessage = ko.observable(null);
        self.requestPreviousMessagesPage = ko.observable(false);
        self.requestNextMessagesPage = ko.observable(false);
        self.sidebarContacts = _contactsState.sidebarContacts;

        self.showAllMedia = ko.observable(false);
        const _fullScreenMediaModal = new FullScreenMediaModalViewModel(self.showAllMedia);

        self.showAllLinks = ko.observable(false);

        /** @type {KnockoutObservableArray<IGetSmsConversationSubscriber>} */
        self.subscribers = ko.observableArray();
        self.forceUnreadMessage = ko.observable(false);

        // Used in Messages View Model,
        self.conversationId = ko.observable(conversationId);
        self.conversationDraftId = ko.observable(conversationDraftId);
        /** @type {KnockoutObservable<string>} */
        self.draftMatchingConversationId = ko.observable(null);
        /** @type {KnockoutObservable<ConversationMessageViewModel>} */
        self.messageToBeSent = ko.observable(null);
        self.messageIdToJumpTo = ko.observable(null);

        self.isCompositionComplete = ko.observable(false);
        self.isArchivingConversation = ko.observable(false);

        self.isConversationVisible = ko.pureComputed(() => {
            return self.isCompositionComplete() && !self.isArchivingConversation();
        });

        self.isNewConversation = ko.pureComputed(() => isNew);
        self.isNumberLimited = ko.pureComputed(() => {
            const sendFromHostedNumberId = self.sendFromHostedNumberId();

            if (!sendFromHostedNumberId) {
                return false;
            }

            const webMessagingNumbers = webMessagingDataSource.webMessagingNumbers();

            return !!webMessagingNumbers.find(numberInfo => {
                return numberInfo.reachedLimit && numberInfo.accountHostedNumberId === sendFromHostedNumberId;
            });
        });

        self.isAnyRecipientBlockedOrUnsubscribed = ko.pureComputed(() => {
            return self.isAnyRecipientBlocked() || self.isAnyRecipientUnsubscribed();
        });

        self.isAnyRecipientBlocked = ko.pureComputed(() => {
            return self.blockedRecipients().length > 0;
        });

        self.blockedRecipients = ko.pureComputed(() => {
            const recipients = self.recipients();
            if (!recipients) {
                return [];
            }

            const blockedPhoneNumbers = _blockingState.blockedPhoneNumbers();
            return recipients.filter((r) => blockedPhoneNumbers.some(x => x.phoneNumber === _phoneNumberFormatter.toEOneSixFour(r.phoneNumber)));
        });

        self.blockedContactLabel = ko.pureComputed(() => {
            const blockedRecipientCount = self.blockedRecipients().length;
            if (self.recipients().length > 1) {
                return i18next.t('messages:nBlockedContacts', { count: blockedRecipientCount });
            } else if (blockedRecipientCount > 0) {
                return i18next.t('messages:blockedContact');
            } else {
                return '';
            }
        });

        self.isAnyRecipientUnsubscribed = ko.pureComputed(() => {
            return self.unsubscribedRecipients().length > 0 && self.isAnyRecipientBlocked() === false;
        });

        self.unsubscribedRecipients = ko.pureComputed(() => {
            const recipients = self.recipients();
            if (!recipients) {
                return [];
            }

            const foundHostedPhoneNumber = self.sendFromHostedNumbers().find((hostedNumber) => {
                return hostedNumber.accountHostedNumberId === self.sendFromHostedNumberId();
            });
            if (foundHostedPhoneNumber === undefined) {
                return [];
            }

            const fromPhoneNumber = foundHostedPhoneNumber.hostedPhoneNumber;
            const toPhoneNumbers = recipients.map((r) => r.phoneNumber);
            return _smsPhoneNumberOptOutsState.getOptedOutPhoneNumbers(fromPhoneNumber, toPhoneNumbers);
        });

        self.unsubscribedRecipientsLabel = ko.pureComputed(() => {
            const unsubscribedRecipientsCount = self.unsubscribedRecipients().length;
            if (unsubscribedRecipientsCount > 1) {
                return i18next.t('messages:nUnsubscribedPhoneNumber_plural', { count: unsubscribedRecipientsCount });
            } else if (unsubscribedRecipientsCount > 0) {
                return i18next.t('messages:unsubscribedPhoneNumber');
            } else {
                return '';
            }
        });

        self.messageAttachmentsReadyToSend = ko.pureComputed(() => {
            return self.messageAttachments()
                .filter((attachment) => {
                    return attachment.isReadyToSend();
                });
        });


        self.canStartNewConversation = ko.pureComputed(() => {
            if (self.blockedRecipients() && self.recipients()) {
                return self.blockedRecipients().length !== self.recipients().length;
            }

            return false;
        });

        self.canStartNewConversationWithOptedInRecipients = ko.pureComputed(() => {
            if (self.unsubscribedRecipients() && self.recipients()) {
                return self.unsubscribedRecipients().length !== self.recipients().length;
            }

            return false;
        });

        self.canSubmit = ko.pureComputed(() => {
            if (self.isNumberLimited()) {
                return false;
            }

            if (self.isSubmittingMessage()) {
                return false;
            }

            if (self.messageContent().length <= 0 && self.messageAttachmentsReadyToSend().length <= 0) {
                return false;
            }

            const recipients = self.recipients();
            return !!recipients.length;
        });

        self.onDeleteDraft = () => {
            _facade.deleteDraftConversation(conversationDraftId)
                .done(() => {
                    webMessagingDataSource.addConversationDraftIdPendingDeletion(conversationDraftId);
                    messagesState.selectedConversationDraftId(null);
                    router.navigate(navigationConfiguration.routesById.messages.routeId);
                });
        };

        self.onAddRecipient = (/** @type {IRecipient} */recipient) => {
            return _facade.getPhoneNumberLocation(recipient.phoneNumber)
                .done(response => {
                    const {city, region} = response;
                    recipient.meta = _facade.buildMetaForNumbersNotInOptions(recipient.phoneNumber, city, region);
                    self.recipients.push(recipient);
                });
        };

        self.onStartNewConversation = () => {
            const blockedPhoneNumbers = _blockingState.blockedPhoneNumbers();
            const newRecipients = self.recipients()
                .filter((r) => !blockedPhoneNumbers.some(x => x.phoneNumber === _phoneNumberFormatter.toEOneSixFour(r.phoneNumber)))
                .map((r) => r.phoneNumber);

            if (newRecipients.length > 0) {
                return self.createDraftConversation(newRecipients);
            }
        };

        self.onStartNewConversationWithOptedInRecipients = () => {
            const unsubscribedRecipients = self.unsubscribedRecipients();
            const newRecipients = self.recipients()
                .filter((r) => unsubscribedRecipients.some(phoneNumber => phoneNumber === _phoneNumberFormatter.toEOneSixFour(r.phoneNumber)))
                .map((r) => r.phoneNumber);

            if (newRecipients.length > 0) {
                return self.createDraftConversation(newRecipients);
            }
        };

        self.createDraftConversation = (/** @type {string[]} */recipients) => {
            if (self.isSubmittingMessage()) {
                return;
            }

            self.isSubmittingMessage(true);

            _facade.createDraftConversation(recipients)
                .fail(() => {
                    self.isSubmittingMessage(false);
                })
                .done((conversationDraftId) => {
                    self.isSubmittingMessage(false);
                    const newMessageUrl = `${navigationConfiguration.routesById.newMessage.baseUrl}/${conversationDraftId}`;
                    router.navigate(newMessageUrl);
                    messagesState.selectedConversationDraftId(conversationDraftId);
                    messagesState.selectedConversationId(null);
                });
        };

        //#region Submit Message

        self.isSubmittingMessage = ko.observable(false);

        self.onSubmit = (/** @type {string} */message) => {
            if (self.isSubmittingMessage()) {
                return _promiseFactory.wait();
            }

            self.isSubmittingMessage(true);

            message = message || self.messageContent();
            self.messageContent("");

            const attachments = self.messageAttachments()
                .filter((attachment) => {
                    return attachment.isReadyToSend();
                });

            const attachmentIds = attachments.map((attachment) => {
                return attachment.attachmentId();
            });

            self.messageAttachments.removeAll();

            const messageToBeSent = _messageManager.addSendingMessage(message, attachments, true);
            _resetUnreadIdentifiersAndCount();
            const existingConversationId = self.conversationId() || self.draftMatchingConversationId();
            if (existingConversationId) {
                _facade.updateSmsConversationLastViewedDate(existingConversationId);
            }

            let apiRequest;

            if (isNew) {
                const sendFromHostedNumberId = self.sendFromHostedNumberId();
                const recipients = self.recipients()
                    .map((recipient) => {
                        const recipientPhoneNumber = recipient.phoneNumber;

                        if (_phoneNumberFormatter.isShortCode(recipientPhoneNumber)) {
                            return recipientPhoneNumber;
                        }

                        return _phoneNumberFormatter.toEOneSixFour(recipientPhoneNumber);
                    });

                apiRequest = _facade.sendNewSms({
                    sendFromHostedNumberId,
                    recipientPhoneNumbers: recipients,
                    message,
                    attachmentIds,
                    smsConversationDraftId: conversationDraftId
                })
                    .then(({deliveryStatus, smsConversationId, messageId, message, urlIds}) => {
                        switch (deliveryStatus) {
                            case DeliveryStatus.Sent:
                            case DeliveryStatus.Sending:
                            case DeliveryStatus.QueuedForSend:
                            case DeliveryStatus.WaitingForAttachments:
                            case DeliveryStatus.Delivered: {
                                const url = `${navigationConfiguration.routesById.conversation.baseUrl}/${smsConversationId}`;
                                router.navigate(url);
                                break;
                            }
                        }

                        messageToBeSent.content = message;
                        messageToBeSent.updateDeliveryStatus(deliveryStatus);
                        messageToBeSent.smsConversationMessageId = messageId;
                        _tryUpdateLinkPreviews(urlIds, messageId);
                    });
            } else {
                apiRequest = _facade.sendSmsReply(conversationId, message, attachmentIds)
                    .then(({messageId, message, deliveryStatus, urlIds}) => {
                        messageToBeSent.content = message;

                        if (_messageManager.hasMessage(messageId)) {
                            _messageManager.deleteMessage(messageToBeSent.smsConversationMessageId);
                            _tryUpdateLinkPreviews(urlIds, messageId);
                        }
                        else {
                            _messageManager.updateMessageAfterSend(messageToBeSent.smsConversationMessageId, messageId, deliveryStatus);
                            _tryUpdateLinkPreviews(urlIds, messageToBeSent.smsConversationMessageId);
                        }

                        conversationDraftId = null;
                    });
            }

            return apiRequest
                .then(() => {
                    self.isSubmittingMessage(false);
                })
                .fail(() => {
                    messageToBeSent.updateDeliveryStatus(DeliveryStatus.Failed);
                });
        };

        self.onMessageContentEdited = () => {
            if (self.isSubmittingMessage()) {
                return _promiseFactory.wait();
            }

            const message = self.messageContent();

            if (conversationDraftId) {
                return _facade.updateConversationDraftContent({
                    content: message,
                    smsConversationDraftId: conversationDraftId
                });
            }
            else {
                const attachmentIds = self.messageAttachments()
                    .filter(attachment => {
                        return attachment.isReadyToSend();
                    }).map(attachment => {
                        return attachment.attachmentId();
                    });

                return _facade.createConversationDraftMessage({
                    content: message,
                    attachmentIds: attachmentIds,
                    smsConversationId: conversationId
                })
                .then(({smsConversationDraftId}) => {
                    conversationDraftId = smsConversationDraftId;
                });
            }
        };

        //#endregion

        self.dispose = () => {
            _disposables.forEach(disposable => disposable.dispose());

            _disposables = [];
        };

        self.activate = () => {
            _promiseFactory = new PromiseFactory();
            _facade = new ConversationFacade();
            _facade.init(_promiseFactory);

            self.getMap = _facade.getMap;

            const currentConversationId = conversationId || conversationDraftId;

            _messageManager = new ConversationMessageManager(
                currentConversationId,
                sessionAccountInfo.userId(),
                _retryMessage,
                _deleteMessage,
                self.isNumberLimited
            );

            const conversationMediaFacade = new ConversationMediaFacade();
            const conversationLinksFacade = new ConversationLinksFacade();

            const isDraft = !conversationId && conversationDraftId;

            _mediaManager = new ConversationMediaManager(
                currentConversationId,
                conversationMediaFacade,
                isDraft
            );

            _linksManager = new ConversationLinksManager(
                currentConversationId,
                conversationLinksFacade,
                isDraft
            );

            self.conversationDetailsViewModel = new ConversationDetailsViewModel(
                _onCloseDetailsClickedCallback,
                _markConversationAsReviewedDetailsCallback,
                _markConversationAsNew,
                _archiveConversation,
                _updateConversationName,
                _onSeeAllAttachmentsClickedCallback,
                _onSeeAllLinksClickedCallback,
                _mediaManager,
                eventManager,
                _linksManager
            );
            self.conversationMediaViewModel = new ConversationMediaViewModel(_mediaManager, self.showAllMedia);
            self.conversationLinksViewModel = new ConversationLinksViewModel(_linksManager, self.showAllLinks);
            self.conversationHeaderViewModel = new ConversationHeaderViewModel(_onInfoBubbleClickedCallback);
            self.conversationMessagesViewModel = new ConversationMessagesViewModel(
                _getPreviousMessagesPage,
                _getNextMessagesPage,
                _onBottomReached,
                _reloadConversationAtBottom
            );
            self.messageComposeAreaViewModel = new MessageComposeAreaViewModel(_cancelAttachmentCallback, self.isAnyRecipientBlockedOrUnsubscribed);

            _disposables.push(_messageManager, _mediaManager, _facade, _linksManager);

            self.conversationMessages = _messageManager.conversationMessages;
            self.messageIdToJumpTo = messagesState.selectedMessageId;

            return _initialize();
        };

        self.detached = () => {
            self.isCompositionComplete(false);
            _resetUnreadIdentifiersAndCount();
            self.isScrolledToBottom(false);

            eventManager.unsubscribe(_attachmentClickedSubscription);
            eventManager.unsubscribe(_attachmentFromDetailsClickedSubscription);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
            _markConversationAsReviewed();
            // When a user goes to a different route, but then hits the back button,
            // this subscription needs to be reestablished here because .activate() does not resubscribe to it
            _attachmentClickedSubscription = eventManager.subscribeSmsAttachmentClicked(_onSmsAttachmentClicked);
            _attachmentFromDetailsClickedSubscription = eventManager.subscribeSmsAttachmentFromDetailsClicked(_onSmsAttachmentFromDetailsClicked);
        };

        const _initialize = () => {
            self.sendFromHostedNumbers = _facade.getWebMessagingNumbers();
            _messageManager.setHostedNumbers(self.sendFromHostedNumbers());

            if (isNew) {
                _facade.getDraftConversation(conversationDraftId, self.sendFromHostedNumbers())
                    .fail(() => {
                        messagesState.selectedConversationDraftId(null);
                        _navigateToConversationErrorPage();
                    })
                    .done((draft) => {
                        _setNewConversationData(draft);

                        _disposables.push(
                            self.recipients.subscribe(_onRecipientsChanged),
                            self.sendFromHostedNumberId.subscribe(_onSendFromNumberChangedBeforeChange, self, "beforeChange"),
                            self.sendFromHostedNumberId.subscribe(_onSendFromNumberChanged),
                            self.draftMatchingConversationId.subscribe(_onDraftMatchingConversationIdChanged),
                            self.messageAttachments.subscribe(_onMessageAttachmentsChanged)
                        );

                        _checkForMatchingConversation();
                        _facade.onSmsConversationMessage(_onSmsConversationMessage);
                        _facade.onSmsConversationMessageDeleted(_onSmsConversationMessageDeleted);
                        _facade.onSmsConversationMessageAttachmentUpdated(_onSmsConversationMessageAttachmentUpdated);
                        _facade.onUrlPreviewConversionCompleted(_onUrlPreviewConversionCompleted);

                        self.isInitialized(true);
                    });
            } else {
                _facade.getSmsConversation(conversationId, MESSAGE_PAGE_SIZE, self.messageIdToJumpTo())
                    .fail(() => {
                        messagesState.selectedConversationId(null);
                        _navigateToConversationErrorPage();
                    })
                    .done((result) => {
                        _setExistingConversationData(result);

                        _disposables.push(
                            self.messageContent.subscribe(_publishSomeoneIsTyping),
                            self.messageAttachments.subscribe(_publishSomeoneIsTyping),
                            self.messageAttachments.subscribe(_onMessageAttachmentsChanged),
                            self.requestPreviousMessagesPage.subscribe(_onPagingRequestChanged),
                            self.requestNextMessagesPage.subscribe(_onPagingRequestChanged),
                            self.sidebarContacts.subscribe(_onSidebarContactsChanged)
                        );

                        _facade.onSmsUserIsTyping(_messageManager.setSomeoneIsTyping);
                        _facade.onSmsConversationMessage(_onSmsConversationMessage);
                        _facade.onSmsConversationMessageDeleted(_onSmsConversationMessageDeleted);
                        _facade.onSmsConversationNameChanged(_onSmsConversationNameChanged);
                        _facade.onSmsConversationUserAssociationChanged(_onSmsConversationUserAssociationChanged);
                        _facade.onSmsPermissionChanged(_onSmsPermissionChanged);
                        _facade.onSmsConversationMessageAttachmentUpdated(_onSmsConversationMessageAttachmentUpdated);
                        _facade.onUrlPreviewConversionCompleted(_onUrlPreviewConversionCompleted);

                        self.isInitialized(true);
                    });
            }

            _mediaManager.init();
            _linksManager.init();

            return _promiseFactory.wait();
        };
    };
});

