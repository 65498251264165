define('externalDependencies/configuration/kazooApiConfiguration',['settings/settings'], function() {
    var KazooApiConfigurationSingleton = function() {
        if (KazooApiConfigurationSingleton.prototype._singletonInstance) {
            return KazooApiConfigurationSingleton.prototype._singletonInstance;
        }
        KazooApiConfigurationSingleton.prototype._singletonInstance = this;

        var settings = require('settings/settings');
        this.apiUrl = settings.externalDependencies.kazooApiServerUrl;
    };

    return new KazooApiConfigurationSingleton();
});
