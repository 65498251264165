define('presentation/settings/prompts/facades/addPromptsFacade',[
    'businessServices/converters/durationTimeFormatter',
    'businessServices/uploads/customerUpload',
    'common/promises/waitHandle',
    'common/storage/commonState',
    'constants/referentialConstraintEnumerations',
    'externalDependencies/clientWebSocket',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
    'presentation/settings/prompts/presentationObjects/voicePromptPresentationObject'
], function () {
    return function () {
        let self = this;
        let _promiseFactory = null;

        const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');
        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const WaitHandleConstructor = require('common/promises/waitHandle');

        const audioPreviewSocket = require('externalDependencies/clientWebSocket').forApplication("audioPreview");
        const promptSocket = require('externalDependencies/clientWebSocket').forApplication("voicePrompt");

        const _commonState = require('common/storage/commonState');
        const _integrityWarningMessagesEnumeration = require('constants/referentialConstraintEnumerations');
        const _customerUpload = require('businessServices/uploads/customerUpload');

        const onAudioPreviewCompleted = (previewInfo) => {
            if (previewInfo.uploadId === waitingForPreviewUploadId) {
                if (previewInfo.isSuccessful) {
                    waitingForPreviewUrl = previewInfo.playUrl;
                    waitingForPreviewDuration = previewInfo.durationInMilliseconds;
                    waitingForPreviewUploadIdFound = true;
                } else {
                    waitingForPreviewUploadIdFound = false;
                }

                waitingForPreview.setGreenLight();
            }
        };
        audioPreviewSocket.subscribeToEvent("audioPreviewConversionCompleted", onAudioPreviewCompleted);
        
        const waitingForPreview = new WaitHandleConstructor();
        waitingForPreview.setRedLight();
        let waitingForPreviewUrl = null;
        let waitingForPreviewDuration = null;
        let waitingForPreviewUploadId = null;
        let waitingForPreviewUploadIdFound = false;

        const AudioPresentationObjectConstructor = require('presentation/common/audioPresentationObject');
        const VoicePromptPresentationObjectConstructor = require('presentation/settings/prompts/presentationObjects/voicePromptPresentationObject');

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        let voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        voicePromptAudioFactory.init();

        const _getFormattedDurationProperty = (durationInMilliseconds) => {
            let formatter = new TimeFormatterConstructor();
            return formatter.formatTotalMilliseconds(durationInMilliseconds);
        };

        const getIntegrityWarnings = (constraints) => {
            return constraints.map((constraint) => {
                const constraintType = constraint.parentType + '-' + constraint.referencedType;
                if (_integrityWarningMessagesEnumeration[constraintType]) {
                    const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                    integrityWarning.entityText = _integrityWarningMessagesEnumeration[constraintType].entityText;
                    integrityWarning.entityName = constraint.name;
                    integrityWarning.icon = _integrityWarningMessagesEnumeration[constraintType].icon;
                    integrityWarning.message = _integrityWarningMessagesEnumeration[constraintType].message;
                    return integrityWarning;
                }
            });
        };

        self.formatAudioSource = (audioDataObj) => {
            voicePromptAudioFactory.formatAudioSource(audioDataObj);
        };

        self.findVoicePrompt = (voicePromptId) => {
            return _promiseFactory.defer((promise) => {
                const request = {
                    accountVoicePromptId: voicePromptId,
                };

                promptSocket.send("getVoicePrompt", request, (accountVoicePrompt, err) => {
                    if (err) {
                        promise.reject(new Error(err));
                        return;
                    }

                    const displayVoicePrompt = new VoicePromptPresentationObjectConstructor();
                    displayVoicePrompt.voicePromptId = voicePromptId;
                    displayVoicePrompt.promptName = accountVoicePrompt.name;
                    displayVoicePrompt.voicePromptDurationSeconds = ko.observable(_getFormattedDurationProperty(accountVoicePrompt.durationInMilliseconds));
                    promise.resolve(displayVoicePrompt);
                });
            });
        };

        self.addVoicePrompt = (voicePromptName, voicePromptFileHandler) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _customerUpload.uploadFile(voicePromptFileHandler)
                    .fail(promise.reject)
                    .done(({uploadId}) => {
                        const parameters = {
                            name: voicePromptName,
                            uploadId: uploadId,
                        };

                        promptSocket.send("addVoicePrompt", parameters, (response, err) => {
                            if (err) {
                                promise.reject(new Error(err));
                                return;
                            }

                            if (response.isSuccessful === false) {
                                promise.reject(new Error(response.failureReason));
                                return;
                            }

                            const {accountVoicePromptId} = response;
                            promise.resolve({accountVoicePromptId});
                        });
                    });
            });
        };

        self.updateVoicePrompt = (voicePromptId, voicePromptName, voicePromptFileHandler) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                if (voicePromptFileHandler) {
                    _customerUpload.uploadFile(voicePromptFileHandler)
                        .fail(promise.reject)
                        .done(({uploadId}) => {
                            const parameters = {
                                accountVoicePromptId: voicePromptId,
                                name: voicePromptName,
                                uploadId: uploadId,
                            };

                            promptSocket.send("updatePromptWithAudio", parameters, (response, err) => {
                                if (err) {
                                    promise.reject(new Error(err));
                                    return;
                                }

                                if (response.isSuccessful === false) {
                                    promise.reject(new Error(response.failureReason));
                                    return;
                                }

                                promise.resolve({accountVoicePromptId : voicePromptId});
                            });
                        });
                } else {
                    const parameters = {
                        accountVoicePromptId: voicePromptId,
                        name: voicePromptName
                    };
                    promptSocket.send("updatePromptNameOnly", parameters, () => {
                        promise.resolve();
                    });
                }
            });
        };

        self.getCurrentVoicePrompt = (voicePromptId) => {
            const voicePrompt = voicePromptAudioFactory.getVoicePrompt(voicePromptId);
            return voicePrompt;
        };

        self.getMissingVoicePrompt = () => {
            const missingVoicePrompt = voicePromptAudioFactory.getMissingVoicePrompt();
            return missingVoicePrompt;
        };

        self.getVoicePromptDownloadUrl = (voicePromptId) => {
            return _promiseFactory.defer((deferredObject) => {
                const request = {
                    accountVoicePromptId: voicePromptId
                };
                promptSocket.send("getVoicePromptMp3Url", request, (result) => {
                    if (result.isSuccessful === false) {
                        const integrityWarnings = getIntegrityWarnings(result.constraints);
                        deferredObject.reject({constraintViolations: integrityWarnings});
                    } else {
                        deferredObject.resolve(result.url);
                    }
                });
            });
        };

        self.isPromptNameUnique = (voicePromptId, promptName) => {
            const trimmedPromptName = promptName.trim().toLowerCase();
            const isPromptNameUnique = _commonState.prompts().every(
                prompt => prompt.name().toLowerCase() !== trimmedPromptName || prompt.id === voicePromptId
            );

            return isPromptNameUnique;
        };

        self.requestForFileConversion = (voicePromptFileHandler) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _customerUpload.uploadFile(voicePromptFileHandler)
                    .fail(promise.reject)
                    .done(({uploadId}) => {
                        waitingForPreviewUploadIdFound = false;
                        waitingForPreviewUploadId = uploadId;
                        waitingForPreview.setRedLight();

                        const parameters = {
                            uploadId: uploadId,
                        };

                        audioPreviewSocket.send("convertToMp3", parameters, (response, err) => {
                            if (err) {
                                promise.reject(new Error(err));
                                return;
                            }

                            waitingForPreview.waitForSignal(() => {
                                if (waitingForPreviewUploadIdFound === false) {
                                    promise.resolve({isSuccessful:false});
                                    return;
                                }

                                let audioSource = new AudioPresentationObjectConstructor();
                                audioSource.audioUrl = waitingForPreviewUrl;
                                audioSource.duration(_getFormattedDurationProperty(waitingForPreviewDuration));
                                voicePromptAudioFactory.formatAudioSource(audioSource);

                                const result = {
                                    isSuccessful : true,
                                    audioFile : audioSource,
                                };

                                promise.resolve(result);
                            });
                        });
                    });
            });
        };

        self.cancelGetConvertedFile = () => {
            waitingForPreviewUploadIdFound = false;
            waitingForPreview.setGreenLight();
        };

        self.dispose = () => {
            audioPreviewSocket.disposeOfEvents();
        };

        self.init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
        };
    };
});

