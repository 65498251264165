define('common/presentation/iconNames',[], function () {
    // foo.svg -> svg-foo
    return {
        actionAdd: 'svg-plus',
        actionCall: 'svg-calls',
        actionClose: 'svg-remove',
        actionCopy: 'svg-copy',
        actionDeactivate: 'svg-deactivate',
        actionDelete: 'svg-delete',
        actionEdit: 'svg-edit',
        actionEmail: 'svg-email',
        actionFilter: 'svg-content-filter',
        actionHangup: 'svg-hangup',
        actionMessage: 'svg-messages',
        actionPhoneRinging: 'svg-ringing',
        actionPlay: 'svg-play',
        actionPause: 'svg-pause',
        actionReactivate: 'svg-reactivate',
        actionRefresh: 'svg-refresh',
        actionRemove: 'svg-remove',
        addContact: 'svg-add-contact-web',
        analyticsGraphLegend: 'svg-chart-legend-item',
        appStore: 'svg-app-store',
        arrowInbound: 'svg-arrow-inbound',
        arrowOutbound: 'svg-arrow-outbound',
        arrowDown: 'svg-arrow',
        attachment: 'svg-attachment',
        audioFile: 'svg-audio-file',
        audioRecording: 'svg-bulls-eye',
        audioRecordingStop: 'svg-bulls-eye-stop',
        autoAttendant: 'svg-auto-attendant',
        autoAttendantSubMenu: 'svg-auto-attendant-sub-menu',
        billingDownload: 'svg-billing-download',
        blockedContact: 'svg-blocked-contact',
        blocking: 'svg-blocked-contact',
        calendar: 'svg-calendar',
        outboundCalls: 'svg-phone',
        callFlowHangup: 'svg-hangup',
        callFlowLoop: 'svg-infinity',
        callFlowRinging: 'svg-ringing',
        callRecording: 'svg-call-recording',
        callRecordingBadge: 'svg-recording-badge',
        callRecordingBadgeBoth: 'svg-recording-badge-both',
        caret: 'svg-caret',
        checkmark: 'svg-checkmark',
        companyAddressBook: 'svg-company-address-book',
        confirmationSuccess: 'svg-circle-with-checkmark',
        confirmationFailed: 'svg-circle-with-x',
        confirmationError: 'svg-round-triangle-with-exclamation',
        contact: 'svg-contact',
        contactPermissions: 'svg-contact-active-navigation',
        contactBlocked: 'svg-contact-blocked',
        contentFilter: 'svg-content-filter',
        denied: 'svg-denied',
        device: 'svg-device',
        disabledMessages: 'svg-disabled-messages',
        disabledPhone: 'svg-disabled-phone',
        disabledRecording: 'svg-disabled-recording',
        download: 'svg-download',
        dropdownArrow: 'svg-arrow-dropdown',
        editPencil: 'svg-edit-pencil',
        forwardingNumber: 'svg-forwarding-number',
        googlePlay: 'svg-google-play',
        group: 'svg-group',
        hourglass: 'svg-hourglass',
        loginAnimation: 'svg-login-animation-group',
        logoBadge: 'svg-tresta-badge-logo',
        logoText: 'svg-tresta-text-logo',
        mainNavAnalytics: 'svg-analytics',
        mainNavCalls: 'svg-calls',
        mainNavContacts: 'svg-contact',
        mainNavMessages: 'svg-messages',
        mainNavInbox: 'svg-inbox',
        mainNavSettings: 'svg-settings',
        mainNavAnalyticsActive: 'svg-analytics-active',
        mainNavCallsActive: 'svg-calls-active',
        mainNavContactsActive: 'svg-contact-active',
        mainNavMessagesActive: 'svg-messages-active',
        mainNavInboxActive: 'svg-inbox-active',
        mainNavSettingsActive: 'svg-settings-active',
        messageSending: 'svg-messages-sending',
        messages: 'svg-messages',
        messagesFailed: 'svg-messages-failed',
        messagesHourglass: 'svg-messages-hourglass',
        messagesSent: 'svg-messages-sent',
        moreInfo: 'svg-info-i',
        numberPickerFailed: 'svg-circle-x',
        numberPickerLocal: 'svg-local',
        numberPickerLocation: 'svg-map-marker',
        numberPickerRemove: 'svg-circle-x',
        numberPickerSearch: 'svg-number-picker-search',
        numberPickerTollfree: 'svg-toll-free',
        numberPickerTransfer: 'svg-transfer',
        optionIcon: 'svg-bulls-eye',
        phone: 'svg-skinny-phone',
        phoneNumber: 'svg-phone-number',
        picture: 'svg-picture',
        recording: 'svg-recording',
        schedule: 'svg-calendar',
        scheduleSegment: 'svg-calendar',
        search: 'svg-search',
        send: 'svg-send',
        showPassword: 'svg-show-password',
        sidebarAccountProfile: 'svg-billing-profile',
        sidebarAutoAttendants: 'svg-auto-attendant',
        sidebarBillCycleToDate: 'svg-bill-cycle-to-date',
        sidebarBillingHistory: 'svg-billing-history',
        sidebarDevices: 'svg-device',
        sidebarExtensions: 'svg-extension',
        sidebarForwardingNumbers: 'svg-forwarding-number',
        sidebarGroups: 'svg-group',
        sidebarHostedNumbers: 'svg-phone-number',
        sidebarMakeAPayment: 'svg-make-a-payment',
        sidebarMessagingCompliance: 'svg-messages',
        sidebarMyProfile: 'svg-user',
        sidebarPaymentMethods: 'svg-payment-method',
        sidebarPrompts: 'svg-audio-file',
        sidebarSchedules: 'svg-calendar',
        sidebarSubscriptions: 'svg-inbox',
        sidebarUsers: 'svg-user',
        sidebarVoicemail: 'svg-voicemail',
        signupArrowForward: 'svg-arrow-forward',
        signupArrowForwardFilled: 'svg-arrow-forward-filled',
        signupCheckmark: 'svg-circle-checkmark-signup',
        signupConfirmation: 'svg-confirmation-checkmark',
        signupConfirmationAirwaves: 'svg-confirmation-airwaves',
        signupConfirmationDesktop: 'svg-confirmation-desktop-icon',
        signupConfirmationDevice: 'svg-confirmation-mobile-icon',
        signupFooterCircle: 'svg-circle-filled',
        signupNumberLocal: 'svg-local-phone-type',
        signupNumberTollfree: 'svg-toll-phone-type',
        signupNumberTransfer: 'svg-port-phone-type',
        signupPorting1: 'svg-signup-porting-form-1',
        signupPorting2: 'svg-signup-porting-form-2',
        signupPorting3: 'svg-signup-porting-form-3',
        signupUser: 'svg-user-icon',
        signupVerticalDots: 'svg-vertical-dots',
        signupWarning: 'svg-signup-warning-icon',
        sortArrow: 'svg-arrow-up',
        sortCollapseAll: 'svg-sort-collapse-all',
        sortEmail: 'svg-sort-email',
        sortExpandAll: 'svg-sort-expand-all',
        sortName: 'svg-sort-name',
        sortPhoneNumber: 'svg-sort-phone-number',
        sortRecentlyAdded: 'svg-sort-recently-added',
        sortRecentlyModified: 'svg-sort-recently-modified',
        underConstruction: 'svg-under-construction',
        uploadPhoto: 'svg-upload-photo',
        user: 'svg-user',
        userSettingsBilling: 'svg-bill-cycle-to-date',
        userSettingsHelpCenter: 'svg-help',
        userSettings: 'svg-settings',
        userSettingsSignOut: 'svg-sign-out',
        videoExpand: 'svg-video-expand',
        videoMinimize: 'svg-video-minimize',
        videoMute: 'svg-video-mute',
        videoPause: 'svg-video-pause',
        videoPlay: 'svg-video-play',
        videoUnmute: 'svg-video-unmute',
        viewInThread: 'svg-view-in-thread',
        voicemail: 'svg-voicemail',
        voicemailBox: 'svg-voicemail',
        warningAttention: 'svg-attention',
        warningCheckmark: 'svg-checkmark',
        yourAddressBook: 'svg-your-address-book'
    };
});
