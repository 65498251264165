define('presentation/settings/autoAttendant/viewModels/addAutoAttendantViewModel',[
    'businessServices/converters/durationTimeFormatter',
    'businessServices/events/eventManager',
    'businessServices/router/router',
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'constants/autoAttendantVoiceRouteEnumerations',
    'constants/inlinePromptDefaultOptionEnumerations',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/copyAutoAttendantActionViewModel',
    'presentation/common/voicePrompt/voicePromptAudioFactory',
    'presentation/settings/autoAttendant/facades/addAutoAttendantFacade',
    'presentation/settings/autoAttendant/presentationObjects/subMenuItemPresentationObject',
    'presentation/settings/autoAttendant/validators/addAutoAttendantValidator',
    'presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const  self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const TimeFormatterConstructor = require('businessServices/converters/durationTimeFormatter');
        const _timeFormatter = new TimeFormatterConstructor();

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        const _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();

        const CopyAutoAttendantActionViewModelConstructor = require('presentation/common/actionModal/viewModels/copyAutoAttendantActionViewModel');
        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const SorterConstructor = require('common/collections/collectionSorter');
        const SubMenuConnectorConstructor = require('presentation/settings/autoAttendant/viewModels/autoAttendantSubMenuViewModel');
        const SubMenuItemPresentationObjectConstructor = require('presentation/settings/autoAttendant/presentationObjects/subMenuItemPresentationObject');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _inlinePromptEnumerations = require('constants/inlinePromptDefaultOptionEnumerations');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');
        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        let _facade = null;
        let _validator = null;
        let _eventManager = null;

        let _selectedVoicePromptChangedSubscription = null;

        self.isSelectPrompt = ko.pureComputed(() => self.selectedPrompt().id === _inlinePromptEnumerations.SELECT_PROMPT.id);
        self.isCurrentPrompt = ko.pureComputed(() => !self.isSelectPrompt());

        self.availablePrompts = ko.pureComputed(() => {
            let availablePrompts = _commonState.prompts().filter((prompt) => {
                return prompt.isSystemPrompt === false;
            }).concat([]);
            const sorter = new SorterConstructor();
            sorter.sort(availablePrompts, "name", true);
            return availablePrompts;
        });

        const _buildCopyModal = (autoAttendantId, autoAttendantName) => {
            let constructorParams = [autoAttendantId, autoAttendantName];

            self.actionModal
                .clearModal()
                .setContentViewModel(CopyAutoAttendantActionViewModelConstructor, constructorParams)
                .setHeaderText({i18n: 'saveCopy'})
                .setSubmitButtonText({i18n: 'save'});
        };

        const _promptSelectionChanged = () => {
            const selectedPrompt = self.selectedPrompt();
            if (selectedPrompt.id === _inlinePromptEnumerations.SELECT_PROMPT.id) {
                self.selectedPromptAudio(_inlinePromptEnumerations.SELECT_PROMPT);
            } else {
                self.isSystemPrompt(selectedPrompt.isSystemPrompt);

                const promptAudioObject = self.isSystemPrompt() ?
                    _voicePromptAudioFactory.getSystemVoicePrompt(selectedPrompt.id) :
                    _voicePromptAudioFactory.getVoicePrompt(selectedPrompt.id);

                promptAudioObject.duration = ko.pureComputed(() => _timeFormatter.formatTotalMilliseconds(selectedPrompt.durationInMilliseconds()));

                self.selectedPromptAudio(promptAudioObject);
                self.voicePromptId(selectedPrompt.id);
            }
        };

        const _loadAutoAttendantInfo = () => {
            return _promiseFactory.defer((deferredObject) => {
                let routingRule = null;
                let availableObjects = null;
                const getAutoAttendantPromiseFactory = new PromiseFactoryConstructor();

                getAutoAttendantPromiseFactory.defer((getAutoAttendantPromise) => {
                    if (self.autoAttendantId()) {
                        _facade.getAutoAttendant(self.autoAttendantId())
                            .fail(deferredObject.reject)
                            .done((autoAttendant) => {
                                self.autoAttendantId(autoAttendant.accountAutoAttendantId);
                                self.autoAttendantName(autoAttendant.name);
                                if (autoAttendant.accountVoicePromptId) {
                                    self.voicePromptId(autoAttendant.accountVoicePromptId);
                                    self.selectedPrompt(_commonState.get(self.voicePromptId()));
                                    self.isSystemPrompt(false);
                                } else if (autoAttendant.systemAudioClipId) {
                                    self.voicePromptId(autoAttendant.systemAudioClipId);
                                    self.selectedPrompt(_commonState.get(self.voicePromptId()));
                                    self.isSystemPrompt(true);
                                }
                                self.isVoicePromptEnabled(true);
                                self.isVoiceAutoAttendantPromptInvalid(false);
                                routingRule = autoAttendant.routingRule;
                                availableObjects = autoAttendant.combinedResults;
                                getAutoAttendantPromise.resolve();
                            });
                    } else {
                        self.selectedPrompt(_inlinePromptEnumerations.SELECT_PROMPT);
                        self.isVoicePromptEnabled(false);
                        self.isVoiceAutoAttendantPromptInvalid(false);
                        _facade.getAutoAttendantCallFlowObjects()
                            .fail(deferredObject.reject)
                            .done((combinedResult) => {
                                availableObjects = combinedResult;
                                getAutoAttendantPromise.resolve();
                            });
                    }
                });

                getAutoAttendantPromiseFactory.wait()
                    .done(() => {
                        const subMenuItemPresentationObject = new SubMenuItemPresentationObjectConstructor();
                        const connection = new SubMenuConnectorConstructor(self.autoAttendantId(), self.availablePrompts, routingRule, _promiseFactory, _facade, self.hasAutoAttendantChanged, subMenuItemPresentationObject, self.autoAttendants, availableObjects);
                        connection.isInitialVoiceRoute(true);
                        connection.activate()
                            .fail(deferredObject.reject)
                            .done(() => {
                                self.connector(connection);
                                deferredObject.resolve();
                            });
                    });
            });
        };

        const _checkAllSelectedPrompts = (subMenuItems, updatedVoicePromptId, updatedAudioData) => {
            subMenuItems.forEach((subMenuItem) => {
                if (subMenuItem.addPromptsViewModel !== undefined) {
                    if (subMenuItem.addPromptsViewModel().voicePromptId() === updatedVoicePromptId) {
                        subMenuItem.addPromptsViewModel().audioData(updatedAudioData);
                    }
                }

                if (subMenuItem.connector() !== null) {

                    if (subMenuItem.connector().callFlow) {
                        subMenuItem.connector().callFlow.forEach((callFlowSegment) => {
                            if (callFlowSegment.routeToItemId === updatedVoicePromptId) {
                                callFlowSegment.currentVoicePrompt(updatedAudioData);
                                callFlowSegment.conversionComplete(true);
                                callFlowSegment.conversionInProgress(false);
                            }
                        });
                    }

                    if (subMenuItem.connector().subMenuItems !== undefined) {
                        _checkAllSelectedPrompts(subMenuItem.connector().subMenuItems(), updatedVoicePromptId, updatedAudioData);
                        return;
                    }
                }
                return;
            });
        };

        self.autoAttendantId = ko.observable();
        self.isNewAutoAttendant = ko.observable(false);
        self.autoAttendantMenuError = ko.observable();
        self.autoAttendantSubMenuError = ko.observable();
        self.autoAttendantPromptError = ko.observable();
        self.autoAttendantVoiceRouteId = ko.observable().extend({observeState: true});
        self.autoAttendants = ko.pureComputed(() => _commonState.autoAttendants());
        self.isVoiceEnabled = ko.observable().extend({observeState: true});
        self.voicePromptId = ko.observable("").extend({observeState: true});
        self.isSystemPrompt = ko.observable(false);
        self.selectedPrompt = ko.observable(null).extend({observeState: true});
        self.selectedPromptAudio = ko.observable(null);
        self.connector = ko.observable(null).extend({observeState: true});
        self.autoAttendantName = ko.observable("").extend({observeState: true});
        self.hasAutoAttendantChanged = ko.observable(false).extend({observeState: true});
        self.isVoicePromptEnabled = ko.observable(true);
        self.isVoiceAutoAttendantPromptInvalid = ko.observable(false);
        self.areMenuVoicePromptsInvalid = ko.observable(false);
        self.isMenuInvalid = ko.observable(false);
        self.isSubMenusInvalid = ko.observable(false);
        self.modelStateObserver = null;
        self.actionModal = null;
        self.addAudioFileUrl = _navigationConfiguration.routesById.newPrompt.url;
        self.autoAttendantUrl = _navigationConfiguration.routesById.autoAttendant.url;
        self.emptyMessage = _inlinePromptEnumerations.SELECT_PROMPT.name;

        self.createAudioFileLinkText = _i18n.t('addAutoAttendant:createAudioFileLinkText');
        self.noAudioFilesWarningMessage = _i18n.t('addAutoAttendant:noAudioFilesWarningMessage');
        self.hasAudioFiles = ko.pureComputed(() => self.availablePrompts().length > 0);

        self.headerI18nKey = ko.pureComputed(() => self.autoAttendantId() ? "addAutoAttendant:headerEdit" : "addAutoAttendant:headerAdd");
        self.saveButtonTitle = ko.pureComputed(() => self.autoAttendantId() ? _i18n.t('addAutoAttendant:save') : _i18n.t('addAutoAttendant:add'));
        self.showCopyAutoAttendant =  ko.pureComputed(() => self.autoAttendantId());
        self.showWarningBox = ko.pureComputed(() => self.invalidMessages().length > 0);
        self.invalidMessages = ko.pureComputed(() => {
            let invalidMessages = [];
            if (self.isMenuInvalid()) {
                invalidMessages.push(_i18n.t('addAutoAttendant:menuRoutesToAtLeastOneEndpoint'));
            }
            if (self.isSubMenusInvalid()) {
                invalidMessages.push(_i18n.t('addAutoAttendant:subMenuRoutesToAtLeastOneEndpoint'));
            }
            if (self.areMenuVoicePromptsInvalid()) {
                invalidMessages.push(_i18n.t('addAutoAttendant:voicePromptMissingOnMenu'));
            }
            if (self.isVoiceAutoAttendantPromptInvalid()) {
                invalidMessages.push(_i18n.t('addAutoAttendant:addOrSelectPrompt'));

            }
            return invalidMessages;
        });

        self.validationWarningMessages = ko.pureComputed(() => {
            return [
                {
                    message: _i18n.t('addAutoAttendant:validationTabDescription'),
                    listItems: self.invalidMessages()
                },
                {
                    message: _i18n.t('addAutoAttendant:validationPleaseFix'),
                    listItems: []
                }
            ];
        });

        self.launchCopyAutoAttendant = () => {
            return _promiseFactory.defer(deferredObject => {
                _buildCopyModal(self.autoAttendantId(), self.autoAttendantName());
                self.actionModal.showModal();
                deferredObject.resolve();
            });
        };

        self.validate = () => _validator.validate();

        self.shouldScrollTop = ko.observable(false);

        self.saveAutoAttendant = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done(isValid => {
                        self.shouldScrollTop(!isValid);
                        if (isValid === false) {
                            deferredObject.resolve(false);
                            self.shouldScrollTop(false);
                            return;
                        }
                        const facadePromise = self.autoAttendantId() ?
                            _facade.updateAutoAttendant(self.autoAttendantId(), self.autoAttendantName(), self.voicePromptId(), self.isSystemPrompt(), self.connector().subMenuItems()) :
                            _facade.addAutoAttendant(self.autoAttendantName(), self.voicePromptId(), self.isSystemPrompt(), self.connector().subMenuItems());

                        facadePromise
                            .fail(deferredObject.reject)
                            .done(() => {
                                deferredObject.resolve();
                                _selectedVoicePromptChangedSubscription.dispose();
                                self.modelStateObserver.saveData();
                                _router.navigate(self.autoAttendantUrl);
                            });
                    });
            });
        };

        self.cancel = () => {
            self.modelStateObserver.navigateWithoutWarning = true;
            _selectedVoicePromptChangedSubscription.dispose();
            _router.navigate(self.autoAttendantUrl );
        };

        self.onSaveAutoAttendantName = (autoAttendantName) => {
            return _promiseFactory.defer((deferredObject) => {
                self.autoAttendantName(autoAttendantName);
                _validator.validateAutoAttendantNameUniqueness(self.autoAttendantName())
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            deferredObject.resolve(false);
                            return;
                        }
                        deferredObject.resolve(true);
                    });
            });
        };

        const _rebuildRouteToSubMenu = (nestedSubMenuItems) => {

            let subMenuItems = nestedSubMenuItems ? nestedSubMenuItems : self.connector().subMenuItems();

            subMenuItems.forEach(subMenuItem => {
                if (subMenuItem.type() === _commonState.types.subMenu && subMenuItem.voicePromptId() === null) {
                    subMenuItem.showVoicePromptOption(true);
                    subMenuItem.isVoicePromptEnabled(true);
                    subMenuItem.isVoicePromptInvalid(false);
                    subMenuItem.voicePromptId(_inlinePromptEnumerations.SELECT_PROMPT.id);
                    subMenuItem.routeId("subMenuId");
                    subMenuItem.type(_voiceRouteEnumerations.subMenu);
                }

                let subMenuConnector = subMenuItem.connector();

                if (subMenuConnector && subMenuConnector.subMenuItems) {
                    _rebuildRouteToSubMenu(subMenuItem.connector().subMenuItems());
                }
            });
        };

        self.compositionComplete = () => {
            if (!self.autoAttendantId()) {
                self.isNewAutoAttendant(true);
            }
        };

        self.detached = () => {
            _selectedVoicePromptChangedSubscription.dispose();
            _facade.dispose();
        };

        let _isActivated = false;

        self.activate = (autoAttendantId) => {
            if (_isActivated) {
                return;
            }
            _isActivated = true;

            if (autoAttendantId) {
                self.autoAttendantId(autoAttendantId);
            }

            const FacadeConstructor = require('presentation/settings/autoAttendant/facades/addAutoAttendantFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/autoAttendant/validators/addAutoAttendantValidator');
            _validator = new ValidatorConstructor();

            const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            self.actionModal = new ActionModalViewModelConstructor();

            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            _selectedVoicePromptChangedSubscription = self.selectedPrompt.subscribe(_promptSelectionChanged);

            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            _loadAutoAttendantInfo()
                .done(() => {
                    self.modelStateObserver.addChildObserver(self.connector().modelStateObserver);
                    self.modelStateObserver.commitData();
                })
                .fail((error) => {
                    throw error;
                });

            _facade.onNewPromptConverted((promptInfo) => {
                const {accountVoicePromptId, audioData} = promptInfo;

                if (self.voicePromptId === accountVoicePromptId) {
                    self.selectedPromptAudio(audioData);
                }

                _checkAllSelectedPrompts(self.connector().subMenuItems(), accountVoicePromptId, audioData);

            });

            _validator.registerViewModel(self, _facade);
            _eventManager.subscribeRouteToModalChanged(_rebuildRouteToSubMenu);
            
            return _promiseFactory.wait();
        };
    };
});

