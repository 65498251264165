define('presentation/settings/forwardingNumbers/validators/addForwardingNumbersViewModelValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/validPhoneNumberSpecification',
    'presentation/common/validation/commonValidator',
    'constants/countryEnumerations',
    'common/converters/phoneNumberFormatter'
], function () {
    return function () {
        const self = this;

        let _addForwardingNumbersViewModel;
        let _addForwardingNumbersFacade;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        let _commonValidator = null;

        const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

        const _countryEnumerations = require('constants/countryEnumerations');

        self.registerViewModel = (addForwardingNumbersViewModel, addForwardingNumbersFacade) => {
            _commonValidator = new CommonValidatorConstructor(addForwardingNumbersViewModel);

            const validationRules = new ValidationRulesConstructor();

            validationRules.field("forwardingNumberName")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addForwardingNumbers:nameRequired')
                .addValidationI18n(_validateForwardingNumberNameUnique, 'addForwardingNumbers:nameUnique');
            validationRules.field("phoneNumber")
                .addValidationI18n(_commonValidator.isStringWithValue, 'addForwardingNumbers:numberRequired')
                .addValidationI18n(_validatePhoneNumberLength, 'addForwardingNumbers:numberInvalid')
                .addValidationI18n(_isAllowedPhoneNumber, 'addForwardingNumbers:numberRestricted');

            _addForwardingNumbersViewModel = addForwardingNumbersViewModel;
            _addForwardingNumbersFacade = addForwardingNumbersFacade;

            _commonValidator.useManualValidation();
            _commonValidator.setValidationRules(validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateForwardingNumberNameUnique = (forwardingNumberName) => {
            const forwardingNumberId = _addForwardingNumbersViewModel.forwardingNumberId;
            return _addForwardingNumbersFacade.isForwardingNumberNameUnique(forwardingNumberId, forwardingNumberName);
        };

        const _validatePhoneNumberLength = (phoneNumber) => {
            if (phoneNumber) {
                const cleanPhoneNumber = phoneNumberFormatter.clean(phoneNumber);
                return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
            }
        };
        const _isAllowedPhoneNumber = (phoneNumber) => {
            return _validPhoneNumberSpecification.isAllowedPhoneNumber(phoneNumber);
        };
    };
});

