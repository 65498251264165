define('entities/userSettingsEntity',[],function() {
    return function(){
        var self = this;
        self.userSettingId = null;
        self.userId = null;
        self.settings =  null;
        self.createdDateTime = null;
        self.modifiedDateTime = null;
    };
});

