define('presentation/login/viewModels/loginViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/authentication/sessionWorkflowFlags',
    'businessServices/signup/affiliateSignup',
    'businessServices/browserSupport/browserType',
    'businessServices/login/accountStatusEvaluator',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/validationMessageEnumerations',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/modal',
    'presentation/common/window/windowControl',
    'presentation/login/validators/loginViewModelValidator',
    'presentation/loginNotifications/viewModels/accountCreationInProgressViewModel',
    'presentation/loginNotifications/viewModels/resendInviteViewModel',
    'presentation/signup/common/signupNavigationManager',
    'settings/navigationConfiguration'
], function () {
    return function () {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SignupAuthenticationStoreConstructor = require('businessServices/authentication/stores/signupAuthenticationStore');
        const _signupAuthenticationStore = new SignupAuthenticationStoreConstructor();
        const _signupSocket = require('externalDependencies/clientWebSocket').forApplication("signup");

        const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
        const _signupSessionStore = new SignupSessionStoreConstructor();

        const AffiliateSignupConstructor = require('businessServices/signup/affiliateSignup');
        const _affiliateSignup = new AffiliateSignupConstructor();

        const ActionModalViewModelConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
        const _actionModal = new ActionModalViewModelConstructor();

        const _browserType = require('businessServices/browserSupport/browserType');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        const _urlFormatter = require('common/url/urlFormatter');

        let _accountStatusEvaluator = null;
        let _authenticationManager = null;
        let _activeModalViewModel = null;
        let _messageType = null;
        let _modalService = null;
        let _router = null;
        let _signupNavigationManager = null;
        let _validator = null;
        let _windowControl = null;

        let AccountCreationInProgressViewModelConstructor = null;
        let ResendInviteViewModelConstructor = null;

        const MILLISECONDS_TO_DISPLAY_CREATION_IN_PROGRESS_MODAL = 5000;

        const _navigateOrLoadModal = (navigationDestination) => {
            return _promiseFactory.defer((deferredObject) => {
                _accountStatusEvaluator.evaluate()
                    .done((evaluation) => {
                        const accountStatusResponses = _accountStatusEvaluator.evaluateResponses;
                        switch (evaluation) {
                            case accountStatusResponses.normal:
                                const sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');
                                const originalPathname = sessionWorkflowFlags.getOriginalPathname();
                                if (originalPathname === null) {
                                    _router.navigate(navigationDestination);
                                } else {
                                    sessionWorkflowFlags.setOriginalPathname(null);
                                    _router.navigate(originalPathname);
                                }
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.closedNoBalance:
                                _router.navigate(_navigationConfiguration.reactivation);
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.creationInProgress:
                                const accountCreationInProgressViewModel = new AccountCreationInProgressViewModelConstructor();
                                const modalStartDate = new Date();
                                _modalService.showModal(accountCreationInProgressViewModel);
                                _activeModalViewModel = accountCreationInProgressViewModel;
                                _signupSocket.subscribeToEvent("signupComplete",
                                    () => _closeAccountCreationInProgressModal(modalStartDate, accountCreationInProgressViewModel, deferredObject));
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.closedBalanceOwed:
                                _router.navigate(_navigationConfiguration.closedBalanceOwed);
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.closedDoNotReactivate:
                                _router.navigate(_navigationConfiguration.closedDoNotReactivate);
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.pastDueBalance:
                                _router.navigate(_navigationConfiguration.payBalance);
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.miscellaneousProblems:
                                _router.navigate(_navigationConfiguration.miscellaneousProblems);
                                deferredObject.resolve();
                                return;
                            case accountStatusResponses.closedAndCannotReactivate:
                                _router.navigate(_navigationConfiguration.closedAndCannotReactivate);
                                deferredObject.resolve();
                                return;
                            default:
                                const error = new Error("Unknown evaluation of state.");
                                error.evaluation = evaluation;
                                throw error;
                        }
                    });
            });
        };
        const _continueLoginFromSignupState = (modalOpenTime, accountCreationInProgressViewModel, promise) => {
            let mSecondsModalHasBeenDisplayed = new Date() - modalOpenTime;
            setTimeout(() => {
                _signupSessionStore.clear();
                _signupAuthenticationStore.clear();
                _affiliateSignup.clear();
                _loginWithCredentials(promise, _navigationConfiguration.signupLandingPage);
                self.password("");
                self.password.resetValidation();
            }, MILLISECONDS_TO_DISPLAY_CREATION_IN_PROGRESS_MODAL - mSecondsModalHasBeenDisplayed);
        };
        const _closeAccountCreationInProgressModal = (modalOpenTime, accountCreationInProgressViewModel, promise) => {
            let mSecondsModalHasBeenDisplayed = new Date() - modalOpenTime;
            setTimeout(() => {
                _signupSessionStore.clear();
                _signupAuthenticationStore.clear();
                _affiliateSignup.clear();
                _router.navigate(_navigationConfiguration.signupLandingPage);
                promise.resolve();
            }, MILLISECONDS_TO_DISPLAY_CREATION_IN_PROGRESS_MODAL - mSecondsModalHasBeenDisplayed);
        };
        const _signupComplete = (eventData, promise, accountCreationInProgressViewModel, modalOpenTime) => {
            _authenticationManager.loginWithToken(eventData.tokenId, eventData.accountId, eventData.userId)
                .done(loginResult => {
                    if (loginResult === true) {
                        _closeAccountCreationInProgressModal(modalOpenTime, accountCreationInProgressViewModel, promise);
                    } else {
                        promise.resolve({keepSpinning: true});
                    }
                });
        };
        const _checkSignupInProgress = () => _promiseFactory.deferIndefinitely(promise => {
            _signupAuthenticationStore.init();
            _signupAuthenticationStore.load()
                .done((signupSessionId) => {
                    if (signupSessionId === null) {
                        promise.resolve();
                    } else {
                        _signupSessionStore.init();
                        _signupSessionStore.getState(true)
                            .fail(promise.reject)
                            .done((sessionState) => {
                                const _signupSessionStatusConstants = require('constants/signupSessionStatusConstants');

                                switch (sessionState.signupDocument.signupSessionStatus) {
                                    case _signupSessionStatusConstants.accountCreationFailed:
                                    case _signupSessionStatusConstants.accountCreationFailedRetryQueued:
                                    case _signupSessionStatusConstants.accountCreationInProgress:
                                    case _signupSessionStatusConstants.accountCreationPending:
                                        const accountCreationInProgressViewModel = new AccountCreationInProgressViewModelConstructor();
                                        const modalOpenTime = new Date();
                                        _signupSocket.subscribeToEvent("signupComplete",
                                            (eventData) => _signupComplete(eventData, promise, accountCreationInProgressViewModel, modalOpenTime));
                                        _modalService.showModal(accountCreationInProgressViewModel);
                                        _activeModalViewModel = accountCreationInProgressViewModel;
                                        break;
                                    case _signupSessionStatusConstants.accountCreationCompleted:
                                        if (sessionState.password === null) {
                                            promise.resolve();
                                        } else {
                                            _authenticationManager.loginWithCredentials(sessionState.signupDocument.emailAddress, sessionState.password)
                                                .done(loginResult => {
                                                    _signupSessionStore.clear();
                                                    _signupAuthenticationStore.clear();
                                                    _affiliateSignup.clear();
                                                    if (loginResult.success === true) {
                                                        _router.navigate(_navigationConfiguration.signupLandingPage);
                                                    }
                                                    promise.resolve();
                                                });
                                        }
                                        break;
                                    default:
                                        promise.resolve();
                                        break;
                                }
                            });
                    }
                });
        });
        const _loginWithCredentials = (promise, navigationDestination) => {
            _authenticationManager.loginWithCredentials(self.username(), self.password())
                .fail(promise.reject)
                .done((loginResult) => {
                    if (loginResult.success === true) {
                        _signupSessionStore.clear();
                        _signupAuthenticationStore.clear();
                        _affiliateSignup.clear();
                        _navigateOrLoadModal(navigationDestination)
                            .done(() => {
                                promise.resolve();
                            });

                    } else if (loginResult.isSignupInProgress === true) {
                        const accountCreationInProgressViewModel = new AccountCreationInProgressViewModelConstructor();
                        const modalStartDate = new Date();
                        _signupSessionStore.init();
                        _signupSessionStore.connectSocket(loginResult.signupSessionId, loginResult.secretKey)
                            .done(() => {
                                _signupSocket.subscribeToEvent(
                                    "signupComplete",
                                    (eventData) => _continueLoginFromSignupState(modalStartDate, accountCreationInProgressViewModel, promise)
                                );
                            });
                        _modalService.showModal(accountCreationInProgressViewModel);
                        _activeModalViewModel = accountCreationInProgressViewModel;
                    } else {
                        const loginStatuses = _authenticationManager.login_statuses;
                        const _loginValidationMessageEnumerations = _validationMessageEnumerations.clientui.presentation.login.login;
                        switch (loginResult.errorMessage) {
                            case loginStatuses.deactivated_user:
                                self.username.isValid(false);
                                self.username.validationMessage(_loginValidationMessageEnumerations.userDeactivated);
                                break;
                            case loginStatuses.invalid_user_name:
                                self.username.isValid(false);
                                self.username.validationMessage(_loginValidationMessageEnumerations.usernameInvalid);
                                break;
                            case loginStatuses.invalid_password:
                                self.password.isValid(false);
                                self.password.validationMessage(_loginValidationMessageEnumerations.passwordInvalid);
                                break;
                            case loginStatuses.invite_pending:
                                self.isResendInviteLinkVisible(true);
                                self.username.isValid(false);
                                self.username.validationMessage(_loginValidationMessageEnumerations.invitePending);
                                break;
                            case loginStatuses.invite_expired:
                                self.username.isValid(false);
                                self.username.validationMessage(_loginValidationMessageEnumerations.inviteExpired);
                                break;
                        }
                        promise.resolve();
                    }
                });
        };

        self.homeUrl = _urlFormatter.buildFrontendUrl("/");
        self.parent = null;
        self.accountName = ko.observable('');
        self.password = ko.observable('');
        self.username = ko.observable('');
        self.inviteExpirationDate = ko.observable('');
        self.isNotAuthenticated = ko.observable(true);
        self.isResendInviteLinkVisible = ko.observable(false);
        self.canDisplayUsernameValidation = ko.observable(false);
        self.canDisplayPasswordValidation = ko.observable(false);

        self.isMobileDevice = ko.pureComputed(() => _browserType.isMobileDevice);

        self.navigateToHomePage = () => {
            _windowControl.openHomePage("_self");
        };

        self.onSignUpClicked = () => {
            _signupNavigationManager.navigateToDefaultSignupPage();
        };

        self.redirectToForgotPassword = () => {
            _router.navigate(_navigationConfiguration.forgotPasswordPageUrl);
        };

        self.onLogInClicked = () => {
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                _validator.validate()
                    .done((isValid) => {
                        self.canDisplayUsernameValidation(true);
                        self.canDisplayPasswordValidation(true);
                        self.isResendInviteLinkVisible(false);
                        if (isValid) {
                            _loginWithCredentials(deferredObject, _navigationConfiguration.loginLandingPageUrl);
                        } else {
                            self.password('');
                            deferredObject.resolve();
                        }
                    })
                    .fail((error) => {
                        // Don't log the password, because that could get leaked.
                        error.userName = self.username();
                        deferredObject.reject(error);
                    });
            });
        };

        self.onDownLoadTheAppClicked = () => {
            if (_browserType.isAndroidDevice === true) {
                _windowControl.openAndroidAppUrl();
            } else {
                _windowControl.openIosAppUrl();
            }
        };

        self.showResendInviteModal = () => {
            return _promiseFactory.deferIndefinitely((resendInviteCompletedPromise) => {
                _actionModal.setContentViewModel(ResendInviteViewModelConstructor, [resendInviteCompletedPromise, self.username()]);
                _actionModal.setHeaderText({i18n: 'resendInvite:headerTitle'});
                _actionModal.setCancelButtonText({i18n: 'resendInvite:dismiss'});
                _actionModal.shouldDisplaySubmitButton(false);
                _actionModal.shouldDisplayDefaultFooterMessage(true);

                _actionModal.showModal()
                    .done(() => {
                        resendInviteCompletedPromise.resolve();
                    });
            });
        };

        self.resendInvite = () => {
            self.showResendInviteModal()
                .done(() => {
                    _modalService.closeModal(_actionModal);
                });
        };

        self.detached = () => {
            if (_activeModalViewModel) {
                _modalService.closeModal(_activeModalViewModel);
            }
            _signupNavigationManager.detached();
            _signupSocket.disposeOfEvents();
        };

        self.activate = (messageType) => {
            _authenticationManager = require('businessServices/authentication/authenticationManager');

            const SignupNavigationManagerConstructor = require('presentation/signup/common/signupNavigationManager');
            _signupNavigationManager = new SignupNavigationManagerConstructor();
            _signupNavigationManager.init();

            const ValidatorConstructor = require('presentation/login/validators/loginViewModelValidator');
            _validator = new ValidatorConstructor();

            _accountStatusEvaluator = require('businessServices/login/accountStatusEvaluator');
            _router = require('businessServices/router/router');
            _windowControl = require('presentation/common/window/windowControl');

            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            AccountCreationInProgressViewModelConstructor = require('presentation/loginNotifications/viewModels/accountCreationInProgressViewModel');
            ResendInviteViewModelConstructor = require('presentation/loginNotifications/viewModels/resendInviteViewModel');

            if (messageType) {
                _messageType = messageType;
            }

            return _initialize();
        };

        const _initialize = () => {
            _validator.registerViewModel(self);

            self.isNotAuthenticated(true);

            switch (_messageType) {
                case "continue":
                    let sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
                    if (sessionAccountInfo.isLoggedIn()) {
                        _navigateOrLoadModal(_navigationConfiguration.loginLandingPageUrl);
                    }
                    break;
                default:
                    _promiseFactory.deferIndefinitely(promise => _checkSignupInProgress()
                        .fail(promise.reject)
                        .done(promise.resolve)
                    );
            }

            return _promiseFactory.wait();
        };
    };
});

