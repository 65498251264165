define('presentation/settings/accountProfile/viewModels/accountProfileViewModel',[
        'businessServices/state/modelStateObserver',
        'common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'common/storage/commonState',
        'constants/accountStatusEnumerations',
        'constants/howWillYouUseTrestaEnumerations',
        'settings/navigationConfiguration',
        'plugins/router',
        'presentation/settings/accountProfile/facades/accountProfileFacade',
        'presentation/settings/accountProfile/presentationObjects/billingCommunication',
        'presentation/settings/accountProfile/validators/accountProfileValidator',
        'presentation/settings/accountProfile/viewModels/accountProfileBusinessViewModel',
        'presentation/settings/accountProfile/viewModels/accountProfilePersonalViewModel',
        'presentation/settings/closeAccount/viewModels/closeAccountPortInProgressActionModalViewModel',
        'presentation/common/actionModal/viewModels/actionModalViewModel',
        'presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel',
], function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');
            const _sorter = new SorterConstructor();

            let _router = null;
            const _navigationConfig = require('settings/navigationConfiguration');

            const ActionModalConstructor = require('presentation/common/actionModal/viewModels/actionModalViewModel');
            const AccountProfileBusinessViewModelConstructor = require('presentation/settings/accountProfile/viewModels/accountProfileBusinessViewModel');
            const AccountProfilePersonalViewModelConstructor = require('presentation/settings/accountProfile/viewModels/accountProfilePersonalViewModel');

            const CloseAccountPortInProgressActionModalViewModel = require('presentation/settings/closeAccount/viewModels/closeAccountPortInProgressActionModalViewModel');
            const BillingCommunicationPresentationConstructor = require('presentation/settings/accountProfile/presentationObjects/billingCommunication');
            const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

            const _accountStatusEnumerations = require('constants/accountStatusEnumerations');
            const _commonState = require('common/storage/commonState');
            const _howWillYouUseTrestaEnumerations = require('constants/howWillYouUseTrestaEnumerations');
            const _i18n = require('i18next');

            let _facade = null;
            let _newEmailAddressArrayId = 0;
            let _validator = null;

            let _disposables = [];

            const MAX_CC_EMAIL_ADDRESSES = 10;

            const _getSubscribersForSave = () => {
                self.subscriberSelectionViewModel.getSubscribers()
                    .done((result) => {
                        self.billingCommunicationSubscribers(result.subscribers.map(subscriber => {
                            let userOrUserGroup = _getUserOrUserGroupValue(_commonState.get(subscriber.id).id, self.allUsersAndUserGroups());

                            const foundPreviousSubscriber = self.billingCommunicationSubscribers().find(billingCommunicationSubscriber => {
                                let id = billingCommunicationSubscriber.key();
                                if (subscriber.subscriberType === "userGroup") {
                                    id = _commonState.resolveGroupIdToGuid(id);
                                }
                                return id === subscriber.id;
                            });

                            if (foundPreviousSubscriber) {
                                userOrUserGroup.arrayId = foundPreviousSubscriber.arrayId;
                            }
                            return userOrUserGroup;
                        }));
                    });
            };

            const _buildCleanBillingCommunications = () => {
                const billingCommunications = self.billingCommunicationEmailAddresses()
                    .filter((billingCommunicationEmailAddress) => {
                        return billingCommunicationEmailAddress.value() !== "";
                    })
                    .map((billingCommunicationEmailAddress) => {
                        return {
                            arrayId: billingCommunicationEmailAddress.arrayId,
                            type: "emailAddress",
                            emailAddress: billingCommunicationEmailAddress.value()
                        };
                    });

                return billingCommunications.concat(self.billingCommunicationSubscribers()
                    .map((billingCommunicationSubscriber) => {
                        const _billingCommunicationSubscriber = ko.unwrap(billingCommunicationSubscriber);
                        const billingCommunication = {
                            arrayId: _billingCommunicationSubscriber.arrayId,
                            type: _billingCommunicationSubscriber.type()
                        };
                        switch (billingCommunication.type) {
                            case "user":
                                billingCommunication.userId = _billingCommunicationSubscriber.key();
                                break;
                            case "userGroup":
                                billingCommunication.userGroupId = _billingCommunicationSubscriber.key();
                                break;
                        }
                        return billingCommunication;
                    })
                );
            };

            const _removeEmptyCCsBeforeSave = () => {
                let emptyAddedCCs = self.billingCommunicationEmailAddresses().filter((cc) => {
                    return cc.value() === "";
                });
                self.billingCommunicationEmailAddresses.removeAll(emptyAddedCCs);
            };

            const _getUserOrUserGroupValue = (userOrUserGroupId, allUsersAndUserGroups) => {
                return allUsersAndUserGroups.find((aUserOrUserGroup) =>{
                    return aUserOrUserGroup.key() === userOrUserGroupId;
                });
            };

            const _splitUpBillingCommunications = (billingCommunications) => {
                return _promiseFactory.defer((promise) => {
                    _facade.getAllUsersAndUserGroups()
                        .fail((error) => {
                            throw error;
                        })
                        .done((allUsersAndUserGroups) => {
                            self.allUsersAndUserGroups(allUsersAndUserGroups);
                            let billingCommunicationSubscribers = [];
                            let billingCommunicationEmailAddresses = [];
                            let userOrUserGroupFound = null;
                            billingCommunications.forEach((billingCommunication) => {
                                const billingCommunicationPresentation = new BillingCommunicationPresentationConstructor();
                                billingCommunicationPresentation.type(billingCommunication.type);
                                billingCommunicationPresentation.arrayId = billingCommunication.arrayId;
                                switch (billingCommunication.type) {
                                    case "user":
                                        userOrUserGroupFound = _getUserOrUserGroupValue(billingCommunication.userId, allUsersAndUserGroups);
                                        if (userOrUserGroupFound !== undefined) {
                                            billingCommunicationPresentation.sortOrder = 2;
                                            billingCommunicationPresentation.key(billingCommunication.userId);
                                            billingCommunicationPresentation.avatar(billingCommunication.avatar);
                                            billingCommunicationPresentation.status(userOrUserGroupFound.status());
                                            billingCommunicationPresentation.value(userOrUserGroupFound.value());
                                            billingCommunicationSubscribers.push(billingCommunicationPresentation);
                                        }
                                        break;
                                    case "userGroup":
                                        userOrUserGroupFound = _getUserOrUserGroupValue(billingCommunication.userGroupId, allUsersAndUserGroups);
                                        if (userOrUserGroupFound !== undefined) {
                                            billingCommunicationPresentation.sortOrder = 1;
                                            billingCommunicationPresentation.key(billingCommunication.userGroupId);
                                            billingCommunicationPresentation.status("userGroup");
                                            billingCommunicationPresentation.value(userOrUserGroupFound.value());
                                            billingCommunicationSubscribers.push(billingCommunicationPresentation);
                                        }
                                        break;
                                    case "emailAddress":
                                        billingCommunicationPresentation.key(billingCommunication.emailAddress);
                                        billingCommunicationPresentation.value(billingCommunication.emailAddress);
                                        billingCommunicationEmailAddresses.push(billingCommunicationPresentation);
                                        break;
                                    default:
                                        const error = new Error("unknown type");
                                        error.type = billingCommunication.type;
                                        error.arrayId = billingCommunication.arrayId;
                                        throw error;
                                }
                            });
                            _sorter.multiSort(billingCommunicationSubscribers, ["sortOrder", "value"], true);
                            self.billingCommunicationSubscribers(billingCommunicationSubscribers);
                            _sorter.sort(billingCommunicationEmailAddresses, "value");
                            self.billingCommunicationEmailAddresses(billingCommunicationEmailAddresses);
                            self.allUsersAndUserGroups(allUsersAndUserGroups.filter((userOrUserGroup) => userOrUserGroup.isEnabled())
                                .map((userOrUserGroup, index) => {
                                    userOrUserGroup.arrayId = "newUserOrGroup" + index;
                                    return userOrUserGroup;
                                }));
                            promise.resolve();
                        });
                });
            };

            const _onAccountTypeChanged = (accountType) => {
                self.howWillYouUseTresta.resetValidation();
                self.activeForm(null);
                if (accountType === _howWillYouUseTrestaEnumerations.business) {
                    self.activeForm(self.accountProfileBusinessViewModel);
                }
                if (accountType === _howWillYouUseTrestaEnumerations.personal) {
                    self.activeForm(self.accountProfilePersonalViewModel);
                }
            };

            self.accountProfileBusinessViewModel = null;
            self.accountProfilePersonalViewModel = null;

            self.isEditMode = ko.observable(false);
            self.accountNumber = ko.observable("");
            self.accountStatus = ko.observable();
            self.allUsersAndUserGroups = ko.observable();

            self.howWillYouUseTrestaOptions = [
                {title: _i18n.t('accountProfile:business'), value: _howWillYouUseTrestaEnumerations.business},
                {title: _i18n.t('accountProfile:personal'), value: _howWillYouUseTrestaEnumerations.personal}
            ];
            self.howWillYouUseTrestaTooltip = _i18n.t('accountProfile:howWillYouUseTrestaTooltip');

            self.formattedAccountTypeOptions = self.howWillYouUseTrestaOptions.map(option => ({textI18n: option.title, value: option.value}));
            self.howWillYouUseTresta = ko.observable("").extend({observeState: true});
            self.isAccountProfileSaved = ko.observable(true);
            self.addAnotherKey = ko.pureComputed(() => {
                return self.billingCommunicationEmailAddresses().length > 0 ? 'accountProfile:addAnother' : 'accountProfile:add';
            });
            self.billingCommunicationSubscribers = ko.observableArray([]).extend({observeState: true});
            self.billingCommunicationEmailAddresses = ko.observableArray([]).extend({observeState: true});
            self.modelStateObserver = null;
            self.isAccountClosed = ko.pureComputed(() => self.accountStatus() === _accountStatusEnumerations.status.closed);

            self.doesAccountHaveActiveNumberPortsInProgress = ko.observable(false);

            self.activeForm = ko.observable();
            self.activationData = ko.observable();
            self.showWarningMessage = ko.observable(false);
            self.invalidItems = ko.observableArray([]);
            self.shouldScrollToTop = ko.observable(false);
            self.canChangeAccountType = ko.observable(true);

            self.closeAccountClicked = () => {
                if (self.doesAccountHaveActiveNumberPortsInProgress()) {
                    const actionModal = new ActionModalConstructor();
                    actionModal.clearModal();
                    actionModal.setContentViewModel(CloseAccountPortInProgressActionModalViewModel);
                    actionModal.setHeaderText({i18n: 'closeAccount:portInProgress'});
                    actionModal.setCancelButtonText({i18n: 'common:dismiss'});
                    actionModal.shouldDisplayCancelButton(true);
                    actionModal.shouldDisplayAgentBypassButton(false);
                    actionModal.shouldDisplaySubmitButton(false);
                    actionModal.shouldDisplayDefaultFooterMessage(true);
                    actionModal.showModal();
                } else {
                    _router.navigate(_navigationConfig.routesById.closeAccount.url);
                }
            };

            const _saveAccountProfile = (accountProfile) => {
                return _promiseFactory.deferIndefinitely((promise) => {
                    _getSubscribersForSave();

                    accountProfile.howWillYouUseTresta = self.howWillYouUseTresta().trim();
                    accountProfile.billingCommunications = _buildCleanBillingCommunications();

                    _facade.saveAccountProfile(accountProfile)
                        .fail(promise.reject)
                        .done((result) => {
                            if (result) {
                                _facade.applyAvatarsToBillingSubscribers(result.billingCommunications)
                                    .fail(promise.reject)
                                    .done((newBillingCommunications) => {
                                        _splitUpBillingCommunications(newBillingCommunications)
                                            .fail(promise.reject)
                                            .done(() => {
                                                self.modelStateObserver.saveData();
                                                promise.resolve();
                                            });
                                    });
                            } else {
                                self.modelStateObserver.saveData();
                                promise.resolve();
                            }
                        });
                });
            };

            self.canAddAnotherBillingCommunicationEmailAddress = ko.pureComputed(() => self.billingCommunicationEmailAddresses().length < MAX_CC_EMAIL_ADDRESSES);
            self.addBillingCommunicationEmailAddress = () => {
                const billingCommunication = new BillingCommunicationPresentationConstructor();
                _newEmailAddressArrayId++;
                billingCommunication.arrayId = "newEmailAddress" + _newEmailAddressArrayId;
                billingCommunication.type("emailAddress");
                self.billingCommunicationEmailAddresses.push(billingCommunication);
            };
            self.deleteBillingCommunicationSubscriber = (billingCommunication) => {
                self.billingCommunicationSubscribers.remove(billingCommunication);
            };
            self.deleteBillingCommunicationEmailAddress = (billingCommunication) => {
                self.billingCommunicationEmailAddresses.remove(billingCommunication);
                _validator.validate();
            };
            self.maxCCEmailsMessage = _i18n.t('accountProfile:maxCCEmailsMessage');

            self.allUsersAndUserGroups = ko.observableArray([]);
            self.filterAvailableOptions = (allOptions, selectedOptions) => {
                let selectedOptionsKeys = [];
                selectedOptions.forEach((selectedOption) => {
                    const selectedOptionKey = selectedOption().key() + selectedOption().type();
                    selectedOptionsKeys[selectedOptionKey] = true;
                });
                return allOptions.filter((aOption) => {
                    const allOptionKey = aOption.key() + aOption.type();
                    return !selectedOptionsKeys[allOptionKey];
                });
            };

            const _setInvalidItems = (items) => {
                let invalidFields = [];
                if (self.howWillYouUseTresta.isInvalid()) {
                    invalidFields.push(_i18n.t('accountProfile:accountTypeRequired'));
                }
                if (items) {
                    invalidFields.push(...items);
                }
                self.invalidItems(invalidFields);
            };

            self.warningMessages = ko.pureComputed(() => {
                return [
                    {
                        message: _i18n.t('accountProfile:oopsInvalidItems'),
                        listItems: self.invalidItems()
                    },
                    {
                        message: _i18n.t('accountProfile:pleaseCorrectInvalidFields'),
                        listItems: []
                    }
                ];
            });

            self.validate = () => {
                return _promiseFactory.defer((promise) => {
                    _validator.validate()
                        .fail(promise.resolve)
                        .done((isAccountProfileValid) => {
                            _setInvalidItems();

                            if (isAccountProfileValid === false) {
                                self.showWarningMessage(true);
                                promise.resolve(isAccountProfileValid);
                                return;
                            } else {
                                self.showWarningMessage(false);
                            }
                            if (self.activeForm()) {
                                self.activeForm().validate()
                                    .done((isActiveFormValid) => {
                                        _setInvalidItems(self.activeForm().invalidItems());

                                        if (isActiveFormValid === false) {
                                            self.showWarningMessage(true);
                                        } else {
                                            self.showWarningMessage(false);
                                        }
                                        promise.resolve(isAccountProfileValid && isActiveFormValid);
                                    });
                            } else {
                                promise.resolve(isAccountProfileValid);
                            }
                        });
                });
            };

            self.save = () => {
                self.shouldScrollToTop(false);
                return _promiseFactory.deferIndefinitely((promise) => {
                    _removeEmptyCCsBeforeSave();
                    self.validate()
                        .fail(promise.reject)
                        .done((isValid) => {
                            if (isValid === false) {
                                self.shouldScrollToTop(true);
                                promise.resolve(false);
                                return;
                            }

                            self.showWarningMessage(false);
                            self.invalidItems([]);

                            const accountProfile = self.activeForm().getAccountProfile();

                            _saveAccountProfile(accountProfile)
                                .fail(promise.reject)
                                .done(() => {
                                    promise.resolve();
                                    location.reload();
                                });
                        });
                });
            };

            self.resetValidation = () => {
                self.showWarningMessage(false);
                self.invalidItems([]);
                _validator.resetValidation();
                self.accountProfileBusinessViewModel.resetValidation();
                self.accountProfilePersonalViewModel.resetValidation();
            };

            self.cancelForm = () => {
                self.accountProfileBusinessViewModel.cancelForm();
                self.accountProfilePersonalViewModel.cancelForm();
                self.modelStateObserver.restoreData();
                self.resetValidation();
            };

            self.detached = () => {
                _disposables.forEach(d => d.dispose());
                _disposables = [];
            };

            self.activate = () => {
                const FacadeConstructor = require('presentation/settings/accountProfile/facades/accountProfileFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                const SubscriberSelectionConstructor = require('presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel');
                self.subscriberSelectionViewModel = new SubscriberSelectionConstructor();
                self.subscriberSelectionViewModel.addAnotherMessage = _i18n.t('accountProfile:noMoreBillingSubscribers');
                self.subscriberSelectionViewModel.isAtLeastOneItemRequired = true;
                self.subscriberSelectionViewModel.lastItemRemoveTitle(_i18n.t('accountProfile:lastBillingSubscriber'));

                const ValidatorConstructor = require('presentation/settings/accountProfile/validators/accountProfileValidator');
                _validator = new ValidatorConstructor();

                self.accountProfileBusinessViewModel = new AccountProfileBusinessViewModelConstructor();
                self.accountProfilePersonalViewModel = new AccountProfilePersonalViewModelConstructor();

                _router = require('plugins/router');

                return _initialize();
            };

            const _initialize = () => {
                let initPromiseFactory = new PromiseFactoryConstructor();

                initPromiseFactory.defer((hasPortsInProgressPromise) => {
                    _facade.hasNumberPortsInProgress()
                        .fail((error) => {
                            throw error;
                        })
                        .done((doesAccountHaveNumberPortsInProgress) => {
                            self.doesAccountHaveActiveNumberPortsInProgress(doesAccountHaveNumberPortsInProgress);
                            hasPortsInProgressPromise.resolve();
                        });
                });

                initPromiseFactory.defer((getAccountProfilePromise) => {
                    _facade.getAccountProfile()
                        .fail(getAccountProfilePromise.reject)
                        .done((accountProfile) => {
                            self.accountNumber(accountProfile.accountNumber);
                            self.accountStatus(accountProfile.accountStatus);

                            if (accountProfile.howWillYouUseTresta) {
                                self.isEditMode(true);
                                self.activationData(accountProfile);
                                self.howWillYouUseTresta(accountProfile.howWillYouUseTresta);
                                if (accountProfile.howWillYouUseTresta === _howWillYouUseTrestaEnumerations.business) {
                                    self.activeForm(self.accountProfileBusinessViewModel);
                                    self.canChangeAccountType(false);
                                } else {
                                    self.activeForm(self.accountProfilePersonalViewModel);
                                }
                            }

                            let activatePromiseFactory = new PromiseFactoryConstructor();

                            activatePromiseFactory.defer((activateAccountProfilePersonalPromise) => {
                                self.accountProfilePersonalViewModel.activate(self.activationData())
                                    .done(activateAccountProfilePersonalPromise.resolve);
                            });

                            activatePromiseFactory.defer((activateAccountProfileBusinessPromise) => {
                                self.accountProfileBusinessViewModel.activate(self.activationData())
                                    .done(activateAccountProfileBusinessPromise.resolve);
                            });

                            activatePromiseFactory.defer((activateBillingCommunicationsPromise) => {
                                _splitUpBillingCommunications(accountProfile.billingCommunications)
                                    .fail(activateBillingCommunicationsPromise.reject)
                                    .done(() => {
                                        let commonStateSubscribers = self.billingCommunicationSubscribers().map((subscriber) => _commonState.get(subscriber.key()));
                                        self.subscriberSelectionViewModel.activate(commonStateSubscribers)
                                            .done(activateBillingCommunicationsPromise.resolve);
                                    });
                            });

                            activatePromiseFactory.wait()
                                .done(getAccountProfilePromise.resolve);
                        });
                });

                _promiseFactory.defer((initializePromise) => {
                    initPromiseFactory.wait()
                        .done(() => {

                            _disposables.push(self.howWillYouUseTresta.subscribe(_onAccountTypeChanged));

                            self.modelStateObserver = new ModelStateObserverConstructor(self, true);
                            self.modelStateObserver.addChildObserver(self.accountProfileBusinessViewModel.modelStateObserver);
                            self.modelStateObserver.addChildObserver(self.accountProfilePersonalViewModel.modelStateObserver);
                            self.modelStateObserver.addChildObserver(self.subscriberSelectionViewModel.modelStateObserver);

                            self.modelStateObserver.commitData();

                            _validator.registerViewModel(self, _facade);

                            initializePromise.resolve();
                        });
                });

                return _promiseFactory.wait();
            };
        };
    }
);

