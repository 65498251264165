define('presentation/settings/users/facades/deactivateUserModalFacade',['common/promises/promiseFactory',
        'common/storage/commonState',
        'externalDependencies/clientApi',
        'externalDependencies/clientWebSocket',
        'persistence/webSocket/webSocketApp',
        'presentation/common/voicePrompt/voicePromptAudioFactory',
        'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
], function () {
    const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');

    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = null;

    const _commonState = require('common/storage/commonState');
    const _deactivateUserSocket = require('externalDependencies/clientWebSocket').forApplication("deactivateUser");

    let _clientApi = null;
    let _webSocketApp = null;
    let _voicePromptAudioFactory = null;
    const GLOBAL_DEACTIVATED_USER_PROMPT = "global_deactivated_user_prompt";

    const _getAboutThisUser = (userId) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            let response = {};
            let getAboutThisUserPromiseFactory = new PromiseFactoryConstructor();
            getAboutThisUserPromiseFactory.deferIndefinitely((erlangPromise) => {
                _webSocketApp.send("get_about_this_user", {userId: userId}, (result) => {
                    response.userGroupsUserIsOnlyMemberOf = result.userGroupsUserIsOnlyMemberOf.map((userGroup) => {
                        const integrityPresentationObject = new IntegrityWarningPresentationObjectConstructor();
                        integrityPresentationObject.entityName = userGroup.groupName;
                        integrityPresentationObject.icon = "userGroup";
                        return integrityPresentationObject;
                    });
                    erlangPromise.resolve();
                });
            });
            getAboutThisUserPromiseFactory.deferIndefinitely((clientApiPromise) => {
                _deactivateUserSocket.send("getAboutThisUser", {userId: userId}, (result, err) => {
                    response.callsRoutedToUser = result.callsRoutedToUser;
                    response.voicemailBoxes = result.onlyUserSubscribedToVoicemailBoxes;
                    response.callRecordingBoxes = result.onlyUserSubscribedToCallRecordings;
                    response.messagingHostedNumbers = result.onlyUserMessagerOnHostedNumbers;
                    clientApiPromise.resolve();
                });
            });
            getAboutThisUserPromiseFactory.wait()
                .fail(deferredObject.reject)
                .done(() => {
                    deferredObject.resolve(response);
                });
        });
    };

    const _deactivateUser = (userId, sendAllCallsTo) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _deactivateUserClientApi(userId, sendAllCallsTo)
                .fail(deferredObject.reject)
                .done(() => {
                    _webSocketApp.send("deactivate_user", {userId: userId}, () => {
                        deferredObject.resolve();
                    });
                });
        });
    };

    const _deactivateConstrainedUser = (userId, sendAllCallsTo) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _deactivateUserClientApi(userId, sendAllCallsTo)
                .fail(deferredObject.reject)
                .done(() => {
                    if (sendAllCallsTo.subscriberType === "userGroup") {
                        const userGroup = _commonState.get(sendAllCallsTo.id);
                        sendAllCallsTo.id = userGroup.id;
                    }
                    _webSocketApp.send("deactivate_constrained_user", {userId: userId, sendAllCallsTo: sendAllCallsTo}, () => {
                        deferredObject.resolve();
                    });
                });
        });
    };

    const _deactivateUserClientApi = (userId, sendAllCallsTo) => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            const request = {
                userId: userId,
                subscriber: {
                    userId: sendAllCallsTo.subscriberType === "user" ? sendAllCallsTo.id : null,
                    userGroupId: sendAllCallsTo.subscriberType === "userGroup" ? sendAllCallsTo.id : null
                }
            };
            _deactivateUserSocket.send("deactivateUser", request, (result, err) => {
                if (err) {
                    deferredObject.reject(new Error(err));
                    return;
                }
                deferredObject.resolve();
            });
        });
    };

    const _getDefaultDeactivatedUserVoicePrompt = () => {
        return _voicePromptAudioFactory.getVoicePrompt(GLOBAL_DEACTIVATED_USER_PROMPT);
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const ClientApiConstructor = require('externalDependencies/clientApi');
        _clientApi = new ClientApiConstructor();
        _clientApi.init();

        const WebSockeAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSockeAppConstructor();
        _webSocketApp.init("user_deactivate_modal");

        const VoicePromptAudioFactoryConstructor = require('presentation/common/voicePrompt/voicePromptAudioFactory');
        _voicePromptAudioFactory = new VoicePromptAudioFactoryConstructor();
        _voicePromptAudioFactory.init();
    };

    return function () {
        const self = this;

        self.init = _init;
        self.getAboutThisUser = _getAboutThisUser;
        self.deactivateUser = _deactivateUser;
        self.deactivateConstrainedUser = _deactivateConstrainedUser;
        self.getDefaultDeactivatedUserVoicePrompt = _getDefaultDeactivatedUserVoicePrompt;
    };
});
