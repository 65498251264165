define('presentation/common/subscriberSelection/presentationObjects/subscriberPresentationObject',[],function() {
    return function(){
        var self = this;
        self.avatar = null;
        self.avatarType = null;
        self.displayName = null;
        self.hoverText = null;
        self.id = null;
        self.readOnly = null;
        self.removeToolTipText = null;
        self.subscriberId = null;
        self.subscriberType = null;
        self.voicemailBoxSubscriberId = null;
    };
});

