define('presentation/loginNotifications/viewModels/accountCreationInProgressViewModel',[
    'businessServices/authentication/authenticationManager',
    'businessServices/authentication/sessionWorkflowFlags',
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'plugins/router'
], function () {
    return function () {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        self.activate = () => _initialize();

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});

