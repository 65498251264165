define('presentation/messages/bindings/messagesComposeAreaAttachmentsBinding',[

],
function(){
    return {
        /** @type import('presentation/messages/bindings/messagesComposeAreaAttachmentsBinding')["init"] */
        init: function (element, valueAccessor) {
            const {
                attachmentRowCount,
            } = ko.unwrap(valueAccessor());

            const composeAreaAttachmentPreviewArea = document.querySelector('.message-compose-area-attachment-preview-container');
            const composeAreaAttachmentRowHeight = 80;

            /** @type {ResizeObserverCallback} */
            const onResize = () => {
                const attachmentPreviewAreaHeight = (composeAreaAttachmentPreviewArea &&
                    composeAreaAttachmentPreviewArea.getBoundingClientRect() &&
                    composeAreaAttachmentPreviewArea.getBoundingClientRect().height) || 0;

                attachmentRowCount(Math.floor(attachmentPreviewAreaHeight / composeAreaAttachmentRowHeight));
            };

            /* jshint ignore:start */
            const resizeObserver = new ResizeObserver(onResize);
            resizeObserver.observe(composeAreaAttachmentPreviewArea);
            /* jshint ignore:end */

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                attachmentRowCount(0);
                /* jshint ignore:start */
                resizeObserver.disconnect();
                /* jshint ignore:end */
            });
        }
    };
});
