define('presentation/contacts/viewModels/contactAddressViewModel',[
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'presentation/contacts/facades/contactCardFacade',
], function(
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type import('common/url/urlFormatter') */
    _urlFormatter,
    /** @type typeof import('presentation/contacts/facades/contactCardFacade') */
    ContactCardFacade
) {
    return function() {
        const self = this;
        /** @type import('presentation/contacts/facades/contactCardFacade') */
        let _contactCardFacade = null;

        const _promiseFactory = new PromiseFactory();

        self.addresses = ko.observableArray([]);
        self.addressesWithMap = ko.observableArray([]);

        self.activate = (/** @type { IContactAddressViewModelActivationObject } */ activationObject) => {
            self.addresses = activationObject.addresses;

            _contactCardFacade = new ContactCardFacade();
            _contactCardFacade.init(_promiseFactory);

            return _initialize();
        };

        const _addMapFieldsToAddresses = (/** @type { any[] }*/addresses) => {
            return addresses.map((address) => {

                const shouldShowMap = address.addressLineOne() && address.postalCode() && address.regionName() && address.city();

                return {
                    accountContactAddressId: address.accountContactAddressId,
                    addressLineOne: address.addressLineOne(),
                    addressLineTwo: address.addressLineTwo(),
                    addressLineThree: address.city() + ", " + address.regionName() + " " + address.postalCode(),
                    city: address.city(),
                    postalCode: address.postalCode(),
                    regionName: address.regionName(),
                    countryName: address.countryName(),
                    label: address.label(),
                    otherAddressInfo: address.otherAddressInfo(),
                    showMap:  ko.observable(shouldShowMap),
                    isMapLoaded: ko.observable(false),
                    mapUrl: ko.observable(null),
                    openMapUrl: ko.observable(null),
                    latitude: ko.observable(),
                    longitude: ko.observable()
                };
            });
        };

        const _initialize = () => {
            const initialData = self.addresses();
            const addressesWithMapFields = _addMapFieldsToAddresses(initialData);

            _contactCardFacade.getLatLongFromAddress((addressesWithMapFields))
                .then((/** @type { IContactAddressInfoResponse }*/ response) => {
                    if (response.postalAddresses.length > 0) {
                        response.postalAddresses.map((item, i) => {
                            addressesWithMapFields[i].latitude(item.latitude);
                            addressesWithMapFields[i].longitude(item.longitude);

                            const mapRequest = {
                                width: 383,
                                height: 172,
                                locations: [
                                    {
                                        useDarkPin: false,
                                        latitude: item.latitude,
                                        longitude: item.longitude
                                    }
                                ]
                            };

                          _contactCardFacade.getMap(mapRequest).then(mapUrl => {
                                if (mapUrl !== '') {
                                    addressesWithMapFields[i].mapUrl(mapUrl);

                                    const addressMapUrl = _urlFormatter.buildMapUrl([
                                        addressesWithMapFields[i].addressLineOne,
                                        addressesWithMapFields[i].city,
                                        addressesWithMapFields[i].regionName,
                                        addressesWithMapFields[i].postalCode
                                    ]);
                                    addressesWithMapFields[i].openMapUrl(addressMapUrl);
                                }
                                addressesWithMapFields[i].isMapLoaded(true);
                            });
                        });
                    }
                });

            self.addressesWithMap(addressesWithMapFields);

            return _promiseFactory.wait();
        };

    };
});
