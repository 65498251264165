define('common/converters/multiItemMessage',[], function() {
    // Joins a list of items together, separated by commas & the specified separator,
    // Uses the oxford comma if there are three or more items
    const _generateMultiItemMessage = (items, messageTexts, separator = "and ") => {
        if (typeof(messageTexts) === "string") {
            const originalMessageTexts = messageTexts;
            messageTexts = {
                plural: originalMessageTexts,
                singular: originalMessageTexts,
            };
        }

        if (items.length === 0) {
            return "";
        } else if (items.length === 1) {
            return messageTexts.singular.replace("{0}", items[0]);
        } else if (items.length === 2) {
            const twoItemsText = items[0] + " " + separator + items[1];
            return messageTexts.plural.replace("{0}", twoItemsText);
        } else {
            const allExceptFinalItem = items.slice(0, items.length - 1);
            const allExceptFinalItemJoined = allExceptFinalItem.join(', ');
            const allWithFinalName = allExceptFinalItemJoined + ", " + separator + items[items.length - 1];
            return messageTexts.plural.replace("{0}", allWithFinalName);
        }
    };

    return {
        generateMultiItemMessage: _generateMultiItemMessage
    };
});

