define('presentation/settings/accountProfile/presentationObjects/billingCommunication',[],function() {
    return function(){
        const self = this;

        self.arrayId = null;
        self.type = ko.observable("");
        self.avatar = ko.observable("");
        self.key = ko.observable("").extend({observeState: true});
        self.status = ko.observable("");
        self.value = ko.observable("").extend({observeState: true});
        self.isReadOnly = ko.pureComputed(() => self.key() === "systemGroup-billingAdmins");
        self.isEnabled = ko.pureComputed(() => {
            const status = self.status();
            return status !== "expired" && status !== "disabled";
        });
    };
});

