define('presentation/settings/phoneNumbers/validators/numberToPortValidator',['businessServices/authentication/sessionAccountInfo',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'presentation/common/validation/validPhoneNumberSpecification',
        'common/converters/phoneNumberFormatConverter',
        'common/converters/phoneNumberFormatter',
        'constants/countryEnumerations'],
    function() {
        return function() {
            let self = this;

            let _commonValidator = null;
            let _facade = null;
            let _viewModel = null;

            const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
            const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

            const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatConverter');
            const _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
            const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

            const _countryEnumerations = require('constants/countryEnumerations');

            const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');
            let accountId = _sessionAccountInfo.accountId();

            self.registerViewModel = function(viewModel, facade) {

                let validationRules = new ValidationRulesConstructor();
                validationRules.field("numberToPort")
                    .addValidationKeyUp(_validatePhoneNumberRequired)
                    .addValidationI18n(_validatePhoneNumberRequired, 'numberToPort:phoneNumberRequired')
                    .addValidationKeyUp(_validatePhoneNumberLength)
                    .addValidationI18n(_validatePhoneNumberLength, 'numberToPort:phoneNumberInvalid')
                    .addValidationI18n(_isPhoneNumberUnique, 'numberToPort:duplicatePhoneNumber')
                    .addValidationI18n(_isPhoneNumberNotOnAnotherAccount, 'numberToPort:phoneNumberOnAnotherAccount')
                    .addValidationI18n(_isAllowedPhoneNumber, 'numberToPort:restrictedPhoneNumber')
                    .addValidationI18n(_checkNumberPortability, 'numberToPort:phoneNumberNotPortable');
                validationRules.field("currentCarrier")
                    .addValidationKeyUp(_validateCurrentCarrierRequired)
                    .addValidationI18n(_validateCurrentCarrierRequired, 'numberToPort:carrierRequired');

                _viewModel = viewModel;
                _facade = facade;
                _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
            };

            self.validate = function() {
                return _commonValidator.validate();
            };

            const _validateCurrentCarrierRequired = (fieldValue) => {
                return _commonValidator.isStringWithValue(fieldValue);
            };

            const _validatePhoneNumberRequired = (phoneNumber) => {
                return _commonValidator.isStringWithValue(phoneNumber);
            };

            const _validatePhoneNumberLength = (phoneNumber) => {
                if (phoneNumber) {
                    let cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                    return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
                }
                return true;
            };

            const _isPhoneNumberUnique = (phoneNumber) => _viewModel.portingPhoneNumbers().every(
                (number, index) => number !== phoneNumber || index === _viewModel.index
            );

            const _isPhoneNumberNotOnAnotherAccount = (phoneNumber) => {
                const cleanNumber = _phoneNumberFormatter.toNumbers(phoneNumber);
                return _validPhoneNumberSpecification.isPhoneNumberOnAnotherAccount(cleanNumber, accountId);
            };

            const _isAllowedPhoneNumber = (phoneNumber) => {
                if (phoneNumber) {
                    return _validPhoneNumberSpecification.isAllowedPhoneNumber(_phoneNumberFormatConverter.getRaw(phoneNumber));
                }
                return true;
            };

            const _checkNumberPortability = (phoneNumber) => {
                const rawPhoneNumber = _phoneNumberFormatConverter.getRaw(phoneNumber);
                if (!rawPhoneNumber.startsWith('0') && !rawPhoneNumber.startsWith('1')) {
                    return _facade.checkNumberPortability(rawPhoneNumber);
                }
                return false;
            };
        };
    });

