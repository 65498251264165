define('presentation/settings/accountProfile/facades/accountProfileFacade',['common/converters/accountNumberFormatter',
        'common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp',
        'presentation/common/userAvatar/userAvatarImageFactory',
        'presentation/settings/accountProfile/presentationObjects/billingCommunication'
], function() {

    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    const AccountNumberFormatterConstructor = require('common/converters/accountNumberFormatter');
    const _accountNumberFormatter = new AccountNumberFormatterConstructor();

    const BillingCommunicationPresentationConstructor = require('presentation/settings/accountProfile/presentationObjects/billingCommunication');

    let _userAvatarImageFactory = null;
    let _webSocketApp = null;

    const _cleanAccountProfileDataResults = (accountProfileDataResults) => {
        for (let accountProfileProperties in accountProfileDataResults) {
            if (accountProfileDataResults[accountProfileProperties] === null || accountProfileDataResults[accountProfileProperties] === undefined) {
                accountProfileDataResults[accountProfileProperties] = "";
            }
        }
    };

    const _saveAccountProfile = (accountProfile) => {
        return _promiseFactory.defer((promise) => {
            const parameters = {
                howWillYouUseTresta: accountProfile.howWillYouUseTresta,
                accountHolderFirstName:  accountProfile.accountHolderFirstName,
                accountHolderLastName: accountProfile.accountHolderLastName,
                businessName: accountProfile.businessName,
                companySize: accountProfile.companySize,
                billingCommunications: accountProfile.billingCommunications,
            };

            _webSocketApp.send("set_account_profile", parameters, (result) => {
                promise.resolve(result);
            });
        });
    };

    const _applyAvatarsToBillingSubscribers = (profileBillingCommunications) => {
        return _promiseFactory.defer((outerPromise) => {
            const avatarUrlsPromiseFactory = new PromiseFactoryConstructor();
            const newProfileBillingCommunications = profileBillingCommunications.map((profileBillingCommunication) => {
                if (profileBillingCommunication.type !== "user") {
                    return profileBillingCommunication;
                }
                const newProfileBillingCommunication = profileBillingCommunication;
                avatarUrlsPromiseFactory.defer((innerPromise) => {
                    _userAvatarImageFactory.getAvatarByUserId(profileBillingCommunication.userId)
                        .fail(innerPromise.reject)
                        .done((thumbnails) => {
                            newProfileBillingCommunication.avatar = thumbnails;
                            innerPromise.resolve();
                        });
                });
                return newProfileBillingCommunication;
            });
            avatarUrlsPromiseFactory.wait()
                .fail(outerPromise.reject)
                .done(() => {
                    outerPromise.resolve(newProfileBillingCommunications);
                });
        });
    };

    const _getAccountProfile = () => {
        return _promiseFactory.defer((deferredObject) => {
            const socketParams = {};
            _webSocketApp.send("get_account_profile", socketParams, (result) => {
                let accountProfile = result;
                accountProfile.accountNumber = _accountNumberFormatter.formatAccountNumber(accountProfile.accountNumber);
                _cleanAccountProfileDataResults(accountProfile);
                _applyAvatarsToBillingSubscribers(accountProfile.billingCommunications)
                    .fail(deferredObject.reject)
                    .done((profileBillingCommunications) => {
                        accountProfile.billingCommunications = profileBillingCommunications;
                        deferredObject.resolve(accountProfile);
                    });
            });
        });
    };

    const _hasNumberPortsInProgress = () => {
        return _promiseFactory.deferIndefinitely((deferredObject) => {
            _webSocketApp.send("has_number_ports_in_progress", {}, (result) => {
                if (result) {
                    deferredObject.resolve(result.hasNumberPortsInProgress);
                } else {
                    deferredObject.resolve(false);
                }
            });
        });
    };

    const _getAllUsersAndUserGroups = () => {
        return _promiseFactory.defer((getAllPromise) => {
            const avatarUrlsPromiseFactory = new PromiseFactoryConstructor();
            const socketParams = {};
            _webSocketApp.send("get_all_users_and_groups", socketParams, (results) => {
                const usersAndUserGroups = results.map((result) => {
                    const billingCommunicationPresentation = new BillingCommunicationPresentationConstructor();
                    billingCommunicationPresentation.value(result.value);
                    billingCommunicationPresentation.key(result.key);
                    billingCommunicationPresentation.type(result.type);

                    if (result.type === "user") {
                        billingCommunicationPresentation.status(result.status);
                        avatarUrlsPromiseFactory.defer((avatarUrlsPromise) => {
                            _userAvatarImageFactory.getAvatarByUserId(result.key)
                                .fail(avatarUrlsPromise.reject)
                                .done((thumbnails) => {
                                    billingCommunicationPresentation.avatar(thumbnails);
                                    avatarUrlsPromise.resolve();
                                });
                        });
                    }
                    return billingCommunicationPresentation;
                });
                avatarUrlsPromiseFactory.wait()
                    .fail(getAllPromise.reject)
                    .done(() => {
                        getAllPromise.resolve(usersAndUserGroups);
                    });
            });
        });
    };

    const _init = () => {
        const UserAvatarImageFactoryConstructor = require('presentation/common/userAvatar/userAvatarImageFactory');
        _userAvatarImageFactory = new UserAvatarImageFactoryConstructor();
        _userAvatarImageFactory.init();

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("account_profile");
    };

    return function() {
        const self = this;

        self.init = _init;
        self.applyAvatarsToBillingSubscribers = _applyAvatarsToBillingSubscribers;
        self.hasNumberPortsInProgress = _hasNumberPortsInProgress;
        self.getAccountProfile = _getAccountProfile;
        self.getAllUsersAndUserGroups = _getAllUsersAndUserGroups;
        self.saveAccountProfile = _saveAccountProfile;
    };
});
