define('presentation/calls/viewModels/callsViewModel',[
    'common/promises/promiseFactory',
], function () {
    return function () {
        const self = this;
        let _promiseFactory = null;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        
        const _rowOne = [
            {
                number: "1",
                subText: "",
            },
            {
                number: "2",
                subText: "ABC",
            },
            {
                number: "3",
                subText: "DEF",
            }
        ];
        
        const _rowTwo = [
            {
                number: "4",
                subText: "GHI",
            },
            {
                number: "5",
                subText: "JKL",
            },
            {
                number: "6",
                subText: "MNO",
            }
        ];
        
        const _rowThree = [
            {
                number: "7",
                subText: "PQRS",
            },
            {
                number: "8",
                subText: "TUV",
            },
            {
                number: "9",
                subText: "WXYZ",
            }
        ];
        
        const _rowFour = [
            {
                number: "*",
                subText: "",
            },
            {
                number: "0",
                subText: "",
            },
            {
                number: "#",
                subText: "",
            }
        ];
        
        self.dialPadRows = [
            {
                keys: _rowOne
            },
            {
                keys: _rowTwo
            },
            {
                keys: _rowThree
            },
            {
                keys: _rowFour
            }
        ];

        self.activate = () => {
            _promiseFactory = new PromiseFactoryConstructor();

            return _initialize();
        };

        const _initialize = () => {


        };
    };
});
