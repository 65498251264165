define('presentation/settings/forwardingNumbers/presentationObjects/forwardingNumbersPresentationObject',[],function() {
    return function(){
        const  self = this;

        self.forwardingNumberId = null;
        self.forwardingNumberName = null;
        self.displayPhoneNumber = null;
        self.phoneNumber = null;
		self.countryAbbreviation = null;
		self.countryCode = null;
		self.countryName = null;
        self.modifiedDateTime = null;
        self.createdDateTime = null;
        self.editForwardingNumberUrl = null;
        self.isVoicemailEnabled = null;
        self.answerTimeout = null;
        self.extension = null;
    };
});

