define('presentation/common/subscriberSelection/validators/subscriberSelectionValidator',['presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'constants/validationMessageEnumerations',
    'common/specifications/validEmailAddressSpecification'], function() {
    return function() {
        var self = this;

        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        var _viewModel;
        var _commonValidator;


        self.registerViewModel = function(ViewModel) {
            var newUserValidationMessageBase = _validationMessageEnumerations.clientui.presentation.settings.userGroups.addUserGroup;
            var validationRules = new ValidationRulesConstructor();

            validationRules.field("displayValues")
                .addValidation(_isAtLeastOneSelectionRequired, newUserValidationMessageBase.membersRequired);
            _commonValidator = new CommonValidatorConstructor(ViewModel, validationRules);
            _viewModel = ViewModel;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _isAtLeastOneSelectionRequired = function(displayValues) {
            if(_viewModel.isAtLeastOneItemRequired && displayValues < 1) {
                return false;
            } else {
                return true;
            }
        };
    };
});

