define('presentation/settings/users/viewModels/addUserModalViewModel',['common/converters/extensionsFormatter',
        'common/promises/promiseFactory',
        'common/url/urlFormatter',
        'presentation/settings/users/facades/addUsersModalFacade',
        'presentation/settings/users/presentationObjects/addUsersPresentationObject',
        'presentation/settings/users/validators/addUsersModalViewModelValidator',
], function() {
    return function () {
        const self = this;

        const AddUsersPresentationObjectConstructor = require('presentation/settings/users/presentationObjects/addUsersPresentationObject');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _i18n = require('i18next');
        const _urlFormatter = require('common/url/urlFormatter');

        let _facade = null;
        let _validator = null;

        const _onUserInviteChange = () => {
            self.showDeleteControls(self.userInvites().length > 1);
        };

        const _trimEmptyInvites = () => {
            return self.userInvites().filter((invite) => {
                return invite.emailAddress().trim().length > 0;
            });
        };

        const _extensionsFormatter = require('common/converters/extensionsFormatter');
        const _getExtensions = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getExtensions()
                    .fail(deferredObject.reject)
                    .done((takenExtensions) => {
                        const extension = _extensionsFormatter.getNextAvailableExtension(1000, takenExtensions, self.unavailableExtensions());
                        self.extension(extension);

                        const unavailableExtensions = {};
                        unavailableExtensions[extension] = extension;
                        self.unavailableExtensions(unavailableExtensions);

                        const addUserPresentationObject = new AddUsersPresentationObjectConstructor();
                        addUserPresentationObject.extension(extension);
                        self.userInvites.push(addUserPresentationObject);
                        deferredObject.resolve();
                    });
            });
        };

        self.pricingUrl = "";
        self.extension = ko.observable(0);
        self.unavailableExtensions = ko.observable([]);
        self.userInvites = ko.observableArray([]).extend({observeState: true});
        self.userInvites.subscribe(_onUserInviteChange);
        self.showDeleteControls = ko.observable(false);
        self.maxUserInvitesMessage = _i18n.t('addUserModal:maxUserInvites');
        self.canAddAnotherUser = ko.computed(() => self.userInvites().length < 10);
        self.isCompositionComplete = ko.observable(false);

        self.disabledClick = () => _validator.validate();

        self.submitAction = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if (isValid === false) {
                            deferredObject.resolve(false);
                            return;
                        }
                        const userInvites = _trimEmptyInvites();
                        if (self.userInvites().length === 0) {
                            deferredObject.resolve(false);
                            return;
                        }

                        _facade.sendUserInvites(userInvites)
                            .fail(deferredObject.reject)
                            .done(() => {
                                deferredObject.resolve(true);
                            });
                    });
            });
        };

        self.extensionApplied = (selectedExtension, previousExtension) => {
            const unavailableExtensions = self.unavailableExtensions();
            unavailableExtensions[selectedExtension.extension] = selectedExtension;
            delete unavailableExtensions[previousExtension];
            self.unavailableExtensions(unavailableExtensions);
        };


        self.addAnotherUser = () => {
            return _promiseFactory.defer((deferredObject) => {
                const unavailableExtensions = self.unavailableExtensions();
                _facade.getExtensions()
                    .fail(deferredObject.reject)
                    .done((takenExtensions) => {
                        const extension = _extensionsFormatter.getNextAvailableExtension(1000, takenExtensions, self.unavailableExtensions());
                        unavailableExtensions[extension] = extension;
                        self.unavailableExtensions(unavailableExtensions);

                        const newUser = new AddUsersPresentationObjectConstructor();
                        newUser.extension(extension);
                        self.userInvites.push(newUser);
                    });
            });
        };

        self.removeInvite = (addUserPresentationObject) => {
            const unavailableExtensions = self.unavailableExtensions();
            delete unavailableExtensions[addUserPresentationObject.extension()];
            self.unavailableExtensions(unavailableExtensions);
            self.userInvites.remove(addUserPresentationObject);
        };

        self.compositionComplete = () => {
            self.isCompositionComplete(true);
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/users/facades/addUsersModalFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);
            const ValidatorConstructor = require('presentation/settings/users/validators/addUsersModalViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            self.pricingUrl = _urlFormatter.buildFrontendUrl(["pricing"]);

            _getExtensions()
                .done(() => {
                    _validator.registerViewModel(self, _facade);
                });

            return _promiseFactory.wait();
        };
    };
});

