define('presentation/settings/forwardingNumbers/facades/forwardingNumbersFacade',[
        'businessServices/country/countryCode',
        'common/converters/phoneNumberFormatConverter',
        'common/converters/phoneNumberFormatter',
        'constants/referentialConstraintEnumerations',
        'persistence/webSocket/webSocketApp',
        'presentation/common/dateTimeValue',
        'presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject',
        'presentation/settings/forwardingNumbers/presentationObjects/forwardingNumbersPresentationObject',
        'settings/navigationConfiguration'
    ],
    function() {
        const CountryCodeConstructor = require('businessServices/country/countryCode');
        const _countryCodes = new CountryCodeConstructor();

        const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatConverter');
        const _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        const IntegrityWarningPresentationObjectConstructor = require('presentation/common/warningModal/presentationObjects/integrityWarningPresentationObject');
        const integrityWarningMessagesEnumeration = require('constants/referentialConstraintEnumerations');
        const ForwardingNumbersPresentationObjectConstructor = require('presentation/settings/forwardingNumbers/presentationObjects/forwardingNumbersPresentationObject');

        const _navigationConfiguration = require('settings/navigationConfiguration');

        let _promiseFactory = null;
        let _webSocketApp = null;

        const _findAllForwardingNumbers = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _webSocketApp.send("get_all_forwarding_numbers", {}, (result) => {
                    let forwardingNumbers = result.forwardingNumbers;
                    let forwardingNumberPresentationObjects = forwardingNumbers.map((forwardingNumber) => {
                        let displayForwardingNumber = new ForwardingNumbersPresentationObjectConstructor();
                        displayForwardingNumber.forwardingNumberId = forwardingNumber.forwardingNumberId;
                        displayForwardingNumber.createdDateTime = new DateTimeValueConstructor(forwardingNumber.createdDateTime);
                        displayForwardingNumber.modifiedDateTime = new DateTimeValueConstructor(forwardingNumber.modifiedDateTime);
                        displayForwardingNumber.forwardingNumberName = forwardingNumber.forwardingNumberName;
                        displayForwardingNumber.displayPhoneNumber = _phoneNumberFormatter.toInternationalWithParens(forwardingNumber.phoneNumber);
                        displayForwardingNumber.phoneNumber = _phoneNumberFormatConverter.convert(forwardingNumber.phoneNumber);
                        displayForwardingNumber.countryCode = forwardingNumber.countryCode;
                        displayForwardingNumber.countryAbbreviation = forwardingNumber.countryAbbreviation;
                        displayForwardingNumber.countryName = _countryCodes.getCountryName(forwardingNumber.countryAbbreviation);
                        displayForwardingNumber.editForwardingNumberUrl = _navigationConfiguration.routesById.editForwardingNumber.baseUrl + forwardingNumber.forwardingNumberId;
                        return displayForwardingNumber;
                    });
                    deferredObject.resolve(forwardingNumberPresentationObjects);
                });
            });
        };

        const _deleteForwardingNumber = (forwardingNumberId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("delete_forwarding_number", {forwardingNumberId: forwardingNumberId}, function (forwardingNumber) {
                    if (forwardingNumber.isDeleted) {
                        deferredObject.resolve();
                    } else {
                        let integrityWarnings = [];
                        forwardingNumber.constraintViolations.forEach((referencedObject) => {
                            if (integrityWarningMessagesEnumeration[referencedObject.type]) {
                                const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                                integrityWarning.entityText = integrityWarningMessagesEnumeration[referencedObject.type].entityText;
                                integrityWarning.entityName = referencedObject.name;
                                integrityWarning.icon = integrityWarningMessagesEnumeration[referencedObject.type].icon;
                                integrityWarning.message = integrityWarningMessagesEnumeration[referencedObject.type].message;

                                integrityWarnings.push(integrityWarning);
                            } else {
                                deferredObject.reject(new Error("Unhandled referenced object type."));
                            }
                        });
                        const error = {"constraintViolations" : integrityWarnings};
                        deferredObject.reject(error);
                    }
                });
            });
        };

        const _getConstraints = (forwardingNumberId) => {
            return _promiseFactory.defer((deferredObject) => {
                _webSocketApp.send("get_constraints", {forwardingNumberId: forwardingNumberId}, (constraints) => {
                    let integrityWarnings = [];
                    constraints.forEach((referencedObject) => {
                        if (integrityWarningMessagesEnumeration[referencedObject.type]) {
                            const integrityWarning = new IntegrityWarningPresentationObjectConstructor();
                            integrityWarning.entityText = integrityWarningMessagesEnumeration[referencedObject.type].entityText;
                            integrityWarning.entityName = referencedObject.name;
                            integrityWarning.icon = integrityWarningMessagesEnumeration[referencedObject.type].icon;
                            integrityWarning.message = integrityWarningMessagesEnumeration[referencedObject.type].message;
                            integrityWarnings.push(integrityWarning);
                        } else {
                            deferredObject.reject(new Error("Unhandled referenced object type."));
                        }
                    });
                    deferredObject.resolve(integrityWarnings);
                });
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketActivityAppConstructor();
            _webSocketApp.init("forwarding_numbers_listing");
        };


        return function() {
            const self = this;

            self.init = _init;
            self.deleteForwardingNumber = _deleteForwardingNumber;
            self.findAllForwardingNumbers = _findAllForwardingNumbers;
            self.getConstraints = _getConstraints;
        };
    });

