define('presentation/signup/viewModels/signupForCustomerViewModel',[
        'businessServices/signup/signupSessionStore',
        'common/promises/promiseFactory',
        'common/storage/workSessionIdStorage'
    ],
    function() {
        return function() {
            const self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            const SignupSessionStoreConstructor = require('businessServices/signup/signupSessionStore');
            let _signupSessionStore = null;

            const _workSessionIdStorage = require('common/storage/workSessionIdStorage');

            let _disposables = [];

            self.affiliate = ko.observable('');
            self.patLiveAccountNumber = ko.observable('');
            self.isAffiliateVisible = ko.pureComputed(() => self.affiliate() !== '');
            self.isPatLiveVisible = ko.pureComputed(() => self.patLiveAccountNumber() !== '');
            self.isSignupForCustomer = ko.observable(false);
            self.signupForCustomerClass = ko.observable('');

            self.detached = function() {
                _disposables.forEach(subscription => subscription.dispose());
                _disposables = [];
            };

            self.activate = function(data) {
                _signupSessionStore = new SignupSessionStoreConstructor();
                _signupSessionStore.init();
                self.signupForCustomerClass(data.signupForCustomerClass);
                return _initialize();
            };

            const _setAffiliate = (signupDocument) => {
                _promiseFactory.defer(promise => {
                    _signupSessionStore.getAffiliates()
                        .fail(promise.reject)
                        .done((result) => {
                            const affiliate = result.affiliates.find((affiliate) => {
                                return affiliate.affiliateCode === signupDocument.affiliateId;
                            });
                            if (affiliate) {
                                self.affiliate(affiliate.affiliateName);
                            }
                            promise.resolve();
                        });
                });
            };

            const _initialize = () => {
                const workSessionId = _workSessionIdStorage.getWorkSessionId();
                self.isSignupForCustomer(workSessionId !== null && workSessionId !== undefined);
                _promiseFactory.defer(promise => {
                    _signupSessionStore.get()
                        .fail(promise.reject)
                        .done(signupDocument => {
                            _setAffiliate(signupDocument);
                            if (signupDocument.patLiveAccountNumber) {
                                self.patLiveAccountNumber(signupDocument.patLiveAccountNumber);
                            }
                            promise.resolve();
                        });
                });
                _promiseFactory.wait();
            };
        };
    });
