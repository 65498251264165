define('presentation/settings/humanDetection/viewModels/humanDetectionViewModel',[
    'settings/navigationConfiguration',
    'common/promises/promiseFactory',
    'common/collections/collectionSorter',
    'presentation/settings/humanDetection/facades/humanDetectionFacade',
    'presentation/settings/humanDetection/presentationObjects/humanDetectionAllowListedPhoneNumberPresentationObject'
], function (
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactoryConstructor
) {
    return function () {
        /** @typedef {import('presentation/settings/humanDetection/viewModels/humanDetectionViewModel')} HumanDetectionViewModel } */
        /** @typedef {import('presentation/settings/humanDetection/facades/humanDetectionFacade')} HumanDetectionFacade } */
        /** @typedef {import('presentation/settings/humanDetection/presentationObjects/humanDetectionAllowListedPhoneNumberPresentationObject')} HumanDetectionAllowListedPhoneNumberPresentationObject } */

        /** @typedef {HumanDetectionViewModel}*/
        let self = this;

        /** @type {(typeof PromiseFactoryConstructor)["prototype"]} */
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();
        const AllowListedPhoneNumberPresentationObject = require('presentation/settings/humanDetection/presentationObjects/humanDetectionAllowListedPhoneNumberPresentationObject');
        const _i18n = require('i18next');

        /** @type {HumanDetectionFacade}*/
        let _facade = null;

        /** @type {IDisposable[]} */
        let _disposables = [];

        //#region Private
        const _onPhoneNumberSearchChanged = () => {
            const searchValue = self.searchText();
            const allowListedNumbers = self.allowListedNumbers();
            if (searchValue !== '' || searchValue !== null) {
                const matchingNumbers = allowListedNumbers.filter(n => n.phoneNumber.includes(searchValue));
                self.displayPhoneNumbers(matchingNumbers);
            } else {
                self.displayPhoneNumbers(allowListedNumbers);
            }
        };

        const _updateAndSortDisplayPhoneNumbers = () => {
            const allowListedNumbers = self.allowListedNumbers();
            self.displayPhoneNumbers(allowListedNumbers);
            _sorter.multiSort(self.displayPhoneNumbers, ["phoneNumber"], [true]);
        };

        const _formatAllowListedNumbers = (/** @type {IHumanDetectionAllowListedPhoneNumberResponse[]}*/allowListedPhoneNumbers) => {
            if (!allowListedPhoneNumbers || !allowListedPhoneNumbers.length) {
                return;
            }

            /** @type {HumanDetectionAllowListedPhoneNumberPresentationObject[]}*/
            const updatedAllowListedNumbers = [];

            allowListedPhoneNumbers.forEach((allowListedPhoneNumber) => {
                const allowListedNumber = new AllowListedPhoneNumberPresentationObject();
                allowListedNumber.humanDetectionAllowListedPhoneNumberId = allowListedPhoneNumber.humanDetectionAllowListedPhoneNumberId;
                allowListedNumber.phoneNumber = allowListedPhoneNumber.phoneNumber;
                allowListedNumber.city = allowListedPhoneNumber.city ? allowListedPhoneNumber.city : null;
                allowListedNumber.state = allowListedPhoneNumber.state ? allowListedPhoneNumber.state : null;
                updatedAllowListedNumbers.push(allowListedNumber);
            });

            self.allowListedNumbers(updatedAllowListedNumbers);
        };
        //#endregion

        self.phoneNumbersPageUrl = _navigationConfiguration.routesById.phoneNumbers.url;
        self.newHumanDetectionPhoneNumberUrl = _navigationConfiguration.routesById.newHumanDetectionPhoneNumber.url;
        self.searchPlaceholder = _i18n.t('humanDetection:search');
        self.searchText = ko.observable("");
        self.allowListedNumbers = ko.observableArray([]).extend({observeState: true});
        self.displayPhoneNumbers = ko.observableArray([]).extend({observeState: true});
        self.hasAllowListedNumbers = ko.pureComputed(() => self.allowListedNumbers().length);

        self.deleteNumber = (/** @type {HumanDetectionAllowListedPhoneNumberPresentationObject}*/allowListedNumber) => {
            self.allowListedNumbers.remove(allowListedNumber);
            _updateAndSortDisplayPhoneNumbers();

            _facade.deleteAllowListedPhoneNumber(allowListedNumber.humanDetectionAllowListedPhoneNumberId)
                .fail((error) => {
                    throw error;
                });
        };

        self.activate = () => {
            const FacadeConstructor = require('presentation/settings/humanDetection/facades/humanDetectionFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
        };

        const _initialize = () => {
            _facade.getAllowListedPhoneNumbers()
                .fail((error) => {
                    throw error;
                })
                .done((allowListedPhoneNumbers) => {
                    _formatAllowListedNumbers(allowListedPhoneNumbers);
                    _updateAndSortDisplayPhoneNumbers();
                });

            _disposables.push(self.searchText.subscribe(_onPhoneNumberSearchChanged));

            return _promiseFactory.wait();
        };
    };
});

