define('constants/timeZoneEnumerations',[], function() {
    return {
        newfoundland: "newfoundland",
        atlantic: "atlantic",
        eastern: "eastern",
        central: "central",
        arizona: "arizona",
        mountain: "mountain",
        pacific: "pacific",
        alaska : "alaska",
        hawaii_aleutian: "hawaii_aleutian"
    };
});

