define('persistence/webSocket/webSocketApp',['externalDependencies/webSocket'],
function() {
    return function() {
        var self = this;
        var _application = null;
        var _webSocket = null;

        self.send = function(command, params, callback, priority) {
            _webSocket.send(_application, command, params, callback, priority);
        };

        self.init = function(application) {
            _application = application;

            _webSocket = require('externalDependencies/webSocket');
            _webSocket.init();
        };

        self.init2 = function(application, webSocket) {
            _application = application;
            _webSocket = webSocket;
        };

        return self;
    };
});

