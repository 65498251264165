define('presentation/common/routeToModal/common/routeToValidationHelper',[
    'common/promises/promiseFactory',
    'constants/autoAttendantVoiceRouteEnumerations',
], function() {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const _voiceRouteEnumerations = require('constants/autoAttendantVoiceRouteEnumerations');

        const _continueToChildRoutes = (routeToViewModel) => {
            return _promiseFactory.defer((deferredObject) => {
                let childRouteToViewModel = routeToViewModel.routeToTypeSelectedForm().routeToViewModel();
                if (childRouteToViewModel) {
                    _validateRouteToViewModal(childRouteToViewModel)
                        .done(deferredObject.resolve);
                } else {
                    deferredObject.resolve(true);
                }
            });
        };

        const _validateRouteToViewModal = (routeToViewModel) => {
            return _promiseFactory.defer((deferredObject) => {
                if (routeToViewModel.showAnswerTimeoutOptions) {
                    if (routeToViewModel.selectedAnswerTimeoutDropDownMenuOption().value !== 'route_to') {
                        deferredObject.resolve(true);
                    }
                }
                switch (routeToViewModel.routeToSelection()) {
                    case _voiceRouteEnumerations.pleaseSelect:
                        deferredObject.resolve(false);
                        break;
                    case _voiceRouteEnumerations.forwardingNumber:
                    case _voiceRouteEnumerations.user:
                    case _voiceRouteEnumerations.userGroup:
                    case _voiceRouteEnumerations.prompt:
                        if (routeToViewModel.routeToTypeSelectedForm().isValid()) {
                            _continueToChildRoutes(routeToViewModel)
                                .done(deferredObject.resolve);
                        } else {
                            deferredObject.resolve(false);
                        }
                        break;
                    default:
                        if (routeToViewModel.routeToTypeSelectedForm()) {
                            deferredObject.resolve(routeToViewModel.routeToTypeSelectedForm().isValid());
                        }

                        deferredObject.resolve(false);
                }
            });
        };

        self.validateRouteToViewModel = _validateRouteToViewModal;
    };
});
