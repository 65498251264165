define('presentation/login/validators/loginViewModelValidator',['common/specifications/validEmailAddressSpecification',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator'
], function () {
    return function () {
        const self = this;

        const ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        const _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');

        let _commonValidator = null;

        self.registerViewModel = (loginViewModel) => {
            const validationRules = new ValidationRulesConstructor();
            validationRules.field("username")
                .addValidationI18n(_validateIsStringWithValue, 'login:emailAddressRequired')
                .addValidationI18n(_validateEmailAddressFormatting, 'login:emailAddressInvalid');

            validationRules.field("password")
                .addValidationI18n(_validateIsStringWithValue, 'login:passwordRequired');

            _commonValidator = new CommonValidatorConstructor(loginViewModel, validationRules);
        };

        self.validate = () => {
            return _commonValidator.validate();
        };

        const _validateIsStringWithValue = (value) => {
            return _commonValidator.isStringWithValue(value);
        };

        const _validateEmailAddressFormatting = (value) => {
            return _validEmailAddressSpecification.isSatisfiedBy(value);
        };
    };
});

