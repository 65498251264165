define('presentation/common/callFlow/presentationObjects/subMenuItemPresentationObject',[],function() {
    return function() {
        let self = this;
        self.name = null;
        self.key = ko.observable(null);
        self.routeSearchData = ko.observable(null);
        self.routeType = ko.observable("");
        self.voicePrompt = ko.observable(null);
        self.callFlow = [];
        self.showControls = ko.observable(true);
        self.showSubMenuCallFlow = ko.observable(true);
        self.showSubMenuCallFlowDetails = ko.observable(true);
        self.deactivatedMessage = ko.observable("");
        self.showDeactivated = ko.observable(false);
        self.showScheduleDetails = ko.observable(true);
        self.endWithHangup = ko.observable(false);
        self.endWithNoTimeout = ko.observable(false);
        self.keyDisplay = ko.pureComputed(() => self.key === '*' ? "＊" : self.key);
    };
});

