define('common/files/fileUploader',[],function() {
    /** @typedef { import('common/files/fileUploader') } FileUploader */

    return function(/** @type {string} */id) {
        /** @type {FileUploader} */
        const _self = this;
        let _fileSizeLimit = Number.MAX_SAFE_INTEGER;
        let _fileLimit = Number.MAX_SAFE_INTEGER;
        let _allowVideo = true;
        let _allowAudio = true;
        let _allowImages = true;
        /** @type {(file: File) => void} */
        let _onFileSelected = null;
        /** @type {() => void} */
        let _onTooManyFiles = null;
        /** @type {(file: File) => void} */
        let _onFileTooLarge = null;
        /** @type {(file: File) => void} */
        let _onFileTypeNotAllowed = null;

        const _handleFiles = function () {
            const input = /** @type {HTMLInputElement} */ (this);
            _handleFilesBase(input.files);
            input.value = null;
        };

        const _handleFilesBase = (/** @type {FileList} */files) => {
            if (files.length > _fileLimit && _onTooManyFiles) {
                _onTooManyFiles();
            }

            const maxUploads = Math.min(_fileLimit, files.length);
            for (let i = 0; i < maxUploads; i++) {
                const file = files[i];

                if (file.size > _fileSizeLimit) {
                    if (_onFileTooLarge) {
                        _onFileTooLarge(file);
                    }
                    continue;
                }

                if (file.type) {
                    const generalType = file.type.split('/')[0].toLowerCase();
                    let allowed = true;
                    switch (generalType) {
                        case `video`:
                            allowed = _allowVideo;
                            break;
                        case `audio`:
                            allowed = _allowAudio;
                            break;
                        case `image`:
                            allowed = _allowImages;
                            break;
                        default:
                            allowed = false;
                            break;
                    }

                    if (!allowed) {
                        if (_onFileTypeNotAllowed) {
                            _onFileTypeNotAllowed(file);
                        }
                        continue;
                    }
                }

                if (_onFileSelected) {
                    _onFileSelected(file);
                }
            }
        };

        /** @type {FileUploader['getElement']} */
        _self.getElement = () => {
            const input = /** @type {HTMLInputElement} */ (document.getElementById(id));
            return input;
        };

        /** @type {FileUploader['showUploader']} */
        _self.showUploader = () => {
            const input = _self.getElement();
            input.click();
        };

        /** @type {FileUploader['setFileSizeLimit']} */
        _self.setFileSizeLimit = (limitInBytes) => {
            _fileSizeLimit = limitInBytes;
            return _self;
        };

        /** @type {FileUploader['setFileLimit']} */
        _self.setFileLimit = (limit) => {
            _fileLimit = limit;
            return _self;
        };

        /** @type {FileUploader['handleFiles']} */
        _self.handleFiles = _handleFilesBase;

        /** @type {FileUploader['startListening']} */
        _self.startListening = () => {
            const input = _self.getElement();
            input.addEventListener('change', _handleFiles, false);
        };

        /** @type {FileUploader['stopListening']} */
        _self.stopListening = () => {
            const input = _self.getElement();
            input.removeEventListener('change', _handleFiles);
        };

        /** @type {FileUploader['onFileSelected']} */
        _self.onFileSelected = (onFileSelected) => {
            _onFileSelected = onFileSelected;
            return _self;
        };

        /** @type {FileUploader['onTooManyFiles']} */
        _self.onTooManyFiles = ( onTooManyFiles) => {
            _onTooManyFiles = onTooManyFiles;
            return _self;
        };

        /** @type {FileUploader['onFileTooLarge']} */
        _self.onFileTooLarge = (onFileTooLarge) => {
            _onFileTooLarge = onFileTooLarge;
            return _self;
        };

        /** @type {FileUploader['onFileTypeNotAllowed']} */
        _self.onFileTypeNotAllowed = (onFileTypeNotAllowed) => {
            _onFileTypeNotAllowed = onFileTypeNotAllowed;
            return _self;
        };

        /** @type {FileUploader['setAllowAudio']} */
        _self.setAllowAudio = (allow) => {
            _allowAudio = allow;
            return _self;
        };

        /** @type {FileUploader['setAllowImages']} */
        _self.setAllowImages = (allow) => {
            _allowImages = allow;
            return _self;
        };

        /** @type {FileUploader['setAllowVideo']} */
        _self.setAllowVideo = (allow) => {
            _allowVideo = allow;
            return _self;
        };

        _self.dispose = () => {
            _self.stopListening();
        };
    };
});
