define('presentation/messages/utils/conversationMessageManager',[
    'i18next',
    'common/converters/phoneNumberFormatter',
    'common/converters/userFormatter',
    'common/storage/commonState',
    'common/time/datetimeFormatter',
    'constants/deliveryStatus',
    'presentation/messages/viewModels/conversationMessageViewModel',
    'presentation/messages/viewModels/conversationMessageAttachmentViewModel',
    './messagesHelper',
    'constants/smsConversationMessageAttachmentStatus',
    'constants/messageDirection',
    'presentation/messages/viewModels/messageLinkPreviewCardViewModel'
],
function(
    /** @type typeof import('i18next') */
    i18next,
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatterConstructor,
    /** @type typeof import('common/converters/userFormatter') */
    UserFormatterConstructor,
    /** @type typeof import('common/storage/commonState') */
    _commonState,
    /** @type typeof import('common/time/datetimeFormatter') */
    DatetimeFormatterConstructor,
    /** @type typeof import('constants/deliveryStatus') */
    DeliveryStatus,
    /** @type typeof import('presentation/messages/viewModels/conversationMessageViewModel') */
    ConversationMessageViewModel,
    /** @type typeof import('presentation/messages/viewModels/conversationMessageAttachmentViewModel') */
    ConversationMessageAttachmentViewModel,
    /** @type typeof import('presentation/messages/utils/messagesHelper') */
    _messagesHelper,
    /** @type typeof import ('constants/smsConversationMessageAttachmentStatus') */
    SmsConversationMessageAttachmentStatus,
    /** @type typeof import ('constants/messageDirection') */
    MessageDirection,
    /** @type typeof import('presentation/messages/viewModels/messageLinkPreviewCardViewModel') */
    MessageLinkPreviewViewModel
) {
    /** @typedef {import('constants/deliveryStatus')} DeliveryStatus */
    /** @typedef {import('presentation/messages/viewModels/conversationMessageViewModel')} ConversationMessageViewModel */
    /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */

    return function(
        /** @type {string} */conversationId,
        /** @type {string} */userId,
        /** @type {(messageIdentifier: ConversationMessageViewModel) => PromiseLike<IRetryMessageResponse>} */retryMessageCallback,
        /** @type {(smsConversationMessageId: string) => JQueryDeferred<IDeleteMessageResponse>} */deleteMessageCallback,
        /** @type {KnockoutComputed<boolean>} */isNumberLimited
    ) {
        /** @type {import('presentation/messages/utils/conversationMessageManager')} */
        const self = this;

        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();
        const _userFormatter = new UserFormatterConstructor();
        const _datetimeFormatter = new DatetimeFormatterConstructor();

        const FIVE_SECONDS = 5 * 1000;

        /** @type {Map<string, ConversationMessageViewModel>} */
        const _messageIdToMessage = new Map();
        /** @type {Map<string, ConversationMessageAttachmentViewModel>} */
        const _attachmentIdToAttachment = new Map();
        /** @type {Map<string, ILocationDataWithPhoneNumber>} */
        const _phoneNumberToRecipient = new Map();
        /** @type {Map<string, IWebMessagingNumber>} */
        const _accountHostedNumberIdToWebMessagingNumber = new Map();
        /** @type {Map<string, ConversationMessageAttachmentViewModel>} */
        const _attachmentIdToOutboundAttachmentPendingConversion = new Map();
        /** @type {KnockoutObservableArray<ConversationMessageViewModel>} */
        const _messages = ko.observableArray([]);
        /** @type {KnockoutObservableArray<ConversationMessageViewModel>} */
        const _messagesWaitingForAttachments = ko.observableArray([]);
        /** @type {KnockoutObservableArray<ConversationMessageViewModel>} */
        const _someoneIsTypingMessages = ko.observableArray([]);
        /** @type {Map<string, ConversationMessageAttachmentViewModel>} */
        const _attachmentIdToInboundAttachmentPendingConversion = new Map();
        /** @type {Map<string, number>} */
        const _typingTimeoutByUserId = new Map();
        /** @type {KnockoutObservable<string | null>} */
        const _oldestNewMessageId = ko.observable(null);
        /** @type {KnockoutObservable<string | null>} */
        const _owningHostedNumberId = ko.observable(null);

        //#region private methods
        const _clearMessages = () => {
            _oldestNewMessageId(null);
            _messageIdToMessage.clear();
            _attachmentIdToOutboundAttachmentPendingConversion.clear();
            _attachmentIdToInboundAttachmentPendingConversion.clear();
            _messagesWaitingForAttachments([]);
            _messages([]);
        };

        /** @param {ConversationMessageViewModel[]} smsMessages */
        const _upsertMessagesToMap = (smsMessages) => {
            smsMessages.forEach((smsMessage) => {
                _messageIdToMessage.set(smsMessage.smsConversationMessageId, smsMessage);

                smsMessage.attachments().forEach((attachment) => {
                    _attachmentIdToAttachment.set(attachment.attachmentId(), attachment);
                });
            });
        };

        /** @param {ConversationMessageViewModel[]} smsMessages */
        const _deleteMessagesFromMap = (smsMessages) => {
            smsMessages.forEach((smsMessage) => {
                _messageIdToMessage.delete(smsMessage.smsConversationMessageId);
            });
        };

        const _deleteMessage = (/** @type {string} */smsConversationMessageId) => {
            const avatarSizeAdjustLength = 500;
            const fadeOutAnimationLength = 1000 * 2.5;
            const messageToDelete = _messageIdToMessage.get(smsConversationMessageId);
            const index = _messages().indexOf(messageToDelete);
            const isOldestMessageInConversation = index === _messages().length - 1;

            if (messageToDelete !== undefined) {
                messageToDelete.isVisible(false);
            }

            if (index === -1) {
                return;
            }

            // Avatar makes the message a bit taller, triggering the height adjust animation
            // which takes 5 seconds. Try to time the animations
            setTimeout(() => {
                let newerMessage = null;
                let olderMessage = null;

                messageToDelete.showDateDivider(false);

                for (const message of _messages()) {
                    if (message.smsConversationMessageId === smsConversationMessageId) {
                        continue;
                    }

                    if (!newerMessage) {
                        newerMessage = message;
                        continue;
                    }

                    if (!olderMessage) {
                        olderMessage = message;
                    }
                    else {
                        newerMessage = olderMessage;
                        olderMessage = message;
                    }

                    newerMessage.showMessageMetadata(_shouldShowMessageMetadata(newerMessage, olderMessage));
                    newerMessage.showDateDivider(_shouldShowDateDivider(newerMessage, olderMessage));
                }

                if (isOldestMessageInConversation && olderMessage) {
                    olderMessage.showMessageMetadata(true);
                    olderMessage.showDateDivider(true);
                }
            }, fadeOutAnimationLength - avatarSizeAdjustLength);

            setTimeout(() => {
                const messagesToRemove = _messages.remove((message) => message.smsConversationMessageId === smsConversationMessageId);
                if (messagesToRemove.length) {
                    _deleteMessagesFromMap(messagesToRemove);
                }
            }, fadeOutAnimationLength);
        };

        /**
         * @param {ConversationMessageViewModel | ISmsConversationMessage} message
         * @param {ConversationMessageViewModel | ISmsConversationMessage} previousMessage
         * */
        const _shouldShowMessageMetadata = (message, previousMessage) => {
            const _thirtyMinutes = 1000 * 60 * 30;

            const isSentByUser = !!message.sendingUserId;
            const messageDate = new Date(message.messageDateTime);
            const previousMessageDate = new Date(previousMessage.messageDateTime);
            const areMessagesThirtyMinutesApart = (messageDate.getTime() - previousMessageDate.getTime()) > _thirtyMinutes;
            const isSendingParticipantTheSame = isSentByUser ?
                message.sendingUserId === previousMessage.sendingUserId :
                message.sendingMemberPhoneNumber === previousMessage.sendingMemberPhoneNumber;
            const hasDateDivider = _shouldShowDateDivider(message, previousMessage);

            return areMessagesThirtyMinutesApart || !isSendingParticipantTheSame || hasDateDivider;
        };

        /**
         * @param {ConversationMessageViewModel | ISmsConversationMessage} message
         * @param {ConversationMessageViewModel | ISmsConversationMessage} previousMessage
         * */
        const _shouldShowDateDivider = (message, previousMessage) => {
            return !_datetimeFormatter.isOnSameDay(new Date(message.messageDateTime),
                new Date(previousMessage.messageDateTime));
        };

        /**
         * @param {ConversationMessageViewModel} message
         * @param {ConversationMessageViewModel} previousMessage
         * */
        const _updateShowMessageMetadata = (message, previousMessage) => {
            if (previousMessage) {
                message.showMessageMetadata(_shouldShowMessageMetadata(message, previousMessage));
            }
        };

        /**
         * @param {ConversationMessageViewModel} message
         * @param {ConversationMessageViewModel} previousMessage
         * */
        const _updateShowDateDivider = (message, previousMessage) => {
            if (previousMessage) {
                message.showDateDivider(_shouldShowDateDivider(message, previousMessage));
            }
        };

        /**
         * @param {ConversationMessageViewModel} message
         * */
        const _tryResetShowDateDivider = (message) => {
            const currentMessages = _messages();
            const messageIndexShowingNewMessageDivider = currentMessages.findIndex((vm) => vm.smsConversationMessageId === message.smsConversationMessageId);
            const previousMessageToCheckAgainst = currentMessages[messageIndexShowingNewMessageDivider + 1];
            if (!previousMessageToCheckAgainst) {
                message.showDateDivider(true);
            } else {
                _updateShowDateDivider(message, previousMessageToCheckAgainst);
            }
        };

        /**
         * @param {ConversationMessageViewModel} message
         * @param {boolean} showDivider
         * */
        const _updateShowNewMessageDivider = (message, showDivider = true) => {
            message.showNewMessageDivider(showDivider);

            if (showDivider) {
                message.showDateDivider(false);
            } else {
                _tryResetShowDateDivider(message);
            }
        };

        /** @param {ISmsConversationMessage[]} smsMessages */
        const _buildDisplayConversationMessages = (smsMessages) => {
            return smsMessages.map((smsMessage, index) => {
                // messages are sorted by date, desc
                const precedingMessage = smsMessages[index + 1];
                const commonStateUser = _commonState.get(smsMessage.sendingUserId);
                const messageViewModel = new ConversationMessageViewModel();
                const isInboundMessage = smsMessage.direction === MessageDirection.incoming;

                let showMessageMetadata = true;
                let showDateDivider = true;

                if (precedingMessage) {
                    showMessageMetadata = _shouldShowMessageMetadata(smsMessage, precedingMessage);
                    showDateDivider = _shouldShowDateDivider(smsMessage, precedingMessage);
                }

                const attachments = smsMessage.smsConversationMessageAttachments.map((attachment) => {
                    const conversionStatus = attachment.conversionStatus;
                    const isConversionComplete = conversionStatus === SmsConversationMessageAttachmentStatus.NoConversion ||
                        conversionStatus === SmsConversationMessageAttachmentStatus.Converted ||
                        conversionStatus === SmsConversationMessageAttachmentStatus.Received;
                    const showLoadingState = isInboundMessage && !isConversionComplete;

                    const attachmentViewModel = new ConversationMessageAttachmentViewModel();
                    attachmentViewModel.activate({
                        attachmentId: attachment.smsConversationMessageAttachmentId,
                        owningMessageId: smsMessage.smsConversationMessageId,
                        conversionStatus: attachment.conversionStatus,
                        sequence: attachment.sequence,
                        createdDateTime: attachment.createdDateTime,
                        remoteObjectUrl: attachment.s3FilePath,
                        remoteObjectContentType: attachment.contentType,
                        showLoadingState: showLoadingState
                    });

                    if (showLoadingState) {
                        _attachmentIdToInboundAttachmentPendingConversion.set(attachment.smsConversationMessageAttachmentId, attachmentViewModel);
                    }

                    return attachmentViewModel;
                });

                attachments.sort((a, b) => {
                    if (a.createdDateTime() === b.createdDateTime()) {
                        if (a.sequence() === b.sequence()) {
                            return 0;
                        }

                        if (a.sequence() > b.sequence()) {
                            return -1;
                        }
                        return 1;
                    }

                    if (a.createdDateTime() > b.createdDateTime()) {
                        return -1;
                    }
                    return 1;
                });

                const urlPreviews = smsMessage.smsConversationMessageUrlPreviews.map((urlPreview) => {
                    return _createLinkPreviewViewModel(urlPreview);
                });

                let messageLocationMeta;
                if (smsMessage.sendingMemberPhoneNumber) {
                    const recipient = self.getRecipient(smsMessage.sendingMemberPhoneNumber);
                    messageLocationMeta = recipient ? recipient.meta : "";
                } else {
                    const hostedNumber = self.getHostedNumber(_owningHostedNumberId());
                    messageLocationMeta = hostedNumber ? _phoneNumberFormatter.formatLocation(hostedNumber.city, hostedNumber.state) : "";
                }

                let sentByName;
                if (smsMessage.isSystemGeneratedMessage) {
                    sentByName = i18next.t('system') ;
                } else if (!!smsMessage.sendingUserId) {
                    sentByName = _userFormatter.formatUserFullName(smsMessage.sendingUserFirstName, smsMessage.sendingUserLastName);
                } else {
                    sentByName = _phoneNumberFormatter.toInternationalWithParens(smsMessage.sendingMemberPhoneNumber);
                }

                messageViewModel.activate({
                    retryMessage: retryMessageCallback,
                    deleteMessage: deleteMessageCallback,
                    content: smsMessage.content,
                    direction: smsMessage.direction,
                    messageDateTime: smsMessage.messageDateTime,
                    showMessageMetadata: showMessageMetadata,
                    sendingUserId: smsMessage.sendingUserId,
                    sendingUserInitials: _userFormatter.formatUserInitials(smsMessage.sendingUserFirstName, smsMessage.sendingUserLastName),
                    sendingUserAvatar: commonStateUser ? commonStateUser.avatar : undefined,
                    sendingMemberPhoneNumber: smsMessage.sendingMemberPhoneNumber,
                    sentByName: sentByName,
                    showDateDivider: showDateDivider,
                    smsConversationMessageId: smsMessage.smsConversationMessageId,
                    deliveryStatus: smsMessage.deliveryStatus,
                    isNumberLimited: isNumberLimited,
                    isSystemGeneratedMessage: smsMessage.isSystemGeneratedMessage,
                    attachments: attachments,
                    linkPreviews: urlPreviews,
                    locationMetaText: messageLocationMeta
                });

                if (self.oldestNewMessageId() === messageViewModel.smsConversationMessageId) {
                    _updateShowNewMessageDivider(messageViewModel);
                }

                return messageViewModel;
            });
        };

        /**
         * @param {ISmsConversationMessage[]} smsMessages
         * @param {boolean} unshift
         * */
        const _addMessages = (smsMessages, unshift = false) => {
            const filteredMessages = smsMessages.filter(newMessage => {
                return _messageIdToMessage.get(newMessage.smsConversationMessageId) === undefined;
            });

            if (filteredMessages.length === 0)
            {
                return;
            }

            _removeSomeOneIsTypingForNewMessages(filteredMessages);

            const formattedMessages = _buildDisplayConversationMessages(filteredMessages);

            let previousMessageToCheckAgainst = null;
            let messageToCheck = null;

            if (unshift) {
                previousMessageToCheckAgainst = self.newestMessage();
                messageToCheck = formattedMessages[formattedMessages.length - 1];
            } else {
                previousMessageToCheckAgainst = formattedMessages[0];
                messageToCheck = self.oldestMessage();
            }

            if (messageToCheck && previousMessageToCheckAgainst) {
                _updateShowMessageMetadata(messageToCheck, previousMessageToCheckAgainst);
                _updateShowDateDivider(messageToCheck, previousMessageToCheckAgainst);
            }

            const currentMessages = _messages();
            let updatedMessages;

            if (unshift) {
                updatedMessages = formattedMessages.concat(currentMessages);
            } else {
                updatedMessages = currentMessages.concat(formattedMessages);
            }

            _messages(updatedMessages);

            _removeAnyMessagesWaitingForAttachments(formattedMessages);

            _upsertMessagesToMap(formattedMessages);
        };

        /**
         * @param {string | ITextOrLinkSegment[]} content
         * @param {ConversationMessageAttachmentViewModel[]} attachments
         * @param {boolean} showImmediately
         * */
        const _addSendingMessage = (content, attachments, showImmediately) => {
            if (attachments.length > 0) {
                return _addSendingMessageWaitingForAttachments(content, attachments, showImmediately);
            }

            const messageViewModel = _createNewSendingMessage(content);

            messageViewModel.isVisible(showImmediately);

            const previousMessageToCheckAgainst = self.newestMessage();

            if (previousMessageToCheckAgainst) {
                _updateShowMessageMetadata(messageViewModel, previousMessageToCheckAgainst);
                _updateShowDateDivider(messageViewModel, previousMessageToCheckAgainst);
            }

            _messages.unshift(messageViewModel);
            _upsertMessagesToMap([messageViewModel]);

            return messageViewModel;
        };

        /**
         * @param {string | ITextOrLinkSegment[]} content
         * @param {ConversationMessageAttachmentViewModel[]} attachments
         * @param {boolean} showImmediately
         * */
        const _addSendingMessageWaitingForAttachments = (content, attachments, showImmediately) => {
            const messageViewModel = _createNewSendingMessage(content, attachments);

            messageViewModel.isVisible(showImmediately);

            const previousMessageToCheckAgainst = self.newestMessageWaitingForAttachments();

            if (previousMessageToCheckAgainst) {
                _updateShowMessageMetadata(messageViewModel, previousMessageToCheckAgainst);
                _updateShowDateDivider(messageViewModel, previousMessageToCheckAgainst);
            }

            _messagesWaitingForAttachments.unshift(messageViewModel);

            attachments.forEach(attachment => {
                _attachmentIdToOutboundAttachmentPendingConversion.set(attachment.attachmentId(), attachment);
            });

            return messageViewModel;
        };

        /**
         * @param {string | ITextOrLinkSegment[]} content
         * @param {ConversationMessageAttachmentViewModel[]} attachments
         * */
        const _createNewSendingMessage = (content, attachments = []) => {
            const messageViewModel = new ConversationMessageViewModel();

            const sendingUserId = userId;
            const commonStateUser = _commonState.get(sendingUserId);
            const sendingUserAvatar = commonStateUser && commonStateUser.avatar;
            const [firstName, lastName] = commonStateUser.name().split(' ');

            const messageDateTime = new Date().toISOString();

            messageViewModel.activate({
                smsConversationMessageId: messageDateTime, // this gets updated w/ the response message ID
                content: content,
                messageDateTime: messageDateTime,
                showMessageMetadata: true,
                sendingUserId: sendingUserId,
                sendingUserInitials: _userFormatter.formatUserInitials(firstName, lastName),
                sendingUserAvatar: sendingUserAvatar,
                sentByName: _userFormatter.formatUserFullName(firstName, lastName),
                deliveryStatus: DeliveryStatus.Sending,
                retryMessage: retryMessageCallback,
                deleteMessage: deleteMessageCallback,
                isNumberLimited: isNumberLimited,
                direction: MessageDirection.outgoing,
                attachments: attachments
            });

            return messageViewModel;
        };

        /** @param {string} previousOldestUnreadMessageId */
        const _beforeOldestUnreadMessageIdChanged = (previousOldestUnreadMessageId) => {
            const message = _messageIdToMessage.get(previousOldestUnreadMessageId);

            if (message) {
                _updateShowNewMessageDivider(message, false);
            }
        };

        /** @param {string} newOldestUnreadMessageId */
        const _afterOldestUnreadMessageIdChanged = (newOldestUnreadMessageId) => {
            const message = _messageIdToMessage.get(newOldestUnreadMessageId);

            if (message) {
                _updateShowNewMessageDivider(message);
            }
        };

        /** @param {IMessageUrlPreviewResponse} urlPreview */
        const _createLinkPreviewViewModel = (urlPreview) => {
            const linkPreviewViewModel = new MessageLinkPreviewViewModel();
            linkPreviewViewModel.activate(urlPreview);
            return linkPreviewViewModel;
        };
        //#endregion

        self.hasMessage = (/** @type {string} */smsConversationMessageId) => {
            return !!_messageIdToMessage.get(smsConversationMessageId);
        };

        self.isConversationEmpty = ko.pureComputed(() => {
            return _messages().length === 0;
        });

        self.oldestNewMessageId = ko.pureComputed(() => {
            return _oldestNewMessageId();
        });

        self.hasNewMessage = ko.pureComputed(() => {
            return _oldestNewMessageId() !== null;
        });

        self.oldestMessage = ko.pureComputed(() => {
            const messages = _messages();
            return messages[messages.length - 1];
        });

        self.newestMessage = ko.pureComputed(() => {
            return _messages()[0];
        });

        self.newestMessageWaitingForAttachments = ko.pureComputed(() => {
            return _messagesWaitingForAttachments()[0];
        });

        self.conversationMessages = ko.pureComputed(() => {
            const typingMessages = _someoneIsTypingMessages();
            const messagesWaitingForAttachments = _messagesWaitingForAttachments();
            const messages = _messages();
            return [].concat(typingMessages).concat(messagesWaitingForAttachments).concat(messages);
        });

        //#region public methods

        //#region clear messages
        self.clearState = _clearMessages;
        //#endregion

        /** @type self["getInboundAttachmentsPendingConversion"] */
        self.getInboundAttachmentsPendingConversion = () => {
            return [..._attachmentIdToInboundAttachmentPendingConversion.keys()];
        };

        /** @type self["getMessageById"] */
        self.getMessageById = (smsConversationMessageId) => {
            return _messageIdToMessage.get(smsConversationMessageId);
        };

        /** @type self["getAttachmentById"] */
        self.getAttachmentById = (attachmentId) => {
            return _attachmentIdToAttachment.get(attachmentId);
        };

        /** @type self["getMediaModalAttachments"] */
        self.getMediaModalAttachments = () => {
            /** @type {IFullScreenMediaAttachment[]}*/
            const mediaAttachments = [];
            _attachmentIdToAttachment.forEach((attachment) => {
                const message = self.getMessageById(attachment.owningMessageId());
                mediaAttachments.push({
                    attachment: attachment,
                    message: message
                });
            });
            mediaAttachments.sort((a,b) => new Date(b.message.messageDateTime) - new Date(a.message.messageDateTime));
            return mediaAttachments;
        };

        /**
         * Set initial messages
         * @param {ISmsConversationMessage[]} smsMessages
         * @param {string} oldestNewMessageId
         */
        self.setInitialMessages = (smsMessages, oldestNewMessageId) => {
            _clearMessages();
            self.setOldestNewMessageId(oldestNewMessageId);
            _addMessages(smsMessages);
        };

        /**
         * Set oldest new message id
         * @param {string} oldestNewMessageId
         */
        self.setOldestNewMessageId = (oldestNewMessageId) => {
            const oldValue = _oldestNewMessageId();

            _beforeOldestUnreadMessageIdChanged(oldValue);
            _oldestNewMessageId(oldestNewMessageId);
            _afterOldestUnreadMessageIdChanged(oldestNewMessageId);
        };

        //#region add messages

        /**
         * @param {ISmsConversationMessage[]} smsMessages
         * @param {string} oldestUnreadMessageId
         */
        self.addNewMessages = (smsMessages, oldestUnreadMessageId) => {
            _addMessages(smsMessages, true);

            if (self.hasNewMessage() === false) {
                self.setOldestNewMessageId(oldestUnreadMessageId);
            }
        };

        /**
         * @param {ISmsConversationMessage[]} smsMessages
         */
        self.addPreviousMessages = (smsMessages) => {
            _addMessages(smsMessages);
        };

        self.addSendingMessage = _addSendingMessage;
        //#endregion

        //#region update message after send
        /**
         * @param {string} oldMessageId
         * @param {string} updatedMessageId
         * @param {DeliveryStatus} deliveryStatus
         */
        self.updateMessageAfterSend = (oldMessageId, updatedMessageId, deliveryStatus) => {
            const message = _messageIdToMessage.get(oldMessageId);

            if (message) {
                message.smsConversationMessageId = updatedMessageId;
                message.updateDeliveryStatus(deliveryStatus);
                _upsertMessagesToMap([message]);
                _messageIdToMessage.delete(oldMessageId);
            }
        };

        /**
         * @param {string} messageId
         * @param {IMessageUrlPreviewResponse} messageUrlPreview
         */
        self.updateMessageLinkPreview = (messageId, messageUrlPreview) => {
            const message = _messageIdToMessage.get(messageId);
            if (!message) {
                return;
            }

            if (!message.hasLinkPreviews()) {
                const newLinkPreview = _createLinkPreviewViewModel(messageUrlPreview);
                message.linkPreviews([newLinkPreview]);
                return;
            }

            const linkPreviewToUpdate = message.linkPreviews().find((lp) => lp.smsConversationMessageUrlId() === messageUrlPreview.smsConversationMessageUrlId);
            if (linkPreviewToUpdate) {
                linkPreviewToUpdate.activate(messageUrlPreview);
                return;
            }

            const newLinkPreview = _createLinkPreviewViewModel(messageUrlPreview);
            const updatedLinks = message.linkPreviews();
            updatedLinks.push(newLinkPreview);
            message.linkPreviews(updatedLinks);
        };
        //#endregion

        self.deleteMessage = _deleteMessage;

        //#region get received messages since message id
        self.getReceivedMessagesCountSinceMessageId = (/** @type {string} */smsConversationMessageId) => {
            const messageToCheck = _messages().find((message) => message.smsConversationMessageId === smsConversationMessageId);

            if (messageToCheck === undefined) {
                return 0;
            }

            const messageToCheckTime = new Date(messageToCheck.messageDateTime).getTime();

            return _messages()
                .reduce((acc, message) => {
                    const messageTime = new Date(message.messageDateTime).getTime();

                    if (
                        (messageTime >= messageToCheckTime) &&
                        (message.sendingUserId !== userId)
                    ) {
                        acc++;
                    }

                    return acc;
                }, 0);
        };
        //#endregion

        //#region set someone is typing

        /**
         * @param {ISmsConversationMessage[]} smsMessages
         */
         const _removeSomeOneIsTypingForNewMessages = (smsMessages) => {
            for (const message of smsMessages) {
                if (!message.sendingUserId) {
                    continue;
                }

                const someoneIsTypingMessage = _someoneIsTypingMessages().find(x => x.sendingUserId === message.sendingUserId);
                if (someoneIsTypingMessage) {
                    _someoneIsTypingMessages.remove(someoneIsTypingMessage);
                }

                const timeOutHandle = _typingTimeoutByUserId.get(message.sendingUserId);

                if (!timeOutHandle) {
                    continue;
                }

                _typingTimeoutByUserId.delete(message.sendingUserId);
                clearTimeout(timeOutHandle);
            }
        };

        self.setSomeoneIsTyping = (/** @type {ISmsUserIsTypingInfo} */{accountUserId, smsConversationId, firstName, lastName}) => {
            if (conversationId !== smsConversationId) {
                return;
            }

            if (userId === accountUserId) {
                return;
            }

            let someoneIsTypingMessage = _someoneIsTypingMessages()
                .find(x => x.isSomeoneTypingNotification() && x.sendingUserId === accountUserId);

            if (someoneIsTypingMessage) {
                const removeAfterDelayTimeout = _typingTimeoutByUserId.get(accountUserId);
                clearTimeout(removeAfterDelayTimeout);
            } else {
                const commonStateUser = _commonState.get(accountUserId);
                someoneIsTypingMessage = new ConversationMessageViewModel();

                someoneIsTypingMessage.activate({
                    isPendingMessage: true,
                    sendingUserId: accountUserId,
                    sendingUserInitials: _userFormatter.formatUserInitials(firstName, lastName),
                    sendingUserAvatar: commonStateUser ? commonStateUser.avatar : undefined,
                    sentByName: _userFormatter.formatUserFullName(firstName, lastName),
                    showMessageMetadata: true
                });

                _someoneIsTypingMessages.push(someoneIsTypingMessage);
            }

            const newRemoveAfterDelayTimeout = setTimeout(() => {
                _someoneIsTypingMessages.remove(someoneIsTypingMessage);
            }, FIVE_SECONDS);

            _typingTimeoutByUserId.set(accountUserId, newRemoveAfterDelayTimeout);
        };
        //#endregion

        //#region on message status changed event
        /** @type self["onSmsConversationMessageStatusChanged"] */
        self.onSmsConversationMessageStatusChanged = (smsConversationMessageId, deliveryStatus) => {
            const messageToUpdate = _messageIdToMessage.get(smsConversationMessageId);

            if (messageToUpdate === undefined) {
                return;
            }

            messageToUpdate.updateDeliveryStatus(deliveryStatus);
        };

        //#endregion

        //#region on attachment status changed event
        /** @type {self["onSmsConversationMessageAttachmentUpdated"]} */
        self.onSmsConversationMessageAttachmentUpdated = ({smsConversationMessageAttachmentId, conversionStatus}) => {
            const attachmentToUpdate = _attachmentIdToOutboundAttachmentPendingConversion.get(smsConversationMessageAttachmentId);

            if (attachmentToUpdate === undefined) {
                return null;
            }

            attachmentToUpdate.updateConversionStatus(conversionStatus);
        };
        //#endregion

        /** @type self["updateSmsConversationMessageAttachment"] */
        self.updateSmsConversationMessageAttachment = ({smsConversationMessageAttachmentId, s3FilePath, conversionStatus, contentType}) => {
            const attachmentToUpdate = _attachmentIdToInboundAttachmentPendingConversion.get(smsConversationMessageAttachmentId);

            if (attachmentToUpdate) {
                attachmentToUpdate.updateAttachment(s3FilePath, contentType, conversionStatus);
                _attachmentIdToInboundAttachmentPendingConversion.delete(smsConversationMessageAttachmentId);
                _attachmentIdToAttachment.set(smsConversationMessageAttachmentId, attachmentToUpdate);
            }
        };

        //#region messagesWaitingForAttachments

        const _removeAnyMessagesWaitingForAttachments = (/** @type {ConversationMessageViewModel[]} */ messagesToCheck) => {
            if (_messagesWaitingForAttachments().length === 0){
                return;
            }

            const newAttachmentIds = _getMessageAttachmentIds(messagesToCheck);

            if (newAttachmentIds.size === 0) {
                return;
            }

            const currentMessagesWaitingForAttachments = _messagesWaitingForAttachments();

            const updatedMessagesWaitingForAttachments = _getUpdatedMessagesWaitingForAttachments(newAttachmentIds, currentMessagesWaitingForAttachments);

            _messagesWaitingForAttachments(updatedMessagesWaitingForAttachments);

            messagesToCheck.forEach(messageToCheck => {
                messageToCheck.attachments().forEach(attachment => {
                    _attachmentIdToOutboundAttachmentPendingConversion.delete(attachment.attachmentId());
                });
            });
        };

        /** @returns { Set<string> } */
        const _getMessageAttachmentIds = (/** @type {ConversationMessageViewModel[]} */ messagesToCheck) => {
            /** @type { Set<string> } */
            const attachmentIds = new Set();

            messagesToCheck.forEach((/** @type {ConversationMessageViewModel} */messageToCheck) => {
                if (messageToCheck.hasAttachments) {
                    messageToCheck.attachments().forEach(/** @type{ConversationMessageAttachmentViewModel} */ attachment => {
                        attachmentIds.add(attachment.attachmentId());
                    });
                }
            });

            return attachmentIds;
        };

        /** @returns { ConversationMessageViewModel[] } */
        const _getUpdatedMessagesWaitingForAttachments = (
            /** @type { Set<string> } */ attachmentIdsToFilter,
            /** @type { ConversationMessageViewModel[] } */ messagesToCheck
        ) => {
            /** @type { Set<string> } */
            const messagesToRemove = new Set();

            messagesToCheck.forEach((/** @type { ConversationMessageViewModel } */ messageWaitingForAttachments) => {
                if (!messageWaitingForAttachments.hasAttachments) {
                    messagesToRemove.add(messageWaitingForAttachments.smsConversationMessageId);
                    return;
                }

                const allAttachmentsProcessed = messageWaitingForAttachments.attachments().every(/** @type {ConversationMessageAttachmentViewModel} */ attachment => {
                    return attachmentIdsToFilter.has(attachment.attachmentId());
                });

                if (allAttachmentsProcessed) {
                    messagesToRemove.add(messageWaitingForAttachments.smsConversationMessageId);
                } else {
                    const attachmentsToRemove = messageWaitingForAttachments.attachments().filter(/** @type {ConversationMessageAttachmentViewModel} */ attachment => {
                        return attachmentIdsToFilter.has(attachment.attachmentId());
                    });

                    messageWaitingForAttachments.attachments.removeAll(attachmentsToRemove);
                }
            });

            return messagesToCheck.filter(messageWaitingForAttachments => {
                return !messagesToRemove.has(messageWaitingForAttachments.smsConversationMessageId);
            });
        };

        //#endregion

        //#region recipients and hosted number
        /** @type self["setRecipients"] */
        self.setRecipients = (recipients) => {
            recipients.forEach((r) => _phoneNumberToRecipient.set(r.phoneNumber, r));
        };

        /** @type self["setHostedNumbers"] */
        self.setHostedNumbers = (webMessagingNumbers) => {
            webMessagingNumbers.forEach((n) => _accountHostedNumberIdToWebMessagingNumber.set(n.accountHostedNumberId, n));
        };

        self.setOwningHostedNumberId = (/** @type {string} */ owningHostedNumberId) => {
            _owningHostedNumberId(owningHostedNumberId);
        };

        /** @type self["getRecipient"] */
        self.getRecipient = (phoneNumber) => {
            return _phoneNumberToRecipient.get(phoneNumber);
        };

        /** @type self["getHostedNumber"] */
        self.getHostedNumber = (accountHostedNumberId) => {
           return _accountHostedNumberIdToWebMessagingNumber.get(accountHostedNumberId);
        };
        //#endregion

        //#endregion

        self.dispose = self.clearState;
    };
});
