define('common/collections/collectionFilter',[],
    function() {

        return function() {
            var self = this;
            var _propertyPaths = [];
            var _findValues = [];
            var _bypassFilterPaths = [];
            self.addProperty = function(propertyPath) {
                _propertyPaths.push(propertyPath);
                return self;
            };
            self.addProperties = function(propertyPaths) {
                _propertyPaths.push(...propertyPaths);
                return self;
            };
            self.addValue = function(value) {
                var formattedValue = value.toLowerCase();
                _findValues.push(formattedValue);
                return self;
            };
            self.setValue = function(value) {
                var formattedValue = value.toLowerCase();
                _findValues = [formattedValue];
                return self;
            };
            self.bypassFilterProperty = function(propertyPath) {
                _bypassFilterPaths.push(propertyPath);
                return self;
            };

            var _getPropertyValue = function(item, propertyPath) {
                var currentObject = item;
                var separatedPropertyPaths = propertyPath.split(".");
                for (var propertyPathIndex = 0; propertyPathIndex < separatedPropertyPaths.length; propertyPathIndex++) {
                    var propertyName = separatedPropertyPaths[propertyPathIndex];
                    currentObject = currentObject[propertyName];

                    if (currentObject === undefined || currentObject === null) {
                        currentObject = null;
                        break;
                    }
                }

                var foundObject = currentObject;
                var foundValue = "";
                if (typeof(foundObject) === "function") {
                    foundObject = foundObject();
                }
                if (foundObject !== null) {
                    var propertyType = typeof(foundObject);
                    switch (propertyType) {
                        case "string":
                            foundValue = foundObject;
                            break;
                        case "number":
                        case "boolean":
                            foundValue = foundObject.toString();
                            break;
                        case "function" :
                            foundValue = foundObject().toString();
                            break;
                        default:
                            throw new Error("Unable to evaluate type: " + propertyType + " for property named: " + propertyPath);
                    }
                }
                return foundValue;
            };

            var _isBypassFilter = function(item) {
                return _bypassFilterPaths.some(function(bypassFilterPath) {
                    return _getPropertyValue(item, bypassFilterPath) === "true";
                });
            };

            self.isSatisfiedBy = function(item) {
                for (var propertyIndex = 0; propertyIndex < _propertyPaths.length; propertyIndex++) {
                    var propertyPath = _propertyPaths[propertyIndex];

                    var checkValue = _getPropertyValue(item, propertyPath);
                    var formattedPropertyValue = checkValue.toLowerCase();
                    for (var findValueIndex = 0; findValueIndex < _findValues.length; findValueIndex++) {
                        var findValue = _findValues[findValueIndex];
                        if (formattedPropertyValue.indexOf(findValue) >= 0) {
                            return true;
                        }
                    }
                }
                return false;
            };

            self.filter = function(items) {
                return items.filter(function(item) {
                    return _isBypassFilter(item) || self.isSatisfiedBy(item);
                });
            };
        };
    });
