define('presentation/common/analyticsGraph/viewModels/analyticsGraphViewModel',['presentation/common/analyticsGraph/analyticsDataSet'],
    function (
        /** @type import('presentation/common/analyticsGraph/analyticsDataSet') */
        createAnalyticsDataSet
    ) {
        return function () {
            const self = this;

            let _chartDataSet;

            /** @type {IChartData} */
            let _chartData;
            const _chartDataSetCountObservable = ko.observable(0);
            /** @type {IDisposable[]} */
            let _disposables = [];
            let _searchByFilter = null;

            const redrawChart = () => {
                const chart = self.analyticsChart();
                if (chart === undefined) {
                    return;
                }
                chart.update();
            };

            const _recomputeChart = () => {
                if (_chartData === undefined || _chartData === null || _chartData.startMoment() === undefined) {
                    return;
                }

                const chart = self.analyticsChart();

                if (!chart) {
                    return;
                }

                _chartDataSet = createAnalyticsDataSet();
                _chartDataSet.initialize(_chartData.startMoment(), _chartData.endMoment(), self.chartGrouping(), self.searchByFilter());

                _chartData.graphData().forEach(graphData => {
                    _chartDataSet.addGraphRecord(graphData);
                });

                self.chartHeight = ko.computed(() => Math.round(chart.chartArea.bottom - chart.chartArea.top - 57) + 'px');

                chart.data.searchByFilter = self.searchByFilter();
                chart.data.labels = _chartDataSet.getLabels();
                chart.data.labelsTooltip = _chartDataSet.getLabelsTooltip();
                chart.data.datasets[0].data = _chartDataSet.getInboundCount();
                chart.data.datasets[1].data = _chartDataSet.getOutboundCount();
                chart.data.datasets[2].data = _chartDataSet.getTotalCount();

                const validGroupings = _chartDataSet.determineValidGroupings();
                enableChartGroupings(validGroupings);

                _chartDataSetCountObservable(_chartDataSet.getDatasetCount());

                redrawChart();
            };

            /** @type {KnockoutComputed<string>} */
            self.chartHeight = ko.pureComputed(() => "0px");
            /** @type {any[]} */
            self.chartGroupings = null;
            self.headerOptions = null;
            self.chartGrouping = null;
            /** @type {KnockoutObservable<Chart>} */
            self.analyticsChart = ko.observable();
            self.showLoadingState = ko.observable(true);
            self.showEmptyState = ko.observable(false);
            self.graphData = null;
            self.searchByFilter = ko.observable(null);

            const _setSearchByFilter = () => {
                self.searchByFilter(_searchByFilter());
            };

            const enableChartGroupings = (groupValuesToEnable) => {
                self.chartGroupings.forEach(grouping => {
                    const isNotInEnabledGroups = groupValuesToEnable.includes(grouping.value) === false;
                    grouping.isDisabled(isNotInEnabledGroups);
                });
                if (groupValuesToEnable.includes(self.chartGrouping()) === false) {
                    const longestTimeFrame = groupValuesToEnable[groupValuesToEnable.length - 1];
                    self.chartGrouping(longestTimeFrame);
                }
            };

            self.chartShowLines = ['inbound', 'outbound', 'total'].map((line, index) => {
                const showLine = {
                    key: line,
                    isSelected: ko.observable(true).toggleable(),
                    iconClass: `analytics-graph__legend-${line}-icon`,
                    get unselected() {
                        return ko.pureComputed(() => this.isSelected() === false);
                    }
                };
                _disposables.push(
                    showLine.isSelected.subscribe(() => {
                        setChartShowLine(showLine, index);
                        redrawChart();
                    })
                );
                return showLine;
            });

            const setChartShowLine = (showLine, dataSetIndex) => {
                const chart = self.analyticsChart();
                if (chart === undefined) {
                    return;
                }

                const dataSet = chart.data.datasets[dataSetIndex];
                dataSet.showLine = showLine.isSelected();
                if (dataSet.showLine === false || _chartDataSetCountObservable() > 54) {
                    hideDataSetCircles(dataSet);
                } else {
                    showDataSetCircles(dataSet);
                }
            };

            const hideDataSetCircles = (dataSet) => {
                dataSet.pointRadius = 0;
                dataSet.pointBorderWidth = 0;
                dataSet.pointHoverRadius = 0;
                dataSet.pointHoverBorderWidth = 0;
                dataSet.hitRadius = 0;
            };
            const showDataSetCircles = (dataSet) => {
                dataSet.pointRadius = 5;
                dataSet.pointBorderWidth = 2;
                dataSet.pointHoverRadius = 7;
                dataSet.pointHoverBorderWidth = 5;
                dataSet.hitRadius = 1;
            };

            self.detached = () => {
                _disposables.forEach(d => d.dispose());
                _disposables = [];
            };

            self.activate = (activationObject) => {
                _chartData = activationObject.chartData;
                self.graphData = activationObject.chartData.graphData;

                self.showLoadingState = activationObject.showLoadingState;
                self.showEmptyState = activationObject.showEmptyState;
                _searchByFilter = activationObject.parentViewModel().sidebarPane().selectedShowFilter;
                self.searchByFilter(_searchByFilter());

                self.headerOptions = activationObject.analyticsHeaderData;
                self.chartGroupings = self.headerOptions.options;
                self.chartGrouping = ko.pureComputed({
                    read: () => {
                        return self.headerOptions.selectedOption().value;
                    },
                    write: (newGrouping) => {
                        const optionMatch = self.chartGroupings.find((option) => option.value === newGrouping);
                        self.headerOptions.selectedOption(optionMatch);
                    },
                    owner: self
                });

                _disposables.push(
                    self.analyticsChart.subscribe(_recomputeChart),
                    self.graphData.subscribe(_recomputeChart),
                    _searchByFilter.subscribe(_setSearchByFilter),
                    self.chartGrouping.subscribe(_recomputeChart)
                );
            };
        };
    });


