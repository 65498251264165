define('presentation/contacts/viewModels/contactsSidebarViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'common/collections/enumerationKeyValueConverter',
    'common/promises/promiseFactory',
    'i18next',
    'presentation/contacts/facades/contactsSidebarFacade',
    'constants/contactSortByOptions',
    'constants/contactFilterByOptions'
], function (
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    _sessionAccountInfo,
    /** @type import('common/collections/enumerationKeyValueConverter') */
    _keyValueConverter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('presentation/contacts/facades/contactsSidebarFacade') */
    ContactsSidebarFacade,
    /** @type typeof import('constants/contactSortByOptions')*/
    ContactSortByOptions,
    /** @type typeof import('constants/contactFilterByOptions')*/
    ContactFilterByOptions
) {
    return function () {
        const self = this;

        /** @type { (typeof PromiseFactory)["prototype"] } */
        let _promiseFactory = null;

        /** @type { IDisposable[] } */
        let _disposables = [];

        /** @type import('presentation/contacts/facades/contactsSidebarFacade') */
        let _contactsSidebarFacade = null;

        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        self.initialData = null;
        self.isFilterVisible = ko.observable(false);
        self.comingSoonTooltip = i18n.t('comingSoon');
        self.tooltip = i18n.t('contactsSidebar:tooltip');
        self.positionY = ko.observable();

        self.selectedSortByOption = ko.observable(ContactSortByOptions.FirstName);
        self.sortByOptions = _keyValueConverter.convertToKeyValues(ContactSortByOptions).map((option) => {
            const firstChar = option.key.charAt(0).toLowerCase();
            const i18nKey = firstChar + option.key.substring(1);
            return { textI18n: i18n.t(`contactsSidebar:${i18nKey}`), value: option.value };
        });

        self.selectedItems = ko.observableArray([]);
        self.selectableItems = ko.observableArray([
            { label: i18n.t('contactsSidebar:companyAddressBook'), value: ContactFilterByOptions.companyAddressBook, pillIconName: 'companyAddressBook' },
            { label: i18n.t('contactsSidebar:yourAddressBook'), value: ContactFilterByOptions.yourAddressBook, pillIconName: 'yourAddressBook' },
            { label: i18n.t('contactsSidebar:trestaUserGroups'), value: ContactFilterByOptions.trestaUserGroups, pillIconName: 'trestaUserGroups' }
        ]);

        self.showEmptyState = ko.pureComputed(() => self.contacts().length === 0);

        self.placeholderCards = new Array(17);
        self.showPlaceholderContacts = ko.pureComputed(() => {
            return _sessionAccountInfo.isImpersonating();
        });

        self.contacts = ko.pureComputed(() => {
            /**  @type { Array<ISidebarContact> } */
            let result = [];
            let currentGroup = '';
            /** @type Array<ISidebarContactPresentationObject>*/
            let currentGroupContacts = [];
            const alphaOnlyRegex = /^[a-z]/i;

            self.initialData().map((/** @type ISidebarContactPresentationObject */ contact) => {
                if (!contact.displayNameFirst()) {
                    return;
                }

                if (self.selectedSortByOption() === ContactSortByOptions.FirstName) {
                    contact.displayName = contact.displayNameFirst;
                } else {
                    contact.displayName = contact.displayNameLast;
                }

                const firstChar = contact.displayName().charAt(0).toLowerCase();
                const groupChar = (!alphaOnlyRegex.test(firstChar)) ? '#' : firstChar;
                if (groupChar === currentGroup) {
                    currentGroupContacts.push(contact);
                    return;
                }

                currentGroup = groupChar;
                currentGroupContacts = [contact];

                result.push({
                    title: currentGroup,
                    group: currentGroupContacts
                });

            });

            return result;
        });

        self.isSelected = (/** @type ISidebarContactPresentationObject */ { accountContactId, accountUserId, accountUserGroupId }) => {
            const selectedId =  _contactsSidebarFacade.getSelectedContactId();
            return selectedId === accountContactId || selectedId === accountUserId || selectedId === accountUserGroupId;
        };

        self.navigateToAddContact = () => {
            const url = `${_navigationConfiguration.routesById.addContact.baseUrl}`;
            _router.navigate(url);
        };

        self.navigateToContact = (/** @type ISidebarContactPresentationObject */ { accountContactId, accountUserId, accountUserGroupId }) => {
            let contactId;
            if (accountContactId) {
                contactId = accountContactId;
            } else if (accountUserId) {
                contactId = accountUserId;
            } else {
                contactId = accountUserGroupId;
            }

            const url = `${_navigationConfiguration.routesById.contact.baseUrl}/${contactId}`;
            _router.navigate(url);
        };

        self.updateScrollPositionY = (/** @type { number } */ position) => {
            _contactsSidebarFacade.setScrollPosition(position);
        };

        self.detached = () => {
            _disposables.forEach(subscription => subscription.dispose());
            _disposables = [];
        };

        self.activate = () => {
            _promiseFactory = new PromiseFactory();

            _contactsSidebarFacade = new ContactsSidebarFacade();
            _contactsSidebarFacade.init(_promiseFactory);

            if (_sessionAccountInfo.isImpersonating()) {
                return _promiseFactory.wait();
            }

            self.initialData = _contactsSidebarFacade.getContactsSidebar();

            return _initialize();
        };

        const _onSortByChanged = (/** @type { any } */ selectedSortByOption) => {
            _contactsSidebarFacade.setSidebarContactsSort(selectedSortByOption);
        };

        const _onFilterByChanged = (/** @type { any } */ selectedItems) => {
            _contactsSidebarFacade.setSidebarContactsFilter(selectedItems);
        };

        const _initialize = () => {
            self.selectedSortByOption(ContactSortByOptions.FirstName);
            self.selectedItems(self.selectableItems().map(i => i.value));

            return _promiseFactory.wait();
        };

        self.attached = () => {
            _contactsSidebarFacade.getScrollPosition().then(position => {
                self.positionY(position);
            });
            _disposables.push(self.selectedSortByOption.subscribe(_onSortByChanged));
            _disposables.push(self.selectedItems.subscribe(_onFilterByChanged));
        };
    };
});
