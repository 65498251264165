define('presentation/messages/bindings/textAreaHandleEnter',[],() => {
    /** @typedef {import('presentation/messages/bindings/textAreaHandleEnter')} TextAreaHandleEnter */

    return {
        /** @type {TextAreaHandleEnter["init"]} */
        init: (element, valueAccessor) => {
            const { onSubmit, submitEnabled } = valueAccessor();

            let maxLength = Number(element.getAttribute("maxlength"));
            if (Number.isNaN(maxLength) || !maxLength) {
                maxLength = Number.MAX_SAFE_INTEGER;
            }

            const listener = (/** @type {KeyboardEvent} */evt) => {
                if (evt.key !== "Enter" || !(evt.target instanceof HTMLTextAreaElement)) {
                    return true;
                }

                if (evt.shiftKey) {
                    const element = evt.target;

                    if ((element.value.length + 1) >= maxLength) {
                        return true;
                    }

                    element.focus();
                    const { selectionStart, selectionEnd, value } = element;

                    element.value = value.slice(0, selectionStart) + `\n` + value.slice(selectionEnd);
                    element.selectionEnd = element.selectionStart = selectionStart + `\n`.length;

                    element.dispatchEvent(new Event('input', {
                        bubbles: false,
                        cancelable: true,
                    }));
                }
                else if (submitEnabled())
                {
                    onSubmit();
                }

                evt.preventDefault();
                return false;
            };

            element.addEventListener("keydown", listener);

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                element.removeEventListener("keydown", listener);
            });
        }
    };
});
