/**
 * Formats a date as HH:MM TT if the date provided is today, otherwise the date will be formatted as MM/DD/YY
 * @module common/converters/passedTimeSpanFormatter
 */
define('common/converters/passedTimeSpanFormatter',['common/time/date',
        'common/calendar/monthList'], function() {
    return function() {
        var self = this;
        var MonthConstructor = require('common/calendar/monthList');
        var _month = new MonthConstructor();


        var _format = function(dateISOString) {
            var DateConstructor = require('common/time/date');
            var date = new DateConstructor();
            date.setFromDateString(dateISOString);

            var now = new DateConstructor();
            now.setNow();

            var formatted;
            if (date.isOnSameDay(now)) {
                formatted = _formatTime(date);
            } else {
                formatted = _formatDate(date);
            }

            return formatted;
        };

        var _formatDate = function(date) {
            var day = date.day().toString();
            var month = date.month().toString();
            var year = date.year().toString();

            var formattedDay = ("00" + day).substr(-2);
            var formattedMonth = ("00" + month).substr(-2);

            var formattedDate = formattedMonth + "/" + formattedDay + "/" + year;
            return formattedDate;
        };

        const _formatBillingHistoryDate =(date) => {
            let day = date.day().toString();
            let month = date.month().toString();
            let year = date.year().toString();

            let formattedDay = ("00" + day).substr(-2);
            let formattedMonth = ("00" + month).substr(-2);

            let formattedDate = year + "-" + formattedMonth + "-" + formattedDay;
            return formattedDate;
        };

        var _formatDateString = function(dateISOString) {
            var DateConstructor = require('common/time/date');
            var date = new DateConstructor();
            date.setFromDateString(dateISOString);

            var now = new DateConstructor();
            now.setNow();

            var formatted;
            if (date.isOnSameDay(now)) {
                formatted = "today";
            } else {
                var day = date.day().toString();
                var year = date.year().toString();
                var monthPadded = date.monthPadded();
                var monthEntity = _month.monthList.find(function(month) {
                    return month.value === monthPadded;
                });
                formatted = monthEntity.name + " " + day + ", " + year;
            }

            return formatted;
        };

        var _formatDateStringUTC = function(dateISOString) {
            var DateConstructor = require('common/time/date');
            var date = new DateConstructor();
            date.setFromDateString(dateISOString);

            var now = new DateConstructor();
            now.setNow();

            var formatted;
            if (date.isOnSameDay(now)) {
                formatted = "today";
            } else {
                var day = date.utcDay().toString();
                var year = date.utcYear().toString();
                var monthPadded = date.utcMonthPadded();
                var monthEntity = _month.monthList.find(function(month) {
                    return month.value === monthPadded;
                });
                formatted = monthEntity.name + " " + day + ", " + year;
            }

            return formatted;
        };

        var _formatTime = function(date) {
            var hour = date.twelveHour().toString();
            var minutes = date.minute().toString();
            var meridiem = date.meridiem();

            var formattedHour = ("00" + hour).substr(-2);
            var formattedMinutes = ("00" + minutes).substr(-2);

            var formattedTime = formattedHour + ":" + formattedMinutes + " " + meridiem;
            return formattedTime;
        };

        var _formatDateTime = function(dateTimeISOString) {
            var DateConstructor = require('common/time/date');
            var dateTime = new DateConstructor();
            dateTime.setFromDateString(dateTimeISOString);

            return _formatDate(dateTime) + " " + _formatTime(dateTime);
        };

        self.format = _format;
        self.formatBillingHistoryDate = _formatBillingHistoryDate;
        self.formatDate = _formatDate;
        self.formatTime = _formatTime;
        self.formatDateString = _formatDateString;
        self.formatDateStringUTC = _formatDateStringUTC;
        self.formatDateTime = _formatDateTime;
    };
});

