define('presentation/shell/viewModels/loginShellViewModel',['businessServices/events/eventManager',
    'businessServices/router/router',
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'constants/shellNameEnumerations'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        const _shellNameEnumerations = require('constants/shellNameEnumerations');
        const _urlFormatter = require('common/url/urlFormatter');

        let _eventManager = null;

        self.router = null;
        self.homeUrl = null;
        self.childViewModel = ko.observable("");
        self.shellIsActive = ko.observable(true);

        const _onShellUpdated = (shellName) => {
            if (shellName === _shellNameEnumerations.login) {
                self.childViewModel(self.router.activeItem());
                self.shellIsActive(true);
            } else {
                self.childViewModel(null);
                self.shellIsActive(false);
            }
        };

        self.activate = () => {
            self.router = require('businessServices/router/router');
            _eventManager = require('businessServices/events/eventManager');

            return _initialize();
        };

        const _initialize = () => {
            self.childViewModel(self.router.activeItem());
            self.childViewModel().parent = self;
            self.homeUrl = _urlFormatter.buildFrontendUrl("/");

            _eventManager.subscribeShellUpdated(_onShellUpdated);

            return _promiseFactory.wait();
        };
    };
});
