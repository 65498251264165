define('constants/numberPickerConstants',[], function() {
    return {
        localStatuses: {
            regionCityNpa: "RegionCityNpa",
            regionCity: "RegionCity",
            npa: "Npa"
        },
        localVanityStringMinimumLength: 3,
        maxNumbersCanAdd: 25,
        numberTypes: {
            local: 1,
            tollFree: 2,
            port: 3,
        },
        requestTypes: {
            initial: 1,
            default: 2,
            tollFreeAlternate: 3,
            user: 4,
            reservation: 5,
            unspecified: 6,
            searchTypeTabSelected: 7,
        },
        searchTypeEnumeration: {
            default: 1,
            repeat: 2,
            repeating: 2,
            vanity: 3,
        },
        searchTypeConstants: {
            default: 'default',
            repeating: 'repeating',
            vanity: 'vanity',
        },
        searchTypeLookup: {
            1: 'default',
            2: 'repeating',
            3: 'vanity',
        },
        tollFreeVanityStringMinimumLength: 4,
        tollFreePrefixDefault: "888",
        tollFreePrefixes: ["888", "877", "866", "855", "844", "833", "800"],
        tollFreeStatuses: {
            alternatePrefix: "AlternatePrefix",
            none: "None",
            success: "Success",
        },
        vanityTypeSelectConstants: {
            begins: 'begins',
            contains: 'contains',
            ends: 'ends',
        },
        successfullyReserved: 'reserved',
    };
});

