define('presentation/acceptUserInvite/presentationObjects/createUserDevicePresentationObject',[],function() {
    return function(){
        var self = this;

        self.deviceId = ko.observable("");
        self.deviceName = ko.observable("");
        self.devicePhoneNumber = ko.observable("");
        self.deviceCountryAbbreviation = ko.observable("us");
        self.availableCallerIds = [
            {key: "caller_ani", name: "Caller's Number"},
            {key: "hosted_number", name: "Tresta Number"}
        ];
        self.selectedCallerId = ko.observable('hosted_number').extend({observeState: true});
    };
});

