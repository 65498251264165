define('presentation/common/analyticsGraph/analyticsDataSet',[
        'moment-timezone',
        'presentation/common/analyticsGraph/graphRecordView'],
    function (
        /** @type import('moment-timezone') */
        moment,
        /** @type import('presentation/common/analyticsGraph/graphRecordView') */
        graphRecordView
    ) {
        /**
         * @typedef {{
         *  startMoment: moment.Moment
         *  endMoment: moment.Moment
         *  inboundCallCount: number
         *  inboundSecondsCount: number
         *  outboundCallCount: number
         *  outboundSecondsCount: number
         *  totalCallCount: number
         *  totalSecondsCount: number
         *  label?: string
         * }} DataSet
         */

        return function() {
            /** @type {DataSet[]} */
            let _dataSets = [];
            /** @type {moment.Moment} */
            let _startMoment;
            /** @type {moment.Moment} */
            let _endMoment;
            /** @type {string} */
            let _groupBy;
            /** @type {string} */
            let _searchByFilter;

            /**
             * 
             * @param {moment.Moment} startMoment 
             * @param {moment.Moment} endMoment 
             * @param {string} groupBy 
             * @param {string} searchByFilter 
             */
            const initialize = (startMoment, endMoment, groupBy, searchByFilter) => {
                _startMoment = startMoment;
                _endMoment = endMoment;
                _groupBy = groupBy;
                _searchByFilter = searchByFilter;

                let currentMoment = startMoment.clone();
                if(currentMoment.minute() > 0) {
                    currentMoment.subtract(currentMoment.minute(), 'minutes');
                }
                while (currentMoment.isBefore(endMoment)) {

                    let nextMoment = currentMoment.clone();
                    switch (groupBy) {
                        case "hour":
                            nextMoment.add(1, 'hours');
                            break;
                        case "day":
                            nextMoment.add(1, 'days');
                            break;
                        case "week":
                            if (currentMoment.day() === 0) {
                                // Sunday
                                nextMoment.add(7, 'days');
                                if (endMoment.isBefore(nextMoment)) {
                                    nextMoment = endMoment.clone();
                                }
                            } else {
                                nextMoment.add(1, 'days');
                                while (true) {
                                    if (nextMoment.day() === 0) {
                                        // Sunday
                                        break;
                                    }
                                    if (endMoment.isBefore(nextMoment)) {
                                        break;
                                    }

                                    nextMoment.add(1, 'days');
                                }

                                if (endMoment.isBefore(nextMoment)) {
                                    nextMoment = endMoment.clone();
                                }
                            }
                            break;
                        case "month":
                            if (currentMoment.date() === 1) {
                                // The first of the month
                                nextMoment.add(1, 'months');
                                if (endMoment.isBefore(nextMoment)) {
                                    nextMoment = endMoment.clone();
                                }
                            } else {
                                nextMoment.add(1, 'months');
                                while (true) {
                                    if (nextMoment.date() === 1) {
                                        // First of the month
                                        break;
                                    }
                                    if (endMoment.isBefore(nextMoment)) {
                                        break;
                                    }

                                    nextMoment.add(1, 'days');
                                }
                            }
                            break;
                    }

                    const dataSet = createDataSet(currentMoment, nextMoment);
                    _dataSets.push(dataSet);

                    currentMoment = nextMoment;
                }
            };

            /**
             * @param {moment.Moment} startMoment 
             * @param {moment.Moment} endMoment 
             * @returns {DataSet}
             */
            const createDataSet = (startMoment, endMoment) => {
                return {
                    startMoment: startMoment,
                    endMoment: endMoment,
                    inboundCallCount: 0,
                    inboundSecondsCount: 0,
                    outboundCallCount: 0,
                    outboundSecondsCount: 0,
                    totalCallCount: 0,
                    totalSecondsCount: 0,
                };
            };

            const selectDataSet = (/** @type {moment.Moment} */callMoment) => {
                return _dataSets.find(function(dataSet) {
                    if (dataSet.endMoment.isAfter(callMoment)) {
                        if (dataSet.startMoment.isBefore(callMoment) || dataSet.startMoment.isSame(callMoment)) {
                            return true;
                        }
                    }
                    return false;
                });
            };

            const addGraphRecord = (graphRecord) => {
                const callMoment = moment(graphRecordView.dateHour(graphRecord));
                const dataSet = selectDataSet(callMoment);
                if (dataSet !== undefined) {
                    dataSet.inboundCallCount += graphRecordView.inboundCount(graphRecord);
                    dataSet.inboundSecondsCount += graphRecordView.inboundSeconds(graphRecord);
                    dataSet.outboundCallCount += graphRecordView.outboundCount(graphRecord);
                    dataSet.outboundSecondsCount += graphRecordView.outboundSeconds(graphRecord);
                    dataSet.totalCallCount = dataSet.inboundCallCount + dataSet.outboundCallCount;
                    dataSet.totalSecondsCount = dataSet.inboundSecondsCount + dataSet.outboundSecondsCount;
                }
            };

            const getLabels = () => {
                /** @type {string} */
                let dataFormatStartMoment = null;
                /** @type {string} */
                let dataFormatEndMoment = null;
                switch (_groupBy) {
                    case "hour":
                        if (_dataSets.length > 24) {
                            dataFormatStartMoment = "MMM D, h:mm A";
                        } else {
                            dataFormatStartMoment = "h:mm A";
                        }
                        break;
                    case "day":
                        dataFormatStartMoment = "MMM D";
                        break;
                    case "week":
                        dataFormatStartMoment = "MMM D";
                        dataFormatEndMoment = "MMM D";
                        break;
                    case "month":
                        dataFormatStartMoment = "MMM YYYY";
                        break;
                }

                _dataSets.forEach(function(dataSet) {
                    switch (_groupBy) {
                        case "hour":
                        case "day":
                            dataSet.label = dataSet.startMoment.format(dataFormatStartMoment);
                            break;
                        case "week":
                            dataSet.label = dataSet.startMoment.format(dataFormatStartMoment) + " - " + dataSet.endMoment.format(dataFormatEndMoment);
                            break;
                        case "month":
                            dataSet.label = dataSet.startMoment.format(dataFormatStartMoment);
                            break;
                    }
                });

                return _dataSets;
            };

            const getLabelsTooltip = () => {
                const labels = _dataSets.map(function(dataSet) {
                    switch (_groupBy) {
                        case "hour":
                            return dataSet.startMoment.format('ddd, MMM Do, YYYY h:mm A');
                        case "day":
                            return dataSet.startMoment.format('dddd, MMMM Do, YYYY');
                        case "week":
                            const weekEndMoment = dataSet.endMoment.clone().subtract(1, 'days');
                            return dataSet.startMoment.format('ddd, MMM Do') + " - " + weekEndMoment.format('ddd, MMM Do, YYYY');
                        case "month":
                            const monthEndMoment = dataSet.endMoment.clone().subtract(1, 'days');
                            return dataSet.startMoment.format('ddd, MMM Do') + " - " + monthEndMoment.format('ddd, MMM Do, YYYY');
                    }
                });

                return labels;
            };

            const calculateDecimalMinutes = (/** @type {number} */seconds) => Math.round(seconds/60 * 10) / 10;

            const getInboundCount = () => {
                switch (_searchByFilter) {
                    case "calls":
                        return _dataSets.map(dataSet => dataSet.inboundCallCount);
                    case "minutes":
                        return _dataSets.map(dataSet => calculateDecimalMinutes(dataSet.inboundSecondsCount));
                }
            };

            const getOutboundCount = function () {
                switch (_searchByFilter){
                    case "calls":
                        return _dataSets.map(dataSet => dataSet.outboundCallCount);
                    case "minutes":
                        return _dataSets.map(dataSet => calculateDecimalMinutes(dataSet.outboundSecondsCount));
                }
            };

            const getTotalCount = function() {
                switch (_searchByFilter) {
                    case "calls":
                        return _dataSets.map(dataSet => dataSet.inboundCallCount + dataSet.outboundCallCount);
                    case "minutes":
                        // can't use total seconds here, was seeing rounding problem. eg. in = .1; out = .1; would both round to zero, but the .2 total would show.
                        // so there would be a graph bump for total but both inbound and outbound would show nothing.
                        return _dataSets.map(dataSet => calculateDecimalMinutes(dataSet.inboundSecondsCount + dataSet.outboundSecondsCount));
                }
            };

            const getDatasetCount = () => {
                return _dataSets.length;
            };

            const determineValidGroupings = () => {
                const isDateRangeGreaterThanOneDay = _endMoment.isAfter(_startMoment.clone().add(1, 'days'));

                if (isDateRangeGreaterThanOneDay) {
                    return ["hour", "day", "week", "month"];
                } else {
                    return ["hour", "day"];
                }
            };

            return {
                initialize : initialize,
                addGraphRecord: addGraphRecord,

                getLabels: getLabels,
                getLabelsTooltip: getLabelsTooltip,
                getDatasetCount : getDatasetCount,

                getOutboundCount : getOutboundCount,
                getInboundCount : getInboundCount,
                getTotalCount : getTotalCount,

                determineValidGroupings : determineValidGroupings,
            };
        };
    });


