define('presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxViewModel',[
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'businessServices/state/modelStateObserver',
    'presentation/settings/voicemailBoxes/facades/addVoicemailBoxFacade',
    'presentation/settings/voicemailBoxes/validators/addVoicemailBoxViewModelValidator',
    'presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxGreetingsViewModel',
    'presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxSubscribersViewModel',
    'presentation/tabs/presentationObjects/tabsPresentationObject',
    'businessServices/router/router',
    'settings/navigationConfiguration'
], function () {
    return function () {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');
        const TabPresentationObjectConstructor = require('presentation/tabs/presentationObjects/tabsPresentationObject');

        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');
        const _navigationConfiguration = require('settings/navigationConfiguration');
        const _router = require('businessServices/router/router');

        let _facade = null;
        let _disposables = [];
        let _validator = null;
        let _voicemailBoxOwnerId = null;

        const _populateExistingVoicemailBoxForm = () => {
            return _promiseFactory.defer((promise) => {
                let _existingVoicemailBoxPromiseFactory = new PromiseFactoryConstructor();
                _existingVoicemailBoxPromiseFactory.defer((voicemailBoxPromise) => {
                    _facade.getVoicemailBox(self.voicemailBoxId)
                        .fail(voicemailBoxPromise.reject)
                        .done((result) => {
                            let existingVoicemailBox = result.data[0];
                            if (existingVoicemailBox.owningUserGroupId !== null) {
                                _voicemailBoxOwnerId = existingVoicemailBox.owningUserGroupId;
                            }
                            if (existingVoicemailBox.owningUserId !== null) {
                                _voicemailBoxOwnerId = existingVoicemailBox.owningUserId;
                            }
                            if (existingVoicemailBox.standaloneName) {
                                self.standaloneName(existingVoicemailBox.standaloneName);
                            } else {
                                let commonStateItem = _commonState.get(_voicemailBoxOwnerId);
                                self.type(commonStateItem.typeDisplay);
                                self.name = commonStateItem.name;
                            }
                            voicemailBoxPromise.resolve();
                        });
                });

                _existingVoicemailBoxPromiseFactory.wait()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _saveNewVoicemailBoxForm = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _facade.createVoicemailBox(self.standaloneName())
                    .fail(promise.reject)
                    .done((voicemailBoxId) => {
                        self.voicemailBoxId = voicemailBoxId;
                        promise.resolve();
                    });
            });
        };

        const _updateVoicemailBox = () => {
            return _promiseFactory.deferIndefinitely((promise) => {
                let customGreetingId = null;
                let systemGreetingId = null;

                let currentGreeting = self.greetingsTabViewModel.greetings().find(g => g.isDefault());
                if (currentGreeting.isBuiltIn()) {
                    systemGreetingId = currentGreeting.voicemailBoxGreetingId;
                } else {
                    customGreetingId = currentGreeting.voicemailBoxGreetingId;
                }

                const voicemailBox = {
                    standaloneName : self.standaloneName(),
                    customGreetingId : customGreetingId,
                    systemGreetingId : systemGreetingId,
                };

                _facade.updateVoicemailBox(self.voicemailBoxId, voicemailBox)
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };

        const _updateActiveTabViewModel = () => {
            switch (self.tabActive()) {
                case self.tabMetadata.greetings.id:
                    self.activeTabViewModel(self.greetingsTabViewModel);
                    break;
                case self.tabMetadata.subscribers.id:
                    self.activeTabViewModel(self.subscribersTabViewModel);
                    break;
            }
        };

        self.voicemailBoxId = null;
        self.voicemailBoxesUrl = _navigationConfiguration.routesById.voicemailBoxes.url;
        self.modelStateObserver = null;
        self.isNewVoicemailBox = ko.observable(false);
        self.type = ko.observable('Standalone');
        self.standaloneName = ko.observable('').extend({observeState: true, rateLimit: {timeout: 1000, method: 'notifyWhenChangesStop'}});
        self.tabActive = ko.observable();
        self.tabMetadata = {
            greetings: { id: "greetings", isValid: ko.observable(true), title: _i18n.t('addVoicemailBox:greetingsTab') },
            subscribers: { id: "subscribers", isValid: ko.observable(true), title: _i18n.t('addVoicemailBox:subscribersTab') },
        };
        self.tabs = [
            new TabPresentationObjectConstructor(self.tabMetadata.greetings.id, self.tabActive, self.tabMetadata.greetings.isValid, self.tabMetadata.greetings.title),
            new TabPresentationObjectConstructor(self.tabMetadata.subscribers.id, self.tabActive, self.tabMetadata.subscribers.isValid, self.tabMetadata.subscribers.title)
        ];
        self.tabActive(self.tabs[0].id);
        self.isFirstTabActive = ko.computed(() => self.tabActive() === self.tabs[0].id);
        self.isTabActive = (tabId) => {
            return self.tabActive() === tabId;
        };
        self.greetingsTabViewModel = null;
        self.subscribersTabViewModel = null;
        self.activeTabViewModel = ko.observable();
        self.headerI18nKey = ko.pureComputed(() => self.voicemailBoxId ? "addVoicemailBox:headerEdit" : "addVoicemailBox:headerAdd");
        self.saveButtonTitle = ko.pureComputed(() => self.voicemailBoxId ? _i18n.t('addVoicemailBox:save') : _i18n.t('addVoicemailBox:add'));
        self.invalidTabs = ko.pureComputed(() => {
            return self.tabs.reduce((accumulator, presentationObject) => {
                if (!presentationObject.isTabValid()) {
                    accumulator.push(presentationObject.tabText);
                }

                return accumulator;
            }, []);
        });
        self.validationWarningMessages = ko.pureComputed(() => {
            return [
                {
                    message: _i18n.t('addVoicemailBox:validationTabDescription'),
                    listItems: self.invalidTabs()
                },
                {
                    message: _i18n.t('addVoicemailBox:validationPleaseFix'),
                    listItems: []
                }
            ];
        });

        self.forceValidation = () => {
            let validationPromiseFactory = new PromiseFactoryConstructor();
            let isFormValid;
            let isGreetingsTabValid;
            let isSubscribersTabValid;

            validationPromiseFactory.defer((formPromise) => {
                if (self.type() === 'Standalone') {
                    _validator.validate()
                        .fail(formPromise.reject)
                        .done((isValid) => {
                            isFormValid = isValid;
                            formPromise.resolve();
                        });
                }  else {
                    isFormValid = true;
                    formPromise.resolve(true);
                }
            });

            validationPromiseFactory.defer((greetingsTabPromise) => {
                self.greetingsTabViewModel.validate()
                    .fail(greetingsTabPromise.reject)
                    .done((isValid) => {
                        isGreetingsTabValid = isValid;
                        greetingsTabPromise.resolve();
                    });
            });
            validationPromiseFactory.defer((subscriberSelectionPromise) => {
                self.subscribersTabViewModel.subscriberSelectionViewModel.validate()
                    .fail(subscriberSelectionPromise.reject)
                    .done((isValid) => {
                        isSubscribersTabValid = isValid;
                        subscriberSelectionPromise.resolve();
                    });
            });

            return _promiseFactory.defer((validationPromise)=> {
                validationPromiseFactory.wait()
                    .fail(validationPromise.reject)
                    .done(() => {
                        validationPromise.resolve(isFormValid && isGreetingsTabValid && isSubscribersTabValid);
                    });
            });
        };

        self.save = () => {
            return _promiseFactory.deferWithMinimumWait((promise) => {
                self.forceValidation()
                    .fail(promise.reject)
                    .done((isValid) => {
                        if (!isValid) {
                            promise.resolve();
                        } else {
                            const _newVoicemailBoxPromiseFactory = new PromiseFactoryConstructor();
                            _newVoicemailBoxPromiseFactory.deferIndefinitely((newVoicemailBoxPromise) => {
                                if (self.voicemailBoxId === null) {
                                    _saveNewVoicemailBoxForm()
                                        .fail(newVoicemailBoxPromise.reject)
                                        .done(newVoicemailBoxPromise.resolve);
                                } else {
                                    newVoicemailBoxPromise.resolve();
                                }
                            });

                            _newVoicemailBoxPromiseFactory.wait()
                                .fail(promise.reject)
                                .done(() => {
                                    const savePromiseFactory = new PromiseFactoryConstructor();
                                    savePromiseFactory.defer((greetingsPromise) =>{
                                        self.greetingsTabViewModel.save(self.voicemailBoxId)
                                            .fail(greetingsPromise.reject)
                                            .done(greetingsPromise.resolve);
                                    });
                                    savePromiseFactory.defer((subscribersPromise) => {
                                        self.subscribersTabViewModel.save(self.voicemailBoxId)
                                            .fail(subscribersPromise.reject)
                                            .done(subscribersPromise.resolve);
                                    });

                                    savePromiseFactory.wait()
                                        .fail(promise.reject)
                                        .done(() => {
                                            _updateVoicemailBox()
                                                .fail(promise.reject)
                                                .done(() => {
                                                    self.modelStateObserver.saveData();
                                                    _router.navigate(self.voicemailBoxesUrl);
                                                    promise.resolve();
                                                });
                                        });
                                });
                        }
                    });
            });
        };

        self.cancelForm = () => {
            return _promiseFactory.defer((promise) => {
                self.greetingsTabViewModel.cancelForm()
                    .fail(promise.reject)
                    .done(() => {
                        self.modelStateObserver.navigateWithoutWarning = true;
                        _router.navigate(self.voicemailBoxesUrl);
                        promise.resolve();
                    });
            });
        };

        self.compositionComplete = () => {
            if (self.voicemailBoxId === null) {
                self.isNewVoicemailBox(true);
            }
        };

        self.detached = () => {
            self.greetingsTabViewModel.disposeSubscriptions();

            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = (voicemailBoxId) => {
            if (voicemailBoxId) {
                self.voicemailBoxId = voicemailBoxId;
            }

            const VoicemailBoxGreetingsViewModelConstructor = require('presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxGreetingsViewModel');
            self.greetingsTabViewModel = new VoicemailBoxGreetingsViewModelConstructor();

            const VoicemailBoxSubscribersViewModelConstructor = require('presentation/settings/voicemailBoxes/viewModels/addVoicemailBoxSubscribersViewModel');
            self.subscribersTabViewModel = new VoicemailBoxSubscribersViewModelConstructor();

            const FacadeConstructor = require('presentation/settings/voicemailBoxes/facades/addVoicemailBoxFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            const ValidatorConstructor = require('presentation/settings/voicemailBoxes/validators/addVoicemailBoxViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.defer((initPromise) => {

                const initPromiseFactory = new PromiseFactoryConstructor();
                initPromiseFactory.defer((formPromise) => {
                    if (self.voicemailBoxId) {
                        _populateExistingVoicemailBoxForm()
                            .fail(formPromise.reject)
                            .done(() => {
                                _initializeTabs()
                                    .fail(formPromise.reject)
                                    .done(formPromise.resolve);
                            });
                    } else {
                        _initializeTabs()
                            .fail(formPromise.reject)
                            .done(formPromise.resolve);
                    }
                });

                initPromiseFactory.wait()
                    .fail(initPromise.reject)
                    .done(() => {
                        self.modelStateObserver = new ModelStateObserverConstructor(self, true, [self.greetingsTabViewModel.modelStateObserver, self.subscribersTabViewModel.modelStateObserver]);
                        self.modelStateObserver.commitData();
                        _validator.registerViewModel(self, _facade);
                        initPromise.resolve();
                    });
            });

            return _promiseFactory.wait();
        };

        const _initializeTabs = () => {
            return _promiseFactory.defer((initTabsPromise) => {
                let initTabsPromiseFactory = new PromiseFactoryConstructor();
                initTabsPromiseFactory.defer((greetingsPromise) => {
                    self.greetingsTabViewModel.activate(self.voicemailBoxId)
                        .fail(greetingsPromise.reject)
                        .done(greetingsPromise.resolve);
                });
                initTabsPromiseFactory.defer((subscribersPromise) => {
                    self.subscribersTabViewModel.activate(self.voicemailBoxId, _voicemailBoxOwnerId)
                        .fail(subscribersPromise.reject)
                        .done(subscribersPromise.resolve);
                });

                initTabsPromiseFactory.wait()
                    .fail(initTabsPromise.reject)
                    .done(() => {
                        let firstTab = self.tabs.find(t => true);
                        self.tabActive(firstTab.id);

                        _updateActiveTabViewModel();
                        _disposables.push(self.tabActive.subscribe(_updateActiveTabViewModel));
                        initTabsPromise.resolve();
                    });
            });
        };
    };
});
