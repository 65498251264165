define('businessServices/blocking/blockingStateSingleton',[
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'common/time/datetimeFormatter',
    'i18next',
    'persistence/repositories/blockingRepository'
], function (
    /** @type typeof import('common/converters/phoneNumberFormatter') */
    PhoneNumberFormatter,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type typeof import('common/time/datetimeFormatter') */
    DateTimeFormatter,
    /** @type typeof import('i18next') */
    i18n,
    /** @type typeof import('persistence/repositories/blockingRepository') */
    BlockingRepository
) {
    /** @typedef { import('businessServices/blocking/blockingStateSingleton') } BlockingStateSingleton */

    const BlockingStateSingleton = function () {

        const _dateTimeFormatter = new DateTimeFormatter();
        const _phoneNumberFormatter = new PhoneNumberFormatter();

        const _promiseFactory = new PromiseFactory();

        /** @type { Map<string, IBlockedPhoneNumberPresentationObject> } */
        const _blockedNumbersById = new Map();
        /** @type { Map<string, IBlockedPhoneNumberPresentationObject> } */
        const _blockedNumbersByPhoneNumber = new Map();
        /** @type { KnockoutObservableArray<IBlockedPhoneNumberPresentationObject> } */
        const _blockedPhoneNumbers = ko.observableArray([]);

        /** @type { IBlockingRepository } */
        let _blockingRepository = null;
        _blockingRepository = new BlockingRepository();

        /** @type { IDisposable[] } */
        let _disposables = [];

        this.isLoaded = false;

        // use pureComputed so these values cannot be accidentally overwritten without calling the setters
        this.blockedPhoneNumbers = ko.pureComputed(() => _blockedPhoneNumbers());

        /** @type { BlockingStateSingleton["initialize"] } */
        this.initialize = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {

                _blockingRepository.getBlockedContacts()
                    .then((/** @type { IGetBlockedContactsResponse } **/ result) => {
                        const { blockedPhoneNumbers } = result;
                        if (blockedPhoneNumbers && blockedPhoneNumbers.length > 0) {
                            const presentationObjects = _buildBlockedPhoneNumberPresentationObjects(blockedPhoneNumbers);
                            _upsertBlockedPhoneNumbersToMap(presentationObjects);
                            _setBlockedPhoneNumbers(presentationObjects);
                            deferredObject.resolve();
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        /** @type { BlockingStateSingleton["getBlockedPhoneNumberById"] } */
        this.getBlockedPhoneNumberById = (/** @type string */ blockedPhoneNumberId) => {
            return _promiseFactory.defer((deferredObject) => {
                if (_blockedNumbersById.has(blockedPhoneNumberId)) {
                    const blockedNumber = _blockedNumbersById.get(blockedPhoneNumberId);
                    deferredObject.resolve(blockedNumber);
                }

                _blockingRepository.getBlockedPhoneNumberById(blockedPhoneNumberId)
                    .then((/** @type { IBlockedPhoneNumber } */ blockedPhoneNumber) => {
                        if (blockedPhoneNumber) {
                            const presentationObject = _buildBlockedPhoneNumberPresentationObject(blockedPhoneNumber);
                            _upsertBlockedPhoneNumbersToMap([presentationObject]);
                            deferredObject.resolve(presentationObject);
                        } else {
                            deferredObject.resolve(null);
                        }
                    });
            });
        };

        /** @type { BlockingStateSingleton["getBlockedPhoneNumberByNumber"] } */
        this.getBlockedPhoneNumberByNumber = (/** @type string */ phoneNumber) => {
            return _promiseFactory.defer((deferredObject) => {
                const phoneNumberKey = _phoneNumberFormatter.toNumbers(phoneNumber);
                if (_blockedNumbersById.has(phoneNumberKey)) {
                    const blockedNumber = _blockedNumbersById.get(phoneNumberKey);
                    deferredObject.resolve(blockedNumber);
                } else {
                    deferredObject.resolve(null);
                }
            });
        };

        this.blockContact = (/** @type { IBlockContactRequest } */ blockContactRequest) => {
            return _blockingRepository.blockContact(blockContactRequest);
        };

        this.blockPhoneNumber = (/** @type { IBlockPhoneNumberRequest } */ blockPhoneNumberRequest) => {
            return _blockingRepository.blockPhoneNumber(blockPhoneNumberRequest);
        };

        this.unblockContact = (/** @type { IUnblockContactRequest } */ unblockContactRequest) => {
            return _blockingRepository.unblockContact(unblockContactRequest);
        };

        this.unblockPhoneNumber = (/** @type { IUnblockPhoneNumberRequest } */ unblockPhoneNumberRequest) => {
            return _blockingRepository.unblockPhoneNumber(unblockPhoneNumberRequest);
        };

        this.reportContact = (/** @type { IReportContactRequest } */ reportContactRequest) => {
            return _blockingRepository.reportContact(reportContactRequest);
        };

        this.reportPhoneNumber = (/** @type { IReportPhoneNumberRequest } */ reportPhoneNumberRequest) => {
            return _blockingRepository.reportPhoneNumber(reportPhoneNumberRequest);
        };

        /** @type { (repository: IBlockingRepository) => void } */
        this.setRepository = (repository) => {
            if (_blockingRepository !== null) {
                return;
            }

            _blockingRepository = repository;

            // register event listeners
            _blockingRepository.onBlockedPhoneNumberCreated(_onBlockedPhoneNumberCreated);
            _blockingRepository.onBlockedPhoneNumberDeleted(_onBlockedPhoneNumberDeleted);

            _disposables.push(_blockingRepository);
        };

        /** @type { IAuthenticationListeningModule["alerts"] } */
        this.alerts = {
            receiveAlertLoginSuccessful: () => {

            },
            receiveAlertLogout: () => {
                this.isLoaded = false;

                _blockedNumbersById.clear();
                _blockedNumbersByPhoneNumber.clear();
                _blockedPhoneNumbers([]);

                _disposables.forEach((disposable) => disposable.dispose());
            }
        };

        /** @type { (event: IBlockedPhoneNumberCreatedEvent) => void } */
        const _onBlockedPhoneNumberCreated = (event) => {
            _blockingRepository.getBlockedPhoneNumberById(event.blockedPhoneNumberId)
                .then((/** @type { IBlockedPhoneNumber } */ phoneNumber) => {
                    const presentationObject = _buildBlockedPhoneNumberPresentationObject(phoneNumber);
                    _upsertBlockedPhoneNumbersToMap([presentationObject]);

                    const currentNumbers = _blockedPhoneNumbers();
                    currentNumbers.push(presentationObject);
                    _blockedPhoneNumbers(currentNumbers);
                });
        };

        /** @type { (event: IBlockedPhoneNumberDeletedEvent) => void } */
        const _onBlockedPhoneNumberDeleted = (event) => {
            _deleteBlockedPhoneNumberFromMap(event.blockedPhoneNumberId, event.phoneNumber);
            _blockedPhoneNumbers.remove((phoneNumber) => {
                return phoneNumber.blockedPhoneNumberId === event.blockedPhoneNumberId;
            });
        };

        const _deleteBlockedPhoneNumberFromMap = (/** @type { string } */ blockedPhoneNumberId, /** @type { string } */ phoneNumber) => {
            _blockedNumbersById.delete(blockedPhoneNumberId);
            const phoneNumberKey = _phoneNumberFormatter.toNumbers(phoneNumber);
            _blockedNumbersByPhoneNumber.delete(phoneNumberKey);
        };

        const _upsertBlockedPhoneNumbersToMap = (/** @type { Array<IBlockedPhoneNumberPresentationObject> } */ blockedPhoneNumbers) => {
            blockedPhoneNumbers.map((number) => {
                _blockedNumbersById.set(number.blockedPhoneNumberId, number);
                const phoneNumberKey = _phoneNumberFormatter.toNumbers(number.phoneNumber);
                _blockedNumbersByPhoneNumber.set(phoneNumberKey, number);
            });
        };

        const _setBlockedPhoneNumbers = (/** @type { Array<IBlockedPhoneNumberPresentationObject> } */ phoneNumbers) => {
            const updatedBlockedPhoneNumbers = _blockedPhoneNumbers().concat(phoneNumbers);
            _blockedPhoneNumbers(updatedBlockedPhoneNumbers);
        };


        const _buildBlockedPhoneNumberPresentationObjects = (/** @type { Array<IBlockedPhoneNumber> } */ blockedPhoneNumbers) => {
            return blockedPhoneNumbers.map((contact) => {
                return _buildBlockedPhoneNumberPresentationObject(contact);
            });
        };

        const _buildBlockedPhoneNumberPresentationObject = (/** @type { IBlockedPhoneNumber } */ blockedPhoneNumber) => {
            /** @type { IBlockedPhoneNumberPresentationObject } */
            const blockedPhoneNumberPresentationObject = {
                blockedPhoneNumberId: blockedPhoneNumber.blockedPhoneNumberId,
                phoneNumber: blockedPhoneNumber.phoneNumber,
                createdDateTime: blockedPhoneNumber.createdDateTime,
                displayPhoneNumber: _formatPhoneNumberForDisplay(blockedPhoneNumber.phoneNumber),
                displayCreatedDateTime: _dateTimeFormatter.formatWithinDayOrLongDate(blockedPhoneNumber.phoneNumber)
            };

            return blockedPhoneNumberPresentationObject;
        };

        const _formatPhoneNumberForDisplay = (/** @type { string } */ phoneNumber) => {
            const digitsOnly = _phoneNumberFormatter.toNumbers(phoneNumber);
            return digitsOnly ? _phoneNumberFormatter.toNumericDefault(digitsOnly) : phoneNumber;
        };

        _blockingRepository.onBlockedPhoneNumberCreated(_onBlockedPhoneNumberCreated);
        _blockingRepository.onBlockedPhoneNumberDeleted(_onBlockedPhoneNumberDeleted);
        _disposables.push(_blockingRepository);
    };


    BlockingStateSingleton.singleton = new BlockingStateSingleton();

    return BlockingStateSingleton.singleton;
});
