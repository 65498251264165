define('businessServices/login/loginInfoProvider',[
    'common/promises/promiseFactory',
    'externalDependencies/kazooApi'
], function() {

    function validateUsername(username) {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var promiseFactory = new PromiseFactoryConstructor();
        return promiseFactory.defer(function(deferredObject) {
            var params = { "data" : { "emailAddress" : username }};

            var jsonCall = JSON.stringify(params);

            var KazooConstructor = require('externalDependencies/kazooApi');
            var kazoo = new KazooConstructor();
            kazoo.init();
            return kazoo.call('lobby_user_auth/validateUserName', 'PUT', jsonCall)
                .done(function(result){
                    var validationResult = {
                        emailAddressFound : result.data.data.emailAddressFound,
                        lobbyUserId : result.data.data.lobbyUserId
                    };

                    deferredObject.resolve(validationResult);
                })
                .fail(function(error) {
                    deferredObject.reject(error);
                });
        });
    }

    return {
        validateUsername : validateUsername
    };
});

