define('constants/secretQuestionEnumerations',[], function() {
    return {
        city_mother_and_father_met : "In what city or town did your mother and father meet?",
        sixth_grade_school: "What school did you attend for sixth grade?",
        first_pet: "What was the name of your first pet?",
        nickname: "What was your childhood nickname?",
        high_school_mascot: "What was your high school mascot?"
    };
});

