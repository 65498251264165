define('businessServices/authentication/sessionWorkflowFlags',['businessServices/authentication/stores/sessionWorkflowFlagsStore'], function(sessionWorkflowFlagsStore) {
    var _workflowItems = {
        hasBypassedPastDueBalance : null,
        hasBypassedMiscProblems : null,
        hasBypassedClosedAccount : null
    };
    var originalPathname = null;

    function receiveAlertLoginSuccessful(loginInfo) {
        sessionWorkflowFlagsStore.setStoreForLoginType(loginInfo.loginType);
        var existingFlagInfo = sessionWorkflowFlagsStore.getValue();

        if (existingFlagInfo) {
            _workflowItems.hasBypassedMiscProblems = existingFlagInfo.hasBypassedMiscProblems;
            _workflowItems.hasBypassedPastDueBalance = existingFlagInfo.hasBypassedPastDueBalance;
            _workflowItems.hasBypassedClosedAccount = existingFlagInfo.hasBypassedClosedAccount;
        } else {
            _workflowItems.hasBypassedMiscProblems = false;
            _workflowItems.hasBypassedPastDueBalance = false;
            _workflowItems.hasBypassedClosedAccount = false;
        }
    }

    function save() {
        sessionWorkflowFlagsStore.saveValue(_workflowItems);
    }

    function receiveAlertLogout() {
        _workflowItems.hasBypassedPastDueBalance = null;
        _workflowItems.hasBypassedMiscProblems = null;
        _workflowItems.hasBypassedClosedAccount = null;
        sessionWorkflowFlagsStore.deleteValue();
    }

    function hasBypassedPastDueBalance(setValue) {
        if (setValue === undefined) {
            return _workflowItems.hasBypassedPastDueBalance;
        } else {
            _workflowItems.hasBypassedPastDueBalance = setValue;
            save();
        }
    }

    function hasBypassedMiscProblems(setValue) {
        if (setValue === undefined) {
            return _workflowItems.hasBypassedMiscProblems;
        } else {
            _workflowItems.hasBypassedMiscProblems = setValue;
            save();
        }
    }

    function hasBypassedClosedAccount(setValue) {
        if (setValue === undefined) {
            return _workflowItems.hasBypassedClosedAccount;
        } else {
            _workflowItems.hasBypassedClosedAccount = setValue;
            save();
        }
    }

    function getOriginalPathname() {
        return originalPathname;
    }

    function setOriginalPathname(pathname) {
        originalPathname = pathname;
    }

    return {
        hasBypassedMiscProblems : hasBypassedMiscProblems,
        hasBypassedPastDueBalance : hasBypassedPastDueBalance,
        hasBypassedClosedAccount : hasBypassedClosedAccount,
        getOriginalPathname: getOriginalPathname,
        setOriginalPathname: setOriginalPathname,

        alerts : {
            receiveAlertLoginSuccessful : receiveAlertLoginSuccessful,
            receiveAlertLogout : receiveAlertLogout
        }
    };
});

