define('constants/billingHistoryConstants',[], function () {
    const _i18n = require('i18next');

    return {
        outcome: {
            approved: "Approved",
            declined: "Declined",
            refunded: "Refunded",
            statement: "Statement",
            applied: "Applied"
        },
        descriptionI18n:   {
            invoiceGenerated: _i18n.t('billingHistory:invoiceGenerated'),
            refundToCard: _i18n.t('billingHistory:refundToCard'),
            oneTimePayment: _i18n.t('billingHistory:oneTimePayment'),
            autoPay: _i18n.t('billingHistory:autoPay'),
            autoPayRetry: _i18n.t('billingHistory:autoPayRetry'),
            completed: _i18n.t('billingHistory:completed'),
            failed: _i18n.t('billingHistory:failed')
        },
        header: {
            date: 'date',
            transactionType: 'description',
            paymentMethod: 'paymentMethod',
            amount: 'amount',
            status: 'outcome',
        }
    };
});

