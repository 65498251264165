define('presentation/common/subscriberSelection/viewModels/inheritedSubscriberSelectionViewModel',[
    'presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel',
    'common/promises/promiseFactory',
    'common/storage/commonState',
    'i18next'
], function() {
    return function() {
        const self = this;

        const SubscriberSelectionViewModelConstructor = require('presentation/common/subscriberSelection/viewModels/subscriberSelectionViewModel');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');

        const _promiseFactory = new PromiseFactoryConstructor();
        const _commonState = require('common/storage/commonState');
        const _i18n = require('i18next');

        const DEFAULT_TEXT = "default";

        self.addItemTitle = ko.observable(DEFAULT_TEXT);
        self.removeItemTitle = ko.observable(DEFAULT_TEXT);
        self.lastItemRemoveTitle = ko.observable(DEFAULT_TEXT);
        self.subscriberLogTitle = ko.observable(DEFAULT_TEXT);
        self.subscriberLogPermissionTitle = ko.observable(DEFAULT_TEXT);
        self.modelStateObserver = null;

        self.inheritedSubscriberList = ko.observable();
        self.isPermissionEnabled = ko.observable(false);
        self.isPermissionInherited = ko.observable(false);
        self.isInboundCallingEnabled = ko.observable(false);
        self.inheritedSubscriptionsDescription = ko.observable("");
        self.yellowBoxInheritedAction = ko.observable("");
        self.yellowBoxAlternateInheritedAction = ko.observable("");
        self.yellowBoxManualOption = ko.observable("");
        self.yellowBoxGrantedPermission = ko.observable("");
        self.inheritedPermissionChecklist = ko.observableArray([]);

        self.subscriberSelectionViewModel = ko.observable();

        self.showMembershipControl = ko.pureComputed(() => !self.isPermissionInherited() && self.isPermissionEnabled());
        self.showInheritedPermissionChecklist = ko.pureComputed(() => self.inheritedPermissionChecklist().length > 0);
        self.showSubscriberListEmptyState = ko.pureComputed(() => self.inheritedSubscribers().length === 0 );
        self.inboundCallingStatusMessage = ko.pureComputed(() => {
            return self.isInboundCallingEnabled() ?
                _i18n.t('subscriberSelection:enabledButNoPermissions') :
                _i18n.t('subscriberSelection:currentlyDisabled');
        });

        self.inheritedSubscribers = ko.pureComputed(() => {
            return self.inheritedSubscriberList().map((subscriber) => {
                let commonStateItem = _commonState.get(subscriber.id);
                return {
                    id: commonStateItem.id,
                    subscriberType: commonStateItem.type,
                    displayName: commonStateItem.name(),
                    avatar: commonStateItem.type === _commonState.types.user ? commonStateItem.avatar() : commonStateItem.avatars()
                };
            });
        });

        self.warningMessages = ko.pureComputed(() => {
            return [
                {
                    message: _i18n.t('inheritedSubscriberSelection:noInheritedActors', {
                        inboundCallingStatus: self.inboundCallingStatusMessage(),
                        inheritedAction: self.yellowBoxInheritedAction()
                    }), listItems: []
                },
                {
                    message: _i18n.t('inheritedSubscriberSelection:toAllowAction', {
                        inheritedAction: self.yellowBoxInheritedAction()
                    }),
                    listItems: [
                        _i18n.t('inheritedSubscriberSelection:noInheritedActorsBulletOne', {
                            inheritedAction: self.yellowBoxInheritedAction()
                        }),
                        _i18n.t('inheritedSubscriberSelection:noInheritedActorsBulletTwo', {
                            manualOption: self.yellowBoxManualOption(),
                            grantedPermission: self.yellowBoxGrantedPermission(),
                            inheritedAction: self.yellowBoxInheritedAction()
                        })
                    ]
                }
            ];
        });

        self.getSubscribers = () => {};
        self.setSubscribersLog = () => {};
        self.validate = () => {};

        self.isUser = (subscriberData) => {
            return _commonState.types.user === subscriberData.subscriberType;
        };

        self.activate = (subscribers, isPermissionEnabled, isPermissionInherited) => {
            return _initialize(subscribers, isPermissionEnabled, isPermissionInherited);
        };

        const _initialize = (subscribers, isPermissionEnabled, isPermissionInherited) => {
            if (ko.isObservable(isPermissionEnabled)) {
                self.isPermissionEnabled = isPermissionEnabled;
            } else {
                self.isPermissionEnabled(isPermissionEnabled);
            }

            if (ko.isObservable(isPermissionInherited)) {
                self.isPermissionInherited = isPermissionInherited;
            } else {
                self.isPermissionInherited(isPermissionInherited);
            }

            let subscriberSelectionViewModel = new SubscriberSelectionViewModelConstructor();
            subscriberSelectionViewModel.addItemTitle = self.addItemTitle;
            subscriberSelectionViewModel.removeItemTitle = self.removeItemTitle;
            subscriberSelectionViewModel.lastItemRemoveTitle = self.lastItemRemoveTitle;
            subscriberSelectionViewModel.subscriberLogTitle = self.subscriberLogTitle;
            subscriberSelectionViewModel.subscriberLogPermissionTitle = self.subscriberLogPermissionTitle;
            self.getSubscribers = subscriberSelectionViewModel.getSubscribers;
            self.setSubscribersLog = subscriberSelectionViewModel.setSubscribersLog;

            self.subscriberSelectionViewModel(subscriberSelectionViewModel);

            _promiseFactory.defer((deferredObject) => {
                self.subscriberSelectionViewModel().activate(subscribers, isPermissionEnabled, self.showMembershipControl)
                    .fail(deferredObject.reject)
                    .done(() => {
                        self.modelStateObserver = subscriberSelectionViewModel.modelStateObserver;
                        self.validate = subscriberSelectionViewModel.validate;
                        deferredObject.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});
