define('presentation/settings/messagingCompliance/viewModels/messagingComplianceProfileTabViewModel',[
        'businessServices/converters/textConverter',
        'businessServices/country/countryRegions',
        'businessServices/state/modelStateObserver',
        'common/collections/collectionSorter',
        'common/collections/enumerationKeyValueConverter',
        'common/promises/promiseFactory',
        'constants/countryEnumerations',
        'constants/formOfBusinessEnumerations',
        'constants/industryEnumerations',
        'constants/smsCampaignRegistrationConstants',
        'constants/stockExchangeEnumerations',
        'i18next',
        'presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject',
        'presentation/settings/messagingCompliance/validators/messagingComplianceProfileTabValidator'
    ],
    function (
        /** @type typeof import('businessServices/converters/textConverter') */
        TextConverter,
        /** @type typeof import('businessServices/country/countryRegions') */
        CountryRegionsConstructor,
        /** @type typeof import('businessServices/state/modelStateObserver') */
        ModelStateObserver,
        /** @type typeof import('common/collections/collectionSorter') */
        CollectionSorter,
        /** @type import('common/collections/enumerationKeyValueConverter') */
        _keyValueConverter,
        /** @type typeof import('common/promises/promiseFactory') */
        PromiseFactory,
        /** @type typeof import('constants/countryEnumerations') */
        _countries,
        /** @type typeof import('constants/formOfBusinessEnumerations') */
        _formOfBusiness,
        /** @type typeof import('constants/industryEnumerations') */
        _industries,
        /** @type typeof import('constants/smsCampaignRegistrationConstants') */
        _smsCampaignRegistrationConstants,
        /** @type typeof import('constants/stockExchangeEnumerations') */
        _stockExchanges,
        /** @type import('i18next') */
        _i18n,
        /** @type typeof import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject') */
        MessagingComplianceProfilePresentationObject
    ) {
        return function (/** @type { KnockoutObservable<boolean> } */ isEditMode, /** @type { KnockoutObservable<number> } */ tcrRegistrationStatus) {
            /** @typedef { import('presentation/settings/messagingCompliance/presentationObjects/messagingComplianceProfilePresentationObject') } MessagingComplianceProfilePresentationObject } */
            /** @typedef { import('presentation/settings/messagingCompliance/validators/messagingComplianceProfileTabValidator') } MessagingComplianceProfileTabValidator } */
            /** @typedef { import('presentation/settings/messagingCompliance/viewModels/messagingComplianceProfileTabViewModel') } MessagingComplianceProfileTabViewModel */

            /** @type { MessagingComplianceProfileTabViewModel } */
            const self = this;

            const _countryRegions = new CountryRegionsConstructor();
            const _promiseFactory = new PromiseFactory();
            const _sorter = new CollectionSorter();
            const _textConverter = new TextConverter();

            /** @type { IDisposable[] } */
            let _disposables = [];

            /** @type { MessagingComplianceProfileTabValidator } */
            let _validator = null;

            const _onCountryChange = (/** @type { string } */ countryCode) => {
                self.zipOrPostalCode("");
                self.zipOrPostalCode.isValid(null);
                self.stateOrProvince(null);
                self.stateOrProvince.isValid(null);
                self.stateOrProvinceOfIncorporation(null);
                self.stateOrProvinceOfIncorporation.isValid(null);
                self.statesProvinces(_countryRegions.findByAbbreviation(countryCode).statesProvinces);

                if (self.isSameAsBusinessAddress()) {
                    _onBusinessAddressChange();
                }
            };

            const _onFormOfBusinessChange = (/** @type { string } */ newFormOfBusiness, /** @type { string } */ previousFormOfBusiness) => {
                const soleProprietor = _formOfBusiness.SoleProprietor.toString();
                if (previousFormOfBusiness === soleProprietor && newFormOfBusiness !== soleProprietor) {
                    self.accountHolderFirstName("");
                    self.accountHolderFirstName.isValid(null);
                    self.accountHolderLastName("");
                    self.accountHolderLastName.isValid(null);
                }

                if (previousFormOfBusiness !== soleProprietor && newFormOfBusiness === soleProprietor) {
                    self.businessName("");
                    self.businessName.isValid(null);
                    self.brandName("");
                    self.brandName.isValid(null);
                    self.stateOrProvinceOfIncorporation("");
                    self.stateOrProvinceOfIncorporation.isValid(null);
                    self.einTaxId("");
                    self.einTaxId.isValid(null);
                    self.registryId("");
                    self.businessNumber("");
                    self.businessNumber.isValid(null);
                    self.exchange("");
                    self.exchange.isValid(null);
                    self.stockSymbol("");
                    self.stockSymbol.isValid(null);
                    self.industry("");
                    self.industry.isValid(null);
                }
            };

            const _onPointOfContactCountryChange = (/** @type { string } */ countryCode) => {
                self.pointOfContactZipOrPostalCode("");
                self.pointOfContactZipOrPostalCode.isValid(null);
                self.pointOfContactStateOrProvince(null);
                self.pointOfContactStateOrProvince.isValid(null);
                self.pointOfContactStatesProvinces(_countryRegions.findByAbbreviation(countryCode).statesProvinces);
            };

            const _onSameAsBusinessAddressChange = (/** @type { boolean } */ isSameAsBusinessAddress) => {
                if (isSameAsBusinessAddress) {
                    self.pointOfContactPhoneNumber(self.phoneNumber());
                    self.pointOfContactPhoneNumberCountryAbbreviation(self.phoneNumberCountryAbbreviation());
                    self.pointOfContactCountry(self.country());
                    const statesProvinces = _countryRegions.findByAbbreviation(self.country()).statesProvinces;
                    self.pointOfContactStatesProvinces(statesProvinces);
                    self.pointOfContactAddressLineOne(self.addressLineOne());
                    self.pointOfContactAddressLineTwo(self.addressLineTwo());
                    self.pointOfContactCity(self.city());
                    // in this scenario pointOfContactStateOrProvince is a textBox
                    const stateOrProvince = statesProvinces.find(region => region.abbreviation === self.stateOrProvince());
                    self.pointOfContactStateOrProvince(stateOrProvince ? stateOrProvince.name : "");
                    self.pointOfContactZipOrPostalCode(self.zipOrPostalCode());
                } else {
                    self.pointOfContactStateOrProvince(self.stateOrProvince());
                }
            };

            const _onBusinessAddressChange = () => {
                if (self.isSameAsBusinessAddress()) {
                    self.pointOfContactPhoneNumber.forceUpdate(true);
                    self.pointOfContactPhoneNumber(self.phoneNumber());
                    self.pointOfContactPhoneNumberCountryAbbreviation.forceUpdate(true);
                    self.pointOfContactPhoneNumberCountryAbbreviation(self.phoneNumberCountryAbbreviation());
                    self.pointOfContactCountry(self.country());
                    const statesProvinces = _countryRegions.findByAbbreviation(self.country()).statesProvinces;
                    self.pointOfContactStatesProvinces(statesProvinces);
                    self.pointOfContactAddressLineOne.forceUpdate(true);
                    self.pointOfContactAddressLineOne(self.addressLineOne());
                    self.pointOfContactAddressLineTwo.forceUpdate(true);
                    self.pointOfContactAddressLineTwo(self.addressLineTwo());
                    self.pointOfContactCity.forceUpdate(true);
                    self.pointOfContactCity(self.city());
                    // in this scenario pointOfContactStateOrProvince is a textBox
                    self.pointOfContactStateOrProvince.forceUpdate(true);
                    const stateOrProvince = statesProvinces.find(region => region.abbreviation === self.stateOrProvince());
                    self.pointOfContactStateOrProvince(stateOrProvince ? stateOrProvince.name : "");
                    self.pointOfContactZipOrPostalCode.forceUpdate(true);
                    self.pointOfContactZipOrPostalCode(self.zipOrPostalCode());
                }
            };

            const _trimEIN = (/** @type { string } */ einTaxId) => {
                const numericEinTaxId = einTaxId.replaceAll(/[^0-9]/g, "");
                const trimmedEinTaxId = numericEinTaxId.substr(0, 9);
                const forceUpdate = einTaxId !== trimmedEinTaxId;

                if (forceUpdate) {
                    self.einTaxId.forceUpdate(true);
                    self.einTaxId(trimmedEinTaxId);
                }
            };

            const _trimRegistryId = (/** @type { string } */ registryId) => {
                const trimmedRegistryId = registryId.substr(0, 21);
                const forceUpdate = registryId !== trimmedRegistryId;

                if (forceUpdate) {
                    self.registryId.forceUpdate(true);
                    self.registryId(trimmedRegistryId);
                }
            };

            const _trimBusinessNumber = (/** @type { string } */ businessNumber) => {
                const trimmedBusinessNumber = businessNumber.substr(0, 21);
                const forceUpdate = businessNumber !== trimmedBusinessNumber;

                if (forceUpdate) {
                    self.businessNumber.forceUpdate(true);
                    self.businessNumber(trimmedBusinessNumber);
                }
            };

            self.formOfBusinessTypes = _keyValueConverter.convertToKeyValues(_formOfBusiness).map((option) => {
                const i18nKey = _textConverter.camelCase(option.key);
                return { key: option.value, title: _i18n.t(`messagingComplianceProfileTab:${i18nKey}`), value: option.value };
            });
            _sorter.sort(self.formOfBusinessTypes, "value", true);

            self.industries = _keyValueConverter.convertToKeyValues(_industries).map((option) => {
                const i18nKey = _textConverter.camelCase(option.key);
                return { key: option.value, title: _i18n.t(`messagingComplianceProfileTab:${i18nKey}`), value: option.value };
            });

            self.exchanges = _keyValueConverter.convertToKeyValues(_stockExchanges).map((option) => {
                const i18nKey = _textConverter.camelCase(option.key);
                return { key: option.value, title: _i18n.t(`messagingComplianceProfileTab:${i18nKey}`), value: option.value };
            });

            self.isCompositionComplete = ko.observable(false);
            self.modelStateObserver = null;
            self.profileData = null;

            self.countries = [];
            self.pointOfContactCountries = [];
            self.pointOfContactStatesProvinces = ko.observableArray([]);
            self.statesProvinces = ko.observableArray([]);

            self.smsComplianceProfileId = null;
            self.accountHolderFirstName = ko.observable("").extend({observeState: true});
            self.accountHolderLastName = ko.observable("").extend({observeState: true});
            self.businessName = ko.observable("").extend({observeState: true});
            self.brandName = ko.observable("").extend({observeState: true});
            self.phoneNumber = ko.observable("").extend({observeState: true});
            self.phoneNumberCountryAbbreviation = ko.observable(_countries.usa).extend({observeState: true});
            self.websiteUrl = ko.observable("").extend({observeState: true});

            self.addressLineOne = ko.observable("").extend({observeState: true});
            self.addressLineTwo = ko.observable("").extend({observeState: true});
            self.city = ko.observable("").extend({observeState: true});
            self.country = ko.observable("").extend({observeState: true});
            self.stateOrProvince = ko.observable("").extend({observeState: true});
            self.zipOrPostalCode = ko.observable("").extend({observeState: true});

            self.formOfBusiness = ko.observable().extend({observeState: true});
            self.stateOrProvinceOfIncorporation = ko.observable("").extend({observeState: true});
            self.stockSymbol = ko.observable("").extend({observeState: true});
            self.industry = ko.observable().extend({observeState: true});
            self.exchange = ko.observable().extend({observeState: true});
            self.einTaxId = ko.observable("").extend({observeState: true});
            self.registryId = ko.observable("").extend({observeState: true});
            self.businessNumber = ko.observable("").extend({observeState: true});

            self.isSameAsBusinessAddress = ko.observable(false);
            self.pointOfContactFirstName = ko.observable("").extend({observeState: true});
            self.pointOfContactLastName = ko.observable("").extend({observeState: true});
            self.pointOfContactJobTitle = ko.observable("").extend({observeState: true});
            self.pointOfContactEmailAddress = ko.observable("").extend({observeState: true});
            self.pointOfContactPhoneNumber = ko.observable("").extend({observeState: true});
            self.pointOfContactPhoneNumberCountryAbbreviation = ko.observable(_countries.usa).extend({observeState: true});
            self.pointOfContactCountry = ko.observable("").extend({observeState: true});
            self.pointOfContactAddressLineOne = ko.observable("").extend({observeState: true});
            self.pointOfContactAddressLineTwo = ko.observable("").extend({observeState: true});
            self.pointOfContactCity = ko.observable("").extend({observeState: true});
            self.pointOfContactStateOrProvince = ko.observable("").extend({observeState: true});
            self.pointOfContactZipOrPostalCode = ko.observable("").extend({observeState: true});

            self.authorizedSignature = ko.observable("").extend({observeState: true});

            self.isEditMode = isEditMode;
            self.tcrRegistrationStatus = tcrRegistrationStatus;

            self.registryIdTooltip = ko.pureComputed(() => {
                return self.isBrandVerified() ?
                    _i18n.t('messagingComplianceProfileTab:verifiedBrandTooltip') :
                    _i18n.t('messagingComplianceProfileTab:registryIdTooltip');
            });

            self.verifiedBrandTooltip = _i18n.t('messagingComplianceProfileTab:verifiedBrandTooltip');

            self.isSoleProprietor = ko.pureComputed(() => {
                return self.formOfBusiness() === _formOfBusiness.SoleProprietor.toString();
            });

            self.isFormOfBusinessSelected = ko.pureComputed(() => {
                return self.formOfBusiness() !== "";
            });

            self.isBrandVerified = ko.pureComputed(() => {
                return self.tcrRegistrationStatus() === _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.SUCCEEDED;
            });

            self.isBrandCreated = ko.pureComputed(() => {
                return self.tcrRegistrationStatus() && self.tcrRegistrationStatus() !== _smsCampaignRegistrationConstants.smsAccountTcrRegistrationStatuses.NONE;
            });

            self.isPointOfContactNameDisabled = ko.pureComputed(() => {
                if (self.isSoleProprietor()) {
                    return self.isSameAsBusinessAddress();
                }

                return false;
            });

            self.countryLabelI18nKey = ko.pureComputed(() => {
                return self.isSoleProprietor() || !self.isFormOfBusinessSelected() ?
                    'messagingComplianceProfileTab:labelCountry' :
                    'messagingComplianceProfileTab:labelCountryOfRegistration';
            });

            self.stateOrProvinceLabelI18nKey = ko.pureComputed(() => {
                return (self.country() === _countries.ca) ?
                    'messagingComplianceProfileTab:labelProvince' :
                    'messagingComplianceProfileTab:labelState';
            });

            self.stateOrProvinceOfIncorporationLabelI18nKey = ko.pureComputed(() => {
                return (self.country() === _countries.ca) ?
                    'messagingComplianceProfileTab:labelProvinceOfIncorporation' :
                    'messagingComplianceProfileTab:labelStateOfIncorporation';
            });

            self.zipOrPostalCodeMetaData = ko.pureComputed(() => {
                return (self.country() === _countries.ca) ?
                    {i18nLabelKey: 'messagingComplianceProfileTab:labelPostalCode', type: 'postalcode', maxLength: 6} :
                    {i18nLabelKey: 'messagingComplianceProfileTab:labelZipCode', type: 'zipcode', maxLength: 5};
            });

            self.pointOfContactStateOrProvinceLabelI18nKey = ko.pureComputed(() => {
                return (self.pointOfContactCountry() === _countries.ca) ?
                    'messagingComplianceProfileTab:labelProvince' :
                    'messagingComplianceProfileTab:labelState';
            });

            self.pointOfContactZipOrPostalCodeMetaData = ko.pureComputed(() => {
                return (self.pointOfContactCountry() === _countries.ca) ?
                    {i18nLabelKey: 'messagingComplianceProfileTab:labelPostalCode', type: 'postalcode', maxLength: 6} :
                    {i18nLabelKey: 'messagingComplianceProfileTab:labelZipCode', type: 'zipcode', maxLength: 5};
            });

            self.selectPlaceHolderMessage = ko.pureComputed(() => _i18n.t("messagingComplianceProfileTab:select"));

            self.showEinTaxId = ko.pureComputed(() => {
                return self.country() === _countries.usa && !self.isSoleProprietor();
            });

            self.showRegistryId = ko.pureComputed(() => {
                return self.country() === _countries.ca && !self.isSoleProprietor();
            });

            self.showBusinessNumber = ko.pureComputed(() => {
                return self.country() === _countries.ca && !self.isSoleProprietor();
            });

            self.showStockSymbolExchange = ko.pureComputed(() => {
                return self.formOfBusiness() === _formOfBusiness.PubliclyTraded.toString();
            });

            self.showNonProfitMessage = ko.pureComputed(() => {
                return self.isFormOfBusinessSelected() && self.formOfBusiness() === _formOfBusiness.Charity.toString();
            });

            self.validate = () => _validator.validate();

            self.resetValidation = () => {
                _validator.resetValidation();
            };

            self.cancelForm = () => {
                if (self.profileData !== null) {
                    self.formOfBusiness(self.profileData.formOfBusiness());
                }
                if (self.accountHolderFirstName.isDirty) {
                    self.accountHolderFirstName.forceUpdate(true);
                }
                if (self.accountHolderLastName.isDirty) {
                    self.accountHolderLastName.forceUpdate(true);
                }
                if (self.businessName.isDirty) {
                    self.businessName.forceUpdate(true);
                }
                if (self.brandName.isDirty) {
                    self.brandName.forceUpdate(true);
                }
                if (self.addressLineOne.isDirty) {
                    self.addressLineOne.forceUpdate(true);
                }
                if (self.addressLineTwo.isDirty) {
                    self.addressLineTwo.forceUpdate(true);
                }
                if (self.city.isDirty) {
                    self.city.forceUpdate(true);
                }
                if (self.zipOrPostalCode.isDirty) {
                    self.zipOrPostalCode.forceUpdate(true);
                }
                if (self.stockSymbol.isDirty) {
                    self.stockSymbol.forceUpdate(true);
                }
                if (self.einTaxId.isDirty) {
                    self.einTaxId.forceUpdate(true);
                }
                if (self.registryId.isDirty) {
                    self.registryId.forceUpdate(true);
                }
                if (self.businessNumber.isDirty) {
                    self.businessNumber.forceUpdate(true);
                }
                if (self.websiteUrl.isDirty) {
                    self.websiteUrl.forceUpdate(true);
                }
                if (self.pointOfContactFirstName.isDirty) {
                    self.pointOfContactFirstName.forceUpdate(true);
                }
                if (self.pointOfContactLastName.isDirty) {
                    self.pointOfContactLastName.forceUpdate(true);
                }
                if (self.pointOfContactJobTitle.isDirty) {
                    self.pointOfContactJobTitle.forceUpdate(true);
                }
                if (self.pointOfContactEmailAddress.isDirty) {
                    self.pointOfContactEmailAddress.forceUpdate(true);
                }
                if (self.pointOfContactPhoneNumber.isDirty) {
                    self.pointOfContactPhoneNumber.forceUpdate(true);
                }
                if (self.pointOfContactAddressLineOne.isDirty) {
                    self.pointOfContactAddressLineOne.forceUpdate(true);
                }
                if (self.pointOfContactAddressLineTwo.isDirty) {
                    self.pointOfContactAddressLineTwo.forceUpdate(true);
                }
                if (self.pointOfContactCity.isDirty) {
                    self.pointOfContactCity.forceUpdate(true);
                }
                if (self.pointOfContactZipOrPostalCode.isDirty) {
                    self.pointOfContactZipOrPostalCode.forceUpdate(true);
                }

            };


            self.getForm = () => {
                const messagingComplianceProfile = new MessagingComplianceProfilePresentationObject();
                messagingComplianceProfile.smsComplianceProfileId = self.smsComplianceProfileId;
                messagingComplianceProfile.accountHolderFirstName = self.accountHolderFirstName;
                messagingComplianceProfile.accountHolderLastName = self.accountHolderLastName;
                messagingComplianceProfile.businessName  = self.businessName;
                messagingComplianceProfile.brandName = self.brandName;
                messagingComplianceProfile.country = self.country;
                messagingComplianceProfile.addressLineOne = self.addressLineOne;
                messagingComplianceProfile.addressLineTwo = self.addressLineTwo;
                messagingComplianceProfile.city = self.city;
                messagingComplianceProfile.stateProvince = self.stateOrProvince;
                messagingComplianceProfile.postalCode = self.zipOrPostalCode;
                messagingComplianceProfile.formOfBusiness = self.formOfBusiness;
                messagingComplianceProfile.stateProvinceOfIncorporation = self.stateOrProvinceOfIncorporation;
                messagingComplianceProfile.stockSymbol = self.stockSymbol;
                messagingComplianceProfile.industry = self.industry;
                messagingComplianceProfile.exchange = self.exchange;
                messagingComplianceProfile.einTaxId = self.einTaxId;
                messagingComplianceProfile.registryId = self.registryId;
                messagingComplianceProfile.businessNumber = self.businessNumber;
                messagingComplianceProfile.phoneNumber = self.phoneNumber;
                messagingComplianceProfile.phoneNumberCountryAbbreviation = self.phoneNumberCountryAbbreviation;
                messagingComplianceProfile.websiteUrl = self.websiteUrl;
                messagingComplianceProfile.pointOfContactFirstName = self.pointOfContactFirstName;
                messagingComplianceProfile.pointOfContactLastName = self.pointOfContactLastName;
                messagingComplianceProfile.pointOfContactJobTitle = self.pointOfContactJobTitle;
                messagingComplianceProfile.pointOfContactEmailAddress = self.pointOfContactEmailAddress;
                messagingComplianceProfile.pointOfContactPhoneNumber = self.pointOfContactPhoneNumber;
                messagingComplianceProfile.pointOfContactPhoneNumberCountryAbbreviation = self.pointOfContactPhoneNumberCountryAbbreviation;
                messagingComplianceProfile.pointOfContactCountry = self.pointOfContactCountry;
                messagingComplianceProfile.pointOfContactAddressLineOne = self.pointOfContactAddressLineOne;
                messagingComplianceProfile.pointOfContactAddressLineTwo = self.pointOfContactAddressLineTwo;
                messagingComplianceProfile.pointOfContactCity = self.pointOfContactCity;
                messagingComplianceProfile.pointOfContactStateOrProvince = self.pointOfContactStateOrProvince;
                messagingComplianceProfile.pointOfContactZipOrPostalCode = self.pointOfContactZipOrPostalCode;
                return messagingComplianceProfile;
            };

            self.dispose = () => {
                _disposables.forEach(s => s.dispose());
                _disposables = [];

                self.isCompositionComplete(false);
            };

            self.compositionComplete = () => {
                self.isCompositionComplete(true);
            };

            self.activate = (/** @type { MessagingComplianceProfilePresentationObject } */ profileData) => {
                let modelStateObserver = new ModelStateObserver(self);

                const ValidatorConstructor = require('presentation/settings/messagingCompliance/validators/messagingComplianceProfileTabValidator');
                _validator = new ValidatorConstructor();

                return _initialize(profileData, modelStateObserver);
            };

            const _initialize = (profileData, modelStateObserver) => {

                self.profileData = profileData;
                self.countries = _countryRegions.countryList;
                self.country(_countryRegions.findByAbbreviation(_countries.usa).countryAbbreviation);
                self.statesProvinces(_countryRegions.findByAbbreviation(self.country()).statesProvinces);
                self.pointOfContactCountries = _countryRegions.countryList;
                self.pointOfContactCountry(_countryRegions.findByAbbreviation(_countries.usa).countryAbbreviation);
                self.pointOfContactStatesProvinces(_countryRegions.findByAbbreviation(self.pointOfContactCountry()).statesProvinces);

                if (profileData) {
                    self.smsComplianceProfileId = profileData.smsComplianceProfileId;
                    _initializeObservables(profileData);
                }

                self.modelStateObserver = modelStateObserver;
                self.modelStateObserver.commitData();

                _validator.registerViewModel(self);

                _disposables.push(self.country.subscribe(_onCountryChange));
                _disposables.push(self.pointOfContactCountry.subscribe(_onPointOfContactCountryChange));
                _disposables.push(self.einTaxId.subscribe(_trimEIN));
                _disposables.push(self.registryId.subscribe(_trimRegistryId));
                _disposables.push(self.businessNumber.subscribe(_trimBusinessNumber));
                _disposables.push(self.formOfBusiness.subscribePrevious(_onFormOfBusinessChange));

                _disposables.push(self.isSameAsBusinessAddress.subscribe(_onSameAsBusinessAddressChange));
                _disposables.push(self.phoneNumber.subscribe(_onBusinessAddressChange));
                _disposables.push(self.phoneNumberCountryAbbreviation.subscribe(_onBusinessAddressChange));
                _disposables.push(self.addressLineOne.subscribe(_onBusinessAddressChange));
                _disposables.push(self.addressLineTwo.subscribe(_onBusinessAddressChange));
                _disposables.push(self.city.subscribe(_onBusinessAddressChange));
                _disposables.push(self.stateOrProvince.subscribe(_onBusinessAddressChange));
                _disposables.push(self.zipOrPostalCode.subscribe(_onBusinessAddressChange));

                return _promiseFactory.wait();
            };

            const _initializeObservables = (profileData) => {
                if (profileData.formOfBusiness()) {
                    self.formOfBusiness(profileData.formOfBusiness());
                }
                self.accountHolderFirstName(profileData.accountHolderFirstName());
                self.accountHolderLastName(profileData.accountHolderLastName());
                self.businessName(profileData.businessName());
                self.brandName(profileData.brandName());
                self.stateOrProvinceOfIncorporation(profileData.stateProvinceOfIncorporation());
                self.stockSymbol(profileData.stockSymbol());
                self.industry(profileData.industry());
                self.exchange(profileData.exchange());
                self.einTaxId(profileData.einTaxId());
                self.registryId(profileData.registryId());
                self.businessNumber(profileData.businessNumber());
                if (profileData.phoneNumber()) {
                    self.phoneNumber(profileData.phoneNumber());
                }
                if (profileData.phoneNumberCountryAbbreviation()) {
                    const phoneNumberCountry = _countryRegions.findByAbbreviation(profileData.phoneNumberCountryAbbreviation()).countryAbbreviation;
                    self.phoneNumberCountryAbbreviation(phoneNumberCountry);
                }
                if (profileData.country()) {
                    self.country(_countryRegions.findByAbbreviation(profileData.country()).countryAbbreviation);
                    self.statesProvinces = ko.observableArray(_countryRegions.findByAbbreviation(profileData.country()).statesProvinces);
                }
                self.addressLineOne(profileData.addressLineOne());
                self.addressLineTwo(profileData.addressLineTwo());
                self.city(profileData.city());
                self.stateOrProvince(profileData.stateProvince());
                self.zipOrPostalCode(profileData.postalCode());
                self.websiteUrl(profileData.websiteUrl());
                self.pointOfContactFirstName(profileData.pointOfContactFirstName());
                self.pointOfContactLastName(profileData.pointOfContactLastName());
                self.pointOfContactJobTitle(profileData.pointOfContactJobTitle());
                self.pointOfContactEmailAddress(profileData.pointOfContactEmailAddress());
                self.pointOfContactPhoneNumber(profileData.pointOfContactPhoneNumber());
                if (profileData.pointOfContactPhoneNumberCountryAbbreviation()) {
                    const pointOfContactPhoneNumberCountry = _countryRegions.findByAbbreviation(profileData.pointOfContactPhoneNumberCountryAbbreviation()).countryAbbreviation;
                    self.pointOfContactPhoneNumberCountryAbbreviation(pointOfContactPhoneNumberCountry);
                }
                if (profileData.pointOfContactCountry()) {
                    self.pointOfContactCountry(_countryRegions.findByAbbreviation(profileData.pointOfContactCountry()).countryAbbreviation);
                    self.pointOfContactStatesProvinces = ko.observableArray(_countryRegions.findByAbbreviation(profileData.pointOfContactCountry()).statesProvinces);
                }
                self.pointOfContactAddressLineOne(profileData.pointOfContactAddressLineOne());
                self.pointOfContactAddressLineTwo(profileData.pointOfContactAddressLineTwo());
                self.pointOfContactCity(profileData.pointOfContactCity());
                self.pointOfContactStateOrProvince(profileData.pointOfContactStateOrProvince());
                self.pointOfContactZipOrPostalCode(profileData.pointOfContactZipOrPostalCode());
            };
        };
    });
