define('presentation/common/toggleButton/viewModels/toggleButtonViewModel',[],function() {
    return function() {
        const self = this;

        let _settings = null;

        self.toggleValue = ko.observable(false);
        self.isDisabled = ko.observable(false);

        self.flip = () => {
            if (self.isDisabled()) {
                return;
            }

            self.toggleValue(!self.toggleValue());
        };

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.hasOwnProperty("toggleValue")) {
                self.toggleValue = _settings.toggleValue;
            }

            if (_settings.hasOwnProperty("isDisabled")) {
                if (ko.isObservable(_settings.isDisabled)) {
                    self.isDisabled = _settings.isDisabled;
                } else {
                    self.isDisabled(_settings.isDisabled);
                }
            }

        };
    };
});

