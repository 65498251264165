/**
 * @module persistence/fileStores/fileManagement/fileReader
 */
define('persistence/fileStores/fileManagement/fileReader',['common/promises/promiseFactory', 'persistence/fileStores/fileManagement/fileReaderResult'], function() {
    /**
     * @constructor
     */
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        /**
         * Reads file information from a file handle
         * @param fileHandle the file html5 handle of the file to be read
         * @returns {Promise<module:persistence/fileStores/fileManagement/fileReaderResult>} fully populated
         */
        self.readFile = function(fileHandle) {
            var FileReaderResultConstructor = require('persistence/fileStores/fileManagement/fileReaderResult');

            var promise = _promiseFactory.defer(function(deferredObject) {
                var fileInfo = new FileReaderResultConstructor();
                fileInfo.fileSize = fileHandle.size;
                fileInfo.fileName = fileHandle.name;
                fileInfo.fileEncodingType = fileHandle.type;

                // FileReader is an HTML5 implementation
                var reader = new FileReader();
                reader.readAsArrayBuffer(fileHandle);
                reader.onabort = function() {
                    var abortError = new Error("Received Abort when reading file");
                    deferredObject.reject(abortError);
                };
                reader.onerror = function(error) {
                    deferredObject.reject(error);
                };
                reader.onloadend = function() {
                    fileInfo.fileContent = reader.result;
                    deferredObject.resolve(fileInfo);
                };
            });

            return promise.promise();
        };

        /**
         * Retrieves the metadata of the file without reading the full file contents
         * @param fileHandle the file html5 handle of the file to be read
         * @returns {module:persistence/fileStores/fileManagement/fileReaderResult}
         */
        self.getFileMetadata = function(fileHandle) {
            var FileReaderResultConstructor = require('persistence/fileStores/fileManagement/fileReaderResult');
            var fileInfo = new FileReaderResultConstructor();
            fileInfo.fileSize = fileHandle.size;
            fileInfo.fileName = fileHandle.name;
            fileInfo.fileEncodingType = fileHandle.type;
            fileInfo.fileContent = null;
            return fileInfo;
        };
    };
});
