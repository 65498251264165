define('externalDependencies/configuration/webSocketConfiguration',['settings/settings'], function() {
    var WebSocketConfigurationSingleton = function() {
        if (WebSocketConfigurationSingleton.prototype._singletonInstance) {
            return WebSocketConfigurationSingleton.prototype._singletonInstance;
        }
        WebSocketConfigurationSingleton.prototype._singletonInstance = this;
        var self = this;

        var settings = require('settings/settings');
        self.webSocketServerUrl = settings.externalDependencies.webSocketServerUrl;
    };

    return new WebSocketConfigurationSingleton();
});

