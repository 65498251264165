define('presentation/settings/users/presentationObjects/addUsersPresentationObject',['i18next'],function() {
    return function(){
        const self = this;
        const _i18n = require('i18next');

        self.emailAddress = ko.observable("").extend({observeState: true});
        self.extension = ko.observable("");

        self.billingAdminOptions = ko.observableArray([
            {text: "No", id: "no"},
            {text: "Yes", id: "yes"},
        ]);
        self.billingAdminOption = ko.observable(self.billingAdminOptions()[0].value).extend({observeState: true});
        self.isBillingAdmin = ko.pureComputed(() => self.billingAdminOption() === 'yes');
        self.billingAdminTooltip = _i18n.t('addUserModal:tooltipBilling');

        self.systemAdminOptions = ko.observableArray([
            {text: "No", id: "no"},
            {text: "Yes", id: "yes"},
        ]);
        self.systemAdminOption = ko.observable(self.systemAdminOptions()[0].value).extend({observeState: true});
        self.isSystemAdmin = ko.pureComputed(() => self.systemAdminOption() === 'yes');
        self.systemAdminTooltip = _i18n.t('addUserModal:tooltipSystem');
    };
});

