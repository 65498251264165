define('common/specifications/validPasswordSpecification',[], function() {
    return function() {
        var self = this;

        self.isSatisfiedBy = function(password) {
            var PASSWORD_MIN_LENGTH = 8;
            var PASSWORD_MAX_LENGTH = 32;
            var numbers = password.match(/[0-9]/g);
            var upperCaseLetters = password.match(/[A-Z]/g);
            var lowerCaseLetters = password.match(/[a-z]/g);

            if (password === null || password === undefined) {
                return false;
            }

            if (password.length < PASSWORD_MIN_LENGTH) {
                return false;
            }

            if (password.length > PASSWORD_MAX_LENGTH) {
                return false;
            }

            if(numbers === null) {
                return false;
            }

            if(upperCaseLetters === null) {
                return false;
            }

            if(lowerCaseLetters === null) {
                return false;
            }

            return true;
        };
    };
});

