/* eslint-disable no-case-declarations */
define('presentation/messages/viewModels/messagesViewModel',[
    'businessServices/router/router',
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/messages/messagesStateSingleton',
    'common/promises/promiseFactory',
    'common/storage/machineStorage',
    'constants/transitionDurations',
    'presentation/common/actionModal/viewModels/actionModalViewModel',
    'presentation/common/actionModal/viewModels/welcomeToMessagingActionViewModel',
    'presentation/common/window/windowControl',
    'presentation/messages/dataSources/webMessagingDataSource',
    'presentation/messages/viewModels/conversationViewModel',
    'settings/navigationConfiguration'
], function (
    /** @type typeof import('businessServices/router/router') */
    _router,
    /** @type typeof import('businessServices/authentication/sessionAccountInfo') */
    _sessionAccountInfo,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    _messagesState,
    /** @type typeof import('common/promises/promiseFactory') */
    PromiseFactory,
    /** @type import('common/storage/machineStorage') */
    MachineStorage,
    /** @type typeof import('constants/transitionDurations') */
    TransitionDurations,
    /** @type typeof import('presentation/common/actionModal/viewModels/actionModalViewModel') */
    ActionModalViewModel,
    /** @type typeof import('presentation/common/actionModal/viewModels/welcomeToMessagingActionViewModel') */
    WelcomeToMessagingAction,
    /** @type import('presentation/common/window/windowControl') */
    _windowControl,
    /** @type import('presentation/messages/dataSources/webMessagingDataSource') */
    _webMessagingDataSource,
    /** @type typeof import('presentation/messages/viewModels/conversationViewModel') */
    ConversationViewModel,
    /** @type typeof import('settings/navigationConfiguration') */
    _navigationConfiguration
) {
    return function () {
        const self = this;

        const _actionModal = new ActionModalViewModel();

        /** @type {(typeof PromiseFactory)["prototype"]} */
        let _promiseFactory = null;
        /** @type {IDisposable[]} */
        let _disposables = [];

        const _navigateToConversationId = (/** @type {string} */conversationId) => {
            const conversationUrl = `${_navigationConfiguration.routesById.conversation.baseUrl}/${conversationId}`;
            _router.navigate(conversationUrl);
        };

        const _navigateToConversationDraftId = (/** @type {string} */conversationDraftId) => {
            const newMessageUrl = `${_navigationConfiguration.routesById.newMessage.baseUrl}/${conversationDraftId}`;
            _router.navigate(newMessageUrl);
        };

        const _jumpToConversationMessage = (/** @type {string} */conversationId, /** @type {string} */messageId) => {
            const messageUrl = `${_navigationConfiguration.routesById.conversation.baseUrl}/${conversationId}/${messageId}`;
            _router.navigate(messageUrl);
        };

        const _handleRoute = () => {
            const routeId = _router.activeInstruction().config.customSettings.routeId;

            switch (routeId) {
                case _navigationConfiguration.routesById.messages.routeId:
                    self.showConversationNotFoundMessage(false);
                    const selectedConversationId = _messagesState.selectedConversationId();

                    if (selectedConversationId) {
                        _navigateToConversationId(selectedConversationId);
                        return;
                    }

                    const selectedConversationDraftId = _messagesState.selectedConversationDraftId();

                    if (selectedConversationDraftId) {
                        _navigateToConversationDraftId(selectedConversationDraftId);
                        return;
                    }

                    const [firstConversationDraftId] = self.authorizedSmsConversationDraftIds();

                    if (firstConversationDraftId) {
                        _messagesState.selectedConversationDraftId(firstConversationDraftId);
                        _navigateToConversationDraftId(firstConversationDraftId);
                        return;
                    }

                    const [firstConversationId] = self.authorizedSmsConversationIds();

                    if (firstConversationId) {
                        _messagesState.selectedConversationId(firstConversationId);
                        _navigateToConversationId(firstConversationId);
                        return;
                    }

                    self.selectedConversation(null);
                    break;
                case _navigationConfiguration.routesById.newMessage.routeId:
                    self.showConversationNotFoundMessage(false);
                    const [smsConversationDraftId] = _router.activeInstruction().params;
                    const draft = self.drafts().find(draft => draft.conversationDraftId() === smsConversationDraftId);
                    if (draft) {
                        self.selectedConversation(draft);
                    }
                    else {
                        const newConversation = new ConversationViewModel(null, smsConversationDraftId, true);

                        self.drafts.push(newConversation);
                        self.selectedConversation(newConversation);
                        newConversation.activate();
                    }

                    _cleanupUnPermissedConversations();
                    _cleanupDeletedNewConversationDrafts();

                    break;
                case _navigationConfiguration.routesById.conversation.routeId:
                    self.showConversationNotFoundMessage(false);
                    const [conversationId, messageId] = _router.activeInstruction().params;
                    const conversation = self.conversations().find(conversation => conversation.conversationId() === conversationId);
                    if (conversation) {
                        self.selectedConversation(conversation);
                    } else {
                        const existingConversation = new ConversationViewModel(conversationId);

                        self.conversations.push(existingConversation);
                        self.selectedConversation(existingConversation);

                        if (messageId) {
                            _messagesState.selectedMessageId(messageId);
                            _jumpToConversationMessage(conversationId, messageId);
                        }

                        existingConversation.activate();
                    }

                    _cleanupUnPermissedConversations();
                    _cleanupDeletedNewConversationDrafts();

                    break;
                case _navigationConfiguration.routesById.conversationNotFound.routeId:
                    self.selectedConversation(null);
                    self.showConversationNotFoundMessage(true);
            }
        };

        const _cleanupUnPermissedConversations = () => {
            const authorizedSmsConversationIds = self.authorizedSmsConversationIds();
            const unAuthorizedConversations = self.conversations()
                .filter(x => !x.isNewConversation())
                .filter((conversation) => {
                    if (!conversation.isInitialized()) {
                        return false;
                    }

                    return !authorizedSmsConversationIds.some((id) => {
                        return id === conversation.conversationId();
                    });
                });

            unAuthorizedConversations.forEach((unAuthorizedConversation) => {
                unAuthorizedConversation.dispose();
                self.conversations.remove(unAuthorizedConversation);
            });
        };

        const _cleanupDeletedNewConversationDrafts = () => {
            const authorizedSmsDraftConversationIds = self.authorizedSmsConversationDraftIds();

            const unAuthorizedConversations = self.conversations()
                .filter(x => x.isNewConversation())
                .filter((draftConversation) => {
                    if (!draftConversation.isInitialized()) {
                        return false;
                    }

                    return !authorizedSmsDraftConversationIds.some((id) => {
                        return id === draftConversation.conversationDraftId();
                    });
                });

            unAuthorizedConversations.forEach((unAuthorizedConversationDraft) => {
                unAuthorizedConversationDraft.dispose();
                self.drafts.remove(unAuthorizedConversationDraft);
            });
        };

        const _onAuthorizedHostedNumberIdsChanged = (/** @type {string[]} */authorizedHostedNumberIds) => {
            const selectedConversation = self.selectedConversation();

            if (!selectedConversation) {
                _router.navigate(_navigationConfiguration.routesById.messages.routeId);
                return;
            }

            const isUserAuthorizedToSelectedConversation = authorizedHostedNumberIds
                .includes(selectedConversation.sendFromHostedNumberId());

            if (selectedConversation.isNewConversation()) {
                if (
                    (!isUserAuthorizedToSelectedConversation) &&
                    (selectedConversation.conversationDraftId() === _messagesState.selectedConversationDraftId())
                ) {
                    _messagesState.selectedConversationDraftId(null);
                    _router.navigate(_navigationConfiguration.routesById.messages.routeId);
                }

                return;
            }

            if (
                (!isUserAuthorizedToSelectedConversation) &&
                (selectedConversation.conversationId() === _messagesState.selectedConversationId())
            ) {
                _messagesState.selectedConversationId(null);
                _router.navigate(_navigationConfiguration.routesById.messages.routeId);
            }
        };

        const _onAuthorizedSmsConversationIdsChanged = () => {
            // wait for archive fade out animation to complete for sidebar and content pane before cleaning up conversations.
            setTimeout(_cleanupUnPermissedConversations, TransitionDurations.slow);
        };

        const _webMessagingNumbers = _webMessagingDataSource.webMessagingNumbers;

        /** @type {KnockoutObservableArray<ConversationViewModel["prototype"]>} */
        self.conversations = _messagesState.conversations;
        self.drafts = _messagesState.drafts;

        self.showMessages = ko.pureComputed(() => {
            return !_sessionAccountInfo.isImpersonating();
        });

        self.showProtectedContentNotice = ko.pureComputed(() => {
            return _sessionAccountInfo.isImpersonating();
        });

        self.authorizedHostedNumberIds = _webMessagingDataSource.authorizedHostedNumberIds;
        self.authorizedSmsConversationIds = _webMessagingDataSource.smsConversationIds;
        self.authorizedSmsConversationDraftIds = _webMessagingDataSource.smsConversationDraftIds;

        /** @type {KnockoutObservable<(typeof ConversationViewModel)["prototype"]>} */
        self.selectedConversation = ko.observable(null);

        self.showConversationNotFoundMessage = ko.observable(false);

        self.showNoPermissionState = ko.pureComputed(() => {
            return (_webMessagingNumbers().length === 0) && _sessionAccountInfo.isLoggedIn();
        });

        self.isSystemAdmin = ko.pureComputed(() => _sessionAccountInfo.isSystemAdmin());
        self.showEmptyState = ko.pureComputed(() => {
            return self.showNoPermissionState() === false &&
                self.selectedConversation() === null &&
                self.showConversationNotFoundMessage() === false;
        });

        self.showConversationMediaScreen = ko.pureComputed(() => {
            return self.selectedConversation() !== null &&
                self.selectedConversation().showAllMedia() === true;
        });

        self.showConversationLinksScreen = ko.pureComputed(() => {
            return self.selectedConversation() !== null &&
                self.selectedConversation().showAllLinks() === true;
        });

        self.showConversation = ko.pureComputed(() => {
            return self.showNoPermissionState() === false &&
                self.selectedConversation() !== null &&
                self.selectedConversation().showAllMedia() === false &&
                self.selectedConversation().showAllLinks() === false &&
                self.showConversationNotFoundMessage() === false;
        });

        self.onConversationCompositionComplete = () => {
            const selectedConversation = self.selectedConversation();
            const isDraftConversation = selectedConversation.isNewConversation();
            const conversationId = selectedConversation.conversationId();
            const conversationDraftId = selectedConversation.conversationDraftId();

            if (isDraftConversation) {
                _messagesState.selectedConversationDraftId(conversationDraftId);
            } else {
                _messagesState.selectedConversationId(conversationId);
            }
        };

        self.openPhoneNumbersPage = () => _windowControl.openNewWindow(_navigationConfiguration.routesById.phoneNumbers.url);

        self.canReuseForRoute = () => {
            return true;
        };

        self.detached = function() {
            _disposables.forEach(subscription => subscription.dispose());
            _disposables = [];
        };

        self.activate = () => {
            _promiseFactory = new PromiseFactory();

            return _initialize();
        };

        const _initialize = () => {

            if (_sessionAccountInfo.isImpersonating()) {
                return _promiseFactory.wait();
            }

            _promiseFactory.defer((deferredObject) => {
                _webMessagingDataSource.waitForData()
                    .done(() => {
                        _disposables.push(
                            self.authorizedHostedNumberIds.subscribe(_onAuthorizedHostedNumberIdsChanged),
                            self.authorizedSmsConversationIds.subscribe(_onAuthorizedSmsConversationIdsChanged)
                        );
                        // TODO: Better solution for this,
                        // Fix for "compositionComplete" not firing in conversationViewModel on initial loading of
                        // messages tab
                        setTimeout(_handleRoute, 0);
                        deferredObject.resolve();
                    });
            });

            return _promiseFactory.wait();
        };
    };
});

