define('businessServices/numberProvisioning/numberProvisioningStore',[
    'businessServices/numberProvisioning/numberProvisioningSingleton',
    'constants/smsCampaignRegistrationConstants',
], function () {
    return function () {
        const self = this;

        const _numberProvisioningState = require('businessServices/numberProvisioning/numberProvisioningSingleton');
        const _smsCampaignRegistrationConstants = require('constants/smsCampaignRegistrationConstants');

        self.setNumberType = (numberType) => {
            const numberTypeValue = numberType;

            _numberProvisioningState.numberType = numberTypeValue;
        };

        self.setSmsPhoneNumberLimit = (smsPhoneNumberLimit) => {
            const smsPhoneNumberLimitValue = smsPhoneNumberLimit;

            _numberProvisioningState.smsPhoneNumberLimit = smsPhoneNumberLimitValue;
        };

        self.setSmsPhoneNumberLimitReason = (smsPhoneNumberLimitReason) => {
            const smsPhoneNumberLimitReasonValue = smsPhoneNumberLimitReason;

            _numberProvisioningState.smsPhoneNumberLimitReason = smsPhoneNumberLimitReasonValue;
        };

        self.addSmsEnabledLocalHostedNumbers = (smsEnabledLocalHostedNumbers) => {
            _numberProvisioningState.smsEnabledLocalHostedNumbers = _numberProvisioningState.smsEnabledLocalHostedNumbers.concat(smsEnabledLocalHostedNumbers);
        };

        self.addSuccessfulNumbers = (successfulNumbers) => {
            _numberProvisioningState.successfulPhoneNumbers = _numberProvisioningState.successfulPhoneNumbers.concat(successfulNumbers);
        };

        self.addFailedNumbers = (failedNumbers) => {
            _numberProvisioningState.failedPhoneNumbers = _numberProvisioningState.failedPhoneNumbers.concat(failedNumbers);
        };

        self.getNumberType = () => {
            const numberTypeResponse = _numberProvisioningState.numberType;

            return numberTypeResponse;
        };

        self.getSmsPhoneNumberLimit = () => {
            const smsPhoneNumberLimitResponse = _numberProvisioningState.smsPhoneNumberLimit;

            return smsPhoneNumberLimitResponse;
        };

        self.getSmsPhoneNumberLimitReason = () => {
            const smsPhoneNumberLimitReasonResponse = _numberProvisioningState.smsPhoneNumberLimitReason;

            return smsPhoneNumberLimitReasonResponse;
        };

        self.getSmsEnabledLocalHostedNumbers = () => {
            return [..._numberProvisioningState.smsEnabledLocalHostedNumbers];
        };

        self.getSuccessfulNumbers = () => {
            return [..._numberProvisioningState.successfulPhoneNumbers];
        };

        self.getFailedNumbers = () => {
            return [..._numberProvisioningState.failedPhoneNumbers];
        };

        self.clear = () => {
            _numberProvisioningState.numberType = null;
            _numberProvisioningState.smsPhoneNumberLimit = _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimits.NONE;
            _numberProvisioningState.smsPhoneNumberLimitReason = _smsCampaignRegistrationConstants.smsAccountPhoneNumberLimitReasons.NONE;
            _numberProvisioningState.successfulPhoneNumbers = [];
            _numberProvisioningState.failedPhoneNumbers = [];
        };

        self.init = () => {};
    };
});
