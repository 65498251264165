define('presentation/loginNotifications/viewModels/payBalanceViewModel',[
    'businessServices/login/accountStatusEvaluator',
    'common/promises/promiseFactory',
    'constants/creditCardResponseConstants',
    'presentation/common/modal',
    'presentation/loginNotifications/viewModels/addPaymentMethodModalViewModel',
    'presentation/loginNotifications/facades/payBalanceFacade',
    'businessServices/converters/currencyFormatter',
    'presentation/loginNotifications/validators/payBalanceValidator'
], function () {
    return function (onModalCloseCompletePromise) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _accountStatusEvaluator = require('businessServices/login/accountStatusEvaluator');

        const CurrencyFormatterConstructor = require('businessServices/converters/currencyFormatter');
        const _currencyFormatter = new CurrencyFormatterConstructor();

        let _addPaymentMethodViewModel = null;
        let _sessionWorkflowFlags = null;
        let _availablePaymentMethods = null;
        const _creditCardResponse = require('constants/creditCardResponseConstants');
        let _facade = null;
        const _promiseFactory = new PromiseFactoryConstructor();
        let _validator = null;
        const _onModalCloseCompletePromise = onModalCloseCompletePromise;

        const _i18n = require('i18next');

        const NEW_PAYMENT_METHOD =  {
            paymentMethodId: "new",
            description: _i18n.t("payBalance:newCard")
        };

        self.addPaymentMethodViewModel = ko.observable();
        self.declinedInformation = {};
        self.isBalancePaid = ko.observable(false);
        self.customerAgreesToPay = ko.observable(false);
        self.paymentMethodDropDownOptions = ko.observableArray([]);
        self.selectedPaymentMethod = ko.observable('');
        self.isCreditCardDeclined = ko.observable(true);
        self.showValidation = ko.observable(false);
        self.showPaymentFailureMessage = ko.observable(false);
        self.paymentFailureTitle = ko.observable('');
        self.paymentFailureMessage = ko.observable('');

        self.isNewPaymentMethod = ko.pureComputed(() => {
            return self.selectedPaymentMethod().paymentMethodId === NEW_PAYMENT_METHOD.paymentMethodId;
        });

        self.warningTitle = ko.pureComputed(() => {
            return _i18n.t("payBalance:warningTitle", {
                pastDueBalance: _currencyFormatter.formatUSDForDisplay(self.declinedInformation.pastDueBalance)
            });
        });

        self.warningMessage = ko.pureComputed(() => {
            if (self.declinedInformation.autoCloseDate) {
                return _i18n.t("payBalance:warningMessageAutoClose", {
                    autoCloseDate: self.declinedInformation.autoCloseDate
                });
            } else {
                return _i18n.t("payBalance:warningMessage");
            }
        });

        self.customerAgreesToPayAcknowledgement = ko.pureComputed(() => {
            return _i18n.t("payBalance:customerAgreesToPayAcknowledgement", {
                pastDueBalance: _currencyFormatter.formatUSDForDisplay(self.declinedInformation.pastDueBalance)
            });
        });

        self.customerAgreesToPayIsValid = ko.pureComputed(() => {
            if (self.showValidation()) {
                return self.customerAgreesToPay();
            }
            return true;
        });

        const _getDeclinedInformation = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getDeclinedInformation()
                    .fail(deferredObject.reject)
                    .done((declinedInformation) => {
                        self.declinedInformation = declinedInformation;
                        if (declinedInformation.autoCloseDate === '') {
                            self.isCreditCardDeclined(false);
                        } else {
                            self.isCreditCardDeclined(true);
                        }
                        deferredObject.resolve();
                    });
            });
        };

        const _getAllPaymentMethods = () => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.getAllPaymentMethods()
                    .fail(deferredObject.reject)
                    .done((paymentMethods) => {
                        _availablePaymentMethods = paymentMethods.filter((paymentMethod) => {
                            return paymentMethod.isExpired() === false;
                        });
                        _buildPaymentMethodsDropDownOptions()
                            .fail(deferredObject.reject)
                            .done((paymentMethodDropDownOptions) => {
                                self.paymentMethodDropDownOptions(paymentMethodDropDownOptions);
                                _setSelectedPaymentMethod();
                                deferredObject.resolve();
                            });
                    });
            });
        };

        const _buildPaymentMethodsDropDownOptions = () => {
            return _promiseFactory.defer((deferredObject) => {
                let paymentMethodDropDownOptions = _availablePaymentMethods.map((paymentMethod) => {
                    return {
                        paymentMethodId: paymentMethod.paymentMethodId,
                        cardHolderName: paymentMethod.cardHolderName,
                        cardType: paymentMethod.cardType,
                        expirationDate: paymentMethod.expirationDate,
                        country: paymentMethod.country,
                        postalCode: paymentMethod.postalCode,
                        description: paymentMethod.description
                    };
                });

                _facade.getAddPaymentMethodAllowed()
                    .fail(deferredObject.reject)
                    .done((addPaymentMethodAllowed) => {
                        if (addPaymentMethodAllowed) {
                            paymentMethodDropDownOptions.push(NEW_PAYMENT_METHOD);
                        }
                        deferredObject.resolve(paymentMethodDropDownOptions);
                    });
            });
        };

        const _setSelectedPaymentMethod = () => {
            let declinedPaymentMethod = _availablePaymentMethods.find((paymentMethod) => {
                return self.declinedInformation.paymentMethodId === paymentMethod.paymentMethodId;
            });

            let defaultPaymentMethod = null;
            if (declinedPaymentMethod === undefined) {
                defaultPaymentMethod = _availablePaymentMethods.find((paymentMethod) => {
                    return paymentMethod.isDefault();
                });
            }

            let selectedPaymentMethod = declinedPaymentMethod || defaultPaymentMethod;
            if (selectedPaymentMethod === undefined) {
                self.selectedPaymentMethod(NEW_PAYMENT_METHOD);
            } else {
                self.selectedPaymentMethod(selectedPaymentMethod);
            }
        };

        const _addPaymentMethodAndMakePayment = () => {
            return _promiseFactory.defer((deferredObject) => {
                let paymentMethod = _addPaymentMethodViewModel.paymentMethod();
                _facade.addPaymentMethodAndMakePayment(paymentMethod, self.declinedInformation.pastDueBalance)
                    .fail(deferredObject.reject)
                    .done((addPaymentMethodAndMakePaymentResponse) => {
                        if (_validatePaymentProcessing(addPaymentMethodAndMakePaymentResponse.status)) {
                            let paymentMethodPresentationObject = _facade.buildPaymentMethodPresentationObject(addPaymentMethodAndMakePaymentResponse.paymentMethod);
                            self.selectedPaymentMethod(paymentMethodPresentationObject);
                            deferredObject.resolve(true);
                        } else {
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        const _makePayment = (paymentMethodId) => {
            return _promiseFactory.defer((deferredObject) => {
                _facade.makePayment(paymentMethodId, self.declinedInformation.pastDueBalance.toString())
                    .fail(deferredObject.reject)
                    .done((paymentResults) => {
                        if (_validatePaymentProcessing(paymentResults.status)) {
                            let selectedPaymentMethod = self.selectedPaymentMethod();
                            self.selectedPaymentMethod(selectedPaymentMethod);
                            deferredObject.resolve(true);
                        } else {
                            deferredObject.resolve(false);
                        }
                    });
            });
        };

        self.payNow = () => {
            self.showValidation(false);
            self.showPaymentFailureMessage(false);
            return _promiseFactory.deferWithMinimumWait((deferredObject) => {
                let payBalancePromiseFactory = new PromiseFactoryConstructor();
                let addPaymentMethodIsValid = false;
                let payBalanceIsValid = false;

                if (self.isNewPaymentMethod()) {
                    payBalancePromiseFactory.defer((newPaymentDeferred) => {
                        self.addPaymentMethodViewModel().validate()
                            .fail(newPaymentDeferred.reject)
                            .done((isValid) => {
                                addPaymentMethodIsValid = isValid;
                                newPaymentDeferred.resolve();
                            });
                    });
                } else {
                    addPaymentMethodIsValid = true;
                }

                payBalancePromiseFactory.defer((validatorDeferred) => {
                    _validator.validate()
                        .fail(validatorDeferred.reject)
                        .done((isValid) => {
                            payBalanceIsValid = isValid;
                            validatorDeferred.resolve();
                        });
                });

                payBalancePromiseFactory.wait()
                    .fail(deferredObject.reject)
                    .done(() => {
                        if (addPaymentMethodIsValid && payBalanceIsValid) {
                            let promise;
                            if (self.isNewPaymentMethod()) {
                                promise = _addPaymentMethodAndMakePayment();
                            } else {
                                promise = _makePayment(self.selectedPaymentMethod().paymentMethodId);
                            }
                            promise
                                .fail(deferredObject.reject)
                                .done((paymentProcessed) => {
                                    if (paymentProcessed) {
                                        const result = {
                                            isBalancePaid: true,
                                            paymentAmount: _currencyFormatter.formatUSDForDisplay(self.declinedInformation.pastDueBalance)
                                        };
                                        _accountStatusEvaluator.refresh();
                                        deferredObject.resolve();
                                        _onModalCloseCompletePromise.resolve(result);
                                    } else {
                                        self.showPaymentFailureMessage(true);
                                        deferredObject.resolve();
                                    }
                                });
                        } else {
                            self.showValidation(true);
                            deferredObject.resolve();
                        }
                    });
            });
        };

        const _validatePaymentProcessing = (paymentStatus) => {
            switch (paymentStatus) {
                case _creditCardResponse.success:
                case _creditCardResponse.approved:
                    self.paymentFailureTitle('');
                    self.paymentFailureMessage('');
                    return true;
                case _creditCardResponse.declined:
                    self.paymentFailureTitle(_i18n.t('payBalance:declinedTitle'));
                    self.paymentFailureMessage(_i18n.t('payBalance:declinedMessage'));
                    return false;
                case _creditCardResponse.duplicateCard:
                case _creditCardResponse.invalidCard:
                case _creditCardResponse.invalidConnection:
                    self.paymentFailureTitle(_i18n.t('payBalance:genericFailureTitle'));
                    self.paymentFailureMessage(_i18n.t('payBalance:genericFailureMessage'));
                    return false;
                default:
                    throw new Error(paymentStatus);
            }
        };

        self.activate = () => {
            const AddPaymentMethodViewModel = require('presentation/loginNotifications/viewModels/addPaymentMethodModalViewModel');
            const FacadeConstructor = require('presentation/loginNotifications/facades/payBalanceFacade');
            const ValidatorConstructor = require('presentation/loginNotifications/validators/payBalanceValidator');

            _addPaymentMethodViewModel = new AddPaymentMethodViewModel();
            self.addPaymentMethodViewModel(_addPaymentMethodViewModel);
            _sessionWorkflowFlags = require('businessServices/authentication/sessionWorkflowFlags');
            _facade = new FacadeConstructor();
            _facade.init();
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {
            _getDeclinedInformation();
            _getAllPaymentMethods();
            _validator.registerViewModel(self);
            return _promiseFactory.wait();
        };
    };
});
