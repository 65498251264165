define('presentation/settings/closeAccount/presentationObjects/closeAccountFeaturePresentationObject',[],function() {
    return function(){
        let self = this;
        self.id = null;
        self.description = null;
        self.isValid = ko.observable(true);
        self.isSelected = ko.observable(false);
    };
});

