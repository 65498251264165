define('businessServices/router/router',['plugins/router',
        'plugins/history',
        'settings/navigationConfiguration',
        'common/promises/promiseFactory',
        'settings/settings',
        'businessServices/router/clientUiRouterPluginManager',
        'businessServices/googleAnalytics/analyticsTracking',
        'common/logging/logger',
        'common/presentation/viewLocatorConfiguration'
    ],
    function (durandalRouter, durandalHistory, navigationConfiguration, PromiseFactoryConstructor, settings, clientUiRouterPluginManager, analyticsTracking, LoggerConstructor, viewLocatorConfiguration) {
        var _durandalRouter = durandalRouter;
        var _durandalHistory = durandalHistory;
        var _navigationConfiguration = navigationConfiguration;
        var _promiseFactory = new PromiseFactoryConstructor();
        var _settings = settings;
        var _clientUIRouterPluginManager = clientUiRouterPluginManager;
        var _logger = new LoggerConstructor();

        var pluginConfigurations = _clientUIRouterPluginManager.getPluginConfigurations();
        var _routeGuards = pluginConfigurations.routeGuards;
        var _loadUrlRedirects = pluginConfigurations.loadUrlRedirects;
        var _unknownRouteRedirects = pluginConfigurations.unknownRouteRedirects;
        var _hasExecutedAuthorizationPlugins = false;
        var _authorizationPlugins = pluginConfigurations.authorizationPlugins;
        var _callbacksWhenNextNavigationComplete = [];

        pluginConfigurations.viewLocatorPlugins.forEach(function(viewLocatorPlugin) {
            viewLocatorConfiguration.addPlugin(viewLocatorPlugin);
        });

        var originalDurandalFunctions = {
            router: {
                loadUrl: _durandalRouter.loadUrl
            },
            history: {
                navigate: _durandalHistory.navigate
            }
        };

        function guardRoute(viewModel, routeInfo) {
            return _promiseFactory.defer(function (promise) {
                _durandalRouter.customState.applicationIsReady.done(function () {
                    _durandalRouter.customState.isNavigationComplete(false);
                    consultRouteGuards(_routeGuards, routeInfo, promise);
                });
            });
        }

        function onNavigationComplete() {
            var instruction = _durandalRouter.activeInstruction();
            _updatePageDescriptions(instruction.config);
            _logEndRouterTransition(instruction.fragment);
            _reportAnalytics(instruction.fragment, instruction.config.title);
            _durandalRouter.customState.isNavigationComplete(true);

            if (_callbacksWhenNextNavigationComplete.length > 0) {
                var notifyCallBacks = _callbacksWhenNextNavigationComplete;
                _callbacksWhenNextNavigationComplete = [];
                notifyCallBacks.forEach(function (callback) {
                    try {
                        callback();
                    } catch (exception) {
                        _logger.logException(exception);
                    }
                });
            }
        }

        function updateDocumentTitle(instance, instruction) {
            document.title = instruction.config.title;
        }

        function loadUrl(fragment) {
            var promise = _promiseFactory.defer();
            promise.done(function (loadFragmentUrl) {
                if (fragment !== loadFragmentUrl) {
                    _durandalRouter.navigate(loadFragmentUrl);
                    return false;
                } else {
                    return originalDurandalFunctions.router.loadUrl(loadFragmentUrl);
                }
            });

            if (_hasExecutedAuthorizationPlugins) {
                consultLoadUrlRedirects(_loadUrlRedirects, fragment, promise);
            } else {
                _hasExecutedAuthorizationPlugins = true;
                var authorizationPromise = _promiseFactory.defer();
                authorizationPromise
                    .done(function(result) {
                        _durandalRouter.customState.applicationIsReady.resolve();
                        consultLoadUrlRedirects(_loadUrlRedirects, fragment, promise);
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });

                consultAuthorizationPlugins(_authorizationPlugins, fragment, authorizationPromise);
            }
        }

        function consultAuthorizationPlugins(authorizationPlugins, fragment, promise) {
            if (authorizationPlugins.length === 0) {
                promise.resolve("noauth");
            } else {
                var authorizationPlugin = authorizationPlugins[0];
                var authorizationResponse = authorizationPlugin(fragment);
                processPromiseOrResult(authorizationResponse, function(result) {
                    switch (result.status) {
                        case "ignored":
                            var remainingAuthorizationPlugins = authorizationPlugins.slice(1);
                            consultAuthorizationPlugins(remainingAuthorizationPlugins, fragment, promise);
                            break;
                        case "success":
                            promise.resolve("success");
                            break;
                        case "failed":
                            promise.resolve("failed");
                            break;
                    }
                });
            }
        }

        function consultRouteGuards(routeGuards, routeInfo, consultRouteGuardsPromise) {
            if (routeGuards.length === 0) {
                consultRouteGuardsPromise.resolve(true);
            } else {
                var currentRouteGuard = routeGuards[0];
                var routeId = routeInfo.config.customSettings.routeId;
                var routeGuardResponse = currentRouteGuard(routeId, routeInfo);
                processPromiseOrResult(routeGuardResponse, function(result) {
                    if (result === true) {
                        var remainingRouteGuards = routeGuards.slice(1);
                        consultRouteGuards(remainingRouteGuards, routeInfo, consultRouteGuardsPromise);
                    } else {
                        if ((result.routeUrl !== undefined) && (result.routeUrl !== null)) {
                            consultRouteGuardsPromise.resolve(result.routeUrl);
                        } else {
                            var error = new Error("Unexpected routeGuardResponse result. routeUrl must be specified in result.");
                            error.routeGuardResponse = result;
                            consultRouteGuardsPromise.reject(error);
                        }
                    }
                });
            }
        }

        function consultLoadUrlRedirects(loadUrlRedirects, fragment, consultRedirectPromise) {
            if (loadUrlRedirects.length === 0) {
                consultRedirectPromise.resolve(fragment);
            } else {
                var currentLoadUrlRedirect = loadUrlRedirects[0];
                var redirectResponse = currentLoadUrlRedirect(fragment);
                processPromiseOrResult(redirectResponse, function (result) {
                    if (result === true) {
                        var remainingUrlRedirects = loadUrlRedirects.slice(1);
                        consultLoadUrlRedirects(remainingUrlRedirects, fragment, consultRedirectPromise);
                    } else {
                        if ((result.routeUrl !== undefined) && (result.routeUrl !== null)) {
                            consultRedirectPromise.resolve(result.routeUrl);
                        } else if (result.stall) {
                            // Do nothing, we want to stall
                        } else {
                            var err = new Error("Unexpected redirectResponse result. routeUrl must be specified in the result.");
                            err.redirectResponse = result;
                            consultRedirectPromise.reject(err);
                        }
                    }
                });
            }
        }

        function processPromiseOrResult(value, callback) {
            if (value.done && typeof value.done === "function") {
                value.done(function(promiseResult) {
                    callback(promiseResult);
                });
            } else {
                callback(value);
            }
        }

        function historyNavigate(fragment, options) {
            var promise = _promiseFactory.defer();

            consultLoadUrlRedirects(_loadUrlRedirects, fragment, promise);
            promise.done(function (loadFragmentUrl) {
                return originalDurandalFunctions.history.navigate(loadFragmentUrl, options);
            });
        }

        function notifyWhenNextNavigationComplete(callback) {
            if (callback === undefined) {
                throw new Error("callback cannot be undefined");
            }
            _callbacksWhenNextNavigationComplete.push(callback);
        }

        var _loggedEvents = [];

        var EventConstructor = function () {
            var self = this;
            self.name = "Routing";
            self.module = null;
            self.startTime = null;
            self.endTime = null;
            self.executionTime = null;
            self.environment = _settings.environmentDomain;
            self.host = _settings.environmentHostName;
        };

        var _logStartRouterTransition = function (routeFragment) {
            var timestamp = new Date().getTime();
            var event = _loggedEvents.find(function (event) {
                return event.module === routeFragment;
            });
            if (event) {
                event.startTime = timestamp;
                event.endTime = null;
            } else {
                event = new EventConstructor();
                event.module = routeFragment;
                event.startTime = timestamp;
                _loggedEvents.push(event);
            }
        };

        var _logEndRouterTransition = function (routeFragment) {
            var timestamp = new Date().getTime();
            var event = _loggedEvents.find(function (event) {
                return event.module === routeFragment;
            });
            if (event) {
                event.endTime = timestamp;
                event.executionTime = event.endTime - event.startTime;
            } else {
                event = new EventConstructor();
                event.endTime = timestamp;
                event.module = routeFragment;
            }
        };

        var _reportAnalytics = function(routeFragment, title) {
            analyticsTracking.trackPageView(routeFragment, title);
        };

        var _updatePageDescriptions = function(routeConfig) {
            var description = routeConfig.description;
            document.querySelector("meta[name='description']").setAttribute("content", description);
        };

        function configureRoutes() {
            var routeIds = _navigationConfiguration.routeIds;
            routeIds.forEach(function (routeId) {
                var routeInfo = _navigationConfiguration.routesById[routeId];
                var routeMenuType = null;
                if (routeInfo.menuType) {
                    routeMenuType = routeInfo.menuType;
                } else {
                    routeMenuType = "";
                }

                var childRoutes = [];
                if (routeInfo.childRoutes) {
                    childRoutes = routeInfo.childRoutes;
                }

                _durandalRouter.map({
                    route: routeInfo.url,
                    moduleId: routeInfo.moduleId,
                    title: routeInfo.title,
                    description: routeInfo.description,
                    displayName: routeInfo.displayName,
                    nav: true,
                    customSettings: {
                        routeId: routeId,
                        permission: routeInfo.permission,
                        parentItem: routeInfo.parentItem,
                        icon: routeInfo.icon,
                        iconActive: routeInfo.iconActive,
                        menuType: routeMenuType,
                        isSecure: routeInfo.isSecure,
                        isNested: routeInfo.isNested,
                        template: routeInfo.template,
                        sidebarViewModel: routeInfo.sidebarViewModel,
                        featureId: routeInfo.featureId,
                        childRoutes: childRoutes,
                        section: routeInfo.section,
                        externalUrl: routeInfo.externalUrl,
                        isNavigationExternal: routeInfo.isNavigationExternal,
                        cssRootClass: routeInfo.cssRootClass,
                        hasBadgeCount: routeInfo.hasBadgeCount,
                    }
                });
            });
        }

        _durandalHistory.navigate = historyNavigate;
        _durandalRouter.deactivate();
        _durandalRouter.reset();
        _durandalRouter.guardRoute = guardRoute;
        _durandalRouter.loadUrl = loadUrl;
        _durandalRouter.updateDocumentTitle = updateDocumentTitle;
        _durandalRouter.customState = {
            isActivated: false,
            isNavigationComplete: ko.observable(false),
            isNavigationBuilt: ko.observable(false),
            applicationIsReady: _promiseFactory.deferIndefinitely(),
            notifyWhenNextNavigationComplete: notifyWhenNextNavigationComplete
        };
        _durandalRouter.mapUnknownRoutes = () => {
            let routeRedirects = _unknownRouteRedirects[0]();
            let redirectResult = null;

            routeRedirects.sections.forEach((section) => {
                if (section.test(location.pathname)) {
                    redirectResult = section.url;
                }
            });

            if (redirectResult === null) {
                redirectResult = routeRedirects.default.url;
            }

            if (location.pathname !== redirectResult) {
                location.replace(redirectResult);
            }
        };

        _durandalRouter.on('router:navigation:processing', function () {
            _logStartRouterTransition(_durandalRouter.activeInstruction().fragment);
        });

        _durandalRouter.on('router:navigation:complete', onNavigationComplete);

        _durandalRouter.on('router:route:not-found', function () {
            _durandalRouter.mapUnknownRoutes();
        });

        configureRoutes();
        _durandalRouter.buildNavigationModel();
        _durandalRouter.customState.isNavigationBuilt(true);

        return _durandalRouter;
    });

