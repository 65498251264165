define('presentation/common/errorNotification/viewModels/errorNotificationViewModel',[],function() {
    return function() {
        var self = this;

        self.observable = null;
        self.isClickAble = ko.observable(false);
        self.onClick = null;
        self.canDisplayError = ko.observable(false);

        self.activate = function(settings) {
            if (settings) {
                self.canDisplayError = settings.canDisplay ? settings.canDisplay : ko.observable(true);

                if (settings.field) {
                    self.field = settings.field;
                } else {
                    self.field = ko.observable({isInvalid: true, validationMessage: ""});
                }

                var id = settings.customId || settings.id;
                if (id) {
                    self.customId = id;
                }

                if (settings.isClickAble === true) {
                    self.onClick = settings.onClick;
                    self.isClickAble(true);
                }
            }
        };

        self.customId = "";
    };
});

