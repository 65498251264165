define('presentation/messages/viewModels/conversationMediaViewModel',[
    'i18next',
    'businessServices/events/eventManager',
    'businessServices/messages/messagesStateSingleton',
    'constants/attachmentFileTypesConstants',
    'presentation/messages/viewModels/conversationMessageAttachmentViewModel',
    'presentation/messages/facades/conversationMediaFacade'
], function(
    /** @type import('i18next') */
    i18n,
    /** @type import('businessServices/events/eventManager') */
    EventManager,
    /** @type import('businessServices/messages/messagesStateSingleton') */
    MessagesState,
    /** @type typeof import('constants/attachmentFileTypesConstants') */
    AttachmentFileTypes
) {
    /** @typedef {import('presentation/messages/viewModels/conversationMessageAttachmentViewModel')} ConversationMessageAttachmentViewModel */

    return function (mediaManager, showAllMedia) {
        const self = this;

        /** @type {IDisposable[]} */
        let _disposables = [];

        const _loadNextBatchOfAttachments = (/** @type {string} */scrollEventSource) => {
            if (scrollEventSource !== self.scrollEventSource) {
                return;
            }

            mediaManager.getNextPage();
        };

        /** @type {KnockoutObservableArray<ConversationMessageAttachmentViewModel>} */
        self.attachments = mediaManager.attachments;

        self.showAll = ko.observable(true);
        self.showVideos = ko.observable(false);
        self.showPhotos = ko.observable(false);

        self.showAllMedia = showAllMedia;

        self.emptyMessage = ko.pureComputed(() => {
            const showAll = self.showAll();
            const hasAttachments = self.filteredAttachments().length > 0;

            if (showAll || hasAttachments) {
                return null;
            }

            if (self.showVideos()) {
                const videos = i18n.t(`conversationMedia:videos`);
                return i18n.t(`conversationMedia:noMediaToDisplay`, {
                    media: videos
                });
            }

            const photos = i18n.t(`conversationMedia:photos`);
            return i18n.t(`conversationMedia:noMediaToDisplay`, {
                media: photos
            });
        });

        self.scrollEventSource = 'messagesConversationMedia';

        self.onClickClose = () => {
            self.showAllMedia(false);
            self.showAll(true);
            self.showVideos(false);
            self.showPhotos(false);
        };

        self.onClickAttachment = (/** @type {ConversationMessageAttachmentViewModel}*/ attachment) => {
            EventManager.publishSmsAttachmentFromDetailsClicked(attachment.attachmentId());
        };

        self.onClickAll = () => {
            self.showAll(true);
            self.showVideos(false);
            self.showPhotos(false);
        };

        self.onClickVideos = () => {
            self.showAll(false);
            self.showVideos(true);
            self.showPhotos(false);
        };

        self.onClickPhotos = () => {
            self.showAll(false);
            self.showVideos(false);
            self.showPhotos(true);
        };

        self.filteredAttachments = ko.pureComputed(() => {
            const _ofType = (/** @type {string} */ type) => {
                return (/** @type {ConversationMessageAttachmentViewModel} */ attachment) => {
                    const contentType = attachment.contentType();
                    return contentType && contentType.includes(type);
                };
            };

            const attachments = self
                .attachments()
                .filter(x => !_ofType(AttachmentFileTypes.audio)(x));

            if (self.showAll()) {
                return attachments;
            }

            if (self.showVideos()) {
                return attachments.filter(_ofType(AttachmentFileTypes.video));
            }

            return attachments.filter(_ofType(AttachmentFileTypes.image));
        });

        self.detached = () => {
            _disposables.forEach(d => d.dispose());
            _disposables = [];
        };

        self.activate = () => {
            EventManager.subscribeBottomScrollBufferReached(_loadNextBatchOfAttachments);
        };
    };
});
