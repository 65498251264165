/**
 * @module index
 */
define('presentation/shell/viewModels/indexViewModel',['common/promises/promiseFactory', 'businessServices/router/router', 'settings/navigationConfiguration'], function() {

	/**
     * ViewModel for the index
     * @class index
     * @constructor
     */
	return function() {
		var self = this;
		var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

		var _navigationConfig = require('settings/navigationConfiguration');
        
		/**
         * Activating Router
         * @property router
         * @type {string}
        */
		self.router = null;


		self.activate = function() {
			self.router = require('businessServices/router/router');

			return _initialize();
		};

		self.activate2 = function(router) {
			self.router = router;

			return _initialize();
		};

		var _initialize = function() {
			self.router.navigate(_navigationConfig.homePageUrl);

			return _promiseFactory.wait();
		};
	};
});
