define('businessServices/specifications/userHasAvatarSpecification',[], function() {
    return function() {
        var self = this;

        self.isSatisfiedBy = function(userEntity) {
            if (userEntity) {
                if ((userEntity.avatarFileEncodingType !== undefined) && (userEntity.avatarFileEncodingType !== null)) {
                    return true;
                }
            }

            return false;
        };
    };
});

