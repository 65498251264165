define('constants/voicemailMessageConstants',[], function() {
    return {
        status: {
            new: {
                id: 1,
                name: "New",
                status: "new"
            },
            reviewed: {
                id: 2,
                name: "Reviewed",
                status: "reviewed",
            }
        }
    };
});
