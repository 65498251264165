define('presentation/common/callFlow/presentationObjects/autoAttendantPresentationObject',[],function() {
    return function(){
        let self = this;
        self.name =  ko.observable(null);
        self.accountAutoAttendantId = null;
        self.voicePrompt = ko.observable(null);
        self.subMenuItems =  ko.observableArray([]);
    };
});

