define('presentation/common/actionModal/viewModels/unsavedChangesActionViewModel',[
    'common/promises/promiseFactory'
], function() {
    return function({i18n}) {
        const self = this;
        
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();
        
        self.content = ko.observable({i18n: i18n});
        
        self.activate = () => {
            return _promiseFactory.wait();
        };
    };
});
