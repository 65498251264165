define('presentation/settings/userSubscriptions/facades/userCallRecordingSubscriptionsFacade',['common/collections/collectionSorter',
        'common/promises/promiseFactory',
        'externalDependencies/clientWebSocket',
        'presentation/settings/userSubscriptions/presentationObjects/userCallRecordingSubscriber',
        'presentation/settings/userSubscriptions/presentationObjects/userCallRecordingSubscription',
], function() {
    const SorterConstructor = require('common/collections/collectionSorter');
    const _sorter = new SorterConstructor();

    const UserCallRecordingSubscriptionPresentationObject  = require('presentation/settings/userSubscriptions/presentationObjects/userCallRecordingSubscription');
    const UserCallRecordingSubscriberPresentationObject = require('presentation/settings/userSubscriptions/presentationObjects/userCallRecordingSubscriber');

    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _userCallRecordingSubscriberSettingsWebSocket = clientWebSocket.forApplication("callRecordingSubscriberSettings");
    const _userCallRecordingSubscribersWebSocket = clientWebSocket.forApplication("callRecordingSubscribers");

    const _commonState = require('common/storage/commonState');

    let _activeHostedNumbers = null;
    let _callback = null;
    let _disposables = [];
    let _hostedNumbers = null;
    let _promiseFactory = null;

    const _getCallRecordingSubscriptions = (userId) => {
        return _promiseFactory.defer((deferredObject) => {

            _userCallRecordingSubscriberSettingsWebSocket.send("getInitialCallRecordingSubscriberSettings", {userId: userId}, (data, errMsg) => {
                if (errMsg) {
                    throw new Error(errMsg);
                }

                _hostedNumbers = data.filter(settings => settings.isActiveSubscriber === true);
                _hostedNumbers.forEach(number => _disposables.push(_commonState.get(number.accountHostedNumberId).status.subscribe(_callback)));
                _activeHostedNumbers = _hostedNumbers.filter(settings => _commonState.get(settings.accountHostedNumberId).status() === "active");

                const callRecordingSubscriptionsForDisplay = _buildCallRecordingSubscriptions(_activeHostedNumbers);
                deferredObject.resolve(callRecordingSubscriptionsForDisplay);
            });
        });
    };

    const _buildCallRecordingSubscriptions = (activeSubscriberSettings) => {
        return activeSubscriberSettings.map((hostedNumber) => {
            let subscriptionPresentationObject = new UserCallRecordingSubscriptionPresentationObject();
            subscriptionPresentationObject.callRecordingBoxId = hostedNumber.accountHostedNumberId;
            subscriptionPresentationObject.callRecordingBoxName = hostedNumber.hostedNumberName;
            subscriptionPresentationObject.callRecordingBoxType = _commonState.types.hostedNumber;
            subscriptionPresentationObject.isEmailDeliveryEnabled(hostedNumber.isEmailDeliveryEnabled);
            subscriptionPresentationObject.isPushToMobileAppEnabled(hostedNumber.isPushToMobileAppEnabled);
            subscriptionPresentationObject.isShowInSidebarEnabled(hostedNumber.isShowInSidebarEnabled);

            hostedNumber.callRecordingSubscriberIds = ko.observableArray(hostedNumber.subscribers);
            subscriptionPresentationObject.subscribers = _buildSubscribers(hostedNumber.callRecordingSubscriberIds);
            
            return subscriptionPresentationObject;
        });
    };

    const _buildSubscribers = (subscriberIdList) => {
        const subscribers = ko.pureComputed(() => {
            let formattedSubscribers = subscriberIdList().map((subscriber) => {
                let subscriberPresentationObject = new UserCallRecordingSubscriberPresentationObject();
                subscriberPresentationObject.subscriberType = _commonState.get(subscriber).type;
                subscriberPresentationObject.displayName = _commonState.get(subscriber).name;
                subscriberPresentationObject.subscriberId = _commonState.get(subscriber).id;
                if (_commonState.get(subscriber).type === _commonState.types.user) {
                    subscriberPresentationObject.avatar = _commonState.get(subscriber).avatar;
                    subscriberPresentationObject.isUser = true;
                } else if (_commonState.get(subscriber).type === _commonState.types.userGroup) {
                    subscriberPresentationObject.isUserGroup = true;
                }
                return subscriberPresentationObject;
            });
            _sorter.sort(formattedSubscribers, "displayName", true);
            return formattedSubscribers;
        });
        return subscribers;
    };

    const _saveSubscriptionSettings = (request) => {
        return _promiseFactory.defer((deferredObject) => {
            _userCallRecordingSubscriberSettingsWebSocket.send("updateCallRecordingSubscriberSettings", request, (data, errMsg) => {
                if (errMsg) {
                    throw new Error(errMsg);
                }
                deferredObject.resolve();
            });
        });
    };

    const _onSubscriberSettingsUpdate = () => {
        _userCallRecordingSubscriberSettingsWebSocket.subscribeToEvent("callRecordingSubscriberSettingsChanged", (callRecordingSubscriberSettings) => {
            const index = _hostedNumbers.findIndex(hostedNumber => hostedNumber.accountHostedNumberId === callRecordingSubscriberSettings.accountHostedNumberId);
            if (index === (-1)) {
                _callback(true);
            } else if (callRecordingSubscriberSettings.isActiveSubscriber === false) {
                _callback(true);
            } else {
                _callback(false);
            }
        });
    };

    const _onSubscribersUpdate = () => {
        _userCallRecordingSubscribersWebSocket.subscribeToEvent("callRecordingSubscribersChanged", (callRecordingSubscribers) => {
            _promiseFactory.defer((deferredObject) => {
                _userCallRecordingSubscribersWebSocket.send("getCallRecordingSubscribers", {accountHostedNumberId: callRecordingSubscribers.accountHostedNumberId}, (data, errMsg) => {
                    if (errMsg) {
                        throw new Error(errMsg);
                    }

                    let index = _hostedNumbers.findIndex(hostedNumber => hostedNumber.accountHostedNumberId === callRecordingSubscribers.accountHostedNumberId);
                    _hostedNumbers[index].callRecordingSubscriberIds(data.callRecordingSubscribersList);
                    deferredObject.resolve();
                });
            });
        });
    };

    const _dispose = () => {
        _disposables.forEach(s => s.dispose);
        _userCallRecordingSubscriberSettingsWebSocket.disposeOfEvents();
        _userCallRecordingSubscribersWebSocket.disposeOfEvents();
    };

    const _init = (promiseFactory, callback) => {
        _promiseFactory = promiseFactory;
        _callback = callback;
    };

    return function() {
        let self = this;

        self.init = _init;
        self.dispose = _dispose;
        self.getCallRecordingSubscriptions = _getCallRecordingSubscriptions;
        self.onSubscriberSettingsUpdate = _onSubscriberSettingsUpdate;
        self.onSubscribersUpdate = _onSubscribersUpdate;
        self.saveSubscriptionSettings = _saveSubscriptionSettings;
    };
});
