define('presentation/common/callRecording/callRecordingAudioFactory',['presentation/common/audioPresentationObject',
        'constants/audioTypeEnumerations'],
    function (AudioPresentationObjectConstructor, webAudioFormatsEnumeration) {

        const getEmptyCallRecording = () => {
            let emptyCallRecording = new AudioPresentationObjectConstructor();
            emptyCallRecording.audioData("about:blank");
            emptyCallRecording.audioType(webAudioFormatsEnumeration.mp3);
            return emptyCallRecording;
        };

        const playCallRecording = (callRecordingUrl) => {
            let audioData = new AudioPresentationObjectConstructor();
            audioData.audioType(webAudioFormatsEnumeration.mp3);
            audioData.audioData(callRecordingUrl);
            audioData.showAudio(true);
            return audioData;
        };

        return {
            getEmptyCallRecording,
            playCallRecording,
        };
    });

