define('businessServices/messages/messagesStateSingleton',[], function() {
    const MessagesStateSingleton = function() {
        this.isLoaded = false;
        this.selectedAccountHostedNumberIds = ko.observableArray([]);
        this.selectedConversationsReviewedStatus = ko.observable(null);
        this.selectedConversationDraftId = ko.observable(null);
        this.selectedConversationId = ko.observable(null);
        this.selectedMessageId = ko.observable(null);
        this.conversations = ko.observableArray([]);
        this.drafts = ko.observableArray([]);

        /** @type {IAuthenticationListeningModule["alerts"]} */
        this.alerts = {
            receiveAlertLoginSuccessful: () => {},
            receiveAlertLogout: () => {
                this.isLoaded = false;
                this.selectedAccountHostedNumberIds([]);
                this.selectedConversationsReviewedStatus(null);
                this.selectedConversationDraftId(null);
                this.selectedConversationId(null);
                this.selectedMessageId(null);
                this.conversations([]);
                this.drafts([]);
            }
        };
    };

    MessagesStateSingleton.singleton = new MessagesStateSingleton();

    return MessagesStateSingleton.singleton;
});

