define('presentation/settings/phoneNumbers/facades/addPhoneNumberPortFacade',['businessServices/authentication/sessionAccountInfo',
        'businessServices/numberProvisioning/numberProvisioner',
        'common/converters/phoneNumberFormatter',
        'common/promises/promiseFactory',
        'externalDependencies/clientApi',
        'persistence/webSocket/webSocketApp',
        'presentation/settings/phoneNumbers/presentationObjects/addPhoneNumberPortPresentationObject'],
    function() {
        let _clientApi = null;
        let _numberProvisioner = null;
        let _phoneNumberFormatter = null;
        let _promiseFactory = null;
        let _sessionAccountInfo = null;
        let _webSocketApp = null;

        const PhoneNumberPortPresentationObjectConstructor = require('presentation/settings/phoneNumbers/presentationObjects/addPhoneNumberPortPresentationObject');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');

        const _addPortedPhoneNumbers = (phoneNumbersToAdd, viewModel) => {
            const addPortedPhoneNumbersPromiseFactory = new PromiseFactoryConstructor();
            return addPortedPhoneNumbersPromiseFactory.deferIndefinitely((deferredObject) => {

                const phoneNumberPortRequest = new PhoneNumberPortPresentationObjectConstructor();
                phoneNumberPortRequest.phoneNumbers = phoneNumbersToAdd.map((currentPhoneNumberInfo) => {
                    return _phoneNumberFormatter.toEOneSixFour(currentPhoneNumberInfo.numberToPort());
                });
                phoneNumberPortRequest.losingCarrier = viewModel.currentCarrier();
                phoneNumberPortRequest.billingInfoName = viewModel.billingInfoName();
                phoneNumberPortRequest.billingInfoNumber = viewModel.billingInfoNumber();
                phoneNumberPortRequest.billingInfoAddressLineOne = viewModel.billingInfoAddress();
                phoneNumberPortRequest.billingInfoAddressLineTwo = viewModel.billingInfoApt();
                phoneNumberPortRequest.billingInfoCity = viewModel.billingInfoCity();
                phoneNumberPortRequest.billingInfoCountry = viewModel.billingInfoCountry();
                phoneNumberPortRequest.billingInfoStateProvince = viewModel.billingInfoState();
                phoneNumberPortRequest.billingInfoPostalCode = viewModel.billingInfoPostalCode();
                phoneNumberPortRequest.portAccountNumber = viewModel.portAccountNumber();
                phoneNumberPortRequest.pinOrPasscode = viewModel.pinOrPasscode();
                phoneNumberPortRequest.selectedOnlyNumberOption = viewModel.selectedOnlyNumberOption();
                phoneNumberPortRequest.portType = viewModel.portType();
                phoneNumberPortRequest.portDate = viewModel.portDate();
                phoneNumberPortRequest.portDateSelection = viewModel.portDateSelection();
                phoneNumberPortRequest.authorizedSignature = viewModel.authorizedSignature();

                _webSocketApp.send("port_numbers", phoneNumberPortRequest, deferredObject.resolve);
            });
        };

        const _checkNumberPortability = (numberToPort) => {
            return _promiseFactory.deferIndefinitely((promise) => {
                _numberProvisioner.checkNumberPortability(numberToPort)
                    .fail(promise.reject)
                    .done((result) => {
                        promise.resolve(result.isPortable);
                    });
            });
        };

        const _getCountriesAndRegions = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _numberProvisioner.getCountries()
                    .fail(deferredObject.reject)
                    .done((result) => {
                        deferredObject.resolve(result.data);
                    });
            });
        };

        const _isImpersonating = function() {
            return _sessionAccountInfo.isImpersonating();
        };


        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
            _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();

            const NumberProvisionerConstructor = require('businessServices/numberProvisioning/numberProvisioner');
            _numberProvisioner = new NumberProvisionerConstructor();
            _numberProvisioner.init();

            const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("add_phone_numbers");
        };

        return function() {
            let self = this;

            self.init = _init;

            self.addPortedPhoneNumbers = _addPortedPhoneNumbers;
            self.checkNumberPortability = _checkNumberPortability;
            self.getCountriesAndRegions = _getCountriesAndRegions;
            self.isImpersonating = _isImpersonating;
        };
    });

