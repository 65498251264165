define('presentation/common/toggleButton/viewModels/longToggleButtonViewModel',[],function() {
    return function() {
        const self = this;

        let _settings = null;

        self.toggleValue = ko.observable(false);
        self.isDisabled = ko.observable(false);

        // control is awaiting result of being switched on and is disabled
        self.isPending = ko.observable(false);

        // control successfully turned on and now shows a checkmark
        self.isFinished = ko.observable(false);

        self.showTooltip = ko.observable(true);
        self.tooltipText = ko.observable("");

        self.isControlDisabled = ko.pureComputed(() => {
            return self.isDisabled() || self.isPending();
        });

        self.iconName = ko.pureComputed(() => {
            return self.isFinished() ? 'checkmark' : 'hourglass';
        });

        self.flip = () => {
            if (self.isControlDisabled()) {
                return;
            }

            self.toggleValue(!self.toggleValue());
        };

        self.activate = (settings) => {
            _settings = settings;
            _initialize();
        };

        const _initialize = () => {
            if (_settings.hasOwnProperty("toggleValue")) {
                self.toggleValue = _settings.toggleValue;
            }

            if (_settings.hasOwnProperty("isDisabled")) {
                if (ko.isObservable(_settings.isDisabled)) {
                    self.isDisabled = _settings.isDisabled;
                } else {
                    self.isDisabled(_settings.isDisabled);
                }
            }

            if (_settings.hasOwnProperty("isPending")) {
                if (ko.isObservable(_settings.isPending)) {
                    self.isPending = _settings.isPending;
                } else {
                    self.isPending(_settings.isPending);
                }
            }

            if (_settings.hasOwnProperty("isFinished")) {
                if (ko.isObservable(_settings.isFinished)) {
                    self.isFinished = _settings.isFinished;
                } else {
                    self.isFinished(_settings.isFinished);
                }
            }

            if (_settings.hasOwnProperty("showTooltip")) {
                if (ko.isObservable(_settings.showTooltip)) {
                    self.showTooltip = _settings.showTooltip;
                } else {
                    self.showTooltip(_settings.showTooltip);
                }
            }

            if (_settings.hasOwnProperty("tooltipText")) {
                if (ko.isObservable(_settings.tooltipText)) {
                    self.tooltipText = _settings.tooltipText;
                } else {
                    self.tooltipText(_settings.tooltipText);
                }
            }
        };
    };
});
