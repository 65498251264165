define('presentation/common/unicodeText',['common/converters/unicodeFormatter'],
(
    /** @type typeof import('common/converters/unicodeFormatter') */
    UnicodeFormatter
) => {
    /**
     * @typedef {{
     *  value: KnockoutObservable<string> | string
     *  singleLine?: KnockoutObservable<boolean | string> | boolean | string
     * }} ValueAccessor
     */

    const _unicodeFormatter = new UnicodeFormatter();

    /**
     * @param {() => string | ValueAccessor | KnockoutObservable<string>} valueAccessor
     * @returns {string}
     */
    const _parseSettings = (valueAccessor) => {
        const valueOrOptions = ko.utils.unwrapObservable(valueAccessor());

        /** @type {string} */
        let text;

        if (typeof valueOrOptions === "string") {
            text = valueOrOptions;
        }
        else {
            text = ko.utils.unwrapObservable(valueOrOptions.value);
            const singleLine = ko.utils.unwrapObservable(valueOrOptions.singleLine);

            if (singleLine === true) {
                text = _unicodeFormatter.replaceNewLines(text);
            }
            else if (typeof singleLine === "string") {
                text = _unicodeFormatter.replaceNewLines(text, singleLine);
            }
        }

        return _unicodeFormatter.unescape(text || ``);
    };

    /**
     * @param {HTMLElement} element 
     * @param {*} valueAccessor 
     */
    const _setInnerText = (element, valueAccessor) => {
        element.innerText = _parseSettings(valueAccessor);
    };

    return {
        init: _setInnerText,
        update: _setInnerText,
    };
});
