define('presentation/settings/userGroupMembership/viewModels/userGroupMembershipViewModel',[
    'businessServices/authentication/sessionAccountInfo',
    'businessServices/state/modelStateObserver',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/settings/userGroupMembership/facades/userGroupMembershipFacade'
], function () {
    return function () {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const SorterConstructor = require('common/collections/collectionSorter');
        const _sorter = new SorterConstructor();

        const ModelStateObserverConstructor = require('businessServices/state/modelStateObserver');

        const _i18n = require('i18next');
        const _sessionAccountInfo = require('businessServices/authentication/sessionAccountInfo');

        let _facade = null;
        let _userId = _sessionAccountInfo.userId();

        self.isSubForm = ko.observable(false);
        self.isSystemAdmin = ko.observable(false);
        self.isUserSelf = ko.observable(true);
        self.modelStateObserver = null;
        self.userGroups = ko.observableArray([]).extend({observeState: true});
        self.allGroups = ko.observableArray([]);
        self.noResultsText = ko.observable(_i18n.t('membershipSelection:addAnotherMessage', {optionText: 'groups'}));
        self.addAnotherMessage = ko.computed(() => {
            return self.isSystemAdmin() ? self.noResultsText(): _i18n.t("noPermission");
        });
        self.groupMembershipLabel= ko.computed(() => {
            return self.isUserSelf() ?
                _i18n.t('userGroupMembership:groupMembershipSelf') :
                _i18n.t('userGroupMembership:groupMembership');
        });

        self.filterAvailableUserGroups = (allUserGroups, selectedUserGroups) => {
            _sorter.multiSort(allUserGroups, ["isDeletable", "groupName"], [true, true]);
            return allUserGroups.filter((userGroup) => {
                return undefined === selectedUserGroups.find((selectedUserGroup) => {
                    return userGroup.id === selectedUserGroup().id;
                });
            });
        };

        self.cancelForm = () => {
            return _promiseFactory.defer((promise) => {
                self.modelStateObserver.restoreData();
                promise.resolve();
            });
        };

        self.savePage = () => {
            const _savePromise = self.isSubForm() ? _promiseFactory.deferIndefinitely() : _promiseFactory.deferWithMinimumWait();

            _facade.saveUserGroups(self.userGroups(), self.allGroups())
                .fail(_savePromise.reject)
                .done(() => {
                    self.modelStateObserver.saveData();
                    _savePromise.resolve();
                });
            return _savePromise;
        };

        self.activate = (userId) => {
            if (userId !== undefined) {
                _userId = userId;
                self.isSubForm(true);
                self.isUserSelf(userId === _sessionAccountInfo.userId());
            }

            const FacadeConstructor = require('presentation/settings/userGroupMembership/facades/userGroupMembershipFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        const _initialize = () => {
            self.isSystemAdmin(_sessionAccountInfo.accountPermissions().SystemSettings);

            self.modelStateObserver = new ModelStateObserverConstructor(self, true);

            _facade.getUserGroups(_userId)
                .fail((error) => {
                    throw error;
                })
                .done((result) => {
                    self.allGroups(result.allGroups);
                    self.userGroups(result.userGroups);
                    _sorter.multiSort(self.userGroups, ["isDeletable", "groupName"], [true, true]);

                    self.modelStateObserver.commitData();
                });
            
            return _promiseFactory.wait();
        };
    };
});

