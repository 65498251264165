define('presentation/settings/phoneNumbers/facades/editPhoneNumberTextMessagingTabFacade',[
    'externalDependencies/clientWebSocket',
    'persistence/webSocket/webSocketApp'
], function() {
    const clientWebSocket = require('externalDependencies/clientWebSocket');
    const _smsRegistrationWebSocket = clientWebSocket.forApplication("smsRegistration");

    let _promiseFactory = null;
    let _webSocketApp = null;

    const _getHostedNumberSmsRegistrationInfo = (accountHostedNumberId) => {
        return _promiseFactory.defer((promise) => {
            const requestData = {
                accountHostedNumberId: accountHostedNumberId,
            };

            _smsRegistrationWebSocket.send("getHostedNumberSmsRegistrationInfo", requestData, (results, error) => {
                if (error) {
                    promise.reject(new Error(error));
                    return;
                }

                promise.resolve(results);
            });
        });
    };

    const _resubmitTollFreeVerification = (accountHostedNumberId) => {
        return _promiseFactory.defer((promise) => {
            const requestData = {
                accountHostedNumberId: accountHostedNumberId,
            };

            _smsRegistrationWebSocket.send("resubmitTollFreeVerification", requestData, (result, error) => {
                if (error) {
                    promise.reject(new Error(error));
                    return;
                }

                promise.resolve(result);
            });
        });
    };

    const _onSmsRegistrationChange = (callback) => {
        _smsRegistrationWebSocket.subscribeToEvent('smsRegistrationChanged', () => {
            _getHostedNumberSmsRegistrationInfo()
                .done((registrationInfo) => {
                    callback(registrationInfo);
                });
        });
    };

    const _sendMessagingFeeConfirmationAutoNotation = (hostedPhoneNumber) => {
        return _promiseFactory.defer((promise) => {
            const requestData = {
                hostedPhoneNumber: hostedPhoneNumber
            };
            _webSocketApp.send("sendMessagingFeeConfirmationAutoNotation", requestData, (result) => {
                if (result.status === "error") {
                    promise.reject(new Error(result.errorMessage));
                } else {
                    promise.resolve(result);
                }
            });
        });
    };


    const _dispose = () => {
        _smsRegistrationWebSocket.disposeOfEvents();
    };

    const _init = (promiseFactory) => {
        _promiseFactory = promiseFactory;

        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("phone_numbers_edit");
    };

    return function() {
        let self = this;

        self.init = _init;
        self.dispose = _dispose;
        self.getHostedNumberSmsRegistrationInfo = _getHostedNumberSmsRegistrationInfo;
        self.resubmitTollFreeVerification = _resubmitTollFreeVerification;
        self.sendMessagingFeeConfirmationAutoNotation = _sendMessagingFeeConfirmationAutoNotation;

        self.onSmsRegistrationChange = _onSmsRegistrationChange;
    };
});

